<div class="advanced-search-wrapper">
  <div class="as-content-container">
    <div class="as-static-container">
      <div class="as-label-container">
        <mat-icon class="as-label-icon"> search </mat-icon>
      </div>

      <div class="as-main-container">
        <app-search-input
          [filters]="filters"
          [mainInputForm]="mainInputValue"
          [focus]="shouldFocusMainSearchBar"
          [title]="placeholder"
          (searchFormChanged)="onMainInputChanged($event)"
          (searchInputFocus)="onSearchInputFocusChange($event)"
          *ngIf="!canShowADropdownContainer()"
        >
        </app-search-input>

        <app-search-number-range
          *ngIf="canShowSearchNumberRange"
          [focusedFilter]="focusedFilter"
          [form]="mainForm"
          (commitFilter)="onFilterCommit($event)"
          (removeFilter)="onRemoveFocusedFilter()"
          (mouseFocus)="onMouseFocus($event, 'search-number-range')"
          (validForm)="onFocusedFilterHasValidForm($event)"
        >
        </app-search-number-range>

        <app-search-location
          *ngIf="canShowSearchLocation"
          [focusedFilter]="focusedFilter"
          [activeFilters]="selectedFilters"
          [form]="mainForm"
          [textArrayMode]="textArrayMode"
          [allowZipSearch]="allowZipSearch"
          [selectedPlace]="selectedPlacePrediction$ | async"
          (commitFilter)="onFilterCommit($event)"
          (removeFilter)="onRemoveFocusedFilter()"
          (mouseFocus)="onMouseFocus($event, 'search-location')"
          (validForm)="onFocusedFilterHasValidForm($event)"
          (newSearchValue)="onNewPlacesSearchPredictions($event)"
        >
        </app-search-location>

        <app-search-date-range
          *ngIf="canShowSearchDateRange && !externalDateSearch"
          [focusedFilter]="focusedFilter"
          [form]="mainForm"
          (commitFilter)="onFilterCommit($event)"
          (removeFilter)="onRemoveFocusedFilter()"
          (mouseFocus)="onMouseFocus($event, 'search-date-range')"
          (validForm)="onFocusedFilterHasValidForm($event)"
        >
        </app-search-date-range>

        <app-search-options
          *ngIf="canShowSearchOptions"
          [focusedFilter]="focusedFilter"
          [form]="mainForm"
          [textArrayMode]="textArrayMode"
          (commitFilter)="onFilterCommit($event)"
          (removeFilter)="onRemoveFocusedFilter()"
          (removeSearchFilter)="onRemoveSearchFilter($event)"
          (searchMultiOptionSelect)="onSearchMultiOptionSelect($event)"
          (mouseFocus)="onMouseFocus($event, 'search-options')"
          (validForm)="onFocusedFilterHasValidForm($event)"
          (textChange)="onTextChange($event)"
        >
        </app-search-options>
      </div>

      <haulynx-search-custom-date-range
        *ngIf="externalDateSearch"
        data-test="external-date-test"
        [focusedFilter]="lastSeenFilter"
        [form]="mainForm"
        [timeZone]="timeZone"
        (commitFilter)="onFilterCommit($event)"
        (removeFilter)="onRemoveFocusedFilter()"
        (mouseFocus)="onMouseFocus($event, 'search-custom-date-range')"
        (validForm)="onFocusedFilterHasValidForm($event)"
      >
      </haulynx-search-custom-date-range>

      <div class="as-search-buttons-container">
        <button
          class="as-search-button"
          (click)="onSearchButtonPress()"
          [disabled]="isSearchButtonDisabled"
          data-test="search-button"
        >
          Search
        </button>
        <div class="as-search-info-container"><app-search-more-info [filters]="filters"></app-search-more-info></div>

        <div
          class="as-recent-search-button"
          [ngStyle]="{ border: shouldShowRecentSearches ? '1px solid white' : 'none' }"
          (focus)="shouldShowRecentSearches = true"
          (blur)="shouldShowRecentSearches = false"
          (click)="showRecentSearches()"
          *ngIf="displayRecentSearches"
        >
          <mat-icon> history </mat-icon>
        </div>
      </div>

      <div class="as-recent-searches-dropdown" [ngStyle]="{ display: shouldShowRecentSearches ? 'initial' : 'none' }">
        <app-search-recent-search
          [recentSearches]="recentSearches"
          [filters]="filters"
          [isOpen]="shouldShowRecentSearches"
          (selectRecentSearch)="onSelectRecentSearch($event)"
          (mouseFocus)="onMouseFocus($event, 'search-recent-search')"
          *ngIf="shouldShowRecentSearches"
        >
        </app-search-recent-search>
      </div>

      <div class="as-dropdown-container" [ngStyle]="{ display: canShowSearchOptionsDropdown ? 'initial' : 'none' }">
        <app-search-options-dropdown
          [focusedFilter]="focusedFilter"
          [currentTextValue]="textChangeValue"
          [form]="mainForm"
          (searchOptionSelect)="onSearchOptionSelect($event)"
          (searchMultiOptionSelect)="onSearchMultiOptionSelect($event)"
          (searchBooleanOptionSelect)="onSearchBooleanOptionSelect($event)"
          (mouseFocus)="onMouseFocus($event, 'search-options-dropdown')"
          (closeSearchOptions)="onCloseSearchOptions()"
          *ngIf="canShowSearchOptionsDropdown"
        >
        </app-search-options-dropdown>
      </div>

      <div class="as-dropdown-container" [ngStyle]="{ display: canShowSearchInputDropdown ? 'initial' : 'none' }">
        <app-search-input-dropdown
          [filters]="filters"
          [mainInputSearchValue]="mainInputValue"
          [selectedFilters]="selectedFilters"
          (filterSelected)="onFilterSelect($event)"
          (mouseFocus)="onMouseFocus($event, 'search-input-dropdown')"
          *ngIf="canShowSearchInputDropdown"
        >
        </app-search-input-dropdown>
      </div>

      <div
        class="as-dropdown-container"
        [ngStyle]="{ display: canShowResultsDropdown && placePredictions.length > 0 ? 'initial' : 'none' }"
      >
        <app-search-place-dropdown
          [predictions]="placePredictions"
          (selectPrediction)="onPredictionSelection($event)"
          (mouseFocus)="onMouseFocus($event, 'search-location-dropdown')"
          *ngIf="canShowResultsDropdown"
        >
        </app-search-place-dropdown>
      </div>
    </div>

    <div class="as-chip-container" *ngIf="selectedFilters.length > 0">
      <app-search-chip-list
        [selectedFilters]="selectedFilters"
        [sideBarSelection]="sideBarSelection"
        [form]="mainForm"
        (removeSearchFilter)="onRemoveSearchFilter($event)"
        (searchFilterFocus)="onSearchFilterFocus($event)"
        (searchFilterBlur)="onSearchFilterBlur()"
        (removeAllSearchFilters)="onRemoveAllSearchFilters()"
      >
      </app-search-chip-list>
    </div>
  </div>
</div>
