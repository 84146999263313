import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  Bid,
  BidHistory,
  bidSortOptions,
  BidSortTypes,
  BidStatusOption,
  BidStatusType,
  Carrier,
  FeatureFlag,
  FFState,
  LoadsServiceLoad,
  UpdateBidInput,
  User,
} from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { get, orderBy } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppDropDownComponent } from '../../../drop-down/components/app-drop-down/app-drop-down.component';

@Component({
  selector: 'app-bid-list',
  templateUrl: './bid-list.component.html',
  styleUrls: ['./bid-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BidListComponent implements OnChanges, OnDestroy, OnInit {
  @ViewChildren(AppDropDownComponent) dropDown: QueryList<AppDropDownComponent>;

  @Input() isLoading: boolean;
  @Input() isUpdating: boolean;
  @Input() activeBidIds: { [bidId: string]: true } = {};
  @Input() bids: Bid[];
  @Input() carrier: Carrier;
  @Input() load: LoadsServiceLoad;
  @Input() bidStatuses: BidStatusOption[] = [];
  @Input() bidsDisabled = false;
  @Input() isLoadingHistory: { [key: string]: boolean } = {};
  @Input() isLoadingAccept: { [key: string]: boolean } = {};
  @Input() isLoadingCounterOffer: { [key: string]: boolean } = {};
  @Input() bidHistory: { [key: string]: BidHistory[] } = {};
  @Input() user: User;
  @Input() userIsBroker: true;
  @Input() userTimeZone: string;
  @Input() sortBidsBy: { [key: string]: BidSortTypes } = {};
  @Input() features: FFState;
  @Output() dataChange = new EventEmitter<{ id: string } & UpdateBidInput>();
  @Output() openHistory = new EventEmitter<Bid>();
  @Output() getHistory = new EventEmitter<Bid>();
  @Output() acceptBid = new EventEmitter<Bid>();
  @Output() disableBids = new EventEmitter<boolean>();
  @Output() openCounterOffer = new EventEmitter<Bid>();
  @Output() sortOrderChange = new EventEmitter<BidSortTypes>();

  sortedBids$ = new BehaviorSubject<Bid[]>([]);
  alive = aliveWhile();
  sortForm: FormGroup;
  statusToAccept = BidStatusType.LIVE;
  sortOptions = bidSortOptions;
  showSortSelector: boolean;
  totalMessages = 1;
  lastCarrierMessage: string;
  bidV2Feature: FeatureFlag = FeatureFlag.BROKER_BIDDING_V2;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.sortForm = this.fb.group({ sort: [BidSortTypes.PRICE] });

    this.sortForm.valueChanges.pipe(takeUntil(this.alive)).subscribe((changes) => {
      const { sort } = changes;
      this.sortedBids$.next(this.sortBids(this.bids, sort));

      this.sortOrderChange.emit(sort);
    });
  }

  sortBids(bids: Bid[], sortedBy: BidSortTypes): Bid[] {
    const order = sortedBy === BidSortTypes.RECENT ? 'desc' : 'asc';

    return orderBy(bids, [sortedBy], [order]);
  }

  onDataChange(bid: { id: string; carrier: Partial<Carrier> } & UpdateBidInput): void {
    this.dataChange.emit(bid);
  }

  onAcceptBid(bid: Bid): void {
    this.acceptBid.emit(bid);
  }

  onOpenHistory(bid: Bid): void {
    this.openHistory.emit(bid);
  }

  onGetHistory(bid: Bid): void {
    this.getHistory.emit(bid);
  }

  onOpenCounterOffer(bid: Bid): void {
    this.openCounterOffer.emit(bid);
  }

  trackByFn(index: number, bid: Bid): string {
    return bid.id;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { bids } = changes;

    if (bids && this.load?.id) {
      const sort = get(this.sortBidsBy, this.load.id, BidSortTypes.PRICE);
      this.sortedBids$.next(this.sortBids(this.bids, sort));

      this.showSortSelector = !!this.sortedBids$.value.length && !this.bidsDisabled;
      if (this.sortForm && this.showSortSelector) {
        this.sortForm.patchValue({ sort });
      }
    }
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }
}
