import { AddressField, CustomerAttributes } from '../generic';
import { Trailer } from '../truck';

export interface LoadDispatch {
  location: AddressField;
  timeAvailable: string;
  notes: string;
}

export class LoadLocation {
  name: string = null;
  creator: string;
  summaryAddress = null;
  completed: number = null; // time finished
  lat: number = null;
  lon: number = null;
  timestamp: number = null; // due date
  departureTime: number = null;
  poNumber: string = null;
  billOfLading: string = null;
  equipmentType: string = null;
  commodity: string = null;
  quantity: number = null;
  loadWeight: number | string = null;
  packingType: string = null;
  temperature: string = null;

  arrivalTime: number = null; // estimated time of arrival -> actual time of arrival when time is passed (UTC timestamp)
  waitTime: number = null; // number of milliseconds
  estimatedWaitTime: number = null;
  appointmentSet: boolean;

  customerId: string = null;
  customerNumber: string = null;
  locationType: LoadLocationType = null;
  specialNotes: string = null;
  entranceTime?: string;
  customerAttributes?: CustomerAttributes[];

  timezone: string;

  trailers?: Trailer[];

  constructor(props?: Partial<LoadLocation>) {
    Object.assign(this, props);
  }
}

export enum LoadLocationType {
  DROPOFF = 'dropoff',
  PICKUP = 'pickup',
  TRAILER = 'traier',
}
