import { ISearchFilter, TextFilterType } from '../search-filter';
import { ISearchFilterType } from '../search-filter.types';
export class LaneWonFilter implements ISearchFilter {
  name = 'Lane Won';
  type: ISearchFilterType = ISearchFilterType.TEXT;
  keys: TextFilterType;
  description = 'Filter by lanes won';

  constructor(defaultValues?: TextFilterType) {
    this.keys = new TextFilterType({
      value: true,
      optionsDataIndex: 'wonLanes',
      textFormName: 'wonLanes',
      ...defaultValues,
    });
  }
}
