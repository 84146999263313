<mat-card class="block-lane mat-elevation-z0">
  <haulynx-carrier-lanes
    [lanes]="dashboardCarrierModel.lanesSearch.entities$ | async"
    [loading]="dashboardCarrierModel.lanesSearch.isLoading$ | async"
    [pagination]="dashboardCarrierModel.lanesSearch.pagination$ | async"
    [checkBoxSelection]="false"
    (onPagination)="searchLanesPaginationChange($event)"
  >
  </haulynx-carrier-lanes>

  <mat-card-actions class="lane-actions">
    <button mat-stroked-button (click)="addLane($event)">Add Lane +</button>
    <app-spinner
      class="lane-actions__spinner"
      [transparentBackground]="true"
      [diameter]="28"
      [active]="dashboardCarrierModel.lanesSearch.isLoading$ | async"
    >
    </app-spinner>
  </mat-card-actions>
</mat-card>
