import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PipesModule } from '@haulynx/pipes';
import { CountdownComponent } from './countdown.component';
@NgModule({
  declarations: [CountdownComponent],
  imports: [CommonModule, FlexLayoutModule, PipesModule],
  exports: [CountdownComponent],
})
export class CountdownModule {}
