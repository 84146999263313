import { Injectable } from '@angular/core';
import {
  CreatePaymentItems,
  DeletePaymentItem,
  FindPaymentItems,
  GetPaymentTypes,
  SendPaymentItems,
  SendPaymentItemsAndEditPrice,
  UpdatePaymentItem,
} from '@haulynx/gql';
import {
  PaymentItem,
  PaymentItemCreateInput,
  PaymentItemCreateResponse,
  PaymentItemSearchCriteria,
  PaymentItemUpdateInput,
  PaymentsTypeForm,
  PaymentType,
  paymentTypes,
} from '@haulynx/types';
import { keyBy } from 'lodash';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { GraphqlService } from '../graphql.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(private graphqlService: GraphqlService) {}

  getPaymentTypes(): Observable<PaymentType[]> {
    return this.graphqlService
      .query<{ getPaymentTypes: PaymentType[] }>({
        query: GetPaymentTypes,
      })
      .pipe(
        map((res) => {
          const entities = ((res.data && res.data.getPaymentTypes) || []).map((paymentType) => {
            return {
              ...paymentType,
              quantity: 1,
              method: paymentType.code === 'SPAY' ? 'STOP' : 'FLT',
            };
          });

          return entities;
        })
      );
  }

  findPaymentItems(variables: PaymentItemSearchCriteria): Observable<PaymentItem[]> {
    return this.graphqlService
      .query<{ findPaymentItems: PaymentItem[] }>({
        query: FindPaymentItems,
        variables: {
          searchCriteria: variables,
        },
      })
      .pipe(map((res) => (res.data && res.data.findPaymentItems) || []));
  }

  findPaymentTypeForm(variables: PaymentItemSearchCriteria): Observable<PaymentsTypeForm[]> {
    const getPaymentTypes = this.getPaymentTypes();
    const findPaymentItems = this.findPaymentItems(variables);

    return forkJoin({
      payments: of(paymentTypes),
      findPaymentItems,
    }).pipe(
      map((res: { findPaymentItems: PaymentItem[]; payments: PaymentType[] }) => {
        const groupedPaymentTypes = keyBy(res.payments, 'code');
        const paymentsTypeForm: PaymentsTypeForm[] = res.findPaymentItems.map((paymentItem) => {
          const paymentType = groupedPaymentTypes[paymentItem.paymentType];

          return {
            id: paymentItem.haulynxId,
            amount: paymentItem.amount,
            orderNumber: paymentItem.orderNumber,
            quantity: paymentItem?.quantity,
            paymentType: {
              code: paymentType.code,
              quantity: paymentItem?.quantity,
              method: paymentType.method,
              description: paymentType.description,
              isReadOnly: paymentType.isReadOnly,
              isNegative: paymentType.isNegative,
            },
          };
        });

        return paymentsTypeForm;
      })
    );
  }

  createPaymentItems(loadId: string, paymentItems: PaymentItemCreateInput[]): Observable<PaymentItemCreateResponse> {
    const modifiedPaymentItems: PaymentItemCreateInput[] = paymentItems?.map((item) => ({
      ...item,
      loadId: loadId,
    }));

    return this.graphqlService
      .mutate<{ createPaymentItems: PaymentItemCreateResponse }>({
        mutation: CreatePaymentItems,
        variables: { paymentItems: modifiedPaymentItems }, // Use the modified payment items
      })
      .pipe(map((res) => res.data.createPaymentItems));
  }

  updatePaymentItem(paymentId: string, paymentUpdate: PaymentItemUpdateInput): Observable<PaymentItem> {
    return this.graphqlService
      .mutate<{ updatePaymentItem: PaymentItem }>({
        mutation: UpdatePaymentItem,
        variables: { paymentId, paymentUpdate },
      })
      .pipe(map((res) => res.data.updatePaymentItem));
  }

  deletePaymentItem(paymentId: string): Observable<PaymentItem> {
    return this.graphqlService
      .mutate<{ deletePaymentItem: PaymentItem }>({
        mutation: DeletePaymentItem,
        variables: {
          paymentId,
        },
      })
      .pipe(map((res) => res.data && res.data.deletePaymentItem));
  }

  sendPaymentItems(orderNumber: string, loadId: string): Observable<PaymentItem[]> {
    return this.graphqlService
      .mutate<{ sendPaymentItems: PaymentItem[] }>({
        mutation: SendPaymentItems,
        variables: { orderNumber: `${orderNumber}`, loadId },
      })
      .pipe(map((res) => res.data && res.data.sendPaymentItems));
  }

  sendPaymentItemsAndEditPrice(orderNumber: string, loadId: string, userEmail: string): Observable<PaymentItem[]> {
    return this.graphqlService
      .mutate<{ sendPaymentItems: PaymentItem[] }>({
        mutation: SendPaymentItemsAndEditPrice,
        variables: { orderNumber, loadId, userEmail },
      })
      .pipe(map((res) => res.data && res.data.sendPaymentItems));
  }
}
