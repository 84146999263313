export interface MomentRange {
  /**
   * Type of `moment.Moment`
   */
  begin: any;
  /**
   * Type of `moment.Moment`
   */
  end: any;
}
