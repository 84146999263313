import { Record } from 'immutable';
import { combineAdaptersInitialState } from '../../shared/store/helpers/adapter';
import { ISearchState } from '../../shared/store/search/state';
import { favoriteAddressAdapter } from './adapters';

export enum FavoriteAddressFormHttpStatus {
  BLANK = 0,
  SUCCESS = 1,
  FAILED = 2,
}

export interface IFavoriteAddressState {
  responseHttp: { [key: string]: any };
  isLoadingFavoriteAddressForm: boolean;
  favoriteAddressFormHttpStatus: FavoriteAddressFormHttpStatus;
  search: ISearchState;
}

const initial = {
  responseHttp: null,
  isLoadingFavoriteAddressForm: false,
  favoriteAddressFormHttpStatus: FavoriteAddressFormHttpStatus.BLANK,
  ...combineAdaptersInitialState(favoriteAddressAdapter),
};

export class FavoriteAddressState extends Record(initial) implements IFavoriteAddressState {
  responseHttp: { [key: string]: any };
  isLoadingFavoriteAddressForm: boolean;
  favoriteAddressFormHttpStatus: FavoriteAddressFormHttpStatus;
  search: ISearchState;
}
