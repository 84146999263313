import { ISearchFilter, TextFilterType } from '../search-filter';
import { ISearchFilterType } from '../search-filter.types';

export class AppointmentSetFilter implements ISearchFilter {
  name = 'Appointment Set';
  type: ISearchFilterType = ISearchFilterType.TEXT;
  keys: TextFilterType;
  description = 'Filter loads by appointment set';

  constructor(defaultValues?: TextFilterType) {
    this.keys = new TextFilterType({
      value: '',
      optionsDataIndex: [
        {
          key: 'None',
          value: 'None',
          // required to get filter data on page refresh
          metaData: {
            firstLocationAppointmentSet: false,
            lastLocationAppointmentSet: false,
          },
        },
        {
          key: 'Pickup',
          value: 'Pickup',
          metaData: {
            firstLocationAppointmentSet: true,
          },
        },
        {
          key: 'Delivery',
          value: 'Delivery',
          metaData: {
            lastLocationAppointmentSet: true,
          },
        },
        {
          key: 'Pickup and Delivery',
          value: 'Pickup and Delivery',
          metaData: {
            firstLocationAppointmentSet: true,
            lastLocationAppointmentSet: true,
          },
        },
      ],
      textFormName: 'appointmentSet',
      ...defaultValues,
    });
  }
}

export enum AppointmentSetOptions {
  NONE = 'None',
  PICKUP = 'Pickup',
  DELIVERY = 'Delivery',
  PICKUP_AND_DELIVERY = 'Pickup and Delivery',
}

export enum AppointmentSetMetaData {
  FIRST_LOCATION = 'metaData.firstLocationAppointmentSet',
  LAST_LOCATION = 'metaData.lastLocationAppointmentSet',
}

export const appointmentSetFormName = 'appointmentSet';
