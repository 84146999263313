import { createFeatureSelector, createSelector } from '@ngrx/store';
import { userAdapter } from './adapters';
import { IUsersState } from './users.state';

export const getUsersState = createFeatureSelector<IUsersState>('users');

const getSearchState = createSelector(getUsersState, (state) => state.search);

export const userSearchSelector = userAdapter.search.getSelectors(getSearchState);

export const isLoadingUserForm = createSelector(getUsersState, (state) => state.isLoadingUserForm);

export const userFormHttpStatus = createSelector(getUsersState, (state) => state.userFormHttpStatus);

export const isLoadingLicenseValidation = createSelector(getUsersState, (state) => state.isLoadingLicenseValidation);

export const isValidDriversLicense = createSelector(getUsersState, (state) => state.isValidDriversLicense);

export const selectedUser = createSelector(getUsersState, (state) => state.selectedUser);
