<div class="input-wrapper" fxFlex fxLayout="column">
  <span class="title">{{ (isEdit | async) ? 'Update Contract' : 'New Contract' }}</span>
  <form [formGroup]="form" class="input-wrapper" fxFlex fxLayout="column" fxLayoutAlign="flex-start center">
    <haulynx-grey-input-change-detection-enabled
      formControlName="billto"
      label="Bill to"
      type="number"
      class="input-blue"
      required="true"
      [disabled]="isEdit | async"
    ></haulynx-grey-input-change-detection-enabled>
    <div class="input-blue sub-input-wrapper">
      <haulynx-grey-input-change-detection-enabled
        formControlName="shipper"
        label="Shipper"
        type="number"
        class="split-input"
        required="true"
        [disabled]="isEdit | async"
      ></haulynx-grey-input-change-detection-enabled>
      <haulynx-grey-input-change-detection-enabled
        formControlName="consignee"
        label="Consignee"
        type="number"
        class="split-input"
        required="true"
        [disabled]="isEdit | async"
      ></haulynx-grey-input-change-detection-enabled>
    </div>
    <div class="input-blue sub-input-wrapper">
      <haulynx-grey-input-change-detection-enabled
        formControlName="carrier"
        label="Carrier"
        type="number"
        class="split-input"
        required="true"
      ></haulynx-grey-input-change-detection-enabled>
      <haulynx-grey-input-change-detection-enabled
        formControlName="carrierRate"
        label="Carrier rate **"
        type="number"
        class="split-input"
        prefix="$"
        placeholder=" "
        pattern="^(\d{0,})(\.\d{2,2})?$"
        autocomplete="off"
        step="0.01"
      ></haulynx-grey-input-change-detection-enabled>
    </div>

    <haulynx-grey-input-change-detection-enabled
      formControlName="broker"
      label="Broker"
      type="text"
      class="input-blue"
    ></haulynx-grey-input-change-detection-enabled>

    <div class="input-blue sub-input-wrapper">
      <haulynx-grey-input-change-detection-enabled
        formControlName="lineHaulPrice"
        label="Line haul price **"
        type="number"
        class="split-input"
        prefix="$"
        placeholder=" "
        pattern="^(\d{0,})(\.\d{2,2})?$"
        autocomplete="off"
        step="0.01"
      ></haulynx-grey-input-change-detection-enabled>
      <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
        <mat-label> Basis </mat-label>
        <app-drop-down
          formControlName="lineHaulBasis"
          class="split-input"
          [data]="lineHaulBasisData"
          key="label"
          placeholder="Basis"
          [chooseDefaultOnTab]="true"
        ></app-drop-down>
      </mat-form-field>
    </div>
    <div class="input-blue sub-input-wrapper">
      <haulynx-grey-input-change-detection-enabled
        formControlName="fuelPrice"
        label="Fuel price **"
        type="number"
        class="split-input"
        prefix="$"
        placeholder=" "
        pattern="^(\d{0,})(\.\d{2,2})?$"
        autocomplete="off"
        step="0.01"
      ></haulynx-grey-input-change-detection-enabled>
      <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
        <mat-label> Basis </mat-label>
        <app-drop-down
          formControlName="fuelBasis"
          class="split-input"
          [data]="fuelBasisData"
          key="label"
          placeholder="Basis"
          [chooseDefaultOnTab]="true"
        ></app-drop-down>
      </mat-form-field>
    </div>
    <div class="input-blue sub-input-wrapper">
      <haulynx-grey-input-change-detection-enabled
        formControlName="stopPrice"
        label="Stop price"
        type="number"
        class="split-input"
        step="0.01"
        placeholder=" "
        prefix="$"
        pattern="^(\d{0,})(\.\d{2,2})?$"
        autocomplete="off"
      ></haulynx-grey-input-change-detection-enabled>
      <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
        <mat-label> Basis </mat-label>
        <app-drop-down
          formControlName="stopBasis"
          class="split-input"
          [data]="stopBasisData"
          key="label"
          placeholder="Basis"
          [chooseDefaultOnTab]="true"
        ></app-drop-down>
      </mat-form-field>
    </div>
    <!-- Warning Message -->
    <div *ngIf="form.errors?.invalidPriceRequirement" class="warning-message">
      ** Either Carrier rate must be provided or both Line haul price and Fuel price must be provided.
    </div>
    <haulynx-blue-checkbox
      class="appt-check"
      text="Shipper Appointment Set Required"
      [(model)]="apptReqShipper"
    ></haulynx-blue-checkbox>
    <haulynx-blue-checkbox
      class="appt-check"
      text="Consignee Appointment Set Required"
      [(model)]="apptReqConsignee"
    ></haulynx-blue-checkbox>
  </form>
  <div class="input-blue sub-input-wrapper">
    <haulynx-blue-button
      fxFlex
      class="cancel-button"
      text="Cancel"
      (clicked)="cancelClicked()"
      data-test="cancel-button"
    ></haulynx-blue-button>
    <haulynx-blue-button
      fxFlex
      fxFlexOffset="5px"
      class="submit-button"
      [text]="(isEdit | async) ? 'Update' : 'Submit'"
      (clicked)="mutateContract()"
      [disabled]="!form.valid"
      data-test="submit-button"
    ></haulynx-blue-button>
  </div>
</div>
