import gql from 'graphql-tag';

export const GetLaneHistory = gql`
  query GetLaneHistory($searchParameters: LaneHistorySearchParameters, $includeBids: Boolean, $paging: PageAndSort) {
    getLaneHistory(searchParameters: $searchParameters, includeBids: $includeBids, paging: $paging) {
      loadSummary {
        totalLoads
        preBookedLoads
        sameDayBookings
        medianPriceOfLoad
        averagePriceOfLoad
        totalLaneProfit
      }
      bidSummary {
        totalBids
        highestBidPrice
        lowestBidPrice
      }
      loads {
        id
        loadStatus
        broker {
          id
          name
          usxId
        }
        created
        createdBy
        carrier {
          name
          dot
          owner
          phone
          id
        }
        locations {
          appointmentStart
          appointmentEnd
          appointmentSet
          address
          city
          state
          timezone
          geometry {
            type
            coordinates
          }
        }
        paymentDetails {
          price
          bookedAt
          revenue
          maxBuy
        }
        providerDetails {
          alternateIds {
            identifierType
            identifierValue
          }
          billToName
        }
        lastUpdatedAt
        bookStatus
        region
        distributionMechanism
        trackingType
        numberOfAppointmentResets
        consignee
        shipperName
        broker {
          usxId
        }
      }
      bids {
        brokerId
        status
        createdAt
        loadId
        carrier {
          name
          dot
          owner
          phone
          id
        }
        id
        price
      }
      carrierLeaderboard {
        id
        name
        totalLoads
        profit
      }
    }
  }
`;
