import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'logout-dialog',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LogoutDialog {
  constructor(public dialogRef: MatDialogRef<LogoutDialog>) {}
}
