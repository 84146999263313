import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  ActionButtonsModule,
  ButtonDropdownModule,
  DatetimePickerModule,
  DividerSliderModule,
  MapboxModule,
  MilestoneComponentsModule,
} from '@haulynx/components';
import { ElementsModule } from '@haulynx/elements';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import {
  AsyncEntityLoadRouteStoreModule,
  AsyncEntityLoadStoreModule,
  LoadDetailsStateModule,
  MilestoneStoreModule,
  AsyncEntityTrailerStoreModule,
} from '@haulynx/store';
import { ReactiveComponentModule } from '@ngrx/component';
import { ClipboardModule } from 'ngx-clipboard';
import { MilestonesContainerRoutingModule } from './milestones-container-routing.module';
import { MilestonesContainerComponent } from './milestones-container.component';
import { LoadOverviewBannerModule } from '../../../../../load-overview/src/lib/components/load-overview-banner/load-overview-banner.module';

@NgModule({
  declarations: [MilestonesContainerComponent],
  imports: [
    CommonModule,
    DividerSliderModule,
    MilestonesContainerRoutingModule,
    ElementsModule,
    MaterialModule,
    ClipboardModule,
    ButtonDropdownModule,
    ActionButtonsModule,
    DatetimePickerModule,
    MapboxModule,
    PipesModule,
    MilestoneComponentsModule,
    MilestoneStoreModule,
    AsyncEntityLoadStoreModule,
    AsyncEntityLoadRouteStoreModule,
    FormsModule,
    ReactiveFormsModule,
    LoadDetailsStateModule,
    ReactiveComponentModule,
    LoadOverviewBannerModule,
    AsyncEntityTrailerStoreModule,
  ],
})
export class MilestonesContainerModule {}
