import gql from 'graphql-tag';

export const DeletePaymentItem = gql(`
  mutation DeletePaymentItem($paymentId: StrictId!) {
    deletePaymentItem(paymentId: $paymentId) {
      orderNumber
      paymentType
      quantity
      amount
      paymentUnits
      haulynxId
      usxId
    }
  }

`);
