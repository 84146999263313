import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import {
  EquipmentTypeToLoadsEquipmentType,
  LoadIdentifierType,
  LoadsServiceEquipmentType,
} from '../../loads-service/loads-service-provider';
import { PickupRequirements } from '../../loads-service/loads-service-restriction';
import { LoadServiceSearchParamData } from '../../loads-service/loads-service-search-parameters';

export type CarrierLoadSearchFilter = keyof LoadServiceSearchParamData;

export interface CarrierLoadSearchFilters {
  main?: CarrierLoadSearchFilterBase[];
  chip?: CarrierLoadSearchFilterBase[];
  more?: CarrierLoadSearchFilterBase[];
}

export enum CarrierLoadSearchFilterSection {
  MAIN,
  CHIP,
  MORE,
}

export interface CarrierLoadSearchFilterBase {
  id: CarrierLoadSearchFilter;
  label?: (state: LoadServiceSearchParamData) => string;
  section: CarrierLoadSearchFilterSection;

  /**
   * Take the full state and change it to an api readable object
   */
  serialize: (state: LoadServiceSearchParamData) => Partial<LoadServiceSearchParamData>;
  /**
   * Whether the value for the selected filter base has been modified and contains a value
   */
  valueIsSelected?: (state: LoadServiceSearchParamData) => boolean;
}

export const carrierLoadSearchOrigin: CarrierLoadSearchFilterBase = {
  id: 'origin',
  section: CarrierLoadSearchFilterSection.MAIN,
  serialize: (state: LoadServiceSearchParamData): Partial<LoadServiceSearchParamData> => {
    if (state.origin?.states) {
      return {
        originStates: [state.origin.states],
      };
    } else if (!state.origin?.states && state.origin?.lat && state.origin?.lon) {
      return {
        originLat: [state.origin.lat],
        originLon: [state.origin.lon],
        originRadiusMiles: [state.origin.radiusMiles],
      };
    } else return {};
  },
};

export const carrierLoadSearchDestination: CarrierLoadSearchFilterBase = {
  id: 'destination',
  section: CarrierLoadSearchFilterSection.MAIN,
  serialize: (state: LoadServiceSearchParamData): Partial<LoadServiceSearchParamData> => {
    if (state.destination?.states) {
      return {
        destinationStates: [state.destination.states],
      };
    } else if (!state.origin?.states && state.destination?.lat && state.destination?.lon) {
      return {
        destinationLat: [state.destination.lat],
        destinationLon: [state.destination.lon],
        destinationRadiusMiles: [state.destination.radiusMiles],
      };
    } else return {};
  },
};

export const carrierLoadSearchEquipmentType: CarrierLoadSearchFilterBase = {
  id: 'equipment',
  label: (state: LoadServiceSearchParamData) => {
    if (state.equipment.length == 0) {
      return 'Equipment Type';
    }
    const firstItem = EquipmentTypeToLoadsEquipmentType(state.equipment[0]);
    return state.equipment.length > 1 ? `${firstItem}  + ${state.equipment?.length - 1}` : firstItem;
  },
  section: CarrierLoadSearchFilterSection.CHIP,
  serialize: (state: LoadServiceSearchParamData): Partial<LoadServiceSearchParamData> => {
    if (!state?.equipment?.length) return {};

    let searchParamData: Partial<LoadServiceSearchParamData> = { equipment: state.equipment };

    // Check to see if either dry van or reefer is selected. If so, add dryVanOrReefer to equipment types
    const includesDryVanOrReefer = state.equipment.some(
      (type: LoadsServiceEquipmentType) =>
        type === LoadsServiceEquipmentType.DRY_VAN || type === LoadsServiceEquipmentType.REEFER
    );
    if (includesDryVanOrReefer && !state.equipment.includes(LoadsServiceEquipmentType.DRY_VAN_OR_REEFER))
      searchParamData.equipment.push(LoadsServiceEquipmentType.DRY_VAN_OR_REEFER);

    // Check to see if power only is selected. If so, add preloaded to pickup requirements
    const includesPowerOnly = state.equipment.includes(LoadsServiceEquipmentType.POWER_ONLY);
    if (includesPowerOnly) searchParamData = { ...searchParamData, pickupRequirements: PickupRequirements.PRELOADED };

    return searchParamData;
  },
  valueIsSelected: (state: LoadServiceSearchParamData): boolean => {
    return state?.equipment?.length ? true : false;
  },
};

export const carrierLoadSearchPickupDateAndTime: CarrierLoadSearchFilterBase = {
  id: 'pickupDateRange',
  label: (state: LoadServiceSearchParamData) => {
    if (!state.pickupDateRange.endTime && !state.pickupDateRange.startTime) {
      return 'Pickup Date';
    }
    const startTime = format(utcToZonedTime(new Date(state.pickupDateRange.startTime), 'UTC'), 'MM/dd/yy');
    const endTime = format(utcToZonedTime(new Date(state.pickupDateRange.endTime), 'UTC'), 'MM/dd/yy');
    return startTime === endTime ? startTime : `${startTime} - ${endTime}`;
  },
  section: CarrierLoadSearchFilterSection.CHIP,
  serialize: (state: LoadServiceSearchParamData): Partial<LoadServiceSearchParamData> => {
    if (state?.pickupDateRange) {
      return {
        firstAppointmentStart: state?.pickupDateRange?.startTime,
        firstAppointmentEnd: state?.pickupDateRange?.endTime,
      };
    }

    return {};
  },
  valueIsSelected: (state: LoadServiceSearchParamData): boolean => {
    return state?.pickupDateRange?.startTime || state?.pickupDateRange?.endTime ? true : false;
  },
};

export const carrierLoadSearchDeliveryDateAndTime: CarrierLoadSearchFilterBase = {
  id: 'deliveryDateRange',
  label: (state: LoadServiceSearchParamData) => {
    if (!state.deliveryDateRange.endTime && !state.deliveryDateRange.startTime) {
      return 'Delivery Date';
    }
    const startTime = format(utcToZonedTime(new Date(state.deliveryDateRange.startTime), 'UTC'), 'MM/dd/yy');
    const endTime = format(utcToZonedTime(new Date(state.deliveryDateRange.endTime), 'UTC'), 'MM/dd/yy');
    return startTime === endTime ? startTime : `${startTime} - ${endTime}`;
  },
  section: CarrierLoadSearchFilterSection.CHIP,
  serialize: (state: LoadServiceSearchParamData): Partial<LoadServiceSearchParamData> => {
    if (state?.deliveryDateRange) {
      return {
        lastAppointmentStart: state?.deliveryDateRange?.startTime,
        lastAppointmentEnd: state?.deliveryDateRange?.endTime,
      };
    }
    return {};
  },
  valueIsSelected: (state: LoadServiceSearchParamData): boolean => {
    return state?.deliveryDateRange?.startTime || state?.deliveryDateRange?.endTime ? true : false;
  },
};

export const carrierLoadSearchPrice: CarrierLoadSearchFilterBase = {
  id: 'priceRange',
  label: (state: LoadServiceSearchParamData) => {
    if (!state.priceRange.minPrice && !state.priceRange.maxPrice) return 'Price';
    const minPrice = state.priceRange?.minPrice;
    const maxPrice = state.priceRange?.maxPrice;
    if (minPrice && !maxPrice) return `> $${Number(minPrice).toLocaleString()}`;
    if (!minPrice && maxPrice) return `< $${Number(maxPrice).toLocaleString()}`;
    if (minPrice && maxPrice) return `$${Number(minPrice).toLocaleString()} - $${Number(maxPrice).toLocaleString()}`;
  },
  section: CarrierLoadSearchFilterSection.CHIP,
  serialize: (state: LoadServiceSearchParamData): Partial<LoadServiceSearchParamData> => {
    const price = {};
    if (state.priceRange?.maxPrice) price['maxPrice'] = state?.priceRange?.maxPrice;
    if (state.priceRange?.minPrice) price['minPrice'] = state?.priceRange?.minPrice;

    return price;
  },
  valueIsSelected: (state: LoadServiceSearchParamData): boolean => {
    return state?.priceRange?.minPrice || state?.priceRange?.maxPrice ? true : false;
  },
};

export const carrierLoadSearchLoadedMiles: CarrierLoadSearchFilterBase = {
  id: 'loadedMilesRange',
  label: (state: LoadServiceSearchParamData) => {
    if (!state.loadedMilesRange.minDistanceMiles && !state.loadedMilesRange.maxDistanceMiles) return 'Loaded Miles';
    const minMiles = state.loadedMilesRange?.minDistanceMiles;
    const maxMiles = state.loadedMilesRange?.maxDistanceMiles;
    if (minMiles && !maxMiles) return `> ${Number(minMiles).toLocaleString()} mil${minMiles > 1 ? 'es' : 'e'}`;
    if (!minMiles && maxMiles) return `< ${Number(maxMiles).toLocaleString()} mil${maxMiles > 1 ? 'es' : 'e'}`;
    if (minMiles && maxMiles)
      return `${Number(minMiles).toLocaleString()} mil${minMiles > 1 ? 'es' : 'e'} - ${Number(
        maxMiles
      ).toLocaleString()} mil${maxMiles > 1 ? 'es' : 'e'}`;
  },
  section: CarrierLoadSearchFilterSection.CHIP,
  serialize: (state: LoadServiceSearchParamData): Partial<LoadServiceSearchParamData> => {
    const loadeMiles = {};
    if (state.loadedMilesRange?.maxDistanceMiles)
      loadeMiles['maxDistanceMiles'] = state?.loadedMilesRange?.maxDistanceMiles;
    if (state.loadedMilesRange?.minDistanceMiles)
      loadeMiles['minDistanceMiles'] = state?.loadedMilesRange?.minDistanceMiles;

    return loadeMiles;
  },
  valueIsSelected: (state: LoadServiceSearchParamData): boolean => {
    return state?.loadedMilesRange?.minDistanceMiles || state?.loadedMilesRange?.maxDistanceMiles ? true : false;
  },
};

export const carrierLoadSearchOrderNumber: CarrierLoadSearchFilterBase = {
  id: 'orderNumber',
  label: (state: LoadServiceSearchParamData) => {
    if (!state.orderNumber?.length) return 'Order Number';

    return `#${state.orderNumber}`;
  },
  section: CarrierLoadSearchFilterSection.CHIP,
  serialize: (state: LoadServiceSearchParamData): Partial<LoadServiceSearchParamData> => {
    return state?.orderNumber
      ? { alternateIdValue: state.orderNumber, alternateIdType: LoadIdentifierType.ORDER_NUMBER }
      : {};
  },
  valueIsSelected: (state: LoadServiceSearchParamData): boolean => {
    return state?.orderNumber ? true : false;
  },
};

export const carrierLoadSearchTMWNumber: CarrierLoadSearchFilterBase = {
  id: 'tmwNumber',
  label: (state: LoadServiceSearchParamData) => {
    if (!state.tmwNumber?.length) return 'TMW Number';

    return `#${state.tmwNumber}`;
  },
  section: CarrierLoadSearchFilterSection.CHIP,
  serialize: (state: LoadServiceSearchParamData): Partial<LoadServiceSearchParamData> => {
    return state?.tmwNumber
      ? { alternateIdValue: state.tmwNumber, alternateIdType: LoadIdentifierType.TMW_NUMBER }
      : {};
  },
  valueIsSelected: (state: LoadServiceSearchParamData): boolean => {
    return state?.tmwNumber ? true : false;
  },
};

export const carrierLoadSearchTruck: CarrierLoadSearchFilterBase = {
  id: 'truckUnitId',
  label: (state: LoadServiceSearchParamData) => {
    if (!state?.truckUnitId?.length) return 'Truck';

    return `${state?.truckUnitId}`;
  },
  section: CarrierLoadSearchFilterSection.CHIP,
  serialize: (state: LoadServiceSearchParamData): Partial<LoadServiceSearchParamData> => {
    return state?.truckUnitId ? { truckUnitId: state?.truckUnitId } : {};
  },
  valueIsSelected: (state: LoadServiceSearchParamData): boolean => {
    return state?.truckUnitId?.length ? true : false;
  },
};

export const carrierLoadSearchDriver: CarrierLoadSearchFilterBase = {
  id: 'driverName',
  label: (state: LoadServiceSearchParamData) => {
    if (!state?.driverName?.length) return 'Driver';
    return `${state?.driverName}`;
  },
  section: CarrierLoadSearchFilterSection.CHIP,
  serialize: (state: LoadServiceSearchParamData): Partial<LoadServiceSearchParamData> => {
    return state?.driverName ? { driverName: state?.driverName } : {};
  },
  valueIsSelected: (state: LoadServiceSearchParamData): boolean => {
    return state?.driverName?.length ? true : false;
  },
};

export const carrierLoadSearchBookItNow: CarrierLoadSearchFilterBase = {
  id: 'carrierCanBookNow',
  label: (state: LoadServiceSearchParamData) => {
    return 'Book it now';
  },
  section: CarrierLoadSearchFilterSection.CHIP,
  serialize: (state: LoadServiceSearchParamData): Partial<LoadServiceSearchParamData> => {
    return state?.carrierCanBookNow ? { carrierCanBookNow: true } : {};
  },
  valueIsSelected: (state: LoadServiceSearchParamData): boolean => {
    return state?.carrierCanBookNow || false;
  },
};

export const carrierLoadSearchPowerOnly: CarrierLoadSearchFilterBase = {
  id: 'pickupRequirements',
  label: (state: LoadServiceSearchParamData) => {
    return 'Power Only';
  },
  section: CarrierLoadSearchFilterSection.CHIP,
  serialize: (state: LoadServiceSearchParamData): Partial<LoadServiceSearchParamData> => {
    return state?.pickupRequirements ? { pickupRequirements: PickupRequirements.PRELOADED } : {};
  },
  valueIsSelected: (state: LoadServiceSearchParamData): boolean => {
    return state?.pickupRequirements ? true : false;
  },
};

// This will order the chips on desktop UI
export const allCarrierLoadSearchFilters: CarrierLoadSearchFilterBase[] =
  [
    carrierLoadSearchOrigin,
    carrierLoadSearchDestination,
    carrierLoadSearchEquipmentType,
    carrierLoadSearchPowerOnly,
    carrierLoadSearchPickupDateAndTime,
    carrierLoadSearchDeliveryDateAndTime,
    carrierLoadSearchPrice,
    carrierLoadSearchLoadedMiles,
    carrierLoadSearchOrderNumber,
    carrierLoadSearchBookItNow,
  ] ?? [];

// This will order the chips on desktop UI
export const activeCarrierLoadSearchFilters: CarrierLoadSearchFilterBase[] =
  [
    carrierLoadSearchOrigin,
    carrierLoadSearchDestination,
    carrierLoadSearchPickupDateAndTime,
    carrierLoadSearchDeliveryDateAndTime,
    carrierLoadSearchOrderNumber,
    carrierLoadSearchTMWNumber,
    carrierLoadSearchTruck,
    carrierLoadSearchDriver,
  ] ?? [];
