import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { DragScrollModule } from 'ngx-drag-scroll';
import { DropDownModule } from '../drop-down/drop-down.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { TourContentComponent } from './tour-content/tour-content.component';
import { TourComponent } from './tour.component';

@NgModule({
  declarations: [TourComponent, TourContentComponent],
  imports: [
    CommonModule,
    PipesModule,
    DragScrollModule,
    RouterModule,
    SpinnerModule,
    DropDownModule,
    ReactiveFormsModule,
    MaterialModule,
  ],
  exports: [TourComponent],
})
export class TourModule {}
