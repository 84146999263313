import { createFormAdapter, FormAdapter } from '../../shared/store/form/adapter';
import { combineAdapters } from '../../shared/store/helpers/adapter';
import { createSearchAdapter, SearchAdapter } from '../../shared/store/search/adapter';
import { createTabAdapter, TabAdapter } from '../../shared/store/tabs/adapter';

export const CARRIERS_TYPE = '[CARRIERS]';

const carrierState = {
  searchCarrier: createSearchAdapter,
  tabs: createTabAdapter,
};

export interface ICarrierStateAdapter {
  searchCarrier: SearchAdapter<any>;
  tabs: TabAdapter;
}

export const carrierAdapter: ICarrierStateAdapter = combineAdapters<ICarrierStateAdapter>(carrierState, CARRIERS_TYPE);

export const TYPE_DASHBOARD = '[CARRIERS DASHBOARD]';
const carrierDashboardState = {
  searchRecommendation: createSearchAdapter,
  carrierForm: createFormAdapter,
};

export interface ICarrierDashboardStateAdapter {
  searchRecommendation: SearchAdapter<any>;
  carrierForm: FormAdapter<any>;
}

export const carrierDashboardAdapter: ICarrierDashboardStateAdapter = combineAdapters<ICarrierDashboardStateAdapter>(
  carrierDashboardState,
  TYPE_DASHBOARD
);

export const TYPE_DASHBOARD_LANES = '[CARRIERS DASHBOARD LANES]';
export interface ILanesStateAdapter {
  searchLanes: SearchAdapter<any>;
}

const lanesState = {
  searchLanes: createSearchAdapter,
};

export const carrierDashboardLanesAdapter: ILanesStateAdapter = combineAdapters<ILanesStateAdapter>(
  lanesState,
  TYPE_DASHBOARD_LANES
);

export const TYPE_DASHBOARD_TRUCKS = '[CARRIERS DASHBOARD TRUCKS]';
export interface ITrucksStateAdapter {
  searchTrucks: SearchAdapter<any>;
}

const trucksState = {
  searchTrucks: createSearchAdapter,
};

export const carrierDashboardTrucksAdapter: ITrucksStateAdapter = combineAdapters<ITrucksStateAdapter>(
  trucksState,
  TYPE_DASHBOARD_TRUCKS
);

export const CARRIERS_TABS_TYPE = '[CARRIERS TABS]';
export const carriersTabsState = {
  carriersTabs: createTabAdapter,
};

export interface ICarriersTabs {
  carriersTabs: TabAdapter;
}

export const carrierTabsAdapter = combineAdapters<ICarriersTabs>(carriersTabsState, CARRIERS_TABS_TYPE);
