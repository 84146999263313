import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from '../../../main-store/app.reducers';
import { IFormSelectors } from './selectors';

export interface IFormModelSelector {
  state$: Observable<{ [key: string]: any } | any>;
  initialState$: Observable<{ [key: string]: any } | any>;
  isLoading$: Observable<boolean>;
  mode$: Observable<string>;
  stateKey$: Observable<string>;
}

export const formModel = (store: Store<AppState>, formSelector: IFormSelectors): IFormModelSelector => {
  const state$ = store.select(formSelector.getState);
  const initialState$ = store.select(formSelector.getInitialState);
  const mode$ = store.select(formSelector.getMode);
  const isLoading$ = store.select(formSelector.getLoading);
  const stateKey$ = store.select(formSelector.getStateKey);

  return {
    state$,
    initialState$,
    mode$,
    isLoading$,
    stateKey$,
  };
};
