import { createAction, createActionTypes } from '../helpers/action';

export interface ISearchActions {
  searchUpdate(params?);

  searchDelete(params?);

  search(params?);

  searchSuccess(params?);

  searchError(params?);
}

export interface ISearchTypes {
  GET: string;
  GET_SUCCESS: string;
  GET_ERROR: string;
  SEARCH_UPDATE: string;
  SEARCH_DELETE: string;
  SEARCH: string;
  SEARCH_SUCCESS: string;
  SEARCH_ERROR: string;
}

export const searchActionTypes: ISearchTypes = {
  GET: 'get',
  GET_SUCCESS: 'get success',
  GET_ERROR: 'get error',
  SEARCH: 'search',
  SEARCH_UPDATE: 'update',
  SEARCH_DELETE: 'delete',
  SEARCH_SUCCESS: 'search success',
  SEARCH_ERROR: 'search error',
};

export const createSearchActionTypes = createActionTypes(searchActionTypes);

export function makeSearchActions(type) {
  const get = createAction(type, searchActionTypes.GET);
  const getSuccess = createAction(type, searchActionTypes.GET_SUCCESS);
  const getError = createAction(type, searchActionTypes.GET_ERROR);
  const search = createAction(type, searchActionTypes.SEARCH);
  const searchUpdate = createAction(type, searchActionTypes.SEARCH_UPDATE);
  const searchDelete = createAction(type, searchActionTypes.SEARCH_DELETE);
  const searchSuccess = createAction(type, searchActionTypes.SEARCH_SUCCESS);
  const searchError = createAction(type, searchActionTypes.SEARCH_ERROR);

  return {
    get,
    getSuccess,
    getError,
    searchUpdate,
    searchDelete,
    search,
    searchSuccess,
    searchError,
  };
}

export function createActions(type) {
  return {
    getActionTypes(): ISearchTypes {
      return createSearchActionTypes(type);
    },
    getActions(): ISearchActions {
      return makeSearchActions(type);
    },
  };
}
