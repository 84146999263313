import { User } from './user';

export enum CounterOfferStatus {
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  PENDING = 'pending',
  PRICE_HOLD = 'price_hold',
}

export interface CounterOfferDialogInput {
  carrierId: string;
  bidId: string;
  price: number;
  notes: string;
  status?: CounterOfferStatus;
  finalOffer?: boolean;
}
export interface CounterOfferInput {
  carrierId: string;
  bidId: string;
  price: number;
  notes: string;
  status?: CounterOfferStatus;
}

export interface UpdateCounterOfferInput {
  status?: CounterOfferStatus;
  price?: number;
  notes?: string;
  deletedAt?: number;
}

export class CounterOffer {
  /**
   * Identifier for bid that this counter offer is associated with
   */
  bidId: string;
  /**
   * Identifier for carrier that this counter off is for
   */
  carrierId: string;
  /**
   * millisecond timestamp
   */
  createdAt: number;
  /**
   * A user such as a broker or carrier user
   */
  createdBy: Partial<User>;
  /**
   * Unique identifier for this counter offer
   */
  id: string;
  /**
   * Notes for counter offer
   */
  notes: string;
  /**
   * Price of bid counter offer
   */
  price: number;
  /**
   * Describes if counter offer is active
   */
  status: CounterOfferStatus;
  /**
   * millisecond timestamp
   */
  updatedAt: number;
  /**
   * A user as a broker or carrier user
   */
  updatedBy: Partial<User>;
  /**
   * millisecond timestamp
   */
  deletedAt?: number;

  constructor(props?: Partial<CounterOffer>) {
    Object.assign(this, props);
  }
}
