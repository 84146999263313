<div class="msi_user">
  <div class="content">
    <div class="content__badge">
      <mat-icon [svgIcon]="user === 'carrier' ? 'location-pin' : 'location-pin-white'"></mat-icon>
    </div>
    <div class="content__info">
      <p [ngStyle]="{ color: getBackgroundColor }" data-test="milestone-address">{{ milestone.loadLocationAddress }}</p>
    </div>
  </div>

  <div class="content">
    <div class="content__badge">
      <mat-icon>rule</mat-icon>
    </div>
    <div class="content__info">
      <p [ngStyle]="{ color: getBackgroundColor }" data-test="milestone-subtype">
        {{ formatSubType | transformMilestoneSubType }}
      </p>
    </div>
  </div>
  <div class="content" *ngIf="displayTimeIn">
    <div class="content__badge">
      <mat-icon>schedule</mat-icon>
    </div>
    <div class="content__info">
      <p [ngStyle]="{ color: getBackgroundColor }" data-test="milestone-time">
        {{ diplayTimeInTitle }}
        {{ getCarrierBrokerEntry.primaryEvent.timestamp | dateTime: formatTimeZone }}
      </p>
    </div>
  </div>
  <div class="content" *ngIf="displayTimeOut">
    <div class="content__badge">
      <mat-icon>schedule</mat-icon>
    </div>
    <div class="content__info">
      <p [ngStyle]="{ color: getBackgroundColor }">
        Facility Leave:
        {{ getCarrierBrokerEntry.secondaryEvent.timestamp | dateTime: formatTimeZone }}
      </p>
    </div>
  </div>
  <div class="content" *ngIf="displayTimeIn && displayTimeOut">
    <div class="content__badge">
      <mat-icon>schedule</mat-icon>
    </div>
    <div class="content__info">
      <p [ngStyle]="{ color: getBackgroundColor }">Time at facility: {{ generateTimeInFacility }}</p>
    </div>
  </div>
</div>
