import { GeoSpacialFilterType, ISearchFilter } from '../search-filter';
import { ISearchFilterType } from '../search-filter.types';

export class OriginFilter implements ISearchFilter {
  name = 'Origin';
  type: ISearchFilterType = ISearchFilterType.GEOSPATIAL;
  keys: GeoSpacialFilterType;
  description = 'Filter loads by the origin location';
  searchPriority = 40;

  constructor(defaultValues?: GeoSpacialFilterType) {
    this.keys = new GeoSpacialFilterType({
      radius: 50,
      lat: null,
      lon: null,
      placeInfo: null,
      location: '',
      radiusFormName: 'originRadiusMiles',
      latFormName: 'originLat',
      lonFormName: 'originLon',
      locationFormName: 'originLocation',
      state: '',
      stateFormName: 'originStates',
      ...defaultValues,
    });
  }

  clearValue = () => {
    this.keys.lat = null;
    this.keys.lon = null;
    this.keys.radius = null;
    this.keys.location = '';
    this.keys.state = '';
  };
}
