import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Carrier } from '@haulynx/types';

@Component({
  selector: 'haulynx-carrier-details-summary',
  templateUrl: './carrier-details-summary.component.html',
  styleUrls: ['./carrier-details-summary.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CarrierDetailsSummaryComponent {
  @Input() carrier: Carrier | any = null;
  @Input() isLoading = false;

  location: any[] = [];
  locationCenter = ([] = []);

  constructor() {}
}
