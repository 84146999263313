import { createFeatureSelector } from '@ngrx/store';
import { Record } from 'immutable';
import { combineAdaptersInitialState } from '../../shared/store/helpers/adapter';
import { ISearchState } from '../../shared/store/search/state';
import { fleetAdaptor } from './adaptors';

export interface IFleetState {
  searchFleetTrailerRecords: ISearchState;
  isLoading: boolean;
}

const initialState = {
  isLoading: false,
  ...combineAdaptersInitialState(fleetAdaptor),
};

export class FleetState extends Record(initialState) implements IFleetState {
  searchFleetTrailerRecords: ISearchState;
  isLoading: boolean;
}

export const getFleetState = createFeatureSelector<FleetState>('fleet');
