import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OWL_DATE_TIME_FORMATS } from '@danielmoncada/angular-datetime-picker';
import { LoadActiveDeliveredForm, LoadActiveDeliveredVm, MomentService } from '@haulynx/services';
import { LoadActiveModel } from '@haulynx/store';
import { buttonTypes, DATE_TIME_PICKER_FORMATS, HttpStatus, LoadsServiceLoadLocation } from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import moment from 'moment';
import { Moment } from 'moment';
import { BehaviorSubject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

export class UsXpressDot {
  constructor(public dot: string) {}

  isUsxLoad(carrierDot) {
    return carrierDot === this.dot;
  }
}

const DEFAULT_TIMEZONE = 'etc/GMT';

@Component({
  selector: 'app-load-active-delivered',
  templateUrl: './load-active-delivered.component.html',
  styleUrls: ['./load-active-delivered.component.scss'],
  providers: [
    {
      provide: OWL_DATE_TIME_FORMATS,
      useValue: DATE_TIME_PICKER_FORMATS,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadActiveDeliveredComponent implements OnInit {
  title = '';
  allButtons = { ...buttonTypes };
  buttons$ = new BehaviorSubject([]);
  form: FormGroup;
  timezone: string;
  showSaveButton = true;
  onTime = false;
  isValid = true;
  alive = aliveWhile();
  isEntranceLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isExistLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  min: Moment = null;
  max: Moment = null;

  isUsxLoad = false;

  get entranceController(): AbstractControl {
    return this.form.get('entranceTime') as AbstractControl;
  }

  get completedController(): AbstractControl {
    return this.form.get('completed') as AbstractControl;
  }

  usXpressDot = new UsXpressDot('3124008');

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      onTime: boolean;
      showConfirmButton: boolean;
      location: LoadsServiceLoadLocation;
      position: number;
      loadId: string;
      carrierDot: string;
      trailerId: string;
      min?: string;
    } = {
      title: 'DELIVER LOAD',
      onTime: false,
      showConfirmButton: false,
      location: null,
      position: null,
      loadId: null,
      carrierDot: null,
      trailerId: null,
      min: null,
    },
    public dialogRef: MatDialogRef<LoadActiveDeliveredComponent>,
    private momentService: MomentService,
    private loadActiveDeliveredVm: LoadActiveDeliveredVm,
    public loadActiveModel: LoadActiveModel
  ) {
    const { title = 'DELIVER LOAD', onTime = false, showConfirmButton = true, location, min = null } = data;

    if (title) {
      this.title = title;
    }

    if (onTime) {
      this.onTime = onTime;
    }

    if (showConfirmButton) {
      this.showSaveButton = false;
    }

    this.isUsxLoad = this.usXpressDot.isUsxLoad(this.data.carrierDot);
    this.timezone = location.timezone || DEFAULT_TIMEZONE;
    this.min = min ? moment(min).tz(this.timezone) : null;
    this.max = moment().tz(this.timezone);
    this.form = this.loadActiveDeliveredVm.create({}, this.timezone);
    this.form
      .get('entranceTime')
      .valueChanges.pipe(takeUntil(this.alive))
      .subscribe((val) => {
        if (val && location.carrierArrival && this.entranceController.enabled) {
          this.completedController.enable();
        }

        if ((!val || !location.carrierArrival) && this.entranceController.enabled) {
          this.completedController.disable();
        }
      });

    const { entranceTime, completed } = this.getTimes(location, !!onTime);
    const formDefaultValues = {
      entranceTime,
      completed,
      locationType: location.locationType,
      locationAddress: location.address,
    };

    // Todo: need to move into service or separate property i the load that says that is usx load
    if (this.isUsxLoad) {
      const isDisableEntrance =
        (location.carrierArrival && location.carrierDeparture) ||
        (!location.carrierDeparture && location.carrierArrival);
      const isDisableExit = location.carrierArrival && location.carrierDeparture;

      isDisableExit ? this.completedController.disable() : this.completedController.enable();
      isDisableEntrance ? this.entranceController.disable() : this.entranceController.enable();
    }

    this.form.patchValue(formDefaultValues);

    this.loadActiveModel.httpStatus$
      .pipe(
        takeUntil(this.alive),
        filter((status) => !!status.get(this.data.loadId)),
        map((status) => status.get(this.data.loadId))
      )
      .subscribe(this.updateLocation.bind(this));
  }

  ngOnInit(): void {
    this.buttons$.next([this.allButtons.COMPLETE_DELIVERY]);
  }

  public fixTimezone(type: 'entranceTime' | 'completed'): void {
    const control = this.form.controls[type];
    const newTime = control.value.clone().tz(this.timezone, true);
    control.setValue(newTime);
  }

  updateLocation(status: HttpStatus) {
    // Not created by usx xpress
    if (!this.isUsxLoad) {
      if (status === HttpStatus.SUCCESS && this.isEntranceLoading$.value) {
        const entrance = this.entranceController.value as Moment;
        this.completedController.setValue(entrance.clone().add('1', 'h'));
        this.completedController.enable();
        this.data = {
          ...this.data,
          location: { ...this.data.location, carrierArrival: this.entranceController.value.valueOf() },
        };
      }

      if (status === HttpStatus.SUCCESS && this.isExistLoading$.value) {
        this.data = {
          ...this.data,
          location: { ...this.data.location, carrierDeparture: this.completedController.value.valueOf() },
        };
      }

      if (status === HttpStatus.SUCCESS || status === HttpStatus.FAILED) {
        this.isEntranceLoading$.next(false);
        this.isExistLoading$.next(false);
      }
    }

    // Todo: move into service to check if load is created by usx express

    if (this.isUsxLoad) {
      if (status === HttpStatus.SUCCESS && this.isEntranceLoading$.value) {
        this.entranceController.disable();
        this.completedController.enable();
        const entrance = this.entranceController.value as Moment;
        this.completedController.setValue(entrance.add('1', 'h'));
        // Todo: need to subscribe from the state and to read new load location
        this.data = {
          ...this.data,
          location: { ...this.data.location, carrierArrival: this.entranceController.value.valueOf() },
        };
      }

      if (status === HttpStatus.SUCCESS && this.isExistLoading$.value) {
        this.completedController.disable();
        this.entranceController.disable();
        // Todo: need to subscribe from the state and to read new load location
        this.data = {
          ...this.data,
          location: { ...this.data.location, carrierDeparture: this.completedController.value.valueOf() },
        };
      }

      if (status === HttpStatus.SUCCESS) {
        this.isEntranceLoading$.next(false);
        this.isExistLoading$.next(false);
      }

      if (status === HttpStatus.FAILED && this.isEntranceLoading$.value) {
        this.entranceController.enable();
      }

      if (status === HttpStatus.FAILED && this.isExistLoading$.value) {
        this.completedController.enable();
      }

      if (status === HttpStatus.FAILED) {
        this.isEntranceLoading$.next(false);
      }
    }
  }

  confirmDepartureTime() {
    if (this.form.controls.completed.invalid || this.form.getError('entranceMoreThanExit')) {
      return;
    }

    const { loadId, position } = this.data;
    const { completed } = this.form.value as LoadActiveDeliveredForm;

    if (completed && this.completedController.enabled) {
      this.isExistLoading$.next(true);
      this.loadActiveModel.confirmPickup({ loadId, data: { completed }, position });
    }

    if (this.isUsxLoad) {
      this.form.get('completed').disable();
    }
  }

  confirmArrivalTime() {
    if (this.form.controls.entranceTime.invalid) {
      return;
    }

    const { loadId, position } = this.data;
    const { entranceTime } = this.form.value as LoadActiveDeliveredForm;

    if (entranceTime && this.entranceController.enabled) {
      this.isEntranceLoading$.next(true);
      this.loadActiveModel.confirmPickup({ loadId, data: { entranceTime }, position });
    }

    if (this.isUsxLoad) {
      this.form.get('entranceTime').disable();
    }
  }

  private getTimes(
    location: LoadsServiceLoadLocation,
    onTime
  ): { entranceTime: moment.Moment; completed: moment.Moment } {
    let entranceTime: moment.Moment, completed: moment.Moment;

    if (location.carrierDeparture) {
      completed = this.momentService.getMoment(location.carrierDeparture, location.timezone, false);
    }

    if (location.carrierArrival) {
      entranceTime = this.momentService.getMoment(location.carrierArrival, location.timezone, false);
    }

    if (onTime && !completed && entranceTime) {
      completed = entranceTime.clone().add(1, 'h');
    }

    if (onTime && !completed && !entranceTime) {
      entranceTime = this.momentService.getMoment(location.carrierArrival, location.timezone);
      completed = entranceTime.clone().add(1, 'h');
    }

    if (!onTime && !completed && !entranceTime) {
      entranceTime = this.momentService.getMoment(location.carrierArrival, location.timezone).add(1, 'h');
      completed = entranceTime.clone().add(1, 'h');
    }

    if (!onTime && !completed && entranceTime) {
      completed = entranceTime.clone().add(1, 'h');
    }

    return {
      entranceTime,
      completed,
    };
  }
}
