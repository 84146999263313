import { TemplateRef } from '@angular/core';
import { Observable } from 'rxjs';
import { LoadsServiceLoad, LoadsServiceLoadStatus } from '../../loads-service/loads-service-load';
import { LoadServiceSearchParameters } from '../../loads-service/loads-service-search-parameters';
import { dataColumns } from '../advanced-search/data-columns';
import {
  AppointmentSetFilter,
  BillToFilter,
  BookedByBrokerFilter,
  BrokerFilter,
  BrokerTeamFilter,
  CarrierDOTFilter,
  CXPriorityFilter,
  DeliveryDateFilter,
  DestinationFilter,
  DistanceFilter,
  DriverFilter,
  DropoffRequirementsFilter,
  EquipmentFilter,
  HazmatFilter,
  IBRegionFilter,
  LoadStatusFilter,
  MaxBuyFilter,
  OBRegionFilter,
  OrderFilter,
  OrderTypeFilter,
  OriginFilter,
  PickupDateFilter,
  PickupRequirementsFilter,
  PriceFilter,
  PriorityFilter,
  ReceiverFilter,
  RevenueFilter,
  ShipperFilter,
  TMWFilter,
  TruckFilter,
  WeightFilter,
} from '../advanced-search/models';
import { CXRIdFilter } from '../advanced-search/models/CXRIdFilter';
import { ISearchFilter } from '../advanced-search/search-filter';
import { BulkActionPage } from '../bulk-actions/load-bulk-actions.enum';
import { IColumns2 } from '../data-table-v2';
import { DataTableConfig } from '../data-table/data-table-type';
import { FeatureFlag } from '../feature-flag';
import { PaginatedData } from '../generic';
import { SidebarItem } from '../generic/sidebar';
import { BookStatus } from '../load';
import { ColumnField } from '../search-view/default-columns';

export type CarrierAdvancedSearchSideBarItem = SidebarItem<
  Partial<LoadServiceSearchParameters>,
  {
    resetParams?: (keyof LoadServiceSearchParameters)[];
    columns?: Array<{ key: string; value: boolean; order?: number }>;
    stickyColumnWidthClosed: string;
    stickyColumnWidthOpen: string;
    isCustom?: boolean;
  }
>;

export interface CRMTab {
  id: string;
  label: string;
  data: {
    bookStatus?: BookStatus[];
    loadStatus?: LoadsServiceLoadStatus[];
    carrierNameOrDot?;
  };
  metaData: {
    showSearch: boolean;
    showCalendar: boolean;
    loadQuery: string;
    columns: IColumns2[];
    configOptions: DataTableConfig;
  };
}

export function carrierCRMLoadBoardTabs(
  flags,
  carrierNameOrDot,
  selectedTruckRecommends,
  recommendTab: string
): CRMTab[] {
  const tabs: CRMTab[] = [
    {
      id: 'recommend',
      label: 'Load Recommendations',
      data: {
        bookStatus: [],
        loadStatus: [],
        carrierNameOrDot,
      },
      metaData: {
        showSearch: false,
        showCalendar: true,
        loadQuery: 'recommend',
        columns: [
          {
            field: ColumnField.ORIGIN_DEAD_HEAD,
            label: 'O-DH',
            sortConfig: [
              {
                label: 'O-DH',
                dataIndex: 'odh',
              },
            ],
            dropdownAlign: 'left',
            isCustomCell: true,
            isSortable: true,
            isVisible: selectedTruckRecommends,
            width: '100px',
            order: 1,
          },
          {
            field: ColumnField.ORIGIN_AND_DESTINATION,
            label: 'Origin and Destination',
            width: '416px',
            sortConfig: [
              {
                label: 'Origin Time',
                dataIndex: 'locations.where-0.appointmentStart',
              },
              {
                label: 'Destination Time',
                dataIndex: 'locations.last.appointmentStart',
              },
            ],
            isCustomCell: true,
            isSortable: true,
            isVisible: true,
            isPinned: true,
            order: 2,
          },
          {
            field: ColumnField.DESTINATION_DEAD_HEAD,
            label: 'D-DH',
            sortConfig: [
              {
                label: 'D-DH',
                dataIndex: 'ddh',
              },
            ],
            isCustomCell: true,
            isSortable: true,
            isVisible: selectedTruckRecommends,
            width: '100px',
            order: 3,
          },
          {
            field: ColumnField.LOADED_MILES,
            label: 'Loaded Miles',
            sortConfig: [
              {
                label: 'Loaded Miles',
                dataIndex: 'paymentDetails.distanceMiles',
              },
            ],
            isCustomCell: true,
            isSortable: true,
            isVisible: true,
            width: '130px',
            order: 4,
          },
          {
            field: ColumnField.EQUIPMENT,
            label: 'Equipment',
            sortConfig: [
              {
                label: 'Equipment',
                dataIndex: 'providerDetails.equipmentType',
              },
              {
                label: 'Weight',
                dataIndex: 'providerDetails.weight',
              },
            ],
            filters: ['Equipment'],
            isCustomCell: true,
            isSortable: true,
            width: '170px',
            isVisible: true,
            order: 5,
          },
          {
            field: ColumnField.TMW_AND_ORDER,
            label: 'TMW / Order',
            sortConfig: [
              {
                label: 'TMW Number',
                dataIndex: 'providerDetails.alternateIds.where-identifierType=tmwNumber.identifierValue',
              },
              {
                label: 'Order Number',
                dataIndex: 'providerDetails.alternateIds.where-identifierType=orderNumber.identifierValue',
              },
            ],
            filters: ['TMW', 'Order'],
            isCustomCell: true,
            isSortable: true,
            width: '150px',
            isVisible: true,
            order: 6,
          },
          {
            field: ColumnField.PRICE_MAX_BUY_REVENUE,
            label: 'Price / Rev',
            sortConfig: [
              {
                label: 'Price',
                dataIndex: 'paymentDetails.price',
              },
              {
                label: 'Revenue',
                dataIndex: 'paymentDetails.revenue',
              },
            ],
            filters: ['Price', 'Max Buy', 'Revenue'],
            isCustomCell: true,
            isSortable: true,
            width: '150px',
            isVisible: true,
            order: 7,
          },
          {
            field: ColumnField.DATE_ADDED,
            label: 'Date Added to Board',
            sortConfig: [
              {
                label: 'Date Added',
                dataIndex: 'created',
              },
            ],
            isCustomCell: true,
            isSortable: true,
            width: '180px',
            isVisible: recommendTab === 'Preferred Lanes',
            order: 8,
          },
          {
            field: ColumnField.BIDS,
            label: 'Bids',
            sortConfig: [
              {
                label: 'Bids',
                dataIndex: 'bidDetails.lowestOffer',
              },
            ],
            filters: ['Highest Bid'],
            isCustomCell: true,
            isSortable: true,
            width: '180px',
            isVisible: selectedTruckRecommends,
            order: 9,
          },
          {
            field: ColumnField.ACTIONS,
            label: '',
            sortConfig: [],
            align: 'right',
            isCustomCell: true,
            isSticky: true,
            width: '200px',
            isSortable: false,
            isClickable: false,
            headerTemplate: null,
            isVisible: true,
            isFrozen: true,
            order: 100,
          },
        ],
        configOptions: {
          globalFilter: false,
          reorderColumns: false,
          columnVisibility: false,
          reorderRows: false,
          showBulkActions: false,
          sortColumns: false,
          scrollable: false,
          checkBoxSelection: false,
          filterLocally: false,
          showTopPaginator: true,
          showBottomPaginator: false,
          isLaneRate: false,
          bulkActions: BulkActionPage.CARRIER_SEARCH,
          pageAmount: 25,
        },
      },
    },
    {
      id: 'available',
      label: 'Search Available Loads',
      data: {
        bookStatus: [BookStatus.BOOKABLE, BookStatus.VIEWABLE],
      },
      metaData: {
        showSearch: true,
        showCalendar: false,
        loadQuery: 'search',
        columns: dataColumns('200px', null, [], [], true, false),
        configOptions: {
          globalFilter: false,
          reorderColumns: false,
          columnVisibility: false,
          reorderRows: false,
          showBulkActions: true,
          sortColumns: false,
          scrollable: false,
          checkBoxSelection: true,
          filterLocally: false,
          showTopPaginator: true,
          showBottomPaginator: false,
          isLaneRate: false,
          bulkActions: BulkActionPage.CARRIER_SEARCH,
          pageAmount: 25,
        },
      },
    },
    {
      id: 'active',
      label: 'Active Loads',
      data: {
        bookStatus: [BookStatus.BOOKED],
        loadStatus: [
          LoadsServiceLoadStatus.ASSIGNED,
          LoadsServiceLoadStatus.AT_RECEIVER,
          LoadsServiceLoadStatus.AT_SHIPPER,
          LoadsServiceLoadStatus.DELIVERED,
          LoadsServiceLoadStatus.DISPATCHED,
          LoadsServiceLoadStatus.PICKED_UP,
          LoadsServiceLoadStatus.UNASSIGNED,
        ],
        carrierNameOrDot,
      },
      metaData: {
        showSearch: false,
        showCalendar: false,
        loadQuery: 'search',
        columns: [
          {
            field: ColumnField.STATUS,
            label: 'Status',
            filters: ['Load Status'],
            sortConfig: [
              {
                label: 'Load Status',
                dataIndex: 'loadStatus',
              },
            ],
            isCustomCell: true,
            isSortable: true,
            width: '160px',
            isVisible: true,
            order: 1,
          },
          {
            field: ColumnField.TMW_AND_ORDER,
            label: 'TMW / Order',
            sortConfig: [
              {
                label: 'TMW Number',
                dataIndex: 'providerDetails.alternateIds.where-identifierType=tmwNumber.identifierValue',
              },
              {
                label: 'Order Number',
                dataIndex: 'providerDetails.alternateIds.where-identifierType=orderNumber.identifierValue',
              },
            ],
            filters: ['TMW', 'Order'],
            isCustomCell: true,
            isSortable: true,
            width: '150px',
            isVisible: true,
            order: 2,
          },
          {
            field: ColumnField.ORIGIN_AND_DESTINATION,
            label: 'Origin and Destination',
            isCustomCell: true,
            isSortable: true,
            width: '416px',
            sortConfig: [
              {
                label: 'Origin Time',
                dataIndex: 'firstAppointmentStart-firstAppointmentEnd',
                emitSort: true,
              },
              {
                label: 'Destination Time',
                dataIndex: 'lastAppointmentStart-lastAppointmentEnd',
                emitSort: true,
              },
            ],
            isVisible: true,
            isPinned: true,
            order: 3,
          },
          {
            field: ColumnField.EQUIPMENT,
            label: 'Equipment',
            sortConfig: [
              {
                label: 'Equipment',
                dataIndex: 'providerDetails.equipmentType',
              },
              {
                label: 'Weight',
                dataIndex: 'weightLbs-weightLbs',
                emitSort: true,
              },
            ],
            filters: ['Equipment'],
            isCustomCell: true,
            isSortable: true,
            width: '170px',
            isVisible: true,
            order: 4,
          },
          {
            field: ColumnField.CARRIER,
            label: 'Carrier / DOT',
            sortConfig: [],
            filters: ['Carrier'],
            isCustomCell: true,
            isSortable: false,
            width: '200px',
            isVisible: true,
            order: 5,
          },
          {
            field: ColumnField.BILL_TO,
            label: 'Bill To',
            sortConfig: [
              {
                label: 'Customer',
                dataIndex: 'providerDetails.billToName',
              },
            ],
            filters: ['Bill To'],
            isCustomCell: true,
            isSortable: true,
            width: '200px',
            isVisible: true,
            order: 6,
          },
          {
            field: ColumnField.INBOUND_REGION,
            label: 'Region',
            filters: ['Inbound Region'],
            sortConfig: [],
            isCustomCell: true,
            isSortable: false,
            isVisible: true,
            width: '130px',
            order: 7,
          },
        ],
        configOptions: {
          globalFilter: false,
          reorderColumns: false,
          columnVisibility: false,
          reorderRows: false,
          showBulkActions: false,
          sortColumns: false,
          scrollable: false,
          checkBoxSelection: false,
          filterLocally: false,
          showTopPaginator: true,
          showBottomPaginator: false,
          isLaneRate: false,
          bulkActions: BulkActionPage.CARRIER_SEARCH,
          pageAmount: 25,
        },
      },
    },
  ];
  if (flags && flags[FeatureFlag.ACTIVE_LOAD_MANAGEMENT]) {
    tabs.push({
      id: 'finalled',
      label: 'Finalled Loads',
      data: {
        loadStatus: [LoadsServiceLoadStatus.FINALLED],
        carrierNameOrDot,
      },
      metaData: {
        showSearch: false,
        showCalendar: false,
        loadQuery: 'search',
        columns: [
          {
            field: ColumnField.ORIGIN_AND_DESTINATION,
            label: 'Origin and Destination',
            isCustomCell: true,
            isSortable: true,
            width: '416px',
            sortConfig: [
              {
                label: 'Origin Time',
                dataIndex: 'firstAppointmentStart-firstAppointmentEnd',
                emitSort: true,
              },
              {
                label: 'Destination Time',
                dataIndex: 'lastAppointmentStart-lastAppointmentEnd',
                emitSort: true,
              },
            ],
            isVisible: true,
            isPinned: true,
            order: -99,
          },
          {
            field: ColumnField.EQUIPMENT,
            label: 'Equipment',
            sortConfig: [
              {
                label: 'Equipment',
                dataIndex: 'providerDetails.equipmentType',
              },
              {
                label: 'Weight',
                dataIndex: 'weightLbs-weightLbs',
                emitSort: true,
              },
            ],
            filters: ['Equipment'],
            isCustomCell: true,
            isSortable: true,
            width: '170px',
            isVisible: true,
            order: 2,
          },
          {
            field: ColumnField.BILL_TO,
            label: 'Customer',
            sortConfig: [
              {
                label: 'Customer',
                dataIndex: 'providerDetails.billToName',
              },
            ],
            filters: ['Bill To'],
            isCustomCell: true,
            isSortable: true,
            width: '200px',
            isVisible: true,
            order: 3,
          },
          {
            field: ColumnField.TMW_AND_ORDER,
            label: 'TMW / Order',
            sortConfig: [
              {
                label: 'TMW Number',
                dataIndex: 'providerDetails.alternateIds.where-identifierType=tmwNumber.identifierValue',
              },
              {
                label: 'Order Number',
                dataIndex: 'providerDetails.alternateIds.where-identifierType=orderNumber.identifierValue',
              },
            ],
            filters: ['TMW', 'Order'],
            isCustomCell: true,
            isSortable: true,
            width: '150px',
            isVisible: true,
            order: 4,
          },
          {
            field: ColumnField.LOADED_MILES,
            label: 'Loaded Miles',
            sortConfig: [
              {
                label: 'Loaded Miles',
                dataIndex: 'distanceMiles-distanceMiles',
                emitSort: true,
              },
            ],
            isCustomCell: true,
            isSortable: true,
            isVisible: true,
            width: '130px',
            order: 5,
          },
          {
            field: ColumnField.PRICE_MAX_BUY_REVENUE,
            label: 'Price / Rev',
            sortConfig: [
              {
                label: 'Price',
                dataIndex: 'price-price',
                emitSort: true,
              },
              {
                label: 'Revenue',
                dataIndex: 'revenue-revenue',
                emitSort: true,
              },
            ],
            filters: ['Price', 'Max Buy', 'Revenue'],
            isCustomCell: true,
            isSortable: true,
            width: '150px',
            isVisible: true,
            order: 6,
          },
          {
            field: ColumnField.BIDS,
            label: 'Bids',
            sortConfig: [
              {
                label: 'Bids',
                dataIndex: 'bidDetails.lowestOffer',
              },
            ],
            filters: ['Highest Bid'],
            isCustomCell: true,
            isSortable: false,
            width: '180px',
            isVisible: true,
            order: 7,
          },
        ],
        configOptions: {
          globalFilter: false,
          reorderColumns: false,
          columnVisibility: false,
          reorderRows: false,
          showBulkActions: false,
          sortColumns: false,
          scrollable: false,
          checkBoxSelection: false,
          filterLocally: false,
          showTopPaginator: true,
          showBottomPaginator: false,
          isLaneRate: false,
          bulkActions: BulkActionPage.CARRIER_SEARCH,
          pageAmount: 100,
        },
      },
    });
  }
  return tabs;
}

export interface RecommendedData {
  loads: PaginatedData<LoadsServiceLoad>;
  trucks: { [loadId: string]: string[] };
}

export const carrierAdvancedSearchConfigOptions: DataTableConfig = {
  globalFilter: false,
  reorderColumns: false,
  columnVisibility: true,
  reorderRows: false,
  showBulkActions: true,
  sortColumns: false,
  scrollable: false,
  checkBoxSelection: true,
  filterLocally: false,
  showTopPaginator: true,
  showBottomPaginator: false,
  isLaneRate: false,
  bulkActions: BulkActionPage.SEARCH,
  pageAmount: 25,
};

export const carrierAdvancedSearchFilters = (data: {
  brokers: Observable<{ id: string; label: string }[]>;
}): ISearchFilter[] => [
  new AppointmentSetFilter(),
  new BillToFilter(),
  new BookedByBrokerFilter(data.brokers),
  new BrokerFilter(data.brokers),
  new BrokerTeamFilter(),
  new CarrierDOTFilter(),
  // new CommodityFilter(), // Not being used right now, but will later
  new CXPriorityFilter(),
  new DeliveryDateFilter(),
  new DestinationFilter(),
  new DistanceFilter(),
  new DriverFilter(),
  new DropoffRequirementsFilter(),
  new EquipmentFilter(),
  new HazmatFilter(),
  // new HighestBidFilter(), // Not being used right now, but will later
  new LoadStatusFilter(),
  new MaxBuyFilter(),
  new OrderFilter(),
  new OrderTypeFilter(),
  new OriginFilter(),
  new PickupDateFilter(),
  new PickupRequirementsFilter(),
  new PriceFilter(),
  new PriorityFilter(),
  new ReceiverFilter(),
  new IBRegionFilter(),
  new OBRegionFilter(),
  new RevenueFilter(),
  new ShipperFilter(),
  new TMWFilter(),
  new TruckFilter(),
  new WeightFilter(),
  new CXRIdFilter(),
];
