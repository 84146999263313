import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckAccountGuard } from '@haulynx/guards';
import { AccountComponent } from './account.component';

const routes: Routes = [
  {
    path: '',
    component: AccountComponent,
    canActivate: [CheckAccountGuard],
    children: [
      {
        path: 'action',
        loadChildren: () => import('./containers/action/action.module').then((m) => m.ActionModule),
      },
      {
        path: 'carrier',
        loadChildren: () => import('./containers/carrier/carrier.module').then((m) => m.CarrierModule),
      },
      {
        path: 'create',
        loadChildren: () =>
          import('./containers/create-account/create-account.module').then((m) => m.CreateAccountModule),
      },
      {
        path: 'login',
        loadChildren: () => import('./containers/login/login.module').then((m) => m.LoginModule),
      },
      {
        path: 'reset-password',
        loadChildren: () =>
          import('./containers/reset-password/reset-password.module').then((m) => m.ResetPasswordModule),
      },
      {
        path: 'terms-of-service',
        loadChildren: () =>
          import('./containers/terms-of-service/terms-of-service.module').then((m) => m.TermsOfServiceModule),
      },
      {
        path: 'verify',
        loadChildren: () => import('./containers/verify-email/verify-email.module').then((m) => m.VerifyEmailModule),
      },
      { path: '', pathMatch: 'full', redirectTo: 'login' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AccountRoutingModule {}
