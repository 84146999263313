import { Pipe, PipeTransform } from '@angular/core';
import { LoadsServiceLoad, LoadsServiceLoadLocation, User } from '@haulynx/types';
import { get } from 'lodash';

@Pipe({ name: 'canViewFacility' })
export class CanViewFacilityPipe implements PipeTransform {
  transform(load: LoadsServiceLoad, user: User, location: LoadsServiceLoadLocation): boolean {
    const customerNumber = get(location, 'customer.number', null);

    if (!customerNumber) {
      return false;
    }

    const brokerId = get(user, 'broker.id', null);

    if (brokerId) {
      return true;
    }

    const carrierId = get(user, 'carrier.id', null);
    const assignedCarrierId = get(load, 'carrier.id', null);

    return !!assignedCarrierId && !!carrierId === !!assignedCarrierId;
  }
}
