import { Injectable } from '@angular/core';
import { IColumns, User } from '@haulynx/types';
import { Store } from '@ngrx/store';
import { List } from 'immutable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppModel } from '../../main-store/app-model';
import { AppState } from '../../main-store/app.reducers';
import { UsersActions } from './users.actions';
import { isLoadingUserForm, selectedUser, userFormHttpStatus, userSearchSelector } from './users.selectors';
import { UserFormHttpStatus } from './users.state';

@Injectable({
  providedIn: 'root',
})
export class UsersModel {
  entities$: Observable<List<User>> = this.store.select(userSearchSelector.getEntities);
  isLoading$: Observable<boolean> = this.store.select(userSearchSelector.getLoading);
  isLoadingUserForm$: Observable<boolean> = this.store.select(isLoadingUserForm);
  userFormHttpStatus$: Observable<UserFormHttpStatus> = this.store.select(userFormHttpStatus);
  selectedUser$: Observable<User> = this.store.select(selectedUser);

  userColumns$: Observable<IColumns[]> = this.appModel.user$.pipe(
    map((user): IColumns[] => {
      const columns: IColumns[] = [
        {
          label: 'Name',
          dataIndex: 'name',
          width: '200px',
        },
        {
          label: 'Email',
          dataIndex: 'email',
          width: '200px',
        },
        {
          label: 'Phone',
          dataIndex: 'phone',
          width: '200px',
          isCustomCell: true,
        },
      ];

      if (user.isHaulynxAdmin) {
        columns.push({
          label: 'Company',
          dataIndex: 'company',
          isCustomCell: true,
          width: '300px',
        });
      }
      if (user.carrier || user.isHaulynxAdmin) {
        if (user.isCompanyAdmin || user.isHaulynxAdmin) {
          columns.push({
            label: 'Company Admin',
            dataIndex: 'isCompanyAdmin',
            isCustomCell: true,
            isSortable: false,
            width: '150px',
          });
        }

        columns.push({
          label: 'Is Driver',
          dataIndex: 'isDriver',
          isCustomCell: true,
          isSortable: false,
          width: '150px',
        });
      }

      if (user.isCompanyAdmin) {
        columns.push({
          label: 'Verified',
          dataIndex: 'isVerified',
          isCustomCell: true,
          isSortable: false,
          width: '150px',
        });
      }

      if (user.carrier) {
        columns.push({
          label: 'Can Add Trailers',
          dataIndex: 'trailerCreationEnabled',
          isCustomCell: true,
          isSortable: false,
          width: '200px',
        });
      }

      if (user.broker) {
        columns.push({
          label: 'Team',
          dataIndex: 'team',
          width: '200px',
        });

        columns.push({
          label: 'Job Title',
          dataIndex: 'jobTitle',
          width: '200px',
        });

        columns.push({
          label: 'Manager',
          dataIndex: 'manager',
          width: '200px',
        });
      }

      columns.push({
        label: 'Options',
        dataIndex: 'options',
        isCustomCell: true,
        isSortable: false,
        width: '10px',
      });
      return columns;
    })
  );

  constructor(private store: Store<AppState>, private appModel: AppModel) {}

  search(filter?) {
    this.store.dispatch(UsersActions.search(filter));
  }

  createUser(user: Partial<User>) {
    this.store.dispatch(UsersActions.createUser(user));
  }

  validateDriversLicense(data: unknown) {
    this.store.dispatch(UsersActions.validateDriversLicense(data));
  }

  getUser(userId: string): void {
    this.store.dispatch(UsersActions.getUser(userId));
  }

  clearSelectedUser(): void {
    this.store.dispatch(UsersActions.clearSelectedUser());
  }

  updateUser(params: { userId: string; userInfo: Partial<User> }): void {
    this.store.dispatch(UsersActions.updateUser(params));
  }
}
