<app-load-choose
  data-test="choseTemplate"
  (selectTemplate)="selectTemplate($event)"
  [templates]="commonEntities.templates.entities$ | async"
  *ngIf="showTemplateChoose"
>
</app-load-choose>

<app-spinner [active]="loadDetailsModel.form.isLoading$ | async"></app-spinner>

<form data-test="loadForm" class="form" *ngIf="!showTemplateChoose && loadForm" [formGroup]="loadForm">
  <div class="app-load-form">
    <div class="app-load-form__locations">
      <ng-container
        formArrayName="locations"
        *ngFor="let location of locations; let i = index; let f = first; let l = last"
      >
        <div class="location-form {{ location.get('locationType')?.value }}">
          <div class="location-form__title">
            <span *ngIf="f || l; else defaultLocationTitle">
              {{ location.get('locationType').value === 'pickup' ? 'ORIGIN' : 'DESTINATION' }}
            </span>

            <ng-template #defaultLocationTitle>
              <span>STOP</span>

              <mat-icon
                (click)="removeStop(i)"
                [hidden]="location.disabled"
                class="location-form__remove"
                svgIcon="haulynx-trash-gray"
              >
              </mat-icon>
            </ng-template>
          </div>
          <ng-container [formGroupName]="i">
            <div class="location-form__field">
              <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
                <app-address-field
                  placeholder="Choose Location"
                  formControlName="location"
                  icon="haulynx-crosshair"
                  required
                  (onPlaceChange)="selectLocation(i, $event)"
                  (onIconClick)="setCurrentUserLocation(i)"
                >
                </app-address-field>
                <div class="location-form__field__location_prefix" matPrefix>
                  <mat-icon svgIcon="haulynx-location"></mat-icon>
                </div>

                <mat-error *ngIf="location.get('location').errors?.required">The location is required</mat-error>
              </mat-form-field>

              <div class="location-form__field__location-actions">
                <ng-container *ngIf="!location.disabled; else choseFavoriteDisable">
                  <mat-icon (click)="choseFavoriteAddress(i)" data-test="origin-favorite-locations">
                    format_list_bulleted
                  </mat-icon>
                </ng-container>
                <ng-template #choseFavoriteDisable>
                  <mat-icon data-test="destination-favorite-locations"> format_list_bulleted </mat-icon>
                </ng-template>

                <mat-icon
                  class="favorite-address"
                  (click)="
                    !location.get('location').value?.id && location.get('location').value?.address
                      ? saveFavoriteAddress(location.value, i)
                      : null
                  "
                  data-test="favorite-address-button"
                >
                  {{ location.get('location').value?.id ? 'star' : 'star_border' }}
                </mat-icon>
              </div>
            </div>

            <div class="location-form__field location-form__field--direction-column">
              <div class="location-form__field--date">
                <label>{{ showWaitingTime[i] ? 'Early' : '' }} Date </label>
                <app-datetime-picker
                  class="app-form-field"
                  [timeZone]="location.get('location')?.value?.timeZone"
                  formControlName="date"
                >
                </app-datetime-picker>
                <div class="location-form__field__swap-time">
                  <mat-icon
                    *ngIf="!location.disabled"
                    (click)="showWaitingTime[i] ? removeWaitingTime(i) : addWaitingTime(i)"
                    data-test="add-or-close-date"
                  >
                    {{ showWaitingTime[i] ? 'close' : 'add' }}
                  </mat-icon>
                </div>
              </div>
              <div class="location-form__field--date" *ngIf="showWaitingTime[i]">
                <label class="location-form__field--date">Late Date</label>
                <app-datetime-picker
                  class="app-form-field"
                  [timeZone]="location.get('location')?.value?.timeZone"
                  formControlName="waitDate"
                ></app-datetime-picker>
              </div>

              <mat-radio-group *ngIf="!l && !f" formControlName="locationType">
                <mat-radio-button [value]="'pickup'"> Pick-Up </mat-radio-button>
                <mat-radio-button [value]="'dropoff'"> Drop-Off </mat-radio-button>
              </mat-radio-group>
            </div>

            <div class="location-form__field__additional-info">
              <div *ngIf="l" class="location-form__field__additional-info--post-truck">
                <mat-checkbox [formControl]="loadForm.controls.postTruck">Post Truck</mat-checkbox>
              </div>
              <div
                class="location-form__field__additional-info--show"
                (click)="showAdditionalInfo(i)"
                data-test="additional-location-info"
              >
                Additional Info
                <mat-icon>{{ showLocationMoreDetails[i] ? 'expand_less' : 'expand_more' }}</mat-icon>
              </div>
            </div>

            <ng-container *ngIf="showLocationMoreDetails[i]">
              <div class="location-form__field">
                <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
                  <mat-label>
                    {{ location.get('locationType').value === 'pickup' ? 'Shipper' : 'Receiver' }}
                  </mat-label>
                  <input
                    matInput
                    placeholder="{{ location.get('locationType').value === 'pickup' ? 'Shipper' : 'Receiver' }}"
                    formControlName="customerId"
                  />
                </mat-form-field>
              </div>

              <div class="app-load-form__field">
                <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
                  <mat-label> Notes </mat-label>
                  <textarea matInput placeholder="Notes" formControlName="specialNotes"></textarea>
                </mat-form-field>
              </div>
            </ng-container>
          </ng-container>
        </div>

        <ng-container *ngIf="f && !location.disabled">
          <span class="location-form__add-stop" (click)="addStop(i)" data-test="add-stop"> ADD STOP + </span>
        </ng-container>
      </ng-container>

      <div
        *ngIf="
          loadForm.get('locations').hasError('chronological') || loadForm.get('locations').hasError('pickupAndDropoff')
        "
      >
        <div class="col-lg-6">
          <div class="alert alert-danger" *ngIf="loadForm.get('locations').hasError('chronological')" role="alert">
            Load times are not chronological
          </div>
          <div class="alert alert-danger" *ngIf="loadForm.get('locations').hasError('pickupAndDropoff')" role="alert">
            You need at least one pickup and one dropoff location
          </div>
        </div>
      </div>
    </div>

    <div class="app-load-form__information">
      <div class="app-load-form__assign" data-test="assign-load">
        <span class="app-load-form__title"> ASSIGN LOAD </span>

        <div class="app-load-form__assign-content">
          <div
            class="app-load-form__field"
            *ngIf="!(loadDetailsModel.form.isLoading$ | async)"
            data-test="driver-drop-down"
          >
            <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
              <mat-label> Driver </mat-label>
              <app-drop-down
                placeholder="{{ !!loadForm.get('driver').value ? '' : 'Unassigned' }}"
                formControlName="driver"
                [data]="drivers"
                displayLabel="name"
                key="id"
              >
              </app-drop-down>
            </mat-form-field>
          </div>

          <div class="app-load-form__field">
            <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
              <mat-label> Truck </mat-label>
              <app-drop-down
                placeholder="Select Truck"
                formControlName="truck"
                [data]="devices$ | async"
                key="id"
                displayLabel="label"
              >
              </app-drop-down>
            </mat-form-field>
          </div>

          <div class="app-load-form__field">
            <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
              <mat-label> Trailer </mat-label>
              <app-drop-down
                placeholder="Select Trailer"
                formControlName="trailerId"
                [data]="trailers"
                key="id"
                displayLabel="trailerNumber"
              >
              </app-drop-down>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="app-load-form__info">
        <span class="app-load-form__title"> LOAD INFO </span>

        <div class="app-load-form__info-content">
          <div class="app-load-form__field bill-to">
            <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
              <mat-label> Bill-To </mat-label>
              <input matInput placeholder="Bill to" formControlName="customer" />
            </mat-form-field>
          </div>

          <div class="app-load-form__field equipment-type">
            <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
              <mat-label> Equipment </mat-label>
              <app-drop-down
                placeholder="Equipment"
                formControlName="equipmentType"
                [data]="equipmentTypes"
                key="text"
                displayLabel="text"
              >
              </app-drop-down>

              <!-- Todo: Need to implement temperature for specific equipment type -->
              <!--            <div matSuffix *ngIf="showTemperatureEquipmentTypes.includes(loadForm.get('equipmentType').value)">-->
              <!--              <input matInput placeholder="Temperature" type="number" formControlName="temperature">-->
              <!--              <div>&deg;F</div>-->
              <!--            </div>-->
            </mat-form-field>
          </div>

          <div class="app-load-form__field price">
            <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
              <mat-label> Price </mat-label>

              <input matInput placeholder="Price" type="number" formControlName="price" />
              <div matPrefix>$ &nbsp;</div>
            </mat-form-field>
          </div>

          <div class="app-load-form__field additional-info" (click)="showMoreInfo(!showMoreInfoAdvanced)">
            <div class="additional-info--advanced">
              <span data-test="additional-load-info"> Additional Info </span>
              <mat-icon>
                {{ showMoreInfoAdvanced ? 'expand_less' : 'expand_more' }}
              </mat-icon>
            </div>
          </div>
        </div>
        <ng-container *ngIf="showMoreInfoAdvanced">
          <div class="app-load-form__info-content">
            <div class="app-load-form__field bol">
              <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
                <mat-label> BOL# </mat-label>
                <input matInput placeholder="BOL #" maxlength="40" formControlName="billOfLading" />
                <mat-hint align="end">{{ loadForm.get('billOfLading').value?.length || 0 }} / 40</mat-hint>
              </mat-form-field>
            </div>

            <div class="app-load-form__field po-number">
              <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
                <mat-label> PO#</mat-label>
                <input matInput placeholder="PO#" formControlName="poNumber" />
              </mat-form-field>
            </div>

            <div class="app-load-form__field load-weight">
              <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
                <mat-label> Weight </mat-label>
                <input matInput placeholder="Weight" type="number" formControlName="loadWeight" />
                <div matSuffix>lbs</div>
              </mat-form-field>
            </div>

            <div class="app-load-form__field commodity">
              <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
                <mat-label> Commodity </mat-label>
                <input matInput placeholder="Commodity" formControlName="commodity" />
              </mat-form-field>
            </div>

            <div class="app-load-form__field quantity">
              <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
                <mat-label> Quantity </mat-label>
                <input matInput placeholder="Quantity" formControlName="quantity" type="number" />
              </mat-form-field>
            </div>

            <div class="app-load-form__field packing-type">
              <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
                <mat-label> Packing Type </mat-label>
                <input matInput placeholder="Packing Type" formControlName="packingType" />
              </mat-form-field>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="app-load-form__actions" *ngIf="!(loadDetailsModel.form.isLoading$ | async)">
        <action-buttons
          [buttons]="buttons$ | async"
          [disableButtons]="disableBtns$ | async"
          (onClick)="action($event)"
          data-test="action-button"
        >
        </action-buttons>
      </div>
    </div>
  </div>
</form>
