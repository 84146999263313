import { ActionReducer, ActionReducerMap } from '@ngrx/store';
import { InjectionToken, Provider } from '@angular/core';
import { USER_PROVIDED_EFFECTS } from '@ngrx/effects';
import { IAsyncEntityState } from '@haulynx/types';
import { ContractsEntityService } from './contracts-entity.service';

export const CONTRACTS_ENTITY_REDUCERS_TOKEN = new InjectionToken<ActionReducerMap<IAsyncEntityState<any>>>(
  'contracts reducers'
);

function getContractsEntityReducersFactory(
  contractsEntityService: ContractsEntityService
): ActionReducer<IAsyncEntityState<any>> {
  return contractsEntityService.getReducers();
}

const contractsEntityReducerProvider: Provider = {
  provide: CONTRACTS_ENTITY_REDUCERS_TOKEN,
  useFactory: getContractsEntityReducersFactory,
  deps: [ContractsEntityService],
};

const contractsEntityEffectProvider: Provider = {
  provide: USER_PROVIDED_EFFECTS,
  multi: true,
  useValue: [ContractsEntityService],
};

export const contractsStateProviders: Provider[] = [contractsEntityReducerProvider, contractsEntityEffectProvider];
