import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  AnalyticsService,
  AssignLoadCarrierFormVm,
  AssignmentSectionForm,
  CarrierInfoSectionForm,
} from '@haulynx/services';
import { AppModel } from '@haulynx/store';
import {
  ANALYTICS_EVENT,
  AssignDriverForm,
  AssignLoadCarrierForm,
  AssignLoadCarrierFormMode,
  AssignLoadEditForm,
  BookStatus,
  Carrier,
  CarrierAdmin,
  CarrierAssets,
  CarrierContacts,
  CarrierContactTypes,
  CarrierSearchResult,
  ComplianceState,
  DispatchForm,
  Driver,
  DriverAssignment,
  FeatureFlag,
  FFState,
  LoadCarrierContact,
  LoadIdentifierType,
  LoadOverviewTask,
  LoadsServiceLoad,
  LoadsServiceLoadStatus,
  OrderInfoForm,
  PaymentsTypeForm,
  paymentTypes,
  RateConEmail,
  TrackingType,
  Trailer,
  TrailerAssignment,
  TrailerOwnerType,
  Truck,
  TruckAssignment,
} from '@haulynx/types';
import { aliveWhile, canBookLoad, getLoadsServiceLoadAlternateId, listToArray, joinNoteTexts } from '@haulynx/utils';
import { List } from 'immutable';
import { get, isEmpty, omit } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { AddDriverComponent } from '../pay-line-items/add-driver/add-driver.component';
import { AddPaymentTypeComponent } from '../pay-line-items/add-payment-type/add-payment-type.component';
import { AddTrailerComponent } from '../pay-line-items/add-trailer/add-trailer.component';
import { AddTruckComponent } from '../pay-line-items/add-truck/add-truck.component';
import { ConfirmBookLoadComponent } from '../pay-line-items/confirm-book-load/confirm-book-load.component';
import { SwapTrailerComponent } from '../swap-trailer/swap-trailer.component';
import { ConfirmBookMissionComponent } from '../pay-line-items/confirm-book-mission/confirm-book-mission.component';
import { TruckInfoSectionComponent } from '../pay-line-items/truck-info-section/truck-info-section.component';

@Component({
  selector: 'app-assign-load-carrier-form-v2',
  templateUrl: './assign-load-carrier-form-v2.component.html',
  styleUrls: ['./assign-load-carrier-form-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssignLoadCarrierFormComponentV2 implements OnChanges, OnInit, OnDestroy {
  @Input() bidId: string = null;
  @Input() carriers: Carrier[] | List<Carrier> = [];
  @Input() carrierAdmin: CarrierAdmin;
  @Input() load: LoadsServiceLoad;
  @Input() loadsServiceLoad: LoadsServiceLoad; // this load will change dynamically
  @Input() featureFlagState: FFState;
  @Input() formData: AssignLoadCarrierForm;
  @Input() isLoading = false;
  @Input() carrierAssets: CarrierAssets;
  @Input() usxiTrailers;
  @Input() brokers: List<{ id: string; label: string }>;
  @Input() isLoadingCarrierAssets = false;
  @Input() isLoadingCarriers = false;
  @Input() truckToPopulate: string;
  @Input() trailerToPopulate: string;
  @Input() driverToPopulate: string;
  @Input() isLoadingCreateEntity = false;
  @Input() onCarrierBookError = false;
  @Input() formMode: AssignLoadCarrierFormMode = AssignLoadCarrierFormMode.NEW;
  @Input() brokerEmail: string;
  @Input() assignmentLoading = false;
  @Input() dispatchLoading = false;
  @Input() paymentLoading = false;
  @Input() isLoadLocked = false;
  // Used to highlight incomplete form
  @Input() task: LoadOverviewTask = LoadOverviewTask.ALL_TASKS_COMPLETE;
  @Input() carrierContact: LoadCarrierContact[];
  @Input() carrierComplianceState: ComplianceState;
  @Input() isCarrierComplianceLoadLock: boolean;
  @Input() carrierComplianceLoading: boolean;
  @Output() searchCarrier = new EventEmitter<string>();
  @Output() selectCarrier = new EventEmitter<{ carrierDot: string; getCarriers: boolean }>();
  @Output() addDriver = new EventEmitter<DriverAssignment>();
  @Output() addTruck = new EventEmitter<TruckAssignment>();
  @Output() addTrailer = new EventEmitter<TrailerAssignment>();
  @Output() bookForCarrier = new EventEmitter<Partial<AssignLoadCarrierForm>>();
  @Output() bookMission = new EventEmitter<Partial<AssignLoadCarrierForm>>();
  @Output() updateDispatch = new EventEmitter<DispatchForm>();
  @Output() updateAssignment = new EventEmitter<{
    assignmentValue?: AssignmentSectionForm;
    carrierContact?: CarrierInfoSectionForm;
    upadateCarrier?: boolean;
  }>();
  @Output() updatePayment = new EventEmitter<FormGroup>();
  //@Output() offer = new EventEmitter<AssignLoadCarrierForm>();
  @Output() addPayType = new EventEmitter<{ index: number; payment: PaymentsTypeForm }>();
  @Output() editPayType = new EventEmitter<{ index: number; payment: Partial<PaymentsTypeForm> }>();
  @Output() deletePayType = new EventEmitter<{ index: number; payment: PaymentsTypeForm }>();
  @Output() createCarrierCompliance = new EventEmitter<Carrier>();

  @ViewChild('truckInput', { static: false }) truckInput: TruckInfoSectionComponent;

  private alive = aliveWhile();
  form: FormGroup;
  carrierAdmin$ = new BehaviorSubject({});
  carrier$ = new BehaviorSubject([]);
  trucks$ = new BehaviorSubject<Truck[]>([]);
  brokers$ = new BehaviorSubject([]);
  drivers$ = new BehaviorSubject<Driver[]>([]);
  trailers$ = new BehaviorSubject<Trailer[]>([]);
  carriers$ = new BehaviorSubject([]);
  rateConEmails$ = new BehaviorSubject([]);
  price$ = new BehaviorSubject(0);
  revenue$ = new BehaviorSubject(0);
  total$ = new BehaviorSubject(0);
  isLoadingTrucks$ = new BehaviorSubject(false);
  isLoadingTrailers$ = new BehaviorSubject(false);
  isLoadingDrivers$ = new BehaviorSubject(false);
  paymentFormData$ = new BehaviorSubject([]);
  payLineFeatureFlag = FeatureFlag.PAY_LINE_ITEMS;
  rateconFeatureFlag = FeatureFlag.RATECON;
  complianceFeatureFlag = FeatureFlag.CARRIER_COMPLIANCE;
  truckAssignment: TruckAssignment;
  trailerAssignment: TrailerAssignment;
  driverAssignment: DriverAssignment;
  isDisableBookCarrierButton = false;
  assignLoadCarrierFormMode = AssignLoadCarrierFormMode;
  formSubmitted = false;
  assignmentInfo$ = new BehaviorSubject<Partial<AssignDriverForm>>({});
  canEdit = true;
  canEditPayment = true;
  LoadOverviewTask = LoadOverviewTask;
  showFooterButtons = true;
  showIndividualSaveButtons = false;
  carrierCompliant = false;
  truckBeforeEdit: Truck;
  headHaul;
  backHaul;

  get orderInfo(): FormGroup {
    return this.form.get('orderInfo') as FormGroup;
  }

  get editLoad(): FormGroup {
    return this.form.get('editLoad') as FormGroup;
  }

  get dispatchInfo(): FormGroup {
    return this.form.get('dispatchInfo') as FormGroup;
  }

  get assignmentInfo(): FormGroup {
    return this.form.get('assignmentInfo') as FormGroup;
  }

  get carrierInfo(): FormGroup {
    return this.form.get('carrierInfo') as FormGroup;
  }

  get payments(): FormArray {
    return this.form.get('payments') as FormArray;
  }

  get showFooter(): boolean {
    return (
      (this.form.get('dispatchInfo').errors?.locationIsEmpty ||
        this.form.get('dispatchInfo').errors?.timeIsEmpty ||
        this.form.get('assignmentInfo')?.get('driverId')?.errors?.required ||
        this.form.get('assignmentInfo')?.get('truckId')?.errors?.required ||
        this.form.get('assignmentInfo')?.get('phone')?.errors?.required ||
        this.form.get('assignmentInfo')?.get('email')?.errors?.required) &&
      this.formSubmitted
    );
  }

  constructor(
    private appModel: AppModel,
    private assignLoadCarrierFormVm: AssignLoadCarrierFormVm,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private analyticsService: AnalyticsService,
    private snackBar: MatSnackBar
  ) {
    this.form = this.assignLoadCarrierFormVm.create();
  }

  onSearchCarrier(keyword: string): void {
    this.truckInput.assignmentForm.patchValue({
      truckId: null,
      trailerId: null,
      driverId: null,
      phone: null,
      email: null,
      trackingType: null,
      secondaryDriver: null,
      secDriverPhone: null,
      secDriverEmail: null,
    });
    this.truckInput.selectedDriver = null;
    this.truckInput.secSelectedDriver = null;
    this.truckInput.driverList.display = null;
    this.truckInput.assignmentForm.controls['trackingType'].updateValueAndValidity();
    this.searchCarrier.emit(keyword);
  }

  onTrackingChange(): void {
    this.formSubmitted = false;
    const formData: AssignLoadCarrierForm = this.form.getRawValue();
    this.analyticsService.logEvent(ANALYTICS_EVENT.LOAD_TRACKING_TYPE_ADDED, {
      loadId: this.load.id,
      tmwNumber: getLoadsServiceLoadAlternateId(this.loadsServiceLoad, LoadIdentifierType.TMW_NUMBER),
      trackingType: formData.assignmentInfo.trackingType,
      broker: formData.orderInfo.brokerId,
    });
  }

  onSave(event: Event): void {
    this.formSubmitted = true;
    event.stopPropagation();

    if (this.form.valid) {
      const formData: Partial<AssignLoadCarrierForm> = this.form.getRawValue();
      const carrierDot = get(formData, 'carrierInfo.carrier', null);
      const selectedCarrier = this.carriers.find((carrier: Carrier) => carrier.dot === carrierDot);
      const carrierName = get(selectedCarrier, 'name', null);
      const totalAmount = this.total$.value;
      const sortedMissionLoads = [];
      const loadId = this.load.id;
      let isHeadHaul = false;
      let isBackHaul = false;
      if (this.load.mission?.loads && this.load.mission?.loads.length > 0) {
        for (const iLoad of this.load.mission.loads) {
          sortedMissionLoads.push(iLoad);
        }
        this.sortMissionLoads(sortedMissionLoads);
        this.headHaul = sortedMissionLoads[0];
        this.backHaul = sortedMissionLoads[sortedMissionLoads.length - 1];
        isHeadHaul = this.headHaul.id === this.load.id ? true : false;
        isBackHaul = this.backHaul.id === this.load.id ? true : false;
      }

      if (
        (this.headHaul?.bookStatus === BookStatus.BOOKABLE ||
          this.headHaul?.bookStatus === BookStatus.VIEWABLE ||
          this.backHaul?.bookStatus === BookStatus.BOOKABLE ||
          this.backHaul?.bookStatus === BookStatus.VIEWABLE) &&
        ((isHeadHaul && this.backHaul?.bookStatus !== BookStatus.BOOKED) ||
          (isBackHaul && this.headHaul?.bookStatus !== BookStatus.BOOKED)) &&
        this.featureFlagState[FeatureFlag.MISSIONS]
      ) {
        this.dialog
          .open(ConfirmBookMissionComponent, {
            data: { carrierDot, loadId, headHaul: this.headHaul, backHaul: this.backHaul },
            width: '365px',
          })
          .afterClosed()
          .pipe(takeUntil(this.alive))
          .subscribe((confirmation: boolean) => {
            if (confirmation) {
              this.bookLoad(formData, carrierName, carrierDot, totalAmount);
            }
          });
      } else {
        this.bookLoad(formData, carrierName, carrierDot, totalAmount);
      }
    }
  }

  private sortMissionLoads(loads: LoadsServiceLoad[]): LoadsServiceLoad[] {
    return loads.sort(
      (first, second) => 0 - (first.locations[0]?.appointmentStart <= second.locations[0]?.appointmentStart ? 1 : -1)
    );
  }

  bookLoad(formData, carrierName, carrierDot, totalAmount) {
    if (this.featureFlagState[this.payLineFeatureFlag]) {
      if (!formData.assignmentInfo.driverId && formData.assignmentInfo.secDriverId) {
        this.snackBar.open('Please select primary driver before secondary driver', 'Close');
      } else {
        this.dialog
          .open(ConfirmBookLoadComponent, {
            data: { carrierName, carrierDot, totalAmount },
            width: '330px',
          })
          .afterClosed()
          .pipe(takeUntil(this.alive))
          .subscribe((confirmation: boolean) => {
            if (confirmation) {
              this.bookLoadForCarrier(formData);
            }
          });
      }
    } else {
      if (this.featureFlagState[this.payLineFeatureFlag]) {
        this.dialog
          .open(ConfirmBookLoadComponent, {
            data: { carrierName, carrierDot, totalAmount },
            width: '330px',
          })
          .afterClosed()
          .pipe(takeUntil(this.alive))
          .subscribe((confirmation: boolean) => {
            if (confirmation) {
              this.bookLoadForCarrier(formData);
            }
          });
      } else {
        this.bookLoadForCarrier(formData);
      }
    }
  }

  saveAssignment(): void {
    if (this.form.get('assignmentInfo').valid) {
      if (!this.assignmentInfo.get('driverId').value && this.assignmentInfo.get('secDriverId').value) {
        this.form.get('assignmentInfo').get('driverId').setErrors({ required: true });
        this.formSubmitted = true;
      } else {
        this.form.disable();
        this.updateAssignment.emit({
          assignmentValue: this.assignmentInfo.value,
          carrierContact: {
            carrier: this.load.carrier.dot,
            mcNumber: this.load.carrier.mcNumber,
            phone: this.carrierContact?.[0]?.contactPhone,
            email: this.carrierContact?.[0]?.contactEmail,
            contact: this.carrierContact?.[0]?.contactName,
          },
          upadateCarrier: false,
        });
      }
    } else {
      this.formSubmitted = true;
    }
  }

  saveCarrierInstance(): void {
    this.form.disable();
    this.updateAssignment.emit({
      assignmentValue: {
        trackingType: this.loadsServiceLoad.trackingType,
        truckId: this.loadsServiceLoad.truck?.id,
        driverId: this.loadsServiceLoad.drivers?.[0]?.id,
        trailerOwner: this.loadsServiceLoad.trailerOwner,
        trailerId: this.loadsServiceLoad.trailers?.[0]?.id,
        phone: this.loadsServiceLoad.drivers?.[0]?.id,
        email: this.loadsServiceLoad.drivers?.[0]?.email,
        equipment: null,
        secDriverId: this.loadsServiceLoad.drivers?.[1]?.id,
        secEmail: this.loadsServiceLoad.drivers?.[1]?.email,
        secPhone: this.loadsServiceLoad.drivers?.[1]?.phone,
      },
      carrierContact: this.carrierInfo.value,
      upadateCarrier: true,
    });
  }

  saveDispatch(): void {
    this.updateDispatch.emit(this.dispatchInfo.value);
  }

  savePayment(): void {
    this.updatePayment.emit(this.payments.value);
  }

  ngOnInit(): void {
    this.form
      .get('carrierInfo')
      .get('carrier')
      .valueChanges.pipe(takeUntil(this.alive), distinctUntilChanged())
      .subscribe((carrierDot) => {
        this.selectCarrier.emit(carrierDot);

        if (!carrierDot && this.load?.bookStatus !== BookStatus.BOOKED) {
          this.rateConEmails$.next([this.brokerEmail]);
        }
      });
  }

  onAddPayType(): void {
    this.dialog
      .open(AddPaymentTypeComponent, {
        data: { paymentTypes, load: this.loadsServiceLoad },
        width: '650px',
        panelClass: 'add-payment__panel',
        restoreFocus: false,
        autoFocus: false,
      })
      .afterClosed()
      .pipe(takeUntil(this.alive))
      .subscribe((paymentsForm: PaymentsTypeForm) => {
        if (paymentsForm) {
          const order = getLoadsServiceLoadAlternateId(this.loadsServiceLoad, LoadIdentifierType.ORDER_NUMBER);
          const index = this.payments.length;
          const paymentItem = { ...paymentsForm, orderNumber: order };

          this.addPayType.emit({ index, payment: paymentItem });
        }
      });
  }

  onSwapTrailer(): void {
    this.dialog
      .open(SwapTrailerComponent, {
        data: {
          loadsServiceLoad: this.loadsServiceLoad,
        },
        maxWidth: '100vw',
        width: '1050px',
        height: '850px',
        panelClass: 'swap-trailer__panel',
        restoreFocus: false,
        autoFocus: false,
      })
      .afterClosed()
      .pipe(takeUntil(this.alive));
  }

  onDeletePayType(data: { index: number; payment: PaymentsTypeForm }): void {
    this.deletePayType.emit(data);
  }

  onEditPayType(data: { index: number; payment: PaymentsTypeForm }): void {
    this.editPayType.emit(data);
  }

  onNewPayTypeTotal(val: number): void {
    this.total$.next(val);
    this.cd.detectChanges();
  }

  onAddDriver(): void {
    const carrierDot = this.carrierInfo.get('carrier').value;
    const selectedCarrier = this.carriers.find((carrier: Carrier) => carrier.dot === carrierDot);
    const carrierName = get(selectedCarrier, 'name', null);
    const carrierId = get(selectedCarrier, 'id', null);
    const trackingType = this.assignmentInfo?.get('trackingType').value;
    this.isLoadingDrivers$.next(true);

    this.dialog
      .open(AddDriverComponent, {
        data: { carrierDot, trackingType },
      })
      .afterClosed()
      .pipe(takeUntil(this.alive))
      .subscribe((driverAssignment: DriverAssignment) => {
        if (driverAssignment) {
          driverAssignment.carrierId = carrierId ? carrierId : '';
          driverAssignment.carrierDot = carrierDot;
          driverAssignment.carrierName = carrierName ? carrierName : '';
          driverAssignment.name = driverAssignment.name.replace(/\s\s+/g, ' ');

          this.driverAssignment = driverAssignment;
          this.addDriver.emit(driverAssignment);
        } else {
          this.isLoadingDrivers$.next(false);
        }
      });
  }

  onAddTruck(): void {
    const carrierDot = this.carrierInfo.get('carrier').value;
    this.isLoadingTrucks$.next(true);

    this.dialog
      .open(AddTruckComponent, {
        data: { carrierDot },
      })
      .afterClosed()
      .pipe(takeUntil(this.alive))
      .subscribe((truckAssignment: TruckAssignment) => {
        if (truckAssignment) {
          this.truckAssignment = truckAssignment;
          this.addTruck.emit(truckAssignment);
        } else {
          this.isLoadingTrucks$.next(false);
        }
      });
  }

  onAddTrailer(): void {
    const carrierDot = this.carrierInfo.get('carrier').value;
    this.isLoadingTrailers$.next(true);
    // get the current truck selected
    const truck = this.truckInput?.truckList?.currentlyFiltered[0];

    this.truckBeforeEdit = { ...truck };

    this.dialog
      .open(AddTrailerComponent, {
        data: { carrierDot },
      })
      .afterClosed()
      .pipe(takeUntil(this.alive))
      .subscribe((trailerAssignment: TrailerAssignment) => {
        if (trailerAssignment) {
          this.trailerAssignment = trailerAssignment;
          this.addTrailer.emit(trailerAssignment);
        } else {
          this.isLoadingTrailers$.next(false);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const {
      formData,
      trackingTypeChange,
      load,
      carrierAssets,
      brokers,
      carriers,
      loadsServiceLoad,
      carrierAdmin,
      isLoadingCarrierAssets,
      isCarrierComplianceLoadLock,
      onCarrierBookError,
    } = changes;

    if (isCarrierComplianceLoadLock) {
      if (isCarrierComplianceLoadLock.currentValue === true) {
        this.carrierInfo.disable();
      } else if (isCarrierComplianceLoadLock.currentValue === false) {
        this.carrierInfo.enable();
      }
    }

    if (onCarrierBookError?.currentValue) {
      if (this.form.disabled) {
        this.form.enable();
      }
    }

    if (carrierAdmin?.currentValue) {
      setTimeout(() => {
        this.carrierAdmin$.next(carrierAdmin.currentValue);
      });
    }

    if (trackingTypeChange) {
      this.formSubmitted = false;
    }

    if (formData) {
      const { payments = null, ...currentFormData } = formData.currentValue || {};

      if (!isEmpty(currentFormData)) {
        setTimeout(() => {
          this.form.patchValue({ ...currentFormData });
        });
      }

      if (payments) {
        const pay = JSON.parse(JSON.stringify(payments));
        pay.forEach((payment) => {
          if (payment.paymentType.isNegative) {
            payment.amount = -Math.abs(payment.amount);
          }
        });
        this.paymentFormData$.next(pay);
      }
    }

    if (load && load.currentValue) {
      const currentLoad: LoadsServiceLoad = load.currentValue || {};
      this.setLoadDispatch(currentLoad);
      this.price$.next(currentLoad && currentLoad.paymentDetails.price);
      this.revenue$.next(currentLoad && currentLoad.paymentDetails.revenue);
      this.isDisableBookCarrierButton = !canBookLoad(currentLoad.bookStatus);
    }

    if (carrierAssets) {
      const newTrailers = listToArray<Trailer>(carrierAssets.currentValue?.trailers);
      const newDrivers = listToArray<Driver>(carrierAssets.currentValue?.drivers);
      const newTrucks = listToArray<Truck>(carrierAssets.currentValue?.trucks);
      this.trailers$.next(newTrailers);
      this.drivers$.next(newDrivers);
      this.trucks$.next(newTrucks);

      if (carrierAssets.previousValue?.trailers?.length > 0) {
        const prevTrailers = listToArray<Trailer>(carrierAssets.previousValue?.trailers);
        const newlyAddedTrailer = newTrailers.find(
          (newTrailer) => !prevTrailers.some((prevTrailer) => prevTrailer.id === newTrailer.id)
        );

        if (newlyAddedTrailer) {
          const truckId = this.assignmentInfo?.get('truckId')?.value;
          const truckObs = this.assignmentInfo$.value?.truck;
          // check if truckBeforeEdit is not null
          if (this.truckBeforeEdit) {
            // make sure the truck is still selected and re-populate if it isn't
            if ((!truckId || !truckObs) && this.truckBeforeEdit) {
              this.assignmentInfo.patchValue({ truckId: this.truckBeforeEdit?.id });
              this.assignmentInfo$.next({ ...this.assignmentInfo$.value, truck: this.truckBeforeEdit });
            }
          }
          // A new trailer has been added, so update the assignment info
          this.assignmentInfo.patchValue({ trailer: newlyAddedTrailer.id });
          this.assignmentInfo$.next({
            ...this.assignmentInfo$.value,
            trailer: newlyAddedTrailer,
            trailerOwner: TrailerOwnerType.THIRD_PARTY,
          });
        }
      }

      let {
        trailerOwner = null,
        trailer = null,
        truck = null,
        driver = null,
        secondaryDriver = null,
      } = this.assignmentInfo$.value;

      if (trailer) {
        if (trailer.trailerOwnerType === TrailerOwnerType.USXI) {
          this.assignmentInfo.patchValue({ trailer: trailer.trailerNumber });
          this.assignmentInfo$.next({ ...this.assignmentInfo$.value, trailer: trailer });
        } else {
          const foundTrailer = newTrailers.find((newTrailer: Trailer) => newTrailer.id === trailer.id);
          this.assignmentInfo.patchValue({ trailer: foundTrailer.id });
          this.assignmentInfo$.next({ ...this.assignmentInfo$.value, trailer: foundTrailer });
        }
      }

      if (trailerOwner && trailerOwner === TrailerOwnerType.USXI) {
        this.assignmentInfo.patchValue({ trailerOwner: TrailerOwnerType.USXI });
        this.assignmentInfo$.next({ ...this.assignmentInfo$.value, trailerOwner: TrailerOwnerType.USXI });
      }

      if (!trailerOwner && this.assignmentInfo$.value.trailer) {
        this.assignmentInfo.patchValue({ trailerOwner: TrailerOwnerType.THIRD_PARTY });
        this.assignmentInfo$.next({ ...this.assignmentInfo$.value, trailerOwner: TrailerOwnerType.THIRD_PARTY });
      }

      if (truck) {
        const foundTruck = newTrucks.find((newTruck: Truck) => newTruck.id === truck.id);
        this.assignmentInfo$.next({ ...this.assignmentInfo$.value, truck: foundTruck });
      }

      if (driver) {
        const foundDriver = newDrivers.find((newDriver: Driver) => newDriver.id === driver.id);
        let findSecDriver;
        if (secondaryDriver) {
          findSecDriver = newDrivers.find((newDriver: Driver) => newDriver.id === secondaryDriver.id);
        }

        this.assignmentInfo$.next({
          ...this.assignmentInfo$.value,
          driver: foundDriver,
          secondaryDriver: findSecDriver,
        });
      }

      this.onSelectCarrier(carrierAssets.currentValue?.carrier);
      this.carrier$.next(carrierAssets.currentValue?.carrier);
    }

    if (brokers) {
      this.brokers$.next(listToArray(brokers.currentValue));
    }

    if (carriers) {
      this.carriers$.next(listToArray(carriers.currentValue));
    }

    if (loadsServiceLoad) {
      this.setLoadsServiceLoad(loadsServiceLoad.currentValue);
    }

    if (isLoadingCarrierAssets) {
      this.isLoadingTrucks$.next(isLoadingCarrierAssets.currentValue);
      this.isLoadingTrailers$.next(isLoadingCarrierAssets.currentValue);
      this.isLoadingDrivers$.next(isLoadingCarrierAssets.currentValue);
    }
  }

  onSelectCarrier(carrier: CarrierSearchResult): void {
    if (this.load?.bookStatus !== BookStatus.BOOKED && carrier && carrier?.contacts?.length) {
      const carrierEmails = this.getCarrierEmails(carrier) ?? [];
      const rateConEmails = [...carrierEmails, this.brokerEmail];

      this.rateConEmails$.next(rateConEmails);
    }
  }

  onCarrierCompliance(carrierCompliance: boolean) {
    if (this.featureFlagState.CARRIER_COMPLIANCE) {
      this.carrierCompliant = carrierCompliance;
    }
  }

  onRateConEmailsChange(emails: string[]): void {
    let rateConEmails: RateConEmail[] = null;

    if (!isEmpty(emails)) {
      rateConEmails = emails.map((emailRecipient: string) => {
        return { emailRecipient };
      });
    }

    this.editLoad.patchValue({ rateConEmails });
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }

  private bookLoadForCarrier(formData: Partial<AssignLoadCarrierForm>) {
    if (
      (this.headHaul?.bookStatus === BookStatus.BOOKABLE ||
        this.headHaul?.bookStatus === BookStatus.VIEWABLE ||
        this.backHaul?.bookStatus === BookStatus.BOOKABLE ||
        this.backHaul?.bookStatus === BookStatus.VIEWABLE) &&
      this.headHaul?.bookStatus !== BookStatus.BOOKED &&
      this.featureFlagState[FeatureFlag.MISSIONS]
    ) {
      this.form.disable();
      this.bookMission.emit(formData);
    } else {
      if (!formData.dispatchInfo.location) {
        formData.dispatchInfo = { ...formData.dispatchInfo, location: null, timeAvailable: null };
      }
      this.form.disable();
      this.bookForCarrier.emit(formData);
    }
  }

  private getCarrierEmails(carrier: CarrierSearchResult): string[] {
    return carrier?.contacts?.reduce((acc: string[], contact: CarrierContacts) => {
      const { email, type } = contact;
      return type === CarrierContactTypes.ADMIN || type === CarrierContactTypes.BUSINESS ? [...acc, email] : acc;
    }, []);
  }

  private setLoadDispatch(load: LoadsServiceLoad) {
    if (!load) return;

    const dispatchInfo: DispatchForm = {
      notes: load.dispatchLocation && load.dispatchLocation.notes,
      timeAvailable: load.dispatchLocation && load.dispatchLocation.timestamp,
      location: load.dispatchLocation && {
        address: load.dispatchLocation.address,
        lat: load.dispatchLocation.lat,
        lon: load.dispatchLocation.lon,
        timeZone: load.dispatchLocation?.timezone,
      },
    };
    this.form.patchValue({ dispatchInfo });
  }

  private async setLoadsServiceLoad(load: LoadsServiceLoad) {
    if (!load) return;

    this.showIndividualSaveButtons = false;
    this.dispatchInfo.disable();
    this.orderInfo.disable();
    this.carrierInfo.disable();
    this.payments.disable();
    this.assignmentInfo.disable();
    this.editLoad.disable();

    const foundTruck = this.trucks$.value.find((truck: Truck) => truck?.id === load.truck?.id);
    const foundDriver = this.drivers$.value.find((driver: Driver) => driver?.id === load.drivers?.[0]?.id);
    const foundSecDriver = this.drivers$.value.find((driver: Driver) => driver?.id === load.drivers?.[1]?.id);
    const foundTrailer = this.trailers$.value.find((trailer: Trailer) => trailer?.id === load.trailers?.[0]?.id);
    const foundUSXTrailer = this.usxiTrailers.find((trailer) => trailer?.trailerNumber === load.trailers?.[0]?.id);

    let modifiedTracking;
    if (
      load.trackingType === TrackingType.MACRO_POINT &&
      load.carrier.thirdPartyTracking.some(
        (option) => option.trackingType === TrackingType.MACRO_POINT && option.vehicleTrackingEnabled
      ) &&
      load.truck?.thirdPartyTracking.some(
        (option) => option.trackingType === TrackingType.MACRO_POINT && option.isSupported
      )
    ) {
      modifiedTracking = TrackingType.MACRO_POINT_ELD;
    } else {
      modifiedTracking = load.trackingType;
    }

    const assignmentInfo: AssignDriverForm = {
      trackingType: modifiedTracking ? modifiedTracking : null,
      truck: foundTruck || load.truck || null,
      driver: foundDriver || load.drivers?.[0] || null,
      trailerOwner: load.trailers?.[0]
        ? load.trailers?.[0]?.trailerOwnerType === TrailerOwnerType.USXI
          ? TrailerOwnerType.USXI
          : TrailerOwnerType.THIRD_PARTY
        : null,
      trailer: foundTrailer || foundUSXTrailer || load.trailers?.[0] || null,
      phone: foundDriver?.phone || load.drivers?.[0]?.phone || null,
      email: foundDriver?.email || load.drivers?.[0]?.email || null,
      secondaryDriver: foundSecDriver || load.drivers?.[1] || null,
      secDriverPhone: foundSecDriver?.phone || load.drivers?.[1]?.phone || null,
      secDriverEmail: foundSecDriver?.email || load.drivers?.[1]?.email || null,
    };

    if (load.bookStatus !== BookStatus.BOOKED) {
      assignmentInfo.trackingType = null;
    } else if (
      load.bookStatus === BookStatus.BOOKED &&
      (assignmentInfo.truck || assignmentInfo.driver || assignmentInfo.trailer) &&
      load.trackingType === TrackingType.MANUAL
    ) {
      assignmentInfo.trackingType = load.trackingType;
    }

    this.assignmentInfo$.next(assignmentInfo);

    const identifier = getLoadsServiceLoadAlternateId(this.loadsServiceLoad, LoadIdentifierType.ORDER_NUMBER);
    const order = identifier || '';
    const brokerId = await this.appModel.brokerId$.pipe(take(1)).toPromise();
    const orderInfo: OrderInfoForm = {
      brokerId:
        load.bookStatus === BookStatus.BOOKED || load.loadStatus === LoadsServiceLoadStatus.FINALLED
          ? load.broker?.usxId
          : brokerId,
      order: order,
    };

    const editLoad: AssignLoadEditForm = {
      internalNotes: load.providerDetails?.internalNotes || null,
      externalNotes: load.providerDetails?.externalNotes || null,
      customerNotes: joinNoteTexts(load?.billTo?.notes),
      rateConEmails: load.rateConEmails || [],
    };
    this.form.patchValue({ orderInfo, editLoad, assignmentInfo });

    // Put this back when notes works on dispatch form and remove setLoadDispatch()
    // Then add notes to dispatchLocation in GetLoadsServiceLoadById and BullkEdit
    // const dispatchInfo: DispatchForm = {
    //   notes: load.dispatchLocation && load.dispatchLocation.notes,
    //   timeAvailable: load.dispatchLocation && load.dispatchLocation.timestamp,
    //   location: load.dispatchLocation && {
    //     address: load.dispatchLocation.address,
    //     lat: load.dispatchLocation.lat,
    //     lon: load.dispatchLocation.lon,
    //     timeZone: load.dispatchLocation?.timezone || null,
    //   },
    // };
    // this.form.patchValue({ dispatchInfo });

    // Disable everything
    if (load.loadStatus === LoadsServiceLoadStatus.FINALLED) {
      this.rateConEmails$.next((load.rateConEmails || []).map((email: RateConEmail) => email.emailRecipient));
      this.canEdit = false;
      this.showFooterButtons = false;
      this.showIndividualSaveButtons = true;
      this.payments.enable();
      return;
    }

    if (load.bookStatus === BookStatus.BOOKED) {
      this.dispatchInfo.enable();
      this.payments.enable();
      this.assignmentInfo.enable();

      // Disable Share Ratecons
      this.rateConEmails$.next((load.rateConEmails || []).map((email: RateConEmail) => email.emailRecipient));
      this.canEdit = false;

      // For now, don't show main save details footer button after booked but show individual save buttons
      this.showFooterButtons = false;
      this.showIndividualSaveButtons = true;
    } else {
      if (!this.isCarrierComplianceLoadLock) {
        this.carrierInfo.enable();
      }
      this.orderInfo.enable();
      this.dispatchInfo.enable();
      this.payments.enable();
      this.assignmentInfo.enable();
    }
  }

  private getBillToNotes(load: LoadsServiceLoad): string {
    return (
      load?.billTo?.notes
        ?.filter((note) => !!note?.noteText)
        .map((note) => note?.noteText)
        .join('\n\n') || ''
    );
  }

  createComplianceTicket(carrier: Carrier): void {
    this.createCarrierCompliance.emit(carrier);
  }
}
