<div class="virtual-scroll-sort">
  <mat-accordion>
    <mat-expansion-panel
      class="main-expansion"
      (opened)="expansionToggle = false"
      (closed)="expansionToggle = true"
      [hideToggle]="expansionToggle"
    >
      <mat-expansion-panel-header
        class="main-expansion__header"
        [collapsedHeight]="collapsedExpansionHeaderHeight"
        [expandedHeight]="expandedExpansionHeaderHeight"
      >
        <mat-panel-title fxLayout="row" fxLayoutAlign="start center" data-test="open-sort">
          <span class="sort_icons" fxLayout="column" fxLayoutAlign="center center">
            <mat-icon fxLayout="column" fxLayoutAlign="start center">expand_less</mat-icon>
            <mat-icon fxLayout="column" fxLayoutAlign="end center">expand_more</mat-icon>
          </span>
          <span class="sort_text">Sort</span>
        </mat-panel-title>
        <mat-panel-description *ngIf="expansionToggle" fxLayout="column" fxLayoutAlign="center start">
          {{ list | carrierLoadSearchListDisplay }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="sort-content" fxLayout="column">
        <mat-radio-group fxLayout="column">
          <ng-container *ngFor="let sortType of mobileVirtualScrollSort; let lastItem = last">
            <div
              class="sort-content__options"
              [ngClass]="{ 'last-option': lastItem }"
              *ngIf="!sortType?.expansionSortOptions?.length; else expansion"
              fxLayout="row"
              fxLayoutAlign="space-between center"
            >
              <mat-radio-button
                [value]="sortType?.sortOrder"
                [checked]="sortType?.selected"
                (click)="changeSortOrder(sortType, !sortType?.sortDirection ? 'asc' : sortType?.sortDirection)"
                attr.data-test="{{ sortType?.label }}"
                >{{ sortType?.label }}</mat-radio-button
              >
              <ng-container *ngIf="sortType?.label != 'Default'">
                <mat-icon
                  svgIcon="virtual-sort-{{ !sortType?.sortDirection ? 'asc' : sortType?.sortDirection }}-{{
                    sortType?.directionType
                  }}-logo"
                  (click)="
                    changeSortDirection(
                      sortType,
                      !sortType?.sortDirection ? 'asc' : sortType?.sortDirection === 'asc' ? 'desc' : 'asc'
                    )
                  "
                  data-test="change-info-sort"
                ></mat-icon>
              </ng-container>
            </div>
            <ng-template #expansion>
              <mat-expansion-panel class="sort-content__expansion" [togglePosition]="'before'">
                <mat-expansion-panel-header
                  class="sort-content__expansion--header"
                  [collapsedHeight]="innerCollapsedExpansionHeaderHeight"
                  [expandedHeight]="innerExpandedExpansionHeaderHeight"
                  data-test="open-panel"
                >
                  <mat-panel-title
                    class="sort-content__expansion--header-title"
                    fxLayout="row"
                    fxLayoutAlign="start center"
                  >
                    {{ sortType?.label }}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-container *ngFor="let childSortOption of sortType?.expansionSortOptions; let lastItem = last">
                  <div
                    class="sort-content__options"
                    [ngClass]="{ 'last-option': lastItem }"
                    fxLayout="row"
                    fxLayoutAlign="space-between center"
                  >
                    <mat-radio-button
                      [value]="childSortOption?.sortOrder"
                      [checked]="childSortOption?.selected"
                      (click)="
                        changeSortOrder(
                          childSortOption,
                          !childSortOption?.sortDirection ? 'asc' : childSortOption?.sortDirection
                        )
                      "
                      attr.data-test="{{ childSortOption?.label }}"
                      >{{ childSortOption?.label }}
                    </mat-radio-button>
                    <mat-icon
                      svgIcon="virtual-sort-{{
                        !childSortOption?.sortDirection ? 'asc' : childSortOption?.sortDirection
                      }}-{{ childSortOption?.directionType }}-logo"
                      (click)="
                        changeSortDirection(
                          childSortOption,
                          !childSortOption?.sortDirection
                            ? 'asc'
                            : childSortOption?.sortDirection === 'asc'
                            ? 'desc'
                            : 'asc'
                        )
                      "
                      data-test="change-location-sort"
                    ></mat-icon>
                  </div>
                </ng-container>
              </mat-expansion-panel>
            </ng-template>
          </ng-container>
        </mat-radio-group>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <haulynx-mobile-banner
    *ngIf="!!this.user?.carrier"
    (toggleMobileBanner)="onToggleMobileBanner($event)"
  ></haulynx-mobile-banner>
</div>
