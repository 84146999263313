import gql from 'graphql-tag';

export const SearchSubscriptionEvents = gql(`
query SearchSubscriptionEvents($filterParams:SubscriptionEventFilterParams!) {
    searchSubscriptionEvents(filterParams:$filterParams){
        id
        eventType
        entityId
        entityType
        email
        queryName
        createdAt
        updatedAt
        user{
        id
        usxId
        name
      }
      expiredAt
    }
  }
`);
