import { IconsService } from '@haulynx/icons';
import { EnvironmentService, LocalStoreService } from '@haulynx/services';
import { AppSettingsActions, AppState, isAppInitSelector, UserEntityService } from '@haulynx/store';
import { User } from '@haulynx/types';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

export function appInitializeFactory(
  iconsService: IconsService,
  localStoreService: LocalStoreService,
  store: Store<AppState>,
  environmentService: EnvironmentService,
  userEntityService: UserEntityService
): () => Observable<any> {
  return () =>
    new Observable((subscriber) => {
      Promise.all([
        localStoreService.getCachedItem<string>('token'),
        localStoreService.getCachedItem<User>('user'),
      ]).then((data) => {
        iconsService.init();
        const token: string = data[0]?.data;
        const user = data[1]?.data;
        userEntityService.setEthnioScript(user);

        const environmentName = environmentService.getEnvironmentName();
        userEntityService.sessionInformationManager.dispatch({
          sessionInformation: { token, user, environment: environmentName },
        });

        store.dispatch(AppSettingsActions.startInitApp({ token, user, environmentName }));
        store
          .select(isAppInitSelector)
          .pipe(filter((val) => !val))
          .subscribe(() => {
            store.dispatch(AppSettingsActions.finishInitApp());
            subscriber.complete();
          });
      });
    });
}
