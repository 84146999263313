<div class="address-search {{ customClass }}">
  <div matAutocompleteOrigin #origin="matAutocompleteOrigin">
    <input
      [formControl]="control"
      #addressInputElement
      matInput
      [required]="required"
      [placeholder]="placeholder"
      (input)="change($event)"
      (keydown.enter)="onEnter($event)"
      (keydown.tab)="onTab()"
      (blur)="onBlur($event)"
      data-test="address-search-field"
      [matAutocomplete]="auto"
      [matAutocompleteConnectedTo]="origin"
    />

    <mat-spinner matSuffix *ngIf="isLoading$ | async as isLoading" [diameter]="20"> </mat-spinner>
    <mat-icon
      matSuffix
      class="address-search__icon"
      *ngIf="icon"
      [ngClass]="icon"
      [svgIcon]="icon"
      (click)="iconClick($event)"
      data-test="current-location-button"
    >
    </mat-icon>
    <div class="text-danger" *ngIf="error && ngControl.touched">{{ error }}</div>
  </div>
  <mat-autocomplete
    #auto="matAutocomplete"
    class="location-autocomplete"
    (optionSelected)="onSelect($event.option.value)"
    [panelWidth]="'auto'"
  >
    <mat-option (onSelectionChange)="onSelect(option)" *ngFor="let option of options$ | async" [value]="option">
      {{ option }}
    </mat-option>
  </mat-autocomplete>
</div>
