import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@haulynx/modules';
import { CarrierInfoRoutingModule } from './carrier-info-routing.module';
import { CarrierInfoComponent } from './carrier-info.component';

@NgModule({
  declarations: [CarrierInfoComponent],
  imports: [CommonModule, CarrierInfoRoutingModule, MaterialModule],
})
export class CarrierInfoModule {}
