import { Injectable } from '@angular/core';
import {
  GetGeocodeLocation,
  GetNearestCities,
  GetReverseGeocodeLocation,
  GetReverseGeocodeLocations,
} from '@haulynx/gql';
import { GeocodeParams, LatLonInput, NearestCity, PaginatedData, PlaceInfo, PlaceType } from '@haulynx/types';
import { get } from 'lodash';

import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { GraphqlService } from '../graphql.service';

@Injectable({ providedIn: 'root' })
export class GeocodingService {
  constructor(private graphqlService: GraphqlService) {}

  getNearestCities(coordinates?: LatLonInput[]): Observable<NearestCity[]> {
    return this.graphqlService
      .query({
        query: GetNearestCities,
        variables: {
          coordinates,
        },
      })
      .pipe(map((result) => get(result, 'data.getNearestCities', [])));
  }

  getGeocodeLocation(geocodeParams: GeocodeParams): Observable<PaginatedData<PlaceInfo>> {
    return this.graphqlService
      .query({
        query: GetGeocodeLocation,
        variables: {
          geocodeParams,
        },
      })
      .pipe(
        map((result) => {
          return { data: get(result.data, 'getGeocodeLocation', []), pagination: null };
        })
      );
  }

  getReverseGeocodeLocation(
    coordinates: LatLonInput,
    limit?: number,
    placeType?: PlaceType
  ): Observable<PaginatedData<PlaceInfo>> {
    return this.graphqlService
      .query({
        query: GetReverseGeocodeLocation,
        variables: {
          coordinates,
          limit,
          placeType,
        },
      })
      .pipe(
        map((result) => {
          return { data: get(result.data, 'getReverseGeocodeLocation', []), pagination: null };
        })
      );
  }

  getReverseGeocodeLocations(coordinates: LatLonInput[], placeType?: PlaceType): Observable<PaginatedData<PlaceInfo>> {
    return this.graphqlService
      .query({
        query: GetReverseGeocodeLocations,
        variables: {
          coordinates,
          placeType,
        },
      })
      .pipe(
        map((result) => {
          return { data: get(result.data, 'getReverseGeocodeLocations', []), pagination: null };
        })
      );
  }
}
