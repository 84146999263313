import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FireDatabaseService } from '../../app-services/generic/fire-database/fire-database.service';

@Injectable()
export class FireBaseNotificationService {
  constructor(private fireDatabaseService: FireDatabaseService, private http: HttpClient) {}

  listening(
    userId: string
  ): Observable<
    {
      id: string;
      data: { body: string; email: string; id: string; message: string; silent: boolean };
      isViewed: boolean;
      message: string;
      timestamp: number;
    }[]
  > {
    return this.fireDatabaseService
      .list<{
        id: string;
        data: { body: string; email: string; id: string; message: string; silent: boolean };
        isViewed: boolean;
        message: string;
        timestamp: number;
      }>(`/notifications/${userId}`, (ref) => ref.orderByChild('timestamp').limitToLast(20))
      .valueChanges();
  }

  clearNotification(): Observable<unknown> {
    return this.http.post('/api/users/dashboard/notifications/clear', null);
  }
}
