import { Carrier } from '@haulynx/types';
import { createAction, createActionTypes } from '../../shared/store/helpers/action';
import { TYPE } from './adapters';

export interface ICarrierDetailsActions {
  setStateKey(params: { key: string });

  get(params: { carrierId?: string; carrierDot?: string });

  getSuccess(params: { carrierId: string; carrier: Carrier });

  getError(params: { carrierId: string; error: any });
}

export interface ICarrierDetailsActionTypes {
  STATE_KEY: string;
  GET: string;
  GET_SUCCESS: string;
  GET_ERROR: string;
}

export const carrierDetailsActionTypes: ICarrierDetailsActionTypes = {
  STATE_KEY: 'state key',
  GET: 'get',
  GET_SUCCESS: 'get success',
  GET_ERROR: 'get error',
};

export const carrierDetailsActions: ICarrierDetailsActions = {
  setStateKey: createAction(TYPE, carrierDetailsActionTypes.STATE_KEY),
  get: createAction(TYPE, carrierDetailsActionTypes.GET),
  getSuccess: createAction(TYPE, carrierDetailsActionTypes.GET_SUCCESS),
  getError: createAction(TYPE, carrierDetailsActionTypes.GET_ERROR),
};

export const CarrierDetailsActions: ICarrierDetailsActions = carrierDetailsActions;

export const CarrierDetailsActionTypes: ICarrierDetailsActionTypes = createActionTypes(carrierDetailsActionTypes)(TYPE);
