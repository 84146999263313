import { Injectable } from '@angular/core';
import { NotificationsService, PreferredLanesService } from '@haulynx/services';
import {
  AsyncDestroyMutationManager,
  AsyncMutationManager,
  AsyncSearchManager,
  CapacityObject,
  CapacityObjectInput,
} from '@haulynx/types';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AppState } from '../../main-store/app.reducers';
import { AsyncEntityBase } from '../../main-store/async-entity/async-entity-base.class';
import { preferredLanesNamespace } from './namespace';

@Injectable({ providedIn: 'root' })
export class PreferredLanesEntityService extends AsyncEntityBase<CapacityObject> {
  createLaneManager: AsyncMutationManager<CapacityObjectInput, CapacityObject>;
  getCarrierLanesManager: AsyncSearchManager<CapacityObject, string>;
  deleteLaneManager: AsyncDestroyMutationManager<{ laneIds: string[] }, string>;

  constructor(
    protected actions$: Actions,
    protected store: Store<AppState>,
    private notifications: NotificationsService,
    private preferredLanesService: PreferredLanesService
  ) {
    super(actions$, store, preferredLanesNamespace);

    this.createLaneManager = this.createAsyncMutation(
      'create lane',
      (newLane) =>
        this.preferredLanesService.createCarrierLane(newLane).pipe(
          tap(
            () => {
              this.notifications.success(`The lane was successfully created!`, `Lane`);
            },
            (error) => this.notifications.error(`Oops, something went wrong. Unable to Save Lane!`, error)
          )
        ),
      { refreshEntityFrom: 'response' }
    );

    this.getCarrierLanesManager = this.createAsyncSearchQuery('get lane recommends by DOT', (payload) => {
      return this.preferredLanesService.getLanesByDOT(payload.query, payload.pageAndSort).pipe(
        catchError((error) => {
          this.notifications.error(error, 'Problem Fetching Lanes');
          return throwError(error);
        })
      );
    });

    this.deleteLaneManager = this.createAsyncDestroyMutation('delete lane', (payload) =>
      this.preferredLanesService.deleteLane(payload.laneIds).pipe(
        tap(
          () => {
            this.notifications.success('Successfully deleted lane!', 'Success!');
          },
          (error) => this.notifications.error(error, 'Error deleting lane!')
        )
      )
    );
  }
}
