import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

/* Return invalid if the second field is empty or null and the first field having
 a certain value. The second field is dependent on the first field's value
*/
export function fieldValDependency(firstField: string, secondField: string, firstFieldValue: string[]): ValidatorFn {
  return (group: FormGroup): ValidationErrors => {
    const firstControl = group.controls[firstField];
    const secondControl = group.controls[secondField];

    if (firstFieldValue.includes(firstControl.value) && secondControl.value === '') {
      secondControl.setErrors({ invalid: true });
    } else {
      secondControl.setErrors(null);
    }
    return;
  };
}
