import { BounceCarrierDialogComponent } from './bounce-carrier-dialog.component';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [BounceCarrierDialogComponent],
  exports: [BounceCarrierDialogComponent],
  imports: [CommonModule, MatDialogModule],
})
export class BounceCarrierDialogModule {}
