import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Milestone, MilestoneUser, User } from '@haulynx/types';
@Component({
  selector: 'haulynx-milestone-sub-item-comments',
  templateUrl: './milestone-sub-item-comments.component.html',
  styleUrls: ['./milestone-sub-item-comments.component.scss'],
})
export class MilestoneSubItemCommentsComponent implements OnInit {
  @Input() user: User;
  @Input() currentUserType: MilestoneUser;
  @Input() milestone: Milestone;
  @Input() milestoneIndex: number;
  @Output() commentAction: EventEmitter<{ action: string; commentIndex: number }> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  commentActions(action: string, index: number): void {
    this.commentAction.emit({ action: action, commentIndex: index });
  }
}
