import { EquipmentType } from '../../loads-service';
import { BookStatus, LoadLocationType } from '../load';

export enum RecommendedType {
  FREQUENT_HAUL = 'frequentHaul',
  SIMILAR_HAUL = 'similarHaul',
  BEST_PRICE = 'bestPrice',
  HISTORICAL_BID = 'historicalBid',
}
export interface RecommendedExplanation {
  type: RecommendedType;
  similarLoadId: string;
}
export interface RecommendedCarriers {
  id: string;
  name: string;
  sourceType: string;
  dot: string;
  brokerId: string;
  email: string;
  owner: string;
  price?: number;
  explanation?: RecommendedExplanation;
  matchPercent?: number;
  type?: string;
  load: {
    id: string;
    bookStatus: BookStatus;
    locations: Array<{
      customer: {
        number: number;
        id: string;
        name: string;
        customerAttributes: Array<{
          key: string;
          label: string;
          option: string;
        }>;
      };
      locationType: LoadLocationType;
      appointmentSet: boolean;
      timezone: string;
      geometry: {
        type: string;
        coordinates: number[];
      };
      appointmentStart: number;
      appointmentEnd: number;
      carrierDeparture: number;
      carrierArrival: number;
      address: string;
    }>;
    broker: {
      id: string;
    };
    carrier: {
      id: string;
      name: string;
      dot: string;
    };
    paymentDetails: {
      price: number;
      distanceMiles: number;
    };
    providerDetails: {
      alternateIds: Array<{
        identifierType?: any;
        identifierValue?: string;
      }>;
      equipmentType: EquipmentType;
    };
  };
}
