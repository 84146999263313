import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  BooleanFilterType,
  DropdownDisplay,
  ISearchFilter,
  ISearchFilterType,
  TextArrayFilterType,
  TextArrayMode,
  TextFilterType,
} from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { isNil, trim } from 'lodash';
import { isObservable, Observable } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-search-options',
  templateUrl: './search-options.component.html',
  styleUrls: ['./search-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchOptionsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('soInput', { static: false }) firstInput: ElementRef;
  @ViewChild('wrapper', { static: false }) wrapper: ElementRef;

  @Input() focusedFilter: ISearchFilter;
  @Input() form: FormGroup;
  @Input() textArrayMode: TextArrayMode;

  @Output() mouseFocus: EventEmitter<boolean> = new EventEmitter();
  @Output() commitFilter: EventEmitter<{ searchFilter: ISearchFilter; form: FormGroup }> = new EventEmitter();
  @Output() removeFilter: EventEmitter<ISearchFilter> = new EventEmitter();
  @Output() validForm: EventEmitter<boolean> = new EventEmitter();
  @Output() removeSearchFilter: EventEmitter<ISearchFilter> = new EventEmitter();
  @Output() searchMultiOptionSelect: EventEmitter<(string | number)[]> = new EventEmitter();

  @Output() textChange: EventEmitter<string> = new EventEmitter();

  formName: string;
  shouldCheckClicks = false;
  filterType = ISearchFilterType;
  selectedIndex = 0;
  options = [];
  placeholder = 'search';
  private alive = aliveWhile();

  constructor() {}

  @HostListener('window:keyup', ['$event']) onKeyPress(event: KeyboardEvent): void {
    if (event.key === 'Enter' && this.isFormValid) {
      this.commitFilter.emit({ searchFilter: this.focusedFilter, form: this.form });
      this.mouseFocus.emit(false);
      this.validForm.emit(false);
    } else {
      this.validForm.emit(this.isFormValid);
    }
  }

  @HostListener('document:click', ['$event.target']) onClick(targetElement: ElementRef): void {
    const clickedInside = this.wrapper.nativeElement.contains(targetElement);
    if (!clickedInside && this.shouldCheckClicks) {
      this.mouseFocus.emit(false);
      // this.validForm.emit(false);
    } else {
      this.mouseFocus.emit(true);
      this.validForm.emit(this.isFormValid);
    }
  }

  ngOnInit(): void {
    this.formName = (<TextFilterType>this.focusedFilter.keys).textFormName;

    if (this.focusedFilter?.type === ISearchFilterType.MULTI_DROPDOWN) {
      this.placeholder = 'Use checkmarks below to make any selection';
    }

    if ((<TextFilterType>this.focusedFilter.keys).numericalOnly) {
      this.form.controls[this.formName].setValidators([Validators.required, Validators.pattern('^[0-9]*$')]);
      this.form.controls[this.formName].updateValueAndValidity();
    }

    if (this.focusedFilter?.type === ISearchFilterType.TEXT_ARRAY) {
      const { value: filterValue } = <TextArrayFilterType>this.focusedFilter.keys;
      if (this.textArrayMode === TextArrayMode.UPDATE) {
        this.selectedIndex = this.textArrayForm.value.findIndex((item: string) => item === filterValue);
      } else {
        this.textArrayForm.push(new FormControl(filterValue ?? ''));
        this.selectedIndex = this.textArrayForm.length - 1;
        (<TextArrayFilterType>this.focusedFilter.keys).index = this.selectedIndex;
      }
    }

    if (this.focusedFilter) {
      this.form
        .get(this.formName)
        .valueChanges.pipe(takeUntil(this.alive))
        .subscribe((value) => {
          if (this.focusedFilter?.type === ISearchFilterType.TEXT_ARRAY && value[this.selectedIndex]) {
            (<TextArrayFilterType>this.focusedFilter?.keys).value = value[this.selectedIndex];
          }
          this.firstInput?.nativeElement.focus();
          this.mouseFocus.emit(true);
        });
    }

    this.textChange.emit('');

    if (isObservable((<TextFilterType>this.focusedFilter.keys).optionsDataIndex)) {
      (<Observable<DropdownDisplay[]>>(<TextFilterType>this.focusedFilter.keys).optionsDataIndex)
        .pipe(takeUntil(this.alive), distinctUntilChanged())
        .subscribe((options: DropdownDisplay[]) => {
          this.options = options.map((option) => option.value);
        });
    }

    if (
      isObservable((<TextFilterType>this.focusedFilter.keys).optionsDataIndex) ||
      Array.isArray((<TextFilterType>this.focusedFilter.keys).optionsDataIndex)
    ) {
      this.form
        .get((<TextFilterType>this.focusedFilter.keys).textFormName)
        .valueChanges.pipe(takeUntil(this.alive))
        .subscribe((value) => {
          if (this.focusedFilter?.type === ISearchFilterType.TEXT_ARRAY) {
            this.textChange.emit(value);
          }
        });
    }
  }

  onRemoveMultiOption(event: { filter: ISearchFilter; value: any }): void {
    const selectedOptions = this.form.get((<TextFilterType>this.focusedFilter.keys).textFormName).value;
    this.searchMultiOptionSelect.emit(selectedOptions.filter((option, i) => i !== event.value));
    this.mouseFocus.emit(true);
  }

  ngAfterViewInit(): void {
    if (this.focusedFilter) {
      if (this.focusedFilter.type === ISearchFilterType.TEXT_ARRAY) this.firstInput?.nativeElement.select();
      this.mouseFocus.emit(true);
      this.shouldCheckClicks = false;
      setTimeout(() => {
        this.validForm.emit(this.isFormValid);
        this.shouldCheckClicks = true;
      }, 500);
    }
  }

  get isFormValid(): boolean {
    if (!this.form.valid) {
      return false;
    } else if (this.focusedFilter.type === ISearchFilterType.TEXT_ARRAY) {
      const { value } = this.textArrayForm;
      return !!trim(value[this.selectedIndex]);
    } else {
      return this.textForm !== '';
    }
  }

  get textForm(): string {
    return this.focusedFilter ? this.form.get((<TextFilterType>this.focusedFilter.keys).textFormName)?.value : '';
  }

  get booleanForm(): string {
    if (this.focusedFilter) {
      const boolVal = this.form.get((<BooleanFilterType>this.focusedFilter.keys).textFormName)?.value;
      if (!isNil(boolVal)) {
        return boolVal ? 'Yes' : 'No';
      }
    }

    return '';
  }

  removeFocusedFilter(): void {
    if (this.focusedFilter?.type === ISearchFilterType.TEXT_ARRAY && this.textArrayMode === TextArrayMode.CREATE) {
      this.textArrayForm.removeAt(this.selectedIndex);
    }
    this.removeFilter.emit(this.focusedFilter);
    this.mouseFocus.emit(false);
  }

  get textArrayForm(): FormArray {
    return this.form.get(this.formName) as FormArray;
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }
}
