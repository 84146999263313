import { createAction, createActionTypes } from '../../shared/store/helpers/action';

const TYPE = '[LOAD PRICE]';

export interface ILoadFeedActions {
  setPrice(params: { loadId: string | number; price: number; maxBuy?: number });

  setPriceSuccess(params: { loadId: string });

  setPriceError(params: { loadId: string });
}

export interface ILoadFeedActionType {
  SET_PRICE: string;
  SET_PRICE_SUCCESS: string;
  SET_PRICE_ERROR: string;
}

export const loadPriceActionTypes = {
  SET_PRICE: 'set pricing',
  SET_PRICE_SUCCESS: 'set pricing success',
  SET_PRICE_ERROR: 'set pricing error',
};

export const loadPriceActions = {
  setPrice: createAction(TYPE, loadPriceActionTypes.SET_PRICE),
  setPriceSuccess: createAction(TYPE, loadPriceActionTypes.SET_PRICE_SUCCESS),
  setPriceError: createAction(TYPE, loadPriceActionTypes.SET_PRICE_ERROR),
};

export const LoadPriceActions: ILoadFeedActions = loadPriceActions;
export const LoadPriceActionTypes: ILoadFeedActionType = createActionTypes(loadPriceActionTypes)(TYPE);
