<div class="user-profile">
  <button
    class="trigger"
    fxLayout="row"
    fxHide.lt-lg
    mat-button
    [matMenuTriggerFor]="menu"
    #menuTrigger="matMenuTrigger"
    data-test="menu-trigger"
  >
    <div class="user-profile__user">
      <div class="user-profile__avatar">{{ user?.name | userInitials }}</div>
      <div class="user-profile__bio">
        <span class="user-profile__bio--name">{{ user?.name }}</span>
        <span class="user-profile__bio--company" *ngIf="!user?.isHaulynxAdmin">{{
          user?.carrier
            ? user.carrier?.name
            : user?.shipper
            ? user.shipper?.name
            : user?.broker
            ? user.broker?.name
            : ''
        }}</span>
        <span *ngIf="user?.isHaulynxAdmin" class="user-info-text"> Haulynx Admin </span>
      </div>
    </div>
    <mat-icon class="menu-arrow">
      {{ menuTrigger.menuOpen ? 'expand_less' : 'expand_more' }}
    </mat-icon>
  </button>
  <mat-menu #menu="matMenu" class="user-profile__mat-menu" [ngStyle]="{ background: 'transparent' }">
    <ng-container *ngFor="let item of headerItems">
      <div *ngIf="item.accountItem">
        <button mat-menu-item (click)="selectedItem(item)" class="account-action" [attr.data-test]="item.name">
          <mat-icon *ngIf="item.svgIcon" [svgIcon]="item.svgIcon" class="account-action__icon"></mat-icon>
          <mat-icon *ngIf="item.icon" class="account-action__icon">{{ item.icon }}</mat-icon>
          <span class="account-action__action">{{ item.name }}</span>
        </button>
      </div>
    </ng-container>
    <button mat-menu-item (click)="onLogout()" data-test="logout-button">
      <mat-icon>logout</mat-icon>
      <span>Sign Out</span>
    </button>
  </mat-menu>
</div>
