import {
  Bid,
  BidDetails,
  BookStatus,
  Carrier,
  Customer,
  DispatchLocation,
  DistributionMechanismString,
  LoadCarrierContact,
  Milestone,
  MilestoneComment,
  MilestoneLog,
  RateConEmail,
  RegionTypes,
  TrackingType,
  Trailer,
  TrailerOwnerType,
  Truck,
  User,
} from '../web-interfaces';
import { BrokerOffer } from './broker-offer';
import { LoadsServiceLoadLocation, LoadsServiceLoadLocationInput } from './loads-service-load-location';
import { LoadsServiceNote } from './loads-service-note';
import { LoadsServiceOffer } from './loads-service-offer';
import { LoadsServicePaymentDetails, LoadsServicePaymentDetailsInput } from './loads-service-payment-details';
import {
  LoadsServiceProvider,
  LoadsServiceProviderDetails,
  LoadsServiceProviderDetailsInput,
  LoadsServiceProviderInput,
} from './loads-service-provider';
import { LoadsServiceRecommendation } from './loads-service-recommendation';
import { LoadsServiceRestriction } from './loads-service-restriction';
import { LoadTrackingDetails } from './loads-service-tracking-details';

export class UnauthenticatedLoadsServiceLoad {
  id: string;
  created: number;
  dateCompleted: number;
  carrierAccepted: boolean;
  loadStatus: LoadsServiceLoadStatus;
  paymentDetails: LoadsServicePaymentDetails;
  recommendations: LoadsServiceRecommendation[];
  providerDetails: LoadsServiceProviderDetails;
  locations: LoadsServiceLoadLocation[];
  carrier: Carrier;
  drivers: User[];
  truck: Truck;

  constructor(props?: Partial<LoadsServiceLoad>) {
    Object.assign(this, props);
    this.dateCompleted =
      this.locations && this.locations[0] && this.locations[this.locations.length - 1].carrierDeparture;
    this.carrierAccepted = !!this.carrier;
  }
}

export class LoadsServiceLoad {
  id?: string;
  loadStatus: LoadsServiceLoadStatus = null; // replacement for filters
  created?: number;
  lastUpdatedAt?: number;
  bookStatus: BookStatus = null;
  distributionMechanism: DistributionMechanismString;
  assignedBrokers?: User[] = [];
  brokerOfferForCarrier?: BrokerOffer;
  carrier?: Carrier;
  carrierBid?: Bid;
  bidDetails?: BidDetails;
  broker?: User;
  billTo?: Customer;
  trackingType?: TrackingType;
  trailerOwner?: TrailerOwnerType;
  truck?: Truck;
  numberOfAppointmentResets?: number;
  provider: LoadsServiceProvider;
  providerDetails?: LoadsServiceProviderDetails;
  paymentDetails?: LoadsServicePaymentDetails;
  restrictions?: LoadsServiceRestriction[];
  locations: LoadsServiceLoadLocation[];
  trailers?: Trailer[];
  drivers?: User[];
  recommendations?: LoadsServiceRecommendation[];
  offers?: LoadsServiceOffer[];
  onTimeStatus?: LoadsServiceLoadOnTimeStatus;
  notes?: LoadsServiceNote[];
  milestones: Milestone[];
  createdBy?: string;
  rateConEmails?: RateConEmail[] = [];
  region?: RegionTypes;
  dispatchLocation?: DispatchLocation;
  trackingStatus?: LoadTrackingDetails;
  contacts?: LoadCarrierContact[];
  latestMilestoneComment?: MilestoneComment;
  latestMilestoneLog?: MilestoneLog;
  carrierCanBookNow?: boolean;
  mission?: LoadsServiceLoadMission;
  constructor(props?: Partial<LoadsServiceLoad>) {
    Object.assign(this, props);
  }
}

export class LoadsServiceLoadInput {
  id?: string = null;
  creator?: string;
  dateCreated?: string;
  loadStatus: LoadsServiceLoadStatus = null; // replacement for filters
  bookStatus: BookStatus = null;
  distributionMechanism: DistributionMechanismString;
  carrier?: Partial<Carrier>;
  broker?: User = null;
  trackingType?: TrackingType;
  truck?: Partial<Truck>;
  numberOfAppointmentResets?: number;
  provider?: LoadsServiceProviderInput;
  providerDetails?: LoadsServiceProviderDetailsInput = null;
  paymentDetails?: LoadsServicePaymentDetailsInput = null;
  restrictions?: LoadsServiceRestriction[] = [];
  locations: LoadsServiceLoadLocationInput[] = [];
  trailers?: Partial<Trailer>[] = [];
  drivers?: User[] = [];
  recommendations?: LoadsServiceRecommendation[] = [];
  region?: RegionTypes;
  offers?: LoadsServiceOffer[] = [];
  notes?: LoadsServiceNote[] = [];
}

export class LoadsServiceLoadTemplateInput {
  id?: string;
  creator: string;
  dateCreated?: string;
  distributionMechanism?: DistributionMechanismString;
  locations: LoadsServiceLoadLocationInput[];
  providerDetails?: LoadsServiceProviderDetailsInput;
  paymentDetails?: LoadsServicePaymentDetailsInput;
  name?: string;
  trailers?: Partial<Trailer>[] = [];
  drivers?: User[] = [];
  carrier?: Partial<Carrier>;
  broker?: User;
  truck?: Partial<Truck>;
  notes?: LoadsServiceNote[];
}

export class LoadsServiceLoadOnTimeStatus {
  onTime: boolean;
  pickupStatus: string;
}

export enum LoadsServiceLoadStatus {
  UNASSIGNED = 'unassigned',
  ASSIGNED = 'assigned',
  DISPATCHED = 'dispatched',
  AT_SHIPPER = 'atShipper',
  PICKED_UP = 'pickedUp',
  AT_RECEIVER = 'atReceiver',
  DELIVERED = 'delivered',
  FINALLED = 'finalled',
  DELETED = 'deleted',
}

export enum MissionStatus {
  CANCELLED = 'cancelled',
  COMPLETED = 'completed',
  NOT_APPLICABLE = 'not_applicable',
  NOT_COMPLETED = 'not_completed',
}

export class LoadsServiceLoadMission {
  id: string;
  status: MissionStatus;
  loads: LoadsServiceLoad[];
}
