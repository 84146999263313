import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { LoadActiveTabsModel } from '@haulynx/store';
import { loadActiveTabsConfig, Tab } from '@haulynx/types';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class LoadActiveTabSelectedGuard implements CanActivate {
  constructor(private loadActiveTabsModel: LoadActiveTabsModel) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const { id = null } = route.params;
    const searchId = loadActiveTabsConfig.SEARCH.id;
    let { selected = null } = route.data;

    if (id) {
      const url = `details/${id}`;

      selected = new Tab({
        id,
        url,
        selected: true,
        queryParams: route.queryParams,
      });
    }

    return this.loadActiveTabsModel.tabsSelected$.pipe(
      withLatestFrom(this.loadActiveTabsModel.tabs$),
      map(([tabSelected, tabs]) => {
        const tabId = _.get(tabSelected, 'id', null);
        const activeId = _.get(selected, 'id', null);

        if (activeId && !tabs.get(activeId)) {
          this.loadActiveTabsModel.addTabs([selected]);
        }

        if (activeId && activeId !== tabId && activeId !== searchId) {
          this.loadActiveTabsModel.selectTab(selected);
        }

        return true;
      })
    );
  }
}
