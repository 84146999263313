<div class="white-progress-spinner" (click)="click()">
  <div class="progress">
    <mat-progress-spinner
      [mode]="mode"
      [value]="value"
      [diameter]="diameter"
      [strokeWidth]="strokeWidth"
    ></mat-progress-spinner>
  </div>
  <div *ngIf="mode === 'determinate'" class="background">
    <mat-progress-spinner
      class="background"
      [mode]="determinate"
      value="100"
      [diameter]="diameter"
      [strokeWidth]="strokeWidth"
    ></mat-progress-spinner>
  </div>
  <ng-container *ngIf="mode === 'determinate'">
    <ng-container *ngIf="value !== 100; else complete">
      <div class="percentage" [ngStyle]="{ height: diameter, width: diameter }">{{ value }}%</div>
    </ng-container>
    <ng-template #complete>
      <div class="percentage" [ngStyle]="{ height: diameter, width: diameter }">
        <mat-icon>check</mat-icon>
      </div>
    </ng-template>
  </ng-container>
</div>
