import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Carrier } from '@haulynx/types';

@Component({
  selector: 'new-device-dialog',
  templateUrl: './device-new.component.html',
  styleUrls: ['./device-new.component.scss'],
})
export class NewDeviceDialog {
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  imeis: string[] = [];
  imeiIsInvalid = false;
  deviceForm = new FormGroup({
    carrier: new FormControl(null, [Validators.required]),
    imeis: new FormControl(this.imeis, []),
  });

  constructor(@Inject(MAT_DIALOG_DATA) public data: Carrier[]) {}

  resetImeiIsInvalid(event: KeyboardEvent) {
    if (event.code !== 'Enter' && event.code !== 'Comma') {
      this.imeiIsInvalid = false;
    }
  }

  public add(event: MatChipInputEvent) {
    this.imeiIsInvalid = false; // reset invalid error
    if (event?.input?.value?.length !== 15 || isNaN(+event?.input?.value)) {
      this.imeiIsInvalid = true;
      return;
    }

    if (event?.input?.value?.length === 15) {
      this.imeis.push(event.value);
      event.input.value = '';
    }
  }

  public remove(index: number) {
    this.imeis.splice(index, 1);
  }
}
