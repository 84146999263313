import { AppSettingsState } from './app-settings.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const getAppSettingsSelector = createFeatureSelector<AppSettingsState>('appSettings');
export const userStateSelector = createSelector(getAppSettingsSelector, (state) => state.user);
export const isAppInitSelector = createSelector(getAppSettingsSelector, (state) => state.isAppInit);
export const carrierStateSelector = createSelector(getAppSettingsSelector, (state) => state && state.carrier);
export const tokenStateSelector = createSelector(getAppSettingsSelector, (state) => state.token);
export const isLoadingStateSelector = createSelector(getAppSettingsSelector, (state) => state.isLoading);
export const loadFeedPermissionStateSelector = createSelector(
  getAppSettingsSelector,
  (state) => state.loadFeedPermission
);
export const widgetReferrerSelector = createSelector(getAppSettingsSelector, (state) => state.widgetReferrer);
export const loadDetailsRedirectUrlSelector = createSelector(
  getAppSettingsSelector,
  (state) => state.loadDetailsRedirectUrl
);
