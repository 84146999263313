import { ChangeDetectionStrategy, Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FeatureFlag, TrackingType } from '@haulynx/types';

@Component({
  selector: 'app-add-driver',
  templateUrl: './add-driver.component.html',
  styleUrls: ['./add-driver.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddDriverComponent implements OnInit {
  form: FormGroup;
  carrierDot: string;
  carrierId: string;
  trackingType: string;
  isChecked = false;
  driverLiteFlag = FeatureFlag.DRIVER_LITE;
  mobileTracking = false;

  constructor(
    private fb: FormBuilder,
    @Optional() public dialogRef: MatDialogRef<AddDriverComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { carrierDot: string; carrierId: string; trackingType: string }
  ) {
    const { carrierDot = null, carrierId = null, trackingType } = data;
    this.carrierDot = carrierDot;
    this.carrierId = carrierId;
    this.trackingType = trackingType;
  }

  ngOnInit(): void {
    this.initForm();
    this.mobileTracking =
      !this.trackingType || this.trackingType === TrackingType.DRIVER_LITE || this.trackingType === TrackingType.MANUAL
        ? true
        : false;
  }

  initForm(): void {
    this.form = this.fb.group({
      name: [null, [Validators.pattern(/[a-zA-Z0-9'-]/), Validators.required]],
      phone: [null, [Validators.pattern(/^\(?[\d]{3}\)?[\s-]?[\d]{3}[\s-]?[\d]{4}$/), Validators.required]],
      email: [null],
      carrierDot: this.carrierDot,
      carrierId: this.carrierId,
      tracking: [false],
    });
  }

  toggle($event: Event) {
    this.isChecked = !this.isChecked;
    if (!this.isChecked) {
      this.form.get('email').setValue(null);
      this.form.get('email').clearValidators();
      this.form.get('email').updateValueAndValidity();
    } else {
      this.form.get('email').setValidators([Validators.email, Validators.required]);
    }
    this.form.get('tracking').setValue(this.isChecked);
  }

  save(): void {
    if (this.form.valid) {
      this.form.get('name').setValue(this.form.get('name').value.trim());
      this.dialogRef.close(this.form.value);
    }
  }
}
