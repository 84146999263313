import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { EmphasizedMilestone, KeyValuePair, LoadsServiceLoad, Milestone, MilestoneUser, User } from '@haulynx/types';
import { BehaviorSubject } from 'rxjs';
@Component({
  selector: 'app-milestone-content',
  templateUrl: './milestone-content.component.html',
  styleUrls: ['./milestone-content.component.scss'],
})
export class MilestoneContentComponent implements OnInit {
  @Input() user: User;
  @Input() currentUserType: MilestoneUser;
  @Input() milestones: Milestone[];
  @Input() load: LoadsServiceLoad;
  @Input() collapseMilestone: { action: string; milestoneId?: string };
  @Output() clickEvent = new EventEmitter();
  @Input() badgeMilestones: KeyValuePair[];
  @Input() savedCollapseMilestone: KeyValuePair[];

  constructor() {}

  ngOnInit(): void {}

  onClickEvent(click: {
    event: string;
    milestoneIndex: number;
    commentIndex: number;
    emphasizedMilestone: EmphasizedMilestone;
  }): void {
    this.clickEvent.emit(click);
  }
}
