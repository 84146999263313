import { IColumns2 } from '../data-table-v2';
import { DataTableConfig } from '../data-table/data-table-type';
import { KeyValuePair } from '../generic/key-value-pair';
import { ColumnField } from '../search-view/default-columns';

export const documentsDataColumns = (
  columnSettings: Array<{ key: string; value: boolean; order?: number }> = []
): IColumns2[] => {
  const allColumns: IColumns2[] = [
    {
      field: ColumnField.UPDATED_AT,
      label: 'Date & Time',
      isSortable: false,
      isCustomCell: true,
      sortConfig: [],
      width: '150px',
      isVisible: true,
      isPinned: true,
      order: 1,
    },
    {
      field: ColumnField.USER_TYPE,
      label: 'User Type',
      isCustomCell: true,
      isSortable: false,
      width: '150px',
      sortConfig: [],
      isVisible: true,
      isPinned: true,
      order: 2,
    },
    {
      field: ColumnField.USER,
      label: 'User',
      filters: [],
      sortConfig: [],
      isCustomCell: true,
      isSortable: false,
      width: '140px',
      isVisible: true,
      order: 3,
    },
    {
      field: ColumnField.DOCUMENT_TYPE,
      label: 'Document Type',
      sortConfig: [],
      filters: [''],
      isCustomCell: true,
      isSortable: false,
      width: '150px',
      isVisible: true,
      order: 4,
    },
    {
      field: ColumnField.DOCUMENT,
      label: 'Document',
      sortConfig: [],
      filters: [],
      isCustomCell: true,
      isSortable: false,
      width: 'auto',
      isVisible: true,
      order: 5,
    },
  ];

  return allColumns.reduce((acc: IColumns2[], column: IColumns2): IColumns2[] => {
    const setting = columnSettings.find(
      (value: { key: string; value: boolean; order?: number }) => value.key === column.field
    );
    if (!setting) return [...acc, column];

    return [...acc, { ...column, isVisible: setting.value, order: setting.order || column.order }];
  }, []);
};

export enum DocumentTypes {
  RATE_CONFIRMATION = 'RATE_CONFIRMATION',
  PROOF_OF_DELIVERY = 'PROOF_OF_DELIVERY',
  BILL_OF_LADING = 'BILL_OF_LADING',
  SHIPPING_INSTRUCTION = 'SHIPPING_INSTRUCTION',
  PACKING_SLIP = 'PACKING_SLIP',
  PICK_TICKET = 'PICK_TICKET',
  PALLET_LABEL = 'PALLET_LABEL',
  SHIPPING_LOG = 'SHIPPING_LOG',
  LUMPER_RECEIPT = 'LUMPER_RECEIPT',
  INSPECTION_IMAGE = 'INSPECTION_IMAGE',
  LOAD_INVOICE = 'LOAD_INVOICE',
  OTHER = 'OTHER',
}

export const loadOverviewDocumentsConfigOptions: DataTableConfig = {
  globalFilter: false,
  reorderColumns: false,
  columnVisibility: false,
  reorderRows: false,
  showBulkActions: false,
  sortColumns: false,
  scrollable: false,
  checkBoxSelection: false,
  filterLocally: false,
  showTopPaginator: false,
  showBottomPaginator: false,
  isLaneRate: false,
  bulkActions: null,
  pageAmount: 300,
};
