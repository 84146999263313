import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsService, WindowRef } from '@haulynx/services';
import { CarrierEntityService, LoadEntityService } from '@haulynx/store';
import {
  ANALYTICS_EVENT,
  Bid,
  CarrierToolbarTab,
  FeatureFlag,
  FFState,
  KeyValuePair,
  laneHistoryActionMenu,
  LoadActionEvent,
  LoadIdentifierType,
  LoadsServiceLoad,
  MergedCarrierData,
  RecommendationVote,
  RecommendedCarriers,
  RecommendedType,
  User,
} from '@haulynx/types';
import { aliveWhile, getLoadsServiceLoadAlternateId } from '@haulynx/utils';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'haulynx-toolbar-card',
  templateUrl: './toolbar-card.component.html',
  styleUrls: ['./toolbar-card.component.scss'],
})
export class ToolbarCardComponent implements OnInit {
  @Input() tab: CarrierToolbarTab;
  @Input() data: any;
  @Input() selectedLoad: LoadsServiceLoad;
  @Input() user: User;
  @Input() latestBid?: Bid;
  @Input() showBids = false;
  @Input() featureFlags: FFState;
  @Input() bidCreateLoading: boolean;
  @Input() recVote: RecommendationVote = null;
  @Output() openCarrierProfile = new EventEmitter<RecommendedCarriers>();
  @Output() carrierAction = new EventEmitter<{
    event: KeyValuePair;
    carrier: RecommendedCarriers;
    loadId: string;
    currentTab: CarrierToolbarTab;
  }>();
  @Output() submitBidForm = new EventEmitter();
  @Output() chosenCarrier = new EventEmitter();

  alive = aliveWhile();
  laneHistoryActionMenu: KeyValuePair[] = laneHistoryActionMenu;
  recommendedType = RecommendedType;
  loadLockState$: Observable<{ [loadId: string]: LoadActionEvent }>;
  showDetails = false;
  callingCarrierDot = '';
  latestBidChecked;
  carrierToolbarTab = CarrierToolbarTab;
  recommendationVote = RecommendationVote;
  recommendationVoteFlag: FeatureFlag = FeatureFlag.RECOMMENDATION_VOTE;
  carrierCalled = false;

  get recommendationText() {
    switch (this.data?.explanation?.type) {
      case RecommendedType.BEST_PRICE:
        return (
          'This carrier was recommended because they took a great price in the past #' +
          getLoadsServiceLoadAlternateId(this.data.load as unknown as LoadsServiceLoad, LoadIdentifierType.TMW_NUMBER)
        );
      case RecommendedType.FREQUENT_HAUL:
        return 'This carrier was recommended because they haul loads frequently';
      case RecommendedType.SIMILAR_HAUL:
        return (
          'This carrier was recommended because of similar load #' +
          getLoadsServiceLoadAlternateId(this.data.load as unknown as LoadsServiceLoad, LoadIdentifierType.TMW_NUMBER)
        );
      case RecommendedType.HISTORICAL_BID:
        return (
          'This carrier was recommended because of historical bid on load #' +
          getLoadsServiceLoadAlternateId(this.data.load as unknown as LoadsServiceLoad, LoadIdentifierType.TMW_NUMBER)
        );
      default:
        return 'unknown';
    }
  }

  constructor(
    private router: Router,
    public loadEntityService: LoadEntityService,
    private windowRef: WindowRef,
    public carrierEntityService: CarrierEntityService,
    private analytics: AnalyticsService
  ) {
    this.loadLockState$ = this.loadEntityService.lockState.loadLockStream$;

    this.carrierEntityService.getMergedCarrier.data$.pipe(takeUntil(this.alive)).subscribe((carrier) => {
      if (this.callingCarrierDot === carrier?.dot && carrier?.phone) {
        this.windowRef.getNativeWindow().open('tel:' + carrier?.phone, '_blank');
        this.callAnalytics(this.tab, carrier, 'PHONE_CLICKED');
        this.callingCarrierDot = '';
      }
    });
  }

  callAnalytics(currentTab: CarrierToolbarTab, carrier: MergedCarrierData, button: string) {
    switch (currentTab) {
      case 0:
        this.analytics.logEvent(ANALYTICS_EVENT['CARRIER_TOOLBAR_REC_' + button], { clickedCarrier: carrier });
        return;
      case 1:
        this.analytics.logEvent(ANALYTICS_EVENT['CARRIER_TOOLBAR_SEARCH_' + button], { clickedCarrier: carrier });
        return;
      case 2:
        this.analytics.logEvent(ANALYTICS_EVENT['CARRIER_TOOLBAR_OWNED_' + button], { clickedCarrier: carrier });
        return;
    }
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.alive.destroy();
  }

  ngOnChanges(changes: SimpleChanges) {
    const { selectedLoad, latestBid, data } = changes;

    if (latestBid) {
      this.latestBidChecked =
        latestBid.currentValue?.carrier?.dot === this.data?.dot ||
        latestBid.currentValue?.carrier?.dot === this.data?.carrier?.dot
          ? latestBid?.currentValue
          : null;
    }

    if (
      selectedLoad?.currentValue?.id != selectedLoad?.previousValue?.id ||
      (selectedLoad && !selectedLoad?.currentValue)
    ) {
      this.showBids = false;
    }
  }

  showLoadDetails() {
    if (
      !this.featureFlags.CARRIER_TOOLBAR_OPPORTUNITIES &&
      this.data?.explanation.type == this.recommendedType.FREQUENT_HAUL
    )
      return;
    this.showDetails = !this.showDetails;
  }

  toggleBids() {
    this.showBids = !this.showBids;
    this.chosenCarrier.emit(
      this.data?.freightId ? this.data?.carrier : this.data?.load?.carrier || { dot: this.data.dot }
    );
  }

  vote(vote: RecommendationVote) {
    //TODO connect to api dispatch when gateway ready
    if (vote === RecommendationVote.LIKE) {
      this.recVote = RecommendationVote.LIKE;
    }
    if (vote === RecommendationVote.DISLIKE) {
      this.recVote = RecommendationVote.DISLIKE;
    }
  }

  onCarrierSelect(): void {
    this.openCarrierProfile.emit(this.data);
  }

  submitBid(e: Event): void {
    this.callAnalytics(this.tab, this.data, 'BID_CLICKED');
    this.submitBidForm.emit(e);
  }

  routeToLoad(id: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree([`loads/${id}`]));
    window.open(url, '_blank');
  }

  onCarrierAction(action: KeyValuePair, data: any): void {
    const carrier = data?.carrier || data;
    this.callingCarrierDot = carrier.dot;
    if (action.key === 'Call Carrier') {
      this.carrierCalled = true;
      this.carrierEntityService.getMergedCarrier.dispatch({ dot: carrier.dot });
    } else {
      this.carrierAction.emit({
        event: action,
        carrier: carrier,
        loadId: this.selectedLoad?.id,
        currentTab: this.tab,
      });
    }
  }
}
