<haulynx-dialog-template (closed)="close()">
  <div title>Registration services provided by RMIS</div>
  <div content fxLayout="column" fxLayoutGap="24px">
    <div>
      Thank you for your interest in becoming an approved carrier. Below are some of our key requirements in order for
      your company to qualify as an approved carrier. All elements identified as ‘required’ must be completed in order
      for the qualification process to be completed. If your company is not compliant to one or several of the
      qualification criteria, you may return at another time to register once the non-compliant items are corrected.
    </div>
    <div class="contact">
      TO BECOME AN APPROVED CARRIER YOU MUST PROVIDE THE REQUESTED INFORMATION AND MEET THE FOLLOWING CRITERIA:
      <br />

      <ul>
        <li>
          Provide your MC/MX# or DOT# to begin registration. Intrastate carriers without a DOT# may provide an
          applicable state registration number to begin registration.
        </li>
        <li>Provide the carrier contact authorized to enter into and bind your company to any agreements.</li>
        <li>Submit an electronic W9. (Canadian carriers will download special W-8 IRS form and submit offline.)</li>
        <li>Complete the Carrier Profile which includes Mode, Commodity, Equipment and Operating Areas.</li>
        <li>Read and ACCEPT the “Broker/Carrier Agreements”.</li>
        <li>
          Provide a Certificate of Insurance that meets the insurance minimum requirements below. Certificates must be
          submitted from your insurance producer (agent);
        </li>
        <ul>
          <li>$100,000 Minimum Cargo Coverage</li>
          <li>$1,000,000 Auto Coverage</li>
          <li>$1,000,000 General Liability Coverage</li>
        </ul>
        <li>
          Provide your requested payment option but to help ensure payment within terms we highly suggest: 1) Email
          Invoice [submit all necessary documents] 2) Accept all payments via ACH / direct deposit
        </li>
        <li>Provide 2 trade references.</li>
        <li>
          If RMIS does not already have a copy of your certificate on file, we will request one for you from your
          insurance agent (producer).
        </li>
        <li>You will be required to meet the following Carrier Compliance Requirements:</li>
        <ul>
          <li>Safety Rating must be Satisfactory, Conditional or None.</li>
          <li>Active Operating Authority.</li>
          <li>All Carriers must have appropriate Federal, State, or Provincial Authority.</li>
        </ul>
      </ul>
    </div>
  </div>
</haulynx-dialog-template>
