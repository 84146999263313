import { ActionReducer } from '@ngrx/store';
import { List } from 'immutable';
import { combineReducersFlat, createReducer } from '../helpers/reducer';
import { ISearchState } from './state';

function searchSuccess() {
  return function (state: ISearchState, action): ISearchState {
    const { total = null, limit = null, page = null, entities = [], docs = [], data = [], paginator = null } =
      action.payload || {};
    let newState = {};

    if (paginator) {
      newState = {
        ...newState,
        total: paginator.total,
        page: paginator.page,
      };
    }

    if (total) {
      newState = { ...newState, total };
    }

    if (limit) {
      newState = { ...newState, limit };
    }

    if (page) {
      newState = { ...newState, page };
    }

    const items = docs.length ? docs : entities.length ? entities : data.length ? data : [];

    return {
      ...state,
      ...newState,
      entities: List(items),
      loading: false,
    };
  };
}

function search() {
  return (state: ISearchState, action): ISearchState => {
    const { query = null, total = null, limit = null, page = null } = action.payload || {};

    let newState = {};

    if (query) {
      newState = {
        ...newState,
        query,
        total: 0,
        limit: 10,
        page: 1,
      };
    }

    if (total) {
      newState = { ...newState, total };
    }

    if (limit) {
      newState = { ...newState, limit };
    }

    if (page) {
      newState = { ...newState, page };
    }

    return {
      ...state,
      loading: true,
      ...newState,
    };
  };
}

function searchError() {
  return (state: ISearchState, action): ISearchState => {
    return {
      ...state,
      loading: false,
      entities: List(),
    };
  };
}

function searchUpdate() {
  return (state: ISearchState, action) => {
    const { id, entity } = action.payload;
    let entities = state.entities;
    const existedEntityKey = state.entities.findKey((item) => {
      return item.id === id;
    });

    if (existedEntityKey) {
      entities = state.entities.update(existedEntityKey, id, (item) => {
        return { ...item, ...entity };
      });
    }

    return {
      ...state,
      entities,
    };
  };
}

function searchDelete() {
  return (state: ISearchState, action) => {
    const { id } = action.payload;
    let entities = state.entities;
    const existedEntity = state.entities.get(id);

    if (existedEntity) {
      entities = state.entities.filter((item) => item.id !== id);
    }

    return {
      ...state,
      entities,
    };
  };
}

export function createSearchReducer(actionTypes) {
  return {
    getReducer(...args): ActionReducer<any, any> {
      const searchReducer = createReducer([actionTypes.SEARCH], search, ...args);
      const searchUpdateReducer = createReducer([actionTypes.SEARCH_UPDATE], searchUpdate, ...args);
      const searchDeleteReducer = createReducer([actionTypes.SEARCH_DELETE], searchDelete, ...args);
      const searchSuccessReducer = createReducer([actionTypes.SEARCH_SUCCESS], searchSuccess, ...args);
      const searchErrorReducer = createReducer([actionTypes.SEARCH_ERROR], searchError, ...args);

      const newReducer = combineReducersFlat([
        searchReducer,
        searchUpdateReducer,
        searchDeleteReducer,
        searchSuccessReducer,
        searchErrorReducer,
      ]);

      return newReducer;
    },
  };
}
