import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AppModel } from '@haulynx/store';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class LoadFeedPermissionGuard implements CanActivate {
  constructor(private router: Router, public appModel: AppModel) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.appModel.loadFeedPermission$.pipe(
      switchMap((hasPermission) => {
        if (!hasPermission) {
          this.router.navigate(['/dashboard/loads/search/active']);
        }

        return of(hasPermission);
      })
    );
  }
}
