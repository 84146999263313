<div class="actions-container">
  <div class="actions-container__buttons">
    <div class="dropdown-buttons">
      <app-button-dropdown
        [closedIcon]="'arrow_drop_down'"
        [openIcon]="'arrow_drop_up'"
        [buttonLabelPrefix]="'Show'"
        [isMultiSelect]="true"
        [dropdownList]="showAll"
        [buttonWidth]="'115px'"
        [showDropdownFromLeft]="true"
        [haveShowAllButton]="true"
        (clickedDropdownItem)="clickEvent($event, 'Display')"
      >
      </app-button-dropdown>
    </div>

    <div class="dropdown-buttons">
      <app-button-dropdown
        [closedIcon]="'arrow_drop_down'"
        [openIcon]="'arrow_drop_up'"
        [isMultiSelect]="false"
        [dropdownList]="recent"
        [buttonWidth]="'115px'"
        (clickedDropdownItem)="clickEvent($event, 'Filter')"
      >
      </app-button-dropdown>
    </div>

    <action-buttons [buttons]="actionButtons" [disableButtons]="disabledButtons" (onClick)="clickEvent(null, $event)">
    </action-buttons>

    <div class="dropdown-buttons">
      <app-button-dropdown
        [closedIcon]="'more_vert'"
        [openIcon]="'menu'"
        [showButtonLabel]="false"
        [isMultiSelect]="false"
        [dropdownList]="hamburger"
        [buttonBackgroundColor]="'transparent'"
        (clickedDropdownItem)="clickEvent($event, 'Menu')"
        data-test="more-button"
      >
      </app-button-dropdown>
    </div>
  </div>
</div>
