import gql from 'graphql-tag';

export const GetUsersByCarrier = gql(`
  query GetUsersByCarrier($carrierId: ID!) {
    getUsersByCarrier(carrierId: $carrierId) {
      id
      name
      phone
      email
      isDriver
    }
  }
`);
