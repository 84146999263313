import { Observable } from 'rxjs';
import { Carrier, MapboxRoute } from '..';
import { LoadsServiceLoad } from '../../loads-service';

export enum CarrierManagerType {
  PLACE_BID = 'placeBid',
  UPDATE_BID = 'updateBid',
  CANCEL_BID = 'cancelBid',
  DECLINE_OFFER = 'declineOffer',
  DECLINE_COUNTER_OFFER = 'declineCounterOffer',
  UPDATE_DRIVER_PHONE = 'updateDriverPhone',
}

export enum CarrierDialogTitle {
  PLACE_BID = 'Place Bid',
  UPDATE_BID = 'Update Bid',
  CANCEL_BID = 'Cancel Bid',
  DECLINE_OFFER = 'Decline Offer',
  UPDATE_DRIVER_PHONE = `Confirm Driver's Number`,
}

export interface CarrierDialogEventType {
  type: CarrierManagerType;
  title: CarrierDialogTitle;
}

export interface BaseCarrierDialogData {
  load: Observable<LoadsServiceLoad>;
  state: any;
  actionFn: (...args: any[]) => void;
  actionCloseFn?: (...args: any[]) => void;
}

export interface CarrierBookActionDialogData extends BaseCarrierDialogData {
  bookingType: 'acceptOffer' | 'bookItNow';
  loadMapboxRoute$: Observable<{ route: MapboxRoute[]; loading: boolean }>;
}

export interface CarrierUpdatePlaceBidDialogData extends BaseCarrierDialogData {
  title: CarrierDialogTitle;
  managerType: CarrierManagerType;
  carrier: Carrier;
}

export interface CarrierConfirmPhoneDialogData extends BaseCarrierDialogData {
  type: CarrierManagerType;
  title: CarrierDialogTitle;
  managerType: CarrierManagerType;
}
