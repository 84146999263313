import gql from 'graphql-tag';

export const AddPageToReleaseNote = gql`
  mutation AddPageToReleaseNote($releaseNoteId: StrictId!, $page: WhatsNewPageInput!) {
    addWhatsNewPageToReleaseNote(releaseNoteId: $releaseNoteId, page: $page) {
      id
      title
      pages {
        id
        title
        description
        media {
          type
          data
        }
      }
      userType
      date
      expiration
      hasViewed
    }
  }
`;
