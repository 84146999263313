<div class="add-bid" [ngClass]="{ 'add-bid__selector-padding': isSorterVisible }">
  <h4 class="add-bid__title">Bids</h4>

  <ng-container *ngIf="!disableBids">
    <div *ngIf="carrier; else noCarrier">
      <button
        mat-button
        class="muted"
        (click)="toggleAddBidForm()"
        data-test="show-form"
        *appFeatures="newRecommendedCarriers; else newRecommendedCarriersTableInactive"
      ></button>

      <ng-template #newRecommendedCarriersTableInactive>
        <mat-icon>
          <ng-container *ngIf="showForm; else addIcon">remove_circle</ng-container>
          <ng-template #addIcon>add_circle</ng-template>
        </mat-icon>
        <span>
          {{ load.carrierBid?.status ? 'Update' : 'Add a' }}
          bid for <span class="add-bid__carrier__name">{{ carrier.name || noNameCarrier }}</span>
        </span>
      </ng-template>
    </div>

    <ng-template #noCarrier>Select a carrier above to add a bid</ng-template>
  </ng-container>
</div>

<mat-accordion displayMode="flat" hideToggle="true" class="bids" *ngIf="showForm && carrier">
  <mat-expansion-panel expanded disabled [formGroup]="form" class="mat-elevation-z0">
    <mat-expansion-panel-header collapsedHeight="64px">
      <mat-panel-title>
        <div class="bids__title">
          {{ carrier.name || noNameCarrier }}
        </div>
      </mat-panel-title>

      <mat-panel-description class="bids__price">
        <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
          <input
            matInput
            currencyMask
            [options]="{ thousands: ',', decimal: '.', precision: 2 }"
            hideRequiredMarker
            [placeholder]="pricePlaceholder | currency: 'USD':'symbol':'1.2-2'"
            [min]="priceMin"
            [max]="priceMax"
            formControlName="price"
            minlength="1"
            autocomplete="off"
            required
          />
          <mat-error *ngIf="form.controls['price'].getError('min')">
            Price > {{ priceMin | currency: 'USD':'symbol':'1.2-2' }}
          </mat-error>
          <mat-error *ngIf="form.controls['price'].getError('max')">
            Price < {{ priceMax | currency: 'USD':'symbol':'1.2-2' }}
          </mat-error>
        </mat-form-field>
      </mat-panel-description>
        <mat-panel-description class="bids__status" *ngIf="!((userEntityService.featureFlags$ | async)[bidV2Feature])">
          <mat-form-field class="app-form-field" appearance="fill" floatLabel="always" *ngIf="!userIsCarrier">
            <app-drop-down [data]="bidStatusOptions" formControlName="status" placeholder="Select Status" required>
            </app-drop-down>
          </mat-form-field>
        </mat-panel-description>
      <mat-panel-description class="bids__action">
        <button
          type="submit"
          mat-button
          class="bids__button"
          [disabled]="form.invalid || isLoading || disableCreate"
          (click)="onCreateBid(form.value)"
          data-test="create-bid"
        >
          <mat-icon *ngIf="isLoading; else textLabel">
            <mat-spinner class="bids__button__spinner" diameter="23"></mat-spinner>
          </mat-icon>
          <ng-template #textLabel>{{
            load?.carrierBid?.status && load?.carrierBid?.status !== BidStatusType.AUTO_REJECTED ? 'Update' : 'Create'
          }}</ng-template>
        </button>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div class="bids__details">
      <div class="bids__notes">
        Notes
        <br />
        <mat-form-field appearance="none">
          <textarea class="bids__notes__textarea" matInput formControlName="notes"></textarea>
        </mat-form-field>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
