import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { LoadSearchTimelineComponent } from './load-search-timeline.component';

@NgModule({
  declarations: [LoadSearchTimelineComponent],
  imports: [CommonModule, MaterialModule, PipesModule, FlexLayoutModule],
  exports: [LoadSearchTimelineComponent],
})
export class LoadSearchTimelineModule {}
