import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { aliveWhile } from '@haulynx/utils';

@Component({
  selector: 'haulynx-carrier-lane-form',
  templateUrl: './carrier-lane-form.component.html',
  styleUrls: ['./carrier-lane-form.component.scss'],
})
export class CarrierLaneFormComponent implements OnDestroy, OnChanges {
  @Input() formState;
  @Input() isLoading;
  @Output() onCreate = new EventEmitter();

  alive = aliveWhile();
  form: FormGroup;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      dot: [null],

      origin: [null, Validators.required],
      orgLat: [null, Validators.required],
      orgLon: [null, Validators.required],

      destination: [null, Validators.required],
      destLat: [null, Validators.required],
      destLon: [null, Validators.required],
    });
  }

  onGpsLocation(formControlName, { lat, lon }) {
    if (formControlName === 'origin') {
      this.form.get('orgLon').setValue(lon);
      this.form.get('orgLat').setValue(lat);
    }

    if (formControlName === 'destination') {
      this.form.get('destLon').setValue(lon);
      this.form.get('destLat').setValue(lat);
    }
  }

  create() {
    const isValid = this.form.valid;

    if (isValid) {
      const newTruck = this.form.getRawValue();

      this.onCreate.emit(newTruck);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { formState } = changes;

    if (formState) {
      const formData = formState.currentValue;

      this.form.patchValue(formData ? formData : {});
    }
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }
}
