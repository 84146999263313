import { FormGroup } from '@angular/forms';

export function matchingPasswords(passwordKey: string, confirmPasswordKey: string) {
  return (group: FormGroup): { [key: string]: unknown } => {
    const control = group.controls[passwordKey];
    const matchingControl = group.controls[confirmPasswordKey];

    if (matchingControl.errors && !matchingControl.errors.mismatchedPasswords) {
      // return if another validator has already found an error on the matchingControl and it is not this one
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mismatchedPasswords: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}
