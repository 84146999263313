import { Injectable } from '@angular/core';
import { IColumns } from '@haulynx/types';
import { Store } from '@ngrx/store';
import { List } from 'immutable';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppState } from '../../main-store/app.reducers';
import { FavoriteAddressActions } from './favorite-address.actions';
import {
  favoriteAddressFormHttpStatus,
  getFavoriteAddressSearchSelector,
  isLoadingFavoriteAddressForm,
} from './favorite-address.selectors';
import { FavoriteAddressFormHttpStatus } from './favorite-address.state';

@Injectable()
export class FavoriteAddressModel {
  entities$: Observable<List<any>> = this.store.select(getFavoriteAddressSearchSelector.getEntities);
  isLoading$: Observable<boolean> = this.store.select(getFavoriteAddressSearchSelector.getLoading);
  isLoadingUserForm$: Observable<boolean> = this.store.select(isLoadingFavoriteAddressForm);
  favoriteAddressFormHttpStatus$: Observable<{
    httpStatus: FavoriteAddressFormHttpStatus;
    response: any;
  }> = this.store.select(favoriteAddressFormHttpStatus);
  addressColumns$: Observable<IColumns[]> = of([]).pipe(
    map(() => {
      const columns: IColumns[] = [
        {
          label: '',
          dataIndex: 'icon',
          width: '80px',
          isCustomCell: true,
          isSortable: false,
        },
        {
          label: 'Name',
          dataIndex: 'name',
          width: '200px',
          isSortable: false,
        },
        {
          label: 'Address',
          dataIndex: 'address',
          width: '500px',
          isSortable: false,
        },
      ];

      return columns;
    })
  );

  constructor(private store: Store<AppState>) {}

  search(filter = {}) {
    this.store.dispatch(FavoriteAddressActions.search(filter));
  }

  create(address) {
    this.store.dispatch(FavoriteAddressActions.createAddress(address));
  }
}
