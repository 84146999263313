import gql from 'graphql-tag';

export const GetFeatureFlags = gql`
  query getFeatureFlags($platform: FeatureFlagPlatform, $environment: WebEnvironment) {
    getFeatureFlags(platform: $platform, environment: $environment) {
      name
      description
      permissions {
        environment
        dots
        users
      }
      platforms {
        web
        ios
        android
      }
    }
  }
`;

export const GetFeatures = gql`
  query getFeatureFlags($platform: FeatureFlagPlatform, $environment: WebEnvironment) {
    getFeatureFlags(platform: $platform, environment: $environment) {
      name
      description
    }
  }
`;
