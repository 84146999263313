import gql from 'graphql-tag';

export const CreateMissions = gql`
  mutation CreateMissions($missionBatchInput: [MissionCreateInput!]!) {
    createMissions(missionBatchInput: $missionBatchInput) {
      id
      status
      loads {
        id
      }
    }
  }
`;
