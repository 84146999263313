import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { User } from '@haulynx/types';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'haulynx-assigned-brokers',
  templateUrl: './assigned-brokers.component.html',
  styleUrls: ['./assigned-brokers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssignedBrokersComponent implements OnInit, OnChanges {
  @Input() brokers: Partial<User>[] = [];
  @Input() maxCount = 2;
  @Input() horizontal = false;

  exposed$ = new BehaviorSubject<User[]>(this.brokers);
  exposedJoined$ = new BehaviorSubject<string>('');

  allItems: string;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    const firstPart = this.brokers?.slice(0, this.maxCount);
    if (this.horizontal) {
      this.exposedJoined$.next(firstPart.map((user) => user?.usxId || user?.name?.split(' ')[0]).join(', '));
    } else {
      this.exposed$.next(firstPart);
    }
    this.allItems = this.brokers?.map((broker) => broker.usxId || broker.name).join('\n');
  }

  trackByFn(index: number, user: User): string {
    return user.id;
  }
}
