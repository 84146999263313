/* Carrier compliance status
0: new
2: open
3: pending
4: resolved
*/

export enum ComplainceStatus {
  NEW = 0,
  OPEN = 2,
  PENDING = 3,
  RESOLVED = 4,
  CLOSED = 5,
}

export enum ComplianceState {
  NONE = 'none',
  PENDING = 'pending',
  DONE = 'done',
}

export interface CarrierComplianceTicket {
  id: string;
  broker_id: string;
  broker_email: string;
  reason: string;
  carrier_id: string;
  load_id: string;
  status: number;
  status_update: string;
  freshdesk_id: string;
  created_at: number;
  updated_at: number;
}

export interface NewCarrierComplianceTicketInput {
  brokerId: string;
  brokerEmail: string;
  reason: string;
  carrierId: string;
  loadId: string;
}

export interface CarrierComplianceTicketParamsInput {
  brokerId?: string;
  brokerEmail?: string;
  reason?: string;
  carrierId?: string;
  loadId?: string;
  status?: number;
  statusUpdate?: string;
}
