import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, UrlTree } from '@angular/router';
import { FeatureFlagService } from '@haulynx/services';
import { UserEntityService } from '@haulynx/store';
import { FFState } from '@haulynx/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class FeatureFlagGuard implements CanActivate {
  previousUrl: string;
  constructor(
    private userEntityService: UserEntityService,
    private featureFlagService: FeatureFlagService,
    private location: Location
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const features: string[] = route.data?.features || [];

    return this.userEntityService.featureFlags$.pipe(
      map((featureState: FFState) => {
        if (this.featureFlagService.checkFlag(featureState, features)) {
          return true;
        }
        // bounce back to last safe url
        this.location.back();
        return false;
      })
    );
  }
}
