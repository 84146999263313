import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TitleService } from '@haulynx/services';
import { LoadAvailableTabsModel, LoadEntityService } from '@haulynx/store';
import { loadAvailableTabsConfig, LoadsServiceLoad, LoadsServiceLoadLocation, Tab } from '@haulynx/types';
import { AddressPosition, aliveWhile, splitAddress } from '@haulynx/utils';
import { delay, filter, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-load-available-details',
  templateUrl: './load-available-details.component.html',
  styleUrls: ['./load-available-details.component.scss'],
})
export class LoadAvailableDetailsComponent implements OnInit, OnDestroy {
  alive = aliveWhile();
  loadId: string = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private loadAvailableTabsModel: LoadAvailableTabsModel,
    private titleService: TitleService,
    public loadEntityService: LoadEntityService
  ) {}

  ngOnInit() {
    const { id } = this.activatedRoute.snapshot.params;
    this.loadId = id;
    this.loadEntityService.getLoadByIdManager.dispatch(id);
    this.loadEntityService.getLoadByIdManager
      .getEntityById(id)
      .pipe(takeUntil(this.alive), delay(0))
      .subscribe((load: LoadsServiceLoad) => {
        if (load && load.id === id) {
          const [pickUp, dropOff] = load.locations;
          this.setTitle(pickUp, dropOff);

          const origin = splitAddress(pickUp && pickUp.address, AddressPosition.CITY, true);
          const destination = splitAddress(dropOff && dropOff.address, AddressPosition.CITY, true);
          const label = `${origin} → ${destination}`;

          const loadTab = new Tab({
            id,
            label,
            closable: true,
          });

          this.loadAvailableTabsModel.updateTabs([loadTab]);
        }
      });
  }

  goToAvailable() {
    this.loadAvailableTabsModel.selectTab(loadAvailableTabsConfig.SEARCH);
  }

  ngOnDestroy(): void {
    this.alive.destroy();
    this.titleService.resetTitle();
  }

  private setTitle(pickup: LoadsServiceLoadLocation, dropoff: LoadsServiceLoadLocation) {
    const origin = splitAddress(pickup && pickup.address, AddressPosition.CITY, true);
    const destination = splitAddress(dropoff && dropoff.address, AddressPosition.CITY, true);
    const originState = splitAddress(pickup.address, AddressPosition.STATE, true);
    const destinationState = splitAddress(dropoff.address, AddressPosition.STATE, true);
    const originNoVowels =
      origin.substring(0, 1).toUpperCase() +
      origin
        .substring(1)
        .replace(/[aeiouyAEIOUY]/g, '')
        .toUpperCase();
    const destinationNoVowels =
      destination.substring(0, 1).toUpperCase() +
      destination
        .substring(1)
        .replace(/[aeiouyAEIOUY]/g, '')
        .toUpperCase();
    this.titleService.setTitle(
      `${originNoVowels},${originState}→${destinationNoVowels},${destinationState} - Load Details - Haulynx`
    );
  }
}
