import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'launch-rmis-dialog',
  templateUrl: `./launch-rmis.component.html`,
  styleUrls: ['./launch-rmis.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LaunchRmisDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { loadId: string },
    public dialogRef: MatDialogRef<LaunchRmisDialog>
  ) {}
}
