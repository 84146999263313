import { Pipe, PipeTransform } from '@angular/core';
import { User } from '@haulynx/types';

@Pipe({
  name: 'assignedToNames',
  pure: true,
})
export class AssignedToNamesPipe implements PipeTransform {
  transform(users: User[], ...args: unknown[]): string {
    return users.map((user: User) => user.name).join(', ');
  }
}
