import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface GoRmisCarrierInfo {
  carrierName: string;
  domicileAddress: string;
  domicileCity: string;
  domicileState: string;
  dotNumber: number;
  email: string;
  mcNumber: string;
  phoneNumber: number;
}

@Injectable({
  providedIn: 'root',
})
export class RmisService {
  constructor(private http: HttpClient) {}

  public getRmisDataFromDot(dot: string): Observable<GoRmisCarrierInfo> {
    return this.http.get<GoRmisCarrierInfo>(`/api/rmis/dotCheck?dot=${dot}`);
  }
}
