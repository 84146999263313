import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  IAsyncSearchResultsPaginationState,
  LoadsServiceOrderByType,
  LoadsServiceOrderByTypeDirection,
  PageAndSort,
  VirtualColumn,
} from '@haulynx/types';
import { MatDialog } from '@angular/material/dialog';
import { virtualLocalSort } from '@haulynx/utils';
import { VirtualTableSortDialogComponent } from './virtual-table-sort-dialog/virtual-table-sort-dialog.component';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'haulynx-virtual-table-sort',
  templateUrl: './virtual-table-sort.component.html',
  styleUrls: ['./virtual-table-sort.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VirtualTableSortComponent implements OnInit {
  @Input() column: VirtualColumn;
  @Input() dataSource: MatTableDataSource<any>;
  @Input() pagination: IAsyncSearchResultsPaginationState;
  @Input() pageSortState: PageAndSort;

  @Output() getSortedData = new EventEmitter();
  @Output() dataSourceChange = new EventEmitter();

  @ViewChild('sortArrow', { static: false }) public expandRef: ElementRef;

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}

  public changeSort(column: VirtualColumn, direction: LoadsServiceOrderByTypeDirection): void {
    if (column?.otherSortTypes?.length) {
      this.openDialog(column);
      return;
    }

    this.sortData(column, direction, column?.sortType);
  }

  private openDialog(column: VirtualColumn): void {
    const dialogRef = this.dialog.open(VirtualTableSortDialogComponent, {
      width: '350px',
      height: '110px',
      position: {
        left: `${this.expandRef?.nativeElement?.getBoundingClientRect().left - 40}px`,
        top: `${this.expandRef?.nativeElement?.getBoundingClientRect().bottom}px`,
      },
      panelClass: 'sort-dialog-container',
      data: {
        column: column ?? null,
        sortOrder: this?.pageSortState?.order ?? null,
        sortDirection: this?.pageSortState?.sort ?? null,
      },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((response) => {
      response = !response ? dialogRef?.componentInstance : response;
      const { sortOrder = null, sortDirection = null } = response?.data;

      if (!sortOrder || !sortDirection) return;

      this.sortData(column, sortDirection, sortOrder);
    });
  }

  private sortData(
    column: VirtualColumn,
    sortDirection: LoadsServiceOrderByTypeDirection,
    sortOrder: LoadsServiceOrderByType
  ): void {
    // If there is more data that is needed to be shown in the table, make a call to get all sorted data
    if (this.pagination?.total > this?.pageSortState?.limit && this?.pagination?.totalPages !== 1) {
      return this.getSortedData.emit({
        data: { ...this.pageSortState, sort: sortDirection, order: sortOrder },
        newSearch: true,
      });
    }
    //VirtualLocalSort will sort data based on the dataIndex, sortOrder and sortDirection.
    const sortedData = virtualLocalSort(this?.dataSource?.data, column?.dataIndex, sortOrder, sortDirection);
    this.dataSource = new MatTableDataSource(sortedData);
    this.dataSourceChange.emit(this.dataSource);
    this.getSortedData.emit({
      data: { ...this.pageSortState, sort: sortDirection, order: sortOrder },
      newSearch: false,
    });
  }
}
