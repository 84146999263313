import { Injectable } from '@angular/core';
import { GetCustomerById, SearchCustomersBy } from '@haulynx/gql';
import { Customer, CustomerSearchParameters, LoadsServiceCustomer, PageAndSort, PaginatedData } from '@haulynx/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GraphqlService } from '../graphql.service';

@Injectable({
  providedIn: 'root',
})
export class CustomersService {
  constructor(private graphqlService: GraphqlService) {}
  getCustomerById(variables: { id: string }): Observable<LoadsServiceCustomer> {
    variables = { ...variables, id: variables.id.toString() };
    return this.graphqlService
      .query({
        variables,
        query: GetCustomerById,
      })
      .pipe(map(({ data }: any) => data.getCustomerById || {}));
  }

  searchCustomersBy(
    searchParameters: Partial<CustomerSearchParameters>,
    paging: Partial<PageAndSort>
  ): Observable<PaginatedData<Customer>> {
    const variables = { ...searchParameters };
    return this.graphqlService
      .query({
        variables: {
          searchCustomerParams: new CustomerSearchParameters(variables),
        },
        query: SearchCustomersBy,
      })
      .pipe(
        map(({ data }: any) => {
          return {
            data: data['searchCustomersBy'] || [],
            pagination: {},
          } as PaginatedData<Customer>;
        })
      );
  }
}
