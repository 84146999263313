import { AsyncManagerType, IAsyncEntityState } from '@haulynx/types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ActionCreator, createAction, on, props, ReducerTypes } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { camelCase } from 'lodash';
import { iif, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { MutationAction } from '../types/mutation-action';
import { AsyncEntityActionFactory } from './async-entity-action-factory';

export class AsyncEntityMutationFactory<
  ActionPayloadType,
  ResponseData,
  ErrorPayloadType
> extends AsyncEntityActionFactory<ActionPayloadType, ResponseData, ErrorPayloadType> {
  public managerType = AsyncManagerType.MUTATION;
  public action = createAction(this.type, props<{ entityId: string; payload: ActionPayloadType }>());
  public actionSuccess = createAction(this.typeSuccess, props<{ entityId: string; payload: ResponseData }>());
  public actionError = createAction(this.typeError, props<{ entityId: string; payload: ErrorPayloadType }>());

  private options: AsyncEntityMutationOptions;

  constructor(
    namespace: string,
    name: string,
    effectCall: (payload: ActionPayloadType) => Observable<ResponseData>,
    options: AsyncEntityMutationOptions = {}
  ) {
    super(namespace, name, effectCall);
    this.options = options;
  }

  public getReducers(): ReducerTypes<IAsyncEntityState<ResponseData>, any>[] {
    return [
      on(this.action, (state: IAsyncEntityState<ResponseData>, action: MutationAction<ActionPayloadType>) => {
        return {
          ...state,
          isLoadingEntities: {
            ...state.isLoadingEntities,
            [action.entityId]: true,
          },
          [this.mutationIsLoadingName]: true,
        };
      }),
      on(
        this.actionSuccess,
        (state: IAsyncEntityState<ResponseData>, action: { entityId: string; payload: ResponseData }) => {
          const entities =
            this.options.refreshEntityFrom === 'response'
              ? {
                  entities: {
                    ...state.entities,
                    [action.entityId]: action.payload,
                  },
                }
              : {};

          return {
            ...state,
            ...entities,
            isLoadingEntities: {
              ...state.isLoadingEntities,
              [action.entityId]: false,
            },
            [this.mutationIsLoadingName]: false,
          };
        }
      ),
      on(
        this.actionError,
        (state: IAsyncEntityState<ResponseData>, action: { entityId: string; payload: ErrorPayloadType }) => {
          return {
            ...state,
            isLoadingEntities: { ...state.isLoadingEntities, [action.entityId]: false },
            [this.mutationIsLoadingName]: false,
          };
        }
      ),
    ];
  }

  public createEffect(actions$: Actions) {
    const actionEffect = (actn) =>
      of(actn).pipe(
        switchMap((action: MutationAction<ActionPayloadType>) =>
          this.sideEffect(action.payload).pipe(
            map((data: ResponseData) => this.actionSuccess({ entityId: action.entityId, payload: data })),
            catchError((error: ErrorPayloadType) => {
              console.error(error);
              return of(this.actionError({ entityId: action.entityId, payload: error }));
            })
          )
        )
      );

    const actionCompleteEffect = (actn) =>
      of(actn).pipe(map((completeAction) => this.getRefreshAction(completeAction.entityId)));

    return createEffect(() =>
      actions$.pipe(
        ofType(...this.chooseActionsToSubscribe()),
        mergeMap((action) => iif(() => action.type === this.type, actionEffect(action), actionCompleteEffect(action)))
      )
    );
  }

  get mutationIsLoadingName(): string {
    return camelCase(`${this.name} is loading`);
  }

  private chooseActionsToSubscribe(): string[] {
    const types: string[] = [this.type];
    if (this.options.refreshEntityFrom && this.options.refreshEntityFrom !== 'response') {
      types.push(this.typeSuccess, this.typeError);
    }
    return types;
  }

  private getRefreshAction(entityId: string): {
    entityId: string;
    payload?: unknown;
  } & TypedAction<string> {
    const fn = this.options.refreshEntityFrom as ActionCreator<
      string,
      (props: { entityId: string; payload?: unknown }) => {
        entityId: string;
        payload?: unknown;
      } & TypedAction<string>
    >;

    return fn({ entityId });
  }
}

export interface AsyncEntityMutationOptions {
  refreshEntityFrom?:
    | 'response'
    | ActionCreator<
        string,
        (props: { entityId: string; payload?: unknown }) => {
          entityId: string;
          payload?: unknown;
        } & TypedAction<string>
      >;
}
