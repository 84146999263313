<div class="new-load-wrapper">
  <div class="new-load-item">
    <img src="./assets/images/load-new-form.svg" width="217" height="184" />
    <p data-test="choseNew" (click)="newLoad()">New</p>
    <button class="new-load-item-button" (click)="newLoad()" mat-button>Create new</button>
  </div>

  <div class="new-load-item" matTooltip="Sorry! We are working on it! Will be up soon!">
    <img src="./assets/images/load-new-upload.svg" width="217" height="184" />
    <input type="file" accept=".csv" #file [hidden]="true" (change)="openFile($event)" />
    <div>{{ importMessage }}</div>
    <p (click)="($event)">Upload</p>
    <button
      style="background-color: #8f96ab"
      (click)="file.click()"
      class="new-load-item-button"
      [disabled]="true"
      mat-button
    >
      Coming Soon
    </button>
  </div>

  <div class="new-load-item">
    <img src="./assets/images/load-new-template.svg" width="217" height="185" />
    <p>Template</p>
    <button class="new-load-item-button" mat-button [matMenuTriggerFor]="templateMenu" data-test="use-template">
      Use template
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
    <mat-menu class="template-menu" #templateMenu="matMenu">
      <div class="menu-container">
        <button
          mat-menu-item
          *ngFor="let template of templates"
          (click)="createWithTemplate(template)"
          data-test="choose-template"
        >
          {{ template.name }}
        </button>
      </div>
    </mat-menu>
  </div>
</div>
