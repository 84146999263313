import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReleaseFormDialogComponent, TourContentComponent } from '@haulynx/components';
import { ReleasesService } from '@haulynx/services';
import { AppModel } from '@haulynx/store';
import { IColumns, ReleaseNote, ReleaseTypes, User } from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-releases',
  templateUrl: './releases.component.html',
  styleUrls: ['./releases.component.scss'],
})
export class ReleasesComponent implements OnDestroy, OnInit {
  releaseNotes$: Observable<ReleaseNote[]>;
  columns: IColumns[] = [];
  timeZone: string;
  user: User;
  userId: string;
  releaseType: ReleaseTypes;

  private alive = aliveWhile();

  constructor(private dialog: MatDialog, public appModel: AppModel, public releaseService: ReleasesService) {}

  ngOnInit(): void {
    this.columns = this.initColumns();

    this.appModel.userTimeZone$.pipe(takeUntil(this.alive)).subscribe((timeZone: string) => (this.timeZone = timeZone));

    this.appModel.id$.pipe(takeUntil(this.alive)).subscribe((userId: string) => {
      this.userId = userId;
      this.getReleaseNotes(userId);
    });
  }

  getReleaseNotes(userId: string): void {
    const userType = null;
    const before = null;
    const includeExpired = true;

    this.releaseNotes$ = this.releaseService
      .getReleaseNotes({ userId, userType, before, includeExpired })
      .pipe(map((releases: ReleaseNote[]) => releases));
  }

  initColumns(): IColumns[] {
    const columns: IColumns[] = [];

    columns.push({
      label: 'Release Title',
      dataIndex: 'title',
      isCustomCell: true,
    });

    columns.push({
      label: 'Release Type',
      dataIndex: 'userType',
      isCustomCell: true,
    });

    columns.push({
      label: 'Date Published',
      dataIndex: 'date',
      isCustomCell: true,
    });

    columns.push({
      label: 'Expiration date',
      dataIndex: 'expiration',
      isCustomCell: true,
    });

    columns.push({
      label: '',
      dataIndex: 'options',
      isCustomCell: true,
      isSortable: false,
      width: '120px',
    });

    return columns;
  }

  onOpenReleaseForm(releaseNoteId: string = null): void {
    this.dialog
      .open(ReleaseFormDialogComponent, {
        data: { releaseNoteId },
        width: '800px',
      })
      .afterClosed()
      .subscribe((refresh: boolean) => {
        if (refresh) {
          this.getReleaseNotes(this.userId);
        }
      });
  }

  onPreview(releaseNoteId: string): void {
    this.releaseService
      .getReleaseNotesById({ releaseNoteId })
      .pipe(takeUntil(this.alive))
      .subscribe((release) => {
        this.dialog.open(TourContentComponent, {
          disableClose: true,
          autoFocus: false,
          backdropClass: 'tour-back-drop',
          height: '840px',
          width: '1050px',
          data: { release, preview: true },
        });
      });
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }
}
