import { Injectable } from '@angular/core';
import { IPostTruckInput, PostTruckForm, User } from '@haulynx/types';
import { createUUID, getBrokerIdFromEmail } from '@haulynx/utils';
import { get, toNumber, toString } from 'lodash';
import { MomentService } from '../../app-services/generic/moment/moment.service';

@Injectable()
export class PostTruckService {
  constructor(private momentService: MomentService) {}

  toDto(truck: PostTruckForm, dot: string, user: User): IPostTruckInput {
    const preferredLocation = get(truck, 'preferredLocation', null);
    const newTruck = {
      id: createUUID(),
      carrierDot: dot,
      radius: toNumber(truck.locationRadius),
      locationLat: toString(truck.location.lat),
      locationLon: toString(truck.location.lon),
      location: truck.location.address,
      timeAvailable: this.momentService.getMoment(truck.timeAvailable, truck.location.timeZone).utc().format(),
      equipmentType: truck.equipmentType,
      brokerId: user.usxId || getBrokerIdFromEmail(user.email),
      preferredRadius: toNumber(truck.preferredLocationRadius),
      preferredLocationLat: preferredLocation && toString(preferredLocation.lat),
      preferredLocationLon: preferredLocation && toString(preferredLocation.lon),
      preferredLocation: preferredLocation && preferredLocation.address,
    } as IPostTruckInput;

    return newTruck;
  }
}
