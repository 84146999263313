import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { GooglePlacePrediction, PlaceInfo } from '@haulynx/types';

@Component({
  selector: 'app-search-place-dropdown',
  templateUrl: './search-place-dropdown.component.html',
  styleUrls: ['./search-place-dropdown.component.scss'],
})
export class SearchPlaceDropdownComponent implements OnInit, AfterViewInit {
  @ViewChild('wrapper', { static: false }) wrapper: ElementRef;

  @Input() predictions: Array<PlaceInfo>;

  @Output() selectPrediction: EventEmitter<PlaceInfo> = new EventEmitter();
  @Output() mouseFocus: EventEmitter<boolean> = new EventEmitter();

  indexOfFocus = -1;
  isMouseFocus = false;
  shouldCheckClicks = true;

  constructor() {}

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent): void {
    if (event.key === 'ArrowDown' && this.indexOfFocus < this.predictions.length - 1) {
      this.indexOfFocus += 1;
      this.isMouseFocus = false;
    } else if (event.key === 'ArrowUp' && this.indexOfFocus > 0) {
      this.indexOfFocus -= 1;
      this.isMouseFocus = false;
    } else if (event.key === 'Enter') {
      if (this.predictions.length > 0) {
        this.selectPrediction.emit(this.predictions.find((p: PlaceInfo, i: number) => i === this.indexOfFocus));
      }
    }
  }

  @HostListener('mouseenter') componentContainsMouse(): void {
    this.mouseFocus.emit(true);
  }

  @HostListener('document:click', ['$event.target']) onClick(targetElement: ElementRef): void {
    const clickedInside = this.wrapper.nativeElement.contains(targetElement);
    if (!clickedInside && this.shouldCheckClicks) {
      this.mouseFocus.emit(false);
    }
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (this.predictions) {
      this.shouldCheckClicks = false;
      setTimeout(() => {
        this.shouldCheckClicks = true;
      }, 500);
    }
  }

  onSelectPrediction(index: number): void {
    this.selectPrediction.emit(this.predictions.find((v: PlaceInfo, i: number) => i === index));
  }
}
