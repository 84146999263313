import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  AnalyticsService,
  CreateBrokerBookedLoad,
  GoogleAnalyticsService,
  LoadFeedActionsService,
  LocalStoreService,
  NotificationsService,
  TitleService,
  WindowRef,
} from '@haulynx/services';
import {
  AppModel,
  CarriersModel,
  LoadDetailsModel,
  LoadEntityService,
  LoadFeedModel,
  NotificationModel,
  UserEntityService,
} from '@haulynx/store';
import {
  Action,
  ANALYTICS_EVENT,
  AssignLoadCarrierData,
  Bid,
  BidHistory,
  BidSortTypes,
  BidStatusOption,
  BidStatusType,
  BookStatus,
  buttonTypes,
  Carrier,
  Category,
  CounterOfferInput,
  CustomerFacilityDto,
  FeatureFlag,
  LoadRouteSource,
  LoadsServiceLoad,
  LoadsServiceLoadLocation,
  LoadsServiceLoadStatus,
  NotificationEventType,
  RecommendedCarriers,
  Tab,
  User,
  LoadIdentifierType,
} from '@haulynx/types';
import {
  AddressPosition,
  aliveWhile,
  getLoadsServiceLoadRoute,
  getLoadsServiceLoadWayPointsCoordinates,
  openEmailClient,
  splitAddress,
  toQueryParams,
  getLoadsServiceLoadAlternateId,
} from '@haulynx/utils';
import { differenceBy, get, nth, omit } from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  catchError,
  delay,
  distinctUntilChanged,
  filter,
  first,
  map,
  pairwise,
  skip,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { AssignLoadCarrierContainerComponent } from '../../../assign-load-carrier-container/assign-load-carrier-container.component';
import { CustomerDetailsDialogComponent } from '../../../customer-details/components/customer-details-dialog/customer-details-dialog.component';
import { BidCounterOfferDialogComponent } from '../../../dialogs/bid-counter-offer-dialog/bid-counter-offer-dialog.component';
import { BidHistoryDialogComponent } from '../../../dialogs/bid-history-dialog/bid-history-dialog.component';
import { LaunchRmisDialog } from '../../../dialogs/launch-rmis/launch-rmis.component';

@Component({
  selector: 'app-load-feed-details-broker',
  templateUrl: './load-feed-details-broker.component.html',
  styleUrls: ['./load-feed-details-broker.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class LoadFeedDetailsBrokerContainer implements OnInit, OnDestroy, OnChanges {
  @ViewChild('loadFeedCarrierList') brokerTabsElementRef: ElementRef;
  @Input() showCarrierList = true;
  @Input() inputLoadId = null;
  alive = aliveWhile();
  loadId: string = null;
  loadFeed: LoadsServiceLoad;
  loadFeedRoute: LoadRouteSource[] = [];
  buttons$ = new BehaviorSubject([]);
  user: User;
  pagination = { limit: 10 };
  disableButtonsMap = {};
  selectedCarrier: Carrier | RecommendedCarriers;
  bidHistory$ = new BehaviorSubject<{ [bidId: string]: BidHistory[] }>({});
  bidStatusesCreation$: Observable<BidStatusOption[]>;
  bidFeatureFlag: FeatureFlag = FeatureFlag.LOAD_OFFER_BIDDING;
  recommendationFeatureFlag: FeatureFlag = FeatureFlag.NEW_CARRIER_RECOMMENDATIONS;
  brokerId: string;
  showRmisBanner: boolean;
  loadAcceptedBid$: Observable<boolean> = this.loadFeedModel.bidEntitiesList$.pipe(
    map((bids) => bids.some((bid: Bid) => bid.status === BidStatusType.ACCEPTED)),
    distinctUntilChanged()
  );
  isBidSorterVisible = true;
  userTimeZone: string;
  activeBidNotifications$ = new BehaviorSubject<{ [bidId: string]: true }>({});
  isLoadingEntities$: Observable<boolean>;
  completedLoad = false;
  backButtonLabel: string;
  referrer: string;
  isLoadDetailsPage = true;
  BookStatus = BookStatus;

  constructor(
    private activatedRoute: ActivatedRoute,
    public loadFeedModel: LoadFeedModel,
    public carriersModel: CarriersModel,
    private loadDetailsModel: LoadDetailsModel,
    public loadEntityService: LoadEntityService,
    public appModel: AppModel,
    private loadFeedActions: LoadFeedActionsService,
    private analytics: AnalyticsService,
    private dialog: MatDialog,
    private windowRef: WindowRef,
    private googleAnalyticsService: GoogleAnalyticsService,
    private notificationModel: NotificationModel,
    private localStoreService: LocalStoreService,
    private router: Router,
    private titleService: TitleService,
    private userEntityService: UserEntityService,
    private createOfferService: CreateBrokerBookedLoad,
    private notificationsService: NotificationsService
  ) {}

  ngOnInit(): void {
    this.buttons$.next([buttonTypes.EMAIL_LOAD]);
    this.loadFeedModel.getBidStatuses();

    this.router.events.pipe(takeUntil(this.alive)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('dashboard/load-feeds/my')) {
          this.isLoadDetailsPage = true;
        } else {
          this.isLoadDetailsPage = false;
        }
      }
    });

    this.activatedRoute.params.subscribe((params) => {
      const { id = null } = params;
      this.loadId = id;
      if (id) {
        this.dispatch(id);
        this.loadFeedModel.searchBids(id);
        this.localStoreService.get<string>(this.loadId).then((data) => {
          this.referrer = data ?? '/loads/search';
        });
        this.backButtonLabel = this.getBackButtonLabel(this.referrer);
      }
    });

    this.notificationModel.notifications$
      .pipe(
        takeUntil(this.alive),
        tap((notifications) => {
          const activeBidNotifications = (notifications || []).filter(
            (notification) => notification.secondaryTargetObjectType === 'BID' && notification.active
          );
          this.activeBidNotifications$.next(
            activeBidNotifications.reduce((prev, curr) => {
              prev[curr.secondaryTargetObjectId] = true;
              return prev;
            }, {})
          );
        }),
        skip(1), // The first emission of notifications should not refresh the page
        map((notifications) =>
          notifications.filter(
            (notification) =>
              notification.eventType === NotificationEventType.BIDS_EVENT && notification.targetObjectId === this.loadId
          )
        ),
        pairwise(),
        filter(([prev, curr]) => prev.length !== curr.length)
      )
      .subscribe(() => {
        this.loadFeedModel.searchBids(this.loadId);
      });

    this.appModel.brokerId$.pipe(takeUntil(this.alive)).subscribe((brokerId) => (this.brokerId = brokerId));
    this.appModel.showRmisBanner$
      .pipe(takeUntil(this.alive))
      .subscribe((showRmisBanner) => (this.showRmisBanner = showRmisBanner));
    this.loadEntityService.getLoadByIdManager
      .getEntityById(this.loadId)
      .pipe(
        takeUntil(this.alive),
        withLatestFrom(this.loadFeedModel.carrierSearch.query$, this.activatedRoute.queryParams),
        delay(0)
      )
      .subscribe(([loadFeed, queryState, queryParams]) => {
        if (loadFeed) {
          this.loadFeed = loadFeed;
          this.completedLoad = this.isLoadDetailsPage
            ? true
            : loadFeed.loadStatus === LoadsServiceLoadStatus.DELIVERED ||
              loadFeed.loadStatus === LoadsServiceLoadStatus.FINALLED
            ? true
            : false;
          this.selectedCarrier = null;
          this.loadDetailsModel.getLoadRoutes({
            key: loadFeed.id,
            coordinates: getLoadsServiceLoadWayPointsCoordinates(loadFeed),
          });

          const [pickUp, dropOff] = loadFeed.locations;
          this.setTitle(pickUp, dropOff);
          const id = loadFeed.id;

          const origin = splitAddress(pickUp.address, AddressPosition.CITY, true);
          const destination = splitAddress(dropOff.address, AddressPosition.CITY, true);
          const label = `${origin} → ${destination}`;
          const loadTab = new Tab({
            id,
            label,
            closable: true,
            queryParams,
          });

          this.loadFeedModel.updateTabs([loadTab]);
          this.carriersModel.updateTabs([loadTab]);

          if (queryState.loadId !== id) {
            const { carrierSearch = null } = queryParams;
            const query = { loadId: id, carrierSearch };

            this.loadFeedModel.searchCarriers({ query, ...this.pagination });
          }
        } else {
          const loadTab = new Tab({
            id: this.loadId,
            label: '',
            closable: true,
          });

          this.loadFeedModel.updateTabs([loadTab]);
        }
      });

    this.loadDetailsModel.routes$.pipe(takeUntil(this.alive)).subscribe((routes: LoadRouteSource[]) => {
      if (routes) {
        this.loadFeedRoute = getLoadsServiceLoadRoute(routes, this.loadFeed);
      }
    });

    this.appModel.user$.pipe(takeUntil(this.alive)).subscribe((user) => {
      this.user = user;
    });

    this.bidStatusesCreation$ = this.loadFeedModel.bidStatusOptions$.pipe(
      map((bidStatuses) =>
        differenceBy(bidStatuses, [{ id: BidStatusType.ACCEPTED }, { id: BidStatusType.AUTO_REJECTED }], 'id')
      )
    );

    // Bid History comes off of the bid object and is immediately available...
    this.loadFeedModel.bidEntitiesList$.pipe(takeUntil(this.alive)).subscribe((bids) => {
      const bidHistory: { [bidId: string]: BidHistory[] } = bids.reduce((prev, curr) => {
        prev[curr.id] = curr.bidHistory;
        return prev;
      }, {});
      this.bidHistory$.next(bidHistory);
    });
    // ... but we can also get bid history separately. So we subscribe to both places to be sure
    // we have the latest Bid History data in our UI
    this.loadFeedModel.bidHistory$.pipe(takeUntil(this.alive)).subscribe((bidHistory) => {
      this.bidHistory$.next({ ...this.bidHistory$.value, ...bidHistory });
    });

    this.appModel.userTimeZone$.pipe(takeUntil(this.alive)).subscribe((userTimeZone: string) => {
      this.userTimeZone = userTimeZone;
    });

    this.loadFeedModel.bidCreationSuccess$.pipe(takeUntil(this.alive)).subscribe(() => {
      this.selectedCarrier = null;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { inputLoadId } = changes;

    if (inputLoadId && inputLoadId.currentValue) {
      this.loadId = inputLoadId.currentValue;
      this.dispatch(inputLoadId.currentValue);
    }
  }

  onAction(event: { action: string; row: RecommendedCarriers }): void {
    if (event.action === buttonTypes.EMAIL_LOAD.action) {
      const dot = event.row.dot;
      const email = event.row.email;
      this.disableButtonsMap = {
        ...this.disableButtonsMap,
        [event.row.id]: [buttonTypes.EMAIL_ICON, buttonTypes.EMAIL_LOAD],
      };

      this.dispatch(this.loadFeed.id);
      this.loadEntityService.getLoadByIdManager
        .getEntityById(this.loadFeed.id)
        .pipe(
          switchMap((load: LoadsServiceLoad) =>
            this.createOfferService
              .createOffer({
                dot,
                price: load?.paymentDetails?.price,
                loadId: load.id,
                brokerEmail: this.user.email,
              })
              .pipe(
                withLatestFrom(of(load)),
                catchError((error) => {
                  this.notificationsService.error(error, 'Error');
                  return of(error);
                })
              )
          )
        )
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .subscribe(([ownership, load]: [unknown, LoadsServiceLoad]) => {
          const url = `mailto:${email}?${this.loadFeedActions.mailMessage(dot, load)}`;
          openEmailClient(url);

          this.disableButtonsMap = omit(this.disableButtonsMap, event.row.id);

          this.analytics.logEvent(ANALYTICS_EVENT.BROKER_EMAIL_OFFER, {
            carrierDot: dot,
            load: load,
            carrierEmail: email,
          });
        });
    } else if (event.action === buttonTypes.BID.action) {
      this.onCarrierSelect(event.row);
    } else if (event.action === buttonTypes.BOOK.action) {
      this.openAssignCarrierForm({
        carrierDot: event.row.dot,
        loadId: this.loadFeed.id,
        brokerId: event.row.brokerId,
        formData: { price: event.row.price },
      });
    } else if (event.action === buttonTypes.EMAIL_ICON.action) {
      this.onAction({ action: buttonTypes.EMAIL_LOAD.action, row: event.row });
    }
  }

  changePageCarrier(pagination: { page: number; limit: number }): void {
    this.brokerTabsElementRef.nativeElement.scroll(0, 0);
    this.loadFeedModel.searchCarriers({ ...pagination, ...this.pagination });
  }

  searchCarriers(query: string): void {
    this.loadFeedModel.searchCarriers({ query, ...this.pagination });
  }

  onCarrierSelect(carrier: Carrier | RecommendedCarriers): void {
    this.selectedCarrier = carrier;
  }

  onAcceptBid(bid: Bid): void {
    const carrierDot: string = bid.carrier.dot;
    const loadId: string = get(this.loadFeed, 'id', null);
    const brokerId: string = this.brokerId;
    const formData = { price: bid.price };

    this.openAssignCarrierForm({ brokerId, carrierDot, loadId, formData, bidId: bid.id })
      .pipe(takeUntil(this.alive))
      .subscribe((bookedLoadId: string) => {
        if (!bookedLoadId) {
          // Undo bid status change by retrieving bids from the server
          this.loadFeedModel.searchBids(loadId);
        }
      });
  }

  openRmisDialog(): void {
    this.dialog
      .open(LaunchRmisDialog)
      .afterClosed()
      .pipe(
        tap((response: boolean) => {
          if (!response) return;

          this.userEntityService.rmisTokenManager.data$.pipe(take(1)).subscribe((response: { url: string }) => {
            this.windowRef.getNativeWindow().open(response.url, '_blank');
          });

          this.userEntityService.rmisTokenManager.dispatch();
        })
      );
  }

  openAssignCarrierForm(data: AssignLoadCarrierData): Observable<string> {
    return this.dialog
      .open(AssignLoadCarrierContainerComponent, { data })
      .afterClosed()
      .pipe(
        map((result) => {
          if (result) {
            const { loadId } = data;
            this.bookSuccess(loadId);
            return loadId;
          }
          return null;
        })
      );
  }

  bookSuccess(loadId: string): void {
    this.dispatch(loadId);
  }

  onCreateBid(bid: Partial<Bid>): void {
    this.appModel.brokerId$.pipe(first()).subscribe((brokerId) => {
      this.loadFeedModel.createBid({ ...bid, brokerId }, this.loadFeed);
    });
  }

  onBidHistory(bid: Bid): void {
    const id = get(bid, 'id', null);

    if (id) {
      this.loadFeedModel.getBidHistory(id);
    }
  }

  onOpenBidHistoryDialog(bid: Bid): void {
    const id = get(bid, 'id', null);

    if (id) {
      this.dialog.open(BidHistoryDialogComponent, {
        data: { bid, load: this.loadFeed, userTimeZone: this.userTimeZone },
      });
    }
  }

  onOpenCounterOfferDialog(bid: Bid): void {
    const id = get(bid, 'id', null);

    if (id) {
      this.dialog
        .open(BidCounterOfferDialogComponent, {
          data: { bid, load: this.loadFeed, userTimeZone: this.userTimeZone },
        })
        .afterClosed()
        .subscribe((counterOfferInput: CounterOfferInput) => {
          if (counterOfferInput) {
            this.loadFeedModel.createCounterOffer(counterOfferInput);
          }
        });
    }
  }

  updateLoad(loadId: string): void {
    this.loadDetailsModel.get({ key: loadId, id: loadId, isLatest: true });
  }

  onUpdateBid(bid: Bid): void {
    this.loadFeedModel.updateBid({ ...bid, brokerId: this.brokerId }, this.loadFeed);
  }

  onToggleBidSorter(flag: boolean): void {
    this.isBidSorterVisible = flag;
  }

  onSortOrderChange(sortBy: BidSortTypes): void {
    this.loadFeedModel.sortBidsBy(this.loadId, sortBy);
  }

  onShowFacilityInfo({ customerNumber, index }: CustomerFacilityDto): void {
    if (customerNumber) {
      this.dialog.open(CustomerDetailsDialogComponent, {
        data: { customerNumber, index },
        position: { top: '15%' },
      });
    }
  }

  onXpmOrder(load: LoadsServiceLoad): void {
    const identifier = getLoadsServiceLoadAlternateId(load, LoadIdentifierType.ORDER_NUMBER);
    const { id } = load;
    this.googleAnalyticsService.eventEmitter(Category.XPM_LINK, Action.CLICK, id);

    const baseUrl = 'http://xpm.usxpress.com/Order/';
    const company = load?.provider?.company || '';
    const number = identifier || '';
    const queryParams = toQueryParams({ company, number });

    this.windowRef.getNativeWindow().open(`${baseUrl}?${queryParams}`, '_blank');
  }

  getBackButtonLabel(referrer: string): string {
    return referrer?.includes('dashboard/carriers') ? 'Carrier Dashboard' : 'Loads Search';
  }

  ngOnDestroy(): void {
    this.localStoreService.clear(this.loadId);
    this.alive.destroy();
    this.titleService.resetTitle();
  }

  private setTitle(pickup: LoadsServiceLoadLocation, dropoff: LoadsServiceLoadLocation) {
    const origin = splitAddress(pickup && pickup.address, AddressPosition.CITY, true);
    const destination = splitAddress(dropoff && dropoff.address, AddressPosition.CITY, true);
    const originState = splitAddress(pickup.address, AddressPosition.STATE, true);
    const destinationState = splitAddress(dropoff.address, AddressPosition.STATE, true);
    const originNoVowels =
      origin.substring(0, 1).toUpperCase() +
      origin
        .substring(1)
        .replace(/[aeiouyAEIOUY]/g, '')
        .toUpperCase();
    const destinationNoVowels =
      destination.substring(0, 1).toUpperCase() +
      destination
        .substring(1)
        .replace(/[aeiouyAEIOUY]/g, '')
        .toUpperCase();
    this.titleService.setTitle(
      `${originNoVowels},${originState}→${destinationNoVowels},${destinationState} - Load Details - Haulynx`
    );
  }

  private dispatch(loadId: string): void {
    this.loadEntityService.getLoadByIdManager.dispatch(loadId);

    this.isLoadingEntities$ = this.loadEntityService.getLoadByIdManager.isLoadingEntities$.pipe(
      map((item) => item[loadId])
    );
  }
}
