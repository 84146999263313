import { Carrier, CarrierInput } from '../carrier';
import { Device, User } from '../generic';
import { Location, LocationInput } from '../location';
import { TrackingType } from './tracking-type';

export class Truck {
  carrierId: string;
  userId: string;
  user?: Partial<User>;
  coDrivers: User[];
  engineHours: number;
  hasDeviceConnected: boolean;
  id: string;
  imei: string;
  type: string;
  lat: number;
  lon: number;
  locationName: string;
  lastUpdated: number; // utc timestamp
  make: string;
  mileage: number;
  model: string;
  needsMileageUpdate: boolean;
  truckState: string;
  unitId: string;
  vehicleMiles: number;
  vin: string;
  year: number;
  mileageSinceDTC: number;
  thirdPartyTracking: TruckTrackingTypeAvailability[];

  // appended models
  carrier?: Carrier;
  lastLocation?: Location;
  index?: number;

  constructor(device?: Device) {
    if (device) {
      // Converts a device to a truck. Useful while we transition models.
      if (device.carrier) {
        this.carrierId = device.carrier.id;
      }
      this.imei = device.imei;
      this.unitId = device.unitId;
      if (device.lastLocation) {
        this.lastLocation = device.lastLocation;
      } else if (device['gpsLat'] && device['gpsLon']) {
        // look for gpsLat and gpsLon on top level
        this.lastLocation = new Location('', device['gpsLat'], device['gpsLon']);
      }
      this.make = device.make;
      this.model = device.model;
      this.year = device.year;
      this.vin = device.vin;
      this.unitId = device.unitId;
    }
  }
}

export class TruckInput {
  carrierId: string;
  userId: string;
  unitId: string;
  vin: string;
  make: string;
  model: string;
  year: number;
  mileage?: TruckMileageInput;
  truckState: string;
  vehicleMiles: number;
  mileageSinceDTC: number;
  needsMileageUpdate: boolean;
  mileageType: string;
  statusOrigin: string;
  engineHours: number;
  hasDeviceConnected: boolean;
  imei: string;
  type: string;
  lat: number;
  lon: number;
  locationName: string;
  lastUpdated: string;
  carrier?: CarrierInput;
  lastLocation?: LocationInput;
  constructor(props?: Partial<Truck>) {
    Object.assign(this, props);
    this.mileage = new TruckMileageInput({ value: props.mileage });
  }
}

export class TruckMileageInput {
  calculationType: string;
  lastOdometerSync: string;
  accumulatedMileage: number;
  accumulatedEngineHours: number;
  manualOdometer: number;
  offset: number;
  substitute: number;
  value: number;
  constructor(props?: Partial<any>) {
    Object.assign(this, props);
  }
}

export class TruckTrackingTypeAvailability {
  trackingType: TrackingType;
  isSupported: boolean;
}
