<mat-card class="carrier-details-tab">
  <app-spinner [active]="isLoading"></app-spinner>

  <div class="row" *ngIf="carrier">
    <div class="col-lg-12">
      <div class="row">
        <div class="col-lg-6">
          <app-field-label position="column" label="Auto Liability Policy Limit">
            <span>{{ carrier | get: 'saferWatchData.autoLiability.limit':'N/A' }}</span>
          </app-field-label>
        </div>
        <div class="col-lg-6">
          <app-field-label position="column" label="Expiration Date">
            <span>{{ carrier | get: 'saferWatchData.autoLiability.expirationDate':'N/A' }}</span>
          </app-field-label>
        </div>
        <div class="col-lg-6">
          <app-field-label position="column" label="Policy Number">
            <span>{{ carrier | get: 'saferWatchData.autoLiability.policyNumber':'N/A' }}</span>
          </app-field-label>
        </div>
        <div class="col-lg-6">
          <app-field-label position="column" label="Underwriter">
            <span>{{ carrier | get: 'saferWatchData.autoLiability.underWriter':'N/A' }}</span>
          </app-field-label>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <app-field-label position="column" label="Cargo Liability Policy Limit">
            <span>{{ carrier | get: 'saferWatchData.cargoLiability.limit':'N/A' }}</span>
          </app-field-label>
        </div>
        <div class="col-lg-6">
          <app-field-label position="column" label="Expiration Date">
            <span>{{ carrier | get: 'saferWatchData.cargoLiability.expirationDate':'N/A' }}</span>
          </app-field-label>
        </div>
        <div class="col-lg-6">
          <app-field-label position="column" label="Policy Number">
            <span>{{ carrier | get: 'saferWatchData.cargoLiability.policyNumber':'N/A' }}</span>
          </app-field-label>
        </div>
        <div class="col-lg-6">
          <app-field-label position="column" label="Underwriter">
            <span>{{ carrier | get: 'saferWatchData.cargoLiability.underWriter':'N/A' }}</span>
          </app-field-label>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <app-field-label position="column" label="General Liability	 Policy Limits">
            <span>{{ carrier | get: 'saferWatchData.cargoLiability.limit':'N/A' }}</span>
          </app-field-label>
        </div>
        <div class="col-lg-6">
          <app-field-label position="column" label="Expiration Date">
            <span>{{ carrier | get: 'saferWatchData.cargoLiability.expirationDate':'N/A' }}</span>
          </app-field-label>
        </div>
        <div class="col-lg-6">
          <app-field-label position="column" label="Policy Number">
            <span>{{ carrier | get: 'saferWatchData.cargoLiability.policyNumber':'N/A' }}</span>
          </app-field-label>
        </div>
        <div class="col-lg-6">
          <app-field-label position="column" label="Underwriter">
            <span>{{ carrier | get: 'saferWatchData.cargoLiability.underWriter':'N/A' }}</span>
          </app-field-label>
        </div>
      </div>
    </div>
  </div>
</mat-card>
