import { FromCamelCasePipe, TransformStatusPipe } from '@haulynx/pipes';
import { lowerCase, startCase } from 'lodash';
import { LaneHistoryRow } from '../../lane-history';
import { LoadsServiceLoad, LoadsServiceLoadStatus, UnauthenticatedLoadsServiceLoad } from '../../loads-service';
import { DateRangeFilter, LaneRadiusFilter, TableViewFilter } from '../advanced-search/models';
import { LaneCarrierDOTFilter } from '../advanced-search/models/LaneCarrierDOTFilter';
import { ISearchFilter } from '../advanced-search/search-filter';
import { ChartConfig } from '../chart/chart';
import { IColumns2 } from '../data-table-v2';
import { DataTableConfig } from '../data-table/data-table-type';
import { KeyValuePair } from '../generic/key-value-pair';
import { ColumnField } from '../search-view/default-columns';

export const LaneHistoryDataColumns = (
  columnSettings: Array<{ key: string; value: boolean; order?: number }> = []
): IColumns2[] => {
  const allColumns: IColumns2[] = [
    {
      field: ColumnField.UPDATED_AT,
      label: 'Date',
      sortConfig: [
        {
          defaultOrder: 'desc',
          label: 'Date',
          orderByIndex: [
            function (value: LaneHistoryRow) {
              return value?.paymentDetails?.bookedAt || value?.createdAt || 0;
            },
          ],
        },
      ],
      isSortable: true,
      isCustomCell: true,
      width: '120px',
      isVisible: true,
      isPinned: true,
      order: 1,
    },
    {
      field: ColumnField.STATUS,
      label: 'Type',
      sortConfig: [
        {
          label: 'Status',
          orderByIndex: [
            function (value: LaneHistoryRow) {
              return value?.status?.[0];
            },
          ],
        },
        {
          label: 'Sub Status',
          orderByIndex: [
            function (value: LaneHistoryRow) {
              switch (value?.status?.[1]) {
                case LoadsServiceLoadStatus.UNASSIGNED:
                  return 'Awaiting Truck Details';
                case LoadsServiceLoadStatus.ASSIGNED:
                  return 'Ready for Dispatch';
                case LoadsServiceLoadStatus.AT_SHIPPER:
                  return 'At Pickup';
                case LoadsServiceLoadStatus.PICKED_UP:
                  return 'In Transit';
                case LoadsServiceLoadStatus.AT_RECEIVER:
                  return 'At Dropoff';
                default:
                  return startCase(lowerCase(value?.status?.[1]));
              }
            },
          ],
        },
      ],
      isSortable: true,
      isCustomCell: true,
      width: '120px',
      isVisible: true,
      isPinned: true,
      order: 2,
    },
    {
      field: ColumnField.TMW_AND_ORDER,
      label: 'Load TMW #',
      filters: [],
      sortConfig: [
        {
          label: 'Load TMW #',
          orderByIndex: [
            function (value: LaneHistoryRow) {
              return parseInt(value.tmwNumber);
            },
          ],
        },
      ],
      isCustomCell: true,
      isSortable: true,
      width: '120px',
      isVisible: true,
      order: 3,
    },
    {
      field: ColumnField.BILL_TO,
      label: 'Bill-To',
      sortConfig: [
        {
          label: 'Bill-To',
          orderByIndex: [
            function (value: LaneHistoryRow) {
              return value?.providerDetails.billToName || '';
            },
          ],
        },
      ],
      filters: [],
      isCustomCell: true,
      isSortable: true,
      width: '120px',
      isVisible: false,
      order: 4,
    },
    {
      field: ColumnField.CARRIER,
      label: 'Carrier',
      sortConfig: [
        {
          label: 'Carrier',
          orderByIndex: [
            function (value: LaneHistoryRow) {
              return value?.carrier?.name || '';
            },
          ],
        },
      ],
      filters: [''],
      isCustomCell: true,
      isSortable: true,
      width: '150px',
      isVisible: true,
      order: 5,
    },
    {
      field: ColumnField.ORIGIN_AND_DESTINATION,
      label: 'Origin/Destination',
      sortConfig: [
        {
          label: 'Origin Time',
          orderByIndex: [
            function (value: LaneHistoryRow) {
              return value?.locations?.[0].appointmentStart;
            },
          ],
        },
        {
          label: 'Destination Time',
          orderByIndex: [
            function (value: LaneHistoryRow) {
              return value?.locations?.[value?.locations.length - 1].appointmentStart;
            },
          ],
        },
        {
          label: 'Origin Name',
          orderByIndex: [
            function (value: LaneHistoryRow) {
              return value?.locations[0].city || '';
            },
          ],
        },
        {
          label: 'Destination Name',
          orderByIndex: [
            function (value: LaneHistoryRow) {
              return value?.locations[1].city || '';
            },
          ],
        },
      ],
      filters: [],
      isCustomCell: true,
      isSortable: true,
      width: '465px',
      isVisible: false,
      order: 6,
    },
    {
      field: ColumnField.ASSIGNED_BROKERS,
      label: 'Broker',
      sortConfig: [
        {
          label: 'Broker',
          orderByIndex: [
            function (value: LaneHistoryRow) {
              return value?.broker?.usxId;
            },
          ],
        },
      ],
      filters: [],
      isCustomCell: true,
      isSortable: true,
      width: '120px',
      isVisible: true,
      order: 7,
    },
    {
      field: ColumnField.REVENUE,
      label: 'Revenue',
      sortConfig: [
        {
          label: 'Revenue',
          orderByIndex: [
            function (value: LaneHistoryRow) {
              return value?.paymentDetails?.revenue;
            },
          ],
        },
      ],
      filters: [],
      isCustomCell: true,
      isSortable: true,
      width: '120px',
      isVisible: true,
      order: 8,
    },
    {
      field: ColumnField.CARRIER_RATE,
      label: 'Carrier Rate',
      sortConfig: [
        {
          label: 'Amount',
          orderByIndex: [
            function (value: LaneHistoryRow) {
              return value?.paymentDetails?.price || 0;
            },
          ],
        },
        {
          label: 'Average',
          orderByIndex: [
            function (value: LaneHistoryRow) {
              return value?.paymentDetails?.avgCarrierRateDiff;
            },
          ],
        },
      ],
      filters: [],
      isCustomCell: true,
      isSortable: true,
      width: '120px',
      isVisible: true,
      order: 9,
    },
    {
      field: ColumnField.MARGIN,
      label: 'Margin',
      sortConfig: [
        {
          label: 'Amount',
          orderByIndex: [
            function (value: LaneHistoryRow) {
              return value?.paymentDetails?.revenue - value?.paymentDetails?.price;
            },
          ],
        },
        {
          label: 'Percentage',
          orderByIndex: [
            function (value: LaneHistoryRow) {
              return (value?.paymentDetails?.revenue - value?.paymentDetails?.price) / value?.paymentDetails?.revenue;
            },
          ],
        },
      ],
      filters: [],
      isCustomCell: true,
      isSortable: true,
      width: '120px',
      isVisible: true,
      order: 10,
    },
    {
      field: ColumnField.ACTIONS,
      label: '',
      sortConfig: [],
      filters: [],
      isCustomCell: true,
      isSortable: false,
      width: '60px',
      isVisible: true,
      order: 11,
    },
  ];

  return allColumns.reduce((acc: IColumns2[], column: IColumns2): IColumns2[] => {
    const setting = columnSettings.find(
      (value: { key: string; value: boolean; order?: number }) => value.key === column.field
    );
    if (!setting) return [...acc, column];

    const newThing = { ...column, isVisible: setting.value, order: setting.order || column.order };
    return [...acc, newThing];

    // return setting?.value === false ? [...acc, { ...column,  isVisible: false }] : [...acc, column];
  }, []);
};

export const LaneTrack = (load: LoadsServiceLoad): LaneTrackBase => {
  const destination = load.locations.length - 1;
  return {
    originLat: load.locations[0].geometry.coordinates[1],
    originLon: load.locations[0].geometry.coordinates[0],
    originLocation: load.locations[0].address,
    originRadiusMiles: '50',
    destinationLat: load.locations[destination].geometry.coordinates[1],
    destinationLon: load.locations[destination].geometry.coordinates[0],
    destinationLocation: load.locations[destination].address,
    destinationRadiusMiles: '50',
  };
};

export interface LaneTrackBase {
  originLat: number;
  originLon: number;
  originLocation: string;
  originRadiusMiles: string;
  destinationLat: number;
  destinationLon: number;
  destinationLocation: string;
  destinationRadiusMiles: string;
}

export const loadOverviewLaneHistoryConfigOptions: DataTableConfig = {
  globalFilter: false,
  reorderColumns: false,
  columnVisibility: false,
  reorderRows: false,
  showBulkActions: false,
  sortColumns: true,
  scrollable: false,
  checkBoxSelection: false,
  filterLocally: false,
  showTopPaginator: true,
  showBottomPaginator: false,
  isLaneRate: false,
  isLaneHistory: true,
  bulkActions: null,
  pageAmount: 10,
};

export const laneHistoryActionMenu: KeyValuePair[] = [
  {
    key: 'Call Carrier',
    value: 0,
  },
  {
    key: 'Book with Carrier',
    value: 1,
  },
  {
    key: 'Bid with Carrier',
    value: 2,
  },
];

export const laneAnalysisActionMenu: KeyValuePair[] = [
  {
    key: 'Call Carrier',
    value: 0,
  },
  {
    key: 'Bid with Carrier',
    value: 1,
  },
];
export interface LaneActionMenuOption {
  svgIcon: string;
  class?: string;
}

export const laneHistoryActionMenuIcons: LaneActionMenuOption[] = [
  {
    svgIcon: 'haulynx-phone-filled',
    class: 'icon-label',
  },
  {
    svgIcon: 'book-icon',
    class: 'icon-label',
  },
  {
    svgIcon: 'one-to-one',
    class: 'icon-label',
  },
];

export const laneAnalysisActionMenuIcons: LaneActionMenuOption[] = [
  {
    svgIcon: 'haulynx-phone-filled',
    class: 'icon-label',
  },
  {
    svgIcon: 'one-to-one',
    class: 'icon-label',
  },
];

export const laneHistoryFilters = (): ISearchFilter[] => [
  new DateRangeFilter(),
  new LaneRadiusFilter(),
  new TableViewFilter(),
  new LaneCarrierDOTFilter(),
];

function loadsServiceLoadHasValidCoordinates(load: UnauthenticatedLoadsServiceLoad | LoadsServiceLoad): boolean {
  if (load && load.locations) {
    const locations = load.locations;

    if (locations.length && locations.length >= 2) {
      // coordinates in lon, lat
      return !(
        !locations[0].geometry?.coordinates[1] ||
        !locations[0].geometry?.coordinates[0] ||
        !locations[1].geometry?.coordinates[1] ||
        !locations[1].geometry?.coordinates[0]
      );
    }
  }
}

export const graphSettings: ChartConfig = {
  chartTitle: 'Network Graph',
  chartOptions: [
    {
      chartSubTitle: 'Rate',
      chartSettingsOptions: [
        {
          chartSettingTitle: 'Individual Loads',
          dataPath: 'rate.loads',
          display: true,
        },
        {
          chartSettingTitle: 'Load Cost Average Single Day',
          dataPath: 'rate.loadsAverage',
          display: true,
        },
      ],
    },
    {
      chartSubTitle: 'Margin',
      chartSettingsOptions: [
        {
          chartSettingTitle: 'Individual Loads',
          dataPath: 'margin.loads',
          display: true,
        },
        {
          chartSettingTitle: 'Load Cost Average Single Day',
          dataPath: 'margin.loadsAverage',
          display: true,
        },
      ],
    },
    {
      chartSubTitle: 'Revenue',
      chartSettingsOptions: [
        {
          chartSettingTitle: 'Individual Loads',
          dataPath: 'revenue.loads',
          display: true,
        },
        {
          chartSettingTitle: 'Load Cost Average Single Day',
          dataPath: 'revenue.loadsAverage',
          display: true,
        },
      ],
    },
  ],
};
