import { EnvironmentName, User } from '@haulynx/types';
import { createAction, createActionTypes } from '../shared/store/helpers/action';
import { AppSettingsState, IAppSettingsState } from './app-settings.state';

const TYPE = '[APP SETTINGS]';

export interface IAppSettingsActions {
  startInitApp(data: { token: string; user: User; environmentName: EnvironmentName });
  updateInitApp(data: Partial<IAppSettingsState>);
  finishInitApp();
  updateUserEntity(data: { token: string; user: User; environmentName: EnvironmentName });
  updateUserEntitySuccess();
  logOut(params?);
  logOutSuccess(params?);
  logOutError(params?);
  logIn(params: { email: string; password: string });
  logInSuccess(params: { user: User; token: string });
  logInError(params?);
  checkUserPermission(params: User);
  checkUserPermissionSuccess(params: Partial<AppSettingsState>);
  checkUserPermissionError(params: { error: unknown; appSettings: Partial<AppSettingsState> });
  updateUser(params?);
  updateToken(params?);
  setWidgetReferrer(params?);
  setLoadDetailsRedirectUrl(params?);
}

export interface IAppActionType {
  START_INIT_APP: string;
  UPDATE_INIT_APP: string;
  FINISH_INIT_APP: string;
  UPDATE_USER_ENTITY: string;
  UPDATE_USER_ENTITY_SUCCESS: string;
  LOG_OUT: string;
  LOG_OUT_SUCCESS: string;
  LOG_OUT_ERROR: string;
  LOG_IN: string;
  LOG_IN_SUCCESS: string;
  LOG_IN_ERROR: string;
  CHECK_USER_PERMISSION: string;
  CHECK_USER_PERMISSION_SUCCESS: string;
  CHECK_USER_PERMISSION_ERROR: string;
  UPDATE_USER: string;
  UPDATE_TOKEN: string;
  SET_WIDGET_REFERRER: string;
  SET_LOAD_DETAILS_REDIRECT_URL: string;
}

export const appActionTypes: IAppActionType = {
  LOG_OUT: 'logout',
  LOG_OUT_SUCCESS: 'logout success',
  LOG_OUT_ERROR: 'logout error',
  LOG_IN: 'login',
  LOG_IN_SUCCESS: 'login success',
  START_INIT_APP: 'start init app',
  UPDATE_INIT_APP: 'update init app',
  FINISH_INIT_APP: 'finish init app',
  UPDATE_USER_ENTITY: 'update user entity',
  UPDATE_USER_ENTITY_SUCCESS: 'update user entity success',
  CHECK_USER_PERMISSION: 'check user permission',
  CHECK_USER_PERMISSION_SUCCESS: 'check user permission success',
  CHECK_USER_PERMISSION_ERROR: 'check user permission error',
  LOG_IN_ERROR: 'login error',
  UPDATE_USER: 'update user',
  UPDATE_TOKEN: 'update token',
  SET_WIDGET_REFERRER: 'set widget referrer',
  SET_LOAD_DETAILS_REDIRECT_URL: 'set load details redirect url',
};

export const appSettingsActions: IAppSettingsActions = {
  startInitApp: createAction(TYPE, appActionTypes.START_INIT_APP),
  finishInitApp: createAction(TYPE, appActionTypes.FINISH_INIT_APP),
  updateInitApp: createAction(TYPE, appActionTypes.UPDATE_INIT_APP),
  updateUserEntity: createAction(TYPE, appActionTypes.UPDATE_USER_ENTITY),
  updateUserEntitySuccess: createAction(TYPE, appActionTypes.UPDATE_USER_ENTITY_SUCCESS),
  logOut: createAction(TYPE, appActionTypes.LOG_OUT),
  logOutSuccess: createAction(TYPE, appActionTypes.LOG_OUT_SUCCESS),
  logOutError: createAction(TYPE, appActionTypes.LOG_OUT_ERROR),
  logIn: createAction(TYPE, appActionTypes.LOG_IN),
  logInSuccess: createAction(TYPE, appActionTypes.LOG_IN_SUCCESS),
  logInError: createAction(TYPE, appActionTypes.LOG_IN_ERROR),
  updateUser: createAction(TYPE, appActionTypes.UPDATE_USER),
  updateToken: createAction(TYPE, appActionTypes.UPDATE_TOKEN),
  checkUserPermission: createAction(TYPE, appActionTypes.CHECK_USER_PERMISSION),
  checkUserPermissionSuccess: createAction(TYPE, appActionTypes.CHECK_USER_PERMISSION_SUCCESS),
  checkUserPermissionError: createAction(TYPE, appActionTypes.CHECK_USER_PERMISSION_ERROR),
  setWidgetReferrer: createAction(TYPE, appActionTypes.SET_WIDGET_REFERRER),
  setLoadDetailsRedirectUrl: createAction(TYPE, appActionTypes.SET_LOAD_DETAILS_REDIRECT_URL),
};

export const AppSettingsActions: IAppSettingsActions = {
  ...appSettingsActions,
};

export const AppSettingsActionTypes: IAppActionType = {
  ...createActionTypes(appActionTypes)(TYPE),
};
