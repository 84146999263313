import { LngLatLike } from 'mapbox-gl';
import { HoursOfOperation, LoadsServiceCustomer } from '../../loads-service';
import { LoadLocationType } from '../load';

export interface MapboxRoute {
  entityId: string;
  wayPoints: WayPoint[];
  routeCoordinates: LngLatLike[];
  truckPosition?: LngLatLike;
  showTruck?: boolean;
  truck?: {
    name: string;
    position: LngLatLike;
  };
  dispatch?: WayPoint;
  eldPoints: EldPoints[];
  isDelayed: boolean;
  lineWidth?: number;
  lineColor?: string;
  customers?: LoadsServiceCustomer[];
}

export interface WayPoint {
  name: string;
  location: [number, number];
  inCoordinates?: [number, number];
  outCoordinates?: [number, number];
  inTimestamp?: number;
  outTimestamp?: number;
  locationType: LoadLocationType | 'dispatch';
  isDetention: boolean;
  isEmphasized?: boolean;
  facility?: WayPointFacility;
  locationId?: string;
  milestoneId?: string;
  className?: string;
  removeTextContent?: boolean;
}

export interface WayPointFacility {
  number?: string;
  label?: string;
  facilityName?: string;
  appointmentTimeIn?: number;
  appotinmentTimeOut?: number;
  shippingHours?: HoursOfOperation[];
}

export interface EldPoints {
  status: EldStatus;
  coordinates: LngLatLike;
  name?: string;
  isEmphasized?: boolean;
  milestoneId?: string;
}

export interface MapTruck {
  name: string;
  position: LngLatLike;
}

export enum MarkerTypes {
  AWAITING_PICKUP = 'marker-gray-pickup',
  AWAITING_DROPOFF = 'marker-gray-dropoff',
  PASSED_PICKUP = 'marker-green-pickup',
  PASSED_DROPOFF = 'marker-green-dropoff',
  DETENTION_PICKUP = 'marker-orange-pickup',
  DETENTION_DROPOFF = 'marker-orange-dropoff',
  DISPATCH = 'marker-gray-dispatch',
  TRUCK = 'truck-green',
  UPDATE_NEUTRAL = 'update-neutral',
  UPDATE_PROBLEM = 'update-problem',
  CHECKPOINT_NEUTRAL = 'checkpoint-neutral',
  UPDATE_ADD = 'update-add',
  UPDATE_UPDATE = 'update-update',
  POPOVER_LOCATION_UPDATE = 'location-update-popover',
  POPOVER_LOCATION_EDIT_UPDATE = 'location-edit-update-popover',
  POPOVER_WAYPOINT = 'waypoint-popover',
  POPOVER_PROBLEM = 'location-problem-popover',

  // Carrier Marker Types
  CARRIER_PICKUP = 'carrier-load-search-pickup',
  CARRIER_DROPOFF = 'carrier-load-search-dropoff',

  // Trailer Marker Types
  TRAILER_WAYPOINT = 'trailer-waypoint',
  TRAILER_START = 'trailer-locations',
  TRAILER_END = 'trailer-locations',
}

export enum EldStatus {
  GOOD = 'good',
  PROBLEM = 'problem',
  UPDATE = 'update',
}

export const DEFAULT_TRUCK_COLOR = '#00ca8e'; // var(--option-green);
export const DEFAULT_TRAILER_COLOR = '#CE41FF';
