import { Pipe, PipeTransform } from '@angular/core';
import { MomentService, UserService } from '@haulynx/services';
import { User } from '@haulynx/types';
import { toString } from 'lodash';
import { take } from 'rxjs/operators';

/**
 * @deprecated Use `date-time.pipe.ts` instead
 */
@Pipe({
  name: 'timezoned',
})
export class TimezonedPipe implements PipeTransform {
  private user: User;
  constructor(private userService: UserService, private momentService: MomentService) {
    this.userService.user.pipe(take(1)).subscribe((data) => {
      this.user = data;
    });
  }

  transform(
    dateString: any,
    outputFormat = 'D MMM YYYY h:mm:ssa',
    inputFormat = 'YYYY-MM-DD HH:mm:ss',
    timeZone?: string
  ): string {
    if (dateString) {
      return this.momentService.getUtcFromInput(
        toString(dateString),
        inputFormat,
        outputFormat,
        timeZone || this.user?.prefs?.timeZone
      );
    }
    return '';
  }
}
