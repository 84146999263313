import {
  LoadHistoryActionType,
  LoadsHistoryNonStartCaseFields,
  LoadsServiceCurrencyFields,
  LoadsServiceDateFields,
  LoadHistoryActionObject,
  LoadsServiceWeightFields,
  LoadHistory,
  removeExceptionTypes,
  ActionStringObject,
  OldNewDeltaType,
  LoadHistoryDelta,
} from '@haulynx/types';
import { forEach, has, isEmpty, isObject, isString, keys, map, pickBy, startCase } from 'lodash';
import { formatTimestampToHuman } from '../time';

export function createActionString(stringArray: ActionStringObject, isHtml: boolean = false): string {
  const [primaryDescription, secondaryTextArray = []] = stringArray;
  const secondaryDescription: string = isHtml
    ? secondaryTextArray
      ? secondaryTextArray
          .map(({ secondaryText, ul = 'string-ul', li = 'string-li' }) => stringArraytoUl(secondaryText, ul, li))
          .join('')
      : ''
    : secondaryTextArray.map(({ secondaryText }) => secondaryText).join(', ');

  return `${primaryDescription}${isHtml ? '<br>' : ', '}${secondaryDescription}`;
}

export function stringArraytoUl(
  stringArray: string[],
  ulClass: string = '',
  liClass: string = '',
  delimiter: string = ''
): string {
  const list = map(stringArray, (text) => {
    return `<li class=${liClass}>${text}</li>`;
  });
  return `<ul class=${ulClass}>${list.join(delimiter)}</ul>`;
}

export function formatLoadHistoryValue(key: string = '', loadHistoryValue: string | number | boolean, config): string {
  const { startcase, timezone } = config;
  const value = loadHistoryValue ?? 'None';

  if (key in LoadsServiceDateFields) {
    return formatTimestampToHuman(value as string | number, timezone);
  } else if (key in LoadsServiceWeightFields) {
    const stringValue = isString(value) ? value : value.toString();
    return stringValue + ' lbs';
  } else if (key in LoadsServiceCurrencyFields) {
    return isString(value) ? value : `$${Number(value).toFixed(2).toString()}`;
  } else {
    const stringValue = isString(value) ? value : value.toString();
    return startcase ? startCase(stringValue) : stringValue;
  }
}

export function getSecondaryText(
  changedValues:
    | {
        newValue: Record<string, unknown> | (string | number | boolean);
        oldValue: Record<string, unknown> | (string | number | boolean);
        sameValue?: Record<string, unknown> | (string | number | boolean);
      }
    | Record<string, unknown>,
  formatConfig: Record<string, unknown> = {}
): string[] {
  if (isObject(changedValues.oldValue)) {
    const oldValStrings = map(Object.keys(changedValues.oldValue), (key) => {
      return `${startCase(key)}: ${formatLoadHistoryValue(key, changedValues.oldValue[key], {
        startcase: !(key in LoadsHistoryNonStartCaseFields),
        ...formatConfig,
      })}`;
    });
    const newValStrings = map(Object.keys(changedValues.newValue), (key) => {
      return `${formatLoadHistoryValue(key, changedValues.newValue[key], {
        startcase: !(key in LoadsHistoryNonStartCaseFields),
        ...formatConfig,
      })}`;
    });
    const sameValStrings = changedValues.sameValue
      ? map(Object.keys(changedValues.sameValue), (key) => {
          return `${startCase(key)}: ${formatLoadHistoryValue(key, changedValues.sameValue[key], {
            startcase: !(key in LoadsHistoryNonStartCaseFields),
            ...formatConfig,
          })}`;
        })
      : [];

    return map(oldValStrings, (oldValString, i) => {
      return `${oldValString} \u2192 ${newValStrings[i]}`;
    }).concat(sameValStrings);
  } else if (changedValues.hasOwnProperty('oldValue')) {
    return [
      `${startCase((changedValues?.oldValue as string) || 'None')} \u2192 ${startCase(
        (changedValues?.newValue as string) || 'None'
      )}`,
    ];
  } else {
    return map(Object.keys(changedValues), (key) => {
      return `${startCase(key)}: ${formatLoadHistoryValue(key, changedValues[key], {
        startcase: !(key in LoadsHistoryNonStartCaseFields),
        ...formatConfig,
      })}`;
    });
  }
}

export function getOldNewDeltaFields<T>(loadHistoryField: OldNewDeltaType<T>): {
  newValue: Partial<T>;
  oldValue: Partial<T>;
} {
  if (loadHistoryField.hasOwnProperty('newValue') && isObject(loadHistoryField.newValue)) {
    const newValues = pickBy(loadHistoryField.newValue, (val) => !!val);
    const oldValues = {};
    forEach(Object.keys(newValues), (key) => {
      oldValues[key] =
        loadHistoryField.oldValue && loadHistoryField.oldValue[key] ? loadHistoryField.oldValue[key] : null;
    });
    return {
      oldValue: oldValues,
      newValue: newValues,
    };
  } else {
    return {
      oldValue: loadHistoryField.oldValue,
      newValue: loadHistoryField.newValue,
    };
  }
}

export function getChangedFieldObject(loadHistoryField: any): {
  newValue: Record<string, string | number | boolean>;
  oldValue: Record<string, string | number | boolean>;
  sameValue: Record<string, string | number | boolean>;
} {
  const fields = pickBy(loadHistoryField, (val) => !!val);
  const oldValues = {},
    newValues = {},
    sameValue = {};
  forEach(Object.keys(fields), (key) => {
    if (!fields[key].hasOwnProperty('oldValue')) {
      sameValue[key] = fields[key];
    } else if (fields[key].oldValue && fields[key].oldValue.constructor === Object) {
      const { oldValue, newValue } = getOldNewDeltaFields(fields[key]);
      forEach(Object.keys(fields[key].oldValue), (fieldKey, i) => {
        oldValues[fieldKey] = oldValue[fieldKey];
        newValues[fieldKey] = newValue[fieldKey];
      });
    } else {
      oldValues[key] = fields[key].oldValue;
      newValues[key] = fields[key].newValue;
    }
  });

  return {
    oldValue: isEmpty(oldValues) ? null : oldValues,
    newValue: isEmpty(newValues) ? null : newValues,
    sameValue: isEmpty(sameValue) ? null : sameValue,
  };
}

export function getChangedFieldArrayAddOrRemove(
  fieldName: string,
  fieldDelta: Array<Record<string, unknown>>,
  action: string
): ActionStringObject {
  const actionString = `${action} ${LoadHistoryActionObject[fieldName]}`;
  const modifiedValues: { secondaryText: string[] }[] = map(fieldDelta, (delta) => {
    return { secondaryText: getSecondaryText(delta) };
  });
  return [actionString, modifiedValues];
}

export function getChangedFieldArrayModify(
  fieldName: string,
  fieldDelta: Array<Record<string, unknown>>
): ActionStringObject {
  const modifiedData = fieldDelta.filter((object) => !!existDeltas(object).length);
  const actionString = `${LoadHistoryActionType.CHANGED} ${LoadHistoryActionObject[fieldName]}`;
  if (!modifiedData.length) return [' ', [{ secondaryText: [] }]];
  const modifiedValues: { secondaryText: string[] }[] = map(modifiedData, (delta) => {
    const changes = getChangedFieldObject(delta);
    return { secondaryText: getSecondaryText(changes) };
  });
  return [actionString, modifiedValues];
}

export function existDeltas(object: Record<string, unknown>): Array<string> {
  if (!object) {
    return [];
  }

  // returns keys of non-null values
  return Object.keys(object).filter((key) => !!object[key]);
}

export function deepObjectExistDeltas(object: Record<string, unknown>): boolean {
  if (isObject(object)) {
    for (const key of Object.keys(object)) {
      if (has(object[key], 'oldValue') && object[key]['oldValue'] !== null) return true;
      if (has(object[key], 'newValue') && object[key]['newValue'] !== null) return true;
    }
  }
  return false;
}

export function deepArrayExistDeltas(arrayObject: {
  addedValues: Array<Record<string, unknown>>;
  removedValues: Array<Record<string, unknown> | { oldValue: any; newValue: any }>;
  modifiedValues: Array<Record<string, unknown>>;
}): boolean {
  for (const key in arrayObject) {
    const arrayObjectValues: Array<Record<string, unknown>> = arrayObject[key];
    if (arrayObjectValues.length) {
      for (const arrayObjectValue of arrayObjectValues) {
        const deltaKeys: string[] = existDeltas(arrayObjectValue);

        if (key === 'addedValues' || key === 'removedValues') {
          if (deltaKeys.length) return true;
        } else if (key === 'modifiedValues') {
          for (const deltaKey of deltaKeys) {
            const deltaValue = arrayObjectValue[deltaKey];
            if (deltaValue && deltaValue.hasOwnProperty('oldValue')) {
              if (deltaValue['oldValue'] || deltaValue['newValue']) return true;
            } else if (deltaKey === 'oldValue' || deltaKey === 'newValue') {
              return true;
            }
          }
        }
      }
    }
  }

  return false;
}

/**
 * @param loadHistoryInput loadhistory object from gql
 * @returns loadhistory object with exceptions removed
 */
export function removeExceptions(loadHistoryInput: LoadHistory[]): LoadHistory[] {
  return loadHistoryInput.map((loadHistory: LoadHistory) => {
    const newloadHistory: LoadHistory = {
      ...loadHistory,
      loadDelta: loadHistory.loadDelta ? { ...loadHistory.loadDelta } : null,
    };
    if (newloadHistory.loadDelta) {
      for (const key in newloadHistory.loadDelta) {
        if (key in removeExceptionTypes && newloadHistory.loadDelta[key]) {
          newloadHistory.loadDelta[key] = removeExceptionTypes[key](newloadHistory.loadDelta[key]);
        }
      }
    }
    return newloadHistory;
  });
}
