import gql from 'graphql-tag';

export const GetReverseGeocodeLocations = gql(`
query getReverseGeocodeLocations($coordinates:[LatLonInput!], $placeType: PlaceType){
  getReverseGeocodeLocations(coordinates:$coordinates, placeType: $placeType){
    addressNumber
    city
    country
    fullAddress
    lat
    lon
    placeType
    state
    street
    zipcode
  }
}
`);
