import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DriverMarker, MapClusterService } from '@haulynx/services';
import { MarkerStyles } from '@haulynx/types';
import { LngLatBounds, LngLatLike } from 'mapbox-gl';

const defaultZoom = 5;

@Component({
  selector: 'app-map-cluster',
  templateUrl: './map-cluster.component.html',
  styleUrls: ['./map-cluster.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapClusterComponent implements OnChanges {
  @ViewChild('clusterMap', { static: true }) clusterMap: ComponentRef<any>;
  @Input() style = 'mapbox://styles/haulynx/ckmxzlojk10b717pi7svpdxgt';
  @Input() padding = 10;
  @Input() zoom = defaultZoom;
  @Input() center: LngLatLike = [-100.04, 38.907];
  @Input() showControls = false;
  @Input() dark = true;
  @Input() driverMarkers: DriverMarker[] = [];
  @Input() isInteractive = true;
  @Input() borderRadius: 'all' | 'none' | 'top' | 'left' | 'bottom' | 'right' = 'all';
  @Input() scrollZoom = true;
  @Input() maxZoom = null;

  mapStyle = {
    DARK: 'mapbox://styles/haulynx/ckmxzlojk10b717pi7svpdxgt',
    STREET: 'mapbox://styles/mapbox/streets-v9',
  };
  markerStyle = MarkerStyles;
  bounds: LngLatBounds;
  cluster: any;
  selectedCluster: { geometry: GeoJSON.Point; properties: any };
  selectedFeature: { geometry: GeoJSON.Point; properties: any };

  constructor(private mapClusterService: MapClusterService) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { center, driverMarkers } = changes;

    if (center) {
      const { lon = 0, lat = 0 } = center.currentValue;

      this.center = [lon, lat];
    }

    if (driverMarkers) {
      this.initMap();
    }
  }

  selectCluster(event: MouseEvent, feature: any, clusterComponent, clusterMap) {
    this.selectedCluster = feature;
    this.getClusterExpansionZoom(event, clusterComponent, clusterMap);
  }

  selectMarker(event: MouseEvent, feature: any, marker) {
    event.stopPropagation(); // This is needed, otherwise the popup will close immediately
    // Change the ref, to trigger mgl-popup onChanges (when the user click on the same marker)
    this.selectedFeature = feature;
  }

  removeSelected() {
    this.selectedFeature = null;
  }

  private getClusterExpansionZoom(event, clusterComponent, clusterMap) {
    const clusterId = this.selectedCluster.properties.cluster_id;
    const currentZoom = Math.floor(clusterMap.mapInstance.getZoom());
    clusterComponent.getClusterExpansionZoom(clusterId, currentZoom).then((zoom) => {
      this.flyTo(clusterMap, this.selectedCluster.geometry.coordinates, zoom);
    });
  }

  private initMap() {
    this.cluster = this.mapClusterService.initCluster(this.driverMarkers);
    this.bounds = this.mapClusterService.zoomToBounds(this.driverMarkers);
  }

  private flatten(list): LngLatLike[] {
    return list.reduce((a, b) => a.concat(b), []);
  }

  private flyTo(clusterMap, coordinates, zoom) {
    clusterMap.mapInstance.flyTo({
      center: coordinates,
      zoom: zoom + 2,
    });
  }
}
