import { CommonModule } from '@angular/common';
import { ConfirmBookLoadComponent } from './confirm-book-load.component';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [ConfirmBookLoadComponent],
  imports: [CommonModule],
  exports: [ConfirmBookLoadComponent],
})
export class ConfirmBookLoadModule {}
