import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoadsServiceService } from '@haulynx/services';
import moment from 'moment';

@Component({
  selector: 'loads-logs-viewer-app',
  template: `
    <h2 matDialogTitle>Logs</h2>
    <mat-dialog-content style="overflow-y: scroll; word-wrap: break-word; border: 1px solid gray; margin: 5px;">
      <div class="row">
        <ul>
          <li *ngFor="let log of logs">
            <div>
              <b>{{ getDate(log.date) }}</b
              >:
              <pre>{{ log.text }}</pre>
            </div>
          </li>
        </ul>
      </div>
      <mat-spinner style="float: right;" *ngIf="loading" [diameter]="32" [strokeWidth]="5"> </mat-spinner>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-button [matDialogClose]="">Close</button>
      <button mat-button color="primary" (click)="print()">Print</button>
    </mat-dialog-actions>
  `,
  // styles: ['pre { white-space: normal;}']
})
export class LoadsLogsViewerComponent {
  public loadId: string;
  public logs: any[];
  public loading = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<LoadsLogsViewerComponent>,
    public loadsServiceService: LoadsServiceService
  ) {
    this.loadId = data;
  }
  ngOnInit() {
    this.loading = true;
    this.loadsServiceService
      .getLogs(this.loadId)
      .then((logs) => {
        this.loading = false;
        this.logs = logs;
      })
      .catch((err) => {
        this.loading = false;
      });
  }

  getDate(timestamp: number) {
    return moment.unix(timestamp / 1000).format('MM/DD/YYYY hh:mm:ss A');
  }

  print() {
    const myWindow = window.open('');
    myWindow.document.write(document.getElementsByClassName('mat-dialog-content')[0].innerHTML);
  }
}
