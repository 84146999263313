import { Pipe, PipeTransform } from '@angular/core';
import { includes } from 'lodash';

@Pipe({
  name: 'contains',
})
export class ContainsPipe implements PipeTransform {
  transform(source: string | any[] | object, value: string | number): boolean {
    return includes(source, value);
  }
}
