<div class="account" fxFlexFill>
  <haulynx-account-header
    ngClass.lt-md="mobile-header"
    [user]="user | async"
    (signOutClicked)="signOut()"
    (routeClicked)="route($event)"
  ></haulynx-account-header>
  <div class="outlet" fxLayout="row" fxLayout.lt-md="column">
    <div
      fxFlex.gt-md="640px"
      fxFlex.md="400px"
      fxFlexOrder.gt-sm="1"
      fxFlexOrder.lt-md="2"
      ngClass.gt-sm="web-form"
      ngClass.lt-md="mobile-form"
    >
      <router-outlet></router-outlet>
    </div>
    <div
      class="picture"
      ngClass.lt-md="mobile-picture"
      fxFlex
      fxFlex.lt-md="30"
      fxFlexOrder.gt-sm="2"
      fxFlexOrder.lt-md="1"
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      <div class="svg">
        <svg-icon [src]="'assets/images/account/' + imageSrc + '.svg'"></svg-icon>
      </div>
    </div>
  </div>
</div>
