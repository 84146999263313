import { LoadsServiceLoad } from '@haulynx/types';
import { Pipe, PipeTransform } from '@angular/core';
import { format, utcToZonedTime } from 'date-fns-tz';
import { nth, toNumber } from 'lodash';

@Pipe({
  name: 'loadTimeRange',
})
export class LoadTimeRangePipe implements PipeTransform {
  transform(
    load: LoadsServiceLoad,
    options: { stopIndex: number; addBreak: boolean; date?: string } = {
      stopIndex: 0,
      addBreak: true,
      date: 'MM/dd/yy',
    }
  ): string {
    const location = nth(load?.locations, options.stopIndex);
    if (!location) {
      return '';
    }

    const locationDateStart = utcToZonedTime(toNumber(location.appointmentStart), location.timezone);
    const date = options.date ? options.date : 'MM/dd/yy';

    const locationDateStartFormatted = format(locationDateStart, date, { timeZone: location.timezone });
    const locationTimeStartFormatted = format(locationDateStart, 'HH:mm', { timeZone: location.timezone });

    const locationDateEnd = utcToZonedTime(toNumber(location.appointmentEnd), location.timezone);
    const locationDateEndFormatted = format(locationDateEnd, date, { timeZone: location.timezone });
    const locationTimeEndFormatted = format(locationDateEnd, 'HH:mm', { timeZone: location.timezone });

    const locationDatesAreSame = locationDateStartFormatted === locationDateEndFormatted;
    const locationTimesAreSame = locationTimeStartFormatted === locationTimeEndFormatted;

    if (location.appointmentStart === null && location.appointmentEnd === null) {
      return '--';
    } else if (location.appointmentStart && !location.appointmentEnd) {
      return `${locationDateStartFormatted} ${locationTimeStartFormatted}`;
    } else if (locationDatesAreSame && locationTimesAreSame) {
      return `${locationDateStartFormatted} ${locationTimeStartFormatted}`;
    } else if (locationDatesAreSame && !locationTimesAreSame) {
      return `${locationDateStartFormatted} ${locationTimeStartFormatted} - ${locationTimeEndFormatted}`;
    } else {
      return `${locationDateStartFormatted} ${locationTimeStartFormatted} -${
        options.addBreak ? '<br>' : ' '
      }${locationDateEndFormatted} ${locationTimeEndFormatted}`;
    }
  }
}
