import { Injectable } from '@angular/core';
import { HttpStatus, LoadEntityQueryParam, LoadsServiceLoad } from '@haulynx/types';
import { Store } from '@ngrx/store';
import { List } from 'immutable';
import { Observable } from 'rxjs';
import { AppState } from '../../main-store/app.reducers';
import { LoadEntitiesActions } from './load-entities.actions';
import {
  getEntitiesSelector,
  getHttpStatusSelector,
  getLoadingSelector,
  getPaginationSelector,
  getQuerySelector,
} from './load-entities.selectors';

@Injectable()
export class LoadEntitiesModel {
  entities$: Observable<List<LoadsServiceLoad>> = this.store.select(getEntitiesSelector);
  isLoading$: Observable<boolean> = this.store.select(getLoadingSelector);
  query$: Observable<{ [key: string]: unknown }> = this.store.select(getQuerySelector);
  pagination$: Observable<{
    total?: number;
    limit: number;
    page: number;
  }> = this.store.select(getPaginationSelector);
  httpStatus$: Observable<HttpStatus> = this.store.select(getHttpStatusSelector);

  constructor(private store: Store<AppState>) {}

  setStateKey(data: { key: string }): void {
    this.store.dispatch(LoadEntitiesActions.setStateKey(data));
  }

  search(query: Partial<LoadEntityQueryParam>): void {
    this.store.dispatch(LoadEntitiesActions.search(query));
  }
}
