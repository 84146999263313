import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'haulynx-terms-of-service-text',
  templateUrl: './terms-of-service-text.component.html',
  styleUrls: ['./terms-of-service-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TermsOfServiceTextComponent {
  @ViewChild('termsOfService') private termsOfService: ElementRef;
  @Input() showScrollToBottomButton = false;
  @Output() reachedBottom = new EventEmitter();
  public bottomReached = false;

  checkScroll() {
    const pos = this.termsOfService.nativeElement.scrollTop + this.termsOfService.nativeElement.offsetHeight;
    const max = this.termsOfService.nativeElement.scrollHeight;

    if (pos >= max) {
      this.bottomReached = true;
      this.reachedBottom.emit(true);
    }
  }

  public scrollBottom() {
    this.termsOfService.nativeElement.scroll({
      top: this.termsOfService.nativeElement.scrollHeight,
      left: 0,
      behavior: 'smooth',
    });
  }
}
