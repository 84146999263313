<mat-card class="app-list-header">
  <div class="row">
    <div class="col-lg-12">
      <app-spinner [active]="loading"></app-spinner>

      <mat-card-header *ngIf="label">
        <mat-card-title>{{ label }}</mat-card-title>
      </mat-card-header>

      <mat-list role="list" class="app-list">
        <mat-list-item
          *ngFor="let item of items"
          role="listitem"
          [ngClass]="{ selected: item[key] === (itemSelected && itemSelected[key]) }"
          (click)="select(item)"
        >
          <div *ngIf="templateLabel; else noCustom">
            <ng-container *ngTemplateOutlet="templateLabel; context: { row: item }"></ng-container>
          </div>

          <ng-template #noCustom>
            {{ item[displayLabel] }}
          </ng-template>

          <mat-divider></mat-divider>
        </mat-list-item>
      </mat-list>
    </div>
  </div>
</mat-card>
