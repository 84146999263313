import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedLocationEffects } from './shared-location.effects';
import { sharedLocationReducer } from './shared-location.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('shared-location', sharedLocationReducer),
    EffectsModule.forFeature([SharedLocationEffects]),
  ],
})
export class SharedLocationStoreModule {}
