import { DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { AnalyticsService } from '@haulynx/services';
import { ANALYTICS_EVENT, Bid, LoadIdentifierType, LoadsServiceLoad } from '@haulynx/types';
import { getLoadsServiceLoadAlternateId, getLoadsServiceLoadBillOfLading } from '@haulynx/utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { size } from 'lodash';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '../../main-store/app.reducers';
import { DispatchAction } from '../../shared/store/helpers/action';
import { LoadFeedActionTypes } from './load-feed.actions';
import { loadFeedSearchSelector } from './load-feed.selectors';

@Injectable()
export class LoadFeedAnalyticsEffects {
  logLoadFeedSearch = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoadFeedActionTypes.SEARCH),
        map((action: DispatchAction) => action.payload),
        withLatestFrom(this.store.select(loadFeedSearchSelector.getQuery)),
        tap(([payload, searchQuery]) => {
          // this.loadFeedsSearchLoadingTrace = this.performance.trace('Load Feed Search');
          // this.loadFeedsSearchLoadingTrace.start();
        })
      ),
    {
      dispatch: false,
    }
  );

  logLoadFeedSearchResponse = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoadFeedActionTypes.SEARCH_SUCCESS),
        map((action: DispatchAction) => action.payload),
        tap((payload) => {
          const queryAnlytic = payload.payload?.query;
          const ents: LoadsServiceLoad[] = payload.entities;
          const quantity = ents.length;
          const analyticData = {
            originRadius: queryAnlytic.originRadius,
            destinationRadius: queryAnlytic.destinationRadius,
            equipmentType: queryAnlytic.equipmentType,
            pickUpDateRangeStart: queryAnlytic.pickupDate?.begin,
            pickUpDateRangeEnd: queryAnlytic.pickupDate?.end,
            deliveryDateRangeStart: queryAnlytic.deliveryDate?.begin,
            deliveryDateRangeEnd: queryAnlytic.deliveryDate?.end,
            origin: queryAnlytic.pickUpLocation?.address,
            destination: queryAnlytic.deliveryLocation?.address,
            originLat: queryAnlytic.pickUpLocation?.lat,
            originLon: queryAnlytic.pickUpLocation?.lon,
            destinationLat: queryAnlytic.deliveryLocation?.lat,
            destinationLon: queryAnlytic.deliveryLocation?.lon,
            totalResults: quantity,
          };
          try {
            // this.loadFeedsSearchLoadingTrace.stop();
            this.analytics.logEvent(ANALYTICS_EVENT.LOAD_FEED_SEARCH, analyticData);
          } catch (error) {
            // Ignore traces that are not running
          }
        })
      ),
    { dispatch: false }
  );

  logLoadFeedSearchError = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoadFeedActionTypes.SEARCH_ERROR),
        map((action: DispatchAction) => action.payload),
        tap((payload) => {
          // try {
          //   this.loadFeedsSearchLoadingTrace.putAttribute(`Error`, payload);
          //   this.loadFeedsSearchLoadingTrace.stop();
          // } catch (error) {
          //   // Ignore traces that are not running
          // }
        })
      ),
    { dispatch: false }
  );

  logCreateBid = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoadFeedActionTypes.CREATE_BID),
        map((action: DispatchAction) => action.payload),
        tap((payload) => {
          const analyticValues = {
            carrierName: payload.bid.carrier?.name,
            carrierDot: +payload.bid.carrier?.dot,
            loadId: payload.load.id,
            loadTmwNum: getLoadsServiceLoadAlternateId(payload.load, LoadIdentifierType.TMW_NUMBER),
            loadOrderNum:
              getLoadsServiceLoadBillOfLading(payload.load) ||
              getLoadsServiceLoadAlternateId(payload.load, LoadIdentifierType.ORDER_NUMBER),
            bookItPrice: this.decimalPipe.transform(payload.load.price, '1.2-2'),
            offerPrice: this.decimalPipe.transform(payload.bid.price, '1.2-2'),
            notes: payload.bid.notes,
            status: payload.bid.status,
          };
          this.analytics.logEvent(ANALYTICS_EVENT.OFFER_CAPTURED, analyticValues);
        })
      ),
    {
      dispatch: false,
    }
  );

  logUpdateBid = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoadFeedActionTypes.UPDATE_BID),
        map((action: DispatchAction<{ load: LoadsServiceLoad; bid: Bid }>) => action.payload),
        tap((payload) => {
          const analyticValues = {
            carrierName: payload.bid.carrier?.name,
            carrierDot: +payload.bid.carrier?.dot,
            loadId: payload.load.id,
            loadTmwNum: getLoadsServiceLoadAlternateId(payload.load, LoadIdentifierType.TMW_NUMBER),
            loadOrderNum: getLoadsServiceLoadAlternateId(payload.load, LoadIdentifierType.ORDER_NUMBER),
            bookItPrice: payload.load.paymentDetails?.price,
            offerPrice: this.decimalPipe.transform(payload.bid.price, '1.2-2'),
            notes: payload.bid.notes,
            status: payload.bid.status,
          };
          this.analytics.logEvent(ANALYTICS_EVENT.OFFER_CAPTURED, analyticValues);
        })
      ),
    {
      dispatch: false,
    }
  );

  logSendLoadOffer = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoadFeedActionTypes.SEND_OFFER_LOAD),
        map((action: DispatchAction) => action.payload),
        tap((payload) => {
          this.analytics.logEvent(ANALYTICS_EVENT.BROKER_SEND_OFFER, payload);
        })
      ),
    {
      dispatch: false,
    }
  );

  logLoadFeedPanelToggle = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoadFeedActionTypes.SET_EXPANDED_PANELS),
        map((action: DispatchAction) => action.payload),
        filter((payload) => size(payload) === 1), // only log when the user individually clicks a panel,
        // nothing programatic.
        tap((payload) => {
          this.analytics.logEvent(ANALYTICS_EVENT.LOAD_FEED_TOGGLE_PANEL, payload);
        })
      ),
    {
      dispatch: false,
    }
  );

  logSelectTab = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoadFeedActionTypes.SELECT_TAB),
        map((action: DispatchAction) => action.payload),
        filter((payload) => payload.id !== 'search'),
        tap((payload) => {
          this.analytics.logEvent(ANALYTICS_EVENT.LOAD_FEED_TAB_SELECTED, { load: payload.data });
        })
      ),
    {
      dispatch: false,
    }
  );

  logCloseTab = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoadFeedActionTypes.REMOVE_TAB),
        map((action: DispatchAction) => action.payload),
        tap((payload) => {
          this.analytics.logEvent(ANALYTICS_EVENT.LOAD_FEED_REMOVE_TAB, { load: payload.data });
        })
      ),
    {
      dispatch: false,
    }
  );

  createBidSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoadFeedActionTypes.CREATE_BID_SUCCESS),
        map((action: DispatchAction) => action.payload),
        tap((payload) => {
          const analyticData = payload.analyticData;
          this.analytics.logEvent(ANALYTICS_EVENT.BID_SUBMITTED, analyticData);
        })
      ),
    {
      dispatch: false,
    }
  );

  updateBidSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoadFeedActionTypes.UPDATE_BID_SUCCESS),
        map((action: DispatchAction) => action.payload),
        tap((payload) => {
          this.analytics.logEvent(ANALYTICS_EVENT.BID_SUBMITTED, payload);
        })
      ),
    {
      dispatch: false,
    }
  );

  acceptCounterBidSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoadFeedActionTypes.ACCEPT_COUNTER_BID_SUCCESS),
        map((action: DispatchAction) => action.payload),
        tap((payload) => {
          this.analytics.logEvent(ANALYTICS_EVENT.BID_COUNTER_ACCEPTED, payload);
        })
      ),
    {
      dispatch: false,
    }
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private analytics: AnalyticsService,
    private decimalPipe: DecimalPipe
  ) {}
}
