import { createFeatureSelector, createSelector } from '@ngrx/store';
import { favoriteAddressAdapter } from './adapters';
import { FavoriteAddressState } from './favorite-address.state';

export const getFavoriteAddressState = createFeatureSelector<FavoriteAddressState>('favoriteAddress');

const getSearchState = createSelector(getFavoriteAddressState, (state) => {
  return state.search;
});

export const getFavoriteAddressSearchSelector = favoriteAddressAdapter.search.getSelectors(getSearchState);

export const isLoadingFavoriteAddressForm = createSelector(getFavoriteAddressState, (state) => {
  return state.isLoadingFavoriteAddressForm;
});
export const favoriteAddressFormHttpStatus = createSelector(getFavoriteAddressState, (state) => {
  return { httpStatus: state.favoriteAddressFormHttpStatus, response: state.responseHttp };
});
