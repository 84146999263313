import { LngLatLike } from 'mapbox-gl';

export interface LoadRouteFeature {
  type: string;
  geometry: {
    type: string;
    properties: any;
    coordinates: LngLatLike[];
  };
}
