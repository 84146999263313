import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { get, last } from 'lodash';
import { LoadsServiceLoad } from '@haulynx/types';
import { LoadLocationsService } from '@haulynx/services';
import { aliveWhile, shortenCityName } from '@haulynx/utils';

@Component({
  selector: 'app-load-single-item',
  templateUrl: './load-single-item.component.html',
  styleUrls: ['./load-single-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadSingleItemComponent implements OnDestroy, OnChanges {
  @Input() load: LoadsServiceLoad;
  @Input() isSelected?: boolean;
  @Input() label = null;
  @Input() isBroker = false;
  @Input() showBookLoad = false;
  @Input() showDecline = false;
  @Output() onBookSuccess = new EventEmitter<void>();
  @Output() offerDeleteSuccessEvent = new EventEmitter<LoadsServiceLoad>();

  firstLocationName: string;
  lastLocationName: string;
  carrierAbbreviation: string;
  deliveryDate: Date;
  alive = aliveWhile();

  constructor(private loadLocationsService: LoadLocationsService) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { load } = changes;

    if (load?.currentValue) {
      const loadCurrent: LoadsServiceLoad = load.currentValue;
      this.carrierAbbreviation = get(loadCurrent, 'carrier.name', '')?.replace(/\s+/g, '')?.substr(0, 6);
      const { firstCity, lastCity } = this.loadLocationsService.findFirstAndLastCityNames(loadCurrent);
      this.firstLocationName = shortenCityName(firstCity);
      this.lastLocationName = shortenCityName(lastCity);
      this.deliveryDate = new Date(get(last(loadCurrent.locations), 'appointmentStart', null));
    }
  }

  offerDeleteSuccess(load: LoadsServiceLoad): void {
    this.offerDeleteSuccessEvent.emit(load);
  }

  bookSuccess(): void {
    this.onBookSuccess.emit();
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }
}
