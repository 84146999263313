import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CarrierService, NotificationsService } from '@haulynx/services';
import { AppModel, UserEntityService } from '@haulynx/store';
import { Carrier, equipmentTypes, Prefs, PasswordStrengthVariation, states, timeZones, User } from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { cloneDeep, findKey } from 'lodash';
import { Observable, of } from 'rxjs';
import { map, mergeMap, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit, OnDestroy {
  alive = aliveWhile();
  user: User;
  company: string;
  equipmentTypes: string[] = [];
  timeZone: string[] = timeZones;
  password: string;
  confirmPassword: string;
  currentPassword: string;
  saving = false;
  public selectedIndex = 0;
  statesList = states;
  public hidePassword = true;  

  constructor(
    private appModel: AppModel,
    private carrierService: CarrierService,
    private router: Router,
    private snackBar: MatSnackBar,
    private userEntityService: UserEntityService,
    private notificationsService: NotificationsService
  ) {
    this.appModel.user$.pipe(takeUntil(this.alive)).subscribe((user) => {
      this.user = cloneDeep(user);

      if (!this.user.prefs) {
        this.user.prefs = new Prefs();
      }
    });

    const url = this.router.url;
    if (url && url.includes('referral')) {
      this.selectedIndex = 3;
    }

    this.userEntityService.updateUserSettingsManager.isLoading$.pipe(takeUntil(this.alive)).subscribe((isLoading) => {
      this.saving = isLoading;
    });
  }

  ngOnInit() {
    if (this.user.isHaulynxAdmin) {
      this.company = 'Haulynx Admin';
    }
    if (this.user.carrier) {
      this.company = this.user.carrier.name;
    }
    if (this.user.shipper) {
      this.selectedIndex = 2;
      this.company = this.user.shipper.name;
    }
    if (this.user.broker) {
      this.company = this.user.broker.name;
    }
    this.appModel.user$
      .pipe(
        takeUntil(this.alive),
        mergeMap((user: User) =>
          this.getCarrier(user).pipe(
            map((userCarrier) => {
              return { userCarrier, user };
            })
          )
        )
      )
      .subscribe(({ userCarrier, user }) => {
        if (user && user.carrier && user.isCompanyAdmin && user?.carrier?.id !== userCarrier?.id) {
          const withUserCarrier = new User({
            ...user,
            carrier: userCarrier,
          });
          this.user = cloneDeep(withUserCarrier);
        } else {
          this.user = cloneDeep(user);
        }

        if (user && user.carrier && user.isCompanyAdmin) {
          equipmentTypes.forEach((e) => {
            if (e.text !== 'N/A') {
              this.equipmentTypes.push(e.text);
            }
          });
        } else {
          this.equipmentTypes = [];
        }
      });

    this.userEntityService.updateUserSettingsManager.onResponse$.pipe(takeUntil(this.alive)).subscribe((response) => {
      response.message == null
        ? this.notificationsService.success('Settings updated successfully!', 'User Update')
        : this.notificationsService.error(response.message, 'Oops, something went wrong');
    });    
  }

  ngOnDestroy() {
    this.alive.destroy();
  }

  updateInfo(): void {
    const { id: userId, name, phone, carrier, isDriver, issuingState, licenseNumber, isCompanyAdmin } = this.user;
    let userInfo: Partial<User> = { name, phone };

    if (carrier && isCompanyAdmin) {
      userInfo = { ...userInfo, isDriver };

      if (isDriver) {
        userInfo = { ...userInfo, issuingState, licenseNumber };
      }
    }

    this.userEntityService.updateUserSettingsManager.dispatch(userId, { userId, userInfo });
  }

  updatePassword(): void {
    if (!this.currentPassword || this.currentPassword.length === 0) {
      this.snackBar.open('Current password is required', null, { duration: 2000 });
      return;
    }

    const requirementsChecklist: PasswordStrengthVariation = {
      length: /.{8}/.test(this.password || ''),
      lowerCase: /[a-z]/.test(this.password || ''),
      upperCase: /[A-Z]/.test(this.password || ''),
      specialCharacter: /[^A-Za-z0-9 ]/.test(this.password || ''),
    };

    const checkRequirements = findKey(requirementsChecklist, (item) => {
      return item === false;
    });

    if (checkRequirements) {
      let requirementNotMet;

      switch (checkRequirements) {
        case 'length':
          requirementNotMet = 'a length greater than 8 characters';
          break;
        case 'lowerCase':
          requirementNotMet = 'at least one lowercase letter';
          break;
        case 'upperCase':
          requirementNotMet = 'at least one uppercase letter';
          break;
        case 'specialCharacter':
          requirementNotMet = 'at least one special character';
          break;
        default:
          requirementNotMet = '1 lowercase character, 1 special character, 1 uppercase character, 8 characters minimum';
      }
      this.snackBar.open(`Password requires ${requirementNotMet}`, null, { duration: 2000 });
      return;
    }

    if (this.password !== this.confirmPassword) {
      this.snackBar.open("Passwords don't match", null, { duration: 2000 });
      return;
    }

    if (this.password == this.currentPassword) {
      this.snackBar.open('Current password and new password should not be the same', null, { duration: 2000 });
      return;
    }

    const { id: userId } = this.user;
    this.userEntityService.updateUserSettingsManager.dispatch(userId, {
      userId,
      userInfo: { currentPassword: this.currentPassword, password: this.password },
    });

    this.currentPassword = '';
    this.password = '';
    this.confirmPassword = '';
  }

  updatePrefs(): void {
    const { id: userId, prefs } = this.user;

    this.userEntityService.updateUserSettingsManager.dispatch(userId, { userId, userInfo: { prefs } });
  }

  private getCarrier(user: User): Observable<Carrier> {
    if (user && user.carrier && user.isCompanyAdmin) {
      return this.carrierService.getCarrier(user.carrier.id);
    }
    return of(null);
  }

  launchRmis() {
    this.userEntityService.rmisTokenManager.dispatch();
    this.userEntityService.rmisTokenManager.onError$.pipe(take(1)).subscribe(() => {});

    this.userEntityService.rmisTokenManager.onSuccess$.pipe(take(1)).subscribe((response: { url: string }) => {
      window.open(response.url, '_target');
    });
  }
}
