import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ViewChild,
  OnChanges,
  AfterViewInit,
  ViewEncapsulation,
} from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { IAsyncSearchResultsPaginationState, IPagination } from '@haulynx/types';
import { id } from 'date-fns/locale';
import { MatSort, Sort } from '@angular/material/sort';

@Component({
  selector: 'haulynx-atomic-table',
  templateUrl: './atomic-table.component.html',
  styleUrls: ['./atomic-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AtomicTableComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() data: any[] = [];
  @Input() pagination: IAsyncSearchResultsPaginationState;
  @Input() loading: boolean = false;
  @Input() rowLimitOptions: number[] = [10, 25, 50, 100];
  @Output() deleteClicked = new EventEmitter<any>();
  @Output() editClicked = new EventEmitter<any>();
  // true is up, false is down
  @Output() pageChanged = new EventEmitter<boolean>();
  @Output() refreshClicked = new EventEmitter<void>();
  @Output() rowLimitChange = new EventEmitter<number>();
  @Output() sortChanged = new EventEmitter<Sort>();

  @ViewChild(MatSort) sort: MatSort;

  constructor() {}

  public displayedColumns: string[] = [];
  public displayedColumnTitles: ('delete' | 'edit' | { name: any; sortable: any })[] = [];

  ngOnInit(): void {
    if (this.data?.length) this.displayedColumns = [...Object.keys(this?.data[0]), 'edit', 'delete'];
    this.displayedColumnTitles = this.displayedColumns.map((columnDataName) => {
      if (columnDataName === 'delete' || columnDataName === 'edit') {
        return columnDataName;
      }
      return { name: this.data[0][columnDataName].name, sortable: this.data[0][columnDataName]?.sortable ?? true };
    });
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sortInfo) => this.sortChanged.emit(sortInfo));
  }

  ngOnChanges(): void {
    if (this.data?.length) this.displayedColumns = [...Object.keys(this?.data[0]), 'edit', 'delete'];
    this.displayedColumnTitles = this.displayedColumns.map((columnDataName, index) => {
      if (columnDataName === 'delete' || columnDataName === 'edit') {
        return columnDataName;
      }
      return { name: this.data[0][columnDataName]?.name, sortable: this.data[0][columnDataName]?.sortable ?? true };
    });
  }

  public rowToString(row: any): string {
    return JSON.stringify(row);
  }

  public onEditClicked(row: any): void {
    this.editClicked.emit(row);
  }

  public onDeleteClicked(row: any): void {
    this.deleteClicked.emit(row);
  }

  public nextPageClicked() {
    // make sure we don't go past the last page
    if (this.pagination.currentPage < this.pagination?.total) {
      this.pageChanged.emit(true);
    }
  }

  public previousPageClicked() {
    // make sure we don't go past the first page
    if (this.pagination.currentPage > 1) {
      this.pageChanged.emit(false);
    }
  }

  public onRefreshClicked() {
    this.refreshClicked.emit();
  }

  public onRowLimitChange(event: any) {
    this.rowLimitChange.emit(event.value);
  }
}
