import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VirtualTableSortComponent } from './virtual-table-sort.component';
import { MaterialModule } from '@haulynx/modules';
import { FlexLayoutModule } from '@angular/flex-layout';
import { VirtualTableSortDialogModule } from './virtual-table-sort-dialog/virtual-table-sort-dialog.module';
import { PipesModule } from '@haulynx/pipes';

@NgModule({
  declarations: [VirtualTableSortComponent],
  imports: [CommonModule, MaterialModule, FlexLayoutModule, PipesModule, VirtualTableSortDialogModule],
  exports: [VirtualTableSortComponent],
})
export class VirtualTableSortModule {}
