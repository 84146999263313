import { ActionReducer, ActionReducerMap } from '@ngrx/store';
import { InjectionToken, Provider } from '@angular/core';
import { LaneRateEntityService } from './lane-rate-entity.service';
import { USER_PROVIDED_EFFECTS } from '@ngrx/effects';
import { IAsyncEntityState } from '@haulynx/types';

export const LANE_RATE_ENTITY_REDUCERS_TOKEN = new InjectionToken<ActionReducerMap<IAsyncEntityState<unknown>>>(
  'lane rate reducers'
);

function getLaneRateEntityReducersFactory(
  laneRateEntityService: LaneRateEntityService
): ActionReducer<IAsyncEntityState<unknown>> {
  return laneRateEntityService.getReducers();
}

const laneRateEntityReducerProvider: Provider = {
  provide: LANE_RATE_ENTITY_REDUCERS_TOKEN,
  useFactory: getLaneRateEntityReducersFactory,
  deps: [LaneRateEntityService],
};

const laneRateEntityEffectProvider: Provider = {
  provide: USER_PROVIDED_EFFECTS,
  multi: true,
  useValue: [LaneRateEntityService],
};

export const laneRateStateProviders: Provider[] = [laneRateEntityReducerProvider, laneRateEntityEffectProvider];
