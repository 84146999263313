export enum TrackingType {
  /**
   * default tracking type, means that brokers and drivers will (hopefully) enter location entrance / exit times manually
   */
  MANUAL = 'manual',
  /**
   * this load's truck is a haulynx driver's phone
   */
  PHONE = 'driverPhone',
  /**
   * this load's truck is a non-haulynx driver's phone
   */
  DRIVER_LITE = 'driverLite',
  /**
   * this load's truck is being tracked in Project44
   */
  P44 = 'p44',
  /**
   * this load's truck is being tracked in MacroPoint
   */
  MACRO_POINT = 'mp',
  /**
   * ONLY FOR FRONT END TYPING NOT A VALID API ENUM INPUT USE MP
   */
  MACRO_POINT_ELD = 'mpEld',
}
