<div class="search-recent-search-container" #wrapper>
  <div class="src-main-title">recent searches</div>

  <div
    class="src-recent-options"
    *ngFor="let recentSearch of recentSearchesConvertedToFilters; let i = index"
    [class.isHovering]="i === indexOfFocus"
    (click)="onSelectRecentSearch(recentSearch)"
    (mouseenter)="indexOfFocus = i; isMouseFocus = true"
    (mouseleave)="indexOfFocus = -1; isMouseFocus = false"
    [scrollIntoView]="i === indexOfFocus && !isMouseFocus"
    [scrollDirection]="scrollDirection"
  >
    <div class="src-index">
      {{ i }}
    </div>

    <div class="src-chip" *ngFor="let filter of recentSearch; let x = index">
      <div class="src-chip-title">
        <div class="src-title">
          {{ filter.name }}
        </div>

        <div class="src-content">
          <span *ngIf="filter.type === textFilterType">
            {{ $any(filter).keys.value }}
          </span>
          <span *ngIf="filter.type === locationFilterType">
            {{ $any(filter).keys.location }}
          </span>
          <span *ngIf="filter.type === numberRangeFilterType">
            {{ $any(filter).keys.min }} - {{ $any(filter).keys.max }}
          </span>
          <span *ngIf="filter.type === dateRangeFilterType">
            {{ $any(filter).keys.from | date: 'shortDate' }} - {{ $any(filter).keys.to | date: 'shortDate' }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
