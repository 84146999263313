import gql from 'graphql-tag';

export const CreateUserFavoriteAddress = gql(`
  mutation CreateUserFavoriteAddress($address: AddressBasicInput) {
    createUserFavoriteAddress(address: $address) {
      lat
      lon
      address
      timeZone
      name
      customer
      notes
      id
    }
  }
`);
