<table class="payment-table">
  <tr *ngIf="formData.length">
    <th></th>
    <th class="payment-table__header">$ Rate</th>
    <th class="payment-table__header">Code</th>
    <th class="payment-table__header payment-table__header--quantity">QNTY</th>
    <th class="payment-table__header payment-table__header--quantity">Full Amount</th>
    <th class="payment-table__header">Description</th>
    <th class="payment-table__header payment-table__header--method">Method</th>
  </tr>
  <tr *ngFor="let control of form.controls; let i = index" data-test="payment-row">
    <ng-container [formGroup]="control">
      <td class="payment-table__item">
        <button
          mat-button
          class="payment-table__button"
          [disabled]="!canEdit"
          (click)="onDeletePayType($event, i, control.value)"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </td>
      <td class="payment-table__item">
        <input
          type="text"
          currencyMask
          [options]="{ thousands: ',', decimal: '.', allowNegative: true }"
          autocomplete="off"
          formControlName="amount"
          class="payment-table__input"
          data-test="amount"
          (keyup)="onEditPayType(i, control.value)"
          [readonly]="!canEdit"
        />
      </td>
      <td class="payment-table__item">
        <input
          type="text"
          [value]="control?.get('paymentType')?.value?.code"
          tabindex="-1"
          readonly
          class="payment-table__input"
        />
      </td>
      <td class="payment-table__item">
        <input
          type="number"
          min="0"
          formControlName="quantity"
          tabindex="-1"
          class="payment-table__input payment-table__input--quantity"
          data-test="quantity"
          (input)="onEditPayType(i, control.value)"
          [readonly]="control?.get('paymentType')?.value?.isReadOnly"
        />
      </td>
      <td class="payment-table__item">
        <input
          type="text"
          [value]="(control?.value?.quantity * control?.value?.amount).toFixed(2) || '0.00' | currency"
          tabindex="-1"
          readonly
          class="payment-table__input payment-table__input--quantity"
        />
      </td>
      <td class="payment-table__item">
        <input
          type="text"
          [value]="control?.get('paymentType')?.value?.description"
          tabindex="-1"
          readonly
          class="payment-table__input"
        />
      </td>
      <td class="payment-table__item">
        <input
          type="text"
          tabindex="-1"
          readonly
          [value]="control?.get('paymentType')?.value?.method"
          class="payment-table__input payment-table__input--method"
        />
      </td>
    </ng-container>
  </tr>
  <tr>
    <td [ngClass]="{ 'no-data': !formData.length }" class="payment-table__footer payment-table__footer--text">Total</td>
    <td [ngClass]="{ 'no-data': !formData.length }" class="payment-table__footer">
      <input
        type="text"
        class="payment-table__input payment-table__input--total"
        tabindex="-1"
        placeholder="$0.00"
        readonly
        [value]="total | currency"
      />
    </td>
    <td
      [ngClass]="{ 'no-data': !formData.length }"
      colspan="5"
      class="payment-table__footer payment-table__footer--action"
    >
      <button
        mat-button
        class="payment-table__button--add"
        (click)="onAddPayType($event)"
        data-test="add-pay-type"
        [disabled]="!canEdit"
        [appKeyboardShortcut]="'p'"
        [componentBusinessLabel]="'Payment Details'"
      >
        Add <span class="underlined">P</span>ayment Item
      </button>
    </td>
  </tr>
</table>
