import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CarrierToolbarService, MomentService, PostTruckService, UserService, WindowRef } from '@haulynx/services';
import { CarrierDashboardModel, CarrierEntityService, LoadEntityService, UserEntityService } from '@haulynx/store';
import { CarrierApiNote, CarrierApiNoteInput, MergedCarrierData, ToolbarLogConfigOptions, User } from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'haulynx-carrier-log',
  templateUrl: './carrier-log.component.html',
  styleUrls: ['./carrier-log.component.scss'],
})
export class CarrierLogComponent implements OnInit {
  @Input() notes: CarrierApiNote[];
  @Input() carrier: MergedCarrierData;
  alive = aliveWhile();

  public user: User;
  data$: Observable<CarrierApiNote[]>;
  paginationData$ = new BehaviorSubject<{
    currentPage: number;
    total: number;
    totalPages: number;
  }>(null);
  toolbarConfigOptions = ToolbarLogConfigOptions;
  showNotesForm = false;
  successfulNote = new BehaviorSubject(false);

  constructor(
    public dashboardCarrierModel: CarrierDashboardModel,
    public loadEntityService: LoadEntityService,
    public router: Router,
    private userEntityService: UserEntityService,
    private carrierService: CarrierEntityService,
    private windowRef: WindowRef
  ) {}

  ngOnInit(): void {
    this.carrierService.createCarrierNotesManager.onSuccess$.pipe(takeUntil(this.alive)).subscribe(() => {
      this.successfulNote.next(true);
    });

    this.userEntityService.user$.pipe(takeUntil(this.alive)).subscribe((user) => {
      this.user = user;
    });

    this.paginationData$.next({
      currentPage: 1,
      total: this.notes?.length,
      totalPages: Math.ceil(this.notes?.length / ToolbarLogConfigOptions.pageAmount),
    });
  }

  onPage(event: { pageUp: boolean; currentPage: number; limit: number }): void {
    this.paginationData$.next({
      currentPage: event.currentPage + (event.pageUp ? 1 : -1),
      total: this.notes.length,
      totalPages: Math.ceil(this.notes?.length / ToolbarLogConfigOptions.pageAmount),
    });
  }

  viewNotesForm(formIsOpen): void {
    if (!formIsOpen) this.showNotesForm = true;
  }

  hideNotes() {
    this.showNotesForm = false;
  }

  saveNote(noteInput: CarrierApiNoteInput): void {
    if (noteInput.content !== '') {
      this.carrierService.createCarrierNotesManager.dispatch(this.carrier.id, {
        carrierComplianceTicketId: this.carrier.id,
        carrierNoteInput: noteInput,
      });
    }
  }

  callCarrier() {
    this.windowRef.getNativeWindow().open('tel:' + this.carrier?.phone, '_blank');
  }
}
