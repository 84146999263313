import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@haulynx/modules';
import { WhiteProgressSpinnerComponent } from './white-progress-spinner.component';

@NgModule({
  declarations: [WhiteProgressSpinnerComponent],
  imports: [CommonModule, MaterialModule],
  exports: [WhiteProgressSpinnerComponent],
})
export class WhiteProgressSpinnerModule {}
