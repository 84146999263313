import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LoadDispatchFormVm, MomentService, LoadDispatchForm } from '@haulynx/services';
import { AddressField, BookStatus, DispatchForm } from '@haulynx/types';
import { get } from 'lodash';

@Component({
  selector: 'app-load-dispatch-form',
  templateUrl: './load-dispatch-form.component.html',
  styleUrls: ['./load-dispatch-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadDispatchFormComponent implements OnInit, OnChanges {
  @Input() bookedLoad: boolean = true;
  @Input() formData: DispatchForm = null;
  @Input() form: FormGroup;
  @Input() isLoading = false;
  @Input() showSkipButton = false;
  @Input() showSaveButton = true;
  @Input() formControlCls: 'app-dark-field' | 'app-form-field' = 'app-form-field';
  @Input() formCls: 'dispatch-form-column' | 'dispatch-form-row' = 'dispatch-form-column';
  @Output() saveDispatchForm = new EventEmitter<DispatchForm>();
  @Output() skipStep = new EventEmitter<boolean>();

  constructor(
    private loadQuickDispatchFormVm: LoadDispatchFormVm,
    private changeDetectorRef: ChangeDetectorRef,
    private momentService: MomentService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { formData } = changes;

    if (formData) {
      if (!this.form) {
        this.form = this.loadQuickDispatchFormVm.create();
      }

      if (!formData.currentValue) {
        this.form.setValue({});
      } else {
        const { timeAvailable, location, notes } = formData.currentValue as DispatchForm;
        const timeAvailableMoment = this.momentService.getMoment(timeAvailable, location.timeZone).valueOf();

        this.form.setValue({
          timeAvailable: timeAvailableMoment,
          location,
          notes,
        });
      }
    }
  }

  checkForm(event) {
    this.changeDetectorRef.detectChanges();
  }

  save(event, formData: LoadDispatchForm) {
    event.stopPropagation();

    if (this.form.valid) {
      const { location = null, notes = null, timeAvailable = null } = formData;
      const newFormData: DispatchForm = {
        notes: notes,
        timeAvailable: timeAvailable && timeAvailable.valueOf(),
        location: location,
      };

      this.saveDispatchForm.emit(newFormData);
    }
  }

  skip(event) {
    event.stopPropagation();
    this.skipStep.emit(true);
  }

  public fixTimezone(): void {
    const timeZone = get(this.form.get('location').value as AddressField, 'timeZone', 'America/Phoenix');
    const control = this.form.controls['timeAvailable'];
    const newTime = control.value.clone().tz(timeZone, true);
    control.setValue(newTime);
  }

  ngOnInit(): void {
    if (!this.form) {
      this.form = this.loadQuickDispatchFormVm.create();
    }
  }
}
