<div class="add-driver" *ngIf="carrierDot || carrierId; else noCarrier">
  <h3 class="add-driver__title">Create New Driver</h3>

  <p class="add-driver__description">Any changes that you make will appear across the platform</p>

  <form [formGroup]="form" data-test="driver-form" (ngSubmit)="save()">
    <div class="add-driver__row">
      <mat-label class="add-driver__label"> Driver </mat-label>
      <mat-form-field class="app-form-field add-driver__field" hideRequiredMarker appearance="fill" floatLabel="always">
        <input matInput formControlName="name" autocomplete="off" placeholder="Name" />
      </mat-form-field>
    </div>

    <div class="add-driver__row">
      <mat-label class="add-driver__label"> Phone </mat-label>
      <mat-form-field class="app-form-field add-driver__field" hideRequiredMarker appearance="fill" floatLabel="always">
        <input matInput mask="000-000-0000" formControlName="phone" autocomplete="off" placeholder="Phone" />
      </mat-form-field>
    </div>

    <div *ngIf="isChecked" class="add-driver__row">
      <mat-label class="add-driver__label"> Email </mat-label>
      <mat-form-field class="app-form-field add-driver__field" hideRequiredMarker appearance="fill" floatLabel="always">
        <input matInput formControlName="email" placeholder="me@mail.com" type="email" />
      </mat-form-field>
    </div>

    <div>
      <div class="add-driver__row">
        <mat-checkbox color="primary" [checked]="isChecked" (change)="toggle($event)"
          >Invite Driver to mobile tracking</mat-checkbox
        >
      </div>
    </div>

    <div class="add-driver__action">
      <button
        mat-dialog-close
        class="hlx-button-command hlx-button-command--cancel hlx-button-command--border-white add-driver__button"
      >
        Go Back
      </button>

      <button
        type="submit"
        class="hlx-button-command hlx-button-command--book add-driver__button"
        [disabled]="form.invalid"
        (click)="save()"
        data-test="submit-button"
      >
        Submit
      </button>
    </div>
  </form>
</div>

<ng-template #noCarrier>
  <div class="no-carrier-dot">
    <p class="add-driver__description">Carrier is required to add a driver</p>
    <button mat-dialog-close class="hlx-button-command hlx-button-command--cancel hlx-button-command--border-white">
      Ok
    </button>
  </div>
</ng-template>
