<div class="bd-container {{ randomClass }}" #wrapper>
  <div
    class="bd-container__button {{ randomClass }}"
    [ngStyle]="{
      background: buttonBackgroundColor && buttonBackgroundColor !== '' ? buttonBackgroundColor : '#262933'
    }"
    (click)="openButton()"
    [tooltip]="label"
  >
    <div *ngIf="label !== ''" class="bd-container__button__label {{ randomClass }}">
      {{ label | textCutoff }}
    </div>

    <div class="bd-container__button__icon {{ randomClass }}">
      <mat-icon class="bd {{ randomClass }}">
        {{ isButtonClicked ? openIcon : closedIcon }}
      </mat-icon>
    </div>
  </div>

  <div
    class="bd-container__dropdown-wrapper bd-container__dropdown-wrapper{{ dropdownPosition }} {{ randomClass }}"
    *ngIf="isButtonClicked"
    [ngStyle]="{ left: showDropdownFromLeft ? '0px' : 'inherit', right: showDropdownFromLeft ? 'inherit' : '0px' }"
  >
    <div class="dropdown-container {{ randomClass }}">
      <div *ngFor="let row of dropdownList; let i = index" class="dropdown-item {{ randomClass }}">
        <div
          [ngClass]="{
            'dropdown-item__container {{ randomClass }}': true,
            disabled: disabledDropdownIndices && disabledDropdownIndices[i]
          }"
          (click)="selectRow(row)"
        >
          <div *ngIf="isMultiSelect" class="dropdown-item__checkbox-container {{ randomClass }}">
            <div class="dropdown-item__checkbox{{ getIsChecked(row) ? '-checked' : '' }} {{ randomClass }}">
              <div class="bd checkbox-check {{ randomClass }}" *ngIf="getIsChecked(row)">L</div>
            </div>
          </div>
          <div
            [ngClass]="{
              'dropdown-item__label {{ randomClass }}': true,
              'icon-label': dropdownListOptions,
              'align-left': showDropdownFromLeft
            }"
            attr.data-test="{{ row.key }}"
          >
            <mat-icon *ngIf="dropdownListOptions" [svgIcon]="dropdownListOptions[row.value].svgIcon"></mat-icon>
            {{ row.key }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
