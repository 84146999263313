import { DateRangeFilterType, ISearchFilter } from '../search-filter';
import { ISearchFilterType } from '../search-filter.types';

export class ActivityDateFilter implements ISearchFilter {
  name = 'Date';
  type: ISearchFilterType = ISearchFilterType.DATE_RANGE;
  keys: DateRangeFilterType;
  description = 'Filter load activities by date';

  localFilterFn<loadHistory>(data: loadHistory[], searchbarVal: Record<string, any>): loadHistory[] {
    return data.filter((loadHistoryRow) => {
      return (
        loadHistoryRow['updatedAt'] <= searchbarVal?.dateEnd && loadHistoryRow['updatedAt'] >= searchbarVal?.dateStart
      );
    });
  }

  constructor(defaultValues?: DateRangeFilterType) {
    this.keys = new DateRangeFilterType({
      from: null,
      to: null,
      fromFormName: 'dateStart',
      toFormName: 'dateEnd',
      ...defaultValues,
    });
  }
}
