import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CommonEntitiesEffects } from '../../shared/common-entities/common-entities.effects';
import {
  carrierLoadSearchStateProviders,
  CARRIER_LOAD_SEARCH_ENTITY_REDUCERS_TOKEN,
} from './carrier-load-search-entity.config';

export const carrierLoadSearchNamespace = 'carrier-load-search';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(carrierLoadSearchNamespace, CARRIER_LOAD_SEARCH_ENTITY_REDUCERS_TOKEN),
    EffectsModule.forFeature([CommonEntitiesEffects]),
  ],
  providers: [...carrierLoadSearchStateProviders],
})
export class CarrierLoadSearchEntityModule {}
