import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchRadiusFieldMobileComponent } from './search-radius-field-mobile.component';
import { BlueInputModule } from '../blue-input/blue-input.module';
import { MaterialModule } from '@haulynx/modules';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BlueSelectModule } from '../blue-select/blue-select.module';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [SearchRadiusFieldMobileComponent],
  imports: [
    BlueSelectModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    BlueInputModule,
    FlexLayoutModule,
  ],
  exports: [SearchRadiusFieldMobileComponent],
})
export class SearchRadiusFieldMobileModule {}
