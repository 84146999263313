import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GoogleAnalyticsService, LoadsServiceService, NotificationsService } from '@haulynx/services';
import { CommonEntities, LoadActiveModel, LoadDetailsModel, LoadEntityService } from '@haulynx/store';
import {
  Action,
  BookStatus,
  buttonTypes,
  Category,
  CreateOptionsBts,
  CustomerFacilityDto,
  DistributionMethod,
  FeatureFlag,
  Label,
  LoadIdentifierType,
  LoadsServiceLoad,
  LoadsServiceRestrictionTypes,
  User,
} from '@haulynx/types';
import { aliveWhile, joinNoteTexts, listToArray } from '@haulynx/utils';
import moment from 'moment-timezone';
import { Observable, of, from } from 'rxjs';
import { map, takeUntil, switchMap, catchError } from 'rxjs/operators';
import { LoadActiveAssignComponent } from '../load/components/load-active-assign/load-active-assign.component';
import { LoadShareLocationWarningComponent } from '../load/components/load-share-location-warning/load-share-location-warning.component';
import { LoadViewService } from '@haulynx/services';
import { ShareLoadDialog } from '../dialogs/load-share/load-share.component';
import { ShareLoadCompleteDialog } from '../dialogs/load-share-complete/load-share-complete.component';

@Component({
  selector: 'app-load-main-info',
  templateUrl: './load-main-info.component.html',
  styleUrls: ['./load-main-info.component.scss'],
})
export class LoadMainInfoComponent implements OnChanges, OnInit, OnDestroy {
  @Input() load: LoadsServiceLoad = null;
  @Input() user: User = null;
  @Input() sectionTitle: string | null = null;
  @Input() showDetailsButton = false;
  @Input() disableDetailsButton = false;
  @Input() showNotesButton = false;
  @Input() isLoading = false;
  @Input() hideFields: { [key: string]: boolean } = {
    revenue: false,
    bookStatus: false,
    modifyPrice: false,
  };
  @Output() update = new EventEmitter<string>();
  @Output() onDetails = new EventEmitter<LoadsServiceLoad>();
  @Output() showSpecialNote = new EventEmitter<number>();
  @Output() showFacilityInfo = new EventEmitter<{ customerNumber: string; index: number }>();
  @Output() xpmOrder = new EventEmitter<LoadsServiceLoad>();
  public loadIdentifierType = LoadIdentifierType;
  public loadsServiceRestrictionTypes = LoadsServiceRestrictionTypes;
  public isEditable = false;
  public isCarrier = false;
  public isBroker = false;
  public isLoadBooked = false;
  public loadServiceLoad$: Observable<LoadsServiceLoad>;

  url = '';
  bookStatus = BookStatus;
  form: FormGroup;
  maxBuy$: Observable<number>;
  maxBuyLoading$: Observable<boolean>;
  maxBuyFeatureFlag: FeatureFlag = FeatureFlag.MAX_BUY;
  private drivers: User[];
  private alive = aliveWhile();
  createOptionsBts: CreateOptionsBts = new CreateOptionsBts();

  constructor(
    public loadViewService: LoadViewService,
    private commonEntities: CommonEntities,
    private dialog: MatDialog,
    public loadActiveModel: LoadActiveModel,
    private googleAnalyticsService: GoogleAnalyticsService,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    public loadDetailsModel: LoadDetailsModel,
    public loadEntityService: LoadEntityService,
    private loadsServiceService: LoadsServiceService,
    private notificationsService: NotificationsService
  ) {}

  ngOnInit(): void {
    if (this.load?.id) {
      this.loadEntityService.getLoadByIdManager.dispatch(this.load.id);
      this.loadServiceLoad$ = this.loadEntityService.getLoadByIdManager.getEntityById(this.load.id);
      this.maxBuy$ = this.loadServiceLoad$.pipe(map((load) => load?.paymentDetails?.maxBuy));
      this.maxBuyLoading$ = this.loadEntityService.getLoadByIdManager.isLoadingEntities$.pipe(
        map((item) => item[this.load.id])
      );
    }

    this.form = this.fb.group({
      internalNotes: [this.load?.providerDetails?.internalNotes],
      externalNotes: [this.load?.providerDetails?.externalNotes],
      customerNotes: [this.getBillToNotes(this.load)],
    });

    this.form.valueChanges.pipe(takeUntil(this.alive)).subscribe((load) => {
      this.load.providerDetails.externalNotes = load.externalNotes;
      this.load.providerDetails.internalNotes = load.internalNotes;
    });

    if (this.load) {
      this.url = `${location.origin}/loads/${this.load.id}`;
    }
    this.getDriversToSetEditStatus();
  }

  ngOnChanges(): void {
    if (this.load) {
      this.isLoadBooked = this.load?.bookStatus === 'booked' && this.user?.carrier?.dot === this.load?.carrier?.dot;
      this.url = `${location.origin}/loads/${this.load.id}`;
    }

    this.isCarrier = !!this.user?.carrier?.id;
    this.isBroker = !!this.user?.broker?.id;

    this.userCanEditLoad();
  }

  public copyLinkSuccess(): void {
    this.googleAnalyticsService.eventEmitter(Category.REFERRAL, Action.CLICK, Label.COPY);
    this.snackBar.open('Successfully copied referral url', 'Okay', { duration: 3000 });
  }

  onUpdate(loadId: string, event: MouseEvent): void {
    event.stopPropagation();
    this.update.emit(loadId);
  }

  details(load: LoadsServiceLoad): void {
    this.onDetails.emit(load);
  }

  onShowSpecialNote(loadLocationIndex: number): void {
    this.showSpecialNote.emit(loadLocationIndex);
  }

  shareLocation(load: LoadsServiceLoad): void {
    const truckId = load?.truck?.id;
    const lastUpdated = load?.truck?.lastUpdated;
    const timezone = this.user?.prefs?.timeZone || 'America/Phoenix';
    const diff = moment().diff(moment(lastUpdated).tz(timezone, true), 'hours');

    if (!truckId) {
      this.assignLoad(load);
    } else if (!lastUpdated || diff > 2) {
      this.openWarningDialog(load);
    } else {
      this.openShareLocationDialog(load);
    }
  }

  openShareLocationDialog(load: LoadsServiceLoad): void {
    this.dialog
      .open(ShareLoadDialog, {
        data: { loadId: load.id },
      })
      .afterClosed()
      .pipe(
        switchMap((result) => {
          if (result) {
            const { loadId, email, phone } = result;
            return from(this.loadsServiceService.shareLoadLocation(loadId, email, phone));
          } else {
            return of();
          }
        }),
        catchError((error) => {
          this.notificationsService.error(error, 'Error');
          return of(error);
        }),
        takeUntil(this.alive)
      )
      .subscribe((result) => {
        const message = result.message ? result.message : null;
        if (message === 'User successfully notified') this.onShareLocationSuccess();
      });
  }

  onShareLocationSuccess(): void {
    this.dialog.open(ShareLoadCompleteDialog, {});
  }

  openWarningDialog(load: LoadsServiceLoad): void {
    this.dialog
      .open(LoadShareLocationWarningComponent)
      .afterClosed()
      .pipe(takeUntil(this.alive))
      .subscribe((result) => {
        if (result) {
          this.openShareLocationDialog(load);
        }
      });
  }

  assignLoad(load: LoadsServiceLoad): void {
    const loadId = this.load.id;

    this.dialog
      .open(LoadActiveAssignComponent, {
        data: {
          load: this.load,
          showWarning: true,
        },
      })
      .afterClosed()
      .pipe(takeUntil(this.alive))
      .subscribe((result) => {
        const { action = null, data = null } = result || {};

        if (action === this.createOptionsBts.NEW_DRIVER) {
          this.loadActiveModel.goTo('/dashboard/fleet');
        } else if (action === this.createOptionsBts.NEW_TRAILER) {
          this.loadActiveModel.goTo('/dashboard/fleet');
        } else if (action === this.createOptionsBts.NEW_TRUCK) {
          this.loadActiveModel.goTo('/dashboard/users');
        } else if (action === buttonTypes.SKIP_AND_SEND_LOAD_INFO.action) {
          this.openShareLocationDialog(load);
        } else if (action && data) {
          const updatedLoad: LoadsServiceLoad = {
            ...this.load,
            ...data,
            distributionMechanism: DistributionMethod.MANUAL,
          };
          this.loadActiveModel.assigned({
            loadId,
            load: updatedLoad,
          });
          this.openShareLocationDialog(updatedLoad);
        }
      });
  }

  editLoad(load: LoadsServiceLoad): void {
    this.loadActiveModel.goTo(`/dashboard/loads/view/${load.id}`);
  }

  onShowFacilityInfo({ customerNumber, index }: CustomerFacilityDto): void {
    this.showFacilityInfo.emit({ customerNumber, index });
  }

  onXpmOrder(load: LoadsServiceLoad): void {
    this.xpmOrder.emit(load);
  }

  ngOnDestroy(): void {
    if (this.loadDetailsModel.setLoad && this.load) {
      this.loadDetailsModel.setLoad({ key: this.load.id, state: this.load });
    }
    this.alive.destroy();
  }

  private getBillToNotes(load: LoadsServiceLoad): string {
    return joinNoteTexts(load?.billTo?.notes);
  }

  private getDriversToSetEditStatus(): void {
    this.commonEntities.graphQlDrivers.search({
      key: 'load-detail-carrier-driver',
      query: { carrierId: this.getCarrierId() },
    });
    this.commonEntities.graphQlDrivers.entities$.pipe(takeUntil(this.alive)).subscribe((drivers) => {
      this.drivers = listToArray(drivers);
    });
  }

  private getCarrierId(): string {
    return this.load?.carrier?.id || this.user?.carrier?.id;
  }
  private userCanEditLoad(): void {
    this.isEditable = this.loadViewService.userCanEditLoad(this.drivers || [], this.user, this.load);
  }
}
