<div *ngIf="loadEntityService.getCarrierHistoryManager.isSearching$ | async; else card">
  <mat-spinner [diameter]="36"></mat-spinner>
</div>

<ng-template #card>
  <div class="history-container">
    <haulynx-pagination-table
      [data]="data$ | async"
      [tableConfig]="toolbarConfigOptions"
      [paginationData]="paginationData$ | async"
      [templateCellRef]="templateRef"
      [loading]="loadEntityService.getCarrierHistoryManager.isSearching$ | async"
      (page)="onPage($event)"
    >
      <ng-template #templateRef let-row="row">
        <div class="history-card">
          <div (click)="onViewLoad(row.id)" class="title_link">
            {{
              'Load #' + (row | loadAlternateId: loadIdentifierType.TMW_NUMBER) ||
                'Load #' + (row | loadAlternateId: loadIdentifierType.ORDER_NUMBER)
            }}
          </div>
          <div class="truck-route">
            <mat-icon class="icon-display">arrow_right_alt</mat-icon>
            <div class="truck-route__text">
              <div class="main-text">{{ row.locations[0].address | address: 1 }}</div>
            </div>
            <div class="truck-route__text">
              <div class="main-text">{{ row.locations[1].address | address: 1 }}</div>
            </div>
          </div>
          <div class="row-display">
            <div class="sub-text">
              {{
                row.locations | nth: 0 | get: 'appointmentStart':'N/A' | dateTime: row.locations[0].timezone:'MM/dd/yy'
              }}
              {{ ' - ' }}
              {{
                row.locations | nth: 0 | get: 'appointmentEnd':'N/A' | dateTime: row.locations[0].timezone:'MM/dd/yy'
              }}
            </div>
            <div class="sub-text">{{ row?.providerDetails?.equipmentType | fromCamelCase | titlecase }}</div>
          </div>
          <div class="row-display">
            <div class="main-text">
              {{ row?.broker?.usxId || 'n/a' }}
            </div>
            <div class="sub-text" *ngIf="row?.paymentDetails?.price">${{ row.paymentDetails.price }}</div>
          </div>
        </div>
      </ng-template>
    </haulynx-pagination-table>
  </div>
</ng-template>
