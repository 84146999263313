import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService, WindowRef } from '@haulynx/services';
import { AppModel, CarrierDashboardModel, LoadEntityService, PreferredLanesEntityService } from '@haulynx/store';
import {
  BookStatus,
  CapacityObject,
  CapacityObjectInput,
  DayOfWeek,
  EquipmentType,
  EquipmentTypeOption,
  equipmentTypes,
  LaneForm,
  LaneRecommendation,
  LaneType,
  LoadIdentifierType,
  LoadServiceSearchParameters,
  LoadsServiceLoad,
  PageAndSort,
  RadiusOption,
  radiusOptions,
  ToolbarLanesConfigOptions,
  User,
} from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { add } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { exclusionFilters } from 'libs/types/src/lib/loads-service/exclusion-filters';
import { camelCase, nth, remove } from 'lodash';
import { TableItemSizeDirective } from 'ng-table-virtual-scroll';
import { combineLatest, Observable, of } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'haulynx-carrier-toolbar-lanes',
  templateUrl: './carrier-toolbar-lanes.component.html',
  styleUrls: ['./carrier-toolbar-lanes.component.scss'],
})
export class CarrierToolbarLanesComponent implements OnInit {
  @Input() dot: string;
  alive = aliveWhile();
  public loadIdentifierType = LoadIdentifierType;
  data$: Observable<CapacityObject[]>;
  paginationData$: Observable<{
    currentPage: number;
    total: number;
    totalPages: number;
  }>;
  toolbarConfigOptions = ToolbarLanesConfigOptions;
  radiusList: RadiusOption[] = radiusOptions;
  equipmentList: EquipmentTypeOption[] = equipmentTypes;
  currentFirstRecord = 0;
  laneTypes = LaneType;
  showLaneForm: boolean = false;
  countryRestrictions = ['us', 'ca'];
  defaultPickupDays: string[];
  truckDefaultRadius: number;
  truckDefaultEquipment: string;
  user: User;
  selectedLane: CapacityObject;
  loadingMapLaneIds: { [key: string]: boolean } = {};
  deletedLane: string;
  filterButton = true;
  query: {
    queryHash: string;
    payload: Partial<LoadServiceSearchParameters>;
    pageAndSort: Partial<PageAndSort>;
  };

  constructor(
    public loadEntityService: LoadEntityService,
    private windowRef: WindowRef,
    public preferredLanesEntityService: PreferredLanesEntityService,
    private userService: UserService,
    public router: Router
  ) {
    this.userService.user.pipe(takeUntil(this.alive)).subscribe((data) => {
      this.user = data;
    });
    this.truckDefaultRadius = nth(this.radiusList, 3).id;
    this.truckDefaultEquipment = nth(this.equipmentList, 1).text;

    this.data$ = this.preferredLanesEntityService.getCarrierLanesManager.searchResults$.pipe(
      map((lanes) => {
        const items = [...lanes];
        return items.sort((a, b) => +a.truckPostedCreatedAt - +b.truckPostedCreatedAt);
      })
    );

    this.paginationData$ = combineLatest([
      this.preferredLanesEntityService.getCarrierLanesManager.searchPaginationResults$,
      this.preferredLanesEntityService.getCarrierLanesManager.searchResults$,
    ]).pipe(
      takeUntil(this.alive),
      map(([pagination, lanes]) => {
        return {
          currentPage: 1,
          total: lanes.length,
          totalPages: pagination.total ? 1 : 0,
        };
      })
    );

    this.loadEntityService.getUSXLoadsManager.searchQuery$.pipe(takeUntil(this.alive)).subscribe((data) => {
      this.query = data;
    });
  }

  ngOnInit(): void {
    if (this.router.url.includes('overview')) {
      this.filterButton = false;
    }

    this.preferredLanesEntityService.getCarrierLanesManager.dispatch({
      query: this.dot,
      pageAndSort: {
        limit: this.toolbarConfigOptions.pageAmount,
        page: 1,
      },
    });

    this.preferredLanesEntityService.createLaneManager.onSuccess$.pipe(takeUntil(this.alive)).subscribe(() => {
      this.preferredLanesEntityService.getCarrierLanesManager.dispatch({
        query: this.dot,
        pageAndSort: {
          limit: this.toolbarConfigOptions.pageAmount,
          page: 1,
        },
      });
    });

    this.preferredLanesEntityService.deleteLaneManager.onSuccess$.pipe(takeUntil(this.alive)).subscribe(() => {
      delete this.loadingMapLaneIds[this.deletedLane];
      this.preferredLanesEntityService.getCarrierLanesManager.dispatch({
        query: this.dot,
        pageAndSort: {
          limit: this.toolbarConfigOptions.pageAmount,
          page: 1,
        },
      });
    });
  }

  viewPostLane(formIsOpen): void {
    if (!formIsOpen) this.showLaneForm = true;
  }

  hidePostLane(): void {
    this.showLaneForm = false;
  }

  onPage(event: { pageUp: boolean; currentPage: number; limit: number }): void {
    this.loadEntityService.getCarrierLoadsManager.goToPage(
      event.currentPage + (event.pageUp ? 1 : -1),
      this.toolbarConfigOptions.pageAmount
    );
  }

  saveLane(lane: LaneForm): void {
    if (!lane?.equipmentType) lane.equipmentType = EquipmentType.DRY_VAN;
    if (lane) {
      const form: CapacityObjectInput = {
        userId: this.user.id,
        type: LaneType.BROKER,
        carrierDot: this.dot,
        brokerId: this.user.usxId,
        active: true,
        origin: {
          point: {
            lat: lane.location?.lat.toString(),
            lon: lane.location?.lon.toString(),
          },
          radius: lane.locationRadius?.toString(),
          state: null,
          region: null,
          availability: {
            dayOfWeek: lane.pickupDays.map((days: { key: DayOfWeek }) => days.key),
            timestamp: null,
            date: null,
          },
          locationName: lane.location?.address,
        },
        destination: {
          point: {
            lat: lane.preferredLocation?.lat.toString(),
            lon: lane.preferredLocation?.lon.toString(),
          },
          radius: lane.preferredLocationRadius?.toString(),
          state: null,
          region: null,
          availability: {
            dayOfWeek: [],
            timestamp: null,
            date: null,
          },
          locationName: lane.preferredLocation?.address,
        },
        equipmentType: lane.equipmentType,
        cost: { min: lane.price?.toString(), max: null },
        minimumStopCharge: null,
        rpm: null,
        count: '1',
        expireTimestamp: null,
        allowStops: false,
        hazmat: false,
        showLoadNotifications: false,
      };
      this.preferredLanesEntityService.createLaneManager.dispatch('', form);
    }
  }

  deleteLane(lane): void {
    if (lane) {
      this.deletedLane = lane.id;
      this.loadingMapLaneIds = { [lane.id]: true };
      this.preferredLanesEntityService.deleteLaneManager.dispatch('CARRIER_DASHBOARD_PAGE', { laneIds: [lane.id] });
    }
  }

  moreCarrierInfo(): void {
    const id = this.dot;
    const url = `/dashboard/carriers/view/${id}`;
    this.windowRef.getNativeWindow().open(url, '_blank');
  }

  filterByLane(lane: CapacityObject): void {
    if (lane) {
      let payload: Partial<LoadServiceSearchParameters> = {
        destinationLat: [lane.destination.point.lat],
        destinationLon: [lane.destination.point.lon],
        destinationRadiusMiles: [+lane.destination.radius],
        destinationLocation: [lane.destination.locationName],
        bookStatus: [BookStatus.BOOKABLE, BookStatus.VIEWABLE],
        equipment: [camelCase(lane.equipmentType)],
        originLat: [lane.origin.point.lat],
        originLon: [lane.origin.point.lon],
        originRadiusMiles: [+lane.origin.radius],
        originLocation: [lane.origin.locationName],
      };
      // if in test loads show available test loads
      if (this.query.payload.showTestLoads) {
        payload = {
          showTestLoads: true,
          billTo: ['haulynx test'],
          ...payload,
        };
      } else {
        // all available loads
        payload = {
          notBillTo: exclusionFilters.map((val) => val.key),
          ...payload,
        };
      }
      this.loadEntityService.getUSXLoadsManager.dispatch({ query: payload, pageAndSort: { limit: 25 } });
    }
  }

  clickedLane(truck: CapacityObject): void {
    this.selectedLane = truck && this.selectedLane?.id !== truck?.id ? truck : null;
  }

  onViewLoad(event): void {
    const url = `/loads/${event}/overview/info`;
    this.windowRef.getNativeWindow().open(url, '_blank');
  }
}
