import { Injectable } from '@angular/core';
import { AnalyticsService } from '@haulynx/services';
import { ANALYTICS_EVENT } from '@haulynx/types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DispatchAction } from '../shared/store/helpers/action';
import { AppSettingsActions, AppSettingsActionTypes } from './app-settings.actions';

@Injectable()
export class AppSettingsAnalyticsEffects {
  logout = createEffect(() =>
    this.actions$.pipe(
      ofType(AppSettingsActionTypes.LOG_OUT),
      map((action: DispatchAction) => action.payload),
      switchMap(() => {
        this.analyticService.logEvent(ANALYTICS_EVENT.SETTING_LOGOUT);
        return of(AppSettingsActions.logOutSuccess());
      })
    )
  );

  constructor(private actions$: Actions, private analyticService: AnalyticsService) {}
}
