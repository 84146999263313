<div class="driver-form-info">
  <app-spinner
    [active]="isLoading || isLoadingCreateEntity"
    [fullCover]="true"
    [valignTop]="isLoadingCreateEntity"
  ></app-spinner>
  <div class="driver-form-info__form">
    <form class="form" [formGroup]="form">
      <mat-form-field
        *ngIf="featureFlags[trackingFeatureFlag]"
        class="app-form-field"
        appearance="fill"
        floatLabel="always"
      >
        <mat-label> Tracking </mat-label>
        <app-drop-down
          placeholder="Enter Tracking Type"
          formControlName="trackingType"
          displayLabel="label"
          key="value"
          [data]="trackingOptions"
          (selected)="selectAssignment(entityTypes.TRACKING_TYPES)"
        >
        </app-drop-down>
      </mat-form-field>

      <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
        <mat-label> Driver </mat-label>
        <app-drop-down
          #driverList
          placeholder="Enter Driver Name"
          formControlName="driver"
          displayLabel="name"
          [data]="drivers$ | async"
          [isLoading]="isLoadingDrivers"
          (selected)="selectAssignment(entityTypes.DRIVERS)"
        >
        </app-drop-down>
      </mat-form-field>
      <mat-error *ngIf="form.get('driver').errors">*</mat-error>

      <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
        <mat-label> Phone Number </mat-label>
        <input
          class="disabled-input"
          matInput
          mask="000-000-0000"
          placeholder="Enter Phone Number"
          formControlName="phone"
          readonly
        />
      </mat-form-field>
      <mat-error *ngIf="form.get('phone').errors">*</mat-error>

      <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
        <mat-label> Truck </mat-label>
        <app-drop-down
          #truckList
          placeholder="Enter Unit #"
          formControlName="truck"
          displayLabel="unitId"
          [data]="trucks$ | async"
          [isLoading]="isLoadingTrucks"
          (selected)="selectAssignment(entityTypes.TRUCKS)"
        >
        </app-drop-down>
      </mat-form-field>
      <mat-error *ngIf="form.get('truck').errors">*</mat-error>

      <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
        <mat-label> Trailer </mat-label>
        <app-drop-down
          #trailerList
          placeholder="Enter Trailer"
          displayLabel="trailerNumber"
          formControlName="trailer"
          [data]="trailers$ | async"
          [isLoading]="isLoadingTrailers"
          (selected)="selectAssignment(entityTypes.TRAILERS)"
        >
        </app-drop-down>
      </mat-form-field>

      <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
        <mat-label> Equipment </mat-label>
        <input matInput placeholder="Enter Equipment" formControlName="equipment" />
      </mat-form-field>
    </form>
    <button
      class="hlx-button hlx-button--active"
      data-test="load-driver-form-submit"
      (click)="save($event, form.getRawValue())"
      [disabled]="!bookedLoad"
    >
      Save
    </button>
  </div>
</div>
