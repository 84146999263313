import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FleetEffects } from './fleet.effects';
import { fleetReducer } from './fleet.reducer';

@NgModule({
  declarations: [],
  imports: [CommonModule, StoreModule.forFeature('fleet', fleetReducer), EffectsModule.forFeature([FleetEffects])],
})
export class FleetStoreModule {}
