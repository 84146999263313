<div class="details-info-wrapper">
  <app-lf-details-info-pane
    [load]="loadFeed$ | async"
    [carrierDot]="carrierDot"
    [carrierId]="carrierId"
    [isCarrier]="isCarrier"
    (bookSuccess)="bookSuccess($event)"
  >
  </app-lf-details-info-pane>

  <app-lf-details-map [load]="loadFeed$ | async" [loadFeedRoute]="loadFeedRoute$ | async"></app-lf-details-map>
</div>
