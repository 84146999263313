import { DomSanitizer } from '@angular/platform-browser';
import { Component, Input, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageComponent implements OnChanges {
  @Input() data = '';
  @Input() height = '32';
  @Input() width = '32';
  @Input() label = '';
  constructor(private sanitizer: DomSanitizer) {}

  getImageSrc(data) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/jpg;base64,${data}`);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { data } = changes;
    if (data) {
      this.getImageSrc(data);
    }
  }
}
