import { Injectable, NgZone } from '@angular/core';
import { Observable, OperatorFunction, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

/**
 * Custom OperatorFunction that makes sure that all lifecycle hooks of an Observable
 * are running in the NgZone.
 */
export function runInZone<T>(zone: NgZone): OperatorFunction<T, T> {
  return (source) => {
    return new Observable((observer) => {
      const onNext = (value: T) => zone.run(() => observer.next(value));
      const onError = (e: any) => zone.run(() => observer.error(e));
      const onComplete = () => zone.run(() => observer.complete());
      return source.subscribe(onNext, onError, onComplete);
    });
  };
}

export interface BroadcastMessage {
  type: string;
  payload: any;
}

@Injectable({ providedIn: 'root' })
export class BroadcastService {
  private onMessage = new Subject<BroadcastMessage>();

  constructor(private ngZone: NgZone) {
    window.addEventListener('storage', (event: StorageEvent) => {
      if (event.key !== 'haulynx-broadcast-channel' || event.newValue == null) return;

      this.onMessage.next(JSON.parse(event.newValue));
    });
  }

  /**
   * This publishes messages to other tabs
   */
  publish(message: BroadcastMessage): void {
    localStorage.setItem('haulynx-broadcast-channel', JSON.stringify(message));
    localStorage.removeItem('haulynx-broadcast-channel');
  }

  /**
   * This publishes messages locally in app
   * @param message
   */
  publishLocally(message: BroadcastMessage): void {
    this.onMessage.next(message);
  }

  messagesOfType(type: string): Observable<BroadcastMessage> {
    return this.onMessage.pipe(
      // It is important that we are running in the NgZone. This will make sure that Angular component changes
      // are immediately visible in the browser when they are updated after receiving messages.
      runInZone(this.ngZone),
      filter((message) => message.type === type)
    );
  }
}
