import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ColumnVisibilityTypes, IColumns2, ISearchFilter, SaveSearchViewDto, SidebarItem } from '@haulynx/types';
import { cloneDeep, orderBy, uniq } from 'lodash';

@Component({
  selector: 'app-column-visibility',
  templateUrl: './column-visibility.component.html',
  styleUrls: ['./column-visibility.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColumnVisibilityComponent implements OnChanges {
  @ViewChild('wrapper', { static: false }) wrapper: ElementRef;

  @Input() columns: IColumns2[] = [];
  @Input() selectedFilters = [];
  @Input() isSearchView = false;
  @Input() selectedSidebarItem: SidebarItem;

  @Output() saveSearchView = new EventEmitter<SaveSearchViewDto>();
  @Output() columnsChange = new EventEmitter<IColumns2[]>();
  @Output() columnsRest = new EventEmitter();

  columnsToShow: IColumns2[] = [];
  columnsToHide: IColumns2[] = [];
  columnVisibilityTypes = ColumnVisibilityTypes;
  isContainerOpen = false;
  indexOfFocus = 0;
  isMouseFocus = false;
  filterSuggestions: string[] = [];
  isHostListenerActive = false;
  isManuallyReordered = false;

  constructor() {}

  @HostListener('document:click', ['$event.target']) onClick(targetElement: ElementRef): void {
    const clickedInside = this.wrapper.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.closeContainer();
    }
  }

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent): void {
    if (event.key === 'ArrowDown' && this.indexOfFocus < this.columns.length - 1) {
      this.indexOfFocus += 1;
      this.isMouseFocus = false;
    } else if (event.key === 'ArrowUp' && this.indexOfFocus > 0) {
      this.indexOfFocus -= 1;
      this.isMouseFocus = false;
    } else if (event.key === 'Enter' && this.isHostListenerActive) {
      this.onSaveSearchView();
    } else if (event.code === 'Space' && this.isHostListenerActive) {
      if (this.indexOfFocus < this.columnsToShow.length) {
        this.onToggleSelection(null, this.indexOfFocus, ColumnVisibilityTypes.VISIBLE);
      } else {
        const indexOfFocus = this.indexOfFocus - this.columnsToShow.length;
        this.onToggleSelection(null, indexOfFocus, ColumnVisibilityTypes.HIDDEN);
      }
    }
  }

  toggleVisibilityContainer(): void {
    this.isContainerOpen = !this.isContainerOpen;
    this.isHostListenerActive = this.isContainerOpen;
  }

  onCancelButton(): void {
    this.onColumnsReset();
    this.closeContainer();
  }

  onSaveSearchView(): void {
    this.saveSearchView.emit({ columnsToShow: this.columnsToShow, columnsToHide: this.columnsToHide });
    this.closeContainer();
  }

  closeContainer(): void {
    this.isContainerOpen = false;
    this.isHostListenerActive = false;
  }

  onToggleSelection(event: MouseEvent, index: number, list: string): void {
    event?.stopImmediatePropagation();
    const row = this[list][index];

    if (row?.isPinned) {
      return;
    }

    row.isVisible = !row.isVisible;
    this.onModelChange(index, list);
  }

  onDrop(event: CdkDragDrop<string[]>): void {
    const { previousIndex, currentIndex } = event;
    const minDropIndex = this.columnsToShow.reduce(
      (acc: number, column: IColumns2) => (column?.isPinned ? acc + 1 : acc),
      0
    );

    if (currentIndex >= minDropIndex) {
      moveItemInArray(this.columnsToShow, previousIndex, currentIndex);
      this.updateColumnsOrder();
    }

    this.columnsChange.emit([...this.columnsToShow, ...this.columnsToHide]);
  }

  onColumnsReset(): void {
    this.columnsRest.emit();
  }

  onPopulateColumns(columns: IColumns2[]): void {
    columns = columns ?? this.columns;

    if (!this.isSearchView) {
      columns = columns.reduce((acc: IColumns2[], column: IColumns2) => {
        const filterMatch = column?.filters?.some((filterName: string) => this.filterSuggestions.includes(filterName));
        const isVisible = this.isManuallyReordered ? column?.isVisible : true;

        return filterMatch
          ? [...acc, { ...column, hasFilterSuggestion: true, isVisible }]
          : [...acc, { ...column, hasFilterSuggestion: false }];
      }, []);
    }

    let columnsToShow = columns.filter((column: IColumns2) => !!column?.isVisible);
    const columnsToHide = columns.filter((column: IColumns2) => !column?.isVisible);

    columnsToShow = this.sortColumnsToShow(columnsToShow);
    this.columnsToShow = cloneDeep(columnsToShow);
    this.columnsToHide = cloneDeep(columnsToHide);

    if (!this.isManuallyReordered) {
      this.isManuallyReordered = true;
      this.columnsChange.emit([...this.columnsToShow, ...this.columnsToHide]);
    }
  }

  sortColumnsToShow(columnsToShow: IColumns2[]): IColumns2[] {
    return orderBy(columnsToShow, ['order'], ['asc']);
  }

  updateColumnsOrder(): void {
    this.columnsToShow = this.columnsToShow.map((column: IColumns2, index: number) => {
      return { ...column, order: index + 1 };
    });

    this.columnsToHide = this.columnsToHide.map((column: IColumns2, index: number) => {
      return { ...column, order: index + 1 };
    });
  }

  onModelChange(index: number, list: string): void {
    const row = this[list][index];

    if (row?.isPinned) {
      return;
    }

    if (row?.isVisible) {
      this.columnsToHide.splice(index, 1);
      this.columnsToShow.push(row);
    } else {
      this.columnsToShow.splice(index, 1);
      this.columnsToHide.push(row);
    }

    this.updateColumnsOrder();
    this.isManuallyReordered = true;
    this.columnsChange.emit([...this.columnsToShow, ...this.columnsToHide]);
  }

  onColumnsChange(columns: IColumns2[]): void {
    this.columnsChange.emit(columns);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { columns, selectedFilters } = changes;

    if (selectedFilters) {
      const filterSuggestions = selectedFilters?.currentValue?.map((filter: ISearchFilter) => filter?.name);
      this.filterSuggestions = uniq(filterSuggestions);
      this.isManuallyReordered = false;
      this.onPopulateColumns(this.columns);
    }

    if (columns) {
      this.columns = columns.currentValue.filter((column: IColumns2) => !!column?.label);
      this.onPopulateColumns(this.columns);
    }
  }
}
