import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TrackingStatus } from '../../load';
import { DropdownDisplay, ISearchFilter, TextFilterType } from '../search-filter';
import { ISearchFilterType } from '../search-filter.types';

export class TrackingStatusFilter implements ISearchFilter {
  name = 'Tracking Status';
  type: ISearchFilterType = ISearchFilterType.MULTI_DROPDOWN;
  keys: TextFilterType;
  description = 'Filter loads by Tracking Status';

  constructor(defaultValues?: TextFilterType) {
    this.keys = new TextFilterType({
      value: '',
      optionsDataIndex: [
        {
          key: TrackingStatus.NOT_AVAILABLE,
          value: TrackingStatus.NOT_AVAILABLE,
        },
        {
          key: TrackingStatus.PRENDING_APPROVAL,
          value: TrackingStatus.PRENDING_APPROVAL,
        },
        {
          key: TrackingStatus.READY_TO_TRACK,
          value: TrackingStatus.READY_TO_TRACK,
        },
        {
          key: TrackingStatus.ACTIVE_TRACKING,
          value: TrackingStatus.ACTIVE_TRACKING,
        },
        {
          key: TrackingStatus.TRACKING_COMPLETED,
          value: TrackingStatus.TRACKING_COMPLETED,
        },
        {
          key: TrackingStatus.TRACKING_EXPIRED,
          value: TrackingStatus.TRACKING_EXPIRED,
        },
        {
          key: TrackingStatus.TRACKING_NOT_RESPONDING,
          value: TrackingStatus.TRACKING_NOT_RESPONDING,
        },
      ],
      textFormName: 'trackingStatusKey',
      ...defaultValues,
    });
  }
}
