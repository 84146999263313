<div class="tour">
  <app-spinner *ngIf="releaseService.isLoadingDetails$ | async" [active]="true" [fullCover]="true"></app-spinner>

  <button mat-flat-button (click)="onClose()" class="tour__close" data-test="close-whats-new">
    <mat-icon>close</mat-icon>
  </button>

  <ng-container *ngIf="release; else noRecords">
    <mat-dialog-content class="tour__content" data-test="whats-new-dialog">
      <div class="tour__header__wrapper">
        <h3 class="tour__header tour__header--release">
          {{ release.title }}
        </h3>

        <div [formGroup]="form" *ngIf="!preview" class="tour__archive-selector">
          <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
            <app-drop-down
              placeholder="Previous Releases"
              formControlName="currentRelease"
              [data]="archivedReleases"
              displayLabel="title"
              key="id"
            ></app-drop-down>
          </mat-form-field>
        </div>
      </div>

      <drag-scroll
        #slider
        [scrollbar-hidden]="true"
        [drag-scroll-y-disabled]="true"
        (reachesLeftBound)="reachesLeftBound($event)"
        (reachesRightBound)="reachesRightBound($event)"
        class="tour__slider"
      >
        <div class="tour__repeat" *ngFor="let tour of release.pages" drag-scroll-item>
          <div class="tour__img-container" *ngIf="tour.media.type === 'image'">
            <img
              #tourImage
              class="tour-image"
              [src]="tour.media.data"
              [alt]="tour.title"
              (error)="tourImage.style.display = 'none'"
            />
          </div>

          <iframe
            *ngIf="tour.media.type === 'video'"
            width="900"
            height="560"
            [src]="tour.media.data | safeUrl"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          >
          </iframe>

          <h4 class="tour__title">
            {{ tour.title }}
          </h4>
          <p class="tour__description">
            {{ tour.description }}
          </p>
        </div>
      </drag-scroll>
    </mat-dialog-content>

    <mat-dialog-actions class="tour__actions">
      <div>
        <span
          *ngFor="let tour of release.pages; let i = index"
          class="tour__dot"
          (click)="moveTo(i)"
          [ngClass]="{ 'tour__dot--active': i === slider.currIndex }"
        >
        </span>
      </div>
      <span class="tour__spacer"></span>
      <button *ngIf="slider.currIndex > 0" mat-flat-button (click)="moveLeft()" class="tour__button--back">Back</button>
      <button
        *ngIf="slider.currIndex < release.pages.length - 1"
        mat-flat-button
        (click)="moveRight()"
        class="affirmative"
      >
        Next
      </button>
      <button
        *ngIf="slider.currIndex === release.pages.length - 1"
        mat-flat-button
        class="affirmative"
        (click)="onClose()"
      >
        Done
      </button>
    </mat-dialog-actions>
  </ng-container>
</div>

<ng-template #noRecords>
  <div
    *ngIf="!(releaseService.isLoadingDetails$ | async) && !(releaseService.isLoadingList$ | async)"
    class="tour__no-records"
    mat-dialog-close
    data-test="no-release-notes"
  >
    No release note found
  </div>
</ng-template>
