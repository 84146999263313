import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { LoadEntityService, MissionEntityService } from '@haulynx/store';
import { LoadIdentifierType, MissionValidation, MissionValidationReasons, OrderValidation } from '@haulynx/types';
import { aliveWhile, getLoadsServiceLoadAlternateId } from '@haulynx/utils';
import { camelCase, startCase } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { takeUntil, skip } from 'rxjs/operators';

@Component({
  selector: 'haulynx-create-mission',
  templateUrl: './create-mission.component.html',
  styleUrls: ['./create-mission.component.scss'],
})
export class CreateMissionComponent implements OnInit, OnDestroy {
  pageNum$ = new BehaviorSubject<number>(0);
  mainForm: FormGroup;

  missionValidationReasons = MissionValidationReasons;
  private alive = aliveWhile();

  constructor(
    private dialogRef: MatDialogRef<CreateMissionComponent>,
    private fb: FormBuilder,
    public loadEntityService: LoadEntityService,
    public missionEntityService: MissionEntityService
  ) {
    this.mainForm = this.fb.group({
      numberMissions: [1, [Validators.required, Validators.max(25), Validators.min(1)]],
      missions: this.fb.array([this.createMission()]),
      invalidMissions: [0, []],
      errors: [null, []],
    });
  }

  get missions(): FormArray {
    return <FormArray>this.mainForm.get('missions');
  }

  get missionValidations(): FormArray {
    return <FormArray>this.mainForm.get('missionValidations');
  }

  get invalidMissions(): FormArray {
    return <FormArray>this.mainForm.get('invalidMissions');
  }

  get numberMissions(): FormArray {
    return <FormArray>this.mainForm?.get('numberMissions');
  }

  get orderNumbers(): Array<string> {
    return this.missions.value.reduce((prev, curr) => {
      prev.push(curr.headHaul ?? '');
      prev.push(curr.backHaul ?? '');
      return prev;
    }, []);
  }

  addMission(index: number) {
    this.missions.push(this.createMission(index));
  }

  deleteMission(index: number) {
    this.missions.removeAt(index);

    if (this.pageNum$.value === 2) {
      this.handleValidate();
    }
  }

  createMission(index: number = 0): FormGroup {
    return this.fb.group({
      headHaul: [
        null,
        [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(1), this.checkDuplicate(index)],
      ],
      headHaulValidation: [true],
      origin: [null],
      backHaul: [
        null,
        [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(1), this.checkDuplicate(index)],
      ],
      backHaulValidation: [true],
      destination: [null],
    });
  }

  checkMissionsArray() {
    this.missions.controls.forEach((x) => {
      (x as FormGroup).get('headHaul').updateValueAndValidity();
      (x as FormGroup).get('backHaul').updateValueAndValidity();
    });
  }

  checkDuplicate(index: number = 0) {
    return (c: AbstractControl) => {
      const formArray = c.parent && c.parent.parent ? (c.parent.parent as FormArray) : null;
      if (formArray && formArray.controls.length) {
        for (let i = 0; i < formArray.controls.length; i++) {
          if (i === index) {
            if (c.value && formArray.at(i).get('headHaul').value === formArray.at(i).get('backHaul').value) {
              return {
                duplicate: true,
              };
            }
          } else if (
            c.value &&
            (formArray.at(i).get('headHaul').value == c.value || formArray.at(i).get('backHaul').value == c.value)
          )
            return {
              duplicate: true,
            };
        }
      }
      return null;
    };
  }

  ngOnInit(): void {
    this.mainForm?.controls['numberMissions']?.valueChanges.pipe(takeUntil(this.alive)).subscribe((val) => {
      while (this.missions.length < val) {
        this.addMission(this.missions.length);
      }
      while (this.missions.length > val) {
        this.missions.removeAt(this.missions.length - 1);
      }
    });

    this.loadEntityService.getUSXLoadsOffBoardManager.searchResults$.pipe(takeUntil(this.alive)).subscribe((loads) => {
      const missions = this.missions.value;

      loads.forEach((load) => {
        const orderNumber = getLoadsServiceLoadAlternateId(load, LoadIdentifierType.ORDER_NUMBER, true);

        missions.forEach((mission, index) => {
          if (missions[index].headHaul === orderNumber) {
            missions[index].origin = startCase(camelCase(load?.locations[0]?.city)) + ', ' + load?.locations[0]?.state;
          }
          if (missions[index].backHaul === orderNumber) {
            missions[index].destination =
              startCase(camelCase(load?.locations[0]?.city)) + ', ' + load?.locations[0]?.state;
          }
        });
      });

      this.missions.setValue(missions);
    });

    this.missionEntityService.validateMissionsManager.data$
      .pipe(skip(1), takeUntil(this.alive))
      .subscribe((validations) => {
        let invalidMissions = 0;

        const missions = this.missions.value;
        validations.missions.forEach((mission, i) => {
          missions[i].headHaulValidation = mission.orders[0];
          missions[i].backHaulValidation = mission.orders[1];

          if (!mission.orders[0].valid || !mission.orders[1].valid) invalidMissions++;
        });

        this.mainForm.patchValue({
          invalidMissions: invalidMissions,
          missions: missions,
          errors: validations.errors,
        });
      });

    this.missionEntityService.createMissionsManager.onSuccess$.pipe(takeUntil(this.alive)).subscribe((create) => {
      this.dialogRef.close({ success: true });
    });
  }

  createMissions() {
    const missionCreateInputs = this.missions.value.map((mission) => ({
      orderNumbers: [mission.headHaul, mission.backHaul],
    }));

    this.missionEntityService.createMissionsManager.dispatch(missionCreateInputs[0].orderNumbers[0], {
      missionBatchInput: missionCreateInputs,
    });
  }

  close() {
    this.dialogRef.close();
  }

  nextPage() {
    if (!this.numberMissions.valid) return;
    if (this.pageNum$.value === 1 && !this.missions.valid) {
      this.missions.markAllAsTouched();
      return;
    }
    if (this.pageNum$.value === 1) {
      this.handleValidate();
    }

    this.pageNum$.next(this.pageNum$.value + 1);
  }

  prevPage() {
    this.pageNum$.next(this.pageNum$.value - 1);
  }

  handleValidate() {
    this.clearValidations();

    const missionCreateInputs = this.missions.value.map((mission) => ({
      orderNumbers: [mission.headHaul, mission.backHaul],
    }));

    this.missionEntityService.validateMissionsManager.dispatch({
      missionBatchInput: missionCreateInputs,
    });

    this.loadEntityService.getUSXLoadsOffBoardManager.dispatch({
      query: {
        alternateIdType: LoadIdentifierType.ORDER_NUMBER,
        alternateIdValue: this.orderNumbers,
        showTestLoads: true,
      },
    });
  }

  clearValidations() {
    let missions = this.missions.value;

    missions = missions.map((mission) => ({
      headHaul: mission.headHaul,
      headHaulValidation: true,
      origin: null,
      backHaul: mission.backHaul,
      backHaulValidation: true,
      destination: null,
    }));

    this.mainForm.patchValue({
      missions: missions,
      errors: null,
    });
  }

  dataPasted(data: Array<string>, type: number, index: number) {
    let j = 0;
    this.missions.controls.forEach((element: FormGroup, i) => {
      const haulType = type ? 'backHaul' : 'headHaul';
      if (i >= index && j < data.length) {
        element.controls[haulType].setValue(data[j++]);
      }
    });
  }

  ngOnDestroy() {
    this.alive.destroy();
  }
}
