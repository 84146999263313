import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserEntityService } from '@haulynx/store';
import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NewCarrierLoadSearchRedirectGuard implements CanActivate {
  constructor(private userEntityService: UserEntityService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return combineLatest([
      this.userEntityService.user$.pipe(take(1)),
      this.userEntityService.featureFlags$.pipe(take(1)),
    ]).pipe(
      take(1),
      map(([user, features]) => {
        if (!features.CARRIER_LOAD_SEARCH) return true;

        // If someone tries to go to the old active loads page and has
        if (state?.url?.includes('loads/search/active') && !state?.url?.includes('loads/search/active/details')) {
          if (!features.CLS_ACTIVE_LOADS) {
            this.router.navigate(['loads/active']);
            return false;
          }

          this.router.navigate(['loads/search?list=active']);
          return false;
        }

        if (state?.url.includes('loads/search') && !user.isCompanyAdmin) {
          // Drivers should only see active loads section
          this.router.navigate(['loads/active']);
          return false;
        }

        // Users who have the new carrier load search should be directed to it
        if (!state?.url.includes('loads/search') && user.broker == null) {
          this.router.navigate(['loads/search']);
          return false;
        }

        return true;
      })
    );
  }
}
