import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NotificationState } from './notification.state';

export const getNotificationState = createFeatureSelector<NotificationState>('notification');
export const getNotificationSelector = createSelector(getNotificationState, (state) => state.notifications);
export const isLoadingNotificationSelector = createSelector(getNotificationState, (state) => state.isLoading);
export const showNotificationSelector = createSelector(getNotificationState, (state) => state.showNotification);
export const totalNotificationSelector = createSelector(getNotificationState, (state) => {
  const notificationEventTypes = state.notificationEventTypes || [];
  const notifications = state.notifications || [];
  const activeNotifications = notifications.filter(
    (notification) => notification.active && notificationEventTypes.includes(notification.eventType)
  );

  return activeNotifications.length;
});
export const notificationEventTypesSelector = createSelector(
  getNotificationState,
  (state) => state.notificationEventTypes
);
export const notificationQuerySelectors = createSelector(getNotificationState, (state) => state.query);
