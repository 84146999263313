import gql from 'graphql-tag';

/**
 * Only add what is necessary for the carrier load search table.
 * More information will be retrieved for the detail view with a later call
 */
export const MINIMAL_LOAD_DETAILS_FRAGMENT = gql`
  fragment minimalLoadDetails on LoadsServiceLoad {
    id
    carrierCanBookNow
    bookStatus
    loadStatus
    lastUpdatedAt
    locations {
      summaryAddress
      timezone
      appointmentSet
      appointmentStart
      appointmentEnd
      locationType
      geometry {
        coordinates
      }
    }
    paymentDetails {
      price
      ratePerMile
      distanceMiles
    }
    providerDetails {
      regionalManagerEmail
      regionalManagerPhone
      alternateIds {
        identifierType
        identifierValue
      }
      equipmentType
      weightLbs
    }
    restrictions {
      type
      value
    }
    deadHeadOriginMiles
    deadHeadDestinationMiles
    drivers {
      name
    }
    trailers {
      trailerNumber
    }
    truck {
      unitId
    }
  }
`;
