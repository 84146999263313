<div class="row">
  <div class="col-lg-12">
    <mat-card>
      <h4>Register Carrier</h4>
      <mat-card-content>
        <form [formGroup]="carrierForm" (ngSubmit)="onSubmit()" novalidate>
          <div class="row">
            <mat-form-field class="col-lg-12">
              <input matInput placeholder="Carrier Name" formControlName="name" />
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field class="col-lg-4">
              <input matInput placeholder="Street" formControlName="addressStreet" />
            </mat-form-field>

            <mat-form-field class="col-lg-4">
              <input matInput placeholder="City" formControlName="addressCity" />
            </mat-form-field>

            <mat-select placeholder="State" class="col-lg-4" formControlName="addressState">
              <mat-option>None</mat-option>
              <mat-option *ngFor="let state of states" [value]="state.code">{{ state.name }}</mat-option>
            </mat-select>
          </div>

          <div class="row">
            <mat-form-field class="col-lg-3">
              <input matInput placeholder="DOT" formControlName="dot" />
            </mat-form-field>
            <mat-form-field class="col-lg-3">
              <input matInput placeholder="Phone Number" formControlName="phone" />
            </mat-form-field>
            <mat-form-field class="col-lg-6">
              <input matInput placeholder="email" formControlName="email" />
            </mat-form-field>
          </div>

          <div class="row">
            <mat-form-field class="col-lg-3">
              <input matInput placeholder="Min Temperature" formControlName="temperature" />
            </mat-form-field>
            <mat-form-field class="col-lg-3">
              <input matInput placeholder="Hazmat Capable" formControlName="hazmatCapable" />
            </mat-form-field>
            <mat-form-field class="col-lg-3">
              <input matInput placeholder="Insurance Amount" formControlName="insurance" />
            </mat-form-field>
            <mat-form-field class="col-lg-3">
              <input matInput placeholder="Max Weight" formControlName="weight" />
            </mat-form-field>
          </div>
          <div class="row">
            <mat-radio-group class="col-lg-2">
              <mat-radio-button *ngFor="let rule of hosRules" [value]="rule.code">{{ rule.text }}</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="row">
            <mat-list>
              <mat-list-item class="col-lg-2" *ngFor="let type of equipmentTypes">
                <mat-checkbox class="mat-secondary" ng-model="type.selected">{{ type.text }}</mat-checkbox>
              </mat-list-item>
            </mat-list>
          </div>

          <mat-card-actions>
            <button mat-button type="submit">Submit</button>
          </mat-card-actions>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>
