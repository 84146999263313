import { HttpStatus, LoadEntityQueryParam } from '@haulynx/types';
import { List } from 'immutable';
import { AppSettingsActionTypes } from '../../main-store/app-settings.actions';
import { DispatchAction } from '../../shared/store/helpers/action';
import { LoadEntitiesActionTypes } from './load-entities.actions';
import { defaultStateKey, initialLoadEntities, LoadEntitiesState } from './load-entities.state';

export function loadEntitiesReducer(
  state: LoadEntitiesState = initialLoadEntities,
  action: DispatchAction
): LoadEntitiesState {
  switch (action.type) {
    case AppSettingsActionTypes.LOG_OUT:
      return initialLoadEntities;

    case LoadEntitiesActionTypes.SET_STATE_KEY: {
      const { key } = action.payload as { key: string };

      return {
        ...state,
        stateKey: key || defaultStateKey,
      };
    }

    case LoadEntitiesActionTypes.SEARCH: {
      const { stateKey } = state;
      const { query = null, limit = null, page = null } = action.payload as LoadEntityQueryParam;
      let newState: Partial<LoadEntitiesState> = {
        loading: state.loading.set(stateKey, true),
        httpStatus: state.httpStatus.set(stateKey, HttpStatus.PENDING),
      };

      if (query) {
        newState = {
          ...newState,
          query: state.query.set(stateKey, query),
          limit: state.limit.set(stateKey, 450),
          page: state.page.set(stateKey, 1),
          total: state.total.set(stateKey, 0),
        };
      }

      if (limit) {
        newState = { ...newState, limit: state.limit.set(stateKey, limit) };
      }

      if (page) {
        newState = { ...newState, page: state.page.set(stateKey, page) };
      }

      return {
        ...state,
        ...newState,
      };
    }

    case LoadEntitiesActionTypes.SEARCH_SUCCESS: {
      const { stateKey } = state;
      const { entities = [], total = 0 } = action.payload as { entities: []; total: number };

      return {
        ...state,
        entities: state.entities.set(stateKey, List(entities)),
        total: state.total.set(stateKey, total),
        loading: state.loading.set(stateKey, false),
        httpStatus: state.httpStatus.set(stateKey, HttpStatus.SUCCESS),
      };
    }

    case LoadEntitiesActionTypes.SEARCH_ERROR: {
      const { stateKey } = state;
      const { error } = action.payload as { error: { [key: string]: any } };

      return {
        ...state,
        entities: state.entities.set(stateKey, List()),
        loading: state.loading.set(stateKey, false),
        httpStatus: state.httpStatus.set(stateKey, HttpStatus.FAILED),
      };
    }

    default:
      return state;
  }
}
