import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { VirtualTableSortModule } from './virtual-table-sort/virtual-table-sort.module';
import { SkeletonLoaderModule } from '../skeleton-loader/skeleton-loader.module';
import { VirtualTableComponent } from './virtual-table.component';

@NgModule({
  declarations: [VirtualTableComponent],
  imports: [
    CommonModule,
    ScrollingModule,
    TableVirtualScrollModule,
    MaterialModule,
    FlexLayoutModule,
    PipesModule,
    VirtualTableSortModule,
    SkeletonLoaderModule,
    MaterialModule,
    FlexLayoutModule,
    PipesModule,
  ],
  exports: [VirtualTableComponent],
})
export class VirtualTableModule {}
