import { DocumentTypes } from '../load-overview';

// https://www.iana.org/assignments/media-types/media-types.xhtml
export enum AcceptFileExtensionTypes {
  WILDCARD_EXT = '*',
  IMAGE_WILDCARD_EXT = 'image/*',
  IMAGE_PNG_EXT = 'image/png',
  IMAGE_JPG_EXT = 'image/jpg',
  IMAGE_JPEG_EXT = 'image/jpeg',
  APP_PDF_EXT = 'application/pdf',
}

export interface LoadDocumentUploadDialogDropdownType {
  key: DocumentTypes | string;
  tags?: string[];
  value: string;
}

export interface LoadDocumentUploadDialogOptions {
  dropdownTypes: LoadDocumentUploadDialogDropdownType[];
  acceptedFileExtensions: string[];
  isMultiUpload: boolean;
  carrierId: string;
  loadId: string;
  token: string;
}
