import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoadFeedModel } from '@haulynx/store';
import { Bid, LoadsServiceLoad } from '@haulynx/types';

@Component({
  selector: 'app-bid-history-dialog',
  templateUrl: './bid-history-dialog.component.html',
  styleUrls: ['./bid-history-dialog.component.scss'],
})
export class BidHistoryDialogComponent implements OnInit {
  isLoadingBidHistory: boolean;
  bid: Bid;
  load: LoadsServiceLoad;
  userTimeZone: string;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: { bid: Bid; load: LoadsServiceLoad; userTimeZone: string },
    @Optional() private dialogRef: MatDialogRef<BidHistoryDialogComponent>,
    public loadFeedModel: LoadFeedModel
  ) {
    const { bid, load, userTimeZone } = data;

    this.bid = bid;
    this.load = load;
    this.userTimeZone = userTimeZone;
  }

  ngOnInit(): void {
    const { id } = this.bid;

    this.loadFeedModel.getBidHistory(id);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
