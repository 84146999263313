import gql from 'graphql-tag';

export const TrucksByCarrierIdQuery = gql(`
  query GetTrucksByCarrierId($carrierId: ID!) {
    getTrucksByCarrierId(carrierId: $carrierId) {
      total
      result {
        id
        carrierId
        userId
        unitId
        vin
        make
        model
        year
        mileage {
          calculationType
          lastOdometerSync
          manualOdometer
          offset
          substitute
        }
        truckState
        vehicleMiles
        mileageSinceDTC
        needsMileageUpdate
        mileageType        
        statusOrigin
        engineHours
        hasDeviceConnected
        imei
        type
        lat
        lon
        locationName
        lastUpdated
        user {
          name
        }
        coDrivers {
          name
        }
        carrier {
          id
          name
          dot
          mcNumber
          phone
          email
          timeZone
        }
        lastLocation {
          gpsLat
          gpsLon
          approximateAddress
        }
      }
    }
  }
`);

export const getTrucksWithUnidentifiedLogsByCarrierId = gql(`
  query getTrucksWithUnidentifiedLogsByCarrierId($id: ID!) {
    getTrucksWithUnidentifiedLogsByCarrierId(id: $id) {
      id
      carrierId
      userId
      unitId
      vin
      make
      model
      year
      mileage {
        calculationType
        lastOdometerSync
        manualOdometer
        offset
        substitute
        value
      }
      truckState
      vehicleMiles
      mileageSinceDTC
      needsMileageUpdate
      mileageType      
      statusOrigin
      engineHours
      hasDeviceConnected
      imei
      type
      lat
      lon
      locationName
      lastUpdated
      carrier {
        id
        name
        dot
        mcNumber
        phone
        email
        timeZone
      }
      lastLocation {
        gpsLat
        gpsLon
      }
    }
  }
`);
