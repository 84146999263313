import { Injectable } from '@angular/core';
import { CarrierManagement, SearchParams } from '@haulynx/types';
import { listToArray } from '@haulynx/utils';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppState } from '../../main-store/app.reducers';
import { CarrierManagementActions } from './carrier-management-state.actions';
import {
  developingSelectors,
  isLoadingDevelopingSelectors,
  isLoadingProspectsSelectors,
  isLoadingTopSelectors,
  prospectsSelectors,
  topSelectors,
} from './carrier-management-state.selectors';

@Injectable()
export class CarrierManagementModel {
  topIsLoading$: Observable<boolean> = this.store.select(isLoadingTopSelectors);
  topEntities$: Observable<CarrierManagement[]> = this.store
    .select(topSelectors)
    .pipe(map((entities) => listToArray(entities)));

  developingIsLoading$: Observable<boolean> = this.store.select(isLoadingDevelopingSelectors);
  developingEntities$: Observable<CarrierManagement[]> = this.store
    .select(developingSelectors)
    .pipe(map((entities) => listToArray(entities)));

  prospectsIsLoading$: Observable<boolean> = this.store.select(isLoadingProspectsSelectors);
  prospectsEntities$: Observable<CarrierManagement[]> = this.store
    .select(prospectsSelectors)
    .pipe(map((entities) => listToArray(entities)));

  constructor(private store: Store<AppState>) {}

  searchTop(params: SearchParams): void {
    this.store.dispatch(CarrierManagementActions.searchTop(params));
  }

  searchDeveloping(params: SearchParams): void {
    this.store.dispatch(CarrierManagementActions.searchDeveloping(params));
  }

  searchProspects(params: SearchParams): void {
    this.store.dispatch(CarrierManagementActions.searchProspects(params));
  }

  setContacted(params: CarrierManagement): void {
    this.store.dispatch(CarrierManagementActions.setContacted(params));
  }

  delete(id: string): void {
    this.store.dispatch(CarrierManagementActions.delete(id));
  }
}
