<data-table
  [data]="lanes"
  [loading]="loading"
  [pagination]="pagination"
  [pageOptions]="[100]"
  [displayedColumns]="lanesColumns"
  [checkBoxSelection]="checkBoxSelection"
  [checkBoxSelectAll]="checkBoxSelectAll"
  [lazyLoad]="true"
  [stickyHeader]="true"
  (onSelect)="select($event)"
  (onPagination)="paginationChange($event)"
  [templateCellRef]="laneTemplateRef"
>
  <ng-template #laneTemplateRef let-row="row" let-column="column"> </ng-template>
</data-table>
