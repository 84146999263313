import { createSelector, MemoizedSelector } from '@ngrx/store';

import { IFormState } from './state';

export interface IFormSelectors {
  getStateKey: MemoizedSelector<Object, string>;
  getState: MemoizedSelector<Object, { [key: string]: any }>;
  getInitialState: MemoizedSelector<Object, { [key: string]: any }>;
  getLoading: MemoizedSelector<Object, boolean>;
  getMode: MemoizedSelector<Object, string>;
}

export function createFormSelectors<T>() {
  return {
    getSelectors(selectState: (state) => IFormState<T> | MemoizedSelector<any, IFormState<T>> | any): IFormSelectors {
      const key = (state) => {
        return state.stateKey.get(state.stateNameSpace);
      };

      const form = (state) => {
        const stateKey = state.stateKey.get(state.stateNameSpace);

        return state && state.state.get(stateKey);
      };
      const loading = (state) => {
        const stateKey = state.stateKey.get(state.stateNameSpace);

        return state && state.isLoading.get(stateKey);
      };
      const initialForm = (state) => {
        const stateKey = state.stateKey.get(state.stateNameSpace);

        return state && state.initialState.get(stateKey);
      };
      const mode = (state) => {
        const stateKey = state.stateKey.get(state.stateNameSpace);

        return state && state.mode.get(stateKey);
      };

      const getState = createSelector(selectState, form);

      const getLoading = createSelector(selectState, loading);

      const getInitialState = createSelector(selectState, initialForm);

      const getMode = createSelector(selectState, mode);

      const getStateKey = createSelector(selectState, key);

      return {
        getStateKey,
        getState,
        getLoading,
        getInitialState,
        getMode,
      };
    },
  };
}
