import { Pipe, PipeTransform } from '@angular/core';
import { LoadActionEvent, SubscriptionAction } from '@haulynx/types';

@Pipe({ name: 'isLoadLockedPipe', pure: false })
export class IsLoadLockedPipe implements PipeTransform {
  constructor() {}
  transform(loadLockState: LoadActionEvent): boolean {
    if (!loadLockState?.expiredAt) {
      return loadLockState && loadLockState.action !== SubscriptionAction.UNLOCK;
    }
    const currentTime = loadLockState?.expiredAt - new Date().valueOf();
    return loadLockState && loadLockState.action !== SubscriptionAction.UNLOCK && currentTime > 0;
  }
}
