import gql from 'graphql-tag';

export const CreateMilestoneComment = gql`
  mutation addMilestoneComment($loadId: StrictId!, $milestoneId: StrictId!, $commentInput: MilestoneCommentInput!) {
    addMilestoneComment(loadId: $loadId, milestoneId: $milestoneId, commentInput: $commentInput) {
      id
      milestones {
        authorId
        authorType
        comments {
          contact
          creatorId
          creatorType
          id
          text
          timestamp
          timezone
        }
        contact {
          email
          name
          phone
        }
        createdAt
        id
        lastUpdated
        loadLocationAddress
        location {
          latitude
          longitude
          timestamp
          timezone
        }
        locationId
        logs {
          editedBy
          editedByType
          id
          primaryEvent {
            latitude
            longitude
            timestamp
            timezone
          }
          secondaryEvent {
            latitude
            longitude
            timestamp
            timezone
          }
          subType
          timestamp
          status
        }
        type
      }
    }
  }
`;
