import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@haulynx/directives';
import { MaterialModule } from '@haulynx/modules';
import { ChipListModule } from '../../../chip-list/chip-list.module';
import { DatepickerModule } from '../../../datepicker/datepicker.module';
import { DropDownModule } from '../../../drop-down/drop-down.module';
import { GoogleAddressFieldModule } from '../../../google-address-field/google-address-field.module';
import { SelectorModule } from '../../../selector/selector.module';
import { LoadFeedSearchFormComponent } from './load-feed-search-form.component';
import { LoadFeedSearchFormVm } from './load-feed-search-form.vm';

@NgModule({
  declarations: [LoadFeedSearchFormComponent],
  exports: [LoadFeedSearchFormComponent],
  imports: [
    CommonModule,
    ChipListModule,
    DatepickerModule,
    DropDownModule,
    FlexLayoutModule,
    DirectivesModule,
    GoogleAddressFieldModule,
    MaterialModule,
    ReactiveFormsModule,
    SelectorModule,
  ],
  providers: [LoadFeedSearchFormVm],
})
export class LoadFeedSearchFormModule {}
