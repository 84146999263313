import gql from 'graphql-tag';

export const GetCustomerById = gql`
  query GetCustomerById($id: String!) {
    getCustomerById(id: $id) {
      id
      company
      number
      name
      address1
      address2
      city
      state
      zipCode
      latCust
      lonCust
      custTimezone
      custFormattedAddress
      telephone
      status
      notes {
        noteNumber
        noteSeq
        noteText
        actionType
      }
      receivingHours {
        dayOfWeek
        hourType
        openTime
        closeTime
        isFirstComeFirstServe
      }
      shippingHours {
        dayOfWeek
        hourType
        openTime
        closeTime
        isFirstComeFirstServe
      }
      customerAttributes {
        canPickUpEarly
        canDeliverEarly
        dropYard
        canBreakAtFacility
        hasFacilities
      }
    }
  }
`;
