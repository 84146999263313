import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

interface IMenuItemsComponent {
  label: string;
  action: string;
}

@Component({
  selector: 'app-menu',
  templateUrl: './app-menu.component.html',
  styleUrls: ['./app-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppMenuComponent {
  @Input() menuItems: IMenuItemsComponent[] | any[] = [];
  @Output() onClick = new EventEmitter<string>();
  @Input() menuIcon = 'more_vert';

  execute(action, event: MouseEvent) {
    event.stopPropagation();
    this.onClick.emit(action);
  }
}
