import { brokerTeams } from '../../generic';
import { ISearchFilter, TextFilterType } from '../search-filter';
import { ISearchFilterType } from '../search-filter.types';

export class BrokerTeamFilter implements ISearchFilter {
  name = 'Broker Team';
  type: ISearchFilterType = ISearchFilterType.MULTI_DROPDOWN;
  keys: TextFilterType;
  description = 'Filter loads by broker team';

  constructor(defaultValues?: TextFilterType) {
    this.keys = new TextFilterType({
      value: '',
      optionsDataIndex: brokerTeams,
      textFormName: 'brokerTeamId',
      ...defaultValues,
    });
  }
}
