import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CarrierToolbarService } from '@haulynx/services';
import { CarrierEntityService, CarriersModel, LoadEntityService } from '@haulynx/store';
import {
  Bid,
  Carrier,
  CarrierToolbarDropdownGroups,
  CarrierToolbarLocations,
  CarrierToolbarTab,
  FFState,
  KeyValuePair,
  LoadsServiceLoad,
  RecommendedCarriers,
  User,
} from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { find } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { keyBy } from 'lodash';

@Component({
  selector: 'haulynx-carrier-toolbar-card-group',
  templateUrl: './carrier-toolbar-card-group.component.html',
  styleUrls: ['./carrier-toolbar-card-group.component.scss'],
})
export class CarrierToolbarCardGroupComponent implements OnInit, OnDestroy, OnChanges {
  @Input() currentTab: CarrierToolbarTab;
  @Input() loads: LoadsServiceLoad[];
  @Input() featureFlags: FFState;
  @Input() selectedLoad: LoadsServiceLoad;
  @Input() user: User;
  @Input() search: string;
  @Input() loadOptions: { label: string; value: string; group: string }[] = [];
  @Input() showOptions? = true;
  @Input() latestBid: Bid;
  @Input() bidCreateLoading: boolean;
  @Input() limit = 10;
  @Output() openCarrierProfile = new EventEmitter<Carrier>();
  @Output() carrierAction = new EventEmitter<{
    event: KeyValuePair;
    carrier: Carrier;
    loadId: string;
    currentTab: CarrierToolbarTab;
  }>();
  @Output() submitBidForm = new EventEmitter();
  @Output() chosenCarrier = new EventEmitter();

  alive = aliveWhile();
  inputForm: FormGroup;
  searchForm: FormGroup;
  carriers$: Observable<Partial<Carrier>[]>;
  loading$: Observable<boolean>;
  selectedLoads$: Observable<LoadsServiceLoad[]>;
  currentCarrier: Carrier;
  carrierToolbarTab = CarrierToolbarTab;
  recommendations$ = new BehaviorSubject<RecommendedCarriers[]>([]);
  ownedCarriers$ = new BehaviorSubject<any[]>([]);
  currentPage = 1;
  enableLoadMore = false;
  recommendations: RecommendedCarriers[] = [];
  maxRecs = 100;
  carrierToolbarDropdownGroups = CarrierToolbarDropdownGroups;

  constructor(
    private fb: FormBuilder,
    public carriersModel: CarriersModel,
    private carrierToolbarService: CarrierToolbarService,
    public carrierEntityService: CarrierEntityService,
    public loadEntityService: LoadEntityService
  ) {
    this.inputForm = this.fb.group({
      currentLoad: [this.selectedLoad ? this.selectedLoad : null],
    });
    this.searchForm = this.fb.group({
      carrierName: [''],
      dot: [''],
      mcNumber: [''],
      search: [''],
    });

    this.carriers$ = this.carrierEntityService.searchCarriers.searchResults$;
    this.selectedLoads$ = this.carrierToolbarService.selectedLoads$;
  }

  ngOnInit(): void {
    this.getOwnedCarriers();
    this.inputForm
      .get('currentLoad')
      .valueChanges.pipe(takeUntil(this.alive))
      .subscribe((newLoadId) => {
        if (this.loads && newLoadId) {
          this.carrierToolbarService.selectLoad(newLoadId);
        }
      });

    this.loadEntityService.getOwnedCarriers.searchResults$.pipe(takeUntil(this.alive)).subscribe((carriers) => {
      this.ownedCarriers$.next(carriers);
    });

    this.loadEntityService.getCarrierRecommendations.searchResults$
      .pipe(takeUntil(this.alive))
      .subscribe((recommendations) => {
        if (this.currentPage > 1) {
          this.recommendations = [...this.recommendations, ...recommendations];
        } else {
          this.recommendations = [...recommendations];
        }
        this.recommendations$.next(this.recommendations);
      });

    this.loadEntityService.getCarrierRecommendations.searchPaginationResults$
      .pipe(takeUntil(this.alive))
      .subscribe((pagination) => {
        const count = this.currentPage * this.limit;
        if (pagination.total && pagination.total > count) {
          this.enableLoadMore = true;
        } else {
          this.enableLoadMore = false;
        }
      });

    this.searchForm
      .get('search')
      .valueChanges.pipe(takeUntil(this.alive), debounceTime(500))
      .subscribe((input) => {
        const formData = this.searchForm.getRawValue();
        this.carrierToolbarService.updateState({ data: { carrierSearchQuery: formData.search } });
        this.carrierEntityService.searchCarriers.dispatch({
          query: { ...formData },
          pageAndSort: {},
        });
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    const { selectedLoad, loadOptions, search, currentTab } = changes;

    if (search && search.currentValue) {
      this.searchForm.get('search').patchValue(search.currentValue);
      this.carrierEntityService.searchCarriers.dispatch({
        query: { search: search.currentValue },
        pageAndSort: {},
      });
    }

    if (loadOptions) {
      if (this.selectedLoad) {
        this.inputForm?.controls['currentLoad'].setValue(
          loadOptions.currentValue.find((load) => load.value === this.selectedLoad?.id)?.value
        );
      }

      if (!find(loadOptions.currentValue, (x) => x?.group === CarrierToolbarDropdownGroups.SELECTED_LOADS)) {
        this.inputForm?.controls['currentLoad'].setValue('');
      }
    }

    if (selectedLoad) {
      this.recommendations$.next([]);
      this.currentPage = 1;
      this.recommendations = [];
      this.getCarrierRecommendations(this.loads.find((load) => load.id === selectedLoad?.currentValue?.id));
    }
  }

  getCarrierRecommendations(load: LoadsServiceLoad) {
    if (load) {
      this.loadEntityService.getCarrierRecommendations.dispatch({
        query: {
          loadId: load?.id,
          useOpportunities: this.featureFlags?.CARRIER_TOOLBAR_OPPORTUNITIES ?? false,
          owner: this.user.usxId,
        },
        pageAndSort: {
          limit: this.limit,
          page: this.currentPage,
        },
      });
    }
  }

  onOpenCarrierProfile(event: Carrier) {
    this.openCarrierProfile.emit(event);
  }

  onCarrierAction(event: { event: KeyValuePair; carrier: Carrier; loadId: string; currentTab: CarrierToolbarTab }) {
    this.carrierAction.emit(event);
  }

  newCarrier(carrier: Carrier) {
    this.chosenCarrier.emit(carrier);
    this.currentCarrier = carrier;
  }

  submitBid(e: Event): void {
    this.submitBidForm.emit(e);
  }

  getOwnedCarriers(): void {
    this.loadEntityService.getOwnedCarriers.dispatch({
      query: { carrierOwner: this.user.usxId },
    });
  }

  loadMoreRecommendations(): void {
    if (this.selectedLoad) {
      this.currentPage++;
      this.loadEntityService.getCarrierRecommendations.dispatch({
        query: {
          loadId: this.selectedLoad?.id,
          useOpportunities: this.featureFlags?.CARRIER_TOOLBAR_OPPORTUNITIES ?? false,
        },
        pageAndSort: {
          limit: this.limit,
          page: this.currentPage,
        },
      });
    }
  }

  removeLoadFromSelected(loadId: string): void {
    // Remove loads from selected dropdown
    this.carrierToolbarService.pushLoads([], [this.loads.find((load) => load.id === loadId)]);
    // Let the service know the carrier search area has been interacted/invoked
    this.carrierToolbarService.setCarrierToolbarInvoked(CarrierToolbarLocations.CARRIER_SEARCH, loadId);
  }

  ngOnDestroy() {
    this.alive.destroy();
  }
}
