import { IAsyncEntityState } from '@haulynx/types';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { AppState } from '../../app.reducers';
import { AsyncEntitySearchFactory } from '../action-factories/async-entity-search-factory';

export const selectSearchQuery = <ActionPayloadType, EntitySchema, ErrorPayloadType, T>(
  factory: AsyncEntitySearchFactory<ActionPayloadType, EntitySchema, ErrorPayloadType>,
  featureSelector: MemoizedSelector<AppState, IAsyncEntityState<T>>
) => createSelector(featureSelector, (state) => state[factory.searchQueryKey]);

export const selectTotal = <ActionPayloadType, EntitySchema, ErrorPayloadType, T>(
  factory: AsyncEntitySearchFactory<ActionPayloadType, EntitySchema, ErrorPayloadType>,
  featureSelector: MemoizedSelector<AppState, IAsyncEntityState<T>>
) =>
  createSelector(
    featureSelector,
    (state) => state[factory.searchResultsKey][state[factory.searchQueryKey].queryHash]?.total
  );

export const selectIsSearching = <ActionPayloadType, EntitySchema, ErrorPayloadType, T>(
  factory: AsyncEntitySearchFactory<ActionPayloadType, EntitySchema, ErrorPayloadType>,
  featureSelector: MemoizedSelector<AppState, IAsyncEntityState<T>>
) => createSelector(featureSelector, (state) => state?.[factory.isSearchingKey]);

export const selectCurrentSearchResults = <ActionPayloadType, EntitySchema, ErrorPayloadType, T>(
  factory: AsyncEntitySearchFactory<ActionPayloadType, EntitySchema, ErrorPayloadType>,
  featureSelector: MemoizedSelector<AppState, IAsyncEntityState<T>>
) =>
  createSelector(featureSelector, (state) => {
    const currentPage: number = state?.[factory.searchQueryKey].pageAndSort?.page || 1;
    return state?.[factory.searchResultsKey][state?.[factory.searchQueryKey].queryHash]?.data[currentPage - 1] || [];
  });

export const selectIsSearchingAndIsEmpty = <ActionPayloadType, EntitySchema, ErrorPayloadType, T>(
  factory: AsyncEntitySearchFactory<ActionPayloadType, EntitySchema, ErrorPayloadType>,
  featureSelector: MemoizedSelector<AppState, IAsyncEntityState<T>>
) =>
  createSelector(featureSelector, (state) => {
    const currentPage: number = state?.[factory.searchQueryKey].pageAndSort?.page || 1;
    const length: number =
      state?.[factory.searchResultsKey][state?.[factory.searchQueryKey].queryHash]?.data[currentPage - 1]?.length;
    return state?.[factory.isSearchingKey] && !length;
  });

export const selectCurrentSearchPaginationResults = <ActionPayloadType, EntitySchema, ErrorPayloadType, T>(
  factory: AsyncEntitySearchFactory<ActionPayloadType, EntitySchema, ErrorPayloadType>,
  featureSelector: MemoizedSelector<AppState, IAsyncEntityState<T>>
) =>
  createSelector(featureSelector, (state) => {
    return {
      total: state[factory.searchResultsKey][state[factory.searchQueryKey].queryHash]?.total,
      currentPage: state[factory.searchResultsKey][state[factory.searchQueryKey].queryHash]?.currentPage,
      nextPage: state[factory.searchResultsKey][state[factory.searchQueryKey].queryHash]?.nextPage,
      previousPage: state[factory.searchResultsKey][state[factory.searchQueryKey].queryHash]?.previousPage,
      totalPages: state[factory.searchResultsKey][state[factory.searchQueryKey].queryHash]?.totalPages,
    };
  });
