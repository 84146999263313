import { Injectable } from '@angular/core';
import { User } from '@haulynx/types';
import 'firebase/database';
import { UserService } from '../shared-services/user.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  private user: User;
  constructor(private userService: UserService) {
    this.userService.user.subscribe((data) => {
      this.user = data;
    });
  }

  isCarrierAdmin(): boolean {
    return this.user && this.user.carrier && this.user.isCompanyAdmin;
  }

  isCarrierDriver(): boolean {
    return this.user && ((this.user.carrier && !this.user.isCompanyAdmin) || this.user.isDriver);
  }

  isBroker(): boolean {
    return this.user && !!this.user.broker;
  }

  isBrokerAdmin(): boolean {
    return this.user && this.user.broker && this.user.isCompanyAdmin;
  }

  isShipperAdmin(): boolean {
    return this.user && this.user.shipper && this.user.isCompanyAdmin;
  }

  isShipper(): boolean {
    return this.user && !!this.user.shipper;
  }

  isAdmin(): boolean {
    return this.user && !!this.user.isHaulynxAdmin;
  }

  isCompany(): boolean {
    return this.user && !!this.user.isCompanyAdmin;
  }

  isDriver(): boolean {
    return this.user && !!this.user.isDriver;
  }

  isOnlyADriver(): boolean {
    return (
      this.user &&
      !this.user.isCompanyAdmin &&
      !this.user.shipper &&
      !this.user.broker &&
      !this.user.isHaulynxAdmin &&
      !!this.user.isDriver
    );
  }

  isTelematicsOnly(): boolean {
    return (this.user && this.user.carrier && this.user.carrier['telematicsOnly']) || this.user['telematicsOnly'];
  }

  getId(): string {
    return this.user['_id'];
  }
}
