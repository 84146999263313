import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { LoadsServiceService, MapRoutesService } from '@haulynx/services';
import { SharedLocationModel } from '@haulynx/store';
import {
  LoadRouteSource,
  Location,
  SharedLocationConfig,
  UnauthenticatedLoadsServiceLoad,
  sharedLocationConfig,
  LoadsServiceLoadStatus,
} from '@haulynx/types';
import {
  aliveWhile,
  getCarrierName,
  getLoadsServiceLoadBillOfLading,
  getLoadsServiceLoadEquipmentType,
  getLoadsServiceLoadRoute,
  getUnitDisplayName,
  isLastLocationGpsValid,
} from '@haulynx/utils';
import { toString } from 'lodash';
import { timer } from 'rxjs';
import { takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { LoadCompleteDialog } from '../dialogs/load-complete/load-complete.component';
import { LoadWaitingForTruckDialog } from '../dialogs/load-waiting-for-truck/load-waiting-for-truck.component';

@Component({
  selector: 'app-shared-location-details',
  templateUrl: './shared-location-details.component.html',
  styleUrls: ['./shared-location-details.component.scss'],
})
export class SharedLocationDetailsComponent implements OnDestroy, OnInit {
  alive = aliveWhile();
  load: UnauthenticatedLoadsServiceLoad;
  lastLocationDate: string;
  equipmentType: string;
  billOfLading: string;
  unitDisplayName: string;
  carrierName: string;
  location: Location;
  lastLocationGps: [number, number];
  showLoadOnMap = false;
  loadFeedRoute: LoadRouteSource[] = [];
  private hash: string;
  sharedLocationConfig: SharedLocationConfig = sharedLocationConfig;

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    public sharedLocationModel: SharedLocationModel,
    private loadsServiceService: LoadsServiceService,
    private mapRoutesService: MapRoutesService
  ) {
    this.lastLocationGps = this.sharedLocationConfig.defaultGps;
  }

  ngOnInit(): void {
    const { hash } = this.route.snapshot.params;
    this.hash = hash;

    this.sharedLocationModel.sharedLoad$
      .pipe(takeUntil(this.alive))
      .subscribe((load: UnauthenticatedLoadsServiceLoad) => {
        if (load) {
          this.sharedLocationModel.getSharedLocation(this.hash);
        }
      });

    this.sharedLocationModel.sharedLocation$
      .pipe(takeUntil(this.alive), withLatestFrom(this.sharedLocationModel.sharedLoad$))
      .subscribe(([location, load]: [Location, UnauthenticatedLoadsServiceLoad]) => {
        this.location = location;
        if (load) {
          this.load = load;

          const lastLocationGps = this.getTruckLastLocation(location);
          const lastLocationGpsValid = isLastLocationGpsValid(lastLocationGps);

          if (lastLocationGpsValid) {
            this.lastLocationDate = location.date;
            this.equipmentType = getLoadsServiceLoadEquipmentType(load);
            this.billOfLading = getLoadsServiceLoadBillOfLading(load);
            this.unitDisplayName = getUnitDisplayName(location);
            this.carrierName = getCarrierName(load);

            this.lastLocationGps = lastLocationGps;
            this.showLoadOnMap = true;
          }

          this.loadStageMessage(load, location);

          const wayPoints = this.mapRoutesService.addTruckByLoadsServiceLoadLocationStatus(load, lastLocationGps);
          const coordinates = this.mapRoutesService.normalizeWayPoints(wayPoints);

          this.loadsServiceService.getSingleLoadRoute(coordinates).subscribe((routes) => {
            this.loadFeedRoute = getLoadsServiceLoadRoute({ [load.id]: routes }, load, true, lastLocationGps);
          });
        }
      });

    timer(10000, 300000)
      .pipe(
        takeUntil(this.alive),
        tap(() => {
          this.sharedLocationModel.getSharedLocation(this.hash);
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }

  private loadStageMessage(load: UnauthenticatedLoadsServiceLoad, location: Location) {
    this.dialog.closeAll();

    const loadStage = load.loadStatus;

    if (loadStage === LoadsServiceLoadStatus.DELIVERED) {
      setTimeout(() => this.dialog.open(LoadCompleteDialog));
    }

    if (loadStage !== LoadsServiceLoadStatus.DELIVERED && !location) {
      setTimeout(() => this.dialog.open(LoadWaitingForTruckDialog, {}));
    }
  }

  private getTruckLastLocation(location: Location) {
    if (location?.gpsLat && location?.gpsLon) {
      return this.mapRoutesService.getTruckLocation({ lat: toString(location.gpsLat), lon: toString(location.gpsLon) });
    }

    return null;
  }
}
