import gql from 'graphql-tag';

export const SearchTrailerHistorybyTrailerInfo = gql(`
  query searchTrailerHistoryByTrailerInfo($trailerCompany: String, $trailerNumber: String, $historyStartDateTime: String, $historyEndDateTime: String) {
    searchTrailerHistoryByTrailerInfo(trailerCompany: $trailerCompany, trailerNumber: $trailerNumber, historyStartDateTime: $historyStartDateTime, historyEndDateTime: $historyEndDateTime) {
      telemetryHistory {
        id
        trailerCompany
        trailerNumber
        latitude
        longitude
        telemetryPingDateTime
      }
      trailer {
        id
        trailerCompany
        trailerNumber
      }
    }
  }
`);
