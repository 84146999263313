import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Environment, LoadsServiceLoad, User } from '@haulynx/types';
import { get, head, last } from 'lodash';

interface IContact {
  name: string;
  phone: string;
  label: string;
  disable?: boolean;
}

@Component({
  selector: 'app-load-active-contact',
  templateUrl: './load-active-contact.component.html',
  styleUrls: ['./load-active-contact.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadActiveContactComponent implements OnChanges {
  @Input() title = null;
  @Input() load = null;
  @Input() user: User;

  contacts: IContact[] = [];

  constructor(private environment: Environment) {}

  checkContacts(load: LoadsServiceLoad): IContact[] {
    const contacts: IContact[] = [];
    const carrier = get(load, 'carrier', null);
    const driver = load?.drivers[0] ? load.drivers[0] : undefined;
    const broker = get(load, 'broker', null);
    const userIsCarrier = !!get(this.user, 'carrier', false);
    const loadLocation = get(load, 'locations', []);
    // broker loads used to be "created" by Philip Pina
    const creator: string = get(load, 'provider.name');
    const isBrokerCreated = creator === this.environment.usx.creatorId || creator === '4cGRHH54aZaihFCrsAcaAuqv3pi1';

    const pickUpLocation = head(loadLocation);
    const deliveryLocation = last(loadLocation);

    if (driver) {
      contacts.push({
        label: 'Driver',
        name: driver.name,
        phone: driver.phone,
        disable: false,
      });
    }

    if (broker) {
      contacts.push({
        label: 'Broker',
        name: broker.name,
        phone: broker.phone,
        disable: false,
      });
    }

    if ((userIsCarrier && !isBrokerCreated) || !userIsCarrier) {
      contacts.push({
        label: 'Shipper',
        name: pickUpLocation?.customer?.name ?? '',
        phone: pickUpLocation?.customer?.phone ?? '',
        disable: false,
      });

      contacts.push({
        label: 'Consignee',
        name: deliveryLocation?.customer?.name ?? '',
        phone: deliveryLocation?.customer?.phone ?? '',
        disable: true,
      });

      contacts.push({
        label: 'Bill-To',
        name: get(head(load.locations), 'billOfLading', ''),
        phone: '',
        disable: true,
      });
    }

    if (!userIsCarrier && carrier) {
      contacts.push({
        label: 'Carrier',
        name: carrier.name,
        phone: carrier.phone,
        disable: false,
      });
    }

    return contacts;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { load, user } = changes;

    if (load || user) {
      this.contacts = this.checkContacts(get(load, 'currentValue', this.load));
    }
  }
}
