import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'load-waiting-for-truck-dialog',
  templateUrl: `./load-waiting-for-truck.component.html`,
  styleUrls: ['./load-waiting-for-truck.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadWaitingForTruckDialog {
  constructor(public dialogRef: MatDialogRef<LoadWaitingForTruckDialog>) {}
}
