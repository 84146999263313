import { Component, ElementRef, HostListener, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DividerSliderLimits } from './divider-slider.types';

@Component({
  selector: 'app-divider-slider',
  templateUrl: './divider-slider.component.html',
  styleUrls: ['./divider-slider.component.scss'],
})
export class DividerSliderComponent implements OnInit {
  @ViewChild('sliderMechanism', { static: false }) sliderMechanism: ElementRef;
  @ViewChild('wrapper', { static: false }) wrapper: ElementRef;

  @Input() leftTopTemplate: TemplateRef<ElementRef>;
  @Input() rightBottomTemplate: TemplateRef<ElementRef>;
  @Input() isVertical: boolean = false;
  @Input() limits: DividerSliderLimits;

  isMouseDown = false;

  leftWidth: string = null;
  topHeight: string = null;

  @HostListener('document:mousedown', ['$event']) mouseDown(event: MouseEvent) {
    if (event.target === this.sliderMechanism.nativeElement) {
      this.isMouseDown = true;
    }
  }

  @HostListener('document:mouseup', ['$event']) mouseup(event: MouseEvent) {
    this.isMouseDown = false;
  }

  @HostListener('document:mousemove', ['$event']) mousemove(event: MouseEvent) {
    if (!this.isMouseDown) {
      return false;
    }

    if (this.isVertical) {
      const offsetTop = this.wrapper.nativeElement.getBoundingClientRect();
      const pointerRelativeOffset = event.clientY - offsetTop.top;
      this.topHeight = pointerRelativeOffset - 1 + 'px';
    } else {
      const offsetLeft = this.wrapper.nativeElement.getBoundingClientRect();
      const pointerRelativeOffset = event.clientX - offsetLeft.left;
      this.leftWidth = pointerRelativeOffset - 1 + 'px';
    }
  }

  ngOnInit(): void {
    if (this.limits.left) {
      const width = this.limits.left.min !== 'inherit' ? this.limits.left.min : this.limits.left.max;
      this.leftWidth = width;
    }
    if (this.limits.top) {
      const height = this.limits.top.min !== 'inherit' ? this.limits.top.min : this.limits.top.max;
      this.topHeight = height;
    }
  }
}
