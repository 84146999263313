export enum CarrierBidStatus {
  Accepted,
  BidHeld,
  BidLost,
  BidRejected,
  BidSent,
  CounterOfferReceived,
  CounterOfferSent,
  Rejected,
  Unknown,
}
