import { Bid, BidSourceType, BidStatusType, CarrierBidStatus, KeyValuePair } from '@haulynx/types';

export const carrierBidStatus = (bid: Bid): CarrierBidStatus => {
  if (bid?.status === BidStatusType.ACCEPTED) return CarrierBidStatus.Accepted;
  if (bid?.status === BidStatusType.AUTO_REJECTED) return CarrierBidStatus.BidLost;
  if (bid?.status === BidStatusType.PRICE_REJECTED || bid?.status === BidStatusType.NO_CAPACITY)
    return CarrierBidStatus.BidRejected;
  if (bid?.status === BidStatusType.PRICE_HOLD) return CarrierBidStatus.BidHeld;

  if (bid?.sourceType === BidSourceType.BROKER) return CarrierBidStatus.CounterOfferReceived;
  if (bid?.activeCounterOffer) return CarrierBidStatus.CounterOfferSent;

  return CarrierBidStatus.BidSent;
};

export const bidActionMenu: KeyValuePair[] = [
  {
    key: "Set to 'No Capacity'",
    value: 0,
  },
];
