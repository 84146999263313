import 'moment-duration-format';
import moment from 'moment';
import { Pipe, PipeTransform } from '@angular/core';

/**
 * @deprecated Use `time-diff.pipe.ts` instead
 */
@Pipe({
  name: 'timeDuration',
})
export class TimeDurationPipe implements PipeTransform {
  /**
   * Returns a human-readable duration in hours, minutes. For example:
   * 5 hours, 32 minutes
   * 1 hour, 0 minutes
   * 43 hours, 1 minute
   * @param minute
   */
  transform(minute: number, displaySeconds = true): string {
    const milliseconds = minute * 1000 * 60;
    const duration = moment.duration(milliseconds);
    const hours: string = duration.format('h');
    const minutes: string = duration.format('m');
    const seconds: string = duration.format('s');

    if (Number(hours) > 0) {
      const minsLessHrs: number = Number(minutes) % 60;
      const secsLessMin: number = Number(minsLessHrs) % 60;
      let hoursText = `${hours} hour${this.pluralize(hours)}, ${minsLessHrs} minute${this.pluralize(minsLessHrs)}`;
      if (displaySeconds) hoursText += `, ${secsLessMin} second${this.pluralize(secsLessMin)}`;
      return hoursText;
    } else if (Number(minutes) > 0) {
      const secsLessMin: number = Number(seconds) % 60;
      let minutesText = `${minutes} minute${this.pluralize(minutes)}`;
      if (displaySeconds) minutesText += `, ${secsLessMin} second${this.pluralize(secsLessMin)}`;
      return minutesText;
    } else {
      return `${seconds} second${this.pluralize(seconds)}`;
    }
  }

  private pluralize(val: string | number): '' | 's' {
    return val === 1 ? '' : 's';
  }
}
