import { Injectable } from '@angular/core';
import {
  AcceptBidMutation,
  AcceptCounterBidAndBookLoadMutation,
  CreateBidMutation,
  CreateCounterOffer,
  DeleteBidMutation,
  GetBidHistory,
  GetBidPriceById,
  GetBidsByLoadId,
  GetBidStatuses,
  UpdateBidMutation,
  UpdateCounterOffer,
} from '@haulynx/gql';
import {
  Bid,
  BidHistory,
  BidInput,
  BidStatus,
  CounterOffer,
  CounterOfferInput,
  CounterOfferStatus,
  UpdateBidInput,
} from '@haulynx/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GraphqlService } from '../graphql.service';

@Injectable({
  providedIn: 'root',
})
export class BidsService {
  constructor(private graphqlService: GraphqlService) {}

  getBidById(bidId: string): Observable<Bid> {
    return this.graphqlService
      .query<Bid>({
        query: GetBidPriceById,
        variables: {
          bidId,
        },
      })
      .pipe(map((result) => result.data['getBidById']));
  }

  getBidsByLoadId(variables: { loadId }): Observable<any> {
    return this.graphqlService.query({
      variables,
      query: GetBidsByLoadId,
    });
  }

  getBidStatuses(): Observable<BidStatus[]> {
    return this.graphqlService
      .query({
        query: GetBidStatuses,
      })
      .pipe(map(({ data }: any) => data.getBidStatuses || []));
  }

  getBidHistory(variables: { bidId: string }): Observable<BidHistory[]> {
    return this.graphqlService
      .query({
        variables,
        query: GetBidHistory,
      })
      .pipe(map(({ data }: any) => data.getBidHistory || []));
  }

  createBid(variables: { newBid: BidInput }): Observable<Bid> {
    return this.graphqlService
      .mutate({
        variables,
        mutation: CreateBidMutation,
      })
      .pipe(map(({ data }: any) => data.createBid || {}));
  }

  updateBid(variables: { bidId: string; bidUpdate: UpdateBidInput }): Observable<Bid> {
    return this.graphqlService
      .mutate({
        variables,
        mutation: UpdateBidMutation,
      })
      .pipe(map(({ data }: any) => data.updateBid || {}));
  }

  acceptBid(variables: { bidId: string }): Observable<Bid> {
    return this.graphqlService
      .mutate({
        variables,
        mutation: AcceptBidMutation,
      })
      .pipe(map(({ data }: any) => data.acceptBid || {}));
  }

  deleteBid(variables: { bidId: string }): Observable<Bid> {
    return this.graphqlService
      .mutate({
        variables,
        mutation: DeleteBidMutation,
      })
      .pipe(map(({ data }: any) => data.deleteBid || {}));
  }

  acceptCounterBid(variables: { counterOfferId: string }): Observable<Boolean> {
    return this.graphqlService
      .mutate({
        variables,
        mutation: AcceptCounterBidAndBookLoadMutation,
      })
      .pipe(map(({ data }: any) => data?.acceptCounterOfferByIdAndBookLoad));
  }

  createCounterOffer(variables: { bidId: string; newCounterOfferInput: CounterOfferInput }): Observable<CounterOffer> {
    return this.graphqlService
      .mutate({
        variables,
        mutation: CreateCounterOffer,
      })
      .pipe(map(({ data }: any) => data.createCounterOffer || {}));
  }

  rejectCounterOffer(variables: { counterOfferId: string }): Observable<CounterOffer> {
    return this.graphqlService
      .mutate({
        variables: { ...variables, updateCounterOfferInput: { status: CounterOfferStatus.REJECTED } },
        mutation: UpdateCounterOffer,
      })
      .pipe(map(({ data }: any) => data.updateCounterOfferById || {}));
  }
}
