import gql from 'graphql-tag';

export const GetCalKpiMetrics = gql(`
  query GetCalKpiMetrics($dot: String!, $dateTime: String) {
    getCalKpiMetrics(dot: $dot, dateTime: $dateTime) {
      units
      revenue
      cost
      margin
      mpu
      marginPercentage
    }
  }
`);
