import { HttpStatus } from '@haulynx/types';
import { AppSettingsActionTypes } from '../../main-store/app-settings.actions';
import { DispatchAction } from '../../shared/store/helpers/action';
import { LoadPriceActionTypes } from './load-price.actions';
import { LoadPriceState } from './load-price.state';

export const initialState: LoadPriceState = new LoadPriceState();

export function loadPriceReducer(state: LoadPriceState = initialState, action: DispatchAction): LoadPriceState {
  switch (action.type) {
    case AppSettingsActionTypes.LOG_OUT:
      return initialState;

    case LoadPriceActionTypes.SET_PRICE: {
      const { loadId } = action.payload;

      return state.merge({
        isLoading: state.isLoading.set(loadId, true),
        httpStatus: state.httpStatus.set(loadId, HttpStatus.BLANK),
      });
    }

    case LoadPriceActionTypes.SET_PRICE_SUCCESS: {
      const { loadId } = action.payload;

      return state.merge({
        isLoading: state.isLoading.delete(loadId),
        httpStatus: state.httpStatus.set(loadId, HttpStatus.SUCCESS),
      });
    }
    case LoadPriceActionTypes.SET_PRICE_ERROR: {
      const { loadId } = action.payload;

      return state.merge({
        isLoading: state.isLoading.delete(loadId),
        httpStatus: state.httpStatus.set(loadId, HttpStatus.FAILED),
      });
    }

    default:
      return state;
  }
}
