import gql from 'graphql-tag';

export const EditReleaseNoteMutation = gql`
  mutation EditReleaseNote($releaseNoteId: StrictId!, $releaseNoteFields: ReleaseNoteUpdateInput!) {
    editReleaseNote(releaseNoteId: $releaseNoteId, releaseNoteFields: $releaseNoteFields) {
      id
      title
      userType
      pages {
        id
        title
        description
        media {
          type
          data
        }
      }
      date
      expiration
      hasViewed
    }
  }
`;
