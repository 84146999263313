import { NotificationEvent } from '@haulynx/types';
import { Record } from 'immutable';

export interface NotificationEventFilterParams {
  recipient?: string;
  eventType?: string;
  targetCarrierDot?: string;
  pastDays?: number;
}

export interface INotificationState {
  showNotification: boolean;
  isLoading: boolean;
  notifications: NotificationEvent[];
  notificationEventTypes: string[];
  query: NotificationEventFilterParams;
}

const initialState = {
  showNotification: false,
  isLoading: false,
  notifications: [],
  notificationEventTypes: [],
  query: {},
};

export class NotificationState extends Record(initialState) implements INotificationState {
  showNotification: boolean;
  isLoading: boolean;
  notifications: NotificationEvent[];
  notificationEventTypes: string[];
  query: NotificationEventFilterParams;
}
