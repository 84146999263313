<div class="milestone-item-container">
  <div class="content">
    <div class="content__icon">
      <mat-icon (click)="openOrClose()">{{
        (open$ | async) ? 'keyboard_arrow_down' : 'keyboard_arrow_right'
      }}</mat-icon>
    </div>

    <div *ngIf="!isMilestoneLocation && !isDispatchMilestone && !isMilestoneTrailer">
      <div class="content__badge" [ngStyle]="{ background: getMilestoneItemColor }">
        <p
          style="padding: 0; margin: 0; text-align: center; font-weight: bold"
          [ngStyle]="{ fontSize: getBadge === '!' ? '15px' : '13px' }"
        >
          {{ getBadge }}
        </p>
        <div class="content__badge__sub-item-count" [ngStyle]="{ background: getMilestoneItemColor }">
          {{ countSubItems }}
        </div>
      </div>
    </div>

    <div *ngIf="isMilestoneTrailer">
      <div class="content__badge">
        <mat-icon [svgIcon]="'haulynx-trailer-icon'"></mat-icon>
        <div class="content__badge__sub-item-count" [ngStyle]="{ background: getMilestoneItemColor }">
          {{ countSubItems }}
        </div>
      </div>
    </div>

    <div *ngIf="isMilestoneLocation">
      <div class="content__badge" [ngStyle]="{ border: 'solid 1px #AFB6CC' }">
        <mat-icon [svgIcon]="'haulynx-location-dots'"></mat-icon>
        <div class="content__badge__sub-item-count" [ngStyle]="{ background: getMilestoneItemColor }">
          {{ countSubItems }}
        </div>
      </div>
    </div>

    <div *ngIf="isDispatchMilestone">
      <div class="content__badge" [ngStyle]="{ background: getMilestoneItemColor }">
        <mat-icon class="content__badge__dispatch-icon" [svgIcon]="'haulynx-car-black'"></mat-icon>
        <div class="content__badge__sub-item-count" [ngStyle]="{ background: getMilestoneItemColor }">
          {{ countSubItems }}
        </div>
      </div>
    </div>

    <div class="content__info" [ngStyle]="{ color: getMilestoneItemColor }">
      <div class="content__info__title" data-test="milestone-title">
        {{ getMilestoneItemTitle }}
      </div>
      <div class="content__info__content" data-test="milestone-timestamp">
        {{ milestone?.lastUpdated | dateTime: null }} -
        {{ isSystemBrokerOrCarrierGenerated }}
      </div>
    </div>

    <div class="content__buttons">
      <mat-icon
        [svgIcon]="'haulynx-add-comment'"
        (click)="onAction({ action: 'open-comment', commentIndex: null })"
        *ngIf="displayCreateComment"
        data-test="add-comment-icon"
      ></mat-icon>
      <mat-icon (click)="onAction({ action: 'edit-milestone', commentIndex: null })" *ngIf="displayEditMilestone"
        >add_circle_outline</mat-icon
      >
    </div>
  </div>
  <div *ngIf="open$ | async">
    <div class="sub-item-container">
      <haulynx-milestone-sub-item
        *ngFor="let log of getMilestoneLogsSorted; let i = index"
        [subItemType]="'system'"
        [milestone]="milestone"
        [milestoneIndex]="milestoneIndex"
        [currentUserType]="currentUserType"
        [load]="load"
        [log]="log"
        (commentAction)="onAction($event)"
      ></haulynx-milestone-sub-item>
    </div>
    <div class="sub-item-container" *ngIf="hasTelemetryHistory">
      <haulynx-milestone-sub-item
        *ngFor="let telemetryHistory of getTelemetryHistory; let i = index"
        [subItemType]="'system'"
        [milestone]="milestone"
        [milestoneIndex]="milestoneIndex"
        [currentUserType]="currentUserType"
        [load]="load"
        [telemetryHistory]="telemetryHistory"
        [collapseMilestone]="collapseMilestone"
        [savedCollapseMilestone]="savedCollapseMilestone"
        (commentAction)="onAction($event)"
      ></haulynx-milestone-sub-item>
    </div>
    <div class="sub-item-container" *ngIf="hasTelemetryHistory">
      <haulynx-milestone-sub-item
        *ngFor="let telemetryHistory of getTelemetryHistory; let i = index"
        [subItemType]="'system'"
        [milestone]="milestone"
        [milestoneIndex]="milestoneIndex"
        [currentUserType]="currentUserType"
        [load]="load"
        [telemetryHistory]="telemetryHistory"
        [collapseMilestone]="collapseMilestone"
        [savedCollapseMilestone]="savedCollapseMilestone"
        (commentAction)="onAction($event)"
      ></haulynx-milestone-sub-item>
    </div>
    <div class="sub-item-container" *ngIf="hasBrokerEdit">
      <haulynx-milestone-sub-item
        [user]="user"
        [subItemType]="'broker_info'"
        [milestone]="milestone"
        [milestoneIndex]="milestoneIndex"
        [currentUserType]="currentUserType"
        [load]="load"
        [log]=""
        (commentAction)="onAction($event)"
      ></haulynx-milestone-sub-item>
    </div>
    <div class="sub-item-container">
      <haulynx-milestone-sub-item
        [user]="user"
        [subItemType]="'carrier_info'"
        [milestone]="milestone"
        [milestoneIndex]="milestoneIndex"
        [currentUserType]="currentUserType"
        [load]="load"
        [log]=""
        (commentAction)="onAction($event)"
      ></haulynx-milestone-sub-item>
    </div>
    <div class="sub-item-container">
      <haulynx-milestone-sub-item
        [user]="user"
        [subItemType]="'comment'"
        [milestone]="milestone"
        [milestoneIndex]="milestoneIndex"
        [currentUserType]="currentUserType"
        [load]="load"
        [log]=""
        (commentAction)="onAction($event)"
      ></haulynx-milestone-sub-item>
    </div>
  </div>
</div>
