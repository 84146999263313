import { Pipe, PipeTransform } from '@angular/core';
import { CarrierSearchListRoute, CarrierSearchListRouteToDisplayListName } from '@haulynx/types';

@Pipe({
  name: 'carrierLoadSearchListDisplay',
})
export class CarrierLoadSearchListDisplayPipe implements PipeTransform {
  transform(list: CarrierSearchListRoute): string {
    return CarrierSearchListRouteToDisplayListName(list);
  }
}
