import { Pipe, PipeTransform } from '@angular/core';
import { Load, LoadsServiceLoad, LoadStatusType, LoadStepType } from '@haulynx/types';
import { transformLoadStatus } from '@haulynx/utils';

@Pipe({ name: 'loadStatus' })
export class LoadStatusPipe implements PipeTransform {
  constructor() {}

  transform(
    load: LoadsServiceLoad,
    statusType: LoadStatusType = LoadStatusType.TEXT,
    stepTypes: Array<LoadStepType[keyof LoadStepType]> = []
  ): string {
    return transformLoadStatus(load, statusType, stepTypes);
  }
}
