import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@haulynx/modules';
import { PostTruckService } from '@haulynx/services';
import { DatepickerModule } from '../datepicker/datepicker.module';
import { DropDownModule } from '../drop-down/drop-down.module';
import { GoogleAddressFieldModule } from '../google-address-field/google-address-field.module';
import { PostTruckFormComponent } from './post-truck-form.component';

@NgModule({
  declarations: [PostTruckFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    GoogleAddressFieldModule,
    DatepickerModule,
    DropDownModule,
    MaterialModule,
  ],
  exports: [PostTruckFormComponent],
  providers: [PostTruckService],
})
export class PostTruckFormModule {}
