import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { CommentDialogComponent, CommentDialogDeleteComponent, MilestoneDialogComponent } from '@haulynx/components';
import { ConfirmationService, CustomersService, MilestonesService } from '@haulynx/services';
import {
  AppModel,
  CommonEntities,
  LoadEntityService,
  LoadRouteEntityService,
  MilestoneEntityService,
  UserEntityService,
  TrailerEntityService,
} from '@haulynx/store';
import {
  CreateMilestoneFormResult,
  DividerSliderLimits,
  DomainEntity,
  EmphasizedMilestone,
  FeatureFlag,
  FFState,
  KeyValuePair,
  LoadIdentifierType,
  LoadLocationType,
  LoadRouteData,
  LoadsServiceCustomer,
  LoadsServiceLoad,
  LoadsServiceLoadLocation,
  MapboxRoute,
  Milestone,
  MilestoneComment,
  MilestoneLog,
  MilestoneStatus,
  MilestoneSubtype,
  MilestoneType,
  MilestoneUser,
  SubscriptionAction,
  User,
  WayPoint,
  AssetBridgeTrailerHistory,
  TrailerOwnerType,
} from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { LngLatLike } from 'mapbox-gl';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, take, takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { alphabetList } from '../milestones-container-config';

@Component({
  selector: 'haulynx-milestones-container',
  templateUrl: './milestones-container.component.html',
  styleUrls: ['./milestones-container.component.scss'],
})
export class MilestonesContainerComponent implements OnInit, OnDestroy {
  dividerSliderLimits: DividerSliderLimits = {
    left: {
      min: '490px',
      max: '630px',
    },
  };

  load: LoadsServiceLoad;
  loadDataLoading$: Observable<boolean>;
  trailersLoading$: Observable<boolean>;
  trailers$: Observable<any>;
  trailerTelemetry$ = new BehaviorSubject([]);
  loadId: string;
  loadCoordinates: string;
  mapboxRoute: MapboxRoute[];
  milestones: Milestone[] = [];
  unusedMilestone: Milestone[] = [];
  alive = aliveWhile();
  user: User;
  currentUserType: MilestoneUser;
  waypoints: WayPoint[] = [];
  dispatchWaypoint: WayPoint = null;
  routeCoordinates: LngLatLike[] = [];
  displayCheckpoints = true;
  displayIssues = true;
  displayEvents = true;
  filterBy = 'Most Recent';
  collapseMilestone: BehaviorSubject<{ action: string; milestoneId?: string }> = new BehaviorSubject({
    action: '',
    milestoneId: '',
  });
  badgeMilestones: KeyValuePair[] = [];
  badgeCount = 0;
  badgeFillers = alphabetList;
  savedCollapseMilestone: KeyValuePair[] = []; //this remembers what the user collasped

  truckPosition: LngLatLike;
  loadRoute$: Observable<LoadRouteData>;
  currentLocation$: Observable<string>;
  private isFirstCoordinatesInitialize = true;
  customers: LoadsServiceCustomer[] = [];
  isDialogOpen: BehaviorSubject<boolean> = new BehaviorSubject(false);
  trailerToggle = true;

  public headHaul: LoadsServiceLoad;
  public backHaul: LoadsServiceLoad;
  public missionId = '';
  public isHeadHaul = false;
  public isBackHaul = false;
  public backHaulId = 0;
  public featureFlags: FFState;
  public missionFeatureFlag = FeatureFlag.MISSIONS;

  constructor(
    private route: ActivatedRoute,
    public loadEntityService: LoadEntityService,
    private milestoneEntityService: MilestoneEntityService,
    private confirmationService: ConfirmationService,
    private appModel: AppModel,
    private dialog: MatDialog,
    private milestoneService: MilestonesService,
    public loadRouteEntityService: LoadRouteEntityService,
    public customer: CustomersService,
    public commonEntities: CommonEntities,
    private userEntityService: UserEntityService,
    private trailerEntityService: TrailerEntityService
  ) {
    this.trailerEntityService.searchTrailerHistoryByTrailerInfo?.onSuccess$
      .pipe(distinctUntilChanged(), takeUntil(this.alive))
      .subscribe((result) => {
        if (result?.telemetryHistory?.length > 0) {
          this.milestones.push(this.trailerTelemetryToMilestone(result));
        }
        this.generateBadges();
        this.generateCollapse();
      });
  }

  ngOnInit(): void {
    this.route.parent.params?.pipe(takeUntil(this.alive)).subscribe((params) => {
      this.loadId = params['id'];
      this.initMilestonesAndLoad();
      this.initTrailers();
    });
    this.generateCustomers();

    this.userEntityService.featureFlags$.pipe(takeUntil(this.alive)).subscribe((features: FFState) => {
      this.featureFlags = features;
    });

    this.milestoneService.createMilestoneFormResult
      .pipe(takeUntil(this.alive))
      .subscribe((result: { formResult: CreateMilestoneFormResult; isEdit: boolean; index: number }) => {
        if (result && result.isEdit) {
          this.onEditMilestone(result.formResult, result.index);
        } else if (result) {
          this.onCreateMilestone(result.formResult);
        }
      });

    this.appModel.user$.pipe(takeUntil(this.alive)).subscribe((user: User) => {
      this.user = user;

      if (this.user.broker) {
        this.currentUserType = MilestoneUser.BROKER;
      } else if (this.user.carrier) {
        this.currentUserType = MilestoneUser.CARRIER;
      }
    });

    this.milestoneEntityService.createMilestoneManager?.onSuccess$
      .pipe(takeUntil(this.alive))
      .subscribe((result: any) => {
        this.milestones = result.milestones;
        this.load = { ...this.load, loadStatus: result.loadStatus };

        this.generateBadges();
        this.generateCollapse();
        this.initMapboxRoute();
        this.performFiltering();
        this.updateLoadOverviewSidebar();
      });

    this.milestoneEntityService.updateMilestoneManager?.onSuccess$
      .pipe(takeUntil(this.alive))
      .subscribe((result: any) => {
        this.generateBadges();
        this.performFiltering();
        this.updateLoadOverviewSidebar();
      });

    this.milestoneEntityService.createCommentManager?.onSuccess$
      .pipe(takeUntil(this.alive))
      .subscribe((result: any) => {
        this.milestones = result;
        this.performFiltering();
      });

    this.milestoneEntityService.updateCommentManager?.onSuccess$
      .pipe(takeUntil(this.alive))
      .subscribe((result: any) => {
        this.milestones = result;
        this.performFiltering();
      });

    this.milestoneEntityService.deleteCommentManager?.onSuccess$
      .pipe(takeUntil(this.alive))
      .subscribe((result: any) => {
        this.milestones = result;
        this.performFiltering();
      });
  }

  performFiltering(): Milestone[] {
    this.displayMilestoneItems([
      {
        key: MilestoneType.CHECKPOINT,
        value: this.displayCheckpoints,
      },
      {
        key: MilestoneType.UPDATE,
        value: this.displayIssues,
      },
      {
        key: MilestoneType.LOCATION,
        value: this.displayEvents,
      },
    ]);
    return this.filterMilestoneItems(this.filterBy);
  }

  initTrailers(): void {
    this.trailersLoading$ = this.trailerEntityService.searchTrailerHistoryByTrailerInfo.isLoading$;

    this.trailers$ = this.trailerEntityService.searchTrailerHistoryByTrailerInfo.onSuccess$;
  }

  initMilestonesAndLoad(): void {
    this.loadDataLoading$ = this.loadEntityService.getLoadByIdManager.getIsLoadingById(this.loadId);

    this.loadEntityService.getLoadByIdManager
      .getEntityById(this.loadId)
      .pipe(takeUntil(this.alive))
      .subscribe((load: LoadsServiceLoad) => {
        this.load = load;
        if (this.load) {
          if (this.load.trailers?.length > 0) {
            for (const trailer of this.load.trailers) {
              if (
                !(trailer.trailerOwnerType === TrailerOwnerType.USXI) ||
                !trailer.trailerCompany ||
                !trailer.trailerNumber
              ) {
                continue;
              }
              this.trailerEntityService.searchTrailerHistoryByTrailerInfo.dispatch({
                trailerCompany: trailer.trailerCompany,
                trailerNumber: trailer.trailerNumber,
              });
            }
          }
          if (load.mission?.loads && load.mission?.loads.length > 0) {
            const sortedMissionLoads = [];
            for (const iLoad of load.mission.loads) {
              sortedMissionLoads.push(iLoad);
            }
            this.sortMissionLoads(sortedMissionLoads);
            this.headHaul = sortedMissionLoads[0];
            this.backHaul = sortedMissionLoads[sortedMissionLoads.length - 1];
            this.missionId = load.mission.id;
            this.isHeadHaul = load.id === this.headHaul.id;
            this.isBackHaul = load.id === this.backHaul.id;
          }

          if (this.load?.milestones?.length > 0) {
            this.milestones = [...this.load.milestones];
            this.milestones = this.performFiltering();
          } else {
            this.milestones = [];
          }

          this.generateBadges();
          this.generateCollapse();

          const waypoints = this.milestoneService.getLoadServiceLoadWaypoints(this.load, this.milestones);
          this.waypoints = waypoints.location;
          this.dispatchWaypoint = waypoints.dispatch;

          // setting up coordinates for pickup and dropoff locations.
          this.initializeCoordinates(load);

          this.loadRouteEntityService.getLoadRouteManager.dispatch(this.loadCoordinates);
          this.initMapboxRoute();

          const carrierId = this.load.carrier?.id;
          const query = { carrierId };
          if (carrierId) {
            this.commonEntities.graphQlDrivers.search({ key: this.loadId, query });
          }
        }
      });

    this.loadEntityService.getLoadByIdManager.dispatch(this.loadId);
  }

  onCollapseClicked(action: string, milestoneId?: string): void {
    this.collapseMilestone.next({ action: action, milestoneId: milestoneId });
  }

  private initializeCoordinates(load: LoadsServiceLoad): void {
    this.loadCoordinates = this.milestoneService.computeLoadRouteCoordinates(load);
    this.loadRoute$ = this.loadRouteEntityService.getLoadRouteManager.getEntityById(this.loadCoordinates);
    this.loadRoute$.pipe(debounceTime(100), takeUntil(this.alive)).subscribe((value) => {
      if (value?.loadMapRoute?.length > 0) {
        this.routeCoordinates = [...value.loadMapRoute];
        if (this.isFirstCoordinatesInitialize) {
          this.initMapboxRoute();
          this.isFirstCoordinatesInitialize = false;
        }
      }
    });
  }

  toggleTrailers(toggleEvent) {
    this.trailerToggle = toggleEvent.checked;
  }

  trailerTelemetryToMilestone(telemetry: AssetBridgeTrailerHistory) {
    const lastUpdated = new Date(telemetry?.telemetryHistory[0]?.telemetryPingDateTime);
    const formattedDate = parseFloat(
      `${lastUpdated.getFullYear()}${lastUpdated.getMonth()}${lastUpdated.getDay()}${lastUpdated.getHours()}${lastUpdated.getMinutes()}`
    );
    const telemetryMilestone: Milestone = {
      authorId: 'trailerTelemetry',
      authorType: MilestoneUser.SYSTEM,
      comments: [],
      location: null,
      locationId: '',
      logs: [],
      lastUpdated: new Date(telemetry?.telemetryHistory[0]?.telemetryPingDateTime).getTime(),
      type: MilestoneType.TRAILER,
      telemetryHistory: telemetry?.telemetryHistory ?? [],
    };

    return telemetryMilestone;
  }

  onHeaderClickEvent(click: { event: KeyValuePair[]; eventType: string }) {
    if (click.eventType === 'Display') {
      this.displayCheckpoints = false;
      this.displayIssues = false;
      this.displayEvents = false;
      click.event.forEach((index) => {
        switch (index.key) {
          case 'All': {
            this.displayCheckpoints = true;
            this.displayIssues = true;
            this.displayEvents = true;
            break;
          }
          case 'Checkpoints': {
            this.displayCheckpoints = true;
            break;
          }
          case 'Issues': {
            this.displayIssues = true;
            break;
          }
          case 'Events': {
            this.displayEvents = true;
            break;
          }
        }
      });

      this.displayMilestoneItems([
        {
          key: MilestoneType.CHECKPOINT,
          value: this.displayCheckpoints,
        },
        {
          key: MilestoneType.UPDATE,
          value: this.displayIssues,
        },
        {
          key: MilestoneType.LOCATION,
          value: this.displayEvents,
        },
      ]);
    } else if (click.eventType === 'Filter') {
      click.event.forEach((index) => {
        this.filterBy = index.key;
        this.filterMilestoneItems(this.filterBy);
      });
    } else {
      click.event.forEach((index) => {
        switch (index.key) {
          case 'Expand All': {
            this.resetCollapse();
            this.onCollapseClicked('expand-all');
            break;
          }
          case 'Expand Broker Entries': {
            this.resetCollapse();
            this.onCollapseClicked('expand-broker');
            break;
          }
          case 'Collapse All': {
            this.resetCollapse();
            this.onCollapseClicked('collapse-all');
            break;
          }
        }
      });
    }
  }

  filterMilestoneItems(key: string): Milestone[] {
    switch (key) {
      case 'Most Recent': {
        const milestones = [...this.milestones];
        const sorted = milestones.sort((a, b) => b.createdAt - a.createdAt);
        this.milestones = sorted;
        return sorted;
      }
      case 'Type': {
        const milestones = [...this.milestones];
        const sorted = milestones.sort((a, b) => {
          const aa = a.type.toUpperCase();
          const bb = b.type.toUpperCase();
          if (aa > bb) return 1;
          else if (aa < bb) return -1;
          else return 0;
        });
        this.milestones = sorted;
        return sorted;
      }
      case 'Last Modified': {
        const milestones = [...this.milestones];
        const sorted = milestones.sort((a, b) => b.lastUpdated - a.lastUpdated);
        this.milestones = sorted;
        return sorted;
      }
      case 'Last Commented': {
        const milestones = [...this.milestones];
        const sorted = milestones.sort((a, b) => {
          const commentA = this.milestoneService.getLastMilestoneComment([a]);
          const commentB = this.milestoneService.getLastMilestoneComment([b]);
          if (commentA && commentB) {
            return commentB.timestamp - commentA.timestamp;
          } else if (commentA) {
            return -1;
          } else if (commentB) {
            return 1;
          } else return 0;
        });
        this.milestones = sorted;
        return sorted;
      }
    }
  }

  displayMilestoneItems(display: Array<KeyValuePair>) {
    this.milestones = [...this.unusedMilestone, ...this.milestones];
    let matchedMilestones: Milestone[] = [];

    display.forEach((value: KeyValuePair) => {
      if (value.value) {
        matchedMilestones = [
          ...matchedMilestones,
          ...this.milestones.filter((milestone) => milestone.type === value.key),
        ];
      }
    });

    this.unusedMilestone = this.milestones.filter((x) => !matchedMilestones.includes(x));
    this.milestones = matchedMilestones;
    this.generateBadges();
    this.filterMilestoneItems(this.filterBy);
  }

  initMapboxRoute(emphasizedMilestone?: EmphasizedMilestone): void {
    if (this.routeCoordinates?.length > 0) {
      const locationUpdates = this.milestones.filter((milestone) => milestone.type === MilestoneType.LOCATION);
      const newTruckLocation = this.milestoneService.getLatestKnownLocation(locationUpdates, this.load);
      if (this.truckPosition !== newTruckLocation) {
        if (this.load) {
          this.loadRouteEntityService.getRouteRemainingDistance(newTruckLocation, this.load);
        }
      }
      this.truckPosition = newTruckLocation;
      if (locationUpdates.length > 0) {
        this.mapboxRoute = [
          {
            entityId: 'MilestoneMap',
            wayPoints: this.waypoints,
            routeCoordinates: this.routeCoordinates,
            eldPoints: this.milestoneService.getEldPointsFromAllMilestones(
              this.milestones,
              emphasizedMilestone,
              this.user
            ),
            isDelayed: this.milestoneService.getIsRouteDelayed(this.milestones, this.load),
            showTruck: true,
            lineColor: '#ffffff',
            lineWidth: 2,
            truck: {
              name: 'Truck',
              position: this.truckPosition,
            },
            dispatch: this.dispatchWaypoint,
            customers: this.customers,
          },
        ];
      } else {
        this.mapboxRoute = [
          {
            entityId: 'MilestoneMap',
            wayPoints: this.waypoints,
            routeCoordinates: this.routeCoordinates,
            eldPoints: this.milestoneService.getEldPointsFromAllMilestones(
              this.milestones,
              emphasizedMilestone,
              this.user
            ),
            isDelayed: this.milestoneService.getIsRouteDelayed(this.milestones, this.load),
            showTruck: true,
            lineColor: '#ffffff',
            lineWidth: 2,
            dispatch: this.dispatchWaypoint,
            customers: this.customers,
          },
        ];
      }
    }
  }

  onContentClickEvent(click: {
    event: string;
    milestoneIndex: number;
    commentIndex: number;
    emphasizedMilestone: EmphasizedMilestone;
  }): void {
    switch (click.event) {
      case 'open-comment': {
        this.createComment(click.milestoneIndex);
        break;
      }
      case 'edit-comment': {
        this.editComment(click.milestoneIndex, click.commentIndex);
        break;
      }
      case 'delete-comment': {
        this.deleteComment(click.milestoneIndex, click.commentIndex);
        break;
      }
      case 'open-milestone': {
        this.openMilestone({ milestoneType: MilestoneType.CHECKPOINT });
        break;
      }
      case 'edit-milestone': {
        this.editMilstone(click.milestoneIndex);
        break;
      }
      case 'delete-milestone-logs': {
        this.deleteMilestoneLog(click.milestoneIndex);
        break;
      }
      case 'expand-milestone': {
        const waypoints = this.milestoneService.getLoadServiceLoadWaypoints(
          this.load,
          this.milestones,
          click.emphasizedMilestone
        );
        this.waypoints = waypoints.location;
        this.dispatchWaypoint = waypoints.dispatch;
        this.initMapboxRoute(click.emphasizedMilestone);
        break;
      }
    }
  }

  deleteMilestoneLog(milestoneIndex: number): void {
    this.confirmationService
      .open({
        title: 'Delete Entry',
        message: 'Are you sure you want to delete this entry?',
        confirm: { text: 'Delete' },
        deny: { text: 'Cancel', hide: false },
      })
      .pipe(takeUntil(this.alive))
      .subscribe((confirmed) => {
        if (confirmed) {
          const deleteLog = this.milestones[milestoneIndex].logs.filter(
            (index: MilestoneLog) => index.editedByType !== this.currentUserType
          );

          const milestone = this.milestones[milestoneIndex];
          this.milestones[milestoneIndex] = { ...milestone, logs: [...deleteLog] };
        }
      });
  }

  deleteComment(milestoneIndex: number, commentIndex: number): void {
    this.isDialogOpen.next(true);
    const comment = this.milestones[milestoneIndex].comments[commentIndex];
    this.dialog
      .open(CommentDialogDeleteComponent, {
        width: '376px',
        height: '230px',
        data: {
          comment: comment,
          milestone: this.milestones[milestoneIndex],
          loadId: this.loadId,
        },
      })
      .afterClosed()
      .pipe(takeUntil(this.alive))
      .subscribe((confirmed) => {
        this.isDialogOpen.next(false);
        if (confirmed) {
          this.filterMilestoneItems(this.filterBy);
        }
      });
  }

  editComment(milestoneIndex: number, commentIndex: number): void {
    this.isDialogOpen.next(true);
    const comment = this.milestones[milestoneIndex].comments[commentIndex];
    this.dialog
      .open(CommentDialogComponent, {
        width: '550px',
        height: '500px',
        data: {
          comment: comment,
          subType: this.getMilestoneItemTitle(this.milestones[milestoneIndex], this.load),
          dialogType: false,
          milestone: this.milestones[milestoneIndex],
          loadId: this.loadId,
          load: this.load,
        },
      })
      .afterClosed()
      .pipe(takeUntil(this.alive))
      .subscribe((result: MilestoneComment) => {
        this.isDialogOpen.next(false);
        if (result) {
          this.filterMilestoneItems(this.filterBy);
        }
      });
  }

  createComment(index: number): void {
    this.isDialogOpen.next(true);
    const newComment: MilestoneComment = {
      creatorId: this.user.usxId,
      contact: '',
      timezone: '',
      text: '',
      timestamp: new Date().valueOf(),
      creatorType: this.currentUserType,
    };
    this.dialog
      .open(CommentDialogComponent, {
        width: '550px',
        height: '500px',
        data: {
          comment: newComment,
          subType: this.getMilestoneItemTitle(this.milestones[index], this.load),
          dialogType: true,
          milestone: this.milestones[index],
          loadId: this.loadId,
          load: this.load,
        },
      })
      .afterClosed()
      .pipe(takeUntil(this.alive))
      .subscribe((result: MilestoneComment) => {
        this.isDialogOpen.next(false);
        if (result) {
          this.filterMilestoneItems(this.filterBy);
        }
      });
  }

  openMilestone(initalValues?: {
    milestoneType?: MilestoneType;
    subType?: MilestoneSubtype;
    coordinates?: LngLatLike;
    locationId?: string;
  }): void {
    this.isDialogOpen.next(true);
    let width = '';
    let height = '';
    if (initalValues?.milestoneType === MilestoneType.UPDATE) {
      width = '550px';
      height = '560px';
    } else {
      width = '550px';
      height = '730px';
    }

    this.dialog
      .open(MilestoneDialogComponent, {
        width: width,
        height: height,
        data: {
          load: this.load,
          milestone: undefined,
          creator: this.user.usxId,
          creatorType: this.currentUserType,
          milestones: this.milestones,
          loadId: this.loadId,
          coordinates: initalValues?.coordinates,
          locationId: initalValues?.locationId,
          milestoneType: initalValues?.milestoneType,
          subType: initalValues?.subType,
        },
      })
      .afterClosed()
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.isDialogOpen.next(false);
      });
  }

  editMilstone(index: number): void {
    this.isDialogOpen.next(true);
    let width = '';
    let height = '';
    if (this.milestones[index].type === MilestoneType.UPDATE) {
      width = '550px';
      height = '560px';
    } else if (
      this.milestones[index].logs[0].subType === MilestoneSubtype.DISPATCH &&
      this.milestones[index].type === MilestoneType.CHECKPOINT
    ) {
      width = '550px';
      height = '620px';
    } else {
      width = '550px';
      height = '730px';
    }
    this.dialog
      .open(MilestoneDialogComponent, {
        width: width,
        height: height,
        data: {
          load: this.load,
          milestone: this.milestones[index],
          creator: this.user.usxId,
          creatorType: this.currentUserType,
          milestones: this.milestones,
          loadId: this.loadId,
          index: index,
        },
      })
      .afterClosed()
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.isDialogOpen.next(false);
      });
  }

  onEditMilestone(event: CreateMilestoneFormResult, index: number) {
    const newLog: MilestoneLog = {
      editedBy: this.user.usxId,
      editedByType: this.currentUserType,
      primaryEvent: {
        latitude: event.loadLocation.geometry.coordinates[0],
        longitude: event.loadLocation.geometry.coordinates[1],
        timestamp: event.timeInDate ? event.timeInDate.valueOf() : null,
        timezone: '',
      },
      secondaryEvent: {
        latitude: null,
        longitude: null,
        timestamp: event.timeOutDate ? event.timeOutDate.valueOf() : null,
        timezone: '',
      },
      subType: event.milestoneStatus,
      timestamp: new Date().valueOf(),
      status: MilestoneStatus.ON_TIME,
    };

    const newComment: MilestoneComment = {
      creatorId: this.user.usxId,
      contact: event.contact,
      creatorType: this.currentUserType,
      text: event.comment,
      timestamp: new Date().valueOf(),
      timezone: '',
    };

    const previousComments = this.milestones[index].comments.map((c: MilestoneComment) => {
      const { id, ...comment } = c;
      return comment;
    });

    const hasNewComment = newComment.text !== '';

    const newMilestone: Milestone = {
      authorId: this.milestones[index].authorId,
      authorType: this.milestones[index].authorType,
      comments: [...previousComments],
      contact: this.milestones[index].contact,
      id: this.milestones[index].id,
      loadLocationAddress: event.loadLocationAddress,
      location: this.milestones[index].location,
      locationId: event.loadLocation ? event.loadLocation.id : '',
      logs: this.editOrCreateLog(index, newLog),
      type: event.milestoneType,
    };
    if (hasNewComment) {
      newMilestone.comments.push(newComment);
    }
    this.milestoneEntityService.updateMilestoneManager.dispatch(this.loadId, {
      loadId: this.loadId,
      milestone: newMilestone,
      loadTmw: this.load.providerDetails.alternateIds.find(
        (value) => value.identifierType === LoadIdentifierType.TMW_NUMBER
      )?.identifierValue,
    });
    const m = { ...newMilestone };
    m.lastUpdated = new Date().valueOf();
    m.createdAt = this.milestones[index].createdAt;
    this.milestones[index] = m;
    const waypoints = this.milestoneService.getLoadServiceLoadWaypoints(this.load, this.milestones);
    this.waypoints = waypoints.location;
    this.dispatchWaypoint = waypoints.dispatch;
    this.initMapboxRoute();
  }

  editOrCreateLog(index: number, newLog: MilestoneLog): MilestoneLog[] {
    const exisitingLog: MilestoneLog = this.milestones[index].logs.find(
      (index) => index.editedByType === this.currentUserType
    );
    if (exisitingLog) {
      //edit logs object - throws can't edit read only objects so had to reassigned logs object
      const filteredLog = this.milestones[index].logs.filter(
        (index: MilestoneLog) => index.editedByType !== this.currentUserType
      );
      const tempLog = [...filteredLog, newLog];
      const milestone = this.milestones[index];
      this.milestones[index] = { ...milestone, logs: [...tempLog] };
      this.milestones[index].logs = this.milestones[index].logs.sort((a, b) => b.timestamp - a.timestamp);
      const finalLogs = [];
      this.milestones[index].logs.forEach((l) => {
        const { id, ...remaining } = l;
        finalLogs.push(remaining);
      });
      return finalLogs;
    } else {
      const finalLogs = [];
      this.milestones[index].logs.forEach((l) => {
        const { id, ...remaining } = l;
        finalLogs.push(remaining);
      });
      let resultLog = [...finalLogs, newLog];
      resultLog = resultLog.sort((a, b) => b.timestamp - a.timestamp);
      return resultLog;
    }
  }

  onCreateMilestone(event: CreateMilestoneFormResult) {
    const comment: MilestoneComment = {
      creatorId: event.createdBy,
      contact: event.contact,
      creatorType: this.currentUserType,
      text: event.comment,
      timestamp: event.creationTime,
      timezone: '',
    };

    const hasCommentText = comment.text !== '';

    const newMilestone: Milestone = {
      authorId: this.user.usxId,
      authorType: this.currentUserType,
      comments: hasCommentText ? [comment] : [],
      contact: {
        email: '',
        name: event.contact,
        phone: '',
      },
      loadLocationAddress: event.loadLocationAddress,
      location: {
        latitude: null,
        longitude: null,
        timestamp: event.creationTime,
        timezone: '',
      },
      locationId: '',
      logs: [
        {
          editedBy: this.user.usxId,
          editedByType: this.currentUserType,
          primaryEvent: {
            latitude: event.loadLocation.geometry.coordinates[0],
            longitude: event.loadLocation.geometry.coordinates[1],
            timestamp: event.timeInDate ? event.timeInDate.valueOf() : null,
            timezone: event.loadLocation.timezone,
          },
          secondaryEvent: {
            latitude: null,
            longitude: null,
            timestamp: event.timeOutDate ? event.timeOutDate.valueOf() : null,
            timezone: event.loadLocation.timezone,
          },
          subType: event.milestoneStatus,
          timestamp: new Date().valueOf(),
          status: MilestoneStatus.ON_TIME,
        },
      ],
      type: event.milestoneType,
    };

    //fill primary event if checkpoint and not dispatch empty or update
    if (newMilestone.type === MilestoneType.CHECKPOINT && newMilestone.logs[0].subType !== MilestoneSubtype.DISPATCH) {
      newMilestone.logs[0].primaryEvent.latitude = event.loadLocation.geometry.coordinates[0];
      newMilestone.logs[0].primaryEvent.longitude = event.loadLocation.geometry.coordinates[1];
      newMilestone.locationId = event.loadLocation.id;
    }

    this.milestoneEntityService.createMilestoneManager.dispatch(this.loadId, {
      milestone: newMilestone,
      loadId: this.loadId,
      loadTmw: this.load.providerDetails.alternateIds.find(
        (value) => value.identifierType === LoadIdentifierType.TMW_NUMBER
      )?.identifierValue,
      trackingType: this.load.trackingType,
    });

    const nMilestone = { ...newMilestone };
    const milestones = [...this.milestones];
    nMilestone.lastUpdated = new Date().valueOf();
    nMilestone.createdAt = new Date().valueOf();
    milestones.push(nMilestone);
    this.milestones = milestones;

    const waypoints = this.milestoneService.getLoadServiceLoadWaypoints(this.load, this.milestones);
    this.waypoints = [...waypoints.location];
    this.dispatchWaypoint = waypoints.dispatch;
    this.initMapboxRoute();
  }

  generateBadges(): void {
    const locations = this.milestoneService
      .getLoadServiceLoadWaypoints(this.load, this.milestones)
      .location.filter((location) => location.locationType !== 'dispatch');
    this.badgeMilestones = [];
    this.badgeCount = locations.length;
    const milestones = [...this.milestones];
    milestones
      .sort((a, b) => {
        const aLocation = this.load.locations.find((location) => location.id === a.locationId);
        const bLocation = this.load.locations.find((location) => location.id === b.locationId);
        if (!aLocation || !bLocation) return 0;
        if (aLocation.locationType === LoadLocationType.PICKUP && bLocation.locationType === LoadLocationType.DROPOFF)
          return 1;
        else if (
          aLocation.locationType === LoadLocationType.DROPOFF &&
          bLocation.locationType === LoadLocationType.PICKUP
        )
          return -1;
        else return 0;
      })
      .sort((a, b) => {
        const aLocation = this.load.locations.find((location) => location.id === a.locationId);
        const bLocation = this.load.locations.find((location) => location.id === b.locationId);
        if (!aLocation || !bLocation) return 0;
        if (aLocation.appointmentStart > bLocation.appointmentStart) return 1;
        else return -1;
      })
      .forEach((milestone) => {
        let notInLocations = true;
        if (
          milestone.type === MilestoneType.CHECKPOINT &&
          milestone.logs.filter((log) => log.subType === MilestoneSubtype.DISPATCH).length <= 0
        ) {
          locations.forEach((location, index) => {
            if (milestone.loadLocationAddress === location.name) {
              notInLocations = false;
              const badgeMilestone: KeyValuePair = {
                key: this.badgeFillers[index],
                value: milestone.id,
              };
              this.badgeMilestones.push(badgeMilestone);
            }
          });
          if (notInLocations) {
            const badgeMilestone: KeyValuePair = {
              key: this.badgeCount > this.badgeFillers.length - 1 ? 'Z' : this.badgeFillers[this.badgeCount],
              value: milestone.id,
            };
            this.badgeMilestones.push(badgeMilestone);
            this.badgeCount++;
          }
        } else if (milestone.type === MilestoneType.UPDATE) {
          this.badgeMilestones.push({ key: '!', value: milestone.id });
        }
      });
  }

  resetCollapse(): void {
    this.savedCollapseMilestone.forEach((milestone) => {
      (milestone.value as KeyValuePair[]).forEach((collapseKey) => {
        collapseKey.value = false;
      });
    });
  }

  generateCollapse(): void {
    this.milestones.forEach((milestone) => {
      if (!this.savedCollapseMilestone.find((collapse) => collapse.key === milestone.id)) {
        this.savedCollapseMilestone.push({
          key: milestone.id,
          value: [
            {
              key: 'MILESTONE',
              value: false,
            },
            {
              key: 'BROKER',
              value: false,
            },
            {
              key: 'CARRIER',
              value: false,
            },
            {
              key: 'SYSTEM',
              value: false,
            },
            {
              key: 'COMMENT',
              value: false,
            },
          ],
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }

  private isMilestoneIssue(milestone: Milestone): boolean {
    return milestone.type === MilestoneType.UPDATE;
  }

  private getMilestoneItemTitle(milestone: Milestone, load: LoadsServiceLoad): string {
    if (this.isMilestoneIssue(milestone)) {
      // TODO: make this the correct milestone subType
      return `Load Issue: ${milestone.logs[0].subType}`;
    } else {
      return this.getNonIssueTitle(milestone, load);
    }
  }

  private getNonIssueTitle(milestone: Milestone, load: LoadsServiceLoad): string {
    const location: LoadsServiceLoadLocation = load.locations.find((location) => location.id === milestone.locationId);
    if (location) {
      return location.locationType.toUpperCase();
    } else return 'Dispatch Empty';
  }

  updateLoadOverviewSidebar(): void {
    //READ will update the sidebar information
    this.loadEntityService.logEntityActionManager.dispatch({
      action: SubscriptionAction.READ,
      entityId: this.loadId,
      entity: DomainEntity.LOAD,
      userId: this.user.id,
    });
    this.loadEntityService.getLoadByIdManager.dispatch(this.loadId);
  }

  waypointclick(click: {
    event: string;
    locationId?: string;
    coordinates?: { lat: number; lng: number };
    milestoneId?: string;
  }): void {
    switch (click.event) {
      case 'create-edit-milestone': {
        const getMilestone = this.milestones.find((milestone) => milestone.locationId === click.locationId);

        if (getMilestone) {
          this.editMilstone(this.milestones.findIndex((milestone) => getMilestone === milestone));
        } else {
          this.openMilestone({ milestoneType: MilestoneType.CHECKPOINT, locationId: click.locationId });
        }
        break;
      }
      case 'create-location-update-milestone': {
        this.openMilestone({
          milestoneType: MilestoneType.UPDATE,
          subType: MilestoneSubtype.LOCATION,
          coordinates: { lng: click.coordinates.lng, lat: click.coordinates.lat },
        });
        break;
      }

      case 'create-comment-milestone': {
        const milestoneIndex = this.milestones.findIndex((milestone) => milestone.id === click.milestoneId);
        this.createComment(milestoneIndex);
        break;
      }
    }
  }

  waypointOpenMilestone(click: { milestoneId: string }): void {
    this.onCollapseClicked('expand-individual', click.milestoneId);
  }

  generateCustomers(): void {
    this.load.locations?.forEach((location) => {
      if (location.customer) {
        this.customer
          .getCustomerById({ id: location.customer?.number.toString() })
          .pipe(take(1))
          .subscribe((customer) => {
            this.customers = [...this.customers, customer];
            this.initMapboxRoute();
          });
      }
    });
  }

  private sortMissionLoads(loads: LoadsServiceLoad[]): LoadsServiceLoad[] {
    return loads.sort(
      (first, second) => 0 - (first.locations[0]?.appointmentStart <= second.locations[0]?.appointmentStart ? 1 : -1)
    );
  }
}
