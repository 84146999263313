import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoadOverviewRedirectGuard } from '@haulynx/guards';
import { LoadDetailsContainerComponent } from './load-details-container.component';

const routes: Routes = [
  { path: '', component: LoadDetailsContainerComponent },
  {
    path: 'overview',
    canActivate: [LoadOverviewRedirectGuard],
    loadChildren: () => import('@haulynx/load-overview').then((m) => m.LoadOverviewModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LoadDetailsContainerRoutingModule {}
