import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'haulynx-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerComponent implements OnInit {
  @Input() label: string = 'Choose Date';
  @Input() minDate: Date = null;
  @Input() maxDate: Date = null;
  @Input() range: boolean = false;
  @Input() comparisonRangeFormGroup = new FormGroup({
    startTime: new FormControl(null, [Validators.required]),
    endTime: new FormControl(null, [Validators.required]),
  });
  @Input() rangeFormGroup = new FormGroup({
    startTime: new FormControl({ value: null, disabled: true }, [Validators.required]),
    endTime: new FormControl({ value: null, disabled: true }, [Validators.required]),
  });
  @Input() formControl = new FormControl({ value: null, disabled: true }, [Validators.required]);
  @Input() separator: string = '–';
  @Input() tabindex = 0;

  constructor() {}

  ngOnInit() {
    this.formControl.disable();
    this.rangeFormGroup.controls.startTime.disable();
    this.rangeFormGroup.controls.endTime.disable();
  }
}
