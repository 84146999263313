import { DispatchAction } from '../../shared/store/helpers/action';
import { SharedLocationActionTypes } from './shared-location.actions';
import { SharedLocationState } from './shared-location.state';

export const initialState: SharedLocationState = new SharedLocationState();

export function sharedLocationReducer(
  state: SharedLocationState = initialState,
  action: DispatchAction
): SharedLocationState {
  switch (action.type) {
    case SharedLocationActionTypes.GET_SHARED_LOAD:
      return state.merge({
        isLoadingSharedLoad: true,
      });

    case SharedLocationActionTypes.GET_SHARED_LOAD_SUCCESS:
      return state.merge({
        sharedLoad: action.payload,
        isLoadingSharedLoad: false,
      });

    case SharedLocationActionTypes.GET_SHARED_LOAD_ERROR:
      return state.merge({
        isLoadingSharedLoad: false,
      });

    case SharedLocationActionTypes.GET_SHARED_LOCATION_SUCCESS:
      return state.merge({
        sharedLocation: action.payload,
      });

    case SharedLocationActionTypes.GET_SHARED_LOCATION_ERROR:
      return state.merge({
        sharedLocation: null,
      });

    default:
      return state;
  }
}
