import gql from 'graphql-tag';

export const UpdateUserInfoMutation = gql(`
  mutation UpdateUserInfo($userId: StrictId!, $userInfo: UserInfoInput!) {
    updateUserInfo(userId: $userId, userInfo: $userInfo) {
      code
      message
      valid
    }
  }
`);
