import { createGenericActionsAdapter, GenericActionsAdapter } from '../../shared/store/generic-actions/adapter';
import { combineAdapters } from '../../shared/store/helpers/adapter';
import { createSearchAdapter, SearchAdapter } from '../../shared/store/search/adapter';
import { createTabAdapter, TabAdapter } from '../../shared/store/tabs/adapter';

export const TYPE = '[LOADS]';

const loadState = {
  // loadSearch: createSearchAdapter,
  tabs: createTabAdapter,
  actions: createGenericActionsAdapter,
};

export interface ILoadStateAdapter {
  // loadSearch: SearchAdapter<any>;
  tabs: TabAdapter;
  actions: GenericActionsAdapter<any>;
}

export const loadAdapter: ILoadStateAdapter = combineAdapters<ILoadStateAdapter>(loadState, TYPE);

export const LOAD_ACTIVE_TABS_TYPE = '[LOADS ACTIVE TABS]';
export const loadActiveTabsState = {
  loadActiveTabs: createTabAdapter,
};

export interface ILoadActiveTabs {
  loadActiveTabs: TabAdapter;
}

export const loadActiveTabsAdapter = combineAdapters<ILoadActiveTabs>(loadActiveTabsState, LOAD_ACTIVE_TABS_TYPE);

export const LOAD_AVAILABLE_TABS_TYPE = '[LOADS AVAILABLE TABS]';

export const loadAvailableTabsState = {
  loadAvailableTabs: createTabAdapter,
};

export interface ILoadAvailableTabs {
  loadAvailableTabs: TabAdapter;
}

export const loadAvailableTabsAdapter = combineAdapters<ILoadAvailableTabs>(
  loadAvailableTabsState,
  LOAD_AVAILABLE_TABS_TYPE
);

export const LOAD_OFFERS_SEARCH = '[LOADS OFFERS SEARCH]';
export const loadOffersSearchState = {
  loadOffers: createSearchAdapter,
};
export interface ILoadOffersSearch {
  loadOffers: SearchAdapter<any>;
}
export const loadOffersSearchAdapter: ILoadOffersSearch = combineAdapters<ILoadOffersSearch>(
  loadOffersSearchState,
  LOAD_OFFERS_SEARCH
);
