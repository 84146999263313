import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'documentType',
})
export class FromDocumentTypePipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case 'RATE_CONFIRMATION':
        return 'RATE CON';
      case 'PROOF_OF_DELIVERY':
        return 'P.O.D. DOC';
      case 'BILL_OF_LADING':
        return 'B.O.L. DOC';
      case 'SHIPPING_INSTRUCTION':
        return 'SHIP INSTRUCT';
      case 'PACKING_SLIP':
        return 'PACKING SLIP';
      case 'PICK_TICKET':
        return 'PICK TICKET';
      case 'PALLET_LABEL':
        return 'PALLET LABEL';
      case 'SHIPPING_LOG':
        return 'SHIP LOG';
      case 'LUMPER_RECEIPT':
        return 'LUMPER RECEIPT';
      case 'INSPECTION_IMAGE':
        return 'INSPECTION DOC';
      case 'LOAD_INVOICE':
        return 'LOAD INVOICE';
      default:
        return 'OTHER';
    }
  }
}
