import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LoadPriceEffects } from './load-price.effects';
import { loadPriceReducer } from './load-price.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('load-price', loadPriceReducer),
    EffectsModule.forFeature([LoadPriceEffects]),
  ],
})
export class LoadPriceStoreModule {}
