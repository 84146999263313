export interface UnclaimedUser {
  email: string;
  phone: string;
  userInfo: UnclaimedUserInfo;
}

export interface UnclaimedUserInfo {
  admin: boolean;
  accountType: string;
  companyId: string;
  issuingCountry: string;
  issuingState: string;
  licenseNumber: string;
  pcEnabled: boolean;
  teamDrivingEnabled: boolean;
  ymEnabled: boolean;
  trailerCreationEnabled: boolean;
}
