import gql from 'graphql-tag';

export const GetLatestUnreadReleaseNotes = gql`
  query GetLatestUnreadReleaseNotes($userId: StrictId!) {
    getLatestUnreadReleaseNotes(userId: $userId) {
      id
      title
      pages {
        title
        description
        media {
          type
          data
        }
      }
      userType
      date
      expiration
      hasViewed
    }
  }
`;
