import { Injectable } from '@angular/core';
import { LocationsServices, NotificationsService } from '@haulynx/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { DispatchAction } from '../../shared/store/helpers/action';
import { FavoriteAddressActions, FavoriteAddressActionTypes } from './favorite-address.actions';

@Injectable({
  providedIn: 'root',
})
export class FavoriteAddressEffects {
  favoriteAddressSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoriteAddressActionTypes.SEARCH),
      map((action: DispatchAction) => action.payload),
      switchMap((filter) =>
        this.locationsServices.getFavoriteLocation(filter).pipe(
          map((entities) => FavoriteAddressActions.searchSuccess({ entities })),
          catchError((err) => {
            this.notificationsService.error(err, 'Favorite Address');
            return of(FavoriteAddressActions.searchError(err));
          })
        )
      )
    )
  );

  creatFavoriteAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FavoriteAddressActionTypes.CREATE_ADDRESS),
      map((action: DispatchAction) => action.payload),
      switchMap((address) =>
        this.locationsServices.createFavoriteAddress(address).pipe(
          map((newAddress) => {
            this.notificationsService.success('Address successfully added', 'Favorite Address');
            return FavoriteAddressActions.createAddressSuccess(newAddress);
          }),
          catchError((err) => {
            this.notificationsService.error(err, 'Favorite Address');

            return of(FavoriteAddressActions.createAddressError(err));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private notificationsService: NotificationsService,
    private locationsServices: LocationsServices
  ) {}
}
