import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FavoriteAddressEffects } from './favorite-address.effects';
import { favoriteAddressReducer } from './favorite-address.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('favoriteAddress', favoriteAddressReducer),
    EffectsModule.forFeature([FavoriteAddressEffects]),
  ],
})
export class FavoriteAddressStoreModule {}
