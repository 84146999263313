import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@haulynx/modules';
import { LoadActivityDownloadComponent } from './load-activity-download/load-activity-download.component';

@NgModule({
  declarations: [LoadActivityDownloadComponent],
  imports: [CommonModule, MaterialModule],
  exports: [LoadActivityDownloadComponent],
})
export class LoadActivityItemsModule {}
