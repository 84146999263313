import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { PermissionsService } from '@haulynx/services';
import { LoadAvailableTabsModel } from '@haulynx/store';
import { Tab } from '@haulynx/types';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';

@Injectable()
export class LoadAvailableTabGuard implements CanActivate {
  constructor(
    private loadAvailableTabsModel: LoadAvailableTabsModel,
    private permissionsService: PermissionsService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.permissionsService.isOnlyADriver()) return this.router.parseUrl('/dashboard/loads/search/active');
    let viewTabs = [];
    const { tabs = null } = route.data;

    if (tabs) {
      const result = _.reduce(
        tabs,
        (accumulator, tab, key) => {
          accumulator.tabs.push(new Tab(tab));

          return accumulator;
        },
        { tabs: [] }
      );

      viewTabs = [...viewTabs, ...result.tabs];
    }

    if (viewTabs.length) {
      this.loadAvailableTabsModel.addTabs(viewTabs);
    }

    return of(true);
  }
}
