import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TabsModule } from '@haulynx/components';
import { ActiveLoadsStateModule, LoadDetailsStateModule } from '@haulynx/store';
import { ActiveLoadsContainerRoutingModule } from './active-loads-container-routing.module';
import { ActiveLoadsContainerComponent } from './active-loads-container.component';

@NgModule({
  declarations: [ActiveLoadsContainerComponent],
  imports: [
    CommonModule,
    ActiveLoadsContainerRoutingModule,
    TabsModule,
    ActiveLoadsStateModule,
    LoadDetailsStateModule,
  ],
})
export class ActiveLoadsContainerModule {}
