import { ANALYTICS_EVENT } from '../analytics/event-types';

export interface HeaderItem {
  name: string; // menu item display label
  link?: string; // url
  icon?: string; // material icon key
  svgIcon?: string;
  selected?: boolean;
  accountItem?: boolean;
  termsOrPrivacyItem?: boolean;
  children?: HeaderItem[];
  stopNavigationOnClick?: boolean;
  analyticsEvent?: ANALYTICS_EVENT;
  queryParams?: {
    [key: string]: any;
  };
}
