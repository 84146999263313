import { Trailer } from '@haulynx/types';
import { Action } from '@ngrx/store';
import { createGenericActionsAdapter } from '../../shared/store/generic-actions/adapter';
import { combineAdapters } from '../../shared/store/helpers/adapter';
import { createSearchAdapter, SearchAdapter } from '../../shared/store/search/adapter';

export const TYPE = '[FLEET]';

const fleetState = {
  searchFleetTrailerRecords: createSearchAdapter,
  actions: createGenericActionsAdapter,
};

export interface IFleetStateAdapter {
  searchFleetTrailerRecords: SearchAdapter<Trailer>;
  actions: Action;
}

export const fleetAdaptor: IFleetStateAdapter = combineAdapters<IFleetStateAdapter>(fleetState, TYPE);
