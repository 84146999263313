import { AppointmentNotSetComponent } from './appointment-not-set.component';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@haulynx/modules';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [AppointmentNotSetComponent],
  imports: [CommonModule, MaterialModule],
  exports: [AppointmentNotSetComponent],
})
export class AppointmentNotSetModule {}
