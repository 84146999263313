import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AdvancedSearchModule } from '../advanced-search/advanced-search.module';
import { DataTableV2Module } from '../data-table-v2/data-table-v2.module';
import { SwapTrailerComponent } from './swap-trailer.component';
import { MaterialModule } from '@haulynx/modules';
import { TruckDropDownModule } from '../truck-drop-down/truck-drop-down.module';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { DropDownModule } from '../drop-down/drop-down.module';

@NgModule({
  exports: [SwapTrailerComponent],
  declarations: [SwapTrailerComponent],
  imports: [
    CommonModule,
    MatInputModule,
    ReactiveFormsModule,
    MaterialModule,
    AdvancedSearchModule,
    DataTableV2Module,
    TruckDropDownModule,
    DropDownModule,
  ],
})
export class SwapTrailerModule {}
