import { Pipe, PipeTransform } from '@angular/core';
import { AddressPosition, splitAddress } from '@haulynx/utils';

@Pipe({ name: 'address' })
export class AddressPipe implements PipeTransform {
  constructor() {}

  transform(fullAddress: string, position: AddressPosition, isCapitalized = false): any {
    return splitAddress(fullAddress, position, isCapitalized);
  }
}
