import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AddressFavoriteComponent, AddressFavoriteFormComponent } from '@haulynx/components';
import {
  AnalyticsService,
  ConfirmationComponent,
  GoogleAddressService,
  LoadForm,
  LoadLocationForm,
  LoadVm,
  NotificationsService,
  PageTitleService,
  PermissionsService,
} from '@haulynx/services';
import {
  AppModel,
  CommonEntities,
  LoadActiveTabsModel,
  LoadDetailsModel,
  LoadEntityService,
  LoadModel,
} from '@haulynx/store';
import {
  ActionButtonsTypes,
  ANALYTICS_EVENT,
  buttonTypes,
  Carrier,
  Device,
  DistributionMechanism,
  equipmentTypes,
  LoadLocationType,
  LoadsServiceLoad,
  LoadsServiceLoadInput,
  Network,
  StateDictionary,
  states,
  Tab,
  Trailer,
  User,
} from '@haulynx/types';
import { aliveWhile, positiveNumbers } from '@haulynx/utils';
import { List } from 'immutable';
import { forEach, get, reduce, split, trim, truncate } from 'lodash';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Location } from '@angular/common';

@Component({
  selector: 'app-load-form',
  templateUrl: './load-form.component.html',
  styleUrls: ['./load-form.component.scss'],
})
export class LoadFormComponent implements OnDestroy, OnInit {
  alive = aliveWhile();
  loadForm: FormGroup;
  user: User;
  load: LoadsServiceLoad = new LoadsServiceLoad();
  buttons$ = new BehaviorSubject([]);
  disableBtns$ = new BehaviorSubject([]);
  equipmentTypes = equipmentTypes;
  showTemplateChoose = false;
  key = null;
  showWaitingTime = {};
  showLocationMoreDetails = {};
  showMoreInfoAdvanced = false;
  private timeZone: string = null;
  // Todo: is only for save full entitiy, in the feature need to call from api
  drivers: List<User> = List();
  devices: List<Device> = List();
  trailers: List<Trailer> = List();
  network: List<Network> = List();
  devices$ = new BehaviorSubject(List());
  private isTemplate = false;
  private actionButtonsTypes: ActionButtonsTypes = buttonTypes;
  get locationArray(): FormArray {
    return this.loadForm.get('locations') as FormArray;
  }

  get locations(): FormGroup[] {
    return this.locationArray.controls as FormGroup[];
  }

  constructor(
    public loadDetailsModel: LoadDetailsModel,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    public loadModel: LoadModel,
    public commonEntities: CommonEntities,
    public loadVm: LoadVm,
    private notificationService: NotificationsService,
    private appModel: AppModel,
    private loadEntityService: LoadEntityService,
    private googleAddressService: GoogleAddressService,
    private loadActiveTabsModel: LoadActiveTabsModel,
    private changeDetectorRef: ChangeDetectorRef,
    private permissionService: PermissionsService,
    private analytics: AnalyticsService,
    private pageTitleService: PageTitleService,
    private router: Router,
    private location: Location
  ) {
    this.pageTitleService.setPageTitle('Create Load');
  }

  selectTemplate(load = null): void {
    this.showTemplateChoose = false;
    this.isTemplate = true;
    this.loadModel.selectTemplate({ key: this.key, state: load });
  }

  selectLocation(): void {
    // Todo: investigate why location control not check when the value is changes
    this.changeDetectorRef.detectChanges();
  }

  showMoreInfo(newValue: boolean): void {
    this.showMoreInfoAdvanced = newValue;
  }

  showAdditionalInfo(index = null): void {
    if (this.showLocationMoreDetails[index] === true) {
      delete this.showLocationMoreDetails[index];
    } else {
      this.showLocationMoreDetails = { ...this.showLocationMoreDetails, [index]: true };
    }
  }

  addStop(index: number): void {
    const data = this.locationArray.at(index).value;
    const newLocationGroup = this.loadVm.createLocation({
      ...data,
      locationType: LoadLocationType.DROPOFF,
      location: { timeZone: this.timeZone, id: null, address: null, lat: null, lon: null },
    });
    this.locationArray.insert(index + 1, newLocationGroup);
    this.showWaitingTime = this.addStopToDetailsOrWaitTime(this.showWaitingTime, index + 1);
    this.showLocationMoreDetails = this.addStopToDetailsOrWaitTime(this.showLocationMoreDetails, index + 1);
  }

  removeStop(index: number): void {
    this.dialog
      .open(ConfirmationComponent, {
        data: {
          title: 'Load location',
          message: 'Delete location ?',
        },
      })
      .afterClosed()
      .pipe(takeUntil(this.alive))
      .subscribe((res) => {
        if (res) {
          this.removeLocationAt(index);
          this.showWaitingTime = this.remeoveStopFromDetailsOrWaitTime(this.showWaitingTime, index);
          this.showLocationMoreDetails = this.remeoveStopFromDetailsOrWaitTime(this.showLocationMoreDetails, index);
        }
      });
  }

  saveFavoriteAddress(location: LoadLocationForm, index: number): void {
    this.dialog
      .open(AddressFavoriteFormComponent, {
        data: {
          ...location.location,
          customer: location.customerId,
          notes: location.specialNotes,
        },
      })
      .afterClosed()
      .pipe(takeUntil(this.alive))
      .subscribe((newAddress) => {
        if (newAddress) {
          this.locations[index].patchValue({ location: newAddress });
        }
      });
  }

  choseFavoriteAddress(index: number): void {
    const dialog = this.dialog.open(AddressFavoriteComponent);

    dialog
      .afterClosed()
      .pipe(takeUntil(this.alive))
      .subscribe((address) => {
        if (address) {
          const location = this.locationArray.at(index);
          const locationData = {
            location: {
              lat: address.lat,
              lon: address.lon,
              address: address.address,
              timeZone: address.timeZone,
              id: address.id,
            },
            specialNotes: address.notes,
            customerId: address.customer,
          };

          location.patchValue(locationData);
        }
      });
  }

  setCurrentUserLocation(index: number): void {
    this.googleAddressService
      .getCurrentPosition()
      .pipe(takeUntil(this.alive))
      .subscribe((userLocation) => {
        this.locations[index].patchValue({ location: userLocation });
      });
  }

  addWaitingTime(index: number): void {
    const location = this.locationArray.at(index);
    const date = location.get('date').value;

    if (!location.get('waitDate').value) location.get('waitDate').setValue(date);
    this.showWaitingTime[index] = true;
  }

  removeWaitingTime(index: number): void {
    this.locationArray.at(index).get('waitDate').reset();
    delete this.showWaitingTime[index];
  }

  action(action: string): void {
    if (action === this.actionButtonsTypes.CANCEL.action) {
      this.location.back();
    }

    if (action === this.actionButtonsTypes.CREATE_LOAD.action || action === this.actionButtonsTypes.UPDATE.action) {
      if (this.loadForm.invalid) {
        this.loadVm.setValidity(this.loadForm);
        this.notificationService.error('The load form is incomplete, please fill all required fields', 'Load');
      } else {
        this.continueSaving(this.loadForm.getRawValue());
      }
    }

    if (action === this.actionButtonsTypes.SAVE_TEMPLATE.action) {
      const load = this.loadForm.getRawValue();
      const selectedEntities = this.selectedEntities(
        load,
        this.user,
        this.network,
        this.drivers,
        this.devices,
        this.trailers
      );

      this.loadDetailsModel.saveTemplate({
        key: this.key,
        load: load,
        selectedEntities,
      });
    }

    if (action === this.actionButtonsTypes.DELETE.action) {
      this.dialog
        .open(ConfirmationComponent, {
          data: {
            title: 'Load',
            message: 'Delete the load?',
          },
        })
        .afterClosed()
        .pipe(takeUntil(this.alive))
        .subscribe((res: boolean) => {
          if (res) {
            this.loadEntityService.deleteLoadManager.dispatch(this.key, { loadId: this.key });
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }

  ngOnInit(): void {
    this.appModel.user$.pipe(takeUntil(this.alive)).subscribe((user) => {
      this.user = user;
      const carrierId = get(this.user, 'carrier.id', null) || get(this.load, 'carrier.id');
      const query = { carrierId };

      this.commonEntities.templates.search({
        key: 'new-load',
        query: { userId: user?.id },
      });
      this.commonEntities.graphQlDrivers.search({
        key: 'load-driver',
        query,
      });
      this.commonEntities.graphQlTrailers.search({
        key: 'new-trailer',
        query,
      });
      this.commonEntities.graphQlDevices.search({
        key: 'load-device',
        query,
      });
      this.commonEntities.graphQlTrucks.search({
        key: 'load-device',
        query,
      });
    });
    this.appModel.userCarrier$.pipe(takeUntil(this.alive)).subscribe((carrier: Carrier) => {
      this.timeZone = carrier?.timeZone;
    });
    this.loadForm = this.loadVm.create(null, this.timeZone);
    this.loadForm.statusChanges.pipe(takeUntil(this.alive)).subscribe((status) => {
      if (status === 'INVALID') {
        this.disableBtns$.next([this.actionButtonsTypes.UPDATE, this.actionButtonsTypes.CREATE_LOAD]);
      } else {
        this.disableBtns$.next([]);
      }
    });

    this.commonEntities.graphQlDrivers.entities$
      .pipe(
        map((drivers: List<User>) => drivers.insert(0, { name: '', id: null } as User)),
        takeUntil(this.alive)
      )
      .subscribe((drivers) => {
        this.drivers = drivers;
      });

    combineLatest([this.commonEntities.graphQlTrucks.entities$, this.commonEntities.graphQlDevices.entities$])
      .pipe(takeUntil(this.alive))
      .subscribe(([trucks, devices]) => {
        const newTrucks = trucks;

        const newDevices = devices.filter((device) => device.type === 'phone');

        const dropDownDevice = List()
          .merge(newTrucks, newDevices)
          .map((device: Device) => {
            let label = '';

            if (device && device.type === 'phone') {
              if (device.lastLocation) {
                label = `${device.lastLocation.driverName}'s Phone`;
              } else {
                label = device.unitId || 'Phone';
              }
            } else if (device) {
              label = `Unit # ${device.unitId || device.lastLocation || 'N/A'}`;
            }

            return { id: device.id, label };
          });

        this.devices = List().merge(newTrucks, newDevices);
        this.devices$.next(dropDownDevice);
      });

    this.commonEntities.graphQlTrailers.entities$.pipe(takeUntil(this.alive)).subscribe((trailer) => {
      this.trailers = trailer.filter((filterTrailer) => !filterTrailer.inactive);
    });
    this.commonEntities.network.entities$.pipe(takeUntil(this.alive)).subscribe((network) => {
      this.network = network;
    });

    this.loadEntityService.deleteLoadManager.onSuccess$
      .pipe(
        withLatestFrom(this.loadEntityService.getCarrierLoadsManager.searchQuery$, this.appModel.userCarrier$),
        takeUntil(this.alive)
      )
      .subscribe(([deletedEntity, query, carrier]) => {
        if (deletedEntity) {
          if (query.payload?.dot || carrier?.dot) {
            this.loadEntityService.getCarrierLoadsManager.dispatch({
              query: {
                ...(query?.payload || {}),
                dot: query.payload?.dot || carrier?.dot,
              },
              pageAndSort: { page: query?.pageAndSort?.page || 1, limit: query?.pageAndSort?.limit | 200 },
            });
          }

          this.router.navigate([`/loads/`], { queryParams: { list: 'active' } });
        }
      });

    this.loadEntityService.createLoadManager.onResponse$.pipe(takeUntil(this.alive)).subscribe((res) => {
      this.router.navigate([`/loads/`], { queryParams: { load: res, list: 'active' } });
    });
    this.loadEntityService.editLoadManager.onSuccess$.pipe(takeUntil(this.alive)).subscribe((res) => {
      this.router.navigate([`/loads/`], { queryParams: { load: res?.id, list: 'active' } });
    });

    this.loadForm
      .get('price')
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.alive))
      .subscribe((val) => {
        this.loadForm.patchValue({ price: positiveNumbers(val) });
      });

    this.loadForm
      .get('quantity')
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.alive))
      .subscribe((val) => {
        this.loadForm.patchValue({ quantity: positiveNumbers(val) });
      });

    this.loadForm
      .get('trailerId')
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.alive))
      .subscribe((val) => {
        const trailer = this.trailers.find((trailer: Trailer) => trailer.id === val);
        this.loadForm.patchValue({ trailer });
      });

    this.loadForm
      .get('loadWeight')
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.alive))
      .subscribe((val) => {
        this.loadForm.patchValue({ loadWeight: positiveNumbers(val) });
      });

    this.loadForm
      .get('equipmentType')
      .valueChanges.pipe(takeUntil(this.alive))
      .subscribe((equipmentType) => {
        if (equipmentType !== 'Reefer') {
          this.loadForm.patchValue({ temperature: null });
        }
      });

    this.loadForm
      .get('distributionMechanism')
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.alive))
      .subscribe((distributionMechanism) => {
        if (this.user.broker || this.user.shipper) {
          if (distributionMechanism === DistributionMechanism.manual) {
            const query = { type: 'network' };

            this.commonEntities.network.search({ key: 'load-network', query });
          }

          if (distributionMechanism !== DistributionMechanism.manual) {
            this.loadForm.patchValue({
              carrier: null,
            });
          }

          if (distributionMechanism !== DistributionMechanism.auto) {
            this.loadForm.patchValue({
              carrierSafetyRating: null,
              cargoLiability: 0,
              autoLiability: 0,
              hazmatCapabilityRequired: null,
            });
          }
        }
      });

    this.loadForm
      .get('driver')
      .valueChanges.pipe(takeUntil(this.alive))
      .subscribe((driver) => {
        if (!driver) {
          this.loadForm.patchValue({
            truck: null,
            trailerId: null,
            trailer: null,
            distributionMechanism: DistributionMechanism.none,
          });
        } else {
          this.loadForm.patchValue({
            distributionMechanism: DistributionMechanism.manual,
          });
        }
      });

    this.activatedRoute.params.pipe(takeUntil(this.alive)).subscribe((params) => {
      const { loadId = null } = params;

      this.key = loadId;
      this.loadDetailsModel.setStateKey(loadId);
      // Todo: this care when user try to get full load information from api by id
      this.loadDetailsModel.setFormMode({ key: this.key, mode: loadId === 'new' ? 'new' : 'edit' });
      this.loadDetailsModel.get({ key: loadId, id: loadId });
      this.showTemplateChoose = loadId === 'new';

      if (loadId === 'new') {
        this.buttons$.next([
          this.actionButtonsTypes.CANCEL,
          this.actionButtonsTypes.SAVE_TEMPLATE,
          this.actionButtonsTypes.CREATE_LOAD,
        ]);
      } else {
        this.buttons$.next([
          this.actionButtonsTypes.CANCEL,
          this.actionButtonsTypes.DELETE,
          this.actionButtonsTypes.UPDATE,
        ]);
      }
    });

    this.loadDetailsModel.form.state$
      .pipe(withLatestFrom(this.appModel.user$), takeUntil(this.alive))
      .subscribe(([state, user]: [LoadsServiceLoad, User]) => {
        const load = state || new LoadsServiceLoad();
        const { id = null } = load;
        this.updateTabs(id, load);

        this.load = load;
        const formData = this.loadVm.fromDto(load, user);
        /**
         * if user is only a driver, shouldn't be able to
         * assign other drivers to new load or template
         */
        if (this.permissionService.isOnlyADriver()) {
          this.assignUserAsOnlyDriver(user);
          formData.driver = user.id;
        }
        /**
         * if user is creator of template and is not isCompanyAdmin
         * shouldn't be allowed to assign other drivers.
         */
        if (formData.creator === user.id && !this.permissionService.isCompany()) {
          this.assignUserAsOnlyDriver(user);
          formData.driver = user.id;
        }

        this.updateLoadForm(formData);
      });

    this.loadDetailsModel.form.isLoading$.pipe(takeUntil(this.alive)).subscribe((isLoading) => {
      this.disableBtns$.next(isLoading ? [this.actionButtonsTypes.UPDATE, this.actionButtonsTypes.CREATE_LOAD] : []);
    });
  }

  /**
   * addStopToDetailsOrWaitTime takes either showWaitingTime or showLocationMoreDetails
   * and adds a new stop adding 1 to existing numeric property names.
   * @param obj
   * @param index
   * @returns object
   */
  private addStopToDetailsOrWaitTime(obj: object, index: number): object {
    const newObj = {};
    for (const item in obj) {
      const num = Number(item);
      if (num === 0 && obj[num] === true) newObj[num] = obj[num];
      if (obj[num] === true && num >= index) newObj[num + 1] = obj[num];
    }
    return newObj;
  }

  /**
   * remeoveStopFromDetailsOrWaitTime takes either showWaitingTime or showLocationMoreDetails
   * and removes a stop subtracting 1 to existing numeric property names.
   * @param obj
   * @param index
   * @returns object
   */
  private remeoveStopFromDetailsOrWaitTime(obj: object, index: number): object {
    const newObj = { ...obj };
    delete newObj[index];
    for (const item in newObj) {
      const num = Number(item);
      if (num > 0 && index < num && newObj[num] === true) {
        newObj[num - 1] = newObj[num];
        delete newObj[num];
      }
    }
    return newObj;
  }

  private continueSaving(load: LoadForm): void {
    const selectedEntities = this.selectedEntities(
      load,
      this.user,
      this.network,
      this.drivers,
      this.devices,
      this.trailers
    );
    if (load.id === null) {
      const newLoad = this.loadVm.toDto(this.load, load, this.user, selectedEntities);
      if (newLoad['createdBy']) {
        delete newLoad['createdBy'];
      }
      this.loadEntityService.createLoadManager.dispatch(this.key, { loadInput: newLoad });
      this.updateTabs(load.id, load);
      this.updateLoadActiveDetailTab(load.id, load);
    } else {
      const newLoad = this.loadVm.toDto(this.load, load, this.user, selectedEntities);
      const loadId: string = load?.id ?? null;

      if (newLoad['carrierBid']) {
        delete newLoad['carrierBid'];
      }
      if (newLoad['createdBy']) {
        delete newLoad['createdBy'];
      }
      if (newLoad['trackingStatus']) {
        delete newLoad['trackingStatus'];
      }
      if (newLoad['milestones']) {
        delete newLoad['milestones'];
      }

      Object.keys(newLoad).forEach((key) => {
        if (!newLoad[key] || (Array.isArray(newLoad[key]) && !newLoad[key]?.length)) {
          delete newLoad[key];
        }
      });
      this.loadEntityService.editLoadManager.dispatch(loadId, {
        loadId,
        input: newLoad,
      });
    }
  }

  private updateLoadForm(formData: LoadForm) {
    let buttons = [];

    if (formData.id) {
      buttons = [this.actionButtonsTypes.CANCEL, this.actionButtonsTypes.DELETE, this.actionButtonsTypes.UPDATE];
    } else {
      buttons = [
        this.actionButtonsTypes.CANCEL,
        this.actionButtonsTypes.SAVE_TEMPLATE,
        this.actionButtonsTypes.CREATE_LOAD,
      ];
    }

    this.buttons$.next(buttons);
    const locationGroupArray = this.loadVm.createLocations(formData.locations) || new FormArray([]);

    if (this.locationArray.length !== locationGroupArray.length) {
      if (locationGroupArray.length > this.locationArray.length) {
        forEach(locationGroupArray.controls, (newControl, index) => {
          if (!this.locationArray.at(index)) {
            this.locationArray.insert(index, newControl);
            this.analytics.logEvent(ANALYTICS_EVENT.LOAD_ADD_STOP, this.load.id);
          }
        });
      } else {
        forEach(this.locationArray.controls, (newControl, index) => {
          if (!locationGroupArray.at(index)) {
            this.locationArray.removeAt(index);
          } else {
            this.locationArray.at(index).patchValue(locationGroupArray.at(index).value);
          }
        });
      }
    }

    this.loadForm.patchValue(formData);
    if (formData.locations.length && this.isTemplate) this.loadForm.patchValue({ locations: locationGroupArray.value });

    if (this?.locationArray) this.setShowWaitTime(this.locationArray);

    const isDisabledSubmit = this.loadForm.invalid || this.user.isHaulynxAdmin;

    this.disableBtns$.next(
      isDisabledSubmit ? [this.actionButtonsTypes.UPDATE, this.actionButtonsTypes.CREATE_LOAD] : []
    );
  }

  /**
   * setShowWaitTime displays "Late Date" for locations with a waitDate.
   * @param locations
   */
  private setShowWaitTime(locations: FormArray): void {
    locations?.value.forEach((location, index) => {
      if (location?.waitDate && location?.waitDate !== location?.date) this.showWaitingTime[index] = true;
    });
  }

  // Todo: need to move in separate service this function
  private selectedEntities(
    load: LoadForm,
    user: User,
    network: List<Network>,
    drivers: List<User>,
    devices: List<Device>,
    trailers: List<Trailer>
  ) {
    const selectedEntities = {};

    if (!load.id && user.carrier) {
      selectedEntities[load.carrier] = user.carrier;
    } else if (!load.id && user.broker) {
      selectedEntities[load.broker] = user.broker;
    }

    if (load.carrier) {
      const carrier = network.find((item) => item.company.id === load.carrier);

      selectedEntities[load.carrier] = (carrier && carrier.company) || null;
    }

    if (user.carrier) {
      selectedEntities[user.carrier.id] = user.carrier;
    }

    if (user.broker) {
      selectedEntities[user.broker.id] = user.broker;
    }

    selectedEntities[load.driver] = drivers.find((item) => item.id === load.driver);
    selectedEntities[load.truck] = devices.find((item) => item.id === load.truck);
    selectedEntities[load.trailer?.id] = trailers.find((item) => item.id === load.trailer?.id);

    return selectedEntities;
  }
  private removeLocationAt(index: number) {
    const location = this.locationArray.at(index);
    const locationType = reduce(
      this.locations,
      ({ pickup, dropoff }, control: AbstractControl) => {
        if (control.get('locationType').value === LoadLocationType.PICKUP) {
          pickup++;
        }
        if (control.get('locationType').value === LoadLocationType.DROPOFF) {
          dropoff++;
        }

        return { pickup, dropoff };
      },
      { pickup: 0, dropoff: 0 }
    );

    if (location.get('locationType').value === LoadLocationType.PICKUP && locationType.pickup > 1) {
      this.locationArray.removeAt(index);
    }

    if (location.get('locationType').value === LoadLocationType.DROPOFF && locationType.dropoff > 1) {
      this.locationArray.removeAt(index);
    }
  }

  private updateTabs(id: string, load: LoadsServiceLoad | LoadForm): void {
    if (id) {
      const [pickUp, dropOff] = load.locations;

      const tab = new Tab({
        id: `${id}`,
        label: `${truncate(get(pickUp, 'location.address', '') || get(pickUp, 'address', ''), {
          length: 10,
        })} -> ${truncate(get(dropOff, 'location.address', '') || get(dropOff, 'address', ''), { length: 10 })}`,
        url: `view/${id}`,
        order: null,
        selected: true,
        closable: true,
      });
      this.loadModel.updateTabs([tab]);
    } else {
      const tab = new Tab({
        id: 'new',
        label: 'New Load',
        url: 'view/new',
        selected: true,
        closable: true,
      });

      this.loadModel.updateTabs([tab]);
    }
  }

  private assignUserAsOnlyDriver(user: User): void {
    this.drivers = List([user]);
  }

  private updateLoadActiveDetailTab(id: string, load: LoadsServiceLoad | LoadForm): void {
    const [pickUp, dropOff] = load.locations;
    const tab = new Tab({
      id: `${id}`,
      label: `${truncate(get(pickUp, 'location.address', '') || get(pickUp, 'address', ''), {
        length: 10,
      })} -> ${truncate(get(dropOff, 'location.address', '') || get(dropOff, 'address', ''), { length: 10 })}`,
      url: `details/${id}`,
      order: null,
      selected: true,
      closable: true,
    });

    this.loadActiveTabsModel.updateTabs([tab]);
  }
}
