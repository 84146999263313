import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

const loadsServiceQuery = `
  query GetRecommendedCarriersByLoadId($loadId: String!, $paging: PageAndSort) {
    getRecommendedCarriersByLoadId(loadId: $loadId, paging: $paging) {
      total
      result {
        id
        name
        sourceType
        dot
        brokerId
        email
        owner
        price
        type
        matchPercent
        explanation {
          type
          similarLoadId
        }
        load {
          id
          bookStatus
          locations {
            customer {
              number
              id
              name
              customerAttributes {
                canPickUpEarly
                canDeliverEarly
                dropYard
                canBreakAtFacility
                hasFacilities
              }
            }
            locationType
            appointmentSet
            timezone
            appointmentStart
            appointmentEnd
            carrierDeparture
            carrierArrival
            address
          }
          broker {
            id
          }
          carrier {
            id
            phone
            dot
          }
          paymentDetails {
            price
            distanceMiles
          }
        }
      }
    }
  }
`;

const dataBridgeQuery = `
  query GetRecommendedCarriersByLoadId($loadId: String!, $paging: PageAndSort) {
    getRecommendedCarriersByLoadId(loadId: $loadId, paging: $paging) {
      total
      result {
        id
        name
        sourceType
        dot
        brokerId
        email
        owner
        price
        type
        matchPercent
        explanation {
          type
          similarLoadId
        }
        load {
          id
          bookStatus
          locations {
            customer {
              number
              id
              name
              customerAttributes {
                canPickUpEarly
                canDeliverEarly
                dropYard
                canBreakAtFacility
                hasFacilities
              }
            }
            locationType
            appointmentSet
            timezone
            geometry {
              type
              coordinates
            }
            appointmentStart
            appointmentEnd
            carrierDeparture
            carrierArrival
            address
          }
          broker {
            id
          }
          carrier {
            id
            name
            dot
          }
          paymentDetails {
            price
            distanceMiles
          }
          providerDetails {
            alternateIds {
              identifierType
              identifierValue
            }
            equipmentType
          }
        }
      }
    }
  }
`;

export const GetRecommendedCarriersByLoadId = (useDataBridge = true): DocumentNode =>
  useDataBridge ? gql(dataBridgeQuery) : gql(loadsServiceQuery);
