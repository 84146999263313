import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { DirectivesModule } from '@haulynx/directives';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { AsyncEntityMissionStoreModule } from '@haulynx/store';
import { CreateMissionComponent } from './create-mission.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    MaterialModule,
    DirectivesModule,
    AsyncEntityMissionStoreModule,
    PipesModule,
  ],
  declarations: [CreateMissionComponent],
  exports: [CreateMissionComponent],
})
export class CreateMissionModule {}
