import { CarrierManagement, SearchParams } from '@haulynx/types';
import { createAction, createActionTypes } from '../../shared/store/helpers/action';
import { TYPE } from './adapters';

export interface ICarrierManagementActions {
  setContacted(params: CarrierManagement);
  setContactedSuccess(params?);
  setContactedError(params?);

  searchTop(params: SearchParams);
  searchTopSuccess(params: { entities: any[]; total?: number });
  searchTopError(params?);

  searchDeveloping(params: SearchParams);
  searchDevelopingSuccess(params: { entities: any[]; total?: number });
  searchDevelopingError(params?);

  searchProspects(params: SearchParams);
  searchProspectsSuccess(params: { entities: any[]; total?: number });
  searchProspectsError(params?);

  delete(params: string);
  deleteSuccess(params?);
  deleteError(params?);
}

export interface ICarrierManagementActionTypes {
  SET_CONTACTED: string;
  SET_CONTACTED_SUCCESS: string;
  SET_CONTACTED_ERROR: string;

  SEARCH_TOP: string;
  SEARCH_TOP_SUCCESS: string;
  SEARCH_TOP_ERROR: string;

  SEARCH_DEVELOPING: string;
  SEARCH_DEVELOPING_SUCCESS: string;
  SEARCH_DEVELOPING_ERROR: string;

  SEARCH_PROSPECTS: string;
  SEARCH_PROSPECTS_SUCCESS: string;
  SEARCH_PROSPECTS_ERROR: string;

  DELETE: string;
  DELETE_SUCCESS: string;
  DELETE_ERROR: string;
}

export const carrierManagementActionTypes: Partial<ICarrierManagementActionTypes> = {
  SET_CONTACTED: 'set contacted',
  SET_CONTACTED_SUCCESS: 'set contacted success',
  SET_CONTACTED_ERROR: 'set contacted error',

  SEARCH_TOP: 'search top',
  SEARCH_TOP_SUCCESS: 'search top success',
  SEARCH_TOP_ERROR: 'search top error',

  SEARCH_DEVELOPING: 'search developing',
  SEARCH_DEVELOPING_SUCCESS: 'search developing success',
  SEARCH_DEVELOPING_ERROR: 'search developing error',

  SEARCH_PROSPECTS: 'search prospects',
  SEARCH_PROSPECTS_SUCCESS: 'search prospects success',
  SEARCH_PROSPECTS_ERROR: 'search prospects error',

  DELETE: 'delete',
  DELETE_SUCCESS: 'delete success',
  DELETE_ERROR: 'delete error',
};

export const carrierManagementStateActions: ICarrierManagementActions = {
  setContacted: createAction(TYPE, carrierManagementActionTypes.SET_CONTACTED),
  setContactedSuccess: createAction(TYPE, carrierManagementActionTypes.SET_CONTACTED_SUCCESS),
  setContactedError: createAction(TYPE, carrierManagementActionTypes.SET_CONTACTED_ERROR),

  searchTop: createAction(TYPE, carrierManagementActionTypes.SEARCH_TOP),
  searchTopSuccess: createAction(TYPE, carrierManagementActionTypes.SEARCH_TOP_SUCCESS),
  searchTopError: createAction(TYPE, carrierManagementActionTypes.SEARCH_TOP_ERROR),

  searchDeveloping: createAction(TYPE, carrierManagementActionTypes.SEARCH_DEVELOPING),
  searchDevelopingSuccess: createAction(TYPE, carrierManagementActionTypes.SEARCH_DEVELOPING_SUCCESS),
  searchDevelopingError: createAction(TYPE, carrierManagementActionTypes.SEARCH_DEVELOPING_ERROR),

  searchProspects: createAction(TYPE, carrierManagementActionTypes.SEARCH_PROSPECTS),
  searchProspectsSuccess: createAction(TYPE, carrierManagementActionTypes.SEARCH_PROSPECTS_SUCCESS),
  searchProspectsError: createAction(TYPE, carrierManagementActionTypes.SEARCH_PROSPECTS_ERROR),

  delete: createAction(TYPE, carrierManagementActionTypes.DELETE),
  deleteSuccess: createAction(TYPE, carrierManagementActionTypes.DELETE_SUCCESS),
  deleteError: createAction(TYPE, carrierManagementActionTypes.DELETE_ERROR),
};

export const CarrierManagementActions: ICarrierManagementActions = carrierManagementStateActions;
export const CarrierManagementActionTypes: ICarrierManagementActionTypes = createActionTypes(
  carrierManagementActionTypes
)(TYPE);
