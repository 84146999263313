import { OrderTypeUSX } from './load-match';
import { LoadsServiceEquipmentType } from './loads-service-provider';

export class LoadsServiceBrokerEditFields {
  price?: number;
  weight?: number;
  commodity?: string;
  brokerId?: string;
  equipmentType?: LoadsServiceEquipmentType;
  trailerInstructions?: LoadsServiceTrailerInstructions;
  orderType?: OrderTypeUSX;
  tankerCertificationRequired?: boolean;
  hazmatRequired?: boolean;
  highValueException?: boolean;
}

export class LoadsServicePickupRequirements {
  livePickup;
  preloaded;
}

export class LoadsServiceDropoffRequirements {
  dropTrailer;
  liveDropoff;
}

export enum LoadsServiceTrailerInstructions {
  LivePickupDropTrailer = 'LivePickupDropTrailer',
  LivePickupLiveDropoff = 'LivePickupLiveDropoff',
  PreloadedDropTrailer = 'PreloadedDropTrailer',
  PreloadedLiveDropoff = 'PreloadedLiveDropoff',
}
