import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@haulynx/modules';
import { DriverToolTipComponent } from './driver-tool-tip.component';

@NgModule({
  declarations: [DriverToolTipComponent],
  imports: [CommonModule, MaterialModule],
  exports: [DriverToolTipComponent],
})
export class DriverToolTipModule {}
