import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ScrollIntoViewDirective } from './app-directives/advanced-search/scroll-into-view.directive';
import { BindQueryParamDirective } from './app-directives/BindQueryParam/bind-query-param.directive';
import { TrimDirective } from './app-directives/trim.directive';
import { DisableDirective } from './disable/disable.directive';
import { FocusIntoViewDirective } from './focus-into-view/focus-into-view.directive';
import { CarouselItemElementDirective } from './shared/carousel/carousel-item-element.directive';
import { CarouselItemDirective } from './shared/carousel/carousel-item.directive';
import { DebounceClickDirective } from './shared/debounce-click/debounce-click.directive';
import { DragDropDirective } from './shared/drag-and-drop-files.directive';
import { ExpandPanelContentDirective } from './shared/expand-panel-content.directive';
import { ExpandPanelLabelDirective } from './shared/expand-panel-label.directive';
import { FeatureDirective } from './shared/feature/feature.directive';
import { KeyboardShortcutDirective } from './shared/keyboard-shortcut/keyboard-shortcut.directive';
import { MsTimeDirective } from './shared/ms-time.directive';
import { PasteDataDirective } from './shared/paste-data.directive';
import { ScrollToFocusDirective } from './shared/scroll-to-focus/scroll-to-focus.directive';
import { TooltipDirective } from './shared/tooltip.directive';
import { UnderlineTextDirective } from './shared/underline-text.directive';

const directives = [
  BindQueryParamDirective,
  CarouselItemDirective,
  CarouselItemElementDirective,
  DebounceClickDirective,
  DisableDirective,
  DragDropDirective,
  ExpandPanelContentDirective,
  ExpandPanelLabelDirective,
  FeatureDirective,
  FocusIntoViewDirective,
  KeyboardShortcutDirective,
  MsTimeDirective,
  ScrollIntoViewDirective,
  TooltipDirective,
  TrimDirective,
  UnderlineTextDirective,
  ScrollToFocusDirective,
  PasteDataDirective,
];

@NgModule({
  declarations: [...directives],
  exports: [...directives],
  imports: [CommonModule],
})
export class DirectivesModule {}
