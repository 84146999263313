import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-chip-list',
  templateUrl: './chip-list.component.html',
  styleUrls: ['./chip-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipListComponent implements ControlValueAccessor {
  @Input() data: { label: string; value: any }[] = [];
  @Input() select: any = null;
  @Output() onClick = new EventEmitter<any>();
  propagateChanges: (...args) => {};

  constructor() {}

  execute(selected) {
    this.select = selected;
    this.onClick.emit(selected);
    if (this.propagateChanges) {
      this.propagateChanges(selected);
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChanges = fn;
  }

  registerOnTouched(fn: any): void {}

  writeValue(obj: any): void {
    this.select = obj;
  }
}
