import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'haulynx-header-endbuttons-help',
  templateUrl: './header-endbuttons-help.component.html',
  styleUrls: ['./header-endbuttons-help.component.scss'],
})
export class HeaderEndbuttonsHelpComponent {
  @Output() goToHelp = new EventEmitter();

  constructor() {}

  onGoToHelp(): void {
    this.goToHelp.emit();
  }
}
