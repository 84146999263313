import { Injectable } from '@angular/core';
import { CreateOffer } from '@haulynx/gql';
import { getBrokerIdFromEmail } from '@haulynx/utils';
import { toString } from 'lodash';
import moment from 'moment';
import { GraphqlService } from '../graphql.service';

export interface BookedLoadInput {
  loadId: string;
  carrierDot: string;
  price: string;
  brokerId: string;
  timestamp: string;
}

@Injectable({
  providedIn: 'root',
})
export class CreateBrokerBookedLoad {
  constructor(private graphqlService: GraphqlService) {}

  createOffer(payload: { dot: string; loadId: string; price: number; brokerEmail: string }) {
    const bookedLoad: BookedLoadInput = {
      loadId: payload.loadId,
      carrierDot: toString(payload.dot),
      price: toString(payload.price),
      brokerId: getBrokerIdFromEmail(payload.brokerEmail),
      timestamp: toString(moment().valueOf()),
    };
    return this.graphqlService.mutate({
      mutation: CreateOffer,
      variables: {
        newBookedLoad: bookedLoad,
      },
    });
  }
}
