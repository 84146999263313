import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@haulynx/modules';
import { GreyInputComponent } from './grey-input.component';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  declarations: [GreyInputComponent],
  imports: [CommonModule, MaterialModule, FlexLayoutModule, ReactiveFormsModule, NgxMaskModule.forRoot()],
  exports: [GreyInputComponent],
})
export class GreyInputModule {}
