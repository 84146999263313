import { Broker } from '../broker';
import { Role } from './role';
import { Carrier } from '../carrier';
import { EnrollmentStatus } from './enrollment-status';
import { Shipper } from '../truck';
import { Device } from './device';

export class Prefs {
  emailNotifications = true;
  timeZone = 'America/Phoenix';
  smsNotifications = true;
  mobileNotifications = true;
  dailyNotifications: string[];

  constructor(props?: Partial<Prefs>) {
    Object.assign(this, props);
  }
}

export class UserSignupHeaders {
  Source? = 'organic';
  Medium?: string;
  Campaign?: string;
  EnableCarrierBidding?: string;

  constructor(props?: Partial<UserSignupHeaders>) {
    Object.assign(this, props);
  }
}
export class UserSignup {
  email: string;
  password: string;
  firstName: string;
  lastName: string;

  constructor(props?: Partial<UserSignup>) {
    Object.assign(this, props);
  }
}

export class User {
  id?: string;
  usxId?: string;
  email?: string;
  password?: string;
  currentPassword?: string;
  phone?: string;
  name?: string;
  firstName?: string;
  lastName?: string;
  photoURL?: string;
  disabled?: boolean;
  companyID?: string;
  licenseNumber?: string;
  issuingState?: string;
  issuingCountry?: string;
  vehicle?: Device;
  carrier?: Carrier;
  shipper?: Shipper;
  broker?: Broker;
  isDriver?: boolean;
  isTcEnabled? = false;
  isCarrier?: boolean;
  isShipper?: boolean;
  isElogAdmin?: boolean;
  isCompanyAdmin?: boolean;
  isHaulynxAdmin?: boolean;
  isVerified?: boolean;
  isExempt?: boolean;
  acceptedTos? = false;
  prefs?: Prefs;
  mobileDeviceToken?: string;
  canLoadMe? = false;
  pcEnabled? = false;
  ymEnabled? = false;
  teamDrivingEnabled? = false;
  trailerCreationEnabled? = false;
  emailVerified?: boolean;
  canNextSendVerification?: number;

  // new user service fields
  jobTitle?: string;

  manager?: string;
  team?: string;
  office?: string;
  role?: Role;
  permissions?: string | string[];
  priority?: number;
  enrollmentStatus?: EnrollmentStatus;
  isPersonalConveyanceEnabled?: boolean;
  isYardMovesEnabled?: boolean;
  unregistered?: boolean;

  constructor(props?: Partial<User>) {
    Object.assign(this, props);
  }
}
