import gql from 'graphql-tag';

export const GetCarrierApiCarrierByDot = gql(`
  query GetCarrierApiCarrierByDot($dot: String!) {
    getCarrierApiCarrierByDot(dot: $dot) {
      notes {
        id
        content
        type
        createdAt
        createdAtTs
        createdBy
        updatedAt
        updatedAtTs
        updatedBy
        createdByUser {
            usxId
        }
      }
    }
  }
`);
