import { Carrier } from '../carrier';
import { NeptuneCarrier } from '../gql';

export class DotCheckReponse {
  ghostCarrier: NeptuneCarrier;
  haulynxCarrier: Carrier;
  saferwatchData: any;

  constructor(carrier: Carrier, saferwatchData: any, ghostCarrier: NeptuneCarrier) {
    this.haulynxCarrier = carrier || null;
    this.ghostCarrier = ghostCarrier || null;
    this.saferwatchData = saferwatchData || null;
  }
}
