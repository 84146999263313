import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoadQuickActionsModule, LoadQuickDriverFormModule } from '@haulynx/components';
import { ActiveLoadsQuickActionContainerRoutingModule } from './active-loads-quick-action-container-routing.module';
import { ActiveLoadsQuickActionContainerComponent } from './active-loads-quick-action-container.component';

@NgModule({
  declarations: [ActiveLoadsQuickActionContainerComponent],
  imports: [
    CommonModule,
    ActiveLoadsQuickActionContainerRoutingModule,
    LoadQuickActionsModule,
    LoadQuickDriverFormModule,
  ],
})
export class ActiveLoadsQuickActionContainerModule {}
