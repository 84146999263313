import { Pipe, PipeTransform } from '@angular/core';
import { getBrokerIdFromEmail } from '@haulynx/utils';
import { isEmpty, toLower, trim } from 'lodash';

@Pipe({ name: 'isCarrierOwned' })
export class IsCarrierOwnedPipe implements PipeTransform {
  transform(brokerEmail: string, carrierOwner: string): boolean {
    const brokerId = getBrokerIdFromEmail(brokerEmail);

    if (isEmpty(trim(brokerId)) || isEmpty(trim(carrierOwner))) {
      return false;
    }

    return toLower(brokerId) === toLower(carrierOwner);
  }
}
