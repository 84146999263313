import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { DataTableModule } from '../data-table/data-table.module';
import { LoadPriceModule } from '../load-price/load-price.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { LoadFeedSearchResultsComponent } from './load-feed-search-results.component';

@NgModule({
  declarations: [LoadFeedSearchResultsComponent],
  exports: [LoadFeedSearchResultsComponent],
  imports: [
    CommonModule,
    DataTableModule,
    FlexLayoutModule,
    LoadPriceModule,
    MaterialModule,
    PipesModule,
    SpinnerModule,
  ],
})
export class LoadFeedSearchResultsModule {}
