import { LoadCarrierContact } from '../generic/load-carrier-contact';
import { TrackingType } from '../truck';

export interface LoadsServiceDriverInput {
  id: string;
  phone: string;
  priority?: number;
}

export interface AssignLoadInput {
  trackingType?: TrackingType;
  drivers?: LoadsServiceDriverInput[];
  truckId?: string;
  trailerIds?: string[];
  trailerId?: string;
  phone?: string;
  equipment?: string;
  driverId?: string;
  contacts?: LoadCarrierContact[];
}

export interface AssignLoadPayload {
  loadId: string;
  carrierId: string;
  assignInput: AssignLoadInput;
}

export interface AssignLoadResponse {
  assignLoadsServiceLoad: {
    id: string;
    drivers: [{ id?: string }] | null;
    truck: { id?: string } | null;
    trailers: [{ id?: string }] | null;
  };
}
