export const otherIconsFolder = [
  'missions-icon',
  'at-receiver-icon',
  'at-shipper-icon',
  'at-stop-icon',
  'book-icon',
  'booked-icon',
  'content-copy',
  'crown',
  'dispatched-icon',
  'document-icon',
  'dollar-sign-icon',
  'download-icon',
  'finalled-icon',
  'haulynx-account-clipboard',
  'haulynx-activity-icon',
  'haulynx-add-comment',
  'haulynx-adjust-dollar-amount',
  'haulynx-assign-to',
  'haulynx-awaiting-confirm-white',
  'haulynx-awaiting-confirm',
  'haulynx-awaiting-delivery',
  'haulynx-back-icon',
  'haulynx-bid-accepted',
  'haulynx-bid-auto_rejected',
  'haulynx-bid-icon',
  'haulynx-bid-live',
  'haulynx-bid-no_capacity',
  'haulynx-bid-pending',
  'haulynx-bid-price_hold',
  'haulynx-bid-price_rejected',
  'haulynx-booking',
  'haulynx-bounce',
  'haulynx-box',
  'haulynx-boxed-comment',
  'haulynx-boxed-person',
  'haulynx-boxed-truck',
  'haulynx-calendar-icon',
  'haulynx-calendar',
  'haulynx-car-black',
  'haulynx-car',
  'haulynx-checked-completed',
  'haulynx-checked-late',
  'haulynx-checked-white',
  'haulynx-checked',
  'haulynx-clip',
  'haulynx-clock',
  'haulynx-close',
  'haulynx-cluster',
  'haulynx-crosshair',
  'haulynx-disabled-lock',
  'haulynx-dollar-check-icon',
  'haulynx-download-with-arrow',
  'haulynx-dropdown-details-arrow',
  'haulynx-dropoff',
  'haulynx-edit',
  'haulynx-envelope-red',
  'haulynx-envelope',
  'haulynx-facility-grey',
  'haulynx-forward',
  'haulynx-geo-grey',
  'haulynx-geo',
  'haulynx-go-to-icon',
  'haulynx-gray-message-small',
  'haulynx-gray-truck-small',
  'haulynx-green-tick-small',
  'haulynx-green-tick',
  'haulynx-group-icon',
  'haulynx-hazmat',
  'haulynx-headphones',
  'haulynx-high-value-icon',
  'haulynx-history-icon',
  'haulynx-home',
  'haulynx-info-icon',
  'haulynx-info-outline',
  'haulynx-information-icon',
  'haulynx-lightbulb',
  'haulynx-list-circle',
  'haulynx-load-accepted',
  'haulynx-loads-icon',
  'haulynx-location-dots',
  'haulynx-trailer-icon',
  'haulynx-gps-icon',
  'haulynx-location-pin',
  'haulynx-location',
  'haulynx-lock',
  'haulynx-mail',
  'haulynx-map-phone',
  'haulynx-map-truck',
  'haulynx-max-buy',
  'haulynx-median-price-icon',
  'haulynx-money',
  'haulynx-moon',
  'haulynx-more',
  'haulynx-new-offer-green',
  'haulynx-not-assigned',
  'haulynx-not-available',
  'haulynx-note',
  'haulynx-number-icon',
  'haulynx-open-new-window',
  'haulynx-orders-truck',
  'haulynx-overview-truck',
  'haulynx-pen',
  'haulynx-phone-alt',
  'haulynx-phone-contact',
  'haulynx-phone-filled',
  'haulynx-phone-red',
  'haulynx-pick-up',
  'haulynx-pickup',
  'haulynx-pin',
  'haulynx-priority',
  'haulynx-range-icon',
  'haulynx-receipt-alt',
  'haulynx-red-cross',
  'haulynx-refresh-icon',
  'haulynx-restroom',
  'haulynx-rolled-load',
  'haulynx-rolled-load-gray',
  'haulynx-search',
  'haulynx-share-loads',
  'haulynx-share',
  'haulynx-sidebar-box-blue',
  'haulynx-sidebar-box-grey',
  'haulynx-sidebar-menu',
  'haulynx-star-calendar-icon',
  'haulynx-swap',
  'haulynx-system',
  'haulynx-tachnometr',
  'haulynx-tanker',
  'haulynx-trash-gray',
  'haulynx-truck-icon',
  'haulynx-truck',
  'haulynx-unlock',
  'haulynx-users-red',
  'haulynx-warn',
  'haulynx-warning-sign',
  'haulynx-what-is-new',
  'haulynx-wifi-router',
  'haulynx-yellow-tick-small',
  'headphones',
  'help',
  'in-transit-icon',
  'jpg-icon',
  'left-arrow',
  'location-pin-white',
  'location-pin',
  'notification-alert',
  'notification-alert-red',
  'notification',
  'one-to-one',
  'package',
  'pdf-icon',
  'png-icon',
  'percent-icon',
  'right-arrow',
  'sort-asc',
  'sort-desc',
  'thumbs-down',
  'thumbs-up',
  'truck-driving',
  'user-info',
  'xpress-technologies-side-nav-icon',
  'xt-header-logo',
  'place-bid-icon',
  'virtual-sort-asc-alphabetical-logo',
  'virtual-sort-desc-alphabetical-logo',
  'virtual-sort-asc-numerical-logo',
  'virtual-sort-desc-numerical-logo',
];

export const loadSearchIconsFolder = [
  'assigned',
  'bed',
  'box-closed',
  'box-opened',
  'bus',
  'circle',
  'clock',
  'create-load',
  'dropoff-arrow',
  'dropoff',
  'dropyard',
  'finalled',
  'home',
  'in-transit',
  'no-bids-offers-timeline',
  'no-bids',
  'no-loads',
  'offer-timeline',
  'offer',
  'pending',
  'pickup-arrow',
  'pickup',
  'sparkle',
  'toilet',
  'filter-search',
  'reset',
];

export const sideBarIconsFolder = [
  'driver-logs-icon',
  'fleet-icon',
  'help-icon',
  'lanes-icon',
  'loads-icon',
  'notifications-icon',
  'users-icon',
  'whats-new-icon',
  'report-icon',
  'xt-xpress-technologies',
  'document-checked',
  'checked-circle-no-border',
];
