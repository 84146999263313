import { Store } from '@ngrx/store';
import { List, Map } from 'immutable';
import { Observable } from 'rxjs';
import { AppState } from '../../../main-store/app.reducers';
import { ISearchSelectors } from './selectors';

export interface ISearchModelSelectors {
  entities$: Observable<List<any>>;
  pagination$: Observable<any>;
  query$: Observable<{ [key: string]: any }>;
  isLoading$: Observable<boolean>;
}

export const searchModel = (store: Store<AppState>, searchStateSelector: ISearchSelectors): ISearchModelSelectors => {
  const entities$: Observable<List<any>> = store.select(searchStateSelector.getEntities);
  const pagination$: Observable<any> = store.select(searchStateSelector.getPagination);
  const query$: Observable<Map<string, any>> = store.select(searchStateSelector.getQuery);
  const isLoading$: Observable<boolean> = store.select(searchStateSelector.getLoading);

  return {
    entities$,
    pagination$,
    query$,
    isLoading$,
  };
};
