import gql from 'graphql-tag';

export const UpdateCounterOffer = gql`
  mutation UpdateCounterOffer($counterOfferId: StrictId!, $updateCounterOfferInput: UpdateCounterOfferInput!) {
    updateCounterOfferById(counterOfferId: $counterOfferId, updateCounterOfferInput: $updateCounterOfferInput) {
      id
      carrierId
      bidId
      price
      notes
      status
      createdAt
      createdBy {
        id
        name
      }
      updatedAt
      updatedBy {
        id
        name
      }
      deletedAt
    }
  }
`;
