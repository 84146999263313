<div class="active-load-search-container">
  <app-load-table-search-bar
    [carriers]="activeLoadsModel.carriersEntities$ | async"
    [brokers]="activeLoadsModel.brokersEntities$ | async"
    [filter]="activeLoadsModel.entitiesQuery$ | async"
    (carrierSearch)="carrierSearch($event)"
    (filterChange)="searchLoads($event)"
  ></app-load-table-search-bar>

  <div class="active-load-search-container__details">
    <app-active-loads-table
      [isLoading]="activeLoadsModel.entitiesLoading$ | async"
      [extendedColumns]="!selectedLoad"
      [data]="activeLoads"
      [selected]="selectedLoad"
      [stickyHeader]="true"
      [activeStepTypes]="activeLoadsModel.activeStepTypes$ | async"
      [pagination]="activeLoadsModel.entitiesPagination$ | async"
      (changePagination)="changePagination($event)"
      (select)="selectLoad($event)"
    ></app-active-loads-table>

    <app-load-quick-actions
      *ngIf="selectedLoad"
      [load]="selectedLoad"
      [title]="loadDetailsModel.nextStepTitle$ | async"
      [user]="appModel.user$ | async"
      [showContent]="!!activeStep"
      [showTimeline]="true"
      [showSeparator]="!!activeStep"
      (actionClick)="quickAction($event, selectedLoad)"
      (showFacilityInfo)="onShowFacilityInfo($event)"
    >
      <app-load-quick-driver-form
        *ngIf="activeStep?.type === loadStepType.ASSIGNED"
        [load]="selectedLoad"
        [featureFlags]="featureFlags$ | async"
        [formData]="loadDetailsModel.assignDriverForm$ | async"
        [trucks]="trucksAndDevice$ | async"
        [trailers]="commonEntities.graphQlTrailers.entities$ | async"
        [drivers]="commonEntities.graphQlDrivers.entities$ | async"
        [equipments]="equipmentType"
        [isLoading]="loadDetailsModel.form.isLoading$ | async"
        [isLoadingDrivers]="commonEntities.graphQlDrivers.isLoading$ | async"
        [isLoadingTrucks]="commonEntities.graphQlTrucks.isLoading$ | async"
        [isLoadingTrailers]="commonEntities.graphQlTrailers.isLoading$ | async"
        [isLoadingCreateEntity]="loadDetailsModel.isLoadingCreateEntity$ | async"
        [driverToPopulate]="loadDetailsModel.driverToPopulate$ | async"
        [truckToPopulate]="loadDetailsModel.truckToPopulate$ | async"
        [trailerToPopulate]="loadDetailsModel.trailerToPopulate$ | async"
        (submit)="submitDriver(selectedLoad?.id, $event)"
        (addDriver)="onAddDriver()"
        (addTruck)="onAddTruck()"
        (addTrailer)="onAddTrailer()"
      ></app-load-quick-driver-form>

      <app-load-dispatch-form
        [formData]="loadDetailsModel.dispatchForm$ | async"
        [showSkipButton]="!mapSkippedLoads[selectedLoad && selectedLoad.id]"
        *ngIf="activeStep?.type === loadStepType.DISPATCHED"
        (saveDispatchForm)="submitDispatch(selectedLoad?.id, $event)"
        (skipStep)="skipStep(selectedLoad?.id)"
      ></app-load-dispatch-form>

      <app-load-quick-location
        *ngIf="
          activeStep?.type === loadStepType.PICKUP ||
          activeStep?.type === loadStepType.PICKUP_STOP ||
          activeStep?.type === loadStepType.DROPOFF ||
          activeStep?.type === loadStepType.DROPOFF_STOP
        "
        [isLoading]="loadDetailsModel.form.isLoading$ | async"
        [location]="activeStep.data"
        [lastStep]="lastStep?.data"
        [formData]="loadDetailsModel.locationForm$ | async"
        (saveLocation)="submitLocation(selectedLoad?.id, activeStep.id, $event)"
      ></app-load-quick-location>
    </app-load-quick-actions>
  </div>
</div>
