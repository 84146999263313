import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-broker-info',
  templateUrl: './broker-info.component.html',
  styleUrls: ['./broker-info.component.scss'],
})
export class BrokerInfoComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
