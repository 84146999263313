import { IPostTruckInput } from '@haulynx/types';
import * as _ from 'lodash';
import { AppSettingsActionTypes } from '../../main-store/app-settings.actions';
import { DispatchAction } from '../../shared/store/helpers/action';
import { combineAdapterReducers } from '../../shared/store/helpers/adapter';
import { combineReducersFlat } from '../../shared/store/helpers/reducer';
import {
  carrierAdapter,
  carrierDashboardAdapter,
  carrierDashboardLanesAdapter,
  carrierDashboardTrucksAdapter,
  carrierTabsAdapter,
} from './adapters';
import { CarrierDashboardActionTypes, CarrierDashboardTrucksActionTypes } from './carriers.action';
import { CarriersState } from './carriers.state';

export const initialState = new CarriersState();

const combinedReducers = combineReducersFlat([
  combineAdapterReducers<CarriersState>(carrierAdapter),
  combineAdapterReducers<CarriersState>(carrierDashboardAdapter),
  combineAdapterReducers<CarriersState>(carrierDashboardLanesAdapter),
  combineAdapterReducers<CarriersState>(carrierDashboardTrucksAdapter),
  combineAdapterReducers<CarriersState>(carrierTabsAdapter),
]);

export function carriersReducer(state = initialState as CarriersState, action: DispatchAction): CarriersState {
  switch (action.type) {
    case AppSettingsActionTypes.LOG_OUT:
      return initialState;

    case CarrierDashboardActionTypes.GET_ROUTES_ERROR:
      return state.merge({
        isLoadingRoutes: false,
      });

    case CarrierDashboardActionTypes.GET_ROUTES:
      return state.merge({
        isLoadingRoutes: true,
      });

    case CarrierDashboardActionTypes.GET_ROUTES_SUCCESS: {
      let newState = {};

      if (_.isArray(action.payload)) {
        newState = _.reduce(
          action.payload,
          (result, { key, geojson }) => {
            result[key] = geojson;

            return result;
          },
          {}
        );
      } else {
        const { key, geojson } = action.payload;

        newState[key] = geojson;
      }

      return state.merge({
        isLoadingRoutes: false,
        routes: {
          ...state.routes,
          ...newState,
        },
      });
    }

    case CarrierDashboardActionTypes.GET_KPI_METRICS:
      return state.merge({
        isLoadingCarrierKpiMetrics: true,
        carrierKpiMetrics: null,
      });

    case CarrierDashboardActionTypes.GET_KPI_METRICS_ERROR:
      return state.merge({
        isLoadingCarrierKpiMetrics: false,
        carrierKpiMetrics: null,
      });

    case CarrierDashboardActionTypes.GET_KPI_METRICS_SUCCESS:
      return state.merge({
        isLoadingCarrierKpiMetrics: false,
        carrierKpiMetrics: action.payload,
      });

    case CarrierDashboardActionTypes.GET_CARRIER_INSURANCE_AND_SAFETY:
      return state.merge({
        isLoadingCarrierInsuranceAndSafety: true,
        carrierInsuranceAndSafety: null,
      });
    case CarrierDashboardActionTypes.GET_CARRIER_INSURANCE_AND_SAFETY_ERROR:
      return state.merge({
        isLoadingCarrierInsuranceAndSafety: false,
        carrierInsuranceAndSafety: null,
      });
    case CarrierDashboardActionTypes.GET_CARRIER_INSURANCE_AND_SAFETY_SUCCESS:
      return state.merge({
        isLoadingCarrierInsuranceAndSafety: false,
        carrierInsuranceAndSafety: action.payload,
      });

    case CarrierDashboardTrucksActionTypes.CREATE_POSTED_TRUCK: {
      const newTruck = action.payload as IPostTruckInput;
      const entities = state.searchTrucks.entities.insert(0, newTruck);
      const searchTrucks = state.searchTrucks;

      return state.merge({
        searchTrucks: { ...searchTrucks, entities },
        loadingMapTruckIds: { ...state.loadingMapTruckIds, [newTruck.id]: true },
      });
    }

    case CarrierDashboardTrucksActionTypes.CREATE_POSTED_TRUCK_SUCCESS:
    case CarrierDashboardTrucksActionTypes.CREATE_POSTED_TRUCK_ERROR: {
      const { id } = action.payload as { id: string | string[] };
      const newLoadingMap = _.isArray(id)
        ? id.map((val) => {
            return { [val]: false };
          })
        : { [id]: false };

      return state.merge({
        loadingMapTruckIds: { ...state.loadingMapTruckIds, ...newLoadingMap },
      });
    }

    case CarrierDashboardTrucksActionTypes.REMOVE_POSTED_TRUCK: {
      const { id } = action.payload;

      return state.merge({
        loadingMapTruckIds: { ...state.loadingMapTruckIds, ...{ [id]: true } },
      });
    }

    case CarrierDashboardTrucksActionTypes.REMOVE_POSTED_TRUCK_SUCCESS:
    case CarrierDashboardTrucksActionTypes.REMOVE_POSTED_TRUCK_ERROR: {
      const { id } = action.payload;

      return state.merge({
        loadingMapTruckIds: { ...state.loadingMapTruckIds, ...{ [id]: false } },
      });
    }

    case CarrierDashboardActionTypes.GET_CARRIER_STATUS:
      return state.merge({
        isLoadingCarriersStatus: true,
        carriersStatus: {},
      });
    case CarrierDashboardActionTypes.GET_CARRIER_STATUS_SUCCESS:
      return state.merge({
        isLoadingCarriersStatus: false,
        carriersStatus: action.payload,
      });
    case CarrierDashboardActionTypes.GET_CARRIER_STATUS_ERROR:
      return state.merge({
        isLoadingCarriersStatus: false,
        carriersStatus: null,
      });

    default: {
      return combinedReducers(state, action);
    }
  }
}
