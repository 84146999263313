import { InjectionToken, Provider } from '@angular/core';
import { IAsyncEntityState, LoadRouteData } from '@haulynx/types';
import { USER_PROVIDED_EFFECTS } from '@ngrx/effects';
import { ActionReducer, ActionReducerMap } from '@ngrx/store';
import { LoadRouteEntityService } from './load-route-entity.service';

export const LOAD_ROUTE_ENTITY_REDUCERS_TOKEN = new InjectionToken<ActionReducerMap<IAsyncEntityState<LoadRouteData>>>(
  'load route entity reducers'
);

function getLoadRouteEntityReducersFactory(
  loadsRouteEntityService: LoadRouteEntityService
): ActionReducer<IAsyncEntityState<LoadRouteData>> {
  return loadsRouteEntityService.getReducers();
}

const loadRouteEntityReducerProvider: Provider = {
  provide: LOAD_ROUTE_ENTITY_REDUCERS_TOKEN,
  useFactory: getLoadRouteEntityReducersFactory,
  deps: [LoadRouteEntityService],
};

const loadRouteEntityEffectProvider: Provider = {
  provide: USER_PROVIDED_EFFECTS,
  multi: true,
  useValue: [LoadRouteEntityService],
};

export const loadRouteStateProviders: Provider[] = [loadRouteEntityReducerProvider, loadRouteEntityEffectProvider];
