import gql from 'graphql-tag';

export const GetReleaseNotesById = gql`
  query GetReleaseNotesById($releaseNoteId: StrictId!, $userId: StrictId) {
    getReleaseNotesById(releaseNoteId: $releaseNoteId, userId: $userId) {
      id
      title
      pages {
        id
        title
        description
        media {
          type
          data
        }
      }
      userType
      date
      expiration
      hasViewed
    }
  }
`;
