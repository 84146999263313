import { ActivityDateFilter } from '../advanced-search/models/ActivityDateFilter';
import { ActivityTypeFilter } from '../advanced-search/models/ActivityTypeFilter';
import { ActivityUserFilter, ActivityUserTypeFilter } from '../advanced-search/models/ActivityUserFilter';
import { ISearchFilter } from '../advanced-search/search-filter';
import { IColumns2 } from '../data-table-v2';
import { DataTableConfig } from '../data-table/data-table-type';
import { KeyValuePair } from '../generic/key-value-pair';
import { ColumnField } from '../search-view/default-columns';

export const activityDataColumns = (
  columnSettings: Array<{ key: string; value: boolean; order?: number }> = []
): IColumns2[] => {
  const allColumns: IColumns2[] = [
    {
      field: ColumnField.UPDATED_AT,
      label: 'Date & Time',
      isSortable: true,
      sortConfig: [
        {
          label: 'Date & Time',
          dataIndex: 'updatedAt',
        },
      ],
      isCustomCell: true,
      width: '150px',
      isVisible: true,
      isPinned: true,
      order: 1,
    },
    {
      field: ColumnField.USER_TYPE,
      label: 'User Type',
      isCustomCell: true,
      isSortable: false,
      width: '150px',
      sortConfig: [],
      isVisible: true,
      isPinned: true,
      order: 2,
    },
    {
      field: ColumnField.USER,
      label: 'User',
      filters: [],
      sortConfig: [],
      isCustomCell: true,
      isSortable: false,
      width: '140px',
      isVisible: true,
      order: 3,
    },
    {
      field: ColumnField.ACTIVITY_TYPE,
      label: 'Activity Type',
      sortConfig: [],
      filters: [''],
      isCustomCell: true,
      isSortable: false,
      width: '150px',
      isVisible: true,
      order: 4,
    },
    {
      field: ColumnField.ACTIONS,
      label: 'Action / Data',
      sortConfig: [],
      filters: [],
      isCustomCell: true,
      isSortable: false,
      width: 'auto',
      isVisible: true,
      order: 5,
    },
  ];

  return allColumns.reduce((acc: IColumns2[], column: IColumns2): IColumns2[] => {
    const setting = columnSettings.find(
      (value: { key: string; value: boolean; order?: number }) => value.key === column.field
    );
    if (!setting) return [...acc, column];

    const newThing = { ...column, isVisible: setting.value, order: setting.order || column.order };
    return [...acc, newThing];

    // return setting?.value === false ? [...acc, { ...column,  isVisible: false }] : [...acc, column];
  }, []);
};

export const loadOverviewActivityConfigOptions: DataTableConfig = {
  globalFilter: false,
  reorderColumns: false,
  columnVisibility: false,
  reorderRows: false,
  showBulkActions: false,
  sortColumns: false,
  scrollable: false,
  checkBoxSelection: false,
  filterLocally: false,
  showTopPaginator: false,
  showBottomPaginator: false,
  isLaneRate: false,
  bulkActions: null,
  pageAmount: 300,
};

export const loadActivityFilters = (): ISearchFilter[] => [
  new ActivityDateFilter(),
  new ActivityTypeFilter(),
  new ActivityUserFilter(),
  new ActivityUserTypeFilter(),
];

export const loadActivityActionMenu: KeyValuePair[] = [
  {
    key: 'Expand All',
    value: 0,
  },
  {
    key: 'Collapse All',
    value: 1,
  },
];

export const loadActivityRowtextConfig = {
  ul: 'string-ul',
  li: 'string-li',
};
