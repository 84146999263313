import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface SaferWatchData {
  carrier_id: string;
  message: string;
  status: number;
}

@Injectable({
  providedIn: 'root',
})
export class SaferwatchService {
  constructor(private http: HttpClient) {}

  getCarrierSaferwatch(dot: string, message = 'carrier saferwatch'): Observable<SaferWatchData> {
    const body = [{ message, dot }];
    return this.http.post<SaferWatchData>('/api/saferwatch', body);
  }
}
