import { ITabsState, Tab } from '@haulynx/types';
import { ActionReducer } from '@ngrx/store';
import { filter, keyBy, map, merge } from 'lodash';
import { combineReducersFlat, createReducer } from '../helpers/reducer';

function getLastFromState(state: ITabsState): number {
  return state.tabs.reduce((initial, item) => {
    if (initial < item.order) {
      initial = item.order;
    }

    return initial;
  }, 0);
}

function updateTab() {
  return (state: ITabsState, action) => {
    const tabs: Tab[] = action.payload;
    const activeTabs = filter(tabs, (tab: Tab) => !!state.tabs.get(tab.id));
    const updatedTabs = map(activeTabs, (tab) => merge({}, state.tabs.get(tab.id), tab));
    const mapByKeyTab = keyBy(updatedTabs, 'id');

    return {
      ...state,
      tabs: state.tabs.merge(mapByKeyTab),
    };
  };
}

function addTab() {
  return (state: ITabsState, action) => {
    const tabs: Tab[] = action.payload;

    const lastOrder = getLastFromState(state);

    const orderedTabs = tabs.map((tab, index) => {
      const stateTab = state.tabs.get(tab.id);
      let order = 0;
      if (!stateTab && !tab.order) {
        order = lastOrder + index + 1;
      } else if (stateTab) {
        order = tab.order || stateTab.order;
      }

      return merge({}, { ...tab, order }, stateTab);
    });

    const mapByKeyTab = keyBy(orderedTabs, 'id');

    const newState = { tabs: state.tabs.merge(mapByKeyTab) };

    return {
      ...state,
      ...newState,
    };
  };
}

function removeTab() {
  return (state: ITabsState, action) => {
    const { id } = action.payload;
    const newTabsState = state.tabs.delete(id);
    const lastTab: Tab = newTabsState.last();
    const newState = {
      tabs: newTabsState,
      selectedTabId: state.selectedTabId === id ? lastTab && lastTab.id : state.selectedTabId,
    };

    return { ...newState };
  };
}

function selectTab() {
  return (state: ITabsState, action) => {
    let tab = action.payload;

    if (!tab.id) {
      const lastOrder = getLastFromState(state);
      tab = lastOrder;
    }

    return {
      ...state,
      selectedTabId: tab.id,
    };
  };
}

export function createTabReducer(actionTypes) {
  return {
    getReducer(...args): ActionReducer<any, any> {
      const addTabReducer = createReducer(actionTypes.ADD_TAB, addTab, ...args);
      const updateTabReducer = createReducer(actionTypes.UPDATE_TAB, updateTab, ...args);
      const removeTabReducer = createReducer(actionTypes.REMOVE_TAB, removeTab, ...args);
      const selectReducer = createReducer(actionTypes.SELECT_TAB, selectTab, ...args);

      const newReducer = combineReducersFlat([addTabReducer, removeTabReducer, selectReducer, updateTabReducer]);

      return newReducer;
    },
  };
}
