import { camelCase } from 'lodash';
import { BookStatus, DistributionMechanism, PageAndSort, RegionTypes } from '../web-interfaces';
import { LoadsServiceLoadStatus } from './loads-service-load';
import { LoadIdentifierType } from './loads-service-provider';
import { DropoffRequirements, PickupRequirements } from './loads-service-restriction';

export interface LoadServiceOriginDestinationData {
  lat?: number;
  lon?: number;
  radiusMiles?: number;
  states?: string;
  address?: string;
}

export interface LoadServicePickupDeliveryDateRangeData {
  startTime?: number;
  endTime?: number;
}

export interface LoadServiceLoadedMilesRange {
  minDistanceMiles?: number;
  maxDistanceMiles?: number;
}

export interface LoadServicePriceRange {
  minPrice?: number;
  maxPrice?: number;
}
export interface LoadServiceSearchParamData extends LoadServiceSearchParameters {
  destinationLocation?: string[];
  appointmentSet?: boolean;
  origin?: LoadServiceOriginDestinationData;
  destination?: LoadServiceOriginDestinationData;
  pickupDateRange?: LoadServicePickupDeliveryDateRangeData;
  deliveryDateRange?: LoadServicePickupDeliveryDateRangeData;
  priceRange?: LoadServicePriceRange;
  loadedMilesRange?: LoadServiceLoadedMilesRange;
  orderNumber?: string;
  tmwNumber?: string;
  trucks?: string[];
  driverName?: string;
  truckUnitId?: string;
  pageSort?: PageAndSort;
}

export class LoadServiceSearchParameters {
  carrierCanBookNow?: boolean;
  hazmat?: boolean;
  loadStatus?: LoadsServiceLoadStatus[];
  bookStatus?: BookStatus[];
  distributionMechanism?: DistributionMechanism;
  showExcluded?: boolean;
  originLat?: number[] | string[] | string;
  originLon?: number[] | string[] | string;
  originRadiusMiles?: number[] | string;
  /**
   * Two letter state codes: AZ, NY, etc.
   */
  originStates?: string[];
  /**
   * Two letter state codes: AZ, NY, etc.
   */
  destinationStates?: string[];
  destinationLat?: number[] | string[] | string;
  destinationLon?: number[] | string[] | string;
  destinationRadiusMiles?: number[] | string;
  firstAppointmentStart?: number;
  firstAppointmentEnd?: number;
  isInMission?: boolean;
  missionId?: string;
  lastAppointmentStart?: number;
  lastAppointmentEnd?: number;
  providerId?: string;
  inboundRegion?: RegionTypes[];
  outboundRegion?: RegionTypes[];
  originZipLane?: string[];
  destinationZipLane?: string[];
  originZoneLane?: string[];
  destinationZoneLane?: string[];
  alternateIdType?: LoadIdentifierType;
  alternateIdValue?: string | string[];
  carrierDot?: string;
  carrierNameOrDot?: string[];
  bookingBrokers?: string[];
  brokerNameOrUsxId?: string[];
  brokerTeamId?: string[];
  billTo?: string[];
  notBillTo?: string[];
  shipper?: string[];
  receiver?: string[];
  driverName?: string;
  truckUnitId?: string;
  equipment?: string[];
  commodity?: string;
  minPrice?: number;
  maxPrice?: number;
  minDistanceMiles?: number;
  maxDistanceMiles?: number;
  minMaxBuy?: number;
  maxMaxBuy?: number;
  minRevenue?: number;
  maxRevenue?: number;
  minWeight?: number;
  maxWeight?: number;
  minBidCount?: number;
  maxBidCount?: number;
  minHighestBid?: number;
  maxHighestBid?: number;
  minDistance?: number;
  maxDistance?: number;
  minCxPriority?: number;
  maxCxPriority?: number;
  minOperationsPriority?: number;
  maxOperationsPriority?: number;
  showTestLoads?: boolean;
  firstLocationAppointmentSet?: boolean;
  lastLocationAppointmentSet?: boolean;
  pickupRequirements?: PickupRequirements;
  dropoffRequirements?: DropoffRequirements;
  originLocation?: string[];
  destinationLocation?: string[];
  parent?: string;
  startDate?: string;
  endDate?: string;
  includeBookedAt?: string;

  // Omitted on search
  viewId?: string;
  fullMission?: boolean;

  constructor(props?: LoadServiceSearchParamData, excluded = true) {
    if (props) {
      const { originLocation, destinationLocation, appointmentSet, ...propsWithOmissions } = props;
      if (propsWithOmissions.truckUnitId) {
        propsWithOmissions.truckUnitId = propsWithOmissions.truckUnitId.toString();
      }
      if (propsWithOmissions.driverName) {
        propsWithOmissions.driverName = propsWithOmissions.driverName.toString();
      }
      Object.assign(this, propsWithOmissions);

      if (this.equipment) this.equipment = [...this.equipment.map(camelCase)];
    }
    this.showExcluded = excluded;
  }
}

type SearchParameters = { dot?: string; id?: string; searchCarrierLoads?: boolean };
export type CarrierLoadServiceSearchParameters = SearchParameters & LoadServiceSearchParameters;
