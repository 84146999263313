import { HttpStatus, PaymentsTypeForm } from '@haulynx/types';
import { List } from 'immutable';
import { AppSettingsActionTypes } from '../../main-store/app-settings.actions';
import { DispatchAction } from '../../shared/store/helpers/action';
import { PaymentTypesActionTypes } from './payment-types.actions';
import { initialState, PaymentTypesState } from './payment-types.state';

export function paymentTypesReducer(
  state: PaymentTypesState = initialState,
  action: DispatchAction
): PaymentTypesState {
  switch (action.type) {
    case AppSettingsActionTypes.LOG_OUT:
    case PaymentTypesActionTypes.CLEAR_STATE:
      return initialState;

    case PaymentTypesActionTypes.GET_PAYMENT_TYPES: {
      return {
        ...state,
        query: action.payload ? action.payload : state.query,
        isLoading: true,
        httpStatus: HttpStatus.PENDING,
      };
    }

    case PaymentTypesActionTypes.GET_PAYMENT_TYPES_SUCCESS: {
      const { entities = [] } = action.payload || {};

      return {
        ...state,
        paymentTypes: List(entities),
        isLoading: false,
        httpStatus: HttpStatus.SUCCESS,
        initialPaymentTypes: List(entities),
      };
    }

    case PaymentTypesActionTypes.GET_PAYMENT_TYPES_ERROR: {
      return {
        ...state,
        paymentTypes: List([]),
        isLoading: false,
        httpStatus: HttpStatus.FAILED,
        initialPaymentTypes: List([]),
      };
    }

    case PaymentTypesActionTypes.ADD_PAYMENT_TYPE_STATE: {
      const { payment, index } = action.payload;

      return {
        ...state,
        paymentTypes: state.paymentTypes.insert(index, payment),
      };
    }

    case PaymentTypesActionTypes.REMOVE_PAYMENT_TYPE_STATE: {
      const { index } = action.payload;

      return {
        ...state,
        paymentTypes: state.paymentTypes.remove(index),
      };
    }

    case PaymentTypesActionTypes.UPDATE_PAYMENT_TYPE_STATE: {
      const { index, payment } = action.payload as { index: number; payment: Partial<PaymentsTypeForm> };
      const paymentValue = state.paymentTypes.get(index);

      return {
        ...state,
        paymentTypes: state.paymentTypes.set(index, { ...paymentValue, ...payment }),
      };
    }

    case PaymentTypesActionTypes.SAVE_PAYMENT_TYPE: {
      return {
        ...state,
        isSaving: true,
      };
    }

    case PaymentTypesActionTypes.SAVE_PAYMENT_TYPE_ERROR:
    case PaymentTypesActionTypes.SAVE_PAYMENT_TYPE_SUCCESS: {
      return {
        ...state,
        isSaving: false,
      };
    }

    default:
      return state;
  }
}
