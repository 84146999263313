import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { SignaturePad } from 'ngx-signaturepad/signature-pad';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss'],
})
export class SignatureComponent {
  @ViewChild(SignaturePad, { static: true }) signaturePad: SignaturePad;
  @Output() signature = new EventEmitter<string>();

  signaturePadOptions: Object = {
    canvasWidth: 370,
    canvasHeight: 100,
    backgroundColor: 'white',
  };
  constructor() {}

  clearSignature() {
    this.signaturePad.clear();
    this.signature.emit(null);
  }

  drawComplete() {
    const signatureData = this.signaturePad.toDataURL();
    this.signature.emit(signatureData);
  }
}
