<div class="load-feed-search-form__container">
  <form [formGroup]="form" fxLayout="column">
    <div class="broker" fxLayout="row" fxLayoutGap.gt-sm="16px" fxLayout.lt-md="column" fxFlexFill>
      <div class="search-block" fxFlex="1 0 auto !important" fxLayout.lt-md="row" fxLayoutGap.lt-md="8px">
        <mat-form-field class="app-form-field" appearance="fill" floatLabel="always" fxFlex.lt-md="65">
          <mat-label> Pick Up Location </mat-label>
          <app-address-field
            formControlName="pickUpLocation"
            placeholder="Chattanooga, TN"
            icon="haulynx-crosshair"
            (stateSelected)="setRadiusControlState('originRadius', $event)"
            (onIconClick)="setCurrentUserLocation()"
            appBindQueryParam="pickup"
            (transformValue)="transformLocation($event)"
          >
          </app-address-field>
        </mat-form-field>

        <app-selector
          fxShow
          fxHide.lt-md
          key="value"
          [data]="radiusSearchOptions"
          placeholder="Radius"
          formControlName="originRadius"
          appBindQueryParam="radius"
          (transformValue)="transformRadius($event)"
          styleDisplay="normal"
        >
        </app-selector>

        <mat-form-field
          class="app-form-field"
          appearance="fill"
          floatLabel="always"
          fxFlex.lt-md="35"
          fxShow.lt-md
          fxHide
        >
          <mat-label> Radius </mat-label>
          <mat-select
            class="small-dropdown-radius"
            formControlName="originRadius"
            appBindQueryParam="radius"
            (transformValue)="transformRadius($event)"
          >
            <mat-option *ngFor="let option of radiusSearchOptions" [value]="option.value">{{
              option.label
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div
        class="search-block swap-button-wrapper"
        fxFlex="25px"
        fxHide.lt-md
        ngClass.gt-sm="swap-button-padding"
        fxFlexAlign.lt-md="center"
      >
        <a mat-button class="swap-icon" (click)="switchLocations()">
          <mat-icon svgIcon="haulynx-swap"></mat-icon>
        </a>
      </div>

      <div class="search-block" fxFlex="1 0 auto !important" fxLayout.lt-md="row" fxLayoutGap.lt-md="8px">
        <mat-form-field class="app-form-field" appearance="fill" floatLabel="always" fxFlex.lt-md="65">
          <mat-label> Delivery Location </mat-label>
          <app-address-field
            formControlName="deliveryLocation"
            placeholder="All"
            (stateSelected)="setRadiusControlState('destinationRadius', $event)"
            appBindQueryParam="dropoff"
            (transformValue)="transformLocation($event)"
          >
          </app-address-field>
        </mat-form-field>

        <app-selector
          fxShow
          fxHide.lt-md
          key="value"
          [data]="radiusSearchOptions"
          placeholder="Radius"
          formControlName="destinationRadius"
          styleDisplay="normal"
        >
        </app-selector>

        <mat-form-field
          class="app-form-field"
          appearance="fill"
          floatLabel="always"
          fxFlex.lt-md="35"
          fxShow.lt-md
          fxHide
        >
          <mat-label> Radius </mat-label>
          <mat-select class="small-dropdown-radius" formControlName="destinationRadius">
            <mat-option *ngFor="let option of radiusSearchOptions" [value]="option.value">{{
              option.label
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="search-block" fxFlex="1 0 auto !important">
        <mat-form-field appearance="fill" class="app-form-field" floatLabel="always" data-test="date-field">
          <mat-label> Pick Up Date </mat-label>
          <app-datepicker
            placeholder="mm/dd/yyyy-mm/dd/yyyy"
            [rangeMode]="true"
            formControlName="pickupDate"
            appBindQueryParam="date"
            (transformValue)="transformDate($event)"
          >
          </app-datepicker>
          <mat-error *ngIf="form.get('pickupDate').errors?.dateRange"> Please limit the range to 7 days </mat-error>
        </mat-form-field>

        <app-chip-list
          [data]="dateButtons"
          [ngClass]="{ 'app-chips-list--margin': form.get('pickupDate').errors?.dateRange }"
          (onClick)="dateButtonClick($event)"
          [select]="activePickUpDateButton"
          fxHide.lt-md
        >
        </app-chip-list>
      </div>

      <div class="search-block" fxFlex="1 0 auto !important" *ngIf="user?.carrier">
        <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
          <mat-label> Equipment </mat-label>
          <app-drop-down
            [placeholder]="'ex. Dry Van'"
            formControlName="equipmentType"
            [data]="equipmentTypes"
            displayLabel="text"
            key="text"
          >
          </app-drop-down>
        </mat-form-field>
      </div>

      <div class="search-block" fxFlex="1 0 auto !important" fxHide.lt-md *ngIf="!user?.carrier">
        <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
          <mat-label> Order ID </mat-label>
          <input matInput formControlName="orderId" placeholder="ex. Order #, TMW #" />
        </mat-form-field>
      </div>
    </div>

    <div
      *ngIf="!user?.carrier"
      class="broker__controls--advanced"
      (click)="advanced()"
      data-test="show-additional-filters"
      fxShow.lt-md
      fxHide
      fxFlexAlign.xs="start"
      fxFlexAlign.gt-xs="end"
    >
      <span>{{ showAdvanced ? 'Hide' : 'Show' }} additional filters</span>
      <mat-icon>{{ showAdvanced ? 'expand_less' : 'expand_more' }}</mat-icon>
    </div>

    <div class="broker__controls" fxFlexAlign.gt-xs="end" ngClass.xs="full-width">
      <div class="broker__controls--search" ngClass.xs="full-width">
        <ng-container *ngTemplateOutlet="searchButton"></ng-container>
      </div>

      <div
        *ngIf="!user?.carrier"
        class="broker__controls--advanced"
        (click)="advanced()"
        data-test="show-additional-filters"
        fxHide.lt-md
        fxShow
      >
        <span>{{ showAdvanced ? 'Hide' : 'Show' }} additional filters</span>
        <mat-icon>{{ showAdvanced ? 'expand_less' : 'expand_more' }}</mat-icon>
      </div>
    </div>

    <div class="broker__advanced" *ngIf="showAdvanced" fxFlexOffset="16px">
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.gt-sm="16px">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="16px" fxFlex="1 0 auto !important">
          <div class="search-block" fxFlex.lt-md="50" fxFlex.gt-sm>
            <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
              <mat-label> Shipper </mat-label>
              <input matInput formControlName="shipper" placeholder="Any" />
            </mat-form-field>
          </div>
          <div class="search-block" fxFlex.lt-md="50" fxFlex.gt-sm>
            <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
              <mat-label> Consignee </mat-label>
              <input matInput formControlName="office" placeholder="Any" />
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="16px" fxFlex="1 0 auto !important">
          <div class="search-block" fxFlex.gt-sm fxFlex.lt-md="50">
            <mat-form-field appearance="fill" class="app-form-field" floatLabel="always">
              <mat-label>Delivery Date</mat-label>
              <app-datepicker placeholder="Choose Delivery Date" [rangeMode]="true" formControlName="deliveryDate">
              </app-datepicker>
            </mat-form-field>
          </div>

          <div class="search-block" fxFlex.gt-sm fxFlex.lt-md="50">
            <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
              <mat-label> Equipment </mat-label>
              <app-drop-down
                [placeholder]="'ex. Dry Van'"
                formControlName="equipmentType"
                [data]="equipmentTypes"
                displayLabel="text"
                key="text"
              >
              </app-drop-down>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div
        class="broker__controls--clear"
        (click)="reset()"
        data-test="clear-all-filters"
        fxLayout="row"
        fxLayoutAlign="end center"
      >
        <span fxFlexAlign="end">CLEAR ALL FILTERS</span>
      </div>
    </div>

    <ng-template #searchButton>
      <button
        [disabled]="form.invalid"
        (click)="click()"
        type="submit"
        mat-button
        class="action-button search-button"
        data-test="search-button"
        ngClass.xs="full-width"
      >
        Search
      </button>
    </ng-template>
  </form>
</div>
