import { SafeUrl } from '@angular/platform-browser';
import { User } from '.';

export class Document {
  id: string;
  tenant_id: string;
  title: string;
  file_name: string;
  format: string;
  file_size: string;
  file_size_bytes: number;
  file_extension: string;
  link: string;
  type: string;
  location: DocumentLocation;
  created_at: number;
  created_by: string;
  updated_at: number;
  updated_by: string;
  lookup_ids: JSON;
  tags: string[];
  acknowledgements: [DocumentAcknowledgement];
  users: User[];
}

class DocumentLocation {
  lat: number;
  lon: number;
}

class DocumentAcknowledgement {
  created_at: number;
  created_by: string;
  signature: string;
  signature_type: string;
}

export class DocumentWrapper {
  metaData: Document[];
  documentSubType?: string;
  fileUrls: SafeUrl[];
}
