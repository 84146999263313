<div class="app-tabs" mat-tab-nav-bar *ngIf="(hide$ | async).length" [ngClass]="{ 'hide-ink-bar': hideInkBar }">
  <div
    mat-tab-link
    class="mat-tab-link"
    *ngFor="let tab of tabs$ | async; let index = index; trackBy: trackByFn"
    [active]="selectedIndex === index"
    [ngClass]="{ 'app-tabs__hide': tab?.hideLabel }"
  >
    <div class="app-tabs__label" (click)="selectTab({ index: index, tab: tab })">
      {{ tab?.label }}
    </div>

    <div class="app-tabs__control" *ngIf="tab?.closable">
      <div class="app-tabs__close" (click)="removeTab(tab, $event)">
        <mat-icon>close</mat-icon>
      </div>
    </div>

    <span *ngIf="tab?.isMatBadge" [matBadge]="tab?.matBadge" matBadgeOverlap="false">&nbsp;</span>
  </div>
</div>

<ng-content></ng-content>
