<div class="search-place-dropdown-container" #wrapper>
  <div
    class="sgd-place-options"
    *ngFor="let prediction of predictions; let i = index"
    [class.isHovering]="i === indexOfFocus"
    (click)="onSelectPrediction(i)"
    (mouseenter)="indexOfFocus = i; isMouseFocus = true"
    (mouseleave)="indexOfFocus = -1; isMouseFocus = false"
    [scrollIntoView]="i === indexOfFocus && !isMouseFocus"
  >
    <div class="sgd-place-value" attr.data-test="{{ prediction.fullAddress }}">
      {{ prediction.fullAddress }}
    </div>
  </div>
</div>
