import { DataTableConfig } from '../data-table/data-table-type';

export const devicesTableConfigOptions: DataTableConfig = {
  globalFilter: false,
  reorderColumns: false,
  columnVisibility: false,
  reorderRows: false,
  showBulkActions: false,
  sortColumns: true,
  scrollable: false,
  checkBoxSelection: false,
  filterLocally: false,
  showTopPaginator: false,
  showBottomPaginator: true,
  isLaneRate: false,
  bulkActions: null,
  pageAmount: 25,
};
