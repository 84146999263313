import { Injectable } from '@angular/core';
import { LoadsServiceService, NotificationsService } from '@haulynx/services';
import { AsyncQueryManager, Location } from '@haulynx/types';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppState } from '../../main-store/app.reducers';
import { AsyncEntityBase } from '../../main-store/async-entity/async-entity-base.class';
import { loadLocationEntityNamespace } from './namespace';

@Injectable({ providedIn: 'root' })
export class LoadLocationEntityService extends AsyncEntityBase<Location> {
  getLoadLocationByIdManager: AsyncQueryManager<Location, string>;

  constructor(
    protected actions$: Actions,
    protected store: Store<AppState>,
    private loadsServiceApi: LoadsServiceService,
    private notifications: NotificationsService
  ) {
    super(actions$, store, loadLocationEntityNamespace);

    this.getLoadLocationByIdManager = this.createAsyncQuery<string, Location, any>((loadId: string) =>
      this.loadsServiceApi.getLoadLocationById(loadId).pipe(
        catchError((error) => {
          this.notifications.error(error, 'There was a problem');
          return throwError(error);
        })
      )
    );
  }
}
