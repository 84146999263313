import gql from 'graphql-tag';

export const GetUsersByEmail = gql`
  query getUsersByEmail($email: String!) {
    getUsersByEmail(email: $email) {
      id
      name
      email
      carrier {
        id
      }
    }
  }
`;
