import { Injectable } from '@angular/core';
import { AnalyticsService, LoadsServiceService, NotificationsService } from '@haulynx/services';
import { ANALYTICS_EVENT, Carrier, LoadIdentifierType, LoadsServiceLoad } from '@haulynx/types';
import { getLoadsServiceLoadAlternateId } from '@haulynx/utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { get } from 'lodash';
import { of, throwError } from 'rxjs';
import { catchError, first, map, mergeMap, switchMap } from 'rxjs/operators';
import { DispatchAction } from '../../shared/store/helpers/action';
import { LoadDetailsModel } from '../load-details/load-details-model';
import { LoadOffersModel } from '../load/load-offers-model';
import { BookLoadActions, BookLoadActionTypes } from './book-load.actions';

@Injectable()
export class BookLoadEffects {
  constructor(
    private actions$: Actions,
    private notificationsService: NotificationsService,
    private analytics: AnalyticsService,
    private loadsServiceService: LoadsServiceService,
    private loadDetailsModel: LoadDetailsModel,
    private loadOffersModel: LoadOffersModel
  ) {}

  bookLoad = createEffect(() =>
    this.actions$.pipe(
      ofType(BookLoadActionTypes.BOOK_LOAD),
      map((action: DispatchAction) => action.payload),
      switchMap((payload: { load: LoadsServiceLoad; carrier: Carrier; carrierDot: string }) => {
        const loadId = payload.load.id;
        const carrierId = payload?.carrierDot || payload?.carrier?.dot;

        return this.loadsServiceService.bookLoad(carrierId, loadId).pipe(
          map((response: { data; error: { message: string } }) => {
            const errorMessage = response?.error?.message;
            if (errorMessage) {
              this.notificationsService.error(errorMessage, `Book Load`);
              return BookLoadActions.bookLoadError({ loadId });
            }

            this.notificationsService.success('Load Successfully Booked!', `Book Load`);
            const query = { dot: carrierId };
            this.loadOffersModel.search({ query });

            this.loadOffersModel.entities$.pipe(first()).subscribe((offers) => {
              const analyticData = {
                loadId,
                loadTmwNum: getLoadsServiceLoadAlternateId(payload.load, LoadIdentifierType.TMW_NUMBER),
                loadOrderNum: getLoadsServiceLoadAlternateId(payload.load, LoadIdentifierType.BILL_OF_LADING),
              };

              this.analytics.logEvent(
                JSON.stringify(offers).includes(loadId)
                  ? ANALYTICS_EVENT.ACCEPTED_LOAD_OFFER
                  : ANALYTICS_EVENT.BOOK_NON_OFFERED_LOAD,
                analyticData
              );
            });
            return BookLoadActions.bookLoadSuccess({ loadId });
          }),
          catchError((error) => {
            this.notificationsService.error(error.message, `Book Load`);
            return of(BookLoadActions.bookLoadError({ loadId }));
          })
        );
      })
    )
  );

  loadBookedSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BookLoadActionTypes.BOOK_LOAD_SUCCESS),
        map((action: DispatchAction) => action.payload),
        map((payload) => {
          const { loadId } = payload;
          return this.loadDetailsModel.get({ key: loadId, id: loadId });
        }),
        catchError((error) => {
          this.notificationsService.error(error.message, `Book Load`);
          return of(BookLoadActions.bookLoadError());
        })
      ),
    { dispatch: false }
  );

  getBrokerBookedLoads = createEffect(() =>
    this.actions$.pipe(
      ofType(BookLoadActionTypes.GET_BROKER_BOOKED_LOADS),
      map((action: DispatchAction) => action.payload),
      mergeMap((payload) => {
        const { loadId, carrierDot }: { loadId: string; carrierDot: string } = payload;
        if (!carrierDot) {
          console.error('Cannot have carrierDot be undefined', payload);
        }
        return this.loadsServiceService.getBrokerBookedLoads(loadId, carrierDot).pipe(
          mergeMap((result) => {
            if (get(result, 'errors')) {
              return throwError(new Error(get(result, 'errors')));
            } else if (!get(result, 'data.getBrokerOffer', null)) {
              return throwError(new Error('You are not approved to book this load.'));
            }
            return [BookLoadActions.getBrokerBookedLoadsSuccess({ loadId })];
          }),
          catchError((error) => {
            return of(BookLoadActions.getBrokerBookedLoadsError({ loadId }));
          })
        );
      })
    )
  );
}
