<mat-card class="block-truck mat-elevation-z0">
  <data-table
    [data]="dashboardCarrierModel.trucksSearch.entities$ | async"
    [loading]="dashboardCarrierModel.trucksSearch.isLoading$ | async"
    [displayedColumns]="truckColumns"
    [stickyHeader]="true"
    [showPagination]="false"
    [templateCellRef]="truckTemplateRef"
    [cls]="'posted-trucks'"
  >
    <ng-template #truckTemplateRef let-row="row" let-column="column">
      <div *ngIf="column === 'location'">
        {{ row.location | truckLocation }}
      </div>

      <div *ngIf="column === 'preferredLocation'">
        {{ row.preferredLocation | truckLocation }}
      </div>

      <div *ngIf="column === 'equipmentType'">
        {{ row.equipmentType }}
      </div>

      <div *ngIf="column === 'timeAvailable'">
        {{ row.timeAvailable | timezoned: 'M/D/YY':null:row.timeZone }}
      </div>

      <div *ngIf="column === 'options'" class="truck-options">
        <mat-icon (click)="removeTruck($event, row)">delete_outline</mat-icon>
      </div>
    </ng-template>
  </data-table>

  <mat-card-actions class="lane-actions">
    <button mat-stroked-button (click)="addTruck($event)">Post Truck +</button>
  </mat-card-actions>
</mat-card>
