import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CarrierToolbarService, MomentService, PostTruckService, UserService, WindowRef } from '@haulynx/services';
import { CarrierDashboardModel, LoadEntityService } from '@haulynx/store';
import {
  AddressField,
  BookStatus,
  CarrierToolbarState,
  EquipmentType,
  EquipmentTypeOption,
  equipmentTypes,
  IPostTruck,
  LoadServiceSearchParameters,
  LoadsServiceEquipmentType,
  PageAndSort,
  PostTruckForm,
  RadiusOption,
  radiusOptions,
  ToolbarPostedTrucksConfigOptions,
  User,
} from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { add } from 'date-fns';
import { exclusionFilters } from 'libs/types/src/lib/loads-service/exclusion-filters';
import { camelCase, nth } from 'lodash';
import { Moment } from 'moment';
import { combineLatest, Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'haulynx-carrier-posted-trucks',
  templateUrl: './carrier-posted-trucks.component.html',
  styleUrls: ['./carrier-posted-trucks.component.scss'],
})
export class CarrierPostedTrucksComponent implements OnInit {
  @Input() dot: string;
  alive = aliveWhile();
  user: User;
  trucks$: Observable<IPostTruck[]>;
  paginationData$: Observable<{
    currentPage: number;
    total: number;
    totalPages: number;
  }>;
  toolbarConfigOptions = ToolbarPostedTrucksConfigOptions;
  loadingMapTruckIds = {};
  showPostTruck = false;

  radiusList: RadiusOption[] = radiusOptions;
  equipmentList: EquipmentTypeOption[] = equipmentTypes;
  truckMinDate: Moment;
  defaultPickupDays: string[] = [];
  truckTimeAvailable: number;
  truckDefaultRadius: number;
  truckDefaultEquipment: string;
  preferredLocation: AddressField = null;
  truckToPopulate: IPostTruck;
  countryRestrictions = ['us', 'ca'];
  selectedTruck: IPostTruck;
  query: {
    queryHash: string;
    payload: Partial<LoadServiceSearchParameters>;
    pageAndSort: Partial<PageAndSort>;
  };
  filterButton = true;
  state$: Observable<CarrierToolbarState>;

  constructor(
    public dashboardCarrierModel: CarrierDashboardModel,
    private momentService: MomentService,
    private postTruckService: PostTruckService,
    private userService: UserService,
    public loadEntityService: LoadEntityService,
    private windowRef: WindowRef,
    public router: Router,
    private carrierToolbarService: CarrierToolbarService
  ) {
    this.state$ = this.carrierToolbarService.state$;
    this.userService.user.pipe(takeUntil(this.alive)).subscribe((data) => {
      this.user = data;
    });
    this.truckMinDate = this.momentService.getMoment();
    this.truckTimeAvailable = this.momentService.getTodayUnixMidnight();
    this.truckDefaultRadius = nth(this.radiusList, 3).id;
    this.truckDefaultEquipment = nth(this.equipmentList, 1).text;

    this.loadEntityService.getUSXLoadsManager.searchQuery$.pipe(takeUntil(this.alive)).subscribe((data) => {
      this.query = data;
    });
  }

  ngOnInit(): void {
    if (this.router.url.includes('overview')) {
      this.filterButton = false;
    }
    this.dashboardCarrierModel.searchTrucks({
      query: { dot: this.dot, localSort: 'timeAvailable' },
      limit: this.toolbarConfigOptions.pageAmount,
      page: 1,
    });

    this.dashboardCarrierModel.loadingMapTruckIds$.pipe(takeUntil(this.alive)).subscribe((loadingMapTruckIds) => {
      this.loadingMapTruckIds = loadingMapTruckIds;
    });

    this.dashboardCarrierModel.createTruckSuccess$.pipe(takeUntil(this.alive)).subscribe(() => {
      this.dashboardCarrierModel.searchTrucks({
        query: { dot: this.dot, localSort: 'timeAvailable' },
        limit: this.toolbarConfigOptions.pageAmount,
        page: 1,
      });
    });

    this.paginationData$ = combineLatest([
      this.dashboardCarrierModel.trucksSearch.pagination$,
      this.dashboardCarrierModel.trucks$,
    ]).pipe(
      takeUntil(this.alive),
      map(([pagination, trucks]) => {
        return {
          currentPage: 1,
          total: trucks.length,
          totalPages: pagination.total ? 1 : 0,
        };
      })
    );

    this.trucks$ = this.dashboardCarrierModel.trucks$;
  }

  viewPostTruck(formIsOpen): void {
    if (!formIsOpen) this.showPostTruck = true;
  }

  hidePostTruck(): void {
    this.showPostTruck = false;
  }

  onPage(event: { pageUp: boolean; currentPage: number; limit: number }): void {
    this.dashboardCarrierModel.searchTrucks({
      query: { dot: this.dot },
      limit: event.limit,
      page: event.currentPage + (event.pageUp ? 1 : -1),
    });
  }

  saveTruck(truck: PostTruckForm): void {
    if (truck) {
      if (!truck?.equipmentType) truck.equipmentType = EquipmentType.DRY_VAN;
      const newTruck = this.postTruckService.toDto(truck, this.dot, this.user);
      this.dashboardCarrierModel.createPostedTruck(newTruck);
    }
  }

  deleteTruck(truck: IPostTruck): void {
    if (truck) {
      this.dashboardCarrierModel.removePostedTruck({ id: truck.id });
      this.carrierToolbarService.updateState({ data: { selectedItemId: null } });
    }
  }

  moreCarrierInfo(): void {
    const id = this.dot;
    const url = `/dashboard/carriers/view/${id}`;
    this.windowRef.getNativeWindow().open(url, '_blank');
  }

  filterByTruck(truck: IPostTruck): void {
    if (truck) {
      const equipment =
        (truck.equipmentType as EquipmentType) === EquipmentType.DRY_VAN ||
        (truck.equipmentType as EquipmentType) === EquipmentType.REEFER
          ? [camelCase(truck.equipmentType), LoadsServiceEquipmentType.DRY_VAN_OR_REEFER]
          : [camelCase(truck.equipmentType)];

      const pickupTime = new Date(new Date(truck.timeAvailable).setHours(0, 0, 0, 0)).getTime();
      let payload: Partial<LoadServiceSearchParameters> = {
        bookStatus: [BookStatus.BOOKABLE, BookStatus.VIEWABLE],
        equipment,
        originLat: [truck.locationLat],
        originLon: [truck.locationLon],
        originRadiusMiles: [truck.radius],
        originLocation: [truck.location],
        firstAppointmentStart: pickupTime,
        firstAppointmentEnd: add(pickupTime, { days: 1, seconds: -1 }).getTime(),
      };
      if (truck.preferredLocation !== 'Any') {
        payload = {
          destinationLat: [truck.preferredLocationLat],
          destinationLon: [truck.preferredLocationLon],
          destinationRadiusMiles: [truck.preferredRadius],
          destinationLocation: [truck.preferredLocation],
          ...payload,
        };
      }
      // if in test loads show available test loads
      if (this.query.payload.showTestLoads) {
        payload = {
          showTestLoads: true,
          billTo: ['haulynx test'],
          ...payload,
        };
      } else {
        // all available loads
        payload = {
          notBillTo: exclusionFilters.map((val) => val.key),
          ...payload,
        };
      }
      this.loadEntityService.getUSXLoadsManager.dispatch({ query: payload, pageAndSort: { limit: 25 } });
    }
  }

  clickedTruck(truck: IPostTruck): void {
    this.selectedTruck = truck && this.selectedTruck?.id !== truck?.id ? truck : null;
    this.carrierToolbarService.updateState({ data: { selectedItemId: this.selectedTruck?.id } });
  }
}
