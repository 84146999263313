import { LoadsServiceLoad } from '../../loads-service';
import { Bid } from '../bid';

export interface DateRange {
  startDate: string;
  endDate: string;
}

export interface LaneHistoryLoadSummary {
  totalLoads: number;
  preBookedLoads: number;
  sameDayBookings: number;
  medianPriceOfLoad: number;
  averagePriceOfLoad: number;
  totalLaneProfit: number;
}

export interface LaneHistoryBidSummary {
  totalBids: number;
  highestBidPrice: number;
  lowestBidPrice: number;
}

export interface LaneHistoryLeaderboardCarrier {
  id: string;
  name: string;
  totalLoads: number;
  profit: number;
}

export interface LaneHistory {
  loadSummary: LaneHistoryLoadSummary;
  bidSummary: LaneHistoryBidSummary;
  loads: LoadsServiceLoad[];
  bids: Bid[];
  carrierLeaderboard: LaneHistoryLeaderboardCarrier[];
}

export enum laneHistoryDateRangeVal {
  MONTH_1 = '30',
  MONTH_3 = '90',
  MONTH_12 = '365',
}
