import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  ActionButtonsModule,
  ActiveLoadsTableModule,
  BookLoadModule,
  ButtonDropdownModule,
  CarrierAnalyticsModule,
  CarrierBidsModule,
  CarrierDetailsModule,
  CarrierDetailsShortModule,
  CarrierDetailsSummaryComponent,
  CarrierFormSearchComponent,
  CarrierLaneFormComponent,
  CarrierLanesComponent,
  CarrierRecommendSearchFormComponent,
  CarrierTruckFormComponent,
  ChipListModule,
  DataTableModule,
  DatepickerModule,
  DropDownModule,
  ExpandPanelModule,
  FieldLabelModule,
  GoogleAddressFieldModule,
  InProgressModule,
  LoadBidDetailsModule,
  LoadFeedComponentsModule,
  LoadPriceModule,
  MapsModule,
  PostTruckFormModule,
  PreferredLanesFormModule,
  PreferredLanesTimelineModule,
  SelectorModule,
  SpinnerModule,
  TabsModule,
} from '@haulynx/components';
import { DirectivesModule } from '@haulynx/directives';
import { ElementsModule } from '@haulynx/elements';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import {
  AssignLoadCarrierFormVm,
  CarrierTruckFormVmService,
  OrderInfoVmService,
  PaymentDetailsVmService,
} from '@haulynx/services';
import {
  ActiveLoadsStateModule,
  AsyncEntityGeocodingeModule,
  AsyncEntityPreferredLanesStoreModule,
  CarrierDashboardModel,
  CarrierDetailsStoreModule,
  CarriersModel,
  CarriersStoreModule,
  LoadEntitiesStateModule,
  PaymentTypeStoreModule,
} from '@haulynx/store';
import { ClipboardModule } from 'ngx-clipboard';
import { SatDatepickerModule } from 'saturn-datepicker';
import { CarrierAdvancedSearchModule } from '../carrier-advanced-search/carrier-advanced-search.module';
import { LoadFeedModule } from '../load-feed/load-feed.module';
import { LoadModule } from '../load/load.module';
import { ActiveLoadDetailsContainerModule } from '../loads/active-load-details-container/active-load-details-container.module';
import { CarrierActiveLoadDialogComponent } from './carrier-active-load-dialog/carrier-active-load-dialog.component';
import { CarrierDashboardComponent } from './carrier-dashboard/carrier-dashboard.component';
import { CarrierLoadDetailsComponent } from './carrier-load-details/carrier-load-details.component';
import { CarrierSearchComponent } from './carrier-search/carrier-search.component';
import { CarrierComponent } from './carrier/carrier.component';
import { CarriersRoutingModule } from './carriers-routing.module';

@NgModule({
  declarations: [
    CarrierSearchComponent,
    CarrierComponent,
    CarrierFormSearchComponent,
    CarrierRecommendSearchFormComponent,
    CarrierDashboardComponent,
    CarrierDetailsSummaryComponent,
    CarrierLanesComponent,
    CarrierTruckFormComponent,
    CarrierLaneFormComponent,
    CarrierLoadDetailsComponent,
    CarrierActiveLoadDialogComponent,
  ],
  exports: [CarrierLanesComponent, CarrierTruckFormComponent],
  imports: [
    CommonModule,
    CarriersRoutingModule,
    FlexLayoutModule,
    CarriersStoreModule,
    CarrierDetailsStoreModule,
    PaymentTypeStoreModule,
    LoadFeedModule,
    ActiveLoadDetailsContainerModule,
    ReactiveFormsModule,
    DataTableModule,
    DropDownModule,
    ActionButtonsModule,
    DirectivesModule,
    BookLoadModule,
    CarrierBidsModule,
    TabsModule,
    SpinnerModule,
    LoadModule,
    MapsModule,
    MaterialModule,
    DatepickerModule,
    DirectivesModule,
    PipesModule,
    SatDatepickerModule,
    FieldLabelModule,
    GoogleAddressFieldModule,
    InProgressModule,
    ExpandPanelModule,
    LoadFeedComponentsModule,
    CarrierDetailsModule,
    LoadPriceModule,
    SelectorModule,
    ChipListModule,
    FormsModule,
    PostTruckFormModule,
    CarrierAnalyticsModule,
    CarrierDetailsShortModule,
    LoadEntitiesStateModule,
    ActiveLoadsStateModule,
    ActiveLoadsTableModule,
    ClipboardModule,
    LoadBidDetailsModule,
    CarrierDetailsStoreModule,
    ElementsModule,
    CarrierAdvancedSearchModule,
    PreferredLanesFormModule,
    AsyncEntityPreferredLanesStoreModule,
    PreferredLanesTimelineModule,
    ButtonDropdownModule,
    AsyncEntityGeocodingeModule,
  ],
  providers: [
    CarriersModel,
    CarrierDashboardModel,
    CarrierTruckFormVmService,
    AssignLoadCarrierFormVm,
    OrderInfoVmService,
    PaymentDetailsVmService,
  ],
})
export class CarriersModule {}
