import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tos',
  templateUrl: './termofservice.component.html',
  styleUrls: ['./termofservice.component.scss'],
})
export class TermofserviceComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
