import { Directive, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { aliveWhile } from '../utils/utils';

@Directive()
export class SubscriberComponent implements OnDestroy {
  public alive = aliveWhile();

  protected whileAlive<T>() {
    return takeUntil<T>(this.alive);
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }
}
