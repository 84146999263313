import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { aliveWhile } from '@haulynx/utils';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-text-area-handler',
  templateUrl: './text-area-handler.component.html',
  styleUrls: ['./text-area-handler.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TextAreaHandlerComponent), multi: true }],
})
export class TextAreaHandlerComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {
  @Input() label = '';
  @Input() readOnly: boolean;
  @Input() data: string;
  @ViewChild('textbox') textboxRef: ElementRef; //referencing input element

  resizer = false;

  notes = new FormControl();
  alive = aliveWhile();
  private initialHeight: number;
  private initialWidth: number;

  onChanges = (_: string): void => {};
  onTouch = (_: Event): void => {};

  ngOnDestroy(): void {
    this.alive.destroy();
  }

  ngOnInit() {
    this.value = this.data ? this.data : null;
  }

  ngAfterViewInit(): void {
    //store the initial height and width of the textboxes
    this.initialHeight = this.textboxRef.nativeElement.offsetHeight;
    this.initialWidth = this.textboxRef.nativeElement.offsetWidth;

    this.notes.valueChanges.pipe(takeUntil(this.alive)).subscribe((value: string) => {
      this.onChanges(value);
    });
  }

  onChangeFocus(e: Event, value: string): void {
    this.value = value;
    this.onChanges(this.value);
    this.onTouch(e);
    this.textboxRef.nativeElement.focus({
      height: this.textboxRef.nativeElement.style.height = '125px',
      width: this.textboxRef.nativeElement.style.width = '40vw',
    });
  }

  onBlur(e: Event, value: string): void {
    this.value = value;
    this.textboxRef.nativeElement.blur(
      (this.textboxRef.nativeElement.style.height = `${this.initialHeight}px`),
      (this.textboxRef.nativeElement.style.width = `${this.initialWidth}px`)
    );
  }

  get value(): string {
    return this.notes.value;
  }

  set value(v: string) {
    if (v !== this.value) {
      this.notes.setValue(v);
    }
  }

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: () => void): void {
    this.onChanges = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.notes.disable();
    } else {
      this.notes.enable();
    }
  }
}
