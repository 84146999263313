import { SortDirection } from '@angular/material/sort';
import { List, Map, Record } from 'immutable';
import { BulkActionPage } from '../bulk-actions';
import { EntityType, ISortingConfig } from '../data-table-v2';
import { DataTableCls } from './data-table-cls-type';

export type DataTableType<T extends object> = Map<string, T> | List<Record<T>> | Record<T>[] | T[];

export interface IColumns {
  label: string;
  defaultValue?: string;
  dataIndex: string;
  isCustomCell?: boolean;
  isExpanded?: boolean;
  width?: string;
  cls?: string;
  sortConvert?: (row, active: string) => EntityType;
  isSortable?: boolean;
  sublabel?: string;
  initiallySortedBy?: SortDirection;
  align?: 'right' | 'left';
}
export interface DataTableConfig {
  globalFilter: boolean;
  reorderColumns: boolean;
  columnVisibility: boolean;
  reorderRows: boolean;
  showBulkActions: boolean;
  sortColumns: boolean;
  scrollable: boolean;
  checkBoxSelection: boolean;
  filterLocally: boolean;
  showTopPaginator: boolean;
  showBottomPaginator: boolean;
  isLaneRate: boolean;
  isLaneHistory?: boolean;
  bulkActions: BulkActionPage;
  pageAmount: number;
}

export interface PaginationTableConfig {
  pageAmount: number;
  showTopPaginator: boolean;
  showBottomPaginator: boolean;
  scrollable: boolean;
  scrollHeight: string;
  showSort?: boolean;
  isSortable?: boolean;
  sortConfig?: ISortingConfig[];
}

export interface IRowSettings {
  cls: DataTableCls;
}

export interface RowSelectionEvent<T extends object> {
  /**
   * The id of the row that changed selection state
   */
  row: T;

  /**
   * The selection state of the row
   */
  rowSelected: boolean;

  /**
   * All selected rows
   */
  selection: DataTableType<T>;

  /**
   * Indicates whether it was the header checkbox that triggered this event
   */
  headerToggled: boolean;

  /**
   * The current page's list of data entries
   */
  page: T[];
}
