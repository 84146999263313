import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '@haulynx/services';
import { LoadFeedModel } from '@haulynx/store';
import {
  counterClock,
  counterTemplate,
  LoadsServiceLoad,
  LoadsServiceRestrictionTypes,
  LoadRouteSource,
} from '@haulynx/types';

@Component({
  selector: 'app-lf-details-map',
  templateUrl: './load-feed-details-map.component.html',
  styleUrls: ['./load-feed-details-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadFeedDetailsMapComponent {
  @Input() load: LoadsServiceLoad;
  @Input() loadFeedRoute: LoadRouteSource[] = [];
  @Input() isExclusive = false;
  @Input() showTruckRoute = false;

  timerFinished = false;
  counterTemplate = counterTemplate;
  counterClock = counterClock;
  loadsServiceRestrictionTypes = LoadsServiceRestrictionTypes;

  constructor(public loadFeedModel: LoadFeedModel, public dialog: MatDialog, public userService: UserService) {}

  onTimerFinished(): void {
    this.timerFinished = true;
  }
}
