import { ActionReducer } from '@ngrx/store';
import { isString, isObject } from 'lodash';

import { combineReducersFlat, createReducer } from '../helpers/reducer';
import { IFormTypes } from './actions';

export const FORM_NAMESPACE = 'DEFAULT_FORM_NAMESPACE';

function setStateKey() {
  return (state, action) => {
    let key,
      namespace = FORM_NAMESPACE;

    if (!isString(action.payload) && isObject(action.payload)) {
      key = action.payload.key;
      namespace = action.payload.namespace || FORM_NAMESPACE;
    } else {
      key = action.payload;
    }

    const newState = {
      stateNameSpace: namespace,
      stateKey: key ? state.stateKey.set(namespace, key) : state.stateKey,
    };

    return {
      ...state,
      ...newState,
    };
  };
}

function get() {
  return (state, action) => {
    const { key } = action.payload;

    return {
      ...state,
      isLoading: state.isLoading.set(key, true),
    };
  };
}

function getSuccess() {
  return (state, action) => {
    const key = action.payload.key;
    const newState = action.payload.state;
    const newMode = state.mode.get(key) ? state.mode.get(key) : 'view';

    return {
      ...state,
      initialState: state.initialState.set(key, newState),
      state: state.state.set(key, newState),
      isLoading: state.isLoading.set(key, false),
      mode: state.mode.set(key, newMode),
    };
  };
}

function getError() {
  return (state, action) => {
    const { key } = action.payload;

    return {
      ...state,
      isLoading: state.isLoading.set(key, false),
    };
  };
}

function save() {
  return (state, action) => {
    const { key } = action.payload;

    return {
      ...state,
      isLoading: state.isLoading.set(key, true),
    };
  };
}

function saveSuccess() {
  return (state, action) => {
    const key = action.payload.key;
    const newState = action.payload.state;

    return {
      ...state,
      initialState: state.initialState.set(key, newState),
      state: state.state.set(key, newState),
      mode: state.mode.set(key, 'view'),
      isLoading: state.isLoading.set(key, false),
    };
  };
}

function saveError() {
  return (state, action) => {
    const { key } = action.payload;

    return {
      ...state,
      isLoading: state.isLoading.set(key, false),
    };
  };
}

function saveState() {
  return (state, action) => {
    const newState = action.payload;

    return {
      ...state,
      state: newState,
      isLoading: false,
    };
  };
}

function reset() {
  return (state, action) => {
    return {
      ...state,
      state: state.initialState,
    };
  };
}

function selectMode() {
  return (state, action) => {
    const { mode, key } = action.payload;
    const prevMode = state.mode.get(key);
    const newState: { [key: string]: any } = {
      mode: state.mode.set(key, mode),
    };

    if (prevMode === 'new' && mode !== prevMode && mode !== 'delete') {
      newState.state = state.state.set(key, state.initialState.get(key));
    }

    return {
      ...state,
      ...newState,
    };
  };
}

export function createFormReducer<T>(actionTypes: IFormTypes) {
  return {
    getReducer(types?: { [key: string]: any }): ActionReducer<T, any> {
      const getReducer = createReducer([actionTypes.GET], get);
      const getSuccessReducer = createReducer([actionTypes.GET_SUCCESS], getSuccess);
      const getErrorReducer = createReducer([actionTypes.GET_ERROR], getError);

      const saveReducer = createReducer([actionTypes.SAVE], save);
      const saveSuccessReducer = createReducer([actionTypes.SAVE_SUCCESS], saveSuccess);
      const saveErrorReducer = createReducer([actionTypes.SAVE_ERROR], saveError);

      const saveStateReducer = createReducer([actionTypes.SAVE_STATE], saveState);
      const resetReducer = createReducer([actionTypes.RESET], reset);
      const selectModeReducer = createReducer([actionTypes.SELECT_MODE], selectMode);
      const setStateReducer = createReducer([actionTypes.SET_STATE_KEY], setStateKey);

      return combineReducersFlat([
        getReducer,
        getSuccessReducer,
        getErrorReducer,
        saveReducer,
        saveSuccessReducer,
        saveErrorReducer,
        saveStateReducer,
        resetReducer,
        selectModeReducer,
        setStateReducer,
      ]);
    },
  };
}
