import { Pipe, PipeTransform } from '@angular/core';
import { LoadsServiceRestriction, LoadsServiceRestrictionTypes } from '@haulynx/types';
import { isEmpty, startCase } from 'lodash';

@Pipe({
  name: 'loadRestrictions',
})
export class LoadRestrictionsPipe implements PipeTransform {
  transform(restrictions: LoadsServiceRestriction[], type: LoadsServiceRestrictionTypes): boolean | string {
    if (isEmpty(restrictions)) return null;
    const matchedRestriction = restrictions.find((restriction) => restriction.type === type);

    if (!matchedRestriction) {
      return null;
    }

    switch (matchedRestriction.value) {
      case 'true':
        return true;

      case 'false':
        return false;

      case 'livePickup':
      case 'preloaded':
      case 'liveDropoff':
      case 'dropTrailer':
        return startCase(matchedRestriction.value);

      default:
        return matchedRestriction.value;
    }
  }
}
