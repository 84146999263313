import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@haulynx/modules';
import { IconModule } from '../icon/icon.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { BlueButtonComponent } from './blue-button.component';

@NgModule({
  declarations: [BlueButtonComponent],
  imports: [CommonModule, FlexLayoutModule, MaterialModule, IconModule, SpinnerModule],
  exports: [BlueButtonComponent],
})
export class BlueButtonModule {}
