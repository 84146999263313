import { Injectable } from '@angular/core';
import { GetDevicesByCarrierIdLittleInfo } from '@haulynx/gql';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GraphqlService } from '../graphql.service';

@Injectable({
  providedIn: 'root',
})
export class DevicesService {
  constructor(private graphqlService: GraphqlService) {}

  getDevicesByCarrierId<T>(variables: { carrierId }): Observable<T[]> {
    return this.graphqlService
      .query({
        variables,
        query: GetDevicesByCarrierIdLittleInfo,
      })
      .pipe(
        map(({ data }: any) => {
          return data.getDevicesByCarrierId || [];
        })
      );
  }
}
