<form [formGroup]="deviceForm">
  <div class="add-device-container">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="title">
      <h2 matDialogTitle>ADD NEW DEVICE</h2>
      <button mat-icon-button data-test="close" matDialogClose>
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <mat-dialog-content>
      <div class="row">
        <div class="col-lg-12">
          <drop-down displayLabel="name" key="id" placeholder="Select Carrier" [data]="data" formControlName="carrier">
          </drop-down>
        </div>
        <mat-form-field class="col-lg-12">
          <mat-chip-list #chipList>
            <mat-chip *ngFor="let imei of imeis; let i = index" [removable]="true" (removed)="remove(i)">
              {{ imei }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
          </mat-chip-list>
          <input
            #imeiInput
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="add($event)"
            placeholder="IMEI"
            data-test="IMEI"
            (keydown)="resetImeiIsInvalid($event)"
          />
          <mat-hint align="start" *ngIf="imeiInput.value.length === 15 && !imeiIsInvalid"
            >Press ENTER to add this IMEI</mat-hint
          >
          <mat-hint align="end">{{ imeiInput.value.length }} / 15</mat-hint>
          <mat-hint *ngIf="imeiIsInvalid"> The imei is invalid </mat-hint>
        </mat-form-field>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
      <button
        mat-raised-button
        class="save-button"
        data-test="save-button"
        [disabled]="!deviceForm.valid || deviceForm.get('imeis').value.length === 0"
        [matDialogClose]="deviceForm.getRawValue()"
      >
        Save
      </button>
    </mat-dialog-actions>
  </div>
</form>
