import { Pipe, PipeTransform } from '@angular/core';
import { keys } from 'lodash';

/**
 * Returns the first key of an object which is useful for the errors returned by Reactive Form Validators
 */
@Pipe({
  name: 'validationError',
})
export class ValidationErrorPipe implements PipeTransform {
  transform(obj: { [key: string]: boolean }): string {
    return keys(obj)[0];
  }
}
