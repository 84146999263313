import { DispatchAction } from '../../shared/store/helpers/action';
import { combineAdapterReducers } from '../../shared/store/helpers/adapter';
import { userAdapter } from './adapters';
import { UsersActionTypes } from './users.actions';
import { UserFormHttpStatus, UserState } from './users.state';

export const initialState = new UserState();

const reducer = combineAdapterReducers<UserState>(userAdapter);

export function usersReducer(state = initialState, action: DispatchAction): UserState {
  switch (action.type) {
    case UsersActionTypes.UPDATE_USER_ERROR:
    case UsersActionTypes.CREATE_USER_ERROR: {
      return state.merge({
        isLoadingUserForm: false,
        userFormHttpStatus: UserFormHttpStatus.FAILED,
      });
    }

    case UsersActionTypes.UPDATE_USER_SUCCESS:
    case UsersActionTypes.CREATE_USER_SUCCESS: {
      return state.merge({
        isLoadingUserForm: false,
        userFormHttpStatus: UserFormHttpStatus.SUCCESS,
      });
    }

    case UsersActionTypes.GET_USER:
    case UsersActionTypes.UPDATE_USER:
    case UsersActionTypes.CREATE_USER: {
      return state.merge({
        isLoadingUserForm: true,
        userFormHttpStatus: UserFormHttpStatus.BLACK,
      });
    }

    case UsersActionTypes.VALIDATE_DRIVERS_LICENSE: {
      return state.merge({
        isLoadingLicenseValidation: true,
        isValidDriversLicense: false,
      });
    }

    case UsersActionTypes.VALIDATE_DRIVERS_LICENSE_SUCCESS: {
      return state.merge({
        isLoadingLicenseValidation: false,
        isValidDriversLicense: true,
      });
    }

    case UsersActionTypes.VALIDATE_DRIVERS_LICENSE_ERROR: {
      return state.merge({
        isLoadingLicenseValidation: false,
        isValidDriversLicense: false,
      });
    }

    case UsersActionTypes.GET_USER_SUCCESS: {
      const { user } = action.payload;

      return state.merge({
        isLoadingUserForm: false,
        userFormHttpStatus: UserFormHttpStatus.GET_SUCCESS,
        selectedUser: user,
      });
    }

    case UsersActionTypes.GET_USER_ERROR: {
      return state.merge({
        isLoadingUserForm: false,
        userFormHttpStatus: UserFormHttpStatus.GET_FAILED,
        selectedUser: null,
      });
    }

    case UsersActionTypes.CLEAR_SELECTED_USER: {
      return state.merge({
        selectedUser: null,
        userFormHttpStatus: UserFormHttpStatus.BLACK,
      });
    }

    default:
      return reducer(state, action);
  }
}
