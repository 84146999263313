import gql from 'graphql-tag';

export const GetLoadsServiceLoadById = gql`
  query GetLoadsServiceLoadById($loadId: StrictId!) {
    getLoadsServiceLoadById(loadId: $loadId) {
      mission {
        id
        status
        loads {
          id
          carrier {
            dot
          }
          bookStatus
          restrictions {
            type
            value
          }
          locations {
            customer {
              telephone
              notes {
                noteText
              }
              name
              number
              customerAttributes {
                canPickUpEarly
                canDeliverEarly
                dropYard
                canBreakAtFacility
                hasFacilities
              }
            }
            id
            address
            summaryAddress
            city
            state
            zipcode
            timezone
            appointmentSet
            appointmentStart
            appointmentEnd
            carrierArrival
            carrierDeparture
            completed
            locationType
            billOfLading
            geometry {
              type
              coordinates
            }
            distanceMiles
            customer {
              name
              number
            }
            notes {
              author
              text
            }
            # sealNumber
          }
          providerDetails {
            alternateIds {
              identifierType
              identifierValue
            }
          }
        }
      }
      assignedBrokers {
        id
        name
        usxId
      }
      numberOfAppointmentResets
      carrierBid {
        id
        price
        status
        bidHistory {
          id
          bidId
          event
          data {
            notes
            price
            status
          }
          createdAt
          createdBy {
            name
            carrier {
              id
            }
          }
        }
        carrierId
        carrier {
          name
          dot
        }
        loadId
        notes
        createdAt
        createdBy {
          id
          name
        }
        updatedAt
        updatedBy {
          id
          name
        }
        activeCounterOffer {
          id
          price
          notes
          status
        }
        counterOffers {
          id
          notes
        }
        sourceType
      }
      loadStatus
      id
      createdBy
      bookStatus
      carrier {
        name
        id
        dot
        mcNumber
        email
        phone
        owner
        thirdPartyTracking {
          trackingType
          mobileTrackingEnabled
          vehicleTrackingEnabled
        }
      }
      carrierBid {
        id
        price
        status
        carrierId
        sourceType
        notes
        activeCounterOffer {
          id
          price
          carrierId
          notes
        }
      }
      billTo {
        notes {
          noteText
        }
      }
      broker {
        name
        id
        usxId
        phone
      }
      drivers {
        id
        name
        phone
        priority
      }
      truck {
        id
        unitId
        type
        lastLocation {
          driverName
          unitId
          gpsLat
          gpsLon
          geohash {
            lat
            lon
          }
        }
        thirdPartyTracking {
          trackingType
          isSupported
        }
      }
      trailers {
        id
        trailerNumber
        trailerOwnerType
        trailerCompany
      }
      locations {
        customer {
          telephone
          notes {
            noteText
          }
          name
          number
          customerAttributes {
            canPickUpEarly
            canDeliverEarly
            dropYard
            canBreakAtFacility
            hasFacilities
          }
        }
        id
        address
        summaryAddress
        city
        state
        zipcode
        timezone
        appointmentSet
        appointmentStart
        appointmentEnd
        carrierArrival
        carrierDeparture
        completed
        locationType
        billOfLading
        geometry {
          type
          coordinates
        }
        distanceMiles
        customer {
          name
          number
        }
        notes {
          author
          text
        }
        # sealNumber
      }
      provider {
        id
        name
        detailType
        company
      }
      providerDetails {
        billTo
        billToName
        cxPriority
        equipmentType
        commodity
        weight: weightLbs
        quantity
        packingType
        operationsPriority
        regionalManagerName
        regionalManagerEmail
        regionalManagerPhone
        orderType
        internalNotes
        externalNotes
        cxrEmployeeId
        alternateIds {
          identifierType
          identifierValue
        }
      }
      paymentDetails {
        price
        revenue
        maxBuy
        ratePerMile
        distanceMiles
        bookedAt
      }
      bidDetails {
        offerCount
        lowestOffer
        lowestOfferCarrierName
      }
      restrictions {
        type
        value
      }
      rolledReasons {
        author
        createdAt
        rolledCode
        rolledCodeDescription
        rolledComment
      }
      contacts {
        contactName
        contactPhone
        contactEmail
      }
      milestones {
        authorId
        authorType
        comments {
          contact
          creatorId
          creatorType
          id
          text
          timestamp
          timezone
        }
        contact {
          email
          name
          phone
        }
        createdAt
        id
        lastUpdated
        loadLocationAddress
        location {
          latitude
          longitude
          timestamp
          timezone
        }
        locationId
        logs {
          editedBy
          editedByType
          id
          primaryEvent {
            latitude
            longitude
            timestamp
            timezone
          }
          secondaryEvent {
            latitude
            longitude
            timestamp
            timezone
          }
          subType
          timestamp
          status
        }
        type
      }
      rateConEmails {
        emailRecipient
      }
      trackingType
      trackingStatus {
        updatedAt
        usxOrderStatus
        usxOrderStatusReason
      }
      dispatchLocation {
        address
        lat
        lon
        timezone
        timestamp
        createdBy
        createdAt
        updatedBy
        updatedAt
        distanceToPickUpInMiles
      }
    }
  }
`;
