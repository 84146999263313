import { Carrier, User } from '@haulynx/types';
import { Record } from 'immutable';

export interface IAppSettingsState {
  user: User;
  isAppInit: boolean;
  carrier: Carrier;
  token: string;
  isLoading: boolean;
  loadFeedPermission: boolean;
  loadDetailsRedirectUrl: string;
  widgetReferrer: string;
}

const initialState = {
  isAppInit: false,
  carrier: null,
  user: null,
  token: null,
  isLoading: false,
  loadFeedPermission: false,
  loadDetailsRedirectUrl: null,
  widgetReferrer: null,
};

export class AppSettingsState extends Record(initialState) implements IAppSettingsState {
  isAppInit: boolean;
  carrier: Carrier;
  user: User;
  token: string;
  isLoading: boolean;
  loadFeedPermission: boolean;
  loadDetailsRedirectUrl: string;
  widgetReferrer: string;
}
