export interface LoadActiveStep {
  id: string | number;
  label: string;
  actionLabel: string;
  action?: string;
  actionData?: any;
  active?: boolean;
  date?: Date | string | null;
  info?: null;
  completed?: boolean;
  index?: number;
  timezone?: string;
  status?: string;
}
