import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Device } from '@haulynx/types';
@Component({
  selector: 'new-truck-dialog',
  template: `
    <h2 matDialogTitle>{{ device.id ? 'Edit' : 'Add New' }} Truck</h2>
    <mat-dialog-content>
      <div class="row">
        <mat-form-field class="col s6">
          <input matInput placeholder="Unit ID" data-test="unitIdField" [(ngModel)]="device.unitId" />
        </mat-form-field>
        <mat-form-field class="col s6">
          <input matInput placeholder="VIN Number" data-test="vinNumberField" [(ngModel)]="device.vin" />
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="col s4">
          <input matInput placeholder="Make" data-test="makeField" [(ngModel)]="device.make" />
        </mat-form-field>
        <mat-form-field class="col s4">
          <input matInput placeholder="Model" data-test="modelField" [(ngModel)]="device.model" />
        </mat-form-field>
        <mat-form-field class="col s4">
          <input matInput placeholder="Year" data-test="yearField" [(ngModel)]="device.year" type="number" />
        </mat-form-field>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button
        mat-button
        color="primary"
        data-test="save-button"
        [matDialogClose]="device"
        [disabled]="!(device.unitId && device.vin)"
      >
        Save
      </button>
      <button mat-button data-test="cancel-button" [matDialogClose]="">Cancel</button>
    </mat-dialog-actions>
  `,
})
export class NewTruckDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public device: Device, public dialogRef: MatDialogRef<NewTruckDialog>) {}
}
