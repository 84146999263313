import { Injectable } from '@angular/core';
import { User } from '@haulynx/types';
import { ApmService } from '@elastic/apm-rum-angular';
import { EnvironmentService } from '../environment/environment.service';
import { ApmBase } from '@elastic/apm-rum';
@Injectable()
export class ElasticRumAgentService {
  private instance: ApmBase;

  constructor(private apmService: ApmService, private environmentService: EnvironmentService) {
    this.init();
  }

  private init(): void {
    this.instance = this.apmService.init({
      serviceName: 'web-app',
      serverUrl: this.environmentService.getApmServerUrl(),
      environment: this.getRUMEnviroment(),
    });
  }

  setUser(user: User): void {
    this.instance.setUserContext({
      id: user.id,
      email: user.email,
    });
  }

  private getRUMEnviroment() {
    const enviroName = this.environmentService.getEnvironmentName() as string;
    if (enviroName === 'development') return 'dev';
    if (enviroName === 'production') return 'prod';
    return enviroName;
  }
}
