<div class="app-drop-down">
  <mat-icon *ngIf="selectedIcon" class="app-drop-down__icon" [svgIcon]="selectedIcon"></mat-icon>
  <input
    #autocompleteInput
    matInput
    [formControl]="keywordSearch"
    [matAutocomplete]="auto"
    [placeholder]="placeholder"
    (focus)="showPanel($event, auto)"
    type="text"
    tab-directive
    class="{{ selectedClass }}"
    [ngClass]="{ disabled: keywordSearch.disabled }"
  />
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="select($event)" (opened)="panelOpen()" panelWidth="auto">
    <mat-option *ngIf="entityType === entityTypes.TRUCKS" class="header" disabled> Truck Number </mat-option>
    <mat-option *ngIf="entityType === entityTypes.TRAILERS && trailerOwner !== owner.USXI" class="header" disabled>
      Trailer Number
    </mat-option>
    <mat-option *ngIf="entityType === entityTypes.TRAILERS && trailerOwner === owner.USXI" class="header" disabled>
      From USXI
    </mat-option>

    <div class="list" *ngIf="entityType !== entityTypes.TRACKING_TYPES">
      <mat-option
        *ngFor="let item of filteredData | async; let i = index; trackBy: trackByFn"
        [value]="item[key]"
        [attr.data-cy]="cypressName"
      >
        <ng-container *ngTemplateOutlet="templateLabel; context: { row: item }"></ng-container>
        <div *ngIf="!templateLabel && entityType !== entityTypes.PHONES && entityType !== entityTypes.EMAILS">
          <mat-icon *ngIf="item['svgIcon']" [svgIcon]="item['svgIcon']"></mat-icon>
          {{ item[displayLabel] }}
        </div>
        <div *ngIf="(!templateLabel && entityType === entityTypes.PHONES) || entityType === entityTypes.PHONES">
          <mat-icon *ngIf="item['svgIcon']" [svgIcon]="item['svgIcon']"></mat-icon>
          {{ item[displayLabel] | phoneMask: 'xxx-xxx-xxxx' }}
          <i *ngIf="entityType === entityTypes.PHONES && i === 0" class="contact-type">Primary Phone</i>
          <i *ngIf="entityType === entityTypes.PHONES && i === 1" class="contact-type"
            >Secondary Phone<mat-icon svgIcon="haulynx-pen"></mat-icon
          ></i>
          <i *ngIf="entityType === entityTypes.EMAILS && i === 0" class="contact-type">Primary Email</i>
          <i *ngIf="entityType === entityTypes.EMAILS && i === 1" class="contact-type"
            >Secondary Email<mat-icon svgIcon="haulynx-pen"></mat-icon
          ></i>
        </div>
      </mat-option>
    </div>

    <div class="tracking-list" *ngIf="entityType === entityTypes.TRACKING_TYPES">
      <mat-option
        *ngFor="let item of filteredData | async; trackBy: trackByFn"
        [value]="item[key]"
        [attr.data-cy]="cypressName"
      >
        <ng-container *ngTemplateOutlet="templateLabel; context: { row: item }"></ng-container>
        <div *ngIf="!templateLabel">
          <mat-icon *ngIf="item['svgIcon']" [svgIcon]="item['svgIcon']"></mat-icon>
          {{ item[displayLabel] }}
          <i *ngIf="item[key] === trackingTypes.MACRO_POINT_ELD"> (Recommended) </i>
          <i *ngIf="item[key] === trackingTypes.MACRO_POINT"> (Type confirmed after booking) </i>
          <span *ngIf="item[key] === trackingTypes.MACRO_POINT_ELD">
            <mat-chip class="tracking-chips">ELD</mat-chip>
          </span>
        </div>
      </mat-option>
    </div>

    <mat-option
      #matOption2
      *ngIf="entityType === entityTypes.TRUCKS || (entityType === entityTypes.TRAILERS && trailerOwner !== owner.USXI)"
      class="create-truck-button"
    >
      <button
        class="hlx-button-command hlx-button-command--cancel hlx-button-command--border-white bulk-actions-dialog__cancel-button"
        data-test="create-button"
      >
        Create
      </button>
    </mat-option>
  </mat-autocomplete>
  <app-spinner [active]="isLoading" diameter="18"></app-spinner>
  <mat-icon
    [ngClass]="disabled ? 'icon-disabled' : 'icon-not-disabled'"
    *ngIf="!hideIcon"
    (click)="showPanel($event, auto)"
    data-test="app-dropdown-arrow"
    >{{ auto.isOpen ? 'expand_less' : 'expand_more' }}</mat-icon
  >
</div>
