export enum CarrierSearchListRoute {
  ACTIVE = 'active',
  ASSIGNED = 'assigned',
  AVAILABLE = 'available',
  BIDS = 'bids',
  BIDS_AND_OFFERS = 'offers',
  COMPLETED = 'completed',
  CREATE = 'create',
  DELIVERED = 'delivered',
  IN_TRANSIT = 'transit',
  MVP_ACTIVE_LOADS = 'myloads',
  OFFERS = 'offer',
  OVERVIEW = 'overview',
  RECOMMENDED = 'recommended',
  TEST_LOADS = 'testloads',
  UNASSIGNED = 'unassigned',
}

export enum CarrierActiveListRoute {
  ACTIVE = 'active',
  ASSIGNED = 'assigned',
  DELIVERED = 'delivered',
  IN_TRANSIT = 'transit',
  UNASSIGNED = 'unassigned',
}

export const CarrierSearchListRouteToDisplayListName = (listName: CarrierSearchListRoute): string => {
  switch (listName) {
    case CarrierSearchListRoute.ACTIVE:
      return 'All Active Loads';
    case CarrierSearchListRoute.ASSIGNED:
      return 'Assigned Loads';
    case CarrierSearchListRoute.AVAILABLE:
      return 'All Available Loads';
    case CarrierSearchListRoute.BIDS_AND_OFFERS:
      return 'Bids/Offers';
    case CarrierSearchListRoute.COMPLETED:
      return 'Completed Loads';
    case CarrierSearchListRoute.DELIVERED:
      return 'Delivered Loads';
    case CarrierSearchListRoute.IN_TRANSIT:
      return 'In Transit Loads';
    case CarrierSearchListRoute.RECOMMENDED:
      return 'Recommendations';
    case CarrierSearchListRoute.TEST_LOADS:
      return 'Test Loads';
    case CarrierSearchListRoute.UNASSIGNED:
      return 'Unassigned Loads';
    default:
      return '';
  }
};
