import gql from 'graphql-tag';

export const DeleteBidMutation = gql`
  mutation DeleteBid($bidId: StrictId!) {
    deleteBid(bidId: $bidId) {
      id
      brokerId
      price
      status
      carrier {
        id
        dot
      }
      carrierId
      loadId
      notes
      createdAt
      createdBy {
        id
        name
      }
      updatedAt
      updatedBy {
        id
        name
      }
    }
  }
`;
