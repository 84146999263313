import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CommonEntitiesEffects } from '../../shared/common-entities/common-entities.effects';
import { trailerStateProviders, TRAILER_ENTITY_REDUCERS_TOKEN } from './config';
import { trailerEntityNamespace } from './namespace';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(trailerEntityNamespace, TRAILER_ENTITY_REDUCERS_TOKEN),
    EffectsModule.forFeature([CommonEntitiesEffects]),
  ],
  providers: [...trailerStateProviders],
})
export class AsyncEntityTrailerStoreModule {}
