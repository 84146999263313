import { animate, style, transition, trigger } from '@angular/animations';
import { getLocaleDateFormat, TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { AddressPipe } from '@haulynx/pipes';
import { AnalyticsService, CarrierToolbarService, WindowRef } from '@haulynx/services';
import {
  CarrierEntityService,
  CarrierLoadSearchEntityService,
  LoadEntityService,
  LoadFeedModel,
  UserEntityService,
} from '@haulynx/store';
import {
  ANALYTICS_EVENT,
  Bid,
  BidHistory,
  BidSortTypes,
  BookStatus,
  Carrier,
  CarrierToolbarDetailsPage,
  CarrierToolbarDropdownGroups,
  CarrierToolbarState,
  CarrierToolbarSubTab,
  CarrierToolbarTab,
  FeatureFlag,
  FFState,
  KeyValuePair,
  LoadOverviewMainRoute,
  LoadsServiceLoad,
  Opportunity,
  RecommendedCarriers,
  User,
} from '@haulynx/types';
import { aliveWhile, formatLocations } from '@haulynx/utils';
import { findIndex, orderBy } from 'lodash';
import { BehaviorSubject, combineLatest, merge, Observable } from 'rxjs';
import { distinctUntilChanged, debounceTime, filter, map, takeUntil, tap, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'haulynx-carrier-toolbar',
  templateUrl: './carrier-toolbar.component.html',
  styleUrls: ['./carrier-toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(50%)', opacity: 0 }),
        animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({ transform: 'translateX(50%)', opacity: 0 })),
      ]),
    ]),
  ],
})
export class CarrierToolbarComponent implements OnInit, OnDestroy {
  loads$: Observable<LoadsServiceLoad[]>;
  selectedLoad$: Observable<LoadsServiceLoad>;
  state$: Observable<CarrierToolbarState>;

  featureFlag: FeatureFlag = FeatureFlag.CARRIER_TOOLBAR;
  featureFlags: FFState;
  selectedLoad: LoadsServiceLoad;
  loadOptions$: Observable<{ label: string; value: string; group: string }[]>;

  alive = aliveWhile();
  latestBid$: Observable<Bid> = null;
  bidHistory$ = new BehaviorSubject<{ [bidId: string]: BidHistory[] }>({});
  sortedBids$ = new BehaviorSubject<Bid[]>([]);
  carrier$ = new BehaviorSubject<Carrier>(null);
  user: User;
  carrierToolbarDetailsPage = CarrierToolbarDetailsPage;
  featureFlags$: Observable<FFState>;
  carrierDot = new BehaviorSubject(null);
  @Input() showOptions? = true;

  constructor(
    private analytics: AnalyticsService,
    private router: Router,
    private carrierToolbarService: CarrierToolbarService,
    private titlecasePipe: TitleCasePipe,
    private addressPipe: AddressPipe,
    public carrierEntityService: CarrierEntityService,
    private windowRef: WindowRef,
    public carrierLoadsServiceEntityService: CarrierLoadSearchEntityService,
    private loadFeedModel: LoadFeedModel,
    private userEntityService: UserEntityService,
    private loadEntityService: LoadEntityService,
    private carrierService: CarrierEntityService
  ) {
    this.userEntityService.user$.pipe(takeUntil(this.alive)).subscribe((user) => {
      this.user = user;
    });

    this.latestBid$ = merge(
      this.loadFeedModel.bidEntitiesList$.pipe(
        withLatestFrom(this.carrierToolbarService.selectedLoad$, this.carrier$),
        map(([bidList, selectedLoad, carrier]) => {
          if (bidList?.length && selectedLoad?.id === bidList[0]?.loadId) {
            //sort by date
            this.sortedBids$.next(orderBy(bidList, [BidSortTypes.RECENT], ['desc']));
            let sortedBids = this.sortedBids$.value.filter((bid) => bid?.createdBy?.id === this.user?.id);

            //filter by selected carrier
            if (carrier) {
              sortedBids = sortedBids.filter((val) => val.carrier?.dot === carrier?.dot);
            }
            return sortedBids[0] ? sortedBids[0] : null;
          }
        })
      ),
      this.carrierLoadsServiceEntityService.placeBidManager.onSuccess$.pipe(
        withLatestFrom(this.carrierToolbarService.selectedLoad$, this.carrier$),
        map(([newBid, selectedLoad, carrier]) => {
          if (newBid && selectedLoad?.id === newBid?.loadsServiceLoad?.id && carrier?.dot === newBid?.carrier?.dot) {
            return newBid;
          }
        })
      )
    );
  }

  ngOnInit(): void {
    this.featureFlags$ = this.userEntityService.featureFlags$;
    this.loads$ = this.carrierToolbarService.loads$;
    this.state$ = this.carrierToolbarService.state$;
    this.selectedLoad$ = this.carrierToolbarService.selectedLoad$.pipe(
      tap((load) => {
        if (load) {
          this.loadFeedModel.searchBids(load?.id);
          this.loadFeedModel.getBidHistory(load?.id);
        }
        this.selectedLoad = load;
      })
    );

    this.userEntityService.featureFlags$
      .pipe(
        takeUntil(this.alive),
        map((featureFlags) => {
          this.featureFlags = featureFlags;
        })
      )
      .subscribe();

    this.carrierService.createCarrierNotesManager.onSuccess$
      .pipe(debounceTime(5000), takeUntil(this.alive))
      .subscribe(() => {
        this.carrierEntityService.getMergedCarrier.dispatch({ dot: this.carrierDot.value });
      });

    this.carrierToolbarService.selectors.detailsPage$
      .pipe(
        distinctUntilChanged(),
        filter((detailsPage) => detailsPage === CarrierToolbarDetailsPage.CARRIER_PROFILE),
        withLatestFrom(this.state$)
      )
      .subscribe(([detailsPage, state]) => {
        this.carrierDot.next(state.data.carrierDot);
        this.carrierEntityService.getMergedCarrier.dispatch({ dot: state.data.carrierDot });
      });

    this.loadOptions$ = this.carrierToolbarService.selectedLoads$.pipe(
      withLatestFrom(this.carrierToolbarService.loads$),
      map(([selectedLoads, loads]) => {
        const options: { label: string; value: string; group: string }[] = [];

        options.push(
          ...selectedLoads.map((load) => {
            return {
              label: formatLocations(load, this.titlecasePipe, this.addressPipe),
              value: load.id,
              group: CarrierToolbarDropdownGroups.SELECTED_LOADS,
            };
          })
        );
        for (let i = 0, recentCount = 0; i < loads.length; i++) {
          const index = findIndex(selectedLoads, (load) => load?.id === loads[i]?.id) ?? -1;
          if (index === -1) {
            options.push({
              label: formatLocations(loads[i], this.titlecasePipe, this.addressPipe),
              value: loads[i]?.id,
              group: CarrierToolbarDropdownGroups.RECENT_LOADS,
            });
            recentCount++;
          }
          if (recentCount === 5) break;
        }

        return options;
      })
    );

    // Bid History comes off of the bid object and is immediately available...
    this.loadFeedModel.bidEntitiesList$.pipe(takeUntil(this.alive)).subscribe((bids) => {
      const bidHistory: { [bidId: string]: BidHistory[] } = bids.reduce((prev, curr) => {
        prev[curr.id] = curr.bidHistory;
        return prev;
      }, {});
      this.bidHistory$.next(bidHistory);
    });
    // ... but we can also get bid history separately. So we subscribe to both places to be sure
    // we have the latest Bid History data in our UI
    this.loadFeedModel.bidHistory$.pipe(takeUntil(this.alive)).subscribe((bidHistory) => {
      this.bidHistory$.next({ ...this.bidHistory$.value, ...bidHistory });
    });

    combineLatest([this.featureFlags$, this.carrierEntityService.getMergedCarrier.onResponse$])
      .pipe(takeUntil(this.alive))
      .subscribe(([features, mergedCarrier]) => {
        if (mergedCarrier) {
          this.loadEntityService.getLoadsMetaDataManager.dispatch({
            query: {
              searchParameters: {
                includeBookedAt: 'max',
                carrierNameOrDot: [mergedCarrier.dot, mergedCarrier.name],
                bookStatus: [BookStatus.BOOKED],
                showTestLoads: features[FeatureFlag.TEST_LOADS],
              },
            },
          });
        }
      });
  }

  tabChange(event: MatTabChangeEvent): void {
    this.carrierToolbarService.updateState({ currentTab: event.index });
  }

  overviewRoute(
    segment: LoadOverviewMainRoute | string,
    event: {
      carrier: any;
      loadId: string;
    },
    newPage: boolean
  ): Promise<boolean> {
    let url: string;
    const dot = event.carrier?.dot || event.carrier?.carrier?.dot;
    if (segment === 'carrier') {
      url = `/dashboard/carriers/view/${dot}`;
    } else if (segment === 'booking' || segment === 'capacity') {
      url = `/loads/${event['loadId']}/overview/${segment}`;
    }

    if (!newPage) {
      return this.router.navigate([url], { queryParams: { dot } });
    }
    this.windowRef.getNativeWindow().open(url, '_blank');
  }

  onCarrierActionClicked(event: {
    event: KeyValuePair;
    carrier: RecommendedCarriers;
    loadId: string;
    currentTab: CarrierToolbarTab;
  }) {
    switch (event.event.key) {
      case 'Book with Carrier': {
        this.callAnalytics(event.currentTab, event.carrier, 'BOOK_CLICKED');
        this.overviewRoute(LoadOverviewMainRoute.booking, event, false);
        break;
      }
      case 'Bid with Carrier': {
        this.overviewRoute(LoadOverviewMainRoute.capacity, event, true);
        break;
      }
    }
  }

  callAnalytics(currentTab: CarrierToolbarTab, carrier: RecommendedCarriers, button: string) {
    switch (currentTab) {
      case 0:
        this.analytics.logEvent(ANALYTICS_EVENT['CARRIER_TOOLBAR_REC_' + button], { clickedCarrier: carrier });
        return;
      case 1:
        this.analytics.logEvent(ANALYTICS_EVENT['CARRIER_TOOLBAR_SEARCH_' + button], { clickedCarrier: carrier });
        return;
      case 2:
        this.analytics.logEvent(ANALYTICS_EVENT['CARRIER_TOOLBAR_OWNED_' + button], { clickedCarrier: carrier });
        return;
    }
  }

  submitBid(payload: Partial<Bid>): void {
    if (this.selectedLoad) {
      const newBid: Partial<Bid> = { ...payload, loadId: this.selectedLoad.id };
      this.carrierLoadsServiceEntityService.placeBidManager.dispatch(this.selectedLoad.id || '', {
        newBid: newBid,
      });
    }
  }

  newCarrier(carrier: Carrier) {
    this.loadFeedModel.searchBids(this.selectedLoad?.id);
    this.loadFeedModel.getBidHistory(this.selectedLoad?.id);
    this.carrier$.next(carrier);
  }

  ngOnDestroy() {
    this.alive.destroy();
  }

  toggleCarrierToolbar(): void {
    this.carrierToolbarService.toggleToolbarStatus();
  }

  /**
   * When carrier profile click retreive all related carrier data
   *
   * NOTE: Carrier data is currently spread out to different databases
   * Need to call different endpoints to retreive each one
   *
   * @param {Opportunity} event index at which reccomended card has been clicked
   *
   */
  onOpenCarrierProfile(event: Opportunity | RecommendedCarriers | Carrier) {
    this.carrierToolbarService.updateState({
      detailsPage: CarrierToolbarDetailsPage.CARRIER_PROFILE,
      data: {
        carrierDot: event['dot'] ?? event['carrier']?.dot,
        selectedTab: CarrierToolbarSubTab.POSTED_TRUCKS,
      },
    });
  }

  onFinishViewingProfile(event: Event) {
    this.carrierToolbarService.updateState({
      detailsPage: null,
      data: null,
    });

    if (event) {
      event.stopPropagation();
    }
  }

  onCarrierProfileToggleDetails(show: boolean) {
    this.carrierToolbarService.updateState({
      data: {
        toggledElements: { carrierProfileToggleDetails: show },
      },
    });
  }

  onCarrierProfileTabChange(event: MatTabChangeEvent) {
    this.carrierToolbarService.updateState({
      data: {
        selectedTab: event.index,
      },
    });
  }
}
