import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FireDatabaseService, LoadFeedFireDatabaseService } from '@haulynx/services';
import { LoadFeedModel } from '@haulynx/store';

@Component({
  selector: 'app-load-feed',
  templateUrl: './load-feed.component.html',
  styleUrls: ['./load-feed.component.scss'],
})
export class LoadFeedContainer implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public loadFeedModel: LoadFeedModel,
    private loadFeedFireDatabaseService: LoadFeedFireDatabaseService,
    private fireDatabaseService: FireDatabaseService
  ) {}

  ngOnInit(): void {
    this.fireDatabaseService.database.goOnline();
    this.loadFeedFireDatabaseService.database.goOnline();
  }

  ngOnDestroy(): void {
    // TODO: Need to move into actions
    this.fireDatabaseService.database.goOffline();
    this.loadFeedFireDatabaseService.database.goOffline();
  }
}
