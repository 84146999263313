import { Injectable } from '@angular/core';
import { FetchUSXOrderStatus, GetLoadsServiceLoadById } from '@haulynx/gql';
import { LoadsServiceLoad, LoadIdentifierType } from '@haulynx/types';
import { isBoolean } from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { GraphqlService } from '../graphql.service';
import { getLoadsServiceLoadAlternateId } from '@haulynx/utils';
import { LoadsServiceService } from '../../loads-service/loads-service.service';

@Injectable({
  providedIn: 'root',
})
export class LoadByIdService {
  constructor(public graphqlService: GraphqlService, private loadsServiceService: LoadsServiceService) {}

  get(loadId: string, isLatest = false): Observable<LoadsServiceLoad> {
    return of(loadId).pipe(
      concatMap((id) => this.getLoadById(id)),
      concatMap((load: LoadsServiceLoad) => {
        const orderNumber =
          load.locations &&
          load.locations.length &&
          getLoadsServiceLoadAlternateId(load, LoadIdentifierType.BILL_OF_LADING);

        return isLatest ? this.fetchUSXOrderStatus(orderNumber).pipe(catchError((err) => of(load))) : of(load);
      }),
      concatMap((load: LoadsServiceLoad) => {
        if (isBoolean(load)) {
          return this.getLoadById(loadId);
        } else {
          return of(load);
        }
      })
    );
  }

  private getLoadById(loadId: string): Observable<LoadsServiceLoad> {
    return this.loadsServiceService.getLoadById(loadId);
  }

  private fetchUSXOrderStatus(orderNumber: string): Observable<boolean> {
    return this.graphqlService
      .mutate({
        mutation: FetchUSXOrderStatus,
        variables: { orderNumber },
      })
      .pipe(map((res) => !!(res && res.data && res.data['fetchUSXOrderStatus'])));
  }
}
