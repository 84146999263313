import { ChangeDetectionStrategy, Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FromCamelCasePipe, LoadRestrictionsPipe, ToCamelCasePipe } from '@haulynx/pipes';
import { PermissionsService } from '@haulynx/services';
import {
  BookStatus,
  EquipmentType,
  equipmentTypes,
  EquipmentTypeToLoadsEquipmentType,
  EquipmentTypeToLoadsServiceEquipmentType,
  LoadIdentifierType,
  LoadsServiceBrokerEditFields,
  LoadsServiceEquipmentType,
  LoadsServiceLoad,
  LoadsServiceLoadStatus,
  LoadsServiceRestrictionTypes,
  LoadsServiceTrailerInstructions,
  OrderTypeUSX,
} from '@haulynx/types';
import { getLoadRestriction } from '@haulynx/utils';
import { find } from 'lodash';

@Component({
  selector: 'haulynx-edit-load-details-dialog',
  templateUrl: './edit-load-details-dialog.component.html',
  styleUrls: ['./edit-load-details-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditLoadDetailsDialogComponent implements OnInit {
  form: FormGroup;
  loadIdentifierType = LoadIdentifierType;
  loadsServiceRestrictionTypes = LoadsServiceRestrictionTypes;
  isFinalled: boolean;
  preBooking: boolean;
  writePermissions: USXPermissions;
  equipmentOptions = equipmentTypes;
  trailerInstrOptions = [
    { label: 'Live Pick Up / Drop Trailer', value: LoadsServiceTrailerInstructions.LivePickupDropTrailer },
    { label: 'Live Pick Up / Live Dropoff', value: LoadsServiceTrailerInstructions.LivePickupLiveDropoff },
    { label: 'Preloaded / Drop Trailer', value: LoadsServiceTrailerInstructions.PreloadedDropTrailer },
    { label: 'Preloaded / Live Dropoff', value: LoadsServiceTrailerInstructions.PreloadedLiveDropoff },
  ];
  freightMixOptions = [
    { label: 'Primary', value: 'primary' },
    { label: 'Secondary', value: 'secondary' },
    { label: 'Spot', value: 'spot' },
  ];
  binaryOptions = [{ value: 'Yes' }, { value: 'No' }];
  highValueOptions = [
    { label: 'High Value', value: false },
    { label: 'Exception / High Value', value: true },
  ];

  constructor(
    private fb: FormBuilder,
    private permissionService: PermissionsService,
    public loadRestrictionsPipe: LoadRestrictionsPipe,
    public fromCamelCase: FromCamelCasePipe,
    public toCamelCasePipe: ToCamelCasePipe,
    @Optional() private dialogRef: MatDialogRef<EditLoadDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { load: LoadsServiceLoad; brokers: { id: string; label: string }[] }
  ) {}

  ngOnInit(): void {
    this.isFinalled = this.data.load.loadStatus === LoadsServiceLoadStatus.FINALLED;
    this.preBooking =
      this.data.load.bookStatus === BookStatus.VIEWABLE || this.data.load.bookStatus === BookStatus.BOOKABLE;
    this.form = this.fb.group({
      price: [this.data.load.paymentDetails?.price],
      weight: [this.data.load.providerDetails?.weight, [Validators.required, Validators.pattern('^[0-9]\\d*(\\d+)?$')]],
      commodity: [this.data.load.providerDetails?.commodity],
      equipment: [
        EquipmentTypeToLoadsEquipmentType(this.data.load.providerDetails?.equipmentType),
        [Validators.required],
      ],
      trailerInstr: [this.setInitialTrailerInstruction()],
      freightMix: [this.setInitialFreightMix(), [Validators.required]],
      tankerEndors: [this.setInitialTankerEndors(), [Validators.required]],
      hazmat: [this.setInitialHazmat(), [Validators.required]],
      highValueException: [this.setInitialHighValueException()],
    });
    if (!this.preBooking) {
      this.form.addControl('broker', new FormControl(this.setInitialBroker(), [Validators.required]));
    }
    const isHighValueProduct = getLoadRestriction(
      this.data.load,
      LoadsServiceRestrictionTypes.HIGH_VALUE_PRODUCT
    ) as boolean;
    const highValueException: boolean = this.getOnlyAdmin() && isHighValueProduct;

    if (!isHighValueProduct) {
      this.highValueOptions = [{ label: 'Not High Value', value: false }];
    }
    this.writePermissions = {
      price: this.getAdminAndBroker(),
      weight: this.getAdminAndBroker(),
      commodity: this.getAdminAndBroker(),
      equipment: this.getAdminAndBroker(),
      broker: this.getOnlyAdmin(),
      trailerInstr: this.getAdminAndBroker(),
      freightMix: this.getOnlyAdmin(),
      tankerEndors: this.getAdminAndBroker(),
      hazmat: this.getOnlyAdmin(),
      highValueException,
    };
  }

  save(): void {
    const equipmentType = EquipmentTypeToLoadsServiceEquipmentType(
      find(EquipmentType, (item) => item === this.form.get('equipment')?.value)
    ) as unknown as LoadsServiceEquipmentType;

    const highValueException: { highValueException?: boolean } = this.form.get('highValueException').dirty
      ? { highValueException: this.form.get('highValueException').value }
      : {};

    const payload: Partial<LoadsServiceBrokerEditFields> = {
      price: this.form.get('price')?.value,
      weight: this.form.get('weight')?.value,
      commodity: this.form.get('commodity')?.value,
      equipmentType: equipmentType,
      brokerId: this.getBroker(),
      trailerInstructions: this.form.get('trailerInstr')?.value,
      orderType: find(OrderTypeUSX, (item) => item === this.form.get('freightMix')?.value) as unknown as OrderTypeUSX,
      tankerCertificationRequired: this.form.get('tankerEndors')?.value === 'Yes' ? true : false,
      hazmatRequired: this.form.get('hazmat').value === 'Yes' ? true : false,
      ...highValueException,
    };
    this.dialogRef.close(payload);
  }

  close(): void {
    this.dialogRef.close();
  }

  private getBroker() {
    const brokerId = this.data.brokers.find((vals) => vals.id === this.form.get('broker')?.value)?.id;
    return brokerId ? brokerId : this.data.load.broker.usxId;
  }

  private setInitialBroker() {
    return this.data.load.broker.usxId;
  }

  private setInitialTrailerInstruction(): string {
    const pickup = this.loadRestrictionsPipe.transform(
      this.data.load.restrictions,
      this.loadsServiceRestrictionTypes.PICKUP_REQUIREMENTS
    );
    const dropoff = this.loadRestrictionsPipe.transform(
      this.data.load.restrictions,
      this.loadsServiceRestrictionTypes.DROPOFF_REQUIREMENTS
    );

    if (pickup === 'Live Pickup' && dropoff === 'Drop Trailer') {
      return this.trailerInstrOptions[0].value;
    }
    if (pickup === 'Live Pickup' && dropoff === 'Live Dropoff') {
      return this.trailerInstrOptions[1].value;
    }
    if (pickup === 'Preloaded' && dropoff === 'Drop Trailer') {
      return this.trailerInstrOptions[2].value;
    }
    if (pickup === 'Preloaded' && dropoff === 'Live Dropoff') {
      return this.trailerInstrOptions[3].value;
    } else {
      return null;
    }
  }

  private setInitialFreightMix(): string {
    if (this.freightMixOptions.some((val) => val.value === this.data.load.providerDetails?.orderType)) {
      return this.freightMixOptions.find((val) => val.value === this.data.load.providerDetails?.orderType)?.value;
    } else {
      const additionalOptions = [
        { label: 'Dedicated', value: 'dedicated' },
        { label: 'Transactional', value: 'transactional' },
        { label: 'Contracted', value: 'contracted' },
      ];
      if (additionalOptions.some((val) => val.value === this.data.load.providerDetails?.orderType)) {
        const irregularOption = additionalOptions.find(
          (val) => val.value === this.data.load.providerDetails?.orderType
        );
        this.freightMixOptions.push(irregularOption);
        return irregularOption.value;
      }
    }
    return null;
  }

  private setInitialTankerEndors(): string {
    return this.loadRestrictionsPipe.transform(
      this.data.load.restrictions,
      this.loadsServiceRestrictionTypes.TANKER_CERTIFICATION_REQUIRED
    )
      ? 'Yes'
      : 'No';
  }

  private setInitialHazmat(): string {
    return this.loadRestrictionsPipe.transform(this.data.load.restrictions, this.loadsServiceRestrictionTypes.HAZMAT)
      ? 'Yes'
      : 'No';
  }

  private getAdminAndBroker(): boolean {
    if (this.permissionService.isBrokerAdmin()) return true;
    if (this.permissionService.isBroker()) return true;
    return false;
  }

  private getOnlyAdmin(): boolean {
    if (this.permissionService.isBrokerAdmin()) return true;
    return false;
  }

  private setInitialHighValueException(): boolean {
    return (
      (getLoadRestriction(this.data.load, LoadsServiceRestrictionTypes.HIGH_VALUE_PRODUCT_EXCEPTION) as boolean) ??
      false
    );
  }
}

export interface USXPermissions {
  [key: string]: any;
}
