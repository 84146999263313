import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { buttonTypes, KeyValuePair, RolledReason, RolledReasonCode } from '@haulynx/types';
import { aliveWhile, fieldValDependency } from '@haulynx/utils';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { reasons } from './rolled-loads-dialog.config';

@Component({
  selector: 'rolled-loads-dialog',
  templateUrl: './rolled-loads-dialog.component.html',
  styleUrls: ['./rolled-loads-dialog.component.scss'],
})
export class RolledLoadsDialogComponent implements OnInit {
  myForm: FormGroup;
  alive = aliveWhile();
  actionButtons = [buttonTypes.MILESTONE_CANCEL, buttonTypes.SUBMIT_REPORT];
  disableButtons = [buttonTypes.SUBMIT_REPORT];
  isLoading$ = new BehaviorSubject<boolean>(false);
  valueSelected = false;
  reasons: KeyValuePair[] = reasons;

  constructor(
    public dialogRef: MatDialogRef<RolledLoadsDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      author: string;
    }
  ) {
    this.myForm = this.fb.group({
      reason: ['', [Validators.required, Validators.minLength(1)]],
      comment: [''],
    });

    this.myForm.setValidators(
      fieldValDependency('reason', 'comment', [
        this.reasons.find((reason) => reason.key === RolledReasonCode.OTHER_CARRIER_RELATED).value as string,
        this.reasons.find((reason) => reason.key === RolledReasonCode.OTHER_CX_RELATED).value as string,
      ])
    );
  }
  ngOnInit(): void {
    this.myForm.valueChanges.pipe(takeUntil(this.alive)).subscribe(() => {
      if (this.myForm.get('reason').value !== '') {
        this.valueSelected = true;
      }

      if (this.myForm.invalid) {
        this.actionButtons = [buttonTypes.MILESTONE_CANCEL, buttonTypes.SUBMIT_REPORT];
        this.disableButtons = [buttonTypes.SUBMIT_REPORT];
      } else {
        this.actionButtons = [buttonTypes.MILESTONE_CANCEL, buttonTypes.SUBMIT_REPORT];
        this.disableButtons = [];
      }
    });
  }

  submitReport(): void {
    const reason = this.reasons.find((reason) => {
      return reason.value === this.myForm.get('reason').value;
    });
    const result: RolledReason = {
      author: this.data.author,
      createdAt: new Date().valueOf(),
      rolledCode: reason.key as RolledReasonCode,
      rolledComment: this.myForm.get('comment').value,
    };
    this.dialogRef.close(result);
  }

  actionButtonClick(event: string): void {
    if (event === buttonTypes.CANCEL.action) {
      this.dialogRef.close(null);
    } else if (event === buttonTypes.SUBMIT_REPORT.action) {
      this.isLoading$.next(true);
      this.submitReport();
    }
  }
}
