import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DateTimeAdapter } from '@danielmoncada/angular-datetime-picker';
import { CarrierInfoSectionVmServiceV2, TrackingTypeOptionsV2 } from '@haulynx/services';
import { CarrierEntityService } from '@haulynx/store';
import {
  AssignDriverForm,
  BookStatus,
  Carrier,
  CarrierAdmin,
  CarrierSearchResult,
  ComplianceState,
  FeatureFlag,
  FFState,
  LoadCarrierContact,
  LoadsServiceLoad,
  TrackingType,
  VehicleEntityTypes,
} from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { find } from 'lodash';
import { OverlayPanel } from 'primeng/overlaypanel';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-carrier-info-section-v2',
  templateUrl: './carrier-info-section-v2.component.html',
  styleUrls: ['./carrier-info-section-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarrierInfoSectionComponentV2 implements OnChanges, OnInit, OnDestroy {
  @ViewChild('carrierToolTip', { static: true }) driverToolTip: OverlayPanel;

  @Input() carrier: Carrier;
  @Input() carrierAdmin: CarrierAdmin;
  @Input() carriers: CarrierSearchResult[] = [];
  @Input() carrierForm: FormGroup;
  @Input() assignmentForm: FormGroup;
  @Input() featureFlags: FFState;
  @Input() assignmentFormData: Partial<AssignDriverForm>;
  @Input() isLoadingCarriers = false;
  @Input() carrierContact: LoadCarrierContact[];
  @Input() assignmentLoading = false;
  @Input() loadsServiceLoad: LoadsServiceLoad;
  @Input() carrierComplianceState: ComplianceState;
  @Input() carrierComplianceLoading: boolean;
  @Output() carrierSearch = new EventEmitter<string>();
  @Output() carrierSelect = new EventEmitter<CarrierSearchResult>();
  @Output() assignmentDataChange = new EventEmitter<AssignDriverForm>();
  @Output() saveCarrierInstance = new EventEmitter();
  @Output() createCarrierCompliance = new EventEmitter();
  @Output() carrierCompliance = new EventEmitter<boolean>();

  carrierSearchDebounce: Subject<string> = new Subject<string>();
  entityTypes = VehicleEntityTypes;
  trackingType = TrackingType;
  trackingOptions: TrackingTypeOptionsV2[] = [];
  trackingFeatureFlag = FeatureFlag.TRACKING_TYPE;
  private alive = aliveWhile();
  editCarrier = false;
  initalData = {
    carrier: null,
    mcNumber: null,
    contact: null,
    phone: null,
    email: null,
  };
  disableCarrierMC = false;
  enableButton = new BehaviorSubject(false);
  selectedCarrier;
  pendingCompliance = false;
  requestCompliance = true;
  enableCompliance = true;
  complianceFeature = FeatureFlag.CARRIER_COMPLIANCE;
  insuranceExp: number;
  today: number;
  tooltipTextSucess = 'Carrier is compliant';
  tooltipTextError = 'This carrier is not compliant.\nSend a ping to compliance to update your carrier’s insurance.';

  constructor(
    private cd: ChangeDetectorRef,
    private carrierInfoSectionVmService: CarrierInfoSectionVmServiceV2,
    public carrierEntityService: CarrierEntityService
  ) {
    this.carrierSearchDebounce
      .pipe(distinctUntilChanged(), debounceTime(500), takeUntil(this.alive))
      .subscribe((value) => {
        this.carrierSearch.emit(value);
        this.cd.markForCheck();
      });
  }

  prepareSearchResult(newCarrierInfo: CarrierSearchResult): {
    carrier: string;
    mcNumber: string;
    contact: string;
    phone: string;
    email: string;
  } {
    const data = {
      carrier: newCarrierInfo?.dot,
      mcNumber: newCarrierInfo?.mcNumber,
      contact:
        this.carrierContact && this.carrierContact?.[0]?.contactName
          ? this.carrierContact[0].contactName
          : newCarrierInfo?.adminName,
      phone:
        this.carrierContact && this.carrierContact?.[0]?.contactPhone
          ? this.carrierContact[0].contactPhone
          : newCarrierInfo?.phone,
      email:
        this.carrierContact && this.carrierContact?.[0]?.contactEmail
          ? this.carrierContact[0].contactEmail
          : newCarrierInfo?.email,
    };
    this.initalData = data;
    return data;
  }

  prepareFormData(newCarrierInfo: CarrierAdmin): {
    carrier: string;
    mcNumber: string;
    contact: string;
    phone: string;
    email: string;
  } {
    return {
      carrier: newCarrierInfo?.carrierDot,
      mcNumber: newCarrierInfo?.mcNumber,
      contact: newCarrierInfo?.name,
      phone: newCarrierInfo?.phone,
      email: newCarrierInfo?.email,
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { carrierAdmin, carriers, loadsServiceLoad } = changes;

    if (loadsServiceLoad) {
      if (
        loadsServiceLoad.currentValue.bookStatus === BookStatus.VIEWABLE ||
        loadsServiceLoad.currentValue.bookStatus === BookStatus.BOOKABLE
      ) {
        this.enableCompliance = true;
      } else {
        this.enableCompliance = false;
      }
    }

    if (carrierAdmin) {
      this.carrierForm.markAsUntouched();
      //set inital data if selected carrier exists
      if (this.selectedCarrier) {
        this.prepareFormData(this.selectedCarrier);
      }
    }

    if (carrierAdmin?.currentValue) {
      //if a brand new form then disable editing
      if (carrierAdmin.currentValue.carrierDot && this.loadsServiceLoad.bookStatus !== BookStatus.VIEWABLE) {
        this.enableButton.next(true);
      } else {
        this.enableButton.next(false);
      }
      this.editCarrier = false;
      this.carrierForm.patchValue(this.prepareFormData(carrierAdmin.currentValue));
      this.selectCarrier();
    }

    if (carriers) {
      this.carriers = carriers.currentValue.map((c: Carrier) => {
        const { dot, mcNumber, name } = c;
        const filter = `${name} ${dot} ${mcNumber}`;

        return { ...c, filter };
      });

      if (this.carrierAdmin && this.carriers.length === 1) {
        this.carrierSearchDebounce.next(this.carriers[0].name);
      }
    }
  }

  selectCarrier(): void {
    const values = this.carrierForm.getRawValue();
    const carrier: CarrierSearchResult = find(this.carriers, (item) => item?.dot === values.carrier);
    this.selectedCarrier = carrier;
    if (this.selectedCarrier) {
      this.carrierEntityService.getMergedCarrier.dispatch({ dot: this.selectedCarrier?.dot });
    }
    if (carrier) {
      this.carrierForm.patchValue(this.prepareSearchResult(carrier));
    }
    this.carrierSelect.emit(carrier);
  }

  onValueChanges(keyword: string): void {
    const carrier: CarrierSearchResult = find(this.carriers, (item) => item.dot === keyword || item.name === keyword);
    if (keyword && !carrier) {
      this.carrierSearchDebounce.next(keyword);
    } else if (keyword && carrier) {
      return;
    } else {
      this.carrierForm.patchValue({
        carrier: null,
        mcNumber: null,
        contact: null,
        phone: null,
        email: null,
      });
    }
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }

  ngOnInit(): void {
    this.today = new Date().valueOf();
    this.carrierEntityService.getMergedCarrier.data$.pipe(takeUntil(this.alive)).subscribe((carrier) => {
      if (carrier) {
        this.insuranceExp = +carrier.insuranceAndSafety?.generalInsuranceExpDate;
        this.carrierCompliance.emit(this.insuranceExp && this.insuranceExp > this.today ? false : true);
      }
    });
    if (!this.carrierForm) {
      this.carrierForm = this.carrierInfoSectionVmService.createCarrierInfoFormOptions({});
    }
    if (!this.assignmentForm) {
      this.carrierForm = this.carrierInfoSectionVmService.createAssignmentFormOptions({});
    }
  }

  toggleEditCarrier(): void {
    this.editCarrier = !this.editCarrier;
    this.carrierForm.enable();
    this.carrierForm.controls['carrier'].disable();
    this.carrierForm.controls['mcNumber'].disable();

    if (!this.editCarrier) {
      this.carrierForm.disable();
      this.carrierForm.patchValue({
        contact: this.selectedCarrier?.adminName,
        phone: this.selectedCarrier?.phone,
        email: this.selectedCarrier?.email,
      });

      //if data has changed
      if (
        !(
          this.initalData.contact === this.selectedCarrier?.adminName &&
          this.initalData.email === this.selectedCarrier.email &&
          parseInt(this.initalData.phone) === parseInt(this.selectedCarrier.phone?.replace(/[-.,() ]/g, ''))
        )
      ) {
        this.carrierForm.markAsUntouched();
        this.saveCarrierInstance.emit();
      }
    }
  }

  saveCarrierInfo(): void {
    this.saveCarrierInstance.emit();
  }

  isCarrierFormValid(): boolean {
    if (
      !(
        this.initalData.contact === this.selectedCarrier?.adminName &&
        this.initalData.email === this.selectedCarrier.email &&
        parseInt(this.initalData.phone) === parseInt(this.selectedCarrier.phone?.replace(/[-.,() ]/g, ''))
      )
    ) {
      return this.carrierForm.disabled ? true : this.carrierForm.valid;
    }

    return this.carrierForm.valid;
  }

  createComplianceTicket(): void {
    this.createCarrierCompliance.emit(this.selectedCarrier);
  }
}
