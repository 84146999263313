import { Injectable } from '@angular/core';
import { Tab } from '@haulynx/types';
import { Store } from '@ngrx/store';
import { Map } from 'immutable';
import { get } from 'lodash';
import { Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { AppState } from '../../main-store/app.reducers';
import { ActiveLoadsActions } from './active-loads-state.actions';
import { activeLoadsTabsSelector } from './active-loads-state.selectors';

@Injectable()
export class ActiveLoadsTabsModel {
  tabs$: Observable<Map<string, Tab>> = this.store.select(activeLoadsTabsSelector.getTabs);
  tabsSelectedId$: Observable<string> = this.store.select(activeLoadsTabsSelector.getSelectedId);
  tabsSelected$: Observable<Tab> = this.store.select(activeLoadsTabsSelector.getSelected).pipe(
    distinctUntilChanged((tab, nextTab) => {
      return get(nextTab, 'id') === get(tab, 'id');
    })
  );

  constructor(private store: Store<AppState>) {}

  addTabs(tabs: Tab[] | Tab) {
    this.store.dispatch(ActiveLoadsActions.addTabs(tabs));
  }

  updateTabs(tabs: Partial<Tab>[] | Partial<Tab>) {
    this.store.dispatch(ActiveLoadsActions.updateTabs(tabs));
  }

  selectTab(tab: Tab | any) {
    this.store.dispatch(ActiveLoadsActions.selectTab(tab));
  }

  removeTabs(data: { id: string }) {
    this.store.dispatch(ActiveLoadsActions.removeTab(data));
  }
}
