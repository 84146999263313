import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AssignedBrokersModule } from '@haulynx/components';
import { DirectivesModule } from '@haulynx/directives';
import { ElementsModule } from '@haulynx/elements';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { CountdownModule } from 'ngx-countdown';
import { LoadOverviewBannerComponent } from './load-overview-banner.component';
import { IconModule } from 'libs/elements/src/lib/icon/icon.module';

@NgModule({
  declarations: [LoadOverviewBannerComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    PipesModule,
    CountdownModule,
    ElementsModule,
    AssignedBrokersModule,
    DirectivesModule,
    IconModule,
  ],
  exports: [LoadOverviewBannerComponent],
})
export class LoadOverviewBannerModule {}
