<data-table
  [data]="invitations$ | async"
  [displayedColumns]="invitationsColumns"
  [templateCellRef]="templateCell"
  [pageOptions]="[50, 200]"
  title="INVITATIONS"
>
  <ng-template #templateCell let-row="row" let-column="column">
    <div *ngIf="column === 'date'">
      {{ row.date | timestamp }}
    </div>
    <div class="invitations-cell" *ngIf="column === 'inviter'">
      <div><b>Contact Name</b>&nbsp;{{ row.inviter.name }}</div>
      <div><b>Dot</b>&nbsp;{{ row.inviter.broker?.dot }}</div>
      <div><b>Email</b>&nbsp;{{ row.inviter.email }}</div>
      <div><b>Phone</b>&nbsp;{{ row.inviter.phone }}</div>
    </div>
    <div class="invitations-cell" *ngIf="column === 'recipient'">
      <div><b>Contact Name</b>&nbsp;{{ row.recipient.contactName }}</div>
      <div><b>Company Name</b>&nbsp;{{ row.recipient.companyName }}</div>
      <div><b>Dot</b>&nbsp;{{ row.recipient.dot }}</div>
      <div><b>Email</b>&nbsp;{{ row.recipient.email }}</div>
      <div><b>Phone</b>&nbsp;{{ row.recipient.phone }}</div>
    </div>
    <div class="invitations-cell" *ngIf="column === 'recipientInfo'">
      <div><b>Carrier Name</b>&nbsp;{{ row.haulynxRecipientInfo?.name }}</div>
      <div><b>Dot</b>&nbsp;{{ row.haulynxRecipientInfo?.dot }}</div>
      <div><b>Email</b>&nbsp;{{ row.haulynxRecipientInfo?.email }}</div>
      <div><b>Phone</b>&nbsp;{{ row.haulynxRecipientInfo?.phone }}</div>
    </div>
  </ng-template>
</data-table>
