<mat-form-field *ngIf="styleDisplay === 'form-field'; else selectTemplate">
  <mat-label>{{ placeholder }}</mat-label>
  <mat-select tabIndex="{{ tabIndex }}" [formControl]="control" (selectionChange)="select($event)">
    <mat-option *ngFor="let item of items" [value]="item">{{ displayFn(item) }}</mat-option>
  </mat-select>
  <mat-error *ngFor="let error of errors">
    {{ validationMessages[error] }}
  </mat-error>
</mat-form-field>

<ng-template #selectTemplate>
  <div class="select-radius__wrapper">
    <mat-label class="label select-radius__label">{{ placeholder }}</mat-label>
    <mat-select
      tabIndex="{{ tabIndex }}"
      [formControl]="control"
      (selectionChange)="select($event)"
      class="select-radius__dropdown select-radius__dropdown--destination"
      data-test="radius-dropdown"
    >
      <mat-option *ngFor="let item of items" [value]="item">{{ displayFn(item) }}</mat-option>
    </mat-select>
    <mat-error *ngFor="let error of errors">
      {{ validationMessages[error] }}
    </mat-error>
  </div>
</ng-template>
