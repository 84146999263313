import { Pipe, PipeTransform } from '@angular/core';
import { get } from 'lodash';

import { LoadsServiceLoadLocation } from '@haulynx/types';

@Pipe({ name: 'waypointType' })
export class LoadWaypointTypePipe implements PipeTransform {
  transform(loadLocation: LoadsServiceLoadLocation): string {
    const locationType = get(loadLocation, 'locationType', 'pickup');

    return locationType === 'pickup' ? 'Pick-Up' : 'Drop-Off';
  }
}
