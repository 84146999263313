<div class="load-active-note">
  <h1 mat-dialog-title class="load-active-note__title">{{ title }}</h1>
  <div mat-dialog-content>
    <form [formGroup]="form" class="load-active-note__form">
      <mat-form-field class="full-width">
        <mat-label>Special Notes</mat-label>
        <textarea matInput placeholder="Special Notes" formControlName="specialNotes" rows="5"></textarea>
      </mat-form-field>
    </form>
  </div>
  <div mat-dialog-actions>
    <button (click)="onSubmit()" mat-flat-button mat-dialog-close color="primary" data-test="save-button">Save</button>
    <button mat-flat-button mat-dialog-close class="affirmative" data-test="close-button">Close</button>
  </div>
</div>
