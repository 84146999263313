<div class="search-more-info-dialog-container">
  <button mat-icon-button data-test="close" class="pull-right close" [matDialogClose]="">
    <mat-icon>close</mat-icon>
  </button>
  <h2 matDialogTitle class="title">AVAILABLE SEARCH FILTERS</h2>
  <mat-dialog-content>
    <div class="smid-filter-options-container">
      <div class="smid-filter-option" *ngFor="let filter of filters; let i = index">
        <div class="smid-filter-title">
          <span class="smid-title">
            {{ filter.name }}
          </span>
        </div>
        <div class="smid-filter-description">
          {{ filter.description }}
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>
