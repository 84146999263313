<app-spinner [active]="isLoading"></app-spinner>

<mat-card class="truck-form mat-elevation-z0" *ngIf="!isLoading">
  <form [formGroup]="form" class="form">
    <div class="truck-form__header">
      <h5>POST A TRUCK</h5>
    </div>

    <ng-container formArrayName="trucks" *ngFor="let truckFormGroup of trucks; let i = index; let f = first">
      <ng-container [formGroupName]="i">
        <div class="trucks">
          <div class="truck-block truck-block--location">
            <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
              <mat-label *ngIf="f"> Location </mat-label>
              <app-address-field
                #location
                formControlName="location"
                placeholder="Ex. Phoenix, AZ"
                (onPlaceChange)="focusTimeAvailable(i)"
                required
              >
              </app-address-field>
            </mat-form-field>

            <app-selector
              key="value"
              [data]="truckRadiusOptions"
              [tabIndex]="-1"
              placeholder="Radius"
              formControlName="locationRadius"
              styleDisplay="normal"
              data-test="truck-radius"
            >
            </app-selector>
          </div>

          <div class="truck-block truck-block--datepicker">
            <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
              <mat-label *ngIf="f"> Date Available </mat-label>
              <app-datepicker
                #timeAvailable
                [min]="minDate"
                [tabIndex]="-1"
                [separateDateInputs]="true"
                formControlName="timeAvailable"
                placeholder="MM/DD/YYYY"
                label="Date Available"
                required
              >
              </app-datepicker>
            </mat-form-field>

            <app-chip-list
              [data]="selectedDateMap"
              (onClick)="selectDate(i, $event)"
              [select]="truckFormGroup.get('timeAvailable').value.valueOf()"
            >
            </app-chip-list>
          </div>

          <div class="truck-block truck-block--location">
            <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
              <mat-label *ngIf="f"> Preferred Destination </mat-label>
              <app-address-field
                formControlName="preferredLocation"
                placeholder="Ex. Anywhere"
                (onPlaceChange)="openEquipmentTypePanel(i, $event)"
              >
              </app-address-field>
            </mat-form-field>
            <app-selector
              key="value"
              [data]="truckRadiusOptions"
              placeholder="Radius"
              [tabIndex]="-1"
              formControlName="preferredLocationRadius"
              styleDisplay="normal"
            >
            </app-selector>
          </div>

          <div class="truck-block">
            <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
              <mat-label *ngIf="f"> Equipment </mat-label>
              <app-drop-down
                #equipmentType
                [placeholder]="'Select'"
                [data]="equipmentTypes"
                (selected)="focusLocation(i)"
                formControlName="equipmentType"
                displayLabel="text"
                key="text"
              >
              </app-drop-down>
            </mat-form-field>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </form>

  <mat-card-actions>
    <div class="truck-form-action">
      <div class="truck-form-action__info"><b>*</b>Required</div>
      <div class="truck-form-action__actions">
        <button mat-raised-button (click)="createTruck()">POST TRUCK</button>
      </div>
    </div>
  </mat-card-actions>
</mat-card>
