import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ActionButtonsModule, CarrierManagementComponent, DataTableModule, DropDownModule } from '@haulynx/components';
import { MaterialModule } from '@haulynx/modules';
import { CarrierManagementRoutingModule } from './carrier-management-routing.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    DataTableModule,
    DropDownModule,
    ActionButtonsModule,
    CarrierManagementRoutingModule,
  ],
  declarations: [CarrierManagementComponent],
  exports: [CarrierManagementComponent],
})
export class CarrierManagementModule {}
