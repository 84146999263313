import { Injectable } from '@angular/core';
import { GetWebFeatures } from '@haulynx/gql';
import { FFState } from '@haulynx/types';
import { capitalize, get, reduce, split } from 'lodash';
import { map } from 'rxjs/operators';
import { GraphqlService } from '../graphql.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  constructor(private graphqlService: GraphqlService) {}

  getFeatureFlag(environmentName: string) {
    const platform = `web${capitalize(environmentName)}`;

    return this.graphqlService
      .query({
        query: GetWebFeatures,
        variables: { platform: platform },
      })
      .pipe(
        map(
          (result: any): FFState => {
            const features = get(result, `data.getFeaturesByPlatform.features.permissions`, '');

            return reduce(
              split(features, ' ').filter(Boolean),
              (accumulator, item) => {
                if (item) {
                  return { ...accumulator, [item]: true };
                }

                return accumulator;
              },
              {} as FFState
            );
          }
        )
      );
  }

  public checkFlag(features: FFState, flags: string[]): boolean {
    if (!features) {
      return false;
    }
    return reduce(
      flags,
      (accumulator, flag) => {
        if (accumulator) {
          return !!features[flag];
        }

        return false;
      },
      true
    );
  }
}
