/* eslint-disable @typescript-eslint/ban-types */
import { Injectable } from '@angular/core';
import { BidsService, CarrierLoadSearchService, LoadsServiceService, NotificationsService } from '@haulynx/services';
import {
  AssignLoadResponse,
  AsyncDataManager,
  AsyncMutationManager,
  AsyncQueryManager,
  AsyncSearchManager,
  Bid,
  BidInput,
  CarrierSearchSidebarLoadCounts,
  CounterOffer,
  FFState,
  LoadServiceSearchParameters,
  LoadsServiceLoad,
  UpdateBidInput,
  AssignLoadPayload,
  LoadsServiceLoadLocation,
  AssignUSXITrailerInput,
  NewUSXITrailer,
} from '@haulynx/types';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppState } from '../../main-store/app.reducers';
import { AsyncEntityBase } from '../../main-store/async-entity/async-entity-base.class';
import { carrierLoadSearchNamespace } from './carrier-load-search-entity.module';
import { tap } from 'rxjs/operators';
import { valueFromAST } from 'graphql';

interface SearchParameters {
  dot?: string;
  carrierId?: string;
  features?: FFState;
  searchParameters?: Partial<LoadServiceSearchParameters>;
}

@Injectable({
  providedIn: 'root',
})
export class CarrierLoadSearchEntityService extends AsyncEntityBase<LoadsServiceLoad> {
  getSidebarLoadCountsManager: AsyncDataManager<SearchParameters, CarrierSearchSidebarLoadCounts>;
  getLoadsServiceLoadsByCarrierDotManager: AsyncSearchManager<LoadsServiceLoad, SearchParameters>;
  getUSXLoadsManager: AsyncSearchManager<LoadsServiceLoad, SearchParameters>;
  getLoadMatchesByCarrierDotManager: AsyncSearchManager<LoadsServiceLoad, SearchParameters>;
  getBidsAndOffersManager: AsyncSearchManager<LoadsServiceLoad, SearchParameters>;
  getLoadDetailsManager: AsyncQueryManager<LoadsServiceLoad, { loadId: string }>;
  bookLoadManager: AsyncMutationManager<{ carrierId: string; loadId: string }, boolean>;
  deleteMissionManager: AsyncMutationManager<{ orderNumber: string; missionId: string }, boolean>;
  acceptCounterOfferAndBookLoadManager: AsyncMutationManager<{ counterOfferId: string }, Boolean>;
  placeBidManager: AsyncMutationManager<{ newBid: Partial<Bid> }, Bid>;
  updateBidManager: AsyncMutationManager<{ bidId: string; bidUpdate: UpdateBidInput }, Bid>;
  declineOfferManager: AsyncMutationManager<
    { loadId: string; carrierDot: string; brokerId: string; rejectionReason: string },
    boolean
  >;
  declineCounterOfferManager: AsyncMutationManager<{ counterOfferId: string }, CounterOffer>;
  updateDriverPhoneManager: AsyncMutationManager<
    AssignLoadPayload,
    { data: AssignLoadResponse; error: { message: string } }
  >;
  confirmArrivalDepartureTimeManager: AsyncMutationManager<
    { loadId: string; locationIndex: number; arrivalTime: number; departureTime: number },
    any
  >;
  assignLoadServiceLoadManager: AsyncMutationManager<
    AssignLoadPayload,
    { data: AssignLoadResponse; error: { message: string } }
  >;
  assignUSXLoadServiceLoadManager: AsyncMutationManager<
    AssignUSXITrailerInput,
    { data: NewUSXITrailer; error: { message: string } }
  >;
  notificationsService: any;

  constructor(
    protected actions$: Actions,
    private bidsService: BidsService,
    protected store: Store<AppState>,
    private carrierLoadSearchService: CarrierLoadSearchService,
    private loadsServiceService: LoadsServiceService,
    private notifications: NotificationsService
  ) {
    super(actions$, store, carrierLoadSearchNamespace);

    this.getSidebarLoadCountsManager = this.createAsyncData('get sidebar load counts', (payload: SearchParameters) =>
      this.carrierLoadSearchService.getSidebarLoadCounts(payload.dot, payload.carrierId, payload.features)
    );

    this.getLoadsServiceLoadsByCarrierDotManager = this.createAsyncSearchQuery(
      'get loads by carrier dot',
      (searchPayload) =>
        this.carrierLoadSearchService.getLoadsByCarrierDot(
          searchPayload.query.dot,
          searchPayload.query.searchParameters,
          searchPayload.pageAndSort
        )
    );

    this.getUSXLoadsManager = this.createAsyncSearchQuery('get usx loads', (searchPayload) =>
      this.carrierLoadSearchService.getUSXLoads(searchPayload.query.searchParameters, searchPayload.pageAndSort)
    );

    this.getLoadMatchesByCarrierDotManager = this.createAsyncSearchQuery('get recommendations', (searchPayload) =>
      this.carrierLoadSearchService.getRecommendations(searchPayload.query.dot)
    );

    this.getBidsAndOffersManager = this.createAsyncSearchQuery('get bids and offers', (searchPayload) =>
      this.carrierLoadSearchService.getBidsAndOffers(
        searchPayload.query.dot,
        searchPayload.query.carrierId,
        searchPayload.query.features
      )
    );

    this.getLoadDetailsManager = this.createAsyncQuery((loadId: string) =>
      this.carrierLoadSearchService.getLoadsServiceLoadById(loadId).pipe(
        catchError((error) => {
          return throwError(error);
        })
      )
    );

    this.deleteMissionManager = this.createAsyncMutation(
      'delete mission',
      (payload) =>
        this.loadsServiceService.deleteMission(payload.orderNumber).pipe(
          tap(
            () => {
              this.notifications.success(`Sucessfully deleted mission ${payload.missionId}`, 'Success!');
              return true;
            },
            (error) => this.notifications.error(error, `Error deleting mission ${payload.missionId}`)
          )
        ),
      { refreshEntityFrom: 'query' }
    );

    this.bookLoadManager = this.createAsyncMutation(
      'book load',
      (payload: { carrierId: string; loadId: string }) => {
        return this.loadsServiceService.bookLoad(payload.carrierId, payload.loadId) as Observable<boolean>;
      },
      { refreshEntityFrom: 'query' }
    );

    this.acceptCounterOfferAndBookLoadManager = this.createAsyncMutation(
      'accept counter offer',
      (payload: { counterOfferId: string }) => {
        return this.bidsService.acceptCounterBid({ counterOfferId: payload.counterOfferId }).pipe(
          catchError((error) => {
            this.notifications.error('Error accepting counter offer');
            return throwError(error);
          })
        );
      },
      { refreshEntityFrom: 'query' }
    );

    this.placeBidManager = this.createAsyncMutation(
      'Place Bid',
      (payload: { newBid: BidInput }) => {
        return this.bidsService.createBid({ newBid: payload.newBid }).pipe(
          catchError((error) => {
            this.notifications.error('Error creating bid');
            return throwError(error);
          })
        );
      },
      { refreshEntityFrom: 'query' }
    );

    this.updateBidManager = this.createAsyncMutation(
      'Update Bid',
      (payload: { bidId: string; bidUpdate: UpdateBidInput }) => {
        return this.bidsService.updateBid({ bidId: payload.bidId, bidUpdate: payload.bidUpdate });
      },
      { refreshEntityFrom: 'query' }
    );

    this.declineOfferManager = this.createAsyncMutation(
      'Decline Offer',
      (payload: { loadId: string; carrierDot: string; brokerId: string; rejectionReason: string }) => {
        return this.loadsServiceService.deleteOffer(payload) as Observable<boolean>;
      },
      { refreshEntityFrom: 'query' }
    );

    this.declineCounterOfferManager = this.createAsyncMutation(
      'Decline Counter Offer',
      (payload: { counterOfferId: string }) => {
        return this.bidsService.rejectCounterOffer(payload);
      },
      { refreshEntityFrom: 'query' }
    );

    this.updateDriverPhoneManager = this.createAsyncMutation(
      'Assign Drivers Phone',
      (payload: AssignLoadPayload) => {
        return this.loadsServiceService.assignLoadServiceLoad(payload) as Observable<{
          data: AssignLoadResponse;
          error: { message: string };
        }>;
      },
      { refreshEntityFrom: 'query' }
    );

    this.confirmArrivalDepartureTimeManager = this.createAsyncMutation(
      'Confirm Arrival/Departure Time',
      (payload: {
        loadId: string;
        locationIndex: number;
        arrivalTime: number;
        departureTime: number;
      }): Observable<LoadsServiceLoadLocation> => {
        const data = { arrivalTime: payload.arrivalTime, departureTime: payload.departureTime };
        return this.loadsServiceService.updateArrivalTimeOrCompleteLoadLocation(
          payload.loadId,
          payload.locationIndex,
          data
        );
      },
      { refreshEntityFrom: 'query' }
    );

    this.assignLoadServiceLoadManager = this.createAsyncMutation(
      'Assign Load Service Load',
      (
        payload: AssignLoadPayload
      ): Observable<{
        data: AssignLoadResponse;
        error: { message: string };
      }> => {
        return this.loadsServiceService.assignLoadServiceLoad(payload) as Observable<{
          data: AssignLoadResponse;
          error: { message: string };
        }>;
      },
      { refreshEntityFrom: 'query' }
    );

    this.assignUSXLoadServiceLoadManager = this.createAsyncMutation(
      'Assign USX Trailer',
      (payload: AssignUSXITrailerInput) => {
        return this.loadsServiceService.assignUSXLoadServiceLoad(payload);
      }
    );
  }
}
