import { HttpStatus, PaymentItemSearchCriteria, PaymentsTypeForm } from '@haulynx/types';
import { createFeatureSelector } from '@ngrx/store';
import { List } from 'immutable';

export const initialState: PaymentTypesState = {
  initialPaymentTypes: List(),
  paymentTypes: List(),
  query: null,
  isLoading: false,
  isSaving: false,
  httpStatus: HttpStatus.BLANK,
};

export class PaymentTypesState {
  initialPaymentTypes: List<PaymentsTypeForm>;
  paymentTypes: List<PaymentsTypeForm>;
  query: PaymentItemSearchCriteria;
  isLoading: boolean;
  isSaving: boolean;
  httpStatus: HttpStatus;
}

export const getPaymentTypesState = createFeatureSelector<PaymentTypesState>('payment-types');
