import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AnnouncementBannerComponent } from './announcement-banner.component';
import { WhiteOutlineButtonModule } from '../white-outline-button/white-outline-button.module';

@NgModule({
  declarations: [AnnouncementBannerComponent],
  imports: [CommonModule, FlexLayoutModule, WhiteOutlineButtonModule],
  exports: [AnnouncementBannerComponent],
})
export class AnnouncementBannerModule {}
