import { LoadsServiceLoad } from '@haulynx/types';
import { isArray, reduce } from 'lodash';
import { AppSettingsActionTypes } from '../../main-store/app-settings.actions';
import { IFormState } from '../../shared/store/form/state';
import { DispatchAction } from '../../shared/store/helpers/action';
import { loadDetailsAdapter } from './adapters';
import { LoadDetailsActionTypes } from './load-details.actions';
import { initialCarrierLoadDetails, LoadDetailsState } from './load-details.state';

export const initialState: LoadDetailsState = { ...initialCarrierLoadDetails } as LoadDetailsState;

const reducer = loadDetailsAdapter.getReducer();

export function loadDetailsReducer(
  state: LoadDetailsState = initialState,
  action: DispatchAction
): LoadDetailsState | IFormState<unknown> {
  switch (action.type) {
    case AppSettingsActionTypes.LOG_OUT:
      return initialState;

    case LoadDetailsActionTypes.GET_ROUTES_ERROR:
    case LoadDetailsActionTypes.GET_ROUTE_ERROR:
      return {
        ...state,
        isLoadingRoutes: false,
      };

    case LoadDetailsActionTypes.GET_ROUTES:
    case LoadDetailsActionTypes.GET_ROUTE:
      return {
        ...state,
        isLoadingRoutes: true,
      };

    case LoadDetailsActionTypes.SET_VALUE: {
      const payload = action.payload as { key: string; state: LoadsServiceLoad };
      return {
        ...state,
        state: state.state.set(payload.key, payload.state),
        initialState: state.initialState.set(payload.key, payload.state),
      };
    }

    case LoadDetailsActionTypes.GET_ROUTE_SUCCESS: {
      let newState = {};

      if (isArray(action.payload)) {
        newState = reduce(
          action.payload,
          (result, { key, geojson }) => {
            result[key] = geojson;

            return result;
          },
          {}
        );
      } else {
        const { key, geojson } = action.payload;

        newState[key] = geojson;
      }

      return {
        ...state,
        isLoadingRoutes: false,
        routes: {
          ...state.routes,
          ...newState,
        },
      };
    }

    case LoadDetailsActionTypes.CLEAR_ROUTES:
      return {
        ...state,
        routes: {},
      };

    case LoadDetailsActionTypes.GET_DEVICE_LOCATIONS_SUCCESS: {
      const newState = {};
      const { key, location } = action.payload;

      newState[key] = location;

      return {
        ...state,
        deviceLocations: {
          ...state.deviceLocations,
          ...newState,
        },
      };
    }

    case LoadDetailsActionTypes.SET_ACTIVE_STEP: {
      const { key, stepId } = action.payload as { key: string; stepId: string };
      const newState = { [key]: stepId };

      return {
        ...state,
        activeStep: {
          ...state.activeStep,
          ...newState,
        },
      };
    }

    case LoadDetailsActionTypes.CONFIRM_LOCATION:
    case LoadDetailsActionTypes.DISPATCH:
    case LoadDetailsActionTypes.BOOK_LOAD_FOR_CARRIER:
    case LoadDetailsActionTypes.ASSIGN_TO_DRIVER: {
      const { loadId, data } = action.payload;

      return {
        ...state,
        isLoading: state.isLoading.set(loadId, true),
      };
    }

    case LoadDetailsActionTypes.CONFIRM_LOCATION_ERROR:
    case LoadDetailsActionTypes.CONFIRM_LOCATION_SUCCESS:
    case LoadDetailsActionTypes.DISPATCH_ERROR:
    case LoadDetailsActionTypes.DISPATCH_SUCCESS:
    case LoadDetailsActionTypes.ASSIGN_TO_DRIVER_ERROR:
    case LoadDetailsActionTypes.BOOK_LOAD_FOR_CARRIER_ERROR:
    case LoadDetailsActionTypes.BOOK_LOAD_FOR_CARRIER_SUCCESS:
    case LoadDetailsActionTypes.ASSIGN_TO_DRIVER_SUCCESS: {
      const { loadId, data } = action.payload;

      return {
        ...state,
        isLoading: state.isLoading.set(loadId, false),
      };
    }

    case LoadDetailsActionTypes.CREATE_DRIVER:
    case LoadDetailsActionTypes.CREATE_TRUCK:
    case LoadDetailsActionTypes.CREATE_TRAILER: {
      return {
        ...state,
        isLoadingCreateEntity: true,
      };
    }

    case LoadDetailsActionTypes.CREATE_DRIVER_SUCCESS:
    case LoadDetailsActionTypes.CREATE_DRIVER_ERROR:
    case LoadDetailsActionTypes.CREATE_TRUCK_SUCCESS:
    case LoadDetailsActionTypes.CREATE_TRUCK_ERROR:
    case LoadDetailsActionTypes.CREATE_TRAILER_SUCCESS:
    case LoadDetailsActionTypes.CREATE_TRAILER_ERROR: {
      return {
        ...state,
        isLoadingCreateEntity: false,
      };
    }

    case LoadDetailsActionTypes.POPULATE_DRIVER:
      return {
        ...state,
        driverToPopulate: action.payload,
      };

    case LoadDetailsActionTypes.POPULATE_TRUCK:
      return {
        ...state,
        truckToPopulate: action.payload,
      };

    case LoadDetailsActionTypes.POPULATE_TRAILER:
      return {
        ...state,
        trailerToPopulate: action.payload,
      };

    default:
      return reducer(state, action);
  }
}
