import { BookLoadHttpStatus } from '@haulynx/types';
import { AppSettingsActionTypes } from '../../main-store/app-settings.actions';
import { DispatchAction } from '../../shared/store/helpers/action';
import { BookLoadActionTypes } from './book-load.actions';
import { BookLoadState } from './book-load.state';

export const initialState: BookLoadState = new BookLoadState();

export function bookLoadReducer(state: BookLoadState = initialState, action: DispatchAction): BookLoadState {
  switch (action.type) {
    case AppSettingsActionTypes.LOG_OUT:
      return initialState;

    case BookLoadActionTypes.BOOK_LOAD: {
      const loadId = action.payload.load.id;

      return state.merge({
        isLoading: state.isLoading.push(loadId),
        httpStatus: state.httpStatus.set(loadId, BookLoadHttpStatus.BLACK),
      });
    }

    case BookLoadActionTypes.GET_BROKER_BOOKED_LOADS: {
      const { loadId } = action.payload;

      return state.merge({
        isLoading: state.isLoading.push(loadId),
        httpStatus: state.httpStatus.set(loadId, BookLoadHttpStatus.BLACK),
      });
    }

    case BookLoadActionTypes.BOOK_LOAD_SUCCESS: {
      const { loadId } = action.payload;

      return state.merge({
        isLoading: state.isLoading.pop(loadId),
        httpStatus: state.httpStatus.set(loadId, BookLoadHttpStatus.SUCCESS),
      });
    }

    case BookLoadActionTypes.GET_BROKER_BOOKED_LOADS_SUCCESS: {
      const { loadId } = action.payload;

      return state.merge({
        isLoading: state.isLoading.pop(loadId),
        isBookable: state.isBookable.set(loadId, true),
      });
    }

    case BookLoadActionTypes.BOOK_LOAD_ERROR: {
      const { loadId } = action.payload;

      return state.merge({
        isLoading: state.isLoading.pop(loadId),
        httpStatus: state.httpStatus.set(loadId, BookLoadHttpStatus.FAILED),
      });
    }

    case BookLoadActionTypes.GET_BROKER_BOOKED_LOADS_ERROR: {
      const { loadId } = action.payload;

      return state.merge({
        isLoading: state.isLoading.pop(loadId),
        isBookable: state.isBookable.set(loadId, false),
      });
    }

    default:
      return state;
  }
}
