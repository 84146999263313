<div
  [appKeyboardShortcut]="'g'"
  globalKeyboardShortcutEvent
  globalKeyBoardShortcut
  (shortcutEventTriggered)="globalKeyboardShortCuts($event)"
  [ngClass]="'menu'"
  [ngClass.lt-lg]="'menu-mobile'"
  *ngIf="user && !reportMode"
>
  <div>
    <mat-nav-list [ngClass]="'list-horizontal'" [ngClass.lt-lg]="'list-vertacle'">
      <ng-container *ngFor="let m of menuItems">
        <mat-list-item *ngIf="m.name === 'Spot Admin'; else notSpot" (click)="navTabClicked(m); closeSidenav()">
          <mat-icon mat-list-icon *ngIf="m.svgIcon" [svgIcon]="m.svgIcon"></mat-icon>
          <mat-icon mat-list-icon *ngIf="!m.svgIcon">{{ m.icon }}</mat-icon>
          <h4 class="menu-item-name" attr.data-test="{{ m.link }}">{{ m.name }}</h4>
        </mat-list-item>
        <ng-template #notSpot>
          <mat-list-item *ngIf="!m.subItems" (click)="navTabClicked(m); closeSidenav()" [routerLink]="[m.link]">
            <mat-icon mat-list-icon *ngIf="m.svgIcon" [svgIcon]="m.svgIcon"></mat-icon>
            <mat-icon mat-list-icon *ngIf="!m.svgIcon">{{ m.icon }}</mat-icon>
            <h4 class="menu-item-name" attr.data-test="{{ m.link }}">{{ m.name }}</h4>
          </mat-list-item>
        </ng-template>

        <app-hover-drop-down
          *ngIf="m.subItems"
          [menuItems]="m.subItems"
          (navClick)="closeSidenav()"
          (changeIsOpened)="m.isOpened = $event"
        >
          <mat-list-item [ngClass]="{ hover: m.isOpened }">
            <mat-icon mat-list-icon *ngIf="m.svgIcon" [svgIcon]="m.svgIcon"></mat-icon>
            <mat-icon mat-list-icon *ngIf="!m.svgIcon">{{ m.icon }}</mat-icon>
            <h4 class="menu-item-name" attr.data-test="{{ m.link }}">{{ m.name }}</h4>
          </mat-list-item>
        </app-hover-drop-down>
      </ng-container>
      <ng-container *appFeatures="featureFlag">
        <mat-list-item (click)="onStartTour()" *ngIf="user?.carrier || user?.broker">
          <mat-icon mat-list-icon svgIcon="haulynx-what-is-new"></mat-icon>
          <h4 class="menu-item-name">What’s New</h4>
        </mat-list-item>
      </ng-container>
    </mat-nav-list>
  </div>
</div>
