<div class="short-details" *ngIf="carrier">
  <div class="short-field field-name">
    <span>{{ carrier.name | titlecase }}</span>
  </div>

  <div class="short-field field-owner">
    <mat-icon svgIcon="haulynx-users-red" data-test="owner-icon"></mat-icon>
    <span data-test="carrier-owner">{{ carrier?.owner | carrierOwner: 'Unknown' }}</span>
  </div>

  <div class="short-field field-mcnumber">
    <span>{{ carrier?.mcNumber || carrier?.saferWatchData?.mcNumber || 'MC# N/A' }}</span>
  </div>

  <div class="short-field field-location" data-test="domicile">
    <mat-icon svgIcon="haulynx-geo"></mat-icon>
    <span *ngIf="carrier.addressCity && carrier.addressState; else notAvailable">
      {{ carrier.addressCity | titlecase }}, {{ carrier.addressState }}
    </span>
  </div>

  <div class="short-field field-phone">
    <mat-icon svgIcon="haulynx-phone-red"></mat-icon>
    <span>
      <a href="tel:{{ carrier.phone }}" (click)="phoneClicked($event)">
        {{ carrier.phone }}
      </a>
    </span>
  </div>

  <div class="short-field field-envelope">
    <mat-icon svgIcon="haulynx-envelope-red"></mat-icon>

    <a (click)="emailClicked($event)" *ngIf="carrier.email; else notAvailable">
      {{ carrier.email | lowercase }}
    </a>
  </div>

  <div class="short-field source-type">
    <div data-test="carrier-source-type">
      Type:
      <span class="source-type__content" *ngIf="carrier.sourceType; else notAvailable">
        {{ carrier.sourceType }}
      </span>
    </div>
  </div>

  <div class="short-field">
    <app-carrier-status
      [isLoading]="isLoadingCarriersStatus"
      [status]="carriersStatus?.carrierStatus"
    ></app-carrier-status>
  </div>

  <div class="short-field">
    <app-carrier-safety-rating [isLoading]="isLoadingInsuranceAndSafety" [score]="insuranceAndSafety?.safetyRating">
    </app-carrier-safety-rating>
  </div>
</div>

<ng-template #notAvailable>
  <span>N/A</span>
</ng-template>
