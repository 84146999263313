<app-spinner [active]="isLoading"></app-spinner>

<div class="customer" *ngIf="!isLoading">
  <h4 class="customer__title">Facility info</h4>
  <mat-icon mat-dialog-close class="customer__close" data-test="close-button">close</mat-icon>

  <div class="customer__details">
    <div class="customer__details__info">
      <div class="customer__name" *ngIf="customer?.name">
        <mat-icon svgIcon="haulynx-facility-grey" class="customer__icon customer__icon--facility"></mat-icon>
        <span data-test="customer-name">{{ customer?.name }}</span>
      </div>
      <div class="customer__address" *ngIf="customer?.address1 || customer?.city || customer?.state">
        <mat-icon svgIcon="haulynx-geo-grey" class="customer__icon customer__icon--geo"></mat-icon>
        <span data-test="load-address">
          {{ customer?.address1 | titlecase }}, {{ customer?.city | titlecase }}, {{ customer?.state }}
          {{ customer?.zipCode }}
        </span>
      </div>

      <div *ngIf="shippingHours.length || receivingHours.length" class="customer__hours" (click)="toggleHoursPanel()">
        <mat-icon class="customer__icon customer__icon--schedule">schedule</mat-icon>
        <span>Shipping & Receiving Hours</span>
        <mat-icon *ngIf="!isHoursPanelExpanded" class="customer__icon--arrow">keyboard_arrow_down</mat-icon>
        <mat-icon *ngIf="isHoursPanelExpanded" class="customer__icon--arrow">keyboard_arrow_up</mat-icon>
      </div>

      <div *ngIf="isHoursPanelExpanded" class="customer__hours__info">
        <div class="customer__shipping">
          <div class="customer__hours__title">Shipping Hours:</div>
          <div class="customer__row" *ngFor="let hour of shippingHours">
            <div class="customer__column customer__column--muted">{{ hour.dayOfWeek | slice: 0:3 }}:</div>
            <div class="customer__column customer__column--hours">
              {{ hour.openTime | date: 'HH:mm' }} - {{ hour.closeTime | date: 'HH:mm' }}
            </div>
          </div>
        </div>

        <div class="customer__receiving">
          <div class="customer__hours__title">Receiving Hours:</div>
          <div class="customer__row" *ngFor="let hour of receivingHours">
            <div class="customer__column customer__column--muted">{{ hour.dayOfWeek | slice: 0:3 }}:</div>
            <div class="customer__column customer__column--hours">
              {{ hour.openTime | date: 'HH:mm' }} - {{ hour.closeTime | date: 'HH:mm' }}
            </div>
          </div>
        </div>
      </div>
      <br />
      <mat-divider></mat-divider>
      <br />
      <div>
        <div *ngFor="let attribute of attributes" class="customer__row">
          <div class="customer__column customer__column--attributes" attr.data-test="{{ attribute?.label }}">
            {{ attribute?.label }}
          </div>
          <div
            class="customer__column customer__column--attributes customer__column--value"
            [ngClass]="{
              'customer__column--positive': attribute.option === attributeOptionTypes.TRUE,
              'customer__column--negative': attribute.option === attributeOptionTypes.FALSE
            }"
            data-test="facility-values"
          >
            {{ attribute?.option }}
          </div>
        </div>
      </div>
      <br />
      <div class="comments" fxLayout="column" fxLayoutAlign="space-between center">
        <div class="customer__note" fxFlex="120px">Facility Notes</div>
        <mat-form-field class="customer__comment" appearance="fill" fxFlex="grow">
          <textarea matInput readonly rows="3" [value]="notes" data-test="facility-notes"></textarea>
        </mat-form-field>
      </div>
    </div>
    <div class="customer__details__map">
      <app-map-routes [center]="marker?.coordinates" [flatMarkers]="[marker]" data-test="map"></app-map-routes>
    </div>
  </div>
</div>
