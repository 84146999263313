<span class="panel-analytics__label">Metrics</span>
<div class="panel-analytics__info carrierMetricInfo">
  <app-carrier-analytics-item
    label="First App Booking"
    [value]="carrierMetrics?.firstAppBooking"
    type="date"
    class="metricsAnalyticsItem"
  ></app-carrier-analytics-item>
  <app-carrier-analytics-item
    label="Last App Booking"
    [value]="carrierMetrics?.aggregations?.bookedAt?.value | date: 'MM/dd/yyyy'"
    type="date"
    class="metricsAnalyticsItem"
  ></app-carrier-analytics-item>
  <app-carrier-analytics-item
    label="# of App Bookings"
    [value]="carrierMetrics?.totalLoads"
    [displaySymbol]="''"
    class="metricsAnalyticsItem"
  ></app-carrier-analytics-item>
  <app-carrier-analytics-item
    label="Self-serve"
    [value]="carrierMetrics?.totalSelfServeBookings"
    type="percent"
    class="metricsAnalyticsItem"
  ></app-carrier-analytics-item>
  <app-carrier-analytics-item
    label="Digital/Total Bookings"
    [value]="carrierMetrics?.totalSelfServeBookings"
    class="metricsAnalyticsItem"
  ></app-carrier-analytics-item>
  <app-carrier-analytics-item
    label="App Bookings"
    [value]="carrierMetrics?.totalSelfServeBookings"
    class="metricsAnalyticsItem"
  ></app-carrier-analytics-item>
</div>
