import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TabsComponent } from './tabs.component';
import { MaterialModule } from '@haulynx/modules';

@NgModule({
  imports: [CommonModule, MaterialModule],
  exports: [TabsComponent],
  declarations: [TabsComponent],
})
export class TabsModule {}
