import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Tab } from '@haulynx/types';
import { Store } from '@ngrx/store';
import { get } from 'lodash';
import { Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { AppState } from '../../main-store/app.reducers';
import { LoadAvailableActions } from './load.actions';
import { loadAvailableTabsSelector } from './load.selectors';

@Injectable({
  providedIn: 'root',
})
export class LoadAvailableTabsModel {
  tabs$: Observable<any> = this.store.select(loadAvailableTabsSelector.getTabs);
  tabsSelected$: Observable<any> = this.store.select(loadAvailableTabsSelector.getSelected).pipe(
    distinctUntilChanged((tab: Tab, nextTab: Tab) => {
      return get(nextTab, 'id') === get(tab, 'id');
    })
  );

  constructor(private store: Store<AppState>, private router: Router, private activatedRoute: ActivatedRoute) {}

  addTabs(tabs: Tab[] | Tab): void {
    this.store.dispatch(LoadAvailableActions.addTabs(tabs));
  }

  updateTabs(tabs: Tab[] | Tab): void {
    this.store.dispatch(LoadAvailableActions.updateTabs(tabs));
  }

  selectTab(tab: Tab | any): void {
    this.store.dispatch(LoadAvailableActions.selectTab(tab));
  }

  removeTabs(data: Tab): void {
    this.store.dispatch(LoadAvailableActions.removeTab(data));
  }

  goTo(url: string): void {
    this.router.navigate([url], { relativeTo: this.activatedRoute });
  }
}
