import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent {
  defaultValues = {
    title: 'Confirmation',
    message: '',
    confirm: { text: 'Yes' },
    deny: { text: 'No', hide: false },
  };

  constructor(
    public dialogRef: MatDialogRef<ConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      message: string;
      confirm?: { text: string };
      deny?: { text: string; hide: boolean };
    }
  ) {
    this.data = { ...this.defaultValues, ...data };
  }
}
