import { FormGroup } from '@angular/forms';
import { LoadsServiceLoadStatus } from '../../loads-service';
import { IColumns2 } from '../data-table-v2/data-table-v2-type';
import { SidebarItem } from '../generic/sidebar';
import { RegionTypes } from '../location';
import { SearchView } from '../search-view/search-view';
import { ISearchFilter } from './search-filter';

export enum SaveSearchViewSteps {
  FORM = 'form',
  SUMMARY = 'summary',
}

export interface SaveSearchViewSummary {
  selectedSidebarItemParent: SidebarItem;
  searchFilters: ISearchFilter[];
  columnsToShow: IColumns2[];
  searchForm: FormGroup;
  searchViews: SearchView[];
}
export interface LoadSearchBarResult {
  minBidCount?: number;
  maxBidCount?: number;
  minWeight?: number;
  maxWeight?: number;
  truckUnitId?: string;
  tmwNumber?: string;
  loadStatus?: LoadsServiceLoadStatus;
  shipper?: string;
  minRevenue?: number;
  maxRevenue?: number;
  region?: RegionTypes;
  receiver?: string;
  priorityValue?: string;
  minPrice?: number;
  maxPrice?: number;
  firstAppointmentStart?: number;
  firstAppointmentEnd?: number;
  originRadius?: number[];
  originLat?: number[];
  originLon?: number[];
  originLocation?: string[];
  orderNumber?: string;
  minMaxBuy?: number;
  maxMaxBuy?: number;
  minHighestBid?: number;
  maxHighestBid?: number;
  equipment?: string;
  driverName?: string;
  minDistance?: number;
  maxDistance?: number;
  destinationRadius?: number[];
  destinationLat?: number[];
  destinationLon?: number[];
  destinationLocation?: string[];
  lastAppointmentEnd?: number;
  deliveryDateTo?: number;
  minCxPriority?: number;
  maxCxPriority?: number;
  commodity?: string;
  carrierNameOrDot?: string;
  bookingBrokers?: string[];
  brokerNameOrUsxId?: string[];
  billTo?: string[];
  minOperationsPriority?: number;
  appointmentSet?: string;
  dateRange?: string;
  startDate?: number;
  endDate?: number;
  hazmat?: boolean;
}
