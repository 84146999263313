import {
  allCarrierSearchListTypes,
  CarrierSearchListRoute,
  CarrierSearchListSection,
  CarrierSearchListTypeBase,
  ExpansionDropdown,
  FFState,
  User,
} from '@haulynx/types';
import { Observable, of } from 'rxjs';

export const CarrierSearchListConfig = (
  user: User,
  features: FFState
): Observable<ExpansionDropdown<CarrierSearchListTypeBase>[]> => {
  const obj = {};

  const types = alterSectionsBasedOnFeatureFlags(user, features);
  types.forEach((data: CarrierSearchListTypeBase) => {
    if (!obj[data.listSection]) obj[data.listSection] = { title: data.listSection, children: [] };
    obj[data.listSection].children.push(data);
  });

  return of(Object.values(obj));
};

const alterSectionsBasedOnFeatureFlags = (user: User, features: FFState): CarrierSearchListTypeBase[] => {
  let listTypeBase = [...allCarrierSearchListTypes];

  if (!user?.isCompanyAdmin) {
    listTypeBase = [
      ...listTypeBase.filter(
        (base: CarrierSearchListTypeBase) =>
          base.listSection !== CarrierSearchListSection.activeLoads &&
          base.listSection !== CarrierSearchListSection.getFreight &&
          base.listSection !== CarrierSearchListSection.completedLoads
      ),
    ];
  }

  // Remove the test loads section when test loads are not enabled
  if (!features.TEST_LOADS)
    listTypeBase = [
      ...listTypeBase.filter((base: CarrierSearchListTypeBase) => base.id !== CarrierSearchListRoute.TEST_LOADS),
    ];

  // Change wording from 'Bids / Offers' to 'Offers' when bidding is not enabled
  if (!features.CARRIER_BID)
    listTypeBase = [
      ...listTypeBase.map((base: CarrierSearchListTypeBase) => {
        if (base.id === CarrierSearchListRoute.BIDS_AND_OFFERS) return { ...base, name: 'Offers' };

        return base;
      }),
    ];

  // Either remove the old active loads section or the new active loads section depending on the Carrier Load Search Active Loads feature flag
  listTypeBase = features.CLS_ACTIVE_LOADS
    ? [
        ...listTypeBase.filter(
          (base: CarrierSearchListTypeBase) => base.listSection !== CarrierSearchListSection.myLoads
        ),
      ]
    : [
        ...listTypeBase.filter(
          (base: CarrierSearchListTypeBase) => base.listSection !== CarrierSearchListSection.activeLoads
        ),
      ];

  return listTypeBase;
};
