import { Bid } from '@haulynx/types';
import { has } from 'lodash';
import { AppSettingsActionTypes } from '../../main-store/app-settings.actions';
import { DispatchAction } from '../../shared/store/helpers/action';
import { combineAdapterReducers } from '../../shared/store/helpers/adapter';
import { combineReducersFlat } from '../../shared/store/helpers/reducer';
import { LoadState } from '../load/load.state';
import { bidAdapter, carriersLoadFeedAdapter, exclusiveLoadFeedAdapter, loadFeedAdapter } from './adapters';
import { LoadFeedActionTypes } from './load-feed.actions';
import { LoadFeedState } from './load-feed.state';

export const initialState: LoadFeedState = new LoadFeedState();

const loadFeedState = combineAdapterReducers<LoadFeedState>(loadFeedAdapter);
const exclusiveLoadFeedState = combineAdapterReducers<LoadState>(exclusiveLoadFeedAdapter);
const carrierLoadFeedState = combineAdapterReducers<LoadState>(carriersLoadFeedAdapter);
const bidState = combineAdapterReducers<LoadState>(bidAdapter);

const mainReducer = combineReducersFlat([loadFeedState, exclusiveLoadFeedState, carrierLoadFeedState, bidState]);

export function loadFeedReducer(state: LoadFeedState = initialState, action: DispatchAction): LoadFeedState {
  switch (action.type) {
    case AppSettingsActionTypes.LOG_OUT:
      return initialState;

    case LoadFeedActionTypes.SET_LOADING:
      return state.merge({
        isLoading: action.payload,
      });

    case LoadFeedActionTypes.REJECT_EXCLUSIVE_LOAD:
      return state.merge({
        isLoading: true,
      });

    case LoadFeedActionTypes.REJECT_EXCLUSIVE_LOAD_SUCCESS:
    case LoadFeedActionTypes.REJECT_EXCLUSIVE_LOAD_ERROR:
      return state.merge({
        isLoading: false,
      });

    case LoadFeedActionTypes.GET_CARRIER_SUCCESS:
      return state.merge({
        isEldCarrier: action.payload,
      });

    case LoadFeedActionTypes.SET_EXPANDED_PANELS: {
      const { payload } = action;
      let newState: {
        isExpandedExclusive?: boolean;
      } = {};

      if (has(payload, 'isExpandedExclusive')) {
        newState = { ...newState, isExpandedExclusive: !!payload.isExpandedExclusive };
      }

      return state.merge({
        ...newState,
      });
    }

    case LoadFeedActionTypes.GET_BID_STATUSES:
      return state.merge({
        isLoadingBidStatuses: true,
      });

    case LoadFeedActionTypes.GET_BID_STATUSES_SUCCESS: {
      const { payload } = action;

      return state.merge({
        bidStatuses: payload,
        isLoadingBidStatuses: false,
      });
    }

    case LoadFeedActionTypes.GET_BID_STATUSES_ERROR:
      return state.merge({
        bidStatuses: [],
        isLoadingBidStatuses: false,
      });

    case LoadFeedActionTypes.GET_BID_HISTORY: {
      const {
        payload: { bidId },
      } = action;

      return state.merge({
        isLoadingBidHistory: { ...state.isLoadingBidHistory, [bidId]: true },
      });
    }

    case LoadFeedActionTypes.GET_BID_HISTORY_SUCCESS: {
      const {
        payload: { bidId, entities },
      } = action;

      return state.merge({
        bidHistory: { ...state.bidHistory, [bidId]: entities },
        isLoadingBidHistory: { ...state.isLoadingBidHistory, [bidId]: false },
      });
    }

    case LoadFeedActionTypes.GET_BID_HISTORY_ERROR: {
      const {
        payload: { bidId },
      } = action;

      return state.merge({
        isLoadingBidHistory: { ...state.isLoadingBidHistory, [bidId]: false },
      });
    }

    case LoadFeedActionTypes.CREATE_BID:
      return state.merge({
        isLoadingBidCreate: true,
      });

    case LoadFeedActionTypes.CREATE_BID_SUCCESS:
    case LoadFeedActionTypes.CREATE_BID_ERROR:
      return state.merge({
        isLoadingBidCreate: false,
      });

    case LoadFeedActionTypes.UPDATE_BID:
      return state.merge({
        isLoadingBidCreate: true,
      });

    case LoadFeedActionTypes.UPDATE_BID_SUCCESS:
    case LoadFeedActionTypes.UPDATE_BID_ERROR:
      return state.merge({
        isLoadingBidCreate: false,
      });

    case LoadFeedActionTypes.ACCEPT_COUNTER_BID:
      return state.merge({
        isLoadingAcceptCounterBid: true,
      });

    case LoadFeedActionTypes.ACCEPT_COUNTER_BID_SUCCESS:
    case LoadFeedActionTypes.ACCEPT_COUNTER_BID_ERROR:
      return state.merge({
        isLoadingAcceptCounterBid: false,
      });

    case LoadFeedActionTypes.ACCEPT_BID: {
      const bid: Partial<Bid> = action.payload.bid;

      return state.merge({
        isLoadingAcceptBid: { ...state.isLoadingAcceptBid, [bid.id]: true },
      });
    }

    case LoadFeedActionTypes.ACCEPT_BID_SUCCESS:
    case LoadFeedActionTypes.ACCEPT_BID_ERROR: {
      const {
        payload: { bidId },
      } = action;

      return state.merge({
        isLoadingAcceptBid: { ...state.isLoadingAcceptBid, [bidId]: false },
      });
    }

    case LoadFeedActionTypes.SORT_BIDS_BY: {
      const {
        payload: { loadId, sortBy },
      } = action;

      return state.merge({
        sortBidsBy: { ...state.sortBidsBy, [loadId]: sortBy },
      });
    }

    case LoadFeedActionTypes.GET_CUSTOMER: {
      const {
        payload: { id },
      } = action;

      return state.merge({
        isLoadingCustomer: { ...state.isLoadingCustomer, [id]: true },
      });
    }

    case LoadFeedActionTypes.GET_CUSTOMER_SUCCESS: {
      const {
        payload: { id, entity },
      } = action;

      return state.merge({
        customers: { ...state.customers, [id]: entity },
        isLoadingCustomer: { ...state.isLoadingCustomer, [id]: false },
      });
    }

    case LoadFeedActionTypes.GET_CUSTOMER_ERROR: {
      const {
        payload: { id },
      } = action;

      return state.merge({
        isLoadingCustomer: { ...state.isLoadingCustomer, [id]: false },
      });
    }

    case LoadFeedActionTypes.CREATE_COUNTER_OFFER: {
      const {
        payload: { bidId },
      } = action;

      return state.merge({
        isLoadingCounterOfferCreate: { ...state.isLoadingCounterOfferCreate, [bidId]: true },
      });
    }

    case LoadFeedActionTypes.CREATE_COUNTER_OFFER_SUCCESS:
    case LoadFeedActionTypes.CREATE_COUNTER_OFFER_ERROR: {
      const {
        payload: { bidId },
      } = action;

      return state.merge({
        isLoadingCounterOfferCreate: { ...state.isLoadingCounterOfferCreate, [bidId]: false },
      });
    }

    default:
      return mainReducer(state, action);
  }
}
