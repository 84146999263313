import gql from 'graphql-tag';

export const GetUsersByBroker = gql(`
  query GetUsersByBroker($brokerId: ID!) {
    getUsersByBroker(brokerId: $brokerId) {
      id
      name
    }
  }
`);
