import { Injectable } from '@angular/core';
import { Environment, EnvironmentName } from '@haulynx/types';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  constructor(private environment: Environment) {}

  getEnvironment(): Environment {
    return this.environment;
  }

  isPreviewEnvironment(): boolean {
    const previewEnvNames: EnvironmentName[] = ['alpha', 'pilot', 'staging'];
    return previewEnvNames.indexOf(this.environment.envName) !== -1;
  }

  getApmServerUrl(): string {
    return this.environment.elastic.apmServerUrl;
  }

  getEnvironmentName(): EnvironmentName {
    return this.environment.envName;
  }

  /**
   * In some environments, you don't want to message users a link to your current environment, but rather production.
   * @returns the current environment's preferred host name for sending links to other users, e.g. https://app.haulynx.com,
   * or the current host name if no preference is defined.
   */
  getInvitationHostName(): string {
    return this.environment.invitationHostName || document.location.origin;
  }

  // /**
  //  * @return the app version number
  //  */
  // getVersionNumber(): string {
  //   return version;
  // }
}
