import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NotificationVmService } from '@haulynx/services';
import { NotificationEventFilterParams } from '@haulynx/store';
import { NotificationEvent, NotificationEventType, User, WebNotification } from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { filter, includes } from 'lodash';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { AppDropDownComponent } from '../../../drop-down/components/app-drop-down/app-drop-down.component';

@Component({
  selector: 'app-notification-center',
  templateUrl: './app-notification-center.component.html',
  styleUrls: ['./app-notification-center.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppNotificationCenterComponent implements OnChanges, OnDestroy, OnInit {
  @Input() notifications: NotificationEvent[] = null;
  @Input() eventTypes: string[] = [];
  @Input() showNotification: boolean;
  @Input() query: NotificationEventFilterParams;
  @Input() isLoading = false;
  @Input() user: User;
  @Output() selectNotification = new EventEmitter<WebNotification>();
  @Output() closeNotification = new EventEmitter();
  @Output() search = new EventEmitter<NotificationEventFilterParams>();
  @ViewChild(AppDropDownComponent, { static: true }) dropDown: AppDropDownComponent;

  alive = aliveWhile();
  form: FormGroup;
  eventTypesDropDown: { label: string; id: string }[] = [];
  types: { label: string; id: string }[] = [];
  availableNotifications: NotificationEvent[] = [];
  allNotifications: NotificationEvent[] = [];

  constructor(private notificationVmService: NotificationVmService) {
    this.form = this.notificationVmService.create();
    this.eventTypesDropDown = [
      {
        label: 'Bidding',
        id: NotificationEventType.BIDS_EVENT,
      },
      {
        label: 'New Match',
        id: NotificationEventType.POSTED_TRUCK_RECOMMENDATION,
      },
      {
        label: 'Repeated Load Match',
        id: NotificationEventType.BROKER_PROFILE_RECOMMENDATION,
      },
      {
        label: 'Broker Assignments',
        id: NotificationEventType.LOAD_BROKER_ASSIGNMENT,
      },
    ];
    this.types = [this.eventTypesDropDown[0]];
  }

  close(event: Event): void {
    event.stopPropagation();
    this.closeNotification.emit();
  }

  select(event: WebNotification): void {
    this.selectNotification.emit(event);
  }

  ngOnInit(): void {
    this.form
      .get('eventType')
      .valueChanges.pipe(distinctUntilChanged(), debounceTime(100), takeUntil(this.alive))
      .subscribe((eventType) => {
        const { recipient, pastDays } = this.form.getRawValue();
        const query = { eventType, recipient, pastDays, targetCarrierDot: this.user?.carrier?.dot };

        this.availableNotifications = [];
        this.search.emit(query);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { eventTypes, query, notifications, showNotification } = changes;
    if (eventTypes) {
      this.types = [
        {
          label: 'All Notifications',
          id: null,
        },
        ...filter(this.eventTypesDropDown, (type) => includes(eventTypes.currentValue, type.id)),
      ];
    }

    if (notifications || eventTypes) {
      const type = this.form.get('eventType').value;
      const currentNotifications: NotificationEvent[] =
        (notifications && notifications.currentValue) || this.notifications;
      const currentEventType: string[] = (eventTypes && eventTypes.currentValue) || this.eventTypes;

      if (!type) {
        this.availableNotifications = filter(currentNotifications, (notification) =>
          includes(currentEventType, notification.eventType)
        );
        this.allNotifications = this.availableNotifications;
      }

      if (type === NotificationEventType.POSTED_TRUCK_RECOMMENDATION) {
        this.availableNotifications = [
          ...filter(currentNotifications, (notification) => includes(type, notification.eventType)),
          ...filter(this.allNotifications, (notification) =>
            includes(NotificationEventType.LOAD_MATCH, notification.eventType)
          ),
        ];
      }

      if (!!type && type !== NotificationEventType.POSTED_TRUCK_RECOMMENDATION) {
        if (this.availableNotifications.some((notification) => notification.eventType.includes(type))) {
          const newNotifications = filter(currentNotifications, (notification) =>
            includes(type, notification.eventType)
          );
          if (!!newNotifications.length) {
            this.availableNotifications = [...this.availableNotifications, ...newNotifications];
          }
        } else {
          this.availableNotifications = filter(currentNotifications, (notification) =>
            includes(type, notification.eventType)
          );
        }
      }
    }

    if (query) {
      this.form.patchValue(query.currentValue);
    }

    if (showNotification) {
      if (!showNotification.currentValue) {
        this.dropDown.closeDropDown();
      }
    }
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }
}
