import { TrackingType } from '../web-interfaces';

export interface LoadTrackingDetails {
  usxOrderStatus: LoadTrackingStatusReasons;
  updatedAt: number;
  usxOrderStatusReason: string;
}

export enum LoadTrackingStatusReasons {
  NOT_RESPONDING = 'Not Responding',
  EXPIRED = 'Expired',
  ACTIVE = 'Active',
  READY_TO_TRACK = 'Ready To Track',
}

export interface LoadTrackingType {
  label: string;
  inactiveLabel: string;
  trackingEnum: TrackingType;
}

export const AvailableLoadTrackingTypes: LoadTrackingType[] = [
  { label: 'MacroPoint Hardware', inactiveLabel: 'Enroll', trackingEnum: TrackingType.MACRO_POINT },
  { label: 'Xpress Tech App', inactiveLabel: '', trackingEnum: TrackingType.MANUAL },
  { label: 'MacroPoint Mobile', inactiveLabel: '', trackingEnum: TrackingType.MACRO_POINT },
];
