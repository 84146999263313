export enum CarrierToolbarDetailsPage {
  CARRIER_PROFILE = 'carrier_profile',
}

export enum CarrierToolbarDropdownGroups {
  SELECTED_LOADS = 'Selected Loads',
  RECENT_LOADS = 'Recent Loads',
}

export enum CarrierToolbarTab {
  RECOMMENDATIONS = 0,
  CARRIER_SEARCH = 1,
  OWNED_CARRIERS = 2,
}

export enum CarrierToolbarSubTab {
  POSTED_TRUCKS = 0,
  LANES = 1,
  HISTORY = 2,
}

export enum RecommendationVote {
  LIKE = 0,
  DISLIKE = 1,
}

export interface CarrierToolbarPageData {
  carrierDot?: string;
  selectedTab?: CarrierToolbarSubTab;
  selectedItemId?: string;
  toggledElements?: { [key: string]: boolean };
  carrierSearchQuery?: string;
}

export interface CarrierToolbarState {
  isOpen: boolean;
  currentTab: CarrierToolbarTab;
  detailsPage: CarrierToolbarDetailsPage | null;
  data: CarrierToolbarPageData;
}

export enum CarrierToolbarLocations {
  CARRIER_SEARCH = 'carrier_search',
  RECOMMENDATIONS = 'recommendations',
  OWNED_CARRIERS = 'owned_carriers',
}
