import { Pipe, PipeTransform } from '@angular/core';
import { LoadLocationType, LoadsServiceRestriction } from '@haulynx/types';

@Pipe({
  name: 'trailerInstructionsLocationType',
  pure: true,
})
export class TrailerInstructionsLocationTypePipe implements PipeTransform {
  transform(locationType: LoadLocationType, restrictions: LoadsServiceRestriction[]): unknown {
    const identifier = restrictions.find((restriction: LoadsServiceRestriction) => {
      return restriction.type.includes(locationType);
    });
    return identifier?.value || '';
  }
}
