import { FeatureFlagDetails, User } from '@haulynx/types';
import { createAction, createActionTypes } from '../../shared/store/helpers/action';
import { TYPE } from './adapters';

export interface IFeatureFlagDashboardActions {
  findAll();
  findAllSuccess(params: { entities });
  findAllError(params: { errors: unknown });
  find(params: { featureFlagName: string });
  findSuccess(params: { entities });
  findByNameSuccess(params: { entity });
  findError(params: { errors: unknown });
  delete(params: { featureFlagName?: string });
  deleteSuccess();
  deleteError(params: { errors: unknown });
  findUsers(params: { email: string; index: number });
  findUsersSuccess(params: { entities: Partial<User>[]; index });
  findUsersError(params: { errors: unknown });
  clearSelectedFeature();
  findCarriers(params: { name?: string; index: number });
  findCarriersSuccess(params: { entities: Partial<User>[]; index });
  findCarriersError(params: { errors: unknown });
  create(params: { featureFlag: FeatureFlagDetails });
  createSuccess();
  createError(params: { errors: unknown });
  update(params: { featureFlagName: string; featureFlag: FeatureFlagDetails });
  updateSuccess();
  updateError(params: { errors: unknown });
}

export interface IFeatureFlagDashboardActionTypes {
  FIND_ALL: string;
  FIND_ALL_SUCCESS: string;
  FIND_ALL_ERROR: string;
  FIND: string;
  FIND_SUCCESS: string;
  FIND_BY_NAME_SUCCESS: string;
  FIND_ERROR: string;
  DELETE: string;
  DELETE_SUCCESS: string;
  DELETE_ERROR: string;
  FIND_USERS: string;
  FIND_USERS_SUCCESS: string;
  FIND_USERS_ERROR: string;
  CLEAR_SELECTED_FEATURE: string;
  FIND_CARRIERS: string;
  FIND_CARRIERS_SUCCESS: string;
  FIND_CARRIERS_ERROR: string;
  CREATE: string;
  CREATE_SUCCESS: string;
  CREATE_ERROR: string;
  UPDATE: string;
  UPDATE_SUCCESS: string;
  UPDATE_ERROR: string;
}

export const featureFlagDashboardActionTypes: IFeatureFlagDashboardActionTypes = {
  FIND_ALL: 'find all',
  FIND_ALL_SUCCESS: 'find all success',
  FIND_ALL_ERROR: 'find all error',
  FIND: 'find',
  FIND_SUCCESS: 'find success',
  FIND_BY_NAME_SUCCESS: 'find by name success',
  FIND_ERROR: 'find error',
  DELETE: 'delete',
  DELETE_SUCCESS: 'delete success',
  DELETE_ERROR: 'delete error',
  FIND_USERS: 'find users',
  FIND_USERS_SUCCESS: 'find users success',
  FIND_USERS_ERROR: 'find users error',
  CLEAR_SELECTED_FEATURE: 'clear selected feature',
  FIND_CARRIERS: 'find carriers',
  FIND_CARRIERS_SUCCESS: 'find carriers success',
  FIND_CARRIERS_ERROR: 'find carriers error',
  CREATE: 'create',
  CREATE_SUCCESS: 'create success',
  CREATE_ERROR: 'create error',
  UPDATE: 'update',
  UPDATE_SUCCESS: 'update success',
  UPDATE_ERROR: 'update error',
};

export const featureFlagDashboardActions: IFeatureFlagDashboardActions = {
  findAll: createAction(TYPE, featureFlagDashboardActionTypes.FIND_ALL),
  findAllSuccess: createAction(TYPE, featureFlagDashboardActionTypes.FIND_ALL_SUCCESS),
  findAllError: createAction(TYPE, featureFlagDashboardActionTypes.FIND_ALL_ERROR),
  find: createAction(TYPE, featureFlagDashboardActionTypes.FIND),
  findSuccess: createAction(TYPE, featureFlagDashboardActionTypes.FIND_SUCCESS),
  findByNameSuccess: createAction(TYPE, featureFlagDashboardActionTypes.FIND_BY_NAME_SUCCESS),
  findError: createAction(TYPE, featureFlagDashboardActionTypes.FIND_ERROR),
  delete: createAction(TYPE, featureFlagDashboardActionTypes.DELETE),
  deleteSuccess: createAction(TYPE, featureFlagDashboardActionTypes.DELETE_SUCCESS),
  deleteError: createAction(TYPE, featureFlagDashboardActionTypes.DELETE_ERROR),
  findUsers: createAction(TYPE, featureFlagDashboardActionTypes.FIND_USERS),
  findUsersSuccess: createAction(TYPE, featureFlagDashboardActionTypes.FIND_USERS_SUCCESS),
  findUsersError: createAction(TYPE, featureFlagDashboardActionTypes.FIND_USERS_ERROR),
  clearSelectedFeature: createAction(TYPE, featureFlagDashboardActionTypes.CLEAR_SELECTED_FEATURE),
  findCarriers: createAction(TYPE, featureFlagDashboardActionTypes.FIND_CARRIERS),
  findCarriersSuccess: createAction(TYPE, featureFlagDashboardActionTypes.FIND_CARRIERS_SUCCESS),
  findCarriersError: createAction(TYPE, featureFlagDashboardActionTypes.FIND_CARRIERS_ERROR),
  create: createAction(TYPE, featureFlagDashboardActionTypes.CREATE),
  createSuccess: createAction(TYPE, featureFlagDashboardActionTypes.CREATE_SUCCESS),
  createError: createAction(TYPE, featureFlagDashboardActionTypes.CREATE_ERROR),
  update: createAction(TYPE, featureFlagDashboardActionTypes.UPDATE),
  updateSuccess: createAction(TYPE, featureFlagDashboardActionTypes.UPDATE_SUCCESS),
  updateError: createAction(TYPE, featureFlagDashboardActionTypes.UPDATE_ERROR),
};

export const FeatureFlagDashboardActions: IFeatureFlagDashboardActions = featureFlagDashboardActions;
export const FeatureFlagDashboardActionTypes: IFeatureFlagDashboardActionTypes = createActionTypes(
  featureFlagDashboardActionTypes
)(TYPE);
