<app-spinner [active]="loading && !smallLoader"></app-spinner>
<div [ngClass]="{ hidden: loading && !smallLoader }">
  <div
    class="data-table"
    (scroll)="onScroll.emit($event)"
    [ngClass]="{ 'data-table-sticky': stickyHeader }"
    (click)="containerClick($event)"
  >
    <div class="table-disable" *ngIf="disable"></div>
    <h6 *ngIf="title">{{ title }}</h6>
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      [matSortActive]="sortActiveColumn"
      [matSortDirection]="sortActiveDirection"
      (matSortChange)="changeSort($event)"
      [trackBy]="trackByFn"
      [ngClass]="cls"
      multiTemplateDataRows
    >
      <ng-container matColumnDef="list-style">
        <th mat-header-cell *matHeaderCellDef>#</th>
        <td mat-cell *matCellDef="let row">
          {{ getNumberOfRow(dataSource.filteredData.indexOf(row)) }}
        </td>
      </ng-container>

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select" *ngIf="checkBoxSelection">
        <th mat-header-cell *matHeaderCellDef (click)="$event.stopPropagation()">
          <mat-checkbox
            [color]="'primary'"
            (change)="$event ? masterToggle() : null"
            [checked]="selectionModel.hasValue() && selectionModel?.selected?.length === dataSource?.data?.length"
            [indeterminate]="
              selectionModel.hasValue() && !(selectionModel?.selected?.length === dataSource?.data?.length)
            "
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row" (click)="$event.stopPropagation()">
          <mat-checkbox
            [color]="'primary'"
            (change)="$event ? selectCheckBox(row) : null"
            [checked]="selectionModel.isSelected(row?.id)"
          >
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container *ngFor="let column of displayedColumns; trackBy: trackByItem" matColumnDef="{{ column.dataIndex }}">
        <ng-container *ngIf="column.isSortable; else notSortable">
          <th
            [ngClass]="{ 'right-align-col': column.align === 'right', 'hide-cell': hideColumnsMap[column.dataIndex] }"
            mat-header-cell
            [attr.data-test]="column.label"
            *matHeaderCellDef
            mat-sort-header
            @.disabled
          >
            <span #sortable>
              {{ column.label }}
            </span>
            <div class="sub-label-container" [style.width.px]="sortable.offsetWidth" *ngIf="column.sublabel">
              <span class="sub-label">
                {{ column.sublabel }}
              </span>
            </div>
          </th>
        </ng-container>
        <ng-template #notSortable>
          <th [ngClass]="{ 'right-align-col': column.align === 'right' }" mat-header-cell *matHeaderCellDef>
            <span #unsortable>
              {{ column.label }}
            </span>
            <div class="sub-label-container" [style.width.px]="unsortable.offsetWidth" *ngIf="column.sublabel">
              <span class="sub-label">
                {{ column.sublabel }}
              </span>
            </div>
          </th>
        </ng-template>
        <td
          mat-cell
          *matCellDef="let element"
          [attr.width]="column.width ? column.width : ''"
          class="row-column"
          [attr.data-test]="column.label"
          [ngClass]="{ 'hide-cell': hideColumnsMap[column.dataIndex] }"
        >
          <div *ngIf="column.isCustomCell">
            <ng-container *ngTemplateOutlet="templateCellRef; context: { row: element, column: column.dataIndex }">
            </ng-container>
          </div>

          <div *ngIf="!column.isCustomCell" class="{{ column.dataIndex }}">
            {{ element | get: column.dataIndex:column.defaultValue || '' }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="expandedDetail">
        <td
          mat-cell
          *matCellDef="let element"
          [ngClass]="{
            expanded: element | isExpanded: this.expandedElement,
            collapsed: !(element | isExpanded: this.expandedElement) || expandedAll
          }"
          [attr.colspan]="columns.length"
          class="element-details-hide"
        >
          <div
            class="element-detail"
            [@detailExpand]="(element | isExpanded: this.expandedElement) || expandedAll ? 'expanded' : 'collapsed'"
          >
            <ng-container *ngTemplateOutlet="templateExpandedRef; context: { row: element }"></ng-container>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columns; sticky: stickyHeader"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: columns"
        (click)="select(row, $event)"
        (mouseenter)="hoverRow(true, row.id)"
        (mouseleave)="hoverRow(false, row.id)"
        [ngClass]="{
          clickable: selection || clickableRow,
          selected: selection && selectionModel.isSelected(row?.id)
        }"
        class="element-row {{ row | getRowClassPipe: rowSettings?.cls }}"
        [class.expanded-row]="expandedElement === row"
        data-cy="data-table-row"
      ></tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
    </table>

    <div *ngIf="!loading && dataSource.data.length === 0" class="empty-message">{{ emptyMessage }}</div>
  </div>
</div>
<div class="footer" [ngClass]="{ hidden: loading && !smallLoader }">
  <mat-paginator
    [hidden]="!showPagination"
    [length]="pagination?.total"
    [pageIndex]="pagination?.page - 1"
    [pageSize]="pagination?.limit"
    [pageSizeOptions]="pageSizeOptions"
    showFirstLastButtons
    data-test="mat-paginator"
  >
  </mat-paginator>
  <app-spinner [active]="loading && smallLoader" active="true" diameter="23"></app-spinner>
</div>
