import { NgModule } from '@angular/core';
import { DatetimePickerComponent } from './datetime-picker.component';
import { RangeModeService, SingleModeService } from '@haulynx/services';
import { CalendarModule } from 'primeng/calendar';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PipesModule } from '@haulynx/pipes';

export const DatetimePickerImports = [CommonModule, CalendarModule, ReactiveFormsModule, PipesModule];

@NgModule({
  imports: DatetimePickerImports,
  declarations: [DatetimePickerComponent],
  providers: [SingleModeService, RangeModeService],
  exports: [DatetimePickerComponent],
})
export class DatetimePickerModule {}
