import gql from 'graphql-tag';

export const GetSearchViewsByUserId = gql(`
  query GetSearchViewsByUserId($userId: StrictId!) {
    getSearchViewsByUserId(userId: $userId){
      id
      label
      parent
      data {
        carrierDot
        brokerId
        carrierNameOrDot
        bookingBrokers
        brokerNameOrUsxId
        brokerTeamId
        loadStatus
        bookStatus
        distributionMechanism
        showExcluded
        providerId
        providerType
        region
        cxrEmployees
        billTo
        shipper
        receiver
        driverName
        truckUnitId
        equipment
        commodity
        minPrice
        maxPrice
        minMaxBuy
        maxMaxBuy
        minRevenue
        maxRevenue
        minWeightLbs
        maxWeightLbs
        minDistanceMiles
        maxDistanceMiles
        minCxPriority
        maxCxPriority
        minOperationsPriority
        maxOperationsPriority
        originLat
        originLon
        originRadiusMiles
        originStates
        originLocation
        destinationLat
        destinationLon
        destinationRadiusMiles
        destinationStates
        destinationLocation
        firstLocationAppointmentSet
        lastLocationAppointmentSet
        firstAppointmentStart
        firstAppointmentEnd
        lastAppointmentStart
        lastAppointmentEnd
        firstAppointmentStartDelta
        firstAppointmentEndDelta
        lastAppointmentStartDelta
        lastAppointmentEndDelta
        inboundRegion
        outboundRegion
        originZipLane
        destinationZipLane
        originZoneLane
        destinationZoneLane
        alternateIdType
        alternateIdValue
        showTestLoads
        carrierDotOfferedTo
        bookingOrAssignedBroker
        hazmat
        orderType
        pickupRequirements
        dropoffRequirements
      }
      metaData {
        columns {
          key
          value
          order
        }
        stickyColumnWidthClosed
        stickyColumnWidthOpen
      }
    }
  }
`);
