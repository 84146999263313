import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PasswordStrengthComponent } from './password-strength.component';

@NgModule({
  declarations: [PasswordStrengthComponent],
  exports: [PasswordStrengthComponent],
  imports: [CommonModule, FlexLayoutModule],
})
export class PasswordStrengthModule {}
