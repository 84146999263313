import { LoadsServiceLoad } from '../../loads-service';
import { Bid } from '../bid';

export class NotificationEvent {
  id: string;
  recipient: string;
  eventType: NotificationEventType;
  secondaryEventType?: SecondaryNotificationEvent;
  secondaryTargetObjectId?: string;
  /**
   * Could be one of 'LOAD', 'BID', etc
   */
  secondaryTargetObjectType?: string;
  /**
   * Could be one of 'LOAD', 'BID', etc
   */
  targetObjectType: string;
  targetObjectId: string;
  targetCarrierDot: string;
  targetUserId: string;
  targetTruckId: string;
  expirationDate: number;
  active: boolean;
  message: string;
  createdBy: string;
  createdAt: number;
  modifiedBy: string;
  modifiedAt: number;
  targetCarrierDisplay: string;
  targetUserDisplay: string;
  pushNotifications?: PushNotification[];
  smsNotifications?: SMSNotification[];
  emailNotifications?: EmailNotification[];
  loadsServiceLoad?: LoadsServiceLoad;
  bid?: Bid;

  constructor(props?: Partial<NotificationEvent>) {
    Object.assign(this, props);
  }
}

export enum NotificationEventType {
  AUTO_ASSIGN_BROKER = 'AUTO_ASSIGN_BROKER_ON_BOOKING',
  BIDS_EVENT = 'BIDS_EVENT',
  BROKER_PROFILE_RECOMMENDATION = 'BROKER_PROFILE_RECOMMENDATION',
  LOAD_ASSIGNMENT = 'LOAD_ASSIGNMENT',
  LOAD_BROKER_ASSIGNMENT = 'LOAD_BROKER_ASSIGNMENT',
  LOAD_MATCH = 'LOAD_MATCH',
  LOAD_OFFER = 'LOAD_OFFER',
  POSTED_TRUCK_RECOMMENDATION = 'POSTED_TRUCK_RECOMMENDATION',
  USX_EVENT = 'USX_EVENT',
}

export enum SecondaryNotificationEvent {
  BID_NEW = 'BID_NEW',
  BID_UPDATE = 'BID_UPDATE',
  BID_COUNTER = 'BID_COUNTER',
  BID_REJECTION = 'BID_REJECTION',
  BID_ACCEPTANCE = 'BID_ACCEPTANCE',
  BID_LOAD_BOOKED = 'BID_LOAD_BOOKED',
  BID_BOOKING_ERROR = 'BID_BOOKING_ERROR',

  BROKER_ASSIGNED_LOAD_BY_ML = 'BROKER_ASSIGNED_LOAD_BY_ML',
  BROKER_UNASSIGNED_LOAD_BY_USER = 'BROKER_UNASSIGNED_LOAD_BY_USER',
  BROKER_ASSIGNED_LOAD_BY_USER = 'BROKER_ASSIGNED_LOAD_BY_USER',

  // usx types below
  APPOINTMENT_RESET = 'APPOINTMENT_RESET',
}

export interface PushNotification {
  id: string;
  success: boolean;
  message: string;
  title: string;
  eventId: string;
  attemptedDate: number;
  url: string;
}

export interface SMSNotification {
  id: string;
  eventId: string;
  message: string;
  attemptedDate: number;
  success: boolean;
}

export interface EmailNotification {
  id: string;
  success: string;
  eventId: string;
  subject: string;
  templateVersion: string;
  templateParameters: JSON;
  template: string;
  attemptedDate: number;
  tags: string;
}
