import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserEntityService } from '@haulynx/store';
import { buttonTypes, User } from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'haulynx-carrier-form-search',
  templateUrl: './carrier-form-search.component.html',
  styleUrls: ['./carrier-form-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarrierFormSearchComponent implements OnChanges, OnDestroy {
  @Input() filter: any;
  @Output() search = new EventEmitter();
  alive = aliveWhile();
  form: FormGroup;
  carriers: any[] = [];
  buttons = [buttonTypes.SEARCH];
  user: User;
  searchKeyWordStream$ = new Subject();

  constructor(private userEntityService: UserEntityService, private fb: FormBuilder) {
    this.userEntityService.user$.pipe(takeUntil(this.alive)).subscribe((user) => {
      this.user = user;
    });

    this.form = this.fb.group({
      carrierName: [''],
      dot: [''],
      mcNumber: [''],
      search: [''],
    });

    this.searchKeyWordStream$.pipe(debounceTime(500), takeUntil(this.alive)).subscribe(() => {
      const formData = this.form.getRawValue();

      this.search.emit(formData);
    });
  }

  handlerAction(action) {
    if (action === buttonTypes.SEARCH.action) {
      const formData = this.form.getRawValue();

      this.search.emit(formData);
    }
  }

  enter() {
    const formData = this.form.getRawValue();

    this.search.emit(formData);
  }

  searchKeyWord(keyword) {
    this.searchKeyWordStream$.next(keyword);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { filter } = changes;

    if (filter) {
      this.form.patchValue(filter.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }
}
