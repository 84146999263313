import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ZipLane } from '@haulynx/types';
@Component({
  selector: 'app-lane-rate-tool-timeline',
  templateUrl: './lane-rate-tool-timeline.component.html',
  styleUrls: ['./lane-rate-tool-timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LaneRateToolTimelineComponent {
  @Input() lane: ZipLane;
}
