import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GetUserById } from '@haulynx/gql';
import {
  Carrier,
  CarrierOptionType,
  CarrierSubType,
  LoginResponse,
  UnclaimedUser,
  User,
  UserSignup,
  UserSignupHeaders,
} from '@haulynx/types';
import { toHttpParams } from '@haulynx/utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GraphqlService } from '../graphql/graphql.service';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  constructor(private graphqlService: GraphqlService, private http: HttpClient) {}

  acceptTermsOfService(sendEmail: boolean): Observable<User> {
    return this.http.put<User>('/api/account/terms', { sendEmail });
  }

  createUser(user: UserSignup, headers: UserSignupHeaders, brokerReferral: Array<string>): Observable<LoginResponse> {
    const options = { headers: { Platform: 'web' } };
    Object.keys(headers).forEach((key: string) => {
      // Cannot add null headers
      if (headers[key]) {
        options.headers[key] = headers[key];
      }
    });

    return this.http.post<LoginResponse>('/api/account/signup', { user, brokerReferral }, options);
  }

  loginUser(email: string, password: string): Observable<LoginResponse> {
    return this.http.post<LoginResponse>('/api/account/login', { email, password });
  }

  refreshToken(): Observable<LoginResponse> {
    return this.http.post<LoginResponse>('/api/account/refresh-token', {});
  }

  logoutUser(email: string): Observable<boolean> {
    return this.http.post<boolean>('/api/account/logout', { email });
  }

  getUserById(userId: string): Observable<User> {
    return this.graphqlService
      .query({
        query: GetUserById,
        variables: { id: userId },
      })
      .pipe(map((result) => result.data['getUserById']));
  }

  getUserUnclaimedByEmail(email: string): Observable<{ carrierClaimed: boolean; name: string }> {
    const params = toHttpParams({ email: encodeURIComponent(email) }); // Important to encode uri so symbols are passed along.
    return this.http.get<{ carrierClaimed: boolean; name: string }>(`/api/account/unclaimed-user`, {
      params,
    });
  }

  /**
   * Verify a user email
   * @param email The email of the user that needs to be verified
   * @returns
   */
  adminVerifyEmail(email: string): Observable<User> {
    return this.http.post<User>(`/api/account/verify/user`, { email });
  }

  /**
   * Verify a user email
   * @param code The email verifiation code from Firebase
   * @returns
   */
  verifyEmail(code: string): Observable<User> {
    return this.http.post<User>(`/api/account/verify`, { code });
  }

  resendVerificationEmail(): Observable<User> {
    return this.http.post<User>('/api/account/verify/resend', {});
  }

  resetPassword(code: string, password: string): Observable<{ message: string }> {
    return this.http.post<{ message: string }>('/api/account/password-reset', { code, password });
  }

  sendPasswordResetEmail(email: string): Observable<{ message: string }> {
    return this.http.post<{ message: string }>('/api/account/password-reset/resend', { email });
  }

  searchForCarrier(
    type: CarrierOptionType,
    subtype: CarrierSubType,
    value: string,
    permitState: string
  ): Observable<any> {
    return this.http.post<any>('/api/account/find-carrier', { type, subtype, value, permitState });
  }

  createCarrier(carrier: any, title: string, phone: string): Observable<{ carrier: Carrier; user: User }> {
    return this.http.post<any>('/api/account/create-carrier', { carrier, title, phone });
  }

  rmisToken(): Observable<{ url: string }> {
    return this.http.get<{ url: string }>('/api/account/rmis-token');
  }

  unclaimedUser(): Observable<{ user: User }> {
    return this.http.post<{ user: User }>('/api/account/unclaimed-user', {});
  }

  attachUnclaimedUser(unclaimedUser: UnclaimedUser): Observable<{ user: User }> {
    return this.http.post<{ user: User }>('/api/account/attach-unclaimed-user', { unclaimedUser });
  }
}
