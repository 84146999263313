import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[haulynxDisabled]',
})
export class DisableDirective implements OnChanges {
  @Input('haulynxDisabled') disableButton: boolean;

  constructor(private renderer: Renderer2, private element: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { disableButton } = changes;

    if (disableButton?.currentValue) this.disable();
    else this.enable();
  }

  disable() {
    this.renderer.setStyle(this.element.nativeElement, 'pointer-events', 'none');
    this.renderer.setStyle(this.element.nativeElement, 'opacity', '0.3');
  }

  enable() {
    this.renderer.setStyle(this.element.nativeElement, 'pointer-events', 'auto');
    this.renderer.setStyle(this.element.nativeElement, 'opacity', '1');
  }
}
