import gql from 'graphql-tag';

export const SendPaymentItems = gql(`
  mutation SendPaymentItems($orderNumber: StrictId, $loadId: StrictId) {
    sendPaymentItems(orderNumber: $orderNumber, loadId: $loadId) {
      orderNumber
      paymentType
      amount
      paymentUnits
      haulynxId
      usxId
    }
  }
`);
