import { LaneVolatility, LoadIdentifierType, LoadsServiceLoad, LoadsServiceProviderDetailId } from '@haulynx/types';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'laneVolatility',
})
export class LaneVolatilityPipe implements PipeTransform {
  transform(laneVol: string): string {
    switch (laneVol) {
      case LaneVolatility.LOW:
        return 'Low';
      case LaneVolatility.MEDIUM:
        return 'Medium';
      case LaneVolatility.HIGH:
        return 'High';
      default:
        return 'N/A';
    }
  }
}
