import gql from 'graphql-tag';

export const CreateCounterOffer = gql`
  mutation CreateCounterOffer($bidId: StrictId!, $newCounterOfferInput: CounterOfferInput!) {
    rejectAndCreateNewCounterOfferByBidId(bidId: $bidId, newCounterOfferInput: $newCounterOfferInput) {
      id
      carrierId
      bidId
      price
      notes
      status
      createdAt
      createdBy {
        id
        name
      }
      updatedAt
      updatedBy {
        id
        name
      }
      deletedAt
    }
  }
`;
