<div class="create-mission" *ngIf="(pageNum$ | async) === 0; else page2">
  <div class="create-mission__header">
    <div>
      <h1>CREATE MISSION - STEP 1 OF 3</h1>
      <p>Fill in the number of missions you would like to create</p>
    </div>
    <button mat-icon-button (click)="close()">
      <mat-icon class="close-icon">close</mat-icon>
    </button>
  </div>

  <form class="create-mission__form" [formGroup]="mainForm">
    <label> Missions To Create </label>
    <mat-form-field class="missions-to-create" appearance="none">
      <input
        class="input-field"
        type="number"
        matInput
        formControlName="numberMissions"
        min="1"
        max="25"
        required
        placeholder="1"
      />
      <mat-error *ngIf="numberMissions.invalid">invalid mission number</mat-error>
    </mat-form-field>
  </form>

  <div class="footer-line"></div>
  <div class="navigation-buttons">
    <button mat-raised-button (click)="close()" class="back" data-test="cancel-button">Cancel</button>
    <button mat-raised-button [disabled]="false" (click)="nextPage()" class="forward" data-test="next-button">
      Next
    </button>
  </div>
</div>

<ng-template #page2>
  <div class="create-mission" *ngIf="(pageNum$ | async) === 1; else page3">
    <div class="create-mission__header">
      <div>
        <h1>CREATE MISSION - STEP 2 OF 3</h1>
        <p>Copy and paste your headhauls first, then copy and paste your backhauls</p>
      </div>
      <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <form class="create-mission__form2" [formGroup]="mainForm">
      <div class="mission-row">
        <div class="bold small">{{ numberMissions.value }} missions created</div>
        <div>Headhaul Order Number</div>
        <div>Backhaul Order Number</div>
      </div>
      <div class="mission-container">
        <ng-container formArrayName="missions" *ngFor="let m of missions.controls; let i = index">
          <div class="mission-row" [formGroupName]="i">
            <label class="white small"> Mission #{{ i + 1 }} </label>
            <mat-form-field appearance="none">
              <input
                class="input-field"
                pasteData
                (onDataProcessed)="dataPasted($event, 0, i)"
                (input)="checkMissionsArray()"
                type="text"
                formControlName="headHaul"
                matInput
                required
              />
              <mat-error *ngIf="m.get('headHaul').errors && m.get('headHaul').getError('duplicate') === null"
                >invalid</mat-error
              >
              <mat-error *ngIf="m.get('headHaul').getError('duplicate') === true" data-test="duplicate-error"
                >duplicate</mat-error
              >
            </mat-form-field>
            <mat-form-field appearance="none">
              <input
                class="input-field"
                pasteData
                (onDataProcessed)="dataPasted($event, 1, i)"
                (input)="checkMissionsArray()"
                type="text"
                formControlName="backHaul"
                matInput
                required
              />
              <mat-error *ngIf="m.get('backHaul').errors && m.get('backHaul').getError('duplicate') === null"
                >invalid</mat-error
              >
              <mat-error *ngIf="m.get('backHaul').getError('duplicate') === true" data-test="duplicate-error"
                >duplicate</mat-error
              >
            </mat-form-field>
            <mat-icon class="close-icon" (click)="deleteMission(i)" data-test="delete-mission">close</mat-icon>
          </div>
        </ng-container>
      </div>
    </form>

    <div class="footer-line"></div>
    <div class="navigation-buttons">
      <button mat-raised-button (click)="close()" class="back" data-test="cancel-button">Cancel</button>
      <button mat-raised-button (click)="prevPage()" class="back" data-test="back-button">Back</button>
      <button mat-raised-button [disabled]="false" (click)="nextPage()" class="forward" data-test="next-button">
        Next
      </button>
    </div>
  </div>
</ng-template>

<ng-template #page3>
  <div class="create-mission" *ngIf="(pageNum$ | async) === 2">
    <div class="create-mission__header">
      <div>
        <h1>CREATE MISSION - STEP 3 OF 3</h1>
        <p>Confirm the missions that have been created</p>
      </div>
      <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <mat-error class="form-error" *ngIf="mainForm.get('invalidMissions')?.value > 0">
      <mat-icon [ngStyle]="{ marginRight: '10px', marginLeft: '0px' }">error</mat-icon>
      {{ mainForm.get('invalidMissions')?.value }} errors found, please go back and edit them or delete them.</mat-error
    >
    <!-- <mat-error
      class="form-error"
      *ngIf="mainForm.get('invalidMissions').value === 0 && mainForm.get('errors').value?.duplicate === true"
    >
      <mat-icon [ngStyle]="{ marginRight: '10px', marginLeft: '0px' }">error</mat-icon>
      Duplcate orders found, please go back and edit them or delete them.</mat-error
    > -->

    <form class="create-mission__form2" [formGroup]="mainForm">
      <div class="mission-row header">
        <div class="bold small">{{ numberMissions.value }} missions created</div>
        <div class="bold small">Mission Details - Headhaul Origin to Backhaul Origin</div>
      </div>
      <div class="mission-container">
        <ng-container formArrayName="missions" *ngFor="let m of missions.controls; let i = index">
          <div class="mission-row detailed" [formGroupName]="i">
            <label class="white small"> Mission #{{ i + 1 }} </label>
            <div
              [ngClass]="{
                'mission-card': true,
                invalid: m.value.headHaulValidation?.valid === false
              }"
            >
              <div class="lst-origin-arrow">
                <div class="lst-oa-background">
                  <div class="lst-oa-foreground">
                    <mat-icon>north</mat-icon>
                  </div>
                </div>
              </div>
              <div class="mission-card__data" data-test="headhaul-value">
                {{ m.value.headHaul || 'n/a' }}<br />
                <span class="white small">{{ m.value.origin || 'n/a' }}</span>
              </div>
            </div>
            <mat-icon>trending_flat</mat-icon>
            <div
              [ngClass]="{
                'mission-card': true,
                invalid: m.value.backHaulValidation?.valid === false
              }"
            >
              <div class="lst-destination-arrow">
                <div class="lst-da-background">
                  <div class="lst-da-foreground">
                    <mat-icon>south</mat-icon>
                  </div>
                </div>
              </div>
              <div class="mission-card__data" data-test="backhaul-value">
                {{ m.value.backHaul || 'n/a' }}<br /><span class="white small">{{ m.value.destination || 'n/a' }}</span>
              </div>
            </div>
            <div class="white small" data-test="mission-validation">
              <mat-spinner
                [diameter]="25"
                *ngIf="missionEntityService.validateMissionsManager.isLoading$ | async"
                mode="indeterminate"
              ></mat-spinner>
              {{ m.value | missionValidation }}
            </div>
            <mat-icon class="close-icon" (click)="deleteMission(i)" data-test="delete-mission">close</mat-icon>
          </div>
        </ng-container>
      </div>
    </form>

    <div class="footer-line"></div>
    <div class="navigation-buttons">
      <button mat-raised-button (click)="close()" class="back" data-test="cancel">Cancel</button>
      <button mat-raised-button (click)="prevPage()" class="back" data-test="back">Back</button>
      <button
        mat-raised-button
        [disabled]="mainForm.get('invalidMissions')?.value > 0 || mainForm.get('errors')?.value"
        (click)="createMissions()"
        class="forward"
        data-test="create-missions"
      >
        Create Mission(s)
      </button>
      <mat-spinner
        [ngStyle]="{ position: 'absolute', top: '5px', left: '-40px' }"
        [diameter]="30"
        *ngIf="missionEntityService.createMissionsManager.isLoading$ | async"
        mode="indeterminate"
      ></mat-spinner>
    </div>
  </div>
</ng-template>
