import { omit } from 'lodash';

export interface ZipLaneSearchParamData extends ZipLaneSearchParameters {
  originLocation?: string;
  destinationLocation?: string;
}

export class ZipLaneSearchParameters {
  originLat?: number[] | string[] | string;
  originLon?: number[] | string[] | string;
  originRadius?: number[];
  destinationLat?: number[] | string[] | string;
  destinationLon?: number[] | string[] | string;
  destinationRadius?: number[];
  customer?: string;
  firstAppointmentStart?: number;
  firstAppointmentEnd?: number;
  lastAppointmentStart?: number;
  lastAppointmentEnd?: number;
  originState?: string[];
  originZip?: string[];
  originZone?: string[];
  destinationState?: string[];
  destinationZip?: string[];
  destinationZone?: string[];
  lastSeenStart?: string;
  lastSeenEnd?: string;
  wonLanes?: boolean;
  constructor(props?: Partial<ZipLaneSearchParameters>) {
    if (props) {
      const propsWithOmissions = omit(props, ['originLocation', 'destinationLocation', 'appointmentSet']);
      Object.assign(this, propsWithOmissions);
    }
  }
}
