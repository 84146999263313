<div class="milestones" *ngrxLet="loadRoute$ as routeData">
  <div *ngIf="missionId && featureFlags[missionFeatureFlag]">
    <haulynx-load-overview-banner
      [currentView]="'trackingMilestones'"
      [missionId]="missionId"
      [isBackHaul]="isBackHaul"
      [isHeadHaul]="isHeadHaul"
      [backHaulLoad]="backHaul"
      [headHaulLoad]="headHaul"
    >
    </haulynx-load-overview-banner>
  </div>
  <app-divider-slider
    [leftTopTemplate]="leftContainer"
    [rightBottomTemplate]="rightContainer"
    [isVertical]="false"
    [limits]="dividerSliderLimits"
  >
    <ng-template #leftContainer>
      <div class="milestones__left">
        <app-milestone-header
          [milestones]="milestones"
          [load]="load"
          [showSpinner]="loadDataLoading$ | async"
          [user]="user"
          [totalDistance]="routeData?.loadMapDistance"
          [truckPosition]="truckPosition"
          [lastLocation]="loadRouteEntityService.currentLocation$ | async"
          [waypoints]="routeData?.loadWaypoints"
          [remainingDistance]="loadRouteEntityService.remainingDistance$ | async"
          [drivers]="commonEntities.graphQlDrivers.entities$ | async"
          (clickEvent)="onHeaderClickEvent($event)"
          (openMilestone)="openMilestone({ milestoneType: 'CHECKPOINT' })"
          (toggle)="toggleTrailers($event)"
        ></app-milestone-header>

        <app-milestone-content
          [user]="user"
          [currentUserType]="currentUserType"
          [milestones]="milestones"
          [load]="load"
          [collapseMilestone]="collapseMilestone | async"
          [badgeMilestones]="badgeMilestones"
          [savedCollapseMilestone]="savedCollapseMilestone"
          (clickEvent)="onContentClickEvent($event)"
        >
        </app-milestone-content>
      </div>
    </ng-template>

    <ng-template #rightContainer>
      <div class="milestones__right">
        <app-mapbox
          [data]="mapboxRoute"
          [zoom]="5"
          [milestones]="milestones"
          [trailerToggle]="trailerToggle"
          [trailerData]="trailers$ | async"
          [isDialogOpen]="isDialogOpen | async"
          (wayPointClickMilestone)="waypointclick($event)"
          (wayPointClickOpenMilestone)="waypointOpenMilestone($event)"
        >
        </app-mapbox>
      </div>
    </ng-template>
  </app-divider-slider>
</div>
