import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BroadcastService, LocalStoreService } from '@haulynx/services';
import { UserEntityService } from '@haulynx/store';
import { CachedItem } from 'ngforage';

// Used to refresh access token before it expires
const FIVE_MINUTES = 1000 * 60 * 5;

// Change this to false if light mode should be disabled
const LIGHT_MODE_ENABLED_GLOBALLY = false;

declare let gtag: (type: string, code: string, event: { page_path: string }) => void;

@Component({
  selector: 'haulynx-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private timer;

  constructor(
    private router: Router,
    private broadcastService: BroadcastService,
    private localStoreService: LocalStoreService,
    private userEntityService: UserEntityService
  ) {}

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);

        gtag('config', 'UA-172630924-1', {
          page_path: event.urlAfterRedirects,
        });
      }
    });

    this.localStoreService.get('dotSignup').then((data) => {
      if (data) {
        this.router.navigateByUrl('dotSignup/setLane');
      }
    });

    this.checkTokenExpiration();

    this.localStoreService.changes$.subscribe((changes) => {
      if (!changes) return;

      if (changes.key === 'token' && changes.value) {
        this.checkTokenExpiration();
      }
    });

    this.userEntityService.refreshAccessTokenManager.onResponse$.subscribe((response) => {
      // We want to make sure that the loop continues to check for expired tokens
      if (!response.accessToken) {
        return setTimeout(() => {
          this.checkTokenExpiration();
        }, 10000);
      }

      // Different tabs will need to know about the updated token and update the user entity services token
      this.broadcastService.publish({ type: 'refresh-token', payload: response.accessToken });
    });

    const lightModeMatchMedia = window.matchMedia('(prefers-color-scheme: light)');
    this.setLightModeDarkMode(lightModeMatchMedia.matches);
    lightModeMatchMedia.addEventListener('change', (e) => {
      this.setLightModeDarkMode(e.matches);
    });

    // Also allow for a way for localstorage to change light mode and dark mode
    window.addEventListener('storage', (event: StorageEvent) => {
      if (event.key === 'enableLightMode') {
        this.setLightModeDarkMode(lightModeMatchMedia.matches);
      }
    });
  }

  /**
   * Checks the token in indexedDB and when it expires.
   * Prior to the token expiring, refresh the token and store it in our state.
   */
  private checkTokenExpiration() {
    if (this.timer) clearTimeout(this.timer);

    this.localStoreService.getCachedItem<string>('token').then((cachedItem: CachedItem<string>) => {
      if (!cachedItem) return;

      const expires = cachedItem.expiresIn;
      const fiveBeforeExpires = expires - FIVE_MINUTES;

      this.timer = setTimeout(() => {
        this.userEntityService.refreshAccessTokenManager.dispatch();
      }, fiveBeforeExpires);
    });
  }

  private async setLightModeDarkMode(isLightMode: boolean) {
    const storage = window.localStorage.getItem('enableLightMode');
    const lightModeEnabled = storage ? (storage === 'true' ? true : false) : true;

    const theme = isLightMode
      ? LIGHT_MODE_ENABLED_GLOBALLY && lightModeEnabled
        ? 'light'
        : null // Light Mode
      : 'dark'; // Dark Mode

    this.broadcastService.publishLocally({ type: 'theme-change', payload: theme });
    document.documentElement.setAttribute('data-theme', theme);
  }
}
