import { Device } from '../generic';

export class Location {
  gpsLat: number;
  gpsLon: number;
  approximateAddress: string;
  date: string;
  driver: string;
  driverName: string;
  driverStatus: string;
  vehicle: Device;
  speed: number;
  unitId: string;
  automaticEntry = true;
  city: string;
  state: string;
  cityAndState: string;
  appVersion: string;
  type: 'tablet' | 'phone' | 'mdi' | 'TELEMATICS';
  timestamp: number;

  constructor(address: string, lat: number, lon: number) {
    this.approximateAddress = address;
    this.gpsLat = lat;
    this.gpsLon = lon;
  }
}

export class LocationInput {
  gpsLat: number;
  gpsLon: number;
  approximateAddress: string;
  date: string;
  driver: string;
  driverName: string;
  driverStatus: string;
  speed: number;
  unitId: string;
  automaticEntry: boolean;
  city: string;
  state: string;
  cityAndState: string;
  appVersion: string;
  type: string;
  constructor(props?: Partial<Location>) {
    Object.assign(this, props);
  }
}
