import { ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, Optional, Self } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControlName, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { MatFormFieldControl } from '@angular/material/form-field';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { takeUntil } from 'rxjs/operators';
import { isNumber } from 'lodash';
import { MomentService } from '@haulynx/services';
import { aliveWhile } from '@haulynx/utils';

@Component({
  selector: 'timestamp-input',
  templateUrl: './timestamp-input.component.html',
  styleUrls: ['./timestamp-input.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: TimestampInputComponent,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimestampInputComponent implements OnDestroy, ControlValueAccessor, MatFormFieldControl<any> {
  private _value = null;
  private _placeholder: string = null;
  private _required = false;
  private _disabled = false;
  private _focused = false;
  private _errorState = false;

  @Input() label: string;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get required() {
    return this._required;
  }

  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  @Input()
  set placeholder(newValue: string) {
    this._placeholder = newValue;
    this.stateChanges.next();
  }

  get placeholder() {
    return this._placeholder;
  }

  set value(time: number | string | null) {
    if (isNumber(time)) {
      const mom = this.momentService.getMoment(time);
      this._value = mom.format('HH:mm');
    } else {
      this._value = time;
    }
  }

  get value() {
    return this._value;
  }

  get empty() {
    return !this.value;
  }

  set focused(newValue: boolean) {
    this._focused = newValue;
  }

  get focused(): boolean {
    return this._focused;
  }

  get errorState(): boolean {
    return !!this._errorState;
  }

  set errorState(newValue) {
    this._errorState = newValue;
  }

  readonly id: string;

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  readonly stateChanges: Subject<void> = new Subject<void>();
  public timestampForm: FormGroup;
  private propagateChanges: Function;
  readonly autofilled: boolean;
  readonly controlType: string;
  private alive = aliveWhile();

  constructor(
    @Optional() @Self() public ngControl: FormControlName,
    private fb: FormBuilder,
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
    private momentService: MomentService
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    this.timestampForm = this.initForm();

    this.fm.monitor(this.elRef.nativeElement, true).subscribe((origin) => {
      this.focused = !!origin;
      this.stateChanges.next();
    });

    this.timestampForm
      .get('time')
      .valueChanges.pipe(takeUntil(this.alive))
      .subscribe((time) => {
        this.value = time ? time : null;

        if (this.propagateChanges) {
          this.propagateChanges(this.value);
        }

        this.errorState = !!this.ngControl.errors;
        this.stateChanges.next();
      });
  }

  writeValue(value: number): void {
    this.value = value;
    this.timestampForm.patchValue({ time: this.value, date: value });
  }

  registerOnChange(fn: Function): void {
    this.propagateChanges = fn;
  }

  registerOnTouched(fn: Function): void {}

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.timestampForm.disable() : this.timestampForm.enable();
  }

  private initForm() {
    return this.fb.group({
      time: [null],
      date: [null],
    });
  }

  onContainerClick(event: MouseEvent): void {}

  setDescribedByIds(ids: string[]): void {}

  ngOnDestroy() {
    this.alive.destroy();
  }
}
