import { Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { MatMenuTrigger } from '@angular/material/menu';
import { RmisProps } from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'haulynx-header-endbuttons-account-setup',
  templateUrl: './header-endbuttons-account-setup.component.html',
  styleUrls: ['./header-endbuttons-account-setup.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderEndbuttonsAccountSetupComponent {
  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
  @Input() rmisProps: RmisProps;

  @Output() complete = new EventEmitter();
  @Output() continueRmis = new EventEmitter();

  private alive = aliveWhile();

  constructor(private mediaObserver: MediaObserver) {}

  ngAfterViewInit() {
    this.mediaObserver
      .asObservable()
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.menuTrigger?.closeMenu();
      });
  }

  ngOnDestroy() {
    this.alive.destroy();
  }

  clickCompleted() {
    this.complete.emit();
  }

  closeMenu() {
    this.menuTrigger.closeMenu();
  }

  continueToRmis(): void {
    this.continueRmis.emit();
  }
}
