<ng-container *ngrxLet="state$ as state">
  <div class="carrier-toolbar" *ngIf="!state?.isOpen; else toolbar">
    <button
      [ngClass]="{ active: (loads$ | async)?.length, 'carrier-toolbar__trigger': true }"
      *appFeatures="featureFlag"
      mat-button
      (click)="toggleCarrierToolbar()"
      data-test="open-carrier-toolbar"
    >
      <mat-icon>work_outline</mat-icon>
      Open Carrier Toolbar
    </button>
  </div>

  <ng-template #toolbar>
    <div class="carrier-toolbar__panel" [@enterAnimation] cdkScrollable>
      <div class="carrier-toolbar__header">
        <div class="carrier-toolbar__subheader"><mat-icon>work_outline</mat-icon><span> CARRIER TOOLBAR</span></div>
        <button mat-icon-button class="carrier-toolbar__close" (click)="toggleCarrierToolbar()">
          <mat-icon>close</mat-icon>
        </button>
      </div>

      <div *ngIf="state?.detailsPage !== carrierToolbarDetailsPage.CARRIER_PROFILE; else carrierProfile">
        <mat-tab-group
          class="toolbar"
          [selectedIndex]="state?.currentTab"
          (selectedTabChange)="tabChange($event)"
          [disablePagination]="true"
          *ngrxLet="selectedLoad$ as selectedLoad"
        >
          <mat-tab label="Recommendations" data-test="recommendations-tab"></mat-tab>
          <mat-tab label="Carrier Search"></mat-tab>
          <mat-tab label="Owned Carriers"></mat-tab>
        </mat-tab-group>
        <div>
          <haulynx-carrier-toolbar-card-group
            [currentTab]="state?.currentTab"
            [loads]="loads$ | async"
            [selectedLoad]="selectedLoad"
            [user]="user"
            [featureFlags]="featureFlags"
            [loadOptions]="loadOptions$ | async"
            [showOptions]="showOptions"
            [latestBid]="latestBid$ | async"
            [search]="state?.data?.carrierSearchQuery"
            [bidCreateLoading]="carrierLoadsServiceEntityService.placeBidManager.isLoading$ | async"
            (openCarrierProfile)="onOpenCarrierProfile($event)"
            (carrierAction)="onCarrierActionClicked($event)"
            (submitBidForm)="submitBid($event)"
            (chosenCarrier)="newCarrier($event)"
          ></haulynx-carrier-toolbar-card-group>
        </div>
      </div>
    </div>

    <ng-template #carrierProfile>
      <haulynx-carrier-toolbar-carrier-profile
        [isLoading]="carrierEntityService.getMergedCarrier.isLoading$ | async"
        [returnText]="state?.currentTab === 1 ? 'Carrier Search' : 'All Recommendations'"
        [carrier]="carrierEntityService.getMergedCarrier.data$ | async"
        [loadsMetaData]="loadEntityService.getLoadsMetaDataManager.searchResults$ | async"
        (finishViewing)="onFinishViewingProfile($event)"
        (carrierProfileTabChange)="onCarrierProfileTabChange($event)"
        (carrierProfileToggleDetails)="onCarrierProfileToggleDetails($event)"
      ></haulynx-carrier-toolbar-carrier-profile>
    </ng-template>
  </ng-template>
</ng-container>
