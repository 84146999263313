import { NgModule } from '@angular/core';
import { Environment } from '@haulynx/types';
import { EntityDataModule, EntityServices } from '@ngrx/data';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { ActionReducer, MetaReducer, META_REDUCERS, StoreModule } from '@ngrx/store';
import { INITIAL_OPTIONS, StoreDevtoolsConfig, StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AsyncEntityUserStoreModule } from '../../async-entity-models/user';
import { AppSettingsAnalyticsEffects } from '../app-settings-analytics.effects';
import { AppSettingsEffects } from '../app-settings.effects';
import { AppState, APP_REDUCERS_TOKEN, getAppReducersFactory } from '../app.reducers';
import { entityConfig } from './app-entity-metadata';
import { AppEntityServices } from './app-entity-services';

export function metaReducerFactory(environment: Environment): MetaReducer<AppState> {
  return (reducer: ActionReducer<any>) => (state, action) => {
    const result = reducer(state, action);
    if (environment.production) return result;

    console.groupCollapsed(action.type);
    console.log('action', action);
    console.log('state', result);
    console.groupEnd();

    if (action.type === 'SET_ROOT_STATE') {
      return action['payload'];
    }

    return result;
  };
}

export function storeDevToolsModuleFactory(environment: Environment): StoreDevtoolsConfig {
  return {
    name: 'Haulynx Store Devtools',
    maxAge: 5,
    logOnly: environment.production,
  };
}

@NgModule({
  imports: [
    StoreRouterConnectingModule.forRoot(),
    EffectsModule.forRoot([AppSettingsEffects, AppSettingsAnalyticsEffects]),
    EntityDataModule.forRoot(entityConfig),
    StoreModule.forRoot(APP_REDUCERS_TOKEN),
    StoreDevtoolsModule.instrument(), // Figure out how to remove this on production builds
    AsyncEntityUserStoreModule,
  ],
  providers: [
    AppEntityServices,
    {
      provide: APP_REDUCERS_TOKEN,
      useFactory: getAppReducersFactory,
    },
    {
      provide: META_REDUCERS,
      deps: [Environment],
      useFactory: metaReducerFactory,
      multi: true,
    },
    { provide: INITIAL_OPTIONS, useFactory: storeDevToolsModuleFactory, deps: [Environment] },
    { provide: EntityServices, useExisting: AppEntityServices },
  ],
})
export class AppStoreModule {}
