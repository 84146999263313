export enum Role {
  prospective_driver = 'prospective_driver',
  driver_lite = 'driver_lite',
  owner = 'owner',
  owner_driver = 'owner_driver',
  carrier_admin = 'carrier_admin',
  carrier_admin_driver = 'carrier_admin_driver',
  dispatcher = 'dispatcher',
  driver_with_booking_authority = 'driver_with_booking_authority',
  driver = 'driver',
  dispatcher_1099 = '1099_dispatcher',
  safety = 'safety',
  broker = 'broker',
  broker_admin = 'broker_admin',
  system_admin = 'system_admin',
}
