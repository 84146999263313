import gql from 'graphql-tag';

export const BookLoadsInMission = gql`
  mutation BookLoadsInMission(
    $missionId: StrictId!
    $dot: StrictId!
    $brokerId: StrictId!
    $bookingType: BookingType
    $loadDetailsInput: [LoadDetailInput]
  ) {
    bookLoadsInMission(
      missionId: $missionId
      dot: $dot
      brokerId: $brokerId
      bookingType: $bookingType
      loadDetailsInput: $loadDetailsInput
    ) {
      missionId
      bookedLoadResponses {
        loadId
        success
        message
      }
    }
  }
`;
