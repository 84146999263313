import { ISearchFilter, TextFilterType } from '../search-filter';
import { ISearchFilterType } from '../search-filter.types';

export class TableViewFilter implements ISearchFilter {
  name = 'Table View';
  type: ISearchFilterType = ISearchFilterType.TEXT;
  keys: TextFilterType;
  description = 'Table view for lane history';

  constructor(defaultValues?: TextFilterType) {
    const options = Object.values(LaneHistoryTableView).map((value) => {
      return {
        key: value,
        value: value,
      };
    });
    this.keys = new TextFilterType({
      value: '',
      optionsDataIndex: options,
      textFormName: 'includeBids',
      ...defaultValues,
    });
  }
}

export enum LaneHistoryTableView {
  LOAD = 'Loads',
  LOADS_AND_BIDS = 'Loads & Bids',
}
