import { LoadServiceSearchParameters } from '../../loads-service/loads-service-search-parameters';
import { BookStatus } from './load';

export interface LoadsServiceHistogramBucket {
  key: number;
  doc_count: number;
}

export interface LoadsServiceHistogramBuckets {
  buckets: LoadsServiceHistogramBucket[];
}

export interface LoadsServiceAggregationsValue {
  value: number;
}
export interface LoadsServiceAggregations {
  prices?: LoadsServiceHistogramBuckets;
  loadedMiles?: LoadsServiceHistogramBuckets;
  bookedAt?: LoadsServiceAggregationsValue;
}

export interface LoadsServiceSearchMetaData {
  totalLoads?: number;
  aggregations?: LoadsServiceAggregations;
}

export enum LoadsServiceHistogramField {
  loadedMiles = 'loadedMiles',
  price = 'price',
}
