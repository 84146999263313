import gql from 'graphql-tag';

export const COMPLETE_LOAD_DETAILS_FRAGMENT = gql`
  fragment completeLoadDetails on LoadsServiceLoad {
    mission {
      id
      status
      loads {
        id
      }
    }
    id
    carrierCanBookNow
    createdBy
    bookStatus
    trackingType
    truck {
      id
      unitId
      thirdPartyTracking {
        trackingType
        isSupported
      }
    }
    loadStatus
    brokerOfferForCarrier {
      brokerId
      price
      timestampOffered
    }
    broker {
      id
    }
    carrier {
      id
      dot
    }
    drivers {
      id
      name
      phone
      priority
    }
    loadStatus
    locations {
      summaryAddress
      city
      carrierArrival
      carrierDeparture
      completed
      state
      timezone
      appointmentSet
      appointmentStart
      appointmentEnd
      carrierArrival
      carrierDeparture
      locationType
      geometry {
        coordinates
      }
      customer {
        name
        number
        company
        shippingHours {
          dayOfWeek
          hourType
          openTime
          closeTime
        }
        receivingHours {
          dayOfWeek
          hourType
          openTime
          closeTime
        }
        customerAttributes {
          canPickUpEarly
          canDeliverEarly
          dropYard
          canBreakAtFacility
          hasFacilities
        }
      }
    }
    paymentDetails {
      price
      ratePerMile
      distanceMiles
      maxBuy
    }
    providerDetails {
      regionalManagerEmail
      regionalManagerPhone
      alternateIds {
        identifierType
        identifierValue
      }
      billTo
      billToName
      equipmentType
      weightLbs
      commodityDescription
      quantity
      packingType
    }
    restrictions {
      type
      value
    }
    notes {
      text
    }
    carrierBid {
      id
      activeCounterOffer {
        id
        price
        status
        notes
      }
      brokerId
      finalStateFrom
      status
      price
      notes
      bidHistory {
        event
        createdAt
        createdBy {
          id
        }
        id
        bidId
        data {
          price
          status
          notes
        }
      }
    }
    offers {
      offeredBy
      price
      createdAt
      lastUpdatedAt
    }
    trailers {
      id
      trailerNumber
      trailerOwnerType
    }
  }
`;
