import { LoadsServiceLoadStatus } from '../../loads-service/loads-service-load';
import { LoadServiceSearchParamData } from '../../loads-service/loads-service-search-parameters';
import { User } from '../generic/user';
import { BookStatus } from '../load';
import { CarrierSearchListRoute } from './carrier-search-list-routes';

export enum CarrierSearchDispatchType {
  getLoadMatchesByCarrierDot = 'getLoadMatchesByCarrierDot',
  getBidsAndOffers = 'getBidsAndOffers',
  getLoadsServiceLoadsByCarrierDot = 'getLoadsServiceLoadsByCarrierDot',
  getUSXLoads = 'getUSXLoads',
}

export enum CarrierSearchListSection {
  getFreight = 'Get Freight',
  activeLoads = 'Active Loads',
  completedLoads = 'Completed Loads',
  myLoads = 'My Loads',
  none = 'None',
}

export interface CarrierSearchListTypeBase {
  id: CarrierSearchListRoute;
  name: string;
  dispatchType?: CarrierSearchDispatchType;
  listSection: CarrierSearchListSection;

  count?: number;
  icon?: string;
  route?: string;
  svgIcon?: string;
  selected?: boolean;

  serialize?: (context: { state: LoadServiceSearchParamData; user: User }) => Partial<LoadServiceSearchParamData>;
}

export const carrierSearchListTypeAllAvailable: CarrierSearchListTypeBase = {
  id: CarrierSearchListRoute.AVAILABLE,
  name: 'All Available Loads',
  dispatchType: CarrierSearchDispatchType.getUSXLoads,
  listSection: CarrierSearchListSection.getFreight,
  svgIcon: 'box-closed',

  serialize: (context: { state: LoadServiceSearchParamData; user: User }): Partial<LoadServiceSearchParamData> => ({
    bookStatus: [BookStatus.BOOKABLE, BookStatus.VIEWABLE],
  }),
};

export const carrierSearchListTypeTestLoads: CarrierSearchListTypeBase = {
  id: CarrierSearchListRoute.TEST_LOADS,
  name: 'Test Loads',
  dispatchType: CarrierSearchDispatchType.getUSXLoads,
  listSection: CarrierSearchListSection.getFreight,
  icon: 'bug_report',

  serialize: (context: { state: LoadServiceSearchParamData; user: User }): Partial<LoadServiceSearchParamData> => ({
    billTo: ['haulynx test'],
    bookStatus: [BookStatus.BOOKABLE, BookStatus.VIEWABLE],
    showExcluded: true,
    showTestLoads: true,
  }),
};

export const carrierSearchListTypeRecommendations: CarrierSearchListTypeBase = {
  id: CarrierSearchListRoute.RECOMMENDED,
  name: 'Recommendations',
  dispatchType: CarrierSearchDispatchType.getLoadMatchesByCarrierDot,
  listSection: CarrierSearchListSection.getFreight,
  svgIcon: 'sparkle',

  serialize: (context: { state: LoadServiceSearchParamData; user: User }): Partial<LoadServiceSearchParamData> => ({}),
};

export const carrierSearchListTypeOffers: CarrierSearchListTypeBase = {
  id: CarrierSearchListRoute.BIDS_AND_OFFERS,
  name: 'Bids / Offers',
  dispatchType: CarrierSearchDispatchType.getBidsAndOffers,
  listSection: CarrierSearchListSection.getFreight,
  svgIcon: 'offer',

  serialize: (context: { state: LoadServiceSearchParamData; user: User }): Partial<LoadServiceSearchParamData> => ({}),
};

export const carrierSearchListTypeMVPActiveLoads: CarrierSearchListTypeBase = {
  id: CarrierSearchListRoute.MVP_ACTIVE_LOADS,
  name: 'All My Loads',
  listSection: CarrierSearchListSection.myLoads,
  route: 'loads/active',
  svgIcon: 'box-opened',
};

export const carrierSearchListTypeActive: CarrierSearchListTypeBase = {
  id: CarrierSearchListRoute.ACTIVE,
  name: 'All Active Loads',
  dispatchType: CarrierSearchDispatchType.getLoadsServiceLoadsByCarrierDot,
  listSection: CarrierSearchListSection.activeLoads,
  svgIcon: 'box-opened',

  serialize: (context: { state: LoadServiceSearchParamData; user: User }): Partial<LoadServiceSearchParamData> => ({
    bookStatus: [BookStatus.BOOKED],
    loadStatus: [
      LoadsServiceLoadStatus.UNASSIGNED,
      LoadsServiceLoadStatus.ASSIGNED,
      LoadsServiceLoadStatus.PICKED_UP,
      LoadsServiceLoadStatus.AT_SHIPPER,
      LoadsServiceLoadStatus.AT_RECEIVER,
      LoadsServiceLoadStatus.DISPATCHED,
    ],
  }),
};

export const carrierSearchListTypeUnassigned: CarrierSearchListTypeBase = {
  id: CarrierSearchListRoute.UNASSIGNED,
  name: 'Unassigned',
  dispatchType: CarrierSearchDispatchType.getLoadsServiceLoadsByCarrierDot,
  listSection: CarrierSearchListSection.activeLoads,
  svgIcon: 'pending',

  serialize: (context: { state: LoadServiceSearchParamData; user: User }): Partial<LoadServiceSearchParamData> => ({
    bookStatus: [BookStatus.BOOKED],
    loadStatus: [LoadsServiceLoadStatus.UNASSIGNED],
  }),
};

export const carrierSearchListTypeAssigned: CarrierSearchListTypeBase = {
  id: CarrierSearchListRoute.ASSIGNED,
  name: 'Assigned',
  dispatchType: CarrierSearchDispatchType.getLoadsServiceLoadsByCarrierDot,
  listSection: CarrierSearchListSection.activeLoads,
  svgIcon: 'assigned',

  serialize: (context: { state: LoadServiceSearchParamData; user: User }): Partial<LoadServiceSearchParamData> => ({
    bookStatus: [BookStatus.BOOKED],
    loadStatus: [LoadsServiceLoadStatus.ASSIGNED],
  }),
};

export const carrierSearchListTypeInTransit: CarrierSearchListTypeBase = {
  id: CarrierSearchListRoute.IN_TRANSIT,
  name: 'In Transit',
  dispatchType: CarrierSearchDispatchType.getLoadsServiceLoadsByCarrierDot,
  listSection: CarrierSearchListSection.activeLoads,
  svgIcon: 'in-transit',

  serialize: (context: { state: LoadServiceSearchParamData; user: User }): Partial<LoadServiceSearchParamData> => ({
    bookStatus: [BookStatus.BOOKED],
    loadStatus: [
      LoadsServiceLoadStatus.PICKED_UP,
      LoadsServiceLoadStatus.AT_SHIPPER,
      LoadsServiceLoadStatus.AT_RECEIVER,
      LoadsServiceLoadStatus.DISPATCHED,
    ],
  }),
};

export const carrierSearchListTypeDelivered: CarrierSearchListTypeBase = {
  id: CarrierSearchListRoute.DELIVERED,
  name: 'Delivered',
  dispatchType: CarrierSearchDispatchType.getLoadsServiceLoadsByCarrierDot,
  listSection: CarrierSearchListSection.activeLoads,
  icon: 'task_alt',

  serialize: (context: { state: LoadServiceSearchParamData; user: User }): Partial<LoadServiceSearchParamData> => ({
    bookStatus: [BookStatus.BOOKED],
    loadStatus: [LoadsServiceLoadStatus.DELIVERED],
  }),
};

export const carrierSearchListTypeCompleted: CarrierSearchListTypeBase = {
  id: CarrierSearchListRoute.COMPLETED,
  name: 'All Completed Loads',
  dispatchType: CarrierSearchDispatchType.getLoadsServiceLoadsByCarrierDot,
  listSection: CarrierSearchListSection.completedLoads,
  svgIcon: 'finalled',

  serialize: (context: { state: LoadServiceSearchParamData; user: User }): Partial<LoadServiceSearchParamData> => ({
    bookStatus: [BookStatus.BOOKED],
    loadStatus: [LoadsServiceLoadStatus.DELIVERED, LoadsServiceLoadStatus.FINALLED],
  }),
};

export type CarrierSearchListMap = {
  [key in CarrierSearchListRoute]?: CarrierSearchListTypeBase;
};

export const allCarrierSearchListTypes = [
  carrierSearchListTypeAllAvailable,
  carrierSearchListTypeTestLoads,
  carrierSearchListTypeRecommendations,
  carrierSearchListTypeOffers,
  carrierSearchListTypeMVPActiveLoads,
  carrierSearchListTypeActive,
  carrierSearchListTypeUnassigned,
  carrierSearchListTypeAssigned,
  carrierSearchListTypeInTransit,
  carrierSearchListTypeDelivered,
  carrierSearchListTypeCompleted,
];
