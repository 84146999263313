import { Injectable } from '@angular/core';
import { LaneHistoryService } from '@haulynx/services';
import { AsyncSearchManager, LaneHistory, LaneHistoryRow, LaneHistorySearchParameters } from '@haulynx/types';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppState } from '../../main-store/app.reducers';
import { AsyncEntityBase } from '../../main-store/async-entity/async-entity-base.class';
import { laneHistoryNamespace } from '../namespace';

@Injectable({ providedIn: 'root' })
export class LaneHistoryEntityService extends AsyncEntityBase<LaneHistory> {
  getLaneHistoriesManager: AsyncSearchManager<
    LaneHistoryRow,
    { searchParameters: Partial<LaneHistorySearchParameters>; includeBids: boolean }
  >;
  laneHistory$ = new BehaviorSubject<LaneHistory>(null);

  constructor(
    protected actions$: Actions,
    protected store: Store<AppState>,
    private laneHistoryService: LaneHistoryService
  ) {
    super(actions$, store, laneHistoryNamespace);

    this.getLaneHistoriesManager = this.createAsyncSearchQuery('get lane history', (payload) => {
      return this.laneHistoryService
        .getLaneHistory(payload.query.searchParameters, payload.query.includeBids, payload.pageAndSort)
        .pipe(
          map((data) => {
            this.laneHistory$.next(data.laneHistoryData);
            return data?.laneHistoryRows;
          })
        );
    });
  }
}
