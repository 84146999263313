<div class="carrier-info-container">
  <form [formGroup]="form" class="form">
    <mat-form-field class="app-dark-field dark" appearance="fill" floatLabel="always">
      <label id="label"> Order </label>
      <input
        class="form-control"
        matInput
        formControlName="order"
        placeholder="order"
        (change)="change(form.value)"
        readonly
      />
    </mat-form-field>
    <mat-form-field class="app-dark-field" appearance="fill" floatLabel="always">
      <label id="label">Broker ID</label>
      <app-drop-down
        class="form-control"
        [placeholder]="'Select Broker'"
        formControlName="brokerId"
        [data]="brokers"
        (selected)="change(form.value)"
      >
      </app-drop-down>
    </mat-form-field>
  </form>
</div>
