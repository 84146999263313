<div class="create-bid-dialog-container">
  <app-add-bid
    #addBidComponent
    [load]="data.load"
    [carrier]="carrier"
    [userIsCarrier]="true"
    [isLoading]="loadFeedModel.isLoadingBidCreate$ | async"
    [disableBids]="false"
    [isSorterVisible]="false"
    [priceMin]="minPrice"
    [priceMax]="maxPrice"
    [disableCreate]="!acceptedTerms"
    (createBid)="onCreateBid($event)"
  >
  </app-add-bid>

  <div class="acceptance" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
    <mat-checkbox [(ngModel)]="acceptedTerms" data-test="agree-to-terms"
      >I agree to accept this load if my bid wins.</mat-checkbox
    >
  </div>
</div>
