import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ISearchFilter } from '@haulynx/types';

@Component({
  selector: 'app-search-more-info-dialog',
  templateUrl: './search-more-info-dialog.component.html',
  styleUrls: ['./search-more-info-dialog.component.scss'],
})
export class SearchMoreInfoDialogComponent implements OnInit {
  filters: ISearchFilter[];

  constructor(
    public dialogRef: MatDialogRef<SearchMoreInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      filters: ISearchFilter[];
    }
  ) {}

  ngOnInit(): void {
    this.filters = this.data.filters;
  }
}
