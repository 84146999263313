<div class="row">
  <div class="col-lg-12">
    <h2 matDialogTitle>Send Invite to Haulynx Network</h2>
    <mat-dialog-content>
      <div class="row">
        <mat-form-field class="col-lg-12">
          <input matInput placeholder="Email Address" [(ngModel)]="email" />
        </mat-form-field>
      </div>

      <div class="row">
        <mat-form-field class="col-lg-12">
          <input matInput placeholder="Phone Number" [(ngModel)]="phone" />
        </mat-form-field>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-button color="primary" (click)="send()">Send</button>
      <button mat-button [matDialogClose]="">Cancel</button>
    </mat-dialog-actions>
  </div>
</div>
