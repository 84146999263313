<app-tabs
  [tabs]="loadActiveTabsModel.tabs$ | async"
  [selected]="loadActiveTabsModel.tabsSelected$ | async"
  (onSelect)="goTo($event)"
  (onRemove)="removeTab($event)"
>
</app-tabs>
<div class="load_active_container">
  <div class="load_detail_container">
    <ng-container *ngIf="(loadDetailsIsLoadedAndEmpty$ | async) === false; else isLoadedOrEmpty">
      <div class="active-map-source">
        <app-map-routes
          [padding]="50"
          [sources]="route"
          [dark]="true"
          class="load-map-expand"
          [showTruckRoute]="true"
          [zoom]="3"
          [maxZoom]="
            selectedLoad?.bookStatus && selectedLoad?.bookStatus !== 'booked' && selectedLoad?.bookStatus !== 'pause'
              ? 3
              : null
          "
          [isLoading]="loadDetailsModel.isLoadingRoutes$ | async"
        >
        </app-map-routes>
      </div>
      <div *ngIf="(appModel.isValidCarrier$ | async) || !loadEntitiesIsEmpty; else noActiveLoads">
        <app-load-main-info
          data-test="app-load-main-info"
          [load]="loadEntityService.getLoadByIdManager.getEntityById(selectedLoad?.id) | async"
          [user]="appModel.user$ | async"
          [showDetailsButton]="(showSearchContainer$ | async) && showSelectedLoadDetailsButton"
          [disableDetailsButton]="
            (loadEntityService.getLoadByIdManager.getEntityById(selectedLoad?.id) | async)?.id === exampleLoad.id
          "
          [showNotesButton]="loadActiveModel.showNotesButton$ | async"
          [isLoading]="loadEntityService.getLoadByIdManager.isLoadingEntities$ | async"
          (onDetails)="goToDetails($event)"
          (showSpecialNote)="onShowSpecialNote(selectedLoad, $event)"
          (showFacilityInfo)="onShowFacilityInfo($event)"
          [hideFields]="hideFields"
          class="load_detail"
        >
        </app-load-main-info>
      </div>
    </ng-container>

    <ng-template #isLoadedOrEmpty>
      <ng-container *ngIf="loadEntityService.getLoadByIdManager.isLoadingEntities$ | async; else noLoads">
        <app-spinner [active]="true"></app-spinner>
      </ng-container>
    </ng-template>

    <ng-template #noLoads>
      <span data-test="no-loads-text">There are no loads to display.</span>
    </ng-template>

    <ng-template #noActiveLoads>
      <span data-test="no-active-loads-text">No active loads. Please create or book a load in the platform.</span>
    </ng-template>
  </div>

  <app-load-active-search
    data-test="app-load-active-search"
    *ngIf="showSearchContainer$ | async"
    class="load_active_search"
    [selectedLoadId]="selectedLoad?.id"
    (onSelect)="selectLoad($event)"
    [isLoading]="isSearching$ | async"
    [isBroker]="isBroker"
    [entitiesAssigned]="assignedEntities$ | async"
    [entitiesLoadOffers]="loadOffers$ | async"
    [entitiesInTransit]="inTransitEntities$ | async"
    [entitiesUnassigned]="unassignedEntities$ | async"
    [displayLoadOffers]="appModel.isValidCarrier$ | async"
    (onBookSuccess)="bookSuccess()"
    (offerDeleteSuccessEvent)="offerDeleteSuccess($event)"
  >
  </app-load-active-search>

  <router-outlet></router-outlet>
</div>
