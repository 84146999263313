<div class="virtual-table-sort-dialog" fxLayout="row" fxLayoutAlign="center center">
  <div class="sort-dialog_left" fxLayout="column">
    <div class="sort-dialog_title">Sort By</div>
    <div class="sort-dialog_options" fxLayout="column">
      <mat-checkbox *ngFor="let option of sortTypes" [checked]="option?.checked" (change)="selectedSortOption(option)"
        >{{ option?.type }}
      </mat-checkbox>
    </div>
  </div>
  <mat-divider vertical class="sort-dialog_divider"></mat-divider>
  <div class="sort-dialog_right" fxLayout="column">
    <div class="sort-dialog_title">Sort Order</div>
    <div class="sort-dialog_options" fxLayout="column" fxLayoutAlign=" start">
      <mat-checkbox
        *ngFor="let direction of sortDirectionTypes"
        [checked]="direction?.checked"
        (change)="selectedDirectionOption(direction)"
        >{{ direction?.direction }}
      </mat-checkbox>
    </div>
  </div>
</div>
