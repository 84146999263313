<div class="load-search-timeline-container">
  <div class="lst-origin">
    <div class="lst-origin-arrow">
      <div class="lst-oa-background">
        <div class="lst-oa-foreground">
          <mat-icon>north</mat-icon>
        </div>
      </div>
    </div>
    <div class="lst-origin-information" *ngIf="displayLoad?.locations | nth: 0 as pickup">
      <div class="lst-origin-location" [ngStyle]="{ 'font-size': fontSize }" data-test="load-pickup-address">{{ pickup.address }}</div>
      <div
        class="lst-origin-datetime"
        [matTooltip]="pickup.appointmentSet ? 'This appointment time is set' : ''"
        fxLayout="row"
        fxLayoutAlign="start start"
        fxLayoutGap="2px"
      >
        <ng-container *ngIf="pickup.appointmentSet">
          <mat-icon class="appointment">check</mat-icon>
        </ng-container>
        <span
          class="appointment-time"
          [innerHTML]="displayLoad | loadTimeRange: { stopIndex: 0, addBreak: false, date: 'MM/dd' }"
          data-test="load-pickup-time"
        ></span>
      </div>
    </div>
  </div>
  <div class="lst-destination">
    <div class="lst-destination-arrow">
      <div class="lst-da-background">
        <div class="lst-da-foreground">
          <mat-icon>south</mat-icon>
        </div>
      </div>
    </div>
    <div class="lst-destination-information" *ngIf="displayLoad?.locations | nth: -1 as dropoff">
      <div class="lst-destination-location" [ngStyle]="{ 'font-size': fontSize }" data-test="load-dropoff-address">{{ dropoff.address }}</div>
      <div
        class="lst-destination-datetime"
        [matTooltip]="dropoff.appointmentSet ? 'This appointment time is set' : ''"
        fxLayout="row"
        fxLayoutAlign="start start"
        fxLayoutGap="2px"
      >
        <ng-container *ngIf="dropoff.appointmentSet">
          <mat-icon class="appointment">check</mat-icon>
        </ng-container>
        <span
          class="appointment-time"
          [innerHTML]="displayLoad | loadTimeRange: { stopIndex: -1, addBreak: false, date: 'MM/dd' }"
          data-test="load-dropoff-time"
        ></span>
      </div>
    </div>
  </div>
</div>
