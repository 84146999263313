<div class="ct-card-group">
  <div class="ct-card-group__header">
    <!-- recs -->
    <span *ngIf="currentTab === carrierToolbarTab.RECOMMENDATIONS && loadOptions?.length">
      Show recommendations for
    </span>

    <!-- search and owned carriers -->
    <span *ngIf="currentTab !== carrierToolbarTab.RECOMMENDATIONS"> Bid / Book For </span>
    <span *ngIf="loadOptions?.length || currentTab !== carrierToolbarTab.RECOMMENDATIONS">
      <ng-container *ngrxLet="selectedLoads$ as selectedLoads">
        <span class="ct-card-group__primary-text"
          >{{ selectedLoads?.length || 'No' }}
          {{ selectedLoads?.length > 1 ? ' Loads Selected' : ' Load Selected' }}</span
        >
      </ng-container>
    </span>
  </div>

  <ng-template #templateDropDown let-row="row">
    {{ row.label }}

    <mat-select-trigger>
      <button
        mat-icon-button
        *ngIf="row.group === carrierToolbarDropdownGroups.SELECTED_LOADS"
        class="ct-card-group__close"
        (click)="removeLoadFromSelected(row.value)"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-select-trigger>
  </ng-template>

  <!-- load dropdown -->
  <form [formGroup]="inputForm" *ngIf="loadOptions?.length || currentTab !== carrierToolbarTab.RECOMMENDATIONS">
    <mat-form-field class="ct-card-group__load-select">
      <app-drop-down
        [disableDropDown]="!showOptions"
        [hideIcon]="!showOptions"
        [data]="loads?.length ? loadOptions : []"
        [templateLabel]="templateDropDown"
        [customClass]="'ct-card-group__load-select'"
        key="value"
        formControlName="currentLoad"
      ></app-drop-down>
    </mat-form-field>
  </form>

  <div class="ct-card-group__search-container" *ngIf="currentTab === carrierToolbarTab.CARRIER_SEARCH">
    <div class="ct-card-group__label-container">
      <mat-icon class="ct-card-group__label-icon"> search </mat-icon>
    </div>
    <form [formGroup]="searchForm" autocomplete="off">
      <input
        formControlName="search"
        class="ct-card-group__input"
        matInput
        placeholder="Search for carriers"
        data-test="search-for-carrier"
      />
    </form>
  </div>

  <mat-spinner
    [diameter]="36"
    *ngIf="
      (currentTab === carrierToolbarTab.RECOMMENDATIONS && loadEntityService.getCarrierRecommendations.isSearching$
        | async) ||
      (currentTab === carrierToolbarTab.CARRIER_SEARCH && carrierEntityService.searchCarriers.isSearching$ | async) ||
      (currentTab === carrierToolbarTab.OWNED_CARRIERS && loadEntityService.getOwnedCarriers.isSearching$ | async)
    "
  ></mat-spinner>

  <div class="card-container">
    <div
      *ngIf="
        (currentTab === carrierToolbarTab.RECOMMENDATIONS && (recommendations$ | async)?.length) ||
        (currentTab === carrierToolbarTab.OWNED_CARRIERS && (ownedCarriers$ | async)?.length) ||
        (currentTab === carrierToolbarTab.CARRIER_SEARCH && (carriers$ | async)?.length)
      "
    >
      <div
        *ngFor="
          let data of currentTab === carrierToolbarTab.RECOMMENDATIONS
            ? (recommendations$ | async)
            : currentTab === carrierToolbarTab.OWNED_CARRIERS
            ? (ownedCarriers$ | async)
            : (carriers$ | async)
        "
      >
        <haulynx-toolbar-card
          (openCarrierProfile)="onOpenCarrierProfile($event)"
          (carrierAction)="onCarrierAction($event)"
          (submitBidForm)="submitBid($event)"
          (chosenCarrier)="newCarrier($event)"
          [featureFlags]="featureFlags"
          [tab]="currentTab"
          [data]="data"
          [user]="user"
          [selectedLoad]="selectedLoad"
          [latestBid]="latestBid"
          [showBids]="(data?.load?.carrier?.dot || data?.carrier?.dot || data?.dot) === currentCarrier?.dot"
          [bidCreateLoading]="bidCreateLoading"
          data-test="haulynx-toolbar-card"
        ></haulynx-toolbar-card>
      </div>
    </div>

    <!-- no loads selected -->
    <span *ngIf="currentTab === carrierToolbarTab.RECOMMENDATIONS && !loadOptions?.length">
      <div class="ct-card-group__noLoads">
        <div class="ct-card-group__noLoads__content">No loads have been selected</div>
        <div
          *ngIf="currentTab === carrierToolbarTab.RECOMMENDATIONS; else ownedCarriers"
          class="ct-card-group__noLoads__secondary"
        >
          If you would like to see carrier recommendations checkmark the loads you would like to see recommendations
          for.
        </div>
      </div>
    </span>

    <!-- no available recommendations -->
    <div
      class="ct-card-group__empty__recs"
      *ngIf="
        currentTab === carrierToolbarTab.RECOMMENDATIONS &&
        loadOptions?.length &&
        !(recommendations$ | async)?.length &&
        !(loadEntityService.getCarrierRecommendations.isSearching$ | async)
      "
    >
      No carriers recommendations are available
    </div>

    <!-- no available owned carriers -->
    <div
      class="ct-card-group__empty__recs"
      *ngIf="
        currentTab === carrierToolbarTab.OWNED_CARRIERS &&
        !(ownedCarriers$ | async)?.length &&
        !(loadEntityService.getOwnedCarriers.isSearching$ | async)
      "
    >
      No owned carriers
    </div>

    <!-- no carriers -->
    <div
      class="ct-card-group__empty__carrier-results"
      *ngIf="
        currentTab === carrierToolbarTab.CARRIER_SEARCH &&
        !(carriers$ | async)?.length &&
        !(carrierEntityService.searchCarriers.isSearching$ | async)
      "
    >
      Search for a carrier MC, DOT, or name to see results below
    </div>

    <!-- load more recommendations -->
    <div class="ct_load_more_sec">
      <button
        *ngIf="
          currentTab === carrierToolbarTab.RECOMMENDATIONS &&
          (recommendations$ | async)?.length &&
          enableLoadMore &&
          !(loadEntityService.getCarrierRecommendations.isSearching$ | async)
        "
        (click)="loadMoreRecommendations()"
        mat-raised-button
        class="ct_load_more"
      >
        Load More Recommendations
      </button>
    </div>
  </div>
</div>
