<app-load-quick-actions
  [load]="load"
  [title]="title"
  [showContent]="true"
  [showTimeline]="true"
  [showSeparator]="true"
  [isLoading]="isLoading"
  (actionClick)="onActionClick($event)"
>
  <app-load-quick-driver-form
    [load]="load"
    [trucks]="[]"
    [trailers]="[]"
    [equipments]="[]"
    (submit)="onSave($event)"
  ></app-load-quick-driver-form>
</app-load-quick-actions>
