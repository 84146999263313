import gql from 'graphql-tag';

export const CreateCarrierNote = gql(`
mutation createCarrierNote($carrierId: String!, $carrierNoteInput: CarrierApiNoteInput! ){
  createCarrierNote(carrierId: $carrierId, carrierNoteInput: $carrierNoteInput){
    id
  }
}

`);
