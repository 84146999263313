<div class="truck-info-container">
  <form [formGroup]="assignmentForm" class="form">
    <div class="col-1">
      <mat-form-field class="app-dark-field truck" appearance="fill" floatLabel="always">
        <label class="label"> Truck </label>
        <app-truck-drop-down
          #truckList
          class="form-control"
          placeholder="Select Truck"
          formControlName="truckId"
          displayLabel="unitId"
          [data]="trucks"
          [changeableData]="true"
          [isLoading]="isLoadingTrucks"
          [entityType]="entityTypes.TRUCKS"
          [cypressName]="'truck-drop-down'"
          (selected)="selectAssignment(entityTypes.TRUCKS)"
        >
        </app-truck-drop-down>
      </mat-form-field>
      <mat-error *ngIf="assignmentForm.get('truckId').errors">*</mat-error>
      <div class="app-dark-field avail-info" appearance="fill" floatLabel="always">
        <mat-icon
          data-test="availToolTipIcon"
          class="icon"
          (mouseenter)="availToolTip.toggle($event)"
          (mouseleave)="availToolTip.toggle($event)"
          svgIcon="haulynx-info-outline"
        ></mat-icon>
        <p-overlayPanel class="available-overlay" #availToolTip>
          <div data-test="availToolTip">{{ availTooltipText }}</div>
        </p-overlayPanel>
        <label class="label"> Avail. </label>
        <div class="chips-field">
          <mat-chip
            *ngIf="assignmentForm.get('truckId').value"
            (mouseenter)="macropointToolTip.toggle($event)"
            (mouseleave)="macropointToolTip.toggle($event)"
            class="unknown_avail"
          >
            MP CAPABLE
          </mat-chip>
          <p-overlayPanel class="macropoint-overlay" #macropointToolTip>
            <div>
              <div class="title">Macropoint</div>
              {{ macropointToolTipText }}
            </div>
          </p-overlayPanel>

          <ng-container *ngrxLet="chips$ as chips">
            <span *ngIf="assignmentForm.get('truckId').value">
              <span *ngFor="let item of chips">
                <mat-chip
                  *ngIf="item.available !== trackingAvail.FALSE"
                  [ngClass]="{ unknown_avail: item.available === trackingAvail.UNKNOWN }"
                >
                  {{ item.name }}
                </mat-chip>
              </span>
            </span>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="col-2">
      <mat-form-field
        *ngIf="featureFlags[trailerFeatureFlag]"
        class="app-dark-field trailer-owner"
        appearance="fill"
        floatLabel="always"
      >
        <label class="label_trailer-owner"> Trailer Owner </label>
        <app-drop-down
          #trailerOwner
          class="form-control"
          placeholder="Select Trailer Owner"
          formControlName="trailerOwner"
          displayLabel="label"
          key="value"
          [data]="trailerOwners"
          [changeableData]="true"
          (selected)="selectTrailerOwner($event)"
        >
        </app-drop-down>
      </mat-form-field>
      <div class="trailerCol">
        <div class="trailerIcon">
          <mat-icon
            *ngIf="unknownTrailerFound"
            class="icon"
            (mouseenter)="unknownTrailerToolTip.toggle($event)"
            (mouseleave)="unknownTrailerToolTip.toggle($event)"
            svgIcon="haulynx-warning-sign"
          ></mat-icon>
          <p-overlayPanel class="unknownTrailer-overlay" #unknownTrailerToolTip>
            <div data-test="unknownTrailerToolTip">{{ unknownTrailerToolTipText }}</div>
          </p-overlayPanel>
        </div>
        <div class="trailerInput">
          <mat-form-field class="app-dark-field trailer" appearance="fill" floatLabel="always">
            <label class="label_trailer"> Trailer </label>
            <app-truck-drop-down
              #trailerList
              class="form-control"
              placeholder="Add Trailer"
              formControlName="trailerId"
              displayLabel="trailerNumber"
              [data]="selectedTrailers$ | async"
              [key]="assignmentForm?.controls['trailerOwner']?.value === trailerOwnerType.USXI ? 'trailerNumber' : 'id'"
              [changeableData]="true"
              [isLoading]="isLoadingTrailers"
              [entityType]="entityTypes.TRAILERS"
              [trailerOwner]="assignmentForm?.controls['trailerOwner']?.value"
              [cypressName]="'trailer-drop-down'"
              (selected)="selectAssignment(entityTypes.TRAILERS)"
              (forceSelect)="forceSelectTrailer($event)"
            >
            </app-truck-drop-down>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="col-3">
      <div class="driver-info">
        <span class="driverToolTip">
          <mat-icon
            (mouseenter)="driverToolTip.toggle($event)"
            (mouseleave)="driverToolTip.toggle($event)"
            svgIcon="haulynx-info-outline"
          ></mat-icon>
          <p-overlayPanel #driverToolTip>
            <driver-tool-tip></driver-tool-tip>
          </p-overlayPanel>

          <label class="label driver-label"> Driver </label>
        </span>
        <span class="driverInput">
          <app-data-table-overlay
            class="driver-overlay"
            #driverList
            [placeholder]="'Select Driver'"
            [data]="drivers"
            [cols]="cols"
            [selectedRow]="selectedRow"
            [displayLabel]="'name'"
            [footerButtonLabel]="'Create New Driver'"
            [isLoading]="isLoadingDrivers"
            [disabled]="assignmentForm.disabled"
            [cypressName]="'driver-drop-down'"
            [buttonIndex]="'primary-driver'"
            (rowSelect)="selectDriver($event, false)"
            (buttonSelect)="selectAssignment(entityTypes.DRIVERS)"
            (buttonSelectIndex)="setButtonIndex($event)"
            formcontrolname="select-driver"
          ></app-data-table-overlay>
        </span>
        <mat-error class="driverError" *ngIf="assignmentForm.get('driverId').errors">*</mat-error>
      </div>

      <mat-form-field class="app-dark-field" appearance="fill" floatLabel="always">
        <label class="label phone"> Phone </label>
        <input
          matInput
          mask="000-000-0000"
          class="form-control"
          formControlName="phone"
          placeholder="/"
          [readonly]="true"
        />
        <!-- <app-truck-drop-down
        class="form-control"
        placeholder="Select Phone"
        formControlName="phone"
        key="value"
        displayLabel="value"
        [data]="phoneList"
        [changeableData]="true"
        [entityType]="entityTypes.PHONES"
        (selected)="selectAssignment(entityTypes.PHONES)"
      >
      </app-truck-drop-down> -->
      </mat-form-field>
      <mat-error *ngIf="assignmentForm.get('phone').errors">*</mat-error>
      <mat-form-field class="app-dark-field" appearance="fill" floatLabel="always">
        <label class="label email"> Email </label>
        <input matInput class="form-control" formControlName="email" placeholder="/" [readonly]="true" />
        <!-- <app-truck-drop-down
        class="form-control"
        placeholder="Select Email"
        formControlName="email"
        key="value"
        displayLabel="value"
        [data]="emailList"
        [changeableData]="true"
        [entityType]="entityTypes.EMAILS"
        (selected)="selectAssignment(entityTypes.EMAILS)"
      >
      </app-truck-drop-down> -->
      </mat-form-field>
      <mat-error *ngIf="assignmentForm.get('email').errors">*</mat-error>

      <mat-form-field
        *ngIf="featureFlags[trackingFeatureFlag]"
        class="app-dark-field"
        appearance="fill"
        floatLabel="always"
      >
        <label class="label"> Tracking </label>
        <app-truck-drop-down
          #trackingList
          class="form-control"
          placeholder="Select Tracking"
          formControlName="trackingType"
          displayLabel="label"
          key="value"
          [data]="trackingOptions$ | async"
          [changeableData]="true"
          [entityType]="entityTypes.TRACKING_TYPES"
          [cypressName]="'tracking-drop-down'"
          (selected)="selectAssignment(entityTypes.TRACKING_TYPES)"
        >
        </app-truck-drop-down>
      </mat-form-field>
    </div>

    <div class="col-4">
      <div class="secondary-driver-container">
        <div class="inner-secondary-driver">
          <div class="inner-secondary-driver-form">
            <div class="secDriverInfo">
              <mat-icon
                (mouseenter)="secDriverToolTip.toggle($event)"
                (mouseleave)="secDriverToolTip.toggle($event)"
                svgIcon="haulynx-info-outline"
              ></mat-icon>
              <p-overlayPanel #secDriverToolTip class="secDriverOverlay">
                {{ 'Tracking will be associated with the primary driver' }}
              </p-overlayPanel>
              <label class="label sec-driver-label"> Sec. Driver </label>
              <app-data-table-overlay
                class="sec-driver-overlay driver-overlay"
                #secDriverList
                [placeholder]="'Select Driver'"
                [data]="drivers"
                [cols]="cols"
                [selectedRow]="selectedSecDriverRow"
                [displayLabel]="'name'"
                [footerButtonLabel]="'Create New Driver'"
                [isLoading]="isLoadingDrivers"
                [disabled]="assignmentForm.disabled"
                [cypressName]="'driver-drop-down'"
                [clearSelectedRow]="clearSelectedDriver | async"
                [buttonIndex]="'secondary-driver'"
                (rowSelect)="selectDriver($event, true)"
                (buttonSelect)="selectAssignment(entityTypes.DRIVERS)"
                (buttonSelectIndex)="setButtonIndex($event)"
                formcontrolname="select-secondary-driver"
              ></app-data-table-overlay>
            </div>

            <mat-form-field class="app-dark-field" appearance="fill" floatLabel="always">
              <label class="label phone"> Phone </label>
              <input
                matInput
                mask="000-000-0000"
                class="form-control"
                formControlName="secPhone"
                placeholder="/"
                [readonly]="true"
              />
            </mat-form-field>

            <mat-form-field class="app-dark-field" appearance="fill" floatLabel="always">
              <label class="label email"> Email </label>
              <input matInput class="form-control" formControlName="secEmail" placeholder="/" [readonly]="true" />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <div class="trailer-swap">
      <button
        *ngIf="
          featureFlags[trailerFeatureFlag] &&
          loadsServiceLoad.bookStatus === 'booked' &&
          loadsServiceLoad.trailers.length > 0 &&
          showSwapTrailerButton
        "
        mat-raised-button
        (click)="onSwapTrailer($event)"
        class="trailer-swap__button"
      >
        Swap Trailer
      </button>
    </div>

    <div class="individual-save-buttons">
      <button mat-raised-button [disabled]="assignmentLoading" (click)="clearSecDriver()" class="clear">
        Clear Sec. Info
      </button>
      <button
        mat-raised-button
        class="assign"
        [disabled]="assignmentLoading || !(showIndividualSaveButtons && !assignmentForm.disabled)"
        (click)="emitSaveAssignment()"
        data-test="save-details-button"
      >
        <mat-spinner [diameter]="18" *ngIf="assignmentLoading" mode="indeterminate"></mat-spinner>
        Save Details
      </button>
    </div>
  </form>
</div>
