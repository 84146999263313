import { GeoSpacialFilterType, ISearchFilter, NumericalFilterType, TextFilterType } from '../search-filter';
import { ISearchFilterType } from '../search-filter.types';

/**
 * Sorting function that sorts by priority in descending order.
 */
function sortByPriority(a: ISearchFilter, b: ISearchFilter): number {
  return (b.searchPriority || 0) - (a.searchPriority || 0);
}

/**
 * @param filterModels all possible filter models
 * @param query user-entered value to search for a desired filter
 * @returns an object containing the list of matched filter models
 */
export function searchFilterModels(filterModels: ISearchFilter[], query: string): ISearchFilter[] {
  if (!query) {
    // return all if there is no query
    return filterModels;
  }
  if (!isNaN(parseInt(query))) {
    // if user entered a number, assume they want to choose a filter and submit all at once
    const matchedFilters = filterModels
      .filter((f) => f.valueIsNumber || f.type === ISearchFilterType.NUMBER_RANGE)
      .sort(sortByPriority);
    matchedFilters.forEach((f) => {
      if (f.type === ISearchFilterType.NUMBER_RANGE) {
        (f.keys as NumericalFilterType).min = parseFloat(query);
      } else if (f.type === ISearchFilterType.TEXT) {
        (f.keys as TextFilterType).value = query;
      }
    });
    return matchedFilters;
  }

  // look for filters that have the query in their name
  const matchedFilters = filterModels
    .filter((f) => {
      return f.name.search(new RegExp(query, 'gi')) !== -1;
    })
    .sort(sortByPriority);

  if (!matchedFilters.length) {
    // if there are no matches, they might be wanting to do a location or generic string search
    const textFilters = filterModels
      .filter((f) => {
        return (
          !f.valueIsNumber &&
          (f.type === ISearchFilterType.GEOSPATIAL ||
            f.type === ISearchFilterType.TEXT_ARRAY ||
            f.type === ISearchFilterType.TEXT)
        );
      })
      .sort(sortByPriority);

    // update the filter with the query
    textFilters.forEach((f) => {
      (f.keys as GeoSpacialFilterType | TextFilterType).assignQuery?.(query);
    });

    return textFilters;
  }

  return matchedFilters;
}
