import { DecimalPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BidVmService } from '@haulynx/services';
import { UserEntityService } from '@haulynx/store';
import {
  Bid,
  BidSourceType,
  BidStatusOption,
  BidStatusType,
  Carrier,
  FeatureFlag,
  LoadsServiceLoad,
} from '@haulynx/types';
import { get } from 'lodash';
import { AppDropDownComponent } from '../../../drop-down/components/app-drop-down/app-drop-down.component';

@Component({
  selector: 'app-add-bid',
  templateUrl: './add-bid.component.html',
  styleUrls: ['./add-bid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddBidComponent implements OnChanges, OnInit {
  @ViewChild('dropDown', { static: true }) dropDown: AppDropDownComponent;

  @Input() isLoading: boolean;
  @Input() carrier: Partial<Carrier>;
  @Input() load: LoadsServiceLoad;
  @Input() disableBids: boolean;
  @Input() bidStatuses: BidStatusOption[] = [];
  @Input() isSorterVisible: boolean;
  @Input() userIsCarrier = false;
  @Input() pricePlaceholder = 0;
  @Input() priceMin = 0;
  @Input() priceMax = Infinity;
  @Input() disableCreate = false;
  @Output() createBid = new EventEmitter<Partial<Bid>>();

  public BidStatusType = BidStatusType;
  newRecommendedCarriers: FeatureFlag = FeatureFlag.NEW_CARRIER_RECOMMENDATIONS;
  form: FormGroup;
  showForm = false;
  bidStatusOptions: BidStatusOption[] = [];
  defaultStatus = BidStatusType.PENDING;
  noNameCarrier = 'No Name Carrier';
  bidV2Feature: FeatureFlag = FeatureFlag.BROKER_BIDDING_V2;

  constructor(
    private bidVmService: BidVmService,
    private decimalPipe: DecimalPipe,
    public userEntityService: UserEntityService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    const defaultFormValues = this.getDefaultFormValues();
    this.form = this.bidVmService.create(defaultFormValues);
  }

  onCreateBid(bid: Partial<Bid>): void {
    this.createBid.emit({ ...bid, carrier: this.carrier });
    this.resetForm();
  }

  toggleAddBidForm(): void {
    if (!this.userIsCarrier) {
      this.showForm = !this.showForm;
    }
  }

  getDefaultFormValues(): Partial<Bid> {
    const loadId = get(this.load, 'id', null);
    const carrierId = get(this.carrier, 'id', null);
    const status = this.defaultStatus;
    const sourceType = this.userIsCarrier ? BidSourceType.CARRIER : BidSourceType.BROKER;
    return { carrierId, loadId, status, sourceType };
  }

  resetForm(): void {
    const defaultFormValues = this.getDefaultFormValues();

    this.form.reset(defaultFormValues);
    this.bidStatusOptions = [...this.bidStatuses];
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { carrier, bidStatuses, isLoading, load } = changes;

    if (carrier && this.form) {
      const carrierId = get(carrier.currentValue, 'id', null);
      this.form.patchValue({ carrierId });
      this.showForm = !this.disableBids;
    }

    if (bidStatuses) {
      this.bidStatusOptions = bidStatuses.currentValue;
    }

    if (load) {
      this.initForm();
    }
  }
}
