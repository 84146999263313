import gql from 'graphql-tag';

export const GetLoadsServiceLoadsByUser = gql`
  query GetLoadsServiceLoadsByUser(
    $filterParams: LoadServiceSearchParameters
    $paging: LoadsServicePageAndSort
    $id: StrictId!
  ) {
    getLoadsServiceLoadsByUser(filterParams: $filterParams, paging: $paging, id: $id) {
      data {
        carrierBid {
          id
          price
          status
          bidHistory {
            id
            bidId
            event
            data {
              notes
              price
              status
            }
            createdAt
            createdBy {
              name
              carrier {
                id
              }
            }
          }
          carrierId
          carrier {
            name
            dot
          }
          loadId
          notes
          createdAt
          createdBy {
            id
            name
          }
          updatedAt
          updatedBy {
            id
            name
          }
          activeCounterOffer {
            id
            price
            notes
            status
          }
          counterOffers {
            id
            notes
          }
          sourceType
        }
        assignedBrokers {
          id
          name
          usxId
        }
        region
        shipperName
        trackingType
        loadStatus
        id
        bookStatus
        carrier {
          owner
          name
          dot
        }
        broker {
          name
          phone
          id
        }
        drivers {
          id
          name
          phone
        }
        truck {
          id
          unitId
        }
        trailers {
          id
          trailerNumber
        }
        locations {
          id
          address
          summaryAddress
          city
          state
          zipcode
          timezone
          appointmentSet
          appointmentStart
          appointmentEnd
          carrierArrival
          carrierDeparture
          locationType
          billOfLading
          customer {
            name
            customerAttributes {
              canPickUpEarly
              canDeliverEarly
              dropYard
              canBreakAtFacility
              hasFacilities
            }
          }
          geometry {
            type
            coordinates
          }
          distance: distanceMiles
          distanceMiles
          customer {
            name
          }
        }
        provider {
          id
          name
          detailType
          company
        }
        providerDetails {
          billTo
          billToName
          cxPriority
          equipmentType
          commodity
          weight: weightLbs
          quantity
          operationsPriority
          orderType
          brokerTeamId
          alternateIds {
            identifierType
            identifierValue
          }
        }
        paymentDetails {
          price
          revenue
          maxBuy
          ratePerMile
          distance: distanceMiles
          distanceMiles
        }
        bidDetails {
          offerCount
          lowestOffer
          lowestOfferCarrierName
        }
        restrictions {
          type
          value
        }
      }
      paginator {
        currentPage
        nextPage
        previousPage
        total
        totalPages
      }
    }
  }
`;
