import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'greaterThan',
})
export class GreaterThanPipe implements PipeTransform {
  transform(value: string | number, max: string | number): string | number {
    return !value || !_.isFinite(+value) || !_.isNumber(+value) || value < 0 || +value < +max ? '' : value;
  }
}
