import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AdvancedSearchService, TrailerOwnerOptions, WindowRef } from '@haulynx/services';
import { CarrierEntityService, LoadEntityService, TrailerEntityService } from '@haulynx/store';
import {
  Customer,
  customerDataColumns,
  CustomerSearchParameters,
  IColumns2,
  ISearchFilter,
  LoadSearchBarResult,
  RowSelectionEvent,
  trailerSearchFilters,
  trailerSwapConfigOptions,
  states,
  TrailerOwnerType,
  Trailer,
  CarrierAssets,
  EntityTypes,
  DataTableType,
  LoadIdentifierType,
} from '@haulynx/types';
import { aliveWhile, getStateCode } from '@haulynx/utils';
import { List } from 'immutable';
import { upperCase } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, map, takeUntil, debounceTime } from 'rxjs/operators';

@Component({
  selector: 'haulynx-swap-trailer',
  templateUrl: './swap-trailer.component.html',
  styleUrls: ['./swap-trailer.component.scss'],
})
export class SwapTrailerComponent implements OnInit, OnDestroy {
  filters: ISearchFilter[] = trailerSearchFilters;
  tableData$: BehaviorSubject<Customer[]> = new BehaviorSubject([]);
  configOptions = trailerSwapConfigOptions;
  columns: IColumns2[] = [];
  dataLoading$: Observable<boolean>;
  alive = aliveWhile();
  selectedRows$ = new BehaviorSubject<Customer[]>([]);
  errorMsg = '';
  searchQuery: Partial<CustomerSearchParameters>;
  searchViewFilters$ = new BehaviorSubject<ISearchFilter[]>([]);
  shouldResetTextArrayForm = false;
  mainForm: FormGroup;
  selectedTrailers$: BehaviorSubject<Partial<Trailer>[] | List<Trailer> | number[]> = new BehaviorSubject([]);
  usxiTrailerNumbers$ = new BehaviorSubject([]);
  trailerSwapLoading$ = new BehaviorSubject<boolean>(false);
  carrierAssets: Trailer[];

  pageNum = 0;
  trailerOwnerType = TrailerOwnerType;
  trailerOwners: TrailerOwnerOptions[] = [{ label: 'USXI', value: TrailerOwnerType.USXI }];
  entityTypes = EntityTypes;

  constructor(
    private dialogRef: MatDialogRef<SwapTrailerComponent>,
    private advancedSearchService: AdvancedSearchService,
    private loadEntityService: LoadEntityService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private windowRef: WindowRef,
    private fb: FormBuilder,
    private trailerEntityService: TrailerEntityService,
    private carrierEntityService: CarrierEntityService
  ) {
    this.mainForm = this.fb.group({
      trailerOwner: [this.trailerOwnerType.USXI],
      trailerId: [''],
    });

    this.trailerEntityService.searchUSXITrailerNumberManager.searchResults$
      .pipe(
        takeUntil(this.alive),
        map((trailerNumber) => {
          const result = [];
          for (const key in trailerNumber) {
            result.push({ key, trailerNumber: trailerNumber[key] });
          }
          return result;
        })
      )
      .subscribe((usxiTrailerNumbers) => {
        this.usxiTrailerNumbers$.next(usxiTrailerNumbers);
        this.updateTrailerData();
      });
  }

  ngOnDestroy() {
    this.alive.destroy();
  }

  ngOnInit(): void {
    this.dataLoading$ = this.loadEntityService.getCustomersManager.isSearching$;

    this.loadEntityService.getCustomersManager.searchQuery$
      .pipe(first(), takeUntil(this.alive))
      .subscribe((currSearchQuery) => {
        const { searchFilters } = this.advancedSearchService.convertSearchPayloadToSearchBarData(
          currSearchQuery,
          this.filters
        );

        this.searchViewFilters$.next(searchFilters);
      });

    this.loadEntityService.getCustomersManager.searchResults$.pipe(takeUntil(this.alive)).subscribe((customers) => {
      this.tableData$.next(customers);
    });

    this.carrierEntityService.carrierAssets.data$.pipe(takeUntil(this.alive)).subscribe((carrierAssets) => {
      if (carrierAssets?.trailers) {
        this.carrierAssets = carrierAssets?.trailers;
        this.updateTrailerData();
      }
    });

    this.trailerEntityService.bookUSXITrailerManager.onSuccess$
      .pipe(debounceTime(500), takeUntil(this.alive))
      .subscribe((res) => {
        this.trailerSwapLoading$.next(false);
        this.loadEntityService.getLoadByIdManager.dispatch(this.data.loadsServiceLoad.id);
        this.dialogRef.close();
      });

    this.trailerEntityService.bookUSXITrailerManager.onError$
      .pipe(debounceTime(500), takeUntil(this.alive))
      .subscribe((res) => {
        this.trailerSwapLoading$.next(false);
      });

    this.columns = customerDataColumns([]);
  }

  close() {
    this.dialogRef.close();
  }

  nextPage() {
    this.pageNum = 1;
    this.dialogRef.updateSize('600px', '550px');
  }

  prevPage() {
    this.pageNum = 0;
    this.dialogRef.updateSize('1050px', '850px');
  }

  onSelectedFilterChange(filters: ISearchFilter[]): void {
    this.searchViewFilters$.next([...filters]);
  }

  onRowSelect(selection: RowSelectionEvent<Customer>): void {
    const selected: any = <DataTableType<Customer>>selection.selection;

    if (selection.rowSelected && selected.length === 1) {
      this.selectedRows$.next(selection.selection as Customer[]);
    }
  }

  onSubmit() {
    this.trailerEntityService.bookUSXITrailerManager.dispatch(this.data.loadsServiceLoad.id, {
      orderNumber: this.data.loadsServiceLoad.providerDetails.alternateIds.find(
        (value) => value.identifierType === LoadIdentifierType.ORDER_NUMBER
      )?.identifierValue,
      customerNumber: '' + this.selectedRows$.value[0].number,
      carrierName: this.data.loadsServiceLoad.carrier.name,
      customerCompany: this.selectedRows$.value[0].company,
      trailerNumber: this.mainForm.controls['trailerId'].value,
      loadId: this.data.loadsServiceLoad.id,
    });
  }

  onSubmitSearch(
    event: { newData: LoadSearchBarResult; previousData: LoadSearchBarResult },
    currSearchQuery: Partial<CustomerSearchParameters>
  ): void {
    const newSearchData: Record<string, any> = this.advancedSearchService.mergeSearchData(
      currSearchQuery,
      this.advancedSearchService.convertSearchToLoadServiceSearchParameters(event.newData),
      this.advancedSearchService.convertSearchToLoadServiceSearchParameters(event.previousData)
    );
    const city = newSearchData.customerCity
      ? upperCase(newSearchData.customerCity[newSearchData.customerCity.length - 1].split(',')[0])
      : null;
    const state = newSearchData.customerState
      ? getStateCode(
          ...(newSearchData.customerState[newSearchData.customerState.length - 1].split(', ') as [string, string])
        )
      : null;
    const query: CustomerSearchParameters = {
      partialName: newSearchData.CustomerName,
      customerNumber: newSearchData.CustomerNumber,
      city: city,
      state: state,
    };
    if (query.state || query.city || query.customerNumber) {
      this.loadEntityService.getCustomersManager.dispatch({ query });
      this.errorMsg = '';
      return;
    }

    this.errorMsg = 'Please enter a city or customer number to allow search to complete';
  }

  onCall(number: string): void {
    this.windowRef.getNativeWindow().open('tel:' + number, '_blank');
  }

  private updateTrailerData() {
    switch (this.mainForm?.controls['trailerOwner']?.value) {
      case TrailerOwnerType.USXI:
        this.selectedTrailers$.next(this.usxiTrailerNumbers$.value);
        break;
      case TrailerOwnerType.THIRD_PARTY:
        console.log(this.carrierAssets);
        this.selectedTrailers$.next(this.carrierAssets);
        break;
      case TrailerOwnerType.OTHER:
        this.selectedTrailers$.next([]);
        break;
      default:
        this.selectedTrailers$.next([]);
    }
  }
}
