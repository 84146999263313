import { Pipe, PipeTransform } from '@angular/core';
import { Document } from '@haulynx/types';

@Pipe({
  name: 'documentName',
})
export class FromDocumentNamePipe implements PipeTransform {
  transform(documentArray: Document[]): string {
    let nameArray = '';
    documentArray.forEach((doc, i) => {
      nameArray += i < documentArray.length - 1 ? `${doc.file_name}, ` : doc.file_name;
    });
    return nameArray;
  }
}
