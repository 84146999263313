import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@haulynx/modules';
import { SpinnerModule } from '../../spinner/spinner.module';
import { ChipSelectorComponent } from './chip-selector.component';

@NgModule({
  declarations: [ChipSelectorComponent],
  imports: [CommonModule, ReactiveFormsModule, MaterialModule, SpinnerModule],
  exports: [ChipSelectorComponent],
})
export class ChipSelectorModule {}
