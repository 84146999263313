import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@haulynx/modules';
import { DialogTemplateModule } from '../dialog-template/dialog-template.module';
import { ContactSupportDialogComponent } from './contact-support-dialog.component';

@NgModule({
  declarations: [ContactSupportDialogComponent],
  imports: [CommonModule, DialogTemplateModule, MaterialModule, FlexLayoutModule],
  exports: [ContactSupportDialogComponent],
})
export class ContactSupportDialogModule {}
