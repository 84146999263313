import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'haulynx-sidebar-nav-sub-template',
  templateUrl: './sidebar-nav-sub-template.component.html',
  styleUrls: ['./sidebar-nav-sub-template.component.scss'],
})
export class SidebarNavSubTemplateComponent implements OnInit {
  @Output() closed = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  public close() {
    this.closed.emit();
  }
}
