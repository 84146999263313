<div class="dialog">
  <div class="dialog__header">
    <div mat-dialog-title>
      <p class="dialog__title">Report Rolled Loads</p>
    </div>
    <p class="dialog__text">Make your selection on the details on the load that is about to roll.</p>
  </div>
  <form [formGroup]="myForm" mat-dialog-content>
    <div class="form_outer">
      <div class="form_outer__form placeholder">
        <label class="reason" for="reason">Reason</label>

        <select formControlName="reason" [ngClass]="valueSelected ? 'dropdown' : 'dropdown-placeholder'">
          <option style="display: none" value="">Select a reason for rolling</option>
          <option style="padding: 50px" *ngFor="let reason of reasons">
            {{ reason?.value }}
          </option>
        </select>
      </div>
      <div class="form_outer__form">
        <label class="comment" for="comment">Comment</label>
        <textarea rows="6" formControlName="comment" placeholder="Enter any additional comments here"> </textarea>
      </div>
    </div>
  </form>
  <mat-dialog-actions align="end">
    <action-buttons
      [buttons]="actionButtons"
      [disableButtons]="disableButtons"
      [loading]="isLoading$ | async"
      (onClick)="actionButtonClick($event)"
    >
    </action-buttons>
  </mat-dialog-actions>
</div>
