import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppModel, LoadFeedModel } from '@haulynx/store';
import { Bid, BidStatusType, Carrier, LoadsServiceLoad } from '@haulynx/types';
import { take } from 'rxjs/operators';
import { AddBidComponent } from '../../../bids/components/add-bid/add-bid.component';

@Component({
  selector: 'app-carrier-create-bid-dialog',
  templateUrl: './carrier-create-bid-dialog.component.html',
  styleUrls: ['./carrier-create-bid-dialog.component.scss'],
})
export class CarrierCreateBidDialogComponent implements OnInit {
  @ViewChild('addBidComponent', { static: true }) addBidComponent: AddBidComponent;

  public acceptedTerms = false;
  public bidForm = new FormGroup({
    price: new FormControl(null, [Validators.required]),
  });
  public carrier: Carrier;
  public maxPrice: number;
  public minPrice: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { load: LoadsServiceLoad },
    private appModel: AppModel,
    public loadFeedModel: LoadFeedModel,
    private dialogRef: MatDialogRef<CarrierCreateBidDialogComponent>
  ) {
    this.appModel.user$.pipe(take(1)).subscribe((user) => {
      this.carrier = user.carrier || null;
    });
  }

  ngOnInit(): void {
    setTimeout(() => {
      // Price should be no lower than the active counter offer price for carriers
      if (
        this.data.load.carrierBid &&
        this.data.load.carrierBid.activeCounterOffer &&
        this.data.load.carrierBid?.status !== BidStatusType.AUTO_REJECTED
      ) {
        this.minPrice = this.data.load.carrierBid.activeCounterOffer.price;
        this.maxPrice = this.data.load.carrierBid.price;
        this.addBidComponent.form.controls.price.setValue(this.data.load.carrierBid.price);
        this.addBidComponent.form.controls.price.setValidators([
          Validators.required,
          Validators.min(this.minPrice + 0.01),
          Validators.max(this.maxPrice - 0.01),
        ]);
        this.addBidComponent.form.controls.price.updateValueAndValidity();
      } else {
        this.minPrice = this.data.load.paymentDetails.price;

        if (this.data.load.carrierBid?.price && this.data.load.carrierBid?.status !== BidStatusType.AUTO_REJECTED) {
          this.maxPrice = this.data.load.carrierBid.price;
          this.addBidComponent.form.controls.price.setValue(this.data.load.carrierBid.price);
        }
        this.addBidComponent.form.controls.price.setValidators([
          Validators.required,
          Validators.min(this.minPrice + 0.01),
          Validators.max(this.maxPrice || Infinity - 0.01),
        ]);
        this.addBidComponent.form.controls.price.updateValueAndValidity();
      }

      this.addBidComponent.showForm = true;
    });
  }

  public onCreateBid(bid: Partial<Bid>): void {
    if (
      !this.data.load.carrierBid?.status ||
      this.data.load.carrierBid?.status === BidStatusType.PRICE_REJECTED ||
      this.data.load.carrierBid?.status === BidStatusType.NO_CAPACITY ||
      this.data.load.carrierBid?.status === BidStatusType.AUTO_REJECTED
    ) {
      this.loadFeedModel.createBid({ ...bid, status: BidStatusType.LIVE }, this.data.load);
    } else {
      this.loadFeedModel.updateBid(
        { id: this.data.load.carrierBid.id, price: Number(bid.price), notes: bid.notes },
        this.data.load
      );
    }

    this.dialogRef.close(true);
  }
}
