import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textCutoff',
})
export class TextCutoffPipe implements PipeTransform {
  transform(value: string, length: number = 9): unknown {
    if (value?.length > length) {
      return value.substr(0, length - 1).trim() + '..';
    }

    return value;
  }
}
