import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@haulynx/modules';
import { CarrierLeaderboardComponent } from './carrier-leaderboard.component';
import { ChartsModule } from 'ng2-charts';
import { PipesModule } from '@haulynx/pipes';

@NgModule({
  declarations: [CarrierLeaderboardComponent],
  exports: [CarrierLeaderboardComponent],
  imports: [CommonModule, MaterialModule, PipesModule, ChartsModule],
})
export class CarrierLeaderboardModule {}
