<haulynx-sidebar-nav-sub-template (closed)="toggleSubNav(sidenav)">
  <div title>ACCOUNT SETUP</div>
  <div content fxLayout="column">
    <div class="rmis-account-setup-status-side" *ngIf="rmisProps?.showAccountSetup">
      <div (click)="$event.stopPropagation()">
        <div class="checks-sidenav" fxLayout="column">
          <div
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="16px"
            [ngClass]="{ complete: (rmisProps?.registration | async)?.fleetRegistered || false }"
          >
            <mat-icon>{{
              (rmisProps?.registration | async)?.fleetRegistered ? 'task_alt' : 'radio_button_unchecked'
            }}</mat-icon>
            <div class="description-sidenav">Carrier Profile</div>
          </div>
          <div
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="16px"
            [ngClass]="{ complete: (rmisProps?.registration | async)?.w9Orw8Registered || false }"
          >
            <mat-icon>{{
              (rmisProps?.registration | async)?.w9Orw8Registered ? 'task_alt' : 'radio_button_unchecked'
            }}</mat-icon>
            <div class="description-sidenav">Tax Documentation</div>
          </div>
          <div
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="16px"
            [ngClass]="{ complete: (rmisProps?.registration | async)?.comcheckAdvancesRegistered || false }"
          >
            <mat-icon>{{
              (rmisProps?.registration | async)?.comcheckAdvancesRegistered ? 'task_alt' : 'radio_button_unchecked'
            }}</mat-icon>
            <div class="description-sidenav">Payment Advances</div>
          </div>
          <div
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="16px"
            [ngClass]="{ complete: (rmisProps?.registration | async)?.paymentOptionRegistered || false }"
          >
            <mat-icon>{{
              (rmisProps?.registration | async)?.paymentOptionRegistered ? 'task_alt' : 'radio_button_unchecked'
            }}</mat-icon>
            <div class="description-sidenav">Payment Options</div>
          </div>
          <div
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="16px"
            [ngClass]="{ complete: (rmisProps?.registration | async)?.supplierDiversityRegistered || false }"
          >
            <mat-icon>{{
              (rmisProps?.registration | async)?.supplierDiversityRegistered ? 'task_alt' : 'radio_button_unchecked'
            }}</mat-icon>
            <div class="description-sidenav">Supplier Diversity</div>
          </div>
        </div>
        <div class="continue-sidenav">
          <ng-container *ngIf="rmisProps?.percentComplete < 100; else completed">
            <haulynx-white-outline-button
              text="Continue Acct. Setup"
              icon="arrow_forward"
              class="rmis-btn-mobile"
              iconPosition="after"
              tooltipText="Changes to your account may take up to 1 minute to process."
              [loading]="rmisProps?.loadingContinue$ | async"
              (clicked)="continueToRmis()"
            ></haulynx-white-outline-button>
          </ng-container>
          <ng-template #completed>
            <haulynx-white-outline-button text="Continue" (clicked)="clickCompleted()"></haulynx-white-outline-button>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</haulynx-sidebar-nav-sub-template>
