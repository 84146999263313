<div
  *ngIf="menuItems"
  [ngClass]="{ isVisible: isVisible }"
  data-test="dropdown"
  class="dropdown"
  (mouseenter)="showMenu()"
  (mouseleave)="hideMenu()"
>
  <ng-content [ngClass]="{ hover: isVisible }"></ng-content>
  <div class="dropdown-content">
    <button
      *ngFor="let subItem of menuItems"
      (click)="handleSelect(subItem)"
      [routerLink]="[subItem.link]"
      [queryParams]="subItem.queryParams ? subItem.queryParams : {}"
      class="dropdown-item dropdown-content__item"
      attr.data-test="{{ subItem.link }}"
    >
      <span>{{ subItem.name }}</span>
    </button>
  </div>
</div>
