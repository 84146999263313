import { Injectable } from '@angular/core';
import { LoadsServiceService, NotificationsService } from '@haulynx/services';
import { AssignLoadPayload, LoadsServiceLoadInput, TrackingType } from '@haulynx/types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { DispatchAction } from '../../shared/store/helpers/action';
import { LoadDetailsModel } from '../load-details/load-details-model';
import { LoadActiveActions, LoadActiveActionTypes } from './load.actions';

@Injectable()
export class LoadActiveEffects {
  loadAssigned = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadActiveActionTypes.ASSIGNED),
      map((action: DispatchAction) => action.payload),
      switchMap((payload) => {
        const { loadId, load } = payload;
        const carrierId = load?.carrier?.id || load?.carrier?.dot;
        const trackingType = TrackingType.PHONE;
        const drivers = load?.drivers?.[0]?.id
          ? [{ id: load.drivers[0].id || null, phone: load.drivers[0].phone || null }]
          : [];
        const truckId = load?.truck?.id || null;
        const trailerIds = load?.trailers?.[0]?.id ? [load.trailers[0].id] : [];
        const assignLoadPayload: AssignLoadPayload = {
          loadId,
          carrierId,
          assignInput: {
            trackingType,
            drivers,
            truckId,
            trailerIds,
          },
        };
        return this.loadsServiceService.assignLoadServiceLoad(assignLoadPayload).pipe(
          map((res) => LoadActiveActions.assignedSuccess({ loadId })),
          catchError((err) => {
            this.notificationsService.error(err, 'Load accept.');
            return of(LoadActiveActions.assignedError({ loadId }));
          })
        );
      })
    )
  );

  loadAssignedSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoadActiveActionTypes.ASSIGNED_SUCCESS),
        map((action: DispatchAction) => action.payload),
        map((payload) => {
          const { loadId } = payload;
          return this.loadDetailsModel.get({ key: loadId, id: loadId });
        })
      ),
    { dispatch: false }
  );

  loadRejected = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadActiveActionTypes.REJECT),
      map((action: DispatchAction) => action.payload),
      switchMap((payload) => {
        const { loadId } = payload;
        return this.loadsServiceService.deleteOffer(payload).pipe(
          map((res) => LoadActiveActions.rejectSuccess({ loadId })),
          catchError((err) => {
            this.notificationsService.error(err, 'Load reject.');
            return of(LoadActiveActions.rejectError({ loadId }));
          })
        );
      })
    )
  );

  loadRejectedSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoadActiveActionTypes.REJECT_SUCCESS),
        map((action: DispatchAction) => action.payload),
        map((payload) => {
          const { loadId } = payload;

          const message = 'Load has been rejected.';

          this.notificationsService.success(message, 'Load');
          return this.loadDetailsModel.get({ key: loadId, id: loadId });
        })
      ),
    { dispatch: false }
  );

  loadDispatch = this.actions$.pipe(
    ofType(LoadActiveActionTypes.DISPATCH),
    map((action: DispatchAction) => action.payload),
    switchMap((payload) => of(true))
  );

  loadCompleteDelivery = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadActiveActionTypes.COMPLETE_DELIVERY, LoadActiveActionTypes.CONFIRM_PICKUP),
      map((action: DispatchAction) => action.payload),
      switchMap((payload) => {
        const { loadId, data, position } = payload;
        const updateLocation = {
          arrivalTime: data.entranceTime ? data.entranceTime.valueOf() : null,
          departureTime: data.completed ? data.completed.valueOf() : null,
        };

        return this.loadsServiceService.updateArrivalTimeOrCompleteLoadLocation(loadId, position, updateLocation).pipe(
          map((location) => LoadActiveActions.completeDeliverySuccess({ loadId, location })),
          catchError((error) => {
            this.notificationsService.error(error, `Load update`);
            return of(LoadActiveActions.completeDeliveryError({ loadId }));
          })
        );
      })
    )
  );

  loadClose = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadActiveActionTypes.CLOSE),
      map((action: DispatchAction) => action.payload),
      switchMap((payload) => {
        const { loadId } = payload;

        return this.loadsServiceService.carrierCloseLoad(loadId).pipe(
          map(() => {
            const message = 'Load has been closed.';

            this.notificationsService.success(message, 'Load');
            return LoadActiveActions.closeSuccess({ loadId });
          }),
          catchError((error) => {
            this.notificationsService.error(`Oops, something went wrong. Unable to confirm!`, `Load`);

            return of(LoadActiveActions.closeError({ loadId }));
          })
        );
      })
    )
  );

  updateLoad = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadActiveActionTypes.UPDATE),
      map((action: DispatchAction) => action.payload),
      switchMap((payload: { load: LoadsServiceLoadInput; refreshLoad?: boolean; id?: string }) => {
        const { load, refreshLoad = false, id } = payload;

        return this.loadsServiceService.editLoad(id, payload.load).pipe(
          map(() => {
            this.notificationsService.success(`Load has been updated.`, `Load`);
            if (refreshLoad) {
              this.loadDetailsModel.get({ key: id, id });
            }

            return LoadActiveActions.updateSuccess({ loadId: id });
          }),
          catchError((error) => {
            this.notificationsService.error(`Oops, something went wrong. Unable to update!`, `Load`);

            return of(LoadActiveActions.updateError({ loadId: id }));
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private notificationsService: NotificationsService,
    private loadDetailsModel: LoadDetailsModel,
    private loadsServiceService: LoadsServiceService
  ) {}
}
