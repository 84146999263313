import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AssignmentForm,
  Carrier,
  CarrierForm,
  CarrierSourceType,
  FeatureFlag,
  FFState,
  TrackingType,
  TrailerOwnerType,
  Truck,
} from '@haulynx/types';
import { forEach, keys } from 'lodash';
import { TrackingTypeOptions } from '../carrier-info-section-vm-service/carrier-info-section-vm.service';

export interface TrackingTypeOptionsV2 {
  label: string;
  value: TrackingType;
}
export class CarrierInfoSectionFormV2 implements CarrierForm {
  carrier: string = null;
  mcNumber: string = null;
  contact: string = null;
  phone: string = null;
  email: string = null;

  constructor(options = {}) {
    const key = keys(this);
    const defaultValue = [null];

    forEach(key, (prop) => {
      this[prop] = (options && options[prop]) || defaultValue;
    });
  }
}

export class AssignmentSectionFormV2 implements AssignmentForm {
  trackingType: TrackingType = null;
  truckId: string = null;
  driverId: string = null;
  trailerOwner: TrailerOwnerType = null;
  trailerId: string = null;
  phone: string = null;
  email: string = null;
  equipment: string = null;

  constructor(options = {}) {
    const key = keys(this);
    const defaultValue = [null];

    forEach(key, (prop) => {
      this[prop] = (options && options[prop]) || defaultValue;
    });
  }
}

@Injectable({
  providedIn: 'root',
})
export class CarrierInfoSectionVmServiceV2 {
  carrierInfoFormOptions;
  assignmentFormOptions;
  private fb = new FormBuilder();
  constructor() {
    this.carrierInfoFormOptions = {
      mcNumber: [{ value: null, disabled: true }],
      contact: [{ value: null, disabled: true }],
      phone: [{ value: null, disabled: true }],
      email: [{ value: null, disabled: true }],
    };

    this.assignmentFormOptions = {
      trackingType: [{ value: null, disabled: false }],
      truckId: [{ value: null, disabled: false }],
      driverId: [{ value: null, disabled: false }],
      trailerId: [{ value: null, disabled: false }],
      phone: [{ value: null, disabled: false }],
      equipment: [{ value: null, disabled: true }],
    };
  }

  createCarrierInfoFormOptions(values: Partial<CarrierInfoSectionFormV2> = {}): FormGroup {
    const carrierInfoForm = new CarrierInfoSectionFormV2(this.carrierInfoFormOptions);
    const formGroup = this.fb.group(carrierInfoForm);

    if (values) {
      formGroup.patchValue(values);
    }

    return formGroup;
  }

  createAssignmentFormOptions(values: Partial<CarrierInfoSectionFormV2> = {}): FormGroup {
    const carrierInfoForm = new AssignmentSectionFormV2(this.assignmentFormOptions);
    const formGroup = this.fb.group(carrierInfoForm);

    if (values) {
      formGroup.patchValue(values);
    }

    return formGroup;
  }

  getDefaultTrackingOptions(): TrackingTypeOptionsV2[] {
    return [{ label: 'Macropoint', value: TrackingType.MACRO_POINT }];
  }

  setEldTrackingForTruck(truck: Truck, carrier: Carrier, trackingOptions): TrackingTypeOptionsV2[] {
    let newOptions = [...trackingOptions];
    if (
      carrier?.thirdPartyTracking?.some(
        (option) => option.trackingType === TrackingType.MACRO_POINT && option.vehicleTrackingEnabled
      ) &&
      truck?.thirdPartyTracking?.some(
        (option) => option.trackingType === TrackingType.MACRO_POINT && option.isSupported
      )
    ) {
      if (!newOptions.some((option) => option.value === TrackingType.MACRO_POINT_ELD)) {
        newOptions.push({ label: 'Macropoint ELD', value: TrackingType.MACRO_POINT_ELD });
      }
    } else {
      newOptions = newOptions.filter((item) => item.value !== TrackingType.MACRO_POINT_ELD);
    }
    return newOptions;
  }

  getTrackingValidation(trackingType: TrackingType, form: FormGroup): FormGroup {
    switch (trackingType) {
      case TrackingType.DRIVER_LITE:
        form.controls['driverId'].setValidators([Validators.required]);
        form.controls['driverId'].updateValueAndValidity();

        form.controls['truckId'].clearValidators();
        form.controls['truckId'].updateValueAndValidity();

        form.controls['phone'].setValidators([Validators.required]);
        form.controls['phone'].updateValueAndValidity();

        form.controls['email'].setValidators([Validators.required]);
        form.controls['email'].updateValueAndValidity();
        return form;
      case TrackingType.MACRO_POINT_ELD:
        form.controls['driverId'].setValidators([Validators.required]);
        form.controls['driverId'].updateValueAndValidity();

        form.controls['truckId'].setValidators([Validators.required]);
        form.controls['truckId'].updateValueAndValidity();

        form.controls['phone'].clearValidators();
        form.controls['phone'].updateValueAndValidity();

        form.controls['email'].clearValidators();
        form.controls['email'].updateValueAndValidity();
        return form;
      case TrackingType.PHONE:
        form.controls['driverId'].setValidators([Validators.required]);
        form.controls['driverId'].updateValueAndValidity();

        form.controls['truckId'].clearValidators();
        form.controls['truckId'].updateValueAndValidity();

        form.controls['phone'].clearValidators();
        form.controls['phone'].updateValueAndValidity();

        form.controls['email'].clearValidators();
        form.controls['email'].updateValueAndValidity();
        return form;
      case TrackingType.MACRO_POINT:
        form.controls['driverId'].setValidators([Validators.required]);
        form.controls['driverId'].updateValueAndValidity();

        form.controls['truckId'].clearValidators();
        form.controls['truckId'].updateValueAndValidity();

        form.controls['phone'].setValidators([Validators.required]);
        form.controls['phone'].updateValueAndValidity();

        form.controls['email'].clearValidators();
        form.controls['email'].updateValueAndValidity();
        return form;
      default:
        form.controls['driverId'].clearValidators();
        form.controls['driverId'].updateValueAndValidity();

        form.controls['truckId'].clearValidators();
        form.controls['truckId'].updateValueAndValidity();

        form.controls['phone'].clearValidators();
        form.controls['phone'].updateValueAndValidity();

        form.controls['email'].clearValidators();
        form.controls['email'].updateValueAndValidity();
        return form;
    }
  }
}
