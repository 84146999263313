import { Injectable } from '@angular/core';
import { GetUserById, GetUsersByCarrier, GetUsersByEmail, UpdateUserInfoMutation } from '@haulynx/gql';
import { User, UserValidationResult } from '@haulynx/types';
import { get } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GraphqlService } from '../graphql.service';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private graphqlService: GraphqlService) {}

  getUsersByCarrier(variables: { carrierId: string }) {
    return this.graphqlService
      .query({
        query: GetUsersByCarrier,
        variables: variables,
      })
      .pipe(
        map(({ data }: any) => {
          const entities = data.getUsersByCarrier || [];
          const total = entities.length;

          return { entities, total };
        })
      );
  }

  getUsersByEmail(variables: { email: string }): Observable<{ entities: Partial<User>[] }> {
    return this.graphqlService
      .query({
        query: GetUsersByEmail,
        variables: variables,
      })
      .pipe(
        map((result: { data: unknown; error: { message: string } }) => {
          const entities = get(result, `data.getUsersByEmail`, []);

          return { entities };
        })
      );
  }

  getUserById(variables: { id: string }): Observable<User> {
    return this.graphqlService
      .query({
        query: GetUserById,
        variables,
      })
      .pipe(
        map((result: { data: { getUserById: User }; error: { message: string } }) =>
          get(result, `data.getUserById`, null)
        )
      );
  }
}
