import { Component, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { listToArray, mapToArray, validationMessages } from '@haulynx/utils';
import { List, Map } from 'immutable';
import { find, get, isNumber, isString } from 'lodash';

export interface IDropDown {
  value: string;
  label: string;
}

@Component({
  selector: 'app-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectorComponent),
      multi: true,
    },
  ],
})
export class SelectorComponent implements ControlValueAccessor, OnChanges {
  @Input() data: IDropDown[] | List<undefined> | Map<string, undefined> | any[] = [];
  @Input() placeholder = '';
  @Input() displayLabel = 'label';
  @Input() key = 'id';
  @Input() isLoading = false;
  @Input() errors: any;
  @Input() styleDisplay: 'form-field' | 'normal' = 'form-field';
  @Input() tabIndex: number = null;

  validationMessages = validationMessages;
  items = [];
  control: FormControl = new FormControl();
  touched = false;

  propagateChanges: Function = () => {};

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    const { data } = changes;

    if (data) {
      const items = this.getData(data.currentValue);

      this.items = items;
    }
  }

  updateSelected(data, selected) {
    let item;
    const items = this.getData(data);

    if (isString(selected) || isNumber(selected)) {
      item = find(items, { [this.key]: selected });
    } else {
      item = find(items, { [this.key]: get(selected, this.key) });
    }

    return item;
  }

  displayFn(selected: IDropDown | null): string | null {
    const label = selected ? selected[this.displayLabel] : '';

    return label;
  }

  select(event: MatSelectChange) {
    const row = event.value;
    const id = row[this.key];

    this.propagateChanges(id);
  }

  registerOnChange(fn: any): void {
    this.propagateChanges = fn;
  }

  registerOnTouched(fn: any): void {
    this.touched = true;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.control.disable() : this.control.enable();
  }

  writeValue(selected: any): void {
    const selectedItem = this.updateSelected(this.data, selected);

    this.control.patchValue(selectedItem);
  }

  getData(data) {
    return List.isList(data) ? listToArray(data) : Map.isMap(data) ? mapToArray(data) : data;
  }
}
