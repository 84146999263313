import { Injectable } from '@angular/core';
import { LoadsServiceService } from '@haulynx/services';
import { LoadServiceSearchParameters } from '@haulynx/types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '../../main-store/app.reducers';
import { DispatchAction } from '../../shared/store/helpers/action';
import { LoadEntitiesActions, LoadEntitiesActionTypes } from './load-entities.actions';
import { getQuerySelector, getGraphQlPagingSelector } from './load-entities.selectors';

@Injectable()
export class LoadEntitiesEffects {
  loadEntitiesSearch = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadEntitiesActionTypes.SEARCH),
      map((action: DispatchAction) => action.payload),
      withLatestFrom(this.store.select(getGraphQlPagingSelector), this.store.select(getQuerySelector)),
      switchMap(([payload, pagination, query]) => {
        const searchField: LoadServiceSearchParameters = {
          bookStatus: query?.bookStatus,
          loadStatus: query?.loadStatus,
          showExcluded: query?.showExcluded,
          showTestLoads: query?.showTestLoads,
        };
        const dot = query?.dot;
        return this.loadsServiceService.searchCarrierLoads(dot, searchField, pagination).pipe(
          map((entities) => {
            const checker = LoadEntitiesActions.searchSuccess({
              entities: entities.data,
              total: entities.pagination.total,
            });
            return checker;
          }),
          catchError((errors) => {
            return of(LoadEntitiesActions.searchError(errors));
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private loadsServiceService: LoadsServiceService
  ) {}
}
