<span class="panel-analytics__label">Margins</span>
<div class="panel-analytics__info">
  <app-carrier-analytics-item label="Margin $" [value]="kpiMetrics?.margin"></app-carrier-analytics-item>
  <app-carrier-analytics-item
    label="Margin %"
    [value]="kpiMetrics?.marginPercentage"
    displaySymbol="%"
  ></app-carrier-analytics-item>
  <app-carrier-analytics-item label="MPU" [value]="kpiMetrics?.MPU"></app-carrier-analytics-item>
</div>
