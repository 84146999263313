import { Component, EventEmitter, Input, Output } from '@angular/core';
import { User } from '@haulynx/types';
import { Observable } from 'rxjs';

@Component({
  selector: 'haulynx-header-endbuttons-notifications',
  templateUrl: './header-endbuttons-notifications.component.html',
  styleUrls: ['./header-endbuttons-notifications.component.scss'],
})
export class HeaderEndbuttonsNotificationsComponent {
  @Input() user: User;
  @Input() totalNotifications: Observable<number>;

  @Output() showNotifications = new EventEmitter();

  constructor() {}

  onShowNotifications(event: Event): void {
    event.stopPropagation();
    this.showNotifications.emit();
  }
}
