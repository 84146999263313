import { NgModule } from '@angular/core';
import { FireBaseNotificationService } from '@haulynx/services';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NotificationModel } from './notification-model';
import { NotificationEffects } from './notification.effects';
import { notificationReducer } from './notification.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature('notification', notificationReducer),
    EffectsModule.forFeature([NotificationEffects]),
  ],
  providers: [NotificationEffects, FireBaseNotificationService, NotificationModel],
})
export class AppNotificationStateModule {}
