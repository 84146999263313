import { createFeatureSelector } from '@ngrx/store';
import { Record } from 'immutable';

interface IDashboardState {
  navIsOpen: boolean;
}

const initialDashboardState = {
  navIsOpen: true,
};

export class DashboardState extends Record(initialDashboardState) implements IDashboardState {
  navIsOpen: boolean;
}

export const getDashboardState = createFeatureSelector<IDashboardState>('dashboard');
