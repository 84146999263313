import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VirtualScrollSortComponent } from './virtual-scroll-sort.component';
import { MobileBannerModule } from '../mobile-banner/mobile-banner.module';

@NgModule({
  declarations: [VirtualScrollSortComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    PipesModule,
    MobileBannerModule,
  ],
  exports: [VirtualScrollSortComponent],
})
export class VirtualScrollSortModule {}
