import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UsersModel } from './users-model';
import { UsersEffects } from './users.effects';
import { usersReducer } from './users.reducer';

@NgModule({
  declarations: [],
  imports: [CommonModule, StoreModule.forFeature('users', usersReducer), EffectsModule.forFeature([UsersEffects])],
  providers: [UsersEffects, UsersModel],
})
export class UsersStoreModule {}
