import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AppModel, LoadModel } from '@haulynx/store';
import { loadTabsConfig, Tab, User } from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class LoadTabGuard implements CanActivate {
  private user: User;
  private loadFeedPermission: boolean;
  private alive = aliveWhile();

  constructor(private loadModel: LoadModel, private appModel: AppModel) {
    this.appModel.user$.pipe(takeUntil(this.alive)).subscribe((user) => (this.user = user));

    this.appModel.loadFeedPermission$
      .pipe(takeUntil(this.alive))
      .subscribe((permission) => (this.loadFeedPermission = permission));
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let viewTabs = [];
    const { tabs = null } = route.data;
    if (tabs && this.user) {
      const result = _.reduce(
        tabs,
        ({ tabs, activeTab }, tab, key) => {
          const newTab = new Tab(tab);
          if (newTab.id === 'completed') {
            if (this.user && !this.user.broker) {
              tabs.push(loadTabsConfig.COMPLETED);
            }
          } else if (newTab.id === 'available') {
            if (this.loadFeedPermission) {
              tabs.push(loadTabsConfig.AVAILABLE);
            }
          } else if (newTab.id === 'active') {
            if (this.user && !!this.user.carrier) {
              tabs.push(loadTabsConfig.ACTIVE);
            }
          } else {
            tabs.push(newTab);
          }

          return { tabs, activeTab };
        },
        { tabs: [], activeTab: null }
      );

      viewTabs = [...viewTabs, ...result.tabs];
    }

    if (viewTabs.length) {
      this.loadModel.addTabs(viewTabs);
    }

    return of(true);
  }
}
