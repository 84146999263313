import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { KeyValuePair } from '@haulynx/types';
import { String } from 'lodash';

@Component({
  selector: 'app-button-dropdown',
  templateUrl: './button-dropdown.component.html',
  styleUrls: ['./button-dropdown.component.scss'],
})
export class ButtonDropdownComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('wrapper', { static: false }) wrapper: ElementRef;

  @Input() closedIcon: string;
  @Input() openIcon: string;
  @Input() showButtonLabel = true;
  @Input() buttonLabelPrefix: string;
  @Input() isMultiSelect: boolean;
  @Input() buttonBackgroundColor: string;
  @Input() dropdownListOptions: string[];
  @Input() dropdownList: Array<KeyValuePair>;
  @Input() dropdownPosition: string;
  @Input() disabledDropdownIndices: boolean[];
  @Input() buttonWidth: string;
  @Input() showDropdownFromLeft = false;
  @Input() haveShowAllButton = false;
  @Input() rowId? = '';
  @Input() closeDropdown = false;

  @Output() clickedDropdownItem: EventEmitter<KeyValuePair[]> = new EventEmitter();
  @Output() closeOtherDropdowns: EventEmitter<string> = new EventEmitter();

  isButtonClicked = false;
  shouldCheckClicks = false;
  checkedArray = [];
  label = '';
  randomDropdownId = Math.random();

  constructor() {}

  @HostListener('document:click', ['$event.target']) onClick(targetElement: ElementRef): void {
    const clickedInside = (<any>targetElement).classList.value.includes(this.randomDropdownId.toString());
    if (!clickedInside && this.shouldCheckClicks) {
      this.isButtonClicked = false;
    }
  }

  @HostListener('click', ['$event']) click(): void {
    this.closeOtherDropdowns.emit(this.rowId);
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.closeDropdown?.currentValue) {
      this.isButtonClicked = false;
    }

    if (changes.dropdownList && this.isMultiSelect) {
      this.checkedArray = [];
      this.dropdownList.forEach((value) => {
        if (typeof value.value !== 'boolean') {
          throw 'On Multiselect dropdowns, the type of the <value> must be boolean';
        } else {
          this.checkedArray.push({ key: value.key, value: value.value });
        }
      });
    }
    if (!this.isMultiSelect) {
      this.checkedArray.push(this.dropdownList[0]);
    }

    if (this.haveShowAllButton) {
      if (!this.checkedArray.find((value) => value.key === 'All')) {
        this.checkedArray.unshift({ key: 'All', value: true });
      }
    }

    this.setButtonLabel();
  }

  get randomClass(): string {
    return this.randomDropdownId.toString();
  }

  ngAfterViewInit(): void {
    this.shouldCheckClicks = false;
    setTimeout(() => {
      this.shouldCheckClicks = true;
    }, 500);
  }

  openButton(): void {
    this.isButtonClicked = !this.isButtonClicked;
  }

  getIsChecked(row: KeyValuePair): boolean {
    const didFindChecked = this.checkedArray.find((value) => {
      if (row.key === value.key) return value.value;
    });
    return didFindChecked ? true : false;
  }

  setButtonLabel(): void {
    if (this.showButtonLabel) {
      const labeledList = this.checkedArray.filter((value) => (this.isMultiSelect ? value.value : true));
      this.label = `${this.buttonLabelPrefix ? this.buttonLabelPrefix + ' ' : ''}${labeledList
        .map((value) => (this.isMultiSelect ? (value.value ? value.key : '') : value.key))
        .join(', ')}`;
    }
  }

  selectRow(row: KeyValuePair): void {
    if (this.isMultiSelect) {
      const showAll =
        this.haveShowAllButton && row.key === 'All'
          ? this.checkedArray.find((value) => value.key === 'All')?.value
            ? 1
            : -1
          : 0;

      if (showAll !== 0) {
        this.checkedArray = this.checkedArray.map((value) => {
          value.value = showAll === 1 ? false : true;
          return value;
        });
      } else {
        const allButShowAll = this.checkedArray.filter((value) => value.key !== 'All');
        const newCheckedArray = allButShowAll.map((value) => {
          if (value.key === row.key) {
            value.value = !value.value;
          }
          return value;
        });

        const showAllButton = { key: 'All', value: true };
        const newCheckedArrayHasAnyFalse = newCheckedArray.find((value) => !value.value);
        if (newCheckedArrayHasAnyFalse) {
          showAllButton.value = false;
        }

        this.checkedArray = [showAllButton, ...newCheckedArray];
      }

      const allTrue = [];
      this.checkedArray.forEach((value) => {
        if (value.value) {
          allTrue.push(value);
        }
      });

      this.clickedDropdownItem.emit(allTrue);
    } else {
      this.checkedArray = [row];
      this.clickedDropdownItem.emit([row]);
      this.isButtonClicked = false;
    }
    this.setButtonLabel();
  }
}
