import { ISearchFilter, NumericalFilterType } from '../search-filter';
import { ISearchFilterType } from '../search-filter.types';

export class HighestBidFilter implements ISearchFilter {
  name = 'Highest Bid';
  type: ISearchFilterType = ISearchFilterType.NUMBER_RANGE;
  keys: NumericalFilterType;
  validation?: NumericalFilterType;
  description = 'Filter a load by the highest bids';
  formPrefix = '$';

  constructor(defaultValues: Partial<NumericalFilterType> = {}, validationValues: Partial<NumericalFilterType> = {}) {
    this.keys = new NumericalFilterType({
      min: null,
      max: null,
      minFormName: 'minHighestBid',
      maxFormName: 'maxHighestBid',
      ...defaultValues,
    });
    this.validation = new NumericalFilterType(validationValues);
  }
}
