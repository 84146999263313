import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DispatchForm } from '@haulynx/types';
import { locationAndTimeNotNull } from '@haulynx/utils';
import { forEach, keys } from 'lodash';

export class LoadDispatchForm implements DispatchForm {
  location = null;
  timeAvailable = null;
  notes = null;

  constructor(options = {}) {
    const key = keys(this);
    const defaultValue = [null];

    forEach(key, (prop) => {
      this[prop] = (options && options[prop]) || defaultValue;
    });
  }
}

@Injectable({
  providedIn: 'root',
})
export class LoadDispatchFormVm {
  options;
  private fb = new FormBuilder();
  constructor() {
    this.options = {
      location: [null],
      timeAvailable: [null],
    };
  }

  create(values: Partial<DispatchForm> = {}): FormGroup {
    const dispatchForm = new LoadDispatchForm(this.options);
    const { timeAvailable, notes, location } = dispatchForm;
    const timeAvailableControl = new FormControl(timeAvailable);
    const notesControl = new FormControl(notes);
    const locationControl = new FormControl(location);

    const formGroup = this.fb.group({
      timeAvailable: timeAvailableControl,
      notes: notesControl,
      location: locationControl,
    });

    locationControl.setValidators(() => {
      const isEmptyLocation = !locationControl.value || (locationControl.value && !locationControl.value.address);

      if (isEmptyLocation) {
        timeAvailableControl.setValue(null);
        timeAvailableControl.disable();
      } else {
        timeAvailableControl.enable();
      }
      return null;
    });

    formGroup.setValidators(locationAndTimeNotNull('location', 'timeAvailable'));

    if (values) {
      formGroup.patchValue(values);
    }

    return formGroup;
  }
}
