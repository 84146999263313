import { AppSettingsActionTypes } from '../../main-store/app-settings.actions';
import { DispatchAction } from '../../shared/store/helpers/action';
import { combineAdapterReducers } from '../../shared/store/helpers/adapter';
import { combineReducersFlat } from '../../shared/store/helpers/reducer';
import { fleetAdaptor } from './adaptors';
import { FleetState } from './fleet.state';

export const initialState = new FleetState();

const combinedReducers = combineReducersFlat([combineAdapterReducers<FleetState>(fleetAdaptor)]);

export function fleetReducer(state = initialState, action: DispatchAction): FleetState {
  switch (action.type) {
    case AppSettingsActionTypes.LOG_OUT:
      return initialState;
    default:
      const newState = combinedReducers(state, action);
      return newState;
  }
}
