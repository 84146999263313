import { RolledLoads } from '.';
import { OperationsPriority } from '../../loads-service';
import { User } from './user';

export interface LoadBulkActionInput {
  loadId: string;
  assignedBrokers?: Partial<User>;
  maxBuy?: number;
  operationsPriority?: OperationsPriority;
  price?: number;
}

export interface LoadBulkActionPayload {
  action: LoadBulkAction;
  loadInputs?: Partial<LoadBulkActionInput>[];
  reportedLoadsInput?: RolledLoads;
}

export enum LoadBulkAction {
  UPDATE_MAX_BUY = 'updateMaxBuy',
  CONFIRM_UPDATE_MAX_BUY = 'confirmUpdateMaxBuy',
  COPY_LOADS = 'copyLoads',
  ASSIGN_REPS = 'assignReps',
  PRIORITY_CHANGE = 'priorityChange',
  REPORT_ROLLED_LOADS = 'reportRolledLoads',
  EXPORT_CSV = 'exportCSV',
}
