import { Location, UnauthenticatedLoadsServiceLoad } from '@haulynx/types';
import { createFeatureSelector } from '@ngrx/store';
import { Record } from 'immutable';

interface ISharedLocationState {
  sharedLoad: UnauthenticatedLoadsServiceLoad;
  isLoadingSharedLoad: boolean;
  sharedLocation: Location;
}
const initialSharedLocationState = {
  sharedLoad: null,
  isLoadingSharedLoad: true,
  sharedLocation: null,
};

export class SharedLocationState extends Record(initialSharedLocationState) implements ISharedLocationState {
  sharedLoad: UnauthenticatedLoadsServiceLoad;
  isLoadingSharedLoad: boolean;
  sharedLocation: Location;
}

export const getSharedLocationState = createFeatureSelector<ISharedLocationState>('shared-location');
