import { Pipe, PipeTransform } from '@angular/core';
import { LoadsServiceLoad, LoadsServiceLoadStatus } from '@haulynx/types';
import { formatDistance } from 'date-fns';

@Pipe({
  name: 'getLastLocationTimeDiffPipe',
})
export class GetLastLocationTimeDiffPipe implements PipeTransform {
  constructor() {}

  transform(timestamp: number) {
    return !timestamp ? 'No time available' : formatDistance(new Date(), timestamp) + ' ago';
  }
}
