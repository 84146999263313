import { Pipe, PipeTransform } from '@angular/core';
import { DayOfWeek, WeekDays } from '@haulynx/types';

@Pipe({ name: 'daysOfWeekPipe' })
export class DaysOfWeekPipe implements PipeTransform {
  constructor() {}
  transform(arr: DayOfWeek[]): string {
    const days: WeekDays[] = [
      { label: 'S', key: DayOfWeek.SUNDAY, id: 0, selected: false },
      { label: 'M', key: DayOfWeek.MONDAY, id: 1, selected: false },
      { label: 'T', key: DayOfWeek.TUESDAY, id: 2, selected: false },
      { label: 'W', key: DayOfWeek.WEDNESDAY, id: 3, selected: false },
      { label: 'Th', key: DayOfWeek.THURSDAY, id: 4, selected: false },
      { label: 'F', key: DayOfWeek.FRIDAY, id: 5, selected: false },
      { label: 'Sa', key: DayOfWeek.SATURDAY, id: 6, selected: false },
    ];

    const res = days
      .filter(({ key }) => arr.includes(key))
      .map((days) => days.label)
      .join(', ');

    return res;
  }
}
