import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ActiveLoadsTabsModel, LoadAvailableTabsModel, LoadDetailsModel, LoadModel } from '@haulynx/store';
import { Tab } from '@haulynx/types';
import { AddressPosition, aliveWhile, splitAddress } from '@haulynx/utils';
import { delay, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-completed-loads-container',
  templateUrl: './completed-loads-container.component.html',
  styleUrls: ['./completed-loads-container.component.scss'],
})
export class CompletedLoadsContainerComponent implements OnInit {
  alive = aliveWhile();
  loadId: string = null;

  constructor(
    public activeLoadsTabsModel: ActiveLoadsTabsModel,
    public availableTabs: LoadAvailableTabsModel,
    public loadModel: LoadModel,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loadDetailsModel: LoadDetailsModel
  ) {}

  selectTab({ index, tab }: { index: number; tab: Tab }) {
    this.activeLoadsTabsModel.selectTab(tab);
  }

  removeTab(tab: Tab) {
    this.activeLoadsTabsModel.removeTabs(tab);
    this.availableTabs.removeTabs(tab);
    this.loadModel.removeTabs({ id: tab.id });
    this.router.navigate(['/dashboard/loads/search/completed']);
  }

  ngOnInit(): void {
    const { id } = this.activatedRoute.snapshot.params;
    this.loadId = id;
    this.loadDetailsModel.form.state$.pipe(takeUntil(this.alive), delay(0)).subscribe((loadFeed) => {
      if (loadFeed && loadFeed.id === id) {
        const [pickUp, dropOff] = loadFeed.locations;

        const origin = splitAddress(pickUp && pickUp.address, AddressPosition.CITY, true);
        const destination = splitAddress(dropOff && dropOff.address, AddressPosition.CITY, true);
        const label = `${origin} → ${destination}`;

        const loadTab = new Tab({
          id,
          label,
          closable: true,
          hideLabel: false,
          selected: true,
        });
        this.activeLoadsTabsModel.addTabs([loadTab]);
      }
    });
    this.activeLoadsTabsModel.tabsSelected$.pipe(takeUntil(this.alive)).subscribe((tab) => {
      if (tab) {
        const { url, queryParams } = tab;
        this.router.navigate(url.split('/'), { queryParams, replaceUrl: true });
      }
    });
  }
  ngOnDestroy(): void {
    this.activeLoadsTabsModel.removeTabs({ id: this.loadId });
    this.alive.destroy();
  }
}
