import { createSelector } from '@ngrx/store';
import { getBookLoadState } from './book-load.state';

export const bookLoadSelector = createSelector(getBookLoadState, (state) => {
  return state.isLoading;
});

export const bookLoadHttpStatusSelector = createSelector(getBookLoadState, (state) => {
  return state.httpStatus;
});

export const isBookableSelector = createSelector(getBookLoadState, (state) => {
  return state.isBookable;
});
