import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { MomentTzPipe } from '@haulynx/pipes';
import { ContractsService, NotificationsService, TitleService, UserService } from '@haulynx/services';
import { AppModel, ContractsEntityService } from '@haulynx/store';
import { FreightContract, FreightContractInput, User } from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { ContractsFormComponent } from 'libs/components/src/lib/contracts-form/contracts-form.component';
import { BehaviorSubject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfirmationDialogComponent } from './contract-delete-modal/contract-delete-modal';

@Component({
  selector: 'app-contract-manage-container',
  templateUrl: './contract-manage-container.component.html',
  styleUrls: ['./contract-manage-container.component.scss'],
})
export class ContractManageContainerComponent implements OnInit, OnDestroy {
  searchForm: FormGroup;
  filters: { filterBy: string; searchValue: string }[] = [];
  public filterDisplayMap = {
    carrierDOT: 'Carrier DOT',
    billTo: 'Bill to',
    brokerOwner: 'Broker ID',
  };

  constructor(
    public contractsEntityService: ContractsEntityService,
    private contractsService: ContractsService,
    private dialog: MatDialog,
    private userService: UserService,
    private momentTzPipe: MomentTzPipe,
    private fb: FormBuilder,
    public appModel: AppModel,
    private titleService: TitleService,
    private notificationsService: NotificationsService
  ) {
    this.loading$ = this.contractsEntityService.contractsSearchManager.isSearching$;
    this.searchForm = this.fb.group({
      filterBy: ['carrierDOT'],
      searchValue: undefined,
    });
  }

  public data$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  user: User;
  userTimeZone: string;
  loading$: Observable<boolean>;
  pagination: any = {
    total: 1,
    currentPage: 1,
    limit: 10,
    totalPages: 1,
    sort: undefined,
    order: undefined,
  };
  paginationData$: BehaviorSubject<Partial<any>> = new BehaviorSubject<Partial<any>>(this.pagination);

  alive = aliveWhile();

  ngOnInit(): void {
    this.titleService.setTitle(`Contracts - Haulynx`);
    this.userService.user.pipe(takeUntil(this.alive)).subscribe((data) => {
      this.user = data;
    });
    this.appModel.userTimeZone$.pipe(takeUntil(this.alive)).subscribe((userTimeZone: string) => {
      this.userTimeZone = userTimeZone;
    });

    this.contractsEntityService.contractsSearchManager.dispatch({ query: {}, pageAndSort: this.pagination });
    this.contractsEntityService.contractsSearchManager.searchResults$
      .pipe(takeUntil(this.alive))
      .subscribe((result) => {
        if (result) {
          const dataRes = result.map((item) => {
            const createdAtFormatted = item?.createdAt
              ? this.momentTzPipe.transform(item?.createdAt, 'MM/DD/YY', this.userTimeZone, false, false)
              : '';

            return {
              customer0: { name: 'Shipper', primary: item?.customer0 },
              cityStateCustomer0: {
                name: 'Shipper City/State',
                primary: item?.customers?.shipper
                  ? item?.customers?.shipper?.city + ', ' + item?.customers?.shipper?.state
                  : '',
                sortable: false,
              },
              customer1: { name: 'Consignee', primary: item?.customer1 },
              cityStateCustomer1: {
                name: 'Consignee City/State',
                primary: item?.customers?.consignee
                  ? item?.customers?.consignee?.city + ', ' + item?.customers?.consignee?.state
                  : '',
                sortable: false,
              },
              billTo: { name: 'Bill To', primary: item?.billTo },
              billToName: { name: 'Bill To Name', primary: item?.customers?.billToCustomer?.name, sortable: false },
              brokerOwner: { name: 'Broker', primary: item?.brokerOwner },
              carrierName: { name: 'Carrier Name', primary: item?.carrier?.name ?? '', sortable: false },
              carrierDOT: { name: 'Carrier DOT', primary: item?.carrierDOT },
              price: {
                name: 'Carrier Rate',
                primary: item?.price
                  ? item?.price?.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    })
                  : '-',
              },
              lineHaulPrice: {
                name: 'Linehaul Price / Basis',
                primary: item?.lineHaulPrice?.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                }),
                secondary: item?.lineHaulBasis,
              },
              fuelPrice: {
                name: 'Fuel Price / Basis',
                primary: item?.fuelPrice?.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                }),
                secondary: item?.fuelBasis,
              },

              stopPrice: {
                name: 'Stop Price / Basis',
                primary: item?.stopPrice?.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                }),
                secondary: item?.stopBasis,
              },
              appointmentSetRequiredCustomer0: {
                name: 'Shipper Appt. Required',
                primary: item?.appointmentSetRequiredCustomer0 ? 'YES' : 'NO',
              },
              appointmentSetRequiredCustomer1: {
                name: 'Consignee Appt. Required',
                primary: item?.appointmentSetRequiredCustomer1 ? 'YES' : 'NO',
              },
              createdAt: { name: 'Created At', primary: createdAtFormatted },
              createdBy: { name: 'Created By', primary: item?.createdBy },
            };
          });
          this.data$.next(dataRes);
        }
      });
    this.contractsEntityService.contractsSearchManager.searchPaginationResults$
      .pipe(takeUntil(this.alive))
      .subscribe((pagination) => {
        this.pagination = {
          ...pagination,
          limit: this.pagination.limit,
          page: this.pagination.currentPage ?? 1,
          sort: this.pagination?.sort,
          order: this.pagination?.order,
        };
        this.paginationData$.next({
          ...pagination,
          limit: this.pagination.limit,
          page: this.pagination.currentPage ?? 1,
        });
      });
  }

  createClicked() {
    this.dialog
      .open(ContractsFormComponent, {
        height: '94%',
        maxHeight: '825px',
        panelClass: 'contracts-form-modal',
        maxWidth: '448px',
        disableClose: false,
      })
      .beforeClosed()
      .subscribe((returned) => {
        if (returned?.combined) {
          const contract: FreightContractInput = {
            customer0: returned.combined.shipper,
            customer1: returned.combined.consignee,
            carrierDOT: returned.combined.carrier,
            billTo: returned.combined.billto,
            price: isNaN(parseFloat(returned.combined.carrierRate))
              ? undefined
              : parseFloat(returned.combined.carrierRate),
            brokerOwner: returned.combined?.broker,
            lineHaulPrice: isNaN(parseFloat(returned.combined?.lineHaulPrice))
              ? undefined
              : parseFloat(returned.combined?.lineHaulPrice),
            lineHaulBasis: returned.combined?.lineHaulBasis,
            fuelPrice: isNaN(parseFloat(returned.combined?.fuelPrice))
              ? undefined
              : parseFloat(returned.combined?.fuelPrice),
            fuelBasis: returned.combined?.fuelBasis,
            stopPrice: isNaN(parseFloat(returned.combined?.stopPrice))
              ? undefined
              : parseFloat(returned.combined?.stopPrice),
            stopBasis: returned.combined?.stopBasis,
            appointmentSetRequiredCustomer0: returned.combined?.appointmentSetRequiredCustomer0,
            appointmentSetRequiredCustomer1: returned.combined?.appointmentSetRequiredCustomer1,
            userId: this.user?.id,
          };

          Object.keys(contract).forEach((key) => {
            if (contract[key] === undefined || contract[key] === null) {
              delete contract[key];
            }
          });

          if (!returned?.isEdit?.getValue()) {
            this.contractsService.createFreightContract(contract).subscribe((result) => {
              if (result)
                this.contractsEntityService.contractsSearchManager.dispatch({
                  query: {},
                  pageAndSort: { ...this.pagination, page: this.pagination.currentPage ?? 1 },
                });
            });
          }
        }
      });
  }

  editClicked(row: any) {
    this.dialog
      .open(ContractsFormComponent, {
        height: '92%',
        maxHeight: '800px',
        panelClass: 'contracts-form-modal',
        disableClose: false,
        data: { contract: { ...row } },
      })
      .beforeClosed()
      .subscribe((returned) => {
        if (returned?.combined) {
          const contract: FreightContractInput = {
            customer0: returned.combined.shipper?.value ?? returned.combined.shipper,
            customer1: returned.combined.consignee?.value ?? returned.combined.consignee,
            carrierDOT: returned.combined.carrier,
            billTo: returned.combined.billto?.value ?? returned.combined.billto,
            price: isNaN(parseFloat(returned.combined.carrierRate))
              ? undefined
              : parseFloat(returned.combined.carrierRate),
            brokerOwner: returned.combined?.broker,
            lineHaulPrice: isNaN(parseFloat(returned.combined?.lineHaulPrice))
              ? undefined
              : parseFloat(returned.combined?.lineHaulPrice),
            lineHaulBasis: returned.combined?.lineHaulBasis,
            fuelPrice: isNaN(parseFloat(returned.combined?.fuelPrice))
              ? undefined
              : parseFloat(returned.combined?.fuelPrice),
            fuelBasis: returned.combined?.fuelBasis,
            stopPrice: isNaN(parseFloat(returned.combined?.stopPrice))
              ? undefined
              : parseFloat(returned.combined?.stopPrice),
            stopBasis: returned.combined?.stopBasis,
            appointmentSetRequiredCustomer0: returned.combined?.appointmentSetRequiredCustomer0,
            appointmentSetRequiredCustomer1: returned.combined?.appointmentSetRequiredCustomer1,
            userId: this.user?.id,
          };

          Object.keys(contract).forEach((key) => {
            if (contract[key] === undefined || contract[key] === null) {
              delete contract[key];
            }
          });
          if (returned?.isEdit?.getValue()) {
            this.contractsService.updateFreightContract(contract).subscribe((result) => {
              if (result) {
                const searchQuery = this.filters.reduce((query, filter) => {
                  query[filter.filterBy] = filter.searchValue;
                  return query;
                }, {});
                this.contractsEntityService.contractsSearchManager.dispatch({
                  query: searchQuery,
                  pageAndSort: { ...this.pagination, page: this.pagination.currentPage ?? 1 },
                });
              }
            });
          }
        }
      });
  }

  deleteClicked(row: any) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { billToNumber: row.billTo.primary, consignee: row.customer1.primary, shipper: row.customer0.primary },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const searchQuery = this.filters.reduce((query, filter) => {
          query[filter.filterBy] = filter.searchValue;
          return query;
        }, {});
        this.contractsService
          .deleteFreightContract(row.customer0.primary, row.customer1.primary, row.billTo.primary)
          .subscribe((result) => {
            if (result) {
              this.notificationsService.success('Contract deleted successfully', 'Success');

              this.contractsEntityService.contractsSearchManager.dispatch({
                query: searchQuery,
                pageAndSort: { ...this.pagination, page: this.pagination.currentPage ?? 1 },
              });
            } else {
              this.notificationsService.error('Contract could not be deleted', 'Error');
            }
          });
      }
    });
  }

  pageChanged(event: boolean) {
    if (event) {
      this.contractsEntityService.contractsSearchManager.goToPage(undefined, this.pagination.limit);
    } else {
      this.contractsEntityService.contractsSearchManager.goToPage(-1, this.pagination.limit);
    }
  }

  rowLimitChange(event: number) {
    this.pagination = { limit: event, page: 1, order: this.pagination?.order, sort: this.pagination?.sort };
    this.contractsEntityService.contractsSearchManager.dispatch({
      query: {},
      pageAndSort: { ...this.pagination, page: 1 },
    });
  }

  refreshClicked() {
    this.contractsEntityService.contractsSearchManager.goToPage(
      this.pagination.currentPage ?? 1,
      this.pagination.limit
    );
  }

  sortChanged(event: Sort) {
    this.contractsEntityService.contractsSearchManager.dispatch({
      query: {},
      pageAndSort: { ...this.pagination, page: 1, order: event.active, sort: event.direction },
    });

    this.pagination = { ...this.pagination, order: event.active, sort: event.direction };
  }

  addFilter(): void {
    const filter = this.searchForm.value;

    // Make sure the filter value isn't undefined, blank, or just whitespace
    if (!filter.searchValue || filter.searchValue.trim() === '') {
      return; // Don't do anything if the filter is invalid
    }

    const index = this.filters.findIndex((item) => {
      return item?.filterBy === filter.filterBy;
    });

    if (index >= 0) {
      // If the filterBy field already exists, replace it with the new filter
      this.filters[index] = filter;
    } else {
      // If the filterBy field does not exist, add the new filter
      this.filters.push(filter);
    }

    this.searchForm.patchValue({ searchValue: undefined });

    this.searchContracts();
  }

  removeFilter(filter): void {
    const index = this.filters.indexOf(filter);

    if (index >= 0) {
      this.filters.splice(index, 1);
    }

    this.searchContracts();
  }

  searchContracts(): void {
    // Generate the query object from the filters array
    const searchQuery = this.filters.reduce((query, filter) => {
      query[filter.filterBy] = filter.searchValue;
      return query;
    }, {});

    // Dispatch the search action
    this.contractsEntityService.contractsSearchManager.dispatch({
      query: searchQuery,
      pageAndSort: { ...this.pagination, page: 1 },
    });
  }

  ngOnDestroy() {
    this.titleService.setTitle(`Haulynx`);
  }
}
