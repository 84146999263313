export class SendLogsPayload {
  fromId: string;
  fromName: string;
  fromEmail: string;
  comment: string;
  email: string;
  method: string;
  sendFromCloud: boolean;
  imei: string;
  carrierId: string;
  carrierName: string;
  carrierDOT: string;
  startDate: string;
  endDate: string;
  daysAgo: number;
  fromWeb: boolean;
}

export class CarrierLog extends SendLogsPayload {
  gpsLatLon: string;
  engineHours: number;
  odometer: number;
  trailerId: string;
  billOfLading: string;
}
