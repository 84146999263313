import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { IAsyncSearchResultsPaginationState, PageAndSort, VirtualColumn } from '@haulynx/types';
import { keyBy } from 'lodash';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';

@Component({
  selector: 'haulynx-virtual-table',
  templateUrl: './virtual-table.component.html',
  styleUrls: ['./virtual-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VirtualTableComponent implements OnChanges {
  @Input() data: any[];
  @Input() columns: VirtualColumn[];
  @Input() defaultColumnWidth: number = 100;
  @Input() defaultColumnHeight: number = 65;
  @Input() loading: boolean = true;
  @Input() headerHeight = 32;
  @Input() stickyHeader = true;
  @Input() customCellReference: TemplateRef<any>;
  @Input() noDataReference: TemplateRef<any>;
  @Input() pagination: IAsyncSearchResultsPaginationState;
  @Input() rowLimitOptions: number[] = [25, 50, 100, 200];
  @Input() set searchState(state) {
    this.pageSortState = state?.pageSort;
  }
  @Input() hideRowLimit = false;

  @Output() refresh = new EventEmitter();
  @Output() rowLimitChange = new EventEmitter();
  @Output() pageChange = new EventEmitter();
  @Output() getSortedData = new EventEmitter();
  @Output() rowClick = new EventEmitter();

  dataSource = new TableVirtualScrollDataSource(new Array(25).fill({}));
  columnLabels: string[] = [];
  paginationPageView: string = null;
  pageSortState: PageAndSort;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    const { columns = null, data = null, loading = null, pagination = null } = changes;

    if (loading?.currentValue) {
      this.pagination = null;
      this.paginationPageView = null;
      this.dataSource = new TableVirtualScrollDataSource(new Array(25).fill({}));
    }

    if (columns?.currentValue) {
      this.columnLabels = Object.keys(keyBy(columns.currentValue, 'label'));
    }

    if ((!this.loading || (loading && !loading?.currentValue)) && data?.currentValue) {
      this.dataSource = new TableVirtualScrollDataSource(data.currentValue);
    }

    if (pagination?.currentValue?.total && loading && !loading?.currentValue) {
      const page: IAsyncSearchResultsPaginationState = pagination?.currentValue;
      const totalData = page?.total;
      const limit = this.pageSortState?.limit ?? 25;

      const lowerLimitText = limit * (page?.currentPage - 1) + 1;
      const upperLimit = limit * (page?.currentPage - 1) + limit;

      const upperLimitText =
        page.totalPages === 1
          ? totalData
          : totalData < limit
          ? totalData
          : totalData < upperLimit
          ? totalData
          : upperLimit;

      this.paginationPageView = `${lowerLimitText}-${upperLimitText} of ${totalData}`;
    }
  }

  public getDataSorted(pageSortData): void {
    this.getSortedData.emit(pageSortData);
  }

  public limitChanged(event) {
    this.rowLimitChange.emit({ ...this.pageSortState, limit: event.value, page: 1 });
  }

  public nextPageClicked() {
    this.pageChange.emit({ ...this.pageSortState, page: this.pagination?.currentPage + 1 });
  }

  public previousPageClicked() {
    this.pageChange.emit({ ...this.pageSortState, page: this.pagination?.currentPage - 1 });
  }

  public refreshClicked() {
    this.refresh.emit();
  }

  public rowClicked(row: any) {
    this.rowClick.emit(row);
  }

  public trackByFn(index: number, item: { id?: string }): string | number {
    return (item && item.id) || index;
  }
}
