import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from '@haulynx/directives';
import { HoverDropDownComponent } from './hover-drop-down.component';

@NgModule({
  declarations: [HoverDropDownComponent],
  exports: [HoverDropDownComponent],
  imports: [RouterModule, CommonModule, DirectivesModule],
})
export class HoverDropDownModule {}
