<div class="interchange-agreement-info">
  <div class="row">
    <div class="col l12">
      <p>
        <b>CARRIER REGISTRATION – INTERCHANGE AGREEMENT</b><br /><br />
        Please Review the Interchange Agreement Instructions below:
        <br /><br />
        <b>Minimum Coverage Requirements</b>
        <br /><br />
        General liability coverage for bodily injury and property damage in amounts no less than $1,000,000 (US) per
        occurrence; Auto liability coverage in amounts no less than $1,000,000 (US) per occurrence; Cargo liability
        insurance coverage in amounts no less than $100,000 (US); Physical damage coverage (including blanket owned and
        non-owned equipment) and Trailer interchange coverage, each with limits of liability sufficient to cover the
        full replacement value of trailers transported under the Agreement, but in no event less than $40,000 (US); USER
        shall provide OWNER with certificates of insurance naming OWNER as an additional insured for the general, auto,
        cargo liability, and physical damage policies.
        <br /><br />
      </p>
    </div>
  </div>
</div>
