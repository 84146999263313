import { createSelector } from '@ngrx/store';
import { getRouterState } from '../../main-store/app.reducers';
import { loadAdapter, loadOffersSearchAdapter } from './adapters';
import { getLoadState } from './load.state';

const getTabsState = createSelector(getLoadState, (state) => state.tabs);

const getLoadActiveTabsState = createSelector(getLoadState, (state) => state.loadActiveTabs);

const getLoadAvailableTabsState = createSelector(getLoadState, (state) => state.loadAvailableTabs);

export const isLoadingSelector = createSelector(getLoadState, (state) => state.isLoading);

export const selectCarrierSelector = createSelector(getLoadState, (state) => state.selectedCarrier);

export const selectUrlSelector = createSelector(getRouterState, (router) => router.state.url);

export const isLoadingConfirmDeliverySelector = createSelector(getLoadState, (router) => router.isLoadingConfirm);

export const loadTabsSelector = loadAdapter.tabs.getSelectors(getTabsState);
export const loadActiveTabsSelector = loadAdapter.tabs.getSelectors(getLoadActiveTabsState);

export const loadAvailableTabsSelector = loadAdapter.tabs.getSelectors(getLoadAvailableTabsState);

export const getIsLoadActiveLoading = createSelector(getLoadState, (state) => {
  return state.isLoadActiveLoading;
});

export const getLoadActiveHttpStatus = createSelector(getLoadState, (state) => {
  return state.loadActiveHttpStatus;
});

const getLoadOffersState = createSelector(getLoadState, (state) => state.loadOffers);

export const loadOffersSearchSelector = loadOffersSearchAdapter.loadOffers.getSelectors(getLoadOffersState);

export const isNotesButtonVisible = createSelector(getLoadState, (state) => {
  return state.showNotesButton;
});
