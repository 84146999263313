import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isAvailable',
})
export class IsAvailablePipe implements PipeTransform {
  transform(value: number | string): number | string {
    return value ? value : 'N/A';
  }
}
