import gql from 'graphql-tag';

export const CreateDevicesWithImei = gql`
  mutation createDevicesWithImei($devices: [DeviceInput!]!, $userId: StrictId!) {
    createDevicesWithImei(devices: $devices, userId: $userId) {
      carrier {
        id
        name
      }
      token
      vin
      unitId
      make
      model
      year
      imei
      type
    }
  }
`;
