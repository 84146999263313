import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrokerInfoRoutingModule } from './broker-info-routing.module';
import { BrokerInfoComponent } from './broker-info.component';

@NgModule({
  declarations: [BrokerInfoComponent],
  imports: [CommonModule, BrokerInfoRoutingModule],
})
export class BrokerInfoModule {}
