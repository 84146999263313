import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoadFeedFireDatabaseService, FireDatabaseService } from '@haulynx/services';

@Component({
  selector: 'app-carrier',
  styleUrls: ['./carrier.component.scss'],
  templateUrl: './carrier.component.html',
})
export class CarrierComponent implements OnDestroy, OnDestroy, OnInit {
  constructor(
    private loadFeedFireDatabaseService: LoadFeedFireDatabaseService,
    private fireDatabaseService: FireDatabaseService
  ) {}

  ngOnDestroy(): void {
    this.loadFeedFireDatabaseService.database.goOffline();
    this.fireDatabaseService.database.goOffline();
  }

  ngOnInit(): void {
    this.fireDatabaseService.database.goOnline();
    this.loadFeedFireDatabaseService.database.goOnline();
  }
}
