export interface ActiveLoadStatusType {
  id: string;
  text: string;
  icon: string;
}

export class ActiveLoadStatus {
  UNASSIGNED: ActiveLoadStatusType;
  ASSIGNED: ActiveLoadStatusType;
  DISPATCHED: ActiveLoadStatusType;
  AT_SHIPPER: ActiveLoadStatusType;
  PICKED_UP: ActiveLoadStatusType;
  AT_RECEIVER: ActiveLoadStatusType;
  DELIVERED: ActiveLoadStatusType;
  FINALLED: ActiveLoadStatusType;
  DELETED: ActiveLoadStatusType;
}

export const activeLoadsStatuses: ActiveLoadStatus = {
  UNASSIGNED: { id: 'unassigned', text: 'Unassigned', icon: 'pending' },
  ASSIGNED: { id: 'assigned', text: 'Assigned', icon: 'assigned' },
  DISPATCHED: { id: 'dispatched', text: 'Dispatched', icon: 'dispatched-icon' },
  AT_SHIPPER: { id: 'at-shipper', text: 'At Pickup', icon: 'at-shipper-icon' },
  PICKED_UP: { id: 'picked-up', text: 'In Transit', icon: 'in-transit-icon' },
  AT_RECEIVER: { id: 'at-receiver', text: 'At Dropoff', icon: 'at-receiver-icon' },
  DELIVERED: { id: 'delivered', text: 'Delivered', icon: 'haulynx-checked' },
  FINALLED: { id: 'finalled', text: 'Finalled', icon: 'finalled-icon' },
  DELETED: { id: 'deleted', text: 'Deleted', icon: 'haulynx-closed' },
};
