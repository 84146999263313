import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ActiveLoadsModel } from './active-loads-model';
import { ActiveLoadsStateEffects } from './active-loads-state.effects';
import { activeLoadsStateReducer } from './active-loads-state.reducer';
import { ActiveLoadsTabsModel } from './active-loads-tabs-model';

@NgModule({
  imports: [
    StoreModule.forFeature('active-loads', activeLoadsStateReducer),
    EffectsModule.forFeature([ActiveLoadsStateEffects]),
  ],
  providers: [ActiveLoadsModel, ActiveLoadsTabsModel, ActiveLoadsStateEffects],
})
export class ActiveLoadsStateModule {}
