import { Pipe, PipeTransform } from '@angular/core';
import { LoadsServiceLoadLocation } from '@haulynx/types';
import { getLoadsServiecLoadTimeWaitStatus } from '@haulynx/utils';

@Pipe({ name: 'loadLocationWaitTimeStatus' })
export class LoadLocationWaitTimeStatusPipe implements PipeTransform {
  transform(location: LoadsServiceLoadLocation): string {
    return getLoadsServiecLoadTimeWaitStatus(location);
  }
}
