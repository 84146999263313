import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PostTruckService } from '@haulynx/services';
import { AppModel, CarrierDashboardModel } from '@haulynx/store';
import { IColumns, PostTruckForm, User } from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { takeUntil } from 'rxjs/operators';
import { CarrierTruckFormComponent } from '../carriers/carrier-truck-form/carrier-truck-form.component';
import { ConfirmationComponent } from '@haulynx/services';

@Component({
  selector: 'app-posted-trucks',
  templateUrl: './posted-trucks.component.html',
  styleUrls: ['./posted-trucks.component.scss'],
})
export class PostedTrucksComponent implements OnChanges, OnInit, OnDestroy {
  @Input() dot = '';
  alive = aliveWhile();
  query: {
    lanesMatches?: string[];
    truckZoneMatches?: any[];
    truckIdsMatches?: string[];
    filterByLanes?: boolean;
    filterByTrucks?: boolean;
    allTrucksSelected?: boolean;
    allLanesSelected?: boolean;
  } = {};
  truckColumns: IColumns[] = [
    {
      label: 'Location',
      dataIndex: 'location',
      isCustomCell: true,
    },
    {
      label: 'Destination',
      dataIndex: 'preferredLocation',
      isCustomCell: true,
    },
    {
      label: 'Equipment',
      dataIndex: 'equipmentType',
      isCustomCell: true,
    },
    {
      label: 'Available',
      dataIndex: 'timeAvailable',
      isCustomCell: true,
    },
    {
      label: '',
      dataIndex: 'options',
      isCustomCell: true,
      width: '20px',
    },
  ];
  user: User;

  constructor(
    public dashboardCarrierModel: CarrierDashboardModel,
    private dialog: MatDialog,
    private appModel: AppModel,
    private postTruckService: PostTruckService
  ) {}

  ngOnInit() {
    this.appModel.user$.pipe(takeUntil(this.alive)).subscribe((user) => (this.user = user));
  }

  ngOnChanges(changes: SimpleChanges) {
    const { dot } = changes;
    if (dot.currentValue) {
      this.dashboardCarrierModel.searchTrucks({ query: { dot: dot.currentValue } });
    }
  }

  ngOnDestroy() {
    this.alive.destroy();
  }

  addTruck(event) {
    const dialogRef = this.dialog.open(CarrierTruckFormComponent);

    dialogRef.afterClosed().subscribe((trucks: PostTruckForm[]) => {
      if (trucks) {
        const newPostedTrucks = trucks.map((truck) => this.postTruckService.toDto(truck, this.dot, this.user));

        this.dashboardCarrierModel.createPostedTruck(newPostedTrucks);
      }
    });
  }

  removeTruck(event: Event, row) {
    event.stopPropagation();
    const { id } = row;
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: {
        title: 'Delete Truck',
        message: 'Are you sure you want to delete this truck?',
        width: '350px',
        deny: { text: 'No', hide: false },
      },
    });

    dialogRef.afterClosed().subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.dashboardCarrierModel.removePostedTruck({ id });
      }
    });
  }
}
