import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AssignLoadCarrierContainerComponent } from './assign-load-carrier-container.component';

const routes: Routes = [{ path: '', component: AssignLoadCarrierContainerComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AssignLoadCarrierContainerRoutingModule {}
