import { Injectable } from '@angular/core';
import { CarrierInfoSectionForm, LoadForm, LoadFormService } from '@haulynx/services';
import {
  AssignDriverForm,
  AssignLoadCarrierForm,
  AssignmentForm,
  DispatchForm,
  DriverAssignment,
  LoadsServiceLoad,
  LoadStep,
  loadStepType,
  LocationForm,
  TrailerAssignment,
  TruckAssignment,
} from '@haulynx/types';
import { buildLoadSteps, getNextInactiveStep, getNextSteps, getSkippedStep } from '@haulynx/utils';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { isEqual, isNumber, isString } from 'lodash';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map, pairwise } from 'rxjs/operators';
import { AppState } from '../../main-store/app.reducers';
import { formModel } from '../../shared/store/form/model';
import { LoadDetailsActions, LoadDetailsActionTypes } from './load-details.actions';
import {
  activeStepIdSelector,
  deviceLocationsSelector,
  driverToPopulateSelector,
  isLoadingCreateEntitySelector,
  isLoadingRoutesSelector,
  loadDetailsSelector,
  routesSelector,
  trailerToPopulateSelector,
  truckToPopulateSelector,
} from './load-details.selectors';

@Injectable({
  providedIn: 'root',
})
export class LoadDetailsModel {
  form = formModel(this.store, loadDetailsSelector);
  state$: Observable<LoadsServiceLoad> = this.form.state$.pipe(map((load: LoadsServiceLoad) => load));
  updatedDetails$ = this.state$.pipe(
    pairwise(),
    filter(([previous, next]) => previous && next && next.id === previous.id && !isEqual(next, previous)),
    map(([previous, next]) => next)
  );
  routes$: Observable<unknown> = this.store.select(routesSelector);
  isLoadingRoutes$: Observable<unknown> = this.store.select(isLoadingRoutesSelector);
  isLoadingCreateEntity$: Observable<boolean> = this.store.select(isLoadingCreateEntitySelector);
  deviceLocations$: Observable<unknown> = this.store.select(deviceLocationsSelector);
  steps$: Observable<LoadStep[]> = this.state$.pipe(
    map((load) => {
      if (!load) {
        return [];
      }

      return buildLoadSteps(load, [loadStepType.ASSIGNED, loadStepType.DISPATCHED]);
    })
  );
  nextSkippedStep$ = this.steps$.pipe(map((steps) => getSkippedStep(steps)));
  nextActiveStep$ = this.steps$.pipe(map((steps) => getNextInactiveStep(steps)));
  nextSteps$ = this.steps$.pipe(map((steps) => getNextSteps(steps)));
  activeStep$: Observable<LoadStep> = combineLatest([this.store.select(activeStepIdSelector), this.steps$]).pipe(
    map(([activeStepId, steps]) => {
      if (isNumber(activeStepId) || isString(activeStepId)) {
        return steps.find((step) => step.id === activeStepId);
      }

      return getNextInactiveStep(steps);
    })
  );
  isSelected$: Observable<boolean> = this.state$.pipe(map((load: LoadsServiceLoad): boolean => !!load));
  carrierId$: Observable<string> = this.state$.pipe(
    map((load: LoadsServiceLoad) => load && load.carrier && load.carrier.id)
  );
  id$: Observable<string> = this.state$.pipe(map((load: LoadsServiceLoad) => load && load.id));
  assignDriverForm$: Observable<AssignDriverForm> = this.state$.pipe(
    map((load: LoadsServiceLoad) => this.loadFormService.getAssignDriverForm(load))
  );
  dispatchForm$: Observable<DispatchForm> = combineLatest([this.state$, this.activeStep$]).pipe(
    map(([load, loadStep]: [LoadsServiceLoad, LoadStep]): DispatchForm => this.loadFormService.getDispatchForm(load))
  );

  locationForm$: Observable<LocationForm> = combineLatest([this.state$, this.activeStep$]).pipe(
    map(([load, loadStep]: [LoadsServiceLoad, LoadStep]): LocationForm => {
      if (loadStep && isNumber(loadStep.id) && load) {
        const location = load.locations[loadStep.id];
        return this.loadFormService.getLocationForm(location);
      }
    })
  );

  nextStepTitle$: BehaviorSubject<string> = new BehaviorSubject<string>(null).pipe(
    map((stepType) => this.getStepTitle(stepType))
  ) as BehaviorSubject<string>;

  onCarrierBookSuccess$: Observable<unknown> = this.actions$.pipe(
    ofType(LoadDetailsActionTypes.BOOK_LOAD_FOR_CARRIER_SUCCESS)
  );
  onCarrierBookError$: Observable<unknown> = this.actions$.pipe(
    ofType(LoadDetailsActionTypes.BOOK_LOAD_FOR_CARRIER_ERROR)
  );

  onDispatchDetailLoadFinally$: Observable<unknown> = this.actions$.pipe(
    ofType(LoadDetailsActionTypes.DISPATCH_DETAIL_LOAD_SUCCESS, LoadDetailsActionTypes.DISPATCH_DETAIL_LOAD_ERROR)
  );
  onAssignmentToDriverSucess$: Observable<unknown> = this.actions$.pipe(
    ofType(LoadDetailsActionTypes.ASSIGNMENT_TO_DRIVER_SUCCESS)
  );
  onAssignmentToDriverError$: Observable<unknown> = this.actions$.pipe(
    ofType(LoadDetailsActionTypes.ASSIGNMENT_TO_DRIVER_ERROR)
  );

  driverToPopulate$: Observable<string> = this.store.select(driverToPopulateSelector);
  truckToPopulate$: Observable<string> = this.store.select(truckToPopulateSelector);
  trailerToPopulate$: Observable<string> = this.store.select(trailerToPopulateSelector);

  onActiveLoadSuccesses$: Observable<unknown> = this.actions$.pipe(
    ofType(
      LoadDetailsActionTypes.DISPATCH_DETAIL_LOAD_SUCCESS,
      LoadDetailsActionTypes.CONFIRM_LOCATION_SUCCESS,
      LoadDetailsActionTypes.ASSIGN_TO_DRIVER_SUCCESS
    )
  );

  onCreateTrailerSuccess$: Observable<unknown> = this.actions$.pipe(
    ofType(LoadDetailsActionTypes.CREATE_TRAILER_SUCCESS)
  );

  onCreateDriverSuccess$: Observable<unknown> = this.actions$.pipe(
    ofType(LoadDetailsActionTypes.CREATE_DRIVER_SUCCESS)
  );

  onCreateTruckSuccess$: Observable<unknown> = this.actions$.pipe(ofType(LoadDetailsActionTypes.CREATE_TRUCK_SUCCESS));

  constructor(private store: Store<AppState>, private actions$: Actions, private loadFormService: LoadFormService) {}

  setLoad(data: { key: string; state: LoadsServiceLoad }): void {
    this.store.dispatch(LoadDetailsActions.setValue(data));
  }

  getLoadRoutes(data): void {
    this.store.dispatch(LoadDetailsActions.getRoutes(data));
  }

  getLoadRoute(data: { key: string; coordinates: string }): void {
    this.store.dispatch(LoadDetailsActions.getRoute(data));
  }

  showEmptyRoute(key): void {
    this.store.dispatch(LoadDetailsActions.getRouteSuccess({ key, geojson: null }));
  }

  clearRoutes(): void {
    this.store.dispatch(LoadDetailsActions.clearRoutes());
  }

  setStateKey(data: { key?: string; namespace?: string }): void {
    this.store.dispatch(LoadDetailsActions.setStateKey(data));
  }

  get(data: { key: string; id: string; source?: string; isLatest?: boolean }): void {
    this.store.dispatch(LoadDetailsActions.get(data));
  }

  refresh(data: { key: string; id: string; source?: string }): void {
    this.store.dispatch(LoadDetailsActions.refresh(data));
  }

  setFormMode(data): void {
    this.store.dispatch(LoadDetailsActions.selectMode(data));
  }

  saveTemplate(template: { key: string; load: Partial<LoadForm>; selectedEntities: { [key: string]: unknown } }): void {
    this.store.dispatch(LoadDetailsActions.saveTemplate(template));
  }

  update(load): void {
    this.store.dispatch(LoadDetailsActions.update(load));
  }

  getTruckLoadLocation(data: { loadId: string }): void {
    this.store.dispatch(LoadDetailsActions.getDeviceLocations(data));
  }

  assignToDriver(loadId: string, data: AssignDriverForm): void {
    this.store.dispatch(LoadDetailsActions.assignToDriver({ loadId, data }));
  }

  assignmentToDriver(
    loadId: string,
    assignment: Partial<AssignmentForm>,
    carrierContact: CarrierInfoSectionForm
  ): void {
    this.store.dispatch(LoadDetailsActions.assignmentToDriver({ loadId, assignment, carrierContact }));
  }

  dispatchDetailLoad(loadId: string, data: DispatchForm): void {
    this.store.dispatch(LoadDetailsActions.dispatchDetailLoad({ loadId, data }));
  }

  dispatchLoad(loadId: string, data: DispatchForm): void {
    this.store.dispatch(LoadDetailsActions.dispatch({ loadId, data }));
  }

  confirmLocation(loadId: string, locationId: number | string, data: LocationForm): void {
    this.store.dispatch(LoadDetailsActions.confirmLocation({ loadId, locationId, data }));
  }

  setActiveStep(data: { key: string; stepId: string | number }): void {
    this.store.dispatch(LoadDetailsActions.setActiveStep(data));
  }

  bookForCarrierLoad(loadId: string, dot: string, data: Partial<AssignLoadCarrierForm>, brokerId: string): void {
    this.store.dispatch(LoadDetailsActions.bookLoadForCarrier({ dot, loadId, data, brokerId }));
  }

  bounceCarrier(loadId: string, orderNumber: string): void {
    this.store.dispatch(LoadDetailsActions.bounceCarrier({ loadId, orderNumber }));
  }

  createDriver(driverAssignment: DriverAssignment): void {
    this.store.dispatch(LoadDetailsActions.createDriver(driverAssignment));
  }

  createTruck(truckAssignment: TruckAssignment): void {
    this.store.dispatch(LoadDetailsActions.createTruck(truckAssignment));
  }

  createTrailer(trailerAssignment: TrailerAssignment): void {
    this.store.dispatch(LoadDetailsActions.createTrailer(trailerAssignment));
  }

  private getStepTitle(stepType: string): string {
    if (!stepType) {
      return '';
    }

    if (stepType === loadStepType.ASSIGNED) {
      return 'Add driver info';
    }
    if (stepType === loadStepType.DISPATCHED) {
      return 'Dispatch';
    }
    if (stepType === loadStepType.PICKUP) {
      return 'Confirm pickup';
    }
    if (stepType === loadStepType.PICKUP_STOP) {
      return 'Confirm stop';
    }
    if (stepType === loadStepType.DROPOFF_STOP) {
      return 'Confirm stop';
    }
    if (stepType === loadStepType.DROPOFF) {
      return 'Confirm delivery';
    }
  }
}
