export interface ActiveBrokeredLoadsAsLoadsParams {
  brokerId?: string;
  customerName?: string;
  dot?: string;
  onlyActiveLoads?: boolean;
  onlyBookedLoads?: boolean;
  orderNumber?: string;
  pickupTimestamp?: number;
  region?: string;
}
