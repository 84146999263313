import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AppModel, CommonEntities, LoadEntityService, LoadModel } from '@haulynx/store';
import { LoadsServiceLoad, Tab, User } from '@haulynx/types';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { LoadViewService, NotificationsService } from '@haulynx/services';
import { List } from 'immutable';
import { listToArray } from '@haulynx/utils';

@Injectable()
export class VerifyCanEditLoadGuard implements CanActivate {
  constructor(
    private appModel: AppModel,
    private commonEntities: CommonEntities,
    private router: Router,
    private loadEntityService: LoadEntityService,
    private loadViewService: LoadViewService,
    private loadModel: LoadModel,
    private notificationsService: NotificationsService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.appModel.user$.pipe(
      withLatestFrom(this.commonEntities.graphQlDrivers.entities$),
      switchMap(([user, drivers]: [User, List<User>]) => {
        const loadId: string = route?.params?.loadId;
        let canEdit: boolean = loadId === 'new';
        const driversArray = listToArray(drivers);
        if (loadId && !canEdit) {
          this.loadEntityService.getLoadByIdManager.dispatch(loadId);
          return this.loadEntityService.getLoadByIdManager.getEntityById(loadId).pipe(
            filter((load) => load !== null),
            map((load: LoadsServiceLoad) => {
              canEdit = this.loadViewService.userCanEditLoad(driversArray, user, load);
              if (!canEdit) {
                const tab = new Tab({ id: load?.id });
                this.loadModel.removeTabs(tab);
                this.notificationsService.error(`Unauthorized to edit load: ${load?.id}`);
                this.router.navigate(['/dashboard/loads/search/active']);
              }
              return canEdit;
            })
          );
        }
        return of(canEdit);
      })
    );
  }
}
