import { User } from '../generic';

export interface CarrierApiCarrier {
  notes: CarrierApiNote[];
  brokerOwners: string[];
  id?: string;
  name?: string;
  mcInt?: string;
  dot?: string;
}

export interface CarrierApiNote {
  id: string;
  createdAt: string;
  createdAtTs: string;
  createdBy: string;
  createdByUser: User;
  updatedAt: string;
  updatedAtTs: number;
  updatedBy: string;
  content: string;
  type: string;
}

export interface CarrierApiNoteInput {
  content: string;
  type?: string;
}
