import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@haulynx/modules';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from '@haulynx/directives';
import { HeaderToolbarTabsComponent } from './header-toolbar-tabs.component';

@NgModule({
  declarations: [HeaderToolbarTabsComponent],
  imports: [CommonModule, DirectivesModule, MaterialModule, FlexLayoutModule, RouterModule],
  exports: [HeaderToolbarTabsComponent],
})
export class HeaderToolbarTabsModule {}
