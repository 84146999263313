import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadDocumentUploadDialogComponent } from './load-document-upload-dialog.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@haulynx/modules';
import { FileDragAndDropModule } from '../../file-drag-and-drop/file-drag-and-drop.module';
import { PipesModule } from '@haulynx/pipes';

@NgModule({
  declarations: [LoadDocumentUploadDialogComponent],
  imports: [CommonModule, MaterialModule, FlexLayoutModule, FileDragAndDropModule, PipesModule],
  exports: [LoadDocumentUploadDialogComponent],
})
export class LoadDocumentUploadDialogModule {}
