import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-load-activity-download',
  templateUrl: './load-activity-download.component.html',
  styleUrls: ['./load-activity-download.component.scss'],
})
export class LoadActivityDownloadComponent implements OnInit {
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: { title: string; text: string },
    @Optional() private dialogRef: MatDialogRef<LoadActivityDownloadComponent>
  ) {}

  ngOnInit(): void {}
}
