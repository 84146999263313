import gql from 'graphql-tag';

export const CREATE_FREIGHT_CONTRACT = gql`
  mutation CreateFreightContract($freightContract: FreightContractInput!) {
    createFreightContract(freightContract: $freightContract)
  }
`;

export const UPDATE_FREIGHT_CONTRACT = gql`
  mutation UpdateFreightContract($freightContract: FreightContractInput!) {
    updateFreightContract(freightContract: $freightContract)
  }
`;

export const DELETE_FREIGHT_CONTRACT = gql`
  mutation deleteFreightContract(
    $customer0: PositiveIntOrString!
    $customer1: PositiveIntOrString!
    $billTo: PositiveIntOrString!
  ) {
    deleteFreightContract(customer0: $customer0, customer1: $customer1, billTo: $billTo)
  }
`;
