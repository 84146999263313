import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GeocodingEntityService } from '@haulynx/store';
import { ISearchFilter, ISearchFilterType, LatLonInput, LoadSearchSideBarItem, PlaceInfo } from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { exclusionFilters } from 'libs/types/src/lib/loads-service/exclusion-filters';
import { find, findIndex, forEach } from 'lodash';
import { Observable } from 'rxjs';
import { takeUntil, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'app-search-chip-list',
  templateUrl: './search-chip-list.component.html',
  styleUrls: ['./search-chip-list.component.scss'],
})
export class SearchChipListComponent implements OnInit {
  @Input() selectedFilters: ISearchFilter[];
  @Input() sideBarSelection: LoadSearchSideBarItem;
  @Input() form: FormGroup;
  @Input() displayCloseButton = true;
  @Input() displayClearAllButton = true;
  @Input() showDaysDifference = false;

  @Output() removeSearchFilter: EventEmitter<ISearchFilter> = new EventEmitter();
  @Output() searchFilterFocus: EventEmitter<ISearchFilter> = new EventEmitter();
  @Output() searchFilterBlur: EventEmitter<ISearchFilter> = new EventEmitter();
  @Output() removeAllSearchFilters: EventEmitter<null> = new EventEmitter();

  alive = aliveWhile();

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    const { selectedFilters } = changes;

    if (selectedFilters?.currentValue?.some((val) => val.name === 'Bill To')) {
      this.selectedFilters = selectedFilters?.currentValue?.filter((item) => {
        return item.name !== 'Bill To' ? true : !exclusionFilters.map((val) => val.key).includes(item.keys['value']);
      });
    }
  }

  onRemoveSearchFilter(event: { filter: ISearchFilter; value: any }): void {
    this.removeSearchFilter.emit(event.filter);
  }

  onSearchFilterFocus(filter: ISearchFilter): void {
    this.searchFilterFocus.emit(filter);
  }

  onSearchFilterBlur(filter: ISearchFilter): void {
    this.searchFilterBlur.emit(filter);
  }

  onRemoveAllSearchFilters(): void {
    this.removeAllSearchFilters.emit();
  }
}
