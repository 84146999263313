import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ElementsModule } from '@haulynx/elements';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { BidVmService } from '@haulynx/services';
import { NgxCurrencyModule } from 'ngx-currency';
import { NgxMaskModule } from 'ngx-mask';
import { ButtonDropdownModule } from '../../../button-dropdown/button-dropdown.module';
import { DropDownModule } from '../../../drop-down/drop-down.module';
import { SpinnerModule } from '../../../spinner/spinner.module';
import { BidItemComponent } from './bid-item.component';

@NgModule({
  declarations: [BidItemComponent],
  imports: [
    CommonModule,
    MaterialModule,
    DropDownModule,
    NgxMaskModule.forRoot(),
    ReactiveFormsModule,
    SpinnerModule,
    PipesModule,
    NgxCurrencyModule,
    ElementsModule,
    ButtonDropdownModule,
  ],
  exports: [BidItemComponent],
  providers: [BidVmService],
})
export class BidItemModule {}
