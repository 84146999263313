<div class="action_button" [ngClass]="{ disabled: disabled }">
  <a mat-flat-button (click)="execute($event)" *ngIf="href" [href]="href">
    <mat-icon *ngIf="svgIcon || icon" [svgIcon]="svgIcon">{{ icon }}</mat-icon>
    <span>{{ label }}</span>
  </a>

  <a mat-flat-button (click)="execute($event)" *ngIf="!href">
    <mat-icon *ngIf="svgIcon || icon" [svgIcon]="svgIcon">{{ icon }}</mat-icon>
    <span>{{ label }}</span>
  </a>
</div>
