import {
  AsyncEntitySearchPayload,
  AsyncManagerType,
  IAsyncEntityState,
  PageAndSort,
  PaginatedData,
} from '@haulynx/types';
import { objectToUrlParams } from '@haulynx/utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, createAction, on, props, ReducerTypes } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { camelCase } from 'lodash';
import { iif, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { AsyncEntityActionFactory } from './async-entity-action-factory';

export class AsyncEntitySearchFactory<ActionPayloadType, EntityType, ErrorPayloadType> extends AsyncEntityActionFactory<
  ActionPayloadType,
  EntityType,
  ErrorPayloadType
> {
  public managerType = AsyncManagerType.SEARCH;
  public action = createAction(
    this.type,
    (payload: { query: ActionPayloadType; pageAndSort: Partial<PageAndSort> }) =>
      ({
        queryHash: objectToUrlParams(payload?.query),
        query: payload?.query,
        pageAndSort: payload?.pageAndSort,
      } as AsyncEntitySearchPayload<ActionPayloadType>)
  );
  public actionSuccess = createAction(
    this.typeSuccess,
    props<{ queryHash: string; payload: PaginatedData<EntityType> }>()
  );
  public actionError = createAction(this.typeError, props<{ queryHash: string; payload: ErrorPayloadType }>());

  private paginateType = `${this.type} paginate`;
  public paginate = createAction(this.paginateType, props<{ page?: number; limit?: number }>());

  constructor(
    namespace: string,
    name: string,
    sideEffect: (payload: {
      query: ActionPayloadType;
      pageAndSort: PageAndSort;
    }) => Observable<PaginatedData<EntityType>>
  ) {
    super(namespace, name, sideEffect);
  }

  public getReducers(): ReducerTypes<IAsyncEntityState<EntityType>, any>[] {
    return [
      on(this.action, (state, action) => {
        if (action.query && action.queryHash) {
          // allow for empty action payload to trigger a rerun
          const pageAndSort = action.pageAndSort
            ? { ...state[this.searchQueryKey]?.pageAndSort, ...action.pageAndSort }
            : { ...state[this.searchQueryKey]?.pageAndSort, page: 1 };
          return {
            ...(state as any),
            [this.isSearchingKey]: true,
            [this.searchQueryKey]: {
              queryHash: action.queryHash,
              payload: action.query,
              pageAndSort,
            },
          };
        } else {
          return {
            ...(state as any),
            [this.isSearchingKey]: true,
          };
        }
      }),
      on(this.actionSuccess, (state, action) => {
        return {
          ...(state as any),
          [this.isSearchingKey]: false,
          [this.searchResultsKey]: {
            ...state[this.searchResultsKey],
            [action.queryHash]: this.getSearchResultsForQuery(state as IAsyncEntityState<EntityType>, action),
          },
        };
      }),
      on(this.actionError, (state, action) => {
        return {
          ...(state as any),
          [this.isSearchingKey]: false,
        };
      }),
    ];
  }

  public createEffect(actions$: Actions, selectors: { [name: string]: Observable<unknown> }) {
    const actionEffect = (actn) =>
      of(actn).pipe(
        switchMap((action: AsyncEntitySearchPayload<ActionPayloadType> & Action) =>
          this.sideEffect({
            query: action.query,
            pageAndSort: action.pageAndSort,
          }).pipe(
            map((data: PaginatedData<EntityType>) =>
              this.actionSuccess({ queryHash: action.queryHash, payload: data })
            ),
            catchError((error: ErrorPayloadType) => {
              console.error(error);
              return of(this.actionError({ queryHash: action.queryHash, payload: error }));
            })
          )
        )
      );
    const paginateEffect = (
      actn: {
        page?: number;
        limit?: number;
      } & TypedAction<string>
    ) =>
      of(actn).pipe(
        withLatestFrom(
          selectors['searchQuery'] as Observable<{
            queryHash: string;
            payload: ActionPayloadType;
            pageAndSort: PageAndSort;
          }>
        ),
        map(([action, searchQuery]) =>
          this.action({
            query: searchQuery.payload,
            pageAndSort: {
              ...searchQuery.pageAndSort,
              page: this.choosePage(action.page, searchQuery.pageAndSort?.page),
              limit: action.limit || 25,
            },
          })
        )
      );
    return createEffect(() =>
      actions$.pipe(
        ofType(this.type, this.paginateType),
        mergeMap((action) => iif(() => action.type === this.type, actionEffect(action), paginateEffect(action)))
      )
    );
  }

  get searchResultsKey(): string {
    return camelCase(`${this.name} search results`);
  }

  get isSearchingKey(): string {
    return camelCase(`${this.name} is searching`);
  }

  get searchQueryKey(): string {
    return camelCase(`${this.name} search query`);
  }

  getInitialState<T>(): Partial<IAsyncEntityState<T>> {
    return {
      [this.searchResultsKey]: {},
      [this.isSearchingKey]: false,
      [this.searchQueryKey]: {
        queryHash: null,
        payload: null,
        pageAndSort: {
          page: 1,
          limit: 25,
        },
      },
    };
  }

  private choosePage(page: number, currentPage: number | undefined): number {
    switch (page) {
      case undefined:
      case null:
        return !currentPage ? 2 : currentPage + 1;
      case -1:
        return Math.max(1, currentPage - 1); // don't go lower than 1
      default:
        return page;
    }
  }

  private getSearchResultsForQuery(
    state: IAsyncEntityState<EntityType>,
    action: {
      queryHash: string;
      payload: PaginatedData<EntityType>;
    } & TypedAction<string> & {
        type: string;
      }
  ): {
    data: EntityType[][];
    total: number;
    currentPage: number;
    nextPage: number;
    previousPage: number;
    totalPages: number;
  } {
    const currentPage: number = state[this.searchQueryKey].pageAndSort?.page || 0;
    const currentSearchResultIndex: number = currentPage >= 1 ? currentPage - 1 : 0;
    const formerSearchResultsForQuery = state[this.searchResultsKey][action.queryHash]?.data || [];
    const searchResultsForQuery = [...formerSearchResultsForQuery];
    searchResultsForQuery[currentSearchResultIndex] = action.payload.data;
    return {
      data: searchResultsForQuery,
      total: action.payload.pagination?.total,
      currentPage,
      nextPage: action.payload.pagination?.nextPage,
      previousPage: action.payload.pagination?.previousPage,
      totalPages: action.payload.pagination?.totalPages,
    };
  }
}
