import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SimpleMessage } from '@haulynx/types';

@Component({
  selector: 'load-archive-complete-dialog',
  templateUrl: `./load-archive-complete.component.html`,
  styleUrls: ['./load-archive-complete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArchiveLoadCompleteDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SimpleMessage,
    public dialogRef: MatDialogRef<ArchiveLoadCompleteDialog>
  ) {}
}
