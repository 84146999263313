import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UserEntityService } from '@haulynx/store';
import { User, UserType } from '@haulynx/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserTypeGuard implements CanActivate {
  constructor(private router: Router, private userEntityService: UserEntityService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const userType: UserType = route.data?.userType || null;
    const redirectUrl = route.data?.redirectUrl || '';

    if (!userType) return true;

    return this.userEntityService.user$.pipe(
      map((user: User) => {
        if ((user.carrier && userType === UserType.CARRIER) || (user.broker && userType === UserType.BROKER))
          return true;

        this.router.navigate([redirectUrl]);
        return false;
      })
    );
  }
}
