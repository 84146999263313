<div class="load-details-container">
  <div *ngIf="sectionTitle" class="load_main_container__title">
    {{ sectionTitle }}
  </div>

  <div class="load-details" *ngIf="load">
    <div class="load-details__row">
      <div class="load-details__item">
        TMW #
        <br />
        <span class="content">
          {{ (load | loadAlternateId: loadIdentifierType.TMW_NUMBER) || 'N/A' }}
        </span>
      </div>
      <div class="load-details__item">
        Equipment type
        <br />
        <span class="content">
          {{ load.providerDetails | get: 'equipmentType':'N/A' | fromCamelCase }}
        </span>
      </div>
      <div class="load-details__item">
        HazMat
        <br />
        <span class="content">
          {{ (load | loadRestrictions: loadsServiceRestrictionTypes.HAZMAT) ? 'Yes' : 'No' }}
        </span>
      </div>
    </div>
    <div class="load-details__row">
      <div class="load-details__item">
        Customer
        <br />
        <span class="content">
          {{ load.locations | nth: 0 | get: 'customer.name':'N/A' }}
        </span>
      </div>
      <div class="load-details__item">
        Customer #
        <br />
        <span class="content">
          {{ load.locations | nth: 0 | get: 'customer.number':'N/A' }}
        </span>
      </div>
      <div class="load-details__item">
        Order #
        <br />
        <span class="content">
          {{ (load | loadAlternateId: loadIdentifierType.ORDER_NUMBER) || 'N/A' }}
        </span>
      </div>
    </div>
    <div class="load-details__row">
      <div class="load-details__item">
        Distance
        <br />
        <span class="content">
          <ng-container *ngIf="load?.paymentDetails?.distanceMiles | safeNumber; else noDistance">
            {{ load?.paymentDetails?.distanceMiles | safeNumber: '1.0-0' }}
          </ng-container>
          <ng-template #noDistance> Unknown </ng-template>
        </span>
      </div>
      <div class="load-details__item">
        Weight
        <br />
        <span class="content">
          {{ load.providerDetails?.weight || 'N/A' }}
        </span>
      </div>
      <div class="load-details__item" *ngIf="showAllNotesButton">
        <button mat-flat-button (click)="onViewAllNotes(load)" class="load-details__more" data-test="view-notes">
          View All Notes &nbsp;&nbsp;&nbsp;>
        </button>
      </div>
      <ng-container *appFeatures="featureFlag">
        <div *ngIf="load.carrier" class="load-details__item">
          Carrier
          <br />
          <span class="content">
            {{ load | get: 'carrier.name':'' }}
          </span>
        </div>
      </ng-container>
    </div>
    <div class="load-details__row">
      <div class="load-details__item">
        Price
        <br />
        <div class="currency content">
          <app-load-price
            [price]="load?.paymentDetails?.price"
            [loadId]="load.id"
            [showCents]="true"
            [currentMaxBuy]="maxBuy$ | async"
            [editablePrice]="showEditPriceButton"
            (priceChanged)="onPriceChanged($event)"
          >
          </app-load-price>
        </div>
      </div>
      <div class="load-details__item">
        RPM
        <br />
        <div class="currency content">
          {{ load.paymentDetails?.ratePerMile | currency: 'USD':'symbol' }}
        </div>
      </div>
      <ng-container *appFeatures="featureFlag">
        <div *ngIf="load.carrier" class="load-details__item">
          <button mat-button class="bounce-carrier" (click)="openBounceCarrierModal()">
            <span class="bounce-carrier__button">
              <mat-icon *ngIf="!loading">clear</mat-icon>
              <mat-spinner *ngIf="loading" [diameter]="20" [strokeWidth]="3"></mat-spinner>
              Bounce Carrier
            </span>
          </button>
        </div>
      </ng-container>
    </div>
    <div class="load-details__row">
      <div class="load-details__item"></div>
      <div class="load-details__item align-center">
        <button mat-raised-button (click)="openLoadDetails()">View more Load Details</button>
      </div>
      <div class="load-details__item">
        <mat-spinner *ngIf="isLoadingEditPaymentPrice; else showEditPaymentButton" [diameter]="24"></mat-spinner>
        <ng-template #showEditPaymentButton>
          <action-buttons
            [buttons]="editButtons$ | async"
            [display]="'column'"
            (onClick)="action($event)"
          ></action-buttons>
        </ng-template>
      </div>
    </div>
  </div>
</div>
