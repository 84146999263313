import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { keys, forEach } from 'lodash';

class AddressForm {
  address: string = null;
  address2: string = null;
  city: string = null;
  state: string = null;
  zip: string = null;

  constructor(controlConfig) {
    const key = keys(this);
    const defaultValue = [''];

    forEach(key, (prop) => {
      const control = (controlConfig && controlConfig[prop]) || defaultValue;

      this[prop] = control;
    });
  }
}

@Injectable()
export class AddressVm {
  addressConfig;

  constructor(private fg: FormBuilder) {
    this.addressConfig = {
      address: ['', Validators.required],
      address2: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zip: [''],
    };
  }

  create(): FormGroup {
    const address = new AddressForm(this.addressConfig);

    return this.fg.group(address);
  }
}
