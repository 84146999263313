import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NotificationEventType } from '@haulynx/types';
import { subDays } from 'date-fns';
import { forEach, keys } from 'lodash';

export class NotificationForm {
  eventType: NotificationEventType = null;
  recipient: string = null;
  pastDays: number = null;

  constructor(controlConfig?: unknown) {
    const key = keys(this);
    const defaultValue = [''];

    forEach(key, (prop) => {
      this[prop] = (controlConfig && controlConfig[prop]) || defaultValue;
    });
  }
}

@Injectable()
export class NotificationVmService {
  options;
  last7Days = subDays(new Date(), 7);

  constructor(private fb: FormBuilder) {
    this.options = {
      eventType: [null],
      recipient: [null],
      pastDays: [this.last7Days],
    };
  }

  create(values: Partial<NotificationForm> = {}): FormGroup {
    const form = new NotificationForm(this.options);
    const formGroup = this.fb.group(form);

    if (values) {
      formGroup.patchValue(values);
    }

    return formGroup;
  }
}
