import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CarrierService, DeviceEntityService } from '@haulynx/services';
import { AppEntityServices, AppModel } from '@haulynx/store';
import { Carrier, Device, User } from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { find, forEach, isNumber } from 'lodash';
import { map, takeUntil } from 'rxjs/operators';
import { EditDeviceDialog } from '../dialogs/devices/devices-edit/device-edit.component';
import { NewDeviceDialog } from '../dialogs/devices/devices-new/device-new.component';
import { ImeiUploadComponentComponent } from '../dialogs/imei-upload/imei-upload.component';
import { devicesColumns } from './devices.config';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss'],
})
export class DevicesComponent implements OnInit, OnDestroy {
  @ViewChild('dropdown') dropdown: ElementRef;
  @ViewChild('file') file: ElementRef;
  @Input() device: Device;
  alive = aliveWhile();
  carriers: Carrier[] = [];
  deviceForm = new FormGroup({
    carrierOrimei: new FormControl(null),
  });
  user: User;
  bulkUploadErrors = [];
  columns = devicesColumns;

  public deviceEntityService: DeviceEntityService;

  constructor(
    private carrierService: CarrierService,
    public snackBar: MatSnackBar,
    private dialog: MatDialog,
    private appModel: AppModel,
    private appEntityServices: AppEntityServices
  ) {
    this.deviceEntityService = this.appEntityServices.deviceEntityService;

    this.appModel.user$.pipe(takeUntil(this.alive)).subscribe((user) => {
      this.user = user;
    });
  }

  ngOnInit() {
    this.carrierService
      .getCarriers()
      .pipe(map((carriers) => [new Carrier({ name: 'Unassigned', id: null }), ...carriers]))
      .subscribe((carriers) => {
        this.carriers = carriers;
      });

    this.deviceForm.controls.carrierOrimei.valueChanges.pipe().subscribe((value) => {
      const submissionValue = !isNaN(+value) ? value : { id: value };
      this.onSubmit(submissionValue);
    });
  }

  generateFakeIMEI(device: Device): void {
    device.type = 'fake' + Math.floor(Math.random() * 10000000 + 1000000);
  }

  onSubmit(dropdownValue) {
    const carrierId = dropdownValue?.id;
    if (carrierId) {
      this.searchCarriers(carrierId);
    } else {
      this.searchDevicesByImei(dropdownValue);
    }
  }

  addDevice(): void {
    this.dialog
      .open(NewDeviceDialog, {
        data: this.carriers,
      })
      .afterClosed()
      .subscribe((deviceForm: { carrier: string; imeis: string[] }) => {
        if (deviceForm) {
          const devices = deviceForm.imeis.map((imei: string) => ({
            imei,
            carrierId: deviceForm.carrier,
            type: this.isTablet(imei) || this.isMdi(imei) || 'TELEMATICS',
          }));
          forEach(devices, (device) => {
            this.deviceEntityService
              .returnDeviceByImei(device.imei)
              .pipe(takeUntil(this.alive))
              .subscribe((devices) => {
                if (devices.data['getDeviceById']) {
                  this.deviceEntityService.updateDevice(device);
                } else {
                  this.deviceEntityService.createDevice(this.user.id, [device]);
                }
              });
          });
        }
      });
  }

  editDevice(device: Device) {
    const ref = this.dialog.open(EditDeviceDialog, {
      data: { device, carriers: this.carriers },
    });
    ref.afterClosed().subscribe((editedDevice: Device) => {
      if (editedDevice) {
        Object.keys(editedDevice).forEach(
          (key) => (editedDevice[key] = editedDevice[key] === '' ? null : editedDevice[key])
        );
        this.deviceEntityService.updateDevice(editedDevice);
      }
    });
  }

  searchCarriers(carrierId: string): void {
    this.deviceEntityService.getDevicesByCarrierId(carrierId);
  }

  searchDevicesByImei(imei) {
    this.deviceEntityService.getDeviceByImei(imei);
  }

  prepareDeviceData(data: { carrierId: string; imei; type }): Device {
    const carrier: Carrier = find<Carrier>(this.carriers, { id: data.carrierId });
    const newDevice: Device = { ...this.deviceInputParams(data), id: '' };
    return { ...newDevice, carrier };
  }

  assignIMEI(device: Device): void {
    const newIMEI = device.type;
    const id = device.id;
    const carrierId = this.deviceForm.get('carrier').value;
    this.carrierService.setIMEI({ id, imei: newIMEI } as Device).subscribe((resp) => {
      this.searchCarriers(carrierId || '-1');
    });
  }

  reassignTablet(device: Device): void {
    const deviceCarrier = find(this.carriers, (carrier: Carrier) => device.carrier.id === carrier.id);
    const selectedCarriersId = this.deviceForm.get('carrier').value;

    this.carrierService.updateDeviceCarrier({ ...device, carrier: deviceCarrier }).subscribe(
      (resp) => {
        this.snackBar.open('device reassigned!', null, { duration: 2000 });

        this.searchCarriers(selectedCarriersId);
      },
      () => {
        this.snackBar.open('unable to reassign device!', null, { duration: 2000 });
      }
    );
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }

  private isTablet(val) {
    const check = new RegExp('^(86)');
    const result = check.test(val) ? 'tablet' : null;

    return result;
  }

  private isMdi(val): string {
    const check = new RegExp('^(35)');
    const result = check.test(val) ? 'TELEMATICS' : null;

    return result;
  }

  // Do not remove, this is going to be added back in WEB-1101
  private processStrings(lines: string[]): Promise<void> {
    const imeis = [];
    let importMessage;

    if (lines.length < 2) {
      importMessage = 'Invalid file. Must include Header and Fields.';
      this.openDialog(null, importMessage);
      return;
    }

    const headers = lines[0].split(',');
    const fieldLines = lines.slice(1, lines.length).map((line) => line.split(','));
    if (!this.headerIncludesImei(headers)) {
      importMessage = 'Invalid file. Header must include IMEI column.';
      this.openDialog(null, importMessage);
      return;
    }

    if (this.rowLengthsDoNotMatch(fieldLines, headers)) {
      importMessage = 'Invalid file. Header and Field count must match.';
      this.openDialog(null, importMessage);
      return;
    }

    const imeiHeaderIndex = this.findImeiHeaderIndex(headers);
    forEach(fieldLines, (line) => {
      const imei = line[imeiHeaderIndex];
      // TODO: Make invalid list
      const extractedImei = imei.replace(/[^\w\s]/gi, '').replace(' ', '');
      if (isNumber(+extractedImei)) imeis.push(extractedImei);
    });

    if (!imeis.length) {
      importMessage = 'Invalid file. Must include at least one valid imei.';
      this.openDialog(null, importMessage);
      return;
    }
    this.openDialog(imeis);
  }

  private rowLengthsDoNotMatch(fieldRows, headers): boolean {
    const headerLength = headers.length;
    const result = fieldRows.findIndex((row) => {
      const rowLength = row.length;
      return rowLength !== headerLength;
    });
    return result > -1;
  }

  private headerIncludesImei(headers): boolean {
    return headers.some((header: string) => header.includes('IMEI'));
  }

  private findImeiHeaderIndex(headers: string[]): number {
    return headers.findIndex((header: string) => header.includes('IMEI'));
  }

  private resetFileInput(): void {
    this.file.nativeElement.value = '';
  }

  private openDialog(imeis = [], importMessage = null): void {
    this.resetFileInput();
    const dialogRef = this.dialog.open(ImeiUploadComponentComponent, {
      data: { imeis, importMessage },
    });

    dialogRef.afterClosed().subscribe((response: boolean) => {
      if (response) return this.sendRequest(imeis);
    });
  }

  private sendRequest(imeis: string[]): void {
    const { carrier } = this.deviceForm.getRawValue();
    const carrierId = !carrier || carrier === '-1' ? null : carrier;
    const devices = imeis.map((imei) => {
      const device = {
        imei: imei,
        type: this.isTablet(imei) || this.isMdi(imei) || null,
      };
      return { ...device, carrierId };
    });
    const userId = this.user.id;
    this.createDevicesWithImei(devices, userId);
  }

  private createDevicesWithImei(devices: Device[], userId: string): void {
    this.carrierService.createDevicesWithImei(devices, userId).subscribe(
      (response) => this.finishedUpload(response),
      (error) => console.log(error)
    );
  }

  private finishedUpload(response: Device[]): void {
    const count = response && response.length;
    this.snackBar.open(`${count || 0} device/s created`, null, { duration: 2000 });
    const { carrier } = this.deviceForm.getRawValue();
    this.searchCarriers(carrier || '-1');
  }

  private deviceInputParams(data: Partial<Device>): Device {
    return {
      carrier: null,
      lastLocation: null,
      token: '',
      destination: null,
      vin: null,
      unitId: null,
      make: null,
      model: null,
      year: null,
      distanceToLoad: null,
      macId: null,
      imei: data.imei,
      type: data.type,
    };
  }
}
