import { Carrier, FeatureFlagDetails, FeatureFlags, User } from '@haulynx/types';
import { createFeatureSelector } from '@ngrx/store';

export const initialFeatureFlagDashboard: FeatureFlagDashboardState = {
  isLoading: false,
  isLoadingDetails: false,
  isLoadingUsers: {},
  isLoadingCarriers: {},
  selected: null,
  entities: [],
  users: {},
  carriers: {},
};

export class FeatureFlagDashboardState {
  isLoading: boolean;
  isLoadingDetails: boolean;
  isLoadingUsers: Record<number, boolean>;
  isLoadingCarriers: Record<number, boolean>;
  selected: FeatureFlagDetails;
  entities: FeatureFlags[];
  users: Record<number, Partial<User>[]>;
  carriers: Record<number, Partial<Carrier>[]>;
}

export const getFeatureFlagDashboardState = createFeatureSelector<FeatureFlagDashboardState>('feature-flag-dashboard');
