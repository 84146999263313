import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AppModel } from '@haulynx/store';
import { userHomePage } from '@haulynx/utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GuestUserGuard implements CanActivate {
  constructor(private appModel: AppModel, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.appModel.user$.pipe(
      map((user) => {
        if (user) {
          this.router.navigate([userHomePage(user).url]);

          return false;
        }

        return true;
      })
    );
  }
}
