import { FocusMonitor } from '@angular/cdk/a11y';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, FormControlName } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { MatFormFieldControl, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MomentService } from '@haulynx/services';
import { DayOfWeek, WeekDays } from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { Subject } from 'rxjs';
import { MAT_DATE_FORMATS } from 'saturn-datepicker';
import { OverlayPanel } from 'primeng/overlaypanel';
import * as _ from 'lodash';

@Component({
  selector: 'haulynx-day-picker',
  templateUrl: './day-picker.component.html',
  styleUrls: ['./day-picker.component.scss'],
  providers: [
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_MOMENT_DATE_FORMATS,
    },
    {
      provide: MatFormFieldControl,
      useExisting: DayPickerComponent,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        float: 'always',
      },
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DayPickerComponent implements ControlValueAccessor, MatFormFieldControl<unknown>, OnInit, OnDestroy {
  @ViewChild('op') op: OverlayPanel;
  @ViewChild('inputFeild', { static: true }) inputFeild: ElementRef;

  @Input() placeholder = 'Select Day';
  @Input() set days(value: WeekDays[]) {
    this.setForm(value);
    this.setUi(value);
  }
  @Input() error?: string;
  @Output() manualSelect: EventEmitter<WeekDays[]> = new EventEmitter();
  @Output() inputBlur = new EventEmitter();

  control: FormControl | AbstractControl | null = new FormControl();
  alive = aliveWhile();
  onTouch: (value) => unknown;
  value: unknown;
  id: string;
  focused: boolean;
  empty: boolean;
  shouldLabelFloat: boolean;
  required: boolean;
  disabled: boolean;
  errorState: boolean;
  controlType?: string;
  autofilled?: boolean;
  userAriaDescribedBy?: string;
  tabIndex: number = null;
  readonly stateChanges: Subject<void> = new Subject<void>();
  showDayPicker = false;
  selectedDays: WeekDays[] = [];
  formattedDays = '';
  week;

  daysOfTheWeek: WeekDays[] = [
    { label: 'S', key: DayOfWeek.SUNDAY, id: 0, selected: false },
    { label: 'M', key: DayOfWeek.MONDAY, id: 1, selected: false },
    { label: 'T', key: DayOfWeek.TUESDAY, id: 2, selected: false },
    { label: 'W', key: DayOfWeek.WEDNESDAY, id: 3, selected: false },
    { label: 'Th', key: DayOfWeek.THURSDAY, id: 4, selected: false },
    { label: 'F', key: DayOfWeek.FRIDAY, id: 5, selected: false },
    { label: 'Sa', key: DayOfWeek.SATURDAY, id: 6, selected: false },
  ];

  constructor(
    @Optional() @Self() public ngControl: FormControlName,
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.week = this.daysOfTheWeek;
  }

  addToList(day: WeekDays) {
    if (!this.selectedDays.find((e) => e.key === day.key)) {
      this.selectedDays.push(day);
    } else {
      const index = this.selectedDays.indexOf(day);
      if (index > -1) this.selectedDays.splice(index, 1);
    }
    day.selected = !day.selected;
  }

  cancel() {
    this.op.hide();
    this.inputBlur.emit();
  }

  confirm() {
    this.selectedDays = _.sortBy(this.selectedDays, 'id');
    this.setForm(this.selectedDays);
    this.op.hide();
    this.inputBlur.emit();
    this.manualSelect.emit(this.selectedDays);
  }

  setForm(val) {
    this.selectedDays = val;
    this.control.setValue(val);
    this.formattedDays = val.map((e) => e.label).join(', ');
  }

  setUi(days: WeekDays[]) {
    // clear old existing days
    this.daysOfTheWeek.forEach((day) => {
      day.selected = false;
    });

    // set new days
    days.forEach((day) => {
      this.addToList(day);
    });
  }

  setDescribedByIds(ids: string[]): void {}
  onContainerClick(event: MouseEvent): void {}
  writeValue(newValue: string[]): void {
    this.value = newValue;
    this.control.patchValue(this.value);
  }
  propagateChanges(value): void {}
  registerOnChange(fn: any): void {
    this.propagateChanges = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.control.disable() : this.control.enable();
  }

  ngOnDestroy(): void {
    this.alive.destroy();
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef.nativeElement);
  }
}
