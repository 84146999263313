<h6>Reject Reason</h6>

<form [formGroup]="form">
  <mat-radio-group formControlName="standardReason" class="reject-radio-group">
    <mat-radio-button class="reject-radio-group" *ngFor="let reason of rejectReasons" [value]="reason.value">
      {{ reason.label }}
    </mat-radio-button>
  </mat-radio-group>
  <mat-form-field [hideRequiredMarker]="!isCustomReasonDisabled" class="reject-full-width">
    <input
      required
      matInput
      autocomplete="off"
      formControlName="customReason"
      placeholder="Please enter reject reason"
    />
  </mat-form-field>

  <div class="reject-buttons-wrapper">
    <button mat-button [disabled]="isSubmitDisabled" (click)="onReject()" class="action-button negative">Reject</button>
    <button mat-stroked-button color="primary" [matDialogClose]="" class="action-button">Cancel</button>
  </div>
</form>
