import { TemplateRef } from '@angular/core';
import { List, Map, Record } from 'immutable';
import {
  DateRangeFilterType,
  GeoSpacialFilterType,
  ISearchFilterType,
  NumericalFilterType,
  TextFilterType,
} from '../advanced-search';

export type DataTableV2Type<T extends object> = Map<string, T> | List<Record<T>> | Record<T>[] | T[];

export interface IColumns2 {
  /**
   * A string or HTML to render for the column label
   */
  label: string;
  field: string;
  isCustomCell?: boolean;
  width?: string;
  /**
   * is set to false: this will have no sorting capabilities and will always be placed on the right of all sortable columns.
   */
  isSortable?: boolean;

  /**
   * if true: data-table-v2 will emit an event when this column is clicked. event emits the row data.
   */
  isClickable?: boolean;

  /**
   * is this a stick column. must use the align property on order to move this column to the right or the left side of the table.
   */
  isSticky?: boolean;
  /**
   * used in conjuction with the `isSticky` property.
   */
  align?: 'right' | 'left';
  dropdownAlign?: 'right' | 'left';
  sortConfig: ISortingConfig[];

  /**
   * this is the `<ng-template>` ref of some content that you
   * would like to display in the header. NOTE: this will need to be added to the displayed
   * columns inside of the `ngAfterContentInit` angular lifecycle hook.
   */
  headerTemplate?: TemplateRef<unknown>;
  isVisible?: boolean;
  isPinned?: boolean;
  isFrozen?: boolean;
  filters?: string[];
  hasFilterSuggestion?: boolean;
  order?: number;
}

export interface ISortingConfig {
  /**
   * if emitSort is set to false, then you can use stringified dot notation to select the property from the table data which
   * will be sorted. for arrays, you can select a specific index of the array by using `arrayProp.<enter number>.propName`. Or
   * you can use the last value of the array by using `arrayProp.length.propName`.
   *
   * also for arrays, a where clause format is supported. the format is as follows: `arrayProp.where-<propInArray>=<value>.propName`.
   * this will take loop through the array and find the index where `propInArray` is equal to a supplied value and then it will
   * sort on `propName` from the array.
   * example from loadsService: `providerDetails.alternateIds.where-identifierType=tmwNumber.identifierValue`
   *
   * if emitSort is set to true, the value of dataIndex should be `<ASCENDING order type>~<DESCENDING order type>`. meaning
   * you will supply both of the order values that the api needs in order to know which it needs to sort by seperated by as dash.
   *
   * in order to support pagination for when emitSort is true but there are not enough records to sort using the backend, we will
   * need to sort locally. In order to do this, add a question mark and follow all rules for localSorting.
   */
  dataIndex?: string;
  secondaryIndex?: string;
  label: string;
  /**
   * If the api which supplies the data for this table allows for ordering on this specific column. set this value to true and
   * set the dataIndex = to the value that the api is expecting to know which it needs to sort by.
   */
  emitSort?: boolean;
  orderByIndex?: string[] | Array<((value: any) => any) | string>;
  /**
   * There can only be a single defaultOrder present on a dataTable, for the column that will set the default sort.
   */
  defaultOrder?: 'asc' | 'desc';
}

export interface SaveSearchViewDto {
  columnsToShow: IColumns2[];
  columnsToHide: IColumns2[];
}

export interface ColumnVisibilityItem {
  label: string;
  name?: string;
  field?: string;
  order?: number;
  keys?: GeoSpacialFilterType | DateRangeFilterType | NumericalFilterType | TextFilterType;
  type?: ISearchFilterType;
  isVisible: boolean;
  isPinned?: boolean;
}

export enum ColumnVisibilityTypes {
  VISIBLE = 'columnsToShow',
  HIDDEN = 'columnsToHide',
}
