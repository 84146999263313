import { InjectionToken } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducerMap, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { entitiesOptions } from '../shared/common-entities/common-entities.options';
import { createCombinationReducers } from '../shared/common-entities/common-entities.utils';
import { appSettingsReducer } from './app-settings.reducer';
import { AppSettingsState } from './app-settings.state';

export function debug(reducer) {
  return function (state, action) {
    console.groupCollapsed(action.type);
    console.log('action', action);
    const result = reducer(state, action);
    console.log('state', result);
    console.groupEnd();
    return result;
  };
}

export function clearState(reducer) {
  return function (state, action) {
    // todo: catch logout action
    return reducer(state, action);
  };
}

export function stateSetter(reducer) {
  return (state, action) => {
    if (action.type === 'SET_ROOT_STATE') {
      return action.payload;
    }
    return reducer(state, action);
  };
}

export const metaReducers = [debug, clearState, stateSetter];

export interface RouterStateUrl {
  url: string;
  root: ActivatedRouteSnapshot;
}

export interface AppState {
  appSettings: AppSettingsState;
  router: RouterReducerState<RouterStateUrl>;
  commonEntities: any;
}

export const reducers: ActionReducerMap<AppState> = {
  appSettings: appSettingsReducer,
  router: routerReducer,
  commonEntities: combineReducers(createCombinationReducers(entitiesOptions)),
};

export function getAppReducersFactory() {
  return reducers;
}

export const APP_REDUCERS_TOKEN = new InjectionToken<ActionReducerMap<AppState>>('AppReducers');

export const getRouterState = createFeatureSelector<RouterReducerState<RouterStateUrl>>('router');

export const routerSelector = createSelector(getRouterState, (state) => state && state.state);
