import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UserService } from '@haulynx/services';
import { User } from '@haulynx/types';
import moment from 'moment';

@Directive({
  selector: 'input[type="time"][msTime]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: MsTimeDirective,
      multi: true,
    },
  ],
})
export class MsTimeDirective implements ControlValueAccessor {
  @Input() timezone = '';
  private onTouched;
  private onChange;
  private formVal: number; // the actual data value
  private timeFormat = 'HH:mm:ss';
  private user: User;
  constructor(private el: ElementRef, private userService: UserService, private renderer: Renderer2) {
    this.userService.user.subscribe((user) => {
      this.user = user;
    });
  }

  @HostListener('click', ['$event']) clickEvent($event: Event): void {
    this.onTouched($event);
  }

  @HostListener('input', ['$event']) inputEvent($event: Event): void {
    const inputTimeVal = moment($event.target['value'], this.timeFormat);

    const controlAsMoment = moment(this.formVal);
    controlAsMoment.set({
      hour: inputTimeVal.get('hour'),
      minute: inputTimeVal.get('minute'),
      second: inputTimeVal.get('second'),
    });

    const result = controlAsMoment.valueOf();
    this.onChange(result);
  }

  writeValue(time: number) {
    this.formVal = time;
    this.el.nativeElement.value = moment(time)
      .tz(this.timezone || this.user.prefs.timeZone)
      .format(this.timeFormat);
  }
  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.renderer.setAttribute(this.el.nativeElement, 'disabled', 'true');
    } else {
      this.renderer.removeAttribute(this.el.nativeElement, 'disabled');
    }
  }
}
