import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LoadsServiceLoad } from '@haulynx/types';

@Component({
  selector: 'app-loads-service-load-preview',
  templateUrl: './loads-service-load-preview.html',
  styleUrls: ['./loads-service-load-preview.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadsServiceLoadPreviewComponent {
  @Input() loadsServiceLoad: LoadsServiceLoad;

  startAddress: string;
  endAddress: string;

  ngOnInit() {
    const start = this.loadsServiceLoad?.locations[0]?.address;
    if (start) {
      this.startAddress = start.substring(start.indexOf(',') + 1, start.length);
    }
    const size = this.loadsServiceLoad?.locations.length;
    const end = this.loadsServiceLoad?.locations[size - 1]?.address;
    if (end) {
      this.endAddress = end.substring(end.indexOf(',') + 1, end.length);
    }
  }
}
