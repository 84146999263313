import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GetAssetTrailerNumbers } from '@haulynx/gql';
import { CarrierInfoSectionVmServiceV2, TrackingTypeOptions, TrailerOwnerOptions } from '@haulynx/services';
import { CarrierEntityService } from '@haulynx/store';
import {
  AssignDriverForm,
  AssignmentForm,
  BookStatus,
  Carrier,
  Driver,
  EntityTypes,
  FeatureFlag,
  FFState,
  LoadsServiceLoad,
  TrackingEnabledInput,
  TrackingType,
  TrackingTypeAvailability,
  Trailer,
  TrailerOwnerType,
  Truck,
  TruckTrackingEnabledInput,
  TruckTrackingTypeAvailability,
} from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { List } from 'immutable';
import { find, get, isEqual, orderBy, startsWith } from 'lodash';
import { OverlayPanel } from 'primeng/overlaypanel';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { DataTableOverlayComponent } from '../../data-table-overlay/data-table-overlay.component';
import { TruckDropDownComponent } from '../../truck-drop-down/truck-drop-down.component';
import { TrackingAvail, TrackingChip } from './truck-info-section.config';

@Component({
  selector: 'app-truck-info-section',
  templateUrl: './truck-info-section.component.html',
  styleUrls: ['./truck-info-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TruckInfoSectionComponent implements OnChanges, OnInit, OnDestroy {
  @ViewChild('availToolTip', { static: true }) availToolTip: OverlayPanel;
  @ViewChild('driverToolTip', { static: true }) driverToolTip: OverlayPanel;

  @Input() carrier: Carrier = null;
  @Input() assignmentForm: FormGroup;
  @Input() trucks: Partial<Truck>[] | List<Truck>;
  @Input() drivers: Partial<Driver>[] | List<Driver>;
  @Input() trailers: Partial<Trailer>[] | List<Trailer>;
  @Input() usxiTrailers;
  @Input() featureFlags: FFState;
  @Input() assignmentFormData: Partial<AssignDriverForm>;
  @Input() isLoadingDrivers = false;
  @Input() isLoadingTrucks = false;
  @Input() isLoadingTrailers = false;
  @Input() driverToPopulate: string;
  @Input() truckToPopulate: string;
  @Input() trailerToPopulate: string;
  @Input() assignmentLoading: boolean;
  @Input() showIndividualSaveButtons: boolean;
  @Input() loadsServiceLoad: LoadsServiceLoad;
  @Output() assignmentDataChange = new EventEmitter<AssignDriverForm>();
  @Output() addDriver = new EventEmitter();
  @Output() addTruck = new EventEmitter();
  @Output() addTrailer = new EventEmitter();
  @Output() trackingTypeChange = new EventEmitter();
  @Output() saveAssignment = new EventEmitter();
  @Output() swapTrailer = new EventEmitter();

  @ViewChild('truckList') truckList: TruckDropDownComponent;
  @ViewChild('trailerList') trailerList: TruckDropDownComponent;
  @ViewChild('driverList') driverList: DataTableOverlayComponent;

  @ViewChild('secDriverList') secDriverList: DataTableOverlayComponent;

  private alive = aliveWhile();
  entityTypes = EntityTypes;
  trackingType = TrackingType;
  trackingOptions$ = new BehaviorSubject<TrackingTypeOptions[]>([]);
  trackingFeatureFlag = FeatureFlag.TRACKING_TYPE;
  trailerFeatureFlag = FeatureFlag.TRAILER_SWAP;
  trackingAvail = TrackingAvail;
  chips$: Observable<TrackingChip[]>;
  availTooltipText =
    'Availability refers to the tracking types that can be selected for this load based on the truck and carrier chosen. A tracking type still must be selected for tracking to be enabled.';
  unknownTrailerToolTipText = 'Warning: This trailer is not in the USXI trailer list. Are you sure you want to use it?';
  macropointToolTipText = 'May be available Determined at booking';
  currentCarrier: Carrier;
  selectedRow;
  selectedSecDriverRow;
  clearSelec;
  cols: { field: string; header: string }[];
  selectedDriver: Driver;
  phoneList;
  emailList;
  chosenDriver = false;
  secSelectedDriver: Driver;
  clearSelectedDriver = new BehaviorSubject(false);
  clear = false;
  buttonIndex = '';
  secDriverTooltipText = '';
  previousForm: AssignmentForm = null;
  newEntityAdded = false;
  trailerOwners: TrailerOwnerOptions[] = [
    { label: 'USXI', value: TrailerOwnerType.USXI },
    { label: 'Third Party', value: TrailerOwnerType.THIRD_PARTY },
    { label: 'Customer (Coming Soon)', value: TrailerOwnerType.OTHER },
  ];
  trailerOwnerType = TrailerOwnerType;
  selectedTrailers$: BehaviorSubject<Partial<Trailer>[] | List<Trailer> | number[]> = new BehaviorSubject([]);
  unknownTrailerFound: boolean;
  unknownTrailer$ = new BehaviorSubject(null);
  showSwapTrailerButton = true;

  constructor(
    private carrierInfoSectionVmService: CarrierInfoSectionVmServiceV2,
    public carrierEntityService: CarrierEntityService
  ) {
    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'phone', header: 'Phone Number' },
      { field: 'email', header: 'Email Address' },
      // { field: 'secPhone', header: 'Secondary Number' },
      // { field: 'secEmail', header: 'Secondary Email' },
    ];
  }

  ngOnInit(): void {
    this.updateTrailerData();
    this.trackingOptions$.next(this.carrierInfoSectionVmService.getDefaultTrackingOptions());

    // The chips list is a function of several dynamic values.
    this.chips$ = combineLatest([this.trackingOptions$]).pipe(
      map(([trackingOptions]) => {
        const chips: TrackingChip[] = [{ name: 'MACROPOINT ELD', available: TrackingAvail.FALSE }].map((chip) => {
          if (chip.name === 'MACROPOINT ELD') {
            chip.available = trackingOptions.some((e) => e.value === TrackingType.MACRO_POINT_ELD)
              ? TrackingAvail.TRUE
              : TrackingAvail.FALSE;
          }
          return chip;
        });
        return chips;
      })
    );
  }

  prepareAssignmentFormData(data: AssignDriverForm): AssignmentForm {
    return {
      trackingType: get(data, 'trackingType', null),
      truckId: get(data, 'truck.id', null),
      driverId: get(data, 'driver.id', null),
      trailerOwner: data?.trailerOwner ? data?.trailerOwner : get(data, 'trailer.trailerOwnerType', null),
      trailerId:
        data?.trailerOwner === TrailerOwnerType.USXI || data?.trailer?.trailerNumber === TrailerOwnerType.USXI
          ? data?.trailer?.trailerNumber
          : get(data, 'trailer.id', null),
      phone: get(data, 'driver.phone', null),
      email: get(data, 'driver.email', null),
      equipment: get(data, 'trailer.type', null),
      secDriverId: get(data, 'secondaryDriver.id', null),
      secPhone: get(data, 'secondaryDriver.phone', null),
      secEmail: get(data, 'secondaryDriver.email', null),
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    const {
      carrier,
      assignmentFormData,
      drivers,
      trucks,
      trailers,
      usxiTrailers,
      isLoadingTrucks,
      isLoadingTrailers,
      isLoadingDrivers,
    } = changes;

    if (carrier) {
      this.currentCarrier = carrier?.currentValue;
      if (carrier.previousValue && carrier?.currentValue?.id !== carrier.previousValue?.id) {
        this.clearCarrierChainedLists();
      }
      this.trackingOptions$.next(this.carrierInfoSectionVmService.getDefaultTrackingOptions());

      if (carrier.currentValue) {
        this.previousForm = null;
      }
    }

    if (assignmentFormData) {
      const currentFormData = this.prepareAssignmentFormData(assignmentFormData.currentValue);
      if (!isEqual(this.previousForm, currentFormData)) {
        this.assignmentForm.patchValue(currentFormData);
      }
      this.previousForm = currentFormData;

      if (currentFormData.driverId) {
        this.selectedRow = find(this.drivers, (item: Driver) => item.id === currentFormData.driverId);
        this.selectedSecDriverRow = find(this.drivers, (item: Driver) => item.id === currentFormData.secDriverId);
        this.selectDriver(new CustomEvent('myCustomEvent', { detail: { id: currentFormData.driverId } }), false);
        this.selectDriver(new CustomEvent('myCustomEvent', { detail: { id: currentFormData.secDriverId } }), true);
      }

      if (currentFormData.truckId && this.carrier) {
        this.callTrackingAndUpdate(true);
      }

      if (currentFormData.trailerOwner) {
        this.selectTrailerOwner(currentFormData.trailerOwner);
        this.callTrackingAndUpdate(true);
      }

      if (currentFormData.trailerId) {
        this.callTrackingAndUpdate(true);
      }

      if (currentFormData.truckId && this.currentCarrier) {
        this.trackingOptions$.next(
          this.carrierInfoSectionVmService.setEldTrackingForTruck(
            find(this.trucks, (item: Truck) => item.id === currentFormData.truckId) as unknown as Truck,
            this.currentCarrier,
            this.trackingOptions$.value
          )
        );
      }
    }

    if (drivers) {
      this.drivers = orderBy(drivers.currentValue, [(driver) => driver.name.toLowerCase()], ['asc']);
      if (this.newEntityAdded) {
        const newDriver = this.getNewEntity(drivers?.currentValue, drivers?.previousValue) as unknown[] as Driver[];
        if (newDriver) {
          this.newEntityAdded = false;
          if (this.buttonIndex === 'primary-driver') {
            this.selectDriver(new CustomEvent('myCustomEvent', { detail: { id: newDriver[0].id } }), false);
            this.driverList.selectedItem = newDriver;
            this.driverList.display = newDriver[0].name;
            this.driverList.isLoading = true;
          } else if (this.buttonIndex === 'secondary-driver') {
            this.selectDriver(new CustomEvent('myCustomEvent', { detail: { id: newDriver[0].id } }), true);
            this.secDriverList.selectedItem = newDriver;
            this.secDriverList.display = newDriver[0].name;
            this.secDriverList.isLoading = true;
          }
        }
      }
    }
    if (trucks) {
      this.trucks = orderBy(trucks.currentValue, [(driver) => driver.unitId.toLowerCase()], ['asc']);
      if (this.newEntityAdded) {
        const newTruck = this.getNewEntity(trucks?.currentValue, trucks?.previousValue) as unknown[] as Truck[];
        if (newTruck) {
          this.newEntityAdded = false;
          this.assignmentForm.controls['truckId'].patchValue(newTruck[0].id);
        }
      }
    }

    if (trailers) {
      this.updateTrailerData();
      this.trailers = orderBy(trailers.currentValue, [(driver) => driver.trailerNumber.toLowerCase()], ['asc']);
      if (this.newEntityAdded) {
        const newTrailer = this.getNewEntity(trailers?.currentValue, trailers?.previousValue) as unknown[] as Trailer[];
        if (newTrailer) {
          this.newEntityAdded = false;
          this.assignmentForm.controls['trailerId'].patchValue(newTrailer[0].id);
        }
      }
    }

    if (usxiTrailers) {
      this.updateTrailerData();
      this.updateTrailerControls();
    }

    if (isLoadingTrucks) {
      this.isLoadingTrucks = isLoadingTrucks.currentValue;
    }

    if (isLoadingTrailers) {
      this.isLoadingTrailers = isLoadingTrailers.currentValue;
    }

    if (isLoadingDrivers) {
      this.isLoadingDrivers = isLoadingDrivers.currentValue;
    }
    /**
     * FEAT_FLAG: You can delete the previous 3 if statements and the following feature flag checks when the feature flag is expired
     */
    // if (this.featureFlags[FeatureFlag.INLINE_CREATION] && drivers) {
    //   this.drivers = [{ name: EntityOptions.ADD_DRIVER }, ...this.drivers];
    // }
    // if (this.featureFlags[FeatureFlag.INLINE_CREATION] && trucks) {
    //   this.trucks = [{ unitId: EntityOptions.ADD_TRUCK }, ...this.trucks];
    // }
    // if (this.featureFlags[FeatureFlag.INLINE_CREATION] && trailers) {
    //   this.trailers = [{ trailerNumber: EntityOptions.ADD_TRAILER }, ...this.trailers];
    // }
  }

  selectDriver(event, secondaryDriver: boolean) {
    this.chosenDriver = true;
    if (event?.detail) {
      this.assignmentForm.controls[secondaryDriver ? 'secDriverId' : 'driverId'].patchValue(event.detail?.id);
    } else {
      this.assignmentForm.controls[secondaryDriver ? 'secDriverId' : 'driverId'].patchValue(event.data?.id);
    }
    this.selectAssignment(EntityTypes.DRIVERS);
    this.chosenDriver = false;
  }

  selectAssignment(entity: EntityTypes): void {
    const { trackingType, truckId, trailerId, trailerOwner } = this.assignmentForm.getRawValue() as AssignmentForm;
    if (!this.chosenDriver && entity === EntityTypes.DRIVERS) {
      if (this.driverList) {
        this.driverList.closePanel();
        this.driverList.isLoading = true;
      } else if (this.secDriverList) {
        this.secDriverList.closePanel();
        this.secDriverList.isLoading = true;
      }
      this.newEntityAdded = true;
      return this.addDriver.emit();
    }

    if (!truckId && entity === EntityTypes.TRUCKS) {
      if (this.truckList) {
        this.truckList.closeDropDown();
        this.truckList.isLoading = true;
        this.truckList.autocompleteInput.nativeElement.blur();
      }
      this.newEntityAdded = true;
      return this.addTruck.emit();
    }

    if (
      !trailerId &&
      entity === EntityTypes.TRAILERS &&
      (!this.featureFlags[FeatureFlag.TRAILER_SWAP] || trailerOwner === TrailerOwnerType.THIRD_PARTY)
    ) {
      if (this.trailerList) {
        this.trailerList.closeDropDown();
        this.trailerList.isLoading = true;
        this.trailerList.autocompleteInput.nativeElement.blur();
      }
      this.newEntityAdded = true;
      return this.addTrailer.emit();
    }

    if (trackingType && entity === EntityTypes.TRACKING_TYPES) {
      this.trackingTypeChange.emit();
    }

    this.callTrackingAndUpdate(!!truckId && entity === EntityTypes.TRUCKS);
  }

  private callTrackingAndUpdate(checkEligibility: boolean) {
    this.updateTrailerControls();

    const { trackingType, driverId, secDriverId, truckId, trailerId, trailerOwner } =
      this.assignmentForm.getRawValue() as AssignmentForm;
    this.selectedDriver = find(this.drivers, (item: Driver) => item.id === driverId) as unknown as Driver;
    this.secSelectedDriver = find(this.drivers, (item: Driver) => item.id === secDriverId) as unknown as Driver;
    // this.phoneList = [{ value: this.selectedDriver?.phone }, { value: this.selectedDriver?.phone }];
    // this.emailList = [{ value: this.selectedDriver?.email }, { value: this.selectedDriver?.email }];

    this.unknownTrailerFound =
      trailerId && trailerOwner === TrailerOwnerType.USXI && trailerId === this.unknownTrailer$.value;

    const newFormData: AssignDriverForm = {
      trackingType: find(this.trackingType, (item) => item === trackingType) as unknown as TrackingType,
      driver: this.selectedDriver,
      truck: find(this.trucks, (item: Truck) => item.id === truckId) as unknown as Truck,
      trailerOwner: trailerOwner,
      trailer: (trailerOwner === TrailerOwnerType.USXI
        ? ({ trailerNumber: trailerId, trailerOwnerType: trailerOwner } as Trailer)
        : find(this.trailers, (item: Trailer) => item.id === trailerId)) as unknown as Trailer,
      phone: get(this.selectedDriver, 'phone', null),
      email: get(this.selectedDriver, 'email', null),
      secondaryDriver: this.secSelectedDriver,
      secDriverEmail: get(this.secSelectedDriver, 'phone', null),
      secDriverPhone: get(this.secSelectedDriver, 'email', null),
    };

    let newTrackingType;
    if (checkEligibility) {
      this.trackingOptions$.next(
        this.carrierInfoSectionVmService.setEldTrackingForTruck(
          newFormData.truck,
          this.carrier,
          this.trackingOptions$.value
        )
      );
      if (
        newFormData.trackingType === TrackingType.MACRO_POINT_ELD &&
        !this.trackingOptions$.value.some((option) => option.value === TrackingType.MACRO_POINT_ELD)
      ) {
        newFormData.trackingType = null;
      }

      if (
        newFormData.trackingType === TrackingType.MACRO_POINT &&
        this.carrier?.thirdPartyTracking.some(
          (option) => option.trackingType === TrackingType.MACRO_POINT && option.vehicleTrackingEnabled
        ) &&
        newFormData.truck?.thirdPartyTracking.some(
          (option) => option.trackingType === TrackingType.MACRO_POINT && option.isSupported
        )
      ) {
        this.assignmentForm.controls['trackingType'].patchValue(TrackingType.MACRO_POINT_ELD);
        this.assignmentForm.updateValueAndValidity();
        newFormData.trackingType = TrackingType.MACRO_POINT_ELD;
        newTrackingType = TrackingType.MACRO_POINT_ELD;
      } else {
        newTrackingType = trackingType;
      }
    }

    this.assignmentForm = this.carrierInfoSectionVmService.getTrackingValidation(newTrackingType, this.assignmentForm);

    this.assignmentDataChange.emit(newFormData);
    this.assignmentForm.patchValue(this.prepareAssignmentFormData(newFormData));
  }

  private updateTrailerControls(): void {
    const { bookStatus, trailers } = this.loadsServiceLoad;
    if (bookStatus !== BookStatus.BOOKED || trailers.length === 0) {
      return;
    }

    const { trailerOwnerType, trailerNumber } = trailers[0];
    if (trailerOwnerType !== TrailerOwnerType.USXI) {
      this.showSwapTrailerButton = false;
      return;
    }

    const isDummyTrailer = startsWith(trailerNumber, 'PT');
    if (isDummyTrailer) {
      this.assignmentForm.controls['trailerOwner'].enable();
      this.assignmentForm.controls['trailerId'].enable();
      this.showSwapTrailerButton = false;
    } else {
      this.assignmentForm.controls['trailerOwner'].disable();
      this.assignmentForm.controls['trailerId'].disable();
      this.showSwapTrailerButton = true;
    }
  }

  clearCarrierChainedLists(): void {
    this.driverList.display = null;
    this.secDriverList.display = null;
    this.truckList.keywordSearch.patchValue(null);
    this.trailerList.keywordSearch.patchValue(null);
    this.assignmentForm.patchValue(this.prepareAssignmentFormData(null));
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }

  selectTrailerOwner(trailerOwner) {
    this.assignmentForm.controls['trailerOwner'].patchValue(trailerOwner);
    this.assignmentForm.controls['trailerOwner'].updateValueAndValidity();
    this.updateTrailerData();
  }

  forceSelectTrailer(value) {
    if (value) {
      if (find(this.usxiTrailers, (trailer) => trailer.trailerNumber === value) ? false : true) {
        this.unknownTrailer$.next(value);
        this.usxiTrailers.push({ key: '' + this.usxiTrailers.length, trailerNumber: value });
        this.selectedTrailers$.next(this.usxiTrailers);
      }
      this.assignmentForm.controls['trailerId'].patchValue(value);
      this.assignmentForm.updateValueAndValidity();
    }
    this.callTrackingAndUpdate(false);
  }

  private getNewEntity(
    newValue: Array<Driver | Truck | Trailer>,
    oldValue: Array<Driver | Truck | Trailer>
  ): Array<Driver | Truck | Trailer> {
    if (newValue?.length - oldValue?.length === 1) {
      return newValue.filter((item) => !oldValue?.some((other) => item.id == other.id));
    }
    return null;
  }

  emitSaveAssignment(): void {
    this.saveAssignment.emit();
  }

  clearSecDriver(): void {
    this.selectDriver(new CustomEvent('myCustomEvent', { detail: { id: null } }), true);
    this.clearSelectedDriver.next(true);
    setTimeout(() => {
      this.clearSelectedDriver.next(false);
    }, 100);
  }

  setButtonIndex(index: string) {
    this.buttonIndex = index;
  }

  onSwapTrailer(event: Event): void {
    event.stopPropagation();
    this.swapTrailer.emit();
  }

  private updateTrailerData() {
    if (this.featureFlags[FeatureFlag.TRAILER_SWAP]) {
      switch (this.assignmentForm?.controls['trailerOwner']?.value) {
        case TrailerOwnerType.USXI:
          this.selectedTrailers$.next(this.usxiTrailers);
          break;
        case TrailerOwnerType.THIRD_PARTY:
          this.selectedTrailers$.next(this.trailers);
          break;
        case TrailerOwnerType.OTHER:
          this.selectedTrailers$.next([]);
          break;
        default:
          this.selectedTrailers$.next([]);
      }
    } else {
      this.selectedTrailers$.next(this.trailers);
    }
  }
}
