<div class="contract-modal" mat-dialog-content>
  <div>Remove this contract?</div>

  <div><sub>Bill to: {{data?.billToNumber}}</sub></div>
  <div><sub>Consignee: {{data?.consignee ?? 'N/A'}}</sub></div>
  <div><sub>Shipper: {{data?.shipper ?? 'N/A'}}</sub></div>
</div>
<div class="contract-modal" mat-dialog-actions>
  <button mat-button class="bordered-button" (click)="onCancel()">Cancel</button>
  <button mat-button color="warn" class="bordered-button" (click)="onConfirm()">Delete</button>
</div>
