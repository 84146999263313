export interface RadiusOption {
  selected: boolean;
  label: string;
  id: number;
}

export interface EquipmentTypeOption {
  selected: boolean;
  text: string;
}
