import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Params, Router } from '@angular/router';
import { ANALYTICS_EVENT, Environment, User } from '@haulynx/types';
import * as amplitude from 'amplitude-js';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ElasticRumAgentService } from '../../elastic-apm/elastic-rum-agent-service';

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  constructor(
    private environment: Environment,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private elasticRumAgentService: ElasticRumAgentService
  ) {
    this.initializeAmplitude();
    this.throttledLogListener();
  }

  private eventQueue = new Subject<{ event: ANALYTICS_EVENT; data: any }>();

  private get apiKey(): string {
    return this.environment.amplitude.apiKey;
  }

  private get amplitude(): amplitude.AmplitudeClient {
    return amplitude.getInstance();
  }

  private initializeAmplitude(): void {
    if (this.apiKey) {
      this.amplitude.init(this.apiKey, null, {
        platform: `Web`,
      });
    } else if (this.environment.production) {
      console.warn('No amplitude api key provided');
    }
  }

  setUser(user: User, features: { flags: { [key: string]: boolean } }): void {
    const flags = features?.flags || {};
    const enabledFeatures = Object.keys(flags).filter((key: string) => flags[key]);

    if (user) {
      const userProperties = {} as UserProperties;
      userProperties.userName = user?.name ?? '';
      userProperties.userEmail = user?.email ?? '';
      userProperties.userPhone = user?.phone ?? '';
      userProperties.isCompanyAdmin = user?.isCompanyAdmin ?? '';
      userProperties.isDriver = user?.isDriver ?? '';
      userProperties.enabledFeatures = enabledFeatures;
      userProperties.userTeam = user?.team ?? '';
      userProperties.userJobTitle = user?.jobTitle ?? '';
      userProperties.userManager = user?.manager ?? '';

      if (user.broker) {
        userProperties.accountType = 'broker';
        userProperties.haulynxCarrier = 'USX Brokerage';
        userProperties.mcNumber = null;
        userProperties.dotNumber = null;
      }
      if (user.carrier) {
        userProperties.accountType = 'carrier';
        userProperties.haulynxCarrier = user.carrier?.name ?? '';
        userProperties.dotNumber = user.carrier?.dot ?? null;
        userProperties.mcNumber = user.carrier?.mcNumber ?? null;
        userProperties.carrierId = user.carrier?.id ?? null;
      }

      this.amplitude.setUserId(user.id);
      this.amplitude.setUserProperties(userProperties);
      this.elasticRumAgentService.setUser(user);
    }
  }

  logEvent(event: ANALYTICS_EVENT, data: any = {}) {
    if (this.apiKey) {
      const { url, router } = getRouterInfo(this.router, this.activatedRoute.snapshot);
      const payload: AnalyticsPayload = { url, router, ...data };

      this.amplitude.logEvent(event, payload);
    }
  }

  logEventThrottled(event: ANALYTICS_EVENT, data: any = {}) {
    this.eventQueue.next({ event, data });
  }

  private throttledLogListener() {
    if (this.apiKey) {
      this.eventQueue.pipe(debounceTime(2000)).subscribe((payload) => {
        this.logEvent(payload.event, payload.data);
      });
    }
  }
}

function getRouterInfo(router: Router, activatedRoute: ActivatedRouteSnapshot): { url: string; router: RouterState } {
  return {
    url: router.url,
    router: {
      params: activatedRoute.params,
      queryParams: activatedRoute.queryParams,
      fragment: activatedRoute.fragment,
    },
  };
}

interface RouterState {
  params: Params;
  queryParams: Params;
  fragment?: string;
}

interface AnalyticsPayload {
  url: string;
  router: RouterState;
}

export interface UserProperties {
  isCompanyAdmin?: boolean | string;
  isDriver?: boolean | string;
  accountType?: string;
  mcNumber?: string | null;
  dotNumber?: string | null;
  userEmail?: string;
  userPhone?: string;
  haulynxCarrier?: string;
  userName?: string;
  enabledFeatures?: string[];
  carrierId?: string;

  // these are probably broker-only fields
  userTeam?: string;
  userJobTitle?: string;
  userManager?: string;
}
