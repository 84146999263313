<div class="popup-container" *ngIf="selectedMarker && selectedMarker.properties?.type != 'phone'">
  <h5>Truck: {{ truckText }}</h5>
  <br />
  <b>Truck State</b>: {{ truckState }}<br />
  <b>Address</b>:
  {{
    { gpsLat: (selectedMarker?.geometry?.coordinates)[1], gpsLon: (selectedMarker?.geometry?.coordinates)[0] }
      | googleLatLon
      | async
  }}
  <br />
  <b>Last Updated</b>:
  {{ selectedMarker?.properties?.lastUpdated | timestamp: 'MM-DD-YYYY hh:mm A' }}
  <br />
</div>
<div [innerHTML]="selectedMarker?.properties?.info"></div>
