/* eslint-disable max-len */
export enum AccountErrorCodes {
  XTS101 = 'XTS101',
  XTS102 = 'XTS102',
  XTS103 = 'XTS103',
  XTS104 = 'XTS104',
  XTS105 = 'XTS105',
  XTS106 = 'XTS106',
  XTS107 = 'XTS107',
  XTS108 = 'XTS108',
}

export const ErrorCodeToSorryPageText = {
  [AccountErrorCodes.XTS101]: {
    header: 'We Are Not Able To Proceed With Account Creation',
    subheader: 'Looks like we were unable to find your DOT/MC.. If this a mistake, please call support.',
    helpText: 'Please provide this error code to our Support team for prompt assistance.',
    buttonText: 'Back to carrier search',
  },
  [AccountErrorCodes.XTS102]: {
    header: 'We Are Not Able To Proceed With Account Creation',
    subheader:
      'Due to FMCSA compliance reasons, we are not able to proceed with the account creation for your company.',
    helpText:
      'Please reach out to our Support team for further assistance if you believe you received this error incorrectly.',
    buttonText: 'Back to carrier search',
  },
  [AccountErrorCodes.XTS103]: {
    header: 'We Are Not Able To Proceed With Account Creation',
    subheader: 'We have encountered an error from our side. Please contact Support for further assistance.',
    helpText: 'Please provide this error code to our Support team for prompt assistance.',
    buttonText: 'Back to carrier search',
  },
  [AccountErrorCodes.XTS104]: {
    header: 'We Are Not Able To Proceed With Account Creation',
    subheader:
      'Due to FMCSA compliance reasons, we are not able to proceed with the account creation for your company. Please reach out to our Support team for further assistance if you believe you received this error incorrectly.',
    helpText: 'Please provide this error code to our Support team for prompt assistance.',
    buttonText: 'Back to carrier search',
  },
  [AccountErrorCodes.XTS105]: {
    header: 'We Are Not Able To Proceed With Registration',
    subheader: 'We have encountered an error from our side. Please contact Support for further assistance.',
    helpText: 'Please provide this error code to our Support team for prompt assistance.',
    buttonText: 'Back to loads',
    loads: true,
  },
  [AccountErrorCodes.XTS106]: {
    header: 'We Are Not Able To Proceed With Registration',
    subheader:
      'Due to FMCSA compliance reasons, we are not able to proceed with the setup with RMIS. Please reach out to our Support team for further assistance if you believe you received this error incorrectly.',
    helpText: 'Please provide this error code to our Support team for prompt assistance.',
    buttonText: 'Back to loads',
    loads: true,
  },
  [AccountErrorCodes.XTS107]: {
    header: 'We Are Not Able To Proceed With Registration',
    subheader:
      'We cannot continue with the registration process. You must contact your company’s administrator(s) so they can sign up for an Xpress Technologies account first. They will be able to add you as a company driver once that is completed!',
    helpText: 'Please provide this error code to our Support team for prompt assistance.',
    buttonText: 'Back to carrier search',
  },
  [AccountErrorCodes.XTS108]: {
    header: 'We Are Not Able To Proceed With Registration',
    subheader:
      'Due to FMCSA compliance reasons, we are not able to proceed with the setup with RMIS. Please reach out to our Support team for further assistance if you believe you received this error incorrectly.',
    helpText: 'Please provide this error code to our Support team for prompt assistance.',
    buttonText: 'Back to carrier search',
  },
};
