import gql from 'graphql-tag';

export const GetTrucksByCarrierId = gql(`
  query GetTrucksByCarrierId($carrierId: ID!) {
    getTrucksByCarrierId(carrierId: $carrierId) {
      total
      result {
        id
        unitId
        carrierId
        imei
        type
      }
    }
  }
`);
