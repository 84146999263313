export class DispatchLocation {
  address: string;
  lat: number;
  lon: number;
  timezone: string;
  timestamp: number;
  notes: string;
  createdBy: string;
  createdAt: number;
  updatedBy: string;
  updatedAt: number;
  distanceToPickUpInMiles: number;

  constructor(props?: Partial<DispatchLocation>) {
    Object.assign(this, props);
  }
}
