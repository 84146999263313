<mat-toolbar color="primary">
  <mat-toolbar-row>
    <a routerLink="/home">
      <img src="assets/images/Haulynx-Powered-by-XT-White.png" class="logo" alt="Haulynx" />
    </a>
  </mat-toolbar-row>
</mat-toolbar>

<mat-tab-group class="page-title">
  <mat-tab label="Share Location"></mat-tab>
</mat-tab-group>

<router-outlet></router-outlet>
