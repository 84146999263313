import { Injectable } from '@angular/core';
import { GetFeatures } from '@haulynx/gql';
import { FeatureFlagState, initialFeatureFlagState } from '@haulynx/types';
import { get } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GraphqlService } from '../graphql.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagQueryService {
  constructor(private graphqlService: GraphqlService) {}

  getFeatureFlag(environmentName: string, platform = 'web'): Observable<FeatureFlagState> {
    const environment = `${environmentName}`;
    return this.graphqlService
      .query({
        query: GetFeatures,
        variables: { environment, platform },
      })
      .pipe(
        map((result: unknown): FeatureFlagState => {
          const features: unknown[] = get(result, `data.getFeatureFlags`, []);
          const flags = { ...initialFeatureFlagState.flags };
          features.forEach((value: { name: string; description: string }) => {
            flags[value.name] = true;
          });
          return { flags } as FeatureFlagState;
        })
      );
  }
}
