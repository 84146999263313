import { IColumns2 } from '@haulynx/types';

export const data = [
  {
    id: '1861195',
    name: 'Cadence Premier Cargo, Inc.',
    owner: 'JKUHENBEAK',
    phone: '(708) 808-9850',
    dot: '1861195',
    match: '96.0',
    email: 'james@cadencepremier.com',
    price: 750,
    sourceType: 'Digital',
    __typename: 'CarrierMatch',
  },
  {
    id: '419752',
    name: 'King s Service, L.L.C.',
    owner: 'UNKNOWN',
    phone: '(301) 910-4112',
    dot: '419752',
    match: '96.0',
    email: 'ggranger@endlessseasons.com',
    price: 750,
    sourceType: 'Traditional',
    __typename: 'CarrierMatch',
  },
  {
    id: '1637762',
    name: 'Real Trucking Inc.',
    owner: 'UNKNOWN',
    phone: '(708) 983-7325',
    dot: '1637762',
    match: '96.0',
    email: 'max@real.us',
    price: 750,
    sourceType: 'Traditional',
    __typename: 'CarrierMatch',
  },
  {
    id: '1471436',
    name: 'Perfecta Express Inc',
    owner: 'UNKNOWN',
    phone: '(216) 577-9166',
    dot: '1471436',
    match: '96.0',
    email: 'dispatch@perfectaexpress.com',
    price: 750,
    sourceType: 'Traditional',
    __typename: 'CarrierMatch',
  },
  {
    id: '2022232',
    name: 'Real Transport Inc',
    owner: 'UNKNOWN',
    phone: '(708) 544-2222',
    dot: '2022232',
    match: '96.0',
    email: 'dispatch@real.us',
    price: 750,
    sourceType: 'Traditional',
    __typename: 'CarrierMatch',
  },
  {
    id: '3100667',
    name: 'Truckstar Logistics Inc',
    owner: 'UNKNOWN',
    phone: '(470) 485-1866',
    dot: '3100667',
    match: '96.0',
    email: 'truckstarse@gmail.com',
    price: 750,
    sourceType: 'Traditional',
    __typename: 'CarrierMatch',
  },
  {
    id: '2318921',
    name: 'B & M Logistics LLC',
    owner: 'GBAUGH',
    phone: '(567) 938-7431',
    dot: '2318921',
    match: '75.0',
    email: 'bmlogistics@ymail.com',
    price: 750,
    sourceType: 'Digital',
    __typename: 'CarrierMatch',
  },
  {
    id: '419752',
    name: 'King s Service, L.L.C.',
    owner: 'UNKNOWN',
    phone: '(301) 910-4112',
    dot: '419752',
    match: '96.0',
    email: 'ggranger@endlessseasons.com',
    price: 750,
    sourceType: 'Traditional',
    __typename: 'CarrierMatch',
  },
  {
    id: '1637762',
    name: 'Real Trucking Inc.',
    owner: 'UNKNOWN',
    phone: '(708) 983-7325',
    dot: '1637762',
    match: '96.0',
    email: 'max@real.us',
    price: 750,
    sourceType: 'Traditional',
    __typename: 'CarrierMatch',
  },
  {
    id: '1471436',
    name: 'Perfecta Express Inc',
    owner: 'UNKNOWN',
    phone: '(216) 577-9166',
    dot: '1471436',
    match: '96.0',
    email: 'dispatch@perfectaexpress.com',
    price: 750,
    sourceType: 'Traditional',
    __typename: 'CarrierMatch',
  },
  {
    id: '2022232',
    name: 'Real Transport Inc',
    owner: 'UNKNOWN',
    phone: '(708) 544-2222',
    dot: '2022232',
    match: '96.0',
    email: 'dispatch@real.us',
    price: 750,
    sourceType: 'Traditional',
    __typename: 'CarrierMatch',
  },
  {
    id: '3100667',
    name: 'Truckstar Logistics Inc',
    owner: 'UNKNOWN',
    phone: '(470) 485-1866',
    dot: '3100667',
    match: '96.0',
    email: 'truckstarse@gmail.com',
    price: 750,
    sourceType: 'Traditional',
    __typename: 'CarrierMatch',
  },
  {
    id: '2318921',
    name: 'B & M Logistics LLC',
    owner: 'GBAUGH',
    phone: '(567) 938-7431',
    dot: '2318921',
    match: '75.0',
    email: 'bmlogistics@ymail.com',
    price: 750,
    sourceType: 'Digital',
    __typename: 'CarrierMatch',
  },
  {
    id: '419752',
    name: 'King s Service, L.L.C.',
    owner: 'UNKNOWN',
    phone: '(301) 910-4112',
    dot: '419752',
    match: '96.0',
    email: 'ggranger@endlessseasons.com',
    price: 750,
    sourceType: 'Traditional',
    __typename: 'CarrierMatch',
  },
  {
    id: '1637762',
    name: 'Real Trucking Inc.',
    owner: 'UNKNOWN',
    phone: '(708) 983-7325',
    dot: '1637762',
    match: '96.0',
    email: 'max@real.us',
    price: 750,
    sourceType: 'Traditional',
    __typename: 'CarrierMatch',
  },
  {
    id: '1471436',
    name: 'Perfecta Express Inc',
    owner: 'UNKNOWN',
    phone: '(216) 577-9166',
    dot: '1471436',
    match: '96.0',
    email: 'dispatch@perfectaexpress.com',
    price: 750,
    sourceType: 'Traditional',
    __typename: 'CarrierMatch',
  },
  {
    id: '2022232',
    name: 'Real Transport Inc',
    owner: 'UNKNOWN',
    phone: '(708) 544-2222',
    dot: '2022232',
    match: '96.0',
    email: 'dispatch@real.us',
    price: 750,
    sourceType: 'Traditional',
    __typename: 'CarrierMatch',
  },
  {
    id: '3100667',
    name: 'Truckstar Logistics Inc',
    owner: 'UNKNOWN',
    phone: '(470) 485-1866',
    dot: '3100667',
    match: '96.0',
    email: 'truckstarse@gmail.com',
    price: 750,
    sourceType: 'Traditional',
    __typename: 'CarrierMatch',
  },
  {
    id: '2318921',
    name: 'B & M Logistics LLC',
    owner: 'GBAUGH',
    phone: '(567) 938-7431',
    dot: '2318921',
    match: '75.0',
    email: 'bmlogistics@ymail.com',
    price: 750,
    sourceType: 'Digital',
    __typename: 'CarrierMatch',
  },
  {
    id: '419752',
    name: 'King s Service, L.L.C.',
    owner: 'UNKNOWN',
    phone: '(301) 910-4112',
    dot: '419752',
    match: '96.0',
    email: 'ggranger@endlessseasons.com',
    price: 750,
    sourceType: 'Traditional',
    __typename: 'CarrierMatch',
  },
  {
    id: '1637762',
    name: 'Real Trucking Inc.',
    owner: 'UNKNOWN',
    phone: '(708) 983-7325',
    dot: '1637762',
    match: '96.0',
    email: 'max@real.us',
    price: 750,
    sourceType: 'Traditional',
    __typename: 'CarrierMatch',
  },
  {
    id: '1471436',
    name: 'Perfecta Express Inc',
    owner: 'UNKNOWN',
    phone: '(216) 577-9166',
    dot: '1471436',
    match: '96.0',
    email: 'dispatch@perfectaexpress.com',
    price: 750,
    sourceType: 'Traditional',
    __typename: 'CarrierMatch',
  },
  {
    id: '2022232',
    name: 'Real Transport Inc',
    owner: 'UNKNOWN',
    phone: '(708) 544-2222',
    dot: '2022232',
    match: '96.0',
    email: 'dispatch@real.us',
    price: 750,
    sourceType: 'Traditional',
    __typename: 'CarrierMatch',
  },
  {
    id: '3100667',
    name: 'Truckstar Logistics Inc',
    owner: 'UNKNOWN',
    phone: '(470) 485-1866',
    dot: '3100667',
    match: '96.0',
    email: 'truckstarse@gmail.com',
    price: 750,
    sourceType: 'Traditional',
    __typename: 'CarrierMatch',
  },
  {
    id: '2318921',
    name: 'B & M Logistics LLC',
    owner: 'GBAUGH',
    phone: '(567) 938-7431',
    dot: '2318921',
    match: '75.0',
    email: 'bmlogistics@ymail.com',
    price: 750,
    sourceType: 'Digital',
    __typename: 'CarrierMatch',
  },
  {
    id: '419752',
    name: 'King s Service, L.L.C.',
    owner: 'UNKNOWN',
    phone: '(301) 910-4112',
    dot: '419752',
    match: '96.0',
    email: 'ggranger@endlessseasons.com',
    price: 750,
    sourceType: 'Traditional',
    __typename: 'CarrierMatch',
  },
  {
    id: '1637762',
    name: 'Real Trucking Inc.',
    owner: 'UNKNOWN',
    phone: '(708) 983-7325',
    dot: '1637762',
    match: '96.0',
    email: 'max@real.us',
    price: 750,
    sourceType: 'Traditional',
    __typename: 'CarrierMatch',
  },
  {
    id: '1471436',
    name: 'Perfecta Express Inc',
    owner: 'UNKNOWN',
    phone: '(216) 577-9166',
    dot: '1471436',
    match: '96.0',
    email: 'dispatch@perfectaexpress.com',
    price: 750,
    sourceType: 'Traditional',
    __typename: 'CarrierMatch',
  },
  {
    id: '2022232',
    name: 'Real Transport Inc',
    owner: 'UNKNOWN',
    phone: '(708) 544-2222',
    dot: '2022232',
    match: '96.0',
    email: 'dispatch@real.us',
    price: 750,
    sourceType: 'Traditional',
    __typename: 'CarrierMatch',
  },
  {
    id: '3100667',
    name: 'Truckstar Logistics Inc',
    owner: 'UNKNOWN',
    phone: '(470) 485-1866',
    dot: '3100667',
    match: '96.0',
    email: 'truckstarse@gmail.com',
    price: 750,
    sourceType: 'Traditional',
    __typename: 'CarrierMatch',
  },
  {
    id: '2318921',
    name: 'B & M Logistics LLC',
    owner: 'GBAUGH',
    phone: '(567) 938-7431',
    dot: '2318921',
    match: '75.0',
    email: 'bmlogistics@ymail.com',
    price: 750,
    sourceType: 'Digital',
    __typename: 'CarrierMatch',
  },
  {
    id: '419752',
    name: 'King s Service, L.L.C.',
    owner: 'UNKNOWN',
    phone: '(301) 910-4112',
    dot: '419752',
    match: '96.0',
    email: 'ggranger@endlessseasons.com',
    price: 750,
    sourceType: 'Traditional',
    __typename: 'CarrierMatch',
  },
  {
    id: '1637762',
    name: 'Real Trucking Inc.',
    owner: 'UNKNOWN',
    phone: '(708) 983-7325',
    dot: '1637762',
    match: '96.0',
    email: 'max@real.us',
    price: 750,
    sourceType: 'Traditional',
    __typename: 'CarrierMatch',
  },
  {
    id: '1471436',
    name: 'Perfecta Express Inc',
    owner: 'UNKNOWN',
    phone: '(216) 577-9166',
    dot: '1471436',
    match: '96.0',
    email: 'dispatch@perfectaexpress.com',
    price: 750,
    sourceType: 'Traditional',
    __typename: 'CarrierMatch',
  },
  {
    id: '2022232',
    name: 'Real Transport Inc',
    owner: 'UNKNOWN',
    phone: '(708) 544-2222',
    dot: '2022232',
    match: '96.0',
    email: 'dispatch@real.us',
    price: 750,
    sourceType: 'Traditional',
    __typename: 'CarrierMatch',
  },
  {
    id: '3100667',
    name: 'Truckstar Logistics Inc',
    owner: 'UNKNOWN',
    phone: '(470) 485-1866',
    dot: '3100667',
    match: '96.0',
    email: 'truckstarse@gmail.com',
    price: 750,
    sourceType: 'Traditional',
    __typename: 'CarrierMatch',
  },
  {
    id: '2318921',
    name: 'B & M Logistics LLC',
    owner: 'GBAUGH',
    phone: '(567) 938-7431',
    dot: '2318921',
    match: '75.0',
    email: 'bmlogistics@ymail.com',
    price: 750,
    sourceType: 'Digital',
    __typename: 'CarrierMatch',
  },
  {
    id: '419752',
    name: 'King s Service, L.L.C.',
    owner: 'UNKNOWN',
    phone: '(301) 910-4112',
    dot: '419752',
    match: '96.0',
    email: 'ggranger@endlessseasons.com',
    price: 750,
    sourceType: 'Traditional',
    __typename: 'CarrierMatch',
  },
  {
    id: '1637762',
    name: 'Real Trucking Inc.',
    owner: 'UNKNOWN',
    phone: '(708) 983-7325',
    dot: '1637762',
    match: '96.0',
    email: 'max@real.us',
    price: 750,
    sourceType: 'Traditional',
    __typename: 'CarrierMatch',
  },
  {
    id: '1471436',
    name: 'Perfecta Express Inc',
    owner: 'UNKNOWN',
    phone: '(216) 577-9166',
    dot: '1471436',
    match: '96.0',
    email: 'dispatch@perfectaexpress.com',
    price: 750,
    sourceType: 'Traditional',
    __typename: 'CarrierMatch',
  },
  {
    id: '2022232',
    name: 'Real Transport Inc',
    owner: 'UNKNOWN',
    phone: '(708) 544-2222',
    dot: '2022232',
    match: '96.0',
    email: 'dispatch@real.us',
    price: 750,
    sourceType: 'Traditional',
    __typename: 'CarrierMatch',
  },
  {
    id: '3100667',
    name: 'Truckstar Logistics Inc',
    owner: 'UNKNOWN',
    phone: '(470) 485-1866',
    dot: '3100667',
    match: '96.0',
    email: 'truckstarse@gmail.com',
    price: 750,
    sourceType: 'Traditional',
    __typename: 'CarrierMatch',
  },
  {
    id: '2318921',
    name: 'B & M Logistics LLC',
    owner: 'GBAUGH',
    phone: '(567) 938-7431',
    dot: '2318921',
    match: '75.0',
    email: 'bmlogistics@ymail.com',
    price: 750,
    sourceType: 'Digital',
    __typename: 'CarrierMatch',
  },
  {
    id: '419752',
    name: 'King s Service, L.L.C.',
    owner: 'UNKNOWN',
    phone: '(301) 910-4112',
    dot: '419752',
    match: '96.0',
    email: 'ggranger@endlessseasons.com',
    price: 750,
    sourceType: 'Traditional',
    __typename: 'CarrierMatch',
  },
  {
    id: '1637762',
    name: 'Real Trucking Inc.',
    owner: 'UNKNOWN',
    phone: '(708) 983-7325',
    dot: '1637762',
    match: '96.0',
    email: 'max@real.us',
    price: 750,
    sourceType: 'Traditional',
    __typename: 'CarrierMatch',
  },
  {
    id: '1471436',
    name: 'Perfecta Express Inc',
    owner: 'UNKNOWN',
    phone: '(216) 577-9166',
    dot: '1471436',
    match: '96.0',
    email: 'dispatch@perfectaexpress.com',
    price: 750,
    sourceType: 'Traditional',
    __typename: 'CarrierMatch',
  },
  {
    id: '2022232',
    name: 'Real Transport Inc',
    owner: 'UNKNOWN',
    phone: '(708) 544-2222',
    dot: '2022232',
    match: '96.0',
    email: 'dispatch@real.us',
    price: 750,
    sourceType: 'Traditional',
    __typename: 'CarrierMatch',
  },
  {
    id: '3100667',
    name: 'Truckstar Logistics Inc',
    owner: 'UNKNOWN',
    phone: '(470) 485-1866',
    dot: '3100667',
    match: '96.0',
    email: 'truckstarse@gmail.com',
    price: 750,
    sourceType: 'Traditional',
    __typename: 'CarrierMatch',
  },
  {
    id: '2318921',
    name: 'B & M Logistics LLC',
    owner: 'GBAUGH',
    phone: '(567) 938-7431',
    dot: '2318921',
    match: '75.0',
    email: 'bmlogistics@ymail.com',
    price: 750,
    sourceType: 'Digital',
    __typename: 'CarrierMatch',
  },
];

const getLoadFeedColumns = (): IColumns2[] => {
  const tableColumns: IColumns2[] = [
    {
      label: 'Carrier Name',
      field: 'name',
      width: '222px',
      isCustomCell: true,
      isSortable: true,
      isClickable: true,
      sortConfig: [],
    },
    {
      label: 'Ownership',
      field: 'owner',
      width: '138px',
      isCustomCell: false,
      isSortable: true,
      sortConfig: [],
    },
    {
      label: 'Type',
      field: 'sourceType',
      isCustomCell: false,
      isSortable: true,
      isClickable: true,
      width: '138px',
      sortConfig: [],
    },
    {
      label: 'DOT',
      field: 'dot',
      width: '140px',
      isCustomCell: false,
      isSortable: true,
      sortConfig: [],
    },
    {
      field: 'clicker',
      isCustomCell: true,
      isSortable: false,
      label: '',
      width: '138px',
      sortConfig: [],
    },
    {
      field: 'action',
      isCustomCell: true,
      label: '',
      width: '130px',
      isSortable: false,
      sortConfig: [],
    },
    {
      label: '',
      field: 'bookLoadButton',
      isCustomCell: true,
      isSortable: false,
      sortConfig: [],
      isSticky: true,
    },
  ];

  return tableColumns;
};

export const columns: IColumns2[] = getLoadFeedColumns();
