import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AppModel, LoadEntityService, LoadActiveTabsModel } from '@haulynx/store';
import { LoadsServiceLoad, Tab, User } from '@haulynx/types';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, delay } from 'rxjs/operators';
import { LoadViewService, NotificationsService } from '@haulynx/services';

@Injectable()
export class VerifyCanViewLoadDetailsGuard implements CanActivate {
  constructor(
    private appModel: AppModel,
    private router: Router,
    private loadEntityService: LoadEntityService,
    private loadViewService: LoadViewService,
    private loadActiveTabsModel: LoadActiveTabsModel,
    private notificationsService: NotificationsService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.appModel.user$.pipe(
      switchMap((user: User) => {
        const loadId: string = route?.params?.id;
        let canView: boolean = false;
        if (loadId) {
          this.loadEntityService.getLoadByIdManager.dispatch(loadId);
          return this.loadEntityService.getLoadByIdManager.getEntityById(loadId).pipe(
            // There is a bug occuring on carrier site when redirecting from CarrierLoadSearchModule to Dashboard ActiveLoadDetail view
            // The load initially comes back null here because the graphQl query fails on the actual HTTP Post (i.e. it never even gets mapped over)
            // This causes the guard to fail instantly even though the load then comes back successfully on the very next pass
            // Believe this is due to some odd race condition / concurrency issue. A 1 millisecond delay before filtering fixes the issue.
            delay(1),
            filter((load) => load !== null),
            map((load: LoadsServiceLoad) => {
              canView = this.loadViewService.userCanViewCreatedLoadDetail(user, load);
              if (!canView) {
                const tab = new Tab({ id: load?.id });
                this.loadActiveTabsModel.removeTabs(tab);
                this.notificationsService.error(`Unauthorized to view details for load: ${load?.id}`);
                this.router.navigate(['/dashboard/loads/search/active']);
              }
              return canView;
            })
          );
        }
        return of(canView);
      })
    );
  }
}
