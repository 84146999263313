import gql from 'graphql-tag';

export const UpdateLocationDepartureTime = gql(`
  mutation UpdateLocationDepartureTime($loadId: StrictId!, $locationIndex: NonNegativeInt!, $departureTime: NonNegativeInt!) {
    updateLocationDepartureTime(loadId: $loadId, locationIndex: $locationIndex, departureTime: $departureTime) {
        arrivalTime:carrierArrival
        departureTime:carrierDeparture
        name:address
    }
  }
`);
