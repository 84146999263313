import { AppSettingsActionTypes } from './app-settings.actions';
import { AppSettingsState } from './app-settings.state';
import { DispatchAction } from '../shared/store/helpers/action';

export const initialState = new AppSettingsState();

export function appSettingsReducer(state = initialState, action: DispatchAction): AppSettingsState {
  switch (action.type) {
    case AppSettingsActionTypes.LOG_OUT:
      return state.merge({
        isLoading: true,
      });

    case AppSettingsActionTypes.LOG_OUT_SUCCESS:
      return state.merge({
        isLoading: false,
      });

    case AppSettingsActionTypes.LOG_IN:
      return state.merge({
        isLoading: true,
      });

    case AppSettingsActionTypes.LOG_IN_SUCCESS: {
      return state.merge({
        isLoading: false,
      });
    }

    case AppSettingsActionTypes.UPDATE_INIT_APP: {
      return state.merge({ ...action.payload, isAppInit: false });
    }

    case AppSettingsActionTypes.START_INIT_APP: {
      return state.merge({ isAppInit: true });
    }

    case AppSettingsActionTypes.UPDATE_USER: {
      const user = action.payload;

      return state.merge({ user });
    }

    case AppSettingsActionTypes.UPDATE_TOKEN: {
      const token = action.payload;

      return state.merge({ token });
    }

    case AppSettingsActionTypes.CHECK_USER_PERMISSION_ERROR:
      return state.merge({
        isLoading: false,
        ...action.payload,
      });

    case AppSettingsActionTypes.CHECK_USER_PERMISSION_SUCCESS:
      return state.merge({
        isLoading: false,
        ...action.payload,
      });

    case AppSettingsActionTypes.LOG_IN_ERROR:
      return state.merge({
        isLoading: false,
        user: null,
        token: null,
      });

    case AppSettingsActionTypes.SET_WIDGET_REFERRER:
      return state.merge({
        widgetReferrer: action.payload,
      });

    case AppSettingsActionTypes.SET_LOAD_DETAILS_REDIRECT_URL:
      return state.merge({
        loadDetailsRedirectUrl: action.payload,
      });

    default:
      return state;
  }
}
