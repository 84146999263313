import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CarrierService, DriverMarker, MapService, NotificationsService, WindowRef } from '@haulynx/services';
import { FleetModel, UserEntityService } from '@haulynx/store';
import {
  buttonTypes,
  Carrier,
  Device,
  devicesTableConfigOptions,
  FleetTrailerSearch,
  IColumns,
  IColumns2,
  NewTrailer,
  radioTypes,
  Trailer,
  TrailerDialogResult,
  Truck,
  User,
} from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { filter, find, get, has, head, map as lodashMap } from 'lodash';
import moment from 'moment-timezone';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { first, map, takeUntil } from 'rxjs/operators';
import { TrailerFormDialog } from '../dialogs/fleet/trailer-form/trailer-form.component';
import { NewTruckDialog } from '../dialogs/fleet/truck-new.component';
import { SimulatePositionComponent } from '../dialogs/simulate-position/simulate-position.component';
import { getDeviceColumns, getTrailerColumns, getTruckColumns } from './fleet.config';

const US_CENTER = {
  lat: 37.0902,
  lon: -95.7129,
};

@Component({
  selector: 'haulynx-fleet',
  templateUrl: './fleet.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./fleet.component.scss'],
})
export class FleetComponent implements OnInit, OnDestroy {
  @Input() showMap = true;
  @ViewChild('parent') parent: ElementRef;
  alive = aliveWhile();
  deviceform: FormGroup;
  carrierId: string;
  carrierDot: string;
  carriers: Carrier[] = [];
  center: { lat: number; lon: number } = US_CENTER;
  trailers: Trailer[] = [];
  trailersColumns: IColumns[] = [];
  status = [radioTypes.ALL, radioTypes.AVAILABLE, radioTypes.UNAVAILABLE];
  filter = radioTypes.ALL.action;
  truckColumns: IColumns[] = [];
  trucks$: BehaviorSubject<Truck[]> = new BehaviorSubject([]);
  deviceColumns: IColumns2[] = [];
  devices$: BehaviorSubject<Device[]> = new BehaviorSubject([]);
  tabHeight = '';
  mdiDevices$: Observable<Device[]> = this.devices$.pipe(
    map((devices) => filter(devices, (device) => this.isTruck(device)))
  );
  phonesAndTablets$: Observable<Device[]> = this.devices$.pipe(
    map((devices) => {
      const phones = filter(devices, (device) => this.isPhone(device));
      return lodashMap(phones, (phone, index) => {
        return { index: index + 1, ...phone };
      });
    })
  );
  public zoom = 5;
  public markers: DriverMarker[] = [];
  public mapIcon = {
    url: './assets/images/moving-company.svg',
    scaledSize: {
      width: 48,
      height: 48,
    },
  };
  public phoneIcon = {
    url: './assets/images/phone.svg',
    scaledSize: {
      width: 48,
      height: 48,
    },
  };

  public selectedIndex = 0;
  user: User;
  isLoadingDevice = false;
  isLoadingTruck = false;

  dialogRef: MatDialogRef<TrailerFormDialog> | null;
  truckActionButtons = new BehaviorSubject([]);

  configOptions = devicesTableConfigOptions;
  stickyColumnWidth = '100px';
  constructor(
    private carrierService: CarrierService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private mapService: MapService,
    private route: ActivatedRoute,
    private router: Router,
    private snackbar: MatSnackBar,
    private notifications: NotificationsService,
    private userEntityService: UserEntityService,
    private windowRef: WindowRef,
    public fleetModel: FleetModel
  ) {
    this.userEntityService.user$.pipe(takeUntil(this.alive)).subscribe((user) => {
      this.user = user;
      this.carrierDot = get(this.user, 'carrier.dot');
    });
    this.route.queryParams.pipe(first(), takeUntil(this.alive)).subscribe((params: { carrier: string }) => {
      const { carrier } = params;
      if (carrier) {
        this.carrierId = carrier;
      }
    });

    this.route.queryParams.pipe(takeUntil(this.alive)).subscribe((params) => {
      const { action } = params;

      switch (action) {
        case buttonTypes.ADD_TRUCK.action:
          this.create(buttonTypes.ADD_TRUCK.action, true);
          break;
      }
    });

    this.deviceform = this.formBuilder.group({
      truck: [true],
      phone: [true],
    });
  }

  ngOnInit(): void {
    const { isHaulynxAdmin, broker, isCompanyAdmin, carrier } = this.user;
    const canViewCarriers = isHaulynxAdmin || (broker && broker.isHaulynxBroker);

    this.checkCarrierAddress(carrier);

    if (isCompanyAdmin) {
      this.carrierId = this.user.carrier.id;
    }

    if (!this.carrierId && this.user.carrier) {
      this.carrierId = this.user.carrier.id;
    }

    if (canViewCarriers) {
      this.carrierService
        .getCarriers()
        .pipe(
          map((carriers: Carrier[]) => {
            const noCarrier = new Carrier();
            noCarrier.name = ' -- Unassigned';
            noCarrier.id = '-1';

            const allCarrier = new Carrier();
            allCarrier.name = ' -- All';
            allCarrier.id = '0';

            return [noCarrier, allCarrier, ...carriers];
          }),
          takeUntil(this.alive)
        )
        .subscribe((carriers: Carrier[]) => {
          this.carriers = carriers;

          if (this.carriers.length > 0 && !this.carrierId) {
            const firstCarrier = head(this.carriers);
            if (firstCarrier) {
              this.carrierId = firstCarrier.id;
            }
          }
          this.search(this.carrierId);
        });
    } else {
      this.search(this.carrierId);
    }

    if (isCompanyAdmin || isHaulynxAdmin) {
      this.truckActionButtons.next([buttonTypes.ADD_TRUCK]);
    }

    this.trailersColumns = getTrailerColumns(this.user, this.carrierId);
    this.truckColumns = getTruckColumns(this.user);
    this.deviceColumns = getDeviceColumns(this.user);
    this.addMarkers(true, true);
    this.listenToForm();

    this.fleetModel.fleetTrailers$.pipe(takeUntil(this.alive)).subscribe((entities) => {
      this.trailers = entities.filter((trailer) => !trailer.inactive);
    });
  }

  ngOnDestroy() {
    this.alive.destroy();
  }

  rowHeights(event: Array<{ value: number; row: string }>) {
    const rowSpacing = 5;
    const rowHeightsTotal = event.map((vals) => vals.value + rowSpacing).reduce((total, num) => total + num);
    this.getTabHeight(rowHeightsTotal);
  }

  changeCarrier(value: string): void {
    this.tabHeight = '';
    this.carrierId = value;

    this.search(value);
  }

  tabSwitched(): void {
    if (this.selectedIndex === 1) {
      if (!['-1', '0'].includes(this.carrierId)) this.updateTrailers();
    } else if (this.selectedIndex === 2) {
      const windowRef = this.windowRef.getNativeWindow();
      windowRef.dispatchEvent(new Event('resize'));
    }
  }

  updateTrailers(): void {
    const query: FleetTrailerSearch = { carrierId: this.carrierId };
    this.fleetModel.searchFleetTrailerRecords({ query });
  }

  search(carrierId = '-1'): void {
    this.getTrucksAndDevices(carrierId, this.filter);
    if (this.selectedIndex === 1 && !['-1', '0'].includes(this.carrierId)) this.updateTrailers();
  }

  createEditOrDeleteTrailer(trailer: Trailer = null): void {
    this.dialogRef = this.dialog.open(TrailerFormDialog, {
      data: trailer == null ? new Trailer('', 'Other') : trailer,
    });

    this.dialogRef
      .afterClosed()
      .pipe(takeUntil(this.alive))
      .subscribe((result) => {
        if (result) {
          if (!result.delete) {
            this.trailerActionFromResult(result);
          } else {
            if (!has(result, 'delete')) return;
            this.deleteTrailer(result);
          }
        }
      });
  }

  addNewTruck(truck: Truck = null, chainModals = false): void {
    this.dialog
      .open(NewTruckDialog, {
        data: truck == null ? new Truck() : truck,
        width: '420px',
      })
      .afterClosed()
      .pipe(takeUntil(this.alive))
      .subscribe((newTruck) => {
        if (newTruck) {
          this.isLoadingTruck = true;

          const carrierId = this.user.isHaulynxAdmin ? this.carrierId : this.user.carrier.id;
          const data = { carrierId: carrierId, userId: this.user.id };

          this.carrierService
            .createTruck(newTruck as Truck, data)
            .pipe(takeUntil(this.alive))
            .subscribe(
              () => {
                this.search(this.carrierId);
                this.notifications.success('Added Truck', 'Success!');
              },
              (error) => {
                this.search(this.carrierId);
                if (JSON.stringify(error).includes(newTruck.vin)) {
                  this.notifications.error(
                    `The vin ${newTruck.vin} already exists in the Haulynx system. Please contact Haulynx customer service to resolve this.`
                  );
                } else {
                  this.notifications.error(error);
                }
              }
            );
        }
      });
  }

  simulatePosition(truck: Truck): void {
    const currentCarrier = find(this.carriers, (carrier) => carrier.id === this.carrierId);

    this.dialog.open(SimulatePositionComponent, {
      data: { truck, carrier: currentCarrier },
      width: '420px',
    });
  }

  create(action: string, chainModals = false): void {
    if (action === buttonTypes.ADD_TRUCK.action) {
      this.addNewTruck(null, chainModals);
    }
  }

  updateTruckImei(value: string, truck: Truck): void {
    const data = {
      imei: value,
      carrierId: truck.carrierId,
      truckId: truck.id,
      truck,
    };

    const originalImei = truck.imei;
    if (value !== originalImei) {
      this.http
        .post('/api/devices/assign', JSON.stringify({ ...data }))
        .toPromise()
        .then(() => {
          const message =
            value === '' ? `Successfully removed truck's device.` : `Successfully updated truck's device.`;
          this.snackbar.open(message, '', { duration: 4000 });
        })
        .catch(() => {
          const message = value === '' ? `Unable to remove truck's device.` : `Unable to update truck's device.`;
          this.snackbar.open(message, '', { duration: 4000 });
        });
    }
  }

  private getTabHeight(rowHeightTotal: number) {
    const staticHeaderFooterHeights = 220;
    if (this.tabHeight.length === 0) {
      this.tabHeight = String(staticHeaderFooterHeights + rowHeightTotal);
    }
  }

  private trailerActionFromResult(result): void {
    if (result.id) return this.updateTrailer(result);
    this.createTrailer(result);
  }

  /**
   * Check for carrier address and move map center if address is valid
   * @param carrier
   */
  private checkCarrierAddress(carrier: Carrier): void {
    // Check to make sure there is at least a single address point
    if (carrier && (carrier.addressStreet || carrier.addressCity || carrier.addressState || carrier.addressZip)) {
      this.mapService.getMapsApiPromise().then(() => {
        const elemMap = document.createElement('div');
        const placeFromQuery = new google.maps.places.PlacesService(elemMap);

        let query = carrier.addressStreet ? `${carrier.addressStreet}` : '';
        query += carrier.addressStreet2 ? `, ${carrier.addressStreet2}` : '';
        query += carrier.addressCity ? `, ${carrier.addressCity}` : '';
        query += carrier.addressState ? `, ${carrier.addressState}` : '';
        query += carrier.addressZip ? ` ${carrier.addressZip}` : '';

        placeFromQuery.findPlaceFromQuery(
          { fields: ['geometry', 'formatted_address'], query },
          (results: google.maps.places.PlaceResult[], status: google.maps.places.PlacesServiceStatus) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
              const { location } = results[0].geometry;
              this.center = { lat: location.lat(), lon: location.lng() };
              this.zoom = 10;
            }
          }
        );
      });
    }
  }

  private isPhone(device: Device | Truck): boolean {
    return device.type === 'phone';
  }

  private isTruck(device: Device | Truck): boolean {
    return (device.type === 'TELEMATICS' || device.type === 'mdi') && !!device.imei;
  }

  private addMarkers(truck: boolean, phone: boolean): void {
    if (truck === false && phone === true) {
      this.phonesAndTablets$
        .pipe(
          map((phonesAndTablets) => filter(phonesAndTablets, (item) => !!item.lastLocation)),
          first(),
          takeUntil(this.alive)
        )
        .subscribe((phonesAndTablets) => {
          this.markers = this.createMarkers(phonesAndTablets);
        });
    } else if (truck === true && phone === false) {
      this.trucks$
        .pipe(
          map((trucks) => filter(trucks, (item) => !!item.lastLocation)),
          first(),
          takeUntil(this.alive)
        )
        .subscribe((trucks) => {
          this.markers = this.createMarkers(trucks);
        });
    } else if (truck === true && phone === true) {
      combineLatest(this.trucks$, this.phonesAndTablets$)
        .pipe(takeUntil(this.alive))
        .subscribe(([trucks, phonesAndTablets]) => {
          const trucksWithLocation = filter(trucks, (item) => !!item.lastLocation);
          const devicesWithLocation = filter(phonesAndTablets, (item) => !!item.lastLocation);
          this.markers = this.createMarkers(trucksWithLocation).concat(this.createMarkers(devicesWithLocation));
        });
    } else {
      this.markers = this.createMarkers([]);
    }
  }

  private createMarkers(devices: Device[] | Truck[]): DriverMarker[] {
    return lodashMap(devices, (item: Device | Truck) => {
      const label = item.type === 'phone' ? item.index : item.unitId ? item.unitId.slice(-7) : 'NA';
      const lastUpdated = get(item, 'lastUpdated', '');
      return {
        lat: item.lastLocation.gpsLat,
        lng: item.lastLocation.gpsLon,
        type: item.type,
        lastUpdated: moment
          .utc(lastUpdated, 'YYYY-MM-DD HH:mm:ss')
          .tz(this.user.prefs.timeZone)
          .format('YYYY-MM-DD HH:mm:ss'),
        status: (<Truck>item).truckState,
        label: {
          text: `${label}`,
          fontSize: '10px',
          color: 'white',
        },
        title: item.lastLocation.unitId,
        info: this.mapService.buildDriverInfo(item, null),
        driver: item.lastLocation.driver,
        iconUrl: item.type === 'phone' ? './assets/images/phone.svg' : './assets/images/moving-company.svg',
      };
    });
  }

  private createTrailer(result: Trailer): void {
    const params: NewTrailer = { carrierId: this.carrierId, trailer: result };
    this.fleetModel.createFleetTrailer(params);
  }

  private updateTrailer(result: Trailer): void {
    const params: NewTrailer = { carrierId: this.carrierId, trailer: result };
    this.fleetModel.updateFleetTrailer(params);
  }
  private deleteTrailer(result: TrailerDialogResult) {
    const trailer = new Trailer(result.trailer.trailerNumber, result.trailer.type || 'Other');
    trailer.id = result.trailer.id;
    trailer.inactive = true;
    const params: NewTrailer = { carrierId: this.carrierId, trailer: trailer };
    this.fleetModel.updateFleetTrailer(params);
  }

  private getTrucksAndDevices(carrierId, filter): void {
    // empty the arrays for the UI
    this.devices$.next([]);
    this.trucks$.next([]);

    this.isLoadingDevice = true;
    this.isLoadingTruck = true;

    this.carrierService
      .getTrucks(carrierId, filter)
      .pipe(takeUntil(this.alive))
      .subscribe(
        (trucks) => {
          const truckList = trucks.map((truck) => {
            return {
              ...truck,
              locationName: truck?.lastLocation?.approximateAddress,
            };
          });
          this.trucks$.next(truckList || []);
          this.isLoadingTruck = false;
        },
        () => {
          this.isLoadingTruck = false;
        }
      );
    this.carrierService
      .getDevices(carrierId, this.filter)
      .pipe(takeUntil(this.alive))
      .subscribe(
        (devices) => {
          const phoneList = devices.map((phone) => {
            return {
              carrier: phone.carrier,
              vin: phone.vin,
              imei: phone.imei,
              unitId: phone.unitId,
              lastLocation: phone.lastLocation,
              lastLocationUpdate: phone.lastLocationUpdate,
              lastUpdated: phone.lastUpdated,
              lastUpdatedBy: phone.lastUpdatedBy,
              driver: phone.driver,
              shipping: phone.shipping,
              return: phone.return,
              status: phone.status,
              cellularCarrierDeactivated: phone.cellularCarrierDeactivated,
              notes: phone.notes,
              icci: phone.icci,
              type: phone.type,
            };
          });

          this.devices$.next(phoneList || []);
          this.isLoadingDevice = false;
        },
        () => {
          this.isLoadingDevice = false;
        }
      );
  }

  private listenToForm(): void {
    this.deviceform.valueChanges
      .pipe(takeUntil(this.alive))
      .subscribe((formVal: { truck: boolean; phone: boolean }) => {
        this.addMarkers(formVal.truck, formVal.phone);
      });
  }
}
