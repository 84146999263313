export class Broker {
  name: string;
  phone: string;
  address: string;
  id: string;
  licenseNumber: string;
  insurance: string;
  isHaulynxBroker: boolean;
  dot: string;
  credits = 3;
  usxId?: string;

  constructor(props?: Partial<Broker>) {
    Object.assign(this, props);
  }
}
