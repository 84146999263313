import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { dateLessThanNowMoment } from '@haulynx/utils';
import { forEach, keys } from 'lodash';
import { Moment } from 'moment';
import { MomentService } from '../../app-services/generic/moment/moment.service';

export class LoadActiveDeliveredForm {
  entranceTime: Moment = null;
  completed: Moment = null;
  locationType: string = null;
  locationAddress: string = null;

  // Todo: create base define field for form and move there
  setOptions(options) {
    const key = keys(this);
    const defaultValue = [null];

    forEach(key, (prop) => {
      const control = (options && options[prop]) || defaultValue;

      this[prop] = control;
    });
  }
}

@Injectable()
export class LoadActiveDeliveredVm {
  constructor(public fb: FormBuilder, public momentService: MomentService) {}

  getOptions(timeZone) {
    return {
      entranceTime: [null, Validators.compose([Validators.required, dateLessThanNowMoment(timeZone)])],
      completed: [null, Validators.compose([Validators.required, dateLessThanNowMoment(timeZone)])],
    };
  }

  create(values, timeZone) {
    const options = this.getOptions(timeZone);
    const loadActiveDeliveryForm = new LoadActiveDeliveredForm();

    loadActiveDeliveryForm.setOptions(options);

    const loadActiveDeliveryGroup = this.fb.group(loadActiveDeliveryForm, {
      validators: [this.compareTwoTimestampsValidator('entranceTime', 'completed').bind(this)],
    });

    if (values) {
      loadActiveDeliveryGroup.patchValue(values);
    }

    return loadActiveDeliveryGroup;
  }

  compareTwoTimestampsValidator(beginDate: string, endDate: string): ValidatorFn {
    return (group: FormGroup): ValidationErrors | null => {
      const entranceMoment = group.controls[beginDate].value as Moment;
      const exitMoment = group.controls[endDate].value as Moment;

      if (group.dirty && exitMoment.isSameOrBefore(entranceMoment, 'minute')) {
        return { entranceMoreThanExit: true };
      }

      return null;
    };
  }
}
