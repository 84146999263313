import { Pipe, PipeTransform } from '@angular/core';
import { LoadsServiceLoad, LoadsServiceLoadStatus } from '@haulynx/types';

@Pipe({
  name: 'getLateOrOnTimeColor',
})
export class GetLateOrOnTimeColorPipe implements PipeTransform {
  constructor() {}

  transform(load: LoadsServiceLoad) {
    //display color for Late or OnTime on active loads
    if (load.loadStatus === LoadsServiceLoadStatus.DISPATCHED) {
      if (load.onTimeStatus?.onTime !== null) {
        return !load.onTimeStatus?.onTime ? 'var(--haulynx-orange)' : 'var(--haulynx-accent-green)';
      }
      return '';
    } else if (
      load.loadStatus === LoadsServiceLoadStatus.PICKED_UP ||
      load.loadStatus === LoadsServiceLoadStatus.DELIVERED ||
      load.loadStatus === LoadsServiceLoadStatus.AT_RECEIVER ||
      load.loadStatus === LoadsServiceLoadStatus.AT_SHIPPER
    ) {
      if (!load.onTimeStatus?.onTime) {
        return 'var(--haulynx-orange)';
      } else {
        return 'var(--haulynx-accent-green)';
      }
    }
    return '';
  }
}
