import { format, utcToZonedTime } from 'date-fns-tz';
import { toNumber } from 'lodash';

export const formatTimestampToHuman = (
  timestamp: number | string,
  timeZone: string,
  outputFormat = 'MM/dd/yy HH:mm zz'
): string => {
  if (!timestamp) {
    return '';
  }
  if (!timeZone) {
    return format(timestamp, outputFormat);
  }
  return format(utcToZonedTime(toNumber(timestamp), timeZone), outputFormat, { timeZone });
};

export const utcTime = (timestamp: Date, { offsetTime }: { offsetTime?: 'start' | 'end' } = {}): Date => {
  const hourOffset = offsetTime === 'start' ? 0 : offsetTime === 'end' ? 23 : null;
  const minuteAndSecondOffset = offsetTime === 'start' ? 0 : offsetTime === 'end' ? 59 : null;
  return new Date(
    Date.UTC(
      timestamp.getUTCFullYear(),
      timestamp.getUTCMonth(),
      timestamp.getUTCDate(),
      hourOffset,
      minuteAndSecondOffset,
      minuteAndSecondOffset
    )
  );
};
