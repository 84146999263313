import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FeatureFlag,
  NotificationEvent,
  NotificationEventType,
  SecondaryNotificationEvent,
  User,
  WebNotification,
} from '@haulynx/types';
import { newMatchToWebNotification } from '@haulynx/utils';
import { format } from 'date-fns';
import { groupBy, keys, orderBy, reduce } from 'lodash';
import { NotificationTemplate } from './app-notification-search.config';

@Component({
  selector: 'app-notification-search',
  templateUrl: './app-notification-search.component.html',
  styleUrls: ['./app-notification-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppNotificationSearchComponent implements OnChanges {
  @Input() notifications: Notification[] = [];
  @Input() user: User;

  @Output() selectNotification = new EventEmitter<WebNotification>();

  public webNotifications: WebNotification[][] = [];
  public notificationTemplate = NotificationTemplate;
  public notificationEventTypes = NotificationEventType;
  public secondaryNotificationEventTypes = SecondaryNotificationEvent;
  featureFlag = FeatureFlag.REPEAT_NOTIFICATIONS;

  select(notification: WebNotification): void {
    this.selectNotification.emit(notification);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { notifications } = changes;

    if (notifications) {
      const grouped = this.groupNotification(notifications.currentValue || []);

      this.webNotifications = this.convertIntoWebNotification(grouped);
    }
  }

  private groupNotification(notifications: NotificationEvent[]): { [key: string]: NotificationEvent[] } {
    const orderByCreatedAt = orderBy(notifications, ['createdAt'], ['desc']);
    const orderByActive = orderBy(orderByCreatedAt, ['active'], ['desc']);

    return groupBy(
      orderByActive,
      (notification) => `${notification.active ? 'active' : 'inactive'}-${format(notification.createdAt, 'yyyy-MM-dd')}`
    );
  }

  getButtonClass(eventType: string): string {
    switch (eventType) {
      case 'BROKER_PROFILE_RECOMMENDATION':
        return 'broker-profile-recommendation';
      default:
        return 'default-button';
    }
  }

  private convertIntoWebNotification(notificationsByDayMap: {
    [dayKey: string]: NotificationEvent[];
  }): WebNotification[][] {
    const dayKeys: string[] = keys(notificationsByDayMap);

    // Todo: move in separate function
    const newMatchesManual = reduce(
      dayKeys,
      (newMatchAccumulator, dayKey) => {
        const newMatch = newMatchToWebNotification(notificationsByDayMap[dayKey]);

        return [...newMatchAccumulator, [...newMatch]];
      },
      []
    );

    return newMatchesManual;
  }
}
