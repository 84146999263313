export class RawLocation {
  public latitude: number;
  public longitude: number;
}
export class RawLog {
  public time: number;
  public dateModified: string;
  public dateOfCertifiedRecord: number;
  public dateOfCertifiedRecordReadable: string;
  public recordStatus: number;
  public recordOrigin: number;
  public eventRecordType: number;
  public eventRecordCode: number;
  public comment: string;
  public notes: string;
  public vehicleMiles: number;
  public engineHours: number;
  public position: RawLocation;
  public commercialVehicleNumber: string;
  public userName: string;
  public malfunctionCode: string;
  public diagnosticCode: string;
}
