import { Injectable } from '@angular/core';
import {
  BrokersService,
  CarrierService,
  CarriersService,
  DevicesService,
  DictionariesService,
  InvitationService,
  LoadsServiceService,
  PaymentService,
  TrailersService,
  TrucksService,
  UserService,
  UsersService,
} from '@haulynx/services';
import { Carrier, Device, LoadTemplate, User } from '@haulynx/types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { head, last } from 'lodash';
import { of } from 'rxjs';
import { catchError, filter, flatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '../../main-store/app.reducers';
import { ICommonEntityAction } from './common-entities.actions';
import { CommonEntities } from './common-entities.model';
import { getEntity, getQuery } from './common-entities.selectors';

@Injectable()
export class CommonEntitiesEffects {
  carriers = createEffect(() =>
    this.actions.pipe(
      ofType(this.commonEntities.carriers.actionTypes.SEARCH),
      map((action: ICommonEntityAction) => action.payload),
      withLatestFrom(this.store.select(getEntity('carriers')), this.store.select(getQuery('carriers'))),
      switchMap(([payload, entities, query]) => {
        const { key = null } = payload || {};

        return this.carrierService.getCarriers().pipe(
          map((carriers) => this.commonEntities.carriers.actions.searchSuccess(carriers)),
          catchError((errors) => of(this.commonEntities.carriers.actions.searchErrors(errors)))
        );
      })
    )
  );

  truck = createEffect(() =>
    this.actions.pipe(
      ofType(this.commonEntities.trucks.actionTypes.SEARCH),
      map((action: ICommonEntityAction) => action.payload),
      withLatestFrom(this.store.select(getEntity('trucks')), this.store.select(getQuery('trucks'))),
      switchMap(([payload, entities, query]) => {
        const { key = null } = payload || {};
        const { carrierId = null } = query && key ? query.get(key) || {} : {};

        return this.carrierService.getTrucks(carrierId).pipe(
          map((data) => this.commonEntities.trucks.actions.searchSuccess({ data })),
          catchError((errors) => of(this.commonEntities.trucks.actions.searchErrors(errors)))
        );
      })
    )
  );

  users = createEffect(() =>
    this.actions.pipe(
      ofType(this.commonEntities.users.actionTypes.SEARCH),
      map((action: ICommonEntityAction) => action.payload),
      withLatestFrom(this.store.select(getEntity('users')), this.store.select(getQuery('users'))),
      switchMap(([payload, entities, query]) => {
        const { key = null } = payload || {};

        return this.userService.getUserList(query && key ? query.get(key) : payload).pipe(
          map((docs) => this.commonEntities.users.actions.searchSuccess(docs)),
          catchError((errors) => of(this.commonEntities.users.actions.searchErrors(errors)))
        );
      })
    )
  );

  states = createEffect(() =>
    this.actions.pipe(
      ofType(this.commonEntities.states.actionTypes.SEARCH),
      map((action: ICommonEntityAction) => action.payload),
      withLatestFrom(this.store.select(getEntity('states'))),
      switchMap(([payload, entities]) =>
        this.dictionariesService.getStates(payload).pipe(
          map((res) => this.commonEntities.states.actions.searchSuccess(res)),
          catchError((errors) => of(this.commonEntities.states.actions.searchErrors(errors)))
        )
      )
    )
  );

  drivers = createEffect(() =>
    this.actions.pipe(
      ofType(this.commonEntities.drivers.actionTypes.SEARCH),
      map((action: ICommonEntityAction) => action.payload),
      withLatestFrom(this.store.select(getQuery('drivers'))),
      switchMap(([payload, query]) => {
        const { key = null } = payload || {};
        const filter = query && key ? query.get(key) : payload;

        return this.carrierService.getDrivers(filter && filter.carrierId).pipe(
          map((entities) => this.commonEntities.drivers.actions.searchSuccess(entities)),
          catchError((errors) => of(this.commonEntities.drivers.actions.searchErrors(errors)))
        );
      })
    )
  );

  orderStatus = createEffect(() =>
    this.actions.pipe(
      ofType(this.commonEntities.orderStatus.actionTypes.SEARCH),
      map((action: ICommonEntityAction) => action.payload),
      withLatestFrom(this.store.select(getEntity('orderStatus')), this.store.select(getQuery('orderStatus'))),
      switchMap(([payload, entities, query]) => {
        const { key = null } = payload || {};

        return this.dictionariesService.getOrderStatuses(query && key ? query.get(key) : payload).pipe(
          map((docs) => this.commonEntities.orderStatus.actions.searchSuccess(docs)),
          catchError((errors) => of(this.commonEntities.orderStatus.actions.searchErrors(errors)))
        );
      })
    )
  );

  devices = createEffect(() =>
    this.actions.pipe(
      ofType(this.commonEntities.devices.actionTypes.SEARCH),
      map((action: ICommonEntityAction) => action.payload),
      withLatestFrom(this.store.select(getEntity('devices')), this.store.select(getQuery('devices'))),
      switchMap(([payload, entities, query]) => {
        const { key = null } = payload || {};

        return this.carrierService.getDevices(query && key ? query.get(key) : payload).pipe(
          map((docs) => this.commonEntities.devices.actions.searchSuccess(docs)),
          catchError((errors) => of(this.commonEntities.devices.actions.searchErrors(errors)))
        );
      })
    )
  );

  trailerTypes = createEffect(() =>
    this.actions.pipe(
      ofType(this.commonEntities.trailerTypes.actionTypes.SEARCH),
      map((action: ICommonEntityAction) => action.payload),
      withLatestFrom(this.store.select(getEntity('trailerTypes')), this.store.select(getQuery('trailerTypes'))),
      switchMap(([payload, entities, query]) => {
        const { key = null } = payload || {};
        const { id, carrierId } = query && key ? query.get(key) : payload;

        return this.trailersService.getTrailersByCarrierId({ carrierId: id || carrierId }).pipe(
          map((docs) => this.commonEntities.trailerTypes.actions.searchSuccess(docs)),
          catchError((errors) => of(this.commonEntities.trailerTypes.actions.searchErrors(errors)))
        );
      })
    )
  );

  templates = createEffect(() =>
    this.actions.pipe(
      ofType(this.commonEntities.templates.actionTypes.SEARCH),
      map((action: ICommonEntityAction) => action.payload),
      withLatestFrom(this.store.select(getEntity('templates')), this.store.select(getQuery('templates'))),
      switchMap(([payload]) =>
        this.loadsServiceService.getTemplates(payload?.query?.userId).pipe(
          map((templates) => {
            const newTemplates: LoadTemplate[] = _.map(templates, (template) => {
              // Ignore the id on the template
              template.id = null;
              if (!template.name) {
                const { locations } = template;
                template.name = `${head(locations).address} to ${last(locations).address}`;
              }

              return template;
            });

            return this.commonEntities.templates.actions.searchSuccess(newTemplates);
          }),
          catchError((errors) => of(this.commonEntities.templates.actions.searchErrors(errors)))
        )
      )
    )
  );

  network = createEffect(() =>
    this.actions.pipe(
      ofType(this.commonEntities.network.actionTypes.SEARCH),
      map((action: ICommonEntityAction) => action.payload),
      withLatestFrom(this.store.select(getEntity('network')), this.store.select(getQuery('network'))),
      switchMap(([payload, entities, query]) => {
        const { key = null } = payload || {};

        return this.invitationService.getNetwork(query && key ? query.get(key) : payload).pipe(
          map((network) => this.commonEntities.network.actions.searchSuccess(network)),
          catchError((errors) => of(this.commonEntities.network.actions.searchErrors(errors)))
        );
      })
    )
  );

  graphQlTrucks = createEffect(() =>
    this.actions.pipe(
      ofType(this.commonEntities.graphQlTrucks.actionTypes.SEARCH),
      map((action: ICommonEntityAction) => action.payload),
      withLatestFrom(this.store.select(getEntity('graphQlTrucks')), this.store.select(getQuery('graphQlTrucks'))),
      switchMap(([payload, entities, query]) => {
        const { key = null } = payload || {};
        const { carrierId = null, carrierDot = null }: { carrierId?: string; carrierDot?: string } =
          query && key ? query.get(key) || {} : {};

        if (carrierDot) {
          return this.carriersService.getCarrierByDot(carrierDot).pipe(
            flatMap((carrier: Carrier) =>
              this.carriersService.getCarrierById(carrier.id).pipe(
                flatMap((firebaseCarrier: Carrier) => {
                  const query = firebaseCarrier ? firebaseCarrier.id : carrierDot;

                  return this.trucksService.getTrucksByCarrierId({ carrierId: query }).pipe(
                    map((data) => this.commonEntities.graphQlTrucks.actions.searchSuccess(data.entities)),
                    catchError((errors) => of(this.commonEntities.graphQlTrucks.actions.searchErrors(errors)))
                  );
                })
              )
            )
          );
        }

        return this.trucksService.getTrucksByCarrierId({ carrierId }).pipe(
          map((data) => this.commonEntities.graphQlTrucks.actions.searchSuccess(data.entities)),
          catchError((errors) => of(this.commonEntities.graphQlTrucks.actions.searchErrors(errors)))
        );
      })
    )
  );

  graphQlTrailers = createEffect(() =>
    this.actions.pipe(
      ofType(this.commonEntities.graphQlTrailers.actionTypes.SEARCH),
      map((action: ICommonEntityAction) => action.payload),
      withLatestFrom(this.store.select(getEntity('graphQlTrailers')), this.store.select(getQuery('graphQlTrailers'))),
      switchMap(([payload, entities, query]) => {
        const { key = null } = payload || {};
        const { carrierId = null, carrierDot = null }: { carrierId?: string; carrierDot?: string } =
          query && key ? query.get(key) || {} : {};

        if (carrierDot) {
          return this.carriersService.getCarrierByDot(carrierDot).pipe(
            flatMap((carrier: Carrier) =>
              this.carriersService.getCarrierById(carrier.id).pipe(
                flatMap((firebaseCarrier: Carrier) => {
                  const query = firebaseCarrier ? firebaseCarrier.id : carrierDot;

                  return this.trailersService.getTrailersByCarrierId({ carrierId: query }).pipe(
                    map((data) => this.commonEntities.graphQlTrailers.actions.searchSuccess(data.entities)),
                    catchError((errors) => of(this.commonEntities.graphQlTrailers.actions.searchErrors(errors)))
                  );
                })
              )
            )
          );
        }

        return this.trailersService.getTrailersByCarrierId({ carrierId }).pipe(
          map((data) => this.commonEntities.graphQlTrailers.actions.searchSuccess(data.entities)),
          catchError((errors) => of(this.commonEntities.graphQlTrailers.actions.searchErrors(errors)))
        );
      })
    )
  );

  graphQlDrivers = createEffect(() =>
    this.actions.pipe(
      ofType(this.commonEntities.graphQlDrivers.actionTypes.SEARCH),
      map((action: ICommonEntityAction) => action.payload),
      withLatestFrom(this.store.select(getEntity('graphQlDrivers')), this.store.select(getQuery('graphQlDrivers'))),
      switchMap(([payload, entities, query]) => {
        const { key = null } = payload || {};
        const { carrierId = null, carrierDot = null }: { carrierId?: string; carrierDot?: string } =
          query && key ? query.get(key) || {} : {};

        if (carrierDot) {
          return this.carriersService.getCarrierByDot(carrierDot).pipe(
            flatMap((carrier: Carrier) =>
              this.carriersService.getCarrierById(carrier.id).pipe(
                flatMap((firebaseCarrier: Carrier) => {
                  const query = firebaseCarrier ? firebaseCarrier.id : carrierDot;

                  return this.usersService.getUsersByCarrier({ carrierId: query }).pipe(
                    map((data) => data.entities.filter((user: User) => user.isDriver)),
                    map((data) => this.commonEntities.graphQlDrivers.actions.searchSuccess(data)),
                    catchError((errors) => of(this.commonEntities.graphQlDrivers.actions.searchErrors(errors)))
                  );
                })
              )
            )
          );
        }

        return this.usersService.getUsersByCarrier({ carrierId }).pipe(
          map((data) => data.entities.filter((user: User) => user.isDriver)),
          map((data) => this.commonEntities.graphQlDrivers.actions.searchSuccess(data)),
          catchError((errors) => of(this.commonEntities.graphQlDrivers.actions.searchErrors(errors)))
        );
      })
    )
  );

  graphQlBrokersIds = createEffect(() =>
    this.actions.pipe(
      ofType(this.commonEntities.graphQlBrokersIds.actionTypes.SEARCH),
      map((action: ICommonEntityAction) => action.payload),
      withLatestFrom(
        this.store.select(getEntity('graphQlBrokersIds')),
        this.store.select(getQuery('graphQlBrokersIds'))
      ),
      switchMap(([payload, entities, query]) =>
        this.brokersService.getAllBrokerIds().pipe(
          map((data) => this.commonEntities.graphQlBrokersIds.actions.searchSuccess(data.entities)),
          catchError((errors) => of(this.commonEntities.graphQlBrokersIds.actions.searchErrors(errors)))
        )
      )
    )
  );

  graphQlCarriers = createEffect(() =>
    this.actions.pipe(
      ofType(this.commonEntities.graphQlCarriers.actionTypes.SEARCH),
      map((action: ICommonEntityAction) => action.payload),
      withLatestFrom(this.store.select(getQuery('graphQlCarriers'))),
      switchMap(([payload, query]) => {
        const { key } = payload;

        const filter = query.get(key);

        return this.carriersService
          .search({
            search: '',
            ...filter,
            paging: {
              limit: 100,
              page: 1,
            },
          })
          .pipe(
            map(({ entities }) => this.commonEntities.graphQlCarriers.actions.searchSuccess(entities)),
            catchError((errors) => of(this.commonEntities.graphQlCarriers.actions.searchErrors(errors)))
          );
      })
    )
  );

  graphQlDevices = createEffect(() =>
    this.actions.pipe(
      ofType(this.commonEntities.graphQlDevices.actionTypes.SEARCH),
      map((action: ICommonEntityAction) => action.payload),
      withLatestFrom(this.store.select(getQuery('graphQlDevices'))),
      switchMap(([payload, query]) => {
        const { key } = payload;
        const filter: { carrierId: string } = query.get(key);

        return this.devicesService.getDevicesByCarrierId<Device>(filter).pipe(
          map((entities) => this.commonEntities.graphQlDevices.actions.searchSuccess(entities)),
          catchError((errors) => of(this.commonEntities.graphQlDevices.actions.searchErrors(errors)))
        );
      })
    )
  );

  paymentTypes = createEffect(() =>
    this.actions.pipe(
      ofType(this.commonEntities.paymentTypes.actionTypes.SEARCH),
      map((action: ICommonEntityAction) => action.payload),
      switchMap((payload) =>
        this.paymentService.getPaymentTypes().pipe(
          map((entities) => this.commonEntities.paymentTypes.actions.searchSuccess(entities)),
          catchError((errors) => of(this.commonEntities.paymentTypes.actions.searchErrors(errors)))
        )
      )
    )
  );

  constructor(
    private actions: Actions,
    private store: Store<AppState>,
    private userService: UserService,
    private commonEntities: CommonEntities,
    private carrierService: CarrierService,
    private dictionariesService: DictionariesService,
    private loadsServiceService: LoadsServiceService,
    private invitationService: InvitationService,
    private trucksService: TrucksService,
    private trailersService: TrailersService,
    private usersService: UsersService,
    private carriersService: CarriersService,
    private devicesService: DevicesService,
    private brokersService: BrokersService,
    private paymentService: PaymentService
  ) {}
}
