import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  KeyValuePair,
  LaneActionMenuOption,
  laneAnalysisActionMenu,
  laneAnalysisActionMenuIcons,
  laneHistoryActionMenu,
  laneHistoryActionMenuIcons,
} from '@haulynx/types';

@Component({
  selector: 'app-lane-history-actions',
  templateUrl: './lane-history-actions.component.html',
  styleUrls: ['./lane-history-actions.component.scss'],
})
export class LaneHistoryActionsComponent implements OnInit {
  @Input() disabledButtons = [];
  @Input() laneAnalysis? = false;
  @Output() buttonClicked: EventEmitter<{ event: KeyValuePair[] }> = new EventEmitter();

  laneHistoryActionMenu: KeyValuePair[];
  laneHistoryActionMenuIcons: LaneActionMenuOption[];

  constructor() {}

  ngOnInit(): void {
    this.laneHistoryActionMenu = this.laneAnalysis ? laneAnalysisActionMenu : laneHistoryActionMenu;
    this.laneHistoryActionMenuIcons = this.laneAnalysis ? laneAnalysisActionMenuIcons : laneHistoryActionMenuIcons;
  }

  clickEvent(event: KeyValuePair[]) {
    this.buttonClicked.emit({ event });
  }
}
