import { Component, ElementRef, HostListener, Inject, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationsService } from '@haulynx/services';
import { CarrierLoadSearchEntityService } from '@haulynx/store';
import {
  BulkAction,
  KeyValuePair,
  LoadIdentifierType,
  LoadsServiceLoad,
  LoadsServiceProviderDetailId,
} from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { Observable, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-bulk-actions-dialog',
  templateUrl: './bulk-actions-dialog.component.html',
  styleUrls: ['./bulk-actions-dialog.component.scss'],
})
export class BulkActionsDialogComponent {
  @ViewChild('dropdown', { static: false }) wrapper: ElementRef;

  public loads: LoadsServiceLoad[];
  public option: BulkAction;
  public allBrokers: { id: string; label: string }[];
  public filterInput: FormControl = new FormControl();
  public filteredBrokers: ReplaySubject<{ id: string; label: string }[]> = new ReplaySubject<
    { id: string; label: string }[]
  >(1);
  isLoading$: Observable<boolean>;

  showDropDown = false;
  LoadBulkAction = BulkAction;
  brokers = new Set<string>();
  form: FormGroup;
  alive = aliveWhile();
  buttonSize = '13em';
  maxbuyChangeOptions: KeyValuePair[] = [
    {
      key: 'dollar-sign-icon',
      value: 'dollar-sign-icon',
    },
    {
      key: 'percent-icon',
      value: 'percent-icon',
    },
  ];
  selectedMaxBuyOption = 'dollar-sign-icon';

  constructor(
    public dialogRef: MatDialogRef<BulkActionsDialogComponent>,
    private carrierLoadSearchEntityService: CarrierLoadSearchEntityService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      loads: LoadsServiceLoad[];
      option: BulkAction;
      allBrokers: Array<{
        id: string;
        label: string;
      }>;
      isCompanyAdmin: boolean;
      usxId: string;
      confirmationType: string;
      confirmationValue: number;
    },
    private notificationService: NotificationsService
  ) {
    this.loads = data.loads;
    this.option = data.option;
    this.allBrokers = data.allBrokers;
    this.form = new FormGroup({});

    this.isLoading$ = this.carrierLoadSearchEntityService.deleteMissionManager.isLoading$;

    this.carrierLoadSearchEntityService.deleteMissionManager.onSuccess$.pipe(takeUntil(this.alive)).subscribe(() => {
      this.dialogRef.close(true);
    });

    switch (this.option) {
      case BulkAction.UPDATE_MAX_BUY:
        this.form.addControl('maxBuy', new FormControl('', [Validators.required]));
        this.form.addControl('maxBuyType', new FormControl('dollar-sign-icon', [Validators.required]));
        this.form
          .get('maxBuyType')
          .valueChanges.pipe(takeUntil(this.alive))
          .subscribe((value) => (this.selectedMaxBuyOption = value));
        break;
      case BulkAction.ASSIGN_REPS:
        this.loads.forEach((load) => {
          if (load.assignedBrokers.length > 0) {
            load.assignedBrokers.forEach((broker) => {
              this.brokers.add(broker.usxId);
            });
          }
        });
        this.filteredBrokers.next(this.allBrokers.slice());
        this.filterInput.valueChanges.pipe(takeUntil(this.alive)).subscribe((filter) => {
          this.filterBrokersMulti();
          if (filter !== '') {
            this.showDropDown = true;
          } else {
            this.showDropDown = false;
          }
        });
        break;
      case BulkAction.PRIORITY_CHANGE:
        this.form.addControl('priorityChange', new FormControl('', [Validators.required]));
        break;
      case BulkAction.ADJUST_PERCENTAGE:
        this.form.addControl(
          'adjustPercentage',
          new FormControl('', [Validators.required, Validators.pattern('^-?([0-9][0-9]?%?$|^100%?)$')])
        );
        this.buttonSize = '230px';
        break;
      case BulkAction.ADJUST_DOLLAR_AMOUNT:
        this.form.addControl('adjustDollarAmount', new FormControl('', [Validators.required]));
        this.buttonSize = '245px';
        break;
    }
  }

  @HostListener('document:click', ['$event.target']) onClick(targetElement: ElementRef): void {
    const clickedInside = this.wrapper ? this.wrapper.nativeElement.contains(targetElement) : false;
    if (!clickedInside) {
      this.showDropDown = false;
    } else {
      this.showDropDown = true;
    }
  }

  deleteItem(id: string): void {
    this.brokers.delete(id);
  }

  submit(): void {
    switch (this.option) {
      case BulkAction.UPDATE_MAX_BUY: {
        const val = parseFloat(this.form.get('maxBuy').value.replace('%', '').replace('$', ''));
        const maxBuyType = this.form.get('maxBuyType').value;
        if (!Number.isNaN(val)) {
          if (maxBuyType === 'dollar-sign-icon') {
            this.dialogRef.close({ value: val, type: maxBuyType });
          } else {
            if (!(val > 100 || val < -100)) {
              this.dialogRef.close({ value: val, type: maxBuyType });
            } else {
              this.notificationService.error(
                'Percent must be greater than -100 and less than 100',
                'Invalid Percentage'
              );
            }
          }
        } else {
          this.notificationService.error('Value inserted is not a number', 'Not A Valid Value');
        }
        break;
      }
      case BulkAction.ASSIGN_REPS: {
        const returnResults = Array.from(this.brokers).map((id) => {
          return { usxId: id };
        });
        this.dialogRef.close(returnResults);
        break;
      }
      case BulkAction.PRIORITY_CHANGE:
        this.dialogRef.close(this.form.get('priorityChange').value);
        break;
      case BulkAction.CONFIRM_UPDATE_MAX_BUY:
        this.dialogRef.close(true);
        break;
      case BulkAction.ADJUST_PERCENTAGE:
        this.dialogRef.close(this.form.get('adjustPercentage').value.replace('%', ''));
        break;
      case BulkAction.CONFIRM_UPDATE_ADJUST_PERCENTAGE:
        this.dialogRef.close(true);
        break;
      case BulkAction.ADJUST_DOLLAR_AMOUNT:
        this.dialogRef.close(this.form.get('adjustDollarAmount').value);
        break;
      case BulkAction.CONFIRM_UPDATE_ADJUST_DOLLAR_AMOUNT:
        this.dialogRef.close(true);
        break;
      case BulkAction.DELETE_MISSION:
        this.deleteMission(this.loads);
        break;
    }
  }

  deleteMission(loads): void {
    const identifier = loads[0].providerDetails.alternateIds.find((value: LoadsServiceProviderDetailId) => {
      return value.identifierType === LoadIdentifierType.ORDER_NUMBER;
    });
    this.carrierLoadSearchEntityService.deleteMissionManager.dispatch(this.loads[0].id, {
      orderNumber: identifier?.identifierValue,
      missionId: this.loads[0].mission.id,
    });
  }

  selectBroker(broker: { id: string; label: string }): void {
    if (!this.brokers.has(broker.id)) {
      this.brokers.add(broker.id);
    } else {
      this.brokers.delete(broker.id);
    }
  }

  getIsChecked(broker: { id: string; label: string }): boolean {
    return this.brokers.has(broker.id);
  }

  protected filterBrokersMulti(): void {
    if (!this.allBrokers) {
      return;
    }
    let search = this.filterInput.value;
    if (!search) {
      this.filteredBrokers.next(this.allBrokers.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredBrokers.next(this.allBrokers.filter((broker) => broker.label.toLowerCase().indexOf(search) > -1));
  }
}
