import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { GoogleAddressFieldModule, TabsModule } from '@haulynx/components';
import { PipesModule } from '@haulynx/pipes';
import { LoadVm } from '@haulynx/services';
import {
  ActiveLoadsStateModule,
  AsyncEntityGeocodingeModule,
  AsyncEntityLoadStoreModule,
  LoadDetailsStateModule,
} from '@haulynx/store';
import { LoadsRoutingModule } from './loads-routing.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    LoadsRoutingModule,
    TabsModule,
    AsyncEntityLoadStoreModule,
    ActiveLoadsStateModule,
    LoadDetailsStateModule,
    FormsModule,
    PipesModule,
    AsyncEntityGeocodingeModule,
  ],
  providers: [LoadVm],
})
export class LoadsModule {}
