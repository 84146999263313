import { Actions } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { laneRateNamespace } from '../namespace';
import { tap } from 'rxjs/operators';
import {
  AsyncBulkMutationManager,
  AsyncSearchManager,
  PageAndSort,
  ZipLane,
  ZipLaneQueryUpdates,
  ZipLaneSearchParameters,
  ZipLaneUpdate,
} from '@haulynx/types';
import { AppState } from '../../main-store/app.reducers';
import { LanesService, NotificationsService } from '@haulynx/services';
import { AsyncEntityBase } from '../../main-store/async-entity/async-entity-base.class';

@Injectable({ providedIn: 'root' })
export class LaneRateEntityService extends AsyncEntityBase<ZipLane> {
  searchManager: AsyncSearchManager<ZipLane, Partial<ZipLaneSearchParameters>>;
  bulkUpdateLanesManager: AsyncBulkMutationManager<{ bidIds: string[]; input: ZipLaneUpdate }, ZipLane[]>;
  updateLaneManager: AsyncBulkMutationManager<
    { bidIds: string[]; input: ZipLaneQueryUpdates; paging: Partial<PageAndSort> },
    string
  >;

  constructor(
    protected actions$: Actions,
    protected store: Store<AppState>,
    private lanesServiceApi: LanesService,
    private notifications: NotificationsService
  ) {
    super(actions$, store, laneRateNamespace);

    this.searchManager = this.createAsyncSearchQuery('get usx zip lanes', (searchPayload) =>
      this.lanesServiceApi.searchLanes(searchPayload.query, searchPayload.pageAndSort)
    );

    this.bulkUpdateLanesManager = this.createBulkAsyncMutation('bulk update lanes', (payload) =>
      this.lanesServiceApi.bulkUpdateLanes(payload.input).pipe(
        tap(
          () => {
            this.notifications.success('Successfully updated lanes.', 'Success!');
          },
          (error) => this.notifications.error(error, 'Error updating lanes')
        )
      )
    );

    this.updateLaneManager = this.createBulkAsyncMutation('update lane', (payload) =>
      this.lanesServiceApi.updateLane(payload.input).pipe(
        tap(
          () => {
            this.notifications.success('Successfully updated lane.', 'Success!');
          },
          (error) => this.notifications.error(error.message, 'Error updating lane')
        )
      )
    );
  }
}
