import { createAction, createActionTypes } from '../../shared/store/helpers/action';

const Type = '[DASHBOARD]';

export interface IDashboardActions {
  toggleNav();
}

export interface IDashboardActionTypes {
  TOGGLE_NAV: string;
}

const dashboardActionTypes: IDashboardActionTypes = {
  TOGGLE_NAV: 'toggle navigation',
};
export const DashboardActionTypes: IDashboardActionTypes = createActionTypes(dashboardActionTypes)(Type);
export const DashboardActions = {
  toggleNav: createAction(Type, dashboardActionTypes.TOGGLE_NAV),
};
