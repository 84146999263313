import gql from 'graphql-tag';

export const SearchCarrierComplianceTickets = gql(`
query searchCarrierComplianceTickets($carrierComplianceTicketParams:CarrierComplianceTicketParamsInput) {
  searchCarrierComplianceTickets(carrierComplianceTicketParams: $carrierComplianceTicketParams){
    id
    broker_id
    broker_email
    reason
    carrier_id
    load_id
    status
    status_update
    freshdesk_id
    created_at
	updated_at
  }
}
`);
