import { TrackingType } from '../truck';
import { CarrierApiNote } from './carrier-api-carrier';
import { CarrierSourceType } from './carrier-source-type';

export class Insurance {
  policyNumber: string;
  limit = 0;
  expirationDate: number;
  underWriter: string;
}
export class SaferWatchData {
  name: string;
  phone: string;
  email: string;
  dateLastUpdated: any;
  autoLiability: Insurance = new Insurance();
  cargoLiability: Insurance = new Insurance();
  generalLiability: Insurance = new Insurance();
  safetyRating: string;
  outOfService: boolean;
  address: string;
  mcNumber: string;
  hazmatCapable: boolean;
  overallRating: string;
}

export class Carrier {
  addressStreet?: string;
  addressStreet2?: string;
  addressCity?: string;
  addressState?: string;
  addressCountry?: string;
  addressZip?: string;
  phone?: string;
  email?: string;
  id?: string;
  name?: string;
  mcNumber?: string;
  dot?: string;
  temperature?: string;
  hazmatCapable?: boolean;
  insurance?: string;
  weight?: string;
  equipmentType?: string[];
  isLoadDistributionEnabled?: boolean;
  maximumDeadMiles? = 100;
  minimumLoadValue? = 100;
  rank? = 0;
  saferWatchData?: SaferWatchData = new SaferWatchData();
  surveyInfo?: Info[];
  isHaulynxUser?: boolean;
  /** @deprecated please use rmisRegistration.isRmisSetup */
  isRmisSetup?: boolean;
  /** @deprecated please use rmisRegistration.isUsxCertified */
  isUSXCertified?: boolean;
  loadFeedEnabled? = false;
  bookLoadsEnabled? = false;
  timeZone? = 'America/Phoenix';
  sourceType?: CarrierSourceType;
  rmisRegistration?: RmisRegistration;
  owner?: string;
  status?: string;
  adminName?: string;
  complianceStatus?: CarrierComplianceStatus;
  thirdPartyTracking?: TrackingTypeAvailability[];

  constructor(props?: Partial<Carrier>) {
    Object.assign(this, props);
  }
}

export interface CarrierComplianceStatus {
  bookingPermissions?: BookingPermissions[];
}
export interface BookingPermissions {
  type?: BookingPermissionType;
  isInCompliance?: boolean;
  invalidReasons?: BookingPermissionInvalidReason[];
}

export enum BookingPermissionInvalidReason {
  NO_INTERCHANGE_COVERAGE = 'NO_INTERCHANGE_COVERAGE',
  INTERCHANGE_COVERAGE_LIMIT = 'INTERCHANGE_COVERAGE_LIMIT',
  GENERAL_COVERAGE_LIMIT = 'GENERAL_COVERAGE_LIMIT',
  AUTO_COVERAGE_LIMIT = 'AUTO_COVERAGE_LIMIT',
  CARGO_COVERAGE_LIMIT = 'CARGO_COVERAGE_LIMIT',
  INTERCHANGE_AGREEMENT_NOT_SIGNED = 'INTERCHANGE_AGREEMENT_NOT_SIGNED',
  INTERCHANGE_AGREEMENT_EXPIRED = 'INTERCHANGE_AGREEMENT_EXPIRED',
  INTERCHANGE_COVERAGE_EXPIRED = 'INTERCHANGE_COVERAGE_EXPIRED',
  UNKNOWN_REASON = 'UNKNOWN_REASON',
}

export enum BookingPermissionInvalidReasonString {
  NO_INTERCHANGE_COVERAGE = 'Your trailer interchange coverage was not submitted',
  INTERCHANGE_COVERAGE_LIMIT = 'Your trailer interchange insurance does not qualify for this load',
  GENERAL_COVERAGE_LIMIT = 'Your general insurance coverage does not qualify for this load',
  AUTO_COVERAGE_LIMIT = 'Your auto liability coverage does not qualify for this load',
  CARGO_COVERAGE_LIMIT = 'Your cargo liability insurance coverage does not qualify for this load',
  INTERCHANGE_AGREEMENT_NOT_SIGNED = 'Your interchange agreement needs to be signed',
  INTERCHANGE_AGREEMENT_EXPIRED = 'Your interchange agreement has expired',
  INTERCHANGE_COVERAGE_EXPIRED = 'Your interchange coverage has expired',
  UNKNOWN_REASON = 'Unknown Reason',
}

export enum BookingPermissionInvalidReasonCTAString {
  NO_INTERCHANGE_COVERAGE = 'Submit insurance',
  INTERCHANGE_COVERAGE_LIMIT = 'Learn more',
  GENERAL_COVERAGE_LIMIT = 'Learn more',
  AUTO_COVERAGE_LIMIT = 'Learn more',
  CARGO_COVERAGE_LIMIT = 'Learn more',
  INTERCHANGE_AGREEMENT_NOT_SIGNED = 'Sign agreement',
  INTERCHANGE_AGREEMENT_EXPIRED = 'Re-sign agreement',
  INTERCHANGE_COVERAGE_EXPIRED = 'Sign agreement',
}

export enum BookingPermissionType {
  POWER_ONLY = 'POWER_ONLY',
}

export enum BookingPermissionInvalidReasonCTAType {
  RMIS = 'RMIS',
  INTERCHANGE_AGREEMENT = 'INTERCHANGE_AGREEMENT',
}

export interface RmisRegistration {
  isRmisSetup?: boolean;
  isUsxCertified?: boolean;
  /**
   * Has the compliance service processed carrier information from RMIS
   */
  hasComplianceProcessedCarrier?: boolean;

  comcheckAdvancesRegistered?: boolean;
  fleetRegistered?: boolean;
  paymentOptionRegistered?: boolean;
  supplierDiversityRegistered?: boolean;
  w9Orw8Registered?: boolean;
}

export class PendingCarrier {
  carrier_id: string;
  carrier_name: string;
  match_percentage: string;
  awareness_percentage: number;
  isELDCarrier?: boolean;
  carrierColor?: string;
  constructor(props?: Partial<PendingCarrier>) {
    Object.assign(this, props);
  }
}

export class Info {
  question: string;
  content: string;
}

export class CarrierInput {
  name: string;
  dot: string;
  temperature: string;
  insurance: string;
  weight: string;
  owner: string;
  mcNumber: string;
  equipmentType: string[];
  isHaulynxUser: boolean;
  isEldCarrier: boolean;
  isLoadDistributionEnabled: boolean;
  loadFeedEnabled: boolean;
  hazmatCapable: boolean;
  rank: number;
  maximumDeadMiles: number;
  minimumLoadValue: number;
  phone: string;
  email: string;
  timeZone: string;
  addressStreet: string;
  addressStreet2: string;
  addressCity: string;
  addressState: string;
  addressCountry: string;
  saferWatchData: SaferWatchInput;
  surveyInfo: QuestionAndAnswerInput[];
  constructor(props?: Partial<Carrier>) {
    Object.assign(this, props);
    this.surveyInfo = props.surveyInfo.map((info) => new QuestionAndAnswerInput(info));
    this.saferWatchData = new SaferWatchInput(props.saferWatchData);
  }
}

export class SaferWatchInput {
  autoLiability: InsuranceInput;
  cargoLiability: InsuranceInput;
  address: string;
  mcNumber: string;
  safetyRating: string;
  overallRating: string;
  outOfService: boolean;
  hazmatCapable: boolean;
  dateLastUpdated: string;
  constructor(props?: Partial<SaferWatchData>) {
    Object.assign(this, props);
    this.autoLiability = new InsuranceInput(props.autoLiability);
    this.cargoLiability = new InsuranceInput(props.cargoLiability);
  }
}

export class QuestionAndAnswerInput {
  question: string;
  content: string;
  constructor(props?: Partial<Info>) {
    Object.assign(this, props);
  }
}

export class InsuranceInput {
  limit: number;
  expirationDate: number;
  underWriter: string;
  policyNumber: string;
  constructor(props?: Partial<Insurance>) {
    Object.assign(this, props);
  }
}

export interface CarrierInsuranceAndSafety {
  autoInsuranceLimit: number;
  cargoInsuranceLimit: number;
  generalInsuranceLimit: number;
  safetyRating: string;
  autoInsuranceExpDate: string;
  cargoInsuranceExpDate: string;
  error?: boolean;
  generalInsuranceExpDate: string;
}

export interface MergedCarrierData extends Carrier {
  insuranceAndSafety: CarrierInsuranceAndSafety;
  notes: CarrierApiNote[];
}

export enum CarrierFooterScrollTo {
  PICKUP = 'pick up',
  DROPOFF = 'drop off',
  NEXTSTOP = 'next stop',
  ASSIGNMENT_DRIVER = 'assign driver',
  ASSIGNMENT_TRUCK = 'assign truck',
  ASSIGNMENT_TRAILER = 'assign trailer',
  SAVE_CHANGES = 'save changes',
}

export interface TrackingTypeAvailability {
  trackingType: TrackingType;
  mobileTrackingEnabled: boolean;
  vehicleTrackingEnabled: boolean;
}
