import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CarrierDashboardModel } from './carrier-dashboard-model';
import { CarriersAnalyticsEffects } from './carriers-analytics.effects';
import { CarriersModel } from './carriers-model';
import { CarriersEffects } from './carriers.effects';
import { carriersReducer } from './carriers.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('carriers', carriersReducer),
    EffectsModule.forFeature([CarriersEffects, CarriersAnalyticsEffects]),
  ],
  providers: [CarriersModel, CarrierDashboardModel],
})
export class CarriersStoreModule {}
