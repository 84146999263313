import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSegment } from '@angular/router';
import { AuthGuard, LoadGuard, UserTypeGuard } from '@haulynx/guards';
import { UserType } from '@haulynx/types';

const routes: Routes = [
  {
    path: 'active',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./active-loads-container/active-loads-container.module').then((m) => m.ActiveLoadsContainerModule),
  },
  {
    path: 'completed',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./completed-loads-container/completed-loads-container.module').then(
        (m) => m.CompletedLoadsContainerModule
      ),
  },
  {
    path: 'available',
    loadChildren: () =>
      import('./available-loads-container/available-loads-container.module').then(
        (m) => m.AvailableLoadsContainerModule
      ),
  },
  {
    path: 'search',
    data: { userType: UserType.BROKER, redirectUrl: '' },
    canActivate: [UserTypeGuard],
    loadChildren: () => import('./load-search/load-search.module').then((m) => m.LoadSearchModule),
    matcher: (url: UrlSegment[]) => {
      if (url.length === 1 && url[0].path.includes('search')) return { consumed: url };
      return null;
    },
  },
  {
    path: ':id',
    canActivate: [LoadGuard],
    loadChildren: () =>
      import('./load-details-container/load-details-container.module').then((m) => m.LoadDetailsContainerModule),
  },
  { path: '**', redirectTo: 'available' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LoadsRoutingModule {}
