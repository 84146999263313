import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  AdvancedSearchModule,
  AppointmentNotSetModule,
  AssignedBrokersModule,
  AssignLoadCarrierContainerModule,
  BulkActionsModule,
  CarrierToolbarModule,
  DataTableV2Module,
  LoadBidDetailsModule,
  LoadPriceModule,
  LoadSearchTimelineModule,
  SaveSearchViewComponent,
  SidebarModule,
  SpinnerModule,
} from '@haulynx/components';
import { DirectivesModule } from '@haulynx/directives';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { AdvancedSearchService } from '@haulynx/services';
import {
  AsyncEntityLoadStoreModule,
  AsyncEntitySearchViewStoreModule,
  AsyncEntityTrailerStoreModule,
  LoadFeedStoreModule,
} from '@haulynx/store';
import { ReactiveComponentModule } from '@ngrx/component';
import { CreateMissionModule } from '../../create-mission/create-mission.module';
import { LoadSearchContainerComponent } from '../load-search-container/load-search-container.component';
import { LoadSearchRouting } from './load-search-routing.module';
@NgModule({
  declarations: [LoadSearchContainerComponent, SaveSearchViewComponent],
  imports: [
    CommonModule,
    LoadSearchRouting,
    LoadFeedStoreModule,
    AsyncEntityLoadStoreModule,
    AsyncEntitySearchViewStoreModule,
    SidebarModule,
    AdvancedSearchModule,
    DataTableV2Module,
    CarrierToolbarModule,
    CreateMissionModule,
    MaterialModule,
    ReactiveFormsModule,
    AgmCoreModule,
    BulkActionsModule,
    LoadBidDetailsModule,
    SpinnerModule,
    AssignLoadCarrierContainerModule,
    PipesModule,
    AppointmentNotSetModule,
    AssignedBrokersModule,
    ReactiveComponentModule,
    LoadSearchTimelineModule,
    LoadPriceModule,
    DirectivesModule,
    AsyncEntityTrailerStoreModule,
  ],
  providers: [AdvancedSearchService],
})
export class LoadSearchModule {}
