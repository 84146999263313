import { Carrier } from '../carrier';
import { EnvironmentName } from '../environment';
import { User } from '../generic';

export enum FeatureFlag {
  ASSIGN_USX_TRAILERS = 'ASSIGN_USX_TRAILERS',
  ACTIVE_LOAD_MANAGEMENT = 'ALM',
  BOUNCE_CARRIER = 'BOUNCE_CARRIER',
  BROKER_BIDDING_V2 = 'BROKER_BIDDING_V2',
  CARRIER_BID = 'CARRIER_BID',
  CARRIER_DASHBOARD_V2 = 'CARRIER_DASHBOARD_V2',
  CARRIER_HEADER = 'CARRIER_HEADER',
  CARRIER_LOAD_SEARCH = 'CARRIER_LOAD_SEARCH',
  CARRIER_MANAGEMENT_DASHBOARD = 'CMD',
  CARRIER_TOOLBAR = 'CARRIER_TOOLBAR',
  CLS_ACTIVE_LOADS = 'CLS_ACTIVE_LOADS',
  DEDICATED = 'DEDICATED',
  DEDICATED_BUTTON = 'DEDICATED_BUTTON',
  DRIVER_LITE = 'DRIVER_LITE',
  EDIT_LOAD = 'EDIT_LOAD',
  FREIGHT_CONTRACTS = 'FREIGHT_CONTRACTS',
  HIDE_OLD_SEARCH_BOARD = 'HIDE_OLD_SEARCH_BOARD',
  INLINE_CREATION = 'INLINE_CREATION',
  INLINE_CREATION_ALM = 'INLINE_CREATION_ALM',
  LANE_ANALYSIS = 'LANE_ANALYSIS',
  LANE_HISTORY = 'LANE_HISTORY',
  LANE_RATE_TOOL = 'LANE_RATE_TOOL',
  LOAD_ACTIVE_EDIT_PRICE = 'LOAD_ACTIVE_EDIT_PRICE',
  LOAD_ACTIVITY = 'LOAD_ACTIVITY',
  LOAD_DOCUMENTS = 'LOAD_DOCUMENTS',
  LOAD_BROKER_ASSIGNMENTS = 'LOAD_BROKER_ASSIGNMENTS',
  LOAD_LOCKING = 'LOAD_LOCKING',
  LOAD_OFFER_BIDDING = 'BID',
  LOAD_OVERVIEW = 'LOAD_OVERVIEW',
  LOAD_SEARCH = 'LOAD_SEARCH',
  MACROPOINT = 'MACROPOINT',
  MAX_BUY = 'MAX_BUY',
  MISSIONS = 'MISSIONS',
  NEW_CARRIER_RECOMMENDATIONS = 'NEW_CARRIER_RECOMMENDATIONS',
  NOTIFICATION_NEW_MATCH = 'NOTIFICATION_NEW_MATCH',
  PAY_LINE_EDIT = 'PLE',
  PAY_LINE_ITEMS = 'PLI',
  PREFERRED_LANES = 'PREFERRED_LANES',
  RATECON = 'RATECON',
  RECOMMENDATION_VOTE = 'RECOMMENDATION_VOTE',
  REPEAT_NOTIFICATIONS = 'REPEAT_NOTIFICATIONS',
  SPOT_ADMIN_TOOL = 'SPOT_ADMIN_TOOL',
  TEST_LOADS = 'TEST_LOADS',
  TRACKING_STATUS = 'TRACKING_STATUS',
  TRACKING_TYPE = 'TRACKING_TYPE',
  WHATS_NEW = 'WHATS_NEW',
  CARRIER_METRICS = 'CARRIER_METRICS',
  CARRIER_COMPLIANCE = 'CARRIER_COMPLIANCE',
  CARRIER_TOOLBAR_OPPORTUNITIES = 'CARRIER_TOOLBAR_OPPORTUNITIES',
  TRAILER_SWAP = 'TRAILER_SWAP',
  TRAILER_TRACKING = 'TRAILER_TRACKING',
}

export interface FeatureFlags {
  name: string;
  description: string;
  platforms: FeatureFlagPlatform;
  permissions: FeatureFlagPermission[];
}

export interface FeatureFlagDetails {
  name: string;
  description: string;
  platforms: FeatureFlagPlatform;
  environments: FeatureFlagRestriction[];
  builds?: FeatureFlagBuilds;
}

export interface FeatureFlagPlatform {
  web: boolean;
  ios: boolean;
  android: boolean;
}

export interface FeatureFlagBuilds {
  ios: number;
  android: number;
}

export interface FeatureFlagRestriction {
  environment: EnvironmentName;
  users: Partial<User>[];
  dots: Partial<Carrier>[];
}

export interface FeatureFlagPermission {
  environment: EnvironmentName;
  users: string[];
  dots: string[];
}

export enum EntityTypes {
  USER,
  CARRIER,
  TRUCKS,
  TRAILERS,
  DRIVERS,
  PHONES,
  EMAILS,
  TRACKING_TYPES,
}

export interface FeatureFlagQuery {
  name: string;
  type: EntityTypes;
  index: number;
}

export interface FeatureFlagPopulate {
  type: EntityTypes;
  index: number;
}

export interface FeatureFlagForm {
  environment: EnvironmentName;
  userIds: string[];
  carrierDots: string[];
  index: number;
}

export interface FeatureFlagDetailsInput {
  name: string;
  description: string;
  platforms: FeatureFlagPlatform;
  environments: FeatureFlagRestrictionInput[];
}

export interface FeatureFlagRestrictionInput {
  environment: EnvironmentName;
  users: string[];
  dots: string[];
}

export const environmentList: EnvironmentName[] = ['alpha', 'development', 'pilot', 'production', 'staging'];
