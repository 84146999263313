import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DialogTemplateModule } from '../dialog-template/dialog-template.module';
import { LeaveInRmisProcessDialogComponent } from './leave-in-rmis-process-dialog.component';

@NgModule({
  declarations: [LeaveInRmisProcessDialogComponent],
  imports: [CommonModule, DialogTemplateModule],
  exports: [LeaveInRmisProcessDialogComponent],
})
export class LeaveInRmisProcessDialogModule {}
