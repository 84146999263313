import { LoadsServiceLoad } from '../../loads-service';
import { Load } from '../load';

export interface NeptuneLoad {
  id: string;
  match: string;
  loadCreated: string;
  load?: Load;
  loadsServiceLoad?: LoadsServiceLoad;
  lane: string;
  loadPickupAddress: string;
  loadPickupTimestamp: string;
  loadDropoffAddress: string;
  loadDropoffTimestamp: string;
  loadEquipmentType: string;
  loadWeight: string;
  distance: string;
  loadPickupLat: string;
  loadPickupLon: string;
  loadDropoffLat: string;
  loadDropoffLon: string;
  truckId?: string;
  odh?: string;
  ddh?: string;
}
