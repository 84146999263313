import { LoadIdentifierType, LoadsServiceLoad } from '@haulynx/types';
import { get, nth } from 'lodash';
import { getLoadsServiceLoadAlternateId } from './load-view';

export function formatLocations(load: LoadsServiceLoad, titlecasePipe, addressPipe): string {
  let address =
    titlecasePipe.transform(addressPipe.transform(get(nth(load.locations, 0), 'address', 'N/A'), 3)) +
    ', ' +
    addressPipe.transform(get(nth(load.locations, 0), 'address', 'N/A'), 4) +
    ' -> ' +
    titlecasePipe.transform(addressPipe.transform(get(nth(load.locations, -1), 'address', 'N/A'), 3)) +
    ', ' +
    addressPipe.transform(get(nth(load.locations, -1), 'address', 'N/A'), 4);
  const tmw = getLoadsServiceLoadAlternateId(load, LoadIdentifierType.TMW_NUMBER);
  if (tmw) address += ` (#${tmw})`;
  return address;
}
