export interface GooglePlaceAutoComplete {
  status: string;
  predictions: Array<GooglePlacePrediction>;
}

export interface GooglePlacePrediction {
  description: string;
  distance_meters?: number;
  id?: string;
  matched_substrings?: Array<{
    length: number;
    offset: number;
  }>;
  place_id?: string;
  reference?: string;
  terms?: Array<{
    offset: number;
    value: string;
  }>;
  types?: Array<GooglePlaceTypes>;
}

export interface GooglePlaceDetails {
  formatted_address: string;
  geometry: {
    location: {
      lat: () => number;
      lng: () => number;
    };
    viewport: google.maps.LatLngBounds;
  };
}

export interface MapboxTimezoneData {
  type: string;
  features: Array<{
    type: string;
    geometry: any;
    properties: {
      TZID: string;
      timequery: any;
    };
  }>;
}

export enum GooglePlaceTypes {
  STATE = 'administrative_area_level_1',
  COUNTRY = 'political',
  CITY = 'locality',
}

export enum GooglePlaceCountries {
  USA = 'USA',
  CANADA = 'Canada',
  MEXICO = 'Mexico',
}

export enum PlaceCountries {
  USA = 'United States',
  CANADA = 'Canada',
  MEXICO = 'Mexico',
}

export interface StateDictionary {
  name: string;
  code: string;
}
