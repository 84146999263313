import { Component, Input, OnChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { CarrierSearchService, FilterEventsService, LocalStoreService, NotificationsService } from '@haulynx/services';
import { CarrierLoadSearchClickEvents, LoadServiceOriginDestinationData, MapBoxFeature } from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { Observable, of } from 'rxjs';
import { catchError, takeUntil, map, take } from 'rxjs/operators';

@Component({
  selector: 'haulynx-search-radius-field',
  templateUrl: './search-radius-field.component.html',
  styleUrls: ['./search-radius-field.component.scss'],
})
export class SearchRadiusFieldComponent implements OnChanges {
  @Input() label: string;
  @Input() searchForm: FormGroup = new FormGroup({
    address: new FormControl(null),
    radiusMiles: new FormControl(null),
  });
  @Input() clickEvent$: Observable<CarrierLoadSearchClickEvents>;

  recentSearches: MapBoxFeature[] = [];
  predictions$: Observable<MapBoxFeature[]>;
  selectedPrediction: MapBoxFeature = null;
  oldSelectedPrediction: MapBoxFeature = null;
  stateSearchTermsLength = 2;
  showRadiusField = true;
  searchQueryStarted = false;
  alive = aliveWhile();
  @Input() radiusValues: Array<{ key: string; value: number }> = [
    { key: '+10 mi', value: 10 },
    { key: '+25 mi', value: 25 },
    { key: '+50 mi', value: 50 },
    { key: '+75 mi', value: 75 },
    { key: '100 mi', value: 100 },
    { key: '+150 mi', value: 150 },
    { key: '+200 mi', value: 200 },
    { key: '+500 mi', value: 500 },
  ];
  @Input() defaultRadiusValue = 200;
  currentLocationSelectedPrediction: MapBoxFeature = null;

  constructor(
    private carrierSearchService: CarrierSearchService,
    private notificationsService: NotificationsService,
    private localStoreService: LocalStoreService,
    private filterEventsService: FilterEventsService
  ) {
    this.filterEventsService.filterEvent$?.pipe(takeUntil(this.alive)).subscribe((event: string) => {
      if (
        event == CarrierLoadSearchClickEvents.RESET_FILTERS ||
        event == CarrierLoadSearchClickEvents.RESET_AND_APPLY_FILTERS
      ) {
        this.selectedPrediction = null;
        this.searchForm.reset();
        this.searchForm.patchValue({ radiusMiles: this.defaultRadiusValue });
      }
    });
  }

  ngOnChanges() {
    this.localStoreService.get<MapBoxFeature[]>('recentSearches').then((recentSearches) => {
      if (!recentSearches) return;
      this.recentSearches = recentSearches;
    });
    if (this.searchForm.controls.states.value) {
      this.searchForm.patchValue({ radiusMiles: null });
      this.searchForm.controls.radiusMiles.disable();
    }
    this.searchForm?.controls?.address?.valueChanges.pipe(takeUntil(this.alive)).subscribe((address: string) => {
      address ? (this.searchQueryStarted = true) : (this.searchQueryStarted = false);
      this.predictions$ = this.carrierSearchService.onAddressChange(address);
    });
  }

  ngOnDestroy() {
    this.alive.destroy();
  }

  setPrediction(event: MatAutocompleteSelectedEvent) {
    const selectedPrediction = event.option.value;

    if (selectedPrediction === 'Current Location') {
      if (!this.currentLocationSelectedPrediction) {
        this.getCurrentLocationPrediction();
        return;
      }

      this.searchState({ ...selectedPrediction, place_name: 'Current Location' });
      return;
    }

    if (!selectedPrediction) return;

    if (this.recentSearches.length >= 5) this.recentSearches.pop();

    this.recentSearches = [selectedPrediction, ...this.recentSearches];
    this.localStoreService.set('recentSearches', this.recentSearches);

    this.searchState(selectedPrediction);
  }

  private getCurrentLocationPrediction(): void {
    this.carrierSearchService
      .getCurrentLocation()
      .pipe(
        takeUntil(this.alive),
        map((prediction: MapBoxFeature) => prediction ?? null),
        catchError((error) => {
          this.notificationsService.error('Unable to retrieve your location.');
          return of(null);
        })
      )
      .subscribe((prediction) => {
        if (!prediction) {
          this.notificationsService.error('Please ensure your location setting is enabled for this browser.');
          return;
        }

        this.currentLocationSelectedPrediction = prediction;
        this.searchState({ ...prediction, place_name: 'Current Location' });
      });
  }

  searchState(selectedPrediction: MapBoxFeature) {
    const [lon, lat] = selectedPrediction.geometry.coordinates;
    const showRadiusField = !this.carrierSearchService.isSearchByState(selectedPrediction);
    const stateValue = this.carrierSearchService.getSelectedStateCode(selectedPrediction);

    let formState: Partial<LoadServiceOriginDestinationData> = {
      address: selectedPrediction.place_name,
    };

    if (!showRadiusField && stateValue) {
      this.searchForm.controls.radiusMiles.disable();
      formState = {
        ...formState,
        radiusMiles: null,
        lat: null,
        lon: null,
        states: stateValue,
      };
    } else {
      this.searchForm.controls.radiusMiles.enable();
      formState = {
        ...formState,
        radiusMiles: this.searchForm.controls.radiusMiles.value || this.defaultRadiusValue,
        lat: lat ?? null,
        lon: lon ?? null,
        states: null,
      };
    }
    this.searchForm.patchValue({
      ...formState,
    });
  }
}
