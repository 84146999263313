import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  KeyValueDiffers,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DataTableV2Service } from '@haulynx/services';
import {
  BookStatus,
  BulkActionPage,
  CSVChoice,
  CSVDataOptions,
  DataTableConfig,
  DataTableType,
  EntityType,
  IColumns2,
  ISearchFilter,
  ISortingConfig,
  LoadBulkActionPayload,
  LoadsServiceLoadStatus,
  SaveSearchViewDto,
  SidebarItem,
  OrderByParams,
  ColumnField,
  RowSelectionEvent,
  DataTableCSVTypes,
  LaneHistoryRow,
  LoadsServiceLoad,
} from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';
import { Record } from 'immutable';
import { ZipLane } from 'libs/types/src/lib/web-interfaces/truck';
import { last, toLower, Many, keyBy } from 'lodash';
import { TableHeaderCheckbox } from 'primeng/table';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'data-table-v2',
  templateUrl: './data-table-v2.component.html',
  styleUrls: ['./data-table-v2.component.scss'],
})
export class DataTableV2Component implements OnInit, OnChanges, AfterContentInit, OnDestroy {
  @ViewChildren('tableRow') rows: QueryList<ElementRef>;
  @ViewChild('headerCheckbox', { static: false }) headerCheckbox: TableHeaderCheckbox;

  @Input() data: DataTableType<EntityType> = [];
  @Input() tableConfig?: DataTableConfig;
  @Input() checkBoxSelectAll = false;
  @Input() emptyMessage = 'No Records Found';
  @Input() displayedColumns: IColumns2[] = [];
  @Input() title = null;
  @Input() showBottomPaginator = false;
  @Input() templateCellRef: TemplateRef<Record<EntityType>>;
  @Input() selectionMode: 'single' | 'multiple' | null = 'single';
  @Input() lazy = false;
  @Input() filterForm = '';
  @Input() filterLocally = false;
  @Input() sortColumns = true;
  @Input() rowSpacing = 12;
  @Input() scrollHeight: string | 'inherit' = 'inherit';
  @Input() scrollable = true;
  @Input() parentToTable: HTMLElement;
  @Input() stickyColumnWidth = '150px';
  @Input() loading = true;
  @Input('paginationData') _paginationData: {
    currentPage: number;
    total: number;
    totalPages: number;
  } = null;
  @Input() entitiesLoading;
  @Input() stickyColumnCount = 2;
  @Input() selectedFilters: ISearchFilter[];
  @Input() isSearchView: boolean;
  @Input() selectedSidebarItem: SidebarItem;
  @Input() selectedRows: EntityType[] = [];
  @Input() toggleRowsHighlight: { entityType: EntityType; highlightState: boolean }[]; //If you want to manually set the highlight state of a row
  @Input() showDownloadCSV = false;
  @Input() loadsNearestCities: { [key: string]: string } = {};

  @Output() onSelect = new EventEmitter<DataTableType<EntityType>>();
  @Output() rowSelect = new EventEmitter<RowSelectionEvent<EntityType>>();
  @Output() clickableColumnOnSelect = new EventEmitter<{ column: IColumns2; row: DataTableType<EntityType> }>();
  @Output() globalFilterChanged = new EventEmitter<string>();
  @Output() bulkAction = new EventEmitter<LoadBulkActionPayload>();
  @Output() page = new EventEmitter<{ pageUp: boolean; limit: number }>();
  @Output() sort = new EventEmitter<{ config: ISortingConfig; sortType: string; page: number }>();
  @Output() toggleChange = new EventEmitter<boolean>();
  @Output() saveSearchView = new EventEmitter<SaveSearchViewDto>();
  @Output() columnsChange = new EventEmitter<IColumns2[]>();
  @Output() columnsRest = new EventEmitter();
  @Output() onRowHover = new EventEmitter();
  @Output() rowHeightsEvent = new EventEmitter<Array<{ value: number; row: string }>>();
  @Output() displayDataChanged = new EventEmitter<EntityType[]>();

  visibleColumns: IColumns2[] = [];
  highlightedLoads: { [key: string]: any } = {};
  dataSource: EntityType[] = [];
  showView$ = new BehaviorSubject(false);
  showingRowCountOnPaginator = 10;
  currentFirstRecord = 0;
  hoveringRow = -1;
  debounceTimeout = null;
  frozenColumms: IColumns2[] = [];
  rowHeights: Array<{ value: number; row: string }> = [];
  dataHasPriority = false;
  hasRowSubscription = false;
  alive = aliveWhile();
  csvDataOptions: CSVDataOptions[] = [];
  toggleForm: FormGroup;
  BulkActionPage = BulkActionPage;
  private localPaginationData: {
    currentPage: number;
    total: number;
    totalPages: number;
  };
  private localSortConfig: OrderByParams<any> = null;
  private _sortedData: EntityType[] = null;
  isDisplayTitle = false;
  csvChoices = CSVChoice;
  colFields = ColumnField;

  constructor(
    private dataTableService: DataTableV2Service,
    private el: ElementRef,
    private fb: FormBuilder,
    private changeDetector: ChangeDetectorRef,
    private differs: KeyValueDiffers
  ) {}

  get paginationData(): {
    currentPage: number;
    total: number;
    totalPages: number;
  } {
    if (this._paginationData) {
      return this._paginationData;
    } else {
      return this.localPaginationData;
    }
  }

  set paginationData(data: { currentPage: number; total: number; totalPages: number }) {
    this._paginationData = data;
  }

  get sortedData(): EntityType[] {
    if (this.localSortConfig) {
      return this._sortedData;
    } else {
      return this.dataTableService.getData(this.data);
    }
  }

  set pageData(data: {
    currentPageData: EntityType[];
    pagination: {
      currentPage: number;
      total: number;
      totalPages: number;
    };
  }) {
    ({ pagination: this.localPaginationData, currentPageData: this.dataSource } = data);
  }

  ngOnInit(): void {
    this.defaultOrdering();
    this.dataSource = this.dataTableService.getData(this.data);
    this.toggleForm = this.fb.group({
      toggle: true,
    });
    this.toggleForm.valueChanges.pipe(takeUntil(this.alive)).subscribe(() => {
      this.toggleChange.emit(this.toggleForm.controls['toggle'].value);
    });
    this.displayTitle();
  }

  hover(index) {
    this.hoveringRow = index;
    this.onRowHover.emit(this.dataSource[index]);
  }

  ngOnChanges(changes: SimpleChanges): void {
    // TODO: create request for a CSV email from backend
    // if (this.data) {
    //   if (!this.csvDataOptions.some((item) => item.value === CSVChoice.ALL)) {
    //     this.csvDataOptions.push({ label: 'All Search Results', value: CSVChoice.ALL });
    //   }
    // } else {
    //   this.csvDataOptions = this.csvDataOptions.filter((item) => item.value !== CSVChoice.ALL);
    // }

    if (changes.data) {
      this.highlightedLoads = [];

      if (this.localSortConfig) {
        this._sortedData = this.dataTableService.sortData(
          this.dataTableService.getData(this.data),
          this.localSortConfig
        );
      } else {
        this.dataSource = this.dataTableService.getData(this.data);
      }
      this.pageData = this.dataTableService.getPaginationData(this.sortedData, this.tableConfig.pageAmount);

      this.setRowHeights();
      this.displayTitle();
    }

    if (changes.selectedRows) {
      this.setHighlights(changes.selectedRows.currentValue);
    }

    if (changes.toggleRowsHighlight) {
      this.manaullySetHighlights(changes.toggleRowsHighlight.currentValue);
    }

    if (changes.displayedColumns) {
      this.frozenColumms = this.displayedColumns.filter((column: IColumns2) => column.isSticky);
      this.displayedColumns = this.displayedColumns.filter((column: IColumns2) => !column.isSticky);
      this.visibleColumns = this.displayedColumns.filter((column: IColumns2) => !column.isSticky && column?.isVisible);
      this.dataHasPriority = this.displayedColumns.find((value) => value.field === 'priority') !== undefined;
      if (this.tableConfig?.checkBoxSelection) {
        const checkbox: IColumns2 = {
          field: 'checkbox',
          label: '',
          sortConfig: [],
          isSortable: false,
          isCustomCell: false,
          width: '3rem',
          isSticky: false,
        };
        if (this.dataHasPriority) {
          this.visibleColumns.splice(1, 0, checkbox);
        } else {
          this.visibleColumns.unshift(checkbox);
        }
      }
      this.setRowHeights();
    }

    if (this.rows && !this.hasRowSubscription) {
      this.hasRowSubscription = true;
      this.rows.changes.pipe(takeUntil(this.alive)).subscribe(() => {
        setTimeout(() => {
          this.rowHeights = [];
          this.setRowHeights();
        });
      });
    }
  }

  ngAfterContentInit(): void {
    if (this.parentToTable) {
      this.dataTableService.addResizeEventListener(this.parentToTable, (elem: HTMLElement) => {
        if (this.title !== '' || this.tableConfig?.globalFilter) {
          this.scrollHeight = elem.offsetHeight - 108 + 'px';
        } else {
          this.scrollHeight = elem.offsetHeight - 8 + 'px';
        }
      });
    }
  }

  setRowHeights(): void {
    setTimeout(() => {
      this.rowHeights = [];
      // the timeout avoids running this algorithm before rows finish rendering
      const data = this.dataTableService.getData(this.dataSource);
      if (data?.length) {
        // if there's no data, there's no point in resizing rows
        // There are multiple <table> elements each with a <tbody> in this component
        // (main table, sticky columns, and the header row all have <tbody>).
        // The <tbody> with the table rows is the last of these.
        const mainTableRows: HTMLElement[] = Array.from(
          last(this.el.nativeElement.getElementsByTagName('tbody'))['rows']
        );
        const stickyTableRows: HTMLElement[] = Array.from(
          this.el.nativeElement.getElementsByTagName('tbody')[1]['rows']
        );
        this.rowHeights = mainTableRows.map((row, i) => {
          if (row.offsetHeight > stickyTableRows[i].offsetHeight) {
            return {
              value: row.offsetHeight,
              row: data[i].id,
            };
          } else {
            return {
              value: stickyTableRows[i].offsetHeight,
              row: data[i].id,
            };
          }
        });
        this.rowHeightsEvent.emit(this.rowHeights);
        this.changeDetector.detectChanges();
      }
    });
  }

  getRowCount(): number {
    return this.dataTableService.getData(this.dataSource).length;
  }

  getSelectedRowCount(): number {
    return this.selectedRows?.length;
  }

  getColumnCount(): number {
    const hasRowSort = this.tableConfig?.reorderRows ? 1 : 0;
    const hasCheckbox = this.tableConfig?.checkBoxSelection ? 1 : 0;
    return this.visibleColumns ? this.visibleColumns.length + hasRowSort + hasCheckbox : 0;
  }

  trackByItem(index: number, item: { id?: string }): string | number {
    return item.id || index;
  }

  selectRow(event: any, checkbox?: 'select-all'): void {
    const isSelectAll = checkbox === 'select-all';
    this.setHighlights(Array.isArray(event) ? event : [event?.data], isSelectAll);

    const rowSelectionState: boolean = isSelectAll
      ? !!this.getSelectedRowCount()
      : !!this.highlightedLoads[event.data?.id];

    if (this.getSelectedRowCount() !== 0 && !this.csvDataOptions.some((item) => item.value === CSVChoice.SELECTED)) {
      this.csvDataOptions.push({ label: 'Selected Lines Only', value: CSVChoice.SELECTED });
    }
    if (this.getSelectedRowCount() === 0) {
      this.csvDataOptions = this.csvDataOptions.filter((item) => item.value !== CSVChoice.SELECTED);
    }

    this.rowSelect.emit({
      row: event.data,
      headerToggled: isSelectAll,
      rowSelected: rowSelectionState,
      selection: this.selectedRows,
      page: this.dataSource,
    });

    this.onSelect.emit(this.selectedRows);
  }

  private manaullySetHighlights(rows: { entityType: EntityType; highlightState: boolean }[]) {
    rows?.forEach((row) => {
      if (row.highlightState) {
        this.highlightedLoads[row?.entityType?.id] = true;
      } else {
        delete this.highlightedLoads[row?.entityType?.id];
      }
    });
  }

  private setHighlights(rows: EntityType[], isSelectAll = false) {
    if (isSelectAll) {
      this.highlightedLoads = keyBy(this.selectedRows, 'id');
    } else {
      rows?.forEach((row) => {
        if (this.selectionMode === 'single') {
          this.highlightedLoads = [];
          if (!this.highlightedLoads[row?.id]) {
            this.highlightedLoads[row?.id] = true;
          }
        } else {
          if (!this.highlightedLoads[row?.id]) {
            this.highlightedLoads[row?.id] = true;
          } else {
            delete this.highlightedLoads[row?.id];
          }
        }
      });
    }
  }

  onBulkAction(event: LoadBulkActionPayload): void {
    this.bulkAction.emit(event);
  }

  onDialogClose(): void {
    this.headerCheckbox.boxViewChild.nativeElement.click();
    this.headerCheckbox.boxViewChild.nativeElement.click();
  }

  downloadClicked() {
    this.downloadLaneAnalysisCSV();
  }

  downloadLaneAnalysisCSV() {
    const formula = 'Lane-Analysis';
    const options = this.dataTableService.getCSVOptions(DataTableCSVTypes.LANE_ANALYSIS);
    const csvData = this.dataTableService.getData(this.data);
    const arrayToSort = [...csvData];
    arrayToSort.sort((a: any, b: any) => {
      return a.paymentDetails.bookedAt < b.paymentDetails.bookedAt
        ? 1
        : a.paymentDetails.bookedAt > b.paymentDetails.bookedAt
        ? -1
        : 0;
    });
    const formattedData = arrayToSort.map((row) => {
      return this.dataTableService.getCSVData(row as unknown as LaneHistoryRow, DataTableCSVTypes.LANE_ANALYSIS);
    });
    if (csvData.length) {
      new AngularCsv(formattedData, formula, options);
    }
  }

  downloadLoadBoardClicked() {
    this.downloadLoadBoardCSV();
  }

  downloadLoadBoardCSV() {
    const formula = 'Loads';
    const options = this.dataTableService.getCSVOptions(DataTableCSVTypes.LOAD_BOARD);
    const csvData = this.dataTableService.getData(this.selectedRows);
    const arrayToSort = [...csvData];
    arrayToSort.sort((a: any, b: any) => {
      return a.paymentDetails.bookedAt < b.paymentDetails.bookedAt
        ? 1
        : a.paymentDetails.bookedAt > b.paymentDetails.bookedAt
        ? -1
        : 0;
    });
    const formattedData = arrayToSort.map((row) => {
      return this.dataTableService.getCSVData(row as unknown as LoadsServiceLoad, DataTableCSVTypes.LOAD_BOARD, this.loadsNearestCities);
    });
    if (csvData.length) {
      new AngularCsv(formattedData, formula, options);
    }
  }

  downloadCSV(choice: CSVChoice): void {
    const formula = 'Lanes';
    const options = this.dataTableService.getCSVOptions();

    let csvData;
    if (choice === CSVChoice.ALL) {
      csvData = this.dataTableService.getData(this.data).map((entity) => {
        return this.dataTableService.getCSVData(entity as unknown as ZipLane);
      });
    } else {
      csvData = this.selectedRows.map((entity) => {
        return this.dataTableService.getCSVData(entity as unknown as ZipLane);
      });
    }

    if (csvData.length) {
      new AngularCsv(csvData, formula, options);
    }
  }

  getTooltipName(value: string): string {
    if (value === 'none') {
      return 'No Priority';
    }
    return value?.charAt(0).toUpperCase() + value?.slice(1) + ' Priority';
  }

  isMlCol(field): boolean {
    switch (field) {
      case 'mlAllInRpm':
        return true;

      case 'mlCostRpm':
        return true;

      case 'laneVolatility':
        return true;

      default:
        return false;
    }
  }

  onSelectOption(config: { config: ISortingConfig; type: 'ASC' | 'DESC' }): void {
    const isOnlySortLocally = this.paginationData ? this.paginationData.total === this.getRowCount() : true;
    if (config.config.emitSort && !isOnlySortLocally) {
      this.sort.emit({ config: config.config, sortType: config.type, page: 1 });
    } else if (!config.config.dataIndex) {
      if (config.config.defaultOrder && !config.type) {
        this.localSortConfig = [
          config.config.orderByIndex,
          toLower(config.config.defaultOrder) as Many<boolean | 'asc' | 'desc'>,
        ];
      } else {
        this.localSortConfig = [config.config.orderByIndex, toLower(config.type) as Many<boolean | 'asc' | 'desc'>];
      }
      this._sortedData = this.dataTableService.sortData(this.dataTableService.getData(this.data), this.localSortConfig);
      this.pageData = this.dataTableService.getPaginationData(this.sortedData, this.tableConfig.pageAmount);
    } else {
      const data = this.dataTableService.getData(this.dataSource);
      let dataIndex;
      if (config.config.emitSort) {
        const temp = config.config.dataIndex.split('?');
        if (temp.length < 2) {
          console.error(
            'When emit = true, dataIndex format should be <ASC order type>~<DSC order type>?<localSort config>'
          );
        } else {
          dataIndex = temp[1].split('.');
        }
      } else {
        dataIndex = config.config.dataIndex.split('.');
      }
      this.dataSource = data.slice().sort((data1: EntityType, data2: EntityType) => {
        const value1 = this.getDataValue(data1, dataIndex);
        const value2 = this.getDataValue(data2, dataIndex);
        let result = null;
        if (!value1 && value2) result = 1;
        else if (!value2 && value1) result = -1;
        else if (!value1 && !value2) result = 0;
        else if (typeof value1 === 'string') result = value1.localeCompare(value2);
        else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
        return config.type === 'ASC' ? 1 * result : -1 * result;
      });
      this.setRowHeights();
      this.displayDataChanged.emit(this.dataSource);
    }
  }

  getDataValue(data: EntityType, dataIndex: string[]): string {
    let finalValue;
    let tempValue;
    dataIndex.forEach((value: string, index: number) => {
      if (parseInt(value, 10)) {
        tempValue = tempValue[parseInt(value, 10)];
      } else if (value === 'length') {
        tempValue = tempValue[tempValue.length - 1];
      } else if (value.includes('where')) {
        const whereClause = value.split('-')[1];
        const props = whereClause.split('=');
        tempValue = tempValue.find((v) => v[props[0]] === props[1]);
      } else if (value.includes('last')) {
        tempValue = tempValue[tempValue.length - 1];
      } else {
        if (index === dataIndex.length - 1 && dataIndex.length === 1) {
          finalValue = data[value];
        } else if (index === dataIndex.length - 1 && dataIndex.length > 1) {
          if (tempValue) finalValue = tempValue[value];
          else finalValue = null;
        } else if (!tempValue) {
          tempValue = data[value];
        } else {
          tempValue = tempValue[value];
        }
      }
    });
    return finalValue;
  }

  filter(event: string): void {
    this.filterForm = event;
    if (this.filterLocally) {
      if (this.filterForm !== '') {
        const filteredResults: EntityType[] = [];
        const addFilteredResult = (filtered: EntityType[]) => {
          filtered.forEach((test: EntityType) => {
            const found = filteredResults.find((current: EntityType) => current.name === test.name);
            if (!found) filteredResults.push(test);
          });
        };
        const data = this.dataTableService.getData(this.data);
        this.displayedColumns.forEach((column: IColumns2) => {
          if (column.isSortable) {
            const filteredData = data.filter((value) =>
              value[column.field].toString().toLowerCase().includes(this.filterForm.toLowerCase()) ? true : false
            );
            addFilteredResult(filteredData);
          }
        });
        this.dataSource = filteredResults;
        this.setRowHeights();
      } else {
        this.dataSource = this.dataTableService.getData(this.data);
        this.setRowHeights();
      }
    } else {
      if (this.debounceTimeout !== null) clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.globalFilterChanged.emit(this.filterForm);
        this.debounceTimeout = null;
      }, 500);
    }
  }

  clickedColumn(column: IColumns2, rowData: DataTableType<EntityType>): void {
    if (column.isClickable) {
      this.clickableColumnOnSelect.emit({ column, row: rowData });
    }
  }

  isDisplayableColumn(column: IColumns2): boolean {
    return column.field !== 'priority' && column.field !== 'checkbox';
  }

  onPage(event: boolean): void {
    if (this._paginationData) {
      this.page.emit({ pageUp: event, limit: this.tableConfig.pageAmount });
    } else {
      const currentPage = event ? this.localPaginationData.currentPage + 1 : this.localPaginationData.currentPage - 1;

      this.pageData = this.dataTableService.getPaginationData(
        this.sortedData,
        this.tableConfig.pageAmount,
        currentPage
      );
      this.displayDataChanged.emit(this.dataSource);
    }
  }

  ngOnDestroy(): void {
    if (this.parentToTable) {
      this.dataTableService.removeResizeEventListener(this.parentToTable);
    }
  }

  onSaveSearchView(saveSearchViewDto: SaveSearchViewDto): void {
    this.saveSearchView.emit(saveSearchViewDto);
  }

  onColumnsChange(columns: IColumns2[]): void {
    this.columnsChange.emit(columns);
  }

  onColumnsReset(): void {
    this.columnsRest.emit();
  }

  displayTitle(): void {
    const bookedLoads = this.dataTableService
      .getData(this.dataSource)
      .find((load) => load.bookStatus !== BookStatus.BOOKED && load.loadStatus !== LoadsServiceLoadStatus.FINALLED);
    if (bookedLoads) {
      this.isDisplayTitle = true;
    } else {
      this.isDisplayTitle = false;
    }
  }

  defaultOrdering(): void {
    const defaultSort = [];
    this.displayedColumns.forEach((col: IColumns2) => {
      col.sortConfig.forEach((sort: ISortingConfig) => {
        if (sort.defaultOrder) defaultSort.push(sort);
      });
    });
    if (defaultSort.length > 1) {
      console.error('Only one defaultOrder allowed per data table.');
    }
    if (defaultSort.length === 1) {
      this.onSelectOption({ config: defaultSort[0], type: null });
    }
  }
}
