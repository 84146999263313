import { combineLatest, Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { FeatureFlag, FFState, IColumns, KeyValuePair, User } from '../../../../types/src/lib/web-interfaces';
import { CarrierActions } from '../carrier-actions/carrier-actions';

export const preferredLaneActionMenu: KeyValuePair[] = [
  {
    key: 'Delete',
    value: 0,
  },
  {
    key: 'Convert to Lane',
    value: 1,
  },
];

export enum CarrierTabs {
  POSTED_TRUCKS = 'Posted Trucks',
  PREFERRED_LANES = 'Preferred Lanes',
}

export const preferredLaneActionMenuIcons: { svgIcon: string; class?: string }[] = [
  {
    svgIcon: 'haulynx-trash-gray',
    class: 'icon-label',
  },
  {
    svgIcon: 'haulynx-download-with-arrow',
    class: 'icon-label',
  },
];

export interface LoadsFilter {
  truckId: string;
}

export interface MapboxApiCoordinates {
  key: string;
  coordinates: string;
}
export const truckColumns = (): IColumns[] => {
  const allColumns: IColumns[] = [
    {
      label: 'Location',
      dataIndex: 'location',
      isCustomCell: true,
      isSortable: true,
      sortConvert: CarrierActions.truckFieldSort as any,
    },
    {
      label: 'Available',
      dataIndex: 'availableTime',
      isCustomCell: true,
      sortConvert: CarrierActions.truckFieldSort as any,
      isSortable: true,
    },
    {
      label: 'Destination',
      dataIndex: 'preferredLocation',
      isCustomCell: true,
      isSortable: true,
      sortConvert: CarrierActions.truckFieldSort as any,
    },
    {
      label: 'Equip',
      dataIndex: 'equipmentType',
      isSortable: true,
      sortConvert: CarrierActions.truckFieldSort as any,
    },
    {
      label: 'Posting',
      dataIndex: 'brokerId',
      isCustomCell: true,
      isSortable: true,
      sortConvert: CarrierActions.truckFieldSort as any,
    },
    {
      label: '',
      dataIndex: 'options',
      isCustomCell: true,
      width: '20px',
      isSortable: false,
    },
  ];
  return allColumns;
};

export const laneColumns = (): IColumns[] => {
  const allColumns: IColumns[] = [
    {
      label: 'Origin & Destination',
      dataIndex: 'originDestination',
      isCustomCell: true,
      isSortable: true,
      sortConvert: CarrierActions.preferredLaneFieldSort as any,
    },
    {
      label: 'Reoccuring',
      dataIndex: 'reoccuring',
      isCustomCell: true,
      isSortable: true,
      sortConvert: CarrierActions.preferredLaneFieldSort as any,
    },
    {
      label: 'Equip',
      dataIndex: 'equipmentType',
      isCustomCell: true,
      isSortable: true,
      sortConvert: CarrierActions.preferredLaneFieldSort as any,
    },
    {
      label: 'Min. Price',
      dataIndex: 'price',
      isCustomCell: true,
      isSortable: true,
      sortConvert: CarrierActions.preferredLaneFieldSort as any,
    },
    {
      label: 'Posted by',
      dataIndex: 'postedBy',
      isCustomCell: true,
      isSortable: true,
      sortConvert: CarrierActions.preferredLaneFieldSort as any,
    },
    {
      label: '',
      dataIndex: 'options',
      isCustomCell: true,
      width: '20px',
      isSortable: false,
    },
  ];
  return allColumns;
};

export function initRecommendedColumns(
  includeDistance = false,
  component: {
    includeDistance: boolean;
    recommendedColumns: IColumns[];
  }
) {
  component.includeDistance = includeDistance;
  component.recommendedColumns = [];

  if (includeDistance) {
    component.recommendedColumns.push({
      label: 'O-DH',
      dataIndex: 'oDh',
      isCustomCell: true,
      sortConvert: CarrierActions.loadFieldSort as any,
    });
  }

  const originLabel = includeDistance ? 'Origin' : 'Origin City';
  const width = includeDistance ? '130px' : null;
  component.recommendedColumns.push({
    label: originLabel,
    dataIndex: 'originCity',
    isCustomCell: true,
    width: width,
    sortConvert: CarrierActions.loadFieldSort as any,
  });

  const stateLabel = includeDistance ? 'St' : 'State';
  component.recommendedColumns.push({
    label: stateLabel,
    dataIndex: 'originState',
    isCustomCell: true,
    sortConvert: CarrierActions.loadFieldSort as any,
  });

  const miles = includeDistance ? 'L Miles' : 'Loaded Miles';
  component.recommendedColumns.push({
    label: miles,
    dataIndex: 'loadDistance',
    isCustomCell: true,
  });

  const destinationLabel = includeDistance ? 'Destination' : 'Destination City';
  component.recommendedColumns.push({
    label: destinationLabel,
    dataIndex: 'destinationCity',
    isCustomCell: true,
    width: width,
    sortConvert: CarrierActions.loadFieldSort as any,
  });

  component.recommendedColumns.push({
    label: stateLabel,
    dataIndex: 'destinationState',
    isCustomCell: true,
    sortConvert: CarrierActions.loadFieldSort as any,
  });

  if (includeDistance) {
    component.recommendedColumns.push({
      label: 'D-DH',
      dataIndex: 'dDh',
      isCustomCell: true,
      sortConvert: CarrierActions.loadFieldSort as any,
    });
  }

  const equipment = includeDistance ? 'Equip' : 'Equipment';
  component.recommendedColumns.push({
    label: equipment,
    dataIndex: 'loadEquipmentType',
    isCustomCell: true,
    isSortable: false,
  });

  if (!includeDistance) {
    component.recommendedColumns.push({
      label: 'Pick-Up Date',
      dataIndex: 'pickupDate',
      isCustomCell: true,
      sortConvert: CarrierActions.loadFieldSort as any,
    });
  }

  if (includeDistance) {
    component.recommendedColumns.push({
      label: 'Pick-Up',
      dataIndex: 'appPickupDate',
      isSortable: true,
      isCustomCell: true,
      sortConvert: CarrierActions.loadFieldSort as any,
    });
  }

  if (!includeDistance) {
    component.recommendedColumns.push({
      label: 'Delivery Date',
      dataIndex: 'dropoffDate',
      isCustomCell: true,
      sortConvert: CarrierActions.loadFieldSort as any,
    });
  }

  if (includeDistance) {
    component.recommendedColumns.push({
      label: 'Delivery',
      dataIndex: 'appDroppoffDate',
      isSortable: true,
      isCustomCell: true,
      sortConvert: CarrierActions.loadFieldSort as any,
    });
  }

  if (includeDistance) {
    component.recommendedColumns.push({
      label: 'Customer',
      dataIndex: 'customerName',
      isCustomCell: true,
      width: '150px',
      sortConvert: CarrierActions.loadFieldSort as any,
    });
  }

  component.recommendedColumns.push({
    label: 'TMW #',
    dataIndex: 'tmwNumber',
    isSortable: false,
    isCustomCell: true,
  });

  component.recommendedColumns.push({
    label: 'Price',
    dataIndex: 'price',
    isCustomCell: true,
    align: 'right',
    sortConvert: CarrierActions.loadFieldSort as any,
  });

  const revenue = includeDistance ? 'Rev' : 'Revenue';
  component.recommendedColumns.push({
    label: revenue,
    dataIndex: 'revenue',
    isCustomCell: true,
    align: 'right',
    sortConvert: CarrierActions.loadFieldSort as any,
  });

  if (includeDistance) {
    component.recommendedColumns.push({
      label: 'Bids',
      dataIndex: 'bidDetails',
      isCustomCell: true,
      width: '130px',
      sortConvert: CarrierActions.loadFieldSort as any,
    });
  }

  if (!includeDistance) {
    component.recommendedColumns.push({
      label: '',
      dataIndex: 'options',
      isCustomCell: true,
      width: '180px',
      isSortable: false,
    });
  }

  if (includeDistance) {
    component.recommendedColumns.push({
      label: '',
      dataIndex: 'emailIcon',
      isCustomCell: true,
      isSortable: false,
    });
  }

  component.recommendedColumns.push({
    label: '',
    dataIndex: 'bookLoadButton',
    isCustomCell: true,
    width: '120px',
    isSortable: false,
  });

  component.recommendedColumns.push({
    label: '',
    dataIndex: 'copyLink',
    isCustomCell: true,
    isSortable: false,
  });
}

export function initLoadSearchColumns(component: {
  appModel: { user$: Observable<User>; isValidCarrier$: Observable<boolean> };
  userEntityService: { featureFlags$: Observable<FFState> };
  featureFlags: FFState;
  loadFeedActionsService: { fullLoadFieldSort: { bind: (arg0: any) => any } };
  loadSearchColumns$: { next: (arg0: IColumns[]) => void };
  alive: Observable<any>;
}) {
  combineLatest([
    component.appModel.user$,
    component.appModel.isValidCarrier$,
    component.userEntityService.featureFlags$,
  ])
    .pipe(
      map(([user, isValidCarrier, features]: [User, boolean, FFState]) => {
        const columns: IColumns[] = [];
        component.featureFlags = features;

        columns.push({
          label: 'Origin City',
          dataIndex: 'pickupLocation',
          isCustomCell: true,
          sortConvert: component.loadFeedActionsService.fullLoadFieldSort.bind(component.loadFeedActionsService),
        });

        columns.push({
          label: 'State',
          dataIndex: 'pickupState',
          isCustomCell: true,
          sortConvert: component.loadFeedActionsService.fullLoadFieldSort.bind(component.loadFeedActionsService),
        });

        columns.push({
          label: 'Destination City',
          dataIndex: 'deliveryLocation',
          isCustomCell: true,
          sortConvert: component.loadFeedActionsService.fullLoadFieldSort.bind(component.loadFeedActionsService),
        });

        columns.push({
          label: 'State',
          dataIndex: 'deliveryState',
          isCustomCell: true,
          sortConvert: component.loadFeedActionsService.fullLoadFieldSort.bind(component.loadFeedActionsService),
        });

        columns.push({
          label: 'Equipment',
          dataIndex: 'equipmentType',
          isCustomCell: true,
          isSortable: true,
          sortConvert: component.loadFeedActionsService.fullLoadFieldSort.bind(component.loadFeedActionsService),
        });

        columns.push({
          label: 'Pick-Up Date',
          dataIndex: 'pickupTimestamp',
          isCustomCell: true,
          sortConvert: component.loadFeedActionsService.fullLoadFieldSort.bind(component.loadFeedActionsService),
          initiallySortedBy: 'asc',
        });

        columns.push({
          label: 'Delivery Date',
          dataIndex: 'dropoffTimestamp',
          isCustomCell: true,
          sortConvert: component.loadFeedActionsService.fullLoadFieldSort.bind(component.loadFeedActionsService),
        });

        if (user && user.broker) {
          columns.push({
            label: 'Customer',
            dataIndex: 'customer',
            isCustomCell: true,
            sortConvert: component.loadFeedActionsService.fullLoadFieldSort.bind(component.loadFeedActionsService),
          });
        }

        if (user && user.carrier) {
          columns.push({
            label: 'RPM',
            dataIndex: 'rpm',
            isCustomCell: true,
            sortConvert: component.loadFeedActionsService.fullLoadFieldSort.bind(component.loadFeedActionsService),
          });
        }

        columns.push({
          label: 'TMW #',
          dataIndex: 'tmwNumber',
          isSortable: true,
          isCustomCell: true,
        });

        columns.push({
          label: 'Loaded Miles',
          dataIndex: 'distance',
          isCustomCell: true,
        });

        columns.push({
          label: 'Order #',
          dataIndex: 'order',
          isSortable: true,
          isCustomCell: true,
          sortConvert: component.loadFeedActionsService.fullLoadFieldSort.bind(component.loadFeedActionsService),
        });

        if (user && user.broker) {
          columns.push({
            label: 'Revenue',
            dataIndex: 'revenue',
            isCustomCell: true,
            isSortable: true,
            sortConvert: component.loadFeedActionsService.fullLoadFieldSort.bind(component.loadFeedActionsService),
          });
        }
        if (user && (isValidCarrier || user.broker)) {
          columns.push({
            label: 'Price',
            dataIndex: 'price',
            isCustomCell: true,
            isSortable: true,
            sortConvert: component.loadFeedActionsService.fullLoadFieldSort.bind(component.loadFeedActionsService),
          });
        }

        if (user && user.broker && features && features[FeatureFlag.LOAD_OFFER_BIDDING]) {
          columns.push({
            label: 'Bids',
            dataIndex: 'bidDetails',
            isCustomCell: true,
            isSortable: true,
            sortConvert: component.loadFeedActionsService.fullLoadFieldSort.bind(component.loadFeedActionsService),
          });
        }

        if (user && user.carrier) {
          columns.push({
            label: 'Weight',
            dataIndex: 'weight',
            isCustomCell: true,
            sortConvert: component.loadFeedActionsService.fullLoadFieldSort.bind(component.loadFeedActionsService),
          });
        }

        if (user && (user.carrier || user.broker)) {
          columns.push({
            label: '',
            dataIndex: 'options',
            isCustomCell: true,
            cls: 'options',
            isSortable: false,
          });
        }

        component.loadSearchColumns$.next(columns);
      }),
      takeUntil(component.alive)
    )
    .subscribe();
}
