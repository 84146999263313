export interface IPostTruckInput {
  id?: string;
  carrierDot: string;
  brokerId?: string;
  locationLat: string;
  locationLon: string;
  location: string;
  timeAvailable?: string;
  preferredLocation?: string;
  equipmentType?: string;
  preferredLocationLat?: string;
  preferredLocationLon?: string;
  timestampTruckPosted?: number;
  radius?: number;
}
