import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Environment } from '@haulynx/types';
import { DocumentNode } from 'graphql';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GraphqlService {
  constructor(private http: HttpClient, private environment: Environment) {
    if (!this.environment?.apollo?.url)
      console.error(
        `You must provide 'apollo: { url: string }' as a Environment property in order to use this service`
      );
  }

  query<T>(options: {
    query: DocumentNode;
    variables?: Record<string, unknown>;
  }): Observable<{ data: T; error: { message: string } }> {
    const { query, variables } = options;

    return this.http
      .post<{ data: T }>(this.environment.apollo.url, { query: query.loc.source.body, variables })
      .pipe(
        map((result: { data: T; errors?: { errors: Record<string, string>[] } }) => this.handleResponse(result)),
        catchError((errors) => this.handleErrors(errors))
      );
  }

  mutate<T>(options: {
    mutation: DocumentNode;
    variables?: Record<string, unknown>;
  }): Observable<{ data: T; error: { message: string } }> {
    const { mutation, variables } = options;

    return this.http
      .post<{ data: T }>(this.environment.apollo.url, { query: mutation.loc.source.body, variables })
      .pipe(
        map((result: { data: T; errors?: { errors: Record<string, string>[] } }) => this.handleResponse(result)),
        catchError((errors) => this.handleErrors(errors))
      );
  }

  private handleResponse<T>(result: {
    data: T;
    errors?: { errors: Record<string, string>[] };
  }): { data: T; error: { message: string } } {
    const data = result?.data || null;
    const errors1: any = result?.errors || null;
    const errors2 = result?.errors?.errors || null;
    const message = this.getErrorMessage(errors1) || this.getErrorMessage(errors2);
    const error = message ? { message } : null;

    return { data, error };
  }

  private handleErrors({ errors }: { errors: Record<string, string>[] }): Observable<never> {
    const message = this.getErrorMessage(errors);
    return throwError({ message });
  }

  private getErrorMessage(errors: Record<string, string>[]): string {
    if (errors?.length) {
      return errors?.[0]?.message || null;
    }
  }
}
