import { AsyncManagerType, IAsyncEntityState } from '@haulynx/types';
import { objectToUrlParams } from '@haulynx/utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, createAction, on, props, ReducerTypes } from '@ngrx/store';
import { camelCase, keys } from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { AsyncDataDictionaryAction } from '../types/async-data-dictionary';
import { AsyncEntityActionFactory } from './async-entity-action-factory';

export class AsyncEntityDataDictionaryFactory<
  ActionPayloadType,
  ResponseData,
  ErrorPayloadType = any
> extends AsyncEntityActionFactory<ActionPayloadType, ResponseData, ErrorPayloadType> {
  public managerType = AsyncManagerType.DATA_DICTIONARY;
  public action = createAction(
    this.type,
    (payload: ActionPayloadType) =>
      ({
        payloadHash: this.getKey(payload),
        payload,
      } as AsyncDataDictionaryAction<ActionPayloadType>)
  );
  public actionSuccess = createAction(this.typeSuccess, props<{ payloadHash: string; payload: ResponseData }>());
  public actionCacheHit = createAction(this.typeCacheHit, props<{ payloadHash: string; payload: ResponseData }>());
  public actionError = createAction(this.typeError, props<{ payloadHash: string; payload: ErrorPayloadType }>());
  private options: AsyncEntityDataDictionaryOptions;

  constructor(
    namespace: string,
    name: string,
    effectCall: (payload: ActionPayloadType) => Observable<ResponseData>,
    options: AsyncEntityDataDictionaryOptions = {}
  ) {
    super(namespace, camelCase(name), effectCall);
    this.options = { doCache: false, ...options };
  }

  public getReducers(): ReducerTypes<IAsyncEntityState<ResponseData>, any>[] {
    return [
      on(this.action, (state, action) => {
        return {
          ...state,
          [this.name]: {
            ...state[this.name],
            [action.payloadHash]: {
              ...state[this.name]?.[action.payloadHash],
              isLoading: true,
            },
          },
        };
      }),
      on(
        this.actionSuccess,
        (state: IAsyncEntityState<ResponseData>, action: AsyncDataDictionaryAction<ResponseData>) => {
          return {
            ...state,
            [this.name]: {
              ...state[this.name],
              [action.payloadHash]: {
                data: action.payload,
                isLoading: false,
              },
            },
          };
        }
      ),
      on(this.actionError, (state: IAsyncEntityState<ResponseData>, action) => {
        return {
          ...state,
          [this.name]: {
            ...state[this.name],
            [action.payloadHash]: {
              ...state[this.name][action.payloadHash],
              isLoading: false,
            },
          },
        };
      }),
      on(
        this.actionCacheHit,
        (state: IAsyncEntityState<ResponseData>, action: AsyncDataDictionaryAction<ResponseData>) => {
          return {
            ...state,
            [this.name]: {
              ...state[this.name],
              [action.payloadHash]: {
                ...state[this.name][action.payloadHash],
                isLoading: false,
              },
            },
          };
        }
      ),
    ];
  }

  public createEffect(actions$: Actions, selectors: { [selectorName: string]: Observable<unknown> }) {
    return createEffect(() =>
      actions$.pipe(
        ofType(this.type),
        withLatestFrom(selectors.feature),
        mergeMap(
          ([action, state]: [
            AsyncDataDictionaryAction<ActionPayloadType> & Action,
            { [key: string]: { data: ResponseData; isLoading: boolean } }
          ]) => {
            if (this.options.doCache && this.keyAlreadyHasData(state?.[action.payloadHash])) {
              return of(
                this.actionCacheHit({ payloadHash: action.payloadHash, payload: state[action.payloadHash]?.data })
              );
            }
            return this.sideEffect(action.payload).pipe(
              map((data: ResponseData) => this.actionSuccess({ payloadHash: action.payloadHash, payload: data })),
              catchError((error: ErrorPayloadType) => {
                console.error(error);
                return of(this.actionError({ payloadHash: action.payloadHash, payload: error }));
              })
            );
          }
        )
      )
    );
  }

  private keyAlreadyHasData(state: { data: ResponseData; isLoading: boolean }): boolean {
    return state?.data !== undefined;
  }

  getKey(payload: Object): string {
    const fields = keys(payload).sort();
    const obj = fields.reduce((prev, key) => {
      prev[key] = payload[key];
      return prev;
    }, {});
    return JSON.stringify(obj);
  }
}

export interface AsyncEntityDataDictionaryOptions {
  /**
    When `true`, if the same data is fetched when a corresponding key is already defined, 
    then return the existing data and do not re-fetch.
   **/
  doCache?: boolean;
}
