import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DataTableUtilsService } from '@haulynx/services';
import {
  DataTableType,
  EntityType,
  IAsyncSearchResultsPaginationState,
  IColumns,
  IPagination,
  IRowSettings,
} from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { Record } from 'immutable';
import { filter, find, get, orderBy } from 'lodash';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { delay, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class DataTableComponent implements OnChanges, OnInit, OnDestroy, AfterViewInit {
  @Input() emptyMessage = 'No records found';
  @Input() newLoadfeed = false;
  @Input() checkBoxSelection = false;
  @Input() checkBoxSelectAll = false;
  @Input() checkBoxLastColumn = false;
  @Input() checkBoxAtPositionFromLast?: number;
  @Input() selection = false;
  @Input() list = false;
  @Input() set selected(ids: string[]) {
    if (this.multipleSelection) {
      this.selectionModel.select(...ids);
    } else {
      this.selectionModel.select(Array.isArray(ids) ? ids[0] : ids);
    }
  }
  @Input() data: DataTableType<EntityType> = [];
  @Input() loading = false;
  @Input() loadingFullCCover = false;
  @Input() cls = '';
  @Input() disable = false;
  @Input() title = null;
  @Input() expandedRow = null;
  @Input() lazyLoad = false;
  @Input() showPagination = true;
  @Input() pagination: IPagination;
  @Input() paginationData: IAsyncSearchResultsPaginationState;
  @Input() pageOptions = [10, 15, 25, 30, 50];
  @Input() displayedColumns: IColumns[] = [];
  @Input() hideColumns: IColumns[] = [];
  @Input() stickyHeader = false;
  @Input() expandedAll = false;
  @Input() rowSettings: IRowSettings;
  @Input() templateCellRef: TemplateRef<Record<EntityType>>;
  @Input() templateExpandedRef: TemplateRef<Record<EntityType>>;
  @Input() multipleSelection = false;
  @Input() sortActiveColumn: string;
  @Input() sortActiveDirection: SortDirection;
  @Input() clickableRow = false;
  @Input() orderBy: { active; direction } = null;
  @Input() smallLoader = false;
  @Input() resetData = false; //if selected was used, use this to reset the data back to data
  @Output() onSelect = new EventEmitter<{
    row: EntityType;
    selection?: EntityType | EntityType[];
    total?: number;
  }>();
  @Output() onViewItems = new EventEmitter();
  @Output() onPagination = new EventEmitter<IPagination>();
  @Output() onClick = new EventEmitter();
  @Output() onHoverRow = new EventEmitter<{ hover: boolean; id: string }>();
  @Output() onScroll = new EventEmitter<Event>();
  @Output() onContainerClick = new EventEmitter<Event>();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  alive = aliveWhile();

  expandedElement: EntityType;
  pageSizeOptions = [10, 15, 25, 30, 50];
  selectionModel = new SelectionModel<string>(true, []);
  dataSource = new MatTableDataSource<EntityType>([]);
  columns: string[] = [];
  hideColumnsMap = {};
  data$ = new BehaviorSubject<EntityType[]>([]);
  showView$ = new BehaviorSubject(false);

  constructor(private dataTableUtilsService: DataTableUtilsService) {}

  selectCheckBox(row: EntityType): void {
    this.selectionModel.toggle(row.id);

    const selected = {
      row,
      selection: this.getSelection(),
      total: this.getDataSize(),
    };

    this.onSelect.emit(selected);
  }

  select(row: EntityType, event: Event): void {
    this.onClick.emit(row);

    if (this.checkBoxSelection) {
      return;
    }

    if (this.selection) {
      this.selectionModel.toggle(row.id);

      this.onSelect.emit({ row, selection: this.getSelection(), total: this.getDataSize() });
    } else {
      this.onSelect.emit({ row });
    }
  }

  hoverRow(hover: boolean, id: string): void {
    this.onHoverRow.emit({ hover, id });
  }

  getDataSize(): number {
    return get(this.data, 'length', get(this.data, 'size', null));
  }

  isAllSelected(): boolean {
    return this.selectionModel.selected.length === this.dataSource.data.length;
  }

  masterToggle(): void {
    if (this.isAllSelected()) {
      this.selectionModel.clear();
    } else {
      this.dataSource.data.forEach((row) => this.selectionModel.select(row.id));
    }

    this.onSelect.emit({ row: null, selection: this.getSelection(), total: this.getDataSize() });
  }

  changeSort(sort: Sort): void {
    this.dataSource.data = this.dataTableUtilsService.sortData(
      this.data,
      sort.active,
      sort.direction as 'asc' | 'desc',
      this.displayedColumns
    );
  }

  sortData(data: DataTableType<EntityType>, active: string, direction: 'asc' | 'desc'): EntityType[] {
    const customSort = find<IColumns>(this.displayedColumns, { dataIndex: active });
    const convertData = this.dataTableUtilsService.getData(data);

    return orderBy(
      convertData,
      (row) => (customSort && customSort.sortConvert ? customSort.sortConvert(row, active) : row[active]),
      [direction]
    );
  }

  containerClick(event: Event): void {
    this.onContainerClick.emit(event);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const {
      checkBoxSelection,
      orderBy,
      data,
      displayedColumns,
      expandedRow,
      list,
      pageOptions,
      multipleSelection,
      lazyLoad,
      checkBoxSelectAll,
      hideColumns,
      paginationData,
    } = changes;

    if (paginationData?.currentValue && this.newLoadfeed) {
      this.paginator.length = paginationData.currentValue?.total;
      this.paginator.pageIndex = paginationData.currentValue?.currentPage - 1;
    }

    if (data && data.currentValue < data.previousValue && data.previousValue._tail) {
      const deleted = data.previousValue._tail.array.filter((value) => !data.currentValue._tail.array.includes(value));
      deleted.forEach((value: EntityType) => {
        this.selectionModel.deselect(value.id);
      });
    }

    if (checkBoxSelectAll) {
      this.selectionModel.clear();

      if (checkBoxSelectAll.currentValue) {
        this.dataSource.data.forEach((row) => this.selectionModel.select(row.id));
      }

      this.onSelect.emit({ row: null, selection: this.getSelection(), total: this.getDataSize() });
    }

    if (data) {
      const newData = this.dataTableUtilsService.orderBy(
        this.orderBy || this.sort,
        data.currentValue,
        this.displayedColumns
      );
      this.data$.next(newData);
    }

    if (displayedColumns) {
      const result = this.dataTableUtilsService.getDisplayColumns(displayedColumns.currentValue);

      this.displayedColumns = result.displayColumns;
      this.columns = result.columns;
      this.sortActiveColumn = result.sortActiveColumn;
      this.sortActiveDirection = result.sortActiveDirection;
    }

    if (hideColumns) {
      this.hideColumnsMap = this.dataTableUtilsService.getHideColumnsMap(hideColumns.currentValue);
    }

    if (checkBoxSelection) {
      const isSelect = find(this.columns, 'select');

      if (checkBoxSelection.currentValue && !isSelect) {
        if (this.checkBoxAtPositionFromLast || this.checkBoxAtPositionFromLast < this.columns.length) {
          const position = this.columns.length - this.checkBoxAtPositionFromLast;
          this.columns = [...this.columns.slice(0, position), 'select', ...this.columns.slice(position)];
        } else {
          this.columns = this.checkBoxLastColumn ? [...this.columns, 'select'] : ['select', ...this.columns];
        }
      }
    }

    if (expandedRow) {
      this.expandedElement = expandedRow.currentValue;
    }

    if (list && list.currentValue) {
      this.columns = ['list-style', ...this.columns];
    }

    if (pageOptions && pageOptions.currentValue) {
      this.pageSizeOptions = pageOptions.currentValue;
    }

    if (multipleSelection) {
      this.selectionModel = new SelectionModel(multipleSelection.currentValue, []);
    }

    if (lazyLoad && !lazyLoad.currentValue) {
      this.dataSource.paginator = this.paginator;
    }
    if (orderBy || this.orderBy) {
      const orderedData = this.dataTableUtilsService.orderBy(
        orderBy?.currentValue || this.orderBy,
        this.data,
        this.displayedColumns
      );

      if (orderedData !== this.data) {
        this.data$.next(orderedData);
      }
    }
  }

  trackByFn(index: number, item: { id?: string }): string | number {
    return (item && item.id) || index;
  }

  trackByItem(index: number, item: { id?: string }): string | number {
    return (item && item.id) || index;
  }

  getNumberOfRow(indexNumber: number): number {
    return this.dataTableUtilsService.getNumberOfRow(indexNumber, this.pagination, this.lazyLoad);
  }

  ngOnInit(): void {
    if (!this.lazyLoad && !this.newLoadfeed) {
      this.dataSource.paginator = this.paginator;
    }

    this.dataSource.sort = this.sort;
    this.paginator.page.pipe(takeUntil(this.alive)).subscribe((page) => {
      const pagination = {
        limit: page.pageSize,
        page: page.pageIndex + 1,
      };

      this.onPagination.emit(pagination);
    });

    combineLatest([this.showView$.pipe(delay(0)), this.data$])
      .pipe(takeUntil(this.alive))
      .subscribe(([showView, data]) => {
        if (showView) {
          this.dataSource.data = data;

          if (this.checkBoxSelectAll) {
            this.selectionModel.clear();
            this.dataSource.data.forEach((row) => this.selectionModel.select(row.id));
            this.onSelect.emit({ row: null, selection: this.getSelection(), total: this.getDataSize() });
          }
        } else {
          this.dataSource.data = [];
        }
      });

    this.dataSource
      .connect()
      .pipe(distinctUntilChanged(), takeUntil(this.alive))
      .subscribe((itemsOnPage) => {
        if (!this.loading) {
          this.onViewItems.emit(itemsOnPage);
        }
      });
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }

  ngAfterViewInit(): void {
    this.showView$.next(true);
  }

  private getSelection(): EntityType | EntityType[] {
    const selection = filter(this.data$.getValue(), (d) => this.selectionModel.selected.includes(d?.id));
    if (this.multipleSelection) {
      return selection;
    } else {
      return selection[0];
    }
  }
}
