export const loads = [
  {
    agreedToTerms: false,
    autoLiability: 0,
    broker: {
      credits: 99735,
      id: '-L6nYDJeuVzlKa6cTd9n',
      isHaulynxBroker: true,
      name: 'Test Broker Anthony',
      phone: '4807079049',
    },
    brokerAcceptedCarrier: true,
    broker_filter: '-L6nYDJeuVzlKa6cTd9n_active',
    cargoLiability: 0,
    carrier: {
      awareness: { awareness_score: 1.116393382424907, lastUpdated: '1562949451000' },
      dot: '2636501',
      equipmentType: ['Reefer', 'Other', 'Dry Van'],
      id: '-L6nXj0z1VHNdaGQ5Cpf',
      isEldCarrier: true,
      is_haulynx_user: true,
      name: 'F & M Transport LLC',
      phone: '480-707-9049',
      rank: -97,
      saferWatchData: {
        address: '913 EAST  UNION AVE, WAUSAU, WI, 54403',
        dateLastUpdated: '07-25-2019',
        hazmatCapable: false,
        outOfService: false,
        overallRating: 'Unacceptable',
        safetyRating: 'Not Rated',
      },
      surveyInfo: [],
      timeZone: 'America/Chicago',
    },
    carrierAccepted: true,
    carrierSafetyRating: 'None',
    carrier_filter: '-L6nXj0z1VHNdaGQ5Cpf_active',
    config: {
      demo: false,
      golden: false,
      includeSaferWatch: false,
      loadFee: 25,
      loadLogging: true,
      maxCarrierCount: 20,
      maximumDeadMiles: 200,
      mode: '0',
      timeToAcceptLoad: 10,
    },
    creator: 'ZwsFSJqg8Mh3iaza4DUEPADHPrz2',
    dateCreated: 1551728644339,
    distributionMechanism: 1,
    driver: {
      acceptedTos: true,
      awareness: {},
      canLoadMe: false,
      carrier: {
        awareness: { awareness_score: 1.116393382424907, lastUpdated: '1562949451000' },
        dot: '2636501',
        equipmentType: ['Reefer', 'Lowboy'],
        id: '-L6nXj0z1VHNdaGQ5Cpf',
        isEldCarrier: true,
        is_haulynx_user: true,
        name: 'F & M Transport LLC',
        phone: '480-707-9049',
        rank: -111,
        saferWatchData: {
          address: '1809 E Paradise Ln, Phoenix, AZ 85022',
          autoLiability: { limit: 0 },
          cargoLiability: { limit: 0 },
        },
        surveyInfo: [],
        timeZone: 'America/Phoenix',
      },
      dateCreated: 'Mon Mar 05 2018',
      disabled: false,
      eldState: { eldOn: false },
      email: 'anthonytestcarrier@haulynx.com',
      id: '3JBwL5WrwxXfIHC3TObPJ6CRuM82',
      isCompanyAdmin: true,
      isDriver: true,
      isElogAdmin: false,
      isHaulynxAdmin: false,
      isVerified: true,
      issuingState: 'AZ',
      lastLocation: {
        approximateAddress: 'Tempe Junction, AZ',
        city: 'Tempe Junction',
        date: '11/9/2018 11:59 AM',
        gpsLat: 33.4240532,
        gpsLon: -111.937874,
        state: 'AZ',
      },
      licenseNumber: 'D12345',
      mobileDeviceToken:
        'eTABujQ-Xzc:APA91bE5n6Tshjmrt-pn7xP87JmJX_jcw-KvsDacyhmdTSe9w8p4oF45C1K_PkhKnjnyk46mwiyyQFbr6nZ95M-KOMxBYfog_SxDrAKOCRpvhoar82UqfKm5p4fxzu7ERTuwQguOEfJY',
      name: 'Dave Ramirez',
      phone: '480-707-9049',
      prefs: {
        emailNotifications: true,
        mobileNotifications: true,
        smsNotifications: true,
        timeZone: 'America/Phoenix',
      },
      vehicle: {},
    },
    id: '-L_9E02moCJZXyCxQQbx',
    isPaid: false,
    loadLocations: [
      {
        billOfLading: '127863',
        creator: 'ZwsFSJqg8Mh3iaza4DUEPADHPrz2',
        equipmentType: 'Reefer',
        lat: 33.4240572,
        locationType: 'pickup',
        lon: -111.93888149999998,
        name: '21 E 6th St, Tempe, AZ 85281, USA',
        timestamp: 1551483000000,
        timezone: 'America/Phoenix',
      },
      {
        billOfLading: '127863',
        creator: 'ZwsFSJqg8Mh3iaza4DUEPADHPrz2',
        equipmentType: 'Reefer',
        lat: 27.5861438,
        locationType: 'dropoff',
        lon: -99.5019522,
        name: 'Las Cruces Dr, Laredo, TX 78045, USA',
        timestamp: 1551709800000,
        timezone: 'America/Chicago',
      },
    ],
    loadRejectedByAllCarriers: false,
    name: 'AZ to TX',
    price: 2000,
    status: 'active',
    templateId: '-LZpt6o2rqXHKUj4X20f',
    timeOffered: 1563275323,
    truck: {
      carrier: {
        awareness: { awareness_score: 1.116393382424907, lastUpdated: '1562949451000' },
        dot: '2636501',
        equipmentType: ['Reefer', 'Lowboy'],
        id: '-L6nXj0z1VHNdaGQ5Cpf',
        isEldCarrier: true,
        is_haulynx_user: true,
        name: 'F & M Transport LLC',
        phone: '480-707-9049',
        rank: -111,
        saferWatchData: {
          address: '1809 E Paradise Ln, Phoenix, AZ 85022',
          autoLiability: { limit: 0 },
          cargoLiability: { limit: 0 },
        },
        surveyInfo: [],
        timeZone: 'America/Phoenix',
      },
      id: 'phone_3JBwL5WrwxXfIHC3TObPJ6CRuM82',
      imei: 'phone_3JBwL5WrwxXfIHC3TObPJ6CRuM82',
      lastLocation: {
        approximateAddress: 'Tempe Junction, AZ',
        city: 'Tempe Junction',
        date: '02-03-2020 02:02 pm',
        driver: '3JBwL5WrwxXfIHC3TObPJ6CRuM82',
        driverName: 'Dave Ramirez',
        gpsLat: 33.4240532,
        gpsLon: -111.937874,
        state: 'AZ',
      },
      push_token: {},
      token:
        'epehvWOvVvk:APA91bGXvcFhcL3AlWEzaV5lLTK3Ggo6CRURLyCdLilwyHYzW7WE9s0foxhgo2aANy9QIFuQHqZOSzx7XUcTHWdw6HjVQGjWtTpBvryiTn75eR8IFm-IQGBzKm7uwcAEiFtO1C14E8vZ',
      type: 'phone',
      unitId: "Dave Ramirez's Phone",
    },
  },
];
