import { TruckTrackingTypeAvailability } from '../truck';
import { TrackingTypeAvailability } from './carrier';

export interface TruckTrackingEnabledInput {
  trackingTypes: TruckTrackingTypeAvailability[];
}

export interface TrackingEnabledInput {
  trackingTypes: TrackingTypeAvailability[];
}
