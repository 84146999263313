import { mapValues } from 'lodash';

export function makeTypedAction(ctor) {
  return function (type) {
    return function (payload?) {
      return new ctor(type, payload);
    };
  };
}

export function createActionTypes(actionTypes): any {
  return function (type) {
    return mapValues(actionTypes, (actionType) => `${type} ${actionType}`);
  };
}

export class DispatchAction<Payload = any> {
  _namespace: string;
  _name: string;

  payload?: Payload;

  set name(value) {
    this._name = value;
  }

  get name() {
    return this._name;
  }

  get type() {
    const name = this.name ? ` ${this.name}` : '';

    return `${this._namespace}${name}`;
  }

  constructor(type) {
    this._namespace = type;
  }
}

export function createActionClass(baseActionType = '') {
  return class extends DispatchAction {
    constructor(type, public payload?) {
      super(type);
      this.name = baseActionType;
    }
  };
}

export function createAction(baseType, type?) {
  const makeAction = type ? makeTypedAction(createActionClass(type)) : makeTypedAction(createActionClass());

  return makeAction(baseType);
}
