import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TrackingType } from '@haulynx/types';
import { forEach, keys } from 'lodash';

export const loadQuickDriverOptions: Pick<any, keyof LoadQuickDriverForm> = {
  trackingType: [null],
  driver: [null],
  phone: [null],
  truck: [null],
  trailerOwner: [null],
  trailer: [null],
  equipment: [null],
};

export class LoadQuickDriverForm {
  trackingType: string = null;
  driver: string = null;
  phone: string = null;
  truck: string = null;
  trailerOwner: string = null;
  trailer: string = null;
  equipment: string = null;

  constructor(options = {}) {
    const key = keys(this);
    const defaultValue = [null];

    forEach(key, (prop) => {
      this[prop] = (options && options[prop]) || defaultValue;
    });
  }
}

@Injectable()
export class LoadQuickDriverFormVm {
  constructor(private fb: FormBuilder) {}

  create(values: Partial<LoadQuickDriverForm> = {}): FormGroup {
    const driverForm = new LoadQuickDriverForm(loadQuickDriverOptions);
    const formGroup = this.fb.group(driverForm);

    if (values) {
      formGroup.patchValue(values);
    }

    return formGroup;
  }

  getTrackingValidation(trackingType: string, form: FormGroup): FormGroup {
    switch (trackingType) {
      case TrackingType.DRIVER_LITE:
        form.controls['driver'].setValidators([Validators.required]);
        form.controls['driver'].updateValueAndValidity();

        form.controls['truck'].setValidators([Validators.required]);
        form.controls['truck'].updateValueAndValidity();

        form.controls['phone'].setValidators([Validators.required]);
        form.controls['phone'].updateValueAndValidity();
        return form;
      case TrackingType.MACRO_POINT_ELD:
        form.controls['driver'].setValidators([Validators.required]);
        form.controls['driver'].updateValueAndValidity();

        form.controls['truck'].setValidators([Validators.required]);
        form.controls['truck'].updateValueAndValidity();

        form.controls['phone'].clearValidators();
        form.controls['phone'].updateValueAndValidity();
        return form;
      case TrackingType.PHONE:
        form.controls['driver'].setValidators([Validators.required]);
        form.controls['driver'].updateValueAndValidity();

        form.controls['truck'].setValidators([Validators.required]);
        form.controls['truck'].updateValueAndValidity();

        form.controls['phone'].clearValidators();
        form.controls['phone'].updateValueAndValidity();
        return form;
      case TrackingType.MACRO_POINT:
        form.controls['driver'].setValidators([Validators.required]);
        form.controls['driver'].updateValueAndValidity();

        form.controls['truck'].setValidators([Validators.required]);
        form.controls['truck'].updateValueAndValidity();

        form.controls['phone'].setValidators([Validators.required]);
        form.controls['phone'].updateValueAndValidity();
        return form;
      default:
        form.controls['driver'].clearValidators();
        form.controls['driver'].updateValueAndValidity();

        form.controls['truck'].clearValidators();
        form.controls['truck'].updateValueAndValidity();

        form.controls['phone'].clearValidators();
        form.controls['phone'].updateValueAndValidity();
        return form;
    }
  }
}
