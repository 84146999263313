import { Pipe, PipeTransform } from '@angular/core';
import { MomentService } from '@haulynx/services';
import { LoadsServiceLoadLocation } from '@haulynx/types';

@Pipe({
  name: 'loadLocationDate',
})
export class LoadLocationDatePipe implements PipeTransform {
  constructor(private momentService: MomentService) {}

  transform(location: LoadsServiceLoadLocation, format: string = 'D MMM YYYY h:mma'): string {
    const timestamp = this.getTimestamp(location);
    const { timezone = null } = location || {};

    return this.momentService.getDateFormat(timestamp, format, timezone);
  }

  private getTimestamp(location: LoadsServiceLoadLocation) {
    return (location as LoadsServiceLoadLocation).appointmentStart;
  }
}
