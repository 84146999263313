import gql from 'graphql-tag';

export const GetLastDeviceLocationImei = gql(`
  query GetLastDeviceLocationByImei($imei: StrictId!) {
    getLastDeviceLocationByImei(imei: $imei) {
      lon
      lat
      timestamp
    }
  }
`);
