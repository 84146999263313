import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { FireDatabaseService, LocalStoreService } from '@haulynx/services';
import { UserEntityService } from '@haulynx/store';
import { AccountRoute, Carrier, RmisRegistration, User } from '@haulynx/types';
import { aliveWhile, rmisAccountStatusComplete } from '@haulynx/utils';
import { differenceInHours } from 'date-fns';
import { Observable } from 'rxjs';
import { debounceTime, delay, filter, map, mergeMap, skip, take, takeUntil } from 'rxjs/operators';
import { SetupRmisDialogComponent } from '../dialogs/setup-rmis-dialog/setup-rmis-dialog.component';

enum RmisTokenValue {
  RMIS_COMPLETE = 'rmisComplete',
  RMIS_SETUP = 'rmisSetup',
}

@Component({
  selector: 'haulynx-rmis-account-setup-status',
  templateUrl: './rmis-account-setup-status.component.html',
  styleUrls: ['./rmis-account-setup-status.component.scss'],
  encapsulation: ViewEncapsulation.None, // disable ViewEncapsulation
})
export class RmisAccountSetupStatusComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
  public loadingContinue$: Observable<boolean>;
  public percentComplete = 0;
  public showAccountSetup = false;
  public registration: Observable<RmisRegistration> = this.userEntitityService.user$.pipe(
    map((user: User) => user?.carrier?.rmisRegistration || null)
  );

  private alive = aliveWhile();
  private carrierDot: string;
  private rmisComplete = false;
  private lastRmisSetupDialogShown: Date;

  constructor(
    private dialog: MatDialog,
    private firebaseDatabaseService: FireDatabaseService,
    private localStoreService: LocalStoreService,
    private mediaObserver: MediaObserver,
    private router: Router,
    private userEntitityService: UserEntityService
  ) {}

  ngOnInit(): void {
    this.loadingContinue$ = this.userEntitityService.rmisTokenManager.isLoading$;

    this.userEntitityService.user$
      .pipe(
        debounceTime(500),
        takeUntil(this.alive),
        map((user: User) => user?.carrier || null)
      )
      .subscribe(async (carrier: Carrier) => {
        if (!carrier) return;
        this.carrierDot = carrier.dot;
        this.rmisComplete = await this.localStoreService.get(`${this.carrierDot}-${RmisTokenValue.RMIS_COMPLETE}`);
        this.lastRmisSetupDialogShown = await this.localStoreService.get(
          `${this.carrierDot}-${RmisTokenValue.RMIS_SETUP}`
        );
        this.checkRmisComplete(carrier);
      });

    this.userEntitityService.user$
      .pipe(
        filter((user) => !!user?.carrier),
        take(1),
        mergeMap((user: User) => {
          return this.firebaseDatabaseService.getItem(`complianceUpdates/dots/${user.carrier.dot}`).pipe(
            skip(1), // Do not take the first emission since the page just loaded and has the correct information
            map((value) => ({ user, value })),
            takeUntil(this.alive)
          );
        })
      )
      .subscribe(({ user, value }) => {
        if (value) {
          this.userEntitityService.getUserByIdManager.dispatch(user.id);
        }
      });
  }

  ngAfterViewInit() {
    this.mediaObserver
      .asObservable()
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.menuTrigger?.closeMenu();
      });
  }

  ngOnDestroy() {
    this.alive.destroy();
  }

  public complete() {
    this.setRmisSetupDate(`${this.carrierDot}-${RmisTokenValue.RMIS_COMPLETE}`);
    this.showAccountSetup = false;
  }

  public closeMenu() {
    this.menuTrigger.closeMenu();
  }

  /**
   * Get the last time the RMIS setup dialog has been shown.
   * If the time it was last shown has been at least 24 hours ago, show it again.
   * @returns void
   */
  private checkRmisComplete(carrier: Carrier) {
    const registration = carrier?.rmisRegistration;
    if (!registration) return;

    if (!registration?.hasComplianceProcessedCarrier) {
      return (this.showAccountSetup = false);
    }

    if (!registration?.isRmisSetup) return (this.showAccountSetup = false);

    // If you switch carriers, this should show and such not based off these flags
    this.checkPercentComplete(registration);

    if (this.rmisComplete) {
      this.showAccountSetup = false;
      return;
    }

    this.showAccountSetup = true;

    if (!this.lastRmisSetupDialogShown) {
      // Setup dialog has never shown
      this.showSetupDialog(carrier);
      return;
    }

    const lastShownDialog = new Date(this.lastRmisSetupDialogShown);
    const difference = differenceInHours(new Date(), lastShownDialog);
    if (difference < 24) return;

    // Setup dialog has been shown at least 24 hours ago and needs to be shown again
    this.showSetupDialog(carrier);
  }

  public continueToRmis() {
    this.userEntitityService.rmisTokenManager.dispatch();

    this.userEntitityService.rmisTokenManager.onError$.pipe(take(1)).subscribe((error) => {
      this.router.navigate([AccountRoute.SORRY]);
    });

    this.userEntitityService.rmisTokenManager.onSuccess$.pipe(take(1)).subscribe((response: { url: string }) => {
      window.open(response.url, '_blank');
    });
  }

  /**
   * Set percentage complete based off of the RMIS registration flags
   * @param registration
   * @returns
   */
  private checkPercentComplete(registration: RmisRegistration) {
    if (!registration) {
      this.percentComplete = 0;
      return;
    }

    this.percentComplete = rmisAccountStatusComplete(registration);
  }

  private setRmisSetupDate(key: string): void {
    this.localStoreService.set(key, new Date().getTime());
  }

  private showSetupDialog(carrier: Carrier) {
    this.setRmisSetupDate(`${this.carrierDot}-${RmisTokenValue.RMIS_SETUP}`);

    const dialog = this.dialog.open(SetupRmisDialogComponent, {
      panelClass: 'account-dialogs',
      maxWidth: '480px',
      disableClose: true,
      data: { registration: carrier?.rmisRegistration },
    });

    dialog.afterClosed().subscribe((response: { redirectToRmis: boolean; completeSetup: boolean }) => {
      if (!response) return;

      if (response.completeSetup) {
        this.setRmisSetupDate(`${this.carrierDot}-${RmisTokenValue.RMIS_COMPLETE}`);
        return (this.showAccountSetup = false);
      }
      if (response.redirectToRmis) return this.continueToRmis();
    });
  }
}
