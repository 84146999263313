import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors } from '@angular/forms';
import { LoadFeedSearchForm, radiusSearchDefault } from '@haulynx/types';
import moment from 'moment';

// Todo: move in validator service
function dateDiff(controlName: string, diffDays: number): ValidationErrors {
  return (control: AbstractControl): { [key: string]: any } => {
    if (control && control.value) {
      const { begin, end } = control.value;
      const momentDeliveryDate = moment(end);
      const momentPickupDate = moment(begin);
      const days = momentDeliveryDate.diff(momentPickupDate, 'days') + 1;

      if (days < 0 || days > diffDays) {
        return {
          dateRange: true,
        };
      }
    }

    return null;
  };
}

@Injectable()
export class LoadFeedSearchFormVm {
  private timeBegin = moment().startOf('day');
  private timeEnd = moment().add(3, 'day').endOf('day');
  private baseFormOptions = {
    pickUpLocation: [],
    originRadius: [radiusSearchDefault],
    deliveryLocation: [],
    destinationRadius: [radiusSearchDefault],
    allowStops: [true],
    equipmentType: [],
    pickupDate: [
      {
        begin: this.timeBegin,
        end: this.timeEnd,
      },
      dateDiff('pickupDate', 7),
    ],
    deliveryDate: [
      {
        begin: null,
        end: null,
      },
    ],
    office: [null],
    shipper: [null],
    orderId: [null],
  };

  constructor(private fb: FormBuilder) {}

  create(values = {}): FormGroup {
    const loadFeedSearchForm = new LoadFeedSearchForm(this.baseFormOptions);
    const formGroup = this.fb.group(loadFeedSearchForm);

    if (values) {
      formGroup.patchValue(values);
    }

    return formGroup;
  }

  defaultValues() {
    return {
      pickupDate: {
        begin: this.timeBegin,
        end: this.timeEnd,
      },
      deliveryDate: {
        begin: null,
        end: null,
      },
      destinationRadius: radiusSearchDefault,
      originRadius: radiusSearchDefault,
    };
  }
}
