import { NgModule } from '@angular/core';
import { ApmModule, ApmService } from '@elastic/apm-rum-angular';
import { ErrorHandler } from '@angular/core';
import { ApmErrorHandler } from '@elastic/apm-rum-angular';
import { ElasticRumAgentService } from '@haulynx/services';
@NgModule({
  imports: [ApmModule],
  providers: [
    ApmService,
    ElasticRumAgentService,
    {
      provide: ErrorHandler,
      useClass: ApmErrorHandler,
    },
  ],
  exports: [],
})
export class ElasticApmModule {}
