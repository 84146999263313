import gql from 'graphql-tag';

export const GetLoadMatchesByCarrierDot = gql(`
  query GetLoadMatchesByCarrierDot($dot: String!, $radius: NonNegativeFloat, $paging: PageAndSort) {
    getTruckPostRecommendations(dot: $dot, radius: $radius, paging: $paging) {
      total
      result {
        id
        match
        price
        revenue
        orderNumber
        tmwNumber
        pickupTimezone
        dropoffTimezone
        lane
        loadCreated
        loadPickupAddress
        loadPickupTimestamp
        loadDropoffAddress
        loadDropoffTimestamp
        loadEquipmentType
        loadWeight
        loadFeedKey
        loadDistance
        loadPickupLat
        loadPickupLon
        loadDropoffLat
        loadDropoffLon
        truckId
        truckIds
        loadsServiceLoad {
          id
          loadStatus
          created
          createdBy
          lastUpdatedAt
          bookStatus
          region
          distributionMechanism
          carrier {
            name
            dot
            owner
          }
          broker {
            name
            id
          }
          assignedBrokers {
            id
            name
            usxId
          }
          truck {
            id
            unitId
          }
          trackingType
          provider {
            id
            name
            detailType
            company
          }
          providerDetails {
            billTo
            billToName
            cxPriority
            equipmentType
            commodity
            weight: weightLbs
            quantity
            operationsPriority
            orderType
            brokerTeamId
            alternateIds {
              identifierType
              identifierValue
            }
          }
          paymentDetails {
            price
            revenue
            maxBuy
            ratePerMile
            distanceMiles
          }
          restrictions {
            type
            value
          }
          locations {
            id
            address
            city
            state
            zipcode
            timezone
            appointmentSet
            appointmentStart
            appointmentEnd
            locationType
            billOfLading
            geometry {
              type
              coordinates
            }
            distanceMiles
            customer {
              name
            }
            carrierArrival
            carrierDeparture
            summaryAddress
          }
          trailers {
            id
            trailerNumber
          }
          drivers {
            id
            name
            phone
          }
          bidDetails {
            offerCount
            lowestOffer
            lowestOfferCarrierName
          }
          consignee
          shipperName
        }
      }
    }
  }
`);
