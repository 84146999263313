import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import {
  LoadsServiceLoad,
  Milestone,
  MilestoneLog,
  MilestoneSubtype,
  MilestoneType,
  MilestoneUser,
} from '@haulynx/types';
import { formatDistance } from 'date-fns';

@Component({
  selector: 'haulynx-milestone-sub-item-user',
  templateUrl: './milestone-sub-item-user.component.html',
  styleUrls: ['./milestone-sub-item-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MilestoneSubItemUserComponent implements OnInit {
  @Input() user: string;
  @Input() milestone: Milestone;
  @Input() load: LoadsServiceLoad;

  ngOnInit(): void {}

  constructor() {}

  get getCarrierBrokerEntry(): MilestoneLog {
    let entry: MilestoneLog;
    switch (this.user) {
      case 'broker': {
        entry = this.milestone.logs.find((index) => index.editedByType === MilestoneUser.BROKER);
        break;
      }
      case 'carrier': {
        entry = this.milestone.logs.find((index) => index.editedByType === MilestoneUser.CARRIER);
        break;
      }
      case 'system': {
        entry = this.milestone.logs.find((index) => index.editedByType === MilestoneUser.SYSTEM);
        if (!entry) {
          entry = this.milestone.logs.find((index) => index.editedByType === MilestoneUser.BROKER);
        }
        break;
      }
    }
    return entry;
  }

  get generateTimeInFacility(): string {
    return formatDistance(
      this.getCarrierBrokerEntry.primaryEvent.timestamp,
      this.getCarrierBrokerEntry.secondaryEvent.timestamp,
      { includeSeconds: false }
    );
  }

  get getBackgroundColor(): string {
    let color = '';
    switch (this.user) {
      case 'broker': {
        color = '#FFFFFF';
        break;
      }
      case 'carrier': {
        color = '#AFB6CC';
        break;
      }
    }
    return color;
  }

  get displayTimeIn(): boolean {
    if (this.milestone.type === MilestoneType.UPDATE) {
      return false;
    } else if (this.getCarrierBrokerEntry.primaryEvent?.timestamp) {
      return true;
    } else {
      return false;
    }
  }

  get displayTimeOut(): boolean {
    if (this.milestone.type === MilestoneType.UPDATE) {
      return false;
    } else if (this.getCarrierBrokerEntry.secondaryEvent?.timestamp) {
      return true;
    } else {
      return false;
    }
  }

  get diplayTimeInTitle(): string {
    if (
      this.milestone.type === MilestoneType.CHECKPOINT &&
      !this.milestone.logs.find((log) => log.subType === MilestoneSubtype.DISPATCH)
    ) {
      return 'Facility Entry:';
    } else {
      return 'Dispatch Empty:';
    }
  }

  get formatSubType(): string {
    return this.getCarrierBrokerEntry?.subType ?? 'system';
  }

  get formatTimeZone(): string {
    const locationTZ = this.load.locations.find((loc) => loc.id === this.milestone.locationId)?.timezone;
    if (locationTZ) {
      return locationTZ;
    } else {
      const eventTZ = this.milestone.logs.find((log) => log.editedByType === MilestoneUser.BROKER)?.primaryEvent
        .timezone;
      if (eventTZ) {
        return eventTZ;
      }
    }
    return '';
  }
}
