import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import {
  LoadsServiceLoad,
  LoadsServiceLoadLocation,
  Milestone,
  MilestoneLog,
  MilestoneType,
  MilestoneUser,
  User,
  AssetBridgeTrailerTelemetryHistory,
  KeyValuePair,
} from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoadsServiceService } from '@haulynx/services';

export enum MilestoneSubItemType {
  COMMENT = 'comment',
  BROKER_INFO = 'broker_info',
  CARRIER_INFO = 'carrier_info',
  SYSTEM = 'system',
}

@Component({
  selector: 'haulynx-milestone-sub-item',
  templateUrl: './milestone-sub-item.component.html',
  styleUrls: ['./milestone-sub-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MilestoneSubItemComponent {
  @Input() user: User;
  @Input() currentUserType: MilestoneUser;
  @Input() subItemType: MilestoneSubItemType;
  @Input() milestone: Milestone;
  @Input() milestoneIndex: number;
  @Input() itemTitle: string;
  @Input() load: LoadsServiceLoad;
  @Input() log: MilestoneLog;

  @Output() commentAction: EventEmitter<{ action: string; commentIndex: number }> = new EventEmitter();
  @Input() set telemetryHistory(telemetryHistory: AssetBridgeTrailerTelemetryHistory) {
    if (telemetryHistory) {
      this._telemetryHistory = telemetryHistory;
      this.loadsService
        ?.reverseGeocodeViaMapbox({
          lon: telemetryHistory.longitude,
          lat: telemetryHistory.latitude,
        })
        .subscribe((result) => {
          this.locationFromLatLong.next(result);
        });
    }
  }
  get telemetryHistory() {
    return this._telemetryHistory;
  }
  @Input() collapseMilestone: { action: string; milestoneId?: string };
  @Input() savedCollapseMilestone: KeyValuePair[] = [];
  isCommentOpen = false;
  isBrokerOpen = false;
  isCarrierOpen = false;
  isSystemOpen = false;
  alive = aliveWhile();
  openComment$ = new BehaviorSubject<boolean>(false);
  openBroker$ = new BehaviorSubject<boolean>(false);
  openCarrier$ = new BehaviorSubject<boolean>(false);
  openSystem$ = new BehaviorSubject<boolean>(false);
  locationFromLatLong = new BehaviorSubject<string>('');
  private _telemetryHistory: AssetBridgeTrailerTelemetryHistory;

  constructor(private loadsService: LoadsServiceService) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { collapseMilestone } = changes;

    if (collapseMilestone) {
      const collapseValue = <{ action: string; milestoneId?: string }>collapseMilestone.currentValue;
      this.collapseOrExpandSubItems(collapseValue.action, collapseValue.milestoneId);
    }
  }

  ngOnInit(): void {
    this.checkSavedCollapsedSubItems();
  }

  private collapseOrExpandSubItems(action: string, milestoneId?: string): void {
    if (action === 'expand-all' || (action === 'expand-individual' && this.milestone.id === milestoneId)) {
      this.isCommentOpen = true;
      this.isBrokerOpen = true;
      this.isCarrierOpen = true;
      this.isSystemOpen = true;
      this.openComment$.next(true);
      this.openBroker$.next(true);
      this.openCarrier$.next(true);
      this.openSystem$.next(true);
    } else if (action === 'collapse-all' || (action === 'expand-individual' && this.milestone.id !== milestoneId)) {
      this.isCommentOpen = false;
      this.isBrokerOpen = false;
      this.isCarrierOpen = false;
      this.isSystemOpen = false;
      this.openComment$.next(false);
      this.openBroker$.next(false);
      this.openCarrier$.next(false);
      this.openSystem$.next(false);
    } else if (action === 'expand-broker') {
      this.isCommentOpen = true;
      this.isBrokerOpen = true;
      this.isCarrierOpen = false;
      this.isSystemOpen = false;
      this.openComment$.next(true);
      this.openBroker$.next(true);
      this.openCarrier$.next(false);
      this.openSystem$.next(false);
    }
  }

  private checkSavedCollapsedSubItems(): void {
    this.savedCollapseMilestone.forEach((milestone) => {
      if (milestone.key === this.milestone.id) {
        const collapseArr = milestone.value as KeyValuePair[];
        collapseArr.forEach((collapse) => {
          if (collapse.key === 'BROKER' && collapse.value) {
            this.isBrokerOpen = true;
            this.openBroker$.next(true);
          } else if (collapse.key === 'CARRIER' && collapse.value) {
            this.isCarrierOpen = true;
            this.openCarrier$.next(true);
          } else if (collapse.key === 'SYSTEM' && collapse.value) {
            this.isSystemOpen = true;
            this.openSystem$.next(true);
          } else if (collapse.key === 'COMMENT' && collapse.value) {
            this.isCommentOpen = true;
            this.openComment$.next(true);
          }
        });
      }
    });
  }

  openOrCloseComment(): void {
    this.isCommentOpen = !this.isCommentOpen;
    if (this.isCommentOpen) {
      this.openComment$.next(true);
    } else {
      this.openComment$.next(false);
    }

    this.savedCollapseMilestone.forEach((milestone) => {
      if (milestone.key === this.milestone.id) {
        const collapseArr = milestone.value as KeyValuePair[];
        collapseArr.forEach((collapse) => {
          if (collapse.key === 'COMMENT') {
            collapse.value = this.isCommentOpen;
          }
        });
      }
    });
  }

  openOrCloseBroker(): void {
    this.isBrokerOpen = !this.isBrokerOpen;
    if (this.isBrokerOpen) {
      this.openBroker$.next(true);
    } else {
      this.openBroker$.next(false);
    }

    this.savedCollapseMilestone.forEach((milestone) => {
      if (milestone.key === this.milestone.id) {
        const collapseArr = milestone.value as KeyValuePair[];
        collapseArr.forEach((collapse) => {
          if (collapse.key === 'BROKER') {
            collapse.value = this.isBrokerOpen;
          }
        });
      }
    });
  }

  openOrCloseCarrier(): void {
    this.isCarrierOpen = !this.isCarrierOpen;
    if (this.isCarrierOpen) {
      this.openCarrier$.next(true);
    } else {
      this.openCarrier$.next(false);
    }
    this.savedCollapseMilestone.forEach((milestone) => {
      if (milestone.key === this.milestone.id) {
        const collapseArr = milestone.value as KeyValuePair[];
        collapseArr.forEach((collapse) => {
          if (collapse.key === 'CARRIER') {
            collapse.value = this.isCarrierOpen;
          }
        });
      }
    });
  }

  openOrCloseSystem(): void {
    this.isSystemOpen = !this.isSystemOpen;
    if (this.isSystemOpen) {
      this.openSystem$.next(true);
    } else {
      this.openSystem$.next(false);
    }
    this.savedCollapseMilestone.forEach((milestone) => {
      if (milestone.key === this.milestone.id) {
        const collapseArr = milestone.value as KeyValuePair[];
        collapseArr.forEach((collapse) => {
          if (collapse.key === 'SYSTEM') {
            collapse.value = this.isSystemOpen;
          }
        });
      }
    });
  }

  get displaySubItemSystem() {
    let display = false;
    if (this.telemetryHistory) {
      display = true;
    }

    if (this.log?.editedByType === MilestoneUser.SYSTEM) {
      display = true;
    }
    return display;
  }

  get displaySubItemCarrier(): boolean {
    let display = false;
    if (this.milestone.authorType === MilestoneUser.CARRIER) {
      display = true;
    }

    for (let i = 0; i < this.milestone.logs.length; i++) {
      if (this.milestone.logs[i].editedByType === MilestoneUser.CARRIER) {
        display = true;
      }
    }
    return display;
  }

  get displaySubItemBroker(): boolean {
    let display = false;
    if (this.milestone.authorType === MilestoneUser.BROKER) {
      display = true;
    }

    for (let i = 0; i < this.milestone.logs.length; i++) {
      if (this.milestone.logs[i].editedByType === MilestoneUser.BROKER) {
        display = true;
      }
    }
    return display;
  }

  commentActions(event: { action: string; commentIndex: number }): void {
    this.commentAction.emit({ action: event.action, commentIndex: event.commentIndex });
  }

  get countComments(): number {
    return this.milestone.comments.length;
  }

  get telemetryHistoryTimeStamp(): number {
    return new Date(this.telemetryHistory.telemetryPingDateTime).getTime();
  }

  get displayBrokerDelete(): boolean {
    if (this.milestone.logs.length <= 1) {
      return false;
    } else {
      const entry: MilestoneLog = this.milestone.logs.find((index) => index.editedByType === MilestoneUser.BROKER);
      return this.user.usxId === entry.editedBy;
    }
  }

  get displayBrokerEdit(): boolean {
    return this.user.broker ? true : false;
  }

  get displayCarrierEdit(): boolean {
    const entry: MilestoneLog = this.milestone.logs.find((index) => index.editedByType === MilestoneUser.CARRIER);
    return this.user.usxId === entry.editedBy;
  }

  get getCarrierMilestoneLog(): MilestoneLog {
    return this.milestone.logs.find((index) => index.editedByType === MilestoneUser.CARRIER);
  }

  get getBrokerMilestoneLog(): MilestoneLog {
    return this.milestone.logs.find((index) => index.editedByType === MilestoneUser.BROKER);
  }

  get getItemType(): string {
    const location: LoadsServiceLoadLocation = this.load.locations.find(
      (location) => location.id === this.milestone.locationId
    );
    if (this.milestone.type === MilestoneType.LOCATION) {
      return '';
    } else if (location) {
      return location.locationType.toUpperCase();
    } else return '';
  }
}
