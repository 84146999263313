import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ElasticApmModule } from '@haulynx/containers';
import { AdminGuard, AuthGuard, CompanyAdminGuard } from '@haulynx/guards';
import { IconsService } from '@haulynx/icons';
import { ApiInterceptorsService } from '@haulynx/interceptors';
import { CustomBreakpointsModule, MaterialModule } from '@haulynx/modules';
import { EnvironmentService, LocalStoreService } from '@haulynx/services';
import { AppStoreModule, UserEntityService } from '@haulynx/store';
import { Environment } from '@haulynx/types';
import { Store } from '@ngrx/store';
import { DEFAULT_CONFIG, Driver, NgForageOptions } from 'ngforage';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { appInitializeFactory } from './app-initializer.factory';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    CustomBreakpointsModule,
    AppStoreModule,
    HttpClientModule,
    ElasticApmModule,
    ToastrModule.forRoot(),
    MaterialModule,
    AgmCoreModule.forRoot({
      apiKey: environment.google.mapsApi,
      libraries: ['places'],
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptorsService,
      multi: true,
    },
    {
      provide: DEFAULT_CONFIG,
      useValue: {
        name: 'haulynx',
        driver: [Driver.INDEXED_DB, Driver.LOCAL_STORAGE],
      } as NgForageOptions,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializeFactory,
      multi: true,
      deps: [IconsService, LocalStoreService, Store, EnvironmentService, UserEntityService],
    },
    {
      provide: Environment,
      useValue: environment,
    },
    AuthGuard,
    AdminGuard,
    CompanyAdminGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
