import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormGroup, ValidationErrors } from '@angular/forms';
import { ValidationErrorPipe } from '@haulynx/pipes';
import { LoadQuickLocationForm, LoadQuickLocationFormVm } from '@haulynx/services';
import { LoadLocation, LocationForm, Mins } from '@haulynx/types';
import { get } from 'lodash';

@Component({
  selector: 'app-load-quick-location',
  templateUrl: './load-quick-location-form.component.html',
  styleUrls: ['./load-quick-location-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadQuickLocationFormComponent implements OnChanges {
  @Input() isLoading = false;
  @Input() bookedLoad = true;
  @Input() location: LoadLocation;
  @Input() lastStep?;
  @Input() formData: LocationForm;
  @Output() saveLocation: EventEmitter<Partial<LocationForm>> = new EventEmitter<Partial<LocationForm>>();

  form: FormGroup;
  entry: number;
  exit: number;
  timezone: string;
  now: number;
  lastYear: number;

  minimumDates: Mins = {
    entry: null,
    exit: null,
  };

  constructor(
    private loadQuickLocationFormVm: LoadQuickLocationFormVm,
    private validationErrorPipe: ValidationErrorPipe
  ) {
    this.now = new Date().getTime();
    const temp = new Date();
    this.lastYear = temp.setMonth(temp.getMonth() - 11);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setupDates(this.location.arrivalTime, this.location['departureTime']);
    this.form = this.loadQuickLocationFormVm.create(
      { arrivalTime: this.entry, departureTime: this.exit },
      get(this.location, 'timezone', null),
      this.minimumDates
    );
    const { formData } = changes;

    if (formData && formData.currentValue) {
      const {
        specialNotes,
        departureTime,
        locationType,
        arrivalTime,
        timeZone,
      } = formData.currentValue as LocationForm;

      this.setupDates(+arrivalTime, +departureTime);

      this.form.patchValue({
        specialNotes,
        locationType: locationType,
        timeZone: timeZone,
        arrivalTime: this.entry,
        departureTime: this.exit,
      });
    }
  }

  getMinEntryDate(): number {
    return !this.lastStep ? this.lastYear : this.lastStep?.departureTime;
  }

  getMaxEntryDate(): number {
    return this.location['departureTime'] ? this.location['departureTime'] : this.now;
  }

  customEntranceError(): ValidationErrors {
    const temp: ValidationErrors = this.form.controls['arrivalTime'].errors;
    if (this.validationErrorPipe.transform(temp) === 'required') {
      return null;
    }
    return temp;
  }

  customExitError(): ValidationErrors {
    if (this.form.errors) {
      return this.form.errors;
    }
    const temp: ValidationErrors = this.form.controls['departureTime'].errors;
    if (this.validationErrorPipe.transform(temp) === 'required') {
      return null;
    }
    return temp;
  }

  save(event: Event, control: string, formData: LoadQuickLocationForm): void {
    event.stopPropagation();
    let newFormData: Partial<LocationForm> = null;

    if (control === 'departureTime' && !this.form.controls['departureTime'].errors) {
      newFormData = {
        departureTime: formData.departureTime,
      };
    }

    if (control === 'arrivalTime' && !this.form.controls['arrivalTime'].errors) {
      newFormData = {
        arrivalTime: formData.arrivalTime,
      };
    }

    if (newFormData) {
      this.saveLocation.emit(newFormData);
    }
  }

  private setupDates(arr: number, dep: number) {
    if (this.location) {
      this.timezone = this.location.timezone;
    }
    if (arr && dep) {
      this.entry = arr;
      this.exit = dep;
    } else if (arr && !dep) {
      this.entry = arr;
      const ent = new Date(this.entry);
      this.exit = ent.setHours(ent.getHours() + 1);
      if (this.exit > this.now) {
        this.exit = this.entry;
      }
    } else {
      const ent = new Date();
      this.exit = ent.getTime();
      this.entry = ent.setHours(ent.getHours() - 1);
    }
  }
}
