import { AsyncManagerType, IAsyncEntityState } from '@haulynx/types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { createAction, on, props, ReducerTypes } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { QueryAction } from '../types/query-action';
import { AsyncEntityActionFactory } from './async-entity-action-factory';

export class AsyncEntityQueryFactory<
  ActionPayloadType,
  ResponseData,
  ErrorPayloadType
> extends AsyncEntityActionFactory<ActionPayloadType, ResponseData, ErrorPayloadType> {
  public managerType = AsyncManagerType.QUERY;
  public action = createAction(this.type, props<{ entityId: string; payload?: ActionPayloadType }>());
  public actionSuccess = createAction(this.typeSuccess, props<{ entityId: string; payload: ResponseData }>());
  public actionError = createAction(this.typeError, props<{ entityId: string; payload: ErrorPayloadType }>());

  constructor(namespace: string, name: string, effectCall: (payload: ActionPayloadType) => Observable<ResponseData>) {
    super(namespace, name, effectCall);
  }

  public getReducers(): ReducerTypes<IAsyncEntityState<ResponseData>, any>[] {
    return [
      on(
        this.action,
        (state: IAsyncEntityState<ResponseData>, action: { entityId: string; payload?: ActionPayloadType }) => {
          return {
            ...state,
            isLoadingEntities: { ...state.isLoadingEntities, [action.entityId]: true },
          };
        }
      ),
      on(
        this.actionSuccess,
        (state: IAsyncEntityState<ResponseData>, action: { entityId: string; payload: ResponseData }) => {
          return {
            ...state,
            entities: { ...state.entities, [action.entityId]: action.payload },
            isLoadingEntities: { ...state.isLoadingEntities, [action.entityId]: false },
          };
        }
      ),
      on(
        this.actionError,
        (state: IAsyncEntityState<ResponseData>, action: { entityId: string; payload: ErrorPayloadType }) => {
          return {
            ...state,
            isLoadingEntities: { ...state.isLoadingEntities, [action.entityId]: false },
          };
        }
      ),
    ];
  }

  public createEffect(actions$: Actions) {
    return createEffect(() =>
      actions$.pipe(
        ofType(this.type),
        switchMap((action: QueryAction<ActionPayloadType>) =>
          this.sideEffect(action.entityId).pipe(
            map((data: ResponseData) => this.actionSuccess({ entityId: action.entityId, payload: data })),
            catchError((error: ErrorPayloadType) => {
              console.error(error);
              return of(this.actionError({ entityId: action.entityId, payload: error }));
            })
          )
        )
      )
    );
  }
}
