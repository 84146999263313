<app-under-construction></app-under-construction>

<div class="carrier-management">
  <div class="carrier-management__item">
    <h3 class="carrier-management__title">Top relationship</h3>
    <app-carrier-management-table
      [isLoading]="carrierManagementModel.topIsLoading$ | async"
      [data]="carrierManagementModel.topEntities$ | async"
      [stickyHeader]="true"
      (delete)="onDelete($event)"
      (change)="onSetContacted($event)"
    >
    </app-carrier-management-table>
  </div>

  <div class="carrier-management__item">
    <h3 class="carrier-management__title">Developing Relationship</h3>
    <app-carrier-management-table
      [isLoading]="carrierManagementModel.developingIsLoading$ | async"
      [data]="carrierManagementModel.developingEntities$ | async"
      [stickyHeader]="true"
      (delete)="onDelete($event)"
      (change)="onSetContacted($event)"
    ></app-carrier-management-table>
  </div>

  <div class="carrier-management__item">
    <h3 class="carrier-management__title">Prospects</h3>
    <app-carrier-management-table
      [isLoading]="carrierManagementModel.prospectsIsLoading$ | async"
      [data]="carrierManagementModel.prospectsEntities$ | async"
      [relationType]="relationTypes.PROSPECTS"
      [stickyHeader]="true"
      (delete)="onDelete($event)"
      (change)="onSetContacted($event)"
    ></app-carrier-management-table>
  </div>
</div>
