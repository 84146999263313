<div class="row">
  <div class="col-lg-12">
    <h2 matDialogTitle>Bulk Upload Imei List</h2>
    <mat-dialog-content>
      <div class="" *ngIf="imeis?.length; else showImportMessage">
        <p>Are you sure you want to upload all {{ imeis?.length }} records?</p>
      </div>
      <ng-template #showImportMessage>
        <div class="">
          <p>{{ importMessage }}</p>
        </div>
      </ng-template>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-raised-button color="primary" (click)="confirm()" *ngIf="imeis?.length">Upload</button>
      <button mat-raised-button [matDialogClose]="">Cancel</button>
    </mat-dialog-actions>
  </div>
</div>
