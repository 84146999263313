import gql from 'graphql-tag';

export const AcceptBidMutation = gql`
  mutation AcceptBid($bidId: StrictId!) {
    acceptBid(bidId: $bidId) {
      id
      brokerId
      price
      status
      carrier {
        id
        dot
      }
      carrierId
      loadId
      notes
      createdAt
      createdBy {
        id
        name
      }
      updatedAt
      updatedBy {
        id
        name
      }
    }
  }
`;
