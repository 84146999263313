import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { OrderInfoVmService } from '@haulynx/services';

import { OrderInfoForm } from '@haulynx/types';

@Component({
  selector: 'app-order-info-table',
  templateUrl: './order-info-table.component.html',
  styleUrls: ['./order-info-table.component.scss'],
})
export class OrderInfoTableComponent implements OnChanges, OnInit {
  @Input() brokers: { id: string; label: string }[] = [];
  @Input() formData: OrderInfoForm = null;
  @Input() form: FormGroup;
  @Output() dataChanged = new EventEmitter<OrderInfoForm>();

  constructor(private orderInfoVmService: OrderInfoVmService) {}

  ngOnChanges(changes: SimpleChanges) {
    const { formData } = changes;

    if (formData) {
      const currentFormData = formData.currentValue;
      this.form.patchValue(currentFormData);
    }
  }

  change(formData: OrderInfoForm) {
    this.dataChanged.emit(formData);
  }

  ngOnInit(): void {
    if (!this.form) {
      this.form = this.orderInfoVmService.create();
    }
  }
}
