import { Pipe, PipeTransform } from '@angular/core';
import { get } from 'lodash';

import { MissionForm } from '@haulynx/types';
import { I } from '@angular/cdk/keycodes';
import { findReason } from '@haulynx/utils';

@Pipe({ name: 'missionValidation' })
export class MissionValidationPipe implements PipeTransform {
  reasonToString = {
    invalid: 'Invalid order number',
    complete: 'Order already completed',
    part: 'Part of mission already',
  };

  transform(mission: MissionForm): string {
    let headHaulReason, backHaulReason;

    if (!mission.headHaulValidation?.valid) {
      headHaulReason = findReason(mission.headHaulValidation.reason);
    }
    if (!mission.backHaulValidation?.valid) {
      backHaulReason = findReason(mission.backHaulValidation.reason);
    }

    let finalReason = 'Error: ';

    if (headHaulReason === backHaulReason && !backHaulReason) {
      finalReason = '';
    } else if (headHaulReason === backHaulReason) {
      finalReason += 'Headhaul and Backhaul - ' + this.reasonToString[headHaulReason];
    } else {
      if (headHaulReason) {
        finalReason += 'Headhaul - ' + this.reasonToString[headHaulReason];
      }
      if (backHaulReason) {
        if (headHaulReason) finalReason += '\n';
        finalReason += 'Backhaul - ' + this.reasonToString[backHaulReason];
      }
    }

    return finalReason;
  }
}
