import gql from 'graphql-tag';

export const AssignAssetBridgeTrailerToLoadByLoadId = gql`
  mutation AssignAssetBridgeTrailerToLoadByLoadId(
    $trailerNumber: StrictId!
    $loadId: StrictId!
    $customerNumber: StrictId
    $customerCompany: NonBlankString
  ) {
    assignAssetBridgeTrailerToLoadByLoadId(
      trailerNumber: $trailerNumber
      loadId: $loadId
      customerNumber: $customerNumber
      customerCompany: $customerCompany
    )
  }
`;
