<form [formGroup]="dateForm">
  <mat-form-field appearance="outline">
    <mat-label>Select Date Range</mat-label>
    <sat-datepicker #picker [rangeMode]="true" [selectFirstDateOnClose]="true"></sat-datepicker>
    <input
      matInput
      formControlName="date"
      [placeholder]="placeholder"
      [satDatepicker]="picker"
      (focus)="picker.open()"
      [min]="min"
      [max]="max"
      (dateChange)="onInputDateChange($event)"
    />
    <sat-datepicker-toggle matSuffix [for]="picker">
      <mat-icon class="calendar-icon" matDatepickerToggleIcon svgIcon="haulynx-calendar"> </mat-icon>
    </sat-datepicker-toggle>
  </mat-form-field>
</form>
