import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CarrierLoadMapService } from '@haulynx/services';
import { MapboxRoute } from '@haulynx/types';
import { Observable } from 'rxjs';

@Component({
  selector: 'haulynx-carrier-mapbox',
  templateUrl: './carrier-mapbox.component.html',
  styleUrls: ['./carrier-mapbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarrierMapboxComponent implements OnInit {
  @Input() loading: boolean;
  @Input() zoom = 2;
  @Input() maxZoom = 10;
  @Input() padding = 30;
  @Input() mapId = 'mapId';
  @Input() mapHeight = 165;
  @Input() mapboxRoute: MapboxRoute[] = [];

  public mapboxTheme$: Observable<string>;

  constructor(private carrierLoadMapService: CarrierLoadMapService) {
    this.mapboxTheme$ = this.carrierLoadMapService.colorTheme();
  }

  ngOnInit(): void {}
}
