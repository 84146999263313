<div class="bounce-carrier-dialog">
  <h2 matDialogTitle>Bounce Carrier</h2>

  <mat-dialog-content class="content" data-test="bounce-message">
    Are you sure you want to remove {{ data.carrierName }} from this active load?
  </mat-dialog-content>

  <mat-dialog-actions class="button-row">
    <button mat-button class="cancel" data-test="cancel-button" [matDialogClose]>CANCEL</button>
    <button mat-button class="confirm" data-test="confirm-button" [matDialogClose] (click)="confirm()">CONFIRM</button>
  </mat-dialog-actions>
</div>
