import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FeatureFlagDashboardDialogComponent } from '@haulynx/components';
import { ConfirmationComponent, FeatureFlagActionService } from '@haulynx/services';
import { FeatureFlagDashboardModel } from '@haulynx/store';
import { IColumns } from '@haulynx/types';

@Component({
  selector: 'app-feature-flag-dashboard',
  templateUrl: './feature-flag-dashboard.component.html',
  styleUrls: ['./feature-flag-dashboard.component.scss'],
})
export class FeatureFlagDashboardComponent implements OnInit {
  columns: IColumns[] = [];

  constructor(
    private dialog: MatDialog,
    public featureFlagDashboardModel: FeatureFlagDashboardModel,
    private featureFlagDashboardService: FeatureFlagActionService
  ) {}

  ngOnInit(): void {
    this.columns = this.initColumns();
    this.featureFlagDashboardModel.findAll();
  }

  initColumns(): IColumns[] {
    const columns: IColumns[] = [];

    columns.push({
      label: 'Name',
      dataIndex: 'name',
      initiallySortedBy: 'asc',
    });

    columns.push({
      label: 'Environments',
      dataIndex: 'environments',
      isCustomCell: true,
      sortConvert: this.featureFlagDashboardService.customSortFeatures.bind(this.featureFlagDashboardService),
    });

    columns.push({
      label: 'Platforms',
      dataIndex: 'platforms',
      isCustomCell: true,
      sortConvert: this.featureFlagDashboardService.customSortFeatures.bind(this.featureFlagDashboardService),
    });

    columns.push({
      label: 'Description',
      dataIndex: 'description',
    });

    columns.push({
      label: '',
      dataIndex: 'options',
      isCustomCell: true,
      isSortable: false,
      width: '50px',
    });

    return columns;
  }

  onFeatureFlagForm(featureFlagName: string = null): void {
    this.dialog.open(FeatureFlagDashboardDialogComponent, {
      data: { featureFlagName },
      width: '800px',
    });
  }

  onDeleteFeatureFlag(featureFlagName: string): void {
    this.dialog
      .open(ConfirmationComponent, {
        data: {
          title: 'Delete Feature',
          message: `Are you sure you want to delete the "${featureFlagName}" feature flag?`,
          featureFlagName,
        },
        width: '380px',
      })
      .afterClosed()
      .subscribe((isConfirmed: boolean) => {
        if (isConfirmed) {
          this.featureFlagDashboardModel.delete({ featureFlagName });
        }
      });
  }
}
