import { ActionReducer, ActionReducerMap } from '@ngrx/store';
import { InjectionToken, Provider } from '@angular/core';
import { GeocodingEntityService } from './geocoding-entity.service';
import { USER_PROVIDED_EFFECTS } from '@ngrx/effects';
import { IAsyncEntityState, PlaceInfo } from '@haulynx/types';

export const GEOCODING_REDUCERS_TOKEN = new InjectionToken<ActionReducerMap<IAsyncEntityState<PlaceInfo>>>(
  'geocoding reducers'
);

function getGeocodingReducersFactory(
  geocodingEntityService: GeocodingEntityService
): ActionReducer<IAsyncEntityState<PlaceInfo>> {
  return geocodingEntityService.getReducers();
}

const geocodingEntityReducerProvider: Provider = {
  provide: GEOCODING_REDUCERS_TOKEN,
  useFactory: getGeocodingReducersFactory,
  deps: [GeocodingEntityService],
};

const geocodingEntityEffectProvider: Provider = {
  provide: USER_PROVIDED_EFFECTS,
  multi: true,
  useValue: [GeocodingEntityService],
};

export const geocodingStateProviders: Provider[] = [geocodingEntityReducerProvider, geocodingEntityEffectProvider];
