<div class="load-price">
  <ng-container *ngIf="!(isLoading$ | async); else showLoading">
    <div (click)="setPrice($event)">
      <ng-container *ngIf="price; else noPrice">
        {{ price | showCurrency: 'USD':'symbol':(showCents ? null : '1.2-2') }}
      </ng-container>
      <ng-template #noPrice>{{ noValueText }}</ng-template>
      <mat-icon svgIcon="haulynx-pen" *ngIf="editablePrice" [attr.data-test]="'edit-price-icon'"></mat-icon>
    </div>
  </ng-container>
  <ng-template #showLoading>
    <div class="load-price-spinner">
      <app-spinner [active]="true" [transparentBackground]="true" [diameter]="32"></app-spinner>
    </div>
  </ng-template>
</div>
