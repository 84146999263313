import { Injectable } from '@angular/core';
import { DateForm, SelectionStrategy } from '@haulynx/types';
import { isInvalidDate } from '@haulynx/utils';
import { isAfter, isBefore, isValid } from 'date-fns';
import { format, toDate, utcToZonedTime } from 'date-fns-tz';
import { padStart, toString } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class SingleModeService implements SelectionStrategy {
  isValidDate(formVal: DateForm, timeZone: string): boolean {
    return isValid(this.formToZonedDate(formVal, timeZone));
  }

  formToZonedDate(dateVal: DateForm, timeZone: string): Date {
    try {
      const month = padStart(toString(dateVal.month), 2, '0');
      const day = padStart(toString(dateVal.day), 2, '0');
      const hours = padStart(toString(dateVal.hours), 2, '0');
      const mins = padStart(toString(dateVal.mins), 2, '0');

      return toDate(`${dateVal.year}-${month}-${day}T${hours}:${mins}:00`, { timeZone });
    } catch {
      return null;
    }
  }

  isInvalidDate(dateVal: number | Date): boolean {
    return isInvalidDate(dateVal);
  }

  normalizeDate(dateVal: Date): number {
    return dateVal.valueOf();
  }

  normalizeTimepickerValue(value: number, timeZone: string): Partial<DateForm> {
    const zonedTime = utcToZonedTime(value || 0, timeZone);

    const month = padStart(toString(zonedTime.getMonth() + 1), 2, '0');
    const day = padStart(toString(zonedTime.getDate()), 2, '0');
    const year = zonedTime.getFullYear().toString();
    const hours = padStart(toString(zonedTime.getHours()), 2, '0');
    const mins = padStart(toString(zonedTime.getMinutes()), 2, '0');
    const monthRange = null;
    const dayRange = null;
    const yearRange = null;

    return { month, day, year, hours, mins, monthRange, dayRange, yearRange };
  }

  isMinMaxValid(formValue: DateForm, timeZone: string, min: Date, max: Date): Record<string, boolean> {
    const formDate = this.formToZonedDate(formValue, timeZone);

    if (!!min && isBefore(formDate, min)) {
      const formattedMinDate = format(min, 'MM/dd/yyyy', { timeZone });
      return { [`Date must be after ${formattedMinDate}`]: true };
    }

    if (!!max && isAfter(formDate, max)) {
      const formattedMaxDate = format(max, 'MM/dd/yyyy', { timeZone });
      return { [`Date must be before ${formattedMaxDate}`]: true };
    }

    return null;
  }
}
