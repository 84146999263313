import { startCase } from 'lodash';
import { LoadsServiceLoadStatus } from '../../../loads-service/loads-service-load';
import { transformLoadStatusText } from '../../load/transform-load-status-text';
import { ISearchFilter, TextFilterType } from '../search-filter';
import { ISearchFilterType } from '../search-filter.types';

export class LoadStatusFilter implements ISearchFilter {
  name = 'Load Status';
  type: ISearchFilterType = ISearchFilterType.MULTI_DROPDOWN;
  keys: TextFilterType;
  description = 'Filter loads by the current load status';
  transformValue = (values: LoadsServiceLoadStatus[] | LoadsServiceLoadStatus) => {
    return Array.isArray(values)
      ? values?.map(transformLoadStatusText)?.map(startCase).join(', ')
      : startCase(transformLoadStatusText(values as LoadsServiceLoadStatus));
  };
  clearValue() {
    this.keys.value = [];
  }

  constructor(defaultValues?: TextFilterType) {
    const options = Object.values(LoadsServiceLoadStatus)
      .filter((status) => status !== LoadsServiceLoadStatus.DELETED) // Do not include deleted in the filter values
      .map((status) => {
        return {
          key: startCase(transformLoadStatusText(status)),
          value: status,
        };
      });
    this.keys = new TextFilterType({
      value: '',
      optionsDataIndex: options,
      textFormName: 'loadStatus',
      ...defaultValues,
    });
  }
}
