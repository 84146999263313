import { Bid, CounterOffer } from '@haulynx/types';
import { Pipe, PipeTransform } from '@angular/core';
import { get, reduce } from 'lodash';

@Pipe({ name: 'totalMessages' })
export class TotalMessagesPipe implements PipeTransform {
  transform(bid: Bid): number {
    const counterOffers: CounterOffer[] = get(bid, 'counterOffers', []);
    return reduce(
      counterOffers,
      (prev, curr) => {
        if (curr.notes) {
          return prev + 1;
        } else {
          return prev;
        }
      },
      0
    );
  }
}
