import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'haulynx-contact-support',
  templateUrl: './contact-support-dialog.component.html',
  styleUrls: ['./contact-support-dialog.component.scss'],
})
export class ContactSupportDialogComponent {
  constructor(private dialogRef: MatDialogRef<ContactSupportDialogComponent>) {}

  public close() {
    this.dialogRef.close();
  }
}
