export class Announcement {
  date: string;
  content: string;
  target: string;
}

export class DashboardSummary {
  currentActiveLoads: number;
  currentPendingLoads: number;
  revenueThisMonth: string;
  revenueLastMonth: string;
  amountOwed: string;
  announcements: Announcement[];
}
