import { MapsAPILoader } from '@agm/core';
import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { LocalStoreService, MetaService, MomentService } from '@haulynx/services';
import { AddressPosition, splitAddress } from '@haulynx/utils';
import { get, trim } from 'lodash';

@Component({
  selector: 'app-dot-signup-set-lane',
  templateUrl: './dot-signup-set-lane.component.html',
  styleUrls: ['./dot-signup-set-lane.component.scss'],
})
export class DotSignupSetLaneComponent implements OnDestroy {
  public setLaneForm = new FormGroup({
    origin: new FormControl(null, [Validators.required]),
    destination: new FormControl(null, [Validators.required]),
  });
  public submitting = false;

  private dot: string;

  constructor(
    private localStoreService: LocalStoreService,
    private mapsApiLoader: MapsAPILoader,
    private metaService: MetaService,
    private momentService: MomentService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {
    this.metaService.setMobileViewport();

    this.localStoreService.get('dotSignup').then((data) => {
      const carrierInformation = data;
      if (!carrierInformation) {
        this.router.navigateByUrl('home');
      }
      this.dot = get(carrierInformation, 'dot', null);
      this.setDomicile(carrierInformation);
    });
  }

  ngOnDestroy(): void {
    this.metaService.removeMobileViewport();
  }

  public setLanes(): void {
    // Parse origin and destination for state
    this.submitting = true;
    const value = this.setLaneForm.value;
    const origin = get(value, 'origin.address', null);
    const destination = get(value, 'destination.address', null);
    const originCity = trim(splitAddress(origin, AddressPosition.CITY));
    const originState = trim(splitAddress(origin, AddressPosition.STATE));
    const destinationCity = trim(splitAddress(destination, AddressPosition.CITY));
    const destinationState = trim(splitAddress(destination, AddressPosition.STATE));

    if (originState === 'No Info' || destinationState === 'No Info') {
      const location = originState === 'No Info' ? 'origin' : destinationState === 'No Info' ? 'destination' : '';
      this.snackBar.open(`Please enter a valid city for your ${location}`, null, { duration: 3000 });
      this.submitting = false;
      return;
    }

    // this.neptuneService
    //   .createCarrierLane(this.dot, { originCity, originState, destinationCity, destinationState })
    //   .subscribe(
    //     async () => {
    //       this.localStoreService.set('dotLanes', this.setLaneForm.value);
    //       this.localStoreService.clear('dotSignup');
    //       await this.router.navigateByUrl('dashboard/loads/search/all');
    //       this.submitting = false;
    //     },
    //     (error) => {
    //       this.snackBar.open(error, null, { duration: 3000 });
    //       this.submitting = false;
    //     }
    //   );
  }

  public switchLocations(): void {
    const origin = this.setLaneForm.get('origin').value;
    const destination = this.setLaneForm.get('destination').value;

    this.setLaneForm.patchValue({
      origin: destination,
      destination: origin,
    });
  }

  /**
   * If dot signup was successful and returned information on a carriers domicile,
   * prefill the destination with a google places result
   * @param carrierInformation Carrier Information which came from
   */
  private setDomicile(carrierInformation: { domicileCity?: string; domicileState?: string }) {
    const domicileCity = get(carrierInformation, 'domicileCity', null);
    const domicileState = get(carrierInformation, 'domicileState', null);

    if (domicileCity && domicileState) {
      const input = document.createElement('input');
      this.mapsApiLoader.load().then(() => {
        const placesService = new google.maps.places.PlacesService(input);
        placesService.textSearch({ query: `${domicileCity}, ${domicileState}, USA` }, (result) => {
          if (result.length) {
            const place = result[0];
            if (!place.geometry) {
              return;
            }

            const lat = place.geometry.location.lat();
            const lon = place.geometry.location.lng();
            const address = trim(place.formatted_address);
            const timeZone = this.momentService.getTimeZoneByLocation(lat, lon);

            this.setLaneForm.patchValue({
              destination: { lat, lon, address, timeZone },
            });
          }
        });
      });
    }
  }
}
