import { createAction, createActionTypes } from '../../shared/store/helpers/action';
import { combineAdaptersActionsState, combineAdaptersActionTypeState } from '../../shared/store/helpers/adapter';
import { ISearchActions, ISearchTypes } from '../../shared/store/search/actions';
import { favoriteAddressAdapter, TYPE } from './adapters';

interface IFavoriteAddressActions extends ISearchActions {
  createAddress(address?);

  createAddressSuccess(params?);

  createAddressError(err?);
}

interface IFavoriteAddressActionType extends ISearchTypes {
  CREATE_ADDRESS: string;
  CREATE_ADDRESS_SUCCESS: string;
  CREATE_ADDRESS_ERROR: string;
}

export const favoriteAddressActionType = {
  CREATE_ADDRESS: 'create address',
  CREATE_ADDRESS_SUCCESS: 'create address success',
  CREATE_ADDRESS_ERROR: 'create address error',
};

export const userActions = {
  createAddress: createAction(TYPE, favoriteAddressActionType.CREATE_ADDRESS),
  createAddressSuccess: createAction(TYPE, favoriteAddressActionType.CREATE_ADDRESS_SUCCESS),
  createAddressError: createAction(TYPE, favoriteAddressActionType.CREATE_ADDRESS_ERROR),
};

export const FavoriteAddressActions: IFavoriteAddressActions = {
  ...combineAdaptersActionsState(favoriteAddressAdapter),
  ...userActions,
};
export const FavoriteAddressActionTypes: IFavoriteAddressActionType = {
  ...combineAdaptersActionTypeState(favoriteAddressAdapter),
  ...createActionTypes(favoriteAddressActionType)(TYPE),
};
