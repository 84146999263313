import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AuthService,
  GoogleAnalyticsService,
  GoRmisCarrierInfo,
  LocalStoreService,
  MetaService,
  OnboardingService,
  RmisService,
  SaferWatchData,
  SaferwatchService,
} from '@haulynx/services';
import { AppModel } from '@haulynx/store';
import { Action, Category, DotCheckReponse, Environment, UtmParams } from '@haulynx/types';
import { matchingPasswords } from '@haulynx/utils';
import { get, isEmpty } from 'lodash';
import { BehaviorSubject, combineLatest, of, Subject } from 'rxjs';
import { catchError, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-dot-signup-create-account',
  templateUrl: './dot-signup-create-account.component.html',
  styleUrls: ['./dot-signup-create-account.component.scss'],
})
export class DotSignupCreateAccountComponent implements OnDestroy {
  @Input() fromDialog = false;
  @Output() signedUp = new EventEmitter<boolean>();

  public carrierInfo: Partial<GoRmisCarrierInfo> = {};
  public carrierNotFound = false;
  public signupForm: FormGroup;
  public submitting = false;
  public $loading = new BehaviorSubject<boolean>(true);

  private dotStatus = 0;
  private dotMessage = '';
  private utmParams: UtmParams;

  constructor(
    private appModel: AppModel,
    private authService: AuthService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private googleAnalyticsService: GoogleAnalyticsService,
    private localStoreService: LocalStoreService,
    private metaService: MetaService,
    private onboardingService: OnboardingService,
    private rmisService: RmisService,
    private router: Router,
    private saferWatchService: SaferwatchService,
    private snackBar: MatSnackBar,
    private environment: Environment
  ) {
    this.metaService.setMobileViewport();

    this.signupForm = this.fb.group(
      {
        email: ['', [Validators.required]],
        phone: ['', [Validators.required]],
        password: ['', [Validators.required, Validators.minLength(5)]],
        passwordConfirm: ['', [Validators.required]],
      },
      {
        validator: matchingPasswords('password', 'passwordConfirm'),
      }
    );

    this.route.queryParams.pipe(take(1)).subscribe((params) => {
      const { utm_source = 'organic', utm_medium = null, utm_campaign = null } = params;
      this.utmParams = { utm_source, utm_medium, utm_campaign };
      const dot = params['dot'];

      if (!dot) {
        this.googleAnalyticsService.eventEmitter(Category.DOT_SIGNUP, Action.DOT_NOT_PROVIDED);
        this.router.navigateByUrl('login');
        return;
      }

      combineLatest([
        this.onboardingService.checkCarrierByDot(dot).pipe(catchError(() => of(null))),
        this.rmisService.getRmisDataFromDot(dot).pipe(catchError(() => of(null))),
        this.saferWatchService.getCarrierSaferwatch(dot, 'carrier signup check').pipe(catchError(() => of(null))),
      ]).subscribe(
        ([checkDotResponse, goRmisResponse, saferWatchResponse]: [
          DotCheckReponse,
          GoRmisCarrierInfo,
          SaferWatchData
        ]) => {
          this.dotStatus = saferWatchResponse.status;
          this.dotMessage = saferWatchResponse.message;

          if (checkDotResponse.haulynxCarrier) {
            this.googleAnalyticsService.eventEmitter(Category.DOT_SIGNUP, Action.ALREADY_EXISTS);
            this.snackBar.open('Please log in with your Haulynx account!', null, { duration: 5000 });
            this.router.navigateByUrl('login');
            this.signedUp.emit(false);
          }

          if (goRmisResponse) {
            // Data exists in goRmis
            this.googleAnalyticsService.eventEmitter(Category.DOT_SIGNUP, Action.USE_GO_RMIS);
            this.carrierInfo = goRmisResponse;
            this.signupForm.patchValue({
              email: this.carrierInfo.email,
              phone: this.carrierInfo.phoneNumber,
            });
          } else if (checkDotResponse.saferwatchData) {
            // Data does not exist in goRmis but does exist in saferwatch
            this.googleAnalyticsService.eventEmitter(Category.DOT_SIGNUP, Action.USE_SAFER_WATCH);
            const saferWatchData = checkDotResponse.saferwatchData;
            this.carrierInfo.dotNumber = !isEmpty(saferWatchData.dotNumber)
              ? get(saferWatchData, 'dotNumber.$t', null)
              : null;
            this.carrierInfo.mcNumber = !isEmpty(saferWatchData.docketNumber)
              ? get(saferWatchData, 'docketNumber', null)
              : null;
            const carrierIdentity = get(saferWatchData, 'Identity', null);
            if (carrierIdentity) {
              this.carrierInfo.carrierName = get(carrierIdentity, 'legalName', null);
              this.carrierInfo.domicileAddress = get(carrierIdentity, 'businessStreet', null);
              this.carrierInfo.domicileCity = get(carrierIdentity, 'businessCity', null);
              this.carrierInfo.domicileState = get(carrierIdentity, 'businessState', null);
              const email = !isEmpty(saferWatchData.emailAddress) ? get(saferWatchData, 'emailAddress', '') : '';
              this.signupForm.patchValue({
                phone: get(carrierIdentity, 'businessPhone', ''),
                email,
              });
            }
          } else {
            // Cannot find any information from either goRmis or saferWatch
            this.googleAnalyticsService.eventEmitter(Category.DOT_SIGNUP, Action.USE_NONE);
            this.carrierNotFound = true;
          }

          this.$loading.next(false);
        }
      );
    });
  }

  ngOnDestroy(): void {
    this.metaService.removeMobileViewport();
  }

  public signup(): void {
    const form = this.signupForm.value;

    this.submitting = true;
    this.authService
      .signUp(
        {
          accountType: 'carrier administrator',
          company: {
            addressCity: this.carrierInfo.domicileCity,
            addressState: this.carrierInfo.domicileState,
            addressStreet: this.carrierInfo.domicileAddress,
            name: this.carrierInfo.carrierName,
            phone: form.phone,
          },
          dot: this.carrierInfo.dotNumber,
          dotStatus: this.dotStatus,
          dotStatusMessage: this.dotMessage,
          email: form.email,
          issuingCountry: '',
          issuingState: '',
          licenseNumber: '',
          name: 'Admin User',
          password: form.password,
          pcEnabled: false,
          phone: form.phone,
          teamDrivingEnabled: false,
          ymEnabled: false,
        },
        {
          Source: this.utmParams.utm_source,
          Medium: this.utmParams.utm_medium,
          Campaign: this.utmParams.utm_campaign,
          EnableCarrierBidding: this.environment.envName,
        }
      )
      .subscribe(
        () => {
          this.googleAnalyticsService.eventEmitter(
            Category.DOT_SIGNUP,
            Action.SIGN_UP_SUCCESS,
            this.carrierInfo.dotNumber.toString()
          );

          // We only want to set the storage value if coming from sign up
          if (!this.signedUp.observers.length) {
            this.localStoreService.set('dotSignup', {
              dot: this.carrierInfo.dotNumber,
              domicileCity: this.carrierInfo.domicileCity,
              domicileState: this.carrierInfo.domicileState,
            });
          }

          this.appModel.login(form.email, form.password);
          const waiting = new Subject();
          this.localStoreService.changes$.pipe(takeUntil(waiting)).subscribe((changes) => {
            const { key = null } = changes || {};

            if (key === 'token') {
              this.submitting = false;
              waiting.next();
              waiting.complete();

              // If a component is watching for event emission, do not navigate and let the component take an action
              if (this.signedUp.observers.length) {
                return this.signedUp.emit(true);
              }

              this.router.navigateByUrl('dotSignup/setLane');
            }
          });
        },
        (error) => {
          this.googleAnalyticsService.eventEmitter(Category.DOT_SIGNUP, Action.SIGN_UP_ERROR, error);
          this.submitting = false;
          this.snackBar.open(error, null, { duration: 3000 });
        }
      );
  }
}
