import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  AdvancedSearchComponent,
  AssignLoadCarrierContainerComponent,
  SaveSearchViewComponent,
} from '@haulynx/components';
import {
  AdvancedSearchService,
  CarrierToolbarService,
  FeatureFlagService,
  GeocodingService,
  LocalStoreService,
  TitleService,
  WindowRef,
} from '@haulynx/services';
import {
  ActiveLoadsModel,
  AppModel,
  CarrierLoadSearchEntityService,
  LoadEntityService,
  LoadPriceModel,
  SearchViewEntityService,
  TrailerEntityService,
  UserEntityService,
} from '@haulynx/store';
import {
  AssignLoadCarrierData,
  BidDetails,
  BookStatus,
  BulkActionPage,
  BulkEditLoadsInput,
  CarrierToolbarLocations,
  ColumnField,
  dataColumns,
  defaultSidebarItems,
  FeatureFlag,
  FFState,
  getParentSidebarItem,
  HttpStatus,
  IColumns2,
  ISearchFilter,
  ISortingConfig,
  LatLonInput,
  LoadActionEvent,
  LoadBulkAction,
  LoadBulkActionPayload,
  loadConfigOptions,
  LoadSearchBarResult,
  LoadSearchSideBarItem,
  loadServiceSearchFilters,
  LoadServiceSearchParamData,
  LoadServiceSearchParameters,
  LoadsServiceLoad,
  LoadsServiceLoadStatus,
  LoadsServiceOrderByType,
  LoadsServiceOrderByTypeDirection,
  LoadsServiceRestrictionTypes,
  MilestoneLog,
  NearestCity,
  PageAndSort,
  RowSelectionEvent,
  SaveSearchViewDto,
  SearchView,
  SidebarItem,
  TrailerOwnerType,
  User,
} from '@haulynx/types';
import { aliveWhile, convertDatesToDeltas } from '@haulynx/utils';
import { formatDistance } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { camelCase, cloneDeep, flatMap, has, isNull, keys, omit, omitBy, orderBy, reduce } from 'lodash';
import { BehaviorSubject, combineLatest, forkJoin, merge, Observable } from 'rxjs';
import { first, map, takeUntil, tap, withLatestFrom, delay } from 'rxjs/operators';
import { CreateMissionComponent } from '../../create-mission/create-mission.component';

@Component({
  selector: 'app-load-search-container',
  templateUrl: './load-search-container.component.html',
  styleUrls: ['./load-search-container.component.scss'],
})
export class LoadSearchContainerComponent implements OnInit, OnDestroy {
  @ViewChild('stickyColumnHeader', { static: false }) stickyColumnHeader: TemplateRef<unknown>;
  @ViewChild(AdvancedSearchComponent) advancedSearch: AdvancedSearchComponent;
  loadsServiceRestrictionTypes = LoadsServiceRestrictionTypes;
  bidDetails: BidDetails;

  alive = aliveWhile();
  BookStatus = BookStatus;
  LoadsServiceLoadStatus = LoadsServiceLoadStatus;
  filters: ISearchFilter[];
  isSidebarOpen = true;
  sidebarItems$: Observable<LoadSearchSideBarItem[]>;
  searchBarFilters$ = new BehaviorSubject<ISearchFilter[]>([]);
  searchQuery$: Observable<{
    queryHash: string;
    payload: Partial<LoadServiceSearchParameters>;
    pageAndSort: Partial<PageAndSort>;
  }>;
  columns: IColumns2[] = [];

  searchViewFilters$ = new BehaviorSubject<ISearchFilter[]>([]);
  selectedSidebarItem: LoadSearchSideBarItem;
  refreshSelectedSidebarItem = true;
  selectedSidebarItemParent: LoadSearchSideBarItem;
  shouldResetTextArrayForm: boolean;
  searchForm: FormGroup;
  columnsToShow;
  isSearchView: boolean;
  currentSearchData$: Observable<LoadServiceSearchParameters>;
  dataLoading$: Observable<boolean>;
  tableData$: BehaviorSubject<LoadsServiceLoad[]> = new BehaviorSubject([]);
  searchQuery: Partial<LoadServiceSearchParameters>;
  searchPage: Partial<PageAndSort>;
  rowActionsDisplay: boolean[] = [];
  stickyColumnWidth = '100px';
  user: User;
  configOptions = loadConfigOptions;
  features: FFState;
  loadLockFlag = FeatureFlag.LOAD_LOCKING;
  loadLockState$: Observable<{ [loadId: string]: LoadActionEvent }>;
  private editedPriceData: { loadId: string; maxBuy?: number; price: number };
  trailerLatLongs: LatLonInput[] = [];
  loadsNearestCities: { [key: string]: string } = {};
  loadsTrailerNearestCities: { [key: string]: any } = {};
  isLoadingEntities$: Observable<boolean>;
  selectedRows$ = new BehaviorSubject<LoadsServiceLoad[]>([]);
  searchViews: SearchView[];
  selectedRowsHighlight$ = new BehaviorSubject<{ entityType: LoadsServiceLoad; highlightState: boolean }[]>(null);
  bidsColumn = false;
  lastTrailerLocationColumn = false;
  loadIds = [];
  loads = [];
  allTrailerCallsMade = false;

  get priceData() {
    return this.editedPriceData;
  }
  /**
   * The search payload from choosing a link from the sidebar
   */
  sidebarVal: LoadServiceSearchParameters;
  /**
   * The search payload from the search bar
   */
  private searchbarVal: LoadServiceSearchParamData;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private advancedSearchService: AdvancedSearchService,
    public loadEntityService: LoadEntityService,
    private dialog: MatDialog,
    private appModel: AppModel,
    private activeLoadsModel: ActiveLoadsModel,
    private localStoreService: LocalStoreService,
    public searchViewEntityService: SearchViewEntityService,
    private loadPriceModel: LoadPriceModel,
    private titleService: TitleService,
    private windowRef: WindowRef,
    private featureFlagService: FeatureFlagService,
    private carrierToolbarService: CarrierToolbarService,
    public geocodingService: GeocodingService,
    private userEntityService: UserEntityService,
    private carrierLoadSearchEntityService: CarrierLoadSearchEntityService,
    private trailerEntityService: TrailerEntityService
  ) {
    this.filters = loadServiceSearchFilters({ brokers: this.activeLoadsModel.brokersEntities$ });
    this.searchQuery$ = this.loadEntityService.getUSXLoadsManager.searchQuery$;
    this.trailerEntityService.searchTrailerHistoryByTrailerInfo?.onSuccess$
      .pipe(takeUntil(this.alive))
      .subscribe((telemetryHistory) => {
        this.trailerLatLongs.push({
          lat: telemetryHistory.telemetryHistory[0].latitude,
          lon: telemetryHistory.telemetryHistory[0].longitude,
        });
        if (this.allTrailerCallsMade) {
          if (this.trailerLatLongs.length > 0) {
            this.geocodingService
              .getNearestCities(this.trailerLatLongs)
              .pipe(takeUntil(this.alive))
              .subscribe((nearestCities: NearestCity[]) => {
                nearestCities.forEach((nearestCity) => {
                  this.loadsTrailerNearestCities[
                    telemetryHistory.trailer.trailerCompany + ':' + telemetryHistory.trailer.trailerNumber
                  ] = {
                    location: nearestCity.city + ', ' + nearestCity.state,
                    timestamp: new Date(telemetryHistory.telemetryHistory[0].telemetryPingDateTime).getTime(),
                  };
                });
              });
          }
        }
      });
    this.currentSearchData$ = this.loadEntityService.getUSXLoadsManager.searchQuery$.pipe(
      map((searchQuery) => searchQuery.payload)
    );
    this.dataLoading$ = combineLatest([
      this.loadEntityService.getUSXLoadsManager.isSearching$,
      this.searchViewEntityService.getSearchViewByUserIdManager.isSearching$,
      this.searchViewEntityService.deleteSearchViewManager.isLoading$,
      this.searchViewEntityService.createSearchViewManager.isLoading$,
      this.searchViewEntityService.deleteSearchViewManager.isLoading$,
      this.searchViewEntityService.getSearchViewByUserIdManager.isSearching$,
      this.searchViewEntityService.bulkUpdateSearchViewsManager.isLoading$,
    ]).pipe(map((values) => values.some(Boolean)));
    this.loadEntityService.getUSXLoadsManager.searchResults$
      .pipe(
        takeUntil(this.alive),
        tap((searchResults) => this.loadEntityService.lockState.next(searchResults.map((load) => load.id) as string[])),
        withLatestFrom(this.carrierToolbarService.selectedLoads$)
      )
      .subscribe(([loads, toolbarLoads]) => {
        this.tableData$.next(loads);

        const selectedLoads =
          toolbarLoads?.reduce((prev, curr) => {
            const matchedLoad = loads.find((load) => load.id === curr.id);
            if (matchedLoad) {
              prev.push(matchedLoad);
            }
            return prev;
          }, []) ?? [];
        this.selectedRows$.next(selectedLoads);
        if (loads.length > 0) {
          this.loadIds = loads
            .map((load) => {
              return load.id;
            })
            .filter((load) => load != null);

          this.loads = loads;
          this.getTrailerTelemetryData(loads);
          this.generateNearestCities(loads);
        }
      });
    this.titleService.setTitle(`Load Board - Haulynx`);
    this.carrierLoadSearchEntityService.deleteMissionManager.onSuccess$.pipe(takeUntil(this.alive)).subscribe(() => {
      this.loadEntityService.getUSXLoadsManager.dispatch({
        query: { isInMission: true, showExcluded: true, showTestLoads: true, fullMission: false },
        pageAndSort: this.searchPage,
      });
    });
  }

  ngOnInit(): void {
    this.userEntityService.featureFlags$.pipe(takeUntil(this.alive)).subscribe((feature: FFState) => {
      this.features = feature;
    });
    forkJoin([
      this.route.queryParams.pipe(first()),
      this.loadEntityService.getUSXLoadsManager.searchQuery$.pipe(first()),
    ])
      .pipe(takeUntil(this.alive))
      .subscribe(([routeParams, currSearchQuery]) => {
        const payload = this.chooseInitialSearchQuery(routeParams, currSearchQuery) || { query: {} };
        // initialize searchParms & sidebarVal
        const { searchFilters, searchParams } = this.advancedSearchService.convertSearchPayloadToSearchBarData(
          payload.query,
          this.filters
        );

        this.searchBarFilters$.next(searchFilters);
        this.searchViewFilters$.next(searchFilters);
        this.searchbarVal = searchParams;
        // sidebar val will be the rest of the query that wasn't assigned to the searchbar
        this.sidebarVal = reduce(
          payload.query,
          (prev, value, key) => {
            if (!has(this.searchbarVal, key)) {
              prev[key] = value;
            }
            return prev;
          },
          {}
        );

        if (this.sidebarVal?.isInMission) {
          payload.pageAndSort = { page: 1, order: 'missionId', sort: LoadsServiceOrderByTypeDirection.ASC, limit: 100 };
        }
        this.loadEntityService.getUSXLoadsManager.dispatch({
          query: { ...payload.query, fullMission: false },
          pageAndSort: payload.pageAndSort,
        });
      });

    this.loadPriceModel.httpStatus$
      .pipe(
        takeUntil(this.alive),
        map((httpStatus) => {
          return httpStatus.get(this?.priceData?.loadId);
        }),
        withLatestFrom(this.tableData$)
      )
      .subscribe(([httpStatus, data]: [HttpStatus, LoadsServiceLoad[]]) => {
        if (httpStatus === HttpStatus.SUCCESS) {
          data.forEach((load, i) => {
            const newPaymentDetails = { ...load.paymentDetails };
            if (load.id === this?.priceData?.loadId) {
              if (load.paymentDetails.price !== this?.priceData?.price) {
                newPaymentDetails.price = this?.priceData?.price;
              }
              if (load.paymentDetails.maxBuy !== this?.editedPriceData?.maxBuy) {
                newPaymentDetails.maxBuy = this.editedPriceData.maxBuy;
              }

              if (newPaymentDetails != load.paymentDetails) {
                const newData = [...(<LoadsServiceLoad[]>data)];
                newData.splice(i, 1, { ...load, paymentDetails: newPaymentDetails });
                this.tableData$.next(newData);
              }
            }
          });
        }
      });

    this.loadEntityService.getUSXLoadsManager.searchQuery$.pipe(takeUntil(this.alive)).subscribe((searchQuery) => {
      const equipment = searchQuery?.payload?.equipment?.map(camelCase);
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { ...omit(searchQuery.payload, 'fullMission'), equipment },
      });
      this.searchQuery = searchQuery.payload;
      this.searchPage = searchQuery.pageAndSort;
    });

    this.appModel.user$.pipe(takeUntil(this.alive)).subscribe((value: User) => {
      if (!value) return;
      this.user = value;
      // this.sidebarItems$.next(defaultSidebarItems(this.user));

      this.searchViewEntityService.getSearchViewByUserIdManager.dispatch({
        query: this.user.id,
      });
    });

    this.searchViewEntityService.bulkUpdateSearchViewsManager.onError$.pipe(takeUntil(this.alive)).subscribe(() => {
      this.getSidebarItems();
    });

    merge(
      this.searchViewEntityService.deleteSearchViewManager.onSuccess$,
      this.searchViewEntityService.createSearchViewManager.onSuccess$,
      this.searchViewEntityService.bulkUpdateSearchViewsManager.onSuccess$
    )
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.searchViewEntityService.getSearchViewByUserIdManager.dispatch({
          query: this.user.id,
        });
      });
    this.getSidebarItems();
    this.watchSearchQuery();
    this.loadLockState$ = this.loadEntityService.lockState.loadLockStream$;

    this.isLoadingEntities$ = combineLatest([
      this.loadEntityService.bulkUpdateLoadsManager.isLoadingEntities$,
      this.loadEntityService.reportRolledLoadsManager.isLoadingEntities$,
    ]).pipe(map((values) => values.some(Boolean)));

    //Logic when a carrier tool bar action happens
    combineLatest([
      this.carrierToolbarService.carrierToolbarInvoked$,
      this.carrierToolbarService.selectedLoads$,
      this.tableData$,
    ])
      .pipe(takeUntil(this.alive))
      .subscribe(([invoked, selectedLoads, loads]) => {
        if (invoked?.location === CarrierToolbarLocations.CARRIER_SEARCH && invoked?.value) {
          this.selectedRows$.next(selectedLoads);
          this.selectedRowsHighlight$.next([
            { entityType: loads.find((load) => String(invoked.value) === load.id), highlightState: false },
            ...selectedLoads.map((selectedLoad) => {
              return { entityType: selectedLoad, highlightState: true };
            }),
          ]);
          //reset invoked instance
          this.carrierToolbarService.setCarrierToolbarInvoked(CarrierToolbarLocations.CARRIER_SEARCH, null);
        }
      });
    this.loadEntityService.getLoadsBidDetailsManager.searchResults$
      .pipe(takeUntil(this.alive), withLatestFrom(this.tableData$))
      .subscribe(([bidDetails, tableData]) => {
        const newData: LoadsServiceLoad[] = tableData.map((load) => {
          const loadBidDetails = bidDetails.find((bidDetail) => bidDetail.loadId === load.id);
          const result = !loadBidDetails?.lowestBid || loadBidDetails?.offerCount === 0 ? null : loadBidDetails;

          return { ...load, bidDetails: result };
        });
        this.tableData$.next(newData);
      });
  }

  ngOnDestroy(): void {
    this.alive.destroy();
    this.titleService.resetTitle();
  }

  onDeleteSidebarItem(item: SidebarItem, allSidebarItems: SidebarItem[]) {
    const selectedSidebarItemParent = this.getSelectedSidebarItemParent(item, allSidebarItems);
    this.onSidebarSelect({ item: selectedSidebarItemParent, previousItem: this.selectedSidebarItem }, this.searchQuery);
    this.searchViewEntityService.deleteSearchViewManager.dispatch(item.id, {
      searchViewId: item.id,
      userId: this.user.id,
    });
  }

  /**
   * Sends dispatch to bulkUpdateSearchViewsmanager for updating saved views
   *
   * @param {SidebarItem[]} items Updated search views
   */
  onUpdateSidebarItem(items: SidebarItem[]) {
    const searchViewIds = items.map((searchView) => searchView.id);
    const copySidebarItems = items.map((searchView, index) => {
      searchView.data.viewId = undefined;
      searchView.metaData.isCustom = undefined;
      if (searchView.data.equipment) {
        searchView.data.equipment = [...searchView.data.equipment.map(camelCase)];
      }
      //order can not start zero
      searchView.order = index + 1;
      return searchView;
    });
    this.searchViewEntityService.bulkUpdateSearchViewsManager.dispatch(searchViewIds, {
      searchViews: <SearchView[]>copySidebarItems,
      userId: this.user.id,
    });
  }

  /**
   * Retreives custom search view items
   */
  getSidebarItems(): void {
    this.sidebarItems$ = this.searchViewEntityService.getSearchViewByUserIdManager.searchResults$.pipe(
      map((searchViews) => {
        this.searchViews = searchViews;
        const sidebarWithCustomViews =
          searchViews?.reduce((prev, curr) => {
            const customView: SearchView = {
              ...curr,
              metaData: { ...curr.metaData, isCustom: true },
              data: { ...omitBy(curr.data, isNull), viewId: curr.id },
            };

            const parent = getParentSidebarItem(curr, prev);
            if (!parent) {
              // If the custom search doesn't have a parent sidebar item, ignore it
              return prev;
            }
            parent.children = [...parent.children, customView];
            return prev;
          }, defaultSidebarItems(this.user, this.features)) ?? defaultSidebarItems(this.user, this.features);

        return sidebarWithCustomViews;
      })
    );
  }

  onSidebarToggle(isSidebarOpen: boolean): void {
    this.isSidebarOpen = isSidebarOpen;
  }

  onSidebarSelect(
    event: { item: LoadSearchSideBarItem; previousItem: LoadSearchSideBarItem },
    currSearchQuery: LoadServiceSearchParameters
  ): void {
    if (event.item?.data) {
      const query = event.item.data?.viewId
        ? event.item.data
        : this.advancedSearchService.mergeSearchData(currSearchQuery, event.item.data, event.previousItem?.data);
      let pageAndSort: PageAndSort = event.item?.metaData?.sort
        ? {
            page: event.item?.metaData?.sort.page,
            limit: event.item?.metaData?.sort.limit,
            order: event.item?.metaData?.sort.order,
            secondaryOrder: event.item?.metaData.sort.secondaryOrder,
          }
        : undefined;
      if (this.selectedSidebarItem?.metaData?.sort) {
        pageAndSort = this.selectedSidebarItem?.metaData?.sort;
      }

      this.loadEntityService.getUSXLoadsManager.dispatch({ query: { ...query, fullMission: false }, pageAndSort });
      setTimeout(() => (this.shouldResetTextArrayForm = false));
    }
  }

  get trackingStatusFeatureFlagValue(): boolean {
    return this.featureFlagService.checkFlag(this.features, [FeatureFlag.TRACKING_STATUS]);
  }

  onMatchSidebarItem(item: LoadSearchSideBarItem, allSidebarItems: SidebarItem[]): void {
    this.columns = dataColumns(
      item?.metaData?.stickyColumnWidthClosed,
      null,
      item?.metaData?.columns || [],
      [
        {
          columnField: ColumnField.TRACKING_STATUS,
          enabled: this.trackingStatusFeatureFlagValue,
          value: false,
        },
      ],
      false,
      item?.metaData?.isCustom
    );
    this.isSearchView = !!this.selectedSidebarItem?.isCustom;

    this.selectedSidebarItem?.metaData?.columns.map((column) => {
      if (column?.value) {
        const columnMatch = this.columns.find((match: IColumns2) => match?.field === column?.key);
        if (columnMatch) {
          columnMatch.order = column?.order;
        }
      }
    });

    this.columns = orderBy(this.columns, ['order'], ['asc']);
    this.stickyColumnWidth = item?.metaData?.stickyColumnWidthClosed;
    if (this.refreshSelectedSidebarItem) {
      this.selectedSidebarItem = item;
      this.selectedSidebarItemParent = this.getSelectedSidebarItemParent(this.selectedSidebarItem, allSidebarItems);
    }
    if (item?.label) {
      this.titleService.setTitle(`Load Board - ${item.label}`);
    }

    if (item.id === 'missions' || item.id === 'test-missions') {
      const index = this.columns.findIndex((column) => column.field === ColumnField.MISSION);
      if (index > -1) {
        this.columns[index].order = 0.5;
      }
      this.missionBulkActions();
      this.advancedSearch?.onRemoveAllSearchFilters();
    } else {
      const missionLoads = this.carrierToolbarService.selectedLoads$.value.filter((load) => load.mission);
      this.carrierToolbarService.pushLoads([], missionLoads);
      this.defaultBulkActions();
    }
  }

  getlastCommentTimeDiff(row: LoadsServiceLoad): string {
    if (!row.latestMilestoneComment?.timestamp) {
      return null;
    }
    return formatDistance(
      new Date(),
      zonedTimeToUtc(
        row.latestMilestoneComment.timestamp,
        row.latestMilestoneComment.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
      )
    );
  }

  getLastLocationCoordinates(load: LoadsServiceLoad, latestLog: MilestoneLog): LatLonInput {
    if (latestLog) {
      return {
        lat: latestLog.primaryEvent.latitude,
        lon: latestLog.primaryEvent.longitude,
      } as LatLonInput;
    }
    return null;
  }

  generateNearestCities(loads: LoadsServiceLoad[]): void {
    const coordinates: LatLonInput[] = [];
    const loadIndexes: number[] = [];
    loads.forEach((load, index) => {
      const lastCoordinates = this.getLastLocationCoordinates(load, load.latestMilestoneLog);
      if (lastCoordinates?.lat && lastCoordinates?.lon) {
        coordinates.push(lastCoordinates);
        loadIndexes.push(index);
      }
    });

    if (coordinates.length > 0) {
      this.geocodingService
        .getNearestCities(coordinates)
        .pipe(takeUntil(this.alive))
        .subscribe((nearestCities: NearestCity[]) => {
          nearestCities.forEach((nearestCity, index) => {
            this.loadsNearestCities[loads[loadIndexes[index]].id] = nearestCity.city + ', ' + nearestCity.state;
          });
        });
    }
  }

  onSubmitSearch(
    event: { newData: LoadSearchBarResult; previousData: LoadSearchBarResult },
    currSearchQuery: LoadServiceSearchParameters
  ): void {
    const newSearchData = this.advancedSearchService.mergeSearchData(
      currSearchQuery,
      this.advancedSearchService.convertSearchToLoadServiceSearchParameters(event.newData),
      this.advancedSearchService.convertSearchToLoadServiceSearchParameters(event.previousData)
    );
    const payload = this.selectedSidebarItem.metaData?.sort
      ? { query: newSearchData, pageAndSort: this.selectedSidebarItem.metaData?.sort }
      : { query: newSearchData };
    this.loadEntityService.getUSXLoadsManager.dispatch({
      query: { ...payload.query, fullMission: false },
      pageAndSort: payload.pageAndSort,
    });
  }

  onRowSelect(selection: RowSelectionEvent<LoadsServiceLoad>): void {
    if (!selection.headerToggled) {
      if (selection.rowSelected) {
        this.carrierToolbarService.pushLoads([selection.row]);
      } else {
        this.carrierToolbarService.pushLoads([], [selection.row]);
      }
    } else {
      if (selection.rowSelected) {
        this.carrierToolbarService.pushLoads(selection.selection as LoadsServiceLoad[]);
      } else {
        this.carrierToolbarService.pushLoads([], selection.page);
      }
    }
  }

  onRowClick(load: LoadsServiceLoad): void {
    const url = this.router.serializeUrl(this.router.createUrlTree([`/loads/${load.id}`]));
    this.localStoreService.set(load.id, this.router.url);
    this.windowRef.getNativeWindow().open(url, '_blank');
  }

  onBulkAction(payload: LoadBulkActionPayload): void {
    if (payload.action === LoadBulkAction.REPORT_ROLLED_LOADS) {
      this.loadEntityService.reportRolledLoadsManager.dispatch(
        payload.reportedLoadsInput.loadIds,
        payload.reportedLoadsInput
      );
    } else {
      const loadIds = payload.loadInputs.map((load) => load.loadId);
      this.loadEntityService.bulkUpdateLoadsManager.dispatch(loadIds, {
        input: <Partial<BulkEditLoadsInput>>payload.loadInputs,
        loadIds,
      });
    }
  }

  onPage(event: { pageUp: boolean; limit: number }): void {
    if (event.pageUp) {
      this.loadEntityService.getUSXLoadsManager.goToPage(null, event.limit);
    } else {
      this.loadEntityService.getUSXLoadsManager.goToPage(-1, event.limit);
    }
  }

  onSort(
    event: { config: ISortingConfig; sortType: 'ASC' | 'DESC'; page: number },
    searchQuery: { payload: Partial<LoadServiceSearchParameters>; pageAndSort: Partial<PageAndSort> }
  ): void {
    const search: Partial<PageAndSort> = {
      ...searchQuery.pageAndSort,
      sort: event.sortType === 'ASC' ? 'asc' : 'desc',
      limit: this.configOptions.pageAmount,
      page: event.page,
    };
    const indexes = event.config.dataIndex.split('~');
    if (indexes.length !== 2) {
      console.error(
        'column config dataIndex incorrect. Please format dataIndex as follows: "<DESC order type>~<ASC order type>".'
      );
    } else {
      if (indexes[1].split('?').length !== 2) {
        console.error(
          'sorting dataIndex format is incorrect. if emitSort = true, format is <asc type>~<desc type>?<local sort config>'
        );
      }
      const secondaryIndexes = event.config?.secondaryIndex?.split('~');

      if (secondaryIndexes && secondaryIndexes?.length !== 2) {
        console.error(
          'column config secondaryIndex incorrect. Please format dataIndex as follows: "<DESC order type>~<ASC order type>".'
        );
      } else {
        if (secondaryIndexes && secondaryIndexes[1].split('?').length !== 2) {
          console.error(
            'sorting secondaryIndex format is incorrect. if emitSort = true, format is <asc type>~<desc type>?<local sort config>'
          );
        }
        // search.secondaryOrder = {
        //   order: (event.sortType === 'ASC'
        //     ? secondaryIndexes[0]
        //     : secondaryIndexes[1].split('?')[0]) as LoadsServiceOrderByType,
        //   sort: event.sortType === 'ASC' ? 'asc' : 'desc',
        // };
      }

      search.order = event.sortType === 'ASC' ? indexes[0] : indexes[1].split('?')[0];
      this.loadEntityService.getUSXLoadsManager.dispatch({
        query: { ...searchQuery.payload, fullMission: false },
        pageAndSort: search,
      });
    }
  }

  openBookLoadDialog(load: LoadsServiceLoad): void {
    if (this.features[FeatureFlag.LOAD_OVERVIEW]) {
      const url = this.router.serializeUrl(this.router.createUrlTree([`loads/${load.id}/overview/booking`]));
      this.windowRef.getNativeWindow().open(url, '_blank');
    } else {
      this.dialog.open(AssignLoadCarrierContainerComponent, {
        data: {
          carrierDot: null,
          loadId: load.id,
          brokerId: load.broker?.id,
          formData: {
            price: load.paymentDetails?.price,
          },
        } as AssignLoadCarrierData,
      });
    }
  }

  openBulkActions(index: number): void {
    this.rowActionsDisplay[index] = !this.rowActionsDisplay[index];
  }

  private watchSearchQuery(): void {
    this.searchQuery$
      .pipe(
        takeUntil(this.alive),
        map((searchQuery) => {
          let payload = searchQuery.payload;
          if (payload?.destinationLat) {
            payload = { ...payload, destinationLat: JSON.stringify(payload.destinationLat) };
          }
          if (payload?.destinationLon) {
            payload = { ...payload, destinationLon: JSON.stringify(payload.destinationLon) };
          }
          if (payload?.originLat) {
            payload = { ...payload, originLat: JSON.stringify(payload.originLat) };
          }
          if (payload?.originLon) {
            payload = { ...payload, originLon: JSON.stringify(payload.originLon) };
          }
          if (payload?.originRadiusMiles) {
            payload = { ...payload, originRadiusMiles: JSON.stringify(payload.originRadiusMiles) };
          }
          if (payload?.destinationRadiusMiles) {
            payload = { ...payload, destinationRadiusMiles: JSON.stringify(payload.destinationRadiusMiles) };
          }

          return { ...searchQuery, payload };
        })
      )
      .subscribe((searchQuery) => {
        const equipment = searchQuery?.payload?.equipment?.map(camelCase);
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: { ...omit(searchQuery.payload, 'fullMission'), equipment },
        });
        // send search filters to search bar

        const { searchFilters } = this.advancedSearchService.convertSearchPayloadToSearchBarData(
          searchQuery.payload,
          this.filters
        );
        this.searchBarFilters$.next(searchFilters);
      });
  }

  onSelectedFilterChange(filters: ISearchFilter[]): void {
    this.searchViewFilters$.next([...filters]);
  }

  openSaveSearchViewDialog(saveSearchViewDto: SaveSearchViewDto, allSidebarItems: SidebarItem[]): void {
    const { columnsToShow, columnsToHide } = saveSearchViewDto;
    this.selectedSidebarItemParent = this.getSelectedSidebarItemParent(this.selectedSidebarItem, allSidebarItems);
    this.columnsToShow = columnsToShow;

    this.dialog
      .open(SaveSearchViewComponent, {
        data: {
          selectedSidebarItemParent: this.selectedSidebarItemParent,
          searchFilters: this.searchViewFilters$.value,
          searchForm: this.advancedSearch.mainForm,
          columnsToShow,
          searchViews: this.searchViews,
        },
        width: '690px',
        height: '600px',
        panelClass: 'save-search-view-panel',
      })
      .afterClosed()
      .pipe(takeUntil(this.alive))
      .subscribe(({ label }: { label: string }) => {
        if (label) {
          const newSidebarItem = cloneDeep(this.selectedSidebarItem);
          newSidebarItem.label = label;
          const excludedColumns = columnsToHide.reduce(
            (acc, curr: IColumns2) => [...acc, { key: curr?.field, value: false }],
            []
          );
          const includedColumns = columnsToShow.reduce(
            (acc, curr: IColumns2) => [...acc, { key: curr?.field, value: true, order: curr?.order }],
            []
          );
          newSidebarItem.metaData.columns = [...includedColumns, ...excludedColumns];
          newSidebarItem.metaData = omit(newSidebarItem.metaData, 'isCustom');

          newSidebarItem.data = this.processSearchViewData(this.searchQuery);

          newSidebarItem.parent = this.selectedSidebarItemParent.id;

          const newSearchView = <SearchView>(<unknown>omit(newSidebarItem, 'id'));

          //convert origin/dest data
          if (
            typeof newSearchView.data.originRadiusMiles === 'string' ||
            newSearchView.data.originRadiusMiles instanceof String
          ) {
            newSearchView.data.originRadiusMiles = JSON.parse('' + newSearchView.data.originRadiusMiles);
          }
          if (
            typeof newSearchView.data.destinationRadiusMiles === 'string' ||
            newSearchView.data.destinationRadiusMiles instanceof String
          ) {
            newSearchView.data.destinationRadiusMiles = JSON.parse('' + newSearchView.data.destinationRadiusMiles);
          }
          if (typeof newSearchView.data.originLat === 'string' || newSearchView.data.originLat instanceof String) {
            newSearchView.data.originLat = JSON.parse('' + newSearchView.data.originLat);
            newSearchView.data.originLon = JSON.parse('' + newSearchView.data.originLon);
          }
          if (
            typeof newSearchView.data.destinationLat === 'string' ||
            newSearchView.data.destinationLat instanceof String
          ) {
            newSearchView.data.destinationLat = JSON.parse('' + newSearchView.data.destinationLat);
            newSearchView.data.destinationLon = JSON.parse('' + newSearchView.data.destinationLon);
          }

          this.searchViewEntityService.createSearchViewManager.dispatch('new', {
            newSearchView,
            userId: this.user.id,
          });
        }
      });
  }

  private processSearchViewData(data: Partial<LoadServiceSearchParameters>): Partial<LoadServiceSearchParameters> {
    const omittedViewId = omit(data, ['viewId']);
    const dateDeltas = convertDatesToDeltas(omittedViewId);
    return dateDeltas;
  }

  private getSelectedSidebarItemParent(sidebarItem: SidebarItem, currentSideBarItems: SidebarItem[]): SidebarItem {
    const isRootItemSelected = currentSideBarItems.find((item: SidebarItem) => item.label === sidebarItem.label);

    if (isRootItemSelected) {
      return null;
    } else {
      const flattenSidebarNodes = (items: SidebarItem[]): SidebarItem[] => {
        if (!items) return [];
        return flatMap(items, (item: SidebarItem) => [item, ...flattenSidebarNodes(item.children)]);
      };
      const allSidebarItems = flattenSidebarNodes(currentSideBarItems);
      const selectedSidebarItemIndex = allSidebarItems.findIndex(
        (item: SidebarItem) => item.label === sidebarItem.label
      );

      return allSidebarItems
        .slice(0, selectedSidebarItemIndex)
        .reverse()
        .find((item: SidebarItem) => !!item?.children?.length);
    }
  }

  onColumnsChange(columns: IColumns2[]): void {
    //On column init check if bids column is being called
    this.bidsColumn = !columns.find((column) => {
      return column?.isVisible && column?.field === 'bids';
    })
      ? false
      : true;

    if (this.bidsColumn) {
      this.loadEntityService.getLoadsBidDetailsManager.dispatch({ query: { loadIds: this.loadIds } });
    }

    this.lastTrailerLocationColumn = columns.find((column) => {
      return column?.isVisible && column?.field === ColumnField.LAST_TRAILER_LOCATION;
    })
      ? true
      : false;

    if (this.lastTrailerLocationColumn) {
      this.getTrailerTelemetryData(this.loads);
    }

    setTimeout(() => {
      if (this.selectedSidebarItem.id === 'missions' || this.selectedSidebarItem.id === 'test-missions') {
        const index = this.columns.findIndex((column) => column.field === ColumnField.MISSION);
        if (index > -1) {
          this.columns[index].order = 0.5;
        }
        this.missionBulkActions();
      } else {
        this.defaultBulkActions();
      }

      const frozenColumns = this.columns.filter((column: IColumns2) => column?.isFrozen);
      const unfrozenColumns = columns.filter((column: IColumns2) => {
        return !frozenColumns.some((frozen: IColumns2) => frozen.field === column.field);
      });
      this.columns = orderBy([...frozenColumns, ...unfrozenColumns], 'order', 'asc');
    });
  }

  onColumnsReset(allSidebarItems: SidebarItem[]): void {
    this.onMatchSidebarItem(this.selectedSidebarItem, allSidebarItems);
  }

  getSidebarDataFromQuery(): LoadServiceSearchParameters {
    const params = new URLSearchParams(location.search);
    const multipleValues = [
      'billTo',
      'carrierNameOrDot',
      'receiver',
      'shipper',
      'bookingBrokers',
      'brokerNameOrUsxId',
      'brokerTeamId',
      'region',
      'bookStatus',
      'loadStatus',
    ];

    const result = {};
    for (const key of params.getAll(location.search)) {
      if (multipleValues.includes(key)) {
        result[key] = params.getAll(key);
      } else {
        result[key] = params.get(key);
      }
    }

    return result;
  }

  getTrailerTelemetryData(loads: LoadsServiceLoad[]) {
    /*this.trailerEntityService.searchTrailerHistoryByTrailerInfo.dispatch({
      trailerNumber: '003051', //load.trailers[0]?.trailerNumber,
      trailerCompany: '01', //load?.trailers[0]?.trailerCompany,
    });*/
    loads.forEach((load) => {
      if (
        load?.trailers?.length > 0 &&
        load?.trailers[0]?.trailerCompany &&
        load?.trailers[0]?.trailerOwnerType === TrailerOwnerType.USXI &&
        this.lastTrailerLocationColumn
      ) {
        this.trailerEntityService.searchTrailerHistoryByTrailerInfo.dispatch({
          trailerNumber: load.trailers[0]?.trailerNumber,
          trailerCompany: load?.trailers[0]?.trailerCompany,
        });
        this.allTrailerCallsMade = true;
      }
    });
  }

  /**
   * We might do an initial search depending on the the currently URL query params,
   * or if there is an existing query in the state.
   */
  private chooseInitialSearchQuery(
    routeParams: Params,
    searchQuery: { queryHash: string; payload: Partial<LoadServiceSearchParameters>; pageAndSort: Partial<PageAndSort> }
  ): {
    query: Partial<LoadServiceSearchParameters>;
    pageAndSort?: Partial<PageAndSort>;
  } {
    const hasRouteParams = !!keys(routeParams).length;
    const hasCurrentQuery = !!searchQuery?.queryHash;
    if (hasRouteParams) {
      if (routeParams?.destinationLat) {
        routeParams = { ...routeParams, destinationLat: JSON.parse(routeParams.destinationLat) };
      }
      if (routeParams?.destinationLon) {
        routeParams = { ...routeParams, destinationLon: JSON.parse(routeParams.destinationLon) };
      }
      if (routeParams?.originLat) {
        routeParams = { ...routeParams, originLat: JSON.parse(routeParams.originLat) };
      }
      if (routeParams?.originLon) {
        routeParams = { ...routeParams, originLon: JSON.parse(routeParams.originLon) };
      }
      if (routeParams?.originRadiusMiles) {
        routeParams = { ...routeParams, originRadiusMiles: JSON.parse(routeParams.originRadiusMiles) };
      }
      if (routeParams?.destinationRadiusMiles) {
        routeParams = { ...routeParams, destinationRadiusMiles: JSON.parse(routeParams.destinationRadiusMiles) };
      }

      return { query: this.advancedSearchService.convertQueryParamsToSearchPayload(routeParams, this.filters) };
    } else if (hasCurrentQuery) {
      return { query: searchQuery.payload, pageAndSort: searchQuery.pageAndSort };
    }
    return null;
  }

  onPriceChange(event: { loadId: string; maxBuy?: number; price: number }) {
    this.editedPriceData = event;
  }

  missionBulkActions() {
    this.configOptions = {
      ...this.configOptions,
      showBulkActions: true,
      bulkActions: BulkActionPage.MISSION,
    };
  }

  defaultBulkActions() {
    this.configOptions = {
      ...this.configOptions,
      showBulkActions: true,
      bulkActions: BulkActionPage.SEARCH,
    };
  }

  onCreateMission(): void {
    this.dialog
      .open(CreateMissionComponent, {
        data: {},
        maxWidth: '100vw',
        width: '700px',
        height: '615px',
        panelClass: 'create-mission__panel',
        restoreFocus: false,
        autoFocus: false,
      })
      .afterClosed()
      .pipe(takeUntil(this.alive), delay(1000))
      .subscribe((result) => {
        if (result.success) {
          //reload page
          this.loadEntityService.getUSXLoadsManager.dispatch({
            query: { isInMission: true, showExcluded: true, showTestLoads: true },
            pageAndSort: this.searchPage,
          });
        }
      });
  }
}
