import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'haulynx-blue-fab',
  templateUrl: './blue-fab.component.html',
  styleUrls: ['./blue-fab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlueFabComponent {
  @Input() icon: string;
  @Output() clicked = new EventEmitter<void>();

  public buttonClicked() {
    this.clicked.emit();
  }
}
