import { Observable } from 'rxjs';

export interface AsyncDataDictionaryManager<PayloadType, ResponseData> {
  /**
   * @returns a map where the keys are query hashes and the values are corresponding data
   */
  data$: Observable<{ [index: string]: ResponseData }>;

  /**
   * Dynamically returns a slice of data$ based on an observable payload.
   * @param input$ An observable that represents the currently relevant query data
   * @returns An observable of the desired data that corresponds to the `input$` query
   */
  getData: <T>(input$: Observable<T>) => Observable<ResponseData>;

  /**
   * If any query is currently loading, this will emit `true`
   */
  isLoading$: Observable<boolean>;
  // /**
  //  * This will trigger when the effect returns successfully
  //  */
  // onSuccess$: Observable<ResponseData>;
  // /**
  //  * This will trigger when the effect returns an error
  //  */
  // onError$: Observable<any>;
  // /**
  //  * This will trigger when the effect returns either successfully or with an error
  //  */
  // onResponse$: Observable<ResponseData | any>;
  dispatch: (payload?: PayloadType) => void;
}
