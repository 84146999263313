import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-load-share-location-warning',
  templateUrl: './load-share-location-warning.component.html',
  styleUrls: ['./load-share-location-warning.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadShareLocationWarningComponent {
  constructor() {}
}
