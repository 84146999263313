import { createAction, createActionTypes } from '../helpers/action';

export interface IGenericActions {
  remove(params?);

  removeSuccess(params?);

  removeError(params?);

  cancel(params?);

  cancelSuccess(params?);

  cancelError(params?);

  add(params?);

  addSuccess(params?);

  addError(params?);

  update(params?);

  updateSuccess(params?);

  updateError(params?);
}

export interface IGenericActionsTypes {
  REMOVE: string;
  REMOVE_SUCCESS: string;
  REMOVE_ERROR: string;
  CANCEL: string;
  CANCEL_SUCCESS: string;
  CANCEL_ERROR: string;
  ADD: string;
  ADD_SUCCESS: string;
  ADD_ERROR: string;
  UPDATE: string;
  UPDATE_SUCCESS: string;
  UPDATE_ERROR: string;
}

export const genericActionsActionTypes: IGenericActionsTypes = {
  REMOVE: 'remove',
  REMOVE_SUCCESS: 'remove success',
  REMOVE_ERROR: 'remove error',
  CANCEL: 'cancel',
  CANCEL_ERROR: 'cancel error',
  CANCEL_SUCCESS: 'cancel success',
  ADD: 'add',
  ADD_SUCCESS: 'add success',
  ADD_ERROR: 'add error',
  UPDATE: 'update',
  UPDATE_SUCCESS: 'update success',
  UPDATE_ERROR: 'update error',
};

export const createGenericActionsActionTypes = createActionTypes(genericActionsActionTypes);

export function makeGenericActionsActions(type) {
  const remove = createAction(type, genericActionsActionTypes.REMOVE);
  const removeSuccess = createAction(type, genericActionsActionTypes.REMOVE_SUCCESS);
  const removeError = createAction(type, genericActionsActionTypes.REMOVE_ERROR);
  const cancel = createAction(type, genericActionsActionTypes.CANCEL);
  const cancelError = createAction(type, genericActionsActionTypes.CANCEL_ERROR);
  const cancelSuccess = createAction(type, genericActionsActionTypes.CANCEL_SUCCESS);
  const add = createAction(type, genericActionsActionTypes.ADD);
  const addError = createAction(type, genericActionsActionTypes.ADD_ERROR);
  const addSuccess = createAction(type, genericActionsActionTypes.ADD_SUCCESS);
  const update = createAction(type, genericActionsActionTypes.UPDATE);
  const updateError = createAction(type, genericActionsActionTypes.UPDATE_ERROR);
  const updateSuccess = createAction(type, genericActionsActionTypes.UPDATE_SUCCESS);

  return {
    remove,
    removeSuccess,
    removeError,
    cancel,
    cancelError,
    cancelSuccess,
    add,
    addError,
    addSuccess,
    update,
    updateError,
    updateSuccess,
  };
}

export function createActions(type) {
  return {
    getActionTypes(): IGenericActionsTypes {
      return createGenericActionsActionTypes(type);
    },
    getActions(): IGenericActions {
      return makeGenericActionsActions(type);
    },
  };
}
