export enum LoadOverviewMainRoute {
  info = 'info',
  capacity = 'capacity',
  booking = 'booking',
  tracking = 'tracking',
  activity = 'activity',
  documents = 'documents',
  history = 'history',
}

export enum JourneyView {
  list = 'list',
  map = 'map',
}
