import { ChangeDetectionStrategy, Component, Injector, Input, ViewEncapsulation } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessorConnectorComponent } from '@haulynx/utils';

@Component({
  selector: 'haulynx-bid-modal-textbox',
  templateUrl: './bid-modal-textbox.component.html',
  styleUrls: ['./bid-modal-textbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: BidModalTextboxComponent,
      multi: true,
    },
  ],
})
export class BidModalTextboxComponent extends ControlValueAccessorConnectorComponent {
  @Input() inputControl: FormControl;
  @Input() maxLength: string = '200';
  @Input() showTitleAndCount: boolean = false;
  @Input() title: string = 'title';
  @Input() placeHolderText: string = 'Message here';

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {}
}
