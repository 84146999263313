import gql from 'graphql-tag';

export const SendPaymentItemsAndEditPrice = gql(`
  mutation SendPaymentItemsAndEditPrice($orderNumber: StrictId, $loadId: StrictId, $userEmail: EmailAddress!) {
    sendPaymentItemsAndEditPrice(orderNumber: $orderNumber, loadId: $loadId, userEmail: $userEmail) {
      orderNumber
      paymentType
      amount
      paymentUnits
      haulynxId
      usxId
    }
  }
`);
