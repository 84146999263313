import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DirectivesModule } from '@haulynx/directives';
import { MaterialModule } from '@haulynx/modules';
import { LoadRestrictionsPipe, PipesModule } from '@haulynx/pipes';
import { ConfirmationModule } from '@haulynx/services';
import { CountdownModule } from 'ngx-countdown';
import { BookLoadModule } from '../book-load/book-load.module';
import { CarrierBidsModule } from '../carrier-bids/carrier-bids.module';
import { CustomerDetailsModule } from '../customer-details/customer-details.module';
import { LoadTimelineModule } from '../load-timeline/load-timeline.module';
import { MapsModule } from '../maps/maps.module';
import { TooltipMenuModule } from '../tooltip-menu/tooltip-menu.module';
import { LoadFeedDetailsInfoPaneComponent } from './components/load-feed-details-info-pane/load-feed-details-info-pane.component';
import { LoadFeedDetailsMapModule } from './components/load-feed-details-map/load-feed-details-map.module';
import { LoadFeedDetailsSharedComponent } from './components/load-feed-details-shared/load-feed-details-shared.component';
import { UnauthenticatedLoadFeedDetailsInfoPaneModule } from './components/unauthenticated-load-feed-details-info-pane/unauthenticated-load-feed-details-info-pane/unauthenticated-load-feed-details-info-pane.module';

@NgModule({
  declarations: [LoadFeedDetailsInfoPaneComponent, LoadFeedDetailsSharedComponent],
  imports: [
    CommonModule,
    LoadFeedDetailsMapModule,
    UnauthenticatedLoadFeedDetailsInfoPaneModule,
    TooltipMenuModule,
    DirectivesModule,
    LoadTimelineModule,
    CountdownModule,
    MapsModule,
    BookLoadModule,
    ConfirmationModule,
    CustomerDetailsModule,
    FlexLayoutModule,
    CarrierBidsModule,
    MaterialModule,
    PipesModule,
    BookLoadModule,
  ],
  exports: [
    LoadFeedDetailsMapModule,
    UnauthenticatedLoadFeedDetailsInfoPaneModule,
    LoadFeedDetailsInfoPaneComponent,
    LoadFeedDetailsSharedComponent,
  ],
  providers: [LoadRestrictionsPipe],
})
export class LoadFeedDetailsComponentsModule {}
