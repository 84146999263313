import { Injectable } from '@angular/core';
import { NotificationsService, PaymentService } from '@haulynx/services';
import {
  PaymentItemCreateInput,
  PaymentItemCreateResponse,
  PaymentItemSearchCriteria,
  PaymentItemUpdateInput,
  PaymentsTypeForm,
} from '@haulynx/types';
import { listToArray } from '@haulynx/utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import _ from 'lodash';
import { groupBy, includes, isEqual, keys, map as _map, omit, reduce, remove } from 'lodash';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '../../main-store/app.reducers';
import { DispatchAction } from '../../shared/store/helpers/action';
import { PaymentTypesActions, PaymentTypesActionTypes } from './payment-types.actions';
import { initialPaymentTypesSelector, paymentQuerySelector } from './payment-types.selectors';

@Injectable()
export class PaymentTypesEffects {
  getPaymentTypes = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentTypesActionTypes.GET_PAYMENT_TYPES),
      map((action: DispatchAction) => action.payload),
      withLatestFrom(this.store.pipe(select(paymentQuerySelector))),
      switchMap(([payload, query]: [PaymentItemSearchCriteria, PaymentItemSearchCriteria]) =>
        this.paymentService.findPaymentTypeForm({ ...payload, ...query }).pipe(
          map((entities) => PaymentTypesActions.getPaymentTypeSuccess({ entities })),
          catchError(() => of(PaymentTypesActions.getPaymentTypeError()))
        )
      )
    )
  );

  savePayments = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentTypesActionTypes.SAVE_PAYMENT_TYPE),
      map((action: DispatchAction) => action.payload),
      withLatestFrom(this.store.pipe(select(initialPaymentTypesSelector), map(listToArray))),
      switchMap(
        ([payload, paymentTypes]: [
          {
            orderNumber: string;
            loadId: string;
            payments: PaymentsTypeForm[];
            sendEditPriceParams: { userEmail: string };
          },
          PaymentsTypeForm[]
        ]) => {
          const { orderNumber, loadId, payments, sendEditPriceParams } = payload;
          const result = this.checkDiffPayment(payments, paymentTypes);
          const items: {
            createPaymentItems?: Observable<PaymentItemCreateResponse>;
            deletePaymentItem?: Observable<unknown>;
            updatePaymentItem?: Observable<unknown>;
          } = {};

          if (result.createPaymentItems.length) {
            result.createPaymentItems.map((item) => {
              if (item.paymentType === 'TRAIL') {
                item.amount = Math.abs(+item.amount);
              }
            });
            items.createPaymentItems = this.paymentService.createPaymentItems(loadId, result.createPaymentItems).pipe(
              catchError(() => {
                this.notificationsService.error('Unable to save new payment', 'Payments');
                return of(null);
              })
            );
          }

          if (result.deletePaymentItem.length) {
            const newDeletePaymentItems = _map(result.deletePaymentItem, (item) =>
              this.paymentService.deletePaymentItem(item)
            );

            items.deletePaymentItem = forkJoin(newDeletePaymentItems).pipe(
              catchError(() => {
                this.notificationsService.error('Unable to delete payment', 'Payments');
                return of(null);
              })
            );
          }

          if (result.updatePaymentItem.length) {
            const newUpdatePaymentItems = _map(result.updatePaymentItem, (item) => {
              if (item.paymentUpdate.paymentType === 'TRAIL') {
                item.paymentUpdate.amount = Math.abs(+item.paymentUpdate.amount);
              }
              return this.paymentService.updatePaymentItem(item.paymentId, _.omit(item.paymentUpdate, 'paymentType'));
            });

            items.updatePaymentItem = forkJoin(newUpdatePaymentItems).pipe(
              catchError(() => {
                this.notificationsService.error('Unable to update payment', 'Payments');
                return of(null);
              })
            );
          }

          if (result.deletePaymentItem.length || result.updatePaymentItem.length || result.createPaymentItems.length) {
            const sendPaymentItemsAndEditPrice = this.paymentService.sendPaymentItemsAndEditPrice(
              orderNumber,
              loadId,
              sendEditPriceParams.userEmail
            );

            return forkJoin(items).pipe(
              concatMap(() => sendPaymentItemsAndEditPrice),
              map(() => {
                const message = 'Payments saved success!';
                this.notificationsService.success(message, 'Payments');

                return PaymentTypesActions.savePaymentsSuccess({ orderNumber });
              }),
              catchError(() => of(PaymentTypesActions.savePaymentsError()))
            );
          } else {
            return of(PaymentTypesActions.savePaymentsSuccess({ orderNumber }));
          }
        }
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private paymentService: PaymentService,
    private notificationsService: NotificationsService
  ) {}

  private checkDiffPayment(
    payments: PaymentsTypeForm[],
    paymentTypes: PaymentsTypeForm[]
  ): {
    deletePaymentItem: string[];
    createPaymentItems: PaymentItemCreateInput[];
    updatePaymentItem: { paymentId: string; paymentUpdate: PaymentItemUpdateInput }[];
  } {
    const groupedPaymentTypes = groupBy(paymentTypes, 'id');
    const activePaymentTypeKeys = keys(groupBy(paymentTypes, 'id'));

    return reduce(
      payments,
      ({ createPaymentItems, deletePaymentItem, updatePaymentItem }, value) => {
        if (!value.id) {
          createPaymentItems.push({
            loadId: value.loadId,
            amount: value.amount,
            paymentType: value.paymentType.code,
            quantity: value.paymentType.quantity,
            orderNumber: value.orderNumber,
          });
        }

        if (value.id && includes(deletePaymentItem, value.id) && !isEqual(groupedPaymentTypes[value.id][0], value)) {
          updatePaymentItem.push({
            paymentId: value.id,
            paymentUpdate: {
              quantity: value.paymentType.quantity,
              amount: value.amount,
              paymentType: value.paymentType.code,
            },
          });
        }

        if (value.id) {
          remove(deletePaymentItem, (paymentItem) => paymentItem === value.id);
        }

        return { createPaymentItems, deletePaymentItem, updatePaymentItem };
      },
      {
        deletePaymentItem: activePaymentTypeKeys as string[],
        createPaymentItems: [] as PaymentItemCreateInput[],
        updatePaymentItem: [] as { paymentId: string; paymentUpdate: PaymentItemUpdateInput }[],
      }
    );
  }
}
