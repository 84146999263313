import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ActiveLoadsTableService } from '@haulynx/services';
import {
  DataTableSelection,
  IColumns,
  Load,
  LoadIdentifierType,
  LoadsServiceLoad,
  LoadStatusType,
  PaginationParams,
} from '@haulynx/types';
import { List } from 'immutable';

@Component({
  selector: 'app-active-loads-table',
  templateUrl: './active-loads-table.component.html',
  styleUrls: ['./active-loads-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActiveLoadsTableComponent implements OnInit, OnChanges {
  @Input() isLoading: boolean;
  @Input() data: List<LoadsServiceLoad[]> = List([]);
  @Input() stickyHeader: boolean;
  @Input() selection: boolean;
  @Input() selected: LoadsServiceLoad;
  @Input() multipleSelection: boolean;
  @Input() extendedColumns: boolean;
  @Input() activeStepTypes: Array<string> = [];
  @Input() lazyLoad = true;
  @Input() pagination: PaginationParams;
  @Input() pageOptions: number[] = [20, 30, 40, 50];
  @Output() select = new EventEmitter<DataTableSelection<LoadsServiceLoad>>();
  @Output() changePagination = new EventEmitter<{
    limit: number;
    page: number;
  }>();
  public loadIdentifierType = LoadIdentifierType;
  selectedRow: string = null;
  displayedColumns: IColumns[] = [];
  hideColumns: IColumns[] = [];
  loadStatusType = LoadStatusType;

  constructor(private activeLoadsTableService: ActiveLoadsTableService) {}

  ngOnInit(): void {
    this.displayedColumns = this.getDisplayedColumns();
  }

  onSelect(event: DataTableSelection<LoadsServiceLoad>): void {
    this.select.emit(event);
    this.selectedRow = event.selection?.id;
  }

  onPagination(data: { limit: number; page: number }): void {
    this.changePagination.emit(data);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { extendedColumns, selected } = changes;

    if (extendedColumns) {
      this.hideColumns = extendedColumns.currentValue ? [] : this.getHideColumns();
    }

    if (selected) {
      this.selectedRow = selected.currentValue?.id;
    }
  }

  private getDisplayedColumns(): IColumns[] {
    const columns: IColumns[] = [];

    columns.push({
      label: '',
      dataIndex: 'icon',
      isCustomCell: true,
      isSortable: false,
    });

    columns.push({
      label: 'Status',
      dataIndex: 'status',
      isCustomCell: true,
      isSortable: true,
      sortConvert: this.activeLoadsTableService.customSort.bind(this.activeLoadsTableService),
    });

    columns.push({
      label: 'TMW #',
      dataIndex: 'tmwNumber',
      isCustomCell: true,
      isSortable: true,
      sortConvert: this.activeLoadsTableService.customSort.bind(this.activeLoadsTableService),
    });

    columns.push({
      label: 'Origin',
      dataIndex: 'origin',
      isCustomCell: true,
      isSortable: true,
      sortConvert: this.activeLoadsTableService.customSort.bind(this.activeLoadsTableService),
    });

    columns.push({
      label: 'State',
      dataIndex: 'originState',
      isCustomCell: true,
      isSortable: true,
      sortConvert: this.activeLoadsTableService.customSort.bind(this.activeLoadsTableService),
    });

    columns.push({
      label: 'P/U Date',
      dataIndex: 'pickupDate',
      isCustomCell: true,
      isSortable: true,
      initiallySortedBy: 'desc',
      sortConvert: this.activeLoadsTableService.customSort.bind(this.activeLoadsTableService),
    });

    columns.push({
      label: 'P/U Time',
      dataIndex: 'pickupTime',
      isCustomCell: true,
      isSortable: true,
      sortConvert: this.activeLoadsTableService.customSort.bind(this.activeLoadsTableService),
    });

    columns.push({
      label: 'Destination',
      dataIndex: 'destination',
      isCustomCell: true,
      isSortable: true,
      sortConvert: this.activeLoadsTableService.customSort.bind(this.activeLoadsTableService),
    });

    columns.push({
      label: 'State',
      dataIndex: 'destinationState',
      isCustomCell: true,
      isSortable: true,
      sortConvert: this.activeLoadsTableService.customSort.bind(this.activeLoadsTableService),
    });

    columns.push({
      label: 'Deliv. Date',
      dataIndex: 'deliveryDate',
      isCustomCell: true,
      isSortable: true,
      sortConvert: this.activeLoadsTableService.customSort.bind(this.activeLoadsTableService),
    });

    columns.push({
      label: 'Deliv. Time',
      dataIndex: 'deliveryTime',
      isCustomCell: true,
      isSortable: true,
      sortConvert: this.activeLoadsTableService.customSort.bind(this.activeLoadsTableService),
    });

    columns.push({
      label: 'Equip', // TODO: Change this back to `Type` later when real type is added
      dataIndex: 'deliveryType',
      isCustomCell: true,
      isSortable: true,
      sortConvert: this.activeLoadsTableService.customSort.bind(this.activeLoadsTableService),
    });

    columns.push({
      label: 'Carrier',
      dataIndex: 'carrier',
      isCustomCell: true,
      isSortable: true,
      sortConvert: this.activeLoadsTableService.customSort.bind(this.activeLoadsTableService),
    });

    columns.push({
      label: 'Customer',
      dataIndex: 'customer',
      isCustomCell: true,
      isSortable: true,
      sortConvert: this.activeLoadsTableService.customSort.bind(this.activeLoadsTableService),
    });

    columns.push({
      label: 'Region',
      dataIndex: 'region',
      isCustomCell: true,
      isSortable: true,
      sortConvert: this.activeLoadsTableService.customSort.bind(this.activeLoadsTableService),
    });

    return columns;
  }

  private getHideColumns(): IColumns[] {
    const columns: IColumns[] = [];
    columns.push({
      label: 'P/U Time',
      dataIndex: 'pickupTime',
      isCustomCell: true,
      isSortable: true,
    });
    columns.push({
      label: 'Deliv. Time',
      dataIndex: 'deliveryTime',
      isCustomCell: true,
      isSortable: true,
    });

    columns.push({
      label: 'Equip', // TODO: Change this back to `Type` later when real type is added
      dataIndex: 'deliveryType',
      isCustomCell: true,
      isSortable: true,
    });

    columns.push({
      label: 'Customer',
      dataIndex: 'customer',
      isCustomCell: true,
      isSortable: true,
    });

    return columns;
  }
}
