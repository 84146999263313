import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoadViewService } from '@haulynx/services';
import { AppModel, CommonEntities, LoadActiveModel, LoadActiveTabsModel, LoadEntityService } from '@haulynx/store';
import { loadActiveTabsConfig, LoadsServiceLoad, Tab, User } from '@haulynx/types';
import { aliveWhile, listToArray } from '@haulynx/utils';
import { truncate } from 'lodash';
import { takeUntil, withLatestFrom } from 'rxjs/operators';

// const FORM_NAMESPACE = 'LOAD_ACTIVE_DETAILS';

@Component({
  selector: 'app-load-active-detail',
  templateUrl: './load-active-details.component.html',
  styleUrls: ['./load-active-details.component.scss'],
})
export class LoadActiveDetails implements OnDestroy {
  user: User;
  alive = aliveWhile();
  showEditLoadButton = false;
  selectedLoad: LoadsServiceLoad = null;
  loadId: string;

  constructor(
    public loadActiveModel: LoadActiveModel,
    public loadActiveTabsModel: LoadActiveTabsModel,
    public appModel: AppModel,
    private activatedRoute: ActivatedRoute,
    private commonEntities: CommonEntities,
    private loadViewService: LoadViewService,
    public loadEntityService: LoadEntityService
  ) {
    this.activatedRoute.params.pipe(takeUntil(this.alive)).subscribe((params) => {
      const { id } = params;
      this.loadId = id;
      this.loadActiveModel.changeSelectedLoadId(id);
      this.loadEntityService.getLoadByIdManager.dispatch(id);
    });

    this.loadEntityService.getLoadByIdManager
      .getEntityById(this.loadId)
      .pipe(withLatestFrom(this.appModel.user$, this.commonEntities.graphQlDrivers.entities$), takeUntil(this.alive))
      .subscribe(([selectedLoad, user, drivers]) => {
        this.user = user;
        this.selectedLoad = selectedLoad;
        const driverArray = listToArray(drivers);
        this.showEditLoadButton = this.loadViewService.userCanEditLoad(driverArray, this.user, this.selectedLoad);
      });

    this.loadEntityService.getLoadByIdManager
      .getEntityById(this.loadId)
      .pipe(withLatestFrom(this.loadActiveTabsModel.tabs$), takeUntil(this.alive))
      .subscribe(([state, tabs]) => {
        const { id = null } = state || {};
        const { label = null } = tabs.get(id) || {};

        if (id && !label) {
          const [pickUp, dropOff] = state?.locations;
          const tab = new Tab({
            id: `${id}`,
            label: `${truncate(pickUp.address, { length: 10 })} -> ${truncate(dropOff.address, { length: 10 })}`,
            url: `details/${id}`,
            order: null,
            selected: true,
            closable: true,
          });

          this.loadActiveTabsModel.updateTabs([tab]);
        }
      });
  }

  success(id): void {
    this.loadEntityService.getLoadByIdManager.dispatch(id);
  }

  closeTab(id): void {
    this.loadActiveTabsModel.removeTabs({ id });
  }

  goToActive(): void {
    this.loadActiveTabsModel.selectTab(loadActiveTabsConfig.SEARCH);
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }
}
