import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[scrollIntoView]',
})
export class ScrollIntoViewDirective implements OnChanges {
  @Input('scrollIntoView') hasFocus = false;
  @Input() scrollDirection = false;
  @Input() scrollIncrement = 50;

  constructor(private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.el.nativeElement.style.cursor = 'pointer';
    if (changes.hasFocus && this.el.nativeElement.parentNode && this.el.nativeElement.parentNode.scrollTo) {
      let currentScrollListElement = 0;
      this.el.nativeElement.parentNode.childNodes.forEach((node, i: number) => {
        if (node && node.classList) {
          if ((<DOMTokenList>node.classList).value.includes('isHovering')) {
            currentScrollListElement = i;
          }
        }
      });
      this.el.nativeElement.parentNode.scrollTo(0, currentScrollListElement * this.scrollIncrement);
    }
  }
}
