import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PaymentsTypeForm, PaymentType } from '@haulynx/types';
import { forEach, keys } from 'lodash';

export class PaymentDetailsForm implements PaymentsTypeForm {
  id: string = null;
  orderNumber: string = null;
  amount: number | string = null;
  quantity: number = null;
  paymentType: PaymentType = null;

  constructor(options = {}) {
    const key = keys(this);
    const defaultValue = [null];

    forEach(key, (prop) => {
      this[prop] = (options && options[prop]) || defaultValue;
    });
  }
}

@Injectable({
  providedIn: 'root',
})
export class PaymentDetailsVmService {
  private fb = new FormBuilder();
  constructor() {}

  create(values?: Partial<PaymentDetailsForm>): FormGroup {
    const paymentDetailsForm = new PaymentDetailsForm();
    const formGroup = this.fb.group(paymentDetailsForm);

    if (values) {
      formGroup.patchValue(values);
    }

    formGroup.controls['quantity'].setValidators(Validators.min(0));

    return formGroup;
  }

  createFormArray(values: PaymentDetailsForm[] = []): FormArray {
    return this.fb.array((values || []).map((payment) => this.create(payment)));
  }
}
