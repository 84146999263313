import gql from 'graphql-tag';

export const GetZipLanesBySearchParameters = gql(`
  query getZipLanesBySearchParameters($searchParameters: ZipLaneSearchParameters!, $paging: ZipLanePageAndSort) {
   getZipLanesBySearchParameters(searchParameters: $searchParameters, paging: $paging) {
    result {
        bidId
        zipLane
        locations {
            city
            state
            zip
            centroidDistance
            timestamp
        }
        loadContents{
            equipmentType
            weight
            commodity
        }
        laneDetails {
            count
            bidSinceWon
            oneWeekAttainment
            customer
        }
        costDetails {
            minCost
            maxCost
            averageCostAmount
            averageCostRate
            recentCostAmount
            recentCostRate
        }
        mlPredicted {
            allInRpm
            costRpm
            laneVolatility
        }
        historical {
            averageBidRate
            averageBidAmount
            bidLow
            bidHigh
            averageWonRate
            averageWonAmount
            wonLow
            wonHigh
            averageMarginAmount
            marginHigh
            marginLow
            datAmount
            datRate
        }
        miles
        allInRPM
        costToHireRPM
    }
    paginator {
        currentPage
        nextPage
        previousPage
        total
        totalPages
    }
    }
  }
`);
