import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'haulynx-blue-button',
  templateUrl: './blue-button.component.html',
  styleUrls: ['./blue-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlueButtonComponent {
  @Input() disabled = false;
  @Input() icon: string = null;
  @Input() svgIcon: string = null;
  @Input() iconPosition: 'before' | 'after' = 'before';
  @Input() loading = false;
  @Input() text = 'text';
  @Input() tabindex = 0;
  @Output() clicked = new EventEmitter<void>();

  public buttonClick() {
    this.clicked.emit();
  }
}
