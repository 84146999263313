import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { List } from 'immutable';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { DispatchAction } from '../../shared/store/helpers/action';
import { CarrierManagementActions, CarrierManagementActionTypes } from './carrier-management-state.actions';

@Injectable()
export class CarrierManagementStateEffects {
  constructor(private actions$: Actions) {}

  setContacted = createEffect(() =>
    this.actions$.pipe(
      ofType(CarrierManagementActionTypes.SET_CONTACTED),
      map((action: DispatchAction) => action.payload),
      switchMap(({ payload }) => {
        // Todo: replace with real service
        return of([]).pipe(
          map(() => {
            return CarrierManagementActions.setContactedSuccess();
          }),
          catchError((error) => {
            return of(CarrierManagementActions.setContactedError({ error }));
          })
        );
      })
    )
  );

  searchTop = createEffect(() =>
    this.actions$.pipe(
      ofType(CarrierManagementActionTypes.SEARCH_TOP),
      map((action: DispatchAction) => action.payload),
      switchMap(({ query = null, pagination = null }) => {
        // Todo: replace with real service
        return of({ entities: List([]) }).pipe(
          map(({ entities }: any) => {
            return CarrierManagementActions.searchTopSuccess({ entities });
          }),
          catchError((error) => {
            return of(CarrierManagementActions.searchTopError({ error }));
          })
        );
      })
    )
  );

  searchDeveloping = createEffect(() =>
    this.actions$.pipe(
      ofType(CarrierManagementActionTypes.SEARCH_DEVELOPING),
      map((action: DispatchAction) => action.payload),
      switchMap(({ query, pagination }) => {
        // Todo: replace with real service
        return of({ entities: List([]) }).pipe(
          map(({ entities }: any) => {
            return CarrierManagementActions.searchDevelopingSuccess({ entities });
          }),
          catchError((error) => {
            return of(CarrierManagementActions.searchDevelopingError({ error }));
          })
        );
      })
    )
  );

  searchProspects = createEffect(() =>
    this.actions$.pipe(
      ofType(CarrierManagementActionTypes.SEARCH_PROSPECTS),
      map((action: DispatchAction) => action.payload),
      switchMap(({ query, pagination }) => {
        // Todo: replace with real service
        return of({ entities: List([]) }).pipe(
          map(({ entities }: any) => {
            return CarrierManagementActions.searchProspectsSuccess({ entities });
          }),
          catchError((error) => {
            return of(CarrierManagementActions.searchProspectsError({ error }));
          })
        );
      })
    )
  );

  delete = createEffect(() =>
    this.actions$.pipe(
      ofType(CarrierManagementActionTypes.DELETE),
      map((action: DispatchAction) => action.payload),
      switchMap((payload) => {
        // Todo: replace with real service
        return of([]).pipe(
          map(() => {
            return CarrierManagementActions.deleteSuccess();
          }),
          catchError((error) => {
            return of(CarrierManagementActions.deleteError({ error }));
          })
        );
      })
    )
  );
}
