import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  constructor(private title: Title) {}

  /**
   * Reset the title of the current HTML document back to `Haulynx`
   */
  public resetTitle(): void {
    this.title.setTitle('Haulynx');
  }

  /**
   * Set the title of the current HTML document.
   * @param title
   */
  public setTitle(title: string): void {
    this.title.setTitle(title);
  }
}
