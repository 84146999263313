import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BackButtonModule } from './back-button/back-button.module';
import { BidModalTextboxModule } from './bid-modal-textbox/bid-modal-textbox.module';
import { BlueButtonModule } from './blue-button/blue-button.module';
import { BlueCheckboxModule } from './blue-checkbox/blue-checkbox.module';
import { BlueFabModule } from './blue-fab/blue-fab.module';
import { BlueInputModule } from './blue-input/blue-input.module';
import { BlueLinkTextModule } from './blue-link-text/blue-link-text.module';
import { BlueSelectModule } from './blue-select/blue-select.module';
import { CarrierBannerModule } from './carrier-banner-with-carousel/carrier-banner/carrier-banner.module';
import { CarrierBookOrContactButtonModule } from './carrier-book-or-contact-button/carrier-book-or-contact-button.module';
import { CarrierMapboxModule } from './carrier-mapbox/carrier-mapbox.module';
import { ChartModule } from './chart/chart.module';
import { CountdownModule } from './countdown/countdown.module';
import { DatepickerModule } from './datepicker/datepicker.module';
import { GreyInputModule } from './grey-input/grey-input.module';
import { GreyInputChangeDetectionEnabledModule } from './grey-input-change-detection-enabled/grey-input-change-detection-enabled.module';
import { IconModule } from './icon/icon.module';
import { MapboxModule } from './mapbox/mapbox.module';
import { PasswordStrengthModule } from './password-strength/password-strength.module';
import { SearchRadiusFieldMobileModule } from './search-radius-field-mobile/search-radius-field-mobile.module';
import { SearchRadiusFieldModule } from './search-radius-field/search-radius-field.module';
import { SkeletonLoaderModule } from './skeleton-loader/skeleton-loader.module';
import { SliderModule } from './slider/slider.module';
import { SpinnerModule } from './spinner/spinner.module';
import { TermsOfServiceTextModule } from './terms-of-service-text/terms-of-service-text.module';
import { ToggleModule } from './toggle/toggle.module';
import { VirtualScrollModule } from './virtual-scroll/virtual-scroll.module';
import { VirtualTableModule } from './virtual-table/virtual-table.module';
import { WhiteLinkTextModule } from './white-link-text/white-link-text.module';
import { WhiteOutlineButtonModule } from './white-outline-button/white-outline-button.module';
import { WhiteProgressSpinnerModule } from './white-progress-spinner/white-progress-spinner.module';
import { WhiteRadioGroupModule } from './white-radio-group/white-radio-group.module';
import { WhiteReadonlyInputModule } from './white-readonly-input/white-readonly-input.module';
import { VirtualScrollSortModule } from './virtual-scroll-sort/virtual-scroll-sort.module';
import { MobileBannerModule } from './mobile-banner/mobile-banner.module';
import { InterchangeAgreementInfoModule } from './interchange-agreement-info/interchange-agreement-info.module';
import { AnnouncementBannerModule } from './announcement-banner/announcement-banner.module';
import { ActiveLoadAssignFieldModule } from './active-load-assign-field/active-load-assign-field.module';
import { CarrierLoadTableModule } from './carrier-load-table/carrier-load-table.module';

const modules = [
  BidModalTextboxModule,
  BackButtonModule,
  BlueButtonModule,
  BlueCheckboxModule,
  BlueFabModule,
  BlueInputModule,
  CarrierBannerModule,
  CarrierBookOrContactButtonModule,
  CarrierMapboxModule,
  GreyInputModule,
  GreyInputChangeDetectionEnabledModule,
  BlueLinkTextModule,
  BlueSelectModule,
  ChartModule,
  CountdownModule,
  DatepickerModule,
  IconModule,
  MapboxModule,
  PasswordStrengthModule,
  SearchRadiusFieldModule,
  SearchRadiusFieldMobileModule,
  SkeletonLoaderModule,
  SliderModule,
  SpinnerModule,
  TermsOfServiceTextModule,
  ToggleModule,
  VirtualTableModule,
  VirtualScrollSortModule,
  WhiteLinkTextModule,
  WhiteOutlineButtonModule,
  WhiteProgressSpinnerModule,
  WhiteRadioGroupModule,
  WhiteReadonlyInputModule,
  VirtualScrollModule,
  MobileBannerModule,
  InterchangeAgreementInfoModule,
  AnnouncementBannerModule,
  ActiveLoadAssignFieldModule,
  CarrierLoadTableModule,
];

@NgModule({
  imports: [CommonModule],
  exports: [...modules],
})
export class ElementsModule {}
