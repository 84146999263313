import gql from 'graphql-tag';

export const AssignLoadsServiceLoadMutation = gql(`
mutation AssignLoadsServiceLoad($loadId: StrictId!, $carrierId: StrictId!, $assignInput: AssignLoadsServiceLoadInput!) {
	assignLoadsServiceLoad(loadId: $loadId, carrierId: $carrierId, assignInput: $assignInput) {
	id
    drivers {
      id
    }
    truck {
      id
    }
    trailers {
      id
    }
	}
}
`);
