<div class="blue-button">
  <button
    data-test="blue-button"
    mat-raised-button
    [tabindex]="tabindex"
    (click)="buttonClick()"
    [disabled]="disabled || loading"
  >
    <haulynx-spinner data-cy="button-spinner" [active]="true" [diameter]="24" *ngIf="loading"></haulynx-spinner>

    <div *ngIf="!loading" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="4px" class="blue-button-contents">
      <haulynx-icon *ngIf="iconPosition === 'before'" [icon]="icon" [svgIcon]="svgIcon"></haulynx-icon>
      <span *ngIf="text" data-cy="button-text">{{ text }}</span>
      <haulynx-icon *ngIf="iconPosition === 'after'" [icon]="icon" [svgIcon]="svgIcon"></haulynx-icon>
    </div>
  </button>
</div>
