import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';

interface IRadiusGroupComponent {
  label: string;
  action: string;
}

@Component({
  selector: 'radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioGroupComponent implements OnChanges {
  @Input() selected;
  @Input() radios: IRadiusGroupComponent[] = [];
  @Output() onChange = new EventEmitter<string>();

  select = null;

  change(action: MatRadioChange) {
    this.onChange.emit(action.value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { selected } = changes;

    if (selected) {
      this.select = selected.currentValue;
    }
  }
}
