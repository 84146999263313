import { BulkActionPage } from '../bulk-actions/load-bulk-actions.enum';
import { IColumns2 } from '../data-table-v2/data-table-v2-type';
import { DataTableConfig } from '../data-table/data-table-type';
import { ColumnField } from '../search-view/default-columns';

import { CityFilter, CustomerNameFilter, CustomerNumberFilter, StateFilter } from './models';

export const trailerSearchFilters = [new CustomerNameFilter(), new CustomerNumberFilter(), new CityFilter()];

export const customerDataColumns = (
  columnSettings: Array<{ key: string; value: boolean; order?: number }> = []
): IColumns2[] => {
  const allColumns: IColumns2[] = [
    {
      field: ColumnField.COMPANY,
      label: 'Company',
      isSortable: false,
      sortConfig: [
        {
          label: 'Company',
          dataIndex: 'updatedAt',
        },
      ],
      isCustomCell: true,
      width: '150px',
      isVisible: true,
      isPinned: true,
      order: 1,
    },
    {
      field: ColumnField.NAME,
      label: 'Name',
      isCustomCell: true,
      isSortable: false,
      width: '150px',
      sortConfig: [],
      isVisible: true,
      isPinned: true,
      order: 2,
    },
    {
      field: ColumnField.PHONER_NUMBER,
      label: 'Phone Number',
      filters: [],
      sortConfig: [],
      isCustomCell: true,
      isSortable: false,
      width: '150px',
      isVisible: true,
      order: 3,
    },
    // {
    //   field: ColumnField.USER_TYPE,
    //   label: 'Type',
    //   sortConfig: [],
    //   filters: [],
    //   isCustomCell: true,
    //   isSortable: false,
    //   width: '100px',
    //   isVisible: true,
    //   order: 4,
    // },
    {
      field: ColumnField.ADDRESS,
      label: 'Address',
      sortConfig: [],
      filters: [],
      isCustomCell: true,
      isSortable: false,
      width: 'auto',
      isVisible: true,
      order: 5,
    },
    {
      field: ColumnField.ZIPCODE,
      label: 'Zip Code',
      sortConfig: [],
      filters: [],
      isCustomCell: true,
      isSortable: false,
      width: '100px',
      isVisible: true,
      order: 6,
    },
  ];

  return allColumns.reduce((acc: IColumns2[], column: IColumns2): IColumns2[] => {
    const setting = columnSettings.find(
      (value: { key: string; value: boolean; order?: number }) => value.key === column.field
    );
    if (!setting) return [...acc, column];

    const newThing = { ...column, isVisible: setting.value, order: setting.order || column.order };
    return [...acc, newThing];

    // return setting?.value === false ? [...acc, { ...column,  isVisible: false }] : [...acc, column];
  }, []);
};

export const trailerSwapConfigOptions: DataTableConfig = {
  globalFilter: false,
  reorderColumns: false,
  columnVisibility: false,
  reorderRows: false,
  showBulkActions: false,
  sortColumns: false,
  scrollable: true,
  checkBoxSelection: true,
  filterLocally: false,
  showTopPaginator: false,
  showBottomPaginator: false,
  isLaneRate: false,
  bulkActions: BulkActionPage.NONE,
  pageAmount: 500,
};
