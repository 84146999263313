import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { BehaviorSubject, interval, Subscription } from 'rxjs';

@Component({
  selector: 'haulynx-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountdownComponent implements OnChanges, OnDestroy {
  @Input() text: string;
  @Input() format: 'mm:ss' | 'all' = 'all';
  @Input() time: number;
  @Output() running = new EventEmitter<boolean>();
  @Output() finished = new EventEmitter<void>();

  private subscription: Subscription;
  public show$ = new BehaviorSubject<boolean>(false);
  public now$ = new BehaviorSubject<number>(new Date().valueOf());

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.time) {
      this.runCountdown();
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private runCountdown() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.time - new Date().valueOf() <= 1000) {
      this.currentlyRunning(false);
      return;
    }

    this.currentlyRunning(true);
    this.subscription = interval(1000).subscribe(() => {
      this.now$.next(new Date().valueOf());

      if (this.time - new Date().valueOf() <= 1000) {
        this.currentlyRunning(false);
        return this.subscription.unsubscribe();
      }

      this.currentlyRunning(true);
    });
  }

  private currentlyRunning(value: boolean) {
    if (!value) {
      this.finished.emit();
    }

    this.running.emit(value);
    this.show$.next(value);
  }
}
