<div class="atomic-table" fxFlex fxLayout="column">
  <!-- <haulynx-contracts-form></haulynx-contracts-form> -->
  <div class="contracts-header" fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <h3>Contracts</h3>
    <haulynx-blue-button
      text="Create Contract"
      (clicked)="createClicked()"
      data-test="create-contract"
    ></haulynx-blue-button>
  </div>

  <form [formGroup]="searchForm" (ngSubmit)="addFilter()">
    <mat-form-field>
      <mat-label>Search By</mat-label>
      <mat-select panelClass="dropdown-select-contract" disableOptionCentering formControlName="filterBy">
        <mat-option value="carrierDOT">Carrier DOT</mat-option>
        <mat-option value="billTo">Bill to</mat-option>
        <mat-option value="brokerOwner">Broker ID</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <input matInput placeholder="Search Value" formControlName="searchValue" />
      <button mat-icon-button matSuffix type="submit">
        <mat-icon>add</mat-icon>
      </button>
    </mat-form-field>
  </form>

  <div class="chip-list">
    <mat-chip-list>
      <mat-chip *ngFor="let filter of filters" [removable]="true" (removed)="removeFilter(filter)">
        {{ filterDisplayMap[filter.filterBy] + ': ' + filter.searchValue }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </div>

  <haulynx-atomic-table
    [data]="data$ | async"
    [loading]="loading$ | async"
    [pagination]="paginationData$ | async"
    (pageChanged)="pageChanged($event)"
    (rowLimitChange)="rowLimitChange($event)"
    (refreshClicked)="refreshClicked()"
    (deleteClicked)="deleteClicked($event)"
    (editClicked)="editClicked($event)"
    (sortChanged)="sortChanged($event)"
    class="outer-atomic-table"
  ></haulynx-atomic-table>
</div>
