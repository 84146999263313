import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { PasswordStrengthVariation } from '@haulynx/types';

@Component({
  selector: 'haulynx-password-strength',
  templateUrl: './password-strength.component.html',
  styleUrls: ['./password-strength.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordStrengthComponent implements OnChanges {
  @Input() password: string;
  @Output() variations = new EventEmitter<PasswordStrengthVariation>();

  public score = 0;
  public variationsChecklist: PasswordStrengthVariation;

  ngOnChanges(changes: SimpleChanges): void {
    const { password } = changes;

    if (password.currentValue || password.currentValue === '') {
      this.checkPasswordStrength(password.currentValue);
    }
  }

  private checkPasswordStrength(password: string) {
    let score = 0;

    // award for required variations
    this.variationsChecklist = {
      length: /.{8}/.test(password),
      lowerCase: /[a-z]/.test(password),
      upperCase: /[A-Z]/.test(password),
      specialCharacter: /[^A-Za-z0-9 ]/.test(password),
    };
    this.variations.emit(this.variationsChecklist);

    for (const check in this.variationsChecklist) {
      score += this.variationsChecklist[check] ? 20 : 0;
    }

    if (score >= 80) {
      this.score = 4;
    } else if (score >= 60) {
      this.score = 3;
    } else if (score >= 40) {
      this.score = 2;
    } else if (score >= 20) {
      this.score = 1;
    } else {
      this.score = 0;
    }
  }
}
