import { BulkActionPage, DataTableConfig } from '@haulynx/types';

export const recommendedColumns = [
  {
    field: 'name',
    isVisible: true,
    isClickable: true,
    isCustomCell: true,
    label: 'Carrier Name',
    isSortable: true,
    width: '200px',
    sortConfig: [
      {
        dataIndex: 'name',
        label: 'Name',
      },
    ],
  },
  {
    field: 'brokerId',
    isVisible: true,
    label: 'Broker ID',
    isClickable: false,
    isCustomCell: false,
    isSortable: true,
    width: '140px',
    sortConfig: [
      {
        dataIndex: 'brokerId',
        label: 'Broker Id',
      },
    ],
  },
  {
    field: 'miles',
    isVisible: true,
    label: '$/Miles',
    isClickable: false,
    isCustomCell: true,
    isSortable: true,
    width: '100px',
    sortConfig: [
      {
        dataIndex: 'price',
        label: 'Price',
      },
      {
        dataIndex: 'load.distance',
        label: 'Distance',
      },
    ],
  },
  {
    field: 'originDestination',
    isVisible: true,
    label: 'Origin & Dest',
    isClickable: false,
    isCustomCell: true,
    isSortable: true,
    width: '230px',
    sortConfig: [
      {
        label: 'Origin',
        dataIndex: 'load.loadLocations.0.summaryAddress',
      },
      {
        label: 'Destination',
        dataIndex: 'load.loadLocations.length.summaryAddress',
      },
    ],
  },
  {
    field: 'pickup',
    isVisible: true,
    label: 'Pickup',
    isClickable: false,
    isSortable: true,
    isCustomCell: true,
    width: '100px',
    sortConfig: [
      {
        label: 'Pickup date',
        dataIndex: 'load.loadLocations.0.appointmentStart',
      },
    ],
  },
  {
    field: 'actions',
    isVisible: true,
    label: '',
    isClickable: false,
    isSortable: false,
    isCustomCell: true,
    isSticky: true,
    width: '250px',
    sortConfig: [],
  },
];

export const notRecommendedColumns = [
  {
    field: 'name',
    isVisible: true,
    isClickable: true,
    isCustomCell: true,
    label: 'Carrier Name',
    isSortable: true,
    width: '200px',
    sortConfig: [
      {
        dataIndex: 'name',
        label: 'Name',
      },
    ],
  },
  {
    label: 'Ownership',
    field: 'owner',
    isVisible: true,
    width: '125px',
    isCustomCell: true,
    isSortable: true,
    sortConfig: [
      {
        dataIndex: 'owner',
        label: 'Ownership',
      },
    ],
  },
  {
    label: 'Type',
    field: 'sourceType',
    isVisible: true,
    isCustomCell: false,
    width: '100px',
    isSortable: true,
    sortConfig: [
      {
        label: 'Source Type',
        dataIndex: 'sourceType',
      },
    ],
  },
  {
    label: 'DOT',
    field: 'dot',
    isVisible: true,
    width: '100px',
    isCustomCell: false,
    isSortable: true,
    sortConfig: [
      {
        label: 'DOT',
        dataIndex: 'dot',
      },
    ],
  },
  {
    label: 'Contact Name',
    field: 'adminName',
    isVisible: true,
    width: '175px',
    isCustomCell: false,
    isSortable: true,
    sortConfig: [
      {
        label: 'Contact Name',
        dataIndex: 'adminName',
      },
    ],
  },
  {
    field: 'actions',
    width: '250px',
    isVisible: true,
    isCustomCell: true,
    label: '',
    isSortable: false,
    isSticky: true,
    sortConfig: [],
  },
];

export const loadConfigOptions: DataTableConfig = {
  globalFilter: true,
  reorderColumns: true,
  columnVisibility: false,
  reorderRows: false,
  showBulkActions: false,
  sortColumns: true,
  scrollable: true,
  checkBoxSelection: false,
  filterLocally: false,
  showTopPaginator: false,
  showBottomPaginator: false,
  isLaneRate: false,
  bulkActions: BulkActionPage.SEARCH,
  pageAmount: 25,
};
