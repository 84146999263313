import { ImageComponent } from './image.component';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [ImageComponent],
  imports: [],
  exports: [ImageComponent],
  providers: [],
})
export class ImageModule {}
