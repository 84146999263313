import { Injectable } from '@angular/core';
import {
  BidsService,
  CarrierService,
  CarriersService,
  CustomersService,
  LoadFeedService,
  NotificationsService,
} from '@haulynx/services';
import {
  AssignLoadCarrierForm,
  Bid,
  BidInput,
  BidHistory,
  BidSourceType,
  BidStatus,
  CarrierMatch,
  CounterOffer,
  CounterOfferInput,
  FeatureFlag,
  GraphqlSearchResponse,
  LoadIdentifierType,
  LoadsServiceCustomer,
  LoadsServiceLoad,
  NotificationEvent,
  RecommendedCarriers,
  UpdateBidInput,
  User,
} from '@haulynx/types';
import { getLoadsServiceLoadAlternateId } from '@haulynx/utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { get, isNull, sortBy, toNumber, toString } from 'lodash';
import { of } from 'rxjs';
import { catchError, map, scan, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { UserEntityService } from '../../async-entity-models/user';
import { AppModel } from '../../main-store/app-model';
import { AppState } from '../../main-store/app.reducers';
import { DispatchAction } from '../../shared/store/helpers/action';
import { NotificationModel } from '../app-notification/notification-model';
import { LoadDetailsModel } from '../load-details/load-details-model';
import { LoadFeedModel } from './load-feed-model';
import {
  BidActions,
  BidActionTypes,
  CarrierLoadFeedActions,
  CarrierLoadFeedActionTypes,
  ExclusiveLoadFeedActions,
  ExclusiveLoadFeedActionTypes,
  LoadFeedActions,
  LoadFeedActionTypes,
} from './load-feed.actions';
import { bidSearchSelector, carrierLoadFeedSearchSelector } from './load-feed.selectors';

@Injectable({ providedIn: 'root' })
export class LoadFeedEffects {
  loadExclusiveSearch = createEffect(() =>
    this.actions$.pipe(
      ofType(ExclusiveLoadFeedActionTypes.SEARCH),
      map((action: DispatchAction) => action.payload),
      switchMap((payload) => {
        const { carrierId } = payload;
        return this.loadFeedService.getRecommendedLoadFeeds(carrierId).pipe(
          map((loadFeeds) => {
            const entities = loadFeeds.filter(
              (loadFeed) => loadFeed.exclusiveLoad && Number(loadFeed.exclusiveUntil) > Date.now()
            );

            return ExclusiveLoadFeedActions.searchSuccess({ entities });
          }),
          catchError((errors) => of(ExclusiveLoadFeedActions.searchError(errors)))
        );
      })
    )
  );

  carrierLoadFeed = createEffect(() =>
    this.actions$.pipe(
      ofType(CarrierLoadFeedActionTypes.SEARCH),
      map((action: DispatchAction) => action.payload),
      withLatestFrom(
        this.store.select(carrierLoadFeedSearchSelector.getQuery),
        this.store.select(carrierLoadFeedSearchSelector.getPagination),
        this.appModel.brokerId$,
        this.userEntityService.featureFlags$
      ),
      switchMap(([payload, query, pagination, brokerId, flags]) => {
        const { loadId, carrierSearch } = query;
        const { total, ...other } = pagination;

        if (carrierSearch) {
          return this.getCarriersQueryService
            .searchNonLeadCarriers({
              search: carrierSearch,
              ...query,
              paging: other,
            })
            .pipe(
              map((data) => CarrierLoadFeedActions.searchSuccess(data)),
              catchError((errors) => of(CarrierLoadFeedActions.searchError(errors)))
            );
        } else {
          if (flags && flags[FeatureFlag.NEW_CARRIER_RECOMMENDATIONS]) {
            return this.getCarriersQueryService.getRecommendedCarriersByLoadId(loadId, other).pipe(
              map((data: GraphqlSearchResponse<RecommendedCarriers>) => {
                const { entities, ...otherFilter } = data;

                return CarrierLoadFeedActions.searchSuccess({ entities, ...otherFilter });
              }),
              catchError((err) => of(CarrierLoadFeedActions.searchError(err)))
            );
          } else {
            return this.getCarriersQueryService.getCarrierMatchesByLoadAndBrokerId(loadId, brokerId, other).pipe(
              map((data: GraphqlSearchResponse<CarrierMatch>) => {
                const { entities, ...otherFilter } = data;

                return CarrierLoadFeedActions.searchSuccess({ entities, ...otherFilter });
              }),
              catchError((err) => of(CarrierLoadFeedActions.searchError(err)))
            );
          }
        }
      })
    )
  );

  getCarrier = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadFeedActionTypes.GET_CARRIER),
      map((action: DispatchAction) => action.payload),
      switchMap((payload) => {
        const { carrierId } = payload;

        return this.carrierService.getCarrier(carrierId).pipe(
          map((carrier) => {
            return LoadFeedActions.getCarrierSuccess(false);
          }),
          catchError((errors) => of(LoadFeedActions.getCarrierError(errors)))
        );
      })
    )
  );

  bidSearch = createEffect(() =>
    this.actions$.pipe(
      ofType(BidActionTypes.SEARCH),
      map((action: DispatchAction) => action.payload),
      withLatestFrom(
        this.store.select(bidSearchSelector.getQuery).pipe(
          scan(
            (acc: string, curr: { loadId: string }) => curr.loadId || acc, // retrieves the valid last loadId from previous bid searches
            null
          )
        )
      ),
      switchMap(([payload, lastLoadId]: [{ loadId: string }, string]) => {
        const loadId: string = get(payload, 'loadId', lastLoadId);
        return this.bidsService.getBidsByLoadId({ loadId }).pipe(
          withLatestFrom(this.userEntityService.featureFlags$),
          map(([{ data }, featureFlagState]: [any, { [key: string]: any }]) => {
            const result: Bid[] = data.getBidsByLoadId || [];
            const filteredResult = !featureFlagState[FeatureFlag.CARRIER_BID]
              ? result.filter((bid) => bid.sourceType !== BidSourceType.CARRIER)
              : result;
            const total = filteredResult.length;

            return BidActions.searchSuccess({ entities: filteredResult, total });
          }),
          catchError((errors) => {
            this.notificationsService.error('There was a problem retrieving offers', 'Offer Search');
            return of(BidActions.searchError(errors));
          })
        );
      })
    )
  );

  createBid = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadFeedActionTypes.CREATE_BID),
      map((action: DispatchAction) => action.payload),
      switchMap((payload: { bid: Bid; load: LoadsServiceLoad; price?: number; notes?: string }) => {
        const { loadId, price, notes } = payload.bid;
        const load: LoadsServiceLoad = payload.load;
        payload = { ...payload, price: toNumber(price), notes: toString(notes) };

        return this.carriersService.tryFindCarrierIdByDot(payload.bid.carrier.dot).pipe(
          switchMap((carrierId: string) => {
            const newBid: BidInput = {
              brokerId: payload.bid.brokerId,
              price: payload.price,
              status: payload.bid.status,
              loadId: payload.bid.loadId,
              notes: payload.notes,
              sourceType: payload.bid.sourceType,
              carrierId,
            };
            const analyticData = {
              bookItPrice: toNumber(payload.price),
              loadOrderNum:
                getLoadsServiceLoadAlternateId(load, LoadIdentifierType.BILL_OF_LADING) ??
                load?.['loadLocations']?.[0].billOfLading,
              loadTmwNum: getLoadsServiceLoadAlternateId(load, LoadIdentifierType.TMW_NUMBER) ?? load['tmwNumber'],
              bidPrice: toNumber(payload.bid.price),
              loadId: load.id,
            };
            return this.bidsService.createBid({ newBid }).pipe(
              map((bid) => LoadFeedActions.createBidSuccess({ newBid, analyticData })),
              catchError((errors) => {
                const errorMessage = get(
                  'networkError.errors[0]extensions.response.error',
                  'Oops, something went wrong. Unable to create a bid!'
                );

                this.notificationsService.error(errorMessage, `Bids`);
                return of(LoadFeedActions.createBidError(errors));
              })
            );
          })
        );
      })
    )
  );

  createBidSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoadFeedActionTypes.CREATE_BID_SUCCESS, LoadFeedActionTypes.CREATE_COUNTER_OFFER_SUCCESS),
        map((action: DispatchAction) => action.payload),
        withLatestFrom(this.notificationModel.notifications$, this.appModel.user$),
        tap(
          ([payload, notifications, user]: [
            { newBid: Bid; counterOffer?: CounterOffer; bidId: string },
            NotificationEvent[],
            User
          ]) => {
            this.loadFeedModel.searchBids(payload.newBid?.loadId);

            // Deactivate notifications that relate to the newly created counter offer.
            if (payload.counterOffer) {
              const relatedNotifications: NotificationEvent[] = notifications.filter(
                (notification) => notification.secondaryTargetObjectId === (payload.counterOffer.bidId || payload.bidId)
              );
              if (relatedNotifications) {
                this.notificationModel.deactivateNotifications(
                  user.id,
                  relatedNotifications.map((notification) => notification.id)
                );
              }
            }
          }
        )
      ),
    { dispatch: false }
  );

  bidStatuses = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadFeedActionTypes.GET_BID_STATUSES),
      map((action: DispatchAction) => action.payload),
      switchMap(() =>
        this.bidsService.getBidStatuses().pipe(
          map((entities: BidStatus[]) => LoadFeedActions.getBidStatusesSuccess(entities)),
          catchError((errors) => of(LoadFeedActions.getBidStatusesError(errors)))
        )
      )
    )
  );

  bidHistory = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadFeedActionTypes.GET_BID_HISTORY),
      map((action: DispatchAction) => action.payload),
      switchMap((payload: { bidId: string }) => {
        const { bidId } = payload;

        return this.bidsService.getBidHistory({ bidId }).pipe(
          map((entities: BidHistory[]) => {
            entities = sortBy(entities, ['createdAt']);
            return LoadFeedActions.getBidHistorySuccess({ entities, bidId });
          }),
          catchError((errors) => of(LoadFeedActions.getBidHistoryError({ errors, bidId })))
        );
      })
    )
  );

  updateBid = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadFeedActionTypes.UPDATE_BID),
      map((action: DispatchAction) => action.payload),
      switchMap((payload: { bid: Bid; load: LoadsServiceLoad }) => {
        const bid = payload.bid;
        const { id: bidId } = bid;
        const bidUpdate = {
          price: bid.price,
          notes: isNull(bid.notes) ? '' : bid.notes,
          status: bid.status,
          brokerId: bid.brokerId,
          finalStateFrom: bid?.finalStateFrom,
        } as UpdateBidInput;
        const load: LoadsServiceLoad = payload.load;
        const analyticData = {
          bookItPrice: toNumber(payload.load.paymentDetails.price),
          loadOrderNum: getLoadsServiceLoadAlternateId(payload.load, LoadIdentifierType.BILL_OF_LADING),
          loadTmwNum: getLoadsServiceLoadAlternateId(payload.load, LoadIdentifierType.TMW_NUMBER),
          bidPrice: toNumber(payload.bid.price),
          loadId: payload.load.id,
        };

        return this.bidsService.updateBid({ bidId, bidUpdate }).pipe(
          map(() => {
            this.loadFeedModel.getBidHistory(bidId);
            return LoadFeedActions.updateBidSuccess(analyticData);
          }),
          catchError((error) => {
            this.notificationsService.error(error.message, `Bids`);
            return of(LoadFeedActions.updateBidError(error));
          })
        );
      })
    )
  );

  acceptBid = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadFeedActionTypes.ACCEPT_BID),
      map((action: DispatchAction) => action.payload),
      switchMap((payload: { bid: Partial<Bid>; assignmentData: Partial<AssignLoadCarrierForm> }) => {
        const loadId = payload.bid.loadId;

        return this.bidsService.acceptBid({ bidId: payload.bid.id }).pipe(
          map((bid) => {
            this.loadDetailsModel.bookForCarrierLoad(
              loadId,
              bid.carrier.dot,
              payload.assignmentData,
              payload.bid.brokerId
            );
            this.loadFeedModel.searchBids(loadId);
            return LoadFeedActions.acceptBidSuccess({ bidId: payload.bid.id });
          }),
          catchError((errors) => of(LoadFeedActions.acceptBidError({ errors, bidId: payload.bid.id })))
        );
      })
    )
  );

  acceptCounterBid = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadFeedActionTypes.ACCEPT_COUNTER_BID),
      map((action: DispatchAction) => action.payload),
      switchMap((payload) => {
        const counterOfferId = payload.carrierBid.activeCounterOffer.id;
        const analyticData = {
          bookItPrice: toNumber(payload?.paymentDetails?.price),
          loadOrderNum: getLoadsServiceLoadAlternateId(payload, LoadIdentifierType.ORDER_NUMBER),
          loadTmwNum: getLoadsServiceLoadAlternateId(payload, LoadIdentifierType.TMW_NUMBER),
          counterPrice: toNumber(payload.carrierBid.activeCounterOffer.price),
          loadId: payload.id,
        };

        return this.bidsService.acceptCounterBid({ counterOfferId }).pipe(
          map(() => LoadFeedActions.acceptCounterBidSuccess(analyticData)),
          catchError((errors) => of(LoadFeedActions.acceptCounterBidError({ errors, counterOfferId })))
        );
      })
    )
  );

  getCustomer = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadFeedActionTypes.GET_CUSTOMER),
      map((action: DispatchAction) => action.payload),
      switchMap((payload: { id: string }) => {
        const { id } = payload;

        return this.customersService.getCustomerById({ id }).pipe(
          map((entity: LoadsServiceCustomer) => LoadFeedActions.getCustomerSuccess({ id, entity })),
          catchError((errors) => {
            this.notificationsService.error('Error getting facility info', `Facility Info`);
            return of(LoadFeedActions.getCustomerError({ id, errors }));
          })
        );
      })
    )
  );

  createCounterOffer = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadFeedActionTypes.CREATE_COUNTER_OFFER),
      map((action: DispatchAction) => action.payload),
      switchMap((payload: CounterOfferInput) => {
        const { bidId, price } = payload;
        const newCounterOfferInput = { ...payload, price: Number(price) };

        return this.bidsService.createCounterOffer({ bidId, newCounterOfferInput }).pipe(
          map((counterOffer) => {
            this.notificationsService.success('Counter offer was created!', 'Counter Offer');
            return LoadFeedActions.createCounterOfferSuccess({ bidId, counterOffer });
          }),
          catchError((errors) => {
            this.notificationsService.error('There was a problem creating your counter offer.', 'Counter Offer');
            return of(LoadFeedActions.createCounterOfferError({ errors, bidId }));
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private notificationsService: NotificationsService,
    private store: Store<AppState>,
    private loadFeedModel: LoadFeedModel,
    private loadFeedService: LoadFeedService,
    private carrierService: CarrierService,
    private appModel: AppModel,
    private getCarriersQueryService: CarriersService,
    private bidsService: BidsService,
    private customersService: CustomersService,
    private carriersService: CarriersService,
    private notificationModel: NotificationModel,
    private loadDetailsModel: LoadDetailsModel,
    private userEntityService: UserEntityService
  ) {}
}
