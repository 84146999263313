import { BrokerTeamId } from '../../loads-service';

export const states = [
  { name: 'Alabama', code: 'AL' },
  { name: 'Alaska', code: 'AK' },
  { name: 'Arizona', code: 'AZ' },
  { name: 'Arkansas', code: 'AR' },
  { name: 'California', code: 'CA' },
  { name: 'Colorado', code: 'CO' },
  { name: 'Connecticut', code: 'CT' },
  { name: 'Delaware', code: 'DE' },
  { name: 'District of Columbia', code: 'DC' },
  { name: 'Florida', code: 'FL' },
  { name: 'Georgia', code: 'GA' },
  { name: 'Hawaii', code: 'HI' },
  { name: 'Idaho', code: 'ID' },
  { name: 'Illinois', code: 'IL' },
  { name: 'Indiana', code: 'IN' },
  { name: 'Iowa', code: 'IA' },
  { name: 'Kansas', code: 'KS' },
  { name: 'Kentucky', code: 'KY' },
  { name: 'Louisiana', code: 'LA' },
  { name: 'Maine', code: 'ME' },
  { name: 'Maryland', code: 'MD' },
  { name: 'Massachusetts', code: 'MA' },
  { name: 'Michigan', code: 'MI' },
  { name: 'Minnesota', code: 'MN' },
  { name: 'Mississippi', code: 'MS' },
  { name: 'Missouri', code: 'MO' },
  { name: 'Montana', code: 'MT' },
  { name: 'Nebraska', code: 'NE' },
  { name: 'Nevada', code: 'NV' },
  { name: 'New Hampshire', code: 'NH' },
  { name: 'New Jersey', code: 'NJ' },
  { name: 'New Mexico', code: 'NM' },
  { name: 'New York State', code: 'NY' },
  { name: 'New York', code: 'NY' },
  { name: 'North Carolina', code: 'NC' },
  { name: 'North Dakota', code: 'ND' },
  { name: 'Ohio', code: 'OH' },
  { name: 'Oklahoma', code: 'OK' },
  { name: 'Oregon', code: 'OR' },
  { name: 'Pennsylvania', code: 'PA' },
  { name: 'Puerto Rico', code: 'PR' },
  { name: 'Rhode Island', code: 'RI' },
  { name: 'South Carolina', code: 'SC' },
  { name: 'South Dakota', code: 'SD' },
  { name: 'Tennessee', code: 'TN' },
  { name: 'Texas', code: 'TX' },
  { name: 'Utah', code: 'UT' },
  { name: 'Vermont', code: 'VT' },
  { name: 'Virginia', code: 'VA' },
  { name: 'Washington', code: 'WA' },
  { name: 'West Virginia', code: 'WV' },
  { name: 'Wisconsin', code: 'WI' },
  { name: 'Wyoming', code: 'WY' },
];

export const mexicoStates = [
  { name: 'AGUASCALIENTES', code: 'AG' },
  { name: 'BAJA CALIFORNIA NORTE', code: 'BN' },
  { name: 'BAJA CALIFORNIA SUR', code: 'BS' },
  { name: 'COAHUILA', code: 'CH' },
  { name: 'CHIHUAHUA', code: 'CI' },
  { name: 'COLIMA', code: 'CL' },
  { name: 'CAMPECHE', code: 'CP' },
  { name: 'CHIAPAS', code: 'CS' },
  { name: 'DURANGO', code: 'DG' },
  { name: 'GUERRERO', code: 'GE' },
  { name: 'GUANAJUATO', code: 'GJ' },
  { name: 'HIDALGO', code: 'HD' },
  { name: 'JALISCO', code: 'JA' },
  { name: 'MICHOACÁN', code: 'MC' },
  { name: 'MORELOS', code: 'MR' },
  { name: 'MÉXICO', code: 'MX' },
  { name: 'MEXICO CITY', code: 'DF' },
  { name: 'NAYARIT', code: 'NA' },
  { name: 'NUEVO LEÓN', code: 'NL' },
  { name: 'OAXACA', code: 'OA' },
  { name: 'PUEBLA', code: 'PU' },
  { name: 'QUERETARO', code: 'QE' },
  { name: 'QUINTANA ROO', code: 'QI' },
  { name: 'SINALOA', code: 'SI' },
  { name: 'SAN LUIS POTOSÍ', code: 'SL' },
  { name: 'SONORA', code: 'SO' },
  { name: 'TAMAULIPAS', code: 'TA' },
  { name: 'TABASCO', code: 'TB' },
  { name: 'TLAXCALA', code: 'TL' },
  { name: 'VERACRUZ', code: 'VC' },
  { name: 'YUCATÁN', code: 'YU' },
  { name: 'ZACATECAS', code: 'ZA' },
];

export const canadaStates = [
  { name: 'ALBERTA', code: 'AB' },
  { name: 'BRITISH COLUMBIA', code: 'BC' },
  { name: 'MANITOBA', code: 'MB' },
  { name: 'NEW BRUNSWICK', code: 'NB' },
  { name: 'NEWFOUNDLAND AND LABRADOR', code: 'NF' },
  { name: 'NOVA SCOTIA', code: 'NS' },
  { name: 'NORTHWEST TERRITORIES', code: 'NT' },
  { name: 'NUNAVUT', code: 'NU' },
  { name: 'ONTARIO', code: 'ON' },
  { name: 'PRINCE EDWARD ISLAND', code: 'PE' },
  { name: 'QUEBEC', code: 'QC' },
  { name: 'SASKATCHEWAN', code: 'SK' },
  { name: 'YUKON TERRITORY', code: 'YT' },
];

export const jurisdictions: { code: string; id: string }[] = [
  { code: 'AL', id: 'AL - Alabama' },
  { code: 'AK', id: 'AK - Alaska' },
  { code: 'AZ', id: 'AZ - Arizona' },
  { code: 'AR', id: 'AR - Arkansas' },
  { code: 'CA', id: 'CA - California' },
  { code: 'CO', id: 'CO - Colorado' },
  { code: 'CT', id: 'CT - Connecticut' },
  { code: 'DC', id: 'DC - District of Columbia' },
  { code: 'DE', id: 'DE - Delaware' },
  { code: 'FL', id: 'FL - Florida' },
  { code: 'GA', id: 'GA - Georgia' },
  { code: 'HI', id: 'HI - Hawaii' },
  { code: 'ID', id: 'ID - Idaho' },
  { code: 'IL', id: 'IL - Illinois' },
  { code: 'IN', id: 'IN - Indiana' },
  { code: 'IA', id: 'IA - Iowa' },
  { code: 'KS', id: 'KS - Kansas' },
  { code: 'KY', id: 'KY - Kentucky' },
  { code: 'LA', id: 'LA - Louisiana' },
  { code: 'ME', id: 'ME - Maine' },
  { code: 'MD', id: 'MD - Maryland' },
  { code: 'MA', id: 'MA - Massachusetts' },
  { code: 'MI', id: 'MI - Michigan' },
  { code: 'MN', id: 'MN - Minnesota' },
  { code: 'MS', id: 'MS - Mississippi' },
  { code: 'MO', id: 'MO - Missouri' },
  { code: 'MT', id: 'MT - Montana' },
  { code: 'NE', id: 'NE - Nebraska' },
  { code: 'NV', id: 'NV - Nevada' },
  { code: 'NH', id: 'NH - New Hampshire' },
  { code: 'NJ', id: 'NJ - New Jersey' },
  { code: 'NM', id: 'NM - New Mexico' },
  { code: 'NY', id: 'NY - New York' },
  { code: 'NC', id: 'NC - North Carolina' },
  { code: 'ND', id: 'ND - North Dakota' },
  { code: 'OH', id: 'OH - Ohio' },
  { code: 'OK', id: 'OK - Oklahoma' },
  { code: 'OR', id: 'OR - Oregon' },
  { code: 'PA', id: 'PA - Pennsylvania' },
  { code: 'RI', id: 'RI - Rhode Island' },
  { code: 'SC', id: 'SC - South Carolina' },
  { code: 'SD', id: 'SD - South Dakota' },
  { code: 'TN', id: 'TN - Tennessee' },
  { code: 'TX', id: 'TX - Texas' },
  { code: 'UT', id: 'UT - Utah' },
  { code: 'VT', id: 'VT - Vermont' },
  { code: 'VA', id: 'VA - Virginia' },
  { code: 'WA', id: 'WA - Washington' },
  { code: 'WV', id: 'WV - West Virgina' },
  { code: 'WI', id: 'WI - Wisconsin' },
  { code: 'WY', id: 'WY - Wyoming' },
  { code: 'AB', id: 'AB - Alberta' },
  { code: 'BC', id: 'BC - British Columbia' },
  { code: 'MB', id: 'MB - Manitoba' },
  { code: 'NB', id: 'NB - New Brunswick' },
  { code: 'NF', id: 'NF - Newfoundland and Labrador' },
  { code: 'NS', id: 'NS - Nova Scotia' },
  { code: 'NT', id: 'NT - Northwest Territories' },
  { code: 'NU', id: 'NU - Nunavut' },
  { code: 'ON', id: 'ON - Ontario' },
  { code: 'PE', id: 'PE - Prince Edward Island' },
  { code: 'QC', id: 'QC - Quebec' },
  { code: 'SK', id: 'SK - Saskatchewan' },
];

export const hosRules = [
  { code: 0, text: '70 Hours / 8 Days' },
  { code: 1, text: '60 Hours / 7 Days' },
];

export const equipmentTypes = [
  { selected: false, text: 'Flat Bed' },
  { selected: false, text: 'Dry Van' },
  { selected: false, text: 'Reefer' },
  { selected: false, text: 'Dry Van or Reefer' },
  { selected: false, text: 'Lowboy' },
  { selected: false, text: 'Step Deck' },
  { selected: false, text: '40 ft. Hot Shot' },
  { selected: false, text: 'Other' },
  // The following options are being deleted for the time being
  // { selected: false, text: 'Car Hauler' },
  // { selected: false, text: 'Power Only' },
];

export const insuranceOptions = [0, 100000, 200000, 500000, 750000, 1000000];

export const timeZones = [
  'Pacific/Honolulu',
  'America/Anchorage',
  'America/Vancouver',
  'America/Los_Angeles',
  'America/Phoenix',
  'America/Denver',
  'America/Denver',
  'America/Chicago',
  'America/New_York',
  'America/Puerto_Rico',
];

export const rejectReasons = [
  { selected: false, text: 'Other' },
  { selected: false, text: 'Broker' },
  { selected: false, text: 'Destination' },
  { selected: false, text: 'Distance' },
  { selected: false, text: 'Load Type' },
  { selected: false, text: 'Price' },
  { selected: false, text: 'Weight' },
  { selected: false, text: 'Pickup Location' },
];

export const radiusOptions = [
  { selected: false, label: '10', id: 10 },
  { selected: false, label: '25', id: 25 },
  { selected: false, label: '50', id: 50 },
  { selected: false, label: '75', id: 75 },
  { selected: false, label: '100', id: 100 },
  { selected: false, label: '150', id: 150 },
  { selected: false, label: '200', id: 200 },
  { selected: false, label: '500', id: 500 },
];

export enum VehicleEntityTypes {
  TRACKING_TYPES = 'trackingTypes',
  DRIVERS = 'drivers',
  TRUCKS = 'trucks',
  TRAILERS = 'trailers',
}

export enum EntityOptions {
  ADD_DRIVER = 'Add New Driver +',
  ADD_TRUCK = 'Add New Truck +',
  ADD_TRAILER = 'Add New Trailer +',
}

export enum BidSortTypes {
  PRICE = 'price',
  RECENT = 'createdAt',
  CARRIER = 'carrier.name',
}

export const bidSortOptions = [
  { label: 'Price', value: BidSortTypes.PRICE },
  { label: 'Recent', value: BidSortTypes.RECENT },
  { label: 'Carrier', value: BidSortTypes.CARRIER },
];

export const brokerTeams: { value: keyof typeof BrokerTeamId; key: string }[] = [
  { value: 'CHICG', key: 'Chicago' },
  { value: 'INSIDE', key: 'Inside' },
  { value: 'MINNY', key: 'Minny' },
  { value: 'NORTH', key: 'North' },
  { value: 'ONEST', key: 'OneStar' },
  { value: 'SOUTH', key: 'South' },
  { value: 'USXCX', key: 'USXCX' },
  { value: 'WESTP', key: 'West' },
];
