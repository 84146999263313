import gql from 'graphql-tag';

export const UpdateMilestoneComment = gql`
  mutation updateMilestoneComment(
    $loadId: StrictId!
    $milestoneId: StrictId!
    $commentId: StrictId!
    $commentInput: MilestoneCommentInput!
  ) {
    updateMilestoneComment(
      loadId: $loadId
      milestoneId: $milestoneId
      commentId: $commentId
      commentInput: $commentInput
    ) {
      id
      milestones {
        authorId
        authorType
        comments {
          contact
          creatorId
          creatorType
          id
          text
          timestamp
          timezone
        }
        contact {
          email
          name
          phone
        }
        createdAt
        id
        lastUpdated
        loadLocationAddress
        location {
          latitude
          longitude
          timestamp
          timezone
        }
        locationId
        logs {
          editedBy
          editedByType
          id
          primaryEvent {
            latitude
            longitude
            timestamp
            timezone
          }
          secondaryEvent {
            latitude
            longitude
            timestamp
            timezone
          }
          subType
          timestamp
          status
        }
        type
      }
    }
  }
`;
