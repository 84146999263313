import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@haulynx/directives';
import { MaterialModule } from '@haulynx/modules';
import { PaymentDetailsVmService } from '@haulynx/services';
import { NgxCurrencyModule } from 'ngx-currency';
import { PaymentDetailsComponent } from './payment-details.component';

@NgModule({
  declarations: [PaymentDetailsComponent],
  imports: [CommonModule, ReactiveFormsModule, MaterialModule, DirectivesModule, NgxCurrencyModule],
  exports: [PaymentDetailsComponent],
  providers: [PaymentDetailsVmService, CurrencyPipe],
})
export class PaymentDetailsModule {}
