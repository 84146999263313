import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CommonEntitiesEffects } from '../../shared/common-entities/common-entities.effects';
import { laneRateNamespace } from '../namespace';
import { laneRateStateProviders, LANE_RATE_ENTITY_REDUCERS_TOKEN } from './config';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(laneRateNamespace, LANE_RATE_ENTITY_REDUCERS_TOKEN),
    EffectsModule.forFeature([CommonEntitiesEffects]),
  ],
  providers: [...laneRateStateProviders],
})
export class AsyncEntityLaneRateStoreModule {}
