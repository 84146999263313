<form [formGroup]="timestampForm" class="form timestamp-form">
  <div class="timestamp-form__field">
    <input
      matInput
      formControlName="time"
      placeholder="{{ label }} Time"
      type="time"
      [min]="timestampForm.get('time').value"
    />
    <mat-icon class="schedule" matSuffix>schedule</mat-icon>
  </div>
</form>
