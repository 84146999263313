import { LngLatLike } from 'mapbox-gl';
import { LoadRouteFeature } from './load-route-feature';
import { LoadWayPoint } from './load-way-point';
import { MarkerStyles } from './marker-styles';

export interface LoadRoute {
  id: string;
  source: {
    type: string;
    data: {
      type: string;
      features: LoadRouteFeature[];
    };
  };
  truckRoute: {
    type: string;
    data: {
      type: string;
      features: LoadRouteFeature[];
    };
  };
  wayPoints: LoadWayPoint[];
  truckPosition: LngLatLike;
  lineColor: string;
  lineWidth: number;
  truckLineWidth: number;
  markerColor: string;
  angle: number;
  markerStyle?: MarkerStyles;
}
