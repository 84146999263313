import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { LoadAlternateIdPipe } from '@haulynx/pipes';
import { AnalyticsService, AssignLoadCarrierFormVm } from '@haulynx/services';
import {
  ANALYTICS_EVENT,
  AssignLoadCarrierForm,
  AssignLoadCarrierFormMode,
  Carrier,
  CarrierAdmin,
  CarrierContacts,
  CarrierContactTypes,
  CarrierSearchResult,
  Driver,
  DriverAssignment,
  EquipmentTypes,
  FeatureFlag,
  FFState,
  LoadIdentifierType,
  LoadsServiceLoad,
  PaymentsTypeForm,
  PaymentType,
  RateConEmail,
  Trailer,
  TrailerAssignment,
  Truck,
  TruckAssignment,
} from '@haulynx/types';
import { aliveWhile, canBookLoad, listToArray } from '@haulynx/utils';
import { List } from 'immutable';
import { get, isEmpty } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { AddDriverComponent } from '../pay-line-items/add-driver/add-driver.component';
import { AddPaymentTypeComponent } from '../pay-line-items/add-payment-type/add-payment-type.component';
import { AddTrailerComponent } from '../pay-line-items/add-trailer/add-trailer.component';
import { AddTruckComponent } from '../pay-line-items/add-truck/add-truck.component';
import { ConfirmBookLoadComponent } from '../pay-line-items/confirm-book-load/confirm-book-load.component';

@Component({
  selector: 'app-assign-load-carrier-form',
  templateUrl: './assign-load-carrier-form.component.html',
  styleUrls: ['./assign-load-carrier-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssignLoadCarrierFormComponent implements OnChanges, OnInit, OnDestroy {
  @Input() isLoading = false;
  @Input() isLoadingCreateEntity = false;
  @Input() load: LoadsServiceLoad;
  @Input() customerNotes: string;
  @Input() carrier: CarrierSearchResult;
  @Input() carrierAdmin: CarrierAdmin;
  @Input() trucks: Truck[] | List<Truck> = [];
  @Input() carriers: Carrier[] | List<Carrier> = [];
  @Input() drivers: Driver[] | List<Driver> = [];
  @Input() trailers: Trailer[] | List<Trailer> = [];
  @Input() brokers: List<{ id: string; label: string }>;
  @Input() equipments: EquipmentTypes[];
  @Input() formData: AssignLoadCarrierForm;
  @Input() isLoadingCarriers = false;
  @Input() paymentTypes: List<PaymentType> = List();
  @Input() isLoadingDrivers = false;
  @Input() isLoadingTrucks = false;
  @Input() isLoadingTrailers = false;
  @Input() featureFlagState: FFState;
  @Input() driverToPopulate: string;
  @Input() truckToPopulate: string;
  @Input() trailerToPopulate: string;
  @Input() brokerEmail: string;
  @Input() formMode: AssignLoadCarrierFormMode = AssignLoadCarrierFormMode.NEW;
  @Output() bookForCarrier = new EventEmitter<AssignLoadCarrierForm>();
  @Output() updatePayment = new EventEmitter<AssignLoadCarrierForm>();
  @Output() searchCarrier = new EventEmitter<string>();
  @Output() selectCarrier = new EventEmitter<string>();
  @Output() offer = new EventEmitter<AssignLoadCarrierForm>();
  @Output() cancel = new EventEmitter();
  @Output() addPayType = new EventEmitter<{ index: number; payment: PaymentsTypeForm }>();
  @Output() editPayType = new EventEmitter<{ index: number; payment: Partial<PaymentsTypeForm> }>();
  @Output() deletePayType = new EventEmitter<{ index: number; payment: PaymentsTypeForm }>();
  @Output() addDriver = new EventEmitter<DriverAssignment>();
  @Output() addTruck = new EventEmitter<TruckAssignment>();
  @Output() addTrailer = new EventEmitter<TrailerAssignment>();

  form: FormGroup;
  trucks$ = new BehaviorSubject([]);
  brokers$ = new BehaviorSubject([]);
  drivers$ = new BehaviorSubject([]);
  trailers$ = new BehaviorSubject([]);
  carriers$ = new BehaviorSubject([]);
  carrier$ = new BehaviorSubject(null);
  carrierAdmin$ = new BehaviorSubject(null);
  rateConEmails$ = new BehaviorSubject([]);
  price$ = new BehaviorSubject(0);
  revenue$ = new BehaviorSubject(0);
  total$ = new BehaviorSubject(0);
  paymentFormData$ = new BehaviorSubject([]);
  payLineFeatureFlag = FeatureFlag.PAY_LINE_ITEMS;
  rateconFeatureFlag = FeatureFlag.RATECON;
  truckAssignment: TruckAssignment;
  trailerAssignment: TrailerAssignment;
  driverAssignment: DriverAssignment;
  isDisableBookCarrierButton = false;
  assignLoadCarrierFormMode = AssignLoadCarrierFormMode;
  formSubmitted = false;
  private alive = aliveWhile();

  get orderInfo(): FormGroup {
    return this.form.get('orderInfo') as FormGroup;
  }

  get editLoad(): FormGroup {
    return this.form.get('editLoad') as FormGroup;
  }

  get dispatchInfo(): FormGroup {
    return this.form.get('dispatchInfo') as FormGroup;
  }

  get assignmentInfo(): FormGroup {
    return this.form.get('assignmentInfo') as FormGroup;
  }

  get carrierInfo(): FormGroup {
    return this.form.get('carrierInfo') as FormGroup;
  }

  get payments(): FormArray {
    return this.form.get('payments') as FormArray;
  }

  constructor(
    private assignLoadCarrierFormVm: AssignLoadCarrierFormVm,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private analyticsService: AnalyticsService,
    private loadAlternateId: LoadAlternateIdPipe
  ) {
    this.form = this.assignLoadCarrierFormVm.create();
  }

  onSearchCarrier(keyword: string): void {
    this.carrierInfo.patchValue({ truckId: null, trailerId: null });
    this.searchCarrier.emit(keyword);
  }

  onTrackingChange(): void {
    this.formSubmitted = false;
    const formData: AssignLoadCarrierForm = this.form.getRawValue();
    this.analyticsService.logEvent(ANALYTICS_EVENT.LOAD_TRACKING_TYPE_ADDED, {
      loadId: this.load.id,
      tmwNumber: this.loadAlternateId.transform(this.load, LoadIdentifierType.TMW_NUMBER),
      trackingType: formData.assignmentInfo.trackingType,
      broker: formData.orderInfo.brokerId,
    });
  }

  onSave(event: Event): void {
    this.formSubmitted = true;
    event.stopPropagation();

    if (this.form.valid) {
      const formData: AssignLoadCarrierForm = this.form.getRawValue();
      const carrierDot = get(formData, 'carrierInfo.carrier', null);
      const selectedCarrier = this.carriers.find((carrier: Carrier) => carrier.dot === carrierDot);
      const carrierName = get(selectedCarrier, 'name', null);
      const totalAmount = this.total$.value;

      if (this.featureFlagState[this.payLineFeatureFlag]) {
        this.dialog
          .open(ConfirmBookLoadComponent, {
            data: { carrierName, carrierDot, totalAmount },
            width: '330px',
          })
          .afterClosed()
          .subscribe((confirmation: boolean) => {
            if (confirmation) {
              this.bookLoadForCarrier(formData);
            }
          });
      } else {
        this.bookLoadForCarrier(formData);
      }
    }
  }

  onUpdate(event: Event): void {
    event.stopPropagation();

    if (this.form.valid) {
      const formData: AssignLoadCarrierForm = this.form.getRawValue();

      this.updatePayment.emit(formData);
    }
  }

  onEmailOffer(event: Event, formData: AssignLoadCarrierForm): void {
    event.stopPropagation();
    this.offer.emit(formData);
  }

  onCancel(event: Event): void {
    event.stopPropagation();
    this.cancel.emit();
  }

  ngOnInit(): void {
    this.form
      .get('carrierInfo')
      .get('carrier')
      .valueChanges.pipe(takeUntil(this.alive), distinctUntilChanged())
      .subscribe((carrierDot) => {
        this.selectCarrier.emit(carrierDot);

        if (!carrierDot) {
          this.rateConEmails$.next([this.brokerEmail]);
        }
      });

    if (this.formMode === this.assignLoadCarrierFormMode.EDIT_PAY_LINE) {
      this.enableOnlyPayment();
    }
  }

  onAddPayType(): void {
    this.dialog
      .open(AddPaymentTypeComponent, {
        data: this.paymentTypes,
        width: '650px',
        panelClass: 'add-payment__panel',
      })
      .afterClosed()
      .subscribe((paymentsForm: PaymentsTypeForm) => {
        if (paymentsForm) {
          const { order } = this.orderInfo.getRawValue();
          const index = this.payments.length;
          const payment = { ...paymentsForm, orderNumber: order };

          this.addPayType.emit({ index, payment });
        }
      });
  }

  onDeletePayType(data: { index: number; payment: PaymentsTypeForm }): void {
    this.deletePayType.emit(data);
  }

  onEditPayType(data: { index: number; payment: PaymentsTypeForm }): void {
    this.editPayType.emit(data);
  }

  onNewPayTypeTotal(val: number): void {
    this.total$.next(val);
    this.cd.detectChanges();
  }

  onAddDriver(): void {
    const carrierDot = this.carrierInfo.get('carrier').value;
    const selectedCarrier = this.carriers.find((carrier: Carrier) => carrier.dot === carrierDot);
    const carrierName = get(selectedCarrier, 'name', null);
    const carrierId = get(selectedCarrier, 'id', null);
    const trackingType = this.assignmentInfo?.get('trackingType').value;

    this.dialog
      .open(AddDriverComponent, {
        data: { carrierDot, trackingType },
      })
      .afterClosed()
      .subscribe((driverAssignment: DriverAssignment) => {
        if (driverAssignment) {
          const { phone } = driverAssignment;
          driverAssignment.carrierId = carrierId ? carrierId : '';
          driverAssignment.carrierDot = carrierDot;
          driverAssignment.carrierName = carrierName ? carrierName : '';

          this.driverAssignment = driverAssignment;
          this.assignmentInfo.patchValue({ phone });
          this.addDriver.emit(driverAssignment);
        }
      });
  }

  onAddTruck(): void {
    const carrierDot = this.carrierInfo.get('carrier').value;

    this.dialog
      .open(AddTruckComponent, {
        data: { carrierDot },
      })
      .afterClosed()
      .subscribe((truckAssignment: TruckAssignment) => {
        if (truckAssignment) {
          this.truckAssignment = truckAssignment;
          this.addTruck.emit(truckAssignment);
        }
      });
  }

  onAddTrailer(): void {
    const carrierDot = this.carrierInfo.get('carrier').value;

    this.dialog
      .open(AddTrailerComponent, {
        data: { carrierDot },
      })
      .afterClosed()
      .subscribe((trailerAssignment: TrailerAssignment) => {
        if (trailerAssignment) {
          this.trailerAssignment = trailerAssignment;
          this.addTrailer.emit(trailerAssignment);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const {
      formData,
      trackingTypeChange,
      load,
      trucks,
      drivers,
      trailers,
      brokers,
      carriers,
      formMode,
      carrier,
      carrierAdmin,
      customerNotes,
    } = changes;

    if (trackingTypeChange) {
      this.formSubmitted = false;
    }

    if (formData) {
      const { payments = null, ...currentFormData } = formData.currentValue || {};

      if (!isEmpty(currentFormData)) {
        this.form.patchValue(currentFormData);
      }

      if (payments) {
        this.paymentFormData$.next(payments);
      }
    }

    if (customerNotes) {
      this.form.patchValue({
        editLoad: {
          ...this.load,
          customerNotes: customerNotes.currentValue,
        },
      });
    }

    if (load) {
      const currentLoad: LoadsServiceLoad = load.currentValue || {};
      this.form.patchValue({
        editLoad: currentLoad,
      });

      this.price$.next(currentLoad?.paymentDetails?.price);
      this.revenue$.next(currentLoad?.paymentDetails?.revenue);
      this.isDisableBookCarrierButton = !canBookLoad(currentLoad.bookStatus);
    }

    if (trailers) {
      const newTrailers = listToArray(trailers.currentValue);

      if (this.trailerAssignment) {
        const { trailerNumber } = this.trailerAssignment;
        const trailerToSelect = newTrailers.find((item: Trailer) => item.trailerNumber === trailerNumber) as Trailer;

        this.assignmentInfo.patchValue({ trailerId: trailerToSelect ? trailerToSelect.id : null });
        this.trailerAssignment = null;
      }

      this.trailers$.next(newTrailers);
    }

    if (trucks) {
      const newTrucks = listToArray(trucks.currentValue);

      if (this.truckAssignment) {
        const { unitId } = this.truckAssignment;
        const truckToSelect = newTrucks.find((item: Truck) => item.unitId === unitId) as Truck;

        this.assignmentInfo.patchValue({ truckId: truckToSelect ? truckToSelect.id : null });
        this.truckAssignment = null;
      }

      this.trucks$.next(newTrucks);
    }

    if (drivers) {
      const newDrivers = listToArray(drivers.currentValue);

      if (this.driverAssignment) {
        const { phone, name } = this.driverAssignment;
        const driverToSelect = newDrivers.find((item: Driver) => item.phone === phone && item.name === name) as Driver;

        this.assignmentInfo.patchValue({ driverId: driverToSelect ? driverToSelect.id : null });
        this.driverAssignment = null;
      }

      this.drivers$.next(newDrivers);
    }

    if (brokers) {
      this.brokers$.next(listToArray(brokers.currentValue));
    }
    if (carriers) {
      this.carriers$.next(listToArray(carriers.currentValue));
    }

    if (formMode && formMode.currentValue === this.assignLoadCarrierFormMode.EDIT_PAY_LINE) {
      this.enableOnlyPayment();
    }

    if (carrier) {
      this.onSelectCarrier(carrier.currentValue);
      this.carrier$.next(carrier.currentValue);
    }

    if (carrierAdmin?.currentValue) {
      setTimeout(() => {
        this.carrierAdmin$.next(carrierAdmin.currentValue);
      });
    }
  }

  onSelectCarrier(carrier: CarrierSearchResult): void {
    if (carrier && carrier?.contacts?.length) {
      const carrierEmails = this.getCarrierEmails(carrier) ?? [];
      const rateConEmails = [...carrierEmails, this.brokerEmail];

      this.rateConEmails$.next(rateConEmails);
    }
  }

  onRateConEmailsChange(emails: string[]): void {
    let rateConEmails: RateConEmail[] = null;

    if (!isEmpty(emails)) {
      rateConEmails = emails.map((emailRecipient: string) => {
        return { emailRecipient };
      });
    }

    this.editLoad.patchValue({ rateConEmails });
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }

  private enableOnlyPayment(): void {
    this.orderInfo.disable();
    this.editLoad.disable();
    this.dispatchInfo.disable();
    this.assignmentInfo.disable();
    this.carrierInfo.disable();
  }

  private bookLoadForCarrier(formData: AssignLoadCarrierForm) {
    if (!formData.dispatchInfo.location) {
      formData.dispatchInfo = { ...formData.dispatchInfo, location: null, timeAvailable: null };
    }

    this.bookForCarrier.emit(formData);
  }

  private getCarrierEmails(carrier: CarrierSearchResult): string[] {
    return carrier?.contacts?.reduce((acc: string[], contact: CarrierContacts) => {
      const { email, type } = contact;
      return type === CarrierContactTypes.ADMIN || type === CarrierContactTypes.BUSINESS ? [...acc, email] : acc;
    }, []);
  }
}
