import { AppSettingsActionTypes } from '../../main-store/app-settings.actions';
import { DispatchAction } from '../../shared/store/helpers/action';
import { NotificationActionTypes } from './notification.action';
import { NotificationState } from './notification.state';

export const initialState = new NotificationState();

export function notificationReducer(state = initialState, action: DispatchAction): NotificationState {
  switch (action.type) {
    case AppSettingsActionTypes.LOG_OUT:
      return initialState;

    case NotificationActionTypes.SET_AVAILABLE_NOTIFICATION_TYPES: {
      const types = action.payload || [];

      return state.merge({
        notificationEventTypes: types,
      });
    }

    case NotificationActionTypes.SEARCH_NOTIFICATION: {
      return state.merge({
        query: action.payload ? action.payload : state.query,
        isLoading: true,
      });
    }

    case NotificationActionTypes.SEARCH_NOTIFICATION_SUCCESS: {
      const { entities = [] } = action.payload;
      return state.merge({
        isLoading: false,
        notifications: entities,
      });
    }

    case NotificationActionTypes.SEARCH_NOTIFICATION_ERROR:
      return state.merge({
        isLoading: false,
        notifications: [],
      });

    case NotificationActionTypes.SHOW_NOTIFICATION:
      return state.merge({
        showNotification: true,
      });

    case NotificationActionTypes.DEACTIVATE_NOTIFICATIONS: {
      const { creatorId, notificationIds }: { creatorId: string; notificationIds: string[] } = action.payload;
      const newNotifications = state.notifications.map((notification) => {
        if (notificationIds.includes(notification.id)) {
          return { ...notification, active: false };
        }

        return notification;
      });

      return state.merge({
        notifications: newNotifications,
      });
    }

    case NotificationActionTypes.HIDE_NOTIFICATION:
      return state.merge({
        showNotification: false,
      });

    default:
      return state;
  }
}
