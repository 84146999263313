import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DontMeetRequirementsDialogComponent } from './dont-meet-requirements-dialog.component';
import { DialogTemplateModule } from '../dialog-template/dialog-template.module';

@NgModule({
  declarations: [DontMeetRequirementsDialogComponent],
  imports: [CommonModule, DialogTemplateModule],
  exports: [DontMeetRequirementsDialogComponent],
})
export class DontMeetRequirementsDialogModule {}
