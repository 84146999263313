export class ZipLane {
  bidId: string;
  zipLane: string;
  locations: ZipLaneLocation[];
  loadContents: LoadContents;
  laneDetails: ZipLaneDetails;
  costDetails: ZipLaneCostDetails;
  mlPredicted: MlPredicted;
  historical: HistoricalDetails;
  miles: number;
  allInRPM: number;
  costToHireRPM: number;
  constructor(props?: Partial<ZipLane>) {
    Object.assign(this, props);
  }
}
export class ZipLaneLocation {
  city: string;
  state: string;
  zip: string;
  centroidDistance: number;
  timestamp: string;
  constructor(props?: Partial<ZipLaneLocation>) {
    Object.assign(this, props);
  }
}
export class LoadContents {
  equipmentType: string;
  weight: string;
  commodity: string;
  constructor(props?: Partial<LoadContents>) {
    Object.assign(this, props);
  }
}
export interface ZipLaneDetails {
  count: number | string;
  bidSinceWon: number;
  oneWeekAttainment: number;
  customer: string;
}
export interface ZipLaneCostDetails {
  minCost: number;
  maxCost: number;
  averageCostAmount: number;
  averageCostRate: number;
  recentCostAmount: number;
  recentCostRate: number;
}
export interface MlPredicted {
  allInRpm: number;
  costRpm: number;
  laneVolatility: string;
}
export interface HistoricalDetails {
  averageBidRate: number;
  averageBidAmount: number;
  bidLow: number;
  bidHigh: number;
  averageWonRate: number;
  averageWonAmount: number;
  wonLow: number;
  wonHigh: number;
  averageMarginAmount: number;
  marginHigh: number;
  marginLow: number;
  datAmount: number;
  datRate: number;
}
