import gql from 'graphql-tag';
import { CarrierSearchListRoute, FFState } from '@haulynx/types';

export const GetSidebarLoadCounts = (features: FFState) =>
  gql(`
  query GetSidebarLoadCounts($dot: StrictId!, $dotString: String! ${
    features.CARRIER_BID ? ', $carrierId: StrictId!' : ''
  }) {
    matches: getLoadMatchesByCarrierDot(dot: $dotString, paging: { limit: 100 }) { total }
    recommendations: getTruckPostRecommendations(dot: $dotString, paging: { limit: 100 }) { total }

    ${
      CarrierSearchListRoute.OFFERS
    }: getLoadsBrokered(filterParams: { dot: $dot, onlyActiveLoads: true }, paging: { limit: 100 }) {
      total
      result {
        id
      }
    }

    ${
      features.CARRIER_BID
        ? `${CarrierSearchListRoute.BIDS}: getBidsByCarrierId(carrierId: $carrierId, onlyActiveBids: true) {
            id: loadId
          }`
        : ''
    }

    ${
      features.CLS_ACTIVE_LOADS
        ? `
          ${CarrierSearchListRoute.UNASSIGNED}: getLoadsServiceSearchMetaData(searchParameters: {loadStatus: unassigned, carrierDot: $dot, showTestLoads: ${features.TEST_LOADS}, showExcluded: false }) {
            totalLoads
          }
          
          ${CarrierSearchListRoute.ASSIGNED}: getLoadsServiceSearchMetaData(searchParameters: {loadStatus: assigned, carrierDot: $dot, showTestLoads: ${features.TEST_LOADS}, showExcluded: false }) {
            totalLoads
          }
          
          ${CarrierSearchListRoute.IN_TRANSIT}: getLoadsServiceSearchMetaData(searchParameters: {loadStatus: [pickedUp, atReceiver, atShipper, dispatched], carrierDot: $dot, showTestLoads: ${features.TEST_LOADS}, showExcluded: false }) {
            totalLoads
          }
          
          ${CarrierSearchListRoute.DELIVERED}: getLoadsServiceSearchMetaData(searchParameters: { loadStatus: delivered, carrierDot: $dot, showTestLoads: ${features.TEST_LOADS}, showExcluded: false }) {
            totalLoads
          }
        `
        : ''
    }
  }
`);
