export interface TruckAssignment {
  unitId: string;
  carrierDot?: string;
  carrierId?: string;
}

export interface CreateTruckDto {
  unitId: string;
  carrierId: string;
}
