<div class="carrier-info-container">
  <form [formGroup]="carrierForm" class="form">
    <mat-form-field class="app-dark-field" appearance="fill" floatLabel="always">
      <label class="label"> Carrier </label>
      <app-drop-down
        class="form-control"
        [placeholder]="'MC/DOT/Carrier'"
        formControlName="carrier"
        [cypressName]="'carrier-drop-down'"
        [data]="carriers"
        [changeableData]="true"
        [customFilter]="true"
        [isLoading]="isLoadingCarriers"
        [suffixIcon]="
          featureFlags?.CARRIER_COMPLIANCE && selectedCarrier && enableCompliance
            ? (insuranceExp && insuranceExp > today) || carrierComplianceState === 'done'
              ? 'haulynx-checked'
              : 'notification-alert-red'
            : ''
        "
        [suffixHoverOver]="
          (selectedCarrier && insuranceExp && insuranceExp > today) || carrierComplianceState === 'done'
            ? tooltipTextSucess
            : tooltipTextError
        "
        displayLabel="name"
        key="dot"
        (keywordChanges)="onValueChanges($event)"
        (selected)="selectCarrier()"
      >
      </app-drop-down>
    </mat-form-field>
    <mat-form-field class="app-dark-field" appearance="fill" floatLabel="always">
      <label class="label"> MC # </label>
      <input class="form-control" matInput formControlName="mcNumber" placeholder="" />
    </mat-form-field>
    <mat-form-field class="app-dark-field" appearance="fill" floatLabel="always">
      <label class="label"> Contact </label>
      <input class="form-control" matInput formControlName="contact" placeholder="" />
    </mat-form-field>
    <mat-form-field class="app-dark-field" appearance="fill" floatLabel="always">
      <label class="label"> Email </label>
      <input class="form-control" matInput formControlName="email" placeholder="" />
    </mat-form-field>
    <mat-form-field class="app-dark-field" appearance="fill" floatLabel="always">
      <label class="label"> Phone </label>
      <input class="form-control" matInput mask="000-000-0000" formControlName="phone" placeholder="" />
    </mat-form-field>
    <div *ngIf="enableButton | async" class="driverToolTip">
      <mat-icon
        (mouseenter)="carrierToolTip.toggle($event)"
        (mouseleave)="carrierToolTip.toggle($event)"
        svgIcon="haulynx-info-outline"
      ></mat-icon>
      <p-overlayPanel #carrierToolTip>
        <haulynx-carrier-tool-tip></haulynx-carrier-tool-tip>
      </p-overlayPanel>
      <button
        [disabled]="assignmentLoading"
        (click)="toggleEditCarrier()"
        mat-raised-button
        class="edit-carrier-button"
        data-test="edit-carrier-info"
      >
        {{ editCarrier ? 'Reset Carrier Info' : 'Edit Carrier Instance' }}
      </button>

      <button
        mat-raised-button
        class="save-carrier-button"
        [disabled]="assignmentLoading || !isCarrierFormValid() || carrierForm.untouched"
        (click)="saveCarrierInfo()"
        data-test="save-carrier-info"
      >
        <mat-spinner [diameter]="18" *ngIf="assignmentLoading" mode="indeterminate"></mat-spinner>
        Save Instance
      </button>
    </div>
    <div *appFeatures="complianceFeature">
      <div class="carrier-compliance">
        <div
          class="carrier-compliance-create"
          *ngIf="
            selectedCarrier &&
            enableCompliance &&
            (!insuranceExp || insuranceExp < today) &&
            carrierComplianceState !== 'done'
          "
        >
          <button
            mat-raised-button
            class="carrier-compliance-button"
            data-test="create-compliance-ticket"
            [disabled]="
              assignmentLoading ||
              !isCarrierFormValid() ||
              carrierComplianceState !== 'none' ||
              carrierComplianceLoading
            "
            (click)="createComplianceTicket()"
          >
            <mat-spinner
              [diameter]="18"
              *ngIf="carrierComplianceState === 'pending'"
              mode="indeterminate"
            ></mat-spinner>
            {{
              carrierComplianceState === 'none'
                ? 'Request Compliance Review'
                : carrierComplianceState === 'pending'
                ? 'Pending Compliance'
                : ''
            }}
          </button>
        </div>
        <div class="carrier-compliance-approved" *ngIf="carrierComplianceState === 'done' && enableCompliance">
          <mat-icon>check</mat-icon>
          <div class="approve-text">Compliance Approved</div>
        </div>
      </div>
    </div>
  </form>
</div>
