import { LoadActionEvent, SubscriptionAction, SubscriptionEvent, SubscriptionEventFilterParams } from '@haulynx/types';
import { loadActionEventSubscription } from '@haulynx/utils';
import { keyBy } from 'lodash';
import { Observable } from 'rxjs';

export function processSearchEvents(subscriptionEvents: SubscriptionEvent[], loadIds: string[]): LoadLockState {
  const loadActionEvents: LoadActionEvent[] = subscriptionEvents
    ?.map((subevent) => {
      return {
        action: subevent.eventType,
        user: {
          id: subevent.user?.id,
          usxId: subevent.user?.usxId,
          name: subevent.user?.name,
          email: subevent.email,
        },
        load: { id: subevent.entityId },
        timestamp: subevent.createdAt,
        expiredAt: subevent?.expiredAt,
      };
    })
    .sort((a, b) => a.timestamp - b.timestamp)
    .filter((ev) => loadIds.includes(ev.load.id)); //filter out unrelated load ids
  const lockEvents = loadActionEvents.filter(
    (ev) => ev.action === SubscriptionAction.UNLOCK || ev.action === SubscriptionAction.LOCK
  );
  const userViewershipEvents = loadActionEvents.filter(
    (ev) =>
      (ev.action === SubscriptionAction.OPEN || ev.action === SubscriptionAction.CLOSE) &&
      new Date().valueOf() - ev.timestamp < 1000 * 60 * 60 * 24 //filter out timestamps that are 24hrs old
  );
  const updateEvents = loadActionEvents.filter((ev) => ev.action === SubscriptionAction.READ);
  return {
    loadLocking: keyBy(lockEvents, 'load.id'),
    userViewership: keyBy(userViewershipEvents, 'user.id'),
    update: keyBy(updateEvents, 'load.id'),
  };
}

export function processSubscriptionEvents(message: { loadActionEvent: LoadActionEvent }): LoadLockState {
  const loadActionEvent: LoadActionEvent = message.loadActionEvent;
  const isLockEvent =
    loadActionEvent.action === SubscriptionAction.UNLOCK || loadActionEvent.action === SubscriptionAction.LOCK;
  const isViewEvent =
    loadActionEvent.action === SubscriptionAction.OPEN || loadActionEvent.action === SubscriptionAction.CLOSE;
  const isUpdateEvent = loadActionEvent.action === SubscriptionAction.READ;
  if (isLockEvent) {
    return {
      loadLocking: {
        [loadActionEvent.load.id]: { ...loadActionEvent } as LoadActionEvent,
      },
      userViewership: {},
      update: {},
    };
  } else if (isViewEvent) {
    return {
      loadLocking: {},
      userViewership: { [loadActionEvent.user.id]: loadActionEvent },
      update: {},
    };
  } else if (isUpdateEvent) {
    return {
      loadLocking: {},
      userViewership: {},
      update: { [loadActionEvent.load.id]: loadActionEvent },
    };
  } else {
    return {} as LoadLockState;
  }
}

export function loadLockStreamReducer(acc: LoadLockState, curr: LoadLockState): LoadLockState {
  return {
    loadLocking: {
      ...acc.loadLocking,
      ...curr.loadLocking,
    },
    userViewership: {
      ...acc.userViewership,
      ...curr.userViewership,
    },
    update: {
      ...acc.update,
      ...curr.update,
    },
  };
}

export function getLoadLockSubscriptionPayload(
  loadIds: string[]
): [query: string, filterParams: SubscriptionEventFilterParams] {
  const query = loadActionEventSubscription(
    [
      SubscriptionAction.UNLOCK,
      SubscriptionAction.LOCK,
      SubscriptionAction.OPEN,
      SubscriptionAction.CLOSE,
      SubscriptionAction.READ,
    ],
    loadIds
  );

  //empty eventTypes return all events
  const filterParams: SubscriptionEventFilterParams = {
    eventTypes: [],
    entityIds: loadIds,
    queryName: 'logEntityActionEvent',
  };
  return [query, filterParams];
}

export type LoadLockState = {
  loadLocking: { [loadId: string]: LoadActionEvent };
  userViewership: { [userId: string]: LoadActionEvent };
  update: { [loadId: string]: LoadActionEvent };
};

export type LoadLockStateManager = {
  /**
   * Returns data indexed by load id that describes the latest action performed on a load
   */
  updateStream$: Observable<{ [loadId: string]: LoadActionEvent }>;
  /**
   * Returns data indexed by load id that describes the latest lock state of a load
   */
  loadLockStream$: Observable<{ [loadId: string]: LoadActionEvent }>;
  /**
   * Returns data indexed by user id that describes the latest user viewership state on loads
   */
  userViewershipStream$: Observable<{ [userId: string]: LoadActionEvent }>;
  /**
   * The other observable streams pull from two sources
   * 1. a search query that returns from the api immediately and shows a history of events
   * 2. the web socket subscription that notifies of live events
   *
   * This observable determines whether #1 is currently active
   */
  isSearchingActions$: Observable<boolean>;
  /**
   * Trigger a new search & subscription given a list of load ids
   */
  next: (loadIds: string[]) => void;
};
