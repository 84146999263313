import { DateRangeFilterType, ISearchFilter } from '../search-filter';
import { ISearchFilterType } from '../search-filter.types';
export class LastSeenFilter implements ISearchFilter {
  name = 'Last Seen Start Date';
  type: ISearchFilterType = ISearchFilterType.DATE_RANGE;
  keys: DateRangeFilterType;
  description = 'Filter lanes by last seen';

  constructor(defaultValues?: DateRangeFilterType) {
    this.keys = new DateRangeFilterType({
      from: null,
      to: null,
      fromFormName: 'lastSeenStart',
      toFormName: 'lastSeenEnd',
      ...defaultValues,
    });
  }
}
