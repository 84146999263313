import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class FileReaderService {
  public parseImeiList(ev: Event): Observable<string[]> {
    return this.fileToString((ev.target as HTMLInputElement).files[0]).pipe(
      map((binary): string[] => {
        const text = binary.split(/\r\n|\n/);
        const lines: string[] = [];
        if (text.length > 0) {
          for (let i = 0; i < text.length; i++) {
            if (text[i].trim() !== '') {
              lines.push(text[i]);
            }
          }
          return lines;
        }
      })
    );
  }

  private fileToString(file: File): Observable<string> {
    return new Observable((sub: Subscriber<string>): void => {
      const reader = new FileReader();
      reader.onload = (ev): void => {
        sub.next((ev.target as FileReader).result as string);
      };
      reader.onerror = (ev): void => {
        sub.error(ev);
      };
      reader.readAsText(file);
    });
  }
}
