import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-carrier-analytics-item',
  templateUrl: './carrier-analytics-item.component.html',
  styleUrls: ['./carrier-analytics-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarrierAnalyticsItemComponent {
  @Input() label: string;
  @Input() value: number | string;
  @Input() displaySymbol = '$';
  @Input() type = 'number';
  constructor() {}
}
