import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@haulynx/modules';
import { AppMenuComponent } from './app-menu.component';

@NgModule({
  declarations: [AppMenuComponent],
  exports: [AppMenuComponent],
  imports: [CommonModule, MaterialModule],
})
export class AppMenuModule {}
