import gql from 'graphql-tag';

export const UpdatePaymentItem = gql(`
  mutation UpdatePaymentItem($paymentId: StrictId!, $paymentUpdate: PaymentItemUpdateInput) {
    updatePaymentItem(paymentId: $paymentId, paymentUpdate: $paymentUpdate) {
      orderNumber
      paymentType
      quantity
      amount
      paymentUnits
      haulynxId
      usxId
    }
  }
`);
