<p-table
  class="data-table-main"
  [value]="dataSource"
  [rows]="showingRowCountOnPaginator"
  [showCurrentPageReport]="true"
  [rowsPerPageOptions]="[10, 25, 50]"
  [paginator]="showBottomPaginator"
  [currentPageReportTemplate]="'{first} / {last} of {totalRecords}'"
  [filterDelay]="0"
  [globalFilterFields]="tableConfig?.globalFilter"
  [rowHover]="true"
  [selectionMode]="selectionMode"
  [reorderableColumns]="tableConfig?.reorderColumns"
  [columns]="visibleColumns"
  [lazy]="lazy"
  [scrollHeight]="scrollHeight"
  [scrollable]="scrollable"
  [frozenColumns]="frozenColumms"
  [frozenWidth]="frozenColumms.length > 0 && getRowCount() > 0 ? stickyColumnWidth : '0px'"
  [rowTrackBy]="trackByItem"
  [(first)]="currentFirstRecord"
  [(selection)]="selectedRows"
  (onRowSelect)="selectRow($event)"
  (onRowUnselect)="selectRow($event)"
>
  <!-- TABLE TITLE / FILTER -->
  <ng-template pTemplate="caption">
    <div class="title-section">
      <div class="title-section-item">
        {{ title }}
      </div>
      <div class="title-section-item" *ngIf="tableConfig?.globalFilter">
        <mat-form-field class="app-dark-field" appearance="fill" floatLabel="always">
          <label class="label">
            <mat-icon data-test="search-button"> search </mat-icon>
          </label>
          <input
            matInput
            [ngModel]="filterForm"
            (ngModelChange)="filter($event)"
            class="form-control"
            placeholder="search"
            data-test="search-field"
          />
        </mat-form-field>
      </div>
      <div class="title-section-item-full">
        <app-bulk-actions
          [showTitle]="selectedRows?.length > 0"
          [loads]="selectedRows"
          [bulkActionsPage]="tableConfig?.bulkActions"
          [displayAssignTo]="isDisplayTitle"
          (action)="onBulkAction($event)"
          (closedDialog)="onDialogClose()"
          (downloadCSV)="downloadLoadBoardClicked()"
          *ngIf="tableConfig?.showBulkActions"
          class="{{ tableConfig?.isLaneRate ? 'bulk-actions' : '' }}"
        ></app-bulk-actions>
        <div class="title-section-right-side">
          <app-spinner [active]="loading" [diameter]="24"></app-spinner>
          <div class="download-csv-container" *ngIf="showDownloadCSV">
            <button class="download-button">
              <mat-icon class="download-icon">arrow_downward</mat-icon>
              <div class="download-text" (click)="downloadClicked()">CSV File</div>
            </button>
          </div>
          <app-pagination
            [pageData]="paginationData"
            [pageTotal]="tableConfig.pageAmount"
            [rowCount]="getRowCount()"
            [loading]="loading"
            (page)="onPage($event)"
            *ngIf="tableConfig?.showTopPaginator"
          ></app-pagination>
          <div *ngIf="tableConfig?.isLaneRate" class="toggle-container">
            <form [formGroup]="toggleForm">
              <mat-label class="toggle-label" mat-slide-toggle-content>$$&nbsp;&nbsp;</mat-label>
              <mat-slide-toggle class="toggle" formControlName="toggle" checked="toggleValue"></mat-slide-toggle>
              <mat-label class="toggle-label" mat-slide-toggle-content>&nbsp;&nbsp;RPM</mat-label>
            </form>
          </div>
          <app-drop-down
            *ngIf="tableConfig?.isLaneRate && csvDataOptions.length > 0"
            class="csv-print"
            [data]="csvDataOptions"
            [prefixIcon]="'haulynx-envelope'"
            displayLabel="label"
            key="value"
            [placeholder]="'CSV File'"
            (selected)="downloadCSV($event)"
          ></app-drop-down>
          <app-column-visibility
            *ngIf="tableConfig?.columnVisibility && !loading"
            [columns]="displayedColumns"
            [isSearchView]="isSearchView"
            [selectedFilters]="selectedFilters"
            [selectedSidebarItem]="selectedSidebarItem"
            (columnsChange)="onColumnsChange($event)"
            (saveSearchView)="onSaveSearchView($event)"
            (columnsRest)="onColumnsReset()"
          ></app-column-visibility>
        </div>
      </div>
    </div>
  </ng-template>

  <!-- TABLE HEADER -->
  <ng-template pTemplate="header" let-columns>
    <th *ngIf="tableConfig?.isLaneRate && columns?.length >= stickyColumnCount" colspan="3"></th>
    <th *ngIf="tableConfig?.isLaneRate && columns?.length >= stickyColumnCount" colspan="3" class="ml-predicted">
      (Predicted By Machine Learning)
    </th>
    <th *ngIf="tableConfig?.isLaneRate && columns?.length < stickyColumnCount" colspan="1" class="sticky-header">
      &nbsp;
    </th>
    <tr class="caption-row">
      <!-- priority -->
      <ng-container *ngIf="dataHasPriority && columns?.length >= stickyColumnCount">
        <th class="caption-section{{ tableConfig?.checkBoxSelection ? '-checkbox' : '' }}" style="width: 4px"></th>
      </ng-container>

      <!-- space for reorder row icon -->
      <th
        class="caption-section{{ tableConfig?.checkBoxSelection ? '-checkbox' : '' }}"
        style="width: 3rem"
        *ngIf="tableConfig?.reorderRows && columns?.length >= stickyColumnCount"
      ></th>

      <!-- table checkbox -->
      <ng-container *ngIf="tableConfig?.checkBoxSelection && columns?.length >= stickyColumnCount">
        <th class="caption-section{{ tableConfig?.checkBoxSelection ? '-checkbox' : '' }}" style="width: 3rem">
          <p-tableHeaderCheckbox #headerCheckbox (click)="selectRow(dataSource, 'select-all')"></p-tableHeaderCheckbox>
        </th>
      </ng-container>

      <!-- sortable/reorderable columns -->
      <ng-container *ngFor="let column of columns; let i = index; trackBy: trackByItem">
        <th
          *ngIf="isDisplayableColumn(column) && column.isVisible"
          pReorderableColumn
          class="caption-section{{ tableConfig?.checkBoxSelection ? '-checkbox' : '' }} 
          {{ i === 0 || column.field === colFields.ORIGIN_AND_DESTINATION ? '' : 'extra-borders' }} 
          {{ column.field === colFields.ORIGIN_AND_DESTINATION ? 'origin-dest-header' : '' }} "
          [ngStyle]="{ width: column.width ? column.width : 'inherit' }"
        >
          <div class="sortable-headers" *ngIf="column.isSortable">
            <span [innerHTML]="column.label"></span>
            <app-column-sort
              [sortConfig]="column.sortConfig"
              [dropdownAlign]="column.dropdownAlign"
              (selectOption)="onSelectOption($event)"
            ></app-column-sort>
          </div>

          <div *ngIf="!column.isSortable && !column.headerTemplate" [innerHTML]="column.label"></div>
          <div *ngIf="!column.isSortable && column.headerTemplate">
            <ng-container
              *ngTemplateOutlet="
                column.headerTemplate;
                context: {
                  column: column
                }
              "
            ></ng-container>
          </div>
        </th>
      </ng-container>
    </tr>
  </ng-template>

  <!-- STICKY COLUMNS -->
  <ng-template pTemplate="colgroup" let-columns>
    <colgroup>
      <col *ngFor="let col of columns; trackBy: trackByItem" [ngStyle]="{ width: col.width }" />
    </colgroup>
  </ng-template>

  <!-- TABLE BODY -->
  <ng-template pTemplate="body" let-data let-index="rowIndex" let-columns="columns">
    <tr
      [pSelectableRow]="data"
      [pReorderableRow]="index"
      class="body-row"
      [ngClass]="{
        'sticky-column': columns?.length <= stickyColumnCount,
        'is-selected': highlightedLoads[data.id],
        'is-hovering': hoveringRow === index,
        'not-sticky': columns?.length >= stickyColumnCount
      }"
      (mouseenter)="hover(index)"
      (mouseleave)="hover(-1)"
      [ngStyle]="{ height: rowHeights[index] ? rowHeights[index].value + 'px' : 'inherit' }"
      #tableRow
    >
      <!-- priority -->
      <ng-container *ngIf="dataHasPriority && columns?.length >= stickyColumnCount">
        <td
          style="width: 4px"
          class="body-section"
          [matTooltip]="getTooltipName(data?.providerDetails?.operationsPriority)"
          [matTooltipPosition]="'above'"
          [ngStyle]="{
            background:
              data?.providerDetails?.operationsPriority === 'high'
                ? '#F92D2D'
                : data?.providerDetails?.operationsPriority === 'medium'
                ? '#F4C008'
                : data?.providerDetails?.operationsPriority === 'low'
                ? '#00CA5D'
                : ''
          }"
          data-test="load-priority"
          attr.data-priority="{{ data?.providerDetails?.operationsPriority }}"
        ></td>
      </ng-container>

      <!-- reorder row column -->
      <td
        style="width: 3rem"
        class="body-section"
        *ngIf="tableConfig?.reorderRows && columns?.length >= stickyColumnCount"
      >
        <span class="pi pi-bars" pReorderableRowHandle></span>
      </td>

      <!-- table checkbox -->
      <ng-container *ngIf="tableConfig?.checkBoxSelection && columns?.length >= stickyColumnCount">
        <td style="width: 3rem" class="body-section checkbox-col">
          <app-spinner
            *ngIf="entitiesLoading[data.id] || entitiesLoading[data.bidId]; else isNotLoading"
            [diameter]="24"
            [active]="true"
          ></app-spinner>
          <ng-template #isNotLoading>
            <p-tableCheckbox
              [value]="data"
              [disabled]="!data.bidId && tableConfig?.isLaneRate"
              class="checkbox"
              [ngClass]="{
                disabled: !data.bidId && tableConfig?.isLaneRate
              }"
              data-test="table-checkbox"
            ></p-tableCheckbox>
          </ng-template>
        </td>
      </ng-container>

      <!-- sortable columns first -->
      <ng-container *ngFor="let column of columns; trackBy: trackByItem">
        <ng-container *ngIf="!column.isCustomCell && column.isVisible && isDisplayableColumn(column)">
          <td
            class="body-section"
            *ngIf="column.label !== '' && column.isSortable"
            (click)="clickedColumn(column, data)"
            [ngStyle]="{ width: column.width ? column.width : 'inherit' }"
          >
            <span
              [ngStyle]="{
                'text-decoration': hoveringRow === index && column.isClickable ? 'underline' : 'none',
                cursor: column.isClickable ? 'pointer' : 'caret'
              }"
            >
              {{ data[column.field] }}
            </span>
          </td>
        </ng-container>
        <ng-container *ngIf="column.isCustomCell && column.isVisible && isDisplayableColumn(column)">
          <ng-container
            *ngTemplateOutlet="
              templateCellRef;
              context: {
                row: data,
                column: column,
                isHovering: hoveringRow === index,
                columnWidth: column.width ? column.width : 'inherit',
                rowIndex: index,
                rowCount: getRowCount()
              }
            "
          >
          </ng-container>
        </ng-container>
      </ng-container>

      <!-- not sortable columns -->
      <!-- <ng-container *ngFor="let column of columns; trackBy: trackByItem">
        <ng-container
          *ngIf="
            column.isCustomCell &&
            !column.isSortable &&
            (!column.isSticky || columns?.length <= stickyColumnCount) &&
            isDisplayableColumn(column)
          "
        >
          <ng-container
            *ngTemplateOutlet="
              templateCellRef;
              context: {
                row: data,
                column: column,
                isHovering: hoveringRow === index,
                columnWidth: column.width ? column.width : 'inherit',
                rowIndex: index,
                rowCount: getRowCount()
              }
            "
          >
          </ng-container>
        </ng-container>
      </ng-container> -->
    </tr>
  </ng-template>

  <!-- TABLE ROW FOR EMPTY MESSAGE -->
  <ng-template pTemplate="emptymessage">
    <tr>
      <td *ngIf="tableConfig?.isLaneHistory; else message" [attr.colspan]="getColumnCount()" class="lane-history">
        <div class="subtitle-empty">No data available</div>
        <div class="subtext-empty">
          Currently there are not enough data points to generate a summary<br />Please expand your lane radius
        </div>
      </td>
      <ng-template #message>
        <td class="empty-message body-section" [attr.colspan]="getColumnCount()">
          {{ emptyMessage }}
        </td>
      </ng-template>
    </tr>
  </ng-template>

  <!-- FOOTER ROW -->
  <ng-template pTemplate="footer">
    <div class="footer-paginator">
      <app-pagination
        [pageData]="paginationData"
        [pageTotal]="tableConfig.pageAmount"
        [rowCount]="getRowCount()"
        [loading]="loading"
        (page)="onPage($event)"
        *ngIf="tableConfig?.showBottomPaginator"
      ></app-pagination>
    </div>
  </ng-template>
</p-table>
