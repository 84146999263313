import { ChangeDetectionStrategy, Component, Injector, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FeatureFlagService } from '@haulynx/services';
import { ControlValueAccessorConnectorComponent } from '@haulynx/utils';

@Component({
  selector: 'haulynx-grey-input',
  templateUrl: './grey-input.component.html',
  styleUrls: ['./grey-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: GreyInputComponent,
      multi: true,
    },
  ],
})
export class GreyInputComponent extends ControlValueAccessorConnectorComponent implements OnInit {
  @Input() inputControl: FormControl;
  @Input() type = 'text';
  @Input() label = 'Label';
  @Input() placeholder: string = null;
  @Input() required = false;
  @Input() prefix: string = null;
  @Input() maxlength: number = null;
  @Input() minlength: number = null;
  @Input() showClearButton: boolean = false;
  @Input() step: number;
  @Input() autocomplete: string;
  @Input() pattern: string;
  @Input() tabindex = 0;
  @Input() mask: string = '';
  @Input() disabled: boolean = false;

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    if (this.disabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  clear(): void {
    this.inputControl.reset();
  }
}
