import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { GeocodingService, TrailersService } from '@haulynx/services';
import { LatLonInput, MilestoneTrailerState, MilestoneTrailerToggleEvent, Trailer } from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { map as _map } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';

@Component({
  selector: 'haulynx-milestone-trailer-status',
  templateUrl: './milestone-trailer-status.component.html',
  styleUrls: ['./milestone-trailer-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MilestoneTrailerStatusComponent implements OnInit {
  @Input() trailers?: Trailer[];

  @Output() toggle = new EventEmitter<MilestoneTrailerToggleEvent>();

  trailerStates: Array<MilestoneTrailerState> = [];
  alive = aliveWhile();

  constructor(private trailerService: TrailersService, private geocodingService: GeocodingService) {}

  ngOnInit(): void {
    // Prep the trailer states
    this.trailerStates = _map(this.trailers, this.initTrailerState);

    // Populate trailer history values
    for (const state of this.trailerStates) {
      this.populateTrailerHistory(state);
    }
  }

  trailerToggled(trailer: Trailer): void {
    const trailerToggleEvent: MilestoneTrailerToggleEvent = {
      id: trailer.id,
      checked: this.trailerStates.find((ts) => ts.trailer.id === trailer.id).checked,
    };

    this.toggle.emit(trailerToggleEvent);
  }

  private initTrailerState(trailer: Trailer): MilestoneTrailerState {
    return {
      trailer,
      checked: true,
      $lastUpdate: new BehaviorSubject<number>(null),
      $position: new BehaviorSubject<string>(null),
    };
  }

  private populateTrailerHistory(state: MilestoneTrailerState): void {
    const { trailerNumber, trailerCompany, trailerOwnerType } = state.trailer;

    // 3rd-party trailers, we don't need to do anything extra.
    if (!trailerCompany && !trailerOwnerType) {
      state.$position.next('UNAVAILABLE');
      return;
    }

    this.trailerService
      .searchTrailerHistorybyTrailerInfo({
        trailerNumber,
        trailerCompany,
      })
      .pipe(
        map((result) => {
          if (!result.telemetryHistory || result.telemetryHistory.length === 0) {
            state.$position.next('UNKNOWN');
            return new Date().getTime();
          }

          const telemetryHistory = result.telemetryHistory[0];
          const { latitude, longitude } = telemetryHistory;

          // Start reverse geoloc from telemertry
          this.populateTrailerGeoLocation(state, {
            lat: latitude,
            lon: longitude,
          });

          return new Date(telemetryHistory.telemetryPingDateTime).getTime();
        }),
        takeUntil(this.alive)
      )
      .subscribe((lastUpdate) => {
        state.$lastUpdate.next(lastUpdate);
      });
  }

  private populateTrailerGeoLocation(state: MilestoneTrailerState, coordinates: LatLonInput): void {
    this.geocodingService
      .getReverseGeocodeLocation(coordinates, 1)
      .pipe(
        map((result) => {
          const locations = result.data;
          if (!locations || locations.length === 0) {
            return 'UNKNOWN';
          }

          return `${locations[0].city}, ${locations[0].stateAbbreviation}`;
        }),
        takeUntil(this.alive)
      )
      .subscribe((location) => {
        state.$position.next(location);
      });
  }
}
