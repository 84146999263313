<div class="chart-container">
  <div class="chart-toolbar">
    <div class="chart-title">
      {{ chartConfig.chartTitle }}
    </div>
    <div class="graph-toolbar">
      <div
        class="settings-button"
        (click)="openSettingsDropdown()"
        [ngStyle]="{ background: isSettingsOpen ? 'var(--hover-dark)' : 'var(--nb80)' }"
      >
        <mat-icon>settings</mat-icon>
      </div>
      <div class="graph-select">
        <div
          class="graph-select-option"
          *ngFor="let option of chartConfig.chartOptions; let i = index"
          (click)="setCurrentChart(option)"
          [ngStyle]="{
            'border-right': i !== chartConfig.chartOptions.length - 1 ? '1px solid var(--input-border-color)' : 'none',
            'border-left': i === chartConfig.chartOptions.length - 1 ? '1px solid var(--input-border-color)' : 'none',
            'border-radius': option.chartSubTitle === currentChartSelection ? '4px' : '0px',
            background: option.chartSubTitle === currentChartSelection ? 'var(--hover-dark)' : 'initial'
          }"
        >
          {{ option.chartSubTitle }}
        </div>
      </div>
      <div class="settings-dropdown" *ngIf="isSettingsOpen" #insideElement>
        <div class="graph-settings-title">GRAPH SETTINGS</div>

        <div class="graph-settings-subtitle">Show items on graph</div>

        <div class="graph-settings-options">
          <div class="graph-settings-option" *ngFor="let option of currentChartSettingsOptions">
            <div class="graph-settings-option__checkbox">
              <mat-checkbox [checked]="option.display" (change)="changeOptionDisplay(option)">
                {{ option.chartSettingTitle }}
              </mat-checkbox>
            </div>
          </div>
        </div>

        <div class="spacer"></div>

        <div class="graph-settings-actions">
          <button class="graph-actions-button cancel" (click)="graphSettingsActionEvent('cancel')">Cancel</button>
        </div>
      </div>
    </div>
  </div>
  <div class="chart" *ngIf="chartData; else spinner">
    <canvas #canvas baseChart [datasets]="currentDisplayData" [options]="chartOptions" [chartType]="chartType">
    </canvas>
  </div>
  <ng-template #spinner>
    <div class="spinner-section">
      <haulynx-spinner [active]="loading"></haulynx-spinner>
    </div>
  </ng-template>
</div>
