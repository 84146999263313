import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ConfirmationComponent, FeatureFlagActionService } from '@haulynx/services';
import { FeatureFlagDashboardModel } from '@haulynx/store';
import {
  Carrier,
  EntityTypes,
  environmentList,
  FeatureFlagDetails,
  FeatureFlagForm,
  FeatureFlagPlatform,
  FeatureFlagPopulate,
  FeatureFlagQuery,
  FeatureFlagRestriction,
  IColumns,
  User,
} from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { chain, find, get, isEmpty, isNil, map, omitBy, trim } from 'lodash';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'app-feature-flag-dashboard-dialog',
  templateUrl: './feature-flag-dashboard-dialog.component.html',
  styleUrls: ['./feature-flag-dashboard-dialog.component.scss'],
})
export class FeatureFlagDashboardDialogComponent implements OnInit, OnDestroy {
  search$ = new Subject<FeatureFlagQuery>();
  populate$ = new Subject<FeatureFlagPopulate>();

  form: FormGroup;
  userColumns: IColumns[] = [];
  carrierColumns: IColumns[] = [];
  isEnvironmentActive: Record<number, boolean> = {};
  users: Record<number, Partial<User>[]> = {};
  carriers: Record<number, Partial<Carrier>[]> = {};
  carriersFilter: Record<number, Partial<Carrier>[]> = {};
  entityTypes = EntityTypes;
  usersQueryMinLength = 4;
  rowsPerPage = 10;

  private alive = aliveWhile();

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: { featureFlagName: string },
    private dialog: MatDialog,
    private fb: FormBuilder,
    public featureFlagDashboardModel: FeatureFlagDashboardModel,
    private featureFlagDashboardService: FeatureFlagActionService,
    private dialogRef: MatDialogRef<FeatureFlagDashboardDialogComponent>
  ) {}

  ngOnInit(): void {
    const { featureFlagName = null } = this.data;

    this.userColumns = this.initColumns(EntityTypes.USER);
    this.carrierColumns = this.initColumns(EntityTypes.CARRIER);
    this.featureFlagDashboardModel.clearSelectedFeature();

    this.initForm();
    this.initEnvironments();

    if (featureFlagName) {
      this.featureFlagDashboardModel.find({ featureFlagName });
    }

    this.search$
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        withLatestFrom(this.featureFlagDashboardModel.carriers$, this.featureFlagDashboardModel.users$),
        takeUntil(this.alive)
      )
      .subscribe(
        ([data, carriers, users]: [
          FeatureFlagQuery,
          Record<number, Partial<Carrier>[]>,
          Record<number, Partial<User>[]>
        ]) => {
          const { name, type, index } = data;

          if (type === EntityTypes.USER) {
            this.searchUsers(name, users, index);
          }

          if (type === EntityTypes.CARRIER) {
            this.searchCarriers(name, carriers, index);
          }
        }
      );

    this.populate$
      .pipe(
        withLatestFrom(this.featureFlagDashboardModel.carriers$, this.featureFlagDashboardModel.users$),
        takeUntil(this.alive)
      )
      .subscribe(
        ([data, carriers, users]: [
          FeatureFlagPopulate,
          Record<number, Partial<Carrier>[]>,
          Record<number, Partial<User>[]>
        ]) => {
          const { type, index } = data;

          if (type === EntityTypes.USER) {
            this.populateUser(users, index);
          }

          if (type === EntityTypes.CARRIER) {
            this.populateCarrier(carriers, index);
          }
        }
      );

    this.featureFlagDashboardModel.carriers$
      .pipe(takeUntil(this.alive))
      .subscribe((carriersList: Record<number, Partial<Carrier>[]>) => {
        map(
          carriersList,
          (carriers: Partial<Carrier>[], index: number) =>
            (this.carriersFilter[index] = carriers.map((carrier: Partial<Carrier>) => {
              const { dot, mcNumber, name } = carrier;
              const filter = `${name} ${dot} ${mcNumber}`;

              return { ...carrier, filter };
            }))
        );
      });

    this.featureFlagDashboardModel.selected$.pipe(takeUntil(this.alive)).subscribe((feature: FeatureFlagDetails) => {
      if (feature) {
        const { environments, builds, ...featureValues } = feature;
        this.form.patchValue(featureValues);

        if (environments) {
          environments.map((featureFlagRestriction: FeatureFlagRestriction) => {
            const { environment, users, dots } = featureFlagRestriction;
            const index = environmentList.findIndex((env) => env === environment);
            const userIds = this.normalizeUserIds(users);
            const carrierDots = this.normalizeCarrierDots(dots);
            const formData: FeatureFlagForm = { environment, userIds, carrierDots, index };

            if (environment) {
              this.isEnvironmentActive[index] = true;
            }

            this.users[index] = this.normalizeUsers(users);
            this.carriers[index] = dots;
            this.assignEnvironmentsData(formData);
          });
        }

        if (builds) {
          const { android = null, ios = null } = builds;
          this.builds.patchValue({ android, ios });
        }
      }
    });

    this.platforms.valueChanges.pipe(takeUntil(this.alive)).subscribe((values: FeatureFlagPlatform) => {
      const { android, ios } = values;

      if (!ios) {
        this.builds.patchValue({ ios: null });
      }

      if (!android) {
        this.builds.patchValue({ android: null });
      }
    });
  }

  initColumns(columnType: EntityTypes): IColumns[] {
    const columns: IColumns[] = [];

    columns.push({
      label: 'Name',
      dataIndex: 'entityName',
      isCustomCell: true,
      sortConvert: this.featureFlagDashboardService.customSortDetails.bind(this.featureFlagDashboardService),
    });

    if (columnType === EntityTypes.CARRIER) {
      columns.push({
        label: 'DOT',
        dataIndex: 'dot',
      });
    }

    if (columnType === EntityTypes.USER) {
      columns.push({
        label: 'ID',
        dataIndex: 'id',
      });

      columns.push({
        label: 'Email',
        dataIndex: 'entityEmail',
        isCustomCell: true,
        sortConvert: this.featureFlagDashboardService.customSortDetails.bind(this.featureFlagDashboardService),
      });

      columns.push({
        label: 'Type',
        dataIndex: 'type',
      });
    }

    columns.push({
      label: '',
      dataIndex: 'options',
      isCustomCell: true,
      isSortable: false,
      width: '50px',
    });

    return columns;
  }

  initForm(): void {
    const platformsForm = this.getPlatformsForm();
    const buildsForm = this.getBuildsForm();

    this.form = this.fb.group({
      name: [null, Validators.required],
      description: [null, Validators.required],
      environments: this.fb.array([]),
      builds: buildsForm,
      platforms: platformsForm,
    });
  }

  getPlatformsForm(): FormGroup {
    return this.fb.group({
      android: false,
      ios: false,
      web: false,
    });
  }

  getBuildsForm(): FormGroup {
    return this.fb.group({
      android: null,
      ios: null,
    });
  }

  getEnvironmentsForm(): FormGroup {
    return this.fb.group({
      environment: null,
      dots: this.fb.array([]),
      users: this.fb.array([]),
      userToPopulate: null,
      carrierToPopulate: null,
    });
  }

  get environments(): FormArray {
    return this.form.controls.environments as FormArray;
  }

  get platforms(): FormGroup {
    return this.form.controls.platforms as FormGroup;
  }

  get builds(): FormGroup {
    return this.form.controls.builds as FormGroup;
  }

  initEnvironments(): void {
    environmentList.map((environment: string) => {
      const environmentsForm = this.getEnvironmentsForm();

      environmentsForm.patchValue({ environment });
      this.environments.push(environmentsForm);
    });
  }

  normalizeUserIds(users: Partial<User>[]): string[] {
    if (users) {
      return users.reduce((acc: string[], item: Partial<User>) => [...acc, item.id], []);
    }
  }

  normalizeCarrierDots(dots: Partial<Carrier>[]): string[] {
    if (dots) {
      return dots.reduce((acc: string[], item: Partial<Carrier>) => [...acc, item.dot], []);
    }
  }

  normalizeUsers(users: Partial<User>[]): Partial<User>[] {
    if (users) {
      return users.map((user: Partial<User>) => this.normalizeUser(user));
    }
  }

  normalizeUser(user: Partial<User>): Partial<User> {
    const carrierId = get(user, 'carrier.id', null);
    const type = carrierId ? 'Carrier' : 'Broker';

    return { ...user, type } as Partial<User>;
  }

  populateUser(users: Record<number, Partial<User>[]>, index: number): void {
    const control = this.environments.controls[index];
    const userId = control.value.userToPopulate;

    if (userId) {
      const isUserPopulated = !!find(this.users[index], (user: Partial<User>) => user.id === userId);

      if (!isUserPopulated) {
        const newUser: Partial<User> = find(users[index], (user: Partial<User>) => user.id === userId);
        const usersControl = control.get('users') as FormArray;

        this.users[index] = [...(this.users[index] || []), this.normalizeUser(newUser)];
        usersControl.push(new FormControl(userId));
      }

      control.patchValue({ userToPopulate: null });
    }
  }

  populateCarrier(carriers: Record<number, Partial<Carrier>[]>, index: number): void {
    const control = this.environments.controls[index];
    const dot = control.value.carrierToPopulate;

    if (dot) {
      const isCarrierPopulated = !!find(this.carriers[index], (carrier: Partial<Carrier>) => carrier.dot === dot);

      if (!isCarrierPopulated) {
        const newCarrier: Partial<Carrier> = find(carriers[index], (carrier: Partial<Carrier>) => carrier.dot === dot);
        const carriersControl = control.get('dots') as FormArray;

        this.carriers[index] = [...(this.carriers[index] || []), newCarrier];
        carriersControl.push(new FormControl(dot));
      }

      control.patchValue({ carrierToPopulate: null });
    }
  }

  onToggleEnvironment(event: MatSlideToggleChange, index: number): void {
    const { checked } = event;

    this.isEnvironmentActive[index] = checked;
  }

  onValueChanges(featureFlagQuery: FeatureFlagQuery): void {
    const { name } = featureFlagQuery;

    if (trim(name)) {
      this.search$.next(featureFlagQuery);
    }
  }

  onPopulate(featureFlagPopulate: FeatureFlagPopulate): void {
    const { index, type } = featureFlagPopulate;
    const control = this.environments.controls[index];

    if (type === EntityTypes.USER) {
      return control.value.userToPopulate
        ? this.populate$.next(featureFlagPopulate)
        : control.patchValue({ userToPopulate: null });
    }

    if (type === EntityTypes.CARRIER) {
      return control.value.carrierToPopulate
        ? this.populate$.next(featureFlagPopulate)
        : control.patchValue({ carrierToPopulate: null });
    }
  }

  searchUsers(email: string, users: Record<number, Partial<User>[]>, index: number): void {
    if (email.length < this.usersQueryMinLength) {
      return;
    }

    let isUserLoaded = false;

    if (users[index]) {
      isUserLoaded = !!users[index].find((user: Partial<User>) => user.email === email);
    }

    if (email && !isUserLoaded) {
      this.featureFlagDashboardModel.findUsersByEmail({ email, index });
    }
  }

  searchCarriers(name: string, carriers: Record<number, Partial<Carrier>[]>, index: number): void {
    let isCarrierLoaded = false;

    if (carriers[index]) {
      isCarrierLoaded = !!carriers[index].find((carrier: Partial<Carrier>) => carrier.name === name);
    }

    if (name && !isCarrierLoaded) {
      this.featureFlagDashboardModel.getCarriersByNameOrDot({ name, index });
    }
  }

  assignEnvironmentsData(formData: FeatureFlagForm): void {
    const { environment, userIds, carrierDots, index } = formData;
    const environmentsForm = this.getEnvironmentsForm();

    environmentsForm.setControl('users', this.fb.array(userIds || []));
    environmentsForm.setControl('dots', this.fb.array(carrierDots || []));
    environmentsForm.patchValue({ environment });
    this.environments.setControl(index, environmentsForm);
  }

  onDeleteEntity(featureFlagQuery: FeatureFlagQuery): void {
    const { name, type, index } = featureFlagQuery;

    this.dialog
      .open(ConfirmationComponent, {
        data: {
          title: 'Delete Entity',
          message: 'Are you sure you want to delete this entity?',
        },
        width: '380px',
        height: '180px',
      })
      .afterClosed()
      .subscribe((isConfirmed: boolean) => {
        if (isConfirmed) {
          if (type === EntityTypes.USER) {
            this.deleteUser(name, index);
          }

          if (type === EntityTypes.CARRIER) {
            this.deleteCarrier(name, index);
          }
        }
      });
  }

  deleteUser(userId: string, index: number): void {
    const control = this.environments.controls[index] as FormGroup;
    const userIds = control.value.users;
    const newIds = userIds.filter((id: string) => id !== userId);

    control.setControl('users', this.fb.array(newIds));
    this.users[index] = this.users[index].filter((user: Partial<User>) => user.id !== userId);
  }

  deleteCarrier(carrierDot: string, index: number): void {
    const control = this.environments.controls[index] as FormGroup;
    const carrierDots = control.value.dots;
    const newDots = carrierDots.filter((dot: string) => dot !== carrierDot);

    control.setControl('dots', this.fb.array(newDots));
    this.carriers[index] = this.carriers[index].filter((carrier: Partial<Carrier>) => carrier.dot !== carrierDot);
  }

  onSave(): void {
    const { featureFlagName } = this.data;
    let { environments, builds, ...featureFlagValue } = this.form.value;

    builds = omitBy(builds, isEmpty);
    featureFlagValue = omitBy(featureFlagValue, isNil);
    environments = environments
      .filter((restriction: FeatureFlagRestriction, index: number) => this.isEnvironmentActive[index])
      .map((restriction: FeatureFlagRestriction) =>
        chain(restriction).omit(['userToPopulate', 'carrierToPopulate']).omitBy(isEmpty).value()
      );

    let featureFlag = { ...featureFlagValue, environments };
    if (!isEmpty(builds)) {
      featureFlag = { ...featureFlag, builds };
    }

    if (featureFlagName) {
      this.featureFlagDashboardModel.update({ featureFlagName, featureFlag });
    } else {
      this.featureFlagDashboardModel.create({ featureFlag });
    }

    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }
}
