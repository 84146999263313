import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  AccountComponent,
  ActionButtonsModule,
  DataTableModule,
  DropDownModule,
  ReferralComponent,
  SpinnerModule,
} from '@haulynx/components';
import { MaterialModule } from '@haulynx/modules';
import { ClipboardModule } from 'ngx-clipboard';
import { AccountRoutingModule } from './account-routing.module';

@NgModule({
  imports: [
    CommonModule,
    AccountRoutingModule,
    ClipboardModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    DataTableModule,
    DropDownModule,
    ActionButtonsModule,
    SpinnerModule,
    MaterialModule,
  ],
  declarations: [AccountComponent, ReferralComponent],
})
export class AccountModule {}
