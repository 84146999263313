import { Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatTabNav } from '@angular/material/tabs';
import { FeatureFlag, HeaderItem, RmisProps, User } from '@haulynx/types';

@Component({
  selector: 'haulynx-header-toolbar-tabs',
  templateUrl: './header-toolbar-tabs.component.html',
  styleUrls: ['./header-toolbar-tabs.component.scss'],
})
export class HeaderToolbarTabsComponent {
  @ViewChild(MatTabNav, { static: false }) tabGroup: MatTabNav;
  @ViewChild(MatMenuTrigger) navigationDropdown: MatMenuTrigger;
  @ViewChild('triggerButton') triggerButtonRef: ElementRef;

  @Input() headerItems: HeaderItem[] = [];
  @Input() rmisProps: RmisProps;
  @Input() user: User;

  @Output() whatsNewtour = new EventEmitter();
  @Output() seletedHeaderItem = new EventEmitter<HeaderItem>();

  featureFlag: FeatureFlag = FeatureFlag.WHATS_NEW;
  private menuOpen = false;

  constructor(private renderer: Renderer2) {}

  startTour(): void {
    this.whatsNewtour.emit();
  }

  selectedItem(item: HeaderItem): void {
    this.seletedHeaderItem.emit(item);
    this.tabGroup.updatePagination();
  }

  openMenu() {
    this.menuOpen = true;
    this.navigationDropdown.openMenu();
    this.removeFocus();
  }

  closeMenu(): void {
    if (!this.menuOpen) return;
    this.navigationDropdown.closeMenu();
    this.removeFocus();
  }

  private removeFocus() {
    this.renderer.removeClass(this.triggerButtonRef.nativeElement, 'cdk-focused');
    this.renderer.removeClass(this.triggerButtonRef.nativeElement, 'cdk-program-focused');
  }
}
