import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CarrierSignupOnePageDialogComponent } from '@haulynx/components';
import { LoadsServiceService, MetaService, TitleService, UnauthenticatedLoadDetailsService } from '@haulynx/services';
import { AppModel, UserEntityService } from '@haulynx/store';
import {
  FeatureFlag,
  FFState,
  LoadRouteSource,
  LoadsServiceLoad,
  LoadsServiceLoadLocation,
  NumberOfStops,
  User,
} from '@haulynx/types';
import {
  AddressPosition,
  aliveWhile,
  getLoadsServiceLoadRoute,
  getLoadsServiceLoadWayPointsCoordinates,
  getNumberOfStops,
  normalizeLoadsServiceLoadWayPoints,
  splitAddress,
} from '@haulynx/utils';
import { get } from 'lodash';
import { combineLatest } from 'rxjs';
import { first, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-load-details-container',
  templateUrl: './load-details-container.component.html',
  styleUrls: ['./load-details-container.component.scss'],
})
export class LoadDetailsContainerComponent implements OnInit, OnDestroy {
  alive = aliveWhile();
  public load: LoadsServiceLoad;
  public route: LoadRouteSource[];
  public stops: NumberOfStops;

  private dot: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private appModel: AppModel,
    private router: Router,
    private snackBar: MatSnackBar,
    private loadsServiceService: LoadsServiceService,
    private matDialog: MatDialog,
    private metaService: MetaService,
    private titleService: TitleService,
    private userEntityService: UserEntityService,
    private unauthenticatedLoadDetailsService: UnauthenticatedLoadDetailsService
  ) {
    this.metaService.setMobileViewport();
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      const { dot = null } = params;
      this.dot = dot;
    });

    combineLatest([this.activatedRoute.params, this.activatedRoute.queryParams, this.appModel.user$])
      .pipe(take(1))
      .subscribe(([params, queryParams, user]) => {
        if (user) {
          return this.routeUser();
        }

        const { id = null } = params;
        if (!id) {
          return this.goToAvailable();
        }

        const { dot = null } = queryParams;
        this.dot = dot;
        this.unauthenticatedLoadDetailsService
          .getLoadDetails(id)
          .pipe(first())
          .subscribe(
            (load) => {
              if (!load) {
                return this.goToAvailable();
              }

              this.load = load;
              const [pickUp, dropOff] = this.load.locations;
              this.setTitle(pickUp, dropOff);
              this.getRoute(this.load);
              this.getStops(this.load);
            },
            (error) => {
              this.snackBar.open(error, null, { duration: 4000 });
            }
          );
      });
  }

  ngOnDestroy(): void {
    this.alive.destroy();
    this.metaService.removeMobileViewport();
    this.titleService.setTitle('Haulynx');
  }

  public backToSearch(): void {
    this.goToAvailable();
  }

  public async signupClick(): Promise<unknown> {
    if (this.dot) {
      return this.matDialog
        .open(CarrierSignupOnePageDialogComponent, {
          height: '80%',
        })
        .afterClosed()
        .subscribe((route: boolean) => {
          if (route) {
            this.routeUser();
          }
        });
    }

    this.router.navigateByUrl('/signup');
  }

  private getRoute(load: LoadsServiceLoad) {
    let mapBoxLocation: [number, number] = [null, null];
    const location = get(load, 'truck.lastLocation', null);
    if (location) {
      mapBoxLocation = [location['lon'], location['lat']];
    }

    const coordinates = getLoadsServiceLoadWayPointsCoordinates(load);
    this.loadsServiceService.getSingleLoadRoute(coordinates).subscribe((routes) => {
      this.route = getLoadsServiceLoadRoute({ [load.id]: routes }, load, true, mapBoxLocation);
    });
  }

  private getStops(load: LoadsServiceLoad) {
    this.stops = normalizeLoadsServiceLoadWayPoints(
      load,
      getNumberOfStops(load.locations, 'pickup'),
      getNumberOfStops(load.locations, 'dropoff')
    );
  }

  private goToAvailable(): void {
    this.router.navigate(['loads/available'], { queryParamsHandling: 'preserve' });
  }

  /**
   * Route the user away from the unauthenticated page to authenticated details page if logged in
   */
  private routeUser(): void {
    const url = this.router.url;
    const loadId = url.split('loads/')[1].split('?')[0];

    this.appModel.user$.pipe(takeUntil(this.alive)).subscribe((user: User) => {
      if (user.carrier) {
        this.router.navigate([`dashboard/loads/search/all/details/${loadId}`], {
          queryParamsHandling: 'preserve',
        });
      }
      if (user.broker) {
        this.userEntityService.featureFlags$.pipe(take(1)).subscribe((features: FFState) => {
          if (features[FeatureFlag.LOAD_OVERVIEW]) {
            return this.router.navigate([`loads/${loadId}/overview`], {
              queryParamsHandling: 'preserve',
            });
          }

          return this.router.navigate([`dashboard/load-feeds/my/${loadId}`], {
            queryParamsHandling: 'preserve',
          });
        });
      }
    });
  }

  private setTitle(pickup: LoadsServiceLoadLocation, dropoff: LoadsServiceLoadLocation) {
    const origin = splitAddress(pickup && pickup.address, AddressPosition.CITY, true);
    const destination = splitAddress(dropoff && dropoff.address, AddressPosition.CITY, true);
    const originState = splitAddress(pickup.address, AddressPosition.STATE, true);
    const destinationState = splitAddress(dropoff.address, AddressPosition.STATE, true);
    const originNoVowels =
      origin.substring(0, 1).toUpperCase() +
      origin
        .substring(1)
        .replace(/[aeiouyAEIOUY]/g, '')
        .toUpperCase();
    const destinationNoVowels =
      destination.substring(0, 1).toUpperCase() +
      destination
        .substring(1)
        .replace(/[aeiouyAEIOUY]/g, '')
        .toUpperCase();
    this.titleService.setTitle(
      `${originNoVowels},${originState}→${destinationNoVowels},${destinationState} - Load Details - Haulynx`
    );
  }
}
