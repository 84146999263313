import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { toLower } from 'lodash';

enum StatusClass {
  POSITIVE = 'positive-status',
  NEGATIVE = 'negative-status',
  NONE = 'none',
}

const getStatusClass = (status: string): StatusClass => {
  if (!status) {
    return StatusClass.NONE;
  }

  switch (toLower(status)) {
    case 'act':
    case 'active':
      return StatusClass.POSITIVE;
    default:
      return StatusClass.NEGATIVE;
  }
};

@Component({
  selector: 'app-carrier-status',
  templateUrl: './carrier-status.component.html',
  styleUrls: ['./carrier-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarrierStatusComponent implements OnChanges {
  @Input() isLoading: boolean;
  @Input() status: string;

  statusClass: string;

  ngOnChanges(changes: SimpleChanges): void {
    const { status } = changes;

    if (status) {
      this.statusClass = getStatusClass(status.currentValue);
    }
  }
}
