<div class="address-favorite-form">
  <div class="address-favorite-form__title">
    <span>Save Location</span>
    <span>{{ form.get('address').value }}</span>
  </div>

  <ng-container *ngIf="favoriteAddressModel.isLoadingUserForm$ | async; else showFavoriteForm">
    <app-spinner [active]="true"></app-spinner>
  </ng-container>

  <ng-template #showFavoriteForm>
    <form class="form" [formGroup]="form">
      <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
        <mat-label> Location Name </mat-label>

        <input matInput placeholder="Enter Location Name" formControlName="name" />
      </mat-form-field>

      <a [disabled]="!form.get('address').value" mat-button (click)="save($event)" data-test="save-location">Save</a>
    </form>
  </ng-template>
</div>
