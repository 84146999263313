import { animate, AnimationBuilder, AnimationFactory, AnimationPlayer, style } from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { aliveWhile } from '@haulynx/utils';
import { CarouselItemElementDirective } from 'libs/directives/src/lib/shared/carousel/carousel-item-element.directive';
import { CarouselItemDirective } from 'libs/directives/src/lib/shared/carousel/carousel-item.directive';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselComponent implements AfterViewInit, OnInit {
  @Output() clicked = new EventEmitter<void>();
  @ContentChildren(CarouselItemDirective) items: QueryList<CarouselItemDirective>;
  @ViewChildren(CarouselItemElementDirective, { read: ElementRef })
  private itemElements: QueryList<ElementRef>;
  @ViewChild('carousel') private carousel: ElementRef;
  @Input() timing = '650ms ease-in';
  @Input() timer: boolean = false;
  private alive = aliveWhile();
  private player: AnimationPlayer;
  private itemWidth: number;
  private currSlide = 0;
  private currSlide$ = new BehaviorSubject(this.currSlide);
  carouselWrapperStyle = {};

  constructor(private builder: AnimationBuilder) {}
  ngOnInit(): void {
    if (this.timer) this.slideTimer();
    this.currSlide$.pipe(takeUntil(this.alive)).subscribe((value) => {
      this.currSlide = value;
      this.transitionCarousel();
    });
  }

  ngAfterViewInit() {
    this.reSizeCarousel();
  }

  public onButtonClick() {
    this.clicked.emit();
  }

  bubbleClick(index: number) {
    this.currSlide = index % this.items.length;
    this.currSlide$.next(this.currSlide);
  }

  private slideTimer() {
    setInterval(() => {
      if (this.currSlide < this.items.length - 1) {
        this.currSlide++;
      } else {
        this.currSlide = 0;
      }
      this.currSlide$.next(this.currSlide);
    }, 6000);
  }

  private buildAnimation(offset) {
    return this.builder.build([animate(this.timing, style({ transform: `translateX(-${offset}px)` }))]);
  }

  transitionCarousel() {
    if (this.carousel) {
      const offset = this.currSlide * this.itemWidth;
      const myAnimation: AnimationFactory = this.buildAnimation(offset);
      this.player = myAnimation.create(this.carousel.nativeElement);
      this.player.play();
    }
  }

  reSizeCarousel(): void {
    if (this.itemElements) {
      this.itemWidth = this.itemElements?.first?.nativeElement?.getBoundingClientRect().width;
      this.carouselWrapperStyle = {
        width: `${this.itemWidth}px`,
      };
      this.transitionCarousel();
    }
  }
}
