import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserEntityService } from '@haulynx/store';
import { aliveWhile, EMAIL_REGEX } from '@haulynx/utils';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'haulynx-admin-verify-email-dialog',
  templateUrl: './admin-verify-email-dialog.component.html',
  styleUrls: ['./admin-verify-email-dialog.component.scss'],
})
export class AdminVerifyEmailDialogComponent implements OnDestroy {
  public error: string = null;
  public success: string = null;
  public loading: Observable<boolean>;
  public verifyEmailForm = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.pattern(EMAIL_REGEX)]),
  });

  private alive = aliveWhile();

  constructor(private userEntityService: UserEntityService) {
    this.verifyEmailForm.valueChanges.pipe(takeUntil(this.alive)).subscribe(() => {
      this.error = null;
      this.success = null;
    });

    this.loading = this.userEntityService.adminEmailVerificationManager.isLoading$;
    this.userEntityService.adminEmailVerificationManager.onResponse$
      .pipe(takeUntil(this.alive))
      .subscribe((response: any) => {
        (this.error = null), (this.success = null);
        const { email } = this.verifyEmailForm.value;

        if (response?.error) {
          if (response?.error?.message?.includes('already verified')) {
            this.error = `The user with email ${email} is already verified`;
          } else if (response?.error?.message?.includes('provided identifier')) {
            this.error = `The user with email ${email} does not exist`;
          } else {
            this.error = response?.error?.message || 'Could not update ';
          }

          return;
        }

        this.success = `Successfully verified ${email}`;
      });
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }

  public verifyEmail() {
    const { email } = this.verifyEmailForm.value;
    this.userEntityService.adminEmailVerificationManager.dispatch({ email });
  }
}
