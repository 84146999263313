import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@haulynx/modules';
import { GreyInputChangeDetectionEnabledComponent } from './grey-input-change-detection-enabled.component';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  declarations: [GreyInputChangeDetectionEnabledComponent],
  imports: [CommonModule, MaterialModule, FlexLayoutModule, ReactiveFormsModule, NgxMaskModule.forRoot()],
  exports: [GreyInputChangeDetectionEnabledComponent],
})
export class GreyInputChangeDetectionEnabledModule {}
