export interface ChartConfig {
  chartTitle: string;
  chartOptions: ChartOption[];
}

export interface ChartOption {
  chartSubTitle: string;
  chartSettingsOptions: ChartSettingOption[];
}

export interface ChartSettingOption {
  chartSettingTitle: string;
  dataPath: string;
  display: boolean;
}

export enum ChartIdentifier {
  LOADS_BOOKED = 'loadsBooked',
  BIDS_ACCEPTED = 'bidsAccepted',
  BIDS_MARGIN = 'bidsMargin',
  LOADS_MARGIN = 'loadsMargin',
  BIDS_REVENUE = 'bidsRevenue',
  LOADS_REVENUE = 'loadsRevenue',
  BIDS_RATE = 'bidsRate',
  LOADS_RATE = 'loadsRate',
}
