<div class="bulk-actions-dialog">
  <div *ngIf="option === LoadBulkAction.UPDATE_MAX_BUY">
    <h4 class="bulk-actions-dialog__title">MAX BUY</h4>
    <p class="bulk-actions-dialog__description">
      Enter change in max buy. You can adjust using a dollar amount or a percentage amount
    </p>
  </div>

  <div *ngIf="option === LoadBulkAction.CONFIRM_UPDATE_MAX_BUY">
    <h4 class="bulk-actions-dialog__title">CONFIRM</h4>
    <p class="bulk-actions-dialog__description">You are about to edit max buy for {{ loads?.length }} loads.</p>
    <br />
    <br />
  </div>

  <div *ngIf="option === LoadBulkAction.ASSIGN_REPS">
    <h4 class="bulk-actions-dialog__title">ASSIGNED TO</h4>
  </div>

  <div *ngIf="option === LoadBulkAction.PRIORITY_CHANGE">
    <h4 class="bulk-actions-dialog__title">SET PRIORITY</h4>
  </div>

  <div *ngIf="option === LoadBulkAction.ADJUST_PERCENTAGE">
    <h4 *ngIf="loads.length === 1" class="bulk-actions-dialog__title">Adjust Percentage for {{ loads.length }} Lane</h4>
    <h4 *ngIf="loads.length > 1" class="bulk-actions-dialog__title">Adjust Percentage for {{ loads.length }} Lanes</h4>
    <p class="bulk-actions-dialog__description">Enter Percentage Shift (+/-)</p>
  </div>

  <div *ngIf="option === LoadBulkAction.CONFIRM_UPDATE_ADJUST_PERCENTAGE">
    <h4 class="bulk-actions-dialog__title">ARE YOU CERTAIN YOU WANT MAKE THIS ADJUSTMENT?</h4>
    <p *ngIf="loads.length === 1" class="bulk-actions-dialog__description">
      You will adjust {{ loads?.length }} lane by {{ data?.confirmationValue }}%. This warning will trigger any time you
      are making a dramatic change.
    </p>
    <p *ngIf="loads.length > 1" class="bulk-actions-dialog__description">
      You will adjust {{ loads?.length }} lanes by {{ data?.confirmationValue }}%. This warning will trigger any time
      you are making a dramatic change.
    </p>
    <br />
    <br />
  </div>

  <div *ngIf="option === LoadBulkAction.ADJUST_DOLLAR_AMOUNT">
    <h4 *ngIf="loads.length === 1" class="bulk-actions-dialog__title">
      Adjust Dollar Amnt. for {{ loads.length }} Lane
    </h4>
    <h4 *ngIf="loads.length > 1" class="bulk-actions-dialog__title">
      Adjust Dollar Amnt. for {{ loads.length }} Lanes
    </h4>
    <p class="bulk-actions-dialog__description">Enter Dollar Shift (+/-)</p>
  </div>

  <div *ngIf="option === LoadBulkAction.CONFIRM_UPDATE_ADJUST_DOLLAR_AMOUNT">
    <h4 class="bulk-actions-dialog__title">ARE YOU CERTAIN YOU WANT MAKE THIS ADJUSTMENT?</h4>
    <p *ngIf="loads.length === 1" class="bulk-actions-dialog__description">
      You will adjust {{ loads?.length }} lane by {{ data?.confirmationValue | currency }}. This warning will trigger
      any time you are making a dramatic change.
    </p>
    <p *ngIf="loads.length > 1" class="bulk-actions-dialog__description">
      You will adjust {{ loads?.length }} lanes by {{ data?.confirmationValue | currency }}. This warning will trigger
      any time you are making a dramatic change.
    </p>
    <br />
    <br />
  </div>

  <div *ngIf="option === LoadBulkAction.DELETE_MISSION">
    <h4 class="bulk-actions-dialog__title">Deactivate Mission</h4>
    <p *ngIf="loads.length > 0" class="bulk-actions-dialog__description" data-test="deactivate-mission-text">
      By deactivating this mission, this may impact any business done in brokerOS or other teams. Are you sure you want
      to deactivate mission {{ this.loads[0].mission.id }}?
    </p>
    <br />
    <br />
  </div>

  <form [formGroup]="form" (ngSubmit)="submit()">
    <div class="bulk-actions-dialog__row">
      <div *ngIf="option === LoadBulkAction.UPDATE_MAX_BUY" class="max-buy-form-fields">
        <mat-form-field
          class="app-form-field bulk-actions-dialog__field-max-buy"
          hideRequiredMarker
          appearance="fill"
          floatLabel="always"
        >
          <input matInput formControlName="maxBuy" autocomplete="off" placeholder="ex: -10%, $100" />
        </mat-form-field>
        <mat-form-field
          class="app-form-field bulk-actions-dialog__field-max-buy"
          hideRequiredMarker
          appearance="fill"
          floatLabel="always"
        >
          <mat-select formControlName="maxBuyType">
            <mat-select-trigger>
              <mat-icon [svgIcon]="selectedMaxBuyOption"></mat-icon>
            </mat-select-trigger>
            <mat-option *ngFor="let option of maxbuyChangeOptions" [value]="option.value">
              <mat-icon [svgIcon]="option.key"></mat-icon>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="option === LoadBulkAction.ASSIGN_REPS">
        <div class="brokerForm" #dropdown>
          <mat-icon class="brokerForm__icon">search</mat-icon>
          <input
            class="brokerForm__input"
            matInput
            [formControl]="filterInput"
            placeholder="Search for broker name"
            autocomplete="off"
            data-test="broker-dropdown"
          />
          <div class="dropdown" *ngIf="showDropDown">
            <div class="dropdown__container">
              <div
                class="dropdown__broker"
                *ngFor="
                  let broker of filteredBrokers | async | brokerList: data.isCompanyAdmin:data.usxId;
                  let i = index
                "
              >
                <div class="dropdown__checkbox-container">
                  <div class="dropdown__checkbox">
                    <mat-checkbox
                      [checked]="getIsChecked(broker)"
                      (change)="selectBroker(broker)"
                      data-test="broker-name"
                    >
                      {{ broker.label }}</mat-checkbox
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="brokerContainer">
          <div class="selectedBrokers" *ngFor="let data of brokers">
            <button mat-button class="deleteBroker" (click)="deleteItem(data)" data-test="remove-broker">
              {{ data }} <mat-icon>close</mat-icon>
            </button>
          </div>
        </div>
      </div>

      <div *ngIf="option === LoadBulkAction.PRIORITY_CHANGE">
        <mat-radio-group formControlName="priorityChange">
          <mat-grid-list cols="4" rowHeight="1:1.5">
            <mat-grid-tile>
              <div [ngClass]="{ selected: form.controls['priorityChange'].value === 'none' }">
                <mat-radio-button [value]="'none'" data-test="priority-none">
                  <img src="assets/images/priority_zero.svg" />
                </mat-radio-button>
              </div>
            </mat-grid-tile>
            <mat-grid-tile>
              <div [ngClass]="{ selected: form.controls['priorityChange'].value === 'low' }">
                <mat-radio-button [value]="'low'" data-test="priority-low">
                  <img src="assets/images/priority_low.svg" />
                </mat-radio-button>
              </div>
            </mat-grid-tile>
            <mat-grid-tile>
              <div [ngClass]="{ selected: form.controls['priorityChange'].value === 'medium' }">
                <mat-radio-button [value]="'medium'" data-test="priority-medium">
                  <img src="assets/images/priority_medium.svg" />
                </mat-radio-button>
              </div>
            </mat-grid-tile>
            <mat-grid-tile>
              <div [ngClass]="{ selected: form.controls['priorityChange'].value === 'high' }">
                <mat-radio-button [value]="'high'" data-test="priority-high">
                  <img src="assets/images/priority_high.svg" />
                </mat-radio-button>
              </div>
            </mat-grid-tile>
          </mat-grid-list>
        </mat-radio-group>
      </div>

      <div *ngIf="option === LoadBulkAction.ADJUST_PERCENTAGE">
        <mat-form-field
          class="app-form-field bulk-actions-dialog__field-max-buy"
          hideRequiredMarker
          appearance="fill"
          floatLabel="always"
        >
          <input matInput formControlName="adjustPercentage" autocomplete="off" placeholder="ex: -10%" />
          <mat-error *ngIf="form.get('adjustPercentage').errors">Please enter value between -100% and 100% </mat-error>
        </mat-form-field>
      </div>

      <div *ngIf="option === LoadBulkAction.ADJUST_DOLLAR_AMOUNT">
        <mat-form-field
          class="app-form-field bulk-actions-dialog__field-max-buy"
          hideRequiredMarker
          appearance="fill"
          floatLabel="always"
        >
          <input
            matInput
            autocomplete="off"
            placeholder="ex: $112.12"
            currencyMask
            formControlName="adjustDollarAmount"
            [options]="{ prefix: '$ ', thousands: ',', decimal: '.', allowNegative: true, align: 'left' }"
          />
          <mat-error *ngIf="form.get('adjustDollarAmount').errors"
            >Please enter a valid positive or negative currency amount.
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="bulk-actions-dialog__action" [ngClass]="{ priority: form.controls['priorityChange'] }">
      <button
        mat-dialog-close
        class="hlx-button-command hlx-button-command--cancel hlx-button-command--border-white bulk-actions-dialog__cancel-button"
        data-test="cancel-button"
      >
        Cancel
      </button>

      <button
        type="submit"
        class="hlx-button-command hlx-button-command--book bulk-actions-dialog__submit-button"
        [disabled]="form.invalid || (isLoading$ | async)"
        [ngStyle]="{ 'min-width': buttonSize }"
        data-test="submit-button"
      >
        <mat-spinner
          class="spinner"
          [diameter]="18"
          *ngIf="isLoading$ | async; else textLabel"
          mode="indeterminate"
        ></mat-spinner>
        <ng-template #textLabel>Submit</ng-template>
      </button>
    </div>
  </form>
</div>
