import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LoadsServiceLoad, LoadRouteSource, NumberOfStops } from '@haulynx/types';

@Component({
  selector: 'app-unauthenticated-load-details',
  templateUrl: './unauthenticated-load-details.component.html',
  styleUrls: ['./unauthenticated-load-details.component.scss'],
})
export class UnauthenticatedLoadDetailsComponent {
  @Input() load: LoadsServiceLoad;
  @Input() stops: NumberOfStops = null;
  @Input() route: LoadRouteSource[] = [];
  @Output() signup = new EventEmitter();

  public signupClick(): void {
    this.signup.emit();
  }
}
