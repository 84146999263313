import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'haulynx-blue-link-text',
  templateUrl: './blue-link-text.component.html',
  styleUrls: ['./blue-link-text.component.scss'],
})
export class BlueLinkTextComponent {
  @Input() icon = 'chevron_right';
  @Input() disabled = false;
  @Input() loading = false;
  @Input() text = 'text';
  @Output() clicked = new EventEmitter<void>();

  public buttonClick() {
    if (!this.loading && !this.disabled) {
      this.clicked.emit();
    }
  }
}
