import { AbstractControl, NgControl } from '@angular/forms';
import { Directive, EventEmitter, Input, Output } from '@angular/core';

export interface TransformEvent {
  control: AbstractControl;
  value: string;
}

@Directive({
  selector: '[appBindQueryParam]',
})
export class BindQueryParamDirective {
  @Input('appBindQueryParam') paramKey: string;
  @Output() transformValue: EventEmitter<TransformEvent> = new EventEmitter();

  constructor(private ngControl: NgControl) {}

  ngOnInit(): void {
    const queryParams = new URLSearchParams(location.search);

    const value = queryParams.get(this.paramKey);
    if (value) {
      if (this.transformValue.observers.length) {
        return this.transformValue.next({ control: this.ngControl.control, value });
      }

      this.ngControl.control.patchValue(value);
    }
  }
}
