<data-table
  [data]="items"
  [loading]="isLoading"
  [displayedColumns]="columns"
  [stickyHeader]="stickyHeader"
  [showPagination]="false"
  [templateCellRef]="relationsTemplateRef"
  cls="relations"
  data-test="relations"
>
  <ng-template #relationsTemplateRef let-row="row" let-column="column">
    <ng-container *ngIf="column === 'carrierName'">
      {{ row?.carrierName }}
    </ng-container>

    <ng-container *ngIf="column === 'mcNumber'">
      {{ row?.mcNumber }}
    </ng-container>

    <ng-container *ngIf="column === 'volume'">
      <div *ngIf="row?.volume?.mtd"><span class="relations__label">MTD:</span> {{ row?.volume?.mtd }} loads</div>
      <div *ngIf="row?.volume?.priorMonth">
        <span class="relations__label">Prior Month:</span> {{ row?.volume?.priorMonth }} loads
      </div>
      <div *ngIf="row?.volume?.ytd"><span class="relations__label">YTD:</span> {{ row?.volume?.ytd }} loads</div>
    </ng-container>

    <ng-container *ngIf="column === 'lastContacted'">
      <div *ngIf="row?.lastContacted?.timestamp">
        {{ row?.lastContacted?.timestamp | date: dateFormat:estOffset }} EST
      </div>
      <div *ngIf="row?.lastContacted?.person">
        {{ row?.lastContacted?.person }}
      </div>
    </ng-container>

    <ng-container *ngIf="column === 'contactedToday'">
      <mat-checkbox
        color="primary"
        [(ngModel)]="row.contactedToday"
        [disabled]="row.contactedToday"
        (change)="onChange(row)"
      ></mat-checkbox>
    </ng-container>

    <ng-container *ngIf="column === 'options'">
      <button mat-button class="relations__button--delete" (click)="onDelete(row.id)">
        <mat-icon svgIcon="haulynx-trash-gray"></mat-icon>
      </button>
    </ng-container>
  </ng-template>
</data-table>
