<div class="search-number-range-container" #wrapper>
  <form [formGroup]="form" autocomplete="off" *ngIf="focusedFilter">
    <div class="snr-chip-title">{{ focusedFilter.name }}:</div>
    <div class="input-container">
      <div class="form-prefix-suffix" *ngIf="focusedFilter.formPrefix">{{ focusedFilter.formPrefix }}</div>
      <input
        matInput
        #snrFirstInput
        type="number"
        class="snr-mat-input{{ isMinFormValid() ? '' : '--invalid' }}"
        formControlName="{{ minFormName }}"
        placeholder="search"
        data-test="minField"
      />
      <div class="form-prefix-suffix" *ngIf="focusedFilter.formSuffix">{{ focusedFilter.formSuffix }}</div>
    </div>
    <span class="snr-number-divider"> - </span>
    <div class="input-container">
      <div class="form-prefix-suffix" *ngIf="focusedFilter.formPrefix">{{ focusedFilter.formPrefix }}</div>
      <input
        matInput
        type="number"
        class="snr-mat-input{{ isMaxFormValid() ? '' : '--invalid' }}"
        formControlName="{{ maxFormName }}"
        placeholder="number"
        data-test="maxField"
      />
      <div class="form-prefix-suffix" *ngIf="focusedFilter.formSuffix">{{ focusedFilter.formSuffix }}</div>
    </div>

    <mat-icon class="snr-close-icon" (click)="removeFocusedFilter()"> close </mat-icon>
  </form>
</div>
