import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AppModel } from '@haulynx/store';
import { User } from '@haulynx/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class BrokerLoadsGuard implements CanActivate {
  constructor(private appModel: AppModel, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.appModel.user$.pipe(
      map((user: User) => {
        if (user && user.broker) {
          return this.router.parseUrl('/dashboard/loads/search/all');
        } else {
          return true;
        }
      })
    );
  }
}
