<div
  class="file-drag-and-drop"
  fxLayout="row"
  fxLayoutAlign="center center"
  appDragDrop
  (onFileDropped)="onFileDropped($event)"
  [ngClass]="selectionIsValid ? 'border-valid' : 'border-invalid'"
>
  <ng-container *ngIf="selectionIsValid; then valid; else invalid"></ng-container>

  <ng-template #valid>
    <label for="document-upload">
      Upload your document by dragging and dropping or <span class="browse-link">browse here</span>
    </label>
  </ng-template>
  <ng-template #invalid>
    <label for="document-upload">
      The {{ isMultiUpload ? 'document(s)' : 'document' }} you selected was an invalid type, please try again.
      <br /><br />
      <span class="browse-link">Browse here</span> or drag and drop.
    </label>
  </ng-template>

  <input
    #inputEl
    type="file"
    id="document-upload"
    [accept]="acceptedFileExtensions.join(',')"
    [multiple]="isMultiUpload"
    (change)="onFileChangeEvent($event)"
  />
</div>
