import { Pipe, PipeTransform } from '@angular/core';
import { Duration as DateFnsDuration, intervalToDuration, format as dateFormat, formatDistance } from 'date-fns';
import { padStart, split, toString } from 'lodash';
import { DateTimePipe } from './date-time.pipe';

@Pipe({
  name: 'timeDiff',
})
export class TimeDiffPipe implements PipeTransform {
  transform(
    time1: number,
    time2: number = new Date().valueOf(),
    format: TimeFormat = 'mm:ss',
    timezone = 'America/Phoenix'
  ): string {
    if (time1 == null || time2 == null) {
      return '';
    }
    const duration = intervalToDuration({
      start: time1,
      end: time2,
    });
    return this.format(duration, time1, format, timezone);
  }

  private format(duration: DateFnsDuration, time1: number, format: TimeFormat, timezone): string {
    switch (format) {
      case 'mm:ss':
        return `${padStart(toString(duration.minutes), 2, '0')}:${padStart(toString(duration.seconds), 2, '0')}`;
      case 'dd hh:mm a':
        return `${toString(duration.days)}d ago ${dateFormat(new Date(time1), 'hh:mm a')}`;
      case 'estimate':
        const timePipe = new DateTimePipe();
        const time = timePipe.transform(time1, timezone, 'HH:mm zzz');
        return duration.days === 0
          ? `${duration.hours > 0 ? toString(duration.hours) + 'h ' : ''}${
              duration.minutes > 0 ? toString(duration.minutes) + 'm ' : duration.hours > 0 ? '' : 'less than 1m '
            }ago`
          : `${toString(duration.days)}d ago ${time}`;

      case 'all':
      default:
        return this.getElements(duration)
          .map((el) => {
            return `${duration[el.key]} ${duration[el.key] === 1 ? el.output : el.plural}`;
          })
          .join(', ');
    }
  }

  private getElements(duration: DateFnsDuration): TimeElement[] {
    const included: TimeElement[] = [];
    if (duration.days) {
      included.push({ key: 'days', output: 'Day', plural: 'Days' });
    }
    if (duration.hours) {
      included.push({ key: 'hours', output: 'Hr', plural: 'Hrs' });
      included.push({ key: 'minutes', output: 'Min', plural: 'Mins' });
    } else if (duration.minutes) {
      included.push({ key: 'minutes', output: 'Min', plural: 'Mins' });
    }

    included.push({ key: 'seconds', output: 'Sec', plural: 'Sec' });
    return included;
  }
}

type TimeFormat = 'mm:ss' | 'dd hh:mm a' | 'all' | 'estimate';
type TimeElement = { key: keyof DateFnsDuration; output: string; plural: string };
