import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@haulynx/modules';
import { BlueCheckboxComponent } from './blue-checkbox.component';

@NgModule({
  declarations: [BlueCheckboxComponent],
  imports: [CommonModule, MaterialModule],
  exports: [BlueCheckboxComponent],
})
export class BlueCheckboxModule {}
