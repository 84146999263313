import { EnvironmentName } from '../environment/environment';
import { FFState } from '../feature-flag/feature-flag.state';
import { User } from '../generic/user';

export interface SessionInformation {
  token: string;
  user: User;
  environment: EnvironmentName;
  featureFlags: FFState;
}
