import { DecimalPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormArray } from '@angular/forms';
import { PaymentDetailsVmService } from '@haulynx/services';
import { AssignLoadCarrierFormMode, PaymentsTypeForm, PaymentItem } from '@haulynx/types';
import { range } from 'lodash';
import { calculateLoadTotalCost } from '@haulynx/types';

@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentDetailsComponent implements OnChanges, OnInit {
  @Input() form: FormArray;
  @Input() formData: PaymentsTypeForm[] = [];
  @Input() formMode: AssignLoadCarrierFormMode;
  @Input() canEdit = true;
  @Output() deletePayType = new EventEmitter<{ index: number; payment: PaymentsTypeForm }>();
  @Output() addPayType = new EventEmitter();
  @Output() editPayType = new EventEmitter<{ index: number; payment: PaymentsTypeForm }>();
  @Output() newTotal = new EventEmitter<number>();

  assignLoadCarrierFormMode = AssignLoadCarrierFormMode;
  private _total = 0;
  get total(): number {
    return this._total;
  }
  set total(val: number) {
    this._total = val;
    this.newTotal.emit(this._total);
  }

  constructor(
    private paymentDetailsVmService: PaymentDetailsVmService,
    private cd: ChangeDetectorRef,
    private decimalPipe: DecimalPipe
  ) {}

  onAddPayType(event: Event): void {
    event.stopPropagation();
    this.addPayType.emit();
  }

  onDeletePayType(event: Event, index: number, payment: PaymentsTypeForm): void {
    event.stopPropagation();

    this.deletePayType.emit({ index, payment });
  }

  onEditPayType(index: number, payment: PaymentsTypeForm): void {
    const total = this.getTotalAmount(this.form.getRawValue());

    if (this.form.valid) {
      this.editPayType.emit({
        index,
        payment: { ...payment, paymentType: { ...payment.paymentType, quantity: payment.quantity } },
      });
    }

    if (total) {
      this.total = total;
    }
  }

  getTotalAmount(formData: PaymentsTypeForm[]): number {
    return calculateLoadTotalCost((formData as unknown) as PaymentItem[]);
  }

  ngOnInit(): void {
    if (!this.form) {
      this.form = this.paymentDetailsVmService.createFormArray();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { formData } = changes;

    if (formData && this.form) {
      this.total = this.getTotalAmount(formData.currentValue);
      const size =
        formData.currentValue.length >= this.form.controls.length
          ? formData.currentValue.length
          : this.form.controls.length;

      range(size).map((val, index) => {
        if (formData.currentValue[index] && this.form.at(index)) {
          const newValue = { id: null, ...formData.currentValue[index] };
          this.form.at(index).setValue(newValue);
        }

        if (formData.currentValue[index] && !this.form.at(index)) {
          const newControl = this.paymentDetailsVmService.create(formData.currentValue[index]);
          this.form.insert(index, newControl);
        }

        if (!formData.currentValue[index] && this.form.at(index)) {
          this.form.removeAt(index);
        }
      });
      // Forms behave pseudo-asynchronously when changing them, so rerun change detection after a cycle.
      setTimeout(() => {
        this.cd.detectChanges();
      });
    }
  }
}
