import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  AdminVerifyEmailDialogModule,
  AppNotificationModule,
  LogoutModule,
  SideNavModule,
  ToolbarModule,
  TourModule,
} from '@haulynx/components';
import { DirectivesModule } from '@haulynx/directives';
import { HeaderModule } from '@haulynx/header';
import { MaterialModule } from '@haulynx/modules';
import { AppNotificationStateModule, LoadStoreModule, MilestoneStoreModule } from '@haulynx/store';
import { SharedModule } from 'primeng/api';
import { PrivacyModule } from '../main-site/privacy/privacy.module';
import { RootRoutingModule } from './root-routing.module';
import { RootComponent } from './root.component';

@NgModule({
  declarations: [RootComponent],
  imports: [
    CommonModule,
    RootRoutingModule,
    HeaderModule,
    DirectivesModule,
    FormsModule,
    MilestoneStoreModule,
    PrivacyModule,
    LoadStoreModule,
    TourModule,
    MaterialModule,
    SideNavModule,
    AppNotificationModule,
    ToolbarModule,
    SharedModule,
    AppNotificationStateModule,
    AdminVerifyEmailDialogModule,
    LogoutModule,
  ],
})
export class RootModule {}
