import { Carrier } from '../carrier';
import { User } from './user';

export class Invitation {
  inviter: User;
  recipient: {
    contactName: string;
    companyName: string;
    dot: string;
    email: string;
    phone: string;
  };
  haulynxRecipientInfo: Carrier;
  date: number;
}
