<p-table
  class="pagination-table-main"
  [value]="dataSource"
  [rows]="showingRowCountOnPaginator"
  [showCurrentPageReport]="true"
  [rowsPerPageOptions]="[10, 25, 50]"
  [paginator]="showBottomPaginator"
  [scrollHeight]="tableConfig?.scrollHeight"
  [currentPageReportTemplate]="'{first} / {last} of {totalRecords}'"
  [rowHover]="true"
  [scrollable]="true"
>
  <!-- TABLE BODY -->
  <ng-template pTemplate="body" let-data let-index="rowIndex">
    <tr class="body-row">
      <ng-container
        *ngTemplateOutlet="
          templateCellRef;
          context: {
            row: data,
            rowIndex: index,
            rowCount: getRowCount()
          }
        "
      >
      </ng-container>
    </tr>
  </ng-template>

  <!-- TABLE ROW FOR EMPTY MESSAGE -->
  <ng-template pTemplate="emptymessage">
    <tr>
      <td class="empty-message body-section">No Data Available</td>
    </tr>
  </ng-template>

  <ng-template pTemplate="header">
    <div class="header-paginator">
      <mat-spinner [diameter]="24" *ngIf="loading" class="pagination-spinner-wrapper"></mat-spinner>
      <app-pagination
        [pageData]="_paginationData"
        [pageTotal]="tableConfig.pageAmount"
        [rowCount]="getRowCount()"
        [loading]="loading"
        (page)="onPage($event)"
        *ngIf="tableConfig?.showTopPaginator && !loading"
      ></app-pagination>
      <div class="sortable-headers" *ngIf="tableConfig.isSortable">
        <span *ngIf="!buttonSort" [innerHTML]="'sort'"></span>
        <app-column-sort
          [buttonSort]="buttonSort ? sortingVal.value : null"
          [sortConfig]="tableConfig.sortConfig"
          [dropdownAlign]="'right'"
          (selectOption)="onSelectOption($event)"
          data-test="app-column-sort"
        ></app-column-sort>
      </div>
    </div>
  </ng-template>
</p-table>
