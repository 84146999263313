import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LoadActiveEffects } from './load-active.effects';
import { LoadOffersEffects } from './load-offers.effects';
import { LoadEffects } from './load.effects';
import { loadReducer } from './load.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('loads', loadReducer),
    EffectsModule.forFeature([LoadEffects, LoadOffersEffects, LoadActiveEffects]),
  ],
  providers: [LoadActiveEffects, LoadOffersEffects],
})
export class LoadStoreModule {}
