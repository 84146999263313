import { StickyDirection } from '@angular/cdk/table';
import { ChangeDetectionStrategy, Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { WindowRef } from '@haulynx/services';
import { LoadIdentifierType, LoadsServiceLoad, BookStatus } from '@haulynx/types';

@Component({
  selector: 'app-confirm-book-mission',
  templateUrl: './confirm-book-mission.component.html',
  styleUrls: ['./confirm-book-mission.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmBookMissionComponent {
  carrierDot: string;
  loadId: string;
  headHaul: LoadsServiceLoad;
  backHaul: LoadsServiceLoad;
  loadIdentifierType = LoadIdentifierType;
  bookStatus = BookStatus;

  constructor(
    @Optional() private dialogRef: MatDialogRef<ConfirmBookMissionComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { carrierDot: string; loadId: string; headHaul: LoadsServiceLoad; backHaul: LoadsServiceLoad },
    public router: Router,
    public windowRef: WindowRef
  ) {
    const { carrierDot = null, loadId = null, headHaul = null, backHaul = null } = data;

    this.carrierDot = carrierDot;
    this.loadId = loadId;
    this.headHaul = headHaul;
    this.backHaul = backHaul;
  }

  navigateToBackHaul(otherLoad) {
    this.windowRef.getNativeWindow().open(`loads/${otherLoad.id}/overview/booking`, '_blank');
  }

  save(): void {
    this.dialogRef.close(true);
  }

  cancel(): void {
    this.dialogRef.close(false);
  }
}
