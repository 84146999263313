import { BookLoadHttpStatus } from '@haulynx/types';
import { MapQueue } from '@haulynx/utils';
import { createFeatureSelector } from '@ngrx/store';
import { Map, Record } from 'immutable';
export interface IBookLoadState {
  isLoading: MapQueue;
  httpStatus: Map<string, BookLoadHttpStatus>;
  isBookable: Map<string, boolean>;
}

export const initialBookLoadState = {
  isLoading: new MapQueue(),
  httpStatus: Map<any, any>(),
  isBookable: Map<any, any>(),
};

export class BookLoadState extends Record(initialBookLoadState) implements IBookLoadState {
  isLoading: MapQueue;
  httpStatus: Map<string, BookLoadHttpStatus>;
  isBookable: Map<string, boolean>;
}

export const getBookLoadState = createFeatureSelector<BookLoadState>('book-load');
