import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { AccountService, BroadcastService, EnvironmentService, UserService } from '@haulynx/services';
import {
  AccountErrorCodes,
  AsyncDataManager,
  AsyncMutationManager,
  AsyncQueryManager,
  Carrier,
  CarrierOptionType,
  CarrierSubType,
  EnvironmentName,
  FFState,
  LoginResponse,
  SessionInformation,
  UnclaimedUser,
  User,
  UserSignup,
  UserSignupHeaders,
  UserValidationResult,
} from '@haulynx/types';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { differenceInMilliseconds } from 'date-fns';
import { BehaviorSubject, combineLatest, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { AppSettingsActions, AppSettingsActionTypes } from '../../main-store/app-settings.actions';
import { AppState } from '../../main-store/app.reducers';
import { AsyncEntityBase } from '../../main-store/async-entity/async-entity-base.class';
import { userEntityNamespace } from './namespace';

@Injectable({ providedIn: 'root' })
export class UserEntityService extends AsyncEntityBase<User> {
  private environment: EnvironmentName;
  private _carrierError = new BehaviorSubject<{ errorCode: string; comment?: string }>(null);

  acceptTermsOfServiceManager: AsyncDataManager<{ sendTermsOfServiceEmail: boolean }, User>;
  chooseRoleManager: AsyncDataManager<{ createAccountRoleDescription: 'owner' | 'admin' }, any>;
  createCarrierManager: AsyncDataManager<{ title: string; phone: string }, any>;
  createUserManager: AsyncDataManager<
    { user: UserSignup; headers: UserSignupHeaders; brokerReferral: Array<string> },
    LoginResponse
  >;
  adminEmailVerificationManager: AsyncDataManager<{ email: string }, User>;
  emailVerificationManager: AsyncDataManager<{ code: string }, User>;
  getUserByIdManager: AsyncQueryManager<User, string>;
  loginManager: AsyncDataManager<{ email: string; password: string }, LoginResponse>;
  passwordResetManager: AsyncDataManager<{ code: string; password: string }, { message: string }>;
  resendVerificationEmailManager: AsyncDataManager<void, User>;
  sendPasswordResetEmailManager: AsyncDataManager<{ email: string }, { message: string }>;
  sessionInformationManager: AsyncDataManager<{ sessionInformation: Partial<SessionInformation> }, SessionInformation>;
  findCarrierManager: AsyncDataManager<
    { type: CarrierOptionType; subtype: CarrierSubType; value: string; permitState: string },
    any
  >;
  rmisTokenManager: AsyncDataManager<void, { url: string }>;
  refreshAccessTokenManager: AsyncDataManager<void, LoginResponse>;
  unclaimedUserManager: AsyncDataManager<void, { user: User }>;
  getUserUnclaimedByEmailManager: AsyncDataManager<{ email: string }, { carrierClaimed: boolean; name: string }>;
  attachUnclaimedUserManager: AsyncDataManager<UnclaimedUser, { user: User }>;
  updateUserSettingsManager: AsyncMutationManager<{ userId: string; userInfo: Partial<User> }, UserValidationResult>;

  user$: Observable<User>;
  token$: Observable<string>;
  environment$: Observable<EnvironmentName>;
  featureFlags$: Observable<FFState>;
  readonly carrierError$ = this._carrierError.asObservable();

  constructor(
    protected actions$: Actions,
    protected store: Store<AppState>,
    private accountService: AccountService,
    private afAuth: AngularFireAuth,
    private broadcastService: BroadcastService,
    private environmentService: EnvironmentService,
    private userService: UserService,
    private router: Router
  ) {
    super(actions$, store, userEntityNamespace);
    this.environment = this.environmentService.getEnvironmentName();

    this.getUserByIdManager = this.createAsyncQuery((userId: string) =>
      this.accountService.getUserById(userId).pipe(
        tap((user: User) => {
          this.sessionInformationManager.dispatch({
            sessionInformation: {
              user,
            },
          });
        }),
        catchError((error) => {
          return throwError(error);
        })
      )
    );

    this.getUserUnclaimedByEmailManager = this.createAsyncData(
      'check unclaimed user',
      (payload: { email: string }) =>
        this.accountService.getUserUnclaimedByEmail(payload.email).pipe(
          catchError((error) => {
            return throwError(error);
          })
        ),
      { dataIndex: 'unclaimedUser' }
    );

    this.sessionInformationManager = this.createAsyncData(
      'session information',
      (data: { sessionInformation: Partial<SessionInformation> }) => {
        return combineLatest([this.user$, this.token$, this.environment$, this.featureFlags$]).pipe(
          take(1),
          map(
            ([previousUser, previousToken, previousEnvironment, previousFeatureFlags]: [
              User,
              string,
              EnvironmentName,
              FFState
            ]) => {
              if (!data.sessionInformation) {
                this.userService.clearData();
                return {} as SessionInformation;
              }

              const {
                user = null,
                token = null,
                environment = null,
                featureFlags = null,
              } = data.sessionInformation || {};
              const sessionInformation: SessionInformation = {
                user: user || previousUser,
                token: token || previousToken,
                environment: environment || previousEnvironment,
                featureFlags: featureFlags || previousFeatureFlags,
              };
              return sessionInformation;
            }
          )
        );
      },
      { dataIndex: 'sessionInformation' }
    );

    this.loginManager = this.createAsyncData(
      'login',
      (user: { email: string; password: string }) =>
        this.accountService.loginUser(user.email, user.password).pipe(
          tap((response: LoginResponse) => {
            this.afAuth.signInWithEmailAndPassword(user.email, user.password);
            const exp = differenceInMilliseconds(response.expiresAt, new Date());

            this.sessionInformationManager.dispatch({
              sessionInformation: {
                token: response.accessToken,
                user: response.user,
                environment: this.environment,
              },
            });
            this.setOldAppModel(response.user, response.accessToken, exp);
          }),
          switchMap((response: LoginResponse) => {
            return this.actions$.pipe(
              ofType(AppSettingsActionTypes.UPDATE_USER_ENTITY_SUCCESS),
              map(() => response)
            );
          }),
          map((response: LoginResponse) => {
            return response;
          })
        ),
      { dataIndex: 'user' }
    );

    this.createUserManager = this.createAsyncData(
      'create user',
      (payload: { user: UserSignup; headers: UserSignupHeaders; brokerReferral: Array<string> }) =>
        this.accountService.createUser(payload.user, payload.headers, payload.brokerReferral).pipe(
          tap((response: LoginResponse) => {
            this.afAuth.signInWithEmailAndPassword(payload.user.email, payload.user.password);
            const exp = differenceInMilliseconds(response.expiresAt, new Date());

            this.sessionInformationManager.dispatch({
              sessionInformation: {
                user: response.user,
                token: response.accessToken,
                environment: this.environment,
              },
            });
            this.setOldAppModel(response.user, response.accessToken, exp);
          })
        ),
      { dataIndex: 'user' }
    );

    this.acceptTermsOfServiceManager = this.createAsyncData(
      'accept terms of service',
      (payload: { sendTermsOfServiceEmail: boolean }) =>
        this.accountService.acceptTermsOfService(payload.sendTermsOfServiceEmail).pipe(
          tap((user: User) => {
            this.sessionInformationManager.dispatch({
              sessionInformation: {
                user,
              },
            });
          })
        ),
      {
        dataIndex: 'user',
      }
    );

    this.adminEmailVerificationManager = this.createAsyncData(
      'admin verify email',
      (payload: { email: string }) => this.accountService.adminVerifyEmail(payload.email),
      { dataIndex: 'admin-verify-email' }
    );

    this.emailVerificationManager = this.createAsyncData(
      'verify email',
      (payload: { code: string }) =>
        this.accountService.verifyEmail(payload.code).pipe(
          tap((user) => {
            this.emailVerified(user);
          })
        ),
      {
        dataIndex: 'user',
      }
    );

    this.resendVerificationEmailManager = this.createAsyncData(
      'resend verification email',
      () =>
        this.accountService.resendVerificationEmail().pipe(
          tap((user: User) => {
            this.sessionInformationManager.dispatch({
              sessionInformation: {
                user,
              },
            });
          })
        ),
      {
        dataIndex: 'user',
      }
    );

    this.sendPasswordResetEmailManager = this.createAsyncData(
      'send password reset email',
      (payload: { email: string }) => this.accountService.sendPasswordResetEmail(payload.email),
      {
        dataIndex: 'password-reset',
      }
    );

    this.passwordResetManager = this.createAsyncData(
      'password reset',
      (payload: { code: string; password: string }) =>
        this.accountService.resetPassword(payload.code, payload.password),
      {
        dataIndex: 'password-reset',
      }
    );

    this.findCarrierManager = this.createAsyncData(
      'find carrier search',
      (payload: { type: CarrierOptionType; subtype: CarrierSubType; value: string; permitState: string }) =>
        this.accountService.searchForCarrier(payload.type, payload.subtype, payload.value, payload.permitState).pipe(
          tap((response) => {
            if (response?.errorCode)
              this._carrierError.next({ errorCode: response?.errorCode, comment: response?.comment });
            else this._carrierError.next(null);
          })
        ),
      { dataIndex: 'carrier' }
    );

    this.chooseRoleManager = this.createAsyncData(
      'choose role',
      (payload: { createAccountRoleDescription: 'owner' | 'admin' }) =>
        this.findCarrierManager.data$.pipe(
          take(1),
          map((carrier) => ({
            ...carrier,
            createAccountRoleDescription: payload.createAccountRoleDescription,
          }))
        ),
      { dataIndex: 'carrier' }
    );

    this.rmisTokenManager = this.createAsyncData(
      'rmis token',
      () =>
        this.accountService.rmisToken().pipe(
          catchError((error) => {
            if (error?.errorCode) this._carrierError.next({ errorCode: error?.errorCode });
            else this._carrierError.next(null);
            return throwError(error);
          })
        ),
      {
        dataIndex: 'carrier',
      }
    );

    this.createCarrierManager = this.createAsyncData(
      'create carrier',
      (payload: { title: string; phone: string }) =>
        this.findCarrierManager.data$.pipe(
          take(1),
          switchMap((carrierFromState) =>
            this.accountService.createCarrier(carrierFromState, payload.title, payload.phone).pipe(
              map((createResponse: { carrier: Carrier; user: User }) => {
                // It is important at this stage for the user to have a carrier so redirect works correctly
                this.sessionInformationManager.dispatch({ sessionInformation: { user: createResponse.user } });
                return createResponse.carrier;
              })
            )
          )
        ),
      { dataIndex: 'carrier' }
    );

    this.unclaimedUserManager = this.createAsyncData(
      'driver role selected',
      () =>
        this.accountService.unclaimedUser().pipe(
          tap((response: { user: User }) => {
            this._carrierError.next({ errorCode: AccountErrorCodes.XTS107 });
            this.sessionInformationManager.dispatch({ sessionInformation: { user: response.user } });
          })
        ),
      { dataIndex: 'user' }
    );

    this.attachUnclaimedUserManager = this.createAsyncData(
      'attach unclaimed user',
      (unclaimedUser: UnclaimedUser) =>
        this.accountService.attachUnclaimedUser(unclaimedUser).pipe(
          tap((response: { user: User }) => {
            this.sessionInformationManager.dispatch({ sessionInformation: { user: response.user } });
          })
        ),
      { dataIndex: 'user' }
    );

    this.refreshAccessTokenManager = this.createAsyncData(
      'refresh token',
      () => {
        return combineLatest([this.user$.pipe(take(1)), this.accountService.refreshToken()]).pipe(
          map(([user, response]: [User, LoginResponse]) => {
            const { accessToken, expiresAt } = response;
            const exp = differenceInMilliseconds(expiresAt, new Date());

            this.sessionInformationManager.dispatch({
              sessionInformation: {
                token: accessToken,
              },
            });
            this.setOldAppModel(user, accessToken, exp);

            return { user, accessToken, expiresAt };
          })
        );
      },
      { dataIndex: 'user' }
    );

    this.updateUserSettingsManager = this.createAsyncMutation(
      'update user settings',
      (payload: { userId: string; userInfo: Partial<User> }) => {
        return this.userService.updateUserInfo(payload);
      },
      { refreshEntityFrom: 'query' }
    );

    this.user$ = this.sessionInformationManager.data$.pipe(map((information) => information?.user));
    this.token$ = this.sessionInformationManager.data$.pipe(map((information) => information?.token));
    this.environment$ = this.sessionInformationManager.data$.pipe(map((information) => information?.environment));
    this.featureFlags$ = this.sessionInformationManager.data$.pipe(map((information) => information?.featureFlags));
    this.userService.setToken$(this.sessionInformationManager.data$.pipe(map((information) => information?.token)));
    this.userService.setUser$(this.sessionInformationManager.data$.pipe(map((information) => information?.user)));

    // Sometimes, tokens can be refreshed on other tabs. When this happens, we need to make sure the session information
    // on this running app needs to be updated so the token isn't seen as an expired token on the servers
    this.broadcastService
      .messagesOfType('refresh-token')
      .pipe(
        tap((response) => {
          this.sessionInformationManager.dispatch({ sessionInformation: { token: response.payload } });
        })
      )
      .subscribe();
  }

  public logout() {
    this.afAuth.signOut();
    this.store.dispatch(AppSettingsActions.logOut());
  }

  /**
   * Sometimes, users verify their email outside of this browser and will verify on another device
   * This ensures that the user is verified in the state so when the redirects happen, it is updated correctly
   */
  public emailVerified(user: User) {
    const verifiedUser: User = { ...user, isVerified: true };
    this.sessionInformationManager.dispatch({ sessionInformation: { user: verifiedUser } });
  }

  /**
   * Set Ethnio script in the head of the document if the user is a carrier
   * This will allow us to inject Self Elected sign ups for users
   * @param user
   */
  public setEthnioScript(user: User) {
    if (!this.environmentService.getEnvironment().production) return;

    if (user && !user?.broker) {
      // Early Adopter
      this.setEthnioScriptInHead('17552');
      // Some other one
      this.setEthnioScriptInHead('42522');
    }

    // BrokerOS Feedback
    if (user && user?.broker) this.setEthnioScriptInHead('49296');
  }

  private setEthnioScriptInHead(ethnioId: string) {
    let node = document.createElement('script');
    node.src = `//ethn.io/${ethnioId}.js`;
    node.type = 'text/javascript';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  /**
   * This is used in order to keep the old AppModel working correctly
   * @param user
   * @param token
   */
  private setOldAppModel(user: User, accessToken: string, expiration: number) {
    this.userService.setUser(user, expiration);
    this.userService.setToken(accessToken, expiration);

    this.store.dispatch(
      AppSettingsActions.updateUserEntity({
        token: accessToken,
        user,
        environmentName: this.environment,
      })
    );
    this.store.dispatch(AppSettingsActions.checkUserPermission(user));
  }
}
