import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Customer, CustomerAttributes, CustomerHoursOfOperation, FlatMarker, LoadLocationType } from '@haulynx/types';
import { get, isBoolean, isEmpty, nth, omit, reduce, toString } from 'lodash';
import { AttributeOptionTypes, CustomerAttributeOption, defaultCustomerAttributes } from './customer-details.config';
import { joinNoteTexts } from '@haulynx/utils';

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerDetailsComponent implements OnChanges {
  @Input() isLoading: boolean;
  @Input() customer: Customer;
  @Input() facilityType: LoadLocationType;

  attributes: CustomerAttributeOption[] = [];
  marker: FlatMarker;
  shippingHours: CustomerHoursOfOperation[] = [];
  receivingHours: CustomerHoursOfOperation[] = [];
  isHoursPanelExpanded: boolean;
  attributeOptionTypes = AttributeOptionTypes;
  notes = '';

  constructor() {}

  toggleHoursPanel(): void {
    this.isHoursPanelExpanded = !this.isHoursPanelExpanded;
  }

  getCustomerMarker(customer: Customer): FlatMarker {
    const lon: number = get(customer, 'lonCust', null);
    const lat: number = get(customer, 'latCust', null);

    return { coordinates: [lon, lat], locationType: this.facilityType };
  }

  formatAttributes(attributes: CustomerAttributes): CustomerAttributeOption[] {
    return reduce(
      attributes,
      (result: CustomerAttributeOption[], value: boolean | string, key: string) => {
        const attr = defaultCustomerAttributes.find((row: CustomerAttributeOption) => row.key === key);
        const option = this.normalizeAttributeOptions(value);

        return attr ? [...result, { ...attr, option }] : result;
      },
      []
    );
  }

  normalizeAttributes(attributes: CustomerAttributes): CustomerAttributes {
    return omit(attributes, '__typename');
  }

  normalizeAttributeOptions(value: unknown): string {
    if (isBoolean(value)) {
      return value ? AttributeOptionTypes.TRUE : AttributeOptionTypes.FALSE;
    }

    return value ? toString(value) : AttributeOptionTypes.NOT_AVAILABLE;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { customer } = changes;
    const newCustomer: Customer = get(customer, 'currentValue', null);

    if (newCustomer) {
      const customerAttributes: CustomerAttributes = nth(get(newCustomer, 'customerAttributes'), 0);
      const normalizedAttributes = this.normalizeAttributes(customerAttributes);
      this.notes = joinNoteTexts(newCustomer?.notes);

      this.attributes = isEmpty(normalizedAttributes)
        ? defaultCustomerAttributes
        : this.formatAttributes(normalizedAttributes);

      this.marker = this.getCustomerMarker(newCustomer);

      this.shippingHours = get(newCustomer, 'shippingHours', []);
      this.receivingHours = get(newCustomer, 'receivingHours', []);
    }
  }
}
