import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  AdvancedSearchModule,
  AssignedBrokersModule,
  BulkActionsModule,
  DataTableCalendarModule,
  DataTableV2Module,
  LoadBidDetailsModule,
  LoadPriceModule,
  LoadSearchTimelineModule,
  SidebarModule,
  SpinnerModule,
} from '@haulynx/components';
import { DirectivesModule } from '@haulynx/directives';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { AsyncEntitySearchViewStoreModule } from '@haulynx/store';
import { ReactiveComponentModule } from '@ngrx/component';
import { CarrierAdvancedSearchComponent } from './carrier-advanced-search.component';

@NgModule({
  declarations: [CarrierAdvancedSearchComponent],
  exports: [CarrierAdvancedSearchComponent],
  imports: [
    CommonModule,
    AdvancedSearchModule,
    DataTableV2Module,
    FormsModule,
    ReactiveFormsModule,
    LoadSearchTimelineModule,
    MaterialModule,
    PipesModule,
    LoadBidDetailsModule,
    AssignedBrokersModule,
    SidebarModule,
    DirectivesModule,
    BulkActionsModule,
    LoadPriceModule,
    SpinnerModule,
    ReactiveComponentModule,
    AsyncEntitySearchViewStoreModule,
    DataTableCalendarModule,
  ],
})
export class CarrierAdvancedSearchModule {}
