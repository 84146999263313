import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DateRangeFilterType, ISearchFilter } from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { addDays, addHours } from 'date-fns';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-search-date-range',
  templateUrl: './search-date-range.component.html',
  styleUrls: ['./search-date-range.component.scss'],
})
export class SearchDateRangeComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('wrapper', { static: false }) wrapper: ElementRef;

  @Input() focusedFilter: ISearchFilter;
  @Input() form: FormGroup;
  @Input() shouldFocusAfterViewInit = true;

  @Output() mouseFocus: EventEmitter<boolean> = new EventEmitter();
  @Output() commitFilter: EventEmitter<{ searchFilter: ISearchFilter; form: FormGroup }> = new EventEmitter();
  @Output() removeFilter: EventEmitter<ISearchFilter> = new EventEmitter();
  @Output() validForm: EventEmitter<boolean> = new EventEmitter();

  fromFormName = 'date';
  dateForm: FormGroup;

  shouldCheckClicks = false;
  alive = aliveWhile();

  constructor(private fb: FormBuilder) {
    this.dateForm = this.fb.group({
      date: [[new Date().valueOf(), addHours(addDays(new Date(), 3), 12).valueOf()]],
    });
  }

  @HostListener('window:keyup', ['$event']) onKeyPress(event: KeyboardEvent): void {
    if (event.key === 'Enter' && this.isFormValid) {
      this.commitFilter.emit({ searchFilter: this.focusedFilter, form: this.form });
      this.mouseFocus.emit(false);
      this.validForm.emit(false);
    } else {
      this.validForm.emit(this.isFormValid);
    }
  }

  @HostListener('document:click', ['$event.target']) onClick(targetElement: ElementRef): void {
    const clickedInside = this.wrapper.nativeElement.contains(targetElement);
    if (!clickedInside && this.shouldCheckClicks) {
      this.mouseFocus.emit(false);
    } else {
      this.mouseFocus.emit(true);
      this.validForm.emit(this.isFormValid);
    }
  }

  ngOnInit(): void {
    this.initDateForm();
    this.dateForm.valueChanges.pipe(takeUntil(this.alive)).subscribe(() => {
      this.patchDateForm();
    });
    this.patchDateForm();
  }

  ngAfterViewInit(): void {
    if (this.focusedFilter) {
      this.mouseFocus.emit(true);
      this.shouldCheckClicks = false;
      setTimeout(() => {
        this.validForm.emit(this.isFormValid);
        this.shouldCheckClicks = true;
      }, 500);
    }
  }

  initDateForm(): void {
    if (this.focusedFilter.name === 'Pickup Date') {
      if (this.form.get('firstAppointmentStart').value === null) {
        this.dateForm = this.fb.group({
          date: [[new Date().valueOf(), addHours(addDays(new Date(), 3), 12).valueOf()]],
        });
      } else {
        this.dateForm = this.fb.group({
          date: [[this.form.get('firstAppointmentStart').value, this.form.get('firstAppointmentEnd').value]],
        });
      }
    } else if (this.focusedFilter.name === 'Delivery Date') {
      if (this.form.get('lastAppointmentStart').value === null) {
        this.dateForm = this.fb.group({
          date: [[new Date().valueOf(), addHours(addDays(new Date(), 3), 12).valueOf()]],
        });
      } else {
        this.dateForm = this.fb.group({
          date: [[this.form.get('lastAppointmentStart').value, this.form.get('lastAppointmentEnd').value]],
        });
      }
    }
  }

  get isFormValid(): boolean {
    return !!this.dateForm.get('date').value?.[0] && !!this.dateForm.get('date').value?.[1];
  }

  removeFocusedFilter(): void {
    this.removeFilter.emit(this.focusedFilter);
    this.mouseFocus.emit(false);
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }

  private patchDateForm(): void {
    if (this.dateForm.get('date').value?.[0] && this.dateForm.get('date').value?.[1] && this.focusedFilter) {
      this.form.patchValue({
        [(<DateRangeFilterType>this.focusedFilter.keys).fromFormName]: this.dateForm.get('date').value[0],
        [(<DateRangeFilterType>this.focusedFilter.keys).toFormName]: this.dateForm.get('date').value[1],
      });
      this.validForm.emit(true);
    }
  }
}
