import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { ActionButtonsModule } from '../../actions-buttons/action-buttons.module';
import { RolledLoadsDialogComponent } from './rolled-loads-dialog.component';

@NgModule({
  declarations: [RolledLoadsDialogComponent],
  imports: [CommonModule, MaterialModule, PipesModule, MatDialogModule, ActionButtonsModule, ReactiveFormsModule],
  exports: [RolledLoadsDialogComponent],
})
export class RolledLoadsDialogModule {}
