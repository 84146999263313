import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import {
  CarrierSignupOnePageDialogComponent,
  DotSignupCreateAccountComponent,
  DotSignupSetLaneComponent,
  GoogleAddressFieldModule,
} from '@haulynx/components';
import { MaterialModule } from '@haulynx/modules';
import { NgxMaskModule } from 'ngx-mask';
import { DotSignupRoutingModule } from './dot-signup-routing.module';

@NgModule({
  declarations: [DotSignupCreateAccountComponent, DotSignupSetLaneComponent, CarrierSignupOnePageDialogComponent],
  exports: [DotSignupCreateAccountComponent, DotSignupSetLaneComponent, CarrierSignupOnePageDialogComponent],
  imports: [
    CommonModule,
    GoogleAddressFieldModule,
    DotSignupRoutingModule,
    FlexLayoutModule,
    MaterialModule,
    NgxMaskModule.forRoot(),
    ReactiveFormsModule,
  ],
})
export class DotSignupModule {}
