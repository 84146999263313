<main>
  <div class="row altInfo">
    <div class="infoBox col-lg-4">
      <div class="container">
        <i class="material-icons">call</i>
        <p>For questions or support, call us at (602)325-1240</p>
      </div>
    </div>
    <div class="infoBox col-lg-4">
      <div class="container">
        <i class="material-icons">email</i>
        <p>
          Email us at <a href="mailto:carrierxperience@usxpress.com" target="_top">carrierxperience@usxpress.com</a>
        </p>
      </div>
    </div>
  </div>
</main>
