import { Component, ChangeDetectionStrategy, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'haulynx-announcement-banner',
  templateUrl: './announcement-banner.component.html',
  styleUrls: ['./announcement-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnnouncementBannerComponent {
  @Input() bannerBgColor: string;
  @Input() bannerBtnText: string;
  @Output() onBannerBtnClicked = new EventEmitter();

  onClicked() {
    this.onBannerBtnClicked.emit();
  }
}
