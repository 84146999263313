import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CarrierInfoSectionVmService, TrackingTypeOptions } from '@haulynx/services';
import {
  AssignDriverForm,
  AssignmentForm,
  Carrier,
  CarrierAdmin,
  CarrierSearchResult,
  Driver,
  EntityOptions,
  FeatureFlag,
  FFState,
  TrackingType,
  Trailer,
  TrailerOwnerType,
  Truck,
  VehicleEntityTypes,
} from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { List } from 'immutable';
import { find, get } from 'lodash';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AppDropDownComponent } from '../../drop-down/components/app-drop-down/app-drop-down.component';

@Component({
  selector: 'app-carrier-info-section',
  templateUrl: './carrier-info-section.component.html',
  styleUrls: ['./carrier-info-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarrierInfoSectionComponent implements OnChanges, OnInit, OnDestroy {
  @Input() carrier: CarrierSearchResult = null;
  @Input() carrierAdmin: CarrierAdmin;
  @Input() carriers: CarrierSearchResult[] = [];
  @Input() carrierForm: FormGroup;
  @Input() assignmentForm: FormGroup;
  @Input() trucks: Partial<Truck>[] | List<Truck>;
  @Input() drivers: Partial<Driver>[] | List<Driver>;
  @Input() trailers: Partial<Trailer>[] | List<Trailer>;
  @Input() featureFlags: FFState;
  @Input() assignmentFormData: Partial<AssignDriverForm>;
  @Input() isLoadingCarriers = false;
  @Input() isLoadingDrivers = false;
  @Input() isLoadingTrucks = false;
  @Input() isLoadingTrailers = false;
  @Input() driverToPopulate: string;
  @Input() truckToPopulate: string;
  @Input() trailerToPopulate: string;
  @Output() carrierSearch = new EventEmitter<string>();
  @Output() carrierSelect = new EventEmitter<CarrierSearchResult>();
  @Output() assignmentDataChange = new EventEmitter<AssignDriverForm>();
  @Output() addDriver = new EventEmitter();
  @Output() addTruck = new EventEmitter();
  @Output() addTrailer = new EventEmitter();
  @Output() trackingTypeChange = new EventEmitter();

  @ViewChild('truckList') truckList: AppDropDownComponent;
  @ViewChild('driverList') driverList: AppDropDownComponent;
  @ViewChild('trailerList') trailerList: AppDropDownComponent;

  alive = aliveWhile();
  carrierSearchDebounce: Subject<string> = new Subject<string>();
  entityTypes = VehicleEntityTypes;
  trackingType = TrackingType;
  trackingOptions: TrackingTypeOptions[] = [];
  trackingFeatureFlag = FeatureFlag.TRACKING_TYPE;
  trailerOwner = TrailerOwnerType;

  constructor(private cd: ChangeDetectorRef, private carrierInfoSectionVmService: CarrierInfoSectionVmService) {
    this.carrierSearchDebounce.pipe(debounceTime(500)).subscribe((value) => {
      this.carrierSearch.emit(value);
      this.cd.markForCheck();
    });
  }

  prepareSearchResult(newCarrierInfo: CarrierSearchResult): {
    carrier: string;
    mcNumber: string;
    contact: string;
    phone: string;
    email: string;
  } {
    return {
      carrier: newCarrierInfo?.dot,
      mcNumber: newCarrierInfo?.mcNumber,
      contact: newCarrierInfo?.adminName,
      phone: newCarrierInfo?.phone,
      email: newCarrierInfo?.email,
    };
  }

  prepareFormData(newCarrierInfo: CarrierAdmin): {
    carrier: string;
    mcNumber: string;
    contact: string;
    phone: string;
    email: string;
  } {
    return {
      carrier: newCarrierInfo?.carrierDot,
      mcNumber: newCarrierInfo?.mcNumber,
      contact: newCarrierInfo?.name,
      phone: newCarrierInfo?.phone,
      email: newCarrierInfo?.email,
    };
  }

  prepareAssignmentFormData(data: AssignDriverForm): AssignmentForm {
    return {
      trackingType: get(data, 'trackingType', null),
      truckId: get(data, 'truck.id', null),
      driverId: get(data, 'driver.id', null),
      trailerOwner: get(data, 'trailerOwner', null),
      trailerId: get(data, 'trailer.id', null),
      phone: get(data, 'driver.phone', null),
      equipment: get(data, 'trailer.type', null),
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    const {
      carrierAdmin,
      assignmentFormData,
      carriers,
      drivers,
      trucks,
      trailers,
      driverToPopulate,
      truckToPopulate,
      trailerToPopulate,
    } = changes;

    if (carrierAdmin?.currentValue) {
      this.carrierForm.patchValue(this.prepareFormData(carrierAdmin.currentValue));
      this.selectCarrier();
      this.trackingOptions = this.carrierInfoSectionVmService.getAdditionalTrackingOptions(
        carrierAdmin.currentValue,
        this.carrierInfoSectionVmService.getDefaultTrackingOptions(this.featureFlags)
      );
    }

    if (assignmentFormData) {
      const currentFormData = this.prepareAssignmentFormData(assignmentFormData.currentValue);
      this.assignmentForm.patchValue(currentFormData);
    }

    if (carriers) {
      this.carriers = carriers.currentValue.map((c: Carrier) => {
        const { dot, mcNumber, name } = c;
        const filter = `${name} ${dot} ${mcNumber}`;

        return { ...c, filter };
      });

      if (this.carrier && this.carriers.length === 1) {
        this.carrierSearchDebounce.next(this.carriers[0].name);
      }
    }

    if (drivers) {
      this.drivers = drivers.currentValue;
    }

    if (trucks) {
      this.trucks = trucks.currentValue;
    }

    if (trailers) {
      this.trailers = trailers.currentValue;
    }

    /**
     * FEAT_FLAG: You can delete the previous 3 if statements and the following feature flag checks when the feature flag is expired
     */
    if (this.featureFlags[FeatureFlag.INLINE_CREATION] && drivers) {
      this.drivers = [{ name: EntityOptions.ADD_DRIVER }, ...this.drivers];
    }
    if (this.featureFlags[FeatureFlag.INLINE_CREATION] && trucks) {
      this.trucks = [{ unitId: EntityOptions.ADD_TRUCK }, ...this.trucks];
    }
    if (this.featureFlags[FeatureFlag.INLINE_CREATION] && trailers) {
      this.trailers = [{ trailerNumber: EntityOptions.ADD_TRAILER }, ...this.trailers];
    }

    if (driverToPopulate && driverToPopulate.currentValue && this.driverList) {
      this.carrierForm.patchValue({ driver: driverToPopulate.currentValue });
      this.driverList.closeDropDown();
    }

    if (truckToPopulate && truckToPopulate.currentValue && this.truckList) {
      this.carrierForm.patchValue({ truck: truckToPopulate.currentValue });
      this.truckList.closeDropDown();
    }

    if (trailerToPopulate && trailerToPopulate.currentValue && this.trailerList) {
      this.carrierForm.patchValue({ trailer: trailerToPopulate.currentValue });
      this.trailerList.closeDropDown();
    }
  }

  selectCarrier(): void {
    const values = this.carrierForm.getRawValue();
    const carrier: CarrierSearchResult = find(this.carriers, (item) => item.dot === values.carrier);

    if (carrier) {
      this.carrierForm.patchValue(this.prepareSearchResult(carrier));
    }
    this.carrierSelect.emit(carrier);

    this.clearCarrierChainedLists();
    this.trackingOptions = this.carrierInfoSectionVmService.getAdditionalTrackingOptions(
      carrier,
      this.carrierInfoSectionVmService.getDefaultTrackingOptions(this.featureFlags)
    );
  }

  selectAssignment(entity: VehicleEntityTypes): void {
    const { trackingType, driverId, truckId, trailerId, trailerOwner } =
      this.assignmentForm.getRawValue() as AssignmentForm;

    if (!driverId && entity === VehicleEntityTypes.DRIVERS) {
      return this.addDriver.emit();
    }

    if (!truckId && entity === VehicleEntityTypes.TRUCKS) {
      return this.addTruck.emit();
    }

    if (!trailerId && entity === VehicleEntityTypes.TRAILERS) {
      return this.addTrailer.emit();
    }

    if (trackingType) {
      this.trackingTypeChange.emit();
    }

    const selectedDriver = find(this.drivers, (item: Driver) => item.id === driverId) as unknown as Driver;
    const newFormData: AssignDriverForm = {
      trackingType: find(this.trackingType, (item) => item === trackingType) as unknown as TrackingType,
      driver: selectedDriver,
      truck: find(this.trucks, (item: Truck) => item.id === truckId) as unknown as Truck,
      trailerOwner: find(
        this.trailerOwner,
        (item: TrailerOwnerType) => item === trailerOwner
      ) as unknown as TrailerOwnerType,
      trailer: find(this.trailers, (item: Trailer) => item.id === trailerId) as unknown as Trailer,
      phone: get(selectedDriver, 'phone', null),
    };

    this.assignmentForm = this.carrierInfoSectionVmService.getTrackingValidation(trackingType, this.assignmentForm);

    this.assignmentDataChange.emit(newFormData);
    this.assignmentForm.patchValue(this.prepareAssignmentFormData(newFormData));
  }

  onValueChanges(keyword: string): void {
    if (keyword) {
      this.carrierSearchDebounce.next(keyword);
    } else {
      this.carrierForm.patchValue({
        mcNumber: null,
        contact: null,
        phone: null,
        email: null,
      });
    }
  }

  clearCarrierChainedLists(): void {
    this.driverList.keywordSearch.patchValue(null);
    this.truckList.keywordSearch.patchValue(null);
    this.trailerList.keywordSearch.patchValue(null);
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }

  ngOnInit(): void {
    this.trackingOptions = this.carrierInfoSectionVmService.getDefaultTrackingOptions(this.featureFlags);

    if (!this.carrierForm) {
      this.carrierForm = this.carrierInfoSectionVmService.createCarrierInfoFormOptions({});
    }
    if (!this.assignmentForm) {
      this.carrierForm = this.carrierInfoSectionVmService.createAssignmentFormOptions({});
    }
  }
}
