import gql from 'graphql-tag';

export const CreateBidMutation = gql(`
  mutation CreateBid($newBid: BidInput!) {
    createBid(newBid: $newBid) {
      id
      price
      status
      carrier {
        id
        dot
      }
      carrierId
      loadId
      loadsServiceLoad {
        id
        created
        locations {
          id
          address
          city
          state
          zipcode
          timezone
          appointmentSet
          appointmentStart
          appointmentEnd
          locationType
          billOfLading
          geometry {
            type
            coordinates
          }
        }
        providerDetails {
          equipmentType
          weightLbs
          alternateIds {
            identifierType
            identifierValue
          }
        }
        paymentDetails {
          price
          revenue
          distanceMiles
        }
      }
      notes
      createdAt
      createdBy {
        id
        name
      }
      updatedAt
      updatedBy {
        id
        name
      }
    }
  }
`);
