import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ConfirmationComponent } from './confirmation.component';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationService {
  constructor(private dialog: MatDialog) {}

  open<T>(data: {
    title: string;
    message: string;
    confirm?: { text: string };
    deny?: { text?: string; hide: boolean };
  }): Observable<T> {
    return this.dialog.open(ConfirmationComponent, { data }).afterClosed();
  }
}
