import { Injectable } from '@angular/core';
import { LoadsServiceService, NotificationsService } from '@haulynx/services';
import { AsyncQueryManager, LoadRouteData, LoadsServiceLoad } from '@haulynx/types';
import { getRemainingDistanceCoordinates } from '@haulynx/utils';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { LngLatLike } from 'mapbox-gl';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, first } from 'rxjs/operators';
import { AppState } from '../../main-store/app.reducers';
import { AsyncEntityBase } from '../../main-store/async-entity/async-entity-base.class';
import { loadRouteEntityNamespace } from './namespace';

@Injectable({ providedIn: 'root' })
export class LoadRouteEntityService extends AsyncEntityBase<LoadRouteData> {
  getLoadRouteManager: AsyncQueryManager<LoadRouteData, string>;

  // TODO: replace with data-query when @gavin merges it
  remainingDistance$ = new BehaviorSubject<number>(null);
  currentLocation$ = new BehaviorSubject<string>(null);

  constructor(
    protected actions$: Actions,
    protected store: Store<AppState>,
    private notifications: NotificationsService,
    private loadsServiceService: LoadsServiceService
  ) {
    super(actions$, store, loadRouteEntityNamespace);

    this.getLoadRouteManager = this.createAsyncQuery((waypoints: string) =>
      this.loadsServiceService.getLoadRoute(waypoints).pipe(
        catchError((error) => {
          this.notifications.error(error, 'There was a problem');
          return throwError(error);
        })
      )
    );
  }

  /**
   * TODO: replace with date-query when @gavin merges it
   * @param truckPosition
   * @param load
   */
  getRouteRemainingDistance(truckPosition: LngLatLike, load: LoadsServiceLoad): void {
    const coordinates: string = getRemainingDistanceCoordinates(truckPosition, load);
    this.loadsServiceService
      .getLoadRoute(coordinates, false)
      .pipe(
        first(),
        catchError((error) => {
          this.notifications.error(error, 'Distance Calculation Error');
          return throwError(error);
        })
      )
      .subscribe((loadRouteData) => {
        this.remainingDistance$.next(loadRouteData?.loadMapRemainingDistance);
      });
  }

  reverseGeoCode(coordinates: LngLatLike): void {
    if (coordinates) {
      this.loadsServiceService
        .reverseGeocodeViaMapbox({ lon: coordinates[0], lat: coordinates[1] })
        .pipe(
          first(),
          catchError((error) => {
            this.notifications.error(error, 'Unable to retrieve truck location');
            return throwError(error);
          })
        )
        .subscribe((locationName) => {
          this.currentLocation$.next(locationName);
        });
    }
  }
}
