import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { LoginService } from '@haulynx/services';
import { AppModel } from '@haulynx/store';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SharedLoadGuard implements CanActivate {
  constructor(private router: Router, private loginService: LoginService, private appModel: AppModel) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.loginService.isLoggedIn().pipe(
      take(1),
      map((isLoggedIn: boolean) => {
        if (route.queryParams.tmwNumber || route.queryParams.orderNumber) {
          if (isLoggedIn) {
            return true;
          } else {
            this.appModel.setLoadDetailsRedirectUrl(state.url);
            this.router.navigate(['/account/login']);
            return false;
          }
        }
        if (isLoggedIn) {
          const loadId = route.params.id;
          this.router.navigate([`/dashboard/loads/search/all/details/${loadId}`]);
          return false;
        } else {
          return true;
        }
      })
    );
  }
}
