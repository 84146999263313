import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  AccountService,
  AnalyticsService,
  EnvironmentService,
  FeatureFlagQueryService,
  GoogleAnalyticsService,
  LoginService,
  NotificationsService,
  UserService,
} from '@haulynx/services';
import { AppSettingsInitializer, EnvironmentName, User } from '@haulynx/types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { differenceInMilliseconds } from 'date-fns';
import * as _ from 'lodash';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { UserEntityService } from '../async-entity-models/user';
import { DispatchAction } from '../shared/store/helpers/action';
import { AppSettingsActions, AppSettingsActionTypes } from './app-settings.actions';

@Injectable()
export class AppSettingsEffects {
  initApp = createEffect(() =>
    this.actions$.pipe(
      ofType(AppSettingsActionTypes.START_INIT_APP),
      map((action: DispatchAction) => action.payload),
      switchMap((payload) => this.getUserInfo(payload))
    )
  );

  update = createEffect(() =>
    this.actions$.pipe(
      ofType(AppSettingsActionTypes.UPDATE_USER_ENTITY),
      map((action: DispatchAction) => action.payload),
      switchMap((payload) => this.getUserInfo(payload))
    )
  );

  appLogout = createEffect(() =>
    this.actions$.pipe(
      ofType(AppSettingsActionTypes.LOG_OUT),
      map((action: DispatchAction) => action.payload),
      switchMap((payload) => {
        this.userEntityService.sessionInformationManager.dispatch({ sessionInformation: null });
        return of(AppSettingsActions.logOutSuccess());
      })
    )
  );

  appLogin = createEffect(() =>
    this.actions$.pipe(
      ofType(AppSettingsActionTypes.LOG_IN),
      map((action: DispatchAction) => action.payload),
      switchMap((payload) => {
        const { email, password } = payload;
        const environmentName = this.environmentService.getEnvironmentName();

        return this.loginService.login(email, password).pipe(
          map(({ user, accessToken, expiresAt }) => {
            return { user, accessToken, expiresAt, environmentName };
          }),
          catchError((errors) => {
            const message = _.get(errors, 'message', 'Unable to login.');
            this.notificationService.error(message, `Login failed`);
            return of(errors);
          })
        );
      }),
      switchMap((result: { user: User; accessToken: string; expiresAt: number; environmentName: EnvironmentName }) => {
        const { user, accessToken, expiresAt, environmentName } = result;
        const exp = differenceInMilliseconds(expiresAt, new Date());
        this.userService.setUser(user, exp);
        this.userService.setToken(accessToken, exp);

        if (user) {
          return [
            AppSettingsActions.logInSuccess({ user, token: accessToken }),
            AppSettingsActions.startInitApp({ user, token: accessToken, environmentName }),
          ];
        } else {
          return [AppSettingsActions.logInError()];
        }
      })
    )
  );

  updateUser = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppSettingsActionTypes.UPDATE_USER),
        map((action: DispatchAction) => action.payload),
        map((user: User) => AppSettingsActions.checkUserPermission(user))
      ),
    { dispatch: false }
  );

  loginSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(AppSettingsActionTypes.LOG_IN_SUCCESS),
      map((action: DispatchAction) => action.payload),
      map(({ user, token }: { user: User; token: string }) => AppSettingsActions.checkUserPermission(user))
    )
  );

  checkUserPermission = createEffect(() =>
    this.actions$.pipe(
      ofType(AppSettingsActionTypes.CHECK_USER_PERMISSION),
      map((action: DispatchAction) => action.payload),
      switchMap((user: User) => {
        return of(
          AppSettingsActions.checkUserPermissionSuccess({
            loadFeedPermission: user?.carrier?.loadFeedEnabled || false,
            carrier: user?.carrier || null,
          })
        );
      })
    )
  );

  private getUserInfo(payload: { token: string; user: User; environmentName: EnvironmentName }) {
    return of(payload).pipe(
      switchMap((payload) => {
        const { token, user, environmentName }: { token: string; user: User; environmentName: EnvironmentName } =
          payload;
        const isEmptyUser = !user || !(user && user.id);

        const forkJoinState: { [key: string]: Observable<unknown> } = {
          user: isEmptyUser ? of(null) : this.accountService.getUserById(user.id),
          firebaseUser: isEmptyUser ? of(null) : this.userService.getUser(user.id),
          token: of(isEmptyUser ? '' : token),
          environmentName: of(environmentName),
          features: isEmptyUser
            ? of(null)
            : this.featureFlagService.getFeatureFlag(environmentName).pipe(catchError((err) => of(null))),
        };

        return forkJoin(forkJoinState);
      }),
      switchMap((result: AppSettingsInitializer) => {
        const { features, token, user, firebaseUser, environmentName } = result;
        if (user) {
          this.googleAnalytics.setUserId(user.id);
          this.analyticService.setUser(user, features);

          this.userEntityService.sessionInformationManager.dispatch({
            sessionInformation: {
              token,
              user: { ...user, canNextSendVerification: firebaseUser.canNextSendVerification },
              environment: environmentName,
              featureFlags: features.flags || null,
            },
          });
        }

        return [
          AppSettingsActions.updateInitApp({
            user: { ...user, canNextSendVerification: firebaseUser.canNextSendVerification },
            token,
            carrier: user?.carrier,
            loadFeedPermission: user?.carrier?.loadFeedEnabled || false,
          }),
          AppSettingsActions.updateUserEntitySuccess(),
        ];
      }),
      catchError((error) => {
        this.userEntityService.sessionInformationManager.dispatch({ sessionInformation: null });
        return of(
          AppSettingsActions.updateInitApp({
            user: null,
            token: null,
            carrier: null,
            loadFeedPermission: null,
          })
        );
      })
    );
  }

  constructor(
    private actions$: Actions,
    private router: Router,
    private loginService: LoginService,
    private notificationService: NotificationsService,
    private googleAnalytics: GoogleAnalyticsService,
    private userService: UserService,
    private featureFlagService: FeatureFlagQueryService,
    private environmentService: EnvironmentService,
    private analyticService: AnalyticsService,

    private userEntityService: UserEntityService,
    private accountService: AccountService
  ) {}
}
