<div class="accept-counter-container" fxLayout="column">
  <div class="title">Confirm Acceptance</div>

  <div class="information" fxFlexOffset="16px">
    <div *ngIf="data.status !== BookStatus.VIEWABLE; else callToBookText">
      U.S. Xpress Logistics hereby referred to as USX LogisticsCarrier agrees to indemnify, defend and hold harmless
      USXLogistics and its customers from any loss, damage, liability, expense or claim relating to, resulting from, or
      arising out of Carriers negligent or willful acts or omissions. Carrier shall be liable forall loss, damage,
      injury to or delay of freight shipped here under in accordance with 49 U.S.C. 14706. Carrier understands and
      agrees that for a period of 1 year from the date of the Agreement, Carrier shall not back solicit, directly or
      indirectly, any Shipper whose transportation needswere disclosed to Carrier in connection with this Agreement. As
      liquidated damages, Carrier agrees to pay a 15% commission on all traffic handled for Shippers whose
      transportation needs were disclosed to Carrier in connection with this Agreement for a period of 1 year etc...
    </div>

    <ng-template #callToBookText>
      Note: This load does not have an appointment time set for one or all stops. Please confirm appointment times with
      your broker or call general line 423-510-6583 about this load.
    </ng-template>
  </div>

  <div fxFlexOffset="32px">
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="16px">
      <button class="book" mat-raised-button [mat-dialog-close]="true" data-test="confirm-accept-offer">
        BOOK: {{ data.price | currency: 'USD':'symbol':'1.2-2' }}
      </button>
      <button *ngIf="data.status === BookStatus.VIEWABLE" mat-raised-button mat-dialog-close>Close</button>
    </div>
  </div>
</div>
