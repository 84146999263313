import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MomentTzPipe } from '@haulynx/pipes';
import { ReleasesService } from '@haulynx/services';
import { AppModel } from '@haulynx/store';
import { ReleaseNote, ReleaseNotesInput, ReleaseTypes, User } from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { get, nth, orderBy } from 'lodash';
import { DragScrollComponent } from 'ngx-drag-scroll';
import { fromEvent, of } from 'rxjs';
import { distinctUntilChanged, filter, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'app-tour-content',
  templateUrl: './tour-content.component.html',
  styleUrls: ['./tour-content.component.scss'],
})
export class TourContentComponent implements OnInit, OnDestroy {
  @ViewChild('slider', { read: DragScrollComponent }) slider: DragScrollComponent;

  form: FormGroup;
  release: ReleaseNote;
  archivedReleases: ReleaseNote[] = [];
  disableNext: boolean;
  disablePrev: boolean;
  userId: string;
  preview: boolean;

  private alive = aliveWhile();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { release: ReleaseNote; preview: boolean },
    private router: Router,
    private dialogRef: MatDialogRef<TourContentComponent>,
    public releaseService: ReleasesService,
    private appModel: AppModel,
    private fb: FormBuilder,
    private momentTzPipe: MomentTzPipe
  ) {}

  ngOnInit(): void {
    this.release = get(this.data, 'release', null);
    this.preview = get(this.data, 'preview', false);
    this.form = this.fb.group({ currentRelease: null });

    this.appModel.user$
      .pipe(
        takeUntil(this.alive),
        switchMap((user: User) => {
          this.userId = user.id;
          const releaseNotesInput = this.getReleaseNotesInput(user);

          return this.releaseService.getReleaseNotes(releaseNotesInput);
        }),
        withLatestFrom(this.appModel.userTimeZone$)
      )
      .subscribe(([releases, timeZone]: [ReleaseNote[], string]) => {
        const sortedReleases = orderBy(releases, ['date'], ['desc']);
        const currentRelease = this.getCurrentRelease(sortedReleases);

        this.archivedReleases = sortedReleases.map((releaseNote: ReleaseNote) => {
          const { date, title } = releaseNote;
          const formattedDate = this.momentTzPipe.transform(date, 'MM/DD/YY', timeZone, false);

          return { ...releaseNote, title: `${formattedDate} - ${title}`, selected: false };
        });

        this.form.patchValue({ currentRelease });
      });

    this.form.controls.currentRelease.valueChanges
      .pipe(
        takeUntil(this.alive),
        filter((releaseNoteId: string) => !!releaseNoteId),
        distinctUntilChanged(),
        withLatestFrom(this.appModel.id$),
        switchMap(([releaseNoteId, userId]: string[]) => {
          const unreadReleaseId = get(this.data, 'release.id', null);

          return unreadReleaseId !== releaseNoteId
            ? this.releaseService.getReleaseNotesById({ releaseNoteId, userId })
            : of(this.data.release);
        }),
        filter((releaseNote: ReleaseNote) => !!releaseNote)
      )
      .subscribe((releaseNote: ReleaseNote) => {
        this.release = releaseNote;
        this.markReleaseNoteAsViewed();

        if (this.slider) {
          this.slider.moveTo(0);
        }
      });

    fromEvent(document, 'keydown')
      .pipe(
        takeUntil(this.alive),
        filter((e: KeyboardEvent) => e.code === 'ArrowLeft' || e.code === 'ArrowRight')
      )
      .subscribe((e: KeyboardEvent) => (e.code === 'ArrowLeft' ? this.moveLeft() : this.moveRight()));

    this.router.events.pipe(takeUntil(this.alive)).subscribe(() => {
      this.onClose();
    });
  }

  getReleaseNotesInput(user: User): ReleaseNotesInput {
    const userId = get(user, 'id', null);
    const userType = user.broker ? ReleaseTypes.BROKER : ReleaseTypes.CARRIER;
    const before = Date.now();
    const includeExpired = true;

    return { userId, userType, before, includeExpired };
  }

  getCurrentRelease(releaseNotes: ReleaseNote[]): string {
    const unreadRelease = get(this.data, 'release.id', null);
    const latestRelease = get(nth(releaseNotes, 0), 'id', null);

    return unreadRelease || latestRelease;
  }

  onClose(): void {
    this.markReleaseNoteAsViewed();
    this.dialogRef.close();
  }

  markReleaseNoteAsViewed(): void {
    if (!this.preview) {
      const hasViewed = get(this.release, 'hasViewed');
      const releaseNoteId = get(this.release, 'id');
      const userId = this.userId;

      if (userId && releaseNoteId && !hasViewed) {
        this.releaseService.markReleaseNotesAsViewed({ userId, releaseNoteId }).subscribe();
      }
    }
  }

  reachesLeftBound(flag: boolean): void {
    this.disablePrev = flag;
  }

  reachesRightBound(flag: boolean): void {
    this.disableNext = flag;
  }

  moveTo(index: number): void {
    this.slider.moveTo(index);
  }

  moveLeft(): void {
    this.slider.moveLeft();
  }

  moveRight(): void {
    this.slider.moveRight();
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }
}
