import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@haulynx/modules';
import { LoadDispatchFormVm } from '@haulynx/services';
import { AsyncEntityGeocodingeModule } from '@haulynx/store';
import { DatetimePickerModule } from '../datetime-picker/datetime-picker.module';
import { GoogleAddressFieldModule } from '../google-address-field/google-address-field.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { TextAreaHandlerModule } from '../text-area-handler/text-area-handler.module';
import { LoadDispatchFormComponent } from './load-dispatch-form.component';

@NgModule({
  imports: [
    ReactiveFormsModule,
    GoogleAddressFieldModule,
    CommonModule,
    SpinnerModule,
    TextAreaHandlerModule,
    DatetimePickerModule,
    MaterialModule,
    AsyncEntityGeocodingeModule,
  ],
  declarations: [LoadDispatchFormComponent],
  exports: [LoadDispatchFormComponent],
  providers: [LoadDispatchFormVm],
})
export class LoadDispatchFormModule {}
