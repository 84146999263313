<form [formGroup]="form" class="form filter-wrapper carrier-form-search" (keydown.enter)="enter()">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div fxFlex="80" class="search-container">
      <mat-form-field appearance="outline">
        <span matPrefix class="search-icon">
          <mat-icon>search</mat-icon>
        </span>
        <input
          matInput
          placeholder="Carrier Name/DOT Number/MC Number"
          formControlName="search"
          (keyup)="searchKeyWord($event)"
        />
      </mat-form-field>
    </div>
    <div fxFlex="20">
      <action-buttons (onClick)="handlerAction($event)" [buttons]="buttons" [position]="'right'"></action-buttons>
    </div>
  </div>
</form>
