import { Injectable } from '@angular/core';
import { NotificationsService, TrailersService } from '@haulynx/services';
import {
  AssetBridgeTrailerHistory,
  AsyncMutationManager,
  AsyncDataManager,
  AsyncSearchManager,
  NewUSXITrailerInput,
  SearchTrailerHistorybyTrailerInfoInput,
} from '@haulynx/types';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { AppState } from '../../main-store/app.reducers';
import { AsyncEntityBase } from '../../main-store/async-entity/async-entity-base.class';
import { trailerEntityNamespace } from '../namespace';

@Injectable({ providedIn: 'root' })
export class TrailerEntityService extends AsyncEntityBase<any> {
  searchUSXITrailerNumberManager: AsyncSearchManager<any, any>;
  bookUSXITrailerManager: AsyncMutationManager<NewUSXITrailerInput, any>;
  searchTrailerHistoryByTrailerInfo: AsyncDataManager<
    SearchTrailerHistorybyTrailerInfoInput,
    AssetBridgeTrailerHistory
  >;

  constructor(
    protected actions$: Actions,
    protected store: Store<AppState>,
    private trailerServiceApi: TrailersService,
    private notifications: NotificationsService
  ) {
    super(actions$, store, trailerEntityNamespace);

    this.searchUSXITrailerNumberManager = this.createAsyncSearchQuery('get usxi trailer numbers', (searchPayload) => {
      return this.trailerServiceApi.searchUSXITrailerNumbers();
    });

    this.searchTrailerHistoryByTrailerInfo = this.createAsyncData(
      'trailer telemetry information',
      (payload: SearchTrailerHistorybyTrailerInfoInput) => {
        return this.trailerServiceApi.searchTrailerHistorybyTrailerInfo(payload);
      }
    );

    this.bookUSXITrailerManager = this.createAsyncMutation(
      'book usxi trailer',
      (payload) =>
        this.trailerServiceApi.bookUSXITrailer(payload).pipe(
          tap(
            () => {
              this.notifications.success(
                `Trailer ${payload.trailerNumber} has been booked, and a new Rate Con has been offered to carrier ${payload.carrierName}`,
                'Success!'
              );
            },
            (error) => {
              const userFriendlyError =
                'failed due to a QTOPS issue caused by this load being locked by another application please try to do your assignment on XPM';
              this.notifications.error(
                error.message.includes('Converting circular structure to JSON') ? userFriendlyError : error,
                `Assignment of USX trailer number ${payload.trailerNumber} to Load ${payload.orderNumber}`
              );
            }
          )
        ),
      { refreshEntityFrom: 'response' }
    );
  }
}
