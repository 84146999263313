<div
  class="white-link-text"
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutGap="4px"
  (click)="buttonClick()"
  [class.disabled]="loading || disabled"
>
  <span>{{ text }}</span>
  <mat-icon>{{ icon }}</mat-icon>
  <haulynx-spinner [active]="true" [diameter]="16" *ngIf="loading"></haulynx-spinner>
</div>
