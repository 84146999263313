export interface SelectionStrategy {
  isValidDate(formVal: DateForm, timeZone: string): boolean;
  formToZonedDate(dateVal: DateForm, timeZone: string): Date | Date[];
  isInvalidDate(dateVal: number | Date | ArrayLike<number | Date>): boolean;
  normalizeDate(dateVal: Date | Date[]): number | number[];
  normalizeTimepickerValue(value: number | number[], timeZone: string): Partial<DateForm>;
  isMinMaxValid(formValue: DateForm, timeZone: string, min: Date, max: Date): Record<string, boolean>;
}

export interface DateForm {
  month: string;
  day: string;
  year: string;
  hours: string;
  mins: string;
  monthRange: string;
  dayRange: string;
  yearRange: string;
}

export enum SelectionMode {
  SINGLE = 'single',
  RANGE = 'range',
}
