import gql from 'graphql-tag';

export const UpdateSearchViewsMutation = gql(`
mutation updateSearchViews($searchViews: [SearchViewInput!],$userId: StrictId!) {
  updateSearchViews(userId: $userId, searchViews: $searchViews) {
    id
  }
}

`);
