import { Pipe, PipeTransform } from '@angular/core';
import { DropdownDisplay } from '@haulynx/types';

@Pipe({
  name: 'optionSort',
})
export class OptionSortPipe implements PipeTransform {
  transform(value: DropdownDisplay[], filterText: string): unknown {
    return filterText === ''
      ? value
      : value.filter(
          (dropdown) =>
            dropdown.key.toLowerCase().includes(filterText.toLowerCase()) ||
            dropdown.value.toLowerCase().includes(filterText.toLowerCase())
        );
  }
}
