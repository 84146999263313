import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { buttonTypes, LoadsServiceLoad } from '@haulynx/types';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-load-active-accept',
  templateUrl: './load-active-accept.component.html',
  styleUrls: ['./load-active-accept.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadActiveAcceptComponent implements OnInit {
  title = 'Accept load?';
  declineRejectTitle = 'Reject Reason';
  buttons$ = new BehaviorSubject([]);
  allButtons = { ...buttonTypes };
  showItemInfo = true;
  otherCheck = false;
  capacityCheck = false;
  laneCheck = false;
  priceCheck = false;
  public reasonForm: FormGroup;

  contactBrokerInfo: { id: string; name: string; phone: string } = null;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      load: LoadsServiceLoad;
      isQuickDecline: boolean;
    } = {
      load: null,
      isQuickDecline: false,
    },
    public dialogRef: MatDialogRef<LoadActiveAcceptComponent>,
    private fb: FormBuilder
  ) {
    const { load } = data;

    if (load.broker) {
      this.contactBrokerInfo = {
        id: load.broker.id,
        name: load.broker.name,
        phone: load.broker.phone,
      };
    }
  }

  ngOnInit(): void {
    const buttons = [];
    if (this.data.isQuickDecline) {
      this.showItemInfo = false;
      this.declineRejectTitle = 'Decline';
      buttons.push(this.allButtons.DECLINE);
    } else {
      this.declineRejectTitle = 'Reject';
      buttons.push(this.allButtons.ACCEPT, this.allButtons.REJECT);
    }
    this.buttons$.next(buttons);
    this.reasonForm = this.fb.group({
      other: null,
    });
  }

  private getReasons(): string {
    const res: string[] = [];
    if (this.capacityCheck) {
      res.push('No available capacity');
    }
    if (this.laneCheck) {
      res.push('Undesirable lane');
    }
    if (this.priceCheck) {
      res.push('Bad price');
    }
    if (this.otherCheck) {
      const { other } = this.reasonForm.value;
      res.push(other);
    }
    return res.join(', ');
  }

  action(action) {
    const payload = {
      action,
      reasons: null,
    };

    switch (action) {
      case this.allButtons.OK.action: {
        this.buttons$.next([this.allButtons.OK, this.allButtons.BACK]);
        const reasons = this.getReasons();
        payload.action = 'reject';
        payload.reasons = reasons;
        this.dialogRef.close(payload);
        break;
      }

      case this.allButtons.DECLINE.action: {
        const reasons = this.getReasons();
        payload.action = 'reject';
        payload.reasons = reasons;
        this.title = '';
        this.dialogRef.close(payload);
        break;
      }

      case this.allButtons.REJECT.action:
        this.showItemInfo = false;
        this.title = '';
        this.buttons$.next([this.allButtons.OK, this.allButtons.BACK]);
        break;

      case this.allButtons.BACK.action:
      case this.allButtons.CANCEL.action:
        this.showItemInfo = true;
        this.title = 'Accept load?';
        this.buttons$.next([this.allButtons.ACCEPT, this.allButtons.REJECT]);
        break;

      case this.allButtons.ACCEPT.action:
        this.dialogRef.close(payload);
        break;
    }
  }
}
