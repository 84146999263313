import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from '@haulynx/directives';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { FieldLabelModule } from '../field-label/field-label.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { CarrierDetailsInfoComponent } from './carrier-details-info/carrier-details-info.component';
import { CarrierDetailsDirective } from './carrier-details-info/carrier-details.directive';

@NgModule({
  imports: [CommonModule, FieldLabelModule, SpinnerModule, RouterModule, PipesModule, MaterialModule, DirectivesModule],
  declarations: [CarrierDetailsInfoComponent, CarrierDetailsDirective],
  exports: [CarrierDetailsInfoComponent, CarrierDetailsDirective],
})
export class CarrierDetailsModule {}
