import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActiveLoadsTabsModel, LoadDetailsModel } from '@haulynx/store';
import { Tab } from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { truncate } from 'lodash';
import { takeUntil, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'app-active-loads-container',
  templateUrl: './active-loads-container.component.html',
  styleUrls: ['./active-loads-container.component.scss'],
})
export class ActiveLoadsContainerComponent implements OnInit {
  alive = aliveWhile();

  constructor(
    public activeLoadsTabsModel: ActiveLoadsTabsModel,
    private router: Router,
    private loadDetailsModel: LoadDetailsModel
  ) {}

  selectTab({ index, tab }: { index: number; tab: Tab }) {
    this.activeLoadsTabsModel.selectTab(tab);
  }

  removeTab(tab: Tab) {
    this.activeLoadsTabsModel.removeTabs(tab);
    this.activeLoadsTabsModel.selectTab({ id: 'active' });
  }

  ngOnInit(): void {
    this.loadDetailsModel.form.state$
      .pipe(
        takeUntil(this.alive),
        withLatestFrom(this.activeLoadsTabsModel.tabsSelectedId$, this.loadDetailsModel.form.isLoading$)
      )
      .subscribe(([load, tabSelectedId, isLoading]) => {
        if (tabSelectedId && load && tabSelectedId === load.id) {
          let label;

          if (isLoading) {
            label = 'Loading ... ';
          } else {
            const [pickUp, dropOff] = load.locations;
            label = `${truncate(pickUp.address, { length: 10 })} -> ${truncate(dropOff.address, { length: 10 })}`;
          }
          const updateTab = {
            id: tabSelectedId,
            label: label,
          };
          this.activeLoadsTabsModel.updateTabs([updateTab]);
        }
      });

    this.activeLoadsTabsModel.tabsSelected$.pipe(takeUntil(this.alive)).subscribe((tab) => {
      if (tab) {
        const { url, queryParams } = tab;
        this.router.navigate(url.split('/'), { queryParams, replaceUrl: true });
      }
    });
  }
}
