import { TrackingType, TrailerOwnerType } from '../truck';

export interface AssignmentForm {
  trackingType: TrackingType;
  truckId: string;
  driverId: string;
  trailerOwner?: TrailerOwnerType;
  trailerId?: string;
  phone: string;
  email?: string;
  equipment?: string;
  secDriverId?: string;
  secPhone?: string;
  secEmail?: string;
}
