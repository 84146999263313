import { Injectable } from '@angular/core';
import { CreateEldTruckMutation, DeleteTruckMutation, GetTrucksByCarrierId } from '@haulynx/gql';
import { CreateTruckDto, ExecutionResult, FetchResult, GraphqlSearchResponse, Truck } from '@haulynx/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GraphqlService } from '../graphql.service';

@Injectable({
  providedIn: 'root',
})
export class TrucksService {
  constructor(private graphqlService: GraphqlService) {}

  getTrucksByCarrierId(variables: {
    carrierId;
  }): Observable<GraphqlSearchResponse<{ total: number; result: Truck[] }>> {
    return this.graphqlService
      .query({
        variables,
        query: GetTrucksByCarrierId,
      })
      .pipe(
        map(({ data }: FetchResult) => {
          const { result = [], total = 0 } = data.getTrucksByCarrierId || {};

          return { entities: result, total };
        })
      );
  }

  deleteTruck(variables: {
    truckId: string;
    dot?: string;
    lat?: number;
    lon?: number;
    timeAvailable?: Date;
  }): Observable<FetchResult> {
    return this.graphqlService.mutate({
      variables,
      mutation: DeleteTruckMutation,
    });
  }

  createTruckEld(variables: CreateTruckDto): Observable<ExecutionResult | string> {
    const { unitId, carrierId } = variables;
    const newTruck = { unitId, carrierId };

    return this.graphqlService
      .mutate({
        variables: {
          newTruck,
        },
        mutation: CreateEldTruckMutation,
      })
      .pipe(map(({ data }: FetchResult) => data.createTruckEld || null));
  }
}
