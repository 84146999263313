import { DocumentTypes } from '../web-interfaces';

export interface LoadsServiceDocumentPayload {
  file: File;
  title: string;
  tenant_id: string;
  type: DocumentTypes | string;
  location?: LoadsServiceDocumentLocation;
  tags?: string[];
  lookup_ids: LoadsServiceDocumentLookupIDs;
}

export interface LoadsServiceDocumentLocation {
  lat: number;
  lon: number;
}

export interface LoadsServiceDocumentLookupIDs {
  load_id: string;
}

export interface LoadsServiceDocumentErrorResponse {
  timestamp: string;
  status: number;
  error: string;
  message: string;
  path: string;
}
