<div *ngIf="isCarrier">
  <h2 *ngIf="location && location?.locationType; else defaultHeader" matDialogTitle>
    {{ (location?.locationType === 'pickup' ? 'Pickup' : 'Delivery') | uppercase }}
  </h2>
  <ng-template #defaultHeader>
    <h2 matDialogTitle>Confirm Load Delivery</h2>
  </ng-template>
  <h3 class="location" [ngStyle]="{ color: location?.locationType === 'pickup' ? '#448BF5' : '#00CA8D' }">
    {{ location?.address }}
  </h3>
</div>
<div *ngIf="isBroker">
  <h2 matDialogTitle>Confirm Load Delivery</h2>
</div>
<mat-dialog-content>
  <div *ngIf="isBroker">
    <span
      >Do you confirm that the Carrier has delivered this Load to it's destination?<br />
      <span style="color: gray; font-size: 0.9em"></span>
    </span>
  </div>

  <div *ngIf="isCarrier">
    <button [matDialogClose]="null" class="close-button">
      <mat-icon svgIcon="haulynx-close" class="close-icon"></mat-icon>
    </button>

    <form class="form" [formGroup]="form" *ngIf="user.carrier">
      <span *ngIf="isPickUp">Entrance to Shipper</span>
      <span *ngIf="!isPickUp">Entrance to Consignee</span>
      <mat-form-field appearance="outline">
        <input
          matInput
          [owlDateTime]="owlEntranceTimestamp"
          formControlName="carrierArrival"
          (dateTimeChange)="check()"
          autocomplete="off"
        />
        <mat-icon
          matSuffix
          [owlDateTimeTrigger]="owlEntranceTimestamp"
          svgIcon="haulynx-calendar"
          class="calendar-icon"
        ></mat-icon>
        <owl-date-time #owlEntranceTimestamp [hour12Timer]="true"></owl-date-time>
        <mat-error *ngIf="form.controls['carrierArrival'].getError('dateMoreThanNow')"
          >This date is invalid. Please try again.
        </mat-error>
      </mat-form-field>
      <span *ngIf="isPickUp">Exit Shipper</span>
      <span *ngIf="!isPickUp">Exit Consignee</span>
      <mat-form-field appearance="outline">
        <input
          matInput
          [owlDateTime]="owlExitTimestamp"
          formControlName="carrierDeparture"
          (dateTimeChange)="check()"
          autocomplete="off"
        />
        <mat-icon matSuffix [owlDateTimeTrigger]="owlExitTimestamp" svgIcon="haulynx-calendar" class="calendar-icon">
        </mat-icon>
        <owl-date-time #owlExitTimestamp [hour12Timer]="true"></owl-date-time>
        <mat-error *ngIf="form.controls['carrierDeparture'].getError('dateMoreThenNow')"
          >This date is invalid. Please try again.
        </mat-error>
      </mat-form-field>
      <mat-error *ngIf="form.getError('entranceMoreThanExit')">This date is invalid. Please try again.</mat-error>
      <mat-error *ngIf="form.getError('oneEntryRequired')">At least one entry is required.</mat-error>
      <span *ngIf="user.carrier && !location"
        >Do you confirm that you've delivered this Load to it's destination? <br
      /></span>

      <span *ngIf="!user.carrier && !location"
        >Do you confirm that the Carrier has delivered this Load to it's destination?<br /><span
          style="color: gray; font-size: 0.9em"
        ></span>
      </span>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    mat-raised-button
    class="action-button"
    [matDialogClose]="result"
    *ngIf="isCarrier"
    [disabled]="form.invalid"
    data-test="set-button"
  >
    {{ showSaveButton ? 'Save' : 'Confirm' }}
  </button>
  <div *ngIf="isBroker">
    <button mat-button color="primary" [matDialogClose]="'confirm'">Confirm</button>
    <button mat-button color="warn" [matDialogClose]="">Cancel</button>
  </div>
</mat-dialog-actions>
