import { Observable } from 'rxjs';
import { RmisRegistration } from '../carrier/carrier';

export enum RmisTokenValue {
  RMIS_COMPLETE = 'rmisComplete',
  RMIS_SETUP = 'rmisSetup',
}

export interface RmisSteps {
  [key: string]: RmisStep;
}

export interface RmisStep {
  step: number;
  label: string;
}

export interface RmisProps {
  loadingContinue$: Observable<boolean>;
  percentComplete: number;
  showAccountSetup: boolean;
  registration: Observable<RmisRegistration>;
  carrierDot: string;
  rmisComplete: boolean;
  lastRmisSetupDialogShown: Date;
  currentAccountStep?: RmisStep;
}
