import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MapboxService } from '@haulynx/services';
import {
  DEFAULT_TRUCK_COLOR,
  EldPoints,
  EldStatus,
  Environment,
  LoadLocationType,
  MapboxRoute,
  MapTruck,
  MarkerTypes,
  WayPoint,
} from '@haulynx/types';
import { Position } from '@turf/helpers';
import { head, isEmpty } from 'lodash';
import { GeoJSONSource, LngLatBounds, LngLatLike, Map, Marker } from 'mapbox-gl';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'haulynx-mapbox',
  templateUrl: './mapbox.component.html',
  styleUrls: ['./mapbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapboxComponent implements AfterViewInit, OnChanges {
  @Input() isLoading = false;
  @Input() data: MapboxRoute[] = [];
  @Input() center: LngLatLike = [-95.712891, 37.09024]; // static if fit bounds is used, USA geographical center
  @Input() zoom = 10; // static if fit bounds is used
  @Input() maxZoom: number = null;
  @Input() padding = 100;
  @Input() style = 'mapbox://styles/mapbox/dark-v9';
  @Input() resize: Observable<void>;
  @Input() mapId? = 'map';

  @Output() wayPointClick = new EventEmitter<string>();

  alive = new Subject();
  map: Map;
  bounds: LngLatBounds;
  route: MapboxRoute[] = [];
  truckRoute: Position[] = [];

  constructor(
    private mapboxService: MapboxService,
    private ngZone: NgZone,
    @Inject(DOCUMENT) private document: Document,
    private environment: Environment
  ) {}

  ngAfterViewInit(): void {
    this.initMap();

    combineLatest([
      this.resize?.pipe(takeUntil(this.alive)) ?? of(),
      this.mapboxService.$resize.pipe(takeUntil(this.alive)),
    ]).subscribe(() => {
      setTimeout(() => {
        this.map.resize();
      }, 250);
    });
  }

  ngOnDestroy() {
    this.alive.next();
    this.alive.complete();
  }

  initMap(): void {
    this.ngZone.runOutsideAngular(() => {
      this.map = new Map({
        accessToken: this.environment.mapbox.accessToken,
        container: this.mapId,
        style: this.style,
        zoom: this.zoom,
        maxZoom: this.maxZoom,
        center: this.center,
        attributionControl: false,
      });

      this.map.on('load', () => {
        this.map.resize();
        this.setMapLayers();
      });

      this.map.on('zoom', () => {
        // catch zoom event here
        // console.log(this.map.getZoom());
      });
    });
  }

  setMapLayers(): void {
    this.ngZone.runOutsideAngular(() => {
      if (this.data) {
        this.data.map((source: MapboxRoute) => {
          const {
            entityId,
            lineColor,
            lineWidth,
            routeCoordinates,
            wayPoints,
            showTruck,
            truck,
            eldPoints,
            isDelayed,
          } = source;

          this.setRoutes(routeCoordinates, entityId, lineColor, lineWidth);
          if (showTruck && !isEmpty(truck)) {
            const { name, position } = truck;
            this.setTruckRoute(name, position, routeCoordinates, entityId, lineWidth);
          }
          this.setWayPoints(wayPoints);
          this.setEldPoints(eldPoints);
          this.setEldDelayPoints(eldPoints);
          this.setTruck(truck, routeCoordinates as Position[], isDelayed);

          if (this.bounds) {
            this.map.fitBounds(this.bounds, { padding: this.padding });
          }
        });
      }
    });
  }

  setRoutes(routeCoordinates: LngLatLike[], entityId: string, lineColor?: string, lineWidth?: number): void {
    this.ngZone.runOutsideAngular(() => {
      const routeSource = this.mapboxService.getRouteSource(routeCoordinates as GeoJSON.Position[]);
      const routeLayerSource = this.mapboxService.getRouteLayerSource(entityId, lineColor, lineWidth);

      if (routeCoordinates.length > 0) {
        this.map.addSource(entityId, routeSource);
        this.map.addLayer(routeLayerSource);
      }
    });
  }

  setTruckRoute(
    name: string,
    truckPosition: LngLatLike,
    routeCoordinates: LngLatLike[],
    entityId: string,
    lineWidth?: number
  ): void {
    this.ngZone.runOutsideAngular(() => {
      const truckId = `truck-${entityId}`;
      if (truckPosition) {
        this.truckRoute = this.mapboxService.sliceRoute(
          head(routeCoordinates) as Position,
          truckPosition as Position,
          routeCoordinates as Position[]
        );
        this.setRoutes(this.truckRoute as LngLatLike[], truckId, DEFAULT_TRUCK_COLOR, lineWidth);
      }
    });
  }

  setTruck(truck: MapTruck, routeCoordinates: Position[], isDelayed: boolean): void {
    if (truck) {
      this.ngZone.runOutsideAngular(() => {
        const { name, position } = truck;
        const bearing = this.mapboxService.getBearing(routeCoordinates, position as Position);

        this.loadTruckImage(
          position as Position,
          'truck-delay',
          isDelayed ? 'truck-red' : 'truck-green',
          name,
          'visible',
          bearing
        );
      });
    }
  }

  loadTruckImage(
    position: Position,
    id: string,
    icon: string,
    name: string,
    visibility: 'visible' | 'none',
    iconRotate?: number
  ): void {
    this.ngZone.runOutsideAngular(() => {
      const { origin } = this.document.location;

      this.map.loadImage(`${origin}/assets/images/${icon}.png`, (_, image) => {
        this.map.addImage(id, image);

        const truckSource = this.mapboxService.getTruckSource(position as Position, name);
        const truckLayerSource = this.mapboxService.getTruckLayerSource(id, visibility, iconRotate);

        this.map.addSource(id, truckSource);
        this.map.addLayer(truckLayerSource);
      });
    });
  }

  setWayPoints(wayPoints: WayPoint[]): void {
    this.ngZone.runOutsideAngular(() => {
      if (this.map) {
        this.removeDOMElements(this.document.querySelectorAll(`${this.mapId} .${MarkerTypes.AWAITING_DROPOFF}`));
        this.removeDOMElements(this.document.querySelectorAll(`${this.mapId} .${MarkerTypes.AWAITING_PICKUP}`));
        this.removeDOMElements(this.document.querySelectorAll(`${this.mapId} .${MarkerTypes.PASSED_DROPOFF}`));
        this.removeDOMElements(this.document.querySelectorAll(`${this.mapId} .${MarkerTypes.PASSED_PICKUP}`));
        this.removeDOMElements(this.document.querySelectorAll(`${this.mapId} .${MarkerTypes.CARRIER_PICKUP}`));
        this.removeDOMElements(this.document.querySelectorAll(`${this.mapId} .${MarkerTypes.CARRIER_DROPOFF}`));

        const wayPointFeatures = wayPoints.map((wayPoint: WayPoint) => {
          const { location, name } = wayPoint;
          return this.mapboxService.getWayPointFeatures(location, name);
        });

        wayPointFeatures.map((marker: GeoJSON.Feature<GeoJSON.Point>, index: number) => {
          const waypoint = wayPoints[index];
          const hasOutTimestamp = waypoint?.outTimestamp ? true : false;
          const isPickup = waypoint.locationType === LoadLocationType.PICKUP ? true : false;
          let className;

          if (waypoint.className) {
            className = waypoint.className;
          } else {
            if (!waypoint.isDetention) {
              if (hasOutTimestamp && isPickup) {
                className = MarkerTypes.PASSED_PICKUP;
              } else if (hasOutTimestamp && !isPickup) {
                className = MarkerTypes.PASSED_DROPOFF;
              } else if (!hasOutTimestamp && isPickup) {
                className = MarkerTypes.AWAITING_PICKUP;
              } else if (!hasOutTimestamp && !isPickup) {
                className = MarkerTypes.AWAITING_DROPOFF;
              }
            } else {
              if (isPickup) {
                className = MarkerTypes.DETENTION_PICKUP;
              } else {
                className = MarkerTypes.DETENTION_DROPOFF;
              }
            }
          }

          const markerElem = this.mapboxService.getWayPointMarkerElem(
            marker,
            className,
            waypoint.removeTextContent ?? index
          );

          markerElem.addEventListener('click', () => {
            this.wayPointClick.emit(marker?.properties?.name);
          });

          new Marker(markerElem).setLngLat(marker.geometry.coordinates as LngLatLike).addTo(this.map);
        });
      }
    });
  }

  setEldPoints(eldPoints: EldPoints[]): void {
    this.ngZone.runOutsideAngular(() => {
      const { origin } = this.document.location;

      this.map.loadImage(`${origin}/assets/images/eld-point.png`, (_, image) => {
        this.map.addImage('eld-point', image);

        const eldFeatures = eldPoints.reduce((acc, current) => {
          const { status, coordinates: eldCoordinates } = current;
          return status === EldStatus.GOOD ? [...acc, this.mapboxService.setEldFeature(eldCoordinates)] : acc;
        }, []);

        const eldSource = this.mapboxService.getEldPointSource(eldFeatures);
        const eldLayerSource = this.mapboxService.getEldLayerSource('eld-point');

        this.map.addSource('eld-point', eldSource);
        this.map.addLayer(eldLayerSource);
      });
    });
  }

  setEldDelayPoints(eldPoints: EldPoints[]): void {
    this.ngZone.runOutsideAngular(() => {
      const { origin } = this.document.location;

      this.map.loadImage(`${origin}/assets/images/eld-point-delay.png`, (_, image) => {
        this.map.addImage('eld-point-delay', image);

        const eldFeatures = eldPoints.reduce((acc, current) => {
          const { status, coordinates: eldCoordinates } = current;
          return status === EldStatus.PROBLEM ? [...acc, this.mapboxService.setEldFeature(eldCoordinates)] : acc;
        }, []);

        const eldSource = this.mapboxService.getEldPointSource(eldFeatures);
        const eldLayerSource = this.mapboxService.getEldLayerSource('eld-point-delay');

        this.map.addSource('eld-point-delay', eldSource);
        this.map.addLayer(eldLayerSource);
      });
    });
  }

  updateRouteSource(entityId: string, routeCoordinates: LngLatLike[]): void {
    this.ngZone.runOutsideAngular(() => {
      const routeSource = this.map?.getSource(entityId) as GeoJSONSource;

      if (routeSource) {
        const routeSourceFeature = this.mapboxService.setRouteSourceFeature(routeCoordinates);
        routeSource.setData(routeSourceFeature);
      }
    });
  }

  updateTruckSource(entityId: string, routeCoordinates: LngLatLike, name: string): void {
    this.ngZone.runOutsideAngular(() => {
      const truckSource = this.map?.getSource(entityId) as GeoJSONSource;

      if (truckSource) {
        const truckSourceFeature = this.mapboxService.setTruckSourceFeature(routeCoordinates, name);
        truckSource.setData(truckSourceFeature);
      }
    });
  }

  updateEldSource(entityId: string, eldPoints: EldPoints[]): void {
    this.ngZone.runOutsideAngular(() => {
      const eldSource = this.map?.getSource(entityId) as GeoJSONSource;

      if (eldSource) {
        const eldFeatures = eldPoints.map((eld) => {
          const { coordinates: eldCoordinates } = eld;
          return this.mapboxService.setEldFeature(eldCoordinates);
        });

        const newEldSource = this.mapboxService.getEldFeatureCollection(eldFeatures);
        eldSource.setData(newEldSource);
      }
    });
  }

  removeDOMElements(elements: NodeListOf<HTMLDivElement>): void {
    elements.forEach((element: HTMLDivElement) => element.remove());
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { data, style } = changes;

    if (data) {
      const { currentValue: mapboxRoutes } = data;

      if (mapboxRoutes) {
        const coordinatesToBound = mapboxRoutes.reduce((acc: LngLatLike[], mapboxRoute: MapboxRoute) => {
          const { entityId, routeCoordinates, wayPoints, truck, eldPoints } = mapboxRoute;

          this.updateRouteSource(entityId, routeCoordinates);
          this.updateTruckSource('truck-delay', truck?.position, truck?.name);
          this.updateTruckSource('truck', truck?.position, truck?.name);
          this.setWayPoints(wayPoints);
          this.updateEldSource('eld-point', eldPoints);

          return [...acc, ...routeCoordinates];
        }, []);
        this.bounds = this.mapboxService.fitBounds(coordinatesToBound);
        this.map?.fitBounds(this.bounds, { padding: this.padding });
      }
    }

    if (style && this.map) {
      this.map.remove();
      this.initMap();
    }
  }
}
