import gql from 'graphql-tag';

export const CreateSearchViewMutation = gql(`
  mutation CreateSearchView($newSearchView: SearchViewInput!, $userId: StrictId!) {
    createSearchView(newSearchView: $newSearchView, userId: $userId) {
      id
      label
    }
  }
`);
