export interface MenuItem {
  name: string; // menu item display label
  link?: string; // url
  icon?: string; // material icon key
  svgIcon?: string;
  subItems?: MenuItem[]; // nested dropdown items
  isOpened?: boolean;
  queryParams?: {
    [key: string]: any;
  };
}
