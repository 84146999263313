import { TemplateRef } from '@angular/core';
import { Observable } from 'rxjs';
import { ZipLaneSearchParameters } from '../../loads-service/zip-lane-search-parameters';
import { CustomerFilter } from '../advanced-search/models/CustomerFilter';
import { LaneDestinationFilter } from '../advanced-search/models/LaneDestinationFilter';
import { LaneOriginFilter } from '../advanced-search/models/LaneOriginFilter';
import { LaneWonFilter } from '../advanced-search/models/LaneWonFilter';
import { LastSeenFilter } from '../advanced-search/models/LastSeenFilter';
import { ISearchFilter } from '../advanced-search/search-filter';
import { BulkActionPage } from '../bulk-actions/load-bulk-actions.enum';
import { IColumns2 } from '../data-table-v2';
import { DataTableConfig } from '../data-table/data-table-type';
import { SidebarItem } from '../generic/sidebar';

export type LaneSearchSideBarItem = SidebarItem<
  Partial<ZipLaneSearchParameters>,
  {
    resetParams?: (keyof ZipLaneSearchParameters)[];
    columns?: Array<{ key: string; value: boolean }>;
    stickyColumnWidthOpen: string;
  }
>;

export const laneDataColumns = (
  stickyColumnWidth: string,
  stickyColHeader: TemplateRef<unknown>,
  columnSettings: Array<{ key: string; value: boolean }> = []
): IColumns2[] => {
  const displayColumns = [];
  const allColumns = [
    {
      field: 'originAndDestination',
      isVisible: true,
      label: 'Origin and Destination',
      sortConfig: [
        {
          label: 'Origin',
          dataIndex: 'origin~origin?location.where-locationType=pickup.address',
          emitSort: true,
        },
        {
          label: 'Destination',
          dataIndex: 'destination~destination?location.where-locationType=dropofff.address',
          emitSort: true,
        },
        {
          label: 'Origin Date/Time',
          dataIndex: 'pickupDate~pickupDate?location.where-locationType=pickup.appointmentStart',
          emitSort: true,
        },
        {
          label: 'Destination Date/Time',
          dataIndex: 'deliveryDate~deliveryDate?location.where-locationType=dropoff.appointmentStart',
          emitSort: true,
        },
      ],
      isCustomCell: true,
      isSortable: true,
      isClickable: false,
      width: '520px',
    },
    {
      field: 'miles',
      isVisible: true,
      label: 'Miles',
      sortConfig: [
        {
          label: 'Miles',
          dataIndex: 'miles~miles?paymentDetails.distanceMiles',
          emitSort: true,
        },
      ],
      isCustomCell: true,
      isSortable: true,
      isClickable: false,
      width: '95px',
    },
    {
      field: 'mlAllInRpm',
      isVisible: true,
      label: 'All-in RPM',
      sortConfig: [
        {
          label: 'All-in RPM',
          dataIndex: 'mlPredicted.allInRpm',
        },
      ],
      isCustomCell: true,
      isSortable: false,
      isClickable: false,
      width: '95px',
    },
    {
      field: 'mlCostRpm',
      isVisible: true,
      label: 'Cost RPM',
      sortConfig: [
        {
          label: 'Cost RPM',
          dataIndex: 'mlPredicted.costRpm',
        },
      ],
      isCustomCell: true,
      isSortable: false,
      isClickable: false,
      width: '95px',
    },
    {
      field: 'laneVolatility',
      isVisible: true,
      label: 'Lane Volatility',
      sortConfig: [
        {
          label: 'Lane Volatility',
          dataIndex: 'mlPredicted.laneVolatility',
        },
      ],
      isCustomCell: true,
      isSortable: false,
      isClickable: false,
      width: '110px',
    },
    {
      field: 'avgBid',
      isVisible: true,
      label: 'Avg Bid Rate / Range',
      sortConfig: [
        {
          label: 'Avg Bid Rate',
          dataIndex: 'historical.averageBidRate',
        },
        {
          label: 'Avg Bid Amount',
          dataIndex: 'historical.averageBidAmount',
        },
        {
          label: 'Avg Bid Low',
          dataIndex: 'historical.bidLow',
        },
        {
          label: 'Avg Bid High',
          dataIndex: 'historical.bidHigh',
        },
      ],
      isCustomCell: true,
      isSortable: false,
      isClickable: false,
      width: '180px',
    },
    {
      field: 'avgWon',
      isVisible: true,
      label: 'Avg Won Rate / Range',
      sortConfig: [
        {
          label: 'Avg Won Rate',
          dataIndex: 'historical.averageWonRate',
        },
        {
          label: 'Avg Won Amount',
          dataIndex: 'historical.averageWonAmount',
        },
        {
          label: 'Avg Won Low',
          dataIndex: 'historical.wonLow',
        },
        {
          label: 'Avg Won High',
          dataIndex: 'historical.wonHigh',
        },
      ],
      isCustomCell: true,
      isSortable: false,
      isClickable: false,
      width: '180px',
    },
    {
      field: 'avgCost',
      isVisible: true,
      label: 'Avg Cost Rate / Range',
      sortConfig: [
        {
          label: 'Avg Cost Rate',
          dataIndex: 'costDetails.averageCostRate',
        },
        {
          label: 'Avg Cost Amount',
          dataIndex: 'costDetails.averageCostAmount',
        },
        {
          label: 'Avg Cost Low',
          dataIndex: 'costDetails.minCost',
        },
        {
          label: 'Avg Cost High',
          dataIndex: 'costDetails.maxCost',
        },
      ],
      isCustomCell: true,
      isSortable: false,
      isClickable: false,
      width: '180px',
    },
    {
      field: 'margin',
      isVisible: true,
      label: 'Margin Rate / Range',
      sortConfig: [
        {
          label: 'Margin Rate',
          dataIndex: 'historical.averageMarginAmount',
        },
        {
          label: 'Margin Low',
          dataIndex: 'historical.marginLow',
        },
        {
          label: 'Margin High',
          dataIndex: 'historical.marginHigh',
        },
      ],
      isCustomCell: true,
      isSortable: false,
      isClickable: false,
      width: '180px',
    },
    {
      field: 'datRate',
      isVisible: true,
      label: 'DAT Rate',
      sortConfig: [
        {
          label: 'DAT Rate',
          dataIndex: 'historical.datRate',
        },
        {
          label: 'DAT Amount',
          dataIndex: 'historical.datAmount',
        },
      ],
      isCustomCell: true,
      isSortable: false,
      isClickable: false,
      width: '100px',
    },
    {
      field: 'recentCost',
      isVisible: true,
      label: 'Recent Cost',
      sortConfig: [
        {
          label: 'Recent Cost Rate',
          dataIndex: 'costDetails.recentCostRate',
        },
        {
          label: 'Recent Cost Amount',
          dataIndex: 'costDetails.recentCostAmount',
        },
      ],
      isCustomCell: true,
      isSortable: false,
      isClickable: false,
      width: '110px',
    },
    {
      field: 'loadCount',
      isVisible: true,
      label: 'Load Count',
      sortConfig: [
        {
          label: 'Load Count',
          dataIndex: 'laneDetails.count',
        },
      ],
      isCustomCell: true,
      isSortable: false,
      isClickable: false,
      width: '90px',
    },
    {
      field: 'bidSinceWon',
      isVisible: true,
      label: 'Bid Since Won',
      sortConfig: [
        {
          label: 'Bid Since Won',
          dataIndex: 'laneDetails.bidSinceWon',
        },
      ],
      isCustomCell: true,
      isSortable: false,
      isClickable: false,
      width: '115px',
    },
    {
      field: 'oneWeekAttainment',
      isVisible: true,
      label: 'One Week Attainment',
      sortConfig: [
        {
          label: 'One Week Attainment',
          dataIndex: 'laneDetails.oneWeekAttainment',
        },
      ],
      isCustomCell: true,
      isSortable: false,
      isClickable: false,
      width: '165px',
    },
    {
      field: 'customer',
      isVisible: true,
      label: 'Customer',
      sortConfig: [
        {
          label: 'Customer',
          dataIndex: 'laneDetails.customer',
        },
      ],
      isCustomCell: true,
      isSortable: false,
      isClickable: false,
      width: '160px',
    },
    {
      field: 'equipment',
      isVisible: true,
      label: 'Equipment',
      sortConfig: [
        {
          label: 'Equipment Type',
          dataIndex: 'loadContents.equipmentType',
        },
        {
          label: 'Equipment Weight',
          dataIndex: 'loadContents.weight',
        },
        {
          label: 'Equipment Commodity',
          dataIndex: 'loadContents.commodity',
        },
      ],
      isCustomCell: true,
      isSortable: false,
      isClickable: false,
      width: '180px',
    },
    {
      field: 'allInRpmEdit',
      isVisible: true,
      label: 'All-in RPM',
      altLabel: 'All-In Cost',
      align: 'right',
      isCustomCell: true,
      isSortable: false,
      width: '100px',
      isSticky: true,
    },
    {
      field: 'costRpmEdit',
      isVisible: true,
      label: 'Cost RPM',
      altLabel: 'Cost',
      isCustomCell: true,
      isSortable: false,
      width: '100px',
      isSticky: true,
    },
    {
      field: 'save',
      isVisible: true,
      label: '',
      sortConfig: [],
      isCustomCell: true,
      isSortable: false,
      width: '80px',
      isSticky: true,
    },
  ];
  allColumns.forEach((column) => {
    const setting = columnSettings.find((value) => value.key === column.field);
    const canDisplayColumn = setting ? setting.value : true;
    if (canDisplayColumn) displayColumns.push(column);
  });
  return displayColumns;
};

export const laneServiceSearchFilters = (data: { brokers: Observable<any[]> }): ISearchFilter[] => [
  new LaneOriginFilter(),
  new LaneDestinationFilter(),
  new CustomerFilter(),
  new LastSeenFilter(),
  new LaneWonFilter(),
];

export enum LaneVolatility {
  LOW = 'Low',
  MEDIUM = 'Medium',
  HIGH = 'High',
}

export enum DateRanges {
  LAST_7_DAYS = 'last7Days',
  LAST_30_DAYS = 'last30Days',
  LAST_60_DAYS = 'Last60Days',
  LAST_90_DAYS = 'Last90Days',
  LAST_180_DAYS = 'Last180Days',
  CUSTOM = 'custom',
}

export enum CSVChoice {
  ALL = 'all',
  SELECTED = 'selected',
}

export const laneConfigOptions: DataTableConfig = {
  globalFilter: false,
  reorderColumns: false,
  columnVisibility: false,
  reorderRows: false,
  showBulkActions: true,
  sortColumns: false,
  scrollable: false,
  checkBoxSelection: true,
  filterLocally: false,
  showTopPaginator: true,
  showBottomPaginator: false,
  isLaneRate: true,
  bulkActions: BulkActionPage.LANE_RATE,
  pageAmount: 25,
};

export const dateRangeOptions = [
  {},
  { label: 'Last 7 Days', value: DateRanges.LAST_7_DAYS },
  { label: 'Last 30 Days', value: DateRanges.LAST_30_DAYS },
  { label: 'Last 60 Days', value: DateRanges.LAST_60_DAYS },
  { label: 'Last 90 Days', value: DateRanges.LAST_90_DAYS },
  { label: 'Last 180 Days', value: DateRanges.LAST_180_DAYS },
  { label: 'Custom', value: DateRanges.CUSTOM },
];

export interface CSVDataOptions {
  label: string;
  value: string;
}

export interface CSVLaneData {
  originCity: string;
  originState: string;
  originZip: string;
  originCentroidDistance: number;
  originTime: string;
  destinationCity: string;
  destinationState: string;
  destinationZip: string;
  destinationCentroidDistance: number;
  destinationTime: string;

  equipmentType: string;
  equipmentWeight: string;
  equipmentCommodity: string;

  count: number | string;
  bidSinceWon: number;
  oneWeekAttainment: number;
  customer: string;

  minCost: number;
  maxCost: number;
  averageCostAmount: number;
  averageCostRate: number;
  recentCostAmount: number;
  recentCostRate: number;

  allInRpm: number;
  costRpm: number;
  laneVolatility: string;

  averageBidRate: number;
  averageBidAmount: number;
  bidLow: number;
  bidHigh: number;
  averageWonRate: number;
  averageWonAmount: number;
  wonLow: number;
  wonHigh: number;
  averageMarginAmount: number;
  marginHigh: number;
  marginLow: number;
  datAmount: number;
  datRate: number;

  miles: number;
  allInRPM: number;
  costRPM: number;
}
