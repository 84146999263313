import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@haulynx/modules';
import { AddressComponent } from './address.component';
import { AddressVm } from './address.vm';

@NgModule({
  declarations: [AddressComponent],
  exports: [AddressComponent],
  imports: [CommonModule, ReactiveFormsModule, MaterialModule],
  providers: [AddressVm],
})
export class AddressModule {}
