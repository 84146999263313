import gql from 'graphql-tag';

export const UpdateLoadsServiceLoad = gql`
  mutation UpdateLoadsServiceLoad($loadId: StrictId!, $loadUpdates: LoadsServiceLoadInput!) {
    updateLoadsServiceLoad(loadId: $loadId, loadUpdates: $loadUpdates) {
      assignedBrokers {
        id
        name
        usxId
      }
      loadStatus
      id
      bookStatus
      carrier {
        name
        dot
      }
      broker {
        name
        phone
        id
      }
      drivers {
        id
        name
      }
      truck {
        id
        unitId
      }
      trailers {
        id
        trailerNumber
      }
      locations {
        id
        address
        city
        state
        zipcode
        timezone
        appointmentSet
        appointmentStart
        appointmentEnd
        locationType
        billOfLading
        geometry {
          type
          coordinates
        }
        distanceMiles
        notes {
          author
          text
        }
      }
      provider {
        id
        name
        detailType
        company
      }
      providerDetails {
        billTo
        billToName
        cxPriority
        equipmentType
        commodity
        weight: weightLbs
        quantity
        operationsPriority
        orderType
        alternateIds {
          identifierType
          identifierValue
        }
      }
      paymentDetails {
        price
        revenue
        maxBuy
        ratePerMile
        distanceMiles
      }
      bidDetails {
        offerCount
        lowestOffer
        lowestOfferCarrierName
      }
      restrictions {
        type
        value
      }
    }
  }
`;
