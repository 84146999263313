import { Device } from '@haulynx/types';
import { get } from 'lodash';

export function deviceByCarrierIdFilter(entities: Device[], search: { carrierId?: string; imei?: string }): Device[] {
  if (search.carrierId) {
    return entities.filter((device: Device) => get(device, 'carrier.id', null) === search.carrierId);
  }

  return entities.filter((device: Device) => get(device, 'id', null) === search.imei);
}
