import gql from 'graphql-tag';

export const FindPaymentItems = gql(`
  query FindPaymentItems($searchCriteria: PaymentItemSearchCriteria) {
    findPaymentItems(searchCriteria: $searchCriteria) {
      orderNumber
      paymentType
      quantity
      amount
      paymentUnits
      haulynxId
      usxId
    }
  }
`);
