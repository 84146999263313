import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GeocodingEntityService } from '@haulynx/store';
import {
  BooleanFilterType,
  canadaStates,
  DateRangeFilterType,
  GeoSpacialFilterType,
  ISearchFilter,
  ISearchFilterType,
  LatLonInput,
  mexicoStates,
  NumericalFilterType,
  PlaceInfo,
  PlaceType,
  States,
  states,
  TextArrayFilterType,
  TextFilterType,
} from '@haulynx/types';
import { aliveWhile, getHumanDaysDifference } from '@haulynx/utils';
import { isNil, toString } from 'lodash';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-search-chip',
  templateUrl: './search-chip.component.html',
  styleUrls: ['./search-chip.component.scss'],
})
export class SearchChipComponent implements OnInit {
  @ViewChild('formInput', { static: false }) formInput: ElementRef;
  @ViewChild('nonVisibleForm', { static: false }) nonVisibleInput: ElementRef;

  @Input() filter: ISearchFilter;
  @Input() form: FormGroup;
  @Input() tabIndex: number;
  @Input() displayCloseButton = true;
  @Input() isFocusable = true;
  @Input() isClearAllChip = false;
  @Input() showDaysDifference = false;
  @Input() showFilter = true;
  @Input() showAsPill = false;

  @Output() removeSearchFilter: EventEmitter<{ filter: ISearchFilter; value: any }> = new EventEmitter();
  @Output() chipFocus: EventEmitter<ISearchFilter> = new EventEmitter();
  @Output() chipBlur: EventEmitter<ISearchFilter> = new EventEmitter();
  @Output() removeAllSearchFilters: EventEmitter<null> = new EventEmitter();

  textFilterType = ISearchFilterType.TEXT;
  locationFilterType = ISearchFilterType.GEOSPATIAL;
  numberRangeFilterType = ISearchFilterType.NUMBER_RANGE;
  dateRangeFilterType = ISearchFilterType.DATE_RANGE;
  isHovering = false;
  cannotType: string;

  constructor() {}

  ngOnInit(): void {}

  removeFromSelected(e: Event): void {
    e.stopPropagation();
    this.removeSearchFilter.emit({ filter: this.filter, value: this.tabIndex });
  }

  onChipFocus(): void {
    if (this.filter) {
      this.chipFocus.emit(this.filter);
    }
  }

  onChipBlur(): void {
    this.chipBlur.emit(this.filter);
  }

  removeAllFiltersIfIsClearAllChip(): void {
    if (this.isClearAllChip) {
      this.removeAllSearchFilters.emit();
    }
  }

  isZipcode(val: string | number): boolean {
    const valAsString: string = toString(val) ?? '';
    return valAsString?.length === 5 && !!valAsString?.match(/\b\d{5}\b/g);
  }

  capitalizeStateNames(stateName) {
    const words = stateName.split(' ');
    stateName
      .split(' ')
      .forEach(
        (word, i) =>
          (words[i] = word.toUpperCase() === 'AND' ? words[i] : words[i][0].toUpperCase() + words[i].substr(1))
      );
    return words.join(' ');
  }

  ngOnChanges(changes: SimpleChanges) {}

  get formValue(): string {
    if (this.filter.type === ISearchFilterType.TEXT) {
      if (this.filter.transformValue) {
        return this.filter.transformValue(this.form.get((<TextFilterType>this.filter.keys).textFormName)?.value);
      }
      return this.form.get((<TextFilterType>this.filter.keys).textFormName)?.value;
    } else if (this.filter.type === ISearchFilterType.GEOSPATIAL) {
      const keys = <GeoSpacialFilterType>this.filter.keys;
      if (this.isZipcode(keys.zipcode) && !keys.location) {
        return `${keys.zipcode}${keys.radius > 0 ? ` (${keys.radius} mi)` : ''}`;
      } else {
        if (!!keys.placeInfo) {
          return `${keys.placeInfo ? `${keys.placeInfo.fullAddress}` : ''}${
            keys.radius > 0 ? ` (${keys.radius} mi)` : ''
          }`;
        }
        if (!keys.placeInfo && keys.state) {
          let stateName;
          let wholePlace;
          if (this.filter['searchPlace'] && (stateName = (<GeoSpacialFilterType>this.filter.keys).location)) {
            return stateName;
          }
          if ((stateName = states.find((state) => state.code === keys.state)))
            wholePlace = `${stateName.name}, United States`;
          if ((stateName = mexicoStates.find((state) => state.code === keys.state)))
            wholePlace = `${this.capitalizeStateNames(stateName.name.toLowerCase())}, Mexico`;
          if ((stateName = canadaStates.find((state) => state.code === keys.state)))
            wholePlace = `${this.capitalizeStateNames(stateName.name.toLowerCase())}, Canada`;
          return `${wholePlace}${keys.radius > 0 ? ` (${keys.radius} mi)` : ''}`;
        }
      }
    } else if (this.filter.type === ISearchFilterType.DATE_RANGE) {
      if (this.showDaysDifference) {
        const fromInDays = getHumanDaysDifference(
          this.form.get((<DateRangeFilterType>this.filter.keys).fromFormName)?.value
        );
        const toInDays = getHumanDaysDifference(
          this.form.get((<DateRangeFilterType>this.filter.keys).toFormName)?.value
        );
        return `From ${fromInDays} to ${toInDays}`;
      } else {
        return `${this.form.get((<DateRangeFilterType>this.filter.keys).fromFormName)?.value} - ${
          this.form.get((<DateRangeFilterType>this.filter.keys).toFormName)?.value
        }`;
      }
    } else if (this.filter.type === ISearchFilterType.NUMBER_RANGE) {
      return `${this.filter.formPrefix ? this.filter.formPrefix : ''}${
        this.form.get((<NumericalFilterType>this.filter.keys).minFormName)?.value
      }${this.filter.formSuffix ? this.filter.formSuffix : ''} - ${
        this.filter.formPrefix ? this.filter.formPrefix : ''
      }${this.form.get((<NumericalFilterType>this.filter.keys).maxFormName).value}${
        this.filter.formSuffix ? this.filter.formSuffix : ''
      }`;
    } else if (this.filter.type === ISearchFilterType.MULTI_DROPDOWN) {
      let formValue = this.form.get((<TextFilterType>this.filter?.keys)?.textFormName)?.value;
      if (!this.showFilter) {
        formValue = formValue[this.tabIndex];
      }
      if (this.filter.transformValue) {
        return this.filter.transformValue(formValue);
      }
      return toString(formValue).replace(/,/g, ', ');
    } else if (this.filter.type === ISearchFilterType.TEXT_ARRAY) {
      return (<TextArrayFilterType>this.filter.keys).value;
    } else if (this.filter.type === ISearchFilterType.BOOLEAN) {
      const boolVal = this.form.get((<BooleanFilterType>this.filter.keys).textFormName)?.value;
      if (!isNil(boolVal)) {
        return boolVal ? 'Yes' : 'No';
      }

      return '';
    }
  }
}
