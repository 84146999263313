import { Component, OnInit } from '@angular/core';
import { DividerSliderLimits } from '../divider-slider.types';

@Component({
  selector: 'app-divider-slider-story',
  templateUrl: './divider-slider-story.component.html',
  styleUrls: ['./divider-slider-story.component.scss'],
})
export class DividerSliderStoryComponent implements OnInit {
  dividerSliderLimits: DividerSliderLimits = {
    left: {
      min: '200px',
      max: '450px',
    },
  };

  dividerSliderLimitsOther: DividerSliderLimits = {
    top: {
      min: '200px',
      max: '450px',
    },
  };

  dividerSliderLimitsBottom: DividerSliderLimits = {
    left: {
      min: '200px',
      max: '450px',
    },
  };

  constructor() {}

  ngOnInit(): void {}
}
