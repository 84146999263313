<div class="complete-load-search-container">
  <app-load-table-search-bar
    [carriers]="activeLoadsModel.carriersEntities$ | async"
    [brokers]="activeLoadsModel.brokersEntities$ | async"
    [filter]="activeLoadsModel.entitiesQuery$ | async"
    (carrierSearch)="carrierSearch($event)"
    (filterChange)="searchLoads($event)"
  ></app-load-table-search-bar>
  <div class="complete-load-search-container__details">
    <app-active-loads-table
      [isLoading]="activeLoadsModel.entitiesLoading$ | async"
      [extendedColumns]="!selectedLoad"
      [data]="completedLoads"
      [selected]="selectedLoad"
      [stickyHeader]="true"
      [activeStepTypes]="activeLoadsModel.activeStepTypes$ | async"
      [pagination]="activeLoadsModel.entitiesPagination$ | async"
      (changePagination)="changePagination($event)"
      (select)="selectLoad($event)"
    ></app-active-loads-table>

    <app-load-quick-actions
      *ngIf="selectedLoad"
      [load]="selectedLoad"
      [title]="loadDetailsModel.nextStepTitle$ | async"
      [user]="appModel.user$ | async"
      [showContent]="!!activeStep"
      [showTimeline]="true"
      [showSeparator]="!!activeStep"
      (actionClick)="quickAction($event, selectedLoad)"
      (showFacilityInfo)="onShowFacilityInfo($event)"
    >
    </app-load-quick-actions>
  </div>
</div>
