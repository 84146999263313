import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserEntityService } from '@haulynx/store';
import { LoadsServiceLoad, User } from '@haulynx/types';
import { take } from 'rxjs/operators';

@Component({
  selector: 'haulynx-carrier-book-or-contact-button',
  templateUrl: './carrier-book-or-contact-button.component.html',
  styleUrls: ['./carrier-book-or-contact-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarrierBookOrContactButtonComponent implements OnInit {
  @Input() rmisComplete: boolean = false;
  @Input() load: LoadsServiceLoad;
  @Output() bookItNow = new EventEmitter<LoadsServiceLoad>();
  @Output() viewMyLoads = new EventEmitter();
  @Output() contactToBook = new EventEmitter();

  menuOpened = false;
  user: User;

  constructor(private snackBar: MatSnackBar, private userEntityService: UserEntityService) {}

  ngOnInit(): void {
    this.userEntityService.user$.pipe(take(1)).subscribe((user: User) => {
      this.user = user;
    });
  }

  bookItNowClicked() {
    this.bookItNow.emit(this.load);
  }

  copyToClipboard(value: string): void {
    navigator.clipboard.writeText(value);
    this.snackBar.open('Successfully copied to clipboard', null, {
      duration: 3000,
      panelClass: 'carrier-snackbar',
    });
  }

  viewMyLoadsClicked() {
    this.viewMyLoads.emit();
  }

  contactToBookClicked() {
    this.contactToBook.emit(this.load?.id);
  }
}
