<div class="active-load-assign-field-container">
  <mat-form-field class="example-full-width" flexLayout="row" appearance="outline" floatLabel="always">
    <mat-label>{{ label }}</mat-label>
    <mat-chip-list #chipList>
      <mat-chip
        *ngFor="let chip of chipsDisplayed"
        [selectable]="isDisabled ? false : selectable"
        (removed)="remove(chip)"
        [selected]="chip.selected"
        [disabled]="isDisabled"
      >
        <button matChipRemove [disabled]="isDisabled">
          <mat-icon class="material-icons-outlined">cancel</mat-icon>
        </button>
        <div class="chip-text">{{ chip }}</div>
      </mat-chip>

      <input
        #chipSearchInput
        #trigger="matAutocompleteTrigger"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="add($event)"
        [matAutocomplete]="auto"
        [formControl]="searchForm?.get('text')"
        [disabled]="isDisabled"
      />
    </mat-chip-list>
    <mat-autocomplete class="assign-auto" #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option [disabled]="isDisabled" *ngFor="let data of filteredResults$ | async" [value]="data">
        {{ data }}
      </mat-option>
    </mat-autocomplete>

    <mat-icon
      *ngIf="!loading && !plusIcon"
      (click)="expandPanelClicked($event, auto)"
      [ngClass]="isDisabled || !autocompleteData?.length ? 'hide_arrow' : 'expand_arrow'"
      >{{ auto.isOpen ? 'expand_less' : 'expand_more' }}</mat-icon
    >
    <mat-icon *ngIf="!loading && plusIcon" (click)="addChipBasedOnInput()" class="expand_arrow">add</mat-icon>
    <haulynx-spinner class="loading_spinner" [active]="true" [diameter]="16" *ngIf="loading"></haulynx-spinner>
  </mat-form-field>
</div>
