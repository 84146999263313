import { Pipe, PipeTransform } from '@angular/core';
import { LoadActionEvent } from '@haulynx/types';

@Pipe({ name: 'transformBookToolTipPipe', pure: false })
export class TransformBookToolTipPipe implements PipeTransform {
  constructor() {}
  transform(action: LoadActionEvent): string {
    const user = action.user?.usxId || action.user?.name || 'Unkown user';

    if (action?.expiredAt) {
      const currentTime = action.expiredAt - new Date().valueOf();
      const timeRemaining = new Date(currentTime).toISOString().substring(14, 19);
      return timeRemaining + ' until unlocked, ' + user + ' locked load';
    }

    return user + ' locked Load';
  }
}
