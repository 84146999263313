import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Carrier, SaferWatchData } from '@haulynx/types';

@Component({
  selector: 'app-carrier-details-info',
  templateUrl: './carrier-details-info.component.html',
  styleUrls: ['./carrier-details-info.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CarrierDetailsInfoComponent {
  @Input() carrier: Carrier = null;
  @Input() isLoading = false;
  @Input() condensed = false;
  @Input() moreLoads = false;

  location: unknown[] = [];

  fields = [
    { label: 'Name', value: 'name' },
    // {label: 'Contact Name', value: 'name'},
    // {label: 'Domicile City/State', value: 'saferWatchData.address'},
    { label: 'Phone', value: 'phone' },
    { label: 'DOT Number', value: 'dot' },
    { label: 'Ownership', value: 'owner' },
    {
      label: 'MC Number',
      value: 'saferWatchData',
      eval: (saferWatchData: SaferWatchData): string => (saferWatchData ? saferWatchData.mcNumber : null),
    },
    // {
    //   label: 'Carrier Type',
    //   value: 'isHaulynxCarrier',
    //   eval: (isHaulynxCarrier: string) => isHaulynxCarrier === 'FALSE' ? 'Haulynx ELD Carrier' : 'Haulynx App Only Carrier'
    // },

    // {label: 'Interstate Number', value: 'interstateNumber'},
    // {label: 'Status', value: 'status'},
    // {label: 'FMCSA Rating', value: 'fmcsaRating'},
    // {label: 'Authority Effective Date', value: 'authorityEffectiveDate'},
    // {label: 'Fleet Size', value: 'fleetSize'},
    // {
    //   label: 'Equipment Type',
    //   value: 'equipmentType',
    //   eval: (equipmentTypes: string[]) => equipmentTypes ? equipmentTypes.join(', ') : ''
    // },
    // {
    //   label: 'Hazmat Capable',
    //   value: 'hazmatCapable',
    //   eval: (hazmatCapable: boolean) => hazmatCapable ? 'Yes' : 'No'
    // },
  ];

  constructor() {}
}
