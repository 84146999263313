import { Injectable } from '@angular/core';
import {
  CarrierInfoSectionForm,
  DriversService,
  LoadForm,
  LoadsServiceService,
  LoadVm,
  NotificationsService,
  TrailersService,
  TrucksService,
} from '@haulynx/services';
import {
  AssignDriverForm,
  AssignLoadPayload,
  CreateTrailerDto,
  CreateTruckDto,
  DispatchForm,
  DispatchLocationInput,
  DriverAssignment,
  Load,
  LoadsServiceLoadInput,
  LocationForm,
  LoadsServiceLoad,
  User,
  BookLoadForCarrier,
  TrackingType,
  AssignmentForm,
  TrackingEnabledInput,
  TrailerOwnerType,
  AssignLoadInput,
} from '@haulynx/types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { omit } from 'lodash';
import { forkJoin, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppModel } from '../../main-store/app-model';
import { AppState } from '../../main-store/app.reducers';
import { CommonEntities } from '../../shared/common-entities/common-entities.model';
import { DispatchAction } from '../../shared/store/helpers/action';
import { LoadDetailsActions, LoadDetailsActionTypes } from './load-details.actions';
import { LoadDetailsAnalyticsEffects } from './load-details.analytics.effects';
import { loadDetailsSelector } from './load-details.selectors';

@Injectable({
  providedIn: 'root',
})
export class LoadDetailsEffects {
  refreshLoad = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadDetailsActionTypes.REFRESH),
      map((action: DispatchAction) => action.payload),
      withLatestFrom(this.store.select(loadDetailsSelector.getMode)),
      switchMap(([payload]) => {
        const { key, id } = payload;

        return this.loadsServiceService.getLoadById(id).pipe(
          map((state) => LoadDetailsActions.getSuccess({ key, state })),
          catchError((error) => of(LoadDetailsActions.getError({ key, error })))
        );
      })
    )
  );

  getLoad = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadDetailsActionTypes.GET),
      map((action: DispatchAction) => action.payload),
      withLatestFrom(this.store.select(loadDetailsSelector.getMode)),
      switchMap(([payload, mode]) => {
        const { key, id, isLatest = false } = payload;

        if (mode === 'new') {
          const state = new LoadsServiceLoad();

          return of(LoadDetailsActions.getSuccess({ key, state }));
        } else {
          return this.loadsServiceService.getLoadById(id).pipe(
            map((state) => {
              if (state === null) {
                this.notificationsService.error(`Load not found!`, `Load`);
              }

              return LoadDetailsActions.getSuccess({ key, state });
            }),
            catchError((error) => {
              this.notificationsService.error(`Oops, something went wrong. Unable to get load!`, `Load`);

              return of(LoadDetailsActions.getError({ key, error }));
            })
          );
        }
      })
    )
  );

  loadAssignmentToDriverAssignmentForm = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadDetailsActionTypes.ASSIGNMENT_TO_DRIVER),
      map((action: DispatchAction) => action.payload),
      withLatestFrom(this.store.select(loadDetailsSelector.getState)),
      switchMap(
        ([payload, load]: [
          { loadId: string; assignment: Partial<AssignmentForm>; carrierContact: CarrierInfoSectionForm },
          LoadsServiceLoad
        ]) => {
          const { loadId, assignment, carrierContact } = payload as {
            loadId: string;
            assignment: Partial<AssignmentForm>;
            carrierContact: CarrierInfoSectionForm;
          };
          const { trackingType, trailerId, phone, driverId, truckId, secDriverId, secPhone } = assignment;

          const carrierId = load.carrier?.id;
          const drivers = driverId
            ? secDriverId
              ? [
                  { id: driverId || null, phone: phone || null, priority: 1 },
                  { id: secDriverId || null, phone: secPhone || null, priority: 2 },
                ]
              : [{ id: driverId || null, phone: phone || null, priority: 1 }]
            : secDriverId
            ? [{ id: secDriverId || null, phone: secPhone || null, priority: 2 }]
            : null;

          const contacts = [
            {
              contactName: carrierContact.contact,
              contactPhone: carrierContact.phone,
              contactEmail: carrierContact.email,
            },
          ];
          let trailerIds;
          if (trailerId) {
            trailerIds = [trailerId];
          }

          let assignInput: AssignLoadInput = {
            trackingType,
            drivers,
            truckId,
            contacts,
          };

          if (trailerIds) {
            assignInput = { ...assignInput, trailerIds };
          }

          const assignLoadPayload: AssignLoadPayload = {
            loadId,
            carrierId,
            assignInput,
          };

          return this.loadsServiceService.assignLoadServiceLoad(assignLoadPayload).pipe(
            map(() => {
              return LoadDetailsActions.assignmentToDriverSuccess({ loadId, state: load });
            }),
            catchError((err) => {
              this.notificationsService.error(err, `Tracking Error`);
              return of(LoadDetailsActions.assignmentToDriverError({ loadId }));
            })
          );
        }
      )
    )
  );

  getLoadMultipleRoute = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadDetailsActionTypes.GET_ROUTES),
      map((action: DispatchAction) => action.payload),
      mergeMap((payload) => {
        if (_.isArray(payload)) {
          const roads = _.map(payload, ({ key, coordinates, mapId }) =>
            this.loadsServiceService.getSingleLoadRoute(coordinates).pipe(
              map((geojson) => {
                return { key, geojson, mapId };
              }),
              catchError((error) => {
                this.notificationsService.error(error.message, `Map`);

                return of(LoadDetailsActions.getRoutesError(error));
              })
            )
          );

          return forkJoin(...roads).pipe(map((result) => LoadDetailsActions.getRouteSuccess(result)));
        } else {
          const { key, coordinates, mapId } = payload;

          return this.loadsServiceService.getSingleLoadRoute(coordinates).pipe(
            map((geojson) => LoadDetailsActions.getRouteSuccess({ key, geojson, mapId })),
            catchError((error) => of(LoadDetailsActions.getRoutesError(error)))
          );
        }
      })
    )
  );

  getLoadRoute = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadDetailsActionTypes.GET_ROUTE),
      map((action: DispatchAction) => action.payload),
      switchMap((payload) => {
        const { key, coordinates } = payload;

        return this.loadsServiceService.getSingleLoadRoute(coordinates).pipe(
          map((geojson) => LoadDetailsActions.getRouteSuccess({ key, geojson })),
          catchError((error) => {
            this.notificationsService.error(error.message, `Map`);

            return of(LoadDetailsActions.getRouteError(error));
          })
        );
      })
    )
  );

  saveTemplate = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadDetailsActionTypes.SAVE_TEMPLATE),
      map((action: DispatchAction) => action.payload),
      withLatestFrom(
        this.store.select(loadDetailsSelector.getMode),
        this.store.select(loadDetailsSelector.getState),
        this.appModel.user$
      ),
      switchMap(
        ([payload, , , user]: [
          { key: string; load: LoadForm; selectedEntities: { [key: string]: unknown } },
          string,
          Load,
          User
        ]) => {
          const { load, selectedEntities } = payload;
          const loadTemplate = this.loadVm.toTemplate(load, user, selectedEntities);

          return this.loadsServiceService.createTemplate(loadTemplate as unknown as LoadsServiceLoadInput).pipe(
            map(() => {
              this.notificationsService.success('The template was saved', 'Template');
              return LoadDetailsActions.saveTemplateSuccess();
            }),
            catchError((error) => {
              this.notificationsService.error(error);
              return of(LoadDetailsActions.saveTemplateError(error));
            })
          );
        }
      )
    )
  );

  getTruckLocation = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadDetailsActionTypes.GET_DEVICE_LOCATIONS),
      map((action: DispatchAction) => action.payload),
      switchMap((payload: { loadId: string }) =>
        this.loadsServiceService.getTruckLastLoadLocationByLoadId(payload.loadId).pipe(
          map((location) => {
            const gpsLat = _.get(location, 'gpsLat', null);
            const gpsLon = _.get(location, 'gpsLon', null);

            if (gpsLat && gpsLon) {
              return LoadDetailsActions.getDeviceLocationsSuccess({
                key: payload.loadId,
                location: { lat: gpsLat, lon: gpsLon },
              });
            }

            return LoadDetailsActions.getDeviceLocationsSuccess({});
          }),
          catchError((error) => of(LoadDetailsActions.getDeviceLocationsError(error)))
        )
      )
    )
  );

  loadDetailLoadDispatch = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadDetailsActionTypes.DISPATCH_DETAIL_LOAD),
      map((action: DispatchAction) => action.payload),
      switchMap((payload: { loadId: string; data: DispatchForm }) => {
        const { loadId, data } = payload as { loadId: string; data: DispatchForm };
        const dispatchInput: DispatchLocationInput = {
          address: data.location.address,
          lat: data.location.lat,
          lon: data.location.lon,
          timezone: data.location.timeZone,
          timestamp: data.timeAvailable,
          notes: data.notes,
        };

        return this.loadsServiceService.dispatchLoad(loadId, dispatchInput).pipe(
          map(() => {
            return LoadDetailsActions.dispatchDetailLoadSuccess({ loadId });
          }),
          catchError((err) => {
            return of(LoadDetailsActions.dispatchDetailLoadError({ loadId }));
          })
        );
      })
    )
  );

  loadAssignToDriver = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadDetailsActionTypes.ASSIGN_TO_DRIVER),
      map((action: DispatchAction) => action.payload),
      withLatestFrom(this.store.select(loadDetailsSelector.getState)),
      switchMap(([payload, load]: [{ loadId: string; data: AssignDriverForm }, LoadsServiceLoad]) => {
        const { loadId, data } = payload as { loadId: string; data: AssignDriverForm };
        const { trackingType, trailer, phone, driver, truck } = data;
        const carrierId = load?.carrier?.id || null;

        const drivers = driver?.id ? [{ id: driver.id, phone: driver.phone || phone }] : [];
        const truckId = truck?.id || null;
        const trailerIds = trailer?.id ? [trailer?.id] : [];

        const assignLoadPayload: AssignLoadPayload = {
          loadId,
          carrierId,
          assignInput: {
            trackingType,
            drivers,
            truckId,
            trailerIds,
          },
        };

        return this.loadsServiceService.assignLoadServiceLoad(assignLoadPayload).pipe(
          map(() => {
            this.notificationsService.success('Load successfully assigned', 'Load assigned');
            return LoadDetailsActions.assignToDriverSuccess({ loadId, state: load });
          }),
          catchError((err) => {
            const message: string = _.get(
              err,
              'errors[0].message',
              'Oops, something went wrong. Unable to save load assignment!'
            );
            this.notificationsService.error(message, 'Load assignment failed');
            return of(LoadDetailsActions.assignToDriverError({ loadId }));
          })
        );
      })
    )
  );

  loadDispatch = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadDetailsActionTypes.DISPATCH),
      map((action: DispatchAction) => action.payload),
      switchMap((payload: { loadId: string; data: DispatchForm }) => {
        const { loadId, data } = payload as { loadId: string; data: DispatchForm };
        const dispatchInput: DispatchLocationInput = {
          address: data.location.address,
          lat: data.location.lat,
          lon: data.location.lon,
          timezone: data.location.timeZone,
          timestamp: data.timeAvailable,
          notes: data.notes,
        };

        return this.loadsServiceService.dispatchLoad(loadId, dispatchInput).pipe(
          map(() => {
            this.notificationsService.success('Load successfully dispatched', 'Load dispatch.');
            return LoadDetailsActions.dispatchSuccess({ loadId });
          }),
          catchError((err) => {
            this.notificationsService.error(err, 'Load dispatched.');
            return of(LoadDetailsActions.dispatchError({ loadId }));
          })
        );
      })
    )
  );

  confirmLocation = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadDetailsActionTypes.CONFIRM_LOCATION),
      map((action: DispatchAction) => action.payload),
      switchMap((payload: { loadId: string; locationId: number; data: LocationForm }) => {
        const { loadId, data, locationId } = payload;
        const updateLocation = {
          arrivalTime: data.arrivalTime ? Number(data.arrivalTime.valueOf()) : null,
          departureTime: data.departureTime ? Number(data.departureTime.valueOf()) : null,
        };

        return this.loadsServiceService
          .updateArrivalTimeOrCompleteLoadLocation(loadId, locationId, updateLocation)
          .pipe(
            map((location) => {
              this.notificationsService.success('Load location was confirmed successfully', 'Load dispatch.');
              return LoadDetailsActions.confirmLocationSuccess({ loadId, location });
            }),
            catchError((error) => {
              this.notificationsService.error(
                error?.message || 'Oops, something went wrong. Unable to confirm!',
                `Load`
              );

              return of(LoadDetailsActions.confirmLocationError({ loadId }));
            })
          );
      })
    )
  );

  bookLoadForCarrier = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadDetailsActionTypes.BOOK_LOAD_FOR_CARRIER),
      map((action: DispatchAction) => action.payload),
      switchMap((payload: BookLoadForCarrier) => {
        const { loadId } = payload;

        let modifiedPayload: Partial<BookLoadForCarrier> = payload;
        if (payload.data.assignmentInfo.trailerOwner === TrailerOwnerType.USXI) {
          modifiedPayload = omit(payload, ['data.assignmentInfo.trailerId']);
        }
        return this.loadsServiceService.bookLoadForCarrier(modifiedPayload).pipe(
          map((response: { data: { bookCarrier: unknown }; error: { message: string } }) => {
            this.notificationsService.success('Load booked successfully!', 'Booking');
            if (response?.error?.message) {
              this.notificationsService.error(response?.error?.message, `Booking Error`);
            }
            return LoadDetailsActions.bookLoadForCarrierSuccess({ loadId });
          }),
          catchError((error) => {
            const message: string = error?.message ?? 'Oops, something went wrong. Unable to book load!';
            this.notificationsService.error(message, `Booking`);
            return of(LoadDetailsActions.bookLoadForCarrierError({ loadId }));
          })
        );
      })
    )
  );

  bounceCarrier = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadDetailsActionTypes.BOUNCE_CARRIER),
      map((action: DispatchAction) => action.payload),
      switchMap((payload) => {
        const { loadId, orderNumber } = payload;

        return this.loadsServiceService.bounceCarrier(orderNumber).pipe(
          map(() => {
            this.notificationsService.success('Carrier bounced sucessfully!', 'Carrier');
            return LoadDetailsActions.bounceCarrierSuccess({ loadId });
          }),
          catchError((error) => {
            const message: string = _.get(error, 'message', 'Oops, something went wrong. Unable to bounce carrier!');
            this.notificationsService.error(message, `Carrier`);
            return of(LoadDetailsActions.bounceCarrierError({ loadId }));
          })
        );
      })
    )
  );

  updateLoad = createEffect(() =>
    this.actions$.pipe(
      ofType(
        LoadDetailsActionTypes.ASSIGN_TO_DRIVER_SUCCESS,
        LoadDetailsActionTypes.DISPATCH_SUCCESS,
        LoadDetailsActionTypes.CONFIRM_LOCATION_SUCCESS,
        LoadDetailsActionTypes.BOOK_LOAD_FOR_CARRIER_SUCCESS,
        LoadDetailsActionTypes.BOUNCE_CARRIER_SUCCESS
      ),
      map((action: DispatchAction) => action.payload),
      map((payload: { loadId: string }) => {
        const { loadId } = payload;
        const params = { key: loadId, id: loadId };

        return LoadDetailsActions.refresh(params);
      })
    )
  );

  createDriver = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadDetailsActionTypes.CREATE_DRIVER),
      map((action: DispatchAction) => action.payload),
      withLatestFrom(this.store.select(loadDetailsSelector.getStateKey)),
      switchMap(([payload, key]: [DriverAssignment, string]) =>
        this.driverService.createUnregisteredDriver(payload).pipe(
          map(({ data }) => {
            const driverId = _.get(data, 'createDriver.data.id', null);
            const query = payload.carrierDot ? { carrierDot: payload.carrierDot } : { carrierId: payload.carrierId };

            this.store.dispatch(LoadDetailsActions.populateDriver(driverId));
            this.notificationsService.success('Driver assigned successfully!', 'Drivers');
            this.commonEntities.graphQlDrivers.search({ key, query });

            return LoadDetailsActions.createTruckSuccess();
          }),
          catchError((error) => {
            const message: string = _.get(error, 'message', 'Oops, something went wrong. Unable to assign a driver!');
            this.notificationsService.error(message, 'Drivers');
            return of(LoadDetailsActions.createTruckError());
          })
        )
      )
    )
  );

  createTruck = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadDetailsActionTypes.CREATE_TRUCK),
      map((action: DispatchAction) => action.payload),
      withLatestFrom(this.store.select(loadDetailsSelector.getStateKey)),
      switchMap(([payload, key]) => {
        const { carrierDot = null, carrierId = null, unitId = null } = payload;
        const truckDto: CreateTruckDto = { unitId, carrierId: carrierDot ? carrierDot : carrierId };

        return this.truckService.createTruckEld(truckDto).pipe(
          map((truckId: string) => {
            const query = carrierDot ? { carrierDot } : { carrierId };

            this.store.dispatch(LoadDetailsActions.populateTruck(truckId));
            this.loadDetailsAnalytics.logCreateTruckSuccess(key, truckId);
            this.notificationsService.success('Truck assigned successfully!', 'Trucks');
            this.commonEntities.graphQlTrucks.search({ key, query });

            return LoadDetailsActions.createTruckSuccess();
          }),
          catchError((error) => {
            const message: string = _.get(error, 'message', 'Oops, something went wrong. Unable to assign a truck!');
            this.notificationsService.error(message, 'Trucks');
            return of(LoadDetailsActions.createTruckError());
          })
        );
      })
    )
  );

  createTrailer = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadDetailsActionTypes.CREATE_TRAILER),
      map((action: DispatchAction) => action.payload),
      withLatestFrom(this.store.select(loadDetailsSelector.getStateKey)),
      switchMap(([payload, key]) => {
        const { carrierDot = null, carrierId = null, trailerNumber = null } = payload;
        const carrier = { id: carrierDot ? carrierDot : carrierId };
        const trailerDto: CreateTrailerDto = { trailerNumber, carrier };

        return this.trailerService.createTrailerEld(trailerDto).pipe(
          map((trailerId: string) => {
            const query = carrierDot ? { carrierDot } : { carrierId };

            this.store.dispatch(LoadDetailsActions.populateTrailer(trailerId));
            this.notificationsService.success('Trailer assigned successfully!', 'Trailers');
            this.commonEntities.graphQlTrailers.search({ key, query });

            return LoadDetailsActions.createTrailerSuccess();
          }),
          catchError((error) => {
            const message: string = _.get(error, 'message', 'Oops, something went wrong. Unable to assign a trailer!');
            this.notificationsService.error(message, 'Trailers');
            return of(LoadDetailsActions.createTrailerError());
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private loadsServiceService: LoadsServiceService,
    private notificationsService: NotificationsService,
    private store: Store<AppState>,
    private loadVm: LoadVm,
    private appModel: AppModel,
    private commonEntities: CommonEntities,
    private truckService: TrucksService,
    private trailerService: TrailersService,
    private driverService: DriversService,
    private loadDetailsAnalytics: LoadDetailsAnalyticsEffects
  ) {}
}
