import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { InProgressComponent } from './in-progress.component';

@NgModule({
  imports: [MatProgressSpinnerModule, CommonModule],
  exports: [InProgressComponent],
  declarations: [InProgressComponent],
})
export class InProgressModule {}
