import { Component, ChangeDetectionStrategy, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { AcceptFileExtensionTypes } from '@haulynx/types';

@Component({
  selector: 'haulynx-file-drag-and-drop',
  templateUrl: './file-drag-and-drop.component.html',
  styleUrls: ['./file-drag-and-drop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileDragAndDropComponent {
  @Input() acceptedFileExtensions: string[] = ['*'];
  @Input() isMultiUpload = false;

  @Output() filesSelected = new EventEmitter<File[]>();

  @ViewChild('inputEl') inputEl: ElementRef;

  selectedFiles: File[] = [];
  selectionIsValid = true;

  onFileDropped($files: FileList): void {
    this.processFiles($files);
  }

  onFileChangeEvent($event: any): void {
    $event.preventDefault();
    $event.stopPropagation();

    this.processFiles($event.target.files);

    // Deselect files since we track them manually to prevent re-selection bug(s).
    (this.inputEl.nativeElement as HTMLInputElement).value = '';
  }

  processFiles(files: FileList): void {
    const filteredFiles = this.filterFiles(files);
    if (filteredFiles.length === 0) {
      this.selectionIsValid = false;
      return;
    }

    this.selectionIsValid = true;

    if (this.isMultiUpload) {
      this.selectedFiles = filteredFiles;
    } else {
      this.selectedFiles = [filteredFiles[0]];
    }

    this.filesSelected.emit(this.selectedFiles);
  }

  filterFiles(files: FileList): File[] {
    // If we included the wildcard ('*'), we should return everything, skip filtering.
    if (this.acceptedFileExtensions.includes(AcceptFileExtensionTypes.WILDCARD_EXT)) {
      return Array.from(files);
    }

    // No wildcard ('*'), filter for accepted file types.
    return Array.from(files).filter((file) => {
      for (const ext of this.acceptedFileExtensions) {
        const pat = RegExp(ext);
        if (pat.test(file.type)) {
          return file;
        }
      }
    });
  }
}
