import { type } from '@haulynx/utils';
import { Action } from '@ngrx/store';

const listActionsTypes = {};

export interface ICommonEntityAction extends Action {
  payload: any;
  name: string;
}

export interface ICommonEntitiesActionTypes {
  SEARCH: string;
  SEARCH_SUCCESS: string;
  SEARCH_ERRORS: string;
  CLEAR: string;
}

export class Search implements ICommonEntityAction {
  type: string;
  payload: any;
  name: string;

  constructor(payload: any, typeAction: ICommonEntitiesActionTypes, entityName: string) {
    this.payload = payload;
    this.type = typeAction.SEARCH;
    this.name = entityName;
  }
}

export class SearchSuccess implements ICommonEntityAction {
  type: string;
  payload: any;
  name: string;

  constructor(payload: any, typeAction: ICommonEntitiesActionTypes, entityName: string) {
    this.payload = payload;
    this.type = typeAction.SEARCH_SUCCESS;
    this.name = entityName;
  }
}

export class SearchErrors implements ICommonEntityAction {
  type: string;
  payload: any;
  name: string;

  constructor(payload: any, typeAction: ICommonEntitiesActionTypes, entityName: string) {
    this.payload = payload;
    this.type = typeAction.SEARCH_ERRORS;
    this.name = entityName;
  }
}

export class Clear implements ICommonEntityAction {
  type: string;
  payload = null;
  name: string;

  constructor(payload: any, typeAction: ICommonEntitiesActionTypes, entityName: string) {
    this.payload = payload;
    this.type = typeAction.CLEAR;
    this.name = entityName;
  }
}

export interface ICommonEntitiesActions {
  search(payload: any): ICommonEntityAction;

  searchSuccess(payload: any): ICommonEntityAction;

  searchErrors(payload: any): ICommonEntityAction;

  clear(): ICommonEntityAction;
}

export function commonActionTypes(entityName = `DefaultType`): ICommonEntitiesActionTypes {
  if (!listActionsTypes.hasOwnProperty(entityName)) {
    listActionsTypes[entityName] = {
      SEARCH: type(`[DROPDOWN ${entityName}] search`),
      SEARCH_SUCCESS: type(`[DROPDOWN ${entityName}] search success`),
      SEARCH_ERRORS: type(`[DROPDOWN ${entityName}] search errors`),
      CLEAR: type(`[DROPDOWN ${entityName}] clear state`),
    };
  }

  return listActionsTypes[entityName];
}

export function commonEntitiesActionClasses(
  typeAction: ICommonEntitiesActionTypes,
  entityName: string
): ICommonEntitiesActions {
  return {
    search: (payload) => {
      return new Search(payload, typeAction, entityName);
    },
    searchSuccess: (payload) => {
      return new SearchSuccess(payload, typeAction, entityName);
    },
    searchErrors: (payload) => {
      return new SearchErrors(payload, typeAction, entityName);
    },
    clear: (payload?) => {
      return new Clear(payload, typeAction, entityName);
    },
  } as ICommonEntitiesActions;
}
