import { Map } from 'immutable';
import { assign } from 'lodash';

export interface ITab {
  label?: string;
  url?: any;
  selected?: boolean;
  disabled?: boolean;
  closable?: boolean;
  controlClose?: boolean;
  data?: { [p: string]: any } | any;
  id?: string;
  order?: number | null;
  queryParams?: any;
  matBadge?: string | number;
  isMatBadge?: boolean;
  hideLabel?: boolean;
}

export class Tab implements ITab {
  label: string;
  url: any;
  selected = false;
  disabled = false;
  closable = false;
  controlClose = false;
  data?: { [p: string]: any } | any;
  id: string;
  order: number | null;
  queryParams?: any = null;
  matBadge?: string | number;
  isMatBadge?: boolean;
  hideLabel? = false;

  constructor(config?: ITab) {
    assign(this, config);
  }
}

export interface ITabsState {
  tabs: Map<string, Tab>;
  selectedTabId: string;
}
