import { CommonModule, CurrencyPipe, DecimalPipe, TitleCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { AssignedToNamesPipe } from './assigned-to-names/assigned-to-names.pipe';
import { AssignedToPipe } from './assigned-to/assigned-to.pipe';
import { BidNotificationTextPipe, BidSourceTypeCountPipe, LastCarrierMessagePipe, TotalMessagesPipe } from './bids';
import { BrokerListPipe, GetBrokerIdFromEmailPipe } from './broker-list';
import { CarrierOwnerPipe, IsCarrierOwnedPipe, ShowCarrierMarkPipe } from './carriers';
import { GetRowClassPipe, IsExpandedPipe } from './data-table';
import { DateTimePipe, TimeDiffPipe, WorkHoursPipe } from './date';
import { DedicatedAllowedPipe } from './dedicated-allowed/dedicated-allowed.pipe';
import { FormatDistancePipe } from './format-distance';
import {
  CastPipe,
  ContainsPipe,
  FilterPipe,
  FromCamelCasePipe,
  GetApptTextPipe,
  GetLastLocationTimeDiffPipe,
  GetLateOrOnTimeColorPipe,
  GetPipe,
  GetPriceTextPipe,
  GreaterThanPipe,
  IncludesPipe,
  LaneVolatilityPipe,
  LessThanPipe,
  LoadRestrictionsPipe,
  LoadsServiceLocationTimePipe,
  LoadStatusIconPipe,
  MaxValPipe,
  NthPipe,
  PhoneMaskPipe,
  RegionByEmailPipe,
  SafeNumberPipe,
  SafeUrlPipe,
  ShowCurrencyPipe,
  TimestampPipe,
  ToCamelCasePipe,
  TransformPickupTimePipe,
  TransformStatusPipe,
  TruckUnitDisplayNamePipe,
  UserInitialsPipe,
  ValidationErrorPipe,
  FromSnakeCasePipe,
} from './generic';
import { StripHtmlPipe } from './generic/strip-html/strip-html.pipe';
import { TransformLoadStatusPipe } from './generic/transform-load-status.pipe';
import { GoogleLatLonPipe } from './google-lat-lon';
import { LaneHistoryStatusPipe, MathJSPipe } from './lane-history';
import { DaysOfWeekPipe } from './lane/days-of-week.pipe';
import { TransformBookToolTipPipe } from './load/transform-book-tooltip.pipe';
import {
  CanViewFacilityPipe,
  FromDocumentNamePipe,
  FromDocumentTypePipe,
  IsLoadLockedPipe,
  LoadAddressPipe,
  LoadAddressPrivatePipe,
  LoadAlternateIdPipe,
  LoadHistoryActionPipe,
  LoadLocationDatePipe,
  LoadStatusPipe,
  LoadTimeRangePipe,
  LoadWaypointClassPipe,
  LoadWaypointTypePipe,
} from './loads';
import { LoadLocationWaitTimeStatusPipe } from './loads/load-location-wait-time-status.pipe';
import { AddressPipe } from './location';
import { TransformMilestoneSubType } from './milestone/transform-sub-type.pipe';
import { MomentTzPipe } from './moment-tz';
import { NotificationMessagePipe } from './notifications/notification-message.pipe';
import { OptionSortPipe } from './option-sort';
import { OrdinalSuffixPipe } from './ordinal-suffix/ordinal-suffix.pipe';
import { TaskToCompletePipe } from './task-to-complete/task-to-complete.pipe';
import { TextCutoffPipe } from './text-cutoff/text-cutoff.pipe';
import { TimeDurationPipe } from './time-duration';
import { TimezonedPipe } from './timezoned';
import { AdditionalStopsPipe } from './tooltips';
import { TrailerInstructionsLocationTypePipe } from './trailer-instructions-location-type/trailer-instructions-location-type.pipe';
import { TruckLocationPipe } from './truck-location';
import { VirtualTableGetPipe } from './virtual-table/virtual-table-get.pipe';
import { VirtualTableSortTypeSelectedPipe } from './virtual-table/virtual-table-sort-type-selected.pipe';
import { CarrierLoadSearchListDisplayPipe } from './carrier-load-search-list-display/carrier-load-search-list-display.pipe';
import { FullDayPipe } from './date/full-day.pipe';
import { MissionValidationPipe } from './loads/mission-validation.pipe';

const pipes = [
  AdditionalStopsPipe,
  AddressPipe,
  AssignedToNamesPipe,
  AssignedToPipe,
  CarrierLoadSearchListDisplayPipe,
  BidNotificationTextPipe,
  BidSourceTypeCountPipe,
  BrokerListPipe,
  CanViewFacilityPipe,
  CarrierOwnerPipe,
  CastPipe,
  ContainsPipe,
  DateTimePipe,
  DaysOfWeekPipe,
  DedicatedAllowedPipe,
  FilterPipe,
  FormatDistancePipe,
  FromCamelCasePipe,
  FromDocumentNamePipe,
  FromDocumentTypePipe,
  FromSnakeCasePipe,
  FullDayPipe,
  GetApptTextPipe,
  GetLateOrOnTimeColorPipe,
  GetLastLocationTimeDiffPipe,
  GetPipe,
  GetPriceTextPipe,
  GetBrokerIdFromEmailPipe,
  GetRowClassPipe,
  GoogleLatLonPipe,
  GreaterThanPipe,
  IncludesPipe,
  IsCarrierOwnedPipe,
  IsExpandedPipe,
  LaneHistoryStatusPipe,
  LaneVolatilityPipe,
  LastCarrierMessagePipe,
  LessThanPipe,
  LoadAddressPipe,
  LoadAddressPrivatePipe,
  LoadAlternateIdPipe,
  LoadHistoryActionPipe,
  LoadLocationDatePipe,
  LoadLocationWaitTimeStatusPipe,
  LoadRestrictionsPipe,
  LoadsServiceLocationTimePipe,
  LoadStatusIconPipe,
  LoadStatusPipe,
  LoadTimeRangePipe,
  LoadWaypointClassPipe,
  LoadWaypointTypePipe,
  MathJSPipe,
  MaxValPipe,
  MissionValidationPipe,
  MomentTzPipe,
  NotificationMessagePipe,
  NthPipe,
  OptionSortPipe,
  OptionSortPipe,
  OrdinalSuffixPipe,
  PhoneMaskPipe,
  RegionByEmailPipe,
  SafeNumberPipe,
  SafeUrlPipe,
  ShowCarrierMarkPipe,
  ShowCurrencyPipe,
  StripHtmlPipe,
  TaskToCompletePipe,
  TextCutoffPipe,
  TimeDiffPipe,
  TimeDurationPipe,
  TimestampPipe,
  TimezonedPipe,
  ToCamelCasePipe,
  TotalMessagesPipe,
  TrailerInstructionsLocationTypePipe,
  TransformBookToolTipPipe,
  TransformLoadStatusPipe,
  TransformMilestoneSubType,
  TransformPickupTimePipe,
  TransformStatusPipe,
  TruckLocationPipe,
  TruckUnitDisplayNamePipe,
  ValidationErrorPipe,
  TransformBookToolTipPipe,
  IsLoadLockedPipe,
  OptionSortPipe,
  TextCutoffPipe,
  NotificationMessagePipe,
  DedicatedAllowedPipe,
  TransformStatusPipe,
  UserInitialsPipe,
  ValidationErrorPipe,
  VirtualTableGetPipe,
  VirtualTableSortTypeSelectedPipe,
  WorkHoursPipe,
];

@NgModule({
  imports: [CommonModule],
  declarations: [...pipes],
  exports: [...pipes],
  providers: [
    // provide pipes when they are used in the .ts files
    AddressPipe,
    CarrierOwnerPipe,
    CurrencyPipe,
    DecimalPipe,
    FromCamelCasePipe,
    LoadAlternateIdPipe,
    LoadLocationDatePipe,
    LoadLocationWaitTimeStatusPipe,
    LoadRestrictionsPipe,
    LoadRestrictionsPipe,
    LoadRestrictionsPipe,
    LoadsServiceLocationTimePipe,
    LoadStatusPipe,
    MomentTzPipe,
    RegionByEmailPipe,
    TimestampPipe,
    TitleCasePipe,
    ToCamelCasePipe,
    ValidationErrorPipe,
  ],
})
export class PipesModule {}
