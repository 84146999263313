import gql from 'graphql-tag';

export const GetReleaseNotes = gql`
  query GetReleaseNotes($userId: StrictId, $userType: UserType, $before: NonNegativeFloat, $includeExpired: Boolean) {
    getReleaseNotes(userId: $userId, userType: $userType, before: $before, includeExpired: $includeExpired) {
      id
      title
      userType
      date
      expiration
      hasViewed
    }
  }
`;
