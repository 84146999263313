<app-spinner [fullCover]="true" [transparentBackground]="true" [active]="isLoading"></app-spinner>

<mgl-map
  #map
  class="border-radius-{{ borderRadius }}"
  [style]="style"
  [zoom]="zoom"
  [center]="center"
  [fitBounds]="bounds"
  [interactive]="isInteractive"
  [scrollZoom]="scrollZoom"
  [maxZoom]="maxZoom"
  [fitBoundsOptions]="{ padding: padding }"
>
  <ng-container *ngFor="let route of routes; let i = index; trackBy: trackByFn">
    <mgl-layer
      *ngIf="route"
      id="{{ route.id }}"
      type="line"
      [source]="route.source"
      [paint]="{
        'line-color': route.lineColor,
        'line-width': route.lineWidth,
        'line-opacity': routeToHighlight && !(route.id | contains: routeToHighlight) ? 0.1 : 1
      }"
      [layout]="{
        'line-join': 'round',
        'line-cap': 'round'
      }"
    >
    </mgl-layer>

    <mgl-layer
      *ngIf="showTruckRoute && route?.truckPosition"
      id="{{ route.id }}Truck"
      type="line"
      [source]="route?.truckRoute"
      [paint]="{
        'line-color': route.markerColor,
        'line-width': route.truckLineWidth
      }"
      [layout]="{
        'line-join': 'round',
        'line-cap': 'round'
      }"
    >
    </mgl-layer>

    <ng-container *ngIf="route.lineColor !== 'transparent'">
      <ng-container
        *ngFor="let wayPoint of route.wayPoints; let i = index; let last = last; let first = first; let marker = index"
      >
        <ng-container *ngIf="wayPoint.name; else noName">
          <mgl-marker #marker [lngLat]="wayPoint.location">
            <ng-container [ngSwitch]="route.markerStyle">
              <div
                *ngSwitchCase="markerStyle.TRUCK"
                class="way-point-marker__wrapper"
                [ngClass]="{
                  'way-point-marker--muted': routeToHighlight && !(route.id | contains: routeToHighlight)
                }"
              >
                <div
                  class="way-point-marker--truck"
                  [ngClass]="{ destination: !first && last, highlighted: route.id | contains: truckToHighlight }"
                ></div>
              </div>
              <div
                *ngSwitchDefault
                class="way-point-marker__wrapper"
                [ngClass]="{
                  'way-point-marker--muted': routeToHighlight && !(route.id | contains: routeToHighlight)
                }"
              >
                <div
                  class="way-point-marker--outer"
                  [ngClass]="{ destination: wayPoint.type === locationType.DROPOFF }"
                ></div>
                <div
                  class="way-point-marker--inner"
                  [ngClass]="{ destination: wayPoint.type === locationType.DROPOFF }"
                ></div>
              </div>
            </ng-container>
          </mgl-marker>
          <mgl-popup [marker]="marker">
            {{ wayPoint.name }}
          </mgl-popup>
        </ng-container>

        <ng-template #noName>
          <mgl-marker [lngLat]="wayPoint.location">
            <ng-container [ngSwitch]="route.markerStyle">
              <div
                *ngSwitchCase="markerStyle.TRUCK"
                class="way-point-marker__wrapper"
                [ngClass]="{
                  'way-point-marker--muted': routeToHighlight && !(route.id | contains: routeToHighlight)
                }"
              >
                <div
                  class="way-point-marker--truck"
                  [ngClass]="{ destination: !first && last, highlighted: route.id | contains: truckToHighlight }"
                ></div>
              </div>
              <div
                *ngSwitchDefault
                class="way-point-marker__wrapper"
                [ngClass]="{
                  'way-point-marker--muted': routeToHighlight && !(route.id | contains: routeToHighlight)
                }"
              >
                <div
                  class="way-point-marker--outer"
                  [ngClass]="{ destination: wayPoint.type === locationType.DROPOFF }"
                ></div>
                <div
                  class="way-point-marker--inner"
                  [ngClass]="{ destination: wayPoint.type === locationType.DROPOFF }"
                ></div>
              </div>
            </ng-container>
          </mgl-marker>
        </ng-template>
      </ng-container>
    </ng-container>

    <mgl-marker *ngIf="route.lineColor !== 'transparent' && route?.truckPosition" [lngLat]="route.truckPosition">
      <div class="truck__wrapper">
        <div class="truck truck__marker"></div>
        <div class="truck__marker--static"></div>
      </div>
    </mgl-marker>
  </ng-container>

  <mgl-marker *ngFor="let marker of flatMarkers" [lngLat]="marker.coordinates">
    <div class="way-point-marker__wrapper">
      <div
        class="way-point-marker--outer"
        [ngClass]="{ destination: marker?.locationType === locationType.DROPOFF }"
      ></div>
      <div
        class="way-point-marker--inner"
        [ngClass]="{ destination: marker?.locationType === locationType.DROPOFF }"
      ></div>
    </div>
  </mgl-marker>

  <mgl-control mglNavigation *ngIf="showControls"></mgl-control>
</mgl-map>
