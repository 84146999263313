<div class="load-document-upload-dialog" fxLayout="column" fxLayoutGap="40px" fxFlexFill>
  <div fxLayout="column" fxLayoutAlign="space-between start">
    <span class="title">Upload Document</span>
    <div>
      <p>Upload your document and label it.</p>
    </div>
  </div>

  <!-- Document Type Selection -->
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <span class="wp-75">Document Type</span>

    <mat-form-field appearance="outline">
      <mat-label>Select a document type</mat-label>
      <mat-select name="document-type-selection" [(value)]="selectedDropdownType">
        <mat-option *ngFor="let opt of data.options.dropdownTypes" [value]="opt">
          {{ opt.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <!-- File Select -->
  <haulynx-file-drag-and-drop
    [acceptedFileExtensions]="data.options.acceptedFileExtensions"
    [isMultiUpload]="data.options.isMultiUpload"
    (filesSelected)="onFilesSelected($event)"
    *ngIf="selectedFiles.length === 0"
  ></haulynx-file-drag-and-drop>

  <!-- Selected File List -->
  <mat-list *ngIf="selectedFiles.length > 0">
    <span class="title">Selected Documents</span>
    <mat-list-item *ngFor="let file of selectedFiles; index as i">
      <div class="file-list-item" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="15px" fxFlexFill>
        <span class="wp-200">{{ file.name | textCutoff: 25 }}</span>
        <span>{{ file.type }}</span>
        <button mat-icon-button (click)="removeFile(i)">
          <mat-icon svgIcon="haulynx-trash-gray"></mat-icon>
        </button>
      </div>
    </mat-list-item>
  </mat-list>

  <!-- Buttons -->
  <div fxLayoutGap="10px" fxLayoutAlign="end">
    <button mat-stroked-button class="cancel-button" (click)="close()">Cancel</button>
    <button
      fxLayout="row"
      fxLayoutAlign="center center"
      mat-flat-button
      class="upload-button"
      (click)="upload()"
      [disabled]="selectedFiles.length === 0 || !selectedDropdownType || (isUploading$ | async)"
      data-test="upload-document"
    >
      <mat-spinner *ngIf="isUploading$ | async; else textLabel" diameter="23"></mat-spinner>
      <ng-template #textLabel>Upload Document</ng-template>
    </button>
  </div>
</div>
