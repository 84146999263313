import { ExecutionResult as GraphQLExecutionResult } from './graphql-execution-result';

export declare type FetchResult<
  TData = {
    [key: string]: any;
  },
  C = Record<string, any>,
  E = Record<string, any>
> = ExecutionResult<TData> & {
  extensions?: E;
  context?: C;
};

export interface ExecutionResult<
  TData = {
    [key: string]: any;
  }
> extends GraphQLExecutionResult {
  data?: TData | null;
}
