import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LoadFeedAnalyticsEffects } from './load-feed-analytics.effects';
import { LoadFeedEffects } from './load-feed.effects';
import { loadFeedReducer } from './load-feed.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('load-feeds', loadFeedReducer),
    EffectsModule.forFeature([LoadFeedEffects, LoadFeedAnalyticsEffects]),
  ],
})
export class LoadFeedStoreModule {}
