<span class="panel-analytics__label slightAlign">Insurance</span>
<app-spinner [active]="isLoading" [diameter]="16"></app-spinner>
<div class="panel-analytics__info" *ngIf="!isLoading">
  <app-carrier-analytics-item
    label="Auto"
    [value]="{ currency: insurance?.autoInsuranceLimit, date: insurance?.autoInsuranceExpDate }"
    type="insurance"
  ></app-carrier-analytics-item>
  <app-carrier-analytics-item
    label="Cargo"
    [value]="{ currency: insurance?.cargoInsuranceLimit, date: insurance?.cargoInsuranceExpDate }"
    type="insurance"
  ></app-carrier-analytics-item>
</div>
