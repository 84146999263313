import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  constructor(private http: HttpClient) {}

  public log(level: LOG_LEVEL, location: string, meta: object): Promise<any> {
    const log = { level, location, ...meta };
    return new Promise((resolve) => {
      this.http.post(`/api/log`, log).toPromise().then(resolve).catch(resolve);
    });
  }
}

export enum LOG_LEVEL {
  trace = 'trace',
  debug = 'debug',
  info = 'info',
  warn = 'warn',
  error = 'error',
  fatal = 'fatal',
}
