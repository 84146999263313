import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { WhiteOutlineButtonModule } from '../white-outline-button/white-outline-button.module';
import { MobileBannerComponent } from './mobile-banner.component';

@NgModule({
  declarations: [MobileBannerComponent],
  imports: [CommonModule, FlexLayoutModule, WhiteOutlineButtonModule],
  exports: [MobileBannerComponent],
})
export class MobileBannerModule {}
