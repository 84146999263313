import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { OrderInfoNotesVmService } from '@haulynx/services';
import { OrderInfoForm } from '@haulynx/types';

@Component({
  selector: 'app-order-info-notes',
  templateUrl: './order-info-notes.component.html',
  styleUrls: ['./order-info-notes.component.scss'],
})
export class OrderInfoNotesComponent implements OnChanges, OnInit {
  @Input() formData: OrderInfoForm = null;
  @Input() form: FormGroup;
  @Input() readOnly?: boolean;

  constructor(private orderInfoNotesVmService: OrderInfoNotesVmService) {}

  ngOnChanges(changes: SimpleChanges) {
    const { formData } = changes;

    if (formData && this.form) {
      this.form.patchValue(formData.currentValue || {});
    }
  }

  ngOnInit(): void {
    if (!this.form) {
      this.form = this.orderInfoNotesVmService.create();
    }
  }
}
