<div class="action-button" [ngClass]="cls">
  <a
    *ngFor="let button of buttons"
    mat-raised-button
    [color]="button?.color || 'primary'"
    [ngClass]="button?.cls"
    [disabled]="!!button?.disable || loading"
    (click)="execute(button.action, $event)"
    [attr.data-test]="button.action"
  >
    <mat-spinner
      [diameter]="18"
      *ngIf="loading"
      mode="indeterminate"
      [attr.data-test]="'action-button-spinner'"
    ></mat-spinner>
    {{ button.label }}
    <mat-icon *ngIf="button.icon" svgIcon="{{ button.icon }}"></mat-icon>
  </a>
</div>
