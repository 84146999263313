import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@haulynx/modules';
import { BlueFabComponent } from './blue-fab.component';

@NgModule({
  declarations: [BlueFabComponent],
  imports: [CommonModule, MaterialModule],
  exports: [BlueFabComponent],
})
export class BlueFabModule {}
