import { Action, ActionReducer } from '@ngrx/store';
import { includes, isArray, reduce } from 'lodash';
import { DispatchAction } from './action';

export function combineReducersFlat<S>(reducers): ActionReducer<any, Action> {
  return (state, action: DispatchAction): S => {
    const result = reduce(
      reducers,
      (state, reducer) => {
        const newState = reducer(state, action);

        return newState;
      },
      state
    );

    return result || state;
  };
}

export function createReducer(types, handler, ...additionalProps) {
  const reducer = handler(...additionalProps);

  return function (state, action) {
    const isOfType = isArray(types) ? includes(types, action.type) : types === action.type;

    return isOfType ? reducer(state, action) : state;
  };
}
