import { NgModule } from '@angular/core';
import { LoadVm } from '@haulynx/services';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LoadDetailsAnalyticsEffects } from './load-details.analytics.effects';
import { LoadDetailsEffects } from './load-details.effects';
import { loadDetailsReducer } from './load-details.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature('load-details', loadDetailsReducer),
    EffectsModule.forFeature([LoadDetailsEffects, LoadDetailsAnalyticsEffects]),
  ],
  providers: [LoadVm],
})
export class LoadDetailsStateModule {}
