import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatAnchor } from '@angular/material/button';

@Component({
  selector: 'app-action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionButtonComponent {
  @Input() label = null;
  @Input() svgIcon = null;
  @Input() icon = null;
  @Input() disabled = false;
  @Input() href = null;
  @Output() onClick = new EventEmitter<string>();

  @ViewChild(MatAnchor) element: ElementRef;

  constructor() {}

  execute(event: MouseEvent): void {
    event.stopPropagation();
  }
}
