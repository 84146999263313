import { LngLatLike } from 'mapbox-gl';

export interface MapboxRouteData {
  routes: MapboxRouteResponse[];
  waypoints: MapboxWaypoint[];
}

export interface MapboxRouteResponse {
  distance: number;
  duration: number;
  geometry: {
    coordinates: LngLatLike[];
    type: string;
  };
  coordinates: LngLatLike[];
  type: string;
  voiceLocale: string;
  weight: number;
  weight_name: string;
}

export interface MapboxWaypoint {
  distance: number;
  location: LngLatLike;
  name: string;
}
