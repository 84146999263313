<div class="search-container">
  <haulynx-carrier-form-search (search)="searchCarrier($event)" [filter]="carriersModel.query$ | async">
  </haulynx-carrier-form-search>

  <app-spinner [active]="carriersModel.isLoading$ | async"></app-spinner>

  <data-table
    [displayedColumns]="ordersColumns"
    [data]="carriersModel.entities$ | async"
    [loading]="carriersModel.isSearchLoading$ | async"
    [pagination]="carriersModel.pagination$ | async"
    [templateCellRef]="templateCell"
    (onClick)="selectCarrier($event)"
    (onPagination)="changePageCarrier($event)"
    [lazyLoad]="true"
    [clickableRow]="true"
    [cls]="'carriers-search'"
    [emptyMessage]="'No carrier found.'"
    data-test="carrier-search-table"
  >
    <ng-template #templateCell let-column="column" let-row="row">
      <div *ngIf="column === 'name'" data-test="carrier-name">
        {{ row.name }}
      </div>
      <div *ngIf="column === 'mcNumber'" data-test="carrier-mc">
        {{ row.mcNumber }}
      </div>
      <div *ngIf="column === 'sourceType'" data-test="carrier-type">
        {{ row.sourceType }}
      </div>
      <div *ngIf="column === 'owner'" data-test="carrier-owner">
        {{ row.owner | carrierOwner: 'Unknown' }}
      </div>
      <div *ngIf="column === 'dot'" data-test="carrier-dot">
        {{ row.dot }}
      </div>
      <div *ngIf="column === 'adminName'" data-test="carrier-contact">
        {{ row.adminName }}
      </div>
      <div *ngIf="column === 'phone'" class="phone" data-test="carrier-phone">
        <mat-icon aria-hidden="false">phone</mat-icon>
        <span>{{ row.phone }}</span>
      </div>
      <div *ngIf="column === 'email'" data-test="carrier-email">
        {{ row.email }}
      </div>
    </ng-template>
  </data-table>
</div>
