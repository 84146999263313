import gql from 'graphql-tag';

export const GetCarrierDetailByDot = gql(`
  query GetCarrierDetailByDot($dot: String!) {
    getCarrierDetailByDot(dot: $dot) {
      id
      dot
      phone
      name
      owner
      mcNumber
      contactName
      email
      isEldCarrier
      isHaulynxCarrier
      isRmisApprovedCarrier
      sourceType
    }
  }
`);
