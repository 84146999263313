import {
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AsyncValidatorFn,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { RangeModeService, SingleModeService } from '@haulynx/services';
import { DateForm, SelectionMode, SelectionStrategy } from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { format, toDate } from 'date-fns-tz';
import { isEqual, padStart, toNumber, toString } from 'lodash';
import { Calendar } from 'primeng/calendar';
import { debounceTime, distinctUntilChanged, first, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-datetime-picker',
  templateUrl: './datetime-picker.component.html',
  styleUrls: ['./datetime-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatetimePickerComponent),
      multi: true,
    },
    {
      provide: MatFormFieldControl,
      useExisting: DatetimePickerComponent,
    },
  ],
})
export class DatetimePickerComponent implements AfterViewInit, ControlValueAccessor, OnDestroy, OnChanges, OnInit {
  @ViewChild('calendar', { static: true }) calendar: Calendar;
  @Input() minDate: Date | string | number;
  @Input() maxDate: Date | string | number;
  @Input() selectionMode = SelectionMode.SINGLE;
  @Input() timeZone = '';
  @Input() showTimeZone = true;
  @Input() errors: ValidationErrors;

  disable = false;
  calendarControl = new FormControl();
  displayTimeZone: string;
  inputForm: FormGroup;
  showCalendar = false;
  prevTimeZone: string;
  selectionStrategy: SelectionStrategy;
  min: Date;
  max: Date;
  private timestamp: number;
  onTouched: (event: FocusEvent) => void;

  private alive = aliveWhile();

  constructor(
    private fb: FormBuilder,
    private el: ElementRef,
    private singleModeService: SingleModeService,
    private rangeModeService: RangeModeService
  ) {
    this.inputForm = this.fb.group({
      month: [],
      day: [],
      year: [],
      hours: [],
      mins: [],
      monthRange: [],
      dayRange: [],
      yearRange: [],
    });
  }

  // hide calendar when use clicks outside component
  @HostListener('document:click', ['$event']) clickOutside(event: MouseEvent): void {
    if (!this.el.nativeElement.contains(event.target)) {
      this.showCalendar = false;
    }
  }
  @HostListener('document:keydown.escape', ['$event']) onEscape(event: KeyboardEvent): void {
    this.showCalendar = false;
    event.stopPropagation();
  }
  @HostListener('document:keydown.enter', ['$event']) onEnter(event: KeyboardEvent): void {
    this.showCalendar = false;
    event.stopPropagation();
  }

  ngOnInit(): void {
    this.selectionStrategy = this.initSelectionStrategy(this.selectionMode);
    this.inputForm.setAsyncValidators(this.getAsyncValidators());
  }

  initSelectionStrategy(selectionMode: string): SelectionStrategy {
    if (selectionMode === SelectionMode.RANGE) {
      return this.rangeModeService;
    }

    return this.singleModeService;
  }

  ngAfterViewInit(): void {
    // Initialize the calendar date
    const formVal = this.inputForm.getRawValue();
    const zonedDate = this.selectionStrategy.formToZonedDate(formVal, this.timeZone);
    this.calendarControl.setValue(zonedDate);

    this.inputForm.valueChanges
      .pipe(
        takeUntil(this.alive),
        debounceTime(300), // help with blur events
        distinctUntilChanged(isEqual)
      )
      .subscribe((dateVal: DateForm) => {
        const newDate = this.selectionStrategy.formToZonedDate(dateVal, this.timeZone);

        if (this.selectionStrategy.isInvalidDate(newDate)) {
          this.onChange(null);
          this.calendarControl.setValue(null);
        } else {
          const controlValue = this.selectionStrategy.normalizeDate(newDate);

          this.onChange(controlValue);
          this.calendarControl.setValue(newDate);
        }
      });
  }

  onChange(val): void {
    return;
  }

  onDateSelect(date: Date): void {
    const selectedDate = this.selectionMode === SelectionMode.SINGLE ? date : this.calendarControl.value;
    const controlValue = this.selectionStrategy.normalizeDate(selectedDate);

    this.setTimepickerValue(controlValue);
    if (this.selectionMode === SelectionMode.SINGLE) {
      this.showCalendar = false;
    }
  }

  writeValue(value: number | number[]): void {
    if (typeof value === 'number') {
      this.timestamp = value;
    }
    this.setTimepickerValue(value);
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: (event: FocusEvent) => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.inputForm.disable();
      this.calendarControl.disable();
      this.disable = true;
    } else {
      this.inputForm.enable();
      this.calendarControl.enable();
      this.disable = false;
    }
  }

  /**
   * Pad a '0' to the form inputs
   */
  padNum(): void {
    const formVal: DateForm = this.inputForm.getRawValue();
    if (this.inputForm.valid) {
      this.inputForm.patchValue({
        month: padStart(formVal.month, 2, '0'),
        day: padStart(formVal.day, 2, '0'),
        hours: padStart(formVal.hours, 2, '0'),
        mins: padStart(formVal.mins, 2, '0'),
        monthRange: padStart(formVal.monthRange, 2, '0'),
        dayRange: padStart(formVal.dayRange, 2, '0'),
        yearRange: padStart(formVal.yearRange, 2, '0'),
      });
    }
  }

  onFocus(event: FocusEvent): void {
    event.target['select'](); // highlights all the text
    this.onTouched(event);
  }

  keypressHandler(event: KeyboardEvent, controlName: string): void {
    if (event.code === 'ArrowDown') {
      setTimeout(() => {
        (<HTMLInputElement>event.target).select();
      });
      this.incrementValue(-1, controlName);
    } else if (event.code === 'ArrowUp') {
      setTimeout(() => {
        (<HTMLInputElement>event.target).select();
      });
      this.incrementValue(1, controlName);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { timeZone, minDate, maxDate } = changes;

    if (!timeZone?.currentValue && !timeZone?.firstChange && timeZone?.previousValue) {
      this.prevTimeZone = timeZone.previousValue;
    }
    if (timeZone?.currentValue && !timeZone?.firstChange && !timeZone?.previousValue) {
      timeZone.previousValue = this.prevTimeZone;
    }

    if (timeZone && !!timeZone?.currentValue) {
      // Update the time if the timezone changes
      this.displayTimeZone = format(new Date(), 'zzz', { timeZone: this.timeZone }) as string;

      if (this.selectionStrategy) {
        const dateForm = this.selectionStrategy.normalizeTimepickerValue(
          this.timestamp,
          timeZone.currentValue
        ) as DateForm;
        const currTimestamp = this.selectionStrategy.formToZonedDate(dateForm, timeZone.currentValue);
        this.setTimepickerValue(this.selectionStrategy.normalizeDate(currTimestamp));
      }
    }

    if (minDate && !!minDate.currentValue) {
      this.min = toDate(minDate.currentValue, { timeZone: this.timeZone });
    }

    if (maxDate && !!maxDate.currentValue) {
      this.max = toDate(maxDate.currentValue, { timeZone: this.timeZone });
    }
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }

  /**
   * @param value a millisecond timestamp | timestamp array
   */
  private setTimepickerValue(value: number | number[]): void {
    if (value && this.selectionStrategy) {
      if (this.selectionStrategy.isInvalidDate(value)) {
        this.inputForm.setValue({
          month: null,
          day: null,
          year: null,
          hours: null,
          mins: null,
          monthRange: null,
          dayRange: null,
          yearRange: null,
        });
      } else {
        this.inputForm.setValue(this.selectionStrategy.normalizeTimepickerValue(value, this.timeZone));
      }
    }
  }

  private getAsyncValidators(): AsyncValidatorFn[] {
    const validators = [this.asyncDateValidator(), this.asyncDateMinMaxValidator()];

    if (this.selectionMode === SelectionMode.RANGE) {
      validators.push(this.asyncDateRangeValidator());
    }

    return validators;
  }

  private incrementValue(value: -1 | 1, controlName: string): void {
    const currVal: number = toNumber(this.inputForm.get(controlName).value);
    const newVal: string = padStart(toString(value + currVal), 2, '0');
    const newFormVal: DateForm = { ...this.inputForm.getRawValue(), [controlName]: newVal };

    if (this.selectionStrategy.isValidDate(newFormVal, this.timeZone)) {
      this.inputForm.get(controlName).setValue(newVal);
    }
  }

  private asyncDateValidator(): AsyncValidatorFn {
    return (formGroup) =>
      formGroup.valueChanges.pipe(
        debounceTime(400),
        distinctUntilChanged(isEqual),
        map((formValue: DateForm) =>
          this.inputForm.touched
            ? this.selectionStrategy.isValidDate(formValue, this.timeZone)
              ? null
              : { 'Invalid date': true }
            : null
        ),
        first()
      );
  }

  private asyncDateMinMaxValidator(): AsyncValidatorFn {
    return (formGroup) =>
      formGroup.valueChanges.pipe(
        debounceTime(400),
        distinctUntilChanged(isEqual),
        map((formValue: DateForm) =>
          this.selectionStrategy.isMinMaxValid(formValue, this.timeZone, this.min, this.max)
        ),
        first()
      );
  }

  private asyncDateRangeValidator(): AsyncValidatorFn {
    return (formGroup) =>
      formGroup.valueChanges.pipe(
        debounceTime(400),
        distinctUntilChanged(isEqual),
        map((formValue: DateForm) => this.rangeModeService.isRangeValid(formValue, this.timeZone)),
        first()
      );
  }
}
