import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-carrier-active-load-dialog',
  templateUrl: './carrier-active-load-dialog.component.html',
  styleUrls: ['./carrier-active-load-dialog.component.scss'],
})
export class CarrierActiveLoadDialogComponent {
  public loadId: string;
  public type: string;

  constructor(
    public dialogRef: MatDialogRef<CarrierActiveLoadDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      loadId: string;
      type: string;
    }
  ) {
    this.loadId = data.loadId;
    this.type = data.type;
  }
}
