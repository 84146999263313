import { Pipe, PipeTransform } from '@angular/core';
import {
  BookStatus,
  LoadLocationType,
  LoadsServiceLoad,
  LoadsServiceLoadLocation,
  LoadsServiceLoadStatus,
  MilestoneSubtype,
  MilestoneType,
} from '@haulynx/types';
import { formatDistance } from 'date-fns';

@Pipe({
  name: 'transformPickupTime',
})
export class TransformPickupTimePipe implements PipeTransform {
  constructor() {}

  transform(load: LoadsServiceLoad) {
    //transformation for dropoff, and in transit
    if (
      (load.loadStatus === LoadsServiceLoadStatus.AT_RECEIVER ||
        load.loadStatus === LoadsServiceLoadStatus.PICKED_UP) &&
      load.locations.length <= 2 &&
      load.bookStatus === BookStatus.BOOKED
    ) {
      let suffix = 'Dropoff';
      let index = 1;
      if (!load.locations[1]) {
        suffix = 'Pickup';
        index = 0;
      }
      const apptTime = !load.locations[index].appointmentEnd
        ? load.locations[index].appointmentStart
        : load.locations[index].appointmentEnd;
      const timeDifference = formatDistance(apptTime, new Date(), { addSuffix: true });

      if (timeDifference.includes('ago')) {
        return suffix + ' was ' + timeDifference;
      } else {
        return suffix + ' ' + timeDifference;
      }
    }
    //transformation for pick up, dropoff, and in transit if load has more than 2 stops
    else if (
      (load.loadStatus === LoadsServiceLoadStatus.PICKED_UP ||
        load.loadStatus === LoadsServiceLoadStatus.AT_RECEIVER ||
        load.loadStatus === LoadsServiceLoadStatus.AT_SHIPPER) &&
      load.locations.length > 2 &&
      load.bookStatus === BookStatus.BOOKED
    ) {
      let currentLocationIndex = -1;
      for (let i = 0; i < load.locations.length; i++) {
        for (let x = 0; x < load.milestones.length; x++) {
          if (
            load.milestones[x].type === MilestoneType.CHECKPOINT &&
            load.milestones[x].logs.find((log) => log.subType === MilestoneSubtype.COMPLETE)
          ) {
            if (load.milestones[x].locationId === load.locations[i].id) {
              currentLocationIndex = i;
            }
          }
        }
      }

      if (currentLocationIndex < load.locations.length - 1) {
        let suffix = '';
        const nextLocation: LoadsServiceLoadLocation = load.locations[currentLocationIndex + 1];
        if (nextLocation.locationType === LoadLocationType.PICKUP) {
          suffix = 'Pickup';
        } else if (nextLocation.locationType === LoadLocationType.DROPOFF) {
          suffix = 'Dropoff';
        } else {
          suffix = '';
        }
        const apptTime = !nextLocation.appointmentEnd ? nextLocation.appointmentStart : nextLocation.appointmentEnd;
        const timeDifference = formatDistance(apptTime, new Date(), { addSuffix: true });

        if (timeDifference.includes('ago')) {
          return suffix + ' was ' + timeDifference;
        } else {
          return suffix + ' ' + timeDifference;
        }
      }
    }
    //transformation for dispatched and awaiting truck details and pick up
    else if (
      (load.loadStatus === LoadsServiceLoadStatus.AT_SHIPPER ||
        load.loadStatus === LoadsServiceLoadStatus.ASSIGNED ||
        load.loadStatus === LoadsServiceLoadStatus.UNASSIGNED ||
        load.loadStatus === LoadsServiceLoadStatus.DISPATCHED) &&
      load.bookStatus === BookStatus.BOOKED
    ) {
      const apptTime = !load.locations[0].appointmentEnd
        ? load.locations[0].appointmentStart
        : load.locations[0].appointmentEnd;
      const timeDifference = formatDistance(apptTime, new Date(), { addSuffix: true });

      if (timeDifference.includes('ago')) {
        return 'Pickup was ' + timeDifference;
      } else {
        return 'Pickup ' + timeDifference;
      }
    } else {
      return '';
    }
  }
}
