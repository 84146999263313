import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, UrlTree } from '@angular/router';
import { CarriersModel } from '@haulynx/store';
import { Tab } from '@haulynx/types';
import { reduce } from 'lodash';
import { Observable } from 'rxjs';

@Injectable()
export class CarriersTabGuard implements CanActivate {
  constructor(private carriersModel: CarriersModel) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let viewTabs = [];
    const { tabs = null } = route.data;
    if (tabs) {
      const result = reduce(
        tabs,
        ({ tabs, activeTab }, tab, key) => {
          const newTab = new Tab(tab);

          tabs.push(newTab);

          return { tabs, activeTab };
        },
        { tabs: [], activeTab: null }
      );

      viewTabs = [...viewTabs, ...result.tabs];
    }

    if (viewTabs.length) {
      this.carriersModel.addTabs(viewTabs);
    }

    return true;
  }
}
