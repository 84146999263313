import gql from 'graphql-tag';

export const GetDeviceById = gql(`
  query getDeviceById($id: ID!) {
    getDeviceById(id: $id) {
      carrier{
        id
        name
      }
      shippedDate
      imei
      make
      model
      type
      year
      lastUpdated
      lastLocation {
        approximateAddress
        driver
      }
      shippedDate
      trackingNumber
      unitId
      returned
      returnDate
      writeOff
      writeOffReason
      cellularCarrierDeactivated
    }
  }
`);
