<div class="completed-loads-container">
  <!-- Use the router outlet to show the quick actions for whatever load is clicked
  in the active loads table -->
  <app-tabs
    [selected]="activeLoadsTabsModel.tabsSelected$ | async"
    [tabs]="activeLoadsTabsModel.tabs$ | async"
    (onSelect)="selectTab($event)"
    (onRemove)="removeTab($event)"
  ></app-tabs>
  <app-load-feed-details [loadId]="loadId"></app-load-feed-details>
  <router-outlet></router-outlet>
</div>
