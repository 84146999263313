import { TitleCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { LoadsServiceLoad, LoadsServiceLoadLocation } from '@haulynx/types';
import { numberToWords, splitAddress } from '@haulynx/utils';

@Pipe({
  name: 'additionalStops',
})
export class AdditionalStopsPipe implements PipeTransform {
  transform(load: LoadsServiceLoad): string {
    if (!load.locations || load.locations.length <= 2) return 'This load has no additional stops';

    // Remove the start and end stop to get extra number of stops
    const numberOfExtraStops = load.locations.length - 2;
    const loadLocations = load.locations
      .slice(1, -1)
      .map((location: LoadsServiceLoadLocation) => splitAddress(location.summaryAddress, 1, true));

    return `This load has ${numberToWords(numberOfExtraStops)} additional stop${
      numberOfExtraStops > 1 ? 's' : ''
    } in ${loadLocations.reduce(
      (text, value, i, array) => text + (i < array.length - 1 ? ', ' : ' and in ') + value
    )}.`;
  }
}
