import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MilestoneEntityService } from '@haulynx/store';
import { buttonTypes, LoadIdentifierType, LoadsServiceLoad, Milestone, MilestoneComment } from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: '  cursor: pointer;',
  templateUrl: './comment-dialog.component.html',
  styleUrls: ['./comment-dialog.component.scss'],
})
export class CommentDialogComponent implements OnInit {
  myForm: FormGroup;
  actionButtons = [];
  disableButtons = [];
  comment: MilestoneComment;
  isLoading$ = new BehaviorSubject<boolean>(false);

  alive = aliveWhile();

  constructor(
    public matDialogRef: MatDialogRef<CommentDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      comment: MilestoneComment;
      subType: string;
      dialogType: boolean;
      milestone: Milestone;
      loadId: string;
      load: LoadsServiceLoad;
    },
    private fb: FormBuilder,
    private milestoneEntityService: MilestoneEntityService
  ) {}

  ngOnInit() {
    this.myForm = this.fb.group({
      contact: [this.data.comment?.contact, [Validators.maxLength(20)]],
      text: [this.data.comment?.text, [Validators.required, Validators.minLength(1)]],
    });

    if (this.data.dialogType) {
      this.milestoneEntityService.createCommentManager.isLoading$.pipe(takeUntil(this.alive)).subscribe((isLoading) => {
        if (isLoading) {
          this.isLoading$.next(true);
        } else {
          this.isLoading$.next(false);
        }
      });

      this.milestoneEntityService.createCommentManager.onSuccess$.pipe(takeUntil(this.alive)).subscribe((success) => {
        if (success) {
          this.matDialogRef.close(this.comment);
        }
      });
    } else {
      this.milestoneEntityService.updateCommentManager.isLoading$.pipe(takeUntil(this.alive)).subscribe((isLoading) => {
        if (isLoading) {
          this.isLoading$.next(true);
        } else {
          this.isLoading$.next(false);
        }
      });
      this.milestoneEntityService.updateCommentManager.onSuccess$.pipe(takeUntil(this.alive)).subscribe((success) => {
        if (success) {
          this.matDialogRef.close(this.comment);
        }
      });
    }

    if (this.data.dialogType) {
      this.actionButtons = [buttonTypes.MILESTONE_CANCEL, buttonTypes.CREATE_COMMENT];
      this.disableButtons = [buttonTypes.CREATE_COMMENT];
    } else {
      this.actionButtons = [buttonTypes.MILESTONE_CANCEL, buttonTypes.EDIT_COMMENT];
      this.disableButtons = [buttonTypes.EDIT_COMMENT];
    }

    this.myForm.valueChanges.subscribe(() => {
      if (this.data.dialogType && !this.myForm.invalid) {
        this.actionButtons = [buttonTypes.MILESTONE_CANCEL, buttonTypes.CREATE_COMMENT];
        this.disableButtons = [];
      } else if (!this.data.dialogType && !this.myForm.invalid) {
        this.actionButtons = [buttonTypes.MILESTONE_CANCEL, buttonTypes.EDIT_COMMENT];
        this.disableButtons = [];
      }
    });
  }

  onContactChange(): void {
    if (this.myForm.get('contact').value.length > 20) {
      this.myForm.patchValue({
        contact: this.myForm.get('contact').value.slice(0, 20),
      });
    }
  }

  clickEvent(eventType: string) {
    if (eventType === 'cancel') {
      this.matDialogRef.close();
    } else {
      if (this.data.dialogType) {
        this.comment = {
          creatorId: this.data.comment.creatorId,
          contact: this.myForm.get('contact').value,
          timezone: this.data.comment.timezone,
          text: this.myForm.get('text').value,
          timestamp: this.data.comment.timestamp,
          creatorType: this.data.comment.creatorType,
        };
        this.milestoneEntityService.createCommentManager.dispatch(this.data.loadId, {
          commentInput: this.comment,
          loadId: this.data.loadId,
          milestoneId: this.data.milestone.id,
          loadTmw: this.data.load.providerDetails.alternateIds.find(
            (value) => value.identifierType === LoadIdentifierType.TMW_NUMBER
          )?.identifierValue,
        });
      } else {
        this.comment = {
          id: this.data.comment.id,
          creatorId: this.data.comment.creatorId,
          contact: this.myForm.get('contact').value,
          timezone: this.data.comment.timezone,
          text: this.myForm.get('text').value,
          timestamp: this.data.comment.timestamp,
          creatorType: this.data.comment.creatorType,
        };
        this.milestoneEntityService.updateCommentManager.dispatch(this.data.loadId, {
          commentInput: this.comment,
          loadId: this.data.loadId,
          milestoneId: this.data.milestone.id,
        });
      }
    }
  }
}
