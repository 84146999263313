export enum MapBoxGeoTypes {
  STATE = 'region',
  COUNTRY = 'country',
  CITY = 'place',
}

export enum MapBoxCountries {
  USA = 'USA',
  CANADA = 'Canada',
  MEXICO = 'Mexico',
}

export interface MapBoxFeatureResponse {
  attribution: string;
  features: MapBoxFeature[];
  query: string[];
  type: string;
}

export interface MapBoxFeature {
  bbox: number[];
  center: number[];
  context: MapBoxContext[];
  geometry: MapBoxGeometry;
  id: string;
  place_name: string;
  place_type: string[];
  properties: MapBoxProperties;
  relevance: number;
  text: string;
  type: string;
}

export interface MapBoxContext {
  id: string;
  short_code: string;
  text: string;
  wikidata: string;
}

export interface MapBoxGeometry {
  type: string;
  coordinates: number[];
}

export interface MapBoxProperties {
  wikidata: string;
  short_code: string;
}
