import { PlaceType } from '../../generic';
import { GeoSpacialFilterType, ISearchFilter } from '../search-filter';
import { ISearchFilterType } from '../search-filter.types';

export class StateFilter implements ISearchFilter {
  name = 'State';
  type: ISearchFilterType = ISearchFilterType.GEOSPATIAL;
  keys: GeoSpacialFilterType;
  description = 'Filter by the state';
  searchPlace: PlaceType = PlaceType.region;

  constructor(defaultValues?: GeoSpacialFilterType) {
    this.keys = new GeoSpacialFilterType({
      placeInfo: null,
      location: '',
      locationFormName: 'customerState',
      state: '',

      // for compatibility
      stateFormName: 'customerStateState',
      radius: null,
      lat: null,
      lon: null,
      radiusFormName: 'StateRadiusMiles',
      latFormName: 'stateLat',
      lonFormName: 'stateLon',
      ...defaultValues,
    });
  }

  clearValue = () => {
    this.keys.lat = null;
    this.keys.lon = null;
    this.keys.radius = null;
    this.keys.location = '';
    this.keys.state = '';
  };
}
