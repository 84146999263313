<div class="load-quick-actions" *ngIf="load">
  <div *ngIf="showContent" class="load-quick-actions__content">
    <span class="title">{{ title }}</span>
    <mat-icon class="load-quick-actions__close" (click)="close($event)"> close </mat-icon>

    <ng-content></ng-content>
  </div>

  <div *ngIf="showTimeline && showSeparator" class="load-quick-actions__separator"></div>

  <div *ngIf="showTimeline" class="load-quick-actions__timeline">
    <span class="title">Load Details</span>
    <app-load-timeline [load]="load" [user]="user" (showFacilityInfo)="onShowFacilityInfo($event)"></app-load-timeline>

    <div class="load-quick-actions__actions">
      <button mat-flat-button (click)="execute('all-info', load)">All Info</button>
    </div>
  </div>
</div>
