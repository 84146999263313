<div class="msi_comment">
  <div *ngFor="let comment of milestone.comments; let i = index">
    <div class="content">
      <div class="content__badge">
        <mat-icon svgIcon="haulynx-boxed-comment"></mat-icon>
      </div>
      <div class="content__info">
        <div class="content__info__title">
          <p>{{ comment.creatorType | titlecase }} {{ comment.creatorId }}</p>
          <p>{{ comment.timestamp | dateTime: comment.timezone }}</p>
        </div>
        <div
          class="content__info__content"
          *ngIf="comment.contact && comment.contact !== ''"
          data-test="milestone-contact"
        >
          Contact: {{ comment.contact }}
        </div>
        <div class="content__info__content" data-test="milestone-comment">
          {{ comment.text }}
        </div>
      </div>
      <div class="content__buttons">
        <mat-icon
          *ngIf="comment.creatorId === user.usxId"
          (click)="commentActions('delete-comment', i)"
          data-test="delete-comment"
          >delete_outline</mat-icon
        >
        <mat-icon
          *ngIf="comment.creatorId === user.usxId"
          (click)="commentActions('edit-comment', i)"
          svgIcon="haulynx-edit"
          data-test="edit-comment"
        ></mat-icon>
      </div>
    </div>
  </div>
</div>
