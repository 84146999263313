import { ISearchFilter, NumericalFilterType } from '../search-filter';
import { ISearchFilterType } from '../search-filter.types';

export class BidCountFilter implements ISearchFilter {
  name = 'Bid Count';
  type: ISearchFilterType = ISearchFilterType.NUMBER_RANGE;
  keys: NumericalFilterType;
  validation?: NumericalFilterType;
  description = 'This will search the bid cound on a specific load';

  constructor(defaultValues: Partial<NumericalFilterType> = {}, validationValues: Partial<NumericalFilterType> = {}) {
    this.keys = new NumericalFilterType({
      min: 0,
      max: 15,
      minFormName: 'minBidCount',
      maxFormName: 'maxBidCount',
      ...defaultValues,
    });
    this.validation = new NumericalFilterType(validationValues);
  }
}
