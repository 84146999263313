import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DataTableCalendar, LoadLocationType, LoadsServiceLoad } from '@haulynx/types';

@Component({
  selector: 'haulynx-data-table-calendar',
  templateUrl: './data-table-calendar.component.html',
  styleUrls: ['./data-table-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataTableCalendarComponent implements OnChanges {
  @ViewChild('calendarItems', { static: true }) calendarItemContainer: ElementRef;

  @Input() loads: LoadsServiceLoad[];
  @Input() selectItem: { value: number | null };
  @Output() clickCalendarDate: EventEmitter<DataTableCalendar> = new EventEmitter();

  dates: DataTableCalendar[] = [];
  days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  rangeInfo: {
    startDate: DataTableCalendar;
    endDate: DataTableCalendar;
    totalLoadCount: number;
  };
  currentScrollAmount = 0;
  lastScrollAmount = 0;
  clickedItem = -1;
  hoveredItem = -1;
  hideEmptyItems = false;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.loads || changes.selectItem) && this.loads.length > 0) {
      this.clickedItem = -1;
      if (this.hideEmptyItems) {
        this.hideEmptyItemBox();
      }
      this.dates = this.getDatesFromLoads();
      this.rangeInfo = {
        startDate: this.dates[0],
        endDate: this.dates[this.dates.length - 1],
        totalLoadCount: this.loads.length,
      };
      setTimeout(() => {
        this.cdr.detectChanges();
      });
    }
    if (changes.selectItem) {
      this.clickCalendarItem(changes.selectItem.currentValue?.value);
    }
  }

  getDatesFromLoads(): DataTableCalendar[] {
    const dates: DataTableCalendar[] = [];
    const matchDate = (date: DataTableCalendar, createdDate: Date) =>
      date.dayNumber === createdDate.getDate() &&
      date.month === createdDate.getMonth() &&
      date.year === createdDate.getFullYear();

    this.loads.forEach((load: LoadsServiceLoad) => {
      const pickupLocation = load.locations.find((location) => location.locationType === LoadLocationType.PICKUP);
      const loadTime = pickupLocation.appointmentStart ? pickupLocation.appointmentStart : load.created;
      const createdDate = new Date(new Date(loadTime).toLocaleString('en-US', { timeZone: pickupLocation.timezone }));
      const foundDate = dates.find((date: DataTableCalendar) => matchDate(date, createdDate));
      if (foundDate) {
        const indexOfFound = dates.findIndex((date: DataTableCalendar) => matchDate(date, createdDate));
        dates[indexOfFound].loadCount++;
      } else {
        dates.push(<DataTableCalendar>{
          dayNumber: createdDate.getDate(),
          month: createdDate.getMonth(),
          year: createdDate.getFullYear(),
          loadCount: 1,
          displayCalendarItem: true,
          dayText: this.days[createdDate.getDay()],
          date: loadTime.valueOf(),
        });
      }
    });

    if (!this.hideEmptyItems) {
      for (let i = 0; i < 30; i++) {
        const startDate = new Date();
        const searchDate = new Date(startDate.setDate(startDate.getDate() + i));
        const foundDate = dates.find((date: DataTableCalendar) => matchDate(date, searchDate));
        if (!foundDate) {
          dates.push({
            dayNumber: searchDate.getDate(),
            month: searchDate.getMonth(),
            year: searchDate.getFullYear(),
            loadCount: 0,
            displayCalendarItem: true,
            dayText: this.days[searchDate.getDay()],
            date: searchDate.valueOf(),
          });
        }
      }
    }

    return dates.sort((a: DataTableCalendar, b: DataTableCalendar) => {
      if (a.date > b.date) return 1;
      if (a.date < b.date) return -1;
      return 0;
    });
  }

  toggleEmptyItemCheckbox(event: MatCheckboxChange): void {
    this.hideEmptyItems = event.checked;
    this.hideEmptyItemBox();
  }

  hideEmptyItemBox(): void {
    if (this.clickedItem !== -1) this.clickCalendarDate.emit(null);
    this.clickedItem = -1;
    this.hoveredItem = -1;
    this.dates = this.getDatesFromLoads();
    this.rangeInfo = {
      startDate: this.dates[0],
      endDate: this.dates[this.dates.length - 1],
      totalLoadCount: this.loads.length,
    };
    setTimeout(() => {
      this.cdr.detectChanges();
    });
  }

  shouldShowChevrons(): boolean {
    if (this.calendarItemContainer && this.calendarItemContainer.nativeElement) {
      return (
        this.calendarItemContainer.nativeElement.scrollWidth > this.calendarItemContainer.nativeElement.clientWidth
      );
    }
  }

  moveCalendar(direction: -1 | 1): void {
    const clientWidth = this.calendarItemContainer.nativeElement.clientWidth;
    const moveAmount = Math.round(clientWidth / 100) * 100 - 200;
    if (direction === -1) {
      this.currentScrollAmount -= moveAmount;
      if (this.currentScrollAmount < 0) this.currentScrollAmount = 0;
    } else {
      this.currentScrollAmount += moveAmount;
      if (this.currentScrollAmount > this.calendarItemContainer.nativeElement.scrollWidth)
        this.currentScrollAmount = this.calendarItemContainer.nativeElement.scrollWidth;
    }
    this.smoothScroll(this.currentScrollAmount, direction);
  }

  smoothScroll(finishedScrollAmount: number, direction: -1 | 1): void {
    const velocity = 10;
    const remaining = Math.abs(finishedScrollAmount - this.calendarItemContainer.nativeElement.scrollLeft);
    let scrollAmount = remaining / velocity;
    if (remaining < scrollAmount) scrollAmount = remaining;
    if (scrollAmount > 2 && scrollAmount !== this.lastScrollAmount) {
      this.lastScrollAmount = scrollAmount;
      this.calendarItemContainer.nativeElement.scrollLeft += scrollAmount * direction;
      setTimeout(() => {
        this.smoothScroll(finishedScrollAmount, direction);
      }, 10);
    }
  }

  clickSummary(): void {
    this.clickedItem = -1;
    this.clickCalendarDate.emit(null);
  }

  clickCalendarItem(index: number): void {
    if (this.clickedItem === index || index === null || index === -1) {
      this.clickedItem = -1;
      this.clickCalendarDate.emit(null);
    } else {
      this.clickedItem = index;
      this.clickCalendarDate.emit(this.dates[index]);
    }
  }
}
