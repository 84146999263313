import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-carrier-signup-one-page-dialog',
  templateUrl: './carrier-signup-one-page-dialog.component.html',
  styleUrls: ['./carrier-signup-one-page-dialog.component.scss'],
})
export class CarrierSignupOnePageDialogComponent {
  constructor(private dialogRef: MatDialogRef<CarrierSignupOnePageDialogComponent>) {}

  signedUp(finishedSuccessfully: boolean): void {
    this.dialogRef.close(finishedSuccessfully);
  }
}
