export interface CarrierManagement {
  id: string;
  carrierName: string;
  mcNumber: string;
  volume?: CarrierVolume;
  lastContacted: LastContacted;
  contactedToday?: boolean;
}

export interface LastContacted {
  timestamp?: number;
  person?: string;
}

export interface CarrierVolume {
  mtd?: number;
  priorMonth?: number;
  ytd?: number;
}
