import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CommonEntitiesEffects } from '../../shared/common-entities/common-entities.effects';
import { searchViewStateProviders, SEARCH_VIEW_ENTITY_REDUCERS_TOKEN } from './config';
import { searchViewEntityNamespace } from './namespace';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(searchViewEntityNamespace, SEARCH_VIEW_ENTITY_REDUCERS_TOKEN),
    EffectsModule.forFeature([CommonEntitiesEffects]),
  ],
  providers: [...searchViewStateProviders],
})
export class AsyncEntitySearchViewStoreModule {}
