import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AppModel, LoadEntityService, UserEntityService } from '@haulynx/store';
import { BookStatus, FeatureFlag, FFState, LoadsServiceLoad, User } from '@haulynx/types';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoadGuard implements CanActivate {
  constructor(
    private appModel: AppModel,
    private router: Router,
    private loadEntityService: LoadEntityService,
    private userEntityService: UserEntityService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.appModel.user$.pipe(
      switchMap((user: User) => {
        if (!user) {
          return of(true);
        } else if (user.broker) {
          const loadId: string = route.paramMap.get('id');

          this.loadEntityService.getLoadByIdManager.dispatch(loadId);
          return combineLatest([
            this.userEntityService.featureFlags$,
            this.loadEntityService.getLoadByIdManager.getEntityById(loadId).pipe(filter((load) => load !== null)),
          ]).pipe(
            map(([features, load]: [FFState, LoadsServiceLoad]) => {
              const loadOverviewFeatureFlag = features[FeatureFlag.LOAD_OVERVIEW];
              const includesOverview = state.url.includes('overview');
              const bookedLoad = load.bookStatus === BookStatus.BOOKED;

              if (!loadOverviewFeatureFlag && !includesOverview) {
                if (bookedLoad) {
                  this.router.navigate([`/loads/active/${loadId}`], { queryParamsHandling: 'preserve' });
                } else {
                  this.router.navigate([`dashboard/load-feeds/my/${loadId}`], {
                    queryParamsHandling: 'preserve',
                  });
                }

                return false;
              } else if (loadOverviewFeatureFlag && !includesOverview) {
                this.router.navigate([`loads/${loadId}/overview`], { queryParamsHandling: 'preserve' });
                return false;
              }

              return true;
            })
          );
        }

        return of(true);
      })
    );
  }
}
