import { format, formatDistance, isToday, isYesterday } from 'date-fns';
import { LoadsServiceLoad } from '../../loads-service';
import { Load } from '../load';
import { NotificationEvent, SecondaryNotificationEvent } from './notification-event';

export class WebNotification {
  id: string = null;
  notifications: NotificationEvent[] = [];
  active = false;
  createdBy: string = null;
  createdAt: number = null;
  eventType: string = null;
  recipient: string = null;
  targetCarrierDisplay: string;
  secondaryEventType: SecondaryNotificationEvent;
  secondaryTargetObjectId: string;
  secondaryTargetObjectType: string;
  targetObjectId: string;
  targetUserDisplay: string;
  targetUserId: string = null;
  targetTruckDisplay: string;
  loadsServiceLoad: LoadsServiceLoad;

  size = 0;
  title: string = null;
  dateCreated: string = null;

  constructor(eventType: string, notifications: NotificationEvent[]) {
    this.id = notifications.map((notification) => notification.id).join('');
    this.eventType = eventType;
    this.createdAt = notifications[0] && notifications[0].createdAt;
    this.createdBy = notifications[0] && notifications[0].createdBy;
    this.targetCarrierDisplay = notifications[0] && notifications[0].targetCarrierDisplay;
    this.targetObjectId = notifications[0] && notifications[0].targetObjectId;
    this.targetUserDisplay = notifications[0] && notifications[0].targetUserDisplay;
    this.targetUserId = notifications[0] && notifications[0].targetUserId;
    this.recipient = notifications[0] && notifications[0].recipient;
    this.secondaryEventType = notifications[0] && notifications[0].secondaryEventType;
    this.secondaryTargetObjectId = notifications[0] && notifications[0].secondaryTargetObjectId;
    this.secondaryTargetObjectType = notifications[0] && notifications[0].secondaryTargetObjectType;
    this.loadsServiceLoad = notifications[0] && notifications[0].loadsServiceLoad;

    this.notifications = notifications;
    this.size = notifications.length;

    const dateCreated = notifications[0].createdAt;
    this.title = isToday(dateCreated)
      ? 'Today'
      : isYesterday(dateCreated)
      ? 'Yesterday'
      : `${formatDistance(dateCreated, new Date())} ago`;

    this.dateCreated = isToday(dateCreated)
      ? `${formatDistance(dateCreated, new Date())} ago`
      : format(dateCreated, `HH:mm  LL/dd/yy`);

    this.active = notifications.every((notification) => notification.active);
  }
}
