import { User } from '@haulynx/types';
import { Record } from 'immutable';
import { combineAdaptersInitialState } from '../../shared/store/helpers/adapter';
import { ISearchState } from '../../shared/store/search/state';
import { userAdapter } from './adapters';

export enum UserFormHttpStatus {
  BLACK = 0,
  SUCCESS = 1,
  FAILED = 2,
  GET_SUCCESS,
  GET_FAILED,
}

export interface IUsersState {
  isLoadingUserForm: boolean;
  userFormHttpStatus: UserFormHttpStatus;
  search: ISearchState;
  isLoadingLicenseValidation: boolean;
  isValidDriversLicense: boolean;
  selectedUser: User;
}

const initial = {
  isLoadingUserForm: false,
  userFormHttpStatus: UserFormHttpStatus.BLACK,
  isLoadingLicenseValidation: false,
  isValidDriversLicense: false,
  selectedUser: null,
  ...combineAdaptersInitialState(userAdapter),
};

export class UserState extends Record(initial) implements IUsersState {
  isLoadingUserForm: boolean;
  userFormHttpStatus: UserFormHttpStatus;
  search: ISearchState;
  isLoadingLicenseValidation: boolean;
  isValidDriversLicense: boolean;
  selectedUser: User;
}
