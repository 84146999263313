import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  ActiveLoadDetailsPaneModule,
  AddDriverModule,
  AddTrailerModule,
  AddTruckModule,
  LoadDispatchFormModule,
  LoadQuickDriverFormModule,
  LoadQuickLocationFormModule,
  LoadTimelineStatusModule,
  MapsModule,
  SpinnerModule,
} from '@haulynx/components';
import { ElementsModule } from '@haulynx/elements';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import {
  AssignLoadCarrierFormVm,
  OrderInfoNotesVmService,
  OrderInfoVmService,
  PaymentDetailsVmService,
} from '@haulynx/services';
import {
  CarrierDetailsStoreModule,
  LoadDetailsStateModule,
  LoadFeedStoreModule,
  PaymentTypeStoreModule,
} from '@haulynx/store';
import { ActiveLoadDetailsContainerRoutingModule } from './active-load-details-container-routing.module';
import { ActiveLoadDetailsContainerComponent } from './active-load-details-container.component';

@NgModule({
  declarations: [ActiveLoadDetailsContainerComponent],
  exports: [ActiveLoadDetailsContainerComponent],
  imports: [
    CommonModule,
    ActiveLoadDetailsContainerRoutingModule,
    LoadTimelineStatusModule,
    PaymentTypeStoreModule,
    LoadFeedStoreModule,
    MapsModule,
    ActiveLoadDetailsPaneModule,
    LoadQuickDriverFormModule,
    LoadDispatchFormModule,
    LoadQuickLocationFormModule,
    PipesModule,
    FormsModule,
    LoadDetailsStateModule,
    SpinnerModule,
    AddDriverModule,
    AddTruckModule,
    AddTrailerModule,
    MaterialModule,
    ElementsModule,
    CarrierDetailsStoreModule,
    PaymentTypeStoreModule,
    LoadFeedStoreModule,
  ],
  providers: [AssignLoadCarrierFormVm, OrderInfoVmService, OrderInfoNotesVmService, PaymentDetailsVmService],
})
export class ActiveLoadDetailsContainerModule {}
