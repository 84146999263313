import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getPriceText',
})
export class GetPriceTextPipe implements PipeTransform {
  transform(regionalManagerPhone: string, biddingEnabled?: boolean): string {
    if (biddingEnabled) {
      return `This load hasn't been priced yet, please enter a bid if you're interested.`;
    } else {
      return `This load hasn't been priced yet, feel free to call us at ${
        regionalManagerPhone || '151515151'
      } if you're interested.`;
    }
  }
}
