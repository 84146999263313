import gql from 'graphql-tag';

export const SearchTrucksQuery = gql(`
  query SearchTrucksByCarrierDot($dot: String!, $paging: PageAndSort) {
    getTrucksByCarrierDot(dot: $dot, paging: $paging) {
      total
      result {
        id
        brokerId
        locationLat
        locationLon
        location
        timeAvailable
        equipmentType
        preferredLocationLat
        preferredLocationLon
        preferredLocation
        zone
        timestampTruckPosted
        timeZone
        preferredRadius
        radius
      }
    }
  }
`);
