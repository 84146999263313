import { createAction, createActionTypes } from '../../shared/store/helpers/action';
import { combineAdaptersActionsState, combineAdaptersActionTypeState } from '../../shared/store/helpers/adapter';
import { ISearchActions, ISearchTypes } from '../../shared/store/search/actions';
import { fleetAdaptor, TYPE } from './adaptors';

export interface IFleetActions extends ISearchActions {
  createFleetTrailer(trailer?);
  createFleetTrailerSuccess(params?);
  createFleetTrailerError(err?);
  updateFleetTrailer(trailer?);
  updateFleetTrailerSuccess(params?);
  updateFleetTrailerError(err?);
}

export interface IFleetActionType extends ISearchTypes {
  CREATE_FLEET_TRAILER: string;
  CREATE_FLEET_TRAILER_SUCCESS: string;
  CREATE_FLEET_TRAILER_ERROR: string;
  UPDATE_FLEET_TRAILER: string;
  UPDATE_FLEET_TRAILER_SUCCESS: string;
  UPDATE_FLEET_TRAILER_ERROR: string;
}

export const fleetActionType = {
  CREATE_FLEET_TRAILER: 'create',
  CREATE_FLEET_TRAILER_SUCCESS: 'create success',
  CREATE_FLEET_TRAILER_ERROR: 'create error',
  UPDATE_FLEET_TRAILER: 'update',
  UPDATE_FLEET_TRAILER_SUCCESS: 'update success',
  UPDATE_FLEET_TRAILER_ERROR: 'update error',
};

export const fleetActions = {
  createFleetTrailer: createAction(TYPE, fleetActionType.CREATE_FLEET_TRAILER),
  createFleetTrailerSuccess: createAction(TYPE, fleetActionType.CREATE_FLEET_TRAILER_SUCCESS),
  createFleetTrailerError: createAction(TYPE, fleetActionType.CREATE_FLEET_TRAILER_ERROR),
  updateFleetTrailer: createAction(TYPE, fleetActionType.UPDATE_FLEET_TRAILER),
  updateFleetTrailerSuccess: createAction(TYPE, fleetActionType.UPDATE_FLEET_TRAILER_SUCCESS),
  updateFleetTrailerError: createAction(TYPE, fleetActionType.UPDATE_FLEET_TRAILER_ERROR),
};

export const FleetActions: IFleetActions = {
  ...combineAdaptersActionsState(fleetAdaptor),
  ...fleetActions,
};

export const FleetActionsType: IFleetActionType = {
  ...combineAdaptersActionTypeState(fleetAdaptor),
  ...createActionTypes(fleetActionType)(TYPE),
};
