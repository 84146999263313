import { Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

@Directive({
  selector: '[scrollToFocus]',
})
export class ScrollToFocusDirective implements OnDestroy {
  @Input() scrollX: boolean = true;
  private subscription: Subscription = new Subscription();

  constructor(private element: ElementRef) {
    this.subscription.add(
      fromEvent(this.element.nativeElement, 'click').subscribe((clickedContainer: MouseEvent) => {
        (clickedContainer.target as HTMLElement).scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest',
        });
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
