<div class="bid-history">
  <h4 class="bid-history__title">Bid History</h4>

  <div class="bids">
    <div class="bids__details">
      <div class="bids__info">
        <div class="bids__info__row" *ngIf="bid?.carrier?.name">
          <div class="bids__info__column bids__info__column--muted">Carrier</div>
          <div class="bids__info__column">
            {{ bid?.carrier?.name }}
          </div>
        </div>
        <div class="bids__info__row" *ngIf="bid?.createdBy">
          <div class="bids__info__column bids__info__column--muted">Created By</div>
          <div class="bids__info__column">
            {{ bid?.createdBy?.name }}
          </div>
        </div>
        <div class="bids__info__row" *ngIf="bid?.createdAt">
          <div class="bids__info__column bids__info__column--muted">Date Created</div>
          <div class="bids__info__column">
            {{ bid.createdAt | mtz: 'MM/DD/YY HH:mm':userTimeZone:false:true }}
          </div>
        </div>
        <div class="bids__info__row" *ngIf="bid?.updatedBy">
          <div class="bids__info__column bids__info__column--muted">Last Edited</div>
          <div class="bids__info__column">
            {{ bid.updatedBy?.name }}
          </div>
        </div>
        <div class="bids__info__row" *ngIf="bid?.updatedAt">
          <div class="bids__info__column bids__info__column--muted">Date Edited</div>
          <div class="bids__info__column">
            {{ bid.updatedAt | mtz: 'MM/DD/YY HH:mm':userTimeZone:false:true }}
          </div>
        </div>
      </div>
      <div class="bids__notes">
        <div class="bids__info__row" *ngIf="load | loadAlternateId: loadIdentifierType.TMW_NUMBER:null">
          <div class="bids__info__column bids__info__column--muted">Order #</div>
          <div class="bids__info__column">
            {{ load.tmwNumber }}
          </div>
        </div>
        <div class="bids__info__row" *ngIf="load?.locations?.length">
          <div class="bids__info__column bids__info__column--muted">Load</div>
          <div class="bids__info__column bids__info__column--route">
            {{ load.locations | nth: 0 | get: 'name':'N/A' | address: 1 }}
            &rarr;
            {{ load.locations | nth: -1 | get: 'name':'N/A' | address: 1 }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bid-history__log">
    <app-spinner *ngIf="isLoading; else historyLog" [active]="true" class="bids__spinner"></app-spinner>

    <ng-template #historyLog>
      <div class="bid-history__log__item" *ngFor="let item of history; let i = index; let first = first">
        <div class="bid-history__log__index bids__info__column--muted" [ngClass]="{ padding: first }">{{ i + 1 }}</div>
        <div class="bid-history__log__data" [ngClass]="{ padding: first }" data-test="bid-history-log">{{ item | fromSnakeCase | titlecase }}</div>
      </div>
    </ng-template>
  </div>

  <div class="bid-history__actions">
    <button
      *ngIf="historyToCopy"
      class="hlx-button-command hlx-button-command--cancel hlx-button-command--border-white bid-history__button"
      ngxClipboard
      [cbContent]="historyToCopy"
      [disabled]="historyCopied$ | async"
      (cbOnSuccess)="onCopySuccess()"
    >
      Copy to Clipboard
    </button>

    <button class="hlx-button-command hlx-button-command--book bid-history__button" (click)="onDone()" data-test="done-button">Done</button>
  </div>
</div>
