import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@haulynx/modules';
import { OrderInfoVmService } from '@haulynx/services';
import { DropDownModule } from '../../drop-down/drop-down.module';
import { OrderInfoTableComponent } from './order-info-table.component';

@NgModule({
  declarations: [OrderInfoTableComponent],
  exports: [OrderInfoTableComponent],
  providers: [OrderInfoVmService],
  imports: [ReactiveFormsModule, CommonModule, DropDownModule, MaterialModule],
})
export class OrderInfoTableModule {}
