import { LoadsServiceLoad } from '../../loads-service';
import { Carrier } from '../carrier';
import { GeohashNeighbor } from '../generic';
import { Load } from '../load';
import { CapacityObjectLocation, CostObject } from './lane';

export interface CapacityObjectInput {
  userId: string;
  type: LaneType;
  carrierDot: string;
  brokerId: string;
  active: boolean;
  origin: CapacityObjectInputLocation;
  destination: CapacityObjectInputLocation;
  equipmentType: string;
  cost: CostObjectInput;
  minimumStopCharge: string;
  rpm: CostObjectInput;
  count: string;
  expireTimestamp: string;
  allowStops: boolean;
  hazmat: boolean;
  showLoadNotifications: boolean;
}

export interface CostObjectInput {
  min: string;
  max: string;
}

export class CapacityObjectInputLocation {
  point: LocationPointInput;
  radius: string;
  state: States;
  region: LaneRegion;
  availability: AvailabilityInput;
  locationName: string;

  constructor(props?: Partial<CapacityObjectLocation>) {
    Object.assign(this, props);
  }
}

export interface LocationPointInput {
  lat: string;
  lon: string;
}

export interface AvailabilityInput {
  dayOfWeek: DayOfWeek[];
  timestamp: string;
  date: string;
}

export interface PreferredLanes {
  lanes: CapacityObject[];
  preferredLoadRecommends: LoadsServiceLoad[];
}
export interface CapacityObject {
  id: string;
  userId: string;
  type: LaneType;
  carrierDot: string;
  active: boolean;
  origin: CapacityObjectLocation;
  destination: CapacityObjectLocation;
  equipmentType: string;
  cost: CostObject;
  minimumStopCharge: string;
  rpm: CostObject;
  count: string;
  expireTimestamp: string;
  allowStops: boolean;
  hazmat: boolean;
  lastUpdatedAt: string;
  showLoadNotifications: boolean;
  recommendations: LaneRecommendationResult;
  brokerId?: string;
  truckPostedCreatedAt: number;
}

export enum LaneType {
  BROKER = 'broker',
  CARRIER = 'carrier',
  LOADBOOKING = 'loadBooking',
}

export interface LaneRecommendationResult {
  total: number;
  result: LaneRecommendation[];
}

export interface LaneRecommendation {
  id: string;
  carrierId: string;
  carrierDot: string;
  carrier: Carrier;
  laneId: string;
  lane: CapacityObject;
  loadId: string;
  load: Load;
  loadsServiceLoad: LoadsServiceLoad;
  orderNumber: string;
  tmwNumber: string;
  type: string;
  loadCreated: number;
  status: string;
  loadFeedKey: string;
  loadPickupAddress: string;
  loadPickupTimestamp: number;
  loadDropoffAddress: string;
  loadDropoffTimestamp: number;
  loadEquipmentType: string;
  loadWeight: number | string;
  loadDistance: number | string;
  loadPickupLat: number | string;
  loadPickupLon: number | string;
  loadDropoffLat: number | string;
  loadDropoffLon: number | string;
  price: number | string;
  revenue: number;
  brokerName: string;
  loadDropoffTimezone: string;
  loadPickupTimezone: string;
  loadPickupGeohash: Geohash;
  loadDropoffGeohash: Geohash;
  recommendationCreatedTimestamp: string;
}

export interface Geohash {
  lat: number;
  lon: number;
  precision: number;
  hash: string;
  neighbor: GeohashNeighbor;
  approximateAddress: string;
}

export interface LocationPoint {
  lat: string;
  lon: string;
}

export enum States {
  AL = 'AL',
  AK = 'AK',
  AZ = 'AZ',
  AR = 'AR',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DE = 'DE',
  FL = 'FL',
  GA = 'GA',
  HI = 'HI',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  IA = 'IA',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  ME = 'ME',
  MD = 'MD',
  MA = 'MA',
  MI = 'MI',
  MN = 'MN',
  MO = 'MO',
  MS = 'MS',
  MT = 'MT',
  NE = 'NE',
  NV = 'NV',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NY = 'NY',
  NC = 'NC',
  ND = 'ND',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PA = 'PA',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VT = 'VT',
  VA = 'VA',
  WA = 'WA',
  WV = 'WV',
  WI = 'WI',
  WY = 'WY',
  AS = 'AS',
  DC = 'DC',
  FM = 'FM',
  GU = 'GU',
  MH = 'MH',
  MP = 'MP',
  PW = 'PW',
  PR = 'PR',
  VI = 'VI',
  AB = 'AB',
  BC = 'BC',
  MB = 'MB',
  NB = 'NB',
  NF = 'NF',
  NS = 'NS',
  NT = 'NT',
  ON = 'ON',
  PE = 'PE',
  QC = 'QC',
  SK = 'SK',
  YT = 'YT',
  AG = 'AG',
  BN = 'BN',
  BS = 'BS',
  CH = 'CH',
  CI = 'CI',
  CL = 'CL',
  CP = 'CP',
  CS = 'CS',
  DF = 'DF',
  DG = 'DG',
  GE = 'GE',
  GJ = 'GJ',
  HD = 'HD',
  JA = 'JA',
  MC = 'MC',
  MR = 'MR',
  MX = 'MX',
  NA = 'NA',
  NL = 'NL',
  OA = 'OA',
  PU = 'PU',
  QE = 'QE',
  QI = 'QI',
  SL = 'SL',
  SI = 'SI',
  SO = 'SO',
  TA = 'TA',
  TB = 'TB',
  TL = 'TL',
  VC = 'VC',
  YU = 'YU',
  ZA = 'ZA',
}

export enum DayOfWeek {
  MONDAY = 'Monday',
  TUESDAY = 'Tuesday',
  WEDNESDAY = 'Wednesday',
  THURSDAY = 'Thursday',
  FRIDAY = 'Friday',
  SATURDAY = 'Saturday',
  SUNDAY = 'Sunday',
}

export interface WeekDays {
  label: string;
  key: DayOfWeek;
  id: number;
  selected: boolean;
}

export enum LaneRegion {
  NE = 'NE',
  NW = 'NW',
  SW = 'SW',
  SC = 'SC',
  S = 'S',
  MW = 'MW',
}
