<form class="bids" [formGroup]="bidForm">
  <div [ngClass]="{ 'counter-offer': isActive }">
    <mat-expansion-panel [expanded]="isActive" (opened)="onGetHistory(bid)">
      <mat-expansion-panel-header [collapsedHeight]="'170px'" [expandedHeight]="'170px'">
        <mat-panel-description class="bids__arrow--icon">
          <img src="icons/other/haulynx-bid-arrow-expanded.svg" class="bids__arrow" data-test="bid-arrow" />
        </mat-panel-description>

        <mat-panel-description>
          <div class="width-100">
            <div class="panel-header-top-row">
              <div class="bids__title" data-test="carrier-bid">
                {{ bid?.carrier?.name }}
                <br />{{ bid?.updatedAt | timeDiff: (now$ | async):'estimate' }}
              </div>

              <div class="bids__price">
                <div class="bids__price_strikethrough" *ngIf="showCounterText">{{ previousPrice | currency }}</div>
                <div class="bids__price_input" [ngStyle]="{ color: showCounterText ? 'var(' + textColor + ')' : 'white' }">
                  {{ (showCounterText ? 'Countered ' : '') + (bidForm.get('price')?.value | currency) }}
                </div>
              </div>
              <div class="bids__description" [ngStyle]="{ color: 'var(' + textColor + ')' }">
                {{ bidForm.get('offerStateDescription')?.value }}
              </div>

              <div class="actions">
                <div class="bids__counter">
                  <button
                    (click)="onOpenCounterOffer($event, bid)"
                    data-test="counter-offer-button"
                    class="hlx-button-command hlx-button-command--cancel hlx-button-command--border-white counter-offer__info__button"
                    [disabled]="disabled || bidForm.disabled || !(load | dedicatedAllowed: features) || !enableCounter"
                  >
                    Counter
                  </button>
                </div>
                <div class="bids__action" (click)="$event.stopImmediatePropagation()">
                  <button
                    data-test="accept-bid-button"
                    type="submit"
                    mat-button
                    class="bids__button"
                    [ngClass]="{ bids__button__award: bid?.activeCounterOffer }"
                    [disabled]="disabled || bidForm.disabled || !(load | dedicatedAllowed: features) || !enableAward"
                    (click)="onAcceptBid(bid)"
                  >
                    <mat-spinner *ngIf="isLoadingAccept; else textLabel" class="bids__button__spinner" diameter="23">
                    </mat-spinner>
                    <ng-template #textLabel>
                      {{ user?.broker ? 'Award' : 'Accept' }}
                    </ng-template>
                  </button>
                </div>

                <div>
                  <app-button-dropdown
                    (click)="$event.stopPropagation()"
                    [closedIcon]="'more_vert'"
                    [openIcon]="'menu'"
                    [showButtonLabel]="false"
                    [showDropdownFromLeft]="false"
                    [isMultiSelect]="false"
                    [dropdownList]="actionMenu"
                    [buttonBackgroundColor]="'transparent'"
                    (clickedDropdownItem)="clickEvent($event)"
                  >
                  </app-button-dropdown>
                </div>
              </div>
            </div>
          </div>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="bids__details">
        <div class="bids__info">
          <div class="expanded-details-column__left">
            <ng-container *ngIf="bid.sourceType === 'broker'; else carrierBidInfo">
              <div class="bids__info__row">
                <div class="bids__info__column bids__info__column--muted">Created By</div>
                <div class="bids__info__column">
                  {{ bid?.createdBy?.name }}
                </div>
              </div>
              <div class="bids__info__row">
                <div class="bids__info__column bids__info__column--muted">Date Created</div>
                <div class="bids__info__column">
                  {{ bid.createdAt | mtz: 'MM/DD/YY HH:mm':userTimeZone:false:true }}
                </div>
              </div>
              <div class="bids__info__row">
                <div class="bids__info__column bids__info__column--muted">Last Edited</div>
                <div class="bids__info__column">
                  {{ bid?.updatedBy?.name }}
                </div>
              </div>
              <div class="bids__info__row">
                <div class="bids__info__column bids__info__column--muted">Date Edited</div>
                <div class="bids__info__column">
                  {{ bid.updatedAt | mtz: 'MM/DD/YY HH:mm':userTimeZone:false:true }}
                </div>
              </div>
            </ng-container>

            <ng-template #carrierBidInfo>
              <div class="bids__info__row">
                <div class="bids__info__column bids__info__column--muted">Created By</div>
                <div class="bids__info__column">
                  {{ bid?.carrier?.name }}
                </div>
              </div>
              <div class="bids__info__row">
                <div class="bids__info__column bids__info__column--muted">Date Submitted</div>
                <div class="bids__info__column">
                  {{ bid.createdAt | mtz: 'MM/DD/YY HH:mm':userTimeZone:false:true }}
                </div>
              </div>
              <div class="bids__info__row">
                <div class="bids__info__column bids__info__column--muted">Buy-it-now Price</div>
                <div class="bids__info__column">
                  {{ load.price | showCurrency }}
                </div>
              </div>
            </ng-template>

            <div class="bids__info__row">
              <div class="bids__info__column bids__info__column--muted">Bid History</div>
              <div class="bids__info__column">
                <span class="bids__info__column--history">
                  <mat-spinner *ngIf="isLoadingHistory; else openHistory" diameter="16" class="bids__spinner--small">
                  </mat-spinner>
                  <ng-template #openHistory>
                    <span (click)="onOpenHistory(bid)" data-test="open-history"> Open History ({{ bidHistory?.length }}) </span>
                  </ng-template>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="expanded-details-column__right">
          <div class="bids__notes" *ngIf="bid.sourceType === 'broker' ? user?.broker : !user?.broker; else carrierNote">
            <br />
            Notes
            <br />
            <mat-form-field appearance="none">
              <textarea
                matInput
                class="bids__notes__textarea"
                formControlName="notes"
                (change)="onDataChange(bidForm.getRawValue(), 'notes')"
              ></textarea>
            </mat-form-field>
          </div>

          <ng-template #carrierNote>
            <div class="bids__notes counter-offer__notes">
              Last Message from Carrier
              <mat-spinner *ngIf="isLoadingHistory; else messageHistory" diameter="16" class="bids__spinner--small">
              </mat-spinner>

              <ng-template #messageHistory>
                <!-- (1 of {{ bidHistory | totalMessages: bid?.carrier?.name }}): -->
                <div class="counter-offer__notes__message">
                  {{ bid.notes }}
                </div>
                <div class="counter-offer__notes__history" *ngIf="(bidHistory | lastCarrierMessage)?.length >= 130">
                  ← [ Full message in bid history ]
                </div>
              </ng-template>
            </div>
          </ng-template>
        </div>
      </div>
    </mat-expansion-panel>
  </div>
</form>
