import { LoadsServiceLoadStatus } from '../../loads-service/loads-service-load';

export function transformLoadStatusText(status: LoadsServiceLoadStatus): string {
  switch (status) {
    case LoadsServiceLoadStatus.UNASSIGNED:
      return 'Awaiting Truck Details';
    case LoadsServiceLoadStatus.ASSIGNED:
      return 'Ready for Dispatch';
    case LoadsServiceLoadStatus.AT_SHIPPER:
      return 'At Pickup';
    case LoadsServiceLoadStatus.PICKED_UP:
      return 'In Transit';
    case LoadsServiceLoadStatus.AT_RECEIVER:
      return 'At Dropoff';
    default:
      return status;
  }
}
