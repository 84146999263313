import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@haulynx/modules';
import { NgxCurrencyModule } from 'ngx-currency';
import { DayPickerModule } from '../day-picker/day-picker.module';
import { DropDownModule } from '../drop-down/drop-down.module';
import { GoogleAddressFieldModule } from '../google-address-field/google-address-field.module';
import { PreferredLanesFormComponent } from './preferred-lanes-form.component';

@NgModule({
  declarations: [PreferredLanesFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    GoogleAddressFieldModule,
    DropDownModule,
    MaterialModule,
    NgxCurrencyModule,
    DayPickerModule,
  ],
  exports: [PreferredLanesFormComponent],
  providers: [],
})
export class PreferredLanesFormModule {}
