import { Component, Input, OnInit } from '@angular/core';
import { CapacityObject } from '@haulynx/types';

@Component({
  selector: 'haulynx-preferred-lanes-timeline',
  templateUrl: './preferred-lanes-timeline.component.html',
  styleUrls: ['./preferred-lanes-timeline.component.scss'],
})
export class PreferredLanesTimelineComponent implements OnInit {
  @Input() lane: CapacityObject;

  constructor() {}

  ngOnInit(): void {}
}
