/**
 * Describes how a load was booked.
 */
export enum BookingType {
  /**
   * Load was booked by a non-ELD Carrier through Haulynx
   */
  APP = 'APP',
  /**
   * Load was booked by a broker on behalf of the carrier
   */
  BROKER_BOOK = 'BROKER_BOOK',
  /**
   * Load was booked by a carrier after it was offered to them by a broker
   */
  BROKER_PUSH = 'BRKPUSH',
  /**
   * Load was booked on platform Web by a Carrier
   */
  APP_WEB = 'APP_WEB',
  /**
   * Load was booked on platform Mobile by a Carrier
   */
  APP_MOBILE = 'APP_MOBILE',
  /**
   * Load was booked as contract load
   */
  AUTOBOOK = 'AUTOBOOK',
  /**
   * Load was booked by traditional Brokers
   */
  ANALOG = 'ANALOG',
}
