import { LoadsServiceOrderByType, LoadsServiceOrderByTypeDirection } from '../../loads-service/loads-service-provider';

export interface PageAndSort {
  page: number;
  /**
   * The max number of items to include in a page
   */
  limit: number;
  sort?: 'ASC' | 'DESC' | 'asc' | 'desc' | LoadsServiceOrderByTypeDirection;
  order?: string;
  secondaryOrder?: LoadsServiceSecondaryOrder;
  // The next fields are usually returned from the server
  total?: number;
  totalPages?: number;
  nextPage?: number;
  previousPage?: number;
}

export interface LoadsServiceSecondaryOrder {
  sort?: 'ASC' | 'DESC' | 'asc' | 'desc' | LoadsServiceOrderByTypeDirection;
  order: LoadsServiceOrderByType;
}
