import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RmisRegistration } from '@haulynx/types';
import { rmisAccountStatusComplete } from '@haulynx/utils';

@Component({
  selector: 'haulynx-setup-rmis-dialog',
  templateUrl: './setup-rmis-dialog.component.html',
  styleUrls: ['./setup-rmis-dialog.component.scss'],
})
export class SetupRmisDialogComponent implements OnInit {
  public complete = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { registration: RmisRegistration },
    private dialogRef: MatDialogRef<SetupRmisDialogComponent>
  ) {}

  ngOnInit() {
    this.checkPercentComplete(this.data.registration);
  }

  public close() {
    this.dialogRef.close();
  }

  public completeSetup() {
    this.dialogRef.close({ completeSetup: true });
  }

  public continue() {
    this.dialogRef.close({ redirectToRmis: true });
  }

  private checkPercentComplete(registration: RmisRegistration) {
    if (!registration) {
      this.complete = false;
      return;
    }

    this.complete = rmisAccountStatusComplete(registration) === 100 ? true : false;
  }
}
