import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CarrierKpiMetrics } from '@haulynx/types';

@Component({
  selector: 'app-carrier-analytics-margins',
  templateUrl: './carrier-analytics-margins.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarrierAnalyticsMarginsComponent {
  @Input() kpiMetrics: CarrierKpiMetrics | any = null;
}
