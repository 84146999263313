<!-- sub sidenavs -->
<mat-sidenav #sidenavsubAccount>
  <haulynx-sidebar-nav-sub-account
    [sidenav]="sidenavsubAccount"
    [user]="user"
    [headerItems]="headerItems"
    (selectedSideItem)="selectedItem($event)"
    (logout)="logout()"
    (subNavToggle)="subNavToggle($event)"
  >
  </haulynx-sidebar-nav-sub-account>
</mat-sidenav>

<mat-sidenav #sidenavsubAccountSetup>
  <haulynx-sidebar-nav-sub-account-setup
    [sidenav]="sidenavsubAccountSetup"
    [rmisProps]="rmisProps"
    (subNavToggle)="subNavToggle($event)"
    (complete)="clickCompleted()"
    (continueRmis)="clickContinueRmis()"
  >
  </haulynx-sidebar-nav-sub-account-setup>
</mat-sidenav>

<mat-sidenav #sidenavsubLoads> </mat-sidenav>

<!-- side navigation bar -->
<div class="inner-content" fxLayout="column">
  <div mat-icon-button class="icon-container">
    <mat-icon class="xpress-tech-icon" svgIcon="xpress-technologies-side-nav-icon"></mat-icon>
  </div>
  <mat-divider></mat-divider>
  <div class="account-container" fxLayout="column">
    <div
      fxLayout="row"
      fxLayoutAlign="space-between center"
      class="account-container__section"
      (click)="subNavToggle(sidenavsubAccount)"
      data-test="account-button"
    >
      <div class="user-profile__user account-container__section--no-icon-wrap">
        <div class="user-profile__avatar">{{ user?.name | userInitials }}</div>
        <div class="user-profile__bio">
          <span class="user-profile__bio--name">{{ user?.name }}</span>
          <span class="user-profile__bio--company" *ngIf="!user?.isHaulynxAdmin">{{
            user?.carrier
              ? user.carrier?.name
              : user?.shipper
              ? user?.shipper?.name
              : user?.broker
              ? user?.broker?.name
              : ''
          }}</span>
          <span *ngIf="user?.isHaulynxAdmin" class="user-info-text"> Haulynx Admin </span>
        </div>
      </div>
      <mat-icon svgIcon="right-arrow"></mat-icon>
    </div>
    <div
      *ngIf="rmisProps?.showAccountSetup"
      fxLayout="row"
      fxLayoutAlign="space-between center"
      class="account-container__section"
      (click)="subNavToggle(sidenavsubAccountSetup)"
    >
      <div class="account-container__section--no-icon-wrap">
        <haulynx-header-endbuttons-account-setup [rmisProps]="rmisProps"></haulynx-header-endbuttons-account-setup>
        <div class="account-container__section--current-rmis-step">
          <p *ngIf="currentRmisStep?.step">Account Setup {{ currentRmisStep?.step }} of 5:</p>
          <p>{{ currentRmisStep?.label }}</p>
        </div>
      </div>
      <mat-icon svgIcon="right-arrow"></mat-icon>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="menu-container">
    <haulynx-sidebar-nav-tabs
      [loadsSidenavSub]="sidenavsubLoads"
      [headerItems]="headerItems"
      [user]="user"
      (selectedSideItem)="selectedItem($event)"
      (startTour)="whatsNewTour()"
      (subNavToggle)="subNavToggle($event)"
    ></haulynx-sidebar-nav-tabs>
  </div>
  <mat-divider></mat-divider>
  <div class="sidebar-buttom-container" fxLayout="column">
    <div class="notification-container" fxLayout="column" fxLayoutGap="20px">
      <ng-container *ngIf="user && user.isHaulynxAdmin">
        <div class="nav-button" (click)="onShowVerifyEmail()" data-test="verify-email-button">
          <mat-icon>email</mat-icon>
          <span>Verify Email</span>
        </div>
      </ng-container>
      <div class="nav-button" (click)="openNotification()">
        <mat-icon svgIcon="notification"></mat-icon>
        <span>Notifications</span>
        <ng-container *ngIf="totalNotifications">
          <div class="notification-container__alert">
            <mat-icon svgIcon="notification-alert"></mat-icon>
            <span class="notification-container__badge" *ngIf="totalNotifications > 0">{{
              totalNotifications > 99 ? '99+' : totalNotifications
            }}</span>
          </div>
        </ng-container>
      </div>
      <div class="nav-button" (click)="openHelp()">
        <mat-icon svgIcon="help"></mat-icon>
        <span>Help</span>
      </div>
    </div>
  </div>
  <div class="policy-terms-container" fxLayout="row" fxLayoutAlign="space-between end">
    <ng-container *ngFor="let item of headerItems">
      <span *ngIf="item.termsOrPrivacyItem" class="policy-terms-item" (click)="selectedItem(item)">{{
        item.name
      }}</span>
      <div *ngIf="item.name === headerNames.TERMS_SERVICE" fxLayout="row" fxLayoutAlign="center center">
        <span> • </span>
      </div>
    </ng-container>
  </div>
</div>
