import { Injectable } from '@angular/core';
import {
  CreateFeatureFlagDetails,
  DeleteFeatureFlagDetails,
  GetFeatureFlagDetails,
  GetFeatureFlags,
  UpdateFeatureFlagDetails,
} from '@haulynx/gql';
import { FeatureFlagDetails, FeatureFlagDetailsInput, FeatureFlags } from '@haulynx/types';
import { get } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GraphqlService } from '../graphql.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagDashboardService {
  constructor(private graphqlService: GraphqlService) {}

  getFeatureFlags(platform = null, environment = null): Observable<FeatureFlags[]> {
    return this.graphqlService
      .query({
        query: GetFeatureFlags,
        variables: { platform, environment },
      })
      .pipe(map((result: { data: unknown; error: { message: string } }) => get(result, `data.getFeatureFlags`, [])));
  }

  getFeatureFlagDetails(featureFlagName = null): Observable<FeatureFlagDetails | FeatureFlagDetails[]> {
    return this.graphqlService
      .query({
        query: GetFeatureFlagDetails,
        variables: { featureFlagName, includeDetails: !!featureFlagName },
      })
      .pipe(
        map((result: { data: unknown; error: { message: string } }) => get(result, `data.getFeatureFlagDetails`, []))
      );
  }

  deleteFeatureFlagDetails(featureFlagName: string): Observable<boolean> {
    return this.graphqlService
      .mutate({
        mutation: DeleteFeatureFlagDetails,
        variables: { featureFlagName },
      })
      .pipe(
        map((result: { data: unknown; error: { message: string } }) => get(result, `data.deleteFeatureFlagDetails`, []))
      );
  }

  createFeatureFlagDetails(featureFlag: FeatureFlagDetailsInput): Observable<boolean> {
    return this.graphqlService
      .mutate({
        mutation: CreateFeatureFlagDetails,
        variables: { featureFlag },
      })
      .pipe(
        map((result: { data: unknown; error: { message: string } }) => get(result, `data.createFeatureFlagDetails`, []))
      );
  }

  updateFeatureFlagDetails(featureFlagName: string, featureFlag: FeatureFlagDetailsInput): Observable<boolean> {
    return this.graphqlService
      .mutate({
        mutation: UpdateFeatureFlagDetails,
        variables: { featureFlagName, featureFlag },
      })
      .pipe(
        map((result: { data: unknown; error: { message: string } }) => get(result, `data.updateFeatureFlagDetails`, []))
      );
  }
}
