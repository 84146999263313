import { Injectable } from '@angular/core';
import { Action, Category, Label } from '@haulynx/types';

declare let gtag: (
  type: string,
  event: { [key: string]: unknown } | string,
  otherInformation?: { [key: string]: unknown }
) => void;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor() {}

  /**
   * Emit an event to Google Analytics for tracking
   * @param eventCategory Typically the object that was interacted with (e.g. 'Video')
   * @param eventAction The type of interaction (e.g. 'play')
   * @param eventLabel Useful for categorizing events (e.g. 'Fall Campaign')
   * @param eventValue A numeric value associated with the event (e.g. 42)
   */
  public eventEmitter(
    eventCategory: Category,
    eventAction: Action,
    eventLabel: Label | string = null,
    eventValue: number = null
  ): void {
    gtag('event', 'web_triggered_event', {
      event_category: eventCategory,
      event_action: eventAction,
      event_label: eventLabel,
      value: eventValue,
    });
  }

  /**
   * Track a custom made timed event in Google Analytics
   * @param name A string indicating what the given event or element is.
   * @param value A numeric value associated with the event (e.g. 42)
   * @param category Typically the object that was interacted with (e.g. 'Video')
   * @param label Useful for categorizing events (e.g. 'Fall Campaign')
   */
  public eventTimer(name: string, value: number, category: string, label: string = null): void {
    gtag('event', 'timing_complete', {
      name,
      value,
      event_category: category,
      event_label: label,
    });
  }

  /**
   * Set the user ID using signed-in user_id.
   * @param id The currently signed in user's id
   */
  public setUserId(id: string): void {
    gtag('set', { user_id: id });
  }
}
