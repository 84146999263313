import { CarrierInfoSectionForm } from '@haulynx/services';
import {
  AssignDriverForm,
  AssignmentForm,
  BookLoadForCarrier,
  DispatchForm,
  DriverAssignment,
  LoadCarrierContact,
  LoadsServiceLoad,
  LoadsServiceLoadLocation,
  LocationForm,
  TrailerAssignment,
  TruckAssignment,
} from '@haulynx/types';
import { IFormActions, IFormTypes } from '../../shared/store/form/actions';
import { createAction, createActionTypes } from '../../shared/store/helpers/action';
import { loadDetailsAdapter, TYPE } from './adapters';

export interface ILoadDetailsActions {
  setActiveStep(params: { key: string; stepId: string | number });
  setValue(params: { key: string; state: LoadsServiceLoad });
  getRoute(params?);
  getRouteSuccess(params?);
  getRouteError(params?);
  getRoutes(params?);
  getRoutesSuccess(params?);
  getRoutesError(params?);
  clearRoutes(params?);
  update(params?);
  updateSuccess(params?);
  updateError(params?);
  getDeviceLocations(params?);
  getDeviceLocationsSuccess(params?);
  getDeviceLocationsError(params?);
  assignToDriver(params: { loadId: string; data: AssignDriverForm });
  assignToDriverSuccess(params: { loadId: string; state: Partial<LoadsServiceLoad> });
  assignToDriverError(params?);
  assignmentToDriver(params: {
    loadId: string;
    assignment: Partial<AssignmentForm>;
    carrierContact: CarrierInfoSectionForm;
  });
  assignmentToDriverSuccess(params: { loadId: string; state: Partial<LoadsServiceLoad> });
  assignmentToDriverError(params?);
  dispatchDetailLoad(params: { loadId: string; data: DispatchForm });
  dispatchDetailLoadSuccess(params: { loadId: string });
  dispatchDetailLoadError(params?);
  dispatch(params: { loadId: string; data: DispatchForm });
  dispatchSuccess(params: { loadId: string });
  dispatchError(params?);
  confirmLocation(params: { loadId: string; locationId: number | string; data: LocationForm });
  confirmLocationSuccess(params: { loadId: string; location: LoadsServiceLoadLocation });
  confirmLocationError(params?);
  bookLoadForCarrier(params: BookLoadForCarrier);
  bookLoadForCarrierSuccess(params: { loadId: string });
  bookLoadForCarrierError(params: { loadId: string });
  bounceCarrier(params: { loadId: string; orderNumber: string });
  bounceCarrierSuccess(params: { loadId: string });
  bounceCarrierError(params: { loadId: string });
  createDriver(params: DriverAssignment);
  createDriverSuccess(params?);
  createDriverError(params?);
  createTruck(params: TruckAssignment);
  createTruckSuccess(params?);
  createTruckError(params?);
  createTrailer(params: TrailerAssignment);
  createTrailerSuccess(params?);
  createTrailerError(params?);
  populateDriver(id: string);
  populateTruck(id: string);
  populateTrailer(id: string);
}

export interface ILoadDetailsActionTypes extends IFormTypes {
  SET_ACTIVE_STEP: string;
  SET_VALUE: string;
  GET_ROUTE: string;
  GET_ROUTE_SUCCESS: string;
  GET_ROUTE_ERROR: string;
  GET_ROUTES: string;
  GET_ROUTES_SUCCESS: string;
  GET_ROUTES_ERROR: string;
  CLEAR_ROUTES: string;
  UPDATE: string;
  UPDATE_SUCCESS: string;
  UPDATE_ERROR: string;
  GET_DEVICE_LOCATIONS: string;
  GET_DEVICE_LOCATIONS_SUCCESS: string;
  GET_DEVICE_LOCATIONS_ERROR: string;
  ASSIGN_TO_DRIVER: string;
  ASSIGN_TO_DRIVER_SUCCESS: string;
  ASSIGN_TO_DRIVER_ERROR: string;
  ASSIGNMENT_TO_DRIVER: string;
  ASSIGNMENT_TO_DRIVER_SUCCESS: string;
  ASSIGNMENT_TO_DRIVER_ERROR: string;
  DISPATCH_DETAIL_LOAD: string;
  DISPATCH_DETAIL_LOAD_SUCCESS: string;
  DISPATCH_DETAIL_LOAD_ERROR: string;
  DISPATCH: string;
  DISPATCH_SUCCESS: string;
  DISPATCH_ERROR: string;
  CONFIRM_LOCATION: string;
  CONFIRM_LOCATION_SUCCESS: string;
  CONFIRM_LOCATION_ERROR: string;
  BOOK_LOAD_FOR_CARRIER: string;
  BOOK_LOAD_FOR_CARRIER_SUCCESS: string;
  BOOK_LOAD_FOR_CARRIER_ERROR: string;
  BOUNCE_CARRIER: string;
  BOUNCE_CARRIER_SUCCESS: string;
  BOUNCE_CARRIER_ERROR: string;
  GET_PAYMENT_TYPES: string;
  GET_PAYMENT_TYPES_SUCCESS: string;
  GET_PAYMENT_TYPES_ERROR: string;
  CREATE_DRIVER: string;
  CREATE_DRIVER_SUCCESS: string;
  CREATE_DRIVER_ERROR: string;
  CREATE_TRUCK: string;
  CREATE_TRUCK_SUCCESS: string;
  CREATE_TRUCK_ERROR: string;
  CREATE_TRAILER: string;
  CREATE_TRAILER_SUCCESS: string;
  CREATE_TRAILER_ERROR: string;
  POPULATE_DRIVER: string;
  POPULATE_TRUCK: string;
  POPULATE_TRAILER: string;
}

export const loadDetailsActionTypes = {
  SET_ACTIVE_STEP: 'set active step',
  SET_VALUE: 'set value',
  GET_ROUTE: 'get route',
  GET_ROUTE_SUCCESS: 'get route success',
  GET_ROUTE_ERROR: 'get route error',
  GET_ROUTES: 'get routes',
  GET_ROUTES_SUCCESS: 'get routes success',
  GET_ROUTES_ERROR: 'get routes error',
  CLEAR_ROUTES: 'clear routes',
  UPDATE: 'update',
  UPDATE_SUCCESS: 'update success',
  UPDATE_ERROR: 'update error',
  GET_DEVICE_LOCATIONS: 'get device locations',
  GET_DEVICE_LOCATIONS_SUCCESS: 'get device locations success',
  GET_DEVICE_LOCATIONS_ERROR: 'get device locations error',
  ASSIGN_TO_DRIVER: 'assign to driver',
  ASSIGN_TO_DRIVER_SUCCESS: 'assign to driver success',
  ASSIGN_TO_DRIVER_ERROR: 'assign to driver error',
  ASSIGNMENT_TO_DRIVER: 'assignment to driver',
  ASSIGNMENT_TO_DRIVER_SUCCESS: 'assignment to driver success',
  ASSIGNMENT_TO_DRIVER_ERROR: 'assignment to driver error',
  DISPATCH_DETAIL_LOAD: 'dispatch detail load',
  DISPATCH_DETAIL_LOAD_SUCCESS: 'dispatch detail load success',
  DISPATCH_DETAIL_LOAD_ERROR: 'dispatch detail load error',
  DISPATCH: 'dispatch',
  DISPATCH_SUCCESS: 'dispatch success',
  DISPATCH_ERROR: 'dispatch error',
  CONFIRM_LOCATION: 'confirm location',
  CONFIRM_LOCATION_SUCCESS: 'confirm location success',
  CONFIRM_LOCATION_ERROR: 'confirm location error',
  BOOK_LOAD_FOR_CARRIER: 'book load for carrier',
  BOOK_LOAD_FOR_CARRIER_SUCCESS: 'book load for carrier success',
  BOOK_LOAD_FOR_CARRIER_ERROR: 'book load for carrier error',
  BOUNCE_CARRIER: 'bounce carrier from load',
  BOUNCE_CARRIER_SUCCESS: 'bounce carrier from load success',
  BOUNCE_CARRIER_ERROR: 'bounce carrier from load error',
  GET_PAYMENT_TYPES: 'get payment types',
  GET_PAYMENT_TYPES_SUCCESS: 'get payment types success',
  GET_PAYMENT_TYPES_ERROR: 'get payment types error',
  CREATE_DRIVER: 'create driver',
  CREATE_DRIVER_SUCCESS: 'create driver success',
  CREATE_DRIVER_ERROR: 'create driver error',
  CREATE_TRUCK: 'create truck',
  CREATE_TRUCK_SUCCESS: 'create truck success',
  CREATE_TRUCK_ERROR: 'create truck error',
  CREATE_TRAILER: 'create trailer',
  CREATE_TRAILER_SUCCESS: 'create trailer success',
  CREATE_TRAILER_ERROR: 'create trailer error',
  POPULATE_DRIVER: 'set driver to populate',
  POPULATE_TRUCK: 'set truck to populate',
  POPULATE_TRAILER: 'set trailer to populate',
};

export const loadDetailsActions: ILoadDetailsActions = {
  setValue: createAction(TYPE, loadDetailsActionTypes.SET_VALUE),
  setActiveStep: createAction(TYPE, loadDetailsActionTypes.SET_ACTIVE_STEP),
  getRoute: createAction(TYPE, loadDetailsActionTypes.GET_ROUTE),
  getRouteSuccess: createAction(TYPE, loadDetailsActionTypes.GET_ROUTE_SUCCESS),
  getRouteError: createAction(TYPE, loadDetailsActionTypes.GET_ROUTE_ERROR),
  getRoutes: createAction(TYPE, loadDetailsActionTypes.GET_ROUTES),
  getRoutesSuccess: createAction(TYPE, loadDetailsActionTypes.GET_ROUTES_SUCCESS),
  getRoutesError: createAction(TYPE, loadDetailsActionTypes.GET_ROUTES_ERROR),
  clearRoutes: createAction(TYPE, loadDetailsActionTypes.CLEAR_ROUTES),
  update: createAction(TYPE, loadDetailsActionTypes.UPDATE),
  updateSuccess: createAction(TYPE, loadDetailsActionTypes.UPDATE_SUCCESS),
  updateError: createAction(TYPE, loadDetailsActionTypes.UPDATE_ERROR),
  getDeviceLocations: createAction(TYPE, loadDetailsActionTypes.GET_DEVICE_LOCATIONS),
  getDeviceLocationsSuccess: createAction(TYPE, loadDetailsActionTypes.GET_DEVICE_LOCATIONS_SUCCESS),
  getDeviceLocationsError: createAction(TYPE, loadDetailsActionTypes.GET_DEVICE_LOCATIONS_ERROR),
  assignToDriver: createAction(TYPE, loadDetailsActionTypes.ASSIGN_TO_DRIVER),
  assignToDriverSuccess: createAction(TYPE, loadDetailsActionTypes.ASSIGN_TO_DRIVER_SUCCESS),
  assignToDriverError: createAction(TYPE, loadDetailsActionTypes.ASSIGN_TO_DRIVER_ERROR),
  assignmentToDriver: createAction(TYPE, loadDetailsActionTypes.ASSIGNMENT_TO_DRIVER),
  assignmentToDriverSuccess: createAction(TYPE, loadDetailsActionTypes.ASSIGNMENT_TO_DRIVER_SUCCESS),
  assignmentToDriverError: createAction(TYPE, loadDetailsActionTypes.ASSIGNMENT_TO_DRIVER_ERROR),
  dispatchDetailLoad: createAction(TYPE, loadDetailsActionTypes.DISPATCH_DETAIL_LOAD),
  dispatchDetailLoadSuccess: createAction(TYPE, loadDetailsActionTypes.DISPATCH_DETAIL_LOAD_SUCCESS),
  dispatchDetailLoadError: createAction(TYPE, loadDetailsActionTypes.DISPATCH_DETAIL_LOAD_ERROR),
  dispatch: createAction(TYPE, loadDetailsActionTypes.DISPATCH),
  dispatchSuccess: createAction(TYPE, loadDetailsActionTypes.DISPATCH_SUCCESS),
  dispatchError: createAction(TYPE, loadDetailsActionTypes.DISPATCH_ERROR),
  confirmLocation: createAction(TYPE, loadDetailsActionTypes.CONFIRM_LOCATION),
  confirmLocationSuccess: createAction(TYPE, loadDetailsActionTypes.CONFIRM_LOCATION_SUCCESS),
  confirmLocationError: createAction(TYPE, loadDetailsActionTypes.CONFIRM_LOCATION_ERROR),
  bookLoadForCarrier: createAction(TYPE, loadDetailsActionTypes.BOOK_LOAD_FOR_CARRIER),
  bookLoadForCarrierSuccess: createAction(TYPE, loadDetailsActionTypes.BOOK_LOAD_FOR_CARRIER_SUCCESS),
  bookLoadForCarrierError: createAction(TYPE, loadDetailsActionTypes.BOOK_LOAD_FOR_CARRIER_ERROR),
  bounceCarrier: createAction(TYPE, loadDetailsActionTypes.BOUNCE_CARRIER),
  bounceCarrierSuccess: createAction(TYPE, loadDetailsActionTypes.BOUNCE_CARRIER_SUCCESS),
  bounceCarrierError: createAction(TYPE, loadDetailsActionTypes.BOUNCE_CARRIER_ERROR),
  createDriver: createAction(TYPE, loadDetailsActionTypes.CREATE_DRIVER),
  createDriverSuccess: createAction(TYPE, loadDetailsActionTypes.CREATE_DRIVER_SUCCESS),
  createDriverError: createAction(TYPE, loadDetailsActionTypes.CREATE_DRIVER_ERROR),
  createTruck: createAction(TYPE, loadDetailsActionTypes.CREATE_TRUCK),
  createTruckSuccess: createAction(TYPE, loadDetailsActionTypes.CREATE_TRUCK_SUCCESS),
  createTruckError: createAction(TYPE, loadDetailsActionTypes.CREATE_TRUCK_ERROR),
  createTrailer: createAction(TYPE, loadDetailsActionTypes.CREATE_TRAILER),
  createTrailerSuccess: createAction(TYPE, loadDetailsActionTypes.CREATE_TRAILER_SUCCESS),
  createTrailerError: createAction(TYPE, loadDetailsActionTypes.CREATE_TRAILER_ERROR),
  populateDriver: createAction(TYPE, loadDetailsActionTypes.POPULATE_DRIVER),
  populateTruck: createAction(TYPE, loadDetailsActionTypes.POPULATE_TRUCK),
  populateTrailer: createAction(TYPE, loadDetailsActionTypes.POPULATE_TRAILER),
};

export const LoadDetailsActions: ILoadDetailsActions & IFormActions = {
  ...loadDetailsActions,
  ...loadDetailsAdapter.getActions(),
};
export const LoadDetailsActionTypes: ILoadDetailsActionTypes = {
  ...loadDetailsAdapter.getActionTypes(),
  ...createActionTypes(loadDetailsActionTypes)(TYPE),
};
