import { GeoSpacialFilterType, ISearchFilter } from '../search-filter';
import { ISearchFilterType } from '../search-filter.types';
export class LaneDestinationFilter implements ISearchFilter {
  name = 'Destination';
  type: ISearchFilterType = ISearchFilterType.GEOSPATIAL;
  keys: GeoSpacialFilterType;
  description = 'Filter lanes by the lane destination';

  constructor(defaultValues?: GeoSpacialFilterType) {
    this.keys = new GeoSpacialFilterType({
      radius: 50,
      lat: null,
      lon: null,
      location: '',
      radiusFormName: 'destinationRadius',
      latFormName: 'destinationLat',
      lonFormName: 'destinationLon',
      locationFormName: 'destinationLocation',
      state: null,
      stateFormName: 'destinationState',
      zipFormName: 'destinationZip',
      ...defaultValues,
    });
  }
}
