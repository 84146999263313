import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ISearchFilter, TextFilterType } from '../search-filter';
import { ISearchFilterType } from '../search-filter.types';

export class BookedByBrokerFilter implements ISearchFilter {
  name = 'Booked By';
  type: ISearchFilterType = ISearchFilterType.MULTI_DROPDOWN;
  keys: TextFilterType;
  description = 'Filter loads by brokers that have booked them';

  constructor(entities: Observable<any[]>, defaultValues?: TextFilterType) {
    this.keys = new TextFilterType({
      value: '',
      optionsDataIndex: entities.pipe(
        map((data) =>
          data.map((dd) => {
            return { value: dd.id, key: dd.label };
          })
        )
      ),
      textFormName: 'bookingBrokers',
      ...defaultValues,
    });
  }
}
