import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { AppModel, UserEntityService } from '@haulynx/store';
import {
  BookStatus,
  buttonTypes,
  Carrier,
  CarrierSourceType,
  FeatureFlag,
  FFState,
  IColumns2,
  LoadsServiceLoad,
  RecommendedCarriers,
  RecommendedType,
  User,
} from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { List, Map } from 'immutable';
import { BehaviorSubject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { loadConfigOptions, notRecommendedColumns, recommendedColumns } from './load-feed-carriers-search-v2.config';

@Component({
  selector: 'app-load-feed-carrier-search-v2',
  templateUrl: './load-feed-carriers-search-v2.component.html',
  styleUrls: ['./load-feed-carriers-search-v2.component.scss'],
})
export class LoadFeedCarrierSearchV2Component implements OnChanges, OnDestroy {
  @Input() data: List<RecommendedCarriers> | Map<string, RecommendedCarriers> | RecommendedCarriers[] = [];
  @Input() load: LoadsServiceLoad = null;
  @Input() isLoading = false;
  @Input() pagination = null;
  @Input() query: { [key: string]: string } = {};
  @Input() disableButtons = {};
  @Input() parentToTable: HTMLElement;
  @Input() hideSearchAndButtons = false;

  @Output() onPagination = new EventEmitter();
  @Output() onSearch = new EventEmitter<{ carrierSearch: string; loadId: string }>();
  @Output() onAction = new EventEmitter<{ action: string; row: unknown }>();
  @Output() onCarrierSelect = new EventEmitter<Carrier>();
  @Output() onShowFacilityInfo = new EventEmitter<{ customerNumber; index }>();

  buttons$ = new BehaviorSubject([]);
  pageOptions = [50];
  dataTableColumns: IColumns2[] = [];
  user: User;
  carrierSourceType = CarrierSourceType;
  alive = aliveWhile();
  filterForm = '';
  hasBidsSection = false;
  BookStatus = BookStatus;
  configOptions$ = new BehaviorSubject(loadConfigOptions);
  isLoading$ = new BehaviorSubject(false);
  tableData$ = new BehaviorSubject([]);
  recommendedType = RecommendedType;

  shouldShowBidBookButtons = true;

  constructor(
    private appModel: AppModel,
    private router: ActivatedRoute,
    private userEntityService: UserEntityService
  ) {
    this.appModel.user$.pipe(takeUntil(this.alive)).subscribe((user) => (this.user = user));
    this.dataTableColumns = this.getColumns(false);
    this.filterForm = this.query && this.query.carrierSearch ? this.query.carrierSearch : '';
  }

  getColumns(isRecommended: boolean): IColumns2[] {
    let columns: IColumns2[] = [];

    if (isRecommended) {
      columns = recommendedColumns;
    } else {
      columns = notRecommendedColumns;
    }

    return columns;
  }

  pageChange(event: unknown): void {
    this.onPagination.emit(event);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { query, data, load, isLoading } = changes;

    if (query) {
      const { carrierSearch } = query.currentValue;
      if (carrierSearch) this.filterForm = carrierSearch;
    }

    if (isLoading) {
      this.isLoading$.next(isLoading.currentValue);
    }

    if (data) {
      const { carrierSearch } = this.query;
      this.tableData$.next(data?.currentValue || []);
      this.dataTableColumns = carrierSearch ? this.getColumns(false) : this.getColumns(true);
    }

    if (load && load.currentValue) {
      this.configOptions$.next({
        ...this.configOptions$.value,
        globalFilter: load.currentValue?.bookStatus !== BookStatus.BOOKED && !this.hideSearchAndButtons,
      });

      const bookButton = load.currentValue.bookStatus === BookStatus.PAUSE ? [] : [buttonTypes.BOOK];

      if (
        load.currentValue &&
        (load.currentValue.bookStatus === BookStatus.BOOKED || load.currentValue.bookStatus === BookStatus.PAUSE)
      ) {
        this.shouldShowBidBookButtons = false;
      }
      this.userEntityService.featureFlags$.pipe(takeUntil(this.alive)).subscribe((feature: FFState) => {
        this.hasBidsSection = feature ? feature[FeatureFlag.LOAD_OFFER_BIDDING] : false;
        if (feature[FeatureFlag.LOAD_OFFER_BIDDING] && this.shouldShowBidBookButtons) {
          this.buttons$.next([buttonTypes.BID, ...bookButton, buttonTypes.EMAIL_ICON]);
        } else if (!this.shouldShowBidBookButtons) {
          this.buttons$.next([buttonTypes.EMAIL_ICON, buttonTypes.BID]);
        } else {
          this.buttons$.next([...bookButton, buttonTypes.EMAIL_ICON]);
        }
      });
    }
  }

  action(action: string, row: unknown): void {
    this.onAction.emit({ action, row });
  }

  clickableColumnWasSelected(event: { column: IColumns2; row: RecommendedCarriers }): void {
    if (event.column.field === 'orderresult.loadServiceLoad.name') {
    }
  }

  filterChanged(event: string): void {
    this.router.params.pipe(first(), takeUntil(this.alive)).subscribe((params: Params) => {
      const loadId = params['id'];
      this.onSearch.emit({ carrierSearch: event, loadId });
    });
  }

  ngOnDestroy() {
    this.alive.destroy();
  }
}
