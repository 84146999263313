import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AssignLoadsServiceLoadMutation,
  AssignUSXLoadsServiceLoadMutation,
  BookLoad,
  BookLoadForCarrierMutation,
  BounceOrderMutation,
  BrokerSetPrice,
  BrokerUpdateLoad,
  BulkEditLoads,
  CreateCarrierLoadMutation,
  CreateLoadsServiceLoadTemplate,
  CreateMissions,
  DeactivateLoad,
  DeleteLoadsServiceLoadById,
  DeleteMission,
  DeleteOfferMutation,
  DispatchLoadMutation,
  FetchUSXOrderStatus,
  GetCarrierByCarrierOwner,
  GetDocuments,
  GetLastLoadLocationByLoadId,
  GetLoadHistoryByLoadId,
  GetLoadMatchesByCarrierDot,
  GetLoadsBidDetails,
  GetLoadsBrokered,
  GetLoadServiceMetaData,
  GetLoadsServiceLoadById,
  GetLoadsServiceLoadsByCarrierDot,
  GetLoadsServiceLoadsByUser,
  GetLoadsServiceLoadTemplatesByUserId,
  GetRecommendedCarriersByLoadId,
  GetUsxLoads,
  ReportRolledLoadsMutation,
  UpdateLoadsServiceLoad,
  UpdateLocationArrivalTime,
  UpdateLocationDepartureTime,
  UpdateUSXLoadPricing,
  ValidateMissions,
  BookLoadsInMission,
  GetOrderLineItems,
} from '@haulynx/gql';
import {
  ActiveBrokeredLoadsAsLoadsParams,
  AssignLoadInput,
  AssignLoadPayload,
  AssignLoadResponse,
  AssignUSXITrailerInput,
  BidDetails,
  BookCarrierPayload,
  BookingType,
  BookLoadForCarrier,
  BulkEditLoadsInput,
  DispatchForm,
  DispatchLocationInput,
  Environment,
  GraphqlSearchResponse,
  ILoadsServicePricingInput,
  LoadCarrierOffer,
  LoadHistory,
  LoadIdentifierType,
  LoadRouteData,
  LoadServiceSearchParameters,
  LoadsServiceBrokerEditFields,
  LoadsServiceHistogramField,
  LoadsServiceLoad,
  LoadsServiceLoadInput,
  LoadsServiceLoadLocation,
  LoadsServiceRestriction,
  LoadsServiceRestrictionTypes,
  LoadsServiceSearchMetaData,
  LoadTemplate,
  Location,
  mapBoxDrivingRequestOptions,
  mapBoxRequestOptions,
  MapboxRouteData,
  MapOverview,
  NewUSXITrailer,
  MissionCreateInput,
  MissionBookInput,
  MissionValidation,
  BookLoadsInMissionResponse,
  PageAndSort,
  PaginatedData,
  PaginatedLoadsServiceData,
  PaginatedZipLaneServiceData,
  PaymentItemCreateInput,
  RecommendedCarriers,
  RecommendedData,
  RolledLoads,
  TrackingEnabledInput,
  TrackingType,
  UnauthenticatedLoadsServiceLoad,
  TrailerOwnerType,
  DocumentParamsInput,
  Document,
  ANALYTICS_EVENT,
  PaymentItemSearchCriteria,
} from '@haulynx/types';
import { decodeGeoJSONString, splitArrayIntoGroups, toHttpParams } from '@haulynx/utils';
import { concat, flatten, get, map as _map, omit, some, toString, uniqBy, values } from 'lodash';
import { LngLatLike } from 'mapbox-gl';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, debounceTime, map, switchMap, switchMapTo } from 'rxjs/operators';
import { AnalyticsService } from '../app-services/analytics/analytics.service';
import { MomentService } from '../app-services/generic/moment/moment.service';
import { GraphqlService } from '../graphql/graphql.service';

@Injectable({
  providedIn: 'root',
})
export class LoadsServiceService {
  constructor(
    private http: HttpClient,
    private graphqlService: GraphqlService,
    private environment: Environment,
    private momentService: MomentService,
    private analyticsService: AnalyticsService
  ) {}

  bounceOrder(orderNumber: string) {
    return this.graphqlService
      .mutate({
        mutation: BounceOrderMutation,
        variables: {
          orderNumber,
        },
      })
      .pipe(map((result) => result.data['bounceOrder']));
  }

  updateUsxOrderStatus(orderNumber: string) {
    return this.graphqlService
      .mutate({
        mutation: FetchUSXOrderStatus,
        variables: {
          orderNumber,
        },
      })
      .pipe(map((result) => result.data['fetchUSXOrderStatus']));
  }

  getLoadById(loadId: string): Observable<LoadsServiceLoad> {
    return this.graphqlService
      .query<LoadsServiceLoad>({
        query: GetLoadsServiceLoadById,
        variables: {
          loadId,
        },
      })
      .pipe(map((response) => response?.data['getLoadsServiceLoadById']));
  }

  getLoadLocationById(loadId: string): Observable<Location> {
    return this.graphqlService
      .query<Location>({
        query: GetLastLoadLocationByLoadId,
        variables: {
          loadId,
        },
      })
      .pipe(map((result) => result.data['getLastLoadLocationByLoadId']));
  }

  brokerUpdateLoad(
    loadId: string,
    loadFields: Partial<LoadsServiceBrokerEditFields>,
    load: LoadsServiceLoad
  ): Observable<LoadsServiceLoad> {
    const { highValueException, ...updatedLoadFields } = loadFields;
    const brokerUpdateObs = this.graphqlService
      .mutate<LoadsServiceLoad>({
        mutation: BrokerUpdateLoad,
        variables: {
          loadId,
          loadFields: updatedLoadFields,
        },
      })
      .pipe(map((result) => result.data['brokerUpdateLoad']));
    if (highValueException == null) {
      return brokerUpdateObs;
    } else {
      const existingRestrictionIndex: number = load?.restrictions?.findIndex(
        (r) => r.type === LoadsServiceRestrictionTypes.HIGH_VALUE_PRODUCT_EXCEPTION
      );
      const highValueExceptionRestriction: LoadsServiceRestriction = {
        type: LoadsServiceRestrictionTypes.HIGH_VALUE_PRODUCT_EXCEPTION,
        value: toString(highValueException),
      };
      const newRestrictions = [...load.restrictions];
      if (existingRestrictionIndex >= 0) {
        newRestrictions[existingRestrictionIndex] = highValueExceptionRestriction;
      } else {
        newRestrictions.push(highValueExceptionRestriction);
      }
      return this.editLoad(loadId, { restrictions: newRestrictions }).pipe(switchMapTo(brokerUpdateObs));
    }
  }

  editLoad(loadId: string, loadUpdates: Partial<LoadsServiceLoadInput>): Observable<LoadsServiceLoad> {
    return this.graphqlService
      .mutate<LoadsServiceLoad>({
        mutation: UpdateLoadsServiceLoad,
        variables: {
          loadId,
          loadUpdates,
        },
      })
      .pipe(map((result) => result.data['updateLoadsServiceLoad']));
  }

  downloadLoadActivities(loadId: string, loadActivities: Partial<LoadsServiceLoadInput>): Observable<LoadsServiceLoad> {
    return this.graphqlService
      .mutate<LoadsServiceLoad>({
        mutation: UpdateLoadsServiceLoad,
        variables: {
          loadId,
          loadActivities,
        },
      })
      .pipe(map((result) => result.data['updateLoadsServiceLoad']));
  }

  updatePricing(loadId: string, pricingUpdate: ILoadsServicePricingInput): Observable<LoadsServiceLoad> {
    return this.graphqlService
      .mutate<LoadsServiceLoad>({
        mutation: UpdateUSXLoadPricing,
        variables: {
          loadId,
          pricingUpdate,
        },
      })
      .pipe(
        map((result) => {
          return result.data['updateUSXLoadPricing'];
        }),
        catchError(() => {
          return of(null);
        })
      );
  }

  bulkUpdateLoads(loadEdits: BulkEditLoadsInput): Observable<LoadsServiceLoad[]> {
    return this.graphqlService
      .mutate({
        mutation: BulkEditLoads,
        variables: {
          loadEdits,
        },
      })
      .pipe(map((result) => result.data['bulkEditLoadsServiceLoads']));
  }

  searchLoads(
    searchParameters: Partial<LoadServiceSearchParameters>,
    paging: Partial<PageAndSort> = { limit: 100 },
    fullMission?: boolean
  ): Observable<PaginatedData<LoadsServiceLoad>> {
    return this.graphqlService
      .query<PaginatedData<LoadsServiceLoad>>({
        query: GetUsxLoads(fullMission),
        variables: {
          searchParameters: new LoadServiceSearchParameters(searchParameters),
          paging,
        },
      })
      .pipe(
        map((result) => {
          const response: PaginatedLoadsServiceData<LoadsServiceLoad> = result.data['getUSXLoads'];
          return {
            data: response?.data,
            pagination: response?.paginator,
          } as PaginatedData<LoadsServiceLoad>;
        })
      );
  }

  searchCarrierLoads(
    dot: string,
    filterParams: Partial<LoadServiceSearchParameters>,
    paging: Partial<PageAndSort> = {}
  ): Observable<PaginatedData<LoadsServiceLoad>> {
    return this.graphqlService
      .query<PaginatedData<LoadsServiceLoad>>({
        query: GetLoadsServiceLoadsByCarrierDot,
        variables: {
          dot,
          filterParams: new LoadServiceSearchParameters(filterParams),
          paging,
        },
      })
      .pipe(
        map((result) => {
          const response: PaginatedLoadsServiceData<LoadsServiceLoad> = result.data['getLoadsServiceLoadsByCarrierDot'];
          return {
            data: response?.data,
            pagination: response?.paginator,
          } as PaginatedData<LoadsServiceLoad>;
        })
      );
  }

  searchUserLoads(
    id: string,
    filterParams: Partial<LoadServiceSearchParameters>,
    paging: Partial<PageAndSort> = {}
  ): Observable<PaginatedData<LoadsServiceLoad>> {
    return this.graphqlService
      .query<PaginatedData<LoadsServiceLoad>>({
        query: GetLoadsServiceLoadsByUser,
        variables: {
          id,
          filterParams: new LoadServiceSearchParameters(filterParams),
          paging,
        },
      })
      .pipe(
        map((result) => {
          const response: PaginatedLoadsServiceData<LoadsServiceLoad> = result.data['getLoadsServiceLoadsByUser'];
          return {
            data: response?.data,
            pagination: response?.paginator,
          } as PaginatedData<LoadsServiceLoad>;
        })
      );
  }

  getCarrierLoadRecommends(
    searchParameters: { dot: string; radius: number },
    paging: Partial<PageAndSort> = {}
  ): Observable<RecommendedData> {
    const defaultPaging: PageAndSort = { limit: 25, page: 1, sort: 'ASC' };
    const { dot, radius } = searchParameters;
    let page = { ...defaultPaging, ...paging };

    return this.graphqlService
      .query({
        query: GetLoadMatchesByCarrierDot,
        variables: {
          dot,
          radius,
          paging: page,
        },
      })
      .pipe(
        map((result: unknown) => {
          const { getTruckPostRecommendations } = get(result, 'data', {});
          page = {
            ...page,
            total: getTruckPostRecommendations.total,
            totalPages: getTruckPostRecommendations.total / page.limit,
          };
          return this.getRecommendedData(getTruckPostRecommendations.result, page);
        })
      );
  }

  getLoadActivity(loadId: string): Observable<PaginatedData<LoadHistory>> {
    return this.graphqlService
      .query({
        query: GetLoadHistoryByLoadId,
        variables: {
          loadId,
        },
      })
      .pipe(
        map((result: unknown) => {
          const {
            getLoadHistoryByLoadId: { events },
          } = get(result, 'data', {});
          return {
            data: events,
            pagination: null,
          } as PaginatedData<LoadHistory>;
        })
      );
  }

  getRecommendedData(result, page): RecommendedData {
    const paginatedLoads = {
      data: result.map((matches) => matches.loadsServiceLoad),
      pagination: page,
    } as PaginatedData<LoadsServiceLoad>;

    const trucksMap: { [loadId: string]: string[] } = result.reduce((prev, loadMatch) => {
      prev[loadMatch.loadsServiceLoad.id] = loadMatch.truckIds;
      return prev;
    }, {});

    return { loads: paginatedLoads, trucks: trucksMap };
  }

  /**
   * decrypt hashed value of loadId,
   * find specific load info,
   * send filtered data to client
   * @param hash
   */
  getSharedLocationByHash(hash: string): Observable<UnauthenticatedLoadsServiceLoad> {
    const url = `/api/loads/sharedLocation/${hash}`;

    return this.http.get(url).pipe(
      map((response: { load: UnauthenticatedLoadsServiceLoad }) => response.load),
      catchError((error) => throwError(error))
    );
  }

  /**
   * Get the loads location by loadId
   * @param hash
   */
  getTruckLastLoadLocationByLoadIdHash(hash: string): Observable<Location> {
    const url = `/api/loads/sharedLocation/location/${hash}`;
    return this.http.get<Location>(url);
  }

  /**
   * get truck last location by load id
   * @param loadId
   */
  getTruckLastLoadLocationByLoadId(loadId: string): Observable<Location> {
    return this.graphqlService
      .query({
        query: GetLastLoadLocationByLoadId,
        variables: {
          loadId,
        },
      })
      .pipe(
        map((result: { data }) => {
          if (result) {
            return result.data?.getLastLoadLocationByLoadId as Location;
          }
          return null;
        })
      );
  }

  /**
   * Removes the broker offer from the Active Loads offers.
   * @param payload
   */
  deleteOffer(payload: {
    loadId: string;
    carrierDot: string;
    brokerId: string;
    rejectionReason: string;
  }): Observable<{ data; error: { message: string } } | boolean> {
    const { loadId, carrierDot, brokerId, rejectionReason } = payload;
    return this.graphqlService
      .mutate({
        mutation: DeleteOfferMutation,
        variables: {
          loadId,
          carrierDot,
          brokerId,
          rejectionReason,
        },
      })
      .pipe(map((result) => result?.data as boolean));
  }

  deleteMission(orderNumber: string) {
    return this.graphqlService
      .mutate({
        mutation: DeleteMission,
        variables: {
          orderNumber,
        },
      })
      .pipe(map((result) => result?.data as boolean));
  }

  bookLoad(carrierId: string, loadId: string): Observable<{ data; error: { message: string } } | boolean> {
    return this.graphqlService
      .mutate({
        mutation: BookLoad,
        variables: {
          loadId,
          dot: carrierId,
          bookingType: BookingType.APP_WEB,
        },
      })
      .pipe(map((result) => result?.data as boolean));
  }

  /**
   * Delete load
   * @param id
   */
  public deleteLoad(loadId: string): Observable<boolean> {
    return this.graphqlService
      .mutate({
        mutation: DeleteLoadsServiceLoadById,
        variables: {
          loadId,
        },
      })

      .pipe(map((result: { data }) => result?.data?.deleteLoadsServiceLoadById));
  }

  /**
   * update arrval or departure time
   * @param loadId
   * @param loadLocationIndex
   * @param payload
   */
  updateArrivalTimeOrCompleteLoadLocation(
    loadId: string,
    locationIndex: number,
    payload: { arrivalTime?: number; departureTime?: number }
  ): Observable<LoadsServiceLoadLocation> {
    const observables: Observable<LoadsServiceLoadLocation>[] = [];
    if (payload.arrivalTime) {
      observables.push(this.updateLocationArrivalTime(loadId, locationIndex, payload.arrivalTime));
    }
    if (payload.departureTime) {
      observables.push(this.updateLocationDepartureTime(loadId, locationIndex, payload.departureTime));
    }
    if (!observables.length) {
      return throwError({ message: 'A valid arrival time or departure time is required.' });
    }

    return concat(observables, ...observables)[observables.length - 1];
  }

  private updateLocationArrivalTime(
    loadId: string,
    locationIndex: number,
    arrivalTime: number
  ): Observable<LoadsServiceLoadLocation> {
    const analyticsPayload = {
      load_id_str: loadId,
      arrival_time_int: arrivalTime,
      location_index_int: locationIndex,
    };
    this.analyticsService.logEvent(ANALYTICS_EVENT.LOAD_CHECK_IN, analyticsPayload);

    return this.graphqlService
      .mutate({
        mutation: UpdateLocationArrivalTime,
        variables: {
          loadId,
          locationIndex,
          arrivalTime,
        },
      })
      .pipe(map((result) => get(result, 'data.updateLocationArrivalTime', null)));
  }

  private updateLocationDepartureTime(
    loadId: string,
    locationIndex: number,
    departureTime: number
  ): Observable<LoadsServiceLoadLocation> {
    const analyticsPayload = {
      load_id_str: loadId,
      departure_time_int: departureTime,
      location_index_int: locationIndex,
    };
    this.analyticsService.logEvent(ANALYTICS_EVENT.LOAD_CHECK_OUT, analyticsPayload);

    return this.graphqlService
      .mutate({
        mutation: UpdateLocationDepartureTime,
        variables: {
          loadId,
          locationIndex,
          departureTime,
        },
      })
      .pipe(map((result) => get(result, 'data.updateLocationDepartureTime', null)));
  }

  /*
   * Get load template for user
   * @todo replace with graphql query
   */
  getTemplates(userId: string): Observable<LoadTemplate[]> {
    return this.graphqlService
      .query({
        query: GetLoadsServiceLoadTemplatesByUserId,
        variables: {
          userId,
        },
      })
      .pipe(
        map((result: { data }) => {
          return result?.data?.getLoadsServiceLoadTemplatesByUserId as LoadTemplate[];
        })
      );
  }

  /**
   * Create load template
   * @todo replace with graphql query
   * @param template
   */
  createTemplate(templateFields: LoadsServiceLoadInput): Observable<unknown> {
    return this.graphqlService
      .query({
        query: CreateLoadsServiceLoadTemplate,
        variables: {
          templateFields,
        },
      })
      .pipe(
        map((result: { data }) => {
          return result?.data?.createTemplate as LoadTemplate[];
        })
      );
  }

  /**
   * Creates a new loads service load utilizing CreateCarrierLoadMutation
   * @param loadInput
   * @returns load id
   */
  createCarrierLoad(loadInput: Partial<LoadsServiceLoadInput>): Observable<LoadsServiceLoadInput> {
    return this.graphqlService
      .mutate({
        mutation: CreateCarrierLoadMutation,
        variables: { loadInput },
      })
      .pipe(
        map((result: { data }) => {
          return result.data?.createCarrierLoad?.id;
        }),
        catchError((error) => throwError(error))
      );
  }

  /**
   * Searches for loadsServiceLoad by value, which is
   * either a Order number or TWM number.
   * @param value string
   * @returns PaginatedData, LoadsSeviceLoad and pagination
   */
  getLoadByNumber(value: string): Observable<PaginatedData<LoadsServiceLoad>> {
    const orderPayload = {
      alternateIdValue: value,
      alternateIdType: LoadIdentifierType.ORDER_NUMBER,
    };
    const tmwPayload = {
      alternateIdValue: value,
      alternateIdType: LoadIdentifierType.TMW_NUMBER,
    };
    return forkJoin([this.searchLoads(orderPayload), this.searchLoads(tmwPayload)]).pipe(
      map(([orderNumResult, tmwNumberResult]) => {
        const data = concat(orderNumResult.data, tmwNumberResult.data);
        return {
          data,
          pagination: {
            total: data.length,
          },
        };
      })
    );
  }

  /**
   * Get brokered loadsServiceLoads by search params
   * @param searchFields
   * @param paging
   * @return object, entities LoadsServiceLoad array, total number
   */
  getLoadsServiceLoadsBrokered(searchFields: {
    filterParams: ActiveBrokeredLoadsAsLoadsParams;
    paging: Partial<PageAndSort>;
  }): Observable<GraphqlSearchResponse<LoadsServiceLoad>> {
    return this.graphqlService
      .query<{ getLoadsBrokered: { result: LoadsServiceLoad[]; total: number } }>({
        query: GetLoadsBrokered,
        variables: searchFields,
      })
      .pipe(
        map((res) => {
          const result = res.data.getLoadsBrokered.result || [];
          const total = res.data.getLoadsBrokered.total || 0;
          const entities: LoadsServiceLoad[] = result
            .map((item) => item['loadsServiceLoad'])
            .filter((item: LoadsServiceLoad) => item && !!item.locations);

          return { entities, total };
        })
      );
  }

  /*
   * assign load to driver
   * @param variables
   */
  assignLoadServiceLoad(
    payload: AssignLoadPayload
  ): Observable<{ data: AssignLoadResponse; error: { message: string } }> {
    const formattedAssignment: AssignLoadInput = {
      ...payload.assignInput,
      trackingType: payload.assignInput.trackingType ? TrackingType.MACRO_POINT : null,
    };
    return this.graphqlService
      .mutate<AssignLoadResponse>({
        mutation: AssignLoadsServiceLoadMutation,
        variables: {
          loadId: payload.loadId,
          carrierId: payload.carrierId,
          assignInput: formattedAssignment,
        },
      })
      .pipe(
        switchMap((result: { data: { assignLoadsServiceLoad }; error: { message: string } }) => {
          if (!result?.error) {
            return of(result?.data?.assignLoadsServiceLoad);
          } else {
            return throwError(result?.error?.message || 'There was a problem assigning the load service load.');
          }
        })
      );
  }

  /*
   * assign USX load to driver
   * @param variables
   */
  assignUSXLoadServiceLoad(
    payload: AssignUSXITrailerInput
  ): Observable<{ data: NewUSXITrailer; error: { message: string } }> {
    return this.graphqlService.mutate<NewUSXITrailer>({
      mutation: AssignUSXLoadsServiceLoadMutation,
      variables: {
        ...payload,
      },
    });
  }

  /**
   * shareLocation route implement functionality for carrier and broker
   * to share info with user
   * @param loadId
   * @param email
   * @param phone
   */
  public shareLoadLocation(
    loadId: string,
    email: string[] = null,
    phone: string[] = null
  ): Promise<{ message: string; link: string }> {
    if (!loadId) {
      throw new Error('load Id is required argument');
    }

    if (!email && !phone) {
      throw new Error(`invalid request params - email ${email} and phone ${phone}`);
    }

    const body = {
      loadId,
    };

    if (phone) {
      body['phone'] = phone;
    }

    if (email) {
      body['email'] = email;
    }

    return this.http
      .post(`/api/loads/shareLocation`, JSON.stringify(body))
      .toPromise()
      .then((resp: { message: string; link: string }) => resp);
  }

  /**
   * Carrier close load
   * Mark load as completed
   * @todo replace with graphql query
   * @param loadId
   */
  public carrierCloseLoad(loadId: string): Observable<unknown> {
    return this.http.post(`/api/loads/${loadId}/carrier/close`, {});
  }

  /**
   * Get logs for load
   * @todo replace with graphql query
   * @param loadId
   */
  public getLogs(loadId: string) {
    return this.http
      .get('/api/loads/logs/' + loadId)
      .toPromise()
      .then((resp) => resp as unknown[]);
  }

  /**
   * Get load distribution config
   * @todo replace with graphql query
   */
  public getConfig(): Observable<unknown[] | unknown> {
    return this.http.get('/api/loads/admin/config');
  }

  /**
   * Update load distribution config
   * @todo replace with graphql query
   * @param config
   */
  public updateConfig(config: unknown): Promise<unknown> {
    return this.http
      .patch('/api/loads/admin/config', JSON.stringify(config))
      .toPromise()
      .then((resp) => resp);
  }
  /**
   *
   * Get single load route waypoints
   * @param waypoints
   * @param isOverviewFull
   */
  getSingleLoadRoute(waypoints: string, isOverviewFull = true): Observable<unknown> {
    const url = this.environment.mapbox.directionsApi;
    const mapOverview = { overview: isOverviewFull ? MapOverview.FULL : MapOverview.SIMPLIFIED };
    const params = toHttpParams({ ...new mapBoxRequestOptions(this.environment.mapbox.accessToken), ...mapOverview });

    return this.http.get(`${url}/${waypoints}`, { params });
  }

  /**
   * Send offer to carrier
   * @todo replace with graphql query
   * @param payload
   */
  sendOffer(payload: LoadCarrierOffer): Observable<unknown> {
    return this.http.post('/api/loads/projection/broker-send-offer', payload);
  }

  /**
   * Determines if a load has been sent to a particular carrier. * @todo replace with graphql query
   * @param loadId
   * @param dot
   */
  getBrokerBookedLoads(loadId: string, dot: string): Observable<unknown> {
    const params = toHttpParams({ loadId, dot });
    return this.http.get('/api/loads/getBrokerBookedLoads', { params });
  }
  /**
   * Mark a load as completed
   * Not used anywhere
   * @deprecated use or remove
   * @param loadId
   */
  deactivateLoad(loadId: string): Observable<unknown> {
    return this.graphqlService
      .mutate({
        mutation: DeactivateLoad,
        variables: {
          loadId,
        },
      })
      .pipe(map(({ data }: any) => data.deactivateLoad || null));
  }

  /**
   * add dispatch location to load
   * @deprecated use updateLoadManager instead
   * @todo move to new load service load
   * @param loadId
   * @param dispatchLocation
   */
  dispatchLoad(loadId: string, dispatchLocation: DispatchLocationInput): Observable<unknown> {
    return this.graphqlService.mutate({
      mutation: DispatchLoadMutation,
      variables: {
        loadId,
        dispatchLocation,
      },
    });
  }

  /**
   * update load price
   * @deprecated use updateLoadManager instead
   * @todo move to new load service load
   * @param variables
   */
  setBrokerPrice(variables: {
    loadId: string;
    newPrice: number;
    newMaxBuy: number;
    user: string;
    timestamp: number;
  }): Observable<unknown> {
    return this.graphqlService.mutate({
      mutation: BrokerSetPrice,
      variables: variables,
    });
  }

  /**
   * book a load
   * @todo move to new load service
   * @param formData
   */
  bookLoadForCarrier(formData: Partial<BookLoadForCarrier>): Observable<unknown> {
    const { data, dot, loadId, brokerId } = formData;
    const { editLoad } = data;
    const { customerNotes, ...editLoadPayload } = editLoad;

    /**
     * Only return the assignment payload if there is at least one valid value
     */
    const validateAssign = (assignmentInfo: AssignLoadInput): AssignLoadInput | null =>
      some(values(assignmentInfo)) ? assignmentInfo : null;

    /**
     * Only return the dispatch payload if it includes address, lat, lon
     */
    const validateDispatch = (dispatchForm: DispatchForm): DispatchLocationInput | null => {
      const { address = null, timeZone = null, lat = null, lon = null } = dispatchForm.location || {};
      const isInvalid: boolean = !address || !lat || !lon;
      if (isInvalid) return null;
      return {
        address,
        lat,
        lon,
        timezone: timeZone,
        timestamp: this.momentService.getMoment(dispatchForm.timeAvailable, timeZone).valueOf(),
        notes: dispatchForm.notes,
      };
    };
    const paymentItems: PaymentItemCreateInput[] = _map(formData.data.payments, (payment): PaymentItemCreateInput => {
      return {
        amount: Math.abs(+payment.amount),
        paymentType: payment.paymentType.code,
        quantity: payment.paymentType.quantity,
        orderNumber: payment.orderNumber,
        loadId: loadId,
      };
    });
    let filteredForm;
    if (!formData.data.assignmentInfo.driverId && !formData.data.assignmentInfo.secDriverId) {
      filteredForm = {
        trackingType: formData.data.assignmentInfo.trackingType ? TrackingType.MACRO_POINT : null,
        truckId: formData.data.assignmentInfo.truckId,
        phone: formData.data.assignmentInfo.phone,
        contacts: [
          {
            contactName: formData.data.carrierInfo.contact,
            contactPhone: formData.data.carrierInfo.phone,
            contactEmail: formData.data.carrierInfo.email,
          },
        ],
      };
    } else {
      filteredForm = {
        trackingType: formData.data.assignmentInfo.trackingType ? TrackingType.MACRO_POINT : null,
        drivers: formData.data.assignmentInfo.driverId
          ? formData.data.assignmentInfo.secDriverId
            ? [
                {
                  id: formData.data.assignmentInfo.driverId,
                  phone: formData.data.assignmentInfo.phone,
                  priority: 1,
                },
                {
                  id: formData.data.assignmentInfo.secDriverId,
                  phone: formData.data.assignmentInfo.secPhone,
                  priority: 2,
                },
              ]
            : [{ id: formData.data.assignmentInfo.driverId, phone: formData.data.assignmentInfo.phone, priority: 1 }]
          : formData.data.assignmentInfo.secDriverId
          ? [
              {
                id: formData.data.assignmentInfo.secDriverId,
                phone: formData.data.assignmentInfo.secPhone,
                priority: 2,
              },
            ]
          : [],
        truckId: formData.data.assignmentInfo.truckId,
        phone: formData.data.assignmentInfo.phone,
        contacts: [
          {
            contactName: formData.data.carrierInfo.contact,
            contactPhone: formData.data.carrierInfo.phone,
            contactEmail: formData.data.carrierInfo.email,
          },
        ],
      };
    }

    if (formData.data.assignmentInfo.trailerId) {
      filteredForm = { ...filteredForm, trailerId: formData.data.assignmentInfo.trailerId };
    }

    const bookForCarrierLoad: BookCarrierPayload = {
      paymentItems,
      assignInput: validateAssign(filteredForm),
      bookLoad: { dot, loadId },
      brokerId,
      dispatchLocation: validateDispatch(formData.data.dispatchInfo),
      editLoad: {
        ...editLoadPayload,
        internalNotes: editLoadPayload.internalNotes || '',
        externalNotes: editLoadPayload.externalNotes || '',
      },
    };
    return this.bookCarrier(bookForCarrierLoad);
  }

  /**
   * bounce carrier
   * @todo move to new load service
   * @param orderNumber
   */
  bounceCarrier(orderNumber: string): Observable<unknown> {
    return this.graphqlService.mutate({
      mutation: BounceOrderMutation,
      variables: {
        orderNumber: orderNumber,
      },
    });
  }

  getLoadRoute(coordinates: string, isOverviewFull = true): Observable<LoadRouteData> {
    const coordsList: string[] = coordinates.split(';');
    // Mapbox only accepts >=2 && <= 25 coordinates.
    // Sometimes 25 coordinates can return an error, and so we use groups of 24
    const coordsGroupedLists = splitArrayIntoGroups<string>(coordsList, 12, 2);
    const observables: Observable<MapboxRouteData>[] = coordsGroupedLists.map((coordsArray) => {
      return this.getMapboxRoute(coordsArray.join(';'), isOverviewFull);
    });

    return forkJoin(observables).pipe(
      map((responses) => {
        const routes = responses.filter((resp) => resp?.routes?.[0]?.geometry);

        const loadMapRoute: LngLatLike[] = routes.reduce((prev, curr) => {
          const coordinates: LngLatLike[] = Array.isArray(curr.routes[0].geometry.coordinates)
            ? curr.routes[0].geometry.coordinates
            : decodeGeoJSONString(curr.routes[0].geometry);
          return [...prev, ...coordinates];
        }, []);

        const distance = Math.round(routes.reduce((prev, curr) => prev + curr.routes[0].distance, 0));
        const loadMapRemainingDistance = isOverviewFull ? null : distance;
        const loadMapDistance = isOverviewFull ? distance : null;

        const loadWaypoints = flatten(routes.map((resp) => resp.waypoints));

        return {
          loadMapRoute,
          loadMapDistance,
          loadWaypoints,
          loadMapRemainingDistance,
        };
      })
    );
  }

  reverseGeocodeViaMapbox(coordinates: { lon: number; lat: number }): Observable<string> {
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${coordinates.lon},${coordinates.lat}.json`;
    const params = toHttpParams({
      overview: 'simplified',
      access_token: this.environment.mapbox.accessToken,
      language: 'en',
      geometries: 'geojson',
    });
    return this.http.get(url, { params }).pipe(
      map((result: { features: { place_name_en: string } }) => {
        const placeName: string = result?.features?.[0]?.place_name_en;
        const parsed = placeName?.split(', ');
        parsed.pop(); // Remove the country
        return parsed?.join(', ');
      })
    );
  }

  getLoadDocuments(documentParams: Partial<DocumentParamsInput>): Observable<PaginatedData<Document>> {
    return this.graphqlService
      .query<PaginatedData<unknown>>({
        query: GetDocuments,
        variables: {
          documentParams,
        },
      })
      .pipe(
        map((result) => {
          return {
            data: result.data['getDocuments'],
            pagination: {},
          } as PaginatedData<Document>;
        })
      );
  }

  getRecommended(loadId: string, paging: PageAndSort): Observable<PaginatedData<RecommendedCarriers>> {
    const defaultPaging: PageAndSort = { limit: 10, page: 1, sort: 'DESC', order: 'matchPercent' };

    return this.graphqlService
      .query<PaginatedData<unknown>>({
        query: GetRecommendedCarriersByLoadId(),
        variables: {
          loadId: loadId,
          paging: { ...defaultPaging, ...paging },
        },
      })
      .pipe(
        map((result) => {
          const response: PaginatedZipLaneServiceData<unknown> = result.data['getRecommendedCarriersByLoadId'];

          const val = {
            data: response.result,
            pagination: {
              ...response.paginator,
              total: result.data['getRecommendedCarriersByLoadId'].total,
            },
          } as PaginatedData<RecommendedCarriers>;
          return val;
        })
      );
  }

  getOwnedCarriers(carrierOwner: string, paging: PageAndSort): Observable<PaginatedData<RecommendedCarriers>> {
    const defaultPaging: PageAndSort = { limit: 10, page: 1, sort: 'DESC', order: 'matchPercent' };

    return this.graphqlService
      .query<PaginatedData<unknown>>({
        query: GetCarrierByCarrierOwner,
        variables: {
          carrierOwner: carrierOwner,
        },
      })
      .pipe(
        map((result) => {
          const response: PaginatedZipLaneServiceData<unknown> = result.data['getCarrierByCarrierOwner'];

          const val = {
            data: response.result,
            pagination: {
              ...response.paginator,
              total: result.data['getCarrierByCarrierOwner'].total,
            },
          } as PaginatedData<RecommendedCarriers>;
          return val;
        })
      );
  }

  /**
   *
   * @param coordinates a max of 25 coordinates that will produce the route
   * @param isOverviewFull
   * @returns
   */
  private getMapboxRoute(coordinates: string, isOverviewFull: boolean): Observable<MapboxRouteData> {
    const url = this.environment.mapbox.drivingDirectionsApi;
    const mapOverview = { overview: isOverviewFull ? MapOverview.FULL : MapOverview.SIMPLIFIED };
    const params = toHttpParams({
      ...new mapBoxDrivingRequestOptions(this.environment.mapbox.accessToken),
      ...mapOverview,
    });

    return this.http.get<MapboxRouteData>(`${url}/${coordinates}`, { params });
  }

  private bookCarrier(payload: BookCarrierPayload): Observable<unknown> {
    return this.graphqlService.mutate({
      mutation: BookLoadForCarrierMutation,
      variables: {
        assignInput: payload.assignInput,
        brokerId: payload.brokerId,
        bookLoad: payload.bookLoad,
        editLoad: payload.editLoad,
        dispatchLocation: payload.dispatchLocation,
        paymentItems: payload.paymentItems,
      },
    });
  }

  reportRolledLoads(payload: RolledLoads): Observable<LoadsServiceLoad[]> {
    return this.graphqlService
      .mutate({
        mutation: ReportRolledLoadsMutation,
        variables: {
          loadIds: payload.loadIds,
          rolledReason: payload.rolledReason,
        },
      })
      .pipe(map((result) => result.data['addRolledReasonToLoad']));
  }

  getLoadsServiceSearchMetaData(
    searchParameters: LoadServiceSearchParameters,
    histogramField?: LoadsServiceHistogramField,
    histogramInterval?: number
  ): Observable<PaginatedData<LoadsServiceSearchMetaData>> {
    return this.graphqlService
      .query({
        query: GetLoadServiceMetaData,
        variables: {
          searchParameters,
          histogramField,
          histogramInterval,
        },
      })
      .pipe(
        map((result) => {
          return {
            data: result.data['getLoadsServiceSearchMetaData'],
            pagination: null,
          } as PaginatedData<LoadsServiceSearchMetaData>;
        })
      );
  }
  getLoadsBidDetails(loadIds: string[]): Observable<PaginatedData<BidDetails>> {
    return this.graphqlService
      .query({
        query: GetLoadsBidDetails,
        variables: {
          loadIds,
        },
      })
      .pipe(
        map((result) => {
          return {
            data: result.data['getLoadsBidDetails'],
            pagination: null,
          } as PaginatedData<BidDetails>;
        })
      );
  }

  getOrderLineItems(searchCriteria: PaymentItemSearchCriteria): Observable<any> {
    return this.graphqlService
      .query({
        query: GetOrderLineItems,
        variables: {
          searchCriteria,
        },
      })
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }

  validateMissions(missionBatchInput: MissionCreateInput[]): Observable<MissionValidation> {
    return this.graphqlService
      .query<LoadsServiceLoad>({
        query: ValidateMissions,
        variables: {
          missionBatchInput,
        },
      })
      .pipe(
        map((response) => {
          return response?.data['validateMissions'];
        })
      );
  }

  createMissions(missionBatchInput: MissionCreateInput[]): Observable<MissionValidation> {
    return this.graphqlService
      .query<LoadsServiceLoad>({
        query: CreateMissions,
        variables: {
          missionBatchInput,
        },
      })
      .pipe(
        map((response) => {
          return response?.data['validateMissions'];
        })
      );
  }

  bookMissionLoads(missionBookInput: MissionBookInput): Observable<any> {
    let modifiedPayload: Partial<MissionBookInput> = missionBookInput;
    if (missionBookInput.formData.assignmentInfo.trailerOwner === TrailerOwnerType.USXI) {
      modifiedPayload = omit(missionBookInput, ['data.assignmentInfo.trailerId']);
    }
    const bookLoadPayload = this.bookLoadForCarrierHelper(modifiedPayload);
    return this.graphqlService
      .mutate({
        mutation: BookLoadsInMission,
        variables: {
          missionId: missionBookInput.missionId,
          dot: missionBookInput.dot,
          brokerId: missionBookInput.brokerId,
          bookingType: missionBookInput.bookingType,
          loadDetailsInput: [
            {
              loadId: modifiedPayload.loadId,
              editLoad: bookLoadPayload.editLoad,
              paymentItems: bookLoadPayload.paymentItems,
              dispatchLocation: bookLoadPayload.dispatchLocation,
              assignInput: bookLoadPayload.assignInput,
            },
          ],
        },
      })
      .pipe(
        map((response) => {
          return response?.data['bookLoadsInMission'];
        })
      );
  }

  private bookLoadForCarrierHelper(form: Partial<MissionBookInput>): BookCarrierPayload {
    const { formData, dot, loadId, brokerId } = form;
    const { editLoad } = formData;
    const { customerNotes, ...editLoadPayload } = editLoad;

    /**
     * Only return the assignment payload if there is at least one valid value
     */
    const validateAssign = (assignmentInfo: AssignLoadInput): AssignLoadInput | null =>
      some(values(assignmentInfo)) ? assignmentInfo : null;

    /**
     * Only return the dispatch payload if it includes address, lat, lon
     */
    const validateDispatch = (dispatchForm: DispatchForm): DispatchLocationInput | null => {
      const { address = null, timeZone = null, lat = null, lon = null } = dispatchForm.location || {};
      const isInvalid: boolean = !address || !lat || !lon;
      if (isInvalid) return null;
      return {
        address,
        lat,
        lon,
        timezone: timeZone,
        timestamp: this.momentService.getMoment(dispatchForm.timeAvailable, timeZone).valueOf(),
        notes: dispatchForm.notes,
      };
    };
    const paymentItems: PaymentItemCreateInput[] = _map(formData.payments, (payment): PaymentItemCreateInput => {
      return {
        amount: payment.amount,
        paymentType: payment.paymentType.code,
        quantity: payment.paymentType.quantity,
        orderNumber: payment.orderNumber,
      };
    });
    let filteredForm;
    if (!formData.assignmentInfo.driverId && !formData.assignmentInfo.secDriverId) {
      filteredForm = {
        trackingType: formData.assignmentInfo.trackingType,
        truckId: formData.assignmentInfo.truckId,
        phone: formData.assignmentInfo.phone,
        contacts: [
          {
            contactName: formData.carrierInfo.contact,
            contactPhone: formData.carrierInfo.phone,
            contactEmail: formData.carrierInfo.email,
          },
        ],
      };
    } else {
      filteredForm = {
        trackingType: formData.assignmentInfo.trackingType,
        drivers: formData.assignmentInfo.driverId
          ? formData.assignmentInfo.secDriverId
            ? [
                {
                  id: formData.assignmentInfo.driverId,
                  phone: formData.assignmentInfo.phone,
                  priority: 1,
                },
                {
                  id: formData.assignmentInfo.secDriverId,
                  phone: formData.assignmentInfo.secPhone,
                  priority: 2,
                },
              ]
            : [{ id: formData.assignmentInfo.driverId, phone: formData.assignmentInfo.phone, priority: 1 }]
          : formData.assignmentInfo.secDriverId
          ? [
              {
                id: formData.assignmentInfo.secDriverId,
                phone: formData.assignmentInfo.secPhone,
                priority: 2,
              },
            ]
          : [],
        truckId: formData.assignmentInfo.truckId,
        phone: formData.assignmentInfo.phone,
        contacts: [
          {
            contactName: formData.carrierInfo.contact,
            contactPhone: formData.carrierInfo.phone,
            contactEmail: formData.carrierInfo.email,
          },
        ],
      };
    }

    if (formData.assignmentInfo.trailerId) {
      filteredForm = { ...filteredForm, trailerId: formData.assignmentInfo.trailerId };
    }

    const bookForCarrierLoad: BookCarrierPayload = {
      paymentItems,
      assignInput: validateAssign(filteredForm),
      bookLoad: { dot, loadId },
      brokerId,
      dispatchLocation: validateDispatch(formData.dispatchInfo),
      editLoad: {
        ...editLoadPayload,
        internalNotes: editLoadPayload.internalNotes || '',
        externalNotes: editLoadPayload.externalNotes || '',
      },
    };
    return bookForCarrierLoad;
  }
}
