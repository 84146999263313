import { NotificationEventType, NotificationTemplateInterface } from '@haulynx/types';

export const NotificationTemplate: { [key in NotificationEventType]?: NotificationTemplateInterface } = {
  BIDS_EVENT: {
    title: null,
    content: null,
    footer: 'Bidding',
  },
  POSTED_TRUCK_RECOMMENDATION: {
    title: null,
    content: null,
    footer: 'New match',
  },
  BROKER_PROFILE_RECOMMENDATION: {
    title: null,
    content: null,
    footer: 'Repeated Load Match',
  },
  LOAD_BROKER_ASSIGNMENT: {
    title: null,
    content: null,
    footer: 'Broker Assignment',
  },
  LOAD_MATCH: {
    title: null,
    content: null,
    footer: 'Load Match',
  },
};
