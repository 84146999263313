<div class="details-info">
  <div class="details-info-block">
    <div class="price" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      Price:
      <button mat-button class="currency" (click)="signupClick()" data-cy="unauthenticated-view-price">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
          <span>View Price</span>
          <img src="./assets/images/lock.svg" />
        </div>
      </button>
    </div>

    <mat-divider *ngIf="load?.locations?.length"></mat-divider>
    <app-load-timeline [load]="load"></app-load-timeline>
    <mat-divider></mat-divider>
    <div class="way-points" *ngIf="stops">
      {{ stops?.dropOff || 0 }} Drop-Off
      <span class="divider muted">|</span>
      {{ stops?.pickUp || 0 }} Pick-Up
    </div>
    <div class="info-sub-title">
      Equipment:
      <br />
      <span class="content">
        {{ (load?.providerDetails?.equipmentType | fromCamelCase) || 'Unknown' }}
      </span>
    </div>
    <div class="info-sub-title">
      Weight:
      <br />
      <span class="content">
        <ng-container *ngIf="load?.providerDetails?.weight as weight; else noWeight">
          {{ weight | safeNumber: '1.0-0' }}
        </ng-container>
        <ng-template #noWeight> Unknown </ng-template>
      </span>
    </div>
    <div class="info-sub-title">
      Distance:
      <br />
      <span class="content">
        <ng-container *ngIf="load?.paymentDetails?.distanceMiles | safeNumber; else noDistance">
          {{ load?.paymentDetails?.distanceMiles | safeNumber: '1.0-0' }}
        </ng-container>
        <ng-template #noDistance> Unknown </ng-template>
      </span>
    </div>
    <div
      class="info-sub-title"
      *ngIf="
        !!(load | loadAlternateId: loadIdentifierType.BILL_OF_LADING) ||
        (load | loadAlternateId: loadIdentifierType.ORDER_NUMBER)
      "
    >
      Order Number:
      <br />
      <span class="content">
        {{
          (load | loadAlternateId: loadIdentifierType.BILL_OF_LADING) ||
            (load | loadAlternateId: loadIdentifierType.ORDER_NUMBER) ||
            'N/A'
        }}
      </span>
    </div>
    <div class="info-sub-title">
      TMW #
      <br />
      <span class="content">
        {{ (load | loadAlternateId: loadIdentifierType.TMW_NUMBER) || 'N/A' }}
      </span>
    </div>
    <div class="info-sub-title">
      HazMat
      <br />
      <span class="content">
        {{ (load?.restrictions | loadRestrictions: loadsServiceRestrictionTypes.HAZMAT) ? 'Yes' : 'No' }}
      </span>
    </div>
    <div class="info-sub-title">
      Tanker Certification
      <br />
      <span class="content">
        {{
          (load?.restrictions | loadRestrictions: loadsServiceRestrictionTypes.TANKER_CERTIFICATION_REQUIRED)
            ? 'Yes'
            : 'No'
        }}
      </span>
    </div>
    <div
      class="info-sub-title"
      *ngIf="{
        pickupInstructions: load?.restrictions | loadRestrictions: loadsServiceRestrictionTypes.PICKUP_REQUIREMENTS,
        dropOffInstructions: load?.restrictions | loadRestrictions: loadsServiceRestrictionTypes.DROPOFF_REQUIREMENTS
      } as instructions"
    >
      Trailer Instructions
      <br />
      <span class="content">
        <div
          class="instruction-name"
          *ngIf="instructions.pickupInstructions || instructions.dropOffInstructions; else noTrailerInstructions"
        >
          {{ load?.restrictions | loadRestrictions: loadsServiceRestrictionTypes.PICKUP_REQUIREMENTS }} /
          {{ load?.restrictions | loadRestrictions: loadsServiceRestrictionTypes.DROPOFF_REQUIREMENTS }}
        </div>
        <ng-template #noTrailerInstructions> Not available </ng-template>
      </span>
    </div>
    <div *ngIf="notes" class="info-sub-title notes">
      Notes:
      <br />
      <div class="content">
        {{ notes }}
      </div>
    </div>
  </div>
  <div
    class="book-button-wrapper"
    fxLayout="row"
    fxLayoutAlign="center center"
    fxLayoutGap="16px"
    fxLayout.lt-md="column"
    fxLayoutAlign.lt-md="start center"
  >
    <button mat-raised-button class="affirmative" type="button" (click)="signupClick()">Book Load</button>

    <button mat-raised-button class="bid-button" type="button" (click)="signupClick()">Place New Bid</button>
  </div>
</div>
