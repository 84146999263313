<haulynx-dialog-template (closed)="close()" class="setup-rmis-dialog">
  <div title>
    <ng-container *ngIf="complete; else notCompleteTitle">Your Account Is Completely Set Up!</ng-container>
    <ng-template #notCompleteTitle>Don’t Forget to Finish Setting Up Your Account</ng-template>
  </div>
  <div content fxLayout="column" fxLayoutGap="24px">
    <ng-container *ngIf="complete; else notCompleteBody">
      Thanks for taking the time to finish registering with RMIS! We now have everything we immediately need for you to
      book freight and get paid
      <br /><br />
      You have the ability to change these settings at any time by accessing your account page.
      <div class="margin-top">
        <haulynx-white-outline-button text="Continue" (clicked)="completeSetup()"></haulynx-white-outline-button>
      </div>
    </ng-container>
    <ng-template #notCompleteBody>
      You have the opportunity to book freight now if you’d like to head straight into the platform.
      <br /><br />
      Before we can pay you, you will need to complete your carrier registration with RMIS where we will collect
      information on your fleet, your W9 / W8 documentation, payment options, and diversity.
      <br /><br />
      However, you have the choice of completing the RMIS registration process right now, you may choose to do so when
      you book your first load.
      <div
        class="continue margin-top"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="16px"
        (click)="continue()"
      >
        <div class="icon" fxLayout="row" fxLayoutAlign="center center" fxFlex="48px">
          <mat-icon>settings</mat-icon>
        </div>
        <div fxLayout="column" fxLayoutGap="16px" class="container">
          <div class="title" fxLayout="row" fxLayoutAlign="start center">
            Continue Setup <mat-icon>arrow_forward</mat-icon>
          </div>
          <div class="text">You will continue with RMIS and then be directed back to the XT Carrier Platform.</div>
        </div>
      </div>
      <div class="or"><span>or</span></div>
      <div class="margin-top">
        <haulynx-white-outline-button text="Finish Setup Later" (clicked)="close()"></haulynx-white-outline-button>
      </div>
    </ng-template>
  </div>
</haulynx-dialog-template>
