import { CommonModule } from '@angular/common';
import { Component, Input, NgModule } from '@angular/core';
import { MATERIAL_SANITY_CHECKS } from '@angular/material/core';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { materialModules } from './material-modules';

@Component({
  selector: 'mat-icon',
  template: '<span></span>',
})
export class MockMatIcon {
  @Input() svgIcon: any;
  @Input() fontSet: any;
  @Input() fontIcon: any;
}

@NgModule({
  declarations: [MockMatIcon],
  imports: [CommonModule, NoopAnimationsModule, ...materialModules],
  exports: [...materialModules, MockMatIcon],
  providers: [{ provide: MATERIAL_SANITY_CHECKS, useValue: false }],
})
export class MaterialTestingModule {}
