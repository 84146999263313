import { User } from '../generic';
import { Location } from '../location';
import { Load } from './load';

export class LoadRequest {
  public id: string;
  public pickupDate: number;
  public updated: number;
  public location: Location;
  public equipmentTypes: string[];
  public currentLoad: Load;
  public user: User;
  public filters: any;

  constructor() {
    this.location = new Location('', 0, 0);
    this.location.state = 'AZ';
    this.equipmentTypes = ['N/A'];
  }
}
