import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MenuName } from '@haulynx/store';
import { FeatureFlag, HeaderItem, User } from '@haulynx/types';

@Component({
  selector: 'haulynx-sidebar-nav-tabs',
  templateUrl: './sidebar-nav-tabs.component.html',
  styleUrls: ['./sidebar-nav-tabs.component.scss'],
})
export class SidebarNavTabsComponent {
  @Input() headerItems: HeaderItem[];
  @Input() loadsSidenavSub: MatSidenav;
  @Input() user: User;

  @Output() selectedSideItem = new EventEmitter<HeaderItem>();
  @Output() startTour = new EventEmitter();
  @Output() subNavToggle = new EventEmitter<MatSidenav>();

  featureFlag: FeatureFlag = FeatureFlag.WHATS_NEW;

  constructor() {}

  selectedItem(item: HeaderItem, hasChildren: boolean): void {
    this.selectedSideItem.emit(item);
    // if (hasChildren) this.handleSubNav(item);
  }

  whatsNewTour(): void {
    this.startTour.emit();
  }
}
