import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

const RMIS_URL = 'https://rmis.com';

@Component({
  selector: 'haulynx-more-about-rmis-dialog',
  templateUrl: './more-about-rmis-dialog.component.html',
  styleUrls: ['./more-about-rmis-dialog.component.scss'],
})
export class MoreAboutRmisDialogComponent {
  constructor(private dialogRef: MatDialogRef<MoreAboutRmisDialogComponent>) {}

  public close(): void {
    this.dialogRef.close();
  }

  public goToRmis() {
    window.open(RMIS_URL, '_blank');
  }
}
