import { Injectable } from '@angular/core';
import { PermissionsService } from '../../permissions/permissions.service';
import { LoadsServiceLoad, User } from '@haulynx/types';
import { get } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class LoadViewService {
  constructor(private permissionsService: PermissionsService) {}

  userCanViewCreatedLoadDetail(user: User, load: LoadsServiceLoad): boolean {
    if (!load || !user) return false;
    if (this.permissionsService.isBroker() || this.permissionsService.isAdmin()) return true;
    if (this.permissionsService.isCarrierAdmin()) {
      const dot = load?.carrier?.dot ? load?.carrier?.dot === user?.carrier?.dot : false;
      const id = load?.carrier?.id ? load?.carrier?.id === user?.carrier?.id : false;
      return id || dot;
    }
    if (this.permissionsService.isDriver()) return this.userIsLoadCreatorOrAsignee(user, load);
    return false;
  }

  userCanEditLoad(driversArray: User[], user: User, load: LoadsServiceLoad): boolean {
    if (!load || !user) return false;
    if (this.permissionsService.isBroker() || this.permissionsService.isAdmin())
      return this.userIsLoadCreator(user, load);
    if (this.permissionsService.isCarrierAdmin()) return this.driverOrCarrierCreatedLoad(driversArray, user, load);
    if (this.permissionsService.isDriver()) return this.userIsLoadCreator(user, load);
    return false;
  }

  private driverOrCarrierCreatedLoad(driversArray: User[] = [], user: User, load: LoadsServiceLoad) {
    const fullUserList = driversArray?.concat(user);
    const createdLoad = fullUserList.some((listUser) => this.userIsLoadCreator(listUser, load));
    return createdLoad;
  }

  private userIsLoadCreator(user: User, load: LoadsServiceLoad): boolean {
    const check = user?.id ? user.id === load?.createdBy : false;
    return !!(user && load && check);
  }

  private userIsLoadCreatorOrAsignee(user: User, load: LoadsServiceLoad): boolean {
    const check = load?.drivers[0]?.id ? load?.drivers[0]?.id === user?.id : false;
    return this.userIsLoadCreator(user, load) || check;
  }
}
