import { InjectionToken, Provider } from '@angular/core';
import { IAsyncEntityState, SearchView } from '@haulynx/types';
import { USER_PROVIDED_EFFECTS } from '@ngrx/effects';
import { ActionReducer, ActionReducerMap } from '@ngrx/store';
import { SearchViewEntityService } from './search-view-entity.service';

export const SEARCH_VIEW_ENTITY_REDUCERS_TOKEN = new InjectionToken<ActionReducerMap<IAsyncEntityState<SearchView>>>(
  'search views reducers'
);

function getSearchViewEntityReducersFactory(
  searchViewEntityService: SearchViewEntityService
): ActionReducer<IAsyncEntityState<SearchView>> {
  return searchViewEntityService.getReducers();
}

const searchViewEntityReducerProvider: Provider = {
  provide: SEARCH_VIEW_ENTITY_REDUCERS_TOKEN,
  useFactory: getSearchViewEntityReducersFactory,
  deps: [SearchViewEntityService],
};

const searchViewEntityEffectProvider: Provider = {
  provide: USER_PROVIDED_EFFECTS,
  multi: true,
  useValue: [SearchViewEntityService],
};

export const searchViewStateProviders: Provider[] = [searchViewEntityReducerProvider, searchViewEntityEffectProvider];
