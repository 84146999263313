<div class="msi_user">
  <div class="content" *ngIf="isMilestoneTypeLocation">
    <div class="content__badge">
      <mat-icon [svgIcon]="'location-pin-white'"></mat-icon>
    </div>
    <div class="content__info">
      <p>{{ milestone.loadLocationAddress }}</p>
      <p>{{ log?.primaryEvent.latitude }}, {{ log?.primaryEvent.longitude }}</p>
    </div>
  </div>

  <div class="content" *ngIf="isMilestoneTypeTrailer">
    <div class="content__badge">
      <mat-icon [svgIcon]="'haulynx-gps-icon'"></mat-icon>
    </div>
    <div class="content__info">
      <p class="trailer-info">{{ 'Skybitz - Trailer ' + telemetryHistory.trailerNumber }}</p>
    </div>
  </div>

  <div class="content" *ngIf="isMilestoneTypeTrailer">
    <div class="content__badge">
      <mat-icon [svgIcon]="'location-pin'"></mat-icon>
    </div>
    <div class="content__info">
      <p class="trailer-info">
        {{ telemetryLocation + ' ' + telemetryHistory.latitude + ', ' + telemetryHistory.longitude }}
      </p>
    </div>
  </div>

  <div class="content" *ngIf="!isMilestoneTypeLocation && !isMilestoneTypeTrailer">
    <div class="content__badge">
      <mat-icon [svgIcon]="user === 'carrier' ? 'location-pin' : 'location-pin-white'"></mat-icon>
    </div>
    <div class="content__info">
      <p [ngStyle]="{ color: getBackgroundColor }">{{ milestone.loadLocationAddress }}</p>
    </div>
  </div>
  <div class="content" *ngIf="!isMilestoneTypeLocation && !isMilestoneTypeTrailer">
    <div class="content__badge">
      <mat-icon>rule</mat-icon>
    </div>
    <div class="content__info">
      <p [ngStyle]="{ color: getBackgroundColor }">{{ formatSubType | titlecase }}</p>
    </div>
  </div>
  <div class="content" *ngIf="displayTimeIn && !isMilestoneTypeLocation && !isMilestoneTypeTrailer">
    <div class="content__badge">
      <mat-icon>schedule</mat-icon>
    </div>
    <div class="content__info">
      <p [ngStyle]="{ color: getBackgroundColor }">
        {{ diplayTimeInTitle }} {{ log.primaryEvent.timestamp | dateTime: log.primaryEvent.timezone }}
      </p>
    </div>
  </div>
  <div class="content" *ngIf="displayTimeOut && !isMilestoneTypeLocation && !isMilestoneTypeTrailer">
    <div class="content__badge">
      <mat-icon>schedule</mat-icon>
    </div>
    <div class="content__info">
      <p [ngStyle]="{ color: getBackgroundColor }">
        Facility Leave: {{ log.secondaryEvent.timestamp | dateTime: log.secondaryEvent.timezone }}
      </p>
    </div>
  </div>
  <div class="content" *ngIf="displayTimeIn && displayTimeOut && !isMilestoneTypeLocation && !isMilestoneTypeTrailer">
    <div class="content__badge">
      <mat-icon>schedule</mat-icon>
    </div>
    <div class="content__info">
      <p [ngStyle]="{ color: getBackgroundColor }">Time at facility: {{ generateTimeInFacility }}</p>
    </div>
  </div>
</div>
