<div class="header-endbuttons" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
    <button
      mat-icon-button
      (click)="onShowVerifyEmail($event)"
      *ngIf="user && user.isHaulynxAdmin"
      data-test="verify-email-button"
    >
      <mat-icon>email</mat-icon>
    </button>
    <haulynx-header-endbuttons-notifications
      (showNotifications)="onShowNotifications()"
      [totalNotifications]="totalNotifications"
      [user]="user"
    ></haulynx-header-endbuttons-notifications>
    <haulynx-header-endbuttons-help (goToHelp)="onGoToHelp()"></haulynx-header-endbuttons-help>
    <haulynx-header-endbuttons-account-setup
      *ngIf="rmisProps?.showAccountSetup"
      [rmisProps]="rmisProps"
      (complete)="clickCompleted()"
      (continueRmis)="clickContinueRmis()"
    ></haulynx-header-endbuttons-account-setup>
  </div>
  <div>
    <haulynx-header-endbuttons-user-profile
      [headerItems]="headerItems"
      [user]="user"
      (openLogout)="onLogout()"
      (seletedHeaderItem)="headerItemSelected($event)"
    ></haulynx-header-endbuttons-user-profile>
  </div>
</div>
