import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@haulynx/modules';
import { WhiteReadonlyInputComponent } from './white-readonly-input.component';

@NgModule({
  declarations: [WhiteReadonlyInputComponent],
  imports: [CommonModule, MaterialModule, FlexLayoutModule],
  exports: [WhiteReadonlyInputComponent],
})
export class WhiteReadonlyInputModule {}
