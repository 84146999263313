import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LoadFeedDetailsMapModule } from '../load-feed-details/components/load-feed-details-map/load-feed-details-map.module';
import { UnauthenticatedLoadFeedDetailsInfoPaneModule } from '../load-feed-details/components/unauthenticated-load-feed-details-info-pane/unauthenticated-load-feed-details-info-pane/unauthenticated-load-feed-details-info-pane.module';
import { UnauthenticatedLoadDetailsComponent } from './unauthenticated-load-details.component';

@NgModule({
  declarations: [UnauthenticatedLoadDetailsComponent],
  exports: [UnauthenticatedLoadDetailsComponent],
  imports: [CommonModule, FlexLayoutModule, LoadFeedDetailsMapModule, UnauthenticatedLoadFeedDetailsInfoPaneModule],
})
export class UnauthenticatedLoadDetailsModule {}
