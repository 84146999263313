<app-load-feed-search-form (onSearch)="searchLoads($event)"> </app-load-feed-search-form>

<haulynx-load-feed-search-results
  [columns]="columns"
  [loading]="loading"
  [loads]="loads"
  (search)="searchLoads($event)"
  (onClick)="viewLoad($event)"
  (signup)="goToSignup()"
></haulynx-load-feed-search-results>
