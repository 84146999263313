import { addMonths, subMonths, subWeeks } from 'date-fns';
import { Observable } from 'rxjs';
import { exclusionFilters } from '../../loads-service/exclusion-filters';
import { LoadsServiceLoadStatus } from '../../loads-service/loads-service-load';
import { LoadServiceSearchParameters } from '../../loads-service/loads-service-search-parameters';
import { BulkActionPage } from '../bulk-actions/load-bulk-actions.enum';
import { DataTableConfig } from '../data-table/data-table-type';
import { FeatureFlag, FFState } from '../feature-flag';
import { SidebarItem } from '../generic/sidebar';
import { PageAndSort } from '../generic/page-and-sort';
import { User } from '../generic/user';
import { BookStatus } from '../load';
import {
  DEFAULT_ACTIVE_COLUMNS,
  DEFAULT_ACTIVE_COLUMNS_WITH_TRACKING,
  DEFAULT_AVAILABLE_COLUMNS,
  DEFAULT_AWAITING_TRUCK_DETAILS_COLUMNS,
} from '../search-view/default-columns';
import { SearchView } from '../search-view/search-view';
import {
  AppointmentSetFilter,
  BillToFilter,
  BookedByBrokerFilter,
  BrokerFilter,
  BrokerTeamFilter,
  CarrierDOTFilter,
  CXPriorityFilter,
  DeliveryDateFilter,
  DestinationFilter,
  DistanceFilter,
  DriverFilter,
  DropoffRequirementsFilter,
  EquipmentFilter,
  HazmatFilter,
  IBRegionFilter,
  LoadStatusFilter,
  MaxBuyFilter,
  MissionFilter,
  OBRegionFilter,
  OrderFilter,
  OrderTypeFilter,
  OriginFilter,
  PickupDateFilter,
  PickupRequirementsFilter,
  PriceFilter,
  PriorityFilter,
  ReceiverFilter,
  RevenueFilter,
  ShipperFilter,
  TMWFilter,
  TrackingStatusFilter,
  TruckFilter,
  WeightFilter,
} from './models';
import { CXRIdFilter } from './models/CXRIdFilter';
import { ISearchFilter } from './search-filter';
import { LoadsServiceOrderByType, LoadsServiceOrderByTypeDirection } from '../../loads-service';

export type LoadSearchSideBarItem = SidebarItem<
  Partial<LoadServiceSearchParameters>,
  {
    resetParams?: (keyof LoadServiceSearchParameters)[];
    columns?: Array<{ key: string; value: boolean; order?: number }>;
    stickyColumnWidthClosed?: string;
    stickyColumnWidthOpen?: string;
    isCustom?: boolean;
    sort?: PageAndSort;
    /**
     * Other ids that children items can have as "parent" that will map to this SidebarItem
     */
    altIds?: string[];
  }
>;

/**
 *
 * @param view a search view whose parent SidebarItem you seek
 * @param parents all parent sidebar items
 * @returns a sidebar item that represents the parent under which this view should be nested
 */
export const getParentSidebarItem = (view: SearchView, parents: LoadSearchSideBarItem[]): LoadSearchSideBarItem => {
  return parents.find((item) => item.id === view.parent || item.metaData?.altIds?.includes(view.parent));
};

export const defaultSidebarItems = (user: User, featureFlags?: FFState): LoadSearchSideBarItem[] => [
  {
    id: 'all-loads',
    label: 'All Loads',
    data: {},
    metaData: {
      columns: [
        {
          key: 'truck',
          value: false,
        },
        {
          key: 'carrier',
          value: false,
        },
        {
          key: 'bookedBy',
          value: false,
        },
      ],
      stickyColumnWidthClosed: '100px',
      stickyColumnWidthOpen: '100px',
    },
  },
  ...(featureFlags[FeatureFlag.MISSIONS]
    ? [
        {
          id: 'missions',
          label: 'Missions',
          data: { isInMission: true, showTestLoads: false },
          metaData: {
            sort: {
              page: 1,
              order: 'missionId',
              sort: LoadsServiceOrderByTypeDirection.ASC,
              limit: 100,
              // secondaryOrder: {
              //   sort: LoadsServiceOrderByTypeDirection.ASC,
              //   order: LoadsServiceOrderByType.firstAppointmentStart,
              // },
            },
            columns: DEFAULT_AVAILABLE_COLUMNS,
            stickyColumnWidthClosed: '100px',
            stickyColumnWidthOpen: '100px',
          },
        },
      ]
    : []),
  {
    id: 'available',
    label: 'Available',
    children: [
      {
        label: 'All Available Loads',
        data: {
          bookStatus: [BookStatus.BOOKABLE, BookStatus.VIEWABLE],
          notBillTo: exclusionFilters.map((val) => val.key),
        },
        metaData: {
          columns: DEFAULT_AVAILABLE_COLUMNS,
          stickyColumnWidthClosed: '100px',
          stickyColumnWidthOpen: '100px',
        },
      },
      {
        label: 'All Current Loads',
        data: {
          bookStatus: [BookStatus.BOOKABLE, BookStatus.VIEWABLE],
          firstAppointmentStart: subWeeks(new Date(), 1).valueOf(),
          firstAppointmentEnd: addMonths(new Date(), 3).valueOf(),
          notBillTo: exclusionFilters.map((val) => val.key),
        },
        metaData: {
          columns: DEFAULT_AVAILABLE_COLUMNS,
          stickyColumnWidthClosed: '100px',
          stickyColumnWidthOpen: '100px',
        },
      },
      {
        label: 'Current Loads with Appt',
        data: {
          bookStatus: [BookStatus.BOOKABLE, BookStatus.VIEWABLE],
          firstAppointmentStart: subWeeks(new Date(), 1).valueOf(),
          firstAppointmentEnd: addMonths(new Date(), 3).valueOf(),
          firstLocationAppointmentSet: true,
          lastLocationAppointmentSet: true,
          notBillTo: exclusionFilters.map((val) => val.key),
        },
        metaData: {
          columns: DEFAULT_AVAILABLE_COLUMNS,
          stickyColumnWidthClosed: '100px',
          stickyColumnWidthOpen: '100px',
        },
      },
      {
        label: 'High Priority',
        data: {
          bookStatus: [BookStatus.BOOKABLE, BookStatus.VIEWABLE],
          minOperationsPriority: 3,
          maxOperationsPriority: 3,
          notBillTo: exclusionFilters.map((val) => val.key),
        },
        metaData: {
          resetParams: ['minOperationsPriority', 'maxOperationsPriority'],
          columns: DEFAULT_AVAILABLE_COLUMNS,
          stickyColumnWidthClosed: '100px',
          stickyColumnWidthOpen: '100px',
        },
      },
      {
        label: 'Archived',
        data: {
          bookStatus: [BookStatus.BOOKABLE, BookStatus.VIEWABLE],
          firstAppointmentStart: subMonths(new Date(), 12).valueOf(),
          firstAppointmentEnd: subWeeks(new Date(), 1).valueOf(),
          notBillTo: exclusionFilters.map((val) => val.key),
        },
        metaData: {
          columns: DEFAULT_AVAILABLE_COLUMNS,
          stickyColumnWidthClosed: '100px',
          stickyColumnWidthOpen: '100px',
        },
      },
      {
        label: 'Assigned To Me',
        data: {
          bookStatus: [BookStatus.BOOKABLE, BookStatus.VIEWABLE],
          brokerNameOrUsxId: [user.usxId],
          notBillTo: exclusionFilters.map((val) => val.key),
        },
        metaData: {
          columns: DEFAULT_AVAILABLE_COLUMNS,
          stickyColumnWidthClosed: '100px',
          stickyColumnWidthOpen: '100px',
        },
      },
      {
        label: 'Excluded Bill-Tos',
        data: {
          bookStatus: [BookStatus.BOOKABLE, BookStatus.VIEWABLE],
          billTo: exclusionFilters.map((val) => val.key),
        },
        metaData: {
          columns: DEFAULT_AVAILABLE_COLUMNS,
          stickyColumnWidthClosed: '100px',
          stickyColumnWidthOpen: '100px',
        },
      },
    ],
  },
  {
    id: 'active',
    label: 'Active',
    metaData: {
      altIds: ['planned'],
    },
    children: [
      {
        label: 'All Active Loads',
        data: {
          bookStatus: [BookStatus.BOOKED],
          loadStatus: [
            LoadsServiceLoadStatus.UNASSIGNED,
            LoadsServiceLoadStatus.ASSIGNED,
            LoadsServiceLoadStatus.DISPATCHED,
            LoadsServiceLoadStatus.AT_SHIPPER,
            LoadsServiceLoadStatus.PICKED_UP,
            LoadsServiceLoadStatus.AT_RECEIVER,
            LoadsServiceLoadStatus.DELIVERED,
          ],
        },
        metaData: {
          columns: DEFAULT_ACTIVE_COLUMNS_WITH_TRACKING,
          stickyColumnWidthClosed: '1px',
          stickyColumnWidthOpen: '1px',
        },
      },
      {
        label: 'Awaiting Truck Details',
        data: {
          bookStatus: [BookStatus.BOOKED],
          loadStatus: [LoadsServiceLoadStatus.UNASSIGNED],
        },
        metaData: {
          columns: DEFAULT_AWAITING_TRUCK_DETAILS_COLUMNS,
          stickyColumnWidthClosed: '1px',
          stickyColumnWidthOpen: '1px',
        },
      },
      {
        label: 'Ready for Dispatch',
        data: {
          bookStatus: [BookStatus.BOOKED],
          loadStatus: [LoadsServiceLoadStatus.ASSIGNED],
        },
        metaData: {
          columns: DEFAULT_ACTIVE_COLUMNS_WITH_TRACKING,
          stickyColumnWidthClosed: '1px',
          stickyColumnWidthOpen: '1px',
        },
      },
      // Removing for now. Keeping code in case they want it back
      // {
      //   label: 'Late',
      //   data: {
      //     bookStatus: [BookStatus.BOOKED],
      //     loadStatus: [
      //       LoadsServiceLoadStatus.UNASSIGNED,
      //       LoadsServiceLoadStatus.ASSIGNED,
      //       LoadsServiceLoadStatus.DISPATCHED,
      //     ],
      //     firstAppointmentStart: subMonths(new Date(), 12).valueOf(),
      //     firstAppointmentEnd: subDays(new Date(), 1).valueOf(),
      //   },
      //   metaData: {
      //     columns: [
      //       {
      //         key: 'bids',
      //         value: false,
      //       },
      //       {
      //         key: 'assignedBrokers',
      //         value: false,
      //       },
      //       {
      //         key: 'bookedBy',
      //         value: true,
      //       },
      //     ],
      //     stickyColumnWidthClosed: '1px',
      //     stickyColumnWidthOpen: '1px',
      //   },
      // },
      {
        label: 'Dispatched',
        data: {
          bookStatus: [BookStatus.BOOKED],
          loadStatus: [LoadsServiceLoadStatus.DISPATCHED],
        },
        metaData: {
          columns: DEFAULT_ACTIVE_COLUMNS_WITH_TRACKING,
          stickyColumnWidthClosed: '1px',
          stickyColumnWidthOpen: '1px',
        },
      },
      {
        label: 'At Pickup',
        data: {
          bookStatus: [BookStatus.BOOKED],
          loadStatus: [LoadsServiceLoadStatus.AT_SHIPPER],
        },
        metaData: {
          columns: DEFAULT_ACTIVE_COLUMNS,
          stickyColumnWidthClosed: '1px',
          stickyColumnWidthOpen: '1px',
        },
      },
      {
        label: 'In Transit',
        data: {
          bookStatus: [BookStatus.BOOKED],
          loadStatus: [LoadsServiceLoadStatus.PICKED_UP],
        },
        metaData: {
          columns: DEFAULT_ACTIVE_COLUMNS,
          stickyColumnWidthClosed: '1px',
          stickyColumnWidthOpen: '1px',
        },
      },
      {
        label: 'At Dropoff',
        data: {
          bookStatus: [BookStatus.BOOKED],
          loadStatus: [LoadsServiceLoadStatus.AT_RECEIVER],
        },
        metaData: {
          columns: DEFAULT_ACTIVE_COLUMNS,
          stickyColumnWidthClosed: '1px',
          stickyColumnWidthOpen: '1px',
        },
      },
      {
        label: 'Delivered',
        data: {
          bookStatus: [BookStatus.BOOKED],
          loadStatus: [LoadsServiceLoadStatus.DELIVERED],
        },
        metaData: {
          columns: DEFAULT_ACTIVE_COLUMNS_WITH_TRACKING,
          stickyColumnWidthClosed: '1px',
          stickyColumnWidthOpen: '1px',
        },
      },
    ],
  },
  {
    id: 'finalled',
    label: 'Finalled',
    children: [
      {
        label: 'All Finalled Loads',
        data: {
          loadStatus: [LoadsServiceLoadStatus.FINALLED],
        },
        metaData: {
          columns: [
            {
              key: 'status',
              value: false,
            },
            {
              key: 'truck',
              value: false,
            },
            {
              key: 'assignedBrokers',
              value: false,
            },
            {
              key: 'bookedBy',
              value: true,
            },
          ],
          stickyColumnWidthClosed: '100px',
          stickyColumnWidthOpen: '100px',
        },
      },
    ],
  },
  {
    id: 'test-loads',
    label: 'Test Loads',
    featureFlag: FeatureFlag.TEST_LOADS,
    children: [
      {
        label: 'Available Test Loads',
        data: {
          bookStatus: [BookStatus.BOOKABLE, BookStatus.VIEWABLE],
          showTestLoads: true,
          billTo: ['haulynx test'],
        },
        metaData: {
          columns: DEFAULT_AVAILABLE_COLUMNS,
          stickyColumnWidthClosed: '100px',
          stickyColumnWidthOpen: '100px',
        },
      },
      {
        label: 'Active Test Loads',
        data: {
          bookStatus: [BookStatus.BOOKED],
          loadStatus: [
            LoadsServiceLoadStatus.UNASSIGNED,
            LoadsServiceLoadStatus.ASSIGNED,
            LoadsServiceLoadStatus.DISPATCHED,
            LoadsServiceLoadStatus.AT_SHIPPER,
            LoadsServiceLoadStatus.PICKED_UP,
            LoadsServiceLoadStatus.AT_RECEIVER,
            LoadsServiceLoadStatus.DELIVERED,
          ],
          billTo: ['haulynx test'],
          showTestLoads: true,
        },
        metaData: {
          columns: DEFAULT_ACTIVE_COLUMNS,
          stickyColumnWidthClosed: '1px',
          stickyColumnWidthOpen: '1px',
        },
      },
    ],
  },
  ...(featureFlags[FeatureFlag.TEST_LOADS]
    ? [
        {
          id: 'test-missions',
          label: 'Test Missions',
          data: { isInMission: true, showTestLoads: true },
          metaData: {
            sort: {
              page: 1,
              order: 'missionId',
              sort: LoadsServiceOrderByTypeDirection.ASC,
              limit: 100,
              // secondaryOrder: {
              //   sort: LoadsServiceOrderByTypeDirection.ASC,
              //   order: LoadsServiceOrderByType.firstAppointmentStart,
              // },
            },
            columns: DEFAULT_AVAILABLE_COLUMNS,
            stickyColumnWidthClosed: '100px',
            stickyColumnWidthOpen: '100px',
          },
        },
      ]
    : []),
];

export const loadConfigOptions: DataTableConfig = {
  globalFilter: false,
  reorderColumns: false,
  columnVisibility: true,
  reorderRows: false,
  showBulkActions: true,
  sortColumns: false,
  scrollable: false,
  checkBoxSelection: true,
  filterLocally: false,
  showTopPaginator: true,
  showBottomPaginator: false,
  isLaneRate: false,
  bulkActions: BulkActionPage.SEARCH,
  pageAmount: 100,
};

export const loadServiceSearchFilters = (data: {
  brokers: Observable<{ id: string; label: string }[]>;
}): ISearchFilter[] => [
  new AppointmentSetFilter(),
  new BillToFilter(),
  new BookedByBrokerFilter(data.brokers),
  new BrokerFilter(data.brokers),
  new BrokerTeamFilter(),
  new CarrierDOTFilter(),
  // new CommodityFilter(), // Not being used right now, but will later
  new CXPriorityFilter(),
  new DeliveryDateFilter(),
  new DestinationFilter(),
  new DistanceFilter(),
  new DriverFilter(),
  new DropoffRequirementsFilter(),
  new EquipmentFilter(),
  new HazmatFilter(),
  // new HighestBidFilter(), // Not being used right now, but will later
  new LoadStatusFilter(),
  new MaxBuyFilter(),
  new OrderFilter(),
  new OrderTypeFilter(),
  new OriginFilter(),
  new PickupDateFilter(),
  new PickupRequirementsFilter(),
  new PriceFilter(),
  new PriorityFilter(),
  new ReceiverFilter(),
  new OBRegionFilter(),
  new IBRegionFilter(),
  new RevenueFilter(),
  new ShipperFilter(),
  new TMWFilter(),
  new TruckFilter(),
  new TrackingStatusFilter(),
  new WeightFilter(),
  new CXRIdFilter(),
  new MissionFilter(),
];
