import { Pipe, PipeTransform } from '@angular/core';
import { BookStatus, LoadsServiceLoadStatus, transformLoadStatusText } from '@haulynx/types';

@Pipe({
  name: 'transformLoadStatus',
})
export class TransformLoadStatusPipe implements PipeTransform {
  transform(value: LoadsServiceLoadStatus, bookStatus: BookStatus = null): string {
    if (bookStatus === BookStatus.BOOKED) {
      return transformLoadStatusText(value);
    }
    if (value === LoadsServiceLoadStatus.FINALLED) {
      return 'Finalled';
    }
    if (bookStatus === BookStatus.PAUSE) {
      return 'Booked in TMW';
    }

    // If the load is not booked, it is still available
    return 'Available';
  }
}
