import { createSelector } from '@ngrx/store';
import { getLoadPriceState } from './load-price.state';

export const loadPriceSelector = createSelector(getLoadPriceState, (state) => {
  return state.isLoading;
});

export const loadPriceHttpStatusSelector = createSelector(getLoadPriceState, (state) => {
  return state.httpStatus;
});
