import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TableModule } from 'primeng/table';
import { SpinnerModule } from '../spinner/spinner.module';
import { DataTableOverlayComponent } from './data-table-overlay.component';

@NgModule({
  imports: [CommonModule, MaterialModule, OverlayPanelModule, TableModule, PipesModule, SpinnerModule],
  exports: [DataTableOverlayComponent],
  declarations: [DataTableOverlayComponent],
})
export class DataTableOverlayModule {}
