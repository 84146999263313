import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { IDropDown } from '@haulynx/types';
import { aliveWhile, listToArray, mapToArray } from '@haulynx/utils';
import { List, Map } from 'immutable';
import { filter, find, get, isString, toLower, trim } from 'lodash';
import { Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'drop-down',
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropDownComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropDownComponent implements ControlValueAccessor, OnChanges, OnDestroy {
  @ViewChild(MatAutocompleteTrigger, { static: true }) autocomplete: MatAutocompleteTrigger;
  @ViewChild('autocompleteInput', { static: true }) autocompleteInput: ElementRef;
  @Input() data: IDropDown[] | List<undefined> | Map<string, undefined> = [];
  @Input() placeholder = '';
  @Input() displayLabel = 'label';
  @Input() key = 'id';
  @Input() floatLabel = 'never';
  @Input() selected;
  @Input() allowNullValue = false;
  @Input() submitOnBlur = false;
  @Input() templateLabel: TemplateRef<any>;
  @Input() isLoading = false;
  @Output() selectionChange = new EventEmitter();
  @Output() onBlur = new EventEmitter();

  alive = aliveWhile();
  itemSelected = null;
  items = [];
  control: FormControl = new FormControl();
  filteredData = new Subject<any[]>();
  virtualScroll = new VirtualScrollContainer([]);

  propagateChanges: Function = () => {};

  constructor() {
    this.control.valueChanges
      .pipe(
        takeUntil(this.alive),
        debounceTime(50),
        map((value) => this.filterBy(this.items, value))
      )
      .subscribe((items) => {
        this.filteredData.next(items);
        this.virtualScroll = new VirtualScrollContainer(items);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { data, selected } = changes;

    if (data) {
      const items = this.getData(data.currentValue);

      this.items = items;

      const selectedItem = this.updateSelected(this.data, this.selected);
      this.itemSelected = selectedItem || this.itemSelected;
      this.control.patchValue(this.itemSelected);
    }

    if (selected) {
      const selectedItem = this.updateSelected(this.data, selected.currentValue);

      this.itemSelected = selectedItem || null;
      this.control.patchValue(this.itemSelected);
    }
  }

  updateSelected(data, selected) {
    let item;
    const items = this.getData(data);

    if (isString(selected)) {
      item = find(items, { [this.key]: selected });
    } else {
      item = find(items, { [this.key]: get(selected, this.key) });
    }

    return item;
  }

  panelClose() {
    this.control.patchValue(this.itemSelected);
  }

  panelOpen() {}

  filterBy(data: IDropDown[], filterValue: string): IDropDown[] {
    const newData = !filterValue
      ? data
      : filter<IDropDown>(data, (item: IDropDown) => {
          const label = toLower(item[this.displayLabel]);
          const value = toLower(filterValue);
          return label.indexOf(trim(value)) > -1;
        });

    return newData;
  }

  displayFn() {
    return (selected: IDropDown | null): string | null => {
      const label = selected ? selected[this.displayLabel] : '';

      return label;
    };
  }

  inputBlur(event: FocusEvent) {
    if (this.submitOnBlur) {
      const value = event.target['value'];
      this.control.setErrors(null);

      if (!value && !this.allowNullValue) {
        // value was null on blur and that's not allowed
        const item = find(this.items, { [this.key]: this.itemSelected }); // default back to original selection
        this.control.patchValue(item);
      } else if (value && !this.valueIsAnOption(value)) {
        // value wasn't null, but it is not one of the options.
        const errors = { mustSelected: true };

        this.control.setErrors(errors);
      } else {
        this.propagateChanges(value);
        this.selectionChange.emit(value);
        this.onBlur.emit(value);
      }
    }
  }

  select(event: MatAutocompleteSelectedEvent) {
    const row = event.option.value;
    const id = row[this.key];

    this.itemSelected = row;
    this.propagateChanges(id);
    this.selectionChange.emit(id);
  }

  registerOnChange(fn: any): void {
    this.propagateChanges = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) this.control.enable();
    else this.control.disable();
  }

  writeValue(selected: any): void {
    this.selected = selected;

    const selectedItem = this.updateSelected(this.data, selected);

    this.itemSelected = selectedItem || null;
    this.control.patchValue(this.itemSelected);
  }

  getData(data) {
    return List.isList(data) ? listToArray(data) : Map.isMap(data) ? mapToArray(data) : data;
  }

  showPanel(event: Event, auto: MatAutocomplete) {
    this.control.patchValue('');

    if (auto.isOpen) this.autocomplete.closePanel();
    else this.autocomplete.openPanel();
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }

  private valueIsAnOption(value: any): boolean {
    return this.items.map((item) => item[this.key]).indexOf(value) !== -1;
  }
}

class VirtualScrollContainer {
  windowSize: number;
  maxBufferPx: number;
  minBufferPx: number;

  private readonly autoCompleteItemHeight = 48;
  private readonly autoCompleteMaxHeight = 256;

  constructor(items: any[]) {
    this.windowSize = Math.min(this.autoCompleteMaxHeight, items.length * this.autoCompleteItemHeight);
    this.minBufferPx = this.windowSize * 3;
    this.maxBufferPx = this.minBufferPx * 2;
  }
}
