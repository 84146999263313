<form [formGroup]="form" [ngClass]="{ 'preferred-lanes__hide': carrierToolbar, 'preferred-lanes__form': true }">
  <div class="preferred-lanes__block preferred-lanes__block--location">
    <mat-form-field class="app-form-field" appearance="fill" floatLabel="always" hideRequiredMarker>
      <mat-label> *Location </mat-label>
      <app-address-field
        #location
        formControlName="location"
        placeholder="Ex. Phoenix, AZ"
        required
        showOnlyCities="true"
        [countryRestrictions]="countryRestrictions"
        (onPlaceChange)="onLocationSelect()"
        data-test="origin-location"
      >
      </app-address-field>
    </mat-form-field>
  </div>

  <div class="preferred-lanes__block preferred-lanes__block--location-radius">
    <mat-form-field class="app-form-field" appearance="fill" floatLabel="always" hideRequiredMarker>
      <mat-label> *Radius </mat-label>
      <app-drop-down
        #locationRadius
        formControlName="locationRadius"
        displayLabel="label"
        placeholder="Select"
        required
        [data]="radiusList"
        [chooseDefaultOnTab]="true"
        (manualSelect)="onLocationRadiusSelect()"
      >
      </app-drop-down>
    </mat-form-field>
  </div>

  <div class="preferred-lanes__block">
    <mat-form-field class="app-form-field" appearance="fill" floatLabel="always" hideRequiredMarker>
      <mat-label> *Destination </mat-label>
      <app-address-field
        #destination
        showOnlyCities="true"
        formControlName="preferredLocation"
        placeholder="Ex. Anywhere"
        data-test="destination-location"
        required
        [countryRestrictions]="countryRestrictions"
        (onPlaceChange)="onDestinationSelect()"
      ></app-address-field>
    </mat-form-field>
  </div>

  <div class="preferred-lanes__block">
    <mat-form-field class="app-form-field" appearance="fill" floatLabel="always" hideRequiredMarker>
      <mat-label> *Radius </mat-label>
      <app-drop-down
        #destinationRadius
        formControlName="preferredLocationRadius"
        placeholder="Select"
        [data]="radiusList"
        [chooseDefaultOnTab]="true"
        required
      >
      </app-drop-down>
    </mat-form-field>
  </div>

  <div class="preferred-lanes__block">
    <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
      <mat-label> *Pick Up Days </mat-label>
      <haulynx-day-picker
        #daypicker
        formControlName="pickupDays"
        placeholder="Select Day"
        [days]="daysList | async"
        (manualSelect)="onPickupDaySelect($event)"
        (inputBlur)="onPickupDaySelect($event)"
      ></haulynx-day-picker>
    </mat-form-field>
  </div>

  <div class="preferred-lanes__block">
    <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
      <mat-label> Equipment </mat-label>
      <app-drop-down
        #equipment
        formControlName="equipmentType"
        displayLabel="text"
        key="text"
        placeholder="Select"
        [data]="equipmentList"
        [chooseDefaultOnTab]="true"
        (manualSelect)="onEquipmentTypesSelect()"
        (inputBlur)="onEquipmentTypesSelect()"
      >
      </app-drop-down>
    </mat-form-field>
  </div>

  <div class="preferred-lanes__block">
    <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
      <mat-label> Min. Price (optional) </mat-label>
      <input
        #price
        matInput
        currencyMask
        [placeholder]="0 | currency: 'USD':'symbol':'1.2-2'"
        formControlName="price"
        (blur)="onPriceSelect()"
      />
    </mat-form-field>
  </div>

  <div class="preferred-lanes__block preferred-lanes__block--button">
    <button
      #submitButton
      type="submit"
      mat-raised-button
      class="affirmative"
      [disabled]="form.invalid"
      (click)="onSubmit($event)"
      data-test="save-lane-button"
    >
      <mat-icon *ngIf="isLoading; else textLabel">
        <mat-spinner diameter="23"></mat-spinner>
      </mat-icon>
      <ng-template #textLabel>SAVE LANE</ng-template>
    </button>
  </div>
</form>

<form [formGroup]="form" [ngClass]="{ 'ct-post-lane__hide': !carrierToolbar, 'ct-post-lane__form': true }">
  <div class="ct-post-lane__title">Add Preferred Lanes</div>
  <div class="ct-post-lane__block">
    <mat-form-field class="app-form-field primary-field" appearance="fill" floatLabel="always">
      <app-address-field
        #location
        formControlName="location"
        placeholder="Enter Location"
        required
        showOnlyCities="true"
        [countryRestrictions]="countryRestrictions"
        (onPlaceChange)="onLocationSelect()"
        data-test="origin-location"
        [error]="form.get('location')?.errors?.locationIsValid"
      >
      </app-address-field>
    </mat-form-field>

    <mat-form-field class="app-form-field secondary-field" appearance="fill" floatLabel="always">
      <app-drop-down
        #locationRadius
        formControlName="locationRadius"
        displayLabel="label"
        placeholder="Select"
        required
        [data]="radiusList"
        [chooseDefaultOnTab]="true"
        (manualSelect)="onLocationRadiusSelect()"
      >
      </app-drop-down>
    </mat-form-field>
  </div>

  <div class="ct-post-lane__block">
    <mat-form-field class="app-form-field primary-field" appearance="fill" floatLabel="always">
      <app-address-field
        #destination
        showOnlyCities="true"
        formControlName="preferredLocation"
        placeholder="Enter Destination"
        required
        data-test="destination-location"
        [countryRestrictions]="countryRestrictions"
        (onPlaceChange)="onDestinationSelect()"
        [error]="form.get('preferredLocation')?.errors?.locationIsValid"
      ></app-address-field>
    </mat-form-field>

    <mat-form-field class="app-form-field secondary-field" appearance="fill" floatLabel="always">
      <app-drop-down
        #destinationRadius
        formControlName="preferredLocationRadius"
        placeholder="Select"
        required
        [data]="radiusList"
        [chooseDefaultOnTab]="true"
        (manualSelect)="onDestinationRadiusSelect()"
      >
      </app-drop-down>
    </mat-form-field>
  </div>

  <div class="ct-post-lane__block">
    <mat-form-field class="app-form-field time-field" appearance="fill" floatLabel="always">
      <haulynx-day-picker
        #daypicker
        formControlName="pickupDays"
        placeholder="Select Day"
        required
        [days]="daysList | async"
        (manualSelect)="onPickupDaySelect($event)"
        [error]="
          form.get('pickupDays')?.errors?.required && form.get('pickupDays').touched ? 'This field is required' : ''
        "
      ></haulynx-day-picker>
    </mat-form-field>
  </div>

  <div class="ct-post-lane__block">
    <mat-form-field class="app-form-field equipment-field" appearance="fill" floatLabel="always">
      <app-drop-down
        #equipment
        formControlName="equipmentType"
        displayLabel="text"
        key="text"
        placeholder="Select"
        required
        [data]="equipmentList"
        [chooseDefaultOnTab]="true"
      >
      </app-drop-down>
    </mat-form-field>
  </div>

  <div class="ct-post-lane__block ct-post-lane__block--button">
    <button class="ct-post-lane__block__button ct-post-lane__block__button__cancel" (click)="onCancel($event)">
      Cancel
    </button>
    <button
      #submitButton
      type="submit"
      class="ct-post-lane__block__button ct-post-lane__block__button__submit"
      (click)="onSubmit($event)"
    >
      <mat-icon *ngIf="isLoading; else textLabel">
        <mat-spinner diameter="23"></mat-spinner>
      </mat-icon>
      <ng-template #textLabel>SAVE LANE</ng-template>
    </button>
  </div>
</form>
