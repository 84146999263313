<div class="privacyInfo" style="margin-left: 20px; margin-right: 20px; margin-top: 100px">
  <app-tos></app-tos>
  <div class="row">
    <div class="col l12">
      <p>
        <b>Privacy Policy </b>
        <br /><br />
        Last modified: September 15, 2017
        <br /><br />
        Xpress Technologies VALUES YOUR PRIVACY
        <br /><br />
        Please read the following Privacy Policy carefully because it governs your use of the Xpress Technologies, Inc.
        ("Xpress Technologies," "we" or "us") website, the Xpress Technologies Electronic Logging Device (ELD), the
        Xpress Technologies AOBRD device, the Xpress Technologies application, the Xpress Technologies network, and
        other associated Xpress Technologies products. By using our Services, you agree to be bound by this Privacy
        Policy. If you have any questions regarding this Privacy Policy, you may contact us at
        carrierxperience@usxpress.com.
        <br /><br />
        We value your privacy, and we want you to know that your information is handled with care and in accordance with
        applicable laws. Xpress Technologies collects information from you at various points through our website, the
        Xpress Technologies Electronic Logging Device (ELD), the Xpress Technologies AOBRD device, the Xpress
        Technologies application, the Xpress Technologies network, and other associated Xpress Technologies products.
        Xpress Technologies is the sole owner of the information collected on its websites. We will share this
        information with our affiliates, marketing partners, users of the Xpress Technologies network, and other vendors
        contracted to do business on our behalf.
        <br /><br />
        Section I<br />
        Types of Information That Xpress Technologies Collects
        <br /><br />
        (1) Information That You Provide to Xpress Technologies
        <br /><br />
        When a Shipper, Broker, or Carrier creates an account with Xpress Technologies, we collect certain information
        that you provide to us directly. Additional information may be provided by you when you request support form or
        otherwise communicate with Xpress Technologies. This information may include names, addresses, insurance
        policies, driver license information, vehicle registration information, payment information, or any other
        information that we request and you choose to provide.
        <br /><br />
        (2) Information We Collect Through Your Use of the Xpress Technologies Network
        <br /><br />
        In order to create an efficient load distribution network, Xpress Technologies collects precise, real-time
        information regarding the location of specific tractors, trailers, and commercial drivers. We also collect
        information regarding the hours of service of the commercial driver operating a specific tractor-trailer. We
        also collect certain information regarding bills of lading, the origin and delivery points of loads, and other
        information about loads.
        <br /><br />
        (3) Information Collected Regarding Usage of the Xpress Technologies Website and Related Applications
        <br /><br />
        Xpress Technologies collects information on our websites and applications regarding how visitors interact with
        our website and applications, preferences established by users, and setting created. In some cases this is done
        through the use of cookies, tags, or similar device identification technologies that create and maintain unique
        identifiers.
        <br /><br />
        Xpress Technologies may use third party providers to collect and record data on the use and performance of our
        websites during your visit (“Usage Data”), which may include without limitation the pages you view, the search
        terms you enter, your IP address, and personal information that you submit through the website. Usage Data may
        be collected by Xpress Technologies or its third party provider through “cookies”, log files and other
        technology. Xpress Technologies uses this information to administer its websites and to improve the services
        provided to you. Xpress Technologies may share information collected through our websites with suppliers to
        provide services for us or as required by law or court order. Xpress Technologies may also share Usage Data in
        an aggregate statistic format with a third party; such statistics would not include personal information.
        <br /><br />
        Xpress Technologies may not respond to “do not track” beacons, tags or other such signals including
        browser-based prompts or controls. You may also receive tracking mechanisms from other parties prior to visiting
        our websites that will continue to track your activity while on our websites. Such data would be subject to the
        privacy policy of the other party from whom you received the mechanism, and we do not control or receive data
        from such mechanisms. For more information on how to manage advertising controls including tracking mechanisms,
        please visit www.networkadvertising.org/managing/opt_out.asp.
        <br /><br />
        A cookie is a piece of data stored on the user's hard drive containing information about the user. Usage of a
        cookie is in no way linked to personal information, until a user provides that information (see below). If a
        user rejects or deletes the cookie, he/she may still use our site. The only drawback to this is that the user
        will be limited in some areas of our site. For example, the user will not be able to receive notification of
        special offers, or participate in any contests that may take place. Cookies also enable us to track and
        aggregate clickstream behavior data. By doing this we can better target the interests of our users and enhance
        their experience on our site.
        <br /><br />
        Xpress Technologies also maintains standard web logs that record data about all visitors to our websites. These
        logs may contain: (1) The Internet domain from which you access the website; (2) Your IP address (Your computer
        is automatically assigned an IP address when you surf the Internet; (3) Your IP address is either static or
        dynamic. A static IP address may be identifiable to you; a dynamic address is usually not identifiable to you;
        (4) The type of browser and operating system you use; (5) The date and time you visited the website; (6) The
        pages you viewed on the website; (6) The address of the website from which you linked.
        <br /><br />
        Xpress Technologies uses web log and clickstream information to help us design our site, to identify popular
        site features and to make the site more useful for visitors. Web logs are recorded in sequential files, and we
        normally do not attempt to identify individuals from these logs. However, we may use web logs to identify
        persons attempting to break into, disrupt, or damage our Site. We may share web log information with law
        enforcement personnel if we believe evidence exists, or is likely to occur, of a violation of federal, state or
        local laws or regulations.
        <br /><br />
        Xpress Technologies advertising at other non-Xpress Technologies sites may be serviced by Xpress Technologies
        service providers, who may use cookies to collect information about your visits to those sites. Information
        collected may include the pages visited, date and time, browser information and/or IP address. We normally make
        no attempt to link this information to you personally, except under circumstances described above. This
        information is gathered in order to help us customize and target our advertising to the needs and interests of
        our customers. If you wish to opt out of this tracking at our advertising at third party sites, please visit the
        following sites: Network Advertising or Omniture.
        <br /><br />
        (4) Information From Other Sources
        <br /><br />
        We may also receive information from other sources and combine that with information we collect through the
        Services. This may include information obtained directly from the Federal Motor Carrier Safety Administration
        regarding commercial carriers and drivers, third-party providers of insurance and other information relating to
        carriers, and information obtained from Shippers, Brokers, or Carriers.
        <br /><br />
        In addition, we may gather additional information from any other services you choose to link to your account.
        For example, if you choose to link to another application that users the Xpress Technologies API we may receive
        information regarding your interaction and usage of that application.
        <br /><br />
        Section II<br />
        Xpress Technologies’s Use of Information
        <br /><br />
        Xpress Technologies uses the information we collect to provide the Xpress Technologies Services. This includes
        maintaining and improving the Services, facilitating load distribution, facilitating payments, sending important
        information to, and between, Shippers, Brokers, and Carriers, developing new services and features, providing
        customer support, authenticating users, and providing important product updates. Xpress Technologies also uses
        the information to track the status and location of individual units and drivers in the Xpress Technologies
        Network in order to facilitate the efficient distribution of loads and to track the progress and estimate the
        delivery time of loads that have been assigned.
        <br /><br />
        Xpress Technologies will also use the information to make you aware of products, services, promotions, and
        events that may be of interest to you. This includes personalizing services and providing third party
        advertisements and offers that may be of value to you. Whenever we do this we will take appropriate measures to
        protect your personal information in accordance with this Privacy Policy.
        <br /><br />
        Section III<br />
        Sharing of Information
        <br /><br />
        (1) Sharing With Other Users
        <br /><br />
        We will share certain information with other users to facilitate the efficient distribution and tracking of
        loads. This may include information regarding the specific unit and driver assigned to a specific load, the
        location of the unit, and the estimated time it will arrive at a pick up or delivery location. We may also share
        information with other people when you request us to do so, or in connection with the provision of a product,
        service or promotional offering made by Xpress Technologies or a third party.
        <br /><br />
        (2) Sharing With the General Public
        <br /><br />
        We will share information with the general public if you choose to create a profile designed to be made
        generally available to the public.
        <br /><br />
        (3) Sharing With Third Parties
        <br /><br />
        We will share information with third parties at your direction, or when you choose to integrate our Services
        with those of third parties.
        <br /><br />
        We may also share information with Xpress Technologies affiliated companies for data integration purposes.
        <br /><br />
        We may also share information with vendors, consultants, marketing partners, and other service providers who
        need access to such information to carry out work on our behalf.
        <br /><br />
        We will share information in direct response to a competent legal authority if we believe disclosure is in
        accordance with, or is otherwise required by, any applicable law, regulation, ordinance, license, operating
        agreement, or legal process. This includes law enforcement officials, government authorities, or other third
        parties if we believe your actions are inconsistent with our user agreements or policies, or to protect the
        rights, property, or safety of Xpress Technologies or others.
        <br /><br />
        We will share information if we otherwise notify you and you consent to the sharing; and, in an aggregated or
        anonymous form which cannot reasonably used to identify you.
        <br /><br />
        We may share information to allow others to provide audience measurement and analytics services for us, to serve
        advertisements on our behalf across the Internet, and to track and report on the performance of those
        advertisements. These entities may use cookies, web beacons, SDKs, and other technologies to identify your
        device when you visit our site and use the Services, as well as when you visit other online sites and services.
        For more information about these technologies and service providers, please refer to cookie policy.
        <br /><br />

        Section IV<br />
        Your Right to Make Choices Regarding Your Data
        <br /><br />
        If you choose to cancel your account we may retain certain information about you as required by law, or for
        legitimate business purposes to the extent permitted by law. For instance, if you have a standing credit or debt
        on your account, or if we believe you have committed fraud or violated our terms of service, we may seek to
        resolve the issue before deleting your information.
        <br /><br />
        Xpress Technologies will delete the personal data it stores at your request in accordance with applicable law.
        <br /><br />
        Xpress Technologies requires precise location data and information regarding a driver’s hours of service to
        efficiently distribute loads through the Xpress Technologies network. If you do not want us to collect this
        information we cannot provide you with the Services.
        <br /><br />
        You may decide to opt out of receiving promotional messages from us by following the instructions in those
        messages. If you opt out, we may still send you non-promotional communications, such as those about your
        account, about Services you have requested, or our ongoing business relations.
        <br /><br />
        Section V<br />
        Website Security
        <br /><br />
        Xpress Technologies takes all reasonable precautions to protect information transmitted through our websites,
        both while the information is being transmitted and after it is received by us. Once your information has been
        received by us, we use security procedures and policies commensurate with industry standards to safeguard
        against loss, theft, unauthorized access, destruction, use, modification and disclosure. If you have any
        questions about the security of our websites, you can send an email to carrierxperience@usxpress.com.
        <br /><br />
        Section VI<br />
        Links to Other Websites
        <br /><br />
        This Xpress Technologies website may contain links to other websites. Please be aware that Xpress Technologies
        is not responsible for the privacy practices of such other websites. We encourage you to be aware when you leave
        our site and to read the privacy statements of each website that collects personal information. This privacy
        statement applies solely to information collected by websites controlled and operated by Xpress Technologies.
        <br /><br />
        Section VII<br />
        Information Transmitted Over Wireless Networks
        <br /><br />
        Because certain communications and information collected from your vehicle is provided through wireless and
        satellite networks, we cannot promise or guarantee that the communications cannot be intercepted by others. You
        agree that Xpress Technologies will not be liable for any damages for any loss of privacy occurring in
        communication over such networks.
        <br /><br />
        Section VIII<br />
        Children’s Information
        <br /><br />
        Xpress Technologies does not knowingly allow anyone under the age of 13 to participate in any services offered
        on our website which requires the submission of user information. If we learn that a user is under 13 and has
        submitted user information to us, we will delete that information promptly.
        <br /><br />
        Section IV<br />
        Changes to This Privacy Policy
        <br /><br />
        Xpress Technologies reserves the right to update its Privacy Policy from time to time. If we do, we will post
        those changes to this page so you are always aware of what information we collect, how we use it, and under what
        circumstances we disclose it. We will use, share, and disclose all information in accordance with the Privacy
        Policy in effect at the time the information was collected. If a material change is made, we will post a notice
        on the Privacy Policy page of our website along with the updated Privacy Policy or, where required by law,
        obtain your consent. You should periodically check for notices and updates to our Privacy Policy and terms of
        service because your use of Xpress Technologies websites or services after any such updates have been made,
        whether or not you have reviewed the updates, constitutes your acceptance.
        <br /><br />
        Section X<br />
        Contacting Xpress Technologies
        <br /><br />
        If you have any questions regarding the terms set forth in this privacy policy, you may contact us at
        carrierxperience@usxpress.com.
        <br /><br />
        You may also write to us at:
        <br /><br />

        Xpress Technologies<br />
        1375 N Scottsdale Rd<br />
        Suite 370<br />
        Scottsdale, AZ 852579<br />
        <br /><br />
        In addition, you may call us at: (602) 325-1240
        <br /><br />
      </p>
    </div>
  </div>
</div>
