import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PayLineCalculatedPrice } from '@haulynx/types';

@Component({
  selector: 'app-pay-line-price',
  templateUrl: './pay-line-price.component.html',
  styleUrls: ['./pay-line-price.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PayLinePriceComponent implements OnChanges {
  @Input() revenue: number = null;
  @Input() total: number = null;
  @Input() price: number = null;

  calculatedPrice: PayLineCalculatedPrice = null;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    const { revenue, total, price } = changes;
    const currentRevenue = (revenue && revenue.currentValue) || this.revenue;
    const currentTotal = (total && total.currentValue) || this.total;
    const currentPrice = (price && price.currentValue) || this.price;

    if (currentRevenue || total || price) {
      const margin = currentRevenue - currentTotal;

      this.calculatedPrice = {
        revenue: currentRevenue,
        margin,
        marginPercent: margin / currentRevenue,
        maxBuy: currentPrice,
        maxBuyDiff: currentPrice - currentTotal,
      };
    }
  }
}
