import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { VirtualTableSortDialogComponent } from './virtual-table-sort-dialog.component';
import { MaterialModule } from '@haulynx/modules';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [VirtualTableSortDialogComponent],
  imports: [CommonModule, FlexLayoutModule, FormsModule, MaterialModule],
  exports: [VirtualTableSortDialogComponent],
})
export class VirtualTableSortDialogModule {}
