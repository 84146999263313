import { Injectable } from '@angular/core';
import { LoadsServiceService, NotificationsService } from '@haulynx/services';
import { AsyncDataManager, AsyncMutationManager, MissionBookInput, MissionCreateInput } from '@haulynx/types';
import { findReason } from '@haulynx/utils';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AppState } from '../../main-store/app.reducers';
import { AsyncEntityBase } from '../../main-store/async-entity/async-entity-base.class';
import { missionEntityNamespace } from './namespace';

@Injectable({ providedIn: 'root' })
export class MissionEntityService extends AsyncEntityBase<any> {
  validateMissionsManager: AsyncDataManager<{ missionBatchInput: MissionCreateInput[] }, any>;
  createMissionsManager: AsyncMutationManager<{ missionBatchInput: MissionCreateInput[] }, any>;
  bookMissionsManager: AsyncMutationManager<{ missionBookInput: MissionBookInput }, any>;

  constructor(
    protected actions$: Actions,
    protected store: Store<AppState>,
    private loadsServiceService: LoadsServiceService,
    private notifications: NotificationsService
  ) {
    super(actions$, store, missionEntityNamespace);

    this.validateMissionsManager = this.createAsyncData('validate mission loads', (searchPayload) =>
      this.loadsServiceService.validateMissions(searchPayload.missionBatchInput).pipe(
        map((validation) => {
          return { ...validation, errors: null };
        }),
        catchError((error) => {
          this.notifications.error(error.message, `Error: `);
          const reason = findReason(error?.message);
          const err = reason.length ? { [reason]: true } : null;

          const validation = { valid: false, errors: err, missions: [] };
          return of(validation);
        })
      )
    );

    this.createMissionsManager = this.createAsyncMutation('create mission loads', (searchPayload) =>
      this.loadsServiceService.createMissions(searchPayload.missionBatchInput).pipe(
        tap(
          () => {
            this.notifications.success(`New mission(s) successfully created`, `Load`);
          },
          (error) => this.notifications.error(`Oops, something went wrong. Unable to create new mission(s)`, error)
        )
      )
    );

    this.bookMissionsManager = this.createAsyncMutation('book mission loads', (searchPayload) =>
      this.loadsServiceService.bookMissionLoads(searchPayload.missionBookInput).pipe(
        tap(
          () => {
            this.notifications.success(`Mission Successfully Booked`, `Mission`);
          },
          (error) => this.notifications.error(`Oops, something went wrong. Unable to book mission loads.`, error)
        )
      )
    );
  }
}
