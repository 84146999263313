import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, ValidationErrors } from '@angular/forms';
import { MomentService } from '@haulynx/services';
import {
  AddressField,
  IPostTruck,
  RadiusOption,
  radiusOptions,
  EquipmentTypeOption,
  equipmentTypes,
  LaneForm,
  WeekDays,
  CapacityObject,
} from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { BehaviorSubject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DayPickerComponent } from '../day-picker/day-picker.component';
import { AppDropDownComponent } from '../drop-down/components/app-drop-down/app-drop-down.component';
import { AppAddressFieldComponent } from '../google-address-field/components/app-address-field.component';

@Component({
  selector: 'haulynx-preferred-lanes-form',
  templateUrl: './preferred-lanes-form.component.html',
  styleUrls: ['./preferred-lanes-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreferredLanesFormComponent implements AfterViewInit, OnChanges, OnInit {
  @ViewChild('location', { static: true }) location: AppAddressFieldComponent;
  @ViewChild('locationRadius', { read: ElementRef, static: true }) locationRadius: ElementRef;
  @ViewChild('destination', { static: true }) destination: AppAddressFieldComponent;
  @ViewChild('destinationRadius', { read: ElementRef, static: true }) destinationRadius: ElementRef;
  @ViewChild('daypicker', { static: true }) daypicker: DayPickerComponent;
  @ViewChild('equipment', { static: true }) equipment: AppDropDownComponent;
  @ViewChild('price', { read: ElementRef, static: true }) price: ElementRef;
  @ViewChild('submitButton', { read: ElementRef, static: true }) submitButton: ElementRef;

  @Input() carrierToolbar: boolean;
  @Input() isLoading: boolean;
  @Input() pickupDays: string[];
  @Input() defaultRadius: number;
  @Input() defaultPreferredLocation: string;
  @Input() defaultEquipment: string;
  @Input() defaultLane: unknown;
  @Input() laneToPopulate: CapacityObject;
  @Input() countryRestrictions: string[];

  @Output() saveLane = new EventEmitter<LaneForm>();
  @Output() cancelForm = new EventEmitter<boolean>();

  private alive = aliveWhile();
  form: FormGroup;
  radiusList: RadiusOption[] = radiusOptions;
  equipmentList: EquipmentTypeOption[] = equipmentTypes;
  daysList = new BehaviorSubject<WeekDays[]>([]);

  constructor(private fb: FormBuilder, private cdRef: ChangeDetectorRef, private momentService: MomentService) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.form = this.fb.group({
      location: [null, validateLocation],
      locationRadius: [this.defaultRadius, Validators.required],
      preferredLocation: [null, validateLocation],
      preferredLocationRadius: [this.defaultRadius, Validators.required],
      pickupDays: [this.pickupDays, Validators.required],
      equipmentType: [this.defaultEquipment, this.carrierToolbar ? Validators.required : null],
      price: [null],
    });
  }

  onLocationSelect(): void {
    this.cdRef.detectChanges();
    this.locationRadius.nativeElement.firstChild.children[0].focus();
  }

  onLocationRadiusSelect(): void {
    this.destination.addressElement.nativeElement.focus();
  }

  onDestinationSelect(): void {
    this.destinationRadius.nativeElement.firstChild.children[0].focus();
  }

  onDestinationRadiusSelect(): void {
    this.daypicker.inputFeild.nativeElement.focus();
  }

  onPickupDaySelect(e: Event): void {
    this.form.patchValue({
      pickupDays: e,
    });
    this.equipment.autocompleteInput.nativeElement.focus();
  }

  onEquipmentTypesSelect(): void {
    this.price.nativeElement.focus();
  }

  onPriceSelect(): void {
    this.submitButton.nativeElement.focus();
  }

  onCancel(e: Event): void {
    e.preventDefault();
    this.cancelForm.emit(true);
  }

  onSubmit(e: Event): void {
    e.preventDefault();
    this.form.markAllAsTouched();

    if (this.form.valid) {
      const lane: LaneForm = this.form.getRawValue();

      this.saveLane.emit(lane);
      this.resetForm();
    }
  }

  resetForm(): void {
    this.form.reset({
      location: null,
      locationRadius: this.defaultRadius,
      preferredLocation: null,
      preferredLocationRadius: this.defaultRadius,
      pickupDays: [],
      equipmentType: this.defaultEquipment,
      price: 0,
    });
    this.daysList.next([]);
    this.focusOnLocation();
  }

  ngAfterViewInit(): void {
    timer(0)
      .pipe(takeUntil(this.alive))
      .subscribe(() => this.focusOnLocation());
  }

  focusOnLocation(): void {
    this.location.addressElement.nativeElement.focus();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { laneToPopulate, defaultPreferredLocation, defaultLane } = changes;

    if (defaultPreferredLocation) {
      const preferredLocation = defaultPreferredLocation.currentValue;

      if (this.form) {
        this.form.patchValue({ preferredLocation });
      }
    }

    if (defaultLane?.currentValue) {
      this.form.patchValue({
        locationRadius: defaultLane.currentValue.locationRadius,
        location: defaultLane.currentValue.location,
        preferredLocation: defaultLane.currentValue.preferredLocation,
        preferredLocationRadius: defaultLane.currentValue.preferredLocationRadius,
        equipmentType: defaultLane.currentValue.equipmentType,
      });
    }

    if (laneToPopulate && this.form) {
      const truck: IPostTruck = laneToPopulate.currentValue;

      if (truck) {
        const {
          locationLat,
          locationLon,
          location,
          timeZone,
          timeAvailable,
          equipmentType,
          preferredLocation,
          preferredLocationLat,
          preferredLocationLon,
          preferredLocationTimeZone,
          radius,
          preferredRadius,
        } = truck;

        this.form.patchValue({
          location: {
            lat: locationLat,
            lon: locationLon,
            address: location,
            timeZone,
          },
          timeAvailable: this.momentService.getMoment(timeAvailable),
          preferredLocation: {
            lat: preferredLocationLat,
            lon: preferredLocationLon,
            address: preferredLocation !== 'None' ? preferredLocation : null,
            timeZone: preferredLocationTimeZone,
          },
          preferredLocationLat,
          preferredLocationLon,
          equipmentType,
          locationRadius: radius ? radius : this.defaultRadius,
          preferredLocationRadius: preferredRadius ? preferredRadius : this.defaultRadius,
        });
      } else {
        this.resetForm();
      }
    }
  }
}

function validateLocation(ctrl: FormControl): ValidationErrors | null {
  const location: AddressField = ctrl.value || {};
  const { address, lat, lon } = location;
  const isValid = !!(address && lat && lon);

  return isValid ? null : { locationIsValid: 'This field is required' };
}
