import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'haulynx-white-readonly-input',
  templateUrl: './white-readonly-input.component.html',
  styleUrls: ['./white-readonly-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WhiteReadonlyInputComponent {
  @Input() icon: string;
  @Input() value: string;
}
