import { KeyValuePair } from '@haulynx/types';

export const issuesList: KeyValuePair[] = [
  {
    key: 'General Location Update',
    value: 'LOCATION',
  },
  {
    key: 'Breakdown',
    value: 'BREAKDOWN',
  },
  {
    key: 'Lumper',
    value: 'LUMPER',
  },
  {
    key: 'OS&D',
    value: 'OSD',
  },
  {
    key: 'Service Failure',
    value: 'SERVICE_FAILURE',
  },
  {
    key: 'TONU/Layover',
    value: 'TONU',
  },
];

export const statusList: KeyValuePair[] = [
  {
    key: 'Incomplete',
    value: 'INCOMPLETE',
  },
  {
    key: 'Detention',
    value: 'DETENTION',
  },
];
