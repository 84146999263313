import { NotificationEvent } from '@haulynx/types';
import { createAction, createActionTypes } from '../../shared/store/helpers/action';
import { NotificationEventFilterParams } from './notification.state';

const TYPE = '[APP NOTIFICATION]';

export interface INotificationActions {
  deactivateNotifications(params: { creatorId: string; notificationIds: string[] });
  deactivateNotificationsSuccess();
  deactivateNotificationsError();
  hideNotification();
  showNotification();
  search(params?: NotificationEventFilterParams);
  searchSuccess(params: { entities: NotificationEvent[] | unknown[] });
  searchError(params?);
  setAvailableNotificationTypes(types: string[]);
  refresh(params?: NotificationEventFilterParams);
}

export interface INotificationActionType {
  SHOW_NOTIFICATION: string;
  HIDE_NOTIFICATION: string;
  DEACTIVATE_NOTIFICATIONS: string;
  DEACTIVATE_NOTIFICATIONS_SUCCESS: string;
  DEACTIVATE_NOTIFICATIONS_ERROR: string;
  SEARCH_NOTIFICATION: string;
  SEARCH_NOTIFICATION_SUCCESS: string;
  SEARCH_NOTIFICATION_ERROR: string;
  SET_AVAILABLE_NOTIFICATION_TYPES: string;
  REFRESH: string;
}

export const notificationActionTypes: INotificationActionType = {
  SHOW_NOTIFICATION: 'show notification',
  HIDE_NOTIFICATION: 'hide notification',
  DEACTIVATE_NOTIFICATIONS: 'deactivate notifications',
  DEACTIVATE_NOTIFICATIONS_SUCCESS: 'deactivate notifications success',
  DEACTIVATE_NOTIFICATIONS_ERROR: 'deactivate notifications error',
  SEARCH_NOTIFICATION: 'search notification',
  SEARCH_NOTIFICATION_SUCCESS: 'search notification success',
  SEARCH_NOTIFICATION_ERROR: 'search notification error',
  SET_AVAILABLE_NOTIFICATION_TYPES: 'set available notification types',
  REFRESH: 'refresh',
};

export const notificationActions: INotificationActions = {
  hideNotification: createAction(TYPE, notificationActionTypes.HIDE_NOTIFICATION),
  showNotification: createAction(TYPE, notificationActionTypes.SHOW_NOTIFICATION),
  deactivateNotifications: createAction(TYPE, notificationActionTypes.DEACTIVATE_NOTIFICATIONS),
  deactivateNotificationsSuccess: createAction(TYPE, notificationActionTypes.DEACTIVATE_NOTIFICATIONS_SUCCESS),
  deactivateNotificationsError: createAction(TYPE, notificationActionTypes.DEACTIVATE_NOTIFICATIONS_ERROR),
  search: createAction(TYPE, notificationActionTypes.SEARCH_NOTIFICATION),
  searchSuccess: createAction(TYPE, notificationActionTypes.SEARCH_NOTIFICATION_SUCCESS),
  searchError: createAction(TYPE, notificationActionTypes.SEARCH_NOTIFICATION_ERROR),
  setAvailableNotificationTypes: createAction(TYPE, notificationActionTypes.SET_AVAILABLE_NOTIFICATION_TYPES),
  refresh: createAction(TYPE, notificationActionTypes.REFRESH),
};

export const NotificationActions: INotificationActions = notificationActions;
export const NotificationActionTypes: INotificationActionType = createActionTypes(notificationActionTypes)(TYPE);
