<div class="chip-input">
  <mat-form-field appearance="outline" floatLabel="always" [hideRequiredMarker]="!required">
    <mat-label>{{ label }}</mat-label>
    <input
      class="grey-text-input"
      matInput
      required="{{ required }}"
      placeholder="{{ placeholder ? placeholder : label }}"
      mask="{{ mask }}"
      [pattern]="pattern"
      [step]="step"
      [type]="type"
      [formControl]="control"
      [attr.tabindex]="tabindex"
      [attr.maxlength]="maxlength"
      [attr.minlength]="minlength"
      [autocomplete]="autocomplete"
      [disabled]="disabled"
      [ngClass]="{ 'shift-right': prefix }"
    />
    <button
      class="clear-button"
      *ngIf="showClearButton && inputControl?.value"
      mat-icon-button
      type="reset"
      (click)="clear()"
    >
      <mat-icon>highlight_off</mat-icon>
    </button>
    <div data-cy="input-prefix" class="prefix" *ngIf="prefix" fxLayout="row" fxLayoutAlign="center center">
      <div>{{ prefix }}</div>
    </div>
    <mat-hint>
      <ng-content select="[mat-hint]"></ng-content>
    </mat-hint>
    <mat-hint align="end">
      <ng-content select="[mat-hint-end]"></ng-content>
    </mat-hint>
    <mat-error>
      <ng-content select="[mat-error]"></ng-content>
    </mat-error>
  </mat-form-field>
</div>
