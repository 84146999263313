import { InjectionToken, Provider } from '@angular/core';
import { IAsyncEntityState, LoadsServiceLoad } from '@haulynx/types';
import { USER_PROVIDED_EFFECTS } from '@ngrx/effects';
import { ActionReducer, ActionReducerMap } from '@ngrx/store';
import { LoadEntityService } from './load-entity.service';

export const LOAD_ENTITY_REDUCERS_TOKEN = new InjectionToken<ActionReducerMap<IAsyncEntityState<LoadsServiceLoad>>>(
  'load entity reducers'
);

function getLoadEntityReducersFactory(
  loadsEntityService: LoadEntityService
): ActionReducer<IAsyncEntityState<LoadsServiceLoad>> {
  return loadsEntityService.getReducers();
}

const loadEntityReducerProvider: Provider = {
  provide: LOAD_ENTITY_REDUCERS_TOKEN,
  useFactory: getLoadEntityReducersFactory,
  deps: [LoadEntityService],
};

const loadEntityEffectProvider: Provider = {
  provide: USER_PROVIDED_EFFECTS,
  multi: true,
  useValue: [LoadEntityService],
};

export const loadStateProviders: Provider[] = [loadEntityReducerProvider, loadEntityEffectProvider];
