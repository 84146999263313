import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  ActionButtonsModule,
  DataTableModule,
  SpinnerModule,
  UserFormComponent,
  UsersComponentModule,
} from '@haulynx/components';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { UserVmService } from '@haulynx/services';
import { UsersStoreModule } from '@haulynx/store';
import { NgxMaskModule } from 'ngx-mask';
import { UserSearchComponent } from './user-search/user-search.component';
import { UsersRoutingModule } from './users-routing.module';

@NgModule({
  imports: [
    CommonModule,
    UsersRoutingModule,
    UsersStoreModule,
    DataTableModule,
    FormsModule,
    ActionButtonsModule,
    NgxMaskModule.forRoot(),
    MaterialModule,
    UsersComponentModule,
    SpinnerModule,
    PipesModule,
  ],
  declarations: [UserSearchComponent],
  exports: [UserFormComponent],
  providers: [UserVmService],
})
export class UsersModule {}
