import { ISearchFilter, TextFilterType } from '../search-filter';
import { ISearchFilterType } from '../search-filter.types';

export class DateRangeFilter implements ISearchFilter {
  name = 'Date Range';
  type: ISearchFilterType = ISearchFilterType.TEXT;
  keys: TextFilterType;
  description = 'Date range for lane history';

  constructor(defaultValues?: TextFilterType) {
    const options = Object.values(LaneHistoryDateRangeKey).map((value) => {
      return {
        key: value,
        value: value,
      };
    });
    this.keys = new TextFilterType({
      value: '',
      optionsDataIndex: options,
      textFormName: 'dateRange',
      ...defaultValues,
    });
  }
}

export enum LaneHistoryDateRangeKey {
  MONTH_1 = '30 day window',
  MONTH_3 = '90 day window',
  MONTH_12 = 'Year to date',
}
