import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Bid, CounterOfferStatus, FeatureFlag, LoadIdentifierType, LoadsServiceLoad } from '@haulynx/types';
import { round } from 'lodash';

@Component({
  selector: 'app-bid-counter-offer',
  templateUrl: './bid-counter-offer.component.html',
  styleUrls: ['./bid-counter-offer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BidCounterOfferComponent implements OnInit, AfterViewInit {
  @ViewChild('priceInput', { static: true }) priceInput: ElementRef;
  @Input() bid: Bid;
  @Input() load: LoadsServiceLoad;
  @Input() userTimeZone: string;
  @Output() openHistory = new EventEmitter<Bid>();
  @Output() done = new EventEmitter();
  loadIdentifierType = LoadIdentifierType;
  bidV2Feature: FeatureFlag = FeatureFlag.BROKER_BIDDING_V2;
  form: FormGroup;
  private get minAmount(): number {
    return Math.max(
      (this.bid.activeCounterOffer && this.bid.activeCounterOffer.price) || 0,
      this.load?.paymentDetails?.price || 0
    );
  }

  private get maxAmount(): number {
    return round(this.bid.price - 0.01, 2);
  }

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    const { id, carrierId, price } = this.bid;

    this.form = this.fb.group({
      carrierId: [carrierId],
      bidId: [id],
      price: [price, [Validators.min(this.minAmount), Validators.max(this.maxAmount), Validators.required]],
      notes: [''],
      status: [CounterOfferStatus.PENDING],
      finalOffer: false,
    });
  }

  getPriceErrorMessage(): string {
    if (this.form.get('price').getError('minlength') || this.form.get('price').getError('min')) {
      return `Make an offer greater than $${this.minAmount - 0.01}`;
    } else if (this.form.get('price').getError('max')) {
      return `Make an offer less than $${round(this.maxAmount + 0.01, 2)}`;
    }
  }

  ngAfterViewInit(): void {
    this.priceInput.nativeElement.select();
  }

  onOpenHistory(bid: Bid): void {
    this.openHistory.emit(bid);
  }

  onDone(): void {
    this.done.emit(this.form.value);
  }

  onChange(event) {
    this.form.get('finalOffer').patchValue(event.checked);
  
  }
}
