<div fxLayout="row" fxLayoutAlign="center center" fxFlexFill class="dot-signup-container">
  <div
    fxLayout="row"
    fxLayoutAlign="center center"
    [attr.fxFlex.gt-sm]="fromDialog ? '' : '840px'"
    fxFlex.lt-md="95"
    ngClass.lt-md="lt-md"
    ngClass.xs="extra-small"
  >
    <div fxLayout="column" fxLayoutAlign="center center" fxFlexFill>
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        fxFlexFill
        fxLayoutGap="32px"
        fxLayoutGap.xs="16px"
        class="welcome"
        [ngClass.gt-sm]="{ 'margin-top': fromDialog === false }"
      >
        <span>Welcome To</span>
        <img src="assets/images/Haulynx-Powered-by-XT-White.png" alt="Haulynx" />
      </div>

      <div fxLayout="row" fxLayoutAlign="start center" class="intro-text">
        Thanks for joining your new community – built by truckers for truckers. We’re really glad you’re here. We cannot
        wait to get you started with your first load.
      </div>

      <mat-card fxFlexFill class="card">
        <!-- Carrier Data Found -->
        <form *ngIf="!($loading | async) && !carrierNotFound" [formGroup]="signupForm" (ngSubmit)="signup()">
          <div class="carrier-information" fxLayout="column" fxLayoutGap="16px" fxLayoutGap.lt-md="8px">
            <div fxLayout="row" fxLayoutGap="16px" fxLayoutGap.lt-md="8px">
              <span class="key-text">Carrier Name:</span>
              <span class="value-text">{{ carrierInfo?.carrierName }}</span>
            </div>
            <div fxLayout="row" fxLayoutGap="16px" fxLayoutGap.lt-md="8px">
              <span class="key-text">DOT:</span>
              <span class="value-text">{{ carrierInfo?.dotNumber }}</span>
            </div>
            <div fxLayout="row" fxLayoutGap="16px" fxLayoutGap.lt-md="8px">
              <span class="key-text">MC:</span>
              <span class="value-text">{{ carrierInfo?.mcNumber }}</span>
            </div>
            <div fxLayout="row" fxLayoutGap="16px" fxLayoutGap.lt-md="8px">
              <span class="key-text">Domicile:</span>
              <span class="value-text">{{ carrierInfo?.domicileCity }}, {{ carrierInfo?.domicileState }}</span>
            </div>
          </div>

          <div class="review-for-accuracy">
            Please review the above information for accuracy and create your login and password
          </div>

          <div class="inputs">
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.lt-md="16px" fxLayoutGap="24px">
              <mat-form-field appearance="outline" floatLabel="always" fxFlex="50">
                <mat-label>Email</mat-label>
                <input matInput placeholder="Email" formControlName="email" />
              </mat-form-field>
              <mat-form-field appearance="outline" floatLabel="always" fxFlex="50">
                <mat-label>Phone Number</mat-label>
                <input mask="000-000-0000" matInput placeholder="Phone Number" formControlName="phone" />
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.lt-md="16px" fxLayoutGap="24px">
              <mat-form-field appearance="outline" floatLabel="always" fxFlex="50">
                <mat-label>Password</mat-label>
                <input matInput placeholder="Password" type="password" formControlName="password" />
                <mat-error *ngIf="signupForm.get('password').hasError('required')"> Password is required </mat-error>
                <mat-error *ngIf="signupForm.get('password').hasError('minlength')">
                  Password must be at least 5 characters
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline" floatLabel="always" fxFlex="50">
                <mat-label>Confirm Password</mat-label>
                <input matInput placeholder="Confirm Password" type="password" formControlName="passwordConfirm" />
                <mat-error *ngIf="signupForm.get('passwordConfirm').hasError('required')">
                  Password is required
                </mat-error>
                <mat-error *ngIf="signupForm.get('passwordConfirm').hasError('mismatchedPasswords')">
                  Passwords must match
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="submit">
            <button
              mat-raised-button
              color="accent"
              fxLayout="row"
              fxLayoutAlign="center center"
              fxFlexFill
              [disabled]="signupForm.invalid || submitting"
              type="submit"
            >
              <span *ngIf="!submitting">Submit</span>
              <mat-spinner *ngIf="submitting" [diameter]="24" [strokeWidth]="4"></mat-spinner>
            </button>
          </div>
        </form>

        <!-- No Carrier Data Found -->
        <div *ngIf="!($loading | async) && carrierNotFound">
          <div class="carrier-information">
            <span class="info-text">The provided DOT is not valid or we could not find your information</span>
          </div>
        </div>

        <!-- Loading -->
        <div *ngIf="$loading | async">
          <div class="carrier-information" fxLayout="row" fxLayoutAlign="center center" fxFlexFill>
            <mat-spinner [diameter]="40" [strokeWidth]="5"></mat-spinner>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>
