export enum Category {
  DOT_SIGNUP = 'DOT Signup',
  GLOBAL = 'Global Shortcut',
  NAVIGATION = 'Navigation',
  LOCAL = 'Local Shortcut',
  REFERRAL = 'Referral',
  XPM_LINK = 'XPM Link',
}

export enum Action {
  ALREADY_EXISTS = 'Account Already Exists',
  AUTOMATIC_LOAD_FEED_SEARCH = 'Automatic Load Feed Search',
  CLICK = 'Click',
  DOT_NOT_PROVIDED = 'DOT Not Provided',
  KEYPRESS = 'Key Press',
  SIGN_UP_ERROR = 'Sign Up Error',
  SIGN_UP_SUCCESS = 'Sign Up Success',
  USE_GO_RMIS = 'Use Go RMIS Data',
  USE_NONE = 'No Information Found',
  USE_SAFER_WATCH = 'User Saferwatch Data',
}

export enum Label {
  COMPOSE_EMAIL = 'Compose Email',
  COPY = 'Copy',
}
