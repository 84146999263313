<table class="price-table">
  <tr class="header">
    <th>Revenue</th>
    <th>Margin $</th>
    <th>Margin %</th>
    <th>Max Buy</th>
    <th>Max Buy Diff.</th>
  </tr>
  <tr class="value">
    <td data-test="revenue">{{ calculatedPrice?.revenue | currency: 'USD':'symbol' }}</td>
    <td data-test="margin">{{ calculatedPrice?.margin | currency: 'USD':'symbol' }}</td>
    <td data-test="margin-percent">{{ calculatedPrice?.marginPercent | percent: '1.0-1' }}</td>
    <td data-test="max-buy">{{ calculatedPrice?.maxBuy | currency: 'USD' }}</td>
    <td data-test="max-buy-diff">{{ calculatedPrice?.maxBuyDiff | currency: 'USD':'symbol' }}</td>
  </tr>
</table>
