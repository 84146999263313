import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BidVmService, NotificationsService } from '@haulynx/services';
import {
  Bid,
  BidHistory,
  BidSortTypes,
  BidSourceType,
  BidStatusOption,
  BidStatusType,
  Carrier,
  FeatureFlag,
  FFState,
  LoadsServiceLoad,
  UpdateBidInput,
  User,
} from '@haulynx/types';
import { dedicatedFreightIsBookable } from '@haulynx/utils';
import { isNumber, orderBy } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { AppDropDownComponent } from '../../../drop-down/components/app-drop-down/app-drop-down.component';

@Component({
  selector: 'app-bid-item-v1',
  templateUrl: './bid-item-v1.component.html',
  styleUrls: ['./bid-item-v1.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BidItemV1Component implements OnChanges {
  @ViewChild(AppDropDownComponent, { static: true }) dropDown: TemplateRef<AppDropDownComponent>;

  @Input() bid: Bid;
  @Input() isActive = false;
  @Input() bidHistory: BidHistory[] = [];
  @Input() disabled = false;
  @Input() load: LoadsServiceLoad;
  @Input() bidStatuses: BidStatusOption[] = [];
  @Input() isLoadingHistory = false;
  @Input() isLoadingAccept = false;
  @Input() isLoadingCounterOffer = false;
  @Input() user: User;
  @Input() userIsBroker = true;
  @Input() userTimeZone: string;
  @Input() features: FFState;

  @Output() dataChange = new EventEmitter<{ id: string; carrier: Partial<Carrier> } & UpdateBidInput>();
  @Output() openHistory = new EventEmitter<Bid>();
  @Output() getHistory = new EventEmitter<Bid>();
  @Output() acceptBid = new EventEmitter<Bid>();
  @Output() openCounterOffer = new EventEmitter<Bid>();

  bidForm: FormGroup;
  currentBidPrice: number;
  statusToAccept = BidStatusType.LIVE;
  bidMessageData$ = new BehaviorSubject<BidMessage>({ doShow: false });
  bidStatusOptions$ = new BehaviorSubject<BidStatusOption[]>([]);

  constructor(private bidVmService: BidVmService, private notification: NotificationsService) {
    this.bidForm = this.bidVmService.createFormGroup(this.bid);
  }

  sortBids(bids: Bid[], sortedBy: BidSortTypes): Bid[] {
    const order = sortedBy === BidSortTypes.RECENT ? 'desc' : 'asc';

    return orderBy(bids, [sortedBy.toLowerCase()], [order]);
  }

  onDataChange(bidForm: Bid, fieldName: keyof UpdateBidInput): void {
    if (bidForm.status === BidStatusType.ACCEPTED) {
      this.onAcceptBid({ ...this.bid, ...bidForm });
    } else {
      const price: number = this.bidForm.controls['price'].value;
      const priceIsValid: boolean = isNumber(price) && price > 0;
      if (priceIsValid) {
        if (fieldName === 'price' && price === this.currentBidPrice) return;
        this.currentBidPrice = price;
        this.dataChange.emit({
          id: this.bid.id,
          carrier: this.bid.carrier,
          [fieldName]: bidForm[fieldName],
        });
      }
    }
  }

  onAcceptBid(bid: Bid): void {
    if (dedicatedFreightIsBookable(this.load, this.features)) {
      this.acceptBid.emit(bid);
    } else {
      this.notification.error('You are not permitted to book dedicated freight', 'Unauthorized');
      this.bidForm.patchValue({
        status: this.bid.status,
      });
      this.bidStatusOptions$.next([...this.bidStatusOptions$.value]);
    }
  }

  onOpenHistory(bid: Bid): void {
    this.openHistory.emit(bid);
  }

  onGetHistory(bid: Bid): void {
    this.getHistory.emit(bid);
  }

  onEnter(event: KeyboardEvent): void {
    event.stopImmediatePropagation();
    this.dropDown['autocompleteInput'].nativeElement.focus();
  }

  onOpenCounterOffer(event: MouseEvent, bid: Bid): void {
    event.stopImmediatePropagation();
    this.openCounterOffer.emit(bid);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { bid } = changes;

    if (bid) {
      this.currentBidPrice = bid.currentValue.price;
      this.bidVmService.setValue(this.bidForm, this.bid);
    }
    const currentUserType: BidSourceType = this.user?.broker ? BidSourceType.BROKER : BidSourceType.CARRIER;
    const sourceMatchesUser: boolean = currentUserType === this.bid.sourceType;
    const isBrokerAdmin: boolean = this.user?.broker && this.user?.isCompanyAdmin;

    this.bidStatusOptions$.next(this.bidStatuses);
    if (this.disabled) {
      this.bidForm.disable();
    } else if (isBrokerAdmin && (bid.currentValue.sourceType !== 'broker' || bid.currentValue.activeCounterOffer)) {
      this.bidStatusOptions$.next(
        this.bidStatuses.filter((bs) => [BidStatusType.LIVE, BidStatusType.NO_CAPACITY].includes(bs.id))
      );
      this.bidForm.enable();
      this.bidForm.get('notes').disable();
      this.bidForm.get('price').disable();
    } else if (sourceMatchesUser) {
      this.bidForm.enable();
    } else {
      this.bidForm.disable();
    }

    this.bidMessageData$.next(this.createBidMessageData(this.bid, this.bidHistory));
  }

  private createBidMessageData(bid: Bid, bidHistory: BidHistory[]): BidMessage {
    if (!bid || !bidHistory) {
      return { doShow: false };
    }
    if (this.mostRecentBidAction(bid, bidHistory) === 'broker') {
      if (bid.activeCounterOffer) {
        return {
          text: 'Last: Counter Offer Sent for',
          color: 'yellow',
          isNew: false,
          price: bid.activeCounterOffer.price,
          doShow: true,
        };
      } else {
        return { doShow: false };
      }
    } else if (bid.status === BidStatusType.PENDING || bid.status === BidStatusType.LIVE) {
      // carrier made the last action
      return {
        text: 'Carrier has submitted a new offer',
        color: 'green',
        isNew: true,
        doShow: true,
      };
    } else {
      return { doShow: false };
    }
  }

  private mostRecentBidAction(bid: Bid, bidHistory: BidHistory[] = []): 'carrier' | 'broker' {
    const mostRecentEvent: BidHistory = bidHistory.reduce((prev, curr) => {
      if (!prev) {
        return curr;
      }
      return curr.createdAt > prev.createdAt ? curr : prev;
    }, null);

    if (mostRecentEvent) {
      return mostRecentEvent.createdBy.carrier ? 'carrier' : 'broker';
    } else {
      return 'broker';
    }
  }
}

interface BidMessage {
  text?: string;
  color?: 'yellow' | 'green';
  isNew?: boolean;
  price?: number;
  doShow: boolean;
}
