import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-invite-component',
  templateUrl: './invite-component.component.html',
  styleUrls: ['./invite-component.component.scss'],
})
export class InviteComponentComponent {
  public email: string;
  public phone: string;

  constructor(public dialogRef: MatDialogRef<InviteComponentComponent>, private http: HttpClient) {}

  // TODO: We need to handle errors
  send() {
    this.http
      .post('/api/users/invite', {
        email: this.email,
        phone: this.phone,
      })
      .toPromise();

    this.dialogRef.close(true);
  }
}
