import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'haulynx-skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  styleUrls: ['./skeleton-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkeletonLoaderComponent implements OnChanges {
  @Input() align: 'left' | 'center' | 'right' = 'left';
  @Input() pixelHeight = 25;
  @Input() percentageWidth = 100;
  @Input() marginBottom = 0;

  public alignment: 'flex-start' | 'center' | 'flex-end' = 'flex-start';

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    const { align = null, pixelHeight = null, percentageWidth = null, marginBottom = null } = changes;

    if (align?.currentValue) {
      const value = align.currentValue;
      this.alignment = value === 'center' ? 'center' : value === 'right' ? 'flex-end' : 'flex-start';
    }

    if (pixelHeight?.currentValue == null) {
      this.pixelHeight = 25;
    }

    if (percentageWidth?.currentValue == null) {
      this.percentageWidth = 100;
    }

    if (marginBottom?.currentValue == null) {
      this.marginBottom = 0;
    }
  }
}
