import { ChartDataSets } from 'chart.js';
import { LoadsServiceLoadLocation, LoadsServiceProviderDetails } from '../loads-service';
import { Broker, Carrier } from '../web-interfaces';

export interface LaneHistorySearchParameters {
  originLat: number | string;
  originLon: number | string;
  originLocation?: string;
  originRadiusMiles?: number | string;
  destinationLat: number | string;
  destinationLon: number | string;
  destinationLocation?: string;
  destinationRadiusMiles?: number | string;
  startDate: string;
  endDate: string;
  laneRadius: number;
  carrierNameOrDot: string;
  minPrice?: number;
}

export class LaneHistorySearchParameters {
  searchParameters?: LaneHistorySearchParameters;
  includeBids?: boolean;
  constructor(props?: Partial<LaneHistorySearchParameters>) {
    Object.assign(this, props);
  }
}

export interface LaneHistoryRow {
  carrier: Carrier;
  bidId?: string;
  loadId: string;
  createdAt?: number;
  paymentDetails: {
    price?: number;
    priceLastUpdatedAt?: number;
    bookedAt?: number;
    revenue?: number;
    avgCarrierRateDiff?: number;
  };
  providerDetails: Partial<LoadsServiceProviderDetails>;
  broker: Partial<Broker>;
  locations: LoadsServiceLoadLocation[];
  status: string[];
  tmwNumber: string;
  date: number;
}

export interface LaneHistoryGraphDataType {
  rate: {
    loads: ChartDataSets;
    bids: ChartDataSets;
    loadsAverage: ChartDataSets;
    bidsAverage: ChartDataSets;
  };
  margin: {
    loads: ChartDataSets;
    bids: ChartDataSets;
    loadsAverage: ChartDataSets;
    bidsAverage: ChartDataSets;
  };
  revenue: {
    loads: ChartDataSets;
    bids: ChartDataSets;
    loadsAverage: ChartDataSets;
    bidsAverage: ChartDataSets;
  };
}

export interface LaneHistoryLocations {
  locations: LaneHistoryLocation[];
}

export interface LaneHistoryLocation {
  address: string;
  appointmentSet: boolean;
  appointmentStart: number;
  appointmentEnd: number;
  timezone: string;
}
