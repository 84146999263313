import { Injectable } from '@angular/core';
import { CreateDriverMutation, CreateUnregisteredDriverMutation } from '@haulynx/gql';
import { DriverAssignment, FetchResult } from '@haulynx/types';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CarriersService } from '../carrier/carriers.service';
import { GraphqlService } from '../graphql.service';

@Injectable({
  providedIn: 'root',
})
export class DriversService {
  constructor(private carriersService: CarriersService, private graphqlService: GraphqlService) {}

  createUnregisteredDriver(variables: DriverAssignment): Observable<FetchResult> {
    const carrierId$: Observable<string> =
      variables.carrierId && variables.carrierId[0] === '-'
        ? of(variables.carrierId)
        : this.carriersService
            .getCarrierByDot(variables.carrierDot)
            .pipe(map((carrier) => (carrier ? carrier.id : variables.carrierDot)));

    return carrierId$.pipe(
      switchMap((carrierId) => {
        if (variables.email) {
          return this.graphqlService
            .mutate({
              variables: {
                name: variables.name,
                phone: variables.phone,
                email: variables.email,
                enrollmentStatus: 'driver_lite',
                carrier: {
                  id: carrierId,
                  dot: variables.carrierDot,
                  name: variables.carrierName,
                },
              },
              mutation: CreateDriverMutation,
            })
            .pipe(catchError((errors) => throwError(errors)));
        }

        return this.graphqlService.mutate({
          variables: {
            name: variables.name,
            phone: variables.phone,
            carrierId: carrierId,
          },
          mutation: CreateUnregisteredDriverMutation,
        });
      })
    );
  }
}
