import { Injectable } from '@angular/core';
import { LOAD_FEED_RECOMMENDED_URL } from '@haulynx/types';
import { LoadFeedFireDatabaseService } from '../../app-services/generic/load-feed-fire-database.service';

@Injectable({
  providedIn: 'root',
})
export class LoadFeedService {
  constructor(private loadFeedFireDatabaseService: LoadFeedFireDatabaseService) {}

  getRecommendedLoadFeeds(carrierId) {
    return this.loadFeedFireDatabaseService.getList(`${LOAD_FEED_RECOMMENDED_URL}/${carrierId}`);
  }
}
