import {
  AsyncBulkMutationManager,
  AsyncDataDictionaryManager,
  AsyncDataManager,
  AsyncDestroyMutationManager,
  AsyncMutationManager,
  AsyncQueryManager,
  AsyncSearchManager,
  IAsyncEntityState,
  MutationManagerOptions,
  PageAndSort,
  PaginatedData,
} from '@haulynx/types';
import { Actions, ofType } from '@ngrx/effects';
import {
  Action,
  ActionReducer,
  createFeatureSelector,
  createReducer,
  createSelector,
  MemoizedSelector,
  Store,
} from '@ngrx/store';
import { camelCase, keys } from 'lodash';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { AppState } from '../app.reducers';
import { AsyncEntityActionFactory } from './action-factories/async-entity-action-factory';
import {
  AsyncEntityBulkMutationFactory,
  AsyncEntityBulkMutationOptions,
} from './action-factories/async-entity-bulk-mutation-factory';
import {
  AsyncEntityDataDictionaryFactory,
  AsyncEntityDataDictionaryOptions,
} from './action-factories/async-entity-data-dictionary-factory';
import { AsyncEntityDataFactory, AsyncEntityDataOptions } from './action-factories/async-entity-data-factory';
import { AsyncEntityDestroyMutationFactory } from './action-factories/async-entity-destroy-mutation.factory';
import {
  AsyncEntityMutationFactory,
  AsyncEntityMutationOptions,
} from './action-factories/async-entity-mutation-factory';
import { AsyncEntityQueryFactory } from './action-factories/async-entity-query-factory';
import { AsyncEntitySearchFactory } from './action-factories/async-entity-search-factory';
import {
  selectCurrentSearchPaginationResults,
  selectCurrentSearchResults,
  selectIsSearching,
  selectIsSearchingAndIsEmpty,
  selectSearchQuery,
  selectTotal,
} from './selectors';

/**
 * @param T - the interface that represents the schema of the data being managed
 */
export abstract class AsyncEntityBase<T> {
  protected nameSpace: string;
  private selectFeature: MemoizedSelector<AppState, IAsyncEntityState<T>>;
  private reducers = [];
  private asyncFactories: { [managerName: string]: AsyncEntityActionFactory<unknown, unknown, unknown> } = {};
  private initialState: IAsyncEntityState<T> = {
    entities: {},

    isLoadingEntities: {},
  };

  constructor(protected actions$: Actions, protected store: Store<AppState>, nameSpace: string) {
    this.nameSpace = nameSpace;
    this.selectFeature = createFeatureSelector<IAsyncEntityState<T>>(nameSpace);
  }

  public getReducers(): ActionReducer<IAsyncEntityState<T>, Action> {
    const reducer = createReducer(this.initialState, ...this.reducers);

    return function (state: IAsyncEntityState<T>, action: Action): IAsyncEntityState<T> {
      return reducer(state, action);
    };
  }

  protected createAsyncSearchQuery<EntitySchema, ActionPayloadType, ErrorPayloadType>(
    name: string,
    sideEffect: (payload: {
      query: ActionPayloadType;
      pageAndSort: PageAndSort;
    }) => Observable<PaginatedData<EntitySchema>>
  ): AsyncSearchManager<EntitySchema, ActionPayloadType> {
    const factory = new AsyncEntitySearchFactory<ActionPayloadType, EntitySchema, ErrorPayloadType>(
      this.nameSpace,
      name,
      sideEffect
    );
    this.reducers.push(...factory.getReducers());
    this.initialState = { ...this.initialState, ...factory.getInitialState() };
    this.asyncFactories[factory.name] = factory;

    const effect = factory.createEffect(this.actions$, {
      searchQuery: this.store.select(selectSearchQuery(factory, this.selectFeature)),
    });

    effect['dispatch'] = (payload: { query: ActionPayloadType; pageAndSort: PageAndSort }) =>
      this.store.dispatch(factory.action(payload));
    effect['total$'] = this.store.select(selectTotal(factory, this.selectFeature));
    effect['isSearching$'] = this.store.select(selectIsSearching(factory, this.selectFeature));
    effect['isSearchingAndEmpty$'] = this.store.select(selectIsSearchingAndIsEmpty(factory, this.selectFeature));
    effect['searchQuery$'] = this.store.select(selectSearchQuery(factory, this.selectFeature));
    effect['searchPaginationResults$'] = this.store.select(
      selectCurrentSearchPaginationResults(factory, this.selectFeature)
    );
    effect['goToPage'] = (page?: number, limit: number = 25) => this.store.dispatch(factory.paginate({ page, limit }));
    effect['searchResults$'] = this.store.select(selectCurrentSearchResults(factory, this.selectFeature));
    return effect as unknown as AsyncSearchManager<EntitySchema, ActionPayloadType>;
  }

  protected createAsyncQuery<ActionPayloadType, ResponseData, ErrorPayloadType>(
    sideEffect: (payload: ActionPayloadType) => Observable<ResponseData>
  ): AsyncQueryManager<T, string> {
    const factory = new AsyncEntityQueryFactory<ActionPayloadType, ResponseData, ErrorPayloadType>(
      this.nameSpace,
      'get by id',
      sideEffect
    );
    this.reducers.push(...factory.getReducers());
    this.asyncFactories[factory.name] = factory;

    const effect = factory.createEffect(this.actions$);

    const isLoadingSelector = createSelector(this.selectFeature, (state) => state.isLoadingEntities);
    const entitiesSelector = createSelector(this.selectFeature, (state) => state.entities);

    effect['dispatch'] = (id: string) => this.store.dispatch(factory.action({ entityId: id }));
    effect['entities$'] = this.store.select(entitiesSelector);
    effect['getEntityById'] = (id: string) =>
      this.store.select(entitiesSelector).pipe(map((entityMap) => entityMap[id] || null));
    effect['isLoadingEntities$'] = this.store.select(isLoadingSelector);
    effect['getIsLoadingById'] = (id: string) =>
      this.store.select(isLoadingSelector).pipe(map((loadingMap) => loadingMap[id] ?? null));
    return effect as unknown as AsyncQueryManager<T, string>;
  }

  protected createBulkAsyncMutation<ActionPayloadType, ResponseData, ErrorPayloadType>(
    name: string,
    sideEffect: (payload: ActionPayloadType) => Observable<ResponseData>,
    options?: MutationManagerOptions
  ): AsyncBulkMutationManager<ActionPayloadType, ResponseData> {
    const factory = new AsyncEntityBulkMutationFactory<ActionPayloadType, ResponseData, ErrorPayloadType>(
      this.nameSpace,
      name,
      sideEffect,
      this.convertBulkMutationOptions(options, name)
    );
    this.reducers.push(...factory.getReducers());
    this.asyncFactories[factory.name] = factory;

    const effect = factory.createEffect(this.actions$);
    const isLoadingEntities = createSelector(this.selectFeature, (state) => state.isLoadingEntities);
    const isLoadingMutationCallSelector = createSelector(
      this.selectFeature,
      (state) => state[factory.mutationIsLoadingName]
    );
    effect['isLoading$'] = this.store.select(isLoadingMutationCallSelector);
    effect['onSuccess$'] = this.actions$.pipe(
      ofType(factory.typeSuccess),
      map((action) => action['payload'] as unknown as ResponseData)
    );
    effect['onError$'] = this.actions$.pipe(
      ofType(factory.typeError),
      map((action) => action['payload'] as unknown as ResponseData)
    );
    effect['onResponse$'] = this.actions$.pipe(
      ofType(factory.typeSuccess, factory.typeError),
      map((action) => action['payload'] as unknown as ResponseData)
    );
    effect['onError$'] = this.actions$.pipe(
      ofType(factory.typeError),
      map((action) => action['payload'] as unknown as ResponseData)
    );
    effect['onResponse$'] = this.actions$.pipe(
      ofType(factory.typeSuccess, factory.typeError),
      map((action) => action['payload'] as unknown as ResponseData)
    );
    effect['dispatch'] = (entityIds: string[], payload: ActionPayloadType) =>
      this.store.dispatch(factory.action({ entityIds, payload }));
    effect['isLoadingEntities$'] = this.store.select(isLoadingEntities);
    return effect as unknown as AsyncBulkMutationManager<ActionPayloadType, ResponseData>;
  }

  protected createAsyncMutation<ActionPayloadType, ResponseData, ErrorPayloadType>(
    name: string,
    sideEffect: (payload: ActionPayloadType) => Observable<ResponseData>,
    options: MutationManagerOptions = {}
  ): AsyncMutationManager<ActionPayloadType, ResponseData> {
    const factory = new AsyncEntityMutationFactory<ActionPayloadType, ResponseData, ErrorPayloadType>(
      this.nameSpace,
      name,
      sideEffect,
      this.convertMutationOptions(options, name)
    );
    this.reducers.push(...factory.getReducers());
    this.asyncFactories[factory.name] = factory;

    const effect = factory.createEffect(this.actions$);

    const isLoadingSelector = createSelector(this.selectFeature, (state) => state[factory.mutationIsLoadingName]);
    effect['isLoading$'] = this.store.select(isLoadingSelector);
    effect['onSuccess$'] = this.actions$.pipe(
      ofType(factory.typeSuccess),
      map((action) => action['payload'] as unknown as ResponseData)
    );
    effect['onError$'] = this.actions$.pipe(
      ofType(factory.typeError),
      map((action) => action['payload'] as unknown as ResponseData)
    );
    effect['onResponse$'] = this.actions$.pipe(
      ofType(factory.typeSuccess, factory.typeError),
      map((action) => action['payload'] as unknown as ResponseData)
    );
    effect['dispatch'] = (entityId: string, payload: ActionPayloadType) =>
      this.store.dispatch(factory.action({ entityId, payload }));
    return effect as unknown as AsyncMutationManager<ActionPayloadType, ResponseData>;
  }

  protected createAsyncData<ActionPayloadType, ResponseData, ErrorPayloadType>(
    name: string,
    sideEffect: (payload: ActionPayloadType) => Observable<ResponseData>,
    options?: AsyncEntityDataOptions
  ): AsyncDataManager<ActionPayloadType, ResponseData> {
    options = { dataIndex: camelCase(name), ...options };
    const factory = new AsyncEntityDataFactory<ActionPayloadType, ResponseData, ErrorPayloadType>(
      this.nameSpace,
      name,
      sideEffect,
      options
    );
    this.reducers.push(...factory.getReducers());
    this.asyncFactories[factory.name] = factory;

    const effect = factory.createEffect(this.actions$);

    const isLoadingSelector = createSelector(
      this.selectFeature,
      (state) => state[`data-${options.dataIndex}`]?.isLoading
    );
    effect['isLoading$'] = this.store.select(isLoadingSelector);
    const dataSelector = createSelector(this.selectFeature, (state) => state[`data-${options.dataIndex}`]?.data);
    effect['data$'] = this.store.select(dataSelector);
    effect['onSuccess$'] = this.actions$.pipe(
      ofType(factory.typeSuccess),
      map((action) => action['payload'] as unknown as ResponseData)
    );
    effect['onError$'] = this.actions$.pipe(
      ofType(factory.typeError),
      map((action) => action['payload'] as unknown as ResponseData)
    );
    effect['onResponse$'] = this.actions$.pipe(
      ofType(factory.typeSuccess, factory.typeError),
      map((action) => action['payload'] as unknown as ResponseData)
    );
    effect['dispatch'] = (payload: ActionPayloadType) => this.store.dispatch(factory.action({ payload }));
    return effect as unknown as AsyncDataManager<ActionPayloadType, ResponseData>;
  }

  protected createAsyncDataDictionary<ActionPayloadType, ResponseData, ErrorPayloadType>(
    name: string,
    sideEffect: (payload: ActionPayloadType) => Observable<ResponseData>,
    options?: AsyncEntityDataDictionaryOptions
  ): AsyncDataDictionaryManager<ActionPayloadType, ResponseData> {
    const factory = new AsyncEntityDataDictionaryFactory<ActionPayloadType, ResponseData, ErrorPayloadType>(
      this.nameSpace,
      name,
      sideEffect,
      options
    );
    this.reducers.push(...factory.getReducers());
    this.asyncFactories[factory.name] = factory;

    const isLoadingSelector = createSelector(this.selectFeature, (state) => {
      const queryHashes = keys(state[factory.name]);
      return queryHashes.some((key) => {
        return state[factory.name][key]?.isLoading === true;
      });
    });

    // Returns a map where the keys are query hashes and the values are corresponding data
    const dataSelector = createSelector(this.selectFeature, (state) => {
      const dictionaryKeys = keys(state[factory.name]);
      return dictionaryKeys.reduce((prev, curr) => {
        prev[curr] = state[factory.name][curr]?.data;
        return prev;
      }, {});
    });

    const dataStoreSelector = this.store.select(dataSelector);

    const effect = factory.createEffect(this.actions$, {
      feature: this.store.select(
        createSelector(this.selectFeature, (state) => {
          return state?.[factory.name];
        })
      ),
    });

    effect['isLoading$'] = this.store.select(isLoadingSelector);
    effect['dispatch'] = (payload: ActionPayloadType) => this.store.dispatch(factory.action(payload));
    effect['data$'] = dataStoreSelector;
    effect['getData'] = (input$: Observable<any>): Observable<ResponseData> => {
      return input$.pipe(
        mergeMap((unhashedData) => {
          const key = factory.getKey(unhashedData);
          return dataStoreSelector.pipe(map((data) => data[key]));
        })
      );
    };

    return effect as unknown as AsyncDataDictionaryManager<ActionPayloadType, ResponseData>;
  }

  protected createAsyncDestroyMutation<ActionPayloadType, ResponseData, ErrorPayloadType>(
    name: string,
    sideEffect: (payload: ActionPayloadType) => Observable<ResponseData>,
    options: MutationManagerOptions = {}
  ): AsyncDestroyMutationManager<ActionPayloadType, ResponseData> {
    const factory = new AsyncEntityDestroyMutationFactory<ActionPayloadType, ResponseData, ErrorPayloadType>(
      this.nameSpace,
      name,
      sideEffect,
      this.convertMutationOptions(options, name)
    );
    this.reducers.push(...factory.getReducers());
    this.asyncFactories[factory.name] = factory;

    const effect = factory.createEffect(this.actions$);

    const isLoadingSelector = createSelector(this.selectFeature, (state) => state[factory.mutationIsLoadingName]);
    effect['isLoading$'] = this.store.select(isLoadingSelector);
    effect['onSuccess$'] = this.actions$.pipe(
      ofType(factory.typeSuccess),
      map((action) => {
        return action['payload'] as unknown as ResponseData;
      })
    );
    effect['dispatch'] = (entityId: string, payload: ActionPayloadType) =>
      this.store.dispatch(factory.action({ entityId, payload }));
    return effect as unknown as AsyncDestroyMutationManager<ActionPayloadType, ResponseData>;
  }

  private convertMutationOptions(options: MutationManagerOptions, name: string): AsyncEntityMutationOptions {
    if (options?.refreshEntityFrom === 'response') {
      return options as AsyncEntityMutationOptions;
    } else if (options?.refreshEntityFrom === 'query') {
      const queryAction = this.asyncFactories['get by id']?.action;
      if (!queryAction) {
        console.error(
          `No query action found for async state namespace "${this.nameSpace}"! You must define a query manager before the mutation manager "${name}" when setting refreshEntityFrom = 'query'.
          \nOtherwise, you can update the mutated entity's state by choosing 'response'.
        `
        );
        return {
          ...options,
          refreshEntityFrom: undefined,
        };
      }
      return {
        ...options,
        refreshEntityFrom: queryAction,
      };
    } else {
      return options as AsyncEntityMutationOptions;
    }
  }

  private convertBulkMutationOptions(options: MutationManagerOptions, name: string) {
    if (options?.refreshEntityFrom === 'response') {
      return options as AsyncEntityBulkMutationOptions;
    } else if (options?.refreshEntityFrom === 'query') {
      const searchAction = this.asyncFactories['search']?.action;
      if (!searchAction) {
        console.error(
          `No search action found for async state namespace "${this.nameSpace}"! You must define a search manager before the bulk mutation manager "${name}" when setting refreshEntityFrom = 'query'.
          \nOtherwise, you can update the mutated entity's state by choosing 'response'.
        `
        );
        return {
          ...options,
          refreshEntityFrom: undefined,
        };
      }
      return {
        ...options,
        refreshEntityFrom: searchAction,
      };
    } else {
      return options as AsyncEntityBulkMutationOptions;
    }
  }
}
