import { Injectable } from '@angular/core';
import { MilestoneService, NotificationsService } from '@haulynx/services';
import {
  AsyncMutationManager,
  LoadsServiceLoad,
  Milestone,
  MilestoneComment,
  MilestoneLog,
  TrackingType,
} from '@haulynx/types';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { AppState } from '../../main-store/app.reducers';
import { AsyncEntityBase } from '../../main-store/async-entity/async-entity-base.class';
import { milestoneEntityNamespace } from './namespace';

@Injectable({ providedIn: 'root' })
export class MilestoneEntityService extends AsyncEntityBase<Milestone[]> {
  updateMilestoneManager: AsyncMutationManager<
    { milestone: Milestone; loadId: string; loadTmw: string },
    LoadsServiceLoad
  >;
  createMilestoneManager: AsyncMutationManager<
    { milestone: Milestone; loadId: string; loadTmw: string; trackingType: TrackingType },
    LoadsServiceLoad
  >;

  createCommentManager: AsyncMutationManager<
    { commentInput: MilestoneComment; milestoneId: string; loadId: string; loadTmw: string },
    LoadsServiceLoad
  >;
  updateCommentManager: AsyncMutationManager<
    { commentInput: MilestoneComment; milestoneId: string; loadId: string },
    LoadsServiceLoad
  >;
  deleteCommentManager: AsyncMutationManager<
    { commentInput: MilestoneComment; milestoneId: string; loadId: string },
    LoadsServiceLoad
  >;

  createLogManager: AsyncMutationManager<
    { logInput: MilestoneLog; milestoneId: string; loadId: string },
    LoadsServiceLoad
  >;
  updateLogManager: AsyncMutationManager<
    { logInput: MilestoneLog; milestoneId: string; loadId: string },
    LoadsServiceLoad
  >;
  deleteLogManager: AsyncMutationManager<
    { logInput: MilestoneLog; milestoneId: string; loadId: string },
    LoadsServiceLoad
  >;

  constructor(
    protected actions$: Actions,
    protected store: Store<AppState>,
    private notifications: NotificationsService,
    private milestonesApi: MilestoneService
  ) {
    super(actions$, store, milestoneEntityNamespace);

    this.updateMilestoneManager = this.createAsyncMutation(
      'update milestone',
      (payload) =>
        this.milestonesApi.updateMilestone(payload).pipe(
          tap(
            () => {
              this.notifications.success('Sucessfully updated milestone', 'Success!');
            },
            (error) => this.notifications.error(error, 'Error updating milestone')
          )
        ),
      { refreshEntityFrom: 'response' }
    );

    this.createMilestoneManager = this.createAsyncMutation(
      'create milestone',
      (payload) =>
        this.milestonesApi.createMilestone(payload).pipe(
          tap(
            () => {
              this.notifications.success('Sucessfully created milestone', 'Success!');
            },
            (error) => this.notifications.error(error, 'Error creating milestone')
          )
        ),
      { refreshEntityFrom: 'response' }
    );

    this.updateCommentManager = this.createAsyncMutation(
      'update Comment',
      (payload) =>
        this.milestonesApi.updateComment(payload).pipe(
          tap(
            () => {
              this.notifications.success('Sucessfully updated comment', 'Success!');
            },
            (error) => this.notifications.error(error, 'Error updating comment')
          )
        ),
      { refreshEntityFrom: 'response' }
    );

    this.createCommentManager = this.createAsyncMutation(
      'create Comment',
      (payload) =>
        this.milestonesApi.createComment(payload).pipe(
          tap(
            () => {
              this.notifications.success('Sucessfully created comment', 'Success!');
            },
            (error) => this.notifications.error(error, 'Error creating comment')
          )
        ),
      { refreshEntityFrom: 'response' }
    );

    this.deleteCommentManager = this.createAsyncMutation(
      'delete Comment',
      (payload) =>
        this.milestonesApi.deleteComment(payload).pipe(
          tap(
            () => {
              this.notifications.success('Sucessfully deleted comment', 'Success!');
            },
            (error) => this.notifications.error(error, 'Error deleting comment')
          )
        ),
      { refreshEntityFrom: 'response' }
    );

    this.createLogManager = this.createAsyncMutation(
      'create milestone log',
      (payload) =>
        this.milestonesApi.createMilestoneLog(payload).pipe(
          tap(
            () => {
              this.notifications.success('Successfully updated milestone', 'Success');
            },
            (error) => this.notifications.error(error, 'Error updating milestone')
          )
        ),
      { refreshEntityFrom: 'response' }
    );

    this.updateLogManager = this.createAsyncMutation(
      'update milestone log',
      (payload) =>
        this.milestonesApi.createMilestoneLog(payload).pipe(
          tap(
            () => {
              this.notifications.success('Successfully updated milestone', 'Success');
            },
            (error) => this.notifications.error(error, 'Error updating milestone')
          )
        ),
      { refreshEntityFrom: 'response' }
    );

    this.deleteLogManager = this.createAsyncMutation(
      'delete milestone log',
      (payload) =>
        this.milestonesApi.createMilestoneLog(payload).pipe(
          tap(
            () => {
              this.notifications.success('Successfully updated milestone', 'Success');
            },
            (error) => this.notifications.error(error, 'Error updating milestone')
          )
        ),
      { refreshEntityFrom: 'response' }
    );
  }
}
