import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import moment from 'moment-timezone';

export function formValuesMatch(controlName: string, matchControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchControlName];

    if (matchingControl.errors && !matchingControl.errors.formValuesMatch) {
      return;
    }

    control.value !== matchingControl.value
      ? matchingControl.setErrors({ formValuesMatch: true })
      : matchingControl.setErrors(null);
  };
}

export function compareTwoTimestampsValidator(
  beginDate: string,
  endDate: string,
  offsetMinutes: number = 0
): ValidatorFn {
  return function (group: FormGroup): ValidationErrors | null {
    const beginDateControl = group.controls[beginDate];
    const endDateControl = group.controls[endDate];
    const entranceMoment = moment(beginDateControl.value).add(offsetMinutes, 'minutes');
    const exitMoment = moment(endDateControl.value);

    if (group.dirty) {
      if (entranceMoment.isValid() && exitMoment.isValid() && entranceMoment.isAfter(exitMoment)) {
        return { entranceMoreThanExit: true };
      }
    }

    return null;
  };
}

export function requiredOneDateValidator(beginDate: string, endDate: string): ValidatorFn {
  return function (group: FormGroup): ValidationErrors | null {
    const beginDateControl = group.controls[beginDate];
    const endDateControl = group.controls[endDate];
    const entranceMoment = moment(beginDateControl.value);
    const exitMoment = moment(endDateControl.value);

    if (group.dirty) {
      if (!entranceMoment.isValid() && !exitMoment.isValid()) {
        beginDateControl.setErrors({ oneEntryRequired: true });
        endDateControl.setErrors({ oneEntryRequired: true });

        return { oneEntryRequire: true };
      }
    }

    return null;
  };
}
