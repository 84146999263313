import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { GeoJSONSource, LngLatBounds, LngLatLike, Map, Marker } from 'mapbox-gl';
import { Position } from '@turf/helpers';
import { head, isEmpty } from 'lodash';
import { MapboxService } from '@haulynx/services';
import {
  MapboxRoute,
  DEFAULT_TRUCK_COLOR,
  DEFAULT_TRAILER_COLOR,
  MapTruck,
  WayPoint,
  MarkerTypes,
  EldPoints,
  EldStatus,
  Environment,
  LoadLocationType,
  Milestone,
  LoadsServiceCustomer,
  AssetBridgeTrailerTelemetryHistory,
  AssetBridgeTrailerHistoryResponse,
} from '@haulynx/types';
import * as mapboxgl from 'mapbox-gl';
import { BehaviorSubject } from 'rxjs';
import { aliveWhile } from '@haulynx/utils';
import { delay, takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-mapbox',
  templateUrl: './mapbox.component.html',
  styleUrls: ['./mapbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapboxComponent implements OnInit, OnChanges {
  @Input() isLoading = false;
  @Input() data: MapboxRoute[] = [];
  @Input() center: LngLatLike = [-95.712891, 37.09024]; // static if fit bounds is used, USA geographical center
  @Input() zoom = 10; // static if fit bounds is used
  @Input() padding = 100;
  @Input() style = 'mapbox://styles/haulynx/ckmxzlojk10b717pi7svpdxgt';
  @Input() milestones: Milestone[] = [];
  @Input() trailerToggle: boolean;
  @Input() trailerData: AssetBridgeTrailerHistoryResponse;
  @Input() set isDialogOpen(isOpen: boolean) {
    this._isDialogOpen = isOpen;
    if (isOpen) {
      this.closePopovers();
    }
  }

  @Output() wayPointClick = new EventEmitter<string>();
  @Output() wayPointClickMilestone = new EventEmitter<{
    event: string;
    locationId?: string;
    coordinates?: LngLatLike;
    milestoneId?: string;
  }>();
  @Output() wayPointClickOpenMilestone = new EventEmitter<{
    milestoneId: string;
  }>();

  map: Map;
  bounds: LngLatBounds;
  route: MapboxRoute[] = [];
  truckRoute: Position[] = [];
  trailerRoute: Position[] = [];
  private hasSetBounds = false;
  alive = aliveWhile();
  private waypointsOnMap = [
    MarkerTypes.UPDATE_ADD,
    MarkerTypes.POPOVER_LOCATION_EDIT_UPDATE,
    MarkerTypes.POPOVER_LOCATION_UPDATE,
    MarkerTypes.POPOVER_WAYPOINT,
    MarkerTypes.POPOVER_PROBLEM,
  ];
  private _isDialogOpen = false;
  private isLocationUpdateClicked = false;
  trailerTelemtry: LngLatLike[] = [];
  trailerWayPoints: WayPoint[] = [];
  trailerMarkers = [];

  constructor(
    private mapboxService: MapboxService,
    private ngZone: NgZone,
    @Inject(DOCUMENT) private document: Document,
    private environment: Environment
  ) {}

  ngOnInit(): void {
    this.initMap();
  }

  initMap(): void {
    this.ngZone.runOutsideAngular(() => {
      this.map = new Map({
        accessToken: this.environment.mapbox.accessToken,
        container: 'map',
        style: this.style,
        zoom: this.zoom,
        center: this.center,
      });

      this.map.on('load', () => {
        this.setMapLayers();
      });

      this.map.on('zoom', () => {
        // catch zoom event here
        // console.log(this.map.getZoom());
      });
    });
  }

  setMapLayers(): void {
    this.ngZone.runOutsideAngular(() => {
      if (this.data) {
        this.data.map((source: MapboxRoute) => {
          const {
            entityId,
            lineColor,
            lineWidth,
            routeCoordinates,
            wayPoints,
            showTruck,
            truck,
            eldPoints,
            isDelayed,
            customers,
          } = source;

          this.trailerTelemtry = this.trailerData?.telemetryHistory?.map((trailer) => {
            return [trailer.longitude, trailer.latitude];
          });

          if (this.trailerTelemtry?.length > 0) {
            this.trailerWayPoints = [
              {
                locationType: LoadLocationType.TRAILER,
                name: 'TrailerStart',
                location: [this.trailerTelemtry[0][0], this.trailerTelemtry[0][1]],
                isDetention: false,
              },
              {
                locationType: LoadLocationType.TRAILER,
                name: 'TrailerEnd',
                location: [
                  this.trailerTelemtry[this.trailerTelemtry.length - 1][0],
                  this.trailerTelemtry[this.trailerTelemtry.length - 1][1],
                ],
                isDetention: false,
              },
            ];
          }

          this.setRoutes(routeCoordinates, entityId, lineColor, lineWidth);
          if (showTruck && !isEmpty(truck)) {
            const { name, position } = truck;
            this.setTruckRoute(name, position, routeCoordinates, entityId, lineWidth);
          }

          if (this.trailerTelemtry) {
            this.setTrailerRoute(this.trailerTelemtry, this.trailerData?.trailer?.id, lineWidth);
            this.setTrailerStartStopWayPoints(this.trailerWayPoints);
          }

          if (source.dispatch) {
            this.setDispatchWaypoint(source.dispatch);
          }

          this.setWayPoints(wayPoints, customers);
          this.setEldPoints(eldPoints);
          this.setTruck(truck, routeCoordinates as Position[], isDelayed);

          if (this.bounds) {
            this.map.fitBounds(this.bounds, { padding: this.padding });
          }
        });
      } else {
        setTimeout(() => {
          this.setMapLayers();
        }, 500);
      }
    });
  }

  setRoutes(
    routeCoordinates: LngLatLike[],
    entityId: string,
    lineColor?: string,
    lineWidth?: number,
    isLineDashed: boolean = false
  ): void {
    this.ngZone.runOutsideAngular(() => {
      const routeSource = this.mapboxService.getRouteSource(routeCoordinates as GeoJSON.Position[]);
      const routeLayerSource = this.mapboxService.getRouteLayerSource(entityId, lineColor, lineWidth, isLineDashed);

      if (routeCoordinates.length > 0) {
        this.map.addSource(entityId, routeSource);
        this.map.addLayer(routeLayerSource);
      }
    });
  }

  setTruckRoute(
    name: string,
    truckPosition: LngLatLike,
    routeCoordinates: LngLatLike[],
    entityId: string,
    lineWidth?: number
  ): void {
    this.ngZone.runOutsideAngular(() => {
      const truckId = `truck-${entityId}`;

      this.truckRoute = this.mapboxService.sliceRoute(
        head(routeCoordinates) as Position,
        truckPosition as Position,
        routeCoordinates as Position[]
      );
      this.setRoutes(this.truckRoute as LngLatLike[], truckId, DEFAULT_TRUCK_COLOR, lineWidth);
    });
  }

  setTrailerRoute(routeCoordinates: LngLatLike[], entityId: string, lineWidth?: number): void {
    this.ngZone.runOutsideAngular(() => {
      const trailerId = `trailer-${entityId}`;
      this.setRoutes(routeCoordinates as LngLatLike[], trailerId, DEFAULT_TRAILER_COLOR, lineWidth, true);
      this.setTrailerWaypoints(routeCoordinates as GeoJSON.Position[]);
    });
  }

  setTruck(truck: MapTruck, routeCoordinates: Position[], isDelayed: boolean): void {
    if (truck) {
      this.ngZone.runOutsideAngular(() => {
        const { name, position } = truck;
        const bearing = this.mapboxService.getBearing(routeCoordinates, position as Position);

        this.loadTruckImage(
          position as Position,
          'truck-delay',
          isDelayed ? 'truck-red' : 'truck-green',
          name,
          'visible',
          bearing
        );
      });
    }
  }

  loadTruckImage(
    position: Position,
    id: string,
    icon: string,
    name: string,
    visibility: 'visible' | 'none',
    iconRotate?: number
  ): void {
    this.ngZone.runOutsideAngular(() => {
      const { origin } = this.document.location;

      this.map.loadImage(`${origin}/assets/images/${icon}.png`, (_, image) => {
        this.map.addImage(id, image);

        const truckSource = this.mapboxService.getTruckSource(position as Position, name);
        const truckLayerSource = this.mapboxService.getTruckLayerSource(id, visibility, iconRotate);

        this.map.addSource(id, truckSource);
        this.map.addLayer(truckLayerSource);
      });
    });
  }

  setDispatchWaypoint(dispatch: WayPoint): void {
    this.ngZone.runOutsideAngular(() => {
      if (this.map) {
        this.removeDOMElements(this.document.querySelectorAll(`.${MarkerTypes.DISPATCH}`));

        const { location, name } = dispatch;
        const feature: GeoJSON.Feature<GeoJSON.Point> = this.mapboxService.getWayPointFeatures(location, name);
        const className = this.chooseClassName(dispatch);
        const markerElem = this.mapboxService.getWayPointMarkerElem(feature, className, null);
        if (markerElem) {
          markerElem.addEventListener('click', () => {
            this.wayPointClick.emit(feature?.properties?.name);
          });
          new Marker(markerElem).setLngLat(feature.geometry.coordinates as LngLatLike).addTo(this.map);
        }
      }
    });
  }

  setTrailerWaypoints(waypointCoords: GeoJSON.Position[]): void {
    this.ngZone.runOutsideAngular(() => {
      if (this.map) {
        this.removeDOMElements(this.document.querySelectorAll(`.${MarkerTypes.TRAILER_WAYPOINT}`));
        const imagePath = 'assets/images/haulynx-trailer-waypoint.png';

        const waypointGeoJSON = {
          type: 'FeatureCollection',
          features: waypointCoords.map((coord) => ({
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: coord,
            },
            properties: {
              title: 'Trailer Marker',
              icon: 'marker',
            },
          })),
        };

        this.map.addSource('waypoints', {
          type: 'geojson',
          data: waypointGeoJSON as GeoJSON.FeatureCollection,
        });

        this.map.loadImage(imagePath, (error, image) => {
          if (error) {
            console.error('Error loading custom icon:', error);
            return;
          }

          this.map.addImage('haulynx-trailer-waypoint', image);

          this.map.addLayer({
            id: 'waypoints',
            type: 'symbol',
            source: 'waypoints',
            layout: {
              'icon-image': 'haulynx-trailer-waypoint',
              'icon-allow-overlap': true,
              'icon-size': 1,
            },
            paint: {
              'icon-opacity': 1,
            },
          });
        });
      }
    });
  }

  setWayPoints(wayPoints: WayPoint[], customers: LoadsServiceCustomer[]): void {
    this.ngZone.runOutsideAngular(() => {
      if (this.map) {
        this.removeDOMElements(this.document.querySelectorAll(`.${MarkerTypes.AWAITING_DROPOFF}`));
        this.removeDOMElements(this.document.querySelectorAll(`.${MarkerTypes.AWAITING_PICKUP}`));
        this.removeDOMElements(this.document.querySelectorAll(`.${MarkerTypes.PASSED_DROPOFF}`));
        this.removeDOMElements(this.document.querySelectorAll(`.${MarkerTypes.PASSED_PICKUP}`));
        this.removeDOMElements(this.document.querySelectorAll(`.${MarkerTypes.DETENTION_DROPOFF}`));
        this.removeDOMElements(this.document.querySelectorAll(`.${MarkerTypes.DETENTION_PICKUP}`));
        this.removeDOMElements(this.document.querySelectorAll(`.${MarkerTypes.POPOVER_WAYPOINT}`));

        const wayPointFeatures = wayPoints.map((wayPoint: WayPoint) => {
          const { location, name } = wayPoint;
          return this.mapboxService.getWayPointFeatures(location, 'name');
        });

        wayPointFeatures.forEach((marker: GeoJSON.Feature<GeoJSON.Point>, index: number) => {
          if (marker) {
            const className = this.chooseClassName(wayPoints[index]);
            const markerElem = this.mapboxService.getWayPointMarkerElem(marker, className, index);
            if (markerElem) {
              markerElem.addEventListener('click', () => {
                this.wayPointClick.emit(marker?.properties?.name);
              });
            }

            if (this.document.querySelectorAll(`.${MarkerTypes.POPOVER_WAYPOINT}`).length === 0) {
              const popup = new Marker(markerElem)
                .setLngLat(marker.geometry.coordinates as LngLatLike)
                .setPopup(
                  new mapboxgl.Popup({ offset: 25, className: 'waypoint-popover' }).setDOMContent(
                    this.mapboxService.getWaypointContent(
                      this.milestones,
                      wayPoints[index],
                      customers,
                      this.wayPointClickMilestone
                    )
                  )
                )
                .addTo(this.map);

              popup.getElement().addEventListener('click', (e) => {
                this.wayPointClickOpenMilestone.emit({ milestoneId: wayPoints[index].milestoneId });
                if (e.detail === 2) {
                  this.wayPointClickMilestone.emit({
                    event: 'create-edit-milestone',
                    locationId: wayPoints[index].locationId,
                  });
                  this.closePopovers();
                }
              });
            }
          }
        });
      }
    });
  }

  setTrailerStartStopWayPoints(wayPoints: WayPoint[]) {
    this.ngZone.runOutsideAngular(() => {
      if (this.map) {
        const wayPointFeatures = wayPoints.map((wayPoint: WayPoint) => {
          const { location, name } = wayPoint;
          return this.mapboxService.getWayPointFeatures(location, name);
        });
        wayPointFeatures.forEach((marker: GeoJSON.Feature<GeoJSON.Point>, index: number) => {
          if (marker) {
            const className = this.chooseClassName(wayPoints[index]);
            const markerElem = this.mapboxService.getTrailertMarkerElem(marker, className, index);
            this.trailerMarkers.push(
              new Marker(markerElem).setLngLat(marker.geometry.coordinates as LngLatLike).addTo(this.map)
            );
          }
        });
      }
    });
  }

  removeTrailerStartStopWayPoints() {
    this.trailerMarkers.forEach((marker) => {
      marker.remove();
    });
  }

  setEldPoints(eldPoints: EldPoints[]): void {
    this.ngZone.runOutsideAngular(() => {
      this.removeDOMElements(this.document.querySelectorAll(`.${MarkerTypes.UPDATE_UPDATE}`));
      this.removeDOMElements(this.document.querySelectorAll(`.${MarkerTypes.UPDATE_PROBLEM}`));
      this.removeDOMElements(this.document.querySelectorAll(`.${MarkerTypes.CHECKPOINT_NEUTRAL}`));
      this.removeDOMElements(this.document.querySelectorAll(`.${MarkerTypes.POPOVER_LOCATION_EDIT_UPDATE}`));
      this.removeDOMElements(this.document.querySelectorAll(`.${MarkerTypes.POPOVER_PROBLEM}`));
      const eldPointFeatures = eldPoints.map((eldPoint: EldPoints) => {
        return this.mapboxService.setEldFeature(eldPoint.coordinates);
      });
      eldPointFeatures.forEach((marker: GeoJSON.Feature<GeoJSON.Point>, index: number) => {
        if (marker) {
          const className = this.chooseELDClassName(eldPoints[index]);
          const markerElem = this.mapboxService.getWayPointMarkerElem(marker, className);
          // markerElem.addEventListener('click', () => {
          //   this.wayPointClick.emit(marker?.properties?.name);
          // });
          let popup;
          if (eldPoints[index].status === EldStatus.UPDATE || eldPoints[index].status === EldStatus.PROBLEM) {
            popup = new Marker(markerElem)
              .setLngLat(marker.geometry.coordinates as LngLatLike)
              .setPopup(
                new mapboxgl.Popup({
                  offset: eldPoints[index].isEmphasized ? 16 : 10,
                  anchor: 'bottom',
                  className:
                    eldPoints[index].status === EldStatus.PROBLEM
                      ? 'location-problem-popover'
                      : 'location-edit-update-popover',
                }).setDOMContent(
                  this.mapboxService.getEditUpdateLocationContent(
                    eldPoints[index].milestoneId,
                    this.wayPointClickMilestone
                  )
                )
              )
              .addTo(this.map);
          } else {
            popup = new Marker(markerElem).setLngLat(marker.geometry.coordinates as LngLatLike).addTo(this.map);
          }

          popup.getElement().addEventListener('click', (e) => {
            this.isLocationUpdateClicked = true;
            setTimeout(() => {
              this.isLocationUpdateClicked = false;
            }, 800);
            this.wayPointClickOpenMilestone.emit({ milestoneId: eldPoints[index].milestoneId });
          });
        }
      });
    });
  }

  updateRouteSource(entityId: string, routeCoordinates: LngLatLike[]): void {
    this.ngZone.runOutsideAngular(() => {
      const routeSource = this.map?.getSource(entityId) as GeoJSONSource;

      if (routeSource) {
        const routeSourceFeature = this.mapboxService.setRouteSourceFeature(routeCoordinates);
        routeSource.setData(routeSourceFeature);
      }
    });
  }

  updateTruckSource(entityId: string, routeCoordinates: LngLatLike, name: string): void {
    this.ngZone.runOutsideAngular(() => {
      const truckSource = this.map?.getSource(entityId) as GeoJSONSource;

      if (truckSource) {
        const truckSourceFeature = this.mapboxService.setTruckSourceFeature(routeCoordinates, name);
        truckSource.setData(truckSourceFeature);
      }
    });
  }

  updateEldSource(entityId: string, eldPoints: EldPoints[]): void {
    this.ngZone.runOutsideAngular(() => {
      const eldSource = this.map?.getSource(entityId) as GeoJSONSource;

      if (eldSource) {
        const eldFeatures: GeoJSON.Feature<GeoJSON.Geometry>[] = eldPoints.map((eld) => {
          const { coordinates: eldCoordinates } = eld;
          return this.mapboxService.setEldFeature(eldCoordinates);
        });

        const newEldSource = this.mapboxService.getEldFeatureCollection(eldFeatures);
        eldSource.setData(newEldSource);
      }
    });
  }

  removeDOMElements(elements: NodeListOf<HTMLDivElement>): void {
    elements.forEach((element: HTMLDivElement) => element.remove());
  }

  private chooseELDClassName(eldPoint: EldPoints): string {
    let className = '';
    switch (eldPoint.status) {
      case EldStatus.GOOD: {
        className = MarkerTypes.CHECKPOINT_NEUTRAL;
        break;
      }
      case EldStatus.PROBLEM: {
        className = MarkerTypes.UPDATE_PROBLEM;
        break;
      }
      case EldStatus.UPDATE: {
        className = MarkerTypes.UPDATE_UPDATE;
        break;
      }
    }
    if (eldPoint.isEmphasized) {
      return `${className} waypoint emphasized`;
    }
    return `${className} waypoint`;
  }

  private chooseClassName(wayPoint: WayPoint): string {
    const hasOutTimestamp = wayPoint?.outTimestamp ? true : false;
    let className: string;
    if (!wayPoint.isDetention) {
      if (hasOutTimestamp && wayPoint.locationType === LoadLocationType.PICKUP) {
        className = MarkerTypes.PASSED_PICKUP;
      } else if (hasOutTimestamp && wayPoint.locationType === LoadLocationType.DROPOFF) {
        className = MarkerTypes.PASSED_DROPOFF;
      } else if (!hasOutTimestamp && wayPoint.locationType === LoadLocationType.PICKUP) {
        className = MarkerTypes.AWAITING_PICKUP;
      } else if (!hasOutTimestamp && wayPoint.locationType === LoadLocationType.DROPOFF) {
        className = MarkerTypes.AWAITING_DROPOFF;
      } else if (!hasOutTimestamp && wayPoint.locationType === LoadLocationType.TRAILER) {
        className = MarkerTypes.TRAILER_START;
      } else if (!hasOutTimestamp && wayPoint.locationType === LoadLocationType.TRAILER) {
        className = MarkerTypes.TRAILER_END;
      } else if (wayPoint.locationType === 'dispatch') {
        className = MarkerTypes.DISPATCH;
      }
    } else {
      if (wayPoint.locationType === LoadLocationType.PICKUP) {
        className = MarkerTypes.DETENTION_PICKUP;
      } else if (wayPoint.locationType === LoadLocationType.DROPOFF) {
        className = MarkerTypes.DETENTION_DROPOFF;
      } else if (wayPoint.locationType === 'dispatch') {
        className = MarkerTypes.DISPATCH;
      }
    }
    if (wayPoint.isEmphasized) {
      return `${className} waypoint marker emphasized`;
    }
    return `${className} waypoint marker`;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { data, trailerToggle } = changes;

    if (data) {
      const { currentValue: mapboxRoutes } = data;

      if (mapboxRoutes) {
        const coordinatesToBound = mapboxRoutes.reduce((acc: LngLatLike[], mapboxRoute: MapboxRoute) => {
          const { entityId, routeCoordinates, wayPoints, truck, eldPoints, customers } = mapboxRoute;

          this.initLocationUpdateListeners(routeCoordinates, wayPoints, eldPoints);
          this.initMapHighlightListener(routeCoordinates, entityId);
          this.updateRouteSource(entityId, routeCoordinates);
          this.updateTruckSource('truck-delay', truck?.position, truck?.name);
          this.updateTruckSource('truck', truck?.position, truck?.name);
          this.setWayPoints(wayPoints, customers);
          this.setEldPoints(eldPoints);
          this.updateEldSource('eld-point', eldPoints);
          if (mapboxRoute.dispatch) {
            this.setDispatchWaypoint(mapboxRoute.dispatch);
          }

          return [...acc, ...routeCoordinates];
        }, []);
        if (!this.hasSetBounds && coordinatesToBound?.length) {
          this.bounds = this.mapboxService.fitBounds(coordinatesToBound);
          this.map?.fitBounds(this.bounds, { padding: this.padding });
          this.hasSetBounds = true;
        }
      }
    }
    if (trailerToggle && this.trailerData) {
      if (trailerToggle.currentValue && this.trailerTelemtry?.length > 0) {
        this.setTrailerRoute(this.trailerTelemtry, this.trailerData?.trailer?.id, 2);
        this.setTrailerStartStopWayPoints(this.trailerWayPoints);
      } else if (this.trailerTelemtry?.length > 0) {
        this.map?.removeLayer(`trailer-${this.trailerData?.trailer?.id}`);
        this.map?.removeSource(`trailer-${this.trailerData?.trailer?.id}`);
        this.removeTrailerStartStopWayPoints();
        this.map?.removeLayer('waypoints');
        this.map?.removeSource('waypoints');
        this.map?.removeImage('haulynx-trailer-waypoint');
      }
    }
  }

  closePopovers(): void {
    this.waypointsOnMap.forEach((waypoint) => {
      const point = this.document.querySelectorAll(`.${waypoint}`);
      if (point.length) {
        for (let i = 0; i < point.length; i++) {
          point[i].remove();
        }
      }
    });
  }

  initLocationUpdateListeners(routeCoordinates: LngLatLike[], wayPoints: WayPoint[], eldPoints: EldPoints[]): void {
    this.ngZone.runOutsideAngular(() => {
      this.removeDOMElements(this.document.querySelectorAll(`.${MarkerTypes.UPDATE_ADD}`));
      this.removeDOMElements(this.document.querySelectorAll(`.${MarkerTypes.POPOVER_LOCATION_UPDATE}`));

      this.map.on('click', (e) => {
        const eldPointFeatures = [this.mapboxService.setEldFeature(e.lngLat as LngLatLike)];
        eldPointFeatures.forEach((point: GeoJSON.Feature<GeoJSON.Point>, index: number) => {
          //race condition with clicking on the waypoint
          setTimeout(() => {
            const route = this.mapboxService.isWithinRange(routeCoordinates, [e.lngLat.lng, e.lngLat.lat]);
            if (
              route.found &&
              this.document.querySelectorAll(`.${MarkerTypes.POPOVER_LOCATION_UPDATE}`).length === 0 &&
              this.document.querySelectorAll(`.${MarkerTypes.POPOVER_WAYPOINT}`).length === 0 &&
              this.document.querySelectorAll(`.${MarkerTypes.POPOVER_LOCATION_EDIT_UPDATE}`).length === 0 &&
              this.document.querySelectorAll(`.${MarkerTypes.POPOVER_PROBLEM}`).length === 0 &&
              !this.isLocationUpdateClicked &&
              !this._isDialogOpen
            ) {
              const markerElem = this.mapboxService.getWayPointMarkerElem(
                point,
                MarkerTypes.UPDATE_ADD + ' waypoint marker'
              );

              const popup = new mapboxgl.Popup({
                offset: 0,
                anchor: 'bottom',
                className: 'location-update-popover',
              })
                .setLngLat(point.geometry.coordinates as LngLatLike)
                .setDOMContent(
                  this.mapboxService.getUpdateLocationContent(
                    { lng: route.closetRoute[0], lat: route.closetRoute[1] } as LngLatLike,
                    this.wayPointClickMilestone
                  )
                )
                .addTo(this.map);

              const marker = new Marker(markerElem).setLngLat(point.geometry.coordinates as LngLatLike).addTo(this.map);

              marker.getElement().addEventListener('click', (e) => {
                this.wayPointClickMilestone.emit({
                  event: 'create-location-update-milestone',
                  coordinates: { lng: route.closetRoute[0], lat: route.closetRoute[1] } as LngLatLike,
                });
              });

              popup.on('close', (e) => {
                marker.remove();
              });
            }
          }, 100);
        });
      });
    });
  }

  initMapHighlightListener(routeCoordinates: LngLatLike[], entityId: string): void {
    this.map.on('mousemove', (e) => {
      if (this.map && this.map.getLayer(entityId)) {
        if (this.mapboxService.isWithinRange(routeCoordinates, [e.lngLat.lng, e.lngLat.lat]).found) {
          this.map.setPaintProperty(entityId, 'line-width', this.mapboxService.DEFAULT_LINE_WIDTH_HIGHLIGHTED);
          this.map.getCanvas().style.cursor = 'pointer';
        } else {
          this.map.setPaintProperty(entityId, 'line-width', this.mapboxService.DEFAULT_LINE_WIDTH);
          this.map.getCanvas().style.cursor = '';
        }
      }
    });
  }
}
