import { ChangeDetectionStrategy, Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { KeyValuePair } from '@haulynx/types';
import { ControlValueAccessorConnectorComponent } from '@haulynx/utils';

@Component({
  selector: 'haulynx-white-radio-group',
  templateUrl: './white-radio-group.component.html',
  styleUrls: ['./white-radio-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: WhiteRadioGroupComponent,
      multi: true,
    },
  ],
})
export class WhiteRadioGroupComponent extends ControlValueAccessorConnectorComponent {
  @Input() options: KeyValuePair[];
  @Input() value;
  @Output() valueChange = new EventEmitter<boolean>();

  constructor(injector: Injector) {
    super(injector);
    this.controlContainer;
  }
}
