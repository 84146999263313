import { GeoSpacialFilterType, ISearchFilter } from '../search-filter';
import { ISearchFilterType } from '../search-filter.types';
export class LaneOriginFilter implements ISearchFilter {
  name = 'Origin';
  type: ISearchFilterType = ISearchFilterType.GEOSPATIAL;
  keys: GeoSpacialFilterType;
  description = 'Filter lanes by the origin location';

  constructor(defaultValues?: GeoSpacialFilterType) {
    this.keys = new GeoSpacialFilterType({
      radius: 50,
      lat: null,
      lon: null,
      location: '',
      radiusFormName: 'originRadius',
      latFormName: 'originLat',
      lonFormName: 'originLon',
      locationFormName: 'originLocation',
      state: null,
      stateFormName: 'originState',
      zipFormName: 'originZip',
      ...defaultValues,
    });
  }
}
