import { Pipe, PipeTransform } from '@angular/core';
import { DataTableCls, EntityType } from '@haulynx/types';
import { isFunction, isString } from 'lodash';

@Pipe({ name: 'getRowClassPipe' })
export class GetRowClassPipe implements PipeTransform {
  constructor() {}

  transform(row: EntityType, cls: DataTableCls): string {
    if (isFunction(cls)) {
      return cls(row);
    }

    if (isString(cls)) {
      return cls;
    }

    return '';
  }
}
