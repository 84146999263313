<div *ngIf="contacts.length" class="load-active-container">
  <div *ngIf="title" class="load-active-container--title">{{ title }}</div>

  <div class="load-active__contacts">
    <div *ngFor="let contact of contacts" class="load-active__contacts__item">
      <div class="load-active__contacts--label">
        <label>{{ contact.label }}</label>
        <span>{{ contact.name }}</span>
      </div>
      <app-action-button
        label="CONTACT"
        [disabled]="contact.disable"
        [href]="contact.phone && !contact.disable ? 'tel:' + contact.phone : null"
        svgIcon="haulynx-phone-contact"
      >
      </app-action-button>
    </div>
  </div>
</div>
