import { Injectable } from '@angular/core';
import { GetLoadMatchesByCarrierDot } from '@haulynx/gql';
import { PageAndSort, QueryOptionsFetchPolicy } from '@haulynx/types';
import { get } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GraphqlService } from '../graphql.service';

@Injectable({
  providedIn: 'root',
})
export class RecommendedLoadsQuery {
  constructor(private graphqlService: GraphqlService) {}

  searchByDot(
    variables: { dot: string; paging: PageAndSort; radius: number },
    options: Partial<QueryOptionsFetchPolicy> = {}
  ): Observable<{ entities: unknown; total: unknown }> {
    const defaultPaging: PageAndSort = { limit: 200, page: 1, sort: 'ASC' };
    const { dot, paging, radius } = variables;

    return this.graphqlService
      .query({
        query: GetLoadMatchesByCarrierDot,
        variables: {
          dot,
          radius,
          paging: { ...defaultPaging, ...paging },
        },
        ...options,
      })
      .pipe(
        map((result: unknown) => {
          const { getTruckPostRecommendations } = get(result, 'data', {});

          return {
            entities: get(getTruckPostRecommendations, 'result', []),
            total: get(getTruckPostRecommendations, 'total', 0),
          };
        })
      );
  }
}
