import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CarriersService } from '@haulynx/services';
import { LoadFeedModel } from '@haulynx/store';
import { Bid, BidSourceType, BidStatusType, Carrier, KeyValuePair, laneHistoryActionMenu, User } from '@haulynx/types';
import { differenceBy, omit } from 'lodash';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Component({
  selector: 'haulynx-carrier-toolbar-bids',
  templateUrl: './carrier-toolbar-bids.component.html',
  styleUrls: ['./carrier-toolbar-bids.component.scss'],
})
export class CarrierToolbarBidsComponent implements OnInit {
  @Input() carrier: Partial<Carrier>;
  @Input() user: Partial<User>;
  @Input() latestBid?: Bid;
  @Input() bidCreateLoading: boolean;
  @Output() toggleForm = new EventEmitter();
  @Output() submitBidForm = new EventEmitter();
  @Output() gotToBids = new EventEmitter();
  form: FormGroup;
  priceMin = 0;
  priceMax = Infinity;
  pricePlaceholder = 0;
  laneHistoryActionMenu: KeyValuePair[] = laneHistoryActionMenu;

  private carrierId: string;

  constructor(private fb: FormBuilder, public loadFeedModel: LoadFeedModel, private carrierService: CarriersService) {}

  ngOnInit(): void {
    this.loadFeedModel.getBidStatuses();
    this.initForm();

    this.carrierService
      .getCarrierByDot(this.carrier.dot)
      .pipe(first())
      .subscribe((carrier) => {
        this.carrierId = carrier.id;
      });
  }

  initForm(): void {
    this.form = this.fb.group({
      price: [null, Validators.required],
      status: [BidStatusType.PENDING, Validators.required],
      notes: [null],
    });
  }

  onCancel(e: Event): void {
    e.preventDefault();
    this.toggleForm.emit();
  }

  onSubmit(e: Event, noCapacity: boolean = false): void {
    e.preventDefault();
    this.form.markAllAsTouched();

    if (this.form.valid) {
      const cleanForm = this.form.controls['notes'].value ? this.form.value : omit(this.form.value, ['notes']);
      const payload = {
        ...cleanForm,
        status: noCapacity ? BidStatusType.NO_CAPACITY : cleanForm.status,
        carrierId: this.carrierId,
        sourceType: this.user.broker ? BidSourceType.BROKER : BidSourceType.CARRIER,
      };
      this.submitBidForm.emit(payload);
    }
  }

  onCarrierAction(e: Event) {
    this.gotToBids.emit(e);
  }
}
