import { IPostTruckInput } from '@haulynx/types';
import { IFormActions, IFormTypes } from '../../shared/store/form/actions';
import { createAction, createActionTypes } from '../../shared/store/helpers/action';
import { combineAdaptersActionsState, combineAdaptersActionTypeState } from '../../shared/store/helpers/adapter';
import { ISearchActions, ISearchTypes } from '../../shared/store/search/actions';
import { ITabsActions, ITabsActionTypes } from '../../shared/store/tabs/actions';
import {
  carrierAdapter,
  carrierDashboardAdapter,
  carrierDashboardLanesAdapter,
  carrierDashboardTrucksAdapter,
  carrierTabsAdapter,
  CARRIERS_TYPE,
  TYPE_DASHBOARD_TRUCKS,
} from './adapters';

type ICarrierActions = ISearchActions & ITabsActions;
type ICarrierActionType = ISearchTypes & ITabsActionTypes;

type ICarriersTabsActions = ITabsActions;
type ICarriersTabsActionType = ITabsActionTypes;

const dashboardActionType = {
  GET_ROUTES: 'get routes',
  GET_ROUTES_SUCCESS: 'get routes success',
  GET_ROUTES_ERROR: 'get routes error',

  GET_KPI_METRICS: 'get kpi metrics',
  GET_KPI_METRICS_SUCCESS: 'get kpi metrics success',
  GET_KPI_METRICS_ERROR: 'get kpi metrics error',

  GET_CARRIER_INSURANCE_AND_SAFETY: 'get carrier insurance and safety',
  GET_CARRIER_INSURANCE_AND_SAFETY_SUCCESS: 'get carrier insurance and safety success',
  GET_CARRIER_INSURANCE_AND_SAFETY_ERROR: 'get carrier insurance and safety error',

  GET_CARRIER_STATUS: 'get carrier status',
  GET_CARRIER_STATUS_SUCCESS: 'get carrier status success',
  GET_CARRIER_STATUS_ERROR: 'get carrier status error',
};

const dashboardTrucksActionType = {
  CREATE_POSTED_TRUCK: 'create posted truck',
  CREATE_POSTED_TRUCK_SUCCESS: 'create posted truck success',
  CREATE_POSTED_TRUCK_ERROR: 'create posted truck error',

  REMOVE_POSTED_TRUCK: 'remove posted truck',
  REMOVE_POSTED_TRUCK_SUCCESS: 'remove posted truck success',
  REMOVE_POSTED_TRUCK_ERROR: 'remove posted truck error',
};

const dashboardActions = {
  getRoutes: createAction(CARRIERS_TYPE, dashboardActionType.GET_ROUTES),

  getRoutesSuccess: createAction(CARRIERS_TYPE, dashboardActionType.GET_ROUTES_SUCCESS),

  getRoutesError: createAction(CARRIERS_TYPE, dashboardActionType.GET_ROUTES_ERROR),

  getKpiMetrics: createAction(CARRIERS_TYPE, dashboardActionType.GET_KPI_METRICS),

  getKpiMetricsSuccess: createAction(CARRIERS_TYPE, dashboardActionType.GET_KPI_METRICS_SUCCESS),

  getKpiMetricsError: createAction(CARRIERS_TYPE, dashboardActionType.GET_KPI_METRICS_ERROR),

  getCarrierInsuranceAndSafety: createAction(CARRIERS_TYPE, dashboardActionType.GET_CARRIER_INSURANCE_AND_SAFETY),

  getCarrierInsuranceAndSafetySuccess: createAction(
    CARRIERS_TYPE,
    dashboardActionType.GET_CARRIER_INSURANCE_AND_SAFETY_SUCCESS
  ),

  getCarrierInsuranceAndSafetyError: createAction(
    CARRIERS_TYPE,
    dashboardActionType.GET_CARRIER_INSURANCE_AND_SAFETY_ERROR
  ),

  getCarrierStatus: createAction(CARRIERS_TYPE, dashboardActionType.GET_CARRIER_STATUS),

  getCarrierStatusSuccess: createAction(CARRIERS_TYPE, dashboardActionType.GET_CARRIER_STATUS_SUCCESS),

  getCarrierStatusError: createAction(CARRIERS_TYPE, dashboardActionType.GET_CARRIER_STATUS_ERROR),
};

const dashboardTrucksActions = {
  createPostedTruck: createAction(TYPE_DASHBOARD_TRUCKS, dashboardTrucksActionType.CREATE_POSTED_TRUCK),
  createPostedTruckSuccess: createAction(TYPE_DASHBOARD_TRUCKS, dashboardTrucksActionType.CREATE_POSTED_TRUCK_SUCCESS),
  createPostedTruckError: createAction(TYPE_DASHBOARD_TRUCKS, dashboardTrucksActionType.CREATE_POSTED_TRUCK_ERROR),

  removePostedTruck: createAction(TYPE_DASHBOARD_TRUCKS, dashboardTrucksActionType.REMOVE_POSTED_TRUCK),
  removePostedTruckSuccess: createAction(TYPE_DASHBOARD_TRUCKS, dashboardTrucksActionType.REMOVE_POSTED_TRUCK_SUCCESS),
  removePostedTruckError: createAction(TYPE_DASHBOARD_TRUCKS, dashboardTrucksActionType.REMOVE_POSTED_TRUCK_ERROR),
};

export const CarrierActions: ICarrierActions = combineAdaptersActionsState(carrierAdapter);
export const CarrierActionTypes: ICarrierActionType = combineAdaptersActionTypeState(carrierAdapter);

export const CarriersTabsActions: ICarriersTabsActions = {
  ...combineAdaptersActionsState(carrierTabsAdapter),
};

export const CarriersTabsActionsTypes: ICarriersTabsActionType = {
  ...combineAdaptersActionTypeState<ICarriersTabsActionType>(carrierTabsAdapter),
};

export const CarrierDashboardActions: ISearchActions &
  IFormActions & {
    getRoutes(params?);

    getRoutesSuccess(params?);

    getRoutesError(params?);

    getKpiMetrics(params?);

    getKpiMetricsSuccess(params?);

    getKpiMetricsError(params?);

    getCarrierInsuranceAndSafety(params?);

    getCarrierInsuranceAndSafetySuccess(params?);

    getCarrierInsuranceAndSafetyError(params?);

    getCarrierStatus(params?);

    getCarrierStatusSuccess(params?);

    getCarrierStatusError(params?);
  } = {
  ...combineAdaptersActionsState(carrierDashboardAdapter),
  ...dashboardActions,
};
export const CarrierDashboardActionTypes: ISearchTypes &
  IFormTypes & {
    GET_ROUTES: string;
    GET_ROUTES_SUCCESS: string;
    GET_ROUTES_ERROR: string;

    GET_KPI_METRICS: string;
    GET_KPI_METRICS_SUCCESS: string;
    GET_KPI_METRICS_ERROR: string;

    GET_CARRIER_INSURANCE_AND_SAFETY: string;
    GET_CARRIER_INSURANCE_AND_SAFETY_SUCCESS: string;
    GET_CARRIER_INSURANCE_AND_SAFETY_ERROR: string;

    GET_CARRIER_STATUS: string;
    GET_CARRIER_STATUS_SUCCESS: string;
    GET_CARRIER_STATUS_ERROR: string;
  } = {
  ...combineAdaptersActionTypeState(carrierDashboardAdapter),
  ...createActionTypes(dashboardActionType)(CARRIERS_TYPE),
};

export const CarrierDashboardLanesActions: ISearchActions = combineAdaptersActionsState(carrierDashboardLanesAdapter);
export const CarrierDashboardLanesActionTypes: ISearchTypes = combineAdaptersActionTypeState(
  carrierDashboardLanesAdapter
);

export const CarrierDashboardTrucksActions: ISearchActions & {
  createPostedTruck(params: IPostTruckInput | IPostTruckInput[]);
  createPostedTruckSuccess(params: { id: string | string[] });
  createPostedTruckError(params: { error: any; id: string });

  removePostedTruck(params: { id: string });
  removePostedTruckSuccess(params: { id: string });
  removePostedTruckError(params: { error: any; id: string });
} = {
  ...combineAdaptersActionsState(carrierDashboardTrucksAdapter),
  ...dashboardTrucksActions,
};
export const CarrierDashboardTrucksActionTypes: ISearchTypes & {
  CREATE_POSTED_TRUCK: string;
  CREATE_POSTED_TRUCK_SUCCESS: string;
  CREATE_POSTED_TRUCK_ERROR: string;

  REMOVE_POSTED_TRUCK: string;
  REMOVE_POSTED_TRUCK_SUCCESS: string;
  REMOVE_POSTED_TRUCK_ERROR: string;
} = {
  ...combineAdaptersActionTypeState(carrierDashboardTrucksAdapter),
  ...createActionTypes(dashboardTrucksActionType)(TYPE_DASHBOARD_TRUCKS),
};
