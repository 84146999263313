import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgModule } from '@angular/core';
import { TextAreaHandlerComponent } from './text-area-handler.component';

export const imports: typeof CommonModule[] = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  MatInputModule,
  MatFormFieldModule,
];

@NgModule({
  declarations: [TextAreaHandlerComponent],
  imports,
  exports: [TextAreaHandlerComponent],
})
export class TextAreaHandlerModule {}
