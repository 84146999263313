<div class="lane-history-timeline-container">
  <div class="lst-origin">
    <div class="lst-origin-arrow">
      <div class="lst-oa-background">
        <div class="lst-oa-foreground">
          <mat-icon>north</mat-icon>
        </div>
      </div>
    </div>
    <div class="lst-origin-information" *ngIf="(displayLane$ | async).locations | nth: 0 as pickup">
      <div class="lst-origin-location" [ngStyle]="{ 'font-size': fontSize }" data-test="load-pickup-address">
        {{ pickup.address }}
      </div>
      <div class="lst-origin-datetime" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="2px">
        <span
          class="appointment-time"
          [innerHTML]="displayLane$ | async | loadTimeRange: { stopIndex: 0, addBreak: false, date: 'MM/dd/yy' }"
          data-test="load-pickup-time"
        ></span>
      </div>
    </div>
  </div>
  <div class="lst-destination">
    <div class="lst-destination-arrow">
      <div class="lst-da-background">
        <div class="lst-da-foreground">
          <mat-icon>south</mat-icon>
        </div>
      </div>
    </div>
    <div class="lst-destination-information" *ngIf="(displayLane$ | async).locations | nth: -1 as dropoff">
      <div class="lst-destination-location" [ngStyle]="{ 'font-size': fontSize }" data-test="load-dropoff-address">
        {{ dropoff.address }}
      </div>
      <div class="lst-destination-datetime" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="2px">
        <span
          class="appointment-time"
          [innerHTML]="displayLane$ | async | loadTimeRange: { stopIndex: -1, addBreak: false, date: 'MM/dd/yy' }"
          data-test="load-dropoff-time"
        ></span>
      </div>
    </div>
  </div>
</div>
