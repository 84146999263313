import { AfterViewInit, Directive, OnDestroy, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Subscription } from 'rxjs';
import { AppDropDownComponent } from '../drop-down/components/app-drop-down/app-drop-down.component';

@Directive({ selector: '[tab-directive]' })
export class TabDirective implements AfterViewInit, OnDestroy {
  subscription: Subscription;

  constructor(
    @Optional() private autoTrigger: MatAutocompleteTrigger,
    @Optional() private control: NgControl,
    @Optional() private component: AppDropDownComponent
  ) {}

  ngAfterViewInit(): void {
    if (this.autoTrigger) {
      this.subscription = this.autoTrigger.panelClosingActions.subscribe((x) => {
        if (this.autoTrigger.activeOption) {
          const value = this.autoTrigger.activeOption.value;
          if (this.control) this.control.control.setValue(value, { emit: false });
          this.autoTrigger.writeValue(this.autoTrigger.activeOption.value);
          this.component.selectOption(this.control.value);
        } else {
          if (this.component) {
            if (this.component.chooseDefaultOnTab) {
              if (!this.component.value || this.component.keywordSearch.value !== this.component.value.toString()) {
                if (this.component.currentlyFiltered.length > 0) {
                  this.component.selectOption(this.component.currentlyFiltered[0][this.component.key]);
                } else {
                  if (this.component.data && this.component.data[0] !== undefined) {
                    this.component.selectOption(this.component.data[0][this.component.key]);
                  }
                }
              } else {
                this.component.selectOption(this.component.value);
              }
            }
          }
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
