import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BooleanFilterType, DropdownDisplay, ISearchFilter, ISearchFilterType, TextFilterType } from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { isNil } from 'lodash';
import { isObservable, Observable } from 'rxjs';
import { takeUntil, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'app-search-options-dropdown',
  templateUrl: './search-options-dropdown.component.html',
  styleUrls: ['./search-options-dropdown.component.scss'],
})
export class SearchOptionsDropdownComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('wrapper', { static: false }) wrapper: ElementRef;

  @Input() focusedFilter: ISearchFilter;
  @Input() currentTextValue: string;
  @Input() form: FormGroup;

  @Output() mouseFocus: EventEmitter<boolean> = new EventEmitter();
  @Output() searchOptionSelect: EventEmitter<string | number> = new EventEmitter();
  @Output() searchMultiOptionSelect: EventEmitter<(string | number)[]> = new EventEmitter();
  @Output() searchBooleanOptionSelect: EventEmitter<boolean> = new EventEmitter();
  @Output() closeSearchOptions: EventEmitter<void> = new EventEmitter();

  indexOfFocus = 0;
  isMouseFocus = false;
  shouldCheckClicks = true;
  optionCount = 0;
  dropdownValues: DropdownDisplay[] = [];
  booleanDefault?: boolean;
  filterType = ISearchFilterType;
  private alive = aliveWhile();

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent): void {
    if (event.key === 'ArrowDown' && this.indexOfFocus < this.getDisplayedList().length - 1) {
      this.indexOfFocus += 1;
      this.isMouseFocus = false;
    } else if (event.key === 'ArrowUp' && this.indexOfFocus > 0) {
      this.indexOfFocus -= 1;
      this.isMouseFocus = false;
    } else if (event.key === 'Enter') {
      this.searchOptionSelect.emit(this.getDisplayedList()[this.indexOfFocus].value);
    } else if (event.code === 'Space' && this.focusedFilter.type === this.filterType.MULTI_DROPDOWN) {
      this.onToggleMultiOption(this.indexOfFocus);
    }
  }

  @HostListener('mouseenter') componentContainsMouse(): void {
    this.mouseFocus.emit(true);
  }

  @HostListener('document:click', ['$event.target']) onClick(targetElement: ElementRef): void {
    const clickedInside = this.wrapper.nativeElement.contains(targetElement);
    if (!clickedInside && this.shouldCheckClicks) {
      this.mouseFocus.emit(false);
    }
  }

  ngOnInit(): void {
    if (isObservable((<TextFilterType>this.focusedFilter.keys).optionsDataIndex)) {
      (<Observable<DropdownDisplay[]>>(<TextFilterType>this.focusedFilter.keys).optionsDataIndex)
        .pipe(takeUntil(this.alive))
        .subscribe((displayValues) => {
          if (this.focusedFilter.type === ISearchFilterType.MULTI_DROPDOWN) {
            displayValues = this.setActiveOptions(displayValues);
          }
          this.dropdownValues = displayValues;
        });

      const { textFormName } = <TextFilterType>this.focusedFilter.keys;
      this.form
        .get(textFormName)
        .valueChanges.pipe(
          takeUntil(this.alive),
          withLatestFrom(<Observable<DropdownDisplay[]>>(<TextFilterType>this.focusedFilter.keys).optionsDataIndex)
        )
        .subscribe(([selectedValues, displayValues]) => {
          if (this.focusedFilter.type === ISearchFilterType.MULTI_DROPDOWN) {
            this.dropdownValues.forEach((dropdownValue) => {
              dropdownValue.isChecked = selectedValues.includes(dropdownValue.value);
            });
          }
        });
    } else if (Array.isArray((<TextFilterType>this.focusedFilter.keys).optionsDataIndex)) {
      let displayValues = <DropdownDisplay[]>(<TextFilterType>this.focusedFilter.keys).optionsDataIndex;

      if (this.focusedFilter.type === ISearchFilterType.MULTI_DROPDOWN) {
        displayValues = this.setActiveOptions(displayValues);
      }
      this.dropdownValues = displayValues;
    } else if (this.focusedFilter.type === ISearchFilterType.BOOLEAN) {
      if (!isNil((<BooleanFilterType>this.focusedFilter.keys).value)) {
        this.booleanDefault = (<BooleanFilterType>this.focusedFilter.keys).value;
      }
      // add something here to properly default when coming from a chip
    } else {
      console.error('Cannot Do dropdown because invalid values');
    }

    if (this.focusedFilter.type === ISearchFilterType.MULTI_DROPDOWN) {
      this.onSelectMultiOption();
    }
  }

  ngAfterViewInit(): void {
    if (this.focusedFilter) {
      this.shouldCheckClicks = false;
      setTimeout(() => {
        this.shouldCheckClicks = true;
      }, 500);
    }
  }

  onSelectOption(index: number): void {
    this.searchOptionSelect.emit(this.getDisplayedList()[index].value);
  }

  onSelectMultiOption(): void {
    const valuesToDisplay = this.dropdownValues.reduce(
      (acc: string[], current: DropdownDisplay) => (current?.isChecked ? [...acc, current.value] : acc),
      []
    );
    this.optionCount = valuesToDisplay.length;
    this.searchMultiOptionSelect.emit(valuesToDisplay);
  }

  onSelectBooleanOption(value: boolean): void {
    this.searchBooleanOptionSelect.emit(value);
  }

  onToggleMultiOption(index: number): void {
    const { isChecked } = this.dropdownValues[index];
    this.dropdownValues[index] = { ...this.dropdownValues[index], isChecked: !isChecked };
    this.onSelectMultiOption();
  }

  onCloseSearchOptions(): void {
    this.toggleSelection(false);
  }

  setActiveOptions(optionsData: DropdownDisplay[]): DropdownDisplay[] {
    const { textFormName } = <TextFilterType>this.focusedFilter.keys;
    const { value: formValue } = this.form.get(textFormName);

    return optionsData.map((option: DropdownDisplay) => {
      return { ...option, isChecked: formValue.includes(option.value) };
    });
  }

  toggleSelection(isChecked: boolean): void {
    this.dropdownValues = this.dropdownValues.map((option: DropdownDisplay) => {
      return { ...option, isChecked };
    });
    this.onSelectMultiOption();
  }

  getDisplayedList(): DropdownDisplay[] {
    if (this.focusedFilter.type === ISearchFilterType.MULTI_DROPDOWN) {
      return this.dropdownValues;
    }

    return this.dropdownValues.filter((dropdown) => {
      return (
        dropdown.key.toLowerCase().includes(this.currentTextValue.toLowerCase()) ||
        (dropdown.value as string).toLowerCase().includes(this.currentTextValue.toLowerCase())
      );
    });
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }
}
