import { List } from 'immutable';
import { DispatchAction } from '../../shared/store/helpers/action';
import { CarrierManagementActionTypes } from './carrier-management-state.actions';
import { CarrierManagementStateState } from './carrier-management-state.state';

export const initialCarrierManagementState: CarrierManagementStateState = new CarrierManagementStateState();

export function carrierManagementStateReducer(
  state: CarrierManagementStateState = initialCarrierManagementState,
  action: DispatchAction
): CarrierManagementStateState {
  switch (action.type) {
    case CarrierManagementActionTypes.SET_CONTACTED: {
      return state.merge({
        isLoading: true,
      });
    }

    case CarrierManagementActionTypes.SET_CONTACTED_SUCCESS:
    case CarrierManagementActionTypes.SET_CONTACTED_ERROR: {
      return state.merge({
        isLoading: false,
      });
    }

    case CarrierManagementActionTypes.SEARCH_TOP: {
      return state.merge({
        isLoadingTop: true,
      });
    }

    case CarrierManagementActionTypes.SEARCH_TOP_SUCCESS: {
      const { entities } = action.payload;

      return state.merge({
        top: List(entities),
        isLoadingTop: false,
      });
    }

    case CarrierManagementActionTypes.SEARCH_TOP_ERROR: {
      return state.merge({
        top: List([]),
        isLoadingTop: false,
      });
    }

    case CarrierManagementActionTypes.SEARCH_DEVELOPING: {
      return state.merge({
        isLoadingDeveloping: true,
      });
    }

    case CarrierManagementActionTypes.SEARCH_DEVELOPING_SUCCESS: {
      const { entities } = action.payload;

      return state.merge({
        developing: List(entities),
        isLoadingDeveloping: false,
      });
    }

    case CarrierManagementActionTypes.SEARCH_DEVELOPING_ERROR: {
      return state.merge({
        developing: List([]),
        isLoadingDeveloping: false,
      });
    }

    case CarrierManagementActionTypes.SEARCH_PROSPECTS: {
      return state.merge({
        isLoadingProspects: true,
      });
    }

    case CarrierManagementActionTypes.SEARCH_PROSPECTS_SUCCESS: {
      const { entities } = action.payload;

      return state.merge({
        prospects: List(entities),
        isLoadingProspects: false,
      });
    }

    case CarrierManagementActionTypes.SEARCH_PROSPECTS_ERROR: {
      return state.merge({
        prospects: List([]),
        isLoadingProspects: false,
      });
    }

    case CarrierManagementActionTypes.DELETE: {
      return state.merge({
        isLoading: true,
      });
    }

    case CarrierManagementActionTypes.DELETE_SUCCESS:
    case CarrierManagementActionTypes.DELETE_ERROR: {
      return state.merge({
        isLoading: false,
      });
    }

    default: {
      return state;
    }
  }
}
