import { Injectable } from '@angular/core';
import { filterDateButtons } from '@haulynx/types';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class LoadFeedFilterService {
  private dateButtons: string[] = [];

  constructor() {
    this.dateButtons = filterDateButtons;
  }

  selectDate(index: number): Date {
    switch (index) {
      case 0:
        return this.getInitialDate();
      case 1:
        return moment.utc().add(1, 'days').toDate();
      case 2:
        return moment.utc().add(2, 'days').toDate();
    }
  }

  getInitialDate(): Date {
    return moment.utc().toDate();
  }

  isToday(date: Date) {
    const today = moment().startOf('day');
    const selectedDate = moment(date).startOf('day');

    return selectedDate.unix() === today.unix();
  }

  isTomorrow(date: Date) {
    const tomorrow = moment().startOf('day').add(1, 'days');
    const selectedDate = moment(date).startOf('day');

    return selectedDate.unix() === tomorrow.unix();
  }

  isNextDay(date: Date) {
    const tomorrow = moment().startOf('day').add(2, 'days');
    const selectedDate = moment(date).startOf('day');

    return selectedDate.unix() === tomorrow.unix();
  }

  getButtonByLabel(label: string): number {
    return this.dateButtons.findIndex((button) => button === label);
  }
}
