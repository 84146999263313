import { Injectable } from '@angular/core';
import { NotificationEvent } from '@haulynx/types';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from '../../main-store/app.reducers';
import { NotificationActions } from './notification.action';
import {
  getNotificationSelector,
  isLoadingNotificationSelector,
  notificationEventTypesSelector,
  notificationQuerySelectors,
  showNotificationSelector,
  totalNotificationSelector,
} from './notification.selectors';
import { NotificationEventFilterParams } from './notification.state';

@Injectable()
export class NotificationModel {
  showNotification$: Observable<boolean> = this.store.select(showNotificationSelector);
  totalNotifications$: Observable<number> = this.store.select(totalNotificationSelector);
  isLoading$: Observable<boolean> = this.store.select(isLoadingNotificationSelector);
  notifications$: Observable<NotificationEvent[]> = this.store.select(getNotificationSelector);
  notificationEventTypes$: Observable<string[]> = this.store.select(notificationEventTypesSelector);
  query$: Observable<NotificationEventFilterParams> = this.store.select(notificationQuerySelectors);

  constructor(private store: Store<AppState>) {}

  showNotification(): void {
    this.store.dispatch(NotificationActions.showNotification());
  }

  hideNotification(): void {
    this.store.dispatch(NotificationActions.hideNotification());
  }

  setAvailableNotificationTypes(types: string[]): void {
    this.store.dispatch(NotificationActions.setAvailableNotificationTypes(types));
  }

  search(data?: NotificationEventFilterParams): void {
    this.store.dispatch(NotificationActions.search(data));
  }

  refresh(data?: NotificationEventFilterParams): void {
    this.store.dispatch(NotificationActions.refresh(data));
  }

  deactivateNotifications(creatorId: string, notificationIds: string[]): void {
    this.store.dispatch(NotificationActions.deactivateNotifications({ creatorId, notificationIds }));
  }
}
