import { OperationsPriority } from '../../loads-service';
import { User } from '../generic';

export interface BulkEditLoadsInputItem {
  loadId: string;
  price?: number;
  maxBuy?: number;
  operationsPriority?: OperationsPriority;
  assignedBrokers?: Partial<User>[];
}

export type BulkEditLoadsInput = BulkEditLoadsInputItem[];
