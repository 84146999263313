import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'expand-panel',
  templateUrl: './expand-panel.component.html',
  styleUrls: ['./expand-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpandPanelComponent {
  @Input() isExpanded = true;
  @Input() cls = '';
  @Output() onExpanded = new EventEmitter();

  viewContent() {
    const isExpanded = !this.isExpanded;

    this.isExpanded = isExpanded;
    this.onExpanded.emit(isExpanded);
  }
}
