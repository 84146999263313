<haulynx-back-button
  *ngIf="referrer"
  label="Loads Search"
  [url]="referrer"
  class="load-active-details__back-button"
></haulynx-back-button>

<div class="load-active-details" *ngIf="loadDetailsModel.isSelected$ | async as isSelected">
  <div class="load-active-status" [ngClass]="{ 'load-overview': loadOverview }">
    <app-load-timeline-status
      *ngIf="isSelected"
      class="timeline"
      [bookedLoad]="isBookedLoad && !finalledLoad"
      [finalledLoad]="finalledLoad"
      [loadOverview]="loadOverview"
      [load]="load"
      [showStatus]="false"
      [showIndicator]="true"
      [selectedStepId]="activeStep?.id"
      (actionClick)="setActiveStep(load?.id, $event)"
      (changeStepStatusLabel)="onChangeLoadStatus($event)"
    ></app-load-timeline-status>
    <div class="quick-action-container" *ngIf="activeStep && !finalledLoad">
      <div class="timeline__header">
        <span class="status">
          <span *ngIf="!loadOverview">{{ currentStatus }}</span>
        </span>
      </div>

      <div class="quick-action" *ngIf="activeStep.type === loadStepType.ASSIGNED">
        <div class="quick-action--header">{{ 'add driver info' | uppercase }}</div>
        <app-load-quick-driver-form
          data-test="quick-driver-form"
          [bookedLoad]="isBookedLoad && !finalledLoad"
          [load]="load"
          [featureFlags]="featureFlags"
          [formData]="loadDetailsModel.assignDriverForm$ | async"
          [drivers]="commonEntities.graphQlDrivers.entities$ | async"
          [trucks]="trucksAndDevice$ | async"
          [trailers]="commonEntities.graphQlTrailers.entities$ | async"
          [equipments]="equipments"
          [isLoading]="loadDetailsModel.form.isLoading$ | async"
          [isLoadingDrivers]="commonEntities.graphQlDrivers.isLoading$ | async"
          [isLoadingTrucks]="commonEntities.graphQlTrucks.isLoading$ | async"
          [isLoadingTrailers]="commonEntities.graphQlTrailers.isLoading$ | async"
          [isLoadingCreateEntity]="loadDetailsModel.isLoadingCreateEntity$ | async"
          [driverToPopulate]="loadDetailsModel.driverToPopulate$ | async"
          [truckToPopulate]="loadDetailsModel.truckToPopulate$ | async"
          [trailerToPopulate]="loadDetailsModel.trailerToPopulate$ | async"
          (addDriver)="onAddDriver()"
          (addTruck)="onAddTruck()"
          (addTrailer)="onAddTrailer()"
          (submit)="submitDriver(load?.id, $event)"
        >
        </app-load-quick-driver-form>
      </div>
      <div class="quick-action" *ngIf="activeStep.type === loadStepType.DISPATCHED">
        <div class="quick-action--header">{{ 'dispatch' | uppercase }}</div>
        <app-load-dispatch-form
          [bookedLoad]="isBookedLoad && !finalledLoad"
          [formData]="loadDetailsModel.dispatchForm$ | async"
          [isLoading]="loadDetailsModel.form.isLoading$ | async"
          (saveDispatchForm)="submitDispatch(load?.id, $event)"
          data-test="quick-dispatch-form"
        >
        </app-load-dispatch-form>
      </div>
      <div
        class="quick-action"
        *ngIf="
          activeStep.type === loadStepType.DROPOFF ||
          activeStep.type === loadStepType.PICKUP ||
          activeStep.type === loadStepType.DROPOFF_STOP ||
          activeStep.type === loadStepType.PICKUP_STOP
        "
      >
        <div>{{ 'confirm ' + (load?.locations | nth: activeStep.id | get: 'locationType':'') | uppercase }}</div>
        <app-load-quick-location
          data-test="quick-location"
          [bookedLoad]="isBookedLoad && !finalledLoad"
          [isLoading]="loadDetailsModel.form.isLoading$ | async"
          [location]="activeStep.data"
          [lastStep]="lastStep?.data"
          [formData]="loadDetailsModel.locationForm$ | async"
          (saveLocation)="submitLocation(load?.id, activeStep.id, $event)"
        >
        </app-load-quick-location>
      </div>
    </div>
  </div>
  <div class="details-container" *ngIf="!loadId">
    <div class="active-map-source">
      <app-map-routes
        [padding]="50"
        [sources]="route"
        [dark]="true"
        class="load-map-expand"
        [showTruckRoute]="true"
        [zoom]="3"
        [isLoading]="loadDetailsModel.isLoadingRoutes$ | async"
      >
      </app-map-routes>
    </div>
    <app-active-load-details-pane
      [load]="load"
      [showEditPriceButton]="showEditPriceButton"
      (viewAllNotes)="onViewAllNotes($event)"
      [showEditPayButton]="isBookedLoad && isPayLineEdit"
      (editPay)="onEditPayDetails($event)"
      (loadDetails)="onLoadDetails()"
      [isLoadingEditPaymentPrice]="paymentTypeStateModel.isSaving$ | async"
    >
    </app-active-load-details-pane>
  </div>
</div>
