import { Pipe, PipeTransform } from '@angular/core';
import { BidStatusType, BookStatus } from '@haulynx/types';
import { startCase } from 'lodash';

@Pipe({
  name: 'laneHistoryStatus',
})
export class LaneHistoryStatusPipe implements PipeTransform {
  transform(value: string) {
    let action = '';

    if (Object.values(BookStatus).some((v) => v === value)) {
      switch (value) {
        case BookStatus.BOOKABLE:
          action = 'Boookable';
          break;
        case BookStatus.BOOKED:
          action = 'Booked';
          break;
        case BookStatus.PAUSE:
          action = 'Bounced';
          break;
        case BookStatus.EXCLUDED:
          break;
        case BookStatus.VIEWABLE:
          action = 'Viewable';
          break;
        default:
          action = startCase(value);
          break;
      }
      return `Load ${action}`;
    } else {
      switch (value) {
        case BidStatusType.ACCEPTED:
          action = 'Accepted';
          break;
        case BidStatusType.AUTO_REJECTED:
          action = 'Rejected';
          break;
        case BidStatusType.LIVE:
          action = 'Live';
          break;
        case BidStatusType.PENDING:
          action = 'Pending';
          break;
        case BidStatusType.PRICE_REJECTED:
          action = 'Rejected';
          break;
        case BidStatusType.NO_CAPACITY:
          action = 'No Capacity';
          break;
        default:
          action = startCase(value);
          break;
      }
      return `Bid ${action}`;
    }
  }
}
