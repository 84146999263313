import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { buttonTypes } from '@haulynx/types';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-load-price-form',
  templateUrl: './load-price-form.component.html',
  styleUrls: ['./load-price-form.component.scss'],
})
export class LoadPriceFormComponent {
  form: FormGroup;
  buttons$ = new BehaviorSubject([]);
  label: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { isMaxBuy: boolean; price: string },
    public dialogRef: MatDialogRef<LoadPriceFormComponent>,
    private formBuilder: FormBuilder
  ) {
    this.label = data.isMaxBuy ? 'Max Buy' : 'Price';
    this.form = this.formBuilder.group({
      price: [this.data.price, Validators.min(0)],
    });
    this.buttons$.next([buttonTypes.SAVE]);
  }

  onAction(action) {
    if (action === buttonTypes.SAVE.action) {
      if (this.form.valid) {
        const data = this.form.getRawValue();

        this.dialogRef.close(data);
      }
    }
  }
}
