import { Injectable } from '@angular/core';
import { HttpStatus, PaymentItemSearchCriteria, PaymentsTypeForm } from '@haulynx/types';
import { listToArray } from '@haulynx/utils';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppState } from '../../main-store/app.reducers';
import { PaymentTypesActions, PaymentTypesActionTypes } from './payment-types.actions';
import {
  httpStatusSelector,
  initialPaymentTypesSelector,
  isLoadingSelector,
  isSavingSelector,
  paymentTypesSelector,
} from './payment-types.selectors';

@Injectable({ providedIn: 'root' })
export class PaymentTypeStateModel {
  initialPaymentsTypes$: Observable<PaymentsTypeForm[]> = this.store.pipe(
    select(initialPaymentTypesSelector),
    map((paymentTypes) => listToArray(paymentTypes))
  );
  paymentsTypes$: Observable<PaymentsTypeForm[]> = this.store.pipe(
    select(paymentTypesSelector),
    map((paymentTypes) => listToArray(paymentTypes))
  );
  savePaymentTypeFinally$: Observable<unknown> = this.actions$.pipe(
    ofType(PaymentTypesActionTypes.SAVE_PAYMENT_TYPE_SUCCESS, PaymentTypesActionTypes.SAVE_PAYMENT_TYPE_ERROR)
  );
  isLoading$: Observable<boolean> = this.store.pipe(select(isLoadingSelector));
  isSaving$: Observable<boolean> = this.store.pipe(select(isSavingSelector));
  httpStatus$: Observable<HttpStatus> = this.store.pipe(select(httpStatusSelector));

  constructor(private store: Store<AppState>, private actions$: Actions) {}

  addPaymentTypeState(data: { payment: PaymentsTypeForm; index: number }): void {
    this.store.dispatch(PaymentTypesActions.addPaymentTypeState(data));
  }

  removePayment(data: { index: number }): void {
    this.store.dispatch(PaymentTypesActions.removePaymentTypeState(data));
  }

  updatePaymentTypeState(data: { index: number; payment: Partial<PaymentsTypeForm> }): void {
    this.store.dispatch(PaymentTypesActions.updatePaymentTypeState(data));
  }

  getPaymentTypes(data: Partial<PaymentItemSearchCriteria>): void {
    this.store.dispatch(PaymentTypesActions.getPaymentType(data));
  }

  savePaymentsTypes(
    orderNumber: string,
    loadId: string,
    payments: PaymentsTypeForm[],
    sendEditPriceParams: { userEmail: string }
  ): void {
    this.store.dispatch(PaymentTypesActions.savePayments({ orderNumber, loadId, payments, sendEditPriceParams }));
  }

  clearState(): void {
    this.store.dispatch(PaymentTypesActions.clearState());
  }
}
