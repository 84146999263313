import { LoadsServiceLoadStatus } from '../../loads-service';
import { RegionTypes } from '../location';

export interface LaneRateToolSearchBarResult {
  minBidCount?: number;
  maxBidCount?: number;
  minWeight?: number;
  maxWeight?: number;
  truckUnitId?: string;
  tmwNumber?: string;
  loadStatus?: LoadsServiceLoadStatus;
  shipper?: string;
  minRevenue?: number;
  maxRevenue?: number;
  region?: RegionTypes;
  receiver?: string;
  priorityValue?: string;
  minPrice?: number;
  maxPrice?: number;
  firstAppointmentStart?: number;
  firstAppointmentEnd?: number;
  originRadius?: number[];
  originLat?: number[];
  originLon?: number[];
  originLocation?: string[];
  orderNumber?: string;
  minMaxBuy?: number;
  maxMaxBuy?: number;
  minHighestBid?: number;
  maxHighestBid?: number;
  equipment?: string;
  driverName?: string;
  minDistance?: number;
  maxDistance?: number;
  destinationRadius?: number[];
  destinationLat?: number[];
  destinationLon?: number[];
  destinationLocation?: string[];
  lastAppointmentEnd?: number;
  deliveryDateTo?: number;
  minCxPriority?: number;
  maxCxPriority?: number;
  commodity?: string;
  customer?: string;
  carrierNameOrDot?: string;
  brokerNameOrUsxId?: string;
  billTo?: string;
  minOperationsPriority?: number;
}
