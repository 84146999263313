<button [matDialogClose]="null" class="close-button">
  <mat-icon svgIcon="haulynx-close" class="close-icon"></mat-icon>
</button>
<div class="warning-dialog" data-test="warning-dialogue">
  <h1 mat-dialog-title>{{ 'Hazmat Warning' | uppercase }}</h1>
  <div mat-dialog-content class="dialog-content">
    <mat-icon svgIcon="haulynx-warning-sign" class="icon"></mat-icon>
    <p class="info">You are about to accept a hazmat load, is your driver certified?</p>
  </div>

  <div mat-dialog-actions class="buttons">
    <button mat-raised-button color="warn" [mat-dialog-close]="false" class="dialog-button" data-test="no-button">
      No
    </button>
    <button
      mat-raised-button
      color="accent"
      [mat-dialog-close]="true"
      class="dialog-button"
      cdkFocusInitial
      data-test="yes-button"
    >
      Yes
    </button>
  </div>
</div>
