import gql from 'graphql-tag';

export const BulkEditLoads = gql`
  mutation BulkEditLoads($loadEdits: [BulkEditLoadsInput]!) {
    bulkEditLoadsServiceLoads(loadEdits: $loadEdits) {
      assignedBrokers {
        id
        name
        usxId
      }
      dispatchLocation {
        address
        lat
        lon
        timestamp
        timezone
      }
      loadStatus
      id
      bookStatus
      carrier {
        name
        id
        dot
        mcNumber
        email
        phone
        owner
      }
      broker {
        name
        id
        usxId
      }
      drivers {
        id
        name
      }
      truck {
        id
        unitId
      }
      trailers {
        id
        trailerNumber
      }
      locations {
        customer {
          name
          number
          customerAttributes {
            canPickUpEarly
            canDeliverEarly
            dropYard
            canBreakAtFacility
            hasFacilities
          }
        }
        id
        address
        city
        state
        zipcode
        timezone
        appointmentSet
        appointmentStart
        appointmentEnd
        locationType
        billOfLading
        geometry {
          type
          coordinates
        }
        distanceMiles
      }
      provider {
        id
        name
        detailType
        company
      }
      providerDetails {
        billTo
        billToName
        cxPriority
        equipmentType
        commodity
        weight: weightLbs
        quantity
        operationsPriority
        orderType
        internalNotes
        externalNotes
        alternateIds {
          identifierType
          identifierValue
        }
      }
      paymentDetails {
        price
        revenue
        maxBuy
        ratePerMile
        distanceMiles
      }
      bidDetails {
        offerCount
        lowestOffer
        lowestOfferCarrierName
      }
      restrictions {
        type
        value
      }
      rateConEmails {
        emailRecipient
      }
      trackingType
    }
  }
`;
