import { Injectable } from '@angular/core';
import {
  LoadLocationType,
  LoadsServiceLoadLocation,
  LoadsServiceLoadStatus,
  UnauthenticatedLoadsServiceLoad,
} from '@haulynx/types';
import { orderBy } from 'lodash';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class SharedLocationService {
  canViewLoad(load: UnauthenticatedLoadsServiceLoad): boolean {
    if (!load.carrierAccepted) {
      return false;
    }

    if (load.loadStatus === LoadsServiceLoadStatus.DELIVERED && load.truck) {
      return false;
    }

    const now = moment();

    const pickupTimeLimit: moment.Moment = this.getLoadTime(load, LoadLocationType.PICKUP).subtract(6, 'hours');

    if (now.isAfter(pickupTimeLimit) && !load.dateCompleted) {
      return true;
    }

    const deliveryTimeLimit: moment.Moment = this.getLoadTime(load, LoadLocationType.PICKUP).add(1, 'hours');

    return now.isBefore(deliveryTimeLimit);
  }

  private getLoadTime(load: UnauthenticatedLoadsServiceLoad, type: LoadLocationType): moment.Moment {
    if (load.locations && load.locations.length) {
      const { locations } = load;
      let sortedLoadLocations = orderBy(locations, ['appointmentStart']);

      if (type === LoadLocationType.DROPOFF) {
        sortedLoadLocations = sortedLoadLocations.reverse();
      }

      const pickupLoad: LoadsServiceLoadLocation = sortedLoadLocations.find((ll) => ll.locationType === type);

      if (pickupLoad) {
        return moment.unix(pickupLoad.appointmentStart);
      }
    }

    return null;
  }
}
