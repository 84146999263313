import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'delete-load--template-dialog',
  template: `
    <h2 matDialogTitle>Delete Load Template</h2>
    <mat-dialog-content>
      <span>Are you sure you want to Delete this Template? <br /></span>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-button color="primary" [matDialogClose]="true">Yes</button>
      <button mat-button color="warn" [matDialogClose]="">Cancel</button>
    </mat-dialog-actions>
  `,
})
export class DeleteLoadTemplateDialog {
  constructor(public dialogRef: MatDialogRef<DeleteLoadTemplateDialog>) {}
}
