import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  CarrierInfoSectionVmService,
  LoadQuickDriverForm,
  LoadQuickDriverFormVm,
  TrackingTypeOptions,
} from '@haulynx/services';
import {
  AssignDriverForm,
  Driver,
  EntityOptions,
  EquipmentTypes,
  FeatureFlag,
  FFState,
  LoadsServiceLoad,
  TrackingType,
  Trailer,
  TrailerOwnerType,
  Truck,
  VehicleEntityTypes,
} from '@haulynx/types';
import { aliveWhile, listToArray } from '@haulynx/utils';
import { List } from 'immutable';
import { find, get } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppDropDownComponent } from '../drop-down/components/app-drop-down/app-drop-down.component';

@Component({
  selector: 'app-load-quick-driver-form',
  templateUrl: './load-quick-driver-form.component.html',
  styleUrls: ['./load-quick-driver-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadQuickDriverFormComponent implements OnChanges, OnDestroy {
  @ViewChild('driverList') driverList: AppDropDownComponent;
  @ViewChild('truckList') truckList: AppDropDownComponent;
  @ViewChild('trailerList') trailerList: AppDropDownComponent;

  @Input() isLoading = false;
  @Input() bookedLoad = true;
  @Input() load: LoadsServiceLoad;
  @Input() trucks: Truck[] | List<Truck>;
  @Input() drivers: Driver[] | List<Driver>;
  @Input() trailers: Trailer[] | List<Trailer>;
  @Input() equipments: EquipmentTypes[];
  @Input() featureFlags: FFState;
  @Input() formData: Partial<AssignDriverForm>;
  @Input() isLoadingDrivers = false;
  @Input() isLoadingTrucks = false;
  @Input() isLoadingTrailers = false;
  @Input() isLoadingCreateEntity = false;
  @Input() driverToPopulate: string;
  @Input() truckToPopulate: string;
  @Input() trailerToPopulate: string;
  @Output() addDriver = new EventEmitter();
  @Output() addTruck = new EventEmitter();
  @Output() addTrailer = new EventEmitter();
  @Output() submit = new EventEmitter<AssignDriverForm>();

  form: FormGroup;
  entityTypes = VehicleEntityTypes;
  trucks$ = new BehaviorSubject([]);
  drivers$ = new BehaviorSubject([]);
  trailers$ = new BehaviorSubject([]);
  alive = aliveWhile();
  trackingType = TrackingType;
  trailerOwner = TrailerOwnerType;
  trackingOptions: TrackingTypeOptions[] = [];
  trackingFeatureFlag = FeatureFlag.TRACKING_TYPE;

  constructor(
    private loadQuickDriverFormVm: LoadQuickDriverFormVm,
    private carrierInfoSectionVmService: CarrierInfoSectionVmService
  ) {
    this.form = this.loadQuickDriverFormVm.create();
    this.form.get('equipment').disable();

    this.form.controls.driver.valueChanges.pipe(takeUntil(this.alive)).subscribe((driverId) => {
      if (driverId) {
        const selectedDriver = find(this.drivers$.value, (driver) => driver.id === driverId);
        const phone = get(selectedDriver, 'phone', null);

        this.form.patchValue({ phone });
      } else {
        this.form.controls['phone'].setValue(null);
      }
    });
  }

  ngAfterViewInit() {
    this.trackingOptions = this.carrierInfoSectionVmService.getDefaultTrackingOptions(this.featureFlags);
    this.trackingOptions = this.carrierInfoSectionVmService.getAdditionalTrackingOptions(
      this.load?.carrier,
      this.trackingOptions
    );
  }

  selectAssignment(entity: VehicleEntityTypes): void {
    const { trackingType, driver, truck, trailer, trailerOwner } = this.form.getRawValue();

    if (!driver && entity === VehicleEntityTypes.DRIVERS) {
      return this.addDriver.emit();
    }

    if (!truck && entity === VehicleEntityTypes.TRUCKS) {
      return this.addTruck.emit();
    }

    if (!trailer && entity === VehicleEntityTypes.TRAILERS) {
      return this.addTrailer.emit();
    }

    this.form = this.loadQuickDriverFormVm.getTrackingValidation(trackingType, this.form);
  }

  save(event: Event, formData: LoadQuickDriverForm): void {
    if (this.form.valid) {
      event.stopPropagation();
      const { trackingType, driver, equipment, phone, trailer, trailerOwner, truck } = formData;

      const newFormData: AssignDriverForm = {
        trackingType: find(this.trackingType, (item) => item === trackingType),
        driver: find(this.drivers$.value, (item) => item.id === driver),
        truck: find(this.trucks$.value, (item) => item.id === truck),
        trailerOwner: find(this.trailerOwner, (item) => item === trailerOwner),
        trailer: find(this.trailers$.value, (item) => item.id === trailer),
        phone,
        equipment,
      };

      this.submit.emit(newFormData);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { formData, trucks, drivers, trailers, driverToPopulate, truckToPopulate, trailerToPopulate } = changes;

    this.form = this.loadQuickDriverFormVm.getTrackingValidation(this.form.controls['trackingType'].value, this.form);

    if (formData) {
      const currentFormData = formData.currentValue || {};
      this.form.setValue(currentFormData);
    }

    if (trailerToPopulate && trailerToPopulate.currentValue && this.trailerList) {
      this.form.patchValue({ trailer: trailerToPopulate.currentValue });
      this.trailerList.closeDropDown();
    }

    if (trailers && this.featureFlags) {
      const newTrailers = listToArray(trailers.currentValue);
      const newTrailersList = this.featureFlags[FeatureFlag.INLINE_CREATION_ALM]
        ? [{ trailerNumber: EntityOptions.ADD_TRAILER }, ...newTrailers]
        : newTrailers;

      this.trailers$.next(newTrailersList);
    }

    if (truckToPopulate && truckToPopulate.currentValue && this.truckList) {
      this.form.patchValue({ truck: truckToPopulate.currentValue });
      this.truckList.closeDropDown();
    }

    if (trucks && this.featureFlags) {
      const newTrucks = listToArray(trucks.currentValue);
      const newTrucksList = this.featureFlags[FeatureFlag.INLINE_CREATION_ALM]
        ? [{ unitId: EntityOptions.ADD_TRUCK }, ...newTrucks]
        : newTrucks;

      this.trucks$.next(newTrucksList);
    }

    if (driverToPopulate && driverToPopulate.currentValue && this.driverList) {
      this.form.patchValue({ driver: driverToPopulate.currentValue });
      this.driverList.closeDropDown();
    }

    if (drivers && this.featureFlags) {
      const newDrivers = listToArray(drivers.currentValue);
      const newDriversList = this.featureFlags[FeatureFlag.INLINE_CREATION_ALM]
        ? [{ name: EntityOptions.ADD_DRIVER }, ...newDrivers]
        : newDrivers;

      this.drivers$.next(newDriversList);
    }
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }
}
