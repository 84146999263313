import { CarrierForm } from '../carrier';
import { AssignmentForm, DispatchForm, OrderForm } from '../forms';
import { PaymentsTypeForm } from '../payment';
import { AssignLoadEditForm } from './assign-load-edit-form';

export class IAssignLoadCarrierForm {
  dispatchInfo: DispatchForm;
  orderInfo: OrderForm;
  carrierInfo: CarrierForm;
  payments: PaymentsTypeForm[];
  assignmentInfo: AssignmentForm;
  editLoad: AssignLoadEditForm;
}

export class AssignLoadCarrierForm {
  orderInfo: OrderForm;
  carrierInfo: CarrierForm;
  payments: PaymentsTypeForm[];
  assignmentInfo: AssignmentForm;
  dispatchInfo: DispatchForm;
  editLoad: AssignLoadEditForm;

  constructor(options = {}) {
    const key = Object.keys(this);
    const defaultValue = [null];

    key.forEach((prop) => {
      this[prop] = (options && options[prop]) || defaultValue;
    });
  }
}
