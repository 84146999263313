import { Injectable } from '@angular/core';
import { AnalyticsService } from '@haulynx/services';
import { ANALYTICS_EVENT } from '@haulynx/types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { distinctUntilKeyChanged, map, tap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '../../main-store/app.reducers';
import { DispatchAction } from '../../shared/store/helpers/action';
import {
  CarrierActionTypes,
  CarrierDashboardActionTypes,
  CarrierDashboardTrucksActionTypes,
  CarriersTabsActionsTypes,
} from './carriers.action';
import { carrierSearchSelector } from './carriers.selectors';

@Injectable()
export class CarriersAnalyticsEffects {
  logCarrierSearch = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CarrierActionTypes.SEARCH),
        map((action: DispatchAction) => action.payload),
        withLatestFrom(this.store.select(carrierSearchSelector.getQuery)),
        tap(([payload, searchQuery]) => {
          this.analytics.logEvent(ANALYTICS_EVENT.CARRIER_SEARCH, { searchQuery });
        })
      ),
    { dispatch: false }
  );

  logDetailsCarrierTabSelect = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CarrierActionTypes.SELECT_TAB),
        map((action: DispatchAction) => action.payload),
        distinctUntilKeyChanged('id'),
        tap((payload) => {
          this.analytics.logEvent(ANALYTICS_EVENT.CRM_TAB_SELECT, { tabId: payload.id });
        })
      ),
    { dispatch: false }
  );

  carrierTabsAdd = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CarriersTabsActionsTypes.ADD_TAB),
        tap(() => {
          // this.carrierDashboardLoadingTrace = this.performance.trace('Carrier Dashboard Loading');
          // this.carrierDashboardLoadingTrace.start();
          // this.stopCarrierDashboardLoadingTrace = false;
        })
      ),
    { dispatch: false }
  );

  carrierDashboardLanesSearch = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CarrierDashboardActionTypes.SEARCH_SUCCESS, CarrierDashboardActionTypes.SEARCH_ERROR),
        tap(() => {
          // this.stopCarrierDashboardLoadingTrace = true;
        })
      ),
    { dispatch: false }
  );

  carrierDashboardGetRoutesSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CarrierDashboardActionTypes.GET_ROUTES_SUCCESS),
        tap(() => {
          // if (this.stopCarrierDashboardLoadingTrace && this.carrierDashboardLoadingTrace) {
          //   try {
          //     this.carrierDashboardLoadingTrace.stop();
          //     this.stopCarrierDashboardLoadingTrace = false;
          //     this.carrierDashboardLoadingTrace = null;
          //   } catch (error) {
          //     // Ignore traces that are not running
          //   }
          // }
        })
      ),
    { dispatch: false }
  );

  carrierDashboardGetRoutesError = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CarrierDashboardActionTypes.GET_ROUTES_ERROR),
        map((action: DispatchAction) => action.payload),
        tap((payload) => {
          // if (this.stopCarrierDashboardLoadingTrace && this.carrierDashboardLoadingTrace) {
          //   try {
          //     this.carrierDashboardLoadingTrace.putAttribute(`Error`, payload);
          //     this.carrierDashboardLoadingTrace.stop();
          //     this.stopCarrierDashboardLoadingTrace = false;
          //     this.carrierDashboardLoadingTrace = null;
          //   } catch (error) {
          //     // Ignore traces that are not running
          //   }
          // }
        })
      ),
    { dispatch: false }
  );

  // Posting Trucks Analytics
  createPostedTruck = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CarrierDashboardTrucksActionTypes.CREATE_POSTED_TRUCK),
        map((action: DispatchAction) => action.payload),
        tap((payload) => {
          // this.createPostedTruckLoadingTrace = this.performance.trace('Create Posted Truck');
          // this.createPostedTruckLoadingTrace.start();
          // const { trucks = [] } = payload || {};
          // this.createPostedTruckLoadingTrace.incrementMetric('Number of Trucks', trucks.length);
        })
      ),
    { dispatch: false }
  );

  createPostedTruckSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CarrierDashboardTrucksActionTypes.SEARCH_SUCCESS),
        tap(() => {
          // if (this.createPostedTruckLoadingTrace) {
          //   try {
          //     this.createPostedTruckLoadingTrace.stop();
          //     this.createPostedTruckLoadingTrace = null;
          //   } catch (error) {
          //     // Ignore traces that are not running
          //   }
          // }
        })
      ),
    { dispatch: false }
  );

  createPostedTruckError = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CarrierDashboardTrucksActionTypes.SEARCH_ERROR),
        map((action: DispatchAction) => action.payload),
        tap((payload) => {
          // if (this.createPostedTruckLoadingTrace) {
          //   try {
          //     this.createPostedTruckLoadingTrace.putAttribute(`Error`, payload);
          //     this.createPostedTruckLoadingTrace.stop();
          //     this.createPostedTruckLoadingTrace = null;
          //   } catch (error) {
          //     // Ignore traces that are not running
          //   }
          // }
        })
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private store: Store<AppState>, private analytics: AnalyticsService) {}
}
