import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FavoriteAddressModel } from '@haulynx/store';
import { aliveWhile } from '@haulynx/utils';

@Component({
  selector: 'app-address-favorite',
  templateUrl: './address-favorite.component.html',
  styleUrls: ['./address-favorite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressFavoriteComponent implements OnDestroy {
  selected = null;
  alive = aliveWhile();

  constructor(
    public favoriteAddressModel: FavoriteAddressModel,
    public dialog: MatDialogRef<AddressFavoriteComponent>
  ) {
    this.favoriteAddressModel.search();
  }

  actionSelect() {
    this.dialog.close(this.selected);
  }

  actionClear() {
    this.selected = null;
  }

  select({ row }) {
    this.selected = row;
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }
}
