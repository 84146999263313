import { LaneRateToolComponent } from './lane-rate-tool.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, LaneRateToolGuard } from '@haulynx/guards';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard, LaneRateToolGuard],
    component: LaneRateToolComponent,
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LaneRateToolRoutingModule {}
