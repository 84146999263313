import { BookStatus, Load, LoadLocationType, LoadOrderType } from '@haulynx/types';
import { RecommendedCarriers } from '@haulynx/types';

export const recommendedCarriers: Partial<RecommendedCarriers[]> = [
  {
    id: 'USXTRILIL',
    name: 'Triple T Express Inc',
    sourceType: 'Traditional',
    dot: '2126609',
    brokerId: 'RSOHLBERG',
    email: 'tripletexpressinc@gmail.com',
    owner: 'UNKNOWN',
    load: <any>{
      id: '-MDWJAUlL5Wx10NSmocy',
    },
  },
  {
    id: 'USXLTCDAR',
    name: 'Cadence Premier Cargo, Inc.',
    sourceType: 'Digital',
    dot: '1861195',
    brokerId: 'JKUHENBEAKER',
    email: 'jimmy@cadencepremier.com',
    owner: 'JKUHENBEAK',
    load: <any>{
      id: '-MB56CjjSMq24gEJj4ng',
    },
  },
  {
    id: 'USXFRELEW',
    name: 'Freight 88',
    sourceType: 'Traditional',
    dot: '3001803',
    brokerId: 'IHOWARD',
    email: 'freight88llc@outlook.com',
    owner: 'UNKNOWN',
    load: <any>{
      id: '-MDouwzLH2FVpghJi6pU',
    },
  },
  {
    id: 'USXSCHGRE01',
    name: 'Schneider National Carriers, Inc.',
    sourceType: 'Traditional',
    dot: '264184',
    brokerId: 'ZSTEPHENS',
    email: 'raddatzs@schneider.com',
    owner: 'UNKNOWN',
    load: <any>{
      id: '-MDUdJFWLkYDX_-M7CeC',
    },
  },
  {
    id: 'USXBARPOM',
    name: 'Barnett Luna Transport Inc',
    sourceType: 'Digital',
    dot: '2853128',
    brokerId: 'EFIRAT',
    email: 'roger.bltransportinc@gmail.com',
    owner: 'EFIRAT',
    load: <any>{
      id: '-MDtXVS8_cZUCLnxcQ8U',
    },
  },
  {
    id: 'USXSAFARL',
    name: 'Safeway Truck Lines LLC',
    sourceType: 'Traditional',
    dot: '2328966',
    brokerId: 'RAYERS',
    email: 'jtrimble@safewaytrucklines.com',
    owner: 'UNKNOWN',
    load: <any>{
      id: '-MDZyi2Kj_iYtUYAw_wj',
    },
  },
  {
    id: 'USXLAWROA',
    name: 'Lawrence',
    sourceType: 'Traditional',
    dot: '84337',
    brokerId: 'DMORLAES',
    email: 'tchildress@lawrencetransportation.com',
    owner: 'UNKNOWN',
    load: <any>{
      id: '-MEjP78eOo5Lg5mdGBq0',
    },
  },
  {
    id: 'USXNILSAC',
    name: 'Nile Express, LLC',
    sourceType: 'Traditional',
    dot: '1479667',
    brokerId: 'RSOHLBERG',
    email: 'gowithnexpress@gmail.com',
    owner: 'UNKNOWN',
    load: <any>{
      id: '-MBZgMYG2ESx9sAL1d0w',
    },
  },
  {
    id: 'USXWFTGRI',
    name: 'Wf Transport LLC',
    sourceType: 'Traditional',
    dot: '1800174',
    brokerId: 'JMOLLNER',
    email: 'wftransport@gmail.com',
    owner: 'UNKNOWN',
    load: <any>{
      id: '-MBoQi3inWz-ZKEtCFwq',
    },
  },
];

export const load: Partial<Load> = {
  loadLocations: [
    {
      name: '1765 SPRING VALLEY RD, TUSCUMBIA AL, 35674',
      departureTime: 0,
      arrivalTime: null,
      equipmentType: 'Dry van',
      timestamp: 1586804400000,
      timezone: 'America/Chicago',
      summaryAddress: ' TUSCUMBIA AL, 35674',
      loadWeight: '45000',
      billOfLading: '5669274',
      packingType: null,
      locationType: LoadLocationType.PICKUP,
      customerId: 'SIMS BARK CO INC',
      appointmentSet: true,
      commodity: '',
      completed: 0,
      creator: '',
      customerNumber: '',
      estimatedWaitTime: 12,
      lat: 1,
      lon: 2,
      poNumber: '',
      quantity: 1,
      specialNotes: '',
      temperature: '',
      waitTime: 2,
    },
    {
      name: '725 CAMPBELLSVILLE BYPASS, CAMPBELLSVILLE KY, 42718',
      departureTime: 0,
      arrivalTime: null,
      equipmentType: 'Flat bed',
      timestamp: 1586862000000,
      timezone: 'America/New_York',
      summaryAddress: ' CAMPBELLSVILLE KY, 42718',
      loadWeight: '0',
      billOfLading: '5669274',
      packingType: null,
      locationType: LoadLocationType.DROPOFF,
      customerId: 'WALMART # 665',
      appointmentSet: true,
      commodity: '',
      completed: 0,
      creator: '',
      customerNumber: '',
      estimatedWaitTime: 12,
      lat: 1,
      lon: 2,
      poNumber: '',
      quantity: 1,
      specialNotes: '',
      temperature: '',
      waitTime: 2,
    },
  ],
  bookStatus: BookStatus.BOOKABLE,
  regionalManagerEmail: 'North@usxpress.com',
  regionalManagerPhone: '312-660-2222',
  regionalManager: 'VLESAGE',
  tmwNumber: '3605985',
  price: 700,
  revenue: 725,
  id: '-M0TEhJGyNffMq09LIxP1',
  ratePerMile: '2.661596958174905',
  shipper: null,
  broker: {
    id: '-LiiecI-H-hTecUQUKSA',
    name: 'U.S. XPRESS, INC.',
    phone: '4804404400',
    licenseNumber: '',
    address: '',
    credits: 0,
    dot: '',
    insurance: '',
    isHaulynxBroker: true,
  },
  creator: 'eaRepWJ665bkbwbTMzhadxC5jPC3',
  pickupCity: null,
  carrier: null,
  status: 'active',
  orderType: LoadOrderType.TRANSACTIONAL,
  loadAssignStatus: null,
  agreedToTerms: true,
  amountOwed: 0,
  autoLiability: 1,
  brokerAcceptedCarrier: true,
  broker_filter: '',
  cargoLiability: 1,
  carrierAccepted: true,
  carrierBid: null,
  carrierSafetyRating: null,
  carrier_filter: null,
  company: null,
  config: null,
  dateCompleted: null,
  dateCreated: null,
  datePaid: null,
  distance: null,
  distributionMechanism: null,
  driver: null,
  exclusiveLoad: null,
  exclusivePrice: null,
  exclusiveRatePerMile: null,
  exclusiveUntil: null,
  hazmatCapabilityRequired: null,
  isPaid: null,
  lastLocation: null,
  loadFeedKey: null,
  loadProgress: null,
  loadRejectedByAllCarriers: null,
  minRequiredInsurance: null,
  mongoId: null,
  networkPreferred: null,
  pendingCarriers: null,
  reasonsForRejection: null,
  shipper_filter: null,
  templateId: null,
  timeOffered: null,
  trailerId: null,
  truck: null,
  unitId: null,
  weight: null,
};
