import { isObject } from 'vanillas';
import { LoadsServiceLoad } from '../../loads-service';
import { PaymentItem } from '../../web-interfaces';
import { PAYMENT_ITEM_MULTI_QUANTITY, PAYMENT_ITEM_NEGATIVE, PAYMENT_ITEM_SINGLE_QUANTITY } from '../enums';
import { parseLoadsServiceLocations } from '../loadsServiceLocation';

export interface AnyObject {
  [v: string]: any | AnyObject;
}

class BadInputError extends TypeError {
  data?: AnyObject;
  name: string;
  constructor(message: string, data: AnyObject = null) {
    super();
    this.name = message;
    this.data = data;
  }
}

/**
 * This method calculates the quantity field of a single PaymentItem.
 *
 * @function
 * @name calculatePaymentItemQuantity
 * @param {PaymentItem} paymentItem the PaymentItem that will have its quantity field calculated
 * @param {LoadsServiceLoad} load the load being booked
 * @returns {number} quantity the number of units of this PaymentItem
 */
export function calculatePaymentItemQuantity(paymentItem: PaymentItem, load: LoadsServiceLoad): number {
  let quantity;
  const location = 'calculatePaymentItemQuantity';
  if (paymentItem == null) {
    throw new BadInputError('Payment Item is missing', { location });
  }
  if (!isObject(paymentItem)) {
    throw new BadInputError('Payment Item is not in a valid format', { location, paymentItem });
  }
  const { paymentType } = paymentItem || {};
  const totalDistance = (parseLoadsServiceLocations(load) || [])
    .filter((loc) => Number(loc.distanceMiles) >= 0)
    .reduce((distanceBetweenStops, currentValue) => distanceBetweenStops + Number(currentValue.distanceMiles), 0);
  if (Object.values(PAYMENT_ITEM_SINGLE_QUANTITY).some((v) => v === paymentType)) {
    return 1;
  } else if (paymentType === PAYMENT_ITEM_MULTI_QUANTITY.FUELMI) {
    quantity = totalDistance;
  } else if (paymentType === PAYMENT_ITEM_MULTI_QUANTITY.LHMLS) {
    quantity = totalDistance;
  } else if (paymentType === PAYMENT_ITEM_MULTI_QUANTITY.LYOVER) {
    throw new BadInputError('Quantity should be manually input by user for Layover (LYOVER) paymentTypes', {
      location,
      paymentType,
    });
  } else if (paymentType === PAYMENT_ITEM_MULTI_QUANTITY.SPAY) {
    throw new BadInputError('Quantity should be manually input by user for StopPayment (SPAY) paymentTypes', {
      location,
      paymentType,
    });
  } else {
    throw new BadInputError('paymentType in PaymentItem not recognized.', { location, paymentType });
  }
  return quantity;
}

/**
 * Calculates the total cost of a Load by adding all of a Load's PaymentItems. Some items might be negative.
 *
 * @function
 * @name calculateLoadTotalCost
 * @param {Array<PaymentItem>} paymentItems an array of PaymentItems that will be used to calculate the corresponding Load's price
 * @returns {number} loadPrice the full cost of this load.
 */
export function calculateLoadTotalCost(paymentItems: PaymentItem[]): number | undefined {
  if (!paymentItems || !Array.isArray(paymentItems)) {
    return undefined;
  }
  if (paymentItems.length < 1) {
    return 0;
  }
  const loadTotalCost = paymentItems
    .filter((paymentItem) => paymentItem.quantity >= 0)
    .reduce(
      (total, paymentItem) =>
        Object.values(PAYMENT_ITEM_NEGATIVE).some((v) => v === paymentItem.paymentType)
          ? total + -(paymentItem.amount * paymentItem.quantity)
          : total + paymentItem.amount * paymentItem.quantity,
      0
    );
  return loadTotalCost;
}
