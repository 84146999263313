import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  Output,
  Self,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, FormControlName } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatFormFieldControl } from '@angular/material/form-field';
import { IDropDown } from '@haulynx/types';
import { aliveWhile, listToArray, mapToArray } from '@haulynx/utils';
import { List, Map } from 'immutable';
import { filter, find, toLower, trim } from 'lodash';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-drop-down',
  templateUrl: './app-drop-down.component.html',
  styleUrls: ['./app-drop-down.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: AppDropDownComponent,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppDropDownComponent
  implements ControlValueAccessor, AfterViewInit, OnChanges, OnDestroy, MatFormFieldControl<unknown>
{
  @ViewChild('auto', { static: true }) autocomplete: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger, { static: true }) autocompleteTrigger: MatAutocompleteTrigger;
  @ViewChild('autocompleteInput', { static: true }) autocompleteInput: ElementRef;
  @Input() data: IDropDown[] | List<undefined> | Map<string, undefined> | unknown[] = [];
  @Input() prefixIcon?: string;
  @Input() suffixIcon?: string;
  @Input() suffixHoverOver?: string;
  @Input() isLoading = false;
  @Input() customFilter = false;
  @Input() displayLabel = 'label';
  @Input() filterLabel = 'filter';
  @Input() key = 'id';
  @Input() floatLabel = 'never';
  @Input() chooseDefaultOnTab = false;
  @Input() isCarrier = false;
  @Input() templateLabel: TemplateRef<unknown>;
  @Input() changeableData = false;
  @Input() cypressName = '';
  @Input() disableDropDown = false;
  @Input() customClass: string = '';
  @Output() selected = new EventEmitter();
  @Output() manualSelect = new EventEmitter();
  @Output() keywordChanges = new EventEmitter();
  @Output() formChange = new EventEmitter();
  @Output() inputBlur = new EventEmitter();

  readonly autofilled: boolean;
  readonly controlType: string;
  readonly id: string;
  readonly stateChanges: Subject<void> = new Subject<void>();
  alive = aliveWhile();
  items = [];
  groups = [];
  isSelected = false;
  keywordSearch: FormControl = new FormControl();
  filteredData = new Subject<unknown[]>();
  selectedIcon: string;
  selectedClass: string;
  onTouch: (value) => unknown;
  currentlyFiltered = [];

  private stringOnPanelOpen: string; // stores the value of the input when the panel last opened
  private _value = null;
  private _placeholder: string = null;
  private _required = false;
  private _disabled = false;
  private _focused = false;
  private _errorState = false;
  private _hideIcon = false;

  constructor(
    @Optional() @Self() public ngControl: FormControlName,
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.fm
      .monitor(this.elRef.nativeElement, true)
      .pipe(takeUntil(this.alive))
      .subscribe((origin) => {
        this.focused = !!origin;
        this.stateChanges.next();
      });

    this.trackByFn = this.trackByFn.bind(this);
  }

  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange(): void {
    if (!document.hidden) {
      this.autocompleteInput.nativeElement.blur();
    }
  }

  @HostBinding('class.floating')
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(req: boolean) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  @Input()
  set hideIcon(value: boolean) {
    this._hideIcon = coerceBooleanProperty(value);
  }

  get hideIcon(): boolean {
    return this._hideIcon;
  }

  @Input()
  set placeholder(newValue: string) {
    this._placeholder = newValue;
    this.stateChanges.next();
  }

  get placeholder(): string {
    return this._placeholder;
  }

  set value(newValue: string) {
    this._value = newValue;
  }

  get value(): string {
    return this._value;
  }

  get empty(): boolean {
    return !this.value;
  }

  set focused(newValue: boolean) {
    this._focused = newValue;
  }

  get focused(): boolean {
    return this._focused;
  }

  get errorState(): boolean {
    return !!this._errorState;
  }

  set errorState(newValue: boolean) {
    this._errorState = newValue;
  }

  private get displayKeyword() {
    const items = this.getData(this.items);
    const item = find(items, { [this.key]: this.value });

    return (item && (item[this.displayLabel] || ' ')) || null;
  }

  ngAfterViewInit(): void {
    this.setDisabledState(this.disableDropDown);
    this.keywordSearch.valueChanges.pipe(takeUntil(this.alive), distinctUntilChanged()).subscribe((keyword) => {
      const filterLabel = this.customFilter ? this.filterLabel : this.displayLabel;
      const items = this.filterBy(this.items, keyword, filterLabel);
      this.currentlyFiltered = items;
      this.filteredData.next(items);
      this.isSelected = false;

      this.errorState = !!(this.ngControl && this.ngControl.errors);
      this.stateChanges.next();
      this.keywordChanges.emit(keyword);
      if (!keyword && this.dataHasLength()) {
        this.value = null;
      }
      this.propagateChanges(this.value);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { data } = changes;

    if (data && data.currentValue) {
      this.items = this.getData(data.currentValue);

      if (this.keywordSearch.value !== this.displayKeyword && !this.changeableData) {
        this.keywordSearch.patchValue(this.displayKeyword);
      } else {
        const filterLabel = this.customFilter ? this.filterLabel : this.displayLabel;
        const items = this.filterBy(this.items, this.keywordSearch.value, filterLabel);
        this.filteredData.next(items);
        if (data.previousValue) {
          this.errorState = !!this.ngControl?.errors;
        }
        this.stateChanges.next();
      }
      if (!this.isCarrier) {
        const selectedItem = this.items && this.items.find((item) => item[this.key] === this.value);

        if (selectedItem) {
          const { svgIcon, className } = selectedItem;

          this.keywordSearch.patchValue(this.displayKeyword);
          this.selectedIcon = svgIcon;
          this.selectedClass = className;
        }
      }
      if (this.items.length && this.items[0].hasOwnProperty('group')) {
        this.groups = this.items.reduce((prev, curr) => {
          return find(prev, (p) => p === curr.group) ? prev : [...prev, curr.group];
        }, []);
      }
    }
  }

  panelOpen(): void {
    if (this.keywordSearch.enabled) {
      this.stringOnPanelOpen = this.keywordSearch.value;
    }
  }

  select(event: MatAutocompleteSelectedEvent): void {
    const { value } = event.option;
    this.selectOption(value);
    this.manualSelect.emit();
  }

  propagateChanges(_: unknown): void {}

  registerOnChange(fn: () => unknown): void {
    this.propagateChanges = fn;
  }

  registerOnTouched(fn: () => unknown): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;

    if (this.disabled) {
      this.keywordSearch.disable();
    } else {
      this.keywordSearch.enable();
    }
  }

  trackByFn(index: number, item: unknown): string | number {
    return item[this.key];
  }

  writeValue(idSelected: string): void {
    this.value = idSelected;
    const items = this.getData(this.items);
    if (items.length) {
      this.keywordSearch.patchValue(this.displayKeyword);
    }
  }

  getData(data: unknown[] | List<unknown> | Map<unknown, unknown>): unknown[] {
    return List.isList(data) ? listToArray(data) : Map.isMap(data) ? mapToArray(data) : data;
  }

  showPanel(event: Event, auto: MatAutocomplete): void {
    event.stopPropagation();

    if (this.keywordSearch.enabled) {
      if (auto.isOpen) {
        this.closeDropDown();
      } else {
        this.openDropDown();
      }
    }
  }

  closeDropDown(): void {
    this.autocompleteTrigger.closePanel();
    this.keywordSearch.patchValue(this.displayKeyword);
  }

  openDropDown(): void {
    this.autocompleteInput.nativeElement.focus();
    this.autocompleteTrigger.openPanel();
    this.highlightText();
    this.filteredData.next(this.items);
  }

  filterBy(data: IDropDown[], searchKeyword: string, displayLabel: string): IDropDown[] {
    const value = trim(toLower(searchKeyword));
    const newData = !searchKeyword
      ? data
      : filter<IDropDown>(data, (item: IDropDown) => {
          const label = toLower(item[displayLabel]);

          return label.indexOf(value) > -1;
        });

    return newData;
  }

  setDescribedByIds(ids: string[]): void {
    // throw new Error("Method not implemented.");
  }

  onContainerClick(event: MouseEvent): void {
    if (this.keywordSearch.enabled) {
      this.autocompleteTrigger.openPanel();
    }
    this.highlightText();
  }

  searchOnBlur() {
    this.inputBlur.emit();
    if (!find(this.data, (item: any) => item?.label === this.autocompleteInput.nativeElement.value)) {
      this.autocompleteInput.nativeElement.value = this.displayKeyword;
    }
  }

  ngOnDestroy(): void {
    this.alive.destroy();
    this.stateChanges.complete();
  }

  public selectOption(value: string): void {
    const selectedItem = this.items.find((item) => item[this.key] === value);

    if (selectedItem) {
      const { svgIcon, className } = selectedItem;
      this.selectedIcon = svgIcon;
      this.selectedClass = className;
    }

    this.value = value;
    this.propagateChanges(this.value);
    this.keywordSearch.patchValue(this.displayKeyword);
    this.selected.emit(value);
    this.isSelected = true;
  }

  private dataHasLength(): boolean {
    if (Array.isArray(this.data)) {
      return !!this.data.length;
    } else if (this.data.values) {
      return !!this.data.values.length;
    } else {
      return false;
    }
  }

  private highlightText(): void {
    this.autocompleteInput.nativeElement.select();
  }
}
