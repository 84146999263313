import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { SpinnerModule } from '../spinner/spinner.module';
import { AppDropDownComponent } from './components/app-drop-down/app-drop-down.component';
import { AutocompleteBlurComponent } from './components/autocomplete-blur/autocomplete-blur.component';
import { DropDownComponent } from './drop-down.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, MaterialModule, ScrollingModule, SpinnerModule, PipesModule],
  exports: [DropDownComponent, AutocompleteBlurComponent, AppDropDownComponent],
  declarations: [DropDownComponent, AutocompleteBlurComponent, AppDropDownComponent],
})
export class DropDownModule {}
