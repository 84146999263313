import { Injectable } from '@angular/core';
import { LoadsServiceLoad, LoadsServiceLoadLocation } from '@haulynx/types';
import { get, last } from 'lodash';
@Injectable({
  providedIn: 'root',
})
export class LoadLocationsService {
  constructor() {}

  private parseCityFromName(loadLocation: LoadsServiceLoadLocation): string {
    function cityGetter(splitLocation: string[]): string {
      if (splitLocation.length <= 2) {
        const ret = splitLocation[0].split(' ');
        return ret[0];
      } else if (splitLocation.length >= 3) {
        return splitLocation[1];
      } else {
        return null;
      }
    }

    const locationName: string = get(loadLocation, 'address', '');
    const city = cityGetter(locationName.split(','));

    return city;
  }

  public findFirstAndLastCityNames(load: LoadsServiceLoad): { firstCity: string; lastCity: string } {
    if (!load || !load.locations) {
      return { firstCity: null, lastCity: null };
    }
    const firstLocation: LoadsServiceLoadLocation = get(load, 'locations[0]', null);
    const lastLocation: LoadsServiceLoadLocation = last(load.locations);

    const firstCity = this.parseCityFromName(firstLocation);
    const lastCity = this.parseCityFromName(lastLocation);

    return { firstCity, lastCity };
  }
}
