<div class="sidebar-nav-tabs" fxLayout="column" fxLayoutGap="20px">
  <ng-container *ngFor="let item of headerItems">
    <span
      class="nav-button"
      *ngIf="!item?.accountItem && !item?.termsOrPrivacyItem"
      [ngClass]="{ selected: item.selected }"
      (click)="selectedItem(item, item.children ? true : false)"
      fxLayout="row"
      fxLayoutAlign="space-between center"
      attr.data-test="{{ item?.name }}"
    >
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
        <mat-icon class="icon" *ngIf="item.svgIcon" [svgIcon]="item.svgIcon"></mat-icon>
        <mat-icon class="icon" *ngIf="item.icon">{{ item.icon }}</mat-icon>
        <span>
          {{ item.name }}
        </span>
      </div>
      <!-- <mat-icon *ngIf="item.children" class="arrow" svgIcon="right-arrow"></mat-icon> -->
    </span>
  </ng-container>
  <ng-container *ngIf="!user?.isHaulynxAdmin">
    <ng-container *appFeatures="featureFlag">
      <div class="nav-button" (click)="whatsNewTour()" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
        <mat-icon svgIcon="whats-new-icon"></mat-icon>
        <span>What's New</span>
      </div>
    </ng-container>
  </ng-container>
</div>
