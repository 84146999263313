export interface CarrierMatch {
  id: string;
  name: string;
  owner: string;
  phone: string;
  dot: string;
  match: string;
  price: string;
  email: string;
}
