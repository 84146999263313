import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DirectivesModule } from '@haulynx/directives';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { LoadDetailsStateModule } from '@haulynx/store';
import { ActionButtonsModule } from '../actions-buttons/action-buttons.module';
import { BounceCarrierDialogModule } from '../dialogs/bounce-carrier-dialog/bounce-carrier-dialog.module';
import { LoadPriceModule } from '../load-price/load-price.module';
import { ActiveLoadDetailsPaneComponent } from './active-load-details-pane.component';

@NgModule({
  declarations: [ActiveLoadDetailsPaneComponent],
  exports: [ActiveLoadDetailsPaneComponent],
  imports: [
    CommonModule,
    MaterialModule,
    LoadPriceModule,
    ActionButtonsModule,
    LoadDetailsStateModule,
    BounceCarrierDialogModule,
    PipesModule,
    DirectivesModule,
  ],
})
export class ActiveLoadDetailsPaneModule {}
