import { Bid, CounterOffer, LoadsServiceLoad, UserTypes } from '@haulynx/types';
import { IGenericActions, IGenericActionsTypes } from '../../shared/store/generic-actions/actions';
import { createAction, createActionTypes } from '../../shared/store/helpers/action';
import { combineAdaptersActionsState, combineAdaptersActionTypeState } from '../../shared/store/helpers/adapter';
import { ISearchActions, ISearchTypes } from '../../shared/store/search/actions';
import { ISelectedActions, ISelectedTypes } from '../../shared/store/selected/actions';
import { ITabsActions, ITabsActionTypes } from '../../shared/store/tabs/actions';
import { bidAdapter, carriersLoadFeedAdapter, exclusiveLoadFeedAdapter, loadFeedAdapter, TYPE } from './adapters';

export interface ILoadFeedActions extends ISearchActions, ITabsActions, IGenericActions, ISelectedActions {
  setLoading(params?);

  searchLoadFeedKeys(params?);

  searchLoadFeedKeysSuccess(params?);

  searchLoadFeedKeysError(params?);

  rejectExclusiveLoad(params?);

  rejectExclusiveLoadSuccess(params?);

  rejectExclusiveLoadError(params?);

  acceptExclusiveLoad(params?);

  acceptExclusiveLoadSuccess(params?);

  acceptExclusiveLoadError(params?);

  setExpandedPanels(params?);

  getCarrier(params?);

  getCarrierSuccess(params?);

  getCarrierError(params?);

  getBidStatuses(params?);

  getBidStatusesSuccess(params?);

  getBidStatusesError(params?);

  getBidHistory(params?);

  getBidHistorySuccess(params?);

  getBidHistoryError(params?);

  createBid(params?: { bid: Partial<Bid>; load: LoadsServiceLoad });

  createBidSuccess(params?);

  createBidError(params?);

  updateBid(params?: { bid: { id: string; brokerId?: string; finalStateFrom?: UserTypes }; load: LoadsServiceLoad });

  updateBidSuccess(params?);

  updateBidError(params?);

  acceptBid(params?);

  acceptBidSuccess(params?);

  acceptBidError(params?);

  acceptCounterBid(params?);

  acceptCounterBidSuccess(params?);

  acceptCounterBidError(params?);

  sortBidsBy(params?);

  getCustomer(params?);

  getCustomerSuccess(params?);

  getCustomerError(params?);

  createCounterOffer(params?);

  createCounterOfferSuccess(params: { bidId: string; counterOffer: CounterOffer });

  createCounterOfferError(params?);
}

export interface ILoadFeedActionType extends ISearchTypes, ITabsActionTypes, IGenericActionsTypes, ISelectedTypes {
  GET_ROUTE: string;
  GET_ROUTE_SUCCESS: string;
  GET_ROUTE_ERROR: string;
  SET_LOADING: string;
  SET_EXCLUSIVE_LOADING: string;
  REJECT_EXCLUSIVE_LOAD: string;
  REJECT_EXCLUSIVE_LOAD_SUCCESS: string;
  REJECT_EXCLUSIVE_LOAD_ERROR: string;
  ACCEPT_EXCLUSIVE_LOAD: string;
  ACCEPT_EXCLUSIVE_LOAD_SUCCESS: string;
  ACCEPT_EXCLUSIVE_LOAD_ERROR: string;
  SEND_OFFER_LOAD: string;
  SEND_OFFER_LOAD_SUCCESS: string;
  SEND_OFFER_LOAD_ERROR: string;
  SET_EXPANDED_PANELS: string;
  GET_CARRIER: string;
  GET_CARRIER_SUCCESS: string;
  GET_CARRIER_ERROR: string;
  GET_BID_STATUSES: string;
  GET_BID_STATUSES_SUCCESS: string;
  GET_BID_STATUSES_ERROR: string;
  GET_BID_HISTORY: string;
  GET_BID_HISTORY_SUCCESS: string;
  GET_BID_HISTORY_ERROR: string;
  CREATE_BID: string;
  CREATE_BID_SUCCESS: string;
  CREATE_BID_ERROR: string;
  UPDATE_BID: string;
  UPDATE_BID_SUCCESS: string;
  UPDATE_BID_ERROR: string;
  ACCEPT_BID: string;
  ACCEPT_BID_SUCCESS: string;
  ACCEPT_BID_ERROR: string;
  ACCEPT_COUNTER_BID: string;
  ACCEPT_COUNTER_BID_SUCCESS: string;
  ACCEPT_COUNTER_BID_ERROR: string;
  SORT_BIDS_BY: string;
  GET_CUSTOMER: string;
  GET_CUSTOMER_SUCCESS: string;
  GET_CUSTOMER_ERROR: string;
  CREATE_COUNTER_OFFER: string;
  CREATE_COUNTER_OFFER_SUCCESS: string;
  CREATE_COUNTER_OFFER_ERROR: string;
}

export const loadFeedActionTypes = {
  SET_LOADING: 'set loading',
  SET_EXCLUSIVE_LOADING: 'set exclusive loading',
  REJECT_EXCLUSIVE_LOAD: 'reject exclusive load',
  REJECT_EXCLUSIVE_LOAD_SUCCESS: 'reject exclusive load success',
  REJECT_EXCLUSIVE_LOAD_ERROR: 'reject exclusive load error',
  ACCEPT_EXCLUSIVE_LOAD: 'accept exclusive load',
  ACCEPT_EXCLUSIVE_LOAD_SUCCESS: 'accept exclusive load success',
  ACCEPT_EXCLUSIVE_LOAD_ERROR: 'accept exclusive load error',
  SET_EXPANDED_PANELS: 'set expanded panels',
  GET_CARRIER: 'get carrier',
  GET_CARRIER_SUCCESS: 'get carrier success',
  GET_CARRIER_ERROR: 'get carrier error',
  GET_BID_STATUSES: 'get bid statuses',
  GET_BID_STATUSES_SUCCESS: 'get bid statuses success',
  GET_BID_STATUSES_ERROR: 'get bid statuses error',
  GET_BID_HISTORY: 'get bid history',
  GET_BID_HISTORY_SUCCESS: 'get bid history success',
  GET_BID_HISTORY_ERROR: 'get bid history error',
  CREATE_BID: 'create bid',
  CREATE_BID_SUCCESS: 'create bid success',
  CREATE_BID_ERROR: 'create bid error',
  UPDATE_BID: 'update bid',
  UPDATE_BID_SUCCESS: 'update bid success',
  UPDATE_BID_ERROR: 'update bid error',
  ACCEPT_BID: 'accept bid',
  ACCEPT_BID_SUCCESS: 'accept bid success',
  ACCEPT_BID_ERROR: 'accept bid error',
  ACCEPT_COUNTER_BID: 'accept counter bid',
  ACCEPT_COUNTER_BID_SUCCESS: 'accept counter bid success',
  ACCEPT_COUNTER_BID_ERROR: 'accept counter bid error',
  SORT_BIDS_BY: 'sort bids by',
  GET_CUSTOMER: 'get customer',
  GET_CUSTOMER_SUCCESS: 'get customer success',
  GET_CUSTOMER_ERROR: 'get customer error',
  CREATE_COUNTER_OFFER: 'create counter offer',
  CREATE_COUNTER_OFFER_SUCCESS: 'create counter offer success',
  CREATE_COUNTER_OFFER_ERROR: 'create counter offer error',
  SEND_OFFER_LOAD: 'send offer load',
  SEND_OFFER_LOAD_SUCCESS: 'send offer load success',
  SEND_OFFER_LOAD_ERROR: 'send offer load error',
};

export const loadFeedActions = {
  setLoading: createAction(TYPE, loadFeedActionTypes.SET_LOADING),

  setExclusiveLoading: createAction(TYPE, loadFeedActionTypes.SET_EXCLUSIVE_LOADING),

  rejectExclusiveLoad: createAction(TYPE, loadFeedActionTypes.REJECT_EXCLUSIVE_LOAD),

  rejectExclusiveLoadSuccess: createAction(TYPE, loadFeedActionTypes.REJECT_EXCLUSIVE_LOAD_SUCCESS),

  rejectExclusiveLoadError: createAction(TYPE, loadFeedActionTypes.REJECT_EXCLUSIVE_LOAD_ERROR),

  acceptExclusiveLoad: createAction(TYPE, loadFeedActionTypes.ACCEPT_EXCLUSIVE_LOAD),

  acceptExclusiveLoadSuccess: createAction(TYPE, loadFeedActionTypes.ACCEPT_EXCLUSIVE_LOAD_SUCCESS),

  acceptExclusiveLoadError: createAction(TYPE, loadFeedActionTypes.ACCEPT_EXCLUSIVE_LOAD_ERROR),

  setExpandedPanels: createAction(TYPE, loadFeedActionTypes.SET_EXPANDED_PANELS),

  getCarrier: createAction(TYPE, loadFeedActionTypes.GET_CARRIER),

  getCarrierSuccess: createAction(TYPE, loadFeedActionTypes.GET_CARRIER_SUCCESS),

  getCarrierError: createAction(TYPE, loadFeedActionTypes.GET_CARRIER_ERROR),

  getBidStatuses: createAction(TYPE, loadFeedActionTypes.GET_BID_STATUSES),

  getBidStatusesSuccess: createAction(TYPE, loadFeedActionTypes.GET_BID_STATUSES_SUCCESS),

  getBidStatusesError: createAction(TYPE, loadFeedActionTypes.GET_BID_STATUSES_ERROR),

  getBidHistory: createAction(TYPE, loadFeedActionTypes.GET_BID_HISTORY),

  getBidHistorySuccess: createAction(TYPE, loadFeedActionTypes.GET_BID_HISTORY_SUCCESS),

  getBidHistoryError: createAction(TYPE, loadFeedActionTypes.GET_BID_HISTORY_ERROR),

  createBid: createAction(TYPE, loadFeedActionTypes.CREATE_BID),

  createBidSuccess: createAction(TYPE, loadFeedActionTypes.CREATE_BID_SUCCESS),

  createBidError: createAction(TYPE, loadFeedActionTypes.CREATE_BID_ERROR),

  updateBid: createAction(TYPE, loadFeedActionTypes.UPDATE_BID),

  updateBidSuccess: createAction(TYPE, loadFeedActionTypes.UPDATE_BID_SUCCESS),

  updateBidError: createAction(TYPE, loadFeedActionTypes.UPDATE_BID_ERROR),

  acceptBid: createAction(TYPE, loadFeedActionTypes.ACCEPT_BID),

  acceptBidSuccess: createAction(TYPE, loadFeedActionTypes.ACCEPT_BID_SUCCESS),

  acceptBidError: createAction(TYPE, loadFeedActionTypes.ACCEPT_BID_ERROR),

  acceptCounterBid: createAction(TYPE, loadFeedActionTypes.ACCEPT_COUNTER_BID),

  acceptCounterBidSuccess: createAction(TYPE, loadFeedActionTypes.ACCEPT_COUNTER_BID_SUCCESS),

  acceptCounterBidError: createAction(TYPE, loadFeedActionTypes.ACCEPT_COUNTER_BID_ERROR),

  sortBidsBy: createAction(TYPE, loadFeedActionTypes.SORT_BIDS_BY),

  getCustomer: createAction(TYPE, loadFeedActionTypes.GET_CUSTOMER),

  getCustomerSuccess: createAction(TYPE, loadFeedActionTypes.GET_CUSTOMER_SUCCESS),

  getCustomerError: createAction(TYPE, loadFeedActionTypes.GET_CUSTOMER_ERROR),

  createCounterOffer: createAction(TYPE, loadFeedActionTypes.CREATE_COUNTER_OFFER),

  createCounterOfferSuccess: createAction(TYPE, loadFeedActionTypes.CREATE_COUNTER_OFFER_SUCCESS),

  createCounterOfferError: createAction(TYPE, loadFeedActionTypes.CREATE_COUNTER_OFFER_ERROR),

  sendOfferLoad: createAction(TYPE, loadFeedActionTypes.SEND_OFFER_LOAD),

  sendOfferLoadSuccess: createAction(TYPE, loadFeedActionTypes.SEND_OFFER_LOAD_SUCCESS),

  sendOfferLoadError: createAction(TYPE, loadFeedActionTypes.SEND_OFFER_LOAD_ERROR),
};

export const LoadFeedActions: ILoadFeedActions = {
  ...combineAdaptersActionsState<ILoadFeedActions>(loadFeedAdapter),
  ...loadFeedActions,
};

export const LoadFeedActionTypes: ILoadFeedActionType = {
  ...combineAdaptersActionTypeState<ILoadFeedActionType>(loadFeedAdapter),
  ...createActionTypes(loadFeedActionTypes)(TYPE),
};

export const ExclusiveLoadFeedActions = combineAdaptersActionsState<ISearchActions>(exclusiveLoadFeedAdapter);
export const ExclusiveLoadFeedActionTypes = combineAdaptersActionTypeState<ISearchTypes>(exclusiveLoadFeedAdapter);

export const CarrierLoadFeedActions = combineAdaptersActionsState<ISearchActions>(carriersLoadFeedAdapter);
export const CarrierLoadFeedActionTypes = combineAdaptersActionTypeState<ISearchTypes>(carriersLoadFeedAdapter);

export const BidActions = combineAdaptersActionsState<ISearchActions>(bidAdapter);
export const BidActionTypes = combineAdaptersActionTypeState<ISearchTypes>(bidAdapter);
