export class LoadsServicePaymentDetails {
  price?: number;
  priceLastUpdatedAt?: number;
  bookedAt?: number;
  maxBuy?: number;
  maxBuyLastUpdatedAt?: number;
  revenue?: number;
  distanceMiles?: number;
  ratePerMile?: number;
}

export class LoadsServicePaymentDetailsInput {
  price?: number;
  maxBuy?: number;
  revenue?: number;
  distanceMiles?: number;
}
