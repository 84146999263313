import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Tab } from '@haulynx/types';
import { Store } from '@ngrx/store';
import { Map } from 'immutable';
import { get } from 'lodash';
import { Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { AppState } from '../../main-store/app.reducers';
import { LoadActions } from './load.actions';
import {
  isLoadingConfirmDeliverySelector,
  isLoadingSelector,
  loadTabsSelector,
  selectCarrierSelector,
  selectUrlSelector,
} from './load.selectors';

@Injectable({
  providedIn: 'root',
})
export class LoadModel {
  isLoading$: Observable<boolean> = this.store.select(isLoadingSelector);
  tabs$: Observable<Map<string, Tab>> = this.store.select(loadTabsSelector.getTabs);
  tabsSelected$: Observable<Tab> = this.store.select(loadTabsSelector.getSelected).pipe(
    distinctUntilChanged((tab, nextTab) => {
      return get(nextTab, 'id') === get(tab, 'id');
    })
  );
  selectCarrier$: Observable<string> = this.store.select(selectCarrierSelector);
  url$: Observable<any> = this.store.select(selectUrlSelector);
  isLoadingConfirmDelivery$: Observable<boolean> = this.store.select(isLoadingConfirmDeliverySelector);

  constructor(private store: Store<AppState>, private router: Router, private activatedRoute: ActivatedRoute) {}

  addTabs(tabs: Tab[] | Tab) {
    this.store.dispatch(LoadActions.addTabs(tabs));
  }

  updateTabs(tabs: Tab[] | Tab) {
    this.store.dispatch(LoadActions.updateTabs(tabs));
  }

  selectTab(tab: Tab | any) {
    this.store.dispatch(LoadActions.selectTab(tab));
  }

  confirmDelivery(data: {
    loadId: string;
    loadLocationIndex: number;
    timestamps?: { entranceTime: number; completed: number };
  }) {
    this.store.dispatch(LoadActions.confirmDelivery(data));
  }

  removeTabs(data) {
    this.store.dispatch(LoadActions.removeTab(data));
  }

  selectCarrier(dot) {
    this.store.dispatch(LoadActions.selectCarrier(dot));
  }

  selectTemplate(template) {
    this.store.dispatch(LoadActions.selectTemplate(template));
  }

  sendOffer(data) {
    this.store.dispatch(LoadActions.sendOfferLoad(data));
  }

  goTo(commands: any[]) {
    this.router.navigate(commands, { relativeTo: this.activatedRoute });
  }
}
