import { Pipe, PipeTransform } from '@angular/core';
import { lowerCase, startCase } from 'lodash';

@Pipe({
  name: 'fromSnakeCase',
})
export class FromSnakeCasePipe implements PipeTransform {
  transform(value: string): string {
    return value.toLocaleLowerCase().split('_').join(' ');
  }
}
