<div class="carrier-details-info-wrapper">
  <app-spinner [active]="isLoading"></app-spinner>
  <ng-container *ngIf="carrier">
    <div class="carrier-details-info">
      <div class="fields-col">
        <app-field-label *ngFor="let field of fields | slice: 0:7" [label]="field.label">
          <ng-container [ngSwitch]="field.value">
            <a class="phonelink" *ngSwitchCase="'phone'" href="tel:{{ carrier | get: field.value }}">
              {{ carrier | get: field.value | phoneMask: 'xxx-xxx-xxxx' }}
            </a>
            <ng-container *ngSwitchCase="'saferWatchData'">
              <span>{{ field.eval(carrier[field.value]) || carrier['mcNumber'] }}</span>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <span *ngIf="!field.eval">{{ carrier | get: field.value }}</span>
              <span *ngIf="field.eval">{{ field.eval(carrier[field.value]) }}</span>
            </ng-container>
          </ng-container>
        </app-field-label>
      </div>

      <div class="fields-col" *ngIf="!condensed">
        <app-field-label *ngFor="let field of fields | slice: 7:fields.length" [label]="field.label">
          <span *ngIf="!field.eval">{{ carrier | get: field.value }}</span>
          <span *ngIf="field.eval">{{ field.eval(carrier[field.value]) }}</span>
        </app-field-label>
      </div>
    </div>

    <a class="more-loads" *ngIf="moreLoads" [routerLink]="['/dashboard/carriers/view/', carrier?.dot]">
      More Recommended Loads
    </a>
  </ng-container>

  <div class="carrier-details-info" *ngIf="!isLoading && !carrier">Carrier not found</div>
</div>
