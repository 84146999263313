import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BreakPoint, BREAKPOINT } from '@angular/flex-layout';

const PRINT_BREAKPOINTS = [
  {
    alias: 'xs.print',
    suffix: 'XsPrint',
    mediaQuery: 'print and (max-width: 297px)',
    overlapping: false,
    priority: 1001, // Needed if overriding the default print breakpoint
  },
];
const SCREEN_BREAKPOINTS: BreakPoint[] = [
  { alias: 'xs', mediaQuery: 'screen and (min-width: 0px) and (max-width: 374.99px)', priority: 1001 },
  { alias: 'sm', mediaQuery: 'screen and (min-width: 375px) and (max-width: 767.99px)', priority: 1001 },
  { alias: 'md', mediaQuery: 'screen and (min-width: 768px) and (max-width: 1023.99px)', priority: 1001 },
  { alias: 'lg', mediaQuery: 'screen and (min-width: 1024px) and (max-width: 1440.99px)', priority: 1001 },
  { alias: 'xl', mediaQuery: 'screen and (min-width: 1441px)', priority: 1001 },

  { alias: 'lt-sm', mediaQuery: 'screen and (max-width: 374.99px)', priority: 1001 },
  { alias: 'lt-md', mediaQuery: 'screen and (max-width: 767.99px)', priority: 1001 },
  { alias: 'lt-lg', mediaQuery: 'screen and (max-width: 1023.99px)', priority: 1001 },
  { alias: 'lt-xl', mediaQuery: 'screen and (max-width: 1440.99px)', priority: 1001 },

  { alias: 'gt-xs', mediaQuery: 'screen and (min-width: 375px)', priority: 1001 },
  { alias: 'gt-sm', mediaQuery: 'screen and (min-width: 768px)', priority: 1001 },
  { alias: 'gt-md', mediaQuery: 'screen and (min-width: 1024px)', priority: 1001 },
  { alias: 'gt-lg', mediaQuery: 'screen and (min-width: 1441px)', priority: 1001 },
];

export const CustomBreakPointsProvider = {
  provide: BREAKPOINT,
  useValue: [...PRINT_BREAKPOINTS, ...SCREEN_BREAKPOINTS],
  multi: true,
};

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [CustomBreakPointsProvider],
})
export class CustomBreakpointsModule {}
