import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { LoadLocation, LoadLocationType, Mins } from '@haulynx/types';
import { dateLessThanNowMoment } from '@haulynx/utils';
import { forEach, keys } from 'lodash';
import { MomentService } from '../../app-services/generic/moment/moment.service';

export class LoadQuickLocationForm {
  arrivalTime: number = null;
  departureTime: number = null;
  locationType: LoadLocationType;
  timeZone: string = null;

  constructor(options = {}) {
    const key = keys(this);
    const defaultValue = [null];

    forEach(key, (prop) => {
      this[prop] = (options && options[prop]) || defaultValue;
    });
  }
}

@Injectable()
export class LoadQuickLocationFormVm {
  constructor(private fb: FormBuilder, private momentService: MomentService) {}

  geTimeValidators(timeZone: string): ValidatorFn[] {
    return timeZone ? [Validators.required, dateLessThanNowMoment(timeZone)] : [Validators.required];
  }

  getOptions(timeZone: string, minimumDates: Mins): { [formControlKey: string]: unknown[] } {
    const validators = this.geTimeValidators(timeZone);

    const newMins: Mins = this.getMinDates(minimumDates, timeZone);

    return {
      arrivalTime: [newMins.entry, Validators.compose(validators)],
      departureTime: [newMins.exit, Validators.compose(validators)],
    };
  }

  create(values: Partial<LoadQuickLocationForm> = {}, timeZone = null, minimumDates: Mins): FormGroup {
    const options = this.getOptions(timeZone, minimumDates);
    const driverForm = new LoadQuickLocationForm(options);
    const formGroup = this.fb.group(driverForm, {
      validators: [this.compareTwoTimestampsValidator('arrivalTime', 'departureTime', timeZone).bind(this)],
    });

    if (values) {
      formGroup.patchValue(values);
    }

    if (options) {
      formGroup.patchValue({
        arrivalTime: options.departureTime,
        departureTime: options.departureTime,
      });
    }

    return formGroup;
  }

  compareTwoTimestampsValidator(beginDate: string, endDate: string, timeZone: string, offsetMinutes = 0): ValidatorFn {
    return (group: FormGroup): ValidationErrors | null => {
      const beginDateControl = group.controls[beginDate];
      const endDateControl = group.controls[endDate];
      const entranceMoment = this.momentService
        .getMoment(beginDateControl.value, timeZone)
        .add(offsetMinutes, 'minutes');
      const exitMoment = this.momentService.getMoment(endDateControl.value, timeZone);

      if (group.dirty) {
        if (entranceMoment.isValid() && exitMoment.isValid() && entranceMoment.isAfter(exitMoment)) {
          return { 'Date and time must be after Entry date and time': true };
        }
      }

      return null;
    };
  }

  getMinDates(minimumDates: Mins, timeZone: string): Mins {
    const newDates: Mins = {
      entry: null,
      exit: null,
    };

    if (minimumDates.entry && minimumDates.exit) {
      newDates.entry = minimumDates.entry;
      newDates.exit = minimumDates.exit;
    } else if (minimumDates.entry && !minimumDates.exit) {
      newDates.entry = minimumDates.entry;
      const ent = new Date(newDates.entry);
      newDates.exit = ent.setHours(ent.getHours() + 1);
    } else {
      newDates.entry = this.momentService.getMoment(null, timeZone).valueOf();
      newDates.exit = this.momentService.getMoment(null, timeZone).add(1, 'hours').valueOf();
    }

    return newDates;
  }
}
