import { CurrencyPipe } from '@angular/common';
import { Pipe } from '@angular/core';
import { isNumber } from 'lodash';

@Pipe({ name: 'showCurrency' })
export class ShowCurrencyPipe extends CurrencyPipe {
  transform(
    value: any,
    currencyCode?: string,
    display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
    digitsInfo?: string,
    locale?: string
  ): any {
    const newValue = Number(value);

    if (isNumber(newValue)) {
      return super.transform(newValue, currencyCode, display, digitsInfo, locale);
    } else {
      return value;
    }
  }
}
