import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { GetUsxLoads } from '@haulynx/gql';
import { ConfirmationService, GraphqlService, NotificationsService } from '@haulynx/services';
import { AppModel, LoadEntityService } from '@haulynx/store';
import { LoadIdentifierType, LoadsServiceLoad } from '@haulynx/types';
import { get } from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, map, tap, withLatestFrom } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SharedLoadResolver implements Resolve<any> {
  constructor(
    private loadEntityService: LoadEntityService,
    private confirmation: ConfirmationService,
    private router: Router,
    private graphqlService: GraphqlService,
    private notificationsService: NotificationsService,
    private appModel: AppModel
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LoadsServiceLoad> {
    const { tmwNumber = null, orderNumber = null } = route.queryParams || {};
    if (tmwNumber || orderNumber) {
      const altId = tmwNumber || orderNumber;
      const altType = tmwNumber ? LoadIdentifierType.TMW_NUMBER : LoadIdentifierType.ORDER_NUMBER;
      return this.graphqlService
        .query({
          query: GetUsxLoads(),
          variables: {
            searchParameters: {
              alternateIdType: altType,
              alternateIdValue: altId,
            },
          },
        })
        .pipe(
          withLatestFrom(this.appModel.user$),
          map(([data, user]) => {
            const loadId = get(data.data, 'getUSXLoads.data[0].id', null);
            if (loadId) {
              const url =
                user && user.broker ? `/dashboard/load-feeds/my/${loadId}` : `/dashboard/loads/item/${loadId}`;

              this.router.navigate([url]);
            } else {
              this.notificationsService.error('Load wasn’t found');
              this.router.navigate(['/']);
            }
          }),
          catchError((error) => {
            this.notificationsService.error(error.message);
            this.router.navigate(['/']);
            return of(null);
          })
        );
    } else if (route.params.id) {
      this.loadEntityService.getLoadByIdManager.dispatch(route.params.id);
      return this.loadEntityService.getLoadByIdManager.getEntityById(route.params.id).pipe(
        catchError((error) => {
          const data = {
            title: 'Woops!',
            message: 'There was a problem getting your requested load.',
            confirm: { text: 'Okay' },
            deny: { hide: true },
          };
          return this.confirmation.open<any>(data).pipe(
            tap(() => {
              this.router.navigate(['/']);
            })
          );
        })
      );
    }
  }
}
