import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SidebarNavSubAccountSetupComponent } from './sidebar-nav-sub-account-setup.component';
import { SidebarNavSubTemplateModule } from '../sidebar-nav-sub-template/sidebar-nav-sub-template.module';
import { RmisAccountSetupStatusModule } from '../../../../../../components/src/lib/rmis-account-setup-status/rmis-account-setup-status.module';
import { MatIconModule } from '@angular/material/icon';
import { WhiteOutlineButtonModule } from 'libs/elements/src/lib/white-outline-button/white-outline-button.module';

@NgModule({
  declarations: [SidebarNavSubAccountSetupComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    RmisAccountSetupStatusModule,
    SidebarNavSubTemplateModule,
    WhiteOutlineButtonModule,
  ],
  exports: [SidebarNavSubAccountSetupComponent],
})
export class SidebarNavSubAccountSetupModule {}
