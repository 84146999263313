import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { EnvironmentService, GeocodingService, LocalStoreService } from '@haulynx/services';
import { ClipboardModule } from 'ngx-clipboard';
import { ActionButtonsModule } from '../actions-buttons/action-buttons.module';
import { AdvancedSearchModule } from '../advanced-search/advanced-search.module';
import { ButtonDropdownModule } from '../button-dropdown/button-dropdown.module';
import { DatetimePickerModule } from '../datetime-picker/datetime-picker.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { ActionsComponent } from './components/actions/actions.component';
import { CommentDialogComponent } from './components/comment-dialog/comment-dialog.component';
import { MilestoneContentComponent } from './components/milestone-content/milestone-content.component';
import { MilestoneDialogComponent } from './components/milestone-dialog/milestone-dialog.component';
import { MilestoneHeaderComponent } from './components/milestone-header/milestone-header.component';
import { MilestoneItemComponent } from './components/milestone-item/milestone-item.component';
import { MilestoneSubItemCommentsComponent } from './components/milestone-sub-item-comments/milestone-sub-item-comments.component';
import { MilestoneSubItemSystemComponent } from './components/milestone-sub-item-system/milestone-sub-item-system.component';
import { MilestoneSubItemUserComponent } from './components/milestone-sub-item-user/milestone-sub-item-user.component';
import { MilestoneSubItemComponent } from './components/milestone-sub-item/milestone-sub-item.component';
import { CommentDialogDeleteComponent } from './components/comment-dialog-delete/comment-dialog-delete.component';
import { ElementsModule } from '@haulynx/elements';
import { AsyncEntityGeocodingeModule } from '@haulynx/store';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MilestoneTrailerStatusComponent } from './components/milestone-trailer-status/milestone-trailer-status.component';

@NgModule({
  declarations: [
    ActionsComponent,
    CommentDialogComponent,
    MilestoneDialogComponent,
    MilestoneContentComponent,
    MilestoneDialogComponent,
    MilestoneHeaderComponent,
    MilestoneItemComponent,
    MilestoneSubItemComponent,
    MilestoneSubItemCommentsComponent,
    MilestoneSubItemSystemComponent,
    MilestoneSubItemUserComponent,
    CommentDialogDeleteComponent,
    MilestoneTrailerStatusComponent,
  ],
  imports: [
    CommonModule,
    DatetimePickerModule,
    MaterialModule,
    PipesModule,
    ReactiveFormsModule,
    ActionButtonsModule,
    ClipboardModule,
    SpinnerModule,
    ElementsModule,
    ButtonDropdownModule,
    ReactiveFormsModule,
    FormsModule,
    AdvancedSearchModule,
    AsyncEntityGeocodingeModule,
    FlexLayoutModule,
    SpinnerModule,
  ],
  exports: [
    ActionsComponent,
    CommentDialogComponent,
    MilestoneDialogComponent,
    MilestoneContentComponent,
    MilestoneDialogComponent,
    MilestoneHeaderComponent,
    MilestoneItemComponent,
    MilestoneSubItemComponent,
    MilestoneSubItemCommentsComponent,
    MilestoneSubItemSystemComponent,
    MilestoneSubItemUserComponent,
    MilestoneTrailerStatusComponent,
  ],
  providers: [EnvironmentService, LocalStoreService, GeocodingService],
})
export class MilestoneComponentsModule {}
