import { List } from 'immutable';
import { commonActionTypes, ICommonEntityAction } from './common-entities.actions';
import { CommonEntityState } from './common-entities.state';

// export const initialState: CommonEntityState = new CommonEntityState();

export function commonEntitiesReducer(
  state: CommonEntityState = new CommonEntityState(),
  action: ICommonEntityAction
): CommonEntityState {
  const actions = commonActionTypes(action.name);
  switch (action.type) {
    case actions.SEARCH:
      const { key = null, query = null } = action.payload || {};
      let newState: any = {
        isLoading: true,
      };

      if (key && query) {
        newState = {
          ...newState,
          query: state.query.set(key, query),
        };
      }

      return state.merge({ ...newState }) as CommonEntityState;

    case actions.SEARCH_SUCCESS:
      const { data = null, docs = null } = action.payload || {};

      return state.merge({
        isLoading: false,
        entities: List(data || docs || action.payload || []),
      }) as CommonEntityState;

    case actions.SEARCH_ERRORS:
      return state.merge({
        isLoading: false,
        entities: List(),
      }) as CommonEntityState;

    case actions.CLEAR:
      return state.merge({
        isLoading: false,
        entities: List(),
      }) as CommonEntityState;

    default:
      return state as CommonEntityState;
  }
}
