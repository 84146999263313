import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  AnalyticsService,
  CreateBrokerBookedLoad,
  FireDatabaseService,
  LoadFeedActionsService,
  LocalStoreService,
  MetaService,
  NotificationsService,
  WindowRef,
} from '@haulynx/services';
import { AppModel, CarrierDashboardModel, LoadEntityService, LoadFeedModel, UserEntityService } from '@haulynx/store';
import {
  AccountRoute,
  ANALYTICS_EVENT,
  BookStatus,
  buttonTypes,
  Carrier,
  carrierNotBillTo,
  equipmentTypes,
  EquipmentTypes,
  EquipmentTypeToLoadsEquipmentType,
  EquipmentTypeToLoadsServiceEquipmentType,
  FeatureFlag,
  FFState,
  IColumns,
  LoadFeedSearchForm,
  LoadIdentifierType,
  LoadServiceSearchParameters,
  LoadsServiceLoad,
  radiusMilesToSearchOption,
  radiusSearchOptionToMiles,
  User,
} from '@haulynx/types';
import { aliveWhile, getLoadsServiceLoadAlternateId, openEmailClient } from '@haulynx/utils';
import { get, isArray } from 'lodash';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import * as tzLookup from 'tz-lookup';
import { LoadFeedSearchFormVm } from '../load-feed-search-form/load-feed-search-form.vm';

@Component({
  selector: 'haulynx-load-feed-search',
  templateUrl: './load-feed-search.component.html',
  styleUrls: ['./load-feed-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadFeedSearchContainerComponent implements OnDestroy, OnChanges {
  @Input() actions = []; // action buttons
  @Input() redirectToDashboard = false;
  @Input() maxHeight = null;
  @Input() showSearchResultHeader = true;
  @Input() carrierDot = null;

  pageTitle = 'Load Feed';
  loadFeedColumns$ = new BehaviorSubject([]);
  isExpandedRecommended = false;
  showSearch = true;
  alive = aliveWhile();
  equipmentTypes: EquipmentTypes[] = [{ selected: true, text: 'Any' }, ...equipmentTypes];
  userPageOptions = [50, 100, 200];
  carrierId: string;
  isEldCarrier: boolean;
  showEldBanner = false;
  recommendedEntities: Array<LoadsServiceLoad> = [];
  filteredRecommendedEntities$: Observable<Array<LoadsServiceLoad>>;
  searchResults$: Observable<LoadsServiceLoad[]>;
  searchQuery$: Observable<LoadFeedSearchForm>;
  carrierBidEnabled = FeatureFlag.CARRIER_BID;
  testUser = FeatureFlag.TEST_LOADS;
  isTestUser = false;
  canBid = false;
  brokerId = null;
  userEmail = null;
  isCompanyAdmin = null;
  bookStatus = BookStatus;
  loadingRmisToken: Observable<boolean>;
  isLoading$: Observable<boolean>;
  public loadIdentifierType = LoadIdentifierType;
  private featureFlags: FFState;
  private validCarrier: boolean;
  private orderIdSearch = false;

  constructor(
    private analyticsService: AnalyticsService,
    private dashboardCarrierModel: CarrierDashboardModel,
    private fireDatabaseService: FireDatabaseService,
    private loadFeedActionsService: LoadFeedActionsService,
    private localStoreService: LocalStoreService,
    private metaService: MetaService,
    private notificationsService: NotificationsService,
    public loadEntityService: LoadEntityService,
    private router: Router,
    private windowRef: WindowRef,
    private userEntityService: UserEntityService,
    public appModel: AppModel,
    public dialog: MatDialog,
    public loadFeedModel: LoadFeedModel,
    private loadFeedSearchFormVm: LoadFeedSearchFormVm,
    private createOfferService: CreateBrokerBookedLoad
  ) {
    this.loadingRmisToken = this.userEntityService.rmisTokenManager.isLoading$;
    this.metaService.setMobileViewport();
    this.fireDatabaseService.database.goOnline();

    this.userEntityService.featureFlags$.pipe(takeUntil(this.alive)).subscribe((feature: FFState) => {
      this.canBid = feature[this.carrierBidEnabled] ? true : false;
      this.isTestUser = feature[this.testUser] ? true : false;
    });

    this.appModel.isValidCarrier$.pipe(takeUntil(this.alive)).subscribe((validCarrier: boolean) => {
      this.validCarrier = validCarrier;
    });

    this.appModel.user$.pipe(takeUntil(this.alive)).subscribe((user) => {
      const carrierId = get(user, 'carrier.id', null);
      const brokerId = get(user, 'broker.id', null);
      const dot = get(user, 'carrier.dot', null);
      const userEmail = get(user, 'email', null);
      const isCompanyAdmin = get(user, 'isCompanyAdmin', null);

      this.carrierId = carrierId;
      this.brokerId = brokerId;
      this.userEmail = userEmail;
      this.carrierDot = dot;
      this.isCompanyAdmin = isCompanyAdmin;

      if (carrierId) {
        const query = { carrierId };
        this.loadEntityService.getCarrierLoadRecommends.dispatch({
          query: {
            dot,
            radius: 75,
          },
          pageAndSort: {
            limit: 10,
            page: 1,
            sort: 'ASC',
          },
        });
        this.loadFeedModel.getCarrier(query);
      }

      if (brokerId) {
        this.pageTitle = `My ${this.pageTitle}`;
        this.showSearchResultHeader = false;
      }

      this.getLoadGridSearchColumns()
        .pipe(takeUntil(this.alive))
        .subscribe((columns) => {
          this.loadFeedColumns$.next(columns);
        });
    });

    this.filteredRecommendedEntities$ = this.loadEntityService.getCarrierLoadRecommends.searchResults$.pipe(
      map((recommendedLoads: LoadsServiceLoad[]) =>
        recommendedLoads.filter(
          (load: LoadsServiceLoad) =>
            !carrierNotBillTo.map((value) => value.key).includes(load?.providerDetails?.billTo) ?? true
        )
      )
    );

    this.loadFeedModel.isEldCarrier$.pipe(takeUntil(this.alive)).subscribe((isEldCarrier) => {
      this.isEldCarrier = isEldCarrier;
      if (!isEldCarrier && isEldCarrier !== null) {
        this.showEldBanner = true;
      }
    });

    this.filteredRecommendedEntities$.pipe(takeUntil(this.alive)).subscribe((entities: LoadsServiceLoad[]) => {
      this.recommendedEntities = entities;
      this.isExpandedRecommended = !!entities.length;
    });

    this.searchResults$ = combineLatest([
      this.loadEntityService.getUSXLoadsManager?.searchResults$,
      this.loadEntityService.getLoadByNumberManager?.searchResults$,
    ]).pipe(map(([usxLoads, numberLoads]) => (this.orderIdSearch ? numberLoads : usxLoads)));

    this.isLoading$ = combineLatest([
      this.loadEntityService.getUSXLoadsManager?.isSearching$,
      this.loadEntityService.getLoadByNumberManager?.isSearching$,
      this.loadEntityService.getCarrierLoadRecommends.isSearching$,
    ]).pipe(map((indicators) => indicators.some((val) => val === true)));

    this.searchQuery$ = combineLatest([
      this.loadEntityService.getUSXLoadsManager?.searchQuery$,
      this.loadEntityService.getLoadByNumberManager?.searchQuery$,
    ]).pipe(
      map(([usxSearch, numberSearch]) => {
        if (usxSearch?.payload || numberSearch?.payload) {
          let query = {};
          const searchParams = numberSearch?.payload && this.orderIdSearch ? numberSearch : usxSearch;

          if (searchParams.payload?.alternateIdValue) {
            query = {
              ...query,
              orderId: searchParams.payload.alternateIdValue,
            };
          } else {
            const {
              destinationLat = [null],
              destinationLon = [null],
              destinationLocation = [null],
              originLat = [null],
              originLon = [null],
              originLocation = [null],
              firstAppointmentStart = null,
              firstAppointmentEnd = null,
              equipment = [null],
            } = searchParams.payload as LoadServiceSearchParameters;

            query = {
              ...query,
              deliveryLocation: {
                address: destinationLocation[0],
                lat: destinationLat[0],
                lon: destinationLon[0],
                timeZone: tzLookup(destinationLat[0], destinationLon[0]),
              },
              destinationRadius: searchParams?.payload['destinationRadiusMiles']
                ? radiusMilesToSearchOption(searchParams?.payload['destinationRadiusMiles'][0])
                : 321868,
              equipmentType: equipment[0] ? EquipmentTypeToLoadsEquipmentType(equipment[0]) : equipment[0],
              originRadius: searchParams?.payload['originRadiusMiles']
                ? radiusMilesToSearchOption(searchParams?.payload['originRadiusMiles'][0])
                : 321868,
              pickUpLocation: {
                address: originLocation[0],
                lat: originLat[0],
                lon: originLon[0],
                timeZone: tzLookup(originLat[0], originLon[0]),
              },
              pickupDate: {
                begin: firstAppointmentStart,
                end: firstAppointmentEnd,
              },
            };
          }
          return this.loadFeedSearchFormVm.create(query).value;
        }
        return null;
      }),
      takeUntil(this.alive)
    );
  }

  bookSuccess(loadId: string): void {
    this.loadEntityService.getUSXLoadsManager.dispatch({
      query: {
        showExcluded: true,
        bookStatus: [BookStatus.BOOKABLE, BookStatus.VIEWABLE],
        notBillTo: carrierNotBillTo.map((val) => val.key),
        showTestLoads: this.isTestUser,
      },
    });
  }

  getLoadGridSearchColumns(): Observable<IColumns[]> {
    return combineLatest([
      this.appModel.user$,
      this.appModel.isValidCarrier$,
      this.userEntityService.featureFlags$,
    ]).pipe(
      map(([user, isValidCarrier, features]: [User, boolean, FFState]) => {
        this.featureFlags = features;
        const columns: IColumns[] = [];

        columns.push({
          label: 'Origin City',
          dataIndex: 'pickupLocation',
          isCustomCell: true,
          sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
        });

        columns.push({
          label: 'State',
          dataIndex: 'pickupState',
          isCustomCell: true,
          sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
        });

        columns.push({
          label: 'Destination City',
          dataIndex: 'deliveryLocation',
          isCustomCell: true,
          sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
        });

        columns.push({
          label: 'State',
          dataIndex: 'deliveryState',
          isCustomCell: true,
          sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
        });

        columns.push({
          label: 'Equipment',
          dataIndex: 'equipmentType',
          isCustomCell: true,
          isSortable: true,
          sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
        });

        columns.push({
          label: 'Pick-Up Date',
          dataIndex: 'pickupTimestamp',
          isCustomCell: true,
          sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
          initiallySortedBy: 'asc',
        });

        columns.push({
          label: 'Delivery Date',
          dataIndex: 'dropoffTimestamp',
          isCustomCell: true,
          sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
        });

        if (user && user.broker) {
          columns.push({
            label: 'Customer',
            dataIndex: 'customer',
            isCustomCell: true,
            sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
          });
        }

        if (user && user.carrier) {
          columns.push({
            label: 'RPM',
            dataIndex: 'rpm',
            isCustomCell: true,
            sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
          });
        }

        columns.push({
          label: 'TMW #',
          dataIndex: 'tmwNumber',
          isSortable: true,
          isCustomCell: true,
        });

        columns.push({
          label: 'Loaded Miles',
          dataIndex: 'distance',
          isCustomCell: true,
        });

        columns.push({
          label: 'Order #',
          dataIndex: 'order',
          isSortable: true,
          isCustomCell: true,
          sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
        });

        if (user && user.broker) {
          columns.push({
            label: 'Revenue',
            dataIndex: 'revenue',
            isCustomCell: true,
            isSortable: true,
            sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
          });
        }
        if (user && (isValidCarrier || user.broker)) {
          columns.push({
            label: 'Price',
            dataIndex: 'price',
            isCustomCell: true,
            isSortable: true,
            sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
          });
        }

        if (user && user.broker && features && features[FeatureFlag.LOAD_OFFER_BIDDING]) {
          columns.push({
            label: 'Bids',
            dataIndex: 'bidDetails',
            isCustomCell: true,
            isSortable: true,
            sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
          });
        }

        if (user && user.carrier) {
          columns.push({
            label: 'Weight',
            dataIndex: 'weight',
            isCustomCell: true,
            sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
          });
        }

        if (user && (user.carrier || user.broker)) {
          columns.push({
            label: '',
            dataIndex: 'options',
            isCustomCell: true,
            cls: 'options',
            isSortable: false,
          });
        }

        return columns;
      })
    );
  }

  toggleRecommended(): void {
    this.isExpandedRecommended = !this.isExpandedRecommended;
  }

  viewLoad(loadFeed: LoadsServiceLoad): void {
    this.localStoreService.set(loadFeed.id, this.router.url);

    if (this.validCarrier || this.brokerId) {
      const { id } = loadFeed;
      let url = `loads/${id}`;
      if (this.featureFlags[FeatureFlag.LOAD_OVERVIEW] && this.brokerId) {
        url += '/overview/info';
      }
      url += this.redirectToDashboard && this.brokerId ? `?carrierDashboard=${this.carrierDot}` : '';

      if (this.brokerId) {
        this.windowRef.getNativeWindow().open(url, '_blank');
      } else {
        this.router.navigate([url]);
      }

      const tmwNum =
        get(loadFeed, 'tmwNumber', null) ||
        getLoadsServiceLoadAlternateId(loadFeed as LoadsServiceLoad, this.loadIdentifierType.TMW_NUMBER);
      const orderNum =
        get(loadFeed, 'loadLocations[0].billOfLading', null) ||
        getLoadsServiceLoadAlternateId(loadFeed as LoadsServiceLoad, this.loadIdentifierType.BILL_OF_LADING);

      if (this.recommendedEntities.includes(loadFeed)) {
        this.analyticsService.logEvent(ANALYTICS_EVENT.RECOMMENDED_LOAD_CLICKED, {
          loadId: loadFeed.id,
          loadTmwNum: tmwNum,
          loadOrderNum: orderNum,
          recSource: 'unknown',
        });
      } else {
        this.analyticsService.logEvent(ANALYTICS_EVENT.BASIC_LOAD_CLICKED, {
          loadId: loadFeed.id,
          loadTmwNum: tmwNum,
          loadOrderNum: orderNum,
        });
      }
    }
  }

  search(query: LoadFeedSearchForm): void {
    let searchFields: LoadServiceSearchParameters = {};
    const {
      pickUpLocation = null,
      deliveryLocation = null,
      pickupDate = null,
      originRadius = null,
      destinationRadius = null,
      equipmentType = null,
      orderId = null,
    } = query;

    if (pickUpLocation && pickUpLocation?.address && pickUpLocation?.lat && pickUpLocation?.lon) {
      searchFields = {
        ...searchFields,
        originLocation: [pickUpLocation.address],
        originLat: [pickUpLocation.lat],
        originLon: [pickUpLocation.lon],
      };
    }
    if (deliveryLocation && deliveryLocation?.address && deliveryLocation?.lon && deliveryLocation?.lat) {
      searchFields = {
        ...searchFields,
        destinationLocation: [deliveryLocation.address],
        destinationLat: [deliveryLocation.lat],
        destinationLon: [deliveryLocation.lon],
      };
    }
    if (pickupDate && pickupDate?.begin && pickupDate?.end) {
      searchFields = {
        ...searchFields,
        firstAppointmentStart: pickupDate?.begin?.valueOf(),
        firstAppointmentEnd: pickupDate?.end?.valueOf(),
      };
    }
    if (originRadius && pickUpLocation && pickUpLocation.lat && pickUpLocation.lon) {
      searchFields = { ...searchFields, originRadiusMiles: [radiusSearchOptionToMiles(originRadius)] };
    }
    if (destinationRadius && deliveryLocation && deliveryLocation.lon && deliveryLocation.lat) {
      searchFields = { ...searchFields, destinationRadiusMiles: [radiusSearchOptionToMiles(destinationRadius)] };
    }
    if (equipmentType && equipmentType != 'Any') {
      searchFields = { ...searchFields, equipment: [EquipmentTypeToLoadsServiceEquipmentType(equipmentType)] };
    }
    if (this?.isTestUser) {
      searchFields = {
        ...searchFields,
        showTestLoads: true,
        billTo: ['haulynx test'],
      };
    }

    if (orderId && this?.carrierId) {
      this.orderIdSearch = true;
      this.loadEntityService.getLoadByNumberManager.dispatch({ query: { alternateIdValue: orderId } });
    } else {
      this.orderIdSearch = false;
      searchFields.showExcluded = true;
      searchFields.bookStatus = [BookStatus.BOOKABLE, BookStatus.VIEWABLE];
      searchFields.notBillTo = carrierNotBillTo.map((val) => val.key);

      this.loadEntityService.getUSXLoadsManager.dispatch({ query: searchFields, pageAndSort: { page: 1, limit: 50 } });
    }
  }

  /**
   * onPage takes emitted pagination from data-table, to query a page change.
   * @param event
   */
  onPage(event: { limit: number; page: number }): void {
    this.loadEntityService.getUSXLoadsManager.goToPage(event?.page, event?.limit);
  }

  /**
   * onPageRecommend takes emitted pagination from data-table, to make a page change.
   * @param event
   */
  onPageRecommend(event: { limit: number; page: number }): void {
    this.loadEntityService.getCarrierLoadRecommends.goToPage(event?.page, event?.limit);
  }

  userSearch(action): void {
    if (action === 'search') {
      this.isExpandedRecommended = false;
    }
  }

  onAction(action, row): void {
    if (action === buttonTypes.EMAIL_LOAD.action) {
      const { id } = row;
      this.loadEntityService.getLoadByIdManager.dispatch(id);
      this.getLoadById(id)
        .pipe(withLatestFrom(this.dashboardCarrierModel.carrierForm.state$), takeUntil(this.alive))
        .subscribe(([load, carrier]: [LoadsServiceLoad, Carrier]) => {
          if (load) {
            this.createOfferService
              .createOffer({
                dot: carrier.dot,
                price: load?.paymentDetails?.price,
                loadId: load.id,
                brokerEmail: this.userEmail,
              })
              .pipe(takeUntil(this.alive))
              .subscribe(() => {
                const url = `mailto:${carrier.email}?${this.loadFeedActionsService.mailMessage(carrier.dot, load)}`;
                openEmailClient(url);
                this.analyticsService.logEvent(ANALYTICS_EVENT.BROKER_EMAIL_OFFER, {
                  carrierDot: carrier.dot,
                  load,
                  carrierEmail: carrier.email,
                });
              });
          }
        });
    }
  }

  getLoadById(id): Observable<LoadsServiceLoad> {
    return this.loadEntityService.getLoadByIdManager.getEntityById(id).pipe(
      map((load: LoadsServiceLoad) => {
        if (!load) {
          this.notificationsService.error(`Oops, something went wrong. Unable to get load!`, `Load`);
          return null;
        } else {
          return load;
        }
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { actions } = changes;

    if (actions && actions.currentValue && isArray(actions.currentValue) && actions.currentValue.length) {
      const val = this.loadFeedColumns$.value;
      val.push({
        label: '',
        dataIndex: 'actions',
        isCustomCell: true,
        width: '180px',
        cls: 'options',
        isSortable: false,
      });
      this.loadFeedColumns$.next(val);
    }
  }

  ngOnDestroy(): void {
    this.alive.destroy();
    this.fireDatabaseService.database.goOffline();
    this.metaService.removeMobileViewport();
  }

  launchRMIS(): void {
    this.userEntityService.rmisTokenManager.dispatch();

    this.userEntityService.rmisTokenManager.onError$.pipe(take(1), takeUntil(this.alive)).subscribe((error) => {
      this.router.navigate([AccountRoute.SORRY]);
    });

    this.userEntityService.rmisTokenManager.onSuccess$
      .pipe(take(1), takeUntil(this.alive))
      .subscribe((response: { url: string }) => {
        window.open(response.url, '_blank');
      });
  }
}
