<haulynx-dialog-template (closed)="close()">
  <div title>Learn about RMIS</div>
  <div content fxLayout="column" fxLayoutGap="24px">
    <div>
      RMIS (Registry Monitoring Insurance Services) provides continuous Certificate of Insurance monitoring on auto and
      cargo coverage as well as AM Best Ratings for insurance providers.
    </div>
    <div class="visit">
      <haulynx-blue-link-text text="Visit the RMIS Website" (clicked)="goToRmis()"></haulynx-blue-link-text>
    </div>
  </div>
</haulynx-dialog-template>
