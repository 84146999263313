import { Pipe, PipeTransform } from '@angular/core';
import { minutesToHours } from 'date-fns';

@Pipe({
  name: 'fullDay',
})
export class FullDayPipe implements PipeTransform {
  transform(minutes: number): unknown {
    const formatted = `${minutesToHours(minutes).toString().padStart(2, '0')}:${(minutes % 60)
      .toString()
      .padStart(2, '0')}`;
    return formatted;
  }
}
