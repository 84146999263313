import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-expand-button',
  templateUrl: './expand-button.component.html',
  styleUrls: ['./expand-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpandButtonComponent {
  @Input() expanded = false;
}
