import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { AddressField, PostTruckForm, truckRadiusOptions } from '@haulynx/types';
import { forEach, keys, nth } from 'lodash';
import { MomentService } from '../../app-services/generic/moment/moment.service';

export class TruckForm implements PostTruckForm {
  location: AddressField = null;
  locationRadius: string = null;
  preferredLocation: AddressField = null;
  preferredLocationRadius: string = null;
  equipmentType: string = null;
  timeAvailable: number = null;

  constructor(controlConfig) {
    const key = keys(this);
    const defaultValue = [''];

    forEach(key, (prop) => {
      const control = (controlConfig && controlConfig[prop]) || defaultValue;

      this[prop] = control;
    });
  }
}

@Injectable()
export class CarrierTruckFormVmService {
  options;

  constructor(private fb: FormBuilder, private momentService: MomentService) {
    this.options = {
      location: [null, validateLocation],
      locationRadius: [nth(truckRadiusOptions, 2).value],
      preferredLocation: [null],
      preferredLocationRadius: [nth(truckRadiusOptions, 2).value],
      timeAvailable: [this.momentService.getTodayUnixMidnight(), Validators.required],
      equipmentType: [null],
    };
  }

  create(values): FormGroup {
    const truckForm = new TruckForm(this.options);
    const truckFormGroup = this.fb.group(truckForm);

    if (values) {
      truckFormGroup.patchValue(values);
    }

    return truckFormGroup;
  }

  createTrucks(values: FormGroup[] = []): FormGroup {
    return this.fb.group({
      trucks: this.fb.array(values),
    });
  }
}

function validateLocation(ctrl: FormControl): ValidationErrors | null {
  const location: AddressField = ctrl.value || {};
  const isValid = !!(location.address && location.lat && location.lon);
  return isValid ? null : { locationIsValid: false };
}
