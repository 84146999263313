<div class="daypicker">
  <input #inputFeild matInput readonly [value]="formattedDays" [placeholder]="placeholder" />
  <div class="text-danger" *ngIf="error && ngControl.touched">{{ error }}</div>
  <button class="calendar-icon" type="button" (click)="op.toggle($event)"></button>
</div>
<p-overlayPanel #op>
  <div class="title">Pickup Dates</div>
  <div class="sub-title">Repeat on</div>
  <div class="container">
    <div *ngFor="let day of daysOfTheWeek">
      <button (click)="addToList(day)" [ngClass]="day.selected ? 'selected' : 'unselected'" attr.data-test="{{ day.label }}">
        {{ day.label }}
      </button>
    </div>
  </div>
  <mat-dialog-actions class="button-row">
    <button mat-button class="cancel" (click)="cancel()">CANCEL</button>
    <button mat-button class="confirm" (click)="confirm()" data-test="confirm-button">CONFIRM</button>
  </mat-dialog-actions>
</p-overlayPanel>
