import { NgModule, OnDestroy } from '@angular/core';
import { Route, Router, RouterModule, ROUTES, Routes } from '@angular/router';
import { TermsOfServiceTextComponent } from 'libs/elements/src/lib/terms-of-service-text/terms-of-service-text.component';
import { InterchangeAgreementInfoComponent } from 'libs/elements/src/lib/interchange-agreement-info/interchange-agreement-info.component';

import { UserEntityService } from '@haulynx/store';
import { FeatureFlag, FFState, User } from '@haulynx/types';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { RootComponent } from './root.component';
import { PrivacyComponent } from '../main-site/privacy/privacy.component';
import { ContractManageComponent } from '../contract-manage/contract-manage.component';

@NgModule({
  imports: [RouterModule],
  exports: [RouterModule],
  providers: [{ provide: ROUTES, useFactory: loadRoutes, deps: [UserEntityService], multi: true }],
})
export class RootRoutingModule implements OnDestroy {
  private alive = new Subject();

  constructor(private router: Router, private userEntityService: UserEntityService) {
    // This is a way to provide routes that use the same name, but use different modules.
    // The problem occurs when logging in and out. Once a lazy loaded module has loaded,
    // the module will not reload and will therefore not reprovide the routes.
    this.userEntityService.user$
      .pipe(
        filter((user) => !user),
        takeUntil(this.alive)
      )
      .subscribe({
        next: () => {
          const routerConfig = this.router.config.map((route) => {
            if (route.path === '' && route['_loadedConfig']) {
              const newConfig = { ...route };
              const appChildrenRoutes = newConfig['_loadedConfig']?.routes as Route[];
              const childRouteIndex = appChildrenRoutes.findIndex((route: Route) =>
                route.children.some((childRoute: Route) => childRoute.path === 'loads')
              );

              // Could not find specified route
              if (childRouteIndex === -1) return newConfig;

              const foundRouteChildren = [...appChildrenRoutes[childRouteIndex].children] as Route[];
              const newChildRoute = foundRouteChildren.map((childRoute) => {
                if (childRoute.path === 'loads') {
                  const newChildConfig = { ...childRoute };
                  delete newChildConfig['_loadedConfig'];
                  delete newChildConfig['_loader$'];

                  return newChildConfig;
                }

                return childRoute;
              });
              newConfig['_loadedConfig'].routes[childRouteIndex].children = newChildRoute;

              return newConfig;
            }

            return route;
          });
          this.router.resetConfig(routerConfig);
        },
      });
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.complete();
  }
}

function loadRoutes(userEntityService: UserEntityService) {
  const children: Route[] = [
    {
      path: 'lane-analysis',
      loadChildren: () => import('../lane-analysis/lane-analysis.module').then((m) => m.LaneAnalysisModule),
    },
    {
      path: 'lane-rate',
      loadChildren: () => import('../lane-rate-tool/lane-rate-tool.module').then((m) => m.LaneRateToolModule),
    },
    {
      path: 'carriers',
      loadChildren: () => import('../carrier-manage/carrier-manage.module').then((m) => m.CarrierManageModule),
    },
    {
      path: 'dashboard',
      loadChildren: () => import('../dashboard/dashboard.module').then((m) => m.DashboardModule),
    },
    {
      path: 'privacy-policy',
      component: PrivacyComponent,
    },
    {
      path: 'terms-of-service',
      component: TermsOfServiceTextComponent,
    },
    {
      path: 'interchange-agreement',
      component: InterchangeAgreementInfoComponent,
    },
    {
      path: 'releases',
      loadChildren: () => import('../releases/releases.module').then((m) => m.ReleasesModule),
    },
    {
      path: 'feature-flag-dashboard',
      loadChildren: () =>
        import('../feature-flag-dashboard/feature-flag-dashboard.module').then((m) => m.FeatureFlagDashboardModule),
    },
    {
      path: 'milestones',
      loadChildren: () => import('../milestones/milestones.module').then((m) => m.MilestonesModule),
    },
    {
      path: 'loads',
      loadChildren: () =>
        new Promise((resolve, reject) => {
          Promise.all([
            import('@haulynx/carrier-load-search'),
            import('../loads/loads.module'),
            userEntityService.user$.pipe(take(1)).toPromise(),
            userEntityService.featureFlags$.pipe(take(1)).toPromise(),
          ]).then(([carrierLoadSearchModule, loadsModule, user, features]: [any, any, User, FFState]) => {
            if (features?.[FeatureFlag.CARRIER_LOAD_SEARCH] && user?.broker == null) {
              return resolve(carrierLoadSearchModule.CarrierLoadSearchModule);
            }

            return resolve(loadsModule.LoadsModule);
          });
        }),
    },
  ];

  userEntityService.featureFlags$.pipe(take(1)).subscribe((features) => {
    if (features?.[FeatureFlag.FREIGHT_CONTRACTS]) {
      children.push({
        path: 'contracts',
        loadChildren: () => import('../contract-manage/contract-manage.module').then((m) => m.ContractManageModule),
      });
    }
  });

  const routes: Routes = [
    {
      path: '',
      component: RootComponent,
      children: children,
    },
  ];

  return routes;
}
