import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { CarrierInfoSectionVmService } from '@haulynx/services';
import { NgxMaskModule } from 'ngx-mask';
import { DropDownModule } from '../../drop-down/drop-down.module';
import { CarrierInfoSectionComponent } from './carrier-info-section.component';

@NgModule({
  declarations: [CarrierInfoSectionComponent],
  exports: [CarrierInfoSectionComponent],
  providers: [CarrierInfoSectionVmService],
  imports: [ReactiveFormsModule, MatInputModule, CommonModule, DropDownModule, NgxMaskModule.forRoot()],
})
export class CarrierInfoSectionModule {}
