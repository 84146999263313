import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CarrierService } from '@haulynx/services';
import { Carrier, Info, SaferWatchData } from '@haulynx/types';

@Component({
  selector: 'app-edit-carrier',
  templateUrl: './edit-carrier.component.html',
  styleUrls: ['./edit-carrier.component.scss'],
})
export class EditCarrierComponent implements OnInit {
  public carrier: Carrier = new Carrier();
  public ratings = ['Satisfactory', 'Conditional', 'Unsatisfactory', 'None'];
  public overallRatings = ['Acceptable', 'Moderate', 'Unacceptable', 'None'];

  public loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Carrier,
    private carrierService: CarrierService,
    public dialogRef: MatDialogRef<EditCarrierComponent>
  ) {}

  ngOnInit() {
    this.carrierService.getCarrier(this.data.id).subscribe((carrier) => {
      if (carrier.saferWatchData == null) carrier.saferWatchData = new SaferWatchData();
      this.carrier = carrier;
      if (!this.carrier.surveyInfo || this.carrier.surveyInfo.length === 0) {
        const info: Info[] = [];
        info.push({ question: 'What states do you do business in?', content: '' });
        info.push({ question: 'Do you own/lease you own trailers?', content: '' });
        info.push({ question: 'Do you haul trailers for any other company?', content: '' });
        info.push({
          question: 'Are you able to take loads from any registered freight broker in the US?',
          content: '',
        });
        info.push({ question: 'Do you have contract with carriers/brokers to haul only their loads?', content: '' });
        info.push({ question: 'Do you work directly with shippers?', content: '' });
        info.push({ question: 'What percentage of your loads are from dedicated accounts?', content: '' });
        info.push({
          question: 'How many admins does your company have (people that have the ability to accept loads)',
          content: '',
        });
        info.push({ question: 'Do they all have Haulynx admin accounts?', content: '' });
        info.push({ question: 'Do they all have the Haulynx mobile app downloaded in the phone?', content: '' });
        info.push({ question: 'What routes are you currently running?', content: '' });
        info.push({ question: 'Do they vary seasonally?', content: '' });
        info.push({ question: 'Are there states where you do not go?', content: '' });
        info.push({ question: 'What commodities do you haul? Are there commodities you refuse to haul?', content: '' });
        info.push({ question: 'Do you currently get loads from Brokers?', content: '' });
        info.push({ question: 'Do you also get loads directly from Shippers?', content: '' });
        info.push({ question: 'What percentage is from Brokers and what percentage is from Shippers?', content: '' });
        info.push({ question: 'Do you take “Less Than Truckload” loads?', content: '' });
        info.push({ question: 'Would you be interested in getting loads from the Haulynx network?', content: '' });
        info.push({
          question:
            'Do you currently have a factoring company for your invoices? Are they required to approve any new brokers in advance?',
          content: '',
        });
        info.push({
          question:
            'Does your company have a dispatcher or administrator that is in charge of load distribution? Is there more than one person? Are they all set up as administrators in the Haulynx system?',
          content: '',
        });
        info.push({
          question: 'What is the best way to contact you when we have a load available for you?',
          content: '',
        });
        this.carrier.surveyInfo = info;
      }
    });
  }

  save() {
    this.loading = true;
    this.carrierService.updateCarrier(this.carrier).subscribe(
      (res) => {
        this.loading = false;
        this.dialogRef.close(this.carrier);
      },
      (err) => {
        this.loading = false;
      }
    );
  }
}
