import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UnauthenticatedLoadDetailsModule } from '@haulynx/components';
import { MaterialModule } from '@haulynx/modules';
import { LoadDetailsContainerRoutingModule } from './load-details-container-routing.module';
import { LoadDetailsContainerComponent } from './load-details-container.component';

@NgModule({
  declarations: [LoadDetailsContainerComponent],
  exports: [LoadDetailsContainerComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    LoadDetailsContainerRoutingModule,
    UnauthenticatedLoadDetailsModule,
  ],
})
export class LoadDetailsContainerModule {}
