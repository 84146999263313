import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@haulynx/modules';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { BidModalTextboxComponent } from './bid-modal-textbox.component';

@NgModule({
  declarations: [BidModalTextboxComponent],
  imports: [CommonModule, MaterialModule, FlexLayoutModule, ReactiveFormsModule],
  exports: [BidModalTextboxComponent],
})
export class BidModalTextboxModule {}
