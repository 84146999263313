export enum ColumnField {
  PRIORITY = 'priority',
  USER_TYPE = 'userType',
  ORIGIN_AND_DESTINATION = 'originAndDestination',
  ORIGIN_DEAD_HEAD = 'originDeadHead',
  DESTINATION_DEAD_HEAD = 'destinationDeadHead',
  STATUS = 'status',
  EQUIPMENT = 'equipment',
  BILL_TO = 'customer',
  TMW_AND_ORDER = 'tmwAndOrder',
  PRICE_MAX_BUY_REVENUE = 'priceMaxBuyRevenue',
  TEAM_DRIVING_REQUIRED = 'teamDrivingRequired',
  BIDS = 'bids',
  TRAILER_INSTRUCTIONS = 'trailerInstructions',
  TRUCK = 'truck',
  DRIVER = 'driver',
  CARRIER = 'carrier',
  ASSIGNED_BROKERS = 'assignedBrokers',
  BOOKED_BY = 'bookedBy',
  FREIGHT_MIX = 'orderType',
  LOADED_MILES = 'distanceMiles',
  INBOUND_REGION = 'inboundRegion',
  BROKER_TEAM = 'brokerTeamId',
  SHIPPER = 'shipper',
  RECEIVER = 'receiver',
  CX_PRIORITY = 'cxPriority',
  TRACKING_TYPES = 'trackingTypes',
  HIGH_VALUE = 'highValue',
  ACTIONS = 'actions',
  UPDATED_AT = 'updatedAt',
  USER = 'user',
  ACTIVITY_TYPE = 'activityType',
  LOAD_BID_TYPES = 'loadBidType',
  REVENUE = 'revenue',
  MAX_BUY = 'maxBuy',
  PRICE = 'price',
  CARRIER_RATE = 'carrierRate',
  MARGIN = 'margin',
  TRACKING_STATUS = 'trackingStatus',
  DATE_ADDED = 'dateAdded',
  CXR_ID = 'cxrId',
  LAST_MILESTONE_COMMENT = 'latestMilestoneComment',
  LAST_TRUCK_LOCATION = 'lastLocation',
  LAST_TRAILER_LOCATION = 'lastTrailerLocation',
  APPOINTMENT_RESETS = 'appointmentResets',
  NAME = 'name',
  COMPANY = 'company',
  PHONER_NUMBER = 'phoneNumber',
  ZIPCODE = 'zipcode',
  ADDRESS = 'address',
  MISSION = 'mission',
  DOCUMENT_TYPE = 'documentType',
  DOCUMENT = 'document',
}

function getColumnData(columns: string[], key: string) {
  const order = columns.indexOf(key);
  if (order < 0) return { key, value: false };
  return { key, order, value: true };
}

function getColumns(columns: string[]) {
  return Object.values(ColumnField).map((key) => getColumnData(columns, key));
}

const AVAILABLE_COLUMNS = [
  ColumnField.PRIORITY,
  ColumnField.ORIGIN_AND_DESTINATION,
  ColumnField.STATUS,
  ColumnField.MISSION,
  ColumnField.TMW_AND_ORDER,
  ColumnField.BILL_TO,
  ColumnField.EQUIPMENT,
  ColumnField.PRICE,
  ColumnField.MAX_BUY,
  ColumnField.REVENUE,
  ColumnField.LOADED_MILES,
  ColumnField.ASSIGNED_BROKERS,
  ColumnField.TRAILER_INSTRUCTIONS,
  ColumnField.FREIGHT_MIX,
  ColumnField.BIDS,
  ColumnField.HIGH_VALUE,
  ColumnField.ACTIONS,
];
export const DEFAULT_AVAILABLE_COLUMNS = getColumns(AVAILABLE_COLUMNS);

const ACTIVE_COLUMNS = [
  ColumnField.PRIORITY,
  ColumnField.ORIGIN_AND_DESTINATION,
  ColumnField.TMW_AND_ORDER,
  ColumnField.STATUS,
  ColumnField.MISSION,
  ColumnField.TRACKING_STATUS,
  ColumnField.LAST_MILESTONE_COMMENT,
  ColumnField.TRUCK,
  ColumnField.DRIVER,
  ColumnField.CARRIER,
  ColumnField.BOOKED_BY,
  ColumnField.LAST_TRUCK_LOCATION,
  ColumnField.LAST_TRAILER_LOCATION,
  ColumnField.ACTIONS,
];
export const DEFAULT_ACTIVE_COLUMNS = getColumns(ACTIVE_COLUMNS);

const ACTIVE_COLUMNS_WITH_TRACKING = [
  ColumnField.PRIORITY,
  ColumnField.ORIGIN_AND_DESTINATION,
  ColumnField.TMW_AND_ORDER,
  ColumnField.STATUS,
  ColumnField.MISSION,
  ColumnField.TRACKING_STATUS,
  ColumnField.LAST_MILESTONE_COMMENT,
  ColumnField.TRUCK,
  ColumnField.DRIVER,
  ColumnField.CARRIER,
  ColumnField.BOOKED_BY,
  ColumnField.TRACKING_TYPES,
  ColumnField.LAST_TRUCK_LOCATION,
  ColumnField.LAST_TRAILER_LOCATION,
  ColumnField.ACTIONS,
];
export const DEFAULT_ACTIVE_COLUMNS_WITH_TRACKING = getColumns(ACTIVE_COLUMNS_WITH_TRACKING);

const ALL_PLANNED_COLUMNS = [
  ColumnField.PRIORITY,
  ColumnField.ORIGIN_AND_DESTINATION,
  ColumnField.TMW_AND_ORDER,
  ColumnField.STATUS,
  ColumnField.BOOKED_BY,
  ColumnField.TRUCK,
  ColumnField.DRIVER,
  ColumnField.CARRIER,
  ColumnField.ACTIONS,
];
export const DEFAULT_ALL_PLANNED_COLUMNS = getColumns(ALL_PLANNED_COLUMNS);

const AWAITING_TRUCK_DETAILS_COLUMNS = [
  ColumnField.PRIORITY,
  ColumnField.ORIGIN_AND_DESTINATION,
  ColumnField.TMW_AND_ORDER,
  ColumnField.STATUS,
  ColumnField.EQUIPMENT,
  ColumnField.CARRIER,
  ColumnField.BOOKED_BY,
  ColumnField.ACTIONS,
];
export const DEFAULT_AWAITING_TRUCK_DETAILS_COLUMNS = getColumns(AWAITING_TRUCK_DETAILS_COLUMNS);
