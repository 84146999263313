import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CarrierManagementModel, CarrierManagementStateModule } from '@haulynx/store';
import { CarrierManagementTableModule } from '../carrier-management-table/carrier-management-table.module';
import { ConfirmationModule } from '@haulynx/services';
import { UnderConstructionModule } from '../under-construction/under-construction.module';
import { CarrierManageContainerComponent } from './carrier-manage-container.component';

@NgModule({
  declarations: [CarrierManageContainerComponent],
  imports: [
    CommonModule,
    UnderConstructionModule,
    CarrierManagementTableModule,
    ConfirmationModule,
    CarrierManagementStateModule,
  ],
  providers: [CarrierManagementModel],
})
export class CarrierManageContainerModule {}
