import { ISearchFilter } from './search-filter';

export interface ISearchState {
  filters: ISearchFilter[];
  date?: number;
}

export interface RecentSearch {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}
