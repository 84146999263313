import { Injectable } from '@angular/core';
import { LogEntityActionEvent, SearchSubscriptionEvents } from '@haulynx/gql';
import { LoadActionEvent, SubscriptionEvent, SubscriptionEventFilterParams } from '@haulynx/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WebSocketService } from '../../web-socket/web-socket.service';
import { GraphqlService } from '../graphql.service';

@Injectable({
  providedIn: 'root',
})
export class ActionEventService {
  constructor(private graphqlService: GraphqlService, private websocketService: WebSocketService) {}

  logEntityActionEvent(
    action: string,
    entityId: string,
    entity: string,
    userId: string,
    expiredAt: number
  ): Observable<{ data; error: { message: string } }> {
    return this.graphqlService.mutate({
      mutation: LogEntityActionEvent,
      variables: {
        action,
        entityId,
        entity,
        userId,
        expiredAt,
      },
    });
  }

  searchSubscriptionEvents(filterParams: SubscriptionEventFilterParams): Observable<SubscriptionEvent[]> {
    return this.graphqlService
      .query({
        query: SearchSubscriptionEvents,
        variables: {
          filterParams,
        },
      })
      .pipe(map((result) => result.data['searchSubscriptionEvents']));
  }

  listenToEvents(query: string): Observable<{ loadActionEvent: LoadActionEvent }> {
    return this.websocketService.request(query);
  }
}
