import { AsyncManagerType, IAsyncEntityState } from '@haulynx/types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { createAction, on, props, ReducerTypes } from '@ngrx/store';
import { camelCase } from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { DataAction } from '../types/data-action';
import { AsyncEntityActionFactory } from './async-entity-action-factory';

export class AsyncEntityDataFactory<
  ActionPayloadType,
  ResponseData,
  ErrorPayloadType = any
> extends AsyncEntityActionFactory<ActionPayloadType, ResponseData, ErrorPayloadType> {
  public managerType = AsyncManagerType.DATA;
  public action = createAction(this.type, props<{ payload: ActionPayloadType }>());
  public actionSuccess = createAction(this.typeSuccess, props<{ payload: ResponseData }>());
  public actionError = createAction(this.typeError, props<{ payload: ErrorPayloadType }>());
  private options: AsyncEntityDataOptions;

  constructor(
    namespace: string,
    name: string,
    effectCall: (payload: ActionPayloadType) => Observable<ResponseData>,
    options: AsyncEntityDataOptions = {}
  ) {
    super(namespace, name, effectCall);
    this.options = { dataIndex: camelCase(name), ...options };
  }

  public getReducers(): ReducerTypes<IAsyncEntityState<ResponseData>, any>[] {
    return [
      on(this.action, (state: IAsyncEntityState<ResponseData>) => {
        return {
          ...state,
          [`data-${this.options.dataIndex}`]: {
            ...state[`data-${this.options.dataIndex}`],
            isLoading: true,
          },
        };
      }),
      on(this.actionSuccess, (state: IAsyncEntityState<ResponseData>, action: { payload: ResponseData }) => {
        return {
          ...state,
          [`data-${this.options.dataIndex}`]: {
            ...state[`data-${this.options.dataIndex}`],
            data: action.payload,
            isLoading: false,
          },
        };
      }),
      on(this.actionError, (state: IAsyncEntityState<ResponseData>) => {
        return {
          ...state,
          [`data-${this.options.dataIndex}`]: {
            ...state[`data-${this.options.dataIndex}`],
            isLoading: false,
          },
        };
      }),
    ];
  }

  public createEffect(actions$: Actions) {
    return createEffect(() =>
      actions$.pipe(
        ofType(this.type),
        switchMap((action: DataAction<ActionPayloadType>) =>
          this.sideEffect(action.payload).pipe(
            map((data: ResponseData) => this.actionSuccess({ payload: data })),
            catchError((error: ErrorPayloadType) => {
              console.error(error);
              return of(this.actionError({ payload: error }));
            })
          )
        )
      )
    );
  }
}

export interface AsyncEntityDataOptions {
  /**
   * A key that allows you to tie multiple AsyncDataManagers together so that they can write to the same slice of the state.
   * If not provided, the default will be assumed off of the `name` of the manager.
   * If you have multiple AysncDataManager's mutating the same piece of state, you should provide this value.
   **/
  dataIndex?: string;
}
