import { FormGroup } from '@angular/forms';
import { ISearchFilter, TextFilterType } from '../search-filter';
import { ISearchFilterType } from '../search-filter.types';

export class CustomerNumberFilter implements ISearchFilter {
  name = 'CustomerNumber';
  type: ISearchFilterType = ISearchFilterType.TEXT;
  keys: TextFilterType;
  description = 'Filter customers by Customer Number';
  shouldPersistInputText = true;
  valueIsNumber = true;

  constructor(defaultValues?: TextFilterType) {
    this.keys = new TextFilterType({
      value: '',
      optionsDataIndex: 'CustomerNumber',
      textFormName: 'CustomerNumber',
      ...defaultValues,
    });
  }
}
