import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CommonEntitiesEffects } from '../../shared/common-entities/common-entities.effects';
import { carrierLoadSearchStateProviders, CARRIER_ENTITY_REDUCERS_TOKEN } from './carrier-entity.config';

export const carrierEntityNamespace = 'carrier-entity';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(carrierEntityNamespace, CARRIER_ENTITY_REDUCERS_TOKEN),
    EffectsModule.forFeature([CommonEntitiesEffects]),
  ],
  providers: [...carrierLoadSearchStateProviders],
})
export class CarrierEntityModule {}
