import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from '@haulynx/directives';
import { MaterialModule } from '@haulynx/modules';
import { HoverDropDownModule } from '../hover-drop-down/hover-drop-down.module';
import { SideNavComponent } from './side-nav.component';

@NgModule({
  declarations: [SideNavComponent],
  imports: [CommonModule, MaterialModule, RouterModule, HoverDropDownModule, FlexLayoutModule, DirectivesModule],
  exports: [SideNavComponent],
})
export class SideNavModule {}
