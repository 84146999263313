export enum RelationTypes {
  TOP = 'top',
  DEVELOPING = 'developing',
  PROSPECTS = 'prospects',
}

export class CarrierManagementTableConfig {
  readonly icons: string[];
  readonly estOffset: string;
  readonly dateFormat: string;
}

export const carrierManagementTableConfig: CarrierManagementTableConfig = {
  icons: ['haulynx-trash-gray'],
  estOffset: '-0500',
  dateFormat: 'M/d/yy @ hh:mm',
};
