<div class="bid-modal-textbox">
  <div class="title-text" *ngIf="showTitleAndCount" fxLayout="row" fxLayoutAlign="space-between center">
    <span>{{ title }}</span>
    <span>{{ (textInput.value?.length || 0) + '/' + maxLength }}</span>
  </div>
  <mat-form-field appearance="fill" class="notes-field">
    <textarea
      matInput
      class="notes-textarea"
      placeholder="{{ placeHolderText }}"
      [formControl]="inputControl"
      #textInput
      maxlength="{{ maxLength }}"
    ></textarea>
  </mat-form-field>
</div>
