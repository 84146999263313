import { combineAdapters } from '../../shared/store/helpers/adapter';
import { createSearchAdapter, SearchAdapter } from '../../shared/store/search/adapter';
import { createTabAdapter, TabAdapter } from '../../shared/store/tabs/adapter';

export const TYPE = '[LOAD FEEDS]';
const loadFeedState = {
  loadFeedSearch: createSearchAdapter,
  tabs: createTabAdapter,
};

export interface ILoadFeedStateAdapter {
  loadFeedSearch: SearchAdapter<any>;
  tabs: TabAdapter;
}

export const loadFeedAdapter: ILoadFeedStateAdapter = combineAdapters<ILoadFeedStateAdapter>(loadFeedState, TYPE);

export const EXCLUSIVE_TYPE = '[LOAD FEEDS EXCLUSIVE]';
const exclusiveLoadFeedState = {
  exclusiveLoadFeedSearch: createSearchAdapter,
};

export interface IMapExclusiveLoadStateAdapter {
  exclusiveLoadFeedSearch: SearchAdapter<any>;
}

export const exclusiveLoadFeedAdapter: IMapExclusiveLoadStateAdapter = combineAdapters<IMapExclusiveLoadStateAdapter>(
  exclusiveLoadFeedState,
  EXCLUSIVE_TYPE
);

export const CARRIERS_LOAD = '[LOAD FEEDS CARRIERS]';
const carriersLoadFeedState = {
  carriersLoadFeedSearch: createSearchAdapter,
};

export interface ICarrierLoadStateAdapter {
  carriersLoadFeedSearch: SearchAdapter<any>;
}

export const carriersLoadFeedAdapter: ICarrierLoadStateAdapter = combineAdapters<ICarrierLoadStateAdapter>(
  carriersLoadFeedState,
  CARRIERS_LOAD
);

export const BID_TYPE = '[BIDS]';
const bidState = {
  bidSearch: createSearchAdapter,
};

export interface IBidStateAdapter {
  bidSearch: SearchAdapter<any>;
}

export const bidAdapter: IBidStateAdapter = combineAdapters<IBidStateAdapter>(bidState, BID_TYPE);
