import { createFeatureSelector } from '@ngrx/store';
import { Map } from 'immutable';

export const initialLoadDetails: CarrierDetailsState = {
  isLoading: Map<any, any>(),
  state: Map<any, any>(),
  stateKey: null,
};

export class CarrierDetailsState {
  isLoading: Map<string, boolean>;
  state: Map<string, { [p: string]: any } | any> | { [p: string]: any };
  stateKey: string;
}

export const getCarrierDetailsState = createFeatureSelector<CarrierDetailsState>('carrier-details');
