import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@haulynx/directives';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { ReactiveComponentModule } from '@ngrx/component';
import { DropDownModule } from '../../../drop-down/drop-down.module';
import { CarrierToolbarBidsModule } from '../carrier-toolbar-bids/carrier-toolbar-bids.module';
import { ToolbarCardComponent } from './toolbar-card.component';

@NgModule({
  declarations: [ToolbarCardComponent],
  exports: [ToolbarCardComponent],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    PipesModule,
    DropDownModule,
    ReactiveComponentModule,
    CarrierToolbarBidsModule,
    DirectivesModule,
  ],
})
export class ToolbarCardModule {}
