import gql from 'graphql-tag';

export const GetGeocodeLocation = gql(`
query getGeocodeLocation($geocodeParams: GeocodeParamsInput){
  getGeocodeLocation(geocodeParams: $geocodeParams){
    addressNumber
    city
    country
    fullAddress
    lat
    lon
    placeType
    state
    street
    zipcode
  }
}
`);
