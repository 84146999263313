import { HttpStatus, ITabsState } from '@haulynx/types';
import { createFeatureSelector } from '@ngrx/store';
import { Map, Record } from 'immutable';
import { combineAdaptersInitialState } from '../../shared/store/helpers/adapter';
import { ISearchState } from '../../shared/store/search/state';
import { loadActiveTabsAdapter, loadAdapter, loadAvailableTabsAdapter, loadOffersSearchAdapter } from './adapters';

const initialLoadActiveState = {
  isLoadActiveLoading: Map<any, any>(),
  loadActiveHttpStatus: Map<any, any>(),
};

export interface ILoadState {
  isLoadActiveLoading: Map<string, boolean>;
  loadActiveHttpStatus: Map<string, HttpStatus>;

  loadOffers: ISearchState;
  tabs: ITabsState;
  loadActiveTabs: ITabsState;
  loadAvailableTabs: ITabsState;

  isLoading: boolean;
  selectedCarrier: string;
  isLoadingBalance: boolean;
  isLoadingConfirm: boolean;
  showNotesButton: boolean;
}

const initialLoadState = {
  isLoading: false,
  selectedCarrier: null,
  isLoadingBalance: false,
  isLoadingConfirm: false,
  showNotesButton: false,
  ...initialLoadActiveState,
  ...combineAdaptersInitialState(loadAdapter),
  ...combineAdaptersInitialState(loadActiveTabsAdapter),
  ...combineAdaptersInitialState(loadAvailableTabsAdapter),
  ...combineAdaptersInitialState(loadOffersSearchAdapter),
};

export class LoadState extends Record(initialLoadState) implements ILoadState {
  isLoadActiveLoading: Map<string, boolean>;
  loadActiveHttpStatus: Map<string, HttpStatus>;

  loadOffers: ISearchState;
  tabs: ITabsState;
  loadActiveTabs: ITabsState;
  loadAvailableTabs: ITabsState;

  isLoading: boolean;
  selectedCarrier: string;
  isLoadingBalance: boolean;
  isLoadingConfirm: boolean;
  showNotesButton: boolean;
}

export const getLoadState = createFeatureSelector<LoadState>('loads');
