import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BookLoadHttpStatus, LoadsServiceLoad } from '@haulynx/types';
import { MapQueue } from '@haulynx/utils';
import { Store } from '@ngrx/store';
import { Map } from 'immutable';
import { Observable } from 'rxjs';
import { AppState } from '../../main-store/app.reducers';
import { BookLoadActions } from './book-load.actions';
import { bookLoadHttpStatusSelector, bookLoadSelector, isBookableSelector } from './book-load.selectors';

@Injectable()
export class BookLoadModel {
  isBookableLoad$: Observable<Map<string, boolean>> = this.store.select(isBookableSelector);
  isLoading$: Observable<MapQueue> = this.store.select(bookLoadSelector);
  httpStatus$: Observable<Map<string, BookLoadHttpStatus>> = this.store.select(bookLoadHttpStatusSelector);

  constructor(private store: Store<AppState>, private router: Router) {}

  bookLoad(data: { load: LoadsServiceLoad; carrierDot: string }) {
    this.store.dispatch(BookLoadActions.bookLoad(data));
  }

  getBrokerBookedLoads(data: { loadId: string; carrierDot: string }) {
    this.store.dispatch(BookLoadActions.getBrokerBookedLoads(data));
  }

  goTo(url: string) {
    this.router.navigate([url]);
  }
}
