interface ZipLaneUpdateItem {
  bidId: string;
  bulkAdjustPercent?: string;
  bulkAdjustDollars?: string;
  margin?: string;
  miles?: string;
}

export type ZipLaneUpdate = ZipLaneUpdateItem[];

interface ZipLaneQueryParameters {
  originZip?: string;
  destinationZip?: string;
  priority?: string;
  zipPrecision?: string;
}

interface ZipLaneQueryUpdateFields {
  margin?: string;
  marginDollars?: string;
  cost?: string;
  allInRate?: string;
  rpm?: string;
  allInRPM?: string;
}
interface ZipLaneQueryUpdate {
  zipLaneQueryParameters: ZipLaneQueryParameters;
  zipLaneFieldsToUpdate: ZipLaneQueryUpdateFields;
}

export type ZipLaneQueryUpdates = ZipLaneQueryUpdate[];
