import { ITabsState } from '@haulynx/types';
import { createFeatureSelector } from '@ngrx/store';
import { List, Record } from 'immutable';
import { combineAdaptersInitialState } from '../../shared/store/helpers/adapter';
import { ISearchState } from '../../shared/store/search/state';
import { activeLoadsAdapter } from './adapters';

export interface IActiveLoadsStateState {
  selectedLoadId: string;
  activeLoads: ISearchState;
  tabs: ITabsState;
  brokers: List<any>;
  isLoadingBrokers: boolean;
  carriers: List<any>;
  isLoadingCarriers: boolean;
}

export const initialLoadState = {
  selectedLoadId: null,
  brokers: List(),
  isLoadingBrokers: false,
  carriers: List(),
  isLoadingCarriers: false,
  ...combineAdaptersInitialState(activeLoadsAdapter),
};

export class ActiveLoadsStateState extends Record(initialLoadState) implements IActiveLoadsStateState {
  selectedLoadId: string;
  activeLoads: ISearchState;
  tabs: ITabsState;
  brokers: List<any>;
  isLoadingBrokers: boolean;
  carriers: List<any>;
  isLoadingCarriers: boolean;
}

export const getActiveLoadsState = createFeatureSelector<ActiveLoadsStateState>('active-loads');
