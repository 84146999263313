import { createSelector } from '@ngrx/store';
import { getFeatureFlagDashboardState } from './feature-flag-dashboard.state';

export const getFeatureFlagDashboardSelector = createSelector(getFeatureFlagDashboardState, (state) => state);

export const isLoadingFeatureFlagDashboardSelector = createSelector(
  getFeatureFlagDashboardSelector,
  (state) => state.isLoading
);

export const isLoadingFeatureFlagDashboardDetailsSelector = createSelector(
  getFeatureFlagDashboardSelector,
  (state) => state.isLoadingDetails
);

export const featureFlagDashboardEntitiesSelector = createSelector(
  getFeatureFlagDashboardSelector,
  (state) => state.entities
);

export const featureFlagDashboardSelectedSelector = createSelector(
  getFeatureFlagDashboardSelector,
  (state) => state.selected
);

export const isLoadingFeatureFlagDashboardUsersSelector = createSelector(
  getFeatureFlagDashboardSelector,
  (state) => state.isLoadingUsers
);

export const featureFlagDashboardUsersSelector = createSelector(
  getFeatureFlagDashboardSelector,
  (state) => state.users
);

export const isLoadingFeatureFlagDashboardCarriersSelector = createSelector(
  getFeatureFlagDashboardSelector,
  (state) => state.isLoadingCarriers
);

export const featureFlagDashboardCarriersSelector = createSelector(
  getFeatureFlagDashboardSelector,
  (state) => state.carriers
);
