import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DotCheckReponse, DriverInvitation, User } from '@haulynx/types';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OnboardingService {
  constructor(private http: HttpClient) {}

  /**
   * @param dot or mc number
   */
  checkCarrierByDot(dot: number | string): Observable<DotCheckReponse> {
    return this.http.post<DotCheckReponse>('/api/onboarding/dotCheck', { dot });
  }

  checkCarrierByMcNumber(mcNumber: number | string): Observable<DotCheckReponse> {
    return this.http.post<DotCheckReponse>('/api/onboarding/mcNumberCheck', { mcNumber });
  }

  inviteDrivers(invitations: DriverInvitation[]): Observable<any> {
    return this.http.post('/api/onboarding/inviteDrivers', invitations);
  }

  getDriverInvitation(phone: string): Observable<any> {
    return this.http.post('/api/onboarding/getGhostDriver', { phone });
  }

  /**
   * Admins will use this to create drivers.
   * @param driver
   */
  createTemporaryDriver(driver: User): Observable<any> {
    return this.http.post('/api/onboarding/createGhostDriver', driver);
  }

  /**
   * @param payload object that contains dot number and list of state codes, such as ['PA', 'UT', 'AZ']
   */
  createLanes(payload: { dot: string; states: string[] }): Observable<any> {
    return this.http.post('/api/onboarding/lanes', payload);
  }
}
