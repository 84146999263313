import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoginResponse } from '@haulynx/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  signIn(data: { email: string; password: string }): Observable<LoginResponse> {
    const { email, password } = data;
    return this.http.post<LoginResponse>('/api/account/login', { email, password });
  }

  signOut(email: string): Observable<boolean> {
    return this.http.post<any>('/api/account/logout', { email });
  }

  signUp(
    userData: any,
    headers: {
      Source?: string;
      Medium?: string;
      Campaign?: string;
      EnableCarrierBidding?: string;
    } = { Source: 'organic' }
  ): Observable<any> {
    const options = {
      headers: {
        Platform: 'web',
      },
      observe: 'response' as 'body',
    };

    Object.keys(headers).forEach((key: string) => {
      // Cannot add null headers
      if (headers[key]) {
        options.headers[key] = headers[key];
      }
    });

    return this.http.post('/api/users/signup', userData, options);
  }

  resetPassword(body): Observable<any> {
    return this.http.post('/api/users/resetPassword', body);
  }
}
