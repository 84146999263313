import { AgmCoreModule } from '@agm/core';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  ActionButtonsModule,
  ChipListModule,
  DataTableModule,
  DataTableV2Module,
  DatepickerModule,
  DropDownModule,
  FleetComponent,
  GoogleAddressFieldModule,
  LanesComponent,
  MapsModule,
  RadioGroupModule,
  SelectorModule,
  SpinnerModule,
} from '@haulynx/components';
import { MaterialModule } from '@haulynx/modules';
import { PhoneMaskPipe, PipesModule } from '@haulynx/pipes';
import { CarrierTruckFormVmService, MapService } from '@haulynx/services';
import { FleetStoreModule } from '@haulynx/store';
import { SharedModule } from 'primeng/api';
import { CarriersModule } from '../carrier/carriers.module';
import { FleetRoutingModule } from './fleet-routing.module';

@NgModule({
  declarations: [FleetComponent, LanesComponent],
  imports: [
    CommonModule,
    DataTableModule,
    DataTableV2Module,
    ReactiveFormsModule,
    DropDownModule,
    SharedModule,
    PipesModule,
    FormsModule,
    RadioGroupModule,
    ActionButtonsModule,
    RouterModule,
    DataTableModule,
    AgmCoreModule,
    AgmJsMarkerClustererModule,
    FleetRoutingModule,
    GoogleAddressFieldModule,
    SelectorModule,
    DatepickerModule,
    ChipListModule,
    CarriersModule,
    SpinnerModule,
    MapsModule,
    MaterialModule,
    FleetStoreModule,
  ],
  providers: [PhoneMaskPipe, CarrierTruckFormVmService, MapService],
})
export class FleetModule {}
