import { combineAdapters } from '../../shared/store/helpers/adapter';
import { createSearchAdapter, SearchAdapter } from '../../shared/store/search/adapter';
import { createTabAdapter, TabAdapter } from '../../shared/store/tabs/adapter';
import { ILoadStateAdapter } from '../load/adapters';

export const TYPE = '[ACTIVE LOADS]';

const activeLoadsState = {
  activeLoads: createSearchAdapter,
  tabs: createTabAdapter,
};

export interface IActiveLoadsStateAdapter {
  activeLoads: SearchAdapter<any>;
  tabs: TabAdapter;
}

export const activeLoadsAdapter: IActiveLoadsStateAdapter = combineAdapters<ILoadStateAdapter>(activeLoadsState, TYPE);
