import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CarrierManagementModel } from './carrier-management-model';
import { CarrierManagementStateEffects } from './carrier-management-state.effects';
import { carrierManagementStateReducer } from './carrier-management-state.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature('carrier-management', carrierManagementStateReducer),
    EffectsModule.forFeature([CarrierManagementStateEffects]),
  ],
  providers: [CarrierManagementModel, CarrierManagementStateEffects],
})
export class CarrierManagementStateModule {}
