import gql from 'graphql-tag';
import { MINIMAL_LOAD_DETAILS_FRAGMENT } from './fragments';

export const CarrierLoadSearchGetLoadsByCarrierDot = gql`
  query GetLoadsServiceLoadsByCarrierDot(
    $filterParams: LoadServiceSearchParameters
    $paging: LoadsServicePageAndSort
    $dot: StrictId!
  ) {
    getLoadsServiceLoadsByCarrierDot(filterParams: $filterParams, paging: $paging, dot: $dot) {
      data {
        ...minimalLoadDetails
      }
      paginator {
        currentPage
        nextPage
        previousPage
        total
        totalPages
      }
    }
  }
  ${MINIMAL_LOAD_DETAILS_FRAGMENT}
`;
