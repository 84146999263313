import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CarrierDetailsStoreModule, LoadDetailsStateModule, PaymentTypeStoreModule } from '@haulynx/store';
import { AssignLoadCarrierFormModule } from '../assign-load-carrier-form/assign-load-carrier-form.module';
import { AssignLoadCarrierContainerRoutingModule } from './assign-load-carrier-container-routing.module';
import { AssignLoadCarrierContainerComponent } from './assign-load-carrier-container.component';

@NgModule({
  declarations: [AssignLoadCarrierContainerComponent],
  exports: [AssignLoadCarrierContainerComponent],
  imports: [
    CommonModule,
    AssignLoadCarrierContainerRoutingModule,
    AssignLoadCarrierFormModule,
    LoadDetailsStateModule,
    CarrierDetailsStoreModule,
    PaymentTypeStoreModule,
  ],
})
export class AssignLoadCarrierContainerModule {}
