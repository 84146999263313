import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@haulynx/modules';
import { BlueSelectComponent } from './blue-select.component';

@NgModule({
  declarations: [BlueSelectComponent],
  imports: [CommonModule, MaterialModule, ReactiveFormsModule],
  exports: [BlueSelectComponent],
})
export class BlueSelectModule {}
