import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DividerSliderComponent } from './divider-slider.component';
import { DividerSliderStoryComponent } from './divider-slider-story/divider-slider-story.component';

@NgModule({
  declarations: [DividerSliderComponent, DividerSliderStoryComponent],
  imports: [CommonModule],
  exports: [DividerSliderComponent],
})
export class DividerSliderModule {}
