import { ITabsState, Tab } from '@haulynx/types';
import { Map } from 'immutable';

export function createInitialTabState() {
  return {
    getInitialState(): ITabsState {
      return {
        tabs: Map<string, Tab>() as Map<string, Tab>,
        selectedTabId: null,
      };
    },
  };
}
