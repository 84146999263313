import { HttpStatus } from '@haulynx/types';
import { createFeatureSelector } from '@ngrx/store';
import { List, Map } from 'immutable';

export const defaultStateKey = 'DEFAULT_KEY';
export const initialLoadEntities: LoadEntitiesState = {
  stateKey: defaultStateKey,
  entities: Map<any, any>(),
  loading: Map<any, any>(),
  total: Map<any, any>(),
  limit: Map<any, any>(),
  page: Map<any, any>(),
  query: Map<any, any>(),
  httpStatus: Map<any, any>(),
};

export class LoadEntitiesState {
  stateKey: string;
  entities: Map<string, List<any>>;
  loading: Map<string, boolean>;
  total: Map<string, number>;
  limit: Map<string, number>;
  page: Map<string, number>;
  query: Map<string, { [key: string]: any }>;
  httpStatus: Map<string, HttpStatus>;
}

export const getLoadEntitiesState = createFeatureSelector<LoadEntitiesState>('load-entities');
