import { Store } from '@ngrx/store';
import { AppState } from '../../../main-store/app.reducers';
import { IEntitySelectors } from './selectors';

export const entityModel = (store: Store<AppState>, formSelector: IEntitySelectors) => {
  const entity$ = store.select(formSelector.getEntity);
  const isLoading$ = store.select(formSelector.getLoading);

  return {
    entity$,
    isLoading$,
  };
};
