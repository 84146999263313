import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { takeUntil, takeWhile } from 'rxjs/operators';
import { filter } from 'lodash';
import { CarrierService } from '@haulynx/services';
import { aliveWhile } from '@haulynx/utils';

@Component({
  selector: 'app-accept-load-dialog',
  templateUrl: './load-feed-accept.component.html',
  styleUrls: ['./load-feed-accept.component.scss'],
})
export class LoadFeedAcceptComponent implements OnInit, OnDestroy {
  alive = aliveWhile();
  form: FormGroup;
  drivers: any[];
  trucks: any[];
  disableButton = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { carrierId: string },
    private fb: FormBuilder,
    private carrierService: CarrierService
  ) {
    this.form = this.fb.group({
      driver: [null],
      truck: [null],
    });

    this.form.valueChanges.pipe(takeUntil(this.alive)).subscribe(() => {
      const data: any = this.form.getRawValue();

      this.disableButton = !((!data.driver && !data.truck) || (data.driver && data.truck));
    });
  }

  ngOnInit() {
    this.carrierService.getDrivers().subscribe((drivers) => {
      this.drivers = drivers;
    });

    this.carrierService.getDevices().subscribe((devices) => {
      this.trucks = filter(devices, (device) => device.type === 'phone');
    });
  }

  getData() {
    const data = this.form.getRawValue();

    return data;
  }

  getUnitDisplayName(truck) {
    if (truck.type && truck.type === 'phone') {
      if (truck.lastLocation) {
        return truck.lastLocation.driverName + "'s Phone";
      } else {
        return 'Phone';
      }
    }
    return 'Unit # ' + (truck.unitId ? truck.unitId : truck.lastLocation ? truck.lastLocation.unitId : 'N/A');
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }
}
