import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from '@haulynx/pipes';
import { ButtonDropdownModule } from '../button-dropdown/button-dropdown.module';

import { LaneHistoryActionsComponent } from './lane-history-actions/lane-history-actions.component';

@NgModule({
  imports: [CommonModule, PipesModule, ButtonDropdownModule],
  exports: [LaneHistoryActionsComponent],
  declarations: [LaneHistoryActionsComponent],
})
export class LaneTableActionModule {}
