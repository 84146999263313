import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
  FormControl,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationsService, SearchViewService } from '@haulynx/services';
import { AppModel, GeocodingEntityService, SearchViewEntityService } from '@haulynx/store';

import {
  IColumns2,
  ISearchFilter,
  ISearchFilterType,
  SaveSearchViewSteps,
  SaveSearchViewSummary,
  SidebarItem,
  AsyncSearchManager,
  SearchView,
  PaginatedData,
  User,
} from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { forEach, findIndex, cloneDeep, has } from 'lodash';
import { takeUntil, distinctUntilChanged, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'app-save-search-view',
  templateUrl: './save-search-view.component.html',
  styleUrls: ['./save-search-view.component.scss'],
})
export class SaveSearchViewComponent implements OnInit, OnDestroy {
  form: FormGroup;
  step = SaveSearchViewSteps.FORM;
  steps = SaveSearchViewSteps;
  selectedSidebarItemParent: SidebarItem;
  searchFilters: ISearchFilter[] = [];
  columnsToShow: IColumns2[];
  searchForm: FormGroup;
  showDaysDifference: boolean;
  private alive = aliveWhile();
  graphqlService: any;
  user: User;
  searchViews: SearchView[];
  invalidForm = false;

  constructor(
    private dialogRef: MatDialogRef<SaveSearchViewComponent>,
    @Inject(MAT_DIALOG_DATA) private data: SaveSearchViewSummary,
    private geocodingEntityService: GeocodingEntityService
  ) {
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.maxLength(40), Validators.pattern('^(?!\\s*$).+')]),
    });

    const { selectedSidebarItemParent, searchFilters = [], searchForm, columnsToShow, searchViews } = data;

    this.selectedSidebarItemParent = selectedSidebarItemParent;
    this.searchFilters = searchFilters;
    this.searchForm = searchForm;
    this.searchViews = searchViews;
    this.columnsToShow = columnsToShow?.filter((column: IColumns2) => column.field);
    this.form.setValidators(this.validateName(this.searchViews));
  }

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      if (this.step === SaveSearchViewSteps.FORM) {
        this.goToStep(SaveSearchViewSteps.SUMMARY);
      } else if (this.step === SaveSearchViewSteps.SUMMARY) {
        this.onSubmitSearchView();
      }
    }
  }

  ngOnInit(): void {
    this.geocodingEntityService.getReverseGecodeLocationsManager.searchResults$
      .pipe(
        takeUntil(this.alive),
        distinctUntilChanged(),
        withLatestFrom(this.geocodingEntityService.getReverseGecodeLocationsManager.searchQuery$)
      )
      .subscribe(([results, query]) => {
        forEach(results, (result, i) => {
          const queryCoordinates = query.payload.coordinates;
          const index = findIndex(this.searchFilters, (filter) => {
            return (
              filter.type === ISearchFilterType.GEOSPATIAL &&
              filter.keys['lat'] === queryCoordinates[i]['lat'] &&
              filter.keys['lon'] === queryCoordinates[i]['lon']
            );
          });
          if (index > -1) {
            // this.selectedFilters[index]['keys']['placeInfo'] = result;
            const updatedSelectedFilters = cloneDeep(this.searchFilters);
            forEach(updatedSelectedFilters, (filter) => {
              if (
                filter.type === ISearchFilterType.GEOSPATIAL &&
                filter.keys['lat'] === queryCoordinates[i]['lat'] &&
                filter.keys['lon'] === queryCoordinates[i]['lon']
              )
                filter['keys']['placeInfo'] = result;
            });
            this.searchFilters = updatedSelectedFilters;
          }
        });
      });
  }

  validateName(views: SearchView[]): ValidatorFn {
    return function validatorName(control: AbstractControl): ValidationErrors | null {
      if (views?.some((view) => view.label === control.value?.name)) {
        return { invalid: true };
      } else {
        return null;
      }
    };
  }

  get name(): AbstractControl {
    return this.form.get('name');
  }

  goToStep(step: SaveSearchViewSteps): void {
    if (step === SaveSearchViewSteps.SUMMARY) {
      this.showDaysDifference = true;
    }
    this.step = step;
  }

  onSubmitSearchView(): void {
    this.dialogRef.close({ label: this.name.value });
  }

  ngOnDestroy(): void {
    this.showDaysDifference = false;
  }
}
