import { Pipe, PipeTransform } from '@angular/core';
import { getBrokerIdFromEmail } from '@haulynx/utils';

@Pipe({
  name: 'getBrokerIdFromEmail',
})
export class GetBrokerIdFromEmailPipe implements PipeTransform {
  transform(email: string): string {
    return getBrokerIdFromEmail(email);
  }
}
