import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IColumns, LoadFeedSearchForm, LoadsServiceLoad } from '@haulynx/types';

@Component({
  selector: 'haulynx-unauthenticated-load-feed',
  templateUrl: './unauthenticated-load-feed.component.html',
  styleUrls: ['./unauthenticated-load-feed.component.scss'],
})
export class UnauthenticatedLoadFeedComponent {
  @Input() columns: IColumns[];
  @Input() loading: boolean;
  @Input() loads: LoadsServiceLoad[];
  @Output() onClick = new EventEmitter<string>();
  @Output() search = new EventEmitter<LoadFeedSearchForm>();
  @Output() signup = new EventEmitter<void>();

  constructor() {}

  searchLoads(query: LoadFeedSearchForm): void {
    this.search.next(query);
  }

  viewLoad(id: string): void {
    this.onClick.next(id);
  }

  goToSignup(): void {
    this.signup.next();
  }
}
