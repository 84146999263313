<div class="available-loads-container">
  <div class="available-loads">Available Loads</div>

  <haulynx-unauthenticated-load-feed
    [columns]="columns$ | async"
    [loading]="loading$ | async"
    [loads]="loads$ | async"
    (search)="search($event)"
    (onClick)="loadSelected($event)"
    (signup)="goToSignup()"
  ></haulynx-unauthenticated-load-feed>
</div>
