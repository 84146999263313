<div *ngIf="dashboardCarrierModel.isLoadingTruckSearch$ | async; else card">
  <mat-spinner [diameter]="36"></mat-spinner>
</div>

<ng-template #card>
  <div class="posted-trucks-container">
    <button [ngClass]="{ 'post-button': true, postTruckActive: showPostTruck }" (click)="viewPostTruck(showPostTruck)" data-test="add-posted-truck">
      <span>Add Posted Truck</span>
    </button>
    <div *ngIf="showPostTruck">
      <app-post-truck-form
        [isLoading]="dashboardCarrierModel.isLoadingPostTruck$ | async"
        [minDate]="truckMinDate"
        [timeAvailable]="truckTimeAvailable"
        [defaultRadius]="truckDefaultRadius"
        [defaultEquipment]="truckDefaultEquipment"
        [truckToPopulate]="truckToPopulate"
        [defaultPreferredLocation]="preferredLocation"
        [countryRestrictions]="countryRestrictions"
        [carrierToolbar]="true"
        (saveTruck)="saveTruck($event)"
        (cancelForm)="hidePostTruck(false)"
      ></app-post-truck-form>
    </div>
    <haulynx-pagination-table
      [data]="trucks$ | async"
      [tableConfig]="toolbarConfigOptions"
      [paginationData]="paginationData$ | async"
      [templateCellRef]="templateRef"
      [loading]="dashboardCarrierModel.trucksSearch.isLoading$ | async"
      (page)="onPage($event)"
    >
      <ng-template #templateRef let-row="row">
        <ng-container *ngrxLet="state$ as state">
          <div
            class="posted-trucks-card"
            [ngClass]="{ clicked: row?.id === state?.data?.selectedItemId }"
            (click)="clickedTruck(row)"
          >
            <mat-icon class="icon-display">arrow_right_alt</mat-icon>
            <div class="truck-route">
              <div class="truck-route__text">
                <div class="main-text" data-test="pt-origin-name">{{ row.location | address: 1 }}</div>
                <div class="sub-text" data-test="pt-origin-radius">{{ row.radius }} mi</div>
              </div>
              <div class="truck-route__text">
                <div class="main-text" data-test="pt-destination-name">{{ row.preferredLocation | address: 1 }}</div>
                <div class="sub-text" data-test="pt-destination-radius">{{ row.preferredRadius }} mi</div>
              </div>
            </div>
            <div class="row-display">
              <div class="sub-text" data-test="pt-date">
                {{ row.timeAvailable | mtz: 'M/D/YY':row.timeZone:true }}
                <!-- -
            {{ trucks.timestampTruckPosted | dateTime: trucks.timeZone:'MM/dd/yy' }} -->
              </div>
              <div class="sub-text" data-test="pt-equipment">{{ row.equipmentType }}</div>
            </div>
            <div class="row-display">
              <div class="main-text">
                {{ row.brokerId || 'n/a' }}
              </div>
              <mat-spinner [diameter]="17" *ngIf="loadingMapTruckIds[row.id]"></mat-spinner>
            </div>
          </div>
          <span class="buttonRow" *ngIf="row?.id === state?.data?.selectedItemId">
            <button mat-button class="filterButton" (click)="filterByTruck(row)" [disabled]="!filterButton">
              <span>Filter Load Board</span>
            </button>
            <button
              mat-button
              class="iconButton"
              (click)="deleteTruck(row)"
              [disabled]="user.broker && !user.isCompanyAdmin && row.brokerId !== user.usxId"
            >
              <mat-icon svgIcon="haulynx-trash-gray"></mat-icon>
            </button>
            <button mat-button class="iconButton" (click)="moreCarrierInfo(row)">
              <mat-icon class="goTo" svgIcon="haulynx-go-to-icon"></mat-icon>
            </button>
          </span>
        </ng-container>
      </ng-template>
    </haulynx-pagination-table>
  </div>
</ng-template>
