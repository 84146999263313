import { KeyValuePair } from '../web-interfaces/generic/key-value-pair';

export const exclusionFilters: KeyValuePair[] = [
  {
    key: '1295655',
    value: 'FXG NON REV XT USXI TRL B',
  },
  {
    key: '1310708',
    value: 'FXG XT RETURN TRL (PEAK A',
  },
  {
    key: '1316263',
    value: 'C&D UNIV XT TRAILER RETUR',
  },
  {
    key: '1315986',
    value: 'UPS BACKHAUL NON REV',
  },
  {
    key: '1316433',
    value: 'BBW NON REV',
  },
  {
    key: '1316696',
    value: 'TSC NON REV XT RETURN',
  },
  {
    key: '1317818',
    value: 'TJX XT OUTSOURCED TRAILER',
  },
  {
    key: '1326865',
    value: 'HD UTP XT OUTSOURCED TRAI',
  },
  {
    key: '1325132',
    value: 'HAIN UNIV XT OUTSOURCED T',
  },
  {
    key: '1328462',
    value: 'WINFIBRE XT TRAILER RETUR',
  },
  {
    key: '1308484',
    value: 'FEDEX REPOSITIONING NON R',
  },
  {
    key: '1332986',
    value: 'USX ASSET TRAILER REPOSIT',
  },
  {
    key: '214817',
    value: 'USX UNKNOWN CARRIER TRAIL',
  },
  // FOR TESTING IN DEV ONLY!
  // {
  //   key: '32242',
  //   value: 'CLOROX',
  // },
  // {
  //   key: '29344',
  //   value: 'RANDOM TEST CARRIER',
  // },
];
