import { AssignLoadCarrierForm, AssignLoadInput, Load } from '../load';
import { DispatchLocationInput } from '../location';
import { PaymentItemCreateInput } from '../payment';

export interface BookLoadForCarrier {
  data: Partial<AssignLoadCarrierForm>;
  loadId: string;
  dot: string;
  brokerId: string;
}

export interface BookCarrierPayload {
  paymentItems: PaymentItemCreateInput[];
  editLoad: Partial<Load>;
  brokerId: string;
  bookLoad: { dot: string; loadId: string };
  dispatchLocation: DispatchLocationInput;
  assignInput: AssignLoadInput;
}

export class PaymentItem {
  amount?: number = null;
  orderNumber: string = null;
  paymentType: string = null;
  quantity?: number = null;
  paymentUnits?: string = null;
  haulynxId?: string = null;
  usxId?: string = null;
}
