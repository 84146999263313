export class LoadStepTypeStatus {
  COMPLETED: string;
  PARTIAL_COMPLETED: string;
  SKIPPED: string;
  INACTIVE: string;
}

export const loadStepTypeStatus: LoadStepTypeStatus = {
  COMPLETED: 'completed',
  PARTIAL_COMPLETED: 'partial_completed',
  SKIPPED: 'skipped',
  INACTIVE: 'inactive',
};

export interface LoadStatusIconLabel {
  icon: string;
  label: string;
}

export class LoadStatuses {
  OFFERED: LoadStatusIconLabel;
  BOOKED: LoadStatusIconLabel;
  AWAITING_DISPATCH: LoadStatusIconLabel;
  AT_SHIPPER: LoadStatusIconLabel;
  IN_TRANSIT: LoadStatusIconLabel;
  AT_STOP: LoadStatusIconLabel;
  ASSIGNED: LoadStatusIconLabel;
  AT_RECEIVER: LoadStatusIconLabel;
  CLOSED: LoadStatusIconLabel;
  DELIVERED: LoadStatusIconLabel;
  DISPATCHED: LoadStatusIconLabel;
  COMPLETED: LoadStatusIconLabel;
}

export const loadStatus: LoadStatuses = {
  OFFERED: { icon: 'offered', label: 'Offered' },
  BOOKED: { icon: 'booked', label: 'Booked' },
  AWAITING_DISPATCH: { icon: 'booked', label: 'Booked' },
  AT_SHIPPER: { icon: 'at-shipper', label: 'At Shipper' },
  IN_TRANSIT: { icon: 'in-transit', label: 'In transit' },
  AT_STOP: { icon: 'at-stop', label: 'At stop' },
  AT_RECEIVER: { icon: 'at-receiver', label: 'At Receiver' },
  ASSIGNED: { icon: 'assigned', label: 'Assigned' },
  CLOSED: { icon: 'closed', label: 'Closed' },
  DELIVERED: { icon: 'delivered', label: 'Delivered' },
  DISPATCHED: { icon: 'dispatched', label: 'Dispatched' },
  COMPLETED: { icon: 'completed', label: 'Completed' },
};
