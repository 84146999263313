import { HttpStatus } from '@haulynx/types';
import { AppSettingsActionTypes } from '../../main-store/app-settings.actions';
import { DispatchAction } from '../../shared/store/helpers/action';
import { combineAdapterReducers } from '../../shared/store/helpers/adapter';
import { combineReducersFlat } from '../../shared/store/helpers/reducer';
import { loadActiveTabsAdapter, loadAdapter, loadAvailableTabsAdapter, loadOffersSearchAdapter } from './adapters';
import { LoadActionTypes, LoadActiveActionTypes } from './load.actions';
import { LoadState } from './load.state';

export const initialState: LoadState = new LoadState();

const mainReducer = combineReducersFlat([
  combineAdapterReducers<LoadState>(loadAdapter),
  combineAdapterReducers<LoadState>(loadActiveTabsAdapter),
  combineAdapterReducers<LoadState>(loadAvailableTabsAdapter),
  combineAdapterReducers<LoadState>(loadOffersSearchAdapter),
]);

export function loadReducer(state: LoadState = initialState, action: DispatchAction): LoadState {
  switch (action.type) {
    case AppSettingsActionTypes.LOG_OUT:
      return initialState;

    case LoadActionTypes.CONFIRM_DELIVERY:
      return state.merge({
        isLoadingConfirm: true,
      });

    case LoadActionTypes.CONFIRM_DELIVERY_ERROR:
    case LoadActionTypes.CONFIRM_DELIVERY_SUCCESS:
      return state.merge({
        isLoadingConfirm: false,
      });

    case LoadActionTypes.SET_LOADING:
      return state.merge({
        isLoading: action.payload,
      });

    case LoadActionTypes.SELECT_CARRIER:
      return state.merge({
        selectedCarrier: action.payload,
      });

    case LoadActiveActionTypes.CLOSE:
    case LoadActiveActionTypes.COMPLETE_DELIVERY:
    case LoadActiveActionTypes.CONFIRM_PICKUP:
    case LoadActiveActionTypes.ASSIGNED:
    case LoadActiveActionTypes.REJECT:
    case LoadActiveActionTypes.ACCEPT: {
      const { loadId } = action.payload;

      return state.merge({
        isLoadActiveLoading: state.isLoadActiveLoading.set(loadId, true),
        loadActiveHttpStatus: state.loadActiveHttpStatus.set(loadId, HttpStatus.BLANK),
      });
    }

    case LoadActiveActionTypes.CLOSE_SUCCESS:
    case LoadActiveActionTypes.COMPLETE_DELIVERY_SUCCESS:
    case LoadActiveActionTypes.CONFIRM_PICKUP_SUCCESS:
    case LoadActiveActionTypes.ASSIGNED_SUCCESS:
    case LoadActiveActionTypes.REJECT_SUCCESS:
    case LoadActiveActionTypes.ACCEPT_SUCCESS: {
      const { loadId } = action.payload;

      return state.merge({
        isLoadActiveLoading: state.isLoadActiveLoading.delete(loadId),
        loadActiveHttpStatus: state.loadActiveHttpStatus.set(loadId, HttpStatus.SUCCESS),
      });
    }

    case LoadActiveActionTypes.CLOSE_ERROR:
    case LoadActiveActionTypes.COMPLETE_DELIVERY_ERROR:
    case LoadActiveActionTypes.CONFIRM_PICKUP_ERROR:
    case LoadActiveActionTypes.ASSIGNED_ERROR:
    case LoadActiveActionTypes.REJECT_ERROR:
    case LoadActiveActionTypes.ACCEPT_ERROR: {
      const { loadId } = action.payload;

      return state.merge({
        isLoadActiveLoading: state.isLoadActiveLoading.delete(loadId),
        loadActiveHttpStatus: state.loadActiveHttpStatus.set(loadId, HttpStatus.FAILED),
      });
    }

    case LoadActiveActionTypes.SHOW_NOTES_BUTTON: {
      return state.merge({
        showNotesButton: action.payload,
      });
    }

    default:
      return mainReducer(state, action);
  }
}
