import { LoadLocationType } from '../web-interfaces';
import { LoadsServiceCustomer, LoadsServiceCustomerInput } from './loads-service-customer';
import { LoadsServiceNote } from './loads-service-note';
import { LoadsServicePoint } from './loads-service-point';

export class LoadsServiceLoadLocation {
  id?: string = null;
  customer?: Partial<LoadsServiceCustomer> = null;
  address?: string = null;
  summaryAddress?: string = null;
  city?: string = null;
  state?: string = null;
  zipcode?: string = null;
  timezone?: string = null;

  appointmentStart?: number = null;
  appointmentEnd?: number = null;
  appointmentSet?: boolean;

  carrierArrival?: number = null;
  carrierDeparture?: number = null;
  completed?: boolean;

  billOfLading?: string = null;

  locationType?: LoadLocationType = null;

  geometry?: LoadsServicePoint = null;
  distanceMiles?: number;

  notes?: LoadsServiceNote = null;

  sealNumber?: string;
  constructor(props?: Partial<LoadsServiceLoadLocation>) {
    Object.assign(this, props);
  }
}

export class LoadsServiceLoadLocationInput {
  customer?: LoadsServiceCustomerInput = null;
  address?: string = null;
  city?: string = null;
  state?: string = null;
  zipcode?: string = null;
  timezone?: string = null;
  appointmentSet?: boolean = false;

  appointmentStart?: number = null;
  appointmentEnd?: number = null;

  carrierArrival?: number = null;
  carrierDeparture?: number = null;

  billOfLading?: string = null;

  locationType?: LoadLocationType = null;
  geometry?: LoadsServicePoint = null;

  distanceMiles?: number;

  notes?: LoadsServiceNote = null;

  constructor(props: Partial<LoadsServiceLoadLocationInput>) {
    Object.assign(this, props);
  }
}

export enum DistanceUnits {
  KILOMETERS = 'km',
  MILES = 'mi',
}
