import { Pipe, PipeTransform } from '@angular/core';
import { FeatureFlag, FFState, LoadOrderType, LoadsServiceLoad } from '@haulynx/types';
import { dedicatedFreightIsBookable } from '@haulynx/utils';

@Pipe({
  name: 'dedicatedAllowed',
})
export class DedicatedAllowedPipe implements PipeTransform {
  transform(load: LoadsServiceLoad, features: FFState): boolean {
    return dedicatedFreightIsBookable(load, features);
  }
}
