<div class="app-drop-down {{ customClass }}">
  <mat-icon *ngIf="selectedIcon" class="app-drop-down__icon" [svgIcon]="selectedIcon"></mat-icon>
  <span *ngIf="prefixIcon" matPrefix class="prefix">
    <mat-icon [svgIcon]="prefixIcon"></mat-icon>
  </span>
  <input
    #autocompleteInput
    [attr.data-cy]="cypressName"
    matInput
    [formControl]="keywordSearch"
    [matAutocomplete]="auto"
    [placeholder]="placeholder"
    (focus)="showPanel($event, auto)"
    type="text"
    tab-directive
    class="{{ selectedClass }}"
    [ngClass]="{ disabled: keywordSearch.disabled }"
    (change)="formChange.emit($event)"
    (blur)="searchOnBlur()"
  />
  <mat-autocomplete
    class="{{ customClass }}"
    #auto="matAutocomplete"
    (optionSelected)="select($event)"
    (opened)="panelOpen()"
    panelWidth="auto"
  >
    <mat-optgroup *ngFor="let group of groups" [label]="group">
      <mat-option
        *ngFor="let item of filteredData | async | filter: { group: group }; trackBy: trackByFn"
        [value]="item[key]"
      >
        <ng-container *ngTemplateOutlet="templateLabel; context: { row: item }"></ng-container>
        <div *ngIf="!templateLabel">
          <mat-icon *ngIf="item['svgIcon']" [svgIcon]="item['svgIcon']"></mat-icon>
          {{ item[displayLabel] }}
        </div>
      </mat-option>
    </mat-optgroup>

    <ng-container *ngIf="!groups.length">
      <mat-option
        *ngFor="let item of filteredData | async; trackBy: trackByFn"
        [value]="item[key]"
        [disabled]="item[displayLabel] === 'Customer (Coming Soon)'"
      >
        <ng-container *ngTemplateOutlet="templateLabel; context: { row: item }"></ng-container>
        <div *ngIf="!templateLabel">
          <mat-icon *ngIf="item['svgIcon']" [svgIcon]="item['svgIcon']"></mat-icon>
          {{ item[displayLabel] }}
        </div>
      </mat-option>
    </ng-container>
  </mat-autocomplete>
  <app-spinner [active]="isLoading && !isSelected" diameter="18"></app-spinner>

  <span *ngIf="suffixIcon" matPrefix class="suffix">
    <mat-icon
      [svgIcon]="suffixIcon"
      [matTooltip]="suffixHoverOver"
      matTooltipPosition="right"
      matTooltipClass="tooltip tooltip-class"
    ></mat-icon>
  </span>
  <mat-icon
    class="icon"
    [ngClass]="disabled ? 'icon-disabled' : 'icon-not-disabled'"
    *ngIf="!hideIcon"
    (click)="showPanel($event, auto)"
    data-test="app-dropdown-arrow"
    >{{ auto.isOpen ? 'expand_less' : 'expand_more' }}</mat-icon
  >
</div>
