import { createSelector, MemoizedSelector } from '@ngrx/store';
import { ISearchState } from './state';
import { List } from 'immutable';

export interface ISearchSelectors {
  getLoading: MemoizedSelector<object, boolean>;
  getEntities: MemoizedSelector<object, List<any>>;
  getQuery: MemoizedSelector<object, any>;
  getPagination: MemoizedSelector<object, any>;
  getGraphQlPaging: MemoizedSelector<object, any>;
}

export function createSearchSelectors<T>() {
  return {
    getSelectors(selectState: ((state) => ISearchState) | MemoizedSelector<any, ISearchState>): ISearchSelectors {
      const loading = (state) => state && state.loading;
      const entities = (state) => state && state.entities;
      const query = (state) => state && state.query;
      const pagination = (state: ISearchState) => {
        const { total = 0, limit = 0, page = 1 } = state || {};

        return {
          total,
          limit,
          page,
        };
      };

      const graphQlPaging = (state: ISearchState) => {
        const { limit = 0, page = 1 } = state || {};

        return {
          limit,
          page,
        };
      };

      const getLoading = createSelector(selectState, loading);

      const getEntities = createSelector(selectState, entities);

      const getQuery = createSelector(selectState, query);

      const getPagination = createSelector(selectState, pagination);

      const getGraphQlPaging = createSelector(selectState, graphQlPaging);

      return {
        getLoading,
        getEntities,
        getQuery,
        getPagination,
        getGraphQlPaging,
      };
    },
  };
}
