import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripHtml',
})
export class StripHtmlPipe implements PipeTransform {
  transform(value: string, replaceValue = ' '): string {
    return value.replace(/(<([^>]+)>)/gi, replaceValue);
  }
}
