export class Relationship {
  accepted = false;
  from: string;
  from_type: string;
  from_dot: string;
  to: string;
  to_type: string;
  to_dot: string;
  from_to: string;
  type: string;
  id: string;
}
