<div *ngIf="preferredLanesEntityService.getCarrierLanesManager.isSearchingAndEmpty$ | async; else card">
  <mat-spinner [diameter]="36"></mat-spinner>
</div>

<ng-template #card>
  <div class="lanes-container">
    <button [ngClass]="{ 'post-button': true, postLaneActive: showLaneForm }" (click)="viewPostLane(showLaneForm)" data-test="add-preferred-lane">
      <span>Add Preferred Lane</span>
    </button>
    <div *ngIf="showLaneForm">
      <haulynx-preferred-lanes-form
        [isLoading]="preferredLanesEntityService.createLaneManager.isLoading$ | async"
        [pickupDays]="defaultPickupDays"
        [defaultRadius]="truckDefaultRadius"
        [defaultEquipment]="truckDefaultEquipment"
        [defaultLane]="laneDefaultPayload$ | async"
        [laneToPopulate]="laneToPopulate"
        [carrierToolbar]="true"
        [countryRestrictions]="countryRestrictions"
        (saveLane)="saveLane($event)"
        (cancelForm)="hidePostLane(false)"
      ></haulynx-preferred-lanes-form>
    </div>
    <haulynx-pagination-table
      [data]="data$ | async"
      [tableConfig]="toolbarConfigOptions"
      [paginationData]="paginationData$ | async"
      [templateCellRef]="templateRef"
      [loading]="preferredLanesEntityService.getCarrierLanesManager.isSearching$ | async"
      (page)="onPage($event)"
    >
      <ng-template #templateRef let-row="row">
        <div class="lanes-card" [ngClass]="{ clicked: row?.id === selectedLane?.id }" (click)="clickedLane(row)">
          <mat-icon class="icon-display">arrow_right_alt</mat-icon>
          <div class="truck-route">
            <div class="truck-route__text">
              <div class="main-text" data-test="lane-origin-name">{{ row?.origin?.locationName | address: 1 }}</div>
              <div class="sub-text" data-test="lane-origin-radius">{{ row?.origin?.radius }} mi</div>
            </div>
            <div class="truck-route__text">
              <div class="main-text" data-test="lane-destination-name">{{ row?.destination?.locationName | address: 1 }}</div>
              <div class="sub-text" data-test="lane-destination-radius">{{ row?.destination?.radius }} mi</div>
            </div>
          </div>
          <div class="row-display">
            <div class="sub-text" data-test="lane-date">{{ row?.origin?.availability?.dayOfWeek | daysOfWeekPipe }}</div>
            <div class="sub-text" data-test="lane-equipment">{{ row.equipmentType }}</div>
          </div>
          <div class="row-display">
            <div *ngIf="row.type === laneTypes.CARRIER" class="main-text">Carrier</div>
            <div *ngIf="row.type === laneTypes.BROKER" class="main-text">{{ row?.brokerId }}</div>
            <mat-spinner [diameter]="17" *ngIf="this.loadingMapLaneIds[row.id]"></mat-spinner>
          </div>
        </div>
        <span class="buttonRow" *ngIf="row?.id === selectedLane?.id">
          <button mat-button class="filterButton" (click)="filterByLane(row)" [disabled]="!filterButton">
            <span>Filter Load Board</span>
          </button>
          <button mat-button class="iconButton" (click)="deleteLane(row)">
            <mat-icon svgIcon="haulynx-trash-gray"></mat-icon>
          </button>
          <button mat-button class="iconButton" (click)="moreCarrierInfo(row)">
            <mat-icon class="goTo" svgIcon="haulynx-go-to-icon"></mat-icon>
          </button>
        </span>
      </ng-template>
    </haulynx-pagination-table>
  </div>
</ng-template>
