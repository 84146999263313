import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DropdownDisplay, ISearchFilter, TextFilterType } from '../search-filter';
import { ISearchFilterType } from '../search-filter.types';

export class BrokerFilter implements ISearchFilter {
  name = 'Broker Assignees';
  type: ISearchFilterType = ISearchFilterType.MULTI_DROPDOWN;
  keys: TextFilterType;
  description = 'Filter loads by assigned brokers';

  constructor(entities: Observable<any[]>, defaultValues?: TextFilterType) {
    this.keys = new TextFilterType({
      value: '',
      optionsDataIndex: entities.pipe(
        map((data): DropdownDisplay[] => {
          const options = data.map((dd) => {
            return { value: dd.id, key: dd.label };
          });
          options.unshift({ value: 'None', key: 'None' });
          return options;
        })
      ),
      textFormName: 'assignedBrokers',
      ...defaultValues,
    });
  }
}
