import { ITabsState, Tab } from '@haulynx/types';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { Map } from 'immutable';

export interface ITabSelectors {
  getTabs: MemoizedSelector<Object, Map<string, Tab>>;
  getSelectedId: MemoizedSelector<Object, string>;
  getSelected: MemoizedSelector<Object, Tab>;
}

export function createTabSelectors() {
  return {
    getSelectors<V>(selectState: ((state: V) => ITabsState) | MemoizedSelector<any, ITabsState>): ITabSelectors {
      const tabs = (state) => state && state.tabs;
      const selected = (state) => state && state.selectedTabId;

      const getTabs = createSelector(selectState, tabs);

      const getSelectedId = createSelector(selectState, selected);

      const getSelected = createSelector(getTabs, getSelectedId, (tabs, selectedId) => tabs && tabs.get(selectedId));

      return {
        getTabs,
        getSelectedId,
        getSelected,
      };
    },
  };
}
