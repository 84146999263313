import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { CustomerDetailsDialogComponent } from '@haulynx/components';
import { FireDatabaseService } from '@haulynx/services';
import {
  AppModel,
  LoadDetailsModel,
  LoadEntityService,
  LoadFeedModel,
  LoadModel,
  NotificationModel,
} from '@haulynx/store';
import { Carrier, CustomerFacilityDto, LoadsServiceLoad, LoadRouteSource, Tab, User } from '@haulynx/types';
import {
  AddressPosition,
  aliveWhile,
  getLoadsServiceLoadRoute,
  getLoadsServiceLoadWayPointsCoordinates,
  isLoadsServiceLoadExclusive,
  loadsServiceLoadHasValidCoordinates,
  splitAddress,
} from '@haulynx/utils';
import { get, head, last } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, pairwise, skip, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-load-feed-details',
  templateUrl: './load-feed-details.component.html',
  styleUrls: ['./load-feed-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadFeedDetailsContainerComponent implements OnInit, OnDestroy, OnChanges {
  @Input() loadId = null;

  loadFeed: LoadsServiceLoad;
  alive = aliveWhile();
  isLoadingEntities$: Observable<boolean>;
  carrierId: string;
  carrierDot: string;
  loadFeedRoute$: BehaviorSubject<LoadRouteSource[]> = new BehaviorSubject([]);
  isExclusive = false;
  isCarrier: boolean;

  constructor(
    private appModel: AppModel,
    private loadFeedModel: LoadFeedModel,
    private loadDetailsModel: LoadDetailsModel,
    public loadEntityService: LoadEntityService,
    private loadModel: LoadModel,
    private activatedRoute: ActivatedRoute,
    private fireDatabaseService: FireDatabaseService,
    private dialog: MatDialog,
    private notificationModel: NotificationModel
  ) {
    this.fireDatabaseService.database.goOnline();
    this.appModel.userCarrier$.pipe(takeUntil(this.alive)).subscribe((carrier: Carrier) => {
      this.carrierId = carrier?.id;
      this.carrierDot = carrier?.dot;
    });
  }

  ngOnInit(): void {
    this.appModel.user$.pipe(takeUntil(this.alive)).subscribe((user: User) => {
      this.isCarrier = !!get(user, 'carrier', null);
    });

    this.activatedRoute.params.pipe(takeUntil(this.alive)).subscribe((params) => {
      const { id = null } = params;
      this.loadId = id;
      this.setStateKeyAndGetLoad(id);
    });

    this.loadDetailsModel.routes$.pipe(takeUntil(this.alive)).subscribe((routes: LoadRouteSource[]) => {
      if (routes) {
        const loadFeedRoute = getLoadsServiceLoadRoute(routes, this.loadFeed);

        this.loadFeedRoute$.next(loadFeedRoute);
      }
    });

    this.notificationModel.notifications$
      .pipe(
        skip(1), // The first emission of notifications should not refresh the page
        map((values) =>
          values.filter((value) => value['eventType'] === 'BIDS_EVENT' && value['targetObjectId'] === this.loadId)
        ),
        pairwise(),
        filter(([prev, curr]) => prev.length !== curr.length),
        takeUntil(this.alive)
      )
      .subscribe(() => {
        this.setStateKeyAndGetLoad(this.loadId);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { loadId } = changes;

    if (loadId && loadId.currentValue) {
      const id = loadId.currentValue;
    }
  }

  bookSuccess(loadId: string): void {
    this.setStateKeyAndGetLoad(loadId);
  }

  onShowFacilityInfo({ customerNumber, index }: CustomerFacilityDto): void {
    if (customerNumber) {
      this.dialog.open(CustomerDetailsDialogComponent, {
        data: { customerNumber, index },
        position: { top: '15%' },
      });
    }
  }

  ngOnDestroy(): void {
    this.alive.destroy();
    this.fireDatabaseService.database.goOffline();
  }

  private setStateKeyAndGetLoad(id: string) {
    this.loadEntityService.getLoadByIdManager.dispatch(id);

    this.loadEntityService.getLoadByIdManager
      .getEntityById(id)
      .pipe(
        filter((load) => !!load),
        take(1),
        takeUntil(this.alive)
      )
      .subscribe((load: LoadsServiceLoad) => {
        if (load && loadsServiceLoadHasValidCoordinates(load)) {
          this.loadFeed = new LoadsServiceLoad({ ...load });

          this.isExclusive = isLoadsServiceLoadExclusive(this.loadFeed);

          this.loadDetailsModel.getLoadRoutes({
            key: load.id,
            coordinates: getLoadsServiceLoadWayPointsCoordinates(load),
          });

          const pickUp = head(load.locations);
          const dropOff = last(load.locations);
          const id = load.id;

          const origin = splitAddress(pickUp.address, AddressPosition.CITY, true);
          const destination = splitAddress(dropOff.address, AddressPosition.CITY, true);

          const label = `${origin} → ${destination}`;
          const loadTab = new Tab({
            id,
            label,
            closable: true,
            selected: true,
            hideLabel: true,
          });

          this.loadFeedModel.updateTabs([loadTab]);
          this.loadModel.updateTabs([loadTab]);
        }
      });
  }
}
