import { LngLatLike } from 'mapbox-gl';
import { BehaviorSubject } from 'rxjs';
import { LoadsServiceLoadLocation } from '../../loads-service';
import { Trailer } from '../truck';
import { KeyValuePair } from './../generic/key-value-pair';
import { AssetBridgeTrailerTelemetryHistory } from '../truck/trailer';

export enum MilestoneUser {
  BROKER = 'BROKER',
  CARRIER = 'CARRIER',
  CX = 'CX',
  SYSTEM = 'SYSTEM',
}

export enum MilestoneType {
  CHECKPOINT = 'CHECKPOINT',
  UPDATE = 'UPDATE',
  LOCATION = 'LOCATION',
  TRAILER = 'TRAILER',
}

export enum MilestoneStatus {
  ON_TIME = 'ON_TIME',
  LATE = 'LATE',
  LATE_BREAKDOWN = 'LATE_BREAKDOWN',
  LATE_DETENTION = 'LATE_DETENTION',
  LATE_SERVICE_FAILURE = 'LATE_SERVICE_FAILURE',
}

export enum MilestoneSubtype {
  BOOK = 'BOOK',
  BOUNCE = 'BOUNCE',
  BREAKDOWN = 'BREAKDOWN',
  COMPLETE = 'COMPLETE',
  DETENTION = 'DETENTION',
  DISPATCH = 'DISPATCH',
  DROPOFF = 'DROPOFF',
  INCOMPLETE = 'INCOMPLETE',
  LOCATION = 'LOCATION',
  LUMPER = 'LUMPER',
  OSD = 'OSD',
  PICKUP = 'PICKUP',
  SERVICE_FAILURE = 'SERVICE_FAILURE',
  TONU = 'TONU',
  TRAILER = 'TRAILER',
}

export class MilestoneLog {
  id?: string;
  editedBy: string;
  editedByType?: MilestoneUser;
  primaryEvent: MilestoneEvent;
  secondaryEvent: MilestoneEvent;
  subType: MilestoneSubtype;
  timestamp: number;
  status: MilestoneStatus; // calculated field
}

export class MilestoneComment {
  id?: string;
  contact: string;
  creatorId: string;
  creatorType: MilestoneUser;
  text: string;
  timestamp: number;
  timezone: string;
}

class MilestoneContact {
  email: string;
  name: string;
  phone: string;
}

export class MilestoneEvent {
  latitude: number;
  longitude: number;
  timestamp: number;
  timezone: string;
}

export class Milestone {
  authorId: string;
  authorType: MilestoneUser;
  comments: MilestoneComment[];
  contact?: MilestoneContact;
  createdAt?: number;
  id?: string; // primary key
  lastUpdated?: number;
  loadLocationAddress?: string;
  location: MilestoneEvent;
  locationId: string;
  logs: MilestoneLog[];
  type: MilestoneType;
  telemetryHistory?: AssetBridgeTrailerTelemetryHistory[];
}

export interface MilestoneTrailerState {
  trailer: Trailer;
  checked: boolean;
  $position?: BehaviorSubject<string>;
  $lastUpdate?: BehaviorSubject<number>;
}

export interface MilestoneTrailerToggleEvent {
  id: string;
  checked: boolean;
}

export interface CreateMilestoneFormResult {
  milestoneType: MilestoneType;
  loadLocation: LoadsServiceLoadLocation;
  loadLocationAddress: string;
  milestoneStatus: MilestoneSubtype;
  contact: string;
  timeInDate: number;
  timeOutDate: number;
  comment: string;
  createdBy: string;
  creationTime: number;
}

export interface LoadMilestoneInfo {
  displayStatus: string;
  currentLocationName: string;
  lastUpdateDisplayTime: number;
  isOnTime: boolean;
  totalMiles: number;
  milesCompleted: number;
  trackingType: string;
}

export interface RouteWaypoints {
  name: string;
  location: LngLatLike;
}

export const milestoneShowAllActionButton: KeyValuePair[] = [
  {
    key: 'All',
    value: true,
  },
  {
    key: 'Checkpoints',
    value: true,
  },
  {
    key: 'Issues',
    value: true,
  },
  {
    key: 'Events',
    value: true,
  },
];

export const milestoneRecentActionButton: KeyValuePair[] = [
  {
    key: 'Most Recent',
    value: 0,
  },
  {
    key: 'Type',
    value: 1,
  },
  {
    key: 'Last Modified',
    value: 2,
  },
  {
    key: 'Last Commented',
    value: 3,
  },
];

export const milestoneHamburgerActionMenu: KeyValuePair[] = [
  {
    key: 'Expand All',
    value: 0,
  },
  {
    key: 'Expand Broker Entries',
    value: 1,
  },
  {
    key: 'Collapse All',
    value: 2,
  },
];
