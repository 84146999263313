import { Injectable } from '@angular/core';
import { CachedItem, NgForage, NgForageCache } from 'ngforage';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocalStoreService {
  public changes$ = new BehaviorSubject(null);

  constructor(private readonly ngf: NgForage, private readonly cache: NgForageCache) {}

  public async get<T>(key: string): Promise<T> {
    try {
      const data = await this.ngf.getItem<T>(key);
      return data || null;
    } catch (e) {
      throw 'Unable to get item from storage.';
    }
  }

  public async getCachedItem<T>(key: string): Promise<CachedItem<T>> {
    const cachedItem = await this.cache.getCached<T>(key);
    if (!cachedItem.hasData || cachedItem.expired) return null;
    return cachedItem;
  }

  public set(key: string, data: any): void {
    this.ngf.setItem(key, data);

    this.changes$.next({ key, value: data });
  }

  public async setCached(key: string, data: any, expiration: number): Promise<void> {
    await this.cache.setCached(key, data, expiration);
    this.changes$.next({ key, value: data });
  }

  public async clear(key): Promise<void> {
    try {
      await this.ngf.removeItem(key);
    } catch (e) {
      throw 'Unable to remove item from storage.';
    }
    this.changes$.next({ key, value: null });
  }

  public async clearCache(key): Promise<void> {
    try {
      await this.cache.removeCached(key);
    } catch (e) {
      throw 'Unable to remove item from cache storage.';
    }
    this.changes$.next({ key, value: null });
  }
}
