export interface NeptuneCarrier {
  carrier: string;
  isHaulynxCarrier: boolean;
  carrierPhone: string;
  carrierId: string;
  carrierEmail: string;
  contactName: string;
  carrierDOT: string;
  id: string;
}
