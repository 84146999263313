import { BehaviorSubject, combineLatest } from 'rxjs';
import { Carrier, Device, Truck } from '@haulynx/types';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { aliveWhile } from '@haulynx/utils';
import { concat } from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { CommonEntities } from '@haulynx/store';
import { List } from 'immutable';

@Component({
  selector: 'edit-device-dialog',
  templateUrl: './device-edit.component.html',
  styleUrls: ['./device-edit.component.scss'],
})
export class EditDeviceDialog implements OnInit {
  alive = aliveWhile();
  public editDeviceForm = new FormGroup({
    carrierId: new FormControl(null),
    imei: new FormControl(null),
    type: new FormControl(null),
    truckId: new FormControl(null),
    unitId: new FormControl(null),
    shippedDate: new FormControl(null),
    trackingNumber: new FormControl(null),
    returnDate: new FormControl(null),
    writeOffReason: new FormControl(null),
    cellularCarrierDeactivated: new FormControl(null),
  });

  trucksAndDevices$: BehaviorSubject<Truck[]> = new BehaviorSubject([]);
  public writeOffReasons = [
    { name: 'RMA - Power', value: 'rma_power' },
    { name: 'RMA - Flash', value: 'rma_flash' },
    { name: 'RMA - GSM', value: 'rma_gsm' },
    { name: 'RMA - GPS', value: 'rma_gps' },
    { name: 'RMA - BLE', value: 'rma_ble' },
    { name: 'RMA - Protocol', value: 'rma_protocol' },
    { name: 'Destroyed', value: 'destroyed' },
    { name: 'Lost', value: 'lost' },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      device: Device;
      carriers: Carrier[];
    },
    public commonEntities: CommonEntities
  ) {
    combineLatest([this.editDeviceForm.controls.truckId.valueChanges, this.trucksAndDevices$])
      .pipe(takeUntil(this.alive))
      .subscribe(([truckId, trucksAndDevices]) => {
        if (!truckId) {
          // Make sure to unassign the unitId as well when removing the truck
          return this.editDeviceForm.patchValue({
            unitId: null,
          });
        }

        const truckOrDevice = trucksAndDevices.find((truckOrDevice) => truckOrDevice.id === truckId);
        this.editDeviceForm.patchValue({
          unitId: truckOrDevice?.unitId || null, // Should ever be null, unless unit id doesn't exist on a truck or device
        });
      });

    this.editDeviceForm.controls.carrierId.valueChanges.pipe(takeUntil(this.alive)).subscribe((carrierId: string) => {
      if (!carrierId) {
        // Make sure that when a carrier is unassigned from a device to remove the truck and unit id's from it as well
        return this.editDeviceForm.patchValue({
          truckId: null,
          unitId: null,
        });
      }

      const query = { carrierId };
      const key = carrierId;
      this.commonEntities.graphQlDevices.search({ key, query });
      this.commonEntities.graphQlTrucks.search({ key, query });
    });
  }

  ngOnInit(): void {
    combineLatest([this.commonEntities.graphQlTrucks.entities$, this.commonEntities.graphQlDevices.entities$])
      .pipe(takeUntil(this.alive))
      .subscribe(([trucks, devices]) => {
        this.trucksAndDevices$.next(this.truckList(trucks, devices));
      });

    const device = this.data.device;
    this.editDeviceForm.patchValue({
      ...device,
      carrierId: device?.carrier?.id || null,
    });
  }

  private truckList(trucks: List<Truck>, devices: List<Truck>): Truck[] {
    const phoneDevices = devices.filter((device) => device.type === 'phone');
    const truck = new Truck({ unitId: 'Unassign' });
    const truckAndDevices = concat(truck, trucks.toArray(), phoneDevices.toArray());

    return truckAndDevices;
  }
}
