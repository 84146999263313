import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appTrim]',
})
export class TrimDirective {
  constructor(private elementRef: ElementRef) {}

  @HostListener('blur', ['$event.target.value'])
  handleInput(value: string): void {
    this.elementRef.nativeElement.value = value.trim();
  }
}
