import { Injectable } from '@angular/core';
import { ChartIdentifier } from '@haulynx/types';
import {
  ChartData,
  ChartDataSets,
  ChartLegendItem,
  ChartLegendLabelItem,
  ChartOptions,
  ChartTooltipItem,
  ChartTooltipModel,
} from 'chart.js';
import { LaneHistoryService } from '../lanes/lane-history.service';

@Injectable({
  providedIn: 'root',
})
export class ChartService {
  canvas: HTMLCanvasElement;
  lastTooltipBody;

  constructor(public laneHistoryService: LaneHistoryService) {}

  setCanvasElement = (canvas: HTMLCanvasElement) => {
    this.canvas = canvas;
  };

  baseChartOptions = (): ChartOptions => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        labels: {
          filter: this.chartOptionsLegendFilterLabel,
          generateLabels: this.chartOptionsLegendGenerateLabels,
        },
      },
      tooltips: {
        enabled: false,
        custom: this.chartOptionsTooltipBuilder,
        callbacks: {
          beforeLabel: this.chartOptionsTooltipBeforelabelCallback,
        },
        filter: this.chartOptionsTooltipFilter,
      },
    };
  };

  updateSuggestedMax = (getScalesFunction: Function, suggested: { min: number; max: number }): ChartOptions => {
    return {
      ...getScalesFunction(suggested),
      // standard options for all charts.
      ...this.baseChartOptions(),
    };
  };

  getSuggestedValues = (chartData: ChartDataSets[]): { min: number; max: number } => {
    let max = 0;
    let min = 20000;
    for (let i = 0; i < chartData.length; i++) {
      chartData[i].data.forEach((data) => {
        if (data && data.y) {
          if (data.y > max) max = data.y;
          if (data.y < min) min = data.y;
        }
      });
    }
    return { max: min / 2 + max, min: min / 2 };
  };

  chartOptionsLegendFilterLabel = (legendItem: ChartLegendItem, chartData: ChartData) => {
    if (legendItem.text === '') {
      return false;
    }
    return true;
  };

  chartOptionsLegendGenerateLabels = (chart: any) => {
    const legendLabels: ChartLegendLabelItem[] = [];
    chart.config.data.datasets.forEach((dataSet: ChartDataSets, index: number) => {
      if (dataSet.label !== '') {
        legendLabels.push({
          text: dataSet.label,
          fillStyle: dataSet.borderColor.toString(),
          lineCap: 'round',
          lineDash: [],
          hidden: false,
          lineDashOffset: 0,
          lineJoin: 'round',
          lineWidth: 6,
          datasetIndex: index,
          index: index,
          pointStyle: 'circle',
          strokeStyle: dataSet.borderColor.toString() + '88',
        });
      }
    });
    return legendLabels;
  };

  chartOptionsTooltipFilter = (item: ChartTooltipItem, data: ChartData): boolean => {
    if ((<{ x: number; y: number; r: any }>data.datasets[item.datasetIndex].data[item.index]).r === null) {
      return false;
    }
    return true;
  };

  chartOptionsTooltipBeforelabelCallback = (item: ChartTooltipItem, data: ChartData): string | string[] => {
    const hasCorrectValue =
      data.datasets &&
      data.datasets[item.datasetIndex] &&
      data.datasets[item.datasetIndex].data &&
      data.datasets[item.datasetIndex].data[item.index] &&
      (<{ x: number; y: number; r: any }>data.datasets[item.datasetIndex].data[item.index]).r;
    if (hasCorrectValue) {
      return `${(<{ x: number; y: number; r: any }>data.datasets[item.datasetIndex].data[item.index]).r}`;
    } else if (hasCorrectValue === null) {
      return 'null';
    } else {
      console.error(
        'Error in tooltip. Please supply valid data using the format: <{x: number, y: number, r: any}>. the `r` value is used to determine the info needed for the tooltip.'
      );
    }
  };

  chartOptionsTooltipBuilder = (tooltipModel: ChartTooltipModel) => {
    if (this.canvas) {
      if (
        !this.lastTooltipBody &&
        tooltipModel &&
        tooltipModel.body &&
        tooltipModel.body[0] &&
        tooltipModel.body[0].before &&
        tooltipModel.body[0].before[0]
      ) {
        this.lastTooltipBody = tooltipModel.body;
        const value = tooltipModel.body[0].before[0];
        const dataId = value.split(':')[0];
        const chartIdentifier = value.split(':')[1];
        if (dataId && chartIdentifier) {
          switch (chartIdentifier) {
            case ChartIdentifier.BIDS_ACCEPTED:
            case ChartIdentifier.BIDS_MARGIN:
            case ChartIdentifier.BIDS_RATE:
            case ChartIdentifier.BIDS_REVENUE: {
              // once this function is moved into the respective service, call that services' function
              this.laneHistoryService.renderBidsAcceptedTooltip(tooltipModel, dataId, this.canvas);
              break;
            }
            case ChartIdentifier.LOADS_BOOKED:
            case ChartIdentifier.LOADS_MARGIN:
            case ChartIdentifier.LOADS_RATE:
            case ChartIdentifier.LOADS_REVENUE: {
              // once this function is moved into the respective service, call that services' function
              this.laneHistoryService.renderLoadsBookedTooltip(tooltipModel, dataId, this.canvas, chartIdentifier);
              break;
            }
            default: {
              console.error(
                'Invalid Chart Identifier. Please supply a valid chartID to the data supplied to the tooltip.',
                ` Chart Identifier Supplied: ${chartIdentifier}`
              );
              return;
            }
          }
        }
      } else {
        // this will hide the tooltips when hovering off.
        this.laneHistoryService.renderBidsAcceptedTooltip(tooltipModel, null, this.canvas);
        this.laneHistoryService.renderLoadsBookedTooltip(tooltipModel, null, this.canvas, null);
        this.lastTooltipBody = null;
      }
    }
  };
}
