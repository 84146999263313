import { Injectable } from '@angular/core';
import {
  CarriersService,
  CreateTruckService,
  LoadsServiceService,
  MomentService,
  NotificationsService,
  RecommendedLoadsQuery,
  SearchLanesService,
  SearchTrucksService,
  TrucksService,
} from '@haulynx/services';
import { CarrierInsuranceAndSafety, CarrierKpiMetrics, CarrierUsx, IPostTruck, IPostTruckInput } from '@haulynx/types';
import { separateLanesCityState } from '@haulynx/utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '../../main-store/app.reducers';
import { DispatchAction } from '../../shared/store/helpers/action';
import {
  CarrierActions,
  CarrierActionTypes,
  CarrierDashboardActions,
  CarrierDashboardActionTypes,
  CarrierDashboardLanesActions,
  CarrierDashboardLanesActionTypes,
  CarrierDashboardTrucksActions,
  CarrierDashboardTrucksActionTypes,
} from './carriers.action';
import {
  carrierDashboardLanesSearchSelector,
  carrierDashboardSearchSelector,
  carrierDashboardTrucksSearchSelector,
  carrierSearchSelector,
} from './carriers.selectors';

@Injectable()
export class CarriersEffects {
  carrierSearch = createEffect(() =>
    this.actions$.pipe(
      ofType(CarrierActionTypes.SEARCH),
      map((action: DispatchAction) => action.payload),
      withLatestFrom(
        this.store.select(carrierSearchSelector.getQuery),
        this.store.select(carrierSearchSelector.getPagination)
      ),
      switchMap(([payload, query, paging]) => {
        const { total, ...other } = paging;

        return this.carriersService
          .search({
            search: '',
            ...query,
            paging: other,
          })
          .pipe(
            map((data) => CarrierActions.searchSuccess(data)),
            catchError((errors) => of(CarrierActions.searchError(errors)))
          );
      })
    )
  );

  getCarrier = createEffect(() =>
    this.actions$.pipe(
      ofType(CarrierDashboardActionTypes.GET),
      map((action: DispatchAction) => action.payload),
      switchMap((payload) => {
        const { key, dot } = payload;

        return this.carriersService.getCarrierWithExtraDetails(dot).pipe(
          map((state) => CarrierDashboardActions.getSuccess({ key, state })),
          catchError((error) => {
            this.notificationService.error(`Oops, something went wrong. Unable to get carrier!`, `Carrier`);

            return of(CarrierDashboardActions.getError({ key, error }));
          })
        );
      })
    )
  );

  recommendedLoadSearch = createEffect(() =>
    this.actions$.pipe(
      ofType(CarrierDashboardActionTypes.SEARCH),
      map((action: DispatchAction) => action.payload),
      withLatestFrom(
        this.store.select(carrierDashboardSearchSelector.getQuery),
        this.store.select(carrierDashboardSearchSelector.getGraphQlPaging)
      ),
      switchMap(([payload, query, paging]) => {
        paging = { ...paging, limit: 200 };

        return this.recommendedLoadsQuery
          .searchByDot({ ...query, paging, radius: 100 }, { fetchPolicy: 'network-only' })
          .pipe(
            map((result) => CarrierDashboardActions.searchSuccess(result)),
            catchError((errors) => of(CarrierDashboardActions.searchError(errors)))
          );
      })
    )
  );

  lanesSearch = createEffect(() =>
    this.actions$.pipe(
      ofType(CarrierDashboardLanesActionTypes.SEARCH),
      map((action: DispatchAction) => action.payload),
      withLatestFrom(
        this.store.select(carrierDashboardLanesSearchSelector.getQuery),
        this.store.select(carrierDashboardLanesSearchSelector.getGraphQlPaging)
      ),
      switchMap(([payloadId, query, paging]) => {
        const dot: string = query.carrierDot;

        return this.searchLanesService.searchLanes({ dot, paging }).pipe(
          map((res) => {
            const dedupedLanes = Array.from(new Set(res.result)); // remove duplicates
            const transformedLanes = _.map(dedupedLanes, (entity) => separateLanesCityState(entity));
            return CarrierDashboardLanesActions.searchSuccess({ entities: transformedLanes, total: res.total });
          }),
          catchError((errors) => of(CarrierDashboardLanesActions.searchError(errors)))
        );
      })
    )
  );

  getLoadMultipleRoute = createEffect(() =>
    this.actions$.pipe(
      ofType(CarrierDashboardActionTypes.GET_ROUTES),
      map((action: DispatchAction) => action.payload),
      mergeMap((payload) => {
        const roads = _.map(payload, ({ key, coordinates, mapId }) =>
          this.loadsServiceService.getSingleLoadRoute(coordinates, false).pipe(
            map((geojson): { key: string; geojson: unknown; mapId: string } => {
              return { key, geojson, mapId };
            }),
            catchError((error) => of(CarrierDashboardActions.getRoutesError(error)))
          )
        );

        if (roads.length) {
          return forkJoin(...roads).pipe(map((result) => CarrierDashboardActions.getRoutesSuccess(result)));
        } else {
          return of(CarrierDashboardActions.getRoutesSuccess([]));
        }
      })
    )
  );

  getCarrierKpiMetrics = createEffect(() =>
    this.actions$.pipe(
      ofType(CarrierDashboardActionTypes.GET_KPI_METRICS),
      map((action: DispatchAction) => action.payload),
      mergeMap((payload) => {
        const { dot, dateTime } = payload;

        return this.carriersService.getCalKpiMetrics(dot, dateTime).pipe(
          map((metrics: CarrierKpiMetrics) => {
            // todo update metrics component to work with numbers
            // convert 0 to '0.0' not to break metrics component output
            const result = _.reduce(
              metrics,
              (acc, value, key) => {
                acc[key] = Number(value).toFixed(1);

                return acc;
              },
              {}
            );

            return CarrierDashboardActions.getKpiMetricsSuccess(result);
          }),
          catchError((error) => of(CarrierDashboardActions.getKpiMetricsError(error)))
        );
      })
    )
  );

  getCarriersInsuranceAndSafety = createEffect(() =>
    this.actions$.pipe(
      ofType(CarrierDashboardActionTypes.GET_CARRIER_INSURANCE_AND_SAFETY),
      map((action: DispatchAction) => action.payload),
      mergeMap((dot) =>
        this.carriersService.getInsuranceAndSafety(dot).pipe(
          map((carrierInsuranceAndSafety: CarrierInsuranceAndSafety) =>
            CarrierDashboardActions.getCarrierInsuranceAndSafetySuccess(carrierInsuranceAndSafety)
          ),
          catchError((error) => of(CarrierDashboardActions.getCarrierInsuranceAndSafetyError(error)))
        )
      )
    )
  );

  trucksSearch = createEffect(() =>
    this.actions$.pipe(
      ofType(CarrierDashboardTrucksActionTypes.SEARCH),
      map((action: DispatchAction) => action.payload),
      withLatestFrom(
        this.store.select(carrierDashboardTrucksSearchSelector.getQuery),
        this.store.select(carrierDashboardTrucksSearchSelector.getGraphQlPaging)
      ),
      switchMap(([payload, query, paging]) => {
        const { ['query']: q, ...params } = payload || {};
        return this.searchTrucksService
          .searchTrucks({
            dot: query.dot,
            paging: {
              ...params,
              ...paging,
            },
          })
          .pipe(
            map((res) => {
              const { result = [], ...otherProps } = res || {};
              // Todo: move filter by date into graphql
              const trucks = _.map<IPostTruck, IPostTruck>(result, (truck) => {
                // This prevents any time not saved as 12:00pm to act like it was saved at 12:00pm preventing timezone issues displaying
                const timeAvailable = this.momentService
                  .getMoment(truck.timeAvailable.split('T')[0])
                  .hour(12)
                  .toString();

                const preferredLocationLon = Number(truck.preferredLocationLon);
                const preferredLocationLat = Number(truck.preferredLocationLat);
                return {
                  ...truck,
                  timeAvailable,
                  preferredLocationLat: _.isNaN(preferredLocationLat) ? 0 : preferredLocationLat,
                  preferredLocationLon: _.isNaN(preferredLocationLon) ? 0 : preferredLocationLon,
                };
              }).filter((truck: IPostTruck) => {
                const truckAvailable = this.momentService.getMoment(truck.timeAvailable).startOf('day');
                const now = this.momentService.getMoment().startOf('day');
                return truckAvailable.isSameOrAfter(now);
              });

              if (q?.localSort === 'timeAvailable') {
                const sortedTrucks = trucks.sort((a, b) => {
                  const date1 = this.momentService.getMoment(a.timeAvailable, a.timeZone, true);
                  const date2 = this.momentService.getMoment(b.timeAvailable, b.timeZone, true);
                  return date1.diff(date2);
                });

                return CarrierDashboardTrucksActions.searchSuccess({ entities: sortedTrucks, ...otherProps });
              }

              return CarrierDashboardTrucksActions.searchSuccess({ entities: trucks, ...otherProps });
            }),
            catchError((errors) => of(CarrierDashboardTrucksActions.searchError(errors)))
          );
      })
    )
  );

  createPostedTruck = createEffect(() =>
    this.actions$.pipe(
      ofType(CarrierDashboardTrucksActionTypes.CREATE_POSTED_TRUCK),
      map((action: DispatchAction) => action.payload),
      switchMap((payload: IPostTruckInput | IPostTruckInput[]) => {
        let resolver: Observable<any>;
        let id;

        if (_.isArray(payload)) {
          id = [];
          resolver = forkJoin(
            ...payload.map((truck) => {
              id.push(truck.id);
              return this.createTrucksService.createTruck(truck);
            })
          );
        } else {
          id = payload.id;
          resolver = this.createTrucksService.createTruck(payload);
        }

        return resolver.pipe(
          mergeMap((result): Actions[] => [
            CarrierDashboardTrucksActions.createPostedTruckSuccess({ id }),
            CarrierDashboardTrucksActions.search(),
          ]),
          catchError((error) => {
            this.notificationService.error(error.message, 'Problem creating posted truck');
            return of(CarrierDashboardTrucksActions.createPostedTruckError({ error, id }));
          })
        );
      })
    )
  );

  removePostedTruck = createEffect(() =>
    this.actions$.pipe(
      ofType(CarrierDashboardTrucksActionTypes.REMOVE_POSTED_TRUCK),
      map((action: DispatchAction) => action.payload),
      switchMap((payload: { id: string }) => {
        const { id } = payload;
        return this.trucksService.deleteTruck({ truckId: id }).pipe(
          switchMap(() => [
            CarrierDashboardTrucksActions.removePostedTruckSuccess({ id }),
            CarrierDashboardTrucksActions.search(),
          ]),
          catchError((error) => {
            this.notificationService.error(error.message, 'Problem deleting posted truck');
            return of(CarrierDashboardTrucksActions.removePostedTruckError({ error, id }));
          })
        );
      })
    )
  );

  getCarriersStatus = createEffect(() =>
    this.actions$.pipe(
      ofType(CarrierDashboardActionTypes.GET_CARRIER_STATUS),
      map((action: DispatchAction) => action.payload),
      mergeMap((dot) =>
        this.carriersService.getCarrierUSXData(dot).pipe(
          map((getCarrierUSXData: CarrierUsx) => CarrierDashboardActions.getCarrierStatusSuccess(getCarrierUSXData)),
          catchError((error) => of(CarrierDashboardActions.getCarrierStatusError(error)))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private notificationService: NotificationsService,
    private store: Store<AppState>,
    private loadsServiceService: LoadsServiceService,
    private recommendedLoadsQuery: RecommendedLoadsQuery,
    private momentService: MomentService,
    private searchLanesService: SearchLanesService,
    private searchTrucksService: SearchTrucksService,
    private createTrucksService: CreateTruckService,
    private carriersService: CarriersService,
    private trucksService: TrucksService
  ) {}
}
