import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { size } from 'lodash';
import m from 'moment';
import moment from 'moment-timezone';

export function ImeiValidator(length): ValidatorFn {
  return function validate(control: AbstractControl): ValidationErrors | null {
    const imeiSize = size(control.value);

    return imeiSize === 0 || imeiSize === length ? null : { imei: true };
  };
}

/**
 * @deprecated
 * @see {@link dateLessThanNowMoment}
 */
export function dateLessThanNowTz(timezone: string, offset = 0): ValidatorFn {
  return function validate(ctrl: AbstractControl): ValidationErrors | null {
    const currentDate = Date.now();
    const val = moment.tz(moment(ctrl.value).format('YYYY-MM-DD HH:mm:ss'), timezone).local(true);

    if (ctrl.disabled || !val.isValid()) {
      return null;
    }

    return val.valueOf() > currentDate + offset ? { dateMoreThanNow: true } : null;
  };
}

/**
 * This uses moment instead of JS Date which uses local time
 * @param timezone
 */
export function dateLessThanNowMoment(timezone: string): ValidatorFn {
  return function validate(ctrl: AbstractControl): ValidationErrors | null {
    const currentDate = m().tz(timezone).local(true);
    const val = m(ctrl.value).tz(timezone).local(true);

    if (ctrl.disabled) {
      return null;
    }

    if (val.isAfter(currentDate)) {
      return { 'Date must be before today': true };
    }

    return null;
  };
}

export function locationAndTimeNotNull(locationFormControlName, timeFormControlName) {
  return (form: FormGroup): ValidationErrors | null => {
    const locationControl = form.get(locationFormControlName);
    const timeControl = form.get(timeFormControlName);

    if (locationControl && timeControl) {
      const isValidLocation = !!(locationControl.value && locationControl.value.address);
      const isValidTime = !!timeControl.value;

      if (!isValidLocation && isValidTime) {
        return { locationIsEmpty: true };
      }

      if (isValidLocation && !isValidTime) {
        return { timeIsEmpty: true };
      }
    }

    return null;
  };
}

export function minLessThanMax(minFormControlName, maxFormControlName): ValidatorFn {
  return (group: FormGroup): { [key: string]: unknown } => {
    const minControl = group.controls[minFormControlName];
    const maxControl = group.controls[maxFormControlName];

    if (!!maxControl.errors || !!minControl.errors) {
      if (!!minControl.value && !!maxControl.value) {
        if (minControl.value <= maxControl.value) {
          minControl.setErrors(null);
          maxControl.setErrors(null);
          return;
        }
        minControl.setErrors({ minGreaterThanMax: true });
        maxControl.setErrors({ minGreaterThanMax: true });
        return;
      }

      if (!!minControl.value && !!minControl.errors && !maxControl.value) {
        minControl.setErrors(null);
        return;
      }

      if (!!maxControl.value && !!maxControl.errors && !minControl.value) {
        maxControl.setErrors(null);
        return;
      }

      return;
    }

    if (!!minControl.value && !!maxControl.value) {
      if (minControl.value > maxControl.value) {
        minControl.setErrors({ minGreaterThanMax: true });
        maxControl.setErrors({ minGreaterThanMax: true });
        return;
      }
    }

    return null;
  };
}
