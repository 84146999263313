import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@haulynx/modules';
import { LoadTimelineModule } from '../load-timeline/load-timeline.module';
import { LoadQuickActionsComponent } from './load-quick-actions.component';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, LoadTimelineModule, MaterialModule],
  declarations: [LoadQuickActionsComponent],
  exports: [LoadQuickActionsComponent],
})
export class LoadQuickActionsModule {}
