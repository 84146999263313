import gql from 'graphql-tag';

export const GetLoadsBidDetails = gql`
  query getLoadsBidDetails($loadIds: [String!]) {
    getLoadsBidDetails(loadIds: $loadIds) {
      loadId
      offerCount
      lowestBid {
        price
        carrier {
          name
        }
      }
    }
  }
`;
