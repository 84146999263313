import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CarrierSignupOnePageDialogComponent } from '@haulynx/components';
import { LoadGridService, MetaService, UnauthenticatedLoadFeedService } from '@haulynx/services';
import { AppModel, UserEntityService } from '@haulynx/store';
import { FeatureFlag, FFState, IColumns, LoadFeedSearchForm, LoadsServiceLoad } from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'haulynx-available-loads-container',
  templateUrl: './available-loads-container.component.html',
  styleUrls: ['./available-loads-container.component.scss'],
})
export class AvailableLoadsContainerComponent implements OnInit, OnDestroy {
  public columns$: Observable<IColumns[]>;
  public loading$ = new BehaviorSubject<boolean>(true);
  public loads$: Subject<LoadsServiceLoad[]> = new Subject();
  private alive = aliveWhile();
  private isTestUser: boolean;

  private dot: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private appModel: AppModel,
    private loadGridService: LoadGridService,
    private matDialog: MatDialog,
    private metaService: MetaService,
    private router: Router,
    private unauthenticatedLoadFeedService: UnauthenticatedLoadFeedService,
    private zone: NgZone,
    private userEntityService: UserEntityService
  ) {
    this.metaService.setMobileViewport();

    this.userEntityService.featureFlags$.pipe(takeUntil(this.alive)).subscribe((feature: FFState) => {
      this.isTestUser = !!feature[FeatureFlag.TEST_LOADS];
    });
  }

  ngOnInit() {
    this.columns$ = this.loadGridService.loadSearchColumnsUnathenticated$;

    this.activatedRoute.queryParams.pipe(takeUntil(this.alive)).subscribe((params) => {
      const { dot = null } = params;
      this.dot = dot;
    });

    this.appModel.user$.pipe(take(1), takeUntil(this.alive)).subscribe((user) => {
      if (user) {
        this.routeUser();
      }
    });
  }

  ngOnDestroy() {
    this.metaService.setMobileViewport();
  }

  goToSignup(): unknown {
    if (this.dot) {
      return this.matDialog
        .open(CarrierSignupOnePageDialogComponent, {
          height: '80%',
        })
        .afterClosed()
        .pipe(takeUntil(this.alive))
        .subscribe((route: boolean) => {
          if (route) {
            this.routeUser();
          }
        });
    }

    this.router.navigateByUrl('/signup');
  }

  loadSelected(id: string): void {
    this.router.navigate([`loads/${id}`], { queryParamsHandling: 'preserve' });
  }

  search(query: LoadFeedSearchForm): void {
    this.setLoading();

    this.unauthenticatedLoadFeedService
      .getUsxOrdersAsLoads(query, this.isTestUser)
      .pipe(takeUntil(this.alive))
      .subscribe(
        (loads: LoadsServiceLoad[]) => {
          this.zone.run(() => {
            this.loads$.next(loads);
            this.loading$.next(false);
          });
        },
        (error) => {
          this.setLoading(false);
        }
      );
  }

  /**
   * Route the user away from the unauthenticated page to authenticated search page if logged in
   */
  private routeUser(): void {
    this.router.navigate([`dashboard/loads/search/all`], {
      queryParamsHandling: 'preserve',
    });
  }

  private setLoading(loading = true) {
    this.zone.run(() => this.loading$.next(loading));
  }
}
