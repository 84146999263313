import { RmisRegistration } from '@haulynx/types';

const KeysToKeep = [
  'fleetRegistered',
  'w9Orw8Registered',
  'comcheckAdvancesRegistered',
  'paymentOptionRegistered',
  'supplierDiversityRegistered',
  'isRmisSetup',
];

export const rmisAccountStatusComplete = (registration: RmisRegistration): number => {
  if (!registration) return 0;

  const keys = Object.keys(registration).filter((key: string) => KeysToKeep.indexOf(key) >= 0);
  const trueElements = keys.filter((key: string) => registration[key]);
  return Math.floor((trueElements.length / keys.length) * 100);
};
