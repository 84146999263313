import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadsServiceLoad } from '@haulynx/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UnauthenticatedLoadDetailsService {
  constructor(private http: HttpClient) {}

  public getLoadDetails(id: string): Observable<LoadsServiceLoad> {
    return this.http.get<LoadsServiceLoad>('/api/loads/query', { params: { id } });
  }
}
