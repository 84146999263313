import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { VirtualColumn, VirtualColumnPipeType } from '@haulynx/types';
import { get } from 'lodash';

@Pipe({
  name: 'virtualTableGet',
})
export class VirtualTableGetPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe, private decimalPipe: DecimalPipe) {}

  transform(data: any, column: VirtualColumn): any {
    if (!column.dataIndex) {
      return data[column.id] || column.defaultValue || '';
    }

    let value = get(data, column.dataIndex, null);
    const pipe = column.pipe;
    if (pipe?.type) {
      switch (+pipe.type) {
        case VirtualColumnPipeType.currency:
          value = this.checkPrice(value, column);
          break;
        case VirtualColumnPipeType.decimal:
          value = this.decimalPipe.transform(value, pipe.digitsInfo);
          break;

        default:
          break;
      }
    }

    if (this.shouldShowDefaultValue(value, column)) {
      return column.defaultValue ?? '';
    }

    return value;
  }

  /**
   * There are some times where price should be displayed as the default value when 0 instead of $0.00
   */
  private checkPrice(value: any, column: VirtualColumn) {
    if (this.shouldShowDefaultValue(value, column)) {
      return null;
    }

    return this.currencyPipe.transform(value, undefined, undefined, column.pipe.digitsInfo);
  }

  private shouldShowDefaultValue(value: any, column: VirtualColumn) {
    return value == null || (value === 0 && column.showDefaultValueWhenZero);
  }
}
