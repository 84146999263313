import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  buttonTypes,
  KeyValuePair,
  milestoneHamburgerActionMenu,
  milestoneRecentActionButton,
  milestoneShowAllActionButton,
} from '@haulynx/types';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss'],
})
export class ActionsComponent implements OnInit {
  hamburger = milestoneHamburgerActionMenu;
  recent = milestoneRecentActionButton;
  showAll = milestoneShowAllActionButton;
  actionButtons = [buttonTypes.CREATE_MILESTONE];

  @Input() disabledButtons = [];
  @Output() buttonClicked: EventEmitter<{ event: KeyValuePair[]; eventType: string }> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  clickEvent(event: KeyValuePair[], eventType: string) {
    this.buttonClicked.emit({ event, eventType });
  }
}
