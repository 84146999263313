import gql from 'graphql-tag';

export const CreateCapacityManagementLane = gql`
  mutation CreateCapacityManagementLane($newLane: CapacityObjectInput!) {
    createCapacityManagementLane(newLane: $newLane) {
      id
      userId
      type
      carrierDot
      brokerId
      active
      origin {
        point {
          lat
          lon
        }
        radius
        state
        region
        availability {
          dayOfWeek
          timestamp
          date
        }
        locationName
      }
      destination {
        point {
          lat
          lon
        }
        radius
        state
        region
        availability {
          dayOfWeek
          timestamp
          date
        }
        locationName
      }
      equipmentType
      cost {
        min
        max
      }
      minimumStopCharge
      rpm {
        min
        max
      }
      count
      expireTimestamp
      allowStops
      hazmat
      lastUpdatedAt
      showLoadNotifications
      recommendations {
        total
        result {
          id
          carrierId
          carrierDot
          carrier {
            id
          }
          laneId
          lane {
            id
          }
          loadId
          load {
            id
          }
          loadsServiceLoad {
            id
          }
          orderNumber
          tmwNumber
          type
          loadCreated
          status
          loadFeedKey
          loadPickupAddress
          loadPickupTimestamp
          loadDropoffAddress
          loadDropoffTimestamp
          loadEquipmentType
          loadWeight
          loadDistance
          loadPickupLat
          loadPickupLon
          loadDropoffLat
          loadDropoffLon
          price
          revenue
          brokerName
          loadDropoffTimezone
          loadPickupTimezone
          loadPickupGeohash {
            lat
            lon
            precision
            hash
            approximateAddress
          }
          loadDropoffGeohash {
            lat
            lon
            precision
            hash
            approximateAddress
          }
          recommendationCreatedTimestamp
        }
      }
    }
  }
`;
