<div class="row expand" [ngClass]="cls">
  <div class="col-lg-12">
    <div (click)="viewContent()" class="expand-label">
      <ng-content select="[expandPanelLabel]"></ng-content>
      <button mat-button class="expand-view">
        <mat-icon *ngIf="isExpanded"> keyboard_arrow_up </mat-icon>
        <mat-icon *ngIf="!isExpanded"> keyboard_arrow_down </mat-icon>
      </button>
    </div>

    <div class="expand-content" *ngIf="isExpanded">
      <ng-content select="[expandPanelContent]"></ng-content>
    </div>
  </div>
</div>
