import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@haulynx/modules';
import { DatepickerModule } from '../datepicker/datepicker.module';
import { DropDownModule } from '../drop-down/drop-down.module';
import { LoadTableSearchBarComponent } from './load-table-search-bar.component';

@NgModule({
  declarations: [LoadTableSearchBarComponent],
  exports: [LoadTableSearchBarComponent],
  imports: [ReactiveFormsModule, DropDownModule, DatepickerModule, CommonModule, MaterialModule],
})
export class LoadTableSearchBarModule {}
