export interface TrackingChip {
  name: string;
  available: TrackingAvail;
}

export enum TrackingAvail {
  TRUE = 'true',
  FALSE = 'false',
  UNKNOWN = 'unknown',
}
