import { Injectable } from '@angular/core';
import { CarrierManagement } from '@haulynx/types';
import { get } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class CarrierManagementTableService {
  constructor() {}

  customSort(row: CarrierManagement, column: string) {
    switch (column) {
      case 'lastContacted': {
        return row.lastContacted.timestamp;
      }
      case 'volume': {
        const mtd = get(row, 'volume.mtd', 0);
        const priorMonth = get(row, 'volume.priorMonth', 0);
        const ytd = get(row, 'volume.ytd', 0);

        return '' + mtd + priorMonth + ytd;
      }
    }
  }
}
