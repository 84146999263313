import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { LoginService, UserService } from '@haulynx/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AdminGuard implements CanActivate, CanActivateChild {
  private isAdmin = false;
  constructor(private loginService: LoginService, private user: UserService) {
    this.user.isHaulynxAdmin().subscribe((admin) => {
      this.isAdmin = admin;
    });
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.loginService.isLoggedIn().pipe(map((loggedIn: boolean) => loggedIn && this.isAdmin));
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(next, state);
  }
}
