import { ISearchFilter, NumericalFilterType } from '../search-filter';
import { ISearchFilterType } from '../search-filter.types';

export class PriceFilter implements ISearchFilter {
  name = 'Price';
  type: ISearchFilterType = ISearchFilterType.NUMBER_RANGE;
  keys: NumericalFilterType;
  validation?: NumericalFilterType;
  description = 'Filter loads by the load price';
  formPrefix = '$';

  constructor(defaultValues: Partial<NumericalFilterType> = {}, validationValues: Partial<NumericalFilterType> = {}) {
    this.keys = new NumericalFilterType({
      min: 0,
      max: null,
      minFormName: 'minPrice',
      maxFormName: 'maxPrice',
      ...defaultValues,
    });
    this.validation = new NumericalFilterType(validationValues);
  }
}
