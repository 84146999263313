import { LoadsServiceLoad, LoadsServiceRestrictionTypes } from '@haulynx/types';

export const getLoadRestriction = (load: LoadsServiceLoad, type: LoadsServiceRestrictionTypes): boolean | string => {
  const value = load?.restrictions.find((r) => r.type === type)?.value;
  switch (value) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return value;
  }
};
