import { FeatureFlag } from '../feature-flag';

export interface SidebarItem<T = any, U = any> {
  id?: string;
  label: string;
  route?: string;
  parent?: string;
  data?: T;
  quantity?: number;
  children?: SidebarItem<T>[];
  metaData?: U;
  featureFlag?: FeatureFlag;
  isCustom?: boolean;
  order?: number;
}
