<ng-content></ng-content>
<form [formGroup]="formGroup" class="address" [ngClass]="{ view: disabled }">
  <p>
    <mat-form-field>
      <label for="address">Address:</label>
      <input matInput formControlName="address" id="address" />
      <mat-error *ngIf="formGroup.get('address').invalid">This field is required</mat-error>
    </mat-form-field>
    <mat-form-field>
      <label for="address2">Address 2:</label>
      <input matInput id="address2" formControlName="address2" />
    </mat-form-field>
  </p>

  <table class="example-full-width" cellspacing="0">
    <tr>
      <td>
        <mat-form-field>
          <label for="city">City:</label>
          <input matInput id="city" formControlName="city" />
          <mat-error *ngIf="formGroup.get('city').invalid">This field is required</mat-error>
        </mat-form-field>
      </td>
      <td>
        <mat-form-field>
          <label for="state">State:</label>
          <input matInput id="state" formControlName="state" />
          <mat-error *ngIf="formGroup.get('state').invalid">This field is required</mat-error>
        </mat-form-field>
      </td>
      <td>
        <mat-form-field>
          <label for="zip">Zip code:</label>
          <input
            matInput
            id="zip"
            formControlName="zip"
            #postalCode
            required
            type="tel"
            pattern="[0-9]{5}"
            maxlength="5"
          />
          <mat-error *ngIf="formGroup.get('zip').getError('pattern')">ex: 37406</mat-error>
          <mat-error *ngIf="formGroup.get('zip').getError('required')">This field is required</mat-error>
          <mat-hint align="end">{{ postalCode.value.length }} / 5</mat-hint>
        </mat-form-field>
      </td>
    </tr>
  </table>
</form>
