import { ISearchFilter, TextFilterType } from '../search-filter';
import { ISearchFilterType } from '../search-filter.types';

export class LaneRadiusFilter implements ISearchFilter {
  name = 'Lane Radius';
  type: ISearchFilterType = ISearchFilterType.TEXT;
  keys: TextFilterType;
  description = 'Lane radius for lane history';

  constructor(defaultValues?: TextFilterType) {
    const options = Object.values(laneHistoryLaneRadius).map((value) => {
      return {
        key: value + ' miles',
        value: value + ' miles',
      };
    });
    this.keys = new TextFilterType({
      value: '',
      optionsDataIndex: options,
      textFormName: 'laneRadius',
      ...defaultValues,
    });
  }
}

export enum laneHistoryLaneRadius {
  MILE_5 = '5',
  MILE_10 = '10',
  MILE_25 = '25',
  MILE_50 = '50',
}
