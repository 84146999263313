<div class="header-toolbar-tabs">
  <nav mat-tab-nav-bar>
    <ng-container *ngFor="let item of headerItems">
      <ng-container *ngIf="!item.children?.length; else hasChildrenRoutes">
        <a
          mat-tab-link
          *ngIf="!item?.accountItem && !item?.termsOrPrivacyItem"
          [active]="item.selected"
          (click)="selectedItem(item)"
          (mouseenter)="closeMenu()"
          class="item-name"
          [attr.data-test]="item.name"
          >{{ item.name }}</a
        >
      </ng-container>
      <ng-template #hasChildrenRoutes>
        <a
          #triggerButton
          mat-tab-link
          [active]="item.selected"
          [matMenuTriggerFor]="navigationDropdown"
          #menuTrigger="matMenuTrigger"
          [matMenuTriggerData]="{ children: item.children }"
          (mouseenter)="openMenu()"
          class="item-name"
          [attr.data-test]="item.name"
        >
          {{ item.name }}
        </a>
        <mat-menu #navigationDropdown="matMenu" [hasBackdrop]="false">
          <span (mouseleave)="closeMenu()">
            <ng-template matMenuContent let-children="children">
              <button
                *ngFor="let child of children"
                mat-menu-item
                [attr.data-test]="child.name"
                (click)="selectedItem(child)"
              >
                {{ child.name }}
              </button>
            </ng-template>
          </span>
        </mat-menu>
      </ng-template>
    </ng-container>
    <ng-container *ngIf="!user?.isHaulynxAdmin">
      <ng-container *appFeatures="featureFlag">
        <a mat-tab-link data-test="whats-new" (click)="startTour()" [disableRipple]="true">What's New</a>
      </ng-container>
    </ng-container>
  </nav>
</div>
