import { PaymentItemSearchCriteria, PaymentsTypeForm } from '@haulynx/types';
import { createAction, createActionTypes } from '../../shared/store/helpers/action';
import { TYPE } from './adapters';

export interface IPaymentTypesActions {
  addPaymentTypeState(data: { payment: PaymentsTypeForm; index: number });
  removePaymentTypeState(data: { index: number });
  updatePaymentTypeState(data: { index: number; payment: Partial<PaymentsTypeForm> });
  clearState();
  getPaymentType(data?: Partial<PaymentItemSearchCriteria>);
  getPaymentTypeSuccess(data: { entities: PaymentsTypeForm[] });
  getPaymentTypeError();
  savePayments(data: {
    orderNumber: string;
    loadId: string;
    payments: PaymentsTypeForm[];
    sendEditPriceParams: { userEmail: string };
  });
  savePaymentsSuccess(data: { orderNumber?: string });
  savePaymentsError();
}

export interface IPaymentTypesActionTypes {
  ADD_PAYMENT_TYPE_STATE: string;
  REMOVE_PAYMENT_TYPE_STATE: string;
  CLEAR_STATE: string;
  UPDATE_PAYMENT_TYPE_STATE: string;
  GET_PAYMENT_TYPES: string;
  GET_PAYMENT_TYPES_SUCCESS: string;
  GET_PAYMENT_TYPES_ERROR: string;
  SAVE_PAYMENT_TYPE: string;
  SAVE_PAYMENT_TYPE_SUCCESS: string;
  SAVE_PAYMENT_TYPE_ERROR: string;
}

export const paymentTypesTypes: IPaymentTypesActionTypes = {
  ADD_PAYMENT_TYPE_STATE: 'add payment type',
  REMOVE_PAYMENT_TYPE_STATE: 'remove payment type',
  UPDATE_PAYMENT_TYPE_STATE: 'update payment type',
  CLEAR_STATE: 'clear state',
  GET_PAYMENT_TYPES: 'get payment types',
  GET_PAYMENT_TYPES_SUCCESS: 'get payment types success',
  GET_PAYMENT_TYPES_ERROR: 'get payment types error',
  SAVE_PAYMENT_TYPE: 'save payment',
  SAVE_PAYMENT_TYPE_SUCCESS: 'save payment success',
  SAVE_PAYMENT_TYPE_ERROR: 'save payment error',
};

export const paymentTypesActions: IPaymentTypesActions = {
  addPaymentTypeState: createAction(TYPE, paymentTypesTypes.ADD_PAYMENT_TYPE_STATE),
  removePaymentTypeState: createAction(TYPE, paymentTypesTypes.REMOVE_PAYMENT_TYPE_STATE),
  updatePaymentTypeState: createAction(TYPE, paymentTypesTypes.UPDATE_PAYMENT_TYPE_STATE),
  clearState: createAction(TYPE, paymentTypesTypes.CLEAR_STATE),
  getPaymentType: createAction(TYPE, paymentTypesTypes.GET_PAYMENT_TYPES),
  getPaymentTypeSuccess: createAction(TYPE, paymentTypesTypes.GET_PAYMENT_TYPES_SUCCESS),
  getPaymentTypeError: createAction(TYPE, paymentTypesTypes.GET_PAYMENT_TYPES_ERROR),
  savePayments: createAction(TYPE, paymentTypesTypes.SAVE_PAYMENT_TYPE),
  savePaymentsSuccess: createAction(TYPE, paymentTypesTypes.SAVE_PAYMENT_TYPE_SUCCESS),
  savePaymentsError: createAction(TYPE, paymentTypesTypes.SAVE_PAYMENT_TYPE_ERROR),
};

export const PaymentTypesActions: IPaymentTypesActions = paymentTypesActions;
export const PaymentTypesActionTypes: IPaymentTypesActionTypes = createActionTypes(paymentTypesTypes)(TYPE);
