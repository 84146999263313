import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SidebarNavSubAccountComponent } from './sidebar-nav-sub-account.component';
import { SidebarNavSubTemplateModule } from '../sidebar-nav-sub-template/sidebar-nav-sub-template.module';
import { MaterialModule } from '@haulynx/modules';

@NgModule({
  declarations: [SidebarNavSubAccountComponent],
  imports: [CommonModule, FlexLayoutModule, SidebarNavSubTemplateModule, MaterialModule],
  exports: [SidebarNavSubAccountComponent],
})
export class SidebarNavSubAccountModule {}
