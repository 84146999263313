import { Injectable } from '@angular/core';
import { BrokersService, CarriersService, LoadsServiceService } from '@haulynx/services';
import { ActiveBrokeredLoadsAsLoadsParams, ActiveLoadFilter, PageAndSort, PaginationParams } from '@haulynx/types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '../../main-store/app.reducers';
import { DispatchAction } from '../../shared/store/helpers/action';
import { ActiveLoadsActions, ActiveLoadsActionTypes } from './active-loads-state.actions';
import { activeLoadsSearchSelector } from './active-loads-state.selectors';

@Injectable()
export class ActiveLoadsStateEffects {
  searchActiveLoads = createEffect(() =>
    this.actions$.pipe(
      ofType(ActiveLoadsActionTypes.SEARCH),
      map((action: DispatchAction) => action.payload),
      withLatestFrom(
        this.store.select(activeLoadsSearchSelector.getGraphQlPaging),
        this.store.select(activeLoadsSearchSelector.getQuery)
      ),
      switchMap(
        ([payload, pagination, stateQuery]: [{ query: ActiveLoadFilter }, PaginationParams, ActiveLoadFilter]) => {
          const { broker, region, carrier, orderId, customer, pickupDate } = (payload && payload.query) || stateQuery;
          const searchFields: { filterParams: ActiveBrokeredLoadsAsLoadsParams; paging: Partial<PageAndSort> } = {
            filterParams: {
              brokerId: broker || undefined,
              customerName: customer || undefined,
              dot: carrier || undefined,
              onlyBookedLoads: true,
              orderNumber: orderId || undefined,
              pickupTimestamp: pickupDate || undefined,
              region: region || undefined,
            },
            paging: pagination,
          };

          return this.loadsServiceService.getLoadsServiceLoadsBrokered(searchFields).pipe(
            map(({ entities, total }) => ActiveLoadsActions.searchSuccess({ entities, total })),
            catchError((error) => of(ActiveLoadsActions.searchError({ error })))
          );
        }
      )
    )
  );

  searchCarriers = createEffect(() =>
    this.actions$.pipe(
      ofType(ActiveLoadsActionTypes.SEARCH_CARRIER),
      map((action: DispatchAction) => action.payload),
      switchMap(({ query, pagination }) =>
        this.carriersService
          .search({
            search: '',
            ...query,
            paging: pagination,
          })
          .pipe(
            map(({ entities }: any) => ActiveLoadsActions.searchCarrierSuccess({ entities })),
            catchError((error) => of(ActiveLoadsActions.searchCarrierError({ error })))
          )
      )
    )
  );

  searchBrokers = createEffect(() =>
    this.actions$.pipe(
      ofType(ActiveLoadsActionTypes.SEARCH_BROKER),
      map((action: DispatchAction) => action.payload),
      switchMap(({ query, pagination }) =>
        this.brokersService.getAllBrokerIds().pipe(
          map(({ entities }: any) => ActiveLoadsActions.searchBrokerSuccess({ entities })),
          catchError((error) => of(ActiveLoadsActions.searchBrokerError({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private loadsServiceService: LoadsServiceService,
    private carriersService: CarriersService,
    private brokersService: BrokersService
  ) {}
}
