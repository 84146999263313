import { Injectable } from '@angular/core';
import { NotificationsService, UserService, UsersService } from '@haulynx/services';
import { User, UserValidationResult } from '@haulynx/types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '../../main-store/app.reducers';
import { DispatchAction } from '../../shared/store/helpers/action';
import { UsersModel } from './users-model';
import { UsersActions, UsersActionTypes } from './users.actions';
import { userSearchSelector } from './users.selectors';

@Injectable()
export class UsersEffects {
  userSearch = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActionTypes.SEARCH),
      map((action: DispatchAction) => action.payload),
      withLatestFrom(this.store.select(userSearchSelector.getQuery)),
      switchMap(([filter, query]) =>
        this.userService.getUserList(filter && filter.query ? filter.query : query).pipe(
          map((entities) => UsersActions.searchSuccess({ entities })),
          catchError((err) => {
            this.notificationsService.error(err, 'User search');
            return of(UsersActions.searchError(err));
          })
        )
      )
    )
  );

  creatUser = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActionTypes.CREATE_USER),
      map((action: DispatchAction) => action.payload),
      switchMap((filter) =>
        this.userService.createUser(filter).pipe(
          map((entities) => {
            this.notificationsService.success('User successfully created', 'Create user');
            return UsersActions.createUserSuccess({ entities });
          }),
          catchError((err) => {
            this.notificationsService.error(err, 'Create user');

            return of(UsersActions.createUserError(err));
          })
        )
      )
    )
  );

  validateDriversLicense = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActionTypes.VALIDATE_DRIVERS_LICENSE),
      map((action: DispatchAction) => action.payload),
      switchMap((filter) => {
        const { country, state, licenseNumber, user } = filter;
        return this.userService.isValidDriversLicense(country, state, licenseNumber).pipe(
          map((response) => {
            const { data } = response;
            const { isValidDriversLicense } = data;
            const { valid, message } = isValidDriversLicense;
            if (valid) {
              this.userModel.createUser(user);
              return UsersActions.validateDriversLicenseSuccess(message);
            } else {
              this.notificationsService.error(message, 'Invalid Drivers License!');
              return UsersActions.validateDriversLicenseError(message);
            }
          }),
          catchError((err) => {
            this.notificationsService.error(err, 'Drivers License validation failed!');

            return of(UsersActions.validateDriversLicenseError(err));
          })
        );
      })
    )
  );

  getUser = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActionTypes.GET_USER),
      map((action: DispatchAction) => action.payload),
      switchMap((id: string) =>
        this.usersService.getUserById({ id }).pipe(
          map((user: User) => UsersActions.getUserSuccess({ user })),
          catchError((err) => {
            this.notificationsService.error(err, 'Get user');

            return of(UsersActions.getUserError(err));
          })
        )
      )
    )
  );

  updateUserInfo = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActionTypes.UPDATE_USER),
      map((action: DispatchAction) => action.payload),
      switchMap((payload: { userId: string; userInfo: Partial<User> }) =>
        this.userService.updateUserInfo(payload).pipe(
          map((result: UserValidationResult) => {
            if (!('acceptedTos' in payload.userInfo)) {
              this.notificationsService.success('User updated', 'Users');
            }

            return UsersActions.updateUserSuccess(result);
          }),
          catchError((err) => {
            this.notificationsService.error(err, 'Update user');

            return of(UsersActions.updateUserError(err));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private notificationsService: NotificationsService,
    private store: Store<AppState>,
    private userModel: UsersModel,
    private usersService: UsersService
  ) {}
}
