import { Validators } from '@angular/forms';
import moment from 'moment';
import { ITab } from '../tab';
import { RadiusSearchOption } from './radius-search-option';

export const dateButtons = [
  {
    label: 'Today',
    value: 0,
  },
  {
    label: 'Tomorrow',
    value: 1,
  },
  {
    label: 'Next Day',
    value: 2,
  },
];

export const filterDateButtons = ['Today', 'Tomorrow', 'Next Day'];

const baseForm = {
  pickUpName: [null, Validators.required],
  origLat: [null, Validators.required],
  origLon: [null, Validators.required],

  deliveryName: ['Any'],
  destLat: [null],
  destLon: [null],

  allowStops: [true],
  pickupDate: [moment.utc().toDate()],
  equipmentType: ['Any'],
};

export const carrierForm = {
  ...baseForm,
  loadDistance: [null],
};

export const brokerForm = {
  ...baseForm,
  office: [null],
  equipmentType: [null],
  shipper: [null],
  orderId: [null],
};

export const LOAD_FEED_RECOMMENDED_URL = 'loadFeed/recommendedLoads/carriers';
export const LOAD_FEED_BROKER_OFFER_URL = 'loadFeed/brokerOffers/carriers';

export function getLoadsTabs(label = 'Load Feed'): { SEARCH: ITab } {
  return {
    SEARCH: {
      id: 'search',
      label,
      url: 'search',
      order: 1,
    },
  };
}

export const counterTemplate = '$!d!d $!h!h $!m!m $!s!s';
export const counterClock = ['d', 100, 2, 'h', 24, 2, 'm', 60, 2, 's', 60, 2, 'u', 10, 1];

export const rejectReasons2 = [
  { label: 'Broker', value: 'Broker' },
  { label: 'Price', value: 'Price' },
  { label: 'Deadhead mileage', value: 'Deadhead mileage' },
  { label: 'Destination', value: 'Destination' },
  { label: 'Other', value: '' },
];

// export const carrierId = 'test_carrier_1';
export const carrierId = '-Lk_EM0kg7d8NGpgJTrg';

export const radiusSearchOptions: RadiusSearchOption[] = [
  { label: '10 mi', value: 16093 }, //changed to 10 miles (1609.34 meters)
  { label: '25 mi', value: 40234 },
  { label: '50 mi', value: 80467 },
  { label: '75 mi', value: 120701 },
  { label: '100 mi', value: 160934 },
  { label: '150 mi', value: 241402 },
  { label: '200 mi', value: 321868 },
  { label: '500 mi', value: 804670 },
];

export const radiusSearchDefault = 321868; //default to 200 miles on the Loads page

/**
 * radiusSearchOptionToMiles takes a radius in meters and returns miles.
 * @param value number
 * @returns number
 */
export const radiusSearchOptionToMiles = (value: number): number => {
  switch (value) {
    case 16093:
      return 10;
    case 40234:
      return 25;
    case 80467:
      return 50;
    case 120701:
      return 75;
    case 160934:
      return 100;
    case 241402:
      return 150;
    case 321868:
      return 200;
    case 804670:
      return 500;
    default:
      return 200;
  }
};

/**
 * radiusMilesToSearchOption takes a radius in miles and returns meters.
 * @param value number
 * @returns number
 */
export const radiusMilesToSearchOption = (value: number): number => {
  switch (value) {
    case 10:
      return 16093;
    case 25:
      return 40234;
    case 50:
      return 80467;
    case 75:
      return 120701;
    case 100:
      return 160934;
    case 150:
      return 241402;
    case 200:
      return 321868;
    case 500:
      return 804670;
    default:
      return 321868;
  }
};
