import { Injectable } from '@angular/core';
import {
  AddressField,
  AssignDriverForm,
  DispatchForm,
  LoadsServiceLoad,
  LoadsServiceLoadLocation,
  LocationForm,
} from '@haulynx/types';
import { get } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class LoadFormService {
  getAssignDriverForm(load: LoadsServiceLoad): AssignDriverForm {
    const trackingType = get(load, 'trackingType', null);
    const driver = get(load, 'drivers[0].id', null);
    const truck = get(load, 'truck.id', null);
    const trailerOwner = get(load, 'trailersOwner', null);
    const trailer = get(load, 'trailers[0].id', null);
    const phone = get(load, 'drivers[0].phone', null);
    const equipment = get(load, 'providerDetails.equipmentType', null);
    return {
      trackingType,
      driver,
      trailerOwner,
      trailer,
      truck,
      phone,
      equipment,
    };
  }

  getDispatchForm(load: LoadsServiceLoad): DispatchForm {
    const location: AddressField = {
      address: get(load, 'dispatchLocation.address', null),
      lat: get(load, 'dispatchLocation.lat', null),
      lon: get(load, 'dispatchLocation.lon', null),
      timeZone: get(load, 'dispatchLocation.timezone', null),
    };
    const notes = get(load, 'dispatchLocation.notes', null);
    const timeAvailable = get(load, 'dispatchLocation.timestamp', null);
    return {
      location,
      notes,
      timeAvailable,
    };
  }

  getLocationForm(location: LoadsServiceLoadLocation): LocationForm {
    return {
      arrivalTime: Number(location.carrierArrival),
      departureTime: location.carrierDeparture,
      timeZone: location.timezone,
      locationType: location.locationType,
      specialNotes: location?.notes.text,
    };
  }
}
