const arr = (x: string) => Array.from(x);
const num = (x: string) => Number(x) || 0;
const isEmpty = (xs: string[]) => xs.length === 0;
const take = (n: number) => (xs: string[]) => xs.slice(0, n);
const drop = (n: number) => (xs: string[]) => xs.slice(n);
const reverse = (xs: string[]) => xs.slice(0).reverse();
const comp = (f) => (g) => (x: string[]) => f(g(x));
const not = (x: number) => !x;
const chunk = (n: number) => (xs: string[]) => isEmpty(xs) ? [] : [take(n)(xs), ...chunk(n)(drop(n)(xs))];

const a = [
  '',
  'one',
  'two',
  'three',
  'four',
  'five',
  'six',
  'seven',
  'eight',
  'nine',
  'ten',
  'eleven',
  'twelve',
  'thirteen',
  'fourteen',
  'fifteen',
  'sixteen',
  'seventeen',
  'eighteen',
  'nineteen',
];
const b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
const g = [
  '',
  'thousand',
  'million',
  'billion',
  'trillion',
  'quadrillion',
  'quintillion',
  'sextillion',
  'septillion',
  'octillion',
  'nonillion',
];

export function numberToWords(n: number) {
  let makeGroup = ([ones, tens, huns]) => {
    return [
      num(huns) === 0 ? '' : a[huns] + ' hundred ',
      num(ones) === 0 ? b[tens] : (b[tens] && b[tens] + '-') || '',
      a[tens + ones] || a[ones],
    ].join('');
  };
  let thousand = (group: string, i: number) => (group === '' ? group : `${group} ${g[i]}`);

  let stringNumber = n.toString();
  if (stringNumber === '0') return 'zero';
  return comp(chunk(3))(reverse)(arr(stringNumber))
    .map(makeGroup)
    .map(thousand)
    .filter(comp(not)(isEmpty))
    .reverse()
    .join(' ');
}
