import gql from 'graphql-tag';

export const GetLoadById = gql(`
  query GetLoadById($id: ID!) {
    getLoadById(id: $id) {
      id
      price
      company
      dateCreated
      dateCompleted
      customerName
      loadLocations {
        appointmentSet
        completed
        creator {
          id
        }
        name
        lat
        lon
        timestamp
        timezone
        poNumber
        billOfLading
        equipmentType
        commodity
        quantity
        loadWeight
        packingType
        temperature
        arrivalTime
        departureTime
        waitTime
        estimatedWaitTime
        customerId
        locationType
        specialNotes
        summaryAddress
        customerNumber
        customerAttributes{
          canPickUpEarly
          canDeliverEarly
          canBreakAtFacility
          hasFacilities
        }
      }
      carrierAccepted
      carrierBid {
        id
        price
        status
        carrierId
        sourceType
        notes
        activeCounterOffer {
          id
          price
          carrierId
          notes
        }
      }
      lastLocation {
        gpsLat
        gpsLon
        approximateAddress
        date
        driver
        driverName
        driverStatus
        vehicle {
          id
        }
        speed
        unitId
        automaticEntry
        city
        state
        cityAndState
        appVersion
        type
      }
      loadRejectedByAllCarriers
      brokerAcceptedCarrier
      creator
      templateId
      trailerId
      trailer {
        id
        trailerNumber
      }
      status
      isPaid
      amountOwed
      datePaid
      loadFeedKey
      customerName
      revenue
      weight
      markerColor
      exclusiveUntil
      exclusiveLoad
      exclusivePrice
      distance
      ratePerMile
      exclusiveRatePerMile
      mongoId
      tmwNumber
      shipper {
        id
        name
        phone
      }
      broker {
        id
        name
        phone
        address
      }
      carrier_filter
      broker_filter
      shipper_filter
      timeOffered
      recommendedCarriers {
        id
        name
        matchPercentage
        awarenessPercentage
        isAssigned
        isEldCarrier
      }
      distributionMechanism
      loadAssignStatus
      loadProgress
      unitId
      carrierSafetyRating
      cargoLiability
      autoLiability
      config
      networkPreferred
      pickupCity
      hazmatCapabilityRequired
      minRequiredInsurance
      agreedToTerms
      reasonsForRejection {
        reason
        carrier {
          id
        }
        date
      }
      carrier {
        id
        name
        phone
        dot
        sourceType
        isEldCarrier
      }
      truck {
        imei
        id
        type
        lastUpdated
        unitId
        lastLocation {
          driverName
          gpsLat
          gpsLon
          approximateAddress
          date
        }
      }
      # trackingType
      driver {
        id
        phone
        name
      }
      dispatchLocation {
        address
        lat
        lon
        timezone
        timestamp
        notes
      }
      consignee
      bookStatus
      internalNotes
      externalNotes
      regionalManager
      regionalManagerPhone
      regionalManagerEmail
      customer
    }
  }
`);
