import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@haulynx/modules';
import { ChipListComponent } from './chip-list.component';

@NgModule({
  imports: [CommonModule, MaterialModule],
  exports: [ChipListComponent],
  declarations: [ChipListComponent],
})
export class ChipListModule {}
