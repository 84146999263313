import gql from 'graphql-tag';

export const GetUserFavoriteAddress = gql(`
  query {
      getUserFavoriteAddress {
        id
        lat
        lon
        address
        timeZone
        notes
        name
        customer
      }
    }
`);
