import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@haulynx/modules';
import { InterchangeAgreementInfoComponent } from './interchange-agreement-info.component';

@NgModule({
  declarations: [InterchangeAgreementInfoComponent],
  imports: [CommonModule, FlexLayoutModule, MaterialModule],
  exports: [InterchangeAgreementInfoComponent],
})
export class InterchangeAgreementInfoModule {}
