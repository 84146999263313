import gql from 'graphql-tag';

export const GetCapacityManagementLanesByCarrierDot = gql`
  query GetCapacityManagementLanesByCarrierDot($carrierDot: StrictId!) {
    getCapacityManagementLanesByCarrierDot(carrierDot: $carrierDot) {
      id
      type
      brokerId
      truckPostedCreatedAt
      origin {
        radius
        availability {
          dayOfWeek
        }
        locationName
        point {
          lat
          lon
        }
      }
      destination {
        radius
        availability {
          dayOfWeek
        }
        locationName
        point {
          lat
          lon
        }
      }
      equipmentType
      cost {
        min
        max
      }
      recommendations {
        total
        result {
          loadsServiceLoad {
            id
            created
            locations {
              id
              address
              city
              state
              zipcode
              timezone
              appointmentSet
              appointmentStart
              appointmentEnd
              locationType
              billOfLading
              geometry {
                type
                coordinates
              }
            }
            providerDetails {
              equipmentType
              weight: weightLbs
              alternateIds {
                identifierType
                identifierValue
              }
            }
            paymentDetails {
              price
              revenue
              distanceMiles
            }
          }
        }
      }
    }
  }
`;
