export class LoadsServiceOffer {
  id: string;
  loadId: string;
  carrierDot: string;
  offeredBy: string;
  price: number;
  ratePerMile: number;
  createdAt: number;
  lastUpdatedAt: number;
}

export class LoadsServiceOfferInput {
  loadId: string;
  carrierDot: string;
  offeredBy: string;
  price: number;
}
