import { TemplateRef } from '@angular/core';
import { IColumns2 } from '../data-table-v2/data-table-v2-type';
import { ColumnField } from '../search-view/default-columns';

export const dataColumns = (
  stickyColumnWidth: string,
  stickyColHeader: TemplateRef<unknown>,
  columnSettings: Array<{ key: string; value: boolean; order?: number }> = [],
  columnsByFeatureFlag: { enabled: boolean; columnField: ColumnField; value: boolean }[] = [],
  CRM?: boolean,
  isCustom?: boolean
): IColumns2[] => {
  const allColumns: IColumns2[] = [
    {
      field: ColumnField.PRIORITY,
      label: '',
      sortConfig: [],
      isSortable: false,
      isCustomCell: false,
      width: '4px',
      isSticky: false,
      isVisible: true,
      isFrozen: true,
      order: -100,
    },
    {
      field: ColumnField.ORIGIN_AND_DESTINATION,
      label: 'Origin and Destination',
      isCustomCell: true,
      isSortable: true,
      width: '435px',
      sortConfig: CRM
        ? [
            {
              label: 'Origin Time',
              dataIndex: 'firstAppointmentStart-firstAppointmentEnd',
              emitSort: true,
            },
            {
              label: 'Destination Time',
              dataIndex: 'lastAppointmentStart-lastAppointmentEnd',
              emitSort: true,
            },
          ]
        : [
            {
              label: 'Origin Time',
              dataIndex:
                'firstAppointmentStart~firstAppointmentEnd?locations.where-locationType=pickup.appointmentStart',
              secondaryIndex: 'missionId~missionId?mission.id',
              emitSort: true,
            },
            {
              label: 'Destination Time',
              dataIndex:
                'lastAppointmentStart~lastAppointmentEnd?locations.where-locationType=dropoff.appointmentStart',
              secondaryIndex: 'missionId~missionId?mission.id',
              emitSort: true,
            },
            {
              label: 'Origin Name',
              dataIndex: 'originLocation~originLocation?locations.where-locationType=pickup.city',
              emitSort: true,
            },
            {
              label: 'Destination Name',
              dataIndex: 'destinationLocation~destinationLocation?locations.where-locationType=dropoff.city',
              emitSort: true,
            },
          ],
      isVisible: true,
      isPinned: true,
      order: -98,
    },
    ...(CRM
      ? []
      : [
          {
            field: ColumnField.STATUS,
            label: 'Status',
            filters: ['Load Status'],
            sortConfig: [
              {
                label: 'Load Status',
                dataIndex: 'loadStatus~loadStatus?loadStatus',
                emitSort: true,
              },
            ],
            isCustomCell: true,
            isSortable: true,
            width: '190px',
            isVisible: true,
            order: 1,
          },
        ]),
    ...(CRM
      ? []
      : [
          {
            field: ColumnField.MISSION,
            label: 'Mission',
            filters: ['Mission Number'],
            sortConfig: [
              {
                label: 'Mission',
                dataIndex: 'missionId~missionId?mission.id',
                secondaryIndex:
                  'firstAppointmentStart~firstAppointmentEnd?locations.where-locationType=pickup.appointmentStart',
                emitSort: true,
              },
            ],
            isCustomCell: true,
            isSortable: true,
            width: '160px',
            isVisible: true,
            order: 1,
          },
        ]),
    ...(CRM
      ? []
      : [
          {
            field: ColumnField.TRACKING_STATUS,
            label: 'Tracking Status',
            isCustomCell: true,
            isSortable: false,
            width: '160px',
            isVisible: true,
            sortConfig: [],
            order: 2,
          },
        ]),
    {
      field: ColumnField.LAST_TRUCK_LOCATION,
      label: 'Last Truck Location',
      isCustomCell: true,
      isSortable: false,
      width: '190px',
      isVisible: !CRM,
      sortConfig: [],
      order: 3,
    },
    {
      field: ColumnField.LAST_MILESTONE_COMMENT,
      label: 'Last Milestone Comment',
      isCustomCell: true,
      isSortable: true,
      width: '350px',
      isVisible: !CRM,
      sortConfig: [
        {
          label: 'Latest Comment',
          dataIndex: 'latestMilestoneComment.timestamp',
          emitSort: false,
        },
      ],
      order: 4,
    },
    {
      field: ColumnField.EQUIPMENT,
      label: 'Equipment',
      sortConfig: [
        {
          label: 'Equipment',
          dataIndex: 'providerDetails.equipmentType',
        },
        ...(CRM
          ? [
              {
                label: 'Weight',
                dataIndex: 'weightLbs-weightLbs',
                emitSort: true,
              },
            ]
          : [
              {
                label: 'Weight',
                dataIndex: 'weightLbs~weightLbs?providerDetails.weight',
                emitSort: true,
              },
            ]),
      ],
      filters: ['Equipment'],
      isCustomCell: true,
      isSortable: true,
      width: '170px',
      isVisible: true,
      order: 5,
    },
    {
      field: ColumnField.BILL_TO,
      label: 'Bill To',
      sortConfig: [
        {
          label: 'Customer',
          dataIndex: 'billTo~billTo?providerDetails.billTo',
          emitSort: true,
        },
      ],
      filters: ['Bill To'],
      isCustomCell: true,
      isSortable: true,
      width: '175px',
      isVisible: true,
      order: 6,
    },
    {
      field: ColumnField.TMW_AND_ORDER,
      label: 'TMW / Order',
      sortConfig: [
        {
          label: 'TMW Number',
          dataIndex: 'providerDetails.alternateIds.where-identifierType=tmwNumber.identifierValue',
        },
        {
          label: 'Order Number',
          dataIndex: 'providerDetails.alternateIds.where-identifierType=orderNumber.identifierValue',
        },
      ],
      filters: ['TMW', 'Order'],
      isCustomCell: true,
      isSortable: true,
      width: '150px',
      isVisible: true,
      order: 6,
    },
    ...(CRM
      ? [
          {
            field: ColumnField.PRICE_MAX_BUY_REVENUE,
            label: 'Price / Rev',
            sortConfig: [
              {
                label: 'Price',
                dataIndex: 'price-price',
                emitSort: true,
              },
              {
                label: 'Revenue',
                dataIndex: 'revenue-revenue',
                emitSort: true,
              },
            ],
            filters: ['Price', 'Revenue'],
            isCustomCell: true,
            isSortable: true,
            width: '150px',
            isVisible: true,
            order: 7,
          },
        ]
      : [
          {
            field: ColumnField.PRICE,
            label: 'Price',
            sortConfig: [
              {
                label: 'Price',
                dataIndex: 'price~price?paymentDetails.price',
                emitSort: true,
              },
            ],
            filters: ['Price'],
            isCustomCell: true,
            isSortable: true,
            width: '150px',
            isVisible: true,
            order: 8,
          },
          {
            field: ColumnField.MAX_BUY,
            label: 'Max Buy',
            sortConfig: [
              {
                label: 'Max Buy',
                dataIndex: 'maxBuy~maxBuy?paymentDetails.maxBuy',
                emitSort: true,
              },
            ],
            filters: ['Max Buy'],
            isCustomCell: true,
            isSortable: true,
            width: '150px',
            isVisible: true,
            order: 9,
          },
          {
            field: ColumnField.REVENUE,
            label: 'Revenue',
            sortConfig: [
              {
                label: 'Revenue',
                dataIndex: 'revenue~revenue?paymentDetails.revenue',
                emitSort: true,
              },
            ],
            filters: ['Revenue'],
            isCustomCell: true,
            isSortable: true,
            width: '150px',
            isVisible: true,
            order: 10,
          },
        ]),
    ...(CRM
      ? []
      : [
          {
            field: ColumnField.TEAM_DRIVING_REQUIRED,
            label: 'Team Required',
            sortConfig: [
              {
                label: 'Team Required',
                dataIndex: 'restrictions.TEAM_DRIVING_REQUIRED',
              },
            ],
            filters: ['Team Required'],
            isCustomCell: true,
            isSortable: true,
            width: '150px',
            order: 11,
          },
        ]),
    {
      field: ColumnField.BIDS,
      label: 'Bids',
      sortConfig: [
        {
          label: 'Bids',
          dataIndex: 'bidDetails.lowestOffer',
        },
      ],
      filters: ['Highest Bid'],
      isCustomCell: true,
      isSortable: false,
      width: '180px',
      isVisible: true,
      order: 12,
    },
    {
      field: ColumnField.TRAILER_INSTRUCTIONS,
      label: 'Trailer Instr',
      sortConfig: [],
      isCustomCell: true,
      isSortable: false,
      width: '130px',
      isVisible: CRM ? true : false,
      order: 13,
      filters: ['Dropoff Requirements', 'Pickup Requirements'],
    },
    ...(CRM
      ? []
      : [
          {
            field: ColumnField.TRUCK,
            label: 'Truck / Trailer',
            sortConfig: [],
            filters: ['Truck'],
            isCustomCell: true,
            isSortable: false,
            width: '200px',
            isVisible: true,
            order: 14,
          },
        ]),
    ...(CRM
      ? []
      : [
          {
            field: ColumnField.DRIVER,
            label: 'Driver',
            sortConfig: [],
            filters: ['Driver'],
            isCustomCell: true,
            isSortable: false,
            width: '200px',
            isVisible: true,
            order: 15,
          },
        ]),
    ...(CRM
      ? []
      : [
          {
            field: ColumnField.CARRIER,
            label: 'Carrier / DOT',
            sortConfig: CRM
              ? []
              : [
                  {
                    label: 'Carrier Name',
                    dataIndex: 'carrierName~carrierName?carrier.name',
                    emitSort: true,
                  },
                ],
            filters: ['Carrier'],
            isCustomCell: true,
            isSortable: CRM ? false : true,
            width: '200px',
            isVisible: true,
            order: 16,
          },
        ]),
    {
      field: ColumnField.ASSIGNED_BROKERS,
      label: 'Broker Assignees',
      filters: ['Broker Assignees'],
      sortConfig: [],
      isCustomCell: true,
      isSortable: false,
      width: '150px',
      order: 17,
    },
    {
      field: ColumnField.BOOKED_BY,
      label: 'Booked By',
      sortConfig: [],
      isCustomCell: true,
      isSortable: false,
      width: '150px',
      order: 18,
    },
    {
      field: ColumnField.FREIGHT_MIX,
      label: 'Freight Mix',
      filters: ['Freight Mix'],
      sortConfig: [],
      isCustomCell: true,
      isSortable: false,
      width: '150px',
      order: 19,
    },
    {
      field: ColumnField.LOADED_MILES,
      label: 'Loaded Miles',
      sortConfig: [
        {
          label: 'Loaded Miles',
          dataIndex: CRM ? 'distanceMiles-distanceMiles' : 'distanceMiles~distanceMiles?paymentDetails.distanceMiles',
          emitSort: true,
        },
      ],
      isCustomCell: true,
      isSortable: true,
      width: '130px',
      isVisible: CRM ? true : false,
      order: 20,
    },
    {
      field: ColumnField.INBOUND_REGION,
      label: 'Region',
      filters: ['Inbound Region'],
      sortConfig: [],
      isCustomCell: true,
      isSortable: false,
      width: '130px',
      order: 21,
    },
    {
      field: ColumnField.BROKER_TEAM,
      label: 'Broker Team',
      filters: ['Broker Team'],
      sortConfig: [],
      isCustomCell: true,
      isSortable: false,
      width: '130px',
      order: 22,
    },
    {
      field: ColumnField.SHIPPER,
      label: 'Shipper',
      filters: ['Shipper'],
      sortConfig: [],
      isCustomCell: true,
      isSortable: false,
      width: '200px',
      order: 23,
    },
    {
      field: ColumnField.RECEIVER,
      label: 'Receiver',
      filters: ['Receiver'],
      sortConfig: [],
      isCustomCell: true,
      isSortable: false,
      width: '200px',
      order: 24,
    },
    {
      field: ColumnField.CX_PRIORITY,
      label: 'CX Priority',
      filters: ['CX Priority'],
      sortConfig: [],
      isCustomCell: true,
      isSortable: false,
      width: '130px',
      order: 25,
    },
    {
      field: ColumnField.TRACKING_TYPES,
      label: 'Tracking Types',
      sortConfig: [],
      isCustomCell: true,
      isSortable: false,
      width: '200px',
      order: 26,
    },
    ...(CRM
      ? []
      : [
          {
            field: ColumnField.HIGH_VALUE,
            label: 'High Value',
            sortConfig: [],
            isCustomCell: true,
            isSortable: false,
            width: '130px',
            order: 27,
          },
        ]),
    {
      field: ColumnField.CXR_ID,
      label: 'CXR Id',
      filters: ['CXR ID'],
      sortConfig: [
        {
          label: 'CXR Id',
          dataIndex: CRM ? 'cxrEmployeeId-cxrEmployeeId' : 'cxrEmployeeId~cxrEmployeeId?providerDetails.cxrEmployeeId',
          emitSort: true,
        },
      ],
      isCustomCell: true,
      isSortable: true,
      width: '130px',
      isVisible: true,
      order: 28,
    },
    {
      field: ColumnField.APPOINTMENT_RESETS,
      label: 'Appt Resets',
      sortConfig: [
        {
          label: 'Appointment Resets',
          dataIndex: 'numberOfAppointmentResets~numberOfAppointmentResets?numberOfAppointmentResets',
          emitSort: true,
        },
      ],
      isCustomCell: true,
      isSortable: true,
      width: '125px',
      isVisible: CRM ? false : true,
      order: 29,
    },
    {
      field: ColumnField.LAST_TRAILER_LOCATION,
      label: 'Last Trailer Location',
      isCustomCell: true,
      isSortable: false,
      width: '190px',
      isVisible: !CRM,
      sortConfig: [],
      order: 30,
    },
    {
      field: ColumnField.ACTIONS,
      label: '',
      sortConfig: [],
      align: 'right',
      isCustomCell: true,
      isSticky: true,
      width: stickyColumnWidth,
      isSortable: false,
      isClickable: false,
      headerTemplate: stickyColHeader,
      isVisible: true,
      isFrozen: true,
      order: 100,
    },
  ];

  return allColumns.reduce((acc: IColumns2[], column: IColumns2): IColumns2[] => {
    const setting = columnSettings.find(
      (value: { key: string; value: boolean; order?: number }) => value.key === column.field
    );

    const columnByFeatureFlag = columnsByFeatureFlag.find((value) => value.columnField === column.field);
    if (columnByFeatureFlag && !setting) {
      if (!columnByFeatureFlag.enabled || !columnByFeatureFlag.value) {
        return [...acc, { ...column, isVisible: false, order: column.order }];
      }
    }

    if ((!setting && !isCustom) || column.field === ColumnField.ACTIONS || column.field === ColumnField.PRIORITY) {
      return [...acc, column];
    }

    //If a custom view, ignore columns that were not found
    if (!setting && isCustom) {
      return [...acc, { ...column, isVisible: false, order: column.order }];
    }
    const foundColumn = { ...column, isVisible: setting.value, order: setting.order ?? column.order };
    return [...acc, foundColumn];
  }, []);
};
