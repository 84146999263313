import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountType } from '@haulynx/types';
import { matchingPasswords } from '@haulynx/utils';
import { forEach, keys } from 'lodash';

export class DriverForm {
  licenseNumber = 'xxx';
  issuingState: string = null;
  issuingCountry: string = null;
  pcEnabled = false;
  ymEnabled = false;
  teamDrivingEnabled = false;
  companyId: string = null;

  constructor(controlConfig) {
    const key = keys(this);
    const defaultValue = [''];

    forEach(key, (prop) => {
      const control = (controlConfig && controlConfig[prop]) || defaultValue;

      this[prop] = control;
    });
  }
}

export class BrokerForm {
  team: string = null;
  jobTitle: string = null;
  manager: string = null;
  office: string = null;
  brokerId: string = null;

  constructor(controlConfig) {
    const key = keys(this);
    const defaultValue = [''];

    forEach(key, (prop) => {
      const control = (controlConfig && controlConfig[prop]) || defaultValue;

      this[prop] = control;
    });
  }
}

export class UserForm {
  id: string = null;
  name: string = null;
  email: string = null;
  accountType: AccountType | string = null;
  password: string = null;
  passwordConfirm: string = null;
  trailerCreationEnabled = false;
  company: string = null;
  phone: string = null;
  admin = false;

  driver: DriverForm;
  broker: BrokerForm;

  constructor(controlConfig) {
    const key = keys(this);
    const defaultValue = [''];

    forEach(key, (prop) => {
      const control = (controlConfig && controlConfig[prop]) || defaultValue;

      this[prop] = control;
    });
  }
}

@Injectable()
export class UserVmService {
  options;
  optionsForDriver;
  optionsForBroker;
  accountType = AccountType;

  constructor(private fb: FormBuilder) {
    this.options = {
      id: [null],
      name: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      accountType: [null, [Validators.required]],
      password: [null, Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(20)])],
      passwordConfirm: [null, Validators.required],
      trailerCreationEnabled: false,
      phone: [null, [Validators.required, Validators.pattern(/^\(?[\d]{3}\)?[\s-]?[\d]{3}[\s-]?[\d]{4}$/)]],
    };

    this.optionsForDriver = {
      licenseNumber: '',
      issuingState: [null],
      issuingCountry: [null],
      accountType: [this.accountType.DRIVER],
    };

    this.optionsForBroker = {
      team: [null],
      jobTitle: [null],
      manager: [null],
      office: [null],
    };
  }

  create(values = {}): FormGroup {
    const userForm = new UserForm(this.options);
    const driverForm = new DriverForm(this.optionsForDriver);

    const driverFormGroup = this.fb.group(driverForm);
    const brokerForm = new BrokerForm(this.optionsForBroker);
    const brokerFormGroup = this.fb.group(brokerForm);

    const formGroup = this.fb.group(
      {
        ...userForm,
        driver: driverFormGroup,
        broker: brokerFormGroup,
        isDriver: true,
      },
      {
        validators: matchingPasswords('password', 'passwordConfirm'),
      }
    );

    if (values) {
      formGroup.patchValue(values);
    }

    return formGroup;
  }
}
