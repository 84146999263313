import { EnvironmentName } from '../environment';
import { FFState } from '../feature-flag/feature-flag.state';
import { User } from './user';

export interface AppSettingsInitializer {
  token: string;
  user: User;
  firebaseUser: User;
  loadFeedPermission: boolean;
  features: { flags: FFState };
  userAcceptedTos: User;
  environmentName: EnvironmentName;
}
