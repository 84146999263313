import { Injectable } from '@angular/core';
import {
  DeviceLocation,
  FlatMarker,
  Load,
  LoadLocation,
  LoadLocationType,
  LoadRoute,
  LoadRouteSource,
  LoadsServiceLoad,
  LoadsServiceLoadLocation,
  LoadWayPoint,
  UnauthenticatedLoadsServiceLoad,
} from '@haulynx/types';
import along from '@turf/along';
import { lineString, point, Position } from '@turf/helpers';
import lineSlice from '@turf/line-slice';
import { get, head, last } from 'lodash';
import { LngLatBounds, LngLatLike } from 'mapbox-gl';

@Injectable({
  providedIn: 'root',
})
export class MapRoutesService {
  locationType = LoadLocationType;

  initRoutes(sources: LoadRouteSource[]): LoadRoute[] {
    if (!Array.isArray(sources)) {
      sources = [sources];
    }
    if (!sources) {
      return [];
    } else {
      return sources.map((source) => {
        return {
          id: source.id,
          source: {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: [
                {
                  type: 'Feature',
                  geometry: {
                    type: 'LineString',
                    properties: {},
                    coordinates: source.coordinates,
                  },
                },
              ],
            },
          },
          truckRoute: {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: [
                {
                  type: 'Feature',
                  geometry: {
                    type: 'LineString',
                    properties: {},
                    coordinates: this.initTruckRoute(source),
                  },
                },
              ],
            },
          },
          wayPoints: source.wayPoints,
          truckPosition: source.truckPosition,
          lineColor: source.lineColor,
          lineWidth: source.lineWidth,
          truckLineWidth: source.lineWidth ? ++source.lineWidth : 1,
          markerColor: source.markerColor,
          markerStyle: source.markerStyle,
          angle: this.initTruck(source),
        };
      });
    }
  }

  zoomToBounds(sources: LoadRouteSource[], markers: FlatMarker[]): LngLatBounds {
    if (!Array.isArray(sources)) {
      sources = [sources];
    }
    const routesCoordinates = sources.map((route) => route.coordinates);
    const markerCoordinates = markers.map((marker) => [marker.coordinates]);

    const coordinates = [...routesCoordinates, ...markerCoordinates];

    if (coordinates.length) {
      const zoomCoordinates = this.flatten(coordinates);

      return zoomCoordinates.reduce(
        (bounds: LngLatBounds, gps: LngLatLike) => bounds.extend(gps),
        new LngLatBounds(zoomCoordinates[0], zoomCoordinates[0])
      );
    }
  }

  pointByDistance(coordinates: Position[], length = 1500): Position {
    const route = lineString(coordinates);
    const pointAlongRoute = along(route, length, { units: 'meters' });

    return pointAlongRoute.geometry.coordinates;
  }

  sliceRoute(start: Position, finish: Position, coordinates: Position[]): Position[] {
    const route = lineString(coordinates);
    const slicedRoute = lineSlice(point(start), point(finish), route);

    return slicedRoute.geometry.coordinates;
  }

  normalizeWayPoints(wayPoints: Position[] = []): string {
    if (!wayPoints) return '';

    const result = wayPoints.map(([lat, lon]: Position) => `${lat},${lon}`);
    return result.join(';');
  }

  isGpsLocationValid(gps: Position): boolean {
    return gps ? gps.filter(Boolean).length > 1 : false;
  }

  getRoutePoints(load: LoadsServiceLoad): Position[] {
    const { locations = [] } = load;

    return locations
      .filter((location: LoadsServiceLoadLocation) => location.geometry?.coordinates?.length)
      .map((location: LoadsServiceLoadLocation) => {
        const lon = location.geometry?.coordinates[0];
        const lat = location.geometry?.coordinates[1];
        if (lon && lat) {
          return [lon, lat];
        }
      });
  }

  getNotCompletedWayPoint(wayPoints: LoadWayPoint[]): number {
    return wayPoints.findIndex((wayPoint: LoadWayPoint) => !wayPoint.completed);
  }

  getNotCompletedLoadLocation(loadLocations: LoadsServiceLoadLocation[]): number {
    return loadLocations.findIndex((location: LoadsServiceLoadLocation) => !location.carrierDeparture);
  }

  addTruckByLocationStatus(load: LoadsServiceLoad, position?: Position): Position[] {
    const { locations } = load;
    const wayPoints = this.getRoutePoints(load);
    const notCompletedLocation = this.getNotCompletedLoadLocation(locations);

    switch (notCompletedLocation) {
      case 0:
        if (!position) return [...wayPoints];
        return [position, ...wayPoints];
      case -1:
        if (!position) return [...wayPoints];
        return [...wayPoints, position];
      default: {
        const start = wayPoints.slice(0, notCompletedLocation);
        const finish = wayPoints.slice(notCompletedLocation);
        if (!position) return [...start, ...finish];
        return [...start, position, ...finish];
      }
    }
  }

  addTruckBySecureLocationStatus(load: UnauthenticatedLoadsServiceLoad, position?: Position): Position[] {
    const { locations = [] } = load;
    const wayPoints = this.getSecureRoutePoints(load);
    const notCompletedLocation = this.getNotCompletedLoadsServiceLoadLocation(locations);

    switch (notCompletedLocation) {
      case 0:
        if (!position) return [...wayPoints];
        return [position, ...wayPoints];
      case -1:
        if (!position) return [...wayPoints];
        return [...wayPoints, position];
      default:
        if (!position) return [...wayPoints.slice(0, notCompletedLocation), ...wayPoints.slice(notCompletedLocation)];
        return [...wayPoints.slice(0, notCompletedLocation), position, ...wayPoints.slice(notCompletedLocation)];
    }
  }

  getSecureRoutePoints(load: UnauthenticatedLoadsServiceLoad): Position[] {
    const { locations = [] } = load;

    return locations
      .filter((location: LoadsServiceLoadLocation) => location.geometry?.coordinates?.length)
      .map((location: LoadsServiceLoadLocation) => {
        const lon = location.geometry?.coordinates[0];
        const lat = location.geometry?.coordinates[1];

        if (lon && lat) {
          return [lon, lat];
        }
      });
  }

  getTruckPosition(wayPoints: LoadWayPoint[], route: Position[], truckPosition: Position): Position {
    if (!truckPosition) {
      return;
    }

    const notCompletedWayPoint = this.getNotCompletedWayPoint(wayPoints);

    switch (notCompletedWayPoint) {
      case 0:
        return head(route);
      case -1:
        return last(route);
      default:
        return truckPosition;
    }
  }

  getShiftedTruckPosition(wayPoints: LoadWayPoint[], route: Position[], truckPosition: Position): Position {
    if (!truckPosition) {
      return;
    }

    const notCompletedWayPoint = this.getNotCompletedWayPoint(wayPoints);
    const reversedRoute = [...route].reverse();

    switch (notCompletedWayPoint) {
      case 0:
        return this.pointByDistance(route);
      case -1:
        return this.pointByDistance(reversedRoute);
      default:
        return truckPosition;
    }
  }

  getShiftedPoints(wayPoints: LoadWayPoint[], origin: Position, destination: Position): LoadWayPoint[] {
    const noNameWayPoints = wayPoints.slice(1, -1).map((wayPoint: LoadWayPoint) => {
      return { ...wayPoint, name: null };
    });

    return [
      { name: null, location: origin, type: this.locationType.PICKUP },
      ...noNameWayPoints,
      { name: null, location: destination, type: this.locationType.DROPOFF },
    ];
  }

  getTruckLocation(deviceLocation: DeviceLocation): [number, number] {
    const lon: number = get(deviceLocation, 'lon', null);
    const lat: number = get(deviceLocation, 'lat', null);

    return lon && lat ? [lon, lat] : null;
  }

  addTruckByLoadsServiceLoadLocationStatus(
    load: UnauthenticatedLoadsServiceLoad | LoadsServiceLoad,
    position?: Position
  ): Position[] {
    const { locations = [] } = load;
    const wayPoints = this.getLoadsServiceLoadRoutePoints(load);
    const notCompletedLocation = this.getNotCompletedLoadsServiceLoadLocation(locations);

    switch (notCompletedLocation) {
      case 0:
        if (!position) return [...wayPoints];
        return [position, ...wayPoints];
      case -1:
        if (!position) return [...wayPoints];
        return [...wayPoints, position];
      default:
        if (!position) return [...wayPoints.slice(0, notCompletedLocation), ...wayPoints.slice(notCompletedLocation)];
        return [...wayPoints.slice(0, notCompletedLocation), position, ...wayPoints.slice(notCompletedLocation)];
    }
  }

  getLoadsServiceLoadRoutePoints(load: UnauthenticatedLoadsServiceLoad | LoadsServiceLoad): Position[] {
    const { locations = [] } = load;

    return locations
      .filter((location: LoadsServiceLoadLocation) => location.geometry?.coordinates?.length)
      .map((location: LoadsServiceLoadLocation) => {
        // coordinates in lon, lat
        const [lon = null, lat = null] = location.geometry?.coordinates;

        if (lon && lat) {
          return [lon, lat];
        }
      });
  }

  getNotCompletedLoadsServiceLoadLocation(loadLocations: LoadsServiceLoadLocation[]): number {
    return loadLocations.findIndex((location: LoadsServiceLoadLocation) => !location.carrierDeparture);
  }

  private initTruck(source: LoadRouteSource): number {
    if (!source.truckPosition) {
      return 0;
    }

    const nearestPoint = this.getClosestPoint(source);
    const nextDirectionIndex =
      typeof source.coordinates[nearestPoint + 1] !== 'undefined' ? nearestPoint + 1 : source.coordinates.length - 1;

    return this.getRouteAngle(
      source.coordinates[nearestPoint][0],
      source.coordinates[nearestPoint][1],
      source.coordinates[nextDirectionIndex][0],
      source.coordinates[nextDirectionIndex][1]
    );
  }

  private initTruckRoute(source: LoadRouteSource): LngLatLike[] {
    if (!source.truckPosition) {
      return [];
    }

    const nearestPoint = this.getClosestPoint(source);

    return [...source.coordinates.slice(0, nearestPoint), source.truckPosition];
  }

  private getClosestPoint(source: LoadRouteSource): number {
    if (!source.truckPosition) {
      return 0;
    }

    const latitude = source.truckPosition[0];
    const longitude = source.truckPosition[1];

    const coordinates = source.coordinates;

    const distances: number[] = coordinates.map((gps: LngLatLike) =>
      this.getDistance(latitude, longitude, gps[0], gps[1])
    );

    return distances.indexOf(Math.min.apply(null, distances));
  }

  private getRouteAngle(lat1: number, lon1: number, lat2: number, lon2: number): number {
    const lonDiff = lon2 - lon1;
    const latDiff = lat2 - lat1;

    const radiansAngle = this.getRadiansAngle(lonDiff, latDiff);
    const angleDegrees = this.radiansToDegrees(radiansAngle);

    return 360 - angleDegrees;
  }

  private getRadiansAngle(lonDiff: number, latDiff: number): number {
    return Math.atan2(lonDiff, latDiff);
  }

  private degreesToRadians(degrees: number): number {
    return (degrees * Math.PI) / 180;
  }

  private radiansToDegrees(angle: number): number {
    return (angle * 180) / Math.PI;
  }

  private getDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
    lat1 = this.degreesToRadians(lat1);
    lat2 = this.degreesToRadians(lat2);
    lon1 = this.degreesToRadians(lon1);
    lon2 = this.degreesToRadians(lon2);

    const x = (lon2 - lon1) * Math.cos((lat1 + lat2) / 2);
    const y = lat2 - lat1;

    return Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
  }

  private flatten(list): LngLatLike[] {
    return list.reduce((a, b) => a.concat(b), []);
  }
}
