import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { DirectivesModule } from '@haulynx/directives';
import { MaterialModule } from '@haulynx/modules';
import { CarrierInfoSectionVmService } from '@haulynx/services';
import { NgxMaskModule } from 'ngx-mask';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CarrierToollTipModule } from '../../carrier-tool-tip/carrier-tool-tip.module';
import { DropDownModule } from '../../drop-down/drop-down.module';
import { CarrierInfoSectionComponentV2 } from './carrier-info-section-v2.component';

@NgModule({
  declarations: [CarrierInfoSectionComponentV2],
  exports: [CarrierInfoSectionComponentV2],
  providers: [CarrierInfoSectionVmService],
  imports: [
    ReactiveFormsModule,
    MatInputModule,
    CommonModule,
    DropDownModule,
    NgxMaskModule.forRoot(),
    MaterialModule,
    OverlayPanelModule,
    CarrierToollTipModule,
    DirectivesModule,
  ],
})
export class CarrierInfoSectionModuleV2 {}
