import { RegionTypes } from '../location';
import { AvailabilityInput, LaneType, LocationPoint } from './capacity-object';

export interface ILane {
  lane: string; // example: "AL-03->FL-02"
  orgCityState: string; // example: "Mobile, AL"
  destCityState: string;
  id: number;
  confidence: number;
  orgCity?: string;
  orgState?: string;
  destCity?: string;
  destState?: string;
}

export class Lane {
  id: string;
  userId: string;
  carrierDot?: string;
  type: LaneType;
  active?: boolean;
  origin?: CapacityObjectLocation;
  destination?: CapacityObjectLocation;
  expireTimestamp?: string;
  equipmentType?: string[];
  cost?: CostObject;
  rpm?: CostObject;
  minimumStopCharge?: string;
  count?: number;
  allowStops?: boolean;
  hazMat?: boolean;
  lastUpdatedAt?: string;
  showLoadNotifications?: boolean;

  constructor(props?: Partial<Lane>) {
    Object.assign(this, props);
  }
}

export class CapacityObjectLocation {
  point: LocationPoint;
  radius: string;
  state?: string;
  region?: RegionTypes;
  availability: AvailabilityInput;
  locationName?: string;

  constructor(props?: Partial<CapacityObjectLocation>) {
    Object.assign(this, props);
  }
}

export class CostObject {
  min: string;
  max: string;
}
