import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoadDetailsModel, LoadFeedStoreModule } from '@haulynx/store';
import { LoadFeedSearchResultsModule } from '../load-feed-search-results/load-feed-search-results.module';
import { LoadFeedSearchFormModule } from '../load-feed/components/load-feed-search-form/load-feed-search-form.module';
import { UnauthenticatedLoadFeedComponent } from './unauthenticated-load-feed.component';

@NgModule({
  declarations: [UnauthenticatedLoadFeedComponent],
  exports: [UnauthenticatedLoadFeedComponent],
  imports: [CommonModule, LoadFeedStoreModule, LoadFeedSearchResultsModule, LoadFeedSearchFormModule],
  providers: [LoadDetailsModel],
})
export class UnauthenticatedLoadFeedModule {}
