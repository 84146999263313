import { orderBy } from 'lodash';
import { RadiusSearchOption, radiusSearchOptions } from '../load-feed';

export const carriersTabs = {
  SEARCH: {
    id: 'search',
    label: 'Carriers',
    url: 'search',
    order: 1,
  },
};

export const truckRadiusOptions: RadiusSearchOption[] = orderBy(
  [...radiusSearchOptions, { label: '75 mi', value: 120701 }],
  ['value']
);
