export interface NearestCity {
  lat: number;
  lon: number;
  city: string;
  state: string;
}

export interface LatLonInput {
  lat: number;
  lon: number;
}
