<div class="date-picker">
  <form [formGroup]="inputForm" class="date-picker-form">
    <div [attr.disabled]="inputForm.disabled" class="date-fields-input">
      <input
        matInput
        class="num-input-2"
        formControlName="month"
        type="text"
        (focus)="onFocus($event)"
        (blur)="padNum()"
        (keydown)="keypressHandler($event, 'month')"
        min="1"
        max="12"
        placeholder="MM"
        #firstInput
      />
      <span>/</span>
      <input
        matInput
        class="num-input-2"
        formControlName="day"
        type="text"
        (focus)="onFocus($event)"
        (blur)="padNum()"
        (keydown)="keypressHandler($event, 'day')"
        min="1"
        max="31"
        placeholder="dd"
      />
      <span>/</span>
      <input
        matInput
        class="num-input-4"
        formControlName="year"
        type="text"
        (focus)="onFocus($event)"
        (blur)="padNum()"
        (keydown)="keypressHandler($event, 'year')"
        min="2020"
        max="2050"
        placeholder="yyyy"
      />

      <ng-container *ngIf="selectionMode === 'range'">
        <span class="range-separator">-</span>
        <input
          matInput
          class="num-input-2"
          formControlName="monthRange"
          type="text"
          (focus)="onFocus($event)"
          (blur)="padNum()"
          (keydown)="keypressHandler($event, 'monthRange')"
          min="1"
          max="12"
          placeholder="MM"
        />
        <span>/</span>
        <input
          matInput
          class="num-input-2"
          formControlName="dayRange"
          type="text"
          (focus)="onFocus($event)"
          (blur)="padNum()"
          (keydown)="keypressHandler($event, 'dayRange')"
          min="1"
          max="31"
          placeholder="dd"
        />
        <span>/</span>
        <input
          matInput
          class="num-input-4"
          formControlName="yearRange"
          type="text"
          (focus)="onFocus($event)"
          (blur)="padNum()"
          (keydown)="keypressHandler($event, 'yearRange')"
          min="2020"
          max="2050"
          placeholder="yyyy"
        />
      </ng-container>

      <ng-container *ngIf="selectionMode === 'single'">
        <input
          matInput
          class="num-input-2"
          formControlName="hours"
          type="text"
          (focus)="onFocus($event)"
          (blur)="padNum()"
          (keydown)="keypressHandler($event, 'hours')"
          min="0"
          max="23"
          placeholder="HH"
        />
        <span>:</span>
        <input
          matInput
          class="num-input-2"
          formControlName="mins"
          type="text"
          (focus)="onFocus($event)"
          (blur)="padNum()"
          (keydown)="keypressHandler($event, 'mins')"
          min="0"
          max="59"
          placeholder="mm"
        />
      </ng-container>
      <div *ngIf="showTimeZone" class="timeZone-text">{{ displayTimeZone }}</div>
      <button
        class="calendar-icon"
        type="button"
        [disabled]="disable"
        (click)="(calendarControl.enable); showCalendar = !showCalendar"
      ></button>
    </div>

    <span class="error-message" *ngIf="errors">
      {{ errors | validationError }}
    </span>

    <span class="error-message" *ngIf="inputForm.errors && !errors">
      {{ inputForm.errors | validationError }}
    </span>

    <div class="calendar-container" *ngIf="showCalendar">
      <p-calendar
        #calendar
        placeholder="mm/dd/yyyy HH:mm"
        [selectionMode]="selectionMode"
        [formControl]="calendarControl"
        [inline]="true"
        [showTime]="true"
        [showButtonBar]="false"
        [minDate]="min"
        [maxDate]="max"
        (onSelect)="onDateSelect($event)"
        styleClass="calendar"
      >
      </p-calendar>
    </div>
  </form>
</div>
