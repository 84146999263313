export enum DomainEntity {
  BID = 'Bid',
  BROKER = 'Broker',
  CAPACITYLANE = 'CapacityLane',
  CARRIER = 'Carrier',
  DEVICE = 'Device',
  FEATUREFLAG = 'FeatureFlag',
  LOAD = 'Load',
  RELEASENOTES = 'ReleaseNote',
  SHIPPER = 'Shipper',
  TEMPLATE = 'Template',
  TRAILER = 'Trailer',
  TRUCK = 'Truck',
  USER = 'User',
}
