import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { SpinnerModule } from '../spinner/spinner.module';
import { MapClusterComponent } from './components/map-cluster/map-cluster.component';
import { MapRoutesComponent } from './components/map-routes/map-routes.component';
import { MarkerPopupComponent } from './components/marker-popup/marker-popup.component';
import { environment } from '../../../../../apps/web/src/environments/environment';

@NgModule({
  declarations: [MapRoutesComponent, MapClusterComponent, MarkerPopupComponent],
  imports: [
    CommonModule,
    // TODO: Change this into a factory that will get the token from environment
    NgxMapboxGLModule.withConfig({
      accessToken: environment.mapbox.accessToken,
    }),
    PipesModule,
    SpinnerModule,
    MaterialModule,
  ],
  exports: [MapRoutesComponent, MapClusterComponent, MarkerPopupComponent],
})
export class MapsModule {}
