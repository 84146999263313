import { Pipe, PipeTransform } from '@angular/core';
import { lowerCase, startCase } from 'lodash';

@Pipe({
  name: 'fromCamelCase',
})
export class FromCamelCasePipe implements PipeTransform {
  transform(value: string): string {
    return startCase(lowerCase(value));
  }
}
