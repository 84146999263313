import gql from 'graphql-tag';

export const GetLoadHistoryByLoadId = gql(`
  query GetLoadHistoryByLoadId($loadId: StrictId!) {
    getLoadHistoryByLoadId(loadId: $loadId) {
      events {
        activityType
        loadDelta{
          assignedBrokers{
            addedValues{
              name
            }
            removedValues{
              name
            }
          }
          bidDetails{
            offerCount
            lowestOffer
            lowestOfferCarrierName
          }
          bookStatus {
            oldValue
            newValue
          }
          broker{ 
            newValue {
              name
            }
            oldValue {
              name
            }
          }
          carrier{
              newValue {
                name
                dot
                mcNumber
                phone
              }
              oldValue {
                name
                dot
                mcNumber
                phone
              }
            }
          carrierBid{ 
            price
            status
            createdBy {
              name
            }
          }
          distributionMechanism {
            newValue
            oldValue
          }
          drivers {
            addedValues{
              name
            }
            removedValues{
              name
            }
          }
          locations {
            addedValues{
              id
              address
              city
              state
              appointmentStart
              appointmentEnd
              appointmentSet
              distanceMiles
              locationType
              timezone
              customer {
                notes {
                  noteText
                }
                name
                number
              }
              notes {
                author
                text
              }
            }
            modifiedValues{
              id
              address {
                newValue
                oldValue
              }
              city {
                newValue
                oldValue
              }
              state {
                newValue
                oldValue
              }
              appointmentStart {
                newValue
                oldValue
              }
              appointmentEnd {
                newValue
                oldValue
              }
              appointmentSet {
                newValue
                oldValue
              }
              locationType {
                newValue
                oldValue
              }
              timezone {
                newValue
                oldValue
              }
              distanceMiles {
                newValue
                oldValue
              }
              customer {
                newValue {
                  notes {
                    noteText
                  }
                  name
                  number
                }
                oldValue {
                  notes {
                    noteText
                  }
                  name
                  number
                }
              }
              notes {
                newValue {
                  author
                  text
                }
                oldValue {
                  author
                  text
                }
              }
            }
            removedValues{
              id
              address
              city
              state
              appointmentStart
              appointmentEnd
              appointmentSet
              distanceMiles
              locationType
              timezone
              customer {
                notes {
                  noteText
                }
                name
                number
              }
              notes {
                author
                text
              }
            }
          }
          notes{
            newValue{
              text
              author
              createdAt
            }
            oldValue{
              text
              author
              createdAt
            }
          }
          paymentDetails{
            price {
              newValue
              oldValue
            }
            revenue {
              newValue
              oldValue
            }
            maxBuy {
              newValue
              oldValue
            }
          }
          provider{
            name {
              newValue
              oldValue
            }
            company {
              newValue
              oldValue
            }
          }
          providerDetails{
            billTo {
              newValue
              oldValue
            }
            cxPriority {
              newValue
              oldValue
            }
            operationsPriority {
              newValue
              oldValue
            }
            internalNotes {
              newValue
              oldValue
            }
            externalNotes {
              newValue
              oldValue
            }
            commodity {
              newValue
              oldValue
            }
            equipmentType {
              newValue
              oldValue
            }
            weightLbs {
              newValue
              oldValue
            }
            orderType {
              newValue
              oldValue
            }
          }
          rateConEmails{
            addedValues{
              emailRecipient
            }
            modifiedValues{
              emailRecipient {
                newValue 
                oldValue
              }
            }
            removedValues{
              emailRecipient
            }
          }
          region {
            newValue
            oldValue 
          }
          restrictions{
            addedValues{
              type
              value
            }
            modifiedValues{
              newValue {
                type
                value
              }
              oldValue {
                type
                value
              }
            }
            removedValues{
              type
              value
            }
          }
          trackingStatus{
            usxStatusCode {
              newValue
              oldValue
            }
            usxOrderStatus {
              newValue 
              oldValue
            }
            usxOrderStatusReason{
              newValue 
              oldValue
            }
          }
          trackingType {
            newValue
            oldValue
          }
          trailers{
            addedValues{
              trailerNumber
            }
            removedValues{
              trailerNumber
            }
          }
          truck{
            newValue{
              unitId
            }
            oldValue{
              unitId
            }
          }
        }
        updatedAt
        updatedBy{
            id
            usxId
            name
            email
            uri
            type
            clientId
            carrierId
            profile
        }
        updatedById
        userType
        newLoad {
          locations {
            id
            address
            summaryAddress
            city
            state
            zipcode
            timezone
            appointmentSet
            appointmentStart
            appointmentEnd
            carrierArrival
            carrierDeparture
            locationType
            billOfLading
            geometry {
              type
              coordinates
            }
            distanceMiles
            customer {
              name
              number
            }
            notes {
              author
              text
            }
          }
        }
      }
    }  
  }
`);
