import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  Load,
  LoadsServiceLoad,
  LoadStatusIconLabel,
  LoadStep,
  loadStepType,
  LoadStepType,
  LoadStepTypeStatus,
  loadStepTypeStatus,
} from '@haulynx/types';
import { buildLoadSteps, getMapButtonLabel, getMapLabelStatus, getNextSteps } from '@haulynx/utils';

@Component({
  selector: 'app-load-timeline-status',
  templateUrl: './load-timeline-status.component.html',
  styleUrls: ['./load-timeline-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadTimelineStatusComponent implements OnChanges {
  @Input() load: LoadsServiceLoad;
  @Input() bookedLoad = true;
  @Input() finalledLoad = true;
  @Input() showStatus = true;
  @Input() selectedStepId = null;
  @Input() showIndicator = false;
  @Output() actionClick = new EventEmitter<LoadStep>();
  @Output() selectActiveStep = new EventEmitter<LoadStep>();
  @Output() changeStepStatusLabel = new EventEmitter<string>();
  @Input() activeStepTypes = [loadStepType.ASSIGNED, loadStepType.DISPATCHED];
  @Input() loadOverview = false;

  selectedId = null;
  activeStep: LoadStep = null;
  steps: LoadStep[];
  locationStepTypes = [];
  loadStatus: LoadStatusIconLabel;
  mapButtonLabel = {};
  loadStepType: LoadStepType = loadStepType;
  public loadStepTypeStatus: LoadStepTypeStatus = loadStepTypeStatus;

  constructor() {
    this.locationStepTypes = [
      this.loadStepType.PICKUP,
      this.loadStepType.PICKUP_STOP,
      this.loadStepType.DROPOFF,
      this.loadStepType.DROPOFF_STOP,
    ];
    this.mapButtonLabel = getMapButtonLabel();
  }

  execute(step: LoadStep) {
    if (
      (this.activeStep && step.id === this.activeStep.id) ||
      step.status === this.loadStepTypeStatus.COMPLETED ||
      step.status === this.loadStepTypeStatus.SKIPPED
    ) {
      this.selectedId = step.id;
      this.actionClick.emit(step);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { load, selectedStepId } = changes;

    if (load) {
      this.steps = buildLoadSteps(load.currentValue, this.activeStepTypes);
      if (this.loadOverview) {
        const newSteps = this.steps.filter((step: LoadStep) => step.id !== 'assigned');
        this.steps = newSteps;
      }
      const { active } = getNextSteps(this.steps);

      this.activeStep = active;
      this.loadStatus = getMapLabelStatus(this.steps);
      this.changeStepStatusLabel.emit(this.loadStatus.label);
    }

    if (selectedStepId) {
      this.selectedId = selectedStepId.currentValue;
    }
  }
}
