import gql from 'graphql-tag';

export const GetBidsByLoadId = gql`
  query GetBidsByLoadId($loadId: StrictId!) {
    getBidsByLoadId(loadId: $loadId, includeBidHistory: true) {
      id
      price
      status
      bidHistory {
        id
        bidId
        bid {
          carrier {
            id
            name
            dot
          }
        }
        event
        data {
          notes
          price
          status
        }
        createdAt
        createdBy {
          name
          carrier {
            id
          }
        }
      }
      carrierId
      carrier {
        id
        name
        dot
      }
      loadId
      loadsServiceLoad {
        id
        created
        locations {
          id
          address
          city
          state
          zipcode
          timezone
          appointmentSet
          appointmentStart
          appointmentEnd
          locationType
          billOfLading
          geometry {
            type
            coordinates
          }
        }
        providerDetails {
          equipmentType
          weightLbs
          alternateIds {
            identifierType
            identifierValue
          }
        }
        paymentDetails {
          price
          revenue
          distanceMiles
        }
      }
      notes
      createdAt
      createdBy {
        id
        name
      }
      updatedAt
      updatedBy {
        id
        name
      }
      activeCounterOffer {
        id
        price
        notes
        status
      }
      counterOffers {
        id
        notes
      }
      sourceType
      creationType
      campaignOrigin
      offerState
      offerStateDescription
      offerStateColor
      finalStateFrom
    }
  }
`;
