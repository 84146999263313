import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@haulynx/modules';
import { SpinnerModule } from '../spinner/spinner.module';
import { BlueLinkTextComponent } from './blue-link-text.component';

@NgModule({
  declarations: [BlueLinkTextComponent],
  imports: [CommonModule, FlexLayoutModule, MaterialModule, SpinnerModule],
  exports: [BlueLinkTextComponent],
})
export class BlueLinkTextModule {}
