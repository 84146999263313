import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@haulynx/directives';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { AssignLoadCarrierFormVm } from '@haulynx/services';
import { CarrierEntityService, MissionEntityService } from '@haulynx/store';
import { DropDownModule } from '../drop-down/drop-down.module';
import { LoadDispatchFormModule } from '../load-dispatch-form/load-dispatch-form.module';
import { AddDriverModule } from '../pay-line-items/add-driver/add-driver.module';
import { AddPaymentTypeModule } from '../pay-line-items/add-payment-type/add-payment-type.module';
import { AddTrailerModule } from '../pay-line-items/add-trailer/add-trailer.module';
import { AddTruckModule } from '../pay-line-items/add-truck/add-truck.module';
import { CarrierInfoSectionModuleV2 } from '../pay-line-items/carrier-info-section-v2/carrier-info-section-v2.module';
import { ChipSelectorModule } from '../pay-line-items/chip-selector/chip-selector.module';
import { ConfirmBookLoadModule } from '../pay-line-items/confirm-book-load/confirm-book-load.module';
import { ConfirmBookMissionModule } from '../pay-line-items/confirm-book-mission/confirm-book-mission.module';
import { OrderInfoNotesModule } from '../pay-line-items/order-info-notes/order-info-notes.module';
import { OrderInfoTableModule } from '../pay-line-items/order-info-table/order-info-table.module';
import { PayLinePriceModule } from '../pay-line-items/pay-line-price/pay-line-price.module';
import { PaymentDetailsModule } from '../pay-line-items/payment-details/payment-details.module';
import { TruckInfoSectionModule } from '../pay-line-items/truck-info-section/truck-info-section.module';
import { SwapTrailerModule } from '../swap-trailer/swap-trailer.module';
import { TextAreaHandlerModule } from '../text-area-handler/text-area-handler.module';
import { AssignLoadCarrierFormComponentV2 } from './assign-load-carrier-form-v2.component';

@NgModule({
  declarations: [AssignLoadCarrierFormComponentV2],
  exports: [AssignLoadCarrierFormComponentV2],
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    DropDownModule,
    OrderInfoTableModule,
    CarrierInfoSectionModuleV2,
    TruckInfoSectionModule,
    PaymentDetailsModule,
    OrderInfoNotesModule,
    LoadDispatchFormModule,
    AddPaymentTypeModule,
    SwapTrailerModule,
    PayLinePriceModule,
    AddDriverModule,
    AddTruckModule,
    AddTrailerModule,
    ConfirmBookLoadModule,
    ConfirmBookMissionModule,
    TextAreaHandlerModule,
    MaterialModule,
    ChipSelectorModule,
    DirectivesModule,
    PipesModule,
  ],
  providers: [AssignLoadCarrierFormVm, CarrierEntityService],
})
export class AssignLoadCarrierFormModuleV2 {}
