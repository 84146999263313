<app-spinner [active]="(loadEntityService.getLoadByIdManager.isLoadingEntities$ | async)[loadId]"></app-spinner>

<div class="details-info-wrapper" *ngIf="loadFeed">
  <app-lf-details-info-pane
    [load]="loadFeed"
    [carrierDot]="carrierDot"
    [isExclusive]="isExclusive"
    [isCarrier]="isCarrier"
    [carrierId]="carrierId"
    (bookSuccess)="bookSuccess($event)"
    (showFacilityInfo)="onShowFacilityInfo($event)"
  >
  </app-lf-details-info-pane>

  <app-lf-details-map [load]="loadFeed" [loadFeedRoute]="loadFeedRoute$ | async" [isExclusive]="isExclusive">
  </app-lf-details-map>
</div>
