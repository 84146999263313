import { Component, Input, ViewEncapsulation } from '@angular/core';
import { AnalyticsService } from '@haulynx/services';
import { ANALYTICS_EVENT, Carrier, CarrierInsuranceAndSafety, CarrierUsx } from '@haulynx/types';
import { openEmailClient } from '@haulynx/utils';

@Component({
  selector: 'app-carrier-details-short',
  templateUrl: './carrier-details-short.component.html',
  styleUrls: ['./carrier-details-short.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CarrierDetailsShortComponent {
  @Input() carrier: Carrier | any = null;
  @Input() isLoading = false;
  @Input() insuranceAndSafety?: CarrierInsuranceAndSafety;
  @Input() isLoadingInsuranceAndSafety = false;
  @Input() carriersStatus: CarrierUsx;
  @Input() isLoadingCarriersStatus = false;

  constructor(private analytics: AnalyticsService) {}

  phoneClicked(event: Event) {
    this.analytics.logEvent(ANALYTICS_EVENT.CRM_PHONE_CLICKED, { clickedCarrier: this.carrier });
    event.stopPropagation();
  }

  emailClicked(event: Event) {
    this.analytics.logEvent(ANALYTICS_EVENT.CRM_EMAIL_CLICKED, { clickedCarrier: this.carrier });
    openEmailClient({
      mailto: this.carrier.email,
    });

    event.stopPropagation();
  }
}
