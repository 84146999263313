<div class="book-load-buttons-container" *ngIf="!(isLoading$ | async); else loadingIndicator">
  <ng-container *ngIf="!isBroker; else brokerBookableLoadButton">
    <ng-container [ngSwitch]="load?.bookStatus">
      <ng-container *ngSwitchCase="bookStatus.BOOKABLE">
        <ng-container *ngIf="isBrokerOrAdmin; else conditionalExclusive">
          <div>Bookable</div>
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="bookStatus.BOOKED">
        <button mat-raised-button (click)="viewLoad()" *ngIf="load?.carrier?.dot === carrierDot; else noViewButton">
          View Load
        </button>
      </ng-container>

      <ng-container *ngSwitchCase="bookStatus.VIEWABLE">
        <ng-container *ngIf="showNonBookable">
          <ng-container *ngIf="isBrokerOrAdmin; else conditionalBookable">
            <div>Viewable</div>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="bookStatus.EXCLUDED">
        <ng-container *ngIf="showNonBookable">
          <ng-container *ngIf="isBrokerOrAdmin; else conditionalBookable"> Excluded </ng-container>
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <div>{{ load?.bookStatus }}</div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>

<!-- TEMPLATES -->
<ng-template #bookableLoadButton>
  <div class="action-buttons" *ngIf="bookableWithPrice; else callToBook">
    <button
      mat-raised-button
      data-test="book-it-button"
      (click)="openConfirm($event)"
      class="action-button affirmative"
    >
      {{ buttonText }} <span *ngIf="showPrice">&nbsp;{{ load?.paymentDetails?.price | currency }}</span>
    </button>
    <button
      *ngIf="showDecline"
      mat-raised-button
      data-test="decline-load-button"
      (click)="openDecline($event)"
      class="action-button negative"
    >
      {{ declineButtonText }}
    </button>
  </div>
</ng-template>

<ng-template #brokerBookableLoadButton>
  <ng-container
    *ngIf="
      load &&
        !(
          load.bookStatus === bookStatus.BOOKED ||
          load.bookStatus === bookStatus.VIEWABLE ||
          load.bookStatus === bookStatus.EXCLUDED
        );
      else bookableLoadButton
    "
  >
    <div>{{ load.bookStatus }}</div>
  </ng-container>
</ng-template>

<ng-template #callToBook>
  <tooltip-menu
    class="centered-item"
    *ngIf="load?.providerDetails?.regionalManagerEmail || load?.providerDetails?.regionalManagerPhone"
    [actionEmail]="load?.providerDetails?.regionalManagerEmail"
    [actionPhone]="load?.providerDetails?.regionalManagerPhone"
    label="Call to book"
  >
  </tooltip-menu>
</ng-template>

<ng-template #exclusiveLoad>
  <button
    mat-raised-button
    class="action-button affirmative"
    [ngClass]="{ expired: timerFinished }"
    [disabled]="timerFinished"
    type="button"
    (click)="openConfirm($event)"
  >
    <span *ngIf="!timerFinished">
      Book it now <span *ngIf="showPrice">&nbsp;{{ load?.paymentDetails?.maxBuy | currency }}</span>
    </span>
    <span *ngIf="timerFinished"> Expired </span>
    <countdown
      class="countdown"
      (finished)="onTimerFinished()"
      *ngIf="
        +(load?.restrictions | loadRestrictions: loadsServiceRestrictionTypes.EXCLUSIVE_UNTIL) > 0 && !timerFinished
      "
      [config]="{
        stopTime: +(load?.restrictions | loadRestrictions: loadsServiceRestrictionTypes.EXCLUSIVE_UNTIL),
        clock: counterClock,
        template: counterTemplate
      }"
    >
    </countdown>
  </button>
</ng-template>

<ng-template #conditionalExclusive>
  <ng-container *ngIf="isExclusive; then exclusiveLoad; else bookableLoadButton"></ng-container>
</ng-template>

<ng-template #conditionalBookable>
  <ng-container *ngIf="isBookable$ | async; then bookableLoadButton; else callToBook"></ng-container>
</ng-template>

<ng-template #loadingIndicator>
  <div class="centered-item">
    <app-spinner [active]="true" [transparentBackground]="true" [diameter]="32"></app-spinner>
  </div>
</ng-template>

<ng-template #noViewButton>
  <div class="centered-item">Booked</div>
</ng-template>
