import { Injectable } from '@angular/core';
import { GetBrokerIds, GetUsersByBroker } from '@haulynx/gql';
import { GraphqlSearchResponse } from '@haulynx/types';
import { map as lodashMap, orderBy } from 'lodash';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { GraphqlService } from '../graphql.service';

@Injectable({
  providedIn: 'root',
})
export class BrokersService {
  constructor(private graphqlService: GraphqlService) {}

  brokerIdsCache: { id: string; label: string }[];

  getAllBrokerIds(): Observable<GraphqlSearchResponse<any>> {
    if (this.brokerIdsCache?.length) {
      return of({ entities: this.brokerIdsCache, total: this.brokerIdsCache.length });
    }
    return this.graphqlService
      .query<any>({
        query: GetBrokerIds,
      })
      .pipe(
        map(({ data }: any) => {
          let entities = lodashMap(data.getBrokerIds || [], (broker) => {
            return { id: broker, label: broker };
          });
          entities = orderBy(entities, ['label'], 'asc').filter(
            (broker: { id: string; label: string }) => broker.label.length && broker.label[0].match(/[a-z]/i)
          );
          // cache data
          this.brokerIdsCache = entities;

          const total = entities.length;

          return { entities, total };
        })
      );
  }

  getUsersByBroker(variables: { brokerId: string }) {
    return this.graphqlService
      .query({
        query: GetUsersByBroker,
        variables: variables,
      })
      .pipe(
        map(({ data }: any) => {
          const entities = data.getUsersByBroker || [];
          const total = entities.length;

          return { entities, total };
        })
      );
  }
}
