import { createActions, IGenericActions, IGenericActionsTypes } from './actions';

export interface GenericActionsAdapter<T> {
  getActionTypes(): IGenericActionsTypes;

  getActions(): IGenericActions;
}

export function createGenericActionsAdapter<T>(options: { type?: any } = {}): GenericActionsAdapter<T> {
  const { type } = options;

  const actions = createActions(type);
  return {
    ...actions,
  };
}
