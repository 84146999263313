import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DirectivesModule } from '@haulynx/directives';
import { PipesModule } from '@haulynx/pipes';
import { ActionButtonsModule } from '../../../actions-buttons/action-buttons.module';
import { CarrierDetailsDirective } from '../../../carrier-details/carrier-details-info/carrier-details.directive';
import { CarrierDetailsModule } from '../../../carrier-details/carrier-details.module';
import { DataTableCalendarModule } from '../../../data-table-calendar/data-table-calendar.module';
import { DataTableV2Module } from '../../../data-table-v2/data-table-v2.module';
import { LoadTimelineModule } from '../../../load-timeline/load-timeline.module';
import { LoadFeedCarrierSearchV2Component } from './load-feed-carriers-search-v2.component';

@NgModule({
  declarations: [LoadFeedCarrierSearchV2Component],
  imports: [
    CommonModule,
    DataTableV2Module,
    LoadTimelineModule,
    ActionButtonsModule,
    PipesModule,
    CarrierDetailsModule,
    DirectivesModule,
  ],
  exports: [LoadFeedCarrierSearchV2Component],
})
export class LoadFeedCarriersSearchV2Module {}
