<div class="actions-container">
  <app-button-dropdown
    [closedIcon]="'more_vert'"
    [openIcon]="'more_vert'"
    [showButtonLabel]="false"
    [isMultiSelect]="false"
    [dropdownList]="laneHistoryActionMenu"
    [dropdownListOptions]="laneHistoryActionMenuIcons"
    [dropdownPosition]="'top'"
    [buttonBackgroundColor]="'transparent'"
    (clickedDropdownItem)="clickEvent($event)"
  >
  </app-button-dropdown>
</div>
