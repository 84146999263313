import { MapsAPILoader } from '@agm/core';
import { Injectable } from '@angular/core';
import {
  BookStatus,
  CreateMilestoneFormResult,
  EldPoints,
  EldStatus,
  EmphasizedMilestone,
  LoadLocationType,
  LoadMilestoneInfo,
  LoadsServiceLoad,
  LoadsServiceLoadLocation,
  LoadsServiceLoadStatus,
  Milestone,
  MilestoneComment,
  MilestoneEvent,
  MilestoneLog,
  MilestoneSubtype,
  MilestoneType,
  MilestoneUser,
  RouteWaypoints,
  TrackingType,
  User,
  WayPoint,
} from '@haulynx/types';
import { getLoadServiceLoadWayPointsCoordinates } from '@haulynx/utils';
import { LngLatLike } from 'mapbox-gl';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MilestonesService {
  conversionFactorMetersToMiles = 1609.344;
  createMilestoneFormResult: Subject<{
    formResult: CreateMilestoneFormResult;
    isEdit: boolean;
    index: number;
  }> = new Subject();

  /**
   * Computing coordinates for the load is heavy and unchanging.
   * Cache results so we don't have to recompute per load.
   */
  private loadCoordinatesCache: { [loadId: string]: string } = {};

  constructor() {}

  pushMilestoneFormResult(form: CreateMilestoneFormResult, isEdit: boolean, index: number) {
    this.createMilestoneFormResult.next({ formResult: form, isEdit, index });
  }

  getEldPointsFromAllMilestones(
    milestones: Milestone[],
    emphasizedMilestone?: EmphasizedMilestone,
    user?: User
  ): EldPoints[] {
    const points = [];
    milestones.forEach((milestone: Milestone, milestoneIndex: number) => {
      if (milestone.type === MilestoneType.LOCATION) {
        milestone.logs.forEach((log: MilestoneLog, logIndex: number) => {
          points.push(<EldPoints>{
            status: EldStatus.GOOD,
            coordinates: <LngLatLike>{ lon: log.primaryEvent.longitude, lat: log.primaryEvent.latitude },
            isEmphasized:
              emphasizedMilestone?.type === MilestoneType.LOCATION &&
              emphasizedMilestone?.index === milestoneIndex &&
              log.subType === emphasizedMilestone.subType,
            milestoneId: milestone.id,
          });
        });
      } else if (
        milestone.type === MilestoneType.UPDATE &&
        milestone.logs.some((log) => log.subType === MilestoneSubtype.LOCATION)
      ) {
        milestone.logs.forEach((log: MilestoneLog) => {
          if (log.primaryEvent.longitude !== 0 && log.primaryEvent.latitude !== 0) {
            points.push(<EldPoints>{
              status: EldStatus.UPDATE,
              coordinates: <LngLatLike>{ lon: log.primaryEvent.longitude, lat: log.primaryEvent.latitude },
              isEmphasized:
                emphasizedMilestone?.type === MilestoneType.UPDATE &&
                emphasizedMilestone?.index === milestoneIndex &&
                log.subType === emphasizedMilestone.subType,
              milestoneId: milestone.id,
            });
          }
        });
      } else if (
        milestone.type === MilestoneType.UPDATE &&
        !milestone.logs.some((log) => log.subType === MilestoneSubtype.LOCATION)
      ) {
        milestone.logs.forEach((log: MilestoneLog) => {
          if (log.primaryEvent.longitude !== 0 && log.primaryEvent.latitude !== 0) {
            points.push(<EldPoints>{
              status: EldStatus.PROBLEM,
              coordinates: <LngLatLike>{ lon: log.primaryEvent.longitude, lat: log.primaryEvent.latitude },
              isEmphasized:
                emphasizedMilestone?.type === MilestoneType.UPDATE &&
                emphasizedMilestone?.index === milestoneIndex &&
                log.subType === emphasizedMilestone.subType,
              milestoneId: milestone.id,
            });
          }
        });
      }
    });
    return points;
  }

  getLastMilestone(milestones: Milestone[]): Milestone {
    const filteredMilestones = milestones.filter((milestone) => milestone.authorId !== 'trailerTelemetry');
    const logReducer = (accLog: MilestoneLog, log: MilestoneLog) => {
      if (!accLog) {
        return log;
      } else {
        if (accLog.timestamp > log.timestamp) return accLog;
        else return log;
      }
    };

    const milestoneReducer = (acc: Milestone, milestone: Milestone) => {
      if (!acc) {
        return milestone;
      } else {
        const accumulatedLatestLog = acc.logs.reduce(logReducer, null);
        const currentLatestLog = milestone.logs.reduce(logReducer, null);

        if (accumulatedLatestLog.timestamp > currentLatestLog.timestamp) return acc;
        else return milestone;
      }
    };

    return filteredMilestones?.reduce(milestoneReducer, null) ?? null;
  }

  getLoadServiceLoadWaypoints(
    load: LoadsServiceLoad,
    milestones: Milestone[],
    emphasizedMilestone?: EmphasizedMilestone
  ): { dispatch: WayPoint; location: WayPoint[] } {
    if (!load.locations) {
      return { dispatch: null, location: [] };
    }

    let dispatchWaypoint: WayPoint;
    const dispatchMilestone = milestones.find(
      (m) => m.logs.filter((l) => l.subType === MilestoneSubtype.DISPATCH).length > 0
    );
    if (dispatchMilestone) {
      const dispatchLog = dispatchMilestone.logs.find((l) => l.subType === MilestoneSubtype.DISPATCH);
      dispatchWaypoint = {
        isDetention: false,
        location: [dispatchLog.primaryEvent.longitude, dispatchLog.primaryEvent.latitude],
        locationType: 'dispatch',
        name: dispatchLog.primaryEvent.timestamp.toString(),
        inCoordinates: null,
        outCoordinates: null,
        inTimestamp: null,
        isEmphasized: emphasizedMilestone?.subType === MilestoneSubtype.DISPATCH,
        outTimestamp: null,
        facility: null,
        milestoneId: null,
      };
    }

    const locationWaypoints = load.locations.map((location: LoadsServiceLoadLocation, index: number) => {
      const milestoneIndex = milestones.findIndex((milestone) => milestone.locationId === location.id);
      const milestone = milestones[milestoneIndex];
      const completedLocationLog = milestone?.logs.find((log) => log.subType === MilestoneSubtype.COMPLETE);
      const isDetention =
        milestone?.logs.find((log) => log.editedByType === MilestoneUser.BROKER)?.subType ===
        MilestoneSubtype.DETENTION;

      return <WayPoint>{
        locationType: location.locationType,
        name: location.address,
        location: location.geometry.coordinates,
        inTimestamp: completedLocationLog ? completedLocationLog.primaryEvent.timestamp : null,
        outTimestamp: completedLocationLog ? completedLocationLog.secondaryEvent.timestamp : null,
        inCoordinates: completedLocationLog
          ? [completedLocationLog.primaryEvent.latitude, completedLocationLog.primaryEvent.longitude]
          : null,
        outCoordinates: completedLocationLog
          ? [completedLocationLog.secondaryEvent.latitude, completedLocationLog.secondaryEvent.longitude]
          : null,
        isDetention: isDetention,
        isEmphasized: emphasizedMilestone?.index === milestoneIndex && milestone.type === emphasizedMilestone?.type,
        facility: {
          label: location.locationType.toUpperCase() + ' ' + String.fromCharCode(65 + index),
          facilityName: location.customer?.name,
          appointmentTimeIn: location.appointmentStart,
          appotinmentTimeOut: location.appointmentEnd,
          number: location.customer?.number,
        },
        locationId: location.id,
        milestoneId: milestone?.id,
      };
    });

    if (dispatchMilestone) {
      return { dispatch: dispatchWaypoint, location: locationWaypoints };
    } else return { dispatch: null, location: locationWaypoints };
  }

  getLastMilestoneLog(milestones: Milestone[]): MilestoneLog {
    const lastMilestone = this.getLastMilestone(milestones);

    if (lastMilestone) {
      const logs = lastMilestone.logs;
      const lastLog = logs.reduce((a: MilestoneLog, b: MilestoneLog) => {
        if (!a) return b;

        if (a.timestamp > b.timestamp) return a;
        else return b;
      }, null);

      return lastLog;
    } else return null;
  }

  getLastMilestoneComment(milestones: Milestone[]): MilestoneComment {
    const lastMilestone = this.getLastMilestone(milestones);

    if (lastMilestone) {
      const comments = lastMilestone.comments;
      const lastComment = comments.reduce((a: MilestoneComment, b: MilestoneComment) => {
        if (!a) return b;

        if (a.timestamp > b.timestamp) return a;
        else return b;
      }, null);

      return lastComment;
    } else return null;
  }

  getLatestKnownLocation(milestones: Milestone[], load: LoadsServiceLoad): LngLatLike {
    if (load.loadStatus === LoadsServiceLoadStatus.FINALLED) {
      return null;
    }
    const lastLog = this.getLastMilestoneLog(milestones);
    if (lastLog) {
      return [lastLog.primaryEvent.longitude, lastLog.primaryEvent.latitude];
    } else {
      return null;
    }
  }

  getIsRouteDelayed(milestones: Milestone[], load: LoadsServiceLoad): boolean {
    return !this.getIsOnTime(milestones, load);
  }

  getMilestoneHeaderInformation(
    milestones: Milestone[],
    load: LoadsServiceLoad,
    totalDistance: number,
    truckPosition: LngLatLike,
    waypoints: RouteWaypoints[],
    remainingDistance: number
  ): LoadMilestoneInfo {
    const lastLog = this.getLastMilestoneLog(milestones);
    return {
      currentLocationName: this.getCurrentLocationName(load, milestones, truckPosition, waypoints),
      displayStatus: this.getDisplayStatus(milestones, load),
      isOnTime: this.getIsOnTime(milestones, load),
      lastUpdateDisplayTime: this.getLastUpdateDisplayTime(milestones, load, lastLog),
      milesCompleted: this.getMilesCompleted(milestones, totalDistance, remainingDistance),
      totalMiles: this.getTotalMiles(totalDistance),
      trackingType: this.getTrackingType(load, milestones),
    };
  }

  getTrackingType(load: LoadsServiceLoad, milestones: Milestone[]): string {
    const lastMilestone = this.getLastMilestone(milestones);
    if (load.trackingType && lastMilestone && lastMilestone.type === MilestoneType.LOCATION) {
      if (load.trackingType === TrackingType.DRIVER_LITE) return 'Haulynx Driver Lite';
      else if (load.trackingType === TrackingType.MACRO_POINT) return 'Macropoint';
      else if (load.trackingType === TrackingType.MANUAL) return 'Manual';
      else if (load.trackingType === TrackingType.PHONE) return 'Haulynx Mobile';
      else if (load.trackingType === TrackingType.MACRO_POINT_ELD) return 'Macropoint Eld';
      else return '';
    } else {
      return '';
    }
  }

  /**
   * Gathers route coordinates for a load via `getLoadServiceLoadWayPointsCoordinates`.
   * Caches coordinate computations to save processing time.
   * @param load
   * @returns A semi-colon-separateed list of lon, lat pairs as a string.
   */
  computeLoadRouteCoordinates(load: LoadsServiceLoad): string {
    if (load) {
      const coordinates = this.loadCoordinatesCache?.[load.id]
        ? this.loadCoordinatesCache[load.id]
        : getLoadServiceLoadWayPointsCoordinates(load);

      this.loadCoordinatesCache[load.id] = coordinates;
      return coordinates;
    } else {
      return null;
    }
  }

  getCurrentLocationName(
    load: LoadsServiceLoad,
    milestones: Milestone[],
    truckPosition: LngLatLike,
    waypoints: RouteWaypoints[]
  ): string {
    if (milestones && milestones.length > 0 && truckPosition && waypoints && waypoints.length > 0) {
      let waypoint;
      waypoints.forEach((way) => {
        if (way.name !== '') {
          waypoint = way;
        }
      });
      if (waypoint) {
        return waypoint.name;
      } else {
        const checkpointMilestones = milestones.filter(
          (milestone: Milestone) => milestone.type === MilestoneType.CHECKPOINT
        );
        const lastMilestone = this.getLastMilestone(checkpointMilestones);
        const loadLocation = load.locations.find(
          (location: LoadsServiceLoadLocation) => location.id === lastMilestone.locationId
        );
        if (checkpointMilestones.length > 0 && loadLocation) {
          return `${loadLocation.city}, ${loadLocation.state} ${loadLocation.zipcode}`;
        } else {
          return '-';
        }
      }
    } else {
      return '-';
    }
  }

  getDisplayStatus(milestones: Milestone[], load: LoadsServiceLoad): string {
    if (
      load.loadStatus === LoadsServiceLoadStatus.UNASSIGNED &&
      (load.bookStatus === BookStatus.BOOKABLE || load.bookStatus === BookStatus.VIEWABLE)
    )
      return 'Available';
    else if (load.loadStatus === LoadsServiceLoadStatus.UNASSIGNED && load.bookStatus === BookStatus.BOOKED)
      return 'Awaiting Truck Details';
    else if (load.loadStatus === LoadsServiceLoadStatus.ASSIGNED) return 'Ready for Dispatch';
    else if (load.loadStatus === LoadsServiceLoadStatus.FINALLED) return 'Finalled';
    else if (load.loadStatus === LoadsServiceLoadStatus.DISPATCHED) return 'Dispatched';
    else if (load.loadStatus === LoadsServiceLoadStatus.AT_SHIPPER) return 'At Pickup';
    else if (load.loadStatus === LoadsServiceLoadStatus.PICKED_UP) return 'In Transit';
    else if (load.loadStatus === LoadsServiceLoadStatus.AT_RECEIVER) return 'At Dropoff';
    else if (load.loadStatus === LoadsServiceLoadStatus.DELIVERED) return 'Delivered';
  }

  public getIsOnTime(milestones: Milestone[], load: LoadsServiceLoad): boolean {
    if (milestones) {
      const displayStatus = this.getDisplayStatus(milestones, load);
      const lastCheckpointMilestone = this.getLastMilestone(
        milestones.filter((milestone: Milestone) => milestone.type === MilestoneType.CHECKPOINT)
      );

      let location: LoadsServiceLoadLocation;
      let log: MilestoneLog;

      if (displayStatus === 'At Pickup' || displayStatus === 'At Dropoff') {
        location = load.locations.find(
          (location: LoadsServiceLoadLocation) => location.id === lastCheckpointMilestone?.locationId
        );
        const event: MilestoneEvent = { latitude: 0, longitude: 0, timestamp: new Date().valueOf(), timezone: '' };
        log = {
          ...lastCheckpointMilestone?.logs.find((log: MilestoneLog) => log.editedByType !== MilestoneUser.SYSTEM),
          primaryEvent: event,
        };
      } else if (displayStatus === 'In Transit') {
        // get the last dropoff location
        location = load.locations
          .filter((location: LoadsServiceLoadLocation) => location.locationType === LoadLocationType.DROPOFF)
          .reduce((acc, location) => {
            if (!acc) return location;
            if (acc.appointmentStart > location.appointmentStart) return acc;
            return location;
          }, null);
        const event: MilestoneEvent = { latitude: 0, longitude: 0, timestamp: new Date().valueOf(), timezone: '' };
        log = { ...this.getLastMilestoneLog(milestones), primaryEvent: event };
      }

      if (location && log) {
        if (location.appointmentEnd) {
          if (log.primaryEvent.timestamp > location.appointmentEnd) {
            return false;
          } else return true;
        } else {
          if (log.primaryEvent.timestamp > location.appointmentStart) {
            return false;
          } else return true;
        }
      }
      return true;
    } else {
      return true;
    }
  }

  getLastUpdateDisplayTime(milestones: Milestone[], load: LoadsServiceLoad, lastLog: MilestoneLog): number {
    if ((!milestones || milestones.length === 0) && !lastLog) {
      if (load) {
        return load.paymentDetails.bookedAt ? load.paymentDetails.bookedAt : load.locations[0].appointmentStart;
      } else {
        return new Date().valueOf();
      }
    } else {
      return lastLog.timestamp;
    }
  }

  getMilesCompleted(milestones: Milestone[], totalDistanceMeters: number, remainingDistanceMeters: number): number {
    if (milestones?.length > 0) {
      const miles = Math.round((totalDistanceMeters - remainingDistanceMeters) / this.conversionFactorMetersToMiles);
      return miles >= 0 ? miles : 0;
    } else {
      return 0;
    }
  }

  getTotalMiles(totalDistanceMeters: number): number {
    if (totalDistanceMeters) {
      return Math.round(totalDistanceMeters / this.conversionFactorMetersToMiles);
    } else {
      return null;
    }
  }
}
