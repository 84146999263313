<div class="dialog-template" fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="end center" class="close">
    <button fxFlexAlign="end" mat-icon-button (click)="close()"><mat-icon>close</mat-icon></button>
  </div>
  <div fxLayout="column" fxLayoutGap="16px">
    <div class="title" mat-dialog-title>
      <ng-content select="[title]"></ng-content>
    </div>
    <mat-dialog-content>
      <div class="content">
        <ng-content select="[content]"></ng-content>
      </div>
    </mat-dialog-content>
  </div>
</div>
