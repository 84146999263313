import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FeatureFlagDashboardEffects } from './feature-flag-dashboard.effects';
import { featureFlagDashboardReducer } from './feature-flag-dashboard.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('feature-flag-dashboard', featureFlagDashboardReducer),
    EffectsModule.forFeature([FeatureFlagDashboardEffects]),
  ],
})
export class FeatureFlagDashboardStoreModule {}
