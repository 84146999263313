<div class="search-google-dropdown-container" #wrapper>
  <ng-container *ngIf="focusedFilter.type !== filterType.MULTI_DROPDOWN && focusedFilter.type !== filterType.BOOLEAN">
    <div
      class="sgd-place-options"
      *ngFor="let option of dropdownValues | optionSort: currentTextValue; let i = index"
      [class.isHovering]="i === indexOfFocus"
      (click)="onSelectOption(i)"
      (mouseenter)="indexOfFocus = i; isMouseFocus = true"
      (mouseleave)="indexOfFocus = -1; isMouseFocus = false"
      [scrollIntoView]="i === indexOfFocus && !isMouseFocus"
    >
      <div class="sgd-place-value" attr.data-test="{{ option.key }}">
        {{ option.key }}
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="focusedFilter.type === filterType.MULTI_DROPDOWN">
    <div (click)="$event.stopImmediatePropagation()" (keyup)="$event.stopImmediatePropagation()">
      <div class="sgd-place-header">
        <mat-form-field class="app-form-field sgd-place-header__form-field" appearance="fill" floatLabel="always">
          <input class="sgd-place-header__input" #currentValue matInput autocomplete="off" placeholder="Search" />
          <mat-icon class="muted" matSuffix>search</mat-icon>
        </mat-form-field>
        <div class="sgd-place-header__subheader">
          <div>{{ optionCount }} options Selected</div>
          <button mat-icon-button class="sgd-place-header__close" (click)="onCloseSearchOptions()">Clear all</button>
        </div>
      </div>
      <div
        class="sgd-place-options"
        *ngFor="let option of dropdownValues | optionSort: currentValue.value; let i = index"
        [class.isHovering]="i === indexOfFocus"
        (click)="onToggleMultiOption(i)"
        (mouseenter)="indexOfFocus = i; isMouseFocus = true"
        (mouseleave)="indexOfFocus = -1; isMouseFocus = false"
        [scrollIntoView]="i === indexOfFocus && !isMouseFocus"
      >
        <div class="sgd-place-value">
          <mat-checkbox
            [(ngModel)]="option.isChecked"
            (ngModelChange)="onSelectMultiOption()"
            (click)="$event.stopImmediatePropagation()"
            >{{ option.key }}</mat-checkbox
          >
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="focusedFilter.type === filterType.BOOLEAN">
    <div (click)="$event.stopImmediatePropagation()" (keyup)="$event.stopImmediatePropagation()">
      <div class="sgd-boolean-options">
        <mat-radio-group aria-label="Select an option" [(ngModel)]="booleanDefault">
          <mat-radio-button [value]="true" (change)="onSelectBooleanOption(true)">Yes</mat-radio-button>
          <mat-radio-button [value]="false" (change)="onSelectBooleanOption(false)">No</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </ng-container>
</div>
