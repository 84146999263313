<div class="app-notification-item" data-test="notification-item">
  <div *ngIf="title" [attr.data-test]="'title'" class="app-notification-item__timeline-title">
    {{ title }}
  </div>

  <div class="app-notification-item__header">
    <div *ngIf="active" [attr.data-test]="'active'" class="app-notification-item__active"></div>

    <div class="app-notification-item__title">
      <ng-content select="[appNotificationTitle]"></ng-content>
    </div>

    <div class="app-notification-item__date">
      {{ dateCreated }}
    </div>
  </div>

  <div class="app-notification-item__content">
    <ng-content select="[appNotificationContent]"></ng-content>
  </div>

  <div class="app-notification-item__footer">
    <div class="app-notification-item__tag highlight-label">
      <ng-content select="[appNotificationFooter]"></ng-content>
    </div>
  </div>
</div>
