import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'haulynx-dialog-template',
  templateUrl: './dialog-template.component.html',
  styleUrls: ['./dialog-template.component.scss'],
})
export class DialogTemplateComponent {
  @Output() closed = new EventEmitter<void>();

  public close() {
    this.closed.emit();
  }
}
