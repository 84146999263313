<app-spinner
  [active]="(releasesService.isLoadingDetails$ | async) || (releasesService.isLoadingCreate$ | async)"
  [fullCover]="true"
></app-spinner>

<div class="release-notes">
  <h3 class="release-notes__title">Release Notes</h3>
  <form [formGroup]="form">
    <div class="release-notes__row">
      <div class="release-notes__cell">
        <mat-form-field
          class="app-form-field release-notes__field"
          hideRequiredMarker
          appearance="fill"
          floatLabel="always"
        >
          <mat-label class="release-notes__label"> Release title </mat-label>
          <input matInput formControlName="title" autocomplete="off" placeholder="Release title" maxlength="30" />
        </mat-form-field>
      </div>

      <span class="release-notes__spacer"></span>
      <div class="release-notes__cell">
        <mat-form-field
          class="app-form-field release-notes__field"
          hideRequiredMarker
          appearance="fill"
          floatLabel="always"
        >
          <mat-label class="release-notes__label"> Release type </mat-label>
          <mat-select formControlName="userType" placeholder="Release type">
            <mat-option [value]="releaseTypes.BROKER">{{ releaseTypes.BROKER | titlecase }}</mat-option>
            <mat-option [value]="releaseTypes.CARRIER">{{ releaseTypes.CARRIER | titlecase }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="release-notes__row">
      <div class="release-notes__cell">
        <div class="release-notes__datepicker">
          Release published
          <br />
          <app-datetime-picker
            class="app-form-field"
            formControlName="date"
            [timeZone]="appModel.userTimeZone$ | async"
          ></app-datetime-picker>
        </div>
      </div>
      <span class="release-notes__spacer"></span>
      <div class="release-notes__cell">
        <div class="release-notes__datepicker">
          Release expires
          <br />
          <app-datetime-picker
            class="app-form-field"
            formControlName="expiration"
            [timeZone]="appModel.userTimeZone$ | async"
          ></app-datetime-picker>
        </div>
      </div>
    </div>

    <mat-tab-group [selectedIndex]="activeTab$ | async" (selectedIndexChange)="setActiveTab($event)">
      <mat-tab *ngFor="let page of pages.controls; let i = index">
        <ng-template mat-tab-label>
          Page {{ i + 1 }}
          <mat-icon *ngIf="i > 0" (click)="removePage(i)" class="release-notes__icon--delete"> close </mat-icon>
        </ng-template>

        <div formArrayName="pages">
          <ng-container [formGroupName]="i">
            <br />
            <br />
            <div class="release-notes__row">
              <div class="release-notes__cell">
                <mat-form-field
                  class="app-form-field release-notes__field"
                  hideRequiredMarker
                  appearance="fill"
                  floatLabel="always"
                >
                  <mat-label class="release-notes__label">Title</mat-label>
                  <input matInput formControlName="title" autocomplete="off" placeholder="Title" maxlength="30" />
                </mat-form-field>
              </div>

              <span class="release-notes__spacer"></span>

              <div class="release-notes__cell">
                <mat-form-field
                  class="app-form-field release-notes__field"
                  hideRequiredMarker
                  appearance="fill"
                  floatLabel="always"
                >
                  <mat-label class="release-notes__label">Description</mat-label>
                  <input
                    matInput
                    formControlName="description"
                    autocomplete="off"
                    placeholder="Description"
                    maxlength="100"
                  />
                </mat-form-field>
              </div>
            </div>

            <ng-container formGroupName="media">
              <div class="release-notes__row">
                <div class="release-notes__cell">
                  <input
                    #imageInput
                    class="release-notes__file"
                    type="file"
                    (change)="onImageSelect($event, i)"
                    data-test="image-selector"
                  />
                  <button mat-button class="hlx-button-command release-notes__image" (click)="imageInput.click()">
                    Select image (900 x 560)
                  </button>
                </div>

                <span class="release-notes__spacer">or</span>

                <div class="release-notes__cell">
                  <mat-form-field
                    class="app-form-field release-notes__field release-notes__field--short"
                    hideRequiredMarker
                    appearance="fill"
                    floatLabel="always"
                  >
                    <input
                      #videoInput
                      matInput
                      autocomplete="off"
                      placeholder="Enter video url"
                      [value]="page.value.media.type === mediaTypes.VIDEO ? page.value.media.data : null"
                      (change)="onVideoSelect($event, i)"
                      data-test="video-selector"
                    />
                  </mat-form-field>
                </div>
              </div>
            </ng-container>

            <div class="release-notes__row release-notes__row--last release-notes__row--preview">
              <div *ngIf="page.value.media.data" class="release-notes__row--preview--wrapper">
                <ng-container *ngIf="page.value.media.type === mediaTypes.IMAGE">
                  <img [src]="page.value.media.data" (error)="onImageError($event, i)" />

                  <div class="release-notes__row--preview--error" [ngClass]="{ hidden: !mediaError[i] }">
                    <mat-icon>report_problem</mat-icon>
                    <p>Image not available, it may have been moved or deleted</p>
                  </div>
                </ng-container>

                <div *ngIf="page.value.media.type === mediaTypes.VIDEO">
                  <iframe
                    [src]="page.value.media.data | safeUrl"
                    frameborder="0"
                    width="444"
                    height="250"
                    allowfullscreen
                  ></iframe>
                </div>

                <button
                  *ngIf="page.value.media.data"
                  (click)="deleteMedia(i)"
                  mat-icon-button
                  class="hlx-button-command release-notes__icon--delete release-notes__icon--delete--media"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </div>
            </div>
          </ng-container>
        </div>
      </mat-tab>
    </mat-tab-group>

    <mat-divider></mat-divider>
    <br />

    <div class="release-notes__action">
      <button mat-button class="hlx-button-command release-notes__add-page" (click)="addPage()">
        <mat-icon class="release-notes__icon--add">add</mat-icon>
        Add new page
      </button>

      <button
        mat-dialog-close
        class="hlx-button-command hlx-button-command--cancel hlx-button-command--border-white release-notes__button"
      >
        Close
      </button>

      <button
        type="submit"
        class="hlx-button-command hlx-button-command--book release-notes__button"
        (click)="onSaveReleaseNotes()"
        [disabled]="form.invalid"
      >
        Save
      </button>
    </div>
  </form>
</div>
