import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from '@haulynx/services';
import { LoadsServiceLoad } from '@haulynx/types';
import { carrierAcceptedLoad, isBrokerLoad } from '@haulynx/utils';
import { get, head, last, reverse, split } from 'lodash';

@Pipe({ name: 'loadAddress' })
export class LoadAddressPipe implements PipeTransform {
  private user;
  constructor(private userService: UserService) {
    this.userService.user.subscribe((data) => {
      this.user = data;
    });
  }

  transform(load: LoadsServiceLoad, position: number, type?: string): any {
    const carrierId = get(this.user, 'carrier.id', null);
    const isAcceptedLoad = carrierAcceptedLoad(load, carrierId);

    const brokerId = get(this.user, 'broker.id', null);
    const isBrokersLoad = isBrokerLoad(load, brokerId);

    let address = '';

    const locationNameField = carrierId ? 'address' : 'name';

    switch (position) {
      case 0:
        {
          address = get(head(load.locations), locationNameField, '');
        }
        break;
      case -1:
        {
          address = get(last(load.locations), locationNameField, '');
        }
        break;
      default:
        {
          address = get(load.locations, `[${position}][${locationNameField}]`, '');
        }
        break;
    }

    const [stateZip, city, street] = reverse(split(address, ','));

    const result = [];

    if (city) {
      result.push(city);
    }

    if (stateZip) {
      result.push(stateZip);
    }

    if (type === 'cityStateZip') {
      return result.join(',');
    }

    if (type === 'address') {
      return street;
    }

    return isBrokersLoad || isAcceptedLoad ? address : result.join(',');
  }
}
