<div #wrapper class="cvc">
  <div
    class="column-visibility-container"
    (click)="toggleVisibilityContainer()"
    [ngClass]="{ 'column-visibility-container--open': isContainerOpen }"
    data-test="column-visibility-settings"
  >
    <img src="icons/other/settings-icon.svg" width="22" height="22" alt="Columns visibility settings" />
  </div>
  <div class="column-visibility-dropdown" *ngIf="isContainerOpen" (click)="$event.stopImmediatePropagation()">
    <div class="column-visibility-dropdown__wrapper">
      <h3 class="column-visibility-dropdown__title">Visible Table Columns</h3>

      <div class="column-visibility-dropdown__list">
        <div class="column-visibility-dropdown__subtitle" *ngIf="columnsToShow.length">Included In Current View</div>

        <div cdkDropList (cdkDropListDropped)="onDrop($event)">
          <div
            *ngFor="let column of columnsToShow; let i = index"
            class="column-visibility-dropdown__item"
            cdkDrag
            [cdkDragDisabled]="column.isPinned"
            [ngClass]="{ 'column-visibility-dropdown__item--hover': i === indexOfFocus }"
            haulynxFocusIntoView
            [focusIntoView]="indexOfFocus === i && !isMouseFocus"
            (mouseenter)="indexOfFocus = i; isMouseFocus = true"
            (mouseleave)="indexOfFocus = -1; isMouseFocus = false"
            (click)="onToggleSelection($event, i, columnVisibilityTypes.VISIBLE)"
            (keydown)="$event.stopImmediatePropagation()"
          >
            <p-checkbox
              class="column-visibility-dropdown__checkbox"
              [binary]="true"
              label="{{ column?.label | stripHtml: ' / ' }} {{
                column?.hasFilterSuggestion ? ' - [ Currently a search filter ]' : ''
              }}"
              [disabled]="column.isPinned"
              [ngClass]="{ 'column-visibility-dropdown__checkbox--filter': column?.hasFilterSuggestion }"
              [(ngModel)]="column.isVisible"
              (ngModelChange)="onModelChange(i, columnVisibilityTypes.VISIBLE)"
              data-test="included-checkbox"
            ></p-checkbox>
            <mat-icon *ngIf="!column.isPinned" cdkDragHandle>menu</mat-icon>
          </div>
        </div>

        <div
          class="column-visibility-dropdown__subtitle column-visibility-dropdown__subtitle--margin"
          *ngIf="columnsToHide.length"
        >
          Not Included in Current View
        </div>

        <div
          *ngFor="let column of columnsToHide; let i = index"
          class="column-visibility-dropdown__item"
          [ngClass]="{ 'column-visibility-dropdown__item--hover': i + columnsToShow.length === indexOfFocus }"
          haulynxFocusIntoView
          [focusIntoView]="indexOfFocus === i + columnsToShow.length && !isMouseFocus"
          (mouseenter)="indexOfFocus = i + columnsToShow.length; isMouseFocus = true"
          (mouseleave)="indexOfFocus = -1; isMouseFocus = false"
          (click)="onToggleSelection($event, i, columnVisibilityTypes.HIDDEN)"
        >
          <p-checkbox
            class="column-visibility-dropdown__checkbox"
            [binary]="true"
            label="{{ column?.label | stripHtml: ' / ' }} {{
              column?.hasFilterSuggestion ? ' - [ Currently a search filter ]' : ''
            }}"
            [disabled]="column.isPinned"
            [ngClass]="{ 'column-visibility-dropdown__checkbox--filter': column?.hasFilterSuggestion }"
            [(ngModel)]="column.isVisible"
            (ngModelChange)="onModelChange(i, columnVisibilityTypes.HIDDEN)"
            data-test="not-included-checkbox"
          ></p-checkbox>
        </div>
      </div>

      <div class="column-visibility-dropdown__footer">
        <div class="column-visibility-dropdown__button--reset" (click)="onColumnsReset()" data-test="reset-defaults">Reset Defaults</div>
        <div *ngIf="selectedSidebarItem?.label !== 'All Loads'">
          <button
            mat-button
            class="hlx-button-command hlx-button-command--cancel hlx-button-command--border-white"
            (click)="onCancelButton()"
            data-test="cancel-button"
          >
            Cancel
          </button>
          <button
            mat-button
            class="hlx-button-command hlx-button-command--book column-visibility-dropdown__button--save"
            (click)="onSaveSearchView()"
            data-test="save-view-button"
          >
            Save View
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
