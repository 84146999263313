import { Injectable } from '@angular/core';
import { LoadsServiceService, NotificationsService } from '@haulynx/services';
import { User } from '@haulynx/types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import moment from 'moment';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppModel } from '../../main-store/app-model';
import { DispatchAction } from '../../shared/store/helpers/action';
import { LoadPriceActions, LoadPriceActionTypes } from './load-price.actions';

@Injectable()
export class LoadPriceEffects {
  loadSetPrice = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadPriceActionTypes.SET_PRICE),
      map((action: DispatchAction) => action.payload),
      withLatestFrom(this.appModel.user$),
      mergeMap(
        ([payload, user]: [{ loadId: string; price: number; maxBuy: number; type: 'price' | 'maxBuy' }, User]) => {
          const { loadId, price, maxBuy, type } = payload;
          const variables = {
            loadId,
            newPrice: price,
            newMaxBuy: maxBuy,
            user: user.email,
            timestamp: moment().valueOf(),
            type,
          };
          return this.loadsServiceService.setBrokerPrice(variables).pipe(
            map((data: unknown) => {
              if (variables.type === 'maxBuy') {
                this.notificationsService.success('Max Buy was successfully updated!', 'Success');
                return LoadPriceActions.setPriceSuccess({ loadId });
              } else {
                this.notificationsService.success('Price was successfully updated!', 'Success');
                return LoadPriceActions.setPriceSuccess({ loadId });
              }
            }),
            catchError((errors) => {
              this.notificationsService.error(errors.message, 'Error');
              return of(LoadPriceActions.setPriceError({ loadId }));
            })
          );
        }
      )
    )
  );

  constructor(
    private actions$: Actions,
    private loadsServiceService: LoadsServiceService,
    private notificationsService: NotificationsService,
    private appModel: AppModel
  ) {}
}
