import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CarrierInsuranceAndSafety } from '@haulynx/types';

@Component({
  selector: 'app-carrier-analytics-insurance',
  templateUrl: './carrier-analytics-insurance.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarrierAnalyticsInsuranceComponent {
  @Input() isLoading: boolean;
  @Input() insurance: CarrierInsuranceAndSafety;
  constructor() {}
}
