import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DirectivesModule } from '@haulynx/directives';
import { MaterialModule } from '@haulynx/modules';
import { IconModule } from '../../icon/icon.module';
import { SpinnerModule } from '../../spinner/spinner.module';
import { WhiteOutlineButtonModule } from '../../white-outline-button/white-outline-button.module';
import { CarouselModule } from '../carousel/carousel.module';
import { CarrierBannerComponent } from './carrier-banner.component';

@NgModule({
  declarations: [CarrierBannerComponent],
  imports: [
    CommonModule,
    DirectivesModule,
    FlexLayoutModule,
    MaterialModule,
    IconModule,
    SpinnerModule,
    WhiteOutlineButtonModule,
    CarouselModule,
  ],
  exports: [CarrierBannerComponent],
})
export class CarrierBannerModule {}
