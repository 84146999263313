import gql from 'graphql-tag';
import { MINIMAL_CARRIER_BID_DETAILS_FRAGMENT } from './fragments';
import { MINIMAL_LOAD_DETAILS_FRAGMENT } from './fragments/minimal-load-details.fragment';

export const CarrierLoadSearchGetBidsAndOffers = (biddingEnabled: boolean) =>
  gql`
    query CarrierLoadSearchGetBidsAndOffers($dot: StrictId! ${biddingEnabled ? ', $carrierId: StrictId!' : ''}) {
      offers: getLoadsBrokered(filterParams: { dot: $dot, onlyActiveLoads: true }, paging: { limit: 100 }) {
        result {
          loadsServiceLoad {
            ...minimalLoadDetails
            brokerOfferForCarrier {
              price
            }
            carrierBid {
              ...minimalCarrierBidDetails
            }
          }
        }
      }
      ${
        biddingEnabled
          ? `bids: getBidsByCarrierId(carrierId: $carrierId, onlyActiveBids: true) {
              loadsServiceLoad {
                ...minimalLoadDetails
                brokerOfferForCarrier {
                  price
                }
                carrierBid {
                  ...minimalCarrierBidDetails
                }
              }
            }`
          : ''
      }
    }
    ${MINIMAL_LOAD_DETAILS_FRAGMENT},
    ${MINIMAL_CARRIER_BID_DETAILS_FRAGMENT}
`;
