import { LAZY_MAPS_API_CONFIG } from '@agm/core';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import {
  ActionButtonsModule,
  AppNotificationModule,
  ArchiveLoadCompleteDialog,
  ArchiveLoadDialog,
  ConfirmDeliveryDialog,
  DataTableModule,
  DatepickerModule,
  DeleteLoadTemplateDialog,
  DevicesComponent,
  DropDownModule,
  EditCarrierComponent,
  EditDeviceDialog,
  ImeiUploadComponentComponent,
  InvitationsComponent,
  InviteComponentComponent,
  LoadsLogsViewerComponent,
  NewDeviceDialog,
  NewTruckDialog,
  RadioGroupModule,
  SideNavModule,
  SimulatePositionComponent,
  SpinnerModule,
  TourModule,
  TrailerDeleteDialog,
  TrailerFormDialog,
} from '@haulynx/components';
import { BrokerLoadsGuard, CarrierLoadsGuard, LoadDetailsGuard } from '@haulynx/guards';
import { MaterialModule } from '@haulynx/modules';
import { DashboardStoreModule } from '@haulynx/store';
import { Environment } from '@haulynx/types';
import { ChartsModule } from 'ng2-charts';
import { SharedModule } from 'primeng/api';
import { AccountModule } from '../account/account.module';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    DashboardRoutingModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    ChartsModule,
    DatepickerModule,
    ReactiveFormsModule,
    CdkTableModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    AgmJsMarkerClustererModule,
    ActionButtonsModule,
    DataTableModule,
    DropDownModule,
    SpinnerModule,
    RadioGroupModule,
    AccountModule,
    SideNavModule,
    TourModule,
    AppNotificationModule,
    DashboardStoreModule,
  ],
  declarations: [
    ArchiveLoadDialog,
    ArchiveLoadCompleteDialog,
    DashboardComponent,
    DevicesComponent,
    EditDeviceDialog,
    TrailerFormDialog,
    TrailerDeleteDialog,
    NewTruckDialog,
    NewDeviceDialog,
    NewDeviceDialog,
    ConfirmDeliveryDialog,
    ArchiveLoadDialog,
    ArchiveLoadCompleteDialog,
    SimulatePositionComponent,
    LoadsLogsViewerComponent,
    EditCarrierComponent,
    DeleteLoadTemplateDialog,
    InviteComponentComponent,
    InvitationsComponent,
    ImeiUploadComponentComponent,
  ],
  providers: [
    BrokerLoadsGuard,
    CarrierLoadsGuard,
    LoadDetailsGuard,
    {
      provide: LAZY_MAPS_API_CONFIG,
      useFactory: (environment: Environment) => {
        return {
          apiKey: environment.google.mapsApi,
          libraries: ['places'],
        };
      },
      deps: [Environment],
    },
  ],
})
export class DashboardModule {}
