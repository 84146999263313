import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { WindowRef } from '@haulynx/services';

@Directive({
  selector: '[tooltip]',
})
export class TooltipDirective {
  @Input('tooltip') tooltipTitle: string;
  @Input() tooltipPosition = 'bottom';
  @Input() tooltipDelay = 200;
  tooltip: HTMLElement;
  offset = 5;

  constructor(private el: ElementRef, private renderer: Renderer2, private windowRef: WindowRef) {}

  @HostListener('mouseenter') onMouseEnter() {
    if (!this.tooltip && this.tooltipTitle) {
      this.show();
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (this.tooltip) {
      this.hide();
    }
  }

  show() {
    this.create();
    this.setPosition();
    this.renderer.addClass(this.tooltip, 'ng-tooltip-show');
  }

  hide() {
    this.renderer.removeClass(this.tooltip, 'ng-tooltip-show');
    this.windowRef.getNativeWindow().setTimeout(() => {
      this.renderer.removeChild(document.body, this.tooltip);
      this.tooltip = null;
    }, this.tooltipDelay);
  }

  create() {
    this.tooltip = this.renderer.createElement('span');

    this.renderer.appendChild(this.tooltip, this.renderer.createText(this.tooltipTitle));

    this.renderer.appendChild(document.body, this.tooltip);

    this.renderer.addClass(this.tooltip, 'ng-tooltip');
    this.renderer.addClass(this.tooltip, `ng-tooltip-${this.tooltipPosition}`);

    this.renderer.setStyle(this.tooltip, '-webkit-transition', `opacity ${this.tooltipDelay}ms`);
    this.renderer.setStyle(this.tooltip, '-moz-transition', `opacity ${this.tooltipDelay}ms`);
    this.renderer.setStyle(this.tooltip, '-o-transition', `opacity ${this.tooltipDelay}ms`);
    this.renderer.setStyle(this.tooltip, 'transition', `opacity ${this.tooltipDelay}ms`);
    this.renderer.setStyle(this.tooltip, 'color', '#ffffff');
  }

  setPosition() {
    const hostPos = this.el.nativeElement.getBoundingClientRect();

    const tooltipPos = this.tooltip.getBoundingClientRect();

    const scrollPos =
      this.windowRef.getNativeWindow().pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    let top, left;

    if (this.tooltipPosition === 'top') {
      top = hostPos.top - tooltipPos.height - this.offset;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 5;
    }

    if (this.tooltipPosition === 'bottom') {
      top = hostPos.bottom + this.offset;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 5;
    }

    if (this.tooltipPosition === 'left') {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.left - tooltipPos.width - this.offset * 2;
    }

    if (this.tooltipPosition === 'right') {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.right + this.offset * 2;
    }

    this.renderer.setStyle(this.tooltip, 'top', `${top + scrollPos}px`);
    this.renderer.setStyle(this.tooltip, 'left', `${left}px`);
  }
}
