import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HeaderItem, RmisProps, User } from '@haulynx/types';
import { Observable } from 'rxjs';

@Component({
  selector: 'haulynx-header-endbuttons',
  templateUrl: './header-endbuttons.component.html',
  styleUrls: ['./header-endbuttons.component.scss'],
})
export class HeaderEndbuttonsComponent {
  @Input() user: User;
  @Input() headerItems: HeaderItem[] = [];
  @Input() totalNotifications: Observable<number>;
  @Input() rmisProps: RmisProps;

  @Output() showNotifications = new EventEmitter();
  @Output() goToHelp = new EventEmitter();
  @Output() openAccount = new EventEmitter();
  @Output() openOrders = new EventEmitter();
  @Output() openLogout = new EventEmitter();
  @Output() seletedHeaderItem = new EventEmitter<HeaderItem>();
  @Output() complete = new EventEmitter<boolean>();
  @Output() showVerifyEmail = new EventEmitter();
  @Output() continueRmis = new EventEmitter();

  constructor() {}

  onGoToHelp(): void {
    this.goToHelp.emit();
  }

  onLogout(): void {
    this.openLogout.emit();
  }

  onShowVerifyEmail(): void {
    this.showVerifyEmail.emit();
  }

  onShowNotifications(): void {
    this.showNotifications.emit();
  }

  headerItemSelected(item: HeaderItem): void {
    this.seletedHeaderItem.emit(item);
  }

  clickCompleted() {
    this.complete.emit();
  }

  clickContinueRmis(): void {
    this.continueRmis.emit();
  }
}
