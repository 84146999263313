import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CommonEntitiesEffects } from '../../shared/common-entities/common-entities.effects';
import { laneHistoryNamespace } from '../namespace';
import { laneHistoryStateProviders, LANE_HISTORY_ENTITY_REDUCERS_TOKEN } from './config';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(laneHistoryNamespace, LANE_HISTORY_ENTITY_REDUCERS_TOKEN),
    EffectsModule.forFeature([CommonEntitiesEffects]),
  ],
  providers: [...laneHistoryStateProviders],
})
export class AsyncEntityLaneHistoryStoreModule {}
