<div class="blue-input">
  <mat-form-field appearance="outline" floatLabel="always" [hideRequiredMarker]="!required">
    <mat-label>{{ label }}</mat-label>
    <input
      matInput
      required
      [type]="type"
      [formControl]="control"
      [attr.maxlength]="maxlength"
      [attr.minlength]="minlength"
      [ngClass]="{ 'shift-right': prefix }"
    />
    <div data-cy="input-prefix" class="prefix" *ngIf="prefix" fxLayout="row" fxLayoutAlign="center center">
      <div>{{ prefix }}</div>
    </div>
    <mat-hint>
      <ng-content select="[mat-hint]"></ng-content>
    </mat-hint>
    <mat-hint align="end">
      <ng-content select="[mat-hint-end]"></ng-content>
    </mat-hint>
    <mat-error>
      <ng-content select="[mat-error]"></ng-content>
    </mat-error>
  </mat-form-field>
</div>
