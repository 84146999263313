import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@haulynx/modules';
import { LogoutDialog } from './logout.component';

@NgModule({
  declarations: [LogoutDialog],
  imports: [CommonModule, MaterialModule],
  exports: [LogoutDialog],
})
export class LogoutModule {}
