export class LaneLocation {
  lat: number = null;
  lon: number = null;
  timestamp: number = null; // due date
  locationType: LaneLocationType = null;
  radius: number;
  daysOfWeek: string[];

  constructor(props?: Partial<LaneLocation>) {
    Object.assign(this, props);
  }
}

export enum LaneLocationType {
  DROPOFF = 'dropoff',
  PICKUP = 'pickup',
}
