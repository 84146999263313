<div class="active-loads-table" [ngClass]="{ 'active-loads-table__expended': !!selectedRow }">
  <app-spinner [active]="isLoading" [fullCover]="true"></app-spinner>
  <data-table
    [data]="data"
    [displayedColumns]="displayedColumns"
    [selection]="true"
    [multipleSelection]="multipleSelection"
    [stickyHeader]="stickyHeader"
    [pageOptions]="pageOptions"
    [selected]="selectedRow"
    [templateCellRef]="activeLoadsTemplateRef"
    [hideColumns]="hideColumns"
    [showPagination]="true"
    [lazyLoad]="lazyLoad"
    [pagination]="pagination"
    (onPagination)="onPagination($event)"
    (onSelect)="onSelect($event)"
    cls="active-loads"
    data-test="active-loads"
  >
    <ng-template #activeLoadsTemplateRef let-row="row" let-column="column">
      <ng-container *ngIf="column === 'icon'">
        <img
          *ngIf="row | loadStatus: loadStatusType.ICON"
          [attr.src]="'icons/other/haulynx-load-' + (row | loadStatus: loadStatusType.ICON:activeStepTypes) + '.svg'"
        />
      </ng-container>

      <ng-container *ngIf="column === 'status'">
        {{ row | loadStatus: loadStatusType.TEXT:activeStepTypes }}
      </ng-container>

      <ng-container *ngIf="column === 'tmwNumber'">
        {{ row | loadAlternateId: loadIdentifierType.TMW_NUMBER:'N/A' }}
      </ng-container>

      <ng-container *ngIf="column === 'origin'">
        {{ row.locations | nth: 0 | get: 'address':'N/A' | address: 3:true }}
      </ng-container>

      <ng-container *ngIf="column === 'originState'">
        {{ row.locations | nth: 0 | get: 'state':'N/A' }}
      </ng-container>

      <ng-container *ngIf="column === 'pickupDate'">
        {{ row.locations | nth: 0 | get: 'appointmentStart':'N/A' | dateTime: row.locations[0].timezone:'MM/dd/yy' }}
      </ng-container>

      <ng-container *ngIf="column === 'pickupTime'">
        {{ row.locations | nth: 0 | get: 'appointmentStart':'N/A' | dateTime: row.locations[0].timezone:'HH:mm z' }}
      </ng-container>

      <ng-container *ngIf="column === 'pickupType'">
        {{ row | get: 'providerDetails.equipmentType':'N/A' }}
      </ng-container>

      <ng-container *ngIf="column === 'destination'">
        {{ row.locations | nth: -1 | get: 'address':'N/A' | address: 3:true }}
      </ng-container>

      <ng-container *ngIf="column === 'destinationState'">
        {{ row.locations | nth: -1 | get: 'state':'N/A' }}
      </ng-container>

      <ng-container *ngIf="column === 'deliveryDate'">
        {{
          row.locations
            | nth: -1
            | get: 'appointmentStart':'N/A'
            | dateTime: row.locations[(row.locations?.length || 1) - 1]?.timezone:'MM/dd/yy'
        }}
      </ng-container>

      <ng-container *ngIf="column === 'deliveryTime'">
        {{
          row.locations
            | nth: -1
            | get: 'appointmentStart':'N/A'
            | dateTime: row.locations[(row.locations?.length || 1) - 1]?.timezone:'HH:mm z'
        }}
      </ng-container>

      <ng-container *ngIf="column === 'deliveryType'">
        {{ row | get: 'providerDetails.equipmentType':'N/A' }}
      </ng-container>

      <ng-container *ngIf="column === 'carrier'">
        {{ row | get: 'carrier.name':'N/A' }}
      </ng-container>

      <ng-container *ngIf="column === 'customer'">
        {{ row | get: 'providerDetails.billToName':'N/A' }}
      </ng-container>

      <ng-container *ngIf="column === 'broker'">
        {{ row | get: 'broker.name':'N/A' }}
      </ng-container>

      <ng-container *ngIf="column === 'region'">
        {{ row.providerDetails.regionalManagerEmail | regionByEmail }}
      </ng-container>
    </ng-template>
  </data-table>
</div>
