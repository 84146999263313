import gql from 'graphql-tag';

export const GetCarrierInsuranceAndSafetyQuery = gql(`
  query GetCarrierInsuranceAndSafety($dot: StrictId!) {
    getCarrierInsuranceAndSafety(dot: $dot) {
      generalInsuranceLimit,
      generalInsuranceExpDate,
      autoInsuranceLimit,
      autoInsuranceExpDate
      cargoInsuranceLimit,
      cargoInsuranceExpDate,
      safetyRating,
      error,
    }
  }
`);
