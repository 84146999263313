import { OWL_DATE_TIME_FORMATS } from '@danielmoncada/angular-datetime-picker';
import {
  LoadsServiceEquipmentType,
  LoadsServiceCustomer,
  LoadsServiceLoad,
  LoadsServiceLoadLocation,
  LoadsServiceLoadStatus,
} from '../../loads-service';
import { buttonTypes } from '../action-buttons';
import { Carrier } from '../carrier';
import { LoadLocationType } from './load-location';

export const loadTabsConfig = {
  AVAILABLE: {
    id: 'available',
    label: 'Available',
    url: 'search/all',
    order: 1,
  },
  ACTIVE: {
    id: 'active',
    label: 'Active',
    url: 'search/active',
    order: 2,
  },
  COMPLETED: {
    id: 'completed',
    label: 'Completed',
    url: 'search/completed',
    order: 5,
  },
  CONFIGURATION: {
    id: 'configuration',
    label: 'Load Distribution Configuration',
    url: 'configuration',
  },
};

export const loadActiveTabsConfig = {
  SEARCH: {
    id: 'search',
    label: 'All Active',
    url: '',
    order: 1,
    hideLabel: true,
  },
};

export const loadAvailableTabsConfig = {
  SEARCH: {
    id: 'search',
    label: 'All Available',
    url: '',
    order: 1,
    hideLabel: true,
  },
};

export const loadStatuses = ['Active', 'Unassigned', 'Completed'];

export const loadIcons = [
  'haulynx-box',
  'haulynx-clock',
  'haulynx-pick-up',
  'haulynx-phone-alt',
  'haulynx-pin',
  'haulynx-truck',
  'haulynx-car',
  'haulynx-geo',
  'haulynx-money',
  'haulynx-checked-white',
  'haulynx-checked-late',
  'haulynx-checked-completed',
  'haulynx-receipt-alt',
  'haulynx-red-cross',
  'haulynx-not-assigned',
  'haulynx-awaiting-delivery',
  'haulynx-load-accepted',
  'haulynx-not-available',
  'haulynx-calendar',
  'haulynx-close',
  'haulynx-warn',
  'haulynx-warning-sign',
  'haulynx-swap',
  'haulynx-forward',
  'haulynx-crosshair',
];

export enum CommonEntitiesLabels {
  BROKERS = 'brokers',
  TEMPLATES = 'templates',
  DRIVERS = 'drivers',
}

export const pendingCarriersColors = ['#FF7A00', '#009669', '#EE5DFB', '#F92D2D', '#43A2F0'];

export const loadActionButtons = {
  CREATE: [buttonTypes.CANCEL, buttonTypes.NEXT],
  UPDATE: [buttonTypes.CANCEL, buttonTypes.UPDATE],
  UPDATE_AND_DELETE: [buttonTypes.DELETE, buttonTypes.CANCEL, buttonTypes.UPDATE],
};

export class CreateOptionsBts {
  NEW_DRIVER = 'new-driver';
  NEW_TRUCK = 'new-truck';
  NEW_TRAILER = 'new-trailer';
}

export class LoadActiveStatus {
  ACCEPTED = {
    id: 'accepted',
    label: 'Accepted',
    actionLabel: 'ACTION LOAD',
    date: null,
    completed: false,
    active: false,
  };
  ASSIGNED = {
    id: 'assigned',
    label: 'Assigned',
    actionLabel: 'ASSIGN LOAD',
    date: null,
    completed: false,
    active: false,
  };
  DISPATCHED = {
    id: 'dispatched',
    label: 'Dispatched',
    actionLabel: 'DISPATCH DRIVER',
    date: null,
    completed: false,
    active: false,
  };
  CONFIRM_PICK_UP = {
    id: 'confirm-pick-up',
    label: 'Confirm Pickup',
    actionLabel: 'CONFIRM PICKUP',
    date: null,
    completed: false,
    active: false,
  };
  PICKED_UP = {
    id: 'picked-up',
    label: 'Picked Up',
    actionLabel: 'CONFIRM PICKUP',
    date: null,
    completed: false,
    active: false,
  };
  DROP_OFF = {
    id: 'drop-off',
    label: 'Dropped Off',
    actionLabel: 'CONFIRM DROP-OFF',
    date: null,
    completed: false,
    active: false,
  };
  CONFIRM_DROP_OFF = {
    id: 'confirm-drop-off',
    label: 'Dropped off',
    actionLabel: 'CONFIRM DROP-OFF',
    date: null,
    completed: false,
    active: false,
  };
  CONFIRM_DELIVERY = {
    id: 'confirm-delivery',
    label: 'Confirm Delivery',
    actionLabel: 'CONFIRM DELIVERY',
    date: null,
    completed: false,
    active: false,
  };
  DELIVERED = {
    id: 'delivered',
    label: 'Delivered',
    actionLabel: 'COMPLETE DELIVERY',
    date: null,
    completed: false,
    active: false,
  };
  CLOSED = {
    id: 'closed',
    label: 'Closed',
    actionLabel: 'CLOSE LOAD',
    date: null,
    completed: false,
    active: false,
  };
}

export enum VariablesToShow {
  SHIPPER = 'shipper',
  DRIVER = 'driver',
}

export const DATE_TIME_PICKER_FORMATS = {
  ...OWL_DATE_TIME_FORMATS,
  fullPickerInput: 'MM/DD/YY, HH:mm z',
  monthYearLabel: 'MMM YYYY',
};

export const DATE_TIME_PICKER_CUSTOM_FORMAT = {
  ...OWL_DATE_TIME_FORMATS,
  fullPickerInput: 'ddd MM/DD/YY • HH:mm z',
  monthYearLabel: 'MMM YYYY',
};

const exLocations = [
  new LoadsServiceLoadLocation({
    address: '3450 Wintergarden Ave, Port Charlotte, FL 33948, USA',
    city: 'Port Charlotte',
    state: 'FL',
    zipcode: '33948',
    geometry: {
      type: LoadLocationType.PICKUP,
      coordinates: [26.9887022, -82.1726314],
    },
    timezone: 'America/New_York',
    billOfLading: '12345',
    locationType: LoadLocationType.PICKUP,
    notes: null,
  }),
  new LoadsServiceLoadLocation({
    address: '1600 Pennsylvania Ave NW, Washington, DC 20500, USA',
    city: 'Washington',
    state: 'DC',
    zipcode: '20500',
    geometry: {
      type: LoadLocationType.PICKUP,
      coordinates: [43.8019592, -83.00077739999999],
    },
    timezone: 'America/New_York',
    billOfLading: '12345',
    locationType: LoadLocationType.DROPOFF,
    notes: null,
  }),
];

export const EXAMPLE_LOAD = new LoadsServiceLoad({
  id: 'example-load',
  created: 1570792154200,
  locations: exLocations,
  loadStatus: LoadsServiceLoadStatus.UNASSIGNED,
  providerDetails: {
    weight: 25000,
  },
  carrier: new Carrier({ id: '-L6nXj0z1VHNdaGQ5Cpf', name: 'F & M Transport LLC', phone: '(480) 707-9049' }),
});

export const EXAMPLE_LOADS_SERVICE_LOAD = new LoadsServiceLoad({
  id: 'example-loads-service-load',
  created: 1570792154200,
  providerDetails: {
    equipmentType: LoadsServiceEquipmentType.DRY_VAN,
    weight: 25000,
  },
  paymentDetails: {
    price: 1000.0,
    revenue: 500,
  },
  locations: [
    new LoadsServiceLoadLocation({
      address: '3450 Wintergarden Ave, Port Charlotte, FL 33948, USA',
      geometry: {
        type: 'point',
        coordinates: [-82.1726314, 26.9887022],
      },
      appointmentStart: 1584543600000,
      timezone: 'America/New_York',
      billOfLading: '12345',
      customer: { id: 'Disney World' } as LoadsServiceCustomer,
      locationType: LoadLocationType.PICKUP,
    }),
    new LoadsServiceLoadLocation({
      address: '1600 Pennsylvania Ave NW, Washington, DC 20500, USA',
      geometry: {
        type: 'point',
        coordinates: [-83.00077739999999, 43.8019592],
      },
      appointmentStart: 1584630000000,
      timezone: 'America/New_York',
      billOfLading: '12345',
      customer: { id: 'Disney World' } as LoadsServiceCustomer,
      locationType: LoadLocationType.DROPOFF,
    }),
  ],
  createdBy: '123456',
  loadStatus: LoadsServiceLoadStatus.ASSIGNED,
  carrier: new Carrier({ id: '-L6nXj0z1VHNdaGQ5Cpf', name: 'F & M Transport LLC', phone: '(480) 707-9049' }),
});
