import gql from 'graphql-tag';

export const GetDocuments = gql`
  query GetDocuments($documentParams: DocumentParamsInput!) {
    getDocuments(documentParams: $documentParams) {
      id
      tenant_id
      title
      file_name
      format
      file_size
      file_size_bytes
      file_extension
      link
      type
      location {
        lat
        lon
      }
      created_at
      created_by
      updated_at
      updated_by
      lookup_ids
      tags
      acknowledgements {
        created_at
        created_by
        signature
        signature_type
      }
      users {
        id
        usxId
        name
        isCarrier
        isCompanyAdmin
        isDriver
        broker {
          isHaulynxBroker
        }
      }
    }
  }
`;
