<app-spinner [active]="isLoading"></app-spinner>

<mat-card class="lane-form mat-elevation-z0" *ngIf="!isLoading">
  <form [formGroup]="form">
    <h5>POST A LANE</h5>

    <div class="lane-block">
      <label class="label">Origin</label>
      <google-address-field
        formControlName="origin"
        [control]="form.get('origin')"
        [lon]="form.get('orgLon').value"
        [lat]="form.get('orgLat').value"
        (onGpsLocation)="onGpsLocation('origin', $event)"
        placeholder="Ex. Chattanooga, TN"
      >
      </google-address-field>
    </div>

    <div class="lane-block">
      <label class="label">Destination</label>
      <google-address-field
        formControlName="destination"
        [control]="form.get('destination')"
        [lon]="form.get('destLon').value"
        [lat]="form.get('destLat').value"
        (onGpsLocation)="onGpsLocation('destination', $event)"
        placeholder="Ex. Phoenix, AZ"
      >
      </google-address-field>
    </div>
  </form>

  <mat-card-actions class="lane-form-action">
    <button mat-raised-button (click)="create()">POST LANE</button>
  </mat-card-actions>
</mat-card>
