import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from '@haulynx/pipes';
import { ConfirmBookMissionComponent } from './confirm-book-mission.component';

@NgModule({
  declarations: [ConfirmBookMissionComponent],
  imports: [CommonModule, PipesModule],
  exports: [ConfirmBookMissionComponent],
})
export class ConfirmBookMissionModule {}
