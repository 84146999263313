<div class="load-warning-dialog">
  <div class="warning">
    <mat-icon svgIcon="haulynx-warning-sign" class="icon"></mat-icon>
    <div class="information">
      Location has not been updated in over 2 hours. Recommended you contact your driver to make sure they have an
      active signal.
      <br /><br />
      Do you still want to send the load information & tracking?
    </div>
  </div>
  <div mat-dialog-actions class="buttons">
    <button mat-button class="button-yes" [matDialogClose]="true" data-test="yes-button">Yes</button>
    <button mat-button class="button-no" [matDialogClose]="" data-test="no-button">No</button>
  </div>
</div>
