import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderEndbuttonsUserProfileComponent } from './header-endbuttons-user-profile.component';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';

@NgModule({
  declarations: [HeaderEndbuttonsUserProfileComponent],
  imports: [CommonModule, MaterialModule, PipesModule],
  exports: [HeaderEndbuttonsUserProfileComponent],
})
export class HeaderEndbuttonsUserProfileModule {}
