import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActiveLoadFilter, Carrier, loadStepType, Regions } from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-load-table-search-bar',
  templateUrl: './load-table-search-bar.component.html',
  styleUrls: ['./load-table-search-bar.component.scss'],
})
export class LoadTableSearchBarComponent implements OnChanges, OnDestroy {
  @Input() filter: ActiveLoadFilter;
  @Input() carriers: Carrier[];
  @Input() brokers: { id: string; label: string }[] = [];
  @Output() filterChange = new EventEmitter<ActiveLoadFilter>();
  @Output() carrierSearch = new EventEmitter<string>();

  // Todo: need to inject LoadStatus with angular provider
  loadStatuses = _.map(_.values(loadStepType), (status) => {
    return { text: status };
  });
  regions = _.orderBy(
    _.map(_.values(Regions), (region) => {
      return { text: region };
    }),
    ['text']
  );
  form: FormGroup;
  carrierSearchDebounce: Subject<string> = new Subject<string>();
  alive = aliveWhile();

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      region: [''],
      broker: [''],
      status: [''],
      carrier: [''],
      orderId: [''],
      customer: [''],
      pickupDate: [''],
    });

    this.carrierSearchDebounce.pipe(debounceTime(500)).subscribe((value) => {
      this.carrierSearch.emit(value);
    });
  }

  onEnter(e: KeyboardEvent): void {
    e.preventDefault(); // don't submit the form when a user clicks the enter key
  }
  ngOnChanges(changes: SimpleChanges) {
    const { brokers, filter } = changes;
    if (filter) {
      this.form.patchValue(filter.currentValue);
    }
  }

  onValueChanges(keyword) {
    if (keyword) {
      this.carrierSearchDebounce.next(keyword);
    }
  }

  search() {
    if (!this.checkIfFormEmpty(this.form)) {
      this.filterChange.emit(this.form.getRawValue());
    }
  }

  checkIfFormEmpty(form) {
    const formValues = form.getRawValue();
    const selectedFields = _.filter(_.values(formValues), (item) => !!item);
    return _.isEmpty(selectedFields);
  }

  ngOnDestroy() {
    this.alive.destroy();
  }
}
