import gql from 'graphql-tag';

export const UpdateLocationArrivalTime = gql(`
  mutation UpdateLocationArrivalTime($loadId: StrictId!, $locationIndex: NonNegativeInt!, $arrivalTime: NonNegativeInt!) {
    updateLocationArrivalTime(loadId: $loadId, locationIndex: $locationIndex, arrivalTime: $arrivalTime) {
        arrivalTime:carrierArrival
        departureTime:carrierDeparture
        name:address
    }
  }
`);
