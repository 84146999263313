import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { takeUntil } from 'rxjs/operators';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, SatDatepickerInputEvent } from 'saturn-datepicker';
import moment from 'moment-timezone';
import { UserService } from '@haulynx/services';
import { aliveWhile } from '@haulynx/utils';
import { User } from '@haulynx/types';

/**
 * Allows two form controls on a form group to be updated via a date-range
 * selector formatted as millisecond timestamps
 */

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class DateRangeComponent implements OnInit, OnDestroy, OnChanges {
  constructor(private fb: FormBuilder, private userService: UserService) {
    this.userService.user.pipe(takeUntil(this.alive)).subscribe((data) => {
      this.user = data;
    });
  }

  @Input() startKey: string; // the name of form control on the parent form group for the BEGIN date
  @Input() endKey: string; // the name of form control on the parent form group for the END date
  @Input() parentForm: FormGroup; // the form group that has both dates as controls
  @Input() timeInputFormat: moment.MomentFormatSpecification = 'x'; // default millisecond timestamp for input
  @Input() placeholder = '';
  @Output() onDateChange = new EventEmitter();
  @Input() min: moment.Moment;
  @Input() max: moment.Moment;

  user: User;
  alive = aliveWhile();
  dateForm = this.fb.group({
    date: [
      {
        begin: null,
        end: null,
      },
    ],
  });

  ngOnInit() {
    if (!this.startKey) {
      throw new Error('You must pass in a value for startKey');
    }
    if (!this.endKey) {
      throw new Error('You must pass in a value for endKey');
    }

    this.dateForm.valueChanges
      .pipe(takeUntil(this.alive))
      .subscribe((formVal: { date: { begin: moment.Moment; end: moment.Moment } }) => {
        if (formVal.date && formVal.date.begin.isValid() && formVal.date.end.isValid()) {
          this.parentForm.patchValue({
            [this.startKey]: formVal.date.begin.valueOf(),
            [this.endKey]: formVal.date.end.endOf('day').valueOf(),
          });
        } else {
          this.parentForm.patchValue({
            [this.startKey]: null,
            [this.endKey]: null,
          });
        }
      });
  }

  onInputDateChange(event: SatDatepickerInputEvent<Date>) {
    this.onDateChange.emit(event.value);
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.parentForm) {
      const form: FormGroup = changes.parentForm.currentValue;
      this.dateForm.patchValue({
        date: {
          begin: moment(form.get(this.startKey).value, this.timeInputFormat).tz(this.user.prefs.timeZone),
          end: moment(form.get(this.endKey).value, this.timeInputFormat).tz(this.user.prefs.timeZone),
        },
      });
    }
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }
}
