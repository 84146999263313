import { MemoizedSelector } from '@ngrx/store';
import { Record } from 'immutable';
import { createActions, ISearchActions, ISearchTypes } from './actions';
import { createSearchReducer } from './reducer';
import { createSearchSelectors, ISearchSelectors } from './selectors';
import { createInitialSearchState, ISearchState } from './state';

export interface SearchAdapter<T> {
  getInitialState();

  getSelectors(selectState: ((state) => ISearchState) | MemoizedSelector<any, Record<ISearchState>>): ISearchSelectors;

  getReducer(types);

  getActionTypes(): ISearchTypes;

  getActions(): ISearchActions;
}

export function createSearchAdapter<T>(options: { type?: any } = {}): SearchAdapter<T> {
  const { type } = options;

  const initialState = createInitialSearchState();
  const selectors = createSearchSelectors<T>();
  const actions = createActions(type);
  const reducer = createSearchReducer(actions.getActionTypes());

  return {
    ...initialState,
    ...selectors,
    ...reducer,
    ...actions,
  };
}
