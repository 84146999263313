import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'haulynx-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackButtonComponent {
  @Input() label: string;
  @Input() set url(value: string) {
    this._url = value ?? '/';
  }
  get url(): string {
    return this._url;
  }

  private _url: string;

  constructor(private router: Router) {}

  onGoTo(): void {
    this.router.navigateByUrl(this.url);
  }
}
