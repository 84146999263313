import gql from 'graphql-tag';
import { deviceQueryFields } from '../../queries';

export const CreateDeviceByImei = gql(`
  mutation CreateDevicesWithImei($devices: [DeviceInput!]!, $userId: StrictId!) {
    createDevicesWithImei(devices: $devices, userId: $userId) { ${deviceQueryFields} }
  }
`);

export const UpdateDeviceById = gql(`
  mutation updateDeviceById($id: ID!, $device: DeviceInput!, $markUnassigned: Boolean) {
    updateDeviceById(id: $id, device: $device, markUnassigned: $markUnassigned) { ${deviceQueryFields} }
  }
`);
