<app-spinner [active]="isLoading"></app-spinner>

<div class="panel-analytics" *ngIf="!isLoading">
  <div class="panel-analytics__item">
    <app-carrier-analytics-insurance
      [isLoading]="isLoadingInsuranceAndSafety"
      [insurance]="insuranceAndSafety"
    ></app-carrier-analytics-insurance>
  </div>
  <div class="verticalDivider"></div>
  <div class="panel-analytics__item panel-analytics__metrics">
    <app-carrier-analytics-metrics [carrierMetrics]="carrierMetrics"></app-carrier-analytics-metrics>
  </div>

  <div class="panel-analytics__item" *ngIf="false">
    <span class="panel-analytics__label">Loads offered</span>
    <div class="panel-analytics__info">
      <div class="panel-analytics__info__item">
        <div class="panel-analytics__info__item__label">Last 30 days:</div>
        <div class="panel-analytics__info__item__value">14</div>
      </div>

      <div class="panel-analytics__info__item">
        <div class="panel-analytics__info__item__label">This week:</div>
        <div class="panel-analytics__info__item__value">2</div>
      </div>
    </div>
  </div>

  <div class="panel-analytics__item" *ngIf="false">
    <span class="panel-analytics__label">Loads taken</span>
    <div class="panel-analytics__info">
      <div class="panel-analytics__info__item">
        <div class="panel-analytics__info__item__label">Last 30 days:</div>
        <div class="panel-analytics__info__item__value">3</div>
      </div>

      <div class="panel-analytics__info__item">
        <div class="panel-analytics__info__item__label">This week:</div>
        <div class="panel-analytics__info__item__value">0</div>
      </div>
    </div>
  </div>

  <div class="panel-analytics__item" *ngIf="false">
    <span class="panel-analytics__label">Carrier owner</span>
    <div class="panel-analytics__info">
      <div class="panel-analytics__info__item">
        <div class="panel-analytics__info__item__label"></div>
        <div class="panel-analytics__info__item__value"></div>
      </div>
    </div>
  </div>
</div>
