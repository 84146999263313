import { BidDetails } from '@haulynx/types';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-load-bid-details',
  templateUrl: './load-bid-details.component.html',
  styleUrls: ['./load-bid-details.component.scss'],
})
export class LoadBidDetailsComponent {
  @Input() row: { bidDetails: BidDetails }; // usually a load

  showCents = false;

  constructor() {}
}
