import { SearchParams } from '@haulynx/types';
import { createAction, createActionTypes } from '../../shared/store/helpers/action';
import { ISearchActions, ISearchTypes } from '../../shared/store/search/actions';
import { ITabsActions, ITabsActionTypes } from '../../shared/store/tabs/actions';
import { activeLoadsAdapter, TYPE } from './adapters';

export interface IActiveLoadsActions extends ISearchActions, ITabsActions {
  selectLoad(params?);

  searchCarrier(params: SearchParams);

  searchCarrierSuccess(params: { entities: any[]; total?: number });

  searchCarrierError(params?);

  searchBroker(params: SearchParams);

  searchBrokerSuccess(params: { entities: any[]; total?: number });

  searchBrokerError(params?);
}

export interface IActiveLoadsActionTypes extends ISearchTypes, ITabsActionTypes {
  SELECT_LOAD: string;
  SEARCH_CARRIER: string;
  SEARCH_CARRIER_SUCCESS: string;
  SEARCH_CARRIER_ERROR: string;
  SEARCH_BROKER: string;
  SEARCH_BROKER_SUCCESS: string;
  SEARCH_BROKER_ERROR: string;
}

export const activeLoadsActionTypes: Partial<IActiveLoadsActionTypes> = {
  SELECT_LOAD: 'select load',
  SEARCH_CARRIER: 'search carrier',
  SEARCH_CARRIER_SUCCESS: 'search carrier success',
  SEARCH_CARRIER_ERROR: 'search carrier error',
  SEARCH_BROKER: 'search broker',
  SEARCH_BROKER_SUCCESS: 'search broker success',
  SEARCH_BROKER_ERROR: 'search broker error',
};

export const activeLoadsStateActions: Partial<IActiveLoadsActions> = {
  selectLoad: createAction(TYPE, activeLoadsActionTypes.SELECT_LOAD),
  searchCarrier: createAction(TYPE, activeLoadsActionTypes.SEARCH_CARRIER),
  searchCarrierSuccess: createAction(TYPE, activeLoadsActionTypes.SEARCH_CARRIER_SUCCESS),
  searchCarrierError: createAction(TYPE, activeLoadsActionTypes.SEARCH_CARRIER_ERROR),
  searchBroker: createAction(TYPE, activeLoadsActionTypes.SEARCH_BROKER),
  searchBrokerSuccess: createAction(TYPE, activeLoadsActionTypes.SEARCH_BROKER_SUCCESS),
  searchBrokerError: createAction(TYPE, activeLoadsActionTypes.SEARCH_BROKER_ERROR),
};

export const ActiveLoadsActions: IActiveLoadsActions = {
  ...activeLoadsStateActions,
  ...activeLoadsAdapter.tabs.getActions(),
  ...activeLoadsAdapter.activeLoads.getActions(),
};
export const ActiveLoadsActionTypes: IActiveLoadsActionTypes = {
  ...activeLoadsAdapter.tabs.getActionTypes(),
  ...activeLoadsAdapter.activeLoads.getActionTypes(),
  ...createActionTypes(activeLoadsActionTypes)(TYPE),
};
