import { ISearchFilter, TextFilterType } from '../search-filter';
import { ISearchFilterType } from '../search-filter.types';

export class LaneCarrierDOTFilter implements ISearchFilter {
  name = 'Carrier DOT';
  type: ISearchFilterType = ISearchFilterType.TEXT;
  keys: TextFilterType;
  description = 'Filter lanes by Carrier DOT number';

  constructor(defaultValues?: TextFilterType) {
    this.keys = new TextFilterType({
      value: '',
      optionsDataIndex: 'dot',
      textFormName: 'carrierNameOrDot',
      numericalOnly: true,
      ...defaultValues,
    });
  }
}
