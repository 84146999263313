import { assign, isFunction, mapValues, reduce } from 'lodash';
import { ActionReducerMap, combineReducers } from '@ngrx/store';

export interface IAdapter {
  getInitialState();

  getSelectors(selectState: (state: any) => any, ...args: any[]): any;

  getReducer(types?);

  getActionTypes();

  getActions();
}

export function combineAdapters<T>(adapterMap, baseType): T | any {
  const adapters = mapValues(adapterMap, (adapterFactory) => {
    const settings = {
      type: `${baseType}`,
    };

    return adapterFactory(settings);
  });

  return adapters;
}

export function combineAdapterReducers<T>(adapterMap, initialState?) {
  const mapReducers = reduce<any, ActionReducerMap<any, any>>(
    adapterMap,
    (result, adapter: IAdapter, key) => {
      const hasReducer = !!adapter.getReducer;

      if (hasReducer) {
        result[key] = adapter.getReducer();
      }

      return result;
    },
    {}
  );

  const newCombineReducers = combineReducers<T>(mapReducers, initialState);

  const newReducer = (state, action) => {
    const newState = newCombineReducers(state, action);

    return state.merge(newState);
  };

  return newReducer;
}

export function combineAdaptersInitialState<T>(adapters) {
  return reduce(
    adapters,
    (result, adapter: IAdapter, key) => {
      const hasInitialState = isFunction(adapter.getInitialState);

      if (hasInitialState) {
        result[key] = adapter.getInitialState();
      }

      return result;
    },
    {}
  );
}

export function combineAdaptersActionsState<T>(adapters): T {
  return reduce(
    adapters,
    (result, adapter: IAdapter) => {
      const hasAction = isFunction(adapter.getActions);

      return assign(result, hasAction ? adapter.getActions() : {});
    },
    {}
  );
}

export function combineAdaptersActionTypeState<T>(adapters): T {
  return reduce(
    adapters,
    (result, adapter: IAdapter) => {
      const hasActionType = isFunction(adapter.getActionTypes);

      return assign(result, hasActionType ? adapter.getActionTypes() : {});
    },
    {}
  );
}
