import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IAssignLoadCarrierForm } from '@haulynx/types';
import { OrderInfoNotesVmService } from '../../containers-services/pay-line-items/order-info-notes-vm.service';
import { OrderInfoVmService } from '../../containers-services/pay-line-items/order-info-vm.service';
import { PaymentDetailsVmService } from '../../containers-services/pay-line-items/payment-details-vm.service';
import { CarrierInfoSectionVmService } from '../carrier-info-section-vm-service/carrier-info-section-vm.service';
import { LoadDispatchFormVm } from '../load-dispatch-form-vm/load-dispatch-form-vm.service';

@Injectable({
  providedIn: 'root',
})
export class AssignLoadCarrierFormVm {
  private fb = new FormBuilder();
  constructor(
    private orderInfoVmService: OrderInfoVmService,
    private carrierInfoSectionVmService: CarrierInfoSectionVmService,
    private orderInfoNotesVmService: OrderInfoNotesVmService,
    private loadDispatchFormVm: LoadDispatchFormVm,
    private paymentDetailsVmService: PaymentDetailsVmService
  ) {}

  create(values: Partial<IAssignLoadCarrierForm> = {}): FormGroup {
    const formGroup = this.fb.group({
      orderInfo: this.orderInfoVmService.create((values && values.orderInfo) || {}),
      carrierInfo: this.carrierInfoSectionVmService.createCarrierInfoFormOptions((values && values.carrierInfo) || {}),
      assignmentInfo: this.carrierInfoSectionVmService.createAssignmentFormOptions(
        (values && values.assignmentInfo) || {}
      ),
      payments: this.fb.array((values.payments || []).map((payment) => this.paymentDetailsVmService.create(payment))),
      dispatchInfo: this.loadDispatchFormVm.create((values && values.dispatchInfo) || {}),
      editLoad: this.orderInfoNotesVmService.create((values && values.editLoad) || {}),
    });

    if (values) {
      formGroup.patchValue(values);
    }

    return formGroup;
  }
}
