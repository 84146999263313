import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { AsyncEntityUserStoreModule } from '@haulynx/store';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AccountRoutingModule } from './account-routing.module';
import { AccountComponent } from './account.component';
import { AccountHeaderModule } from './components/account-header/account-header.module';

@NgModule({
  declarations: [AccountComponent],
  imports: [
    CommonModule,
    AccountRoutingModule,
    AccountHeaderModule,
    AsyncEntityUserStoreModule,
    RouterModule,
    FlexLayoutModule,
    AngularSvgIconModule.forRoot(),
  ],
})
export class AccountModule {}
