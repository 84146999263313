import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CommonEntities,
  LoadActiveTabsModel,
  LoadAvailableTabsModel,
  LoadModel,
  UserEntityService,
} from '@haulynx/store';
import {
  buttonTypes,
  Carrier,
  loadActiveTabsConfig,
  loadAvailableTabsConfig,
  loadTabsConfig,
  Tab,
  User,
} from '@haulynx/types';
import { aliveWhile, listToArray } from '@haulynx/utils';
import { BehaviorSubject } from 'rxjs';
import { delay, first, takeUntil, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'haulynx-load',
  templateUrl: './load.component.html',
  styleUrls: ['./load.component.scss'],
})
export class LoadComponent implements OnInit, OnDestroy {
  alive = aliveWhile();
  user: User;
  carriers: Carrier[] = [];
  buttons$ = new BehaviorSubject([]);
  tabs$ = new BehaviorSubject(null);
  private prevTabUrl: string = null;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userEntityService: UserEntityService,
    public loadModel: LoadModel,
    public commonEntities: CommonEntities,
    public loadActiveTabsModel: LoadActiveTabsModel,
    public loadAvailableTabsModel: LoadAvailableTabsModel
  ) {
    this.userEntityService.user$
      .pipe(withLatestFrom(this.loadModel.tabsSelected$), takeUntil(this.alive))
      .subscribe(([user, tab]) => {
        this.user = user;

        if (this.user && (this.user.carrier || this.user.broker || this.user.shipper)) {
          if (tab) {
            if (['Active', 'Completed', 'Available'].includes(tab.label)) this.buttons$.next([buttonTypes.CREATE_LOAD]);
            else this.buttons$.next([]);
          }
        }
      });
    this.commonEntities.carriers.entities$.pipe(takeUntil(this.alive)).subscribe((entities) => {
      const carriers = listToArray(entities);
      const all = {
        name: 'All',
        id: null,
      };

      carriers.unshift(all);

      this.carriers = carriers;
    });

    this.commonEntities.trucks.search();
    this.commonEntities.carriers.search({ key: 'loads-carriers' });

    this.loadModel.tabsSelected$
      .pipe(withLatestFrom(this.loadModel.tabs$), takeUntil(this.alive))
      .subscribe(([tab, tabs]) => {
        if (tab) {
          if (this.prevTabUrl && tab.id !== 'new') {
            this.router.navigate([this.prevTabUrl], { relativeTo: this.activatedRoute, queryParamsHandling: 'merge' });
            this.prevTabUrl = null;
          } else {
            this.router.navigate([tab?.url], {
              relativeTo: this.activatedRoute,
              queryParamsHandling: 'merge',
            });
          }
        }
      });

    this.loadModel.tabs$.pipe(delay(0), takeUntil(this.alive)).subscribe((tabs) => {
      this.tabs$.next(tabs);
    });
  }

  ngOnInit(): void {
    this.loadModel.url$;
  }

  goTo(data: { tab }): void {
    const tab: Tab = data.tab;
    this.loadModel.selectTab(tab);
    if (tab.id === loadTabsConfig.ACTIVE.id) {
      this.loadActiveTabsModel.selectTab(loadActiveTabsConfig.SEARCH);
    }
    if (tab.id === loadTabsConfig.AVAILABLE.id) {
      this.loadAvailableTabsModel.selectTab(loadAvailableTabsConfig.SEARCH);
    }
  }

  removeTab(tab: Tab): void {
    this.loadModel.removeTabs(tab);
  }

  selectCarrier(dot: string): void {
    this.loadModel.selectCarrier(dot);
  }

  handlerAction(action: string): void {
    if (action === buttonTypes.CREATE_LOAD.action) {
      this.loadModel.tabsSelected$.pipe(first(), takeUntil(this.alive)).subscribe((tab) => {
        if (tab) {
          this.prevTabUrl = tab.url;
        }
      });
      this.router.navigate(['view/new'], { relativeTo: this.activatedRoute });
    }
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }
}
