import gql from 'graphql-tag';
import { COMPLETE_LOAD_DETAILS_FRAGMENT } from './fragments';

export const CarrierLoadSearchGetLoadsServiceLoadById = gql`
  query CarrierLoadSearchGetLoadsServiceLoadById($loadId: StrictId!) {
    getLoadsServiceLoadById(loadId: $loadId) {
      ...completeLoadDetails
    }
  }
  ${COMPLETE_LOAD_DETAILS_FRAGMENT}
`;
