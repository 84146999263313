import { Injectable } from '@angular/core';
import { DateForm, SelectionStrategy } from '@haulynx/types';
import { isInvalidDate } from '@haulynx/utils';
import { endOfDay, isAfter, isBefore, isValid } from 'date-fns';
import { format, toDate, utcToZonedTime } from 'date-fns-tz';
import { head, last, nth, padStart, toString } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class RangeModeService implements SelectionStrategy {
  isValidDate(formVal: DateForm, timeZone: string): boolean {
    const dateRange = this.formToZonedDate(formVal, timeZone);
    return isValid(nth(dateRange, 0)) && isValid(nth(dateRange, 1));
  }

  formToZonedDate(dateVal: DateForm, timeZone: string): Date[] {
    try {
      const month = padStart(toString(dateVal.month), 2, '0');
      const day = padStart(toString(dateVal.day), 2, '0');
      const monthRange = padStart(toString(dateVal.monthRange), 2, '0');
      const dayRange = padStart(toString(dateVal.dayRange), 2, '0');

      const start = toDate(`${dateVal.year}-${month}-${day}T00:00:00`, { timeZone });
      const end = endOfDay(toDate(`${dateVal.yearRange}-${monthRange}-${dayRange}T00:00:00`, { timeZone }));
      // If the dates aren't valid, passing `null` to primeng calendar will leave the range blank
      // and let the calendar focus on the current day / month.
      return this.isInvalidDate([start, end]) ? null : [start, end];
    } catch {
      return null;
    }
  }

  isInvalidDate(dateVal: ArrayLike<number | Date>): boolean {
    return isInvalidDate(nth(dateVal, 0)) || isInvalidDate(nth(dateVal, 1));
  }

  normalizeDate(dateVal: Date[]): number[] {
    const start = head(dateVal);
    const end = last(dateVal);

    if (start && end) {
      return [start.valueOf(), endOfDay(end).valueOf()];
    }
  }

  normalizeTimepickerValue(dateVal: number[], timeZone: string): Partial<DateForm> {
    if (dateVal) {
      const [start, end] = dateVal;

      const zonedTime = utcToZonedTime(start, timeZone);
      const zonedRangeTime = end ? utcToZonedTime(end, timeZone) : null;

      const month = padStart(toString(zonedTime.getMonth() + 1), 2, '0');
      const day = padStart(toString(zonedTime.getDate()), 2, '0');
      const year = zonedTime.getFullYear().toString();
      const hours = null;
      const mins = null;
      const monthRange = end ? padStart(toString(zonedRangeTime.getMonth() + 1), 2, '0') : null;
      const dayRange = end ? padStart(toString(zonedRangeTime.getDate()), 2, '0') : null;
      const yearRange = end ? zonedRangeTime.getFullYear().toString() : null;

      return { month, day, year, hours, mins, monthRange, dayRange, yearRange };
    }
  }

  isMinMaxValid(formValue: DateForm, timeZone: string, min: Date, max: Date): Record<string, boolean> {
    const formDate = this.formToZonedDate(formValue, timeZone);

    if (!!min && (isBefore(nth(formDate, 0), min) || isBefore(nth(formDate, 1), min))) {
      const formattedMinDate = format(min, 'MM/dd/yyyy', { timeZone });
      return { [`Date must be after ${formattedMinDate}`]: true };
    }

    if (!!max && (isAfter(formDate[0], max) || isAfter(formDate[1], max))) {
      const formattedMaxDate = format(max, 'MM/dd/yyyy', { timeZone });
      return { [`Date must be after ${formattedMaxDate}`]: true };
    }

    return null;
  }

  isRangeValid(formValue: DateForm, timeZone: string): Record<string, boolean> {
    const formDate = this.formToZonedDate(formValue, timeZone);

    const start = head(formDate);
    const end = last(formDate);

    if (start > end) {
      return { [`Start date must be less or equal end date`]: true };
    }

    return null;
  }
}
