import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxCurrencyModule } from 'ngx-currency';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { BulkActionsDialogComponent } from '../dialogs/bulk-actions-dialog/bulk-actions-dialog.component';
import { BulkActionsComponent } from './bulk-actions-component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    ClipboardModule,
    NgxCurrencyModule,
    PipesModule,
  ],
  declarations: [BulkActionsComponent, BulkActionsDialogComponent],
  exports: [BulkActionsComponent, BulkActionsDialogComponent],
})
export class BulkActionsModule {}
