<div class="dispatch-container">
  <form class="form" [ngClass]="formCls" [formGroup]="form">
    <app-spinner [active]="true" *ngIf="isLoading" [fullCover]="true"></app-spinner>
    <ng-container *ngIf="formControlCls === 'app-form-field'; else locationFormControlField">
      <mat-form-field class="app-form-field dispatch-form__control-location" appearance="fill" floatLabel="always">
        <mat-label> Empty Location </mat-label>
        <app-address-field
          formControlName="location"
          placeholder="Myrtle Beach, SC"
          (onPlaceChange)="checkForm($event)"
        >
        </app-address-field>
      </mat-form-field>
    </ng-container>

    <ng-template #locationFormControlField>
      <mat-form-field class="app-dark-field dispatch-form__control-location" appearance="fill" floatLabel="always">
        <label> Empty Location </label>
        <app-address-field
          formControlName="location"
          placeholder="Myrtle Beach, SC"
          class="form-control"
          (onPlaceChange)="checkForm($event)"
        >
        </app-address-field>
      </mat-form-field>
    </ng-template>

    <ng-container *ngIf="formControlCls === 'app-form-field'; else timeAvailableFormControlField">
      <div class="dispatch-form__control-time-available">
        <div class="form-field">
          <label> Time Of Empty </label>
          <app-datetime-picker
            class="app-form-field"
            formControlName="timeAvailable"
            [timeZone]="form.get('location').value?.timeZone"
          ></app-datetime-picker>
        </div>
      </div>
    </ng-container>

    <ng-template #timeAvailableFormControlField>
      <div class="app-dark-field dispatch-form__control-time-available">
        <div class="form-field">
          <label> Time Of Empty </label>

          <app-datetime-picker formControlName="timeAvailable" [timeZone]="form.get('location').value?.timeZone">
          </app-datetime-picker>
        </div>
      </div>
    </ng-template>

    <ng-container *ngIf="formControlCls === 'app-form-field'; else notesFormControlField">
      <mat-form-field class="app-form-field dispatch-form__control-notes" appearance="fill" floatLabel="always">
        <mat-label> Notes </mat-label>
        <textarea formControlName="notes" matInput placeholder=""> </textarea>
      </mat-form-field>
    </ng-container>

    <ng-template #notesFormControlField>
      <mat-form-field class="app-dark-field dispatch-form__control-notes" appearance="fill" floatLabel="always">
        <label> Notes </label>
        <textarea class="form-control" formControlName="notes" matInput placeholder=""> </textarea>
      </mat-form-field>
    </ng-template>

    <div class="dispatch-form__control-actions" *ngIf="showSaveButton || showSkipButton">
      <button
        class="hlx-button hlx-button--active"
        *ngIf="showSaveButton"
        (click)="save($event, form.value)"
        [disabled]="!bookedLoad"
      >
        Save
      </button>

      <button
        class="hlx-button hlx-button--skip"
        *ngIf="showSkipButton"
        (click)="skip($event)"
        [disabled]="!bookedLoad"
      >
        Skip step
      </button>
    </div>
  </form>
</div>
