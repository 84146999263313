import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { UserEntityService } from '@haulynx/store';
import { AccountRoute, User } from '@haulynx/types';
import { userHomePage, userRedirect } from '@haulynx/utils';
import { isEmpty } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CheckAccountGuard implements CanActivate {
  constructor(private router: Router, private userEntityService: UserEntityService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // Anyone should always be allowed to go to action url, loads avaialble, terms of service, and privacy policy
    if (
      state.url.includes('action') ||
      state.url.includes('loads/available') ||
      state.url.includes('terms-of-service') ||
      state.url.includes('privacy-policy')
    )
      return true;

    return this.userEntityService.user$.pipe(
      map((user: User) => {
        if (isEmpty(user)) {
          if (state.url.includes('login') || state.url.includes('create')) return true;

          let queryParams = {};
          if (!state.url.includes('account') || state.url.includes('dashboard/account')) {
            queryParams = { returnUrl: state.url };
          }

          // this.router.navigateByUrl('', { qu });
          this.router.navigate([AccountRoute.LOGIN], { queryParams });
          return false;
        }

        const url = userRedirect(user);
        if (url && !state.url.includes(url)) {
          this.router.navigate([url]);
          return false;
        }

        if (
          !url &&
          state.url.includes('account') &&
          !state.url.includes('dashboard/account') &&
          !state.url.includes('account/carrier/sorry')
        ) {
          const homePage = userHomePage(user);
          this.router.navigate([homePage.url], {
            queryParamsHandling: 'merge',
            queryParams: { ...homePage.queryParams, ...(route.queryParams || {}) },
          });
          return false;
        }

        return true;
      })
    );
  }
}
