import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, FormControlName } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatFormFieldControl, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MomentService } from '@haulynx/services';
import { DatesRange, MomentRange } from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import * as _ from 'lodash';
import moment from 'moment';
import { isMoment } from 'moment';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from 'saturn-datepicker';
import { AppDropDownComponent } from '../drop-down/components/app-drop-down/app-drop-down.component';
import { SeparateDateInputsComponent } from './components/separate-date-inputs/separate-date-inputs.component';

export class DateOptions {
  selected: boolean;
  text: string;
  date: moment.Moment;
}

/**
 *
 *
 * @deprecated- USE DatetimePickerComponent
 *
 *
 */
@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_MOMENT_DATE_FORMATS,
    },
    {
      provide: MatFormFieldControl,
      useExisting: DatepickerComponent,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        float: 'always',
      },
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerComponent implements ControlValueAccessor, MatFormFieldControl<unknown>, OnInit, OnDestroy {
  @Input() min: moment.Moment;
  @Input() max: moment.Moment;
  @Input() timeInputFormat: string | moment.MomentFormatSpecification = 'x';
  @Input() tabIndex: number = null;
  @Input() separateDateInputs = false;
  @Input() dropdown: boolean;
  @Input() timezone: string;
  @Input() selectFirstDateOnClose = true;
  @Input() label = 'Select Date Range';

  @ViewChild('pickerInput', { static: true }) pickerInput: ElementRef;
  @ViewChild(SeparateDateInputsComponent) separatePickerInput: SeparateDateInputsComponent;
  @ViewChild(AppDropDownComponent) dropDownInput: AppDropDownComponent;

  control: FormControl | AbstractControl | null = new FormControl();
  alive = aliveWhile();
  nextTenDays: DateOptions[] = [];
  startDate: moment.Moment;
  onTouch: (value) => unknown;

  readonly autofilled: boolean;
  readonly controlType: string;

  readonly id: string;
  readonly stateChanges: Subject<void> = new Subject<void>();

  private _rangeMode;
  private _value = null;
  private _placeholder: string = null;
  private _required = false;
  private _disabled = false;
  private _focused = false;
  private _errorState = false;

  constructor(
    @Optional() @Self() public ngControl: FormControlName,
    private momentService: MomentService,
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.fm.monitor(elRef.nativeElement, true).subscribe((origin) => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  @Input()
  set rangeMode(newValue: unknown) {
    this._rangeMode = !!newValue;
  }

  get rangeMode(): unknown {
    return this._rangeMode;
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(req: boolean) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  @Input()
  set placeholder(newValue: string) {
    this._placeholder = newValue;
    this.stateChanges.next();
  }

  get placeholder(): string {
    return this._placeholder;
  }

  @HostBinding('class.floating')
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  set value(newValue: unknown) {
    const timeZone = this.timezone;
    let momentValue = null;
    if (isMoment(newValue)) {
      momentValue = this.momentService.getMoment(newValue, timeZone);
    } else if (this.momentService.isDatesRange(newValue)) {
      const { begin = null, end = null } = (newValue as DatesRange) || {};

      const newDates = {
        begin: begin && _.isNumber(begin) ? this.momentService.getMoment(begin, timeZone) : begin,
        end: end && _.isNumber(end) ? this.momentService.getMoment(end, timeZone) : end,
      };

      momentValue = newDates;
    } else if (_.isNumber(newValue)) {
      momentValue = this.momentService.getMoment(newValue, timeZone);
    }

    this._value = momentValue;
  }

  get value(): unknown {
    return this._value;
  }

  get empty(): boolean {
    return !this.value;
  }

  set focused(newValue: boolean) {
    this._focused = newValue;
  }

  get focused(): boolean {
    return this._focused;
  }

  get errorState(): boolean {
    return !!this._errorState;
  }

  set errorState(newValue: boolean) {
    this._errorState = newValue;
  }

  propagateChanges(value): void {}

  registerOnChange(fn: () => unknown): void {
    this.propagateChanges = fn;
  }

  registerOnTouched(fn: () => unknown): void {
    this.onTouch = fn;
  }

  writeValue(newValue: DatesRange | moment.Moment | string): void {
    this.value = newValue;
    this.control.patchValue(this.value);
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  onContainerClick(event: MouseEvent): void {}

  setDescribedByIds(ids: string[]): void {}

  onDateInput(changes: string): void {
    const newDateTime = this.momentService.getMomentFromString(changes, this.timezone, true);

    if (newDateTime !== this.control.value) {
      this.control.patchValue(newDateTime);
      this.propagateChanges(newDateTime);
    }
  }

  ngOnInit(): void {
    if (this.dropdown) {
      this.startDate = this.min.clone();
      this.placeholder = this.startDate.format('ddd, MM/DD');
      this.nextTenDays.push({ selected: false, text: this.placeholder, date: this.startDate });
      this.getTenDayForcast(this.startDate);
    }

    this.control.valueChanges
      .pipe(takeUntil(this.alive), distinctUntilChanged())
      .subscribe((newValues: MomentRange | moment.Moment | null) => {
        const timeInputFormat = this.timeInputFormat as string;
        let newDateTime = null;

        if (this.momentService.isDatesRange(newValues)) {
          let { begin = null, end = null }: any = (newValues as MomentRange) || {};
          if (begin && end) {
            const rawBegin = begin.startOf('day');
            const rawEnd = end.endOf('day');

            begin = timeInputFormat === 'x' ? rawBegin.valueOf() : rawBegin.format(timeInputFormat);
            end = timeInputFormat === 'x' ? rawEnd.valueOf() : rawEnd.format(timeInputFormat);
          }
          newDateTime = { begin, end };
        } else if (isMoment(newValues)) {
          if (timeInputFormat === 'x') {
            newDateTime = newValues.clone().startOf('day').valueOf();
          } else {
            newDateTime = newValues.clone().format(timeInputFormat).valueOf();
          }
        }

        if (this.propagateChanges) {
          this.propagateChanges(newDateTime);
        }
        this.value = newDateTime;
        if (this.separatePickerInput) {
          this.separatePickerInput.value = this.momentService.getMoment(newDateTime, this.timezone).format('M/D/YYYY');
        }
        if (this.dropdown) {
          this.dropDownInput.keywordSearch.setValue(
            this.momentService.getMoment(newDateTime, this.timezone).format('ddd, MM/DD')
          );
        }
        this.errorState = !!this.ngControl.errors;
        this.stateChanges.next();
      });
  }

  ngAfterViewInit(): void {
    if (this.dropDownInput) {
      this.dropDownInput.keywordSearch.setValue(
        this.momentService.getMoment(this.min, this.timezone).format('ddd, MM/DD')
      );
    }
  }

  getTenDayForcast(currentDate: moment.Moment): void {
    const date = currentDate.clone();
    for (let i = 0; i < 9; i++) {
      const tempDate = date.clone().add(i + 1, 'days');
      this.nextTenDays.push({ selected: false, text: tempDate.format('ddd, MM/DD'), date: tempDate });
    }
  }

  ngOnDestroy(): void {
    this.alive.destroy();
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef.nativeElement);
  }
}
