import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DataTableModule, DatetimePickerModule, ReleaseFormDialogComponent, SpinnerModule } from '@haulynx/components';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { ReleasesRoutingModule } from './releases-routing.module';
import { ReleasesComponent } from './releases.component';

@NgModule({
  declarations: [ReleasesComponent, ReleaseFormDialogComponent],
  imports: [
    CommonModule,
    MaterialModule,
    ReleasesRoutingModule,
    ReactiveFormsModule,
    PipesModule,
    DatetimePickerModule,
    DataTableModule,
    SpinnerModule,
  ],
})
export class ReleasesModule {}
