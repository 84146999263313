<h2 matDialogTitle>Share Load</h2>

<form *ngIf="shareForm" [formGroup]="shareForm">
  <mat-dialog-content>
    <mat-form-field>
      <mat-chip-list #emailChipList formArrayName="email">
        <mat-chip
          *ngFor="let email of formData('email').controls; let i = index"
          [removable]="true"
          (removed)="remove('email', i)"
        >
          {{ email.value }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
        <input
          data-test="email-input"
          placeholder="Enter Email Addresses"
          [matChipInputFor]="emailChipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="true"
          (matChipInputTokenEnd)="add($event, 'email')"
        />
      </mat-chip-list>
      <mat-error data-test="email-mat-error" *ngIf="shareForm.get('email').hasError('email')">
        Please enter a valid email
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-chip-list #phoneChipList formArrayName="phone">
        <mat-chip
          *ngFor="let phone of formData('phone').controls; let i = index"
          [removable]="true"
          (removed)="remove('phone', i)"
        >
          {{ phone.value }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
        <input
          data-test="phone-input"
          placeholder="Enter Phone Numbers"
          [matChipInputFor]="phoneChipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="true"
          (matChipInputTokenEnd)="add($event, 'phone')"
        />
      </mat-chip-list>
      <mat-error data-test="phone-mat-error" *ngIf="shareForm.get('phone').hasError('phone')">
        Please enter a valid phone number xxx-xxx-xxxx
      </mat-error>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-button color="warn" type="reset" [matDialogClose]="" data-test="cancel-button">Cancel</button>

    <button
      [disabled]="
        formData('email').invalid ||
        formData('phone').invalid ||
        (!formData('email').value.length && !formData('phone').value.length)
      "
      mat-button
      color="primary"
      [mat-dialog-close]="shareForm.value"
      data-test="send-button"
    >
      Send
    </button>
  </mat-dialog-actions>
</form>
