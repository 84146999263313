import {
  Component,
  ChangeDetectionStrategy,
  AfterViewInit,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { differenceInCalendarDays } from 'date-fns';
import { LocalStoreService } from '@haulynx/services';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'haulynx-mobile-banner',
  templateUrl: './mobile-banner.component.html',
  styleUrls: ['./mobile-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileBannerComponent implements AfterViewInit {
  showBanner: boolean;
  @Output() toggleMobileBanner = new EventEmitter<boolean>();

  constructor(
    private localStoreService: LocalStoreService,
    private breakpointObserver: BreakpointObserver,
    private cd: ChangeDetectorRef
  ) {}

  ngAfterViewInit() {
    const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent);
    const isMobileScreen = this.breakpointObserver.isMatched('(max-width: 599px)');

    if (isMobileDevice && isMobileScreen) {
      Promise.all([
        this.localStoreService.get<boolean>('showBanner'),
        this.localStoreService.get<any>('bannerSetOn'),
      ]).then(([showBanner, bannerSetOn]) => {
        if (showBanner) {
          this.showBanner = showBanner;
          this.cd.detectChanges();
        }
        // A week from when banner was dismissed, will count down each day after
        const differenceInDaysSinceBannerSetOn =
          bannerSetOn && differenceInCalendarDays(bannerSetOn, new Date(new Date().getTime() + 7 * 86400000));

        if (!showBanner && !bannerSetOn) {
          this.setFreshBannerState();
        }
        if (!showBanner && bannerSetOn && differenceInDaysSinceBannerSetOn == 0) {
          this.setFreshBannerState();
        }
      });
    }
  }

  setFreshBannerState() {
    this.showBanner = true;
    this.localStoreService.set('showBanner', true);
    this.cd.detectChanges();
    this.emitShowBanner();
  }

  dismiss() {
    this.showBanner = false;
    this.localStoreService.clear('showBanner');

    // Set the start date for countdown so that user can be reminded again in a week
    this.localStoreService.set('bannerSetOn', new Date().getTime());
    this.toggleMobileBanner.emit(this.showBanner);
    this.emitShowBanner();
  }

  emitShowBanner() {
    this.toggleMobileBanner.emit(this.showBanner);
  }
}
