import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { LoadModel } from '@haulynx/store';
import { loadTabsConfig, Tab } from '@haulynx/types';
import { findKey, get } from 'lodash';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class LoadTabSelectedGuard implements CanActivate {
  constructor(private loadModel: LoadModel) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const { id = null, loadId = null, status = null } = route.params;
    let selected = null;

    if (id || loadId || status) {
      let url = null;
      if (id) {
        url = `item/${id}`;
      }

      if (loadId) {
        url = `view/${loadId}`;
      }

      if (status) {
        url = `search/${status}`;
      }

      selected = new Tab({
        id: id || loadId || status,
        label: '',
        url,
        closable: true,
        selected: !status,
        hideLabel: false,
      });
    } else {
      const { url } = state;
      const activeTabKey = findKey(loadTabsConfig, (item) => url.includes(item.url));

      selected = new Tab(loadTabsConfig[activeTabKey]);
    }
    if (selected.label == '') {
      selected.hideLabel = true;
    }
    return this.loadModel.tabsSelected$.pipe(
      withLatestFrom(this.loadModel.tabs$),
      map(([tabSelected, tabs]) => {
        const tabId = get(tabSelected, 'id', null);
        const activeId = get(selected, 'id', null);

        if (activeId && !tabs.get(activeId)) {
          this.loadModel.addTabs([selected]);
        }

        if (activeId && activeId !== tabId) {
          this.loadModel.selectTab(selected);
        }

        return true;
      })
    );
  }
}
