import { SubscriptionAction } from '@haulynx/types';

export const loadActionEventSubscription = (actions: SubscriptionAction[], loadIds: string[]) => {
  const loads = loadIds.map((id: string) => `"${id}"`);
  return `
    subscription {
      loadActionEvent(action: [${actions.join(',')}], loadIds: [${loads.join(',')}]) {
        load{
          id
        }
        user {
          usxId
          id
          name
          email
        }
        action
        timestamp
        expiredAt
      }
    }
  `;
};
