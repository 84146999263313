import gql from 'graphql-tag';

export const GetUserById = gql(`
  query GetUserById($id: ID!) {
    getUserById(id: $id) {
      id
      email
      name
      role
      permissions
      phone
      usxId
      manager
      office
      team
      jobTitle
      isDriver
      isCarrier
      isShipper
      isElogAdmin
      isCompanyAdmin
      isHaulynxAdmin
      isVerified
      acceptedTos
      isPersonalConveyanceEnabled
      isYardMovesEnabled
      trailerCreationEnabled
      teamDrivingEnabled
      licenseNumber
      issuingState
      prefs {
        timeZone
        smsNotifications
        emailNotifications
        mobileNotifications
        dailyNotifications
      }
      broker {
        credits
        dot
        id
        isHaulynxBroker
        name
        phone
      }
      carrier {
        id
        complianceStatus {
          bookingPermissions {
            type
            isInCompliance
            invalidReasons
          }
        }
        name
        dot
        temperature
        insurance
        weight
        owner
        mcNumber
        equipmentType
        isHaulynxUser
        isEldCarrier
        eldEnabledDate
        isLoadDistributionEnabled
        loadFeedEnabled
        loadFeedEnabledDate
        hazmatCapable
        rank
        maximumDeadMiles
        minimumLoadValue
        phone
        email
        timeZone
        addressStreet
        addressStreet2
        addressCity
        addressState
        addressCountry
        saferWatchData {
          mcNumber
        }
        rmisRegistration {
          isRmisSetup
          isUsxCertified
          hasComplianceProcessedCarrier
          fleetRegistered
          w9Orw8Registered
          comcheckAdvancesRegistered
          paymentOptionRegistered
          supplierDiversityRegistered
        }
      }
    }
  }
`);
