import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  Output,
  Self,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, FormControlName } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldControl } from '@angular/material/form-field';
import { PhoneMaskPipe } from '@haulynx/pipes';
import { EntityOptions, EntityTypes, IDropDown, TrackingType, TrailerOwnerType } from '@haulynx/types';
import { aliveWhile, listToArray, mapToArray } from '@haulynx/utils';
import { List, Map } from 'immutable';
import { filter, find, toLower, trim } from 'lodash';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { TrackingAvail } from '../pay-line-items/truck-info-section/truck-info-section.config';

@Component({
  selector: 'app-truck-drop-down',
  templateUrl: './truck-drop-down.component.html',
  styleUrls: ['./truck-drop-down.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: TruckDropDownComponent,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TruckDropDownComponent
  implements ControlValueAccessor, AfterViewInit, OnChanges, OnDestroy, MatFormFieldControl<unknown>
{
  @ViewChild('auto', { static: true }) autocomplete: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger, { static: true }) autocompleteTrigger: MatAutocompleteTrigger;
  @ViewChild('autocompleteInput', { static: true }) autocompleteInput: ElementRef;

  @Input() data: IDropDown[] | List<undefined> | Map<string, undefined> | unknown[] = [];
  @Input() isLoading = false;
  @Input() customFilter = false;
  @Input() displayLabel = 'label';
  @Input() filterLabel = 'filter';
  @Input() key = 'id';
  @Input() phoneMask? = false;
  @Input() floatLabel = 'never';
  @Input() chooseDefaultOnTab = false;
  @Input() templateLabel: TemplateRef<unknown>;
  @Input() changeableData = false;
  @Input() entityType: EntityTypes;
  @Input() trailerOwner: string;
  @Input() cypressName = '';
  @Output() selected = new EventEmitter();
  @Output() forceSelect = new EventEmitter();
  @Output() manualSelect = new EventEmitter();
  @Output() keywordChanges = new EventEmitter();

  readonly autofilled: boolean;
  readonly controlType: string;
  readonly id: string;
  readonly stateChanges: Subject<void> = new Subject<void>();
  private alive = aliveWhile();
  items = [];
  isSelected = false;
  keywordSearch: FormControl = new FormControl();
  filteredData = new Subject<unknown[]>();
  selectedIcon: string;
  selectedClass: string;
  onTouch: (value) => unknown;
  currentlyFiltered = [];
  entityTypes = EntityTypes;
  trackingAvail = TrackingAvail;
  trackingTypes = TrackingType;
  selectedRow;
  cols: any[];
  entityOptions = EntityOptions;
  owner = TrailerOwnerType;

  private stringOnPanelOpen: string; // stores the value of the input when the panel last opened
  private _value = null;
  private _placeholder: string = null;
  private _required = false;
  private _disabled = false;
  private _focused = false;
  private _errorState = false;
  private _hideIcon = false;

  constructor(
    @Optional() @Self() public ngControl: FormControlName,
    @Optional() private dialogRef: MatDialogRef<TruckDropDownComponent>,
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
    private phoneMaskPipe: PhoneMaskPipe
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.fm
      .monitor(this.elRef.nativeElement, true)
      .pipe(takeUntil(this.alive))
      .subscribe((origin) => {
        this.focused = !!origin;
        this.stateChanges.next();
      });

    this.trackByFn = this.trackByFn.bind(this);
  }

  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange(): void {
    if (!document.hidden) {
      this.autocompleteInput.nativeElement.blur();
    }
  }

  @HostBinding('class.floating')
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(req: boolean) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  @Input()
  set hideIcon(value: boolean) {
    this._hideIcon = coerceBooleanProperty(value);
  }

  get hideIcon(): boolean {
    return this._hideIcon;
  }

  @Input()
  set placeholder(newValue: string) {
    this._placeholder = newValue;
    this.stateChanges.next();
  }

  get placeholder(): string {
    return this._placeholder;
  }

  set value(newValue: string) {
    this._value = newValue;
  }

  get value(): string {
    return this._value;
  }

  get empty(): boolean {
    return !this.value;
  }

  set focused(newValue: boolean) {
    this._focused = newValue;
  }

  get focused(): boolean {
    return this._focused;
  }

  get errorState(): boolean {
    return !!this._errorState;
  }

  set errorState(newValue: boolean) {
    this._errorState = newValue;
  }

  private get displayKeyword() {
    const items = this.getData(this.items);
    const item = find(items, { [this.key]: this.value });

    return (item && (item[this.displayLabel] || ' ')) || null;
  }

  ngAfterViewInit(): void {
    this.keywordSearch.valueChanges.pipe(takeUntil(this.alive), distinctUntilChanged()).subscribe((keyword) => {
      const filterLabel = this.customFilter ? this.filterLabel : this.displayLabel;
      const items = this.filterBy(this.items, keyword, filterLabel);
      this.currentlyFiltered = items;
      this.filteredData.next(items.slice(0, 200));
      this.isSelected = false;

      this.errorState = !!(this.ngControl && this.ngControl.errors);
      this.stateChanges.next();
      this.keywordChanges.emit(keyword);
      if (!keyword && this.dataHasLength()) {
        this.value = null;
      }
      this.propagateChanges(this.value);
    });

    this.autocompleteInput.nativeElement.addEventListener('focusout', (event) => {
      if (this.key === 'trailerNumber') {
        if (this.keywordSearch.value && !this.items.find((item) => item[this.key] === this.keywordSearch.value)) {
          this.forceSelect.emit(this.keywordSearch.value);
          this.closeDropDown();
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { data } = changes;

    if (data && data.currentValue) {
      this.items = this.getData(data.currentValue);

      if (data.currentValue?.length > data.previousValue?.length) {
        const currentFilter = data.currentValue.filter(function (obj) {
          return data.previousValue?.indexOf(obj) == -1;
        });
        this.filteredData.next(currentFilter.slice(0, 200));
        this.closeDropDown();
      }
    }
  }

  panelOpen(): void {
    if (this.keywordSearch.enabled) {
      this.stringOnPanelOpen = this.keywordSearch.value;
    }
  }

  select(event): void {
    this.autocompleteInput.nativeElement.focus();
    const { value } = event.option;
    this.selectOption(value);
    this.manualSelect.emit();
  }

  propagateChanges(_: unknown): void {}

  registerOnChange(fn: () => unknown): void {
    this.propagateChanges = fn;
  }

  registerOnTouched(fn: () => unknown): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;

    if (this.disabled) {
      this.keywordSearch.disable();
    } else {
      this.keywordSearch.enable();
    }
  }

  trackByFn(index: number, item: unknown): string | number {
    return item[this.key];
  }

  writeValue(idSelected: string): void {
    this.value = idSelected;
    const items = this.getData(this.items);
    if (items.length) {
      this.keywordSearch.patchValue(this.displayKeyword);
    }
  }

  getData(data: unknown[] | List<unknown> | Map<unknown, unknown>): unknown[] {
    return List.isList(data) ? listToArray(data) : Map.isMap(data) ? mapToArray(data) : data;
  }

  showPanel(event: Event, auto: MatAutocomplete): void {
    event.stopImmediatePropagation();

    if (this.keywordSearch.enabled) {
      if (auto.isOpen) {
        this.closeDropDown();
      } else {
        this.openDropDown();
      }
    }
  }

  closeDropDown(): void {
    this.autocompleteTrigger.closePanel();
    this.keywordSearch.patchValue(this.displayKeyword);
  }

  openDropDown(): void {
    this.autocompleteInput.nativeElement.focus();
    this.autocompleteTrigger.openPanel();
    this.highlightText();
    this.filteredData.next(this.items.slice(0, 200));
  }

  filterBy(data: IDropDown[], searchKeyword: string, displayLabel: string): IDropDown[] {
    const value = trim(toLower(searchKeyword));
    const newData = !searchKeyword
      ? data
      : filter<IDropDown>(data, (item: IDropDown) => {
          const label = toLower(item[displayLabel]);

          return label.indexOf(value) > -1;
        });

    return newData;
  }

  setDescribedByIds(ids: string[]): void {
    // throw new Error("Method not implemented.");
  }

  onContainerClick(event: MouseEvent): void {
    if (this.keywordSearch.enabled) {
      this.autocompleteTrigger.openPanel();
    }
    this.highlightText();
  }

  ngOnDestroy(): void {
    this.alive.destroy();
    this.stateChanges.complete();
  }

  public selectOption(value: string): void {
    const selectedItem = this.items.find((item) => item[this.key] === value);

    if (selectedItem) {
      const { svgIcon, className } = selectedItem;
      this.selectedIcon = svgIcon;
      this.selectedClass = className;
    }

    this.value = value;
    this.propagateChanges(this.value);
    this.keywordSearch.patchValue(this.displayKeyword);
    this.selected.emit(value);
    this.isSelected = true;
  }

  private dataHasLength(): boolean {
    if (Array.isArray(this.data)) {
      return !!this.data.length;
    } else if (this.data?.values) {
      return !!this.data.values.length;
    } else {
      return false;
    }
  }

  private highlightText(): void {
    this.autocompleteInput.nativeElement.select();
  }
}
