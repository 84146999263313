import { Component } from '@angular/core';

@Component({
  selector: 'app-hazmat-warning',
  templateUrl: './hazmat-warning.component.html',
  styleUrls: ['./hazmat-warning.component.scss'],
})
export class HazmatWarningComponent {
  constructor() {}
}
