import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { EntityTypes } from '@haulynx/types';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'app-data-table-overlay',
  templateUrl: './data-table-overlay.component.html',
  styleUrls: ['./data-table-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataTableOverlayComponent implements OnChanges {
  @Input() placeholder: '';
  @Input() data: Array<unknown>;
  @Input() cols: { field: string; header: string }[];
  @Input() selectedRow;
  @Input() displayLabel;
  @Input() footerButtonLabel;
  @Input() isLoading = false;
  @Input() disabled = false;
  @Input() cypressName = '';
  @Input() set clearSelectedRow(clear: boolean) {
    if (clear) {
      this.display = '';
      this.selectedItem = null;
    }
  }
  @Input() buttonIndex: string;

  @Output() buttonSelectIndex = new EventEmitter();
  @Output() rowSelect = new EventEmitter();
  @Output() buttonSelect = new EventEmitter();

  @ViewChild('op', { static: true }) op: OverlayPanel;
  @ViewChild('input') input: ElementRef;

  entityTypes = EntityTypes;
  selectedItem;
  display = null;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    const { selectedRow = null } = changes;
    if (selectedRow?.currentValue) {
      this.display = selectedRow.currentValue?.[this.displayLabel] || '';
    }
  }

  inputChanges(event) {
    if (event.input.nativeElement.value.length === 0) {
      this.rowSelect.emit('');
    }
  }

  selectRow(event) {
    this.rowSelect.emit(event);
    this.selectedItem = event.data;
    if (this.selectedItem) {
      this.display = this.selectedItem[this.displayLabel] || ' ' || null;
    }
    this.op.onCloseClick(event);
  }

  onFooterButtonClicked() {
    this.buttonSelectIndex.emit(this.buttonIndex);
    this.buttonSelect.emit();
  }

  openPanel(event) {
    if (this.op.overlayVisible) {
      this.op.onCloseClick(event);
    } else {
      this.input.nativeElement.focus();
      this.op.show(event);
    }
  }

  closePanel() {
    this.op.onCloseClick(new Event('click'));
  }
}
