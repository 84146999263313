import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { LoginService, UserService } from '@haulynx/services';
import { Observable } from 'rxjs';

@Injectable()
export class CompanyAdminGuard implements CanActivate, CanActivateChild {
  private isCompanyAdmin: boolean;
  private isAdmin: boolean;
  constructor(private loginService: LoginService, private user: UserService) {
    this.user.user.subscribe((data) => {
      this.isCompanyAdmin = data.isCompanyAdmin;
    });
    this.user.isHaulynxAdmin().subscribe((admin) => {
      this.isAdmin = admin;
    });
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.loginService.isLoggedIn() && (this.isAdmin || this.isCompanyAdmin);
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(next, state);
  }
}
