import { formatDate } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoadAlternateIdPipe } from '@haulynx/pipes';
import { EnvironmentService } from '@haulynx/services';
import { ActiveLoadsModel, AppModel } from '@haulynx/store';
import {
  BookStatus,
  BulkAction,
  BulkActionPage,
  LoadIdentifierType,
  LoadsServiceLoad,
  LoadsServiceLoadStatus,
  OperationsPriority,
  RolledLoads,
  RolledReason,
  User,
  ZipLane,
} from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { toString } from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { BulkActionsDialogComponent } from '../dialogs/bulk-actions-dialog/bulk-actions-dialog.component';
import { RolledLoadsDialogComponent } from '../dialogs/rolled-loads-dialog/rolled-loads-dialog.component';

@Component({
  selector: 'app-bulk-actions',
  templateUrl: './bulk-actions.component.html',
  styleUrls: ['./bulk-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkActionsComponent implements OnInit, OnDestroy {
  @Input() displayAssignTo = true;
  @Input() showTitle = true;
  @Input() loads = [];
  @Input() verticalActions = false;
  @Input() bulkActionsPage?: BulkActionPage = BulkActionPage.SEARCH;
  @Output() action = new EventEmitter<unknown>();
  @Output() closedDialog = new EventEmitter();
  @Output() downloadCSV = new EventEmitter();

  LoadBulkAction = BulkAction;
  BulkActionPage = BulkActionPage;
  hoverIndex = -1;
  allBrokers: User[];
  alive = aliveWhile();
  isCompanyAdmin = false;
  usxId = '';
  maxBuyFormValue;
  percentShiftFormValue;
  dollarAmountFormValue;

  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private environmentService: EnvironmentService,
    public activeLoadsModel: ActiveLoadsModel,
    private loadAlternateIdPipe: LoadAlternateIdPipe,
    private appModel: AppModel
  ) {
    this.appModel.user$.pipe(takeUntil(this.alive)).subscribe((user: User) => {
      this.isCompanyAdmin = user?.isCompanyAdmin;
      this.usxId = user?.usxId;
    });
  }

  ngOnInit(): void {
    if (this.bulkActionsPage !== BulkActionPage.CARRIER_SEARCH) {
      this.activeLoadsModel.searchBrokers({});
    }
    this.activeLoadsModel.brokersEntities$.pipe(takeUntil(this.alive)).subscribe((brokers) => {
      this.allBrokers = brokers;
    });
  }

  openBulkActionsDetailsModal(option: BulkAction): void {
    if (this.loads.length > 0) {
      const value =
        option === BulkAction.CONFIRM_UPDATE_ADJUST_PERCENTAGE
          ? this.percentShiftFormValue
          : this.dollarAmountFormValue;
      const modalRef = this.dialog.open(BulkActionsDialogComponent, {
        data: {
          loads: this.loads,
          option: option,
          allBrokers: this.allBrokers,
          isCompanyAdmin: this.isCompanyAdmin,
          usxId: this.usxId,
          confirmationValue: value,
        },
        width: this.getWidth(option),
        height: this.getHeight(option),
      });
      modalRef
        .afterClosed()
        .pipe(takeUntil(this.alive))
        .subscribe((value) => {
          if (!value) {
            return;
          }
          if (option !== BulkAction.UPDATE_MAX_BUY && option !== BulkAction.ADJUST_PERCENTAGE) {
            this.closedDialog.emit();
          }
          switch (option) {
            case BulkAction.UPDATE_MAX_BUY:
              this.maxBuyFormValue = value;
              this.openBulkActionsDetailsModal(BulkAction.CONFIRM_UPDATE_MAX_BUY);
              break;
            case BulkAction.ASSIGN_REPS:
              this.onAssignTo(value);
              break;
            case BulkAction.PRIORITY_CHANGE:
              this.onPriorityChange(value);
              break;
            case BulkAction.CONFIRM_UPDATE_MAX_BUY:
              this.closedDialog.emit();
              this.onMaxBuy(this.maxBuyFormValue);
              break;
            case BulkAction.ADJUST_PERCENTAGE:
              this.closedDialog.emit();
              if (this.loads.length > 50 || value > 30) {
                this.percentShiftFormValue = value;
                this.openBulkActionsDetailsModal(BulkAction.CONFIRM_UPDATE_ADJUST_PERCENTAGE);
                break;
              } else {
                this.onAdjustPercentage(value);
                break;
              }
            case BulkAction.CONFIRM_UPDATE_ADJUST_PERCENTAGE:
              this.closedDialog.emit();
              this.onAdjustPercentage(this.percentShiftFormValue);
              break;
            case BulkAction.ADJUST_DOLLAR_AMOUNT:
              this.closedDialog.emit();
              if (this.loads.length > 50 || value > 200) {
                this.dollarAmountFormValue = value;
                this.openBulkActionsDetailsModal(BulkAction.CONFIRM_UPDATE_ADJUST_DOLLAR_AMOUNT);
                break;
              } else {
                this.onAdjustDollarAmount(value);
                break;
              }
            case BulkAction.CONFIRM_UPDATE_ADJUST_DOLLAR_AMOUNT:
              this.closedDialog.emit();
              this.onAdjustDollarAmount(this.dollarAmountFormValue);
              break;
            case BulkAction.DELETE_MISSION:
              this.closedDialog.emit();
              break;
          }
        });
    }
  }

  onMaxBuy(maxBuy: { value: number; type: 'dollar-sign-icon' | 'percent-icon' }): void {
    const payload = this.loads.map((load: LoadsServiceLoad) => {
      let value: number;
      if (maxBuy.type === 'dollar-sign-icon') {
        value = load.paymentDetails.maxBuy + maxBuy.value;
      } else {
        value = load.paymentDetails.maxBuy * (1 + maxBuy.value / 100);
      }
      return {
        loadId: load.id,
        maxBuy: value,
      };
    });
    this.action.emit({
      action: BulkAction.UPDATE_MAX_BUY,
      loadInputs: payload,
    });
  }

  onShareLoads(): string {
    const loadStrings: string[] = this.loads.map((load) => {
      const origin = load.locations[0];
      const orderNumber = this.loadAlternateIdPipe.transform(load, LoadIdentifierType.ORDER_NUMBER);
      const dest = load.locations[load.locations?.length - 1];
      const link = this.environmentService.isPreviewEnvironment()
        ? `https://app.haulynx.com/loads/${load.id}`
        : `${window.location.origin}/loads/${load.id}`;

      return (
        `#${orderNumber}: ${origin.city}, ${origin.state} ${formatDate(
          origin.appointmentStart,
          'MMM',
          'en-US',
          origin.timezone
        )} ` +
        `${formatDate(origin.appointmentStart, 'd', 'en-US', origin.timezone)} -> ` +
        `${dest.city}, ${dest.state} ${formatDate(dest.appointmentEnd, 'MMM', 'en-US', dest.timezone)} ` +
        `${formatDate(dest.appointmentEnd, 'd', 'en-US', dest.timezone)}\n${link}`
      );
    });

    return loadStrings.join('\n\n');
  }

  copyLoadSuccess(): void {
    const confirmation = this.loads.length > 1 ? 'loads' : 'load';
    this.snackBar.open('Successfully copied ' + confirmation, 'Okay', { duration: 3000 });
  }

  onAssignTo(brokers): void {
    const payload = this.loads.map((load: LoadsServiceLoad) => {
      return { loadId: load.id, assignedBrokers: brokers };
    });
    this.action.emit({
      action: BulkAction.ASSIGN_REPS,
      loadInputs: payload,
    });
  }

  onPriorityChange(priority: OperationsPriority): void {
    const payload = this.loads.map((load: LoadsServiceLoad) => {
      return { loadId: load.id, operationsPriority: priority };
    });
    this.action.emit({
      action: BulkAction.PRIORITY_CHANGE,
      loadInputs: payload,
    });
  }

  onAdjustPercentage(percent: number): void {
    const payload = this.loads.map((lane: ZipLane) => {
      return {
        bidId: toString(lane.bidId),
        bulkAdjustPercent: toString(percent / 100),
      };
    });
    this.action.emit({
      action: BulkAction.ADJUST_PERCENTAGE,
      laneInputs: payload,
    });
  }

  onAdjustDollarAmount(dollarAmount: number): void {
    const payload = this.loads.map((lane: ZipLane) => {
      return {
        bidId: toString(lane.bidId),
        bulkAdjustDollars: toString(dollarAmount),
        miles: toString(lane.miles),
      };
    });
    this.action.emit({
      action: BulkAction.ADJUST_DOLLAR_AMOUNT,
      laneInputs: payload,
    });
  }

  onDownloadCSV(): void {
    this.downloadCSV.emit();
  }

  private getWidth(option: BulkAction): string {
    switch (option) {
      case BulkAction.UPDATE_MAX_BUY:
        return '400px';
      case BulkAction.ASSIGN_REPS:
        return '400px';
      case BulkAction.PRIORITY_CHANGE:
        return '560px';
      case BulkAction.ADJUST_PERCENTAGE:
        return '450px';
      case BulkAction.CONFIRM_UPDATE_ADJUST_PERCENTAGE:
        return '420px';
      case BulkAction.ADJUST_DOLLAR_AMOUNT:
        return '465px';
      case BulkAction.CONFIRM_UPDATE_ADJUST_DOLLAR_AMOUNT:
        return '420px';
      case BulkAction.DELETE_MISSION:
        return '410px';
    }
    return '';
  }

  private getHeight(option: BulkAction): string {
    switch (option) {
      case BulkAction.UPDATE_MAX_BUY:
        return '350px';
      case BulkAction.ASSIGN_REPS:
        return '356px';
      case BulkAction.PRIORITY_CHANGE:
        return '376px';
      case BulkAction.ADJUST_PERCENTAGE:
        return '350px';
      case BulkAction.CONFIRM_UPDATE_ADJUST_PERCENTAGE:
        return '300px';
      case BulkAction.ADJUST_DOLLAR_AMOUNT:
        return '350px';
      case BulkAction.CONFIRM_UPDATE_ADJUST_DOLLAR_AMOUNT:
        return '300px';
      case BulkAction.DELETE_MISSION:
        return '300px';
    }
    return '';
  }

  get displaySelectedAssignTo(): boolean {
    const bookedLoads = this.loads.find(
      (load) => load.bookStatus === BookStatus.BOOKED || load.loadStatus === LoadsServiceLoadStatus.FINALLED
    );

    if (!bookedLoads) {
      return true;
    } else {
      return false;
    }
  }

  openRolledLoadsDialog(): void {
    if (this.loads.length > 0) {
      this.dialog
        .open(RolledLoadsDialogComponent, {
          data: {
            author: this.usxId,
          },
        })
        .afterClosed()
        .pipe(takeUntil(this.alive))
        .subscribe((result: RolledReason) => {
          this.closedDialog.emit();
          if (result) {
            const entityIds = this.loads.map((load: LoadsServiceLoad) => {
              return load.id;
            });
            const payload: RolledLoads = {
              loadIds: entityIds,
              rolledReason: result,
            };
            this.action.emit({
              action: BulkAction.REPORT_ROLLED_LOADS,
              reportedLoadsInput: payload,
            });
          }
        });
    }
  }

  ngOnDestroy() {
    this.alive.destroy();
  }
}
