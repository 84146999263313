import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@haulynx/directives';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { AsyncEntityGeocodingeModule } from '@haulynx/store';
import { DatetimePickerModule } from '../datetime-picker/datetime-picker.module';
import { DropDownModule } from '../drop-down/drop-down.module';
import { AdvancedSearchComponent } from './advanced-search.component';
import { SearchChipComponent } from './components/search-chip';
import { SearchChipListComponent } from './components/search-chip-list';
import { SearchCustomDateRangeComponent } from './components/search-custom-date-range';
import { SearchDateRangeComponent } from './components/search-date-range-form';
import { SearchPlaceDropdownComponent } from './components/search-place-dropdown';
import { SearchInputComponent } from './components/search-input';
import { SearchInputDropdownComponent } from './components/search-input-dropdown';
import { SearchLocationComponent } from './components/search-location-form';
import { SearchMoreInfoComponent } from './components/search-more-info';
import { SearchMoreInfoDialogComponent } from './components/search-more-info-dialog';
import { SearchNumberRangeComponent } from './components/search-number-range-form';
import { SearchOptionsDropdownComponent } from './components/search-options-dropdown';
import { SearchOptionsComponent } from './components/search-options-form';
import { SearchRecentSearchComponent } from './components/search-recent-search';

@NgModule({
  declarations: [
    AdvancedSearchComponent,
    SearchInputComponent,
    SearchInputDropdownComponent,
    SearchChipComponent,
    SearchMoreInfoComponent,
    SearchChipListComponent,
    SearchMoreInfoDialogComponent,
    SearchNumberRangeComponent,
    SearchDateRangeComponent,
    SearchCustomDateRangeComponent,
    SearchLocationComponent,
    SearchOptionsComponent,
    SearchPlaceDropdownComponent,
    SearchRecentSearchComponent,
    SearchOptionsDropdownComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DatetimePickerModule,
    MaterialModule,
    DropDownModule,
    PipesModule,
    DirectivesModule,
    AsyncEntityGeocodingeModule,
  ],
  exports: [AdvancedSearchComponent, SearchChipListComponent, SearchPlaceDropdownComponent],
})
export class AdvancedSearchModule {}
