<div class="search-chip-list-container">
  <ng-container *ngFor="let filter of selectedFilters; let i = index">
    <app-search-chip
      [filter]="filter"
      [form]="form"
      [tabIndex]="i"
      [displayCloseButton]="displayCloseButton"
      [showDaysDifference]="showDaysDifference"
      (removeSearchFilter)="onRemoveSearchFilter($event)"
      (chipFocus)="onSearchFilterFocus($event)"
      (chipBlur)="onSearchFilterBlur($event)"
      data-test="filter-chip"
    >
    </app-search-chip>
  </ng-container>

  <app-search-chip
    *ngIf="selectedFilters.length > 1 && displayClearAllButton"
    [isClearAllChip]="true"
    [displayCloseButton]="false"
    (removeAllSearchFilters)="onRemoveAllSearchFilters()"
  >
  </app-search-chip>
</div>
