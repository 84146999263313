import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoadFeedModel } from '@haulynx/store';
import { BidStatusType, LoadsServiceLoad } from '@haulynx/types';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CarrierAcceptCounterOfferDialogComponent } from '../carrier-accept-counter-offer-dialog';
import { CarrierCreateBidDialogComponent } from '../carrier-create-bid-dialog';

@Component({
  selector: 'app-carrier-bid-button',
  templateUrl: './carrier-bid-button.component.html',
  styleUrls: ['./carrier-bid-button.component.scss'],
})
export class CarrierBidButtonComponent {
  @Input() load: LoadsServiceLoad = null;
  @Output() finishedCreatingBid = new EventEmitter();
  @Output() finishedAcceptingCounterAndBook = new EventEmitter();

  public BidStatusType = BidStatusType;

  constructor(private matDialog: MatDialog, public loadFeedModel: LoadFeedModel) {}

  acceptCounterOffer(): void {
    this.matDialog
      .open(CarrierAcceptCounterOfferDialogComponent, {
        data: { price: this.load.carrierBid.activeCounterOffer.price, status: this.load.bookStatus },
      })
      .afterClosed()
      .subscribe((accepted: boolean) => {
        if (accepted) {
          this.loadFeedModel.acceptCounterBid(this.load);
          const creating = new Subject();
          this.loadFeedModel.isLoadingCounterAccepted$.pipe(takeUntil(creating)).subscribe((loading: boolean) => {
            if (!loading) {
              creating.next();
              creating.complete();
              this.finishedAcceptingCounterAndBook.emit();
            }
          });
        }
      });
  }

  createNewBid(): void {
    if (this.load) {
      this.matDialog
        .open(CarrierCreateBidDialogComponent, {
          data: { load: this.load },
          minWidth: '50%',
        })
        .afterClosed()
        .subscribe((created: boolean) => {
          if (created) {
            const creating = new Subject();
            this.loadFeedModel.isLoadingBidCreate$.pipe(takeUntil(creating)).subscribe((loading: boolean) => {
              if (!loading) {
                creating.next();
                creating.complete();
                this.finishedCreatingBid.emit();
              }
            });
          }
        });
    }
  }
}
