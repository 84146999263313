import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-imei-upload',
  templateUrl: './imei-upload.component.html',
  styleUrls: ['./imei-upload.component.scss'],
})
export class ImeiUploadComponentComponent {
  imeis: number[];
  importMessage: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { imeis: number[]; importMessage: string },
    public dialogRef: MatDialogRef<ImeiUploadComponentComponent>
  ) {
    this.imeis = data.imeis;
    this.importMessage = data.importMessage;
  }

  confirm(): void {
    this.dialogRef.close(true);
  }
}
