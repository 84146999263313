import gql from 'graphql-tag';

export const DeleteMilestoneComment = gql`
  mutation removeMilestoneComment($loadId: StrictId!, $milestoneId: StrictId!, $commentId: StrictId!) {
    removeMilestoneComment(loadId: $loadId, milestoneId: $milestoneId, commentId: $commentId) {
      id
      milestones {
        authorId
        authorType
        comments {
          contact
          creatorId
          creatorType
          id
          text
          timestamp
          timezone
        }
        contact {
          email
          name
          phone
        }
        createdAt
        id
        lastUpdated
        loadLocationAddress
        location {
          latitude
          longitude
          timestamp
          timezone
        }
        locationId
        logs {
          editedBy
          editedByType
          id
          primaryEvent {
            latitude
            longitude
            timestamp
            timezone
          }
          secondaryEvent {
            latitude
            longitude
            timestamp
            timezone
          }
          subType
          timestamp
          status
        }
        type
      }
    }
  }
`;
