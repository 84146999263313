import { CarrierInsuranceAndSafety, CarrierKpiMetrics, CarrierUsx, ITabsState } from '@haulynx/types';
import { createFeatureSelector } from '@ngrx/store';
import { Record } from 'immutable';
import { IFormState } from '../../shared/store/form/state';
import { combineAdaptersInitialState } from '../../shared/store/helpers/adapter';
import { ISearchState } from '../../shared/store/search/state';
import {
  carrierAdapter,
  carrierDashboardAdapter,
  carrierDashboardLanesAdapter,
  carrierDashboardTrucksAdapter,
  carrierTabsAdapter,
} from './adapters';

export interface ICarrierState {
  searchTrucks: ISearchState;
  searchLanes: ISearchState;
  searchRecommendation: ISearchState;
  searchCarrier: ISearchState;
  carrierForm: IFormState<any>;
  tabs: ITabsState;
  isLoading: boolean;

  routes: any;
  isLoadingRoutes: boolean;

  carrierKpiMetrics: CarrierKpiMetrics;
  isLoadingCarrierKpiMetrics: boolean;
  carrierInsuranceAndSafety: CarrierInsuranceAndSafety;
  isLoadingPostTruck: boolean;
  carriersTabs: ITabsState;
  isLoadingCarriersStatus: boolean;
  carriersStatus: CarrierUsx;
  loadingMapTruckIds: { [key: string]: boolean };
}

const initialState = {
  isLoading: false,
  routes: {},
  isLoadingRoutes: false,
  carrierKpiMetrics: null,
  isLoadingCarrierKpiMetrics: false,
  carrierInsuranceAndSafety: null,
  isLoadingCarrierInsuranceAndSafety: false,
  isLoadingPostTruck: false,
  // postTruckRemoving: null,
  isLoadingCarriersStatus: false,
  carriersStatus: null,
  searchTrucks: {},
  loadingMapTruckIds: {},
  ...combineAdaptersInitialState(carrierAdapter),
  ...combineAdaptersInitialState(carrierDashboardAdapter),
  ...combineAdaptersInitialState(carrierDashboardLanesAdapter),
  ...combineAdaptersInitialState(carrierDashboardTrucksAdapter),
  ...combineAdaptersInitialState(carrierTabsAdapter),
};

export class CarriersState extends Record(initialState) implements ICarrierState {
  public searchTrucks: ISearchState;
  public searchLanes: ISearchState;
  public searchRecommendation: ISearchState;
  public searchCarrier: ISearchState;
  isLoadingCarrierInsuranceAndSafety: boolean;
  carrierForm: IFormState<any>;
  tabs: ITabsState;
  isLoading: boolean;

  routes: any;
  isLoadingRoutes: boolean;

  carrierKpiMetrics: CarrierKpiMetrics;
  isLoadingCarrierKpiMetrics: boolean;

  carrierInsuranceAndSafety: CarrierInsuranceAndSafety;

  isLoadingPostTruck: boolean;
  carriersTabs: ITabsState;

  isLoadingCarriersStatus: boolean;
  carriersStatus: CarrierUsx;
  loadingMapTruckIds: { [key: string]: boolean };
}

export const getCarriersState = createFeatureSelector<CarriersState>('carriers');
