<div class="search-input-dropdown-container" #wrapper>
  <div
    class="sid-filter-options"
    *ngFor="let filter of displayFilters; let i = index"
    [class.isHovering]="i === indexOfFocus"
    (click)="selectSearchType(i)"
    (mouseenter)="indexOfFocus = i; isMouseFocus = true"
    (mouseleave)="indexOfFocus = -1; isMouseFocus = false"
    [scrollIntoView]="i === indexOfFocus && !isMouseFocus"
  >
    <div class="sid-filter-option">
      <div class="sid-filter-name">
        <div class="sid-filter-type" tabindex="{{ i }}" attr.data-test="{{ filter.name }}">{{ filter.name }}</div>
      </div>
      <div class="sid-filter-description">
        {{ filter.description }}
      </div>
    </div>
  </div>
</div>
