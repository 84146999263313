<div class="row">
  <div class="col-lg-12 pt-1 pb-1 carrier-action">
    <button mat-raised-button color="accent" (click)="emailCarriers()">Email Carriers</button>
  </div>
</div>
<div class="row">
  <div class="col-lg-12">
    <data-table
      [displayedColumns]="carriersColumns"
      [data]="carriers"
      [list]="true"
      [loading]="isLoading"
      [templateCellRef]="templateCell"
    >
      <ng-template #templateCell let-row="row" let-column="column">
        <div *ngIf="column === 'carrierName'">
          <a
            target="_blank"
            style="color: #dae2f6"
            href="https://console.firebase.google.com/u/0/project/haulynx-elog-app/database/haulynx-elog-app/data/carriers/{{
              row.id
            }}"
            >{{ row.name }}</a
          >
        </div>

        <div *ngIf="column === 'dot'">
          <a
            target="_blank"
            style="color: #dae2f6"
            href="https://ai.fmcsa.dot.gov/SMS/Carrier/{{ row.dot }}/Overview.aspx?FirstView=True"
            >{{ row.dot }}</a
          >
        </div>

        <div *ngIf="column === 'equipmentType'">
          {{ formatEquipments(row.equipmentType) }}
        </div>

        <div *ngIf="column === 'saferData'" (click)="editCarrier(row)">
          <mat-icon
            matTooltip="{{ displaySaferWatchData(row?.saferWatchData) }}"
            *ngIf="row.saferWatchData"
            style="vertical-align: middle; color: rgb(172, 172, 172)"
          >
            note
          </mat-icon>
        </div>

        <div *ngIf="column === 'demoCarriers'">
          <mat-checkbox [checked]="isDemoCarrier(row)" (change)="setDemoCarrier($event, row)"></mat-checkbox>
        </div>

        <div *ngIf="column === 'isHaulynxUser'">
          <mat-checkbox [checked]="row.isHaulynxUser" [disabled]="true"></mat-checkbox>
        </div>

        <div *ngIf="column === 'options'">
          <mat-icon [matMenuTriggerFor]="menu">more_vert</mat-icon>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="emailCarrier(row)">Email Carrier</button>
            <button mat-menu-item (click)="editCarrier(row)">Edit Carrier</button>
            <button mat-menu-item (click)="deleteCarrier(row)">Delete Carrier</button>
          </mat-menu>
        </div>
      </ng-template>
    </data-table>
  </div>
</div>
