import { Pipe, PipeTransform } from '@angular/core';
import { Truck } from '@haulynx/types';

@Pipe({ name: 'truckUnitDisplayName' })
export class TruckUnitDisplayNamePipe implements PipeTransform {
  transform(truck: Truck, defaultValue: any): any {
    if (!truck) {
      return defaultValue;
    }
    if (truck.type && truck.type === 'phone') {
      if (truck.lastLocation) {
        return truck.lastLocation.driverName + "'s Phone";
      } else {
        return 'Phone';
      }
    }
    return 'Unit # ' + (truck.unitId ? truck.unitId : truck.lastLocation ? truck.lastLocation.unitId : defaultValue);
  }
}
