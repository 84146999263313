import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { loadSearchIconsFolder, otherIconsFolder, sideBarIconsFolder } from './icons.config';

@Injectable({
  providedIn: 'root',
})
export class IconsService {
  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {}

  /**
   * To be called at beginning of app load.
   * Will register all icons listed below for all pages
   */
  public init() {
    this.loadSearchIcons();
    this.otherIcons();
    this.sideBarIcons();
  }

  private loadSearchIcons() {
    loadSearchIconsFolder.forEach((icon: string) => {
      this.registerIcon(icon, `icons/load-search/${icon}.svg`);
    });
  }

  private otherIcons() {
    otherIconsFolder.forEach((icon: string) => {
      this.registerIcon(icon, `icons/other/${icon}.svg`);
    });
  }

  private sideBarIcons() {
    sideBarIconsFolder.forEach((icon: string) => {
      this.registerIcon(icon, `icons/side-bar/${icon}.svg`);
    });
  }

  private registerIcon(name: string, path: string): void {
    this.matIconRegistry.addSvgIcon(name, this.domSanitizer.bypassSecurityTrustResourceUrl(path));
  }
}
