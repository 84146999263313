import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { GetLaneHistory } from '@haulynx/gql';
import {
  Bid,
  BookStatus,
  Carrier,
  ChartIdentifier,
  LaneHistory,
  LaneHistoryGraphDataType,
  LaneHistoryRow,
  LaneHistorySearchParameters,
  LoadIdentifierType,
  LoadLocationType,
  LoadsServiceLoad,
  LoadsServiceLoadLocation,
  LoadsServiceLoadStatus,
  PageAndSort,
  PaginatedData,
  transformLoadStatusText,
} from '@haulynx/types';
import { getDateString, getLoadsServiceLoadAlternateId, transformLoadStatus } from '@haulynx/utils';
import { ChartDataSets, ChartOptions, ChartPoint, ChartTooltipModel } from 'chart.js';
import { Dictionary, groupBy, keyBy, lowerCase, omit, reduce, startCase, unionWith } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { chartTooltipSetup, fillDataMatchingSlopeBetweenDataPoints, getDateNamePlusDays } from '../chart/chart-utils';
import { GraphqlService } from '../graphql/graphql.service';

@Injectable({
  providedIn: 'root',
})
export class LaneHistoryService {
  public renderer: Renderer2;
  private currentLaneHistoryData: LaneHistory;

  constructor(
    private graphql: GraphqlService,
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) public document
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  getLaneHistory(
    searchParameters: Partial<LaneHistorySearchParameters>,
    includeBids: boolean,
    paging: Partial<PageAndSort>
  ): Observable<{ laneHistoryData: LaneHistory; laneHistoryRows: PaginatedData<LaneHistoryRow> }> {
    const defaultPaging: PageAndSort = { limit: 500, page: 1, sort: 'ASC' };
    const page = { ...defaultPaging, ...paging };
    return this.graphql
      .query<LaneHistory>({
        query: GetLaneHistory,
        variables: {
          searchParameters: this.cleanSearchParameters(searchParameters),
          includeBids,
          paging: page,
        },
      })
      .pipe(
        map((result) => {
          this.currentLaneHistoryData = result.data['getLaneHistory'];
          return {
            laneHistoryData: this.currentLaneHistoryData,
            laneHistoryRows: this.getLaneHistoryRows(result.data['getLaneHistory']),
          };
        })
      );
  }

  private cleanSearchParameters(payload: Partial<LaneHistorySearchParameters>): Partial<LaneHistorySearchParameters> {
    const cleanedParams = omit(payload, ['originLocation', 'destinationLocation', 'includeBids']);
    return cleanedParams;
  }

  private getLaneHistoryRows(laneHistory: LaneHistory): PaginatedData<LaneHistoryRow> {
    let avgCarrierRate = 0;
    let loads: LaneHistoryRow[] = [];
    const loadsKeyedById = keyBy(laneHistory.loads, 'id');
    if (laneHistory.loads) {
      avgCarrierRate =
        laneHistory.loads?.reduce((acc, curr) => acc + curr?.paymentDetails?.price, 0) / laneHistory.loads.length || 0;
      loads = laneHistory.loads?.map((load) => {
        const status = [];
        if (load.bookStatus) {
          status.push(load.bookStatus);
          if (load.loadStatus) {
            status.push(load.loadStatus);
          }
        }

        return {
          carrier: load.carrier,
          loadId: load.id,
          locations: load.locations,
          paymentDetails: {
            ...load.paymentDetails,
            avgCarrierRateDiff: load.paymentDetails.price ? load.paymentDetails.price - avgCarrierRate : 0,
          },
          providerDetails: load.providerDetails,
          broker: load.broker,
          status: status,
          date: load.created,
          tmwNumber: getLoadsServiceLoadAlternateId(load, LoadIdentifierType.TMW_NUMBER),
        } as LaneHistoryRow;
      });
    }

    let bids = null;
    if (laneHistory.bids) {
      bids = laneHistory.bids.map((bid) => {
        const load = loadsKeyedById[bid.loadId];
        return {
          carrier: bid.carrier as Carrier,
          loadId: bid.loadId,
          bidId: bid.id,
          createdAt: bid.createdAt,
          locations: load?.locations,
          paymentDetails: {
            revenue: load?.paymentDetails?.revenue,
            price: bid.price,
            avgCarrierRateDiff: bid.price ? bid.price - avgCarrierRate : 0,
          },
          providerDetails: load?.providerDetails,
          broker: { usxId: bid.brokerId },
          status: [bid.status],
          date: bid.createdAt,
          tmwNumber: getLoadsServiceLoadAlternateId(load, LoadIdentifierType.TMW_NUMBER),
        } as LaneHistoryRow;
      });
    }

    const laneHistoryRows = unionWith(loads, bids, (a, b) => {
      return a.loadId === b.loadId && a.status === b.status;
    });

    return { data: laneHistoryRows, pagination: null };
  }

  formatLaneHistoryDataForGraph = (data: LaneHistory): LaneHistoryGraphDataType => {
    const graphMarginLoads = this.getGraphDataMarginLoads(data);
    const graphMarginBids = this.getGraphDataMarginBids(data);

    const graphRateLoads = this.getGraphDataRateLoads(data);
    const graphRateBids = this.getGraphDataRateBids(data);

    const graphRevenueLoads = this.getGraphDataRevenueLoads(data);
    return <LaneHistoryGraphDataType>{
      margin: {
        loads: graphMarginLoads,
        bids: graphMarginBids,
        loadsAverage: this.getGraphDataLoadsAverage(graphMarginLoads),
        bidsAverage: this.getGraphDataBidsAverage(graphMarginBids),
      },
      rate: {
        loads: graphRateLoads,
        bids: graphRateBids,
        loadsAverage: this.getGraphDataLoadsAverage(graphRateLoads),
        bidsAverage: this.getGraphDataBidsAverage(graphRateBids),
      },
      revenue: {
        loads: graphRevenueLoads,
        loadsAverage: this.getGraphDataLoadsAverage(graphRevenueLoads),
      },
    };
  };

  private getGraphDataMarginLoads = (data: LaneHistory): ChartDataSets => {
    const result: ChartDataSets = {
      data: [],
      ...this.chartDataSetsDefaultFields(ChartIdentifier.LOADS_MARGIN, 'scatter'),
    };
    data.loads?.forEach((load: LoadsServiceLoad) => {
      const margin = load.paymentDetails?.revenue - load.paymentDetails?.price;
      if (!load.paymentDetails.bookedAt) return;
      const date = getDateNamePlusDays(load.paymentDetails.bookedAt, 0);
      (<ChartPoint[]>result.data).push(<ChartPoint>{
        y: margin,
        x: date,
        r: <any>`${load.id}:${ChartIdentifier.LOADS_MARGIN}`,
      });
    });
    return result;
  };

  private getGraphDataMarginBids = (data: LaneHistory): ChartDataSets => {
    const result: ChartDataSets = {
      data: [],
      ...this.chartDataSetsDefaultFields(ChartIdentifier.BIDS_MARGIN, 'scatter'),
    };
    data.bids?.forEach((bid: Bid) => {
      const load = data.loads.find((load: LoadsServiceLoad) => load.id === bid.loadId);
      const margin = load.paymentDetails?.revenue - bid.price;
      const date = getDateNamePlusDays(bid.createdAt, 0);
      (<ChartPoint[]>result.data).push(<ChartPoint>{
        y: margin,
        x: date,
        r: <any>`${bid.id}:${ChartIdentifier.BIDS_MARGIN}`,
      });
    });
    return result;
  };

  private getGraphDataRateLoads = (data: LaneHistory): ChartDataSets => {
    const result: ChartDataSets = {
      data: [],
      ...this.chartDataSetsDefaultFields(ChartIdentifier.LOADS_RATE, 'scatter'),
    };
    data.loads?.forEach((load: LoadsServiceLoad) => {
      const rate = load.paymentDetails?.price;
      if (!load.paymentDetails.bookedAt) return;
      const date = getDateNamePlusDays(load.paymentDetails.bookedAt, 0);
      (<ChartPoint[]>result.data).push(<ChartPoint>{
        y: rate,
        x: date,
        r: <any>`${load.id}:${ChartIdentifier.LOADS_RATE}`,
      });
    });
    return result;
  };

  private getGraphDataRateBids = (data: LaneHistory): ChartDataSets => {
    const result: ChartDataSets = {
      data: [],
      ...this.chartDataSetsDefaultFields(ChartIdentifier.BIDS_RATE, 'scatter'),
    };
    data.bids?.forEach((bid: Bid) => {
      const rate = bid.price;
      const date = getDateNamePlusDays(bid.createdAt, 0);
      (<ChartPoint[]>result.data).push(<ChartPoint>{
        y: rate,
        x: date,
        r: <any>`${bid.id}:${ChartIdentifier.BIDS_RATE}`,
      });
    });
    return result;
  };

  private getGraphDataRevenueLoads = (data: LaneHistory): ChartDataSets => {
    const result: ChartDataSets = {
      data: [],
      ...this.chartDataSetsDefaultFields(ChartIdentifier.LOADS_REVENUE, 'scatter'),
    };
    data.loads?.forEach((load: LoadsServiceLoad) => {
      const revenue = load.paymentDetails?.revenue;
      const pickup = load.locations.find(
        (location: LoadsServiceLoadLocation) => location.locationType === LoadLocationType.PICKUP
      );
      if (!load.paymentDetails.bookedAt) return;
      const date = getDateNamePlusDays(load.paymentDetails.bookedAt, 0);
      (<ChartPoint[]>result.data).push(<ChartPoint>{
        y: revenue,
        x: date,
        r: <any>`${load.id}:${ChartIdentifier.LOADS_REVENUE}`,
      });
    });
    return result;
  };

  private getGraphDataLoadsAverage = (graphData: ChartDataSets): ChartDataSets => {
    const sortedData: ChartPoint[] = this.sortDataByDate(<ChartPoint[]>graphData.data);
    const groupedData: ChartPoint[] = this.groupSortedData(sortedData);
    const filledSortedData: ChartPoint[] = this.fillEmptyDays(groupedData);
    return {
      data: filledSortedData,
      ...this.chartDataSetsDefaultFields(ChartIdentifier.LOADS_MARGIN, 'line'),
    };
  };

  private getGraphDataBidsAverage = (graphData: ChartDataSets): ChartDataSets => {
    const sortedData: ChartPoint[] = this.sortDataByDate(<ChartPoint[]>graphData.data);
    const groupedData: ChartPoint[] = this.groupSortedData(sortedData);
    const filledSortedData: ChartPoint[] = this.fillEmptyDays(groupedData);
    return {
      data: filledSortedData,
      ...this.chartDataSetsDefaultFields(ChartIdentifier.BIDS_MARGIN, 'line'),
    };
  };

  private chartDataSetsDefaultFields = (identifier: ChartIdentifier, graphType: 'line' | 'scatter'): ChartDataSets => {
    switch (identifier) {
      case ChartIdentifier.BIDS_MARGIN:
      case ChartIdentifier.BIDS_RATE:
      case ChartIdentifier.BIDS_REVENUE: {
        if (graphType === 'line') {
          return {
            label: 'Bids',
            fill: false,
            lineTension: 0,
            pointBackgroundColor: '#00000000',
            pointBorderColor: '#00000000',
            borderColor: '#7B4EFF',
            type: 'line',
            order: 1,
          };
        } else {
          return {
            pointBackgroundColor: '#7B4EFF',
            pointBorderColor: '#7B4EFF88',
            pointRadius: 3,
            pointBorderWidth: 4,
            pointHoverRadius: 6,
            pointHoverBackgroundColor: '#7B4EFF',
            pointHoverBorderColor: '#7B4EFF88',
            pointHoverBorderWidth: 8,
            type: 'scatter',
            order: 2,
            label: '',
          };
        }
      }
      case ChartIdentifier.LOADS_MARGIN:
      case ChartIdentifier.LOADS_RATE:
      case ChartIdentifier.LOADS_REVENUE: {
        if (graphType === 'line') {
          return {
            label: 'Loads',
            fill: false,
            lineTension: 0,
            pointBackgroundColor: '#00000000',
            pointBorderColor: '#00000000',
            borderColor: '#00CA8E',
            type: 'line',
            order: 1,
          };
        } else {
          return {
            pointBackgroundColor: '#00CA8E',
            pointBorderColor: '#00CA8E88',
            pointRadius: 3,
            pointBorderWidth: 4,
            pointHoverRadius: 6,
            pointHoverBackgroundColor: '#00CA8E',
            pointHoverBorderColor: '#00CA8E88',
            pointHoverBorderWidth: 8,
            type: 'scatter',
            order: 2,
            label: '',
          };
        }
      }
    }
  };

  private sortDataByDate = (data: ChartPoint[]): ChartPoint[] => {
    return data.sort((a: ChartPoint, b: ChartPoint) => {
      const dateA = new Date(<any>a?.x);
      const dateB = new Date(<any>b?.x);
      return dateA.valueOf() - dateB.valueOf();
    });
  };

  private fillEmptyDays = (data: ChartPoint[]): ChartPoint[] => {
    const result: ChartPoint[] = [];
    for (let i = 0; i < data.length; i++) {
      result.push(data[i]);
      if (data[i + 1]) {
        const data1Date = new Date(<any>data[i].x).valueOf();
        const data2Date = new Date(<any>data[i + 1].x).valueOf();
        const gap = Math.abs(data1Date - data2Date);
        if (gap > 86400000) {
          // gap is greater than 1 day.
          const fillAmount = gap / 86400000;
          const filledValues = fillDataMatchingSlopeBetweenDataPoints(
            [
              {
                value: <number>data[i].y,
                id: null,
                chartId: null,
              },
              {
                value: <number>data[i + 1].y,
                id: null,
                chartId: null,
              },
            ],
            fillAmount - 1,
            data1Date
          );
          filledValues.forEach((value: ChartPoint, index) => {
            if (index !== 0 && index !== filledValues.length - 1) {
              result.push(value);
            }
          });
        }
      }
    }
    if (result.length < 30 && result.length > 0) {
      const amountMissing = 30 - result.length;
      const lastDate = new Date(<string>result[0].x).valueOf();
      for (let i = 0; i < amountMissing; i++) {
        result.unshift({
          x: <any>getDateNamePlusDays(lastDate, -(amountMissing - i)),
          y: null,
          r: null,
        });
      }
    }
    return result;
  };

  private groupSortedData = (data: ChartPoint[]): ChartPoint[] => {
    const result: ChartPoint[] = [];
    const groupedDataByDate: Dictionary<ChartPoint[]> = groupBy(data, (data: ChartPoint) => data.x);

    // get averages for each group
    Object.keys(groupedDataByDate).forEach((key) => {
      const groupAverage: number =
        reduce(
          groupedDataByDate[key],
          (prev: number, curr: ChartPoint) => {
            return prev + <number>curr.y;
          },
          0
        ) / groupedDataByDate[key].length;
      result.push({
        x: key,
        y: groupAverage,
        r: <any>null,
      });
    });

    return result;
  };

  getLaneHistoryChartScalesOptions = (suggested: { min: number; max: number }): ChartOptions => {
    return {
      scales: {
        // the scales can change based on the chart you want to display
        xAxes: [
          {
            type: 'time',
            time: {
              displayFormats: {
                day: 'MMM D',
              },
              stepSize: 5,
            },
            ticks: {
              fontColor: '#8F96AB',
            },
            gridLines: {
              color: '#8F96AB',
              lineWidth: 1,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              fontColor: '#8F96AB',
              suggestedMax: suggested.max,
              suggestedMin: suggested.min,
              callback: function (value, index, values) {
                return '$' + value;
              },
            },
            gridLines: {
              color: '#8F96AB22',
              borderDash: [4, 4],
            },
          },
        ],
      },
    };
  };

  formatBidStatus = (status: string): string => {
    const result = status?.charAt(0).toUpperCase() + status?.slice(1);
    if (result === 'Auto_rejected') {
      return 'Rejected';
    }
    return result;
  };

  renderBidsAcceptedTooltip = (tooltipModel: ChartTooltipModel, data: any, canvas: HTMLCanvasElement) => {
    const element: HTMLDivElement = chartTooltipSetup(
      tooltipModel,
      canvas.getBoundingClientRect(),
      data,
      this.renderer,
      this.document
    );
    if (data) {
      const bid = this.currentLaneHistoryData?.bids.find((bid: Bid) => bid.id === data);
      const load = this.currentLaneHistoryData?.loads.find((load: LoadsServiceLoad) => bid.loadId === load.id);
      const bidData = {
        carrierRate: '$' + bid?.price,
        loadId: getLoadsServiceLoadAlternateId(load, LoadIdentifierType.TMW_NUMBER),
        bidDate: getDateString(bid?.createdAt),
        bidCreated: bid?.brokerId,
      };
      if (element) {
        const containerElement: HTMLDivElement = this.renderer.createElement('div');
        this.renderer.setStyle(containerElement, 'display', 'flex');
        this.renderer.setStyle(containerElement, 'flex-direction', 'column');

        const bidTitle: HTMLDivElement = this.renderer.createElement('div');
        this.renderer.setStyle(bidTitle, 'color', '#8A62FF');
        this.renderer.setProperty(bidTitle, 'innerHTML', `Bid ${this.formatBidStatus(bid?.status)}`);
        this.renderer.appendChild(containerElement, bidTitle);

        const carrierRateTitle: HTMLDivElement = this.renderer.createElement('div');
        this.renderer.setStyle(carrierRateTitle, 'color', '#FFFFFF');
        this.renderer.setProperty(carrierRateTitle, 'innerHTML', 'Carrier Rate: ');

        const carrierRate: HTMLSpanElement = this.renderer.createElement('span');
        this.renderer.setStyle(carrierRate, 'color', '#8A62FF');
        this.renderer.setProperty(carrierRate, 'innerHTML', bidData.carrierRate);
        this.renderer.appendChild(carrierRateTitle, carrierRate);
        this.renderer.appendChild(containerElement, carrierRateTitle);

        const loadId: HTMLDivElement = this.renderer.createElement('div');
        this.renderer.setStyle(loadId, 'color', '#FFFFFF');
        this.renderer.setProperty(loadId, 'innerHTML', `<br>Load #: ${bidData.loadId}`);
        this.renderer.appendChild(containerElement, loadId);

        const bidOn: HTMLDivElement = this.renderer.createElement('div');
        this.renderer.setStyle(bidOn, 'color', '#FFFFFF');
        this.renderer.setProperty(bidOn, 'innerHTML', `Bid On: ${bidData.bidDate}`);
        this.renderer.appendChild(containerElement, bidOn);

        const bidCreated: HTMLDivElement = this.renderer.createElement('div');
        this.renderer.setStyle(bidCreated, 'color', '#FFFFFF');
        this.renderer.setProperty(bidCreated, 'innerHTML', `Bid created: ${bidData.bidCreated}`);
        this.renderer.appendChild(containerElement, bidCreated);

        this.renderer.appendChild(element, containerElement);
      }
    }
  };

  renderLoadsBookedTooltip = (
    tooltipModel: ChartTooltipModel,
    data: any,
    canvas: HTMLCanvasElement,
    chartIdentifier: string
  ) => {
    const element: HTMLDivElement = chartTooltipSetup(
      tooltipModel,
      canvas.getBoundingClientRect(),
      data,
      this.renderer,
      this.document
    );
    if (data) {
      const load = this.currentLaneHistoryData?.loads.find((load: LoadsServiceLoad) => load.id === data);
      const loadData = {
        carrierRate: '$' + load?.paymentDetails.price,
        loadStatus: this.getGraphLoadStatus(load.loadStatus),
        loadId: getLoadsServiceLoadAlternateId(load, LoadIdentifierType.TMW_NUMBER),
        bookedDate: getDateString(load?.paymentDetails.bookedAt),
        bookCreated: load?.broker.usxId,
      };
      const chartName =
        chartIdentifier === 'loadsRate' ? 'Rate' : chartIdentifier === 'loadsMargin' ? 'Margin' : 'Revenue';

      const monetaryDisplay =
        chartName === 'Rate'
          ? loadData.carrierRate
          : chartName === 'Margin'
          ? '$' + (load?.paymentDetails?.revenue - load?.paymentDetails?.price)
          : '$' + load?.paymentDetails?.revenue;
      if (element) {
        const containerElement: HTMLDivElement = this.renderer.createElement('div');
        this.renderer.setStyle(containerElement, 'display', 'flex');
        this.renderer.setStyle(containerElement, 'flex-direction', 'column');

        const loadBooked: HTMLDivElement = this.renderer.createElement('div');
        this.renderer.setStyle(loadBooked, 'color', '#00CA8E');
        this.renderer.setProperty(loadBooked, 'innerHTML', 'Load Booked');
        this.renderer.appendChild(containerElement, loadBooked);

        const loadBookedStatus: HTMLSpanElement = this.renderer.createElement('span');
        this.renderer.setStyle(loadBookedStatus, 'color', '#00CA8E');
        this.renderer.setStyle(loadBookedStatus, 'font-style', 'italic');
        this.renderer.setProperty(loadBookedStatus, 'innerHTML', ` - ${loadData.loadStatus}`);
        this.renderer.appendChild(loadBooked, loadBookedStatus);

        const carrierRateTitle: HTMLDivElement = this.renderer.createElement('div');
        this.renderer.setStyle(carrierRateTitle, 'color', '#FFFFFF');
        this.renderer.setProperty(carrierRateTitle, 'innerHTML', `Carrier ${chartName}: `);

        const carrierRate: HTMLSpanElement = this.renderer.createElement('span');
        this.renderer.setStyle(carrierRate, 'color', '#00CA8E');
        this.renderer.setProperty(carrierRate, 'innerHTML', monetaryDisplay);
        this.renderer.appendChild(carrierRateTitle, carrierRate);
        this.renderer.appendChild(containerElement, carrierRateTitle);

        const loadId: HTMLDivElement = this.renderer.createElement('div');
        this.renderer.setStyle(loadId, 'color', '#FFFFFF');
        this.renderer.setProperty(loadId, 'innerHTML', `<br>Load #: ${loadData.loadId}`);
        this.renderer.appendChild(containerElement, loadId);

        const bidOn: HTMLDivElement = this.renderer.createElement('div');
        this.renderer.setStyle(bidOn, 'color', '#FFFFFF');
        this.renderer.setProperty(bidOn, 'innerHTML', `Booked On: ${loadData.bookedDate}`);
        this.renderer.appendChild(containerElement, bidOn);

        const bookCreated: HTMLDivElement = this.renderer.createElement('div');
        this.renderer.setStyle(bookCreated, 'color', '#FFFFFF');
        this.renderer.setProperty(bookCreated, 'innerHTML', `Booked by: ${loadData.bookCreated}`);
        this.renderer.appendChild(containerElement, bookCreated);

        this.renderer.appendChild(element, containerElement);
      }
    }
  };

  private getGraphLoadStatus(status: LoadsServiceLoadStatus): string {
    switch (status) {
      case LoadsServiceLoadStatus.UNASSIGNED:
        return 'Awaiting Truck Details';
      case LoadsServiceLoadStatus.ASSIGNED:
        return 'Ready for Dispatch';
      case LoadsServiceLoadStatus.AT_SHIPPER:
        return 'At Pickup';
      case LoadsServiceLoadStatus.PICKED_UP:
        return 'In Transit';
      case LoadsServiceLoadStatus.AT_RECEIVER:
        return 'At Dropoff';
      default:
        return startCase(lowerCase(status));
    }
  }
}
