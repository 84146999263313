export class LoadStepType {
  ACCEPTED: string;
  DISPATCHED: string;
  ASSIGNED: string;
  PICKUP: string;
  PICKUP_STOP: string;
  DROPOFF_STOP: string;
  DROPOFF: string;
  CLOSED: string;
}

export const loadStepType: LoadStepType = {
  ACCEPTED: 'accepted',
  DISPATCHED: 'dispatched',
  ASSIGNED: 'assigned',
  PICKUP: 'pickUp',
  PICKUP_STOP: 'pickUp-stop',
  DROPOFF_STOP: 'dropoff-stop',
  DROPOFF: 'dropoff',
  CLOSED: 'closed',
};
