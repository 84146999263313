<div class="calendar-container">
  <div class="summary-container" (click)="clickSummary()">
    <div class="day-count-display">Next 30 days</div>
    <div class="date-range-display" *ngIf="dates.length >= 1; else noDates">
      <div class="day">
        {{ dates[0]?.dayNumber | ordinalSuffix }}
      </div>
      <div class="to">to</div>
      <div class="day">
        {{ dates[dates.length - 1]?.dayNumber | ordinalSuffix }}
      </div>
    </div>
    <ng-template class="date-range-display" #noDates>
      <div class="day">No Dates</div>
    </ng-template>
    <div class="number-bubble-container">
      <div
        class="number-bubble"
        [ngStyle]="{
          background: rangeInfo?.totalLoadCount === 0 ? 'var(--haulynx-grey)' : 'var(--bg50)',
          color: rangeInfo?.totalLoadCount === 0 ? 'white' : 'black'
        }"
      >
        {{ rangeInfo?.totalLoadCount }}
      </div>
    </div>
  </div>

  <div class="vertical-divider"></div>

  <div class="calendar-section">
    <div class="arrow-section" *ngIf="shouldShowChevrons()" haulynxDebounceClick (debounceClick)="moveCalendar(-1)">
      <mat-icon>chevron_left</mat-icon>
    </div>
    <span *ngIf="!shouldShowChevrons()"></span>
    <div class="calendar-items" #calendarItems>
      <ng-container *ngFor="let date of dates; let i = index">
        <div
          class="calendar-item"
          *ngIf="date.displayCalendarItem"
          (click)="clickCalendarItem(i)"
          [ngStyle]="{ background: i === clickedItem || i === hoveredItem ? 'var(--active-input-bg)' : 'transparent' }"
          (mouseover)="hoveredItem = i"
          (mouseout)="hoveredItem = -1"
        >
          <div class="day-name-display">
            {{ date.dayText }}
          </div>
          <div class="day-number-display">
            {{ date.dayNumber }}
          </div>
          <div class="number-bubble-container">
            <div
              class="number-bubble"
              [ngStyle]="{
                background: date.loadCount === 0 ? 'var(--haulynx-grey)' : 'var(--bg50)',
                color: date.loadCount === 0 ? 'white' : 'black'
              }"
            >
              {{ date.loadCount }}
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="arrow-section" *ngIf="shouldShowChevrons()" haulynxDebounceClick (debounceClick)="moveCalendar(1)">
      <mat-icon>chevron_right</mat-icon>
    </div>
  </div>

  <div style="height: 10px"></div>
  <div style="height: 10px"></div>
  <div class="hide-empty-days">
    <mat-checkbox [checked]="hideEmptyItems" (change)="toggleEmptyItemCheckbox($event)">Hide empty dates</mat-checkbox>
  </div>
</div>
