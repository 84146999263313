import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DevicesComponent, InvitationsComponent } from '@haulynx/components';
import { AuthGuard, LoadDetailsGuard } from '@haulynx/guards';
import { FileReaderService } from '@haulynx/services';
import { DashboardComponent } from './dashboard.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    children: [
      // {path: '', component: OverviewComponent},
      {
        path: 'load-feeds',
        loadChildren: () => import('../load-feed/load-feed.module').then((m) => m.LoadFeedModule),
        /*canActivate: [CarrierLoadsGuard],*/
      },
      {
        path: 'account',
        canActivate: [AuthGuard],
        loadChildren: () => import('../account/account.module').then((m) => m.AccountModule),
      },
      {
        path: 'loads',
        canActivate: [AuthGuard],
        loadChildren: () => import('../load/load.module').then((m) => m.LoadModule),
      },
      {
        path: 'elog',
        canActivate: [AuthGuard],
        redirectTo: 'loads',
      },
      {
        path: 'fleet',
        canActivate: [AuthGuard],
        loadChildren: () => import('../fleet/fleet.module').then((m) => m.FleetModule),
      },
      {
        path: 'users',
        canActivate: [AuthGuard],
        loadChildren: () => import('../users/users.module').then((m) => m.UsersModule),
      },
      { path: 'devices', component: DevicesComponent },
      {
        path: 'carrier-management',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('../carrier-management/carrier-management.module').then((m) => m.CarrierManagementModule),
      },
      { path: 'invitations', component: InvitationsComponent },
      {
        path: 'carriers',
        canActivate: [LoadDetailsGuard],
        loadChildren: () => import('../carrier/carriers.module').then((m) => m.CarriersModule),
      },
      {
        path: '**',
        redirectTo: 'loads',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [FileReaderService],
})
export class DashboardRoutingModule {}
