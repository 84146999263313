import { Pipe, PipeTransform } from '@angular/core';
import { get, maxBy } from 'lodash';

@Pipe({
  name: 'maxVal',
})
export class MaxValPipe implements PipeTransform {
  transform(list: any[], fieldQuery: string): any {
    return get(maxBy(list, fieldQuery), fieldQuery);
  }
}
