import { GeoSpacialFilterType, ISearchFilter } from '../search-filter';
import { ISearchFilterType } from '../search-filter.types';

export class DestinationFilter implements ISearchFilter {
  name = 'Destination';
  type: ISearchFilterType = ISearchFilterType.GEOSPATIAL;
  keys: GeoSpacialFilterType;
  description = 'Filter loads by the load destination';
  searchPriority = 39;

  constructor(defaultValues?: GeoSpacialFilterType) {
    this.keys = new GeoSpacialFilterType({
      radius: 50,
      lat: null,
      lon: null,
      placeInfo: null,
      location: '',
      radiusFormName: 'destinationRadiusMiles',
      latFormName: 'destinationLat',
      lonFormName: 'destinationLon',
      locationFormName: 'destinationLocation',
      state: '',
      stateFormName: 'destinationStates',
      ...defaultValues,
    });
  }

  clearValue = () => {
    this.keys.lat = null;
    this.keys.lon = null;
    this.keys.radius = null;
    this.keys.location = '';
    this.keys.state = '';
  };
}
