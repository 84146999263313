import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { ConfirmDeliveryDialog } from '@haulynx/components';
import { LoadFeedActionsService, LoadGridService, LoadViewService, UserService } from '@haulynx/services';
import { AppModel, LoadEntitiesModel, LoadEntityService, LoadModel, UserEntityService } from '@haulynx/store';
import {
  DistributionMechanismString,
  FeatureFlag,
  FFState,
  IColumns,
  LoadIdentifierType,
  LoadsServiceLoad,
  LoadsServiceLoadStatus,
  LoadStage,
  Notification,
  Tab,
  User,
} from '@haulynx/types';
import { aliveWhile, listToArray } from '@haulynx/utils';
import { get } from 'lodash';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, map, pairwise, takeUntil, withLatestFrom } from 'rxjs/operators';

interface loadSearchFilter {
  dot?: string;
  loadStatus?: LoadsServiceLoadStatus[];
  showExcluded?: boolean;
  showTestLoads?: boolean;
  startAt?;
}

@Component({
  selector: 'app-load-search',
  templateUrl: './load-search.component.html',
  styleUrls: ['./load-search.component.scss'],
})
export class LoadSearchComponent implements OnInit, OnDestroy {
  public loadIdentifierType = LoadIdentifierType;
  key = 'CARRIER_COMPLETED_LOADS_PAGE';
  alive = aliveWhile();
  loads: LoadsServiceLoad[] = [];
  statuses = ['Active', LoadsServiceLoadStatus.FINALLED, LoadsServiceLoadStatus.DELETED];
  currentStatus: string = this.statuses[0];
  user: User;
  isLoading = false;
  loadRefreshTimer: number;
  loadDetails: LoadsServiceLoad;
  userPageOptions = [15];
  loadRoute = [];
  filter: loadSearchFilter = {};
  searchStream$ = new Subject();
  loadSearchColumns$ = new BehaviorSubject<IColumns[]>([]);
  orderBy: { active: string; direction: string } = { active: 'pickupTimestamp', direction: 'desc' };

  constructor(
    public userService: UserService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private appModel: AppModel,
    private loadFeedActionsService: LoadFeedActionsService,
    public snackBar: MatSnackBar,
    public loadViewService: LoadViewService,
    public loadModel: LoadModel,
    public loadGridService: LoadGridService,
    public loadEntitiesModel: LoadEntitiesModel,
    public loadEntityService: LoadEntityService,
    private userEntityService: UserEntityService
  ) {}

  ngOnInit(): void {
    this.initLoadSearchColumns();

    this.loadEntitiesModel.setStateKey({ key: this.key });

    this.searchStream$.pipe(takeUntil(this.alive)).subscribe((filter) => {
      this.loadEntitiesModel.search({ query: filter });
    });

    this.appModel.user$.pipe(takeUntil(this.alive)).subscribe((user) => {
      this.user = user;
    });

    this.loadModel.selectCarrier$
      .pipe(takeUntil(this.alive), distinctUntilChanged(), withLatestFrom(this.appModel.userCarrier$))
      .subscribe(([dot, userCarrier]) => {
        this.filter.dot = get(userCarrier, 'dot') || dot;

        this.searchStream$.next(this.filter);
      });

    this.activatedRoute.params.pipe(takeUntil(this.alive)).subscribe((params) => {
      const { status } = params;
      const loadStatus =
        status === 'completed' ? [LoadsServiceLoadStatus.FINALLED, LoadsServiceLoadStatus.DELIVERED] : undefined;
      const showExcluded = true;
      const showTestLoads = true;

      this.filter = { ...this.filter, loadStatus, showExcluded, showTestLoads };
      this.searchStream$.next(this.filter);
    });

    this.loadEntitiesModel.entities$.pipe(takeUntil(this.alive)).subscribe((loads) => {
      const entities = listToArray(loads);

      if (!this.filter.startAt) {
        this.loads = entities;
        this.loads.map((load) => (this.loadRoute[load.id] = []));
      } else {
        this.loads.pop();
        this.loads = this.loads.concat(entities);
      }

      if (this.loadRefreshTimer != null) {
        clearTimeout(this.loadRefreshTimer);
      }

      this.loadRefreshTimer = window.setTimeout(() => {
        this.search();
      }, 1000 * 60 * 60);
    });

    this.loadModel.isLoadingConfirmDelivery$
      .pipe(takeUntil(this.alive), pairwise())
      .subscribe(([previousLoading, current]) => {
        if (current === false && previousLoading === true) {
          this.search();
        }
      });

    this.listenForNewLoads();
  }

  initLoadSearchColumns() {
    combineLatest([this.appModel.user$, this.appModel.isValidCarrier$, this.userEntityService.featureFlags$])
      .pipe(
        takeUntil(this.alive),
        distinctUntilChanged(),
        map(([user, isValidCarrier, features]: [User, boolean, FFState]) => {
          const columns: IColumns[] = [];

          columns.push({
            label: 'Origin City',
            dataIndex: 'pickupLocation',
            isCustomCell: true,
            sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
          });

          columns.push({
            label: 'State',
            dataIndex: 'pickupState',
            isCustomCell: true,
            sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
          });

          columns.push({
            label: 'Destination City',
            dataIndex: 'deliveryLocation',
            isCustomCell: true,
            sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
          });

          columns.push({
            label: 'State',
            dataIndex: 'deliveryState',
            isCustomCell: true,
            sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
          });

          columns.push({
            label: 'Equipment',
            dataIndex: 'equipmentType',
            isCustomCell: true,
            isSortable: true,
            sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
          });

          columns.push({
            label: 'Pick-Up Date',
            dataIndex: 'pickupTimestamp',
            isCustomCell: true,
            sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
            initiallySortedBy: 'asc',
          });

          columns.push({
            label: 'Delivery Date',
            dataIndex: 'dropoffTimestamp',
            isCustomCell: true,
            sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
          });

          if (user && user.broker) {
            columns.push({
              label: 'Customer',
              dataIndex: 'customer',
              isCustomCell: true,
              sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
            });
          }

          if (user && user.carrier) {
            columns.push({
              label: 'RPM',
              dataIndex: 'rpm',
              isCustomCell: true,
              sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
            });
          }

          columns.push({
            label: 'TMW #',
            dataIndex: 'tmwNumber',
            isSortable: true,
            isCustomCell: true,
          });

          columns.push({
            label: 'Loaded Miles',
            dataIndex: 'distance',
            isCustomCell: true,
          });

          columns.push({
            label: 'Order #',
            dataIndex: 'order',
            isSortable: true,
            isCustomCell: true,
            sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
          });

          if (user && user.broker) {
            columns.push({
              label: 'Revenue',
              dataIndex: 'revenue',
              isCustomCell: true,
              isSortable: true,
              sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
            });
          }
          if (user && (isValidCarrier || user.broker)) {
            columns.push({
              label: 'Price',
              dataIndex: 'price',
              isCustomCell: true,
              isSortable: true,
              sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
            });
          }

          if (user && user.broker && features && features[FeatureFlag.LOAD_OFFER_BIDDING]) {
            columns.push({
              label: 'Bids',
              dataIndex: 'bidDetails',
              isCustomCell: true,
              isSortable: true,
              sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
            });
          }

          if (user && user.carrier) {
            columns.push({
              label: 'Weight',
              dataIndex: 'weight',
              isCustomCell: true,
              sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
            });
          }

          if (user && (user.carrier || user.broker)) {
            columns.push({
              label: '',
              dataIndex: 'options',
              isCustomCell: true,
              cls: 'options',
              isSortable: false,
            });
          }

          this.loadSearchColumns$.next(columns);
        })
      )
      .subscribe();
  }

  confirmDelivery({ loadId, loadLocationIndex }, load) {
    const ref = this.dialog.open(ConfirmDeliveryDialog, {
      data: { user: this.user, load, loadLocationIndex },
    });

    ref.afterClosed().subscribe((result) => {
      if (result) {
        const timestamps = result === 'confirm' ? null : result;

        this.loadModel.confirmDelivery({ loadId, loadLocationIndex, timestamps: timestamps });
      }
    });
  }

  listenForNewLoads(): void {
    this.userService.listenForNotifications('loads', (data) => {
      const notification = data as Notification;
      const id = notification.data.loadId;
      let z: LoadStage;
      switch (notification.data.title) {
        case 'New Load Offer':
          z = LoadStage.LoadOffered;
          break;
      }

      this.loadEntityService.getLoadByIdManager.dispatch(id);
      this.loadEntityService.getLoadByIdManager
        .getEntityById(id)
        .pipe(takeUntil(this.alive))
        .subscribe(
          (load) => {
            if (load) {
              const index = this.loads.findIndex((serviceLoad) => serviceLoad.id === load.id);

              if (index >= 0) {
                this.loads[index] = load;
              } else {
                this.loads.unshift(load);
                this.snackBar.open('New Load', null, { duration: 5000 });
                if (load.distributionMechanism === DistributionMechanismString.NONE) {
                  this.currentStatus = this.statuses[1];
                }
              }
            }
          },
          (err) => {
            const index = this.loads.findIndex((i) => i.id === id);
            if (index >= 0) {
              this.loads.splice(index, 1);
              this.snackBar.open(`Load Reassigned to another Carrier`, null, { duration: 5000 });
            }
          }
        );
    });
  }

  search(): void {
    this.searchStream$.next(this.filter);
  }

  viewLoad(load): void {
    const { id } = load;

    const loadEditTab = new Tab({
      id: `${id}`,
      label: '',
      url: `item/${id}`,
      selected: true,
      closable: true,
      hideLabel: true,
    });

    this.loadModel.addTabs([loadEditTab]);
    this.loadModel.selectTab({ id });
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }
}
