import { FeatureFlag, FFState, LoadOrderType, LoadsServiceLoad } from '@haulynx/types';

/**
 *
 * @param load
 * @param features The current feature flag state for the session
 * @returns a boolean determining if the current load can be booked
 */
export const dedicatedFreightIsBookable = (load: LoadsServiceLoad, features: FFState): boolean => {
  return load?.providerDetails?.orderType === LoadOrderType.DEDICATED ? features?.[FeatureFlag.DEDICATED] : true;
};
