export interface BookableOrderSearchField {
  startTime: string;
  endTime: string;
  originLat: number;
  originLon: number;
  destinationLat: number;
  destinationLon: number;
  originRadius: number;
  originState: string;
  destinationState: string;
  destinationRadius: number;
  orderNumber: string;
  region: string;
  orderType: string;
  tmwNumber: string;
  showExcluded: boolean;
}
