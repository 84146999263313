import { Injectable } from '@angular/core';
import {
  CarrierService,
  CarriersService,
  DriversService,
  LoadsServiceService,
  NotificationsService,
  OpportunityService,
} from '@haulynx/services';
import {
  AsyncDataDictionaryManager,
  AsyncDataManager,
  AsyncMutationManager,
  AsyncSearchManager,
  Carrier,
  CarrierApiNote,
  CarrierApiNoteInput,
  CarrierAssets,
  CarrierComplianceTicket,
  CarrierComplianceTicketParamsInput,
  DriverAssignment,
  MergedCarrierData,
  NewCarrierComplianceTicketInput,
  Opportunity,
  OpportunityParams,
  TrackingEnabledInput,
} from '@haulynx/types';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { AppState } from '../../main-store/app.reducers';
import { AsyncEntityBase } from '../../main-store/async-entity/async-entity-base.class';
import { carrierEntityNamespace } from './carrier-entity.module';

@Injectable({
  providedIn: 'root',
})
export class CarrierEntityService extends AsyncEntityBase<Carrier> {
  // searchTruckTracking: AsyncSearchManager<boolean, { truckId: string; carrierDot: number; trackingType: TrackingType }>;
  getMergedCarrier: AsyncDataManager<{ dot: string }, MergedCarrierData>;
  carrierAssets: AsyncDataManager<string, CarrierAssets>;
  searchCarriers: AsyncSearchManager<
    any,
    Partial<{ search: string; carrierName: string; dot: string; mcNumber: string }>
  >;
  searchOpportunitityManager: AsyncSearchManager<Opportunity, OpportunityParams>;
  //getOwnedCarriers: AsyncSearchManager<any, Partial<{ userId: string }>>;
  trackingData: AsyncDataDictionaryManager<TrackingEnabledInput, boolean>;
  createComplianceTicketManager: AsyncMutationManager<NewCarrierComplianceTicketInput, CarrierComplianceTicket>;
  searchComplianceTicketsManager: AsyncSearchManager<CarrierComplianceTicket, CarrierComplianceTicketParamsInput>;
  updateComplianceTicketsManager: AsyncMutationManager<
    { carrierComplianceTicketId: string; updateComplianceTicketParam: CarrierComplianceTicketParamsInput },
    CarrierComplianceTicket
  >;
  createNewDriverManager: AsyncMutationManager<DriverAssignment, any>;
  createCarrierNotesManager: AsyncMutationManager<
    { carrierComplianceTicketId: string; carrierNoteInput: CarrierApiNoteInput },
    CarrierApiNote
  >;

  constructor(
    protected actions$: Actions,
    protected store: Store<AppState>,
    private loadsServiceService: LoadsServiceService,
    private carrierService: CarrierService,
    private carriersService: CarriersService,
    private opportunityService: OpportunityService,
    private notifications: NotificationsService,
    private driverService: DriversService
  ) {
    super(actions$, store, carrierEntityNamespace);

    this.searchOpportunitityManager = this.createAsyncSearchQuery('get opportunities', (searchPayload) => {
      return this.opportunityService.getOpportunity(searchPayload.query);
    });

    this.carrierAssets = this.createAsyncData('get carrier assets', (dot) => {
      return this.carrierService.getCarrierAssets(dot);
    });

    this.searchCarriers = this.createAsyncSearchQuery('search for a carrier', (searchPayload) =>
      this.carrierService.searchCarriers(searchPayload.query, searchPayload.pageAndSort)
    );

    this.getMergedCarrier = this.createAsyncData('merged carrier', (payload) => {
      return this.carriersService.getMergedCarrierData(payload.dot);
    });

    // this.getOwnedCarriers = this.createAsyncSearchQuery('get carriers by owner', (searchPayload) =>
    //   this.carrierToolbarsApi.getOwnedCarriers(searchPayload.query.userId, searchPayload.pageAndSort)
    // );

    // this.searchTruckTracking = this.createAsyncDataDictionary('truck tracking', (payload) => {
    //   return this.loadsServiceService
    //     .isTruckEnabledForTracking(payload.query)
    //     .pipe(map((result) => ({ data: [result] })));
    // });

    this.createComplianceTicketManager = this.createAsyncMutation(
      'create carrier compliance ticket',
      (payload) =>
        this.carrierService.createCarrierComplianceTicket(payload).pipe(
          tap(
            () => {
              this.notifications.success(`Carrier compliance ticket created`, `Load`);
            },
            (error) =>
              this.notifications.error(`Oops, something went wrong. Unable to create carrier compliance ticket!`, error)
          )
        ),
      { refreshEntityFrom: 'response' }
    );

    this.searchComplianceTicketsManager = this.createAsyncSearchQuery('search carrier compliance', (payload) => {
      return this.carrierService.searchCarrierComplianceTicket(payload.query);
    });

    this.updateComplianceTicketsManager = this.createAsyncMutation(
      'update carrier compliance ticket',
      (payload) =>
        this.carrierService.updateCarrierComplianceTicket(
          payload.carrierComplianceTicketId,
          payload.updateComplianceTicketParam
        ),
      { refreshEntityFrom: 'response' }
    );

    this.createNewDriverManager = this.createAsyncMutation(
      'create driver',
      (payload) =>
        this.driverService.createUnregisteredDriver(payload).pipe(
          tap(
            () => {
              this.notifications.success(`New driver successfully created`, `Load`);
            },
            (error) => this.notifications.error(`Oops, something went wrong. Unable to create new driver!`, error)
          )
        ),
      { refreshEntityFrom: 'response' }
    );

    this.createCarrierNotesManager = this.createAsyncMutation(
      'create carrier note',
      (payload) =>
        this.carrierService.createNoteforCarrier(payload.carrierComplianceTicketId, payload.carrierNoteInput).pipe(
          tap(
            () => {
              this.notifications.success(`New carrier note successfully created`, `Carrier`);
            },
            (error) => this.notifications.error(`Oops, something went wrong. Unable to create new carrier note!`, error)
          )
        ),
      { refreshEntityFrom: 'response' }
    );
  }
}
