import { LoadsServiceOrderByType, LoadsServiceOrderByTypeDirection } from '../../loads-service/loads-service-provider';

/**
 * VirtualScrollSortType will be used by VirutalScrollSort
 * to display sort order and direction.
 */
export interface VirtualScrollSortType {
  /**
   * label will be useed as the sort expansion header value
   */
  label: VirtualScrollSortLabelType;
  /**
   * selected will be used to determine the current selected sort order, and
   * will be used to check the selected mat radio option
   */
  selected?: boolean;
  /**
   * sortDirection is used to track the sort direction asc or desc
   */
  sortDirection?: LoadsServiceOrderByTypeDirection;
  /**
   * sortOrder is used to trake the sort order type
   */
  sortOrder?: LoadsServiceOrderByType;
  /**
   * direcitonType is used to determine whether sort direction type
   * will be alphabetical or numerical
   */
  directionType?: VirtualScrollDirectionType;
  /**
   * expansionSortOptions is used to hold any inner sort options
   * that will need an inner mat expansion.
   */
  expansionSortOptions?: VirtualScrollSortType[];
  /**
   * dataIndex will be used to index
   * the data in a loadsServiceLoad
   * only works for object properties
   * nested arrays will not work
   */
  dataIndex?: string;
}

/**
 * VirtualScrollDirectionType holds types of sort direction
 * alphabetical and numerical are most common.
 */
export enum VirtualScrollDirectionType {
  ALPHABETICAL = 'alphabetical',
  NUMERICAL = 'numerical',
}

/**
 * VirtualScrollSortLabelType holds labels for virtual sort expansion.
 */
export enum VirtualScrollSortLabelType {
  DEFAULT = 'Default',
  ORIGIN = 'Origin',
  ORIGIN_NAME = 'Origin Name',
  ORIGIN_DATE = 'Origin Date/Time',
  ORIGIN_DEAD_HEAD = 'Origin Dead Head Distance',
  DESTINATION = 'Destination',
  DESTINATION_NAME = 'Destination Name',
  DESTINATION_DATE = 'Destination Date/Time',
  DESTINATION_DEAD_HEAD = 'Destination Dead Head Distance',
  PRICE = 'Price',
  RPM = 'Rate Per Mile',
  MILES = 'Loaded Miles (Stops)',
  WEIGHT = 'Weight',
}
