import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { aliveWhile } from '@haulynx/utils';
import { Observable } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'haulynx-multi-chip-select-field',
  templateUrl: './active-load-assign-field.component.html',
  styleUrls: ['./active-load-assign-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ActiveLoadAssignFieldComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() label: string;
  @Input() isDisabled: boolean = false;
  @Input() loading: boolean = false;
  @Input() plusIcon: boolean = false;

  @Input() autocompleteData: string[] = [];
  @Input() maxLength: number = 2;
  @Input() searchForm: FormGroup = new FormGroup({
    text: new FormControl(''),
  });

  @Input() prepopulatedData: string[] = [];

  // Output chips displayed
  @Output() chipsDisplayedChange = new EventEmitter<string[]>();

  separatorKeysCodes: number[] = [ENTER, COMMA];
  chipsDisplayed: string[] = [];
  filteredResults$: Observable<string[]>;

  searchQueryStarted = false;
  alive = aliveWhile();

  @ViewChild('chipSearchInput') chipSearchInput: ElementRef<HTMLInputElement>;
  @ViewChild(MatAutocompleteTrigger, { static: true }) autocomplete: MatAutocompleteTrigger;
  constructor() {}

  ngOnInit(): void {
    this.chipsDisplayed = this.prepopulatedData ?? [];
  }

  ngAfterViewInit() {
    this.formResetter();
  }

  ngOnChanges(changes: SimpleChanges) {
    // If its the first time we are getting data, reset the form to refresh the dropdown autocomplete
    if (!changes.autocompleteData?.previousValue?.length && changes.autocompleteData?.currentValue?.length) {
      this.formResetter();
    }
  }

  ngOnDestroy() {
    this.alive.destroy();
  }

  formResetter() {
    this.filteredResults$ = this.searchForm.get('text').valueChanges.pipe(
      takeUntil(this.alive),
      startWith(''),
      map((value) => (value ? this._filter(value) : this.autocompleteData?.slice() ?? []))
    );

    this.searchForm.get('text').setValue('', { emitEvent: true });
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value && !this.chipsDisplayed.includes(value)) {
      this.chipsDisplayed = [...this.chipsDisplayed, value];
    }

    // Clear the input value
    event.chipInput!.clear();

    this.searchForm.get('text').setValue(null);
    // if maxLength is reached, remove the first chip
    if (this.chipsDisplayed.length > this.maxLength) {
      this.chipsDisplayed.shift();
    }
    this.chipsDisplayedChange.emit(this.chipsDisplayed);
  }

  addChipBasedOnInput() {
    if (this.searchForm.get('text').value === '') return;
    this.add({
      value: this.searchForm.get('text').value,
      chipInput: {
        clear: () => {
          this.searchForm.get('text').setValue(null);
          this.chipSearchInput.nativeElement.value = '';
          return;
        },
      },
    } as MatChipInputEvent);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (this.isDisabled) {
      return;
    }

    if (!this.chipsDisplayed.includes(event.option.viewValue)) {
      this.chipsDisplayed = [...this.chipsDisplayed, event?.option?.viewValue];
    }

    this.chipSearchInput.nativeElement.value = '';
    this.searchForm.get('text').setValue(null);

    // if maxLength is reached, remove the first chip
    if (this.chipsDisplayed.length > this.maxLength) {
      this.chipsDisplayed.shift();
    }

    this.chipsDisplayedChange.emit(this.chipsDisplayed);
  }

  remove(fruit: string): void {
    if (this.isDisabled) {
      return;
    }
    const index = this.chipsDisplayed.indexOf(fruit);

    if (index >= 0) {
      this.chipsDisplayed.splice(index, 1);
    }

    this.chipsDisplayedChange.emit(this.chipsDisplayed);
  }

  // Clear all chips
  clearAll() {
    if (this.isDisabled) {
      return;
    }
    this.chipsDisplayed = [];
    this.chipsDisplayedChange.emit(this.chipsDisplayed);
  }

  expandPanelClicked(event: Event, auto: MatAutocomplete) {
    if (this.isDisabled) {
      return;
    }
    if (auto.isOpen) {
      setTimeout(() => {
        this.autocomplete.closePanel();
      });
    } else {
      setTimeout(() => {
        this.autocomplete.openPanel();
      });
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.autocompleteData.filter((value) => value.toLowerCase().includes(filterValue));
  }

  mouseFocus() {
    if (this.isDisabled) {
      return;
    }
    setTimeout(() => {
      this.chipSearchInput.nativeElement.click();
      this.autocomplete.openPanel();
    }, 100);
  }
}
