import { MapsAPILoader } from '@agm/core';
import { HttpClient } from '@angular/common/http';
import { ElementRef, Injectable } from '@angular/core';
import { Params } from '@angular/router';
import {
  appointmentSetFormName,
  AppointmentSetMetaData,
  AppointmentSetOptions,
  BooleanFilterType,
  DateRangeFilterType,
  DropdownDisplay,
  Environment,
  GeoSpacialFilterType,
  GooglePlaceAutoComplete,
  GooglePlaceDetails,
  GooglePlacePrediction,
  ISearchFilter,
  ISearchFilterType,
  LaneHistoryDateRangeKey,
  LaneHistoryTableView,
  LaneRateToolSearchBarResult,
  LoadIdentifierType,
  LoadSearchBarResult,
  LoadServiceSearchParamData,
  LoadsServiceLoadStatus,
  mapBoxRequestOptions,
  MapboxTimezoneData,
  NumericalFilterType,
  TextArrayFilterType,
  TextFilterType,
  ZipLaneSearchParamData,
} from '@haulynx/types';
import { toHttpParams } from '@haulynx/utils';
import { differenceInMonths, subMonths } from 'date-fns';
import { assign, camelCase, cloneDeep, has, isArray, isEmpty, isNil, keyBy, orderBy, reduce, toNumber } from 'lodash';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class AdvancedSearchService {
  constructor(private googleMapsApi: MapsAPILoader, private environment: Environment, private http: HttpClient) {}

  getAutoCompleteOptions = async (
    input: string,
    predictionTypes: string[] = ['(regions)']
  ): Promise<GooglePlaceAutoComplete> =>
    new Promise((resolve, reject) => {
      if (input && input !== '') {
        this.googleMapsApi.load().then(() => {
          const service = new google.maps.places.AutocompleteService();
          service.getPlacePredictions(
            { input, componentRestrictions: { country: ['ca', 'mx', 'us'] }, types: predictionTypes },
            (
              predictions: google.maps.places.QueryAutocompletePrediction[],
              status: google.maps.places.PlacesServiceStatus
            ) => {
              if (status === google.maps.places.PlacesServiceStatus.OK) {
                resolve({ status, predictions: <GooglePlacePrediction[]>predictions });
              } else {
                reject(status);
              }
            }
          );
        });
      } else {
        this.googleMapsApi.load().then(() => {
          resolve({ status: google.maps.places.PlacesServiceStatus.OK, predictions: [] });
        });
      }
    });

  getPlaceDetails = async (placeId: string, mapDiv: ElementRef): Promise<GooglePlaceDetails> =>
    new Promise((resolve, reject) => {
      this.googleMapsApi.load().then(() => {
        const request: google.maps.places.PlaceDetailsRequest = {
          placeId,
          fields: ['formatted_address', 'geometry', 'types'],
        };
        const map = new google.maps.Map(mapDiv.nativeElement);
        const service = new google.maps.places.PlacesService(map);
        service.getDetails(
          request,
          (result: google.maps.places.PlaceResult, status: google.maps.places.PlacesServiceStatus) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
              resolve(<GooglePlaceDetails>result);
            } else {
              reject(status);
            }
          }
        );
      });
    });

  getTimezone = (lat: number, lng: number): Observable<MapboxTimezoneData> => {
    const url = this.environment.mapbox.timezoneApi + `/${lng},${lat}.json`;
    const params = toHttpParams({ ...new mapBoxRequestOptions(this.environment.mapbox.accessToken) });
    return this.http.get<MapboxTimezoneData>(`${url}`, { params });
  };

  convertSearchToLoadServiceSearchParameters(values: LoadSearchBarResult): LoadServiceSearchParamData {
    const result: LoadServiceSearchParamData = {};
    Object.keys(values).forEach((value) => {
      if (value === 'orderNumber') {
        result['alternateIdType'] = LoadIdentifierType.ORDER_NUMBER;
        result['alternateIdValue'] = values[value];
        let foo = '';
        ({ [value]: foo, ...values } = values);
      } else if (value === 'tmwNumber') {
        result['alternateIdType'] = LoadIdentifierType.TMW_NUMBER;
        result['alternateIdValue'] = values[value];
        let foo = '';
        ({ [value]: foo, ...values } = values);
      } else if (value === 'loadStatus') {
        if (isArray(values['loadStatus'])) {
          result['loadStatus'] = values['loadStatus'];
        } else {
          result['loadStatus'] = [<LoadsServiceLoadStatus>values['loadStatus']];
        }
      } else if (value === 'minOperationsPriority') {
        result['minOperationsPriority'] = values['minOperationsPriority'];
        result['maxOperationsPriority'] = values['minOperationsPriority'];
      } else if (value === 'dateRange') {
        result['startDate'] = getDateRange(values[value]).toString();
        result['endDate'] = new Date().valueOf().toString();
      } else if (value === 'includeBids') {
        result['includeBids'] = values['includeBids'] === LaneHistoryTableView.LOADS_AND_BIDS ? true : false;
      } else if (value === 'laneRadius') {
        const temp = parseInt(values[value].replace(' miles', ''));
        result['laneRadius'] = temp;
      } else if (value === 'appointmentSet') {
        switch (values[value]) {
          case AppointmentSetOptions.NONE:
            result['firstLocationAppointmentSet'] = false;
            result['lastLocationAppointmentSet'] = false;
            break;
          case AppointmentSetOptions.PICKUP:
            result['firstLocationAppointmentSet'] = true;
            break;
          case AppointmentSetOptions.DELIVERY:
            result['lastLocationAppointmentSet'] = true;
            break;
          case AppointmentSetOptions.PICKUP_AND_DELIVERY:
            result['firstLocationAppointmentSet'] = true;
            result['lastLocationAppointmentSet'] = true;
            break;
        }
        let foo = '';
        ({ [value]: foo, ...values } = values);
      } else if ((value === 'originStates' || value === 'destinationStates') && values[value] !== null) {
        const filtered = values[value].filter((s) => s !== '' && s !== null);
        if (filtered.length > 0) {
          result[value] = filtered.map((value) => value.split(':')[0]);
        }
      } else if (
        (value === 'originLat' ||
          value === 'originLon' ||
          value === 'destinationLat' ||
          value === 'destinationLon' ||
          value === 'originRadiusMiles' ||
          value === 'destinationRadiusMiles') &&
        values[value] !== null
      ) {
        const filtered = values[value].filter((s) => s !== null && s !== 0);
        if (filtered.length > 0) {
          result[value] = filtered;
        }
      } else {
        result[value] = values[value];
      }
    });
    return result;
  }

  convertSearchToLaneRateToolSearchParameters(values: LaneRateToolSearchBarResult): ZipLaneSearchParamData {
    const result: ZipLaneSearchParamData = {};
    Object.keys(values).forEach((value) => {
      result[value] = values[value];
    });
    return result;
  }

  /**
   * Converts url query params into a payload-friendly object.
   */
  convertQueryParamsToSearchPayload(queryParams: Params, filters: ISearchFilter[]): unknown {
    const textFormFiltersKeyedByFormName = keyBy(
      filters.filter((f) => f.type === ISearchFilterType.TEXT_ARRAY || f.type === ISearchFilterType.MULTI_DROPDOWN),
      (f) => {
        const formFieldName: string = (f.keys as TextFilterType).textFormName || 'null';
        return formFieldName;
      }
    );

    const geospacialFilterKeys: string[] = [];
    filters.forEach((f: ISearchFilter) => {
      if (f.type === ISearchFilterType.GEOSPATIAL) {
        geospacialFilterKeys.push(...Object.keys(f.keys));
      }
    });
    return reduce(
      queryParams,
      (prev, value, key) => {
        // The query param should be an array even if there is just one element (url query params won't show up that way).
        // 'bookStatus' doesn't have a filter (it doesn't appear in the search bar) and so we loook for it specifically too.
        if ((textFormFiltersKeyedByFormName[key] || key === 'bookStatus') && !Array.isArray(value)) {
          prev[key] = [value];
        } else if (key === 'originZip' || key === 'destinationZip') {
          prev[key] = value;
        } else if (value === 'true') {
          prev[key] = true;
        } else if (value === 'false') {
          prev[key] = false;
        } else if ((key === 'originStates' || key === 'destinationStates') && !Array.isArray(value)) {
          prev[key] = [value];
        } else if (
          key === 'originLat' ||
          key === 'originLon' ||
          key === 'originRadiusMiles' ||
          key === 'destinationLat' ||
          key === 'destinationLon' ||
          key === 'destinationRadiusMiles'
        ) {
          if (!Array.isArray(value)) {
            if (toNumber(value)) prev[key] = [toNumber(value)];
          } else {
            prev[key] = [...value.map((v) => toNumber(v))];
          }
        } else if (toNumber(value)) {
          prev[key] = toNumber(value);
        } else if (key === 'firstLocationAppointmentSet' || key === 'lastLocationAppointmentSet') {
          prev[key] = !!value;
        } else {
          prev[key] = value;
        }
        return prev;
      },
      {}
    );
  }

  convertSearchPayloadToSearchBarData(
    payload: unknown,
    availableFilters: ISearchFilter[]
  ): { searchParams: LoadServiceSearchParamData; searchFilters: ISearchFilter[] } {
    const searchFilters: ISearchFilter[] = [];
    let searchParams: Partial<unknown> = {};

    availableFilters.forEach((filter: ISearchFilter) => {
      if (filter.type === ISearchFilterType.TEXT) {
        const textKeys = filter.keys as TextFilterType;

        if (Array.isArray(textKeys.optionsDataIndex) && textKeys.textFormName === appointmentSetFormName) {
          const orderedOptions = orderBy(
            textKeys.optionsDataIndex,
            [AppointmentSetMetaData.FIRST_LOCATION, AppointmentSetMetaData.LAST_LOCATION],
            ['asc', 'asc']
          );

          const optionMatch = orderedOptions.find((item: DropdownDisplay) =>
            Object.entries(item.metaData).every(([key, value]) => has(payload, key) && payload[key] === value)
          );

          if (optionMatch) {
            textKeys.value = optionMatch.value;
            searchFilters.push(filter);
            searchParams = {
              ...searchParams,
              ...optionMatch.metaData,
              [<string>textKeys.textFormName]: optionMatch.value,
            };
          }
        } else {
          if (textKeys.textFormName === 'includeBids') {
            textKeys.value = payload['includeBids'] ? 'Loads & Bids' : 'Loads';
            searchFilters.push(filter);
            searchParams = {
              ...searchParams,
              [<string>textKeys.textFormName]: textKeys.value,
            };
          } else if (payload[textKeys.textFormName]) {
            textKeys.value = payload[textKeys.textFormName];
            searchFilters.push(filter);
            searchParams = { ...searchParams, [textKeys.textFormName]: payload[textKeys.textFormName] };
          } else if (
            textKeys.textFormName === 'dateRange' &&
            payload['searchParameters']['startDate'] &&
            payload['searchParameters']['endDate']
          ) {
            textKeys.value = this.getMonthValue(
              payload['searchParameters']['startDate'],
              payload['searchParameters']['endDate']
            );
            searchFilters.push(filter);
            searchParams = {
              ...searchParams,
              [<string>textKeys.textFormName]: textKeys.value,
            };
          } else if (textKeys.textFormName === 'carrierNameOrDot' && payload['searchParameters']['carrierNameOrDot']) {
            textKeys.value = payload['searchParameters']['carrierNameOrDot'];
            searchFilters.push(filter);
            searchParams = {
              ...searchParams,
              [<string>textKeys.textFormName]: textKeys.value,
            };
          } else if (textKeys.textFormName === 'laneRadius' && payload['searchParameters']['laneRadius']) {
            textKeys.value = '' + payload['searchParameters']['laneRadius'];
            searchFilters.push(filter);
            searchParams = {
              ...searchParams,
              [<string>textKeys.textFormName]: textKeys.value,
            };
          } else if (
            textKeys.textFormName === 'tmwNumber' &&
            payload['alternateIdType'] === LoadIdentifierType.TMW_NUMBER
          ) {
            textKeys.value = payload['alternateIdValue'];
            searchFilters.push(filter);
            searchParams = {
              ...searchParams,
              [<string>textKeys.textFormName]: textKeys.value,
            };
          } else if (
            textKeys.textFormName === 'orderNumber' &&
            payload['alternateIdType'] === LoadIdentifierType.ORDER_NUMBER
          ) {
            textKeys.value = payload['alternateIdValue'];
            searchFilters.push(filter);
            searchParams = {
              ...searchParams,
              [<string>textKeys.textFormName]: textKeys.value,
            };
          }
        }
      } else if (filter.type === ISearchFilterType.NUMBER_RANGE) {
        const numberKeys = filter.keys as NumericalFilterType;
        const isTruthyVal =
          payload[numberKeys.minFormName] ||
          payload[numberKeys.maxFormName] ||
          payload[numberKeys.minFormName] === 0 ||
          payload[numberKeys.maxFormName] === 0;
        if (isTruthyVal) {
          numberKeys.min = payload[numberKeys.minFormName];
          numberKeys.max = payload[numberKeys.maxFormName];
          searchFilters.push(filter);
          searchParams = {
            ...searchParams,
            [numberKeys.minFormName]: payload[numberKeys.minFormName],
            [numberKeys.maxFormName]: payload[numberKeys.maxFormName],
          };
        }
      } else if (filter.type === ISearchFilterType.GEOSPATIAL) {
        const geospatialKeys = filter.keys as GeoSpacialFilterType;
        if (payload[geospatialKeys.radiusFormName] || payload[geospatialKeys.stateFormName]) {
          let filterParams = [];
          let stateFilterParams = [];
          if (payload[geospatialKeys.radiusFormName]) {
            if (
              typeof payload[geospatialKeys.radiusFormName] === 'string' ||
              payload[geospatialKeys.radiusFormName] instanceof String
            ) {
              payload[geospatialKeys.radiusFormName] = JSON.parse(payload[geospatialKeys.radiusFormName]);
            }
            if (
              typeof payload[geospatialKeys.latFormName] === 'string' ||
              payload[geospatialKeys.latFormName] instanceof String
            ) {
              payload[geospatialKeys.latFormName] = JSON.parse(payload[geospatialKeys.latFormName]);
              payload[geospatialKeys.lonFormName] = JSON.parse(payload[geospatialKeys.lonFormName]);
            }
            filterParams = Array.isArray(payload[geospatialKeys.latFormName])
              ? payload[geospatialKeys.latFormName].map((l, i) => {
                  if (payload[geospatialKeys.latFormName] && payload[geospatialKeys.latFormName][i]) {
                    return { lat: payload[geospatialKeys.latFormName][i], lon: payload[geospatialKeys.lonFormName] };
                  }
                })
              : [];
          }

          if (payload[geospatialKeys.stateFormName]) {
            stateFilterParams = payload[geospatialKeys.stateFormName]
              ? payload[geospatialKeys.stateFormName].map((l, i) => {
                  return { state: payload[geospatialKeys.stateFormName] };
                })
              : [];
          }

          filterParams = [...filterParams, ...stateFilterParams];

          if (!Array.isArray(filterParams)) {
            filterParams = [filterParams];
          }
          let stateFiltersIndex = 0;
          if (!isEmpty(filterParams)) {
            filterParams.map((value: string, index: number) => {
              if (payload[geospatialKeys.latFormName] && payload[geospatialKeys.latFormName][index]) {
                geospatialKeys.lat = payload[geospatialKeys.latFormName][index];
                geospatialKeys.lon = payload[geospatialKeys.lonFormName][index];
                geospatialKeys.radius = payload[geospatialKeys.radiusFormName][index];
                geospatialKeys.state = null;
              } else if (payload[geospatialKeys.zipFormName]) {
                geospatialKeys.zipcode = payload[geospatialKeys.zipFormName][index];
              } else {
                geospatialKeys.state = payload[geospatialKeys.stateFormName][stateFiltersIndex];
                geospatialKeys.lat = null;
                geospatialKeys.lon = null;
                geospatialKeys.radius = null;
                stateFiltersIndex++;
              }
              const geospacialFilter = assign(cloneDeep(filter), {
                keys: {
                  ...geospatialKeys,
                },
              });
              searchFilters.push(geospacialFilter);
              searchParams = {
                ...searchParams,
                [geospatialKeys.latFormName]: payload[geospatialKeys.latFormName],
                [geospatialKeys.lonFormName]: payload[geospatialKeys.lonFormName],
                [geospatialKeys.radiusFormName]: payload[geospatialKeys.radiusFormName],
                [geospatialKeys.stateFormName]: payload[geospatialKeys.stateFormName],
                [geospatialKeys.zipFormName]: payload[geospatialKeys.zipFormName],
              };
            });
          }
        }
      } else if (filter.type === ISearchFilterType.DATE_RANGE) {
        const dateKeys = filter.keys as DateRangeFilterType;
        if (payload[dateKeys.fromFormName]) {
          dateKeys.from = payload[dateKeys.fromFormName];
          dateKeys.to = payload[dateKeys.toFormName];
          searchFilters.push(filter);
          searchParams = {
            ...searchParams,
            [dateKeys.fromFormName]: payload[dateKeys.fromFormName],
            [dateKeys.toFormName]: payload[dateKeys.toFormName],
          };
        }
      } else if (filter.type === ISearchFilterType.TEXT_ARRAY) {
        const textKeys = filter.keys as TextArrayFilterType;
        let filterParams = payload[textKeys.textFormName] || [];

        if (!Array.isArray(filterParams)) {
          filterParams = [filterParams];
        }

        if (!isEmpty(filterParams)) {
          filterParams.map((value: string, index: number) => {
            const textArrayFilter = assign(cloneDeep(filter), {
              keys: { ...textKeys, value, index },
            });
            searchFilters.push(textArrayFilter);
            searchParams = { ...searchParams, [textKeys.textFormName]: payload[textKeys.textFormName] };
          });
        }
      } else if (filter.type === ISearchFilterType.MULTI_DROPDOWN) {
        const textKeys = filter.keys as TextFilterType;

        if (payload[textKeys.textFormName]) {
          let values = payload[textKeys.textFormName];
          if (filter.name === 'Equipment') {
            // Data from the equipment resolver is being changed
            values = [...values.map(camelCase)];
          }

          textKeys.value = values;
          searchFilters.push(filter);
          searchParams = { ...searchParams, [textKeys.textFormName]: values };
        }
      } else if (filter.type === ISearchFilterType.BOOLEAN) {
        const textKeys = filter.keys as BooleanFilterType;

        if (!isNil(payload[textKeys.textFormName])) {
          const value = payload[textKeys.textFormName];

          textKeys.value = value;
          searchFilters.push(filter);
          searchParams = { ...searchParams, [textKeys.textFormName]: value };
        }
      }
    });
    return { searchParams, searchFilters };
  }

  getMonthValue(startDate, endDate): string {
    const numberOfMonths = differenceInMonths(parseInt(endDate), parseInt(startDate));
    switch (numberOfMonths) {
      case 1:
        return LaneHistoryDateRangeKey.MONTH_1;
      case 3:
        return LaneHistoryDateRangeKey.MONTH_3;
      case 12:
        return LaneHistoryDateRangeKey.MONTH_12;
    }
  }

  mergeSearchData(
    existingData: Partial<unknown>,
    newData: Partial<unknown>,
    fieldsToRemove: Partial<unknown> = {},
    topPriorityFields: Partial<unknown> = {}
  ): Partial<unknown> {
    const removedFieldsFromExistingData = reduce(
      fieldsToRemove,
      (prev, currVal, currKey) => {
        if (isNil(currVal)) return prev;

        switch (typeof currVal) {
          case 'number':
          case 'string':
            delete prev[currKey];
            return prev;
          case 'boolean':
            delete prev[currKey];
            return prev;
          case 'object':
            if (isArray(currVal)) {
              const fieldsToRemoveArrayFieldSet = new Set<number | string | boolean>(currVal);
              const difference = new Set(
                (isArray(prev[currKey]) ? prev[currKey] : []).filter((x) => !fieldsToRemoveArrayFieldSet.has(x))
              );
              if (difference.size) {
                prev[currKey] = Array.from(difference);
              } else {
                delete prev[currKey];
              }
              return prev;
            }
          // eslint-disable-next-line no-fallthrough
          default:
            console.error(`Removing data: no matching type for key ${currKey} in object`, fieldsToRemove);
            return prev;
        }
      },
      { ...existingData }
    );

    const mergedData = reduce(
      newData,
      (prev, currVal, currKey) => {
        if (isNil(currVal)) return prev;

        switch (typeof currVal) {
          case 'number':
          case 'string':
            prev[currKey] = currVal;
            return prev;
          case 'boolean':
            prev[currKey] = currVal;
            return prev;
          case 'object':
            if (isArray(currVal)) {
              prev[currKey] = [...currVal, ...(isArray(prev[currKey]) ? prev[currKey] : [])];
              return prev;
            }
          // eslint-disable-next-line no-fallthrough
          default:
            console.error(`Merging data: no matching type for key ${currKey} in object`, newData);
            return prev;
        }
      },
      { ...removedFieldsFromExistingData }
    );

    const dataAfterOverwrites = reduce(
      topPriorityFields,
      (prev, currVal, currKey) => {
        prev[currKey] = currVal;
        return prev;
      },
      { ...mergedData }
    );

    return dataAfterOverwrites;
  }
}

export function getDateRange(values): number {
  switch (values) {
    case LaneHistoryDateRangeKey.MONTH_1:
      return subMonths(new Date(), 1).valueOf();
    case LaneHistoryDateRangeKey.MONTH_3:
      return subMonths(new Date(), 3).valueOf();
    case LaneHistoryDateRangeKey.MONTH_12:
      return subMonths(new Date(), 12).valueOf();
  }
}
