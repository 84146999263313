import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ContractManageContainerModule } from '@haulynx/components';
import { ContractsManageRoutingModule } from './contract-manage-routing.module';
import { ContractManageComponent } from './contract-manage.component';

@NgModule({
  declarations: [ContractManageComponent],
  imports: [CommonModule, ContractsManageRoutingModule, ContractManageContainerModule],
})
export class ContractManageModule {}
