<div class="load-search-container" *ngrxLet="sidebarItems$ as sidebarItems">
  <div class="load-search-sidebar">
    <app-sidebar
      [name]="'Loads'"
      [items]="sidebarItems$ | async"
      [isSidebarOpened]="isSidebarOpen"
      [currentData]="currentSearchData$ | async"
      [requiredDataFields]="['loadStatus']"
      (itemSelected)="onSidebarSelect($event, searchQuery)"
      (sidebarToggled)="onSidebarToggle($event)"
      (matchedSidebarItem)="onMatchSidebarItem($event, sidebarItems)"
      (deleteSidebarItem)="onDeleteSidebarItem($event, sidebarItems)"
      (updateSidebarItem)="onUpdateSidebarItem($event)"
      data-test="app-sidebar"
    ></app-sidebar>
  </div>

  <div class="search-column" [ngStyle]="{ 'margin-left': isSidebarOpen ? '299px' : '59px' }">
    <app-advanced-search
      *ngIf="selectedSidebarItem.id !== 'missions' && selectedSidebarItem.id !== 'test-missions'; else missionsHeader"
      class="searchbar-container"
      [filters]="filters"
      [setFilters]="searchBarFilters$ | async"
      [sideBarSelection]="selectedSidebarItem"
      [recentSearches]="[]"
      [displayRecentSearches]="false"
      [shouldResetTextArrayForm]="shouldResetTextArrayForm"
      (submitSearch)="onSubmitSearch($event, searchQuery)"
      (selectedFilterChange)="onSelectedFilterChange($event)"
      data-test="app-advanced-search"
    >
    </app-advanced-search>

    <ng-template #missionsHeader>
      <div class="missions-header">
        <h6>{{ selectedSidebarItem.label }}</h6>
        <button mat-raised-button (click)="onCreateMission()" class="create-mission" data-test="create-mission">
          +&emsp;Create Mission
        </button>
      </div>
    </ng-template>

    <ng-container *ngrxLet="loadEntityService.lockState.isSearchingActions$ as isSearchingActions">
      <div *ngrxLet="loadLockState$ as loadLockState" class="data-tables" #parent>
        <data-table-v2
          [data]="tableData$ | async"
          [tableConfig]="configOptions"
          [emptyMessage]="'No Loads Found'"
          [displayedColumns]="columns"
          [templateCellRef]="templateRef"
          [parentToTable]="parent"
          [loading]="dataLoading$ | async"
          [entitiesLoading]="isLoadingEntities$ | async"
          [selectedFilters]="searchViewFilters$ | async"
          [selectedSidebarItem]="selectedSidebarItem"
          [isSearchView]="isSearchView"
          [selectionMode]="null"
          [stickyColumnWidth]="stickyColumnWidth"
          [paginationData]="loadEntityService.getUSXLoadsManager.searchPaginationResults$ | async"
          [scrollHeight]="'703px'"
          [selectedRows]="selectedRows$ | async"
          [toggleRowsHighlight]="selectedRowsHighlight$ | async"
          [loadsNearestCities]="loadsNearestCities"
          (rowSelect)="onRowSelect($event)"
          (bulkAction)="onBulkAction($event)"
          (page)="onPage($event)"
          (sort)="onSort($event, { payload: searchQuery })"
          (columnsChange)="onColumnsChange($event)"
          (saveSearchView)="openSaveSearchViewDialog($event, sidebarItems)"
          (columnsRest)="onColumnsReset(sidebarItems)"
          data-test="load-search-table"
        >
          <ng-template
            #templateRef
            let-row="row"
            let-column="column"
            let-hovering="isHovering"
            let-width="columnWidth"
            let-index="rowIndex"
            let-count="rowCount"
          >
            <td [ngStyle]="{ width: width }" *ngIf="column.field === 'mission'" (click)="onRowClick(row)">
              <div class="equipment-container" data-test="load-mission">
                <div class="equipment-name">
                  {{ row.mission?.id || 'N/A' }}
                </div>
              </div>
            </td>

            <td
              class="origin-dest-container"
              [ngStyle]="{ width: width }"
              *ngIf="column.field === 'originAndDestination'"
              (click)="onRowClick(row)"
            >
              <app-load-search-timeline [load]="row"></app-load-search-timeline>
            </td>

            <td [ngStyle]="{ width: width }" *ngIf="column.field === 'status'" (click)="onRowClick(row)">
              <div class="load-status-container" data-test="load-status">
                <div class="status-icon"><mat-icon [svgIcon]="row | loadStatusIcon"></mat-icon></div>
                <div class="status-icon">
                  <div class="status" [ngStyle]="{ color: (row | getLateOrOnTimeColor) }">
                    <span *ngIf="row.onTimeStatus?.onTime !== null">
                      {{ row.onTimeStatus?.onTime ? 'On Time /' : 'Late /' }}
                    </span>
                    {{ row.loadStatus | transformLoadStatus: row.bookStatus | fromCamelCase }}
                  </div>
                  <div class="status-time" [ngStyle]="{ color: (row | getLateOrOnTimeColor) }">
                    {{ row.onTimeStatus?.pickupStatus }}
                  </div>
                </div>
                <div
                  class="status-icon bids__container"
                  *ngIf="
                    selectedSidebarItem?.label === 'All Loads' ||
                    selectedSidebarItemParent?.label === 'Available' ||
                    selectedSidebarItemParent?.label === 'Test Loads'
                  "
                >
                  <mat-icon class="status-icon__bid" [svgIcon]="'haulynx-bid-icon'"> </mat-icon>
                  <div class="status-icon__bid__item-count">
                    {{ row.bidDetails?.offerCount ?? 0 }}
                  </div>
                </div>
              </div>
            </td>

            <td [ngStyle]="{ width: width }" *ngIf="column.field === 'trackingStatus'" (click)="onRowClick(row)">
              <div class="tracking-status-container" data-test="load-tracking-status">
                {{ row.trackingStatus?.usxOrderStatus || 'Not Available' }}
              </div>
            </td>

            <td [ngStyle]="{ width: width }" *ngIf="column.field === 'equipment'" (click)="onRowClick(row)">
              <div class="equipment-container">
                <div class="first-line">
                  <div class="equipment-name" data-test="load-equipment">
                    {{ row.providerDetails?.equipmentType | fromCamelCase }}
                  </div>
                  <mat-icon
                    class="tanker-icon"
                    *ngIf="row.restrictions | loadRestrictions: 'tankerCertificationRequired'"
                    [svgIcon]="'haulynx-tanker'"
                    matTooltip="This shipment is tanker certified"
                  ></mat-icon>
                </div>

                <div class="second-line">
                  <div class="commodity-name" data-test="load-weight">
                    <!-- {{row.providerDetails.commodity || 'Unknown'}} /  -->
                    {{ row.providerDetails?.weight }} lbs
                  </div>
                  <span class="equipment-icon">
                    <mat-icon
                      class="hazmat-icon"
                      *ngIf="row.restrictions | loadRestrictions: 'hazmat'"
                      [svgIcon]="'haulynx-hazmat'"
                      matTooltip="This shipment incudes hazardous material"
                    ></mat-icon>
                  </span>
                </div>
              </div>
            </td>

            <td [ngStyle]="{ width: width }" *ngIf="column.field === 'lastLocation'" (click)="onRowClick(row)">
              <div
                class="last-location-container"
                [tooltip]="loadsNearestCities[row.id] ? loadsNearestCities[row.id] : 'Last Location Unavailable'"
                data-test="load-last-location"
              >
                <div class="last-location-title">
                  {{ loadsNearestCities[row.id] ? loadsNearestCities[row.id] : 'Last Location Unavailable' }}
                </div>
                <div class="last-location-time">
                  {{ row.latestMilestoneLog?.timestamp | getLastLocationTimeDiffPipe }}
                </div>
              </div>
            </td>

            <td [ngStyle]="{ width: width }" *ngIf="column.field === 'lastTrailerLocation'" (click)="onRowClick(row)">
              <div
                class="last-location-container"
                [tooltip]="
                  loadsTrailerNearestCities[row.trailers[0]?.id]
                    ? loadsTrailerNearestCities[row.trailers[0]?.id].location
                    : 'Last Location Unavailable'
                "
                data-test="trailer-last-location"
              >
                <div class="last-location-title">
                  {{
                    loadsTrailerNearestCities[row.trailers[0]?.id]
                      ? loadsTrailerNearestCities[row.trailers[0]?.id].location
                      : 'Last Location Unavailable'
                  }}
                </div>
                <div class="last-location-time">
                  {{
                    loadsTrailerNearestCities[row.trailers[0]?.id]
                      ? (loadsTrailerNearestCities[row.trailers[0]?.id].timestamp | getLastLocationTimeDiffPipe)
                      : ''
                  }}
                </div>
              </div>
            </td>

            <td
              [ngStyle]="{ width: width }"
              *ngIf="column.field === 'latestMilestoneComment'"
              (click)="onRowSelect(row)"
            >
              <div
                class="last-milestone-comment"
                [tooltip]="row.latestMilestoneComment?.text"
                data-test="last-milestone-comment"
              >
                <div class="contact">
                  {{ row.latestMilestoneComment?.contact }}

                  <span class="timestamp"
                    >&nbsp;&nbsp;{{
                      row.latestMilestoneComment?.contact || row.latestMilestoneComment?.text
                        ? getlastCommentTimeDiff(row) + ' ago'
                        : ''
                    }}</span
                  >
                </div>
                <div class="message">
                  {{
                    row.latestMilestoneComment && row.latestMilestoneComment?.text
                      ? (row.latestMilestoneComment?.text | textCutoff: 80)
                      : 'No Comment Added'
                  }}
                </div>
              </div>
            </td>

            <td [ngStyle]="{ width: width }" *ngIf="column.field === 'customer'" (click)="onRowClick(row)">
              <div
                class="customer-name"
                [matTooltip]="row.providerDetails?.billToName ? row.providerDetails?.billToName : ''"
                data-test="load-bill-to"
              >
                {{ row.providerDetails?.billToName | titlecase }}
              </div>
            </td>

            <td [ngStyle]="{ width: width }" *ngIf="column.field === 'tmwAndOrder'" (click)="onRowClick(row)">
              <div class="tmw-order-container">
                <div class="tmw-number" data-test="load-tmw">#{{ row | loadAlternateId: 'tmwNumber' }}</div>
                <div class="order-number" data-test="load-order-no">#{{ row | loadAlternateId: 'orderNumber' }}</div>
              </div>
            </td>

            <td [ngStyle]="{ width: width }" *ngIf="column.field === 'price'" (click)="onRowClick(row)">
              <div class="price-revenue-container">
                <app-load-price
                  class="value"
                  [price]="row?.paymentDetails.price"
                  [load]="row"
                  [loadId]="row.id"
                  [loadsServiceLoad]="true"
                  [noValueText]="'-'"
                  [editablePrice]="
                    row.bookStatus !== BookStatus.BOOKED && row.loadStatus !== LoadsServiceLoadStatus.FINALLED
                  "
                  [showCents]="true"
                  [currentMaxBuy]="row.paymentDetails?.maxBuy || null"
                  (priceChanged)="onPriceChange($event)"
                  data-test="load-price"
                >
                </app-load-price>
              </div>
            </td>
            <td [ngStyle]="{ width: width }" *ngIf="column.field === 'revenue'" (click)="onRowClick(row)">
              <div class="price-revenue-container" data-test="load-revenue">
                {{ (row.paymentDetails?.revenue | currency) || '' }}
              </div>
            </td>
            <td [ngStyle]="{ width: width }" *ngIf="column.field === 'maxBuy'" (click)="onRowClick(row)">
              <div class="price-revenue-container">
                <app-load-price
                  class="value"
                  [price]="row?.paymentDetails?.maxBuy"
                  [load]="row"
                  [loadId]="row.id"
                  [loadsServiceLoad]="true"
                  [isMaxBuy]="true"
                  [noValueText]="'-'"
                  [editablePrice]="
                    row.bookStatus !== BookStatus.BOOKED && row.loadStatus !== LoadsServiceLoadStatus.FINALLED
                  "
                  [showCents]="true"
                  [currentPrice]="row.paymentDetails.price"
                  (priceChanged)="onPriceChange($event)"
                  data-test="load-max-buy"
                >
                </app-load-price>
              </div>
            </td>

            <td [ngStyle]="{ width: width }" *ngIf="column.field === 'bids'" (click)="onRowClick(row)">
              <app-load-bid-details [row]="row" data-test="load-bid-details"></app-load-bid-details>
            </td>

            <td [ngStyle]="{ width: width }" *ngIf="column.field === 'teamDrivingRequired'" (click)="onRowClick(row)">
              <mat-icon
                class="team-required-icon"
                svgIcon="haulynx-group-icon"
                [ngClass]="{
                  'team-required-icon__white':
                    (row?.restrictions | loadRestrictions: loadsServiceRestrictionTypes.TEAM_DRIVING_REQUIRED)
                }"
              ></mat-icon>
              <span class="team-required-value" data-test="load-team-required">{{
                (row?.restrictions | loadRestrictions: loadsServiceRestrictionTypes.TEAM_DRIVING_REQUIRED)
                  ? 'Yes'
                  : 'No'
              }}</span>
            </td>

            <td [ngStyle]="{ width: width }" *ngIf="column.field === 'trailerInstructions'" (click)="onRowClick(row)">
              <div class="trailer-instruction-container" data-test="load-trailer-instructions">
                <div
                  *ngIf="{
                    pickupInstructions:
                      row.restrictions | loadRestrictions: loadsServiceRestrictionTypes.PICKUP_REQUIREMENTS,
                    dropOffInstructions:
                      row.restrictions | loadRestrictions: loadsServiceRestrictionTypes.DROPOFF_REQUIREMENTS
                  } as instructions"
                >
                  <div
                    class="instruction-name"
                    *ngIf="
                      instructions.pickupInstructions || instructions.dropOffInstructions;
                      else noTrailerInstructions
                    "
                  >
                    {{ row.restrictions | loadRestrictions: loadsServiceRestrictionTypes.PICKUP_REQUIREMENTS }} /
                    {{ row.restrictions | loadRestrictions: loadsServiceRestrictionTypes.DROPOFF_REQUIREMENTS }}
                  </div>
                  <ng-template #noTrailerInstructions> Not available </ng-template>
                </div>
              </div>
            </td>

            <td [ngStyle]="{ width: width }" *ngIf="column.field === 'truck'" (click)="onRowClick(row)">
              <div class="equipment-container" data-test="load-truck-trailer">
                <div class="equipment-name" data-test="load-truck">
                  {{ row.truck?.unitId || 'No Truck Assigned' }}
                </div>
                <div class="commodity-name" data-test="load-trailer">
                  {{ (row.trailers | nth: 0)?.trailerNumber || 'No Trailer Assigned' }}
                </div>
              </div>
            </td>

            <td [ngStyle]="{ width: width }" *ngIf="column.field === 'driver'" (click)="onRowClick(row)">
              <div class="equipment-container" data-test="load-driver-number">
                <div class="equipment-name" data-test="load-driver">
                  {{ (row.drivers | nth: 0)?.name || 'No Driver Assigned' }}
                </div>
                <div class="commodity-name" *ngIf="(row.drivers | nth: 0)?.name">
                  {{ (row.drivers | nth: 0)?.phone || 'No Phone Number' }}
                </div>
              </div>
            </td>

            <td [ngStyle]="{ width: width }" *ngIf="column.field === 'carrier'" (click)="onRowClick(row)">
              <div class="equipment-container" data-test="load-carrier-dot">
                <div class="equipment-name" data-test="load-carrier">
                  {{ row.carrier?.name }}
                </div>
                <div class="commodity-name" data-test="load-dot">
                  {{ row.carrier?.dot }}
                </div>
              </div>
            </td>

            <td [ngStyle]="{ width: width }" *ngIf="column.field === 'assignedBrokers'" (click)="onRowClick(row)">
              <div class="trailer-instruction-container" data-test="load-assigned-broker">
                <haulynx-assigned-brokers [brokers]="row.assignedBrokers" [maxCount]="1"></haulynx-assigned-brokers>
              </div>
            </td>

            <td [ngStyle]="{ width: width }" *ngIf="column.field === 'bookedBy'" (click)="onRowClick(row)">
              <div class="trailer-instruction-container" data-test="load-booked-by">
                {{ row.broker?.name }}
              </div>
            </td>

            <td [ngStyle]="{ width: width }" *ngIf="column.field === 'orderType'" (click)="onRowClick(row)">
              <div class="customer-name" data-test="load-order-type">
                {{ row.providerDetails?.orderType | fromCamelCase }}
              </div>
            </td>

            <td [ngStyle]="{ width: width }" *ngIf="column.field === 'distanceMiles'" (click)="onRowClick(row)">
              <div class="customer-name" data-test="load-distance-miles">
                {{ row.paymentDetails?.distanceMiles }}
              </div>
            </td>

            <td [ngStyle]="{ width: width }" *ngIf="column.field === 'inboundRegion'" (click)="onRowClick(row)">
              <div class="customer-name" data-test="load-region">
                {{ row.region }}
              </div>
            </td>

            <td [ngStyle]="{ width: width }" *ngIf="column.field === 'brokerTeamId'" (click)="onRowClick(row)">
              <div class="customer-name" data-test="load-broker-team">
                {{ row.providerDetails?.brokerTeamId | titlecase }}
              </div>
            </td>

            <td [ngStyle]="{ width: width }" *ngIf="column.field === 'shipper'" (click)="onRowClick(row)">
              <div class="customer-name" data-test="load-shipper-name">
                {{ (row.locations | nth: 0)?.customer?.name }}
              </div>
            </td>

            <td [ngStyle]="{ width: width }" *ngIf="column.field === 'receiver'" (click)="onRowClick(row)">
              <div class="customer-name" data-test="load-reciever-name">
                {{ (row.locations | nth: -1)?.customer?.name | titlecase }}
              </div>
            </td>

            <td [ngStyle]="{ width: width }" *ngIf="column.field === 'cxPriority'" (click)="onRowClick(row)">
              <div class="customer-name" data-test="load-cx-priority">
                {{ row.providerDetails?.cxPriority }}
              </div>
            </td>

            <td [ngStyle]="{ width: width }" *ngIf="column.field === 'trackingTypes'" (click)="onRowClick(row)">
              <div class="customer-name" data-test="load-tracking-type">
                {{ row.trackingType === 'manual' ? 'No Tracking Assigned' : (row.trackingType | fromCamelCase) }}
              </div>
            </td>

            <td [ngStyle]="{ width: width }" *ngIf="column.field === 'highValue'" (click)="onRowClick(row)">
              <div class="high-value-container" data-test="load-high-value">
                <mat-icon
                  [ngClass]="
                    (row.restrictions | loadRestrictions: 'highValueProductException')
                      ? 'high-value-icon-exception'
                      : (row.restrictions | loadRestrictions: 'highValueProduct')
                      ? 'high-value-icon-yes'
                      : 'high-value-icon-no'
                  "
                  [svgIcon]="'haulynx-high-value-icon'"
                ></mat-icon>
                <div class="high-value-text">
                  <div
                    [ngClass]="
                      (row.restrictions | loadRestrictions: 'highValueProductException')
                        ? 'high-value-text'
                        : 'high-value-no-exception'
                    "
                  >
                    {{ (row.restrictions | loadRestrictions: 'highValueProduct') ? 'Yes' : 'No' }}
                  </div>
                  <div class="high-value-exception">
                    {{ (row.restrictions | loadRestrictions: 'highValueProductException') ? 'Exception' : '' }}
                  </div>
                </div>
              </div>
            </td>

            <td [ngStyle]="{ width: width }" *ngIf="column.field === 'cxrId'" (click)="onRowClick(row)">
              <div class="customer-name" data-test="load-cxr-id">
                {{ row.providerDetails?.cxrEmployeeId }}
              </div>
            </td>
            <td [ngStyle]="{ width: width }" *ngIf="column.field === 'appointmentResets'" (click)="onRowClick(row)">
              <div class="price-revenue-container" data-test="load-appt-resets">
                {{ row.numberOfAppointmentResets || 0 }}
              </div>
            </td>
            <td [ngStyle]="{ width: width }" *ngIf="column.field === 'actions'">
              <div class="actions-container">
                <ng-container *ngIf="!features[loadLockFlag]; else lockedButton">
                  <ng-container *ngTemplateOutlet="bookButton"></ng-container>
                </ng-container>

                <ng-template #lockedButton>
                  <ng-container *ngIf="loadLockState[row?.id] | isLoadLockedPipe; else bookButton">
                    <button
                      class="book-locked"
                      [matTooltip]="loadLockState[row?.id] | transformBookToolTipPipe"
                      data-test="locked-icon"
                    >
                      Locked
                    </button>
                  </ng-container>
                </ng-template>

                <ng-template #bookButton>
                  <button
                    class="book-load"
                    (click)="openBookLoadDialog(row)"
                    *ngIf="
                      row.bookStatus !== 'booked' &&
                      row.bookStatus !== 'pause' &&
                      !isSearchingActions &&
                      (row | dedicatedAllowed: features)
                    "
                    data-test="book-button"
                  >
                    Book
                  </button>
                </ng-template>
              </div>
            </td>
          </ng-template>
        </data-table-v2>
      </div>
    </ng-container>
  </div>

  <haulynx-carrier-toolbar
    *ngIf="selectedSidebarItem.id !== 'missions' && selectedSidebarItem.id !== 'test-missions'"
  ></haulynx-carrier-toolbar>
</div>
