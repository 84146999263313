export const menuItemTypes = {
  VIEW_LOGS: {
    label: 'View Logs',
    action: 'view-logs',
  },
  CREATE_TEMPLATE: {
    label: 'Create Template',
    action: 'create-template',
  },
  EXPORT: {
    label: 'Export',
    action: 'export',
  },
  REFRESH_LOCATION: {
    label: 'Refresh Driver Location',
    action: 'refresh-location',
  },
  SHARE_LOCATION: {
    label: 'Share Location',
    action: 'share-location',
  },
  ARCHIVE_LOAD: {
    label: 'Archive Load',
    action: 'archive-load',
  },
};
