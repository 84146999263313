import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { LoadAlternateIdPipe } from '@haulynx/pipes';
import { AnalyticsService } from '@haulynx/services';
import { LoadModel } from '@haulynx/store';
import { ANALYTICS_EVENT, Load, LoadIdentifierType, LoadsServiceLoad } from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { get, isEmpty } from 'lodash';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-load-active-search',
  templateUrl: './load-active-search.component.html',
  styleUrls: ['./load-active-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadActiveSearch implements OnDestroy, OnChanges {
  @Input() tableType;
  @Input() selectedLoadId;
  @Input() entitiesUnassigned: LoadsServiceLoad[] = [];
  @Input() entitiesAssigned: LoadsServiceLoad[] = [];
  @Input() entitiesInTransit: LoadsServiceLoad[] = [];
  @Input() entitiesLoadOffers: LoadsServiceLoad[] = [];
  @Input() displayLoadOffers = true;
  @Input() isBroker = false;
  @Input() isLoading = false;
  @Output() onSelect = new EventEmitter();
  @Output() onBookSuccess = new EventEmitter<void>();
  @Output() offerDeleteSuccessEvent = new EventEmitter<LoadsServiceLoad>();

  selectedId: string = null;
  alive = aliveWhile();
  public noActiveLoads = false;
  selectStream$ = new Subject();

  constructor(
    public loadModel: LoadModel,
    private analytics: AnalyticsService,
    private loadAlternateIdPipe: LoadAlternateIdPipe
  ) {
    this.selectStream$
      .pipe(takeUntil(this.alive), debounceTime(500), distinctUntilChanged())
      .subscribe((selectedId) => {
        if (selectedId) {
          this.onSelect.emit(selectedId);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    const { selectedLoadId, entitiesInTransit, entitiesAssigned, entitiesLoadOffers, entitiesUnassigned } = changes;

    if (selectedLoadId) {
      this.selectedId = selectedLoadId.currentValue;
    }

    if (entitiesInTransit || entitiesAssigned || entitiesLoadOffers || entitiesUnassigned) {
      this.noActiveLoads = false;

      if (!this.selectedId) {
        let selected = null;
        if (!isEmpty(this.entitiesUnassigned)) {
          selected = this.entitiesUnassigned[0];
        } else if (!isEmpty(this.entitiesAssigned)) {
          selected = this.entitiesAssigned[0];
        } else if (!isEmpty(this.entitiesInTransit)) {
          selected = this.entitiesInTransit[0];
        } else if (!isEmpty(this.entitiesLoadOffers)) {
          selected = this.entitiesLoadOffers[0];
        } else {
          this.noActiveLoads = true;
        }

        this.selectStream$.next(selected?.id);
      }
    }
  }

  setSelectedLoad(load: LoadsServiceLoad) {
    this.selectedId = get(load, 'id', null);
    this.selectStream$.next(this.selectedId);
    if (this.entitiesLoadOffers.includes(load)) {
      this.analytics.logEvent(ANALYTICS_EVENT.BROKER_OFFER_LOAD_CLICKED, {
        loadId: load.id,
        loadTmwNum: this.loadAlternateIdPipe.transform(load, LoadIdentifierType.TMW_NUMBER),
        loadOrderNum: this.loadAlternateIdPipe.transform(load, LoadIdentifierType.ORDER_NUMBER),
      });
    } else {
      this.analytics.logEvent(ANALYTICS_EVENT.BASIC_LOAD_CLICKED, {
        loadId: load.id,
        loadTmwNum: this.loadAlternateIdPipe.transform(load, LoadIdentifierType.TMW_NUMBER),
        loadOrderNum: this.loadAlternateIdPipe.transform(load, LoadIdentifierType.ORDER_NUMBER),
      });
    }
  }

  bookSuccess(): void {
    this.onBookSuccess.emit();
  }

  offerDeleteSuccess(load: LoadsServiceLoad): void {
    this.offerDeleteSuccessEvent.emit(load);
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }
}
