import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ActionButtonConfig } from '@haulynx/types';
import { find, map } from 'lodash';

export enum ActionButtonsDisplay {
  COLUMN = 'column',
  ROW = 'row',
}

export enum ActionButtonsPosition {
  RIGHT = 'right',
  LEFT = 'left',
  FULL = 'full',
}

@Component({
  selector: 'action-buttons',
  templateUrl: './action-buttons.component.html',
  styleUrls: ['./action-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionButtonsComponent implements OnChanges {
  @Input() buttons: ActionButtonConfig[] = [];
  @Input() disableButtons: ActionButtonConfig[] = [];
  @Input() position: ActionButtonsPosition = ActionButtonsPosition.RIGHT;
  @Input() display: ActionButtonsDisplay = ActionButtonsDisplay.ROW;
  @Input() displayWrap = false;
  @Input() loading = false;
  @Output() onClick = new EventEmitter<string>();

  cls: {
    'buttons-left'?: boolean;
    'buttons-right'?: boolean;
    'buttons-full'?: boolean;
    'display-row'?: boolean;
    'display-column'?: boolean;
  } = {};

  execute(action: string, event: MouseEvent): void {
    event.stopPropagation();
    const exist = find(this.disableButtons, { action });

    if (!exist && !this.loading) {
      this.onClick.emit(action);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { disableButtons, position, display, displayWrap } = changes;

    if (disableButtons) {
      this.buttons = map<ActionButtonConfig[], ActionButtonConfig>(this.buttons, (button: ActionButtonConfig) => {
        const exist = find(disableButtons.currentValue, { action: button.action });

        return { ...button, disable: exist };
      });
    }

    if (position) {
      const currentPosition = position.currentValue;
      this.cls['buttons-left'] = currentPosition === ActionButtonsPosition.LEFT;
      this.cls['buttons-right'] = currentPosition === ActionButtonsPosition.RIGHT;
      this.cls['buttons-full'] = currentPosition === ActionButtonsPosition.FULL;
    }

    if (display) {
      const currentDisplay = display.currentValue;
      this.cls['display-row'] = currentDisplay === ActionButtonsDisplay.ROW;
      this.cls['display-column'] = currentDisplay === ActionButtonsDisplay.COLUMN;
    }

    if (displayWrap) {
      this.cls['display-wrap'] = !!displayWrap.currentValue;
    }
  }
}
