import { ActionReducer, ActionReducerMap } from '@ngrx/store';
import { InjectionToken, Provider } from '@angular/core';
import { USER_PROVIDED_EFFECTS } from '@ngrx/effects';
import { IAsyncEntityState } from '@haulynx/types';
import { PreferredLanesEntityService } from './preferred-lanes-entity.service';

export const PREFERRED_LANES_ENTITY_REDUCERS_TOKEN = new InjectionToken<ActionReducerMap<IAsyncEntityState<unknown>>>(
  'preferred lanes reducers'
);

function getPreferredLanesEntityReducersFactory(
  preferredLanesEntityService: PreferredLanesEntityService
): ActionReducer<IAsyncEntityState<unknown>> {
  return preferredLanesEntityService.getReducers();
}

const preferredLanesEntityReducerProvider: Provider = {
  provide: PREFERRED_LANES_ENTITY_REDUCERS_TOKEN,
  useFactory: getPreferredLanesEntityReducersFactory,
  deps: [PreferredLanesEntityService],
};

const preferredLanesEntityEffectProvider: Provider = {
  provide: USER_PROVIDED_EFFECTS,
  multi: true,
  useValue: [PreferredLanesEntityService],
};

export const preferredLanesStateProviders: Provider[] = [
  preferredLanesEntityReducerProvider,
  preferredLanesEntityEffectProvider,
];
