import { LoadIdentifierType, LoadsServiceLoad } from '@haulynx/types';
import { Pipe, PipeTransform } from '@angular/core';
import { getLoadsServiceLoadAlternateId } from '@haulynx/utils';

@Pipe({
  name: 'loadAlternateId',
})
export class LoadAlternateIdPipe implements PipeTransform {
  transform(load: LoadsServiceLoad, identifierType: LoadIdentifierType, checkCoordinates = true): string {
    return getLoadsServiceLoadAlternateId(load, identifierType, checkCoordinates);
  }
}
