import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoadFeedDetailsBrokerContainer, LoadFeedSearchContainerComponent } from '@haulynx/components';
import { AuthGuard, LoadDetailsGuard, LoadFeedPermissionGuard } from '@haulynx/guards';
import { getLoadsTabs } from '@haulynx/types';
import { LoadFeedContainer } from './load-feed/load-feed.component';

const routes: Routes = [
  {
    path: '',
    component: LoadFeedContainer,
    canActivate: [LoadFeedPermissionGuard],
    data: {
      tabs: getLoadsTabs(),
    },
    children: [
      {
        path: 'search',
        component: LoadFeedSearchContainerComponent,
        canActivate: [AuthGuard],
        data: {
          selected: getLoadsTabs().SEARCH,
        },
      },
      {
        path: 'my/:id',
        component: LoadFeedDetailsBrokerContainer,
        canActivate: [LoadDetailsGuard],
      },
      {
        path: 'view',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('../load-feed-details/load-feed-details.module').then((m) => m.LoadFeedDetailsModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LoadFeedRoutingModule {}
