import { Bid, BidHistory, BidHistoryEventType, BidSourceType } from '@haulynx/types';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bidSourceTypeCount',
})
export class BidSourceTypeCountPipe implements PipeTransform {
  transform(bid: Bid, bidHistory: BidHistory[], source: BidSourceType): number {
    if (source === BidSourceType.CARRIER) {
      const currentBidVal = bid.sourceType === BidSourceType.CARRIER ? 1 : 0;
      return currentBidVal + this.getCarrierPriceChanges(bidHistory);
    } else if (source === BidSourceType.BROKER) {
      const currentBidVal = bid.sourceType === BidSourceType.BROKER ? 1 : 0;
      return currentBidVal + (bid.counterOffers || []).length;
    } else {
      return 0;
    }
  }

  private getCarrierPriceChanges(bidHistory: BidHistory[] = []): number {
    return bidHistory.filter(
      (bh) => bh.event === BidHistoryEventType.UPDATE_BID && !!bh.createdBy.carrier && bh.data.price !== undefined
    ).length;
  }
}
