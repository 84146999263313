import { AgmCoreModule, LAZY_MAPS_API_CONFIG } from '@agm/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@haulynx/modules';
import { GoogleAddressService } from '@haulynx/services';
import { AsyncEntityGeocodingeModule } from '@haulynx/store';
import { Environment } from '@haulynx/types';
import { AdvancedSearchModule } from '../advanced-search/advanced-search.module';
import { AppAddressFieldComponent } from './components/app-address-field.component';
import { GoogleAddressFieldComponent } from './google-address-field.component';

@NgModule({
  providers: [
    GoogleAddressService,
    {
      provide: LAZY_MAPS_API_CONFIG,
      useFactory: (environment: Environment) => {
        return {
          apiKey: environment.google.mapsApi,
          libraries: ['places'],
        };
      },
      deps: [Environment],
    },
  ],
  declarations: [GoogleAddressFieldComponent, AppAddressFieldComponent],
  imports: [CommonModule, ReactiveFormsModule, MaterialModule, AdvancedSearchModule, AsyncEntityGeocodingeModule],
  exports: [GoogleAddressFieldComponent, AppAddressFieldComponent],
})
export class GoogleAddressFieldModule {}
