<div class="subnav-template" fxLayout="column">
  <div class="main-menu-button" (click)="close()" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
    <mat-icon svgIcon="left-arrow"></mat-icon>
    <span>Main Menu</span>
  </div>
  <div fxLayout="column">
    <div class="title" mat-dialog-title>
      <ng-content select="[title]"></ng-content>
    </div>
    <mat-divider></mat-divider>
    <div class="subnav-template__content-wrap content">
      <ng-content select="[content]"></ng-content>
    </div>
  </div>
</div>
