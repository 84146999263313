import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TabsModule } from '@haulynx/components';
import { ActiveLoadsStateModule, LoadDetailsStateModule } from '@haulynx/store';
import { LoadFeedDetailsModule } from '../../load-feed-details/load-feed-details.module';
import { LoadDetailsContainerModule } from '../load-details-container/load-details-container.module';
import { CompletedLoadsContainerRoutingModule } from './completed-loads-container-routing.module';
import { CompletedLoadsContainerComponent } from './completed-loads-container.component';

@NgModule({
  declarations: [CompletedLoadsContainerComponent],
  imports: [
    CommonModule,
    CompletedLoadsContainerRoutingModule,
    TabsModule,
    ActiveLoadsStateModule,
    LoadDetailsStateModule,
    LoadDetailsContainerModule,
    LoadFeedDetailsModule,
  ],
  exports: [CompletedLoadsContainerComponent],
})
export class CompletedLoadsContainerModule {}
