<div class="load-timeline-status" *ngIf="load" [ngClass]="{ 'load-overview': loadOverview }">
  <div class="timeline__header">
    <span class="timeline__header--details">{{ loadOverview ? 'TRACKING' : 'LOAD STATUS' }}</span>
    <span class="timeline__header--status" *ngIf="showStatus">
      <span *ngIf="activeStep">{{ loadStatus.label }}</span>
    </span>
  </div>

  <div class="timeline__body">
    <div *ngFor="let step of steps" class="timeline-step" [ngClass]="{ selected: step.id === selectedId }">
      <div class="timeline-step__icon">
        <ng-container *ngIf="locationStepTypes | includes: step.type; else stepIcon">
          <mat-icon *ngIf="step.status === loadStepTypeStatus.COMPLETED" [svgIcon]="'haulynx-load-accepted'">
          </mat-icon>
          <div class="icon" *ngIf="step.status !== loadStepTypeStatus.COMPLETED" [ngClass]="step.type"></div>
        </ng-container>

        <ng-template #stepIcon>
          <mat-icon *ngIf="step.status === loadStepTypeStatus.COMPLETED" [svgIcon]="'haulynx-load-accepted'">
          </mat-icon>
          <mat-icon
            *ngIf="step.status !== loadStepTypeStatus.COMPLETED"
            [svgIcon]="loadOverview ? 'haulynx-awaiting-confirm-white' : 'haulynx-awaiting-confirm'"
          ></mat-icon>
        </ng-template>
      </div>

      <div
        class="timeline-step__info"
        [ngClass]="{
          'inline-info':
            step.type !== loadStepType.PICKUP_STOP &&
            step.type !== loadStepType.DROPOFF_STOP &&
            step.type !== loadStepType.PICKUP &&
            step.type !== loadStepType.DROPOFF
        }"
      >
        <ng-container *ngIf="locationStepTypes | includes: step.type; else firstSteps">
          <div class="address">
            {{ step.data?.summaryAddress }}
          </div>
          <div class="created-time" *ngIf="step.data?.timestamp">
            {{ step.data | loadServiceLocationTime: { showLabel: true, timeFormat: 'd MMM yyyy HH:mm zz' } }}
          </div>
          <div class="arrival-time" *ngIf="step.data?.arrivalTime">
            Entry: {{ step.data?.arrivalTime | mtz: 'M/D/YYYY HH:mm z':step.data?.timezone }}
          </div>
          <div class="departure-time" *ngIf="step.data?.departureTime">
            Exit: {{ step.data?.departureTime | mtz: 'M/D/YYYY HH:mm z':step.data?.timezone }}
          </div>
        </ng-container>

        <ng-template #firstSteps>
          <ng-container *ngIf="step.type === loadStepType.ASSIGNED">
            <ul class="step-info assigned">
              <li>Driver: {{ step.data | get: 'driver.name':'N/A' }}</li>
              <li>Truck: {{ step.data | get: 'truck.unitId':'N/A' }}</li>
              <li>Trailer: {{ step.data | get: 'trailer.trailerNumber':'N/A' }}</li>
            </ul>
          </ng-container>
          <ng-container *ngIf="step.type === loadStepType.DISPATCHED">
            <ul class="step-info dispatched">
              <li>Address: {{ step.data | get: 'dispatchLocation.address':'N/A' }}</li>
              <li>
                Time available:
                {{
                  step.data?.dispatchLocation
                    ? (step.data?.dispatchLocation?.timestamp
                      | mtz: 'M/D/YYYY HH:mm z':step.data?.dispatchLocation?.timezone)
                    : 'N/A'
                }}
              </li>
              <li class="notes">
                <app-text-area-handler
                  *ngIf="step.data.dispatchLocation && step.data.dispatchLocation.notes; else none"
                  label="Notes:"
                  [readOnly]="true"
                  [data]="step.data.dispatchLocation.notes"
                >
                </app-text-area-handler>
                <ng-template #none>Notes: NA</ng-template>
              </li>
            </ul>
          </ng-container>
        </ng-template>
      </div>

      <div
        class="timeline-step__action"
        [ngClass]="{
          disabled: (step.status === loadStepTypeStatus.INACTIVE && step.id !== activeStep?.id) || !bookedLoad,
          active: step.id === activeStep?.id || step.status === loadStepTypeStatus.SKIPPED
        }"
      >
        <div class="btn" (click)="execute(step)">
          {{ mapButtonLabel[step.type] }}
        </div>

        <div class="timeline-indicator" *ngIf="showIndicator && step.id === selectedId && !finalledLoad">
          <div class="timeline-indicator--circle"></div>
          <div class="timeline-indicator--line"></div>
        </div>
      </div>
    </div>
  </div>
</div>
