import {
  activeCarrierLoadSearchFilters,
  allCarrierLoadSearchFilters,
  CarrierLoadSearchFilter,
  LoadServiceSearchParamData,
  LoadServiceSearchParameters,
} from '@haulynx/types';
import { keyBy, reduce } from 'lodash';

export const carrierLoadSearchFilterBaseMap = keyBy(
  [...allCarrierLoadSearchFilters, ...activeCarrierLoadSearchFilters],
  'id'
);

export const serializeLoadSearch = (state: LoadServiceSearchParamData): Partial<LoadServiceSearchParameters> => {
  return reduce(
    state,
    (prev: Partial<LoadServiceSearchParameters>, curr: LoadServiceSearchParamData, key: CarrierLoadSearchFilter) => {
      const matchedFilter = carrierLoadSearchFilterBaseMap[key];
      if (!matchedFilter) return { ...prev };
      return { ...prev, ...matchedFilter.serialize(state) };
    },
    {}
  );
};
