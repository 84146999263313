import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { RmisProps } from '@haulynx/types';

@Component({
  selector: 'haulynx-sidebar-nav-sub-account-setup',
  templateUrl: './sidebar-nav-sub-account-setup.component.html',
  styleUrls: ['./sidebar-nav-sub-account-setup.component.scss'],
})
export class SidebarNavSubAccountSetupComponent {
  @Input() sidenav: MatSidenav;
  @Input() rmisProps: RmisProps;

  @Output() subNavToggle = new EventEmitter<MatSidenav>();
  @Output() complete = new EventEmitter<void>();
  @Output() continueRmis = new EventEmitter();

  toggleSubNav(subNav: MatSidenav): void {
    this.subNavToggle.emit(subNav);
  }

  clickCompleted() {
    this.complete.emit();
    this.sidenav.close();
  }

  continueToRmis(): void {
    this.continueRmis.emit();
  }
}
