import { Injectable } from '@angular/core';
import { Carrier, FeatureFlagPermission, FeatureFlags, User } from '@haulynx/types';
import { get } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagActionService {
  customSortDetails(row: Partial<User> | Partial<Carrier>, column: string): string {
    switch (column) {
      case 'entityName':
        return row.name.toLowerCase();

      case 'entityEmail':
        return row.email.toLowerCase();

      default:
        return '';
    }
  }

  customSortFeatures(row: FeatureFlags, column: string): string | number {
    switch (column) {
      case 'environments':
        const { permissions } = row;

        return permissions.reduce((acc: string, restriction: FeatureFlagPermission) => {
          const dots = get(restriction, 'dots', null);
          const users = get(restriction, 'users', null);
          const environment = get(restriction, 'environment', null);

          return dots || users ? acc + environment : acc;
        }, '');

      case 'platforms':
        const { platforms } = row;

        return Object.entries(platforms).reduce(
          (acc: string, [key, value]: [string, boolean]) => (value ? acc + key : acc),
          ''
        );

      default:
        return '';
    }
  }
}
