<form [formGroup]="form" class="chip-list">
  <app-spinner *ngIf="isLoading" [active]="true" [fullCover]="true"></app-spinner>

  <mat-form-field class="chip-list__wrapper">
    <mat-chip-list #emailChipList formArrayName="email">
      <mat-chip
        *ngFor="let email of emailForm?.controls; let i = index"
        [removable]="removable"
        (removed)="onRemove(i)"
        [disabled]="isDisabled"
      >
        {{ email.value }}
        <mat-icon matChipRemove *ngIf="removable">close</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </mat-form-field>

  <mat-error class="chip-list__error" data-test="email-mat-error" *ngIf="emailForm?.hasError('email')">
    Please enter a valid email
  </mat-error>

  <div class="chip-list__input__wrapper" *ngIf="showEnterEmailAddresses">
    <mat-form-field class="app-dark-field" appearance="fill" floatLabel="always">
      <label *ngIf="labelText">
        {{ labelText }}
      </label>
      <input
        matInput
        data-test="email-input"
        autocomplete="off"
        placeholder="Enter Email Addresses"
        class="form-control"
        [disabled]="isDisabled"
        [matChipInputFor]="emailChipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="true"
        (matChipInputTokenEnd)="onAdd($event)"
        tabindex="1"
      />
      <div class="chip-list__button--add">
        <mat-icon class="chip-list__input__button" matSuffix tabindex="2"> add </mat-icon>
      </div>
    </mat-form-field>
  </div>
  <div class="chip-list__footer">
    <button
      *ngIf="saveButtonText"
      mat-flat-button
      class="chip-list__button--save"
      [disabled]="form?.invalid"
      (click)="onSave()"
    >
      {{ saveButtonText }}
    </button>
  </div>
</form>
