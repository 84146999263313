<div class="timeline-container">
  <div class="timeline-wrapper" *ngFor="let location of load?.locations; let $last = last">
    <div class="timeline-item">
      <div class="icon-wrapper">
        <mat-icon *ngIf="!$last" class="location-small-icon" svgIcon="haulynx-{{ location?.locationType }}"> </mat-icon>
        <mat-icon *ngIf="$last" class="location-small-icon" svgIcon="haulynx-dropoff"> </mat-icon>
      </div>

      <div class="timeline-content" [ngClass]="{ 'timeline-border': !$last }">
        {{ location?.address || 'N/A' }}
        <br />
        <span class="muted">
          {{ location | loadServiceLocationTime: { showLabel: true } }}
        </span>
      </div>
    </div>
  </div>
</div>
