<div class="load-active-delivery">
  <h1 mat-dialog-title>{{ title }}</h1>
  <div mat-dialog-content>
    <form [formGroup]="form">
      <div class="form_location">
        <span
          class="location__control--address"
          [ngStyle]="{ color: form.get('locationType').value === 'pickup' ? '#448BF5' : '#00CA8D' }"
        >
          {{ form.get('locationAddress').value | address: 1 }} {{ form.get('locationAddress').value | address: 2 }}
        </span>

        <div class="load-active-delivery__field">
          <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
            <mat-label>
              {{ form.get('locationType').value === 'pickup' ? 'Entrance Shipper' : 'Entrance Consignee' }}
            </mat-label>
            <input
              matInput
              type="text"
              data-test="entranceTime"
              readonly
              [owlDateTime]="owlEntranceTimestamp"
              (dateTimeChange)="fixTimezone('entranceTime')"
              formControlName="entranceTime"
              autocomplete="off"
              [min]="min"
              [max]="max"
            />
            <mat-icon
              matSuffix
              [owlDateTimeTrigger]="owlEntranceTimestamp"
              svgIcon="haulynx-calendar"
              class="calendar-icon"
            >
            </mat-icon>
            <owl-date-time #owlEntranceTimestamp></owl-date-time>

            <mat-error *ngIf="form.get('entranceTime').errors?.dateMoreThanNow">
              This date is more than now.
            </mat-error>

            <mat-error *ngIf="form.get('entranceTime').errors?.required"> This date is required </mat-error>
          </mat-form-field>

          <div class="load-active-delivery__field--action">
            <ng-container *ngIf="!(isEntranceLoading$ | async); else showSpinner">
              <a
                mat-flat-button
                [disabled]="form.get('entranceTime').disabled || entranceController.invalid"
                [ngClass]="{ orange: data.location.arrivalTime }"
                (click)="confirmArrivalTime()"
                data-test="confirm-update"
              >
                {{ data.location.arrivalTime ? 'Update' : 'Confirm' }}
              </a>
            </ng-container>
          </div>
        </div>

        <div class="load-active-delivery__field">
          <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
            <mat-label>
              {{ form.get('locationType').value === 'pickup' ? 'Exit Shipper' : 'Exit Consignee' }}
            </mat-label>
            <input
              matInput
              type="text"
              data-test="exitTime"
              readonly
              [owlDateTime]="owlExitTimestamp"
              (dateTimeChange)="fixTimezone('completed')"
              formControlName="completed"
              placeholder="{{ form.get('locationType').value === 'pickup' ? 'Exit Shipper' : 'Exit Consignee' }}"
              autocomplete="off"
              [min]="min"
              [max]="max"
            />
            <mat-icon
              matSuffix
              [owlDateTimeTrigger]="owlExitTimestamp"
              svgIcon="haulynx-calendar"
              class="calendar-icon"
            >
            </mat-icon>
            <owl-date-time #owlExitTimestamp></owl-date-time>

            <mat-error *ngIf="form.get('completed').errors?.oneEntryRequired">
              This date is invalid. Please try again.
            </mat-error>

            <mat-error *ngIf="form.get('completed').errors?.dateMoreThanNow"> This date is more than now. </mat-error>
          </mat-form-field>

          <div class="load-active-delivery__field--action">
            <ng-container *ngIf="!(isExistLoading$ | async); else showSpinner">
              <ng-container
                *ngIf="
                  form.get('completed').disabled ||
                    completedController.invalid ||
                    (form.get('locationType').value === 'pickup' && !data.trailerId && isUsxLoad);
                  else showConfirmcompleted
                "
              >
                <a
                  mat-flat-button
                  [disabled]="true"
                  [ngClass]="{ orange: data.location.completed }"
                  data-test="confirm-update"
                >
                  {{ data.location.completed ? 'Update' : 'Confirm' }}
                </a>
              </ng-container>

              <ng-template #showConfirmcompleted>
                <a
                  mat-flat-button
                  [disabled]="form.errors?.entranceMoreThanExit"
                  (click)="confirmDepartureTime()"
                  [ngClass]="{ orange: data.location.completed }"
                  data-test="confirm-update"
                >
                  {{ data.location.completed ? 'Update' : 'Confirm' }}
                </a>
              </ng-template>
            </ng-container>
          </div>
        </div>
        <button mat-icon-button [mat-dialog-close] class="load-active-delivery__close" data-test="closeLoad">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <mat-error *ngIf="form.errors?.entranceMoreThanExit"> Exit time is the same or before entry time. </mat-error>

      <mat-error *ngIf="form.get('locationType').value === 'pickup' && !data.trailerId && isUsxLoad">
        Must assign trailer first
      </mat-error>
    </form>

    <ng-template #showSpinner>
      <app-spinner [active]="true" [diameter]="40"></app-spinner>
    </ng-template>
  </div>
</div>
