import gql from 'graphql-tag';

export const ValidateMissions = gql`
  query ValidateMissions($missionBatchInput: [MissionCreateInput!]!) {
    validateMissions(missionBatchInput: $missionBatchInput) {
      valid
      missions {
        orders {
          orderNumber
          valid
          reason
        }
      }
    }
  }
`;
