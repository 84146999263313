import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({ name: 'phoneMask' })
export class PhoneMaskPipe implements PipeTransform {
  transform(data: string, mask: string): any {
    const removedDashes = _.replace(data, /(\()|(\))|(\s)|(-)/g, '');
    const dataArr = removedDashes.split('');
    const result = _.map(mask.split(''), (char) => {
      if (char === 'X' || char === 'x') {
        return dataArr.shift();
      } else {
        return char;
      }
    });
    return result.join('');
  }
}
