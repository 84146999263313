import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@haulynx/modules';
import { BlueInputComponent } from './blue-input.component';

@NgModule({
  declarations: [BlueInputComponent],
  imports: [CommonModule, MaterialModule, FlexLayoutModule, ReactiveFormsModule],
  exports: [BlueInputComponent],
})
export class BlueInputModule {}
