<div class="bulk-actions" [ngStyle]="{ 'flex-direction': verticalActions ? 'column' : 'row' }">
  <div class="bulk-actions">
    <!-- Max Buy Button -->
    <div
      *ngIf="bulkActionsPage === BulkActionPage.SEARCH"
      class="bulk-actions-item"
      [ngStyle]="{ background: verticalActions && hoverIndex === 1 ? '#353945' : 'inherit' }"
      (mouseover)="hoverIndex = 1"
      (mouseleave)="hoverIndex = -1"
      (click)="openBulkActionsDetailsModal(LoadBulkAction.UPDATE_MAX_BUY)"
    >
      <button class="bulk-action-button" type="button" data-test="max-buy-bulk">
        <mat-icon svgIcon="haulynx-max-buy"></mat-icon>
        <div *ngIf="showTitle" class="title">Max Buy</div>
      </button>
    </div>

    <!-- Share Button -->
    <div
      *ngIf="bulkActionsPage === BulkActionPage.SEARCH || bulkActionsPage === BulkActionPage.CARRIER_SEARCH"
      class="bulk-actions-item"
      [ngStyle]="{ background: verticalActions && hoverIndex === 2 ? '#353945' : 'inherit' }"
      (mouseover)="hoverIndex = 2"
      (mouseleave)="hoverIndex = -1"
    >
      <button
        class="bulk-action-button"
        type="button"
        ngxClipboard
        [cbContent]="onShareLoads()"
        (cbOnSuccess)="copyLoadSuccess()"
        data-test="share-load-bulk"
      >
        <mat-icon svgIcon="haulynx-share-loads"></mat-icon>
        <div *ngIf="showTitle" class="title">Share These Loads</div>
      </button>
    </div>

    <!-- Assignment Button -->
    <div
      *ngIf="bulkActionsPage === BulkActionPage.SEARCH"
      class="bulk-actions-item"
      [ngStyle]="{ background: verticalActions && hoverIndex === 3 ? '#353945' : 'inherit' }"
      (mouseover)="hoverIndex = 3"
      (mouseleave)="hoverIndex = -1"
      (click)="openBulkActionsDetailsModal(LoadBulkAction.ASSIGN_REPS)"
    >
      <button
        *ngIf="displayAssignTo && displaySelectedAssignTo"
        class="bulk-action-button"
        type="button"
        data-test="assign-load-bulk"
      >
        <mat-icon svgIcon="haulynx-assign-to"></mat-icon>
        <div *ngIf="showTitle" class="title">Assign To</div>
      </button>
    </div>

    <!-- Priority Button -->
    <div
      *ngIf="bulkActionsPage === BulkActionPage.SEARCH"
      class="bulk-actions-item"
      [ngStyle]="{ background: verticalActions && hoverIndex === 4 ? '#353945' : 'inherit' }"
      (mouseover)="hoverIndex = 4"
      (mouseleave)="hoverIndex = -1"
      (click)="openBulkActionsDetailsModal(LoadBulkAction.PRIORITY_CHANGE)"
    >
      <button class="bulk-action-button" type="button" data-test="assign-priority-bulk">
        <mat-icon svgIcon="haulynx-priority"></mat-icon>
        <div *ngIf="showTitle" class="title">Priority</div>
      </button>
    </div>

    <!-- Report Rolled loads -->
    <div
      *ngIf="bulkActionsPage === BulkActionPage.SEARCH && isCompanyAdmin"
      class="bulk-actions-item"
      [ngStyle]="{ background: verticalActions && hoverIndex === 1 ? '#353945' : 'inherit' }"
      (mouseover)="hoverIndex = 1"
      (mouseleave)="hoverIndex = -1"
      (click)="openRolledLoadsDialog()"
    >
      <button class="bulk-action-button" type="button">
        <mat-icon svgIcon="haulynx-rolled-load-gray"></mat-icon>
        <div *ngIf="showTitle" class="title">Report Rolled Loads</div>
      </button>
    </div>

    <!-- Export CSV -->
    <div
      *ngIf="bulkActionsPage === BulkActionPage.SEARCH"
      class="bulk-actions-item"
      [ngStyle]="{ background: verticalActions && hoverIndex === 1 ? '#353945' : 'inherit' }"
      (mouseover)="hoverIndex = 1"
      (mouseleave)="hoverIndex = -1"
      (click)="onDownloadCSV()"
    >
      <button class="bulk-action-button" type="button">
        <mat-icon >archive</mat-icon>
        <div *ngIf="showTitle" class="title">Export CSV</div>
      </button>
    </div>
  </div>

  <div class="bulk-actions" *ngIf="bulkActionsPage === BulkActionPage.LANE_RATE">
    <!-- Adjust Percentage -->
    <div
      class="bulk-actions-item"
      [ngStyle]="{ background: verticalActions && hoverIndex === 1 ? '#353945' : 'inherit' }"
      (mouseover)="hoverIndex = 1"
      (mouseleave)="hoverIndex = -1"
      (click)="openBulkActionsDetailsModal(LoadBulkAction.ADJUST_PERCENTAGE)"
    >
      <button class="bulk-action-button" type="button">
        <mat-icon svgIcon="haulynx-max-buy"></mat-icon>
        <div *ngIf="showTitle" class="title">Adjust Percentage</div>
      </button>
    </div>

    <!-- Adjust Dollar Amount -->
    <div
      class="bulk-actions-item"
      [ngStyle]="{ background: verticalActions && hoverIndex === 1 ? '#353945' : 'inherit' }"
      (mouseover)="hoverIndex = 1"
      (mouseleave)="hoverIndex = -1"
      (click)="openBulkActionsDetailsModal(LoadBulkAction.ADJUST_DOLLAR_AMOUNT)"
    >
      <button class="bulk-action-button" type="button">
        <mat-icon svgIcon="haulynx-adjust-dollar-amount"></mat-icon>
        <div *ngIf="showTitle" class="title">Adjust Dollar Amount</div>
      </button>
    </div>
  </div>

  <div class="bulk-actions" *ngIf="bulkActionsPage === BulkActionPage.MISSION">
    <!-- Delete Mission -->
    <div
      class="bulk-actions-item"
      [ngStyle]="{ background: verticalActions && hoverIndex === 1 ? '#353945' : 'inherit' }"
      (mouseover)="hoverIndex = 1"
      (mouseleave)="hoverIndex = -1"
      (click)="openBulkActionsDetailsModal(LoadBulkAction.DELETE_MISSION)"
    >
      <button class="bulk-action-button" type="button">
        <mat-icon class="light-gray" svgIcon="haulynx-trash-gray" data-test="delete-button"></mat-icon>
        <div *ngIf="showTitle" class="title">Delete Mission</div>
      </button>
    </div>
  </div>
</div>
