import { ISearchFilter, NumericalFilterType } from '../search-filter';
import { ISearchFilterType } from '../search-filter.types';

export class MaxBuyFilter implements ISearchFilter {
  name = 'Max Buy';
  type: ISearchFilterType = ISearchFilterType.NUMBER_RANGE;
  keys: NumericalFilterType;
  validation?: NumericalFilterType;
  description = 'Filter loads by the Max Buy amount';
  formPrefix = '$';

  constructor(defaultValues: Partial<NumericalFilterType> = {}, validationValues: Partial<NumericalFilterType> = {}) {
    this.keys = new NumericalFilterType({
      min: 0,
      max: null,
      minFormName: 'minMaxBuy',
      maxFormName: 'maxMaxBuy',
      ...defaultValues,
    });
    this.validation = new NumericalFilterType(validationValues);
  }
}
