import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@haulynx/modules';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AccountHeaderComponent } from './account-header.component';

@NgModule({
  declarations: [AccountHeaderComponent],
  imports: [CommonModule, MaterialModule, FlexLayoutModule, AngularSvgIconModule.forRoot()],
  exports: [AccountHeaderComponent],
})
export class AccountHeaderModule {}
