import { createSelector } from '@ngrx/store';
import {
  carrierAdapter,
  carrierDashboardAdapter,
  carrierDashboardLanesAdapter,
  carrierDashboardTrucksAdapter,
} from './adapters';
import { getCarriersState } from './carriers.state';

const getSearchCarrierState = createSelector(getCarriersState, (state) => {
  return state.searchCarrier;
});

export const getLoadingMapTruckIdsSelectors = createSelector(getCarriersState, (state) => {
  return state.loadingMapTruckIds;
});

export const carrierSearchSelector = carrierAdapter.searchCarrier.getSelectors(getSearchCarrierState);

const getTabsCarrierState = createSelector(getCarriersState, (state) => {
  return state.tabs;
});

export const carrierTabsSelector = carrierAdapter.tabs.getSelectors(getTabsCarrierState);

export const getIsLoadingSelector = createSelector(getCarriersState, (state) => {
  return state.isLoading;
});

const getCarrierFormState = createSelector(getCarriersState, (state) => {
  return state.carrierForm;
});
export const carrierDashboardFormSelector = carrierDashboardAdapter.carrierForm.getSelectors(getCarrierFormState);

const getSearchRecommendationState = createSelector(getCarriersState, (state) => {
  return state.searchRecommendation;
});
export const carrierDashboardSearchSelector = carrierDashboardAdapter.searchRecommendation.getSelectors(
  getSearchRecommendationState
);

export const carrierRouteSelector = createSelector(getCarriersState, (state) => {
  return state.routes;
});

export const isLoadingRouteSelector = createSelector(getCarriersState, (state) => {
  return state.isLoadingRoutes;
});

const getSearchLanesState = createSelector(getCarriersState, (state) => {
  return state.searchLanes;
});

export const carrierDashboardLanesSearchSelector = carrierDashboardLanesAdapter.searchLanes.getSelectors(
  getSearchLanesState
);

const getSearchTrucksState = createSelector(getCarriersState, (state) => {
  return state.searchTrucks;
});

export const carrierDashboardTrucksSearchSelector = carrierDashboardTrucksAdapter.searchTrucks.getSelectors(
  getSearchTrucksState
);

export const getIsLoadingCarrierKpiMetricsSelector = createSelector(getCarriersState, (state) => {
  return state.isLoadingCarrierKpiMetrics;
});

export const getCarrierKpiMetricsSelector = createSelector(getCarriersState, (state) => {
  return state.carrierKpiMetrics;
});

const getCarriersTabsState = createSelector(getCarriersState, (state) => state.carriersTabs);

export const carriersTabsSelector = carrierAdapter.tabs.getSelectors(getCarriersTabsState);

export const getIsLoadingPostTruckSelector = createSelector(getCarriersState, (state) => {
  return state.isLoadingPostTruck;
});

export const getCarrierInsuranceAndSafetySelector = createSelector(
  getCarriersState,
  (state) => state.carrierInsuranceAndSafety
);
export const getIsLoadingCarrierInsuranceAndSafetySelector = createSelector(
  getCarriersState,
  (state) => state.isLoadingCarrierInsuranceAndSafety
);

export const getIsLoadingCarriersStatusSelector = createSelector(
  getCarriersState,
  (state) => state.isLoadingCarriersStatus
);

export const getCarriersStatusSelector = createSelector(getCarriersState, (state) => state.carriersStatus);
