import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UnauthenticatedLoadsServiceLoad } from '@haulynx/types';

@Component({
  selector: 'app-shared-location-timeline',
  templateUrl: './shared-location-timeline.component.html',
  styleUrls: ['./shared-location-timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedLocationTimelineComponent {
  @Input() load: UnauthenticatedLoadsServiceLoad;

  constructor() {}
}
