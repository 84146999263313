import { Injectable } from '@angular/core';
import { Environment } from '@haulynx/types';
import { SubscriptionClient } from 'graphql-subscriptions-client';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { UserService } from '../shared-services/user.service';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  private token: string;
  constructor(private userService: UserService, private enviroment: Environment) {
    this.userService.token.subscribe((data) => {
      this.token = data;
    });
  }

  private getAuthHeaders(bearerToken: string) {
    return {
      ...(bearerToken && { Authorization: `Bearer ${bearerToken}` }),
    };
  }

  private createWsClient(): SubscriptionClient {
    return new SubscriptionClient(this.enviroment.websocketUrl, {
      lazy: true,
      reconnect: true,
      connectionParams: {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          ...this.getAuthHeaders(this.token),
        },
      },
      connectionCallback(error) {
        if (error) {
          console.error(error);
        }
      },
    });
  }

  public request<T>(query: string) {
    const subject = new Subject<T>();
    const client = this.createWsClient();
    const subscription = client.request({ query }).subscribe({
      complete() {},
      error(error) {},
      next(resp) {
        subject.next(resp?.data);
      },
    });
    const observable = subject.asObservable().pipe(
      finalize(() => {
        client.close();
        subscription.unsubscribe();
      })
    );

    return observable;
  }
}
