<div *ngIf="!isLoading; else loading" class="active-search-container">
  <div class="load_active_search">
    <div class="load_active_search_column">
      <div class="load_active_search_header">Load Offers</div>
    </div>
    <div class="load_active_search_column">
      <div class="load_active_search_header">{{ isBroker ? 'Accepted' : 'Unassigned' }}</div>
    </div>
    <div class="load_active_search_column">
      <div class="load_active_search_header">{{ isBroker ? 'At Shipper' : 'Assigned' }}</div>
    </div>
    <div class="load_active_search_column">
      <div class="load_active_search_header">In Transit</div>
    </div>
  </div>

  <div class="load_active_search content" *ngIf="!noActiveLoads">
    <div class="load_active_search_column">
      <div class="load_active_search_body" *ngIf="displayLoadOffers">
        <app-load-single-item
          *ngFor="let load of entitiesLoadOffers"
          [load]="load"
          [showDecline]="true"
          [isSelected]="load.id === selectedId"
          [isBroker]="isBroker"
          [showBookLoad]="true"
          (onBookSuccess)="bookSuccess()"
          (offerDeleteSuccessEvent)="offerDeleteSuccess($event)"
          (click)="setSelectedLoad(load)"
          data-test="load-offers-load"
        >
        </app-load-single-item>
      </div>
    </div>

    <div class="load_active_search_column">
      <div class="load_active_search_body">
        <app-load-single-item
          *ngFor="let load of entitiesUnassigned"
          [load]="load"
          [isSelected]="load.id === selectedId"
          [isBroker]="isBroker"
          (click)="setSelectedLoad(load)"
          data-test="unassigned-load"
        >
        </app-load-single-item>
      </div>
    </div>

    <div class="load_active_search_column">
      <div class="load_active_search_body">
        <app-load-single-item
          *ngFor="let load of entitiesAssigned"
          [load]="load"
          [isSelected]="load.id === selectedId"
          [isBroker]="isBroker"
          (click)="setSelectedLoad(load)"
          data-test="assigned-load"
          attr.data-load-id="{{ load.id }}"
        >
        </app-load-single-item>
      </div>
    </div>

    <div class="load_active_search_column">
      <div class="load_active_search_body">
        <app-load-single-item
          *ngFor="let load of entitiesInTransit"
          [load]="load"
          [isSelected]="load.id === selectedId"
          [isBroker]="isBroker"
          (click)="setSelectedLoad(load)"
          data-test="in-transit-load"
        >
        </app-load-single-item>
      </div>
    </div>
  </div>
  <div class="no-active-loads-message" *ngIf="noActiveLoads">
    You currently have no active loads. <br />
    Create a load or book one with your USX rep to begin the load life-cycle management.
  </div>
</div>

<ng-template #loading>
  <app-spinner [active]="true"></app-spinner>
</ng-template>
