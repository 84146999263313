import { LatLng } from '@agm/core';
import { LatLonInput } from './nearestCity';

export interface PlaceInfo {
  addressNumber?: string;
  city?: string;
  country?: string;
  fullAddress?: string;
  lat?: number;
  lon?: number;
  placeType?: PlaceType;
  state?: string;
  street?: string;
  zipcode?: string;
  stateAbbreviation?: string;
}

export enum PlaceType {
  country = 'country',
  region = 'region',
  postcode = 'postcode',
  district = 'district',
  place = 'place',
  locality = 'locality',
  neighborhood = 'neighborhood',
  address = 'address',
  poi = 'poi',
}

export interface GeocodeParams {
  search: string;
  fuzzyMatch?: boolean;
  limit?: number;
  proximity?: LatLonInput;
  placeType?: PlaceType;
  autocomplete?: boolean;
  returnPlace?: boolean;
}
