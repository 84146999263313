<mat-toolbar class="account-toolbar">
  <mat-toolbar-row>
    <!-- Web Header -->
    <div fxShow.gt-sm fxHide fxFlexFill fxLayout="row" fxLayoutAlign="space-between center" class="desktop">
      <svg-icon src="assets/images/xt-powered-by.svg"></svg-icon>

      <div fxLayoutGap="12px" class="buttons">
        <button mat-button class="white" data-mobile="about" (click)="about()">About Us</button>
        <button mat-button class="white" data-mobile="resources" (click)="resources()">Resources</button>
        <ng-container *ngIf="!user; else userSignedIn">
          <button mat-raised-button class="sign-in" data-mobile="signIn" (click)="route(AccountRoute.LOGIN)">
            Sign In
          </button>
          <button mat-raised-button class="sign-up" data-mobile="signUp" (click)="route(AccountRoute.CREATE)">
            Sign Up
          </button>
        </ng-container>
        <ng-template #userSignedIn>
          <button mat-raised-button class="sign-out" data-mobile="signOut" (click)="signOut()">Sign Out</button>
        </ng-template>
      </div>
    </div>

    <!-- Mobile Header -->
    <div fxShow.lt-md fxHide fxFlexFill fxLayout="row" fxLayoutAlign="center center" class="mobile">
      <svg-icon src="assets/images/xt-powered-by.svg" ngClass.xs="smaller-logo"></svg-icon>
      <button mat-icon-button [matMenuTriggerFor]="menu" class="menu">
        <mat-icon>menu</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item data-mobile="about" (click)="about()">About Us</button>
        <button mat-menu-item data-mobile="resources" (click)="resources()">Resources</button>
        <ng-container *ngIf="!user; else userSignedInMobile">
          <button mat-menu-item data-mobile="signIn" (click)="route(AccountRoute.LOGIN)">Sign In</button>
          <button mat-menu-item data-mobile="signUp" (click)="route(AccountRoute.CREATE)">Sign Up</button>
        </ng-container>
        <ng-template #userSignedInMobile>
          <button mat-menu-item data-mobile="signOutMobile" (click)="signOut()">Sign Out</button>
        </ng-template>
      </mat-menu>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
