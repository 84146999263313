import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { UserForm, UserVmService } from '@haulynx/services';
import { UserEntityService, UserFormHttpStatus, UsersModel } from '@haulynx/store';
import { AccountType, buttonTypes, canadaStates, mexicoStates, states, User } from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-signup',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent implements OnInit {
  isUnclaimedUser = false; // This happens when a driver tries to sign up before the carrier admin
  issuingCountry: string;
  issuingState: string;
  statesList: {
    name: string;
    code: string;
  }[];
  form: FormGroup;
  buttons$ = new BehaviorSubject([]);
  disableButtons$ = new BehaviorSubject([]);
  countries = ['UNITED STATES', 'MEXICO', 'CANADA'];
  countryCodes: Record<string, string> = {};
  createMode = true;
  accountType = AccountType;
  get selectedAccountType(): AccountType {
    return this.form.get('accountType').value;
  }
  private alive = aliveWhile();

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: Partial<UserForm>,
    @Optional() private dialogRef: MatDialogRef<UserFormComponent>,
    private userVmService: UserVmService,
    private userEntityService: UserEntityService,
    public usersModel: UsersModel
  ) {
    this.isUnclaimedUser = false;
    this.usersModel.clearSelectedUser();
    this.buttons$.next([buttonTypes.CANCEL, buttonTypes.CREATE]);

    this.userEntityService.attachUnclaimedUserManager.onSuccess$.pipe(takeUntil(this.alive)).subscribe(() => {
      this.close(true);
    });

    this.userEntityService.attachUnclaimedUserManager.onError$.pipe(takeUntil(this.alive)).subscribe(() => {
      this.close();
    });

    this.usersModel.userFormHttpStatus$.pipe(takeUntil(this.alive)).subscribe((status) => {
      if (status === UserFormHttpStatus.SUCCESS) {
        this.close(this.form.value);
      }
    });

    this.form = this.userVmService.create();
    this.form
      .get('accountType')
      .valueChanges.pipe(takeUntil(this.alive))
      .subscribe((accountType) => {
        this.accountTypeValueChanges(accountType);
      });
    this.form.statusChanges.pipe(distinctUntilChanged(), takeUntil(this.alive)).subscribe((status) => {
      if (status === 'INVALID') {
        this.disableButtons$.next([buttonTypes.CREATE, buttonTypes.UPDATE]);
      } else {
        this.disableButtons$.next([]);
      }
    });
    this.form.patchValue(data || {});
    this.countryCodes['UNITED STATES'] = 'US';
    this.countryCodes['MEXICO'] = 'MX';
    this.countryCodes['CANADA'] = 'CA';

    const { id } = data;
    if (id) {
      this.usersModel.getUser(id);
      this.createMode = false;
      this.buttons$.next([buttonTypes.CANCEL, buttonTypes.UPDATE]);

      const controlsToExclude = ['email', 'password', 'passwordConfirm'];
      controlsToExclude.map((item: string) => this.form.get(item).clearValidators());
      this.form.updateValueAndValidity();
    }

    this.usersModel.selectedUser$
      .pipe(
        filter((user: User) => !!user),
        distinctUntilChanged(),
        takeUntil(this.alive)
      )
      .subscribe((user: User) => {
        const { name, phone } = user;
        this.form.patchValue({ name, phone });

        if (this.selectedAccountType === AccountType.BROKER) {
          const { manager, team, jobTitle, usxId: brokerId } = user;
          this.form.get('broker').patchValue({ manager, team, jobTitle, brokerId });
        }
      });
  }

  ngOnInit(): void {
    this.statesList = states;

    this.userEntityService.getUserUnclaimedByEmailManager.onSuccess$
      .pipe(takeUntil(this.alive))
      .subscribe((data: { carrierClaimed: boolean; name: string }) => {
        this.isUnclaimedUser = !!data?.name;
        this.disableButtons$.next([]);
        this.form.controls.name.enable();
        this.buttons$.next([buttonTypes.CANCEL, buttonTypes.CREATE]);
        this.form.controls.name.patchValue(null);

        if (this.isUnclaimedUser) {
          this.form.controls.name.patchValue(data.name);
          this.form.controls.name.disable();
          this.buttons$.next([buttonTypes.CANCEL, buttonTypes.INVITE]);
        }

        if (data?.carrierClaimed) {
          this.disableButtons$.next([buttonTypes.CREATE, buttonTypes.INVITE]);
          this.form.controls.email.setErrors({ carrierClaimed: true });
        }
      });

    this.form.controls.email.valueChanges.pipe(debounceTime(500), takeUntil(this.alive)).subscribe((email: string) => {
      this.emailValueChanges(email);
    });
  }

  onSelectCountry({ value }: MatSelectChange): void {
    this.form.patchValue({ issuingState: null });
    switch (value) {
      case 'UNITED STATES':
        this.statesList = states;
        break;
      case 'MEXICO':
        this.statesList = mexicoStates;
        break;
      case 'CANADA':
        this.statesList = canadaStates;
        break;
    }
  }

  toggleForDriver(accountType: AccountType): void {
    this.form.patchValue({
      accountType: accountType === AccountType.DRIVER ? AccountType.CARRIER : AccountType.DRIVER,
    });
  }

  execute(action): void {
    if (action === buttonTypes.CANCEL.action) {
      this.close();
      return;
    }

    if (action === buttonTypes.INVITE.action) {
      this.inviteUser(this.form.value);
      return;
    }

    if (action === buttonTypes.CREATE.action) {
      this.createUser(this.form.value);
      return;
    }

    if (action === buttonTypes.UPDATE.action) {
      this.updateUser(this.form.value);
      return;
    }
  }

  cancel(): void {
    this.close();
  }

  private close(value: boolean | UserForm = null): void {
    this.dialogRef.close(value);
  }

  private accountTypeValueChanges(accountType: AccountType): void {
    if (accountType === AccountType.DRIVER) {
      this.form.get('driver').enable();
      this.form.get('broker').disable();
    }

    if (accountType === AccountType.BROKER) {
      this.form.get('broker').enable();
      this.form.get('driver').disable();
    }
  }

  private emailValueChanges(email: string): void {
    if (email === '') {
      this.isUnclaimedUser = false;
      this.buttons$.next([buttonTypes.CANCEL, buttonTypes.CREATE]);
      return;
    }

    this.userEntityService.getUserUnclaimedByEmailManager.dispatch({ email });
  }

  private inviteUser(form: UserForm): void {
    const { phone, email, admin, accountType, trailerCreationEnabled, driver } = form;
    const {
      companyId = null,
      issuingCountry = null,
      issuingState = null,
      licenseNumber = null,
      pcEnabled = null,
      teamDrivingEnabled = null,
      ymEnabled = null,
    } = driver;

    this.userEntityService.attachUnclaimedUserManager.dispatch({
      phone,
      email,
      userInfo: {
        admin,
        accountType,
        trailerCreationEnabled,
        companyId,
        issuingCountry,
        issuingState,
        licenseNumber,
        pcEnabled,
        teamDrivingEnabled,
        ymEnabled,
      },
    });
  }

  private createUser(form: UserForm): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id = null, broker = null, driver = null, admin = null, ...user } = form;

    let newUser: Partial<User> = { ...user };
    if (broker) {
      newUser = { ...newUser, ...broker, isDriver: false };
    }
    if (driver) {
      const { issuingCountry, issuingState, licenseNumber } = driver;
      newUser = {
        ...newUser,
        ...driver,
        isCompanyAdmin: admin,
        isDriver: form.accountType === AccountType.DRIVER,
        issuingCountry: this.countryCodes[driver.issuingCountry],
      };
      if (licenseNumber && issuingCountry && issuingState) {
        return this.usersModel.validateDriversLicense({
          country: this.countryCodes[issuingCountry],
          state: issuingState,
          licenseNumber: licenseNumber,
          user: newUser,
        });
      } else {
        newUser.licenseNumber = '';
        newUser.issuingState = '';
        newUser.issuingCountry = '';
      }
    } else {
      newUser = { ...newUser, isCompanyAdmin: admin };
    }
    this.usersModel.createUser(newUser);
  }

  private updateUser(form: UserForm): void {
    const { id: userId, name, phone, accountType } = form;
    let userInfo = { accountType, name, phone } as Partial<User>;

    if (this.selectedAccountType === AccountType.BROKER) {
      const { brokerId: usxId, team, jobTitle, manager } = this.form.get('broker').value;
      userInfo = { ...userInfo, usxId, team, jobTitle, manager };
    }

    this.usersModel.updateUser({ userId, userInfo });
  }
}
