import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { BidVmService } from '@haulynx/services';
import { NgxCurrencyModule } from 'ngx-currency';
import { NgxMaskModule } from 'ngx-mask';
import { DropDownModule } from '../../../drop-down/drop-down.module';
import { SpinnerModule } from '../../../spinner/spinner.module';
import { BidItemV1Component } from './bid-item-v1.component';

@NgModule({
  declarations: [BidItemV1Component],
  imports: [
    CommonModule,
    MaterialModule,
    DropDownModule,
    NgxMaskModule.forRoot(),
    ReactiveFormsModule,
    SpinnerModule,
    PipesModule,
    NgxCurrencyModule,
  ],
  exports: [BidItemV1Component],
  providers: [BidVmService],
})
export class BidItemV1Module {}
