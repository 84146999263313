import { Injectable } from '@angular/core';
import { Carrier } from '@haulynx/types';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from '../../main-store/app.reducers';
import { isLoadingSelector } from '../load-feed/load-feed.selectors';
import { CarrierDetailsActions } from './carrier-details.actions';
import { stateSelector } from './carrier-details.selectors';

@Injectable({ providedIn: 'root' })
export class CarrierDetailsModel {
  state$: Observable<Carrier> = this.store.select(stateSelector);
  isLoading$: Observable<boolean> = this.store.select(isLoadingSelector);

  constructor(private store: Store<AppState>) {}

  setStateKey(data: { key: string }) {
    this.store.dispatch(CarrierDetailsActions.setStateKey(data));
  }

  get(data: { carrierId?: string; carrierDot?: string }) {
    this.store.dispatch(CarrierDetailsActions.get(data));
  }
}
