import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { CarrierInsuranceAndSafety, CarrierKpiMetrics, CarrierMetrics } from '@haulynx/types';

@Component({
  selector: 'app-carrier-analytics',
  templateUrl: './carrier-analytics.component.html',
  styleUrls: ['./carrier-analytics.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarrierAnalyticsComponent {
  @Input() kpiMetrics: CarrierKpiMetrics | any = null;
  @Input() carrierMetrics: CarrierMetrics | any = null;
  @Input() isLoading = false;
  @Input() insuranceAndSafety: CarrierInsuranceAndSafety | null;
  @Input() isLoadingInsuranceAndSafety: false;

  constructor() {}
}
