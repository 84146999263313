<div class="load_main_container">
  <div *ngIf="sectionTitle" class="load_main_container__title">
    {{ sectionTitle }}
    <button
      mat-button
      ngxClipboard
      [cbContent]="url"
      (cbOnSuccess)="copyLinkSuccess()"
      class="load_main_container__button"
    >
      <span class="copy_link_button"><mat-icon mat-list-icon>content_copy</mat-icon>Copy Link</span>
    </button>
  </div>

  <div class="load_main_info" *ngIf="load">
    <app-load-timeline
      class="load_main_info__timeline"
      [load]="load"
      [isBroker]="!!user?.broker"
      [user]="user"
      [showNotesButton]="showNotesButton && !!load.carrier"
      (showSpecialNote)="onShowSpecialNote($event)"
      (showFacilityInfo)="onShowFacilityInfo($event)"
    >
    </app-load-timeline>
    <div class="load_main_info__block" *ngIf="isCarrier" [ngClass]="{ editable_block: isEditable }">
      <div class="load_main_info__item">
        TMW #
        <br />
        <span class="content" data-test="tmw">
          {{ (load | loadAlternateId: loadIdentifierType.TMW_NUMBER) || 'N/A' }}
        </span>
      </div>
      <div class="load_main_info__item">
        Customer
        <br />
        <span class="content" data-test="customer">
          {{ load?.providerDetails?.billToName || (load?.locations | nth: 0 | get: 'customer.name':'N/A') }}
        </span>
      </div>
      <div class="load_main_info__item">
        Order #
        <br />
        <span class="content" data-test="order-number">
          {{ (load | loadAlternateId: loadIdentifierType.ORDER_NUMBER) || 'N/A' }}
        </span>
      </div>
      <div class="load_main_info__item">
        Equipment
        <br />
        <span class="content" data-test="equipment">
          {{ load?.providerDetails?.equipmentType || '' }}
        </span>
      </div>
      <div class="load_main_info__item">
        Driver
        <br />
        <span class="content" data-test="driver">
          {{ load | get: 'drivers[0].name':'N/A' }}
        </span>
      </div>
      <div class="load_main_info__item">
        HazMat
        <br />
        <span class="content" data-test="hazmat">
          {{ (load.restrictions | loadRestrictions: loadsServiceRestrictionTypes.HAZMAT) ? 'Yes' : 'No' }}
        </span>
      </div>
      <div class="load_main_info__item truck">
        <span>
          Truck
          <br />
          <span class="content" data-test="truck">
            {{ load.truck | truckUnitDisplayName: 'N/A' }}
          </span>
        </span>
        <mat-icon
          class="eld-icon"
          *ngIf="load.truck && load.truck.imei && load.truck.type !== 'phone'"
          data-test="eld-icon"
        ></mat-icon>
      </div>
      <div class="load_main_info__item">
        Price
        <br />
        <div class="currency content">
          <app-load-price
            [price]="load?.paymentDetails?.price"
            [loadId]="load.id"
            [editablePrice]="!!user?.broker"
            [showCents]="true"
            data-test="price"
          >
          </app-load-price>
        </div>
      </div>
      <div class="load_main_info__item">
        Weight
        <br />
        <span class="content" data-test="weight">
          {{ (load.providerDetails?.weight | safeNumber: '1.0-0') || 'N/A' }}
        </span>
      </div>
      <div class="load_main_info__item">
        Trailer
        <br />

        <span class="content" data-test="trailer-number">
          {{ load | get: 'trailers[0].trailerNumber':'N/A' }}
        </span>
      </div>

      <div class="load_main_info__item">
        Tanker Certification
        <br />
        <span class="content" data-test="tanker-cert">
          {{
            (load?.restrictions | loadRestrictions: loadsServiceRestrictionTypes.TANKER_CERTIFICATION_REQUIRED)
              ? 'Yes'
              : 'No'
          }}
        </span>
      </div>
      <div
        class="load_main_info__item"
        *ngIf="{
          pickupInstructions: load?.restrictions | loadRestrictions: loadsServiceRestrictionTypes.PICKUP_REQUIREMENTS,
          dropOffInstructions: load?.restrictions | loadRestrictions: loadsServiceRestrictionTypes.DROPOFF_REQUIREMENTS
        } as instructions"
      >
        Trailer Instructions
        <br />
        <span class="content" data-test="trailer-instructions">
          <div
            class="instruction-name"
            *ngIf="instructions.pickupInstructions || instructions.dropOffInstructions; else noTrailerInstructions"
          >
            {{ load?.restrictions | loadRestrictions: loadsServiceRestrictionTypes.PICKUP_REQUIREMENTS }} /
            {{ load?.restrictions | loadRestrictions: loadsServiceRestrictionTypes.DROPOFF_REQUIREMENTS }}
          </div>
          <ng-template #noTrailerInstructions> Not available </ng-template>
        </span>
      </div>

      <div *ngIf="showDetailsButton" class="load_main_info__item-full">
        <button
          mat-flat-button
          (click)="details(load)"
          class="load_main_info__details"
          data-test="additional-details-button"
        >
          ADDITIONAL DETAILS
        </button>
      </div>
    </div>

    <div class="load_main_info__block broker" *ngIf="!isCarrier" [ngClass]="{ editable_block: isEditable }">
      <div class="load_main_info__item">
        TMW #
        <br />
        <span class="content">
          {{ (load | loadAlternateId: loadIdentifierType.TMW_NUMBER) || 'N/A' }}
        </span>
      </div>
      <div class="load_main_info__item">
        Equipment
        <br />
        <span class="content">
          {{ load?.providerDetails?.equipmentType | fromCamelCase }}
        </span>
      </div>
      <div class="load_main_info__item">
        Order #
        <br />
        <span class="content">
          {{ (load | loadAlternateId: loadIdentifierType.ORDER_NUMBER) || 'N/A' }}
        </span>
      </div>
      <div class="load_main_info__item">
        Loaded Miles
        <br />
        <span class="content">
          <ng-container *ngIf="load?.paymentDetails?.distanceMiles | safeNumber; else noDistance">
            {{ load?.paymentDetails?.distanceMiles | safeNumber: '1.0-0' }}
          </ng-container>
          <ng-template #noDistance> Unknown </ng-template>
        </span>
      </div>
      <div class="load_main_info__item">
        Customer #
        <br />
        <span class="content">
          {{ load.locations | nth: 0 | get: 'customer.number':'N/A' }}
        </span>
      </div>
      <div class="load_main_info__item">
        Customer
        <br />
        <span class="content">
          {{ load.locations | nth: 0 | get: 'customer.name':'N/A' }}
        </span>
      </div>
      <div class="load_main_info__item">
        Price
        <br />
        <div class="currency content">
          <app-load-price
            [price]="load?.paymentDetails?.price"
            [currentMaxBuy]="maxBuy$ | async"
            [load]="load"
            [loadId]="load.id"
            [editablePrice]="!!user?.broker"
            [showCents]="true"
          >
          </app-load-price>
        </div>
      </div>
      <ng-container *appFeatures="maxBuyFeatureFlag">
        <div class="load_main_info__item">
          Max Buy
          <br />
          <div class="currency content">
            <app-load-price
              [price]="maxBuy$ | async"
              [load]="load"
              [loadId]="load.id"
              [isMaxBuy]="true"
              [editablePrice]="!!user?.broker"
              [showCents]="true"
              [maxBuyLoading]="maxBuyLoading$ | async"
            >
            </app-load-price>
          </div>
        </div>
      </ng-container>
      <div class="load_main_info__item">
        RPM
        <br />
        <span class="content">
          {{ load.paymentDetails?.ratePerMile | lessThan: 5 | showCurrency: 'USD':'symbol':'1.2-2' }}
        </span>
      </div>
      <div *ngIf="!hideFields.revenue" class="load_main_info__item">
        Revenue
        <br />
        <div class="currency content">
          <span>{{ load?.paymentDetails?.revenue | currency: 'USD':'symbol' }}</span>
        </div>
      </div>
      <!-- LOAD SERVICE LOAD FIELDS -->
      <ng-container *ngIf="loadServiceLoad$ | async as loadServiceLoad">
        <div class="load_main_info__item">
          HazMat
          <br />
          <span class="content">
            {{ (load?.restrictions | loadRestrictions: loadsServiceRestrictionTypes.HAZMAT) ? 'Yes' : 'No' }}
          </span>
        </div>
        <div class="load_main_info__item">
          Tanker Certification
          <br />
          <span class="content">
            {{
              (load?.restrictions | loadRestrictions: loadsServiceRestrictionTypes.TANKER_CERTIFICATION_REQUIRED)
                ? 'Yes'
                : 'No'
            }}
          </span>
        </div>
        <div
          class="load_main_info__item"
          *ngIf="{
            pickupInstructions:
              loadServiceLoad.restrictions | loadRestrictions: loadsServiceRestrictionTypes.PICKUP_REQUIREMENTS,
            dropOffInstructions:
              loadServiceLoad.restrictions | loadRestrictions: loadsServiceRestrictionTypes.DROPOFF_REQUIREMENTS
          } as instructions"
        >
          Trailer Instructions
          <br />
          <span class="content">
            <div
              class="instruction-name"
              *ngIf="instructions.pickupInstructions || instructions.dropOffInstructions; else noTrailerInstructions"
            >
              {{ loadServiceLoad.restrictions | loadRestrictions: loadsServiceRestrictionTypes.PICKUP_REQUIREMENTS }} /
              {{ loadServiceLoad.restrictions | loadRestrictions: loadsServiceRestrictionTypes.DROPOFF_REQUIREMENTS }}
            </div>
            <ng-template #noTrailerInstructions> Not available </ng-template>
          </span>
        </div>
        <div class="load_main_info__item">
          Broker Assignees
          <br />
          <span class="content">
            <haulynx-assigned-brokers
              [brokers]="loadServiceLoad.assignedBrokers"
              [maxCount]="1"
            ></haulynx-assigned-brokers>
          </span>
        </div>
      </ng-container>
      <!-- END LOAD SERVICE LOAD FIELDS -->
      <div *ngIf="!hideFields.bookStatus" class="load_main_info__item">
        Book Status
        <br />
        <div class="load_main_info__load-actions">
          <span class="content">
            {{ load?.bookStatus | titlecase }}
          </span>

          <button
            class="load_main_info__btn--update"
            [disabled]="load?.bookStatus === bookStatus.BOOKED"
            (click)="onUpdate(load.id, $event)"
          >
            <div *ngIf="!isLoading">
              <img class="refreshIcon" src="icons/other/haulynx-refresh-icon.svg" />
            </div>
            <mat-spinner *ngIf="isLoading" [diameter]="20"></mat-spinner>
          </button>
        </div>
        <div class="load_main_info__item"></div>
        <div *ngIf="showDetailsButton" class="load_main_info__item-full">
          <button
            mat-flat-button
            (click)="details(load)"
            class="load_main_info__details"
            [disabled]="disableDetailsButton"
          >
            ADDITIONAL DETAILS
          </button>
        </div>
      </div>
      <div class="load_main_info__item">
        Weight
        <br />
        <span class="content">
          {{ (load.providerDetails?.weight | safeNumber: '1.0-0') || 'N/A' }}
        </span>
      </div>
      <div class="load_main_info__item"></div>
      <div *ngIf="showDetailsButton" class="load_main_info__item-full">
        <button
          mat-flat-button
          (click)="details(load)"
          class="load_main_info__details"
          [disabled]="disableDetailsButton"
        >
          ADDITIONAL DETAILS
        </button>
      </div>
      <div *ngIf="isBroker" class="load_main_info__xpm">
        <button mat-flat-button class="load_main_info__xpm__button" (click)="onXpmOrder(load)" data-test="xpm-order">
          XPM
        </button>
      </div>
      <div class="load_main_info__item"></div>
      <div *ngIf="isBroker" class="load_main_info__notes">
        <form [formGroup]="form">
          <app-order-info-notes [form]="form" [readOnly]="true"></app-order-info-notes>
        </form>
      </div>
    </div>
  </div>
  <div class="icons-container">
    <mat-icon
      *ngIf="isCarrier && isLoadBooked"
      data-test="share-icon"
      [class.icon]="true"
      [class.lone]="!isEditable"
      svgIcon="haulynx-share"
      (click)="shareLocation(load)"
    ></mat-icon>
    <mat-icon *ngIf="isEditable" data-test="edit-icon" class="icon" svgIcon="haulynx-pen" (click)="editLoad(load)">
    </mat-icon>
  </div>
</div>
