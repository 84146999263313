<mat-form-field class="drop-down" [floatLabel]="floatLabel">
  <input
    matInput
    [formControl]="control"
    [matAutocomplete]="auto"
    (blur)="inputBlur($event)"
    placeholder="{{ placeholder }}"
    type="text"
  />
  <mat-autocomplete
    autoActiveFirstOption
    #auto="matAutocomplete"
    (optionSelected)="select($event)"
    [displayWith]="displayFn()"
  >
    <mat-option *ngFor="let item of filteredData | async" [value]="item">
      {{ item[displayLabel] }}
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="control.errors?.mustSelected">
    Must choose an option from the dropdown, or leave it blank.
  </mat-error>
</mat-form-field>
