import { Injectable } from '@angular/core';
import { CarriersService, FeatureFlagDashboardService, NotificationsService, UsersService } from '@haulynx/services';
import { Carrier, FeatureFlagDetails, FeatureFlagDetailsInput, FeatureFlags } from '@haulynx/types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { get, nth } from 'lodash';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { DispatchAction } from '../../shared/store/helpers/action';
import { FeatureFlagDashboardActions, FeatureFlagDashboardActionTypes } from './feature-flag-dashboard.actions';

@Injectable()
export class FeatureFlagDashboardEffects {
  getFeatureFlags = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureFlagDashboardActionTypes.FIND_ALL),
      map((action: DispatchAction) => action.payload),
      switchMap(() =>
        this.featureFlagDashboardService.getFeatureFlags().pipe(
          map((data: FeatureFlags[]) => FeatureFlagDashboardActions.findAllSuccess({ entities: data })),
          catchError((errors) => {
            this.notificationsService.error('Error to get feature flag details', 'Feature flag dashboard');
            return of(FeatureFlagDashboardActions.findAllError({ errors }));
          })
        )
      )
    )
  );

  getFeatureFlagDetails = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureFlagDashboardActionTypes.FIND),
      map((action: DispatchAction) => action.payload),
      switchMap((payload?: { featureFlagName?: string }) => {
        const featureFlagName = get(payload, 'featureFlagName', null);

        return this.featureFlagDashboardService.getFeatureFlagDetails(featureFlagName).pipe(
          map((data: FeatureFlagDetails[]) => {
            if (featureFlagName) {
              return FeatureFlagDashboardActions.findByNameSuccess({ entity: nth(data, 0) });
            }

            return FeatureFlagDashboardActions.findSuccess({ entities: data });
          }),
          catchError((errors) => {
            this.notificationsService.error('Error to get feature flag details', 'Feature flag dashboard');
            return of(FeatureFlagDashboardActions.findError({ errors }));
          })
        );
      })
    )
  );

  deleteFeatureFlagDetails = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureFlagDashboardActionTypes.DELETE),
      map((action: DispatchAction) => action.payload),
      switchMap((payload: { featureFlagName?: string }) => {
        const { featureFlagName = null } = payload;

        return this.featureFlagDashboardService.deleteFeatureFlagDetails(featureFlagName).pipe(
          map(() => FeatureFlagDashboardActions.findAll()),
          catchError((errors) => {
            this.notificationsService.error('Error to delete feature flag details', 'Feature flag dashboard');
            return of(FeatureFlagDashboardActions.deleteError({ errors }));
          })
        );
      })
    )
  );

  getUsersByEmail = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureFlagDashboardActionTypes.FIND_USERS),
      map((action: DispatchAction) => action.payload),
      switchMap((payload: { email?: string; index: number }) => {
        const { email = null, index = null } = payload;

        return this.usersService.getUsersByEmail({ email }).pipe(
          map(({ entities }) => FeatureFlagDashboardActions.findUsersSuccess({ entities, index })),
          catchError((errors) => {
            this.notificationsService.error('Error to get users', 'Feature flag dashboard');
            return of(FeatureFlagDashboardActions.findUsersError({ errors }));
          })
        );
      })
    )
  );

  getCarriersByNameOrDot = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureFlagDashboardActionTypes.FIND_CARRIERS),
      map((action: DispatchAction) => action.payload),
      switchMap((payload: { name?: string; index: number }) => {
        const { name = null, index = null } = payload;

        return this.carriersService.search({ search: name, paging: { limit: 50 } }).pipe(
          map(({ entities }: { entities: Partial<Carrier>[] }) =>
            FeatureFlagDashboardActions.findCarriersSuccess({ entities, index })
          ),
          catchError((errors) => {
            this.notificationsService.error('Error to get carriers', 'Feature flag dashboard');
            return of(FeatureFlagDashboardActions.findCarriersError({ errors }));
          })
        );
      })
    )
  );

  createFeatureFlagDetails = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureFlagDashboardActionTypes.CREATE),
      map((action: DispatchAction) => action.payload),
      switchMap((payload: { featureFlag: FeatureFlagDetailsInput }) => {
        const { featureFlag } = payload;

        return this.featureFlagDashboardService.createFeatureFlagDetails(featureFlag).pipe(
          map(() => FeatureFlagDashboardActions.findAll()),
          catchError((errors) => {
            this.notificationsService.error(errors.message, 'Feature flag dashboard');
            return of(FeatureFlagDashboardActions.createError({ errors }));
          })
        );
      })
    )
  );

  updateFeatureFlagDetails = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureFlagDashboardActionTypes.UPDATE),
      map((action: DispatchAction) => action.payload),
      switchMap((payload: { featureFlagName: string; featureFlag: FeatureFlagDetailsInput }) => {
        const { featureFlagName, featureFlag } = payload;

        return this.featureFlagDashboardService.updateFeatureFlagDetails(featureFlagName, featureFlag).pipe(
          map(() => FeatureFlagDashboardActions.findAll()),
          catchError((errors) => {
            this.notificationsService.error(errors.message, 'Feature flag dashboard');
            return of(FeatureFlagDashboardActions.updateError({ errors }));
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private notificationsService: NotificationsService,
    private featureFlagDashboardService: FeatureFlagDashboardService,
    private usersService: UsersService,
    private carriersService: CarriersService
  ) {}
}
