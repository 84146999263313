import { ChangeDetectionStrategy, Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { KeyHintValidation, KeyValuePair } from '@haulynx/types';
import { ControlValueAccessorConnectorComponent } from '@haulynx/utils';

@Component({
  selector: 'haulynx-blue-select',
  templateUrl: './blue-select.component.html',
  styleUrls: ['./blue-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: BlueSelectComponent,
      multi: true,
    },
  ],
})
export class BlueSelectComponent extends ControlValueAccessorConnectorComponent {
  @Input() label = 'Label';
  @Input() required = false;
  @Input() options: KeyValuePair[] | KeyHintValidation[];
  @Input() key = 'key';
  @Input() value = 'value';
  @Output() optionSelected = new EventEmitter();

  constructor(injector: Injector) {
    super(injector);
  }

  onOptionSelected() {
    this.optionSelected.emit();
  }
}
