<main class="container shipperInfo">
  <div class="row">
    <div class="col l12">
      <p><b>Finding A Carrier To Safely Deliver Your Load Is Easier Than Ever </b><br /></p>
      <p>
        The Haulynx network makes finding a Carrier to deliver your load safely and on time easier than it has ever
        been. You simply create a set of requirements for the Carriers you want to do business with. Your requirements
        can include their safety rating, insurance, and other factors. Then you enter the information regarding the load
        and click a button. Your load is then offered to a Carrier who meets your requirements. The Carrier is chosen
        based on a predictive algorithm that takes into account the location of the individual unit, the equipment (e.g.
        flatbed or reefer), and the individual driver’s hours of service availability. Once your load is accepted, and
        the chosen Carrier is identified, you simply click one more button to confirm the transaction. If the chosen
        Carrier is unacceptable despite the fact that it meets your specified requirements, then you can reject the
        Carrier and send the load through our network to find a different Carrier.
      </p>
      <p>
        <b>Real Time Tracking Provides You With The Status Of Your Load </b>
      </p>
      <p>
        Once a Carrier is assigned to your load, you can follow it every step of the way via the Haulynx web
        application. Our map provides the location and status of your load in real time throughout the entire process.
        When the load is delivered you will receive the appropriate confirmation paperwork immediately.
      </p>

      <p>
        <b>Transparent Connections Lead To Greater Efficiency</b>
      </p>
      <p>
        By creating transparent connections between Shippers and Carriers, Haulynx takes the guesswork out the process.
        You connect directly with the Carrier, and you have the ability to track your load throughout the process. In
        addition, Haulynx predictive analytics software can tell you not only where your load is now, but where it will
        be in the future. This allows you to operate at peak efficiency.
      </p>
    </div>
  </div>
</main>
