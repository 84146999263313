import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@haulynx/directives';
import { MaterialModule } from '@haulynx/modules';
import { AssignLoadCarrierFormVm } from '@haulynx/services';
import { DropDownModule } from '../drop-down/drop-down.module';
import { LoadDispatchFormModule } from '../load-dispatch-form/load-dispatch-form.module';
import { AddDriverModule } from '../pay-line-items/add-driver/add-driver.module';
import { AddPaymentTypeModule } from '../pay-line-items/add-payment-type/add-payment-type.module';
import { AddTrailerModule } from '../pay-line-items/add-trailer/add-trailer.module';
import { AddTruckModule } from '../pay-line-items/add-truck/add-truck.module';
import { CarrierInfoSectionModule } from '../pay-line-items/carrier-info-section/carrier-info-section.module';
import { ChipSelectorModule } from '../pay-line-items/chip-selector/chip-selector.module';
import { ConfirmBookLoadModule } from '../pay-line-items/confirm-book-load/confirm-book-load.module';
import { OrderInfoNotesModule } from '../pay-line-items/order-info-notes/order-info-notes.module';
import { OrderInfoTableModule } from '../pay-line-items/order-info-table/order-info-table.module';
import { PayLinePriceModule } from '../pay-line-items/pay-line-price/pay-line-price.module';
import { PaymentDetailsModule } from '../pay-line-items/payment-details/payment-details.module';
import { TextAreaHandlerModule } from '../text-area-handler/text-area-handler.module';
import { AssignLoadCarrierFormComponent } from './assign-load-carrier-form.component';

@NgModule({
  declarations: [AssignLoadCarrierFormComponent],
  exports: [AssignLoadCarrierFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DropDownModule,
    OrderInfoTableModule,
    CarrierInfoSectionModule,
    PaymentDetailsModule,
    OrderInfoNotesModule,
    LoadDispatchFormModule,
    AddPaymentTypeModule,
    PayLinePriceModule,
    AddDriverModule,
    AddTruckModule,
    AddTrailerModule,
    ConfirmBookLoadModule,
    TextAreaHandlerModule,
    MaterialModule,
    ChipSelectorModule,
    DirectivesModule,
  ],
  providers: [AssignLoadCarrierFormVm],
})
export class AssignLoadCarrierFormModule {}
