import { Environment } from '@haulynx/types';
// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment: Environment = {
  production: false,
  websocketUrl: 'wss://ws-gateway-dev.haulynx.io/graphql',
  apiUrl: 'https://web-api-dev.haulynx.io',
  envName: 'development',
  mapbox: {
    accessToken: 'pk.eyJ1IjoiaGF1bHlueCIsImEiOiJja3ZzbjF5cncwZXEzMm9rMzZpZWljcHNiIn0.8gadio7KSky9XDModIazTw',
    directionsApi: 'https://api.mapbox.com/directions/v5/mapbox/driving',
    drivingDirectionsApi: 'https://api.mapbox.com/directions/v5/mapbox/driving',
    timezoneApi: 'https://api.mapbox.com/v4/examples.4ze9z6tv/tilequery',
    geocoding: {
      accessToken: 'pk.eyJ1IjoiaGF1bHlueCIsImEiOiJja3ZzbjF5cncwZXEzMm9rMzZpZWljcHNiIn0.8gadio7KSky9XDModIazTw',
      geocodingApi: 'https://api.mapbox.com/geocoding/v5/mapbox.places',
    },
  },
  firebase: {
    apiKey: 'AIzaSyBEV_0HxGwt2kToshJhYtTIJvJ0DOGjhxg',
    authDomain: 'haulynx-elog-app.firebaseapp.com',
    databaseURL: 'https://haulynx-elog-app-dev.firebaseio.com',
    projectId: 'haulynx-elog-app',
    storageBucket: 'haulynx-elog-app.appspot.com',
    messagingSenderId: '722208934797',
    appId: '1:722208934797:web:90c3bd917c3a003b48cbc4',
    measurementId: 'G-1R6NZEPPRD',
  },
  apollo: {
    url: '/api/graphql',
  },
  amplitude: {
    apiKey: '1a7d60c7eddf3d5c880648c2f688ba6f',
  },
  usx: {
    creatorId: 'eaRepWJ665bkbwbTMzhadxC5jPC3',
  },
  google: {
    mapsApi: 'AIzaSyAH2ixL6EHFOK9UF3FPYUTXVXgRdZ6iDD0',
  },
  elastic: {
    apmServerUrl: 'https://b512476d717b4298af9850d87c6f8f53.apm.us-west-2.aws.cloud.es.io/',
  },
  documents: {
    documentServerUrl: 'https://gateway-dev.haulynx.io',
  },
};
