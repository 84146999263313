import {
  Carrier,
  LoadLocationType,
  LoadsServiceLoad,
  LoadsServiceLoadLocation,
  LoadsServiceLoadStatus,
  loadStatus,
  LoadStatusIconLabel,
  LoadStatusType,
  LoadStep,
  loadStepType,
  LoadStepType,
  loadStepTypeStatus,
  User,
} from '@haulynx/types';
import { find, get, isArray, last, map, reduce } from 'lodash';

export function transformLoadStatus(
  load: LoadsServiceLoad,
  statusType: LoadStatusType = LoadStatusType.TEXT,
  stepTypes: Array<LoadStepType[keyof LoadStepType]> = []
): string {
  const steps = buildLoadSteps(load, stepTypes);
  const mapStepStatusLabel = getMapLabelStatus(steps);

  if (statusType === LoadStatusType.ICON) {
    return mapStepStatusLabel.icon;
  }

  return mapStepStatusLabel.label;
}

const mapTypeStatus = {
  [loadStepType.ACCEPTED]: (load: LoadsServiceLoad | LoadsServiceLoadLocation, carrier?: Carrier) =>
    isAcceptedBy(load, carrier) ? loadStepTypeStatus.COMPLETED : loadStepTypeStatus.INACTIVE,
  [loadStepType.ASSIGNED]: (load: LoadsServiceLoad) =>
    isAssigned(load) ? loadStepTypeStatus.COMPLETED : loadStepTypeStatus.INACTIVE,
  [loadStepType.DISPATCHED]: (load: LoadsServiceLoad) =>
    hasDispatched(load)
      ? loadStepTypeStatus.COMPLETED
      : get(load, 'loadLocations', []).length
      ? loadStepTypeStatus.SKIPPED
      : loadStepTypeStatus.COMPLETED,
  [loadStepType.PICKUP]: (loadLocation: LoadsServiceLoadLocation) => {
    if (hasArrivedStop(loadLocation) && !hasLeftStop(loadLocation)) {
      return loadStepTypeStatus.PARTIAL_COMPLETED;
    }

    if (hasArrivedStop(loadLocation) && hasLeftStop(loadLocation)) {
      return loadStepTypeStatus.COMPLETED;
    }

    return loadStepTypeStatus.INACTIVE;
  },
  [loadStepType.PICKUP_STOP]: (loadLocation: LoadsServiceLoadLocation) => {
    if (hasArrivedStop(loadLocation) && !hasLeftStop(loadLocation)) {
      return loadStepTypeStatus.PARTIAL_COMPLETED;
    }

    if (hasArrivedStop(loadLocation) && hasLeftStop(loadLocation)) {
      return loadStepTypeStatus.COMPLETED;
    }

    return loadStepTypeStatus.INACTIVE;
  },
  [loadStepType.DROPOFF]: (loadLocation: LoadsServiceLoadLocation) => {
    if (hasArrivedStop(loadLocation) && !hasLeftStop(loadLocation)) {
      return loadStepTypeStatus.PARTIAL_COMPLETED;
    }

    if (hasArrivedStop(loadLocation) && hasLeftStop(loadLocation)) {
      return loadStepTypeStatus.COMPLETED;
    }

    return loadStepTypeStatus.INACTIVE;
  },
  [loadStepType.DROPOFF_STOP]: (loadLocation: LoadsServiceLoadLocation) => {
    if (hasArrivedStop(loadLocation) && !hasLeftStop(loadLocation)) {
      return loadStepTypeStatus.PARTIAL_COMPLETED;
    }

    if (hasArrivedStop(loadLocation) && hasLeftStop(loadLocation)) {
      return loadStepTypeStatus.COMPLETED;
    }

    return loadStepTypeStatus.INACTIVE;
  },
};

export function buildLoadSteps(
  load: LoadsServiceLoad,
  stepTypes: Array<LoadStepType[keyof LoadStepType]> = [],
  user?: User
): LoadStep[] {
  const accumulator = map(stepTypes, (step, index) => {
    const status = mapTypeStatus[step](load, user);

    return {
      id: step,
      data: load,
      type: step,
      status: status,
    };
  });

  const locationSteps = buildLoadLocationSteps(load.locations);

  return [...accumulator, ...locationSteps];
}

export function buildLoadLocationSteps(loadLocations: LoadsServiceLoadLocation[] = [], user?: User): LoadStep[] {
  return map(loadLocations, (location, index) => {
    if (index === 0) {
      return {
        id: index,
        type: loadStepType.PICKUP,
        status: mapTypeStatus[loadStepType.PICKUP](location, user),
        data: location,
      };
    }

    if (index === loadLocations.length - 1) {
      return {
        id: index,
        type: loadStepType.DROPOFF,
        status: mapTypeStatus[loadStepType.DROPOFF](location),
        data: location,
      };
    }

    const stepType =
      location.locationType === LoadLocationType.PICKUP ? loadStepType.PICKUP_STOP : loadStepType.DROPOFF_STOP;
    return {
      id: index,
      type: stepType,
      status: mapTypeStatus[stepType](location),
      data: location,
    };
  });
}

export function getMapLabelStatus(loadSteps: LoadStep[] = []): LoadStatusIconLabel {
  const { active, completed } = getNextSteps(loadSteps);
  const dispatchStep = loadSteps.find((step) => step.type === loadStepType.DISPATCHED);
  let status = null;

  if (!active) {
    status = loadStatus.DELIVERED;
  } else if (isAssignedStop(active) && !isStepCompleted(active)) {
    status = loadStatus.BOOKED;
  } else if (
    isAssignedStop(completed) &&
    isPickUpStop(active) &&
    dispatchStep.status === loadStepTypeStatus.SKIPPED &&
    active.status === loadStepTypeStatus.INACTIVE
  ) {
    status = loadStatus.ASSIGNED;
  } else if (isPickUpStop(active) && isStepCompleted(dispatchStep) && active.status === loadStepTypeStatus.INACTIVE) {
    status = loadStatus.DISPATCHED;
  } else if (isPickUpStop(active) && active.status === loadStepTypeStatus.PARTIAL_COMPLETED) {
    status = loadStatus.AT_SHIPPER;
  } else if (isDropOffStop(active) && active.status === loadStepTypeStatus.PARTIAL_COMPLETED) {
    status = loadStatus.AT_RECEIVER;
  } else if (isDropOffStop(active) && isStepCompleted(active)) {
    status = loadStatus.DELIVERED;
  } else if (isStepStop(active) && active.status === loadStepTypeStatus.PARTIAL_COMPLETED) {
    status = loadStatus.AT_STOP;
  } else if (
    (isStepStop(active) || isPickUpStop(active) || isDropOffStop(active)) &&
    active.status === loadStepTypeStatus.INACTIVE
  ) {
    status = loadStatus.IN_TRANSIT;
  }

  return status;
}

export function getSkippedStep(steps: LoadStep[]): LoadStep {
  return find(steps, (step) => step.status === loadStepTypeStatus.SKIPPED);
}

export function getNextInactiveStep(steps: LoadStep[]): LoadStep {
  return find(
    steps,
    (step) => step.status === loadStepTypeStatus.INACTIVE || step.status === loadStepTypeStatus.PARTIAL_COMPLETED
  );
}

export function getNextSteps(
  steps: LoadStep[]
): {
  completed: LoadStep;
  skipp: LoadStep;
  active: LoadStep;
} {
  return reduce(
    steps,
    ({ completed, active, skipp }, loadStep) => {
      if (!active && loadStep.status === loadStepTypeStatus.COMPLETED) {
        completed = loadStep;
      } else if (!active && loadStep.status === loadStepTypeStatus.SKIPPED) {
        skipp = loadStep;
      } else if (
        !active &&
        (loadStep.status === loadStepTypeStatus.INACTIVE || loadStep.status === loadStepTypeStatus.PARTIAL_COMPLETED)
      ) {
        active = loadStep;
      }

      return {
        completed,
        active,
        skipp,
      };
    },
    {
      completed: null,
      skipp: null,
      active: null,
    }
  );
}

export function isStepCompleted(step: LoadStep): boolean {
  return !!(step && step.status === loadStepTypeStatus.COMPLETED);
}

export function isStepStop(step: LoadStep): boolean {
  return !!(step.type === loadStepType.DROPOFF_STOP || step.type === loadStepType.PICKUP_STOP);
}

export function isDropOffStop(step: LoadStep): boolean {
  return step && step.type === loadStepType.DROPOFF;
}

export function isPickUpStop(step: LoadStep): boolean {
  return step && step.type === loadStepType.PICKUP;
}

export function isAssignedStop(step: LoadStep): boolean {
  return step && step.type === loadStepType.ASSIGNED;
}

export function isCorrectLoadLocation(load: LoadsServiceLoad): boolean {
  return !!load.locations && isArray(load.locations);
}

export function hasDelivered(loadLocation: LoadsServiceLoadLocation): boolean {
  return !!hasStopCompleted(loadLocation);
}

export function hasPickUp(loadLocation: LoadsServiceLoadLocation): boolean {
  return !!hasStopCompleted(loadLocation);
}

export function hasCompletedLastLocation(load: LoadsServiceLoad): boolean {
  const location = last(get(load, 'locations', []));
  return !!location && hasStopCompleted(location);
}

export function hasCompletedFirstPickedUp(load: LoadsServiceLoad) {
  const loadLocation = find(
    get(load, 'locations', []),
    (location: LoadsServiceLoadLocation) =>
      hasStopCompleted(location) && location.locationType === LoadLocationType.PICKUP
  );

  return !!loadLocation;
}

export function hasLeftStop(loadLocation: LoadsServiceLoadLocation): boolean {
  return loadLocation && !!loadLocation.carrierDeparture;
}

export function hasArrivedStop(loadLocation: LoadsServiceLoadLocation) {
  return loadLocation && !!(loadLocation.carrierArrival || loadLocation.carrierDeparture);
}

export function hasStopCompleted(loadLocation: LoadsServiceLoadLocation) {
  return loadLocation && !!(loadLocation.carrierArrival || loadLocation.carrierDeparture);
}

export function hasDispatched(load: LoadsServiceLoad) {
  return load.loadStatus === LoadsServiceLoadStatus.DISPATCHED;
}

export function hasClosed(load: LoadsServiceLoad): boolean {
  return !!(
    load &&
    (load.loadStatus === LoadsServiceLoadStatus.FINALLED || load.loadStatus === LoadsServiceLoadStatus.DELETED)
  );
}

export function isAcceptedBy(load, carrier) {
  const loadCarrierDot = get(load, 'carrier.dot', null);
  const carrierDot = get(carrier, 'dot', null);

  return loadCarrierDot && carrierDot && loadCarrierDot === carrierDot;
}

export function isAssigned(load: LoadsServiceLoad): boolean {
  return load.loadStatus != LoadsServiceLoadStatus.UNASSIGNED;
}

export function getMapButtonLabel() {
  return {
    [loadStepType.CLOSED]: 'Closed',
    [loadStepType.ACCEPTED]: 'ACCEPT',
    [loadStepType.DISPATCHED]: 'Dispatch',
    [loadStepType.ASSIGNED]: 'Assign',
    [loadStepType.PICKUP]: 'Pickup',
    [loadStepType.PICKUP_STOP]: 'Pickup',
    [loadStepType.DROPOFF_STOP]: 'Drop-off',
    [loadStepType.DROPOFF]: 'Deliver',
  };
}
