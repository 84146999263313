import gql from 'graphql-tag';

export const GetBidStatuses = gql`
  query GetBidStatuses {
    getBidStatuses {
      name
      value
      description
    }
  }
`;
