import { createSelector } from '@ngrx/store';
import { loadDetailsAdapter } from './adapters';
import { getLoadDetailsState } from './load-details.state';

export const loadDetailsSelector = loadDetailsAdapter.getSelectors(getLoadDetailsState);

export const routesSelector = createSelector(getLoadDetailsState, (state) => state.routes);

export const isLoadingRoutesSelector = createSelector(getLoadDetailsState, (state) => state.isLoadingRoutes);

export const deviceLocationsSelector = createSelector(getLoadDetailsState, (state) => state.deviceLocations);

export const activeStepIdSelector = createSelector(
  getLoadDetailsState,
  loadDetailsSelector.getState,
  (state, load) => load && state.activeStep[load.id]
);

export const isLoadingCreateEntitySelector = createSelector(
  getLoadDetailsState,
  (state) => state.isLoadingCreateEntity
);

export const driverToPopulateSelector = createSelector(getLoadDetailsState, (state) => state.driverToPopulate);

export const truckToPopulateSelector = createSelector(getLoadDetailsState, (state) => state.truckToPopulate);

export const trailerToPopulateSelector = createSelector(getLoadDetailsState, (state) => state.trailerToPopulate);
