<div class="lane-search-container" *ngrxLet="searchQuery$ as searchQuery">
  <div class="search-column">
    <app-advanced-search
      class="searchbar-container"
      [placeholder]="'Search Lanes by Location, Customer, etc.'"
      [filters]="filters"
      [setFilters]="searchBarFilters$ | async"
      [recentSearches]="[]"
      [allowZipSearch]="true"
      [displayRecentSearches]="false"
      [externalDateSearch]="true"
      [timeZone]="appModel.userTimeZone$ | async"
      (submitSearch)="onSubmitSearch($event, searchQuery?.payload)"
    >
    </app-advanced-search>

    <div class="data-table" #parent>
      <data-table-v2
        [data]="tableData$ | async"
        [tableConfig]="configOptions"
        [emptyMessage]="'No Lanes Found'"
        [displayedColumns]="columns"
        [templateCellRef]="templateRef"
        [parentToTable]="parent"
        [loading]="dataLoading$ | async"
        [entitiesLoading]="laneRateEntityService.bulkUpdateLanesManager.isLoadingEntities$ | async"
        [selectionMode]="null"
        [stickyColumnWidth]="stickyColumnWidth"
        [stickyColumnCount]="4"
        [paginationData]="laneRateEntityService.searchManager.searchPaginationResults$ | async"
        (bulkAction)="onBulkAction($event)"
        (onSelect)="onRowSelect($event)"
        (page)="onPage($event)"
        (sort)="onSort($event, searchQuery)"
        (toggleChange)="onToggleChange($event)"
      >
        <form [formGroup]="form">
          <div formArrayName="tableRowArray">
            <ng-template
              #templateRef
              let-row="row"
              let-column="column"
              let-hovering="isHovering"
              let-width="columnWidth"
              let-index="rowIndex"
              let-count="rowCount"
            >
              <td
                class="origin-destination-container"
                [ngStyle]="{ width: width }"
                *ngIf="column.field === 'originAndDestination'"
              >
                <app-lane-rate-tool-timeline [lane]="row"></app-lane-rate-tool-timeline>
              </td>

              <td class="miles-container" [ngStyle]="{ width: width }" *ngIf="column.field === 'miles'">
                {{ row.miles }}
              </td>

              <td class="allInRpm-container" [ngStyle]="{ width: width }" *ngIf="column.field === 'mlAllInRpm'">
                {{ (row.mlPredicted?.allInRpm ? row.mlPredicted?.allInRpm : 0) | currency }}
              </td>

              <td class="costRpm-container" [ngStyle]="{ width: width }" *ngIf="column.field === 'mlCostRpm'">
                {{ row.mlPredicted?.costRpm | currency }}
              </td>

              <td
                class="laneVolatility-container"
                [ngStyle]="{ width: width }"
                *ngIf="column.field === 'laneVolatility'"
              >
                <span
                  [ngClass]="{
                    'low-volatility': laneVolatility.LOW === row.mlPredicted?.laneVolatility,
                    'medium-volatility': laneVolatility.MEDIUM === row.mlPredicted?.laneVolatility,
                    'high-volatility': laneVolatility.HIGH === row.mlPredicted?.laneVolatility
                  }"
                  >{{ row.mlPredicted?.laneVolatility | laneVolatility }}</span
                >
              </td>

              <td class="avgBid-container" [ngStyle]="{ width: width }" *ngIf="column.field === 'avgBid'">
                <div class="rate">
                  {{ row.historical?.averageBidRate | currency }}({{ row.historical?.averageBidAmount | currency }})
                </div>
                <div class="range">
                  {{ row.historical?.bidLow | currency }} - {{ row.historical?.bidHigh | currency }}
                </div>
              </td>

              <td class="avgWon-container" [ngStyle]="{ width: width }" *ngIf="column.field === 'avgWon'">
                <div class="rate">
                  {{ row.historical?.averageWonRate | currency }}({{ row.historical?.averageWonAmount | currency }})
                </div>
                <div class="range">
                  {{ row.historical?.wonLow | currency }} - {{ row.historical?.wonHigh | currency }}
                </div>
              </td>

              <td class="avgCost-container" [ngStyle]="{ width: width }" *ngIf="column.field === 'avgCost'">
                <div class="rate">
                  {{ row.costDetails?.averageCostRate | currency }}({{ row.costDetails?.averageCostAmount | currency }})
                </div>
                <div class="range">
                  {{ row.costDetails?.minCost | currency }} - {{ row.costDetails?.maxCost | currency }}
                </div>
              </td>

              <td class="margin-container" [ngStyle]="{ width: width }" *ngIf="column.field === 'margin'">
                <div class="rate">
                  {{ row.historical?.averageMarginAmount | currency }}
                </div>
                <div class="range">
                  {{ row.historical?.marginLow | currency }} - {{ row.historical?.marginHigh | currency }}
                </div>
              </td>

              <td class="datRate-container" [ngStyle]="{ width: width }" *ngIf="column.field === 'datRate'">
                <div class="rate">
                  {{ row.historical?.datRate | currency }}
                </div>
                <div class="range">
                  {{ row.historical?.datAmount | currency }}
                </div>
              </td>

              <td class="recentCost-container" [ngStyle]="{ width: width }" *ngIf="column.field === 'recentCost'">
                <div class="rate">
                  {{ row.costDetails?.recentCostRate | currency }}
                </div>
                <div class="range">
                  {{ row.costDetails?.recentCostAmount | currency }}
                </div>
              </td>

              <td class="loadCount-container" [ngStyle]="{ width: width }" *ngIf="column.field === 'loadCount'">
                {{ row.laneDetails?.count }}
              </td>

              <td class="bidSinceWon-container" [ngStyle]="{ width: width }" *ngIf="column.field === 'bidSinceWon'">
                {{ row.laneDetails?.bidSinceWon }}
              </td>

              <td
                class="oneWeekAttainment-container"
                [ngStyle]="{ width: width }"
                *ngIf="column.field === 'oneWeekAttainment'"
              >
                {{ row.laneDetails?.oneWeekAttainment | number: '1.0-2' }}%
              </td>

              <td class="customer-container" [ngStyle]="{ width: width }" *ngIf="column.field === 'customer'">
                {{ row.laneDetails?.customer ? row.laneDetails?.customer : 'N/A' }}
              </td>

              <td class="equipment-container" [ngStyle]="{ width: width }" *ngIf="column.field === 'equipment'">
                <div class="equipment-name">
                  {{ row.loadContents?.equipmentType | fromCamelCase }}
                </div>
                <div
                  *ngIf="row.loadContents?.commodity != 'N/A' || row.loadContents?.weight != 'N/A'"
                  class="commodity-name"
                >
                  {{ row.loadContents?.commodity }} | {{ row.loadContents?.weight }} lbs
                </div>
              </td>

              <td
                [formGroupName]="index"
                [ngStyle]="{ width: width }"
                *ngIf="column.field === 'allInRpmEdit' && showRPM"
              >
                <mat-form-field class="app-form-field" appearance="outline">
                  <input
                    matInput
                    currencyMask
                    [options]="{ thousands: ',', decimal: '.' }"
                    formControlName="allInRpmEdit"
                  />
                </mat-form-field>
              </td>

              <td
                [formGroupName]="index"
                [ngStyle]="{ width: width }"
                *ngIf="column.field === 'costRpmEdit' && showRPM"
              >
                <mat-form-field class="app-form-field" appearance="outline">
                  <input
                    matInput
                    currencyMask
                    [options]="{ thousands: ',', decimal: '.' }"
                    formControlName="costRpmEdit"
                  />
                </mat-form-field>
              </td>

              <td
                [formGroupName]="index"
                [ngStyle]="{ width: width }"
                *ngIf="column.field === 'allInRpmEdit' && !showRPM"
              >
                <mat-form-field class="app-form-field" appearance="outline">
                  <input
                    matInput
                    currencyMask
                    [options]="{ thousands: ',', decimal: '.' }"
                    formControlName="allInEdit"
                  />
                </mat-form-field>
              </td>

              <td
                [formGroupName]="index"
                [ngStyle]="{ width: width }"
                *ngIf="column.field === 'costRpmEdit' && !showRPM"
              >
                <mat-form-field class="app-form-field" appearance="outline">
                  <input
                    matInput
                    currencyMask
                    [options]="{ thousands: ',', decimal: '.' }"
                    formControlName="costEdit"
                  />
                </mat-form-field>
              </td>

              <td [formGroupName]="index" [ngStyle]="{ width: width }" *ngIf="column.field === 'save'">
                <button class="save-lane-form" (click)="saveLaneForm(row, index)">
                  <mat-spinner
                    *ngIf="savingForm && index === savedIndex; else saveDisplay"
                    class="save-spinner"
                    [diameter]="20"
                    [strokeWidth]="3"
                  ></mat-spinner>
                  <ng-template #saveDisplay> <span>Save</span> </ng-template>
                </button>
              </td>
            </ng-template>
          </div>
        </form>
      </data-table-v2>
    </div>
  </div>
</div>
