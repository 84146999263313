import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@haulynx/modules';
import { CarrierInfoSectionVmService, LoadQuickDriverFormVm } from '@haulynx/services';
import { NgxMaskModule } from 'ngx-mask';
import { DropDownModule } from '../drop-down/drop-down.module';
import { LoadTimelineModule } from '../load-timeline/load-timeline.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { LoadQuickDriverFormComponent } from './load-quick-driver-form.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DropDownModule,
    LoadTimelineModule,
    SpinnerModule,
    NgxMaskModule.forRoot(),
    MaterialModule,
  ],
  declarations: [LoadQuickDriverFormComponent],
  exports: [LoadQuickDriverFormComponent],
  providers: [LoadQuickDriverFormVm, CarrierInfoSectionVmService],
})
export class LoadQuickDriverFormModule {}
