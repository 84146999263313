<div class="app-chips-list">
  <div
    class="app-chips-list__item"
    *ngFor="let item of data"
    (click)="execute(item.value)"
    [ngClass]="{ active: select === item.value }"
  >
    {{ item.label }}
  </div>
</div>
