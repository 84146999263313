import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  AppModel,
  CarrierDetailsModel,
  CommonEntities,
  LoadDetailsModel,
  LoadEntityService,
  LoadFeedModel,
  PaymentTypeStateModel,
  UserEntityService,
} from '@haulynx/store';
import {
  AssignLoadCarrierData,
  AssignLoadCarrierForm,
  AssignLoadCarrierFormMode,
  Carrier,
  CarrierAdmin,
  DispatchForm,
  DriverAssignment,
  equipmentTypes,
  HttpStatus,
  LoadsServiceLoad,
  OrderInfoForm,
  PaymentsTypeForm,
  TrailerAssignment,
  TruckAssignment,
} from '@haulynx/types';
import { aliveWhile, joinNoteTexts } from '@haulynx/utils';
import { first as lodashFirst, get } from 'lodash';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map, pairwise, takeUntil, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'app-assign-load-carrier-container',
  templateUrl: './assign-load-carrier-container.component.html',
  styleUrls: ['./assign-load-carrier-container.component.scss'],
})
export class AssignLoadCarrierContainerComponent implements OnInit, OnDestroy {
  alive = aliveWhile();
  carrierDot: string;
  equipments = equipmentTypes;
  isLoading$ = new BehaviorSubject(false);
  formData$ = new BehaviorSubject({});
  selectedCarrier$: Observable<Carrier>;
  carrierAdmin$ = new BehaviorSubject<CarrierAdmin>(null);
  load: LoadsServiceLoad;
  assignLoadCarrierFormMode = AssignLoadCarrierFormMode;
  customerNotes$: Observable<string>;

  constructor(
    public loadDetailsModel: LoadDetailsModel,
    private loadFeedModel: LoadFeedModel,
    public commonEntities: CommonEntities,
    public carrierDetailsModel: CarrierDetailsModel,
    public appModel: AppModel,
    private dialogRef: MatDialogRef<AssignLoadCarrierContainerComponent>,
    public paymentTypeStateModel: PaymentTypeStateModel,
    public userEntityService: UserEntityService,
    @Inject(MAT_DIALOG_DATA)
    public data: AssignLoadCarrierData,
    private loadEntityService: LoadEntityService
  ) {
    this.paymentTypeStateModel.clearState();
    this.selectedCarrier$ = this.carrierDetailsModel.state$;
  }

  cancel(): void {
    this.dialogRef.close();
  }

  bookForCarrier(data: AssignLoadCarrierForm): void {
    const loadId = this.load.id;
    const dot = data.carrierInfo.carrier;

    if (this.data.bidId) {
      this.loadFeedModel.acceptBidAndBookLoad(
        {
          brokerId: this.data.brokerId,
          id: this.data.bidId,
          loadId,
        },
        data
      );
    } else {
      this.loadDetailsModel.bookForCarrierLoad(loadId, dot, data, data.orderInfo.brokerId);
    }
  }

  updatePayment(data: AssignLoadCarrierForm): void {
    const orderNumber = data.payments[0] && data.payments[0].orderNumber;
    const userEmail = this.data.userEmail;
    const payments = data.payments;

    this.paymentTypeStateModel.savePaymentsTypes(orderNumber, this.load?.id, payments, { userEmail });
    this.dialogRef.close();
  }

  onAddPayType(data: { payment: PaymentsTypeForm; index: number }): void {
    this.paymentTypeStateModel.addPaymentTypeState(data);
  }

  onDeletePayType(data: { index: number }): void {
    const { index } = data;
    this.paymentTypeStateModel.removePayment({ index });
  }

  clearAllPayments(payments: PaymentsTypeForm[]): void {
    this.paymentTypeStateModel.clearState();
  }

  onEditPayType(data: { index: number; payment: Partial<PaymentsTypeForm> }): void {
    this.paymentTypeStateModel.updatePaymentTypeState(data);
  }

  ngOnInit(): void {
    const { carrierDot = null, loadId = null } = this.data || {};
    this.carrierDot = carrierDot;
    if (carrierDot && loadId) {
      const query = { carrierDot };
      const key = loadId;

      this.commonEntities.graphQlDrivers.search({ key, query });
      this.commonEntities.graphQlTrailers.search({ key, query });
      this.commonEntities.graphQlTrucks.search({ key, query });
      this.commonEntities.graphQlBrokersIds.search({ key, query });
      this.commonEntities.graphQlCarriers.search({ key, query: { search: carrierDot } });

      this.carrierDetailsModel.setStateKey({ key: carrierDot });
      this.carrierDetailsModel.get({ carrierDot });
    }

    if (loadId) {
      this.loadDetailsModel.setStateKey({ key: loadId, namespace: 'ASSIGN_LOAD_CARRIER_CONTAINER' });
      this.loadDetailsModel.get({ key: loadId, id: loadId, isLatest: true });
    }

    this.commonEntities.paymentTypes.search();
    this.commonEntities.graphQlBrokersIds.search({});

    this.loadDetailsModel.onCarrierBookSuccess$.pipe(takeUntil(this.alive)).subscribe(() => {
      this.dialogRef.close(true); // load was booked
    });

    // The selected carrier only emits once if there is a carrierId passed in.
    // Otherwise, it won't emit a selected carrier and the user will chose one later

    combineLatest([this.carrierDetailsModel.state$, this.commonEntities.graphQlCarriers.entities$])
      .pipe(
        takeUntil(this.alive),
        map(([carrier, carriers]) => {
          if (carrier) {
            const matchedCarrier = carriers?.find((carrier) => carrier.dot === this.carrierDot);
            if (matchedCarrier) {
              this.carrierAdmin$.next({
                carrierDot: matchedCarrier?.dot,
                mcNumber: matchedCarrier?.mcNumber,
                name: matchedCarrier?.adminName,
                email: matchedCarrier?.email,
                phone: matchedCarrier?.phone,
              });
            }
          }
        })
      )
      .subscribe();

    combineLatest([
      this.loadDetailsModel.form.isLoading$,
      this.carrierDetailsModel.isLoading$,
      this.paymentTypeStateModel.isLoading$,
      this.loadFeedModel.isLoadingAcceptBid$,
    ])
      .pipe(takeUntil(this.alive))
      .subscribe(([isLoadingLoad, isLoadingCarrier, isLoadingPayType, isAcceptingBid]) => {
        const isAcceptingBidForThisLoad: boolean = this.data.bidId ? isAcceptingBid[this.data.bidId] : false;
        this.isLoading$.next(isLoadingLoad || isLoadingCarrier || isLoadingPayType || isAcceptingBidForThisLoad);
      });

    combineLatest([this.loadDetailsModel.form.state$, this.appModel.brokerId$])
      .pipe(takeUntil(this.alive))
      .subscribe(([load, brokerId]: [LoadsServiceLoad, string]) => {
        this.load = load;
        if (load) {
          const order = get(lodashFirst(load.locations), 'billOfLading', null);
          const orderInfo: OrderInfoForm = {
            brokerId: brokerId,
            order: order,
          };

          this.loadEntityService.getLoadByIdManager.dispatch(load.id);
          this.customerNotes$ = this.loadEntityService.getLoadByIdManager.entities$.pipe(
            map((entities) => joinNoteTexts(entities[load.id]?.billTo?.notes))
          );

          const dispatchInfo: DispatchForm = {
            notes: load.dispatchLocation && load.dispatchLocation.notes,
            timeAvailable: load.dispatchLocation && load.dispatchLocation.timestamp,
            location: load.dispatchLocation && {
              address: load.dispatchLocation.address,
              lat: load.dispatchLocation.lat,
              lon: load.dispatchLocation.lon,
            },
          };
          this.formData$.next({ orderInfo, dispatchInfo });
          this.paymentTypeStateModel.getPaymentTypes({ loadId: load?.id });
        }
      });

    this.paymentTypeStateModel.httpStatus$
      .pipe(
        takeUntil(this.alive),
        pairwise(),
        filter(([previousHttpsStatus]: [HttpStatus, HttpStatus]) => previousHttpsStatus === HttpStatus.PENDING),
        withLatestFrom(this.paymentTypeStateModel.paymentsTypes$, this.loadDetailsModel.form.state$)
      )
      .subscribe(([httpsStatus, payments, load]: [[HttpStatus, HttpStatus], PaymentsTypeForm[], LoadsServiceLoad]) => {
        const { price = null } = this.data.formData || {};
        const payment = {
          amount: price || load.paymentDetails?.price || null,
          orderNumber: get(lodashFirst(load.locations), 'billOfLading', null),
          quantity: 1,
          paymentType: {
            code: 'LHFLT',
            description: 'Linehaul - Flat',
            quantity: 1,
            method: 'FLT',
            isReadOnly: true,
            isNegative: false,
          },
        };

        if (!payments.length) {
          this.onAddPayType({ payment, index: 0 });
        }
        if (payments.length && this.getPaymentTotal(payments) != load.paymentDetails?.price) {
          this.paymentTypeStateModel.savePaymentsTypes(
            get(lodashFirst(load.locations), 'billOfLading', null),
            this.load?.id,
            [],
            {
              userEmail: this.data.userEmail,
            }
          );
          this.onAddPayType({ payment, index: 0 });
        }
        this.paymentTypeStateModel.paymentsTypes$
          .pipe(takeUntil(this.alive))
          .subscribe((payments) => this.formData$.next({ payments }));
      });
  }

  getPaymentTotal(payments): number {
    let total = 0;
    payments.forEach((payment) => {
      total = total + payment.amount;
    });
    return total;
  }

  selectCarrier(carrierDot: string): void {
    this.carrierDot = carrierDot ? carrierDot : this.carrierDot;
    if (!this.load || !carrierDot) {
      return;
    }
    const query = { carrierDot };
    const key = this.load.id;

    this.carrierDetailsModel.setStateKey({ key: carrierDot });
    this.carrierDetailsModel.get({ carrierDot });
    this.commonEntities.graphQlCarriers.search({ key, query });
    this.commonEntities.graphQlDrivers.search({ key, query });
    this.commonEntities.graphQlTrailers.search({ key, query });
    this.commonEntities.graphQlTrucks.search({ key, query });
  }

  onSearchCarrier(search: unknown): void {
    if (this.load) {
      const key = this.load.id;
      this.commonEntities.graphQlCarriers.search({ key, query: { search } });
    }
  }

  onCreateDriver(driverAssignment: DriverAssignment): void {
    this.loadDetailsModel.createDriver(driverAssignment);
  }

  onCreateTruck(truckAssignment: TruckAssignment): void {
    this.loadDetailsModel.createTruck(truckAssignment);
  }

  onCreateTrailer(trailerAssignment: TrailerAssignment): void {
    this.loadDetailsModel.createTrailer(trailerAssignment);
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }
}
