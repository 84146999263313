import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadIdentifierType, LoadsServiceLoad } from '@haulynx/types';

@Component({
  selector: 'haulynx-load-overview-banner',
  templateUrl: './load-overview-banner.component.html',
  styleUrls: ['./load-overview-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadOverviewBannerComponent {
  @Input() missionId = '';
  @Input() isHeadHaul = false;
  @Input() isBackHaul = false;
  @Input() headHaulLoad: LoadsServiceLoad;
  @Input() backHaulLoad: LoadsServiceLoad;
  @Input() currentView = '';
  public loadIdentifierType = LoadIdentifierType;

  constructor(public router: Router) {}

  navigateToLoad(location: string) {
    switch (location) {
      case 'headHaulDetails':
        this.router.navigateByUrl(`loads/${this.headHaulLoad.id}/overview/info`);
        break;
      case 'backHaulDetails':
        this.router.navigateByUrl(`loads/${this.backHaulLoad.id}/overview/info`);
        break;
      case 'headHaulBooking':
        this.router.navigateByUrl(`loads/${this.headHaulLoad.id}/overview/booking`);
        break;
      case 'backHaulBooking':
        this.router.navigateByUrl(`loads/${this.backHaulLoad.id}/overview/booking`);
        break;
      case 'headHaulTracking':
        this.router.navigateByUrl(`loads/${this.headHaulLoad.id}/overview/tracking`);
        break;
      case 'backHaulTracking':
        this.router.navigateByUrl(`loads/${this.backHaulLoad.id}/overview/tracking`);
        break;
      case 'mission':
        this.router.navigate([`loads/search`], { queryParams: { missionId: this.missionId } });
        break;
    }
  }
}
