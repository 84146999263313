<div class="blue-select">
  <mat-form-field appearance="outline" floatLabel="always" [hideRequiredMarker]="!required">
    <mat-label *ngIf="label != 'Label'">{{ label }}</mat-label>
    <mat-select [formControl]="control" panelClass="blue-select-panel" (selectionChange)="onOptionSelected()" required>
      <mat-option *ngFor="let option of options" [value]="option[value]">
        {{ option[key] }}
      </mat-option>
    </mat-select>
    <div class="errors">
      <ng-content select="mat-error"></ng-content>
    </div>
  </mat-form-field>
</div>
