import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { SidebarItem } from '@haulynx/types';
import { ConfirmationService } from '@haulynx/services';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-sidebar-node',
  templateUrl: './sidebar-node.component.html',
  styleUrls: ['./sidebar-node.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('arrowRotation', [
      state('collapsed', style({ transform: 'rotate(0deg)' })),
      state('expanded', style({ transform: 'rotate(180deg)' })),
      transition('expanded <=> collapsed', animate('300ms cubic-bezier(0.4,0.0,0.2,1)')),
    ]),
  ],
})
export class SidebarNodeComponent implements OnChanges {
  @Input() item: SidebarItem;
  @Input() depth = 0;
  @Input() isSidebarOpened: boolean;
  @Input() activeNodeLabel: string;
  @Input() move = false;
  @Input() check = false;
  @Input() savedViews: SidebarItem[] = [];
  @Output() itemSelected = new EventEmitter<SidebarItem>();
  @Output() deleteSidebarItem = new EventEmitter<SidebarItem>();
  @Output() updateSidebarItem = new EventEmitter<SidebarItem[]>();
  @Output() moveSidebarItem = new EventEmitter<{ move: boolean; item?: SidebarItem; done?: boolean }>();

  constructor(private confirmationService: ConfirmationService, private cd: ChangeDetectorRef) {}

  isActive: boolean;
  isExpanded: boolean;

  toggleMove = false;
  checkOrigin: SidebarItem;

  onItemExpanded(event: MouseEvent, item: SidebarItem): void {
    event.stopImmediatePropagation();
    this.isExpanded = item.children.length ? !this.isExpanded : false;
  }

  onItemSelected(item: SidebarItem, event?: MouseEvent): void {
    if (!this.toggleMove) {
      this.itemSelected.emit(item);
      if (event && item?.children?.length) {
        this.onItemExpanded(event, item);
      }
    }
  }

  trackByFn(index: number, item: SidebarItem): string {
    return item.route;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { move } = changes;
    if (move && this.item?.metaData?.isCustom) {
      this.toggleMove = move.currentValue;
    }
    setTimeout(() => {
      this.isActive = this.item.label === this.activeNodeLabel;
      this.isExpanded = this.calculateIsExpanded(this.item);
      this.cd.detectChanges();
    });
  }

  shouldShowExpand(): boolean {
    if (this.isSidebarOpened) {
      if (this.item.children && this.item.children.length > 0) {
        return true;
      }
      return false;
    } else return false;
  }

  private calculateIsExpanded(item: SidebarItem): boolean {
    if (item.label === this.activeNodeLabel) {
      return true;
    }

    if (item.children?.length) {
      return item.children.some((child) => this.calculateIsExpanded(child));
    }
    return false;
  }

  deleteSearchView(item: SidebarItem) {
    this.confirmationService
      .open({ title: 'Confirm', message: 'Please confirm deletion of custom search view' })
      .subscribe((result: boolean) => {
        if (result) {
          this.deleteSidebarItem.emit(item);
        }
      });
  }

  onDeleteSidebarItem(item: SidebarItem) {
    this.deleteSidebarItem.emit(item);
  }

  /**
   * Handles drop event when moving sidebar items
   *
   * @param { CdkDragDrop<SidebarItem[]>} event drag and drop event
   */
  onDrop(event: CdkDragDrop<SidebarItem[]>) {
    if (this.getSavedViews(this.item).includes(this.item?.children[event.currentIndex])) {
      moveItemInArray(this.item?.children, event.previousIndex, event.currentIndex);
    }
  }

  getSavedViews(item: SidebarItem): SidebarItem[] {
    const searchViews = item?.children?.filter((child) => child.id);
    return searchViews;
  }

  /**
   * If a custom view, toggles the move state,
   * intializes the check/the sidebar origin of the check, and
   * emits current state to other sidebar items
   */
  onMoveToggle(): void {
    if (this.item?.metaData?.isCustom) {
      this.toggleMove = !this.toggleMove;
      this.check = this.toggleMove;
      this.checkOrigin = this.item;
      this.moveSidebarItem.emit({ item: this.item, move: this.toggleMove });
    }
  }

  /**
   * Receives updateSidebarItem emit and emits updated search view array if root item
   *
   * @param {boolean} move If current state allows moving of side bar items
   * @param {SidebarItem} item The item the oringal emit came from
   * @param {boolean} done If moving is finished
   */
  onMoveSideBarItem(event: { move: boolean; item?: SidebarItem; done: boolean }): void {
    this.toggleMove = event.move;
    this.checkOrigin = event.item;
    this.moveSidebarItem.emit({ item: event.item, move: event.move, done: event.done });
    if (this.item.children && event?.item?.parent === this.item?.id && event.done) {
      this.updateSidebarItem.emit(this.getSavedViews(this.item));
    }
  }

  /**
   * Toggles Move and emits done to other side bar items
   */
  onCheckToggle(): void {
    if (this.item?.metaData?.isCustom) {
      this.toggleMove = !this.toggleMove;
      this.check = this.toggleMove;
      this.moveSidebarItem.emit({ item: this.item, move: this.toggleMove, done: true });
    }
  }
}
