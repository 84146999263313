import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@haulynx/directives';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { NotificationVmService } from '@haulynx/services';
import { DropDownModule } from '../drop-down/drop-down.module';
import { LoadSearchTimelineModule } from '../load-search-timeline/load-search-timeline.module';
import { LoadsServiceLoadPreviewModule } from '../loads-service-load-preview/loads-service-load-preview.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { AppNotificationComponent } from './components/app-notification';
import { AppNotificationCenterComponent } from './components/app-notification-center';
import { AppNotificationSearchComponent } from './components/app-notification-search';

@NgModule({
  exports: [AppNotificationCenterComponent, AppNotificationComponent, AppNotificationSearchComponent],
  declarations: [AppNotificationCenterComponent, AppNotificationComponent, AppNotificationSearchComponent],
  imports: [
    CommonModule,
    MaterialModule,
    SpinnerModule,
    DropDownModule,
    ReactiveFormsModule,
    FormsModule,
    LoadsServiceLoadPreviewModule,
    PipesModule,
    DirectivesModule,
    LoadSearchTimelineModule,
  ],
  providers: [NotificationVmService],
})
export class AppNotificationModule {}
