import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BulkActionPage, DataTableConfig, DataTableType, EntityType, IColumns2 } from '@haulynx/types';

@Component({
  selector: 'app-test-data-table',
  templateUrl: './test-data-table.component.html',
  styleUrls: ['./test-data-table.component.scss'],
})
export class TestDataTableComponent implements OnInit, OnChanges {
  @Input() data: DataTableType<EntityType> = [];
  @Input() displayedColumns: IColumns2[] = [];
  @Input() title = null;
  @Input() showPaginator = false;
  @Input() showCheckbox = false;
  @Input() rowSelection = false;
  @Input() sortColumns = false;
  @Input() rowSpacing = 12;
  @Input() scrollable = true;
  @Input() scrollHeight: string | 'inherit' = 'inherit';
  @Input() emptyMessage = '';
  @Input() loading = false;

  configOptions: DataTableConfig = {
    globalFilter: false,
    reorderColumns: false,
    columnVisibility: false,
    reorderRows: false,
    showBulkActions: false,
    sortColumns: false,
    scrollable: false,
    checkBoxSelection: false,
    filterLocally: false,
    showTopPaginator: false,
    showBottomPaginator: false,
    isLaneRate: false,
    bulkActions: BulkActionPage.SEARCH,
    pageAmount: 25,
  };

  constructor() {}

  ngOnInit(): void {}

  selectedRow(row: unknown): void {
    console.log(row);
  }

  selectedColumn(event: unknown): void {
    console.log('SELECT CLICKABLE COLUMN FROM NOT TEMPLATED CODE');
    console.log(event);
  }

  clickColumnFromTemplateCode(event: unknown): void {
    console.log('SELECT CLICKABLE COLUMN FROM TEMPLATED CODE');
    console.log(event);
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
  }
}
