import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoadFeedFilterService, WindowRef } from '@haulynx/services';
import { dateButtons, EquipmentTypes, User } from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import moment from 'moment';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-network-carrier-search-form',
  templateUrl: './carrier-recommend-search-form.component.html',
  styleUrls: ['./carrier-recommend-search-form.component.scss'],
})
export class CarrierRecommendSearchFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() query: any = null;
  @Input() locations: any[] = [];
  @Input() equipmentTypes: EquipmentTypes[] = [];
  @Output() onSearch = new EventEmitter();

  alive = aliveWhile();
  user: User;
  activeDateButton: number;
  dateButtons = dateButtons;
  form: FormGroup;
  userLocation = {
    pickUpName: 'Your Location',
    origLon: null,
    origLat: null,
  };
  _window: any;

  constructor(
    private loadFeedFilterService: LoadFeedFilterService,
    private fb: FormBuilder,
    private windowRef: WindowRef,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this._window = this.windowRef.getNativeWindow();
    this.form = this.fb.group({
      pickUpName: ['Any'],
      origLat: [null],
      origLon: [null],

      deliveryName: ['Any'],
      destLat: [null],
      destLon: [null],

      allowStops: [true],
      pickupDate: [moment.utc().toDate()],
      equipmentType: ['Any'],
      loadDistance: [null],
      price: [null],
      weight: [null],
      rpm: [null],
    });

    this.form
      .get('pickupDate')
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.alive))
      .subscribe((activeDate) => {
        let activeDateButton = null;

        if (this.loadFeedFilterService.isToday(activeDate)) {
          activeDateButton = this.loadFeedFilterService.getButtonByLabel('Today');
        }

        if (this.loadFeedFilterService.isTomorrow(activeDate)) {
          activeDateButton = this.loadFeedFilterService.getButtonByLabel('Tomorrow');
        }

        if (this.loadFeedFilterService.isNextDay(activeDate)) {
          activeDateButton = this.loadFeedFilterService.getButtonByLabel('Next Day');
        }

        this.activeDateButton = activeDateButton;
      });

    this.activeDateButton = this.loadFeedFilterService.getButtonByLabel('Today');

    if (data) {
      const { query } = data;

      this.form.patchValue(query || {});
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { query } = changes;

    if (query) {
      const formData = query.currentValue;

      this.form.patchValue(formData ? formData : {});
    }
  }

  ngOnInit(): void {
    const geolocation = this._window.navigator.geolocation;

    if (geolocation) {
      geolocation.getCurrentPosition((position) => {
        const { longitude, latitude } = position.coords;
        const origLon = this.form.get('origLon').value,
          origLat = this.form.get('origLat').value;

        if (!origLon && !origLat) {
          this.userLocation = {
            pickUpName: 'Your Location',
            origLon: longitude,
            origLat: latitude,
          };

          this.form.patchValue(this.userLocation);
        }
      });
    }
  }

  onGpsLocation(formControlName, { lat, lon }) {
    if (formControlName === 'pickUpName') {
      this.form.get('origLon').setValue(lon);
      this.form.get('origLat').setValue(lat);
    }

    if (formControlName === 'deliveryName') {
      this.form.get('destLon').setValue(lon);
      this.form.get('destLat').setValue(lat);
    }
  }

  setCurrentUserLocation() {
    this.form.patchValue(this.userLocation);
  }

  dateButtonClick(activeDateButton: number): void {
    const newActiveDate = this.loadFeedFilterService.selectDate(activeDateButton);

    this.activeDateButton = activeDateButton;
    this.form.patchValue({
      pickupDate: newActiveDate,
    });
  }

  search(): void {
    const data = this.form.getRawValue();

    this.onSearch.emit(data);
  }

  switchLocations(): void {
    const pickUpName = this.form.get('pickUpName').value;
    const origLat = this.form.get('origLat').value;
    const origLon = this.form.get('origLon').value;

    const deliveryName = this.form.get('deliveryName').value;
    const destLat = this.form.get('destLat').value;
    const destLon = this.form.get('destLon').value;

    this.form.patchValue({
      pickUpName: deliveryName,
      origLat: destLat,
      origLon: destLon,

      deliveryName: pickUpName,
      destLat: origLat,
      destLon: origLon,
    });
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }
}
