import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ContactSupportDialogModule } from './contact-support-dialog/contact-support-dialog.module';
import { DialogTemplateModule } from './dialog-template/dialog-template.module';
import { DontMeetRequirementsDialogModule } from './dont-meet-requirements-dialog/dont-meet-requirements-dialog.module';
import { LeaveInRmisProcessDialogModule } from './leave-in-rmis-process-dialog/leave-in-rmis-process-dialog.module';
import { MoreAboutRmisDialogModule } from './more-about-rmis-dialog/more-about-rmis-dialog.module';
import { XtCarrierRequirementsDialogModule } from './xt-carrier-requirements-dialog/xt-carrier-requirements-dialog.module';

const dialogs = [
  DialogTemplateModule,
  ContactSupportDialogModule,
  DontMeetRequirementsDialogModule,
  LeaveInRmisProcessDialogModule,
  MoreAboutRmisDialogModule,
  XtCarrierRequirementsDialogModule,
];

@NgModule({
  imports: [CommonModule, ...dialogs],
  exports: [...dialogs],
})
export class DialogsModule {}
