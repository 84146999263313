import gql from 'graphql-tag';
import { MINIMAL_LOAD_DETAILS_FRAGMENT } from './fragments';

export const CarrierLoadSearchGetRecommendations = gql`
  query CarrierLoadSearchGetRecommendations($dot: String!, $paging: PageAndSort) {
    matches: getLoadMatchesByCarrierDot(dot: $dot, paging: $paging) {
      total
      result {
        loadsServiceLoad {
          ...minimalLoadDetails
        }
      }
    }
    recommendations: getTruckPostRecommendations(dot: $dot, paging: $paging) {
      total
      result {
        loadsServiceLoad {
          ...minimalLoadDetails
        }
      }
    }
  }
  ${MINIMAL_LOAD_DETAILS_FRAGMENT}
`;
