<!-- Main slider bar -->
<div class="slider">
  <mat-slider #sMin class="mat-range-slider mat-range-min-slider" [min]="min" [max]="max" [step]="1" (input)="onChange(false)" ></mat-slider>
  <mat-slider
    #sMax
    class="mat-range-slider mat-range-max-slider"
    (input)="onChange(true)"
    [min]="min"
    [max]="max"
    [step]="1"
    [invert]="true"
    
  ></mat-slider>
</div>
