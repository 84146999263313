import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import {
  PaymentItem,
  PaymentType,
  paymentTypes,
  calculatePaymentItemQuantity,
  PAYMENT_ITEM_MULTI_QUANTITY,
  LoadsServiceLoad,
} from '@haulynx/types';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { aliveWhile } from '@haulynx/utils';
import { get, includes, isString, toLower, sortBy } from 'lodash';
import { Observable } from 'rxjs';
import { debounceTime, map, startWith, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-add-payment-type',
  templateUrl: './add-payment-type.component.html',
  styleUrls: ['./add-payment-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddPaymentTypeComponent implements AfterViewInit, OnDestroy, OnInit {
  @Input() tabIndex: number = null;

  @ViewChild('auto', { static: true }) autocomplete: MatAutocomplete;
  @ViewChild('amount', { static: true }) amountEl: ElementRef;
  @ViewChild('quantity', { static: true }) quantityEl: ElementRef;
  @ViewChild(MatAutocompleteTrigger, { static: true }) autocompleteTrigger: MatAutocompleteTrigger;

  filteredOptions$: Observable<PaymentType[]>;

  form: FormGroup;
  alive = aliveWhile();
  readOnly = true;

  constructor(
    private fb: FormBuilder,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: { paymentTypes: PaymentType[]; load: LoadsServiceLoad },
    @Optional() private dialogRef: MatDialogRef<AddPaymentTypeComponent>
  ) {}

  get fullAmount(): number {
    return this.form.controls.quantity.value * this.form.controls.amount.value || 0;
  }

  ngOnInit(): void {
    this.initForm();
    this.initAutoComplete();
  }

  initForm(): void {
    this.form = this.fb.group({
      paymentType: [null, this.validatePaymentType()],
      amount: [null, Validators.min(0)],
      quantity: [null, Validators.min(0)],
    });
  }

  initAutoComplete(): void {
    this.filteredOptions$ = this.form.controls.paymentType.valueChanges.pipe(
      startWith(''),
      debounceTime(200),
      map((value: string | PaymentType) => (isString(value) ? value : value.description)),
      map((query: string) => this.filterTypes(query))
    );
  }

  getPaymentItemQuantity(paymentItem: PaymentItem, load: LoadsServiceLoad, quantity: number = null): number | void {
    if (
      paymentItem.paymentType === PAYMENT_ITEM_MULTI_QUANTITY.SPAY ||
      paymentItem.paymentType === PAYMENT_ITEM_MULTI_QUANTITY.LYOVER ||
      paymentItem.paymentType === PAYMENT_ITEM_MULTI_QUANTITY.TRAIL ||
      paymentItem.paymentType === PAYMENT_ITEM_MULTI_QUANTITY.DTNTN
    ) {
      if (quantity) return quantity;
      this.readOnly = false;
      return;
    }
    this.readOnly = true;
    return calculatePaymentItemQuantity(paymentItem, load);
  }

  select(paymentType: PaymentType): void {
    const paymentItem = { paymentType: paymentType.code, orderNumber: null } as PaymentItem;
    const quantity = this.getPaymentItemQuantity(paymentItem, this.data?.load);

    this.form.patchValue({ quantity: quantity, paymentType: paymentType });

    if (paymentType.code === PAYMENT_ITEM_MULTI_QUANTITY.TRAIL) {
      this.form.controls['amount'].setValue(null);
      this.form.controls['amount'].setValidators([Validators.max(0)]);
    } else {
      this.form.controls['amount'].setValue(null);
      this.form.controls['amount'].setValidators([Validators.min(0)]);
    }
    this.form.updateValueAndValidity();

    this.readOnly ? this.amountEl.nativeElement.focus() : this.quantityEl.nativeElement.focus();
  }

  save(event: Event): void {
    event.stopPropagation();
    const { amount, paymentType, quantity } = this.form.value;
    const output = { amount, quantity, paymentType: { ...paymentType, quantity } };
    this.dialogRef.close(output);
  }

  filterTypes(query: string): PaymentType[] {
    const filterValue = toLower(query);
    const filteredTypes = paymentTypes.filter((paymentType: PaymentType) => {
      const descriptionMatch =
        includes(toLower(paymentType.description), filterValue) || includes(toLower(paymentType.code), filterValue);
      return descriptionMatch;
    });

    return sortBy(filteredTypes, ['code']);
  }

  displayFn(option: PaymentType): string {
    return get(option, 'description', '');
  }

  ngAfterViewInit(): void {
    if (this.autocompleteTrigger) {
      this.autocompleteTrigger.panelClosingActions.pipe(takeUntil(this.alive)).subscribe(() => {
        const firstValue = get(this.autocomplete, 'options.first.value', null);

        if (this.form.controls.paymentType.valid && firstValue) {
          const autoValue = get(this.autocompleteTrigger.activeOption, 'value', firstValue);

          this.form.patchValue({ type: autoValue });
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }

  private validatePaymentType(): ValidatorFn {
    return (control: AbstractControl) => {
      const paymentType = control.value as PaymentType;
      const { code = null } = paymentType || {};

      return !paymentType || !code ? { paymentType: true } : null;
    };
  }
}
