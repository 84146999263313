import { InjectionToken, Provider } from '@angular/core';
import { IAsyncEntityState, User } from '@haulynx/types';
import { USER_PROVIDED_EFFECTS } from '@ngrx/effects';
import { ActionReducer, ActionReducerMap } from '@ngrx/store';
import { UserEntityService } from './user-entity.service';

export const USER_ENTITY_REDUCERS_TOKEN = new InjectionToken<ActionReducerMap<IAsyncEntityState<User>>>(
  'user reducers'
);

function getUserEntityReducersFactory(userEntityService: UserEntityService): ActionReducer<IAsyncEntityState<User>> {
  return userEntityService.getReducers();
}

const userEntityReducerProvider: Provider = {
  provide: USER_ENTITY_REDUCERS_TOKEN,
  useFactory: getUserEntityReducersFactory,
  deps: [UserEntityService],
};

const userEntityEffectProvider: Provider = {
  provide: USER_PROVIDED_EFFECTS,
  multi: true,
  useValue: [UserEntityService],
};

export const userStateProviders: Provider[] = [userEntityReducerProvider, userEntityEffectProvider];
