import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import { DispatchAction } from '../../shared/store/helpers/action';
import { BookLoadActionTypes } from './book-load.actions';

@Injectable()
export class BookLoadAnalyticsEffects {
  bookLoad = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BookLoadActionTypes.BOOK_LOAD),
        tap(() => {
          // this.bookLoadLoadingTrace = this.performance.trace('Book Load');
          // this.bookLoadLoadingTrace.start();
        })
      ),
    { dispatch: false }
  );

  bookLoadSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BookLoadActionTypes.BOOK_LOAD_SUCCESS),
        tap(() => {
          // if (this.bookLoadLoadingTrace) {
          //   try {
          //     this.bookLoadLoadingTrace.stop();
          //     this.bookLoadLoadingTrace = null;
          //   } catch (error) {
          //     // Ignore traces that are not running
          //   }
          // }
        })
      ),
    { dispatch: false }
  );

  bookLoadError = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BookLoadActionTypes.BOOK_LOAD_ERROR),
        map((action: DispatchAction) => action.payload),
        tap((payload) => {
          // if (this.bookLoadLoadingTrace) {
          //   try {
          //     this.bookLoadLoadingTrace.putAttribute(`Error`, payload);
          //     this.bookLoadLoadingTrace.stop();
          //     this.bookLoadLoadingTrace = null;
          //   } catch (error) {
          //     // Ignore traces that are not running
          //   }
          // }
        })
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions) {}
}
