<div class="referral">
  <p>Copy the link below or send an email to start earning comission for every carrier that signs up with Haulynx!</p>

  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
    <div data-cy="referral-link">
      {{ link }}
    </div>
    <button mat-raised-button ngxClipboard [cbContent]="link" (cbOnSuccess)="copyLinkSuccess()" data-test="copy-button">
      <mat-icon> content_copy </mat-icon>
    </button>
    <button mat-raised-button (click)="composeEmail()" data-test="compose-email-button">Compose Email</button>
  </div>
</div>
