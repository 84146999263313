import { ISearchFilter, TextFilterType } from '../search-filter';
import { ISearchFilterType } from '../search-filter.types';

export class OBRegionFilter implements ISearchFilter {
  name = 'Outbound Region';
  type: ISearchFilterType = ISearchFilterType.MULTI_DROPDOWN;
  keys: TextFilterType;
  description = 'Filter loads by outbound region';

  constructor(defaultValues?: TextFilterType) {
    this.keys = new TextFilterType({
      value: '',
      optionsDataIndex: [
        {
          key: 'Midwest',
          value: 'Midwest',
        },
        {
          key: 'North',
          value: 'North',
        },
        {
          key: 'South',
          value: 'South',
        },
        {
          key: 'WestCoast',
          value: 'WestCoast',
        },
      ],
      textFormName: 'outboundRegion',
      ...defaultValues,
    });
  }
}
