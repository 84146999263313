import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { LoadsServiceService, SharedLocationService } from '@haulynx/services';
import { SharedLocationModel } from '@haulynx/store';
import { UnauthenticatedLoadsServiceLoad } from '@haulynx/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CanViewLoadGuard implements CanActivate {
  constructor(
    private sharedLocationModel: SharedLocationModel,
    private sharedLocationService: SharedLocationService,
    private loadsServiceService: LoadsServiceService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const { hash } = route.params;

    return this.loadsServiceService.getSharedLocationByHash(hash).pipe(
      map((load: UnauthenticatedLoadsServiceLoad) => {
        const isActive = this.sharedLocationService.canViewLoad(load);

        if (isActive) {
          this.sharedLocationModel.setSharedLoad(load);

          return this.sharedLocationService.canViewLoad(load);
        } else {
          this.router.navigate(['/']);
        }

        return isActive;
      })
    );
  }
}
