<form
  [formGroup]="form"
  (submit)="onSubmit($event)"
  [ngClass]="{ 'post-truck__hide': carrierToolbar, 'post-truck__form': true }"
>
  <div class="post-truck__block post-truck__block--location">
    <mat-form-field class="app-form-field" appearance="fill" floatLabel="always" hideRequiredMarker>
      <mat-label> *Location </mat-label>
      <app-address-field
        #location
        formControlName="location"
        placeholder="Ex. Phoenix, AZ"
        required
        showOnlyCities="true"
        [countryRestrictions]="countryRestrictions"
        (onPlaceChange)="onLocationSelect()"
        data-test="origin-location"
      >
      </app-address-field>
    </mat-form-field>
  </div>

  <div class="post-truck__block post-truck__block--location-radius">
    <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
      <mat-label> Radius </mat-label>
      <app-drop-down
        #locationRadius
        formControlName="locationRadius"
        displayLabel="label"
        placeholder="Select"
        [data]="radiusList"
        [chooseDefaultOnTab]="true"
        (manualSelect)="onLocationRadiusSelect()"
      >
      </app-drop-down>
    </mat-form-field>
  </div>

  <div class="post-truck__block">
    <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
      <mat-label> Destination </mat-label>
      <app-address-field
        #destination
        showOnlyCities="true"
        formControlName="preferredLocation"
        placeholder="Ex. Anywhere"
        data-test="destination-location"
        [countryRestrictions]="countryRestrictions"
        (onPlaceChange)="onDestinationSelect()"
      ></app-address-field>
    </mat-form-field>
  </div>

  <div class="post-truck__block">
    <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
      <mat-label> Radius </mat-label>
      <app-drop-down
        #destinationRadius
        formControlName="preferredLocationRadius"
        placeholder="Select"
        [data]="radiusList"
        [chooseDefaultOnTab]="true"
        (manualSelect)="onDestinationRadiusSelect()"
      >
      </app-drop-down>
    </mat-form-field>
  </div>

  <div class="post-truck__block">
    <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
      <mat-label> Date Available </mat-label>
      <app-datepicker
        #datepicker
        label="Date Available"
        formControlName="timeAvailable"
        [min]="minDate"
        [tabIndex]="-1"
        [separateDateInputs]="false"
        [dropdown]="true"
        data-test="dropdown"
      >
      </app-datepicker>
    </mat-form-field>
  </div>

  <div class="post-truck__block">
    <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
      <mat-label> Equipment </mat-label>
      <app-drop-down
        formControlName="equipmentType"
        displayLabel="text"
        key="text"
        placeholder="Select"
        [data]="equipmentList"
        [chooseDefaultOnTab]="true"
        (manualSelect)="onEquipmentTypesSelect()"
      >
      </app-drop-down>
    </mat-form-field>
  </div>

  <div class="post-truck__block post-truck__block--button">
    <button
      #submitButton
      type="submit"
      mat-raised-button
      class="affirmative"
      [disabled]="form.invalid"
      (click)="onSubmit($event)"
      data-test="post-truck"
    >
      <mat-icon *ngIf="isLoading; else textLabel">
        <mat-spinner diameter="23"></mat-spinner>
      </mat-icon>
      <ng-template #textLabel>POST TRUCK</ng-template>
    </button>
  </div>
</form>

<form
  [formGroup]="form"
  (submit)="onSubmit($event)"
  [ngClass]="{ 'ct-post-truck__hide': !carrierToolbar, 'ct-post-truck__form': true }"
>
  <div class="ct-post-truck__title">Add Posted Truck</div>
  <div class="ct-post-truck__block">
    <mat-form-field class="app-form-field primary-field" appearance="fill" floatLabel="always">
      <app-address-field
        #location
        formControlName="location"
        placeholder="Enter Location"
        required
        showOnlyCities="true"
        [countryRestrictions]="countryRestrictions"
        (onPlaceChange)="onLocationSelect()"
        data-test="origin-location"
        [error]="form.get('location')?.errors?.locationIsValid"
      >
      </app-address-field>
    </mat-form-field>

    <mat-form-field class="app-form-field secondary-field" appearance="fill" floatLabel="always">
      <app-drop-down
        #locationRadius
        formControlName="locationRadius"
        displayLabel="label"
        placeholder="Select"
        [data]="radiusList"
        [chooseDefaultOnTab]="true"
        (manualSelect)="onLocationRadiusSelect()"
      >
      </app-drop-down>
    </mat-form-field>
  </div>

  <div class="ct-post-truck__block">
    <mat-form-field class="app-form-field primary-field" appearance="fill" floatLabel="always">
      <app-address-field
        #destination
        showOnlyCities="true"
        formControlName="preferredLocation"
        placeholder="Enter Destination"
        data-test="destination-location"
        [countryRestrictions]="countryRestrictions"
        (onPlaceChange)="onDestinationSelect()"
      ></app-address-field>
    </mat-form-field>

    <mat-form-field class="app-form-field secondary-field" appearance="fill" floatLabel="always">
      <app-drop-down
        #destinationRadius
        formControlName="preferredLocationRadius"
        placeholder="Select"
        [data]="radiusList"
        [chooseDefaultOnTab]="true"
        (manualSelect)="onDestinationRadiusSelect()"
      >
      </app-drop-down>
    </mat-form-field>
  </div>

  <div class="ct-post-truck__block">
    <mat-form-field class="app-form-field time-field" appearance="fill" floatLabel="always">
      <app-datepicker
        #datepicker
        label="Date Available"
        formControlName="timeAvailable"
        placeholder="Enter Date"
        [min]="minDate"
        [tabIndex]="-1"
        [separateDateInputs]="false"
        [dropdown]="true"
        data-test="dropdown"
      >
      </app-datepicker>
    </mat-form-field>
  </div>

  <div class="ct-post-truck__block">
    <mat-form-field class="app-form-field equipment-field" appearance="fill" floatLabel="always">
      <app-drop-down
        formControlName="equipmentType"
        displayLabel="text"
        key="text"
        placeholder="Select"
        [data]="equipmentList"
        [chooseDefaultOnTab]="true"
        (manualSelect)="onEquipmentTypesSelect()"
      >
      </app-drop-down>
    </mat-form-field>
  </div>

  <div class="ct-post-truck__block ct-post-truck__block--button">
    <button
      class="ct-post-truck__block__button ct-post-truck__block__button__cancel"
      (click)="onCancel($event)"
      data-test="cancel-button"
    >
      Cancel
    </button>
    <button
      #submitButton
      type="submit"
      class="ct-post-truck__block__button ct-post-truck__block__button__submit"
      (click)="onSubmit($event)"
      data-test="post-truck"
    >
      <mat-icon *ngIf="isLoading; else textLabel">
        <mat-spinner diameter="23"></mat-spinner>
      </mat-icon>
      <ng-template #textLabel>Add Truck</ng-template>
    </button>
  </div>
</form>
