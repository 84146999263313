import { AccountRoute, RedirectedRoute, User } from '@haulynx/types';
import { exclusionFilters } from 'libs/types/src/lib/loads-service/exclusion-filters';

/**
 * Checks the user's account and if they should be redirected
 * to a certain page based off the new signup flow
 * @param user
 * @returns
 */
export const userRedirect = (user: User): string => {
  // Haulynx Admins should be able to redirect
  if (user.broker) return null;

  if (user.isHaulynxAdmin) {
    return 'dashboard/fleet';
  }

  if (!user.acceptedTos) {
    return AccountRoute.TERMS_OF_SERVICE;
  }

  if (!user.isVerified) {
    return AccountRoute.VERIFY;
  }

  if (!user.carrier) {
    return AccountRoute.FIND_CARRIER;
  }

  return null;
};

/**
 * Determines what page the user should be sent to by default
 * @param user
 * @returns
 */
export const userHomePage = (user: User): RedirectedRoute => {
  if (!user) return { url: '' };

  const redirect = userRedirect(user);
  /* tslint:disable-next-line */
  return redirect
    ? { url: redirect }
    : user.broker
    ? {
        url: 'loads/search',
        queryParams: {
          bookStatus: ['bookable', 'viewable'],
          notBillTo: exclusionFilters.map((val) => val.key),
        },
      }
    : user.isDriver && !user.isCompanyAdmin
    ? { url: 'dashboard/loads/search/active' }
    : { url: '/loads' };
};
