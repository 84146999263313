import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-appointment-not-set',
  templateUrl: './appointment-not-set.component.html',
  styleUrls: ['./appointment-not-set.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentNotSetComponent {
  @Input() tooltipText: string;
}
