import { Injectable } from '@angular/core';
import {
  AssignStatesToCarrier,
  BulkUpdateZipLanes,
  BulkUpdateZipLanesByQuery,
  GetZipLanesBySearchParameters,
} from '@haulynx/gql';
import { Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import {
  PageAndSort,
  PaginatedData,
  PaginatedZipLaneServiceData,
  ZipLane,
  ZipLaneQueryUpdates,
  ZipLaneSearchParameters,
  ZipLaneUpdate,
} from '@haulynx/types';
import { GraphqlService } from '../graphql.service';

@Injectable({
  providedIn: 'root',
})
export class LanesService {
  constructor(private graphqlService: GraphqlService) {}

  assignStatesToCarrier({ dot, states }: { dot: string; states: string[] }): Observable<any> {
    return this.graphqlService.mutate<any>({
      mutation: AssignStatesToCarrier,
      variables: {
        dot,
        states,
      },
    });
  }

  searchLanes(
    searchParameters: Partial<ZipLaneSearchParameters>,
    paging: Partial<PageAndSort> = {}
  ): Observable<PaginatedData<ZipLane>> {
    return this.graphqlService
      .query<PaginatedData<ZipLane>>({
        query: GetZipLanesBySearchParameters,
        variables: {
          searchParameters: new ZipLaneSearchParameters(searchParameters),
          paging,
        },
      })
      .pipe(
        map((result) => {
          const response: PaginatedZipLaneServiceData<ZipLane> = result.data['getZipLanesBySearchParameters'];
          const temp = {
            data: response.result,
            pagination: response.paginator,
          } as PaginatedData<ZipLane>;
          return temp;
        })
      );
  }

  bulkUpdateLanes(zipLaneUpdates: ZipLaneUpdate): Observable<ZipLane[]> {
    return this.graphqlService
      .mutate({
        mutation: BulkUpdateZipLanes,
        variables: {
          zipLaneUpdates,
        },
      })
      .pipe(map((result) => result.data['bulkUpdateZipLanes']));
  }

  updateLane(zipLaneQueryUpdates: ZipLaneQueryUpdates): Observable<string> {
    return this.graphqlService
      .mutate({
        mutation: BulkUpdateZipLanesByQuery,
        variables: {
          zipLaneQueryUpdates,
        },
      })
      .pipe(map((result) => result.data['bulkUpdateZipLanesByQuery']));
  }
}
