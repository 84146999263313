import gql from 'graphql-tag';

export const SEARCH_FREIGHT_CONTRACTS = gql`
  query SearchFreightContracts($pageParams: FreightContractPageParams, $searchParams: FreightContractSearch) {
    searchFreightContracts(pageParams: $pageParams, searchParams: $searchParams) {
      page
      pages
      pageRowCount
      pageSize
      totalRowCount
      items {
        billTo
        brokerOwner
        customer0
        customer1
        customers {
          shipper {
            city
            state
          }
          consignee {
            city
            state
          }
          billToCustomer {
            name
          }
        }
        brokerOwner
        lineHaulPrice
        carrierDOT
        carrier {
          name
        }
        price
        lineHaulBasis
        fuelPrice
        fuelBasis
        stopPrice
        stopBasis
        pickupTimeDOW
        deliveryTimeDOW
        appointmentSetRequiredCustomer0
        appointmentSetRequiredCustomer1
        appointmentSetStart
        createdAt
        createdBy
      }
    }
  }
`;
