import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'haulynx-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleComponent implements OnInit {
  @Input() text = 'Toggle Text';
  @Input() model: boolean;
  @Input() tabindex = 0;
  @Output() modelChange = new EventEmitter<boolean>();

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: any): void {
    if (event.key === 'Enter') event.target.click();
  }

  constructor() {}

  ngOnInit(): void {}

  public change(change: MatSlideToggleChange): void {
    this.modelChange.emit(change.checked);
  }
}
