<mat-tab-group #tabs class="loads" (selectedTabChange)="crmTabChange($event)">
  <mat-tab *ngFor="let tab of CRMTabs$ | async" [label]="tab.label"></mat-tab>
</mat-tab-group>

<ng-container *ngIf="showSpinner$ | async; else dataTable"> <app-spinner [active]="true"> </app-spinner></ng-container>

<ng-template #dataTable>
  <div class="load-search-container" *ngIf="showTable$ | async">
    <div
      class="search-column"
      [ngStyle]="{ 'margin-left': isSidebarOpen ? '315px' : '75px' }"
      *ngrxLet="currentTabData$ as currentTabData"
    >
      <app-advanced-search
        *ngIf="currentTabData?.metaData.showSearch"
        class="searchbar-container"
        [filters]="filters"
        [setFilters]="searchBarFilters$ | async"
        [recentSearches]="[]"
        [displayRecentSearches]="false"
        [shouldResetTextArrayForm]="shouldResetTextArrayForm"
        (submitSearch)="onSubmitSearch($event, searchQuery)"
        (selectedFilterChange)="onSelectedFilterChange($event)"
      >
      </app-advanced-search>
      <haulynx-data-table-calendar
        class="table-calendar"
        *ngIf="currentTabData?.metaData.showCalendar"
        [loads]="calendarData$ | async"
        [selectItem]="setCalendarSelection$ | async"
        (clickCalendarDate)="calendarDateClicked($event)"
      ></haulynx-data-table-calendar>

      <div *ngrxLet="loadLockState$ as loadLockState" class="data-tables" #parent>
        <data-table-v2
          [data]="tableData$ | async"
          [tableConfig]="currentTabData?.metaData?.configOptions"
          [emptyMessage]="'No Loads Found'"
          [displayedColumns]="columns"
          [templateCellRef]="templateRef"
          [parentToTable]="parent"
          [loading]="dataLoading$ | async"
          [entitiesLoading]="loadEntityService.bulkUpdateLoadsManager.isLoadingEntities$ | async"
          [selectedFilters]="searchViewFilters$ | async"
          [selectedSidebarItem]="selectedSidebarItem"
          [isSearchView]="isSearchView"
          [selectionMode]="null"
          [stickyColumnWidth]="stickyColumnWidth"
          [scrollHeight]="'703px'"
          (onRowHover)="onRowHover($event)"
          (onSelect)="onRowSelect($event)"
          (bulkAction)="onBulkAction($event)"
          (page)="onPage($event)"
          (sort)="onSort($event, { payload: searchQuery })"
          (columnsChange)="onColumnsChange($event)"
          (displayDataChanged)="onDataTableViewChange($event)"
          data-test="loads-table"
        >
          <ng-template
            #templateRef
            let-row="row"
            let-column="column"
            let-hovering="isHovering"
            let-width="columnWidth"
            let-index="rowIndex"
            let-count="rowCount"
          >
            <td [ngStyle]="{ width: width }" *ngIf="column.field === 'originDeadHead'" (click)="onRowSelect(row)">
              <div class="customer-name">{{ row.odh }} mi</div>
            </td>

            <td [ngStyle]="{ width: width }" *ngIf="column.field === 'status'" (click)="onRowSelect(row)">
              <div class="load-status-container">
                <mat-icon [svgIcon]="row | loadStatusIcon"></mat-icon>
                <div style="margin-left: 10px" data-test="load-status">
                  {{ row.loadStatus | transformLoadStatus: row.bookStatus | fromCamelCase }}
                </div>
              </div>
            </td>

            <td
              class="origin-dest-container"
              [ngStyle]="{ width: width }"
              *ngIf="column.field === 'originAndDestination'"
              (click)="onRowSelect(row)"
            >
              <app-load-search-timeline [load]="row"></app-load-search-timeline>
            </td>

            <td [ngStyle]="{ width: width }" *ngIf="column.field === 'destinationDeadHead'" (click)="onRowSelect(row)">
              <div class="customer-name">
                {{ row.ddh >= 0 ? row.ddh : 'N/A' }}
              </div>
            </td>

            <td [ngStyle]="{ width: width }" *ngIf="column.field === 'equipment'" (click)="onRowSelect(row)">
              <div class="equipment-container">
                <div class="equipment-name" data-test="load-equipment">
                  {{ row.providerDetails?.equipmentType | fromCamelCase }}
                </div>
                <div class="commodity-name" data-test="load-weight">
                  <!-- {{row.providerDetails.commodity || 'Unknown'}} /  -->
                  {{ row.providerDetails?.weight }}
                  <span *ngIf="row.providerDetails?.weight">lbs</span>
                </div>
                <div class="equipment-icon">
                  <mat-icon
                    *ngIf="row.restrictions | loadRestrictions: 'hazmat'"
                    [svgIcon]="'haulynx-hazmat'"
                    matTooltip="This shipment incudes hazardous material"
                  ></mat-icon>
                  <mat-icon
                    *ngIf="row.restrictions | loadRestrictions: 'tankerCertificationRequired'"
                    [svgIcon]="'haulynx-tanker'"
                    matTooltip="This shipment is tanker certified"
                  ></mat-icon>
                </div>
              </div>
            </td>

            <td [ngStyle]="{ width: width }" *ngIf="column.field === 'carrier'" (click)="onRowSelect(row)">
              <div class="equipment-container">
                <div class="equipment-name" data-test="carrier-name">
                  {{ row.carrier?.name }}
                </div>
                <div class="commodity-name" data-test="dot-name">
                  {{ row.carrier?.dot }}
                </div>
              </div>
            </td>

            <td [ngStyle]="{ width: width }" *ngIf="column.field === 'customer'" (click)="onRowSelect(row)">
              <div class="customer-name" data-test="load-bill-to-name">
                {{ row.providerDetails?.billToName }}
              </div>
            </td>

            <td [ngStyle]="{ width: width }" *ngIf="column.field === 'tmwAndOrder'" (click)="onRowSelect(row)">
              <div class="tmw-order-container">
                <div class="tmw-number" data-test="load-tmw-number">#{{ row | loadAlternateId: 'tmwNumber' }}</div>
                <div class="order-number" data-test="load-order-number">#{{ row | loadAlternateId: 'orderNumber' }}</div>
              </div>
            </td>

            <td [ngStyle]="{ width: width }" *ngIf="column.field === 'trailerInstructions'" (click)="onRowSelect(row)">
              <div class="trailer-instruction-container" data-test="trailer-instructions">
                <div
                  *ngIf="{
                    pickupInstructions: row.restrictions | loadRestrictions: 'pickupRequirements',
                    dropOffInstructions: row.restrictions | loadRestrictions: 'dropoffRequirements'
                  } as instructions"
                >
                  <div
                    class="instruction-name"
                    *ngIf="
                      instructions.pickupInstructions || instructions.dropOffInstructions;
                      else noTrailerInstructions
                    "
                  >
                    {{ row.restrictions | loadRestrictions: 'pickupRequirements' }} /
                    {{ row.restrictions | loadRestrictions: 'dropoffRequirements' }}
                  </div>
                  <ng-template #noTrailerInstructions> Not available </ng-template>
                </div>
              </div>
            </td>

            <td [ngStyle]="{ width: width }" *ngIf="column.field === 'distanceMiles'" (click)="onRowSelect(row)">
              <div class="customer-name" data-test="load-distance">
                {{ row.paymentDetails?.distanceMiles }}
              </div>
            </td>

            <td [ngStyle]="{ width: width }" *ngIf="column.field === 'priceMaxBuyRevenue'" (click)="onRowSelect(row)">
              <div class="price-revenue-container">
                <div class="price">
                  <app-load-price
                    *ngIf="selectedTab.id !== 'finalled'; else finalPrice"
                    [price]="row.price ? row.price : row.paymentDetails?.price"
                    [loadId]="row.id"
                    [editablePrice]="true"
                    data-test="load-price"
                  ></app-load-price>
                  <ng-template #finalPrice>
                    <div *ngIf="row.paymentDetails.price | currency as price">
                      {{ price === '$0.00' ? 'Unknown' : price }}
                    </div>
                  </ng-template>
                </div>
                <div class="maxbuy-and-revenue" data-test="load-revenue">
                  {{ (row.paymentDetails?.revenue | currency) || '?' }}
                </div>
              </div>
            </td>

            <td [ngStyle]="{ width: width }" *ngIf="column.field === 'dateAdded'" (click)="onRowSelect(row)">
              <div class="customer-name">
                {{ row.created | dateTime: row.locations[0].timezone:'MM/dd/yyy' }}
              </div>
            </td>

            <td [ngStyle]="{ width: width }" *ngIf="column.field === 'bids'" (click)="onRowSelect(row)">
              <app-load-bid-details [row]="row" data-test="bid-details"></app-load-bid-details>
            </td>

            <td [ngStyle]="{ width: width }" *ngIf="column.field === 'inboundRegion'" (click)="onRowSelect(row)">
              <div class="customer-name">
                {{ row.region }}
              </div>
            </td>

            <td [ngStyle]="{ width: width }" *ngIf="column.field === 'cxrId'" (click)="onRowSelect(row)">
              <div class="customer-name" data-test="load-cxr-id">
                {{ row.providerDetails?.cxrEmployeeId }}
              </div>
            </td>

            <td [ngStyle]="{ width: width }" *ngIf="column.field === 'actions'">
              <span class="actions-container">
                <app-bulk-actions
                  *ngIf="row.bookStatus !== 'booked' && row.bookStatus !== 'pause'"
                  [showTitle]="false"
                  [loads]="[row]"
                  [bulkActionsPage]="currentTabData?.metaData?.configOptions?.bulkActions"
                  (action)="onBulkAction($event)"
                ></app-bulk-actions>
                <button
                  mat-icon-button
                  data-test="send-email"
                  class="email-button"
                  (click)="sendEmail(row); $event.stopPropagation()"
                  *ngIf="row | dedicatedAllowed: features"
                >
                  <mat-icon svgIcon="haulynx-mail"></mat-icon>
                </button>
                <ng-container *ngIf="row | dedicatedAllowed: features">
                  <ng-container *ngIf="!(loadLockState[row?.id] | isLoadLockedPipe); else lockedButton">
                    <button class="book-load" (click)="openBookLoadDialog(row)">Book</button>
                  </ng-container>
                </ng-container>
                <ng-template #lockedButton>
                  <button class="book-locked" [matTooltip]="loadLockState[row?.id] | transformBookToolTipPipe">
                    Locked
                  </button>
                </ng-template>
              </span>
            </td>
          </ng-template>
        </data-table-v2>
      </div>
    </div>
  </div>
  <div class="no-content" *ngIf="!(showTable$ | async)">No Records Found</div>
</ng-template>
