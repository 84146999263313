export interface RolledLoads {
  loadIds: string[];
  rolledReason: RolledReason;
}

export interface RolledReason {
  author: string;
  createdAt: number;
  rolledCode: RolledReasonCode;
  rolledCodeDescription?: string;
  rolledComment: string;
}

export enum RolledReasonCode {
  WEATHER = 'weather',
  ORIGIN_VOLUME = 'origin_volume',
  RATE = 'rate',
  BOUNCE_GENERAL = 'bounce_general',
  BOUNCE_BREAKDOWN = 'bounce_breakdown',
  LOW_LEAD_TIME = 'low_lead_time',
  CAPACITY = 'capacity',
  NO_QUALIFIED_CARRIERS = 'no_qualified_carriers',
  NO_USX_INTERCHANGE_CARRIERS = 'no_usx_interchange_Carriers',
  APPOINTMENTS = 'appointments',
  SHIPPER_RELATED_ISSUE = 'shipper_related_issue',
  TRANSIT_RELATED_ISSUE = 'transit_related_issue',
  OTHER_CARRIER_RELATED = 'other_carrier_related',
  OTHER_CX_RELATED = 'other_cx_related',
}
