import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@haulynx/modules';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SidebarNavComponent } from './sidebar-nav.component';
import { SidebarNavTabsModule } from './sidebar-nav-tabs/sidebar-nav-tabs.module';
import { SidebarNavSubAccountModule } from './sidebar-nav-sub-account/sidebar-nav-sub-account.module';
import { SidebarNavSubAccountSetupModule } from './sidebar-nav-sub-account-setup/sidebar-nav-sub-account-setup.module';
import { MatDividerModule } from '@angular/material/divider';
import { SidebarNavSubTemplateModule } from './sidebar-nav-sub-template/sidebar-nav-sub-template.module';
import { PipesModule } from '@haulynx/pipes';

import { HeaderEndbuttonsAccountSetupModule } from '../header-toolbar/header-endbuttons/header-endbuttons-account-setup/header-endbuttons-account-setup.module';

@NgModule({
  declarations: [SidebarNavComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    RouterModule,
    SidebarNavTabsModule,
    MatDividerModule,
    SidebarNavSubAccountModule,
    SidebarNavSubAccountSetupModule,
    SidebarNavSubTemplateModule,
    HeaderEndbuttonsAccountSetupModule,
    PipesModule,
  ],
  exports: [SidebarNavComponent],
})
export class SidebarNavModule {}
