import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@haulynx/modules';
import { SpinnerModule } from '../spinner/spinner.module';
import { AtomicTableComponent } from './atomic-table.component';

@NgModule({
  declarations: [AtomicTableComponent],
  exports: [AtomicTableComponent],
  imports: [CommonModule, SpinnerModule, MaterialModule, FlexLayoutModule],
})
export class AtomicTableModule {}
