import { EquipmentType, LoadsServiceEquipmentType } from '../../loads-service/loads-service-provider';
import { KeyValuePair } from '../generic/key-value-pair';

export interface KeyValueSelected extends KeyValuePair {
  selected?: boolean;
}

export const carrierLoadSearchEquipmentOptions: KeyValueSelected[] = [
  { key: EquipmentType.DRY_VAN, value: LoadsServiceEquipmentType.DRY_VAN },
  { key: EquipmentType.REEFER, value: LoadsServiceEquipmentType.REEFER },
  { key: EquipmentType.FLAT_BED, value: LoadsServiceEquipmentType.FLAT_BED },
];
