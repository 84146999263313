export class LoadsServicePoint {
  type: string;
  coordinates: number[];

  constructor(props: Partial<LoadsServicePoint>) {
    Object.assign(this, props);
  }
}

export class LoadsServicePointInput {
  type: string;
  coordinates: number[];
}
