import gql from 'graphql-tag';

export const CreateDriverMutation = gql`
  mutation CreateDriver(
    $name: NonBlankString!
    $phone: String
    $email: EmailAddress!
    $enrollmentStatus: EnrollmentStatus!
    $carrier: DriverCarrier!
  ) {
    createDriver(name: $name, phone: $phone, email: $email, enrollmentStatus: $enrollmentStatus, carrier: $carrier) {
      message
      code
      data {
        email
        id
      }
    }
  }
`;
