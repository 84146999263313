import { Pipe, PipeTransform } from '@angular/core';
import { formatDistance } from 'date-fns';

@Pipe({ name: 'formatDistance' })
export class FormatDistancePipe implements PipeTransform {
  transform(
    date: Date | number,
    baseDate: Date | number,
    options?: { includeSeconds?: boolean; addSuffix?: boolean; locale?: unknown }
  ): string {
    return date ? formatDistance(date, baseDate, options) : '';
  }
}
