import { LoadsServiceLoadLocation } from '../../loads-service';

export interface CarrierActiveStop {
  loadId: string;
  location: LoadsServiceLoadLocation;
  locationIndex: number;
  status: CarrierActiveStopStatus;
}

export enum CarrierActiveStopStatus {
  ASSIGN = 'assign',
  ARRIVAL = 'arrival',
  DEPARTURE = 'departure',
  COMPLETED = 'completed',
}

export interface CarrierActiveStopInput {
  loadId: string;
  locationIndex: number;
  arrivalTime?: number;
  departureTime?: number;
}
