import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { DataTableV2Service } from '@haulynx/services';
import { DataTableType, EntityType, ISortingConfig, OrderByParams, PaginationTableConfig } from '@haulynx/types';
import { Record } from 'immutable';
import { Many, toLower } from 'lodash';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'haulynx-pagination-table',
  templateUrl: './pagination-table.component.html',
  styleUrls: ['./pagination-table.component.scss'],
})
export class PaginationTableComponent implements OnInit, OnChanges {
  @Input('paginationData') _paginationData: {
    currentPage: number;
    total: number;
    totalPages: number;
  } = null;
  @Input() data: DataTableType<EntityType> = [];
  @Input() tableConfig?: PaginationTableConfig;
  @Input() loading = true;
  @Input() templateCellRef: TemplateRef<Record<EntityType>>;
  @Input() showBottomPaginator = false;
  @Input() buttonSort? = false;

  @Output() page = new EventEmitter<{ pageUp: boolean; currentPage: number; limit: number }>();

  dataSource: EntityType[] = [];
  showingRowCountOnPaginator = 10;
  sortBy = '';
  private localPaginationData: {
    currentPage: number;
    total: number;
    totalPages: number;
  };
  private localSortConfig: OrderByParams<any> = null;
  private _sortedData: EntityType[] = null;
  sortingVal = new BehaviorSubject('Newest');

  constructor(private dataTableService: DataTableV2Service) {}

  ngOnInit(): void {
    this.dataSource = this.dataTableService.getData(this.data);
    this.onSelectOption({
      config: {
        dataIndex: 'createdAtTs',
        emitSort: false,
        label: 'Time Created',
      },
      type: 'DESC',
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      if (this.localSortConfig) {
        this._sortedData = this.dataTableService.sortData(
          this.dataTableService.getData(this.data),
          this.localSortConfig
        );
      } else {
        this.dataSource = this.dataTableService.getData(this.data);
      }
      this.pageData = this.dataTableService.getPaginationData(this.sortedData, this.tableConfig.pageAmount);
    }
  }

  get paginationData(): {
    currentPage: number;
    total: number;
    totalPages: number;
  } {
    if (this._paginationData) {
      return this._paginationData;
    } else {
      return this.localPaginationData;
    }
  }

  set paginationData(data: { currentPage: number; total: number; totalPages: number }) {
    this._paginationData = data;
  }

  get sortedData(): EntityType[] {
    if (this.localSortConfig) {
      return this._sortedData;
    } else {
      return this.dataTableService.getData(this.data);
    }
  }

  set pageData(data: {
    currentPageData: EntityType[];
    pagination: {
      currentPage: number;
      total: number;
      totalPages: number;
    };
  }) {
    ({ pagination: this.localPaginationData, currentPageData: this.dataSource } = data);
  }

  onPage(event: boolean): void {
    if (this._paginationData) {
      this.page.emit({
        pageUp: event,
        currentPage: this._paginationData.currentPage,
        limit: this.tableConfig.pageAmount,
      });
    } else {
      const currentPage = event ? this.localPaginationData.currentPage + 1 : this.localPaginationData.currentPage - 1;

      this.pageData = this.dataTableService.getPaginationData(
        this.sortedData,
        this.tableConfig.pageAmount,
        currentPage
      );
    }
  }

  getRowCount(): number {
    return this.dataTableService.getData(this.dataSource).length;
  }

  onSelectOption(config: { config: ISortingConfig; type: 'ASC' | 'DESC' }): void {
    const isOnlySortLocally = this.paginationData ? this.paginationData.total === this.getRowCount() : true;
    if (config.config.emitSort && !isOnlySortLocally) {
      // this.sort.emit({ config: config.config, sortType: config.type, page: 1 });
    } else if (!config.config.dataIndex) {
      if (config.config.defaultOrder && !config.type) {
        this.localSortConfig = [
          config.config.orderByIndex,
          toLower(config.config.defaultOrder) as Many<boolean | 'asc' | 'desc'>,
        ];
      } else {
        this.localSortConfig = [config.config.orderByIndex, toLower(config.type) as Many<boolean | 'asc' | 'desc'>];
      }
      this._sortedData = this.dataTableService.sortData(this.dataTableService.getData(this.data), this.localSortConfig);
      this.pageData = this.dataTableService.getPaginationData(this.sortedData, this.tableConfig.pageAmount);
    } else {
      const data = this.dataTableService.getData(this.dataSource);
      let dataIndex;
      if (config.config.emitSort) {
        const temp = config.config.dataIndex.split('?');
        if (temp.length < 2) {
          console.error(
            'When emit = true, dataIndex format should be <ASC order type>~<DSC order type>?<localSort config>'
          );
        } else {
          dataIndex = temp[1].split('.');
        }
      } else {
        dataIndex = config.config.dataIndex.split('.');
      }
      this.sortBy = dataIndex;
      this.dataSource = data.slice().sort((data1: EntityType, data2: EntityType) => {
        const value1 = this.getDataValue(data1, dataIndex);
        const value2 = this.getDataValue(data2, dataIndex);
        let result = null;
        if (!value1 && value2) result = 1;
        else if (!value2 && value1) result = -1;
        else if (!value1 && !value2) result = 0;
        else if (typeof value1 === 'string') result = value1.localeCompare(value2);
        else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
        return config.type === 'ASC' ? 1 * result : -1 * result;
      });
      this.sortingVal.next(config.type === 'ASC' ? 'Oldest' : 'Newest');
      // this.displayDataChanged.emit(this.dataSource);
    }
  }

  getDataValue(data: EntityType, dataIndex: string[]): string {
    let finalValue;
    let tempValue;
    dataIndex.forEach((value: string, index: number) => {
      if (parseInt(value, 10)) {
        tempValue = tempValue[parseInt(value, 10)];
      } else if (value === 'length') {
        tempValue = tempValue[tempValue.length - 1];
      } else if (value.includes('where')) {
        const whereClause = value.split('-')[1];
        const props = whereClause.split('=');
        tempValue = tempValue.find((v) => v[props[0]] === props[1]);
      } else if (value.includes('last')) {
        tempValue = tempValue[tempValue.length - 1];
      } else {
        if (index === dataIndex.length - 1 && dataIndex.length === 1) {
          finalValue = data[value];
        } else if (index === dataIndex.length - 1 && dataIndex.length > 1) {
          if (tempValue) finalValue = tempValue[value];
          else finalValue = null;
        } else if (!tempValue) {
          tempValue = data[value];
        } else {
          tempValue = tempValue[value];
        }
      }
    });
    return finalValue;
  }
}
