import { Injectable } from '@angular/core';
import { NotificationsService, SearchViewService } from '@haulynx/services';
import { AsyncBulkMutationManager, AsyncMutationManager, AsyncSearchManager, SearchView } from '@haulynx/types';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { AppState } from '../../main-store/app.reducers';
import { AsyncEntityBase } from '../../main-store/async-entity/async-entity-base.class';
import { searchViewEntityNamespace } from './namespace';

@Injectable({ providedIn: 'root' })
export class SearchViewEntityService extends AsyncEntityBase<SearchView> {
  getSearchViewByUserIdManager: AsyncSearchManager<SearchView, string>;
  createSearchViewManager: AsyncMutationManager<{ newSearchView: SearchView; userId: string }, SearchView>;
  deleteSearchViewManager: AsyncMutationManager<{ searchViewId: string; userId: string }, boolean>;
  bulkUpdateSearchViewsManager: AsyncBulkMutationManager<{ searchViews: SearchView[]; userId: string }, SearchView[]>;
  getSavedViewUserIdManager: AsyncSearchManager<SearchView, string>;

  constructor(
    protected actions$: Actions,
    protected store: Store<AppState>,
    private searchViewService: SearchViewService,
    private notifications: NotificationsService
  ) {
    super(actions$, store, searchViewEntityNamespace);

    this.getSearchViewByUserIdManager = this.createAsyncSearchQuery('get search views', (searchPayload) =>
      this.searchViewService.getSearchViewsByUserId(searchPayload.query)
    );

    this.createSearchViewManager = this.createAsyncMutation(
      'create search view',
      (payload) =>
        this.searchViewService.createSearchView(payload.newSearchView, payload.userId).pipe(
          tap(
            () => {
              this.notifications.success('Successfully created search view', 'Success!');
            },
            (error) => {
              this.notifications.error('Please try again later', 'Error creating search view');
              console.error(error);
            }
          )
        ),
      { refreshEntityFrom: 'response' }
    );

    this.deleteSearchViewManager = this.createAsyncMutation(
      'delete search view',
      (payload) =>
        this.searchViewService.deleteSearchView(payload.searchViewId, payload.userId).pipe(
          tap(
            () => {
              this.notifications.success('Successfully deleted search view', 'Success!');
            },
            (error) => {
              this.notifications.error('Please try again later', 'Error deleting search view');
              console.error(error);
            }
          )
        ),
      { refreshEntityFrom: 'response' }
    );

    this.bulkUpdateSearchViewsManager = this.createBulkAsyncMutation(
      'update search views',
      (payload) =>
        this.searchViewService.bulkUpdateSearchViews(payload.searchViews, payload.userId).pipe(
          tap(
            () => {
              this.notifications.success('Successfully updated search views', 'Success!');
            },
            (error) => {
              this.notifications.error('Please try again later', 'Error updating search views');
              console.error(error);
            }
          )
        ),
      { refreshEntityFrom: 'response' }
    );
  }
}
