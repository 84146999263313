export enum AccountType {
  BROKER = 'broker',
  BROKER_ADMINISTRATOR = 'broker_admin',
  /** @deprecated */
  CARRIER = 'carrier',
  CARRIER_ADMINISTRATOR = 'carrier_admin',
  DRIVER = 'driver',
  HAULYNX_ADMINISTRATOR = 'haulynx_admin',
  /** @deprecated */
  SHIPPER = 'shipper',
  UNKNOWN = 'unknown',
  USER = 'user',
}

export interface UserValidationResult {
  code: number;
  message: string;
  valid: boolean;
}

export enum EditableUserProperties {
  IS_VERIFIED = 'isVerified',
  IS_ADMIN = 'isCompanyAdmin',
  IS_DRIVER = 'isDriver',
  PC_ENABLED = 'pcEnabled',
  YM_ENABLED = 'ymEnabled',
  TC_ENABLED = 'trailerCreationEnabled',
  TD_ENABLED = 'teamDrivingEnabled',
}
