import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@haulynx/modules';
import { SpinnerModule } from '../spinner/spinner.module';
import { AppListComponent } from './app-list.component';

@NgModule({
  declarations: [AppListComponent],
  exports: [AppListComponent],
  imports: [CommonModule, SpinnerModule, MaterialModule],
})
export class AppListModule {}
