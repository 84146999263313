import { Injectable } from '@angular/core';
import {
  AddPageToReleaseNote,
  CreateReleaseNoteMutation,
  EditReleaseNoteMutation,
  GetLatestUnreadReleaseNotes,
  GetReleaseNotes,
  GetReleaseNotesById,
  MarkReleaseNotesAsViewedMutation,
} from '@haulynx/gql';
import { ReleaseNote, ReleaseNotesInput, WhatsNewPage } from '@haulynx/types';
import { get } from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, delay, finalize, map } from 'rxjs/operators';
import { NotificationsService } from '../../notifications/notifications.service';
import { GraphqlService } from '../graphql.service';

@Injectable({
  providedIn: 'root',
})
export class ReleasesService {
  isLoadingList$: Observable<boolean>;
  isLoadingDetails$: Observable<boolean>;
  isLoadingCreate$: Observable<boolean>;

  private isLoadingList = new BehaviorSubject<boolean>(false);
  private isLoadingDetails = new BehaviorSubject<boolean>(false);
  private isLoadingCreate = new BehaviorSubject<boolean>(false);

  constructor(private graphqlService: GraphqlService, private notificationsService: NotificationsService) {
    this.isLoadingList$ = this.isLoadingList.asObservable().pipe(delay(0));
    this.isLoadingDetails$ = this.isLoadingDetails.asObservable().pipe(delay(0));
    this.isLoadingCreate$ = this.isLoadingCreate.asObservable();
  }

  createReleaseNote(variables: { newReleaseNote: Partial<ReleaseNote> }): Observable<ReleaseNote> {
    this.isLoadingCreate.next(true);

    return this.graphqlService
      .mutate<ReleaseNote>({
        variables,
        mutation: CreateReleaseNoteMutation,
      })
      .pipe(
        map((result) => get(result, 'data.createReleaseNote', {})),
        finalize(() => this.isLoadingCreate.next(false)),
        catchError(() => {
          this.notificationsService.error(`Error creating release note`, `Release Notes`);
          return of(null);
        })
      );
  }

  editReleaseNote(variables: {
    releaseNoteId: string;
    releaseNoteFields: Partial<ReleaseNote>;
  }): Observable<ReleaseNote> {
    this.isLoadingCreate.next(true);

    return this.graphqlService
      .mutate({
        variables,
        mutation: EditReleaseNoteMutation,
      })
      .pipe(
        map((result) => get(result, 'data.editReleaseNote', {})),
        finalize(() => this.isLoadingCreate.next(false)),
        catchError(() => {
          this.notificationsService.error(`Error updating release note`, `Release Notes`);
          return of(null);
        })
      );
  }

  addPageToReleaseNote(variables: { releaseNoteId: string; page: WhatsNewPage }): Observable<ReleaseNote> {
    this.isLoadingCreate.next(true);

    return this.graphqlService
      .mutate({
        variables,
        mutation: AddPageToReleaseNote,
      })
      .pipe(
        map((result) => get(result, 'data.addWhatsNewPageToReleaseNote', {})),
        catchError(() => {
          this.notificationsService.error(`Error adding pages to release note`, `Release Notes`);
          return of(null);
        })
      );
  }

  getReleaseNotes(variables: ReleaseNotesInput): Observable<ReleaseNote[]> {
    this.isLoadingList.next(true);

    return this.graphqlService
      .query({
        variables: { ...variables },
        query: GetReleaseNotes,
      })
      .pipe(
        map((result) => get(result, 'data.getReleaseNotes', {})),
        finalize(() => this.isLoadingList.next(false)),
        catchError(() => {
          this.notificationsService.error(`Error getting release notes`, `Release Notes`);
          return of(null);
        })
      );
  }

  getReleaseNotesById(variables: { releaseNoteId: string; userId?: string }): Observable<ReleaseNote> {
    this.isLoadingDetails.next(true);

    return this.graphqlService
      .query({
        variables,
        query: GetReleaseNotesById,
      })
      .pipe(
        map((result) => get(result, 'data.getReleaseNotesById', {})),
        finalize(() => this.isLoadingDetails.next(false)),
        catchError(() => {
          this.notificationsService.error(`Error getting release note`, `Release Notes`);
          return of(null);
        })
      );
  }

  getLatestUnreadReleaseNotes(variables: { userId: string }): Observable<ReleaseNote> {
    return this.graphqlService
      .query({
        variables,
        query: GetLatestUnreadReleaseNotes,
      })
      .pipe(
        map((result) => get(result, 'data.getLatestUnreadReleaseNotes', {})),
        catchError(() => of(null))
      );
  }

  markReleaseNotesAsViewed(variables: { userId: string; releaseNoteId: string }): Observable<boolean> {
    return this.graphqlService
      .mutate({
        variables,
        mutation: MarkReleaseNotesAsViewedMutation,
      })
      .pipe(
        map((result) => get(result, 'data.markReleaseNotesAsViewed', {})),
        catchError(() => of(null))
      );
  }
}
