<div class="banner-padding">
  <div class="banner-container">
    <div class="icon-combiner">
      <span>
        <mat-icon svgIcon="missions-icon"></mat-icon>
      </span>
    </div>
    <!-- Load Details Banner -->
    <div *ngIf="currentView === 'loadDetails'">
      <div class="text-container">
        This load is part of a mission. The corresponding mission number is<button
          class="button"
          (click)="navigateToLoad('mission')"
          data-test="mission-number"
        >
          {{ missionId }}.
        </button>
        <span *ngIf="isHeadHaul">
          This load is a headhaul. View the link to<button
            class="button"
            (click)="navigateToLoad('backHaulDetails')"
            data-test="view-backhaul"
          >
            the backhaul</button
          >here.
        </span>
        <span *ngIf="isBackHaul">
          This load is a backhaul. View the link to<button
            class="button"
            (click)="navigateToLoad('headHaulDetails')"
            data-test="view-headhaul"
          >
            the headhaul</button
          >here.
        </span>
      </div>
    </div>
    <!-- Tracking and Milestones Banner -->
    <div *ngIf="currentView === 'trackingMilestones'">
      <div class="text-container">
        This load is part of a mission. The corresponding mission number is<button
          class="button"
          (click)="navigateToLoad('mission')"
        >
          {{ missionId }}.
        </button>
        <span *ngIf="isHeadHaul">
          Once this load finishes view tracking for
          <button class="button" (click)="navigateToLoad('backHaulTracking')">backhaul here.</button>
        </span>
        <span *ngIf="isBackHaul">
          View previous tracking for
          <button class="button" (click)="navigateToLoad('headHaulTracking')">headhaul here.</button>
        </span>
      </div>
    </div>
    <!-- Booking Details Banner -->
    <div *ngIf="currentView === 'bookingDetails'">
      <div class="text-container">
        This load is part of a mission. The corresponding mission number is<button
          class="button"
          (click)="navigateToLoad('mission')"
        >
          {{ missionId }}.
        </button>
        <span *ngIf="isHeadHaul">
          Booking this load also books load<button class="button" (click)="navigateToLoad('backHaulBooking')">
            {{ backHaulLoad | loadAlternateId: loadIdentifierType.ORDER_NUMBER }}</button
          >here.
        </span>
        <span *ngIf="isBackHaul">
          Booking this load also books load<button class="button" (click)="navigateToLoad('headHaulBooking')">
            {{ headHaulLoad | loadAlternateId: loadIdentifierType.ORDER_NUMBER }}</button
          >here.
        </span>
      </div>
    </div>
  </div>
</div>
