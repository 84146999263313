import { Bid, BidStatusType } from '@haulynx/types';
import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-carrier-bid-chips',
  templateUrl: './carrier-bid-chips.component.html',
  styleUrls: ['./carrier-bid-chips.component.scss'],
})
export class CarrierBidChipsComponent implements OnChanges {
  @Input() bid: Bid;

  public bidText: string;
  public bidPrice: number;
  public class: string;

  constructor() {}

  ngOnChanges() {
    this.setInformation();
  }

  private setInformation() {
    if (this.bid.status === BidStatusType.AUTO_REJECTED) {
      this.bidPrice = null;
      this.bidText = 'Bid Lost';
      this.class = 'rejected';
    } else if (this.bid.status === BidStatusType.ACCEPTED) {
      this.bidPrice = this.bid.price;
      this.bidText = 'Bid Accepted';
      this.class = 'accepted';
    } else if (this.bid.status === BidStatusType.PRICE_REJECTED || this.bid.status === BidStatusType.NO_CAPACITY) {
      this.bidPrice = this.bid.price;
      this.bidText = 'Bid Rejected';
      this.class = 'rejected';
    } else if (this.bid.activeCounterOffer) {
      this.bidPrice = this.bid.activeCounterOffer.price;
      this.bidText = 'Counter Received';
      this.class = 'counter';
    } else {
      this.bidPrice = this.bid.price;
      this.bidText = 'Bid Placed';
      this.class = 'placed';
    }
  }
}
