<div class="virtual-scroll">
  <cdk-virtual-scroll-viewport [itemSize]="size" (scrolledIndexChange)="scrollIndex($event)">
    <ng-container *cdkVirtualFor="let item of data">
      <ng-container
        *ngTemplateOutlet="
          customReference;
          context: {
            data: item
          }
        "
      >
      </ng-container>
    </ng-container>
    <ng-container *ngTemplateOutlet="customButtonReference"></ng-container>
  </cdk-virtual-scroll-viewport>
</div>
