import { Component, forwardRef, Input, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { listToArray, mapToArray } from '@haulynx/utils';
import { List, Map } from 'immutable';
import { find, get, isString } from 'lodash';

@Component({
  selector: 'app-list',
  templateUrl: './app-list.component.html',
  styleUrls: ['./app-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AppListComponent),
      multi: true,
    },
  ],
})
export class AppListComponent implements OnChanges, ControlValueAccessor {
  @Input() data;
  @Input() loading;
  @Input() label;
  @Input() displayLabel = 'label';
  @Input() key = 'id';
  @Input() templateLabel: TemplateRef<any>;

  control: FormControl = new FormControl();
  itemSelected = null;
  items = [];

  propagateChanges: Function = () => {};

  select(item) {
    const id = item[this.key];

    this.itemSelected = item;
    this.propagateChanges(id);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { data, selected } = changes;

    if (data) {
      if (List.isList(data.currentValue)) {
        this.items = listToArray(data.currentValue);
      } else if (Map.isMap(data.currentValue)) {
        this.items = mapToArray(data.currentValue);
      } else {
        this.items = data.currentValue || [];
      }
    }

    if (selected) {
      this.itemSelected = selected;
    }
  }

  updateSelected(data, selected) {
    let item;
    const items = this.getData(data);

    if (isString(selected)) {
      item = find(items, { [this.key]: selected });
    } else {
      item = find(items, { [this.key]: get(selected, this.key) });
    }

    return item;
  }

  getData(data) {
    return List.isList(data) ? listToArray(data) : Map.isMap(data) ? mapToArray(data) : data;
  }

  registerOnChange(fn: any): void {
    this.propagateChanges = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.control.enable() : this.control.disable();
  }

  writeValue(selected: any): void {
    const selectedItem = this.updateSelected(this.items, selected);

    this.itemSelected = selectedItem || null;
    this.control.patchValue(this.itemSelected);
  }
}
