import gql from 'graphql-tag';

const Bidfragment = {
  price: gql`
    fragment BidPrice on Bid {
      price
    }
  `,
};

export const GetBidPriceById = gql`
  query GetBidById($bidId: StrictId!) {
    getBidById(bidId: $bidId) {
      ...BidPrice
    }
  }
  ${Bidfragment.price}
`;
