import { AsyncManagerType, IAsyncEntityState } from '@haulynx/types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ActionCreator, createAction, on, props, ReducerTypes } from '@ngrx/store';
import { camelCase, endsWith, groupBy, keyBy, keys, map as _map, reduce } from 'lodash';
import { iif, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { MutationBulkAction } from '../types/mutation-bulk-action';
import { AsyncEntityActionFactory } from './async-entity-action-factory';

export class AsyncEntityBulkMutationFactory<
  ActionPayloadType,
  ResponseData,
  ErrorPayloadType
> extends AsyncEntityActionFactory<ActionPayloadType, ResponseData, ErrorPayloadType> {
  public managerType = AsyncManagerType.BULK_MUTATION;
  public action = createAction(this.type, props<{ entityIds: string[]; payload: ActionPayloadType }>());
  public actionSuccess = createAction(this.typeSuccess, props<{ entityIds: string[]; payload: ResponseData }>());
  public actionError = createAction(this.typeError, props<{ entityIds: string[]; payload: ErrorPayloadType }>());

  private options: AsyncEntityBulkMutationOptions;

  constructor(
    namespace: string,
    name: string,
    effectCall: (payload: ActionPayloadType) => Observable<ResponseData>,
    options: AsyncEntityBulkMutationOptions = {}
  ) {
    super(namespace, name, effectCall);
    this.options = options;
  }

  public getReducers(): ReducerTypes<IAsyncEntityState<ResponseData>, any>[] {
    return [
      on(this.action, (state: IAsyncEntityState<ResponseData>, action: MutationBulkAction<ActionPayloadType>) => {
        return {
          ...state,
          isLoadingEntities: {
            ...state.isLoadingEntities,
            ...this.getEntitesLoading(action.entityIds, true),
          },
          [this.mutationIsLoadingName]: true,
        };
      }),
      on(this.actionSuccess, (state: IAsyncEntityState<ResponseData>, action: MutationBulkAction<ResponseData>) => {
        const entitiesAndSearchResults = this.updatedEntitesAndSearchResults(state, action.payload as any);

        return {
          ...state,
          ...entitiesAndSearchResults,
          isLoadingEntities: {
            ...state.isLoadingEntities,
            ...this.getEntitesLoading(action.entityIds, false),
          },
          [this.mutationIsLoadingName]: false,
        };
      }),
      on(this.actionError, (state: IAsyncEntityState<ResponseData>, action: MutationBulkAction<ErrorPayloadType>) => {
        return {
          ...state,
          isLoadingEntities: {
            ...state.isLoadingEntities,
            ...this.getEntitesLoading(action.entityIds, false),
          },
          [this.mutationIsLoadingName]: false,
        };
      }),
    ];
  }

  public createEffect(actions$: Actions) {
    const actionEffect = (actn) =>
      of(actn).pipe(
        switchMap((action: MutationBulkAction<ActionPayloadType>) =>
          this.sideEffect(action.payload).pipe(
            map((data: ResponseData) => this.actionSuccess({ entityIds: action.entityIds, payload: data })),
            catchError((error: ErrorPayloadType) => {
              console.error(error);
              return of(this.actionError({ entityIds: action.entityIds, payload: error }));
            })
          )
        )
      );

    const actionCompleteEffect = (actn) => of(actn).pipe(map(() => this.getRefreshAction()));

    return createEffect(() =>
      actions$.pipe(
        ofType(...this.chooseActionsToSubscribe()),
        mergeMap((action) => iif(() => action.type === this.type, actionEffect(action), actionCompleteEffect(action)))
      )
    );
  }

  get mutationIsLoadingName(): string {
    return camelCase(`${this.name} is loading`);
  }

  private chooseActionsToSubscribe(): string[] {
    const types: string[] = [this.type];
    if (this.options.refreshEntityFrom && this.options.refreshEntityFrom !== 'response') {
      types.push(this.typeSuccess, this.typeError);
    }
    return types;
  }

  private getRefreshAction(): any {
    const fn = this.options.refreshEntityFrom as ActionCreator;

    return fn();
  }

  private getEntitesLoading(entityIds: string[], isLoading: boolean): { [enityId: string]: boolean } {
    return reduce(
      entityIds,
      (prev, id) => {
        prev[id] = isLoading;
        return prev;
      },
      {}
    );
  }

  private updatedEntitesAndSearchResults(
    state: IAsyncEntityState<ResponseData>,
    newData: any[]
  ): Partial<IAsyncEntityState<ResponseData>> {
    if (this.options.refreshEntityFrom === 'response') {
      const stateKeys = keys(state);
      const searchResultsStateKeys: string[] = stateKeys.filter((key) => endsWith(key, 'SearchResults'));
      const searchManagerNames: string[] = searchResultsStateKeys.map((key) => key.split('SearchResults')[0]);

      const newDataKeyedById: { [entityId: string]: any } = keyBy(newData, 'id');

      const newSearchResultsStateSlice = reduce(
        searchManagerNames,
        (prev, name) => {
          const searchResultKey = `${name}SearchResults`;
          const searchQueryKey = `${name}SearchQuery`;

          const currentPageOfSearchData =
            state[searchResultKey][state[searchQueryKey].queryHash]?.data[state[searchQueryKey].pageAndSort.page - 1];

          if (currentPageOfSearchData) {
            const updatedPageOfSearchData = _map(currentPageOfSearchData, (entity) =>
              newDataKeyedById[entity['id']] ? newDataKeyedById[entity['id']] : entity
            );
            const searchDataCopyToBeUpdated = [
              ...(state[searchResultKey][state[searchQueryKey].queryHash]?.data || {}),
            ] as any[][];
            searchDataCopyToBeUpdated[state[searchQueryKey].pageAndSort.page - 1] = updatedPageOfSearchData;
            prev[searchResultKey] = {
              ...state[searchResultKey],
              [state[searchQueryKey].queryHash]: {
                ...state[searchResultKey][state[searchQueryKey].queryHash],
                data: searchDataCopyToBeUpdated,
              },
            };
          }
          return prev;
        },
        {}
      );

      return {
        entities: {
          ...state.entities,
          ...(groupBy(newData as any[], 'id') as any),
        },
        ...newSearchResultsStateSlice,
      };
    } else {
      return {};
    }
  }
}

export interface AsyncEntityBulkMutationOptions {
  refreshEntityFrom?: 'response' | ActionCreator;
}
