<form class="form" [formGroup]="form">
  <div class="assign-load-carrier">
    <div class="assign-load-carrier__order-info">
      <div class="assign-load-carrier__label">Order Info</div>
      <div class="assign-load-carrier__controls" fxLayout="column" fxLayoutGap="8px">
        <app-order-info-table [form]="orderInfo" [brokers]="brokers$ | async"></app-order-info-table>
        <app-order-info-notes [form]="editLoad" [readOnly]="true"></app-order-info-notes>
      </div>
    </div>
    <div class="assign-load-carrier__carrier-info">
      <div
        class="assign-load-carrier__label"
        [ngClass]="{ 'incomplete-section': task === LoadOverviewTask.ADD_BOOKING_DETAILS }"
      >
        Carrier Info
      </div>
      <div class="assign-load-carrier__controls">
        <app-carrier-info-section-v2
          [carrier]="carrier$ | async"
          [carrierAdmin]="carrierAdmin$ | async"
          (carrierSearch)="onSearchCarrier($event)"
          [carrierForm]="carrierInfo"
          [assignmentForm]="assignmentInfo"
          [carriers]="carriers$ | async"
          [featureFlags]="featureFlagState"
          [isLoadingCarriers]="isLoadingCarriers"
          [carrierContact]="carrierContact"
          [assignmentLoading]="assignmentLoading"
          [loadsServiceLoad]="loadsServiceLoad"
          [carrierComplianceState]="carrierComplianceState"
          [carrierComplianceLoading]="carrierComplianceLoading"
          (carrierCompliance)="onCarrierCompliance($event)"
          (carrierSelect)="onSelectCarrier($event)"
          (saveCarrierInstance)="saveCarrierInstance()"
          (createCarrierCompliance)="createComplianceTicket($event)"
        ></app-carrier-info-section-v2>
      </div>
    </div>
    <div class="assign-load-carrier__truck-info">
      <div
        class="assign-load-carrier__label"
        [ngClass]="{ 'incomplete-section': task === LoadOverviewTask.ADD_TRUCKING_DETAILS }"
      >
        Truck Info
      </div>
      <div class="assign-load-carrier__controls">
        <app-truck-info-section
          #truckInput
          [carrier]="carrier$ | async"
          [drivers]="drivers$ | async"
          [trucks]="trucks$ | async"
          [trailers]="trailers$ | async"
          [usxiTrailers]="usxiTrailers"
          (carrierSearch)="onSearchCarrier($event)"
          [assignmentForm]="assignmentInfo"
          [assignmentFormData]="assignmentInfo$ | async"
          [featureFlags]="featureFlagState"
          [isLoadingDrivers]="isLoadingDrivers$ | async"
          [isLoadingTrucks]="isLoadingTrucks$ | async"
          [isLoadingTrailers]="isLoadingTrailers$ | async"
          [driverToPopulate]="driverToPopulate"
          [truckToPopulate]="truckToPopulate"
          [trailerToPopulate]="trailerToPopulate"
          [assignmentLoading]="assignmentLoading"
          [showIndividualSaveButtons]="showIndividualSaveButtons"
          [loadsServiceLoad]="loadsServiceLoad"
          (addDriver)="onAddDriver()"
          (addTruck)="onAddTruck()"
          (addTrailer)="onAddTrailer()"
          (swapTrailer)="onSwapTrailer()"
          (trackingTypeChange)="onTrackingChange()"
          (carrierSelect)="onSelectCarrier($event)"
          (saveAssignment)="saveAssignment()"
        ></app-truck-info-section>
      </div>
    </div>
    <div class="assign-load-carrier__payment-info">
      <div class="assign-load-carrier__label">Payment Details</div>
      <div class="assign-load-carrier__controls">
        <app-payment-details
          *ngIf="featureFlagState[payLineFeatureFlag]"
          [canEdit]="canEditPayment"
          [form]="payments"
          [formData]="paymentFormData$ | async"
          (addPayType)="onAddPayType()"
          (deletePayType)="onDeletePayType($event)"
          (editPayType)="onEditPayType($event)"
          (newTotal)="onNewPayTypeTotal($event)"
        ></app-payment-details>
        <app-pay-line-price [price]="price$ | async" [revenue]="revenue$ | async" [total]="total$ | async">
        </app-pay-line-price>
      </div>
      <div
        fxLayout="row"
        fxLayoutAlign="end center"
        *ngIf="showIndividualSaveButtons && !payments.disabled"
        class="individual-save-buttons"
      >
        <button
          mat-raised-button
          class="payment"
          [disabled]="paymentLoading"
          (click)="savePayment()"
          data-test="save-payment-details"
        >
          <mat-spinner [diameter]="18" *ngIf="paymentLoading" mode="indeterminate"></mat-spinner>
          <span>Save Payment Details</span>
        </button>
      </div>
    </div>

    <div class="assign-load-carrier__ratecon-info">
      <ng-container *appFeatures="rateconFeatureFlag">
        <div class="assign-load-carrier__label">Share Ratecon</div>
        <div class="assign-load-carrier__controls">
          <app-chip-selector
            [emails]="rateConEmails$ | async"
            [removable]="canEdit"
            [showEnterEmailAddresses]="canEdit"
            (dataChange)="onRateConEmailsChange($event)"
          ></app-chip-selector>
        </div>
      </ng-container>
    </div>

    <div class="assign-load-carrier__dispatch-info">
      <div
        class="assign-load-carrier__label"
        [ngClass]="{ 'incomplete-section': task === LoadOverviewTask.ADD_DISPATCH_DETAILS }"
      >
        Dispatch Driver
      </div>
      <div class="assign-load-carrier__controls">
        <app-load-dispatch-form
          [formCls]="'dispatch-form-row'"
          [formControlCls]="'app-dark-field'"
          [showSaveButton]="false"
          [form]="dispatchInfo"
        ></app-load-dispatch-form>
      </div>
      <div
        fxLayout="row"
        fxLayoutAlign="end center"
        *ngIf="showIndividualSaveButtons && !dispatchInfo.disabled"
        class="individual-save-buttons"
      >
        <button mat-raised-button class="dispatch" [disabled]="dispatchLoading" (click)="saveDispatch()">
          <mat-spinner [diameter]="18" *ngIf="dispatchLoading" mode="indeterminate"></mat-spinner>
          <span>Save Dispatch</span>
        </button>
      </div>
    </div>
  </div>
  <footer class="footer" *ngIf="showFooter">
    <div class="form-errors">
      <div class="shortErrors">
        <span *ngIf="form.get('dispatchInfo').errors?.locationIsEmpty && formSubmitted"
          >* Empty location is required</span
        >
        <span *ngIf="form.get('dispatchInfo').errors?.timeIsEmpty && formSubmitted">* Time of empty" is required</span>
        <span *ngIf="form.get('assignmentInfo')?.get('driverId')?.errors?.required && formSubmitted"
          >* The Driver field is required. Select a Driver</span
        >
        <span *ngIf="form.get('assignmentInfo')?.get('truckId')?.errors?.required && formSubmitted"
          >* The Truck field is required. Select a Truck</span
        >
      </div>
      <span *ngIf="form.get('assignmentInfo')?.get('phone')?.errors?.required && formSubmitted"
        >* The Driver Phone Number field is required. Please add a new driver to include a phone number or choose a
        different tracking method.</span
      >
      <div *ngIf="form.get('assignmentInfo')?.get('email')?.errors?.required && formSubmitted">
        * The Driver Email field is required. Please add a new driver to include an email or choose a different tracking
        method.
      </div>
    </div>
  </footer>

  <span class="footer-buttons" *ngIf="showFooterButtons">
    <!--    <button class="hlx-button-command hlx-button-command&#45;&#45;border-white" (click)="onEmailOffer($event, form.value)">Email Offer to Carrier</button>-->
    <button
      mat-raised-button
      class="hlx-button-command hlx-button-command--book"
      (click)="onSave($event)"
      [disabled]="
        isDisableBookCarrierButton ||
        isLoading ||
        isLoadingCreateEntity ||
        !(load | dedicatedAllowed: featureFlagState) ||
        (carrierCompliant && carrierComplianceState !== 'done')
      "
      *ngIf="formMode === assignLoadCarrierFormMode.NEW && !isLoadLocked && !isCarrierComplianceLoadLock"
      data-test="book-button"
    >
      <mat-spinner [diameter]="18" *ngIf="isLoading" mode="indeterminate"></mat-spinner>
      <span
        *ngIf="!bidId; else acceptBidAndBook"
        matTooltipPosition="above"
        [matTooltip]="isDisableBookCarrierButton ? 'Load already booked' : ''"
      >
        {{ (load | dedicatedAllowed: featureFlagState) ? 'Book for Carrier' : 'Dedicated / Unavailable' }}
      </span>
      <ng-template #acceptBidAndBook>
        <span>Award and Book</span>
      </ng-template>
    </button>

    <button
      mat-raised-button
      [disabled]="true"
      type="button"
      class="book-locked"
      *ngIf="isLoadLocked || isCarrierComplianceLoadLock"
    >
      <mat-spinner [diameter]="18" *ngIf="isLoading" mode="indeterminate"></mat-spinner>
      <span matTooltipPosition="above" [matTooltip]="'Load is locked'"> Locked </span>
    </button>
  </span>
</form>
