import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@haulynx/modules';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DropDownModule } from '../drop-down/drop-down.module';
import { DayPickerComponent } from './day-picker.component';

@NgModule({
  declarations: [DayPickerComponent],
  imports: [CommonModule, ReactiveFormsModule, MaterialModule, DropDownModule, OverlayPanelModule],
  exports: [DayPickerComponent],
})
export class DayPickerModule {}
