import { ISearchFilter, TextFilterType } from '../search-filter';
import { ISearchFilterType } from '../search-filter.types';
import { startCase } from 'lodash';
import { LoadHistoryUserType } from '../../load-overview/load-history';

export class ActivityUserFilter implements ISearchFilter {
  name = 'User';
  type: ISearchFilterType = ISearchFilterType.TEXT;
  keys: TextFilterType;
  description = 'Filter load activity by user';

  localFilterFn<loadHistory>(data: loadHistory[], searchbarVal: Record<string, any>): loadHistory[] {
    return data.filter((loadHistoryRow) => {
      const user =
        loadHistoryRow['userType'] === 'SYSTEM_GENERATED'
          ? 'Broker OS'
          : loadHistoryRow['updatedBy']?.usxId || loadHistoryRow['updatedBy']?.name;
      return user.toLowerCase().includes(searchbarVal?.user.toLowerCase());
    });
  }

  constructor(defaultValues?: TextFilterType) {
    this.keys = new TextFilterType({
      value: '',
      optionsDataIndex: 'user',
      textFormName: 'user',
      ...defaultValues,
    });
  }
}

export class ActivityUserTypeFilter implements ISearchFilter {
  name = 'User Type';
  type: ISearchFilterType = ISearchFilterType.MULTI_DROPDOWN;
  keys: TextFilterType;
  description = 'Filter load activity by user type';

  localFilterFn<loadHistory>(data: loadHistory[], searchbarVal: Record<string, any>): loadHistory[] {
    return data.filter((loadHistoryRow) => {
      const userType =
        loadHistoryRow['updatedBy']?.usxId === 'ML.API' ? 'MACHINE_LEARNING' : loadHistoryRow['userType'];
      return searchbarVal?.userType.includes(startCase(userType));
    });
  }

  constructor(defaultValues?: TextFilterType) {
    const options = Object.values(LoadHistoryUserType).map((type) => {
      return {
        key: startCase(type),
        value: startCase(type),
      };
    });
    this.keys = new TextFilterType({
      value: '',
      optionsDataIndex: options,
      textFormName: 'userType',
      ...defaultValues,
    });
  }
}
