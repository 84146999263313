import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { SpinnerModule } from '../spinner/spinner.module';
import { CarrierDetailsShortComponent } from './carrier-details-short.component';
import { CarrierSafetyRatingComponent } from './components/carrier-safety-rating/carrier-safety-rating.component';
import { CarrierStatusComponent } from './components/carrier-status/carrier-status.component';

@NgModule({
  declarations: [CarrierDetailsShortComponent, CarrierSafetyRatingComponent, CarrierStatusComponent],
  exports: [CarrierDetailsShortComponent, CarrierSafetyRatingComponent],
  imports: [CommonModule, MaterialModule, SpinnerModule, PipesModule],
})
export class CarrierDetailsShortModule {}
