import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { MapRoutesService, UserService } from '@haulynx/services';
import {
  FlatMarker,
  LoadLocationType,
  LoadRoute,
  LoadRouteSource,
  LoadWayPoint,
  MarkerStyles,
  User,
} from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { Position } from '@turf/helpers';
import { get, head, last } from 'lodash';
import { LngLatBounds, LngLatLike } from 'mapbox-gl';
import { takeUntil } from 'rxjs/operators';

const defaultZoom = 5;

@Component({
  selector: 'app-map-routes',
  templateUrl: './map-routes.component.html',
  styleUrls: ['./map-routes.component.scss'],
})
export class MapRoutesComponent implements OnChanges {
  @Input() style = 'mapbox://styles/haulynx/ckmxzlojk10b717pi7svpdxgt';
  @Input() padding = 10;
  @Input() truckMarkerSize = 32;
  @Input() zoom = defaultZoom;
  @Input() exposeTruck = false;
  @Input() sources: LoadRouteSource[] = [];
  @Input() center: LngLatLike = [-100.04, 38.907];
  @Input() showControls = false;
  @Input() dark = true;
  @Input() flatMarkers: FlatMarker[] = [];
  @Input() isInteractive = true;
  @Input() showTruckRoute = false;
  @Input() borderRadius: 'all' | 'none' | 'top' | 'left' | 'bottom' | 'right' = 'all';
  @Input() scrollZoom = true;
  @Input() maxZoom = null;
  @Input() routeToHighlight;
  @Input() truckToHighlight;
  @Input() isLoading = false;

  @ViewChild('map', { static: true }) map: any;

  alive = aliveWhile();
  mapStyle = {
    DARK: 'mapbox://styles/haulynx/ckmxzlojk10b717pi7svpdxgt',
    STREET: 'mapbox://styles/mapbox/streets-v9',
  };
  markerStyle = MarkerStyles;
  user: User;
  bounds: LngLatBounds;
  routes: LoadRoute[] = [];
  locationType = LoadLocationType;

  constructor(private mapRoutesService: MapRoutesService, private userService: UserService) {
    this.userService.user.pipe(takeUntil(this.alive)).subscribe((ret) => {
      this.user = ret;
    });
  }

  initMap(bounds = true): void {
    this.routes = this.mapRoutesService.initRoutes(this.sources);
    if (bounds) {
      this.bounds = this.mapRoutesService.zoomToBounds(this.sources, this.flatMarkers);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { sources, zoom, center, flatMarkers, dark } = changes;

    if (center) {
      const [lng = 0, lat = 0] = center.currentValue || [];

      this.center = [lng, lat];
    }

    if (flatMarkers) {
      this.flatMarkers = flatMarkers.currentValue.filter((marker) => {
        const lon = head(get(marker, 'coordinates'));
        const lat = last(get(marker, 'coordinates'));

        return lon && lat;
      });

      this.initMap(false);
    }

    if (sources || zoom) {
      if (sources && !this.exposeTruck) {
        const mapSource = sources.currentValue || [];

        if (sources.previousValue) {
          sources.previousValue.forEach((source) => {
            if (this.map.mapInstance.getLayer(source.id)) {
              this.map.mapInstance.removeLayer(source.id);
            }
            if (this.map.mapInstance.getSource(source.id)) {
              this.map.mapInstance.removeSource(source.id);
            }
          });
        }

        this.sources = mapSource.reduce((routes: LoadRouteSource[], source: LoadRouteSource) => {
          const route = source.coordinates as Position[];
          const wayPoints = source.wayPoints as LoadWayPoint[];

          if (!route || !wayPoints) {
            return routes;
          }

          const carrierId = get(this.user, 'carrier.id', null);
          const truckPosition = source.truckPosition as Position;

          if (source.carrierId === carrierId && source.carrierAccepted) {
            const newTruckPosition = this.mapRoutesService.getTruckPosition(wayPoints, route, truckPosition);
            return { ...source, truckPosition: newTruckPosition };
          }

          const origin = get(head(wayPoints), 'location');
          const destination = get(last(wayPoints), 'location');
          const reversedRoute = [...route].reverse();

          const shiftedStart = this.mapRoutesService.pointByDistance(route);
          const shiftedFinish = this.mapRoutesService.pointByDistance(reversedRoute);
          const shiftedCoordinates = this.mapRoutesService.sliceRoute(shiftedStart, shiftedFinish, route);

          const wayPointsRoute = this.mapRoutesService.sliceRoute(origin, destination, route);
          const wayPointsReversedRoute = [...wayPointsRoute].reverse();
          const shiftedOrigin = this.mapRoutesService.pointByDistance(wayPointsRoute);
          const shiftedDestination = this.mapRoutesService.pointByDistance(wayPointsReversedRoute);

          const shiftedTruckPosition = this.mapRoutesService.getShiftedTruckPosition(wayPoints, route, truckPosition);
          const shiftedWayPoints = this.mapRoutesService.getShiftedPoints(wayPoints, shiftedOrigin, shiftedDestination);

          return [
            ...routes,
            {
              ...source,
              coordinates: shiftedCoordinates,
              wayPoints: shiftedWayPoints ? shiftedWayPoints : null,
              truckPosition: shiftedTruckPosition,
            },
          ];
        }, []);
      }

      if (dark) {
        this.style = dark.currentValue ? this.mapStyle.DARK : this.mapStyle.STREET;
      }

      this.initMap();
    }
  }

  ngOnDestroy() {
    this.alive.destroy();
  }

  trackByFn(index: number, item: LoadRoute): string {
    return item.id;
  }
}
