import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[haulynxFocusIntoView]',
})
export class FocusIntoViewDirective {
  @Input() set focusIntoView(value: boolean) {
    if (value) {
      this.elementRef.nativeElement.scrollIntoViewIfNeeded();
    }
  }

  constructor(private elementRef: ElementRef) {}
}
