import { createFeatureSelector } from '@ngrx/store';
import { Map } from 'immutable';
import { IFormState } from '../../shared/store/form/state';
import { loadDetailsAdapter } from './adapters';

export const initialCarrierLoadDetails: LoadDetailsState = {
  routes: {},
  isLoadingRoutes: false,
  isLoadingCreateEntity: false,
  deviceLocations: {},
  activeStep: {},
  driverToPopulate: null,
  truckToPopulate: null,
  trailerToPopulate: null,
  ...loadDetailsAdapter.getInitialState(),
};

export class LoadDetailsState implements IFormState<unknown> {
  stateNameSpace: string;
  initialState: Map<string, { [p: string]: unknown } | unknown>;
  isLoading: Map<string, boolean>;
  isLoadingCreateEntity: boolean;
  mode: Map<string, string>;
  state: Map<string, { [p: string]: unknown } | unknown>;
  stateKey: Map<string, string>;
  routes: { [key: string]: unknown };
  isLoadingRoutes: boolean;
  deviceLocations: { [key: string]: unknown };
  activeStep: { [key: string]: string };
  driverToPopulate: string;
  truckToPopulate: string;
  trailerToPopulate: string;
}

export const getLoadDetailsState = createFeatureSelector<LoadDetailsState>('load-details');
