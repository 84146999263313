import { Provider } from '@angular/core';
import { cloneDeep } from 'lodash';
import { Point } from 'mapbox-gl';
import {
  LoadsServiceLoad,
  LoadsServiceLoadLocation,
  LoadsServiceNote,
  LoadsServiceOffer,
  LoadsServiceRestriction,
  LoadsServiceRestrictionTypes,
} from '../../loads-service';
import {
  BrokerTeamId,
  EquipmentType,
  LoadsServiceProviderDetailId,
  OperationsPriority,
} from '../../loads-service/loads-service-provider';
import { Bid } from '../bid/bid';
import { BidDetails } from '../bid/bid-details';
import { BookingType } from '../booking/booking-type';
import { Carrier } from '../carrier/carrier';
import { Customer } from '../generic/customer';
import { User } from '../generic/user';
import { LoadLocationType } from '../load';
import { RateConEmail } from '../load/assign-load-edit-form';
import { BookStatus, DistributionMechanismString, LoadStatus } from '../load/load';
import { DispatchLocation } from '../location/dispatch-location';
import { TrackingType } from '../truck/tracking-type';
import { Trailer } from '../truck/trailer';
import { Truck } from '../truck/truck';

export type SecondaryTextHtmlObject = { secondaryText: string[]; ul?: string; li?: string };
export type ActionStringObject = [string, SecondaryTextHtmlObject[]];

export type LoadHistory = {
  newLoad: LoadsServiceLoad;
  activityType: LoadHistoryActivityType;
  loadDelta: LoadHistoryDelta;
  updatedAt: string | number;
  updatedBy: UserOrApp;
  updatedById: string;
  userType: LoadHistoryUserType;
};

export class UserOrApp {
  id: string;
  name: string;
  email: string;
  usxId: string;
  uri?: string;
  type: AuthSubject;
  clientId: string;
  carrierId: string;
  profile: string;
}

export enum AuthSubject {
  user = 'user',
  app = 'app',
}

export enum ProviderDetailPrimaryFields {
  operationsPriority = 'operationsPriority',
  internalNotes = 'internalNotes',
  externalNotes = 'externalNotes',
  commodity = 'commodity',
  equipmentType = 'equipmentType',
  weightLbs = 'weightLbs',
  orderType = 'orderType',
  cxPriority = 'cxPriority',
}

export enum PaymentDetailPrimaryFields {
  maxBuy = 'maxBuy',
  price = 'price',
}

export enum LocationPrimaryFields {
  appointmentStart = 'appointmentStart',
  appointmentEnd = 'appointmentEnd',
  appointmentSet = 'appointmentSet',
}

export enum LoadHistoryActionType {
  ASSIGNED = 'Assigned',
  CHANGED = 'Changed',
  UPDATED = 'Updated',
  ADDED = 'Added',
  SENT = 'Sent',
  SET = 'Set',
  DELETED = 'Deleted',
  REMOVED = 'Removed',
  ACCEPTED = 'Accepted',
  REJECTED = 'Rejected',
  BOOKED = 'Booked',
  BOUNCED = 'Bounced',
}

export enum CrudDeltaPrimaryActions {
  addedValues = 'Added',
  removedValues = 'Removed',
  modifiedValues = 'Changed',
}

export enum LoadStatusTypeString {
  atShipper = 'At Pickup',
  pickedUp = 'In Transit',
  unassigned = 'Awaiting Truck Details',
  assigned = 'Ready for Dispatch',
  atReceiver = 'At Dropoff',
  delivered = 'Delivered',
  finalled = 'Finalled',
  deleted = 'Deleted',
  dispatched = 'Dispatched',
}

export enum LoadHistoryActionObject {
  price = 'Price',
  maxBuy = 'Max Buy',
  pickupRequirements = 'Pickup Requirements',
  dropoffRequirements = 'Dropoff Requirements',
  hazmat = 'Hazmat',
  tankerCertificationRequired = 'Tanker Certification Required',
  teamDrivingRequired = 'Team Driving Required',
  cxPriority = 'CX Priority',
  operationsPriority = 'Priority',
  internalNotes = 'Internal Notes',
  commodity = 'Commodity',
  equipmentType = 'Equipment Type',
  weightLbs = 'Weight',
  orderType = 'Freight Mix',
  bookStatus = 'Book Status',
  assignedBrokers = 'Broker Assignment(s)',
  loadStatus = 'Load Status',
  broker = 'Booked By Broker',
  carrier = 'Carrier',
  trailers = 'Trailer',
  dispatchLocation = 'Dispatch Location',
  distributionMechanism = 'Distribution Mechanism',
  notes = 'Note',
  externalNotes = 'External Note',
  drivers = 'Driver',
  locations = 'Locations',
  paymentDetails = 'Payment Detail',
  providerDetails = 'Provider Detail',
  rateConEmails = 'Rate Confirmation',
  provider = 'Provider',
  region = 'Region',
  trackingStatus = 'Tracking Status',
  trackingType = 'Tracking Type',
  truck = 'Truck',
  offers = 'Offer',
  restrictions = 'Restriction',
  freightmix = 'Freight Mix',
  appointmentSet = 'Appointment',
  appointmentStart = 'Stop(s) Appointments',
  appointmentEnd = 'Stop(s) Appointments',
}

export enum LoadHistoryFields {
  PRIORITY = 'cxPriority',
  BOOK_STATUS = 'bookStatus',
  LOAD_STATUS = 'loadStatus',
  BROKER = 'broker',
  CARRIER = 'carrier',
  TRAILERS = 'trailers',
  DISPATCH_LOCATION = 'dispatchLocation',
  DISTRIBUTION_MECHANISM = 'distributionMechanism',
  NOTES = 'notes',
  EXTERNAL_NOTES = 'externalNotes',
  DRIVERS = 'drivers',
  LOCATIONS = 'locations',
  PAYMENT_DETAILS = 'paymentDetails',
  PROVIDER_DETAILS = 'providerDetails',
  RATE_CON_EMAILS = 'rateConEmails',
  PROVIDER = 'provider',
  REGION = 'region',
  TRACKING_STATUS = 'trackingStatus',
  TRACKING_TYPE = 'trackingType',
  TRUCK = 'truck',
  OFFERS = 'offers',
  RESTRICTIONS = 'restrictions',
  FREIGHT_MIX = 'freightmix',
  APPOINTMENT_SET = 'appointmentSet',
  APPOINTMENT_START = 'appointmentStart',
  APPOINTMENT_END = 'appointmentEnd',
  ASSIGNED_BROKERS = 'assignedBrokers',
}

export enum LoadHistoryActivityType {
  LOAD_CREATED = 'LOAD_CREATED',
  LOAD_DETAILS = 'LOAD_DETAILS',
  LOAD_STATUS = 'LOAD_STATUS',
  BROKER_DETAILS = 'BROKER_DETAILS',
  PAYMENT_DETAILS = 'PAYMENT_DETAILS',
  // MILESTONES = 'MILESTONES',
  // BIDS = 'BIDS',
  CARRIER_DETAILS = 'CARRIER_DETAILS',
}

export type LoadHistoryDelta = {
  assignedBrokers: CrudDeltaType<User, User>;
  bidDetails: BidDetails;
  bookStatus: OldNewDeltaType<BookStatus>;
  broker: OldNewDeltaType<User>;
  carrier: OldNewDeltaType<Carrier>;
  carrierBid: Bid;
  dispatchLocation: OldNewDeltaType<DispatchLocation>;
  distributionMechanism: OldNewDeltaType<DistributionMechanismString>;
  drivers: CrudDeltaType<User, User>;
  loadStatus: OldNewDeltaType<LoadStatus>;
  locations: CrudDeltaType<LoadsServiceLoadLocation, LoadHistoryLocationDelta>;
  notes: OldNewDeltaType<LoadsServiceNote>;
  offers: CrudDeltaType<LoadsServiceOffer, LoadHistoryOfferDelta>;
  paymentDetails: LoadHistoryPaymentDetailsDelta;
  provider?: LoadHistoryProviderDelta;
  providerDetails: LoadHistoryProviderDetailsDelta;
  rateConEmails: CrudDeltaType<RateConEmail, LoadHistoryRateConEmailDelta>;
  region: OldNewDeltaType<Region>;
  restrictions: CrudDeltaType<LoadsServiceRestriction, OldNewDeltaType<LoadsServiceRestriction>>;
  trackingStatus: LoadHistoryTrackingDetailsDelta;
  trackingType: OldNewDeltaType<TrackingType>;
  trailers: CrudDeltaType<Trailer, Trailer>;
  truck: OldNewDeltaType<Truck>;
};

export type OldNewDeltaType<T> = {
  oldValue: T;
  newValue: T;
};

export type CrudDeltaType<T, Delta> = {
  addedValues: T[];
  removedValues: T[];
  modifiedValues?: Delta[];
};

export type SubDeltaType = {
  [key: string]: OldNewDeltaType<string | number | Record<string, any>>;
};

export type LoadHistoryRateConEmailDelta = {
  emailRecipient: OldNewDeltaType<string>;
  message: OldNewDeltaType<string>;
};
export class LoadHistoryProviderDetailsDelta {
  // alternateIds?: LoadHistoryAlternateIdsDelta;
  billTo?: OldNewDeltaType<string>;
  billToName?: OldNewDeltaType<string>;
  // brokerTeamId?: BrokerTeamIdDelta;
  // operationsPriority?: OperationsPriorityDelta;
  cxPriority?: OldNewDeltaType<number>;
  equipmentType?: OldNewDeltaType<string>;
  commodity?: OldNewDeltaType<string>;
  // packingType?: OldNewDeltaType<string>;
  // quantity?: NonNegativeIntDelta;
  weightLbs?: OldNewDeltaType<number>;
  // temperatureF?: FloatDelta;
  // regionalManagerName?: OldNewDeltaType<string>;
  // regionalManagerEmail?: OldNewDeltaType<string>;
  // regionalManagerPhone?: OldNewDeltaType<string>;
  // cxrEmployeeId?: NonBlankStringDelta;
  internalNotes?: OldNewDeltaType<string>;
  externalNotes?: OldNewDeltaType<string>;
  orderType?: OldNewDeltaType<string>;
  operationsPriority?: OldNewDeltaType<string>;
}

export class LoadHistoryProviderDelta {
  id: OldNewDeltaType<string>;
  name: OldNewDeltaType<string>;
  detailType: OldNewDeltaType<DetailType>;
  company: OldNewDeltaType<string>;
}

export enum DetailType {
  usx = 'usx',
  carrier = 'carrier',
}

export enum Region {
  WestCoast = 'WestCoast',
  North = 'North',
  South = 'South',
  Midwest = 'Midwest',
}
export class LoadHistoryPaymentDetailsDelta {
  price?: OldNewDeltaType<number>;
  priceLastUpdatedAt?: OldNewDeltaType<number>;
  priceLastUpdatedBy?: OldNewDeltaType<string>;
  bookedAt?: OldNewDeltaType<number>;
  maxBuy?: OldNewDeltaType<number>;
  maxBuyLastUpdatedAt?: OldNewDeltaType<number>;
  maxBuyLastUpdatedBy?: OldNewDeltaType<string>;
  revenue?: OldNewDeltaType<number>;
  distanceMiles?: OldNewDeltaType<number>;
  ratePerMile?: OldNewDeltaType<number>;
  bookingType?: BookingType;
}

export type LoadHistoryLocationDelta = {
  id?: string;
  customer?: OldNewDeltaType<Customer>;
  address?: OldNewDeltaType<string>;
  city?: OldNewDeltaType<string>;
  state?: OldNewDeltaType<string>;
  zipcode?: OldNewDeltaType<string>;
  summaryAddress?: OldNewDeltaType<string>;
  timezone?: OldNewDeltaType<string>;
  billOfLading?: OldNewDeltaType<string>;
  geometry?: OldNewDeltaType<Point>;
  appointmentStart?: OldNewDeltaType<number>;
  appointmentEnd?: OldNewDeltaType<number>;
  carrierArrival?: OldNewDeltaType<number>;
  carrierDeparture?: OldNewDeltaType<number>;
  appointmentSet?: OldNewDeltaType<boolean>;
  locationType?: OldNewDeltaType<LoadLocationType>;
  distanceMiles?: OldNewDeltaType<number>;
  notes?: OldNewDeltaType<LoadsServiceNote>;
  zipLane?: OldNewDeltaType<string>;
  zoneLane?: OldNewDeltaType<string>;
};

export enum RestrictionValues {
  true = 'Yes',
  false = 'No',
}

export type LoadHistoryOfferDelta = {
  id: OldNewDeltaType<string>;
  loadId: OldNewDeltaType<string>;
  carrierDot: OldNewDeltaType<string>;
  offeredBy: OldNewDeltaType<string>;
  price: OldNewDeltaType<number>;
  ratePerMile: OldNewDeltaType<number>;
};

export enum LoadHistoryUserType {
  BROKER = 'BROKER',
  CARRIER = 'CARRIER',
  SYSTEM_GENERATED = 'SYSTEM_GENERATED',
  USXPRESS_ORDER_BRIDGE = 'USXPRESS_ORDER_BRIDGE',
  MACHINE_LEARNING = 'MACHINE_LEARNING',
}

export type LoadHistoryTrackingDetailsDelta = {
  usxStatusCode: OldNewDeltaType<number>;
  usxOrderStatus: OldNewDeltaType<string>;
  updatedAt: OldNewDeltaType<number>;
  usxOrderStatusReason: OldNewDeltaType<string>;
};

export enum LoadsServiceDateFields {
  bookedAt,
  carrierArrival,
  carrierDeparture,
  created,
  createdAt,
  updatedAt,
  appointmentStart,
  appointmentEnd,
}

export enum LoadsServiceCurrencyFields {
  price,
  revenue,
  margin,
  maxBuy,
}

export enum LoadsServiceWeightFields {
  weightLbs,
}

export enum LoadsHistoryNonStartCaseFields {
  emailRecipient,
  lat,
  lon,
  name,
}

export const notDeltaFields = {
  location: ['id'],
};

export const removeExceptionTypes: Record<string, any> = {
  restrictions: (
    delta: CrudDeltaType<LoadsServiceRestriction, OldNewDeltaType<LoadsServiceRestriction>>
  ): CrudDeltaType<LoadsServiceRestriction, OldNewDeltaType<LoadsServiceRestriction>> => {
    const restrictionDelta = { ...delta };
    Object.keys(restrictionDelta).forEach((action) => {
      if (action === 'addedValues' || action === 'removedValues') {
        restrictionDelta[action] = restrictionDelta[action].filter((changedItem) => {
          return !(changedItem.type === 'excluded');
        });
      } else if (action === 'modifiedValues') {
        restrictionDelta[action] = restrictionDelta[action].filter((changedItem) => {
          return !(changedItem?.oldValue?.type === 'excluded' || changedItem?.newValue?.type === 'excluded');
        });
      }
    });
    return restrictionDelta;
  },
  locations: (
    delta: CrudDeltaType<LoadsServiceLoadLocation, LoadHistoryLocationDelta>
  ): CrudDeltaType<LoadsServiceLoadLocation, LoadHistoryLocationDelta> => {
    const locationDelta = cloneDeep(delta);
    Object.keys(locationDelta).forEach((action) => {
      if (action === 'addedValues' || action === 'removedValues') {
        locationDelta[action].forEach((changedItem) => {
          if (!changedItem?.notes.text) {
            changedItem.notes = null;
          }
        });
      } else if (action === 'modifiedValues') {
        locationDelta[action].forEach((changedItem) => {
          if (
            changedItem?.notes?.oldValue?.author == changedItem?.notes?.newValue?.author &&
            changedItem?.notes?.oldValue.text.replace(/ /g, '') == changedItem?.notes?.newValue.text.replace(/ /g, '')
          ) {
            changedItem.notes = null;
          }
        });
      }
    });
    return locationDelta;
  },
};
