import { Pipe, PipeTransform } from '@angular/core';
import { MomentService } from '@haulynx/services';

/**
 * @deprecated use `DateTimePipe` instead
 */
@Pipe({
  name: 'timestamp',
})
export class TimestampPipe implements PipeTransform {
  constructor(private momentService: MomentService) {}

  transform(timestamp: number | string, format: string = 'D MMM YYYY h:mma', timezone?: string): string {
    return this.momentService.getDateFormat(Number(timestamp), format, timezone);
  }
}
