import { ActionReducer, ActionReducerMap } from '@ngrx/store';
import { IAsyncEntityState, Milestone } from '@haulynx/types';
import { InjectionToken, Provider } from '@angular/core';
import { MilestoneEntityService } from './milestone-entity.service';
import { USER_PROVIDED_EFFECTS } from '@ngrx/effects';

export const MILESTONE_ENTITY_REDUCERS_TOKEN = new InjectionToken<ActionReducerMap<IAsyncEntityState<Milestone[]>>>(
  'milestone entity reducers'
);

function getMilestoneEntityReducersFactory(
  milestoneEntityService: MilestoneEntityService
): ActionReducer<IAsyncEntityState<Milestone[]>> {
  return milestoneEntityService.getReducers();
}

const milestoneEntityReducerProvider: Provider = {
  provide: MILESTONE_ENTITY_REDUCERS_TOKEN,
  useFactory: getMilestoneEntityReducersFactory,
  deps: [MilestoneEntityService],
};

const milestoneEntityEffectProvider: Provider = {
  provide: USER_PROVIDED_EFFECTS,
  multi: true,
  useValue: [MilestoneEntityService],
};

export const milestoneStateProvider: Provider[] = [milestoneEntityReducerProvider, milestoneEntityEffectProvider];
