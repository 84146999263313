import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'load-share-complete-dialog',
  templateUrl: `./load-share-complete.component.html`,
  styleUrls: ['./load-share-complete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareLoadCompleteDialog {
  constructor(public dialogRef: MatDialogRef<ShareLoadCompleteDialog>) {}
}
