import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@haulynx/modules';
import { AssignedBrokersComponent } from './assigned-brokers.component';

@NgModule({
  declarations: [AssignedBrokersComponent],
  exports: [AssignedBrokersComponent],
  imports: [CommonModule, MaterialModule],
})
export class AssignedBrokersModule {}
