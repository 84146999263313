<div class="bid-counter-offer">
  <h4 class="bid-counter-offer__title">Create a Counter Offer</h4>

  <div class="bids">
    <div class="bids__details">
      <div class="bids__info">
        <div class="bids__info__row" *ngIf="bid?.carrier?.name">
          <div class="bids__info__column bids__info__column--muted">Carrier</div>
          <div class="bids__info__column">
            {{ bid?.carrier?.name }}
          </div>
        </div>
        <div class="bids__info__row" *ngIf="bid?.createdAt">
          <div class="bids__info__column bids__info__column--muted">Submitted</div>
          <div class="bids__info__column">
            {{ bid.createdAt | mtz: 'MM/DD/YY HH:mm':userTimeZone:false:true }}
          </div>
        </div>
        <div class="bids__info__row">
          <div class="bids__info__column bids__info__column--muted">Last Offer</div>
          <div class="bids__info__column bids__info__column--last-offer">
            {{ bid.price | currency }}
          </div>
        </div>
        <div class="bids__info__row">
          <div class="bids__info__column bids__info__column--muted">Buy-it-now Price</div>
          <div class="bids__info__column">
            {{ load.paymentDetails?.price | currency }}
          </div>
        </div>
      </div>
      <div class="bids__notes">
        <div class="bids__info__row" *ngIf="!!(load | loadAlternateId: loadIdentifierType.TMW_NUMBER)">
          <div class="bids__info__column bids__info__column--muted">Order #</div>
          <div class="bids__info__column">
            {{
              !(load | loadAlternateId: loadIdentifierType.BILL_OF_LADING)
                ? 'N/A'
                : (load | loadAlternateId: loadIdentifierType.BILL_OF_LADING)
            }}
          </div>
        </div>
        <div class="bids__info__row" *ngIf="load?.locations?.length">
          <div class="bids__info__column bids__info__column--muted">Load</div>
          <div class="bids__info__column bids__info__column--route">
            {{ load.locations | nth: 0 | get: 'address':'N/A' | address: 1 }}
            &rarr;
            {{ load.locations | nth: -1 | get: 'address':'N/A' | address: 1 }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <br />
  <mat-divider></mat-divider>
  <br />

  <form [formGroup]="form" class="bid-counter-offer__form">
    <div class="bid-counter-offer__row">
      <mat-label class="bid-counter-offer__label"> New Offer </mat-label>
      <mat-form-field
        class="app-form-field bid-counter-offer__field bid-counter-offer__field--offer"
        hideRequiredMarker
        appearance="fill"
        floatLabel="always"
      >
        <input
          #priceInput
          mask="separator"
          decimalMarker="."
          thousandSeparator=","
          prefix="$"
          matInput
          placeholder="$0.00"
          formControlName="price"
          minlength="1"
          autocomplete="off"
          pattern="^([0-9]*[.])?[0-9]+$"
          required
        />
        <mat-error class="price-error" *ngIf="form.get('price').invalid">{{ getPriceErrorMessage() }}</mat-error>
      </mat-form-field>
    </div>
    <div class="bid-counter-offer__row">
      <mat-label class="bid-counter-offer__label"> Send a Message to the Carrier </mat-label>
      <mat-form-field
        class="app-form-field bid-counter-offer__field bid-counter-offer__field--high"
        hideRequiredMarker
        appearance="fill"
        floatLabel="always"
      >
        <textarea matInput formControlName="notes"></textarea>
      </mat-form-field>
    </div>
  </form>

  <div class="bid-counter-offer__history">
    <mat-icon class="bid-counter-offer__history--icon">restore</mat-icon>
    <span class="bid-counter-offer__history--link" (click)="onOpenHistory(bid)">Bid History</span>
  </div>

  <div *appFeatures="bidV2Feature" class="bid-counter-offer__hold">
    <mat-checkbox (change)="onChange($event)"
      >Final Offer: Let carrier know this is the highest/final price.</mat-checkbox
    >
  </div>

  <div class="bid-counter-offer__actions">
    <button
      mat-dialog-close
      class="hlx-button-command hlx-button-command--cancel hlx-button-command--border-white bid-counter-offer__button"
    >
      Cancel
    </button>

    <button
      class="hlx-button-command hlx-button-command--book bid-counter-offer__button"
      (click)="onDone()"
      [disabled]="form.invalid"
    >
      Submit
    </button>
  </div>
</div>
