import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'driver-tool-tip',
  templateUrl: './driver-tool-tip.component.html',
  styleUrls: ['./driver-tool-tip.component.scss'],
})
export class DriverToolTipComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
