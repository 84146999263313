import gql from 'graphql-tag';

export const GetLastLoadLocationByLoadId = gql(`
query GetLastLoadLocationByLoadId($loadId: StrictId!) {
  getLastLoadLocationByLoadId(loadId: $loadId) {
    gpsLat
    gpsLon
    approximateAddress
    timestamp
    timeZone
    date
    driver
    driverName
    driverStatus
    speed
    unitId
    automaticEntry
    city
    state
    cityAndState
    appVersion
    type
  }
}
  `);
