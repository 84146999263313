import { forEach, keys } from 'lodash';
import { Moment } from 'moment';
import { AddressField } from '../generic';

export class LoadFeedSearchForm {
  pickUpLocation: AddressField = null;
  originRadius: number = null;
  deliveryLocation: AddressField = null;
  destinationRadius: number = null;
  allowStops = true;
  equipmentType = null;
  pickupDate: {
    begin: Moment;
    end: Moment;
  } = {
    begin: null,
    end: null,
  };
  deliveryDate: {
    begin: Moment;
    end: Moment;
  } = {
    begin: null,
    end: null,
  };
  office: string = null;
  shipper: string = null;
  orderId: string = null;

  constructor(controlConfig) {
    const key = keys(this);
    const defaultValue = [''];

    forEach(key, (prop) => {
      const control = (controlConfig && controlConfig[prop]) || defaultValue;

      this[prop] = control;
    });
  }
}
