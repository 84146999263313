import { Injectable } from '@angular/core';
import {
  CreateCarrierNote,
  GetCalKpiMetrics,
  GetCarrierApiCarrierByDot,
  GetCarrierByDot,
  GetCarrierById,
  GetCarrierDetailByDot,
  GetCarrierInsuranceAndSafetyQuery,
  GetCarrierMatchesByLoadAndBrokerId,
  GetCarriersByNameOrDot,
  GetCarrierUSXData,
  GetRecommendedCarriersByLoadId,
} from '@haulynx/gql';
import {
  Carrier,
  CarrierApiCarrier,
  CarrierApiNoteInput,
  CarrierDetail,
  CarrierInsuranceAndSafety,
  CarrierKpiMetrics,
  CarrierMatch,
  CarrierSourceType,
  CarrierUsx,
  GraphqlSearchResponse,
  MergedCarrierData,
  PageAndSort,
  RecommendedCarriers,
} from '@haulynx/types';
import { getTruthyFields } from '@haulynx/utils';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GraphqlService } from '../graphql.service';

@Injectable({
  providedIn: 'root',
})
export class CarriersService {
  constructor(private graphqlService: GraphqlService) {}

  search(variables: any): Observable<{ entities: unknown[]; total: number }> {
    return this.graphqlService
      .query({
        variables,
        query: GetCarriersByNameOrDot,
      })
      .pipe(
        map(({ data }) => {
          const { result = [], total = 0 } = data['getCarriersByNameOrDot'] || {};

          return { entities: result, total };
        })
      );
  }

  searchNonLeadCarriers(variables: unknown): Observable<{ entities: unknown[]; total: number }> {
    return this.search(variables).pipe(
      map((result) => {
        const filteredCarriers = result.entities.filter(
          (carrier: Carrier) => carrier.sourceType !== CarrierSourceType.LEAD
        );
        const total = filteredCarriers.length;
        return {
          entities: filteredCarriers,
          total,
        };
      })
    );
  }

  getCarrierByDot(dot: string): Observable<Carrier> {
    return this.graphqlService
      .query({
        query: GetCarrierByDot,
        variables: {
          dot,
        },
      })
      .pipe(map(({ data }) => data && data['getCarrierByDot']));
  }

  getCarrierById(id: string): Observable<Carrier> {
    return this.graphqlService
      .query({
        query: GetCarrierById,
        variables: {
          id,
        },
      })
      .pipe(map(({ data }) => data && data['getCarrierById']));
  }

  getCarrierApiCarrierByDot(dot: string): Observable<CarrierApiCarrier> {
    return this.graphqlService
      .query({
        query: GetCarrierApiCarrierByDot,
        variables: {
          dot,
        },
      })
      .pipe(map(({ data }) => data && data['getCarrierApiCarrierByDot']));
  }

  getCarrierDetailByDot(dot: string): Observable<CarrierDetail> {
    return this.graphqlService
      .query({
        query: GetCarrierDetailByDot,
        variables: {
          dot,
        },
      })
      .pipe(map(({ data }) => data && data['getCarrierDetailByDot']));
  }

  // TODO: fix resolver for getCarrierByDot and delete this
  getCarrierWithExtraDetails(dot: string): Observable<Carrier> {
    return forkJoin([this.getCarrierByDot(dot), this.getCarrierDetailByDot(dot)]).pipe(
      map(
        ([carrier, carrierDetail]) =>
          carrier &&
          getTruthyFields<Carrier>(carrier, carrierDetail, ['owner', 'sourceType', 'email', 'phone', 'mcNumber'])
      )
    );
  }

  getCarrierMatchesByLoadAndBrokerId(
    loadId: string,
    brokerId: string,
    paging: PageAndSort
  ): Observable<GraphqlSearchResponse<CarrierMatch>> {
    return this.graphqlService
      .query({
        query: GetCarrierMatchesByLoadAndBrokerId,
        variables: {
          loadId,
          brokerId,
          paging,
        },
      })
      .pipe(
        map(({ data }) => {
          const { total = 0, result = [] } = data['getCarrierMatchesByLoadAndBrokerId'] || {};

          return { total, entities: result };
        })
      );
  }

  getRecommendedCarriersByLoadId(
    loadId: string,
    paging: PageAndSort
  ): Observable<GraphqlSearchResponse<RecommendedCarriers>> {
    return this.graphqlService
      .query({
        query: GetRecommendedCarriersByLoadId(),
        variables: {
          loadId,
          paging,
        },
      })
      .pipe(
        map(({ data }) => {
          const { total = 0, result = [] } = data['getRecommendedCarriersByLoadId'] || {};

          return { total, entities: result };
        })
      );
  }

  getCalKpiMetrics(dot: string, dateTime: string): Observable<CarrierKpiMetrics> {
    return this.graphqlService
      .query({
        query: GetCalKpiMetrics,
        variables: {
          dot,
          dateTime,
        },
      })
      .pipe(map(({ data }) => data['getCalKpiMetrics']));
  }

  getCarrierUSXData(dot: string | number): Observable<CarrierUsx> {
    return this.graphqlService
      .query({
        query: GetCarrierUSXData,
        variables: {
          dot,
        },
      })
      .pipe(map(({ data }) => data['getCarrierUSXData']));
  }

  getInsuranceAndSafety(dot: number): Observable<CarrierInsuranceAndSafety> {
    if (Number.isNaN(Number(dot))) {
      throw new Error('Dot must be a number');
    }

    return this.graphqlService
      .query<{
        getCarrierInsuranceAndSafety: CarrierInsuranceAndSafety;
      }>({
        query: GetCarrierInsuranceAndSafetyQuery,
        variables: {
          dot,
        },
      })
      .pipe(
        map(({ data }) => {
          const { getCarrierInsuranceAndSafety } = data;

          return getCarrierInsuranceAndSafety;
        })
      );
  }

  getMergedCarrierData(dot: string): Observable<MergedCarrierData> {
    return forkJoin([
      this.search({ search: dot, paging: { limit: 1 } }),
      this.getInsuranceAndSafety(parseInt(dot)).pipe(
        map((res) => res),
        catchError((e) => of({}))
      ),
      this.getCarrierApiCarrierByDot(dot),
      this.getCarrierByDot(dot),
    ]).pipe(
      map(([searchResults, insuranceAndSafety, carrierApiCarrier, carrierByDot]) => {
        const searchResult: Carrier = searchResults?.entities?.[0];
        return {
          id: carrierByDot?.id,
          status: searchResult?.status || carrierByDot?.status,
          name: searchResult?.name || carrierByDot?.name,
          owner: searchResult?.owner || carrierByDot?.owner,
          mcNumber: searchResult?.mcNumber || carrierByDot?.mcNumber,
          dot: searchResult?.dot || carrierByDot?.dot,
          sourceType: searchResult?.sourceType || carrierByDot?.sourceType,
          phone: searchResult?.phone || carrierByDot?.phone,
          email: searchResult?.email || carrierByDot?.email,
          addressCity: carrierByDot?.addressCity,
          addressState: carrierByDot?.addressState,
          adminName: searchResult?.adminName || carrierByDot?.adminName,
          notes: carrierApiCarrier?.notes,
          insuranceAndSafety,
        } as MergedCarrierData;
      })
    );
  }

  tryFindCarrierIdByDot(dot: string): Observable<string> {
    return this.getCarrierByDot(dot).pipe(
      map((carrier) => {
        if (carrier) {
          return carrier.id;
        } else {
          return dot;
        }
      })
    );
  }
}
