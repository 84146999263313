import { IColumns, IColumns2, User } from '@haulynx/types';
import { get } from 'lodash';

export const getDeviceColumns = (user: User): IColumns2[] => {
  const columns: IColumns2[] = [];

  columns.push(
    {
      label: 'Carrier',
      field: 'carrier.name',
      isCustomCell: true,
      width: '150px',
      isSortable: true,
      dropdownAlign: 'left',
      sortConfig: [
        {
          emitSort: false,
          label: 'Carrier',
          orderByIndex: ['carrier.name'],
        },
      ],
      isVisible: true,
    },
    {
      label: 'VIN',
      field: 'vin',
      isCustomCell: true,
      width: '200px',
      isSortable: true,
      dropdownAlign: 'left',
      sortConfig: [
        {
          emitSort: false,
          label: 'VIN',
          orderByIndex: ['vin'],
        },
      ],
      isVisible: true,
    },
    {
      label: 'IMEI',
      field: 'imei',
      isCustomCell: true,
      width: '320px',
      isSortable: true,
      dropdownAlign: 'left',
      sortConfig: [
        {
          emitSort: false,
          label: 'IMEI',
          orderByIndex: ['imei'],
        },
      ],
      isVisible: true,
    },
    {
      label: 'Unit ID',
      field: 'unitId',
      isCustomCell: true,
      width: '200px',
      isSortable: true,
      dropdownAlign: 'left',
      sortConfig: [
        {
          emitSort: false,
          label: 'Unit ID',
          orderByIndex: ['unitId'],
        },
      ],
      isVisible: true,
    },
    {
      label: 'Driver',
      field: 'driver',
      isCustomCell: true,
      width: '200px',
      isSortable: true,
      dropdownAlign: 'left',
      sortConfig: [
        {
          emitSort: false,
          label: 'Driver',
          orderByIndex: ['driver'],
        },
      ],
      isVisible: user.isHaulynxAdmin,
    },
    {
      label: 'Last Location',
      field: 'lastLocation.approximateAddress',
      isCustomCell: true,
      width: '200px',
      isSortable: true,
      dropdownAlign: 'left',
      sortConfig: [
        {
          emitSort: false,
          label: 'Last Location',
          orderByIndex: ['lastLocation.approximateAddress'],
        },
      ],
      isVisible: true,
    },
    {
      label: 'Last Location Update',
      field: 'lastLocationUpdate',
      isCustomCell: true,
      width: '200px',
      isSortable: true,
      dropdownAlign: 'left',
      sortConfig: [
        {
          emitSort: false,
          label: 'Last Location Update',
          orderByIndex: ['lastLocationUpdate'],
        },
      ],
      isVisible: true,
    },
    {
      label: 'Last Updated',
      field: 'lastUpdated',
      isCustomCell: true,
      width: '200px',
      isSortable: true,
      dropdownAlign: 'left',
      sortConfig: [
        {
          emitSort: false,
          label: 'Last Updated',
          orderByIndex: ['lastUpdated'],
        },
      ],
      isVisible: user.isHaulynxAdmin,
    },
    {
      label: 'Last Updated By',
      field: 'lastUpdatedBy',
      isCustomCell: true,
      width: '200px',
      isSortable: true,
      dropdownAlign: 'left',
      sortConfig: [
        {
          emitSort: false,
          label: 'Last Updated By',
          orderByIndex: ['lastUpdatedBy'],
        },
      ],
      isVisible: user.isHaulynxAdmin,
    },
    {
      label: 'ELD First Installed',
      field: 'eldFirstInstalled',
      isCustomCell: true,
      width: '200px',
      isSortable: true,
      dropdownAlign: 'left',
      sortConfig: [
        {
          emitSort: false,
          label: 'ELD First Installed',
          orderByIndex: ['eldFirstInstalled'],
        },
      ],
      isVisible: user.isHaulynxAdmin,
    },
    {
      label: 'Shipping',
      field: 'shipping',
      isCustomCell: true,
      width: '200px',
      isSortable: true,
      dropdownAlign: 'left',
      sortConfig: [
        {
          emitSort: false,
          label: 'Shipping',
          orderByIndex: ['shipping'],
        },
      ],
      isVisible: user.isHaulynxAdmin,
    },
    {
      label: 'Return',
      field: 'return',
      isCustomCell: true,
      width: '200px',
      isSortable: true,
      dropdownAlign: 'left',
      sortConfig: [
        {
          emitSort: false,
          label: 'Return',
          orderByIndex: ['return'],
        },
      ],
      isVisible: user.isHaulynxAdmin,
    },
    {
      label: 'Status',
      field: 'status',
      isCustomCell: true,
      width: '200px',
      isSortable: true,
      dropdownAlign: 'left',
      sortConfig: [
        {
          emitSort: false,
          label: 'Status',
          orderByIndex: ['status'],
        },
      ],
      isVisible: user.isHaulynxAdmin,
    },
    {
      label: 'Deactivated',
      field: 'cellularCarrierDeactivated',
      isCustomCell: true,
      isSortable: true,
      width: '200px',
      dropdownAlign: 'left',
      sortConfig: [
        {
          emitSort: false,
          label: 'Deactivated',
          orderByIndex: ['cellularCarrierDeactivated'],
        },
      ],
      isVisible: user.isHaulynxAdmin,
    },
    {
      label: 'Notes',
      field: 'notes',
      isCustomCell: true,
      width: '200px',
      isSortable: true,
      dropdownAlign: 'left',
      sortConfig: [
        {
          emitSort: false,
          label: 'Notes',
          orderByIndex: ['notes'],
        },
      ],
      isVisible: user.isHaulynxAdmin,
    },
    {
      label: 'ICCI',
      field: 'icci',
      isCustomCell: true,
      width: '200px',
      isSortable: true,
      dropdownAlign: 'left',
      sortConfig: [
        {
          emitSort: false,
          label: 'ICCI',
          orderByIndex: ['icci'],
        },
      ],
      isVisible: user.isHaulynxAdmin,
    },
    {
      label: '',
      field: 'type',
      isCustomCell: true,
      width: '100px',
      isSortable: true,
      dropdownAlign: 'left',
      sortConfig: [
        {
          emitSort: false,
          label: '',
          orderByIndex: ['type'],
        },
      ],
      isVisible: true,
    }
  );

  return columns;
};

export const getTruckColumns = (user: User): IColumns[] => {
  const columns: IColumns[] = [];

  columns.push(
    {
      label: 'Truck ID',
      dataIndex: 'unitId',
    },
    {
      label: 'VIN',
      dataIndex: 'vin',
      isCustomCell: true,
    },
    {
      label: 'Make',
      dataIndex: 'make',
    },
    {
      label: 'Model',
      dataIndex: 'model',
    },
    {
      label: 'Year',
      dataIndex: 'year',
    },
    {
      label: 'Telematics Device',
      dataIndex: 'imei',
      isCustomCell: true,
      cls: user.isHaulynxAdmin ? 'telematics-column' : '',
    },
    {
      label: 'Current Driver',
      dataIndex: 'user.name',
      defaultValue: '',
    },
    {
      label: 'Co-Drivers',
      dataIndex: 'coDrivers',
      isCustomCell: true,
      defaultValue: '',
    },
    {
      label: 'Last Location',
      dataIndex: 'locationName',
      isCustomCell: true,
    },
    {
      label: 'Last Updated',
      dataIndex: 'lastUpdated',
      isCustomCell: true,
    },
    {
      label: '',
      dataIndex: 'eld',
      defaultValue: '',
      isCustomCell: true,
    }
  );

  return columns;
};

export const getTrailerColumns = (user: User, carrierId: string): IColumns[] => {
  const columns: IColumns[] = [];
  columns.push(
    {
      label: 'Trailer Number',
      dataIndex: 'trailerNumber',
    },
    {
      label: 'Trailer Type',
      dataIndex: 'type',
    }
  );

  if (user.isHaulynxAdmin || (user.isCompanyAdmin && get(user, 'carrier.id', null) === carrierId)) {
    columns.push({
      label: 'Edit Trailer',
      dataIndex: 'editTrailer',
      isCustomCell: true,
      isSortable: false,
      align: 'right',
    });
  }
  return columns;
};
