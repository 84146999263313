import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  constructor(public meta: Meta) {}

  /**
   * This sets the viewport to equal the device width and therefore act like a mobile viewport
   */
  public setMobileViewport(): void {
    this.meta.addTag({
      name: 'viewport',
      content: 'maximum-scale=1.0,user-scalable=no,width=device-width,initial-scale=1',
    });
  }

  /**
   * Remove the mobile viewport to reset the app to be how it has been
   */
  public removeMobileViewport(): void {
    this.meta.removeTag('name="viewport"');
  }
}
