import { MapsAPILoader } from '@agm/core';
import { MapsEventListener } from '@agm/core/services/google-maps-types';
import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NG_VALUE_ACCESSOR,
  NgForm,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { get, head, last, trim } from 'lodash';
import { AddressTypes } from './google-address-field.config';

@Component({
  selector: 'google-address-field',
  templateUrl: './google-address-field.component.html',
  styleUrls: ['./google-address-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GoogleAddressFieldComponent),
      multi: true,
    },
  ],
})
export class GoogleAddressFieldComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @ViewChild('addressInputElement', { static: true }) public addressElement: ElementRef;
  @Input() control: FormControl;
  @Input() showErrors = true;
  @Input() lat = null;
  @Input() lon = null;
  @Input() showSuffixIcon = null;
  @Input() placeholder = 'Address';
  @Input() parentForm: FormGroup = null;
  @Output() onGpsLocation = new EventEmitter();
  @Output() onIconClick = new EventEmitter();
  @Output() onStateSelected = new EventEmitter<boolean>();
  address: string;
  errorMatcher = new CrossFieldErrorMatcher();
  private mapsAPIPromise: Promise<void>;

  constructor(private mapsApiLoader: MapsAPILoader) {}

  private autoCompleteListener: MapsEventListener;
  private onChange: Function;
  public onTouch: Function;

  ngOnInit() {
    this.mapsAPIPromise = this.mapsApiLoader.load();
    this.mapsAPIPromise.then(() => {
      const autoCompleteWrapper = new google.maps.places.Autocomplete(this.addressElement.nativeElement);
      this.autoCompleteListener = autoCompleteWrapper.addListener('place_changed', () => {
        // assign the longitude and latitude and address to the parent form. Use the field keys from our inputs above.
        const place: google.maps.places.PlaceResult = autoCompleteWrapper.getPlace();
        const lat = place.geometry.location.lat();
        const lon = place.geometry.location.lng();
        const isStateSelected = this.isState(place.types);
        this.address = trim(place.formatted_address);

        this.onChange(this.address);
        this.onGpsLocation.emit({ lat, lon });
        this.control.patchValue(this.address);
        this.onStateSelected.emit(isStateSelected);
      });
    });
  }

  isState(types: string[] = []): boolean {
    return types.length === 2 && head(types) === AddressTypes.STATE && last(types) === AddressTypes.COUNTRY;
  }

  change(event) {
    const value = get(event, 'target.value', null);

    if (!value || value !== this.address) {
      const lat = null,
        lon = null;
      this.onGpsLocation.emit({ lat, lon });
    }
  }

  writeValue(value: string): void {
    const address = trim(value);

    if (address !== this.control.value) {
      this.control.setValue(value);
    }

    if (!address) {
      this.onGpsLocation.emit({ lat: null, lon: null });
    }
  }

  registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    // isDisabled ? this.control.disable() : this.control.enable();
  }

  onSuffixIconClick(event) {
    event.stopPropagation();

    this.onIconClick.emit();
  }

  ngOnDestroy() {
    if (this.autoCompleteListener) {
      this.autoCompleteListener.remove();
    }
  }
}

class CrossFieldErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.dirty && form.hasError('notSelectedValues');
  }
}
