<div
  class="ds"
  [ngStyle]="{
    'flex-direction': isVertical ? 'column' : 'row'
  }"
  #wrapper
>
  <div
    class="ds__container-one"
    [ngStyle]="{
      'min-width': limits?.left ? limits?.left.min : 'inherit',
      'max-width': limits?.left ? limits?.left.max : 'inherit',
      'min-height': limits?.top ? limits?.top.min : 'inherit',
      'max-height': limits?.top ? limits?.top.max : 'inherit',
      width: leftWidth ? leftWidth : 'inherit',
      height: topHeight ? topHeight : 'inherit'
    }"
  >
    <ng-container *ngTemplateOutlet="leftTopTemplate"></ng-container>
  </div>

  <div class="ds__slider-mechanism">
    <div
      class="ds__slider-grabber"
      [ngStyle]="{
        width: isVertical ? '100px' : '2px',
        height: isVertical ? '2px' : '100px',
        top: isVertical ? '2px' : 'calc(50% - 50px)',
        left: isVertical ? 'calc(50% - 50px)' : '2px',
        cursor: isVertical ? 'row-resize' : 'col-resize'
      }"
      #sliderMechanism
    ></div>
  </div>

  <div
    class="ds__container-two"
    [ngStyle]="{
      'min-width': limits?.right ? limits?.right.min : 'inherit',
      'max-width': limits?.right ? limits?.right.max : 'inherit',
      'min-height': limits?.bottom ? limits?.bottom.min : 'inherit',
      'max-height': limits?.bottom ? limits?.bottom.max : 'inherit'
    }"
  >
    <ng-container *ngTemplateOutlet="rightBottomTemplate"></ng-container>
  </div>
</div>
