import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { ReactiveComponentModule } from '@ngrx/component';
import { NgxCurrencyModule } from 'ngx-currency';
import { DropDownModule } from '../../../drop-down/drop-down.module';
import { CarrierToolbarBidsComponent } from './carrier-toolbar-bids.component';

@NgModule({
  declarations: [CarrierToolbarBidsComponent],
  exports: [CarrierToolbarBidsComponent],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    PipesModule,
    DropDownModule,
    ReactiveComponentModule,
    NgxCurrencyModule,
  ],
})
export class CarrierToolbarBidsModule {}
