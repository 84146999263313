import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@haulynx/modules';
import { SidebarNavSubTemplateComponent } from './sidebar-nav-sub-template.component';

@NgModule({
  declarations: [SidebarNavSubTemplateComponent],
  imports: [CommonModule, FlexLayoutModule, MaterialModule],
  exports: [SidebarNavSubTemplateComponent],
})
export class SidebarNavSubTemplateModule {}
