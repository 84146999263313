import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@haulynx/modules';
import { ActionButtonsModule } from '../actions-buttons/action-buttons.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { DeleteUserDialog } from './components/user-delete/user-delete.component';
import { UserFormComponent } from './components/user-form/user-form.component';

@NgModule({
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule, SpinnerModule, ActionButtonsModule],
  declarations: [DeleteUserDialog, UserFormComponent],
  exports: [DeleteUserDialog, UserFormComponent],
})
export class UsersComponentModule {}
