import { combineAdapters } from '../../shared/store/helpers/adapter';
import { createSearchAdapter, SearchAdapter } from '../../shared/store/search/adapter';

export const TYPE = '[USERS]';

const mapState = {
  search: createSearchAdapter,
};

export interface IUserStateAdapter {
  search: SearchAdapter<any>;
}

export const userAdapter: IUserStateAdapter = combineAdapters<IUserStateAdapter>(mapState, TYPE);
