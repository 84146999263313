export enum ANALYTICS_EVENT {
  ADD_LANE = 'add lane',
  BOOK_LOAD = 'book load',
  SAVE_LOAD_CHANGES = '[clicked] save changes',
  SAVE_LOAD_CHANGES_ERROR = '[error] save changes',
  SHARE_LOAD = '[clicked] share',
  LOAD_CHECK_IN = '[update] arrival time',
  LOAD_CHECK_OUT = '[update] departure time',

  BOOK_NON_OFFERED_LOAD = 'bookedNonOfferedLoad',
  BROKER_OFFER_LOAD_CLICKED = 'brokerOfferLoadClicked',
  ACCEPTED_LOAD_OFFER = 'acceptedLoadOffer',
  RECOMMENDED_LOAD_CLICKED = 'recommendedLoadClicked',
  LOAD_ADD_STOP = 'loadAddStop',
  BASIC_LOAD_CLICKED = 'searchedLoadClicked',

  TRACKING_TYPE = 'load tracking type',
  BROKER_ADD_TRUCK_INFO = 'broker adds truck info',
  BROKER_ADD_DRIVER_INFO = 'brokerAddDriverInfo',

  OFFER_CAPTURED = 'offerCaptured',

  BROKER_EMAIL_OFFER = 'broker email offer clicked',
  BROKER_SEND_OFFER = 'broker send offer',

  CARRIER_DETAILS = 'carrier details clicked',
  CARRIER_LIST_SEARCH = 'carrier list search clicked',
  CARRIER_SEARCH = 'carrier search',

  LOAD_FEED_REMOVE_TAB = 'load feed remove tab',
  LOAD_FEED_TAB_SELECTED = 'load feed tab selected',
  LOAD_FEED_TOGGLE_PANEL = 'toggle load feed panel',

  CRM_TAB_SELECT = 'CRM tab selected',
  CRM_EMAIL_CLICKED = 'CRM email clicked',
  CRM_PHONE_CLICKED = 'CRM phone clicked',
  CRM_LANE_CHECKED = 'CRM lane checked',
  CRM_TRUCK_CHECKED = 'CRM truck checked',
  CRM_LANE_SCROLL = 'CRM lane scrolling',

  CARRIER_TOOLBAR_REC_PHONE_CLICKED = 'carrier toolbar rec phone clicked',
  CARRIER_TOOLBAR_REC_BID_CLICKED = 'carrier toolbar rec bid clicked',
  CARRIER_TOOLBAR_REC_BOOK_CLICKED = 'carrier toolbar rec book clicked',

  CARRIER_TOOLBAR_SEARCH_PHONE_CLICKED = 'carrier toolbar search phone clicked',
  CARRIER_TOOLBAR_SEARCH_BID_CLICKED = 'carrier toolbar search bid clicked',
  CARRIER_TOOLBAR_SEARCH_BOOK_CLICKED = 'carrier toolbar search book clicked',

  CARRIER_TOOLBAR_OWNED_PHONE_CLICKED = 'carrier toolbar owned carrier phone clicked',
  CARRIER_TOOLBAR_OWNED_BID_CLICKED = 'carrier toolbar owned carrier bid clicked',
  CARRIER_TOOLBAR_OWNED_BOOK_CLICKED = 'carrier toolbar owned carrier book clicked',

  DELETE_RECOMMENDED_LOAD = 'delete recommended load',

  LOAD_DETAILS = 'load details',
  LOAD_DETAILS_CLOSE = 'load details close',

  LOAD_FEED_BROKER_OFFERS_SEARCH = 'broker offers load feed search',
  LOAD_FEED_EXCLUSIVE_SEARCH = 'exclusive offers load feed search',
  LOAD_FEED_SEARCH = 'loadFeedSearch',
  LOAD_FEED_RECOMMENDED_SEARCH = 'recommended load feed search',

  MAP_INTERACTION = 'map interation',
  MAP_LOAD_CLICKED = 'map load clicked',

  MORE_RECOMMENDED_LOADS = 'more recommended loads clicked',
  NAV_TOGGLE = 'toggle nav',
  NAV_ROUTE_CLICK = 'nav route clicked',

  REMOVE_BROKER_OFFER = 'remove broker offer',
  REJECT_LOAD = 'reject load',

  SETTING_LOGOUT = 'loggedOut',
  VIEW_ACCOUNT = 'accountVisited',
  VIEW_HELP = 'helpVisited',
  VIEW_ALL_AVAILABLE = 'allAvailableloadsVisited',
  VIEW_BIDS_OFFERS = 'myLoadsBidsOffersVisited',
  VIEW_RECOMMENDED = 'recommendationsVisited',
  VIEW_ALL_MY_LOADS = 'allMyLoadsvisited',
  VIEW_WHATS_NEW = 'whatsNewVisited',
  VIEW_COMPLETED_LOADS = 'completedLoadsVisited',
  VIEW_FUEL = 'fuelVisited',
  VIEW_LOGS = 'logsVisited',
  VIEW_NOTIFICATIONS = 'notificationCenterVisited',
  VIEW_ACTIVE_LOADS = 'activeLoadsVisited',
  VIEW_LOAD_FEED = 'loadFeedVisited',
  VIEW_LOAD_OVERVIEW = 'loadOverviewVisited',
  VIEW_FLEET = 'fleetVisited',
  VIEW_USERS = 'usersVisited',
  CREATE_NEW_USER = 'createNewUser',
  VIEW_TERMS_SERVICE = 'termsOfServiceVisited',
  VIEW_PRIVACY_POLICY = 'privacyPolicyVisited',
  VIEW_INTERCHANGE_AGREEMENT = 'interchangeAgreementVisited',

  BID_SUBMITTED = 'bidSubmitted',
  BID_COUNTER_ACCEPTED = 'counterOfferAccepted',
  BROKER_MILESTONE_EDITED = 'brokerMilestoneEdited',
  BROKER_MILESTONE_CREATED = 'brokerMilestoneCreated',
  BROKER_MILESTONE_COMMENT_CREATED = 'brokerMilestoneCommentCreated',
  LOAD_TRACKING_TYPE_ADDED = 'trackingTypeAdded',

  WEB_BID_UPDATE_ERROR = 'Web: updatebidError',
  WEB_BID_UPDATE_SUCCESS = 'Web: updateBidSuccess',
  WEB_BOOK_IT_NOW_CLICKED = 'Web: bookItNowClicked',
  WEB_BOOK_IT_NOW_ERROR = 'Web: bookItNowError',
  WEB_BOOK_IT_NOW_SUCCESS = 'Web: bookItNowSuccess',
  WEB_CONTACT_TO_BOOK_CLICKED = 'Web: contactToBookClicked',
  WEB_DETAILS_VIEWED = 'Web: loadDetailsViewed',
  WEB_LOAD_SEARCH = 'Web: loadSearch',
  WEB_ACCEPTED_AND_BOOKED_COUNTER_OFFER_SUCCESS = 'Web: acceptedCounterOfferAndBookedLoadSuccess',
  WEB_ACCEPTED_AND_BOOKED_COUNTER_OFFER_ERROR = 'Web: acceptedCounterOfferAndBookedLoadError',
  WEB_CREATE_BID_SUCCESS = 'Web: createBidSuccess',
  WEB_CREATE_BID_ERROR = 'Web: createBidError',
  WEB_BROKER_OFFER_LOAD_DETAILS_CLICKED = 'Web: brokerOfferLoadDetailsClicked',
  WEB_ACCEPTED_OFFERED_LOAD = 'Web: acceptedOfferedLoad',
  WEB_DECLINED_COUNTER_OFFER = 'Web: declinedCounterOffer',
}
