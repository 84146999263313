import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LoadDetailsModel, LoadEntityService } from '@haulynx/store';
import {
  ActionButtonsTypes,
  buttonTypes,
  FeatureFlag,
  LoadIdentifierType,
  LoadsServiceLoad,
  LoadsServiceRestrictionTypes,
} from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BounceCarrierDialogComponent } from '../dialogs/bounce-carrier-dialog/bounce-carrier-dialog.component';

@Component({
  selector: 'app-active-load-details-pane',
  templateUrl: './active-load-details-pane.component.html',
  styleUrls: ['./active-load-details-pane.component.scss'],
})
export class ActiveLoadDetailsPaneComponent implements OnChanges {
  @Input() load: LoadsServiceLoad = null;
  @Input() sectionTitle: string | null = null;
  @Input() showLoadEditButton = false;
  @Input() showAllNotesButton = false;
  @Input() showEditPriceButton = false;
  @Input() showEditPayButton = false;
  @Input() isLoadingEditPaymentPrice = false;
  @Output() editLoad = new EventEmitter<LoadsServiceLoad>();
  @Output() priceChanged = new EventEmitter<string | number>();
  @Output() viewAllNotes = new EventEmitter<LoadsServiceLoad>();
  @Output() editPay = new EventEmitter<string>();
  @Output() loadDetails = new EventEmitter<void>();

  public loadServiceLoad$: Observable<LoadsServiceLoad>;

  alive = aliveWhile();
  loading = false;
  editButtons$ = new BehaviorSubject([]);
  featureFlag = FeatureFlag.BOUNCE_CARRIER;
  maxBuy$: Observable<number>;
  loadsServiceRestrictionTypes = LoadsServiceRestrictionTypes;
  loadIdentifierType = LoadIdentifierType;
  private actionButtonsTypes: ActionButtonsTypes = buttonTypes;

  constructor(
    public loadDetailsModel: LoadDetailsModel,
    public loadEntityService: LoadEntityService,
    private dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loadEntityService.getLoadByIdManager.dispatch(this.load.id);
    this.loadServiceLoad$ = this.loadEntityService.getLoadByIdManager.getEntityById(this.load.id);
    this.maxBuy$ = this.loadServiceLoad$?.pipe(map((load) => load?.paymentDetails?.maxBuy));
  }

  onPriceChanged(price: string | number): void {
    this.priceChanged.emit(price);
  }

  onViewAllNotes(load: LoadsServiceLoad): void {
    this.viewAllNotes.emit(load);
  }

  onEditPayDetails(event: Event, loadId: string): void {
    event.stopPropagation();
    this.editPay.emit(loadId);
  }

  action(action: string): void {
    if (action === this.actionButtonsTypes.LOAD_EDIT.action) {
      this.editLoad.emit(this.load);
    }

    if (action === this.actionButtonsTypes.PAY_EDIT_DETAILS.action) {
      const loadId = this.load && this.load.id;
      this.editPay.emit(loadId);
    }
  }

  openBounceCarrierModal(): void {
    this.dialog
      .open(BounceCarrierDialogComponent, {
        data: {
          carrierName: this.load.carrier.name,
        },
        width: '480px',
        height: '296px',
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.loading = true;
          const orderNumber = this.load.locations?.length && this.load.locations[0].billOfLading;
          this.loadDetailsModel.bounceCarrier(this.load.id, orderNumber);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { showEditPayButton, showLoadEditButton, load } = changes;

    if (showEditPayButton || showLoadEditButton) {
      const currentShowEditPayButton = showEditPayButton ? showEditPayButton.currentValue : this.showEditPayButton;
      const currentShowLoadEditButton = showLoadEditButton ? showLoadEditButton.currentValue : this.showLoadEditButton;
      let editButtons = [];

      if (currentShowEditPayButton) {
        editButtons = [...editButtons, this.actionButtonsTypes.PAY_EDIT_DETAILS];
      }

      if (currentShowLoadEditButton) {
        editButtons = [...editButtons, this.actionButtonsTypes.LOAD_EDIT];
      }

      this.editButtons$.next(editButtons);
    }

    const newLoad = load.currentValue;
    if (this.loading && !newLoad.carrier) {
      this.loading = false;
      this.router.navigateByUrl(`dashboard/load-feeds/my/${this.load.id}`);
    }
  }

  openLoadDetails() {
    this.loadDetails.emit();
  }
}
