import { Component, Input, OnChanges, OnDestroy, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatDialogRef } from '@angular/material/dialog';
import { CarrierTruckFormVmService, MomentService } from '@haulynx/services';
import { equipmentTypes, PostTruckForm, RadiusSearchOption, truckRadiusOptions } from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { chain, map, nth, range } from 'lodash';
import moment from 'moment';
import { DatepickerComponent } from '../../datepicker/datepicker.component';
import { AppDropDownComponent } from '../../drop-down/components/app-drop-down/app-drop-down.component';
import { AppAddressFieldComponent } from '../../google-address-field/components/app-address-field.component';

@Component({
  selector: 'app-carrier-truck-form',
  templateUrl: './carrier-truck-form.component.html',
  styleUrls: ['./carrier-truck-form.component.scss'],
})
export class CarrierTruckFormComponent implements OnChanges, OnDestroy {
  @Input() formState;
  @Input() isLoading;
  @ViewChildren('location') location: QueryList<AppAddressFieldComponent>;
  @ViewChildren('timeAvailable') timeAvailable: QueryList<DatepickerComponent>;
  @ViewChildren('equipmentType') equipmentType: QueryList<AppDropDownComponent>;

  numberTrucks = 5;
  alive = aliveWhile();
  form: FormGroup;
  minDate = moment();
  equipmentTypes: any[] = equipmentTypes;
  truckRadiusOptions: RadiusSearchOption[] = truckRadiusOptions;
  selectedDateMap: { label: string; value: number }[] = this.getDefinedDates();

  get trucks(): FormGroup[] {
    const trucks = this.form.get('trucks') as FormArray;

    return trucks.controls as FormGroup[];
  }

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<CarrierTruckFormComponent>,
    private carrierTruckFormVmService: CarrierTruckFormVmService,
    private momentService: MomentService
  ) {
    const trucksFormArray = map(range(this.numberTrucks), (group, index) => {
      return this.carrierTruckFormVmService.create(group);
    });

    this.form = this.carrierTruckFormVmService.createTrucks(trucksFormArray);
  }

  createTruck() {
    const trucks: PostTruckForm[] = chain(this.trucks)
      .filter((truck: FormGroup) => {
        return truck.valid;
      })
      .map((truck: FormGroup) => {
        return truck.value;
      })
      .value();

    if (trucks.length) {
      this.dialogRef.close(trucks);
    }
  }

  getDefinedDates(): { label: string; value: number }[] {
    const tomorrow = this.momentService.getToday().startOf('day').add(1, 'day').valueOf();
    const plus2Days = this.momentService.getToday().startOf('day').add(2, 'day').valueOf();
    const plus3Days = this.momentService.getToday().startOf('day').add(3, 'day').valueOf();

    return [
      {
        label: 'Tomorrow',
        value: tomorrow,
      },
      {
        label: '+2 days',
        value: plus2Days,
      },
      {
        label: '+3 days',
        value: plus3Days,
      },
    ];
  }

  selectDate(index: number, timeAvailable) {
    this.trucks[index].patchValue({ timeAvailable });
  }

  focusTimeAvailable(index: number): void {
    const timeAvailableInput = nth(this.timeAvailable.toArray(), index).pickerInput;

    timeAvailableInput.nativeElement.focus();
  }

  openEquipmentTypePanel(index: number, event: Event): void {
    const autocomplete = { isOpen: false } as MatAutocomplete;

    nth(this.equipmentType.toArray(), index).showPanel(event, autocomplete);
  }

  focusLocation(index: number): void {
    const locationInput = nth(this.location.toArray(), index + 1).addressElement;

    locationInput.nativeElement.focus();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { formState } = changes;

    if (formState) {
      const formData = formState.currentValue;

      this.form.patchValue(formData ? formData : {});
    }
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }
}
