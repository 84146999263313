<div class="ct_profile">
  <div (click)="onFinishViewing()" class="ct_profile__back" data-test="back-to-carrier-search">
    <mat-icon>arrow_back</mat-icon>
    <div class="ct_profile__back_text">Back to {{ returnText }}</div>
  </div>

  <div *ngIf="isLoading; else profile">
    <mat-spinner [diameter]="36"></mat-spinner>
  </div>

  <div class="additional_info">
    <div class="additional_info__header"><span>Additional Info</span></div>
    <mat-tab-group [selectedIndex]="(state$ | async)?.data?.selectedTab" (selectedTabChange)="tabChange($event)">
      <mat-tab label="Log">
        <div *ngIf="isLoading; else log">
          <mat-spinner [diameter]="36"></mat-spinner>
        </div>
      </mat-tab>
      <mat-tab label="Posted Trucks">
        <div *ngIf="isLoading; else postedTrucks">
          <mat-spinner [diameter]="36"></mat-spinner>
        </div>
      </mat-tab>
      <mat-tab label="Lanes" *appFeatures="LanesFeature">
        <div *ngIf="isLoading; else lanes">
          <mat-spinner [diameter]="36"></mat-spinner>
        </div>
      </mat-tab>
      <mat-tab label="History">
        <div *ngIf="isLoading; else history">
          <mat-spinner [diameter]="36"></mat-spinner>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>

  <ng-template #log>
    <haulynx-carrier-log [carrier]="carrier" [notes]="carrier?.notes"></haulynx-carrier-log>
  </ng-template>

  <ng-template #postedTrucks>
    <haulynx-carrier-posted-trucks [dot]="carrier?.dot"></haulynx-carrier-posted-trucks>
  </ng-template>

  <ng-template #lanes>
    <haulynx-carrier-toolbar-lanes [dot]="carrier?.dot"></haulynx-carrier-toolbar-lanes>
  </ng-template>

  <ng-template #history>
    <haulynx-carrier-history [dot]="carrier?.dot"></haulynx-carrier-history>
  </ng-template>

  <ng-template #profile>
    <div class="ct_profile__header">
      <div class="ct_profile__title" data-test="carrier-name">{{ carrier?.name | titlecase }}</div>
      <div (click)="onViewProfile()" class="ct_profile__title_link">View Profile</div>
    </div>
    <div class="ct_profile__sub_title">Carrier Details</div>

    <div class="ct_profile__body">
      <div class="ct_profile__field">
        <div class="ct_profile__field_title">Status:</div>
        <div class="ct_profile__field_highlight" data-test="carrier-status">
          {{ !carrier?.status ? 'N/A' : carrier?.status }}
        </div>
      </div>
      <div class="ct_profile__field">
        <div class="ct_profile__field_title">Owned:</div>
        <div class="ct_profile__field_value" data-test="carrier-owner">
          {{ !carrier?.owner ? 'N/A' : carrier?.owner }}
        </div>
      </div>
      <div class="ct_profile__field">
        <div class="ct_profile__field_title">MC#:</div>
        <div class="ct_profile__field_value" data-test="carrier-mc-number">
          {{ !carrier?.mcNumber ? 'N/A' : carrier?.mcNumber }}
        </div>
      </div>
      <div class="ct_profile__field">
        <div class="ct_profile__field_title">DOT#:</div>
        <div class="ct_profile__field_value" data-test="carrier-dot-number">
          {{ !carrier?.dot ? 'N/A' : carrier?.dot }}
        </div>
      </div>
      <div class="ct_profile__field">
        <div class="ct_profile__field_title">Type:</div>
        <div class="ct_profile__field_value" data-test="carrier-source-type">
          {{ !carrier?.sourceType ? 'N/A' : carrier?.sourceType }}
        </div>
      </div>
      <div class="ct_profile__field">
        <div class="ct_profile__field_title">Primary Phone:</div>
        <div class="ct_profile__field_value" data-test="carrier-phone-number">
          {{ !carrier?.phone ? 'N/A' : carrier?.phone }}
        </div>
      </div>
      <div class="ct_profile__field">
        <div class="ct_profile__field_title">Primary Email:</div>
        <div class="ct_profile__field_value" data-test="carrier-email">
          {{ !carrier?.email ? 'N/A' : carrier?.email }}
        </div>
      </div>
      <div class="ct_profile__field">
        <div class="ct_profile__field_title">Contact:</div>
        <div class="ct_profile__field_value" data-test="carrier-contact">
          {{ !carrier?.adminName ? 'N/A' : carrier?.adminName }}
        </div>
      </div>
      <div class="ct_profile__field">
        <div class="ct_profile__field_title">Domicile</div>
        <div class="ct_profile__field_value" data-test="carrier-domicile">
          {{ formatDomicile === '' ? 'N/A' : formatDomicile }}
        </div>
      </div>
      <div class="ct_profile__field">
        <div class="ct_profile__field_title">Safety score</div>
        <div class="ct_profile__field_value" data-test="carrier-safety-score">
          {{ carrier?.insuranceAndSafety?.safetyRating }}
        </div>
      </div>
    </div>

    <div *ngIf="expandDetails">
      <div class="ct_profile__sub_title">Insurance</div>

      <div class="ct_profile__body">
        <div class="ct_profile__field">
          <div class="ct_profile__field_title">Auto Expiration:</div>
          <div class="ct_profile__field_value" data-test="carrier-insurance-auto-expiration">
            {{
              !carrier?.insuranceAndSafety?.autoInsuranceExpDate
                ? 'N/A'
                : (carrier?.insuranceAndSafety?.autoInsuranceExpDate | dateTime: '':'MM/dd/yy')
            }}
          </div>
        </div>

        <div class="ct_profile__field">
          <div class="ct_profile__field_title">Auto Limit:</div>
          <div class="ct_profile__field_value" data-test="carrier-insurance-auto-limit">
            {{
              !carrier?.insuranceAndSafety?.autoInsuranceLimit
                ? 'N/A'
                : (carrier?.insuranceAndSafety?.autoInsuranceLimit | number: '1.0':'en-US')
            }}
          </div>
        </div>

        <div class="ct_profile__field">
          <div class="ct_profile__field_title">Cargo Expiration:</div>
          <div class="ct_profile__field_value" data-test="carrier-insurance-cargo-expiration">
            {{
              !carrier?.insuranceAndSafety?.cargoInsuranceExpDate
                ? 'N/A'
                : (carrier?.insuranceAndSafety?.cargoInsuranceExpDate | dateTime: '':'MM/dd/yy')
            }}
          </div>
        </div>

        <div class="ct_profile__field">
          <div class="ct_profile__field_title">Cargo Limit:</div>
          <div class="ct_profile__field_value" data-test="carrier-insurance-cargo-limit">
            {{
              !carrier?.insuranceAndSafety?.cargoInsuranceLimit
                ? 'N/A'
                : (carrier?.insuranceAndSafety?.cargoInsuranceLimit | number: '1.0':'en-US')
            }}
          </div>
        </div>

        <div class="ct_profile__field">
          <div class="ct_profile__field_title">General Expiration:</div>
          <div class="ct_profile__field_value" data-test="carrier-insurance-general-expiration">
            {{
              !carrier?.insuranceAndSafety?.generalInsuranceExpDate
                ? 'N/A'
                : (carrier?.insuranceAndSafety?.generalInsuranceExpDate | dateTime: '':'MM/dd/yy')
            }}
          </div>
        </div>

        <div class="ct_profile__field">
          <div class="ct_profile__field_title">General Limit:</div>
          <div class="ct_profile__field_value" data-test="carrier-insurance-general-limit">
            {{
              !carrier?.insuranceAndSafety?.cargoInsuranceLimit
                ? 'N/A'
                : (carrier?.insuranceAndSafety?.cargoInsuranceLimit | number: '1.0':'en-US')
            }}
          </div>
        </div>
      </div>
    </div>

    <button (click)="toggleDetails()" mat-raised-button class="ct_profile_buton" data-test="expand-details">
      {{ expandDetails ? 'Collapse Details' : 'Expand Details' }}
    </button>

    <div *appFeatures="metricsFeature">
      <div class="ct_profile__sub_title">Platform Metrics</div>

      <div class="ct_profile__body">
        <div class="ct_profile__field">
          <div class="ct_profile__field_title">Total Number of Bookings:</div>
          <div class="ct_profile__field_value" data-test="carrier-total-bookings">
            {{ !loadsMetaData?.totalLoads ? 'N/A' : loadsMetaData?.totalLoads }}
          </div>
        </div>

        <div class="ct_profile__field">
          <div class="ct_profile__field_title">Last Booking:</div>
          <div class="ct_profile__field_value" data-test="carrier-last-booking">
            {{
              !loadsMetaData?.aggregations?.bookedAt?.value
                ? 'N/A'
                : (loadsMetaData?.aggregations?.bookedAt?.value | date: 'MM/dd/yyyy')
            }}
          </div>
        </div>
      </div>

      <!-- <div class="ct_profile__field">
        <div class="ct_profile__field_title">Sign-up date:</div>
        <div class="ct_profile__field_value">N/A</div>
      </div> -->
    </div>
  </ng-template>
</div>
