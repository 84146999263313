import { BulkAction } from './load-bulk-actions.enum';

export interface LaneBulkActionInput {
  bidId: string;
  percentChange?: string;
  percentDollars?: string;
  margin?: string;
  miles: string;
}

export interface LaneBulkActionPayload {
  action: BulkAction;
  laneInputs: Partial<LaneBulkActionInput>[];
}
