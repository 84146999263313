import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CarriersModel } from '@haulynx/store';
import { Tab } from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { takeUntil, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'app-carrier-load-details',
  templateUrl: './carrier-load-details.component.html',
  styleUrls: ['./carrier-load-details.component.scss'],
})
export class CarrierLoadDetailsComponent implements OnDestroy, OnInit {
  alive = aliveWhile();
  constructor(private carriersModel: CarriersModel, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.activatedRoute.params
      .pipe(takeUntil(this.alive), withLatestFrom(this.activatedRoute.queryParams))
      .subscribe(([params, queryParams]) => {
        const { id } = params;
        const tab = new Tab({
          id,
          label: '',
          url: `load/${id}`,
          selected: true,
          closable: true,
          queryParams: queryParams,
        });

        this.carriersModel.addTabs([tab]);
        this.carriersModel.selectTab(tab);
      });
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }
}
