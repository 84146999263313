<main class="container brokerInfo">
  <div class="row">
    <div class="col l12">
      <p><b>Haulynx Is Working With Brokers, Not Trying To Put Them Out Of Business</b><br /></p>
      <p>
        If you are a Freight Broker, you must be aware of the technology companies that are swarming into the
        transportation business. In most cases, their goal is to replace Brokers with fancy new software. While their
        early efforts may have left you laughing, you should also realize that they are a real threat to your business.
        <br />
        Haulynx is taking a different approach. We understand the important role of the Broker in handling the
        inevitable problems that arise during freight transportation. After all, no software is going to be able to deal
        with the fact that a late season produce delivery has been rejected for poor quality. That takes someone who
        understands the business.
        <br />
        Haulynx is partnering with Brokers to allow them to access the Haulynx network to distribute, track, and confirm
        the delivery of loads. Our network of Carriers gives Brokers access to a huge number of available trucks at the
        touch of a button. No more calling endlessly looking for the right Carrier. No more posting on load boards and
        waiting to see what happens. Haulynx makes the process simple.
        <br />
      </p>
      <p>
        <b>Finding A Carrier To Safely Deliver Your Load Is Easier Than Ever </b>
      </p>
      <p>
        The Haulynx network makes finding a Carrier to deliver your load safely and on time easier than it has ever
        been. You simply create a set of requirements for the Carriers you want to do business with. Your requirements
        can include their safety rating, insurance, and other factors. Then you enter the information regarding the load
        and click a button. Your load is then offered to a Carrier who meets your requirements. The Carrier is chosen
        based on a predictive algorithm that takes into account the location of the individual unit, the equipment (e.g.
        flatbed or reefer), and the individual driver’s hours of service availability. Once your load is accepted, and
        the chosen Carrier is identified, you simply click one more button to confirm the transaction. If the chosen
        Carrier is unacceptable despite the fact that it meets your specified requirements, then you can reject the
        Carrier and send the load through our network to find a different Carrier.
      </p>

      <p>
        <b>Real Time Tracking Provides You With The Status Of Your Load</b>
      </p>
      <p>
        Once a Carrier is assigned to your load, you can follow it every step of the way via the Haulynx web
        application. Our map provides the location and status of your load in real time throughout the entire process.
        You can even add loads that you have manually assigned without using the Haulynx load distribution system. When
        the load is delivered you will receive the appropriate confirmation paperwork immediately.
      </p>
      <p>
        <b>Transparent Connections Lead To Greater Efficiency</b>
      </p>
      <p>
        By creating transparent connections between Shippers, Brokers, and Carriers Haulynx takes the guesswork out the
        process. You connect directly with the Carrier, and you have the ability to track your load throughout the
        process. In addition, Haulynx’ predictive analytics software can tell you not only where your load is now, but
        where it will be in the future. This allows you to operate at peak efficiency. A broker using the Haulynx
        network can distribute and track more loads with less effort. This adds money to a Broker’s bottom line.
        <br />
        Load distribution technology is coming to the transportation industry. You can either adapt to the new
        technology, or be destroyed by it. The choice is yours.
      </p>
    </div>
  </div>
</main>
