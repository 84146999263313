import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shipper-info',
  templateUrl: './shipper-info.component.html',
  styleUrls: ['./shipper-info.component.scss'],
})
export class ShipperInfoComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
