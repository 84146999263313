export interface CarrierMatches {
  carrier: string;
  carrierDot: string;
  carrierEmail: string;
  carrierOwner: string;
  carrierPhone: number;
  id: string;
  match: string;
  price: string;
}
