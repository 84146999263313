import { LoadsServiceLoad } from '../../loads-service';
import { Bid, BidDetails } from '../bid';
import { Broker } from '../broker';
import { Carrier, PendingCarrier } from '../carrier';
import { User } from '../generic';
import { DispatchLocation } from '../location';
import { Shipper, Trailer, Truck } from '../truck';
import { LoadLocation } from './load-location';

export class DistributionMechanism {
  public static auto = 0;
  public static manual = 1;
  public static none = 2;
}

export enum DistributionMechanismString {
  AUTO = 'auto',
  MANUAL = 'manual',
  NONE = 'none',
}

export enum DistributionMethod {
  AUTO = 0,
  MANUAL = 1,
  NONE = 2,
}

export class RejectedLoadReason {
  public reason: string;
  public carrier: Carrier;
  public date: string;
}

export enum LoadStage {
  LoadCreated,
  LoadOffered,
  LoadAccepted,
  LoadRejected,
  LoadUpdated,
  LoadDelivered,
  LoadConfirmedDelivered,
  LoadPaidFor,
  LoadReassigned,
  CarrierChanged,
  AwaitingCarrierAcceptance,
  AwaitingDeliveryConfirmation,
  NotAssigned,
  AwaitingDelivery,
  AwaitingPickUp,
  AwaitingBrokerAcceptance,
  BrokerAccepted,
}

export enum LoadStatus {
  ACTIVE = 'active',
  UNASSIGNED = 'unassigned',
  COMPLETED = 'completed',
}

export enum LoadOrderType {
  /**
   * Locked to a specific carrier. Comes to Haulynx already booked.
   */
  DEDICATED = 'dedicated',

  /**
   * Normal freight
   */
  TRANSACTIONAL = 'transactional',

  /**
   * Locked to a specific carrier. Not booked until OrderBridge books it.
   */
  CONTRACTED = 'contracted',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SPOT = 'spot',
}

/**
 * BOOKABLE: Can be searched for in loadFeed and can be booked
 * BOOKED: Cannot be searched for in loadFeed and cannot be booked because a carrier has already booked it
 * VIEWABLE: Can be searched for in loadFeed but cannot be booked
 * PAUSE: Cannot be searched for in loadFeed and cannot be booked, but is not booked
 */
export enum BookStatus {
  BOOKABLE = 'bookable',
  BOOKED = 'booked',
  VIEWABLE = 'viewable',
  PAUSE = 'pause',
  EXCLUDED = 'excluded',
}

export enum TrackingStatus {
  NOT_AVAILABLE = 'Not Available',
  PRENDING_APPROVAL = 'Pending Approval',
  READY_TO_TRACK = 'Ready to Track',
  ACTIVE_TRACKING = 'Active Tracking',
  TRACKING_NOT_RESPONDING = 'Tracking not Responding',
  TRACKING_EXPIRED = 'Tracking Expired',
  TRACKING_COMPLETED = 'Tracking Completed',
}

/**
 * @deprecated use `LoadsServiceLoad` instead
 */
export class Load {
  // mandatory fields start
  id: string = null;
  loadLocations: LoadLocation[] = [];
  // mandatory fields end

  // usx mandatory fields start
  revenue: number;
  exclusiveLoad: boolean = null;
  mongoId: string = null; // Orders that come in from USX will be stored in MongoDB.
  bookStatus: BookStatus = null;
  tmwNumber?: string = null; // usx loads may have a tmw number
  regionalManager?: string;
  regionalManagerEmail?: string;
  regionalManagerPhone?: string;
  // usx mandatory fields end

  pendingCarriers: PendingCarrier[] = null;

  creator: string = null;
  dateCreated: number = null; // UTC timestamp
  dateCompleted: number = null; // UTC timestamp
  templateId: string = null;
  trailerId: string = null;
  trailer?: Trailer;
  status: string = null;
  isPaid = false;
  amountOwed: number = null;
  datePaid: number = null; // UTC timestamp

  shipper: Shipper = null;
  broker: Broker = null;

  carrier: Carrier = null;
  carrier_filter: string = null;
  broker_filter: string = null;
  shipper_filter: string = null;
  carrierAccepted: boolean = null;
  brokerAcceptedCarrier = false;
  loadRejectedByAllCarriers = false;
  timeOffered: number = null;

  driver: User = null;
  distributionMechanism: DistributionMethod;

  loadAssignStatus: string = null;
  loadProgress: string = null;

  lastLocation: Location = null;
  truck: Truck = null;
  unitId: string = null;

  carrierSafetyRating = 'None';
  cargoLiability = 0;
  autoLiability = 0;
  config: any = {};
  networkPreferred: boolean = null;
  pickupCity: string;
  hazmatCapabilityRequired: boolean = null;
  minRequiredInsurance: string = null;

  agreedToTerms = false;
  reasonsForRejection: RejectedLoadReason[] = [];

  loadFeedKey: string = null; // OD-Pair Key location of load in load feed (!null for USX-generated loads only)
  distance: string = null; // Number of miles between pickup and last dropoff
  weight: number = null;
  ratePerMile: string = null;
  exclusiveUntil: number = null;
  exclusivePrice: number = null;
  exclusiveRatePerMile: string = null;
  markerColor?: string = null;
  price: number;

  internalNotes?: string; // these two are not null or undefined for USX loads only
  externalNotes?: string;
  dispatchLocation?: DispatchLocation;

  company?: string; // current values are "01" or "22"
  cxrEmployeeId?: string;

  /**
   * Type of load
   */
  orderType: LoadOrderType;
  customer?: string; // USX number of billTo customer
  customerName?: string; // USX name of billTo customer

  bidDetails?: BidDetails;

  carrierBid: Bid = null;

  constructor(props?: Partial<Load>) {
    Object.assign(this, props);
    if (!props) {
      return;
    }

    const firstLocationWeight =
      (this.loadLocations && this.loadLocations[0] && this.loadLocations[0].loadWeight) || null;

    if (firstLocationWeight && !this.weight) {
      this.weight = Number(firstLocationWeight);
    }

    if (this.distance) {
      const distance = Number(this.distance);

      if (this.price) {
        const price = Number(this.price);

        this.ratePerMile = `${(price / distance).toFixed(2)}`;
      }
      if (this.exclusivePrice) {
        const exclusivePrice = Number(this.exclusivePrice);

        this.exclusiveRatePerMile = `${(exclusivePrice / distance).toFixed(2)}`;
      }
    }
  }
}

export class LoadTemplate extends LoadsServiceLoad {
  name: string = null;

  constructor(props?: LoadTemplate) {
    super(props);
  }
}

export interface IDeleteOffer {
  loadId: string;
  carrierDot: string;
  brokerId: string;
  rejectionReason: string;
}
