import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ShipperInfoRoutingModule } from './shipper-info-routing.module';
import { ShipperInfoComponent } from './shipper-info.component';

@NgModule({
  declarations: [ShipperInfoComponent],
  imports: [CommonModule, ShipperInfoRoutingModule],
})
export class ShipperInfoModule {}
