import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  DataTableModule,
  DropDownModule,
  FeatureFlagDashboardDialogComponent,
  SpinnerModule,
} from '@haulynx/components';
import { MaterialModule } from '@haulynx/modules';
import { ConfirmationModule, FeatureFlagActionService } from '@haulynx/services';
import { FeatureFlagDashboardModel, FeatureFlagDashboardStoreModule } from '@haulynx/store';
import { FeatureFlagDashboardRoutingModule } from './feature-flag-dashboard-routing.module';
import { FeatureFlagDashboardComponent } from './feature-flag-dashboard.component';

@NgModule({
  declarations: [FeatureFlagDashboardComponent, FeatureFlagDashboardDialogComponent],
  imports: [
    CommonModule,
    FeatureFlagDashboardRoutingModule,
    DataTableModule,
    MaterialModule,
    SpinnerModule,
    ReactiveFormsModule,
    DropDownModule,
    ConfirmationModule,
    FeatureFlagDashboardStoreModule,
  ],
  providers: [FeatureFlagDashboardModel, FeatureFlagActionService],
})
export class FeatureFlagDashboardModule {}
