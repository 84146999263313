import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userInitials',
})
export class UserInitialsPipe implements PipeTransform {
  transform(userName): string {
    return userName?.split(' ').reduce((curr, next) => curr.charAt(0) + next.charAt(0));
  }
}
