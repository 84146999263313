import gql from 'graphql-tag';

export const GetCarrierMatchesByLoadAndBrokerId = gql`
  query GetCarrierMatchesByLoadAndBrokerId($loadId: ID!, $brokerId: StrictId, $paging: PageAndSort) {
    getCarrierMatchesByLoadAndBrokerId(loadId: $loadId, brokerId: $brokerId, paging: $paging) {
      total
      result {
        id
        name
        owner
        phone
        dot
        match
        email
        price
        sourceType
      }
    }
  }
`;
