import { CommonModule, CurrencyPipe, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  ActionButtonsModule,
  BidsModule,
  BookLoadModule,
  CarrierBidsModule,
  CarrierDetailsModule,
  ChipListModule,
  CustomerDetailsModule,
  DataTableModule,
  DataTableV2Module,
  DatepickerModule,
  DropDownModule,
  ExpandButtonModule,
  ExpandPanelModule,
  GoogleAddressFieldModule,
  LaunchRmisDialog,
  LoadBidDetailsModule,
  LoadFeedSearchFormModule,
  LoadMainInfo,
  LoadPriceModule,
  LoadTimelineModule,
  MapsModule,
  SelectorModule,
  SpinnerModule,
  TabsModule,
  TooltipMenuModule,
} from '@haulynx/components';
import { DirectivesModule } from '@haulynx/directives';
import { ElementsModule } from '@haulynx/elements';
import { LoadDetailsGuard, LoadFeedPermissionGuard } from '@haulynx/guards';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { ConfirmationModule, LoadFeedFilters } from '@haulynx/services';
import { AsyncEntityLoadStoreModule, CarrierDetailsStoreModule, LoadFeedStoreModule } from '@haulynx/store';
import { LoadFeedDetailsModule } from '../load-feed-details/load-feed-details.module';
import { LoadFeedRoutingModule } from './load-feed-routing.module';
import { LoadFeedContainer } from './load-feed/load-feed.component';

@NgModule({
  declarations: [LaunchRmisDialog, LoadFeedContainer],
  imports: [
    CommonModule,
    LoadFeedRoutingModule,
    LoadFeedStoreModule,
    CarrierDetailsStoreModule,
    ConfirmationModule,
    ActionButtonsModule,
    AngularFireDatabaseModule,
    AsyncEntityLoadStoreModule,
    BidsModule,
    BookLoadModule,
    CarrierBidsModule,
    CarrierDetailsModule,
    ChipListModule,
    DataTableModule,
    DataTableV2Module,
    DatepickerModule,
    DirectivesModule,
    DropDownModule,
    DropDownModule,
    ExpandButtonModule,
    ExpandPanelModule,
    FlexLayoutModule,
    FormsModule,
    GoogleAddressFieldModule,
    LoadBidDetailsModule,
    LoadFeedDetailsModule,
    LoadFeedSearchFormModule,
    LoadMainInfo,
    LoadPriceModule,
    LoadTimelineModule,
    MapsModule,
    MaterialModule,
    PipesModule,
    ReactiveFormsModule,
    SelectorModule,
    SpinnerModule,
    TabsModule,
    TooltipMenuModule,
    CustomerDetailsModule,
    ElementsModule,
  ],
  providers: [
    DecimalPipe,
    CurrencyPipe,
    LoadFeedFilters,
    LoadFeedPermissionGuard,
    CustomerDetailsModule,
    LoadDetailsGuard,
  ],
})
export class LoadFeedModule {}
