<div class="load_active_status">
  <ng-container *ngIf="isLoading$ | async; else showLoadDetails">
    <app-spinner [active]="true"></app-spinner>
  </ng-container>

  <ng-template #showLoadDetails>
    <div *ngIf="title" class="load_active_status__header">
      <span class="title">{{ title }}</span>
      <span class="status" *ngIf="label">{{ label }}</span>
    </div>

    <div *ngIf="title" class="load_active__statuses">
      <div
        *ngFor="let status of statuses; let i = index"
        class="load_active__statuses_row"
        [ngClass]="{ complete: status.completed, active: status.active, inactive: !status.active && !status.completed }"
      >
        <div class="icon" [attr.data-test]="status.id">
          <mat-icon svgIcon="{{ status.completed ? 'haulynx-load-accepted' : 'haulynx-awaiting-confirm' }}"></mat-icon>
        </div>
        <div class="load-status">{{ status.label }}</div>
        <div class="load-date">
          <ng-container *ngIf="status.date; else noDate">
            {{ status.date | timezoned: 'M/D/YYYY @ HH:mm z':'x':status.timezone }}
          </ng-container>
          <ng-template #noDate>
            {{ status.info ? status.info : '' }}
          </ng-template>
        </div>
        <ng-container *ngIf="load && status.active; else notLoad">
          <div class="load-action" data-test="execute" (click)="execute(status.id, i)">
            <span>{{ status.actionLabel }}</span>
          </div>
        </ng-container>

        <ng-template #notLoad>
          <div class="load-action">
            <span>{{ status.actionLabel }}</span>
          </div>
        </ng-template>
      </div>
      <button *ngIf="showEditLoadButton" (click)="onEditLoad(load)" mat-button data-test="edit-load-button">
        Edit Load
      </button>
    </div>
  </ng-template>
</div>
