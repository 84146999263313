import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DialogsModule } from '@haulynx/account';
import { ElementsModule } from '@haulynx/elements';
import { MaterialModule } from '@haulynx/modules';
import { SetupRmisDialogComponent } from './setup-rmis-dialog.component';

@NgModule({
  declarations: [SetupRmisDialogComponent],
  imports: [CommonModule, DialogsModule, FlexLayoutModule, MaterialModule, ElementsModule],
  exports: [SetupRmisDialogComponent],
})
export class SetupRmisDialogModule {}
