import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from '@haulynx/pipes';
import { SpinnerModule } from '../spinner/spinner.module';
import { LoadsServiceLoadPreviewComponent } from './loads-service-load-preview.component';

@NgModule({
  declarations: [LoadsServiceLoadPreviewComponent],
  imports: [CommonModule, SpinnerModule, PipesModule],
  exports: [LoadsServiceLoadPreviewComponent],
})
export class LoadsServiceLoadPreviewModule {}
