<app-spinner [active]="releaseService.isLoadingDetails$ | async" [fullCover]="true"></app-spinner>

<div class="releases">
  <h4>Release Notes</h4>

  <button mat-button class="releases__button" (click)="onOpenReleaseForm()">
    <mat-icon class="releases__button--icon">add</mat-icon>
    Create Release
  </button>
</div>

<data-table
  [data]="releaseNotes$ | async"
  [loading]="releaseService.isLoadingList$ | async"
  [displayedColumns]="columns"
  [templateCellRef]="relationsTemplateRef"
  cls="releases"
  data-test="releases-notes"
>
  <ng-template #relationsTemplateRef let-row="row" let-column="column">
    <ng-container *ngIf="column === 'title'">
      {{ row?.title }}
    </ng-container>

    <ng-container *ngIf="column === 'userType'">
      {{ row?.userType | titlecase }}
    </ng-container>

    <ng-container *ngIf="column === 'date'">
      {{ row?.date | mtz: 'MM/DD/YY HH:mm':timeZone:false }}
    </ng-container>

    <ng-container *ngIf="column === 'expiration'">
      {{ row?.expiration | mtz: 'MM/DD/YY HH:mm':timeZone:false }}
    </ng-container>

    <ng-container *ngIf="column === 'options'">
      <button mat-icon-button class="releases__button--preview" (click)="onPreview(row?.id)">
        <mat-icon>visibility</mat-icon>
      </button>

      <button mat-icon-button (click)="onOpenReleaseForm(row?.id)">
        <mat-icon>edit</mat-icon>
      </button>
    </ng-container>
  </ng-template>
</data-table>
