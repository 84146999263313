import { HttpStatus } from '@haulynx/types';
import { createFeatureSelector } from '@ngrx/store';
import { Map, Record } from 'immutable';

export interface ILoadFeedState2 {
  isLoading: Map<string, boolean>;
  httpStatus: Map<string, HttpStatus>;
}

const initialLoadFeedState = {
  isLoading: Map<any, any>(),
  httpStatus: Map<any, any>(),
};

export class LoadPriceState extends Record(initialLoadFeedState) implements ILoadFeedState2 {
  isLoading: Map<string, boolean>;
  httpStatus: Map<string, HttpStatus>;
}

export const getLoadPriceState = createFeatureSelector<LoadPriceState>('load-price');
