import gql from 'graphql-tag';

export const UpdateBidMutation = gql`
  mutation UpdateBid($bidId: StrictId!, $bidUpdate: UpdateBidInput!) {
    updateBid(bidId: $bidId, bidUpdate: $bidUpdate) {
      id
      price
      status
      carrierId
      carrier {
        name
        dot
      }
      loadId
      notes
      createdAt
      createdBy {
        id
        name
      }
      updatedAt
      updatedBy {
        id
        name
      }
      finalStateFrom
    }
  }
`;
