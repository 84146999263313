import gql from 'graphql-tag';

export const deviceQueryFields = `
  id
  type
  carrier {
    name
    id
  }
  imei
  make
  model
  year
  unitId
  truckId
  lastUpdated
  lastLocation {
    approximateAddress
    date
    driver
    driverName
    gpsLat
    gpsLon
  }
  shippedDate
  trackingNumber
  returnDate
  writeOffReason
  cellularCarrierDeactivated
`;

export const GetDevicesByCarrierId = gql(`
  query GetDevicesByCarrierId($carrierId: ID!) {
    getDevicesByCarrierId(carrierId: $carrierId) { ${deviceQueryFields} }
  }
`);

export const GetDeviceByImei = gql(`
  query GetDeviceById($id: ID!) {
    getDeviceById(id: $id) { ${deviceQueryFields} }
  }
`);
