import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from '@haulynx/directives';
import { MaterialModule } from '@haulynx/modules';
import { AppNotificationModule } from '../app-notification/app-notification.module';
import { RmisAccountSetupStatusModule } from '../rmis-account-setup-status/rmis-account-setup-status.module';
import { SideNavModule } from '../side-nav/side-nav.module';
import { ToolbarComponent } from './toolbar.component';

@NgModule({
  declarations: [ToolbarComponent],
  exports: [ToolbarComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    SideNavModule,
    AppNotificationModule,
    RouterModule,
    DirectivesModule,
    RmisAccountSetupStatusModule,
  ],
})
export class ToolbarModule {}
