import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@haulynx/modules';
import { BlueButtonModule } from '../blue-button/blue-button.module';
import { CarrierBookOrContactButtonComponent } from './carrier-book-or-contact-button.component';

@NgModule({
  declarations: [CarrierBookOrContactButtonComponent],
  imports: [CommonModule, MaterialModule, FlexLayoutModule, BlueButtonModule],
  exports: [CarrierBookOrContactButtonComponent],
})
export class CarrierBookOrContactButtonModule {}
