import { Injectable } from '@angular/core';
import { CarrierService, NotificationsService, TrailersService } from '@haulynx/services';
import { FleetTrailerSearch, NewTrailer } from '@haulynx/types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '../../main-store/app.reducers';
import { DispatchAction } from '../../shared/store/helpers/action';
import { FleetActions, FleetActionsType } from './fleet.action';
import { fleetSearchSelector } from './fleet.selectors';

@Injectable()
export class FleetEffects {
  fleetTrailerSearch = createEffect(() =>
    this.actions$.pipe(
      ofType(FleetActionsType.SEARCH),
      map((action: DispatchAction) => action.payload),
      withLatestFrom(this.store.select(fleetSearchSelector.getQuery)),
      switchMap(([, query]: [NewTrailer, FleetTrailerSearch]) => {
        const { carrierId } = query as FleetTrailerSearch;
        if (!carrierId) {
          return of(FleetActions.searchSuccess({ entities: [] }));
        }
        return this.trailersService.getTrailersByCarrierId({ carrierId }).pipe(
          map((entities) => FleetActions.searchSuccess(entities)),
          catchError((error) => of(FleetActions.searchError(error)))
        );
      })
    )
  );

  createFleetTrailer = createEffect(() =>
    this.actions$.pipe(
      ofType(FleetActionsType.CREATE_FLEET_TRAILER),
      map((action: DispatchAction) => action.payload as NewTrailer),
      withLatestFrom(this.store.select(fleetSearchSelector.getQuery)),
      switchMap(([payload, query]: [NewTrailer, FleetTrailerSearch]) => {
        const { trailer, carrierId } = payload as NewTrailer;
        return this.carrierService.createTrailer(trailer, carrierId).pipe(
          mergeMap((entities) => {
            this.notificationsService.success('Trailer Successfully Created', 'Create Trailer');
            return [FleetActions.createFleetTrailerSuccess({ entities }), FleetActions.search({ query: query })];
          }),
          catchError((err) => {
            this.notificationsService.error(err, 'Create Trailer');
            return of(FleetActions.createFleetTrailerError(err));
          })
        );
      })
    )
  );

  updateFleetTrailer = createEffect(() =>
    this.actions$.pipe(
      ofType(FleetActionsType.UPDATE_FLEET_TRAILER),
      map((action: DispatchAction) => action.payload),
      withLatestFrom(this.store.select(fleetSearchSelector.getQuery)),
      switchMap(([payload, query]: [NewTrailer, FleetTrailerSearch]) => {
        const { trailer, carrierId } = payload;
        const action = trailer.inactive ? 'Destroy' : 'Update';
        const actionPastTense = trailer.inactive ? 'Destroyed' : 'Updated';
        return this.carrierService.updateTrailer(trailer, carrierId).pipe(
          mergeMap((entities) => {
            this.notificationsService.success(`Trailer Successfully ${actionPastTense}`, `${action} Trailer`);
            return [FleetActions.updateFleetTrailerSuccess({ entities }), FleetActions.search({ query: query })];
          }),
          catchError((err) => {
            this.notificationsService.error(err, `${action} Trailer`);
            return of(FleetActions.updateFleetTrailerError(err));
          })
        );
      })
    )
  );
  constructor(
    private actions$: Actions,
    private carrierService: CarrierService,
    private notificationsService: NotificationsService,
    private trailersService: TrailersService,
    private store: Store<AppState>
  ) {}
}
