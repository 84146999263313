import { InjectionToken, Provider } from '@angular/core';
import { Carrier, IAsyncEntityState } from '@haulynx/types';
import { USER_PROVIDED_EFFECTS } from '@ngrx/effects';
import { ActionReducer, ActionReducerMap } from '@ngrx/store';
import { CarrierEntityService } from './carrier-entity.service';

export const CARRIER_ENTITY_REDUCERS_TOKEN = new InjectionToken<ActionReducerMap<IAsyncEntityState<Carrier>>>(
  'carrier reducers'
);

function getCarrierEntityReducersFactory(
  carrierEntityService: CarrierEntityService
): ActionReducer<IAsyncEntityState<Carrier>> {
  return carrierEntityService.getReducers();
}

const carrierEntityReducerProvider: Provider = {
  provide: CARRIER_ENTITY_REDUCERS_TOKEN,
  useFactory: getCarrierEntityReducersFactory,
  deps: [CarrierEntityService],
};

const carrierEntityEffectProvider: Provider = {
  provide: USER_PROVIDED_EFFECTS,
  multi: true,
  useValue: [CarrierEntityService],
};

export const carrierLoadSearchStateProviders: Provider[] = [carrierEntityReducerProvider, carrierEntityEffectProvider];
