import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { PageTitleService } from '@haulynx/services';
import { HeaderItem, RmisProps, User } from '@haulynx/types';
import { Observable } from 'rxjs';

@Component({
  selector: 'haulynx-header-toolbar',
  templateUrl: './header-toolbar.component.html',
  styleUrls: ['./header-toolbar.component.scss'],
})
export class HeaderToolbarComponent {
  @Input() sidenav: MatSidenav;
  @Input() headerItems: HeaderItem[] = [];
  @Input() user: User;
  @Input() currentHeaderItem: HeaderItem;
  @Input() totalNotifications: Observable<number>;
  @Input() rmisProps: RmisProps;

  @Output() startTour = new EventEmitter();
  @Output() showNotifications = new EventEmitter();
  @Output() showVerifyEmail = new EventEmitter();
  @Output() goToHelp = new EventEmitter();
  @Output() openLogout = new EventEmitter();
  @Output() headerItemSelected = new EventEmitter<HeaderItem>();
  @Output() complete = new EventEmitter<boolean>();
  @Output() goToRmis = new EventEmitter();
  @Output() goBack = new EventEmitter();

  isHomeScreen = false;
  isSidenavOpen = false;
  pageTitle$: Observable<string>;

  constructor(private pageTitleService: PageTitleService) {
    this.pageTitle$ = this.pageTitleService.pageTitle$;
    this.pageTitleService.isSidenavOpenSubject.subscribe((isOpen: boolean) => (this.isSidenavOpen = isOpen));
  }

  closeSidenav() {
    this.pageTitleService.toggleFilter();
  }

  onShowNotifications(): void {
    this.showNotifications.emit();
  }

  onShowVerifyEmail(): void {
    this.showVerifyEmail.emit();
  }

  onGoToHelp(): void {
    this.goToHelp.emit();
  }

  onOpenLogout(): void {
    this.openLogout.emit();
  }

  whatsNewTour(): void {
    this.startTour.emit();
  }

  seletedItem(item: HeaderItem): void {
    this.headerItemSelected.emit(item);
  }

  clickCompleted() {
    this.complete.emit();
  }

  clickToRmis(): void {
    this.goToRmis.emit();
  }

  back() {
    this.goBack.emit();
  }
}
