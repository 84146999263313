<data-table-v2
  [data]="data"
  [displayedColumns]="displayedColumns"
  [title]="title"
  [templateCellRef]="templateCell"
  (onSelect)="selectedRow($event)"
  [showBottomPaginator]="showPaginator"
  [selectionMode]="rowSelection"
  [tableConfig]="configOptions"
  [sortColumns]="sortColumns"
  [rowSpacing]="rowSpacing"
  [scrollable]="scrollable"
  [scrollHeight]="scrollHeight"
  [emptyMessage]="emptyMessage"
  [loading]="loading"
  (clickableColumnOnSelect)="selectedColumn($event)"
>
  <ng-template #templateCell let-row="row" let-column="column" let-hovering="isHovering" let-width="columnWidth">
    <td [ngStyle]="{ width: width }" *ngIf="column.field === 'clicker'">Hello From the clicker</td>
    <td [ngStyle]="{ width: width }" *ngIf="column.field === 'action'">Hello From the action</td>
    <td [ngStyle]="{ width: width }" *ngIf="column.field === 'bookLoadButton'">Hello From the book load button</td>
    <td [ngStyle]="{ width: width }" *ngIf="column.field === 'name'">
      <span [underlineText]="hovering" (click)="clickColumnFromTemplateCode({ row: row, column: column })">
        {{ row.name }}
      </span>
    </td>
  </ng-template>
</data-table-v2>
