export interface IAsyncSearchResultsPaginationState {
  total: number;
  currentPage: number;
  nextPage: number;
  previousPage: number;
  totalPages: number;
}

export interface IAsyncEntityState<EntitySchema> {
  // <search query name>searchResults: {
  //   [queryHash: string]: {
  //     data: EntitySchema[][];
  //     total: number,
  //     currentPage: number,
  //     nextPage: number,
  //     previousPage: number,
  //     totalPages: number
  //   }
  // };
  // <search query name>isSearching: boolean;
  // <search query name>searchQuery: {
  //   queryHash: string;
  //   payload: unknown;
  //   pageAndSort: Partial<PageAndSort>;
  // };

  /**
   * A dictionary of complete Entity models (not abbreviated like for searchEntities) indexed by their ID's
   */
  entities: { [entityId: string]: EntitySchema };

  /**
   * A dictionary of all the entity ID keys whose values tell whether or not it is currently loading
   */
  isLoadingEntities: { [entityId: string]: boolean };

  // also on the state will be "isLoading<user-generated-mutation>" instances
}
