<div class="bids">
  <ng-container *ngIf="latestBid?.['id']; else showForm">
    <div class="latestBid">
      <span class="latestBid-info" data-test="latest-bid-info"
        >{{ latestBid.price | currency: 'USD':'symbol':'1.2-2' }} Bid added for Load #{{
          latestBid.loadsServiceLoad | loadAlternateId: 'tmwNumber'
        }}</span
      >
      <a class="link" (click)="onCarrierAction($event, laneHistoryActionMenu[2], carrier, latestBid.loadId)"
        >View Bids in Load
      </a>
    </div>
    <button class="ct-bids__block__button ct-bids__block__button__cancel" (click)="onCancel($event)">Dismiss</button>
  </ng-container>

  <ng-template #showForm>
    <form [formGroup]="form" class="bids-form" (submit)="onSubmit($event)">
      <div class="title">Add Bid</div>

      <mat-form-field class="app-form-field price" appearance="fill" floatLabel="always">
        <label class="label">Price</label>
        <input
          class="content"
          matInput
          currencyMask
          [options]="{ thousands: ',', decimal: '.', precision: 2 }"
          hideRequiredMarker
          [placeholder]="pricePlaceholder | currency: 'USD':'symbol':'1.2-2'"
          [min]="priceMin"
          [max]="priceMax"
          formControlName="price"
          minlength="1"
          autocomplete="off"
          required
        />
        <mat-error *ngIf="form.controls['price'].getError('min')">
          Price > {{ priceMin | currency: 'USD':'symbol':'1.2-2' }}
        </mat-error>
        <mat-error *ngIf="form.controls['price'].getError('max')">
          Price < {{ priceMax | currency: 'USD':'symbol':'1.2-2' }}
        </mat-error>
      </mat-form-field>

      <mat-form-field class="app-form-field notes" appearance="fill" floatLabel="always">
        <label class="label">Notes</label>
        <textarea class="bids__notes__textarea content" matInput formControlName="notes"></textarea>
      </mat-form-field>

      <div class="ct-bids__block ct-bids__block--button">
        <button class="ct-bids__block__button ct-bids__block__button__cancel" (click)="onCancel($event)">Cancel</button>
        <button
          #submitButton
          type="submit"
          class="ct-bids__block__button ct-bids__block__button__submit"
          (click)="onSubmit($event)"
          [disabled]="!form.valid || bidCreateLoading"
          data-test="add-bid-button"
        >
          <mat-spinner [diameter]="18" *ngIf="bidCreateLoading; else notLoading" mode="indeterminate"></mat-spinner>
          <ng-template #notLoading>Add Bid</ng-template>
        </button>
      </div>

      <div class="ct-bids__block ct-bids__block--button">
        <button
          class="ct-bids__block__button ct-bids__block__button__no__capacity"
          [disabled]="!form.valid || bidCreateLoading"
          (click)="onSubmit($event, true)"
        >
          Record Carrier as 'No Capacity'
        </button>
      </div>
    </form>
  </ng-template>
</div>
