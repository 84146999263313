import { Injectable } from '@angular/core';
import {
  AssignAssetBridgeTrailerToLoadByLoadId,
  CreateEldTrailerMutation,
  GetAssetTrailerNumbers,
  GetTrailersByCarrierId,
  SearchTrailerHistorybyTrailerInfo,
} from '@haulynx/gql';
import {
  AssetBridgeTrailerHistory,
  CreateTrailerDto,
  GraphqlSearchResponse,
  NewUSXITrailerInput,
  PaginatedData,
  SearchTrailerHistorybyTrailerInfoInput,
  Trailer,
} from '@haulynx/types';
import { isEmpty, omitBy } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GraphqlService } from '../graphql.service';

@Injectable({
  providedIn: 'root',
})
export class TrailersService {
  constructor(private graphqlService: GraphqlService) {}

  getTrailersByCarrierId(variables: { carrierId: string }): Observable<GraphqlSearchResponse<Trailer>> {
    return this.graphqlService
      .query({
        variables: {
          carrierId: variables.carrierId,
        },
        query: GetTrailersByCarrierId,
      })
      .pipe(
        map(({ data }: any) => {
          const entities: Trailer[] = data.getTrailersByCarrierId || [];

          return { entities, total: entities.length };
        })
      );
  }

  createTrailerEld(newTrailer: CreateTrailerDto): Observable<any | string> {
    return this.graphqlService
      .mutate({
        variables: {
          newTrailer,
        },
        mutation: CreateEldTrailerMutation,
      })
      .pipe(map(({ data }: any) => data.createTrailerEld || null));
  }

  searchUSXITrailerNumbers(): Observable<any> {
    return this.graphqlService
      .query({
        query: GetAssetTrailerNumbers,
      })
      .pipe(
        map((result) => {
          return {
            data: result.data['getAssetTrailerNumbers'],
            pagination: null,
          } as PaginatedData<any>;
        })
      );
  }

  bookUSXITrailer(payload: NewUSXITrailerInput): Observable<any> {
    return this.graphqlService
      .mutate<any>({
        mutation: AssignAssetBridgeTrailerToLoadByLoadId,
        variables: {
          trailerNumber: payload.trailerNumber,
          loadId: payload.loadId,
          customerNumber: payload.customerNumber,
          customerCompany: payload.customerCompany,
        },
      })
      .pipe(map((result) => result.data['assignAssetBridgeTrailerToLoadByLoadId']));
  }

  searchTrailerHistorybyTrailerInfo(
    payload: SearchTrailerHistorybyTrailerInfoInput
  ): Observable<AssetBridgeTrailerHistory> {
    const variables: Partial<SearchTrailerHistorybyTrailerInfoInput> = omitBy(payload, isEmpty);
    if (!variables.trailerCompany) {
      variables.trailerCompany = '';
    }

    return this.graphqlService
      .query({
        query: SearchTrailerHistorybyTrailerInfo,
        variables,
      })
      .pipe(
        map((result) => {
          return result.data['searchTrailerHistoryByTrailerInfo'] as AssetBridgeTrailerHistory;
        })
      );
  }
}
