import { createAction, createActionTypes } from '../../shared/store/helpers/action';

export const TYPE = '[SHARED LOCATION]';

export interface ISharedLocationActions {
  getSharedLoad(params?);
  getSharedLoadSuccess(params?);
  getSharedLoadError(params?);
  getSharedLocation(params?);
  getSharedLocationSuccess(params?);
  getSharedLocationError(params?);
}

export interface ISharedLocationActionType {
  GET_SHARED_LOAD: string;
  GET_SHARED_LOAD_SUCCESS: string;
  GET_SHARED_LOAD_ERROR: string;
  GET_SHARED_LOCATION: string;
  GET_SHARED_LOCATION_SUCCESS: string;
  GET_SHARED_LOCATION_ERROR: string;
}

export const sharedLocationActionTypes = {
  GET_SHARED_LOAD: 'get shared load',
  GET_SHARED_LOAD_SUCCESS: 'get shared load success',
  GET_SHARED_LOAD_ERROR: 'get shared load error',
  GET_SHARED_LOCATION: 'get shared location',
  GET_SHARED_LOCATION_SUCCESS: 'get shared location success',
  GET_SHARED_LOCATION_ERROR: 'get shared location error',
};

export const sharedLocationActions = {
  getSharedLoad: createAction(TYPE, sharedLocationActionTypes.GET_SHARED_LOAD),
  getSharedLoadSuccess: createAction(TYPE, sharedLocationActionTypes.GET_SHARED_LOAD_SUCCESS),
  getSharedLoadError: createAction(TYPE, sharedLocationActionTypes.GET_SHARED_LOAD_ERROR),
  getSharedLocation: createAction(TYPE, sharedLocationActionTypes.GET_SHARED_LOCATION),
  getSharedLocationSuccess: createAction(TYPE, sharedLocationActionTypes.GET_SHARED_LOCATION_SUCCESS),
  getSharedLocationError: createAction(TYPE, sharedLocationActionTypes.GET_SHARED_LOCATION_ERROR),
};

export const SharedLocationActions: ISharedLocationActions = {
  ...sharedLocationActions,
};

export const SharedLocationActionTypes: ISharedLocationActionType = {
  ...createActionTypes(sharedLocationActionTypes)(TYPE),
};
