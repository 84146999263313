import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EnvironmentService, FeatureFlagService, PermissionsService } from '@haulynx/services';
import { ANALYTICS_EVENT, BookStatus, FeatureFlag, FFState, HeaderItem, MenuItem, User } from '@haulynx/types';
import { exclusionFilters } from 'libs/types/src/lib/loads-service/exclusion-filters';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserEntityService } from '../../async-entity-models/user';
import { AppModel } from '../../main-store/app-model';

export enum MenuName {
  ACTIVE_LOADS = 'Active Loads',
  AVAILABLE_LOADS = 'Available Loads',
  COMPLETE_LOADS = 'Completed Loads',
  CONFIGURATION = 'Load Distribution Configuration',
  BROKERS = 'Brokers',
  CARRIERS = 'Carriers',
  CONTRACTS = 'Contracts',
  DEVICES = 'Devices',
  FLEET = 'Fleet',
  INVITATIONS = 'Invitations',
  LOADS = 'Loads',
  LOADS_CONFIG = 'Loads',
  MANAGE = 'Manage',
  SEARCH = 'Search',
  USERS = 'Users',
  RELEASE_NOTES = 'Release Notes',
  FEATURE_FLAGS = 'Feature Flags',
  LANE_RATE_TOOL = 'Lane Rate',
  USER_ACCOUNT = 'My Account',
  USER_SETTINGS = 'My Settings',
  TERMS_SERVICE = 'Terms of Service',
  PRIVACY_POLICY = 'Privacy Policy',
  SPOT_ADMIN = 'Spot Admin',
  LANE_ANALYSIS = 'Lanes',
}

@Injectable({
  providedIn: 'root',
})
export class DashboardModel {
  menu$: Observable<MenuItem[]> = combineLatest([
    this.appModel.user$,
    this.appModel.loadFeedPermission$,
    this.userEntityService.featureFlags$,
  ]).pipe(
    map(([user, loadFeedPermission, features]: [User, boolean, FFState]) => {
      const menuItems: MenuItem[] = [];

      if (!user) {
        return menuItems;
      }

      if (user.shipper || (user.carrier && user.isCompanyAdmin)) {
        if (loadFeedPermission) {
          menuItems.push({
            name: MenuName.LOADS,
            link: '/dashboard/loads/search/all',
            icon: 'dashboard',
            subItems: [
              { name: MenuName.AVAILABLE_LOADS, link: '/dashboard/loads/search/all' },
              { name: MenuName.ACTIVE_LOADS, link: '/dashboard/loads/search/active' },
              { name: MenuName.COMPLETE_LOADS, link: '/dashboard/loads/search/completed' },
            ],
            isOpened: false,
          });
        } else {
          menuItems.push({ name: MenuName.LOADS, link: '/dashboard/loads/search/active', icon: 'monetization_on' });
        }
      }

      if (user.broker) {
        // If Hide Old Search Board is active, show the new load board search
        if (this.featureFlagService.checkFlag(features, [FeatureFlag.HIDE_OLD_SEARCH_BOARD])) {
          menuItems.push({
            name: MenuName.LOADS,
            svgIcon: 'haulynx-loads-icon',
            link: '/loads/search',
            queryParams: {
              bookStatus: [BookStatus.BOOKABLE, BookStatus.VIEWABLE],
              notBillTo: exclusionFilters.map((val) => val.key),
            },
          });
        } else if (this.featureFlagService.checkFlag(features, [FeatureFlag.ACTIVE_LOAD_MANAGEMENT])) {
          const subItems: MenuItem[] = [
            { name: MenuName.AVAILABLE_LOADS, link: '/dashboard/load-feeds/search' },
            { name: MenuName.ACTIVE_LOADS, link: '/loads/active' },
          ];
          if (this.featureFlagService.checkFlag(features, [FeatureFlag.LANE_RATE_TOOL])) {
            subItems.push({
              name: MenuName.LANE_RATE_TOOL,
              link: '/lane-rate',
            });
          }
          if (this.featureFlagService.checkFlag(features, [FeatureFlag.LOAD_SEARCH])) {
            subItems.push({
              name: MenuName.SEARCH,
              link: '/loads/search',
              queryParams: {
                bookStatus: [BookStatus.BOOKABLE, BookStatus.VIEWABLE],
                notBillTo: exclusionFilters.map((val) => val.key),
              },
            });
          }
          menuItems.push({
            name: MenuName.LOADS,
            svgIcon: 'haulynx-loads-icon',
            subItems,
            isOpened: false,
          });
        } else {
          menuItems.push({
            name: MenuName.LOADS,
            svgIcon: 'haulynx-loads-icon',
            link: '/dashboard/load-feeds/search',
          });
        }
        if (this.featureFlagService.checkFlag(features, [FeatureFlag.CARRIER_MANAGEMENT_DASHBOARD])) {
          menuItems.push({
            name: MenuName.CARRIERS,
            svgIcon: 'users-icon',
            subItems: [
              { name: MenuName.MANAGE, link: '/carriers/manage' },
              { name: MenuName.SEARCH, link: '/dashboard/carriers/search' },
            ],
            isOpened: false,
          });
        } else {
          menuItems.push({
            name: MenuName.CARRIERS,
            svgIcon: 'users-icon',
            link: '/dashboard/carriers/search',
          });
        }
        if (features.FREIGHT_CONTRACTS) {
          menuItems.push({
            name: MenuName.CONTRACTS,
            svgIcon: 'book-icon',
            link: '/contracts',
          });
        }
      }

      if (user.carrier) {
        if (user.isCompanyAdmin) {
          menuItems.push({ name: MenuName.FLEET, link: '/dashboard/fleet', icon: 'local_shipping' });
        }
      }

      if (user.isCompanyAdmin) {
        menuItems.push({ name: MenuName.USERS, link: '/dashboard/users', svgIcon: 'users-icon' });
      }

      if (user.isHaulynxAdmin) {
        menuItems.push({ name: MenuName.FLEET, link: '/dashboard/fleet', icon: 'local_shipping' });
        menuItems.push({ name: MenuName.DEVICES, link: '/dashboard/devices', icon: 'router' });
        menuItems.push({ name: MenuName.INVITATIONS, link: '/dashboard/invitations', icon: 'mail' });
        menuItems.push({ name: MenuName.RELEASE_NOTES, link: '/releases', icon: 'local_fire_department' });
        menuItems.push({ name: MenuName.FEATURE_FLAGS, link: '/feature-flag-dashboard', icon: 'flag' });
      }

      if (features.LANE_ANALYSIS) {
        menuItems.push({
          name: MenuName.LANE_ANALYSIS,
          link: '/lane-analysis',
          svgIcon: 'lanes-icon',
        });
      }

      if (user.broker && features.SPOT_ADMIN_TOOL) {
        const env = this.environmentService.getEnvironmentName();
        menuItems.push({
          name: MenuName.SPOT_ADMIN,
          svgIcon: 'report-icon',
          link: env === 'production' || env === 'pilot' ? 'https://spot.haulynx.com/' : 'https://spotdev.haulynx.com/',
        });
      }

      return menuItems;
    })
  );

  headerMenu$: Observable<HeaderItem[]> = combineLatest([
    this.appModel.user$,
    this.userEntityService.featureFlags$,
  ]).pipe(
    map(([user, features]: [User, FFState]) => {
      const headerItems: HeaderItem[] = [];
      if (!user) return headerItems;

      headerItems.push(
        {
          name: user.carrier ? MenuName.USER_ACCOUNT : MenuName.USER_SETTINGS,
          link: '/dashboard/account',
          accountItem: true,
          analyticsEvent: ANALYTICS_EVENT.VIEW_ACCOUNT,
          icon: 'settings',
        },
        {
          name: MenuName.TERMS_SERVICE,
          link: 'terms-of-service',
          termsOrPrivacyItem: true,
          analyticsEvent: ANALYTICS_EVENT.VIEW_TERMS_SERVICE,
        },
        {
          name: MenuName.PRIVACY_POLICY,
          link: 'privacy-policy',
          termsOrPrivacyItem: true,
          analyticsEvent: ANALYTICS_EVENT.VIEW_TERMS_SERVICE,
        }
      );
      if (!user.isHaulynxAdmin) {
        if (features.CARRIER_LOAD_SEARCH) {
          headerItems.push({
            name: MenuName.LOADS,
            link: '/loads',
            svgIcon: 'loads-icon',

            analyticsEvent: ANALYTICS_EVENT.VIEW_LOAD_OVERVIEW,
            children: [],
          });
        } else {
          if (user.isCompanyAdmin) {
            headerItems.push({
              name: MenuName.LOADS,
              link: '/loads',
              svgIcon: 'loads-icon',
              stopNavigationOnClick: true,
              children: [
                { name: MenuName.AVAILABLE_LOADS, svgIcon: 'loads-icon', link: '/dashboard/loads/search/all' },
                { name: MenuName.ACTIVE_LOADS, svgIcon: 'loads-icon', link: '/dashboard/loads/search/active' },
                { name: MenuName.COMPLETE_LOADS, svgIcon: 'loads-icon', link: '/dashboard/loads/search/completed' },
              ],
            });
          }
        }

        if (user.isCompanyAdmin) {
          headerItems.push(
            {
              name: MenuName.FLEET,
              link: '/dashboard/fleet',
              svgIcon: 'fleet-icon',
              analyticsEvent: ANALYTICS_EVENT.VIEW_FLEET,
            },
            {
              name: MenuName.USERS,
              link: '/dashboard/users',
              svgIcon: 'users-icon',
              analyticsEvent: ANALYTICS_EVENT.VIEW_USERS,
            }
          );
        } else if (user.isDriver) {
          if (!features.CARRIER_LOAD_SEARCH) {
            headerItems.push({
              name: MenuName.LOADS,
              link: '/loads',
              svgIcon: 'loads-icon',
              stopNavigationOnClick: true,
              children: [
                { name: MenuName.ACTIVE_LOADS, svgIcon: 'loads-icon', link: '/dashboard/loads/search/active' },
              ],
            });
          }
        }
      }

      if (user.isHaulynxAdmin) {
        headerItems.push(
          {
            name: MenuName.FLEET,
            link: '/dashboard/fleet',
            svgIcon: 'fleet-icon',
            analyticsEvent: ANALYTICS_EVENT.VIEW_FLEET,
          },
          { name: MenuName.DEVICES, link: '/dashboard/devices', icon: 'router', selected: false },
          { name: MenuName.INVITATIONS, link: '/dashboard/invitations', icon: 'mail', selected: false },
          { name: MenuName.RELEASE_NOTES, link: '/releases', icon: 'local_fire_department', selected: false },
          { name: MenuName.FEATURE_FLAGS, link: '/feature-flag-dashboard', icon: 'flag', selected: false }
        );
      }

      if (!features.LANE_ANALYSIS) {
        headerItems.push({
          name: MenuName.LANE_ANALYSIS,
          link: '/lane-analysis',
          svgIcon: 'lanes-icon',
        });
      }

      if (user.broker && features.SPOT_ADMIN_TOOL) {
        const env = this.environmentService.getEnvironmentName();

        headerItems.push({
          name: MenuName.SPOT_ADMIN,
          link: env === 'production' || env === 'pilot' ? 'https://spot.haulynx.com/' : 'https://spotdev.haulynx.com/',
          svgIcon: 'report-icon',
        });
      }

      return headerItems;
    })
  );

  constructor(
    private appModel: AppModel,
    private router: Router,
    private userEntityService: UserEntityService,
    private featureFlagService: FeatureFlagService,
    private permissionsService: PermissionsService,
    private environmentService: EnvironmentService
  ) {}

  goTo(url: string): void {
    this.router.navigate([url]);
  }
}
