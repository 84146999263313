/* eslint-disable max-len */

export const termsAndConditions = `U.S. Xpress Logistics hereby referred to as USX LogisticsCarrier agrees to indemnify, defend and hold harmless USXLogistics and its customers from any loss, damage, liability, expense or claim relating to, resulting from, or arising out of Carriers negligent or willful acts or omissions.

Carrier shall be liable forall loss, damage, injury to or delay of freight shipped here under in accordance with 49 U.S.C. 14706.

Carrier understands and agrees that for a period of 1 year from the date of the Agreement, Carrier shall not back solicit, directly or indirectly, any Shipper whose transportation needswere disclosed to Carrier in connection with this Agreement.

As liquidated damages, Carrier agrees to pay a 15% commission on all traffic handled for Shippers whose transportation needs were disclosed to Carrier in connection with this Agreement for a period of 1 year.

If a shipment is rejected by Consignee, USX Logistics hasthe option to either (1) have Carrier place the load in storageor (2) have Carrier return it to the point of origin or someother point of destination specified by USX Logistics.

Carrier waives all rights to any claim for a lien on theshipment.

This written Agreement contains the entire Agreement between the parties and may only be modified by signed written Agreement. If there is a signed Broker/Carrier Agreement or signed accessorial Agreement in effect between Broker and Carrier, any terms of such Agreement that conflict with this Agreement shall take precedence over this Agreement.

Tennessee law, venue and jurisdiction shall apply. Failure by USX Logistics to enforce any provision of this Agreement does not constitute any waiver of any rights or remedies under this Agreement. If any provisions of this Agreement are declared invalid by a court of competent jurisdiction, the remainder of this Agreement shall remain in full force and effect.

Carrier's signature below or its acceptance of any load tendered by USX Logistics constitutes confirmation of and agreement to all information and terms and conditions set forth above and posted at http://www.usxpress.com.`;
