import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CustomerFacilityDto, LoadsServiceLoad, User } from '@haulynx/types';

@Component({
  selector: 'app-load-timeline',
  templateUrl: './load-timeline.component.html',
  styleUrls: ['./load-timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadTimelineComponent {
  @Input() load: LoadsServiceLoad;
  @Input() showTimeWaitStatus = false;
  @Input() showSaveLocation = false;
  @Input() isBroker = false;
  @Input() showDateTimes = true;
  @Input() showLocationActions = true;
  @Input() showTimelineBorder = true;
  @Input() isSpaciousIcons = true;
  @Input() showCustomerAttributes = true;
  @Input() user: User;
  @Input() showNotesButton = false;
  @Output() onSaveLocation = new EventEmitter();
  @Output() showSpecialNote = new EventEmitter<number>();
  @Output() showFacilityInfo = new EventEmitter<CustomerFacilityDto>();

  constructor() {}

  onShowSpecialNote(loadLocationIndex: number): void {
    this.showSpecialNote.emit(loadLocationIndex);
  }

  onShowFacilityInfo(customerNumber: string, index: number): void {
    this.showFacilityInfo.emit({ customerNumber, index });
  }
}
