import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'haulynx-leave-in-rmis-process-dialog',
  templateUrl: './leave-in-rmis-process-dialog.component.html',
  styleUrls: ['./leave-in-rmis-process-dialog.component.scss'],
})
export class LeaveInRmisProcessDialogComponent {
  constructor(private dialogRef: MatDialogRef<LeaveInRmisProcessDialogComponent>) {}

  public close(): void {
    this.dialogRef.close();
  }
}
