<div class="row">
  <div class="col-lg-12">
    <h2 matDialogTitle>Edit Carrier</h2>
    <mat-dialog-content>
      <div class="row">
        <mat-form-field class="col-lg-6">
          <input matInput placeholder="Name" [(ngModel)]="carrier.name" />
        </mat-form-field>
        <mat-form-field class="col-lg-3">
          <input matInput placeholder="DOT" [(ngModel)]="carrier.dot" />
        </mat-form-field>
        <mat-form-field class="col-lg-3">
          <input matInput placeholder="Phone" [(ngModel)]="carrier.phone" />
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="col-lg-4">
          <mat-select placeholder="FMCSA Safety Rating" [(ngModel)]="carrier.saferWatchData.safetyRating">
            <mat-option *ngFor="let rating of ratings" [value]="rating">{{ rating }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="col-lg-4">
          <mat-select placeholder="Saferwatch Overall Rating" [(ngModel)]="carrier.saferWatchData.overallRating">
            <mat-option *ngFor="let rating of overallRatings" [value]="rating">{{ rating }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="col-lg-4">
          <input matInput placeholder="MC Number" [(ngModel)]="carrier.saferWatchData.mcNumber" />
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="col-lg-4">
          <input
            matInput
            placeholder="Auto Liability Policy Limit"
            type="number"
            [(ngModel)]="carrier.saferWatchData.autoLiability.limit"
          />
          <label matPrefix>$</label>
        </mat-form-field>
        <mat-form-field class="col-lg-3">
          <input
            matInput
            placeholder="Expiration Date"
            type="date"
            [(ngModel)]="carrier.saferWatchData.autoLiability.expirationDate"
          />
        </mat-form-field>
        <mat-form-field class="col-lg-3">
          <input matInput placeholder="Policy Number" [(ngModel)]="carrier.saferWatchData.autoLiability.policyNumber" />
        </mat-form-field>
        <mat-form-field class="col-lg-2">
          <input matInput placeholder="Underwriter" [(ngModel)]="carrier.saferWatchData.autoLiability.underWriter" />
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="col-lg-4">
          <input
            matInput
            placeholder="Cargo Liability Policy Limit"
            type="number"
            [(ngModel)]="carrier.saferWatchData.cargoLiability.limit"
          />
          <label matPrefix>$</label>
        </mat-form-field>
        <mat-form-field class="col-lg-3">
          <input
            matInput
            placeholder="Expiration Date"
            type="date"
            [(ngModel)]="carrier.saferWatchData.cargoLiability.expirationDate"
          />
        </mat-form-field>
        <mat-form-field class="col-lg-3">
          <input
            matInput
            placeholder="Policy Number"
            [(ngModel)]="carrier.saferWatchData.cargoLiability.policyNumber"
          />
        </mat-form-field>
        <mat-form-field class="col-lg-2">
          <input matInput placeholder="Underwriter" [(ngModel)]="carrier.saferWatchData.cargoLiability.underWriter" />
        </mat-form-field>
      </div>
      <div class="row">
        <mat-checkbox class="col-lg-4" color="primary" [(ngModel)]="carrier.saferWatchData.outOfService">
          Out Of Service
        </mat-checkbox>
        <mat-checkbox class="col-lg-4" color="primary" [(ngModel)]="carrier.saferWatchData.hazmatCapable"
          >Hazmat Capable
        </mat-checkbox>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <h4>Survey</h4>
          <ul>
            <li *ngFor="let info of carrier.surveyInfo">
              {{ info.question }} <br />
              <mat-form-field class="col s2">
                <input matInput [(ngModel)]="info.content" />
              </mat-form-field>
            </li>
          </ul>
        </div>
      </div>
    </mat-dialog-content>

    <mat-spinner *ngIf="loading" [diameter]="40" [strokeWidth]="5"></mat-spinner>
    <mat-dialog-actions>
      <button mat-raised-button color="accent" (click)="save()" [disabled]="loading">Save Changes</button>
      <button mat-raised-button [matDialogClose]="">Cancel</button>
    </mat-dialog-actions>
  </div>
</div>
