import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { rejectReasons } from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-load-feed-reject',
  templateUrl: './load-feed-reject.component.html',
  styleUrls: ['./load-feed-reject.component.scss'],
})
export class LoadFeedRejectComponent implements OnInit, OnDestroy {
  standardReason: string;
  alive = aliveWhile();
  rejectReasons = rejectReasons;
  form: FormGroup;
  isCustomReasonDisabled = true;
  isSubmitDisabled = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<LoadFeedRejectComponent>,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      standardReason: [null],
      customReason: [{ value: '', disabled: true }],
    });
  }

  ngOnInit(): void {
    this.form
      .get('standardReason')
      .valueChanges.pipe(takeUntil(this.alive), distinctUntilChanged())
      .subscribe((data) => {
        const customReason = this.form.get('customReason');
        const standardReason = this.form.get('standardReason');

        if (data) {
          customReason.disable();
          this.isSubmitDisabled = false;
        } else {
          customReason.enable();
          this.isSubmitDisabled = true;
        }

        this.form.patchValue({
          customReason: standardReason.value,
        });
      });

    this.form
      .get('customReason')
      .valueChanges.pipe(takeUntil(this.alive), distinctUntilChanged())
      .subscribe((data: string) => {
        this.isSubmitDisabled = !data.trim();
      });
  }

  onReject(): void {
    if (!this.isSubmitDisabled) {
      this.dialogRef.close(this.form.get('customReason').value);
    }
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }
}
