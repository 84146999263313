import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { LoadTimelineModule } from '../../../../load-timeline/load-timeline.module';
import { UnauthenticatedLoadFeedDetailsInfoPaneComponent } from './unauthenticated-load-feed-details-info-pane.component';

@NgModule({
  declarations: [UnauthenticatedLoadFeedDetailsInfoPaneComponent],
  exports: [UnauthenticatedLoadFeedDetailsInfoPaneComponent],
  imports: [CommonModule, PipesModule, FlexLayoutModule, LoadTimelineModule, MaterialModule],
})
export class UnauthenticatedLoadFeedDetailsInfoPaneModule {}
