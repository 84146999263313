<div class="label" [ngClass]="{ centered: type === 'insurance' }">{{ label }}:</div>
<div class="value" *ngIf="value; else noData">
  <ng-container *ngIf="type === 'number'">
    {{ value | showCurrency: 'symbol':displaySymbol:'1.0-0' || 'N/A' }}
  </ng-container>
  <ng-container *ngIf="type === 'percent'"> {{ value }}% </ng-container>
  <ng-container *ngIf="type === 'date'">
    {{ value }}
  </ng-container>
  <div *ngIf="type === 'insurance'" class="two-items">
    <span>{{ value?.currency | showCurrency: 'symbol':displaySymbol:'1.0-0' || 'N/A' }}</span>
    <span>{{ value?.date | date: 'MM/dd/yy' }}</span>
  </div>
</div>

<ng-template #noData>
  <span>N/A</span>
</ng-template>
