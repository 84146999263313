import { Component, OnDestroy, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { MetaService } from '@haulynx/services';
import { UserEntityService } from '@haulynx/store';
import { AccountRoute, User } from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'haulynx-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit, OnDestroy {
  private alive = aliveWhile();
  public imageSrc = 'auth';
  public user: Observable<User>;

  constructor(private metaService: MetaService, private router: Router, private userEntityService: UserEntityService) {}

  ngOnInit(): void {
    this.metaService.setMobileViewport();
    this.user = this.userEntityService.user$;
    this.setImage(this.router.url);

    this.router.events.pipe(takeUntil(this.alive)).subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.setImage(event.urlAfterRedirects);
      }
    });
  }

  ngOnDestroy(): void {
    this.alive.destroy();
    this.metaService.removeMobileViewport();
  }

  public route(route: AccountRoute) {
    this.router.navigate([route], { queryParamsHandling: 'preserve' });
  }

  public signOut() {
    this.userEntityService.logout();
    this.router.navigate([AccountRoute.LOGIN]);
  }

  /**
   * Set the background and side image for the current page
   * @param {string} url
   */
  private setImage(url: string) {
    if (['create', 'verify', 'terms-of-service'].some((term) => url.includes(term))) {
      this.imageSrc = 'trucks';
    } else if (['action'].some((term) => url.includes(term))) {
      this.imageSrc = 'action';
    } else if (['contact', 'role'].some((term) => url.includes(term))) {
      this.imageSrc = 'rocket';
    } else if (['finish'].some((term) => url.includes(term))) {
      this.imageSrc = 'computer';
    } else if (['carrier'].some((term) => url.includes(term))) {
      this.imageSrc = 'find';
    } else {
      this.imageSrc = 'auth';
    }
  }
}
