import gql from 'graphql-tag';

export const GetPaymentTypes = gql(`
  query GetPaymentTypes {
    getPaymentTypes {
      code
      description
    }
  }
`);
