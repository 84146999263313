export enum TrailerOwnerType {
  /**
   * usxi trailer owner
   */
  USXI = 'usxi',
  /**
   * third party trailer owner
   */
  THIRD_PARTY = 'thirdParty',
  /**
   * other trailer owner type
   */
  OTHER = 'other',
}
