<div class="add-truck" *ngIf="carrierDot || carrierId; else noCarrier">
  <h3 class="add-truck__title">Add Truck Info</h3>

  <div class="add-truck__description">Any changes that you make will appear across the platform</div>

  <form [formGroup]="form" data-test="truck-form" (ngSubmit)="save()">
    <div class="add-truck__row">
      <mat-label class="add-truck__label"> Truck </mat-label>
      <mat-form-field class="app-form-field add-truck__field" hideRequiredMarker appearance="fill" floatLabel="always">
        <input matInput formControlName="unitId" autocomplete="off" placeholder="Enter Truck Name" />
      </mat-form-field>
    </div>

    <div class="add-truck__action">
      <button
        mat-dialog-close
        class="hlx-button-command hlx-button-command--cancel hlx-button-command--border-white add-truck__button__back"
      >
        Go Back
      </button>

      <button
        type="submit"
        class="hlx-button-command hlx-button-command--book add-truck__button__submit"
        [disabled]="form.invalid"
        (click)="save()"
        data-test="submit-button"
      >
        Submit
      </button>
    </div>
  </form>
</div>

<ng-template #noCarrier>
  <div class="no-carrier-dot">
    <p class="add-truck__description">Carrier is required to add a truck</p>
    <button mat-dialog-close class="hlx-button-command hlx-button-command--cancel hlx-button-command--border-white">
      Ok
    </button>
  </div>
</ng-template>
