import { Pipe, PipeTransform } from '@angular/core';
import { SecondaryNotificationEvent, User, LoadIdentifierType } from '@haulynx/types';
import { WebNotification } from '@haulynx/types';
import { getLoadsServiceLoadAlternateId } from '@haulynx/utils';
import { get } from 'lodash';

@Pipe({
  name: 'bidNotificationText',
})
export class BidNotificationTextPipe implements PipeTransform {
  transform(notification: WebNotification, user: User = null): string {
    if (user && user.carrier) {
      switch (notification.secondaryEventType) {
        case SecondaryNotificationEvent.BID_NEW: {
          if (notification?.notifications[0]?.pushNotifications[0]?.message.includes('after hours')) {
            return `Your bid was placed after hours. We will respond as soon as we can.`;
          }
          return `Broker offered you load
            <span class="highlight-label">
              ${
                getLoadsServiceLoadAlternateId(
                  notification?.loadsServiceLoad,
                  LoadIdentifierType.BILL_OF_LADING,
                  false
                ) || ''
              }
            </span>
          `;
        }
        case SecondaryNotificationEvent.BID_COUNTER:
          return `New counter offer for load
            <span class="highlight-label">
              ${
                getLoadsServiceLoadAlternateId(
                  notification?.loadsServiceLoad,
                  LoadIdentifierType.BILL_OF_LADING,
                  false
                ) || ''
              }
            </span>
          `;
        case SecondaryNotificationEvent.BID_REJECTION:
          return `Your bid was rejected for load
            <span class="highlight-label">
              ${
                getLoadsServiceLoadAlternateId(
                  notification?.loadsServiceLoad,
                  LoadIdentifierType.BILL_OF_LADING,
                  false
                ) || ''
              }
            </span>
          `;
        case SecondaryNotificationEvent.BID_ACCEPTANCE:
          return `Your bid was accepted for load
            <span class="highlight-label">
              ${
                getLoadsServiceLoadAlternateId(
                  notification?.loadsServiceLoad,
                  LoadIdentifierType.BILL_OF_LADING,
                  false
                ) || ''
              }
            </span>
          `;
        case SecondaryNotificationEvent.BID_LOAD_BOOKED:
          return `The bidding for
            <span class="highlight-label">
              ${
                getLoadsServiceLoadAlternateId(
                  notification?.loadsServiceLoad,
                  LoadIdentifierType.BILL_OF_LADING,
                  false
                ) || ''
              }
            </span>
            has ended due to load being booked
          `;
        case SecondaryNotificationEvent.BID_BOOKING_ERROR:
          return `There was a problem accepting the bid for load
            <span class="highlight-label">
              ${
                getLoadsServiceLoadAlternateId(
                  notification?.loadsServiceLoad,
                  LoadIdentifierType.BILL_OF_LADING,
                  false
                ) || ''
              }
            </span>
          `;
        default:
          return `You have activity on your bid for load
            <span class="highlight-label">
              ${
                getLoadsServiceLoadAlternateId(
                  notification?.loadsServiceLoad,
                  LoadIdentifierType.BILL_OF_LADING,
                  false
                ) || ''
              }
            </span>
          `;
      }
    }

    switch (notification.secondaryEventType) {
      case SecondaryNotificationEvent.BID_NEW:
        return `New bid from
          <span class="highlight-label">
            ${get(notification, 'targetCarrierDisplay', 'no name')}
          </span>
        `;
      case SecondaryNotificationEvent.BID_UPDATE:
        return `Offer price lowered by
          <span class="highlight-label">
            ${get(notification, 'targetCarrierDisplay', 'no name')}
          </span>
        `;
      case SecondaryNotificationEvent.BID_COUNTER:
        return `New counter offer from
          <span class="highlight-label">
            ${get(notification, 'targetCarrierDisplay', 'no name')}
          </span>
        `;
      case SecondaryNotificationEvent.BID_REJECTION:
        return `The offer was rejected by
          <span class="highlight-label">
            ${get(notification, 'targetCarrierDisplay', 'no name')}
          </span>
        `;
      case SecondaryNotificationEvent.BID_ACCEPTANCE:
        return `The offer was accepted by
          <span class="highlight-label">
            ${get(notification, 'targetCarrierDisplay', 'no name')}
          </span>
        `;
      case SecondaryNotificationEvent.BID_LOAD_BOOKED:
        return `The bidding for
          <span class="highlight-label">
            ${
              getLoadsServiceLoadAlternateId(
                notification?.loadsServiceLoad,
                LoadIdentifierType.BILL_OF_LADING,
                false
              ) || ''
            }
          </span>
         has ended due to load being booked
         `;
      case SecondaryNotificationEvent.BID_BOOKING_ERROR:
        return `There was a problem accepting the bid from
          <span class="highlight-label">
            ${get(notification, 'targetCarrierDisplay', 'no name')}
          </span>
        `;
      default:
        return `You have activity on a bid from
          <span class="highlight-label">
            ${get(notification, 'targetCarrierDisplay', 'no name')}
          </span>
        `;
    }
  }
}
