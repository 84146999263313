import gql from 'graphql-tag';

export const GetNearestCities = gql(`
  query getNearestCities($coordinates: [LatLonInput]) {
    getNearestCities(coordinates: $coordinates){
    lat
    lon
    city
    state
    }
  }
`);
