import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CarrierManagementModel } from '@haulynx/store';
import { CarrierManagement, RelationTypes } from '@haulynx/types';
import { ConfirmationComponent } from '@haulynx/services';

@Component({
  selector: 'app-carrier-manage-container',
  templateUrl: './carrier-manage-container.component.html',
  styleUrls: ['./carrier-manage-container.component.scss'],
})
export class CarrierManageContainerComponent implements OnInit {
  constructor(public carrierManagementModel: CarrierManagementModel, private dialog: MatDialog) {}

  relationTypes = RelationTypes;

  ngOnInit(): void {
    this.carrierManagementModel.searchTop({});
    this.carrierManagementModel.searchDeveloping({});
    this.carrierManagementModel.searchProspects({});
  }

  onSetContacted(row: CarrierManagement): void {
    this.carrierManagementModel.setContacted(row);
  }

  onDelete(row: CarrierManagement): void {
    const { id } = row;
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: {
        title: 'Delete Relationship',
        message: 'Are you sure you want to delete this relationship?',
        width: '350px',
        deny: { text: 'No', hide: false },
      },
    });

    dialogRef.afterClosed().subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.carrierManagementModel.delete(id);
      }
    });
  }
}
