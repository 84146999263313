import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { LoadDetailsModel, LoadPriceModel, LoadPriceStoreModule } from '@haulynx/store';
import { NgxMaskModule } from 'ngx-mask';
import { ActionButtonsModule } from '../actions-buttons/action-buttons.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { LoadPriceFormComponent } from './components/load-price-form/load-price-form.component';
import { LoadPriceComponent } from './load-price.component';

@NgModule({
  imports: [
    CommonModule,
    PipesModule,
    LoadPriceStoreModule,
    SpinnerModule,
    ActionButtonsModule,
    ReactiveFormsModule,
    MaterialModule,
    NgxMaskModule.forRoot(),
  ],
  providers: [LoadPriceModel, LoadDetailsModel],
  exports: [LoadPriceComponent],
  declarations: [LoadPriceComponent, LoadPriceFormComponent],
})
export class LoadPriceModule {}
