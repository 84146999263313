import { Carrier, Insurance } from './carrier';
import { CarrierSourceType } from './carrier-source-type';

export interface CarrierSearchResult {
  id: string;
  dot: string;
  isEldCarrier?: boolean;
  isHaulynxCarrier?: boolean;
  name?: string;
  email?: string;
  phone?: string;
  owner?: string;
  mcNumber?: string;
  usxId?: string;
  carrier?: Carrier;
  adminName?: string;
  contacts?: CarrierContacts[];
  status?: string;
  sourceType?: CarrierSourceType;
}

export interface CarrierContacts {
  email?: string;
  type?: string;
}

export enum CarrierContactTypes {
  ADMIN = 'ADMIN',
  BUSINESS = 'BUSINESS',
}
