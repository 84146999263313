import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { aliveWhile } from '@haulynx/utils';
import * as _ from 'lodash';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { AddressVm } from './address.vm';

@Component({
  selector: 'haulynx-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
})
export class AddressComponent implements OnInit, OnDestroy, OnChanges {
  @Input() form: FormGroup;
  @Input() disabled = false;
  alive = aliveWhile();
  formGroup: FormGroup;

  constructor(private addressVm: AddressVm) {
    this.formGroup = this.addressVm.create();
  }

  ngOnInit(): void {
    this.formGroup.valueChanges
      .pipe(
        distinctUntilChanged((previous, current) => {
          return _.isEqual(previous, current);
        }),
        takeUntil(this.alive)
      )
      .subscribe((values) => {
        this.form.patchValue(values);
      });

    this.form.valueChanges
      .pipe(
        distinctUntilChanged((previous, current) => {
          return _.isEqual(previous, current);
        }),
        takeUntil(this.alive)
      )
      .subscribe((values) => {
        this.formGroup.patchValue(values);
      });
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { disabled } = changes;

    if (disabled) {
      disabled.currentValue ? this.formGroup.disable() : this.formGroup.enable();
    }
  }
}
