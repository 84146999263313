import { Renderer2 } from '@angular/core';
import { ChartIdentifier } from '@haulynx/types';
import { ChartTooltipModel } from 'chart.js';

/**
 *
 * @param data an array of numbers
 * @param fillCount the amount of numbers to place between each data point
 * @param startDate the first date of the data
 * @returns Array<{x:number, y:number}>
 *
 * This function will take any data points and fill it with additional data matching the slope between two data points.
 * IE:
 * data = [5, 10]; fillCount = 2
 * return = [5, 6.67, 8.34, 10]
 * the function added 2 value between 5 and 10 following the slope ((10 - 2) / (5 - 0)) = 1.66
 */
export const fillDataMatchingSlopeBetweenDataPoints = (
  data: { value: number; id: any; chartId: ChartIdentifier | null }[],
  fillCount: number,
  startDate: number
): Array<{ x: number; y: number; r: any }> => {
  const results = [];
  for (let i = 0; i < data.length; i++) {
    if (fillCount > 0 && data[i + 1]) {
      const nextValue = data[i + 1].value;
      const slope = Math.round(((nextValue - data[i].value) / (fillCount + 1)) * 100) / 100;
      for (let y = 0; y <= fillCount; y++) {
        const increaseDays = y + (fillCount + 1) * i;
        results.push({
          x: new Date(getDateNamePlusDays(startDate, increaseDays)),
          y: data[i].value + slope * y,
          r: y === 0 || data[i].chartId !== null ? `${data[i].id}:${data[i].chartId}` : null,
        });
      }
    } else {
      results.push({
        x: getDateNamePlusDays(startDate, i * (fillCount + 1)),
        y: data[i].value,
        r: `${data[i].id}:${data[i].chartId}`,
      });
    }
  }
  return results;
};

export const getDateNamePlusDays = (d: number, days: number): string => {
  const date = new Date(new Date(d).setHours(0, 0, 0, 0));
  date.setDate(date.getDate() + days);
  return date.toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
};

export const chartTooltipSetup = (
  tooltipModel: ChartTooltipModel,
  canvasBoundingClientRect: { left: number; top: number },
  data: any,
  renderer: Renderer2,
  document: any
): HTMLDivElement => {
  let tooltipEl = document.getElementById('chartjs-tooltip');

  if (!tooltipEl && tooltipModel) {
    tooltipEl = renderer.createElement('div');
    renderer.setAttribute(tooltipEl, 'id', 'chartjs-tooltip');

    // Display, position, and set styles for font
    renderer.setStyle(tooltipEl, 'opacity', 1);
    renderer.setStyle(tooltipEl, 'position', 'absolute');
    renderer.setStyle(tooltipEl, 'z-index', 10);
    renderer.setStyle(
      tooltipEl,
      'left',
      canvasBoundingClientRect.left + window.pageXOffset + tooltipModel.caretX - 170 + 'px'
    );
    renderer.setStyle(
      tooltipEl,
      'top',
      canvasBoundingClientRect.top + window.pageYOffset + tooltipModel.caretY + 15 + 'px'
    );
    renderer.setStyle(tooltipEl, 'fontFamily', tooltipModel._bodyFontFamily);
    renderer.setStyle(tooltipEl, 'fontSize', tooltipModel.bodyFontSize + 'px');
    renderer.setStyle(tooltipEl, 'fontStyle', tooltipModel._bodyFontStyle);
    renderer.setStyle(tooltipEl, 'padding', tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px');
    renderer.setStyle(tooltipEl, 'pointerEvents', 'none');
    renderer.setStyle(tooltipEl, 'background', '#262A37');
    renderer.setStyle(tooltipEl, 'border-radius', '6px');
    renderer.setStyle(tooltipEl, 'min-width', '150px');

    renderer.removeClass(tooltipEl, 'above');
    renderer.removeClass(tooltipEl, 'below');
    renderer.removeClass(tooltipEl, 'no-transform');
    // set caret position
    if (tooltipModel.yAlign) {
      renderer.addClass(tooltipEl, tooltipModel.yAlign);
    } else {
      renderer.addClass(tooltipEl, 'no-transform');
    }

    renderer.appendChild(document.body, tooltipEl);
  } else {
    if (((tooltipModel && tooltipModel.opacity === 0) || !data) && tooltipEl) {
      renderer.removeChild(document.body, tooltipEl);
      return null;
    }
  }

  return tooltipEl;
};
