<div [formGroup]="form" class="date-inputs">
  <input
    class="date-inputs__input date-inputs__input--month"
    #month
    formControlName="month"
    placeholder="MM"
    size="3"
    maxlength="2"
    required
  />
  <span class="date-inputs__separator">/</span>
  <input
    class="date-inputs__input date-inputs__input--day"
    formControlName="day"
    placeholder="DD"
    size="3"
    maxlength="2"
    required
  />
  <span class="date-inputs__separator">/</span>
  <input
    class="date-inputs__input date-inputs__input--year"
    formControlName="year"
    placeholder="YYYY"
    size="5"
    maxlength="4"
    required
  />
</div>
