import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { LoadBidDetailsComponent } from './load-bid-details.component';

@NgModule({
  imports: [CommonModule, PipesModule, MaterialModule, ReactiveFormsModule],
  providers: [],
  exports: [LoadBidDetailsComponent],
  declarations: [LoadBidDetailsComponent],
})
export class LoadBidDetailsModule {}
