import { Injectable } from '@angular/core';
import { LoadsServiceService, NotificationsService } from '@haulynx/services';
import { UnauthenticatedLoadsServiceLoad } from '@haulynx/types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { DispatchAction } from '../../shared/store/helpers/action';
import { SharedLocationActions, SharedLocationActionTypes } from './shared-location.actions';

@Injectable({
  providedIn: 'root',
})
export class SharedLocationEffects {
  getLoad = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedLocationActionTypes.GET_SHARED_LOAD),
      map((action: DispatchAction) => action.payload),
      switchMap((hash) =>
        this.loadsServiceService.getSharedLocationByHash(hash).pipe(
          map((load: UnauthenticatedLoadsServiceLoad) => SharedLocationActions.getSharedLoadSuccess(load)),
          catchError((error) => {
            this.notificationsService.error(`Oops, unable to get shared load!`, `Shared Load`);

            return of(SharedLocationActions.getSharedLoadError(error));
          })
        )
      )
    )
  );

  getLoadLocation = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedLocationActionTypes.GET_SHARED_LOCATION),
      map((action: DispatchAction) => action.payload),
      switchMap((hash: string) =>
        this.loadsServiceService.getTruckLastLoadLocationByLoadIdHash(hash).pipe(
          map((location) => SharedLocationActions.getSharedLocationSuccess(location)),
          catchError((error) => SharedLocationActions.getSharedLocationError(error))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private loadsServiceService: LoadsServiceService,
    private notificationsService: NotificationsService
  ) {}
}
