import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { CarrierDetailsModel } from './carrier-details-model';
import { CarrierDetailsEffects } from './carrier-details.effects';
import { carrierDetailsReducer } from './carrier-details.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature('carrier-details', carrierDetailsReducer),
    EffectsModule.forFeature([CarrierDetailsEffects]),
  ],
  providers: [CarrierDetailsModel, CarrierDetailsEffects],
})
export class CarrierDetailsStoreModule {}
