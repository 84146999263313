<div *ngIf="!loading" class="leaderboard">
  <div *ngIf="carrier1">
    <div class="top-carrier">{{ carrier1 }}</div>
    <div class="row">
      <canvas
        class="canvas-loads"
        baseChart
        height="40"
        [chartType]="'horizontalBar'"
        [datasets]="chartLoads1Datasets"
        [labels]="chartLabels"
        [options]="chartLoads1Options"
        [colors]="loadsChartColors"
        [legend]="false"
      >
      </canvas>
      <div class="graph-data">{{ loads1[0] }} loads</div>
    </div>
    <div class="row">
      <canvas
        class="canvas-loads"
        baseChart
        height="40"
        [chartType]="'horizontalBar'"
        [datasets]="chartProfit1Datasets"
        [labels]="chartLabels"
        [options]="chartProfit1Options"
        [colors]="revenueChartColors"
        [legend]="false"
      >
      </canvas>
      <div class="graph-data">{{ profit1[0] | currency: 'USD':'symbol':'1.0-0' }}</div>
    </div>
  </div>

  <div *ngIf="carrier2">
    <div class="top-carrier">{{ carrier2 }}</div>
    <div class="row">
      <canvas
        class="canvas-loads"
        baseChart
        height="40"
        [chartType]="'horizontalBar'"
        [datasets]="chartLoads2Datasets"
        [labels]="chartLabels"
        [options]="chartLoads2Options"
        [colors]="loadsChartColors"
        [legend]="false"
      >
      </canvas>
      <div class="graph-data">{{ loads2[0] }} loads</div>
    </div>
    <div class="row">
      <canvas
        class="canvas-loads"
        baseChart
        height="40"
        [chartType]="'horizontalBar'"
        [datasets]="chartProfit2Datasets"
        [labels]="chartLabels"
        [options]="chartProfit2Options"
        [colors]="revenueChartColors"
        [legend]="false"
      >
      </canvas>
      <div class="graph-data">{{ profit2[0] | currency: 'USD':'symbol':'1.0-0' }}</div>
    </div>
  </div>
</div>
