import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@haulynx/modules';
import { HeaderComponent } from './header.component';
import { HeaderToolbarModule } from './components/header-toolbar/header-toolbar.module';
import { SidebarNavModule } from './components/sidebar-nav/sidebar-nav.module';

@NgModule({
  declarations: [HeaderComponent],
  imports: [CommonModule, FlexLayoutModule, MaterialModule, HeaderToolbarModule, SidebarNavModule],
  exports: [HeaderComponent],
})
export class HeaderModule {}
