import { AppSettingsActionTypes } from '../../main-store/app-settings.actions';
import { DispatchAction } from '../../shared/store/helpers/action';
import { DashboardActionTypes } from './dashboard.actions';
import { DashboardState } from './dashboard.state';

export const initialState: DashboardState = new DashboardState();

export function dashboardReducer(state: DashboardState = initialState, action: DispatchAction): DashboardState {
  switch (action.type) {
    case AppSettingsActionTypes.LOG_OUT:
      return initialState;
    case DashboardActionTypes.TOGGLE_NAV:
      return state.merge({
        navIsOpen: !state.navIsOpen,
      });
    default:
      return state;
  }
}
