import { Injectable } from '@angular/core';
import { CreateUserFavoriteAddress, GetUserFavoriteAddress } from '@haulynx/gql';
import { QueryOptionsFetchPolicy } from '@haulynx/types';
import { map } from 'rxjs/operators';
import { GraphqlService } from '../graphql.service';

@Injectable({
  providedIn: 'root',
})
export class LocationsServices {
  constructor(private graphqlService: GraphqlService) {}

  public getFavoriteLocation(options: Partial<QueryOptionsFetchPolicy>) {
    return this.graphqlService
      .query({
        ...options,
        query: GetUserFavoriteAddress,
      })
      .pipe(map(({ data }: any) => data.getUserFavoriteAddress));
  }

  public createFavoriteAddress(address, options?: { [key: string]: unknown }) {
    return this.graphqlService
      .mutate({
        variables: { address },
        ...options,
        mutation: CreateUserFavoriteAddress,
      })
      .pipe(map(({ data }: any) => data.createUserFavoriteAddress));
  }
}
