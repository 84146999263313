import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { SharedLocationTimelineComponent } from './shared-location-timeline.component';

@NgModule({
  declarations: [SharedLocationTimelineComponent],
  imports: [CommonModule, MaterialModule, PipesModule],
  exports: [SharedLocationTimelineComponent],
})
export class SharedLocationTimelineModule {}
