import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@haulynx/modules';
import { VirtualScrollComponent } from './virtual-scroll.component';
import { BlueButtonModule } from '../blue-button/blue-button.module';

@NgModule({
  declarations: [VirtualScrollComponent],
  imports: [BlueButtonModule, CommonModule, FlexLayoutModule, ScrollingModule, MaterialModule],
  exports: [VirtualScrollComponent],
})
export class VirtualScrollModule {}
