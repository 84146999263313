import { Component, ElementRef, Inject, OnDestroy, OnInit, Optional, QueryList, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReleasesService } from '@haulynx/services';
import { AppModel } from '@haulynx/store';
import { MediaTypes, ReleaseNote, ReleaseTypes, WhatsNewPage } from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { nth, omit } from 'lodash';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { concatMap, filter, last, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-release-form-dialog',
  templateUrl: './release-form-dialog.component.html',
  styleUrls: ['./release-form-dialog.component.scss'],
})
export class ReleaseFormDialogComponent implements OnInit, OnDestroy {
  @ViewChildren('imageInput', { read: ElementRef }) imageInput: QueryList<ElementRef>;
  @ViewChildren('videoInput', { read: ElementRef }) videoInput: QueryList<ElementRef>;

  form: FormGroup;
  fileReader = new FileReader();
  mediaTypes = MediaTypes;
  releaseTypes = ReleaseTypes;
  activeTab$: Observable<number>;
  formData: ReleaseNote;
  userId: string;
  mediaError: Record<number, boolean> = {};

  private activeTab = new BehaviorSubject<number>(0);
  private alive = aliveWhile();

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) private data: { releaseNoteId: string },
    @Optional() private dialogRef: MatDialogRef<ReleaseFormDialogComponent>,
    private fb: FormBuilder,
    public releasesService: ReleasesService,
    public appModel: AppModel
  ) {}

  ngOnInit(): void {
    const { releaseNoteId = null } = this.data;

    this.activeTab$ = this.activeTab.asObservable();
    this.form = this.initForm();

    this.appModel.id$
      .pipe(
        takeUntil(this.alive),
        tap((userId: string) => (this.userId = userId)),
        switchMap((userId: string) =>
          releaseNoteId ? this.releasesService.getReleaseNotesById({ releaseNoteId, userId }) : of(null)
        ),
        filter((release) => !!release)
      )
      .subscribe((release: ReleaseNote) => this.assignFormData(release));
  }

  initForm(): FormGroup {
    const pageForm = this.getPageForm();

    return this.fb.group({
      id: [null],
      title: [null, Validators.required],
      userType: [null, Validators.required],
      date: [Date.now(), Validators.required],
      expiration: [Date.now() + 3600000 * 24 * 14, Validators.required],
      hasViewed: [false],
      pages: this.fb.array([pageForm]),
    });
  }

  get pages(): FormArray {
    return this.form.controls.pages as FormArray;
  }

  getPageForm(): FormGroup {
    return this.fb.group({
      title: [null, Validators.required],
      description: [null],
      media: this.fb.group({
        type: [null, Validators.required],
        data: [null, Validators.required],
      }),
    });
  }

  assignFormData(formData: ReleaseNote): void {
    this.form.patchValue(formData);
    this.pages.patchValue(formData.pages);
    this.pages.clear();

    formData.pages.map((pageData: WhatsNewPage) => {
      const page = this.getPageForm();
      page.addControl('id', this.fb.control(null));

      page.patchValue(pageData);
      this.pages.push(page);
    });
  }

  onImageSelect(event: Event, index: number): void {
    const file = nth((<HTMLInputElement>event.target).files, 0);

    if (file) {
      this.fileReader.readAsDataURL(file);
      this.fileReader.onload = () => {
        const { result } = this.fileReader;

        nth(this.videoInput.toArray(), index).nativeElement.value = null;
        this.patchMediaValue(MediaTypes.IMAGE, result, index);
      };

      this.mediaError[index] = false;
    }
  }

  onVideoSelect(event: Event, index: number): void {
    const { value } = <HTMLInputElement>event.target;
    const videoId = this.getYoutubeId(value);
    const baseVideoUrl = 'https://www.youtube.com/embed';

    const videoUrl: string = videoId ? `${baseVideoUrl}/${videoId}` : null;
    const mediaType = videoId ? MediaTypes.VIDEO : null;

    nth(this.videoInput.toArray(), index).nativeElement.value = videoUrl;
    this.patchMediaValue(mediaType, videoUrl, index);
  }

  deleteMedia(index: number): void {
    this.patchMediaValue(null, null, index);

    nth(this.videoInput.toArray(), index).nativeElement.value = null;
    nth(this.imageInput.toArray(), index).nativeElement.value = null;
  }

  addPage(): void {
    const pageForm = this.getPageForm();

    this.pages.push(pageForm);
    this.setActiveTab(this.pages.length - 1);
  }

  removePage(index: number): void {
    this.pages.removeAt(index);
  }

  setActiveTab(index: number): void {
    this.activeTab.next(index);
  }

  patchMediaValue(type: MediaTypes, data: string | ArrayBuffer, index: number): void {
    nth(this.pages.controls, index).get('media').patchValue({ type, data });
  }

  onSaveReleaseNotes(): void {
    if (this.form.valid) {
      const { id, hasViewed, ...newReleaseNote } = this.form.getRawValue();
      return id ? this.updateReleaseNote(id, newReleaseNote) : this.createReleaseNote(newReleaseNote);
    }
  }

  createReleaseNote(newReleaseNote: Partial<ReleaseNote>): void {
    this.releasesService
      .createReleaseNote({ newReleaseNote })
      .pipe(takeUntil(this.alive))
      .subscribe(() => this.dialogRef.close(true));
  }

  updateReleaseNote(releaseNoteId: string, newReleaseNote: Partial<ReleaseNote>): void {
    from(newReleaseNote.pages)
      .pipe(
        concatMap((page: WhatsNewPage) =>
          !page.id ? this.releasesService.addPageToReleaseNote({ releaseNoteId, page }) : of(newReleaseNote)
        ),
        last(),
        switchMap((releaseNote: ReleaseNote) => {
          const releaseNoteFields = this.normalizeReleaseNote(releaseNote);

          return this.releasesService.editReleaseNote({ releaseNoteId, releaseNoteFields });
        })
      )
      .subscribe(() => {
        this.dialogRef.close(true);
      });
  }

  normalizeReleaseNote(releaseNote: Partial<ReleaseNote>): Partial<ReleaseNote> {
    const release = omit(releaseNote, ['id', 'hasViewed', '__typename']);
    const pages = release.pages.map((whatsNewPage: WhatsNewPage) => {
      const page = omit(whatsNewPage, '__typename');
      const media = omit(page.media, '__typename');

      return { ...page, media };
    });

    return { ...release, pages };
  }

  getYoutubeId(url: string): string {
    if (!url) {
      return null;
    }

    const regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/;
    const match = url.match(regExp);

    return match && match[1].length === 11 ? match[1] : null;
  }

  onImageError(event: Event, index: number): void {
    this.mediaError[index] = true;
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }
}
