import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmationComponent, LoadsServiceService } from '@haulynx/services';
import { BookLoadModel, LoadActiveModel } from '@haulynx/store';
import {
  AccountType,
  buttonTypes,
  CreateOptionsBts,
  DistributionMethod,
  Environment,
  HttpStatus,
  LoadActiveStatus,
  LoadActiveStep,
  LoadLocationType,
  LoadsServiceLoad,
  User,
} from '@haulynx/types';
import { aliveWhile, hasClosed, hasDelivered, isAcceptedBy, isAssigned } from '@haulynx/utils';
import { find, get, last, reduce } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { UserFormComponent } from '../../../users/components/user-form/user-form.component';
import { LoadActiveAcceptComponent } from '../load-active-accept/load-active-accept.component';
import { LoadActiveAssignComponent } from '../load-active-assign/load-active-assign.component';
import { LoadActiveDeliveredComponent } from '../load-active-delivered/load-active-delivered.component';

@Component({
  selector: 'app-load-active-status',
  templateUrl: './load-active-status.component.html',
  styleUrls: ['./load-active-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadActiveStatusComponent implements OnChanges, OnDestroy {
  @Input() title = null;
  @Input() load: LoadsServiceLoad | null = null;
  @Input() user: User = null;
  @Input() showEditLoadButton: boolean;
  @Output() onSuccess = new EventEmitter<string>();
  @Output() onClosed = new EventEmitter<string>();

  label: string = null;
  statuses: LoadActiveStep[] = [];
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  onTime: boolean;

  alive = aliveWhile();
  loadActiveStatus: LoadActiveStatus = new LoadActiveStatus();
  createOptionsBts: CreateOptionsBts = new CreateOptionsBts();
  private accountType = AccountType;

  constructor(
    private dialog: MatDialog,
    public loadActiveModel: LoadActiveModel,
    private loadsServiceService: LoadsServiceService,
    private router: Router,
    private bookLoadModel: BookLoadModel,
    private environment: Environment
  ) {
    this.loadActiveModel.isLoading$
      .pipe(
        filter(() => !!this.load),
        map((loading) => this.load && !!loading.get(this.load.id)),
        takeUntil(this.alive)
      )
      .subscribe((loading) => {
        this.isLoading$.next(loading);
      });

    this.loadActiveModel.httpStatus$
      .pipe(
        filter(() => !!this.load),
        map((httpStatus) => this.load && httpStatus.get(this.load.id)),
        takeUntil(this.alive)
      )
      .subscribe((status) => {
        if (status === HttpStatus.SUCCESS) {
          this.onSuccess.emit(this.load.id);
        }
      });
  }

  execute(execAction: string, index: number) {
    const loadId = this.load.id;
    const activeStep = this.statuses[index];
    this.activePopUp(execAction, loadId, activeStep);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { load } = changes;

    if (load) {
      this.statuses = this.checkStatues(load.currentValue, this.user.carrier);
      this.label = this.checkLabel(this.statuses);
    }
  }

  activePopUp(executeAction: string, loadId: string, activeStep: LoadActiveStep) {
    switch (executeAction) {
      case this.loadActiveStatus.ACCEPTED.id:
        this.dialog
          .open(LoadActiveAcceptComponent, {
            data: {
              load: this.load,
            },
          })
          .afterClosed()
          .pipe(takeUntil(this.alive))
          .subscribe((result) => {
            const dot = get(this.user, 'carrier.dot', null);
            if (result.action === buttonTypes.ACCEPT.action) {
              this.bookLoadModel.bookLoad({ load: this.load, carrierDot: dot });
              this.statuses = this.checkStatues(this.load, this.user.carrier);
              this.label = this.checkLabel(this.statuses);
            } else if (result.action === buttonTypes.REJECT.action) {
              this.loadsServiceService
                .getBrokerBookedLoads(this.load.id, dot)
                .pipe(takeUntil(this.alive))
                .subscribe((data) => {
                  const brokerId = get(data, 'data.getBrokerOffer.brokerId', null);
                  this.loadActiveModel.reject({
                    loadId: loadId,
                    carrierDot: dot,
                    brokerId: brokerId,
                    rejectionReason: result.reasons,
                  });
                });
              this.onClosed.emit(loadId);
            }
          });
        break;
      case this.loadActiveStatus.ASSIGNED.id: {
        const load = {
          id: this.load.id,
          drivers: this.load?.drivers,
          truck: this.load?.truck,
          trailerId: this.load?.trailers[0]?.id,
          trailers: this.load?.trailers,
        };
        this.dialog
          .open(LoadActiveAssignComponent, {
            data: {
              load: load,
            },
          })
          .afterClosed()
          .pipe(takeUntil(this.alive))
          .subscribe((result) => {
            const { action = null, data = null } = result || {};

            if (action === this.createOptionsBts.NEW_DRIVER) {
              this.dialog
                .open(UserFormComponent, {
                  data: {
                    accountType: this.accountType.DRIVER,
                    company: this.user.carrier,
                  },
                  width: '400px',
                })
                .afterClosed()
                .pipe(takeUntil(this.alive))
                .subscribe((newUser) => {
                  if (newUser) {
                    this.activePopUp(this.loadActiveStatus.ASSIGNED.id, loadId, activeStep);
                  }
                });
            } else if (action === this.createOptionsBts.NEW_TRAILER) {
              this.loadActiveModel.goTo('/dashboard/fleet');
            } else if (action === this.createOptionsBts.NEW_TRUCK) {
              this.loadActiveModel.goTo('/dashboard/fleet');
            } else if (action && data) {
              this.loadActiveModel.assigned({
                loadId,
                load: { ...this.load, ...data, distributionMechanism: DistributionMethod.MANUAL },
              });
            }
          });
        break;
      }

      case this.loadActiveStatus.DROP_OFF.id:
        {
          this.activePopUp(this.loadActiveStatus.CONFIRM_DROP_OFF.id, loadId, activeStep);
        }
        break;
      case this.loadActiveStatus.CONFIRM_DROP_OFF.id:
        {
          this.dialog
            .open(LoadActiveDeliveredComponent, {
              data: {
                location: this.load.locations[activeStep.index],
                title: 'DROP-OFF LOAD',
                onTime: this.onTime,
                loadId: loadId,
                trailerId: this.load?.trailers[0]?.id ?? 'N/A',
                showConfirmButton: true,
              },
            })
            .afterClosed()
            .pipe(takeUntil(this.alive))
            .subscribe((result) => {
              const { data = null } = result || {};

              if (data) {
                this.loadActiveModel.confirmPickup({ loadId, data, position: activeStep.index });
              }

              this.onTime = false;
            });
        }
        break;
      case this.loadActiveStatus.PICKED_UP.id:
        {
          this.activePopUp(this.loadActiveStatus.CONFIRM_PICK_UP.id, loadId, activeStep);
        }
        break;
      case this.loadActiveStatus.CONFIRM_PICK_UP.id:
        {
          this.dialog
            .open(LoadActiveDeliveredComponent, {
              data: {
                location: this.load?.locations?.[activeStep?.index],
                title: 'PICK-UP LOAD',
                onTime: this.onTime,
                showConfirmButton: true,
                position: activeStep.index,
                loadId: loadId,
                trailerId: this.load?.trailers?.[0]?.id ?? 'N/A',
                carrierDot: get(this.load, 'carrier.dot', null),
              },
            })
            .afterClosed()
            .pipe(takeUntil(this.alive))
            .subscribe((result) => {
              const { data = null } = result || {};

              if (data) {
                this.loadActiveModel.confirmPickup({ loadId, data, position: activeStep.index });
              }

              this.onTime = false;
            });
        }
        break;
      case this.loadActiveStatus.DELIVERED.id:
        {
          this.activePopUp(this.loadActiveStatus.CONFIRM_DELIVERY.id, loadId, activeStep);
        }
        break;
      case this.loadActiveStatus.CONFIRM_DELIVERY.id:
        {
          const previousLoad = this.load.locations[activeStep.index - 1];
          const min = get(previousLoad, 'completed', null);

          this.dialog
            .open(LoadActiveDeliveredComponent, {
              data: {
                location: this.load.locations[activeStep.index],
                position: activeStep.index,
                loadId: loadId,
                onTime: this.onTime,
                showConfirmButton: true,
                carrierDot: get(this.load, 'carrier.dot', null),
                min,
              },
            })
            .afterClosed()
            .pipe(takeUntil(this.alive))
            .subscribe((result) => {
              const { data = null } = result || {};

              if (data) {
                this.loadActiveModel.completeDelivery({ loadId, data, position: activeStep.index });
              }

              this.onTime = false;
            });
        }
        break;
      case this.loadActiveStatus.CLOSED.id:
        this.dialog
          .open(ConfirmationComponent, {
            data: {
              title: 'Confirmation',
              message: 'Would you like to close this load?',
              confirm: { text: 'Yes' },
              deny: { text: 'No', hide: false },
            },
          })
          .afterClosed()
          .pipe(takeUntil(this.alive))
          .subscribe((isConfirmed) => {
            if (isConfirmed) {
              this.loadActiveModel.close({ loadId });

              this.onClosed.emit(loadId);
            }
          });
        break;
    }
  }

  checkStatues(load: LoadsServiceLoad, carrier): LoadActiveStep[] {
    if (!load) return;
    const isClosed = hasClosed(load),
      isDelivered = hasDelivered(last(load && load.locations)),
      isAssign = isAssigned(load),
      isAccepted = isAcceptedBy(load, carrier);

    const assignedName = isAssign ? load?.drivers[0]?.name || (load.truck && load.truck.unitId) : '';
    const acceptStatus = {
      ...this.loadActiveStatus.ACCEPTED,
      ...{
        completed: isAccepted,
        active: !isAssigned && !isAccepted,
      },
    };
    const assignedStatus = {
      ...this.loadActiveStatus.ASSIGNED,
      ...{
        completed: isAssigned,
        active: !isClosed && isAccepted,
        date: null,
        info: assignedName,
        actionLabel: this.loadActiveStatus.ASSIGNED.actionLabel,
      },
    };
    const closeStatus = {
      ...this.loadActiveStatus.CLOSED,
      ...{
        completed: isClosed,
        active: !isClosed && isDelivered,
        date: load && load?.lastUpdatedAt,
      },
    };

    const result = reduce(
      load.locations,
      ({ locationStatuses, previousLocations }, location, index) => {
        let locationType;

        if (load.locations.length - 1 === index && location.locationType === LoadLocationType.DROPOFF) {
          locationType = this.loadActiveStatus.DELIVERED;
        } else if (location.locationType === LoadLocationType.PICKUP) {
          locationType = this.loadActiveStatus.PICKED_UP;
        } else {
          locationType = this.loadActiveStatus.DROP_OFF;
        }

        const newStatus = {
          ...locationType,
          active:
            isAssign &&
            ((!location.carrierDeparture && !previousLocations) ||
              (!location.carrierDeparture && previousLocations && !!previousLocations.carrierDeparture)),
          completed: !!location.carrierDeparture,
          date: location.carrierDeparture ? location.carrierDeparture : null,
          timezone: location.timezone ? location.timezone : null,
          index: index,
        };

        return { locationStatuses: [...locationStatuses, newStatus], previousLocations: location };
      },
      { locationStatuses: [], previousLocations: null }
    );

    const creator = get(load, 'creator');
    const isBrokerCreated = creator === this.environment.usx.creatorId;

    if (isBrokerCreated) {
      return [acceptStatus, assignedStatus, ...result.locationStatuses, closeStatus];
    }

    return [assignedStatus, ...result.locationStatuses, closeStatus];
  }

  checkLabel(statuses: LoadActiveStep[]): string {
    const activeStatus = find<LoadActiveStep>(statuses, (status) => !status.completed && status.active);

    if (!activeStatus) {
      return null;
    }

    if (activeStatus.id === this.loadActiveStatus.ACCEPTED.id) {
      return 'Offered';
    }
    if (activeStatus.id === this.loadActiveStatus.ASSIGNED.id) {
      return 'Unassigned';
    }
    if (activeStatus.id === this.loadActiveStatus.DISPATCHED.id) {
      return 'Awaiting Dispatch';
    }
    if (activeStatus.id === this.loadActiveStatus.PICKED_UP.id) {
      return 'In Transit';
    }
    if (activeStatus.id === this.loadActiveStatus.PICKED_UP.id) {
      return 'Closure Pending';
    }
    if (activeStatus.id === this.loadActiveStatus.CLOSED.id) {
      return 'Complete';
    }
  }

  success(loadId, event: MouseEvent) {
    this.onSuccess.emit(loadId);
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }

  onEditLoad(load: LoadsServiceLoad): void {
    const { id } = load;

    this.router.navigate(['/dashboard', 'loads', 'view', id]);
  }
}
