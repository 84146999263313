import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BlueFabModule } from '../blue-fab/blue-fab.module';
import { TermsOfServiceTextComponent } from './terms-of-service-text.component';

@NgModule({
  declarations: [TermsOfServiceTextComponent],
  imports: [CommonModule, BlueFabModule],
  exports: [TermsOfServiceTextComponent],
})
export class TermsOfServiceTextModule {}
