<div class="search-location-container" #wrapper>
  <form [formGroup]="form" autocomplete="off" *ngIf="focusedFilter">
    <div class="sl-chip-title">
      {{ focusedFilter.name }}
    </div>
    <input
      matInput
      #slInput
      type="text"
      class="sl-mat-input sl-mat-input--location"
      [formControl]="locationArrayForm?.controls[this.selectedIndex]"
      placeholder="search location"
      data-test="search-field"
    />
    <ng-container *ngIf="showRadiusField">
      <div class="sl-radius-label">radius</div>
      <input
        matInput
        type="number"
        class="sl-mat-input{{ isRadiusFormValid() ? '' : '--invalid' }}"
        [formControl]="radiusArrayForm?.controls[this.selectedIndex]"
        placeholder="radius"
        data-test="radius-field"
      />
    </ng-container>

    <mat-icon class="sl-close-icon" (click)="removeFocusedFilter()"> close </mat-icon>
  </form>
</div>
