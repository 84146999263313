import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase, AngularFireList, QueryFn } from '@angular/fire/database';
import { QueryOptions } from '@haulynx/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FireDatabaseService {
  constructor(private auth: AngularFireAuth, private db: AngularFireDatabase) {}

  get database(): firebase.database.Database {
    return this.db.database;
  }

  public login(email: string, password: string): Promise<firebase.auth.UserCredential> {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  public list<T>(path: string, query?: QueryFn): AngularFireList<T> {
    return this.db.list(path, query);
  }

  getList(path: string, options: QueryOptions = {}): Observable<unknown[]> {
    this.db.list('');
    return this.db
      .list(path, (ref: firebase.database.Query) => {
        const { limit = 100 } = options;

        if (limit) {
          ref = this.limit(ref, limit);
        }

        return ref;
      })
      .valueChanges();
  }

  getItem(path: string): Observable<any> {
    return this.db.object(path).valueChanges();
  }

  addItem(listName: string, data: unknown): void {
    const listRef = this.db.list(listName);

    listRef.push(data);
  }

  updateItem(listName: string, itemId: string, data: unknown): void {
    const listRef = this.db.list(listName);

    listRef.update(itemId, data);
  }

  private limit(ref: firebase.database.Query, limit: number): firebase.database.Query {
    return ref.limitToFirst(limit);
  }
}
