import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CustomerFacilityDto, LoadsServiceLoad, User } from '@haulynx/types';

@Component({
  selector: 'app-load-quick-actions',
  templateUrl: './load-quick-actions.component.html',
  styleUrls: ['./load-quick-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadQuickActionsComponent {
  @Input() load: LoadsServiceLoad;
  @Input() title = null;
  @Input() showTimeline = false;
  @Input() showContent = false;
  @Input() showSeparator = false;
  @Input() isLoading: boolean;
  @Input() user: User;
  @Output() actionClick: EventEmitter<{
    action: string;
    payload: { [key: string]: unknown };
  }> = new EventEmitter<{ action: string; payload: { [p: string]: unknown } }>();
  @Output() showFacilityInfo = new EventEmitter<CustomerFacilityDto>();

  execute(action, payload): void {
    this.actionClick.emit({
      action,
      payload,
    });
  }

  close(event): void {
    this.actionClick.emit({
      action: 'close',
      payload: null,
    });
  }

  onShowFacilityInfo({ customerNumber, index }: CustomerFacilityDto): void {
    this.showFacilityInfo.emit({ customerNumber, index });
  }
}
