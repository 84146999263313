import { CommonModule, Location } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { SpinnerModule } from '../spinner/spinner.module';
import { MapboxComponent } from './mapbox.component';
import { environment } from '../../../../../apps/web/src/environments/environment';

@NgModule({
  declarations: [MapboxComponent],
  imports: [
    CommonModule,
    SpinnerModule,
    NgxMapboxGLModule.withConfig({
      accessToken: environment.mapbox.accessToken,
    }),
  ],
  exports: [MapboxComponent],
  providers: [Location],
})
export class MapboxModule {}
