import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ActionEventService,
  CarriersService,
  CustomersService,
  LoadsServiceService,
  NotificationsService,
  OpportunityService,
  UserService,
} from '@haulynx/services';
import {
  AsyncBulkMutationManager,
  AsyncDataManager,
  AsyncDestroyMutationManager,
  AsyncMutationManager,
  AsyncQueryManager,
  AsyncSearchManager,
  BidDetails,
  BookStatus,
  BulkEditLoadsInput,
  BulkEditLoadsInputItem,
  CarrierLoadServiceSearchParameters,
  Customer,
  CustomerSearchParameters,
  DocumentParamsInput,
  Environment,
  LaneServiceSearchParameters,
  LoadActionEvent,
  LoadHistory,
  LoadServiceSearchParameters,
  LoadsServiceBrokerEditFields,
  LoadsServiceHistogramField,
  LoadsServiceLoad,
  LoadsServiceLoadInput,
  LoadsServiceLoadStatus,
  LoadsServiceSearchMetaData,
  OpportunityParams,
  PageAndSort,
  RecommendedData,
  RolledLoads,
  Document,
  LoadsServiceDocumentPayload,
} from '@haulynx/types';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { omit } from 'lodash';
import { BehaviorSubject, EMPTY, fromEvent, Observable, Subject, throwError, timer } from 'rxjs';
import {
  catchError,
  delay,
  distinctUntilChanged,
  expand,
  filter,
  map,
  repeatWhen,
  scan,
  startWith,
  switchMap,
  switchMapTo,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { AppState } from '../../main-store/app.reducers';
import { AsyncEntityBase } from '../../main-store/async-entity/async-entity-base.class';
import {
  getLoadLockSubscriptionPayload,
  LoadLockState,
  LoadLockStateManager,
  loadLockStreamReducer,
  processSearchEvents,
  processSubscriptionEvents,
} from './load-lock-helper';
import { loadEntityNamespace } from './namespace';
import { aliveWhile } from '@haulynx/utils';

@Injectable({ providedIn: 'root' })
export class LoadEntityService extends AsyncEntityBase<LoadsServiceLoad> {
  getLoadByIdManager: AsyncQueryManager<LoadsServiceLoad, string>;
  getUSXLoadsManager: AsyncSearchManager<LoadsServiceLoad, Partial<LoadServiceSearchParameters>>;
  getUSXLoadsOffBoardManager: AsyncSearchManager<LoadsServiceLoad, Partial<LoadServiceSearchParameters>>;
  getCarrierLoadsManager: AsyncSearchManager<LoadsServiceLoad, Partial<CarrierLoadServiceSearchParameters>>;
  getCarrierLoadRecommends: AsyncSearchManager<LoadsServiceLoad, { dot: string; radius: number }>;
  getLoadActivity: AsyncSearchManager<LoadHistory, LaneServiceSearchParameters>;
  getUnassignedActiveLoadsManager: AsyncSearchManager<LoadsServiceLoad, Partial<CarrierLoadServiceSearchParameters>>;
  getAssignedActiveLoadsManager: AsyncSearchManager<LoadsServiceLoad, Partial<CarrierLoadServiceSearchParameters>>;
  getInTransitActiveLoadsManager: AsyncSearchManager<LoadsServiceLoad, Partial<CarrierLoadServiceSearchParameters>>;

  updateLoadManager: AsyncMutationManager<{ loadId: string; input: BulkEditLoadsInputItem }, LoadsServiceLoad>;
  editLoadManager: AsyncMutationManager<{ loadId: string; input: Partial<LoadsServiceLoadInput> }, LoadsServiceLoad>;
  downloadLoadActivityManager: AsyncMutationManager<
    { loadId: string; input: Partial<LoadsServiceLoadInput> },
    LoadsServiceLoad
  >;
  brokerUpdateLoadManager: AsyncMutationManager<
    { loadId: string; loadFields: Partial<LoadsServiceBrokerEditFields>; load?: LoadsServiceLoad },
    LoadsServiceLoad
  >;
  createLoadManager: AsyncMutationManager<{ loadInput: LoadsServiceLoadInput }, LoadsServiceLoadInput>;
  bulkUpdateLoadsManager: AsyncBulkMutationManager<
    { loadIds: string[]; input: BulkEditLoadsInput },
    LoadsServiceLoad[]
  >;
  bounceLoadManager: AsyncMutationManager<{ loadId: string; orderNumber: string }, { bounceOrder: boolean }>;
  deleteLoadManager: AsyncDestroyMutationManager<{ loadId: string }, boolean>;
  updateUsxOrderStatusManager: AsyncMutationManager<{ orderNumber: string }, boolean>;
  logEntityActionManager: AsyncDataManager<
    { action: string; entityId: string; entity: string; userId: string; expiredAt?: number },
    { data: boolean }
  >;
  lockState: LoadLockStateManager;
  recommendedLoadTruckIds$: BehaviorSubject<{ [loadId: string]: string[] }>;
  getLoadByNumberManager: AsyncSearchManager<LoadsServiceLoad, { alternateIdValue: string }>;
  reportRolledLoadsManager: AsyncBulkMutationManager<RolledLoads, LoadsServiceLoad[]>;
  getCarrierRecommendations: AsyncSearchManager<
    any,
    Partial<{ loadId: string; useOpportunities: boolean; owner: string }>
  >;
  getOpportunities: AsyncSearchManager<any, Partial<{ loadId: string }>>;
  getOwnedCarriers: AsyncSearchManager<any, Partial<{ carrierOwner: string }>>;
  getCarrierHistoryManager: AsyncSearchManager<LoadsServiceLoad, Partial<LoadServiceSearchParameters>>;
  getCustomersManager: AsyncSearchManager<Customer, Partial<CustomerSearchParameters>>;
  getLoadsMetaDataManager: AsyncSearchManager<
    LoadsServiceSearchMetaData,
    {
      searchParameters: LoadServiceSearchParameters;
      histogramField?: LoadsServiceHistogramField;
      histogramInterval?: number;
    }
  >;
  getLoadsBidDetailsManager: AsyncSearchManager<BidDetails, { loadIds: string[] }>;
  getLoadDocsManager: AsyncSearchManager<Document, Partial<DocumentParamsInput>>;
  private alive = aliveWhile();

  constructor(
    protected actions$: Actions,
    protected store: Store<AppState>,
    private loadsServiceApi: LoadsServiceService,
    private opportunityService: OpportunityService,
    private notifications: NotificationsService,
    private actionEventService: ActionEventService,
    private carriersService: CarriersService,
    private customersService: CustomersService,
    private http: HttpClient,
    private environment: Environment,
    private userService: UserService
  ) {
    super(actions$, store, loadEntityNamespace);

    this.recommendedLoadTruckIds$ = new BehaviorSubject({});

    this.getLoadByIdManager = this.createAsyncQuery((loadId: string) =>
      this.loadsServiceApi.getLoadById(loadId).pipe(
        catchError((error) => {
          this.notifications.error(error, 'There was a problem');
          return throwError(error);
        })
      )
    );

    this.getUSXLoadsManager = this.createAsyncSearchQuery('get usx loads', (searchPayload) =>
      this.loadsServiceApi.searchLoads(
        omit(searchPayload.query, ['viewId', 'fullMission']),
        searchPayload.pageAndSort,
        searchPayload.query?.fullMission
      )
    );

    this.getUSXLoadsOffBoardManager = this.createAsyncSearchQuery('get usx loads offboard', (searchPayload) =>
      this.loadsServiceApi.searchLoads(omit(searchPayload.query, 'viewId'), searchPayload.pageAndSort)
    );

    this.getLoadByNumberManager = this.createAsyncSearchQuery('get load by number', (searchPayload) => {
      return this.loadsServiceApi.getLoadByNumber(searchPayload.query.alternateIdValue);
    });

    this.getCarrierLoadRecommends = this.createAsyncSearchQuery('get carrier load recommend', (searchPayload) => {
      return this.loadsServiceApi.getCarrierLoadRecommends(searchPayload.query, searchPayload.pageAndSort).pipe(
        map((data: RecommendedData) => {
          this.recommendedLoadTruckIds$.next(data.trucks);
          return data.loads;
        })
      );
    });

    this.getLoadActivity = this.createAsyncSearchQuery('get load activity', (searchPayload) =>
      this.loadsServiceApi.getLoadActivity(searchPayload.query.loadId).pipe(
        catchError((error) => {
          this.notifications.error(error, 'There was a problem getting the load history.');
          return throwError(error);
        })
      )
    );

    (searchPayload: { dot: string; paging: PageAndSort; radius: number; fetchPolicy: 'network-only' }) =>
      this.loadsServiceApi.getCarrierLoadRecommends(searchPayload).pipe(
        catchError((error) => {
          this.notifications.error(error, 'There was a problem');
          return throwError(error);
        })
      );

    this.getCarrierLoadsManager = this.createAsyncSearchQuery('get carrier loads', (searchPayload) => {
      console.log('searchPayload', searchPayload);
      const effect = searchPayload.query.searchCarrierLoads
        ? this.loadsServiceApi.searchCarrierLoads(
            searchPayload.query.dot,
            omit(searchPayload.query, 'dot', 'id', 'searchCarrierLoads'),
            searchPayload.pageAndSort
          )
        : this.loadsServiceApi.searchUserLoads(
            searchPayload.query.id,
            omit(searchPayload.query, 'dot', 'id', 'searchCarrierLoads'),
            searchPayload.pageAndSort
          );
      return effect;
    });

    this.getUnassignedActiveLoadsManager = this.createAsyncSearchQuery('unassigned search', (searchPayload) => {
      const filterParams = {
        ...omit(searchPayload.query, 'dot', 'id', 'searchCarrierLoads'),
        loadStatus: [LoadsServiceLoadStatus.UNASSIGNED],
        bookStatus: [BookStatus.BOOKED],
      };
      return this.searchCarrierUserLoads(searchPayload, filterParams);
    });

    this.getAssignedActiveLoadsManager = this.createAsyncSearchQuery('assigned search', (searchPayload) => {
      const filterParams = {
        ...omit(searchPayload.query, 'dot', 'id', 'searchCarrierLoads'),
        loadStatus: [LoadsServiceLoadStatus.ASSIGNED],
        bookStatus: [BookStatus.BOOKED],
      };
      return this.searchCarrierUserLoads(searchPayload, filterParams);
    });

    this.getInTransitActiveLoadsManager = this.createAsyncSearchQuery('in transit search', (searchPayload) => {
      const filterParams = {
        ...omit(searchPayload.query, 'dot', 'id', 'searchCarrierLoads'),
        loadStatus: [
          LoadsServiceLoadStatus.PICKED_UP,
          LoadsServiceLoadStatus.DISPATCHED,
          LoadsServiceLoadStatus.AT_SHIPPER,
          LoadsServiceLoadStatus.AT_RECEIVER,
        ],
        bookStatus: [BookStatus.BOOKED],
      };
      return this.searchCarrierUserLoads(searchPayload, filterParams);
    });

    this.editLoadManager = this.createAsyncMutation(
      'edit load',
      (payload) =>
        this.loadsServiceApi.editLoad(payload.loadId, payload.input).pipe(
          tap(
            () => {
              this.notifications.success('Successfully edited load.', 'Success!');
            },
            (error) => this.notifications.error(error, 'Error updating load')
          )
        ),
      { refreshEntityFrom: 'response' }
    );

    this.downloadLoadActivityManager = this.createAsyncMutation(
      'download load activity',
      (payload) =>
        this.loadsServiceApi.downloadLoadActivities(payload.loadId, payload.input).pipe(
          tap(
            () => {
              this.notifications.success('Successfully downloaded Activities.', 'Success!');
            },
            (error) => this.notifications.error(error, 'Error downloading activities')
          )
        ),
      { refreshEntityFrom: 'response' }
    );

    this.brokerUpdateLoadManager = this.createAsyncMutation(
      'broker update load',
      (payload) =>
        this.loadsServiceApi.brokerUpdateLoad(payload.loadId, payload.loadFields, payload.load).pipe(
          tap(
            () => {
              this.notifications.success('Successfully edited load.', 'Success!');
            },
            (error) => this.notifications.error(error, 'Error editing load')
          )
        ),
      { refreshEntityFrom: 'response' }
    );

    this.updateLoadManager = this.createAsyncMutation(
      'update load',
      (payload) =>
        this.loadsServiceApi.bulkUpdateLoads([payload.input]).pipe(
          map((loadsServiceLoadArray: LoadsServiceLoad[]) => loadsServiceLoadArray[0]),
          tap(
            () => {
              this.notifications.success('Successfully updated load.', 'Success!');
            },
            (error) => this.notifications.error(error, 'Error updating load')
          )
        ),
      { refreshEntityFrom: 'response' }
    );

    this.createLoadManager = this.createAsyncMutation(
      'create load',
      (payload) =>
        this.loadsServiceApi.createCarrierLoad(payload.loadInput).pipe(
          tap(
            () => {
              this.notifications.success(`The load created with success!`, `Load`);
            },
            (error) => this.notifications.error(`Oops, something went wrong. Unable to Save!`, error)
          )
        ),
      { refreshEntityFrom: 'response' }
    );

    this.bulkUpdateLoadsManager = this.createBulkAsyncMutation(
      'bulk update loads',
      (payload) =>
        this.loadsServiceApi.bulkUpdateLoads(payload.input).pipe(
          tap(
            () => {
              this.notifications.success('Successfully updated loads.', 'Success!');
            },
            (error) => this.notifications.error(error, 'Error updating loads')
          )
        ),
      { refreshEntityFrom: 'response' }
    );

    this.bounceLoadManager = this.createAsyncMutation(
      'bounce load',
      (payload) =>
        this.loadsServiceApi.bounceOrder(payload.orderNumber).pipe(
          tap(
            () => {
              this.notifications.success('Successfully bounced load', 'Success!');
            },
            (error) => this.notifications.error(error, 'Error bouncing load')
          )
        ),
      { refreshEntityFrom: 'query' }
    );

    this.deleteLoadManager = this.createAsyncDestroyMutation(
      'delete load',
      (payload) =>
        this.loadsServiceApi.deleteLoad(payload.loadId).pipe(
          tap(
            () => {
              this.notifications.success('Successfully deleted load', 'Success!');
            },
            (error) => this.notifications.error(error, 'Error deleting load')
          )
        ),
      { refreshEntityFrom: 'response' }
    );

    this.updateUsxOrderStatusManager = this.createAsyncMutation('update usx order status', (payload) =>
      this.loadsServiceApi.updateUsxOrderStatus(payload.orderNumber)
    );

    this.reportRolledLoadsManager = this.createBulkAsyncMutation(
      'report rolled loads',
      (payload) =>
        this.loadsServiceApi.reportRolledLoads(payload).pipe(
          tap(
            () => {
              this.notifications.success('Successfully Reported Rolled Load(s)', 'Success!');
            },
            (error) => this.notifications.error(error, 'Error reporting rolled loads')
          )
        ),
      { refreshEntityFrom: 'response' }
    );

    this.logEntityActionManager = this.createAsyncData('log entity action event', (payload) =>
      this.actionEventService
        .logEntityActionEvent(payload.action, payload.entityId, payload.entity, payload.userId, payload?.expiredAt)
        .pipe(
          catchError((error) => {
            this.notifications.error(error, 'There was a problem');
            return throwError(error);
          })
        )
    );

    this.lockState = this.getLoadLockManager();

    this.getCarrierRecommendations = this.createAsyncSearchQuery('get carrier recommends', (searchPayload) => {
      if (searchPayload.query.useOpportunities) {
        const params: OpportunityParams = {
          freightId: searchPayload.query.loadId,
        };

        return this.opportunityService.getOpportunities(params, searchPayload.query.owner) as any;
      }

      return this.loadsServiceApi.getRecommended(searchPayload.query.loadId, searchPayload.pageAndSort) as any;
    });

    this.getOwnedCarriers = this.createAsyncSearchQuery('get owned carriers', (searchPayload) =>
      this.loadsServiceApi.getOwnedCarriers(searchPayload.query.carrierOwner, searchPayload.pageAndSort)
    );

    this.getCarrierHistoryManager = this.createAsyncSearchQuery('get carrier history', (searchPayload) =>
      this.loadsServiceApi.searchLoads(searchPayload.query, searchPayload.pageAndSort)
    );

    this.getCustomersManager = this.createAsyncSearchQuery('get customers', (searchPayload) =>
      this.customersService.searchCustomersBy(searchPayload.query, searchPayload.pageAndSort)
    );

    this.getLoadsMetaDataManager = this.createAsyncSearchQuery('get load meta data', (searchPayload) =>
      this.loadsServiceApi
        .getLoadsServiceSearchMetaData(
          searchPayload.query.searchParameters,
          searchPayload.query.histogramField,
          searchPayload.query.histogramInterval
        )
        .pipe(
          catchError((error) => {
            this.notifications.error(error, 'There was a problem getting the load meta data.');
            return throwError(error);
          })
        )
    );

    this.getLoadsBidDetailsManager = this.createAsyncSearchQuery('get loads bid details', (searchPayload) =>
      this.loadsServiceApi.getLoadsBidDetails(searchPayload.query.loadIds)
    );

    this.getLoadDocsManager = this.createAsyncSearchQuery('get load documents', (payload) =>
      this.loadsServiceApi.getLoadDocuments(payload.query).pipe(
        catchError((error) => {
          this.notifications.error(error, 'There was a problem get this loads documents.');
          return throwError(error);
        })
      )
    );
  }

  private getLoadLockManager(): LoadLockStateManager {
    const loadLockStream$ = new BehaviorSubject<{ [loadId: string]: LoadActionEvent }>({});
    const updateStream$ = new BehaviorSubject<{ [loadId: string]: LoadActionEvent }>({});
    const userViewershipStream$ = new BehaviorSubject<{ [userId: string]: LoadActionEvent }>({});
    const newLoadsSubj = new Subject<boolean>();
    const _isSearchingActions$ = new BehaviorSubject(false);
    const visibilityChange$ = fromEvent(document, 'visibilitychange');
    const tabHidden$ = visibilityChange$.pipe(filter(() => document.hidden));
    const tabVisible$ = visibilityChange$.pipe(filter(() => !document.hidden));

    const next = (loadIds: string[]) => {
      newLoadsSubj.next(true);
      _isSearchingActions$.next(true);

      if (!loadIds?.length) {
        return;
      }

      const [query, filterParams] = getLoadLockSubscriptionPayload(loadIds);

      this.userService.token
        .pipe(
          distinctUntilChanged(),
          switchMap(() =>
            timer(1500).pipe(
              switchMapTo(
                this.actionEventService.searchSubscriptionEvents(filterParams).pipe(
                  map((subscriptionEvents) => processSearchEvents(subscriptionEvents, loadIds)),
                  switchMap((subscriptionEvents) =>
                    this.actionEventService.listenToEvents(query).pipe(
                      map((newState) => processSubscriptionEvents(newState)),
                      startWith(subscriptionEvents)
                    )
                  ),
                  scan(loadLockStreamReducer, {
                    loadLocking: {},
                    userViewership: {},
                    update: {},
                  })
                )
              )
            )
          ),
          takeUntil(newLoadsSubj),
          takeUntil(tabHidden$),
          takeUntil(this.alive),
          repeatWhen(() => tabVisible$.pipe(delay(2000)))
        )
        .subscribe(
          (subscriptionEvents: LoadLockState) => {
            loadLockStream$.next(subscriptionEvents.loadLocking);
            userViewershipStream$.next(subscriptionEvents.userViewership);
            updateStream$.next(subscriptionEvents.update);
            _isSearchingActions$.next(false);
          },
          (error) => {
            _isSearchingActions$.next(false);
          }
        );
    };

    return {
      loadLockStream$: loadLockStream$.asObservable(),
      userViewershipStream$: userViewershipStream$.asObservable(),
      isSearchingActions$: _isSearchingActions$.asObservable(),
      updateStream$: updateStream$.asObservable(),
      next,
    };
  }

  private searchCarrierUserLoads(
    searchPayload,
    filterParams
  ): Observable<{ pagination: Partial<PageAndSort>; data: LoadsServiceLoad[] }> {
    return this.searchLoads(searchPayload, filterParams).pipe(
      take(1),
      expand((data) => {
        const page = data?.pagination?.['currentPage'] + 1;
        const totalPages = data?.pagination?.totalPages;
        if (page <= totalPages) {
          return this.searchLoads(searchPayload, filterParams, {
            page,
            ...omit(searchPayload.pageAndSort, 'page'),
          }).pipe(
            map((response) => ({
              pagination: response.pagination,
              data: [...data.data, ...response.data],
            }))
          );
        }
        return EMPTY;
      })
    );
  }

  private searchLoads(searchPayload, filterParams, pageAndSort = null) {
    const identifier = searchPayload.query.searchCarrierLoads ? searchPayload.query?.dot : searchPayload.query?.id;
    if (searchPayload.query.searchCarrierLoads)
      return this.loadsServiceApi.searchCarrierLoads(
        identifier,
        filterParams,
        pageAndSort || searchPayload.pageAndSort
      );
    return this.loadsServiceApi.searchUserLoads(identifier, filterParams, pageAndSort || searchPayload.pageAndSort);
  }

  downloadFile(fileExt: string, fileId: string, token: string) {
    return this.http.get(`/document-proxy/document/${fileId}/file`, {
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'blob',
    });
  }

  uploadFile(documentPayload: LoadsServiceDocumentPayload, token: string): Observable<any> {
    const formData = new FormData();

    formData.set('file', documentPayload.file);
    formData.set('data', JSON.stringify(omit(documentPayload, ['file'])));

    return this.http.post(`/document-proxy/document`, formData, {
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'json',
    });
  }
}
