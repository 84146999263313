import { createSelector } from '@ngrx/store';
import { getCarrierManagementState } from './carrier-management-state.state';

export const topSelectors = createSelector(getCarrierManagementState, (state) => {
  return state.top;
});

export const isLoadingTopSelectors = createSelector(getCarrierManagementState, (state) => {
  return state.isLoadingTop;
});

export const developingSelectors = createSelector(getCarrierManagementState, (state) => {
  return state.developing;
});

export const isLoadingDevelopingSelectors = createSelector(getCarrierManagementState, (state) => {
  return state.isLoadingDeveloping;
});

export const prospectsSelectors = createSelector(getCarrierManagementState, (state) => {
  return state.prospects;
});

export const isLoadingProspectsSelectors = createSelector(getCarrierManagementState, (state) => {
  return state.isLoadingProspects;
});
