import { Component, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  AdminVerifyEmailDialogComponent,
  CarrierSignupOnePageDialogComponent,
  LogoutDialog,
  TourComponent,
} from '@haulynx/components';
import { AnalyticsService, FireDatabaseService, WindowRef } from '@haulynx/services';
import { DashboardModel, NotificationModel, UserEntityService } from '@haulynx/store';
import {
  AccountRoute,
  ANALYTICS_EVENT,
  CarrierSearchListRoute,
  FeatureFlag,
  FFState,
  NotificationEventType,
  SecondaryNotificationEvent,
  User,
  WebNotification,
} from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { differenceInDays, subDays } from 'date-fns';
import { combineLatest, Observable } from 'rxjs';
import { first, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-root-container',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss'],
})
export class RootComponent implements OnDestroy {
  @ViewChild('tour', { read: TourComponent }) tour: TourComponent;

  user: User;
  token: string;
  params: { [key: string]: string };
  reportMode = false;
  alive = aliveWhile();
  showNotification = false;
  isMilestones = false;

  public showNewToolbar$: Observable<boolean>;
  featureFlag = FeatureFlag.WHATS_NEW;
  featureFlags: FFState;

  constructor(
    public dashboardModel: DashboardModel,
    private activeRoute: ActivatedRoute,
    private analytics: AnalyticsService,
    public dialog: MatDialog,
    public notificationModel: NotificationModel,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private firebaseDatabaseService: FireDatabaseService,
    private windowRef: WindowRef,
    private userEntityService: UserEntityService,
    private analyticsEvent: AnalyticsService
  ) {
    this.userEntityService.token$.pipe(takeUntil(this.alive)).subscribe((token: string) => {
      this.token = token;
    });
    this.userEntityService.user$.pipe(takeUntil(this.alive)).subscribe((user: User) => {
      this.user = user;
      if (this.user == null) {
        return;
      }

      this.firebaseDatabaseService
        .getItem(`eventUpdates/${this.user.id}/events`)
        .pipe(takeUntil(this.alive))
        .subscribe(() => {
          if (!user) return;

          this.notificationModel.refresh({ recipient: user.id, targetCarrierDot: user?.carrier?.dot });
        });
    });

    this.activeRoute.queryParams.subscribe((params) => {
      this.params = params;
      if (params && params.report) {
        this.reportMode = true;
      }
    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('/milestones')) {
          this.isMilestones = true;
        } else {
          this.isMilestones = false;
        }
      }
    });

    this.showNewToolbar$ = combineLatest([this.userEntityService.user$, this.userEntityService.featureFlags$]).pipe(
      map(([user, features]: [User, FFState]) => features?.CARRIER_HEADER && (!!user?.carrier || user?.isHaulynxAdmin))
    );

    combineLatest([this.userEntityService.user$, this.userEntityService.featureFlags$])
      .pipe(takeUntil(this.alive))
      .subscribe(([user, featureFlags]) => {
        if (!user) return;

        const recipient = user && user.id;
        const currentDate = new Date();
        const pastDays = differenceInDays(currentDate, subDays(currentDate, 7));
        this.featureFlags = featureFlags;

        if (recipient) {
          const eventTypes = [];

          if (featureFlags) {
            if (featureFlags[FeatureFlag.NOTIFICATION_NEW_MATCH]) {
              eventTypes.push(NotificationEventType.POSTED_TRUCK_RECOMMENDATION);
            }
            if (featureFlags[FeatureFlag.REPEAT_NOTIFICATIONS]) {
              eventTypes.push(NotificationEventType.BROKER_PROFILE_RECOMMENDATION);
            }
            if (featureFlags[FeatureFlag.CARRIER_BID]) {
              eventTypes.push(NotificationEventType.BIDS_EVENT);
            }
            if (featureFlags[FeatureFlag.LOAD_BROKER_ASSIGNMENTS]) {
              eventTypes.push(NotificationEventType.LOAD_BROKER_ASSIGNMENT);
            }
            if (user.broker) {
              eventTypes.push(NotificationEventType.AUTO_ASSIGN_BROKER);
            }
            if (user.carrier) {
              eventTypes.push(NotificationEventType.LOAD_MATCH);
            }
            this.notificationModel.setAvailableNotificationTypes(eventTypes);

            this.notificationModel.search({ recipient, pastDays, targetCarrierDot: user?.carrier?.dot });
          }
        }
      });

    this.notificationModel.showNotification$
      .pipe(takeUntil(this.alive))
      .subscribe((showNotification) => (this.showNotification = showNotification));
  }

  selectNotification(webNotification: WebNotification): void {
    if (webNotification.eventType === NotificationEventType.POSTED_TRUCK_RECOMMENDATION) {
      const carrierDot = webNotification.notifications[0].targetCarrierDot;
      this.router.navigate([`/dashboard/carriers/view/${carrierDot}`], { relativeTo: this.activatedRoute });
    } else if (
      webNotification.eventType === NotificationEventType.BIDS_EVENT ||
      webNotification.eventType === NotificationEventType.LOAD_MATCH
    ) {
      if (this.featureFlags[FeatureFlag.LOAD_OVERVIEW] && !!this.user.broker) {
        this.router.navigate([`/loads/${webNotification.targetObjectId}/overview/capacity`]);
      } else if (this.featureFlags[FeatureFlag.CARRIER_LOAD_SEARCH]) {
        if (webNotification.secondaryEventType === SecondaryNotificationEvent.BID_ACCEPTANCE) {
          this.router.navigate([`/dashboard/loads/search/active/details/${webNotification?.targetObjectId}`]);
          this.closeNotification(webNotification);
          return;
        }
        this.router.navigate(['/loads/search'], {
          queryParams: {
            load: webNotification.targetObjectId,
            list:
              webNotification.eventType === NotificationEventType.BIDS_EVENT
                ? CarrierSearchListRoute.BIDS_AND_OFFERS
                : CarrierSearchListRoute.RECOMMENDED,
          },
          queryParamsHandling: 'merge',
        });
      } else {
        this.router.navigate([`/loads/${webNotification.targetObjectId}`]);
      }
    } else if (NotificationEventType.BROKER_PROFILE_RECOMMENDATION || NotificationEventType.LOAD_BROKER_ASSIGNMENT) {
      this.router.navigate([`/loads/${webNotification.targetObjectId}`]);
    }
    const notificationIds = webNotification.notifications.map((item) => item.id);
    const creatorId = webNotification.createdBy;
    this.notificationModel.deactivateNotifications(creatorId, notificationIds);
    this.closeNotification(webNotification);
  }

  closeNotification(notification: WebNotification): void {
    this.notificationModel.hideNotification();
  }

  searchNotification(data?): void {
    this.notificationModel.search(data);
  }

  showNotifications(): void {
    if (this.showNotification) {
      this.notificationModel.hideNotification();
    } else {
      this.notificationModel.showNotification();
      this.analytics.logEvent(ANALYTICS_EVENT.VIEW_NOTIFICATIONS);
    }
  }

  showVerifyEmail(): void {
    this.dialog.open(AdminVerifyEmailDialogComponent, {
      panelClass: 'account-dialogs',
      maxWidth: '480px',
    });
  }

  logout(): void {
    const dialogRef = this.dialog.open(LogoutDialog, {
      panelClass: this?.user?.carrier ? 'logout-dialog-container' : '',
      disableClose: true,
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe((result) => {
        if (result) {
          this.userEntityService.logout();
          this.router.navigate([AccountRoute.LOGIN]);
        }
      });
  }

  goToAccount(): void {
    this.dashboardModel.goTo('/dashboard/account');
    this.analytics.logEvent(ANALYTICS_EVENT.VIEW_ACCOUNT);
  }

  goToLogs(): void {
    this.analytics.logEvent(ANALYTICS_EVENT.VIEW_LOGS);
  }

  goToLogin(): void {
    this.router.navigate([`login`], {
      queryParams: { returnUrl: this.router.url },
    });
  }

  goToSignup(): void {
    const { dot } = this.params;
    if (dot) {
      this.dialog
        .open(CarrierSignupOnePageDialogComponent)
        .afterClosed()
        .subscribe((route: boolean) => {
          if (route) {
            const url = this.router.url;
            if (url.includes('available')) {
              this.router.navigate(['dashboard/loads/search/all'], { queryParamsHandling: 'preserve' });
            } else {
              const loadId = url.split('loads/')[1].split('?')[0];
              this.router.navigate([`dashboard/loads/search/all/details/${loadId}`], {
                queryParamsHandling: 'preserve',
              });
            }
          }
        });
      return;
    }

    this.router.navigateByUrl('/signup');
  }

  goToHelp(): void {
    if (this.user?.broker) {
      this.windowRef.getNativeWindow().open('https://usxpress.freshservice.com/support/catalog/items/513', '_blank');
    } else {
      this.windowRef.getNativeWindow().open('https://support.xpresstechfreight.com/', '_blank');
    }
    this.analytics.logEvent(ANALYTICS_EVENT.VIEW_HELP);
  }

  startTour(): void {
    this.analyticsEvent.logEvent(ANALYTICS_EVENT.VIEW_WHATS_NEW);
    this.tour.startTour();
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }
}
