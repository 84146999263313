import { Component, EventEmitter, Output, Input, ChangeDetectionStrategy } from '@angular/core';
import {
  CarrierSearchListRoute,
  VirtualScrollSortType,
  LoadsServiceOrderByType,
  LoadsServiceOrderByTypeDirection,
  PageAndSort,
  User,
} from '@haulynx/types';

@Component({
  selector: 'haulynx-virtual-scroll-sort',
  templateUrl: './virtual-scroll-sort.component.html',
  styleUrls: ['./virtual-scroll-sort.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VirtualScrollSortComponent {
  @Input() list: CarrierSearchListRoute;
  @Input() collapsedExpansionHeaderHeight: string = '57px';
  @Input() expandedExpansionHeaderHeight: string = '44px';
  @Input() innerCollapsedExpansionHeaderHeight: string = '46px';
  @Input() innerExpandedExpansionHeaderHeight: string = '46px';
  @Input() mobileVirtualScrollSort: VirtualScrollSortType[];
  @Input() pageSortState: PageAndSort;
  @Input() user: User;

  @Output() updateSort = new EventEmitter();
  @Output() bannerToggle = new EventEmitter();

  expansionToggle: boolean = true;

  constructor() {}

  ngAfterViewInit(): void {
    if (this.pageSortState?.order && this.pageSortState?.sort) {
      this.selectCurrentSortType(
        null,
        this.pageSortState?.order as LoadsServiceOrderByType,
        this.pageSortState?.sort as LoadsServiceOrderByTypeDirection
      );
    }
  }

  /**
   * changeSortOrder sets new sort order
   * will return is selection is already selected
   * to avoid unnessary order changes.
   * @param sortType
   * @param direction
   * @returns
   */
  changeSortOrder(sortType: VirtualScrollSortType, direction: LoadsServiceOrderByTypeDirection): void {
    if (sortType?.selected) return;
    this.selectCurrentSortType(sortType, null, direction);
    this.sortUpdate(sortType);
  }

  /**
   * changeSortDirection sets new sort direction asc or desc
   * will return if sortType is not selected to avoid unnecessary
   * direction changes.
   * @param sortType
   * @param direction
   * @returns
   */
  changeSortDirection(sortType: VirtualScrollSortType, direction: LoadsServiceOrderByTypeDirection): void {
    if (!sortType.selected) return;
    sortType.sortDirection = direction;
    this.sortUpdate(sortType);
  }

  /**
   * selectCurrentSortType will select new sort order and direction
   * and set the rest false.
   * @param sortType
   * @param orderType
   * @param direction
   */
  selectCurrentSortType(
    sortType: VirtualScrollSortType = null,
    orderType: LoadsServiceOrderByType = null,
    direction: LoadsServiceOrderByTypeDirection
  ): void {
    const type: LoadsServiceOrderByType = orderType ? orderType : sortType?.sortOrder;
    this.mobileVirtualScrollSort.forEach((virtualType) => {
      if (virtualType?.sortOrder === type) {
        virtualType.selected = true;
        virtualType.sortDirection = direction;
        return;
      }
      if (virtualType?.expansionSortOptions?.length) {
        virtualType.expansionSortOptions.forEach((childSortType) => {
          if (childSortType?.sortOrder === type) {
            childSortType.selected = true;
            childSortType.sortDirection = direction;
            return;
          }
          childSortType.selected = false;
          childSortType.sortDirection = null;
        });
      }
      virtualType.selected = false;
      virtualType.sortDirection = null;
    });
  }

  /**
   * sortUpdate emits and object containing
   * new sort order, direction and data index
   * @param sortType
   */
  private sortUpdate(sortType: VirtualScrollSortType): void {
    let { sortOrder, sortDirection, dataIndex = null } = sortType;
    this.updateSort.emit({ sortOrder, sortDirection, dataIndex });
  }

  onToggleMobileBanner(showBanner: boolean) {
    this.bannerToggle.emit(showBanner);
  }
}
