<main class="container carrierInfo">
  <div class="row">
    <div class="col l12">
      <p>
        Haulynx is dedicated to creating an intense relationship with Carriers that will help them to reduce their costs
        and increase their bottom line profits.<br />
        Haulynx creates this relationship by offering free services to Carriers. That’s right – Free.<br />
        ALL THE SERVICES THAT HAULYNX PROVIDES TO CARRIERS ARE FREE.
      </p>
      <p>
        <b>Haulynx Free FMCSA Compliant ELD</b>
      </p>
      <p>
        Haulynx wants to partner with Carriers to help them reduce their costs<br />
        That is why Haulynx has created a <b>Free</b> FMCSA compliant Electronic Logging Device.<br />
        That’s right - <b>FREE</b>. That means:
      </p>
      <p>
        <!--<mat-list>
          <mat-list-item>No charge for the hardware</mat-list-item> 
          <mat-list-item>No charge to help you install the device</mat-list-item> 
          <mat-list-item>No monthly charge for using the Haulynx ELD.</mat-list-item> 
        </mat-list> -->
        <mat-list *ngFor="let item of items">
          <mat-list-item>
            <mat-icon mat-list-icon>chevron_right</mat-icon>
            <p matLine>{{ item.title }}</p>
          </mat-list-item>
        </mat-list>
      </p>
    </div>
  </div>
  <div class="“row”">
    <div>
      <p>
        <b>Haulynx Free Real Time Tracking</b>
      </p>
      <p>
        Haulynx offers more than just a free ELD.<br />
        Carriers using our ELD will also be able to access the Haulynx network to track<br />
        the location of their trucks in real time and to view every one of their trucks on a map.<br />
        Some providers charge as much as $30.00 per month per unit for this service,<br />
        but HAULYNX PROVIDES REAL TIME TRACKING FOR FREE.
      </p>
      <p>
        <b>Haulynx Predictive Algorithms Can Help Make Carriers More Efficient</b>
      </p>
      <p>
        In addition to our other services, Haulynx provides free predictive analytics software<br />
        to help Carriers make better decisions and operate more efficiently.
      </p>
      <p>
        <b>Haulynx Free Load Distribution Network </b>
      </p>
      <P>
        Carriers in the Haulynx’ network will be able to receive loads directly from Shippers and Brokers<br />
        without having to search load boards or bid on loads. Instead, Haulynx will offer its’ Carriers loads<br />
        based on their current location, anticipated delivery time (if currently loaded), availability of service
        hours,<br />
        and a set of conditions regarding load preference established by individual Carriers.<br />
        THIS IS NOT ANOTHER LOAD BOARD. Instead, it is a more efficient method to create <br />
        transparent connections between Shippers, Brokers, and Carriers.
      </P>
      <p>
        <b>Haulynx Will Continue To Add More Free Features</b>
      </p>
      <p>
        As Haulynx continues to build its network, we will continue to add new features that will help<br />
        make a Carriers business cheaper and more efficient. Most importantly:<br />
        <b>HAULYNX WILL NEVER CHARGE CARRIERS FOR ANY OF THE SERVICES IT PROVIDES. FREE MEANS FREE.</b>
      </p>
    </div>
  </div>
</main>
