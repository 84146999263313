<div #clicker class="column-sort-container" (click)="onSelectSortDropdown()">
  <span>{{ buttonSort }}</span>
  <mat-icon data-test="column-sort-down-arrow" *ngIf="!isSelected">keyboard_arrow_down</mat-icon>
  <mat-icon data-test="column-sort-up-arrow" *ngIf="isSelected">keyboard_arrow_up</mat-icon>
</div>

<div #dropdown class="column-sort-dropdown-container" *ngIf="isSelected" [ngStyle]="getDynamicStylingObject()">
  <div class="sort-title">Sort:</div>

  <div class="csd-options" *ngFor="let config of sortConfig; let i = index">
    <div class="csd-option-asc" (click)="onSelectOptionAscending(config)">
      <div class="csd-option-icon">
        <mat-icon [svgIcon]="'sort-asc'"></mat-icon>
      </div>
      <div class="csd-option-text" attr.data-test="{{ config.label }} Asc">
        {{ config.label.toUpperCase() }} ASCENDING
      </div>
    </div>
    <div class="csd-option-desc" (click)="onSelectOptionDescending(config)">
      <div class="csd-option-icon">
        <mat-icon [svgIcon]="'sort-desc'"></mat-icon>
      </div>
      <div class="csd-option-text" attr.data-test="{{ config.label }} Desc">
        {{ config.label.toUpperCase() }} DESCENDING
      </div>
    </div>
  </div>
</div>
