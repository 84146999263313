import { Pipe, PipeTransform } from '@angular/core';
import { utcToZonedTime } from 'date-fns-tz';

@Pipe({
  name: 'workHours',
})
export class WorkHoursPipe implements PipeTransform {
  transform(date: Date): boolean {
    const estTime = utcToZonedTime(date, 'America/New_York');
    const hours = estTime.getHours();
    const minutes = estTime.getMinutes();

    if ((hours <= 8 && minutes < 30) || hours >= 19) {
      return false;
    }
    return true;
  }
}
