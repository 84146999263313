import { Injectable } from '@angular/core';
import { Carrier, FeatureFlagDetails, FeatureFlags, User } from '@haulynx/types';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from '../../main-store/app.reducers';
import { FeatureFlagDashboardActions } from './feature-flag-dashboard.actions';
import {
  featureFlagDashboardCarriersSelector,
  featureFlagDashboardEntitiesSelector,
  featureFlagDashboardSelectedSelector,
  featureFlagDashboardUsersSelector,
  isLoadingFeatureFlagDashboardCarriersSelector,
  isLoadingFeatureFlagDashboardDetailsSelector,
  isLoadingFeatureFlagDashboardSelector,
  isLoadingFeatureFlagDashboardUsersSelector,
} from './feature-flag-dashboard.selectors';

@Injectable()
export class FeatureFlagDashboardModel {
  isLoading$: Observable<boolean> = this.store.select(isLoadingFeatureFlagDashboardSelector);
  isLoadingDetails$: Observable<boolean> = this.store.select(isLoadingFeatureFlagDashboardDetailsSelector);
  entities$: Observable<FeatureFlags[]> = this.store.select(featureFlagDashboardEntitiesSelector);
  selected$: Observable<FeatureFlagDetails> = this.store.select(featureFlagDashboardSelectedSelector);
  isLoadingUsers$: Observable<Record<number, boolean>> = this.store.select(isLoadingFeatureFlagDashboardUsersSelector);
  users$: Observable<Record<number, Partial<User>[]>> = this.store.select(featureFlagDashboardUsersSelector);
  isLoadingCarriers$: Observable<Record<number, boolean>> = this.store.select(
    isLoadingFeatureFlagDashboardCarriersSelector
  );
  carriers$: Observable<Record<number, Partial<Carrier>[]>> = this.store.select(featureFlagDashboardCarriersSelector);

  constructor(private store: Store<AppState>) {}

  findAll(): void {
    this.store.dispatch(FeatureFlagDashboardActions.findAll());
  }

  find(data: { featureFlagName: string }): void {
    this.store.dispatch(FeatureFlagDashboardActions.find(data));
  }

  delete(data: { featureFlagName?: string }): void {
    this.store.dispatch(FeatureFlagDashboardActions.delete(data));
  }

  findUsersByEmail(data: { email: string; index: number }): void {
    this.store.dispatch(FeatureFlagDashboardActions.findUsers(data));
  }

  getCarriersByNameOrDot(data: { name?: string; index: number }): void {
    this.store.dispatch(FeatureFlagDashboardActions.findCarriers(data));
  }

  clearSelectedFeature(): void {
    this.store.dispatch(FeatureFlagDashboardActions.clearSelectedFeature());
  }

  create(data: { featureFlag: FeatureFlagDetails }): void {
    this.store.dispatch(FeatureFlagDashboardActions.create(data));
  }

  update(data: { featureFlagName: string; featureFlag: FeatureFlagDetails }): void {
    this.store.dispatch(FeatureFlagDashboardActions.update(data));
  }
}
