import { List } from 'immutable';

export interface ISearchState {
  entities: List<any>;
  loading: boolean;
  total: number;
  limit: number;
  page: number;
  query: {};
}

export function createInitialSearchState() {
  return {
    getInitialState(): ISearchState {
      return {
        entities: List(),
        loading: false,
        total: 0,
        limit: 10,
        page: 1,
        query: {},
      };
    },
  };
}
