import { Injectable } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { DataTableCls, DataTableType, EntityType, IColumns, IPagination } from '@haulynx/types';
import { listToArray, mapToArray } from '@haulynx/utils';
import { List, Map } from 'immutable';
import { find, has, isArray, isFunction, isString, orderBy, reduce } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class DataTableUtilsService {
  getData(data: DataTableType<EntityType>): EntityType[] {
    if (List.isList(data)) {
      return listToArray(data);
    }

    if (Map.isMap(data)) {
      return mapToArray(data);
    }

    if (isArray(data)) {
      return data;
    }

    return [];
  }

  getRowClass(cls: DataTableCls, row?: EntityType): string {
    if (isFunction(cls)) {
      return cls(row);
    }

    if (isString(cls)) {
      return cls;
    }

    return '';
  }

  sortData(
    data: DataTableType<EntityType>,
    active: string,
    direction: 'asc' | 'desc',
    columns: IColumns[]
  ): EntityType[] {
    const customSort = find<IColumns>(columns, { dataIndex: active });
    const convertData = this.getData(data);

    return orderBy(
      convertData,
      (row) => (customSort && customSort.sortConvert ? customSort.sortConvert(row, active) : row[active]),
      [direction]
    );
  }

  orderBy(
    order: { active?: string; direction?: 'asc' | 'desc' },
    data: DataTableType<EntityType>,
    columns: IColumns[]
  ): EntityType[] {
    const { active = null, direction = null } = order || {};

    if (active && direction) {
      return this.sortData(data, active, direction, columns);
    }

    return this.getData(data);
  }

  getNumberOfRow(indexNumber: number, pagination: IPagination, isLazyLoad: boolean): number {
    let numberOfRow;

    if (isLazyLoad) {
      numberOfRow = (pagination.page - 1) * pagination.limit + indexNumber + 1;
    } else {
      numberOfRow = indexNumber + 1;
    }

    return numberOfRow;
  }

  getDisplayColumns(
    tableColumns: IColumns[]
  ): {
    displayColumns: IColumns[];
    columns: string[];
    sortActiveColumn: string;
    sortActiveDirection: SortDirection;
  } {
    return reduce(
      tableColumns,
      ({ columns, displayColumns, sortActiveColumn, sortActiveDirection }, column: IColumns) => {
        const isSortable = has(column, 'isSortable') ? column.isSortable : true;
        const newColumn = { ...column, isSortable };

        displayColumns.push(newColumn);
        columns.push(newColumn.dataIndex);

        if (has(column, 'initiallySortedBy')) {
          sortActiveColumn = column.dataIndex;
          sortActiveDirection = column.initiallySortedBy;
        }

        return { displayColumns, columns, sortActiveColumn, sortActiveDirection };
      },
      { displayColumns: [], columns: [], sortActiveColumn: null, sortActiveDirection: null }
    );
  }

  getHideColumnsMap(columns: IColumns[]): { [key: string]: boolean } {
    return reduce(
      columns,
      (accumulator, column) => {
        return { ...accumulator, [column.dataIndex]: true };
      },
      {}
    );
  }
}
