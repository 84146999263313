import { ISearchFilter, TextFilterType } from '../search-filter';
import { ISearchFilterType } from '../search-filter.types';
import { startCase } from 'lodash';
import { LoadOrderType } from '../../load';

export class OrderTypeFilter implements ISearchFilter {
  name = 'Freight Mix';
  type: ISearchFilterType = ISearchFilterType.MULTI_DROPDOWN;
  keys: TextFilterType;
  description = 'Filter loads by freight mix';

  constructor(defaultValues?: TextFilterType) {
    const options = Object.values(LoadOrderType).map((orderType) => {
      return {
        key: startCase(orderType),
        value: orderType,
      };
    });
    this.keys = new TextFilterType({
      value: '',
      optionsDataIndex: options,
      textFormName: 'orderType',
      ...defaultValues,
    });
  }
}
