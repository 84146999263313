import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CarrierManageContainerModule } from '@haulynx/components';
import { CarrierManageRoutingModule } from './carrier-manage-routing.module';
import { CarrierManageComponent } from './carrier-manage.component';

@NgModule({
  declarations: [CarrierManageComponent],
  imports: [CommonModule, CarrierManageRoutingModule, CarrierManageContainerModule],
})
export class CarrierManageModule {}
