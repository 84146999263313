import { Component, ChangeDetectionStrategy, Input, OnChanges } from '@angular/core';
import { LaneHistoryLocations, LaneHistoryRow } from '@haulynx/types';
import { isEmpty } from 'lodash';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'haulynx-lane-history-timeline',
  templateUrl: './lane-history-timeline.component.html',
  styleUrls: ['./lane-history-timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LaneHistoryTimelineComponent implements OnChanges {
  @Input() lane: LaneHistoryRow;
  @Input() fontSize = '14px';

  displayLane$ = new BehaviorSubject<LaneHistoryLocations>(null);

  ngOnChanges(): void {
    if (!isEmpty(this.lane)) {
      if (!isEmpty(this.lane?.locations) && this.lane?.locations.length > 0) {
        const pickup = this.lane.locations[0];
        const dropoff = this.lane.locations[this.lane.locations.length - 1];

        this.displayLane$.next({
          locations: [
            {
              address: `${pickup.city} ${pickup.state}`,
              appointmentSet: pickup.appointmentSet,
              appointmentStart: pickup.appointmentStart,
              appointmentEnd: pickup.appointmentEnd,
              timezone: pickup.timezone,
            },
            {
              address: `${dropoff.city} ${dropoff.state}`,
              appointmentSet: dropoff.appointmentSet,
              appointmentStart: dropoff.appointmentStart,
              appointmentEnd: dropoff.appointmentEnd,
              timezone: dropoff.timezone,
            },
          ],
        });
      }
    }
  }
}
