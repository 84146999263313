import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActiveLoadAssignFieldComponent } from './active-load-assign-field.component';
import { MaterialModule } from '@haulynx/modules';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpinnerModule } from '../spinner/spinner.module';

@NgModule({
  declarations: [ActiveLoadAssignFieldComponent],
  imports: [CommonModule, ReactiveFormsModule, FormsModule, MaterialModule, SpinnerModule],
  exports: [ActiveLoadAssignFieldComponent],
})
export class ActiveLoadAssignFieldModule {}
