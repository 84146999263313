import { PaymentType, User } from '@haulynx/types';
import { CommonEntitiesModel } from './common-entities.utils';

export const entitiesOptions = {
  carriers: 'carriers',
  states: 'states',
  drivers: 'drivers',
  orderStatus: 'orderStatus',
  trucks: 'trucks',
  devices: 'devices',
  eldDevice: 'eldDevice',
  trailerTypes: 'trailerTypes',
  users: 'users',
  templates: 'templates',
  network: 'network',
  graphQlCarriers: 'graphQlCarriers',
  graphQlTrucks: 'graphQlTrucks',
  graphQlTrailers: 'graphQlTrailers',
  graphQlDrivers: 'graphQlDrivers',
  graphQlDevices: 'graphQlDevices',
  graphQlBrokersIds: 'graphQlBrokersIds',
  paymentTypes: 'paymentTypes',
};

export class EntitiesOptionsModel {
  public carriers: CommonEntitiesModel<any>;
  public states: CommonEntitiesModel<any>;
  public drivers: CommonEntitiesModel<any>;
  public orderStatus: CommonEntitiesModel<any>;
  public trucks: CommonEntitiesModel<any>;
  public devices: CommonEntitiesModel<any>;
  public eldDevice: CommonEntitiesModel<any>;
  public trailerTypes: CommonEntitiesModel<any>;
  public users: CommonEntitiesModel<User>;
  public templates: CommonEntitiesModel<any>;
  public network: CommonEntitiesModel<any>;
  public graphQlCarriers: CommonEntitiesModel<any>;
  public graphQlTrucks: CommonEntitiesModel<any>;
  public graphQlTrailers: CommonEntitiesModel<any>;
  public graphQlDrivers: CommonEntitiesModel<any>;
  public graphQlDevices: CommonEntitiesModel<any>;
  public graphQlBrokersIds: CommonEntitiesModel<any>;
  public paymentTypes: CommonEntitiesModel<PaymentType>;
}
