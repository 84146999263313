import { Injectable } from '@angular/core';
import { CarrierToolbarState, defaultCarrierToolbarState, LoadsServiceLoad } from '@haulynx/types';
import { differenceBy, findIndex, keyBy, merge } from 'lodash';
import { BehaviorSubject, combineLatest, from, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, pairwise, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { WindowRef } from '../app-services/generic/window-ref';
import { SessionStoreService } from '../shared-services/sessionStore.service';
import { CarrierToolbarSelectors, createToolbarSelectors } from './carrier-toolbar-selectors';

@Injectable({
  providedIn: 'root',
})
export class CarrierToolbarService {
  loads$ = new BehaviorSubject<LoadsServiceLoad[]>([]);
  selectedLoads$ = new BehaviorSubject<LoadsServiceLoad[]>([]);
  state$: Observable<CarrierToolbarState>;
  //if we want pass in any toolbar related interactions
  carrierToolbarInvoked$ = new BehaviorSubject<{ location: string; value?: any }>(null);
  private _state: CarrierToolbarState;
  private _selectedLoad$ = new BehaviorSubject<LoadsServiceLoad>(null);
  selectedLoad$ = combineLatest([this.selectedLoads$, this._selectedLoad$]).pipe(
    map(([loads, selectedLoad]) => {
      // If there's only 1 load selected, or if the "selected" load isn't found in the list,
      // choose the first element in the list of selected loads
      if (loads.length < 2 || !loads.some((load) => load.id === selectedLoad?.id)) {
        return loads[0];
      } else {
        return selectedLoad ?? loads[0];
      }
    }),
    distinctUntilChanged((a, b) => a?.id === b?.id)
  );

  selectors: CarrierToolbarSelectors;

  constructor(private sessionStoreService: SessionStoreService, private windowRef: WindowRef) {
    this.startListening();
    this.state$ = from(this.sessionStoreService.get(`carrier-toolbar`)).pipe(
      switchMap((state: CarrierToolbarState) => {
        if (!state) {
          // if there is no session and no state
          this.sessionStoreService.set(`carrier-toolbar`, defaultCarrierToolbarState);
        }
        return this.sessionStoreService.changes$.pipe(
          startWith({ key: `carrier-toolbar`, value: state ?? defaultCarrierToolbarState }),
          filter((changes: { key: string; value: CarrierToolbarState }) => changes?.key === `carrier-toolbar`),
          distinctUntilChanged(),
          map((changes) => changes.value),
          tap((state) => {
            this._state = state;
          })
        );
      }),
      shareReplay(1)
    );

    this.selectedLoads$
      .pipe(
        startWith([]),
        pairwise(),
        map(([oldLoads, newLoads]) => {
          let newLoad: LoadsServiceLoad = null;
          if (differenceBy(newLoads, oldLoads, 'id').length && newLoads.length > oldLoads.length) {
            newLoad = differenceBy(newLoads, oldLoads, 'id')[0] as unknown as LoadsServiceLoad;

            const loads = this.loads$.value;
            const index = findIndex(this.loads$.value, (load) => load?.id === newLoad.id);
            if (index !== -1) {
              loads.unshift(loads.splice(index, 1)[0]);
            } else {
              loads.unshift(newLoad);
            }
            this.loads$.next(loads);
          }
        }),
        shareReplay(1)
      )
      .subscribe();

    this.selectors = createToolbarSelectors(this.state$);
  }

  pushLoads(added: LoadsServiceLoad[] = [], removed: LoadsServiceLoad[] = []): void {
    const selection = keyBy(this.selectedLoads$.value, 'id');

    added.forEach((load) => {
      selection[load.id] = load;
    });

    removed.forEach((load) => {
      delete selection[load.id];
    });

    this.selectedLoads$.next(Object.values(selection));
  }

  // This will set the state of the tool bar being invoked
  // However after use if you wanted a "fresh" instance of the invoked location
  // you will need to reset the value by setting value to null
  setCarrierToolbarInvoked(location: string, value?: any): void {
    this.carrierToolbarInvoked$.next({ location, value });
  }

  setLoads(loads: LoadsServiceLoad[]): void {
    this.selectedLoads$.next(loads ?? []);
  }

  updateState(newState: Partial<CarrierToolbarState>): void {
    // merge (not overwrite) newState with state$
    // if needed, we could read fromt the local storeage service to retrieve oldState instead of making it a param here
    this.sessionStoreService.set(`carrier-toolbar`, merge(this._state, newState) as CarrierToolbarState);
  }

  selectLoad(loadId: string): void {
    this._selectedLoad$.next(this.loads$.value.find((load) => load.id === loadId) ?? null);
  }

  toggleToolbarStatus() {
    this.updateState({ isOpen: !this._state?.isOpen });
  }

  private startListening(): void {
    this.windowRef.getNativeWindow().addEventListener('beforeunload', this.onDestroy.bind(this));
  }

  private stopListening(): void {
    this.windowRef.getNativeWindow().removeEventListener('beforeunload', this.onDestroy.bind(this));
  }

  onDestroy() {
    this.stopListening();
  }
}
