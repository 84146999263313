export class VehicleStatus {
  public deviceName: string;
  public distance: number;
  public errorMessage: string;
  public isDeviceConnected: boolean;
  public isDriving: boolean;
  public isEngineOn: boolean;
  public odometerDistance: number;
  public protocol: string;
  public rpm: number;
  public source: string;
  public speed: number;
}
