import { FormGroup } from '@angular/forms';
import { ISearchFilter, TextFilterType } from '../search-filter';
import { ISearchFilterType } from '../search-filter.types';

export class OrderFilter implements ISearchFilter {
  name = 'Order';
  type: ISearchFilterType = ISearchFilterType.TEXT;
  keys: TextFilterType;
  description = 'Filter loads by order number';
  shouldPersistInputText = true;
  valueIsNumber = true;
  searchPriority = 50;

  constructor(defaultValues?: TextFilterType) {
    this.keys = new TextFilterType({
      value: '',
      optionsDataIndex: 'order',
      textFormName: 'orderNumber',
      ...defaultValues,
    });
  }

  modificationFunction(form: FormGroup): TextFilterType {
    return new TextFilterType({
      ...this.keys,
      value: form.get(this.keys.textFormName).value.trim(),
    });
  }
}
