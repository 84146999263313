import gql from 'graphql-tag';

export const CreateCarrierComplianceTicket = gql(`
mutation createCarrierComplianceTicket($carrierComplianceTicketInput: NewCarrierComplianceTicketInput){
  createCarrierComplianceTicket(carrierComplianceTicketInput: $carrierComplianceTicketInput){
    id
    broker_id
    broker_email
    reason
    carrier_id
    load_id
    status
    status_update
    freshdesk_id
    created_at
    updated_at
  }
}
`);
