import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Load, LoadsServiceLoad } from '@haulynx/types';
import { get, nth } from 'lodash';

@Component({
  selector: 'app-load-active-note',
  templateUrl: './load-active-note.component.html',
  styleUrls: ['./load-active-note.component.scss'],
})
export class LoadActiveNoteComponent implements OnInit {
  title = 'Special Notes';
  form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { load: LoadsServiceLoad; loadLocationIndex: number; canEditLoad: boolean },
    public dialogRef: MatDialogRef<LoadActiveNoteComponent>,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    const { load, loadLocationIndex } = this.data;
    const specialNotes = get(nth(load.locations, loadLocationIndex), 'notes.text');
    this.form = this.fb.group({
      specialNotes: [specialNotes],
    });
  }

  onSubmit(): void {
    this.dialogRef.close(this.form.value);
  }
}
