import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mathJS',
})
export class MathJSPipe implements PipeTransform {
  transform(value: number, method: string): number {
    return Math[method](value);
  }
}
