import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { LoadFormService, StepStatusService } from '@haulynx/services';
import { TextAreaHandlerModule } from '../text-area-handler/text-area-handler.module';
import { LoadTimelineStatusComponent } from './load-timeline-status.component';

@NgModule({
  imports: [CommonModule, TextAreaHandlerModule, MaterialModule, PipesModule],
  declarations: [LoadTimelineStatusComponent],
  exports: [LoadTimelineStatusComponent],
  providers: [LoadFormService, StepStatusService],
})
export class LoadTimelineStatusModule {}
