import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@haulynx/modules';
import { FavoriteAddressModel, FavoriteAddressStoreModule } from '@haulynx/store';
import { FavoriteAddressEffects } from 'libs/store/src/lib/components-store/favorite-address/favorite-address.effects';
import { DataTableModule } from '../data-table/data-table.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { AddressFavoriteFormComponent } from './components/address-favorite-form/address-favorite-form.component';
import { AddressFavoriteComponent } from './components/address-favorite/address-favorite.component';

@NgModule({
  imports: [
    CommonModule,
    DataTableModule,
    FormsModule,
    ReactiveFormsModule,
    SpinnerModule,
    FavoriteAddressStoreModule,
    MaterialModule,
  ],
  declarations: [AddressFavoriteComponent, AddressFavoriteFormComponent],
  providers: [FavoriteAddressModel, FavoriteAddressEffects],
  exports: [AddressFavoriteComponent, AddressFavoriteFormComponent],
})
export class FavoriteAddressModule {}
