<div class="load-table-search-form__container">
  <form [formGroup]="form" class="form">
    <div class="search_block">
      <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
        <mat-label> Region </mat-label>
        <app-drop-down
          [placeholder]="'ex. West Coast'"
          formControlName="region"
          [data]="regions"
          displayLabel="text"
          key="text"
        >
        </app-drop-down>
      </mat-form-field>
    </div>
    <div class="search_block">
      <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
        <mat-label> Broker </mat-label>
        <app-drop-down [placeholder]="'Select Broker'" formControlName="broker" [data]="brokers"> </app-drop-down>
      </mat-form-field>
    </div>
    <!-- <div class="search_block">
      <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
        <mat-label>
          Status
        </mat-label>
        <app-drop-down
          [placeholder]="'Select Status'"
          formControlName="status"
          [data]="loadStatuses"
          displayLabel="text"
          key="text"
        >
        </app-drop-down>
      </mat-form-field>
    </div> -->
    <div class="search_block">
      <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
        <mat-label> Carrier </mat-label>
        <app-drop-down
          [placeholder]="'ex. TIBCRA'"
          formControlName="carrier"
          [data]="carriers"
          [changeableData]="true"
          [isCarrier]="true"
          displayLabel="name"
          key="dot"
          (keydown.enter)="onEnter($event)"
          (keywordChanges)="onValueChanges($event)"
        >
        </app-drop-down>
      </mat-form-field>
    </div>
    <div class="search_block">
      <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
        <mat-label> Order ID </mat-label>
        <input matInput formControlName="orderId" placeholder="1234567" />
      </mat-form-field>
    </div>
    <!-- <div class="search_block">
      <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
        <mat-label>
          Customer
        </mat-label>
        <input matInput formControlName="customer" placeholder="ex. Home Depot" />
      </mat-form-field>
    </div> -->
    <div class="search_block">
      <mat-form-field appearance="fill" class="app-form-field" floatLabel="always">
        <mat-label> Pick-Up Date </mat-label>
        <app-datepicker placeholder="mm/dd/yyyy" [rangeMode]="false" formControlName="pickupDate"> </app-datepicker>
      </mat-form-field>
    </div>
    <div class="search_block">
      <button
        [disabled]="form.invalid"
        (click)="search()"
        type="submit"
        mat-button
        class="action-button search-button"
        data-test="search-button"
      >
        Search
      </button>
    </div>
  </form>
</div>
