import gql from 'graphql-tag';
/**
 * @deprecated
 */
export const GetDriverLogByDay = gql(`
  query getDriverLogByDay($epochDay: HaulynxDays!, $userId: String!) {
    getDriverLogByDay(epochDay: $epochDay, userId: $userId) {
    totalMilesDriven
    }
  }
`);
