<div *ngIf="showBanner" class="mobile-banner" fxFlex fxLayoutAlign="space-between center">
  <p class="mobile-banner__text">
    For the best experience, <a href="https://haulynx.page.link/download">download the Xpress Technologies app</a>
  </p>
  <haulynx-white-outline-button
    class="text-12 height-32"
    fxFlex
    text="Dismiss"
    (clicked)="dismiss()"
  ></haulynx-white-outline-button>
</div>
