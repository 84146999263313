import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TRUCK_STATUS } from '@haulynx/services';

@Component({
  selector: 'app-marker-popup',
  templateUrl: './marker-popup.component.html',
  styleUrls: ['./marker-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarkerPopupComponent implements OnChanges {
  @Input() selectedMarker: {
    geometry: GeoJSON.Point;
    properties: {
      lastUpdated: string;
      status: string;
      label: string;
      info: string;
      type: string;
    };
  } = null;

  public truckState: string;
  public truckText: string;

  ngOnChanges(changes: SimpleChanges): void {
    const { selectedMarker } = changes;

    if (selectedMarker) {
      const currentSelectedMarker = selectedMarker.currentValue;
      this.truckState = this.setTruckStatus(currentSelectedMarker);
      this.truckText = this.markerTruckText(currentSelectedMarker);
    }
  }

  private setTruckStatus(currentSelectedMarker) {
    const truckStatus = currentSelectedMarker.properties && +currentSelectedMarker.properties.status;
    return TRUCK_STATUS[truckStatus];
  }
  private markerTruckText(currentSelectedMarker) {
    const markerlabel = (currentSelectedMarker.properties && currentSelectedMarker.properties.label) || '{}';
    const { text } = JSON.parse(markerlabel);
    return text;
  }
}
