import gql from 'graphql-tag';

export const CreateMilestone = gql`
  mutation CreateMilestone($loadId: StrictId!, $milestoneInput: MilestoneInput!) {
    addMilestone(loadId: $loadId, milestoneInput: $milestoneInput) {
      id
      loadStatus
      milestones {
        authorId
        authorType
        comments {
          contact
          creatorId
          creatorType
          id
          text
          timestamp
          timezone
        }
        contact {
          email
          name
          phone
        }
        createdAt
        id
        lastUpdated
        loadLocationAddress
        location {
          latitude
          longitude
          timestamp
          timezone
        }
        locationId
        logs {
          editedBy
          editedByType
          id
          primaryEvent {
            latitude
            longitude
            timestamp
            timezone
          }
          secondaryEvent {
            latitude
            longitude
            timestamp
            timezone
          }
          subType
          timestamp
          status
        }
        type
      }
    }
  }
`;
