import { ISearchFilter, NumericalFilterType } from '../search-filter';
import { ISearchFilterType } from '../search-filter.types';

export class RevenueFilter implements ISearchFilter {
  name = 'Revenue';
  type: ISearchFilterType = ISearchFilterType.NUMBER_RANGE;
  keys: NumericalFilterType;
  validation?: NumericalFilterType;
  description = 'Filter loads by load revenue';
  formPrefix = '$';

  constructor(defaultValues: Partial<NumericalFilterType> = {}, validationValues: Partial<NumericalFilterType> = {}) {
    this.keys = new NumericalFilterType({
      min: 0,
      max: null,
      maxFormName: 'maxRevenue',
      minFormName: 'minRevenue',
      ...defaultValues,
    });
    this.validation = new NumericalFilterType(validationValues);
  }
}
