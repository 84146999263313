import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { takeUntil } from 'rxjs/operators';
import { trim } from 'lodash';
import { aliveWhile } from '@haulynx/utils';

const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/;

@Component({
  selector: 'app-chip-selector',
  templateUrl: './chip-selector.component.html',
  styleUrls: ['./chip-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChipSelectorComponent),
      multi: true,
    },
  ],
})
export class ChipSelectorComponent implements ControlValueAccessor, OnInit, OnDestroy, OnChanges {
  @Input() isLoading: boolean;
  @Input() removable = true;
  @Input() showEnterEmailAddresses = true;
  @Input() emails: string[];
  @Input() saveButtonText: string;
  @Input() labelText: string;
  @Output() dataChange = new EventEmitter<string[]>();
  @Output() saveDefaultRecipients = new EventEmitter<string[]>();

  form: FormGroup;
  value: string[];
  isDisabled: boolean;
  alive = aliveWhile();
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(private fb: FormBuilder, private cdr: ChangeDetectorRef) {
    this.initForm();
  }

  ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntil(this.alive)).subscribe(({ email }) => {
      this.value = email;
      this.onChange(email);
      this.dataChange.emit(email);
    });
  }

  get emailForm(): FormArray {
    return this.form.controls.email as FormArray;
  }

  onAdd(event: MatChipInputEvent): void {
    const { input, value } = event;

    this.populate(value);
    input.value = '';
  }

  onRemove(index: number): void {
    this.emailForm.removeAt(index);
    this.dataChange.emit(this.emailForm.value);
  }

  onSave(): void {
    if (!this.value) {
      this.value = this.emailForm.value;
      this.onChange(this.value);
    }

    this.saveDefaultRecipients.emit(this.value);
  }

  initForm(): void {
    this.form = this.fb.group({
      email: this.fb.array([], [this.validateEmails]),
    });
  }

  populate(item: string): void {
    const value = trim(item);

    if (value) {
      const controlValue = this.fb.control(value);
      this.emailForm.push(controlValue);
      this.dataChange.emit(this.emailForm.value);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { emails } = changes;

    if (emails) {
      this.initForm();
      emails?.currentValue?.map((value: string) => this.populate(value));
      this.dataChange.emit(emails?.currentValue);
    }
  }

  onChange = (_: unknown): void => {};

  onTouched = (): void => {};

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(value: string[]): void {
    this.form.setControl('email', this.fb.array(value ?? [], [this.validateEmails]));
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }

  private validateEmails(control: FormControl) {
    const emails = control.value.every((value: string) => value.match(EMAIL_REGEX));

    if (!emails) {
      return { email: { valid: false } };
    }

    return null;
  }
}
