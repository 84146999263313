<div class="carrier-dashboard-details">
  <haulynx-back-button
    label="Carrier Search"
    url="/dashboard/carriers/search"
    class="carrier-dashboard-details__back-button"
  ></haulynx-back-button>

  <app-carrier-details-short
    expandPanelLabel
    [carrier]="carrier"
    [insuranceAndSafety]="dashboardCarrierModel.carrierInsuranceAndSafety$ | async"
    [isLoadingInsuranceAndSafety]="dashboardCarrierModel.isLoadingCarrierInsuranceAndSafety$ | async"
    [carriersStatus]="dashboardCarrierModel.carriersStatus$ | async"
    [isLoadingCarriersStatus]="dashboardCarrierModel.isLoadingCarriersStatus$ | async"
  >
  </app-carrier-details-short>

  <br />

  <!-- Insurance and Metrics panel with dropdown -->
  <div class="carrier-dashboard-details">
    <expand-panel cls="carrier-widget-panel" [isExpanded]="false">
      <div expandPanelLabel>
        <span class="styleLeft">Insurance and Metrics</span>
      </div>
      <app-carrier-analytics
        expandPanelContent
        [insuranceAndSafety]="dashboardCarrierModel.carrierInsuranceAndSafety$ | async"
        [isLoadingInsuranceAndSafety]="dashboardCarrierModel.isLoadingCarrierInsuranceAndSafety$ | async"
        [carrierMetrics]="loadEntityService.getLoadsMetaDataManager.searchResults$ | async"
      >
      </app-carrier-analytics>
    </expand-panel>
  </div>

  <div class="details-block-lane">
    <mat-tab-group #tabs [selectedIndex]="selectedTabIndex" (selectedTabChange)="upperTabChange($event)">
      <mat-tab content label="Posted Trucks">
        <ng-template mat-tab-label
          >Posted Trucks
          <div
            matTooltip="Begin by posting a truck. To view load matches, click on a posted truck and then watch the recommended loads table update."
          >
            <img id="info-trucks" src="icons/other/haulynx-info-icon.svg" />
          </div>
        </ng-template>

        <mat-card class="block-truck mat-elevation-z0">
          <app-post-truck-form
            [isLoading]="dashboardCarrierModel.isLoadingPostTruck$ | async"
            [minDate]="truckMinDate"
            [timeAvailable]="truckTimeAvailable"
            [defaultRadius]="truckDefaultRadius"
            [defaultEquipment]="truckDefaultEquipment"
            [truckToPopulate]="truckToPopulate"
            [defaultPreferredLocation]="preferredLocation"
            [countryRestrictions]="countryRestrictions"
            (saveTruck)="saveTruck($event)"
          ></app-post-truck-form>

          <data-table
            [data]="dashboardCarrierModel.trucks$ | async"
            [loading]="dashboardCarrierModel.isLoadingTruckSearch$ | async"
            [displayedColumns]="truckColumns"
            [selection]="true"
            [multipleSelection]="false"
            [selected]="selectedTrucks$ | async"
            [stickyHeader]="true"
            [showPagination]="false"
            [pageOptions]="[1000]"
            [templateCellRef]="truckTemplateRef"
            [cls]="'posted-trucks'"
            [smallLoader]="true"
            (onSelect)="selectTruck($event)"
            data-test="posted-trucks"
          >
            <ng-template #truckTemplateRef let-row="row" let-column="column">
              <div *ngIf="column === 'location'">
                {{ row.location | address: 1 }}
              </div>

              <div *ngIf="column === 'preferredLocation'">
                {{ row.preferredLocation | address: 1 }}
              </div>

              <div *ngIf="column === 'availableTime'">
                {{ row.timeAvailable | mtz: 'M/D/YY':row.timeZone:true }}
              </div>

              <div *ngIf="column === 'brokerId'">
                <span *ngIf="row.brokerId">
                  {{ row.brokerId }} {{ row.timestampTruckPosted | dateTime: row.timeZone:'MM/dd/yy HH:mm zz' }}
                </span>
                <span *ngIf="!row.brokerId">Carrier</span>
              </div>

              <div *ngIf="column === 'options'" class="truck-options" (click)="$event.stopPropagation()">
                <app-spinner *ngIf="loadingMapTruckIds[row.id]; else iconLabel" active="true" diameter="23">
                </app-spinner>
                <ng-template #iconLabel>
                  <app-button-dropdown
                    [closedIcon]="'more_vert'"
                    [openIcon]="'more_vert'"
                    [rowId]="row.id"
                    [closeDropdown]="!closeDropdown[row.id]"
                    [showButtonLabel]="false"
                    [isMultiSelect]="false"
                    [dropdownList]="preferredLaneActionMenu"
                    [disabledDropdownIndices]="
                      row.brokerId !== user.usxId &&
                      user.broker &&
                      !user.isCompanyAdmin &&
                      row.brokerId !== (user.email | getBrokerIdFromEmail)
                        ? [true, false]
                        : []
                    "
                    [dropdownListOptions]="preferredLaneActionMenuIcons"
                    [dropdownPosition]="'right'"
                    [buttonBackgroundColor]="'transparent'"
                    (clickedDropdownItem)="clickEvent($event, row)"
                    (closeOtherDropdowns)="closeOtherDropdowns($event, row)"
                    data-test="more-button"
                  >
                  </app-button-dropdown>
                </ng-template>
              </div>
            </ng-template>
          </data-table>
        </mat-card>
      </mat-tab>

      <mat-tab content label="Preferred Lanes" *ngIf="featureFlags[preferredLanesFeatureFlag]">
        <ng-template mat-tab-label>Preferred Lanes</ng-template>

        <mat-card class="block-truck mat-elevation-z0">
          <haulynx-preferred-lanes-form
            [isLoading]="preferredLanesEntityService.createLaneManager.isLoading$ | async"
            [pickupDays]="defaultPickupDays"
            [defaultRadius]="truckDefaultRadius"
            [defaultEquipment]="truckDefaultEquipment"
            [defaultLane]="laneDefaultPayload$ | async"
            [laneToPopulate]="laneToPopulate"
            [countryRestrictions]="countryRestrictions"
            (saveLane)="saveLane($event)"
          ></haulynx-preferred-lanes-form>

          <data-table
            [data]="tableData$ | async"
            [loading]="tableDataLoading$ | async"
            [displayedColumns]="laneColumns"
            [selection]="true"
            [multipleSelection]="false"
            [selected]="selectedLanes$ | async"
            [stickyHeader]="true"
            [showPagination]="false"
            [pageOptions]="[1000]"
            [templateCellRef]="prefLanesTemplateRef"
            [smallLoader]="true"
            [cls]="'preferred-lanes'"
            (onSelect)="selectLane($event)"
            data-test="lanes-table"
          >
            <ng-template #prefLanesTemplateRef let-row="row" let-column="column">
              <div *ngIf="column === 'originDestination'">
                <haulynx-preferred-lanes-timeline [lane]="row"></haulynx-preferred-lanes-timeline>
              </div>

              <div *ngIf="column === 'reoccuring'">
                <span *ngIf="row.origin?.availability?.dayOfWeek.length">{{
                  row.origin?.availability?.dayOfWeek | daysOfWeekPipe
                }}</span>
                <span
                  *ngIf="!row.origin?.availability?.dayOfWeek.length && row.destination?.availability?.dayOfWeek.length"
                  >{{ row.destination?.availability?.dayOfWeek | daysOfWeekPipe }}</span
                >
              </div>

              <div *ngIf="column === 'equipmentType'">
                {{ row.equipmentType }}
              </div>

              <div *ngIf="column === 'price'">
                <span>
                  {{ (row.cost?.min > 0 ? row.cost?.min : 0) | currency: 'USD':'symbol':'1.2-2' }}
                </span>
              </div>

              <div *ngIf="column === 'postedBy'">
                <span *ngIf="row.type === laneTypes.CARRIER"> Carrier </span>
                <span *ngIf="row.type === laneTypes.BROKER">
                  <div>{{ row.brokerId }}</div>
                  <div>
                    {{ row.truckPostedCreatedAt | dateTime: user.prefs.timeZone:'MM/dd' }}&nbsp;@&nbsp;{{
                      row.truckPostedCreatedAt | dateTime: user.prefs.timeZone:'HH:mm z'
                    }}
                  </div>
                </span>
                <span *ngIf="row.type === carrier">Carrier</span>
              </div>

              <div *ngIf="column === 'options'" class="truck-options">
                <button
                  mat-icon-button
                  [disabled]="!(row.type === laneTypes.BROKER && (user.isCompanyAdmin || row.brokerId === user.usxId))"
                  data-test="delete-icon"
                >
                  <mat-icon (click)="removeLane($event, row)">
                    <app-spinner
                      *ngIf="(deleting$ | async) && row.id === deletedLaneId; else iconLabel"
                      active="true"
                      diameter="23"
                    >
                    </app-spinner>
                    <ng-template #iconLabel>delete_outline</ng-template>
                  </mat-icon>
                </button>
              </div>
            </ng-template>
          </data-table>
        </mat-card>
      </mat-tab>
    </mat-tab-group>

    <app-map-routes
      #mapbox
      [padding]="100"
      [showControls]="true"
      [scrollZoom]="false"
      [maxZoom]="10"
      [truckToHighlight]="truckToHighlight?.id"
      [dark]="true"
      borderRadius="right"
      [sources]="routes$ | async"
    >
    </app-map-routes>
  </div>

  <div class="details-block-loads">
    <carrier-advanced-search
      [carrierDot]="carrier?.dot"
      [trucks]="dashboardCarrierModel.trucksSearch.entities$ | async"
      [selectedTruck]="selectedTruckRecommends"
      [currentData]="tableData$ | async"
      [selectedLaneRecommends]="selectedLaneRecommends"
      [selectedRecommendTab]="selectedRecommendTab"
      [calendarSelection]="selectedCalendarItem$ | async"
      (email)="email($event)"
      (highlightRoute)="highlightRoute($event)"
    ></carrier-advanced-search>

    <ng-template #carrierDashboardV2Inactive>
      <mat-tab-group class="loads" (selectedTabChange)="crmTabChange($event)">
        <mat-tab label="Load Recommendations">
          <data-table
            [data]="loads$ | async"
            [loading]="dashboardCarrierModel.recommendationSearch.isLoading$ | async"
            [pagination]="dashboardCarrierModel.recommendationSearch.pagination$ | async"
            [displayedColumns]="recommendedColumns"
            [clickableRow]="true"
            [cls]="'carrier-loads'"
            [stickyHeader]="true"
            class="load-list"
            (onClick)="openLoadDetails($event)"
            (onHoverRow)="highlightRoute($event)"
            [templateCellRef]="templateCellRecommended"
            data-test="carrier-loads"
          >
            <ng-template #templateCellRecommended let-row="row" let-column="column">
              <div *ngIf="column === 'oDh'">
                {{ row.odh }}
              </div>

              <div *ngIf="column === 'originCity'">
                {{ row.loadPickupAddress | address: 3 }}
              </div>

              <div *ngIf="column === 'originState'">
                {{ row.loadPickupAddress | address: 4 }}
              </div>

              <div *ngIf="column === 'dDh'">
                {{ row.ddh }}
              </div>

              <div *ngIf="column === 'destinationCity'">
                {{ row.loadDropoffAddress | address: 3 }}
              </div>

              <div *ngIf="column === 'destinationState'">
                {{ row.loadDropoffAddress | address: 4 }}
              </div>

              <div *ngIf="column === 'pickupDate'">
                {{
                  row.loadsServiceLoad?.locations
                    | nth: 0
                    | loadServiceLocationTime: { timeFormat: 'MM/dd', showLabel: true }
                }}
              </div>

              <div *ngIf="column === 'appPickupDate'">
                <div>
                  {{
                    row.loadsServiceLoad?.locations
                      | nth: 0
                      | loadServiceLocationTime: { timeFormat: 'MM/dd/yy', showLabel: true }
                  }}
                </div>
                <span *ngIf="appointmentOnLoads(row.loadsServiceLoad?.locations | nth: 0); else noPickupApp">
                  <span class="load-time">{{
                    row.loadsServiceLoad?.locations
                      | nth: 0
                      | loadServiceLocationTime: { timeFormat: 'HH:mm', showLabel: true }
                  }}</span>
                  <mat-icon class="appointment">check</mat-icon>
                </span>
                <ng-template #noPickupApp>
                  <span class="load-time no-appointment">{{
                    row.loadsServiceLoad?.locations
                      | nth: 0
                      | loadServiceLocationTime: { timeFormat: 'HH:mm', showLabel: true }
                  }}</span>
                </ng-template>
              </div>

              <div *ngIf="column === 'dropoffDate'">
                {{
                  row.loadsServiceLoad?.locations
                    | nth: -1
                    | loadServiceLocationTime: { timeFormat: 'MM/dd', showLabel: true }
                }}
              </div>

              <div *ngIf="column === 'appDroppoffDate'">
                <div>
                  {{
                    row.loadsServiceLoad?.locations
                      | nth: -1
                      | loadServiceLocationTime: { timeFormat: 'MM/dd/YY', showLabel: true }
                  }}
                </div>
                <span
                  *ngIf="
                    row.locations && appointmentOnLoads(row.load?.locations[row.locations.length - 1]);
                    else noDropoffApp
                  "
                >
                  <span class="load-time">{{
                    row.loadsServiceLoad?.locations
                      | nth: -1
                      | loadServiceLocationTime: { timeFormat: 'HH:mm', showLabel: true }
                  }}</span>
                  <mat-icon class="appointment">check</mat-icon>
                </span>
                <ng-template #noDropoffApp>
                  <span class="load-time no-appointment">{{
                    row.loadsServiceLoad?.locations
                      | nth: -1
                      | loadServiceLocationTime: { timeFormat: 'HH:mm', showLabel: true }
                  }}</span>
                </ng-template>
              </div>

              <div *ngIf="column === 'price'" class="table-currency-col">
                <app-load-price
                  [price]="row.price"
                  [loadId]="row.loadsServiceLoad.id"
                  [editablePrice]="true"
                ></app-load-price>
              </div>

              <div *ngIf="column === 'customerName'">
                {{
                  row.loadsServiceLoad?.providerDetails?.billToName
                    ? row.loadsServiceLoad?.providerDetails?.billToName
                    : 'N/A'
                }}
              </div>

              <div *ngIf="column === 'tmwNumber'">
                {{ row.tmwNumber ? row.tmwNumber : 'N/A' }}
              </div>

              <div *ngIf="column === 'loadDistance'">
                {{ row.loadDistance ? row.loadDistance : 'N/A' }}
              </div>

              <div *ngIf="column === 'loadEquipmentType'">
                {{ row.loadEquipmentType }}
              </div>

              <div *ngIf="column === 'revenue'" class="table-currency-col">
                {{ row.revenue | showCurrency: 'USD':'symbol':'1.0-0' }}
              </div>

              <div *appFeatures="bidFeatureFlag">
                <div *ngIf="column === 'bidDetails'">
                  <app-load-bid-details [row]="row.loadsServiceLoad"></app-load-bid-details>
                </div>
              </div>

              <div *ngIf="column === 'copyLink'">
                <button
                  mat-button
                  ngxClipboard
                  [cbContent]="getCurrentURL(row)"
                  (cbOnSuccess)="copyLinkSuccess()"
                  class="copy-link"
                  (click)="$event.stopPropagation()"
                >
                  <mat-icon>content_copy</mat-icon>
                </button>
              </div>

              <div *ngIf="column === 'options'">
                <action-buttons [buttons]="buttons$ | async" (onClick)="onAction($event, row)"> </action-buttons>
              </div>

              <div *ngIf="column === 'emailIcon'">
                <button mat-button (click)="email(row); $event.stopPropagation()" class="email-icon">
                  <mat-icon>email</mat-icon>
                </button>
              </div>

              <div *ngIf="column === 'bookLoadButton'">
                <app-book-load-button [load]="row.load" [carrierDot]="dot" [buttonText]="'BOOK LOAD'">
                </app-book-load-button>
              </div>
            </ng-template>
          </data-table>
        </mat-tab>

        <mat-tab label="Search All Loads">
          <haulynx-load-feed-search
            class="load-feed-search"
            [redirectToDashboard]="true"
            [showSearchResultHeader]="false"
            [maxHeight]="'700px'"
            [actions]="buttons$ | async"
            [carrierDot]="dot"
          ></haulynx-load-feed-search>
        </mat-tab>

        <mat-tab label="Completed Loads">
          <data-table
            [data]="loadEntityService.getCarrierLoadsManager.searchResults$ | async"
            [displayedColumns]="loadSearchColumns$ | async"
            [loading]="loadEntityService.getCarrierLoadsManager.isSearching$ | async"
            [pageOptions]="userPageOptions"
            [cls]="'completed-loads clickable-row'"
            [stickyHeader]="true"
            [clickableRow]="true"
            (onClick)="openLoadDetailsModal($event, 'completed')"
            [templateCellRef]="templateCell"
            data-test="completed-loads-table"
          >
            <ng-template #templateCell let-row="row" let-column="column">
              <div *ngIf="column === 'pickupLocation'">
                {{ row.locations | nth: 0 | get: 'address':'N/A' | address: 3 }}
              </div>

              <div *ngIf="column === 'pickupState'">
                {{ row.locations | nth: 0 | get: 'address':'N/A' | address: 4 }}
              </div>

              <div *ngIf="column === 'deliveryLocation'">
                {{ row.locations | nth: -1 | get: 'address':'N/A' | address: 3 }}
              </div>

              <div *ngIf="column === 'deliveryState'">
                {{ row.locations | nth: -1 | get: 'address':'N/A' | address: 4 }}
              </div>

              <div *ngIf="column === 'pickupTimestamp'">
                {{ row.locations | nth: 0 | loadLocationDate: 'MM/dd/YY' }}
              </div>

              <div *ngIf="column === 'dropoffTimestamp'">
                {{ row.locations | nth: -1 | loadLocationDate: 'MM/dd/YY' }}
              </div>

              <div *ngIf="column === 'equipmentType'">
                {{ row | get: 'providerDetails.equipmentType':'N/A' }}
              </div>

              <div *ngIf="column === 'distance'">
                {{ row.paymentDetails?.distanceMiles }}
              </div>

              <div *ngIf="column === 'order'" data-test="column-order">
                {{ row.locations | nth: 0 | get: 'billOfLading':'' }}
              </div>

              <div *ngIf="column === 'tmwNumber'">
                {{ (row | loadAlternateId: loadIdentifierType.TMW_NUMBER) || 'N/A' }}
              </div>

              <div *ngIf="column === 'shipper'">
                <!-- shipper is the customer.name on the first location -->
                {{ row | get: 'locations[0].customer.name':'N/A' }}
              </div>

              <!-- <div *ngIf="column === 'potentialCarriers'">
              {{ row.potentialCarriers }}
            </div> -->

              <div *ngIf="column === 'carrierOwner'">
                {{ row.carrier?.owner }}
              </div>

              <div *ngIf="column === 'rpm'">
                {{ row.paymentDetails?.ratePerMile | lessThan: 5 | showCurrency: 'USD':'symbol':'1.2-2' }}
              </div>

              <div *ngIf="column === 'price'">
                <app-load-price
                  [price]="row.paymentDetails?.price"
                  [loadId]="row.id"
                  [editablePrice]="false"
                ></app-load-price>
              </div>

              <div *ngIf="column === 'revenue'">
                {{ row.paymentDetails?.revenue | showCurrency: 'USD':'symbol':'1.0-0' }}
              </div>

              <div *ngIf="column === 'weight'">
                {{ row.providerDetails?.weightLbs | safeNumber: '1.0-0' }}
              </div>
            </ng-template>
          </data-table>
        </mat-tab>

        <mat-tab label="Active" *appFeatures="activeLoadFeatureFlags">
          <app-active-loads-table
            [isLoading]="activeLoadsModel.entitiesLoading$ | async"
            [extendedColumns]="true"
            [data]="activeLoadsModel.entities$ | async"
            [selected]="null"
            [activeStepTypes]="activeLoadsModel.activeStepTypes$ | async"
            [pagination]="activeLoadsModel.entitiesPagination$ | async"
            (changePagination)="changePagination($event)"
            (select)="openLoadDetailsModal($event, 'active')"
            data-test="active-loads-table"
          ></app-active-loads-table>
        </mat-tab>
      </mat-tab-group>
    </ng-template>
  </div>
</div>
