import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  AdvancedSearchComponent,
  AssignLoadCarrierContainerComponent,
  DataTableV2Component,
} from '@haulynx/components';
import {
  AdvancedSearchService,
  AnalyticsService,
  FireDatabaseService,
  LocalStoreService,
  TitleService,
  WindowRef,
} from '@haulynx/services';
import {
  ActiveLoadsModel,
  AppModel,
  CarrierDashboardModel,
  LoadEntityService,
  NotificationModel,
  PreferredLanesEntityService,
  SearchViewEntityService,
  UserEntityService,
} from '@haulynx/store';
import {
  ANALYTICS_EVENT,
  AssignLoadCarrierData,
  BulkEditLoadsInput,
  CapacityObject,
  carrierCRMLoadBoardTabs,
  CRMTab,
  DataTableCalendar,
  defaultSidebarItems,
  FeatureFlag,
  FFState,
  IAsyncSearchResultsPaginationState,
  IColumns2,
  IPostTruck,
  ISearchFilter,
  ISortingConfig,
  LaneRecommendation,
  LoadActionEvent,
  LoadBulkAction,
  LoadBulkActionPayload,
  LoadLocationType,
  LoadSearchBarResult,
  LoadSearchSideBarItem,
  loadServiceSearchFilters,
  LoadServiceSearchParamData,
  LoadServiceSearchParameters,
  LoadsServiceLoad,
  LoadStatusType,
  NotificationEvent,
  NotificationEventType,
  PageAndSort,
  SearchView,
  User,
} from '@haulynx/types';
import { aliveWhile, calculateDistance } from '@haulynx/utils';
import { addDays } from 'date-fns';
import {
  camelCase,
  cloneDeep,
  flow,
  has,
  isNull,
  keys,
  omitBy,
  orderBy,
  reduce,
  toNumber,
  toString,
  uniqBy,
} from 'lodash';
import { BehaviorSubject, combineLatest, forkJoin, merge, Observable, of, Subject } from 'rxjs';
import { debounceTime, delay, filter, first, map, mapTo, pairwise, startWith, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'carrier-advanced-search',
  templateUrl: './carrier-advanced-search.component.html',
  styleUrls: ['./carrier-advanced-search.component.scss'],
})
export class CarrierAdvancedSearchComponent implements OnInit, OnDestroy {
  @Input() carrierDot: string;
  @Input() trucks: IPostTruck[];
  @Input() lane: IPostTruck[];
  @Input() currentData = [];
  @Input() set selectedTruck(truckVal: IPostTruck) {
    this.selectedTruck$.next(truckVal);
  }
  @Input() set selectedLaneRecommends(laneVal: CapacityObject) {
    this.selectedLane$.next(laneVal);
  }
  @Input() set selectedRecommendTab(value: 'Posted Truck' | 'Preferred Lanes') {
    this.recommendTab$.next(value ? value : 'Posted Truck');
    this.calendarDate$.next(null);
  }
  @Input() set calendarSelection(data: { value: number | null }) {
    this.setCalendarSelection$.next(data);
  }
  @Output() email = new EventEmitter<LoadsServiceLoad>();
  @Output() highlightRoute = new EventEmitter<unknown>();

  @ViewChild('stickyColumnHeader', { static: false }) stickyColumnHeader: TemplateRef<unknown>;
  @ViewChild(AdvancedSearchComponent) advancedSearch: AdvancedSearchComponent;
  @ViewChild('parent', { static: false }) parent: DataTableV2Component;
  @ViewChild('tabs') tabGroup: MatTabGroup;

  alive = aliveWhile();
  filters: ISearchFilter[];
  isSidebarOpen = true;
  sidebarItems$: BehaviorSubject<LoadSearchSideBarItem[]> = new BehaviorSubject<LoadSearchSideBarItem[]>([]);
  searchBarFilters$ = new BehaviorSubject<ISearchFilter[]>([]);
  searchQuery$: Observable<{
    queryHash: string;
    payload: Partial<LoadServiceSearchParameters>;
    pageAndSort: Partial<PageAndSort>;
  }>;
  columns: IColumns2[] = [];
  searchViewFilters$ = new BehaviorSubject<ISearchFilter[]>([]);
  selectedSidebarItem: LoadSearchSideBarItem;
  refreshSelectedSidebarItem = true;
  selectedSidebarItemParent: LoadSearchSideBarItem;
  shouldResetTextArrayForm: boolean;
  searchForm: FormGroup;
  columnsToShow;
  isSearchView: boolean;
  currentSearchData$: Observable<LoadServiceSearchParameters>;
  dataLoading$: Observable<boolean>;
  loadEntities$: Observable<boolean>;
  tableData$: Observable<LoadsServiceLoad[]>;
  calendarData$ = new BehaviorSubject<LoadsServiceLoad[]>(null);
  setCalendarSelection$ = new Subject<{ value: number | null }>();
  calendarLoads$: Observable<LoadsServiceLoad[]>;
  calendarDate$ = new BehaviorSubject<DataTableCalendar>(null);
  paginator$: Observable<IAsyncSearchResultsPaginationState>;
  searchQuery: Partial<LoadServiceSearchParameters>;
  searchPage: Partial<PageAndSort>;
  rowActionsDisplay: boolean[] = [];
  stickyColumnWidth = '195px';
  user: User;
  features;
  activeLoadFeatureFlags: FeatureFlag[] = [FeatureFlag.ACTIVE_LOAD_MANAGEMENT];
  CRMTabs$: Observable<CRMTab[]>;
  currentTabData$ = new BehaviorSubject<CRMTab>(carrierCRMLoadBoardTabs[0]);
  sidebarVal: LoadServiceSearchParameters;
  loadStatusType = LoadStatusType;
  activeStepTypes: Array<string> = [];
  recommendations = true;
  flags;
  selectedTab: CRMTab;
  selectedTrucks: IPostTruck[] = [];
  private searchbarVal: LoadServiceSearchParamData;
  recs$: Observable<boolean>;
  totalPages$ = new BehaviorSubject(0);
  loadLockState$: Observable<{ [loadId: string]: LoadActionEvent }>;
  recommendTab$ = new BehaviorSubject('Posted Trucks');
  showTable$: Observable<boolean>;
  showSpinner$: Observable<boolean>;
  currentTab$ = new BehaviorSubject(null);
  currentLaneRecommendations: LoadsServiceLoad[];
  currentTruckRecommendations: LoadsServiceLoad[];
  loads: LoadsServiceLoad[] = [];
  now = new Date();
  selectedTruck$ = new BehaviorSubject<IPostTruck>(null);
  selectedLane$ = new BehaviorSubject<CapacityObject>(null);
  private websocketQueue$ = new BehaviorSubject<string[]>(null);

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private advancedSearchService: AdvancedSearchService,
    public loadEntityService: LoadEntityService,
    private dialog: MatDialog,
    public appModel: AppModel,
    private activeLoadsModel: ActiveLoadsModel,
    private localStoreService: LocalStoreService,
    public searchViewEntityService: SearchViewEntityService,
    private titleService: TitleService,
    private windowRef: WindowRef,
    private userEntityService: UserEntityService,
    private analyticsService: AnalyticsService,
    public dashboardCarrierModel: CarrierDashboardModel,
    private fireDatabaseService: FireDatabaseService,
    public preferredLanesEntityService: PreferredLanesEntityService,
    public notificationModel: NotificationModel
  ) {
    this.activeLoadsModel.activeStepTypes$.pipe(takeUntil(this.alive)).subscribe((steps) => {
      this.activeStepTypes = steps;
    });
    this.filters = loadServiceSearchFilters({ brokers: this.activeLoadsModel.brokersEntities$ });
    this.searchQuery$ = this.loadEntityService.getUSXLoadsManager.searchQuery$;
    this.currentSearchData$ = this.loadEntityService.getUSXLoadsManager.searchQuery$.pipe(
      map((searchQuery) => searchQuery.payload)
    );

    this.preferredLanesEntityService.deleteLaneManager.onSuccess$
      .pipe(debounceTime(3000), takeUntil(this.alive))
      .subscribe((val) => {
        this.dispatch();
      });

    this.preferredLanesEntityService.createLaneManager.onSuccess$
      .pipe(debounceTime(3000), takeUntil(this.alive))
      .subscribe((val) => {
        this.dispatch();
      });

    this.dataLoading$ = combineLatest([
      this.loadEntityService.getUSXLoadsManager.isSearching$,
      this.loadEntityService.getCarrierLoadRecommends.isSearching$,
      this.preferredLanesEntityService.getCarrierLanesManager.isSearching$,
    ]).pipe(
      map(([loadSearch, truckRecommendations, laneRecommendations]) => {
        return loadSearch || truckRecommendations || laneRecommendations;
      })
    );
    this.titleService.setTitle(`Load Board - Haulynx`);
    this.CRMTabs$ = this.userEntityService.featureFlags$.pipe(
      map((flags) => {
        this.flags = flags;
        return carrierCRMLoadBoardTabs(flags, this.carrierDot, this.selectedTruck, this.recommendTab$.value);
      })
    );

    this.notificationModel.notifications$
      .pipe(
        filter((notifications: NotificationEvent[]) => {
          return !!notifications.find((notification: NotificationEvent) => {
            return (
              notification.eventType === NotificationEventType.LOAD_MATCH && notification.createdAt > this.now.valueOf()
            );
          });
        }),
        takeUntil(this.alive)
      )
      .subscribe(() => {
        this.dispatch();
      });

    this.websocketQueue$.pipe(debounceTime(1500), takeUntil(this.alive)).subscribe((data) => {
      this.loadEntityService.lockState.next(data);
    });

    this.showSpinner$ = merge(this.currentTab$.pipe(mapTo(true)), this.currentTab$.pipe(mapTo(false), delay(3000)));
    this.loadLockState$ = this.loadEntityService.lockState.loadLockStream$;
  }

  selectCalendarDate(date: DataTableCalendar, loads: LoadsServiceLoad[]): LoadsServiceLoad[] {
    const temporaryLoads = loads.filter((load: LoadsServiceLoad) => {
      const pickupLocation = load.locations.find((location) => location.locationType === LoadLocationType.PICKUP);
      const loadTime = pickupLocation.appointmentStart
        ? new Date(
            new Date(pickupLocation.appointmentStart).toLocaleString('en-US', {
              timeZone: pickupLocation.timezone,
            })
          )
        : new Date(load.created);
      if (
        loadTime.getDate() === date.dayNumber &&
        loadTime.getMonth() === date.month &&
        loadTime.getFullYear() === date.year
      ) {
        return true;
      }
      return false;
    });
    return temporaryLoads;
  }

  setTableData(truckRecommendations, laneRecommendations): LoadsServiceLoad[] {
    const loads: LoadsServiceLoad[] = [];
    laneRecommendations.forEach((rec) => {
      if (rec.recommendations?.result?.length) {
        rec.recommendations.result.forEach((recs: LaneRecommendation) => {
          loads.push(recs.loadsServiceLoad);
        });
      }
    });
    this.currentLaneRecommendations = loads;
    this.currentTruckRecommendations = truckRecommendations;
    return loads;
  }

  ngOnChanges(changes: SimpleChanges) {
    const { carrierDot, selectedTruck, trucks } = changes;
    if (carrierDot?.currentValue) {
      this.crmTabChange({ tab: { textLabel: 'Load Recommendations' } });
      this.dispatch();

      this.firebaseSub();
    }
    if (this.selectedTab?.id === 'recommend' && this.recommendTab$.value === 'Posted Trucks') {
      this.loadEntityService.getCarrierLoadRecommends.dispatch({
        query: {
          dot: this.carrierDot,
          radius: 75,
        },
        pageAndSort: {
          page: 1,
          limit: 500,
        },
      });
      this.selectedTab?.metaData.columns.map((col) => {
        if (col.label === 'Bids' || col.label === 'O-DH' || col.label === 'D-DH') {
          col.isVisible = selectedTruck?.currentValue ? true : false;
        }
      });
      this.onColumnsChange(this.selectedTab?.metaData.columns);

      this.paginator$ = combineLatest([
        this.loadEntityService.getUSXLoadsManager.searchPaginationResults$,
        this.loadEntityService.getCarrierLoadRecommends.searchPaginationResults$,
      ]).pipe(
        map(([searchResults, recommendations]) => {
          const paging = this.currentTab$.value === 'recommend' ? recommendations : searchResults;
          this.totalPages$.next(paging.totalPages);
          return paging;
        })
      );
    }
    if (trucks) {
      if (this.currentTab$.value === 'recommend') {
        if (
          (trucks?.previousValue?.size === 0 && trucks?.currentValue?.size === 1) ||
          (trucks?.previousValue?.size > 0 && trucks?.currentValue?.size === 0)
        ) {
          this.currentTab$.next('');
          this.currentTab$.next('recommend');
          this.loadEntityService.getUSXLoadsManager.dispatch({ query: {} });
        }
      }
    }
  }

  private firebaseSub(): void {
    this.fireDatabaseService
      .getItem(`carrierLaneRecommendationsUpdates/${this.carrierDot}`)
      .pipe(first(), takeUntil(this.alive))
      .forEach((val) => {
        if (!val) {
          this.fireDatabaseService.addItem(`carrierLaneRecommendationsUpdates/${this.carrierDot}`, {});
        }
      });

    this.fireDatabaseService
      .getItem(`carrierRecommendationsUpdates/${this.carrierDot}`)
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        this.loadEntityService.getCarrierLoadRecommends.dispatch({
          query: {
            dot: this.carrierDot,
            radius: 75,
          },
        });
      });

    setTimeout(() => {
      this.fireDatabaseService
        .getItem(`carrierLaneRecommendationsUpdates/${this.carrierDot}`)
        .pipe(takeUntil(this.alive))
        .subscribe(() => {
          this.dispatch();
        });
    }, 5000);
  }

  dispatch() {
    this.preferredLanesEntityService.getCarrierLanesManager.dispatch({
      query: this.carrierDot,
      pageAndSort: {},
    });
  }

  private filterTrucks(
    selectedTruck: IPostTruck,
    truckIds: { [loadId: string]: string[] },
    recommenedData: LoadsServiceLoad[]
  ) {
    const postedTruckFilters = [];
    if (selectedTruck) {
      // Todo: need to move in separate service this filter functions
      const filterByUniqByIdFilter = (loads: LoadsServiceLoad[]) => uniqBy(loads, 'id');
      const addressCleaner = (loads: LoadsServiceLoad[]) =>
        loads.map((load) => {
          return {
            ...load,
            loadPickupAddress: this.cleanLoadAddresses(load?.locations[0]?.address),
            loadDropoffAddress: this.cleanLoadAddresses(load?.locations[load.locations.length - 1]?.address),
          };
        });
      postedTruckFilters.push(filterByUniqByIdFilter, addressCleaner);

      if (selectedTruck?.id) {
        const filterByTruckIdFilter = (loads) =>
          loads.filter((load) => {
            return !!truckIds[load.id]?.find((truckId) => {
              return truckId === selectedTruck?.id;
            });
          });
        postedTruckFilters.push(filterByTruckIdFilter);
      }

      if (selectedTruck) {
        const distancesMapper = (loads: LoadsServiceLoad[]) =>
          loads.map((load: LoadsServiceLoad) => {
            return {
              ...load,
              odh: parseInt(
                this.getOdhDistance(selectedTruck, load)
                  .toString()
                  .replace(/[^0-9\.]/g, ''),
                10
              ),
              ddh: parseInt(
                this.getDdhDistance(selectedTruck, load)
                  .toString()
                  .replace(/[^0-9\.]/g, ''),
                10
              ),
            };
          });
        postedTruckFilters.push(distancesMapper);
      }

      if (!postedTruckFilters.length) {
        this.selectedTrucks = [];
      }

      return flow(postedTruckFilters)(recommenedData);
    }
    return recommenedData;
  }

  private cleanLoadAddresses(address: string): string {
    if ((address?.match(/,/g) || []).length === 3) {
      address = address.replace(',', '');
    }
    return address;
  }

  getOdhDistance(truck: IPostTruck, load): string | number {
    const { locationLon, locationLat } = truck;
    const odhOptions = {
      fromLon: Number(locationLon),
      fromLat: Number(locationLat),
      toLon: Number(load?.locations[0]?.geometry?.coordinates[0]),
      toLat: Number(load?.locations[0]?.geometry?.coordinates[1]),
      suffix: 'mi',
    };

    return calculateDistance(odhOptions);
  }

  getDdhDistance(truck: IPostTruck, load): string | number {
    const { preferredLocationLon, preferredLocationLat } = truck;
    const ddhOptions = {
      fromLon: Number(preferredLocationLon),
      fromLat: Number(preferredLocationLat),
      toLon: Number(load.locations[load.locations.length - 1].geometry.coordinates[0]),
      toLat: Number(load.locations[load.locations.length - 1].geometry.coordinates[1]),
      suffix: 'mi',
    };

    return calculateDistance(ddhOptions);
  }

  ngOnInit(): void {
    this.tableData$ = combineLatest([
      this.loadEntityService.getUSXLoadsManager.searchResults$,
      this.loadEntityService.getCarrierLoadRecommends.searchResults$,
      this.preferredLanesEntityService.getCarrierLanesManager.searchResults$,
      this.preferredLanesEntityService.getCarrierLanesManager.isSearching$,
      this.currentTab$,
      this.recommendTab$,
      this.selectedLane$.pipe(pairwise(), startWith([null, null])),
      this.selectedTruck$.pipe(pairwise(), startWith([null, null])),
      this.loadEntityService.recommendedLoadTruckIds$.pipe(),
      this.calendarDate$,
      this.preferredLanesEntityService.deleteLaneManager.onSuccess$.pipe(startWith(null)),
      this.preferredLanesEntityService.createLaneManager.onSuccess$.pipe(startWith(null)),
    ]).pipe(
      map(
        ([
          searchResults,
          truckRecommendations,
          laneRecommendations,
          searching,
          currentTab,
          recommendTab,
          [prevSelectedLane, currSelectedLane],
          [prevTruckId, currTruckId],
          truckIds,
          date,
          laneDeleted,
          createLane,
        ]: [
          LoadsServiceLoad[],
          LoadsServiceLoad[],
          CapacityObject[],
          boolean,
          string,
          string,
          [CapacityObject, CapacityObject],
          [IPostTruck, IPostTruck],
          { [loadId: string]: string[] },
          DataTableCalendar,
          unknown,
          unknown
        ]) => {
          const selectedLaneChanged = !!(prevSelectedLane?.id !== currSelectedLane?.id && currSelectedLane?.id);
          const selectedTrucksChanged = !!(prevTruckId?.id !== currTruckId?.id && currTruckId);
          // selected lane filter
          if (selectedLaneChanged || this.selectedLane$.value?.id) {
            const loads = [];
            const recommends = laneRecommendations.find((rec) => rec.id === this.selectedLane$.value?.id);
            recommends?.recommendations.result.forEach((recs: LaneRecommendation) => {
              loads.push(recs.loadsServiceLoad);
            });
            if (date) {
              return this.selectCalendarDate(date, loads);
            }
            this.calendarData$.next(uniqBy(loads, 'id').filter(loadStartsIn30Days));
            this.refreshColumns();
            return loads;
          }
          // selected truck filter
          if (selectedTrucksChanged || this.selectedTruck$.value) {
            const truckFilteredLoads: LoadsServiceLoad[] = this.filterTrucks(
              this.selectedTruck$.value,
              truckIds,
              truckRecommendations
            );
            if (date) {
              return this.selectCalendarDate(date, truckFilteredLoads);
            }
            this.calendarData$.next(uniqBy(truckFilteredLoads, 'id').filter(loadStartsIn30Days));
            this.refreshColumns();
            return truckFilteredLoads;
          }
          // calendar filter
          if (date) {
            let loads: LoadsServiceLoad[] = [];
            if (recommendTab === 'Preferred Lanes') {
              laneRecommendations.forEach((capacityObj) => {
                return capacityObj.recommendations.result.forEach((recs: LaneRecommendation) => {
                  loads.push(recs.loadsServiceLoad);
                });
              });
            } else {
              loads = truckRecommendations;
            }
            return this.selectCalendarDate(date, loads);
          }
          //update columns in recommends table
          if (currentTab === 'recommend') {
            const loads: LoadsServiceLoad[] = this.setTableData(truckRecommendations, laneRecommendations);
            if (recommendTab === 'Preferred Lanes') {
              this.refreshColumns();
              this.calendarData$.next(uniqBy(loads, 'id').filter(loadStartsIn30Days));
              return loads;
            } else {
              this.refreshColumns();
              this.calendarData$.next(uniqBy(truckRecommendations, 'id').filter(loadStartsIn30Days));
              return truckRecommendations;
            }
          }
          // all other load tabs
          else {
            this.calendarData$.next(uniqBy(searchResults, 'id').filter(loadStartsIn30Days));
            return searchResults;
          }
        }
      ),
      map((loads: LoadsServiceLoad[]) => {
        return uniqBy(loads, 'id').filter(loadStartsIn30Days);
      }),
      tap((searchResults) =>
        this.onDataTableViewChange(searchResults?.slice(0, Math.min(25, searchResults?.length || 0)))
      )
    );

    this.showTable$ = combineLatest([
      this.tableData$,
      this.currentTab$,
      this.loadEntityService.getCarrierLoadRecommends.isSearching$,
      this.loadEntityService.getUSXLoadsManager.isSearching$,
      this.calendarDate$,
    ]).pipe(
      map(([loads, currentTab, isLoadingRecommendations, isLoadingLoads, date]) => {
        switch (currentTab) {
          case 'recommend':
            if (isLoadingRecommendations && loads.length) {
              return true;
            } else {
              if (date) {
                return true;
              }
              return !!loads.length;
            }
          case 'available':
            return true;
          case 'active':
          case 'finalled':
            if (isLoadingLoads) {
              return true;
            } else {
              return !!loads.length;
            }
          default:
            return false;
        }
      })
    );

    this.router.navigate([], { relativeTo: this.route, queryParams: null, queryParamsHandling: 'merge' });

    this.userEntityService.featureFlags$.pipe(takeUntil(this.alive)).subscribe((feature: FFState) => {
      this.features = feature;
    });

    forkJoin([
      this.route.queryParams.pipe(first()),
      this.loadEntityService.getUSXLoadsManager.searchQuery$.pipe(first()),
    ])
      .pipe(takeUntil(this.alive))
      .subscribe(([routeParams, currSearchQuery]) => {
        const payload = this.chooseInitialSearchQuery(routeParams, currSearchQuery) || { query: {} };
        // initialize searchParms & sidebarVal
        this.loadEntityService.getUSXLoadsManager.dispatch({ ...payload, pageAndSort: { limit: 25 } });

        const { searchFilters, searchParams } = this.advancedSearchService.convertSearchPayloadToSearchBarData(
          payload.query,
          this.filters
        );

        this.searchBarFilters$.next(searchFilters);
        this.searchViewFilters$.next(searchFilters);
        this.searchbarVal = searchParams;
        // sidebar val will be the rest of the query that wasn't assigned to the searchbar
        this.sidebarVal = reduce(
          payload.query,
          (prev, value, key) => {
            if (!has(this.searchbarVal, key)) {
              prev[key] = value;
            }
            return prev;
          },
          {}
        );
      });

    this.loadEntityService.getUSXLoadsManager.searchQuery$.pipe(takeUntil(this.alive)).subscribe((searchQuery) => {
      const equipment = searchQuery?.payload?.equipment?.map(camelCase);
      this.router.navigate([], { relativeTo: this.route, queryParams: { ...searchQuery.payload, equipment } });
      this.searchQuery = searchQuery.payload;
      this.searchPage = searchQuery.pageAndSort;
    });

    this.appModel.user$.pipe(takeUntil(this.alive)).subscribe((value: User) => {
      if (!value) return;

      this.user = value;
      this.sidebarItems$.next(defaultSidebarItems(this.user));

      this.searchViewEntityService.getSearchViewByUserIdManager.dispatch({
        query: this.user.id,
      });
    });

    this.searchViewEntityService.deleteSearchViewManager.onSuccess$
      .pipe(takeUntil(this.alive))
      .subscribe((success: boolean) => {
        this.searchViewEntityService.getSearchViewByUserIdManager.dispatch({
          query: this.user.id,
        });
      });

    this.searchViewEntityService.getSearchViewByUserIdManager.searchResults$
      .pipe(takeUntil(this.alive))
      .subscribe((searchViews: SearchView[]) => {
        // todo filters invalid records, delete after db clean
        searchViews = searchViews?.filter((item) => item.parent);
        const currentSidebar = defaultSidebarItems(this.user);
        this.sidebarItems$.next(currentSidebar);
        searchViews?.map((item: SearchView) => {
          item = cloneDeep(item);
          let { data } = item;
          data = omitBy(data, isNull);

          const normalizedData = Object.keys(data).reduce((acc: Partial<LoadServiceSearchParameters>, key: string) => {
            if (
              key === 'firstAppointmentStart' ||
              key === 'firstAppointmentEnd' ||
              key === 'lastAppointmentStart' ||
              key === 'lastAppointmentEnd'
            ) {
              if (toString(data[key]).length !== 13) {
                acc = { ...acc, [key]: addDays(Date.now(), toNumber(data[key])).valueOf() };
              }
            }

            return acc;
          }, {});

          item.data = { ...data, viewId: item.id, ...normalizedData } as LoadServiceSearchParameters;
          const sidebarItemParent = currentSidebar.find((option) => option.id === item.parent);
          item.metaData.isCustom = true;
          if (sidebarItemParent?.children?.length) {
            sidebarItemParent.children = [...sidebarItemParent.children, item] as LoadSearchSideBarItem[];
          } else {
            const shiftedParent = cloneDeep(sidebarItemParent);
            if (shiftedParent) {
              shiftedParent.label = shiftedParent.label?.startsWith('All')
                ? shiftedParent.label
                : `All ${shiftedParent.label}`;

              sidebarItemParent.data = null;
              sidebarItemParent.children = [shiftedParent, item];
            }
          }
        });

        this.sidebarItems$.next(cloneDeep(currentSidebar));

        this.watchSearchQuery();
      });

    this.CRMTabs$.pipe(first()).forEach((tabs) => {
      this.currentTabData$.next(tabs[0]);
    });
  }

  ngOnDestroy(): void {
    this.alive.destroy();
    this.titleService.resetTitle();
  }

  onSubmitSearch(
    event: { newData: LoadSearchBarResult; previousData: LoadSearchBarResult },
    currSearchQuery: LoadServiceSearchParameters
  ): void {
    const newSearchData = this.advancedSearchService.mergeSearchData(
      currSearchQuery,
      this.advancedSearchService.convertSearchToLoadServiceSearchParameters(event.newData),
      this.advancedSearchService.convertSearchToLoadServiceSearchParameters(event.previousData)
    );
    this.loadEntityService.getUSXLoadsManager.dispatch({
      query: newSearchData,
    });
  }

  onRowHover(load: LoadsServiceLoad) {
    this.highlightRoute.emit({ hover: load ? true : false, id: load?.id });
  }

  onRowSelect(load: LoadsServiceLoad | LoadsServiceLoad[]): void {
    if (!Array.isArray(load)) {
      // Converts the route into a string that can be used
      // with the window.open() function
      const url = this.router.serializeUrl(this.router.createUrlTree([`/loads/${load.id}`]));
      this.localStoreService.set(load.id, this.router.url);
      window.open(url, '_blank');
    }
  }

  onBulkAction(payload: LoadBulkActionPayload): void {
    if (payload.action === LoadBulkAction.REPORT_ROLLED_LOADS) {
      this.loadEntityService.reportRolledLoadsManager.dispatch(
        payload.reportedLoadsInput.loadIds,
        payload.reportedLoadsInput
      );
    } else {
      const loadIds = payload.loadInputs.map((load) => load.loadId);
      this.loadEntityService.bulkUpdateLoadsManager.dispatch(loadIds, {
        input: <Partial<BulkEditLoadsInput>>payload.loadInputs,
        loadIds,
      });
    }
  }

  onPage(event: { limit: number; pageUp: boolean }): void {
    if (event.pageUp) {
      if (this.currentTab$.value === 'recommend') {
        this.loadEntityService.getCarrierLoadRecommends.goToPage(null, event.limit);
      } else {
        this.loadEntityService.getUSXLoadsManager.goToPage(null, event.limit);
      }
    } else {
      if (this.currentTab$.value === 'recommend') {
        this.loadEntityService.getCarrierLoadRecommends.goToPage(-1, event.limit);
      } else {
        this.loadEntityService.getUSXLoadsManager.goToPage(-1, event.limit);
      }
    }
  }

  onSort(
    event: { config: ISortingConfig; sortType: 'ASC' | 'DESC' },
    searchQuery: { payload: Partial<LoadServiceSearchParameters>; pageAndSort: Partial<PageAndSort> }
  ): void {
    const search: Partial<PageAndSort> = {
      ...searchQuery.pageAndSort,
      sort: event.sortType === 'ASC' ? 'asc' : 'desc',
    };
    const indexes = event.config.dataIndex.split('-');
    if (indexes.length !== 2) {
      console.error(
        'column config dataIndex incorrect. Please format dataIndex as follows: "<DESC order type>-<ASC order type>".'
      );
    } else {
      search.order = event.sortType === 'ASC' ? indexes[0] : indexes[1];
      if (this.currentTab$.value === 'recommend') {
        this.loadEntityService.getCarrierLoadRecommends.dispatch({
          query: {
            dot: this.carrierDot,
            radius: 75,
          },
          pageAndSort: search,
        });
      } else {
        this.loadEntityService.getUSXLoadsManager.dispatch({ query: searchQuery.payload, pageAndSort: search });
      }
    }
  }

  openBookLoadDialog(load: LoadsServiceLoad): void {
    if (this.features[FeatureFlag.LOAD_OVERVIEW]) {
      const queryParams = { dot: this.carrierDot, carrierDashboard: this.carrierDot || null };
      const url = this.router.serializeUrl(
        this.router.createUrlTree([`/loads/${load.id}/overview/booking`], { queryParams })
      );
      this.windowRef.getNativeWindow().open(url, '_blank');
    } else {
      this.dialog.open(AssignLoadCarrierContainerComponent, {
        data: {
          carrierDot: null,
          loadId: load.id,
          brokerId: load.broker?.id,
          formData: {
            price: load.paymentDetails?.price,
          },
        } as AssignLoadCarrierData,
      });
    }
  }

  onDataTableViewChange(loads: LoadsServiceLoad[]): void {
    const loadIds: string[] = loads?.map((load) => load.id);
    this.websocketQueue$.next(loadIds);
  }

  private watchSearchQuery(): void {
    this.searchQuery$.pipe(takeUntil(this.alive)).subscribe((searchQuery) => {
      const equipment = searchQuery?.payload?.equipment?.map(camelCase);
      this.router.navigate([], { relativeTo: this.route, queryParams: { ...searchQuery.payload, equipment } });
      // send search filters to search bar
      const { searchFilters } = this.advancedSearchService.convertSearchPayloadToSearchBarData(
        searchQuery.payload,
        this.filters
      );
      this.searchBarFilters$.next(searchFilters);
    });
  }

  onSelectedFilterChange(filters: ISearchFilter[]): void {
    this.searchViewFilters$.next([...filters]);
  }

  onColumnsChange(columns: IColumns2[]): void {
    setTimeout(() => {
      const frozenColumns = this.columns.filter((column: IColumns2) => column?.isFrozen);
      const unfrozenColumns = columns.filter((column: IColumns2) => {
        return !frozenColumns.some((frozen: IColumns2) => frozen.field === column.field);
      });
      this.columns = orderBy([...frozenColumns, ...unfrozenColumns], 'order', 'asc');
    });
  }

  crmTabChange(event): void {
    this.CRMTabs$.forEach((tabs) => {
      this.selectedTab = tabs.find((tab: CRMTab) => tab.label === event.tab.textLabel);
      this.searchLoads(this.selectedTab);
      this.columns = this.selectedTab.metaData.columns;
      this.currentTabData$.next(this.selectedTab);
    });

    this.analyticsService.logEvent(ANALYTICS_EVENT.CRM_TAB_SELECT, { selectedTab: event.tab.textLabel });
  }

  private searchLoads(tab: CRMTab) {
    this.currentTab$.next(this.selectedTab.id);
    if (tab.id === 'recommend') {
      this.loadEntityService.getCarrierLoadRecommends.dispatch({
        query: {
          dot: this.carrierDot,
          radius: 75,
        },
      });
    } else {
      const extraPageandSortParams: Partial<PageAndSort> =
        tab.id === 'finalled' ? { sort: 'desc', order: 'firstAppointmentStart' } : {};
      this.loadEntityService.getUSXLoadsManager.dispatch({
        query: tab.data,
        pageAndSort: { limit: tab?.metaData?.configOptions?.pageAmount || 25, ...extraPageandSortParams },
      });
    }
    this.route.queryParams = of(null);
    this.router.navigate([], { relativeTo: this.route, queryParams: null, queryParamsHandling: 'merge' });
  }

  sendEmail(load: LoadsServiceLoad) {
    this.email.emit(load);
  }

  calendarDateClicked(date: DataTableCalendar): void {
    this.calendarDate$.next(date);
  }

  private refreshColumns() {
    const newRecTab = carrierCRMLoadBoardTabs(
      this.flags,
      this.carrierDot,
      this.selectedTruck$.value,
      this.recommendTab$.value
    );
    this.columns = newRecTab[0].metaData.columns;
    this.currentTabData$.next(newRecTab[0]);
  }

  /**
   * We might do an initial search depending on the the currently URL query params,
   * or if there is an existing query in the state.
   */
  private chooseInitialSearchQuery(
    routeParams: Params,
    searchQuery: { queryHash: string; payload: Partial<LoadServiceSearchParameters>; pageAndSort: Partial<PageAndSort> }
  ): {
    query: Partial<LoadServiceSearchParameters>;
    pageAndSort?: Partial<PageAndSort>;
  } {
    const hasRouteParams = !!keys(routeParams).length;
    const hasCurrentQuery = !!searchQuery?.queryHash;
    if (hasRouteParams) {
      return { query: this.advancedSearchService.convertQueryParamsToSearchPayload(routeParams, this.filters) };
    } else if (hasCurrentQuery) {
      return { query: searchQuery.payload, pageAndSort: searchQuery.pageAndSort };
    }
    return null;
  }
}

const loadStartsIn30Days = (load: LoadsServiceLoad): boolean => {
  return load.locations[0].appointmentStart <= addDays(new Date(), 30).valueOf();
};
