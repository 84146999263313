import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { MenuName } from '@haulynx/store';
import { FFState, HeaderItem, RmisProps, User } from '@haulynx/types';
import { Observable } from 'rxjs';

@Component({
  selector: 'haulynx-sidebar-nav',
  templateUrl: './sidebar-nav.component.html',
  styleUrls: ['./sidebar-nav.component.scss'],
})
export class SidebarNavComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('sidenavsubAccount') private accountSubController: MatSidenav;
  @ViewChild('sidenavsubAccountSetup') private accountRmisSubController: MatSidenav;
  @ViewChild('sidenavsubLoads') private loadsSubController: MatSidenav;

  @Input() headerItems: HeaderItem[] = [];
  @Input() user: User;
  @Input() currentSideItem: HeaderItem;
  @Input() sidenav: MatSidenav;
  @Input() totalNotifications: Observable<number>;
  @Input() rmisProps: RmisProps;
  @Input() featureFlags: FFState;

  @Output() startTour = new EventEmitter();
  @Output() sideItemSelected = new EventEmitter<HeaderItem>();
  @Output() goToHelp = new EventEmitter();
  @Output() showNotifications = new EventEmitter();
  @Output() openLogout = new EventEmitter();
  @Output() complete = new EventEmitter<void>();
  @Output() showVerifyEmail = new EventEmitter();
  @Output() goToRmis = new EventEmitter();

  currentRmisStep: { step: number; label: string };
  headerNames = MenuName;

  constructor(private cd: ChangeDetectorRef, private router: Router) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    const { currentSideItem } = changes;
    this.setCurrentSubNav(currentSideItem?.currentValue);
  }

  ngAfterViewInit(): void {
    this.setCurrentSubNav(this.currentSideItem);
    this.currentRmisStep = this.rmisProps?.currentAccountStep;
    this.cd.detectChanges();
  }

  whatsNewTour(): void {
    this.startTour.emit();
  }

  selectedItem(selectedItem: HeaderItem): void {
    this.sideItemSelected.emit(selectedItem);
  }

  openHelp(): void {
    this.goToHelp.emit();
  }

  openNotification(): void {
    this.sidenav.close();
    this.showNotifications.emit();
  }

  logout(): void {
    this.openLogout.emit();
  }

  subNavToggle(subNav: MatSidenav): void {
    if (subNav) subNav?.toggle();
  }

  clickCompleted() {
    this.complete.emit();
  }

  clickContinueRmis(): void {
    this.goToRmis.emit();
  }

  onShowVerifyEmail(): void {
    this.sidenav.close();
    this.showVerifyEmail.emit();
  }

  private setCurrentSubNav(item: HeaderItem): void {
    [this?.accountRmisSubController, this?.loadsSubController, this.accountSubController].forEach((subnav) => {
      if (subnav && item) {
        if (
          (item?.name === MenuName.LOADS ||
            item?.name === MenuName.ACTIVE_LOADS ||
            item?.name === MenuName.AVAILABLE_LOADS ||
            item?.name === MenuName.COMPLETE_LOADS) &&
          subnav === this.loadsSubController
        ) {
          subnav?.open();
        } else if (
          (item?.name === MenuName.USER_ACCOUNT || item?.name === MenuName.USER_SETTINGS) &&
          subnav === this.accountSubController
        ) {
          subnav?.open();
        } else {
          subnav?.close();
        }
      }
    });
  }
}
