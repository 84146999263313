import { Pipe, PipeTransform } from '@angular/core';
import { toLower, trim, upperFirst } from 'lodash';

@Pipe({ name: 'carrierOwner' })
export class CarrierOwnerPipe implements PipeTransform {
  transform(carrierOwner: string, defaultValue = 'None'): string {
    carrierOwner = toLower(trim(carrierOwner));

    if (!carrierOwner || carrierOwner === 'unknown') {
      return defaultValue;
    }

    const anyPlacePattern = /(mc|mac)([a-z])/g;
    const anyPlaceMatch = anyPlacePattern.exec(carrierOwner);

    if (anyPlaceMatch) {
      return upperFirst(
        carrierOwner.replace(anyPlacePattern, upperFirst(anyPlaceMatch[1]) + upperFirst(anyPlaceMatch[2]))
      );
    }

    const startOnlyPattern = /^(rc|ts|nc)([a-z])/;
    const startOnlyMatch = startOnlyPattern.exec(carrierOwner);

    if (startOnlyMatch) {
      return upperFirst(
        carrierOwner.replace(startOnlyPattern, upperFirst(startOnlyMatch[1]) + upperFirst(startOnlyMatch[2]))
      );
    }

    const prefix = carrierOwner.slice(0, 1);
    const rest = carrierOwner.slice(1);

    return upperFirst(prefix) + upperFirst(rest);
  }
}
