import { KeyValuePair } from '../generic/key-value-pair';

export interface KeyHintValidation {
  key: string;
  hint?: string;
  validation?: RegExp;
}

export enum CarrierOptionType {
  INTERSTATE = 'Interstate (Default)',
  INTRASTATE = 'Intrastate',
  INTRACANADA = 'Intra-Canada',
}

export enum CarrierOptionTypeValue {
  INTERSTATE = 'interstate',
  INTRASTATE = 'intrastate',
  INTRACANADA = 'intracanada',
}

export const CarrierOptionTypeValueToKey = {
  [CarrierOptionTypeValue.INTERSTATE]: 'Interstate',
  [CarrierOptionTypeValue.INTRASTATE]: 'Intrastate',
  [CarrierOptionTypeValue.INTRACANADA]: 'Intra-Canada',
};

export enum CarrierSubType {
  MC = 'MC Number',
  DOT = 'DOT Number',
  PERMIT_NUMBER = 'Permit Number',
}

export enum CarrierSubTypeValue {
  MC = 'mc',
  DOT = 'dot',
  PERMIT_NUMBER = 'permit',
}

export const CarrierTypeOptions: KeyValuePair[] = [
  { key: CarrierOptionType.INTERSTATE, value: CarrierOptionTypeValue.INTERSTATE },
  { key: CarrierOptionType.INTRASTATE, value: CarrierOptionTypeValue.INTRASTATE },
  { key: CarrierOptionType.INTRACANADA, value: CarrierOptionTypeValue.INTRACANADA },
];

export const InterstateSubTypeOptions: KeyValuePair[] = [
  { key: CarrierSubType.MC, value: CarrierSubTypeValue.MC },
  { key: CarrierSubType.DOT, value: CarrierSubTypeValue.DOT },
];

export const IntrastateSubTypeOptions: KeyValuePair[] = [
  { key: CarrierSubType.DOT, value: CarrierSubTypeValue.DOT },
  { key: CarrierSubType.PERMIT_NUMBER, value: CarrierSubTypeValue.PERMIT_NUMBER },
];

export const IntraCanadaSubTypeOptions: KeyValuePair[] = [
  { key: CarrierSubType.DOT, value: CarrierSubTypeValue.DOT },
  { key: CarrierSubType.PERMIT_NUMBER, value: CarrierSubTypeValue.PERMIT_NUMBER },
];

export const IntrastatePermitStateOptions: KeyHintValidation[] = [
  { key: 'AL', hint: 'F# or F## or F### or F#### or F##### (Ex: F12345)', validation: /[fF]\d{1,5}$/ },
  { key: 'AZ' },
  { key: 'AR' },
  { key: 'CA', hint: '0####### (Ex: 01234567)', validation: /0\d{7}$/ },
  { key: 'CO' },
  { key: 'CT' },
  { key: 'DE' },
  { key: 'FL' },
  { key: 'GA', hint: 'A###### (Ex: A123456)', validation: /[aA]\d{6}$/ },
  { key: 'ID' },
  { key: 'IL', hint: '###### (Ex: 123456)', validation: /\d{6}$/ },
  { key: 'IN' },
  { key: 'IA' },
  { key: 'KS' },
  { key: 'KY' },
  { key: 'LA' },
  { key: 'ME' },
  { key: 'MD' },
  { key: 'MA' },
  { key: 'MI', hint: '###### (Ex: 123456)', validation: /\d{6}$/ },
  { key: 'MN' },
  { key: 'MS' },
  { key: 'MO' },
  { key: 'MT' },
  { key: 'NE' },
  { key: 'NV' },
  { key: 'NH' },
  { key: 'NJ' },
  { key: 'NM' },
  { key: 'NY' },
  { key: 'NC' },
  { key: 'ND' },
  { key: 'OH', hint: '######## (Ex: 12345678)', validation: /\d{8}$/ },
  { key: 'OK' },
  { key: 'OR' },
  { key: 'PA' },
  { key: 'RI' },
  { key: 'SC' },
  { key: 'SD' },
  { key: 'TN' },
  { key: 'TX', hint: '00#######C (Ex: 001234567C)', validation: /00\d{7}[cC]$/ },
  { key: 'UT' },
  { key: 'VT' },
  { key: 'VA', hint: '##### (Ex: 12345)', validation: /\d{5}$/ },
  { key: 'WA' },
  { key: 'WV' },
  { key: 'WI', hint: 'LC##### (Ex: LC12345)', validation: /(LC|lc|Lc|lC)\d{5}$/ },
  { key: 'WY' },
];

export const IntraCanadaPermitStateOptions: KeyHintValidation[] = [
  { key: 'AB', hint: 'AB###-### (Ex: AB123-456)', validation: /(AB|ab|Ab|aB)\d{3}-\d{3}$/ },
  { key: 'BC', hint: '###-###-### (Ex: 123-456-789)', validation: /\d{3}-\d{3}-\d{3}$/ },
  { key: 'MB', hint: 'MB###### or MB####### (Ex: MB123456)', validation: /(MB|mb|Mb|mB)\d{6,7}$/ },
  { key: 'NB', hint: 'NB####### (Ex: NB1234567)', validation: /(NB|nb|Nb|nB)\d{7}$/ },
  { key: 'NF' },
  { key: 'NS', hint: 'ZZZZZ######### (Ex: ABCDE123456789)', validation: /[a-zA-Z]{5}\d{9}$/ },
  { key: 'NT' },
  { key: 'ON', hint: '###-###-### (Ex: 123-456-789)', validation: /\d{3}-\d{3}-\d{3}$/ },
  { key: 'PE' },
  { key: 'QC', hint: 'R-######-# or N-######-# (Ex: R-123456-7)', validation: /[rnRN]-\d{6}-\d{1}$/ },
  { key: 'SK', hint: 'SK####### (Ex: SK1234567)', validation: /(SK|sk|Sk|sK)\d{7}$/ },
  { key: 'YT' },
];
