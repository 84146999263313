import { InjectionToken, Provider } from '@angular/core';
import { IAsyncEntityState, Location } from '@haulynx/types';
import { USER_PROVIDED_EFFECTS } from '@ngrx/effects';
import { ActionReducer, ActionReducerMap } from '@ngrx/store';
import { LoadLocationEntityService } from './load-location-entity.service';

export const LOAD_LOCATION_ENTITY_REDUCERS_TOKEN = new InjectionToken<ActionReducerMap<IAsyncEntityState<Location>>>(
  'load location entity reducers'
);

function getLoadEntityReducersFactory(
  loadLocationEntityService: LoadLocationEntityService
): ActionReducer<IAsyncEntityState<Location>> {
  return loadLocationEntityService.getReducers();
}

const loadLocationEntityReducerProvider: Provider = {
  provide: LOAD_LOCATION_ENTITY_REDUCERS_TOKEN,
  useFactory: getLoadEntityReducersFactory,
  deps: [LoadLocationEntityService],
};

const loadLocationEntityEffectProvider: Provider = {
  provide: USER_PROVIDED_EFFECTS,
  multi: true,
  useValue: [LoadLocationEntityService],
};

export const loadLocationStateProviders: Provider[] = [
  loadLocationEntityReducerProvider,
  loadLocationEntityEffectProvider,
];
