import { CarrierSourceType } from './carrier-source-type';

export interface CarrierDetail {
  id: string;
  dot: string;
  phone: string;
  name: string;
  owner: string;
  mcNumber: string;
  contactName: string;
  email: string;
  isEldCarrier: boolean;
  isHaulynxCarrier: boolean;
  isRmisApprovedCarrier: boolean;
  sourceType: CarrierSourceType;
}
