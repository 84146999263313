import { LoadEntityQueryParam } from '@haulynx/types';
import { createAction, createActionTypes } from '../../shared/store/helpers/action';
import { TYPE } from './adapters';

export interface ILoadEntitiesActions {
  search(params: Partial<LoadEntityQueryParam>);
  searchSuccess(params: { entities: any[]; total: number });
  searchError(params: { error: { [key: string]: any } });
  setStateKey(params: { key: string });
}

export interface ILoadEntitiesActionTypes {
  SEARCH: string;
  SEARCH_SUCCESS: string;
  SEARCH_ERROR: string;
  SET_STATE_KEY: string;
}

const loadEntitiesActionTypes: ILoadEntitiesActionTypes = {
  SEARCH: 'search',
  SEARCH_SUCCESS: 'search success',
  SEARCH_ERROR: 'search error',
  SET_STATE_KEY: ' set state key',
};

const loadEntitiesActions: ILoadEntitiesActions = {
  search: createAction(TYPE, loadEntitiesActionTypes.SEARCH),
  searchSuccess: createAction(TYPE, loadEntitiesActionTypes.SEARCH_SUCCESS),
  searchError: createAction(TYPE, loadEntitiesActionTypes.SEARCH_ERROR),
  setStateKey: createAction(TYPE, loadEntitiesActionTypes.SET_STATE_KEY),
};

export const LoadEntitiesActions: ILoadEntitiesActions = loadEntitiesActions;
export const LoadEntitiesActionTypes: ILoadEntitiesActionTypes = createActionTypes(loadEntitiesActionTypes)(TYPE);
