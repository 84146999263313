<form class="bids" [formGroup]="bidForm">
  <div [ngClass]="{ 'counter-offer': isActive }">
    <mat-expansion-panel [expanded]="isActive" (opened)="onGetHistory(bid)">
      <mat-expansion-panel-header [collapsedHeight]="'124px'" [expandedHeight]="'124px'">
        <mat-panel-description class="bids__arrow--icon">
          <img src="icons/other/haulynx-bid-arrow-expanded.svg" class="bids__arrow" />
        </mat-panel-description>

        <mat-panel-description>
          <div class="width-100">
            <div class="panel-header-top-row">
              <div class="bids__title">
                {{ bid?.carrier?.name }} <br />
                {{ bid?.createdAt | mtz: 'MM/DD/YY HH:mm':userTimeZone:false:true }}
              </div>

              <div class="actions">
                <div class="bids__price">
                  <mat-form-field
                    class="app-form-field"
                    appearance="fill"
                    floatLabel="always"
                    (click)="$event.stopImmediatePropagation()"
                  >
                    <input
                      data-test="price-input"
                      matInput
                      currencyMask
                      [options]="{ thousands: ',', decimal: '.', precision: 2 }"
                      hideRequiredMarker
                      placeholder="$0.00"
                      formControlName="price"
                      minlength="1"
                      autocomplete="off"
                      autofocus
                      required
                      (keydown.enter)="onEnter($event)"
                      (blur)="onDataChange(bidForm.getRawValue(), 'price')"
                    />
                  </mat-form-field>
                </div>

                <div class="bids__status">
                  <mat-form-field
                    class="app-form-field status-select-height-override"
                    appearance="fill"
                    floatLabel="always"
                    (click)="$event.stopImmediatePropagation()"
                    (keydown)="$event.stopImmediatePropagation()"
                  >
                    <app-drop-down
                      #dropDown
                      [data]="bidStatusOptions$ | async"
                      formControlName="status"
                      required
                      (selected)="onDataChange(bidForm.getRawValue(), 'status')"
                      (keydown.enter)="$event.stopImmediatePropagation()"
                    >
                    </app-drop-down>
                  </mat-form-field>
                </div>

                <div class="bids__action" (click)="$event.stopImmediatePropagation()">
                  <button
                    *ngIf="bidForm.value.status === statusToAccept || bid?.activeCounterOffer"
                    data-test="accept-bid-button"
                    type="submit"
                    mat-button
                    class="bids__button"
                    [ngClass]="{ bids__button__award: bid?.activeCounterOffer }"
                    [disabled]="disabled || bidForm.disabled || !(load | dedicatedAllowed: featureFlagState)"
                    (click)="onAcceptBid(bid)"
                  >
                    <mat-spinner *ngIf="isLoadingAccept; else textLabel" class="bids__button__spinner" diameter="23">
                    </mat-spinner>
                    <ng-template #textLabel>
                      {{ user?.broker ? 'Award' : 'Accept' }}
                    </ng-template>
                  </button>
                </div>
              </div>
            </div>

            <div class="counter-offer__info muted panel-header-row">
              <div class="counter-offer__info__wrapper">
                <ng-container
                  *ngIf="{
                    carrier: bid | bidSourceTypeCount: bidHistory:'carrier',
                    broker: bid | bidSourceTypeCount: bidHistory:'broker'
                  } as offerCount"
                >
                  <div class="counter-offer__info__header" *ngIf="user?.broker ? offerCount.carrier : true">
                    <mat-icon svgIcon="haulynx-gray-truck-small" class="counter-offer__icon"></mat-icon>
                    <span>
                      Carrier Offers:
                      <span class="counter-offer__value">{{ offerCount.carrier }} </span>
                    </span>
                    <mat-icon svgIcon="haulynx-gray-message-small" class="counter-offer__icon"></mat-icon>
                    <span>
                      Broker Offers:
                      <span class="counter-offer__value">{{ offerCount.broker }} </span>
                    </span>
                  </div>
                </ng-container>

                <ng-container *ngIf="bidMessageData$ | async as message">
                  <div
                    *ngIf="message.doShow"
                    class="{{ 'counter-offer__' + (message.isNew ? 'new' : 'last') + '-offer' }}"
                  >
                    <mat-icon
                      svgIcon="{{ 'haulynx-' + message.color + '-tick-small' }}"
                      class="counter-offer__icon--tick"
                    >
                    </mat-icon>
                    {{ message.text }} <span *ngIf="message.price">&nbsp;{{ message.price | showCurrency }}</span>
                  </div>
                </ng-container>
              </div>
              <button
                (click)="onOpenCounterOffer($event, bid)"
                data-test="counter-offer-button"
                *ngIf="(bid.sourceType !== 'broker' || bid.activeCounterOffer) && !disabled"
                class="hlx-button-command hlx-button-command--cancel hlx-button-command--border-white counter-offer__info__button"
                [disabled]="bidForm.disabled"
              >
                {{ bid.activeCounterOffer ? 'Update Counter Offer' : 'New Counter Offer' }}
              </button>
            </div>
          </div>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="bids__details">
        <div class="bids__info">
          <div class="expanded-details-column__left">
            <ng-container *ngIf="bid.sourceType === 'broker'; else carrierBidInfo">
              <div class="bids__info__row">
                <div class="bids__info__column bids__info__column--muted">Created By</div>
                <div class="bids__info__column">
                  {{ bid?.createdBy?.name }}
                </div>
              </div>
              <div class="bids__info__row">
                <div class="bids__info__column bids__info__column--muted">Date Created</div>
                <div class="bids__info__column">
                  {{ bid.createdAt | mtz: 'MM/DD/YY HH:mm':userTimeZone:false:true }}
                </div>
              </div>
              <div class="bids__info__row">
                <div class="bids__info__column bids__info__column--muted">Last Edited</div>
                <div class="bids__info__column">
                  {{ bid?.updatedBy?.name }}
                </div>
              </div>
              <div class="bids__info__row">
                <div class="bids__info__column bids__info__column--muted">Date Edited</div>
                <div class="bids__info__column">
                  {{ bid.updatedAt | mtz: 'MM/DD/YY HH:mm':userTimeZone:false:true }}
                </div>
              </div>
            </ng-container>

            <ng-template #carrierBidInfo>
              <div class="bids__info__row">
                <div class="bids__info__column bids__info__column--muted">Created By</div>
                <div class="bids__info__column">
                  {{ bid?.carrier?.name }}
                </div>
              </div>
              <div class="bids__info__row">
                <div class="bids__info__column bids__info__column--muted">Date Submitted</div>
                <div class="bids__info__column">
                  {{ bid.createdAt | mtz: 'MM/DD/YY HH:mm':userTimeZone:false:true }}
                </div>
              </div>
              <div class="bids__info__row">
                <div class="bids__info__column bids__info__column--muted">Buy-it-now Price</div>
                <div class="bids__info__column">
                  {{ load.price | showCurrency }}
                </div>
              </div>
            </ng-template>

            <div class="bids__info__row">
              <div class="bids__info__column bids__info__column--muted">Bid History</div>
              <div class="bids__info__column">
                <span class="bids__info__column--history">
                  <mat-spinner *ngIf="isLoadingHistory; else openHistory" diameter="16" class="bids__spinner--small">
                  </mat-spinner>
                  <ng-template #openHistory>
                    <span (click)="onOpenHistory(bid)"> Open History ({{ bidHistory?.length }}) </span>
                  </ng-template>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="expanded-details-column__right">
          <div class="bids__notes" *ngIf="bid.sourceType === 'broker' ? user?.broker : !user?.broker; else carrierNote">
            <br />
            Notes
            <br />
            <mat-form-field appearance="none">
              <textarea
                matInput
                class="bids__notes__textarea"
                formControlName="notes"
                (change)="onDataChange(bidForm.getRawValue(), 'notes')"
              ></textarea>
            </mat-form-field>
          </div>

          <ng-template #carrierNote>
            <div class="bids__notes counter-offer__notes">
              Last Message from Carrier
              <mat-spinner *ngIf="isLoadingHistory; else messageHistory" diameter="16" class="bids__spinner--small">
              </mat-spinner>

              <ng-template #messageHistory>
                <!-- (1 of {{ bidHistory | totalMessages: bid?.carrier?.name }}): -->
                <div class="counter-offer__notes__message">
                  {{ bid.notes }}
                </div>
                <div class="counter-offer__notes__history" *ngIf="(bidHistory | lastCarrierMessage)?.length >= 130">
                  ← [ Full message in bid history ]
                </div>
              </ng-template>
            </div>
          </ng-template>
        </div>
      </div>
    </mat-expansion-panel>
  </div>
</form>
