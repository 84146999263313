import gql from 'graphql-tag';

export const GetTrailersByCarrierId = gql(`
  query GetTrailersByCarrierId($carrierId: ID!) {
    getTrailersByCarrierId(carrierId: $carrierId) {
      id
      type
      trailerNumber
      inactive
      trailerOwnerType
    }
  }
`);
