import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AssignmentForm,
  CarrierForm,
  CarrierSourceType,
  FeatureFlag,
  FFState,
  TrackingType,
  TrailerOwnerType,
} from '@haulynx/types';
import { forEach, keys } from 'lodash';

export interface TrackingTypeOptions {
  label: string;
  value: TrackingType;
}

export interface TrailerOwnerOptions {
  label: string;
  value: TrailerOwnerType;
}

export class CarrierInfoSectionForm implements CarrierForm {
  carrier: string = null;
  mcNumber: string = null;
  contact: string = null;
  phone: string = null;
  email: string = null;

  constructor(options = {}) {
    const key = keys(this);
    const defaultValue = [null];

    forEach(key, (prop) => {
      this[prop] = (options && options[prop]) || defaultValue;
    });
  }
}

export class AssignmentSectionForm implements AssignmentForm {
  trackingType: TrackingType = null;
  truckId: string = null;
  driverId: string = null;
  trailerOwner: TrailerOwnerType = null;
  trailerId: string = null;
  phone: string = null;
  email: string = null;
  equipment: string = null;
  secDriverId: string = null;
  secEmail: string = null;
  secPhone: string = null;

  constructor(options = {}) {
    const key = keys(this);
    const defaultValue = [null];

    forEach(key, (prop) => {
      this[prop] = (options && options[prop]) || defaultValue;
    });
  }
}

@Injectable()
export class CarrierInfoSectionVmService {
  carrierInfoFormOptions;
  assignmentFormOptions;

  constructor(private fb: FormBuilder) {
    this.carrierInfoFormOptions = {
      mcNumber: [{ value: null, disabled: true }],
      contact: [{ value: null, disabled: true }],
      phone: [{ value: null, disabled: true }],
      email: [{ value: null, disabled: true }],
    };

    this.assignmentFormOptions = {
      trackingType: [{ value: null, disabled: false }],
      truckId: [{ value: null, disabled: false }],
      driverId: [{ value: null, disabled: false }],
      trailerId: [{ value: null, disabled: false }],
      trailerOwner: [{ value: null, disabled: false }],
      phone: [{ value: null, disabled: false }],
      email: [{ value: null, disabled: false }],
      equipment: [{ value: null, disabled: true }],
    };
  }

  createCarrierInfoFormOptions(values: Partial<CarrierInfoSectionForm> = {}): FormGroup {
    const carrierInfoForm = new CarrierInfoSectionForm(this.carrierInfoFormOptions);
    const formGroup = this.fb.group(carrierInfoForm);

    if (values) {
      formGroup.patchValue(values);
    }

    return formGroup;
  }

  createAssignmentFormOptions(values: Partial<CarrierInfoSectionForm> = {}): FormGroup {
    const carrierInfoForm = new AssignmentSectionForm(this.assignmentFormOptions);
    const formGroup = this.fb.group(carrierInfoForm);

    if (values) {
      formGroup.patchValue(values);
    }

    return formGroup;
  }

  getDefaultTrackingOptions(featureFlags: FFState): TrackingTypeOptions[] {
    const options: TrackingTypeOptions[] = [];
    options.push(
      { label: undefined, value: undefined },
      { label: 'Manual', value: TrackingType.MANUAL },
      { label: 'Driver Lite', value: TrackingType.DRIVER_LITE }
    );
    if (featureFlags && featureFlags[FeatureFlag.MACROPOINT]) {
      options.push({ label: 'Macropoint', value: TrackingType.MACRO_POINT });
    }
    return options;
  }

  getAdditionalTrackingOptions(carrier, trackingOptions): TrackingTypeOptions[] {
    let newOptions = [...trackingOptions];
    if (carrier?.sourceType === CarrierSourceType.DIGITAL) {
      newOptions.push({ label: 'Phone', value: TrackingType.PHONE });
    } else {
      newOptions = newOptions.filter((item) => item.value !== TrackingType.PHONE);
    }
    return newOptions;
  }

  getTrackingValidation(trackingType: TrackingType, form: FormGroup): FormGroup {
    switch (trackingType) {
      case TrackingType.DRIVER_LITE:
        form.controls['driverId'].setValidators([Validators.required]);
        form.controls['driverId'].updateValueAndValidity();

        form.controls['truckId'].setValidators([Validators.required]);
        form.controls['truckId'].updateValueAndValidity();

        form.controls['phone'].setValidators([Validators.required]);
        form.controls['phone'].updateValueAndValidity();
        return form;
      case TrackingType.MACRO_POINT_ELD:
        form.controls['driverId'].setValidators([Validators.required]);
        form.controls['driverId'].updateValueAndValidity();

        form.controls['truckId'].setValidators([Validators.required]);
        form.controls['truckId'].updateValueAndValidity();

        form.controls['phone'].clearValidators();
        form.controls['phone'].updateValueAndValidity();
        return form;
      case TrackingType.PHONE:
        form.controls['driverId'].setValidators([Validators.required]);
        form.controls['driverId'].updateValueAndValidity();

        form.controls['truckId'].setValidators([Validators.required]);
        form.controls['truckId'].updateValueAndValidity();

        form.controls['phone'].clearValidators();
        form.controls['phone'].updateValueAndValidity();
        return form;
      case TrackingType.MACRO_POINT:
        form.controls['driverId'].setValidators([Validators.required]);
        form.controls['driverId'].updateValueAndValidity();

        form.controls['truckId'].setValidators([Validators.required]);
        form.controls['truckId'].updateValueAndValidity();

        form.controls['phone'].setValidators([Validators.required]);
        form.controls['phone'].updateValueAndValidity();
        return form;
      default:
        form.controls['driverId'].clearValidators();
        form.controls['driverId'].updateValueAndValidity();

        form.controls['truckId'].clearValidators();
        form.controls['truckId'].updateValueAndValidity();

        form.controls['phone'].clearValidators();
        form.controls['phone'].updateValueAndValidity();
        return form;
    }
  }
}
