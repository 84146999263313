import { List } from 'immutable';
import { IFormState } from '../../shared/store/form/state';
import { DispatchAction } from '../../shared/store/helpers/action';
import { combineAdapterReducers } from '../../shared/store/helpers/adapter';
import { combineReducersFlat } from '../../shared/store/helpers/reducer';
import { ActiveLoadsActionTypes } from './active-loads-state.actions';
import { ActiveLoadsStateState } from './active-loads-state.state';
import { activeLoadsAdapter } from './adapters';

export const initialState: ActiveLoadsStateState = new ActiveLoadsStateState();

const mainReducer = combineReducersFlat([combineAdapterReducers(activeLoadsAdapter)]);

export function activeLoadsStateReducer(
  state: ActiveLoadsStateState = initialState,
  action: DispatchAction
): ActiveLoadsStateState | IFormState<any> {
  switch (action.type) {
    case ActiveLoadsActionTypes.SELECT_LOAD: {
      return state.merge({
        selectedLoadId: action.payload,
      });
    }

    case ActiveLoadsActionTypes.SEARCH_CARRIER: {
      return state.merge({
        isLoadingCarriers: true,
      });
    }

    case ActiveLoadsActionTypes.SEARCH_CARRIER_SUCCESS: {
      const { entities } = action.payload;

      return state.merge({
        carriers: List(entities),
        isLoadingCarriers: false,
      });
    }

    case ActiveLoadsActionTypes.SEARCH_CARRIER_ERROR: {
      return state.merge({
        carriers: List([]),
        isLoadingCarriers: false,
      });
    }

    case ActiveLoadsActionTypes.SEARCH_BROKER: {
      return state.merge({
        isLoadingBrokers: true,
      });
    }

    case ActiveLoadsActionTypes.SEARCH_BROKER_SUCCESS: {
      const { entities } = action.payload;

      return state.merge({
        brokers: List(entities),
        isLoadingCarriers: false,
      });
    }

    case ActiveLoadsActionTypes.SEARCH_BROKER_ERROR: {
      return state.merge({
        brokers: List([]),
        isLoadingCarriers: false,
      });
    }

    default: {
      return mainReducer(state, action);
    }
  }
}
