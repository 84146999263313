import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { DataTableUtilsService } from '@haulynx/services';
import { SpinnerModule } from '../spinner/spinner.module';
import { DataTableComponent } from './data-table.component';

@NgModule({
  imports: [CommonModule, SpinnerModule, PipesModule, MaterialModule],
  exports: [DataTableComponent],
  declarations: [DataTableComponent],
  providers: [DataTableUtilsService],
})
export class DataTableModule {}
