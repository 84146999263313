import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  IColumns,
  LoadFeedSearchForm,
  LoadIdentifierType,
  LoadsServiceLoad,
  LoadsServiceRestrictionTypes,
} from '@haulynx/types';

@Component({
  selector: 'haulynx-load-feed-search-results',
  templateUrl: './load-feed-search-results.component.html',
  styleUrls: ['./load-feed-search-results.component.scss'],
})
export class LoadFeedSearchResultsComponent {
  @Input() columns: IColumns[];
  @Input() loading: boolean;
  @Input() loads: LoadsServiceLoad[];
  @Input() showPrice = false;
  @Output() onClick = new EventEmitter<string>();
  @Output() search = new EventEmitter<LoadFeedSearchForm>();
  @Output() signup = new EventEmitter<void>();
  loadIdentifierType = LoadIdentifierType;
  loadsServiceRestrictionTypes = LoadsServiceRestrictionTypes;

  constructor() {}

  searchLoads(query: LoadFeedSearchForm): void {
    this.search.next(query);
  }

  viewLoad(load: LoadsServiceLoad): void {
    const { id } = load;
    this.onClick.next(id);
  }

  viewPriceClick(event: Event): void {
    event.stopPropagation();
    this.signup.next();
  }
}
