import { KeyValuePair } from '../generic/key-value-pair';

export const carrierNotBillTo: KeyValuePair[] = [
  {
    // Fed Ex Back Hauls
    key: '1295655',
    value: 'FXG NON REV XT USXI TRL B',
  },
  {
    // Fed Ex Back Hauls
    key: '1308484',
    value: 'FXG REPOSITIONING',
  },
  // // FOR TESTING IN DEV ONLY!
  // {
  //   key: '32242',
  //   value: 'CLOROX',
  // },
  // {
  //   key: '29344',
  //   value: 'RANDOM TEST CARRIER',
  // },
  // {
  //   key: '1241693',
  //   value: 'HAULYNX TEST',
  // },
];

export const usxiAssignBillToWhitelist: (string | undefined)[] = [
  '363862',
  '1295655',
  '505164',
  '997459',
  '1116542',
  '1316696',
  '1316263',
  '1253556',
  '1128714',
  '1040560',
  '1146374',
  '1336516',
  '1217219',
  '1341057',
  '1116153',
  '1144185',
  '1301820',
  '1317818',
  '621859',
  '993674',
  '1259726',
  '621823',
  '1316433',
  '529577',
  '1332986',
  '687728',
  '160784',
  '1308484',
];
