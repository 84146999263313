<div class="order-info-notes">
  <form [formGroup]="form" fxLayout="column" fxLayoutGap="8px">
    <div fxLayout="column" fxLayoutGap="16px">
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="8px">
        <div class="note-type" fxFlex="105px">Internal Notes</div>
        <mat-form-field appearance="fill" fxFlex="grow">
          <textarea matInput [readonly]="readOnly" rows="3" formControlName="internalNotes"></textarea>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="8px">
        <div class="note-type" fxFlex="105px">External Notes</div>
        <mat-form-field appearance="fill" fxFlex="grow">
          <textarea matInput [readonly]="readOnly" rows="3" formControlName="externalNotes"></textarea>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="8px">
        <div class="note-type" fxFlex="105px">Customer Notes</div>
        <mat-form-field appearance="fill" fxFlex="grow">
          <textarea matInput [readonly]="true" rows="3" formControlName="customerNotes"></textarea>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
