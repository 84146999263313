import { startCase } from 'lodash';
import { DropoffRequirements } from '../../../loads-service';
import { ISearchFilter, TextFilterType } from '../search-filter';
import { ISearchFilterType } from '../search-filter.types';

export class DropoffRequirementsFilter implements ISearchFilter {
  name = 'Dropoff Requirements';
  type: ISearchFilterType = ISearchFilterType.TEXT;
  keys: TextFilterType;
  description = 'Trailer instructions for the load dropoff';
  transformValue = startCase;

  constructor(defaultValues?: TextFilterType) {
    const options = Object.values(DropoffRequirements).map((value) => {
      return {
        key: startCase(value),
        value,
      };
    });
    this.keys = new TextFilterType({
      value: '',
      optionsDataIndex: options,
      textFormName: 'dropoffRequirements',
      ...defaultValues,
    });
  }
}
