import { List } from 'immutable';
import { deserialize } from 'serializer.ts/Serializer';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DistributionMechanismString, Load, LoadsServiceLoad, LoadTemplate } from '@haulynx/types';

@Component({
  selector: 'app-load-choose',
  templateUrl: './load-choose.component.html',
  styleUrls: ['./load-choose.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadChooseComponent {
  @Input() templates: undefined[] | List<{ [key: string]: unknown }> = [];
  @Output() selectTemplate = new EventEmitter();

  importMessage;

  constructor() {}

  newLoad(): void {
    const load = new LoadsServiceLoad();

    this.selectTemplate.emit(load);
  }

  openFile(event): void {
    const input = event.target;
    const reader = new FileReader();
    reader.onload = () => {
      const text = (reader.result as string).split(/\r\n|\n/);
      const lines: string[] = [];
      if (text.length > 0) {
        for (let i = 0; i < text.length; i++) {
          if (text[i].trim() !== '') {
            lines.push(text[i]);
          }
        }
        this.processStrings(lines);
      }
    };
    reader.readAsText(input.files[0]);
  }

  createWithTemplate(template: LoadTemplate): void {
    const load = new LoadsServiceLoad({
      ...template,
      distributionMechanism: DistributionMechanismString.AUTO,
    });

    this.selectTemplate.emit(load);
  }

  private processStrings(lines: string[]): void {
    const formData = {};
    if (lines.length === 0 || lines.length > 2) {
      this.importMessage = 'Invalid file. Please Check file format';
      return;
    }
    const header = lines[0].split(',');
    const fields = lines[1].split(',');
    if (header.length !== fields.length) {
      this.importMessage = 'Invalid file. Header must match field count';
      return;
    }
    for (let i = 0; i < header.length; i++) {
      formData[header[i].trim()] = fields[i].trim();
    }
    const load = deserialize<Load>(Load, formData);

    this.selectTemplate.emit(load);
  }
}
