import { Carrier } from '../carrier';
import { Location } from '../location';

export class Device {
  id?: string;
  imei?: string;
  token?: string;
  push_token?: string[];
  carrier?: Carrier;
  lastLocation?: Location;
  type?: string;
  destination?: Location;
  vin?: string;
  unitId?: string;
  make?: string;
  model?: string;
  year?: number;
  distanceToLoad?: number;
  macId?: string;
  index?: number;
  shippedDate?: string;
  trackingNumber?: string;
  returned?: boolean;
  returnDate?: string;
  writeOff?: boolean;
  writeOffReason?: string;
  cellularCarrierDeactivated?: boolean;
  lastLocationUpdate?: string;
  lastUpdated?: string;
  lastUpdatedBy?: string;
  notes?: string;
  status?: string;
  icci?: string;
  eldFirstInstalled?: string;
  deactivated?: boolean;
  driver?: string;
  shipping?: string;
  return?: string;

  constructor(props?: Partial<Device>) {
    Object.assign(this, props);
  }
}
