import { Component, OnDestroy } from '@angular/core';
import { TitleService, WindowRef } from '@haulynx/services';
import { CarriersModel } from '@haulynx/store';
import { Carrier, IColumns, User } from '@haulynx/types';

@Component({
  selector: 'haulynx-carriers-search',
  templateUrl: './carrier-search.component.html',
  styleUrls: ['./carrier-search.component.scss'],
})
export class CarrierSearchComponent implements OnDestroy {
  user: User;
  carrier: Carrier;

  ordersColumns: IColumns[] = [
    {
      label: 'Carrier Name',
      dataIndex: 'name',
      width: '222px',
      isCustomCell: true,
    },
    {
      label: 'MC#',
      dataIndex: 'mcNumber',
      width: '138px',
      isCustomCell: true,
    },
    {
      label: 'Type',
      dataIndex: 'sourceType',
      width: '138px',
      isCustomCell: true,
    },
    {
      label: 'Ownership',
      dataIndex: 'owner',
      isCustomCell: true,
      width: '138px',
    },
    {
      label: 'DOT',
      dataIndex: 'dot',
      width: '138px',
      isCustomCell: true,
    },
    {
      label: 'Contact Name',
      dataIndex: 'adminName',
      width: '138px',
      isCustomCell: true,
    },
    {
      label: 'Phone',
      dataIndex: 'phone',
      width: '138px',
      isCustomCell: true,
    },
    {
      label: 'Email',
      dataIndex: 'email',
      width: '138px',
      isCustomCell: true,
    },
  ];

  constructor(public carriersModel: CarriersModel, private windowRef: WindowRef, private titleService: TitleService) {
    this.titleService.setTitle(`Carrier Search - Haulynx`);
  }

  ngOnDestroy() {
    this.titleService.resetTitle();
  }

  searchCarrier(query?): void {
    this.carriersModel.search({ query });
  }

  changePageCarrier(pagination): void {
    this.carriersModel.search(pagination);
  }

  selectCarrier(carrier: Carrier): void {
    const id = carrier.dot;
    const url = `/dashboard/carriers/view/${id}`;
    this.windowRef.getNativeWindow().open(url, '_blank');
  }
}
