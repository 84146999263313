import { Injectable } from '@angular/core';
import { LoadsServiceService, NotificationsService } from '@haulynx/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { DispatchAction } from '../../shared/store/helpers/action';
import { LoadDetailsActions } from '../load-details/load-details.actions';
import { LoadActions, LoadActionTypes } from './load.actions';

@Injectable()
export class LoadEffects {
  selectTemplate = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadActionTypes.SELECT_TEMPLATE),
      map((action: DispatchAction) => action.payload),
      switchMap((payload) => {
        const { key, state } = payload;
        // omit unneeded fields from template
        const { name, templateId, dateCreated, ...newState } = state;

        return of(LoadDetailsActions.getSuccess({ key, state: newState }));
      })
    )
  );

  confirmDelivery = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadActionTypes.CONFIRM_DELIVERY),
      map((action: DispatchAction) => action.payload),
      switchMap((payload) => {
        const { loadId, loadLocationIndex = null, timestamps = null } = payload;
        const updateLocation = {
          arrivalTime: timestamps.entranceTime || null,
          departureTime: timestamps.completed || null,
        };

        return this.loadsServiceService
          .updateArrivalTimeOrCompleteLoadLocation(loadId, loadLocationIndex, updateLocation)
          .pipe(
            map(() => LoadActions.confirmDeliverySuccess()),
            catchError((error) => {
              this.notificationsService.error(`Oops, something went wrong. Unable to confirm!`, `Load`);

              return of(LoadActions.confirmDeliveryError(error));
            })
          );
      })
    )
  );

  sendOfferLoad = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadActionTypes.SEND_OFFER_LOAD),
      map((action: DispatchAction) => action.payload),
      switchMap((payload) =>
        this.loadsServiceService.sendOffer(payload).pipe(
          map(() => {
            this.notificationsService.success(`The offer send success!`, `Send offer`);

            return LoadActions.sendOfferLoadSuccess({});
          }),
          catchError((err) => {
            this.notificationsService.error(`Can't send offer.`, `Send offer`);

            return of(LoadActions.sendOfferLoadError(err));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private loadsServiceService: LoadsServiceService,
    private notificationsService: NotificationsService
  ) {}
}
