<div [ngClass]="{ selected: isSelected }" class="load_item_container">
  <div class="locations info">
    <div class="locations_text nowrap">{{ firstLocationName | uppercase }}</div>
    <mat-icon class="icon" svgIcon="haulynx-forward"></mat-icon>
    <div class="locations_text nowrap">{{ lastLocationName | uppercase }}</div>
  </div>
  <div class="load info" *ngIf="!isBroker">
    <div class="load_text" *ngIf="label">{{ label }}</div>
    <div class="load_text" *ngIf="load?.paymentDetails?.price">
      {{ load?.paymentDetails?.price | currency: 'USD':'symbol':'1.2-2' }}
    </div>
    <div class="load_text nowrap">Deliver: {{ deliveryDate | date: 'mediumDate' }}</div>
  </div>
  <div class="load info" *ngIf="isBroker" [ngClass]="carrierAbbreviation ? 'space-between' : 'center'">
    <div class="load_text" *ngIf="label">{{ label }}</div>
    <div class="load_text" *ngIf="carrierAbbreviation">{{ carrierAbbreviation | uppercase }}</div>
    <div class="load_text nowrap">Deliver: {{ deliveryDate | date: 'mediumDate' }}</div>
  </div>
  <div class="load info">
    <app-book-load-button
      class="book-or-decline"
      *ngIf="showBookLoad"
      [load]="load"
      [showDecline]="showDecline"
      [isStatusCentered]="true"
      (bookSuccess)="bookSuccess()"
      (offerDeleteSuccessEvent)="offerDeleteSuccess($event)"
    >
    </app-book-load-button>
  </div>
</div>
