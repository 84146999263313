import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { MenuName } from '@haulynx/store';
import { HeaderItem, User } from '@haulynx/types';

@Component({
  selector: 'haulynx-header-endbuttons-user-profile',
  templateUrl: './header-endbuttons-user-profile.component.html',
  styleUrls: ['./header-endbuttons-user-profile.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderEndbuttonsUserProfileComponent {
  @Input() headerItems: HeaderItem[] = [];
  @Input() user: User;

  @Output() seletedHeaderItem = new EventEmitter<HeaderItem>();
  @Output() openLogout = new EventEmitter();

  headerNames = MenuName;

  constructor() {}

  onLogout(): void {
    this.openLogout.emit();
  }

  selectedItem(item: HeaderItem): void {
    this.seletedHeaderItem.emit(item);
  }
}
