import { Injectable } from '@angular/core';
import { NewTrailer, SearchParams, Trailer } from '@haulynx/types';
import { listToArray } from '@haulynx/utils';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppState } from '../../main-store/app.reducers';
import { FleetActions } from './fleet.action';
import { fleetSearchSelector, getIsLoadingSelector } from './fleet.selectors';

@Injectable({
  providedIn: 'root',
})
export class FleetModel {
  isLoading$: Observable<boolean> = this.store.select(getIsLoadingSelector);
  fleetTrailers$: Observable<Trailer[]> = this.store
    .select(fleetSearchSelector.getEntities)
    .pipe(map((entities) => listToArray(entities)));

  constructor(private store: Store<AppState>) {}

  searchFleetTrailerRecords(params?: SearchParams): void {
    this.store.dispatch(FleetActions.search(params));
  }

  createFleetTrailer(params: NewTrailer): void {
    this.store.dispatch(FleetActions.createFleetTrailer(params));
  }

  updateFleetTrailer(params: NewTrailer): void {
    this.store.dispatch(FleetActions.updateFleetTrailer(params));
  }
}
