import gql from 'graphql-tag';

export const CreatePaymentItems = gql(`
  mutation CreatePaymentItems($paymentItems: [PaymentItemCreateInput]) {
    createPaymentItems(paymentItems: $paymentItems) {
      successfulItems {
        orderNumber
        paymentType
        quantity
        amount
        paymentUnits
        haulynxId
        usxId
      }
      failures {
        orderNumber
        paymentType
        quantity
        amount
        paymentUnits
        haulynxId
        usxId
      }
    }
  }
`);
