<div class="datepicker">
  <mat-form-field appearance="outline" floatLabel="always" *ngIf="range" class="range">
    <mat-label>{{ label }}</mat-label>
    <mat-date-range-input
      [formGroup]="rangeFormGroup"
      [rangePicker]="rangePicker"
      [min]="minDate"
      [max]="maxDate"
      [separator]="separator"
      [comparisonStart]="comparisonRangeFormGroup.value.startTime"
      [comparisonEnd]="comparisonRangeFormGroup.value.endTime"
      (click)="rangePicker.open()"
    >
      <input matStartDate formControlName="startTime" placeholder="Start date" />
      <input matEndDate formControlName="endTime" placeholder="End date" />
    </mat-date-range-input>
    <mat-datepicker-toggle [tabIndex]="tabindex" matSuffix [for]="rangePicker"></mat-datepicker-toggle>
    <mat-date-range-picker #rangePicker disabled="false"></mat-date-range-picker>
  </mat-form-field>

  <mat-form-field appearance="outline" floatLabel="always" *ngIf="!range">
    <mat-label>{{ label }}</mat-label>
    <input
      matInput
      [formControl]="formControl"
      [min]="minDate"
      [max]="maxDate"
      [matDatepicker]="picker"
      (click)="picker.open()"
    />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker disabled="false"></mat-datepicker>
  </mat-form-field>
</div>
