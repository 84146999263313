<div class="dialog">
  <div class="dialog__header">
    <div mat-dialog-title>
      <p class="dialog__title">{{ data.dialogType ? 'Create' : 'Edit' }} Comment</p>
    </div>
    <p class="dialog__text">{{ data.dialogType ? 'Create' : 'Edit' }} comment on {{ data.subType | titlecase }}</p>
    <p class="dialog__text">Created by: {{ data.comment?.creatorType | titlecase }} {{ data.comment?.creatorId }}</p>
    <p class="dialog__text">
      Creation time: {{ data.comment?.timestamp | date: 'MM/dd/yy HH:mm' }} {{ data.comment?.timezone }}
    </p>
  </div>
  <form [formGroup]="myForm" mat-dialog-content>
    <div class="form_outer">
      <div class="form_outer__form">
        <label for="contact">Contact</label>
        <div class="form_outer__form__input">
          <span>{{ !myForm.get('contact')?.value?.length ? 0 : myForm.get('contact').value.length }}/20</span>
          <input
            type="text"
            formControlName="contact"
            id="contact"
            autocomplete="off"
            placeholder="Enter contact spoken to"
            (ngModelChange)="onContactChange()"
          />
        </div>
      </div>
      <div class="form_outer__form">
        <label for="text">Comment</label>
        <textarea rows="4" id="text" formControlName="text" placeholder="Enter your comments here..."> </textarea>
      </div>
    </div>
  </form>
  <mat-dialog-actions align="end">
    <action-buttons
      [buttons]="actionButtons"
      [disableButtons]="disableButtons"
      [loading]="isLoading$ | async"
      (onClick)="clickEvent($event)"
    >
    </action-buttons>
  </mat-dialog-actions>
</div>
