import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { equipmentTypes, Trailer } from '@haulynx/types';
import { TrailerDeleteDialog } from '../trailer-delete/trailer-delete.component';

@Component({
  selector: 'app-trailer-form-dialog',
  template: `
    <h2 matDialogTitle>{{ newTrailer.id ? 'Edit' : 'Add New' }} Trailer</h2>
    <mat-dialog-content>
      <mat-form-field>
        <input
          matInput
          placeholder="Trailer Number"
          data-test="trailerNumberField"
          maxlength="10"
          [(ngModel)]="newTrailer.trailerNumber"
        />
        <mat-hint align="end">{{ newTrailer?.trailerNumber?.length }} / 10</mat-hint>
      </mat-form-field>

      <mat-form-field>
        <mat-select placeholder="Equipment" data-test="equipmentDropdown" [(ngModel)]="newTrailer.type">
          <mat-option *ngFor="let equipment of equipmentTypes" [value]="equipment"> {{ equipment }}</mat-option>
        </mat-select>
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button
        mat-button
        data-test="save-trailer"
        color="primary"
        [matDialogClose]="newTrailer"
        [disabled]="newTrailer?.trailerNumber?.length == 0"
      >
        Save
      </button>
      <button mat-button [matDialogClose]="" data-test="cancel-button">Cancel</button>
      <button *ngIf="trailer.id" mat-button data-test="delete-trailer" color="warn" (click)="deleteTrailer()">
        Delete
      </button>
    </mat-dialog-actions>
  `,
})
export class TrailerFormDialog {
  public equipmentTypes = []; // ["N/A", "Van", "Reefer", "Flatbed"];
  public deleteDialogRef: MatDialogRef<TrailerDeleteDialog>;
  public newTrailer: Trailer;

  constructor(
    @Inject(MAT_DIALOG_DATA) public trailer: Trailer,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<TrailerFormDialog>
  ) {
    this.newTrailer = { ...this.trailer };
    equipmentTypes.forEach((entry) => {
      this.equipmentTypes.push(entry.text);
    });
  }

  deleteTrailer() {
    this.deleteDialogRef = this.dialog.open(TrailerDeleteDialog, {});
    this.deleteDialogRef.afterClosed().subscribe((result) => {
      if (result && result.delete) {
        const data = { delete: true, trailer: this.trailer };
        this.dialogRef.close(data);
      }
    });
  }
}
