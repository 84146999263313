import { PaymentType } from './payment-type';

export interface PaymentsTypeForm {
  id?: string;
  paymentType: PaymentType;
  amount: number | string;
  orderNumber: string;
  loadId?: string;
  quantity: number;
}
