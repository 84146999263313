export interface FreightContract {
  customer0: string;
  customer1: string;
  shipper?: {
    city?: string;
    state?: string;
  };
  consignee?: {
    city?: string;
    state?: string;
  };
  carrierDOT: string;
  billTo: number;
  brokerOwner: string;
  price?: number;
  lineHaulPrice?: number;
  fuelPrice?: number;
  stopPrice?: number;
  lineHaulBasis: string;
  fuelBasis: string;
  stopBasis: string;
  notes?: string;
  appointmentSetRequiredCustomer0?: boolean;
  appointmentSetRequiredCustomer1?: boolean;
  appointmentSetStart?: Date;
  appointmentSetEnd?: Date;
  pickupTimeOfDay?: string;
  deliveryTimeOfDay?: string;
  pickupTimeDOW?: string;
  deliveryTimeDOW?: string;
  pickupType?: string;
  deliveryType?: string;
}

export interface FreightContractSearch {
  billTo: number;
  customer0: string;
  customer1: string;
  brokerOwner: string;
}

export interface FreightContractPageParams {
  pageSize: number;
  page: number;
  sortColumn?: string;
  sortDirection?: string;
}

export interface FreightContractInput extends FreightContract {
  userId: string;
}
