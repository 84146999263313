import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BookStatus,
  LoadFeedSearchForm,
  LoadServiceSearchParameters,
  LoadsServiceLoad,
  radiusSearchOptionToMiles,
} from '@haulynx/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UnauthenticatedLoadFeedService {
  constructor(private http: HttpClient) {}

  public getUsxOrdersAsLoads(query: LoadFeedSearchForm, isTestUser = false): Observable<LoadsServiceLoad[]> {
    const {
      pickUpLocation = null,
      deliveryLocation = null,
      pickupDate = null,
      originRadius = null,
      destinationRadius = null,
    } = query;
    let searchFields: LoadServiceSearchParameters = {
      showExcluded: true,
      bookStatus: [BookStatus.BOOKABLE, BookStatus.VIEWABLE],
      showTestLoads: isTestUser,
    };

    if (pickUpLocation && pickUpLocation.lat && pickUpLocation.lon) {
      searchFields = {
        ...searchFields,
        originLocation: [pickUpLocation.address],
        originLat: [pickUpLocation.lat],
        originLon: [pickUpLocation.lon],
      };
    }

    if (deliveryLocation && deliveryLocation.lon && deliveryLocation.lat) {
      searchFields = {
        ...searchFields,
        destinationLocation: [deliveryLocation.address],
        destinationLat: [deliveryLocation.lat],
        destinationLon: [deliveryLocation.lon],
      };
    }

    if (pickupDate) {
      searchFields = {
        ...searchFields,
        firstAppointmentStart: pickupDate.begin.valueOf(),
        firstAppointmentEnd: pickupDate.end.valueOf(),
      };
    }

    if (originRadius && pickUpLocation && pickUpLocation.lat && pickUpLocation.lon) {
      searchFields = { ...searchFields, originRadiusMiles: [radiusSearchOptionToMiles(originRadius)] };
    }

    if (destinationRadius && deliveryLocation && deliveryLocation.lon && deliveryLocation.lat) {
      searchFields = { ...searchFields, destinationRadiusMiles: [radiusSearchOptionToMiles(destinationRadius)] };
    }

    return this.http.post<Array<LoadsServiceLoad>>(`/api/loads/query`, { searchFields });
  }
}
