import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AppModel } from '@haulynx/store';
import { User } from '@haulynx/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class LoadDetailsGuard implements CanActivate {
  constructor(private appModel: AppModel, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.appModel.user$.pipe(
      map((user: User) => {
        let loadId;
        if (state.url.includes('?')) {
          loadId = state.url.substring(state.url.lastIndexOf('/') + 1, state.url.lastIndexOf('?'));
        } else {
          loadId = state.url.substring(state.url.lastIndexOf('/') + 1, state.url.length);
        }

        if (!user && state.url.includes('load')) {
          return this.router.parseUrl(`/loads/${loadId}`);
        }
        if (user && user.carrier) {
          return this.router.parseUrl(`/dashboard/loads/${loadId}`);
        }
        return true;
      })
    );
  }
}
