import { DispatchAction } from '../../shared/store/helpers/action';
import { combineAdapterReducers } from '../../shared/store/helpers/adapter';
import { favoriteAddressAdapter } from './adapters';
import { FavoriteAddressActionTypes } from './favorite-address.actions';
import { FavoriteAddressFormHttpStatus, FavoriteAddressState } from './favorite-address.state';

export const initialState = new FavoriteAddressState();

const reducer = combineAdapterReducers<FavoriteAddressState>(favoriteAddressAdapter);

export function favoriteAddressReducer(state = initialState, action: DispatchAction): FavoriteAddressState {
  switch (action.type) {
    case FavoriteAddressActionTypes.CREATE_ADDRESS: {
      return state.merge({
        isLoadingFavoriteAddressForm: true,
        favoriteAddressFormHttpStatus: FavoriteAddressFormHttpStatus.BLANK,
      });
    }

    case FavoriteAddressActionTypes.CREATE_ADDRESS_SUCCESS: {
      return state.merge({
        responseHttp: action.payload,
        isLoadingFavoriteAddressForm: false,
        favoriteAddressFormHttpStatus: FavoriteAddressFormHttpStatus.SUCCESS,
      });
    }

    case FavoriteAddressActionTypes.CREATE_ADDRESS_ERROR: {
      return state.merge({
        responseHttp: null,
        isLoadingFavoriteAddressForm: false,
        favoriteAddressFormHttpStatus: FavoriteAddressFormHttpStatus.FAILED,
      });
    }

    default:
      return reducer(state, action);
  }
}
