<div *ngIf="data" class="toolbar-card">
  <div (click)="onCarrierSelect()" class="toolbar-card__header">
    <div class="row1">
      <span class="name" data-test="carrier-name" [matTooltip]="data.name ? data.name : ''"
        >{{ data.name ?? data?.carrier?.name ?? 'No Carrier Name (DOT: ' + data.dot ?? data?.carrier.dot + ')' }}
        <span
          *ngIf="data.status"
          class="toolbar-card__header__status"
          [ngClass]="{ 'out-status': data.status === 'OUT' }"
          >{{ data.status }}</span
        ></span
      >
      <span class="toolbar-card__header__icon-container">
        <div *ngIf="tab === carrierToolbarTab.RECOMMENDATIONS" class="toolbar-card__header__icon blue">
          <mat-icon svgIcon="haulynx-truck"></mat-icon>
        </div>
        <div *ngIf="tab === carrierToolbarTab.RECOMMENDATIONS" class="toolbar-card__header__icon green">
          <mat-icon svgIcon="haulynx-loads-icon"></mat-icon>
        </div>
        <mat-icon class="toolbar-card__header__profile-button" (click)="onCarrierSelect()">chevron_right</mat-icon>
      </span>
    </div>
    <div class="row2">
      <div *ngIf="data.brokerId && data.brokerId !== 'NA' && tab === carrierToolbarTab.RECOMMENDATIONS" class="broker">
        Owned By {{ data.brokerId }}
      </div>
      <div *ngIf="data.usxId && tab !== carrierToolbarTab.RECOMMENDATIONS" class="broker">{{ data.usxId }}</div>
      <div *ngIf="data.owner && tab !== carrierToolbarTab.RECOMMENDATIONS" class="broker">
        Owned By {{ data.owner }}
      </div>
    </div>
  </div>

  <div class="toolbar-card__button-container" *ngrxLet="loadLockState$ as loadLockState">
    <button
      *ngIf="!(loadLockState[selectedLoad?.id] | isLoadLockedPipe); else lockedButton"
      class="book-button"
      (click)="onCarrierAction(laneHistoryActionMenu[1], data)"
      [disabled]="!selectedLoad || selectedLoad?.bookStatus === 'booked'"
      data-test="book-button"
    >
      <mat-icon svgIcon="haulynx-loads-icon"></mat-icon>
      <span>Book</span>
    </button>
    <ng-template #lockedButton>
      <span [matTooltip]="loadLockState[selectedLoad?.id] | transformBookToolTipPipe">
        <button class="book-locked" data-test="locked-button" disabled>
          <mat-icon svgIcon="haulynx-lock"></mat-icon>
          Locked
        </button>
      </span>
    </ng-template>
    <button
      class="bid-button"
      (click)="toggleBids()"
      [disabled]="!selectedLoad || selectedLoad?.bookStatus === 'booked'"
      data-test="bid-button"
    >
      <mat-icon svgIcon="haulynx-bid-icon"></mat-icon>
      <span>Bid</span>
    </button>
    <button class="call-button" (click)="onCarrierAction(laneHistoryActionMenu[0], data)">
      <mat-icon svgIcon="haulynx-phone-contact"></mat-icon>
      <span>Call</span>
    </button>
  </div>

  <haulynx-carrier-toolbar-bids
    *ngIf="showBids"
    [carrier]="{ dot: data?.dot || data?.carrier?.dot || data }"
    [user]="user"
    [latestBid]="latestBidChecked"
    [bidCreateLoading]="bidCreateLoading"
    (toggleForm)="toggleBids()"
    (submitBidForm)="submitBid($event)"
    (gotToBids)="onCarrierAction(laneHistoryActionMenu[2], data)"
  ></haulynx-carrier-toolbar-bids>

  <div
    *ngIf="
      featureFlags.CARRIER_TOOLBAR_OPPORTUNITIES && tab === carrierToolbarTab.RECOMMENDATIONS;
      else recommendationContent
    "
    class="toolbar-card__match-percent"
  >
    {{ data.score ?? 0 }}%
  </div>
  <ng-template #recommendationContent>
    <div *ngIf="tab === carrierToolbarTab.RECOMMENDATIONS" class="toolbar-card__match-percent">
      {{ data.matchPercent ?? 0 }}%
    </div>
  </ng-template>

  <div *ngIf="tab === carrierToolbarTab.RECOMMENDATIONS" class="toolbar-card__details-button">
    <span class="details" (click)="showLoadDetails()">
      <mat-icon class="lightbulb-icon" svgIcon="haulynx-lightbulb"></mat-icon>

      <ng-container *ngIf="featureFlags.CARRIER_TOOLBAR_OPPORTUNITIES; else recommendationContent1">
        <span>This carrier was recommended from load details</span>
      </ng-container>

      <ng-template #recommendationContent1>
        <span class="text"> {{ recommendationText }}</span>
      </ng-template>

      <mat-icon
        [ngClass]="{
          'caret-icon': true,
          'no-display': data?.explanation?.type == recommendedType.FREQUENT_HAUL,
          open: showDetails
        }"
        >play_arrow</mat-icon
      >
    </span>
    <span class="vote" *appFeatures="recommendationVoteFlag">
      <span *ngIf="latestBidChecked || carrierCalled">
        <mat-icon
          [ngClass]="{
            'thumbs-up': true,
            chosen: recVote === recommendationVote.LIKE
          }"
          matTooltip="Provide feedback that this carrier was a great fit for this load."
          matTooltipClass="tooltip-class"
          (click)="vote(recommendationVote.LIKE)"
          svgIcon="thumbs-up"
        ></mat-icon>
        <mat-icon
          [ngClass]="{
            'thumbs-down': true,
            chosen: recVote === recommendationVote.DISLIKE
          }"
          matTooltip="Provide feedback that this carrier was not a good fit for this load."
          matTooltipClass="tooltip-class"
          (click)="vote(recommendationVote.DISLIKE)"
          svgIcon="thumbs-down"
        ></mat-icon>
      </span>
    </span>
  </div>

  <div *ngIf="showDetails && tab === carrierToolbarTab.RECOMMENDATIONS" class="toolbar-card__details">
    <div *ngIf="featureFlags.CARRIER_TOOLBAR_OPPORTUNITIES" class="header">
      This similarly booked load has these details
    </div>
    <div class="load-number" (click)="routeToLoad(data?.load?.id)">
      Load #{{ data?.load | loadAlternateId: 'tmwNumber' }}
    </div>

    <div class="row-one">
      <span class="origin">
        {{ data?.load?.locations | nth: 0 | get: 'address':'N/A' | address: 3:true | titlecase }}
        {{ data?.load?.locations | nth: 0 | get: 'address':'N/A' | address: 4 | uppercase }}
      </span>
      <mat-icon class="arrow">east</mat-icon>
      <span class="dest"
        >{{ data?.load?.locations | nth: -1 | get: 'address':'N/A' | address: 3:true | titlecase }}
        {{ data?.load?.locations | nth: -1 | get: 'address':'N/A' | address: 4 | uppercase }}
      </span>
    </div>

    <div class="row-two">
      <div class="date-range">
        {{ data.load?.locations | nth: 0 | get: 'appointmentStart' | date: 'shortDate' }} -
        {{ data.load?.locations | nth: 0 | get: 'appointmentStart' | date: 'shortDate' }}
      </div>
      <div class="equipment">{{ data.load?.providerDetails?.equipmentType | fromCamelCase }}</div>
    </div>

    <div class="row-three">
      <div class="broker">{{ data?.brokerId }}</div>
      <div class="price">{{ data.load?.paymentDetails?.price | currency: 'USD':'symbol':'1.2-2' }}</div>
    </div>
  </div>
</div>
