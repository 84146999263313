import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CreateCarrierComplianceTicket,
  CreateCarrierNote,
  CreateDevicesWithImei,
  CreateEldTruckMutation,
  GetCarrierAssetsByDot,
  GetCarriersByNameOrDot,
  GetDeviceById,
  GetDevicesByCarrierId,
  GetRecommendedCarriersByLoadId,
  GetTrailersByCarrierId,
  SearchAssetBridgeTrailers,
  SearchCarrierComplianceTickets,
  TrucksByCarrierIdQuery,
  UpdateCarrierComplianceTicket,
  UpdateDeviceById,
  UpdateEldTruckMutation,
} from '@haulynx/gql';
import {
  Carrier,
  CarrierApiNote,
  CarrierApiNoteInput,
  CarrierAssets,
  CarrierComplianceTicket,
  CarrierComplianceTicketParamsInput,
  Device,
  FetchResult,
  Location,
  NewCarrierComplianceTicketInput,
  NewUSXITrailer,
  PageAndSort,
  PaginatedData,
  PaginatedZipLaneServiceData,
  radioTypes,
  RecommendedCarriers,
  SendLogsPayload,
  Trailer,
  Truck,
  TruckInput,
  User,
  VINLookupResponse,
} from '@haulynx/types';
import { toHttpParams } from '@haulynx/utils';
import { get, uniqBy } from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { GraphqlService } from '../../graphql/graphql.service';
import { UserService } from '../../shared-services/user.service';

@Injectable({ providedIn: 'root' })
export class CarrierService {
  private carriers: Carrier[] = [];
  constructor(private user: UserService, private http: HttpClient, private graphqlService: GraphqlService) {
    this.user.isHaulynxAdmin().subscribe((data) => {
      if (data) {
        this.getCarriers();
      }
    });
  }

  getCarriers(): Observable<Carrier[]> {
    if (this.carriers.length) {
      return of(this.carriers);
    } else {
      return this.http.get<Carrier[]>('/api/carriers').pipe(
        tap((carriers) => {
          this.carriers = carriers || [];
        })
      );
    }
  }

  /**
   * Gets a carrier from firebase directly
   * @param carrierId
   * @param dot
   * @returns
   * @deprecated use carriers service get carrier by id
   */
  getCarrier(carrierId: string, dot = ''): Observable<Carrier> {
    let params = {};

    if (dot) {
      params = toHttpParams({ dot });
    }

    return this.http.get<Carrier>(`/api/carriers/${carrierId}`, { params });
  }

  createCarrier(carrier: Carrier): Observable<string> {
    return this.http.post<string>('/api/carriers', JSON.stringify(carrier)).pipe(
      map((result) => {
        if (result && result['id']) {
          return result['id'] as string;
        } else {
          return '';
        }
      })
    );
  }

  createTrailer(trailer: Trailer, carrierId = ''): Observable<Trailer> {
    const params = toHttpParams({ carrierId }).toString();
    const url = `/api/devices/trailers${params ? '?' + params : ''}`;
    return this.http.post<Trailer>(url, JSON.stringify(trailer));
  }

  getTrailers(carrierId: string = null): Observable<Trailer[]> {
    return this.graphqlService
      .query({
        query: GetTrailersByCarrierId,
        variables: {
          carrierId,
        },
      })
      .pipe(
        map(({ data }: FetchResult) => {
          return get(data, 'getTrailersByCarrierId', []) as Trailer[];
        })
      );
  }

  updateTrailer(trailer: Trailer, carrierId = ''): Observable<Trailer> {
    const params = toHttpParams({ carrierId }).toString();
    const url = `/api/devices/trailers${params ? '?' + params : ''}`;
    return this.http.post<Trailer>(url, JSON.stringify(trailer));
  }

  deleteTrailer(trailer: Trailer): Observable<Trailer> {
    return this.http.delete<Trailer>(`/api/devices/trailers/${trailer.id}`);
  }

  createDevice(device): Observable<Device> {
    return this.http.post<Device>('/api/devices', device);
  }

  createDevicesWithImei(devices: Device[], userId: string): Observable<Device[]> {
    return this.graphqlService
      .mutate({
        mutation: CreateDevicesWithImei,
        variables: {
          devices,
          userId,
        },
      })
      .pipe(map(({ data }: FetchResult) => data.createDevicesWithImei || []));
  }

  setIMEI(data: Device): Observable<Device> {
    return this.http.post<Device>('/api/devices/setimei', JSON.stringify(data));
  }

  updateDeviceCarrier(device: Device): Observable<Device> {
    return this.http.post<Device>(`/api/devices/updateCarrier/${device.imei}`, JSON.stringify(device.carrier));
  }

  updateCarrier(carrier: Carrier): Observable<Carrier> {
    return this.http.patch<Carrier>(`/api/carriers/${carrier.id}`, JSON.stringify(carrier));
  }

  getCarrierDevice(deviceID: string): Observable<Device> {
    return this.http.get<Device>(`/api/devices/${deviceID}`);
  }

  getDrivers(carrierId = ''): Observable<User[]> {
    const params = toHttpParams({
      carrierId,
      type: 'isDriver',
    });

    return this.http.get<User[]>('/api/users', { params });
  }

  searchDevice(filter): Observable<Device[]> {
    return this.http.post<Device[]>('/api/v2/device/search', filter);
  }

  getDevices(carrierId: string = null, status = radioTypes.ALL.action): Observable<Device[]> {
    if (!carrierId || !carrierId.length || carrierId === '-1') return of([]);
    return this.graphqlService
      .query({
        query: GetDevicesByCarrierId,
        variables: {
          carrierId,
        },
      })
      .pipe(
        map((trucks) => {
          const result = get(trucks, 'data.getDevicesByCarrierId', []);
          if (Array.isArray(result)) {
            return result.filter((device) => {
              switch (status) {
                case radioTypes.ALL.action:
                  return true;
                case radioTypes.UNAVAILABLE.action:
                  return device.lastLocation && device.lastLocation.driver !== 'N/A' && device.lastLocation.driverName;
                case radioTypes.AVAILABLE.action:
                  return (
                    device.lastLocation && (device.lastLocation.driver === 'N/A' || !device.lastLocation.driverName)
                  );
                case radioTypes.NO_TELEMATICS.action:
                  return !device.imei;
                default:
                  return false;
              }
            });
          } else {
            return [];
          }
        })
      );
  }

  getDeviceById(id: string): Observable<Device> {
    return this.graphqlService
      .query({
        query: GetDeviceById,
        variables: {
          id,
        },
      })
      .pipe(
        map((data) => get(data.data, 'getDeviceById', null)),
        catchError((error) => null)
      );
  }

  updateDevice(device: Device, markUnassigned: boolean): Observable<Device> {
    return this.graphqlService
      .mutate<{ updateDeviceById: Device }>({
        mutation: UpdateDeviceById,
        variables: { id: device.imei, device, markUnassigned },
      })
      .pipe(map((res) => res.data.updateDeviceById));
  }

  getUSXTrailers(customerLotNumber?: string, customerCompany?: string): Observable<NewUSXITrailer[]> {
    return this.graphqlService
      .query<{ getUSXTrailers: NewUSXITrailer[] }>({
        query: SearchAssetBridgeTrailers,
        variables: {
          customerCompany,
          customerLotNumber,
          pageNumber: 1,
          pageSize: 100,
        },
      })
      .pipe(
        map((res) => {
          const result = get(res, 'data.searchAssetBridgeTrailers.data', []);
          if (Array.isArray(result)) {
            return result;
          } else {
            return [];
          }
        }),
        catchError((error) => of([]))
      );
  }

  getTrucks(carrierId: string = null, status = radioTypes.ALL.action): Observable<Truck[]> {
    if (!carrierId || !carrierId.length || carrierId === '-1') return of([]);
    return this.graphqlService
      .query({
        query: TrucksByCarrierIdQuery,
        variables: {
          carrierId,
        },
      })
      .pipe(
        map((trucks) => {
          const result = get(trucks, 'data.getTrucksByCarrierId.result', []);
          if (Array.isArray(result)) {
            return result.filter((device) => {
              switch (status) {
                case radioTypes.ALL.action:
                  return true;
                case radioTypes.UNAVAILABLE.action:
                  return device.lastLocation && device.lastLocation.driver !== 'N/A' && device.lastLocation.driverName;
                case radioTypes.AVAILABLE.action:
                  return (
                    device.lastLocation && (device.lastLocation.driver === 'N/A' || !device.lastLocation.driverName)
                  );
                case radioTypes.NO_TELEMATICS.action:
                  return !device.imei;
                default:
                  return false;
              }
            });
          } else {
            return [];
          }
        })
      );
  }

  createTruck(truck: Truck, data: { carrierId?: string; userId: string }): Observable<FetchResult<Truck>> {
    const { carrierId, userId } = data;
    const newTruck = new TruckInput({ ...truck, carrierId });
    return this.graphqlService.mutate<Truck>({
      mutation: CreateEldTruckMutation,
      variables: {
        newTruck,
        userId,
      },
    });
  }

  updateTruck(truck: Truck, userId?: string): Observable<FetchResult<Truck>> {
    const truckFields = new TruckInput(truck);
    return this.graphqlService.mutate<Truck>({
      mutation: UpdateEldTruckMutation,
      variables: {
        truckFields,
        userId,
      },
    });
  }

  simulatePosition(deviceId: string, lastLocation: Location) {
    return this.http.patch(`/api/devices/position/${deviceId}`, JSON.stringify(lastLocation));
  }

  deleteCarrier(carrier: Carrier) {
    return this.http.delete(`/api/carriers/${carrier.id}`);
  }

  updateSaferwatch() {
    return this.http.post('/api/carriers/runSaferwatch', null);
  }

  sendLogs(payload: SendLogsPayload) {
    return this.http.post('/api/elogs/sendLogs', payload);
  }

  searchTrailerTypes(filter = {}): Observable<Trailer> {
    return this.http.post<Trailer>(`/api/v2/dictionaries/trailer-types/search`, filter);
  }

  vinLookup(vins: string[]): Observable<VINLookupResponse> {
    return this.http.post<VINLookupResponse>(`/api/trucks/vinLookup`, vins);
  }

  /**
   * Gets a trucks, driver, trailer and carrier from dot
   * @param dot
   */
  getCarrierAssets(dot: string): Observable<CarrierAssets> {
    return this.graphqlService
      .query({
        query: GetCarrierAssetsByDot,
        variables: {
          dot,
        },
      })
      .pipe(
        map((data) => get(data.data, 'getCarrierAssetsByDot', null)),
        catchError((error) => null)
      );
  }

  getRecommended(loadId: string, paging: PageAndSort): Observable<PaginatedData<RecommendedCarriers>> {
    const defaultPaging: PageAndSort = { limit: 10, page: 1, sort: 'ASC' };

    return this.graphqlService
      .query<PaginatedData<unknown>>({
        query: GetRecommendedCarriersByLoadId(),
        variables: {
          loadId: loadId,
          paging: { ...defaultPaging, ...paging },
        },
      })
      .pipe(
        map((result) => {
          const response: PaginatedZipLaneServiceData<unknown> = result.data['getRecommendedCarriersByLoadId'];

          const val = {
            data: uniqBy(response.result, 'id'),
            pagination: response.paginator,
          } as PaginatedData<RecommendedCarriers>;
          return val;
        })
      );
  }

  searchCarriers(variables: any, paging: PageAndSort): Observable<PaginatedData<unknown>> {
    const defaultPaging: PageAndSort = { limit: 25, page: 1 };
    return this.graphqlService
      .query({
        variables: {
          ...variables,
          paging: { ...defaultPaging, ...paging },
        },
        query: GetCarriersByNameOrDot,
      })
      .pipe(
        map(({ data }) => {
          const { result = [], total = 0 } = data['getCarriersByNameOrDot'] || {};
          return {
            data: result,
            pagination: defaultPaging,
          };
        })
      );
  }

  // getOwnedCarriers(ownedId: string, paging: PageAndSort): Observable<PaginatedData<Carrier>> {
  //   const defaultPaging: PageAndSort = { limit: 10, page: 1, sort: 'ASC' };

  //   return this.graphqlService
  //     .query<PaginatedData<unknown>>({
  //       query: GetCarrierByOwner,
  //       variables: {
  //         loadId: ownedId,
  //         paging: { ...defaultPaging, ...paging },
  //       },
  //     })
  //     .pipe(
  //       map((result) => {
  //         const response: PaginatedLoadsServiceData<unknown> = result.data['getCarrierbyOwner'];

  //         const val = {
  //           data: uniqBy(response.data, 'id'),
  //           pagination: response.paginator,
  //         } as PaginatedData<Carrier>;
  //         return val;
  //       })
  //     );
  // }

  createCarrierComplianceTicket(
    carrierComplianceTicketInput: NewCarrierComplianceTicketInput
  ): Observable<CarrierComplianceTicket> {
    return this.graphqlService
      .mutate({
        variables: { carrierComplianceTicketInput },
        mutation: CreateCarrierComplianceTicket,
      })
      .pipe(
        map(({ data }) => {
          return data['createCarrierComplianceTicket'] || {};
        })
      );
  }

  searchCarrierComplianceTicket(
    carrierComplianceTicketParams: CarrierComplianceTicketParamsInput
  ): Observable<PaginatedData<CarrierComplianceTicket>> {
    return this.graphqlService
      .query({
        variables: { carrierComplianceTicketParams },
        query: SearchCarrierComplianceTickets,
      })
      .pipe(
        map(({ data }) => {
          const response = data['searchCarrierComplianceTickets'];
          const val = {
            data: response.sort((a, b) => {
              return b.updated_at - a.updated_at;
            }),
            pagination: null,
          } as PaginatedData<CarrierComplianceTicket>;
          return val;
        })
      );
  }

  updateCarrierComplianceTicket(
    carrierComplianceTicketId: string,
    updateComplianceTicketParam: CarrierComplianceTicketParamsInput
  ): Observable<CarrierComplianceTicket> {
    return this.graphqlService
      .mutate({
        variables: { carrierComplianceTicketId, carrierComplianceTicketInput: updateComplianceTicketParam },
        mutation: UpdateCarrierComplianceTicket,
      })
      .pipe(
        map(({ data }) => {
          return data['updateCarrierComplianceTicket'] || {};
        })
      );
  }

  createNoteforCarrier(carrierId: string, carrierNoteInput: CarrierApiNoteInput): Observable<CarrierApiNote> {
    return this.graphqlService
      .mutate({
        mutation: CreateCarrierNote,
        variables: { carrierId, carrierNoteInput },
      })
      .pipe(
        map(({ data }) => {
          return data['createCarrierNote'] || {};
        })
      );
  }
}
