import { Injectable } from '@angular/core';
import { GeocodingService } from '@haulynx/services';
import { AsyncSearchManager, GeocodeParams, LatLonInput, PlaceInfo, PlaceType } from '@haulynx/types';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../../main-store/app.reducers';
import { AsyncEntityBase } from '../../main-store/async-entity/async-entity-base.class';
import { geocodingNamespace } from '../namespace';

@Injectable({ providedIn: 'root' })
export class GeocodingEntityService extends AsyncEntityBase<PlaceInfo> {
  getGeocodeLocationManager: AsyncSearchManager<PlaceInfo, GeocodeParams>;
  getGeocodeAutocompleteManager: AsyncSearchManager<PlaceInfo, GeocodeParams>;
  getReverseGecodeLocationManager: AsyncSearchManager<
    PlaceInfo,
    { coordinates: LatLonInput; limit?: number; placeType?: PlaceType }
  >;
  getReverseGecodeLocationsManager: AsyncSearchManager<
    PlaceInfo,
    { coordinates: LatLonInput[]; placeType?: PlaceType }
  >;

  constructor(protected actions$: Actions, protected store: Store<AppState>, private geocodingApi: GeocodingService) {
    super(actions$, store, geocodingNamespace);

    this.getGeocodeLocationManager = this.createAsyncSearchQuery('get geocode location', (searchPayload) => {
      return this.geocodingApi.getGeocodeLocation(searchPayload.query);
    });

    this.getGeocodeAutocompleteManager = this.createAsyncSearchQuery(
      'get geocode autocomplete locations',
      (searchPayload) => {
        const payload: GeocodeParams = {
          ...searchPayload.query,
          autocomplete: true,
        };
        return this.geocodingApi.getGeocodeLocation(payload);
      }
    );

    this.getReverseGecodeLocationManager = this.createAsyncSearchQuery(
      'get reverse geocode location',
      (searchPayload) => {
        return this.geocodingApi.getReverseGeocodeLocation(
          searchPayload.query.coordinates,
          searchPayload.query.limit,
          searchPayload.query.placeType
        );
      }
    );

    this.getReverseGecodeLocationsManager = this.createAsyncSearchQuery(
      'get reverse geocode locations',
      (searchPayload) => {
        return this.geocodingApi.getReverseGeocodeLocations(
          searchPayload.query.coordinates,
          searchPayload.query.placeType
        );
      }
    );
  }
}
