import { createSelector } from '@ngrx/store';
import { defaultStateKey, getLoadEntitiesState, LoadEntitiesState } from './load-entities.state';

const stateKey = (state: LoadEntitiesState) => (state && state.stateKey) || defaultStateKey;
const loading = (state: LoadEntitiesState, activeStateKey: string) => state && state.loading.get(activeStateKey);
const entities = (state: LoadEntitiesState, activeStateKey: string) => state && state.entities.get(activeStateKey);
const query = (state: LoadEntitiesState, activeStateKey: string) => state && state.query.get(activeStateKey);
const httpStatus = (state: LoadEntitiesState, activeStateKey: string) => state && state.httpStatus.get(activeStateKey);
const pagination = (state: LoadEntitiesState, activeStateKey: string) => {
  const total = (state.total && state.total.get(activeStateKey)) || 0;
  const limit = (state.limit && state.limit.get(activeStateKey)) || 0;
  const page = (state.page && state.page.get(activeStateKey)) || 1;

  return {
    total,
    limit,
    page,
  };
};

const graphQlPaging = (state: LoadEntitiesState, activeStateKey: string) => {
  const page = (state.page && state.page.get(activeStateKey)) || 1;
  const limit = (state.limit && state.limit.get(activeStateKey)) || 0;

  return {
    limit,
    page,
  };
};

export const getStateKey = createSelector(getLoadEntitiesState, stateKey);
export const getLoadingSelector = createSelector(getLoadEntitiesState, getStateKey, loading);
export const getEntitiesSelector = createSelector(getLoadEntitiesState, getStateKey, entities);
export const getQuerySelector = createSelector(getLoadEntitiesState, getStateKey, query);
export const getPaginationSelector = createSelector(getLoadEntitiesState, getStateKey, pagination);
export const getHttpStatusSelector = createSelector(getLoadEntitiesState, getStateKey, httpStatus);
export const getGraphQlPagingSelector = createSelector(getLoadEntitiesState, getStateKey, graphQlPaging);
