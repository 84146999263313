export class MapQueue {
  constructor(map?: Map<string, number[]>) {
    if (map) {
      this.map = map;
    }
  }

  private map: Map<string, number[]> = new Map<string, number[]>();

  /**
   * Pushes a value to the list of given key.
   * Creates the list for the key if the key is not found on the map.
   * @returns a new MapQueue instance.
   */
  push(key: string): MapQueue {
    if (this.map.has(key)) {
      this.map.get(key).push(1);
    } else {
      this.map.set(key, [1]);
    }
    return new MapQueue(this.map);
  }

  /**
   * Pops a value from the list at a given key if key is present.
   * @returns a new MapQueue instance.
   */
  pop(key: string): MapQueue {
    if (this.map.has(key)) {
      this.map.get(key).pop();
    }
    return new MapQueue(this.map);
  }

  /**
   * Checks that the size > 0 for the list at the given key.
   * @returns false if key is not present
   */
  has(key: string): boolean {
    if (this.map.has(key)) {
      return this.map.get(key).length > 0;
    } else {
      return false;
    }
  }
}
