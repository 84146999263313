<app-spinner [active]="loadFeedModel.isLoading$ | async"></app-spinner>

<haulynx-back-button
  *ngIf="referrer"
  [label]="backButtonLabel"
  [url]="referrer"
  class="broker-container__back-button"
></haulynx-back-button>

<div class="broker-container" *ngIf="loadFeed" [ngClass]="{ 'one-column': !showCarrierList }">
  <div class="broker-info">
    <app-load-main-info
      [user]="user"
      [load]="loadFeed"
      sectionTitle="MAIN INFO"
      (update)="updateLoad($event)"
      (showFacilityInfo)="onShowFacilityInfo($event)"
      (xpmOrder)="onXpmOrder($event)"
      [isLoading]="isLoadingEntities$ | async"
    >
    </app-load-main-info>
  </div>

  <div class="broker-map">
    <app-map-routes [padding]="30" [sources]="loadFeedRoute" [dark]="true"> </app-map-routes>
  </div>

  <div *ngIf="completedLoad" class="grid-right">
    <div class="broker-carriers" #loadFeedCarrierList *ngIf="showCarrierList">
      <ng-container *appFeatures="recommendationFeatureFlag; else oldRecommendations">
        <app-load-feed-carrier-search-v2
          class="carrier-search"
          [data]="loadFeedModel.carrierSearch.entities$ | async"
          [query]="loadFeedModel.carrierSearch.query$ | async"
          [pagination]="loadFeedModel.carrierSearch.pagination$ | async"
          [isLoading]="loadFeedModel.carrierSearch.isLoading$ | async"
          [disableButtons]="disableButtonsMap"
          [load]="loadFeed"
          [parentToTable]="loadFeedCarrierList"
          (onPagination)="changePageCarrier($event)"
          (onSearch)="searchCarriers($event)"
          (onAction)="onAction($event)"
          (onCarrierSelect)="onCarrierSelect($event)"
          (onShowFacilityInfo)="onShowFacilityInfo($event)"
        >
        </app-load-feed-carrier-search-v2>
      </ng-container>
    </div>

    <ng-template #oldRecommendations>
      <app-load-feed-carrier-search
        class="carrier-search"
        [data]="loadFeedModel.carrierSearch.entities$ | async"
        [query]="loadFeedModel.carrierSearch.query$ | async"
        [pagination]="loadFeedModel.carrierSearch.pagination$ | async"
        [isLoading]="loadFeedModel.carrierSearch.isLoading$ | async"
        [disableButtons]="disableButtonsMap"
        [load]="loadFeed"
        (onPagination)="changePageCarrier($event)"
        (onSearch)="searchCarriers($event)"
        (onAction)="onAction($event)"
        (onCarrierSelect)="onCarrierSelect($event)"
      >
      </app-load-feed-carrier-search>
    </ng-template>

    <div class="load-offers" *appFeatures="bidFeatureFlag">
      <ng-container
        *ngIf="{ bids: loadFeedModel.bidEntitiesList$ | async, bidsDisabled: loadAcceptedBid$ | async } as bidData"
      >
        <app-add-bid
          [load]="loadFeed"
          [carrier]="selectedCarrier"
          [bidStatuses]="bidStatusesCreation$ | async"
          [isLoading]="loadFeedModel.isLoadingBidCreate$ | async"
          [disableBids]="bidData.bidsDisabled || loadFeed?.bookStatus === BookStatus.BOOKED"
          [isSorterVisible]="bidData.bids?.length && !bidData.bidsDisabled"
          (createBid)="onCreateBid($event)"
        >
        </app-add-bid>

        <app-bid-list
          [isLoading]="loadFeedModel.isBidSearchLoading$ | async"
          [activeBidIds]="activeBidNotifications$ | async"
          [bids]="bidData.bids"
          [bidHistory]="bidHistory$ | async"
          [isLoadingAccept]="loadFeedModel.isLoadingAcceptBid$ | async"
          [isLoadingCounterOffer]="loadFeedModel.isLoadingCounterOffer$ | async"
          [isLoadingHistory]="loadFeedModel.isLoadingBidHistory$ | async"
          [bidStatuses]="loadFeedModel.bidStatusOptions$ | async"
          [carrier]="selectedCarrier"
          [bidsDisabled]="bidData.bidsDisabled || loadFeed?.bookStatus === BookStatus.BOOKED"
          [user]="appModel.user$ | async"
          [userTimeZone]="userTimeZone"
          [load]="loadFeed"
          [sortBidsBy]="loadFeedModel.sortBidsBy$ | async"
          (acceptBid)="showRmisBanner ? openRmisDialog() : onAcceptBid($event)"
          (getHistory)="onBidHistory($event)"
          (openHistory)="onOpenBidHistoryDialog($event)"
          (openCounterOffer)="onOpenCounterOfferDialog($event)"
          (dataChange)="onUpdateBid($event)"
          (sortOrderChange)="onSortOrderChange($event)"
        ></app-bid-list>
      </ng-container>
    </div>
  </div>
</div>
