import { ActionReducer, MemoizedSelector } from '@ngrx/store';

import { IAdapter } from '../helpers/adapter';
import { createActions, IFormActions, IFormTypes } from './actions';
import { createInitialFormState, IFormState } from './state';
import { createFormSelectors, IFormSelectors } from './selectors';
import { createFormReducer } from './reducer';

export interface FormAdapter<T> extends IAdapter {
  getInitialState(): IFormState<T>;

  getSelectors<V>(
    selectState: ((state: V) => IFormState<T>) | MemoizedSelector<any, IFormState<T>> | any
  ): IFormSelectors;

  getReducer(types?): ActionReducer<IFormState<T>, any>;

  getActionTypes(): IFormTypes;

  getActions(): IFormActions;
}

export function createFormAdapter<T>(
  options: {
    type?: any;
  } = {}
): FormAdapter<T> {
  const { type } = options;

  const initialState = createInitialFormState();
  const selectors = createFormSelectors<T>();
  const actions = createActions(type);
  const reducer = createFormReducer<IFormState<any>>(actions.getActionTypes());

  return {
    ...initialState,
    ...selectors,
    ...reducer,
    ...actions,
  };
}
