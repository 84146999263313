<div class="search-date-range-container" #wrapper>
  <form data-test="form" [formGroup]="dateForm" autocomplete="off" *ngIf="focusedFilter">
    <div class="sdr-chip-title">{{ focusedFilter.name }}:</div>

    <app-datetime-picker
      formControlName="{{ fromFormName }}"
      [selectionMode]="'range'"
      [minDate]="'2019-01-15'"
      [timeZone]="'America/Phoenix'"
      [showTimeZone]="true"
      style="padding: 8px"
    >
    </app-datetime-picker>

    <mat-icon data-test="close-button" class="sdr-close-icon" (click)="removeFocusedFilter()"> close </mat-icon>
  </form>
</div>
