import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { LoadAvailableTabsModel } from '@haulynx/store';
import { loadAvailableTabsConfig, Tab } from '@haulynx/types';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class LoadAvailableTabSelectedGuard implements CanActivate {
  constructor(private loadAvailableTabsModel: LoadAvailableTabsModel) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const { id = null } = route.params;
    const searchId = loadAvailableTabsConfig.SEARCH.id;
    let { selected = null } = route.data;

    if (id) {
      const url = `details/${id}`;

      selected = new Tab({
        id,
        url,
        selected: true,
        queryParams: route.queryParams,
      });
    }

    return this.loadAvailableTabsModel.tabsSelected$.pipe(
      withLatestFrom(this.loadAvailableTabsModel.tabs$),
      map(([tabSelected, tabs]) => {
        const tabId = _.get(tabSelected, 'id', null);
        const activeId = _.get(selected, 'id', null);

        if (activeId && !tabs.get(activeId)) {
          this.loadAvailableTabsModel.addTabs([selected]);
        }

        if (activeId && activeId !== tabId && activeId !== searchId) {
          this.loadAvailableTabsModel.selectTab(selected);
        }

        return true;
      })
    );
  }
}
