import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CommonEntitiesEffects } from '../../shared/common-entities/common-entities.effects';
import { preferredLanesStateProviders, PREFERRED_LANES_ENTITY_REDUCERS_TOKEN } from './config';
import { preferredLanesNamespace } from './namespace';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(preferredLanesNamespace, PREFERRED_LANES_ENTITY_REDUCERS_TOKEN),
    EffectsModule.forFeature([CommonEntitiesEffects]),
  ],
  providers: [...preferredLanesStateProviders],
})
export class AsyncEntityPreferredLanesStoreModule {}
