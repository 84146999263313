import { BidHistory, BidSortTypes, BidStatus, Customer, ITabsState } from '@haulynx/types';
import { createFeatureSelector } from '@ngrx/store';
import { Record } from 'immutable';
import { combineAdaptersInitialState } from '../../shared/store/helpers/adapter';
import { ISearchState } from '../../shared/store/search/state';
import { bidAdapter, carriersLoadFeedAdapter, exclusiveLoadFeedAdapter, loadFeedAdapter } from './adapters';

export interface ILoadFeedState {
  loadFeedSearch: ISearchState;
  exclusiveLoadFeedSearch: ISearchState;
  carriersLoadFeedSearch: ISearchState;
  tabs: ITabsState;

  isLoading: boolean;

  isExpandedExclusive: boolean;

  isEldCarrier: boolean | null;

  bidStatuses: BidStatus[];
  isLoadingBidStatuses: boolean;
  bidHistory: { [key: string]: BidHistory[] };
  isLoadingBidHistory: { [key: string]: boolean };
  isLoadingBidCreate: boolean;
  isLoadingAcceptBid: { [key: string]: boolean };
  isLoadingAcceptCounterBid: boolean;
  sortBidsBy: { [key: string]: BidSortTypes };

  isLoadingCustomer: { [key: string]: boolean };
  customers: { [key: string]: Customer };
  isLoadingCounterOfferCreate: { [key: string]: boolean };
}

const initialLoadFeedState = {
  isLoading: false,
  isExpandedExclusive: true,
  isEldCarrier: null,
  bidStatuses: [],
  isLoadingBidStatuses: false,
  bidHistory: {},
  isLoadingBidHistory: {},
  isLoadingBidCreate: false,
  isLoadingAcceptBid: {},
  isLoadingAcceptCounterBid: false,
  sortBidsBy: {},
  isLoadingCustomer: {},
  customers: {},
  isLoadingCounterOfferCreate: {},
  ...combineAdaptersInitialState(loadFeedAdapter),
  ...combineAdaptersInitialState(exclusiveLoadFeedAdapter),
  ...combineAdaptersInitialState(carriersLoadFeedAdapter),
  ...combineAdaptersInitialState(bidAdapter),
};

export class LoadFeedState extends Record(initialLoadFeedState) implements ILoadFeedState {
  loadFeedSearch: ISearchState;
  exclusiveLoadFeedSearch: ISearchState;
  tabs: ITabsState;
  carriersLoadFeedSearch: ISearchState;
  bidSearch: ISearchState;

  isLoading: boolean;

  isExpandedExclusive: boolean;

  isEldCarrier: boolean | null;

  bidStatuses: BidStatus[];
  isLoadingBidStatuses: boolean;
  bidHistory: { [key: string]: BidHistory[] };
  isLoadingBidHistory: { [key: string]: boolean };
  isLoadingBidCreate: boolean;
  isLoadingAcceptBid: { [key: string]: boolean };
  isLoadingAcceptCounterBid: boolean;
  sortBidsBy: { [key: string]: BidSortTypes };

  isLoadingCustomer: { [key: string]: boolean };
  customers: { [key: string]: Customer };
  isLoadingCounterOfferCreate: { [key: string]: boolean };
}

export const getLoadFeedState = createFeatureSelector<LoadFeedState>('load-feeds');
