import { get } from 'lodash';
import { DispatchAction } from '../../shared/store/helpers/action';
import { FeatureFlagDashboardActionTypes } from './feature-flag-dashboard.actions';
import { FeatureFlagDashboardState, initialFeatureFlagDashboard } from './feature-flag-dashboard.state';

export function featureFlagDashboardReducer(
  state: FeatureFlagDashboardState = initialFeatureFlagDashboard,
  action: DispatchAction
): FeatureFlagDashboardState {
  switch (action.type) {
    case FeatureFlagDashboardActionTypes.FIND_USERS: {
      const { index } = action.payload;

      return {
        ...state,
        isLoadingUsers: { [index]: true },
      };
    }

    case FeatureFlagDashboardActionTypes.FIND_USERS_SUCCESS: {
      const { entities, index } = action.payload;

      return {
        ...state,
        isLoadingUsers: { [index]: false },
        users: { [index]: entities },
      };
    }

    case FeatureFlagDashboardActionTypes.FIND_USERS_ERROR: {
      const { index } = action.payload;

      return {
        ...state,
        isLoadingUsers: { [index]: false },
      };
    }

    case FeatureFlagDashboardActionTypes.CLEAR_SELECTED_FEATURE: {
      return {
        ...state,
        selected: null,
      };
    }

    case FeatureFlagDashboardActionTypes.FIND_CARRIERS: {
      const { index } = action.payload;

      return {
        ...state,
        isLoadingCarriers: { [index]: true },
      };
    }

    case FeatureFlagDashboardActionTypes.FIND_CARRIERS_SUCCESS: {
      const { entities, index } = action.payload;

      return {
        ...state,
        isLoadingCarriers: { [index]: false },
        carriers: { [index]: entities },
      };
    }

    case FeatureFlagDashboardActionTypes.FIND_CARRIERS_ERROR: {
      const { index } = action.payload;

      return {
        ...state,
        isLoadingCarriers: { [index]: false },
      };
    }

    case FeatureFlagDashboardActionTypes.FIND_ALL: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case FeatureFlagDashboardActionTypes.FIND_ALL_SUCCESS: {
      const { entities } = action.payload;

      return {
        ...state,
        isLoading: false,
        entities,
      };
    }

    case FeatureFlagDashboardActionTypes.FIND_ALL_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case FeatureFlagDashboardActionTypes.FIND: {
      const featureFlagName = get(action, 'payload.featureFlagName', null);

      return {
        ...state,
        isLoading: !featureFlagName,
        isLoadingDetails: !!featureFlagName,
      };
    }

    case FeatureFlagDashboardActionTypes.FIND_SUCCESS: {
      const { entities } = action.payload;

      return {
        ...state,
        isLoading: false,
        isLoadingDetails: false,
        entities,
      };
    }

    case FeatureFlagDashboardActionTypes.FIND_BY_NAME_SUCCESS: {
      const { entity } = action.payload;

      return {
        ...state,
        isLoading: false,
        isLoadingDetails: false,
        selected: entity,
      };
    }

    case FeatureFlagDashboardActionTypes.FIND_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case FeatureFlagDashboardActionTypes.CREATE:
    case FeatureFlagDashboardActionTypes.DELETE:
    case FeatureFlagDashboardActionTypes.UPDATE:
      return {
        ...state,
        isLoading: true,
      };

    case FeatureFlagDashboardActionTypes.CREATE_ERROR:
    case FeatureFlagDashboardActionTypes.DELETE_ERROR:
    case FeatureFlagDashboardActionTypes.UPDATE_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}
