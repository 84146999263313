import { Pipe, PipeTransform } from '@angular/core';
import { LoadsServiceLoadLocation } from '@haulynx/types';

@Pipe({ name: 'loadAddressPrivate' })
export class LoadAddressPrivatePipe implements PipeTransform {
  transform(location: LoadsServiceLoadLocation, isPrivate = true): string {
    const name = location?.address;
    const summaryAddress = location?.summaryAddress;
    if (isPrivate) return summaryAddress || '';
    // return the longer of the two
    return (name?.length || 0) > (summaryAddress?.length || 0) ? name || '' : summaryAddress || '';
  }
}
