import { MapsAPILoader } from '@agm/core';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Carrier, Device, LoadsServiceLoad, Truck, User } from '@haulynx/types';
import { get } from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from './user.service';
declare let google;

export const TRUCK_STATUS = ['OFF', 'IDLE', 'DRIVING', 'DRIVING'];

export interface MarkerLabel {
  color?: string;
  fontSize?: string;
  text: string;
}

export interface DriverMarker {
  lat: number;
  lng: number;
  lastUpdated?;
  label?: MarkerLabel;
  title?: string;
  info?;
  driver?;
  iconUrl?: string;
  type?: string;
  status?: string;
}

export interface LoadMarker {
  lat: number;
  lng: number;
  type: string;
  label?: MarkerLabel;
  title?: string;
  info?;
  driver?;
  mapIcon?;
  load?: LoadsServiceLoad;
}

@Injectable({ providedIn: 'root' })
export class MapService {
  public selectedDriver: string;
  private mapsAPIPromise: Promise<void>;
  private addressCache = Object.create({});
  private user: User;
  constructor(private userService: UserService, private mapsApiLoader: MapsAPILoader, private http: HttpClient) {
    this.selectedDriver = '';
    this.mapsAPIPromise = this.mapsApiLoader.load();
    this.userService.user.subscribe((data) => {
      this.user = data;
    });
  }

  buildDriverInfo(item: Truck | Device, load: LoadsServiceLoad): string {
    let info = '';
    // let driver: User;
    if (item.type === 'phone') {
      info += `<h5> Truck: ${get(item, 'unitId', '')} </h5> <br>`;
    }
    let billOfLadings: string;
    if (load) {
      if (!Array.isArray(load.locations)) {
        return 'No load location info available.';
      }
      billOfLadings = load.locations
        .filter((ll) => !!ll.billOfLading)
        .map((ll) => ll.billOfLading)
        .join(', ');
    }

    info += this.conditionalAddInfo(`<b>BOL's</b>: ${billOfLadings}`, billOfLadings);

    if (this.user && this.user.carrier) {
      const driverName = get(item, 'lastLocation.driverName', '');
      info += this.conditionalAddInfo('<b>Driver</b>: ' + driverName + '<br>', driverName);
    } else {
      const carrierName = get(item, 'carrier.name', '');
      info += this.conditionalAddInfo('<b>Carrier</b>: ' + carrierName + '<br>', item.carrier);
    }
    const driverStatus = get(item, 'lastLocation.driverStatus', '');
    const approximateAddress = get(item, 'lastLocation.approximateAddress', '');
    const lastLocationDate = get(item, 'lastLocation.date', '');

    info += this.conditionalAddInfo('<b>Driver Status</b>: ' + driverStatus + '<br>', driverStatus);
    if (item.type === 'phone') {
      info += '<b>Address</b>:  ' + approximateAddress + '<br>';

      // customer id info?

      info += '<b>Last Updated</b>: ' + lastLocationDate + '<br>';
    }

    return info;
  }

  getApproximateLocation(lat: number, lon: number): Observable<string> {
    const latLon = `${lat},${lon}`;
    if (this.addressCache.hasOwnProperty(latLon)) {
      return of(this.addressCache[latLon]);
    } else {
      return this.http.get(`/api/trucks/location?lat=${lat}&lon=${lon}`).pipe(
        map((result: { addressName?: string }) => {
          if (result && result.addressName) {
            this.addressCache[latLon] = result.addressName;
            return result.addressName as string;
          } else {
            return 'N/A';
          }
        })
      );
    }
  }

  getLatLngByQuery(query: string): Observable<Array<string | number>> {
    const $location = new BehaviorSubject(null);

    this.mapsAPIPromise
      .then(() => {
        const address = new google.maps.places.PlacesService(document.createElement('div'));
        address.textSearch({ query }, ([result]) => {
          const location = [result.geometry.location.lng(), result.geometry.location.lat()];

          $location.next(location);

          $location.complete();
        });
      })
      .catch(() => {
        $location.complete();
      });

    return $location;
  }

  public getMapsApiPromise(): Promise<void> {
    return this.mapsAPIPromise;
  }

  private conditionalAddInfo(html: string, value: string | Carrier): string {
    if (value) {
      return html;
    }
    return '';
  }
}
