export const radioTypes = {
  ALL: {
    label: 'All',
    action: 'all',
  },
  AVAILABLE: {
    label: 'Available',
    action: 'available',
  },
  UNAVAILABLE: {
    label: 'Unavailable',
    action: 'unavailable',
  },
  NO_TELEMATICS: {
    label: 'NO TELEMATICS',
    action: 'NO TELEMATICS',
  },
};
