import { lowerCase, startCase } from 'lodash';
import { OperationsPriority } from '../../../loads-service';
import { ISearchFilter, TextFilterType } from '../search-filter';
import { ISearchFilterType } from '../search-filter.types';

export class PriorityFilter implements ISearchFilter {
  name = 'Priority';
  type: ISearchFilterType = ISearchFilterType.TEXT;
  keys: TextFilterType;
  description = 'Filter loads by the load priority';
  transformValue = (val: number) => startCase(lowerCase(OperationsPriority[val]));

  constructor(defaultValues?: TextFilterType) {
    this.keys = new TextFilterType({
      value: '',
      optionsDataIndex: [
        {
          key: 'None',
          value: '0',
        },
        {
          key: 'Low',
          value: '1',
        },
        {
          key: 'Medium',
          value: '2',
        },
        {
          key: 'High',
          value: '3',
        },
      ],
      textFormName: 'minOperationsPriority',
      ...defaultValues,
    });
  }
}
