<section *ngIf="items.length" class="carrier-banner">
  <carousel [timer]="timer">
    <ng-container *ngFor="let item of items; let x = index">
      <ng-container *carouselItem>
        <div class="item" fxLayout="row" fxLayoutAlign="start center" fxFlex>
          <mat-icon *ngIf="items?.length == 1" class="icon" svgIcon="haulynx-priority"></mat-icon>
          <span *ngIf="items?.length > 1" class="circle">
            <span class="counter"> {{ x + 1 }}/{{ items?.length }}</span>
          </span>
          <span class="text"> {{ item?.text }} </span>
          <haulynx-white-outline-button
            fxLayoutAlign="end center"
            text="{{ item?.buttonText }}"
            [loading]="loading"
            (clicked)="onButtonClick(item?.buttonUrl)"
          ></haulynx-white-outline-button>
        </div>
      </ng-container>
    </ng-container>
  </carousel>
</section>
