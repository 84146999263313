import { ChangeDetectionStrategy, Component, Inject, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Bid, CounterOfferDialogInput, CounterOfferInput, LoadsServiceLoad } from '@haulynx/types';
import { BidHistoryDialogComponent } from '../bid-history-dialog/bid-history-dialog.component';

@Component({
  selector: 'app-bid-counter-offer-dialog',
  templateUrl: './bid-counter-offer-dialog.component.html',
  styleUrls: ['./bid-counter-offer-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BidCounterOfferDialogComponent {
  bid: Bid;
  load: LoadsServiceLoad;
  userTimeZone: string;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: { bid: Bid; load: LoadsServiceLoad; userTimeZone: string },
    @Optional() private dialogRef: MatDialogRef<BidCounterOfferDialogComponent>,
    private dialog: MatDialog
  ) {
    const { bid, load, userTimeZone } = data;

    this.bid = bid;
    this.load = load;
    this.userTimeZone = userTimeZone;
  }

  onOpenBidHistoryDialog(bid: Bid): void {
    this.dialog.open(BidHistoryDialogComponent, {
      data: { bid, load: this.load, userTimeZone: this.userTimeZone },
    });
  }

  closeDialog(counterOfferInput: CounterOfferDialogInput): void {
    this.dialogRef.close(counterOfferInput);
  }
}
