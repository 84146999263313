<div class="address-favorite">
  <div class="address-favorite__title">MY LOCATION</div>

  <data-table
    [data]="favoriteAddressModel.entities$ | async"
    [loading]="favoriteAddressModel.isLoading$ | async"
    [displayedColumns]="favoriteAddressModel.addressColumns$ | async"
    [showPagination]="false"
    [selection]="true"
    [multipleSelection]="false"
    [pageOptions]="[100]"
    [selected]="selected"
    [templateCellRef]="templateCell"
    (onSelect)="select($event)"
    data-test="favorite-locations-table"
  >
    <ng-template #templateCell let-row="row" let-column="column">
      <div *ngIf="column === 'icon'" class="icon">
        <mat-icon svgIcon="haulynx-location"></mat-icon>
      </div>
    </ng-template>
  </data-table>

  <div class="address-favorite__actions">
    <a mat-button class="address-favorite__actions--clear" (click)="actionClear()" data-test="clear-favorites">
      CLEAR
    </a>

    <a
      mat-button
      class="address-favorite__actions--select"
      (click)="actionSelect()"
      [disabled]="!selected"
      data-test="select-favorite"
    >
      SELECT
    </a>
  </div>
</div>
