import { createSelector } from '@ngrx/store';
import { activeLoadsAdapter } from './adapters';
import { getActiveLoadsState } from './active-loads-state.state';

const activeLoadsSearchState = createSelector(getActiveLoadsState, (state) => {
  return state.activeLoads;
});

const activeLoadsTabsState = createSelector(getActiveLoadsState, (state) => {
  return state.tabs;
});

export const activeLoadsSelectedLoadIdSelector = createSelector(getActiveLoadsState, (state) => {
  return state.selectedLoadId;
});

export const activeLoadsSearchSelector = activeLoadsAdapter.activeLoads.getSelectors(activeLoadsSearchState);

export const activeLoadsTabsSelector = activeLoadsAdapter.tabs.getSelectors(activeLoadsTabsState);

export const brokersSelectors = createSelector(getActiveLoadsState, (state) => {
  return state.brokers;
});
export const isLoadingBrokersSelectors = createSelector(getActiveLoadsState, (state) => {
  return state.isLoadingBrokers;
});
export const carriersSelectors = createSelector(getActiveLoadsState, (state) => {
  return state.carriers;
});
export const isLoadingCarriersSelectors = createSelector(getActiveLoadsState, (state) => {
  return state.isLoadingCarriers;
});
