<div class="milestone-content-wrapper">
  <div class="milestone-content-container">
    <app-milestone-item
      *ngFor="let milestone of milestones; let i = index"
      [user]="user"
      [currentUserType]="currentUserType"
      [milestone]="milestone"
      [load]="load"
      [milestoneIndex]="i"
      [collapseMilestone]="collapseMilestone"
      [badgeMilestones]="badgeMilestones"
      [savedCollapseMilestone]="savedCollapseMilestone"
      (clickEvent)="onClickEvent($event)"
    >
    </app-milestone-item>
  </div>
</div>
