<div class="confirm-book" *ngIf="carrierDot; else noCarrier">
  <h3 class="confirm-book__title">Confirm Load Assignment</h3>

  <p class="confirm-book__description">
    The carrier will receive a Rate Con and be informed via email that this load has been booked on their behalf.
  </p>

  <div class="confirm-book__row">
    <table>
      <tr>
        <td class="confirm-book__label">Carrier</td>
        <td class="confirm-book__value" data-test="carrier-name">{{ carrierName ? carrierName : 'No name' }}</td>
      </tr>
      <tr>
        <td class="confirm-book__label">Rate</td>
        <td class="confirm-book__value">{{ totalAmount | currency: 'USD':'symbol':'1.2-2' }}</td>
      </tr>
    </table>
  </div>

  <div class="confirm-book__action">
    <button
      class="hlx-button-command hlx-button-command--cancel hlx-button-command--border-white confirm-book__button"
      (click)="cancel()"
    >
      Go Back
    </button>
    <button type="submit" class="hlx-button-command hlx-button-command--book confirm-book__button" (click)="save()" data-test="confirm-booking">
      Confirm
    </button>
  </div>
</div>

<ng-template #noCarrier>
  <div class="no-carrier-dot">
    <p class="add-driver__description">Please select carrier to book a load</p>
    <button (click)="cancel()" class="hlx-button-command hlx-button-command--cancel hlx-button-command--border-white">
      Ok
    </button>
  </div>
</ng-template>
