import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@haulynx/modules';
import { SatDatepickerModule } from 'saturn-datepicker';
import { DropDownModule } from '../drop-down/drop-down.module';
import { SeparateDateInputsComponent } from './components/separate-date-inputs/separate-date-inputs.component';
import { DatepickerComponent } from './datepicker.component';

@NgModule({
  declarations: [DatepickerComponent, SeparateDateInputsComponent],
  imports: [CommonModule, SatDatepickerModule, ReactiveFormsModule, DropDownModule, MaterialModule],
  exports: [DatepickerComponent],
})
export class DatepickerModule {}
