import gql from 'graphql-tag';

export const GetBidHistory = gql`
  query GetBidHistory($bidId: StrictId!) {
    getBidHistory(bidId: $bidId) {
      id
      bidId
      event
      data {
        price
        status
        notes
      }
      bid {
        id
        price
        status
        carrierId
        carrier {
          id
          dot
        }
        loadId
        notes
        createdAt
        createdBy {
          name
        }
        updatedAt
        updatedBy {
          name
        }
      }
      createdAt
      createdBy {
        name
        carrier {
          id
        }
      }
    }
  }
`;
