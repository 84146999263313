import { Broker, User } from '..';
import { LoadsServiceLoad } from '../../loads-service';
import { SubscriptionAction } from '../../subscriptions';

export interface LoadActionEvent {
  action?: SubscriptionAction;
  timestamp?: number;
  user?: Partial<User>;
  load?: Partial<LoadsServiceLoad>;
  expiredAt?: number;
}
