import { ActionReducer, ActionReducerMap } from '@ngrx/store';
import { InjectionToken, Provider } from '@angular/core';
import { USER_PROVIDED_EFFECTS } from '@ngrx/effects';
import { IAsyncEntityState } from '@haulynx/types';
import { TrailerEntityService } from './trailer-entity.service';

export const TRAILER_ENTITY_REDUCERS_TOKEN = new InjectionToken<ActionReducerMap<IAsyncEntityState<unknown>>>(
  'trailer reducers'
);

function getTrailerEntityReducersFactory(
  trailerEntityService: TrailerEntityService
): ActionReducer<IAsyncEntityState<unknown>> {
  return trailerEntityService.getReducers();
}

const trailerEntityReducerProvider: Provider = {
  provide: TRAILER_ENTITY_REDUCERS_TOKEN,
  useFactory: getTrailerEntityReducersFactory,
  deps: [TrailerEntityService],
};

const trailerEntityEffectProvider: Provider = {
  provide: USER_PROVIDED_EFFECTS,
  multi: true,
  useValue: [TrailerEntityService],
};

export const trailerStateProviders: Provider[] = [trailerEntityReducerProvider, trailerEntityEffectProvider];
