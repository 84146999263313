import { Pipe, PipeTransform } from '@angular/core';
import { trim, head, toLower, startCase, replace } from 'lodash';

@Pipe({
  name: 'truckLocation',
})
export class TruckLocationPipe implements PipeTransform {
  transform(locationName: string): string {
    if (!locationName || typeof locationName !== 'string') return '';
    if (toLower(locationName) === 'any') {
      return locationName;
    }
    const cityRegex = /(, .*|^.*,)(?= [A-Z]{2}( |,|$))/i;
    const stateRegex = /( [A-Z]{2}(?=[, ]))(?=$|(,*))/i;
    const city = trim(replace(head(locationName.match(cityRegex)), ',', ''));
    const state = trim(head(locationName.match(stateRegex)));

    return `${city}, ${state}`;
  }
}
