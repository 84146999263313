import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({
  selector: '[underlineText]',
})
export class UnderlineTextDirective implements OnChanges {
  @Input('underlineText') isHovering = false;

  constructor(private el: ElementRef) {}

  ngOnChanges(): void {
    this.el.nativeElement.style.cursor = 'pointer';
    if (this.isHovering) {
      this.el.nativeElement.style.textDecoration = 'underline';
    } else {
      this.el.nativeElement.style.textDecoration = 'none';
    }
  }
}
