<form [formGroup]="dateForm" class="date-row">
  <mat-form-field
    class="app-form-field date-range-drop-down"
    appearance="fill"
    floatLabel="always"
    gdArea="jurisdiction"
  >
    <app-drop-down
      [data]="dateRangeOptions"
      displayLabel="label"
      key="value"
      formControlName="dateFilter"
      [placeholder]="'Date'"
    ></app-drop-down>
  </mat-form-field>

  <mat-form-field
    *ngIf="dateForm.get('dateFilter').value === dateRange.CUSTOM"
    class="app-form-field date-range-picker"
    appearance="fill"
    floatLabel="always"
  >
    <app-datetime-picker
      [selectionMode]="'range'"
      formControlName="customDate"
      [timeZone]="timeZone"
      [showTimeZone]="false"
    >
    </app-datetime-picker>
  </mat-form-field>
</form>
