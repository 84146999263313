import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ElementsModule } from '@haulynx/elements';
import { MaterialModule } from '@haulynx/modules';
import { SetupRmisDialogModule } from '../dialogs/setup-rmis-dialog/setup-rmis-dialog.module';
import { RmisAccountSetupStatusComponent } from './rmis-account-setup-status.component';

@NgModule({
  declarations: [RmisAccountSetupStatusComponent],
  imports: [CommonModule, MaterialModule, FlexLayoutModule, ElementsModule, SetupRmisDialogModule],
  exports: [RmisAccountSetupStatusComponent],
})
export class RmisAccountSetupStatusModule {}
