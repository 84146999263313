import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AnalyticsService,
  CreateBrokerBookedLoad,
  FireDatabaseService,
  GoogleAnalyticsService,
  LoadFeedActionsService,
  LocalStoreService,
  MomentService,
  NotificationsService,
  PostTruckService,
  TitleService,
  UserService,
  WindowRef,
} from '@haulynx/services';
import {
  ActiveLoadsModel,
  AppModel,
  CarrierDashboardModel,
  CarriersModel,
  CarriersTabsModel,
  GeocodingEntityService,
  LoadDetailsModel,
  LoadEntityService,
  PreferredLanesEntityService,
  UserEntityService,
} from '@haulynx/store';
import {
  Action,
  ActiveLoadFilter,
  AddressField,
  ANALYTICS_EVENT,
  BookStatus,
  buttonTypes,
  CapacityObject,
  CapacityObjectInput,
  Carrier,
  Category,
  DayOfWeek,
  EquipmentTypeOption,
  equipmentTypes,
  FeatureFlag,
  FFState,
  GeocodeParams,
  IColumns,
  IPostTruck,
  KeyValuePair,
  Label,
  LaneActionMenuOption,
  LaneForm,
  LaneRecommendation,
  LaneType,
  LoadIdentifierType,
  LoadLocationType,
  LoadRoute,
  LoadsServiceLoad,
  LoadsServiceLoadLocation,
  LoadsServiceLoadStatus,
  NeptuneLoad,
  PlaceInfo,
  PostTruckForm,
  QueryParams,
  RadiusOption,
  radiusOptions,
  Tab,
  User,
} from '@haulynx/types';
import {
  aliveWhile,
  calculateDistance,
  CarrierTabs,
  getLaneRoutes,
  getLoadsServiceLoadRoute,
  getNeptuneLoadsRoute,
  getTruckRoutes,
  getUniqueId,
  initRecommendedColumns,
  laneColumns,
  listToArray,
  LoadsFilter,
  MapboxApiCoordinates,
  openEmailClient,
  preferredLaneActionMenu,
  preferredLaneActionMenuIcons,
  truckColumns,
} from '@haulynx/utils';
import { List } from 'immutable';
import { flow, isEmpty, nth, reduce, startCase, toLower, uniqBy } from 'lodash';
import { Moment } from 'moment';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { CarrierActiveLoadDialogComponent } from '../carrier-active-load-dialog/carrier-active-load-dialog.component';
@Component({
  selector: 'haulynx-dashboard-carrier',
  templateUrl: './carrier-dashboard.component.html',
  styleUrls: ['./carrier-dashboard.component.scss'],
})
export class CarrierDashboardComponent implements OnInit, OnDestroy {
  @ViewChild('tabs', { static: false }) tabs: MatTabGroup;

  key = 'CARRIER_DASHBOARD_PAGE';
  alive = aliveWhile();
  user: User;
  carrier: Carrier = null;
  buttons$ = new BehaviorSubject([]);
  activeLoadFeatureFlags: FeatureFlag[] = [FeatureFlag.ACTIVE_LOAD_MANAGEMENT];
  bidFeatureFlag = FeatureFlag.LOAD_OFFER_BIDDING;
  preferredLanesFeatureFlag = FeatureFlag.PREFERRED_LANES;
  countryRestrictions = ['us', 'ca'];
  locationType = LoadLocationType;
  loadIdentifierType = LoadIdentifierType;
  defaultPagination = {
    page: 1,
    limit: 200,
  };
  laneTypes = LaneType;
  loadSearchColumns$ = new BehaviorSubject<IColumns[]>([]);
  recommendedColumns: IColumns[] = [];
  selectedTruckRecommends: IPostTruck;
  selectedLaneRecommends: unknown;
  loads$: Observable<LoadsServiceLoad[]>;
  loadsFilter$ = new BehaviorSubject<LoadsFilter>(null);
  selectedCalendarItem$ = new Subject<{ value: null | number }>();
  recommendedRoutes: LoadRoute[] = [];
  recommendedLaneRoutes;
  routes$ = new BehaviorSubject<LoadRoute[]>([]);
  truckRoutes$ = new BehaviorSubject<LoadRoute[]>([]);
  laneRoutes$ = new BehaviorSubject<LoadRoute[]>([]);
  dot: string;
  truckMinDate: Moment;
  defaultPickupDays: string[] = [];
  truckTimeAvailable: number;
  truckDefaultRadius: number;
  truckDefaultEquipment: string;
  laneDefaultPayload$ = new BehaviorSubject<unknown>(null);
  truckToHighlight: any;
  selectedTrucks$ = new BehaviorSubject<string[]>([]);
  selectedLanes$ = new BehaviorSubject<string[]>([]);
  userPageOptions = [50];
  preferredLocation: AddressField = null;
  truckToPopulate: IPostTruck;
  laneToPopulate: CapacityObject;
  radiusList: RadiusOption[] = radiusOptions;
  equipmentList: EquipmentTypeOption[] = equipmentTypes;
  loadingMapTruckIds = {};
  deletedLaneId;
  includeDistance = false;
  carrierDashboardV2 = FeatureFlag.CARRIER_DASHBOARD_V2;
  selectedRecommendTab = 'Posted Trucks';
  featureFlags: FFState;
  tableData$: Observable<CapacityObject[]>;
  tableDataLoading$: Observable<boolean>;
  deleting$: Observable<boolean>;
  preferredLaneActionMenu: KeyValuePair[] = preferredLaneActionMenu;
  preferredLaneActionMenuIcons: LaneActionMenuOption[] = preferredLaneActionMenuIcons;
  truckColumns: IColumns[];
  laneColumns: IColumns[];
  selectedTabIndex = 0;
  private mapsAPIPromise: Promise<void>;
  closeDropdown: { [key: string]: boolean } = {};

  constructor(
    private appModel: AppModel,
    private activatedRoute: ActivatedRoute,
    private analyticsService: AnalyticsService,
    private fireDatabaseService: FireDatabaseService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private loadFeedActionsService: LoadFeedActionsService,
    private momentService: MomentService,
    private notificationsService: NotificationsService,
    private postTruckService: PostTruckService,
    private titleService: TitleService,
    private userService: UserService,
    private userEntityService: UserEntityService,
    private windowRef: WindowRef,
    private geocodingEntityService: GeocodingEntityService,
    public router: Router,
    public location: Location,
    public dialog: MatDialog,
    public localStoreService: LocalStoreService,
    public snackBar: MatSnackBar,
    public preferredLanesEntityService: PreferredLanesEntityService,
    public activeLoadsModel: ActiveLoadsModel,
    public carriersModel: CarriersModel,
    public carriersTabsModel: CarriersTabsModel,
    public dashboardCarrierModel: CarrierDashboardModel,
    public loadEntityService: LoadEntityService,
    public loadDetailsModel: LoadDetailsModel,
    private createOfferService: CreateBrokerBookedLoad
  ) {
    this.truckColumns = truckColumns();
    this.laneColumns = laneColumns();
    this.userService.user.pipe(takeUntil(this.alive)).subscribe((data) => {
      this.user = data;
    });
    this.truckMinDate = this.momentService.getMoment();
    this.truckTimeAvailable = this.momentService.getTodayUnixMidnight();
    this.truckDefaultRadius = nth(this.radiusList, 3).id;
    this.truckDefaultEquipment = nth(this.equipmentList, 1).text;
    this.tableDataLoading$ = this.preferredLanesEntityService.getCarrierLanesManager.isSearching$;
    this.deleting$ = this.preferredLanesEntityService.deleteLaneManager.isLoading$;

    this.preferredLanesEntityService.createLaneManager.onSuccess$.pipe(takeUntil(this.alive)).subscribe((val) => {
      this.refresh();
    });

    this.preferredLanesEntityService.deleteLaneManager.onSuccess$.pipe(takeUntil(this.alive)).subscribe((val) => {
      this.refresh();
    });

    this.activatedRoute.queryParams.pipe(takeUntil(this.alive)).subscribe((queryParams) => {
      const { tab = null } = queryParams;
      this.selectedTabIndex = parseInt(tab, 10);
      if (isNaN(this.selectedTabIndex) || this.selectedTabIndex < 0) {
        this.selectedTabIndex = 0;
      }
      this.selectedTabIndex === 0
        ? (this.selectedRecommendTab = CarrierTabs.POSTED_TRUCKS)
        : (this.selectedRecommendTab = CarrierTabs.PREFERRED_LANES);
    });
  }

  ngOnInit(): void {
    this.initLoadSearchColumns();
    initRecommendedColumns(false, this);
    this.tableData$ = this.preferredLanesEntityService.getCarrierLanesManager.searchResults$.pipe(
      map((results) => {
        this.laneRoutes$.next(this.initLaneRoutes(results));
        return results;
      })
    );

    combineLatest([this.truckRoutes$, this.laneRoutes$])
      .pipe(takeUntil(this.alive))
      .subscribe(([trucks, lanes]: [LoadRoute[], LoadRoute[]]) => {
        const routing = this.selectedRecommendTab === CarrierTabs.POSTED_TRUCKS ? trucks : lanes;
        this.routes$.next(routing);
      });

    this.dashboardCarrierModel.loadingMapTruckIds$.pipe(takeUntil(this.alive)).subscribe((loadingMapTruckIds) => {
      this.loadingMapTruckIds = loadingMapTruckIds;
    });
    this.buttons$.next([buttonTypes.EMAIL_LOAD]);

    this.loads$ = combineLatest([
      this.loadsFilter$.pipe(debounceTime(100)),
      this.dashboardCarrierModel.recommendationSearch.entities$,
    ]).pipe(
      map(([loadsFilter, recommendedLoadEntities]: [LoadsFilter, List<NeptuneLoad>]) => {
        const { truckId = null } = loadsFilter;
        const recommendedLoads = listToArray(recommendedLoadEntities);
        const postedTruckFilters = [];

        // Todo: need to move in separate service this filter functions
        const filterByUniqByIdFilter = (loads: NeptuneLoad[]) => uniqBy(loads, 'id');
        const addressCleaner = (loads: NeptuneLoad[]) =>
          loads.map((load) => {
            return {
              ...load,
              loadPickupAddress: this.cleanLoadAddresses(load.loadPickupAddress),
              loadDropoffAddress: this.cleanLoadAddresses(load.loadDropoffAddress),
            };
          });
        postedTruckFilters.push(filterByUniqByIdFilter, addressCleaner);

        if (truckId) {
          const filterByTruckIdFilter = (loads) => loads.filter((load) => load.truckId === truckId);
          postedTruckFilters.push(filterByTruckIdFilter);
        }

        if (this.truckToHighlight) {
          const distancesMapper = (loads: NeptuneLoad[]) =>
            loads.map((load: NeptuneLoad) => {
              return {
                ...load,
                odh: this.getOdhDistance(this.truckToHighlight, load),
                ddh: this.getDdhDistance(this.truckToHighlight, load),
              };
            });
          postedTruckFilters.push(distancesMapper);
        }

        if (!postedTruckFilters.length) {
          // Todo: save in the state selected trucks and highlight truck
          this.selectedTrucks$.next([]);
        }

        return flow(postedTruckFilters)(recommendedLoads);
      })
    );

    this.dashboardCarrierModel.trucksSearch.entities$
      .pipe(takeUntil(this.alive))
      .subscribe((truckEntities: List<IPostTruck>) => {
        const truckRoutes = this.initTruckRoutes(listToArray(truckEntities));
        // Todo: save filter in the state
        initRecommendedColumns(false, this);
        this.truckRoutes$.next(truckRoutes);

        this.loadsFilter$.next({ truckId: null });
      });

    this.preferredLanesEntityService.getCarrierLanesManager.searchResults$
      .pipe(takeUntil(this.alive))
      .subscribe((laneRecs: CapacityObject[]) => {
        const loads: LoadsServiceLoad[] = [];
        laneRecs.forEach((rec) => {
          if (rec.recommendations?.result?.length) {
            rec.recommendations.result.forEach((recs: LaneRecommendation) => {
              loads.push(recs.loadsServiceLoad);
            });
          }
        });

        const routes = loads.map((load: LoadsServiceLoad) => {
          const pickUp = [`${load.locations[0].geometry.coordinates[0]},${load.locations[0].geometry.coordinates[1]}`];
          const dropOff = [
            `${load.locations[load.locations.length - 1].geometry.coordinates[0]},${
              load.locations[load.locations.length - 1].geometry.coordinates[1]
            }`,
          ];

          return {
            key: load.id,
            coordinates: [pickUp, dropOff].join(';'),
          };
        });

        this.loadDetailsModel.getLoadRoutes(routes);
      });

    combineLatest([
      this.loadDetailsModel.routes$,
      this.preferredLanesEntityService.getCarrierLanesManager.searchResults$,
    ])
      .pipe(takeUntil(this.alive))
      .subscribe(([lRoutes, laneRecs]: [unknown, CapacityObject[]]) => {
        const loads: LoadsServiceLoad[] = [];
        laneRecs.forEach((rec) => {
          if (rec.recommendations?.result?.length) {
            rec.recommendations.result.forEach((recs: LaneRecommendation) => {
              loads.push(recs.loadsServiceLoad);
            });
          }
        });

        const laneRoutes = [];
        loads.forEach((load) => {
          laneRoutes.push(getLoadsServiceLoadRoute(lRoutes, load));
        });

        this.recommendedLaneRoutes = laneRoutes;
      });

    combineLatest([this.dashboardCarrierModel.routes$, this.dashboardCarrierModel.recommendationSearch.entities$])
      .pipe(takeUntil(this.alive))
      .subscribe(([routes, truckRecs]: [unknown, List<NeptuneLoad>]) => {
        this.recommendedRoutes = this.initRecommendedRoutes(listToArray(truckRecs), routes);
      });

    this.dashboardCarrierModel.recommendationSearch.entities$
      .pipe(takeUntil(this.alive))
      .subscribe((entities: List<NeptuneLoad>) => {
        const loads = listToArray(entities);
        const routes = this.normalizeCoordinates(loads);

        this.dashboardCarrierModel.getLoadRoute(routes);
      });

    this.activatedRoute.params.pipe(takeUntil(this.alive)).subscribe((params) => {
      const { dot = null } = params;
      if (dot) {
        this.dot = dot;
        const tab = new Tab({
          id: dot,
          label: '',
          url: `view/${dot}`,
          selected: true,
          closable: true,
        });
        this.carriersTabsModel.addTabs([tab]);
        this.carriersTabsModel.selectTab(tab);
        this.dashboardCarrierModel.setStateKey(dot);
        this.dashboardCarrierModel.get({ dot, key: dot });
      }
    });

    this.dashboardCarrierModel.carrierForm.state$.pipe(takeUntil(this.alive)).subscribe((carrier: Carrier) => {
      if (carrier) {
        this.titleService.setTitle(`${startCase(toLower(carrier.name))} - Carriers - Haulynx`);
        this.getDefaultPreferredLocation(carrier);

        const dot = carrier.dot;
        const carrierTab = new Tab({
          id: dot,
          label: carrier.name,
          closable: true,
        });
        this.carriersTabsModel.updateTabs([carrierTab]);

        const query = { dot };
        this.dot = dot;
        this.carriersModel.updateTabs([carrierTab]);
        this.dashboardCarrierModel.searchCarrierKpiMetrics({ ...query, dateTime: '1546326000' });
        this.dashboardCarrierModel.searchTrucks({ query: { dot: dot }, limit: 100, page: 1 });
        this.dashboardCarrierModel.getInsuranceAndSafety(Number(dot));
        this.loadEntityService.getCarrierLoadsManager.dispatch({
          query: { dot, loadStatus: [LoadsServiceLoadStatus.FINALLED], searchCarrierLoads: true },
        });
        this.dashboardCarrierModel.getCarriersStatus(dot);

        this.searchActiveLoads({ carrier: carrier.dot });

        this.fireDatabaseService
          .getItem(`carrierRecommendationsUpdates/${carrier.dot}`)
          .pipe(takeUntil(this.alive))
          .subscribe(() => {
            this.dashboardCarrierModel.searchRecommended({ query });
          });

        this.loadEntityService.getLoadsMetaDataManager.dispatch({
          query: {
            searchParameters: {
              includeBookedAt: 'max',
              carrierNameOrDot: [carrier?.dot, carrier?.name],
              bookStatus: [BookStatus.BOOKED],
              showTestLoads: true,
            },
          },
        });
      }

      this.carrier = carrier;
    });

    this.geocodingEntityService.getGeocodeLocationManager.searchResults$
      .pipe(takeUntil(this.alive))
      .subscribe((results: PlaceInfo[]) => {
        if (results && results.length > 0) {
          const lat = results[0].lat;
          const lon = results[0].lon;
          const address = results[0].fullAddress;
          const timeZone = this.momentService.getTimeZoneByLocation(lat, lon);

          this.preferredLocation = { lat, lon, address, timeZone };
        }
      });
  }

  clickEvent(event: KeyValuePair[], row) {
    if (event[0].key === 'Delete') {
      this.removeTruck(row);
    } else {
      this.tabs.selectedIndex = 1;

      const payload = {
        location:
          row.location !== 'Any'
            ? {
                address: row.location,
                lat: row.locationLat,
                lon: row.locationLon,
              }
            : null,
        locationRadius: row.radius,
        preferredLocation:
          row.preferredLocation !== 'Any'
            ? {
                address: row.preferredLocation,
                lat: row.preferredLocationLat,
                lon: row.preferredLocationLon,
              }
            : null,
        preferredLocationRadius: row.preferredRadius,
        equipmentType: row.equipmentType,
      };
      this.laneDefaultPayload$.next(payload);
    }
  }

  closeOtherDropdowns(event, row) {
    this.closeDropdown = { [event]: true };
    const temp = this.closeDropdown[row.id];
  }

  removeTruck(row: { id: string }): void {
    const { id } = row;

    this.dashboardCarrierModel.removePostedTruck({ id });
    this.selectedTruckRecommends = null;
  }

  removeLane(event: Event, row): void {
    event.stopPropagation();
    if (row.type === LaneType.BROKER) {
      if (this.user.isCompanyAdmin || row.brokerId === this.user.usxId) {
        this.deletedLaneId = row.id;
        this.preferredLanesEntityService.deleteLaneManager.dispatch(this.key, { laneIds: [row.id] });
        this.selectedLaneRecommends = {};
      }
    }
  }

  onAction(action: string, row: { id: string }): void {
    if (action === buttonTypes.EMAIL_LOAD.action) {
      this.email(row);
    }
  }

  email(row: { id: string }): void {
    const { id } = row;
    this.loadEntityService.getLoadByIdManager.dispatch(id);
    this.loadEntityService.getLoadByIdManager
      .getEntityById(id)
      .pipe(withLatestFrom(this.loadEntityService.getLoadByIdManager.isLoadingEntities$), takeUntil(this.alive))
      .subscribe(([load, loading]) => {
        if (!loading[id] && !load) {
          this.notificationsService.error(`Oops, something went wrong. Unable to get load!`, `Load`);
          return null;
        }
        if (!loading[id] && load) {
          this.createOfferService
            .createOffer({
              dot: this.dot,
              price: load.paymentDetails.price,
              loadId: load.id,
              brokerEmail: this.user.email,
            })
            .pipe(
              takeUntil(this.alive),
              catchError((error) => {
                this.notificationsService.error(error, 'Error');
                return of(error);
              })
            )
            .subscribe(() => {
              const url = `mailto:${this.carrier.email}?${this.loadFeedActionsService.mailMessage(this.dot, load)}`;
              openEmailClient(url);
              this.analyticsService.logEvent(ANALYTICS_EVENT.BROKER_EMAIL_OFFER, {
                carrierDot: this.dot,
                load,
                carrierEmail: this.carrier.email,
              });
            });
        }
      });
  }

  getCurrentURL(row: { id: string }): string {
    const { id } = row;
    return `${location.origin}/dashboard/carriers/load/${id}`;
  }

  copyLinkSuccess(): void {
    this.googleAnalyticsService.eventEmitter(Category.REFERRAL, Action.CLICK, Label.COPY);
    this.snackBar.open('Successfully copied referral url', 'Okay', { duration: 3000 });
  }

  openLoadDetails(load: NeptuneLoad): void {
    const { id } = load;
    const queryParams = { carrierDashboard: this.carrier.dot };
    let url;

    if (this.featureFlags[FeatureFlag.LOAD_OVERVIEW]) {
      url = this.router.serializeUrl(this.router.createUrlTree([`loads/${id}/overview/info`], { queryParams }));
    } else {
      url = this.router.serializeUrl(this.router.createUrlTree([`loads/${id}`], { queryParams }));
    }

    this.localStoreService.set(load.id, this.router.url);
    this.windowRef.getNativeWindow().open(url, '_blank');
  }

  openLoadDetailsModal(load: LoadsServiceLoad, type: string): void {
    const { id } = load;
    this.dialog.open(CarrierActiveLoadDialogComponent, {
      data: {
        loadId: id,
        type,
      },
    });
  }

  changePagination(pagination: { limit: number; page: number }): void {
    this.activeLoadsModel.searchLoads(pagination);
  }

  selectTruck(event: { selection?: IPostTruck; row?: IPostTruck; refresh?: boolean }): void {
    const selection = event.selection ?? null;
    const truckId = (selection && selection.id) || null;
    if (selection && this.loadingMapTruckIds[selection.id]) {
      return;
    }

    this.truckToPopulate = selection;
    this.truckToHighlight = selection;

    this.loadsFilter$.next({
      truckId,
    });

    if (selection) {
      initRecommendedColumns(true, this);
      this.selectedTruckRecommends = selection;
      if (this.selectedTrucks$.value.includes(selection.id)) {
        this.selectedTrucks$.next([]);
      } else {
        this.selectedTrucks$.next([selection.id]);
      }
    } else {
      initRecommendedColumns(false, this);
      this.selectedTruckRecommends = selection;
      this.selectedTrucks$.next([]);
    }
  }

  selectLane(event: { row: CapacityObject; selection: CapacityObject }): void {
    const { selection = null } = event || {};

    initRecommendedColumns(false, this);
    this.laneToPopulate = selection;
    this.selectedLaneRecommends = selection || {};
    this.selectedCalendarItem$.next({ value: null });
    if (selection) {
      this.truckToHighlight = selection;
      if (this.selectedLanes$.value.includes(selection.id)) {
        this.selectedLanes$.next([]);
      } else {
        this.selectedLanes$.next([selection.id]);
      }
    } else {
      this.selectedLanes$.next([]);
    }
    this.selectedCalendarItem$.next({ value: -1 });
  }

  crmTabChange(event: MatTabChangeEvent): void {
    this.selectedRecommendTab = event.tab.textLabel;
    if (this.truckRoutes$.value || this.laneRoutes$.value) {
      const routeList =
        this.selectedRecommendTab === CarrierTabs.POSTED_TRUCKS ? this.truckRoutes$.value : this.laneRoutes$.value;
      this.routes$.next(routeList);
    }
    this.selectedTruckRecommends = null;
    this.selectedLaneRecommends = {};
    this.selectedLanes$.next([]);
    this.selectedTrucks$.next([]);
    this.analyticsService.logEvent(ANALYTICS_EVENT.CRM_TAB_SELECT, { selectedTab: event.tab.textLabel });
  }

  upperTabChange(event: MatTabChangeEvent): void {
    this.selectedRecommendTab = event.tab.textLabel;
    if (this.truckRoutes$.value || this.laneRoutes$.value) {
      const routeList =
        this.selectedRecommendTab === CarrierTabs.POSTED_TRUCKS ? this.truckRoutes$.value : this.laneRoutes$.value;
      this.routes$.next(routeList);
    }
    this.selectedTruckRecommends = null;
    this.selectedLaneRecommends = {};
    this.selectedLanes$.next([]);
    this.selectedTrucks$.next([]);
    this.analyticsService.logEvent(ANALYTICS_EVENT.CRM_TAB_SELECT, { selectedTab: event.tab.textLabel });

    this.location.go(this.router.url.split('?')[0], `tab=${event.index}`);
    this.selectedTabIndex = event.index;
  }

  highlightRoute(event: { hover; id: string }): void {
    const { hover, id } = event;

    if (hover) {
      const routeId = getUniqueId(id);

      let highlightedRoute;
      let route;

      if (this.selectedRecommendTab === CarrierTabs.POSTED_TRUCKS) {
        highlightedRoute = this.truckRoutes$.value;
        route = this.recommendedRoutes.find((road: LoadRoute) => road.id.includes(id));
        this.routes$.next([{ ...route, id: routeId }, ...highlightedRoute]);
      } else {
        highlightedRoute = this.laneRoutes$.value;
        route = this.recommendedLaneRoutes.find((road: LoadRoute[]) => road[0].id.includes(id));
        const temp: LoadRoute[] = [{ ...route[0], id: routeId }, ...highlightedRoute];
        this.routes$.next(temp);
      }
    } else {
      const removeHighlight =
        this.selectedRecommendTab === CarrierTabs.POSTED_TRUCKS ? this.truckRoutes$.value : this.laneRoutes$.value;
      this.routes$.next([...removeHighlight]);
    }
  }

  saveTruck(truck: PostTruckForm): void {
    if (truck) {
      const newTruck = this.postTruckService.toDto(truck, this.dot, this.user);
      this.dashboardCarrierModel.createPostedTruck(newTruck);
      this.selectedTruckRecommends = null;
    }
  }

  saveLane(lane: LaneForm): void {
    const form: CapacityObjectInput = {
      userId: this.user.id,
      type: LaneType.BROKER,
      carrierDot: this.dot,
      brokerId: this.user.usxId,
      active: true,
      origin: {
        point: {
          lat: lane.location?.lat.toString(),
          lon: lane.location?.lon.toString(),
        },
        radius: lane.locationRadius?.toString(),
        state: null,
        region: null,
        availability: {
          dayOfWeek: lane.pickupDays.map((days: { key: DayOfWeek }) => days.key),
          timestamp: null,
          date: null,
        },
        locationName: lane.location?.address,
      },
      destination: {
        point: {
          lat: lane.preferredLocation?.lat.toString(),
          lon: lane.preferredLocation?.lon.toString(),
        },
        radius: lane.preferredLocationRadius?.toString(),
        state: null,
        region: null,
        availability: {
          dayOfWeek: [],
          timestamp: null,
          date: null,
        },
        locationName: lane.preferredLocation?.address,
      },
      equipmentType: lane.equipmentType,
      cost: { min: lane.price?.toString(), max: null },
      minimumStopCharge: null,
      rpm: null,
      count: '1',
      expireTimestamp: null,
      allowStops: false,
      hazmat: false,
      showLoadNotifications: false,
    };
    if (lane) {
      this.preferredLanesEntityService.createLaneManager.dispatch('', form);
    }
    this.selectedTruckRecommends = null;
  }

  appointmentOnLoads(locations: LoadsServiceLoadLocation): boolean {
    let appts = false;
    if (locations && !!locations.appointmentSet) {
      appts = true;
    }
    return appts;
  }

  ngOnDestroy(): void {
    this.alive.destroy();
    let query: QueryParams = null;
    query = { ...query, forceSearch: true, carrier: null };
    this.activeLoadsModel.searchLoads({ query, ...this.defaultPagination });
    this.titleService.resetTitle();
  }

  private normalizeCoordinates(loads: NeptuneLoad[]): MapboxApiCoordinates[] {
    return loads.map((load: NeptuneLoad) => {
      const { loadPickupLon, loadPickupLat, loadDropoffLon, loadDropoffLat } = load;

      const pickUp = [`${loadPickupLon},${loadPickupLat}`];
      const dropOff = [`${loadDropoffLon},${loadDropoffLat}`];

      return {
        key: load.id,
        coordinates: [pickUp, dropOff].join(';'),
      };
    });
  }

  private searchActiveLoads(query: ActiveLoadFilter): void {
    const pagination = {
      page: 1,
      limit: 20,
    };
    this.activeLoadsModel.searchLoads({ query, ...pagination });
  }

  private getDefaultPreferredLocation(carrier: Carrier): void {
    const { addressCity = null, addressState = null, addressCountry = null } = carrier;

    this.preferredLocation = null;

    if (addressCity && addressState && addressCountry) {
      const query = `${addressCity}, ${addressState}, ${addressCountry}`;
      this.geocodingEntityService.getGeocodeLocationManager.dispatch({ query: { search: query } as GeocodeParams });
    }
  }

  private getOdhDistance(truck: IPostTruck, load: NeptuneLoad): string | number {
    const { locationLon, locationLat } = truck;
    const { loadPickupLon, loadPickupLat } = load;
    const odhOptions = {
      fromLon: Number(locationLon),
      fromLat: Number(locationLat),
      toLon: Number(loadPickupLon),
      toLat: Number(loadPickupLat),
      suffix: 'mi',
    };

    return calculateDistance(odhOptions);
  }

  private getDdhDistance(truck: IPostTruck, load: NeptuneLoad): string | number {
    const { preferredLocationLon, preferredLocationLat } = truck;
    const { loadDropoffLon, loadDropoffLat } = load;
    const ddhOptions = {
      fromLon: Number(preferredLocationLon),
      fromLat: Number(preferredLocationLat),
      toLon: Number(loadDropoffLon),
      toLat: Number(loadDropoffLat),
      suffix: 'mi',
    };

    return calculateDistance(ddhOptions);
  }

  private initTruckRoutes(trucks: IPostTruck[]): LoadRoute[] {
    return reduce(
      trucks,
      (result: IPostTruck[], truck: IPostTruck) => {
        const route = getTruckRoutes(truck);

        return isEmpty(route) ? result : [...result, ...route];
      },
      []
    ) as unknown as LoadRoute[];
  }

  private initLaneRoutes(lanes: CapacityObject[]): LoadRoute[] {
    return reduce(
      lanes,
      (result: CapacityObject[], lane: CapacityObject) => {
        const route = getLaneRoutes(lane);

        return isEmpty(route) ? result : [...result, ...route];
      },
      []
    ) as unknown as LoadRoute[];
  }

  private initRecommendedRoutes(recommendedLoads: NeptuneLoad[], routes: unknown): LoadRoute[] {
    return reduce(
      recommendedLoads,
      (result, load: NeptuneLoad) => {
        const route = getNeptuneLoadsRoute(routes, load);

        return isEmpty(route) ? result : [...result, ...route];
      },
      []
    );
  }

  private cleanLoadAddresses(address: string): string {
    if ((address?.match(/,/g) || []).length === 3) {
      address = address.replace(',', '');
    }
    return address;
  }

  private refresh() {
    this.preferredLanesEntityService.getCarrierLanesManager.dispatch({
      query: this.dot,
      pageAndSort: {},
    });
  }

  private initLoadSearchColumns() {
    combineLatest([this.appModel.user$, this.appModel.isValidCarrier$, this.userEntityService.featureFlags$])
      .pipe(
        map(([user, isValidCarrier, features]: [User, boolean, FFState]) => {
          const columns: IColumns[] = [];
          this.featureFlags = features;

          columns.push({
            label: 'Origin City',
            dataIndex: 'pickupLocation',
            isCustomCell: true,
            sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
          });

          columns.push({
            label: 'State',
            dataIndex: 'pickupState',
            isCustomCell: true,
            sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
          });

          columns.push({
            label: 'Destination City',
            dataIndex: 'deliveryLocation',
            isCustomCell: true,
            sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
          });

          columns.push({
            label: 'State',
            dataIndex: 'deliveryState',
            isCustomCell: true,
            sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
          });

          columns.push({
            label: 'Equipment',
            dataIndex: 'equipmentType',
            isCustomCell: true,
            isSortable: true,
            sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
          });

          columns.push({
            label: 'Pick-Up Date',
            dataIndex: 'pickupTimestamp',
            isCustomCell: true,
            sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
            initiallySortedBy: 'asc',
          });

          columns.push({
            label: 'Delivery Date',
            dataIndex: 'dropoffTimestamp',
            isCustomCell: true,
            sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
          });

          if (user && user.broker) {
            columns.push({
              label: 'Customer',
              dataIndex: 'customer',
              isCustomCell: true,
              sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
            });
          }

          if (user && user.carrier) {
            columns.push({
              label: 'RPM',
              dataIndex: 'rpm',
              isCustomCell: true,
              sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
            });
          }

          columns.push({
            label: 'TMW #',
            dataIndex: 'tmwNumber',
            isSortable: true,
            isCustomCell: true,
          });

          columns.push({
            label: 'Loaded Miles',
            dataIndex: 'distance',
            isCustomCell: true,
          });

          columns.push({
            label: 'Order #',
            dataIndex: 'order',
            isSortable: true,
            isCustomCell: true,
            sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
          });

          if (user && user.broker) {
            columns.push({
              label: 'Revenue',
              dataIndex: 'revenue',
              isCustomCell: true,
              isSortable: true,
              sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
            });
          }
          if (user && (isValidCarrier || user.broker)) {
            columns.push({
              label: 'Price',
              dataIndex: 'price',
              isCustomCell: true,
              isSortable: true,
              sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
            });
          }

          if (user && user.broker && features && features[FeatureFlag.LOAD_OFFER_BIDDING]) {
            columns.push({
              label: 'Bids',
              dataIndex: 'bidDetails',
              isCustomCell: true,
              isSortable: true,
              sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
            });
          }

          if (user && user.carrier) {
            columns.push({
              label: 'Weight',
              dataIndex: 'weight',
              isCustomCell: true,
              sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
            });
          }

          if (user && (user.carrier || user.broker)) {
            columns.push({
              label: '',
              dataIndex: 'options',
              isCustomCell: true,
              cls: 'options',
              isSortable: false,
            });
          }

          this.loadSearchColumns$.next(columns);
        }),
        takeUntil(this.alive)
      )
      .subscribe();
  }
}
