<div fxLayout="row" fxLayoutAlign="center center" fxFlexFill>
  <div fxLayout="row" fxLayoutAlign="center center" fxFlex.gt-sm="840px" fxFlex.lt-md="95" class="dot-signup-container">
    <div fxLayout="column" fxLayoutAlign="center center" fxFlexFill>
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayout.xs="column"
        fxFlexFill
        fxLayoutGap="32px"
        class="welcome"
      >
        <span>Welcome To</span>
        <img src="assets/images/Haulynx-Powered-by-XT-White.png" alt="Haulynx" />
      </div>

      <div fxLayout="row" fxLayoutAlign="start center" fxFlexFill class="intro-text">One more thing</div>

      <mat-card fxFlexFill class="card">
        <form [formGroup]="setLaneForm" (ngSubmit)="setLanes()">
          <div class="give-us-a-lane">Give us one lane where you struggle to find consistent freight</div>

          <div class="whats-available">
            We'll show you what's available right now and proactively find more freight for you
          </div>

          <div class="inputs">
            <div fxLayout="row" fxLayoutAlign.gt-sm="start center" fxLayout.lt-md="column" fxLayoutGap.gt-sm="24px">
              <mat-form-field class="app-form-field" appearance="fill" floatLabel="always" fxFlex="48">
                <mat-label> Origin </mat-label>
                <app-address-field [showOnlyCities]="true" formControlName="origin" placeholder="Phoenix, AZ">
                </app-address-field>
              </mat-form-field>

              <button mat-icon-button (click)="switchLocations()" fxFlex="4" type="button">
                <mat-icon svgIcon="haulynx-swap"></mat-icon>
              </button>

              <mat-form-field class="app-form-field" appearance="fill" floatLabel="always" fxFlex="48">
                <mat-label> Destination </mat-label>
                <app-address-field
                  [showOnlyCities]="true"
                  formControlName="destination"
                  placeholder="Enter Destination"
                >
                </app-address-field>
              </mat-form-field>
            </div>
          </div>

          <div class="submit">
            <button
              mat-raised-button
              color="accent"
              fxLayout="row"
              fxLayoutAlign="center center"
              fxFlexFill
              [disabled]="setLaneForm.invalid || submitting"
              type="submit"
            >
              <span *ngIf="!submitting">Submit</span>
              <mat-spinner *ngIf="submitting" [diameter]="24" [strokeWidth]="4"></mat-spinner>
            </button>
          </div>
        </form>
      </mat-card>
    </div>
  </div>
</div>
