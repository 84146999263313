import { DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { User } from '@haulynx/types';
import { LoadsServiceLoad, LoadsServiceLoadLocation } from '@haulynx/types';
import { AddressPosition, getLoadsServiceLoadPickUpDate, getLocationCityAndState, splitAddress } from '@haulynx/utils';
import { get, head, last, toLower, trim } from 'lodash';
import moment from 'moment';
import { MomentService } from '../../app-services/generic/moment/moment.service';
import { EnvironmentService } from '../../environment/environment.service';
import { UserService } from '../../shared-services/user.service';

@Injectable({ providedIn: 'root' })
export class LoadFeedActionsService {
  private user: User;
  constructor(
    private userService: UserService,
    private environmentService: EnvironmentService,
    private momentService: MomentService,
    private decimalPipe: DecimalPipe
  ) {
    this.userService.user.subscribe((data) => {
      this.user = data;
    });
  }

  fullLoadFieldSort(load: LoadsServiceLoad, column: string) {
    switch (column) {
      case 'pickupLocation':
        return get(head(load.locations), 'address', null);
      case 'pickupState':
        const origin = get(head(load.locations), 'address', null);

        return splitAddress(origin, AddressPosition.STATE);
      case 'deliveryLocation':
        return get(last(load.locations), 'address', null);
      case 'deliveryState':
        const destination = get(last(load.locations), 'address', null);

        return splitAddress(destination, AddressPosition.STATE);
      case 'equipmentType':
        return get(load, 'providerDetails.equipmentType', null);
      case 'customer':
        return get(load, 'customer.address1', null);
      case 'revenue':
        const revenue = get(load, 'paymentDetails.revenue', 0);

        return Number(revenue);
      case 'rpm':
        const rpm = get(load, 'paymentDetails.ratePerMile', null);
        return rpm;
      case 'weight':
        const loadWeight = get(load, 'providerDetails.weightLbs', 0);

        return Number(loadWeight);

      case 'pickupTimestamp':
        const pickupTimestamp = get(head(load.locations), 'appointmentStart', null);

        return pickupTimestamp > 1000000000000 ? pickupTimestamp : pickupTimestamp * 1000;

      case 'dropoffTimestamp':
        const dropoffTimestamp = get(last(load.locations), 'appointmentEnd', null);

        return dropoffTimestamp > 1000000000000 ? dropoffTimestamp : dropoffTimestamp * 1000;

      case 'order':
        return get(head(load.locations), 'billOfLading', '').trim();

      case 'bidDetails':
        return load.bidDetails
          ? load.bidDetails.lowestOffer
            ? load.bidDetails.lowestOffer
            : load.bidDetails.lowestBid
            ? load.bidDetails.lowestBid.price
            : 0
          : 0;

      default:
        return '';
    }
  }

  customSort(load: LoadsServiceLoad, column: string): string | number | unknown {
    switch (column) {
      case 'pickupLocation':
      case 'deliveryLocation':
      case 'weight':
        return this.sortByContent(load, column);
      case 'rpm':
        return this.sortByRatePerMile(load, column);
      case 'pickUpDate':
        return this.sortByPickUpDate(load, column);

      default:
        return '';
    }
  }

  sortByContent(load: LoadsServiceLoad, column: string): string {
    return load[column] && load[column]['pickupLocation'] ? load[column]['pickupLocation'] : '';
  }

  sortByRatePerMile(load: LoadsServiceLoad, column: string): unknown {
    return load[column] && load[column]['rpm'] ? load[column]['rpm'] : '';
  }

  sortByPickUpDate(load: LoadsServiceLoad, column: string): unknown {
    if (!load['pickupDate']) {
      const pickUpDate = +getLoadsServiceLoadPickUpDate(load);

      return pickUpDate ? moment.unix(pickUpDate) : null;
    } else {
      return load['pickupDate'] ? moment.unix(new Date(load['pickupDate']).getTime()) : null;
    }
  }

  fullCarrierFieldSort(carrierMatches, column: string): string {
    switch (column) {
      case 'carrier':
        return toLower(trim(carrierMatches.name));
      case 'owners':
        const owner = toLower(trim(carrierMatches.carrierOwner));

        return owner === 'unknown' ? 'none' : owner;
      default:
        return '';
    }
  }

  mailMessage(carrierDot: string, load: LoadsServiceLoad) {
    const pickUpLocation: LoadsServiceLoadLocation = head(get(load, 'loadLocations', get(load, 'locations')));
    const dropOffLocation: LoadsServiceLoadLocation = last(get(load, 'loadLocations', get(load, 'locations')));
    const ratePerMile = get(load, 'ratePerMile', get(load, 'paymentDetails.ratePerMile', null))
      ? `${get(load, 'ratePerMile', get(load, 'paymentDetails.ratePerMile', null))} USD`
      : 'unknown';
    const subject = encodeURIComponent(`View Your U.S. Xpress Load Offer`);
    /**
     * Replace email link sent from a pilot user to the carrier with the prod url.
     */
    const hostName: string = this.environmentService.getInvitationHostName();
    const body = encodeURIComponent(`
    On behalf of ${this.user.name} at US Xpress, please see load offer(s) below:

    Pickup location: ${getLocationCityAndState(get(pickUpLocation, 'name', get(pickUpLocation, 'address', null)))}
    Pickup time: ${this.momentService.getDateFormat(
      Number(get(pickUpLocation, 'timestamp', get(pickUpLocation, 'appointmentStart', null))),
      'MM-DD-YYYY hh:mm a',
      pickUpLocation.timezone
    )}

    DropOff location: ${getLocationCityAndState(get(dropOffLocation, 'name', get(dropOffLocation, 'address', null)))}
    DropOff time: ${this.momentService.getDateFormat(
      Number(get(dropOffLocation, 'timestamp', get(dropOffLocation, 'appointmentStart', null))),
      'MM-DD-YYYY hh:mm a',
      dropOffLocation.timezone
    )}

    Price: $${this.decimalPipe.transform(get(load, 'price', get(load, 'paymentDetails.price', null)), '1.2-2')}
    Rate per mile: ${ratePerMile}

    Book the load now: ${hostName}/load-details/shared/${load.id}?carrierId=${carrierDot}
    `);

    return [`subject=${subject}`, `body=${body}`].join('&');
  }
}
