<div class="load-quick-location">
  <app-spinner [active]="isLoading" [fullCover]="true"></app-spinner>
  <div class="load-quick-location__title customer">
    {{ location?.customerId }}
  </div>

  <div class="load-quick-location__title address">
    {{ location?.name }}
  </div>

  <form class="form" [formGroup]="form">
    <div class="load-quick-location__controls">
      <mat-form-field class="app-form-field datetime-picker" floatLabel="always">
        <mat-label>
          {{ location.locationType === 'pickup' ? 'Facility Entry' : 'Delivery Entry' }}
        </mat-label>

        <app-datetime-picker
          #datetimepicker
          class="app-form-field"
          formControlName="arrivalTime"
          [timeZone]="timezone"
          [minDate]="getMinEntryDate()"
          [maxDate]="getMaxEntryDate()"
          [errors]="customEntranceError()"
        >
        </app-datetime-picker>
      </mat-form-field>
      <button
        class="hlx-button"
        [disabled]="form.get('arrivalTime').errors"
        [ngClass]="{ 'hlx-button--active': !location?.arrivalTime }"
        (click)="save($event, 'arrivalTime', form.value)"
        [disabled]="form.get('arrivalTime').errors"
      >
        Save
      </button>
    </div>

    <div class="load-quick-location__controls">
      <mat-form-field class="app-form-field datetime-picker" floatLabel="always">
        <mat-label>
          {{ location.locationType === 'pickup' ? 'Shipper Exit' : 'Exit' }}
        </mat-label>

        <app-datetime-picker
          #datetimepicker
          class="app-form-field"
          formControlName="departureTime"
          [timeZone]="timezone"
          [minDate]="form.get('arrivalTime').value"
          [maxDate]="now"
          [errors]="customExitError()"
        >
        </app-datetime-picker>
      </mat-form-field>

      <button
        class="hlx-button"
        [disabled]="form.get('arrivalTime').errors || form.get('departureTime').errors"
        [ngClass]="{ 'hlx-button--active': !location?.completed }"
        (click)="save($event, 'departureTime', form.value)"
        [disabled]="
          !location.arrivalTime ||
          form.errors ||
          form.get('arrivalTime').errors ||
          form.get('departureTime').errors ||
          !bookedLoad
        "
      >
        Save
      </button>
    </div>

    <div class="load-quick-location__controls--notes">
      <label>All Notes</label>
      <div class="info">
        {{ location?.specialNotes }}
      </div>
    </div>
  </form>
</div>
