<div class="swap-trailer" *ngIf="pageNum === 0; else page2">
  <div class="swap-trailer__header">
    <h1>Swap Trailer - Step 1 of 2</h1>
    <p>Fill out the according fields to swap trailers</p>
  </div>

  <div class="swap-trailer__table">
    <div class="swap-trailer__table__header">
      <h1>Update spotted location of Assigned Trailer</h1>
      <p *ngIf="errorMsg" class="error">
        {{ errorMsg }}
      </p>
    </div>
    <app-advanced-search
      class="swap-trailer__table__searchbar"
      [filters]="filters"
      [placeholder]="'Search for where the trailer is spotted'"
      [recentSearches]="[]"
      [displayRecentSearches]="false"
      [shouldResetTextArrayForm]="shouldResetTextArrayForm"
      (submitSearch)="onSubmitSearch($event, searchQuery)"
      (selectedFilterChange)="onSelectedFilterChange($event)"
      data-test="app-advanced-search"
    >
    </app-advanced-search>
    <div class="data-tables" #parent>
      <data-table-v2
        [data]="tableData$ | async"
        [tableConfig]="configOptions"
        [loading]="dataLoading$ | async"
        [emptyMessage]="'No Customers Found'"
        [displayedColumns]="columns"
        [templateCellRef]="templateRef"
        [parentToTable]="parent"
        [selectedFilters]="searchViewFilters$ | async"
        [selectedRows]="selectedRows$ | async"
        [isSearchView]="false"
        [entitiesLoading]="dataLoading$ | async"
        [selectionMode]="null"
        [scrollHeight]="'500px'"
        (rowSelect)="onRowSelect($event)"
      >
        <ng-template
          #templateRef
          let-row="row"
          let-column="column"
          let-hovering="isHovering"
          let-width="columnWidth"
          let-index="rowIndex"
          let-count="rowCount"
        >
          <td [ngStyle]="{ width: width }" *ngIf="column.field === 'company'">
            <div class="data md">#{{ row.company }}</div>
          </td>
          <td [ngStyle]="{ width: width }" *ngIf="column.field === 'name'">
            <div class="data md">
              {{ row?.name }}
            </div>
          </td>
          <td [ngStyle]="{ width: width }" *ngIf="column.field === 'phoneNumber'">
            <div class="data telephone lg" (click)="onCall(row.telephone)">
              {{ row?.telephone }}
            </div>
          </td>
          <td [ngStyle]="{ width: width }" *ngIf="column.field === 'userType'">
            <div class="data sm">
              {{ row?.isShipper ? 'Shipper' : row?.isCosignee ? 'Cosignee' : '' }}
            </div>
          </td>
          <td [ngStyle]="{ width: width }" class="action" *ngIf="column.field === 'address'">
            <div class="data md">
              {{ (row?.address1 || row?.address2) + ', ' + row?.city + ', ' + row?.state }}
            </div>
          </td>
          <td [ngStyle]="{ width: width }" class="action" *ngIf="column.field === 'zipcode'">
            <div class="data lg">
              {{ row?.zipCode }}
            </div>
          </td>
        </ng-template>
      </data-table-v2>
    </div>
  </div>
  <div class="navigation-buttons">
    <button mat-raised-button (click)="close()" class="back">Close</button>
    <button mat-raised-button [disabled]="selectedRows$.value.length === 0" (click)="nextPage()" class="forward">
      Next
    </button>
  </div>
</div>

<ng-template #page2>
  <div class="swap-trailer">
    <div class="swap-trailer__header">
      <h1>Swap Trailer - Step 2 of 2</h1>
      <p>Fill out the according fields to swap trailers</p>
    </div>

    <div class="swap-trailer__form">
      <div class="swap-trailer__form__header">
        <h1>Find New Trailer to Assign</h1>
      </div>

      <form [formGroup]="mainForm">
        <mat-form-field class="app-dark-field trailer-owner" appearance="fill" floatLabel="always">
          <label class="label_trailer-owner"> Trailer Owner </label>
          <app-drop-down
            #trailerOwner
            class="form-control"
            placeholder="Select Trailer Owner"
            formControlName="trailerOwner"
            displayLabel="label"
            key="value"
            [data]="trailerOwners"
            [changeableData]="true"
          >
          </app-drop-down>
        </mat-form-field>
        <mat-form-field class="app-dark-field trailer" appearance="fill" floatLabel="always">
          <label class="label_trailer"> Trailer </label>
          <app-truck-drop-down
            #trailerList
            class="form-control"
            placeholder="Add Trailer"
            formControlName="trailerId"
            displayLabel="trailerNumber"
            [data]="selectedTrailers$ | async"
            [key]="mainForm?.controls['trailerOwner']?.value === trailerOwnerType.USXI ? 'trailerNumber' : 'id'"
            [changeableData]="true"
            [isLoading]="isLoadingTrailers"
            [entityType]="entityTypes.TRAILERS"
            [trailerOwner]="mainForm?.controls['trailerOwner']?.value"
            [cypressName]="'trailer-drop-down'"
          >
          </app-truck-drop-down>
        </mat-form-field>
      </form>
    </div>

    <div class="navigation-buttons">
      <mat-spinner
        [diameter]="32"
        *ngIf="trailerEntityService.bookUSXITrailerManager.isLoading$ | async"
        mode="indeterminate"
        [attr.data-test]="'action-button-spinner'"
      ></mat-spinner>
      <button mat-raised-button (click)="prevPage()" class="back">Go Back</button>
      <button
        mat-raised-button
        [disabled]="mainForm.get('trailerId').value === ''"
        (click)="onSubmit()"
        class="forward"
      >
        Submit
      </button>
    </div>
  </div>
</ng-template>
