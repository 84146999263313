import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuItem, loadActiveTabsConfig } from '@haulynx/types';
import { LoadActiveTabsModel } from '@haulynx/store';
@Component({
  selector: 'app-hover-drop-down',
  templateUrl: './hover-drop-down.component.html',
  styleUrls: ['./hover-drop-down.component.scss'],
})
export class HoverDropDownComponent implements OnInit {
  @Input() menuItems: MenuItem[];
  @Output() changeIsOpened = new EventEmitter<boolean>();
  @Output() navClick = new EventEmitter<void>();

  isVisible = false;

  constructor(private loadActiveTabsModel: LoadActiveTabsModel) {}

  ngOnInit(): void {}

  hideMenu(): void {
    this.isVisible = false;
    this.changeIsOpened.emit(false);
  }

  private onSelected(): void {
    this.isVisible = false;
    this.navClick.emit();
  }

  showMenu(): void {
    this.isVisible = true;
    this.changeIsOpened.emit(true);
  }

  /**
   * handleSelect hides menu, emits click, and sets loadActiveTabsModel.
   * @param item
   */
  handleSelect(subItem): void {
    this.hideMenu();
    this.onSelected();
    if (subItem.name === 'Active Loads') {
      this.loadActiveTabsModel.selectTab(loadActiveTabsConfig.SEARCH);
    }
  }
}
