import gql from 'graphql-tag';

export const GetCarrierUSXData = gql`
  query GetCarrierUSXData($dot: StrictId!) {
    getCarrierUSXData(dot: $dot) {
      carrierId
      carrierStatus
    }
  }
`;
