import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ISearchFilter, ISearchFilterType, searchFilterModels } from '@haulynx/types';
import { keyBy } from 'lodash';

@Component({
  selector: 'app-search-input-dropdown',
  templateUrl: './search-input-dropdown.component.html',
  styleUrls: ['./search-input-dropdown.component.scss'],
})
export class SearchInputDropdownComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('wrapper', { static: false }) wrapper: ElementRef;

  @Input() filters: ISearchFilter[];
  @Input() mainInputSearchValue = '';
  @Input() selectedFilters: ISearchFilter[];

  @Output() filterSelected = new EventEmitter<ISearchFilter>();
  @Output() mouseFocus: EventEmitter<boolean> = new EventEmitter<boolean>();

  indexOfFocus = 0;
  displayFilters: ISearchFilter[];
  isMouseFocus = false;
  shouldCheckClicks = true;

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent): void {
    if (event.key === 'ArrowDown' && this.indexOfFocus < this.displayFilters.length - 1) {
      this.indexOfFocus += 1;
      this.isMouseFocus = false;
    } else if (event.key === 'ArrowUp' && this.indexOfFocus > 0) {
      this.indexOfFocus -= 1;
      this.isMouseFocus = false;
    } else if (event.key === 'Enter') {
      if (this.displayFilters.length > 0) {
        const curFilter = this.displayFilters.find((v: ISearchFilter, i: number) => i === this.indexOfFocus);
        this.emitSelection(curFilter, false);
      }
    }
  }

  @HostListener('mouseenter') componentContainsMouse(): void {
    this.mouseFocus.emit(true);
  }

  @HostListener('document:click', ['$event.target']) onClick(targetElement: ElementRef): void {
    const clickedInside = this.wrapper.nativeElement.contains(targetElement);
    if (!clickedInside && this.shouldCheckClicks) {
      this.mouseFocus.emit(false);
    }
  }

  selectSearchType(i: number): void {
    const foundFilter = this.displayFilters.find((v: ISearchFilter, index: number) => index === i);
    foundFilter.clearValue?.();
    this.filterSelected.emit(foundFilter);
  }

  ngOnInit(): void {
    this.displayFilters = this.displayFilters ?? this.filters;
    this.indexOfFocus = 0;
  }

  ngAfterViewInit(): void {
    this.indexOfFocus = 0;
    this.shouldCheckClicks = false;
    setTimeout(() => {
      this.shouldCheckClicks = true;
    }, 500);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.indexOfFocus = 0;
    const matchedFilters = searchFilterModels(this.filters, this.mainInputSearchValue);
    const selectedFiltersMap = keyBy(this.selectedFilters, 'name');
    this.displayFilters = matchedFilters.filter((matched) => {
      if (selectedFiltersMap[matched.name] && matched['searchPlace']) return false;
      if (selectedFiltersMap[matched.name]) {
        // text array filter types can be chosen many times
        return matched.type === ISearchFilterType.TEXT_ARRAY || matched.type === ISearchFilterType.GEOSPATIAL;
      }
      return true;
    });
  }

  private emitSelection(filter: ISearchFilter, mouseFocus: boolean): void {
    filter.clearValue?.();
    this.filterSelected.emit(filter);
    this.mouseFocus.emit(mouseFocus);
  }
}
