<div class="row">
  <div class="col-lg-12 flex-row-reverse">
    <drop-down
      *ngIf="user?.isHaulynxAdmin"
      placeholder="Select carrier"
      [selected]="loadModel.selectCarrier$ | async"
      [data]="carriers"
      key="dot"
      displayLabel="name"
      name="brokers"
      (selectionChange)="selectCarrier($event)"
    >
    </drop-down>

    <action-buttons
      *ngIf="user?.carrier?.id"
      [buttons]="buttons$ | async"
      (onClick)="handlerAction($event)"
    ></action-buttons>
  </div>
</div>

<div class="row">
  <div class="col-lg-12">
    <app-tabs
      [tabs]="[loadModel.tabsSelected$ | async]"
      [selected]="loadAvailableTabsModel.tabsSelected$ | async"
      (onSelect)="goTo($event)"
      (onRemove)="removeTab($event)"
    ></app-tabs>

    <router-outlet></router-outlet>
  </div>
</div>
