import gql from 'graphql-tag';

export const GetOrderLineItems = gql`
  query GetOrderLineItems($searchCriteria: PaymentItemSearchCriteria!) {
    orderLineItems(searchCriteria: $searchCriteria) {
      lineItems {
        orderNumber
        paymentType
        quantity
        amount
        paymentUnits
        haulynxId
        usxId
        isNegative
      }
      total
    }
  }
`;
