<app-spinner [active]="saving" [fullCover]="true"></app-spinner>

<form novalidate>
  <h4>SETTINGS</h4>
  <mat-tab-group [(selectedIndex)]="selectedIndex">
    <mat-tab label="User Info">
      <mat-card class="account-tabs">
        <mat-card-content class="grid-template">
          <mat-form-field>
            <input matInput name="Name" placeholder="Name" [(ngModel)]="user.name" [disabled]="false" />
          </mat-form-field>
          <mat-form-field>
            <input matInput name="Email" placeholder="Email" [(ngModel)]="user.email" [disabled]="true" />
          </mat-form-field>
          <mat-form-field *ngIf="user?.carrier">
            <input
              matInput
              name="companyName"
              placeholder="Company"
              [(ngModel)]="user.carrier.name"
              [disabled]="true"
            />
          </mat-form-field>
          <mat-form-field *ngIf="user?.shipper">
            <input
              matInput
              name="shipperName"
              placeholder="Company"
              [(ngModel)]="user.shipper.name"
              [disabled]="true"
            />
          </mat-form-field>
          <mat-form-field *ngIf="user?.broker">
            <input matInput name="brokerName" placeholder="Company" [(ngModel)]="user.broker.name" [disabled]="true" />
          </mat-form-field>
          <mat-form-field *ngIf="user?.isHaulynxAdmin">
            <input matInput name="Admin" placeholder="Company" value="Haulynx Admin" [disabled]="true" />
          </mat-form-field>
          <mat-form-field>
            <input matInput name="Phone" placeholder="Phone" [(ngModel)]="user.phone" [disabled]="false" />
          </mat-form-field>
          <ng-container *ngIf="user?.carrier">
            <mat-form-field>
              <input matInput name="DOT" placeholder="DOT Number" [(ngModel)]="user?.carrier.dot" [disabled]="true" />
            </mat-form-field>
            <mat-form-field>
              <mat-select
                placeholder="Is Driver"
                name="isDriver"
                [(ngModel)]="user.isDriver"
                [disabled]="!user.isCompanyAdmin"
              >
                <mat-option [value]="true">Yes</mat-option>
                <mat-option [value]="false">No</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field floatPlaceholder="auto" *ngIf="user?.isDriver">
              <input
                matInput
                [(ngModel)]="user.licenseNumber"
                name="licenseNumber"
                type="text"
                placeholder="Driver's License Number"
              />
            </mat-form-field>
            <mat-form-field *ngIf="user?.isDriver">
              <mat-select [(ngModel)]="user.issuingState" placeholder="State" name="issuingState">
                <mat-option *ngFor="let state of statesList" [value]="state.code">{{ state.code }}</mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>
        </mat-card-content>
      </mat-card>
      <mat-card-content class="button" style="text-align: right">
        <button class="rmis-btn" mat-stroked-button color="accent" (click)="launchRmis()" data-test="launch-rmis">
          View RMIS Registration
        </button>
        <button mat-raised-button color="accent" (click)="updateInfo()" [disabled]="saving" data-test="save-button">
          Save
        </button>
      </mat-card-content>
    </mat-tab>
    <mat-tab label="Password">
      <mat-card class="account-tabs">
        <mat-card-content class="grid-template-password">
          <mat-form-field>
            <input
              matInput
              placeholder="Current Password"
              name="Current Password"
              [(ngModel)]="currentPassword"
              type="{{ hidePassword ? 'password' : 'text' }}"
            />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              name="New Password"
              placeholder="New Password"
              autocomplete="off"
              [(ngModel)]="password"
              type="{{ hidePassword ? 'password' : 'text' }}"
            />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              placeholder="Re-enter New Password"
              autocomplete="off"
              name="Re-enter New Password"
              [(ngModel)]="confirmPassword"
              type="{{ hidePassword ? 'password' : 'text' }}"
            />
          </mat-form-field>
          <button mat-button class="hide-button" type="button" (click)="hidePassword = !hidePassword">
            <span class="hidePassword">{{ hidePassword ? 'Show Passwords ' : 'Hide Passwords ' }}</span>
            <mat-icon matSuffix>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
        </mat-card-content>
        <div class="passwordRequirements">
          <span>Password Requirements: </span>
          <span>8 characters minimum | </span>
          <span>1 special character | </span>
          <span>1 lowercase character | </span>
          <span>1 uppercase character</span>
        </div>
      </mat-card>
      <mat-card-content class="button" style="text-align: right">
        <button class="rmis-btn" mat-stroked-button color="accent" (click)="launchRmis()" data-test="launch-rmis">
          View RMIS Registration
        </button>
        <button
          mat-raised-button
          color="accent"
          name="saving"
          (click)="updatePassword()"
          [disabled]="saving"
          data-test="save-button"
        >
          Save
        </button>
      </mat-card-content>
    </mat-tab>
    <mat-tab label="Notifications">
      <mat-card class="account-tabs">
        <mat-card-content>
          <mat-form-field>
            <mat-select
              [(ngModel)]="user.prefs.timeZone"
              placeholder="Timezone"
              name="timeZone"
              [disabled]="!user.isHaulynxAdmin"
            >
              <mat-option *ngFor="let option of timeZone" [value]="option">
                {{ option }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="grid-template">
            <mat-slide-toggle color="primary" [(ngModel)]="user.prefs.emailNotifications" name="emailNotifications">
              Email Notifications
            </mat-slide-toggle>
            <mat-slide-toggle color="primary" [(ngModel)]="user.prefs.mobileNotifications" name="mobileNotifications">
              Mobile Notifications
            </mat-slide-toggle>
            <mat-slide-toggle color="primary" [(ngModel)]="user.prefs.smsNotifications" name="smsNotifications">
              SMS Notifications
            </mat-slide-toggle>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card-content class="button" style="text-align: right">
        <button class="rmis-btn" mat-stroked-button color="accent" (click)="launchRmis()" data-test="launch-rmis">
          View RMIS Registration
        </button>

        <button mat-raised-button color="accent" (click)="updatePrefs()" [disabled]="saving" data-test="save-button">
          Save
        </button>
      </mat-card-content>
    </mat-tab>
    <mat-tab label="Referral" *ngIf="user.broker">
      <ng-template matTabContent>
        <app-referral></app-referral>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</form>
