import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonDropdownComponent } from './button-dropdown.component';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { DirectivesModule } from '@haulynx/directives';

@NgModule({
  declarations: [ButtonDropdownComponent],
  imports: [CommonModule, MaterialModule, PipesModule, DirectivesModule],
  exports: [ButtonDropdownComponent],
})
export class ButtonDropdownModule {}
