<div>
  <div class="loads-service-load-preview-container" *ngIf="loadsServiceLoad?.locations">
    <div>
      <div class="top-row">
        {{ startAddress | titlecase }} &nbsp;
        <img src="icons/other/haulynx-right-arrow.svg" height="20" />
        &nbsp; {{ endAddress | titlecase }}
      </div>

      <div class="times-row">
        {{ loadsServiceLoad | get: 'locations[0].appointmentStart' | timezoned: 'MM/DD/YY @ HH:mm
        z':'x':(loadsServiceLoad | get: 'locations[0].timezone') }} - {{ (loadsServiceLoad?.locations | nth:
        -1)?.appointmentEnd | timezoned: 'MM/DD/YY @ HH:mm z':'x':(loadsServiceLoad?.locations | nth: -1)?.timezone }}
      </div>
    </div>

    <div class="chevron-container">
      <img src="icons/other/chevron-right.svg" height="20" />
    </div>
  </div>
</div>
