import gql from 'graphql-tag';

export const CreateReleaseNoteMutation = gql`
  mutation CreateReleaseNote($newReleaseNote: ReleaseNoteInput!) {
    createReleaseNote(newReleaseNote: $newReleaseNote) {
      id
      title
      userType
      date
      expiration
      hasViewed
    }
  }
`;
