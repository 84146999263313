import { ITabsState } from '@haulynx/types';
import { MemoizedSelector } from '@ngrx/store/src/selector';
import { IAdapter } from '../helpers/adapter';
import { createActions } from './actions';
import { createTabReducer } from './reducer';
import { createTabSelectors, ITabSelectors } from './selector';
import { createInitialTabState } from './state';

export interface TabAdapter extends IAdapter {
  getSelectors<V>(selectState: ((state: V) => ITabsState) | MemoizedSelector<any, ITabsState>): ITabSelectors;
}

export function createTabAdapter<T>(options: { type?: any } = {}): TabAdapter {
  const { type } = options;

  const initialState = createInitialTabState();
  const selectors = createTabSelectors();
  const actions = createActions(type);
  const reducer = createTabReducer(actions.getActionTypes());

  return {
    ...initialState,
    ...selectors,
    ...reducer,
    ...actions,
  };
}
