import gql from 'graphql-tag';

export const UpdateMilestoneLog = gql`
  mutation updateMilestoneLog(
    $loadId: StrictId!
    $milestoneId: StrictId!
    $logId: StrictId!
    $logInput: MilestoneLogInput!
  ) {
    updateMilestoneLog(loadId: $loadId, milestoneId: $milestoneId, logId: $logId, logInput: $logInput) {
      id
    }
  }
`;
