import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  CreateSearchViewMutation,
  DeleteSearchViewMutation,
  GetSearchViewsByUserId,
  UpdateSearchViewsMutation,
} from '@haulynx/gql';
import { ColumnField, PaginatedData, SearchView } from '@haulynx/types';
import { convertLoadTimeDeltas } from '@haulynx/utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GraphqlService } from '../graphql/graphql.service';
@Injectable({
  providedIn: 'root',
})
export class SearchViewService {
  constructor(private graphqlService: GraphqlService) {}

  getSearchViewsByUserId(userId: string): Observable<PaginatedData<SearchView>> {
    return this.graphqlService
      .query<SearchView>({
        query: GetSearchViewsByUserId,
        variables: {
          userId,
        },
      })
      .pipe(
        // keep map until saved search views are mutated in firebase
        map((result) => {
          const data: Array<SearchView> = result.data['getSearchViewsByUserId'];
          const processedData = data.map((searchView) => {
            const oldPriceColumn = [...(searchView?.metaData?.columns || [])];

            oldPriceColumn.forEach((item, i) => {
              if (item.key === ColumnField.PRICE_MAX_BUY_REVENUE) {
                const newColumns: { key: string; value: boolean; order?: number }[] = [
                  { key: 'price', value: false },
                  { key: 'revenue', value: false },
                  { key: 'maxBuy', value: false },
                ];
                if (item.value) {
                  newColumns.forEach((val, j) => {
                    val.value = true;
                    val.order = oldPriceColumn[i].order + j;
                  });
                  oldPriceColumn.forEach((val, j) => {
                    if (val.order && val.order > item.order) val.order += 2;
                  });
                }
                //removing PRICE_MAX_BUY_REVENUE column and inserted newColumns
                oldPriceColumn.splice(i, 1, ...newColumns);
              }
            });

            return {
              ...searchView,
              data: convertLoadTimeDeltas(searchView.data),
              metaData: {
                ...searchView.metaData,
                columns: oldPriceColumn,
              },
            };
          });
          return { data: processedData } as PaginatedData<SearchView>;
        })
      );
  }

  createSearchView(newSearchView: SearchView, userId: string): Observable<SearchView> {
    try {
      const { data: { viewId, fullMission, ...dataRest } = {}, ...newSearchViewRest } = newSearchView;

      return this.graphqlService
        .mutate<SearchView>({
          mutation: CreateSearchViewMutation,
          variables: {
            newSearchView: {
              ...newSearchViewRest,
              data: dataRest,
            },
            userId,
          },
        })
        .pipe(map((result) => result.data['createSearchView']));
    } catch (e) {
      console.log(e);
    }
  }

  deleteSearchView(searchViewId: string, userId: string): Observable<boolean> {
    return this.graphqlService
      .mutate<boolean>({
        mutation: DeleteSearchViewMutation,
        variables: {
          searchViewId,
          userId,
        },
      })
      .pipe(map((result) => result.data['deleteSearchView']));
  }

  bulkUpdateSearchViews(searchViews: SearchView[], userId: string): Observable<SearchView[]> {
    return this.graphqlService
      .mutate<SearchView[]>({
        mutation: UpdateSearchViewsMutation,
        variables: {
          searchViews,
          userId,
        },
      })
      .pipe(map((result) => result.data['updateSearchViews']));
  }
}
