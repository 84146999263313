<div class="row">
  <div class="col-lg-12">
    <h5>(Don't mess this Up!)</h5>
    <div class="row">
      <div class="col-lg-12">
        <mat-checkbox class="col s4" [(ngModel)]="config.loadLogging"> Logging Enabled</mat-checkbox>
      </div>
    </div>
    <div class="row">
      <mat-form-field class="col-lg-3">
        <input matInput placeholder="Load Distribution Fee" [(ngModel)]="config.loadFee" type="number" min="1" />
        <label matPrefix>$</label>
      </mat-form-field>
      <mat-form-field class="col-lg-3">
        <input
          matInput
          placeholder="Maximum Dead Mile Radius"
          [(ngModel)]="config.maximumDeadMiles"
          type="number"
          min="1"
        />
        <label matSuffix>miles</label>
      </mat-form-field>
      <mat-form-field class="col-lg-3">
        <input
          matInput
          placeholder="Time to accept Load"
          [(ngModel)]="config.timeToAcceptLoad"
          type="number"
          min="1"
          max="60"
        />
        <label matSuffix>mins</label>
      </mat-form-field>
      <mat-form-field class="col-lg-3">
        <input
          matInput
          placeholder="Maximum Potential Carriers for Load"
          [(ngModel)]="config.maxCarrierCount"
          type="number"
          min="1"
          max="100"
        />
      </mat-form-field>
    </div>
    <div class="row">
      <mat-radio-group [(ngModel)]="config.mode" class="col-lg-4">
        <mat-radio-button value="0">Restrict to all Carriers</mat-radio-button>
        <mat-radio-button value="1" style="margin-left: 10px">Restrict to Real Carriers only</mat-radio-button>
        <mat-radio-button value="2" style="margin-left: 10px">Restrict to Demo Carriers only</mat-radio-button>
      </mat-radio-group>
      <mat-checkbox class="col-lg-4" [(ngModel)]="config.golden"> Golden Carriers only</mat-checkbox>
      <mat-checkbox class="col-lg-4" [(ngModel)]="config.includeSaferWatch"> Include Safer watch </mat-checkbox>
    </div>
    <div class="row" *ngIf="config.demo">
      <div class="col-lg-12">
        <label>
          <b>Demo Carriers</b>
          <a routerLink="../carrier-management">Edit List</a></label
        >
        <ol>
          <li *ngFor="let carrier of config.demoCarriers">{{ carrier?.name }}</li>
        </ol>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <button mat-raised-button color="primary" (click)="saveConfig()">Save</button>
      </div>
    </div>
  </div>
</div>
