import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MaterialModule } from '@haulynx/modules';

import { ElementsModule } from '@haulynx/elements';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AsyncContractsStoreModule } from '@haulynx/store';
import { ContractManageContainerComponent } from './contract-manage-container.component';
import { AtomicTableModule } from '../atomic-table/atomic-table.module';
import { ContractsFormModule } from '../contracts-form/contracts-form.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ConfirmationDialogComponent } from './contract-delete-modal/contract-delete-modal';

@NgModule({
  declarations: [ContractManageContainerComponent, ConfirmationDialogComponent],
  exports: [ContractManageContainerComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    ElementsModule,
    MaterialModule,
    AtomicTableModule,
    ContractsFormModule,
    AsyncContractsStoreModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  entryComponents: [ConfirmationDialogComponent],
})
export class ContractManageContainerModule {}
