import { Pipe, PipeTransform } from '@angular/core';
import { LoadOverviewTask, LoadsServiceLoad } from '@haulynx/types';
import { loadOverviewTaskToComplete } from '@haulynx/utils';

@Pipe({
  name: 'taskToComplete',
})
export class TaskToCompletePipe implements PipeTransform {
  transform(load: LoadsServiceLoad, details: boolean = false, showTrackingStatus: boolean = false): unknown {
    const task = loadOverviewTaskToComplete(load, showTrackingStatus);

    if (details) {
      if (task === LoadOverviewTask.ADD_BOOKING_DETAILS) {
        return `<span class="yellow-text">Add Carrier Details to complete booking details</span>`;
      }
      if (task === LoadOverviewTask.ADD_DISPATCH_DETAILS) {
        return `<span class="yellow-text">Add Dispatch Details to complete booking details</span>`;
      }
      if (task === LoadOverviewTask.ADD_TRUCKING_DETAILS) {
        return `<span class="yellow-text">Enter Truck Details to complete booking details</span>`;
      }
      if (task === LoadOverviewTask.TRACKING_NOT_RESPONDING) {
        return `<span class="red-text">Update your tracking type or reach out to driver</span>`;
      }
      if (task === LoadOverviewTask.EXPIRED_TRACKING) {
        return `<span class="red-text">Update your tracking type or reach out to driver</span>`;
      }

      return `<span>Edit Booking Details</span>`;
    }

    if (task === LoadOverviewTask.ADD_BOOKING_DETAILS) {
      return `<span class="yellow-text">Add Booking Details</span>`;
    }
    if (task === LoadOverviewTask.ADD_DISPATCH_DETAILS) {
      return `<span class="yellow-text">Add Dispatch Details</span>`;
    }
    if (task === LoadOverviewTask.ADD_TRUCKING_DETAILS) {
      return `<span class="yellow-text">Add Truck Details</span>`;
    }
    if (task === LoadOverviewTask.EXPIRED_TRACKING) {
      return `<span class="red-text">Tracking Expired</span>`;
    }
    if (task === LoadOverviewTask.TRACKING_NOT_RESPONDING) {
      return `<span class="red-text">Tracking Not Responding</span>`;
    }

    return `<span class="all-complete">All Tasks Complete</span>`;
  }
}
