import { HttpParams } from '@angular/common/http';
import {
  BidStatusType,
  BookStatus,
  calculateDistanceDto,
  Carrier,
  GooglePlaceCountries,
  PlaceCountries,
  ILane,
  LoadOverviewTask,
  LoadsServiceLoad,
  LoadsServiceLoadLocation,
  LoadsServiceLoadStatus,
  NotificationEvent,
  NotificationEventType,
  WebNotification,
  CustomerNote,
  LoadTrackingStatusReasons,
  states,
  mexicoStates,
  canadaStates,
  StateDictionary,
} from '@haulynx/types';
import distance from '@turf/distance';
import { point } from '@turf/helpers';
import { differenceInDays, isValid, startOfDay } from 'date-fns';
import { List, Map } from 'immutable';
import {
  capitalize,
  filter,
  forEach,
  get,
  groupBy,
  has,
  includes,
  isEqual,
  isFunction,
  isNull,
  isNumber,
  isObject,
  isString,
  join,
  keys,
  last,
  map,
  reduce,
  round,
  startCase,
  slice,
  split,
  toLower,
  toUpper,
  transform,
  trim,
} from 'lodash';
import moment from 'moment';
import momentTimeZone from 'moment-timezone';
import { Subject } from 'rxjs';

export const dateDisplayFormat = 'ddd, MMM Do, YYYY';

export class Day {
  public key: string;
  public value: string;
  public date: Date;
  public daysAgo: number;

  constructor(daysAgo: number) {
    this.daysAgo = daysAgo;
    const m = momentTimeZone().subtract(daysAgo, 'days');
    this.value = m.format(dateDisplayFormat);
    this.date = m.toDate();
  }
}

export const validationMessages = {
  required: 'Is required',
};

export function generateDays(month = 6) {
  const days = month * 30;
  const newDays = [];

  for (let i = 0; i < days; i++) {
    newDays.push(new Day(i));
  }

  return newDays;
}

const typeCache: { [label: string]: boolean } = {};

export function type<T>(label: T | ''): T {
  if (typeCache[label as string]) {
    throw new Error(`Action type '${label}' is not unique'`);
  }

  typeCache[label as string] = true;

  return label as T;
}

export function toHttpParams(body: { [key: string]: string | number | boolean }): HttpParams {
  let params = new HttpParams();

  forEach(body, (value, key) => {
    params = params.set(key, value as string);
  });

  return params;
}

export function toQueryParams(params: Record<string, string | number | boolean>): string {
  return Object.entries(params)
    .map(([key, val]) => `${key}=${val}`)
    .join('&');
}

export function mapToArray<T>(data: Map<unknown, T>): T[] {
  return Map.isMap(data) ? data.valueSeq().toArray() : data ? data : [];
}

export function mapToFormGroup(data: List<unknown>): { [key: string]: unknown } {
  const newData = data ? data.toArray() : [];

  return reduce(
    newData,
    (result, val) => {
      result[val['id']] = [''];

      return result;
    },
    {}
  );
}

export function listToArray<T>(data: List<T>): Array<T> {
  return List.isList(data) ? data.toArray() : data ? data : [];
}

export function sortByMCNumber(row: Carrier): string {
  return row.saferWatchData && row.saferWatchData.mcNumber ? row.saferWatchData.mcNumber : '';
}

export function sortByEquipmentType(row: { equipmentType: string[] }): string {
  return row.equipmentType ? row.equipmentType.join(',') : 'N/A';
}

export function sortByCompanyName(row: { company: { name: string } }): string {
  return row.company && row.company.name ? row.company.name.replace(/\s/g, '') : '';
}

export function sortByPickUpDate(row: { loadLocations: Array<{ timestamp: number }> }): string | number {
  return row.loadLocations ? row.loadLocations.slice().shift().timestamp : '';
}

export function sortByDeliveryDate(row: { loadLocations: Array<{ timestamp: number }> }): string | number {
  return row.loadLocations ? row.loadLocations.slice().pop().timestamp : '';
}

export function sortByDateCreated(row): number {
  const time = moment(row.dateCreated, ['MM-DD-YYYY hh:mm a', 'X']).unix();
  return time;
}

export function sortByMatch(row): string {
  return row.match ? row.match : '';
}

/**
 * Filters an array of objects by checking if the search query is contained in the value
 * of the given key.
 * @param collection an array of objects
 * @param query search string
 * @param key object key whose value will be filtered by
 */
export function filterBySearchKey<T>(collection: T[], query: string, key: string | number): T[] {
  return filter(collection, (item) => {
    const itemValue = toLower(item[key]);
    const keywordValue = toLower(query);
    return includes(itemValue, keywordValue);
  });
}

class AliveWhile extends Subject<unknown> {
  public destroy() {
    this.next();
    this.complete();
  }
}

export function aliveWhile(): AliveWhile {
  return new AliveWhile();
}

export enum AddressPosition {
  ADDRESS = 0,
  STATE_AND_CITY = 1,
  ZIP = 2,
  CITY = 3,
  STATE = 4,
}

/**
 * Parse address
 * @param {string} fullAddress String address with this format Address,City State,ZIP | Address,City,State Zip,Country | City,State,Country
 * @param {AddressPosition} position
 * @param {boolean} isCapitalized
 * @param {boolean} stateCode returns in two letter code format
 */
export function splitAddress(
  fullAddress: string,
  position: AddressPosition | null = 1,
  isCapitalized = false,
  stateCode: boolean = false
): string {
  const lookupCountries = ['USA', 'Canada', 'Mexico', 'United States'];
  if (includes(['any', 'none'], toLower(fullAddress)) || isNull(fullAddress)) {
    return 'Any';
  }

  const parseAddress = split(trim(fullAddress), ',');
  let address = 'No Info',
    city = 'No Info',
    state = 'No Info',
    zip = 'No Info',
    country = 'No Info';

  // Only address is included
  if (parseAddress.length === 1) {
    address = trim(parseAddress[0]);
  }

  if (parseAddress.length === 2) {
    const normalizedCountry = trim(last(parseAddress));
    if (lookupCountries.includes(normalizedCountry)) {
      const stateAndCountry = parseAddress;
      city = stateAndCountry[0];
      state = stateAndCountry[1];
    } else {
      const cityAndState = split(trim(parseAddress[0]), ' ');

      if (cityAndState.length > 2) {
        city = cityAndState.slice(0, cityAndState.length - 1).join(' ');
        state = last(cityAndState);
      } else {
        city = cityAndState[0];
        state = cityAndState[1];
      }

      zip = trim(parseAddress[1]);
    }
  }

  if (parseAddress.length === 3) {
    const normalizedCountry = trim(last(parseAddress));

    if (lookupCountries.includes(normalizedCountry)) {
      const splitState = split(trim(parseAddress[1]), ' ');
      if (splitState.length === 2) {
        state = splitState[0];
        zip = splitState[1];
      } else {
        state = parseAddress[1];
      }
      city = trim(parseAddress[0]);
      country = parseAddress[2];
    } else {
      const cityState = split(trim(parseAddress[1]), ' ');

      city = trim(slice(cityState, 0, cityState.length - 1).join(' '));
      state = last(cityState);
      address = trim(parseAddress[0]);
      zip = trim(parseAddress[2]);
    }
  }

  if (parseAddress.length === 4) {
    const stateZip = split(trim(parseAddress[2]), ' ');

    state = stateZip[0];
    zip = stateZip[1];
    address = trim(parseAddress[0]);
    city = trim(parseAddress[1]);
    country = trim(parseAddress[3]);
  }

  if (parseAddress && parseAddress.length) {
    switch (position) {
      case AddressPosition.ADDRESS:
        return address;
      case AddressPosition.CITY:
        return isCapitalized ? startCase(toLower(city)) : city;
      case AddressPosition.STATE:
        return stateCode && trim(state).length > 2 ? getStateCode(state, country) : state;
      case AddressPosition.ZIP:
        return zip;
      default:
        return join([isCapitalized ? startCase(toLower(city)) : city, state], ', ');
    }
  } else {
    return fullAddress;
  }
}

/**
 * getStateCode will take a state name and return it as a two letter state code
 * example: 'Washington' returns 'WA'
 * @param state
 * @param country
 * @returns
 */
export function getStateCode(state: string, country: string = 'United States'): string {
  const currentState = trim(state);
  const currentCountry = trim(country);
  const searchCountryStates =
    currentCountry === PlaceCountries.USA || currentCountry === GooglePlaceCountries.USA
      ? states
      : currentCountry === PlaceCountries.MEXICO
      ? mexicoStates
      : currentCountry === PlaceCountries.CANADA
      ? canadaStates
      : [];
  if (!searchCountryStates.length) return 'No Info';
  return searchCountryStates.find((item: StateDictionary) => {
    return item.name.toUpperCase().replace(' ', '') === currentState.toUpperCase().replace(' ', '');
  }).code;
}

/**
 * Get the task that is still pending completion for load overview and booking
 * @param {LoadsServiceLoad} load
 * @returns
 */
export function loadOverviewTaskToComplete(load: LoadsServiceLoad, trackingFeature: boolean = true): LoadOverviewTask {
  if (!load) return LoadOverviewTask.ALL_TASKS_COMPLETE;

  if (load.loadStatus === LoadsServiceLoadStatus.FINALLED) {
    return LoadOverviewTask.ALL_TASKS_COMPLETE;
  }

  if (load.bookStatus === BookStatus.BOOKED || load.bookStatus === BookStatus.PAUSE) {
    if (load.loadStatus === LoadsServiceLoadStatus.UNASSIGNED) {
      return LoadOverviewTask.ADD_TRUCKING_DETAILS;
    }
    if (load.loadStatus === LoadsServiceLoadStatus.ASSIGNED) {
      return LoadOverviewTask.ADD_DISPATCH_DETAILS;
    }

    /**
     * Every other case:
     *  LoadsServiceLoadStatus.AT_SHIPPER
     *  LoadsServiceLoadStatus.AT_RECEIVER
     *  LoadsServiceLoadStatus.DELIVERED
     */
    if (!load.drivers?.length && !load.truck?.id) {
      return LoadOverviewTask.ADD_TRUCKING_DETAILS;
    } else if (!load.dispatchLocation?.address || !load.dispatchLocation?.timestamp) {
      return LoadOverviewTask.ADD_DISPATCH_DETAILS;
    } else {
      if (
        load.trackingStatus?.usxOrderStatus !== LoadTrackingStatusReasons.ACTIVE &&
        load.trackingStatus?.usxOrderStatus !== LoadTrackingStatusReasons.READY_TO_TRACK &&
        trackingFeature
      ) {
        if (load.trackingStatus?.usxOrderStatus === LoadTrackingStatusReasons.EXPIRED) {
          return LoadOverviewTask.EXPIRED_TRACKING;
        }
        if (load.trackingStatus?.usxOrderStatus === LoadTrackingStatusReasons.NOT_RESPONDING) {
          return LoadOverviewTask.TRACKING_NOT_RESPONDING;
        }
      }
      return LoadOverviewTask.ALL_TASKS_COMPLETE;
    }
  }

  // Load is still available and not finalled
  return LoadOverviewTask.ADD_BOOKING_DETAILS;
}

/**
 *
 * @param load
 * @returns A string that summarizes a load by pickup / drop off locations
 */
export function getAppTitleForLoad(load: LoadsServiceLoad): string {
  return loadsServiceLoadDetailsTitle(load.locations[0], load.locations[load.locations.length - 1]);
}

export function loadsServiceLoadDetailsTitle(
  loadOrigin: LoadsServiceLoadLocation,
  loadDestination: LoadsServiceLoadLocation
) {
  const origin = splitAddress(loadOrigin && loadOrigin.address, AddressPosition.CITY, true);
  const destination = splitAddress(loadDestination && loadDestination.address, AddressPosition.CITY, true);
  const originState = splitAddress(loadOrigin.address, AddressPosition.STATE, true);
  const destinationState = splitAddress(loadDestination.address, AddressPosition.STATE, true);
  return `${removeVowelsToUpperCase(origin)},${originState}→${removeVowelsToUpperCase(
    destination
  )},${destinationState}`;
}

export function removeVowelsToUpperCase(word: string): string {
  return (
    word.substring(0, 1).toUpperCase() +
    word
      .substring(1)
      .replace(/[aeiouyAEIOUY]/g, '')
      .toUpperCase()
  );
}

export function openEmailClient(data: string | { mailto?: string; subject?: string; body?: string }): void {
  let url = null;
  if (typeof data === 'string') {
    url = data;
  } else if (typeof data === 'object') {
    url = `mailto:${data.mailto || ''}?subject=${data.subject || ''}&body=${data.body || ''}`;
  }
  window.open(url, '_blank');
}

export function shortenCityName(city: string): string {
  const vowelRegex = /[aeoui]/gi;
  const cityNames = trim(city).split(' ');

  const shortenedCityNames = map(
    cityNames,
    (cityName) =>
      cityName.charAt(0) +
      cityName.substr(1, cityName.length - 2).replace(vowelRegex, '') +
      cityName.charAt(cityName.length - 1)
  );

  return shortenedCityNames.join(' ').substr(0, 12);
}

export function getEmailUserName(email: string): string {
  return get(split(email, '@'), 0, '');
}

export function joinNoteTexts(note: CustomerNote[]): string {
  return (
    note
      ?.filter((note) => !!note?.noteText)
      .map((note) => note?.noteText)
      .join('\n\n') || ''
  );
}

export function getBrokerIdFromEmail(email: string): string {
  const id = get(split(email, '@'), 0, '');
  if (!id) {
    throw new Error(`Unable to parse broker id from ${email}`);
  }
  return toUpper(id);
}

export function separateLanesCityState(lane: ILane): ILane {
  const [orgCity, orgState] = lane.orgCityState.split(', ');
  const [destCity, destState] = lane.destCityState.split(', ');

  return { ...lane, orgCity, orgState, destCity, destState };
}

export function mapDto<T>(dto: unknown, dtoMap: { [key: string]: unknown }, args: Array<unknown>): T {
  return reduce(
    dtoMap,
    (accumulator, value, key) => {
      if (isFunction(value)) {
        accumulator[key] = value(...args);
      } else if (isString(value)) {
        accumulator[key] = get(dto, value, null);
      }

      return accumulator;
    },
    {} as T
  );
}

/**
 * Determines if a string, number, or date is invalid.
 */
export function isInvalidDate(val: string | number | Date): boolean {
  if (!val) {
    return true;
  }
  return typeof val === 'object' && !isValid(val);
}

export function createUUID(): string {
  let dt = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    // eslint-disable-next-line no-bitwise
    const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    // eslint-disable-next-line no-bitwise
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
}

/**
 * Some fields on a given object might be falsey whereas another similiar object might have truthy data for those fields.
 * @param base an object that might have falsey fields
 * @param fallback another instance of the base model that might have truthy fields
 * @param fields list of field names to check for truthy values
 */
export function getTruthyFields<T>(base: T, fallback: Partial<T>, fields: string[]): T {
  return reduce(
    fields,
    (accumulator, field) => {
      accumulator[field] = get(base, field) || get(fallback, field);
      return accumulator;
    },
    base
  );
}

export function isValidCarrier(carrier: Carrier): boolean {
  const hasRmisSetup = get(carrier, 'rmisRegistration.isRmisSetup', null) === true;
  return hasRmisSetup;
}

export function calculateDistance(options: calculateDistanceDto): string | number {
  const { fromLon, fromLat, toLon, toLat, suffix } = options;

  if (!fromLon || !fromLat || !toLon || !toLat) {
    return 'N/A';
  }

  const origin = point([fromLon, fromLat]);
  const destination = point([toLon, toLat]);
  const result = round(distance(origin, destination, { units: 'miles' }));

  return suffix ? `${result} ${suffix}` : result;
}

export function convertMileToMeters(mile: number): number {
  return Math.round(mile * 1609.344);
}

export function newMatchToWebNotification(notifications: NotificationEvent[]): WebNotification[] {
  const newMatchNotifications = filter(
    notifications,
    (n) => n.eventType === NotificationEventType.POSTED_TRUCK_RECOMMENDATION
  );
  const newMatchNotisGroupedByCarrier = groupBy(newMatchNotifications, 'targetCarrierDot');

  const otherNotifications = filter(
    notifications,
    (n) => n.eventType !== NotificationEventType.POSTED_TRUCK_RECOMMENDATION
  );
  const otherNotisIndexedById = groupBy(otherNotifications, 'id');

  return reduce(
    { ...newMatchNotisGroupedByCarrier, ...otherNotisIndexedById },
    (acc: WebNotification[], perCarrierNewMatches) => [
      ...acc,
      new WebNotification(perCarrierNewMatches[0].eventType, perCarrierNewMatches),
    ],
    []
  );
}

/**
 * Deep diff between two objects using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base Object to compare with
 * @return {Object} Returns a difference in a new object
 */
export function objDifference(object: unknown, base: unknown): unknown {
  function changes(objA, objB) {
    return transform(objA, (result, value, key) => {
      if (!isEqual(value, objB[key])) {
        result[key] = isObject(value) && isObject(objB[key]) ? changes(value, objB[key]) : value;
      }
    });
  }

  return changes(object, base);
}

// todo remove when fixed on backend, status name required, not value
export function bidStatusToValue(status: string): string {
  switch (status) {
    case BidStatusType.NO_CAPACITY:
      return 'No Capacity';
    case BidStatusType.PRICE_REJECTED:
      return 'Price Rejected';
    case BidStatusType.AUTO_REJECTED:
      return 'Auto Rejected';
    case 'N/A':
      return 'N/A';
    default:
      return capitalize(status);
  }
}

// todo remove when fixed on backend, status value required, not name
export function bidStatusToName(status: string): string {
  switch (status) {
    case 'No Capacity':
      return BidStatusType.NO_CAPACITY;
    case 'Price Rejected':
      return BidStatusType.PRICE_REJECTED;
    case 'Auto Rejected':
      return BidStatusType.AUTO_REJECTED;
    default:
      return toLower(status);
  }
}

export function canBookLoad(loadBookStatus: string): boolean {
  return (
    loadBookStatus === BookStatus.BOOKABLE ||
    loadBookStatus === BookStatus.EXCLUDED ||
    loadBookStatus === BookStatus.VIEWABLE
  );
}

export function objectToUrlParams(obj: unknown, defaultVal = 'all', delimeter = '&', q = ''): string {
  const objKeys = keys(obj).sort();
  const strings: string[] = objKeys.map((key) => {
    if (Array.isArray(obj[key])) {
      return map(obj[key], (val, index) => `${key}=${obj[key][index]}`).join(delimeter);
    } else {
      switch (typeof key) {
        case 'string':
        case 'number':
          return `${key}=${obj[key]}`;
        case 'object':
          return objectToUrlParams(obj[key], delimeter);
      }
    }
  });
  return q + strings.join(delimeter) || defaultVal;
}

/**
 * Score an object based on its key / value similiarity to a base object.
 * @param objectToScore the object in question that you want to score
 * @param baseObject the object by which you are scoring to determine similiarity
 * @returns an int number score. Higher values indicate more simliarity.
 */
export function scoreObjectSimliarity(objectToScore: any, baseObject: any): number {
  const objectKeys = keys(objectToScore);
  if (!objectKeys.length) {
    return 0;
  }
  if (!baseObject) {
    // +1 point for matching a nullish value
    return objectToScore === baseObject ? 1 : 0;
  }
  return reduce(
    objectKeys,
    (prevScore, currKey) => {
      if (has(baseObject, currKey)) {
        // 1 point for having the same key
        ++prevScore;
      }
      if (!objectToScore[currKey]) {
        return prevScore;
      }

      switch (typeof objectToScore[currKey]) {
        case 'string':
        case 'number':
          // +1 point for matching the value of each key
          return objectToScore[currKey] === baseObject[currKey] ? ++prevScore : prevScore;
        case 'object':
          // compute points for nested objects / arrays
          return prevScore + scoreObjectSimliarity(objectToScore[currKey], baseObject[currKey]);
        default:
          return prevScore;
      }
    },
    0
  );
}

export function getDaysDifference(timestamp: number): number {
  timestamp = startOfDay(timestamp).valueOf();
  const now = startOfDay(Date.now()).valueOf();

  return differenceInDays(timestamp, now);
}

export function getDateString(
  timestamp: number,
  format: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'numeric', day: 'numeric' }
): string {
  const date = new Date(new Date(timestamp).setHours(0, 0, 0, 0));
  date.setDate(date.getDate());
  return date.toLocaleString('en-US', format);
}

export function getHumanDaysDifference(timestamp: number): string {
  let prefix = '';
  let suffix = '';
  let difference: number | string;

  difference = getDaysDifference(timestamp);
  suffix = difference === 1 || difference === -1 ? 'day' : 'days';

  if (difference > 0) {
    prefix = '+';
  } else if (difference === 0) {
    difference = 'today';
    suffix = '';
  }

  return `${prefix}${difference} ${suffix}`;
}

/**
 * Splits an array into chunks where each chunk of `arr` is no longer than `chunkSize`
 * @param arr Array to be split
 * @param chunkSize Max number of elements for each chunk
 * @param minChunkSize Minimum number of elements for the last entry
 * @returns The array of chunks
 */
export function splitArrayIntoGroups<T>(arr: T[], chunkSize: number, minChunkSize = 0): T[][] {
  const myArray: T[][] = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    myArray.push(arr.slice(i, i + chunkSize));
  }
  if (minChunkSize > 0 && last(myArray).length < minChunkSize) {
    // compute how many elements we need to add to the last array
    const countToAdd: number = minChunkSize - last(myArray).length;
    // remove elements from the penultimate array
    const removedFromPenultimateArr = myArray[myArray.length - 2].splice(0 - countToAdd, countToAdd);
    // push removed elements to the last array
    myArray[myArray.length - 1] = [...removedFromPenultimateArr, ...myArray[myArray.length - 1]];
  }
  return myArray;
}

export async function copyText(text: string) {
  const selBox = document.createElement('textarea');
  selBox.style.position = 'fixed';
  selBox.style.left = '0';
  selBox.style.top = '0';
  selBox.style.opacity = '0';
  selBox.value = text;
  document.body.appendChild(selBox);
  selBox.focus();
  selBox.select();
  document.execCommand('copy');
  document.body.removeChild(selBox);
}

/**
 * positiveNumbers ensures number is positive.
 * @param value
 * @returns number if value positive, else null
 */
export function positiveNumbers(value: number): number {
  if (!value) return null;
  const num = Number(value);
  return isNumber(num) && num > 0 ? num : null;
}
