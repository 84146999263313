import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MomentService } from '@haulynx/services';
import {
  AddressField,
  EquipmentTypeOption,
  equipmentTypes,
  IPostTruck,
  PostTruckForm,
  RadiusOption,
  radiusOptions,
} from '@haulynx/types';
import moment from 'moment';
import { timer } from 'rxjs';
import { first } from 'rxjs/operators';
import { DatepickerComponent } from '../datepicker/datepicker.component';
import { AppAddressFieldComponent } from '../google-address-field/components/app-address-field.component';

@Component({
  selector: 'app-post-truck-form',
  templateUrl: './post-truck-form.component.html',
  styleUrls: ['./post-truck-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostTruckFormComponent implements AfterViewInit, OnChanges, OnInit {
  @ViewChild('location', { static: true }) location: AppAddressFieldComponent;
  @ViewChild('locationRadius', { read: ElementRef, static: true }) locationRadius: ElementRef;
  @ViewChild('destination', { static: true }) destination: AppAddressFieldComponent;
  @ViewChild('destinationRadius', { read: ElementRef, static: true }) destinationRadius: ElementRef;
  @ViewChild('datepicker', { static: true }) datepicker: DatepickerComponent;
  @ViewChild('submitButton', { read: ElementRef, static: true }) submitButton: ElementRef;

  @Input() carrierToolbar: boolean;
  @Input() isLoading: boolean;
  @Input() minDate: moment.Moment;
  @Input() timeAvailable: number;
  @Input() defaultRadius: number;
  @Input() defaultEquipment: string;
  @Input() defaultPreferredLocation: AddressField;
  @Input() truckToPopulate: IPostTruck;
  @Input() countryRestrictions: string[];

  @Output() saveTruck = new EventEmitter<PostTruckForm>();
  @Output() cancelForm = new EventEmitter<boolean>();

  form: FormGroup;
  radiusList: RadiusOption[] = radiusOptions;
  equipmentList: EquipmentTypeOption[] = equipmentTypes;

  constructor(private fb: FormBuilder, private cdRef: ChangeDetectorRef, private momentService: MomentService) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.form = this.fb.group({
      location: [null, validateLocation],
      locationRadius: [this.defaultRadius],
      preferredLocation: [null],
      preferredLocationRadius: [this.defaultRadius],
      timeAvailable: [this.timeAvailable, Validators.required],
      equipmentType: [this.defaultEquipment],
    });
  }

  onLocationSelect(): void {
    this.cdRef.detectChanges();
    this.locationRadius.nativeElement.firstChild.children[0].focus();
  }

  onLocationRadiusSelect(): void {
    this.destination.addressElement.nativeElement.focus();
  }

  onDestinationSelect(): void {
    this.destinationRadius.nativeElement.firstChild.children[0].focus();
  }

  onDestinationRadiusSelect(): void {
    this.datepicker.dropDownInput.autocompleteInput.nativeElement.focus();
  }

  onEquipmentTypesSelect(): void {
    this.submitButton.nativeElement.focus();
  }

  onCancel(e: Event): void {
    e.preventDefault();
    this.cancelForm.emit(true);
  }

  onSubmit(e: Event): void {
    e.preventDefault();
    this.form.markAllAsTouched();

    if (this.form.valid) {
      const truck: PostTruckForm = this.form.getRawValue();

      this.saveTruck.emit(truck);
      this.resetForm();
    }
  }

  resetForm(): void {
    this.form.reset({
      locationRadius: this.defaultRadius,
      preferredLocation: this.defaultPreferredLocation,
      preferredLocationRadius: this.defaultRadius,
      timeAvailable: this.timeAvailable,
      equipmentType: this.defaultEquipment,
    });

    this.focusOnLocation();
  }

  ngAfterViewInit(): void {
    timer(0)
      .pipe(first())
      .subscribe(() => this.focusOnLocation());
  }

  focusOnLocation(): void {
    this.location.addressElement.nativeElement.focus();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { truckToPopulate, defaultPreferredLocation } = changes;

    if (defaultPreferredLocation) {
      const preferredLocation = defaultPreferredLocation.currentValue;

      if (this.form) {
        this.form.patchValue({ preferredLocation });
      }
    }

    if (truckToPopulate && this.form) {
      const truck: IPostTruck = truckToPopulate.currentValue;

      if (truck) {
        const {
          locationLat,
          locationLon,
          location,
          timeZone,
          timeAvailable,
          equipmentType,
          preferredLocation,
          preferredLocationLat,
          preferredLocationLon,
          preferredLocationTimeZone,
          radius,
          preferredRadius,
        } = truck;

        this.form.patchValue({
          location: {
            lat: locationLat,
            lon: locationLon,
            address: location,
            timeZone,
          },
          timeAvailable: this.momentService.getMoment(timeAvailable),
          preferredLocation: {
            lat: preferredLocationLat,
            lon: preferredLocationLon,
            address: preferredLocation !== 'None' ? preferredLocation : null,
            timeZone: preferredLocationTimeZone,
          },
          preferredLocationLat,
          preferredLocationLon,
          equipmentType,
          locationRadius: radius ? radius : this.defaultRadius,
          preferredLocationRadius: preferredRadius ? preferredRadius : this.defaultRadius,
        });
      } else {
        this.resetForm();
      }
    }
  }
}

function validateLocation(ctrl: FormControl): ValidationErrors | null {
  const location: AddressField = ctrl.value || {};
  const { address, lat, lon } = location;
  const isValid = !!(address && lat && lon);

  return isValid ? null : { locationIsValid: 'This field is required' };
}
