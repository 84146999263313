import { Bid } from './bid';

export class BidDetails {
  loadId?: string;
  offerCount?: number;
  lowestBid?: Bid;
  lowestOffer?: number;
  lowestOfferCarrierName?: string;

  constructor(props?: Partial<BidDetails>) {
    Object.assign(this, props);
  }
}
