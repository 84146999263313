import { Directive, HostBinding, HostListener, Input, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
import { CarrierOwnerPipe } from '@haulynx/pipes';
import { CarriersService } from '@haulynx/services';
import { aliveWhile } from '@haulynx/utils';
import { get } from 'lodash';
import { combineLatest, Subject } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';
import { CarrierDetailsInfoComponent } from './carrier-details-info.component';

@Directive({
  selector: '[carrierDetails]',
})
export class CarrierDetailsDirective implements OnDestroy {
  whileOpened = new Subject();
  alive = aliveWhile();

  @Input('carrierDetails') carrierId: string;
  @HostBinding('class') elementClass = 'clickable';

  @HostListener('click', ['$event']) onClick($event) {
    this.getCarrierDetails();
  }

  constructor(
    private dialog: MatDialog,
    private carriersService: CarriersService,
    private router: Router,
    private carrierOwnerPipe: CarrierOwnerPipe
  ) {}

  getCarrierDetails() {
    const ref: MatDialogRef<CarrierDetailsInfoComponent> = this.dialog.open(CarrierDetailsInfoComponent);
    ref.componentInstance.isLoading = true;

    combineLatest(
      this.carriersService.getCarrierByDot(this.carrierId),
      this.carriersService.getCarrierDetailByDot(this.carrierId)
    )
      .pipe(takeUntil(this.alive))
      .subscribe(([carrier, details]: any) => {
        const carrierOwner: string = get(details, 'owner', 'Unknown');

        if (carrierOwner) {
          const owner = this.carrierOwnerPipe.transform(carrierOwner, 'Unknown');
          carrier = { ...carrier, owner };
        }

        ref.componentInstance.carrier = carrier;
        ref.componentInstance.isLoading = false;
        ref.componentInstance.condensed = true;
        ref.componentInstance.moreLoads = true;
      });

    ref.afterClosed().subscribe(() => {
      this.whileOpened.next();
      this.whileOpened.complete();
    });

    this.router.events
      .pipe(
        filter((event: RouterEvent) => event instanceof NavigationStart),
        first(),
        takeUntil(this.whileOpened)
      )
      .subscribe(() => {
        ref.close();
      });
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }
}
