<div class="details-info">
  <div class="details-info-block">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div *ngIf="!load?.carrierBid?.activeCounterOffer">
        <div class="price" *ngIf="!isExclusive">
          Price:
          <span class="currency" data-test="load-price">
            <ng-container *ngIf="load?.paymentDetails?.price; else noPrice">
              {{ load?.paymentDetails?.price | currency: 'USD':'symbol':'1.2-2' }}
            </ng-container>
          </span>
        </div>

        <div class="price" *ngIf="isExclusive">
          Price:
          <span class="currency currency-margin">
            <ng-container *ngIf="load.paymentDetails?.price; else noPrice">
              <span [class.exclusive]="isExclusive" [class.cross]="!isExclusive">
                {{ load.paymentDetails?.price | currency: 'USD':'symbol':'1.2-2' }}
              </span>
            </ng-container>
          </span>
          <ng-container *ngIf="isExclusive && (load?.paymentDetails?.ratePerMile | lessThan: 5)">
            RPM:
            <span class="currency">
              <span
                *ngIf="!!load?.paymentDetails?.ratePerMile"
                [class.exclusive]="isExclusive"
                [class.cross]="!isExclusive"
              >
                {{ load?.paymentDetails?.ratePerMile | currency: 'USD':'symbol':'1.2-2' }}
              </span>
            </span>
          </ng-container>
        </div>
      </div>

      <ng-container *appFeatures="carrierBidEnabled">
        <app-carrier-bid-chips
          *ngIf="load.carrierBid?.status"
          [bid]="load.carrierBid"
          data-test="carrier-bid"
        ></app-carrier-bid-chips>

        <div *ngIf="load.carrierBid?.activeCounterOffer" data-test="counter-offer-price">
          Your Bid: {{ load?.carrierBid.price | currency: 'USD':'symbol':'1.2-2' }}
        </div>
      </ng-container>
    </div>

    <ng-container *appFeatures="carrierBidEnabled">
      <div *ngIf="load.carrierBid?.activeCounterOffer?.notes" class="counter-offer-notes" data-test="broker-notes">
        <b>Last Message From Broker:</b><br />
        {{ load.carrierBid.activeCounterOffer.notes }}
      </div>
    </ng-container>

    <mat-divider *ngIf="load?.locations?.length"></mat-divider>
    <app-load-timeline
      [load]="load"
      [isBroker]="!!(appModel.userBroker$ | async)"
      [user]="appModel.user$ | async"
      (showFacilityInfo)="onShowFacilityInfo($event)"
    ></app-load-timeline>
    <mat-divider></mat-divider>
    <div class="way-points">
      {{ numberOfStops?.dropOff || 0 }} Drop-Off
      <span class="divider muted">|</span>
      {{ numberOfStops?.pickUp || 0 }} Pick-Up
    </div>
    <div class="info-sub-title">
      Equipment:
      <br />
      <span class="content" data-test="equipment">
        {{ load?.providerDetails?.equipmentType || 'Unknown' | fromCamelCase }}
      </span>
    </div>
    <div class="info-sub-title">
      Weight:
      <br />
      <span class="content" data-test="weight">
        <ng-container *ngIf="load?.providerDetails?.weight as weight; else unknown">
          {{ weight | safeNumber: '1.0-0' }}
        </ng-container>
      </span>
    </div>
    <div class="info-sub-title" *ngIf="!isExclusive && load?.paymentDetails?.ratePerMile | lessThan: 5">
      RPM:
      <br />
      <span
        class="content"
        *ngIf="load?.paymentDetails?.distanceMiles > 0 || load?.paymentDetails?.ratePerMile"
        data-test="rpm"
      >
        <ng-container *ngIf="isCarrier; else isBroker">
          {{
            load?.paymentDetails?.ratePerMile || +load?.paymentDetails?.price / +load?.paymentDetails?.distanceMiles
              | currency: 'USD':'symbol':'1.2-2'
          }}
        </ng-container>
      </span>
    </div>
    <div class="info-sub-title" *ngIf="!isExclusive">
      Distance:
      <br />
      <span class="content" data-test="distance">
        <ng-container *ngIf="load?.paymentDetails?.distanceMiles | safeNumber; else unknown">
          {{ load?.paymentDetails?.distanceMiles | safeNumber: '1.0-0' }}
        </ng-container>
      </span>
    </div>
    <div
      class="info-sub-title"
      *ngIf="
        !!(load | loadAlternateId: loadIdentifierType.BILL_OF_LADING) ||
        (load | loadAlternateId: loadIdentifierType.ORDER_NUMBER)
      "
    >
      Order Number:
      <br />
      <span class="content" data-test="order-number">
        {{
          (load | loadAlternateId: loadIdentifierType.BILL_OF_LADING) ||
            (load | loadAlternateId: loadIdentifierType.ORDER_NUMBER) ||
            'N/A'
        }}
      </span>
    </div>
    <div class="info-sub-title">
      TMW #
      <br />
      <span class="content" data-test="tmw">
        {{ (load | loadAlternateId: loadIdentifierType.TMW_NUMBER) || 'N/A' }}
      </span>
    </div>
    <div class="info-sub-title" *ngIf="!isCarrier">
      Book Status
      <br />
      <span class="content">
        {{ load.bookStatus | titlecase }}
      </span>
    </div>
    <div class="info-sub-title">
      HazMat
      <br />
      <span class="content" data-test="hazmat">
        {{ (load.restrictions | loadRestrictions: loadsServiceRestrictionTypes.HAZMAT) ? 'Yes' : 'No' }}
      </span>
    </div>
    <div class="info-sub-title">
      Tanker Certification
      <br />
      <span class="content" data-test="tanker-cert">
        {{
          (load?.restrictions | loadRestrictions: loadsServiceRestrictionTypes.TANKER_CERTIFICATION_REQUIRED)
            ? 'Yes'
            : 'No'
        }}
      </span>
    </div>
    <div
      class="info-sub-title"
      *ngIf="{
        pickupInstructions: load?.restrictions | loadRestrictions: loadsServiceRestrictionTypes.PICKUP_REQUIREMENTS,
        dropOffInstructions: load?.restrictions | loadRestrictions: loadsServiceRestrictionTypes.DROPOFF_REQUIREMENTS
      } as instructions"
    >
      Trailer Instructions
      <br />
      <span class="content" data-test="trailer-instructions">
        <div
          class="instruction-name"
          *ngIf="instructions.pickupInstructions || instructions.dropOffInstructions; else noTrailerInstructions"
        >
          {{ load?.restrictions | loadRestrictions: loadsServiceRestrictionTypes.PICKUP_REQUIREMENTS }} /
          {{ load?.restrictions | loadRestrictions: loadsServiceRestrictionTypes.DROPOFF_REQUIREMENTS }}
        </div>
        <ng-template #noTrailerInstructions> Not available </ng-template>
      </span>
    </div>
    <div *ngIf="load.providerDetails?.externalNotes" class="info-sub-title notes">
      Notes:
      <br />
      <div class="content" data-test="notes">
        {{ load.providerDetails?.externalNotes }}
      </div>
    </div>
    <ng-container *ngIf="isCarrier && carrierId === load?.carrier?.id">
      <div class="info-sub-title" *ngIf="load?.drivers">
        Driver
        <br />
        <span class="content">
          {{ load.drivers | nth: 0 | get: 'name':'N/A' }}
        </span>
      </div>

      <div class="info-sub-title">
        Truck
        <br />
        <span class="content">
          {{ load?.truck?.unitId || 'N/A' }}
        </span>
      </div>

      <div class="info-sub-title">
        Trailer
        <br />
        <span class="content" data-test="trailerNumber">
          {{ load?.trailers | nth: 0 | get: 'trailerNumber':'N/A' }}
        </span>
      </div>

      <div class="info-sub-title">
        Shipper
        <br />
        <span class="content">
          {{ load.locations | nth: 0 | get: 'customer.name':'N/A' }}
        </span>
      </div>

      <div class="info-sub-title">
        Consignee
        <br />
        <span class="content">
          {{ load.locations | nth: -1 | get: 'customer.name':'N/A' }}
        </span>
      </div>
    </ng-container>
  </div>
  <div
    class="book-button-wrapper"
    fxLayout="row"
    fxLayoutAlign="center center"
    fxLayoutGap="16px"
    fxLayout.lt-md="column"
    fxLayoutAlign.lt-md="start center"
  >
    <!-- Current carrier accepted the load and should show the book button so the can view the load -->
    <div *ngIf="isAcceptedCarrier; else notAccepted">
      <app-book-load-button
        data-test="book-load-button-accepted-carrier"
        [load]="load"
        [carrierDot]="carrierDot"
        [bookableWithPrice]="(load.paymentDetails?.price > 0 && load?.bookStatus === 'bookable') || false"
        (bookSuccess)="onBookSuccess($event)"
      >
      </app-book-load-button>
    </div>
  </div>
</div>

<ng-template #notAccepted>
  <app-book-load-button
    data-test="book-load-button"
    *ngIf="isValidAcceptedCarrier && !load?.carrierBid?.activeCounterOffer"
    [load]="load"
    [carrierDot]="carrierDot"
    [bookableWithPrice]="(load.paymentDetails?.price > 0 && load?.bookStatus === 'bookable') || false"
    (bookSuccess)="onBookSuccess($event)"
  >
  </app-book-load-button>
  <app-carrier-bid-button
    *appFeatures="carrierBidEnabled"
    [load]="load"
    (finishedAcceptingCounterAndBook)="onBookSuccess(load.id)"
    (finishedCreatingBid)="onBookSuccess(load.id)"
    data-test="bid-button"
  ></app-carrier-bid-button>
</ng-template>

<ng-template #isBroker>
  <ng-container *ngIf="load?.paymentDetails?.ratePerMile | lessThan: 5; else unknown">
    {{ load?.paymentDetails?.ratePerMile | currency: 'USD':'symbol':'1.0-2' }}
  </ng-container>
</ng-template>

<ng-template #noPrice>
  <button
    class="noAppointment"
    mat-raised-button
    [matTooltip]="load.providerDetails?.regionalManagerPhone | getPriceText: canBid"
    matTooltipClass="no-appt-tooltip"
  >
    <div class="no-appt-button" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="4px">
      <span>No Price Yet <mat-icon class="exclamation">error_outline</mat-icon></span>
    </div>
  </button>
</ng-template>

<ng-template #unknown> Unknown </ng-template>
