import gql from 'graphql-tag';
export const assetBridgeTrailers = `
  data {
    id
    deviceId
    licensePlateNumber
    trailerDetail{
      id
      status
      typeCode
      ownership
    }
    trailerNumber
  }
`;

export const SearchAssetBridgeTrailers = gql`
  query SearchAssetBridgeTrailers($customerCompany: String, $customerLotNumber: String, $pageNumber: Int, $pageSize: Int) {
    searchAssetBridgeTrailers(customerCompany: $customerCompany, customerLotNumber: $customerLotNumber, pageNumber: $pageNumber, pageSize: $pageSize) { ${assetBridgeTrailers} }
  }
`;
