import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ISearchFilter } from '@haulynx/types';
import { SearchMoreInfoDialogComponent } from '../search-more-info-dialog/search-more-info-dialog.component';

@Component({
  selector: 'app-search-more-info',
  templateUrl: './search-more-info.component.html',
  styleUrls: ['./search-more-info.component.scss'],
})
export class SearchMoreInfoComponent {
  @Input() filters: ISearchFilter[];

  constructor(private dialog: MatDialog) {}

  getMoreInfo(): void {
    this.dialog.open(SearchMoreInfoDialogComponent, {
      data: {
        filters: this.filters,
      },
    });
  }
}
