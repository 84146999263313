import { Component, Input } from '@angular/core';

@Component({
  selector: 'haulynx-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
  @Input() active = false;
  @Input() fullCover = false;
  @Input() transparentBackground = true;
  @Input() valignTop = false;
  @Input() diameter = 48;
}
