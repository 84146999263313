<div mat-accordian>
  <mat-expansion-panel *ngFor="let state of this.trailerStates" class="trailer-list" [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>Trailer</mat-panel-title>
      <mat-panel-description>
        <mat-slide-toggle
          (click)="$event.stopPropagation()"
          (change)="trailerToggled(state.trailer)"
          [(ngModel)]="state.checked"
        ></mat-slide-toggle>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <div class="trailer-content" fxLayout="column" fxLayoutGap="-20px" fxFlexFill>
      <div>
        <p>Trailer Type:</p>
        <span>{{ state.trailer.trailerOwnerType || '3rd-party' }}</span>
      </div>

      <div>
        <p>Trailer:</p>
        <span>{{ state.trailer.trailerNumber }}</span>
      </div>

      <div>
        <p>Trailer Position:</p>
        <span>
          <app-spinner [active]="(state.$position | async) === null" [diameter]="16"></app-spinner>
          {{ state.$position | async }}
        </span>
      </div>

      <div *ngIf="state.trailer.trailerCompany && state.trailer.trailerOwnerType">
        <p>Last Update:</p>
        <span>
          <app-spinner [active]="(state.$lastUpdate | async) === null" [diameter]="16"></app-spinner>
          {{ state.$lastUpdate | async | timeDiff: format:'estimate' }}
        </span>
      </div>
    </div>
  </mat-expansion-panel>
</div>
