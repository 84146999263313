import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UpdateUserInfoMutation } from '@haulynx/gql';
import { Broker, Driver, Notification, User, UserValidationResult } from '@haulynx/types';
import { toHttpParams } from '@haulynx/utils';
import 'firebase/database';
import { get } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GraphqlService } from '../graphql/graphql.service';
import { LocalStoreService } from './localstore.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public notifications: Notification[] = [];
  private callbacks = {};
  private token$: Observable<string> = new BehaviorSubject(null);
  private user$: Observable<User> = new BehaviorSubject(null);

  get user(): Observable<User> {
    return this.user$;
  }

  get token(): Observable<string> {
    return this.token$;
  }

  constructor(
    private localStoreService: LocalStoreService,
    private http: HttpClient,
    private graphqlService: GraphqlService
  ) {}

  clearData() {
    this.localStoreService.clearCache('user');
    this.localStoreService.clearCache('token');
  }

  setUser(user: User, expiration: number) {
    if (user && expiration) {
      this.localStoreService.setCached('user', user, expiration);
    }
  }

  setUser$(user: Observable<User>) {
    this.user$ = user;
  }

  setToken(token: string, expiration: number) {
    if (token && expiration) {
      this.localStoreService.setCached('token', token, expiration);
    }
  }

  setToken$(token: Observable<string>) {
    this.token$ = token;
  }

  getUser(uid: string): Observable<User> {
    return this.http.get<User>('/api/users/' + uid);
  }

  getUserList(filter = {}): Observable<User[]> {
    const params = toHttpParams(filter);

    return this.http.get<User[]>('/api/users', { params });
  }

  createUser(user: Partial<User>): Observable<any> {
    const options = { headers: { Source: 'web' } };

    return this.http.post('/api/users/signup', user, options);
  }

  update(body: any) {
    return this.http.patch('/api/users', body);
  }

  delete(userId: any) {
    return this.http.delete(`/api/users/${userId}`);
  }

  getUserNotifications(): Observable<Notification[]> {
    return this.http.get<Notification[]>('/api/users/dashboard/notifications');
  }

  clearNotifications(): Observable<any> {
    return this.http.post('/api/users/dashboard/notifications/clear', null);
  }

  public updateUser(body: any) {
    return this.http.patch('/api/users', body);
  }

  public verifyDriver(driver: Driver): Observable<boolean> {
    const { id, isVerified } = driver;

    return this.http.patch<boolean>(`/api/users/verifyDriver/${id}`, { isVerified });
  }

  public deleteUser(userId: string) {
    return this.http.delete(`/api/users/${userId}`).toPromise();
  }

  getBrokers(): Promise<Broker[]> {
    return this.http.get<Broker[]>('/api/brokers').toPromise();
  }

  refreshPosition(user: User) {
    return this.http.post(`/api/devices/refreshPosition/${user.id}`, {}).toPromise();
  }

  isCarrierOrHaulynxAdmin(): Observable<boolean> {
    return this.user.pipe(
      map((data) => {
        if (data?.isHaulynxAdmin || data?.isCompanyAdmin) {
          return true;
        } else return false;
      })
    );
  }

  isPC(): Observable<boolean> {
    return this.user.pipe(
      map((data) => {
        if (data?.pcEnabled) {
          return true;
        } else return false;
      })
    );
  }

  isYM(): Observable<boolean> {
    return this.user.pipe(
      map((data) => {
        if (data?.ymEnabled) {
          return true;
        } else return false;
      })
    );
  }

  isHaulynxAdmin(): Observable<boolean> {
    return this.user.pipe(
      map((data) => {
        if (data && data?.isHaulynxAdmin) {
          return true;
        } else return false;
      })
    );
  }

  public listenForNotifications(name: string, func) {
    this.callbacks[name] = func;
  }

  search(filter = {}): Observable<any> {
    return this.http.post('/api/v2/user/search', filter);
  }

  isValidDriversLicense(country: string, state: string, licenseNumber: string): Observable<any> {
    const params = { country, state, licenseNumber };
    const options = { headers: { Source: 'web' } };
    return this.http.post<any>(`/api/users/isValidDriversLicense`, params, options);
  }

  updateUserInfo(variables: { userId: string; userInfo: Partial<User> }): Observable<UserValidationResult> {
    return this.graphqlService
      .mutate({
        mutation: UpdateUserInfoMutation,
        variables,
      })
      .pipe(
        map((result: { data: { updateUserInfo: UserValidationResult }; error: { message: string } }) =>
          get(result, `data.updateUserInfo`, null)
        )
      );
  }
}
