<div class="search-location-container">
  <mat-form-field class="example-full-width" appearance="outline" floatLabel="always">
    <mat-label>{{ label | titlecase }}</mat-label>
    <input
      matInput
      [matAutocomplete]="auto"
      [formControl]="searchForm?.get('address')"
      [placeholder]="label === 'origin' ? 'Where do you want to pick up?' : 'Where do you want to go?'"
      data-test="searchField"
    />
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="setPrediction($event)">
      <mat-option [value]="'Current Location'" data-test="currentLocation">
        <span>Current Location</span>
      </mat-option>
      <ng-container *ngIf="searchQueryStarted; else recentSearchesTemplate">
        <mat-option *ngFor="let prediction of predictions$ | async" [value]="prediction" data-test="searchPrediction">
          <span>{{ prediction?.place_name ?? '' }}</span>
        </mat-option>
      </ng-container>
      <ng-template #recentSearchesTemplate>
        <mat-option *ngFor="let recentSearch of recentSearches" [value]="recentSearch" data-test="recentSearch"
          ><span>{{ recentSearch?.place_name ?? '' }}</span></mat-option
        >
      </ng-template>
    </mat-autocomplete>
  </mat-form-field>
  <haulynx-blue-select
    [options]="radiusValues"
    [label]="'Radius'"
    [formControl]="searchForm?.get('radiusMiles')"
    data-test="radiusSelect"
  >
  </haulynx-blue-select>
</div>
