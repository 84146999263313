export interface ZipLanePageAndSort {
  page: number;
  limit: number;
  sort: 'ASC' | 'DESC' | 'asc' | 'desc';
  order?: string;
  // The next fields are usually returned from the server
  total?: number;
  totalPages?: number;
  nextPage?: number;
  previousPage?: number;
}
