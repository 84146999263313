import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@haulynx/modules';
import { SatDatepickerModule } from 'saturn-datepicker';
import { DateRangeComponent } from './date-range.component';

@NgModule({
  declarations: [DateRangeComponent],
  imports: [CommonModule, SatDatepickerModule, ReactiveFormsModule, MaterialModule],
  exports: [DateRangeComponent],
})
export class DateRangeModule {}
