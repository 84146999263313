export class Duration {
  mins: number;

  constructor(mins: number) {
    this.mins = mins;
  }

  toString() {
    const h = Math.floor(this.mins / 60);
    const m = Math.round(this.mins % 60);
    return (h < 10 ? '0' + h : h) + ':' + (m < 10 ? '0' + m : m);
  }
}
