import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AccountRoute, User } from '@haulynx/types';

@Component({
  selector: 'haulynx-account-header',
  templateUrl: './account-header.component.html',
  styleUrls: ['./account-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountHeaderComponent {
  @Input() user: User;
  @Output() signOutClicked = new EventEmitter<void>();
  @Output() routeClicked = new EventEmitter<AccountRoute>();
  public AccountRoute = AccountRoute;

  public about() {
    this.dispatchEvent('about');
    window.open('https://www.xpresstechfreight.com/about-us/', '_blank');
  }

  public resources() {
    this.dispatchEvent('resources');
    window.open('https://resources.xpresstechfreight.com/blog', '_blank');
  }

  public route(route: AccountRoute) {
    this.routeClicked.emit(route);
    this.dispatchEvent(route === AccountRoute.LOGIN ? 'signIn' : route === AccountRoute.CREATE ? 'signUp' : '');
  }

  public signOut() {
    this.dispatchEvent('signOut');
    this.signOutClicked.emit();
  }

  /**
   * Mobile clients need a window event to handle navigation
   */
  private dispatchEvent(eventName: string) {
    window.dispatchEvent(new Event(eventName));
  }
}
