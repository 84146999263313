import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { MatChipInputEvent, MatChipList } from '@angular/material/chips';
const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$/;
const PHONE_REGEX = /^\(?[\d]{3}\)?[\s-]?[\d]{3}[\s-]?[\d]{4}$/;

@Component({
  selector: 'load-share-dialog',
  templateUrl: `./load-share.component.html`,
  styleUrls: ['./load-share.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareLoadDialog implements OnInit {
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild('emailChipList') emailChipList: MatChipList;
  @ViewChild('phoneChipList') phoneChipList: MatChipList;
  public shareForm: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder) {
    this.shareForm = this.fb.group({
      loadId: [this.data.loadId as string, Validators.required],
      email: this.fb.array([], [this.validateEmails]),
      phone: this.fb.array([], [this.validatePhones]),
    });
  }

  ngOnInit() {
    this.shareForm
      .get('email')
      .statusChanges.subscribe((status) => (this.emailChipList.errorState = status === 'INVALID'));
    this.shareForm
      .get('phone')
      .statusChanges.subscribe((status) => (this.phoneChipList.errorState = status === 'INVALID'));
  }

  formData(control: 'email' | 'phone'): FormArray {
    return <FormArray>this.shareForm.get(control);
  }

  add(event: MatChipInputEvent, control: 'email' | 'phone'): void {
    const input = event.input;
    const value = event.value;

    // Add value
    if ((value || '').trim()) {
      const array = <FormArray>this.shareForm.get(control);
      const controlValue = this.fb.control(value.trim());
      array.push(controlValue);
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(control: 'email' | 'phone', index: number) {
    const array = <FormArray>this.shareForm.get(control);
    array.removeAt(index);
  }

  private validateEmails(c: FormControl) {
    const emails = c.value.every((value: string) => value.match(EMAIL_REGEX));

    if (!emails) {
      return { email: { valid: false } };
    }

    return null;
  }

  private validatePhones(c: FormControl) {
    const phoneNumbers = c.value.every((value: string) => value.match(PHONE_REGEX));

    if (!phoneNumbers) {
      return { phone: { valid: false } };
    }

    return null;
  }
}
