import { LoadsServiceOrderByType } from '../../loads-service/loads-service-provider';

export interface VirtualColumn {
  /**
   * This will be used as the table header value
   */
  label: string;

  /**
   * This will be used as the table header value and a fallback for the supplied data's value
   */
  id: string;

  /**
   * Used with lodash `get` to access properties in an object.
   * If no dataIndex is supplied, data property will be retrieved using the id
   */
  dataIndex?: string;

  /**
   * If the value is null, what should the default value show
   */
  defaultValue?: string;

  /**
   * If the computed value of the data is 0, show the default be shown instead of 0
   */
  showDefaultValueWhenZero?: boolean;

  /**
   * Whether this should be a custom cell or not and overwridden with a template outlet
   */
  custom?: boolean;

  /**
   * When a custom width is wanted for the column
   */
  width?: number;

  /**
   * Should the column stick to the left
   * Use this if you do not want your sticky left item to have a box shadow
   */
  stickyLeft?: boolean;
  /**
   * Should the column stick to the right
   * Use this if you do not want your sticky right item to have a box shadow
   */
  stickyRight?: boolean;
  /**
   * Is the column the last sticky left item?
   * This should be used when there are more then one sticky left items
   * Use this if you want your sticky left item to have a box shadow
   */
  lastLeftSticky?: boolean;
  /**
   * Is the column the first sticky right item?
   * This should be used when there are more then one sticky right items
   * Use this if you want your sticky right item to have a box shadow
   */
  firstRightSticky?: boolean;

  /**
   * Should the z-index of a sticky header be pushed behind the other overflow-x scrolling header labels
   */
  pushStickyHeaderBack?: boolean;

  /**
   * Where the label will be aligned in the table header
   * @default 'left'
   */
  headerAlignment?: Alignment;

  /**
   * Should the separator on the right of the header be hidden
   */
  hideHeaderRightSeparator?: boolean;

  /**
   * How the loading bars should look when the table is loading
   */
  loadingStyle?: VirtualColumnLoadingStyle[];

  /**
   * Does the column have a sorting type that should be used to show the sort arrow
   */
  sortType?: LoadsServiceOrderByType;

  /**
   * Does the column have additional sorting types
   */
  otherSortTypes?: LoadsServiceOrderByType[];

  /**
   * Should there be a pipe used to transform data
   */
  pipe?: VirtualColumnPipe;
}

/**
 * How the item should be aligned
 */
export type Alignment = 'left' | 'center' | 'right';

export interface VirtualColumnLoadingStyle {
  /**
   * Will be used in pixels
   * @default 25
   */
  pixelHeight?: number;

  /**
   * Will be used as a percentage of the column width
   * @default 100
   */
  percentageWidth?: number;

  /**
   * How the loading indicator will be aligned in the cell
   * @default 'left'
   */
  align?: Alignment;

  /**
   * Should a margin bottom be applied to the element
   */
  marginBottom?: number;
}

export interface VirtualColumnPipe {
  type: VirtualColumnPipeType;
  /**
   * Decimal representation options, specified by a string in the following format:
   * {minIntegerDigits}.{minFractionDigits}-{maxFractionDigits}.
  
   * minIntegerDigits: The minimum number of integer digits before the decimal point. Default is 1.
   * minFractionDigits: The minimum number of digits after the decimal point. Default is 2.
   * maxFractionDigits: The maximum number of digits after the decimal point. Default is 2.
   */
  digitsInfo?: string;
}

export enum VirtualColumnPipeType {
  date,
  upperCase,
  lowerCase,
  currency,
  decimal,
  percent,
}
