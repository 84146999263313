import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  ActiveLoadsTableModule,
  AddDriverModule,
  AddTrailerModule,
  AddTruckModule,
  CustomerDetailsModule,
  DataTableModule,
  LoadDispatchFormModule,
  LoadPriceModule,
  LoadQuickActionsModule,
  LoadQuickDriverFormModule,
  LoadQuickLocationFormModule,
  LoadTableSearchBarModule,
  SpinnerModule,
} from '@haulynx/components';
import { PipesModule } from '@haulynx/pipes';
import { LoadFormService, StepStatusService } from '@haulynx/services';
import { LoadDetailsStateModule, LoadEntitiesStateModule } from '@haulynx/store';
import { CompletedLoadSearchContainerRoutingModule } from './completed-load-search-container-routing.module';
import { CompletedLoadSearchContainerComponent } from './completed-load-search-container.component';

@NgModule({
  declarations: [CompletedLoadSearchContainerComponent],
  imports: [
    CommonModule,
    CompletedLoadSearchContainerRoutingModule,
    LoadTableSearchBarModule,
    ActiveLoadsTableModule,
    LoadQuickActionsModule,
    LoadQuickDriverFormModule,
    LoadDetailsStateModule,
    LoadDispatchFormModule,
    LoadQuickLocationFormModule,
    CustomerDetailsModule,
    AddDriverModule,
    AddTruckModule,
    AddTrailerModule,
    DataTableModule,
    SpinnerModule,
    PipesModule,
    LoadPriceModule,
    LoadEntitiesStateModule,
  ],
  providers: [LoadFormService, StepStatusService],
})
export class CompletedLoadSearchContainerModule {}
