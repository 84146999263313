import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CarrierLane, IColumns, IPagination } from '@haulynx/types';

@Component({
  selector: 'haulynx-carrier-lanes',
  templateUrl: './carrier-lanes.component.html',
  styleUrls: ['./carrier-lanes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarrierLanesComponent {
  @Input() lanes: CarrierLane[] = [];
  @Input() loading = false;
  @Input() pagination: IPagination;
  @Input() checkBoxSelectAll = true;
  @Input() checkBoxSelection = true;
  @Output() onSelect = new EventEmitter();
  @Output() onPagination = new EventEmitter();

  constructor() {}

  lanesColumns: IColumns[] = [
    {
      label: 'Origin City',
      dataIndex: 'orgCity',
      initiallySortedBy: 'asc',
    },
    {
      label: 'State',
      dataIndex: 'orgState',
    },
    {
      label: 'Destination City',
      dataIndex: 'destCity',
    },
    {
      label: 'State',
      dataIndex: 'destState',
    },
  ];

  select(row) {
    this.onSelect.emit(row);
  }

  paginationChange(pagination) {
    this.onPagination.emit(pagination);
  }
}
