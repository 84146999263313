import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@haulynx/guards';
import { carriersTabs } from '@haulynx/types';
import { CarrierDashboardComponent } from './carrier-dashboard/carrier-dashboard.component';
import { CarrierLoadDetailsComponent } from './carrier-load-details/carrier-load-details.component';
import { CarrierSearchComponent } from './carrier-search/carrier-search.component';
import { CarrierComponent } from './carrier/carrier.component';

const routes: Routes = [
  {
    path: '',
    component: CarrierComponent,
    data: { tabs: carriersTabs },
    children: [
      {
        path: 'search',
        canActivate: [AuthGuard],
        component: CarrierSearchComponent,
      },
      {
        path: 'view/:dot',
        canActivate: [AuthGuard],
        component: CarrierDashboardComponent,
      },
      {
        path: 'load/:id',
        component: CarrierLoadDetailsComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CarriersRoutingModule {}
