import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { LocalStoreService } from '@haulynx/services';
import { Broker, Carrier, User } from '@haulynx/types';
import { getBrokerIdFromEmail, isValidCarrier } from '@haulynx/utils';
import { Store } from '@ngrx/store';
import { differenceInMinutes } from 'date-fns';
import { get } from 'lodash';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserEntityService } from '../async-entity-models/user';
import { AppSettingsActions } from './app-settings.actions';
import {
  carrierStateSelector,
  isLoadingStateSelector,
  loadDetailsRedirectUrlSelector,
  loadFeedPermissionStateSelector,
  widgetReferrerSelector,
} from './app-settings.selectors';
import { AppState } from './app.reducers';

const YPageIdentifier = 'YPage',
  YPageExpirationMinutes = 5;

@Injectable({
  providedIn: 'root',
})
export class AppModel {
  user$: Observable<User> = this.userEntityService.user$;
  userEmail$: Observable<string> = this.user$.pipe(map((user) => user && user.email));
  id$: Observable<string> = this.user$.pipe(map((user: User) => user && user.id));
  userTimeZone$: Observable<string> = this.user$.pipe(map((user) => get(user, 'prefs.timeZone', null)));
  userCarrier$: Observable<Carrier | null> = this.user$.pipe(map((user: User) => user?.carrier));
  userBroker$: Observable<Broker | null> = this.user$.pipe(map((user) => (user && user.broker) || null));
  showRmisBanner$: Observable<boolean>;
  isHaulynxAdmin$: Observable<boolean> = this.user$.pipe(map((user: User) => (user && user.isHaulynxAdmin) || false));
  brokerId$: Observable<string> = this.user$.pipe(
    map((user: User) => {
      return user?.usxId ?? (user?.email ? getBrokerIdFromEmail(user.email) : null);
    })
  );
  token$: Observable<string> = this.userEntityService.token$;
  loadFeedPermission$: Observable<boolean> = this.store.select(loadFeedPermissionStateSelector);
  loadDetailsRedirectUrl$: Observable<string> = this.store.select(loadDetailsRedirectUrlSelector);
  isLoading$: Observable<boolean> = this.store.select(isLoadingStateSelector);
  widgetReferrer$: Observable<string> = this.store.select(widgetReferrerSelector);
  redirectDashboard$: Observable<string | null> = combineLatest([
    this.loadFeedPermission$,
    this.isLoading$,
    this.user$,
    this.loadDetailsRedirectUrl$,
  ]).pipe(
    map(([loadFeed, isLoading, user, loadDetailsRedirectUrl]) => {
      if (loadDetailsRedirectUrl && user) {
        return loadDetailsRedirectUrl;
      }

      if (!isLoading && user) {
        const { returnUrl = null } = this.activatedRoute.snapshot.queryParams || {};
        if (returnUrl) {
          return returnUrl;
        } else if (loadFeed) {
          return user.broker ? '/loads/search?bookStatus=bookable&bookStatus=viewable' : `/dashboard/loads/search/all`;
        } else {
          return `/dashboard/loads/search/active`;
        }
      }

      return null;
    })
  );

  isValidCarrier$: Observable<boolean> = this.store.select(carrierStateSelector).pipe(map(isValidCarrier));
  ypage: Date;
  constructor(
    private store: Store<AppState>,
    private activatedRoute: ActivatedRoute,
    private userEntityService: UserEntityService,
    private localStoreService: LocalStoreService
  ) {
    this.localStoreService.get<Date>(YPageIdentifier).then((data) => {
      this.ypage = data;
    });

    this.showRmisBanner$ = combineLatest([this.userCarrier$, this.activatedRoute.queryParams]).pipe(
      map(([carrier, params]: [Carrier, Params]): boolean => {
        if (!carrier) return false;
        const yPage = this.containsOriginYPage(params);
        if (yPage) return !yPage;
        return !isValidCarrier(carrier);
      })
    );

    this.isValidCarrier$ = combineLatest([
      this.store.select(carrierStateSelector),
      this.activatedRoute.queryParams,
    ]).pipe(
      map(([carrier, params]: [Carrier, Params]) => {
        const yPage = this.containsOriginYPage(params);
        if (yPage) return yPage;
        return isValidCarrier(carrier);
      })
    );
  }

  private containsOriginYPage(params: Params): boolean {
    const { origin = null } = params;

    // Check if origin=YPage exists. If so, user came from RMIS and should not show launch RMIS banner and also be a valid carrier
    if (origin === YPageIdentifier) {
      this.localStoreService.set(YPageIdentifier, new Date().getTime());
      return true;
    }

    if (this.ypage) {
      // Check to see if it is less than 5 minutes old
      const lastYPage = new Date(this.ypage);
      const difference = differenceInMinutes(new Date(), lastYPage);
      if (difference < YPageExpirationMinutes) return true;

      // Remove the ypage localstorage key since it is older than 5 minutes
      this.localStoreService.clear(YPageIdentifier);
    }

    return false;
  }

  updateUser(user: Partial<User>): void {
    this.store.dispatch(AppSettingsActions.updateUser(user));
  }

  updateToken(token: string): void {
    this.store.dispatch(AppSettingsActions.updateToken(token));
  }

  logout(email: string): void {
    this.store.dispatch(AppSettingsActions.logOut(email));
  }

  login(email: string, password: string): void {
    this.store.dispatch(AppSettingsActions.logIn({ email, password }));
  }

  setWidgetReferrer(widget: string): void {
    this.store.dispatch(AppSettingsActions.setWidgetReferrer(widget));
  }

  setLoadDetailsRedirectUrl(url: string): void {
    this.store.dispatch(AppSettingsActions.setLoadDetailsRedirectUrl(url));
  }
}
