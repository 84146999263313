import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ElementsModule } from '@haulynx/elements';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { DropDownModule } from '../drop-down/drop-down.module';
import { ContractsFormComponent } from './contracts-form.component';

@NgModule({
  imports: [
    CommonModule,
    PipesModule,
    MaterialModule,
    FlexLayoutModule,
    ElementsModule,
    ReactiveFormsModule,
    FormsModule,
    DropDownModule,
  ],
  exports: [ContractsFormComponent],
  declarations: [ContractsFormComponent],
})
export class ContractsFormModule {}
