import { Injectable } from '@angular/core';
import { FireBaseNotificationService, NotificationsService } from '@haulynx/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '../../main-store/app.reducers';
import { DispatchAction } from '../../shared/store/helpers/action';
import { NotificationActions, NotificationActionTypes } from './notification.action';
import { notificationQuerySelectors } from './notification.selectors';
import { NotificationEventFilterParams } from './notification.state';

@Injectable()
export class NotificationEffects {
  getNotification = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActionTypes.SEARCH_NOTIFICATION),
      map((action: DispatchAction) => action.payload),
      withLatestFrom(this.store.select(notificationQuerySelectors)),
      switchMap(([payload, query]: [NotificationEventFilterParams, NotificationEventFilterParams]) => {
        const { recipient, eventType, pastDays, targetCarrierDot } = payload || query;
        const filterParams = { eventType, recipient, pastDays, targetCarrierDot };

        return this.notificationsService.getNotifications(filterParams).pipe(
          map((entities) => NotificationActions.searchSuccess({ entities })),
          catchError((error) => of(NotificationActions.searchError(error)))
        );
      })
    )
  );

  deactivateNotifications = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActionTypes.DEACTIVATE_NOTIFICATIONS),
      map((action: DispatchAction) => action.payload),
      switchMap((payload: { creatorId: string; notificationIds: string[] }) =>
        this.notificationsService.deactivateNotifications(payload).pipe(
          map(() => NotificationActions.deactivateNotificationsSuccess()),
          catchError(() => of(NotificationActions.deactivateNotificationsError()))
        )
      )
    )
  );

  refresh = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActionTypes.REFRESH, NotificationActionTypes.DEACTIVATE_NOTIFICATIONS_SUCCESS),
      map((action: DispatchAction) => action.payload),
      withLatestFrom(this.store.select(notificationQuerySelectors)),
      switchMap(([payload, query]: [NotificationEventFilterParams, NotificationEventFilterParams]) => {
        const { recipient, eventType, pastDays, targetCarrierDot } = payload || query;
        const filterParams = { eventType, recipient, pastDays, targetCarrierDot };

        return this.notificationsService.getNotifications(filterParams).pipe(
          map((entities) => NotificationActions.searchSuccess({ entities })),
          catchError((error) => of(NotificationActions.searchError(error)))
        );
      })
    )
  );

  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private firebaseNotificationService: FireBaseNotificationService,
    private notificationsService: NotificationsService
  ) {}
}
