import { A } from '@angular/cdk/keycodes';
import { KeyValue } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BidVmService, NotificationsService } from '@haulynx/services';
import {
  Bid,
  BidHistory,
  BidOfferState,
  BidSortTypes,
  BidSourceType,
  BidStatusOption,
  BidStatusType,
  Carrier,
  FFState,
  KeyValuePair,
  LoadsServiceLoad,
  UpdateBidInput,
  User,
} from '@haulynx/types';
import { bidActionMenu, dedicatedFreightIsBookable } from '@haulynx/utils';
import { isNumber, orderBy } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { AppDropDownComponent } from '../../../drop-down/components/app-drop-down/app-drop-down.component';

@Component({
  selector: 'app-bid-item',
  templateUrl: './bid-item.component.html',
  styleUrls: ['./bid-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BidItemComponent implements OnChanges {
  @ViewChild(AppDropDownComponent, { static: true }) dropDown: TemplateRef<AppDropDownComponent>;

  @Input() bid: Bid;
  @Input() isActive = false;
  @Input() bidHistory: BidHistory[] = [];
  @Input() disabled = false;
  @Input() load: LoadsServiceLoad;
  @Input() bidStatuses: BidStatusOption[] = [];
  @Input() isLoadingHistory = false;
  @Input() isLoadingAccept = false;
  @Input() isLoadingCounterOffer = false;
  @Input() user: User;
  @Input() userIsBroker = true;
  @Input() userTimeZone: string;
  @Input() features: FFState;

  @Output() dataChange = new EventEmitter<{ id: string; carrier: Partial<Carrier> } & UpdateBidInput>();
  @Output() openHistory = new EventEmitter<Bid>();
  @Output() getHistory = new EventEmitter<Bid>();
  @Output() acceptBid = new EventEmitter<Bid>();
  @Output() noCapacity = new EventEmitter<Bid>();
  @Output() openCounterOffer = new EventEmitter<Bid>();

  actionMenu = bidActionMenu;
  bidForm: FormGroup;
  currentBidPrice: number;
  statusToAccept = BidStatusType.LIVE;
  bidMessageData$ = new BehaviorSubject<BidMessage>({ doShow: false });
  bidStatusOptions$ = new BehaviorSubject<BidStatusOption[]>([]);
  now$ = new BehaviorSubject<number>(new Date().valueOf());
  displayStrikeThrough = true;
  previousPrice = 0;
  textColor = '';
  enableCounter = false;
  enableAward = false;
  showCounterText = false;

  colorKey: KeyValuePair[] = [
    { key: 'Blue', value: '--link-blue' },
    { key: 'Orange', value: '--haulynx-orange' },
    {
      key: 'Green',
      value: '--bg50',
    },
    {
      key: 'Red',
      value: '--sr50',
    },
  ];

  constructor(private bidVmService: BidVmService, private notification: NotificationsService) {
    this.bidForm = this.bidVmService.createFormGroup(this.bid);
  }

  sortBids(bids: Bid[], sortedBy: BidSortTypes): Bid[] {
    const order = sortedBy === BidSortTypes.RECENT ? 'desc' : 'asc';

    return orderBy(bids, [sortedBy.toLowerCase()], [order]);
  }

  onDataChange(bidForm: Bid, fieldName: keyof UpdateBidInput): void {
    if (bidForm.status === BidStatusType.ACCEPTED) {
      this.onAcceptBid({ ...this.bid, ...bidForm });
    } else {
      const price: number = this.bidForm.controls['price'].value;
      const priceIsValid: boolean = isNumber(price) && price > 0;
      if (priceIsValid) {
        if (fieldName === 'price' && price === this.currentBidPrice) return;
        this.currentBidPrice = price;
        this.dataChange.emit({
          id: this.bid.id,
          carrier: this.bid.carrier,
          [fieldName]: bidForm[fieldName],
        });
      }
    }
  }

  onAcceptBid(bid: Bid): void {
    if (dedicatedFreightIsBookable(this.load, this.features)) {
      this.acceptBid.emit(bid);
    } else {
      this.notification.error('You are not permitted to book dedicated freight', 'Unauthorized');
      this.bidForm.patchValue({
        status: this.bid.status,
      });
      this.bidStatusOptions$.next([...this.bidStatusOptions$.value]);
    }
  }

  onOpenHistory(bid: Bid): void {
    this.openHistory.emit(bid);
  }

  onGetHistory(bid: Bid): void {
    this.getHistory.emit(bid);
  }

  onEnter(event: KeyboardEvent): void {
    event.stopImmediatePropagation();
    this.dropDown['autocompleteInput'].nativeElement.focus();
  }

  onOpenCounterOffer(event: MouseEvent, bid: Bid): void {
    event.stopImmediatePropagation();
    this.openCounterOffer.emit(bid);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { bid } = changes;

    if (bid) {
      this.currentBidPrice = bid.currentValue.price;
      this.bidVmService.setValue(this.bidForm, this.bid);
      this.setBidState({ ...bid.currentValue });
    }

    if (this.disabled) {
      this.bidForm.disable();
    }
    this.bidStatusOptions$.next(this.bidStatuses);
    this.bidMessageData$.next(this.createBidMessageData(this.bid, this.bidHistory));
  }

  private createBidMessageData(bid: Bid, bidHistory: BidHistory[]): BidMessage {
    if (!bid || !bidHistory) {
      return { doShow: false };
    }
    if (this.mostRecentBidAction(bid, bidHistory) === 'broker') {
      if (bid.activeCounterOffer) {
        return {
          text: 'Last: Counter Offer Sent for',
          color: 'yellow',
          isNew: false,
          price: bid.activeCounterOffer.price,
          doShow: true,
        };
      } else {
        return { doShow: false };
      }
    } else if (bid.status === BidStatusType.PENDING || bid.status === BidStatusType.LIVE) {
      // carrier made the last action
      return {
        text: 'Carrier has submitted a new offer',
        color: 'green',
        isNew: true,
        doShow: true,
      };
    } else {
      return { doShow: false };
    }
  }

  private mostRecentBidAction(bid: Bid, bidHistory: BidHistory[] = []): 'carrier' | 'broker' {
    const mostRecentEvent: BidHistory = bidHistory.reduce((prev, curr) => {
      if (!prev) {
        return curr;
      }
      return curr.createdAt > prev.createdAt ? curr : prev;
    }, null);

    if (mostRecentEvent) {
      return mostRecentEvent.createdBy?.carrier ? 'carrier' : 'broker';
    } else {
      return 'broker';
    }
  }

  getPreviousPrice(user: BidSourceType, bidHistory: BidHistory[] = [], event?: string): number {
    const sortedBidHistory: BidHistory[] = bidHistory.sort((a, b) => b.createdAt - a.createdAt);
    const result = sortedBidHistory.find((bid) =>
      user === BidSourceType.CARRIER ? bid.createdBy?.carrier : !bid.createdBy?.carrier
    )?.data?.price;

    if (event) {
      return sortedBidHistory.find(
        (bid) =>
          (user === BidSourceType.CARRIER ? bid.createdBy?.carrier : !bid.createdBy?.carrier) && bid.event === event
      )?.data?.price;
    }

    return result;
  }

  clickEvent(event: KeyValuePair[]) {
    if (event[0].key === "Set to 'No Capacity'") {
      this.dataChange.emit({
        id: this.bid.id,
        carrier: this.bid.carrier,
        status: BidStatusType.NO_CAPACITY,
      });
    }
  }

  setBidState(bid: Bid): void {
    this.previousPrice = this.getPreviousPrice(
      bid.sourceType === BidSourceType.BROKER ? BidSourceType.CARRIER : BidSourceType.BROKER,
      [...bid?.bidHistory]
    );
    this.textColor = this.colorKey.find((color) => color.key === bid?.offerStateColor)?.value as string;

    this.showCounterText = false;

    switch (bid.offerState) {
      case BidOfferState.COUNTER_OFFER:
      case BidOfferState.OFFER_UPDATED:
        this.bidForm.get('price').patchValue(this.getPreviousPrice(BidSourceType.BROKER, [...bid?.bidHistory]));
        this.showCounterText = true;
      case BidOfferState.OFFER_HOLD:
      case BidOfferState.BROKER_OFFER:
        this.enableCounter = true;
        this.enableAward = false;
        break;

      case BidOfferState.COUNTER_BID:
        this.showCounterText = true;
      case BidOfferState.BID_HELD:
      case BidOfferState.CARRIER_BID:
        this.enableCounter = true;
        this.enableAward = true;
        break;

      case BidOfferState.FIRM_BID:
      case BidOfferState.FINAL_BID:
        this.enableCounter = false;
        this.enableAward = true;
        break;

      case BidOfferState.FIRM_OFFER:
      case BidOfferState.FINAL_OFFER:
      case BidOfferState.ACCEPT_FIRM_BID:
      case BidOfferState.ACCEPT_BID:
      case BidOfferState.ACCEPT_OFFER:
      case BidOfferState.REJECT_BID:
      case BidOfferState.REJECT_FIRM_BID:
      case BidOfferState.REJECT_OFFER:
      default:
        this.enableCounter = false;
        this.enableAward = false;
        break;
    }
  }
}

interface BidMessage {
  text?: string;
  color?: 'yellow' | 'green';
  isNew?: boolean;
  price?: number;
  doShow: boolean;
}
