<div class="rmis-account-setup-status" *ngIf="showAccountSetup">
  <haulynx-white-progress-spinner
    mode="determinate"
    diameter="36"
    [value]="percentComplete"
    [matMenuTriggerFor]="accountStatusMenu"
  ></haulynx-white-progress-spinner>
  <mat-menu #accountStatusMenu="matMenu" xPosition="before" class="progress-items-menu">
    <div (click)="$event.stopPropagation()">
      <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="title">Account Setup</div>
        <button mat-icon-button (click)="closeMenu()"><mat-icon>close</mat-icon></button>
      </div>
      <div class="checks" fxLayout="column">
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="16px"
          [ngClass]="{ complete: (registration | async)?.fleetRegistered || false }"
        >
          <mat-icon>{{ (registration | async)?.fleetRegistered ? 'task_alt' : 'radio_button_unchecked' }}</mat-icon>
          <div class="description">Carrier Profile</div>
        </div>
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="16px"
          [ngClass]="{ complete: (registration | async)?.w9Orw8Registered || false }"
        >
          <mat-icon>{{ (registration | async)?.w9Orw8Registered ? 'task_alt' : 'radio_button_unchecked' }}</mat-icon>
          <div class="description">Tax Documentation</div>
        </div>
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="16px"
          [ngClass]="{ complete: (registration | async)?.comcheckAdvancesRegistered || false }"
        >
          <mat-icon>{{
            (registration | async)?.comcheckAdvancesRegistered ? 'task_alt' : 'radio_button_unchecked'
          }}</mat-icon>
          <div class="description">Payment Advances</div>
        </div>
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="16px"
          [ngClass]="{ complete: (registration | async)?.paymentOptionRegistered || false }"
        >
          <mat-icon>{{
            (registration | async)?.paymentOptionRegistered ? 'task_alt' : 'radio_button_unchecked'
          }}</mat-icon>
          <div class="description">Payment Options</div>
        </div>
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="16px"
          [ngClass]="{ complete: (registration | async)?.supplierDiversityRegistered || false }"
        >
          <mat-icon>{{
            (registration | async)?.supplierDiversityRegistered ? 'task_alt' : 'radio_button_unchecked'
          }}</mat-icon>
          <div class="description">Supplier Diversity</div>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="continue">
        <ng-container *ngIf="percentComplete !== 100; else completed">
          <haulynx-white-outline-button
            text="Continue Acct. Setup"
            icon="arrow_forward"
            iconPosition="after"
            tooltipText="Changes to your account may take up to 1 minute to process."
            [loading]="loadingContinue$ | async"
            (clicked)="continueToRmis()"
          ></haulynx-white-outline-button>
        </ng-container>
        <ng-template #completed>
          <haulynx-white-outline-button text="Continue" (clicked)="complete()"></haulynx-white-outline-button>
        </ng-template>
      </div>
    </div>
  </mat-menu>
</div>
