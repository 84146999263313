import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { QueryOptions } from '@haulynx/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadFeedFireDatabaseService extends AngularFireDatabase {
  getList(path: string, options: QueryOptions = {}): Observable<any[]> {
    return this.list(path, (ref) => {
      const { limit = 100 } = options;

      if (limit) {
        ref = this.limit(ref, limit);
      }

      return ref;
    }).valueChanges();
  }

  getItem(path: string): Observable<any> {
    return this.object(path).valueChanges();
  }

  addItem(listName: string, data: any) {
    const listRef = this.list(listName);

    listRef.push(data);
  }

  updateItem(listName: string, itemId: string, data: any): void {
    const listRef = this.list(listName);

    listRef.update(itemId, data);
  }

  private limit(ref, limit) {
    return ref.limitToFirst(limit);
  }
}
