import { Component, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DeleteUserDialog, UserFormComponent } from '@haulynx/components';
import { UserService } from '@haulynx/services';
import { AppModel, CommonEntities, UsersModel } from '@haulynx/store';
import { AccountType, Driver, EditableUserProperties, Truck, User } from '@haulynx/types';
import { aliveWhile, listToArray } from '@haulynx/utils';
import { cloneDeep, forEach, get } from 'lodash';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'haulynx-users',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.scss'],
})
export class UserSearchComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  users: User[] | unknown;
  userId: string;
  user: User;
  isLoading = false;
  userPageOptions = [10, 20, 30, 50, 100, 500];
  alive = aliveWhile();
  truckData: unknown = {};
  editableProperties = EditableUserProperties;
  private accountType = AccountType;

  constructor(
    private userService: UserService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public appModel: AppModel,
    public usersModel: UsersModel,
    private router: Router,
    private commonEntities: CommonEntities,
    @Optional() public dialogRef: MatDialogRef<UserFormComponent | DeleteUserDialog>
  ) {
    this.appModel.user$.pipe(takeUntil(this.alive)).subscribe((user) => {
      this.user = user;

      const query = { all: 'true' };
      this.usersModel.search({ query });
    });

    this.usersModel.entities$.pipe(takeUntil(this.alive)).subscribe((users) => {
      this.users = cloneDeep(listToArray(users));
    });
    this.commonEntities.trucks.entities$.pipe(takeUntil(this.alive)).subscribe((trucks) => {
      trucks.map((truck: Truck) => {
        if (truck.userId) {
          this.truckData[truck.userId] = { truckId: truck.id, imei: truck.imei };
        }
        if (truck.coDrivers) {
          forEach(truck.coDrivers, (driver: User) => {
            this.truckData[driver.id] = { truckId: truck.id, imei: truck.imei };
          });
        }
      });
    });
  }

  ngOnInit() {
    const query = {
      carrierId: get(this.user, 'carrier.id', ''),
    };
    this.commonEntities.trucks.search({ key: 'users-trucks', query: query });
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }

  normalizeFormData(): { accountType: AccountType; company: Partial<User> } {
    const { carrier, broker, shipper } = this.user;
    let accountType: AccountType = null,
      company: Partial<User> = null;

    if (carrier) {
      accountType = this.accountType.DRIVER;
      company = carrier;
    } else if (broker) {
      accountType = this.accountType.BROKER;
      company = broker;
    } else if (shipper) {
      accountType = this.accountType.SHIPPER;
      company = shipper;
    }

    return { accountType, company };
  }

  public openNewUserDialog(id: string = null) {
    const data = this.normalizeFormData();

    if (data.accountType) {
      this.dialogRef = this.dialog.open(UserFormComponent, {
        data: { id, ...data },
        width: '400px',
      });

      this.dialogRef
        .afterClosed()
        .pipe(takeUntil(this.alive))
        .subscribe((newUser) => {
          if (newUser) {
            this.usersModel.search(newUser);
          }
        });
    }
  }

  updateUserProperty(userProperty: EditableUserProperties, driver: User): void {
    const { id: userId } = driver;
    const userInfo = { [userProperty]: driver[userProperty] };

    this.usersModel.updateUser({ userId, userInfo });
  }

  canEditUserProperty(user: User, driver: Driver) {
    return driver.isDriver && (user.carrier || user.isHaulynxAdmin);
  }

  public deleteUser(user: User) {
    this.dialogRef = this.dialog.open(DeleteUserDialog, {});

    this.dialogRef
      .afterClosed()
      .pipe(takeUntil(this.alive))
      .subscribe((result) => {
        if (result && result === true) {
          this.userService
            .deleteUser(user.id)
            .then((resp) => {
              const query = { all: 'true' };
              this.usersModel.search({ query });
              this.snackBar.open('User Deleted', null, { duration: 2000 });
            })
            .catch((err) => {
              this.snackBar.open('Unable to Delete User!', null, { duration: 2000 });
            });
        }
      });
  }
}
