import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { IDropDown } from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'autocomplete-blur',
  templateUrl: './autocomplete-blur.component.html',
  styleUrls: ['./autocomplete-blur.component.scss'],
})
export class AutocompleteBlurComponent implements OnInit {
  @Input() data: IDropDown[] = [];
  @Input() placeholder = '';
  @Input() displayLabel = 'label';
  @Input() key = 'id';
  @Input() floatLabel = 'never';

  @Input() set selected(value: string) {
    this.currentValue = value;
  }

  @Output() selectionChange = new EventEmitter();

  alive = aliveWhile();
  control: FormControl = new FormControl();
  filteredData = new Subject();
  private valueStream$ = new Subject<string>();
  private currentValue: string; // represents the external value of the input.

  ngOnInit(): void {
    this.control.valueChanges
      .pipe(
        takeUntil(this.alive),
        map((value) => {
          return this.filterBy(this.data, value);
        })
      )
      .subscribe((items) => {
        this.filteredData.next(items);
      });

    this.valueStream$
      .pipe(
        takeUntil(this.alive),
        distinctUntilChanged(),
        debounceTime(200),
        filter((value) => value !== this.currentValue)
      )
      .subscribe((value) => {
        this.validateValue(value);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { data } = changes;

    if (data) {
      const item = _.find(data.currentValue, { [this.key]: this.currentValue });
      this.control.patchValue(item);
    }
  }

  private filterBy(data: IDropDown[], filterValue: string): IDropDown[] {
    const newData = !filterValue
      ? data
      : _.filter<IDropDown>(data, (item: IDropDown) => {
          const label = _.toLower(item[this.displayLabel]);
          const value = _.toLower(filterValue);

          return label.indexOf(_.trim(value)) > -1;
        });

    return newData;
  }

  displayFn() {
    return (selected: IDropDown | null): string | null => {
      const label = selected ? selected[this.displayLabel] : '';
      return label;
    };
  }

  private validateValue(value: string) {
    this.control.setErrors(null);
    if (!this.valueIsAnOption(value)) {
      const errors = { mustSelected: true };
      this.control.setErrors(errors);
    } else {
      this.emitChange(value);
    }
  }

  private emitChange(value: string) {
    this.selectionChange.emit(value);
    this.currentValue = value;
  }

  /**
   * Checks if current value is one of the autocomplete options
   * @param value: entered value in text field
   */
  private valueIsAnOption(value: any): boolean {
    if (!value) {
      return true;
    } // null, blank values allowed
    return this.data.map((item) => item[this.key]).indexOf(value) !== -1;
  }

  select(event: MatAutocompleteSelectedEvent) {
    const row = event.option.value;
    const id = row[this.key];
    this.valueStream$.next(id);
  }

  inputBlur(event: FocusEvent) {
    const value: string = event.target['value'];
    this.valueStream$.next(value);
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }

  private clear() {
    // this.control.patchValue(null, {emitEvent: true});
  }
}
