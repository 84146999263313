import gql from 'graphql-tag';

export const GetWebFeatures = gql(`
  query getFeaturesByEnvironment($environment: WebEnvironment!) {
   getFeaturesByEnvironment(environment: $environment) {
      name
      description
    }
  }
`);
