import { Injectable } from '@angular/core';
import { HttpStatus } from '@haulynx/types';
import { Store } from '@ngrx/store';
import { Map } from 'immutable';
import { Observable } from 'rxjs';
import { AppState } from '../../main-store/app.reducers';
import { loadPriceHttpStatusSelector, loadPriceSelector } from './load-feed.selectors';
import { LoadPriceActions } from './load-price.actions';

@Injectable({ providedIn: 'root' })
export class LoadPriceModel {
  isLoading$: Observable<Map<string, boolean>> = this.store.select(loadPriceSelector);
  httpStatus$: Observable<Map<string, HttpStatus>> = this.store.select(loadPriceHttpStatusSelector);

  constructor(private store: Store<AppState>) {}

  setPrice(data: { loadId: string | number; price: number; maxBuy?: number }): void {
    this.store.dispatch(LoadPriceActions.setPrice(data));
  }
}
