import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { SharedLocationStoreModule } from '@haulynx/store';
import { MapsModule } from '../maps/maps.module';
import { SharedLocationTimelineModule } from '../shared-location-timeline/shared-location-timeline.module';
import { SharedLocationDetailsComponent } from './shared-location-details.component';

@NgModule({
  declarations: [SharedLocationDetailsComponent],
  imports: [
    CommonModule,
    SharedLocationStoreModule,
    MapsModule,
    SharedLocationTimelineModule,
    MaterialModule,
    PipesModule,
  ],
  exports: [SharedLocationDetailsComponent],
})
export class SharedLocationDetailsModule {}
