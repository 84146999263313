<form [formGroup]="deviceForm" (ngSubmit)="onSubmit(dropdown?.control?.value)" novalidate class="haulynx-form">
  <div class="row">
    <span class="title">DEVICES</span>
    <div class="col-lg add-button-container">
      <button
        data-test="submitButton"
        mat-raised-button
        class="add-button"
        type="button"
        color="primary"
        [disabled]="carriers.length === 0"
        (click)="addDevice()"
        data-test="add-device"
      >
        Add New Device
      </button>
    </div>
  </div>

  <mat-card class="device-search-header">
    <drop-down
      type="text"
      placeholder="Search by Carrier or IMEI"
      displayLabel="name"
      mat-input
      key="id"
      formControlName="carrierOrimei"
      [data]="carriers"
      [isLoading]="!carriers.length"
      #dropdown
    ></drop-down>
  </mat-card>
</form>
<div class="table">
  <data-table
    [loading]="deviceEntityService.loading$ | async"
    [list]="true"
    [data]="deviceEntityService.filteredEntities$ | async"
    [displayedColumns]="columns"
    [templateCellRef]="templateCell"
    data-test="devices-table"
  >
    <ng-template #templateCell let-row="row" let-column="column">
      <div *ngIf="column === 'carrier'" class="carrier" data-test="device-carrier">
        {{ row.carrier?.name }}
      </div>
      <div *ngIf="column === 'imei'" [ngClass]="row[column]" data-test="device-imei">
        <div class="imei">{{ row?.imei }}</div>
        <mat-form-field *ngIf="!row?.imei">
          <input matInput placeholder="IMEI" [(ngModel)]="row.type" />
        </mat-form-field>
        <button mat-raised-button *ngIf="!row?.imei" (click)="generateFakeIMEI(row)">Fake IMEI</button>
        <button mat-button *ngIf="!row?.imei" (click)="assignIMEI(row)">Assign IMEI</button>
      </div>
      <div *ngIf="column === 'unitId'" data-test="device-unit">
        <span data-test="device-make">Make: {{ row?.make || 'N/A' }}</span
        ><br />
        <span data-test="device-model">Model: {{ row?.model || 'N/A' }}</span
        ><br />
        <span data-test="device-year">Year: {{ row?.year || 'N/A' }}</span
        ><br />
        <span data-test="device-unit-id">Unit ID: {{ row?.unitId || 'N/A' }}</span>
      </div>
      <div *ngIf="column === 'lastUpdated'" data-test="device-last-updated">
        <span>{{ row?.lastUpdated | date: 'MM/dd/yyyy hh:mm a' || 'N/A' }}</span
        ><br />
      </div>
      <div *ngIf="column === 'shippedDate'" data-test="device-shipping-info">
        <span data-test="device-shipped-date">Date: {{ row?.shippedDate | date: 'MM/dd/yyyy' || 'N/A' }}</span
        ><br />
        <span data-test="device-tracking-number">Tracking Number: {{ row?.trackingNumber || 'N/A' }}</span
        ><br />
      </div>
      <div *ngIf="column === 'returnInfo'" data-test="device-return-info">
        <span>Date: {{ row?.returnDate | date: 'MM/dd/yyyy' || 'N/A' }}</span
        ><br />
      </div>
      <div *ngIf="column === 'writeOff'" data-test="device-write-off">
        <span>{{ row.writeOffReason ? 'Yes' : 'No' }}</span
        ><br />
      </div>
      <div *ngIf="column === 'deactivated'" data-test="device-deactivated">
        <span>{{ row.cellularCarrierDeactivated ? 'Yes' : 'No' }}</span
        ><br />
      </div>
      <div
        *ngIf="column === 'actions' && (row.type == 'mdi' || row.type == 'TELEMATICS')"
        data-test="device-edit-device"
      >
        <button mat-icon-button color="warm" (click)="editDevice(row)">
          <mat-icon>edit_outline</mat-icon>
        </button>
      </div>
    </ng-template>
  </data-table>
</div>
