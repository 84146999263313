import { ChangeDetectionStrategy, Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-trailer',
  templateUrl: './add-trailer.component.html',
  styleUrls: ['./add-trailer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddTrailerComponent implements OnInit {
  form: FormGroup;
  carrierDot: string;
  carrierId: string;

  constructor(
    private fb: FormBuilder,
    @Optional() private dialogRef: MatDialogRef<AddTrailerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { carrierDot: string; carrierId: string }
  ) {
    const { carrierDot = null, carrierId = null } = data;
    this.carrierDot = carrierDot;
    this.carrierId = carrierId;
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.form = this.fb.group({
      trailerNumber: [null, [Validators.pattern(/[0-9a-zA-Z]{1,10}/), Validators.required]],
      carrierDot: this.carrierDot,
      carrierId: this.carrierId,
    });
  }

  save(): void {
    if (this.form.valid) {
      this.dialogRef.close(this.form.value);
    }
  }
}
