import gql from 'graphql-tag';

export const UpdateCarrierComplianceTicket = gql(`
mutation updateCarrierComplianceTicket($carrierComplianceTicketId: StrictId!, $carrierComplianceTicketInput: UpdateCarrierComplianceTicketInput ){
  updateCarrierComplianceTicket(carrierComplianceTicketId: $carrierComplianceTicketId, carrierComplianceTicketInput: $carrierComplianceTicketInput){
    id
    broker_id
    broker_email
    reason
    carrier_id
    load_id
    status
    status_update
    freshdesk_id
    created_at
    updated_at
  }
}

`);
