import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@haulynx/modules';
import { NgxMaskModule } from 'ngx-mask';
import { AddTrailerComponent } from './add-trailer.component';

@NgModule({
  declarations: [AddTrailerComponent],
  imports: [CommonModule, ReactiveFormsModule, MaterialModule, NgxMaskModule.forRoot()],
  exports: [AddTrailerComponent],
})
export class AddTrailerModule {}
