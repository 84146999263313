<haulynx-dialog-template (closed)="close()">
  <div title>Contact support</div>
  <div content fxLayout="column" fxLayoutGap="24px">
    <div>
      We’re sorry that you’ve encounted a difficulty with our system. Please contact Xpress Technologies support by
      phone or email so we can get your problem resolved as quickly as possible..
    </div>
    <div class="contact">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <mat-icon>phone</mat-icon>
        <div>1-480-646-3545</div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <mat-icon>email</mat-icon>
        <div>xtcarriercompliance@usxpress.com</div>
      </div>
    </div>
  </div>
</haulynx-dialog-template>
