import gql from 'graphql-tag';

export const GetNotifications = gql(`
  query GetNotifications($filterParams: NotificationEventFilterParams) {
    getNotifications(filterParams: $filterParams) {
      id
      recipient
      eventType
      targetObjectType
      targetObjectId
      targetCarrierDot
      targetUserId
      targetTruckId
      expirationDate
      active
      createdBy
      createdAt
      modifiedBy
      modifiedAt
      secondaryEventType
      secondaryTargetObjectId
      secondaryTargetObjectType
      targetCarrierDisplay
      targetUserDisplay
      message
      pushNotifications {
        id
        success
        message
        title
        eventId
        attemptedDate
        url
      }
      loadsServiceLoad {
        id
        paymentDetails {
          price
        }
        providerDetails {
          alternateIds {
            identifierType
            identifierValue
          }
        }
        locations {
          city
          state
          appointmentSet
          appointmentEnd
          appointmentStart
          timezone
        }
      }
    }
  }
`);
