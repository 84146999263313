<form [formGroup]="editDeviceForm" *ngIf="data?.device" class="edit-device-container">
  <div fxLayout="column" fxLayoutGap="16px">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="title">
      <h2 matDialogTitle>EDIT DEVICE</h2>
      <button mat-icon-button data-test="close" matDialogClose>
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <mat-dialog-content fxLayout="column">
      <div fxLayout="row" fxLayoutGap="16px">
        <div fxFlex="50">
          <span class="label positioned-label">Carrier</span>
          <drop-down
            displayLabel="name"
            key="id"
            placeholder="Select Carrier"
            [data]="data.carriers"
            formControlName="carrierId"
            data-test="edit-carrier-dropdown"
          >
          </drop-down>
        </div>
        <div fxFlex="50">
          <span class="label positioned-label">Truck</span>
          <drop-down
            displayLabel="unitId"
            placeholder="Unit Id"
            [isLoading]="
              (commonEntities.graphQlTrucks.isLoading$ | async) || (commonEntities.graphQlDevices.isLoading$ | async)
            "
            [data]="trucksAndDevices$ | async"
            formControlName="truckId"
            data-test="edit-truck-dropdown"
          >
          </drop-down>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px">
        <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
          <mat-label>Shipped Date</mat-label>
          <app-datepicker formControlName="shippedDate" placeholder="Shipped Date" data-test="edit-shipped-date">
          </app-datepicker>
        </mat-form-field>
        <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
          <mat-label>Tracking Number</mat-label>
          <input
            matInput
            placeholder="Tracking Number"
            formControlName="trackingNumber"
            type="number"
            data-test="edit-tracking-number"
          />
        </mat-form-field>
      </div>

      <div class="offset" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px">
        <div fxLayout="column" fxLayoutAlign="start start" fxFlex="50">
          <span class="label return-label">Return Date</span>
          <mat-form-field class="app-form-field" appearance="fill" floatLabel="always">
            <app-datepicker formControlName="returnDate" placeholder="Return Date" data-test="edit-return-date">
            </app-datepicker>
          </mat-form-field>
        </div>
        <div class="writeOff" fxLayout="column" fxLayoutAlign="start start" fxFlex="50">
          <span class="label positioned-label">Write-off Reason</span>
          <drop-down
            placeholder="Write-off Reason"
            displayLabel="name"
            key="value"
            [data]="writeOffReasons"
            formControlName="writeOffReason"
            data-test="edit-write-off-dropdown"
          >
          </drop-down>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px">
        <mat-checkbox
          [checked]="!!data.device?.cellularCarrierDeactivated"
          formControlName="cellularCarrierDeactivated"
          data-test="deactivated-checkbox"
          >Deactivated</mat-checkbox
        >
      </div>
    </mat-dialog-content>
    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
      <button
        mat-raised-button
        class="save-button"
        data-test="save-device"
        [matDialogClose]="editDeviceForm.getRawValue()"
      >
        Save
      </button>
    </mat-dialog-actions>
  </div>
</form>
