import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { UserService } from '@haulynx/services';
import { LoadEntityService } from '@haulynx/store';
import { BookStatus, LoadsServiceLoad, LoadsServiceLoadStatus, User } from '@haulynx/types';
import { Observable } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoadOverviewRedirectGuard implements CanActivate {
  private user: User;
  constructor(private loadEntityService: LoadEntityService, private router: Router, private userService: UserService) {
    this.userService.user.subscribe((user) => {
      this.user = user;
    });
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const { id: loadId } = route.params as { id: string };
    const overview = state.url.split('overview');

    if (this.user?.isCarrier || this.user?.carrier) {
      this.router.navigate([`loads/${loadId}`]);
      return false;
    }

    // Make sure that we don't navigate people away from a page that they actually want to be on
    if (overview?.[1] === '') {
      this.loadEntityService.getLoadByIdManager.dispatch(loadId);

      return this.loadEntityService.getLoadByIdManager.getEntityById(loadId).pipe(
        filter((load) => load !== null),
        map((load: LoadsServiceLoad) => {
          if (load.bookStatus === BookStatus.BOOKED) {
            if (load.loadStatus === LoadsServiceLoadStatus.UNASSIGNED) {
              this.route(`booking`, loadId);
            } else if (
              load.loadStatus === LoadsServiceLoadStatus.DISPATCHED ||
              load.loadStatus === LoadsServiceLoadStatus.ASSIGNED ||
              load.loadStatus === LoadsServiceLoadStatus.AT_SHIPPER ||
              load.loadStatus === LoadsServiceLoadStatus.AT_RECEIVER ||
              load.loadStatus === LoadsServiceLoadStatus.PICKED_UP ||
              load.loadStatus === LoadsServiceLoadStatus.DELIVERED
            ) {
              this.route(`tracking`, loadId);
            } else {
              this.route('info', loadId);
            }
          } else {
            this.route('info', loadId);
          }

          return true; // Always return true since we are just redirecting them
        })
      );
    }

    return true;
  }

  private route(segment: string, loadId: string) {
    this.router.navigate([`loads/${loadId}/overview/${segment}`], { queryParamsHandling: 'preserve' });
  }
}
