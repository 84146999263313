import { EntityNames } from '@haulynx/types';
import { EntityDataModuleConfig, EntityMetadataMap } from '@ngrx/data';
import { deviceByCarrierIdFilter } from './app-entity-filters';

const entityMetadata: EntityMetadataMap = {
  DEVICE: {
    entityName: EntityNames.DEVICE,
    filterFn: deviceByCarrierIdFilter,
  },
};

export const entityConfig: EntityDataModuleConfig = {
  entityMetadata,
};
