import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@haulynx/directives';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { ClipboardModule } from 'ngx-clipboard';
import { AssignedBrokersModule } from '../assigned-brokers/assigned-brokers.module';
import { ShareLoadCompleteDialog } from '../dialogs/load-share-complete/load-share-complete.component';
import { ShareLoadDialog } from '../dialogs/load-share/load-share.component';
import { LoadPriceModule } from '../load-price/load-price.module';
import { LoadTimelineModule } from '../load-timeline/load-timeline.module';
import { OrderInfoNotesModule } from '../pay-line-items/order-info-notes/order-info-notes.module';
import { LoadMainInfoComponent } from './load-main-info.component';

@NgModule({
  declarations: [LoadMainInfoComponent, ShareLoadCompleteDialog, ShareLoadDialog],
  imports: [
    LoadTimelineModule,
    PipesModule,
    MaterialModule,
    DirectivesModule,
    LoadPriceModule,
    CommonModule,
    ClipboardModule,
    OrderInfoNotesModule,
    FormsModule,
    ReactiveFormsModule,
    AssignedBrokersModule,
  ],
  exports: [LoadMainInfoComponent],
})
export class LoadMainInfo {}
