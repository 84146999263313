<ng-container *appFeatures="item?.featureFlag">
  <div [ngStyle]="{ width: '250px' }" *ngIf="isSidebarOpened; else sidebarClosed">
    <a mat-list-item [ngClass]="{ expanded: isExpanded, active: isActive }" (click)="onItemSelected(item, $event)">
      <div class="sidebar__item">
        <span class="sidebar__item__content" [ngClass]="{ italics: item?.metaData?.isCustom }">
          <div *ngIf="!item.children"></div>
          <mat-icon
            class="sidebar__item__icon--expand"
            [@arrowRotation]="isExpanded ? 'expanded' : 'collapsed'"
            *ngIf="item.children"
            (click)="onItemSelected(item, $event)"
            data-test="expand-button"
          >
            expand_more
          </mat-icon>

          <mat-icon
            class="sidebar__item__icon--savedViews"
            scdkDragHandle
            *ngIf="!item?.children?.length && depth > 0 && item?.metaData?.isCustom && toggleMove"
            data-test="drag-menu"
            >menu</mat-icon
          >

          <mat-icon
            *ngIf="!item?.metaData?.isCustom && !item?.children?.length && depth > 0"
            [svgIcon]="isActive ? 'haulynx-sidebar-box-blue' : 'haulynx-sidebar-box-grey'"
            [ngStyle]="{
              color: isActive && item?.metaData?.isCustom ? '#2edfff' : '#ffffff'
            }"
            class="sidebar__item__icon--main"
          ></mat-icon>

          <mat-icon
            *ngIf="item?.metaData?.isCustom && !item?.children?.length && depth > 0 && !toggleMove"
            [ngStyle]="{
              fill: isActive && item?.metaData?.isCustom ? '#2edfff' : '#ffffff'
            }"
            class="material-icons-outlined sidebar__item__icon--savedViews"
          >
            dashboard_customize
          </mat-icon>
          <div
            [ngStyle]="{ margin: !item?.children && depth === 0 ? '0 0 0 40px' : '0 0 0 15px' }"
            [ngClass]="depth === 0 ? 'sidebar__item__icon--title' : 'sidebar__item__icon--child'"
            attr.data-test="{{ item?.label }}"
          >
            {{ item?.label }}
          </div>
        </span>

        <button
          mat-icon-button
          [matMenuTriggerFor]="searchView"
          class="sidebar__item__icon--trash"
          (click)="$event.stopPropagation()"
          *ngIf="item?.metaData?.isCustom && isSidebarOpened && !toggleMove"
          data-test="custom-view-more"
        >
          <mat-icon> more_vert </mat-icon>
        </button>

        <button
          mat-icon-button
          class="sidebar__item__icon--trash"
          (click)="onCheckToggle()"
          *ngIf="item?.metaData?.isCustom && isSidebarOpened && check"
          data-test="check-button"
        >
          <mat-icon> check </mat-icon>
        </button>

        <mat-menu #searchView="matMenu">
          <button *ngIf="!toggleMove" mat-menu-item (click)="onMoveToggle()" data-test="move-button">Move</button>
          <button mat-menu-item (click)="deleteSearchView(item)" data-test="delete-button">Delete</button>
        </mat-menu>

        <span *ngIf="isSidebarOpened" class="sidebar__badge-wrapper">
          <mat-icon *ngIf="!item?.quantity && isActive && item.children?.length"> navigate_next </mat-icon>

          <span *ngIf="item?.quantity" class="sidebar__badge">
            {{ item.quantity }}
          </span>
        </span>
      </div>
    </a>
  </div>

  <div
    cdkDropList
    (cdkDropListDropped)="onDrop($event)"
    class="sidebar__node__wrapper"
    [ngClass]="{ expanded: isExpanded }"
  >
    <div *ngFor="let child of item?.children; trackBy: trackByFn">
      <div cdkDrag [cdkDragDisabled]="!child?.metaData?.isCustom || !toggleMove">
        <app-sidebar-node
          [item]="child"
          [activeNodeLabel]="activeNodeLabel"
          [depth]="depth + 1"
          [isSidebarOpened]="isSidebarOpened"
          [move]="toggleMove"
          [check]="checkOrigin?.id === child?.id && toggleMove"
          [savedViews]="savedViewsItems"
          (itemSelected)="onItemSelected($event)"
          (deleteSidebarItem)="onDeleteSidebarItem($event)"
          (moveSidebarItem)="onMoveSideBarItem($event)"
        >
        </app-sidebar-node>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #solidBullet>lens</ng-template>
<ng-template #emptyBullet>trip_origin</ng-template>
<ng-template #mainItem>haulynx-sidebar-box-grey</ng-template>
<ng-template #mainItemActive>haulynx-sidebar-box-blue</ng-template>
<ng-template #dragHandle>menu</ng-template>
<ng-template #collapsedIcon>
  <mat-icon
    *ngIf="!item.children"
    [svgIcon]="isActive ? 'haulynx-sidebar-box-blue' : 'haulynx-sidebar-box-grey'"
    [matTooltip]="item.label"
  ></mat-icon>
</ng-template>
<ng-template #collapsedTab>
  <mat-icon
    [@arrowRotation]="isExpanded ? 'expanded' : 'collapsed'"
    (click)="onItemSelected(item, $event)"
    [matTooltip]="item.label"
  >
    expand_more
  </mat-icon>
</ng-template>

<ng-template #sidebarClosed>
  <div class="sidebar__item__closed">
    <div
      [ngClass]="{ sidebar__item__closed__icon: true, sidebar__item__closed__active: isActive && !item.children }"
      (click)="onItemSelected(item, $event)"
    >
      <div class="sidebar__item__icon--expand" *ngIf="item.children; then collapsedTab; else collapsedIcon"></div>
    </div>
  </div>
</ng-template>
