import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { Load, LoadLocation, LoadsServiceLoad, LoadsServiceLoadLocation } from '@haulynx/types';
import { isEmpty } from 'lodash';

@Component({
  selector: 'app-load-search-timeline',
  templateUrl: './load-search-timeline.component.html',
  styleUrls: ['./load-search-timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadSearchTimelineComponent implements OnChanges {
  @Input() load: LoadsServiceLoad;
  @Input() fontSize: string = '14px';

  displayLoad: {
    locations: Array<{
      address: string;
      appointmentSet: boolean;
      appointmentStart: number;
      appointmentEnd: number;
      timezone: string;
    }>;
  } = {
    locations: [],
  };

  ngOnChanges(): void {
    if (!isEmpty(this.load)) {
      if (!isEmpty(this.load?.locations) && this.load?.locations.length > 0) {
        const pickup = this.load.locations[0];
        const dropoff = this.load?.locations[this.load?.locations?.length - 1];
        this.displayLoad.locations = [
          {
            address: `${pickup.city} ${pickup.state}`,
            appointmentSet: pickup.appointmentSet,
            appointmentEnd: pickup.appointmentEnd,
            appointmentStart: pickup.appointmentStart,
            timezone: pickup.timezone,
          },
          {
            address: `${dropoff.city} ${dropoff.state}`,
            appointmentSet: dropoff.appointmentSet,
            appointmentEnd: dropoff.appointmentEnd,
            appointmentStart: dropoff.appointmentStart,
            timezone: dropoff.timezone,
          },
        ];
      }
    }
  }
}
