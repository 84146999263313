import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { LoadActiveTabsModel } from '@haulynx/store';
import { Tab } from '@haulynx/types';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoadActiveTabGuard implements CanActivate {
  constructor(private loadActiveTabsModel: LoadActiveTabsModel) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let viewTabs = [];
    const { tabs = null } = route.data;

    if (tabs) {
      const result = _.reduce(
        tabs,
        (accumulator, tab, key) => {
          accumulator.tabs.push(new Tab(tab));

          return accumulator;
        },
        { tabs: [] }
      );

      viewTabs = [...viewTabs, ...result.tabs];
    }

    if (viewTabs.length) {
      this.loadActiveTabsModel.addTabs(viewTabs);
    }

    return of(true);
  }
}
