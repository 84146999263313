<div class="edit-load-details-dialog">
  <div class="dialog-header">
    <span class="dialog-header__title">EDIT LOAD DETAILS</span>
    <button mat-icon-button matDialogClose class="dialog-header__close">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="dialog-sub-header">LOAD DETAILS</div>
  <form [formGroup]="form" class="form">
    <div class="form-container">
      <mat-form-field class="app-dark-field" appearance="fill" floatLabel="always">
        <label class="label"> Price </label>
        <input
          matInput
          class="form-control"
          formControlName="price"
          data-test="price"
          currencyMask
          [options]="{ thousands: ',', decimal: '.', allowNegative: false, align: 'left' }"
          [readonly]="isFinalled || !writePermissions['price']"
        />
        <mat-error *ngIf="form?.get('price').errors">*</mat-error>
      </mat-form-field>

      <mat-form-field class="app-dark-field" appearance="fill" floatLabel="always">
        <label class="label"> Weight </label>
        <input
          matInput
          type="number"
          class="form-control"
          formControlName="weight"
          data-test="weight"
          placeholder="ex: 450"
          [readonly]="isFinalled || !writePermissions['weight']"
        />
        <span matSuffix class="matSuffix">LBS</span>
        <mat-error *ngIf="form?.get('weight').errors">*</mat-error>
      </mat-form-field>

      <mat-form-field class="app-dark-field" appearance="fill" floatLabel="always">
        <label class="label"> Commodity </label>
        <input
          matInput
          class="form-control"
          formControlName="commodity"
          data-test="commodity"
          placeholder="Enter Commodity Here"
          [readonly]="isFinalled || !writePermissions['commodity']"
        />
        <mat-error *ngIf="form?.get('commodity').errors">*</mat-error>
      </mat-form-field>

      <mat-form-field *ngIf="!preBooking" class="app-dark-field" appearance="fill" floatLabel="always">
        <label class="label"> Broker </label>
        <app-drop-down
          class="form-control"
          formControlName="broker"
          data-test="broker"
          placeholder="Select Broker"
          [data]="data.brokers"
          displayLabel="label"
          key="id"
          [changeableData]="true"
          [disableDropDown]="!writePermissions['broker']"
        >
        </app-drop-down>
      </mat-form-field>

      <mat-form-field class="app-dark-field" appearance="fill" floatLabel="always">
        <label class="label"> Equipment </label>
        <app-drop-down
          class="form-control"
          formControlName="equipment"
          data-test="equipment"
          placeholder="Select Equipment"
          displayLabel="text"
          key="text"
          [data]="equipmentOptions"
          [changeableData]="true"
          [disableDropDown]="!writePermissions['equipment']"
        >
        </app-drop-down>
      </mat-form-field>

      <mat-form-field class="app-dark-field" appearance="fill" floatLabel="always">
        <label class="label"> Trailer Instructions </label>
        <app-drop-down
          class="form-control"
          formControlName="trailerInstr"
          data-test="trailerInstr"
          placeholder="Select Trailer Instructions"
          displayLabel="label"
          key="value"
          [data]="trailerInstrOptions"
          [changeableData]="true"
          [disableDropDown]="!writePermissions['trailerInstr']"
        >
        </app-drop-down>
      </mat-form-field>

      <mat-form-field class="app-dark-field" appearance="fill" floatLabel="always">
        <label class="label"> Freight Mix </label>
        <app-drop-down
          class="form-control"
          formControlName="freightMix"
          data-test="freightMix"
          placeholder="Select Freight Mix"
          displayLabel="label"
          key="value"
          [data]="freightMixOptions"
          [changeableData]="true"
          [disableDropDown]="!writePermissions['freightMix']"
        >
        </app-drop-down>
      </mat-form-field>

      <mat-form-field class="app-dark-field" appearance="fill" floatLabel="always">
        <label class="label"> Tanker Endorsement </label>
        <app-drop-down
          class="form-control"
          formControlName="tankerEndors"
          data-test="tankerEndors"
          placeholder="Select Tanker Endorsement"
          displayLabel="value"
          key="value"
          [data]="binaryOptions"
          [changeableData]="true"
          [disableDropDown]="!writePermissions['tankerEndors']"
        >
        </app-drop-down>
      </mat-form-field>

      <mat-form-field class="app-dark-field" appearance="fill" floatLabel="always">
        <label class="label"> Hazmat </label>
        <app-drop-down
          class="form-control"
          formControlName="hazmat"
          data-test="hazmat"
          placeholder="Select Hazmat Option"
          displayLabel="value"
          key="value"
          [data]="binaryOptions"
          [changeableData]="true"
          [disableDropDown]="!writePermissions['hazmat']"
        >
        </app-drop-down>
      </mat-form-field>

      <mat-form-field class="app-dark-field" appearance="fill" floatLabel="always">
        <label class="label"> Cargo Value Adjustment </label>
        <app-drop-down
          class="form-control"
          formControlName="highValueException"
          data-test="highValueException"
          placeholder="Select Cargo Value Exception"
          displayLabel="label"
          key="value"
          [data]="highValueOptions"
          [changeableData]="true"
          [disableDropDown]="!writePermissions['highValueException']"
        >
        </app-drop-down>
      </mat-form-field>
    </div>

    <footer>
      <mat-error *ngIf="form?.get('weight').getError('pattern')">* Load weight must be a positive number.</mat-error>
      <div class="edit-load-details-dialog__action">
        <button
          mat-dialog-close
          class="
            hlx-button-command hlx-button-command--cancel hlx-button-command--border-white
            edit-load-details-dialog__cancel-button
          "
          (click)="close()"
          data-test="close-button"
        >
          Close
        </button>

        <button
          type="submit"
          class="hlx-button-command hlx-button-command--book edit-load-details-dialog__submit-button"
          [disabled]="form.invalid || isFinalled"
          (click)="save()"
          data-test="save-button"
        >
          Save Changes
        </button>
      </div>
    </footer>
  </form>
</div>
