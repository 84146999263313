import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { List, Map } from 'immutable';
import { listToArray, mapToArray } from '@haulynx/utils';

@Pipe({ name: 'filter' })
export class FilterPipe implements PipeTransform {
  transform(items: any[] | List<any> | Map<string, any>, filter: { [key: string]: any }): any {
    if (Map.isMap(items)) {
      items = mapToArray(items);
    }

    if (List.isList(items)) {
      items = listToArray(items);
    }

    return _.filter(items, filter);
  }
}
