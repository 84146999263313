import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdvancedSearchService, MilestonesService, NotificationsService } from '@haulynx/services';
import { GeocodingEntityService, MilestoneEntityService, UserEntityService } from '@haulynx/store';
import {
  buttonTypes,
  CreateMilestoneFormResult,
  FeatureFlag,
  FFState,
  GeocodeParams,
  KeyValuePair,
  LatLonInput,
  LoadsServiceLoad,
  LoadsServiceLoadLocation,
  MapboxTimezoneData,
  Milestone,
  MilestoneLog,
  MilestoneSubtype,
  MilestoneType,
  MilestoneUser,
  PlaceInfo,
} from '@haulynx/types';
import { aliveWhile, dateLessThanNowMoment } from '@haulynx/utils';
import { formatDistance, sub } from 'date-fns';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, takeUntil, withLatestFrom } from 'rxjs/operators';
import { issuesList, statusList } from './milestone-dialog.config';

@Component({
  selector: 'app-milestone-dialog',
  templateUrl: './milestone-dialog.component.html',
  styleUrls: ['./milestone-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MilestoneDialogComponent implements OnInit {
  form: FormGroup;
  isEditMilestone = false;
  checkpoints: Array<{ key: string; value: string }>;
  milestoneTypes: Array<{ key: string; value: string }>;
  statusTypes: KeyValuePair[];
  issues: KeyValuePair[];
  timeInFacility: string;
  creationTime: Date;
  actionButtons = [buttonTypes.MILESTONE_CANCEL, buttonTypes.CREATE_MILESTONE];
  disableButtons = [buttonTypes.CREATE_MILESTONE];
  isMilestoneTypeDisabled = false;
  isLoadLocationDisabled = false;
  isAddrDisabled = false;
  invalidTimeIn$ = new BehaviorSubject(false);
  invalidTimeOut$ = new BehaviorSubject(false);
  invalidPickup = false;
  invalidDropoff = false;
  invalidDropoffInitial = false;
  isTimeOutDisable = false;
  disableTimeOutCancel = false;
  resultMilestone: Milestone;
  alive = aliveWhile();
  isLoading$ = new BehaviorSubject<boolean>(false);
  isTimeInOutBeforeCompelted$ = new BehaviorSubject<boolean>(false);
  changeTimeInToCompletedTime = false;
  public featureFlags: FFState;

  hasRecentGoogleSelection = false;
  predictions: PlaceInfo[] = [];
  addressFormHasFocus = false;
  timezone: string;
  onInit = true;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      load: LoadsServiceLoad;
      milestone: Milestone;
      creator: string;
      milestones: Milestone[];
      creatorType: MilestoneUser;
      loadId: string;
      index: number;
      //inital values for Create Milestone
      milestoneType?: MilestoneType;
      subType?: MilestoneSubtype;
      coordinates?: { lat: number; lng: number };
      locationId?: string;
    },
    public matDialogRef: MatDialogRef<MilestoneDialogComponent>,
    private fb: FormBuilder,
    private notifications: NotificationsService,
    private searchService: AdvancedSearchService,
    private cdr: ChangeDetectorRef,
    private milestoneEntityService: MilestoneEntityService,
    private milestonesService: MilestonesService,
    private geocodingEntityService: GeocodingEntityService,
    private userEntityService: UserEntityService
  ) {}

  ngOnInit(): void {
    this.userEntityService.featureFlags$.pipe(takeUntil(this.alive)).subscribe((features: FFState) => {
      this.featureFlags = features;
    });

    this.generateCheckpoints();
    this.generateMilestoneTypes();
    this.generateStatusTypes();
    this.generateIssues();
    this.disableFormValueIfIsEdit();

    this.form = this.fb.group({
      milestoneType: [
        { value: 'CHECKPOINT', disabled: this.isMilestoneTypeDisabled },
        [Validators.required, Validators.minLength(1)],
      ],
      loadLocation: [
        { value: '', disabled: this.isLoadLocationDisabled },
        [Validators.required, Validators.minLength(1)],
      ],
      loadLocationAddress: [
        { value: '', disabled: this.isAddrDisabled },
        [Validators.required, Validators.minLength(1)],
      ],
      milestoneStatus: ['', [Validators.required, Validators.minLength(1)]],
      contact: [''],
      timeInDate: [new Date(), [Validators.required, Validators.minLength(1), dateLessThanNowMoment(this.getTimeZone)]],
      timeOutDate: [null, [dateLessThanNowMoment(this.getTimeZone)]],
      comment: [''],
      googleLocation: [''],
    });

    if (this.data.milestone) {
      this.actionButtons = [buttonTypes.MILESTONE_CANCEL, buttonTypes.EDIT_MILESTONE];
      this.disableButtons = [buttonTypes.EDIT_MILESTONE];
    }

    this.form.valueChanges
      .pipe(takeUntil(this.alive), withLatestFrom(this.invalidTimeIn$))
      .subscribe(([, invalidTimeInOut]) => {
        if (this.data.milestone !== undefined) {
          if (
            !this.form.invalid &&
            !invalidTimeInOut &&
            !this.invalidPickup &&
            !this.invalidDropoff &&
            !this.invalidDropoffInitial
          ) {
            this.actionButtons = [buttonTypes.MILESTONE_CANCEL, buttonTypes.EDIT_MILESTONE];
            this.disableButtons = [];
          } else {
            this.actionButtons = [buttonTypes.MILESTONE_CANCEL, buttonTypes.EDIT_MILESTONE];
            this.disableButtons = [buttonTypes.EDIT_MILESTONE];
          }
        } else {
          if (
            !this.form.invalid &&
            !invalidTimeInOut &&
            !this.invalidPickup &&
            !this.invalidDropoff &&
            !this.invalidDropoffInitial
          ) {
            this.actionButtons = [buttonTypes.MILESTONE_CANCEL, buttonTypes.CREATE_MILESTONE];
            this.disableButtons = [];
          } else {
            this.actionButtons = [buttonTypes.MILESTONE_CANCEL, buttonTypes.CREATE_MILESTONE];
            this.disableButtons = [buttonTypes.CREATE_MILESTONE];
          }
        }
      });

    this.setFormValueIfIsEdit();
    this.generateTimeInFacility();
    this.form
      .get('milestoneType')
      .valueChanges.pipe(takeUntil(this.alive))
      .subscribe((value) => {
        if (value === 'UPDATE') {
          this.form.get('timeInDate').clearValidators();
          this.form.get('timeInDate').updateValueAndValidity();
          this.form.get('loadLocation').clearValidators();
          this.form.get('loadLocation').updateValueAndValidity();
          this.matDialogRef.updateSize('550px', '560px');
          this.form.patchValue({
            loadLocation: '',
            loadLocationAddress: '',
            milestoneStatus: '',
            timeInDate: null,
            timeOutDate: null,
            contact: '',
            comment: '',
          });
          this.form.get('loadLocationAddress').enable();
          this.isAddrDisabled = false;
        } else if (value === 'CHECKPOINT') {
          this.form
            .get('timeInDate')
            .setValidators([Validators.required, Validators.minLength(1), dateLessThanNowMoment(this.getTimeZone)]);
          this.form.get('timeInDate').updateValueAndValidity();
          this.form.get('loadLocation').setValidators([Validators.required, Validators.minLength(1)]);
          this.form.get('loadLocation').updateValueAndValidity();
          this.matDialogRef.updateSize('550px', '730px');
          this.form.patchValue({
            loadLocation: '',
            loadLocationAddress: '',
            milestoneStatus: '',
            timeInDate: new Date(),
            timeOutDate: null,
            contact: '',
            comment: '',
          });
        }
      });

    this.form
      .get('loadLocation')
      .valueChanges.pipe(takeUntil(this.alive))
      .subscribe((value) => {
        if (value === 'Dispatch Empty') {
          this.matDialogRef.updateSize('550px', '620px');

          this.form.get('timeInDate').clearValidators();
          this.form.get('timeInDate').setValidators([Validators.required, Validators.minLength(1)]);
          this.form.get('timeInDate').updateValueAndValidity();

          this.form.patchValue({
            loadLocationAddress: '',
            milestoneStatus: MilestoneSubtype.DISPATCH,
            timeInDate: new Date(),
            timeOutDate: null,
            contact: '',
            comment: '',
          });

          this.form.get('loadLocationAddress').enable();
          this.isAddrDisabled = false;
          this.invalidTimeIn$.next(false);
        } else if (value !== 'Dispatch Empty' && this.form.get('milestoneType').value === MilestoneType.CHECKPOINT) {
          this.matDialogRef.updateSize('550px', '730px');
          const location: LoadsServiceLoadLocation = this.data.load.locations.find(
            (location: LoadsServiceLoadLocation) => location.id === value
          );
          this.form.patchValue({
            loadLocationAddress: '',
          });
          this.form.patchValue({
            loadLocationAddress: location ? location.address : '',
            milestoneStatus: '',
            timeInDate: new Date(),
          });
          if (location) {
            this.checkPickupDropoffIsValidAction(location.locationType);
          }
          if (this.invalidDropoffInitial) {
            this.notifications.warning(
              'Please add pickup before dropoff can be created',
              'Warning on selecting checkpoints'
            );
          } else if (this.invalidPickup) {
            this.notifications.warning(
              'Please complete previous pickup before adding a dropoff or pickup milestone',
              'Warning on selecting checkpoints'
            );
          } else if (this.invalidDropoff) {
            this.notifications.warning(
              'Please complete previous dropoff before adding a dropoff or pickup milestone',
              'Warning on selecting checkpoints'
            );
          }
          this.isTimeOutDisable = false;
          this.invalidTimeIn$.next(false);
          this.form.get('loadLocationAddress').disable();
          this.isAddrDisabled = true;
        } else if (this.form.get('milestoneType').value === MilestoneType.UPDATE) {
          this.form.get('loadLocationAddress').enable();
          this.isAddrDisabled = false;
        }
      });

    this.form.valueChanges
      .pipe(takeUntil(this.alive), withLatestFrom(this.invalidTimeIn$, this.invalidTimeOut$))
      .subscribe(([, invalidTimeIn, invalidTimeOut]) => {
        this.setButtonActivity(invalidTimeIn, invalidTimeOut);
      });

    this.form
      .get('loadLocationAddress')
      .valueChanges.pipe(takeUntil(this.alive), debounceTime(300))
      .subscribe((value: string) => {
        const milestoneType = this.form.get('milestoneType').value;
        const canShowDropdown =
          milestoneType === MilestoneType.UPDATE
            ? true
            : milestoneType === MilestoneType.CHECKPOINT &&
              this.form.get('milestoneStatus').value === MilestoneSubtype.DISPATCH
            ? true
            : false;
        if (canShowDropdown && value !== '' && this.addressFormHasFocus) {
          this.onInit = false;
          this.geocodingEntityService.getGeocodeLocationManager.dispatch({
            query: { search: value, autocomplete: true, limit: 10 } as GeocodeParams,
          });
        } else {
          this.predictions = [];
          this.hasRecentGoogleSelection = false;
          this.cdr.detectChanges();
        }
      });

    this.checkStatus();
    if (!this.isEditMilestone) {
      this.milestoneEntityService.createMilestoneManager.isLoading$
        .pipe(takeUntil(this.alive))
        .subscribe((isLoading: boolean) => {
          if (isLoading) {
            this.isLoading$.next(true);
          } else {
            this.isLoading$.next(false);
          }
        });

      this.milestoneEntityService.createMilestoneManager.onSuccess$.pipe(takeUntil(this.alive)).subscribe((success) => {
        this.matDialogRef.close(this.resultMilestone);
      });
    } else {
      this.milestoneEntityService.updateMilestoneManager.isLoading$
        .pipe(takeUntil(this.alive))
        .subscribe((isLoading: boolean) => {
          if (isLoading) {
            this.isLoading$.next(true);
          } else {
            this.isLoading$.next(false);
          }
        });

      this.milestoneEntityService.updateMilestoneManager.onSuccess$.pipe(takeUntil(this.alive)).subscribe((success) => {
        this.matDialogRef.close(this.resultMilestone);
      });
    }

    this.form
      .get('timeInDate')
      .valueChanges.pipe(takeUntil(this.alive), debounceTime(500))
      .subscribe((value) => {
        this.generateTimeInFacility();
        this.checkStatus();
        this.checkValidTimeForCompletedMilestones();
      });
    this.form
      .get('timeOutDate')
      .valueChanges.pipe(takeUntil(this.alive), debounceTime(500))
      .subscribe((value) => {
        this.generateTimeInFacility();
        this.checkStatus();
        this.checkValidTimeForCompletedMilestones();
      });
    this.checkValidTimeForCompletedMilestones();
    this.setInitalValues();

    this.geocodingEntityService.getReverseGecodeLocationManager.searchResults$
      .pipe(takeUntil(this.alive))
      .subscribe((results: PlaceInfo[]) => {
        if (results && results.length > 0) {
          this.form.patchValue({
            loadLocationAddress: results[0].fullAddress,
          });
        }
      });

    this.geocodingEntityService.getGeocodeLocationManager.searchResults$
      .pipe(takeUntil(this.alive))
      .subscribe((results: PlaceInfo[]) => {
        /* On init it would save previous predictions
         * and activate the dropdown - this if will prevent that
         */
        if (!this.onInit) {
          this.predictions = results;
          this.hasRecentGoogleSelection = false;
          this.cdr.detectChanges();
        }
      });
  }

  private setInitalValues() {
    if (!this.isEditMilestone) {
      this.form.patchValue({
        milestoneType: this.data.milestoneType,
        milestoneStatus: !this.data.subType ? '' : this.data.subType,
      });
      this.setInitalLocation();
      this.setInitalAddress();
    }
  }

  private setInitalLocation(): void {
    if (this.data.locationId) {
      const location = this.data.load.locations.find(
        (location: LoadsServiceLoadLocation) => location.id === this.data.locationId
      );
      const loadLocationId = this.checkpoints.find(
        (index) => index.key === `${location.locationType} - ${location.city} ${location.state}`
      );

      this.form.patchValue({
        loadLocation: loadLocationId.value,
        loadLocationAddress: location.address,
      });
    }
  }

  private setInitalAddress(): void {
    if (this.data.coordinates) {
      this.geocodingEntityService.getReverseGecodeLocationManager.dispatch({
        query: {
          coordinates: { lat: this.data.coordinates.lat, lon: this.data.coordinates.lng } as LatLonInput,
        },
      });

      this.form.patchValue({
        googleLocation: {
          geometry: {
            coordinates: [this.data.coordinates.lat, this.data.coordinates.lng],
          },
        },
      });
    }
  }

  setButtonActivity(invalidTimeIn: boolean, invalidTimeOut: boolean): void {
    if (!this.canShowAddress()) {
      if (this.data.milestone !== undefined) {
        this.actionButtons = [buttonTypes.MILESTONE_CANCEL, buttonTypes.EDIT_MILESTONE];
        this.disableButtons = [];
      } else {
        this.actionButtons = [buttonTypes.MILESTONE_CANCEL, buttonTypes.CREATE_MILESTONE];
        this.disableButtons = [];
      }
    } else {
      if (this.data.milestone !== undefined) {
        if (!this.form.invalid && !invalidTimeIn && !invalidTimeOut) {
          this.actionButtons = [buttonTypes.MILESTONE_CANCEL, buttonTypes.EDIT_MILESTONE];
          this.disableButtons = [];
        } else {
          this.actionButtons = [buttonTypes.MILESTONE_CANCEL, buttonTypes.EDIT_MILESTONE];
          this.disableButtons = [buttonTypes.EDIT_MILESTONE];
        }
      } else {
        if (!this.form.invalid && !invalidTimeIn && !invalidTimeOut) {
          this.actionButtons = [buttonTypes.MILESTONE_CANCEL, buttonTypes.CREATE_MILESTONE];
          this.disableButtons = [];
        } else {
          this.actionButtons = [buttonTypes.MILESTONE_CANCEL, buttonTypes.CREATE_MILESTONE];
          this.disableButtons = [buttonTypes.CREATE_MILESTONE];
        }
      }
    }
  }

  onAddressFormFocus = (value: boolean) => {
    this.addressFormHasFocus = value;
  };

  onSelectPrediction = (event: PlaceInfo) => {
    this.hasRecentGoogleSelection = true;
    const sub = this.searchService.getTimezone(event.lat, event.lon).subscribe((result: MapboxTimezoneData) => {
      sub.unsubscribe();
      this.addressFormHasFocus = false;
      this.form.patchValue({
        googleLocation: {
          geometry: {
            coordinates: [event.lat, event.lon],
          },
        },
        loadLocationAddress: event.fullAddress,
      });
      this.timezone = result.features[0]?.properties.TZID;
      this.predictions = [];
    });
  };

  get canShowResultsDropdown(): boolean {
    if (this.predictions.length > 0) {
      if (this.hasRecentGoogleSelection) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  }

  generateCheckpoints(): void {
    this.checkpoints = [];
    if (!this.data.milestone) {
      this.data.load.locations.forEach((location: LoadsServiceLoadLocation) => {
        const hasCheckpoint = this.data.milestones.find((m: Milestone) => m.locationId === location.id);
        if (!hasCheckpoint) {
          this.checkpoints.push({
            key: `${location.locationType} - ${location.city} ${location.state}`,
            value: location.id,
          });
        }
      });
      const hasDispatch =
        this.data.milestones.filter((m: Milestone) =>
          m.logs.find((l: MilestoneLog) => l.subType === MilestoneSubtype.DISPATCH)
        ).length > 0;
      if (!hasDispatch) {
        this.checkpoints.push({
          key: 'Dispatch Empty',
          value: 'Dispatch Empty',
        });
      }
    } else {
      this.data.load.locations.forEach((location: LoadsServiceLoadLocation) => {
        if (this.data.milestone.locationId === location.id) {
          this.checkpoints.push({
            key: `${location.locationType} - ${location.city} ${location.state}`,
            value: location.id,
          });
        }
      });
      this.checkpoints.push({
        key: 'Dispatch Empty',
        value: 'Dispatch Empty',
      });
    }
  }

  checkStatus(): void {
    if (this.form.get('milestoneStatus').value !== 'DISPATCH') {
      if (
        this.form.get('timeOutDate').value &&
        this.form.get('timeInDate').value &&
        this.statusTypes[0].key !== 'Complete'
      ) {
        this.statusTypes[0] = {
          key: 'Complete',
          value: 'COMPLETE',
        };
        this.form.patchValue({
          milestoneStatus: this.statusTypes[0].value,
        });
      } else if (
        !this.form.get('timeOutDate').value &&
        this.form.get('timeInDate').value &&
        this.statusTypes[0].key !== 'Incomplete'
      ) {
        this.statusTypes[0] = {
          key: 'Incomplete',
          value: 'INCOMPLETE',
        };
        this.form.patchValue({
          milestoneStatus: this.statusTypes[0].value,
        });
      } else {
      }
    }
  }

  generateIssues(): void {
    this.issues = issuesList;
  }

  generateMilestoneTypes(): void {
    this.milestoneTypes = [];
    Object.keys(MilestoneType).forEach((type) => {
      if (type !== MilestoneType.LOCATION) {
        if (
          type !== MilestoneType.TRAILER ||
          (this.featureFlags[FeatureFlag.TRAILER_TRACKING] && type === MilestoneType.TRAILER)
        ) {
          this.milestoneTypes.push({
            key: type.toLowerCase().replace('_', ' '),
            value: type,
          });
        }
      }
    });
  }

  generateStatusTypes(): void {
    this.statusTypes = statusList;
  }

  generateTimeInFacility(): void {
    this.timeInFacility =
      this.form.get('timeInDate').value && this.form.get('timeOutDate').value
        ? formatDistance(this.form.get('timeInDate').value, this.form.get('timeOutDate').value, {
            includeSeconds: false,
          })
        : '';
  }

  disableFormValueIfIsEdit(): void {
    if (this.data.milestone !== undefined) {
      this.isMilestoneTypeDisabled = true;
      this.isLoadLocationDisabled = true;
      this.isAddrDisabled = true;
    }
  }

  private setFormValueIfIsEdit(): void {
    if (this.data.milestone !== undefined) {
      this.creationTime = new Date(this.data.milestone.createdAt);
      this.isEditMilestone = true;

      const location: LoadsServiceLoadLocation = this.data.load.locations.find(
        (location: LoadsServiceLoadLocation) => location.id === this.data.milestone.locationId
      );

      let loadLocationValue = '';
      if (this.data.milestone.type === MilestoneType.CHECKPOINT && !location) {
        loadLocationValue = 'Dispatch Empty';
        this.form.get('loadLocationAddress').enable();
        this.isAddrDisabled = false;
      } else if (this.data.milestone.type !== MilestoneType.UPDATE) {
        const loadLocationId = this.checkpoints.find(
          (index) => index.key === `${location.locationType} - ${location.city} ${location.state}`
        );
        loadLocationValue = loadLocationId.value;
      } else loadLocationValue = '';

      if (this.data.milestone.type === MilestoneType.UPDATE) {
        this.form.get('loadLocationAddress').enable();
        this.isAddrDisabled = false;
      }

      //TODO: get the right subType and primary and secondary events
      const subType = this.data.milestone.logs[0].subType;
      const primaryEventTime = this.data.milestone.logs[0].primaryEvent.timestamp;
      const secondaryEventTime = this.data.milestone.logs[0].secondaryEvent?.timestamp;

      if (secondaryEventTime) {
        this.enableTimeOut();
        this.disableTimeOutCancel = true;
      }
      this.form.patchValue({
        milestoneType: this.data.milestone.type,
        loadLocation: loadLocationValue,
        loadLocationAddress: this.data.milestone.loadLocationAddress,
        milestoneStatus: subType,
        timeInDate: primaryEventTime,
        timeOutDate: secondaryEventTime,
        googleLocation: {
          geometry: {
            coordinates: [
              this.data.milestone.logs[0].primaryEvent.latitude,
              this.data.milestone.logs[0].primaryEvent.longitude,
            ],
          },
        },
      });
    } else {
      this.creationTime = new Date();
      this.isEditMilestone = false;
    }
  }

  get canShowTimeInAndTimeOut(): boolean {
    if (this.form.get('loadLocation').value === 'Dispatch Empty') {
      return false;
    } else if (this.form.get('milestoneType').value === 'UPDATE') {
      return false;
    } else return true;
  }

  get canShowStatus(): boolean {
    if (this.form.get('loadLocation').value === 'Dispatch Empty') {
      return false;
    } else if (this.form.get('milestoneType').value === 'UPDATE') {
      return false;
    } else return true;
  }

  get isDisatchEmpty(): boolean {
    return this.form.get('loadLocation').value === 'Dispatch Empty';
  }

  canShowAddress = (): boolean => {
    const status = this.form.get('milestoneStatus').value;
    if (
      this.form.get('milestoneType').value === 'UPDATE' &&
      (status === 'LUMPER' || status === 'OSD' || status === 'SERVICE_FAILURE' || status === 'TONU')
    ) {
      return false;
    }
    return true;
  };

  actionButtonClick(event: string): void {
    if (event === 'cancel') {
      this.matDialogRef.close(null);
    } else if (event === 'create-milestone') {
      let location: LoadsServiceLoadLocation;
      if (
        this.form.get('milestoneType').value === MilestoneType.UPDATE ||
        this.form.get('milestoneStatus').value === MilestoneSubtype.DISPATCH
      ) {
        location = this.form.get('googleLocation').value;
        if (!location) {
          location = <any>{
            geometry: {
              coordinates: [0, 0],
              type: 'POINT',
            },
            id: '',
            timezone: this.getTimeZone,
          };
        } else {
          location.timezone = this.getTimeZone;
        }
      } else {
        let temp: LoadsServiceLoadLocation = this.data.load.locations.find(
          (location: LoadsServiceLoadLocation) => location.id === this.form.get('loadLocation').value
        ) as LoadsServiceLoadLocation;

        location = {
          ...temp,
          geometry: {
            type: temp.geometry.type,
            coordinates: [temp.geometry.coordinates[1], temp.geometry.coordinates[0]],
          },
        };
      }
      const formResult: CreateMilestoneFormResult = {
        comment: this.form.get('comment').value,
        contact: this.form.get('contact').value,
        loadLocation: location,
        loadLocationAddress: this.form.get('loadLocationAddress').value,
        milestoneStatus: this.form.get('milestoneStatus').value,
        milestoneType: this.form.get('milestoneType').value,
        timeInDate: this.form.get('timeInDate').value,
        timeOutDate: this.form.get('timeOutDate').value,
        createdBy: this.data.creator,
        creationTime: this.creationTime.valueOf(),
      };
      this.milestonesService.pushMilestoneFormResult(formResult, this.isEditMilestone, this.data.index);
    }
  }

  get getTimeZone(): string {
    const location: LoadsServiceLoadLocation = this.data.load.locations.find(
      (location) => location.id === this.form?.get('loadLocation').value
    );

    if (location) return location.timezone;
    else if (this.timezone) return this.timezone;
    else return this.data.load.locations[0].timezone;
  }

  get getMaxTime(): Date {
    return new Date();
  }

  checkPickupDropoffIsValidAction(checkpoint: string): void {
    this.invalidPickup = false;
    this.invalidDropoff = false;
    this.invalidDropoffInitial = false;

    const amountOfCheckpoints = this.data.milestones.filter(
      (milestone) => milestone.type === MilestoneType.CHECKPOINT
    ).length;
    if (checkpoint === 'dropoff' && amountOfCheckpoints === 0) {
      this.invalidDropoffInitial = true;
    } else {
      this.data.milestones.forEach((milestone) => {
        if (milestone.type === MilestoneType.CHECKPOINT) {
          milestone.logs.forEach((log) => {
            const location: LoadsServiceLoadLocation = this.data.load.locations.find(
              (location: LoadsServiceLoadLocation) => location.id === milestone.locationId
            );
            if (location) {
              if (
                location.locationType === 'pickup' &&
                log.editedByType === MilestoneUser.BROKER &&
                log.subType !== MilestoneSubtype.COMPLETE
              ) {
                this.invalidPickup = true;
              } else if (
                location.locationType === 'dropoff' &&
                log.editedByType === MilestoneUser.BROKER &&
                log.subType !== MilestoneSubtype.COMPLETE
              ) {
                this.invalidDropoff = true;
              }
            }
          });
        }
      });
    }
  }

  enableTimeOut(): boolean {
    if (this.isTimeOutDisable) {
      this.matDialogRef.updateSize('550px', '730px');
      this.form.patchValue({
        timeOutDate: null,
      });
    } else {
      this.matDialogRef.updateSize('550px', '770px');
      this.form.patchValue({
        timeOutDate: new Date(),
      });
    }
    this.isTimeOutDisable = !this.isTimeOutDisable;
    return this.isTimeOutDisable;
  }

  checkValidTimeForCompletedMilestones(): void {
    if (this.canShowTimeInAndTimeOut) {
      const pickupDropoffMilestones: Milestone[] = [];
      if (this.data.milestones.length > 0) {
        for (let i = 0; i < this.data.load.locations.length; i++) {
          const milestone = this.data.milestones.find(
            (milestone) => milestone.locationId === this.data.load.locations[i].id
          );
          if (milestone) pickupDropoffMilestones.push(milestone);
        }
      }

      if (pickupDropoffMilestones.length > 0) {
        for (let i = 0; i < pickupDropoffMilestones.length; i++) {
          if (this.data.milestone && pickupDropoffMilestones[i].id === this.data.milestone.id) {
            if (this.disableTimeOutCancel) {
              if (pickupDropoffMilestones[i - 1]) {
                const completed = pickupDropoffMilestones[i - 1].logs.find(
                  (log) => log.subType === MilestoneSubtype.COMPLETE
                );
                if (completed) {
                  if (this.form.get('timeInDate').value > completed.secondaryEvent?.timestamp) {
                    this.invalidTimeIn$.next(false);
                  } else {
                    this.notifications.error(
                      'Time in entry must be greater than Time out of the previous milestone',
                      'Error on Time In'
                    );
                    this.invalidTimeIn$.next(true);
                  }
                } else {
                  const incompleted = pickupDropoffMilestones[i - 1].logs.find(
                    (log) => log.subType === MilestoneSubtype.PICKUP || log.subType === MilestoneSubtype.DROPOFF
                  );

                  if (this.form.get('timeInDate').value > incompleted?.primaryEvent.timestamp) {
                    this.invalidTimeIn$.next(false);
                  } else {
                    this.notifications.error(
                      'Time in entry must be greater than Time out of the previous milestone',
                      'Error on Time In'
                    );
                    this.invalidTimeIn$.next(true);
                  }
                }
              } else {
                this.invalidTimeIn$.next(false);
                this.invalidTimeOut$.next(false);
              }
            } else {
              if (
                !this.form.get('timeOutDate').value ||
                this.form.get('timeInDate').value < this.form.get('timeOutDate').value
              ) {
                if (pickupDropoffMilestones[i - 1]) {
                  const completed = pickupDropoffMilestones[i - 1].logs.find(
                    (log) => log.subType === MilestoneSubtype.COMPLETE
                  );
                  if (completed) {
                    if (this.form.get('timeInDate').value > completed?.secondaryEvent?.timestamp) {
                      this.invalidTimeIn$.next(false);
                    } else {
                      this.notifications.error(
                        'Time in entry must be greater than Time out of the previous milestone',
                        'Error on Time In'
                      );
                      this.invalidTimeIn$.next(true);
                    }
                  } else {
                    const incompleted = pickupDropoffMilestones[i - 1].logs.find(
                      (log) => log.subType === MilestoneSubtype.PICKUP || log.subType === MilestoneSubtype.DROPOFF
                    );
                    if (this.form.get('timeInDate').value > incompleted?.primaryEvent.timestamp) {
                      this.invalidTimeIn$.next(false);
                    } else {
                      this.notifications.error(
                        'Time in entry must be greater than Time out of the previous milestone',
                        'Error on Time In'
                      );
                      this.invalidTimeIn$.next(true);
                    }
                  }
                } else {
                  this.invalidTimeIn$.next(false);
                  this.invalidTimeOut$.next(false);
                }
              } else {
                this.notifications.error('Time in entry must be less than Time out entry', 'Error on Time In/Time Out');
                this.invalidTimeIn$.next(true);
                this.invalidTimeOut$.next(true);
              }
            }

            if (this.form.get('timeInDate').value < this.form.get('timeOutDate').value) {
              if (pickupDropoffMilestones[i + 1]) {
                if (
                  this.form.get('timeOutDate').value < pickupDropoffMilestones[i + 1].logs[0].primaryEvent.timestamp
                ) {
                  this.invalidTimeIn$.next(false);
                  this.invalidTimeOut$.next(false);
                } else {
                  this.notifications.error(
                    'Time out entry must be less than the Time in of the next milestone',
                    'Error on Time out'
                  );
                  this.invalidTimeIn$.next(true);
                  this.invalidTimeOut$.next(true);
                }
              }
            }
          } else {
            const location = this.data.load.locations.find((loc) => loc.id === this.form.get('loadLocation').value);
            const locations = this.data.load.locations;
            for (let i = 0; i < locations.length; i++) {
              if (location && location.id === locations[i].id) {
                if (this.data.load.locations[i + 1]) {
                  const m = this.data.milestones.find((milestone) => milestone.locationId === locations[i + 1].id);
                  if (this.form.get('timeOutDate').value < m.logs[0].primaryEvent.timestamp) {
                    if (
                      this.form.get('timeOutDate').value == null ||
                      !this.form.get('timeOutDate').value ||
                      this.form.get('timeInDate').value < this.form.get('timeOutDate').value
                    ) {
                      this.invalidTimeIn$.next(false);
                      this.invalidTimeOut$.next(false);
                    } else {
                      this.notifications.error(
                        'Time in entry must be less than the Time out entry',
                        'Error on Time out'
                      );
                      this.invalidTimeIn$.next(true);
                      this.invalidTimeOut$.next(true);
                    }
                  } else {
                    this.notifications.error(
                      'Time out entry must be less than the Time in of the next milestone',
                      'Error on Time out'
                    );
                    this.invalidTimeIn$.next(true);
                    this.invalidTimeOut$.next(true);
                  }
                } else if (locations[i - 1]) {
                  const m = this.data.milestones.find((milestone) => milestone.locationId === locations[i - 1].id);
                  const completed = m.logs.find((log) => log.subType === MilestoneSubtype.COMPLETE);
                  if (completed) {
                    if (this.form.get('timeInDate').value > completed.secondaryEvent?.timestamp) {
                      if (
                        this.form.get('timeOutDate').value == null ||
                        !this.form.get('timeOutDate').value ||
                        this.form.get('timeInDate').value < this.form.get('timeOutDate').value
                      ) {
                        this.invalidTimeIn$.next(false);
                        this.invalidTimeOut$.next(false);
                      } else {
                        this.notifications.error(
                          'Time in entry must be less than the Time out entry ',
                          'Error on Time out'
                        );
                        this.invalidTimeIn$.next(true);
                        this.invalidTimeOut$.next(true);
                      }
                    } else {
                      this.notifications.error(
                        'Time in entry must be greater than Time out of the previous milestone',
                        'Error on Time In'
                      );
                      this.invalidTimeIn$.next(true);
                    }
                  } else {
                    const incompleted = m.logs.find(
                      (log) => log.subType === MilestoneSubtype.PICKUP || log.subType === MilestoneSubtype.DROPOFF
                    );
                    if (
                      incompleted &&
                      incompleted.secondaryEvent?.timestamp !== 0 &&
                      this.form.get('timeInDate').value > incompleted.secondaryEvent?.timestamp
                    ) {
                      if (
                        this.form.get('timeOutDate').value == null ||
                        !this.form.get('timeOutDate').value ||
                        this.form.get('timeInDate').value < this.form.get('timeOutDate').value
                      ) {
                        this.invalidTimeIn$.next(false);
                        this.invalidTimeOut$.next(false);
                      } else {
                        this.notifications.error(
                          'Time in entry must be less than the Time out entry',
                          'Error on Time out'
                        );
                        this.invalidTimeIn$.next(true);
                        this.invalidTimeOut$.next(true);
                      }
                    } else {
                      this.notifications.error(
                        'Time in entry must be greater than Time out of the previous milestone',
                        'Error on Time In'
                      );
                      this.invalidTimeIn$.next(true);
                    }
                  }
                }
              }
            }
          }
        }
      } else {
        if (
          this.form.get('timeOutDate').value == null ||
          !this.form.get('timeOutDate').value ||
          this.form.get('timeInDate').value < this.form.get('timeOutDate').value
        ) {
          this.invalidTimeIn$.next(false);
          this.invalidTimeOut$.next(false);
        } else {
          this.notifications.error('Time in entry must be less than the Time out entry', 'Error on Time out');
          this.invalidTimeIn$.next(true);
          this.invalidTimeOut$.next(true);
        }
      }
      this.setButtonActivity(this.invalidTimeIn$.value, this.invalidTimeOut$.value);
    }
  }
}
