import { CarrierToolbarDetailsPage, CarrierToolbarState } from '@haulynx/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export const createToolbarSelectors = (state: Observable<CarrierToolbarState>): CarrierToolbarSelectors => {
  return { detailsPage$: state.pipe(map((s) => s?.detailsPage)), isOpen$: state.pipe(map((s) => s?.isOpen)) };
};

export type CarrierToolbarSelectors = {
  detailsPage$: Observable<CarrierToolbarDetailsPage>;
  isOpen$: Observable<boolean>;
};
