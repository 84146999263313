import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { CarrierInfoSectionVmServiceV2 } from '@haulynx/services';
import { ReactiveComponentModule } from '@ngrx/component';
import { NgxMaskModule } from 'ngx-mask';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TableModule } from 'primeng/table';
import { DataTableOverlayModule } from '../../data-table-overlay/data-table-overlay.module';
import { DriverToolTipModule } from '../../driver-tool-tip/driver-tool-tip.module';
import { DropDownModule } from '../../drop-down/drop-down.module';
import { SpinnerModule } from '../../spinner/spinner.module';
import { TruckDropDownModule } from '../../truck-drop-down/truck-drop-down.module';
import { TruckInfoSectionComponent } from './truck-info-section.component';

@NgModule({
  declarations: [TruckInfoSectionComponent],
  exports: [TruckInfoSectionComponent],
  providers: [CarrierInfoSectionVmServiceV2],
  imports: [
    ReactiveFormsModule,
    MatInputModule,
    CommonModule,
    DropDownModule,
    NgxMaskModule.forRoot(),
    TableModule,
    OverlayPanelModule,
    MaterialModule,
    TruckDropDownModule,
    PipesModule,
    DriverToolTipModule,
    DataTableOverlayModule,
    SpinnerModule,
    ReactiveComponentModule,
  ],
})
export class TruckInfoSectionModule {}
