import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  LoadActiveTabGuard,
  LoadActiveTabSelectedGuard,
  LoadAvailableTabGuard,
  LoadAvailableTabSelectedGuard,
  LoadTabGuard,
  LoadTabSelectedGuard,
  NewCarrierLoadSearchRedirectGuard,
  VerifyCanEditLoadGuard,
  VerifyCanViewLoadDetailsGuard,
} from '@haulynx/guards';
import { loadActiveTabsConfig, loadAvailableTabsConfig, loadTabsConfig } from '@haulynx/types';
import { CompletedLoadsContainerComponent } from '../loads/completed-loads-container/completed-loads-container.component';
import { LoadActiveDetails } from './load-active-details/load-active-details.component';
import { LoadActiveContainer } from './load-active/load-active-container.component';
import { LoadAvailableDetailsComponent } from './load-available-details/load-available-details.component';
import { LoadAvailableSearchComponent } from './load-available-search/load-available-search.component';
import { LoadAvailableComponent } from './load-available/load-available.component';
import { LoadDistributionConfigComponent } from './load-distribution-config/load-distribution-config.component';
import { LoadFormComponent } from './load-form/load-form.component';
import { LoadSearchComponent } from './load-search/load-search.component';
import { LoadComponent } from './load/load.component';

const routes: Routes = [
  {
    path: '',
    component: LoadComponent,
    canActivate: [LoadTabGuard],
    data: {
      tabs: loadTabsConfig,
    },
    children: [
      {
        path: 'view/:loadId',
        component: LoadFormComponent,
        // something is wrong when including this component in another module
        // Using loadChildren does not display the component and doesn't throw any errors
        // loadChildren: () => import('./load-form/load-form.component.module').then((m) => m.LoadFormModule),
        canActivate: [VerifyCanEditLoadGuard, LoadTabSelectedGuard],
      },
      //active loads page
      {
        path: 'search/active',
        component: LoadActiveContainer,
        canActivate: [LoadActiveTabGuard, LoadTabSelectedGuard, NewCarrierLoadSearchRedirectGuard],
        data: {
          tabs: loadActiveTabsConfig,
        },
        children: [
          {
            path: 'details/:id',
            component: LoadActiveDetails,
            canActivate: [VerifyCanViewLoadDetailsGuard, LoadActiveTabSelectedGuard],
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LoadRoutingModule {}
