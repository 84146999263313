import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ElementsModule } from '@haulynx/elements';
import { MaterialModule } from '@haulynx/modules';
import { AppNotificationStateModule } from '@haulynx/store';
import { HeaderEndbuttonsAccountSetupModule } from './header-endbuttons-account-setup/header-endbuttons-account-setup.module';
import { HeaderEndbuttonsHelpModule } from './header-endbuttons-help/header-endbuttons-help.module';
import { HeaderEndbuttonsNotificationsModule } from './header-endbuttons-notifications/header-endbuttons.notifications.module';
import { HeaderEndbuttonsUserProfileModule } from './header-endbuttons-user-profile/header-endbuttons-user-profile.module';
import { HeaderEndbuttonsComponent } from './header-endbuttons.component';

@NgModule({
  declarations: [HeaderEndbuttonsComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    AppNotificationStateModule,
    ElementsModule,
    HeaderEndbuttonsNotificationsModule,
    HeaderEndbuttonsHelpModule,
    HeaderEndbuttonsAccountSetupModule,
    HeaderEndbuttonsUserProfileModule,
  ],
  exports: [HeaderEndbuttonsComponent],
})
export class HeaderEndbuttonsModule {}
