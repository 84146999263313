import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  CarrierApiNoteInput,
  EquipmentTypeOption,
  equipmentTypes,
  RadiusOption,
  radiusOptions,
  User,
} from '@haulynx/types';
import { timer } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-post-notes-form',
  templateUrl: './post-notes-form.component.html',
  styleUrls: ['./post-notes-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostNotesFormComponent implements AfterViewInit, OnInit {
  @ViewChild('note', { read: ElementRef, static: true }) note: ElementRef;
  @ViewChild('submitButton', { read: ElementRef, static: true }) submitButton: ElementRef;

  @Input() user: User;
  @Output() saveNote = new EventEmitter<CarrierApiNoteInput>();
  @Output() cancelForm = new EventEmitter<boolean>();

  form: FormGroup;
  radiusList: RadiusOption[] = radiusOptions;
  equipmentList: EquipmentTypeOption[] = equipmentTypes;
  now;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.initForm();
    this.now = Date.now();
  }

  initForm(): void {
    this.form = this.fb.group({
      note: [''],
    });
  }

  onCancel(e: Event): void {
    e.preventDefault();
    this.cancelForm.emit(true);
  }

  onSubmit(e: Event): void {
    e.preventDefault();
    this.form.markAllAsTouched();

    if (this.form.valid) {
      const note: CarrierApiNoteInput = {
        content: this.form.controls['note'].value,
      };

      this.saveNote.emit(note);
      this.resetForm();
      this.cancelForm.emit(true);
    }
  }

  resetForm(): void {
    this.form.reset({
      note: '',
    });

    this.focusOnNotes();
  }

  ngAfterViewInit(): void {
    timer(0)
      .pipe(first())
      .subscribe(() => this.focusOnNotes());
  }

  focusOnNotes(): void {
    this.note.nativeElement.focus();
  }
}
