import { Pipe, PipeTransform } from '@angular/core';
import { MomentService } from '@haulynx/services';

@Pipe({
  name: 'mtz',
})
export class MomentTzPipe implements PipeTransform {
  constructor(private momentService: MomentService) {}

  transform(
    dateString: number | string,
    outputFormat = 'D MMM YYYY h:mm:ssa',
    timeZone?: string,
    local = false,
    showTimeZone = false
  ): string {
    const date = this.momentService.getMoment(dateString, timeZone, local);

    if (date && date.isValid()) {
      const formattedDate = date.format(outputFormat);

      if (showTimeZone) {
        const timeZoneAbbr = this.momentService.getTimeZoneAbbr(timeZone);

        return `${formattedDate} ${timeZoneAbbr}`;
      }

      return formattedDate;
    }

    return '';
  }
}
