import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@haulynx/modules';
import { ActionButtonComponent } from './action-button.component';

@NgModule({
  imports: [CommonModule, MaterialModule],
  exports: [ActionButtonComponent],
  declarations: [ActionButtonComponent],
})
export class ActionButtonModule {}
