import { Observable } from 'rxjs';
import { PageAndSort } from '../generic';
import { IAsyncSearchResultsPaginationState } from './async-entity-state.interface';

export interface AsyncSearchManager<EntitySchema, SearchPayload> {
  dispatch: (payload: { query: SearchPayload; pageAndSort?: Partial<PageAndSort> }) => void;
  total$: Observable<number>;
  searchResults$: Observable<EntitySchema[]>;
  searchPaginationResults$: Observable<IAsyncSearchResultsPaginationState>;
  isSearching$: Observable<boolean>;
  isSearchingAndEmpty$: Observable<boolean>;
  searchQuery$: Observable<{
    queryHash: string;
    payload: SearchPayload;
    pageAndSort: Partial<PageAndSort>;
  }>;
  /**
   * Paginate your current query.
   * @param page - The desired page number. Leave `undefined` to request next page. Pass -1 to go to the previous page.
   */
  goToPage: (page?: number, limit?: number) => void;
}
