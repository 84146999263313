import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  CustomerDetailsDialogComponent,
  LoadActiveAcceptComponent,
  LoadActiveNoteComponent,
} from '@haulynx/components';
import {
  LoadsServiceService,
  LoadViewService,
  MapRoutesService,
  PageTitleService,
  PermissionsService,
} from '@haulynx/services';
import {
  AppModel,
  LoadActiveModel,
  LoadActiveTabsModel,
  LoadDetailsModel,
  LoadEntityService,
  LoadModel,
  LoadOffersModel,
  UserEntityService,
} from '@haulynx/store';
import {
  BookStatus,
  CarrierLoadServiceSearchParameters,
  CustomerFacilityDto,
  DeviceLocation,
  EXAMPLE_LOADS_SERVICE_LOAD,
  FeatureFlag,
  FFState,
  IDeleteOffer,
  loadActiveTabsConfig,
  LoadRouteSource,
  LoadsServiceLoad,
  LoadsServiceLoadLocation,
  LoadsServiceLoadLocationInput,
  LoadsServiceNoteInput,
  Tab,
  User,
} from '@haulynx/types';
import {
  aliveWhile,
  getLoadsServiceLoadRoute,
  getLoadsServiceLoadWayPointsCoordinates,
  loadsServiceLoadHasValidCoordinates,
} from '@haulynx/utils';
import { get, isEmpty, isEqual, truncate } from 'lodash';
import { combineLatest, interval, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil, tap, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'app-load-active',
  templateUrl: './load-active-container.component.html',
  styleUrls: ['./load-active-container.component.scss'],
})
export class LoadActiveContainer implements OnInit, OnDestroy {
  alive = aliveWhile();
  selectedLoad: LoadsServiceLoad = null;
  route: LoadRouteSource[] = null;
  isBroker = false;
  loadDetailsIsLoadedAndEmpty$: Observable<boolean>;
  isSearching$: Observable<boolean>;
  exampleLoad = EXAMPLE_LOADS_SERVICE_LOAD;

  loadOffers$: Observable<LoadsServiceLoad[]>;
  hideFields = {
    bookStatus: true,
    revenue: true,
  };

  loadEntitiesIsEmpty = false;

  showSelectedLoadDetailsButton = false;
  showSearchContainer$: Observable<boolean> = this.loadActiveTabsModel.tabsSelected$.pipe(
    map((tab) => {
      const isSearchTab = !tab || tab.id === loadActiveTabsConfig.SEARCH.id;

      return isSearchTab;
    })
  );

  searchTab$: Observable<boolean> = this.loadActiveTabsModel.tabsSelected$.pipe(
    map((tab) => {
      const onCorrectTab = !tab || tab.id === loadActiveTabsConfig.SEARCH.id;
      return onCorrectTab;
    })
  );

  unassignedEntities$: Observable<LoadsServiceLoad[]> =
    this.loadEntityService.getUnassignedActiveLoadsManager.searchResults$;
  assignedEntities$: Observable<LoadsServiceLoad[]> =
    this.loadEntityService.getAssignedActiveLoadsManager.searchResults$;
  inTransitEntities$: Observable<LoadsServiceLoad[]> =
    this.loadEntityService.getInTransitActiveLoadsManager.searchResults$;

  loadEntitiesIsEmpty$: Observable<boolean> = combineLatest([
    this.assignedEntities$,
    this.inTransitEntities$,
    this.unassignedEntities$,
    this.loadEntityService.getCarrierLoadsManager.isSearching$,
  ]).pipe(
    map(([assignedLoads, inTransitLoads, unassignedLoads, isSearching]) => {
      const zeroLoads = assignedLoads.length === 0 && inTransitLoads.length === 0 && unassignedLoads.length === 0;
      const empty = isSearching || zeroLoads;
      return empty;
    })
  );

  private filter: Partial<CarrierLoadServiceSearchParameters>;
  private user: User;
  private previousLoadId: string = null;
  private deviceLocation: DeviceLocation = null;
  private loadLimit = 50;
  private features: FFState;

  constructor(
    public appModel: AppModel,
    private dialog: MatDialog,
    public loadActiveModel: LoadActiveModel,
    public loadActiveTabsModel: LoadActiveTabsModel,
    public loadDetailsModel: LoadDetailsModel,
    private loadModel: LoadModel,
    private loadsServiceService: LoadsServiceService,
    private loadOffersModel: LoadOffersModel,
    private loadViewService: LoadViewService,
    public loadEntityService: LoadEntityService,
    private permissionService: PermissionsService,
    private mapRoutesService: MapRoutesService,
    private userEntityService: UserEntityService,
    private router: Router,
    private pagetitleService: PageTitleService
  ) {
    this.userEntityService.featureFlags$.pipe(takeUntil(this.alive)).subscribe((features) => {
      this.features = features;
    });
    this.loadDetailsIsLoadedAndEmpty$ = combineLatest([
      this.loadEntitiesIsEmpty$,
      this.loadOffersModel.entities$,
      this.loadEntityService.getLoadByIdManager.isLoadingEntities$,
    ]).pipe(
      map(([loadEntitiesIsEmpty, loadOffers, isLoadingEntities]) => {
        this.loadEntitiesIsEmpty = loadEntitiesIsEmpty;
        return loadEntitiesIsEmpty && loadOffers.length === 0 && !isLoadingEntities;
      }),
      distinctUntilChanged(),
      tap((isLoadedAndEmpty: boolean) => {
        if (isLoadedAndEmpty) {
          this.selectedLoad = this.exampleLoad;
          this.loadDetailsModel.getLoadRoute({
            key: this.selectedLoad?.id,
            coordinates: getLoadsServiceLoadWayPointsCoordinates(this.selectedLoad),
          });
        }
      })
    );
    this.isSearching$ = combineLatest([
      this.loadEntityService.getUnassignedActiveLoadsManager.isSearching$,
      this.loadEntityService.getAssignedActiveLoadsManager.isSearching$,
      this.loadEntityService.getInTransitActiveLoadsManager.isSearching$,
      this.loadOffersModel.isSearchLoading$,
    ]).pipe(
      map(
        ([unassignedLoadLoading, assignedLoadLoading, inTransitLoadLoading, offerLoading]: [
          boolean,
          boolean,
          boolean,
          boolean
        ]) => unassignedLoadLoading && assignedLoadLoading && inTransitLoadLoading && offerLoading
      ),
      distinctUntilChanged()
    );

    this.loadActiveModel.showNotesButton(true);

    this.appModel.user$.pipe(takeUntil(this.alive)).subscribe((user: User) => {
      if (!user) return;

      this.user = user;
      this.isBroker = this.permissionService.isBroker();
      this.hideFields.revenue = !this.isBroker;
      this.search({ dot: user.carrier?.dot, showTestLoads: !!this.features[FeatureFlag.TEST_LOADS] });

      if (this.permissionService.isCarrierAdmin()) {
        this.loadModel.selectCarrier(user.carrier.dot);
      }
    });

    this.loadEntityService.getLoadByIdManager.entities$
      .pipe(
        withLatestFrom(
          this.loadDetailsModel.deviceLocations$,
          this.loadActiveModel.selectedLoadId$,
          this.loadActiveTabsModel.tabsSelected$
        ),
        takeUntil(this.alive)
      )
      .subscribe(([loadEntities, deviceLocations, selectedLoadId, selectedTab]) => {
        if (loadEntities && loadEntities[selectedLoadId || selectedTab?.id]) {
          const load = loadEntities[selectedLoadId || selectedTab?.id];
          const id = load.id;
          const lastLocation = get(deviceLocations, id, null);
          const imei: string = get(deviceLocations, `${id}.imei`);

          if (!lastLocation) {
            this.refreshTruckLocation(load);
          }

          if (!imei) {
            const coordinates = getLoadsServiceLoadWayPointsCoordinates(load);
            this.getLoadRoute(coordinates, id);
          }

          this.selectedLoad = load;
          this.showSelectedLoadDetailsButton = this.loadViewService?.userCanViewCreatedLoadDetail(
            this.user,
            this.selectedLoad
          );
        }
      });

    combineLatest([this.loadDetailsModel.deviceLocations$, this.loadEntityService.getLoadByIdManager.entities$])
      .pipe(takeUntil(this.alive))
      .subscribe(([deviceLocations, loadEntities]) => {
        const selectedLoadId = this.currentSelectedLoadId();
        const load = get(loadEntities, selectedLoadId, null);
        const deviceLocation = get(deviceLocations, selectedLoadId, null);
        const hasDifferentLoad = !isEqual(this.previousLoadId, selectedLoadId);
        const hasDifferentLocation = !isEqual(this.deviceLocation, deviceLocation);

        if (selectedLoadId && (hasDifferentLoad || hasDifferentLocation)) {
          const truckLocation = this.mapRoutesService.getTruckLocation(deviceLocation);
          const isTruckLocationValid = this.mapRoutesService.isGpsLocationValid(truckLocation);
          const isLoadLocationValid = loadsServiceLoadHasValidCoordinates(load);

          if (isTruckLocationValid && isLoadLocationValid) {
            const wayPoints = this.mapRoutesService.addTruckByLoadsServiceLoadLocationStatus(load, truckLocation);
            const coordinates = this.mapRoutesService.normalizeWayPoints(wayPoints);

            this.getLoadRoute(coordinates, selectedLoadId);
          }
        }

        this.previousLoadId = selectedLoadId;
        this.deviceLocation = deviceLocation;
      });

    const routes$ = combineLatest([
      this.loadDetailsModel.isLoadingRoutes$,
      this.loadEntityService.getLoadByIdManager.isLoadingEntities$,
      this.loadDetailsModel.routes$,
      this.loadDetailsModel.deviceLocations$,
      this.loadEntityService.getLoadByIdManager.entities$,
    ]);

    routes$
      .pipe(
        filter(
          ([isLoadingRoutes, isLoadingEntities, routes, deviceLocations, entities]) =>
            !isLoadingRoutes && !isEmpty(isLoadingEntities) && !isLoadingEntities[this.currentSelectedLoadId()]
        ),
        map(([isLoadingRoutes, isLoadingEntities, routes, deviceLocations, loadEntities]) => {
          return { routes, deviceLocations, loadEntities };
        }),
        takeUntil(this.alive)
      )
      .subscribe(({ deviceLocations, loadEntities, routes }) => {
        if (loadEntities && routes && routes[this.currentSelectedLoadId()]) {
          const load = loadEntities[this.currentSelectedLoadId()];
          const deviceLocation = get(deviceLocations, this.currentSelectedLoadId(), null);
          const truckLocation = this.mapRoutesService.getTruckLocation(deviceLocation);

          this.route = getLoadsServiceLoadRoute(routes, load, true, truckLocation);
        }
      });

    this.loadActiveTabsModel.tabsSelected$.pipe(takeUntil(this.alive)).subscribe((tab) => {
      if (tab) {
        if (tab.id === 'search' && this.features.CARRIER_LOAD_SEARCH && !this.features.CLS_ACTIVE_LOADS) {
          return this.router.navigate(['loads/active']);
        }

        this.loadActiveTabsModel.goTo(`/dashboard/loads/search/active/${tab.url}`);
      }
    });

    interval(60000)
      .pipe(takeUntil(this.alive))
      .subscribe(() => {
        if (this.selectedLoad) {
          this.refreshTruckLocation(this.selectedLoad);
        }
      });

    this.pagetitleService.setPageTitle('All My Loads');
  }

  ngOnInit(): void {
    this.loadModel.selectCarrier$.pipe(takeUntil(this.alive)).subscribe((carrierDot) => {
      if (carrierDot) {
        const dot = carrierDot;
        this.search({ dot, showTestLoads: !!this.features[FeatureFlag.TEST_LOADS] });
      }
    });
  }

  offerDeleteSuccess(load: LoadsServiceLoad): void {
    this.dialog
      .open(LoadActiveAcceptComponent, {
        data: {
          load,
          isQuickDecline: true,
        },
      })
      .afterClosed()
      .pipe(takeUntil(this.alive))
      .subscribe((result) => {
        if (result) {
          const dot = get(this.user, 'carrier.dot');

          this.loadsServiceService
            .getBrokerBookedLoads(load.id, dot)
            .pipe(takeUntil(this.alive))
            .subscribe((data) => {
              const brokerId = get(data, 'data.getBrokerOffer.brokerId');
              const deleteOffer: IDeleteOffer = {
                loadId: load.id,
                carrierDot: dot,
                brokerId: brokerId,
                rejectionReason: result.reasons,
              };

              this.loadActiveModel.reject(deleteOffer);
            });
          // pause: allow removal of declined load offer in search results
          setTimeout(() => {
            this.search({ dot, showTestLoads: !!this.features[FeatureFlag.TEST_LOADS] });
          }, 1000);
        }
      });
  }

  refreshTruckLocation(load: LoadsServiceLoad): void {
    this.loadDetailsModel.getTruckLoadLocation({ loadId: load && load.id });
  }

  goTo(data: { tab?: Tab }): void {
    this.loadActiveTabsModel.selectTab(data.tab);
  }

  removeTab(tab: Tab): void {
    this.loadActiveTabsModel.removeTabs(tab);
  }

  goToDetails(load: LoadsServiceLoad): void {
    const { id } = load;

    if (id) {
      const [pickUp, dropOff] = load.locations;
      const tab = new Tab({
        id: `${id}`,
        label: `${truncate(pickUp.address, { length: 10 })} -> ${truncate(dropOff.address, { length: 10 })}`,
        url: `details/${id}`,
        order: null,
        selected: false,
        closable: true,
      });
      this.loadActiveTabsModel.addTabs([tab]);
      this.loadActiveTabsModel.selectTab(tab);
    }
  }

  selectLoad(loadId: string): void {
    if (loadId) {
      this.nextSelectedLoadId(loadId);
      this.loadEntityService.getLoadByIdManager.dispatch(loadId);
    }
  }

  onShowSpecialNote(load, loadLocationIndex: number): void {
    this.dialog
      .open(LoadActiveNoteComponent, {
        data: { load, loadLocationIndex },
        width: '500px',
      })
      .afterClosed()
      .pipe(takeUntil(this.alive))
      .subscribe((result: { specialNotes: string }) => {
        if (result) {
          const { specialNotes } = result;
          const locations = load.locations.map((location: LoadsServiceLoadLocation, index: number) =>
            index === loadLocationIndex
              ? {
                  notes: {
                    text: specialNotes,
                    author: get(this.user, 'id', ''),
                  } as Partial<LoadsServiceNoteInput>,
                  geometry: location.geometry,
                }
              : { notes: location.notes, geometry: location.geometry }
          ) as LoadsServiceLoadLocationInput[];

          this.loadEntityService.editLoadManager.dispatch(load.id, {
            loadId: load.id,
            input: { locations },
          });
        }
      });
  }

  bookSuccess(): void {
    const { dot } = get(this.user, 'carrier');
    const data = { dot, showTestLoads: !!this.features[FeatureFlag.TEST_LOADS] };

    this.search(data);
  }

  getLoadRoute(coordinates: string, key: string): void {
    if (coordinates) {
      this.loadDetailsModel.getLoadRoute({ key, coordinates });
    } else {
      this.loadDetailsModel.showEmptyRoute(key);
    }
  }

  onShowFacilityInfo({ customerNumber, index }: CustomerFacilityDto): void {
    if (customerNumber) {
      this.dialog.open(CustomerDetailsDialogComponent, {
        data: { customerNumber, index },
        position: { top: '15%' },
      });
    }
  }

  ngOnDestroy(): void {
    this.loadActiveModel.showNotesButton(false);
    this.alive.destroy();
  }

  private search(data: { dot?: string; showTestLoads?: boolean }): void {
    const { dot = null, showTestLoads } = data;

    if (dot) {
      const query = { dot: dot };
      this.loadOffersModel.search({ query });
      this.loadOffers$ = this.loadOffersModel.entities$.pipe(
        map((entities: LoadsServiceLoad[]) =>
          entities.filter((load) => load.bookStatus === BookStatus.BOOKABLE || load.bookStatus === BookStatus.VIEWABLE)
        ),
        takeUntil(this.alive)
      );
      if (this.permissionService.isOnlyADriver()) {
        this.filter = { ...this.filter, id: this.user?.id, showTestLoads };
      } else {
        this.filter = { ...this.filter, dot, searchCarrierLoads: true, showTestLoads };
      }
    } else {
      this.filter = this.isBroker ? this.filter : { ...this.filter, showTestLoads };
    }
    this.getUnassigned();
    this.getAssigned();
    this.getInTransit();
  }

  private getUnassigned() {
    this.loadEntityService.getUnassignedActiveLoadsManager.dispatch({
      query: { ...this.filter },
      pageAndSort: {
        sort: 'desc',
        order: 'firstAppointmentStart',
        limit: this.loadLimit,
        page: 1,
      },
    });
  }

  private getAssigned() {
    this.loadEntityService.getAssignedActiveLoadsManager.dispatch({
      query: { ...this.filter },
      pageAndSort: {
        sort: 'desc',
        order: 'firstAppointmentStart',
        limit: this.loadLimit,
        page: 1,
      },
    });
  }

  private getInTransit() {
    this.loadEntityService.getInTransitActiveLoadsManager.dispatch({
      query: { ...this.filter },
      pageAndSort: {
        sort: 'desc',
        order: 'firstAppointmentStart',
        limit: this.loadLimit,
        page: 1,
      },
    });
  }

  private currentSelectedLoadId(): string {
    return this.loadActiveModel.selectedLoadId$.getValue();
  }
  private nextSelectedLoadId(value: string): void {
    this.loadActiveModel.changeSelectedLoadId(value);
  }
}
