import { Pipe, PipeTransform } from '@angular/core';
import { LoadsServiceOrderByType, VirtualColumn } from '@haulynx/types';

@Pipe({
  name: 'virtualTableSortTypeSelected',
})
export class VirtualTableSortTypeSelectedPipe implements PipeTransform {
  transform(orderSort: LoadsServiceOrderByType, column: VirtualColumn): boolean {
    if (column?.sortType === orderSort) return true;

    return column?.otherSortTypes?.length && column.otherSortTypes.some((sortType) => sortType === orderSort);
  }
}
