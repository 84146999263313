import { Injectable } from '@angular/core';
import { CarrierLoadSearchClickEvents } from '@haulynx/types';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class FilterEventsService {
  public filterEventSubject: Subject<CarrierLoadSearchClickEvents> = new Subject<CarrierLoadSearchClickEvents>();
  readonly filterEvent$ = this.filterEventSubject.asObservable();

  constructor() {}

  setFilterEvent(event: CarrierLoadSearchClickEvents) {
    this.filterEventSubject.next(event);
  }
}
