import { ISearchFilter, TextFilterType } from '../search-filter';
import { ISearchFilterType } from '../search-filter.types';

export class CXRIdFilter implements ISearchFilter {
  name = 'CXR Id';
  type: ISearchFilterType = ISearchFilterType.TEXT_ARRAY;
  keys: TextFilterType;
  description = 'Filter loads by CXR id';

  constructor(defaultValues?: TextFilterType) {
    this.keys = new TextFilterType({
      value: '',
      optionsDataIndex: 'cxrEmployees',
      textFormName: 'cxrEmployees',
      ...defaultValues,
    });
  }
}
