import {
  BookStatus,
  CapacityObject,
  CapacityObjectInputLocation,
  IPostTruck,
  Load,
  LoadIdentifierType,
  LoadLocation,
  LoadLocationType,
  LoadRouteSource,
  LoadsServiceLoad,
  LoadsServiceLoadLocation,
  LoadsServiceLoadStatus,
  LoadsServiceProviderDetailId,
  LoadsServiceRestriction,
  LoadsServiceRestrictionTypes,
  LoadsServiceOrderByType,
  LoadsServiceOrderByTypeDirection,
  LoadStage,
  LoadWayPoint,
  Location,
  MapOptions,
  MarkerStyles,
  MilestoneSubtype,
  MilestoneType,
  NeptuneLoad,
  UnauthenticatedLoadsServiceLoad,
  User,
} from '@haulynx/types';
import { find, get, head, isEmpty, isEqual, isNumber, last, reverse, split } from 'lodash';
import { LngLatLike } from 'mapbox-gl';
import moment from 'moment';
import * as randomColor from 'randomcolor';

/**
 * Accepts a load and reads the load locations and milestone logs to gather a mapbox-friendly list of coordinates
 * @param load The load that you want to gather coordinates for
 * @returns a stringified, semi-colon-separated list of longitude / latitude pairs.
 * @example "-90,35;-90.5,36.4"
 */
export function getLoadServiceLoadWayPointsCoordinates(load: LoadsServiceLoad): string {
  if (!load.locations) {
    return;
  }
  // If there are milestones, we want to include some of their logs in our routing.
  if (load?.milestones) {
    // Mixing the load location points with milestone logs sometimes gives us a strange route.
    // So only we include the locations that haven't been finished yet.
    const incompleteLoadWaypoints: { lat: number; lon: number; timestamp: number }[] =
      load?.locations
        ?.filter((location) => {
          return !location.carrierDeparture;
        })
        .map((location) => {
          return {
            lon: location.geometry.coordinates[0],
            lat: location.geometry.coordinates[1],
            timestamp: null,
          };
        }) || [];

    const locationMilestones = load?.milestones?.filter((milestone) => milestone.type === MilestoneType.LOCATION) || [];

    const loadWaypointsWithoutMilestoneLog: { lat: number; lon: number; timestamp: number }[] =
      load?.locations
        ?.filter((loc) => {
          return loc.carrierDeparture && locationMilestones.every((milestone) => milestone.locationId !== loc.id);
        })
        ?.map((location) => {
          return {
            lon: location.geometry.coordinates[0],
            lat: location.geometry.coordinates[1],
            timestamp: location.carrierDeparture,
          };
        }) || [];

    // Ignore all milestones before the first pickup and after the last dropoff
    const startRouteTime: number = choosePickupCompleteTimeWithMilestones(load) || 0;
    const endRouteTime: number = chooseDropoffCompleteTimeWithMilestones(load) || 9999999999999;

    const milestoneWaypoints: { lat: number; lon: number; timestamp: number }[] =
      locationMilestones.reduce<{ lat: number; lon: number; timestamp: number }[]>((acc, milestone) => {
        const logs = milestone.logs.reduce((prev, log) => {
          if (
            log.timestamp >= startRouteTime &&
            log.timestamp <= endRouteTime &&
            log.subType !== MilestoneSubtype.DISPATCH
          ) {
            prev.push({
              lat: log.primaryEvent.latitude,
              lon: log.primaryEvent.longitude,
              timestamp: log.primaryEvent.timestamp,
            });
          }
          return prev;
        }, []);

        acc.push(...logs);
        return acc;
      }, []) || [];

    const visitedWaypoints = [...milestoneWaypoints, ...loadWaypointsWithoutMilestoneLog].sort(timestampSort);
    // Append the incomplete location waypoints to the milestone waypoints since they have no timestamp yet.
    return [...visitedWaypoints, ...incompleteLoadWaypoints]
      .map((waypoint) => `${waypoint.lon},${waypoint.lat}`)
      .join(';');
  } else {
    // If there are no milestones, we just want to return the locations of the load.
    return load?.locations
      .map((location) => `${location.geometry.coordinates[0]},${location.geometry.coordinates[1]}`)
      .join(';');
  }
}

export function getRemainingDistanceCoordinates(truckPosition: LngLatLike, load: LoadsServiceLoad): string {
  if (truckPosition) {
    const lastDropoffLocation = load.locations
      .filter((location: LoadsServiceLoadLocation) => location.locationType === LoadLocationType.DROPOFF)
      .reduce((acc, location) => {
        if (!acc) return location;
        if (acc.appointmentStart > location.appointmentStart) return acc;
        return location;
      }, null);

    return `${truckPosition[0]},${truckPosition[1]};${lastDropoffLocation.geometry.coordinates[0]},${lastDropoffLocation.geometry.coordinates[1]}`;
  } else {
    const lastDropoffLocation = load?.locations
      .filter((location: LoadsServiceLoadLocation) => location.locationType === LoadLocationType.DROPOFF)
      .reduce((acc, location) => {
        if (!acc) return location;
        if (acc.appointmentStart > location.appointmentStart) return acc;
        return location;
      }, null);
    const firstPickup = load?.locations
      .filter((location: LoadsServiceLoadLocation) => location.locationType === LoadLocationType.PICKUP)
      .reduce((acc, location) => {
        if (!acc) return location;
        if (acc.appointmentStart < location.appointmentStart) return acc;
        return location;
      }, null);

    return `${firstPickup.geometry.coordinates[0]},${firstPickup.geometry.coordinates[1]};${lastDropoffLocation.geometry.coordinates[0]},${lastDropoffLocation.geometry.coordinates[1]}`;
  }
}

export function decodeGeoJSONString(str, precision = 5): [number, number][] {
  if (!str) return [];
  let index = 0,
    lat = 0,
    lng = 0,
    coordinates: [number, number][] = [],
    shift = 0,
    result = 0,
    byte = null,
    factor = Math.pow(10, Number.isInteger(precision) ? precision : 5);
  let latitude_change, longitude_change;

  while (index < str.length) {
    byte = null;
    shift = 0;
    result = 0;

    do {
      byte = str.charCodeAt(index++) - 63;
      result |= (byte & 0x1f) << shift;
      shift += 5;
    } while (byte >= 0x20);

    latitude_change = result & 1 ? ~(result >> 1) : result >> 1;

    shift = result = 0;

    do {
      byte = str.charCodeAt(index++) - 63;
      result |= (byte & 0x1f) << shift;
      shift += 5;
    } while (byte >= 0x20);

    longitude_change = result & 1 ? ~(result >> 1) : result >> 1;

    lat += latitude_change;
    lng += longitude_change;

    coordinates.push([lng / factor, lat / factor]);
  }

  return coordinates;
}

export function hasValidCoordinates(load: LoadsServiceLoad): boolean {
  if (load && load.locations) {
    const locations = load.locations;

    if (locations.length && locations.length >= 2) {
      return !(
        !locations[0].geometry.coordinates[1] ||
        !locations[0].geometry.coordinates[0] ||
        !locations[1].geometry.coordinates[1] ||
        !locations[1].geometry.coordinates[0]
      );
    }
  }
}

export function truckPosition(load: LoadsServiceLoad): [number, number] {
  if (load.loadStatus === 'finalled') {
    return;
  }

  const truckPositionLat = get(load, 'truck.lastLocation.gpsLat');
  const truckPositionLon = get(load, 'truck.lastLocation.gpsLon');

  let truckPosition;
  if (truckPositionLat && truckPositionLon && load.loadStatus !== LoadsServiceLoadStatus.DELIVERED) {
    truckPosition = [truckPositionLon, truckPositionLat];
  }

  return truckPosition;
}

export function getUnitDisplayName(location: Location): string {
  if (!location) {
    return '';
  }
  if (location.type === 'phone') {
    return location.driverName ? `${location.driverName}'s Phone` : 'Phone';
  } else {
    return `Unit # ${location.unitId || 'N/A'}`;
  }
}

export function getNeptuneLoadsRoute(routes, load: NeptuneLoad) {
  const coordinates = get(routes, `${load.id}.routes[0].geometry.coordinates`, null);

  if (!coordinates) {
    return [];
  }

  return [
    {
      id: getUniqueId(load.id),
      coordinates: get(routes, `${load.id}.routes[0].geometry.coordinates`),
      wayPoints: [
        {
          name: load.loadPickupAddress,
          location: [+load.loadPickupLon, +load.loadPickupLat],
        },
        {
          name: load.loadDropoffAddress,
          location: [+load.loadDropoffLon, +load.loadDropoffLat],
        },
      ],
      lineColor: MapOptions.lineColor,
      lineWidth: MapOptions.lineWidth,
      markerColor: randomColor({ luminosity: 'dark' }),
    },
  ];
}

export function getLoadRoute(
  routes,
  load: LoadsServiceLoad,
  isVisible = true,
  truckLocation: mapboxgl.LngLatLike = null
): LoadRouteSource[] {
  if (!routes || !(load && routes[load.id] && routes[load.id].routes && routes[load.id].routes.length)) {
    return [];
  }

  const truckPos = truckLocation || truckPosition(load);

  return [
    {
      id: getUniqueId(load.id),
      coordinates: routes[load.id].routes[0].geometry.coordinates,
      wayPoints: getWayPoints(load.locations),
      truckPosition: truckPos,
      lineColor: isVisible ? MapOptions.lineColor : 'transparent',
      lineWidth: MapOptions.lineWidth,
      markerColor: randomColor({ luminosity: 'dark' }),
      carrierAccepted: get(load, 'carrierAccepted'),
      carrierId: get(load, 'carrier.id'),
    },
  ];
}

export function getTruckRoutes(truck: IPostTruck) {
  const { locationLon, locationLat, preferredLocationLon, preferredLocationLat } = truck;
  const locationGps = [+locationLon, +locationLat];
  const destinationGps = [+preferredLocationLon, +preferredLocationLat];

  return [
    {
      id: getUniqueId(truck.id),
      coordinates: [locationGps, isEqual(destinationGps, [0, 0]) ? locationGps : destinationGps],
      wayPoints: [
        { name: null, location: locationGps },
        { name: null, location: destinationGps },
      ],
      lineColor: '#f25e40',
      lineWidth: 2,
      markerStyle: MarkerStyles.TRUCK,
    },
  ];
}

export function getLaneRoutes(lane: CapacityObject) {
  let locationGps = [0, 0];
  let destinationGps = [0, 0];

  const originLon = +lane?.origin?.point?.lon;
  const originLat = +lane?.origin?.point?.lat;
  const destLon = +lane?.destination?.point?.lon;
  const destLat = +lane?.destination?.point?.lat;

  if (originLon && originLat && isNumber(originLon) && isNumber(originLat)) {
    locationGps = [+lane?.origin?.point?.lon, +lane?.origin?.point?.lat];
  } else {
    locationGps = [+lane?.destination?.point?.lon, +lane?.destination?.point?.lat];
  }

  if (destLon && destLat && isNumber(destLon) && isNumber(destLat)) {
    destinationGps = [+lane?.destination?.point?.lon, +lane?.destination?.point?.lat];
  } else {
    destinationGps = locationGps;
  }

  return [
    {
      id: getUniqueId(lane?.id),
      coordinates: [locationGps, destinationGps],
      wayPoints: [
        { name: null, location: locationGps },
        { name: null, location: destinationGps },
      ],
      lineColor: '#f25e40',
      lineWidth: 2,
      markerStyle: MarkerStyles.TRUCK,
    },
  ];
}

export function getUniqueId(id: string): string {
  return id + Math.random().toString(36).substr(2, 9);
}

export function getWayPoints(loadLocations: LoadsServiceLoadLocation[]): LoadWayPoint[] {
  return loadLocations?.map((location) => {
    return {
      name: location.address,
      location: [+location.geometry.coordinates[0], +location.geometry.coordinates[1]],
      type: location.locationType,
      completed: location.carrierDeparture,
    };
  });
}

export function getNumberOfStops(
  loadLocations: Array<LoadLocation | LoadsServiceLoadLocation>,
  wayPointType: string
): number {
  return loadLocations.reduce(
    (quantity, loadLocation) => (loadLocation.locationType === wayPointType ? ++quantity : quantity),
    0
  );
}

export function normalizeWayPoints(
  loadFeed: LoadsServiceLoad,
  pickUpNumber: number,
  dropOffNumber: number
): { pickUp: number; dropOff: number } {
  let numberOfStops = {
    pickUp: pickUpNumber,
    dropOff: dropOffNumber,
  };

  if (loadFeed.locations.length === pickUpNumber && loadFeed.locations.length > 1) {
    numberOfStops = {
      pickUp: --pickUpNumber,
      dropOff: ++dropOffNumber,
    };
  }

  return numberOfStops;
}

export function getEquipmentType(load: LoadsServiceLoad): string {
  if (hasValidCoordinates(load)) {
    return load?.providerDetails?.equipmentType;
  }
}

export function getBillOfLading(load: LoadsServiceLoad): string {
  if (hasValidCoordinates(load)) {
    return head(load.locations).billOfLading;
  }
}

export function getWeight(load: LoadsServiceLoad): string | number {
  if (hasValidCoordinates(load)) {
    return load?.providerDetails?.weight.toString();
  }
}

export function isLastLocationGpsValid(gps: [number, number]): boolean {
  return gps ? gps.filter(Boolean).length > 1 : false;
}

export function getCarrierName(load: UnauthenticatedLoadsServiceLoad): string {
  return get(load, 'truck.carrier.name', null) || get(load, 'carrier.name', null);
}

export function getSpecialNote(load: LoadsServiceLoad): string {
  if (hasValidCoordinates(load)) {
    return head(load.locations)?.notes?.text ?? '';
  }
}

export function getTimeWaitStatus(loadLocation: LoadsServiceLoadLocation): string {
  const { appointmentStart, appointmentEnd } = loadLocation;
  return new Date(appointmentEnd - appointmentStart).getTime() > 0 ? 'flexible' : 'strict';
}

export function getPickUpDate(load: LoadsServiceLoad): string {
  return hasValidCoordinates(load) ? load.locations[0].carrierArrival.toString() : null;
}

export function isLoadExclusive(load: LoadsServiceLoad): boolean {
  const exclusiveUntil = get(load, 'exclusiveUntil', null);
  const exclusiveLoad = get(load, 'exclusiveLoad', false);

  return exclusiveLoad && exclusiveUntil > new Date().getTime();
}

export function getLocationCityAndState(address: string): string {
  const [stateZip, city] = reverse(split(address, ','));
  const result = [];

  if (city) {
    result.push(city);
  }

  if (stateZip) {
    result.push(stateZip);
  }

  return result.join(',');
}

export function carrierAcceptedLoad(load: LoadsServiceLoad, carrierId: string): boolean {
  const loadCarrierId = get(load, 'carrier.id', null);
  const isAccepted = load.bookStatus == BookStatus.BOOKED;

  return carrierId && carrierId === loadCarrierId && isAccepted;
}

export function isBrokerLoad(load: LoadsServiceLoad, brokerId: string): boolean {
  const loadBrokerId = get(load, 'broker.id', null);

  return brokerId && brokerId === loadBrokerId;
}

// LOADS SERVICE LOAD
export function loadsServiceLoadHasValidCoordinates(load: UnauthenticatedLoadsServiceLoad | LoadsServiceLoad): boolean {
  if (load && load.locations) {
    const locations = load.locations;

    if (locations.length && locations.length >= 2) {
      // coordinates in lon, lat
      return !(
        !locations[0].geometry?.coordinates[1] ||
        !locations[0].geometry?.coordinates[0] ||
        !locations[1].geometry?.coordinates[1] ||
        !locations[1].geometry?.coordinates[0]
      );
    }
  }
}

export function loadsServiceLoadTruckPosition(
  load: UnauthenticatedLoadsServiceLoad | LoadsServiceLoad | LoadsServiceLoad
): [number, number] {
  if (load.loadStatus === LoadsServiceLoadStatus.FINALLED) {
    return;
  }

  const truckPositionLat = get(load, 'truck.lastLocation.gpsLat');
  const truckPositionLon = get(load, 'truck.lastLocation.gpsLon');

  let truckPosition;
  if (truckPositionLat && truckPositionLon && !loadServiceLoadDateCompleted(load)) {
    truckPosition = [truckPositionLon, truckPositionLat];
  }

  return truckPosition;
}

export function getLoadsServiceLoadRoute(
  routes,
  load: UnauthenticatedLoadsServiceLoad | LoadsServiceLoad | LoadsServiceLoad,
  isVisible = true,
  truckLocation: mapboxgl.LngLatLike = null
): LoadRouteSource[] {
  if (!routes || !(load && routes[load.id] && routes[load.id].routes && routes[load.id].routes.length)) {
    return [];
  }

  const truckPosition = truckLocation || loadsServiceLoadTruckPosition(load);

  return [
    {
      id: getUniqueId(load.id),
      coordinates: routes[load.id].routes[0].geometry.coordinates,
      wayPoints: getLoadsServiceLoadWayPoints(load.locations),
      truckPosition,
      lineColor: isVisible ? MapOptions.lineColor : 'transparent',
      lineWidth: MapOptions.lineWidth,
      markerColor: randomColor({ luminosity: 'dark' }),
      carrierAccepted: !!(load.carrier && !isEmpty(load.carrier)),
      carrierId: get(load, 'carrier.id'),
    },
  ];
}

export function getLoadsServiceLoadWayPoints(loadLocations: LoadsServiceLoadLocation[]): LoadWayPoint[] {
  return loadLocations.map((location) => {
    return {
      name: location.address,
      // coordinates in lon, lat
      location: [+location.geometry.coordinates[0], +location.geometry.coordinates[1]],
      type: location.locationType,
      completed: location?.carrierDeparture ?? null,
    };
  });
}

export function getLoadsServiceLoadEquipmentType(load: UnauthenticatedLoadsServiceLoad | LoadsServiceLoad): string {
  if (loadsServiceLoadHasValidCoordinates(load)) {
    return load.providerDetails?.equipmentType;
  }
}
export function getLoadsServiceLoadBillOfLading(load: UnauthenticatedLoadsServiceLoad | LoadsServiceLoad): string {
  if (loadsServiceLoadHasValidCoordinates(load)) {
    return head(load.locations).billOfLading;
  }
}

/**
 * iterates through alternateId array searching for the identifier type passed as parameter
 * @param load UnauthenticatedLoadsServiceLoad LoadsServiceLoad
 * @param type LoadIdentifierType
 * @returns identifierValue string
 */
export function getLoadsServiceLoadAlternateId(
  load: UnauthenticatedLoadsServiceLoad | LoadsServiceLoad,
  type: LoadIdentifierType,
  checkCoordinates: boolean = true
): string {
  if (checkCoordinates && !loadsServiceLoadHasValidCoordinates(load)) return;
  return load?.providerDetails?.alternateIds?.find(
    (detail: LoadsServiceProviderDetailId) => detail?.identifierType === type
  )?.identifierValue;
}

export function getLoadsServiceLoadWeight(load: LoadsServiceLoad): string | number {
  if (loadsServiceLoadHasValidCoordinates(load)) {
    return load?.providerDetails?.weight;
  }
}

export function getLoadsServiceLoadNote(load: LoadsServiceLoad): string {
  if (loadsServiceLoadHasValidCoordinates(load)) {
    const { notes } = head(load.locations);
    return get(notes, '[0].text', '');
  }
}

export function getLoadsServiceLoadPickUpDate(load: LoadsServiceLoad): string {
  return loadsServiceLoadHasValidCoordinates(load) ? head(load.locations)?.appointmentStart.toString() : null;
}

export function isLoadsServiceLoadExclusive(load: LoadsServiceLoad): boolean {
  const restriction = load?.restrictions?.find((restrictions: LoadsServiceRestriction) => {
    return restrictions?.type === LoadsServiceRestrictionTypes.EXCLUSIVE_UNTIL;
  });
  if (!restriction) return false;
  return +restriction.value > new Date().getTime();
}

export function carrierAcceptedLoadsServiceLoad(load: LoadsServiceLoad, carrierId: string): boolean {
  const loadCarrierId = get(load, 'carrier.id', null);

  return carrierId === loadCarrierId;
}

export function getLoadsServiceLoadWayPointsCoordinates(load: LoadsServiceLoad): string {
  if (!load.locations) {
    return;
  }

  if (load.locations.length < 2) {
    return;
  }

  return load.locations
    .filter(
      (location: LoadsServiceLoadLocation) => location.geometry.coordinates[1] && location.geometry.coordinates[0]
    )
    .map(
      (location: LoadsServiceLoadLocation) => `${location.geometry.coordinates[0]},${location.geometry.coordinates[1]}`
    )
    .join(';');
}

export function normalizeLoadsServiceLoadWayPoints(
  loadFeed: LoadsServiceLoad,
  pickUpNumber: number,
  dropOffNumber: number
): {
  pickUp: number;
  dropOff: number;
} {
  let numberOfStops = {
    pickUp: pickUpNumber,
    dropOff: dropOffNumber,
  };

  if (loadFeed.locations.length === pickUpNumber && loadFeed.locations.length > 1) {
    numberOfStops = {
      pickUp: --pickUpNumber,
      dropOff: ++dropOffNumber,
    };
  }

  return numberOfStops;
}

export function getLoadsServiecLoadTimeWaitStatus(location: LoadsServiceLoadLocation): string {
  const { appointmentEnd } = location;

  return appointmentEnd && new Date(appointmentEnd).getTime() > 0 ? 'flexible' : 'strict';
}

export function loadServiceLoadDateCompleted(
  load: UnauthenticatedLoadsServiceLoad | LoadsServiceLoad | LoadsServiceLoad
): number {
  return load.locations?.length && load.locations[load.locations.length - 1].carrierDeparture;
}

const timestampSort = (x, y) => x.timestamp - y.timestamp;

/**
 * Chooses the presumed pickup complete time based off of milestone data and the load location data.
 * @param load
 * @returns A timestamp chosen from milestone checkpoint data (favored) or pickup departure time
 */
const choosePickupCompleteTimeWithMilestones = (load: LoadsServiceLoad): number => {
  if (!load) {
    return null;
  }

  const pickupLocationDeparture: number = load.locations[0].carrierDeparture;

  const pickupCompletionLog = load.milestones
    ?.find((milestone) => {
      return milestone.locationId === load.locations[0].id;
    })
    ?.logs.find((log) => log.subType === MilestoneSubtype.COMPLETE);

  const pickupCompleteMilestoneTime: number = Math.max(
    pickupCompletionLog?.timestamp || 0,
    pickupCompletionLog?.primaryEvent.timestamp || 0,
    pickupCompletionLog?.secondaryEvent.timestamp || 0
  );

  // Favor milestone data
  return pickupCompleteMilestoneTime || pickupLocationDeparture;
};

/**
 * Chooses the presumed dropoff complete time based off of milestone data and the load location data.
 * @param load
 * @returns A timestamp chosen from milestone checkpoint data (favored) or dropoff departure time
 */
const chooseDropoffCompleteTimeWithMilestones = (load: LoadsServiceLoad): number => {
  if (!load) {
    return null;
  }

  const dropoffLocationDeparture: number = last(load.locations)?.carrierDeparture;

  const dropoffCompletionLog = load.milestones
    ?.find((milestone) => {
      return milestone.locationId === last(load.locations)?.id;
    })
    ?.logs.find((log) => log.subType === MilestoneSubtype.COMPLETE);

  const dropoffCompleteMilestoneTime: number = Math.max(
    dropoffCompletionLog?.timestamp || 0,
    dropoffCompletionLog?.primaryEvent.timestamp || 0,
    dropoffCompletionLog?.secondaryEvent.timestamp || 0
  );

  // Favor milestone data
  return dropoffCompleteMilestoneTime || dropoffLocationDeparture;
};

/**
 * virtualLocalSort sorts an array of loadServiceLoads based
 * on order and direction
 * @param data
 * @param dataIndex
 * @param sortOrder
 * @param sortDirection
 * @returns sorted loadsServiceLoad array
 */
export function virtualLocalSort(
  data: LoadsServiceLoad[],
  dataIndex: string,
  sortOrder: LoadsServiceOrderByType,
  sortDirection: LoadsServiceOrderByTypeDirection
): LoadsServiceLoad[] {
  return [...data].sort((a, b) => {
    let value1 = dataIndex ? get(a, dataIndex) : getIndexPath(a, sortOrder);
    let value2 = dataIndex ? get(b, dataIndex) : getIndexPath(b, sortOrder);

    let result = null;
    if (!value1 && value2) result = 1;
    else if (!value2 && value1) result = -1;
    else if (!value1 && !value2) result = 0;
    else if (typeof value1 === 'string') result = value1.localeCompare(value2);
    else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

    return sortDirection === LoadsServiceOrderByTypeDirection.ASC ? 1 * result : -1 * result;
  });
}

/**
 * getIndexPath returns data nested in
 * load location index path for virtualLocalSort.
 * @param load
 * @param sortType
 * @returns
 */
const getIndexPath = (load: LoadsServiceLoad, sortType: LoadsServiceOrderByType): number | string => {
  const { locations = null } = load;

  if (!locations) return;

  const lastLocation = locations[locations?.length - 1] ?? null;
  switch (sortType) {
    case LoadsServiceOrderByType.originLocation:
      return locations[0]?.summaryAddress;
    case LoadsServiceOrderByType.firstAppointmentStart:
      return locations[0].appointmentStart;
    case LoadsServiceOrderByType.destinationLocation:
      return lastLocation.summaryAddress;
    case LoadsServiceOrderByType.lastAppointmentStart:
      return lastLocation.appointmentStart;
    default:
      return null;
  }
};
