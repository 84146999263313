import gql from 'graphql-tag';

export const GetLoadsServiceLoadTemplatesByUserId = gql`
  query GetLoadsServiceLoadTemplatesByUserId($userId: ID!) {
    getLoadsServiceLoadTemplatesByUserId(userId: $userId) {
      id
      name
      templateId
      createdBy
      dateCreated
      locations {
        id
        customer {
          id
          number
          name
        }
        address
        timezone
        geometry {
          coordinates
        }
        appointmentStart
        appointmentEnd
        carrierArrival
        carrierDeparture
        appointmentSet
        locationType
        notes {
          text
        }
      }
      providerDetails {
        alternateIds {
          identifierType
          identifierValue
        }
        weightLbs
        equipmentType
        commodity
        quantity
      }
      paymentDetails {
        price
      }
      trailers {
        id
        truckId
        trailerNumber
      }
    }
  }
`;
