import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LaneHistoryTimelineComponent } from './lane-history-timeline.component';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [LaneHistoryTimelineComponent],
  imports: [CommonModule, MaterialModule, PipesModule, FlexLayoutModule],
  exports: [LaneHistoryTimelineComponent],
})
export class LaneHistoryTimelineModule {}
