import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MaterialModule } from '@haulynx/modules';
import { NgxMaskModule } from 'ngx-mask';
import { AddTruckComponent } from './add-truck.component';

@NgModule({
  declarations: [AddTruckComponent],
  imports: [CommonModule, ReactiveFormsModule, NgxMaskModule.forRoot(), MatDialogModule, MaterialModule],
  exports: [AddTruckComponent],
})
export class AddTruckModule {}
