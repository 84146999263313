import { Injectable } from '@angular/core';
import { BidsService } from '@haulynx/services';
import { AsyncQueryManager, Bid } from '@haulynx/types';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../../main-store/app.reducers';
import { AsyncEntityBase } from '../../main-store/async-entity/async-entity-base.class';
import { bidEntityNamespace } from './namespace';

@Injectable({ providedIn: 'root' })
export class BidEntityService extends AsyncEntityBase<Bid> {
  getBidByIdManager: AsyncQueryManager<Bid, string>;

  constructor(protected actions$: Actions, protected store: Store<AppState>, private bidsService: BidsService) {
    super(actions$, store, bidEntityNamespace);

    this.getBidByIdManager = this.createAsyncQuery((bidId: string) => this.bidsService.getBidById(bidId));
  }
}
