<div class="row">
  <div class="col-lg-12">
    <h2 matDialogTitle>Accept Load</h2>
    <mat-dialog-content style="width: 480px">
      <div class="row">
        <div class="col-lg-12">
          <span>Do you wish to accept this load? <br /></span>
        </div>
      </div>

      <form [formGroup]="form">
        <div class="row">
          <mat-form-field class="col-lg-12">
            <mat-select formControlName="driver" placeholder="Select Driver">
              <mat-option [value]="null"> </mat-option>
              <mat-option *ngFor="let driver of drivers" [value]="driver">
                {{ driver.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="row">
          <mat-form-field class="col-lg-12">
            <mat-select formControlName="truck" placeholder="Select Truck">
              <mat-option [value]="null"> </mat-option>
              <mat-option *ngFor="let truck of trucks" [value]="truck">
                {{ getUnitDisplayName(truck) }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </form>

      <span style="color: gray; font-size: 0.8em">
        By accepting this Load, you'll be responsible for ensuring it gets delivered to it's final destination</span
      >
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-raised-button [disabled]="disableButton" [mat-dialog-close]="getData()" color="primary">
        <span style="color: #fff">Accept Load</span>
      </button>
      <button mat-raised-button color="warn" [matDialogClose]="null">Cancel</button>
    </mat-dialog-actions>
  </div>
</div>
