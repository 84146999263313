import { User } from '@haulynx/types';
import { createAction, createActionTypes } from '../../shared/store/helpers/action';
import { combineAdaptersActionsState, combineAdaptersActionTypeState } from '../../shared/store/helpers/adapter';
import { ISearchActions, ISearchTypes } from '../../shared/store/search/actions';
import { TYPE, userAdapter } from './adapters';

interface IUserActions extends ISearchActions {
  createUser(user?: Partial<User>);
  createUserSuccess(params?);
  createUserError(err?);
  validateDriversLicense(params?);
  validateDriversLicenseSuccess(params?);
  validateDriversLicenseError(params?);
  disableTeamDrivingSuccess(params?);
  disableTeamDrivingError(params?);
  getUser(userId);
  getUserSuccess(user);
  getUserError(err?);
  clearSelectedUser();
  updateUser(params);
  updateUserSuccess(params?);
  updateUserError(err?);
}

interface IUserActionType extends ISearchTypes {
  CREATE_USER: string;
  CREATE_USER_SUCCESS: string;
  CREATE_USER_ERROR: string;
  VALIDATE_DRIVERS_LICENSE: string;
  VALIDATE_DRIVERS_LICENSE_SUCCESS: string;
  VALIDATE_DRIVERS_LICENSE_ERROR: string;
  DISABLE_TEAM_DRIVING: string;
  DISABLE_TEAM_DRIVING_SUCCESS: string;
  DISABLE_TEAM_DRIVING_ERROR: string;
  GET_USER: string;
  GET_USER_SUCCESS: string;
  GET_USER_ERROR: string;
  CLEAR_SELECTED_USER: string;
  UPDATE_USER: string;
  UPDATE_USER_SUCCESS: string;
  UPDATE_USER_ERROR: string;
}

export const userActionType = {
  CREATE_USER: 'create',
  CREATE_USER_SUCCESS: 'create success',
  CREATE_USER_ERROR: 'create error',
  VALIDATE_DRIVERS_LICENSE: 'validate user',
  VALIDATE_DRIVERS_LICENSE_SUCCESS: 'validate user success',
  VALIDATE_DRIVERS_LICENSE_ERROR: 'validate user error',
  GET_USER: 'get user',
  GET_USER_SUCCESS: 'get user success',
  GET_USER_ERROR: 'get user error',
  CLEAR_SELECTED_USER: 'clear selected user',
  UPDATE_USER: 'update user',
  UPDATE_USER_SUCCESS: 'update user success',
  UPDATE_USER_ERROR: 'update user error',
};

export const userActions = {
  createUser: createAction(TYPE, userActionType.CREATE_USER),
  createUserSuccess: createAction(TYPE, userActionType.CREATE_USER_SUCCESS),
  createUserError: createAction(TYPE, userActionType.CREATE_USER_ERROR),
  validateDriversLicense: createAction(TYPE, userActionType.VALIDATE_DRIVERS_LICENSE),
  validateDriversLicenseSuccess: createAction(TYPE, userActionType.VALIDATE_DRIVERS_LICENSE_SUCCESS),
  validateDriversLicenseError: createAction(TYPE, userActionType.VALIDATE_DRIVERS_LICENSE_ERROR),
  getUser: createAction(TYPE, userActionType.GET_USER),
  getUserSuccess: createAction(TYPE, userActionType.GET_USER_SUCCESS),
  getUserError: createAction(TYPE, userActionType.GET_USER_ERROR),
  clearSelectedUser: createAction(TYPE, userActionType.CLEAR_SELECTED_USER),
  updateUser: createAction(TYPE, userActionType.UPDATE_USER),
  updateUserSuccess: createAction(TYPE, userActionType.UPDATE_USER_SUCCESS),
  updateUserError: createAction(TYPE, userActionType.UPDATE_USER_ERROR),
};

export const UsersActions: IUserActions = {
  ...combineAdaptersActionsState(userAdapter),
  ...userActions,
};
export const UsersActionTypes: IUserActionType = {
  ...combineAdaptersActionTypeState(userAdapter),
  ...createActionTypes(userActionType)(TYPE),
};
