import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@haulynx/modules';
import { ConfirmationComponent } from './confirmation.component';

@NgModule({
  imports: [CommonModule, MaterialModule],
  exports: [ConfirmationComponent],
  declarations: [ConfirmationComponent],
})
export class ConfirmationModule {}
