import { Injectable } from '@angular/core';
import {
  CreateCapacityManagementLane,
  DeleteCapacityManagementLanes,
  GetCapacityManagementLanesByCarrierDot,
} from '@haulynx/gql';
import {
  CapacityObject,
  CapacityObjectInput,
  LaneRecommendation,
  LoadsServiceLoad,
  PageAndSort,
  PaginatedData,
} from '@haulynx/types';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GraphqlService } from '../graphql/graphql.service';

@Injectable({
  providedIn: 'root',
})
export class PreferredLanesService {
  constructor(private graphqlService: GraphqlService) {}

  createCarrierLane(input: CapacityObjectInput): Observable<CapacityObject> {
    return this.graphqlService
      .mutate({
        mutation: CreateCapacityManagementLane,
        variables: { newLane: input },
      })
      .pipe(
        map((result: { data }) => {
          return result?.data?.createCapacityManagementLane as CapacityObject;
        }),
        catchError((error) => throwError(error))
      );
  }

  getLanesByDOT(carrierDot, paging: Partial<PageAndSort> = {}): Observable<PaginatedData<CapacityObject>> {
    const defaultPaging: PageAndSort = { limit: 10, page: 1, sort: 'ASC' };
    return this.graphqlService
      .query({
        query: GetCapacityManagementLanesByCarrierDot,
        variables: {
          carrierDot,
        },
      })
      .pipe(
        map((result) => {
          const data = result.data['getCapacityManagementLanesByCarrierDot'] as CapacityObject[];
          return {
            data: data,
            pagination: { total: data?.length || 0, ...paging, ...defaultPaging },
          } as PaginatedData<CapacityObject>;
        })
      );
  }

  deleteLane(laneIds: string[]): Observable<string> {
    return this.graphqlService
      .mutate({
        mutation: DeleteCapacityManagementLanes,
        variables: {
          laneIds: laneIds,
        },
      })
      .pipe(
        map((result: { data }) => result?.data?.deleteLoadsServiceLoadById),
        catchError((error) => throwError(error))
      );
  }
}
