import { startCase } from 'lodash';
import { ISearchFilter, TextFilterType } from '../search-filter';
import { ISearchFilterType } from '../search-filter.types';

export class EquipmentFilter implements ISearchFilter {
  name = 'Equipment';
  type: ISearchFilterType = ISearchFilterType.MULTI_DROPDOWN;
  keys: TextFilterType;
  description = 'Filter loads by the equipment';
  transformValue = (values: string[] | string) => {
    return Array.isArray(values) ? values?.map(startCase).join(', ') : startCase(values);
  };

  constructor(defaultValues?: TextFilterType) {
    this.keys = new TextFilterType({
      value: '',
      optionsDataIndex: [
        {
          key: 'Flat Bed',
          value: 'flatBed',
        },
        {
          key: 'Dry Van',
          value: 'dryVan',
        },
        {
          key: 'Reefer',
          value: 'reefer',
        },
        {
          key: 'Dry Van or Reefer',
          value: 'dryVanOrReefer',
        },
        {
          key: 'Lowboy',
          value: 'lowboy',
        },
      ],
      textFormName: 'equipment',
      ...defaultValues,
    });
  }
}
