<div class="milestones">
  <app-divider-slider
    [leftTopTemplate]="leftContainer"
    [rightBottomTemplate]="rightContainer"
    [isVertical]="false"
    [limits]="dividerSliderLimits"
  >
    <ng-template #leftContainer>
      <div class="leftContainerBubbleContent">
        this has width limits of: <br />
        <br />
        MIN: {{ dividerSliderLimits.left.min }}<br />
        MAX: {{ dividerSliderLimits.left.max }}
      </div>
    </ng-template>
    <ng-template #rightContainer>
      <app-divider-slider
        [leftTopTemplate]="topContainer"
        [rightBottomTemplate]="bottomContainer"
        [isVertical]="true"
        [limits]="dividerSliderLimitsOther"
      >
        <ng-template #topContainer>
          <div class="topContainerBubbleContent">
            this has height limits of: <br />
            <br />
            MIN: {{ dividerSliderLimitsOther.top.min }}<br />
            MAX: {{ dividerSliderLimitsOther.top.max }}
          </div>
        </ng-template>

        <ng-template #bottomContainer>
          <app-divider-slider
            [leftTopTemplate]="leftContainer2"
            [rightBottomTemplate]="rightContainer2"
            [isVertical]="false"
            [limits]="dividerSliderLimitsBottom"
          >
            <ng-template #leftContainer2>
              <div class="leftContainerBubbleContent2">
                this has width limits of: <br />
                <br />
                MIN: {{ dividerSliderLimitsBottom.left.min }}<br />
                MAX: {{ dividerSliderLimitsBottom.left.max }}
              </div>
            </ng-template>
            <ng-template #rightContainer2>
              <div class="bottomContainerBubbleContent">currently this container is width auto.</div>
            </ng-template>
          </app-divider-slider>
        </ng-template>
      </app-divider-slider>
    </ng-template>
  </app-divider-slider>
</div>
