import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'haulynx-xt-carrier-requirements-dialog',
  templateUrl: './xt-carrier-requirements-dialog.component.html',
  styleUrls: ['./xt-carrier-requirements-dialog.component.scss'],
})
export class XtCarrierRequirementsDialogComponent {
  constructor(private dialogRef: MatDialogRef<XtCarrierRequirementsDialogComponent>) {}

  public close() {
    this.dialogRef.close();
  }
}
