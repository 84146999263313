import { createSelector } from '@ngrx/store';
import { ISelectState } from './state';

export interface ISelectSelectors {
  getSelected: (state) => string;
}

export function createSelectSelectors<T>() {
  return {
    getSelectors(selectState: (state) => ISelectState): ISelectSelectors {
      const selected = (state) => state && state.selectedId;

      const getSelected = createSelector(selectState, selected);

      return {
        getSelected,
      };
    },
  };
}
