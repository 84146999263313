import { Observable } from 'rxjs';

export interface AsyncBulkMutationManager<PayloadType, ResponseData> {
  isLoading$: Observable<boolean>;
  isLoadingEntities$: Observable<{ [entityId: string]: boolean }>;
  /**
   * This will trigger when the effect returns successfully
   */
  onSuccess$: Observable<ResponseData>;
  /**
   * This will trigger when the effect returns an error
   */
  onError$: Observable<any>;
  /**
   * This will trigger when the effect returns either successfully or with an error
   */
  onResponse$: Observable<ResponseData | any>;
  dispatch: (entityIds: string[], payload?: PayloadType) => void;
}
