<div class="load-details-container" fxLayout="column">
  <div
    class="button"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="8px"
    ngClass.lt-md="padding-bottom-8"
    (click)="backToSearch()"
  >
    <mat-icon>arrow_back</mat-icon>
    <span>To Search Results</span>
  </div>

  <div class="load-details" fxHide.lt-md="true">Load Details</div>

  <app-unauthenticated-load-details
    [load]="load"
    [route]="route"
    [stops]="stops"
    (signup)="signupClick()"
  ></app-unauthenticated-load-details>
</div>
