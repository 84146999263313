import { Pipe, PipeTransform } from '@angular/core';
import { formatTimestampToHuman } from '@haulynx/utils';

@Pipe({
  name: 'dateTime',
})
export class DateTimePipe implements PipeTransform {
  transform(timestamp: number | string, timeZone: string, outputFormat = 'MM/dd/yy HH:mm zz'): string {
    return formatTimestampToHuman(timestamp, timeZone, outputFormat);
  }
}
