import { I } from '@angular/cdk/keycodes';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { LaneHistorySearchParameters } from '../../lane-history/lane-history-search-parameters';
import { getStateCode } from 'libs/utils/src/lib/utils/utils';

export type LaneAnalysisSearchFilter = keyof LaneHistorySearchParameters;

export interface LaneAnalysisSearchFilters {
  main?: LaneAnalysisSearchFilterBase[];
  chip?: LaneAnalysisSearchFilterBase[];
  // more?: LaneAnalysisSearchFilterBase[];
}

export enum LaneAnalysisSearchFilterSection {
  MAIN,
  CHIP,
  // MORE,
}

export interface LaneAnalysisSearchFilterBase {
  id: string;
  label?: (state: LaneHistorySearchParameters) => string;
  section: LaneAnalysisSearchFilterSection;

  /**
   * Take the full state and change it to an api readable object
   */
  serialize: (state: LaneHistorySearchParameters) => Partial<LaneHistorySearchParameters>;

  /**
   * Whether the value for the selected filter base has been modified and contains a value
   */
  valueIsSelected?: (state: LaneHistorySearchParameters) => boolean;
}

export const laneHistorySearchOrigin: LaneAnalysisSearchFilterBase = {
  id: 'origin',
  label: (state: LaneHistorySearchParameters) => {
    if (state['origin']['address'].length > 0) {
      const addressArray = state['origin']['address']?.split(',');
      const stateWithoutZipcode = addressArray[1].replace(/[0-9]/g, '').replace(/\s/g, '');
      return addressArray.length < 3
        ? `${getStateCode(addressArray[0], addressArray[1])}`
        : `${addressArray[0]}, ${getStateCode(stateWithoutZipcode, addressArray[2])}`;
    }
  },
  section: LaneAnalysisSearchFilterSection.MAIN,
  serialize: (state: LaneHistorySearchParameters): Partial<LaneHistorySearchParameters> => {
    return {
      originLat: state['lat'],
      originLon: state['lon'],
      originRadiusMiles: state['radiusMiles'],
    };
  },
};

export const laneHistorySearchDestination: LaneAnalysisSearchFilterBase = {
  id: 'destination',
  label: (state: LaneHistorySearchParameters) => {
    if (state['destination']['address'].length > 0) {
      const addressArray = state['destination']['address']?.split(',');
      const stateWithoutZipcode = addressArray[1].replace(/[0-9]/g, '').replace(/\s/g, '');
      return addressArray.length < 3
        ? `${getStateCode(addressArray[0], addressArray[1])}`
        : `${addressArray[0]}, ${getStateCode(stateWithoutZipcode, addressArray[2])}`;
    }
  },
  section: LaneAnalysisSearchFilterSection.MAIN,
  serialize: (state: LaneHistorySearchParameters): Partial<LaneHistorySearchParameters> => {
    return {
      destinationLat: state['lat'],
      destinationLon: state['lon'],
      destinationRadiusMiles: state['radiusMiles'],
    };
  },
};

export const laneHistoryDateRange: LaneAnalysisSearchFilterBase = {
  id: 'dateRange',
  label: (state: LaneHistorySearchParameters) => {
    const displayValue = state['dateRange']['displayValue'];
    return displayValue ? `${displayValue}` : 'Past 90 Days';
  },
  section: LaneAnalysisSearchFilterSection.CHIP,
  serialize: (state: LaneHistorySearchParameters): Partial<LaneHistorySearchParameters> => {
    // Default to last 90 days if missing.
    if (!state.startDate || !state.endDate) {
      const endDate = new Date();
      endDate.setUTCHours(23, 59, 59, 999);

      const startDate = new Date();
      startDate.setUTCHours(0, 0, 0, 0);
      startDate.setDate(endDate.getDate() - 90);

      return {
        startDate: startDate.getTime().toString(),
        endDate: endDate.getTime().toString(),
      };
    }

    return {
      startDate: state.startDate,
      endDate: state.endDate,
    };
  },
  valueIsSelected: (state: LaneHistorySearchParameters): boolean => {
    if (!state) {
      return false;
    }

    return !!state['dateRange']['startDate'] && !!state['dateRange']['endDate'];
  },
};

export const allLaneAnalysisSearchFilters: LaneAnalysisSearchFilterBase[] = [
  laneHistorySearchOrigin,
  laneHistorySearchDestination,
  laneHistoryDateRange,
];
