export class Customer {
  id: number;
  company: string;
  number: number;
  name: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
  billTo: number;
  isShipper: boolean;
  isConsignee: boolean;
  latCust: number;
  lonCust: number;
  custGeohash: CustomerGeohash;
  custTimezone: string;
  custFormattedAddress: string;
  latGate: number;
  lonGate: number;
  gateGeohash: CustomerGeohash;
  gateTimezone: string;
  gateFormattedAddress: string;
  telephone: string;
  status: string;
  polygon: CustomerPolygon;
  notes: CustomerNote[];
  receivingHours: CustomerHoursOfOperation[];
  shippingHours: CustomerHoursOfOperation[];
  customerAttributes: CustomerAttributes[];

  constructor(props?: Partial<Customer>) {
    Object.assign(this, props);
  }
}

export class CustomerSearchParameters {
  customerNumber: string;
  partialName?: string;
  state: string;
  city?: string;

  constructor(props?: Partial<CustomerSearchParameters>) {
    Object.assign(this, props);
  }
}

export class DocumentParamsInput {
  title: string;
  tags: string;
  lookup_ids: string;
  document_types: string;
}

export class CustomerPolygon {
  coordinates: { lat: number; lon: number; geohash: CustomerGeohash }[];
}

export class CustomerNote {
  noteNumber: string;
  noteSeq: string;
  noteText: string;
  actionType: string;
}

export class CustomerAttributes {
  canPickUpEarly: boolean;
  canDeliverEarly: boolean;
  dropYard: string;
  canBreakAtFacility: boolean;
  hasFacilities: boolean;
}

export class CustomerHoursOfOperation {
  /**
   * Example: "Sunday"
   */
  dayOfWeek: string;
  /**
   * A timestamp based off the beginning of unix time. Ignore the date because the hours are the only relevant piece of information.
   */
  openTime: string;
  /**
   * A timestamp based off the beginning of unix time. Ignore the date because the hours are the only relevant piece of information.
   */
  closeTime: string;
  isFirstComeFirstServe: boolean;
}

export class CustomerGeohash {
  lat: number;
  lon: number;
  precision: number;
  hash: string;
  neighbor: GeohashNeighbor;
  approximateAddress: string;
}

export class GeohashNeighbor {
  north: CustomerGeohash;
  northeast: CustomerGeohash;
  east: CustomerGeohash;
  southeast: CustomerGeohash;
  south: CustomerGeohash;
  southwest: CustomerGeohash;
  west: CustomerGeohash;
  northwest: CustomerGeohash;
}
