import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoadFeedDetailsComponentsModule, SpinnerModule } from '@haulynx/components';
import { SharedLoadGuard, SharedLoadResolver } from '@haulynx/guards';
import { FireDatabaseService } from '@haulynx/services';
import { LoadDetailsModel, LoadFeedModel, LoadModel, NotificationModel } from '@haulynx/store';
import { LoadFeedDetailsContainerComponent } from './container/load-feed-details.component';

@NgModule({
  declarations: [LoadFeedDetailsContainerComponent],
  imports: [CommonModule, LoadFeedDetailsComponentsModule, SpinnerModule],
  providers: [
    SharedLoadGuard,
    SharedLoadResolver,
    LoadFeedModel,
    LoadDetailsModel,
    LoadModel,
    FireDatabaseService,
    NotificationModel,
  ],
  exports: [LoadFeedDetailsContainerComponent],
})
export class LoadFeedDetailsModule {}
