import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { OrderForm } from '@haulynx/types';
import { forEach, keys } from 'lodash';

export class OrderInfoForm implements OrderForm {
  order: string = null;
  brokerId: string = null;

  constructor(options = {}) {
    const key = keys(this);
    const defaultValue = [null];

    forEach(key, (prop) => {
      this[prop] = (options && options[prop]) || defaultValue;
    });
  }
}

@Injectable({
  providedIn: 'root',
})
export class OrderInfoVmService {
  options;
  private fb = new FormBuilder();
  constructor() {
    this.options = {
      order: [{ value: null, disabled: true }],
    };
  }

  create(values: Partial<OrderInfoForm> = {}): FormGroup {
    const orderInfoForm = new OrderInfoForm(this.options);
    const formGroup = this.fb.group(orderInfoForm);

    if (values) {
      formGroup.patchValue(values);
    }

    return formGroup;
  }
}
