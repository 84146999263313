import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@haulynx/modules';
import { ActionButtonsComponent } from './action-buttons.component';

@NgModule({
  declarations: [ActionButtonsComponent],
  exports: [ActionButtonsComponent],
  imports: [CommonModule, MaterialModule],
})
export class ActionButtonsModule {}
