import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AssignLoadEditForm, RateConEmail } from '@haulynx/types';
import { forEach, keys } from 'lodash';

export class OrderInfoNotesForm implements AssignLoadEditForm {
  externalNotes: string = null;
  internalNotes: string = null;
  customerNotes: string = null;
  rateConEmails: RateConEmail[] = null;

  constructor(options = {}) {
    const key = keys(this);
    const defaultValue = [null];

    forEach(key, (prop) => {
      this[prop] = (options && options[prop]) || defaultValue;
    });
  }
}

@Injectable({
  providedIn: 'root',
})
export class OrderInfoNotesVmService {
  options;
  private fb = new FormBuilder();
  constructor() {
    this.options = {
      externalNotes: [''],
      internalNotes: [''],
      customerNotes: [''],
      rateConEmails: [[]],
    };
  }

  create(values: Partial<OrderInfoNotesForm> = {}): FormGroup {
    const orderInfoNotesForm = new OrderInfoNotesForm(this.options);
    const formGroup = this.fb.group(orderInfoNotesForm);

    if (values) {
      formGroup.patchValue(values);
    }

    return formGroup;
  }
}
