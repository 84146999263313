import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { UnauthenticatedLoadFeedModule } from '@haulynx/components';
import { LoadFeedActionsService, LoadGridService } from '@haulynx/services';
import { AvailableLoadsContainerRoutingModule } from './available-loads-container-routing.module';
import { AvailableLoadsContainerComponent } from './available-loads-container.component';

@NgModule({
  declarations: [AvailableLoadsContainerComponent],
  imports: [CommonModule, AvailableLoadsContainerRoutingModule, UnauthenticatedLoadFeedModule],
  providers: [LoadGridService, DecimalPipe, LoadFeedActionsService],
})
export class AvailableLoadsContainerModule {}
