import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@haulynx/modules';
import { LoadRestrictionsPipe, PipesModule } from '@haulynx/pipes';
import { BookLoadStateModule } from '@haulynx/store';
import { CountdownModule } from 'ngx-countdown';
import { SpinnerModule } from '../spinner/spinner.module';
import { TooltipMenuModule } from '../tooltip-menu/tooltip-menu.module';
import { BookLoadButtonComponent } from './components/book-load-button';
import { ConfirmBookingComponent } from './components/confirm-booking';
import { HazmatWarningComponent } from './components/hazmat-warning';

@NgModule({
  declarations: [BookLoadButtonComponent, ConfirmBookingComponent, HazmatWarningComponent],
  exports: [BookLoadButtonComponent, ConfirmBookingComponent, HazmatWarningComponent],
  imports: [
    CommonModule,
    BookLoadStateModule,
    MaterialModule,
    TooltipMenuModule,
    SpinnerModule,
    CountdownModule,
    PipesModule,
  ],
  providers: [LoadRestrictionsPipe],
})
export class BookLoadModule {}
