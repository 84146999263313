import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTableCalendarComponent } from './data-table-calendar.component';
import { PipesModule } from '@haulynx/pipes';
import { MaterialModule } from '@haulynx/modules';
import { DirectivesModule } from '@haulynx/directives';

@NgModule({
  declarations: [DataTableCalendarComponent],
  imports: [CommonModule, PipesModule, MaterialModule, DirectivesModule],
  exports: [DataTableCalendarComponent],
})
export class DataTableCalendarModule {}
