<div class="load-active-accept">
  <h1 mat-dialog-title *ngIf="!data.isQuickDecline">
    {{ title }}
  </h1>
  <div mat-dialog-content>
    <div *ngIf="data.load">
      <app-load-single-item *ngIf="showItemInfo; else contactBroker" [load]="data.load" label="Price:">
      </app-load-single-item>
    </div>
    <ng-template #contactBroker>
      <button *ngIf="data.isQuickDecline" [matDialogClose]="null" class="close-button">
        <mat-icon svgIcon="haulynx-close" class="close-icon"></mat-icon>
      </button>
      <h1 mat-dialog-title>{{ declineRejectTitle }} Reason</h1>
      <mat-checkbox [(ngModel)]="capacityCheck" class="checkbox"> No available capacity</mat-checkbox>
      <br />
      <mat-checkbox [(ngModel)]="laneCheck" class="checkbox"> Undesirable lane</mat-checkbox>
      <br />
      <mat-checkbox [(ngModel)]="priceCheck" class="checkbox"> Bad price</mat-checkbox>
      <br />
      <mat-checkbox [(ngModel)]="otherCheck" class="other-checkbox"></mat-checkbox>
      <span>
        <mat-form-field [formGroup]="reasonForm" class="app-form-field" appearance="fill" floatLabel="always">
          <input matInput formControlName="other" placeholder="Other Reason" autocomplete="off"
        /></mat-form-field>
      </span>
    </ng-template>
  </div>
  <div mat-dialog-actions>
    <action-buttons [buttons]="buttons$ | async" (onClick)="action($event)"></action-buttons>
  </div>
</div>
