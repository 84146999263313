import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'interchange-agreement-info',
  templateUrl: './interchange-agreement-info.component.html',
  styleUrls: ['./interchange-agreement-info.component.scss'],
})
export class InterchangeAgreementInfoComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
