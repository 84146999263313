import { AgmCoreModule } from '@agm/core';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OwlDateTimeModule, OwlMomentDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { DirectivesModule } from '@haulynx/directives';
import {
  LoadActiveTabGuard,
  LoadActiveTabSelectedGuard,
  LoadAvailableTabGuard,
  LoadAvailableTabSelectedGuard,
  LoadTabGuard,
  LoadTabSelectedGuard,
  VerifyCanEditLoadGuard,
  VerifyCanViewLoadDetailsGuard,
} from '@haulynx/guards';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { LoadActiveDeliveredVm, LoadFormService, LoadGridService, LoadVm, StepStatusService } from '@haulynx/services';
import {
  LoadActiveModel,
  LoadActiveTabsModel,
  LoadDetailsStateModule,
  LoadEntitiesStateModule,
  LoadModel,
  LoadOffersModel,
  LoadStoreModule,
} from '@haulynx/store';
import { DragScrollModule } from 'ngx-drag-scroll';
import { SharedModule } from 'primeng/api';
import { ActionButtonModule } from '../action-button/action-button.module';
import { ActionButtonsModule } from '../actions-buttons/action-buttons.module';
import { AppMenuModule } from '../app-menu/app-menu.module';
import { BookLoadModule } from '../book-load/book-load.module';
import { CustomerDetailsModule } from '../customer-details/customer-details.module';
import { DataTableModule } from '../data-table/data-table.module';
import { DatepickerModule } from '../datepicker/datepicker.module';
import { DatetimePickerModule } from '../datetime-picker/datetime-picker.module';
import { DropDownModule } from '../drop-down/drop-down.module';
import { ExpandButtonModule } from '../expand-button/expand-button.module';
import { FavoriteAddressModule } from '../favorite-address/favorite-address.module';
import { GoogleAddressFieldModule } from '../google-address-field/google-address-field.module';
import { LoadMainInfo } from '../load-main-info/load-main-info.module';
import { LoadPriceModule } from '../load-price/load-price.module';
import { MapsModule } from '../maps/maps.module';
import { ProgressBarModule } from '../progress-bar/progress-bar.module';
import { SelectorModule } from '../selector/selector.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { TabsModule } from '../tabs/tabs.module';
import { LoadActiveAcceptComponent } from './components/load-active-accept/load-active-accept.component';
import { LoadActiveAssignComponent } from './components/load-active-assign/load-active-assign.component';
import { LoadActiveContactComponent } from './components/load-active-contact/load-active-contact.component';
import { LoadActiveDeliveredComponent } from './components/load-active-delivered/load-active-delivered.component';
import { LoadActiveNoteComponent } from './components/load-active-note/load-active-note.component';
import { LoadActivePickUpComponent } from './components/load-active-pick-up/load-active-pick-up.component';
import { LoadActiveStatusComponent } from './components/load-active-status/load-active-status.component';
import { LoadChooseComponent } from './components/load-choose/load-choose.component';
import { LoadShareLocationWarningComponent } from './components/load-share-location-warning/load-share-location-warning.component';
import { LoadSingleItemComponent } from './components/load-single-item/load-single-item.component';
import { TimestampInputComponent } from './components/timestamp-input/timestamp-input.component';

@NgModule({
  declarations: [
    TimestampInputComponent,
    LoadChooseComponent,
    LoadActiveStatusComponent,
    LoadActiveContactComponent,
    LoadSingleItemComponent,
    LoadActiveAssignComponent,
    LoadActiveAcceptComponent,
    LoadActivePickUpComponent,
    LoadActiveDeliveredComponent,
    LoadShareLocationWarningComponent,
    LoadActiveNoteComponent,
  ],
  imports: [
    CommonModule,
    DropDownModule,
    PipesModule,
    DirectivesModule,
    DataTableModule,
    SharedModule,
    AgmJsMarkerClustererModule,
    FormsModule,
    TabsModule,
    AgmCoreModule,
    MapsModule,
    DragScrollModule,
    SpinnerModule,
    ActionButtonsModule,
    ProgressBarModule,
    SelectorModule,
    ExpandButtonModule,
    AppMenuModule,
    GoogleAddressFieldModule,
    DatepickerModule,
    FavoriteAddressModule,
    LoadPriceModule,
    LoadMainInfo,
    ActionButtonModule,
    OwlDateTimeModule,
    OwlMomentDateTimeModule,
    LoadDetailsStateModule,
    BookLoadModule,
    LoadEntitiesStateModule,
    DatetimePickerModule,
    CustomerDetailsModule,
    MaterialModule,
    LoadStoreModule,
    ReactiveFormsModule,
  ],
  providers: [
    LoadModel,
    LoadOffersModel,
    LoadVm,
    LoadActiveDeliveredVm,
    LoadTabGuard,
    LoadActiveTabsModel,
    LoadActiveTabSelectedGuard,
    LoadActiveTabGuard,
    LoadTabSelectedGuard,
    LoadAvailableTabSelectedGuard,
    LoadAvailableTabGuard,
    LoadFormService,
    StepStatusService,
    LoadActiveModel,
    LoadGridService,
    VerifyCanEditLoadGuard,
    VerifyCanViewLoadDetailsGuard,
  ],
  exports: [
    LoadActiveAcceptComponent,
    LoadActiveAssignComponent,
    LoadActiveContactComponent,
    LoadActiveDeliveredComponent,
    LoadActiveNoteComponent,
    LoadActivePickUpComponent,
    LoadActiveStatusComponent,
    LoadChooseComponent,
    LoadShareLocationWarningComponent,
    LoadSingleItemComponent,
    TimestampInputComponent,
  ],
})
export class LoadComponentsModule {}
