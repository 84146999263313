import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ChartService } from '@haulynx/services';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { ChartConfig, ChartOption, ChartSettingOption } from '@haulynx/types';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';

@Component({
  selector: 'haulynx-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('canvas', { static: false }) canvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('insideElement') insideElement: ElementRef;

  @Input() loading = false;
  @Input() scalesFunction: Function;
  @Input() chartOptions: ChartOptions = this.chartService.baseChartOptions();
  @Input() chartConfig: ChartConfig;
  @Input() chartData: any;
  @Input() chartType: ChartType = 'scatter';

  currentChartSelection: string;
  isSettingsOpen = false;
  currentDisplayData: ChartDataSets[] = [];
  graphSettingsForm: FormGroup;
  canCheckOffClick = false;

  constructor(private chartService: ChartService, private fb: FormBuilder) {
    this.graphSettingsForm = this.buildGraphSettingsForm();
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {
    const clickedInside = this.insideElement ? this.insideElement.nativeElement.contains(targetElement) : false;
    if (!clickedInside && this.canCheckOffClick) {
      this.isSettingsOpen = false;
      this.canCheckOffClick = false;
    }
  }

  ngOnInit(): void {
    this.currentChartSelection = this.chartConfig.chartOptions[0].chartSubTitle;
    this.setCurrentDisplayData();
  }

  ngAfterViewInit(): void {
    if (this.chartData) {
      this.chartService.setCanvasElement(this.canvas.nativeElement);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.chartData && this.chartData) {
      this.setCurrentDisplayData();
      setTimeout(() => {
        this.chartService.setCanvasElement(this.canvas.nativeElement);
      });
    }
  }

  openSettingsDropdown = () => {
    this.isSettingsOpen = !this.isSettingsOpen;
    setTimeout(() => {
      this.canCheckOffClick = true;
    });
  };

  graphSettingsActionEvent = (event: string) => {
    if (event === 'cancel') {
      this.openSettingsDropdown();
    } else {
      this.setCurrentDisplayData();
      this.openSettingsDropdown();
    }
  };

  setCurrentChart = (option: ChartOption): void => {
    this.currentChartSelection = option.chartSubTitle;
    this.graphSettingsForm = this.buildGraphSettingsForm();
    this.setCurrentDisplayData();
  };

  setCurrentDisplayData = (): void => {
    if (this.chartData) {
      const getDataValue = (data: any, path: string[]): any => {
        if (path.length === 1) {
          return data[path[0]];
        } else {
          const value: string = path.shift() as string;
          return getDataValue(data[value], path);
        }
      };
      this.currentDisplayData = this.currentChartSettingsOptions
        .map((value: ChartSettingOption) => {
          if (value.display) {
            const dataPathSplit = value.dataPath.split('.');
            return getDataValue(this.chartData, dataPathSplit);
          }
          return null;
        })
        .filter((value) => value !== null);

      this.chartOptions = this.chartService.updateSuggestedMax(
        this.scalesFunction,
        this.chartService.getSuggestedValues(this.currentDisplayData)
      );
    }
  };

  buildGraphSettingsForm = (): FormGroup => {
    const formGroup: FormGroup = this.fb.group({});
    this.currentChartSettingsOptions.forEach((option: ChartSettingOption) => {
      formGroup.addControl(option.chartSettingTitle.split(' ').join(''), new FormControl(option.display));
    });
    return formGroup;
  };

  changeOptionDisplay = (option: ChartSettingOption): void => {
    option.display = !option.display;
    this.setCurrentDisplayData();
  };

  get currentChartSettingsOptions(): ChartSettingOption[] {
    return (
      this.chartConfig?.chartOptions.find(
        (chartOption: ChartOption) => chartOption.chartSubTitle === this.currentChartSelection
      ).chartSettingsOptions || []
    );
  }
}
