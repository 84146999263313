import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  LoadCompleteDialog,
  LoadWaitingForTruckDialog,
  MapsModule,
  SharedLocationDetailsModule,
  SpinnerModule,
} from '@haulynx/components';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { SharedLocationStoreModule } from '@haulynx/store';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { SharedLocationRoutingModule } from './shared-location-routing.module';
import { SharedLocationContainer } from './shared-location.component';
import { environment } from '../../../../../apps/web/src/environments/environment';

@NgModule({
  declarations: [SharedLocationContainer, LoadCompleteDialog, LoadWaitingForTruckDialog],
  imports: [
    CommonModule,
    SharedLocationStoreModule,
    SharedLocationRoutingModule,
    // TODO: Change this into a factory that will get the token from environment
    NgxMapboxGLModule.withConfig({
      accessToken: environment.mapbox.accessToken,
    }),
    SpinnerModule,
    MapsModule,
    PipesModule,
    SharedLocationDetailsModule,
    MaterialModule,
  ],
  exports: [SharedLocationContainer],
})
export class SharedLocationModule {}
