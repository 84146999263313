import { ISearchFilter, TextFilterType } from '../search-filter';
import { ISearchFilterType } from '../search-filter.types';
export class StatusFilter implements ISearchFilter {
  name = 'Status';
  type: ISearchFilterType = ISearchFilterType.TEXT;
  keys: TextFilterType;
  description = 'Filter loads by the current load status';

  constructor(defaultValues?: TextFilterType) {
    this.keys = new TextFilterType({
      value: '',
      optionsDataIndex: 'status',
      textFormName: 'loadStatus',
      ...defaultValues,
    });
  }
}
