import gql from 'graphql-tag';

export const GetDevicesByCarrierIdLittleInfo = gql(
  `
  query GetDevicesByCarrierId($carrierId: ID!) {
    getDevicesByCarrierId(carrierId: $carrierId) {
      id
      imei
      unitId
      type
    }
  }
`
);
