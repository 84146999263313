<div class="shared-container" *ngIf="load">
  <div class="shared-info-block">
    <div class="shared-info">
      <div class="shared-details-wrapper">
        <div class="shared-details-block">
          <div class="shared-detail-item">
            Date Created
            <br />
            <span class="content">
              {{ load.created | date: 'MM/dd/yyyy' || 'N/A' }}
            </span>
          </div>
          <div class="shared-detail-item">
            Load Details
            <br />
            <span class="content">
              {{ billOfLading || 'N/A' }}
            </span>
          </div>
          <div class="shared-detail-item">
            Equipment
            <br />
            <span class="content">
              {{ equipmentType || 'N/A' }}
            </span>
          </div>
          <div class="shared-detail-item">
            Driver Details
            <br />
            <span class="content">
              {{ load.drivers | get: '[0].name':'' || 'N/A' }}
              <ng-container *ngIf="unitDisplayName">
                <br />
                {{ location?.type === 'phone' ? 'Phone' : 'Truck' }}:
                {{ unitDisplayName }}
              </ng-container>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="shared-locations">
      <ng-container *ngIf="showLoadOnMap">
        <div class="shared-last-location">
          <mat-icon class="haulynx-geo" svgIcon="haulynx-geo"> </mat-icon>
          <div class="geo-wrapper">
            <span class="geo-title"> Current location: </span>
            {{ (sharedLocationModel.sharedLocation$ | async)?.approximateAddress || 'N/A' }}
          </div>
        </div>
        <div *ngIf="lastLocationDate" class="last-location-date">(as of {{ lastLocationDate }})</div>
        <mat-divider></mat-divider>
      </ng-container>

      <div class="shared-locations-wrapper">
        <app-shared-location-timeline class="shared-timeline" [load]="load"> </app-shared-location-timeline>
      </div>
    </div>
  </div>

  <div class="shared-map">
    <app-map-routes
      [padding]="30"
      [showTruckRoute]="true"
      [exposeTruck]="true"
      [sources]="loadFeedRoute"
      [dark]="true"
    ></app-map-routes>
  </div>
</div>
