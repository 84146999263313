import { Pipe, PipeTransform } from '@angular/core';
import { MilestoneSubtype } from '@haulynx/types';

@Pipe({ name: 'transformMilestoneSubType' })
export class TransformMilestoneSubType implements PipeTransform {
  transform(status: string): string {
    switch (status) {
      case MilestoneSubtype.OSD: {
        return 'OS&D';
      }
      case MilestoneSubtype.TONU: {
        return 'TONU/LAYOVER';
      }
      case MilestoneSubtype.SERVICE_FAILURE: {
        return 'SERVICE FAILURE';
      }
      case MilestoneSubtype.LOCATION: {
        return 'GENERAL LOCATION UPDATE';
      }
      default: {
        return status.toUpperCase();
      }
    }
  }
}
