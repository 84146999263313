import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AnalyticsService } from '@haulynx/services';
import { ANALYTICS_EVENT, Tab } from '@haulynx/types';
import { aliveWhile, mapToArray } from '@haulynx/utils';
import { filter, findIndex } from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsComponent implements OnChanges, OnDestroy {
  @Input() tabs = [];
  @Input() selected: Tab;
  @Output() onSelect = new EventEmitter<{ index: number; tab: Tab }>();
  @Output() onRemove = new EventEmitter<Tab>();
  @Output() close = new EventEmitter<string>();
  @Input() hideInkBar = false;

  selectedIndex = 0;
  alive = aliveWhile();

  tabsStream$ = new Subject();
  hide$ = new BehaviorSubject([]);
  tabsRemoveStream$ = new Subject();
  tabs$ = new BehaviorSubject<Tab[]>([]);

  constructor(private analytics: AnalyticsService) {
    this.tabsStream$.pipe(takeUntil(this.alive), debounceTime(500)).subscribe(({ index, tab }) => {
      this.selectedIndex = index;
      this.onSelect.emit({ index, tab });
    });

    this.tabsRemoveStream$.pipe(takeUntil(this.alive), debounceTime(500)).subscribe((tab: Tab) => {
      this.onRemove.emit(tab);
    });
  }

  selectTab({ index, tab }: { index: number; tab: Tab }): void {
    this.tabsStream$.next({ index, tab });
  }

  removeTab(tab: Tab, event: MouseEvent): void {
    event.stopPropagation();
    this.tabsRemoveStream$.next(tab);
  }

  trackByFn(index: number, tab: Tab): string {
    return tab?.id || '';
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { tabs, selected } = changes;

    if (tabs) {
      const orderedTabs = mapToArray<Tab>(tabs.currentValue);
      if (orderedTabs[0]?.label === 'Active') {
        this.analytics.logEvent(ANALYTICS_EVENT.VIEW_ACTIVE_LOADS);
      } else if (orderedTabs[0]?.label === 'Completed') {
        this.analytics.logEvent(ANALYTICS_EVENT.VIEW_COMPLETED_LOADS);
      } else if (orderedTabs[0]?.label === 'Available') {
        this.analytics.logEvent(ANALYTICS_EVENT.VIEW_LOAD_FEED);
      }
      this.hide$.next(filter(orderedTabs, (item) => !item?.hideLabel));
      this.tabs$.next(orderedTabs);
    }

    if (selected && selected.currentValue) {
      const selectedIndex = findIndex(this.tabs$.value, { id: selected.currentValue.id });

      if (selectedIndex !== this.selectedIndex) {
        this.selectedIndex = selectedIndex;
        if (this.tabs$.value[selectedIndex]?.label === 'All Active') {
          this.analytics.logEvent(ANALYTICS_EVENT.VIEW_ACTIVE_LOADS);
        } else if (this.tabs$.value[selectedIndex]?.label === 'All Available') {
          this.analytics.logEvent(ANALYTICS_EVENT.VIEW_LOAD_FEED);
        }
      }
    }

    if (this.selected) {
      const selectedIndex = findIndex(this.tabs$.value, { id: this.selected.id });

      if (selectedIndex !== this.selectedIndex) {
        this.selectedIndex = selectedIndex;
      }
    }
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }
}
