import { LoadStatus } from './load';

export interface ActiveLoadFilter {
  forceSearch?: boolean;
  region?: string;
  broker?: string;
  status?: LoadStatus;
  carrier?: string;
  orderId?: string;
  customer?: string;
  pickupDate?: number;
}
