import { Pipe, PipeTransform } from '@angular/core';
import { LoadsServiceLoadLocation } from '@haulynx/types';
import { isSameDay } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';

@Pipe({
  name: 'loadServiceLocationTime',
})
export class LoadsServiceLocationTimePipe implements PipeTransform {
  transform(location: LoadsServiceLoadLocation, options?: { timeFormat?: string; showLabel?: boolean }): string {
    if (!location) return null;
    const { timeFormat = 'd MMM yyyy HH:mm zz', showLabel = false } = options;
    const timeZone = location.timezone;

    if (location.carrierDeparture) {
      return `${showLabel ? 'Completed: ' : ''}${format(
        utcToZonedTime(location.carrierDeparture, timeZone),
        timeFormat,
        { timeZone }
      )}`;
    } else if (location.carrierArrival) {
      return `${showLabel ? 'ETA: ' : ''}${format(utcToZonedTime(location.carrierArrival, timeZone), timeFormat, {
        timeZone,
      })}`;
    }

    const timeStamp = Number(location.appointmentStart);
    const estimatedWaitTime = Number(location.appointmentEnd);

    return this.waitTime(timeStamp, timeZone, options, timeFormat, estimatedWaitTime);
  }

  private waitTime(timeStamp, timeZone, options, timeFormat, estimatedWaitTime) {
    let timePattern = '';
    const newFormat = options.timeFormat;

    if (newFormat) {
      if (newFormat.includes('H')) {
        timePattern = newFormat.substr(newFormat.indexOf('H'), newFormat.length);
      }

      if (newFormat.includes('h')) {
        timePattern = newFormat.substr(newFormat.indexOf('h'), newFormat.length);
      }
    }

    if (estimatedWaitTime && estimatedWaitTime > timeStamp) {
      if (
        timeFormat.includes('M') &&
        timeFormat.includes('d') &&
        timeFormat.includes('y') &&
        !isSameDay(timeStamp, estimatedWaitTime)
      ) {
        const start = format(utcToZonedTime(timeStamp, timeZone), timeFormat, { timeZone });
        const end = format(utcToZonedTime(estimatedWaitTime, timeZone), timeFormat, { timeZone });
        return `${start} - ${end}`;
      }

      if (timeFormat.includes('H') || timeFormat.includes('h')) {
        const start = format(utcToZonedTime(timeStamp, timeZone), timeFormat.replace('z', ''), { timeZone });
        const end = isSameDay(timeStamp, estimatedWaitTime)
          ? format(utcToZonedTime(estimatedWaitTime, timeZone), timePattern || 'HH:mm', { timeZone })
          : format(utcToZonedTime(estimatedWaitTime, timeZone), timeFormat, { timeZone });

        return `${start} - ${end}`;
      }
    }

    if (estimatedWaitTime && estimatedWaitTime === timeStamp) {
      if (timeFormat.includes('H') || timeFormat.includes('h')) {
        const start = format(utcToZonedTime(timeStamp, timeZone), timeFormat.replace('z', ''), { timeZone });
        return `${start}`;
      }
    }

    return format(utcToZonedTime(timeStamp, timeZone), timeFormat, { timeZone });
  }
}
