import { Pipe, PipeTransform } from '@angular/core';
import {
  ActionStringObject,
  BookStatus,
  CrudDeltaPrimaryActions,
  LoadHistory,
  LoadHistoryActionObject,
  LoadHistoryActionType,
  LoadHistoryDelta,
  LoadHistoryFields,
  LoadsServiceLoad,
  LocationPrimaryFields,
  PaymentDetailPrimaryFields,
  ProviderDetailPrimaryFields,
  RestrictionValues,
} from '@haulynx/types';
import {
  createActionString,
  existDeltas,
  getChangedFieldArrayAddOrRemove,
  getChangedFieldArrayModify,
  getChangedFieldObject,
  getOldNewDeltaFields,
  getSecondaryText,
} from '@haulynx/utils';
import { filter, forEach, pickBy, startCase } from 'lodash';

@Pipe({ name: 'loadHistoryAction' })
export class LoadHistoryActionPipe implements PipeTransform {
  transform(loadHistoryData: LoadHistory, isHtml: boolean): string {
    const loadHistoryField: Partial<LoadHistoryDelta> = pickBy(loadHistoryData.loadDelta, (val) => !!val);
    const fields = Object.keys(loadHistoryField);
    if (loadHistoryData.activityType === 'LOAD_CREATED') return 'Created order';
    if (!fields.length) return;

    const fieldHtmlStrings = [];
    forEach(fields, (field) => {
      const fieldHtmlString = this.getLoadHistoryString(loadHistoryField, loadHistoryData.newLoad, field);
      if (fieldHtmlString) {
        forEach(fieldHtmlString, (descriptionString: ActionStringObject) => {
          if (descriptionString) {
            fieldHtmlStrings.push(createActionString(descriptionString, isHtml));
          }
        });
      }
    });
    return isHtml ? fieldHtmlStrings.join(' ') : fieldHtmlStrings.join('\n');
  }

  getLoadHistoryString(loadHistoryField: Partial<LoadHistoryDelta>, newLoad: LoadsServiceLoad, field: string) {
    // Computes the string data for a single field changed in the row delta ex: paymentDetails
    const mainPrimaryDescriptions = [];
    const mainSecondaryDescriptions = [];

    switch (field) {
      case LoadHistoryFields.DRIVERS:
      case LoadHistoryFields.ASSIGNED_BROKERS:
      case LoadHistoryFields.TRAILERS: {
        Object.keys(loadHistoryField[field]).forEach((action: 'addedValues' | 'removedValues' | 'modifiedValues') => {
          if (!loadHistoryField[field][action].length) {
            return;
          }
          switch (action) {
            case 'addedValues':
            case 'removedValues': {
              const [primaryDescription, secondaryDescription] = getChangedFieldArrayAddOrRemove(
                field,
                loadHistoryField[field][action] as Record<string, unknown>[],
                CrudDeltaPrimaryActions[action]
              );
              mainPrimaryDescriptions.push(primaryDescription);
              mainSecondaryDescriptions.push(secondaryDescription);
              break;
            }
            case 'modifiedValues': {
              const [primaryDescription, secondaryDescription] = getChangedFieldArrayModify(
                field,
                loadHistoryField[field][action] as Record<string, unknown>[]
              );
              mainPrimaryDescriptions.push(primaryDescription);
              mainSecondaryDescriptions.push(secondaryDescription);
              break;
            }
            default: {
              console.log('error', field);
              break;
            }
          }
        });
        break;
      }
      case LoadHistoryFields.LOCATIONS: {
        Object.keys(loadHistoryField[field]).forEach((action: 'addedValues' | 'removedValues' | 'modifiedValues') => {
          if (!loadHistoryField[field][action].length) {
            return;
          }
          if (action === 'removedValues' || action === 'addedValues') {
            const delta = [...loadHistoryField[field][action]];
            const locationID = [];
            delta.forEach((location, i) => {
              const { id, ...filteredLocation } = delta[i];
              locationID.push(location.id);
              delta[i] = filteredLocation;
            });

            delta.forEach((location, i) => {
              Object.keys(location).forEach((key) => {
                if (LocationPrimaryFields[key] && location.hasOwnProperty(key)) {
                  if (!existDeltas(LocationPrimaryFields[key]).length) return;

                  let primaryAction = CrudDeltaPrimaryActions[action];
                  if (key === LocationPrimaryFields.appointmentSet) {
                    primaryAction = LoadHistoryActionType.SET as any;
                  }
                  const loadLocation = filter(newLoad.locations, function (obj) {
                    return obj.id == locationID[i];
                  });
                  const fieldObject = { [key]: location[key] };
                  let locationText = '';
                  if (key === LocationPrimaryFields.appointmentEnd || key === LocationPrimaryFields.appointmentStart) {
                    locationText = loadLocation[0]
                      ? `${startCase(loadLocation[0]?.locationType || 'None')}: ${loadLocation[0]?.city || 'None'}, ${
                          loadLocation[0]?.state || 'None'
                        }; `
                      : '';
                    const timezone = loadLocation[0] ? loadLocation[0]?.timezone : null;

                    if (location[LocationPrimaryFields.appointmentEnd]) {
                      fieldObject[LocationPrimaryFields.appointmentEnd] =
                        location[LocationPrimaryFields.appointmentEnd];
                      if (
                        location[LocationPrimaryFields.appointmentEnd] ===
                        location[LocationPrimaryFields.appointmentStart]
                      ) {
                        delete fieldObject[LocationPrimaryFields.appointmentEnd];
                      }
                      delete delta[i][LocationPrimaryFields.appointmentEnd];
                    }
                    if (location[LocationPrimaryFields.appointmentStart]) {
                      fieldObject[LocationPrimaryFields.appointmentStart] =
                        location[LocationPrimaryFields.appointmentStart];
                      delete delta[i][LocationPrimaryFields.appointmentStart];
                    }

                    const secondaryTextArray = getSecondaryText(fieldObject, { timezone });
                    secondaryTextArray[0] = locationText + secondaryTextArray[0];

                    mainPrimaryDescriptions.push(`${primaryAction} ${LoadHistoryActionObject[key]}`);
                    mainSecondaryDescriptions.push([
                      {
                        secondaryText: secondaryTextArray,
                        ul: 'string-ul',
                        li: 'string-li-inline',
                      },
                    ]);
                  } else {
                    if (key === LocationPrimaryFields.appointmentSet) {
                      locationText = loadLocation[0]
                        ? `${startCase(loadLocation[0]?.locationType || 'None')}: ${loadLocation[0]?.city || 'None'}, ${
                            loadLocation[0]?.state || 'None'
                          }; `
                        : '';
                    }

                    const secondaryTextArray = getSecondaryText(fieldObject);
                    secondaryTextArray[0] = locationText + secondaryTextArray[0];

                    mainPrimaryDescriptions.push(`${primaryAction} ${LoadHistoryActionObject[key]}`);
                    mainSecondaryDescriptions.push([
                      {
                        secondaryText: secondaryTextArray,
                        ul: 'string-ul',
                        li: 'string-li-inline',
                      },
                    ]);
                    delete delta[i][key];
                  }
                }
              });
            });
            if (delta.filter((obj: Record<string, unknown>) => !!existDeltas(obj).length).length) {
              const [primaryDescription, secondaryDescription] = getChangedFieldArrayAddOrRemove(
                field,
                delta as Record<string, unknown>[],
                CrudDeltaPrimaryActions[action]
              );
              mainPrimaryDescriptions.push(primaryDescription);
              mainSecondaryDescriptions.push(secondaryDescription);
            }
          } else if (action === 'modifiedValues') {
            const delta = [...loadHistoryField[field][action]];
            const locationID = [];
            delta.forEach((location, i) => {
              const { id, ...filteredLocation } = delta[i];
              locationID.push(location.id);
              delta[i] = filteredLocation;
            });

            delta.forEach((location, i) => {
              Object.keys(location).forEach((key) => {
                if (LocationPrimaryFields[key]) {
                  if (!location[key]?.newValue && !location[key]?.oldValue) return;
                  const loadLocation = filter(newLoad.locations, function (obj) {
                    return obj.id == locationID[i];
                  });
                  let primaryAction = LoadHistoryActionType.CHANGED;
                  if (key === LocationPrimaryFields.appointmentSet) {
                    primaryAction = LoadHistoryActionType.SET;
                  }

                  const fieldObject = { [key]: location[key] };
                  let locationText = '';
                  if (key === LocationPrimaryFields.appointmentEnd || key === LocationPrimaryFields.appointmentStart) {
                    locationText = loadLocation[0]
                      ? `${startCase(loadLocation[0]?.locationType || 'None')}: ${loadLocation[0]?.city || 'None'}, ${
                          loadLocation[0]?.state || 'None'
                        }; `
                      : '';
                    const timezone = loadLocation[0] ? loadLocation[0]?.timezone : null;

                    if (location[LocationPrimaryFields.appointmentEnd]) {
                      fieldObject[LocationPrimaryFields.appointmentEnd] =
                        location[LocationPrimaryFields.appointmentEnd];
                      if (
                        location[LocationPrimaryFields.appointmentEnd]?.oldValue ===
                          location[LocationPrimaryFields.appointmentStart]?.oldValue ||
                        location[LocationPrimaryFields.appointmentEnd]?.newValue ===
                          location[LocationPrimaryFields.appointmentStart]?.newValue
                      ) {
                        delete fieldObject[LocationPrimaryFields.appointmentEnd];
                      }
                      delete delta[i][LocationPrimaryFields.appointmentEnd];
                    }
                    if (location[LocationPrimaryFields.appointmentStart]) {
                      fieldObject[LocationPrimaryFields.appointmentStart] =
                        location[LocationPrimaryFields.appointmentStart];
                      delete delta[i][LocationPrimaryFields.appointmentStart];
                    }

                    const secondaryTextArray = getSecondaryText(getChangedFieldObject(fieldObject), { timezone });
                    secondaryTextArray[0] = locationText + secondaryTextArray[0];

                    mainPrimaryDescriptions.push(`${primaryAction} ${LoadHistoryActionObject[key]}`);
                    mainSecondaryDescriptions.push([
                      {
                        secondaryText: secondaryTextArray,
                        ul: 'string-ul',
                        li: 'string-li-inline',
                      },
                    ]);
                  } else {
                    if (key === LocationPrimaryFields.appointmentSet) {
                      locationText = loadLocation[0]
                        ? `${startCase(loadLocation[0]?.locationType || 'None')}: ${loadLocation[0]?.city || 'None'}, ${
                            loadLocation[0]?.state || 'None'
                          }; `
                        : '';
                    }

                    const secondaryTextArray = getSecondaryText(getChangedFieldObject(fieldObject));
                    secondaryTextArray[0] = locationText + secondaryTextArray[0];

                    mainPrimaryDescriptions.push(`${primaryAction} ${LoadHistoryActionObject[key]}`);
                    mainSecondaryDescriptions.push([
                      {
                        secondaryText: secondaryTextArray,
                        ul: 'string-ul',
                        li: 'string-li-inline',
                      },
                    ]);

                    delete delta[i][key];
                  }
                }
              });
            });
            if (delta.filter((object) => !!existDeltas(object).length).length) {
              const [primaryDescription, secondaryDescription] = getChangedFieldArrayModify(field, delta);
              mainPrimaryDescriptions.push(primaryDescription);
              mainSecondaryDescriptions.push(secondaryDescription);
            }
          }
        });
        break;
      }
      case LoadHistoryFields.RESTRICTIONS: {
        Object.keys(loadHistoryField[field]).forEach((action: 'addedValues' | 'removedValues' | 'modifiedValues') => {
          if (!loadHistoryField[field][action].length) {
            return;
          }
          if (action === 'addedValues') {
            const delta = [...loadHistoryField[field][action]];

            delta.forEach((restriction, i) => {
              const newValue = RestrictionValues[restriction.value]
                ? RestrictionValues[restriction.value]
                : restriction.value;
              const oldValue =
                newValue === RestrictionValues.true
                  ? RestrictionValues.false
                  : newValue === RestrictionValues.false
                  ? RestrictionValues.true
                  : 'None';

              const secondaryString = getSecondaryText(
                getChangedFieldObject({
                  value: {
                    oldValue: oldValue,
                    newValue: newValue,
                  },
                })
              );
              mainPrimaryDescriptions.push(
                `${LoadHistoryActionType.CHANGED} ${
                  LoadHistoryActionObject[restriction.type] ?? startCase(restriction.type)
                }`
              );
              mainSecondaryDescriptions.push([{ secondaryText: secondaryString }]);
              delete delta[i];
            });
          } else if (action === 'removedValues') {
            const delta = [...loadHistoryField[field][action]];
            delta.forEach((restriction, i) => {
              const oldValue = RestrictionValues[restriction.value] ? RestrictionValues[restriction.value] : '';
              const newValue = oldValue === RestrictionValues.true ? RestrictionValues.false : RestrictionValues.true;
              const secondaryString = getSecondaryText(
                getChangedFieldObject({
                  value: {
                    oldValue: oldValue,
                    newValue: newValue,
                  },
                })
              );
              mainPrimaryDescriptions.push(
                `${LoadHistoryActionType.CHANGED} ${
                  LoadHistoryActionObject[restriction.type] ?? startCase(restriction.type)
                }`
              );
              mainSecondaryDescriptions.push([{ secondaryText: secondaryString }]);
              delete delta[i];
            });
          } else if (action === 'modifiedValues') {
            const delta = [...loadHistoryField[field][action]].filter((object) => !!existDeltas(object).length);

            delta.forEach((restriction, i) => {
              const secondaryString = getSecondaryText(
                getChangedFieldObject({
                  value: {
                    oldValue: RestrictionValues[restriction.oldValue.value]
                      ? RestrictionValues[restriction.oldValue.value]
                      : restriction.oldValue.value,
                    newValue: RestrictionValues[restriction.newValue.value]
                      ? RestrictionValues[restriction.newValue.value]
                      : restriction.newValue.value,
                  },
                })
              );
              mainPrimaryDescriptions.push(
                `${LoadHistoryActionType.CHANGED} ${
                  LoadHistoryActionObject[<string>restriction.oldValue.type] ?? startCase(restriction.oldValue.type)
                }`
              );
              mainSecondaryDescriptions.push([{ secondaryText: secondaryString }]);

              delete delta[i];
            });
            if (delta.filter((obj) => !!obj).length) {
              const [primaryDescription, secondaryDescription] = getChangedFieldArrayModify(field, delta);
              mainPrimaryDescriptions.push(primaryDescription);
              mainSecondaryDescriptions.push(secondaryDescription);
            }
          }
        });
        break;
      }
      case LoadHistoryFields.RATE_CON_EMAILS: {
        Object.keys(loadHistoryField[field]).forEach((action: 'addedValues' | 'removedValues' | 'modifiedValues') => {
          if (!loadHistoryField[field][action].length) {
            return;
          }
          if (action === 'addedValues') {
            const delta = [...loadHistoryField[field][action]];
            const [primaryDescription, secondaryDescription] = getChangedFieldArrayAddOrRemove(
              field,
              delta as unknown as Record<string, unknown>[],
              LoadHistoryActionType.SENT
            );
            mainPrimaryDescriptions.push(primaryDescription);
            mainSecondaryDescriptions.push(secondaryDescription);
          } else if (action === 'removedValues') {
            const delta = [...loadHistoryField[field][action]];
            const [primaryDescription, secondaryDescription] = getChangedFieldArrayAddOrRemove(
              field,
              delta as unknown as Record<string, unknown>[],
              LoadHistoryActionType.REMOVED
            );
            mainPrimaryDescriptions.push(primaryDescription);
            mainSecondaryDescriptions.push(secondaryDescription);
          } else if (action === 'modifiedValues') {
            const delta = [...loadHistoryField[field][action]].filter((object) => !!existDeltas(object).length);
            if (delta.filter((obj) => !!obj).length) {
              const [primaryDescription, secondaryDescription] = getChangedFieldArrayModify(field, delta);
              mainPrimaryDescriptions.push(primaryDescription);
              mainSecondaryDescriptions.push(secondaryDescription);
            }
          }
        });
        break;
      }
      case LoadHistoryFields.BOOK_STATUS: {
        const changes = getOldNewDeltaFields(loadHistoryField[field]);
        let PrimaryAction = LoadHistoryActionType.CHANGED;
        if (changes.newValue === BookStatus.BOOKED) {
          PrimaryAction = LoadHistoryActionType.BOOKED;
        } else if (
          changes.oldValue === BookStatus.BOOKED &&
          (changes.newValue === BookStatus.BOOKABLE || changes.newValue === BookStatus.VIEWABLE)
        ) {
          PrimaryAction = LoadHistoryActionType.BOUNCED;
        }

        mainPrimaryDescriptions.push(`${PrimaryAction} Load`);
        mainSecondaryDescriptions.push([{ secondaryText: getSecondaryText(changes) }]);
        break;
      }
      case LoadHistoryFields.CARRIER: {
        const changes = getOldNewDeltaFields(loadHistoryField[field]);
        const primaryAction = !existDeltas(changes.oldValue).length
          ? LoadHistoryActionType.ASSIGNED
          : LoadHistoryActionType.UPDATED;
        const secondaryDescription = !existDeltas(changes.oldValue).length
          ? `None \u2192 ${changes.newValue?.name || 'None'} (DOT ${changes.newValue?.dot || 'None'})`
          : `${changes.newValue?.name || 'None'} (DOT ${changes.newValue?.dot || 'None'}) \u2192 None`;
        mainPrimaryDescriptions.push(`${primaryAction} the ${LoadHistoryActionObject[field]}`);
        mainSecondaryDescriptions.push([{ secondaryText: [secondaryDescription] }]);

        break;
      }
      // case LoadHistoryFields.DISPATCH_LOCATION:
      case LoadHistoryFields.BROKER:
      case LoadHistoryFields.DISTRIBUTION_MECHANISM:
      case LoadHistoryFields.NOTES:
      case LoadHistoryFields.TRACKING_TYPE:
      case LoadHistoryFields.REGION: {
        const t = loadHistoryField[field].oldValue;
        const changes = getOldNewDeltaFields<typeof t>(loadHistoryField[field]);
        mainPrimaryDescriptions.push(`${LoadHistoryActionType.CHANGED} ${LoadHistoryActionObject[field]}`);
        mainSecondaryDescriptions.push([{ secondaryText: getSecondaryText(changes as Record<string, unknown>) }]);
        break;
      }
      // case LoadHistoryFields.LOAD_STATUS: {
      //   const changes = getOldNewDeltaFields(loadHistoryField[field]);
      //   if (changes.oldValue) {
      //     changes.oldValue = LoadStatusTypeString[changes.oldValue];
      //   }
      //   if (changes.newValue) {
      //     changes.newValue = LoadStatusTypeString[changes.newValue];
      //   }
      //   mainPrimaryDescriptions.push(`${LoadHistoryActionType.CHANGED} ${LoadHistoryActionObject[field]}`);
      //   mainSecondaryDescriptions.push([{ secondaryText: getSecondaryText(changes) }]);
      //   break;
      // }
      case LoadHistoryFields.PROVIDER:
      case LoadHistoryFields.TRACKING_STATUS: {
        const changes = getChangedFieldObject(loadHistoryField[field]);
        if (!changes.newValue && !changes.oldValue) return;

        mainPrimaryDescriptions.push(`${LoadHistoryActionType.CHANGED} ${LoadHistoryActionObject[field]}`);
        mainSecondaryDescriptions.push([{ secondaryText: getSecondaryText(changes) }]);
        break;
      }
      case LoadHistoryFields.PROVIDER_DETAILS: {
        const changes = getChangedFieldObject(loadHistoryField[field]);
        if (!changes.newValue && !changes.oldValue) return;

        Object.keys(changes.oldValue).forEach((key) => {
          if (ProviderDetailPrimaryFields[key]) {
            const primaryAction =
              key === ProviderDetailPrimaryFields.cxPriority
                ? LoadHistoryActionType.UPDATED
                : LoadHistoryActionType.CHANGED;

            const specificChanges = {
              newValue: { [key]: changes.newValue[key] },
              oldValue: { [key]: changes.oldValue[key] },
            };
            delete changes.newValue[key];
            delete changes.oldValue[key];

            mainPrimaryDescriptions.push(`${primaryAction} the ${LoadHistoryActionObject[key]}`);
            mainSecondaryDescriptions.push([{ secondaryText: getSecondaryText(specificChanges) }]);
          }
        });
        if (Object.keys(changes.oldValue).length) {
          mainPrimaryDescriptions.push(`${LoadHistoryActionType.CHANGED} the ${LoadHistoryActionObject[field]}`);
          mainSecondaryDescriptions.push([{ secondaryText: getSecondaryText(changes) }]);
        }
        break;
      }
      case LoadHistoryFields.PAYMENT_DETAILS: {
        const changes = getChangedFieldObject(loadHistoryField[field]);
        if (!changes.newValue && !changes.oldValue) return;

        Object.keys(changes.oldValue).forEach((key) => {
          if (PaymentDetailPrimaryFields[key]) {
            const specificChanges = {
              newValue: { [key]: changes.newValue[key] },
              oldValue: { [key]: changes.oldValue[key] },
            };
            delete changes.newValue[key];
            delete changes.oldValue[key];

            mainPrimaryDescriptions.push(`${LoadHistoryActionType.CHANGED} the ${LoadHistoryActionObject[key]}`);
            mainSecondaryDescriptions.push([{ secondaryText: getSecondaryText(specificChanges) }]);
          }
        });
        if (Object.keys(changes.oldValue).length) {
          mainPrimaryDescriptions.push(`${LoadHistoryActionType.CHANGED} the ${LoadHistoryActionObject[field]}`);
          mainSecondaryDescriptions.push([{ secondaryText: getSecondaryText(changes) }]);
        }
        break;
      }
      case LoadHistoryFields.TRUCK: {
        const changes = getOldNewDeltaFields(loadHistoryField[field]);
        if (!changes.newValue && !changes.oldValue) return;
        let loadHistoryActionType = LoadHistoryActionType.CHANGED;
        if (!changes?.oldValue.unitId) {
          loadHistoryActionType = LoadHistoryActionType.ADDED;
        }

        mainPrimaryDescriptions.push(`${loadHistoryActionType} ${LoadHistoryActionObject[field]}`);
        mainSecondaryDescriptions.push([{ secondaryText: getSecondaryText(changes as Record<string, unknown>) }]);
        break;
      }
      default: {
        mainPrimaryDescriptions.push(`Tracked but not handled - ${field}`);
        mainSecondaryDescriptions.push([]);
        break;
      }
    }

    return mainPrimaryDescriptions.map((mainPrimaryDescription, i) => {
      return [mainPrimaryDescription, mainSecondaryDescriptions[i]];
    });
  }
}
