import { Carrier } from '../carrier';
import { TrailerOwnerType } from './trailer-owner-type';

export class Trailer {
  id: string;
  trailerNumber: string;
  type: string;
  truckId: string;
  carrier: Carrier;
  inactive?: boolean;
  trailerOwnerType?: TrailerOwnerType;
  trailerCompany?: string;

  constructor(trailerNumber: string, type: string) {
    this.trailerNumber = trailerNumber;
    this.type = type;
  }
}

export interface TrailerDialogResult {
  deleted: boolean;
  trailer: Trailer;
}

export interface FleetTrailerSearch {
  carrierId: string;
}
export interface NewTrailer {
  carrierId: string;
  trailer: Trailer;
}

export interface NewUSXITrailerInput {
  orderNumber: string;
  carrierName: string;
  trailerNumber: string;
  loadId: string;
  customerNumber?: string;
  customerCompany?: string;
}

export interface AssetBridgeTrailerHistoryResponse {
  telemetryHistory: [AssetBridgeTrailerTelemetryHistory];
  trailer: AssetBridgeSearchTrailerData;
}

export interface TrailerInput {
  trailerCompany: string;
  trailerNumber: string;
  historyStartDateTime: string;
  historyEndDateTime: string;
}

export interface NewUSXITrailer {
  id: string;
  deviceId: string;
  licensePlateNumber: string;
  trailerDetail: NewUSXITrailerDetails;
  trailerNumber: string;
}

export interface NewUSXITrailerDetails {
  id: string;
  status: string;
  typeCode: string;
  ownership: string;
}

export interface AssignUSXITrailerInput {
  trailerNumber: string;
  loadId: string;
  customerNumber: string;
  customerCompany: string;
}

export interface SearchTrailerHistorybyTrailerInfoInput {
  trailerCompany: string;
  trailerNumber: string;
  historyStartDateTime?: string;
  historyEndDateTime?: string;
}

export interface AssetBridgeTrailerHistory {
  telemetryHistory?: AssetBridgeTrailerTelemetryHistory[];
  trailer: {
    id: string;
    trailerNumber: string;
    trailerCompany: string;
  };
}

export interface AssetBridgeTrailerTelemetryHistory {
  id?: number;
  trailerCompany?: string;
  trailerNumber?: string;
  latitude?: number;
  longitude?: number;
  telemetryPingDateTime?: string;
}

export interface AssetBridgeSearchTrailerData {
  id?: string;
  batteryStatus?: string;
  cargoStatus?: string;
  createdOn?: string;
  customer?: AssetBridgeTrailerCustomer;
  deviceId?: string;
  deviceType?: string;
  directionDegrees?: number;
  eventType?: string;
  headingDirection?: string;
  landmark?: AssetBridgeTrailerLandmark;
  location?: AssetBridgeTrailerLocation;
  licensePlateNumber?: string;
  markedAvailableForRemoval?: boolean;
  markedPlanInPlace?: boolean;
  speed?: number;
  spottedInfo?: AssetBridgeTrailerSpottedInfo;
  telemetryPingDateTime?: string;
  tractor?: AssetBridgeTrailerTractor;
  trailerCompany?: string;
  trailerDetail?: AssetBridgeTrailerDetail;
  trailerNumber?: string;
  trailerPool?: AssetBridgeTrailerPool;
  updatedOn?: string;
}

export interface AssetBridgeTrailerCustomer {
  id?: string;
  billingAddress1?: string;
  billingAddress2?: string;
  city?: string;
  name?: string;
  state?: string;
}

export interface AssetBridgeTrailerLandmark {
  id?: string;
  addressCity?: string;
  addressCountry?: string;
  addressPostal?: string;
  addressState?: string;
  addressStreet?: string;
  geographicName?: string;
}

export interface AssetBridgeTrailerLocation {
  id?: string;
  latitude?: number;
  longitude?: number;
}

export interface AssetBridgeTrailerSpottedInfo {
  id?: string;
  alpha?: string;
  customerLotLatitude?: number;
  customerLotLongitude?: number;
  customerLotNumber?: string;
  date?: string;
  state?: string;
  trailerCity?: string;
  trailerTerminalComment?: string;
}

export interface AssetBridgeTrailerTractor {
  id?: string;
  driverCompany?: string;
  driverNumber?: string;
  orderCompany?: string;
  orderNumber?: string;
  orderSequence?: string;
  orderStopNumber?: string;
  truckCompany?: string;
  truckNumber?: string;
}

export interface AssetBridgeTrailerDetail {
  id?: string;
  emptyLoadedStatus?: string;
  lengthFeet?: string;
  make?: string;
  model?: string;
  modelYear?: number;
  ownership?: string;
  serialNumber?: string;
  status?: string;
  typeCode?: string;
}

export interface AssetBridgeTrailerPool {
  id?: string;
  createdOn?: string;
  customerLotNumber?: string;
  customerCompany?: string;
  gateLatitude?: number;
  gateLongitude?: number;
  updatedOn?: string;
}
