import { Tab } from '@haulynx/types';
import { Store } from '@ngrx/store';
import { Map } from 'immutable';
import { get } from 'lodash';
import { Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { AppState } from '../../../main-store/app.reducers';
import { ITabSelectors } from './selector';

export interface ITabModelSelectors {
  tabs$: Observable<Map<string, Tab>>;
  selectedId$: Observable<string>;
  selected$: Observable<Tab>;
}

export const tabModel = (store: Store<AppState>, searchStateSelector: ITabSelectors): ITabModelSelectors => {
  const tabs$ = store.select(searchStateSelector.getTabs);
  const selectedId$ = store.select(searchStateSelector.getSelectedId);
  const selected$ = store.select(searchStateSelector.getSelected).pipe(
    distinctUntilChanged((tab, nextTab) => {
      return get(nextTab, 'id') === get(tab, 'id');
    })
  );

  return {
    tabs$,
    selectedId$,
    selected$,
  };
};
