import { InjectionToken, Provider } from '@angular/core';
import { IAsyncEntityState } from '@haulynx/types';
import { USER_PROVIDED_EFFECTS } from '@ngrx/effects';
import { ActionReducer, ActionReducerMap } from '@ngrx/store';
import { CarrierLoadSearchEntityService } from './carrier-load-search-entity.service';

export const CARRIER_LOAD_SEARCH_ENTITY_REDUCERS_TOKEN = new InjectionToken<
  ActionReducerMap<IAsyncEntityState<unknown>>
>('carrier load search reducers');

function getCarrierLoadSearchEntityReducersFactory(
  carrierLoadSearchEntityService: CarrierLoadSearchEntityService
): ActionReducer<IAsyncEntityState<unknown>> {
  return carrierLoadSearchEntityService.getReducers();
}

const carrierLoadSearchEntityReducerProvider: Provider = {
  provide: CARRIER_LOAD_SEARCH_ENTITY_REDUCERS_TOKEN,
  useFactory: getCarrierLoadSearchEntityReducersFactory,
  deps: [CarrierLoadSearchEntityService],
};

const carrierLoadSearchEntityEffectProvider: Provider = {
  provide: USER_PROVIDED_EFFECTS,
  multi: true,
  useValue: [CarrierLoadSearchEntityService],
};

export const carrierLoadSearchStateProviders: Provider[] = [
  carrierLoadSearchEntityReducerProvider,
  carrierLoadSearchEntityEffectProvider,
];
