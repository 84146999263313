<div class="row fleet-container">
  <div class="col-lg-12">
    <mat-tab-group dynamicHeight (selectedTabChange)="tabSwitched()" [(selectedIndex)]="selectedIndex">
      <mat-tab label="Trucks">
        <form class="form haulynx-form">
          <div class="row">
            <div class="col-lg-12">
              <div class="row">
                <div class="col-lg-4">
                  <drop-down
                    *ngIf="user?.isHaulynxAdmin || user?.broker?.isHaulynxBroker"
                    placeholder="Select Carrier"
                    (selectionChange)="changeCarrier($event)"
                    [templateLabel]="templateDropDown"
                    [(ngModel)]="carrierId"
                    key="id"
                    displayLabel="name"
                    name="carrier"
                    [data]="carriers"
                  >
                    <ng-template #templateDropDown let-row="row">
                      {{ row.name }} {{ row.dot ? ' (DOT:' + row.dot + ')' : '' }}
                    </ng-template>
                  </drop-down>
                </div>
                <div class="col-lg-8">
                  <div class="row">
                    <div class="col-lg-6"></div>
                    <div class="col-lg-6">
                      <action-buttons [buttons]="truckActionButtons | async" (onClick)="create($event)">
                      </action-buttons>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <data-table
                    [data]="trucks$ | async"
                    [loading]="isLoadingTruck"
                    [displayedColumns]="truckColumns"
                    [templateCellRef]="templateCellTruck"
                    [list]="true"
                    [title]="'Trucks'"
                    [cls]="'truck-table'"
                    data-test="truck-table"
                  >
                    <ng-template #templateCellTruck let-row="row" let-column="column">
                      <div *ngIf="column === 'vin'" data-test="truck-vin">
                        {{ row.vin ? row.vin : 'N/A' }}
                      </div>
                      <div *ngIf="column === 'imei'" data-test="truck-imei">
                        <autocomplete-blur
                          *ngIf="user?.isHaulynxAdmin; else notAdmin"
                          [data]="mdiDevices$ | async"
                          displayLabel="imei"
                          key="imei"
                          placeholder="Enter device IMEI"
                          [selected]="row.imei || ''"
                          (selectionChange)="updateTruckImei($event, row)"
                        >
                        </autocomplete-blur>
                        <ng-template #notAdmin>
                          {{ row.imei }}
                        </ng-template>
                      </div>
                      <div *ngIf="column === 'locationName'" data-test="truck-location">
                        {{ row.locationName }}
                      </div>
                      <div *ngIf="column === 'lastUpdated'" data-test="truck-last-updated">
                        {{ row.lastUpdated | timezoned }}
                      </div>
                      <div *ngIf="column === 'coDrivers'">
                        <div *ngFor="let coDriver of row.coDrivers">
                          {{ coDriver?.name }}
                        </div>
                      </div>
                      <mat-menu #menu="matMenu">
                        <button mat-menu-item *ngIf="user?.isHaulynxAdmin" (click)="simulatePosition(row)">
                          <span>Simulate Position</span>
                        </button>

                        <button
                          mat-menu-item
                          [disabled]="!row.lastLocation?.driverName"
                          *ngIf="!(user.carrier || user.isHaulynxAdmin)"
                          mat-menu-item
                          routerLink="{{ '../../dashboard/loads?assignTo=' + row.imei }}"
                        >
                          <span>Assign a Load to Truck</span>
                        </button>
                      </mat-menu>
                    </ng-template>
                  </data-table>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 data-table-v2" #parent [style.height]="tabHeight + 'px'">
                  <data-table-v2
                    [data]="devices$ | async"
                    [tableConfig]="configOptions"
                    [emptyMessage]="'No Devices Found'"
                    [displayedColumns]="deviceColumns"
                    [title]="'Devices'"
                    [entitiesLoading]="fleetModel.isLoading$"
                    [templateCellRef]="templateRef"
                    [loading]="isLoadingDevice"
                    [isSearchView]="true"
                    [sortColumns]="true"
                    [selectionMode]="null"
                    [stickyColumnWidth]="stickyColumnWidth"
                    [parentToTable]="parent"
                    (rowHeightsEvent)="rowHeights($event)"
                    data-test="devices-table"
                  >
                    <ng-template
                      #templateRef
                      let-row="row"
                      let-column="column"
                      let-width="columnWidth"
                      let-index="rowIndex"
                      let-count="rowCount"
                    >
                      <td [ngStyle]="{ width: width }" *ngIf="column.field === 'carrier.name'">
                        <div data-test="device-carrier">
                          {{ row?.carrier?.name || 'N/A' }}
                        </div>
                      </td>
                      <td [ngStyle]="{ width: width }" *ngIf="column.field === 'imei'">
                        <div data-test="device-imei">
                          {{ row?.imei || 'N/A' }}
                        </div>
                      </td>
                      <td [ngStyle]="{ width: width }" *ngIf="column.field === 'unitId'">
                        <div data-test="device-unit-id">
                          {{ row?.unitId || 'N/A' }}
                        </div>
                      </td>
                      <td [ngStyle]="{ width: width }" *ngIf="column.field === 'vin'">
                        <div data-test="device-vin">
                          {{ row?.vin || 'N/A' }}
                        </div>
                      </td>
                      <td [ngStyle]="{ width: width }" *ngIf="column.field === 'lastLocation.approximateAddress'">
                        <div data-test="device-address">
                          {{ row?.lastLocation?.approximateAddress || 'N/A' }}
                        </div>
                      </td>
                      <td [ngStyle]="{ width: width }" *ngIf="column.field === 'lastLocationUpdate'">
                        <div data-test="device-last-update">{{ row?.lastLocationUpdate || 'N/A' }}</div>
                      </td>
                      <td [ngStyle]="{ width: width }" *ngIf="column.field === 'lastUpdated'">
                        <div>{{ row?.lastUpdated || 'N/A' }}</div>
                      </td>
                      <td [ngStyle]="{ width: width }" *ngIf="column.field === 'lastUpdatedBy'">
                        <div>{{ row?.lastUpdatedBy || 'N/A' }}</div>
                      </td>
                      <td [ngStyle]="{ width: width }" *ngIf="column.field === 'driver'">
                        <div>{{ row?.driver || 'N/A' }}</div>
                      </td>
                      <td [ngStyle]="{ width: width }" *ngIf="column.field === 'shipping'">
                        <div>{{ row?.shipping || 'N/A' }}</div>
                      </td>
                      <td [ngStyle]="{ width: width }" *ngIf="column.field === 'return'">
                        <div>{{ row?.return || 'N/A' }}</div>
                      </td>
                      <td [ngStyle]="{ width: width }" *ngIf="column.field === 'status'">
                        <div>{{ row?.status || 'N/A' }}</div>
                      </td>
                      <td [ngStyle]="{ width: width }" *ngIf="column.field === 'cellularCarrierDeactivated'">
                        <div>{{ row?.cellularCarrierDeactivated || 'N/A' }}</div>
                      </td>
                      <td [ngStyle]="{ width: width }" *ngIf="column.field === 'notes'">
                        <div>{{ row?.notes || 'N/A' }}</div>
                      </td>
                      <td [ngStyle]="{ width: width }" *ngIf="column.field === 'icci'">
                        <div>{{ row?.icci || 'N/A' }}</div>
                      </td>
                      <td [ngStyle]="{ width: width }" *ngIf="column.field === 'type'">
                        <div *ngIf="!row.imei" class="no-telematics">NO TELEMATICS</div>
                        <div *ngIf="row.imei" data-test="device-type" [matMenuTriggerFor]="menu">
                          <mat-icon>{{ row.type === 'phone' ? 'smartphone' : 'local_shipping' }}</mat-icon>
                        </div>
                      </td>

                      <mat-menu #menu="matMenu">
                        <button mat-menu-item *ngIf="user?.isHaulynxAdmin" (click)="simulatePosition(row)">
                          <span>Simulate Position</span>
                        </button>
                        <button mat-menu-item [disabled]="true" *ngIf="user.carrier || user.isHaulynxAdmin">
                          <span>Message Driver</span>
                        </button>
                        <button
                          mat-menu-item
                          [disabled]="!row?.driverName"
                          *ngIf="!(user.carrier || user.isHaulynxAdmin)"
                          mat-menu-item
                          routerLink="{{ '../../dashboard/loads?assignTo=' + row.imei }}"
                        >
                          <span>Assign a Load to Truck</span>
                        </button>
                      </mat-menu>
                    </ng-template>
                  </data-table-v2>
                </div>
              </div>
            </div>
          </div>
        </form>
      </mat-tab>
      <mat-tab label="Trailers">
        <form class="form haulynx-form">
          <div class="row">
            <div class="col-lg-4" *ngIf="user?.isHaulynxAdmin || user?.broker?.isHaulynxBroker">
              <drop-down
                *ngIf="user?.isHaulynxAdmin || user?.broker?.isHaulynxBroker"
                placeholder="Select Carrier"
                (selectionChange)="changeCarrier($event)"
                [templateLabel]="templateDropDownCarrier"
                [(ngModel)]="carrierId"
                key="id"
                displayLabel="name"
                name="carrier"
                [data]="carriers"
              >
                <ng-template #templateDropDownCarrier let-row="row">
                  {{ row.name }} {{ row.dot ? ' (DOT:' + row.dot + ')' : '' }}
                </ng-template>
              </drop-down>
            </div>
            <div class="col-lg-12" *ngIf="user?.carrier || user?.isHaulynxAdmin">
              <button
                mat-raised-button
                class="trailer-button"
                color="primary"
                (click)="createEditOrDeleteTrailer()"
                data-test="new-trailer-button"
              >
                Add New Trailer
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <data-table
                [data]="trailers"
                [displayedColumns]="trailersColumns"
                [templateCellRef]="templateTrailers"
                [list]="true"
                [loading]="fleetModel.isLoading$ | async"
                data-test="trailers-table"
              ></data-table>

              <ng-template #templateTrailers let-row="row" let-column="column">
                <div class="header-align-right" *ngIf="column === 'editTrailer'">
                  <mat-icon
                    svgIcon="haulynx-pen"
                    class="icon"
                    data-test="edit-price-icon"
                    (click)="createEditOrDeleteTrailer(row)"
                  ></mat-icon>
                </div>
              </ng-template>
            </div>
          </div>
        </form>
      </mat-tab>
      <mat-tab label="Map">
        <form [formGroup]="deviceform">
          <mat-checkbox formControlName="phone" class="checkbox-margin" color="primary">Phones</mat-checkbox>
          <mat-checkbox formControlName="truck" class="checkbox-margin" color="primary">Trucks</mat-checkbox>
        </form>
        <div class="row">
          <div class="col-lg-12">
            <app-map-cluster [driverMarkers]="markers" [center]="center"> </app-map-cluster>
          </div>
        </div>
      </mat-tab>
      <!-- <mat-tab label="Posted Trucks">
        <app-posted-trucks [dot]="carrierDot"></app-posted-trucks>
      </mat-tab>
      <mat-tab label="Lanes">
        <app-lanes [dot]="carrierDot"></app-lanes>
      </mat-tab> -->
    </mat-tab-group>
  </div>
</div>
