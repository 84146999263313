<haulynx-dialog-template (closed)="close()">
  <div title>Need to pick up again once you are part-way-through?</div>
  <div content>
    <div>
      The RMIS registration is divided into two phases. You are required to complete the first part of the registration.
      After that, you will be prompted to “Complete Registration” or “Book Freight Now”. Select “Book Freight Now” in
      order to save your registration progress to continue at a later time. You can resume the RMIS registration by
      logging back in to the XT Platform and selecting “Complete Acct. Setup” in the top right progress button.
    </div>
  </div>
</haulynx-dialog-template>
