import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ArchiveLoadDialog } from '../load-archive/load-archive.component';

@Component({
  selector: 'app-bounce-carrier-dialog',
  templateUrl: './bounce-carrier-dialog.component.html',
  styleUrls: ['./bounce-carrier-dialog.component.scss'],
})
export class BounceCarrierDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { carrierName: string },
    private dialogRef: MatDialogRef<ArchiveLoadDialog>
  ) {}

  confirm(): void {
    this.dialogRef.close(true);
  }
}
