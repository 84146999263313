import { NgModule } from '@angular/core';

import { FieldLabelComponent } from './field-label.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule],
  declarations: [FieldLabelComponent],
  exports: [FieldLabelComponent],
})
export class FieldLabelModule {}
