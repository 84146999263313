import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@haulynx/directives';
import { MaterialModule } from '@haulynx/modules';
import { NgxMaskModule } from 'ngx-mask';
import { AddDriverComponent } from './add-driver.component';

@NgModule({
  declarations: [AddDriverComponent],
  imports: [CommonModule, ReactiveFormsModule, NgxMaskModule.forRoot(), MaterialModule, DirectivesModule],
  exports: [AddDriverComponent],
})
export class AddDriverModule {}
