<div class="load-active-assign" *ngIf="isDataLoaded$ | async; else spinner" data-test="content">
  <div *ngIf="showWarning" class="warning">
    <mat-icon svgIcon="haulynx-warning-sign" class="icon"></mat-icon>
    <div>Warning: This load is not currently assigned to a truck with an active tracking device. Please assign.</div>
  </div>
  <h1 mat-dialog-title>{{ title }}</h1>
  <div mat-dialog-content>
    <form *ngIf="form" class="form haulynx-form" [formGroup]="form">
      <mat-form-field class="col-lg-12">
        <mat-select
          placeholder="Select Driver"
          formControlName="driver"
          [disabled]="isOnlyADriver"
          data-test="driver-select"
        >
          <mat-option [value]="createOptionsBts.NEW_DRIVER"> Add New Driver + </mat-option>
          <mat-option *ngFor="let driver of drivers" [value]="driver.id">
            {{ driver.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="col-lg-12">
        <mat-select placeholder="Select Truck" formControlName="truck">
          <mat-option [value]="createOptionsBts.NEW_TRUCK"> Add New Truck + </mat-option>
          <mat-option *ngFor="let truck of trucks" [value]="truck.id">
            {{ getUnitDisplayName(truck) }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="col-lg-12">
        <mat-select placeholder="Select Trailer" formControlName="trailer">
          <mat-option [value]="createOptionsBts.NEW_TRAILER"> Add New Trailer + </mat-option>
          <mat-option *ngFor="let trailer of trailers" [value]="trailer.id">
            {{ trailer.trailerNumber }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </div>
  <button mat-icon-button [mat-dialog-close] class="load-active-assign__close">
    <mat-icon>close</mat-icon>
  </button>
  <div mat-dialog-actions>
    <action-buttons [buttons]="buttons$ | async" (onClick)="action($event)"> </action-buttons>
  </div>
</div>

<ng-template #spinner>
  <app-spinner [active]="true"></app-spinner>
</ng-template>
