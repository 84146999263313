import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'brokerList',
})
export class BrokerListPipe implements PipeTransform {
  transform(value: Array<{ id: string; label: string }>, isCompanyAdmin: boolean, usxId: string): unknown {
    if (isCompanyAdmin) {
      return value;
    } else {
      return value.filter((value) => value.label === usxId);
    }
  }
}
