import { Injectable } from '@angular/core';
import { listToArray } from '@haulynx/utils';
import { Store } from '@ngrx/store';
import { orderBy } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppState } from '../../main-store/app.reducers';
import { LoadOffersSearchActions } from './load.actions';
import { loadOffersSearchSelector } from './load.selectors';

@Injectable({
  providedIn: 'root',
})
export class LoadOffersModel {
  entities$: Observable<any[]> = this.store
    .select(loadOffersSearchSelector.getEntities)
    .pipe(map((entities) => orderBy(listToArray(entities), ['dateCreated'], ['desc'])));
  pagination$: Observable<any> = this.store.select(loadOffersSearchSelector.getPagination);
  query$: Observable<any> = this.store.select(loadOffersSearchSelector.getQuery);
  isSearchLoading$: Observable<boolean> = this.store.select(loadOffersSearchSelector.getLoading);

  constructor(private store: Store<AppState>) {}

  search(filter) {
    this.store.dispatch(LoadOffersSearchActions.search(filter));
  }
}
