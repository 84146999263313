import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { AdvancedSearchService } from '@haulynx/services';
import { GeocodingEntityService } from '@haulynx/store';
import {
  canadaStates,
  GeoSpacialFilterType,
  ISearchFilter,
  ISearchFilterType,
  mexicoStates,
  PlaceCountries,
  PlaceInfo,
  PlaceType,
  StateDictionary,
  states,
  TextArrayMode,
} from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { Subscription } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-search-location',
  templateUrl: './search-location.component.html',
  styleUrls: ['./search-location.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchLocationComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('wrapper', { static: false }) wrapper: ElementRef;
  @ViewChild('slInput', { static: false }) firstInput: ElementRef;

  @Input() focusedFilter: ISearchFilter;
  @Input() activeFilters: any;
  @Input() allowZipSearch? = false;
  @Input() form: FormGroup;
  @Input() selectedPlace: PlaceInfo;
  @Input() textArrayMode: TextArrayMode;

  @Output() mouseFocus: EventEmitter<boolean> = new EventEmitter();
  @Output() commitFilter: EventEmitter<{ searchFilter: ISearchFilter; form: FormGroup }> = new EventEmitter();
  @Output() removeFilter: EventEmitter<ISearchFilter> = new EventEmitter();
  @Output() validForm: EventEmitter<boolean> = new EventEmitter();
  @Output() newSearchValue: EventEmitter<Array<PlaceInfo>> = new EventEmitter();

  locationFormName: string;
  radiusFormName: string;
  stateFormName: string;
  zipFormName: string;
  latFormName: string;
  lonFormName: string;

  shouldCheckClicks = false;
  shouldSendNewValue = true;
  showRadiusField = false;
  defaultRadius = 50;
  stateSearchTermsLength = 2;
  selectedIndex = 0;
  hasMadeASearch = false;
  emptyInput = false;

  alive = aliveWhile();

  locationFormArrayChanges: Subscription = null;
  places: PlaceInfo[] = [];

  constructor(public searchService: AdvancedSearchService, private geocodingEntityService: GeocodingEntityService) {}

  @HostListener('window:keyup', ['$event']) onKeyPress(event: KeyboardEvent): void {
    if (event.key === 'Enter' && this.isFormValid) {
      this.commitFilter.emit({ searchFilter: this.focusedFilter, form: this.form });
      this.mouseFocus.emit(false);
      this.validForm.emit(false);
    } else {
      this.validForm.emit(this.isFormValid);
      this.shouldSendNewValue = true;
    }
  }

  @HostListener('document:click', ['$event.target']) onClick(targetElement: ElementRef): void {
    const clickedInside = this.wrapper.nativeElement.contains(targetElement);
    if (!clickedInside && this.shouldCheckClicks && !this.isFormValid) {
    } else {
      this.mouseFocus.emit(true);
      this.validForm.emit(this.isFormValid);
    }
  }

  ngOnInit(): void {
    this.hasMadeASearch = false;
    if (this.focusedFilter) {
      this.locationFormName = (<GeoSpacialFilterType>this.focusedFilter.keys).locationFormName;
      this.radiusFormName = (<GeoSpacialFilterType>this.focusedFilter.keys).radiusFormName;
      this.stateFormName = (<GeoSpacialFilterType>this.focusedFilter.keys).stateFormName;
      this.zipFormName = (<GeoSpacialFilterType>this.focusedFilter.keys).zipFormName;
      this.latFormName = (<GeoSpacialFilterType>this.focusedFilter.keys).latFormName;
      this.lonFormName = (<GeoSpacialFilterType>this.focusedFilter.keys).lonFormName;
      const {
        location: locationValue,
        radius: radiusValue,
        state: stateValue,
        zipcode: zipValue,
        lat: latValue,
        lon: lonValue,
      } = <GeoSpacialFilterType>this.focusedFilter.keys;
      if (this.focusedFilter['searchPlace']) {
        this.selectedIndex = 0;

        if (this.locationArrayForm) {
          this.locationArrayForm.clear();
          this.locationArrayForm.push(new FormControl(locationValue ?? ''));
        }
        if (this.radiusArrayForm) {
          this.radiusArrayForm.clear();
          this.radiusArrayForm.push(new FormControl(radiusValue ?? null));
        }
        if (this.stateArrayForm) {
          this.stateArrayForm.clear();
          this.stateArrayForm.push(new FormControl(stateValue ?? ''));
        }
        if (this.zipArrayForm) {
          this.zipArrayForm.clear();
          this.zipArrayForm.push(new FormControl(zipValue ?? ''));
        }
        if (this.latArrayForm) {
          this.latArrayForm.clear();
          this.latArrayForm.push(new FormControl(latValue ?? null));
        }
        if (this.lonArrayForm) {
          this.lonArrayForm.clear();
          this.lonArrayForm.push(new FormControl(lonValue ?? null));
        }

        (<GeoSpacialFilterType>this.focusedFilter.keys).index = this.selectedIndex;
      } else if (this.textArrayMode === TextArrayMode.UPDATE) {
        this.selectedIndex = this.locationArrayForm.value.findIndex((item: string) => item === locationValue);
        (<GeoSpacialFilterType>this.focusedFilter.keys).index = this.selectedIndex;
      } else {
        if (this.locationArrayForm) this.locationArrayForm.push(new FormControl(locationValue ?? ''));
        if (this.radiusArrayForm) this.radiusArrayForm.push(new FormControl(radiusValue ?? null));
        if (this.stateArrayForm) this.stateArrayForm.push(new FormControl(stateValue ?? ''));
        if (this.zipArrayForm) this.zipArrayForm.push(new FormControl(zipValue ?? ''));
        if (this.latArrayForm) this.latArrayForm.push(new FormControl(latValue ?? null));
        if (this.lonArrayForm) this.lonArrayForm.push(new FormControl(lonValue ?? null));

        this.selectedIndex = this.locationArrayForm.length - 1;
        (<GeoSpacialFilterType>this.focusedFilter.keys).index = this.selectedIndex;
      }
    }

    this.setupLocationArrayFormSubscription();

    this.geocodingEntityService.getGeocodeLocationManager.searchResults$
      .pipe(takeUntil(this.alive))
      .subscribe((places: PlaceInfo[]) => {
        if (places && places.length > 0) {
          this.newSearchValue.emit(places);
        }
      });

    this.locationArrayForm?.controls[this.selectedIndex].valueChanges.pipe(takeUntil(this.alive)).subscribe((value) => {
      if (!value || value === '') {
        this.emptyInput = true;
      } else {
        this.emptyInput = false;
      }
    });
  }

  setupLocationArrayFormSubscription = () => {
    if (this.locationFormArrayChanges) {
      this.locationFormArrayChanges.unsubscribe();
    }

    this.locationFormArrayChanges = this.locationArrayForm?.valueChanges
      .pipe(
        takeUntil(this.alive),
        debounceTime(300),
        filter((value) => {
          const doContinue =
            this.selectedIndex !== -1 &&
            value[this.selectedIndex] &&
            value[this.selectedIndex] !== '' &&
            this.shouldSendNewValue;
          if (!doContinue) {
            this.shouldSendNewValue = true;
          }
          return doContinue;
        })
      )
      .subscribe((value) => {
        if (value && value.length > 0) {
          this.geocodingEntityService.getGeocodeLocationManager.dispatch({
            query: {
              search: value[this.selectedIndex],
              autocomplete: true,
              ...(this.focusedFilter['searchPlace'] && { placeType: this.focusedFilter['searchPlace'] }),
              limit: 10,
            },
          });
        }
      });
  };

  get locationArrayForm(): FormArray {
    return this.form.get(this.locationFormName) as FormArray;
  }

  get radiusArrayForm(): FormArray {
    return this.form.get(this.radiusFormName) as FormArray;
  }

  get stateArrayForm(): FormArray {
    return this.form.get(this.stateFormName) as FormArray;
  }

  get zipArrayForm(): FormArray {
    return this.form.get(this.zipFormName) as FormArray;
  }

  get latArrayForm(): FormArray {
    return this.form.get(this.latFormName) as FormArray;
  }

  get lonArrayForm(): FormArray {
    return this.form.get(this.lonFormName) as FormArray;
  }

  removeFocusedFilter(): void {
    if (this.locationArrayForm) this.locationArrayForm.removeAt(this.selectedIndex);
    if (this.radiusArrayForm) this.radiusArrayForm.removeAt(this.selectedIndex);
    if (this.stateArrayForm) this.stateArrayForm.removeAt(this.selectedIndex);
    if (this.latArrayForm) this.latArrayForm.removeAt(this.selectedIndex);
    if (this.lonArrayForm) this.lonArrayForm.removeAt(this.selectedIndex);
    if (this.zipArrayForm) this.zipArrayForm.removeAt(this.selectedIndex);

    this.removeFilter.emit(this.focusedFilter);
    this.mouseFocus.emit(false);
  }

  isSearchByState(): boolean {
    if (this.selectedPlace) {
      const hasState = !!this.selectedPlace.state || !!this.selectedPlace.street;
      const hasCountry = !!this.selectedPlace.country;
      const hasCity = !!this.selectedPlace.state && !!this.selectedPlace.street;

      if (this.selectedPlace.country === PlaceCountries.MEXICO && this.selectedPlace.placeType === PlaceType.region) {
        return hasCountry && !!this.selectedPlace.street;
      }

      return hasState && !hasCity;
    }

    return false;
  }

  /*
   * State will be stored as a street if there is not street that exists on the search result
   */
  getSelectedStateCode(place: PlaceInfo): string {
    const stateName = !place.state && place.street ? place.street : place.state;
    let stateDictionary: StateDictionary[];
    switch (place.country) {
      case PlaceCountries.USA:
        stateDictionary = states;
        break;
      case PlaceCountries.CANADA:
        stateDictionary = canadaStates;
        break;
      case PlaceCountries.MEXICO:
        stateDictionary = mexicoStates;
        break;
    }

    const selectedState = stateDictionary?.find((item: StateDictionary) => {
      return item.name.toLowerCase().includes(stateName.toLowerCase()) || item.code === stateName ? true : false;
    });

    return selectedState?.code;
  }

  ngAfterViewInit(): void {
    if (this.focusedFilter) {
      this.firstInput.nativeElement.focus();
      this.mouseFocus.emit(true);
      this.shouldCheckClicks = false;
      setTimeout(() => {
        this.validForm.emit(this.isFormValid);
        this.shouldCheckClicks = true;
      }, 250);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedPlace && this.selectedPlace) {
      this.hasMadeASearch = true;
      this.showRadiusField = !this.isSearchByState() && !this.focusedFilter['searchPlace'];
      this.shouldSendNewValue = false;

      if (!this.showRadiusField && this.stateArrayForm) {
        this.radiusArrayForm?.at(this.selectedIndex)?.setValue(null);
        this.stateArrayForm?.at(this.selectedIndex)?.setValue(this.getSelectedStateCode(this.selectedPlace));
      } else {
        this.radiusArrayForm?.at(this.selectedIndex)?.setValue(this.defaultRadius);
        // remove the state from a location filter that isn't a state search
        this.stateArrayForm?.at(this.selectedIndex)?.setValue(null);
        this.latArrayForm?.at(this.selectedIndex)?.setValue(this.selectedPlace.lat ?? null);
        this.lonArrayForm?.at(this.selectedIndex)?.setValue(this.selectedPlace.lon ?? null);
      }
      this.locationArrayForm?.at(this.selectedIndex)?.setValue(this.selectedPlace.fullAddress);
      this.setupLocationArrayFormSubscription();

      this.validForm.emit(this.isFormValid);
    }

    if (changes.focusedFilter) {
      this.hasMadeASearch = false;
      // The user might have submitted a search value before we were able to run ngOnInit.
      // If that's the case, let's run a search query.
      if (!this.locationFormName) {
        const newPatchValue = (<GeoSpacialFilterType>this.focusedFilter.keys).location;
        this.geocodingEntityService.getGeocodeLocationManager.dispatch({
          query: {
            search: newPatchValue,
            autocomplete: true,
            ...(this.focusedFilter['searchPlace'] && { placeType: this.focusedFilter['searchPlace'] }),
            limit: 10,
          },
        });
      }
    }
  }

  hasHitMaxLocationSearch(filters): boolean {
    return filters.length > 5;
  }

  get isFormValid(): boolean {
    if (
      this.focusedFilter.name === 'Origin' &&
      this.hasHitMaxLocationSearch(this.activeFilters.filter((val) => val.name === 'Origin'))
    ) {
      return false;
    }

    if (
      this.focusedFilter.name === 'Destination' &&
      this.hasHitMaxLocationSearch(this.activeFilters.filter((val) => val.name === 'Destination'))
    ) {
      return false;
    }

    this.showRadiusField =
      this.isSearchByState() ||
      this.focusedFilter['searchPlace'] ||
      this.isZipcode(this.form.value.originLocation) ||
      this.isZipcode(this.form.value.destinationLocation) ||
      !this.hasMadeASearch
        ? false
        : true;

    const locationVal = this.locationArrayForm?.value[this.selectedIndex];

    if (this.emptyInput) {
      return false;
    }
    if (this.showRadiusField) {
      const latVal = this.latArrayForm?.value[this.selectedIndex];
      const lonVal = this.lonArrayForm?.value[this.selectedIndex];

      const hasAddress = locationVal !== '';
      const hasLat = latVal !== null && latVal !== 0;
      const hasLon = lonVal !== null && lonVal !== 0;

      return hasAddress && hasLat && hasLon && this.isRadiusFormValid();
    } else if (this.allowZipSearch && this.isZipcode(this.form.value.originLocation)) {
      return !!locationVal;
    } else if (this.allowZipSearch && this.isZipcode(this.form.value.destinationLocation)) {
      return !!locationVal;
    } else {
      return !!this.state && this.hasMadeASearch;
    }
  }

  isZipcode(val): boolean {
    const valAsString: string = val?.toString ?? '';
    return valAsString?.length === 5 && !!valAsString?.match(/\b\d{5}\b/g);
  }

  get state(): string {
    return (
      this.form.get((<GeoSpacialFilterType>this.focusedFilter?.keys)?.stateFormName)?.value ||
      this.form.get((<GeoSpacialFilterType>this.focusedFilter?.keys)?.locationFormName)?.value
    );
  }

  isRadiusFormValid(): boolean {
    return this.radiusArrayForm?.value[this.selectedIndex] > 0;
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }
}
