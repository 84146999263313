import gql from 'graphql-tag';

export const SearchCustomersBy = gql`
  query SearchCustomersBy($searchCustomerParams: SearchCustomerParams!) {
    searchCustomersBy(searchCustomerParams: $searchCustomerParams) {
      id
      company
      number
      name
      address1
      address2
      city
      state
      zipCode
      custTimezone
      custFormattedAddress
      telephone
      status
      notes {
        noteNumber
        noteSeq
        noteText
        actionType
      }
      receivingHours {
        dayOfWeek
        hourType
        openTime
        closeTime
        isFirstComeFirstServe
      }
      shippingHours {
        dayOfWeek
        hourType
        openTime
        closeTime
        isFirstComeFirstServe
      }
      customerAttributes {
        canPickUpEarly
        canDeliverEarly
        dropYard
        canBreakAtFacility
        hasFacilities
      }
    }
  }
`;
