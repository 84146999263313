import { Injectable } from '@angular/core';
import { has, isEmpty, isNumber, split } from 'lodash';
import { Moment } from 'moment';
import moment from 'moment-timezone';
import * as tzLookup from 'tz-lookup';

@Injectable({
  providedIn: 'root',
})
export class MomentService {
  getMoment(unixTime = null, timeZone = null, keepLocal = false): moment.Moment {
    const time: Moment = unixTime ? moment(unixTime) : moment();

    if (timeZone) {
      return time.tz(timeZone, keepLocal);
    }

    return time;
  }

  getToday(timeZone = null, keepLocal = false) {
    const time = moment();

    if (timeZone) {
      return time.tz(timeZone, keepLocal);
    }

    return time;
  }

  getTodayUnixMidnight() {
    return this.getToday().startOf('day').valueOf();
  }

  getTimeZoneByLocation(latitude, longitude) {
    return tzLookup(latitude, longitude);
  }

  isDatesRange(newDate) {
    return has(newDate, 'begin') && has(newDate, 'end');
  }

  getMomentFromString(dateString: string, timeZone = null, keepLocal = false): moment.Moment {
    const date = moment(dateString, 'MM/DD/YYYY').startOf('day');

    if (timeZone) {
      return date.tz(timeZone, keepLocal);
    }

    return date;
  }

  getUtcFromInput(
    dateString: string,
    inputFormat: string,
    outputFormat: string,
    timeZone = null,
    keepLocal = false
  ): string {
    const date = moment.utc(dateString, inputFormat);

    if (timeZone) {
      return date.tz(timeZone, keepLocal).format(outputFormat);
    }
    return date.format(outputFormat);
  }

  convertHtmlTime(unixTime: number, time: string, timezone = null) {
    const date = this.getMoment(unixTime, timezone);
    if (!unixTime || !time) {
      return date;
    } else {
      const [hour, minute] = split(time, ':');
      return date.hours(Number(hour)).minutes(Number(minute));
    }
  }

  isValidDate(timestamp: string | number, timezone: string): boolean {
    if (!timestamp || (!isNumber(timestamp) && isEmpty(timestamp))) {
      return false;
    }

    const resultMoment = this.getMoment(timestamp, timezone, false);
    return resultMoment.isValid();
  }

  getDateFormat(timestamp: string | number, format = 'D MMM YYYY h:mma', timezone?: string): string {
    const isValid = this.isValidDate(timestamp, timezone);

    if (!isValid) {
      return '';
    }

    timestamp = Number(timestamp) ? Number(timestamp) : timestamp;

    if (!timezone) {
      console.warn(`No timezone provided for time ${timestamp}`);
      timezone = 'America/Phoenix';
    }

    return this.getMoment(timestamp, timezone, false).format(format);
  }

  isToday(unixTime: number): boolean {
    const today = this.getToday();

    return today.isSame(this.getMoment(unixTime), 'day');
  }

  getTimeZoneAbbr(timezone: string): string {
    if (timezone) {
      return moment.tz(timezone).zoneAbbr();
    } else {
      const guess = moment.tz.guess();

      return moment().tz(guess).format('z');
    }
  }
}
