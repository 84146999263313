export const PAYMENT_ITEM_SINGLE_QUANTITY = {
  ACCDED: 'ACCDED',
  ADVLNF: 'ADVLNF',
  ADVLWF: 'ADVLWF',
  BOBTAL: 'BOBTAL',
  BRDCRS: 'BRDCRS',
  DHMLFT: 'DHMLFT',
  ESCRT: 'ESCRT',
  FUADV: 'FUADV',
  FUELFT: 'FUELFT',
  LDUNLD: 'LDUNLD',
  LHDED: 'LHDED',
  LHFLT: 'LHFLT',
  LUMBRK: 'LUMBRK',
  MINRED: 'MINRED',
  PRMTS: 'PRMTS',
  RCNSGN: 'RCNSGN',
  SRVBN: 'SRVBN',
  STRFLT: 'STRFLT',
  TLSPOT: 'TLSPOT',
  TONU: 'TONU',
  TRAIL: 'TRAIL',
};
export const PAYMENT_ITEM_MULTI_QUANTITY = {
  DTNTN: 'DTNTN',
  FUELMI: 'FUELMI',
  LHMLS: 'LHMLS',
  LYOVER: 'LYOVER',
  SPAY: 'SPAY',
  TRAIL: 'TRAIL',
};
export const PAYMENT_ITEM_NEGATIVE = {
  ACCDED: 'ACCDED',
  MINRED: 'MINRED',
  RCNSGN: 'RCNSGN',
};
