import { MissionValidationReasons } from '@haulynx/types';

export function findReason(reason: string) {
  if (!reason) return '';

  const lowerCaseReason = reason.toLowerCase();

  if (lowerCaseReason.indexOf(MissionValidationReasons.COMPLETE) !== -1) {
    return MissionValidationReasons.COMPLETE;
  } else if (lowerCaseReason.indexOf(MissionValidationReasons.INVALID) !== -1) {
    return MissionValidationReasons.INVALID;
  } else if (lowerCaseReason.indexOf(MissionValidationReasons.PART) !== -1) {
    return MissionValidationReasons.PART;
  } else if (lowerCaseReason.indexOf(MissionValidationReasons.DUPLICATE) !== -1) {
    return MissionValidationReasons.DUPLICATE;
  }

  return '';
}
