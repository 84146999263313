import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { MapsModule } from '../maps/maps.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { CustomerDetailsDialogComponent } from './components/customer-details-dialog/customer-details-dialog.component';
import { CustomerDetailsComponent } from './customer-details.component';

@NgModule({
  declarations: [CustomerDetailsComponent, CustomerDetailsDialogComponent],
  imports: [CommonModule, SpinnerModule, PipesModule, MapsModule, MaterialModule],
  exports: [CustomerDetailsComponent, CustomerDetailsDialogComponent],
})
export class CustomerDetailsModule {}
