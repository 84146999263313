import { CurrencyPipe, DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import {
  CSVLaneData,
  DataTableType,
  EntityType,
  OrderByParams,
  ZipLane,
  DataTableCSVTypes,
  LaneHistoryRow,
  LoadsServiceLoad,
  LoadIdentifierType,
  LoadsServiceRestrictionTypes,
} from '@haulynx/types';
import { listToArray, mapToArray } from '@haulynx/utils';
import { format, utcToZonedTime } from 'date-fns-tz';
import elementResizeDetectorMake from 'element-resize-detector';
import { List, Map } from 'immutable';
import { chunk, isArray, orderBy, lowerCase, startCase, toNumber } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class DataTableV2Service {
  private resizeDetector;

  constructor(private datePipe: DatePipe) {
    this.resizeDetector = elementResizeDetectorMake({ strategy: 'scroll' });
  }

  addResizeEventListener(element: HTMLElement, handler: unknown): void {
    this.resizeDetector.listenTo(element, handler);
  }

  removeResizeEventListener(element: HTMLElement): void {
    this.resizeDetector.uninstall(element);
  }

  getPaginationData(
    data: EntityType[],
    limit: number,
    currentPage: number = 1
  ): {
    pagination: {
      currentPage: number;
      total: number;
      totalPages: number;
    };
    currentPageData: EntityType[];
  } {
    const chunkedData = chunk(data, limit);
    return {
      pagination: {
        currentPage,
        total: data.length,
        totalPages: chunkedData.length,
      },
      currentPageData: chunkedData[currentPage - 1],
    };
  }

  sortData(data: EntityType[], config: OrderByParams<any>): EntityType[] {
    return orderBy(data, config[0], config[1]) as EntityType[];
  }

  getData(data: DataTableType<EntityType>): EntityType[] {
    if (List.isList(data)) {
      return listToArray(data);
    }

    if (Map.isMap(data)) {
      return mapToArray(data);
    }

    if (isArray(data)) {
      return data;
    }

    return [];
  }

  getCSVOptions(tableType: DataTableCSVTypes = DataTableCSVTypes.ZIP_LANE): unknown {
    if (tableType === DataTableCSVTypes.ZIP_LANE) {
      return this.getZipLaneCSVOptions();
    }
    if (tableType === DataTableCSVTypes.LANE_ANALYSIS) {
      return this.getLaneAnalysisCSVOptions();
    }
    if (tableType === DataTableCSVTypes.LOAD_BOARD) {
      return this.getLoadBoardCSVOptions();
    }
    return;
  }

  getCSVData(
    row: any,
    rowType: DataTableCSVTypes = DataTableCSVTypes.ZIP_LANE,
    loadsNearestCities?: { [key: string]: string }
  ) {
    if (rowType === DataTableCSVTypes.ZIP_LANE) {
      return this.getZipLaneCSVData(row);
    }
    if (rowType === DataTableCSVTypes.LANE_ANALYSIS) {
      return this.getLaneAnalysisCSVData(row);
    }
    if (rowType === DataTableCSVTypes.LOAD_BOARD) {
      return this.getLoadBoardCSVData(row, loadsNearestCities);
    }
    return;
  }

  getLoadBoardCSVOptions(): unknown {
    return {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      useBom: true,
      headers: [
        'Origin City',
        'Origin State',
        'Origin Zip',
        'Origin Date/Time Start',
        'Origin Date/Time End',
        'Destination City',
        'Destination State',
        'Destination Zip',
        'Destination Date/Time Start',
        'Destination Date/Time End',

        'Status',
        'Mission',
        'Tracking Status',
        'Last Location',
        'Last Milestone Comment',
        'Equipment',
        'Bill To',
        'TMW #',
        'Order #',
        'Price',
        'Max Buy',
        'Revenue',
        'Team Required',
        'Bids',
        'Trailer Instr',
        'Truck',
        'Trailer',
        'Driver',
        'Carrier / DOT',
        'Broker Assignees',
        'Booked By',
        'Freight Mix',
        'Loaded Miles',
        'Region',
        'Broker Team',
        'Shipper',
        'Receiver',
        'CX Priority',
        'Tracking Types',
        'High Value',
        'CXR Id',
        'Appt Resets',
      ],
    };
  }

  getLoadBoardCSVData(row: LoadsServiceLoad, loadsNearestCities: { [key: string]: string } = {}): any {
    const assignedBrokers = row.assignedBrokers?.length
      ? row.assignedBrokers.reduce((accum, broker) => {
          const idOrName = broker.usxId ? broker.usxId : broker.name;

          return accum ? `${accum} ${idOrName}` : `${idOrName}`;
        }, '')
      : 'None';

    const getTrailerInstructions = () => {
      const pickupReq = row.restrictions?.find(
        (restriction) => restriction.type === LoadsServiceRestrictionTypes.PICKUP_REQUIREMENTS
      );
      const dropoffReq = row.restrictions?.find(
        (restriction) => restriction.type === LoadsServiceRestrictionTypes.DROPOFF_REQUIREMENTS
      );

      const pickupInstructions = pickupReq ? startCase(lowerCase(pickupReq.value)) : '';
      const dropoffInstructions = dropoffReq ? startCase(lowerCase(dropoffReq.value)) : '';

      if (pickupInstructions || dropoffInstructions) {
        return `${pickupInstructions} / ${dropoffInstructions}`;
      }

      return 'Not Available';
    };

    const getHighValueText = () => {
      const highValueProduct = row.restrictions?.find(
        (restriction) => restriction.type === LoadsServiceRestrictionTypes.HIGH_VALUE_PRODUCT
      );
      const highValueException = row.restrictions?.find(
        (restriction) => restriction.type === LoadsServiceRestrictionTypes.HIGH_VALUE_PRODUCT_EXCEPTION
      );

      const highValueText = highValueProduct ? 'Yes' : 'No';
      const highValueExceptionText = highValueException ? 'Exception' : '';

      return `${highValueText} ${highValueExceptionText}`;
    };

    const getFormattedDate = (time, timeZone) => {
      if (time && timeZone) {
        const outputFormat = 'MM/dd/yy HH:mm zz';

        const result = format(utcToZonedTime(toNumber(time), timeZone), outputFormat, { timeZone });
        return result;
      }
      
      return '--';
    }
    

    return {
      originCity: row.locations[0]?.city,
      originState: row.locations[0]?.state,
      originZip: row.locations[0]?.zipcode,
      originTimeStart: getFormattedDate(row.locations[0]?.appointmentStart, row.locations[0]?.timezone),
      originTimeEnd: getFormattedDate(row.locations[0]?.appointmentEnd, row.locations[0]?.timezone),

      destinationCity: row.locations[row.locations.length - 1]?.city,
      destinationState: row.locations[row.locations.length - 1]?.state,
      destinationZip: row.locations[row.locations.length - 1]?.zipcode,
      destinationTimeStart: getFormattedDate(
        row.locations[row.locations.length - 1]?.appointmentStart,
        row.locations[row.locations.length - 1]?.timezone
      ),
      destinationTimeEnd: getFormattedDate(
        row.locations[row.locations.length - 1]?.appointmentEnd,
        row.locations[row.locations.length - 1]?.timezone
      ),

      status: startCase(lowerCase(row.loadStatus)),
      mission: row.mission?.id || 'N/A',
      trackingStatus: row.trackingStatus?.usxOrderStatus || 'Not Available',
      lastLocation: loadsNearestCities[row.id] ? loadsNearestCities[row.id] : 'Last Location Unavailable',
      lastMilestoneComment: row.latestMilestoneComment?.text || 'No Comment Added',
      equipment: `${startCase(lowerCase(row.providerDetails?.equipmentType))}, ${row.providerDetails?.weight} lbs`,
      billTo: row.providerDetails?.billToName || '',
      tmwNumber: row.providerDetails?.alternateIds?.find((id) => id.identifierType === LoadIdentifierType.TMW_NUMBER)
        ?.identifierValue,
      orderNumber: row.providerDetails?.alternateIds?.find(
        (id) => id.identifierType === LoadIdentifierType.ORDER_NUMBER
      )?.identifierValue,
      price: row.paymentDetails?.price ? `$${row.paymentDetails?.price?.toFixed(2)}` : '',
      maxBuy: row.paymentDetails?.maxBuy ? `$${row.paymentDetails?.maxBuy?.toFixed(2)}` : '',
      revenue: row.paymentDetails?.revenue ? `$${row.paymentDetails?.revenue?.toFixed(2)}` : '',
      teamRequired:
        row.restrictions?.find((restriction) => restriction.type === LoadsServiceRestrictionTypes.TEAM_DRIVING_REQUIRED)
          ?.value || '',
      bids: row.bidDetails?.lowestBid?.price ? `$${row.bidDetails?.lowestBid?.price?.toFixed(2)}` : 'No Offer',
      trailerInstructions: getTrailerInstructions(),
      truck: row.truck?.unitId || 'No Truck Assigned',
      trailer: row.trailers[0]?.trailerNumber || 'No Trailer Assigned',
      driver: `${
        row.drivers[0]?.name
          ? `${row.drivers[0].name}, ${row.drivers[0].phone || 'No Phone Number'}`
          : 'No Driver Assigned'
      }`,
      carrierDot: `${row.carrier?.name || ''} ${row.carrier?.dot || ''}`,
      brokerAssignees: assignedBrokers,
      bookedBy: row.broker?.name || '',
      freightMix: row.providerDetails?.orderType || '',
      loadedMiles: row.paymentDetails?.distanceMiles || '',
      region: row.region || '',
      brokerTeam: row.providerDetails?.brokerTeamId || '',
      shipper: row.locations[0]?.customer?.name || '',
      receiver: row.locations[row.locations.length - 1]?.customer?.name || '',
      cxPriority: row.providerDetails?.cxPriority || '',
      trackingTypes: row.trackingType === 'manual' ? 'No Tracking Assigned' : row.trackingType,
      highValue: getHighValueText(),
      CXRId: row.providerDetails?.cxrEmployeeId || '',
      apptResets: row.numberOfAppointmentResets || 0,
    };
  }

  getZipLaneCSVOptions(): unknown {
    return {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      useBom: true,
      headers: [
        'Origin City',
        'Origin State',
        'Origin Zip',
        'OCEN',
        'Origin Date/Time',
        'Destination City',
        'Destination State',
        'Destination Zip',
        'DCEN',
        'Destination Date/Time',

        'Miles',
        'ML All-in RPM',
        'ML Cost RPM',
        'ML Lane Volatility RPM',

        'Average Bid Rate',
        'Average Bid Amount',
        'Average Bid Min',
        'Average Bid Max',
        'Average Won Rate',
        'Average Won Amount',
        'Won Min',
        'Won Max',

        'Average Cost Rate',
        'Average Cost Amount',
        'Cost Min',
        'Cost Max',

        'Average Margin Amount',
        'Margin Min',
        'Margin Max',

        'DAT Rate',
        'DAT Amount',

        'Recent Cost Rate',
        'Recent Cost Amount',

        'Load Count',

        'Bid Since Won',
        'One Week Attainment',
        'Customer',

        'Equipment Type',
        'Equipment Weight',
        'Equipment Commodity',

        'All in-RPM',
        'Cost RPM',
      ],
    };
  }

  getZipLaneCSVData(row: ZipLane): CSVLaneData {
    const container: CSVLaneData = {
      originCity: row.locations[0].city,
      originState: row.locations[0].state,
      originZip: row.locations[0].zip,
      originCentroidDistance: row.locations[0].centroidDistance,
      originTime: this.datePipe.transform(row.locations[0].timestamp, 'long'),
      destinationCity: row.locations[row.locations.length - 1].city,
      destinationState: row.locations[row.locations.length - 1].state,
      destinationZip: row.locations[row.locations.length - 1].zip,
      destinationCentroidDistance: row.locations[row.locations.length - 1].centroidDistance,
      destinationTime: this.datePipe.transform(row.locations[row.locations.length - 1].timestamp, 'long'),

      miles: row.miles,
      allInRpm: row.mlPredicted.allInRpm,
      costRpm: row.mlPredicted.costRpm,
      laneVolatility: row.mlPredicted.laneVolatility,

      averageBidRate: row.historical.averageBidRate,
      averageBidAmount: row.historical.averageBidAmount,
      bidLow: row.historical.bidLow,
      bidHigh: row.historical.bidHigh,
      averageWonRate: row.historical.averageWonRate,
      averageWonAmount: row.historical.averageWonAmount,
      wonLow: row.historical.wonLow,
      wonHigh: row.historical.wonHigh,

      averageCostAmount: row.costDetails.averageCostAmount,
      averageCostRate: row.costDetails.averageCostRate,
      minCost: row.costDetails.minCost,
      maxCost: row.costDetails.maxCost,

      averageMarginAmount: row.historical.averageMarginAmount,
      marginHigh: row.historical.marginHigh,
      marginLow: row.historical.marginLow,

      datRate: row.historical.datRate,
      datAmount: row.historical.datAmount,

      recentCostRate: row.costDetails.recentCostRate,
      recentCostAmount: row.costDetails.recentCostAmount,

      count: row.laneDetails.count,

      bidSinceWon: row.laneDetails.bidSinceWon,
      oneWeekAttainment: row.laneDetails.oneWeekAttainment,
      customer: row.laneDetails.customer,

      equipmentType: row.loadContents.equipmentType,
      equipmentWeight: row.loadContents.weight,
      equipmentCommodity: row.loadContents.commodity,

      allInRPM: row.allInRPM,
      costRPM: row.costToHireRPM,
    };
    return container;
  }

  getLaneAnalysisCSVOptions() {
    return {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      useBom: true,
      headers: [
        'Date',
        'Type',
        'Bill-To',
        'Origin Address',
        'Origin Date/Time',
        'Destination Address',
        'Destination Date/Time',
        'Broker',
        'Revenue',
        'Carrier Rate',
        'Margin',
      ],
    };
  }

  getLaneAnalysisCSVData(row: LaneHistoryRow) {
    if (!row) {
      return;
    }
    const originStartDateTimeValue = new Date(row.locations[0]?.appointmentStart);
    const originEndDateTimeValue = new Date(row.locations[0]?.appointmentEnd);
    const destinationStartDateTimeValue = new Date(row.locations[1]?.appointmentStart);
    const destinationEndDateTimeValue = new Date(row.locations[1]?.appointmentEnd);
    return {
      date: `${new Date(row?.paymentDetails?.bookedAt).toDateString()}, ${new Date(
        row?.paymentDetails?.bookedAt
      ).toTimeString()}`,
      type: row.status?.toString(),
      billTo: row.providerDetails?.billToName,
      originAddress: row.locations[0]?.address,
      originDateTime: `${originStartDateTimeValue ? originStartDateTimeValue.toDateString() : ''}, ${
        originStartDateTimeValue ? originStartDateTimeValue.toTimeString() : ''
      } - ${originEndDateTimeValue ? originEndDateTimeValue.toDateString() : ''}, ${
        originEndDateTimeValue ? originEndDateTimeValue.toTimeString() : ''
      }`,
      destinationAddress: row.locations[1]?.address,
      destinationDateTime: `${destinationStartDateTimeValue ? destinationStartDateTimeValue.toDateString() : ''}, ${
        destinationStartDateTimeValue ? destinationStartDateTimeValue.toTimeString() : ''
      } - ${destinationEndDateTimeValue ? destinationEndDateTimeValue.toDateString() : ''}, ${
        destinationEndDateTimeValue ? destinationEndDateTimeValue.toTimeString() : ''
      }`,
      broker: row.broker?.name,
      revenue: `$${row.paymentDetails?.revenue.toFixed(2)}`,
      carrierRate: this.getCarrierRateText(row.paymentDetails),
      margin: this.getMarginText(row.paymentDetails),
    };
  }

  getCarrierRateText(paymentDetails: any) {
    if (paymentDetails.avgCarrierRateDiff < 0) {
      return `$${paymentDetails.price.toFixed(2)} ($${Math.round(paymentDetails.avgCarrierRateDiff * -1).toFixed(
        2
      )} below average rate)`;
    } else if (paymentDetails.avgCarrierRateDiff > 0) {
      return `$${paymentDetails.price.toFixed(2)} ($${Math.round(paymentDetails.avgCarrierRateDiff).toFixed(
        2
      )} above average rate)`;
    } else {
      return `$${paymentDetails.price.toFixed(2)} (average rate)`;
    }
  }

  getMarginText(paymentDetails: any) {
    return `$${(paymentDetails?.revenue - paymentDetails?.price).toFixed(2)} (${Math.round(
      ((paymentDetails?.revenue - paymentDetails?.price) / paymentDetails?.revenue) * 100
    )}%)`;
  }
}
