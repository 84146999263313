import { User } from '..';
import { SubscriptionAction } from '../../subscriptions';
import { DomainEntity } from './domain-entity';

export interface SubscriptionEvent {
  id?: string;
  eventType?: SubscriptionAction;
  entityId?: string;
  entityType?: DomainEntity;
  email?: string;
  queryName?: string;
  createdAt?: number;
  updatedAt?: number;
  user: User;
  expiredAt?: number;
}

export interface SubscriptionEventFilterParams {
  id?: string;
  email?: string;
  queryName?: string;
  eventType?: SubscriptionAction;
  entityId?: string;
  entityType?: DomainEntity;
  ids?: string[];
  emails?: string[];
  queryNames?: string[];
  eventTypes?: SubscriptionAction[];
  entityTypes?: DomainEntity[];
  entityIds?: string[];
}
