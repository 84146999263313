import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CommonEntitiesEffects } from '../../shared/common-entities/common-entities.effects';
import { loadLocationStateProviders, LOAD_LOCATION_ENTITY_REDUCERS_TOKEN } from './config';
import { loadLocationEntityNamespace } from './namespace';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(loadLocationEntityNamespace, LOAD_LOCATION_ENTITY_REDUCERS_TOKEN),
    EffectsModule.forFeature([CommonEntitiesEffects]),
  ],
  providers: [...loadLocationStateProviders],
})
export class AsyncEntityLoadLocationStoreModule {}
