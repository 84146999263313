<h3 class="save-search-view__title">Save new view</h3>

<ng-container *ngIf="step === steps.FORM; else summary">
  <div mat-dialog-content class="save-search-view__content">
    Enter name for new view

    <form [formGroup]="form" (ngSubmit)="goToStep(steps.SUMMARY)">
      <mat-form-field hideRequiredMarker class="app-form-field" appearance="fill" floatLabel="always">
        <input matInput formControlName="name" autocomplete="off" placeholder="ex: My custom view" maxlength="40" />
        <mat-hint align="end">{{ form.controls?.name?.value?.length }} / 40</mat-hint>
        <mat-hint align="left">
          <mat-error *ngIf="form?.hasError('invalid')"> "{{ form.controls?.name?.value }}" is already taken</mat-error>
        </mat-hint>
      </mat-form-field>
    </form>
  </div>

  <div mat-dialog-actions class="save-search-view__actions">
    <button
      mat-button
      mat-dialog-close
      class="hlx-button-command hlx-button-command--cancel hlx-button-command--border-white save-search-view__button"
      data-test="cancel-button"
    >
      Cancel
    </button>
    <button
      mat-button
      type="submit"
      [disabled]="form.invalid"
      (click)="goToStep(steps.SUMMARY)"
      class="hlx-button-command hlx-button-command--book save-search-view__button save-search-view__button--save"
      data-test="next-button"
    >
      Next
    </button>
  </div>
</ng-container>

<ng-template #summary>
  <div mat-dialog-content class="save-search-view__content">
    <div class="save-search-view__subtitle">Summary</div>

    <div class="save-search-view__section">
      <div class="save-search-view__section__title">Name</div>
      <div class="save-search-view__section__content">
        {{ this.form.get('name').value }}
      </div>
    </div>

    <div class="save-search-view__section">
      <div class="save-search-view__section__title">Filters</div>

      <div class="save-search-view__section__content">
        <app-search-chip-list
          [selectedFilters]="searchFilters"
          [form]="searchForm"
          [displayCloseButton]="false"
          [displayClearAllButton]="false"
          [showDaysDifference]="showDaysDifference"
        >
        </app-search-chip-list>
      </div>

      <div class="save-search-view__section__hint">
        <ng-container *ngIf="selectedSidebarItemParent; else noParent">
          The view will be listed under the <span>{{ selectedSidebarItemParent.label }}</span> section
        </ng-container>
        <ng-template #noParent> The view will be listed in the main section </ng-template>
      </div>
    </div>

    <div class="save-search-view__section">
      <div class="save-search-view__section__title">Columns</div>
      <div class="save-search-view__section__content">
        <div *ngFor="let column of columnsToShow">
          {{ column.label | stripHtml }}
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="save-search-view__actions">
    <button
      mat-button
      (click)="goToStep(steps.FORM)"
      class="hlx-button-command hlx-button-command--cancel hlx-button-command--border-white save-search-view__button"
      data-test="back-button"
    >
      Back
    </button>

    <button
      mat-button
      mat-dialog-close
      class="hlx-button-command hlx-button-command--cancel hlx-button-command--border-white save-search-view__button"
      data-test="cancel-button"
    >
      Cancel
    </button>

    <button
      mat-button
      (click)="onSubmitSearchView()"
      class="hlx-button-command hlx-button-command--book save-search-view__button save-search-view__button--save"
      data-test="submit-button"
    >
      Submit
    </button>
  </div>
</ng-template>
