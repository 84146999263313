import gql from 'graphql-tag';

export const CreateNeptuneTruckMutation = gql(`
  mutation createTruck($newTruck: TruckNeptuneInput!) {
    createTruck(newTruck: $newTruck)
  }
`);

export const CreateEldTruckMutation = gql`
  mutation createTruckEld($newTruck: TruckInput!, $userId: ID) {
    createTruckEld(newTruck: $newTruck, userId: $userId)
  }
`;
