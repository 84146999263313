import { Injectable } from '@angular/core';
import { SearchTrucksQuery } from '@haulynx/gql';
import { IPostTruck, PageAndSort, QueryOptionsFetchPolicy } from '@haulynx/types';
import { get } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GraphqlService } from '../graphql.service';

@Injectable({
  providedIn: 'root',
})
export class SearchTrucksService {
  constructor(private graphqlService: GraphqlService) {}

  searchTrucks(
    variables: { dot: string; paging: PageAndSort },
    options?: QueryOptionsFetchPolicy
  ): Observable<{ result: IPostTruck[]; total: number }> {
    const defaultPaging: PageAndSort = { limit: 10, page: 1, sort: 'ASC' };

    return this.graphqlService
      .query({
        query: SearchTrucksQuery,
        variables: {
          dot: variables.dot,
          paging: { ...defaultPaging, ...variables.paging },
        },
        ...options,
      })
      .pipe(
        map((result) => {
          return {
            result: get(result, 'data.getTrucksByCarrierDot.result', []) as IPostTruck[],
            total: get(result, 'data.getTrucksByCarrierDot.total', 0) as number,
          };
        })
      );
  }
}
