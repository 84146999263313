import { Injectable } from '@angular/core';
import { LoadIdentifierType, LoadsServiceLoad, LoadsServiceLoadLocation } from '@haulynx/types';
import { AddressPosition, regionByEmail, splitAddress } from '@haulynx/utils';
import { get, head, last } from 'lodash';
import { MomentService } from '../../app-services/generic/moment/moment.service';

@Injectable({
  providedIn: 'root',
})
export class ActiveLoadsTableService {
  constructor(private momentService: MomentService) {}

  customSort(load: LoadsServiceLoad, column: string) {
    switch (column) {
      case 'status': {
        return load.loadStatus;
      }
      case 'tmwNumber': {
        return load.providerDetails.alternateIds.find((ids) => {
          if (ids.identifierType === LoadIdentifierType.TMW_NUMBER) {
            return ids;
          }
        }).identifierValue;
      }
      case 'origin': {
        const origin = get(head(load.locations), 'address', null);
        return splitAddress(origin, AddressPosition.CITY);
      }
      case 'originState': {
        const origin = get(head(load.locations), 'address', null);
        return splitAddress(origin, AddressPosition.STATE);
      }
      case 'pickupDate': {
        return this.transformLoadLocationDate(head(load.locations), 'D MMM YYYY');
      }
      case 'pickupTime': {
        return this.transformLoadLocationDate(head(load.locations), 'HH/mm');
      }
      case 'pickupType': {
        return load?.providerDetails?.equipmentType;
      }
      case 'destination': {
        const origin = get(last(load.locations), 'address', null);
        return splitAddress(origin, AddressPosition.CITY);
      }
      case 'destinationState': {
        const origin = get(last(load.locations), 'address', null);
        return splitAddress(origin, AddressPosition.STATE);
      }
      case 'deliveryDate': {
        return this.transformLoadLocationDate(last(load.locations), 'D MMM YYYY');
      }
      case 'deliveryTime': {
        return this.transformLoadLocationDate(last(load.locations), 'HH/mm');
      }
      case 'deliveryType': {
        return load?.providerDetails?.equipmentType;
      }
      case 'carrier': {
        return get(load, 'carrier.name', 'N/A');
      }
      case 'customer': {
        return get(load, 'provider.id', 'N/A');
      }
      case 'region': {
        const email = get(load, 'providerDetails?regionalManagerEmail', null);
        return regionByEmail(email);
      }
      case 'broker': {
        return get(load, 'broker.name', 'N/A');
      }
    }
  }

  private transformLoadLocationDate(location: LoadsServiceLoadLocation, format: string = 'D MMM YYYY h:mma') {
    const { appointmentStart = null, timezone = null } = location || {};

    return this.momentService.getDateFormat(appointmentStart, format, timezone);
  }
}
