import { LngLatLike } from 'mapbox-gl';
import { LoadWayPoint } from './load-way-point';
import { MarkerStyles } from './marker-styles';

export interface LoadRouteSource {
  id: string;
  coordinates: LngLatLike[];
  wayPoints: LoadWayPoint[];
  truckPosition?: LngLatLike;
  lineColor: string;
  lineWidth: number;
  markerColor: string;
  carrierId: string;
  carrierAccepted: boolean;
  markerStyle?: MarkerStyles;
}
