export enum AccountRoute {
  CREATE = 'account/create',
  LOGIN = 'account/login',
  RESET_PASSWORD = 'account/reset-password',
  TERMS_OF_SERVICE = 'account/terms-of-service',
  VERIFY = 'account/verify',

  // Carrier Routes
  CARRIER = 'account/carrier',
  FIND_CARRIER = 'account/carrier/find',
  CHOOSE_ROLE = 'account/carrier/role',
  EXISTS = 'account/carrier/exists',
  SORRY = 'account/carrier/sorry',
  CONTACT = 'account/carrier/contact',
  FINISH = 'account/carrier/finish',
}
