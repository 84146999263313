import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'delete-user-dialog',
  template: `
    <button mat-icon-button class="close" [matDialogClose]>
      <mat-icon>close</mat-icon>
    </button>
    <h5 matDialogTitle>DELETE USER</h5>
    <mat-dialog-content>Are you sure you want to delete User? (All user's data will be lost)</mat-dialog-content>
    <mat-dialog-actions>
      <button mat-flat-button color="accent" [matDialogClose]="true">DELETE</button>
    </mat-dialog-actions>
  `,
})
export class DeleteUserDialog {
  constructor(public dialogRef: MatDialogRef<DeleteUserDialog>) {}
}
