import { ChangeDetectionStrategy, Component, Injector, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessorConnectorComponent } from '@haulynx/utils';

@Component({
  selector: 'haulynx-blue-input',
  templateUrl: './blue-input.component.html',
  styleUrls: ['./blue-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: BlueInputComponent,
      multi: true,
    },
  ],
})
export class BlueInputComponent extends ControlValueAccessorConnectorComponent {
  @Input() type = 'text';
  @Input() label = 'Label';
  @Input() required = false;
  @Input() prefix: string = null;
  @Input() maxlength: number = null;
  @Input() minlength: number = null;

  constructor(injector: Injector) {
    super(injector);
  }
}
