export interface DividerSliderLimitMinMax {
  min: string;
  max: string;
}

export interface DividerSliderLimits {
  left?: DividerSliderLimitMinMax;
  right?: DividerSliderLimitMinMax;
  top?: DividerSliderLimitMinMax;
  bottom?: DividerSliderLimitMinMax;
}
