import { LoadsServiceLoadLocation, LoadsServiceLoad } from '../../loads-service';
import { isObject, has } from 'vanillas';

/**
 * Iterates over the load locations in order of occurrence, filtering out
 * any without a valid `locationType` (which should be pickup or dropoff)
 *
 * @function
 * @name pruneLoadsServiceLocations
 * @param {Array<LoadsServiceLoadLocation>} locations A list of (unsorted)  LoadsServiceLoadLocation objects
 * @returns {Array<LoadsServiceLoadLocation>} A list of filtered LoadsServiceLoadLocations
 */
export function pruneLoadsServiceLocations(locations: LoadsServiceLoadLocation[] = []): LoadsServiceLoadLocation[] {
  return locations.filter((ll) => ll && /^(pickup|dropoff)$/.test(ll.locationType));
}

/**
 * Parses the load locations from a source object (which may be the load itself).
 *
 * @function
 * @name parseLoadsServiceLocations
 * @param {LoadsServiceLoad|Array<LoadsServiceLoadLocation>} obj A source for the loads service locations (could also be the load itself)
 * @returns {Array<LoadsServiceLoadLocation>} The parsed list of the loads service locations
 */
export function parseLoadsServiceLocations(
  obj: LoadsServiceLoad | LoadsServiceLoadLocation[]
): LoadsServiceLoadLocation[] {
  if (obj == null) return undefined;
  if (Array.isArray(obj)) return pruneLoadsServiceLocations(obj);
  if (isObject(obj)) {
    if (has('locations', obj)) {
      return pruneLoadsServiceLocations(Array.isArray(obj.locations) ? obj.locations : Object.values(obj.locations));
    }
    // TODO: could use a typeguard
    return pruneLoadsServiceLocations(Object.values(obj));
  }
  return undefined;
}
