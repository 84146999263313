import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LoadRestrictionsPipe } from '@haulynx/pipes';
import { WindowRef } from '@haulynx/services';
import { AppModel, BookLoadModel, LoadActiveTabsModel, UserEntityService } from '@haulynx/store';
import {
  BookLoadHttpStatus,
  BookStatus,
  counterClock,
  counterTemplate,
  FeatureFlag,
  FFState,
  LoadOrderType,
  LoadsServiceLoad,
  LoadsServiceRestrictionTypes,
  Tab,
  User,
} from '@haulynx/types';
import { aliveWhile, isLoadsServiceLoadExclusive } from '@haulynx/utils';
import { get, head, last, truncate } from 'lodash';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, first, map, take, takeUntil } from 'rxjs/operators';
import { AssignLoadCarrierContainerComponent } from '../../../assign-load-carrier-container/assign-load-carrier-container.component';
import { LaunchRmisDialog } from '../../../dialogs/launch-rmis/launch-rmis.component';
import { ConfirmBookingComponent } from '../confirm-booking/confirm-booking.component';
import { HazmatWarningComponent } from '../hazmat-warning/hazmat-warning.component';

@Component({
  selector: 'app-book-load-button',
  templateUrl: './book-load-button.component.html',
  styleUrls: ['./book-load-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookLoadButtonComponent implements OnInit, OnChanges, OnDestroy {
  @Input() load: LoadsServiceLoad = null;
  @Input() buttonText = 'Book it now';
  @Input() showNonBookable = true;
  @Input() showPrice = false;
  @Input() bookableWithPrice = true;
  @Input() showDecline = false;
  @Input() isStatusCentered = false;
  @Input() carrierDot: string; // we can read from user state if not provided
  @Input() linkroute;
  @Output() bookSuccess = new EventEmitter<string>(); // sends the load id
  @Output() offerDeleteSuccessEvent = new EventEmitter<LoadsServiceLoad>();

  alive = aliveWhile();
  isBrokerOrAdmin = true;
  isBroker = false;
  isExclusive = false;
  timerFinished = false;
  isLoading$: Observable<boolean>;
  isBookable$: Observable<boolean>;
  counterTemplate = counterTemplate;
  counterClock = counterClock;
  features;
  declineButtonText = 'Decline';
  loadsServiceRestrictionTypes = LoadsServiceRestrictionTypes;
  bookStatus = BookStatus;

  constructor(
    private dialog: MatDialog,
    public bookLoadModel: BookLoadModel,
    public appModel: AppModel,
    private changeDetect: ChangeDetectorRef,
    private windowRef: WindowRef,
    private router: Router,
    private loadActiveTabsModel: LoadActiveTabsModel,
    private userEntityService: UserEntityService,
    public loadRestrictionsPipe: LoadRestrictionsPipe
  ) {}

  ngOnInit(): void {
    this.userEntityService.featureFlags$.pipe(takeUntil(this.alive)).subscribe((feature: FFState) => {
      this.features = feature;
    });

    combineLatest([this.appModel.userBroker$, this.appModel.isHaulynxAdmin$, this.appModel.userCarrier$])
      .pipe(first())
      .subscribe(([userBroker, isHaulynxAdmin, userCarrier]) => {
        this.isBrokerOrAdmin = !!userBroker || isHaulynxAdmin;
        this.isBroker = !!userBroker;
        const isCarrierOrUnAuthenticated: boolean = !!userCarrier || !this.isBrokerOrAdmin;

        if (this.doRunBrokeredCheck(isCarrierOrUnAuthenticated)) {
          this.bookLoadModel.getBrokerBookedLoads({
            loadId: this.load.id,
            carrierDot: (userCarrier && userCarrier.dot) || this.carrierDot,
          });
        }
      });

    this.isLoading$ = this.bookLoadModel.isLoading$.pipe(
      map((loadingQueue) => loadingQueue.has(get(this.load, 'id'))),
      distinctUntilChanged()
    );

    this.bookLoadModel.httpStatus$
      .pipe(
        takeUntil(this.alive),
        map((httpMap) => httpMap.get(get(this.load, 'id'))),
        filter((httpStatus) => httpStatus === BookLoadHttpStatus.SUCCESS)
      )
      .subscribe(() => {
        this.bookSuccess.emit(get(this.load, 'id'));
      });

    this.isBookable$ = this.bookLoadModel.isBookableLoad$.pipe(
      map(
        (isBookableLoad) =>
          !!isBookableLoad.get(get(this.load, 'id')) &&
          this.load?.providerDetails?.orderType !== LoadOrderType.DEDICATED
      ),
      distinctUntilChanged()
    );
  }

  onTimerFinished(): void {
    this.timerFinished = true;
    this.changeDetect.markForCheck();
  }

  openConfirm(event: MouseEvent): void {
    event.stopPropagation();

    combineLatest([this.appModel.user$, this.appModel.brokerId$, this.appModel.showRmisBanner$])
      .pipe(first())
      .subscribe(([user, brokerId, showRmisBanner]: [User, string, boolean]) => {
        const dot = get(user, 'carrier.dot', this.carrierDot);
        if (showRmisBanner) return this.openRmisDialog();
        if (this.isBroker) {
          const data = {
            brokerId,
            carrierDot: this.carrierDot,
            loadId: get(this.load, 'id', null),
          };
          this.openAssignCarrierForm(data);
        } else {
          this.openConfirmAndCheckHazmatRequired(dot);
        }
      });
  }

  openDecline(event: MouseEvent): void {
    event.stopPropagation();

    this.offerDeleteSuccessEvent.emit(this.load);
  }

  openConfirmAndCheckHazmatRequired(dot: string): void {
    if (this.loadRestrictionsPipe.transform(this.load.restrictions, this.loadsServiceRestrictionTypes.HAZMAT)) {
      const dialogRef = this.dialog.open(HazmatWarningComponent);
      dialogRef.afterClosed().subscribe((confirm: boolean) => {
        if (confirm) {
          this.showConfirmBookingDialog(dot, this.load);
        }
      });
    } else {
      this.showConfirmBookingDialog(dot, this.load);
    }
  }

  openAssignCarrierForm(data: { carrierDot: string; loadId: string; brokerId: string }): void {
    if (this.features[FeatureFlag.LOAD_OVERVIEW]) {
      const queryParams = { dot: data.carrierDot, carrierDashboard: this.carrierDot || null };
      const url = this.router.serializeUrl(
        this.router.createUrlTree([`loads/${data.loadId}/overview/booking`], { queryParams })
      );
      this.windowRef.getNativeWindow().open(url, '_blank');
    } else {
      const dialogRef = this.dialog.open(AssignLoadCarrierContainerComponent, { data });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.bookSuccess.emit(this.load.id);
        }
      });
    }
  }

  showConfirmBookingDialog(carrierDot: string, load: LoadsServiceLoad): void {
    const dialogRef = this.dialog.open(ConfirmBookingComponent, {
      data: {
        carrierDot,
        load,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const payload = { load, carrierDot };
        this.bookLoadModel.bookLoad(payload);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { load } = changes;

    if (load.currentValue) {
      this.isExclusive = isLoadsServiceLoadExclusive(load.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }

  openRmisDialog(): void {
    this.dialog
      .open(LaunchRmisDialog)
      .afterClosed()
      .subscribe((response: boolean) => {
        if (!response) return;

        this.userEntityService.rmisTokenManager.data$.pipe(take(1)).subscribe((response: { url: string }) => {
          this.windowRef.getNativeWindow().open(response.url, '_blank');
        });

        this.userEntityService.rmisTokenManager.dispatch();
      });
  }

  viewLoad(): void {
    const pickUp = head(this.load.locations);
    const dropOff = last(this.load.locations);
    const tab = new Tab({
      id: `${this.load.id}`,
      label: `${truncate(pickUp.address, { length: 10 })} -> ${truncate(dropOff.address, { length: 10 })}`,
      url: `details/${this.load.id}`,
      order: null,
      selected: false,
      closable: true,
    });

    this.loadActiveTabsModel.addTabs([tab]);
    this.loadActiveTabsModel.selectTab(tab);
    this.router.navigateByUrl(`dashboard/loads/search/active/details/${this.load.id}`);
  }

  private doRunBrokeredCheck(isCarrier: boolean): boolean {
    return (
      isCarrier &&
      this.showNonBookable &&
      this.load &&
      (this.load.bookStatus === BookStatus.VIEWABLE || this.load.bookStatus === BookStatus.EXCLUDED)
    );
  }
}
