import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppModel, LoadDetailsModel, LoadEntityService } from '@haulynx/store';
import { Carrier, LoadRouteSource, LoadsServiceLoad, User } from '@haulynx/types';
import { aliveWhile, getLoadsServiceLoadRoute } from '@haulynx/utils';
import { get } from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, first, map, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'app-lf-details-shared',
  templateUrl: './load-feed-details-shared.component.html',
  styleUrls: ['./load-feed-details-shared.component.scss'],
})
export class LoadFeedDetailsSharedComponent {
  loadFeed$ = new BehaviorSubject<LoadsServiceLoad>(null);
  loadFeedRoute$: Observable<LoadRouteSource[]>;
  isCarrier: boolean;
  carrierId: string;
  carrierDot: string;
  private alive = aliveWhile();
  private carrierDot$: Observable<string[]>;

  constructor(
    private appModel: AppModel,
    private activatedRoute: ActivatedRoute,
    private loadEntityService: LoadEntityService,
    private loadDetailsModel: LoadDetailsModel
  ) {
    this.activatedRoute.data
      .pipe(
        map((data: { loadFeed: LoadsServiceLoad }) => new LoadsServiceLoad(data.loadFeed)),
        first()
      )
      .subscribe((load: LoadsServiceLoad) => {
        this.loadFeed$.next(load);
      });

    this.carrierDot$ = this.activatedRoute.queryParams.pipe(
      filter((params) => !!params.carrierId || !!params.carrierDot),
      map((params) => {
        return [params.carrierId, params.carrierDot];
      })
    );

    this.loadFeedRoute$ = this.loadDetailsModel.routes$.pipe(
      withLatestFrom(this.loadFeed$, this.carrierDot$, this.appModel.userCarrier$),
      switchMap(
        ([routeSource, loadFeed, carrierIds, userCarrier]: [
          LoadRouteSource[],
          LoadsServiceLoad,
          string[],
          Carrier
        ]) => {
          const [carrierId = userCarrier.id, carrierDot] = carrierIds || [];
          this.carrierId = carrierId;
          this.carrierDot = carrierDot;
          const routes = getLoadsServiceLoadRoute(routeSource, loadFeed);
          const loadFeedRoute = routes.map((route) => {
            return {
              ...route,
              carrierId: this.carrierId || null,
            };
          });

          return of(loadFeedRoute);
        }
      ),
      takeUntil(this.alive)
    );

    this.appModel.user$.pipe().subscribe((user: User) => {
      this.isCarrier = !!get(user, 'carrier', null);
    });
  }

  bookSuccess(loadId: string) {
    this.loadEntityService.getLoadByIdManager.dispatch(loadId);
    this.loadEntityService.getLoadByIdManager
      .getEntityById(loadId)
      .pipe(first())
      .subscribe((load: LoadsServiceLoad) => {
        this.loadFeed$.next(load);
      });
  }
}
