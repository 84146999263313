import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

enum ScoreClass {
  SATISFACTORY = 'satisfactory',
  UNSATISFACTORY = 'unsatisfactory',
  CONDITIONAL = 'conditional',
  NONE = 'none',
}

const getScoreClass = (score): ScoreClass => {
  if (!score) {
    return ScoreClass.NONE;
  }
  switch (score.toLowerCase()) {
    case 'satisfactory':
      return ScoreClass.SATISFACTORY;
    case 'unsatisfactory':
      return ScoreClass.UNSATISFACTORY;
    case 'conditional':
      return ScoreClass.CONDITIONAL;
    case 'none':
      return ScoreClass.NONE;
  }
};

@Component({
  selector: 'app-carrier-safety-rating',
  templateUrl: './carrier-safety-rating.component.html',
  styleUrls: ['./carrier-safety-rating.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarrierSafetyRatingComponent implements OnInit {
  @Input() score: string;
  @Input() isLoading: boolean;

  scoreClass = '';
  constructor() {}

  ngOnInit() {
    this.scoreClass = getScoreClass(this.score);
  }
}
