import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, NgZone, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faIR } from 'date-fns/locale';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'haulynx-contracts-form',
  templateUrl: './contracts-form.component.html',
  styleUrls: ['./contracts-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContractsFormComponent implements OnInit {
  public form = this.fb.group({
    billto: new FormControl(),
    shipper: new FormControl(),
    consignee: new FormControl(),
    broker: new FormControl(),
    carrier: new FormControl(),
    carrierRate: new FormControl(),
    lineHaulPrice: new FormControl(),
    lineHaulBasis: new FormControl(),
    fuelPrice: new FormControl(),
    fuelBasis: new FormControl(),
    stopPrice: new FormControl(),
    stopBasis: new FormControl(),
  });

  // if being edited
  isEdit = new BehaviorSubject<boolean>(false);

  apptReqShipper = false;
  apptReqConsignee = false;
  lineHaulBasisData = [
    { label: 'LHFLT', value: 'LHFLT' },
    { label: 'LHMLS', value: 'LHMLS' },
  ];
  fuelBasisData = [
    { label: '-', value: null },
    { label: 'FUELMI', value: 'FUELMI' },
  ];
  stopBasisData = [
    { label: '-', value: null },
    { label: 'SPAY', value: 'SPAY' },
  ];

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ContractsFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (this.data && this.data?.contract) {
      const { contract } = this.data;

      this.form.setValue({
        billto: { value: contract.billTo?.primary },
        shipper: { value: contract.customer0?.primary },
        consignee: { value: contract.customer1?.primary },
        broker: contract.brokerOwner?.primary,
        carrier: contract.carrierDOT?.primary,
        carrierRate: contract.price?.primary
          ? parseFloat(contract.price?.primary?.replace(/[$,]/g, ''))?.toFixed(2)
          : null,
        lineHaulPrice: contract.lineHaulPrice?.primary
          ? parseFloat(contract.lineHaulPrice?.primary?.replace(/[$,]/g, ''))?.toFixed(2)
          : null,
        lineHaulBasis: contract.lineHaulPrice?.secondary,
        fuelPrice: contract.fuelPrice?.primary
          ? parseFloat(contract.fuelPrice?.primary?.replace(/[$,]/g, '')).toFixed(2)
          : null,
        fuelBasis: contract.fuelPrice?.secondary,
        stopPrice: contract.stopPrice?.primary
          ? parseFloat(contract.stopPrice?.primary?.replace(/[$,]/g, ''))?.toFixed(2)
          : null,
        stopBasis: contract.stopPrice?.secondary,
      });

      this.apptReqShipper = contract.appointmentSetRequiredCustomer0?.primary === 'YES' ? true : false;
      this.apptReqConsignee = contract.appointmentSetRequiredCustomer1?.primary === 'YES' ? true : false;
      this.isEdit.next(true);
    }

    this.form.setValidators(this.priceRequirementValidator());

    this.form.markAllAsTouched();

    // Update values and validity
    this.form.updateValueAndValidity();
  }

  mutateContract() {
    const combined = {
      ...this.form.getRawValue(),
      appointmentSetRequiredCustomer0: this.apptReqShipper,
      appointmentSetRequiredCustomer1: this.apptReqConsignee,
    };
    this.dialogRef.close({ combined, isEdit: this.isEdit });
  }

  cancelClicked() {
    this.dialogRef.close();
  }

  priceRequirementValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const carrierRate = control.get('carrierRate')?.value;
      const lineHaulPrice = control.get('lineHaulPrice')?.value;
      const fuelPrice = control.get('fuelPrice')?.value;

      // Check if the fields are provided
      const isCarrierRateProvided = carrierRate !== null && carrierRate !== undefined && carrierRate !== '';
      const areLineHaulAndFuelPricesProvided =
        lineHaulPrice !== null &&
        lineHaulPrice !== undefined &&
        lineHaulPrice !== '' &&
        fuelPrice !== null &&
        fuelPrice !== undefined &&
        fuelPrice !== '';

      // Valid if either carrierRate is provided or both lineHaulPrice and fuelPrice are provided
      const isValid = isCarrierRateProvided || areLineHaulAndFuelPricesProvided;

      // Set or clear errors on individual controls
      control.get('carrierRate').setErrors(isValid ? null : { invalidPriceRequirement: true });
      control.get('lineHaulPrice').setErrors(isValid ? null : { invalidPriceRequirement: true });
      control.get('fuelPrice').setErrors(isValid ? null : { invalidPriceRequirement: true });

      setTimeout(() => this.cd.detectChanges());
      // Set error on form group if invalid
      return isValid ? null : { invalidPriceRequirement: true };
    };
  }
}
