import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { forEach } from 'lodash';
import { AppState } from '../../main-store/app.reducers';
import { entitiesOptions, EntitiesOptionsModel } from './common-entities.options';
import { CommonEntitiesModel } from './common-entities.utils';

@Injectable({
  providedIn: 'root',
})
export class CommonEntities extends EntitiesOptionsModel {
  constructor(private store: Store<AppState>) {
    super();

    forEach(entitiesOptions, (storeName, entityName) => {
      this[entityName] = new CommonEntitiesModel(this.store, { storeName, entityName });
    });
  }
}
