import { createAction, createActionTypes } from '../helpers/action';

export interface ITabsActionTypes {
  ADD_TAB: string;
  UPDATE_TAB: string;
  REMOVE_TAB: string;
  SELECT_TAB: string;
}

export interface ITabsActions {
  addTabs(params?);
  updateTabs(params?);
  removeTab(params?);
  selectTab(params?);
}

export const tabsActionType: ITabsActionTypes = {
  ADD_TAB: 'add tabs',
  UPDATE_TAB: 'update tabs',
  REMOVE_TAB: 'remove tabs',
  SELECT_TAB: 'select tab',
};

export const createTabsActionTypes = createActionTypes(tabsActionType);

export function makeTabActions(type): ITabsActions {
  const addTabs = createAction(type, tabsActionType.ADD_TAB);
  const updateTabs = createAction(type, tabsActionType.UPDATE_TAB);
  const removeTab = createAction(type, tabsActionType.REMOVE_TAB);
  const selectTab = createAction(type, tabsActionType.SELECT_TAB);

  return {
    addTabs,
    updateTabs,
    removeTab,
    selectTab,
  };
}

export function createActions(type) {
  return {
    getActionTypes() {
      return createTabsActionTypes(type);
    },
    getActions(): ITabsActions {
      return makeTabActions(type);
    },
  };
}
