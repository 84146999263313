import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

export interface ITooltipMenuItemsComponent {
  value: string;
  label: string;
  action: string;
}

@Component({
  selector: 'tooltip-menu',
  templateUrl: './tooltip-menu.component.html',
  styleUrls: ['./tooltip-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipMenuComponent implements OnChanges {
  @Input() menuItems: ITooltipMenuItemsComponent[] | any[] = [];
  @Output() onClick = new EventEmitter<string>();
  @Input() label = '...';
  @Input() actionEmail = null;
  @Input() actionPhone = null;

  constructor() {}

  execute(action, event: MouseEvent) {
    event.stopPropagation();
    this.onClick.emit(action);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { actionEmail, actionPhone } = changes;

    if (actionEmail && actionEmail.currentValue) {
      this.menuItems = [
        ...this.menuItems,
        {
          action: 'email',
          label: actionEmail.currentValue,
          value: actionEmail.currentValue,
        },
      ];
    }

    if (actionPhone && actionPhone.currentValue) {
      this.menuItems = [
        ...this.menuItems,
        {
          action: 'phone',
          label: actionPhone.currentValue,
          value: actionPhone.currentValue,
        },
      ];
    }
  }
}
