<div class="tooltip-menu">
  <button mat-button [color]="'accent'" [matMenuTriggerFor]="mainTooltipMenu" (click)="$event.stopPropagation()">
    {{ label }}
  </button>

  <mat-menu #mainTooltipMenu="matMenu">
    <div *ngFor="let item of menuItems" [ngSwitch]="item.action">
      <a
        *ngSwitchCase="'phone'"
        class="tooltip-item"
        href="tel:{{ item.value }}"
        (click)="execute(item.action, $event)"
        mat-menu-item
      >
        <mat-icon svgIcon="haulynx-phone-red"></mat-icon>
        {{ item.label }}
      </a>

      <a *ngSwitchCase="'email'" class="tooltip-item" href="mailto:{{ item.value }}" mat-menu-item>
        <mat-icon svgIcon="haulynx-envelope-red"></mat-icon>
        {{ item.label }}
      </a>

      <button class="tooltip-item" *ngSwitchDefault (click)="execute(item.action, $event)" mat-menu-item>
        <span>{{ item.label }}</span>
      </button>
    </div>
  </mat-menu>
</div>
