import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ISearchFilter } from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
})
export class SearchInputComponent implements OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('searchInput', { static: false }) searchInput: ElementRef;

  @Input() filters: ISearchFilter[];
  @Input() mainInputForm: string;
  @Input() focus: boolean;
  @Input() title: string;
  @Output() searchFormChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() searchInputFocus: EventEmitter<boolean> = new EventEmitter<boolean>();

  inputForm: FormGroup;
  alive = aliveWhile();

  constructor(private fb: FormBuilder) {
    this.inputForm = this.fb.group({
      searchTypeSearch: [''],
    });

    this.inputForm.valueChanges.pipe(takeUntil(this.alive)).subscribe(() => {
      if (this.searchInputFormValue !== '') {
        this.searchInputFocus.emit(true);
        this.searchFormChanged.emit(this.searchInputFormValue);
      } else {
        this.searchInputFocus.emit(false);
      }
    });
  }

  get searchInputFormValue(): string {
    return this.inputForm.get('searchTypeSearch').value;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.mainInputForm) {
      this.inputForm.patchValue({
        searchTypeSearch: this.mainInputForm,
      });
    } else if (changes.focus) {
      this.searchInput.nativeElement.focus();
    }
  }

  ngAfterViewInit(): void {
    // this.searchInput.nativeElement.focus();
  }

  onFocus(): void {
    if (this.searchInputFormValue !== '') {
      this.searchInputFocus.emit(true);
    }
  }

  onBlur(): void {
    this.searchInputFocus.emit(false);
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }
}
