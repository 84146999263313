import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DirectivesModule } from '@haulynx/directives';
import { MaterialModule } from '@haulynx/modules';
import { ExpandPanelComponent } from './expand-panel.component';

@NgModule({
  imports: [CommonModule, MaterialModule, DirectivesModule],
  exports: [ExpandPanelComponent],
  declarations: [ExpandPanelComponent],
})
export class ExpandPanelModule {}
