<div class="lane-rate-tool-timeline-container">
  <div class="lst-origin">
    <div class="lst-origin-arrow">
      <div class="lst-oa-background">
        <div class="lst-oa-foreground">
          <mat-icon>north</mat-icon>
        </div>
      </div>
    </div>
    <div class="lst-origin-information" *ngIf="lane?.locations | nth: 0 as pickup">
      <div class="lst-origin-location">{{ pickup.city }}, {{ pickup.state }} {{ pickup.zip }}</div>
      OCEN: {{ pickup.centroidDistance }}
    </div>
  </div>
  <div class="lst-distination-pointer">
    <mat-icon>east</mat-icon>
  </div>
  <div class="lst-destination">
    <div class="lst-destination-arrow">
      <div class="lst-da-background">
        <div class="lst-da-foreground">
          <mat-icon>south</mat-icon>
        </div>
      </div>
    </div>
    <div class="lst-destination-information" *ngIf="lane?.locations | nth: -1 as dropoff">
      <div class="lst-destination-location">{{ dropoff.city }}, {{ dropoff.state }} {{ dropoff.zip }}</div>
      DCEN: {{ dropoff.centroidDistance }}
    </div>
  </div>
</div>
