import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@haulynx/directives';
import { MaterialModule } from '@haulynx/modules';
import { TabDirective } from '../../tab-directive/tab-directive';
import { NgxCurrencyModule } from 'ngx-currency';
import { AddPaymentTypeComponent } from './add-payment-type.component';

@NgModule({
  declarations: [AddPaymentTypeComponent, TabDirective],
  imports: [CommonModule, ReactiveFormsModule, MaterialModule, DirectivesModule, NgxCurrencyModule],
  exports: [AddPaymentTypeComponent],
  providers: [CurrencyPipe],
})
export class AddPaymentTypeModule {}
