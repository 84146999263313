import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AssignLoadCarrierForm,
  Bid,
  BidHistory,
  BidSortTypes,
  BidStatus,
  BidStatusOption,
  BidStatusType,
  CounterOfferInput,
  Customer,
  LoadsServiceLoad,
  Tab,
  UpdateBidInput,
  UserType,
  UserTypes,
} from '@haulynx/types';
import { listToArray } from '@haulynx/utils';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { List, Map } from 'immutable';
import { differenceBy, get } from 'lodash';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { AppState } from '../../main-store/app.reducers';
import { ISearchModelSelectors, searchModel } from '../../shared/store/search/model';
import {
  BidActions,
  CarrierLoadFeedActions,
  ExclusiveLoadFeedActions,
  LoadFeedActions,
  LoadFeedActionTypes,
} from './load-feed.actions';
import {
  bidHistorySelector,
  bidSearchSelector,
  bidStatusesSelector,
  carrierLoadFeedSearchSelector,
  customersSelector,
  exclusiveLoadFeedSearchSelector,
  isEldCarrierSelector,
  isExpandedExclusiveSelector,
  isLoadingAcceptBidSelector,
  isLoadingAcceptCounterBidSelector,
  isLoadingBidCreateSelector,
  isLoadingBidHistorySelector,
  isLoadingCounterOfferCreateSelector,
  isLoadingCustomerSelector,
  isLoadingSelector,
  loadFeedSearchSelector,
  loadFeedTabsSelector,
  selectUrlSelector,
  sortBidsBySelector,
} from './load-feed.selectors';

@Injectable({
  providedIn: 'root',
})
export class LoadFeedModel {
  exclusiveEntities$: Observable<List<unknown>> = this.store.select(exclusiveLoadFeedSearchSelector.getEntities);
  isExclusiveSearchLoading$: Observable<boolean> = this.store.select(exclusiveLoadFeedSearchSelector.getLoading);

  carrierSearch: ISearchModelSelectors = searchModel(this.store, carrierLoadFeedSearchSelector);

  entities$: Observable<List<unknown>> = this.store.select(loadFeedSearchSelector.getEntities);
  pagination$: Observable<unknown> = this.store.select(loadFeedSearchSelector.getPagination);
  query$: Observable<Map<string, unknown>> = this.store.select(loadFeedSearchSelector.getQuery);
  isSearchLoading$: Observable<boolean> = this.store.select(loadFeedSearchSelector.getLoading);

  loadFeedSearch: ISearchModelSelectors = searchModel(this.store, loadFeedSearchSelector);
  isLoading$: Observable<boolean> = this.store.select(isLoadingSelector);
  tabs$: Observable<Map<string, Tab>> = this.store.select(loadFeedTabsSelector.getTabs);
  tabsSelected$: Observable<unknown> = this.store
    .select(loadFeedTabsSelector.getSelected)
    .pipe(distinctUntilChanged((tab, nextTab) => get(nextTab, 'id') === get(tab, 'id')));
  url$: Observable<unknown> = this.store.select(selectUrlSelector);

  panels = {
    isExpandedExclusive$: this.store.select(isExpandedExclusiveSelector),
  };
  isEldCarrier$ = this.store.select(isEldCarrierSelector);

  bidEntities$: Observable<List<Bid>> = this.store.select(bidSearchSelector.getEntities);
  bidEntitiesList$: Observable<Bid[]> = this.bidEntities$.pipe(map((bidList) => listToArray(bidList)));
  isBidSearchLoading$: Observable<boolean> = this.store.select(bidSearchSelector.getLoading);

  bidStatuses$: Observable<BidStatus[]> = this.store.select(bidStatusesSelector);
  bidStatusOptions$: Observable<BidStatusOption[]> = this.bidStatuses$.pipe(
    map((bidStatuses) => this.normalizeBidStatusOptions(bidStatuses || []))
  );
  bidStatusesCreation$ = this.bidStatuses$.pipe(
    map((bidStatuses) =>
      differenceBy(bidStatuses, [{ id: BidStatusType.ACCEPTED }, { id: BidStatusType.AUTO_REJECTED }], 'id')
    ),
    map((bidStatuses) => this.normalizeBidStatusOptions(bidStatuses || []))
  );
  bidCreationSuccess$ = this.actions$.pipe(ofType(LoadFeedActionTypes.CREATE_BID_SUCCESS));
  bidHistory$: Observable<{ [key: string]: BidHistory[] }> = this.store.select(bidHistorySelector);
  isLoadingBidHistory$: Observable<{ [key: string]: boolean }> = this.store.select(isLoadingBidHistorySelector);

  isLoadingBidCreate$: Observable<boolean> = this.store.select(isLoadingBidCreateSelector);
  isLoadingCounterAccepted$: Observable<boolean> = this.store.select(isLoadingAcceptCounterBidSelector);

  isLoadingAcceptBid$: Observable<{ [key: string]: boolean }> = this.store.select(isLoadingAcceptBidSelector);

  isLoadingCounterOffer$: Observable<{ [key: string]: boolean }> = this.store.select(
    isLoadingCounterOfferCreateSelector
  );
  createCounterOfferSuccess$ = this.actions$.pipe(ofType(LoadFeedActionTypes.CREATE_COUNTER_OFFER_SUCCESS));
  updateBidSuccess$ = this.actions$.pipe(ofType(LoadFeedActionTypes.UPDATE_BID_SUCCESS));

  sortBidsBy$: Observable<{ [key: string]: BidSortTypes }> = this.store.select(sortBidsBySelector);

  onAcceptBid$: Observable<unknown> = this.actions$.pipe(ofType(LoadFeedActionTypes.ACCEPT_BID_SUCCESS));

  isLoadingCustomer$: Observable<{ [key: string]: boolean }> = this.store.select(isLoadingCustomerSelector);
  customers$: Observable<{ [key: string]: Customer }> = this.store.select(customersSelector);

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private actions$: Actions
  ) {}

  searchCarriers(params) {
    this.store.dispatch(CarrierLoadFeedActions.search(params));
  }

  addTabs(tabs: Tab[] | Tab) {
    this.store.dispatch(LoadFeedActions.addTabs(tabs));
  }

  updateTabs(tabs: Tab[] | Tab) {
    this.store.dispatch(LoadFeedActions.updateTabs(tabs));
  }

  removeTabs(data) {
    this.store.dispatch(LoadFeedActions.removeTab(data));
  }

  selectTab(tab: Tab | unknown) {
    this.store.dispatch(LoadFeedActions.selectTab(tab));
  }

  searchLoadFeedKeysSuccess(keys) {
    this.store.dispatch(LoadFeedActions.searchLoadFeedKeysSuccess(keys));
  }

  searchExclusive(filter?) {
    this.store.dispatch(ExclusiveLoadFeedActions.search(filter));
  }

  loadFeedSearchKeys(data) {
    this.store.dispatch(LoadFeedActions.searchLoadFeedKeys(data));
  }

  setExpandedPanels(data: { isExpandedExclusive?: boolean }) {
    this.store.dispatch(LoadFeedActions.setExpandedPanels(data));
  }

  updateExclusiveEntity(id, entity) {
    this.store.dispatch(ExclusiveLoadFeedActions.searchUpdate({ id, entity }));
  }

  setLoading(param: boolean) {
    this.store.dispatch(LoadFeedActions.setLoading(param));
  }

  rejectExclusiveLoad(entity, reason, setDefaultTab = false) {
    this.store.dispatch(LoadFeedActions.rejectExclusiveLoad({ entity, reason, setDefaultTab }));
  }

  acceptExclusiveLoad(entity) {
    this.store.dispatch(LoadFeedActions.acceptExclusiveLoad({ entity }));
  }

  goTo(path) {
    this.router.navigate([path], { relativeTo: this.activatedRoute });
  }

  getCarrier(data) {
    this.store.dispatch(LoadFeedActions.getCarrier(data));
  }

  createBid(bid: Partial<Bid>, load: LoadsServiceLoad): void {
    this.store.dispatch(LoadFeedActions.createBid({ bid, load }));
  }

  searchBids(loadId: string): void {
    this.store.dispatch(BidActions.search({ loadId, query: { loadId } }));
  }

  getBidStatuses(): void {
    this.store.dispatch(LoadFeedActions.getBidStatuses());
  }

  getBidHistory(bidId: string): void {
    this.store.dispatch(LoadFeedActions.getBidHistory({ bidId }));
  }

  updateBid(
    bid: { id: string; brokerId?: string; finalStateFrom?: UserTypes } & UpdateBidInput,
    load: LoadsServiceLoad
  ): void {
    this.store.dispatch(LoadFeedActions.updateBid({ bid, load }));
  }

  /**
   * The related effect for this action will also book the load
   */
  acceptBidAndBookLoad(bid: Partial<Bid>, assignmentData: Partial<AssignLoadCarrierForm>): void {
    this.store.dispatch(LoadFeedActions.acceptBid({ bid, assignmentData }));
  }

  acceptCounterBid(load: LoadsServiceLoad): void {
    this.store.dispatch(LoadFeedActions.acceptCounterBid(load));
  }

  sortBidsBy(loadId: string, sortBy: BidSortTypes): void {
    this.store.dispatch(LoadFeedActions.sortBidsBy({ loadId, sortBy }));
  }

  getCustomer(id: string): void {
    this.store.dispatch(LoadFeedActions.getCustomer({ id }));
  }

  createCounterOffer(counterOfferInput: CounterOfferInput): void {
    this.store.dispatch(LoadFeedActions.createCounterOffer(counterOfferInput));
  }

  private normalizeBidStatusOptions(options: BidStatus[]): BidStatusOption[] {
    return options.map((option: BidStatus) => {
      const { name, value } = option;

      return {
        id: value,
        selected: false,
        label: name,
        svgIcon: `haulynx-bid-${value}`,
        className: `bid-${value}`,
      };
    });
  }
}
