<div class="dialog">
  <div mat-dialog-title>
    <p class="dialog__title">{{ isEditMilestone ? 'Edit' : 'Create' }} Milestone</p>
  </div>
  <p class="dialog__creator">Created By: Broker {{ data.creator }}</p>
  <p class="dialog__creation-time">Creation Time: {{ creationTime | date: 'short' }}</p>

  <form [formGroup]="form" class="form" mat-dialog-content>
    <div class="form-section">
      <div class="form-section__title">Milestone<sup> *</sup></div>

      <div class="form-section__input-container">
        <select formControlName="milestoneType" class="form-section__input" [attr.data-test]="'milestoneType'">
          <option *ngFor="let milestoneType of milestoneTypes" [value]="milestoneType.value">
            {{ milestoneType.key | titlecase }}
          </option>
        </select>
      </div>
    </div>

    <div class="form-section" *ngIf="!canShowStatus && !isDisatchEmpty">
      <div class="form-section__title">Update <sup>*</sup></div>

      <div class="form-section__input-container">
        <select
          formControlName="milestoneStatus"
          class="form-section__input"
          placeholder="Select Issue"
          [attr.data-test]="'milestoneUpdateStatus'"
        >
          <option *ngFor="let issue of issues" [value]="issue.value">
            {{ issue.key }}
          </option>
        </select>
      </div>
    </div>

    <div class="form-section" *ngIf="canShowStatus || isDisatchEmpty">
      <div class="form-section__title">Checkpoint <sup>*</sup></div>

      <div class="form-section__input-container">
        <select
          formControlName="loadLocation"
          class="form-section__input"
          placeholder="Select Checkpoint"
          [attr.data-test]="'milestoneLocation'"
        >
          <option *ngFor="let checkpoint of checkpoints" [value]="checkpoint.value">
            {{ checkpoint.key | titlecase }}
          </option>
        </select>
      </div>
    </div>

    <div class="form-section" *ngIf="canShowAddress()">
      <div class="form-section__title">Address <sup>*</sup></div>

      <div class="form-section__input-container">
        <input
          type="text"
          formControlName="loadLocationAddress"
          class="form-section__input"
          placeholder="Enter Address"
          [ngStyle]="{ color: isAddrDisabled ? '#bdbdc0' : '#FFFFFF' }"
          [attr.data-test]="'address'"
          (focus)="onAddressFormFocus(true)"
          (blur)="onAddressFormFocus(false)"
        />
        <div
          class="form-section__input-container__dropdown"
          [ngStyle]="{ display: canShowResultsDropdown ? 'initial' : 'none' }"
        >
          <app-search-place-dropdown
            *ngIf="predictions.length > 0"
            [predictions]="predictions"
            (selectPrediction)="onSelectPrediction($event)"
          >
          </app-search-place-dropdown>
        </div>
      </div>
    </div>

    <div class="form-section" *ngIf="canShowStatus">
      <div class="form-section__title">Status <sup>*</sup></div>

      <div class="form-section__input-container">
        <select formControlName="milestoneStatus" class="form-section__input" [attr.data-test]="'milestoneStatus'">
          <option *ngFor="let milestoneStatus of statusTypes" [value]="milestoneStatus.value">
            {{ milestoneStatus.key }}
          </option>
        </select>
      </div>
    </div>

    <div class="form-section" *ngIf="!canShowTimeInAndTimeOut && !canShowStatus && isDisatchEmpty">
      <div class="form-section__title">Time Empty<sup>*</sup></div>

      <div class="form-section__input-container">
        <app-datetime-picker
          [timeZone]="getTimeZone"
          class="app-form-field"
          formControlName="timeInDate"
        ></app-datetime-picker>
      </div>
    </div>

    <div class="form-section" *ngIf="canShowTimeInAndTimeOut">
      <div class="form-section__title">Time In <sup>*</sup></div>

      <div class="form-section__input-container">
        <app-datetime-picker
          [timeZone]="getTimeZone"
          [maxDate]="getMaxTime"
          class="app-form-field"
          formControlName="timeInDate"
        ></app-datetime-picker>
      </div>
    </div>

    <div class="form-section" *ngIf="canShowTimeInAndTimeOut && !isTimeOutDisable">
      <div class="form-section__input-container">
        <button (click)="enableTimeOut()" data-test="add-time-out">Add Time Out</button>
      </div>
    </div>

    <div class="form-section" *ngIf="canShowTimeInAndTimeOut && isTimeOutDisable">
      <div class="form-section__title">
        <mat-icon *ngIf="!disableTimeOutCancel" (click)="enableTimeOut()">clear </mat-icon>
        Time Out
      </div>

      <div class="form-section__input-container">
        <app-datetime-picker
          [timeZone]="getTimeZone"
          [maxDate]="getMaxTime"
          class="app-form-field"
          formControlName="timeOutDate"
        ></app-datetime-picker>
      </div>
    </div>

    <div class="form-section" *ngIf="canShowTimeInAndTimeOut">
      <div class="form-section__input-container">
        <p *ngIf="(invalidTimeIn$ | async) || (invalidTimeOut$ | async)" class="error" data-test="time-in-out-error">
          Invalid Time In/Time Out
        </p>
      </div>
    </div>

    <div class="form-section" *ngIf="canShowTimeInAndTimeOut && isTimeOutDisable">
      <div class="form-section__title"></div>

      <div class="form-section__input-container">
        <p>Time In Facility: {{ timeInFacility }}</p>
      </div>
    </div>

    <div class="form-section">
      <div class="form-section__title">Contact</div>

      <div class="form-section__input-container">
        <input
          type="text"
          formControlName="contact"
          class="form-section__input"
          placeholder="Enter Contact spoken to"
          [attr.data-test]="'milestoneContact'"
        />
      </div>
    </div>

    <div class="form-section">
      <div class="form-section__title">Comment</div>

      <div class="form-section__input-container">
        <textarea
          formControlName="comment"
          class="form-section__input"
          placeholder="Enter comments here..."
          [attr.data-test]="'milestoneComment'"
        ></textarea>
      </div>
    </div>
  </form>

  <div class="dialog__actions" mat-dialog-actions align="end">
    <action-buttons
      [buttons]="actionButtons"
      [disableButtons]="disableButtons"
      [loading]="isLoading$ | async"
      (onClick)="actionButtonClick($event)"
    >
    </action-buttons>
  </div>
</div>
