import gql from 'graphql-tag';

export const GetCarrierByDot = gql(`
  query GetCarrierByDot($dot: String!) {
    getCarrierByDot(dot: $dot) {
      id
      name
      dot
      temperature
      insurance
      weight
      owner
      mcNumber
      equipmentType
      isHaulynxUser
      isEldCarrier
      eldEnabledDate
      isLoadDistributionEnabled
      loadFeedEnabled
      loadFeedEnabledDate
      hazmatCapable
      rank
      maximumDeadMiles
      minimumLoadValue
      phone
      email
      timeZone
      addressStreet
      addressStreet2
      addressCity
      addressState
      addressCountry
      mcNumber
      saferWatchData {
        mcNumber
      }
      sourceType
      status
    }
  }
`);
