import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateRangeFilterType, dateRangeOptions, DateRanges, ISearchFilter } from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { addDays } from 'date-fns';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'haulynx-search-custom-date-range',
  templateUrl: './search-custom-date-range.html',
  styleUrls: ['./search-custom-date-range.scss'],
})
export class SearchCustomDateRangeComponent implements OnInit, OnDestroy {
  @ViewChild('wrapper', { static: false }) wrapper: ElementRef;

  @Input() focusedFilter: ISearchFilter;
  @Input() form: FormGroup;
  @Input() shouldFocusAfterViewInit = true;
  @Input() timeZone = 'America/Phoenix';

  @Output() mouseFocus: EventEmitter<boolean> = new EventEmitter();
  @Output() commitFilter: EventEmitter<{ searchFilter: ISearchFilter; form: FormGroup }> = new EventEmitter();
  @Output() removeFilter: EventEmitter<ISearchFilter> = new EventEmitter();
  @Output() validForm: EventEmitter<boolean> = new EventEmitter();

  fromFormName = 'date';
  dateForm: FormGroup;

  dateRange = DateRanges;
  dateRangeOptions = dateRangeOptions;
  alive = aliveWhile();
  today = new Date();

  constructor(private fb: FormBuilder) {
    this.dateForm = this.setForm();
  }

  ngOnInit(): void {
    this.setDateFormValidation();
  }

  setForm(): FormGroup {
    return this.fb.group({
      dateFilter: [null],
      customDate: [null],
    });
  }

  setDateFormValidation(): void {
    const curDateFilter = this.dateForm.get('dateFilter');
    const curCustomDate = this.dateForm.get('customDate');

    curDateFilter.valueChanges.pipe(takeUntil(this.alive)).subscribe((filter) => {
      this.mouseFocus.emit(true);
      if (curDateFilter.value === DateRanges.CUSTOM) {
        curCustomDate.setValidators(Validators.required);
        curCustomDate.updateValueAndValidity();
        this.validForm.emit(false);
      } else {
        curCustomDate.setValue(null);
        curCustomDate.clearValidators();
        curCustomDate.updateValueAndValidity();

        switch (curDateFilter.value) {
          case DateRanges.LAST_7_DAYS: {
            this.form.patchValue({
              [(<DateRangeFilterType>this.focusedFilter.keys).fromFormName]: addDays(new Date(), -7).toISOString(),
              [(<DateRangeFilterType>this.focusedFilter.keys).toFormName]: this.today.toISOString(),
            });
            break;
          }
          case DateRanges.LAST_30_DAYS: {
            this.form.patchValue({
              [(<DateRangeFilterType>this.focusedFilter.keys).fromFormName]: addDays(new Date(), -30).toISOString(),
              [(<DateRangeFilterType>this.focusedFilter.keys).toFormName]: this.today.toISOString(),
            });
            break;
          }
          case DateRanges.LAST_90_DAYS: {
            this.form.patchValue({
              [(<DateRangeFilterType>this.focusedFilter.keys).fromFormName]: addDays(new Date(), -90).toISOString(),
              [(<DateRangeFilterType>this.focusedFilter.keys).toFormName]: this.today.toISOString(),
            });
            break;
          }
          case DateRanges.LAST_180_DAYS: {
            this.form.patchValue({
              [(<DateRangeFilterType>this.focusedFilter.keys).fromFormName]: addDays(new Date(), -180).toISOString(),
              [(<DateRangeFilterType>this.focusedFilter.keys).toFormName]: this.today.toISOString(),
            });
            break;
          }
          default:
            this.mouseFocus.emit(false);
            break;
        }

        if (curDateFilter.value) {
          this.validForm.emit(true);
          this.mouseFocus.emit(false);
        } else {
          this.validForm.emit(false);
          this.mouseFocus.emit(false);
        }
      }
    });

    curCustomDate.valueChanges.pipe(takeUntil(this.alive)).subscribe((filter) => {
      if (curDateFilter.value === DateRanges.CUSTOM && curCustomDate.value) {
        this.form.patchValue({
          [(<DateRangeFilterType>this.focusedFilter.keys).fromFormName]: new Date(
            this.dateForm.get('customDate').value[0]
          ).toISOString(),
          [(<DateRangeFilterType>this.focusedFilter.keys).toFormName]: new Date(
            this.dateForm.get('customDate').value[1]
          ).toISOString(),
        });
        this.validForm.emit(true);
        this.mouseFocus.emit(false);
      }
    });
  }

  get isFormValid(): boolean {
    return this.form.get('lastSeenStart').value !== 0 && this.form.get('lastSeenEnd').value !== 0;
  }

  removeFocusedFilter(): void {
    this.removeFilter.emit(this.focusedFilter);
    this.mouseFocus.emit(false);
  }

  clearForm(): void {
    this.dateForm.reset();
    this.setForm();
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }
}
