import { ChangeDetectorRef, Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeatureFlagService } from '@haulynx/services';
import { UserEntityService } from '@haulynx/store';
import { FeatureFlag, FFState } from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { isArray } from 'lodash';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appFeatures]',
})
export class FeatureDirective implements OnDestroy {
  /**
   * To make use of this optional else template, follow the *ngIf "else" syntax like so:
   * *appFeatures="featureFlag; else otherTemplate"
   */
  @Input() appFeaturesElse: TemplateRef<unknown>;

  @Input() set appFeatures(flags: FeatureFlag | FeatureFlag[]) {
    if (flags) {
      this.flags = isArray(flags) ? flags : [flags];
    } else {
      this.flags = null;
      this.embedView(this.templateRef);
    }
  }

  private alive = aliveWhile();
  private flags = [];

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private userEntityService: UserEntityService,
    private featureFlagService: FeatureFlagService,
    private cd: ChangeDetectorRef
  ) {
    /**
     * This ensures that both the template and template else are
     * initialized before running function
     */
    setTimeout(() => {
      this.listenToFeatures();
    });
  }

  private listenToFeatures(): void {
    this.userEntityService.featureFlags$.pipe(takeUntil(this.alive)).subscribe((features: FFState) => {
      const hasPermission = !this.flags ? true : this.featureFlagService.checkFlag(features, this.flags);

      if (hasPermission) {
        this.embedView(this.templateRef);
      } else if (this.appFeaturesElse) {
        this.embedView(this.appFeaturesElse);
      } else {
        this.embedView(null);
      }
    });
  }

  private embedView(template: TemplateRef<unknown>): void {
    this.viewContainer.clear();
    if (template) {
      this.viewContainer.createEmbeddedView(template);
    }
    this.cd.markForCheck();
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }
}
