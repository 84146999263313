import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@haulynx/modules';
import { SpinnerModule } from '../spinner/spinner.module';
import { WhiteLinkTextComponent } from './white-link-text.component';

@NgModule({
  declarations: [WhiteLinkTextComponent],
  imports: [CommonModule, FlexLayoutModule, MaterialModule, SpinnerModule],
  exports: [WhiteLinkTextComponent],
})
export class WhiteLinkTextModule {}
