import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BookLoadAnalyticsEffects } from './book-load-analytics.effects';
import { BookLoadModel } from './book-load-model';
import { BookLoadEffects } from './book-load.effects';
import { bookLoadReducer } from './book-load.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature('book-load', bookLoadReducer),
    EffectsModule.forFeature([BookLoadEffects, BookLoadAnalyticsEffects]),
  ],
  providers: [BookLoadModel],
})
export class BookLoadStateModule {}
