import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@haulynx/modules';
import { TooltipMenuComponent } from './tooltip-menu.component';

@NgModule({
  imports: [CommonModule, MaterialModule],
  exports: [TooltipMenuComponent],
  declarations: [TooltipMenuComponent],
})
export class TooltipMenuModule {}
