import { Map } from 'immutable';
import { FORM_NAMESPACE } from './reducer';

export interface IFormState<T> {
  stateNameSpace: string;
  stateKey: Map<string, string>;
  state: Map<string, T | { [key: string]: any }> | { [key: string]: any } | any;
  initialState: Map<string, T | { [key: string]: any }> | { [key: string]: any } | any;
  isLoading: Map<string, boolean>;
  mode: Map<string, string>;
}

export function createInitialFormState() {
  return {
    getInitialState<T>(): IFormState<T> {
      return {
        stateNameSpace: FORM_NAMESPACE,
        stateKey: Map<any, any>(),
        state: Map<any, any>(),
        initialState: Map<any, any>(),
        isLoading: Map<any, any>(),
        mode: Map<any, any>(),
      };
    },
  };
}
