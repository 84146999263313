<div class="map-cluster">
  <mgl-map
    #clusterMap
    class="border-radius-{{ borderRadius }}"
    style="{{ style }}"
    [fitBoundsOptions]="{ padding: padding }"
    [zoom]="zoom"
    [center]="center"
    [fitBounds]="bounds"
    [interactive]="isInteractive"
    [scrollZoom]="scrollZoom"
    [maxZoom]="maxZoom"
  >
    <ng-container *ngIf="cluster && cluster.source">
      <mgl-geojson-source
        #clusterComponent
        id="earthquakes"
        [data]="cluster.source.data"
        [cluster]="true"
        [clusterRadius]="50"
        [clusterMaxZoom]="14"
      ></mgl-geojson-source>
      <mgl-markers-for-clusters source="earthquakes">
        <ng-template mglPoint let-feature>
          <mgl-marker [lngLat]="feature?.geometry?.coordinates">
            <div [title]="feature.properties.type" (click)="selectMarker($event, feature, marker)">
              <mat-icon
                svgIcon="haulynx-map-phone"
                class="marker-icon"
                *ngIf="feature.properties.type === 'phone'"
              ></mat-icon>
              <mat-icon
                svgIcon="haulynx-map-truck"
                class="marker-icon"
                *ngIf="feature.properties.type !== 'phone'"
              ></mat-icon>
            </div>
          </mgl-marker>
        </ng-template>
        <mgl-popup
          *ngIf="selectedFeature"
          [closeOnClick]="true"
          [lngLat]="selectedFeature?.geometry?.coordinates"
          (close)="removeSelected()"
        >
          <app-marker-popup [selectedMarker]="selectedFeature"></app-marker-popup>
        </mgl-popup>
        <ng-template mglClusterPoint let-feature>
          <div
            class="icon-stack"
            style="text-align: center"
            (click)="selectCluster($event, feature, clusterComponent, clusterMap)"
          >
            <mat-icon svgIcon="haulynx-cluster" class="marker-cluster-icon"> </mat-icon>
            <span class="marker-cluster-number">
              <strong>
                {{ feature.properties?.point_count }}
              </strong>
            </span>
          </div>
        </ng-template>
      </mgl-markers-for-clusters>
    </ng-container>
    <mgl-control mglNavigation *ngIf="showControls"></mgl-control>
  </mgl-map>
</div>
