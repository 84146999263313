<div
  class="search-chip-container"
  [ngClass]="{ 'search-chip-container--is-hovering': isHovering, 'search-chip-container--pill': showAsPill }"
>
  <div class="sc-title-container" (click)="removeAllFiltersIfIsClearAllChip()" data-test="remove-all-filters">
    <div class="sc-title" *ngIf="!isClearAllChip && showFilter">{{ filter.name }}:</div>
    <div class="sc-title" *ngIf="isClearAllChip">Clear All</div>
    <div class="sc-content" *ngIf="!isClearAllChip && filter.type !== dateRangeFilterType">
      {{ formValue }}
    </div>
    <div class="sc-content" *ngIf="!isClearAllChip && filter.type === dateRangeFilterType">
      <ng-container *ngIf="!showDaysDifference; else daysDifference">
        {{ formValue?.split(' - ')[0] || '' | date: 'shortDate' }}
        - {{ formValue?.split(' - ')[1] || '' | date: 'shortDate' }}
      </ng-container>
      <ng-template #daysDifference>
        {{ formValue }}
      </ng-template>
    </div>

    <!-- Not Text Input -- Just For Focus Capability On Chip -->
    <input
      *ngIf="isFocusable"
      class="hidden-form-for-focusing"
      [(ngModel)]="cannotType"
      (ngModelChange)="cannotType = ''"
      #nonVisibleForm
      (focus)="onChipFocus(filter)"
      (blur)="onChipBlur(null)"
      (mouseover)="isHovering = true"
      (mouseleave)="isHovering = false"
      data-test="focus-input"
    />
  </div>

  <div
    *ngIf="displayCloseButton"
    class="sc-close-container"
    (click)="removeFromSelected($event)"
    data-test="close-button"
  >
    <mat-icon class="sc-close-icon"> close </mat-icon>
  </div>
</div>
