<div class="search-options-container" #wrapper>
  <form [formGroup]="form" autocomplete="off" *ngIf="focusedFilter">
    <div class="so-form">
      <div class="so-chip-title">{{ focusedFilter.name }}:</div>
      <input
        *ngIf="
          focusedFilter.type !== filterType.TEXT_ARRAY &&
          focusedFilter.type !== filterType.MULTI_DROPDOWN &&
          focusedFilter.type !== filterType.BOOLEAN
        "
        matInput
        #soInput
        type="text"
        class="so-mat-input"
        formControlName="{{ formName }}"
        placeholder="{{ placeholder }}"
        attr.data-test="{{ focusedFilter.name }}"
        [readonly]="focusedFilter.type === filterType.MULTI_DROPDOWN"
      />
      <div class="boolean-options-selected" *ngIf="focusedFilter.type === filterType.BOOLEAN">
        {{ booleanForm }}
      </div>
      <div class="search-options-selected" *ngIf="focusedFilter.type === filterType.MULTI_DROPDOWN">
        <ng-container *ngFor="let control of textForm; let i = index">
          <app-search-chip
            [filter]="focusedFilter"
            [form]="form"
            [tabIndex]="i"
            [showAsPill]="true"
            [showFilter]="false"
            (removeSearchFilter)="onRemoveMultiOption($event)"
          >
          </app-search-chip>
        </ng-container>
      </div>
      <ng-container *ngIf="focusedFilter.type === filterType.TEXT_ARRAY">
        <ng-container [formArrayName]="formName">
          <ng-container *ngFor="let control of textArrayForm.controls; let i = index">
            <input
              *ngIf="i === selectedIndex"
              matInput
              #soInput
              type="text"
              class="so-mat-input"
              [formControl]="control"
              [placeholder]="placeholder"
              [attr.data-index]="i"
              data-test="search-field"
            />
          </ng-container>
        </ng-container>
      </ng-container>

      <mat-icon class="so-close-icon" (click)="removeFocusedFilter()"> close </mat-icon>
    </div>
  </form>
</div>
