import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ElementsModule } from '@haulynx/elements';
import { MaterialModule } from '@haulynx/modules';
import { HeaderEndbuttonsAccountSetupComponent } from './header-endbuttons-account-setup.component';
@NgModule({
  declarations: [HeaderEndbuttonsAccountSetupComponent],
  imports: [CommonModule, ElementsModule, MaterialModule, FlexLayoutModule],
  exports: [HeaderEndbuttonsAccountSetupComponent],
})
export class HeaderEndbuttonsAccountSetupModule {}
