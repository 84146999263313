import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule, UnderlineTextDirective } from '@haulynx/directives';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { DataTableV2Service } from '@haulynx/services';
import { TableModule } from 'primeng/table';
import { DropDownModule } from '../drop-down/drop-down.module';
import { BulkActionsModule } from '../load-bulk-actions/bulk-actions.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { ColumnSortComponent } from './components/column-sort/column-sort.component';
import { ColumnVisibilityComponent } from './components/column-visibility/column-visibility.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { DataTableV2Component } from './data-table-v2.component';
import { TestDataTableComponent } from './stories/test-data-table/test-data-table.component';
import { CheckboxModule } from 'primeng/checkbox';
@NgModule({
  declarations: [
    DataTableV2Component,
    ColumnSortComponent,
    ColumnVisibilityComponent,
    PaginationComponent,
    TestDataTableComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    TableModule,
    MaterialModule,
    BulkActionsModule,
    SpinnerModule,
    DropDownModule,
    DirectivesModule,
    PipesModule,
    CheckboxModule,
    DragDropModule,
  ],
  exports: [DataTableV2Component, UnderlineTextDirective, PaginationComponent, ColumnSortComponent],
  providers: [DataTableV2Service, DatePipe],
})
export class DataTableV2Module {}
