<form class="add-payment" [formGroup]="form" (ngSubmit)="save($event)">
  <h3 class="add-payment__title">Add Payment Item</h3>

  <div class="add-payment__grid__form">
    <mat-form-field class="app-form-field add-payment__type" hideRequiredMarker appearance="fill" floatLabel="always">
      <mat-label class="add-payment__label"> Search by PYT code, Description, or Shortcut # </mat-label>
      <input
        tab-directive
        data-test="tabThrough"
        matInput
        numericalKeyBoardShortcut
        class="add-payment__input add-payment__input--search"
        hideRequiredMarker
        autocomplete="off"
        formControlName="paymentType"
        [matAutocomplete]="auto"
        required
      />
      <mat-icon class="add-payment__icon">search</mat-icon>

      <mat-autocomplete
        #auto="matAutocomplete"
        [displayWith]="displayFn"
        autoActiveFirstOption
        (optionSelected)="select($event.option.value)"
        class="add-payment__autocomplete"
      >
        <mat-option *ngFor="let option of filteredOptions$ | async" [value]="option">
          {{ option.code }} / {{ option.description }}
        </mat-option>
      </mat-autocomplete>

      <mat-error *ngIf="form.get('paymentType')?.errors?.paymentType"> Select payment from the list </mat-error>
    </mat-form-field>
    <mat-form-field class="app-form-field add-payment__amount" hideRequiredMarker appearance="fill" floatLabel="always">
      <mat-label class="add-payment__label"> Quantity </mat-label>
      <input
        tab-directive
        matInput
        type="number"
        tabindex="-1"
        #quantity
        min="0"
        data-test="quantity"
        formControlName="quantity"
        [readonly]="readOnly"
        class="add-payment__input add-payment__input__quantity"
        placeholder="0"
        required
      />
    </mat-form-field>
    <div class="add-payment__amounts">
      <mat-form-field
        class="app-form-field add-payment__amount"
        hideRequiredMarker
        appearance="fill"
        floatLabel="always"
      >
        <mat-label class="add-payment__label">
          $ Rate
          <span class="astrick" *ngIf="form.controls['amount'].hasError('max')">*</span>
          <span class="astrick" *ngIf="form.controls['amount'].hasError('min')">*</span>
        </mat-label>
        <input
          tab-directive
          matInput
          type="text"
          currencyMask
          [options]="{ thousands: ',', decimal: '.', allowNegative: true }"
          #amount
          hideRequiredMarker
          formControlName="amount"
          class="add-payment__input"
          data-test="addAmount"
          autocomplete="off"
          placeholder="0.00"
          required
        />
      </mat-form-field>
    </div>
    <div class="error" *ngIf="form.controls['amount'].hasError('max')">Rate must be negative</div>
    <div class="error" *ngIf="form.controls['amount'].hasError('min')">Rate must be positive</div>
    <mat-form-field
      class="app-form-field add-payment__amount add-payment__rightitem"
      hideRequiredMarker
      appearance="fill"
      floatLabel="always"
    >
      <mat-label class="add-payment__label">Full Amount</mat-label>
      <input
        matInput
        type="text"
        tabindex="-1"
        readonly
        class="add-payment__input__quantity"
        data-test="fullAmount"
        [value]="fullAmount | currency"
      />
    </mat-form-field>
  </div>

  <p class="add-payment__description" *ngIf="data?.paymentTypes?.length">
    Or choose from one of the following common types below
  </p>

  <div class="add-payment__grid__list">
    <div *ngFor="let option of data?.paymentTypes | slice: 0:9; let i = index" class="add-payment__grid__item">
      <button
        type="button"
        tabindex="-1"
        mat-button
        class="add-payment__option"
        (click)="select(option)"
        [appKeyboardShortcut]="'' + (i + 1)"
        numericalKeyBoardShortcut
        [componentBusinessLabel]="'Add Payment Type'"
        data-test="payment-type"
      >
        <span class="add-payment__option__bullet">{{ i + 1 }}.</span>
        {{ option.code }} / {{ option.description }}
      </button>
    </div>
  </div>

  <div class="add-payment__action">
    <button
      type="button"
      mat-dialog-close
      class="hlx-button-command hlx-button-command--cancel hlx-button-command--border-white add-payment__button"
    >
      Cancel
    </button>

    <button
      type="submit"
      class="hlx-button-command hlx-button-command--book add-payment__button"
      [disabled]="form?.invalid"
      [appKeyboardShortcut]="'s'"
      [componentBusinessLabel]="'Add Payment Type'"
      data-test="save-button"
    >
      <span class="underlined">S</span>ave
    </button>
  </div>
</form>
