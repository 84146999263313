<div class="user-form">
  <app-spinner [active]="usersModel.isLoadingUserForm$ | async" [fullCover]="true"></app-spinner>

  <div class="user-form__header">
    <h1 mat-dialog-title>{{ isUnclaimedUser ? 'Invite user' : createMode ? 'Create new user' : 'Edit user' }}</h1>
  </div>

  <div class="user-form__content" mat-dialog-content>
    <form [formGroup]="form" class="form" novalidate>
      <div class="row form-max-width" *ngIf="createMode">
        <mat-form-field [ngClass]="{ 'margin-bottom': form.get('email').getError('carrierClaimed') }">
          <input matInput formControlName="email" type="email" placeholder="Email" />
          <mat-error *ngIf="form.get('email').getError('email')"> Please enter a valid email. </mat-error>
          <mat-error *ngIf="form.get('email').getError('carrierClaimed')">
            This email account is already associated with existing carrier account. Please try another email.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="row form-max-width">
        <mat-form-field>
          <input matInput formControlName="name" type="text" placeholder="Name" />
          <mat-error *ngIf="form.get('name').invalid"> Please enter name. </mat-error>
        </mat-form-field>
      </div>
      <div class="row form-max-width">
        <mat-form-field>
          <input matInput mask="000-000-0000" formControlName="phone" type="text" placeholder="Phone Number" />
          <mat-error *ngIf="form.get('phone').invalid"> Please enter a valid phone number. </mat-error>
        </mat-form-field>
      </div>
      <div class="row form-max-width" *ngIf="createMode && !isUnclaimedUser">
        <mat-form-field>
          <input
            matInput
            formControlName="password"
            type="password"
            autocomplete="new-password"
            placeholder="Password"
          />
          <mat-error *ngIf="form.get('password').invalid"> Passwords must be valid between 6-20 characters. </mat-error>
        </mat-form-field>
      </div>
      <div class="row form-max-width" *ngIf="createMode && !isUnclaimedUser">
        <mat-form-field>
          <input matInput formControlName="passwordConfirm" type="password" placeholder="Confirm Password" />
          <mat-error *ngIf="form.hasError('mismatchedPasswords') && form.get('passwordConfirm').invalid">
            Passwords must match.
          </mat-error>
        </mat-form-field>
      </div>

      <div
        class="row form-max-width"
        *ngIf="
          createMode && (selectedAccountType === accountType.CARRIER || selectedAccountType === accountType.DRIVER)
        "
      >
        <div>
          <mat-checkbox color="accent" formControlName="admin"> This user is a Company Admin </mat-checkbox>
        </div>
        <div>
          <mat-checkbox
            [checked]="selectedAccountType === accountType.DRIVER"
            color="accent"
            (change)="toggleForDriver(selectedAccountType)"
            formControlName="isDriver"
          >
            This user is a Driver
          </mat-checkbox>
        </div>
        <div>
          <mat-checkbox color="primary" formControlName="trailerCreationEnabled"> Can Add Trailers </mat-checkbox>
        </div>
      </div>

      <ng-container *ngIf="createMode && selectedAccountType === accountType.DRIVER" formGroupName="driver">
        <div class="row form-max-width">
          <mat-form-field>
            <input matInput formControlName="companyId" type="text" placeholder="User's Company ID (Optional)" />
          </mat-form-field>
        </div>

        <div class="row form-max-width">
          <mat-form-field>
            <mat-select
              placeholder="Driver Licence Country"
              formControlName="issuingCountry"
              (selectionChange)="onSelectCountry($event)"
              data-test="countrySelect"
            >
              <mat-option *ngFor="let country of countries" [value]="country">{{ country }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="row form-max-width">
          <mat-form-field>
            <mat-select formControlName="issuingState" placeholder="Driver's License State">
              <mat-option *ngFor="let state of statesList" [value]="state.code">
                {{ state.code }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('driver').get('issuingState').invalid"> The state is required </mat-error>
          </mat-form-field>
        </div>

        <div class="row form-max-width">
          <mat-form-field>
            <input matInput formControlName="licenseNumber" type="text" placeholder="Driver's License Number" />
            <mat-error *ngIf="form.get('driver').get('licenseNumber').invalid">
              A valid driver's license is required
            </mat-error>
          </mat-form-field>
        </div>
      </ng-container>

      <ng-container *ngIf="selectedAccountType === accountType.BROKER" formGroupName="broker">
        <div class="row form-max-width">
          <mat-form-field>
            <input matInput formControlName="brokerId" type="text" placeholder="Broker id" />
          </mat-form-field>
        </div>

        <div class="row form-max-width">
          <mat-form-field>
            <input matInput formControlName="team" type="text" placeholder="Team" />
          </mat-form-field>
        </div>

        <div class="row form-max-width">
          <mat-form-field>
            <input matInput formControlName="jobTitle" type="text" placeholder="Job Title" />
          </mat-form-field>
        </div>

        <div class="row form-max-width">
          <mat-form-field>
            <input matInput formControlName="manager" type="text" placeholder="Manager" />
          </mat-form-field>
        </div>

        <div class="row form-max-width" *ngIf="createMode">
          <mat-form-field>
            <input matInput formControlName="office" type="text" placeholder="Office" />
          </mat-form-field>
        </div>
      </ng-container>
    </form>
  </div>

  <div class="user-form__footer" mat-dialog-actions>
    <action-buttons [buttons]="buttons$ | async" [disableButtons]="disableButtons$ | async" (onClick)="execute($event)">
    </action-buttons>
  </div>
</div>
