import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReleasesService } from '@haulynx/services';
import { AppModel } from '@haulynx/store';
import { ReleaseNote } from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { get } from 'lodash';
import { switchMap, take } from 'rxjs/operators';
import { TourContentComponent } from './tour-content/tour-content.component';

@Component({
  selector: 'app-tour',
  template: ``,
  styles: [],
})
export class TourComponent implements OnInit, OnDestroy {
  private alive = aliveWhile();

  constructor(public dialog: MatDialog, public releaseService: ReleasesService, private appModel: AppModel) {}

  ngOnInit(): void {
    this.appModel.id$
      .pipe(
        take(1),
        switchMap((userId: string) => this.releaseService.getLatestUnreadReleaseNotes({ userId }))
      )
      .subscribe((releaseNote: ReleaseNote) => {
        const releaseNoteId = get(releaseNote, 'id', null);

        if (releaseNoteId) {
          this.startTour(releaseNote);
        }
      });
  }

  startTour(release: ReleaseNote = null): void {
    this.dialog
      .open(TourContentComponent, {
        disableClose: true,
        autoFocus: false,
        backdropClass: 'tour-back-drop',
        height: '840px',
        width: '1050px',
        data: { release },
      })
      .afterClosed()
      .subscribe();
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }
}
