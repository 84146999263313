import { LoadHistoryActivityType } from '../web-interfaces/load-overview/load-history';
import { EquipmentType } from './loads-service-provider';

export interface LaneServiceSearchParamData extends LaneServiceSearchParameters {
  originLocation?: string;
  destinationLocation?: string;
  appointmentSet?: boolean;
}

export class LaneServiceSearchParameters {
  loadId?: string;
  equipment?: EquipmentType[];
  activityType?: LoadHistoryActivityType[];

  constructor(props?: Partial<LaneServiceSearchParameters>) {
    Object.assign(this, props);
  }
}
