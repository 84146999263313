<div class="datepicker">
  <sat-datepicker #picker [rangeMode]="rangeMode" [selectFirstDateOnClose]="selectFirstDateOnClose"> </sat-datepicker>

  <div *ngIf="!dropdown" class="regularDatepicker">
    <app-separate-date-inputs
      *ngIf="!rangeMode && separateDateInputs"
      [value]="pickerInput.value"
      (onDateChange)="onDateInput($event)"
    >
    </app-separate-date-inputs>
    <input
      #pickerInput
      matInput
      [formControl]="control"
      [placeholder]="placeholder"
      [satDatepicker]="picker"
      [min]="min"
      [max]="max"
      [ngClass]="{ hidden: separateDateInputs }"
      (focus)="picker.open()"
    />
  </div>

  <div *ngIf="dropdown">
    <app-drop-down
      ngDefaultControl
      [placeholder]="placeholder"
      [displayLabel]="'text'"
      [key]="'date'"
      [data]="nextTenDays"
      (selected)="onDateInput($event)"
      [chooseDefaultOnTab]="true"
    >
      <input
        matInput
        [satDatepicker]="picker"
        [formControl]="control"
        [min]="min"
        [max]="max"
        [ngClass]="{ hidden: separateDateInputs }"
        (focus)="picker.open()"
        (onDateChange)="onDateInput($event)"
      />
    </app-drop-down>
  </div>

  <sat-datepicker-toggle matSuffix tabIndex="{{ tabIndex }}" [for]="picker">
    <mat-icon class="calendar-icon" matDatepickerToggleIcon svgIcon="haulynx-calendar"> </mat-icon>
  </sat-datepicker-toggle>
</div>
