import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoadsServiceService } from '@haulynx/services';
import { BookStatus, LoadsServiceLoad, termsAndConditions } from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { get } from 'lodash';

@Component({
  selector: 'app-confirm-booking',
  templateUrl: './confirm-booking.component.html',
  styleUrls: ['./confirm-booking.component.scss'],
})
export class ConfirmBookingComponent implements OnInit, OnDestroy {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { carrierDot: string; load: LoadsServiceLoad },
    private loadsServiceService: LoadsServiceService
  ) {}

  alive = aliveWhile();
  termsAndConditions = termsAndConditions;
  isLoading = false;
  isBookable = true;

  ngOnInit() {
    if (this.data.load.bookStatus === BookStatus.EXCLUDED) {
      this.isLoading = true;
      if (!this.data.carrierDot) {
        console.error('carrierDot cannot be undefined', this.data);
      }
      this.loadsServiceService.getBrokerBookedLoads(this.data.load.id, this.data.carrierDot).subscribe(
        (data) => {
          if (get(data, 'errors')) {
            this.isBookable = false;
          } else {
            this.isBookable = true;
          }
          this.isLoading = false;
        },
        (error) => {
          this.isBookable = false;
          this.isLoading = false;
        }
      );
    }
  }

  ngOnDestroy() {
    this.alive.destroy();
  }
}
