import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { LoadQuickDriverForm } from '@haulynx/services';
import {
  BookStatus,
  LoadLocationType,
  LoadsServiceCustomer,
  LoadsServiceEquipmentType,
  LoadsServiceLoad,
  LoadsServiceLoadStatus,
  LoadsServiceNote,
  LoadsServicePoint,
} from '@haulynx/types';

@Component({
  selector: 'app-active-loads-quick-action-container',
  templateUrl: './active-loads-quick-action-container.component.html',
  styleUrls: ['./active-loads-quick-action-container.component.scss'],
})
export class ActiveLoadsQuickActionContainerComponent implements OnInit {
  load: LoadsServiceLoad;
  isLoading: boolean;
  title = 'Some Title';

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      const { id = null } = params;

      this.load = id ? new LoadsServiceLoad(mockLoad) : null;
    });
  }

  onSave(data: LoadQuickDriverForm) {}

  onActionClick(event: { action: string; payload: { [key: string]: any } }): void {}
}

const mockLoad = new LoadsServiceLoad({
  id: '-MGOrhJebTVwsOjmiYDb',
  loadStatus: LoadsServiceLoadStatus.DELIVERED,
  bookStatus: BookStatus.VIEWABLE,
  providerDetails: { equipmentType: LoadsServiceEquipmentType.DRY_VAN },
  createdBy: 'eaRepWJ665bkbwbTMzhadxC5jPC3',
  locations: [
    {
      customer: new LoadsServiceCustomer(),
      address: '1765 SPRING VALLEY RD, TUSCUMBIA AL, 35674',
      timezone: 'America/Chicago',
      appointmentStart: 1583020800,
      appointmentEnd: 1586028290,
      appointmentSet: true,
      carrierArrival: null,
      carrierDeparture: 1588199750,
      billOfLading: '5669274',
      locationType: LoadLocationType.PICKUP,
      geometry: new LoadsServicePoint({}),
      distanceMiles: 13,
      notes: new LoadsServiceNote(),
    },
    {
      customer: new LoadsServiceCustomer(),
      address: '725 CAMPBELLSVILLE BYPASS, CAMPBELLSVILLE KY, 42718',
      timezone: 'America/New_York',
      appointmentStart: 1588545350,
      appointmentEnd: 1589150150,
      appointmentSet: false,
      carrierArrival: null,
      carrierDeparture: 1589445350,
      billOfLading: '5669274',
      locationType: LoadLocationType.DROPOFF,
      geometry: new LoadsServicePoint({}),
      distanceMiles: 23,
      notes: new LoadsServiceNote(),
    },
  ],
});
