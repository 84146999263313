import { CapacityObject, CarrierLane, IPostTruck, LaneType, NeptuneLoad } from '@haulynx/types';
import { get } from 'lodash';
import { AddressPosition, splitAddress } from '../utils/utils';

export class CarrierActions {
  static loadFieldSort(load: NeptuneLoad, column: string): string | number {
    switch (column) {
      case 'originCity':
        return splitAddress(load.loadPickupAddress, AddressPosition.CITY);

      case 'originState':
        return splitAddress(load.loadPickupAddress, AddressPosition.STATE);

      case 'destinationCity':
        return splitAddress(load.loadDropoffAddress, AddressPosition.CITY);

      case 'destinationState':
        return splitAddress(load.loadDropoffAddress, AddressPosition.STATE);

      case 'revenue':
        const revenue = get(load, 'revenue', 0);

        return Number(revenue);

      case 'price':
        const price = get(load, 'price', 0);

        return Number(price);

      case 'pickupDate':
        return Number(get(load, 'loadPickupTimestamp', null));

      case 'appPickupDate':
        return Number(get(load, 'loadPickupTimestamp', null));

      case 'dropoffDate':
        return Number(get(load, 'loadDropoffTimestamp', null));

      case 'appDroppoffDate':
        return Number(get(load, 'loadDropoffTimestamp', null));

      case 'oDh':
        const odh = load.odh.replace(' mi', '');
        return Number(odh);

      case 'dDh':
        const ddh = load.ddh.replace(' mi', '');
        return Number(ddh);

      case 'customerName':
        return get(load, 'load.customerName', 'N/A');

      case 'bidDetails':
        return load.load.bidDetails.lowestOffer
          ? load.load.bidDetails.lowestOffer
          : load.load.bidDetails.lowestBid
          ? load.load.bidDetails.lowestBid.price
          : 0;

      default:
        return '';
    }
  }

  static laneFieldSort(lane: CarrierLane, column: string): string {
    switch (column) {
      case 'lanes':
        return lane.orgCityState + lane.destCityState;

      default:
        return '';
    }
  }

  static truckFieldSort(truck: IPostTruck, column: string): number | string {
    switch (column) {
      case 'availableTime':
        return Date.parse(truck.timeAvailable);

      case 'location':
        return truck.location;

      case 'preferredLocation':
        return truck.preferredLocation;

      case 'equipmentType':
        return truck.equipmentType;

      case 'brokerId':
        return truck.brokerId ? truck.brokerId : 'Carrier';

      default:
        return '';
    }
  }

  static preferredLaneFieldSort(lane: CapacityObject, column: string): number | string {
    console.log(lane);
    switch (column) {
      case 'originDestination':
        return lane.origin.locationName + lane.destination.locationName;

      case 'reoccuring':
        return lane.origin.availability.dayOfWeek.join(',');

      case 'equipmentType':
        return lane.equipmentType;

      case 'price':
        return lane.cost && lane.cost.min ? lane.cost.min : 0;

      case 'postedBy':
        return lane.type === LaneType.CARRIER ? 'Carrier' : lane.brokerId;

      default:
        return '';
    }
  }
}
