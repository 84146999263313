import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { BidsModule } from '../bids/bids.module';
import { CarrierAcceptCounterOfferDialogComponent } from './components/carrier-accept-counter-offer-dialog';
import { CarrierBidButtonComponent } from './components/carrier-bid-button/carrier-bid-button.component';
import { CarrierBidChipsComponent } from './components/carrier-bid-chips/carrier-bid-chips.component';
import { CarrierCreateBidDialogComponent } from './components/carrier-create-bid-dialog/carrier-create-bid-dialog.component';

@NgModule({
  declarations: [
    CarrierBidButtonComponent,
    CarrierCreateBidDialogComponent,
    CarrierBidChipsComponent,
    CarrierAcceptCounterOfferDialogComponent,
  ],
  exports: [CarrierBidButtonComponent, CarrierBidChipsComponent],
  imports: [CommonModule, MaterialModule, PipesModule, FormsModule, ReactiveFormsModule, FlexLayoutModule, BidsModule],
})
export class CarrierBidsModule {}
