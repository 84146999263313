import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'haulynx-white-progress-spinner',
  templateUrl: './white-progress-spinner.component.html',
  styleUrls: ['./white-progress-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WhiteProgressSpinnerComponent {
  @Input() mode: 'determinate' | 'indeterminate' = 'indeterminate';
  @Input() value: number;
  @Input() diameter = 36;
  @Input() strokeWidth = 4;
  @Output() clicked = new EventEmitter<void>();

  public click() {
    this.clicked.emit();
  }
}
