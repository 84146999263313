<form [formGroup]="form" class="filter-wrapper">
  <div class="network-carrier-filter">
    <div class="search-block pickup-filter-wrapper">
      <label class="label">Pick-Up Location</label>
      <google-address-field
        formControlName="pickUpName"
        [control]="form.get('pickUpName')"
        (onIconClick)="setCurrentUserLocation()"
        (onGpsLocation)="onGpsLocation('pickUpName', $event)"
        [lon]="form.get('origLon').value"
        [lat]="form.get('origLat').value"
        [placeholder]="'Pick-Up Location'"
        [showSuffixIcon]="'crosshair'"
      >
      </google-address-field>
      <div class="allow-stops-wrapper">
        <mat-checkbox color="primary" formControlName="allowStops"> Allow Stops </mat-checkbox>
      </div>
    </div>

    <div class="search-block swap-button-wrapper">
      <a class="swap-icon" mat-button (click)="switchLocations()">
        <mat-icon svgIcon="haulynx-swap"></mat-icon>
      </a>
    </div>

    <div class="search-block delivery-filter-wrapper">
      <label class="label">Delivery Location</label>
      <google-address-field
        formControlName="deliveryName"
        [control]="form.get('deliveryName')"
        (onGpsLocation)="onGpsLocation('deliveryName', $event)"
        [lon]="form.get('destLon').value"
        [lat]="form.get('destLat').value"
        [placeholder]="'Delivery Location'"
      >
      </google-address-field>
    </div>

    <div class="search-block date-filter-wrapper">
      <label class="label">Pick-Up Date</label>
      <mat-form-field appearance="outline">
        <input matInput [matDatepicker]="picker" formControlName="pickupDate" placeholder="Pick-Up Date" />
        <mat-datepicker-toggle matSuffix [for]="picker">
          <mat-icon class="calendar-icon" matDatepickerToggleIcon svgIcon="haulynx-calendar"> </mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <app-chip-list [data]="dateButtons" (onClick)="dateButtonClick($event)" [select]="activeDateButton">
      </app-chip-list>
    </div>
  </div>

  <div class="network-carrier-filter filter-center">
    <div class="search-block price-filter-wrapper">
      <label class="label">Price</label>
      <mat-form-field appearance="outline">
        <input matInput formControlName="price" placeholder="Price" />
      </mat-form-field>
    </div>

    <div class="search-block rpm-filter-wrapper">
      <label class="label">RPM</label>
      <mat-form-field appearance="outline">
        <input matInput formControlName="rpm" placeholder="RPM" />
      </mat-form-field>
    </div>

    <div class="search-block equipment-filter-wrapper">
      <ng-container *ngTemplateOutlet="equipmentType"></ng-container>
    </div>

    <div class="search-block distance-filter-wrapper">
      <label class="label">Distance</label>
      <mat-form-field appearance="outline">
        <input matInput formControlName="loadDistance" placeholder="Distance" />
      </mat-form-field>
    </div>

    <div class="search-block weight-filter-wrapper">
      <label class="label">Weight</label>
      <mat-form-field appearance="outline">
        <input matInput formControlName="weight" placeholder="Weight" />
      </mat-form-field>
    </div>

    <div class="search-block search-button-wrapper">
      <ng-container *ngTemplateOutlet="searchButton"></ng-container>
    </div>
  </div>

  <ng-template #searchButton>
    <a (click)="search()" mat-button class="action-button search-button"> Search </a>
  </ng-template>

  <ng-template #equipmentType>
    <label class="label">Equipment</label>
    <mat-form-field appearance="outline">
      <mat-select formControlName="equipmentType" placeholder="Equipment">
        <mat-option *ngFor="let equipment of equipmentTypes" [value]="equipment.text">
          {{ equipment.text }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-template>
</form>
