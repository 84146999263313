export enum AddressTypes {
  STATE = 'administrative_area_level_1',
  COUNTRY = 'political',
}

export enum KeyboardKeys {
  Tab = 'Tab',
  ArrowDown = 'ArrowDown',
  ArrowUp = 'ArrowUp',
}

export enum KeyboardCodes {
  ArrowDown = 40,
  Enter = 13,
}
