import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CreateDeviceByImei, GetDeviceByImei, GetDevicesByCarrierId } from '@haulynx/gql';
import { Device, EntityNames } from '@haulynx/types';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, EntityOp } from '@ngrx/data';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { CarrierService } from '../../component-services/carrier/carrier.service';
import { GraphqlService } from '../../graphql/graphql.service';

@Injectable({ providedIn: 'root' })
export class DeviceEntityService extends EntityCollectionServiceBase<Device> {
  constructor(
    private carrierService: CarrierService,
    private graphqlService: GraphqlService,
    private snackBar: MatSnackBar,
    elementsFactory: EntityCollectionServiceElementsFactory
  ) {
    super(EntityNames.DEVICE, elementsFactory);
  }

  public createDevice(userId: string, devices: { imei: string; type: string; carrierId?: string }[]) {
    this.graphqlService
      .mutate({
        mutation: CreateDeviceByImei,
        variables: {
          userId,
          devices,
        },
      })
      .subscribe(
        (apolloResponse) => {
          const createdDevices = apolloResponse?.data['createDevicesWithImei'];

          if (!createdDevices.length) {
            return this.snackBar.open(`Unable to create device${devices.length === 1 ? '' : 's'}`, null, {
              duration: 2000,
            });
          }

          this.snackBar.open(`Successfully created devices`, null, { duration: 2000 });
          this.filter$.pipe(take(1)).subscribe((filter: { imei?: string; carrierId?: string }) => {
            if (filter.carrierId) {
              this.getDevicesByCarrierId(filter.carrierId);
            }
          });
        },
        (error) => {
          this.createAndDispatch(EntityOp.SAVE_UPSERT_MANY_ERROR, error);
        }
      );
  }

  public returnDeviceByImei(imei: string): Observable<{ data: Device[]; error: { message: string } }> {
    return this.graphqlService.query<Device[]>({
      query: GetDeviceByImei,
      variables: {
        id: imei,
      },
    });
  }

  public getDeviceByImei(imei: string) {
    this.setFilter({ imei });

    this.entityMap$.pipe(take(1)).subscribe((entities) => {
      if (!entities[imei]) {
        this.setLoading(true);
      }

      this.graphqlService
        .query<Device[]>({
          query: GetDeviceByImei,
          variables: {
            id: imei,
          },
        })
        .subscribe(
          (apolloResponse) => {
            const device = apolloResponse?.data['getDeviceById'];
            this.createAndDispatch(EntityOp.QUERY_BY_KEY_SUCCESS, device);
          },
          (error) => {
            this.createAndDispatch(EntityOp.QUERY_BY_KEY_ERROR, error);
          }
        );
    });
  }

  public getDevicesByCarrierId(carrierId: string): void {
    this.setFilter({ carrierId });

    this.filteredEntities$.pipe(take(1)).subscribe((entities) => {
      if (!entities?.length) {
        this.setLoading(true);
      }

      this.graphqlService
        .query<Device[]>({
          query: GetDevicesByCarrierId,
          variables: {
            carrierId,
          },
        })
        .subscribe(
          (apolloResponse) => {
            const devices = apolloResponse?.data['getDevicesByCarrierId'];
            this.createAndDispatch(EntityOp.SAVE_UPSERT_MANY_SUCCESS, devices);
          },
          (error) => {
            this.createAndDispatch(EntityOp.SAVE_UPSERT_MANY_ERROR, error);
          }
        );
    });
  }

  public updateDevice(device: Device) {
    this.filter$.pipe(take(1)).subscribe((filter: { carrierId?: string; imei?: string }) => {
      if (filter.imei) {
        this.setLoading(true);
      }

      this.carrierService.updateDevice(device, device['carrierId'] ? false : true).subscribe(
        (updatedDevice: Device) => {
          this.snackBar.open(`Successfully updated device`, null, { duration: 2000 });
          this.createAndDispatch(EntityOp.SAVE_UPSERT_ONE_SUCCESS, updatedDevice);
        },
        (error) => {
          this.createAndDispatch(EntityOp.SAVE_UPSERT_ONE_ERROR, error);
        }
      );
    });
  }
}
