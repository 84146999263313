import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { GoogleAnalyticsService, MetaService } from '@haulynx/services';
import { Action, Category, FeatureFlag, MenuItem, User } from '@haulynx/types';
import { head, split } from 'lodash';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideNavComponent {
  @Input() menuItems: MenuItem[] = [];
  @Input() user: User;
  @Input() reportMode: boolean;
  @Input() sidenav: MatSidenav;
  @Input() url: string;
  @Output() startTour = new EventEmitter<unknown>();
  @Output() logs = new EventEmitter<unknown>();

  featureFlag = FeatureFlag.WHATS_NEW;

  constructor(
    private googleAnalyticsService: GoogleAnalyticsService,
    private router: Router,
    private metaService: MetaService
  ) {
    this.metaService.setMobileViewport();
  }

  checkActive(item: { link: string }): boolean {
    const [itemParent, itemChild] = split(item.link, '/');
    const [urlParent, urlChildRaw] = split(this.url, '/');
    const urlChild = head(split(urlChildRaw, '?'));
    const activeMenu = itemParent === urlParent && itemChild === urlChild;

    return activeMenu;
  }

  navTabClicked(m: { name: string; link: string; icon: string }): void {
    if (m?.name === 'Spot Admin') {
      window.open(m?.link, '_blank');
      return;
    }
    this.googleAnalyticsService.eventEmitter(Category.NAVIGATION, Action.CLICK, m.name);
    if (m.name === 'Driver Logs') {
      this.logs.emit();
    }
  }

  closeSidenav() {
    if (this.sidenav) {
      this.sidenav.close();
    }
  }

  onStartTour(): void {
    this.startTour.emit();
  }

  globalKeyboardShortCuts(key: string): void {
    for (const menu of this.menuItems) {
      if (menu.subItems) {
        for (const sub of menu.subItems) {
          if (key === 'j' && sub.name === 'Available Loads') {
            this.googleAnalyticsService.eventEmitter(Category.GLOBAL, Action.KEYPRESS, sub.name + ': j');
            this.router.navigate([sub.link]);
          }
          if (key === 'k' && sub.name === 'Active Loads') {
            this.googleAnalyticsService.eventEmitter(Category.GLOBAL, Action.KEYPRESS, sub.name + ': k');
            this.router.navigate([sub.link]);
          }
          if (key === 'l' && sub.name === 'Search') {
            this.googleAnalyticsService.eventEmitter(Category.GLOBAL, Action.KEYPRESS, sub.name + ': l');
            this.router.navigate([sub.link]);
          }
        }
      } else {
        if (key === 'j' && menu.name === 'Loads') {
          this.googleAnalyticsService.eventEmitter(Category.GLOBAL, Action.KEYPRESS, menu.name + ': j');
          this.router.navigate([menu.link]);
        }
        if (key === 'l' && menu.name === 'Carriers') {
          this.googleAnalyticsService.eventEmitter(Category.GLOBAL, Action.KEYPRESS, menu.name + ': l');
          this.router.navigate([menu.link]);
        }
      }
    }
  }
}
