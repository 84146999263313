import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@haulynx/modules';
import { RegisterCarrierComponent } from './register-carrier.component';

@NgModule({
  declarations: [RegisterCarrierComponent],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
  exports: [RegisterCarrierComponent],
})
export class RegisterCarrierModule {}
