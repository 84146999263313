import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from '@haulynx/pipes';
import { SpinnerModule } from '../spinner/spinner.module';
import { CarrierAnalyticsComponent } from './carrier-analytics.component';
import { CarrierAnalyticsInsuranceComponent } from './components/carrier-analytics-insurance';
import { CarrierAnalyticsItemComponent } from './components/carrier-analytics-item';
import { CarrierAnalyticsMarginsComponent } from './components/carrier-analytics-margins';
import { CarrierAnalyticsMetricsComponent } from './components/carrier-analytics-metrics';

@NgModule({
  declarations: [
    CarrierAnalyticsComponent,
    CarrierAnalyticsInsuranceComponent,
    CarrierAnalyticsMetricsComponent,
    CarrierAnalyticsMarginsComponent,
    CarrierAnalyticsItemComponent,
  ],
  exports: [
    CarrierAnalyticsComponent,
    CarrierAnalyticsInsuranceComponent,
    CarrierAnalyticsMetricsComponent,
    CarrierAnalyticsMarginsComponent,
  ],
  imports: [CommonModule, PipesModule, SpinnerModule],
})
export class CarrierAnalyticsModule {}
