import { Component } from '@angular/core';

@Component({
  selector: 'app-carrier-info',
  templateUrl: './carrier-info.component.html',
  styleUrls: ['./carrier-info.component.scss'],
})
export class CarrierInfoComponent {
  items = [
    { title: 'No charge for the hardware' },
    { title: 'No charge to help you install the device' },
    { title: 'No monthly charge for using the Haulynx ELD' },
  ];

  constructor() {}
}
