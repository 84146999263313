import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@haulynx/modules';
import { IconModule } from '../icon/icon.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { WhiteOutlineButtonComponent } from './white-outline-button.component';

@NgModule({
  declarations: [WhiteOutlineButtonComponent],
  imports: [CommonModule, MaterialModule, FlexLayoutModule, SpinnerModule, IconModule],
  exports: [WhiteOutlineButtonComponent],
})
export class WhiteOutlineButtonModule {}
