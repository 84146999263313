import { CustomerGeohash, Load } from '../web-interfaces';
import { LoadsServiceLoad } from './loads-service-load';

export class LoadMatch {
  id: string;
  match: string;
  price: string;
  revenue: string;
  lane: string;
  orderNumber: string;
  tmwNumber: number;
  loadCreated: number;
  loadPickupAddress: string;
  loadPickupTimestamp: number;
  loadDropoffAddress: string;
  loadDropoffTimestamp: number;
  loadEquipmentType: string;
  loadWeight: number;
  loadFeedKey: string;
  loadDistance: number;
  loadPickupLat: number;
  loadPickupLon: number;
  loadDropoffLat: number;
  loadDropoffLon: number;
  loadDropoffTimezone: string;
  loadPickupTimezone: string;
  loadPickupGeohash: CustomerGeohash;
  loadDropoffGeohash: CustomerGeohash;
  load: Load;
  loadsServiceLoad: LoadsServiceLoad;
  order: Order;
  constructor(props?: Partial<LoadMatch>) {
    Object.assign(this, props);
  }
}

export class Order {
  id: string;
  load: Load;
  company: string;
  orderNumber: number;
  sequence: number;
  status: string;
  billTo: number;
  teamRequired: boolean;
  hvp: boolean;
  hazmat: boolean;
  trailerType: string;
  pickupRequirements: string;
  unloadRequirements: string;
  masterbill: string;
  shipperNumber: string;
  consigneeNumber: string;
  backupRate: boolean;
  customerExperienceRepLastName: string;
  customerExperienceRepFirstName: string;
  customerExperienceRepId: string;
  exclusiveExpire: string;
  exclusiveExpireMs: number;
  revenue: string;
  stops: [OrderStop];
  firstStop: OrderStop;
  lastStop: OrderStop;
  updatedTime: number;
  created: string;
  createdMs: number;
  timeArray: [number];
  isBounced: boolean;
  isHaulynxAccepted: boolean;
  tmwNumber: string;
  orderType: OrderTypeUSX;
  regMgr: string;
  regEmail: string;
  regPhone: string;
  isExcluded: boolean;
  numberernalNotes: string;
  externalNotes: string;
  dedicatedCarrierDot: string;
  trailerNumber: string;
  trailerOwner: string;
  tractorId: string;
  haulynxCarrierDot: string;
  notificationsSent: boolean;
  orderAcceptMs: number;
  bookType: string;
  constructor(props?: Partial<Order>) {
    Object.assign(this, props);
  }
}

export class OrderStop {
  id: string;
  load: Load;
  company: string;
  orderNumber: number;
  sequence: number;
  status: string;
  billTo: number;
  teamRequired: boolean;
  hvp: boolean;
  hazmat: boolean;
  trailerType: string;
  pickupRequirements: string;
  unloadRequirements: string;
  masterbill: string;
  shipperNumber: string;
  consigneeNumber: string;
  backupRate: boolean;
  customerExperienceRepLastName: string;
  customerExperienceRepFirstName: string;
  customerExperienceRepId: string;
  exclusiveExpire: string;
  exclusiveExpireMs: number;
  revenue: string;
  stops: [OrderStop];
  firstStop: OrderStop;
  lastStop: OrderStop;
  updatedTime: number;
  created: string;
  createdMs: number;
  timeArray: [number];
  isBounced: boolean;
  isHaulynxAccepted: boolean;
  tmwNumber: string;
  orderType: OrderTypeUSX;
  regMgr: string;
  regEmail: string;
  regPhone: string;
  isExcluded: boolean;
  numberernalNotes: string;
  externalNotes: string;
  dedicatedCarrierDot: string;
  trailerNumber: string;
  trailerOwner: string;
  tractorId: string;
  haulynxCarrierDot: string;
  notificationsSent: boolean;
  orderAcceptMs: number;
  bookType: string;
  constructor(props?: Partial<OrderStop>) {
    Object.assign(this, props);
  }
}

export enum OrderTypeUSX {
  D = 'dedicated',
  T = 'transactional',
  C = 'contracted',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SPOT = 'spot',
}
