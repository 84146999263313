<haulynx-skeleton-loader *ngIf="loading" [pixelHeight]="mapHeight"></haulynx-skeleton-loader>
<section *ngIf="!loading" class="carrier-mapbox" [style.height.px]="mapHeight">
  <haulynx-mapbox
    [data]="mapboxRoute"
    [mapId]="mapId"
    [maxZoom]="maxZoom"
    [padding]="padding"
    [zoom]="zoom"
    [style]="mapboxTheme$ | async"
  ></haulynx-mapbox>
</section>
