import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { InvitationService } from '@haulynx/services';
import { IColumns, Invitation } from '@haulynx/types';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-invitations',
  templateUrl: './invitations.component.html',
  styleUrls: ['./invitations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvitationsComponent implements OnInit {
  invitationsColumns: IColumns[] = [
    {
      label: 'Date',
      dataIndex: 'date',
      isCustomCell: true,
    },
    {
      label: 'Inviter (broker)',
      dataIndex: 'inviter',
      isCustomCell: true,
    },
    {
      label: 'Recipient (carrier)',
      dataIndex: 'recipient',
      isCustomCell: true,
    },
    {
      label: 'Haulynx Recipient Info (carrier)',
      dataIndex: 'recipientInfo',
      isCustomCell: true,
    },
  ];
  invitations$: Observable<Invitation[]>;

  constructor(private invitationService: InvitationService) {}

  ngOnInit() {
    this.invitations$ = this.invitationService.getInvitations();
  }
}
