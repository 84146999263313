import { startCase } from 'lodash';
import { LoadHistoryActivityType } from '../../load-overview/load-history';
import { ISearchFilter, TextFilterType } from '../search-filter';
import { ISearchFilterType } from '../search-filter.types';

export class ActivityTypeFilter implements ISearchFilter {
  name = 'Activity Type';
  type: ISearchFilterType = ISearchFilterType.MULTI_DROPDOWN;
  keys: TextFilterType;
  description = 'Filter load activity by activity type';

  localFilterFn<loadHistory>(data: loadHistory[], searchbarVal: Record<string, any>): loadHistory[] {
    return data.filter((loadHistoryRow) => {
      return searchbarVal?.activityType.includes(startCase(loadHistoryRow['activityType']));
    });
  }

  constructor(defaultValues?: TextFilterType) {
    const options = Object.values(LoadHistoryActivityType).map((type) => {
      return {
        key: startCase(type),
        value: startCase(type),
      };
    });
    this.keys = new TextFilterType({
      value: '',
      optionsDataIndex: options,
      textFormName: 'activityType',
      ...defaultValues,
    });
  }
}
