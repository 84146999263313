<haulynx-sidebar-nav-sub-template (closed)="toggleSubNav(sidenav)">
  <div title>ACCOUNT</div>
  <div class="inner-content" content>
    <ng-container *ngFor="let item of headerItems">
      <div *ngIf="item.accountItem">
        <div (click)="sideItemSelect(item)" class="account-action">
          <mat-icon *ngIf="item.svgIcon" [svgIcon]="item.svgIcon" class="account-action__icon"></mat-icon>
          <mat-icon *ngIf="item.icon" class="account-action__icon">{{ item.icon }}</mat-icon>
          <span class="account-action__action">{{ item.name }}</span>
        </div>
      </div>
    </ng-container>
    <div (click)="openLogout()" class="account-action">
      <mat-icon class="account-action__icon">logout</mat-icon>
      <span class="account-action__action" data-test="logout-button">Sign Out</span>
    </div>
  </div>
</haulynx-sidebar-nav-sub-template>
