import { FeatureFlag } from './feature-flags';
import { Record } from 'immutable';

export type FFState = { [key in FeatureFlag]: boolean };

interface IFeatureFlagState {
  flags: FFState;
}

export const initialFeatureFlagState: IFeatureFlagState = {
  flags: {
    [FeatureFlag.ASSIGN_USX_TRAILERS]: false,
    [FeatureFlag.ACTIVE_LOAD_MANAGEMENT]: false,
    [FeatureFlag.BOUNCE_CARRIER]: false,
    [FeatureFlag.BROKER_BIDDING_V2]: false,
    [FeatureFlag.CARRIER_BID]: false,
    [FeatureFlag.CARRIER_DASHBOARD_V2]: false,
    [FeatureFlag.CARRIER_HEADER]: false,
    [FeatureFlag.CARRIER_LOAD_SEARCH]: false,
    [FeatureFlag.CARRIER_MANAGEMENT_DASHBOARD]: false,
    [FeatureFlag.CARRIER_TOOLBAR]: false,
    [FeatureFlag.CLS_ACTIVE_LOADS]: false,
    [FeatureFlag.DEDICATED_BUTTON]: false,
    [FeatureFlag.DEDICATED]: false,
    [FeatureFlag.DRIVER_LITE]: false,
    [FeatureFlag.EDIT_LOAD]: false,
    [FeatureFlag.FREIGHT_CONTRACTS]: false,
    [FeatureFlag.HIDE_OLD_SEARCH_BOARD]: false,
    [FeatureFlag.INLINE_CREATION_ALM]: false,
    [FeatureFlag.INLINE_CREATION]: false,
    [FeatureFlag.LANE_ANALYSIS]: false,
    [FeatureFlag.LANE_HISTORY]: false,
    [FeatureFlag.LANE_RATE_TOOL]: false,
    [FeatureFlag.LOAD_ACTIVE_EDIT_PRICE]: false,
    [FeatureFlag.LOAD_ACTIVITY]: false,
    [FeatureFlag.LOAD_DOCUMENTS]: false,
    [FeatureFlag.LOAD_BROKER_ASSIGNMENTS]: false,
    [FeatureFlag.LOAD_LOCKING]: false,
    [FeatureFlag.LOAD_OFFER_BIDDING]: false,
    [FeatureFlag.LOAD_OVERVIEW]: false,
    [FeatureFlag.LOAD_SEARCH]: false,
    [FeatureFlag.MACROPOINT]: false,
    [FeatureFlag.MAX_BUY]: false,
    [FeatureFlag.MISSIONS]: false,
    [FeatureFlag.NEW_CARRIER_RECOMMENDATIONS]: false,
    [FeatureFlag.NEW_CARRIER_RECOMMENDATIONS]: false,
    [FeatureFlag.NOTIFICATION_NEW_MATCH]: false,
    [FeatureFlag.PAY_LINE_EDIT]: false,
    [FeatureFlag.PAY_LINE_ITEMS]: false,
    [FeatureFlag.RATECON]: false,
    [FeatureFlag.RECOMMENDATION_VOTE]: false,
    [FeatureFlag.REPEAT_NOTIFICATIONS]: false,
    [FeatureFlag.TEST_LOADS]: false,
    [FeatureFlag.TRACKING_STATUS]: false,
    [FeatureFlag.TRACKING_TYPE]: false,
    [FeatureFlag.WHATS_NEW]: false,
    [FeatureFlag.EDIT_LOAD]: false,
    [FeatureFlag.LANE_HISTORY]: false,
    [FeatureFlag.CARRIER_TOOLBAR]: false,
    [FeatureFlag.PREFERRED_LANES]: false,
    [FeatureFlag.CARRIER_METRICS]: false,
    [FeatureFlag.CARRIER_COMPLIANCE]: false,
    [FeatureFlag.CARRIER_TOOLBAR_OPPORTUNITIES]: false,
    [FeatureFlag.TRAILER_SWAP]: false,
    [FeatureFlag.SPOT_ADMIN_TOOL]: false,
    [FeatureFlag.TRAILER_TRACKING]: false,
  },
};

export class FeatureFlagState extends Record(initialFeatureFlagState) implements IFeatureFlagState {
  flags: {
    [FeatureFlag.ASSIGN_USX_TRAILERS]: false;
    [FeatureFlag.ACTIVE_LOAD_MANAGEMENT]: false;
    [FeatureFlag.BOUNCE_CARRIER]: false;
    [FeatureFlag.BROKER_BIDDING_V2]: false;
    [FeatureFlag.CARRIER_BID]: false;
    [FeatureFlag.CARRIER_DASHBOARD_V2]: false;
    [FeatureFlag.CARRIER_HEADER]: false;
    [FeatureFlag.CARRIER_LOAD_SEARCH]: false;
    [FeatureFlag.CARRIER_MANAGEMENT_DASHBOARD]: false;
    [FeatureFlag.CARRIER_TOOLBAR]: false;
    [FeatureFlag.CLS_ACTIVE_LOADS]: false;
    [FeatureFlag.DEDICATED_BUTTON]: false;
    [FeatureFlag.DEDICATED]: false;
    [FeatureFlag.DRIVER_LITE]: false;
    [FeatureFlag.EDIT_LOAD]: false;
    [FeatureFlag.FREIGHT_CONTRACTS]: false;
    [FeatureFlag.HIDE_OLD_SEARCH_BOARD]: false;
    [FeatureFlag.INLINE_CREATION_ALM]: false;
    [FeatureFlag.INLINE_CREATION]: false;
    [FeatureFlag.LANE_ANALYSIS]: false;
    [FeatureFlag.LANE_HISTORY]: false;
    [FeatureFlag.LANE_RATE_TOOL]: false;
    [FeatureFlag.LOAD_ACTIVE_EDIT_PRICE]: false;
    [FeatureFlag.LOAD_ACTIVITY]: false;
    [FeatureFlag.LOAD_DOCUMENTS]: false;
    [FeatureFlag.LOAD_BROKER_ASSIGNMENTS]: false;
    [FeatureFlag.LOAD_LOCKING]: false;
    [FeatureFlag.LOAD_OFFER_BIDDING]: false;
    [FeatureFlag.LOAD_OVERVIEW]: false;
    [FeatureFlag.LOAD_SEARCH]: false;
    [FeatureFlag.MACROPOINT]: false;
    [FeatureFlag.MAX_BUY]: false;
    [FeatureFlag.MISSIONS]: false;
    [FeatureFlag.NEW_CARRIER_RECOMMENDATIONS]: false;
    [FeatureFlag.NEW_CARRIER_RECOMMENDATIONS]: false;
    [FeatureFlag.NOTIFICATION_NEW_MATCH]: false;
    [FeatureFlag.PAY_LINE_EDIT]: false;
    [FeatureFlag.PAY_LINE_ITEMS]: false;
    [FeatureFlag.RATECON]: false;
    [FeatureFlag.RECOMMENDATION_VOTE]: false;
    [FeatureFlag.REPEAT_NOTIFICATIONS]: false;
    [FeatureFlag.TEST_LOADS]: false;
    [FeatureFlag.TRACKING_STATUS]: false;
    [FeatureFlag.TRACKING_TYPE]: false;
    [FeatureFlag.WHATS_NEW]: false;
    [FeatureFlag.EDIT_LOAD]: false;
    [FeatureFlag.LANE_HISTORY]: false;
    [FeatureFlag.CARRIER_TOOLBAR]: false;
    [FeatureFlag.PREFERRED_LANES]: false;
    [FeatureFlag.CARRIER_METRICS]: false;
    [FeatureFlag.CARRIER_COMPLIANCE]: false;
    [FeatureFlag.CARRIER_TOOLBAR_OPPORTUNITIES]: false;
    [FeatureFlag.TRAILER_SWAP]: false;
    [FeatureFlag.SPOT_ADMIN_TOOL]: false;
    [FeatureFlag.TRAILER_TRACKING]: false;
  };
}
