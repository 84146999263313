<div class="search-location-container" fxLayout="row">
  <mat-form-field class="example-full-width" appearance="outline" floatLabel="always" fxFlex>
    <input
      matInput
      class="mobile-search-input"
      [matAutocomplete]="auto"
      [formControl]="searchForm?.get('address')"
      [placeholder]="label === 'origin' ? 'Origin...' : 'Destination...'"
      data-test="searchFieldMobile"
    />
    <mat-autocomplete 
      class="dest-orig-mobile"
      #auto="matAutocomplete" 
      (optionSelected)="setPrediction($event)" 
      (opened)="autocompleteOpened()"
    >
      <mat-option [value]="'Current Location'" data-test="currentLocation">
        <span>Current Location</span>
      </mat-option>
      <ng-container *ngIf="searchQueryStarted; else recentSearchesTemplate">
        <mat-option *ngFor="let prediction of predictions$ | async" [value]="prediction" data-test="searchPrediction">
          <span>{{ prediction?.place_name ?? '' }}</span>
        </mat-option>
      </ng-container>
      <ng-template #recentSearchesTemplate>
        <mat-option *ngFor="let recentSearch of recentSearches" [value]="recentSearch" data-test="recentSearch"
          ><span>{{ recentSearch?.place_name ?? '' }}</span></mat-option>
      </ng-template>
    </mat-autocomplete>
  </mat-form-field>
  <haulynx-blue-select
    [options]="radiusValues"
    [formControl]="searchForm?.get('radiusMiles')"
    class="radius-select"
    data-test="radiusSelect"
    fxFlex="20"
  >
  </haulynx-blue-select>
</div>
