<div class="terms-of-service-container" #termsOfService (scroll)="checkScroll()">
  <b>XPRESS TECHNOLOGIES TERMS OF SERVICE</b><br /><br />

  Please read the following Terms of Service (“Terms”) carefully because they govern your use of the Xpress
  Technologies, Inc. ("Xpress Technologies," "we" or "us") website, the Xpress Technologies Electronic Logging Device
  (ELD), the Xpress Technologies AOBRD device, the Xpress Technologies application, the Xpress Technologies network, and
  other associated Xpress Technologies products. By using our Services, you agree to be bound by these Terms of Service.
  If you have any questions regarding the terms set forth in this agreement, you may contact us at
  carrierxperience@usxpress.com.
  <br /><br />
  1. Description of Service
  <br /><br />
  1.1. The "Service(s)" means Xpress Technologies's Electronic Logging Device, the Xpress Technologies AOBRD device, the
  associated fleet management and load distribution software, the Xpress Technologies website, the Xpress Technologies
  mobile application, and all associated software. The Services also include all associated hardware, including the
  Electronic Logging Device, cables, display devices, and accessories ("Hardware"), and all of Our Content (as defined
  below). The Services do not include Your Data (as defined below), or any software application or service that is
  provided by you or a third party, which you use in connection with the Services, whether or not they constitute a
  Xpress Technologies integrated product or a Non-Xpress Technologies product. Any modifications or additional features
  added to Xpress Technologies’ Service in the future are also governed by these terms. All rights, title and interest
  in and to the Service and its components (including all intellectual property rights) belong exclusively to Xpress
  Technologies, including the rights to any text, graphics, images, music, software, audio, video, documents, works of
  authorship of any kind, de-identified and aggregated data collected by the Services, and information or other
  materials that are posted, generated, provided or otherwise made available by us through the Services ("Our Content").
  <br /><br />
  2. Agreement to Terms
  <br /><br />
  2.1. BY USING OUR SERVICES, YOU AGREE TO BE BOUND BY THESE TERMS INCLUDING ANY POLICIES OR OTHER TERMS REFERENCED IN
  OR INCORPORATED BY THESE TERMS (INCLUDING THE PRIVACY POLICY). BY ACCEPTING THIS AGREEMENT, SIGNING UP TO USE ANY
  Xpress Technologies SERVICES OR ANY OTHER SERVICES THAT REFERENCE THIS AGREEMENT, YOU AGREE TO THE TERMS OF THIS
  AGREEMENT. IF YOU ARE ENTERING INTO THIS AGREEMENT ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, YOU REPRESENT THAT
  YOU HAVE THE AUTHORITY TO BIND SUCH ENTITY AND ITS AFFILIATES TO THESE TERMS AND CONDITIONS, IN WHICH CASE THE TERMS
  "YOU" OR "YOUR" SHALL REFER TO SUCH ENTITY AND ITS AFFILIATES. IF YOU DO NOT HAVE SUCH AUTHORITY, OR IF YOU DO NOT
  AGREE WITH THESE TERMS AND CONDITIONS, YOU MUST NOT ACCEPT THIS AGREEMENT AND MAY NOT USE THE XPRESS TECHNOLOGIES
  SERVICES.
  <br /><br />
  2.2. PLEASE BE AWARE THAT THIS AGREEMENT IS SUBJECT TO AN ARBITRATION CLAUSE LOCATED IN SECTION 13 OF THIS AGREEMENT.
  PLEASE READ THIS SECTION CAREFULLY AS IT MAY AFFECT YOUR LEGAL RIGHTS AND THE RIGHT TO FILE A LAWSUIT IN COURT, YOUR
  RIGHT TO A JURY TRIAL AND YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION.
  <br /><br />
  3. Your Access and Use of the Services
  <br /><br />
  3.1. You may access and use the Services only for lawful, authorized purposes and you shall not misuse the Services in
  any manner (as determined by Xpress Technologies in its sole discretion). See Section 11 below for further provisions
  outlining prohibited uses of the Services. You must comply with any codes of conduct, policies, storage limitations,
  or other notices Xpress Technologies provides you or publishes in connection with the Services from time to time, but
  if any of those policies materially change the Terms, we will provide you with reasonable notice as provided in
  Section 5 below.
  <br /><br />
  3.2. Any Software or Hardware or any of Our Content that may be made available by or on behalf of Xpress Technologies
  in connection with the Service contains proprietary and confidential information that is protected by applicable
  intellectual property and other laws. Subject to these Terms, Xpress Technologies only grants you a personal,
  non-exclusive and revocable license to use the object code of any Software solely in connection with the Service. You
  may not sublicense any rights or license granted by this Agreement. Any rights not expressly granted herein are
  reserved.
  <br /><br />
  3.3. Subject to your compliance with these Terms, Xpress Technologies grants you a limited, non-exclusive,
  non-transferable, and revocable license to download and install a copy of the Xpress Technologies App on a mobile
  device or computer that you own or control and to run such copy of the App. Xpress Technologies reserves all rights in
  and to the App not expressly granted to you under these Terms.
  <br /><br />
  3.4. You may not share your Account information with, or allow access to your Account by, any third party. You are
  solely responsible for all activity that occurs under your access credentials, whether or not a third party accesses
  your Account.
  <br /><br />
  3.5. Notwithstanding Section 3.4, you may allow other certain users, e.g. employees, independent contractors, and
  other third parties, ("End Users") to access the Services in accordance with these Terms. To the extent that you
  create any Accounts for other users of the Services, direct us to create any Accounts for End Users, or allow End
  Users to access the Services, you are responsible for all End Users' activity and ensuring that they are in compliance
  with these Terms.
  <br /><br />
  3.6. You may specify certain End Users as "Administrators" through the administrative console. Administrators may have
  the ability to access, disclose, restrict or remove Your Data in or from Services accounts. Administrators may also
  have the ability to monitor, restrict, or terminate access to Services accounts. Xpress Technologies's
  responsibilities do not extend to the internal management or administration of the Services, and you are responsible
  for the activities of any Administrator and ensuring that any Administrator is in compliance with these Terms.
  <br /><br />
  3.7. To the extent that you allow End Users and Administrators to access the Services or create, or direct us to
  create, any Accounts for other users, you are responsible for (i) maintaining the confidentiality of usernames and
  passwords, (ii) managing access to End User Accounts and other Accounts which you create, and (iii) ensuring that all
  End Users and users of other Accounts which you create are in compliance with these Terms.
  <br /><br />
  3.8. To the extent that you allow End Users to use the Hardware, you are solely responsible for such End Users' use of
  the Hardware, including any damage to or misuse of such Hardware.
  <br /><br />
  3.9. You agree to prevent any unauthorized access or use by End Users or other users of Accounts and terminate any
  unauthorized use of or access to the Services.
  <br /><br />
  3.10. You may not sell, resell or lease the Services unless you have entered into a separate agreement with Xpress
  Technologies to do so.
  <br /><br />
  3.11. You shall promptly notify Xpress Technologies if you learn of a security breach related to the Service.
  <br /><br />
  3.12. You assume sole responsibility for your driving logs and for complying with all state and federal regulation
  including, but not limited to, FMCSA Hours of Service of drivers.
  <br /><br />
  4. Your Data, Your Privacy, Your Ownership of Your Data, and Licenses for Our Use of Your Data
  <br /><br />
  4.1. Xpress Technologies takes the privacy of its users seriously. A complete copy of the Xpress Technologies Privacy
  Policy is available at https://haulynx.com/privacy-policy.
  <br /><br />
  4.2. "Your Data" means any data and content you create, post, upload, transmit or otherwise make available via the
  Services (which may include data you elect to import from Non-Xpress Technologies Products you use). "Your Data"
  includes logs you create, location coordinates that are collected, messages you send, documents you upload, profile
  information and anything else you enter or upload into the Service. Xpress Technologies will make commercially
  reasonable efforts to ensure that all facilities used to store and process Your Data meet a high standard for
  security. For more information on our current practices and policies regarding data privacy, security and
  confidentiality, please see our Privacy Policy; we keep that document updated as these practices and policies evolve
  over time.
  <br /><br />
  4.3. Your acceptance of these Terms does not grant us full ownership of all of Your Data. As between Xpress
  Technologies and you, you are only granting us the rights and licenses necessary to provide our Services to you and
  any other services for which you have signed up through any of our resellers, business partners and affiliated
  businesses (This includes Shippers and Brokers who use the Xpress Technologies network to distribute loads). In order
  for us to provide the Services to you, we need to be able to transmit, store, copy and analyze Your Data. In addition,
  we need to be able to use the information contained in your data to provide you with the Services that we, our
  business partners, and affiliates make available to you. This information may include the location of your vehicles,
  the available hours of service for the driver of your vehicle, and other information related to the location and
  operation of your equipment. We will also use this information in order to display it to you and to those with whom
  you share it, to index it so you are able to search it, to make backups to prevent data loss, to send your submissions
  and messages through our platform, and so on.
  <br /><br />
  For example, if you submit documents or send messages via the Xpress Technologies network, you are granting us a
  royalty-free, perpetual, sub licensable, irrevocable and worldwide license for us to transform, modify, reproduce,
  display, perform and distribute such documents and messages for your and other members of the Xpress Technologies
  networks use of the Services.
  <br /><br />
  Your acceptance of these Terms gives us the permission to do so and grants us any such rights and licenses necessary
  to provide the Service to you, and any other services or products for which you have signed up through any of our
  resellers, business partners and affiliated businesses. For example, if you have agreed to share any of Your Data, or
  any other data we collect in connection with your use of the Services, with one of our partners or affiliates or other
  third party, you grant us the rights and licenses to share or deliver such data. This permission includes allowing us
  to use third-party service providers (such as Google or Amazon Web Services) in the operation and administration of
  the Service and the rights granted to us are extended to these third parties to the degree necessary in order for the
  Service to be provided.
  <br /><br />
  If you authorize a third party to receive Your Data, we may provide Your Data to a third party. If we have an
  agreement with such third party to market the Services or otherwise provide services or products to you, either
  jointly or separately, we may rely on a representation from that third party that you have authorized such third party
  to receive Your Data.
  <br /><br />
  FOR CLARIFICATION PURPOSES, YOU GRANT US THE NECESSARY LICENSES TO YOUR DATA FOR US TO CREATE AGGREGATED AND
  DE-IDENTIFIED DATA FOR OUR USE IN PROVIDING THE SERVICES AND OTHERWISE. NOTWITHSTANDING ANYTHING ELSE IN THESE TERMS,
  WE MAY USE AND SELL SUCH AGGREGATED AND DE-IDENTIFIED DATA, WHICH IS COMPILED FROM OUR USERS, AND DOES NOT DIRECTLY
  IDENTIFY YOU, FOR ANY PURPOSE WE SEE FIT.
  <br /><br />
  Please see the Privacy Policy for further information on how we may use Your Data.
  <br /><br />
  4.4. You are solely responsible for your conduct (including by and between all users), the content of Your Data, and
  all communications with others while using the Services. You represent and warrant that you own all Your Data or you
  have all rights that are necessary to grant us the license rights in Your Data under these Terms. You also represent
  and warrant that neither Your Data, nor your use and provision of Your Data, nor any use of Your Data by Xpress
  Technologies on or through the Services will infringe, misappropriate or violate a third party's intellectual property
  rights, or rights of publicity or privacy, or result in the violation of any applicable law or regulation. We are not
  responsible for the accuracy, appropriateness, or legality of Your Data or any other information you and your users
  may be able to access using the Services. Should you be in violation of these Terms, we have the right to remove any
  of Your Data causing such violation.
  <br /><br />
  4.5. The Services provide features that allow you and your End Users to share Your Data and other materials with
  others. For example, certain information and data must be shared with Shippers and Brokers in order for them to offer
  you loads via the Xpress Technologies network. When you choose to share Your Data through our Services, you are
  granting us the necessary rights and licenses to do so. Please consider carefully what you allow to be shared.
  <br /><br />
  4.6. While you retain ownership in all rights to your feedback and suggestions, you agree that by submitting
  suggestions or other feedback regarding our Services or Xpress Technologies, Xpress Technologies may use such feedback
  for any purpose without compensation to you. We appreciate all of your feedback and suggestions, and you can submit
  feedback by emailing us at feedback@Xpress Technologies.com.
  <br /><br />
  4.7. The Children's Online Privacy Protection Act ("COPPA") requires that online service providers obtain parental
  consent before they knowingly collect personally identifiable information online from children who are under 13. We do
  not knowingly collect or solicit personally identifiable information from children under 13; if you are a child under
  13, please do not attempt to register or sign up for the Services or send any personal information about yourself to
  us. If we learn we have collected personal information from a child under 13, we will delete that information as
  quickly as possible. If you believe that a child under 13 may have provided us personal information, please contact us
  at carrierxperience@usxpress.com.
  <br /><br />
  5. Changes and Modifications to Terms of Service
  <br /><br />
  5.1. We may modify these Terms at any time, in our sole discretion. If we do so, we'll let you know either by posting
  the modified Terms on the Site or through other communications. It's important that you review the modified Terms
  because if you continue to use the Services after we've let you know that the Terms have been modified, you are
  indicating to us that you agree to be bound by the modified Terms. If you don't agree to be bound by the modified
  Terms then you may no longer use the Services. Because our Services are evolving over time we may change or
  discontinue all or any part of the Services, at any time and without notice, at our sole discretion.
  <br /><br />
  6. Limitations on Services and Third-Party Relationships
  <br /><br />
  6.1. Xpress Technologies is not a party to any relationships or agreements between you and any third parties for the
  performance of any duties between the parties, and does not have control over and disclaims all liability for the
  quality, timing, legality, failure to provide, or any other aspect whatsoever of any professional duties performed by
  you or other users of the Services. Xpress Technologies acts only as an interface to facilitate -- not to direct or
  control -- communications between users of Xpress Technologies's Services.
  <br /><br />
  7. Who May Use the Xpress Technologies Services
  <br /><br />
  7.1. You may use the Services only if you are 18 years or older and capable of forming a binding contract with Xpress
  Technologies and are not barred from using the Services under applicable law.
  <br /><br />
  7.2. If you want to access and use the Services, you'll have to create an account ("Account"). You can do this via the
  Services. It's important that you provide us with accurate, complete and up-to-date information for your Account and
  you agree to update such information to keep it accurate, complete and up-to-date. If you don't, we might have to
  suspend or terminate your Account. You agree that you won't disclose your Account password to anyone and you'll notify
  us immediately of any unauthorized use of your Account. You're responsible for all activities that occur under your
  Account, whether or not you know about them.
  <br /><br />
  7.3. Shippers, Carriers, and Freight Brokers. If you are using the Xpress Technologies network as a commercial
  shipper, agent or broker (collectively, “Shippers”),or as a Carrier, you also agree to the additional terms in this
  section:
  <br /><br />
  Any Shipper must be a bona fide shipper, freight broker, third-party logistics provider (“3PL”), freight forwarder,
  motor carrier, or other properly licensed entity to operate and to enter into an agreement of this nature. You must
  maintain all proper legal authority to conduct any transaction via the Xpress Technologies network. You will cease to
  use the Service immediately if for any reason you no longer maintain such authority. You will not represent yourselves
  as operating under the authority of any company without express permission from such company. You will not attempt to
  broker freight without proper legal authority. You will not enter into any transaction to transport freight without
  the appropriate carrier authority. You will not enter into any transaction to transport freight without the
  appropriate level of insurance coverage or bond, nor will you misrepresent the level of insurance coverage or bond you
  have in place. You acknowledge that your use of the Xpress Technologies network is solely for your commercial purposes
  related to your movement of freight or other services and that you may not reproduce, republish, resell, or distribute
  such information in any format, in whole or in part, for sale or commercial use by third parties. You acknowledge that
  you are responsible for verifying the accuracy of any information provided via the Xpress Technologies network,
  including but not limited to any information about loads, trucks or equipment. You may not rely solely on the
  information provided to you via the Xpress Technologies network, and agree that you will independently verify any such
  information. You agree that neither Xpress Technologies, nor the Xpress Technologies network, is liable to your or
  responsible to you or the recipient of your shipments for any shipments you contract for through our Service that may
  become lost or cannot be located while in transit. You agree that tracking the locations of your shipments through the
  Service does not guarantee your shipments will be delivered at the time you specified. You agree not to ship and track
  through our Service contraband, stolen products, products that are illegal to transport via freight, or any other
  products we consider inappropriate. Xpress Technologies does not provide transportation services, and the Service is
  not a transportation carrier. It is up to the third party transportation provider, driver or vehicle operator to offer
  transportation services which may be scheduled through use of the Xpress Technologies network. The Service offers
  information and a method to obtain such third party transportation services but does not and does not intend to
  provide transportation services in any way as a transportation carrier, and has no responsibility or liability for any
  transportation services provided to you by such third parties. As a Carrier, we will use the information you provide
  us and the Data we obtain to suggest, offer, promote or connect you with potential loads or 3PL parties. You also
  approve our use of and display of your Carrier safety ratings and insurance certificate, as provided by the FMCSA, a
  third party, or any other authority, and agree that Xpress Technologies shall have no liability to you or any third
  party for any incorrect or inaccurate data contained in such ratings. By using the Service you agree your contact
  information may be displayed, used, and accessed by any other user of the Xpress Technologies network. Contact
  information includes but is not limited to, your name, your company name, your MC and/or DOT numbers, your phone
  number and your email address. You understand and acknowledge that it is the users of the Service who provide the
  contact information and that Xpress Technologies makes no warranty regarding the accuracy of any such contact
  information. Location Based Services. The Xpress Technologies network relies on location, hours of service, and other
  data obtained from the ELD and AOBRD devices located in your trucks to offer loads to Carriers. Xpress Technologies,
  its affiliates, and certain third-party vendors may also offer additional features and services based on this data. BY
  SIGNING UP FOR THIS SERVICE YOU ARE AGREEING TO ALLOW Xpress Technologies, ITS AFFILIATES, AND CERTAIN THIRD-PARTY
  VENDORS TO USE THIS DATA TO OFFER YOU LOADS AND OTHER SERVICES. PLEASE NOTE THAT LOCATION DATA MAY NOT BE ACCURATE,
  WHETHER COLLECTED ORIGINALLY BY THE APP OR A THIRD PARTY APPLICATION, AND Xpress Technologies DISCLAIMS ANY AND ALL
  WARRANTIES RELATED TO LOCATION DATA AND LOCATION BASED SERVICES. Xpress Technologies may use third party services as
  part of its Location-based-Services, including but not limited to Google maps. By using our apps you agree to be bound
  to the terms of such Location-based-Services. For Google such terms can be found here: XXXX.
  <br /><br />
  <br /><br />
  <br /><br />
  8. Warnings About Connections Between Drivers and Companies
  <br /><br />
  8.1. The Services enable a commercial motor vehicle driver ("Driver") to connect directly with a motor carrier
  ("Company"), allowing Drivers to send messages and automatically share their driving logs, documents, location
  information and other data ("Driver Data") with the Company. When Drivers connect with a Company through the Services,
  the Company's employees and other third parties who are also connected to that Company ("Fleet Users") may access
  Driver Data for all Drivers who are connected to that same Company.
  <br /><br />
  8.2. If a Driver connects with a Company, the Driver should be aware that the Driver's Driver Data will be
  automatically shared with that Company and its Fleet Users and that Company and its Fleet Users will be able to send
  messages to that Driver. Please consider carefully the parties with whom you choose to connect and what you choose to
  share. Xpress Technologies's role in this process is solely to provide the technology and platform that enables these
  Services. Please see Sections 3 & 4, above, and Section 11, below, as well as the rest of these Terms, for further
  specific provisions outlining your responsibility for anything shared through the Services.
  <br /><br />
  9. ALL XPRESS TECHNOLOGIES SOFTWARE AND HARDWARE IS PROVIDED TO CARRIERS FREE OF CHARGE. NO CARRIER IS EVER CHARGED
  FOR XPRESS TECHNOLOGIES SERVICES.
  <br /><br />
  9.2. Xpress Technologies Receives Compensation From Third-Parties. Xpress Technologies does receive compensation from
  Freight Brokers and Shippers who use its Services to distribute loads to Carriers. Xpress Technologies does not charge
  Carriers for load distribution, but you acknowledge that the fact that Freight Brokers and Shippers are required to
  compensate Xpress Technologies may have some effect on the amount of compensation you receive for a load. Xpress
  Technologies may also receive compensation from other third-parties who provide goods and services to Carriers through
  the Xpress Technologies network.
  <br /><br />
  9.1 Hardware Installation. You are solely responsible for installing the Hardware in accordance with Xpress
  Technologies's written instructions. You acknowledge that you're aware that in order to install the Hardware you may
  choose to drill holes in your vehicle, and you agree that Xpress Technologies is not liable for any cost, expense or
  damages arising from the installation of the Hardware. Xpress Technologies is not responsible for any damage arising
  from installation of the Hardware that does not follow Xpress Technologies's written instructions. In some instances,
  Xpress Technologies may assist you in installing hardware into your vehicles.
  <br /><br />
  9.2. Terms Related to Xpress Technologies-Owned Hardware
  <br /><br />
  (i) Ownership of the Hardware. Notwithstanding delivery of the Hardware to you and your license to use the Services,
  unless you have purchased the Hardware in full from Xpress Technologies, Xpress Technologies retains ownership of the
  Hardware and the software contained therein. The hardware is provided solely in conjunction with the use of Xpress
  Technologies Electronic Logging Device, AOBRD Device, and associated Services. If you do not install the hardware, and
  activate your account within thirty (30) days of the receipt of the hardware you must return it to Xpress Technologies
  pursuant to the provisions contained in Paragraph 9.2(ii).
  <br /><br />
  (ii) Return of Xpress Technologies-Owned Hardware. At the end of the initial term specified on the Order Form or as
  such term is extended by further agreement between you and Xpress Technologies ("Term"), or if the Services have been
  terminated for any other reason, you must, at your own expense, uninstall, package and return the Hardware to Xpress
  Technologies within 30 days of the end of the Term, in reasonable condition allowing for wear and tear. If we haven't
  received the Hardware within 30 days of termination of the services, or if the Hardware is returned in damaged
  condition, we will charge you $100 per unit of unreturned or damaged Hardware, so please ensure that you promptly
  return your Hardware.
  <br /><br />
  (iii) Your Responsibility for the Hardware. Except for with respect to our gross negligence or willful misconduct, you
  are responsible for the loss of, damage to, or the entire cost of any necessary service or repair of the Hardware. You
  have no right to sell, give away, transfer, pledge, mortgage, alter or tamper with the Hardware.
  <br /><br />
  (iv) Material Defects. If you report to us, in writing, a material defect in the Hardware we will determine, at our
  sole discretion, the proper course of action to take. This may include a diagnostic test of the Hardware or a complete
  replacement. You agree that Xpress Technologies will not compensate you in any way for a material defect to the
  Hardware. After all, the Service is free.
  <br /><br />
  9.3. Customer Service. In the event the Hardware does not operate, please contact us by emailing us at
  carrierxperience@usxpress.com or by calling us at (602) 325-1240.
  <br /><br />
  9.4. Limited Warranty; Disclaimers
  <br /><br />
  9.4.1. We warrant that during the applicable subscription term for the Services, the Services and Hardware will be
  free of defects in materials and workmanship under normal use and service and shall substantially conform with
  instruction manuals, user guides, and other information provided by Xpress Technologies, or posted to its website,
  provided that such warranty is limited by these Terms; provided, however, that the remedies provided for in this
  Section 9 and the Hardware Warranty, if applicable to you, are the only remedies available for defective Hardware
  and/or Services.
  <br /><br />
  9.4.2. The Hardware connects to the battery of your vehicle and consumes a small drain on your vehicle's battery,
  which may adversely affect your vehicle while not in operation. Xpress Technologies is not liable for any consequences
  of the battery drain associated with use of the Hardware or the Services.
  <br /><br />
  9.4.3. The warranties provided herein and any remedies for such warranties are null and void in the event of (a) any
  alteration, modification or special configuration or installation or removal of the Hardware, the embedded software or
  the Software without following Xpress Technologies's written instructions; (b) accidents affecting the Hardware,
  misuse, tampering with, abuse, neglect or damage to the Hardware; (c) use of the Services not in the ordinary course
  of your business operations; (d) connecting the Hardware to an improper voltage supply; (e) water, weather or physical
  damage; or (f) use of the Hardware with accessories and/or devices which are not approved by Xpress Technologies.
  <br /><br />
  10. Privacy Policy
  <br /><br />
  Please refer to our Privacy Policy for information on how we collect, use and disclose information from our users. Our
  Privacy Policy can be found at http://www.Xpress Technologies.com/privacy.
  <br /><br />
  11. General Prohibitions
  <br /><br />
  <br /><br />
  11.1. IT IS STRICTLY FORBIDDEN TO USE THE Xpress Technologies APP WHILE DRIVING. OBVIOUSLY IT IS EXTREMLY DANGEROUS TO
  OPERATE A TRACTOR TRAILOR WHILE READING AND PROCESSING INFORMATION FROM A SCREEN. PULL OVER TO A SAFE AREA BEFORE
  USING THE APP.
  <br /><br />
  11.2. You further agree not to do any of the following:
  <br /><br />
  11.2.1. Post, upload, publish, submit or transmit anything that: (i) infringes, misappropriates or violates a third
  party's patent, copyright, trademark, trade secret, moral rights or other intellectual property rights, or rights of
  publicity or privacy; (ii) violates, or encourages any conduct that would violate, any applicable law or regulation or
  would give rise to civil liability; (iii) is fraudulent, false, misleading or deceptive; (iv) is defamatory, obscene,
  pornographic, vulgar or offensive; (v) promotes discrimination, bigotry, racism, hatred, harassment or harm against
  any individual or group; (vi) is violent or threatening or promotes violence or actions that are threatening to any
  person or entity; or (vii) promotes illegal or harmful activities or substances;
  <br /><br />
  11.2.2. Use, display, mirror or frame the Services, or any individual element within the Services, Xpress
  Technologies's name, any Xpress Technologies trademark, logo or other proprietary information, or the layout and
  design of any page or form contained on a page, without Xpress Technologies's express written consent;
  <br /><br />
  11.2.3. Access, tamper with, or use non-public areas of the Services, Xpress Technologies's computer systems, or the
  technical delivery systems of Xpress Technologies's providers;
  <br /><br />
  11.2.4. Attempt to probe, scan, or test the vulnerability of any Xpress Technologies system or network or breach any
  security or authentication measures;
  <br /><br />
  11.2.5. Avoid, bypass, remove, deactivate, impair, descramble or otherwise circumvent any technological measure
  implemented by Xpress Technologies or any of Xpress Technologies's providers or any other third party (including
  another user) to protect the Services;
  <br /><br />
  11.2.6. Attempt to access or search the Services or download any Software or Our Content from the Services through the
  use of any engine, software, tool, agent, device or mechanism (including spiders, robots, crawlers, data mining tools
  or the like) other than the software and/or search agents provided by Xpress Technologies or other generally available
  third party web browsers;
  <br /><br />
  11.2.7. Send any unsolicited or unauthorized advertising, promotional materials, email, junk mail, spam, chain letters
  or other form of solicitation;
  <br /><br />
  11.2.8. Use any meta tags or other hidden text or metadata utilizing a Xpress Technologies trademark, logo, URL or
  product name without Xpress Technologies's express written consent;
  <br /><br />
  11.2.9. Use the Services for the benefit of any third party or in any manner not permitted by these Terms;
  <br /><br />
  11.2.10. Forge any TCP/IP packet header or any part of the header information in any email or newsgroup posting, or in
  any way use the Services to send altered, deceptive or false source-identifying information;
  <br /><br />
  11.2.11. Attempt to decipher, decompile, disassemble or reverse engineer any of the software used to provide the
  Services;
  <br /><br />
  11.2.12. Interfere with, or attempt to interfere with, the access of any user, host or network, including, without
  limitation, sending a virus, overloading, flooding, spamming, or mail-bombing the Services;
  <br /><br />
  11.2.13. Collect or store any personally identifiable information from the Services from other users of the Services
  without their express permission;
  <br /><br />
  11.2.14. Impersonate or misrepresent your affiliation with any person or entity;
  <br /><br />
  11.2.15. Violate any applicable law or regulation; or
  <br /><br />
  11.2.16. Encourage or enable any other individual to do any of the foregoing.
  <br /><br />
  11.3. Although we're not obligated to monitor access to or use of the Services or to review or edit any of Services or
  Your Data, we have the right to do so for the purpose of operating the Services, to ensure compliance with these
  Terms, or to comply with applicable law or other legal requirements. We reserve the right, but are not obligated, to
  remove or disable access to any of Our Content or Your Data, at any time and without notice, including, but not
  limited to, if we, at our sole discretion, consider any of Our Content or Your Data to be objectionable or in
  violation of these Terms. We have the right to investigate violations of these Terms or conduct that affects the
  Services. We may also consult and cooperate with law enforcement authorities to prosecute users who violate the law.
  <br /><br />
  12. Additional Terms for Apple Apps
  <br /><br />
  These Terms apply to your use of all the Services, including the iPhone and iPad applications available via the Apple,
  Inc. ("Apple") App Store (each an "App Store App"), but the following additional terms also apply to App Store Apps:
  <br /><br />
  (i) Both you and Xpress Technologies acknowledge that the Terms are concluded between you and Xpress Technologies
  only, and not with Apple, and that Apple is not responsible for App Store Apps or the Content;
  <br /><br />
  (ii) The App Store Apps are licensed to you on a limited, non-exclusive, non-transferable, non-sublicensable basis,
  solely to be used in connection with the Services for your private, personal, non-commercial use, subject to all the
  terms and conditions of these Terms as they are applicable to the Services;
  <br /><br />
  (iii) You will only use the App Store Apps in connection with an Apple device that you own or control;
  <br /><br />
  (iv) You acknowledge and agree that Apple has no obligation whatsoever to furnish any maintenance and support services
  with respect to the App Store Apps;
  <br /><br />
  (v) In the event of any failure of the App Store Apps to conform to any applicable warranty, including those implied
  by law, you may notify Apple of such failure; upon notification, Apple's sole warranty obligation to you will be to
  refund to you the purchase price, if any, of the App Store App;
  <br /><br />
  (vi) You acknowledge and agree that Xpress Technologies, and not Apple, is responsible for addressing any claims you
  or any third party may have in relation to the App Store App;
  <br /><br />
  (vii) You acknowledge and agree that, in the event of any third party claim that the App Store App or your possession
  and use of the App Store App infringes that third party's intellectual property rights, Xpress Technologies, and not
  Apple, will be responsible for the investigation, defense, settlement and discharge of any such infringement claim;
  <br /><br />
  (viii) You represent and warrant that you are not located in a country subject to a U.S. Government embargo, or that
  has been designated by the U.S. Government as a "terrorist supporting" country, and that you are not listed on any
  U.S. Government list of prohibited or restricted parties;
  <br /><br />
  (ix) Both you and Xpress Technologies acknowledge and agree that, in your use of the App Store App, you will comply
  with any applicable third party terms of agreement which may affect or be affected by such use; and
  <br /><br />
  (x) Both you and Xpress Technologies acknowledge and agree that Apple and Apple's subsidiaries are third party
  beneficiaries of these Terms, and that upon your acceptance of these Terms, Apple will have the right (and will be
  deemed to have accepted the right) to enforce these Terms against you as the third party beneficiary hereof. 13.
  Arbitration Agreement, Dispute Resolution and Releases
  <br /><br />
  PLEASE READ THIS SECTION CAREFULLY AS IT MAY AFFECT YOUR LEGAL RIGHTS AND YOUR RIGHT TO FILE A LAWSUIT IN COURT, YOUR
  RIGHT TO A JURY TRIAL AND YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION.
  <br /><br />
  Unless otherwise agreed to by both parties in writing, you and Xpress Technologies agree that, in order to expedite
  and control the cost of any disputes, any legal or equitable claim arising out of or relating in any way to your use
  of the Services, or any use of the Services by a user to whom you provide access to your Account, or arising out of or
  relating to these Terms and the Hardware Warranty ("Claim") will be resolved as follows:
  <br /><br />
  13.1. Informal Dispute Resolution. Except with respect to Claims listed in Section 13.6, below, you and Xpress
  Technologies agree to attempt to resolve any disputes informally before initiating any arbitration or other formal
  proceedings, and neither of us may start an arbitration or other formal proceeding for at least 30 days after you
  contact us or we contact you regarding any dispute ("Informal Negotiation Period").
  <br /><br />
  13.2. Agreement to Arbitrate. If such dispute is not resolved within 30 days, you or Xpress Technologies may initiate
  a formal arbitration proceeding in accordance with the terms in this Section 13. Except with respect to Claims listed
  in Section 13.6, below, you and Xpress Technologies agree to resolve any Claims relating to or arising from the
  Services, these Terms or the Hardware Warranty through final and binding arbitration in accordance with the terms in
  this Section 13.
  <br /><br />
  13.3. Arbitration Notice. If either you or Xpress Technologies chooses to start an arbitration proceeding, the party
  initiating the proceeding will send a notice of its Claim ("Arbitration Notice") to the other party. You will send
  your Arbitration Notice by email to carrierxperience@usxpress.com and by U.S. mail to:
  <br /><br />
  Xpress Technologies 1375 N Scottsdale Rd Suite 370, Scottsdale, AZ 85257
  <br /><br />
  13.4. Arbitration Procedures. Any arbitration proceedings between you and us will be conducted under the commercial
  rules then in effect for the American Arbitration Association ("AAA"), except with respect to the provision of this
  agreement which bars class actions in Section 13.8 and any specific rules and procedures explicitly discussed in this
  Section 13.4. The award rendered by the arbitrator(s) shall include costs of arbitration, reasonable costs of expert
  and other witness and reasonable attorneys' fees. For claims less than $10,000, we will advance to you AAA's filing
  fees; provided, however, that such fees must be repaid to Xpress Technologies if Xpress Technologies prevails in the
  arbitration. Please see this link for the AAA's current rules and procedures or contact the AAA at 1-800-778-7879.
  <br /><br />
  13.5. Non-Appearance-Based Arbitration Option. Except with respect to the exceptions to arbitration below in Section
  13.6, for Claims where the total amount of the award sought in arbitration is less than $10,000, the party seeking the
  award may choose non-appearance-based arbitration. If non-appearance-based arbitration is elected, the arbitration
  will be conducted online, by telephone, and/or solely based on written submissions to the arbitrator. The specific
  manner shall be chosen by the party initiating arbitration. Non-appearance-based arbitration involves no personal
  appearances by parties or witnesses unless otherwise mutually agreed to by the parties.
  <br /><br />
  13.6. Exceptions to the Agreement to Arbitrate.
  <br /><br />
  13.6.1. Notwithstanding the foregoing, the Arbitration Notice requirement and the Informal Negotiation Period do not
  apply to either law suits solely for injunctive relief to stop unauthorized use of the Services or lawsuits concerning
  copyrights, trademarks, moral rights, patents, trade secrets, claims of piracy or unauthorized use of the Services.
  <br /><br />
  13.6.2. Either you or Xpress Technologies may assert claims which qualify for small claims court in Phoenix, AZ, or in
  any United States county in which you either live or work.
  <br /><br />
  13.7. Opting Out of the Agreement to Arbitrate. You have the right to opt-out and not be bound by the binding
  agreement to arbitrate provided in these Terms by sending written notice of your decision to opt-out to
  carrierxperience@usxpress.com AND by U.S. Mail to Xpress Technologies, 1375 N Scottsdale Rd Suite 370, Scottsdale, AZ
  85257. In order for your opting out to be valid, your notice must be sent within 30 days of first using the Services.
  <br /><br />
  13.8. No Class Actions. Unless you and Xpress Technologies agree otherwise, you may only resolve disputes with us on
  an individual basis. Class actions, class arbitrations, private attorney general actions and consolidations with other
  Claims are not allowed. Neither you nor Xpress Technologies may not consolidate a Claim or Claims as a plaintiff or a
  class member in a class action, a consolidated action or a representative action.
  <br /><br />
  13.9. Consent to Jurisdiction. If the agreement to arbitrate provided for in these Terms is found not to apply to you
  or your Claim, or if you or Xpress Technologies challenges any arbitration award or seeks to have an arbitration award
  enforced, you and Xpress Technologies agree that any judicial proceeding will be brought in the federal or state
  courts located in Phoenix, AZ. You and Xpress Technologies consent and agree to both venue and personal jurisdiction
  in the federal and state courts located in Phoenix, AZ.
  <br /><br />
  13.10. Release from Third-Party Claims. Because Xpress Technologies is not party to any agreements between you and any
  third parties or involved in the completion of any associated professional services, in the event that you have a
  dispute with one or more other Drivers, Fleet Users, Freight Brokers, Shippers, or Companies or other third parties,
  as applicable (each, an "Other Party"), you agree to address such dispute directly with the Other Party in question
  and you release Xpress Technologies (and our officers, directors, agents, investors, subsidiaries, and employees from
  any and all claims, demands, or damages (actual or consequential)) of every kind and nature, known and unknown,
  suspected and unsuspected, disclosed and undisclosed, arising out of or in any way connected with such dispute.
  <br /><br />
  14. Links to Third Party Websites or Resources
  <br /><br />
  14.1. The Services may contain links to third-party websites or resources. We provide these links only as a
  convenience and are not responsible for the content, products or services on or available from those websites or
  resources or links displayed on such sites. You acknowledge sole responsibility for, and assume all risk arising from,
  your use of any third-party websites or resources.
  <br /><br />
  15. Digital Millennium Copyright Act
  <br /><br />
  15.1. We respect the ownership rights of artists and other content owners and creators and ask that you do too. We
  respond to notices that we receive regarding alleged infringement if they comply with the requirements of and process
  specified in the Digital Millennium Copyright Act of 1998 ("DMCA"). For an overview of the DMCA's notice and takedown
  procedures for allegedly infringing material, please see http://www.copyright.gov/legislation/dmca.pdf. For more
  comprehensive provisions please see http://www.copyright.gov/title17/92chap5.html#512. To review our complete
  Copyright Dispute Policy and learn how to report potentially infringing content, click here.
  <br /><br />
  15.2. Upon receipt of a notice which complies with the DMCA, we will take whatever action we deem appropriate,
  including removing the allegedly infringing content from the Services and limiting or terminating use of the Services
  for any users who are infringing the property rights of others.
  <br /><br />
  15.3. Please note that this Section is intended to inform you and copyright owners of procedures under the DMCA and to
  comply with Xpress Technologies's rights and obligations under the DMCA. This Section does not constitute legal
  advice, and it may be advisable to contact an attorney concerning your rights and obligations under the DMCA and any
  other applicable laws.
  <br /><br />
  16. Termination
  <br /><br />
  16.1. These Terms will continue in full force and effect unless and until your Account or these Terms are terminated
  as described herein.
  <br /><br />
  16.2. We may terminate your access to and use of the Services, at our sole discretion, at any time and without notice
  to you. You may cancel your Account at any time by sending an email to us at carrierxperience@usxpress.com. Upon any
  termination, discontinuation or cancellation of Services or your Account, all provisions of the Terms that should, by
  their nature, survive termination of this Terms will survive termination. Such provisions include, but are not limited
  to: Your Data, Your Privacy, Your Ownership of Your Data, and Licenses for Our Use of Your Data; Disclaimers;
  Indemnity; Limitation of Liability; Arbitration Agreement, Dispute Resolution and Release; General Terms; and
  provisions related to ownership.
  <br /><br />
  16.3. Notwithstanding Section 16.2, please be aware that once your Account has been terminated, we no longer have any
  obligation to maintain or provide Your Data, and we may delete or destroy all copies of Your Data in our possession or
  control, unless legally prohibited.
  <br /><br />
  17. Disclaimers
  <br /><br />
  17.1. TO THE MAXIMUM EXTENT ALLOWABLE BY LAW AND EXCEPT AS EXPRESSLY PROVIDED IN THESE TERMS AND THE HARDWARE
  WARRANTY, IF APPLICABLE TO YOU, THE SERVICES ARE PROVIDED "AS IS," WITHOUT WARRANTY OF ANY KIND. WITHOUT LIMITING THE
  FOREGOING, WE EXPLICITLY DISCLAIM ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET
  ENJOYMENT AND NON-INFRINGEMENT AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. XPRESS
  TECHNOLOGIES EXPLICITLY DISCLAIMS ALL LIABILITY FOR ANY ACT OR OMISSION OF ANY DRIVER, FLEET USER, COMPANY OR OTHER
  THIRD PARTY. Except with respect to the limited warranty contained herein and in the Hardware Warranty, if applicable
  to you, we make no warranty that the Services will meet your requirements or be available on an uninterrupted, secure,
  or error-free basis. We make no warranty regarding the quality, accuracy, timeliness, truthfulness, completeness or
  reliability of any of the Services or anything shared by any users of the Services.
  <br /><br />
  17.2. You are solely responsible for your interactions with other users on the Services. You are solely responsible
  for, and will exercise caution, discretion, common sense and judgment in, using the Services.
  <br /><br />
  17.3. Neither Xpress Technologies nor its affiliates or licensors is responsible for the conduct of any user of Xpress
  Technologies's Services. Your use of the Services and your use of Your Data in connection with the Services and
  performance of and participation in any professional services is at your sole risk and discretion and Xpress
  Technologies hereby disclaims any and all liability to you or any third party relating thereto.
  <br /><br />
  18. Limitation of Liability
  <br /><br />
  18.1. NEITHER XPRESS TECHNOLOGIES NOR ANY OTHER PARTY INVOLVED IN CREATING, PRODUCING, OR DELIVERING THE SERVICES OR
  CONTENT WILL BE LIABLE FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOSS
  OF DATA OR GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE OR SYSTEM FAILURE OR THE COST OF SUBSTITUTE SERVICES
  ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE SERVICES, WHETHER BASED ON
  WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT
  XPRESS TECHNOLOGIES HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED REMEDY SET FORTH HEREIN IS
  FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
  LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
  <br /><br />
  18.2. TO THE MAXIMUM EXTENT PERMITTED UNDER LAW, XPRESS TECHNOLOGIES DOES NOT ACCEPT ANY LIABILITY WITH RESPECT TO THE
  QUALITY OR FITNESS OF ANY PROFESSIONAL SERVICES PERFORMED BY USERS OF OUR SERVICES IN CONNECTION WITH THEIR USE OF THE
  SERVICES. XPRESS TECHNOLOGIES WILL NOT BE LIABLE TO YOU UNDER ANY CIRCUMSTANCES ARISING OUT OF THE MISUSE OF YOUR USER
  CONTENT BY THIRD PARTIES INCLUDING, WITHOUT LIMITATION, OTHER USERS WITH WHOM YOU HAVE CONNECTED THROUGH THE SERVICES
  OR ENTERED INTO AN AGREEMENT IN CONNECTION WITH THE PERFORMANCE OF PROFESSIONAL SERVICES. IN NO EVENT WILL XPRESS
  TECHNOLOGIES'S TOTAL LIABILITY ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE
  THE SERVICES OR CONTENT EXCEED THE GREATER OF (A) ONE HUNDRED DOLLARS ($100) OR (B), IF YOU HAVE ORDERED PREMIUM
  SERVICES FROM XPRESS TECHNOLOGIES, THE TOTAL AMOUNT OF FEES RECEIVED BY XPRESS TECHNOLOGIES FROM YOU FOR THE USE OF
  PREMIUM SERVICES DURING THE PAST SIX (6) MONTHS. THE LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS
  OF THE BASIS OF THE BARGAIN BETWEEN XPRESS TECHNOLOGIES AND YOU.
  <br /><br />
  18.3. Any cause of action related to the Services or the Terms must commence within one (1) year after the cause of
  action arises. Otherwise, such cause of action is barred permanently.
  <br /><br />
  19. Indemnity
  <br /><br />
  19.1. You will indemnify, defend and hold harmless Xpress Technologies and its officers, directors, employees and
  agents, from and against, and covenant not to sue them for any claims, disputes, demands, liabilities, damages,
  losses, and costs and expenses, including, without limitation, reasonable legal and accounting fees, arising out of or
  in any way connected with (i) your access to or use of the Services or Our Content; (ii) Your Data; (iii) your
  performance of or participation in professional services in connection with your use of the Services or Our Content;
  or (iv) your violation of these Terms. Xpress Technologies reserves the right to control and conduct the defense of
  any matter subject to indemnification under these Terms. If Xpress Technologies decides to control or conduct any such
  defense, you agree to cooperate with Xpress Technologies's requests in assisting Xpress Technologies's defense of such
  matters.
  <br /><br />
  20. General Terms
  <br /><br />
  20.1. Integration. These Terms and the Privacy Policy constitute the entire and exclusive understanding and agreement
  between Xpress Technologies and you regarding the Services, and supersede and replace any and all prior oral or
  written understandings or agreements between Xpress Technologies and you regarding the Services. If for any reason a
  court of competent jurisdiction finds any provision of these Terms invalid or unenforceable, that provision will be
  enforced to the maximum extent permissible and the other provisions of these Terms will remain in full force and
  effect.
  <br /><br />
  20.2. Assignment. You may not assign or transfer these Terms, by operation of law or otherwise, without Xpress
  Technologies's prior written consent. Any attempt by you to assign or transfer these Terms, without such consent, will
  be null and of no effect. Xpress Technologies may freely assign or transfer these Terms without restriction. Subject
  to the foregoing, these Terms will bind and inure to the benefit of the parties, their successors and permitted
  assigns.
  <br /><br />
  20.3. Governing Law. The Terms and the relationship between you and Xpress Technologies shall be governed by the laws
  of the State of Arizona.
  <br /><br />
  20.4. Notices. Any notices or other communications provided by Xpress Technologies under these Terms, including those
  regarding modifications to these Terms, will be given by Xpress Technologies: (i) via email; or (ii) by posting to the
  Site. For notices made by e-mail, the date of receipt will be deemed the date on which such notice is transmitted.
  <br /><br />
  20.5. Waiver and Severability. Xpress Technologies's failure to enforce any right or provision of these Terms will not
  be considered a waiver of those rights. The waiver of any such right or provision will be effective only if in writing
  and signed by a duly authorized representative of Xpress Technologies. Except as expressly set forth in these Terms,
  the exercise by either party of any of its remedies under these Terms will be without prejudice to its other remedies
  under these Terms or otherwise. If any of these Terms are unenforceable, it will not affect the enforceability of the
  rest of the Terms.
  <br /><br />
  21. Contact Information
  <br /><br />
  If you have any questions about these Terms or the Services please contact us at carrierxperience@usxpress.com or at
  1375 N Scottsdale Rd Suite 370, Scottsdale, AZ 85257.
  <br /><br />
</div>
<div class="fab" *ngIf="showScrollToBottomButton && !bottomReached">
  <haulynx-blue-fab icon="arrow_downward" (clicked)="scrollBottom()" data-test="down-arrow"></haulynx-blue-fab>
</div>
