import { Carrier, CarrierApiCarrier } from '..';

export class Opportunity {
  freightId: string;
  carrierId: string;
  carrier: CarrierApiCarrier;
  recommendationId: string;
  brokerId: string;
  score: number;
  opportunityType: OpportunityType;
}

export class OpportunityParams {
  freightId: string;
  carrierId?: string;
  recommendationId?: string;
  brokerId?: string;
}

export enum OpportunityType {
  PreferredLane = 'PreferredLane',
  PostedTruck = 'PostedTruck',
  RepeatPostedTruck = 'RepeatPostedTruck',
}
