import { CompletedLoadsContainerComponent } from './completed-loads-container.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    component: CompletedLoadsContainerComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('../completed-load-search-container/completed-load-search-container.module').then(
            (m) => m.CompletedLoadSearchContainerModule
          ),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CompletedLoadsContainerRoutingModule {}
