import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@haulynx/modules';
import { ExpandButtonComponent } from './expand-button.component';

@NgModule({
  imports: [CommonModule, MaterialModule],
  exports: [ExpandButtonComponent],
  declarations: [ExpandButtonComponent],
})
export class ExpandButtonModule {}
