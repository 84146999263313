import { createSelector } from '@ngrx/store';
import { getCarrierDetailsState } from './carrier-details.state';

export const getStateKeySelector = createSelector(getCarrierDetailsState, (state) => state.stateKey);

export const isLoadingSelector = createSelector(
  getCarrierDetailsState,
  getStateKeySelector,
  (state, stateKey) => !!state.isLoading.get(stateKey)
);

export const stateSelector = createSelector(getCarrierDetailsState, getStateKeySelector, (state, stateKey) =>
  state.state.get(stateKey)
);
