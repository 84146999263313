import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@haulynx/modules';
import { CountdownModule } from 'ngx-countdown';
import { MapsModule } from '../../../maps/maps.module';
import { PipesModule } from '@haulynx/pipes';
import { LoadFeedDetailsMapComponent } from './load-feed-details-map.component';

@NgModule({
  declarations: [LoadFeedDetailsMapComponent],
  exports: [LoadFeedDetailsMapComponent],
  imports: [CommonModule, CountdownModule, MapsModule, PipesModule, MaterialModule],
})
export class LoadFeedDetailsMapModule {}
