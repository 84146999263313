import { Injectable } from '@angular/core';
import {
  CarrierLoadSearchGetBidsAndOffers,
  CarrierLoadSearchGetLoadsByCarrierDot,
  CarrierLoadSearchGetLoadsServiceLoadById,
  CarrierLoadSearchGetRecommendations,
  CarrierLoadSearchGetUsxLoads,
  GetSidebarLoadCounts,
} from '@haulynx/gql';
import {
  BidStatusType,
  carrierNotBillTo,
  CarrierSearchListRoute,
  CarrierSearchSidebarLoadCounts,
  FFState,
  LoadServiceSearchParameters,
  LoadsServiceLoad,
  PageAndSort,
  PaginatedData,
  PaginatedLoadsServiceData,
} from '@haulynx/types';
import { map } from 'rxjs/operators';
import { GraphqlService } from '../graphql/graphql.service';

@Injectable({
  providedIn: 'root',
})
export class CarrierLoadSearchService {
  constructor(private graphqlService: GraphqlService) {}

  public getSidebarLoadCounts(dot: string, carrierId: string, features: FFState) {
    return this.graphqlService
      .query<CarrierSearchSidebarLoadCounts>({
        query: GetSidebarLoadCounts(features),
        variables: {
          dot,
          carrierId,
          dotString: dot,
        },
      })
      .pipe(
        map((result) => {
          const data: any = result.data;
          return {
            [CarrierSearchListRoute.RECOMMENDED]: (data['matches']?.total ?? 0) + (data['recommendations']?.total ?? 0),
            [CarrierSearchListRoute.BIDS_AND_OFFERS]:
              this.removeDuplicateBidsOffers([...(data['bids'] ?? []), ...data['offer']?.['result']])?.length ?? 0,
            [CarrierSearchListRoute.UNASSIGNED]: data[CarrierSearchListRoute.UNASSIGNED]?.totalLoads ?? 0,
            [CarrierSearchListRoute.ASSIGNED]: data[CarrierSearchListRoute.ASSIGNED]?.totalLoads ?? 0,
            [CarrierSearchListRoute.IN_TRANSIT]: data[CarrierSearchListRoute.IN_TRANSIT]?.totalLoads ?? 0,
            [CarrierSearchListRoute.DELIVERED]: data[CarrierSearchListRoute.DELIVERED]?.totalLoads ?? 0,
            [CarrierSearchListRoute.ACTIVE]:
              (data[CarrierSearchListRoute.UNASSIGNED]?.totalLoads ?? 0) +
              (data[CarrierSearchListRoute.ASSIGNED]?.totalLoads ?? 0) +
              (data[CarrierSearchListRoute.IN_TRANSIT]?.totalLoads ?? 0),
          } as CarrierSearchSidebarLoadCounts;
        })
      );
  }

  public getLoadsByCarrierDot(
    dot: string,
    searchParameters: Partial<LoadServiceSearchParameters>,
    paging: Partial<PageAndSort> = { limit: 25 }
  ) {
    return this.graphqlService
      .query<PaginatedData<LoadsServiceLoad>>({
        query: CarrierLoadSearchGetLoadsByCarrierDot,
        variables: {
          dot,
          filterParams: new LoadServiceSearchParameters(searchParameters, false),
          paging,
        },
      })
      .pipe(
        map((result) => {
          const response: PaginatedLoadsServiceData<LoadsServiceLoad> = result.data['getLoadsServiceLoadsByCarrierDot'];
          return {
            data: response?.data,
            pagination: response?.paginator,
          } as PaginatedData<LoadsServiceLoad>;
        })
      );
  }

  public getUSXLoads(
    searchParameters: Partial<LoadServiceSearchParameters>,
    paging: Partial<PageAndSort> = { limit: 25 }
  ) {
    return this.graphqlService
      .query<PaginatedData<LoadsServiceLoad>>({
        query: CarrierLoadSearchGetUsxLoads,
        variables: {
          searchParameters: {
            ...new LoadServiceSearchParameters(searchParameters, false),
            notBillTo: carrierNotBillTo.map((val) => val.key), // Ensure that certain loads are not shown in search
          },
          paging,
        },
      })
      .pipe(
        map((result) => {
          const response: PaginatedLoadsServiceData<LoadsServiceLoad> = result.data['getUSXLoads'];
          return {
            data: response?.data,
            pagination: response?.paginator,
          } as PaginatedData<LoadsServiceLoad>;
        })
      );
  }

  public getLoadsServiceLoadById(loadId: string) {
    return this.graphqlService
      .query<LoadsServiceLoad>({
        query: CarrierLoadSearchGetLoadsServiceLoadById,
        variables: {
          loadId,
        },
      })
      .pipe(
        map((result) => {
          const data: LoadsServiceLoad = result.data['getLoadsServiceLoadById'];

          const bidHistory = [
            ...(data?.carrierBid?.bidHistory || [])
              .filter(
                (bid) =>
                  !!bid?.data?.price ||
                  bid?.data?.status === BidStatusType.PRICE_REJECTED ||
                  bid?.data?.status === BidStatusType.AUTO_REJECTED ||
                  bid?.data?.status === BidStatusType.NO_CAPACITY ||
                  bid?.data?.status === BidStatusType.PRICE_HOLD
              ) // filter out bids with no price
              .sort((a, b) => a?.createdAt - b?.createdAt), // sort by date
          ];

          return { ...data, carrierBid: { ...(data?.carrierBid || {}), bidHistory } };
        })
      );
  }

  public getRecommendations(dot: string, paging: Partial<PageAndSort> = { limit: 100 }) {
    return this.graphqlService
      .query({
        query: CarrierLoadSearchGetRecommendations,
        variables: {
          dot,
          paging,
        },
      })
      .pipe(
        map((result) => {
          const { matches = { total: 0, result: [] }, recommendations = { total: 0, result: [] } } = result.data as any;
          const results = [...matches.result, ...recommendations.result].map(
            (result: { loadsServiceLoad: LoadsServiceLoad }) => result.loadsServiceLoad
          );
          return {
            data: [...results],
            pagination: { total: results.length, totalPages: 1, currentPage: 1, previousPage: null, nextPage: null },
          } as PaginatedData<LoadsServiceLoad>;
        })
      );
  }

  public getBidsAndOffers(dot: string, carrierId: string, features: FFState) {
    return this.graphqlService
      .query<PaginatedData<LoadsServiceLoad>>({
        query: CarrierLoadSearchGetBidsAndOffers(features.CARRIER_BID),
        variables: {
          dot,
          carrierId,
        },
      })
      .pipe(
        map((result) => {
          const results: LoadsServiceLoad[] = this.removeDuplicateBidsOffers([
            ...(result.data?.['offers']?.['result'] || []).map((res) => ({
              ...res.loadsServiceLoad,
              type: 'offer',
            })),
            ...(result.data?.['bids'] || []).map((res) => ({ ...res.loadsServiceLoad, type: 'bid' })),
          ]);
          return {
            data: [...results],
            pagination: { total: results?.length, totalPages: 1, currentPage: 1, previousPage: null, nextPage: null },
          } as PaginatedData<LoadsServiceLoad>;
        })
      );
  }

  /**
   * removeDuplicateBidsOffers removes duplicate loads in bids and offers
   * Remove this implementation when graphql calls getLoadsBrokered & getBidsByCarrierId
   * don't return duplicate load in call results.
   * @param loads
   * @returns LoadsServiceLoad[]
   */
  private removeDuplicateBidsOffers(loads: LoadsServiceLoad[] = []): LoadsServiceLoad[] {
    if (!loads?.length) return [];
    const idSet = new Set();
    return (
      loads?.filter((load) => {
        const isDuplicate = idSet.has(load?.id);
        idSet.add(load?.id);
        return !isDuplicate;
      }) || []
    );
  }
}
