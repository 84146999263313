export class LoadsServiceRestriction {
  type: LoadsServiceRestrictionTypes;
  value: string;
}

export enum LoadsServiceRestrictionTypes {
  EXCLUDED = 'excluded',
  HAZMAT = 'hazmat',
  EXCLUSIVE_UNTIL = 'exclusiveUntil',
  PICKUP_REQUIREMENTS = 'pickupRequirements',
  DROPOFF_REQUIREMENTS = 'dropoffRequirements',
  TANKER_CERTIFICATION_REQUIRED = 'tankerCertificationRequired',
  TEAM_DRIVING_REQUIRED = 'teamDrivingRequired',
  HIGH_VALUE_PRODUCT = 'highValueProduct',
  HIGH_VALUE_PRODUCT_EXCEPTION = 'highValueProductException',
}

export enum PickupRequirements {
  LIVE_PICKUP = 'livePickup',
  PRELOADED = 'preloaded',
}

export enum DropoffRequirements {
  LIVE_DROPOFF = 'liveDropoff',
  DROP_TRAILER = 'dropTrailer',
}
