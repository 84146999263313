import { AddressField } from '../generic';

export interface PostTruckForm {
  location: AddressField;
  locationRadius: string;
  preferredLocation?: AddressField;
  preferredLocationRadius?: string;
  equipmentType?: string;
  timeAvailable: number;
}
