import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BookStatus } from '@haulynx/types';

@Component({
  selector: 'app-carrier-accept-counter-offer-dialog',
  templateUrl: './carrier-accept-counter-offer-dialog.component.html',
  styleUrls: ['./carrier-accept-counter-offer-dialog.component.scss'],
})
export class CarrierAcceptCounterOfferDialogComponent {
  public BookStatus = BookStatus;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { price: number; status: BookStatus }) {}
}
