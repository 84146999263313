<div class="row">
  <div class="col-lg-12">
    <h2 matDialogTitle>Simulate position</h2>
    <mat-dialog-content>
      <div class="row">
        <mat-form-field class="col-lg-12">
          <mat-select [(ngModel)]="selectedDriver" placeholder="Select Driver" #select="matSelect">
            <mat-option *ngFor="let driver of drivers | async" [value]="driver">
              {{ driver.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div class="row">
          <mat-form-field class="col-lg-12">
            <input #addressAutocomplete matInput placeholder="Enter Address" [(ngModel)]="address" />
          </mat-form-field>
        </div>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button mat-button [matDialogClose]="">Close</button>
      <button mat-button color="accent" (click)="save()">Save</button>
    </mat-dialog-actions>
  </div>
</div>
