import { Injectable } from '@angular/core';
import { CarriersService, NotificationsService } from '@haulynx/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { DispatchAction } from '../../shared/store/helpers/action';
import { CarrierDetailsActions, CarrierDetailsActionTypes } from './carrier-details.actions';

@Injectable()
export class CarrierDetailsEffects {
  constructor(
    private actions$: Actions,
    private notificationsService: NotificationsService,
    private carriersService: CarriersService
  ) {}

  getCarrier = createEffect(() =>
    this.actions$.pipe(
      ofType(CarrierDetailsActionTypes.GET),
      map((action: DispatchAction) => action.payload),
      switchMap((payload: { carrierId: string; carrierDot?: string }) => {
        const { carrierId, carrierDot = null } = payload;

        if (carrierId) {
          return this.carriersService.getCarrierById(carrierId).pipe(
            map((carrier) => {
              return CarrierDetailsActions.getSuccess({ carrierId, carrier });
            }),
            catchError((error) => {
              this.notificationsService.error('Error to get carrier', 'Carrier');
              return of(CarrierDetailsActions.getError({ carrierId, error }));
            })
          );
        }

        if (carrierDot) {
          return this.carriersService.getCarrierByDot(carrierDot).pipe(
            map((carrier) => {
              return CarrierDetailsActions.getSuccess({ carrierId: carrierDot, carrier });
            }),
            catchError((error) => {
              this.notificationsService.error('Error to get carrier', 'Carrier');
              return of(CarrierDetailsActions.getError({ carrierId: carrierDot, error }));
            })
          );
        }
      })
    )
  );
}
