import { Injectable } from '@angular/core';
import { LngLatBounds, LngLatLike } from 'mapbox-gl';
import { DriverMarker } from '../../shared-services/map.service';

@Injectable({
  providedIn: 'root',
})
export class MapClusterService {
  constructor() {}

  initCluster(sources: DriverMarker[]) {
    if (!sources) {
      return [];
    }
    return {
      source: {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: sources.map((source: DriverMarker) => {
            return {
              type: 'Feature',
              properties: {
                lastUpdated: source.lastUpdated,
                status: source.status,
                label: source.label,
                info: source.info,
                type: source.type,
              },
              geometry: {
                type: 'Point',
                coordinates: [source.lng, source.lat],
              },
            };
          }),
        },
      },
    };
  }

  zoomToBounds(markers: DriverMarker[]): LngLatBounds {
    const markerCoordinates = markers.map((marker) => [marker.lng, marker.lat]) as LngLatLike[];

    const coordinates = markerCoordinates;

    if (coordinates.length) {
      const zoomCoordinates = coordinates;

      if (!zoomCoordinates?.[0]?.[0]) return;
      return zoomCoordinates
        .filter((coordinate) => coordinate[0] && coordinate[1])
        .reduce((bounds, coord) => bounds.extend(coord), new LngLatBounds(zoomCoordinates[0], zoomCoordinates[0]));
    }
  }
}
