import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { GeocodingEntityService } from '@haulynx/store';
import {
  BooleanFilterType,
  DateRangeFilterType,
  GeoSpacialFilterType,
  ISearchFilter,
  ISearchFilterType,
  LastSeenFilter,
  LatLonInput,
  LoadSearchBarResult,
  LoadSearchSideBarItem,
  NumericalFilterType,
  PlaceInfo,
  RecentSearch,
  TextArrayFilterType,
  TextArrayMode,
  TextFilterType,
} from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { cloneDeep, findIndex, forEach, isEmpty, isNil } from 'lodash';
import { BehaviorSubject, isObservable } from 'rxjs';
import { distinctUntilChanged, takeUntil, withLatestFrom } from 'rxjs/operators';
import { SearchCustomDateRangeComponent } from './components/search-custom-date-range';
@Component({
  selector: 'app-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.scss'],
})
export class AdvancedSearchComponent implements OnInit {
  @Input() placeholder? = 'Search Loads by Date, Location, etc.';
  @Input() filters: ISearchFilter[] = [];
  @Input() sideBarSelection: LoadSearchSideBarItem;
  @Input() allowZipSearch? = false;
  @Input() externalDateSearch? = false;
  @Input() timeZone? = 'America/Phoenix';
  @Input() recentSearches: RecentSearch[];
  @Input() displayRecentSearches = false;
  @Input() shouldResetTextArrayForm = false;

  @Input() set setFilters(filters: ISearchFilter[]) {
    setTimeout(() => {
      this.setFinalFormValWithFilters(filters);
    });
  }
  @Output() submitSearch: EventEmitter<{
    newData: LoadSearchBarResult;
    previousData: LoadSearchBarResult;
  }> = new EventEmitter();
  @Output() selectedFilterChange: EventEmitter<ISearchFilter[]> = new EventEmitter();

  @ViewChild(SearchCustomDateRangeComponent) searchCustomDateRangeFormComponent: SearchCustomDateRangeComponent;

  mainInputValue = '';
  selectedFilters: ISearchFilter[] = [];
  focusedFilter: ISearchFilter;
  mainForm: FormGroup;
  finalFormValue: { [key: string]: unknown } = {};

  showDateRange = false;
  dateRangeHasMouseFocus = false;

  showLocationResults = false;
  locationResultsHasMouseFocus = false;
  refreshMultiFilterResults = false;
  showResultsDropdown = false;
  selectedPlacePrediction$ = new BehaviorSubject<PlaceInfo>(null);
  placePredictions: Array<PlaceInfo> = [];

  showNumberRange = false;
  numberRangeHasMouseFocus = false;

  showSearchOptions = false;
  searchOptionsHasMouseFocus = false;
  showSearchOptionsDropdown = false;
  textChangeValue = '';

  showFilterOptions = false;
  filterOptionsHasMouseFocus = false;

  shouldFocusMainSearchBar = false;

  isSearchButtonDisabled = true;

  shouldShowRecentSearches = false;
  textArrayMode: TextArrayMode;
  dateRangeSearch: ISearchFilter;
  lastSeenFilter = new LastSeenFilter();
  private alive = aliveWhile();

  constructor(private fb: FormBuilder, private geocodingEntityService: GeocodingEntityService) {}

  ngOnInit(): void {
    this.mainForm = this.fb.group(this.buildFilterForm());

    this.geocodingEntityService.getReverseGecodeLocationsManager.searchResults$
      .pipe(
        takeUntil(this.alive),
        distinctUntilChanged(),
        withLatestFrom(this.geocodingEntityService.getReverseGecodeLocationsManager.searchQuery$)
      )
      .subscribe(([results, query]) => {
        forEach(results, (result, i) => {
          const queryCoordinates = query.payload.coordinates;
          const index = findIndex(this.selectedFilters, (filter) => {
            return (
              filter.type === ISearchFilterType.GEOSPATIAL &&
              filter.keys['lat'] === queryCoordinates[i]['lat'] &&
              filter.keys['lon'] === queryCoordinates[i]['lon']
            );
          });
          if (index > -1) {
            this.selectedFilters[index]['keys']['placeInfo'] = result;
            const updatedSelectedFilters = this.selectedFilters.map((filter) => {
              if (
                filter.type === ISearchFilterType.GEOSPATIAL &&
                filter.keys['lat'] === queryCoordinates[i]['lat'] &&
                filter.keys['lon'] === queryCoordinates[i]['lon']
              ) {
                let newFilter = cloneDeep(filter);
                newFilter['keys']['placeInfo'] = result;
                return newFilter;
              }
              return filter;
            });
            this.selectedFilters = updatedSelectedFilters;
          }
        });
      });
  }

  buildFilterForm = (): { [key: string]: unknown } => {
    const result = {};
    this.filters.forEach((filter: ISearchFilter) => {
      if (filter.type === ISearchFilterType.TEXT) {
        const keys = <TextFilterType>filter.keys;
        result[keys.textFormName] = [keys.value || ''];
      } else if (filter.type === ISearchFilterType.NUMBER_RANGE) {
        const keys = <NumericalFilterType>filter.keys;
        result[keys.minFormName] = [keys.min || 0];
        result[keys.maxFormName] = [keys.max || 0];
      } else if (filter.type === ISearchFilterType.GEOSPATIAL) {
        const keys = <GeoSpacialFilterType>filter.keys;
        result[keys.locationFormName] = this.fb.array([]);
        result[keys.latFormName] = this.fb.array([]);
        result[keys.lonFormName] = this.fb.array([]);
        result[keys.radiusFormName] = this.fb.array([]);
        result[keys.stateFormName] = this.fb.array([]);
        result[keys.zipFormName] = this.fb.array([]);
      } else if (filter.type === ISearchFilterType.DATE_RANGE) {
        const keys = <DateRangeFilterType>filter.keys;
        result[keys.fromFormName] = [keys.from || 0];
        result[keys.toFormName] = [keys.to || 0];
      } else if (filter.type === ISearchFilterType.MULTI_DROPDOWN) {
        const keys = <TextFilterType>filter.keys;
        result[keys.textFormName] = [keys.value || ''];
      } else if (filter.type === ISearchFilterType.TEXT_ARRAY) {
        const keys = <TextArrayFilterType>filter.keys;
        result[keys.textFormName] = this.fb.array([]);
      } else if (filter.type === ISearchFilterType.BOOLEAN) {
        const keys = <BooleanFilterType>filter.keys;
        result[keys.textFormName] = [!isNil(keys.value) ? keys.value : undefined];
      }
    });
    return result;
  };

  setDisplayFilterType(date: boolean, location: boolean, number: boolean, options: boolean): void {
    this.showDateRange = date;
    this.showLocationResults = location;
    this.showNumberRange = number;
    this.showSearchOptions = options;
    this.shouldFocusMainSearchBar = false;
    this.filterOptionsHasMouseFocus = false;
    this.shouldShowRecentSearches = false;
    this.locationResultsHasMouseFocus = false;
  }

  canShowADropdownContainer(): boolean {
    return (
      this.canShowSearchNumberRange ||
      this.canShowSearchLocation ||
      this.canShowSearchDateRange ||
      this.canShowSearchOptions
    );
  }

  get canShowSearchOptions(): boolean {
    return this.showSearchOptions || this.searchOptionsHasMouseFocus;
  }

  get canShowSearchDateRange(): boolean {
    return this.showDateRange || this.dateRangeHasMouseFocus;
  }

  get canShowSearchNumberRange(): boolean {
    return this.showNumberRange || this.numberRangeHasMouseFocus;
  }

  get canShowSearchLocation(): boolean {
    return !this.refreshMultiFilterResults && (this.showLocationResults || this.locationResultsHasMouseFocus);
  }

  get canShowSearchInputDropdown(): boolean {
    return this.showFilterOptions || this.filterOptionsHasMouseFocus;
  }

  get canShowResultsDropdown(): boolean {
    return this.showResultsDropdown;
  }

  get canShowSearchOptionsDropdown(): boolean {
    return (
      (this.showSearchOptionsDropdown &&
        this.focusedFilter &&
        (Array.isArray((<TextFilterType>this.focusedFilter.keys).optionsDataIndex) ||
          isObservable((<TextFilterType>this.focusedFilter.keys).optionsDataIndex))) ||
      this.focusedFilter?.type === ISearchFilterType.BOOLEAN
    );
  }

  onMainInputChanged(event: string): void {
    this.mainInputValue = event;
  }

  onFilterSelect(event: ISearchFilter): void {
    const canBeSelected = !this.selectedFilters.find(
      (filter: ISearchFilter) =>
        filter.name === event.name &&
        event.type !== ISearchFilterType.TEXT_ARRAY &&
        event.type !== ISearchFilterType.GEOSPATIAL
    );

    if (event && canBeSelected) {
      this.focusedFilter = event;

      // apply defaults
      if (event.type === ISearchFilterType.DATE_RANGE) {
        this.mainForm.patchValue({
          [(<DateRangeFilterType>this.focusedFilter.keys).fromFormName]: (<DateRangeFilterType>this.focusedFilter.keys)
            .from,
          [(<DateRangeFilterType>this.focusedFilter.keys).toFormName]: (<DateRangeFilterType>this.focusedFilter.keys)
            .to,
        });
      } else if (event.type === ISearchFilterType.GEOSPATIAL) {
        // update the location with the text in the input bar.
        if (!(this.mainInputValue.includes('ori') || this.mainInputValue.includes('dest'))) {
          (<GeoSpacialFilterType>this.focusedFilter.keys).location = this.mainInputValue;
        }

        this.textArrayMode = TextArrayMode.CREATE;
      } else if (event.type === ISearchFilterType.NUMBER_RANGE) {
        this.mainForm.patchValue({
          [(<NumericalFilterType>this.focusedFilter.keys).minFormName]: (<NumericalFilterType>this.focusedFilter.keys)
            .min,
          [(<NumericalFilterType>this.focusedFilter.keys).maxFormName]: (<NumericalFilterType>this.focusedFilter.keys)
            .max,
        });
      } else if (event.type === ISearchFilterType.TEXT) {
        this.mainForm.patchValue({
          [(<TextFilterType>this.focusedFilter.keys).textFormName]: (<TextFilterType>this.focusedFilter.keys).value,
        });
      } else if (event.type === ISearchFilterType.MULTI_DROPDOWN) {
        this.mainForm.patchValue({
          [(<TextFilterType>this.focusedFilter.keys).textFormName]: (<TextFilterType>this.focusedFilter.keys).value,
        });
      } else if (event.type === ISearchFilterType.TEXT_ARRAY) {
        this.textArrayMode = TextArrayMode.CREATE;
      } else if (event.type === ISearchFilterType.BOOLEAN) {
        this.mainForm.patchValue({
          [(<BooleanFilterType>this.focusedFilter.keys).textFormName]: (<BooleanFilterType>this.focusedFilter.keys)
            .value,
        });
      }

      if (this.focusedFilter.shouldPersistInputText && this.focusedFilter.type === ISearchFilterType.TEXT) {
        this.mainForm.patchValue({
          [(<TextFilterType>this.focusedFilter.keys).textFormName]: this.mainInputValue,
        });
      }
      this.mainInputValue = '';
      this.showFilterOptions = false;
      this.refreshMultiFilterResults = false;
      if (event.type === ISearchFilterType.DATE_RANGE) {
        this.setDisplayFilterType(true, false, false, false);
      } else if (event.type === ISearchFilterType.GEOSPATIAL) {
        this.setDisplayFilterType(false, true, false, false);
      } else if (event.type === ISearchFilterType.NUMBER_RANGE) {
        this.setDisplayFilterType(false, false, true, false);
      } else if (event.type === ISearchFilterType.TEXT) {
        this.setDisplayFilterType(false, false, false, true);
      } else if (event.type === ISearchFilterType.MULTI_DROPDOWN) {
        this.setDisplayFilterType(false, false, false, true);
      } else if (event.type === ISearchFilterType.TEXT_ARRAY) {
        this.setDisplayFilterType(false, false, false, true);
      } else if (event.type === ISearchFilterType.BOOLEAN) {
        this.setDisplayFilterType(false, false, false, true);
      }
    }
  }

  onSearchButtonPress(): void {
    if (this.focusedFilter) {
      if (this.focusedFilter.name === 'Last Seen Start Date') {
        this.searchCustomDateRangeFormComponent.clearForm();
      }
      this.locationResultsHasMouseFocus = false;
      this.onFilterCommit({ searchFilter: this.focusedFilter, form: this.mainForm });
    }
  }

  onSearchOptionSelect(event: string): void {
    this.showSearchOptionsDropdown = false;
    this.mainForm.patchValue({
      [(<TextFilterType>this.focusedFilter.keys).textFormName]: event,
    });
    this.isSearchButtonDisabled = false;
    this.searchOptionsHasMouseFocus = false;
    this.onSearchButtonPress();
  }

  onSearchBooleanOptionSelect(event: boolean): void {
    this.mainForm.patchValue({
      [(<TextFilterType>this.focusedFilter.keys).textFormName]: event,
    });

    this.isSearchButtonDisabled = false;
  }

  onSearchMultiOptionSelect(event: string[]): void {
    this.mainForm.patchValue({
      [(<TextFilterType>this.focusedFilter.keys).textFormName]: event,
    });

    this.isSearchButtonDisabled = isEmpty(event);
  }

  onCloseSearchOptions(): void {
    if (!isEmpty(this.mainForm.get((<TextFilterType>this.focusedFilter.keys).textFormName).value)) {
      this.onSearchButtonPress();
    } else {
      this.onRemoveSearchFilter(this.focusedFilter);
    }

    this.showSearchOptionsDropdown = false;
  }

  showRecentSearches(): void {
    this.shouldShowRecentSearches = !this.shouldShowRecentSearches;
  }

  onTextChange(event: string): void {
    this.textChangeValue = event;
  }

  onFocusedFilterHasValidForm(event: boolean): void {
    this.isSearchButtonDisabled = !event;
  }

  onRemoveFocusedFilter(): void {
    this.focusedFilter = null;
    this.setDisplayFilterType(false, false, false, false);
  }

  onSearchFilterFocus(event: ISearchFilter): void {
    this.onRemoveFocusedFilter();
    this.removeAllMouseFocus();
    this.focusedFilter = event;
    this.mainInputValue = '';
    this.refreshMultiFilterResults = true;
    if (event.type === ISearchFilterType.DATE_RANGE) {
      this.setDisplayFilterType(true, false, false, false);
    } else if (event.type === ISearchFilterType.GEOSPATIAL) {
      this.refreshMultiFilterResults = false;
      this.textArrayMode = TextArrayMode.UPDATE;
      setTimeout(() => this.setDisplayFilterType(false, true, false, false));
    } else if (event.type === ISearchFilterType.NUMBER_RANGE) {
      this.setDisplayFilterType(false, false, true, false);
    } else if (event.type === ISearchFilterType.TEXT) {
      this.setDisplayFilterType(false, false, false, true);
    } else if (event.type === ISearchFilterType.MULTI_DROPDOWN) {
      this.refreshMultiFilterResults = false;
      setTimeout(() => this.setDisplayFilterType(false, false, false, true));
    } else if (event.type === ISearchFilterType.TEXT_ARRAY) {
      this.refreshMultiFilterResults = false;
      this.textArrayMode = TextArrayMode.UPDATE;
      setTimeout(() => this.setDisplayFilterType(false, false, false, true));
    } else if (event.type === ISearchFilterType.BOOLEAN) {
      this.refreshMultiFilterResults = false;
      setTimeout(() => this.setDisplayFilterType(false, false, false, true));
    }
  }

  onSearchFilterBlur(): void {
    this.setDisplayFilterType(false, false, false, false);
  }

  onSearchInputFocusChange(event: boolean): void {
    this.showFilterOptions = event;
  }

  removeAllMouseFocus(): void {
    const containers: string[] = [
      'search-input-dropdown',
      'search-number-range',
      'search-date-range',
      'search-location',
      'search-location-dropdown',
      'search-recent-search',
      'search-options',
    ];
    containers.forEach((value) => this.onMouseFocus(false, value));
  }

  onMouseFocus(event: boolean, eventContainer: string): void {
    if (eventContainer === 'search-input-dropdown') {
      this.filterOptionsHasMouseFocus = event;
    } else if (eventContainer === 'search-number-range') {
      this.numberRangeHasMouseFocus = event;
    } else if (eventContainer === 'search-date-range') {
      this.dateRangeHasMouseFocus = event;
    } else if (eventContainer === 'search-custom-date-range') {
      this.dateRangeHasMouseFocus = event;
      this.focusedFilter = new LastSeenFilter();
    } else if (eventContainer === 'search-location') {
      this.locationResultsHasMouseFocus = event;
    } else if (eventContainer === 'search-location-dropdown') {
      this.showResultsDropdown = event;
    } else if (eventContainer === 'search-recent-search') {
      this.shouldShowRecentSearches = event;
    } else if (eventContainer === 'search-options') {
      this.searchOptionsHasMouseFocus = event;
      this.showSearchOptionsDropdown = event;
    }
  }

  onRemoveSearchFilter(event: ISearchFilter): void {
    const previousData = { ...this.finalFormValue };

    this.setDisplayFilterType(false, false, false, false);
    this.shouldFocusMainSearchBar = true;
    // redo the final filters variable and emit the search
    if (event.type === ISearchFilterType.TEXT || event.type === ISearchFilterType.MULTI_DROPDOWN) {
      let foo;
      ({ [(<TextFilterType>event.keys).textFormName]: foo, ...this.finalFormValue } = this.finalFormValue);
    } else if (event.type === ISearchFilterType.BOOLEAN) {
      let foo;
      ({ [(<BooleanFilterType>event.keys).textFormName]: foo, ...this.finalFormValue } = this.finalFormValue);
    } else if (event.type === ISearchFilterType.NUMBER_RANGE) {
      let foo, bar;
      ({
        [(<NumericalFilterType>event.keys).minFormName]: foo,
        [(<NumericalFilterType>event.keys).maxFormName]: bar,
        ...this.finalFormValue
      } = this.finalFormValue);
    } else if (event.type === ISearchFilterType.GEOSPATIAL) {
      const keys = <GeoSpacialFilterType>event.keys;
      let foo;
      // remove the filter from the selected filters and emit the new selected filters
      const filterIndex = this.selectedFilters.findIndex(
        (item: ISearchFilter) =>
          item.name === event.name && (<GeoSpacialFilterType>item.keys).location === keys.location
      );
      this.selectedFilters.splice(filterIndex, 1);
      this.selectedFilterChange.emit(this.selectedFilters);

      // radius
      const radiusFormIndex = this.mainForm
        .get(keys.radiusFormName)
        .value.findIndex((item: number) => item === keys.radius);
      if (radiusFormIndex !== -1) (this.mainForm.get(keys.radiusFormName) as FormArray).removeAt(radiusFormIndex);
      this.finalFormValue[keys.radiusFormName] = this.mainForm.get(keys.radiusFormName).value;
      if (isEmpty(this.mainForm.get(keys.radiusFormName).value))
        ({ [keys.radiusFormName]: foo, ...this.finalFormValue } = this.finalFormValue);

      // latitude
      const latFormIndex = this.mainForm.get(keys.latFormName).value.findIndex((item: number) => item === keys.lat);
      if (latFormIndex !== -1) (this.mainForm.get(keys.latFormName) as FormArray).removeAt(latFormIndex);
      this.finalFormValue[keys.latFormName] = this.mainForm.get(keys.latFormName).value;
      if (isEmpty(this.mainForm.get(keys.latFormName).value))
        ({ [keys.latFormName]: foo, ...this.finalFormValue } = this.finalFormValue);

      // longitude
      const lonFormIndex = this.mainForm.get(keys.lonFormName).value.findIndex((item: number) => item === keys.lon);
      if (lonFormIndex !== -1) (this.mainForm.get(keys.lonFormName) as FormArray).removeAt(lonFormIndex);
      this.finalFormValue[keys.lonFormName] = this.mainForm.get(keys.lonFormName).value;
      if (isEmpty(this.mainForm.get(keys.lonFormName).value))
        ({ [keys.lonFormName]: foo, ...this.finalFormValue } = this.finalFormValue);

      // state
      if (keys.stateFormName) {
        const stateFormIndex = this.mainForm
          .get(keys.stateFormName)
          .value.findIndex((item: string) => item === keys.state);
        if (stateFormIndex !== -1) (this.mainForm.get(keys.stateFormName) as FormArray).removeAt(stateFormIndex);
        this.finalFormValue[keys.stateFormName] = this.mainForm.get(keys.stateFormName).value;
        if (isEmpty(this.mainForm.get(keys.stateFormName).value))
          ({ [keys.stateFormName]: foo, ...this.finalFormValue } = this.finalFormValue);
      }

      if (keys.locationFormName && event['searchPlace']) {
        this.finalFormValue[keys.locationFormName] = null;
      }

      // zip code
      if (keys.zipFormName) {
        const zipFormIndex = this.mainForm
          .get(keys.zipFormName)
          .value.findIndex((item: string) => item === keys.zipcode);
        if (zipFormIndex !== -1) (this.mainForm.get(keys.zipFormName) as FormArray).removeAt(zipFormIndex);
        this.finalFormValue[keys.zipFormName] = this.mainForm.get(keys.zipFormName).value;
        if (isEmpty(this.mainForm.get(keys.zipFormName).value))
          ({ [keys.zipFormName]: foo, ...this.finalFormValue } = this.finalFormValue);
      }

      this.selectedPlacePrediction$.next(null);
    } else if (event.type === ISearchFilterType.DATE_RANGE) {
      let foo;
      const keys = <DateRangeFilterType>event.keys;
      ({ [keys.fromFormName]: foo, [keys.toFormName]: foo, ...this.finalFormValue } = this.finalFormValue);
    } else if (event.type === ISearchFilterType.TEXT_ARRAY) {
      const keys = <TextArrayFilterType>event.keys;
      const filterIndex = this.selectedFilters.findIndex(
        (item: ISearchFilter) => item.name === event.name && (<TextArrayFilterType>item.keys).value === keys.value
      );
      const formIndex = this.mainForm.get(keys.textFormName).value.findIndex((item: string) => item === keys.value);

      this.selectedFilters.splice(filterIndex, 1);
      this.selectedFilterChange.emit(this.selectedFilters);
      (this.mainForm.get(keys.textFormName) as FormArray).removeAt(formIndex);
      this.finalFormValue[keys.textFormName] = this.mainForm.get(keys.textFormName).value;

      if (isEmpty(this.mainForm.get(keys.textFormName).value)) {
        let foo;
        ({ [keys.textFormName]: foo, ...this.finalFormValue } = this.finalFormValue);
      }
    }

    // remove the filter from the selected Filters array
    if (
      event.type !== ISearchFilterType.TEXT_ARRAY &&
      event.type !== ISearchFilterType.GEOSPATIAL &&
      event.type !== ISearchFilterType.MULTI_DROPDOWN
    ) {
      this.selectedFilters.splice(
        this.selectedFilters.findIndex((v: ISearchFilter) => v.name === event.name),
        1
      );
    } else if (event.type === ISearchFilterType.TEXT_ARRAY) {
      this.selectedFilters.splice(
        this.selectedFilters.findIndex((v: ISearchFilter) => {
          const valuesAreEqual = (<TextArrayFilterType>v.keys).value === (<TextArrayFilterType>event.keys).value;
          return v.name === event.name && valuesAreEqual;
        }),
        1
      );
    } else if (event.type === ISearchFilterType.GEOSPATIAL) {
      let index = this.selectedFilters.findIndex((v: ISearchFilter) => {
        const locationNamesEqual =
          (<GeoSpacialFilterType>v.keys).location === (<GeoSpacialFilterType>event.keys).location;
        return v.name === event.name && locationNamesEqual;
      });

      if (index > -1) this.selectedFilters.splice(index, 1);
    } else if (event.type === ISearchFilterType.MULTI_DROPDOWN) {
      this.mainForm.patchValue({
        [(<TextFilterType>event.keys).textFormName]: [],
      });
      this.selectedFilters.splice(
        this.selectedFilters.findIndex((v: ISearchFilter) => v.name === event.name),
        1
      );
      this.filters = this.filters.map((f: ISearchFilter) => {
        if (f.name === event.name) {
          (<TextFilterType>f.keys).value = [];
        }
        return f;
      });
    }

    this.submitSearch.emit({ newData: this.finalFormValue, previousData });
    this.onRemoveFocusedFilter();
  }

  onFilterCommit(event: { searchFilter: ISearchFilter; form: FormGroup }): void {
    const previousData = { ...this.finalFormValue };
    const filter = event.searchFilter;
    const form = this.performFilterModifications(event.searchFilter, event.form);

    let filterIndex = 0;
    const selectedFiltersHasEventFilter = this.selectedFilters.filter((v) => v.name === filter.name);
    if (selectedFiltersHasEventFilter.length > 0) {
      filterIndex = selectedFiltersHasEventFilter.length;
    }
    if (filter.type === ISearchFilterType.GEOSPATIAL && <GeoSpacialFilterType>filter['searchPlace']) {
      filterIndex = 0;
    }
    if (filter.type === ISearchFilterType.TEXT || filter.type === ISearchFilterType.MULTI_DROPDOWN) {
      const keys = <TextFilterType>filter.keys;
      const textFormName = keys.textFormName;
      this.finalFormValue[textFormName] = form.get(textFormName).value;
    } else if (filter.type === ISearchFilterType.BOOLEAN) {
      const keys = <BooleanFilterType>filter.keys;
      const textFormName = keys.textFormName;
      this.finalFormValue[textFormName] = form.get(textFormName).value;
    } else if (filter.type === ISearchFilterType.NUMBER_RANGE) {
      const keys = <NumericalFilterType>filter.keys;
      const minFormName = keys.minFormName;
      const maxFormName = keys.maxFormName;
      this.finalFormValue[minFormName] = form.get(minFormName).value;
      this.finalFormValue[maxFormName] = form.get(maxFormName).value;
    } else if (filter.type === ISearchFilterType.GEOSPATIAL) {
      this.selectedPlacePrediction$.next(null);
      const keys = <GeoSpacialFilterType>filter.keys;
      const latFormName = keys.latFormName;
      const lonFormName = keys.lonFormName;
      const radiusFormName = keys.radiusFormName;
      const locationFormName = keys.locationFormName;
      const stateFormName = keys.stateFormName;
      const zipFormName = keys.zipFormName;

      if (this.isZipcode(form.get(locationFormName).value)) {
        this.finalFormValue[zipFormName] = form.get(locationFormName).value;
        this.finalFormValue[latFormName] = null;
        this.finalFormValue[lonFormName] = null;
        this.finalFormValue[radiusFormName] = null;
      } else {
        this.finalFormValue[latFormName] = form.get(latFormName).value;
        this.finalFormValue[lonFormName] = form.get(lonFormName).value;
        this.finalFormValue[radiusFormName] = form.get(radiusFormName).value;
        this.finalFormValue[stateFormName] = form.get(stateFormName).value;
        keys.lat = form.get(latFormName).value[filterIndex];
        keys.lon = form.get(lonFormName).value[filterIndex];
        const stateFormValue = form.get(stateFormName).value[filterIndex];
        keys.state = stateFormValue + `${stateFormValue !== '' ? ':' + filterIndex : ''}`;
        keys.location = form.get(locationFormName).value[filterIndex];
        keys.radius = form.get(radiusFormName).value[filterIndex];
        filter.keys = keys;
      }
    } else if (filter.type === ISearchFilterType.DATE_RANGE) {
      const keys = <DateRangeFilterType>filter.keys;
      const fromFormName = keys.fromFormName;
      const toFormName = keys.toFormName;
      this.finalFormValue[fromFormName] = form.get(fromFormName).value;
      this.finalFormValue[toFormName] = form.get(toFormName).value;
    } else if (filter.type === ISearchFilterType.TEXT_ARRAY) {
      const textFormName = (<TextArrayFilterType>filter.keys).textFormName;
      this.finalFormValue[textFormName] = form.get(textFormName).value;
    }

    if (filter.type === ISearchFilterType.GEOSPATIAL && <GeoSpacialFilterType>filter['searchPlace']) {
      const keys = <GeoSpacialFilterType>filter.keys;
      const locationFormName = keys.locationFormName;
      keys.location = form.get(locationFormName).value[form.get(locationFormName).value.length - 1];
      filter.keys = { ...(<GeoSpacialFilterType>filter.keys), location: keys.location } as GeoSpacialFilterType;
      this.finalFormValue[locationFormName] = form.get(locationFormName).value;
    }

    if (filter.type === ISearchFilterType.TEXT_ARRAY || filter.type === ISearchFilterType.GEOSPATIAL) {
      if (this.textArrayMode === TextArrayMode.CREATE) {
        if (selectedFiltersHasEventFilter.length < 1 && <GeoSpacialFilterType>filter['searchPlace']) {
          this.selectedFilters.push(cloneDeep(filter));
        }
      } else if (this.textArrayMode === TextArrayMode.UPDATE) {
        let index = this.selectedFilters.findIndex((f) => f.name === filter.name);
        this.selectedFilters.splice(index, 1, cloneDeep(filter));
      }
    } else {
      const foundSelectedFilter = this.selectedFilters.find((value: ISearchFilter) => value.name === filter.name);
      if (!foundSelectedFilter) {
        this.selectedFilters.push(filter);
      }
    }

    this.setDisplayFilterType(false, false, false, false);
    this.focusedFilter = null;
    this.submitSearch.emit({ newData: this.finalFormValue, previousData });
    this.shouldFocusMainSearchBar = !this.shouldFocusMainSearchBar;
    this.isSearchButtonDisabled = true;
    this.selectedFilterChange.emit(this.selectedFilters);
  }

  isZipcode(val): boolean {
    const valAsString: string = val.toString ?? '';
    return valAsString?.length === 5 && !!valAsString?.match(/\b\d{5}\b/g);
  }

  performFilterModifications(filter: ISearchFilter, form: FormGroup): FormGroup {
    let filterModifications;

    if (filter.modificationFunction) {
      filterModifications = filter.modificationFunction(form);
    }

    if (filterModifications) {
      if (filter.type === ISearchFilterType.TEXT || filter.type === ISearchFilterType.MULTI_DROPDOWN) {
        form.patchValue({
          [(<TextFilterType>filter.keys).textFormName]: (<TextFilterType>filterModifications).value,
        });
      } else if (filter.type === ISearchFilterType.NUMBER_RANGE) {
        form.patchValue({
          [(<NumericalFilterType>filter.keys).minFormName]: (<NumericalFilterType>filterModifications).min,
          [(<NumericalFilterType>filter.keys).maxFormName]: (<NumericalFilterType>filterModifications).max,
        });
      } else if (filter.type === ISearchFilterType.GEOSPATIAL) {
        const { state } = <GeoSpacialFilterType>filterModifications;
        if (state) {
          form.patchValue({
            [(<GeoSpacialFilterType>filter.keys).stateFormName]: (<GeoSpacialFilterType>filterModifications).state,
          });
        } else {
          form.patchValue({
            [(<GeoSpacialFilterType>filter.keys).latFormName]: (<GeoSpacialFilterType>filterModifications).lat,
            [(<GeoSpacialFilterType>filter.keys).lonFormName]: (<GeoSpacialFilterType>filterModifications).lon,
            [(<GeoSpacialFilterType>filter.keys).locationFormName]: (<GeoSpacialFilterType>filterModifications)
              .location,
            [(<GeoSpacialFilterType>filter.keys).radiusFormName]: (<GeoSpacialFilterType>filterModifications).radius,
            [(<GeoSpacialFilterType>filter.keys).zipcode]: (<GeoSpacialFilterType>filterModifications).zipcode,
          });
        }
      } else if (filter.type === ISearchFilterType.DATE_RANGE) {
        form.patchValue({
          [(<DateRangeFilterType>filter.keys).fromFormName]: (<DateRangeFilterType>filterModifications).from,
          [(<DateRangeFilterType>filter.keys).toFormName]: (<DateRangeFilterType>filterModifications).to,
        });
      }
    }

    return form;
  }

  onNewPlacesSearchPredictions(event: Array<PlaceInfo>): void {
    this.showResultsDropdown = true;
    this.placePredictions = event;
  }

  onPredictionSelection(event: PlaceInfo): void {
    this.selectedPlacePrediction$.next(event);
    this.showResultsDropdown = false;
  }

  onRemoveAllSearchFilters(): void {
    const previousData = { ...this.finalFormValue };
    this.selectedFilters = [];
    this.selectedFilterChange.emit(this.selectedFilters);
    this.setDisplayFilterType(false, false, false, false);
    this.focusedFilter = null;
    this.finalFormValue = {};
    this.submitSearch.emit({ newData: this.finalFormValue, previousData });
    this.mainForm = this.fb.group(this.buildFilterForm());
    this.selectedPlacePrediction$.next(null);
  }

  onSelectRecentSearch(event: ISearchFilter[]): void {
    if (event) {
      const previousData = { ...this.finalFormValue };
      this.setFinalFormValWithFilters(event);
      this.focusedFilter = null;
      this.submitSearch.emit({ newData: this.finalFormValue, previousData });
      this.shouldFocusMainSearchBar = !this.shouldFocusMainSearchBar;
      this.isSearchButtonDisabled = true;
      this.shouldShowRecentSearches = false;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { shouldResetTextArrayForm } = changes;

    if (shouldResetTextArrayForm?.currentValue) {
      Object.entries(this.mainForm.controls).map(([key, value]: [string, AbstractControl]) => {
        if (value instanceof FormArray) {
          (this.mainForm.get(key) as FormArray).clear();
        }
      });
    }
  }

  private setFinalFormValWithFilters(filters: ISearchFilter[]): void {
    if (filters) {
      this.finalFormValue = {};
      this.selectedFilters = [];
      this.mainForm = this.fb.group(this.buildFilterForm());
      filters.forEach((filter: ISearchFilter) => {
        if (filter.type === ISearchFilterType.TEXT || filter.type === ISearchFilterType.MULTI_DROPDOWN) {
          this.finalFormValue[(<TextFilterType>filter.keys).textFormName] = (<TextFilterType>filter.keys).value;
          this.mainForm.patchValue({
            [(<TextFilterType>filter.keys).textFormName]: (<TextFilterType>filter.keys).value,
          });
        } else if (filter.type === ISearchFilterType.BOOLEAN) {
          this.finalFormValue[(<BooleanFilterType>filter.keys).textFormName] = (<TextFilterType>filter.keys).value;
          this.mainForm.patchValue({
            [(<BooleanFilterType>filter.keys).textFormName]: (<BooleanFilterType>filter.keys).value,
          });
        } else if (filter.type === ISearchFilterType.NUMBER_RANGE) {
          this.finalFormValue[(<NumericalFilterType>filter.keys).minFormName] = (<NumericalFilterType>filter.keys).min;
          this.finalFormValue[(<NumericalFilterType>filter.keys).maxFormName] = (<NumericalFilterType>filter.keys).max;
          this.mainForm.patchValue({
            [(<NumericalFilterType>filter.keys).minFormName]: (<NumericalFilterType>filter.keys).min,
            [(<NumericalFilterType>filter.keys).maxFormName]: (<NumericalFilterType>filter.keys).max,
          });
        } else if (filter.type === ISearchFilterType.GEOSPATIAL) {
          const latValue = (<GeoSpacialFilterType>filter.keys).lat;
          const latFormName = (<GeoSpacialFilterType>filter.keys).latFormName;
          const latFormValue = this.finalFormValue[latFormName];
          const latHasArray = Array.isArray(latFormValue) && latFormValue.length;
          if (latHasArray) {
            (this.finalFormValue[latFormName] as number[]).push(latValue);
          } else {
            this.finalFormValue[latFormName] = [latValue];
          }
          (this.mainForm.get(latFormName) as FormArray).push(new FormControl(latValue));

          const lngValue = (<GeoSpacialFilterType>filter.keys).lon;
          const lonFormName = (<GeoSpacialFilterType>filter.keys).lonFormName;
          const lngFormValue = this.finalFormValue[lonFormName];
          const lngHasArray = Array.isArray(lngFormValue) && lngFormValue.length;
          if (lngHasArray) {
            (this.finalFormValue[lonFormName] as number[]).push(lngValue);
          } else {
            this.finalFormValue[lonFormName] = [lngValue];
          }
          (this.mainForm.get(lonFormName) as FormArray).push(new FormControl(lngValue));

          const radiusValue = (<GeoSpacialFilterType>filter.keys).radius;
          const radiusFormName = (<GeoSpacialFilterType>filter.keys).radiusFormName;
          const radiusFormValue = this.finalFormValue[radiusFormName];
          const radiusHasArray = Array.isArray(radiusFormValue) && radiusFormValue.length;
          if (radiusHasArray) {
            (this.finalFormValue[radiusFormName] as number[]).push(radiusValue);
          } else {
            this.finalFormValue[radiusFormName] = [radiusValue];
          }
          (this.mainForm.get(radiusFormName) as FormArray).push(new FormControl(radiusValue));

          const locationValue = (<GeoSpacialFilterType>filter.keys).location;
          const locationFormName = (<GeoSpacialFilterType>filter.keys).locationFormName;

          let coordinates: LatLonInput[] = [];
          filters?.forEach((item) => {
            if (
              (item.name === 'Origin' && item.keys['lat'] && item.keys['lon'] && !item.keys['state']) ||
              (item.name === 'Destination' && item.keys['lat'] && item.keys['lon'] && !item.keys['state'])
            ) {
              coordinates.push({ lat: item.keys['lat'], lon: item.keys['lon'] });
            }
          });
          if (coordinates.length) {
            this.geocodingEntityService.getReverseGecodeLocationsManager.dispatch({
              query: {
                coordinates,
              },
            });
          }
          (this.mainForm.get(locationFormName) as FormArray).push(new FormControl(locationValue));

          const stateFormName = (<GeoSpacialFilterType>filter.keys).stateFormName;
          if (stateFormName) {
            const stateValue = (<GeoSpacialFilterType>filter.keys).state;
            const stateFormValue = this.finalFormValue[stateFormName];
            const stateHasArray = Array.isArray(stateFormValue) && stateFormValue.length;
            if (stateHasArray) {
              (this.finalFormValue[stateFormName] as string[]).push(stateValue);
            } else {
              this.finalFormValue[stateFormName] = [stateValue];
            }
            (this.mainForm.get(stateFormName) as FormArray).push(new FormControl(stateValue));
          }

          const zipFormName = (<GeoSpacialFilterType>filter.keys).zipFormName;
          if (zipFormName) {
            const zipValue = (<GeoSpacialFilterType>filter.keys).zipcode;
            const zipFormValue = this.finalFormValue[zipFormName];
            const zipHasArray = Array.isArray(zipFormValue) && zipFormValue.length;
            if (zipHasArray) {
              (this.finalFormValue[zipFormName] as string[]).push(zipValue);
            } else {
              this.finalFormValue[zipFormName] = [zipValue];
            }
            (this.mainForm.get(zipFormName) as FormArray).push(new FormControl(zipValue));
          }
        } else if (filter.type === ISearchFilterType.DATE_RANGE) {
          this.finalFormValue[(<DateRangeFilterType>filter.keys).fromFormName] = (<DateRangeFilterType>(
            filter.keys
          )).from;
          this.finalFormValue[(<DateRangeFilterType>filter.keys).toFormName] = (<DateRangeFilterType>filter.keys).to;
          this.mainForm.patchValue({
            [(<DateRangeFilterType>filter.keys).fromFormName]: (<DateRangeFilterType>filter.keys).from,
            [(<DateRangeFilterType>filter.keys).toFormName]: (<DateRangeFilterType>filter.keys).to,
          });
        } else if (filter.type === ISearchFilterType.TEXT_ARRAY) {
          const currentFilterValue = (<TextArrayFilterType>filter.keys).value;
          const finalFormValue = this.finalFormValue[(<TextArrayFilterType>filter.keys).textFormName];
          const hasArrayValue = Array.isArray(finalFormValue) && finalFormValue.length;
          if (hasArrayValue) {
            (this.finalFormValue[(<TextArrayFilterType>filter.keys).textFormName] as string[]).push(currentFilterValue);
          } else {
            this.finalFormValue[(<TextArrayFilterType>filter.keys).textFormName] = [currentFilterValue];
          }

          (this.mainForm.get((<TextArrayFilterType>filter.keys).textFormName) as FormArray).push(
            new FormControl((<TextArrayFilterType>filter.keys).value)
          );
        }
      });
      this.selectedFilters.push(...filters);
      this.selectedFilterChange.emit(this.selectedFilters);
      this.setDisplayFilterType(false, false, false, false);
    }
  }
}
