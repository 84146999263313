import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { buttonTypes, Milestone, MilestoneComment } from '@haulynx/types';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MilestoneEntityService } from '@haulynx/store';
import { takeUntil } from 'rxjs/operators';
import { aliveWhile } from '@haulynx/utils';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'haulynx-comment-dialog-delete',
  templateUrl: './comment-dialog-delete.component.html',
  styleUrls: ['./comment-dialog-delete.component.scss'],
})
export class CommentDialogDeleteComponent implements OnInit {
  alive = aliveWhile();
  myForm: FormGroup;
  comment: MilestoneComment;
  isLoading$ = new BehaviorSubject<boolean>(false);
  actionButtons = [buttonTypes.MILESTONE_CANCEL, buttonTypes.DELETE_COMMENT];

  constructor(
    public matDialogRef: MatDialogRef<CommentDialogDeleteComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      comment: MilestoneComment;
      milestone: Milestone;
      loadId: string;
    },
    private milestoneEntityService: MilestoneEntityService
  ) {}

  ngOnInit() {
    this.milestoneEntityService.deleteCommentManager.isLoading$.pipe(takeUntil(this.alive)).subscribe((isLoading) => {
      if (isLoading) {
        this.isLoading$.next(true);
      } else {
        this.isLoading$.next(false);
      }
    });
    this.milestoneEntityService.deleteCommentManager.onSuccess$.pipe(takeUntil(this.alive)).subscribe((success) => {
      if (success) {
        this.matDialogRef.close(true);
      }
    });
  }

  clickEvent(eventType: string) {
    if (eventType === 'cancel') {
      this.matDialogRef.close(false);
    } else {
      this.milestoneEntityService.deleteCommentManager.dispatch(this.data.loadId, {
        commentInput: this.data.comment,
        loadId: this.data.loadId,
        milestoneId: this.data.milestone.id,
      });
    }
  }
}
