import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MetaService } from '@haulynx/services';
import { FeatureFlag, MenuItem, User } from '@haulynx/types';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent {
  @Input() user: User;
  @Input() menuItems: MenuItem[];
  @Input() reportMode: boolean;
  @Input() url: string;
  @Input() totalNotifications = 0;

  @Output() startTour = new EventEmitter();
  @Output() goToAccount = new EventEmitter();
  @Output() goToLogin = new EventEmitter();
  @Output() goToOrders = new EventEmitter();
  @Output() goToSignup = new EventEmitter();
  @Output() goToHelp = new EventEmitter();
  @Output() goToLogs = new EventEmitter();
  @Output() logout = new EventEmitter();
  @Output() showNotifications = new EventEmitter();
  @Output() showVerifyEmail = new EventEmitter();

  dedicatedFeatureFlag = FeatureFlag.DEDICATED_BUTTON;

  constructor(private metaService: MetaService) {
    this.metaService.setMobileViewport();
  }

  onShowNotifications(event: Event): void {
    event.stopPropagation();
    this.showNotifications.emit();
  }

  onShowVerifyEmail(event: Event): void {
    event.stopPropagation();
    this.showVerifyEmail.emit();
  }

  onStartTour(): void {
    this.startTour.emit();
  }

  onGoToAccount(): void {
    this.goToAccount.emit();
  }

  onGoToOrders(): void {
    this.goToOrders.emit();
  }

  onGoToHelp(): void {
    this.goToHelp.emit();
  }

  onGoToLogs(): void {
    this.goToLogs.emit();
  }

  onLogout(): void {
    this.logout.emit();
  }

  login(): void {
    this.goToLogin.emit();
  }

  signUp(): void {
    this.goToSignup.emit();
  }
}
