import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'haulynx-carrier-leaderboard',
  templateUrl: './carrier-leaderboard.component.html',
  styleUrls: ['./carrier-leaderboard.component.scss'],
})
export class CarrierLeaderboardComponent implements OnInit, OnChanges {
  @Input() loading = false;
  @Input() carrier1 = null;
  @Input() carrier2 = null;
  @Input() loads1 = [];
  @Input() loads2 = [];
  @Input() profit1 = [];
  @Input() profit2 = [];

  public chartLabels: Array<any> = [];

  public loadsChartColors: Array<any> = [];
  public revenueChartColors: Array<any> = [];

  public chartLoads1Datasets: Array<any> = [];
  public chartProfit1Datasets: Array<any> = [];
  public chartLoads2Datasets: Array<any> = [];
  public chartProfit2Datasets: Array<any> = [];

  public chartLoads1Options = null;
  public chartProfit1Options = null;
  public chartLoads2Options = null;
  public chartProfit2Options = null;

  constructor() {}

  ngOnInit(): void {
    this.chartLabels = [''];
    this.loadsChartColors = [
      {
        backgroundColor: ['#00CA8E'],
        borderColor: ['#00CA8E'],
        borderWidth: 2,
      },
      {
        backgroundColor: ['#24363A'],
        borderColor: ['#24363A'],
        borderWidth: 2,
      },
    ];
    this.revenueChartColors = [
      {
        backgroundColor: ['#0066B0'],
        borderColor: ['#0066B0'],
        borderWidth: 2,
      },
      {
        backgroundColor: ['#212C3E'],
        borderColor: ['#212C3E'],
        borderWidth: 2,
      },
    ];
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { loads1, loads2, profit1, profit2 } = changes;

    this.chartLoads1Options = {
      responsive: true,
      tooltips: { enabled: false },
      scales: {
        xAxes: [
          {
            ticks: { display: false, max: loads1?.currentValue[0] + loads1?.currentValue[1] },
            stacked: true,
          },
        ],
        yAxes: [{ stacked: true }],
      },
    };
    this.chartProfit1Options = {
      responsive: true,
      tooltips: { enabled: false },
      scales: {
        xAxes: [
          {
            ticks: { display: false, max: profit1?.currentValue[0] + profit1?.currentValue[1] },
            stacked: true,
          },
        ],
        yAxes: [{ stacked: true }],
      },
    };
    this.chartLoads2Options = {
      responsive: true,
      tooltips: { enabled: false },
      scales: {
        xAxes: [
          {
            ticks: { display: false, max: loads2?.currentValue[0] + loads2?.currentValue[1] },
            stacked: true,
          },
        ],
        yAxes: [{ stacked: true }],
      },
    };
    this.chartProfit2Options = {
      responsive: true,
      tooltips: { enabled: false },
      scales: {
        xAxes: [
          {
            ticks: { display: false, max: profit2?.currentValue[0] + profit2?.currentValue[1] },
            stacked: true,
          },
        ],
        yAxes: [{ stacked: true }],
      },
    };

    this.chartLoads1Datasets = [{ data: [loads1?.currentValue[0]] }, { data: [loads1?.currentValue[1]] }];
    this.chartProfit1Datasets = [{ data: [profit1?.currentValue[0]] }, { data: [profit1?.currentValue[1]] }];
    this.chartLoads2Datasets = [{ data: [loads2?.currentValue[0]] }, { data: [loads2?.currentValue[1]] }];
    this.chartProfit2Datasets = [{ data: [profit2?.currentValue[0]] }, { data: [profit2?.currentValue[1]] }];
  }
}
