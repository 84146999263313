import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Invitation, Relationship } from '@haulynx/types';
import { toHttpParams } from '@haulynx/utils';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InvitationService {
  constructor(private http: HttpClient) {}

  getNetwork(filter: any = { type: 'all' }): Observable<any[]> {
    const params = toHttpParams(filter);

    return this.http.get<any[]>(`/api/networks`, { params });
  }

  getRelationship(relationshipId: string): Observable<Relationship> {
    return this.http.get<Relationship>(`/api/networks/${relationshipId}`);
  }

  public createRelationship(relationship: any): Observable<any> {
    return this.http.post('/api/networks/', JSON.stringify(relationship));
  }

  public destroyRelationship(id: string): Observable<any> {
    return this.http.delete(`/api/networks/${id}`);
  }

  public updateRelationship(relationship: Relationship): Observable<any> {
    return this.http.post('/api/networks/update', JSON.stringify(relationship));
  }

  public acceptRelationship(relationship: Relationship, accept: boolean): Observable<boolean> {
    const status = accept ? 'accept' : 'reject';

    return this.http.post<boolean>(`/api/networks/${relationship.id}/${status}`, JSON.stringify(relationship));
  }

  public inviteToHaulynx(contact: {
    companyName: string;
    contactName: string;
    email: string;
    phone: string;
    dot: string;
  }): Observable<any> {
    return this.http.post('/api/users/invite', contact);
  }

  public getInvitations(): Observable<Invitation[]> {
    return this.http.get<Invitation[]>('/api/networks/invitations');
  }
}
