import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from '@haulynx/directives';
import { MaterialModule } from '@haulynx/modules';
import { SidebarNodeComponent } from './components/sidebar-node/sidebar-node.component';
import { SidebarComponent } from './sidebar.component';

@NgModule({
  declarations: [SidebarComponent, SidebarNodeComponent],
  imports: [CommonModule, RouterModule, MaterialModule, DirectivesModule, DragDropModule],
  exports: [SidebarComponent],
})
export class SidebarModule {}
