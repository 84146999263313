import { DOCUMENT } from '@angular/common';
import { EventEmitter, Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import {
  HoursOfOperation,
  LoadLocationType,
  LoadsServiceCustomer,
  MarkerTypes,
  Milestone,
  WayPoint,
} from '@haulynx/types';
import along from '@turf/along';
import bearing from '@turf/bearing';
import { lineString, point, Position } from '@turf/helpers';
import lineSlice from '@turf/line-slice';
import { isNumber } from 'lodash';
import { AnyLayer, AnySourceData, LngLatBounds, LngLatLike } from 'mapbox-gl';
import { BehaviorSubject } from 'rxjs';
import { format } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class MapboxService {
  DEFAULT_LINE_WIDTH = 2;
  DEFAULT_LINE_COLOR = '#afb6cc';
  DEFAULT_LINE_WIDTH_HIGHLIGHTED = 4;

  private renderer: Renderer2;
  private _resize = new BehaviorSubject(true);
  public $resize = this._resize.asObservable();

  constructor(@Inject(DOCUMENT) private document: Document, private rendererFactory: RendererFactory2) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  getEditUpdateLocationContent(
    milestoneId: string,
    event: EventEmitter<{
      event: string;
      locationId?: string;
      coordinates?: LngLatLike;
      milestoneId?: string;
    }>
  ): HTMLDivElement {
    const tooltipOuter: HTMLDivElement = this.renderer.createElement('div');
    this.renderer.addClass(tooltipOuter, 'tooltip-edit-location');
    this.renderer.setProperty(tooltipOuter, 'innerHTML', 'Add Comment');

    this.renderer.listen(tooltipOuter, 'click', () => {
      event.emit({ event: 'create-comment-milestone', milestoneId: milestoneId });
    });
    return tooltipOuter;
  }

  getUpdateLocationContent(
    wayPointCoordinates: LngLatLike,
    event: EventEmitter<{
      event: string;
      locationId?: string;
      coordinates?: LngLatLike;
      milestoneId?: string;
    }>
  ): HTMLDivElement {
    const tooltipOuter: HTMLDivElement = this.renderer.createElement('div');
    this.renderer.addClass(tooltipOuter, 'tooltip-create-location');

    this.renderer.setProperty(tooltipOuter, 'innerHTML', 'Create Update');

    this.renderer.listen(tooltipOuter, 'click', () => {
      event.emit({ event: 'create-location-update-milestone', coordinates: wayPointCoordinates });
    });
    return tooltipOuter;
  }

  getWaypointContent(
    milestones: Milestone[],
    waypoint: WayPoint,
    customers: LoadsServiceCustomer[],
    event: EventEmitter<{
      event: string;
      locationId?: string;
      coordinates?: LngLatLike;
      milestoneId?: string;
    }>
  ): HTMLDivElement {
    const tooltipOuter: HTMLDivElement = this.renderer.createElement('div');
    this.renderer.addClass(tooltipOuter, 'tooltip-content');

    const tooltipLabel = this.renderer.createElement('p');
    this.renderer.setProperty(tooltipLabel, 'innerHTML', waypoint.facility?.label);
    this.renderer.addClass(tooltipOuter, 'tooltip-popup-title');
    this.renderer.appendChild(tooltipOuter, tooltipLabel);

    const tooltip: HTMLDivElement = this.renderer.createElement('div');
    this.renderer.addClass(tooltip, 'tooltip-content-inner');

    const tooltipTitle = this.renderer.createElement('p');
    this.renderer.addClass(tooltipTitle, 'tooltip-facility-title');
    this.renderer.setProperty(tooltipTitle, 'innerHTML', 'FACILITY INFORMATION');
    this.renderer.appendChild(tooltip, tooltipTitle);

    const tooltipNameIconDiv: HTMLDivElement = this.renderer.createElement('div');
    this.renderer.addClass(tooltipNameIconDiv, 'tooltip-content-name');

    if (waypoint.facility?.facilityName && waypoint.facility?.facilityName !== '') {
      const tooltipNameIcon = this.renderer.createElement('img');
      this.renderer.setAttribute(tooltipNameIcon, 'src', './icons/other/haulynx-home.svg');
      this.renderer.appendChild(tooltipNameIconDiv, tooltipNameIcon);
    }

    const tooltipName = this.renderer.createElement('p');
    this.renderer.setProperty(
      tooltipName,
      'innerHTML',
      !waypoint.facility?.facilityName ? '' : waypoint.facility?.facilityName
    );
    this.renderer.appendChild(tooltipNameIconDiv, tooltipName);
    this.renderer.appendChild(tooltip, tooltipNameIconDiv);

    const tooltipLocationIconDiv: HTMLDivElement = this.renderer.createElement('div');
    this.renderer.addClass(tooltipLocationIconDiv, 'tooltip-content-name');

    const tooltipLocationIcon = this.renderer.createElement('img');
    this.renderer.setAttribute(tooltipLocationIcon, 'src', './icons/other/haulynx-location-pin.svg');
    this.renderer.appendChild(tooltipLocationIconDiv, tooltipLocationIcon);

    const tooltipLocation = this.renderer.createElement('p');
    this.renderer.setProperty(tooltipLocation, 'innerHTML', waypoint.name);
    this.renderer.appendChild(tooltipLocationIconDiv, tooltipLocation);
    this.renderer.appendChild(tooltip, tooltipLocationIconDiv);

    const tooltipTimeTitle = this.renderer.createElement('p');
    this.renderer.setProperty(
      tooltipTimeTitle,
      'innerHTML',
      '<br>Appointment Time<br>& ' + (waypoint.locationType === 'pickup' ? 'Shipping' : 'Retrieving') + ' Hours'
    );
    this.renderer.addClass(tooltipTimeTitle, 'tooltip-content-title');
    this.renderer.appendChild(tooltip, tooltipTimeTitle);

    const tooltipShippingTime = this.renderer.createElement('p');
    this.renderer.addClass(tooltipShippingTime, 'tooltip-hours');
    this.renderer.addClass(tooltipShippingTime, 'tooltip-hours-title');
    this.renderer.setProperty(
      tooltipShippingTime,
      'innerHTML',
      'Related ' + (waypoint.locationType === 'pickup' ? 'Shipping' : 'Retrieving') + ' Hours'
    );
    this.renderer.appendChild(tooltip, tooltipShippingTime);

    const tooltipShippingTimeTitle = this.renderer.createElement('p');
    this.renderer.setProperty(
      tooltipShippingTimeTitle,
      'innerHTML',
      this.getShippingOrRecevingTime(customers, waypoint)
    );
    this.renderer.addClass(tooltipShippingTimeTitle, 'tooltip-hours');
    this.renderer.appendChild(tooltip, tooltipShippingTimeTitle);

    const apptDash =
      !waypoint.facility?.appointmentTimeIn &&
      waypoint.facility?.appointmentTimeIn === waypoint.facility?.appotinmentTimeOut
        ? ''
        : ' - ';
    const appt =
      waypoint.facility?.appointmentTimeIn !== waypoint.facility?.appotinmentTimeOut
        ? format(waypoint.facility?.appointmentTimeIn, 'EEEE MM/dd, hh:mm a') +
          apptDash +
          format(waypoint.facility?.appotinmentTimeOut, 'EEEE MM/dd, hh:mm a')
        : format(waypoint.facility?.appotinmentTimeOut, 'EEEE MM/dd, hh:mm a');
    const tooltipApptTime = this.renderer.createElement('p');
    this.renderer.addClass(tooltipApptTime, 'tooltip-hours');
    this.renderer.addClass(tooltipApptTime, 'tooltip-hours-title');
    this.renderer.setProperty(tooltipApptTime, 'innerHTML', 'Appointment Time Slot:');
    this.renderer.appendChild(tooltip, tooltipApptTime);

    const tooltipApptTimeText = this.renderer.createElement('p');
    this.renderer.setProperty(tooltipApptTimeText, 'innerHTML', appt);
    this.renderer.addClass(tooltipApptTimeText, 'tooltip-hours');
    this.renderer.appendChild(tooltip, tooltipApptTimeText);

    this.renderer.appendChild(tooltipOuter, tooltip);

    const exisitingMilestone = !milestones.find((milestone) => milestone.locationId === waypoint.locationId);
    const toooltipButtonLabel = exisitingMilestone ? 'Create' : 'Edit';
    const tooltipButton = this.renderer.createElement('button');
    this.renderer.setProperty(
      tooltipButton,
      'innerHTML',
      toooltipButtonLabel +
        ' ' +
        waypoint.locationType.charAt(0).toUpperCase() +
        waypoint.locationType.substr(1).toLowerCase() +
        ' Milestone'
    );
    this.renderer.addClass(tooltipButton, exisitingMilestone ? 'tooltip-popup-button-create' : 'tooltip-popup-button');
    this.renderer.appendChild(tooltipOuter, tooltipButton);
    this.renderer.listen(tooltipButton, 'click', () => {
      event.emit({ event: 'create-edit-milestone', locationId: waypoint.locationId });
    });
    return tooltipOuter;
  }

  resize() {
    this._resize.next(true);
  }

  getRouteSource(coordinates: GeoJSON.Position[]): AnySourceData {
    return {
      type: 'geojson',
      data: {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates,
        },
      },
    };
  }

  getRouteLayerSource(
    entityId: string,
    lineColor?: string,
    lineWidth?: number,
    isLineDashed: boolean = false
  ): AnyLayer {
    if (isLineDashed) {
      return {
        id: entityId,
        type: 'line',
        source: entityId,
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': lineColor ?? this.DEFAULT_LINE_COLOR,
          'line-width': lineWidth ?? this.DEFAULT_LINE_WIDTH,
          'line-dasharray': [2, 2],
        },
      };
    }
    return {
      id: entityId,
      type: 'line',
      source: entityId,
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': lineColor ?? this.DEFAULT_LINE_COLOR,
        'line-width': lineWidth ?? this.DEFAULT_LINE_WIDTH,
      },
    };
  }

  setRouteSourceFeature(routeCoordinates: LngLatLike[]): GeoJSON.Feature<GeoJSON.Geometry> {
    return {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: <GeoJSON.Position[]>routeCoordinates,
      },
    };
  }

  getWayPointFeatures(coordinates: GeoJSON.Position, name: string): GeoJSON.Feature<GeoJSON.Point> {
    return {
      type: 'Feature',
      properties: {
        name,
      },
      geometry: {
        type: 'Point',
        coordinates,
      },
    };
  }

  sliceRoute(start: Position, finish: Position, coordinates: Position[]): Position[] {
    const route = lineString(coordinates);
    const slicedRoute = lineSlice(point(start), point(finish), route);

    return slicedRoute.geometry.coordinates;
  }

  getTruckSource(coordinates: GeoJSON.Position, name: string): AnySourceData {
    return {
      type: 'geojson',
      data: {
        type: 'Feature',
        properties: {
          name,
        },
        geometry: {
          type: 'Point',
          coordinates,
        },
      },
    };
  }

  getTruckLayerSource(entityId: string, visibility: 'visible' | 'none', iconRotate = 10): AnyLayer {
    return {
      id: entityId,
      type: 'symbol',
      source: entityId,
      layout: {
        'icon-image': entityId,
        'icon-size': 1.2,
        'icon-rotate': iconRotate,
        visibility,
      },
    };
  }

  setTruckSourceFeature(routeCoordinates: LngLatLike, name: string): GeoJSON.Feature<GeoJSON.Geometry> {
    return {
      type: 'Feature',
      properties: {
        name,
      },
      geometry: {
        type: 'Point',
        coordinates: <GeoJSON.Position>routeCoordinates,
      },
    };
  }

  getEldPointSource(features: GeoJSON.Feature[]): AnySourceData {
    return {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features,
      },
    };
  }

  setEldFeature(routeCoordinates: LngLatLike): GeoJSON.Feature<GeoJSON.Geometry> {
    return {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Point',
        coordinates: <GeoJSON.Position>routeCoordinates,
      },
    };
  }

  getEldLayerSource(entityId: string): AnyLayer {
    return {
      id: entityId,
      type: 'symbol',
      source: entityId,
      layout: {
        'icon-image': entityId,
        'icon-size': 1,
      },
    };
  }

  getEldFeatureCollection(features: GeoJSON.Feature[]): GeoJSON.FeatureCollection<GeoJSON.Geometry> {
    return {
      type: 'FeatureCollection',
      features,
    };
  }

  getBearing(routeCoordinates: Position[], position: Position): number {
    const line = lineString([...routeCoordinates].reverse() as Position[]);
    const point1 = point(position as Position);
    const point2 = along(line, 300, { units: 'meters' });

    return bearing(point1, point2);
  }

  getWayPointMarkerElem(
    marker: GeoJSON.Feature<GeoJSON.Point>,
    className: string,
    index?: number | boolean
  ): HTMLDivElement {
    const element = this.document.createElement('div');
    element.className = className;

    if (isNumber(index)) {
      element.textContent = String.fromCharCode(index + 65);
    }
    return element;
  }

  getTrailertMarkerElem(
    marker: GeoJSON.Feature<GeoJSON.Point>,
    className: string,
    index?: number | boolean
  ): HTMLDivElement {
    const element = this.document.createElement('div');
    element.className = className;
    return element;
  }

  fitBounds(routeCoordinates: LngLatLike[]): LngLatBounds {
    return routeCoordinates.reduce(
      (bounds: LngLatBounds, coordinates: LngLatLike) => bounds.extend(coordinates),
      new LngLatBounds(routeCoordinates[0], routeCoordinates[0])
    );
  }

  getDaysOfTheWeek(waypoint: WayPoint): string[] {
    const result: string[] = [];
    const daysOfTheWeek: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const apptSame = waypoint.facility?.appointmentTimeIn === waypoint.facility?.appotinmentTimeOut;
    if (apptSame) {
      return [format(waypoint.facility?.appotinmentTimeOut, 'EEEE')];
    } else {
      const indexOfDay = daysOfTheWeek.indexOf(format(waypoint.facility?.appointmentTimeIn, 'EEEE'));
      if (!indexOfDay) {
        return [];
      } else {
        let index = indexOfDay;
        result.push(format(waypoint.facility?.appointmentTimeIn, 'EEEE'));
        while (daysOfTheWeek[index] != format(waypoint.facility?.appotinmentTimeOut, 'EEEE')) {
          if (index < daysOfTheWeek.length - 1) {
            index++;
          } else {
            index = 0;
          }
          result.push(daysOfTheWeek[index]);
        }
        return result;
      }
    }
  }

  getShippingOrRecevingTime(customers: LoadsServiceCustomer[], waypoint: WayPoint): string {
    const customer = customers.find((customer) => customer.number === waypoint.facility.number);
    let fullSchedule = '';
    const daysBetween = this.getDaysOfTheWeek(waypoint);
    if (customer) {
      const shippingOrRetrevingHours: HoursOfOperation[] =
        waypoint.locationType === 'pickup' ? customer.shippingHours : customer.receivingHours;
      daysBetween?.forEach((days) => {
        shippingOrRetrevingHours?.forEach((shippingHour) => {
          if (days === shippingHour.dayOfWeek) {
            fullSchedule +=
              shippingHour?.dayOfWeek +
              ' ' +
              format(new Date(shippingHour?.openTime).valueOf(), 'hh:mm a') +
              ' - ' +
              format(new Date(shippingHour?.closeTime).valueOf(), 'hh:mm a') +
              '<br>';
          }
        });
      });
    }

    if (fullSchedule === '') {
      return 'Hours Not Available<br>';
    }

    return fullSchedule;
  }

  isWithinRange(
    routeCoordinates: LngLatLike[],
    initalCoordinates: LngLatLike
  ): { found: boolean; closetRoute: LngLatLike } {
    const found = routeCoordinates.filter((coordinates: LngLatLike) => {
      const roundedClickedLat = Math.round(initalCoordinates[1] * 1000) / 1000;
      const roundedClikedLng = Math.round(initalCoordinates[0] * 1000) / 1000;
      const roundedLat = Math.round(coordinates[1] * 1000) / 1000;
      const roundedLng = Math.round(coordinates[0] * 1000) / 1000;

      return (
        roundedLat - 0.02 <= roundedClickedLat &&
        roundedClickedLat <= roundedLat + 0.02 &&
        roundedLng - 0.02 <= roundedClikedLng &&
        roundedClikedLng <= roundedLng + 0.02
      );
    });
    return { found: found.length > 0, closetRoute: found[Math.floor(found.length / 2)] };
  }
}
