import gql from 'graphql-tag';

export const GetCarriersByNameOrDot = gql(`
  query myCarriersQuery($search: String!, $paging: PageAndSort) {
    getCarriersByNameOrDot(search: $search, paging: $paging) {
      total
      result {
        id
        name
        dot
        usxId
        rmsId
        mcNumber
        owner
        intrastateNumber
        status
        comcheck
        termAt
        termMs
        updatedAt
        updatedBy
        updatedMs
        createdMs
        createdBy
        createdAt
        notes {
          id
          type
          text
          sequence
          updatedAt
          updatedBy
          updatedMs
        }
        qualifications {
          type
          createdAt
          createdBy
        }
        phones {
          id
          type
          number
          extension
        }
        contacts {
          email
          type
        }
        email
        phone
        adminName
        sourceType
      }
    }
  }
`);
