import { Pipe, PipeTransform } from '@angular/core';
import { MapService } from '@haulynx/services';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'googleLatLon',
})
export class GoogleLatLonPipe implements PipeTransform {
  constructor(private mapService: MapService) {}

  /**
   *
   * @param obj
   * @param schema What the keys are for the latitude and longitude in the object, respectively.
   * The two keys are space delimited
   */
  transform(
    obj: { gpsLat: number; gpsLon: number } | { lat: number; lon: number },
    schema = 'gpsLat gpsLon'
  ): Observable<string> {
    const [latKey, lonKey] = schema.split(' ');
    if (!obj || !obj[latKey] || !obj[lonKey]) {
      return of('N/A');
    } else {
      return this.mapService.getApproximateLocation(obj[latKey], obj[lonKey]);
    }
  }
}
