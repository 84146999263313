import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  AdvancedSearchModule,
  AppointmentNotSetModule,
  AssignLoadCarrierContainerComponent,
  AssignLoadCarrierContainerModule,
  BulkActionsModule,
  DataTableV2Module,
  SidebarModule,
  SpinnerModule,
} from '@haulynx/components';
import { LaneRateToolGuard } from '@haulynx/guards';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { AdvancedSearchService, LoadVm } from '@haulynx/services';
import { ActiveLoadsStateModule, AsyncEntityLaneRateStoreModule } from '@haulynx/store';
import { ReactiveComponentModule } from '@ngrx/component';
import { NgxCurrencyModule } from 'ngx-currency';
import { LaneRateToolRoutingModule } from './lane-rate-tool-routing.module';
import { LaneRateToolTimelineComponent } from './lane-rate-tool-timeline/lane-rate-tool-timeline.component';
import { LaneRateToolComponent } from './lane-rate-tool.component';

@NgModule({
  declarations: [LaneRateToolComponent, LaneRateToolTimelineComponent],
  imports: [
    CommonModule,
    LaneRateToolRoutingModule,
    SidebarModule,
    AdvancedSearchModule,
    DataTableV2Module,
    MaterialModule,
    BulkActionsModule,
    SpinnerModule,
    AssignLoadCarrierContainerModule,
    PipesModule,
    AppointmentNotSetModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCurrencyModule,
    ReactiveComponentModule,
    ActiveLoadsStateModule,
    AsyncEntityLaneRateStoreModule,
  ],
  providers: [LoadVm, AdvancedSearchService, LaneRateToolGuard],
  exports: [LaneRateToolTimelineComponent],
})
export class LaneRateToolModule {}
