<div class="virutal-table-sort">
  <span
    #sortArrow
    *ngIf="
      !(pageSortState?.order | virtualTableSortTypeSelected: column) ||
      pageSortState?.sort === null ||
      pageSortState?.sort === 'desc'
    "
    (click)="changeSort(column, 'asc')"
  >
    <mat-icon class="icon-sort">expand_more</mat-icon>
  </span>
  <span
    #sortArrow
    *ngIf="
      (pageSortState?.order | virtualTableSortTypeSelected: column) &&
      pageSortState?.sort != null &&
      pageSortState?.sort === 'asc'
    "
    (click)="changeSort(column, 'desc')"
  >
    <mat-icon class="icon-sort">expand_less</mat-icon>
  </span>
</div>
