import { LoadsServiceLoad } from '../../loads-service';
import { Carrier } from '../carrier';
import { CounterOffer, User } from '../generic';
import { UserTypes } from '../user/user-type';

export enum BidSourceType {
  CARRIER = 'carrier',
  BROKER = 'broker',
}

export class Bid {
  /**
   * The current counter offer for the bid.
   */
  activeCounterOffer?: CounterOffer;
  /**
   * The releated bid history
   */
  bidHistory?: BidHistory[];
  /**
   * The current broker handling the bid
   */
  brokerId: string;
  /**
   * Resolved Carrier object from carrierId.
   */
  carrier?: Partial<Carrier>;
  carrierId: string;
  /**
   * All counter offers related to this bid.
   */
  counterOffers?: CounterOffer[];
  /**
   * millisecond timestamp
   */
  createdAt: number;
  /**
   * The reference to the user who created this model
   */
  createdBy: Partial<User>;
  /**
   * millisecond timestamp
   */
  deletedAt?: number;
  /**
   * millisecond timestamp
   */
  expiredAt?: number;
  /**
   * Unique identifier for this bid
   */
  id: string;
  /**
   * The database load id
   */
  loadId: string;
  loadsServiceLoad: LoadsServiceLoad;
  notes: string;
  price: number;
  /**
   * The source type of a bid
   */
  sourceType: BidSourceType;
  /**
   * The current acceptance state the bid
   */
  status: BidStatusType;
  /**
   * millisecond timestamp
   */
  updatedAt: number;
  /**
   * The reference to the user who last updated this model
   */
  updatedBy: Partial<User>;
  /**
   * The final state for a bid from either a carrier or a broker
   */
  finalStateFrom?: UserTypes;

  creationType: string;
  campaignOrigin: BidCampaignOrigin;
  offerState: BidOfferState;
  offerStateDescription: string;
  offerStateColor: string;
  constructor(props?: Partial<Bid>) {
    Object.assign(this, props);
  }
}

export interface UpdateBidInput {
  brokerId?: string;
  deletedAt?: number;
  expiredAt?: number;
  notes?: string;
  price?: number;
  status?: BidStatusType;
  finalStateFrom?: UserTypes;
}

export interface BidInput {
  carrierId: string;
  loadId: string;
  notes: string;
  price: number;
  status: BidStatusType;
  sourceType: BidSourceType;
  brokerId?: string;
  expiredAt?: number;
  finalStateFrom?: UserTypes;
}

export class BidHistory {
  /**
   * Entire state of the relevant bid
   */
  bid: Bid;
  /**
   * millisecond timestamp
   */
  createdAt: number;
  /**
   * A reference to the user who created this BidHistory instance
   */
  createdBy: Partial<User>;
  /**
   * The mutated portion of a corresponding bid that this log records.
   */
  data: Partial<Bid> | Partial<CounterOffer>;
  /**
   * Describes what kind of log this is.
   */
  event: BidHistoryEvent;
  /**
   * Unique identifier for this log
   */
  id: string;

  constructor(props?: Partial<Bid>) {
    Object.assign(this, props);
  }
}

export enum BidCampaignOrigin {
  DAT_DIRECT = 'dat_direct',
  MARKETING_MAIL = 'marketing_mail',
  BROKER_OFFERS = 'broker_offers',
  ML_RECOMMENDATIONS = 'ml_recommendations',
  ORGANIC_BID = 'organic_bid',
}

export enum BidOfferState {
  NO_OFFER_OR_BID = 'no_offer_or_bid',
  FIRM_OFFER = 'firm_offer',
  FIRM_BID = 'firm_bid',
  BROKER_OFFER = 'broker_offer',
  CARRIER_BID = 'carrier_bid',
  OFFER_UPDATED = 'offer_updated',
  BID_UPDATED = 'bid_updated',
  FINAL_BID = 'final_bid',
  FINAL_OFFER = 'final_offer',
  ACCEPT_OFFER = 'accept_offer',
  ACCEPT_BID = 'accept_bid',
  ACCEPT_FIRM_BID = 'accept_firm_bid',
  REJECT_OFFER = 'reject_offer',
  REJECT_BID = 'reject_bid',
  REJECT_FIRM_BID = 'reject_firm_bid',
  BID_HELD = 'bid_held',
  OFFER_HOLD = 'offer_hold',
  COUNTER_OFFER = 'counter_offer',
  COUNTER_BID = 'counter_bid',
}
export type BidHistoryEvent =
  | 'accept_bid'
  | 'create_bid'
  | 'delete_bid'
  | 'update_bid'
  | 'auto_reject_bid'
  | 'accept_counter_offer'
  | 'update_counter_offer'
  | 'create_counter_offer'
  | 'reject_counter_offer'
  | 'carrier_decrease_bid';

export enum BidHistoryEventType {
  ACCEPT_BID = 'accept_bid',
  CREATE_BID = 'create_bid',
  DELETE_BID = 'delete_bid',
  UPDATE_BID = 'update_bid',
  AUTO_REJECT_BID = 'auto_reject_bid',
  ACCEPT_COUNTER_OFFER = 'accept_counter_offer',
  UPDATE_COUNTER_OFFER = 'update_counter_offer',
  CREATE_COUNTER_OFFER = 'create_counter_offer',
  REJECT_COUNTER_OFFER = 'reject_counter_offer',
  CARRIER_DECREASE_BID = 'carrier_decrease_bid',
}

export class BidStatus {
  description: string;
  /**
   * A user-friendly name
   */
  name: string;
  value: BidStatusType;

  constructor(props?: Partial<Bid>) {
    Object.assign(this, props);
  }
}

export enum BidStatusType {
  ACCEPTED = 'accepted',
  AUTO_REJECTED = 'auto_rejected',
  LIVE = 'live',
  NO_CAPACITY = 'no_capacity',
  PENDING = 'pending',
  PRICE_REJECTED = 'price_rejected',
  PRICE_HOLD = 'price_hold',
}
