<button mat-button class="details-menu" (click)="$event.stopPropagation()">
  <mat-icon (click)="$event.stopPropagation()" [matMenuTriggerFor]="mainMenu">
    {{ menuIcon }}
  </mat-icon>
</button>

<mat-menu #mainMenu="matMenu">
  <button *ngFor="let item of menuItems" mat-menu-item (click)="execute(item.action, $event)">
    <span>{{ item.label }}</span>
  </button>
</mat-menu>
