import { Component, Inject, OnInit, Optional } from '@angular/core';
import { Customer, LoadLocationType } from '@haulynx/types';
import { CustomerFacilityDto } from '@haulynx/types';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoadFeedModel } from '@haulynx/store';

@Component({
  selector: 'app-customer-details-dialog',
  templateUrl: './customer-details-dialog.component.html',
  styleUrls: ['./customer-details-dialog.component.scss'],
})
export class CustomerDetailsDialogComponent implements OnInit {
  isLoading$: Observable<boolean>;
  customer$: Observable<Customer>;
  facilityType: LoadLocationType;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: CustomerFacilityDto,
    public loadFeedModel: LoadFeedModel
  ) {}

  ngOnInit(): void {
    const { customerNumber, index } = this.data;

    this.loadFeedModel.getCustomer(customerNumber);

    this.isLoading$ = this.loadFeedModel.isLoadingCustomer$.pipe(
      map((isLoading: { [key: string]: boolean }) => isLoading[customerNumber])
    );

    this.customer$ = this.loadFeedModel.customers$.pipe(
      map((customers: { [key: string]: Customer }) => customers[customerNumber])
    );

    this.facilityType = index > 0 ? LoadLocationType.DROPOFF : LoadLocationType.PICKUP;
  }
}
