import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ISortingConfig } from '@haulynx/types';

@Component({
  selector: 'app-column-sort',
  templateUrl: './column-sort.component.html',
  styleUrls: ['./column-sort.component.scss'],
})
export class ColumnSortComponent implements AfterViewChecked {
  @ViewChild('clicker', { static: true }) clicker: ElementRef;
  @ViewChild('dropdown', { static: false }) dropdown: ElementRef;

  @Input() buttonSort? = null;
  @Input() sortConfig: ISortingConfig[];
  @Input() dropdownAlign = 'right';

  @Output() selectOption: EventEmitter<{ config: ISortingConfig; type: 'ASC' | 'DESC' }> = new EventEmitter();

  isSelected = false;
  shouldCheckClicks = false;

  constructor(private cdr: ChangeDetectorRef) {}

  @HostListener('document:click', ['$event.target'])
  onClick(targetElement: ElementRef): void {
    if (this.isSelected && this.shouldCheckClicks) {
      const clickedDropdown = this.dropdown.nativeElement.contains(targetElement);
      if (!clickedDropdown) {
        this.isSelected = false;
      }
    }
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  onSelectSortDropdown() {
    this.isSelected = !this.isSelected;
    this.shouldCheckClicks = false;
    setTimeout(() => {
      this.shouldCheckClicks = true;
    }, 500);
    if (this.isSelected) {
    }
  }

  onSelectOptionDescending(config: ISortingConfig): void {
    this.selectOption.emit({ config, type: 'DESC' });
    this.isSelected = false;
  }

  onSelectOptionAscending(config: ISortingConfig): void {
    this.selectOption.emit({ config, type: 'ASC' });
    this.isSelected = false;
  }

  getDynamicStylingObject(): Object {
    const rect: DOMRect = this.clicker.nativeElement.getBoundingClientRect();

    // Align to bottom left of column ('clicker')
    const top = `${rect.bottom}px`;
    let left = `${rect.left}px`;

    // If align right, -280px (styling width)
    if (this.dropdownAlign === 'right') {
      left = `${rect.right - 280}px`;
    }

    return { top, left };
  }
}
