import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { NgxCurrencyModule } from 'ngx-currency';
import { DropDownModule } from '../../drop-down/drop-down.module';
import { EditLoadDetailsDialogComponent } from './edit-load-details-dialog.component';

@NgModule({
  declarations: [EditLoadDetailsDialogComponent],
  exports: [EditLoadDetailsDialogComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatInputModule,
    DropDownModule,
    PipesModule,
    MaterialModule,
    NgxCurrencyModule,
  ],
})
export class EditLoadDetailsDialogModule {}
