import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoadLocation, LoadsServiceLoad, LoadsServiceLoadLocation, User } from '@haulynx/types';
import { compareTwoTimestampsValidator, dateLessThanNowTz, requiredOneDateValidator } from '@haulynx/utils';
import moment from 'moment-timezone';

@Component({
  selector: 'confirm-delivery-dialog',
  templateUrl: 'load-confirm-delivery.component.html',
  styleUrls: ['load-confirm-delivery.component.scss'],
})
export class ConfirmDeliveryDialog {
  public user: User;
  public load: LoadsServiceLoad;
  public location: LoadsServiceLoadLocation;
  public timezone = 'etc/GMT';
  public form: FormGroup;
  public showSaveButton = true;
  public result: any = null;
  public isCarrier = false;
  public isBroker = false;
  public isPickUp = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { user: User; load: LoadsServiceLoad; loadLocationIndex: number },
    private fb: FormBuilder
  ) {
    this.user = data.user;
    this.load = data.load;
    this.location = this.load.locations[data.loadLocationIndex];
    this.timezone = this.location.timezone;
    this.isCarrier = !!this.user.carrier;
    this.isBroker = !!this.user.broker;
    this.isPickUp = this.location && this.location.locationType === 'pickup';

    if (this.user.carrier) {
      const carrierArrival = this.location.carrierArrival
        ? moment(this.location.carrierArrival).tz(this.timezone).toDate()
        : null;
      const carrierDeparture = this.location.carrierDeparture && moment(this.location.carrierDeparture).toDate();

      this.form = this.fb.group(
        {
          carrierArrival: [carrierArrival, [dateLessThanNowTz(this.timezone)]],
          carrierDeparture: [carrierDeparture, [dateLessThanNowTz(this.timezone)]],
        },
        {
          validators: [
            compareTwoTimestampsValidator('carrierArrival', 'carrierDeparture'),
            requiredOneDateValidator('carrierArrival', 'carrierDeparture'),
          ],
        }
      );
    }
  }

  check() {
    const carrierArrival = moment.tz(
      moment(this.form.getRawValue().carrierArrival).format('YYYY-MM-DD HH:mm:ss'),
      this.timezone
    );
    const carrierDeparture = moment.tz(
      moment(this.form.getRawValue().carrierDeparture).format('YYYY-MM-DD HH:mm:ss'),
      this.timezone
    );

    this.result = {
      entranceTime: carrierArrival.isValid() ? carrierArrival.valueOf() : null,
      completed: carrierDeparture.isValid() ? carrierDeparture.valueOf() : null,
    };

    this.showSaveButton = !(carrierArrival.isValid() && carrierDeparture.isValid());
  }
}
