<div class="confirm-book" *ngIf="carrierDot; else noCarrier">
  <h3 class="confirm-book__title">Confirm Mission Assignment</h3>

  <div>
    <p class="confirm-book__description">
      If you book this load, you will book the other load that is part of this mission. The carrier will receive a Rate
      Con and be informed via email that this load
      <span class="bold">AND</span>
      <button class="link" (click)="navigateToBackHaul(loadId === backHaul.id ? headHaul : backHaul)">
        Load#{{ (loadId === backHaul.id ? headHaul : backHaul) | loadAlternateId: loadIdentifierType.ORDER_NUMBER }}
      </button>
      has been booked.
    </p>
  </div>

  <div class="confirm-book__action">
    <button class="hlx-button-command hlx-button-command--cancel hlx-button-command--border-white" (click)="cancel()">
      Go Back
    </button>
    <button
      type="submit"
      class="hlx-button-command hlx-button-command--book confirm-book__button"
      (click)="save()"
      data-test="confirm-booking"
    >
      Confirm
    </button>
  </div>
</div>

<ng-template #noCarrier>
  <div class="no-carrier-dot">
    <p class="add-driver__description">Please select carrier to book a load</p>
    <button (click)="cancel()" class="hlx-button-command hlx-button-command--cancel hlx-button-command--border-white">
      Ok
    </button>
  </div>
</ng-template>
