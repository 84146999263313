import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  EmphasizedMilestone,
  KeyValuePair,
  LoadsServiceLoad,
  LoadsServiceLoadLocation,
  Milestone,
  MilestoneLog,
  MilestoneSubtype,
  MilestoneType,
  MilestoneUser,
  User,
} from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-milestone-item',
  templateUrl: './milestone-item.component.html',
  styleUrls: ['./milestone-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MilestoneItemComponent implements OnInit, OnChanges {
  @Input() user: User;
  @Input() currentUserType: MilestoneUser;
  @Input() milestone: Milestone;
  @Input() load: LoadsServiceLoad;
  @Input() milestoneIndex: number;
  @Input() open: boolean;
  @Input() collapseMilestone: { action: string; milestoneId?: string };
  @Input() badgeMilestones: KeyValuePair[] = [];
  @Input() savedCollapseMilestone: KeyValuePair[] = [];
  @Output() clickEvent: EventEmitter<{
    event: string;
    commentIndex: number;
    milestoneIndex: number;
    emphasizedMilestone: EmphasizedMilestone;
  }> = new EventEmitter();

  isOpen = false;
  alive = aliveWhile();
  open$ = new BehaviorSubject<boolean>(false);

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    const { collapseMilestone } = changes;

    if (collapseMilestone) {
      const collapsedValue = <{ action: string; milestoneId?: string }>collapseMilestone.currentValue;
      this.collapseOrExpandItems(collapsedValue.action, collapsedValue.milestoneId);
    }
  }

  ngOnInit(): void {
    this.checkSavedCollapsedItems();
  }

  private collapseOrExpandItems(action: string, milestoneId?: string) {
    if (action === 'expand-all') {
      this.isOpen = true;
      this.open$.next(true);
    } else if (action === 'collapse-all') {
      this.isOpen = false;
      this.open$.next(false);
    } else if (action === 'expand-broker') {
      if (
        this.milestone?.authorType === MilestoneUser.BROKER ||
        this.milestone?.logs.find((log) => {
          return log.editedByType === MilestoneUser.BROKER;
        })
      ) {
        this.isOpen = true;
        this.open$.next(true);
      } else {
        this.isOpen = false;
        this.open$.next(false);
      }
    } else if (action === 'expand-individual') {
      if (this.milestone.id === milestoneId) {
        this.isOpen = true;
        this.open$.next(true);
      } else {
        this.isOpen = false;
        this.open$.next(false);
      }
    }
  }

  private checkSavedCollapsedItems(): void {
    this.savedCollapseMilestone.forEach((milestone) => {
      if (milestone.key === this.milestone.id) {
        const collapseArr = milestone.value as KeyValuePair[];
        collapseArr.forEach((collapse) => {
          if (collapse.key === 'MILESTONE' && collapse.value) {
            this.isOpen = true;
            this.open$.next(true);
          }
        });
      }
    });
  }

  openOrClose(): void {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      this.open$.next(true);
    } else {
      this.open$.next(false);
    }
    this.clickEvent.emit({
      event: 'expand-milestone',
      milestoneIndex: this.isOpen ? this.milestoneIndex : null,
      commentIndex: null,
      emphasizedMilestone: this.isOpen
        ? { index: this.milestoneIndex, type: this.milestone.type, subType: this.milestone.logs?.[0]?.subType }
        : null,
    });

    this.savedCollapseMilestone.forEach((milestone) => {
      if (milestone.key === this.milestone.id) {
        const collapseArr = milestone.value as KeyValuePair[];
        collapseArr.forEach((collapse) => {
          if (collapse.key === 'MILESTONE') {
            collapse.value = this.isOpen;
          }
        });
      }
    });
  }

  get isMilestoneLocation(): boolean {
    return this.milestone?.type === MilestoneType.LOCATION;
  }

  get isMilestoneTrailer(): boolean {
    return this.milestone?.type === MilestoneType.TRAILER;
  }

  get isDispatchMilestone(): boolean {
    return this.milestone?.logs.filter((log) => log.subType === MilestoneSubtype.DISPATCH).length > 0;
  }

  isMilestoneIssue(): boolean {
    return this.milestone?.type === MilestoneType.UPDATE;
  }

  get countSubItems(): number {
    if (this.milestone.telemetryHistory) {
      return this.milestone.telemetryHistory.length + this.milestone.comments?.length;
    }
    const logLength = this.milestone?.logs.length;
    const commentLength = this.milestone?.comments.length;
    return logLength + commentLength;
  }

  get getMilestoneItemTitle(): string {
    if (this.isMilestoneIssue()) {
      switch (this.milestone?.logs[0].subType) {
        case MilestoneSubtype.LOCATION: {
          return 'General Location Update';
        }
        case MilestoneSubtype.OSD: {
          return 'Load Issue: OS&D';
        }
        case MilestoneSubtype.TONU: {
          return 'Load Issue: TONU/Layover';
        }
        default: {
          let subTypeString = this.milestone?.logs[0].subType.toLocaleLowerCase().split('');
          subTypeString[0] = subTypeString[0].toUpperCase();
          subTypeString = subTypeString.join('').split('_');
          subTypeString = subTypeString
            .map((l) => {
              const x = l.split('');
              x[0] = x[0].toUpperCase();
              return x.join('');
            })
            .join(' ')
            .split('');
          return `Load Issue: ${subTypeString.join('')} `;
        }
      }
    } else if (this.milestone.type === MilestoneType.TRAILER) {
      return 'All Trailer Position Updates';
    } else if (this.milestone?.type === MilestoneType.LOCATION) {
      return 'Location Update';
    } else if (this.milestone?.logs[0].subType === MilestoneSubtype.DISPATCH) {
      return 'Dispatch Empty';
    } else {
      return this.getNonIssueTitle();
    }
  }

  get getMilestoneLogsSorted(): MilestoneLog[] {
    const logs = [...this.milestone.logs];
    return logs.sort((a, b) => b.timestamp - a.timestamp);
  }

  get getTelemetryHistory() {
    return [...this.milestone.telemetryHistory];
  }

  get hasBrokerEdit(): boolean {
    return this.milestone?.logs.some((l) => l.editedByType === MilestoneUser.BROKER);
  }

  get hasTelemetryHistory(): boolean {
    return !!this.milestone.telemetryHistory;
  }

  get isSystemBrokerOrCarrierGenerated(): string {
    if (this.milestone?.telemetryHistory) {
      return 'Trailer SkyBits Generated';
    }
    const hasSystem = this.milestone?.logs.some((l) => l.editedByType === MilestoneUser.SYSTEM);
    const hasBroker = this.hasBrokerEdit;
    const hasCarrier = this.milestone?.logs.some((l) => l.editedByType === MilestoneUser.CARRIER);

    if (hasSystem && hasBroker && hasCarrier) {
      return 'Broker, Carrier & System Generated';
    } else if (hasCarrier && hasBroker) {
      return 'Broker & Carrier Generated';
    } else if (hasSystem && hasCarrier) {
      return 'Carrier & System Generated';
    } else if (hasSystem && hasBroker) {
      return 'Broker & System Generated';
    } else if (hasSystem) {
      return 'System Generated';
    } else if (hasBroker) {
      return 'Broker Generated';
    } else if (hasCarrier) {
      return 'Carrier Generated';
    }
  }

  getNonIssueTitle(): string {
    const location: LoadsServiceLoadLocation = this.load?.locations.find(
      (location) => location.id === this.milestone?.locationId
    );
    if (location) {
      let loc = location.locationType.split('');
      loc[0] = loc[0].toUpperCase();
      loc = loc.join('').split('_');
      loc = loc
        .map((l) => {
          const x = l.split('');
          x[0] = x[0].toUpperCase();
          return x.join('');
        })
        .join(' ')
        .split('');

      const isDetention = this.milestone?.logs.filter((log) => log.subType === MilestoneSubtype.DETENTION).length > 0;
      const isCompletePickupOrDropoff =
        this.milestone?.logs.filter((log) => log.subType === MilestoneSubtype.COMPLETE).length > 0;
      return loc.join('') + (isDetention ? ' / Detention' : isCompletePickupOrDropoff ? '' : ' / Incomplete');
    } else return '';
  }

  onAction(event: { action: string; commentIndex: number }): void {
    this.clickEvent.emit({
      event: event.action,
      milestoneIndex: this.milestoneIndex,
      commentIndex: event.commentIndex,
      emphasizedMilestone: {
        index: this.milestoneIndex,
        subType: this.milestone.logs[0].subType,
        type: this.milestone.type,
      },
    });
  }

  get displayEditMilestone(): boolean {
    if (this.milestone?.type === MilestoneType.LOCATION) {
      return false;
    } else if (this.milestone.type === MilestoneType.TRAILER) {
      return false;
    } else {
      for (let i = 0; i < this.milestone?.logs.length; i++) {
        if (this.milestone.logs[i].editedByType === this.currentUserType) {
          return false;
        }
      }
      return true;
    }
  }

  get displayCreateComment(): boolean {
    if (this.milestone?.type === MilestoneType.LOCATION) {
      return false;
    } else if (this.milestone?.type === MilestoneType.TRAILER) {
      return false;
    } else {
      return true;
    }
  }

  get getMilestoneItemColor(): string {
    let result = '';
    switch (this.milestone?.type) {
      case MilestoneType.CHECKPOINT: {
        if (this.milestone?.logs.filter((log) => log.subType === MilestoneSubtype.DETENTION).length > 0) {
          result = '#F25E40';
        } else {
          result = '#00CA8E';
        }
        break;
      }
      case MilestoneType.UPDATE: {
        if (this.milestone?.logs.find((log) => log.subType === MilestoneSubtype.LOCATION)) {
          result = '#00CA8E';
        } else {
          result = '#F25E40';
        }
        break;
      }
      case MilestoneType.LOCATION: {
        result = '#AFB6CC';
        break;
      }
      case MilestoneType.TRAILER: {
        result = '#CD41FF';
        break;
      }
    }
    return result;
  }

  get getBadge(): string {
    let result = '';
    this.badgeMilestones.forEach((milestone) => {
      if (milestone.value === this.milestone?.id) {
        result = milestone.key;
      }
    });
    return result;
  }
}
