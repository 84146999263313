import { Injectable } from '@angular/core';
import { AnalyticsService } from '@haulynx/services';
import { ANALYTICS_EVENT } from '@haulynx/types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, tap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '../../main-store/app.reducers';
import { DispatchAction } from '../../shared/store/helpers/action';
import { DashboardActionTypes } from './dashboard.actions';
import { getNavOpenSelector } from './dashboard.selectors';

@Injectable({ providedIn: 'root' })
export class DashboardEffects {
  constructor(private actions$: Actions, private store: Store<AppState>, private analytics: AnalyticsService) {}

  toggleNav = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DashboardActionTypes.TOGGLE_NAV),
        map((action: DispatchAction) => action.payload),
        withLatestFrom(this.store.select(getNavOpenSelector)),
        tap(([payload, navIsOpen]) => {
          this.analytics.logEvent(ANALYTICS_EVENT.NAV_TOGGLE, { navIsOpen });
        })
      ),
    {
      dispatch: false,
    }
  );
}
