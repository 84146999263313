import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getApptText',
})
export class GetApptTextPipe implements PipeTransform {
  transform(regionalManagerPhone: string): string {
    return `This appointment hasn’t been set yet. Please call us at ${
      regionalManagerPhone || '151515151'
    }. We’ll be happy to assist you with additional scheduling details.`;
  }
}
