import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private token;
  constructor(
    public router: Router,
    public dialog: MatDialog,
    public http: HttpClient,
    private authService: AuthService,
    private userService: UserService
  ) {}

  login(email, password): Observable<any> {
    return this.authService.signIn({ email, password });
  }

  logout(email: string): Observable<boolean> {
    return this.authService.signOut(email);
  }

  resetPassword(email: string) {
    const body = JSON.stringify({ email });

    return this.authService.resetPassword(body);
  }

  isLoggedIn(): Observable<boolean> {
    return this.userService.token.pipe(map((token: string) => !!token));
  }
}
