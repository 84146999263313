<mat-toolbar class="toolbar" fxLayout="row">
  <div fxHide.lt-lg class="desktop-view" fxLayout="row" fxLayoutAlign="space-between center" fxFlexFill>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="40px" fxFlex="1 1 calc(100% - 352px)">
      <div mat-icon-button class="xt-logo" fxLayout="row" fxLayoutAlign="center center">
        <mat-icon svgIcon="xt-header-logo"></mat-icon>
      </div>
      <haulynx-header-toolbar-tabs
        [headerItems]="headerItems"
        [user]="user"
        (whatsNewtour)="whatsNewTour()"
        (seletedHeaderItem)="seletedItem($event)"
      ></haulynx-header-toolbar-tabs>
    </div>
    <div fxLayout="row" fxLayoutAlign="end center" fxFlex="352px">
      <haulynx-header-endbuttons
        [headerItems]="headerItems"
        [user]="user"
        [totalNotifications]="totalNotifications"
        (showNotifications)="onShowNotifications()"
        (showVerifyEmail)="onShowVerifyEmail()"
        (openLogout)="onOpenLogout()"
        (goToHelp)="onGoToHelp()"
        (seletedHeaderItem)="seletedItem($event)"
        (complete)="clickCompleted()"
        (continueRmis)="clickToRmis()"
        [rmisProps]="rmisProps"
      ></haulynx-header-endbuttons>
    </div>
  </div>
  <div fxHide fxShow.lt-lg class="mobile-view" fxLayout="row" fxLayoutAlign="space-between center">
    <ng-container *ngIf="isHomeScreen; else mobilePageDisplay">
      <div mat-icon-button class="xt-logo" fxLayout="row" fxLayoutAlign="center center">
        <mat-icon svgIcon="xt-header-logo"></mat-icon>
      </div>
      <span class="page-display">{{ currentHeaderItem?.name }}</span>
    </ng-container>

    <ng-template #mobilePageDisplay>
      <ng-container [ngSwitch]="isSidenavOpen">
        <ng-container *ngSwitchCase="true">
          <span class="material-icons close" (click)="closeSidenav()"> close </span>
        </ng-container>
        <ng-container *ngSwitchCase="false">
          <span class="material-icons back" (click)="back()"> chevron_left </span>
        </ng-container>
      </ng-container>
      <span class="page-display">{{ pageTitle$ | async }}</span>
    </ng-template>
    <div fxLayout="row" fxLayoutAlign="center center">
      <ng-container *ngIf="totalNotifications">
        <div class="alert">
          <span class="badge" *ngIf="totalNotifications > 0">{{
            totalNotifications > 99 ? '99+' : totalNotifications
          }}</span>
        </div>
      </ng-container>
      <button mat-icon-button class="menu-button" (click)="sidenav.toggle()" data-test="sidebar-trigger">
        <mat-icon>menu</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
