<div class="white-outline-button">
  <button
    mat-stroked-button
    type="button"
    data-test="white-outline-button"
    [tabindex]="tabindex"
    (click)="buttonClick($event)"
    [disabled]="disabled || loading"
  >
    <haulynx-spinner [active]="true" [diameter]="24" *ngIf="loading"></haulynx-spinner>

    <div
      *ngIf="!loading"
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="4px"
      class="white-outline-button-contents"
    >
      <mat-icon
        class="rmis_tooltip"
        matTooltip="{{ tooltipText }}"
        matTooltipClass="tooltip"
        *ngIf="tooltipText && iconPosition === 'after'"
        >help_outline</mat-icon
      >
      <haulynx-icon *ngIf="iconPosition === 'before'" [icon]="icon" [svgIcon]="svgIcon"></haulynx-icon>
      <span *ngIf="text">{{ text }}</span>
      <mat-icon
        class="rmis_tooltip"
        matTooltip="{{ tooltipText }}"
        matTooltipClass="tooltip"
        *ngIf="tooltipText && iconPosition === 'before'"
        >help_outline</mat-icon
      >
      <haulynx-icon *ngIf="iconPosition === 'after'" [icon]="icon" [svgIcon]="svgIcon"></haulynx-icon>
    </div>
  </button>
</div>
