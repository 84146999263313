export enum SubscriptionAction {
  CREATE = 'create',
  READ = 'read',
  LOCK = 'lock',
  UNLOCK = 'unlock',
  UPDATE = 'update',
  DELETE = 'delete',
  /**
   * Used when user is viewing entity
   */
  OPEN = 'open',
  /**
   * Used when user is no longer viewing entity
   */
  CLOSE = 'close',
}
