import { Injectable } from '@angular/core';
import { Location, UnauthenticatedLoadsServiceLoad } from '@haulynx/types';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from '../../main-store/app.reducers';
import { SharedLocationActions } from './shared-location.actions';
import { isLoadingSharedLoadSelector, sharedLoadSelector, sharedLocationSelector } from './shared-location.selectors';

@Injectable({
  providedIn: 'root',
})
export class SharedLocationModel {
  sharedLoad$: Observable<UnauthenticatedLoadsServiceLoad> = this.store.select(sharedLoadSelector);
  isLoadingSharedLoad$: Observable<boolean> = this.store.select(isLoadingSharedLoadSelector);
  sharedLocation$: Observable<Location> = this.store.select(sharedLocationSelector);

  constructor(private store: Store<AppState>) {}

  getSharedLoad(hash: string): void {
    this.store.dispatch(SharedLocationActions.getSharedLoad(hash));
  }

  getSharedLocation(hash: string): void {
    this.store.dispatch(SharedLocationActions.getSharedLocation(hash));
  }

  setSharedLoad(load: UnauthenticatedLoadsServiceLoad): void {
    this.store.dispatch(SharedLocationActions.getSharedLoadSuccess(load));
  }
}
