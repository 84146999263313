import gql from 'graphql-tag';

export const SearchLanesQuery = gql(`
  query SearchLanesForCarrier($dot: String!, $paging: PageAndSort) {
    getCarrierLaneWithCitiesMatchByCarrierDot(dot: $dot, paging: $paging) {
      total
      result {
        lane
        id
        orgCityState
        destCityState
        confidence
      }
    }
  }
`);
