<div class="feed-map-wrapper">
  <div class="now-viewing" *ngIf="!timerFinished && isExclusive">
    <mat-icon>alarm</mat-icon>
    Time Left
    <span class="viewers">
      <countdown
        [config]="{
          stopTime: +(load.restrictions | loadRestrictions: loadsServiceRestrictionTypes.EXCLUSIVE_UNTIL),
          clock: counterClock,
          template: counterTemplate
        }"
        (finished)="onTimerFinished()"
      >
      </countdown>
    </span>
  </div>
  <app-map-routes
    data-test="map-route"
    [padding]="100"
    [zoom]="8"
    [maxZoom]="load?.bookStatus && load?.bookStatus !== 'booked' && load?.bookStatus !== 'pause' ? 8 : null"
    [sources]="loadFeedRoute"
    [showTruckRoute]="showTruckRoute"
  ></app-map-routes>
</div>
