import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DirectivesModule } from '@haulynx/directives';
import { SidebarNavTabsComponent } from './sidebar-nav-tabs.component';
import { MaterialModule } from '@haulynx/modules';

@NgModule({
  declarations: [SidebarNavTabsComponent],
  imports: [CommonModule, MaterialModule, DirectivesModule, FlexLayoutModule],
  exports: [SidebarNavTabsComponent],
})
export class SidebarNavTabsModule {}
