import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  DateRangeFilterType,
  GeoSpacialFilterType,
  GooglePlacePrediction,
  ISearchFilter,
  ISearchFilterType,
  NumericalFilterType,
  RecentSearch,
  TextFilterType,
} from '@haulynx/types';

@Component({
  selector: 'app-search-recent-search',
  templateUrl: './search-recent-search.component.html',
  styleUrls: ['./search-recent-search.component.scss'],
})
export class SearchRecentSearchComponent implements OnInit, OnChanges {
  @ViewChild('wrapper', { static: false }) wrapper: ElementRef;

  @Input() recentSearches: RecentSearch[];
  @Input() filters: ISearchFilter[];
  @Input() isOpen: boolean;

  @Output() selectRecentSearch: EventEmitter<RecentSearch> = new EventEmitter();
  @Output() mouseFocus: EventEmitter<boolean> = new EventEmitter();

  indexOfFocus = -1;
  scrollDirection: 'start' | 'end' = 'start';
  isMouseFocus = false;
  recentSearchesConvertedToFilters: ISearchFilter[][] = [];
  canClickToClose = false;

  textFilterType = ISearchFilterType.TEXT;
  locationFilterType = ISearchFilterType.GEOSPATIAL;
  numberRangeFilterType = ISearchFilterType.NUMBER_RANGE;
  dateRangeFilterType = ISearchFilterType.DATE_RANGE;

  constructor() {}

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent): void {
    if (this.isOpen && this.canClickToClose) {
      if (event.key === 'ArrowDown' && this.indexOfFocus < this.recentSearches.length - 1) {
        this.indexOfFocus += 1;
        this.scrollDirection = 'start';
        this.isMouseFocus = false;
      } else if (event.key === 'ArrowUp' && this.indexOfFocus > 0) {
        this.indexOfFocus -= 1;
        this.scrollDirection = 'end';
        this.isMouseFocus = false;
      } else if (event.key === 'Enter' || event.key === 'Tab') {
        if (this.recentSearches.length > 0) {
          this.selectRecentSearch.emit(
            this.recentSearches.find((p: GooglePlacePrediction, i: number) => i === this.indexOfFocus)
          );
        }
      }
    }
  }

  @HostListener('mouseenter') componentContainsMouse(): void {
    this.mouseFocus.emit(true);
  }

  @HostListener('document:click', ['$event.target']) onClick(targetElement: ElementRef): void {
    if (this.isOpen) {
      const clickedInside = this.wrapper.nativeElement.contains(targetElement);
      if (!clickedInside && this.canClickToClose) {
        this.mouseFocus.emit(false);
      }
    }
  }

  ngOnInit(): void {
    if (this.recentSearches) {
      this.recentSearches.forEach((search: RecentSearch) => {
        const thisSearchFilters: ISearchFilter[] = [];
        this.filters.forEach((filter: ISearchFilter) => {
          if (filter.type === ISearchFilterType.TEXT) {
            if (search[(<TextFilterType>filter.keys).textFormName]) {
              (<TextFilterType>filter.keys).value = search[(<TextFilterType>filter.keys).textFormName];
              thisSearchFilters.push(filter);
            }
          } else if (filter.type === ISearchFilterType.NUMBER_RANGE) {
            if (search[(<NumericalFilterType>filter.keys).minFormName]) {
              (<NumericalFilterType>filter.keys).min = search[(<NumericalFilterType>filter.keys).minFormName];
              (<NumericalFilterType>filter.keys).max = search[(<NumericalFilterType>filter.keys).maxFormName];
              thisSearchFilters.push(filter);
            }
          } else if (filter.type === ISearchFilterType.GEOSPATIAL) {
            if (search[(<GeoSpacialFilterType>filter.keys).locationFormName]) {
              (<GeoSpacialFilterType>filter.keys).location =
                search[(<GeoSpacialFilterType>filter.keys).locationFormName];
              (<GeoSpacialFilterType>filter.keys).lat = search[(<GeoSpacialFilterType>filter.keys).latFormName];
              (<GeoSpacialFilterType>filter.keys).lon = search[(<GeoSpacialFilterType>filter.keys).lonFormName];
              (<GeoSpacialFilterType>filter.keys).radius = search[(<GeoSpacialFilterType>filter.keys).radiusFormName];
              thisSearchFilters.push(filter);
            }
          } else if (filter.type === ISearchFilterType.DATE_RANGE) {
            if (search[(<DateRangeFilterType>filter.keys).fromFormName]) {
              (<DateRangeFilterType>filter.keys).from = search[(<DateRangeFilterType>filter.keys).fromFormName];
              (<DateRangeFilterType>filter.keys).to = search[(<DateRangeFilterType>filter.keys).toFormName];
              thisSearchFilters.push(filter);
            }
          }
        });
        this.recentSearchesConvertedToFilters.push(thisSearchFilters);
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isOpen && this.isOpen) {
      this.canClickToClose = false;
      setTimeout(() => {
        this.canClickToClose = true;
      }, 100);
    }
  }

  onSelectRecentSearch(event: RecentSearch): void {
    this.selectRecentSearch.emit(event);
  }
}
