import { LoadOrderType } from '../web-interfaces';

export class LoadsServiceProvider {
  id: string;
  name: string;
  detailType: string;
  company?: string;
}

export class LoadsServiceProviderInput {
  id: string;
  name: string;
  detailType: string;
  company?: string;
}

export class LoadsServiceProviderDetails {
  alternateIds?: LoadsServiceProviderDetailId[];
  billTo?: string;
  billToName?: string;
  brokerTeamId?: BrokerTeamId;
  operationsPriority?: OperationsPriority;
  cxPriority?: number;
  equipmentType?: LoadsServiceEquipmentType;
  commodity?: string;
  packingType?: string;
  quantity?: number;
  weight?: number;
  weightLbs?: number;
  weightUnits?: WeightUnits;
  temperature?: number;
  temperatureUnits?: TemperatureUnits;
  regionalManagerName?: string;
  regionalManagerEmail?: string;
  regionalManagerPhone?: string;
  cxrEmployeeId?: string;
  internalNotes?: string;
  externalNotes?: string;
  orderType?: LoadOrderType;
}

export class LoadsServiceProviderDetailsInput {
  alternateIds?: LoadsServiceProviderDetailIdInput[];
  billTo?: string;
  billToName?: string;
  brokerTeamId?: BrokerTeamId;
  operationsPriority?: OperationsPriority;
  cxPriority?: number;
  equipmentType?: LoadsServiceEquipmentType;
  commodity?: string;
  packingType?: string;
  quantity?: number;
  weightLbs?: number;
  temperatureF?: number;
  regionalManagerName?: string;
  regionalManagerEmail?: string;
  regionalManagerPhone?: string;
  cxrEmployeeId?: string;
  internalNotes?: string;
  externalNotes?: string;
  orderType?: LoadOrderType;
}

export enum LoadsServiceOrderByType {
  carrierName = 'carrierName',
  cxPriority = 'cxPriority',
  cxrEmployeeId = 'cxrEmployeeId',
  destinationLocation = 'destinationLocation',
  distanceMiles = 'distanceMiles',
  firstAppointmentEnd = 'firstAppointmentEnd',
  firstAppointmentStart = 'firstAppointmentStart',
  lastAppointmentEnd = 'lastAppointmentEnd',
  lastAppointmentStart = 'lastAppointmentStart',
  loadStatus = 'loadStatus',
  maxBuy = 'maxBuy',
  missionId = 'missionId',
  numberOfAppointmentResets = 'numberOfAppointmentResets',
  operationsPriority = 'operationsPriority',
  originLocation = 'originLocation',
  price = 'price',
  billTo = 'billTo',
  quantity = 'quantity',
  ratePerMile = 'ratePerMile',
  revenue = 'revenue',
  weightLbs = 'weightLbs',
}

export enum LoadsServiceOrderByTypeDirection {
  ASC = 'asc',
  DESC = 'desc',
  NONE = '',
}

export enum LoadIdentifierType {
  /**
   * only on usx loads
   */
  MONGO_ID = 'mongoId',
  /**
   * only on usx loads
   */
  ORDER_NUMBER = 'orderNumber',
  /**
   * only on usx loads
   */
  TMW_NUMBER = 'tmwNumber',
  /**
   * only on usx loads
   */
  MASTER_BILL = 'masterBill',
  /**
   * manually input on carrier loads
   */
  BILL_OF_LADING = 'billOfLading',
  /**
   * manually input on carrier loads
   */
  PO_NUMBER = 'poNumber',
  /**
   * load identifier from an external freight tracking api, such as Project44 or MacroPoint
   */
  TRACKING_REFERENCE_ID = 'trackingReferenceId',
}

export class LoadsServiceProviderDetailId {
  identifierType: LoadIdentifierType;
  identifierValue: string;
}

export class LoadsServiceProviderDetailIdInput {
  identifierType: LoadIdentifierType;
  identifierValue: string;
}

export enum OperationsPriority {
  NONE,
  LOW,
  MEDIUM,
  HIGH,
}

export const EquipmentTypeToLoadsServiceEquipmentType = (equipmentType: string): LoadsServiceEquipmentType => {
  switch (equipmentType) {
    case EquipmentType.ANY:
      return LoadsServiceEquipmentType.ANY;
    case EquipmentType.FLAT_BED:
      return LoadsServiceEquipmentType.FLAT_BED;
    case EquipmentType.DRY_VAN:
      return LoadsServiceEquipmentType.DRY_VAN;
    case EquipmentType.REEFER:
      return LoadsServiceEquipmentType.REEFER;
    case EquipmentType.LOWBOY:
      return LoadsServiceEquipmentType.LOWBOY;
    case EquipmentType.STEP_DECK:
      return LoadsServiceEquipmentType.STEP_DECK;
    case EquipmentType.CAR_HAULER:
      return LoadsServiceEquipmentType.CAR_HAULER;
    case EquipmentType.POWER_ONLY:
      return LoadsServiceEquipmentType.POWER_ONLY;
    case EquipmentType.DRY_VAN_OR_REEFER:
      return LoadsServiceEquipmentType.DRY_VAN_OR_REEFER;
    case EquipmentType.OTHER:
      return LoadsServiceEquipmentType.OTHER;
    default:
      return LoadsServiceEquipmentType.OTHER;
  }
};

export const EquipmentTypeToLoadsEquipmentType = (equipmentType: string): EquipmentType => {
  switch (equipmentType) {
    case LoadsServiceEquipmentType.ANY:
      return EquipmentType.ANY;
    case LoadsServiceEquipmentType.FLAT_BED:
      return EquipmentType.FLAT_BED;
    case LoadsServiceEquipmentType.DRY_VAN:
      return EquipmentType.DRY_VAN;
    case LoadsServiceEquipmentType.REEFER:
      return EquipmentType.REEFER;
    case LoadsServiceEquipmentType.LOWBOY:
      return EquipmentType.LOWBOY;
    case LoadsServiceEquipmentType.STEP_DECK:
      return EquipmentType.STEP_DECK;
    case LoadsServiceEquipmentType.CAR_HAULER:
      return EquipmentType.CAR_HAULER;
    case LoadsServiceEquipmentType.POWER_ONLY:
      return EquipmentType.POWER_ONLY;
    case LoadsServiceEquipmentType.DRY_VAN_OR_REEFER:
      return EquipmentType.DRY_VAN_OR_REEFER;
    case LoadsServiceEquipmentType.OTHER:
      return EquipmentType.OTHER;
    default:
      return EquipmentType.OTHER;
  }
};

export enum EquipmentType {
  ANY = 'Any',
  FLAT_BED = 'Flat Bed',
  DRY_VAN = 'Dry Van',
  DRY_VAN_OR_REEFER = 'Dry Van or Reefer',
  REEFER = 'Reefer',
  LOWBOY = 'Lowboy',
  STEP_DECK = 'Step Deck',
  CAR_HAULER = 'Car Hauler',
  POWER_ONLY = 'Power Only',
  OTHER = 'Other',
}

export enum LoadsServiceEquipmentType {
  ANY = 'any',
  FLAT_BED = 'flatBed',
  DRY_VAN = 'dryVan',
  DRY_VAN_OR_REEFER = 'dryVanOrReefer',
  REEFER = 'reefer',
  LOWBOY = 'lowboy',
  STEP_DECK = 'stepDeck',
  CAR_HAULER = 'carHauler',
  POWER_ONLY = 'powerOnly',
  OTHER = 'other',
}

export enum TemperatureUnits {
  CELSIUS = 'C',
  FAHRENHEIT = 'F',
}

export enum WeightUnits {
  KILOGRAMS = 'kg',
  POUNDS = 'lb',
}

export enum BrokerTeamId {
  SOUTH = 'SOUTH',
  MINNY = 'MINNY',
  WESTP = 'WESTP',
  INSIDE = 'INSIDE',
  CHICG = 'CHICG',
  NORTH = 'NORTH',
  ONEST = 'ONEST',
  USXCX = 'USXCX',
}

/**
 *  All below is Used in Vitual Table Sort functionality.
 */
/**
 * VirtualTableSortTypes displyed with VirtualTable sort dialog
 */
export enum VirtualTableSortTypes {
  destinationLocation = 'Destination Name',
  distanceMiles = 'Loaded Miles',
  firstAppointmentStart = 'Origin Date/Time',
  lastAppointmentStart = 'Destination Date/Time',
  originLocation = 'Origin Name',
  price = 'Price',
  billTo = 'Bill To',
  ratePerMile = 'Rate Per Mile',
  weightLbs = 'Weight',
}

/**
 * Converts LSL order types to VirtualTableSortTypes
 * @param type
 * @returns VirtualTableSort Type
 */
export const LoadsServiceOrderByTypeToVirtualTableSortTypes = (
  type: LoadsServiceOrderByType
): VirtualTableSortTypes => {
  switch (type) {
    case LoadsServiceOrderByType.originLocation:
      return VirtualTableSortTypes.originLocation;
    case LoadsServiceOrderByType.destinationLocation:
      return VirtualTableSortTypes.destinationLocation;
    case LoadsServiceOrderByType.firstAppointmentStart:
      return VirtualTableSortTypes.firstAppointmentStart;
    case LoadsServiceOrderByType.lastAppointmentStart:
      return VirtualTableSortTypes.lastAppointmentStart;
    case LoadsServiceOrderByType.price:
      return VirtualTableSortTypes.price;
    case LoadsServiceOrderByType.billTo:
      return VirtualTableSortTypes.billTo;
    case LoadsServiceOrderByType.ratePerMile:
      return VirtualTableSortTypes.ratePerMile;
    case LoadsServiceOrderByType.distanceMiles:
      return VirtualTableSortTypes.distanceMiles;
    case LoadsServiceOrderByType.weightLbs:
      return VirtualTableSortTypes.weightLbs;
    default:
      return null;
  }
};

/**
 * Converts VirtualTableSortTypes to LSL order types.
 * @param type
 * @returns LoadsServiceOrderByType
 */
export const VirtualTableSortTypesToLoadsServiceOrderByType = (
  type: VirtualTableSortTypes
): LoadsServiceOrderByType => {
  switch (type) {
    case VirtualTableSortTypes.originLocation:
      return LoadsServiceOrderByType.originLocation;
    case VirtualTableSortTypes.destinationLocation:
      return LoadsServiceOrderByType.destinationLocation;
    case VirtualTableSortTypes.firstAppointmentStart:
      return LoadsServiceOrderByType.firstAppointmentStart;
    case VirtualTableSortTypes.lastAppointmentStart:
      return LoadsServiceOrderByType.lastAppointmentStart;
    case VirtualTableSortTypes.price:
      return LoadsServiceOrderByType.price;
    case VirtualTableSortTypes.billTo:
      return LoadsServiceOrderByType.billTo;
    case VirtualTableSortTypes.ratePerMile:
      return LoadsServiceOrderByType.ratePerMile;
    case VirtualTableSortTypes.distanceMiles:
      return LoadsServiceOrderByType.distanceMiles;
    case VirtualTableSortTypes.weightLbs:
      return LoadsServiceOrderByType.weightLbs;
    default:
      return null;
  }
};

/**
 * VirtualSortDirection used to display sort direction in virtual table sort dialog.
 */
export enum VirtualSortDirection {
  ALHPABETIC_ASC = 'Ascending (A-Z)',
  ALHPABETIC_DESC = 'Descending (Z-A)',
  NUMBER_ASC = 'Ascending (1-10)',
  NUMBER_DESC = 'Descending (10-1)',
  DATE_ASC = 'Ascending (Jan-Dec)',
  DATE_DESC = 'Descending (Dec-Jan)',
}

/**
 * Matches VirtualTableSortTypes to correct VirtualSortDirection
 * @param type
 * @returns VirtualSortDirection[]
 */
export const MatchVirtualTableSortDirectionToType = (type: VirtualTableSortTypes): VirtualSortDirection[] => {
  switch (type) {
    case VirtualTableSortTypes.originLocation:
      return [VirtualSortDirection.ALHPABETIC_ASC, VirtualSortDirection.ALHPABETIC_DESC];
    case VirtualTableSortTypes.destinationLocation:
      return [VirtualSortDirection.ALHPABETIC_ASC, VirtualSortDirection.ALHPABETIC_DESC];
    case VirtualTableSortTypes.billTo:
      return [VirtualSortDirection.ALHPABETIC_ASC, VirtualSortDirection.ALHPABETIC_DESC];
    case VirtualTableSortTypes.firstAppointmentStart:
      return [VirtualSortDirection.DATE_ASC, VirtualSortDirection.DATE_DESC];
    case VirtualTableSortTypes.lastAppointmentStart:
      return [VirtualSortDirection.DATE_ASC, VirtualSortDirection.DATE_DESC];
    case VirtualTableSortTypes.price:
      return [VirtualSortDirection.NUMBER_ASC, VirtualSortDirection.NUMBER_DESC];
    case VirtualTableSortTypes.ratePerMile:
      return [VirtualSortDirection.NUMBER_ASC, VirtualSortDirection.NUMBER_DESC];
    case VirtualTableSortTypes.distanceMiles:
      return [VirtualSortDirection.NUMBER_ASC, VirtualSortDirection.NUMBER_DESC];
    case VirtualTableSortTypes.weightLbs:
      return [VirtualSortDirection.NUMBER_ASC, VirtualSortDirection.NUMBER_DESC];
    default:
      return null;
  }
};

/**
 * Converts VirtualSortDirection to LSL LoadsServiceOrderByTypeDirection
 * @param type
 * @returns LoadsServiceOrderByTypeDirection
 */
export const VirtualTableSortTypeToLoadsServiceDirectionSortType = (type: VirtualSortDirection) => {
  switch (type) {
    case VirtualSortDirection.ALHPABETIC_ASC:
      return LoadsServiceOrderByTypeDirection.ASC;
    case VirtualSortDirection.DATE_ASC:
      return LoadsServiceOrderByTypeDirection.ASC;
    case VirtualSortDirection.NUMBER_ASC:
      return LoadsServiceOrderByTypeDirection.ASC;
    case VirtualSortDirection.ALHPABETIC_DESC:
      return LoadsServiceOrderByTypeDirection.DESC;
    case VirtualSortDirection.DATE_DESC:
      return LoadsServiceOrderByTypeDirection.DESC;
    case VirtualSortDirection.NUMBER_DESC:
      return LoadsServiceOrderByTypeDirection.DESC;
    default:
      return null;
  }
};
