import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CarrierMetrics } from '@haulynx/types';

@Component({
  selector: 'app-carrier-analytics-metrics',
  templateUrl: './carrier-analytics-metrics.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarrierAnalyticsMetricsComponent {
  @Input() carrierMetrics: CarrierMetrics | any = null;
}
