import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WindowRef } from '../../app-services/generic/window-ref';

@Injectable({
  providedIn: 'root',
})
export class GoogleAddressService {
  _window: any;

  constructor(private windowRef: WindowRef) {
    this._window = this.windowRef.getNativeWindow();
  }

  getCurrentPosition(): Observable<{ address: string; lon: number; lat: number }> {
    return new Observable((subscriber) => {
      const geolocation = this._window.navigator.geolocation;

      if (geolocation) {
        geolocation.getCurrentPosition((position) => {
          const { longitude, latitude } = position.coords;

          subscriber.next({
            address: 'Your Location',
            lon: longitude,
            lat: latitude,
          });
          subscriber.complete();
        });
      } else {
        subscriber.next(null);
        subscriber.complete();
      }
    });
  }
}
