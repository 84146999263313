export class mapBoxRequestOptions {
  access_token: string;
  geometries = 'geojson';
  max_height = 4.1148;
  max_width = 2.5908;
  max_weight = 36.28736;
  language = 'en';
  constructor(mapBoxccessToken: string) {
    this.access_token = mapBoxccessToken;
  }
}

export class mapBoxDrivingRequestOptions {
  access_token: string;
  geometries = 'geojson';
  language = 'en';

  constructor(mapBoxccessToken: string) {
    this.access_token = mapBoxccessToken;
  }
}

export const MapOptions = {
  lineColor: '#ddd',
  lineWidth: 2,
  markerColor: '#c60008',
};

export enum MapOverview {
  FULL = 'full',
  SIMPLIFIED = 'simplified',
}
