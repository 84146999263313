import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AppModel, UserEntityService } from '@haulynx/store';
import {
  CustomerFacilityDto,
  FeatureFlag,
  FFState,
  LoadIdentifierType,
  LoadsServiceLoad,
  LoadsServiceRestrictionTypes,
  NumberOfStops,
} from '@haulynx/types';
import {
  aliveWhile,
  carrierAcceptedLoadsServiceLoad,
  getNumberOfStops,
  normalizeLoadsServiceLoadWayPoints,
} from '@haulynx/utils';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-lf-details-info-pane',
  templateUrl: './load-feed-details-info-pane.component.html',
  styleUrls: ['./load-feed-details-info-pane.component.scss'],
})
export class LoadFeedDetailsInfoPaneComponent implements OnInit, OnDestroy, OnChanges {
  @Input() load: LoadsServiceLoad;
  @Input() carrierDot: string;
  @Input() isExclusive = false;
  @Input() isCarrier: boolean;
  @Input() carrierId: string;
  @Output() bookSuccess = new EventEmitter<string>();
  @Output() showFacilityInfo = new EventEmitter<CustomerFacilityDto>();

  alive = aliveWhile();
  numberOfStops: NumberOfStops;
  carrierId$: Observable<string>;
  carrierBidEnabled = FeatureFlag.CARRIER_BID;
  canBid = false;
  isAcceptedCarrier: boolean;
  isValidAcceptedCarrier: boolean;
  loadIdentifierType = LoadIdentifierType;
  loadsServiceRestrictionTypes = LoadsServiceRestrictionTypes;

  constructor(public appModel: AppModel, private userEntityService: UserEntityService) {
    this.userEntityService.featureFlags$.pipe(takeUntil(this.alive)).subscribe((feature: FFState) => {
      if (feature[this.carrierBidEnabled]) {
        this.canBid = true;
      }
    });
  }

  ngOnInit(): void {}

  onBookSuccess(loadId: string): void {
    this.bookSuccess.emit(loadId);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { load } = changes;

    if (load) {
      this.setNumberOfStops(load.currentValue as LoadsServiceLoad);
      this.isAcceptedCarrier = carrierAcceptedLoadsServiceLoad(this.load, this.carrierDot);
      this.isValidAcceptedCarrier = (this.isCarrier || this.carrierId || this.carrierDot) && !this.isAcceptedCarrier;
    }
  }

  onShowFacilityInfo({ customerNumber, index }: CustomerFacilityDto): void {
    this.showFacilityInfo.emit({ customerNumber, index });
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }

  private setNumberOfStops(load: LoadsServiceLoad): void {
    if (load) {
      this.numberOfStops = normalizeLoadsServiceLoadWayPoints(
        load,
        getNumberOfStops(load.locations, 'pickup'),
        getNumberOfStops(load.locations, 'dropoff')
      );
    }
  }
}
