<app-load-feed-search-form
  [equipmentTypes]="equipmentTypes"
  [query]="searchQuery$ | async"
  (onSearch)="search($event)"
  (onClick)="userSearch($event)"
>
</app-load-feed-search-form>

<div class="row" [ngStyle]="maxHeight ? { 'max-height': maxHeight, width: '100%' } : {}">
  <div class="col-lg-12">
    <app-spinner [active]="isLoading$ | async" [valignTop]="true"></app-spinner>
    <mat-card
      class="rmis-setup-panel"
      fxLayoutAlign="start center"
      data-test="rmis-banner"
      *ngIf="appModel.showRmisBanner$ | async"
    >
      <mat-card-header>
        <mat-card-title>
          <mat-icon> chevron_right </mat-icon>
          <span> Complete Your USX RMIS Carrier Setup to View Load Details and Book Freight </span>
        </mat-card-title>
        <div class="fill-remaining-space"></div>
        <button mat-raised-button (click)="launchRMIS()" data-test="launch-rmis-button">
          <app-spinner [active]="true" [diameter]="36" *ngIf="loadingRmisToken | async"></app-spinner>
          <span *ngIf="!(loadingRmisToken | async)">Launch RMIS</span>
        </button>
      </mat-card-header>
    </mat-card>

    <div class="load-feed-panel" *ngIf="!brokerId">
      <expand-panel
        [ngClass]="{ rolled: !isExpandedRecommended }"
        [isExpanded]="isExpandedRecommended"
        (onExpanded)="toggleRecommended()"
      >
        <div expandPanelLabel>
          <span class="info-badge-wrapper">
            Recommended Loads
            <span *ngIf="!isExpandedRecommended" class="info-badge">
              {{ recommendedEntities.length }}
            </span>
          </span>
        </div>
        <div expandPanelContent>
          <data-table
            *ngIf="isExpandedRecommended"
            [newLoadfeed]="true"
            [data]="filteredRecommendedEntities$ | async"
            [loading]="loadEntityService.getCarrierLoadRecommends.isSearching$ | async"
            [paginationData]="loadEntityService.getCarrierLoadRecommends.searchPaginationResults$ | async"
            (onPagination)="onPageRecommend($event)"
            [displayedColumns]="loadFeedColumns$ | async"
            [pageOptions]="userPageOptions"
            [cls]="'load-feeds clickable-row'"
            [clickableRow]="true"
            (onClick)="viewLoad($event)"
            [templateCellRef]="templateCellRecommended"
            data-test="recommended-loads"
          >
            <ng-template #templateCellRecommended let-row="row" let-column="column">
              <div *ngIf="column === 'pickupLocation'">
                {{ row.locations | nth: 0 | get: 'address':'N/A' | address: 3 }}
              </div>

              <div *ngIf="column === 'pickupState'">
                {{ row.locations | nth: 0 | get: 'address':'N/A' | address: 4 }}
              </div>

              <div *ngIf="column === 'deliveryLocation'">
                {{ row.locations | nth: -1 | get: 'address':'N/A' | address: 3 }}
              </div>

              <div *ngIf="column === 'deliveryState'">
                {{ row.locations | nth: -1 | get: 'address':'N/A' | address: 4 }}
              </div>

              <div *ngIf="column === 'pickupTimestamp'">
                {{ row.locations | nth: 0 | loadServiceLocationTime: { timeFormat: 'MM/dd', showLabel: true } }}
              </div>

              <div *ngIf="column === 'dropoffTimestamp'">
                {{ row.locations | nth: -1 | loadServiceLocationTime: { timeFormat: 'MM/dd', showLabel: true } }}
              </div>

              <div *ngIf="column === 'equipmentType'">
                {{ (row.providerDetails?.equipmentType | fromCamelCase) || 'N/A' }}
              </div>

              <div *ngIf="column === 'distance'">
                {{ row.paymentDetails?.distanceMiles || 'N/A' }}
              </div>

              <div *ngIf="column === 'tmwNumber'">
                {{ row | loadAlternateId: loadIdentifierType.TMW_NUMBER || 'N/A' }}
              </div>

              <div *ngIf="column === 'order'">
                {{ row | loadAlternateId: loadIdentifierType.ORDER_NUMBER || 'N/A' }}
              </div>

              <div *ngIf="column === 'shipper'">
                {{ row?.shipperName }}
              </div>

              <div *ngIf="column === 'rpm'">
                {{ row.paymentDetails?.ratePerMile | showCurrency: 'USD':'symbol':'1.2-2' }}
              </div>

              <div *ngIf="column === 'carrierOwner'">
                {{ row?.carrier?.owner }}
              </div>

              <div *ngIf="column === 'price'">
                <app-load-price
                  *ngIf="row.paymentDetails.price; else noPrice"
                  [price]="row.paymentDetails.price"
                  [loadId]="row.id"
                  [editablePrice]="!!brokerId"
                ></app-load-price>
              </div>

              <div *ngIf="column === 'revenue'" class="table-currency-col">
                {{ row.paymentDetails?.revenue | showCurrency: 'USD':'symbol':'1.0-0' }}
              </div>

              <div *ngIf="column === 'weight'">
                {{ (row?.providerDetails?.weight | safeNumber: '1.0-0') || 'N/A' }}
              </div>

              <ng-container *ngIf="column === 'options'">
                <div fxLayout="row" fxLayoutAlign="center center" fxFlexFill>
                  <ng-container *appFeatures="carrierBidEnabled; else showArrowRecommended">
                    <app-carrier-bid-chips
                      [bid]="row.carrierBid"
                      *ngIf="row.carrierBid?.status && !brokerId; else showArrowRecommended"
                    ></app-carrier-bid-chips>
                  </ng-container>
                </div>
              </ng-container>
            </ng-template>
          </data-table>
        </div>
      </expand-panel>
    </div>

    <div *ngIf="showSearch">
      <h5 *ngIf="showSearchResultHeader" class="panel-header">Search Results</h5>
      <data-table
        [newLoadfeed]="true"
        [data]="searchResults$ | async"
        [loading]="
          loadEntityService.getUSXLoadsManager.isSearching$ || loadEntityService.getLoadByNumberManager.isSearching$
            | async
        "
        [paginationData]="
          loadEntityService.getUSXLoadsManager.searchPaginationResults$ ||
            loadEntityService.getLoadByNumberManager.searchPaginationResults$ | async
        "
        (onPagination)="onPage($event)"
        [displayedColumns]="loadFeedColumns$ | async"
        [pageOptions]="userPageOptions"
        [cls]="'load-feeds clickable-row'"
        [clickableRow]="true"
        (onClick)="viewLoad($event)"
        [templateCellRef]="templateCellSearch"
        data-test="recommended-search-results-table"
      >
        <ng-template #templateCellSearch let-row="row" let-column="column">
          <div *ngIf="column === 'pickupLocation'" data-test="search-pickup-city">
            {{ row.locations | nth: 0 | get: 'address':'N/A' | address: 3 }}
          </div>

          <div *ngIf="column === 'pickupState'" data-test="search-pickup-state">
            {{ row.locations | nth: 0 | get: 'address':'N/A' | address: 4 }}
          </div>

          <div *ngIf="column === 'deliveryLocation'" data-test="search-destination-city">
            {{ row.locations | nth: -1 | get: 'address':'N/A' | address: 3 }}
          </div>

          <div *ngIf="column === 'deliveryState'" data-test="search-destination-state">
            {{ row.locations | nth: -1 | get: 'address':'N/A' | address: 4 }}
          </div>

          <div *ngIf="column === 'pickupTimestamp'" data-test="search-pickup-date">
            <!-- For carriers, show no appt tooltip if there isn't an appt -->
            <div *ngIf="!brokerId; else isBroker">
              <div *ngIf="row.locations[0].appointmentSet">
                <div>
                  {{ row.locations | nth: 0 | loadServiceLocationTime: { timeFormat: 'MM/dd/yy', showLabel: true } }}
                </div>
                <span class="load-time">
                  {{ row.locations | nth: 0 | loadServiceLocationTime: { timeFormat: 'HH:mm', showLabel: true } }}
                </span>
              </div>
              <ng-container
                [ngTemplateOutlet]="noAppt"
                [ngTemplateOutletContext]="{ row: row }"
                *ngIf="!row.locations[0].appointmentSet"
              ></ng-container>
            </div>
            <ng-template #isBroker>
              <div>
                {{ row.locations | nth: 0 | loadServiceLocationTime: { timeFormat: 'MM/dd/yy', showLabel: true } }}
              </div>
              <span class="load-time">
                {{ row.locations | nth: 0 | loadServiceLocationTime: { timeFormat: 'HH:mm', showLabel: true } }}
              </span>
              <mat-icon *ngIf="row.locations[0].appointmentSet" class="appointment"> check </mat-icon>
            </ng-template>
          </div>

          <div *ngIf="column === 'dropoffTimestamp'" data-test="search-destination-date">
            <!-- For carriers, show no appt tooltip if there isn't an appt -->
            <div *ngIf="!brokerId; else isBroker">
              <div *ngIf="row.locations[row.locations.length - 1].appointmentSet">
                <div>
                  {{ row.locations | nth: -1 | loadServiceLocationTime: { timeFormat: 'MM/dd/yy', showLabel: true } }}
                </div>
                <span class="load-time">
                  {{ row.locations | nth: -1 | loadServiceLocationTime: { timeFormat: 'HH:mm', showLabel: true } }}
                </span>
              </div>

              <ng-container
                [ngTemplateOutlet]="noAppt"
                [ngTemplateOutletContext]="{ row: row }"
                *ngIf="!row.locations[row.locations.length - 1].appointmentSet"
              ></ng-container>
            </div>
            <ng-template #isBroker>
              <div>
                {{ row.locations | nth: -1 | loadServiceLocationTime: { timeFormat: 'MM/dd/yy', showLabel: true } }}
              </div>
              <span class="load-time">
                {{ row.locations | nth: -1 | loadServiceLocationTime: { timeFormat: 'HH:mm', showLabel: true } }}
              </span>
              <mat-icon *ngIf="row.locations[row.locations.length - 1].appointmentSet" class="appointment">
                check
              </mat-icon>
            </ng-template>
          </div>

          <div *ngIf="column === 'equipmentType'" data-test="search-equipment">
            {{ (row.providerDetails?.equipmentType | fromCamelCase) || 'N/A' }}
          </div>

          <div *ngIf="column === 'distance'" data-test="search-distance">
            {{ row.paymentDetails?.distanceMiles || 'N/A' }}
          </div>

          <div *ngIf="column === 'order'" data-test="search-order">
            {{ row | loadAlternateId: loadIdentifierType.ORDER_NUMBER || 'N/A' }}
          </div>

          <div *ngIf="column === 'customer'">
            {{ row.providerDetails?.billTo || 'N/A' }}
          </div>

          <div *ngIf="column === 'tmwNumber'" data-test="search-tmw">
            {{ row | loadAlternateId: loadIdentifierType.TMW_NUMBER || 'N/A' }}
          </div>

          <div *ngIf="column === 'order'">
            {{ row | loadAlternateId: loadIdentifierType.BILL_OF_LADING || 'N/A' }}
          </div>

          <div *ngIf="column === 'shipper'">
            {{ row.shipperName }}
          </div>

          <div *ngIf="column === 'carrierOwner'">
            {{ row?.carrier?.owner }}
          </div>

          <div *ngIf="column === 'rpm'" data-test="search-rpm">
            {{
              row.paymentDetails?.ratePerMile || row.paymentDetails?.price / row.paymentDetails?.distanceMiles
                | showCurrency: 'USD':'symbol':'1.2-2'
            }}
          </div>

          <div *ngIf="column === 'price'" data-test="search-price">
            <app-load-price
              *ngIf="row.paymentDetails.price; else noPrice"
              [price]="row.paymentDetails.price"
              [loadId]="row.id"
              [editablePrice]="!!brokerId"
            ></app-load-price>
          </div>

          <div *ngIf="column === 'bidDetails'">
            <app-load-bid-details [row]="row"></app-load-bid-details>
          </div>

          <div *ngIf="column === 'revenue'">
            {{ row.paymentDetails?.revenue | showCurrency: 'USD':'symbol':'1.0-0' }}
          </div>

          <div *ngIf="column === 'weight'" data-test="search-weight">
            {{ (row?.providerDetails?.weight | safeNumber: '1.0-0') || 'N/A' }}
          </div>

          <ng-container *ngIf="column === 'options'">
            <div fxLayout="row" fxLayoutAlign="center center" fxFlexFill>
              <ng-container *appFeatures="carrierBidEnabled; else showBookButton">
                <app-carrier-bid-chips
                  [bid]="row.carrierBid"
                  *ngIf="row.carrierBid?.status && !brokerId; else showBookButton"
                ></app-carrier-bid-chips>
              </ng-container>

              <ng-template #showBookButton>
                <div fxFlexFill *ngIf="brokerId; else showArrow">
                  <app-book-load-button
                    [showNonBookable]="false"
                    [load]="row"
                    [carrierDot]="carrierDot"
                    [buttonText]="brokerId || isCompanyAdmin ? 'BOOK LOAD' : null"
                    (bookSuccess)="bookSuccess($event)"
                  >
                  </app-book-load-button>
                  <div *ngIf="!brokerId">
                    <button mat-button class="call-button" *ngIf="row.bookStatus != bookStatus.BOOKABLE">
                      View Details
                    </button>
                  </div>
                </div>
              </ng-template>
            </div>
          </ng-container>

          <ng-container *ngIf="column === 'actions'">
            <action-buttons [buttons]="actions" (onClick)="onAction($event, row)"> </action-buttons>
          </ng-container>
        </ng-template>
      </data-table>
    </div>
  </div>
</div>

<ng-template #noAppt let-row="row">
  <button
    mat-raised-button
    [matTooltip]="row?.providerDetails?.regionalManagerPhone | getApptText"
    matTooltipClass="no-appt-tooltip"
  >
    <div class="no-appt-button" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="4px">
      <span>No Appt</span>
      <mat-icon>error_outline</mat-icon>
    </div>
  </button>
</ng-template>

<ng-template #noPrice let-row="row">
  <button
    class="noAppointment"
    mat-raised-button
    [matTooltip]="row?.providerDetails?.regionalManagerPhone | getPriceText: canBid"
    matTooltipClass="no-appt-tooltip"
  >
    <div class="no-appt-button" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="4px">
      <span>No Price Yet <mat-icon class="exclamation">error_outline</mat-icon></span>
    </div>
  </button>
</ng-template>

<ng-template class="arrow" #showArrowRecommended>></ng-template>

<ng-template class="arrow" #showArrow>
  <div fxFlexFill>></div>
</ng-template>
