import { Injectable } from '@angular/core';
import { LoadAlternateIdPipe } from '@haulynx/pipes';
import { AnalyticsService, LoadByIdService } from '@haulynx/services';
import {
  ANALYTICS_EVENT,
  AssignDriverForm,
  BookLoadForCarrier,
  DriverAssignment,
  Load,
  LoadIdentifierType,
  LoadsServiceLoad,
  TrackingType,
} from '@haulynx/types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, tap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '../../main-store/app.reducers';
import { DispatchAction } from '../../shared/store/helpers/action';
import { LoadDetailsActionTypes } from './load-details.actions';
import { loadDetailsSelector } from './load-details.selectors';

@Injectable()
export class LoadDetailsAnalyticsEffects {
  logBookLoadForCarrier = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoadDetailsActionTypes.BOOK_LOAD_FOR_CARRIER),
        map((action: DispatchAction) => action.payload),
        tap((payload: BookLoadForCarrier) => {
          const { data } = payload;

          switch (data.assignmentInfo.trackingType) {
            case TrackingType.DRIVER_LITE:
              this.analytics.logEvent(ANALYTICS_EVENT.TRACKING_TYPE, { isdriverlite: true });
              break;
            case TrackingType.MANUAL:
              this.analytics.logEvent(ANALYTICS_EVENT.TRACKING_TYPE, { manual: true });
              break;
            case TrackingType.PHONE:
              this.analytics.logEvent(ANALYTICS_EVENT.TRACKING_TYPE, { isdriverPhone: true });
              break;
            case TrackingType.MACRO_POINT:
              this.analytics.logEvent(ANALYTICS_EVENT.TRACKING_TYPE, { ismacropoint: true });
              break;
            case TrackingType.MACRO_POINT_ELD:
              this.analytics.logEvent(ANALYTICS_EVENT.TRACKING_TYPE, { ismacropointEld: true });
              break;
            default:
              break;
          }
        })
      ),
    { dispatch: false }
  );

  logAssignToDriver = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoadDetailsActionTypes.ASSIGN_TO_DRIVER),
        map((action: DispatchAction) => action.payload),
        withLatestFrom(this.store.select(loadDetailsSelector.getState)),
        tap(([payload, load]: [{ loadId: string; data: AssignDriverForm }, Load]) => {
          const { data } = payload as { loadId: string; data: AssignDriverForm };
          const { trackingType } = data;

          switch (trackingType) {
            case TrackingType.DRIVER_LITE:
              this.analytics.logEvent(ANALYTICS_EVENT.TRACKING_TYPE, { isdriverlite: true });
              break;
            case TrackingType.MANUAL:
              this.analytics.logEvent(ANALYTICS_EVENT.TRACKING_TYPE, { manual: true });
              break;
            case TrackingType.PHONE:
              this.analytics.logEvent(ANALYTICS_EVENT.TRACKING_TYPE, { isdriverPhone: true });
              break;
            case TrackingType.MACRO_POINT:
              this.analytics.logEvent(ANALYTICS_EVENT.TRACKING_TYPE, { ismacropoint: true });
              break;
            case TrackingType.MACRO_POINT_ELD:
              this.analytics.logEvent(ANALYTICS_EVENT.TRACKING_TYPE, { ismacropointEld: true });
              break;
            default:
              break;
          }
        })
      ),
    { dispatch: false }
  );

  logCreateDriver = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoadDetailsActionTypes.CREATE_DRIVER),
        map((action: DispatchAction) => action.payload),
        withLatestFrom(this.store.select(loadDetailsSelector.getStateKey)),
        tap(([payload, searchQuery]: [DriverAssignment, string]) => {
          this.loadByIdService.get(searchQuery).subscribe((load) => {
            const analyticValues = {
              carrierName: payload.carrierName,
              carrierDot: +payload.carrierDot,
              loadId: searchQuery,
              loadTmwNum: this.alternateIdPipe.transform(load, LoadIdentifierType.TMW_NUMBER),
              loadOrderNum: this.alternateIdPipe.transform(load, LoadIdentifierType.BILL_OF_LADING),
              driverName: payload.name,
              driverPhone: payload.phone,
              driverEmail: payload.email,
              mobileTrackingInvite: payload.tracking,
            };
            this.analytics.logEvent(ANALYTICS_EVENT.BROKER_ADD_DRIVER_INFO, analyticValues);
          });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private analytics: AnalyticsService,
    private store: Store<AppState>,
    private loadByIdService: LoadByIdService,
    private alternateIdPipe: LoadAlternateIdPipe
  ) {}

  logCreateTruckSuccess(key: string, truckId: string): void {
    this.loadByIdService.get(key).subscribe((load: LoadsServiceLoad) => {
      const analyticValues = {
        truckId: truckId,
        carrierName: load.carrier?.name,
        carrierDot: load.carrier.dot,
        loadId: load.id,
        loadTmwNum: this.alternateIdPipe.transform(load, LoadIdentifierType.TMW_NUMBER),
        loadOrderNum: this.alternateIdPipe.transform(load, LoadIdentifierType.BILL_OF_LADING),
      };

      if (analyticValues) {
        this.analytics.logEvent(ANALYTICS_EVENT.BROKER_ADD_TRUCK_INFO, analyticValues);
      }
    });
  }
}
