import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';
import { MaterialModule } from '@haulynx/modules';
import { DatepickerComponent } from './datepicker.component';

export class CustomDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: any): string {
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear();
    return `${month}/${day}/${year}`;
  }

  getYear(date: Date): number {
    return date.getUTCFullYear();
  }

  getMonth(date: Date): number {
    return date.getUTCMonth();
  }

  getDate(date: Date): number {
    return date.getUTCDate();
  }
}

@NgModule({
  declarations: [DatepickerComponent],
  imports: [CommonModule, ReactiveFormsModule, MaterialModule],
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
  ],
  exports: [DatepickerComponent],
})
export class DatepickerModule {}
