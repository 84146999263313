<ng-container *ngIf="!horizontal; else horizontalTemplate">
  <div *ngIf="exposed$ | async as exposed">
    <div *ngFor="let broker of exposed; trackBy: trackByFn">
      {{ broker.usxId || broker.name }}
    </div>

    <span *ngIf="horizontal && brokers?.length && brokers?.length > 1">,&nbsp;</span>

    <div *ngIf="brokers?.length > maxCount + 1">
      <a class="more-link" [matTooltip]="allItems" matTooltipPosition="above" matTooltipClass="tooltip-class">
        + {{ brokers?.length - maxCount }} others
      </a>
    </div>

    <div *ngIf="exposed.length === brokers.length - 1">
      {{ brokers[brokers.length - 1].usxId || brokers[brokers.length - 1].name }}
    </div>

    <div *ngIf="!brokers?.length">None</div>
  </div>
</ng-container>

<ng-template #horizontalTemplate>
  <div class="horizontal">
    <div>
      {{ exposedJoined$ | async }}
      <ng-container *ngIf="brokers?.length > maxCount">
        <a class="more-link" [matTooltip]="allItems" matTooltipPosition="above" matTooltipClass="tooltip-class">
          and {{ brokers?.length - maxCount }} more...
        </a>
      </ng-container>
    </div>

    <div *ngIf="!brokers?.length">None</div>
  </div>
</ng-template>
