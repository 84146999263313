import gql from 'graphql-tag';

export const GetCarrierAssetsByDot = gql(`
query getCarrierAssetsByDot($dot:String!){
  getCarrierAssetsByDot(dot:$dot){
    carrier {
        id
        name
        dot
        temperature
        insurance
        weight
        owner
        mcNumber
        equipmentType
        isHaulynxUser
        isEldCarrier
        eldEnabledDate
        isLoadDistributionEnabled
        loadFeedEnabled
        loadFeedEnabledDate
        hazmatCapable
        rank
        maximumDeadMiles
        minimumLoadValue
        phone
        email
        timeZone
        addressStreet
        addressStreet2
        addressCity
        addressState
        addressCountry
        mcNumber
        saferWatchData {
            mcNumber
        }
        sourceType
        status
        thirdPartyTracking{
          trackingType
          mobileTrackingEnabled
          vehicleTrackingEnabled
        }
    }
    trucks {
        unitId
        id
        carrierId
        imei
        type
        thirdPartyTracking{
            trackingType
            isSupported
        }
    }
    trailers {
        id
        type
        trailerNumber
        inactive
    }
    drivers {
        id
        name
        phone
        email
        isDriver
    }
  }
}
`);
