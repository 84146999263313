import { IGenericActions, IGenericActionsTypes } from '../../shared/store/generic-actions/actions';
import { createAction, createActionTypes } from '../../shared/store/helpers/action';
import { combineAdaptersActionsState, combineAdaptersActionTypeState } from '../../shared/store/helpers/adapter';
import { ISearchActions, ISearchTypes } from '../../shared/store/search/actions';
import { ITabsActions, ITabsActionTypes } from '../../shared/store/tabs/actions';
import {
  loadActiveTabsAdapter,
  loadAdapter,
  loadAvailableTabsAdapter,
  loadOffersSearchAdapter,
  LOAD_ACTIVE_TABS_TYPE,
  TYPE,
} from './adapters';

export interface ILoadActions extends ITabsActions, IGenericActions {
  confirmDelivery(params?);

  confirmDeliverySuccess(params?);

  confirmDeliveryError(params?);

  getTruckDistance(params?);

  getTruckDistanceSuccess(params?);

  getTruckDistanceError(params?);

  setStatus(params?);

  setLoading(params?);

  deleteLoad(params?);

  deleteLoadSuccess(params?);

  deleteLoadError(params?);

  acceptLoad(params?);

  acceptLoadSuccess(params?);

  acceptLoadError(params?);

  createTemplate(params?);

  createTemplateSuccess(params?);

  createTemplateError(params?);

  selectCarrier(params?);

  selectTemplate(params?);

  selectDriver(params?);

  selectTruck(params?);

  selectShipperId(params?);

  selectCarrierId(params?);

  sendOfferLoad(params?);

  sendOfferLoadSuccess(params?);

  sendOfferLoadError(params?);
}

export interface ILoadActionType extends ITabsActionTypes, IGenericActionsTypes {
  CONFIRM_DELIVERY: string;
  CONFIRM_DELIVERY_SUCCESS: string;
  CONFIRM_DELIVERY_ERROR: string;
  SET_LOADING: string;
  ACCEPT_LOAD: string;
  ACCEPT_LOAD_SUCCESS: string;
  ACCEPT_LOAD_ERROR: string;
  CREATE_TEMPLATE: string;
  CREATE_TEMPLATE_SUCCESS: string;
  CREATE_TEMPLATE_ERROR: string;
  SELECT_CARRIER: string;
  SELECT_TEMPLATE: string;
  SEND_OFFER_LOAD: string;
  SEND_OFFER_LOAD_SUCCESS: string;
  SEND_OFFER_LOAD_ERROR: string;
}

export const loadActionTypes = {
  CONFIRM_DELIVERY: 'confirm delivery',
  CONFIRM_DELIVERY_SUCCESS: 'confirm delivery success',
  CONFIRM_DELIVERY_ERROR: 'confirm delivery error',
  SET_LOADING: 'set loading',
  ACCEPT_LOAD: 'accept load',
  ACCEPT_LOAD_SUCCESS: 'accept load success',
  ACCEPT_LOAD_ERROR: 'accept load error',
  CREATE_TEMPLATE: 'create template',
  CREATE_TEMPLATE_SUCCESS: 'create template success',
  CREATE_TEMPLATE_ERROR: 'create template error',
  SELECT_CARRIER: 'select carrier',
  SELECT_TEMPLATE: 'select template',
  SEND_OFFER_LOAD: 'send offer load',
  SEND_OFFER_LOAD_SUCCESS: 'send offer load success',
  SEND_OFFER_LOAD_ERROR: 'send offer load error',
};

export const loadActions = {
  confirmDelivery: createAction(TYPE, loadActionTypes.CONFIRM_DELIVERY),

  confirmDeliverySuccess: createAction(TYPE, loadActionTypes.CONFIRM_DELIVERY_SUCCESS),

  confirmDeliveryError: createAction(TYPE, loadActionTypes.CONFIRM_DELIVERY_ERROR),

  setLoading: createAction(TYPE, loadActionTypes.SET_LOADING),

  acceptLoad: createAction(TYPE, loadActionTypes.ACCEPT_LOAD),

  acceptLoadSuccess: createAction(TYPE, loadActionTypes.ACCEPT_LOAD_SUCCESS),

  acceptLoadError: createAction(TYPE, loadActionTypes.ACCEPT_LOAD_ERROR),

  createTemplate: createAction(TYPE, loadActionTypes.CREATE_TEMPLATE),

  createTemplateSuccess: createAction(TYPE, loadActionTypes.CREATE_TEMPLATE_SUCCESS),

  createTemplateError: createAction(TYPE, loadActionTypes.CREATE_TEMPLATE_ERROR),

  selectCarrier: createAction(TYPE, loadActionTypes.SELECT_CARRIER),

  selectTemplate: createAction(TYPE, loadActionTypes.SELECT_TEMPLATE),

  sendOfferLoad: createAction(TYPE, loadActionTypes.SEND_OFFER_LOAD),

  sendOfferLoadSuccess: createAction(TYPE, loadActionTypes.SEND_OFFER_LOAD_SUCCESS),

  sendOfferLoadError: createAction(TYPE, loadActionTypes.SEND_OFFER_LOAD_ERROR),
};

export const LoadActions: ILoadActions = {
  ...combineAdaptersActionsState<ILoadActions>(loadAdapter),
  ...loadActions,
};

export const LoadActionTypes: ILoadActionType = {
  ...combineAdaptersActionTypeState<ILoadActionType>(loadAdapter),
  ...createActionTypes(loadActionTypes)(TYPE),
};

export interface ILoadActiveActions extends ITabsActions {
  accept(params?);

  acceptSuccess(params?);

  acceptError(params?);

  reject(params?);

  rejectSuccess(params?);

  rejectError(params?);

  assigned(params?);

  assignedSuccess(params?);

  assignedError(params?);

  dispatch(params?);

  dispatchSuccess(params?);

  dispatchError(params?);

  confirmPickUp(params?);

  confirmPickUpSuccess(params?);

  confirmPickUpError(params?);

  completeDelivery(params?);

  completeDeliverySuccess(params?);

  completeDeliveryError(params?);

  close(params?);

  closeSuccess(params?);

  closeError(params?);

  update(params?);

  updateSuccess(params?);

  updateError(params?);

  showNotesButton(params?);
}

export interface ILoadActiveActionType extends ITabsActionTypes {
  ACCEPT: string;
  ACCEPT_SUCCESS: string;
  ACCEPT_ERROR: string;

  REJECT: string;
  REJECT_SUCCESS: string;
  REJECT_ERROR: string;

  ASSIGNED: string;
  ASSIGNED_SUCCESS: string;
  ASSIGNED_ERROR: string;

  DISPATCH: string;
  DISPATCH_SUCCESS: string;
  DISPATCH_ERROR: string;

  CONFIRM_PICKUP: string;
  CONFIRM_PICKUP_SUCCESS: string;
  CONFIRM_PICKUP_ERROR: string;

  COMPLETE_DELIVERY: string;
  COMPLETE_DELIVERY_SUCCESS: string;
  COMPLETE_DELIVERY_ERROR: string;

  CLOSE: string;
  CLOSE_SUCCESS: string;
  CLOSE_ERROR: string;

  UPDATE: string;
  UPDATE_SUCCESS: string;
  UPDATE_ERROR: string;

  SHOW_NOTES_BUTTON: string;
}

const loadActiveActionTypes = {
  ACCEPT: 'accept',
  ACCEPT_SUCCESS: 'accept success',
  ACCEPT_ERROR: 'accept error',

  REJECT: 'reject',
  REJECT_SUCCESS: 'reject success',
  REJECT_ERROR: 'reject error',

  ASSIGNED: 'assigned',
  ASSIGNED_SUCCESS: 'assigned success',
  ASSIGNED_ERROR: 'assigned error',

  DISPATCH: 'dispatch',
  DISPATCH_SUCCESS: 'dispatch success',
  DISPATCH_ERROR: 'dispatch error',

  CONFIRM_PICKUP: 'confirm pickup',
  CONFIRM_PICKUP_SUCCESS: 'confirm pickup success',
  CONFIRM_PICKUP_ERROR: 'confirm pickup error',

  COMPLETE_DELIVERY: 'complete delivery',
  COMPLETE_DELIVERY_SUCCESS: 'complete delivery success',
  COMPLETE_DELIVERY_ERROR: 'complete delivery error',

  CLOSE: 'close',
  CLOSE_SUCCESS: 'close success',
  CLOSE_ERROR: 'close error',

  UPDATE: 'update',
  UPDATE_SUCCESS: 'update success',
  UPDATE_ERROR: 'update error',

  SHOW_NOTES_BUTTON: 'show notes button',
};

export const loadActiveActions = {
  assigned: createAction(LOAD_ACTIVE_TABS_TYPE, loadActiveActionTypes.ASSIGNED),
  assignedSuccess: createAction(LOAD_ACTIVE_TABS_TYPE, loadActiveActionTypes.ASSIGNED_SUCCESS),
  assignedError: createAction(LOAD_ACTIVE_TABS_TYPE, loadActiveActionTypes.ASSIGNED_ERROR),

  reject: createAction(LOAD_ACTIVE_TABS_TYPE, loadActiveActionTypes.REJECT),
  rejectSuccess: createAction(LOAD_ACTIVE_TABS_TYPE, loadActiveActionTypes.REJECT_SUCCESS),
  rejectError: createAction(LOAD_ACTIVE_TABS_TYPE, loadActiveActionTypes.REJECT_ERROR),

  dispatch: createAction(LOAD_ACTIVE_TABS_TYPE, loadActiveActionTypes.DISPATCH),
  dispatchSuccess: createAction(LOAD_ACTIVE_TABS_TYPE, loadActiveActionTypes.DISPATCH_SUCCESS),
  dispatchError: createAction(LOAD_ACTIVE_TABS_TYPE, loadActiveActionTypes.DISPATCH_ERROR),

  confirmPickUp: createAction(LOAD_ACTIVE_TABS_TYPE, loadActiveActionTypes.CONFIRM_PICKUP),
  confirmPickUpSuccess: createAction(LOAD_ACTIVE_TABS_TYPE, loadActiveActionTypes.CONFIRM_PICKUP_SUCCESS),
  confirmPickUpError: createAction(LOAD_ACTIVE_TABS_TYPE, loadActiveActionTypes.CONFIRM_PICKUP_ERROR),

  completeDelivery: createAction(LOAD_ACTIVE_TABS_TYPE, loadActiveActionTypes.COMPLETE_DELIVERY),
  completeDeliverySuccess: createAction(LOAD_ACTIVE_TABS_TYPE, loadActiveActionTypes.COMPLETE_DELIVERY_SUCCESS),
  completeDeliveryError: createAction(LOAD_ACTIVE_TABS_TYPE, loadActiveActionTypes.COMPLETE_DELIVERY_ERROR),

  close: createAction(LOAD_ACTIVE_TABS_TYPE, loadActiveActionTypes.CLOSE),
  closeSuccess: createAction(LOAD_ACTIVE_TABS_TYPE, loadActiveActionTypes.CLOSE_SUCCESS),
  closeError: createAction(LOAD_ACTIVE_TABS_TYPE, loadActiveActionTypes.CLOSE_ERROR),

  update: createAction(LOAD_ACTIVE_TABS_TYPE, loadActiveActionTypes.UPDATE),
  updateSuccess: createAction(LOAD_ACTIVE_TABS_TYPE, loadActiveActionTypes.UPDATE_SUCCESS),
  updateError: createAction(LOAD_ACTIVE_TABS_TYPE, loadActiveActionTypes.UPDATE_ERROR),

  showNotesButton: createAction(LOAD_ACTIVE_TABS_TYPE, loadActiveActionTypes.SHOW_NOTES_BUTTON),
};

export const LoadActiveActions: ILoadActiveActions = {
  ...combineAdaptersActionsState<ILoadActiveActions>(loadActiveTabsAdapter),
  ...loadActiveActions,
};
export const LoadActiveActionTypes: ILoadActiveActionType = {
  ...combineAdaptersActionTypeState<ILoadActiveActionType>(loadActiveTabsAdapter),
  ...createActionTypes(loadActiveActionTypes)(LOAD_ACTIVE_TABS_TYPE),
};

export const LoadAvailableActions = combineAdaptersActionsState<ITabsActions>(loadAvailableTabsAdapter);
export const LoadAvailableActionTypes = combineAdaptersActionTypeState<ITabsActionTypes>(loadAvailableTabsAdapter);

export const LoadOffersSearchActions = combineAdaptersActionsState<ISearchActions>(loadOffersSearchAdapter);
export const LoadOffersSearchActionTypes = combineAdaptersActionTypeState<ISearchTypes>(loadOffersSearchAdapter);
