<form [formGroup]="form" (submit)="onSubmit($event)" class="ct-notes__form">
  <div class="ct-notes__title">Add Note</div>
  <div class="ct-notes__info">
    <span class="broker">By Broker {{ user.usxId }}</span>
    <span class="time">{{ now | dateTime: user.prefs.timeZone:'MM/dd/yy h:mm' }}</span>
  </div>

  <div class="ct-notes__block">
    <mat-form-field class="app-form-field notes-field" appearance="fill" floatLabel="always">
      <span class="header">Note</span>
      <textarea class="body" #note matInput formControlName="note" type="text" maxlength="200"></textarea>
      <span class="counter">{{ form.controls['note'].value.length }}/200</span>
    </mat-form-field>
  </div>

  <div class="ct-notes__block ct-notes__block--button">
    <button
      class="ct-notes__block__button ct-notes__block__button__cancel"
      (click)="onCancel($event)"
      data-test="cancel-button"
    >
      Cancel
    </button>
    <button
      #submitButton
      type="submit"
      class="ct-notes__block__button ct-notes__block__button__submit"
      (click)="onSubmit($event)"
      data-test="notes"
    >
      Add Note
    </button>
  </div>
</form>
