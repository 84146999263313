import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Tab } from '@haulynx/types';
import { mapToArray } from '@haulynx/utils';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppState } from '../../main-store/app.reducers';
import { CarriersTabsActions } from './carriers.action';
import { carriersTabsSelector } from './carriers.selectors';

@Injectable({
  providedIn: 'root',
})
export class CarriersTabsModel {
  tabs$: Observable<Tab[]> = this.store.select(carriersTabsSelector.getTabs).pipe(map(mapToArray));
  tabsSelected$: Observable<Tab> = this.store.select(carriersTabsSelector.getSelected);

  constructor(private store: Store<AppState>, private router: Router) {}

  addTabs(tabs: Tab[] | Tab) {
    this.store.dispatch(CarriersTabsActions.addTabs(tabs));
  }

  updateTabs(tabs: Tab[] | Tab) {
    this.store.dispatch(CarriersTabsActions.updateTabs(tabs));
  }

  selectTab(tab: Tab | any) {
    this.store.dispatch(CarriersTabsActions.selectTab(tab));
  }

  removeTabs(data) {
    this.store.dispatch(CarriersTabsActions.removeTab(data));
  }

  goTo(url) {
    this.router.navigate([url]);
  }
}
