import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Environment,
  MapBoxFeatureResponse,
  MapBoxFeature,
  states,
  canadaStates,
  mexicoStates,
  StateDictionary,
  MapBoxGeoTypes,
} from '@haulynx/types';
import { Observable, of } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { aliveWhile } from '@haulynx/utils';
import { WindowRef } from '../../app-services/generic/window-ref';

const COUNTRIES = ['us', 'ca', 'mx'];

@Injectable({
  providedIn: 'root',
})
export class CarrierSearchService {
  private baseUrl: string;
  private accessToken: string;
  private _window: any;
  alive = aliveWhile();

  constructor(private environment: Environment, private http: HttpClient, private windowRef: WindowRef) {
    this.baseUrl = this.environment.mapbox.geocoding.geocodingApi;
    this.accessToken = this.environment.mapbox.geocoding.accessToken;
    this._window = this.windowRef.getNativeWindow();
  }

  getAutoCompleteOptions(searchText: string): Observable<MapBoxFeature[]> {
    if (searchText && searchText !== '') {
      return this.http
        .get<MapBoxFeatureResponse>(
          `${this.baseUrl}/${searchText}.json?country=${COUNTRIES.join(',')}&access_token=${this.accessToken}`
        )
        .pipe(
          takeUntil(this.alive),
          map((response: MapBoxFeatureResponse) => response?.features),
          catchError((error) => of(null))
        );
    } else {
      return of(null);
    }
  }

  getCurrentLocation(): Observable<MapBoxFeature> {
    return new Observable((subscriber) => {
      const geolocation = this._window?.navigator?.geolocation;
      if (geolocation) {
        geolocation.getCurrentPosition(
          (position) => {
            const { longitude, latitude } = position?.coords;
            this.http
              .get<MapBoxFeatureResponse>(
                `${this.baseUrl}/${longitude},${latitude}.json?access_token=${this.accessToken}&limit=1`
              )
              .pipe(
                takeUntil(this.alive),
                map((response: MapBoxFeatureResponse) => response?.features ?? null),
                catchError((error) => of(null))
              )
              .subscribe((locations: MapBoxFeature[]) => {
                if (!location || !locations?.length) return of(null);
                subscriber.next(locations[0]);
                subscriber.complete();
              });
          },
          (noPosition) => {
            subscriber.next(null);
            subscriber.complete();
          }
        );
      } else {
        subscriber.next(null);
        subscriber.complete();
      }
    });
  }

  onAddressChange(address: string): Observable<MapBoxFeature[]> {
    if (!address || address === 'Current Location') return;
    return this.getAutoCompleteOptions(address);
  }

  getSelectedStateCode(selectedPrediction: MapBoxFeature): string {
    const { id } = selectedPrediction ?? {};

    const [countryCode, stateName] =
      (id.includes('region') && selectedPrediction.properties.short_code.split('-')) || [];

    let stateDictionary: StateDictionary[];
    switch (countryCode) {
      case 'US':
        stateDictionary = states;
        break;
      case 'CA':
        stateDictionary = canadaStates;
        break;
      case 'MX':
        stateDictionary = mexicoStates;
        break;
    }

    const selectedState = stateDictionary?.find((item: StateDictionary) => item.code.includes(stateName));

    return selectedState?.code;
  }

  isSearchByState(selectedPrediction: MapBoxFeature): boolean {
    const { place_type } = selectedPrediction ?? {};
    const [regionType] = place_type;

    const hasState = regionType?.includes(MapBoxGeoTypes.STATE);

    return !!hasState;
  }

  ngOnDestroy() {
    this.alive.destroy();
  }
}
