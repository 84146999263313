<mat-toolbar
  class="mat-elevation-z6 fixed-header page-header"
  fxLayout="row"
  *ngIf="user && !reportMode && !sidenav.opened"
>
  <div class="logo">
    <a mat-button routerLink="/home">
      <mat-icon class="logo-icon" svgIcon="xt-header-logo"></mat-icon>
    </a>
  </div>
  <app-side-nav
    class="side-nav"
    fxHide.lt-lg
    [menuItems]="menuItems"
    [user]="user"
    [reportMode]="reportMode"
    (startTour)="onStartTour()"
    (logs)="onGoToLogs()"
    [url]="url"
    data-test="app-side-nav"
  ></app-side-nav>

  <span class="fill-space"></span>
  <haulynx-rmis-account-setup-status fxHide.lt-lg></haulynx-rmis-account-setup-status>
  <div class="user-info-container">
    <ng-container *ngIf="user && user.broker">
      <ng-container *appFeatures="dedicatedFeatureFlag">
        <a
          mat-raised-button
          class="toolbar__button toolbar__button--dedicated"
          href="https://usxpress.sharepoint.com/:x:/r/sites/HaulynxDedicatedOpportunities/_layouts/15/Doc.aspx?sourcedoc={17C6F0A7-A0CC-442B-87CD-94E436356288}&file=DedicatedOps.xlsx&action=default&mobileredirect=true"
          target="_blank"
          data-test="button--dedicated"
        >
          Broker Awards & Opportunities
        </a>
      </ng-container>

      <a
        mat-raised-button
        class="toolbar__button toolbar__button--refer-carrier"
        routerLink="/dashboard/account/referral"
        data-test="button--refer-carrier"
      >
        Refer Carrier
      </a>
    </ng-container>

    <button
      mat-icon-button
      (click)="onShowVerifyEmail($event)"
      *ngIf="user && user.isHaulynxAdmin"
      data-test="verify-email-button"
    >
      <mat-icon>email</mat-icon>
    </button>
    <button mat-icon-button (click)="onShowNotifications($event)" *ngIf="user" data-test="notifications-button">
      <mat-icon svgIcon="notifications-icon"></mat-icon>
      <ng-container *ngIf="totalNotifications">
        <span class="app-notification__badge" *ngIf="totalNotifications > 0" data-test="num-notifications">{{
          totalNotifications > 99 ? '99+' : totalNotifications
        }}</span>
      </ng-container>
    </button>
    <a mat-icon-button (click)="onGoToHelp()" data-test="help-button">
      <mat-icon>help_outline</mat-icon>
    </a>
    <button mat-icon-button fxHide.gt-md class="menu-button" (click)="sidenav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <button fxHide.lt-lg mat-button [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger" data-test="menu-trigger">
      <span class="user-info-content">
        {{ user.name }}
        <br />
        <span *ngIf="!user?.isHaulynxAdmin" class="user-info-text">
          {{
            user.carrier ? user.carrier.name : user.shipper ? user.shipper.name : user.broker ? user.broker.name : ''
          }}
        </span>
        <span *ngIf="user?.isHaulynxAdmin" class="user-info-text"> Haulynx Admin </span>
      </span>
      <mat-icon class="menu-arrow">
        {{ menuTrigger.menuOpen ? 'expand_less' : 'expand_more' }}
      </mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button class="mat-menu-button" mat-menu-item (click)="onGoToAccount()" data-test="account-button">
        <mat-icon>settings</mat-icon>
        <span>{{ user?.carrier ? 'Your Account' : 'Settings' }}</span>
      </button>
      <button class="mat-menu-button" mat-menu-item (click)="onLogout()" data-test="logout-button">
        <mat-icon>exit_to_app</mat-icon>
        <span>Sign Out</span>
      </button>
    </mat-menu>
  </div>
</mat-toolbar>

<mat-toolbar
  class="mat-elevation-z6 fixed-header page-header"
  *ngIf="!user && !reportMode"
  fxLayout="row"
  fxLayoutAlign="space-between center"
>
  <img src="assets/images/Haulynx-Powered-by-XT-White.png" />
  <div fxLayoutGap="8px">
    <button mat-raised-button class="sign-up" (click)="login()">LOGIN</button>
    <button mat-raised-button class="sign-up" (click)="signUp()">SIGN UP</button>
  </div>
</mat-toolbar>

<mat-sidenav-container class="sidenav-container" [hasBackdrop]="true">
  <mat-sidenav
    class="sidenav"
    #sidenav
    fixedInViewport="true"
    [attr.role]="'dialog'"
    [mode]="'over'"
    [opened]="sidenav.opened"
  >
    <div class="logo sidenav-logo">
      <a
        mat-button
        [tabIndex]="-1"
        [routerLink]="'/loads/search'"
        [queryParams]="{ bookStatus: ['bookable', 'viewable'] }"
      >
        <mat-icon class="logo-icon" svgIcon="xt-header-logo"></mat-icon>
      </a>
    </div>
    <app-side-nav
      class="side-nav"
      [menuItems]="menuItems"
      [user]="user"
      [reportMode]="reportMode"
      (startTour)="onStartTour()"
      (logs)="onGoToLogs()"
      [url]="url"
      [sidenav]="sidenav"
      data-test="app-side-nav"
    ></app-side-nav>
    <mat-divider class="divider-top"></mat-divider>
    <div class="user-info-content-mobile" *ngIf="user">
      {{ user.name }}
      <br />
      <span *ngIf="!user?.isHaulynxAdmin" class="user-info-text-mobile">
        {{ user.carrier ? user.carrier.name : user.shipper ? user.shipper.name : user.broker ? user.broker.name : '' }}
      </span>
      <span *ngIf="user?.isHaulynxAdmin" class="user-info-text-mobile"> Haulynx Admin </span>
    </div>
    <mat-divider class="divider-bottom"></mat-divider>
    <button mat-menu-item (click)="onGoToAccount()" data-test="account-button">
      <span>{{ user?.carrier ? 'Your Account' : 'Settings' }}</span>
    </button>
    <button mat-menu-item (click)="onLogout()" data-test="logout-button">
      <span>Sign Out</span>
    </button>
  </mat-sidenav>
</mat-sidenav-container>
<button mat-icon-button class="close-button" *ngIf="sidenav.opened" (click)="sidenav.toggle()">
  <mat-icon>close</mat-icon>
</button>
