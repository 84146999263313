import { Injectable } from '@angular/core';
import { ContractsService } from '@haulynx/services';
import { AsyncSearchManager } from '@haulynx/types';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../../main-store/app.reducers';
import { AsyncEntityBase } from '../../main-store/async-entity/async-entity-base.class';
import { contractsEntityNamespace } from './namespace';

@Injectable({ providedIn: 'root' })
export class ContractsEntityService extends AsyncEntityBase<any> {
  contractsSearchManager: AsyncSearchManager<any, any>;

  constructor(
    protected actions$: Actions,
    protected store: Store<AppState>,
    private contractsService: ContractsService
  ) {
    super(actions$, store, contractsEntityNamespace);

    this.contractsSearchManager = this.createAsyncSearchQuery('get contracts', (searchPayload) => {
      return this.contractsService.searchFreightContracts(searchPayload.query, searchPayload.pageAndSort);
    });
  }
}
