import gql from 'graphql-tag';

export const GetCarrierById = gql(
  `
  query GetCarrierById($id: ID!) {
    getCarrierById(id: $id) {
      id
      name
      dot
      temperature
      insurance
      weight
      owner
      mcNumber
      equipmentType
      isHaulynxUser
      isRmisSetup
      isUSXCertified
      isEldCarrier
      eldEnabledDate
      isLoadDistributionEnabled
      loadFeedEnabled
      loadFeedEnabledDate
      hazmatCapable
      rank
      maximumDeadMiles
      minimumLoadValue
      phone
      email
      timeZone
      addressStreet
      addressStreet2
      addressCity
      addressState
      addressCountry
      mcNumber
      saferWatchData {
        mcNumber
      }
      rmisRegistration {
        isRmisSetup
        isUsxCertified
        fleetRegistered
        w9Orw8Registered
        comcheckAdvancesRegistered
        paymentOptionRegistered
        supplierDiversityRegistered
      }
    }
  }
`
);
