import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoadDetailsModel, LoadEntityService, LoadPriceModel } from '@haulynx/store';
import { HttpStatus, LoadsServiceLoad } from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { LoadPriceFormComponent } from './components/load-price-form/load-price-form.component';

@Component({
  selector: 'app-load-price',
  templateUrl: './load-price.component.html',
  styleUrls: ['./load-price.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadPriceComponent implements OnDestroy, OnInit {
  @Input() loadsServiceLoad = false;
  @Input() price: number | string = null;
  @Input() load?: LoadsServiceLoad;
  @Input() isMaxBuy? = false;
  @Input() editablePrice = false;
  @Input() loadId: string = null;
  @Input() showCents = false;
  @Input() noValueText = 'Unknown';
  @Input() maxBuyLoading: boolean;
  @Input() currentMaxBuy?: number;
  @Input() currentPrice = 0;
  @Output() priceChanged = new EventEmitter<{ loadId: string; maxBuy?: number; price: number }>();

  newPrice = null;
  newMaxBuy = null;

  isLoading$ = new BehaviorSubject(false);
  alive = aliveWhile();

  constructor(
    private dialog: MatDialog,
    public loadPriceModel: LoadPriceModel,
    public loadEntityService: LoadEntityService,
    public loadDetailsModel: LoadDetailsModel
  ) {}

  ngOnInit(): void {
    this.loadPriceModel.isLoading$
      .pipe(
        takeUntil(this.alive),
        map((loading) => !!loading.get(this.loadId)),
        distinctUntilChanged()
      )
      .subscribe((isLoading) => {
        if (this.newPrice || this.newPrice === 0) {
          this.isLoading$.next(isLoading);
        }
        if (this.newMaxBuy || this.newMaxBuy === 0) {
          this.isLoading$.next(isLoading);
        }
      });
    this.loadPriceModel.httpStatus$
      .pipe(
        takeUntil(this.alive),
        map((httpStatus) => httpStatus.get(this.loadId)),
        distinctUntilChanged()
      )
      .subscribe((httpStatus) => {
        if (httpStatus === HttpStatus.SUCCESS) {
          if (this.newMaxBuy && this.newPrice) {
            this.price = this.newPrice;
            this.loadEntityService.getLoadByIdManager.dispatch(this.loadId);
          }
          if (this.newPrice) {
            this.price = this.newPrice;
            this.newPrice = null;
            this.loadEntityService.getLoadByIdManager.dispatch(this.loadId);
            this.loadDetailsModel.get({ key: this.loadId, id: this.loadId, source: 'loads', isLatest: true });
          }
          if (this.newMaxBuy) {
            this.loadEntityService.getLoadByIdManager.dispatch(this.loadId);
            this.price = this.newMaxBuy;
            this.newMaxBuy = null;
          }
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { maxBuyLoading } = changes;
    if (maxBuyLoading) {
      this.isLoading$.next(maxBuyLoading.currentValue);
    }
  }

  setPrice(event: Event) {
    event.stopPropagation();

    if (this.editablePrice) {
      const dialogRef = this.dialog.open(LoadPriceFormComponent, {
        data: {
          isMaxBuy: this.isMaxBuy,
          price: this.price,
        },
      });

      dialogRef
        .afterClosed()
        .pipe(
          withLatestFrom(
            this.loadDetailsModel.form.state$,
            this.loadEntityService.getLoadByIdManager.getEntityById(this.loadId)
          ),
          first()
        )
        .subscribe(([data, load, loadsServiceLoad]: [{ price }, LoadsServiceLoad, LoadsServiceLoad]) => {
          if (data) {
            let payload;

            if (this.isMaxBuy) {
              this.newMaxBuy = data.price;
              const price = this.loadsServiceLoad
                ? loadsServiceLoad?.paymentDetails?.price
                : load.paymentDetails?.price;
              payload = { loadId: this.loadId, maxBuy: data.price, price: price || this.currentPrice, type: 'maxBuy' };
              this.isLoading$.next(true);
            } else {
              this.newPrice = data.price;
              if (this.currentMaxBuy || loadsServiceLoad?.paymentDetails?.maxBuy || load?.paymentDetails?.maxBuy) {
                payload = {
                  loadId: this.loadId,
                  price: data.price,
                  maxBuy:
                    this.currentMaxBuy || loadsServiceLoad?.paymentDetails?.maxBuy || load?.paymentDetails?.maxBuy,
                };
              } else {
                this.newMaxBuy = data.price;
                payload = { loadId: this.loadId, price: data.price, maxBuy: data.price };
              }
            }
            this.loadPriceModel.setPrice(payload);
            this.priceChanged.emit(payload);
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }
}
