import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@haulynx/modules';
import { DialogTemplateComponent } from './dialog-template.component';

@NgModule({
  declarations: [DialogTemplateComponent],
  imports: [CommonModule, MaterialModule, FlexLayoutModule],
  exports: [DialogTemplateComponent],
})
export class DialogTemplateModule {}
