import { ActionReducer, ActionReducerMap } from '@ngrx/store';
import { InjectionToken, Provider } from '@angular/core';
import { LaneHistoryEntityService } from './lane-history-entity.service';
import { USER_PROVIDED_EFFECTS } from '@ngrx/effects';
import { IAsyncEntityState } from '@haulynx/types';

export const LANE_HISTORY_ENTITY_REDUCERS_TOKEN = new InjectionToken<ActionReducerMap<IAsyncEntityState<unknown>>>(
  'lane history reducers'
);

function getLaneHistoryEntityReducersFactory(
  laneHistoryEntityService: LaneHistoryEntityService
): ActionReducer<IAsyncEntityState<unknown>> {
  return laneHistoryEntityService.getReducers();
}

const laneHistoryEntityReducerProvider: Provider = {
  provide: LANE_HISTORY_ENTITY_REDUCERS_TOKEN,
  useFactory: getLaneHistoryEntityReducersFactory,
  deps: [LaneHistoryEntityService],
};

const laneHistoryEntityEffectProvider: Provider = {
  provide: USER_PROVIDED_EFFECTS,
  multi: true,
  useValue: [LaneHistoryEntityService],
};

export const laneHistoryStateProviders: Provider[] = [
  laneHistoryEntityReducerProvider,
  laneHistoryEntityEffectProvider,
];
