import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartComponent } from './chart.component';
import { ChartsModule } from 'ng2-charts';
import { MaterialModule } from '@haulynx/modules';
import { SpinnerModule } from '../spinner/spinner.module';

@NgModule({
  declarations: [ChartComponent],
  imports: [CommonModule, ChartsModule, MaterialModule, SpinnerModule],
  exports: [ChartComponent],
})
export class ChartModule {}
