import gql from 'graphql-tag';

export const DeactivateNotifications = gql(`
  mutation DeactivateNotifications($creatorId: StrictId!, $notificationIds: [StrictId]!) {
    deactivateNotifications(creatorId: $creatorId, notificationIds: $notificationIds) {
      success
      index
    }
  }

`);
