import { LoadServiceSearchParameters } from '@haulynx/types';
import { addDays, endOfDay, startOfDay } from 'date-fns';
import { isNumber } from 'lodash';
import { getDaysDifference } from '../utils/utils';

/**
 * List of time delta fields.
 * See https://codyarose.com/blog/object-keys-from-array-in-typescript/ for an explanation on `as const`
 */
const timeDeltaFields = [
  'firstAppointmentStartDelta',
  'firstAppointmentEndDelta',
  'lastAppointmentStartDelta',
  'lastAppointmentEndDelta',
] as const;
const timeFields = ['firstAppointmentStart', 'firstAppointmentEnd', 'lastAppointmentStart', 'lastAppointmentEnd'];

type LoadServiceSearchParametersWithDeltas = LoadServiceSearchParameters &
  { [K in typeof timeDeltaFields[number]]?: number };

export const convertLoadTimeDeltas = (
  searchData: LoadServiceSearchParametersWithDeltas
): LoadServiceSearchParameters => {
  const {
    firstAppointmentStartDelta,
    firstAppointmentEndDelta,
    lastAppointmentStartDelta,
    lastAppointmentEndDelta,
    ...searchParams
  } = searchData;

  timeDeltaFields.forEach((fieldName, index) => {
    if (isNumber(searchData[fieldName])) {
      const date = fieldName.includes('Start') ? startOfDay(new Date()) : endOfDay(new Date());
      searchParams[timeFields[index]] = addDays(date, searchData[fieldName]).valueOf();
    }
  });

  return searchParams;
};

export const convertDatesToDeltas = (data: LoadServiceSearchParameters): LoadServiceSearchParameters => {
  const result = { ...data };

  timeFields.forEach((field) => {
    if (isNumber(result[field])) {
      result[field] = getDaysDifference(result[field]);
    }
  });

  return result;
};
