export enum MediaTypes {
  IMAGE = 'image',
  VIDEO = 'video',
}

export enum ReleaseTypes {
  BROKER = 'broker',
  CARRIER = 'carrier',
}

export interface WhatsNewPage {
  id?: string;
  title: string;
  description: string;
  media: {
    type: MediaTypes;
    data: string;
  };
}

export interface ReleaseNote {
  id: string;
  title: string;
  pages: WhatsNewPage[];
  userType: ReleaseTypes;
  date: number;
  expiration: number;
  hasViewed: boolean;
}

export interface ReleaseNotesInput {
  userId: string;
  userType: ReleaseTypes;
  before: number;
  includeExpired: boolean;
}
