import { Injectable } from '@angular/core';
import {
  CreateMilestone,
  CreateMilestoneComment,
  CreateMilestoneLog,
  DeleteMilestoneComment,
  DeleteMilestoneLog,
  UpdateMilestone,
  UpdateMilestoneComment,
  UpdateMilestoneLog,
} from '@haulynx/gql';
import {
  ANALYTICS_EVENT,
  LoadsServiceLoad,
  Milestone,
  MilestoneComment,
  MilestoneLog,
  MilestoneUser,
  TrackingType,
} from '@haulynx/types';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AnalyticsService } from '../../app-services/analytics/analytics.service';
import { GraphqlService } from '../graphql.service';

@Injectable({
  providedIn: 'root',
})
export class MilestoneService {
  tempMilestones: Milestone[] = [];

  constructor(private graphqlService: GraphqlService, private analyticsService: AnalyticsService) {}

  updateMilestone(event: { milestone: Milestone; loadId: string; loadTmw: string }): Observable<any> {
    const { id, ...milestoneInput } = event.milestone;
    this.analyticsService.logEvent(ANALYTICS_EVENT.BROKER_MILESTONE_EDITED, {
      loadId: event.loadId,
      loadTMWNumber: event.loadTmw,
      milestoneType: event.milestone.type,
      update: event.milestone.logs.find((l) => l.editedByType === MilestoneUser.BROKER)?.subType,
      broker: event.milestone.logs.find((l) => l.editedByType === MilestoneUser.BROKER)?.editedBy,
    });
    return this.graphqlService
      .mutate<{ updateMilestone: LoadsServiceLoad }>({
        mutation: UpdateMilestone,
        variables: {
          loadId: event.loadId,
          milestoneInput: milestoneInput,
          milestoneId: id,
        },
      })
      .pipe(
        map((value) => {
          return value.data.updateMilestone.milestones;
        })
      );
  }

  createMilestone(event: {
    milestone: Milestone;
    loadId: string;
    loadTmw: string;
    trackingType: TrackingType;
  }): Observable<LoadsServiceLoad> {
    this.analyticsService.logEvent(ANALYTICS_EVENT.BROKER_MILESTONE_CREATED, {
      loadId: event.loadId,
      loadTMWNumber: event.loadTmw,
      milestoneType: event.milestone.type,
      update: event.milestone.logs.find((l) => l.editedByType === MilestoneUser.BROKER)?.subType,
      broker: event.milestone.logs.find((l) => l.editedByType === MilestoneUser.BROKER)?.editedBy,
      trackingType: event.trackingType,
    });
    return this.graphqlService
      .mutate<{ addMilestone: LoadsServiceLoad }>({
        mutation: CreateMilestone,
        variables: {
          loadId: event.loadId,
          milestoneInput: event.milestone,
        },
      })
      .pipe(
        map((result) => {
          return result.data.addMilestone;
        })
      );
  }

  createMilestoneLog(event: { logInput: MilestoneLog; milestoneId: string; loadId: string }): Observable<any> {
    return this.graphqlService
      .mutate<{ addMilestoneLog: LoadsServiceLoad }>({
        mutation: CreateMilestoneLog,
        variables: {
          loadId: event.loadId,
          milestoneId: event.milestoneId,
          logInput: event.logInput,
        },
      })
      .pipe(
        map((value) => {
          return value.data.addMilestoneLog.milestones;
        })
      );
  }

  updateMilestoneLog(event: { logInput: MilestoneLog; milestoneId: string; loadId: string }): Observable<any> {
    return this.graphqlService
      .mutate<{ updateMilestoneLog: LoadsServiceLoad }>({
        mutation: UpdateMilestoneLog,
        variables: {
          loadId: event.loadId,
          milestoneId: event.milestoneId,
          logId: event.logInput.id,
          logInput: event.logInput,
        },
      })
      .pipe(
        map((value) => {
          return value.data.updateMilestoneLog.milestones;
        })
      );
  }

  deleteMilestoneLog(event: { logInput: MilestoneLog; milestoneId: string; loadId: string }): Observable<any> {
    return this.graphqlService
      .mutate<{ removeMilestoneLog: LoadsServiceLoad }>({
        mutation: DeleteMilestoneLog,
        variables: {
          loadId: event.loadId,
          milestoneId: event.milestoneId,
          logId: event.logInput.id,
        },
      })
      .pipe(
        map((value) => {
          return value.data.removeMilestoneLog.milestones;
        })
      );
  }

  updateComment(event: { commentInput: MilestoneComment; milestoneId: string; loadId: string }): Observable<any> {
    const { id, ...comment } = event.commentInput;
    return this.graphqlService
      .mutate<{ updateMilestoneComment: LoadsServiceLoad }>({
        mutation: UpdateMilestoneComment,
        variables: {
          loadId: event.loadId,
          milestoneId: event.milestoneId,
          commentId: id,
          commentInput: comment,
        },
      })
      .pipe(
        map((value) => {
          return value.data.updateMilestoneComment.milestones;
        })
      );
  }

  createComment(event: {
    commentInput: MilestoneComment;
    milestoneId: string;
    loadId: string;
    loadTmw: string;
  }): Observable<any> {
    this.analyticsService.logEvent(ANALYTICS_EVENT.BROKER_MILESTONE_COMMENT_CREATED, {
      loadId: event.loadId,
      milestoneId: event.milestoneId,
      contact: event.commentInput.contact,
      broker: event.commentInput.creatorId,
      tmwNumber: event.loadTmw,
    });
    return this.graphqlService
      .mutate<{ addMilestoneComment: LoadsServiceLoad }>({
        mutation: CreateMilestoneComment,
        variables: {
          loadId: event.loadId,
          milestoneId: event.milestoneId,
          commentInput: event.commentInput,
        },
      })
      .pipe(
        map((value) => {
          return value.data.addMilestoneComment.milestones;
        })
      );
  }

  deleteComment(event: { commentInput: MilestoneComment; milestoneId: string; loadId: string }): Observable<any> {
    return this.graphqlService
      .mutate<{ removeMilestoneComment: LoadsServiceLoad }>({
        mutation: DeleteMilestoneComment,
        variables: {
          loadId: event.loadId,
          milestoneId: event.milestoneId,
          commentId: event.commentInput.id,
        },
      })
      .pipe(
        map((value) => {
          return value.data.removeMilestoneComment.milestones;
        })
      );
  }
}
