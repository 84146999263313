import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@haulynx/modules';
import { CarrierToolTipComponent } from './carrier-tool-tip.component';

@NgModule({
  declarations: [CarrierToolTipComponent],
  imports: [CommonModule, MaterialModule],
  exports: [CarrierToolTipComponent],
})
export class CarrierToollTipModule {}
