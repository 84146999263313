import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderToolbarComponent } from './header-toolbar.component';
import { MaterialModule } from '@haulynx/modules';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HeaderEndbuttonsModule } from './header-endbuttons/header-endbuttons.module';
import { HeaderToolbarTabsModule } from './header-toolbar-tabs/header-toolbar-tabs.module';

@NgModule({
  declarations: [HeaderToolbarComponent],
  imports: [CommonModule, FlexLayoutModule, MaterialModule, HeaderToolbarTabsModule, HeaderEndbuttonsModule],
  exports: [HeaderToolbarComponent],
})
export class HeaderToolbarModule {}
