import { PaymentType } from './payment-type';
import { PAYMENT_ITEM_MULTI_QUANTITY, PAYMENT_ITEM_SINGLE_QUANTITY } from '../../domain-logic';

export const paymentTypes: PaymentType[] = [
  {
    code: PAYMENT_ITEM_SINGLE_QUANTITY.LHFLT,
    description: 'Linehaul - Flat',
    method: 'FLT',
    isNegative: false,
    isReadOnly: true,
  },
  {
    code: PAYMENT_ITEM_SINGLE_QUANTITY.TRAIL,
    description: 'Trailer Usage Fee (1 day)',
    method: 'FLT',
    isNegative: true,
    isReadOnly: true,
  },
  {
    code: PAYMENT_ITEM_SINGLE_QUANTITY.FUELFT,
    description: 'Fuel Surcharge - Flat',
    method: 'FLT',
    isNegative: false,
    isReadOnly: true,
  },
  {
    code: PAYMENT_ITEM_SINGLE_QUANTITY.LUMBRK,
    description: 'Lumper - Brokerage',
    method: 'FLT',
    isNegative: false,
    isReadOnly: true,
  },
  {
    code: PAYMENT_ITEM_MULTI_QUANTITY.DTNTN,
    description: ' Detention',
    method: 'FLT',
    isNegative: false,
    isReadOnly: true,
  },
  // {
  //   code: PAYMENT_ITEM_SINGLE_QUANTITY.DTNTN,
  //   description: ' Detention',
  //   method: 'FLT',
  //   isNegative: false,
  //   isReadOnly: true,
  // },
  {
    code: PAYMENT_ITEM_SINGLE_QUANTITY.TONU,
    description: 'Truck Order Not Used',
    method: 'FLT',
    isNegative: false,
    isReadOnly: true,
  },
  {
    code: PAYMENT_ITEM_SINGLE_QUANTITY.SRVBN,
    description: 'Service Bonus',
    method: 'FLT',
    isNegative: false,
    isReadOnly: true,
  },
  {
    code: PAYMENT_ITEM_MULTI_QUANTITY.SPAY,
    description: 'Stop Pay',
    method: 'STOP',
    isNegative: false,
    isReadOnly: false,
  },
  {
    code: PAYMENT_ITEM_MULTI_QUANTITY.LHMLS,
    description: 'Linehaul - Miles',
    method: 'MLS',
    isNegative: false,
    isReadOnly: true,
  },
  {
    code: PAYMENT_ITEM_MULTI_QUANTITY.TRAIL,
    description: 'Trailer Payment Neg.',
    method: 'FLT',
    isNegative: true,
    isReadOnly: true,
  },
  {
    code: PAYMENT_ITEM_MULTI_QUANTITY.LYOVER,
    description: 'Layover Charge',
    method: 'STOP',
    isNegative: false,
    isReadOnly: false,
  },
  // {
  //   code: PAYMENT_ITEM_SINGLE_QUANTITY.ADVLWF,
  //   description: 'Lumper Adv With Fee',
  //   method: 'FLT',
  //   isNegative: true,
  //   isReadOnly: true,
  // },
  {
    code: PAYMENT_ITEM_SINGLE_QUANTITY.ADVLNF,
    description: 'Lumper Adv No Fee',
    method: 'FLT',
    isNegative: false,
    isReadOnly: true,
  },
  {
    code: PAYMENT_ITEM_SINGLE_QUANTITY.BOBTAL,
    description: 'Bobtail - Logistics',
    method: 'FLT',
    isNegative: false,
    isReadOnly: true,
  },
  {
    code: PAYMENT_ITEM_SINGLE_QUANTITY.BRDCRS,
    description: 'Border Crossing',
    method: 'FLT',
    isNegative: false,
    isReadOnly: true,
  },
  {
    code: PAYMENT_ITEM_SINGLE_QUANTITY.DHMLFT,
    description: 'Deadhead Mileage Flat',
    method: 'FLT',
    isNegative: false,
    isReadOnly: true,
  },
  {
    code: PAYMENT_ITEM_SINGLE_QUANTITY.ESCRT,
    description: 'Escort Service - Flat',
    method: 'FLT',
    isNegative: false,
    isReadOnly: true,
  },
  {
    code: PAYMENT_ITEM_MULTI_QUANTITY.FUELMI,
    description: 'Fuel Surcharge - Miles',
    method: 'MLS',
    isNegative: false,
    isReadOnly: true,
  },
  // {
  //   code: PAYMENT_ITEM_SINGLE_QUANTITY.FUADV,
  //   description: 'Fuel Advance',
  //   method: 'FLT',
  //   isNegative: true,
  //   isReadOnly: true,
  // },
  {
    code: PAYMENT_ITEM_SINGLE_QUANTITY.LDUNLD,
    description: 'Load / Unload',
    method: 'FLT',
    isNegative: false,
    isReadOnly: true,
  },
  // {
  //   code: PAYMENT_ITEM_SINGLE_QUANTITY.LHDED,
  //   description: 'Linehaul-Deduction',
  //   method: 'FLT',
  //   isNegative: true,
  //   isReadOnly: true,
  // },
  {
    code: PAYMENT_ITEM_SINGLE_QUANTITY.PRMTS,
    description: 'Permits Charge',
    method: 'FLT',
    isNegative: false,
    isReadOnly: true,
  },
  {
    code: PAYMENT_ITEM_SINGLE_QUANTITY.STRFLT,
    description: 'Storage Charge -Flat',
    method: 'FLT',
    isNegative: false,
    isReadOnly: true,
  },
  {
    code: PAYMENT_ITEM_SINGLE_QUANTITY.TLSPOT,
    description: 'Trailer Spotting Charge',
    method: 'FLT',
    isNegative: false,
    isReadOnly: true,
  },
  // {
  //   code: enums.PAYMENT_ITEM_SINGLE_QUANTITY.MINRED,
  //   description: 'Minimum Reduction Adjustment',
  //   method: 'NEGATIVE',
  //   isNegative: true,
  //   isReadOnly: true,
  // },
  // {
  // {
  //   code: enums.PAYMENT_ITEM_SINGLE_QUANTITY.RCNSGN,
  //   description: 'Reconsignment / Diversion Fee',
  //   method: 'NEGATIVE',
  //   isNegative: true,
  //   isReadOnly: true,
  // },
];
