export class Environment {
  readonly production: boolean;
  readonly apiUrl: string;
  readonly envName: EnvironmentName;
  readonly websocketUrl: string;
  /**
   * When a user messages another user a link to the app, what is the hostname that is sent to the recipient?
   */
  readonly invitationHostName?: string;
  readonly mapbox: {
    accessToken: string;
    directionsApi: string;
    drivingDirectionsApi: string;
    timezoneApi: string;
    geocoding?: {
      accessToken: string;
      geocodingApi: string;
    };
  };
  readonly firebase: {
    readonly apiKey: string;
    readonly authDomain: string;
    readonly databaseURL: string;
    readonly projectId: string;
    readonly storageBucket: string;
    readonly messagingSenderId?: string;
    readonly appId?: string;
    readonly measurementId?: string;
  };
  readonly apollo: {
    readonly url: string;
  };
  readonly amplitude: {
    readonly apiKey: string;
  };
  readonly usx: {
    readonly creatorId: string;
  };
  readonly google: {
    readonly mapsApi: string;
  };
  readonly elastic: {
    readonly apmServerUrl: string;
  };
  readonly documents: {
    readonly documentServerUrl: string;
  };
}

export type EnvironmentName = 'development' | 'alpha' | 'pilot' | 'staging' | 'production';
