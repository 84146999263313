<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
  <p>{{ data.message }}</p>
</div>
<div mat-dialog-actions align="center">
  <button
    mat-button
    class="cancel"
    [mat-dialog-close]="false"
    *ngIf="data.deny && !data.deny.hide"
    data-test="no-button"
  >
    {{ data.deny?.text || 'No' }}
  </button>
  <button mat-button class="confirm" [mat-dialog-close]="true" cdkFocusInitial data-test="yes-button">
    {{ data.confirm?.text || 'Yes' }}
  </button>
</div>
