import { AssignLoadCarrierForm, AssignLoadInput } from '../load';
import { PaymentItemCreateInput } from '../payment';
import { DispatchLocationInput } from '../location';
import { BookingType } from '../booking';

export interface MissionValidationInput {
  missionBatchInput: MissionCreateInput[];
}

export interface MissionCreateInput {
  orderNumbers: string[];
}

export interface MissionBookInput {
  loadId: string;
  missionId: string;
  dot: string;
  brokerId: string;
  bookingType: BookingType;
  formData: Partial<AssignLoadCarrierForm>;
}

export interface LoadDetailInput {
  loadId: string;
  editLoad: any;
  paymentItems: [PaymentItemCreateInput];
  dispatchLocation: DispatchLocationInput;
  assignInput: AssignLoadInput;
}

export interface MissionValidation {
  valid: boolean;
  missions: {
    orders: OrderValidation[];
  };
}

export interface BookLoadsInMissionResponse {
  missionId: string;
  bookedLoadResponses: BookedLoadResponse[];
}

export interface BookedLoadResponse {
  loadId: string;
  success: boolean;
  message: string;
}

export interface OrderValidation {
  orderNumber: string;
  valid: boolean;
  reason: string;
}

export interface MissionForm {
  headHaul: string;
  headHaulValidation: OrderValidation;
  origin: string;
  backHaul: string;
  backHaulValidation: OrderValidation;
  destination: string;
}

export enum MissionValidationReasons {
  INVALID = 'invalid',
  COMPLETE = 'complete',
  PART = 'part',
  DUPLICATE = 'duplicate',
}
