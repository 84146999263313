import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CarrierService, LoadsServiceService, UserService } from '@haulynx/services';
import { Carrier, IColumns, SaferWatchData } from '@haulynx/types';
import { filter, map } from 'lodash';
import { EditCarrierComponent } from '../dialogs/edit-carrier/edit-carrier.component';

@Component({
  selector: 'app-carrier-management',
  templateUrl: './carrier-management.component.html',
  styleUrls: ['./carrier-management.component.scss'],
})
export class CarrierManagementComponent implements OnInit {
  public carriers: Carrier[];
  public isLoading = false;
  public config = {};

  carriersColumns: IColumns[] = [
    {
      label: 'Carrier Name',
      dataIndex: 'carrierName',
      isCustomCell: true,
    },
    {
      label: 'DOT',
      dataIndex: 'dot',
      isCustomCell: true,
    },
    {
      label: 'Equipments',
      dataIndex: 'equipmentType',
      isCustomCell: true,
    },
    {
      label: 'SaferWatch Data',
      dataIndex: 'saferData',
      isCustomCell: true,
    },
    {
      label: 'Phone',
      dataIndex: 'phone',
    },
    {
      label: 'Email',
      dataIndex: 'email',
    },
    {
      label: 'Demo Carrier',
      dataIndex: 'demoCarriers',
      isCustomCell: true,
    },
    {
      label: 'Haulynx User',
      dataIndex: 'isHaulynxUser',
      isCustomCell: true,
    },
    {
      label: 'Options',
      dataIndex: 'options',
      isCustomCell: true,
    },
  ];

  constructor(
    private userService: UserService,
    private carrierService: CarrierService,
    private dialog: MatDialog,
    private loadsServiceService: LoadsServiceService,
    public snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.userService.isHaulynxAdmin().subscribe((bool) => {
      if (bool) {
        this.loadsServiceService.getConfig().subscribe((config) => {
          this.config = config;
          this.carrierService.getCarriers().subscribe((carriers) => {
            this.isLoading = false;
            this.carriers = carriers;
          });
        });
      }
    });
  }

  isDemoCarrier(carrier: Carrier): boolean {
    if (this.config['demoCarriers']) {
      const carriers = this.config['demoCarriers'] as Carrier[];
      return carriers.findIndex((c) => c.id === carrier.id) >= 0;
    }
    return false;
  }

  setDemoCarrier(event: { checked: boolean }, carrier: Carrier): void {
    const data = {
      updateDemoCarrier: true,
      adding: event.checked,
      carrier,
    };
    this.loadsServiceService.updateConfig(data).then(() => {
      this.snackBar.open(`Updated`, null, { duration: 5000 });
    });
  }

  formatEquipments(equipments: any[]) {
    return JSON.stringify(equipments, null, '\t');
  }

  displaySaferWatchData(saferWatchData: SaferWatchData): string {
    return JSON.stringify(saferWatchData, null, '\t');
  }

  emailCarriers() {
    let emails = '';

    this.carriers.forEach((carrier) => {
      if (carrier.email) {
        emails += carrier.email + ',';
      }
    });

    window.location.href = `mailto:admin@haulynx.com?bcc=${emails}`;
  }

  emailCarrier(carrier: Carrier) {
    window.location.href = `mailto:${carrier.email}`;
  }

  updateSaferwatch() {
    alert('Update will now run in the background. Updates will be applied in about 15mins');

    this.carrierService.updateSaferwatch().subscribe((res) => {});
  }

  editCarrier(carrier: Carrier) {
    const ref = this.dialog.open(EditCarrierComponent, {
      data: carrier,
      width: '800px',
    });

    ref.afterClosed().subscribe((result) => {
      if (result) {
        this.carriers = map(this.carriers, (c) => (carrier.id === c.id ? result : c));
      }
    });
  }

  deleteCarrier(carrier: Carrier): void {
    const isConfirmed = confirm("You sure you wanna do this? There's no going back!");
    if (isConfirmed === true) {
      this.carrierService.deleteCarrier(carrier).subscribe(
        () => {
          this.carriers = filter(this.carriers, (c) => c.id !== carrier.id);
        },
        (err) => {
          alert(JSON.stringify(err));
        }
      );
    }
  }
}
