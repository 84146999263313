import { ISearchFilter, TextFilterType } from '../search-filter';
import { ISearchFilterType } from '../search-filter.types';

export class CustomerFilter implements ISearchFilter {
  name = 'Customer';
  type: ISearchFilterType = ISearchFilterType.TEXT;
  keys: TextFilterType;
  description = 'Filter lane by the lane customer';

  constructor(defaultValues?: TextFilterType) {
    this.keys = new TextFilterType({
      value: '',
      optionsDataIndex: 'customer',
      textFormName: 'customer',
      ...defaultValues,
    });
  }
}
