import { AddressField } from '../generic';
import { DayOfWeek } from './capacity-object';

export interface LaneForm {
  location: AddressField;
  locationRadius: string;
  preferredLocation?: AddressField;
  preferredLocationRadius?: string;
  pickupDays: Array<{ key: DayOfWeek }>;
  equipmentType?: string;
  price: number;
}
