<div *ngIf="loadEntityService.getCarrierHistoryManager.isSearching$ | async; else card">
  <mat-spinner [diameter]="36"></mat-spinner>
</div>

<ng-template #card>
  <div class="log-container">
    <span class="button-row">
      <button
        [ngClass]="{ 'buttons note-button': true, postTruckActive: showNotesForm }"
        (click)="viewNotesForm(showNotesForm)"
        data-test="add-note"
      >
        <span>Add Note</span>
      </button>
      <button
        [ngClass]="{ 'buttons call-button': true, postTruckActive: showNotesForm }"
        (click)="callCarrier()"
        data-test="call"
      >
        <span>
          <mat-icon svgIcon="haulynx-phone-contact"></mat-icon>
          Call
        </span>
      </button>
    </span>
    <div *ngIf="showNotesForm">
      <app-post-notes-form
        [user]="user"
        (saveNote)="saveNote($event)"
        (cancelForm)="hideNotes(false)"
      ></app-post-notes-form>
    </div>
    <div *ngIf="successfulNote | async" class="successfulNote">Your note was successfully added!</div>
    <hr *ngIf="showNotesForm" class="solid divider" />
    <haulynx-pagination-table
      [data]="notes"
      [tableConfig]="toolbarConfigOptions"
      [paginationData]="paginationData$ | async"
      [templateCellRef]="templateRef"
      [loading]="false"
      [buttonSort]="true"
      (page)="onPage($event)"
    >
      <ng-template #templateRef let-row="row">
        <div class="log-card">
          <div class="log-header">
            <span class="bold">Added Note</span>
            <mat-icon svgIcon="haulynx-note"></mat-icon>
          </div>
          <div class="log-content">
            <div class="log-note" data-test="log-note">
              {{ row.content }}
            </div>
            <div class="log-created">
              <div class="sub-text">
                <div class="log-date" data-test="log-date">
                  {{ row.createdAtTs | dateTime: n / a:'HH:mm MM/dd/yy' }}
                </div>
                <div class="bold">{{ row?.createdByUser?.usxId || 'N/A' }}</div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </haulynx-pagination-table>
  </div>
</ng-template>
