import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DialogTemplateModule } from '../dialog-template/dialog-template.module';
import { XtCarrierRequirementsDialogComponent } from './xt-carrier-requirements-dialog.component';

@NgModule({
  declarations: [XtCarrierRequirementsDialogComponent],
  imports: [CommonModule, DialogTemplateModule, FlexLayoutModule],
  exports: [XtCarrierRequirementsDialogComponent],
})
export class XtCarrierRequirementsDialogModule {}
