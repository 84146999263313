import { Injectable } from '@angular/core';
import { Tab } from '@haulynx/types';
import { mapToArray } from '@haulynx/utils';
import { Store } from '@ngrx/store';
import { List, Map } from 'immutable';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppState } from '../../main-store/app.reducers';
import { CarrierActions } from './carriers.action';
import { carrierSearchSelector, carrierTabsSelector, getIsLoadingSelector } from './carriers.selectors';

@Injectable({
  providedIn: 'root',
})
export class CarriersModel {
  entities$: Observable<List<any>> = this.store.select(carrierSearchSelector.getEntities);
  pagination$: Observable<any> = this.store.select(carrierSearchSelector.getPagination);
  query$: Observable<Map<string, any>> = this.store.select(carrierSearchSelector.getQuery);
  isSearchLoading$: Observable<boolean> = this.store.select(carrierSearchSelector.getLoading);

  tabs$: Observable<Tab[]> = this.store.select(carrierTabsSelector.getTabs).pipe(map(mapToArray));
  tabsSelected$: Observable<Tab> = this.store.select(carrierTabsSelector.getSelected);
  isLoading$: Observable<boolean> = this.store.select(getIsLoadingSelector);

  constructor(private store: Store<AppState>) {}

  search(filter?) {
    this.store.dispatch(CarrierActions.search(filter));
  }

  addTabs(tabs: Tab[] | Tab) {
    this.store.dispatch(CarrierActions.addTabs(tabs));
  }

  updateTabs(tabs: Tab[] | any[]) {
    this.store.dispatch(CarrierActions.updateTabs(tabs));
  }

  selectTab(tab) {
    this.store.dispatch(CarrierActions.selectTab(tab));
  }

  removeTabs(data) {
    this.store.dispatch(CarrierActions.removeTab(data));
  }
}
