<input
  #input
  type="text"
  pInputText
  size="50"
  displayLabel="name"
  [value]="display"
  [placeholder]="placeholder"
  (input)="mytable.filterGlobal($event.target.value, 'contains')"
  (keyup)="inputChanges(this)"
  style="width: auto"
  (focus)="op.toggle($event)"
  [ngClass]="{ disabled: disabled }"
/>
<app-spinner [active]="isLoading" diameter="18"></app-spinner>
<button
  [ngClass]="disabled ? 'disabled-caret' : 'not-disabled-caret'"
  mat-icon-button
  [disabled]="disabled"
  (click)="openPanel($event)"
>
  <mat-icon>{{ op.overlayVisible ? 'expand_less' : 'expand_more' }}</mat-icon>
</button>
<div class="op">
  <p-overlayPanel #op>
    <p-table
      #mytable
      [columns]="cols"
      [value]="data"
      [scrollable]="true"
      scrollHeight="200px"
      selectionMode="single"
      [(selection)]="selectedRow"
      (onRowSelect)="selectRow($event)"
    >
      <ng-template pTemplate="header" let-columns>
        <th *ngFor="let col of columns">
          {{ col.header }}
        </th>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr class="body-format" [pSelectableRow]="item" data-cy="driver-drop-down" data-test="driver-selection">
          <td *ngFor="let column of cols" class="{{ column.field ? column.field : '' }}">
            <span *ngIf="column.field === 'phone'; else allOtherData">
              <mat-icon class="contact-icon" svgIcon="haulynx-phone-filled"></mat-icon
              >{{ item[column.field] | phoneMask: 'xxx-xxx-xxxx' }}</span
            >
            <ng-template #allOtherData>
              {{ item[column.field] }}
            </ng-template>
          </td>

          <!-- <td class="body-data">{{ item.phone | phoneMask: 'xxx-xxx-xxxx' }}</td>
                <td class="body-data">{{ item.email }}</td>  -->
        </tr>
      </ng-template>
      <ng-template pTemplate="footer">
        <div class="footer">
          <button
            class="
              hlx-button-command hlx-button-command--cancel hlx-button-command--border-white
              bulk-actions-dialog__cancel-button
            "
            (click)="onFooterButtonClicked()"
            attr.data-test="{{ footerButtonLabel }}"
          >
            {{ footerButtonLabel }}
          </button>
        </div>
      </ng-template>
    </p-table>
  </p-overlayPanel>
</div>
