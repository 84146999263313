import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@haulynx/directives';
import { MaterialModule } from '@haulynx/modules';
import { LoadRestrictionsPipe, PipesModule } from '@haulynx/pipes';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxCurrencyModule } from 'ngx-currency';
import { NgxMaskModule } from 'ngx-mask';
import { SharedModule } from 'primeng/api';
import { BidCounterOfferDialogComponent } from '../dialogs/bid-counter-offer-dialog/bid-counter-offer-dialog.component';
import { BidHistoryDialogComponent } from '../dialogs/bid-history-dialog/bid-history-dialog.component';
import { DropDownModule } from '../drop-down/drop-down.module';
import { SelectorModule } from '../selector/selector.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { AddBidComponent } from './components/add-bid/add-bid.component';
import { BidCounterOfferComponent } from './components/bid-counter-offer/bid-counter-offer.component';
import { BidHistoryComponent } from './components/bid-history/bid-history.component';
import { BidItemV1Module } from './components/bid-item-v1/bid-item-v1.module';
import { BidItemModule } from './components/bid-item/bid-item.module';
import { BidListComponent } from './components/bid-list/bid-list.component';

@NgModule({
  declarations: [
    AddBidComponent,
    BidCounterOfferComponent,
    BidCounterOfferDialogComponent,
    BidHistoryComponent,
    BidHistoryDialogComponent,
    BidListComponent,
  ],
  exports: [
    BidListComponent,
    AddBidComponent,
    BidCounterOfferComponent,
    BidCounterOfferDialogComponent,
    BidHistoryComponent,
    BidHistoryDialogComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    DropDownModule,
    NgxMaskModule.forRoot(),
    NgxCurrencyModule,
    ReactiveFormsModule,
    SpinnerModule,
    PipesModule,
    ClipboardModule,
    SelectorModule,
    SharedModule,
    BidItemModule,
    BidItemV1Module,
    DirectivesModule,
  ],
  providers: [DatePipe, LoadRestrictionsPipe],
})
export class BidsModule {}
