import { createFeatureSelector } from '@ngrx/store';
import { List, Record } from 'immutable';

export interface ICarrierManagementStateState {
  isLoading: boolean;
  top: List<any>;
  isLoadingTop: boolean;
  developing: List<any>;
  isLoadingDeveloping: boolean;
  prospects: List<any>;
  isLoadingProspects: boolean;
}

export const initialCarrierManagementState = {
  isLoading: false,
  top: List(),
  isLoadingTop: false,
  developing: List(),
  isLoadingDeveloping: false,
  prospects: List(),
  isLoadingProspects: false,
};

export class CarrierManagementStateState
  extends Record(initialCarrierManagementState)
  implements ICarrierManagementStateState {
  isLoading: boolean;
  top: List<any>;
  isLoadingTop: boolean;
  developing: List<any>;
  isLoadingDeveloping: boolean;
  prospects: List<any>;
  isLoadingProspects: boolean;
}

export const getCarrierManagementState = createFeatureSelector<CarrierManagementStateState>('carrier-management');
