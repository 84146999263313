<div class="segment location">
  <div *ngIf="lane?.origin?.locationName; else noOrigin">
    <div>{{ lane?.origin.locationName }}</div>
    {{ lane?.origin.radius }} mi
  </div>
  <ng-template #noOrigin><div class="defaultLocation">Any</div></ng-template>
</div>

<div class="segment"><mat-icon>east</mat-icon></div>

<div class="segment location">
  <div *ngIf="lane?.destination?.locationName; else noDest">
    <div>{{ lane?.destination.locationName }}</div>
    {{ lane?.destination.radius }} mi
  </div>
  <ng-template #noDest><div class="defaultLocation">Any</div></ng-template>
</div>
