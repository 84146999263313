<div class="password-strength-container" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="16px">
  <div class="bars" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="8px">
    <div fxFlex class="status-bar" [ngClass]="score >= 1 ? (score === 4 ? 'filled-bar' : 'red-filled-bar') : ''"></div>
    <div fxFlex class="status-bar" [ngClass]="score >= 2 ? (score === 4 ? 'filled-bar' : 'red-filled-bar') : ''"></div>
    <div fxFlex class="status-bar" [ngClass]="score >= 3 ? (score === 4 ? 'filled-bar' : 'red-filled-bar') : ''"></div>
    <div fxFlex class="status-bar" [ngClass]="score >= 4 ? (score === 4 ? 'filled-bar' : 'red-filled-bar') : ''"></div>
  </div>
  <ul fxLayout="row wrap" class="requirements">
    <li fxFlex="50" [ngClass]="variationsChecklist?.lowerCase ? 'checked' : 'not-checked'">1 lowercase character</li>
    <li fxFlex="50" [ngClass]="variationsChecklist?.specialCharacter ? 'checked' : 'not-checked'">
      1 special character
    </li>
    <li fxFlex="50" [ngClass]="variationsChecklist?.upperCase ? 'checked' : 'not-checked'">1 uppercase character</li>
    <li fxFlex="50" [ngClass]="variationsChecklist?.length ? 'checked' : 'not-checked'">8 characters minimum</li>
  </ul>
</div>
