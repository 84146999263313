<div
  [ngClass]="{ isAlternative: bannerBgColor === 'alternative' }"
  class="banner"
  fxLayout="row"
  fxLayoutAlign="center center"
  fxLayoutGap="16px"
>
  <ng-content select="[bannerText]"></ng-content>
  <haulynx-white-outline-button class="constant-white" [text]="bannerBtnText" (clicked)="onClicked()">
  </haulynx-white-outline-button>
</div>
