import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CarrierManagementTableService, MomentService } from '@haulynx/services';
import { CarrierManagement, carrierManagementTableConfig, IColumns, RelationTypes } from '@haulynx/types';
import { listToArray } from '@haulynx/utils';
import { List } from 'immutable';
import { get } from 'lodash';

@Component({
  selector: 'app-carrier-management-table',
  templateUrl: './carrier-management-table.component.html',
  styleUrls: ['./carrier-management-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarrierManagementTableComponent implements OnChanges, OnInit {
  @Input() isLoading: boolean;
  @Input() data: List<CarrierManagement>;
  @Input() stickyHeader: boolean;
  @Input() relationType: RelationTypes;
  @Output() change = new EventEmitter<CarrierManagement>();
  @Output() delete = new EventEmitter<CarrierManagement>();

  columns: IColumns[] = [];
  items: CarrierManagement[] = [];
  estOffset: string;
  dateFormat: string;

  constructor(
    private carrierManagementTableService: CarrierManagementTableService,
    private momentService: MomentService
  ) {}

  ngOnInit() {
    this.columns = this.initColumns(this.relationType);
    this.dateFormat = carrierManagementTableConfig.dateFormat;
    this.estOffset = carrierManagementTableConfig.estOffset;
  }

  initColumns(relationType: RelationTypes): IColumns[] {
    const columns: IColumns[] = [];

    columns.push({
      label: 'Carrier',
      dataIndex: 'carrierName',
      isCustomCell: true,
    });

    columns.push({
      label: 'MC#',
      dataIndex: 'mcNumber',
      isCustomCell: true,
    });

    if (relationType !== RelationTypes.PROSPECTS) {
      columns.push({
        label: 'Volume',
        dataIndex: 'volume',
        isCustomCell: true,
        sortConvert: this.carrierManagementTableService.customSort.bind(this.carrierManagementTableService),
      });
    }

    columns.push({
      label: 'Last Contacted',
      dataIndex: 'lastContacted',
      isCustomCell: true,
      sortConvert: this.carrierManagementTableService.customSort.bind(this.carrierManagementTableService),
    });

    columns.push({
      label: 'Contacted Today',
      dataIndex: 'contactedToday',
      isCustomCell: true,
      width: '140px',
    });

    columns.push({
      label: '',
      dataIndex: 'options',
      isCustomCell: true,
      isSortable: false,
      width: '50px',
    });

    return columns;
  }

  onChange(row: CarrierManagement): void {
    this.change.emit(row);
  }

  onDelete(row: CarrierManagement): void {
    this.delete.emit(row);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { data } = changes;

    if (data) {
      const items = listToArray(data.currentValue);

      this.items = items.map((item: CarrierManagement) => {
        const lastContacted = get(item, 'lastContacted.timestamp', 0);
        const contactedToday = this.momentService.isToday(lastContacted);

        return {
          ...item,
          contactedToday,
        };
      });
    }
  }
}
