import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { GoogleAnalyticsService } from '@haulynx/services';
import { Action, Category } from '@haulynx/types';

@Directive({
  selector: '[appKeyboardShortcut]',
})
export class KeyboardShortcutDirective {
  @Input() appKeyboardShortcut: string;
  @Input('componentBusinessLabel') componentBusinessLabel: string;

  @Output() shortcutEventTriggered = new EventEmitter();

  key: string;

  constructor(private el: ElementRef, private googleAnalyticsService: GoogleAnalyticsService) {}

  @HostListener('document:keyup', ['$event']) singleKeyboardShortcutTriggered(e: KeyboardEvent): void {
    // single keyboard shortcut for numerical choice select on focused element
    if (document.activeElement.hasAttribute('numericalKeyBoardShortcut')) {
      if (this.numericalInputOnly(this.appKeyboardShortcut)) {
        if (e.key === this.appKeyboardShortcut) {
          this.shortcutSelected();
          this.googleAnalyticsService.eventEmitter(
            Category.LOCAL,
            Action.KEYPRESS,
            this.componentBusinessLabel + ' ' + e.key
          );
        }
      }
    }
  }

  @HostListener('document:keypress', ['$event']) comboKeyboardShortcutTriggered(e: KeyboardEvent): void {
    const hotkey = e.code.substr(e.code.length - 1, e.code.length).toLocaleLowerCase();

    // keyboard combination for local keyboard shortcuts
    const isModifierKey = e.altKey || e.ctrlKey;
    if (
      isModifierKey &&
      hotkey === this.appKeyboardShortcut &&
      !((e.ctrlKey && e.metaKey) || (e.ctrlKey && e.altKey))
    ) {
      this.shortcutSelected();
      this.googleAnalyticsService.eventEmitter(
        Category.LOCAL,
        Action.KEYPRESS,
        this.componentBusinessLabel + ' ' + hotkey
      );
    }

    // keyboard combination for global keyboard shortcuts
    const isGlobalModifierKey = (e.ctrlKey && e.metaKey) || (e.ctrlKey && e.altKey);
    if (isGlobalModifierKey && hotkey !== ' ') {
      if (this.el.nativeElement.hasAttribute('globalKeyboardShortcutEvent')) {
        this.shortcutEventTriggered.emit(hotkey);
      }
    }
  }

  shortcutSelected(): void {
    this.key = this.appKeyboardShortcut;
    if (this.el.nativeElement.hasAttribute('customKeyboardShortcutEvent')) {
      this.shortcutEventTriggered.emit(this.key);
    } else {
      this.el.nativeElement.click();
    }
  }

  numericalInputOnly(input: string): boolean {
    return /^[0-9]+$/.test(input);
  }
}
