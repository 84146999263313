import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DirectivesModule } from '@haulynx/directives';
import { MaterialModule } from '@haulynx/modules';
import { IconModule } from '../../icon/icon.module';
import { CarouselComponent } from './carousel.component';
@NgModule({
  declarations: [CarouselComponent],
  imports: [CommonModule, DirectivesModule, FlexLayoutModule, MaterialModule, IconModule],
  exports: [CarouselComponent],
})
export class CarouselModule {}
