<div class="book-or-contact">
  <div *ngIf="!(load?.bookStatus === 'booked')">
    <haulynx-blue-button
      *ngIf="load?.carrierCanBookNow; else contact"
      class="lighter-green bids-timeline-btns can-book-it-now"
      text="Book It Now"
      icon="schedule"
      fxFlex
      (clicked)="bookItNowClicked()"
      [haulynxDisabled]="!rmisComplete"
      data-test="book-it-now-button"
    >
    </haulynx-blue-button>
    <ng-template #contact>
      <haulynx-blue-button
        class="lighter-blue bids-timeline-btns contact-to-book"
        text="Contact To Book"
        fxFlex
        [icon]="menuOpened ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
        iconPosition="after"
        [matMenuTriggerFor]="contactBrokerMenu"
        (menuOpened)="menuOpened = true"
        (menuClosed)="menuOpened = false"
        (click)="$event.stopPropagation(); contactToBookClicked()"
        [haulynxDisabled]="!rmisComplete"
        data-test="contact-to-book-button"
      >
      </haulynx-blue-button>
    </ng-template>
  </div>

  <mat-menu #contactBrokerMenu="matMenu" class="carrier-menu-panel book-or-contact-panel">
    <div fxLayout="column" fxLayoutGap="12px" class="book-or-contact">
      <div fxLayout="column" fxLayoutGap="3px">
        <div fxLayout="row" fxLayoutAlign="space-between start">
          <span class="field">Email</span>
          <span class="button" (click)="copyToClipboard(load?.providerDetails?.regionalManagerEmail)">Copy</span>
        </div>
        <span class="field"
          ><a href="mailto:{{ load?.providerDetails?.regionalManagerEmail }}">{{
            load?.providerDetails?.regionalManagerEmail
          }}</a></span
        >
      </div>
      <div fxLayout="column" fxLayoutGap="3px">
        <div fxLayout="row" fxLayoutAlign="space-between start">
          <span class="field" fxLayout="column">Phone</span>
          <span class="button" (click)="copyToClipboard(load?.providerDetails?.regionalManagerPhone)">Copy</span>
        </div>
        <span class="field"
          ><a href="tel:{{ load?.providerDetails?.regionalManagerPhone }}">{{
            load?.providerDetails?.regionalManagerPhone
          }}</a></span
        >
      </div>
    </div>
  </mat-menu>
</div>
