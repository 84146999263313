import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PageTitleService {
  private pageTitleSubject = new BehaviorSubject<string>('');
  readonly pageTitle$ = this.pageTitleSubject.asObservable();
  public isSidenavOpenSubject: Subject<boolean> = new Subject<boolean>();
  public isSidenavOpen = false;

  constructor() {
    this.isSidenavOpenSubject.subscribe((isOpen: boolean) => (this.isSidenavOpen = isOpen));
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      event.returnValue = 'Unsaved Changes';
      return event;
    });
  }

  setPageTitle(pageTitle: string) {
    this.pageTitleSubject.next(pageTitle);
  }

  toggleFilter() {
    this.isSidenavOpenSubject.next(!this.isSidenavOpen);
  }
}
