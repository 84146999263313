import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@haulynx/directives';
import { ElementsModule } from '@haulynx/elements';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { ActionButtonsModule } from '../actions-buttons/action-buttons.module';
import { BidsModule } from '../bids/bids.module';
import { CarrierDetailsModule } from '../carrier-details/carrier-details.module';
import { DataTableV2Module } from '../data-table-v2/data-table-v2.module';
import { DataTableModule } from '../data-table/data-table.module';
import { LoadMainInfo } from '../load-main-info/load-main-info.module';
import { LoadTimelineModule } from '../load-timeline/load-timeline.module';
import { MapsModule } from '../maps/maps.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { LoadFeedAcceptComponent } from './components/load-feed-accept/load-feed-accept.component';
import { LoadFeedCarriersSearchV2Module } from './components/load-feed-carriers-search-v2/load-feed-carriers-search-v2.module';
import { LoadFeedCarrierSearchComponent } from './components/load-feed-carriers-search/load-feed-carrier-search.component';
import { LoadFeedDetailsBrokerContainer } from './components/load-feed-details-broker/load-feed-details-broker.component';
import { LoadFeedRejectComponent } from './components/load-feed-reject/load-feed-reject.component';
import { LoadFeedSearchFormModule } from './components/load-feed-search-form/load-feed-search-form.module';

@NgModule({
  declarations: [
    LoadFeedAcceptComponent,
    LoadFeedCarrierSearchComponent,
    LoadFeedDetailsBrokerContainer,
    LoadFeedRejectComponent,
  ],
  exports: [
    LoadFeedAcceptComponent,
    LoadFeedCarrierSearchComponent,
    LoadFeedDetailsBrokerContainer,
    LoadFeedRejectComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    DataTableModule,
    PipesModule,
    DirectivesModule,
    ActionButtonsModule,
    DataTableV2Module,
    LoadTimelineModule,
    SpinnerModule,
    ElementsModule,
    LoadMainInfo,
    MapsModule,
    BidsModule,
    LoadFeedSearchFormModule,
    LoadFeedCarriersSearchV2Module,
    CarrierDetailsModule,
  ],
})
export class LoadFeedComponentsModule {}
