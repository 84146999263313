import gql from 'graphql-tag';

export const GetFeatureFlagDetails = gql`
  query getFeatureFlagDetails($featureFlagName: String, $includeDetails: Boolean!) {
    getFeatureFlagDetails(featureFlagName: $featureFlagName) {
      name
      description
      platforms {
        web
        ios
        android
      }
      environments {
        environment
        dots {
          name @include(if: $includeDetails)
          dot
        }
        users {
          id
          name @include(if: $includeDetails)
          email @include(if: $includeDetails)
          carrier @include(if: $includeDetails) {
            id
          }
        }
      }
      builds {
        ios
        android
      }
    }
  }
`;
