import gql from 'graphql-tag';

export const GetReverseGeocodeLocation = gql(`
query getReverseGeocodeLocation($coordinates:LatLonInput!, $limit: PositiveInt, $placeType: PlaceType){
  getReverseGeocodeLocation(coordinates:$coordinates, limit: $limit, placeType: $placeType){
    addressNumber
    city
    country
    fullAddress
    lat
    lon
    placeType
    state
    street
    zipcode
    stateAbbreviation
  }
}
`);
