import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { NgxMaskModule } from 'ngx-mask';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TableModule } from 'primeng/table';
import { SpinnerModule } from '../spinner/spinner.module';
import { TruckDropDownComponent } from './truck-drop-down.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    ScrollingModule,
    SpinnerModule,
    TableModule,
    OverlayPanelModule,
    PipesModule,
    NgxMaskModule,
  ],
  exports: [TruckDropDownComponent],
  declarations: [TruckDropDownComponent],
})
export class TruckDropDownModule {}
