import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  AddressField,
  Carrier,
  DistributionMethod,
  equipmentTypes,
  LoadsServiceLoadInput,
  LoadsServiceLoad,
  LoadLocationType,
  User,
} from '@haulynx/types';
import { addressFieldValidators, mapDto } from '@haulynx/utils';
import { assign, forEach, head, keys } from 'lodash';
import { MomentService } from '../../app-services/generic/moment/moment.service';
import { loadFromDto, loadTemplateToDto, loadToDto } from './load-map.vm';
import { add, isBefore } from 'date-fns';

export class LoadLocationForm {
  location: AddressField = null;
  date: number = null;
  waitDate: number = null;
  // customerId
  customerId: string = null;
  // location type
  locationType: LoadLocationType = null;
  completed: number = null; // time finished
  arrivalTime: number = null; // estimated time of arrival -> actual time of arrival when time is passed (UTC timestamp)
  //estimatedWaitTime: number = null;
  specialNotes: string = null;

  constructor(options = {}) {
    const key = keys(this);
    const defaultValue = [null];

    forEach(key, (prop) => {
      const control = (options && options[prop]) || defaultValue;

      this[prop] = control;
    });
  }
}

export class LoadForm {
  id: string = null;
  //Bill-to customer
  customer: string = null;
  // B.O.L
  billOfLading = null;
  // Equipment Type
  equipmentType = null;
  // Price
  price: number = null;
  revenue: string = null;
  // Weight of Product
  loadWeight = null;
  // Status of loads
  loadStatus = null;
  // P.O. number
  poNumber = null;
  // Commodity
  commodity = null;
  // Quantity
  quantity = null;
  // Packing type
  packingType = null;
  temperature = null;
  // ****** ASSIGN LOAD ******
  // Load Distribution Method
  distributionMechanism: DistributionMethod = null;
  // Carrier Safety Rating
  carrierSafetyRating: string = null;
  // Carrier Auto Liability Insurance
  cargoLiability: number = null;
  // Carrier Cargo Liability Insurance
  autoLiability: number = null;
  // Carrier should be Hazmat Capable
  hazmatCapabilityRequired = false;
  // Select carrier
  carrier: string = null;
  // Select driver
  driver: string = null;
  // Select truck
  truck: string = null;
  // Trailer Number
  trailerId = null;
  // Select trailer
  trailer = null;
  // Locations
  locations: LoadLocationForm[] = [];
  carrierName: string = null;

  // These data for check
  dateCompleted: number = null;
  carrierAccepted: string = null;
  pendingCarriers: Carrier[] = null;
  creator: string = null;
  isPaid = false;
  broker: string = null;

  postTruck = false;

  constructor(options = {}) {
    const key = keys(this);
    const defaultValue = [null];

    forEach(key, (prop) => {
      const control = (options && options[prop]) || defaultValue;

      this[prop] = control;
    });
  }
}

@Injectable({
  providedIn: 'root',
})
export class LoadVm {
  constructor(private fb: FormBuilder, private momentService: MomentService) {}

  generateOptions() {
    const loadOption = {
      billOfLading: [null],
      price: [null, Validators.compose([Validators.min(0)])],
      equipmentType: [head(equipmentTypes)?.text],
      loadWeight: [null],
      locations: this.fb.array([]),
      postTruck: [true],
    };
    const locationOption = {
      location: [null, Validators.compose([addressFieldValidators.required()])],
      date: [null, Validators.compose([Validators.required])],
      time: [null, Validators.compose([Validators.required])],
    };

    return { locationOption, loadOption };
  }

  create(values: Partial<LoadForm>, carrierTimezone = null): FormGroup {
    const options = this.generateOptions();
    const loadForm = new LoadForm(options.loadOption);
    const loadGroup = this.fb.group({
      ...loadForm,
      locations: this.createLocations(loadForm.locations, carrierTimezone),
    });

    if (values) {
      loadGroup.patchValue(values);
    }

    return loadGroup;
  }

  createLocations(locations = [], carrierTimezone = null): FormArray {
    const todayDay = new Date();
    const nextDay = add(todayDay, { days: 1 });

    let locationGroup;
    if (locations && locations.length) {
      locationGroup = locations.map((value, index) => {
        const date = index > 0 ? nextDay.valueOf() : todayDay.valueOf();
        const updatedLocation = { ...value, date };
        return this.createLocation(updatedLocation);
      });
    } else {
      locationGroup = [
        this.createLocation({
          locationType: LoadLocationType.PICKUP,
          date: todayDay.valueOf(),
          location: { timeZone: carrierTimezone, id: null, address: null, lat: null, lon: null },
        }),
        this.createLocation({
          locationType: LoadLocationType.DROPOFF,
          date: nextDay.valueOf(),
          location: { timeZone: carrierTimezone, id: null, address: null, lat: null, lon: null },
        }),
      ];
    }

    return this.fb.array(locationGroup, [
      this.loadLocationsAreChronological.bind(this),
      this.onePickupOneDropoff.bind(this),
    ]);
  }

  createLocation(values: Partial<LoadLocationForm>): FormGroup {
    const { locationOption } = this.generateOptions();
    const locationForm = new LoadLocationForm(locationOption);
    const locationGroup = this.fb.group(locationForm);

    if (values) {
      locationGroup.patchValue(values);
    }

    return locationGroup;
  }

  /**
   * From api object
   * @param LoadsServiceLoad
   * @param user
   */
  fromDto(load: LoadsServiceLoad, user: User): LoadForm {
    return mapDto<LoadForm>(load, loadFromDto, [load, load.locations, user]);
  }

  /**
   * To API
   * @param loadServiceLoadInput
   * @param loadForm
   * @param entities
   * @param user
   */
  toDto(load: LoadsServiceLoadInput, loadForm: LoadForm, user, entities): LoadsServiceLoadInput {
    const partialLoad = mapDto<Partial<LoadsServiceLoadInput>>(loadForm, loadToDto, [loadForm, user, entities]);
    return assign({}, load, partialLoad, { locations: partialLoad.locations }) as LoadsServiceLoadInput;
  }

  /**
   * To template
   * @param load
   * @param user
   * @param selectedEntities
   */
  toTemplate(load: LoadForm, user: User, selectedEntities): LoadsServiceLoad {
    const partialLoad = mapDto<Partial<LoadsServiceLoad>>(load, loadTemplateToDto, [load, user, selectedEntities]);

    return assign({}, partialLoad, { locations: partialLoad.locations }) as LoadsServiceLoad;
  }

  setValidity(form: FormGroup) {
    const formData = form.getRawValue();

    forEach(formData, (val, key) => {
      const control = form.get(key) as FormControl | FormArray | FormGroup;

      if (control instanceof FormArray) {
        forEach(control.controls as FormGroup[], (controlVal) => {
          this.setValidity(controlVal);
        });
      } else if (control instanceof FormGroup) {
        this.setValidity(control);
      } else {
        form.get(key).markAsTouched({ onlySelf: true });
      }
    });
  }

  onePickupOneDropoff(c: FormArray): { [key: string]: boolean } | null {
    const error: { [key: string]: boolean } = { pickupAndDropoff: true };

    if (c.length < 2) {
      return error;
    }
    try {
      const pickup = c.controls.find(
        (loadLocationGroup) => loadLocationGroup.get('locationType').value === LoadLocationType.PICKUP
      );
      const dropoff = c.controls.find(
        (loadLocationGroup) => loadLocationGroup.get('locationType').value === LoadLocationType.DROPOFF
      );
      if (!pickup || !dropoff) {
        return error;
      } else {
        return null;
      }
    } catch {
      return null;
    }
  }
  /**
   * Iterate through formgroups and assess if the current timestamp control value is greater than the previous.
   * @param c FormArray
   * @returns { [key: string]: boolean } | null
   */
  loadLocationsAreChronological(c: FormArray): { [key: string]: boolean } | null {
    try {
      const invalidOrderController = c.controls.find((currentControl, index: number) => {
        const skipFirstLocation = index === 0;
        let waitDateChronologicalCheck: boolean = false;

        if (currentControl?.value?.date && currentControl?.value?.waitDate) {
          waitDateChronologicalCheck = this.waitDateChronological(currentControl.value);
        }

        if (skipFirstLocation) {
          return waitDateChronologicalCheck;
        }

        const currentControlValue: LoadLocationForm = currentControl.value;
        const currentLocationDate = this.momentService.convertHtmlTime(
          currentControlValue.date,
          currentControlValue.location && currentControlValue.location.timeZone
        );

        const previousControl: LoadLocationForm = c.at(index - 1).value;
        const previousLocationDate = this.momentService.convertHtmlTime(
          previousControl.date,
          previousControl.location && previousControl.location.timeZone
        );

        return currentLocationDate.isBefore(previousLocationDate) || waitDateChronologicalCheck;
      });

      return invalidOrderController ? { chronological: true } : null;
    } catch {
      return null;
    }
  }

  /**
   * waitDateChronological takes a location populated with waitDate and date
   * utilized isBefore to check if waitDate is before date.
   * @param location LoadLocationForm from formArray
   * @returns boolean
   */
  private waitDateChronological(location: LoadLocationForm): boolean {
    return isBefore(location?.waitDate, location?.date);
  }
}
