<button class="go-to-active" mat-mini-fab (click)="goToActive()">
  <mat-icon>chevron_left</mat-icon>
</button>

<ng-container *ngIf="!(loadEntityService.getLoadByIdManager.getIsLoadingById(this.loadId) | async); else showLoading">
  <div class="load-active-details">
    <app-load-active-status
      title="Load Status"
      [load]="selectedLoad"
      [user]="user"
      [showEditLoadButton]="showEditLoadButton"
      (onSuccess)="success($event)"
      (onClosed)="closeTab($event)"
      *ngIf="!!selectedLoad"
    >
    </app-load-active-status>

    <app-load-active-contact
      title="Contact info"
      [load]="selectedLoad"
      [user]="appModel.user$ | async"
      *ngIf="!!selectedLoad && selectedLoad?.provider?.detailType !== 'carrier'"
    >
    </app-load-active-contact>
  </div>
</ng-container>

<ng-template #showLoading>
  <app-spinner [active]="true"></app-spinner>
</ng-template>
