import gql from 'graphql-tag';

/**
 * Only add what is necessary for the carrier load search table.
 * More information will be retrieved for the detail view with a later call
 */
export const MINIMAL_CARRIER_BID_DETAILS_FRAGMENT = gql`
  fragment minimalCarrierBidDetails on Bid {
    status
    finalStateFrom
    price
    sourceType
    activeCounterOffer {
      price
      status
    }
  }
`;
