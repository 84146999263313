import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MapboxModule } from '../mapbox/mapbox.module';
import { SkeletonLoaderModule } from '../skeleton-loader/skeleton-loader.module';
import { CarrierMapboxComponent } from './carrier-mapbox.component';

@NgModule({
  declarations: [CarrierMapboxComponent],
  imports: [CommonModule, MapboxModule, SkeletonLoaderModule],
  exports: [CarrierMapboxComponent],
})
export class CarrierMapboxModule {}
