<div *ngIf="dataSource?.data?.length !== 0; else showNoContent" class="table-container" fxLayout="column">
  <!-- Paginator -->
  <div fxLayout="row" fxLayoutAlign="space-between center" class="paginator">
    <div *ngIf="hideRowLimit"></div>
    <div class="text left-block" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" *ngIf="!hideRowLimit">
      <div>Showing</div>
      <mat-select
        class="select"
        fxFlex="50px"
        [disabled]="loading"
        (selectionChange)="limitChanged($event)"
        [value]="pageSortState?.limit ?? 25"
        panelClass="select-panel"
        fxLayout="row"
        fxLayoutAlign="center center"
        data-test="result-dropdown"
      >
        <mat-option *ngFor="let limit of rowLimitOptions" [value]="limit">{{ limit }}</mat-option>
      </mat-select>
      <div>Results</div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" class="right-block">
      <div class="text">{{ paginationPageView }}</div>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="32px">
        <div fxLayout="row">
          <button
            mat-icon-button
            [disabled]="pagination?.totalPages <= 1 || pagination?.currentPage === 1 || loading"
            (click)="previousPageClicked()"
            data-test="previous-page"
          >
            <mat-icon>chevron_left</mat-icon>
          </button>
          <button
            mat-icon-button
            [disabled]="pagination?.totalPages <= 1 || pagination?.currentPage === pagination?.totalPages || loading"
            (click)="nextPageClicked()"
            data-test="next-page"
          >
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
        <button mat-icon-button (click)="refreshClicked()" class="refresh-button" [disabled]="loading">
          <mat-icon>autorenew</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <!-- Table -->
  <div>
    <table mat-table fixedLayout [dataSource]="dataSource">
      <ng-container *ngFor="let column of columns; let even = even">
        <ng-container
          matColumnDef="{{ column.label }}"
          [sticky]="column?.stickyLeft || column?.lastLeftSticky"
          [stickyEnd]="column?.stickyRight || column?.firstRightSticky"
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            class="padding-left"
            [ngClass]="{
              'zindex-down': column?.pushStickyHeaderBack,
              bold: column?.stickyLeft || column?.lastLeftSticky
            }"
            [style.width.px]="column?.width ?? defaultColumnWidth"
            [style.height.px]="headerHeight"
          >
            <div
              fxLayout="row"
              [fxLayoutAlign]="
                column?.headerAlignment === 'right'
                  ? 'end center'
                  : column?.headerAlignment === 'center'
                  ? 'center center'
                  : 'space-between center'
              "
            >
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                <div>{{ column.label | titlecase }}</div>
                <div class="sort-icon" *ngIf="column?.sortType">
                  <haulynx-virtual-table-sort
                    [column]="column"
                    [(dataSource)]="dataSource"
                    [pagination]="pagination"
                    [pageSortState]="pageSortState"
                    (getSortedData)="getDataSorted($event)"
                  >
                  </haulynx-virtual-table-sort>
                </div>
              </div>
              <span class="separator" [hidden]="column?.hideHeaderRightSeparator">|</span>
            </div>
          </th>
          <td
            mat-cell
            *matCellDef="let data"
            class="padding-left"
            [ngClass]="{
              'remove-box-shadow': column?.stickyLeft,
              'box-shadow': column?.lastLeftSticky || column?.firstRightSticky
            }"
            [style.width.px]="column?.width ?? defaultColumnWidth"
            [style.height.px]="column?.height ?? defaultColumnHeight"
          >
            <!-- Loading -->
            <div *ngIf="loading; else notLoading">
              <div *ngFor="let style of column?.loadingStyle ?? [{}]" class="loading-bars" fxLayout="column">
                <haulynx-skeleton-loader
                  [pixelHeight]="style?.pixelHeight"
                  [percentageWidth]="style?.percentageWidth"
                  [marginBottom]="style?.marginBottom"
                  [align]="style?.align"
                  data-test="skeleton-loader"
                ></haulynx-skeleton-loader>
              </div>
            </div>

            <!-- Custom Outlet or Interpolated Value -->
            <ng-template #notLoading>
              <ng-container
                *ngIf="{
                  value: data | virtualTableGet: column
                } as variable"
              >
                <ng-container *ngIf="!column?.custom; else customCell"> {{ variable?.value }} </ng-container>
                <ng-template #customCell>
                  <ng-container
                    *ngTemplateOutlet="
                      customCellReference;
                      context: {
                        data: data,
                        column: column,
                        value: variable?.value
                      }
                    "
                  >
                  </ng-container>
                </ng-template>
              </ng-container>
            </ng-template>
          </td>
        </ng-container>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnLabels; sticky: stickyHeader"></tr>
      <tr mat-row *matRowDef="let row; columns: columnLabels" (click)="rowClicked(row)"></tr>
    </table>
  </div>
</div>

<ng-template #showNoContent>
  <div fxFlexFill>
    <ng-container fxFlexFill *ngTemplateOutlet="noDataReference"></ng-container>
  </div>
</ng-template>
