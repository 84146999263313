import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@haulynx/directives';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import {
  AsyncEntityGeocodingeModule,
  AsyncEntityPreferredLanesStoreModule,
  CarrierEntityModule,
  CarrierLoadSearchEntityModule,
  CarriersStoreModule,
} from '@haulynx/store';
import { ReactiveComponentModule } from '@ngrx/component';
import { TableModule } from 'primeng/table';
import { DataTableV2Module } from '../data-table-v2/data-table-v2.module';
import { DropDownModule } from '../drop-down/drop-down.module';
import { PostTruckFormModule } from '../post-truck-form/post-truck-form.module';
import { PreferredLanesFormModule } from '../preferred-lanes-form/preferred-lanes-form.module';
import { CarrierToolbarComponent } from './carrier-toolbar.component';
import { CarrierHistoryComponent } from './components/carrier-history/carrier-history.component';
import { CarrierLogComponent } from './components/carrier-log/carrier-log.component';
import { CarrierPostedTrucksComponent } from './components/carrier-posted-trucks/carrier-posted-trucks.component';
import { CarrierToolbarCardGroupComponent } from './components/carrier-toolbar-card-group/carrier-toolbar-card-group.component';
import { CarrierToolbarCarrierProfileComponent } from './components/carrier-toolbar-carrier-profile/carrier-toolbar-carrier-profile.component';
import { CarrierToolbarLanesComponent } from './components/carrier-toolbar-lanes/carrier-toolbar-lanes.component';
import { PaginationTableComponent } from './components/pagination-table/pagination-table.component';
import { PostNotesFormComponent } from './components/post-notes-form/post-notes-form.component';
import { ToolbarCardModule } from './components/toolbar-card/toolbar-card.module';

@NgModule({
  declarations: [
    CarrierToolbarComponent,
    CarrierToolbarCardGroupComponent,
    CarrierToolbarCarrierProfileComponent,
    CarrierPostedTrucksComponent,
    PostNotesFormComponent,
    CarrierToolbarLanesComponent,
    CarrierLogComponent,
    CarrierHistoryComponent,
    PaginationTableComponent,
  ],
  exports: [CarrierToolbarComponent],
  imports: [
    AsyncEntityGeocodingeModule,
    AsyncEntityPreferredLanesStoreModule,
    DataTableV2Module,
    CarriersStoreModule,
    PostTruckFormModule,
    PreferredLanesFormModule,
    TableModule,
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    PipesModule,
    ToolbarCardModule,
    CarrierLoadSearchEntityModule,
    DirectivesModule,
    CarrierEntityModule,
    ReactiveComponentModule,
    DropDownModule,
    ScrollingModule,
  ],
})
export class CarrierToolbarModule {}
