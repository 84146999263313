import { ISortingConfig } from '@haulynx/types';
import { PaginationTableConfig } from '../data-table';
import {
  CarrierToolbarDetailsPage,
  CarrierToolbarState,
  CarrierToolbarSubTab,
  CarrierToolbarTab,
} from './carrier-toolbar-state-type';

export const ToolbarPostedTrucksConfigOptions: PaginationTableConfig = {
  showTopPaginator: true,
  showBottomPaginator: false,
  showSort: false,
  pageAmount: 50,
  scrollHeight: '600px',
  scrollable: true,
};

export const ToolbarHistoryConfigOptions: PaginationTableConfig = {
  showTopPaginator: true,
  showBottomPaginator: false,
  showSort: false,
  pageAmount: 10,
  scrollHeight: '600px',
  scrollable: true,
};

export const ToolbarLogConfigOptions: PaginationTableConfig = {
  showTopPaginator: true,
  showBottomPaginator: false,
  showSort: true,
  pageAmount: 10,
  scrollHeight: '600px',
  scrollable: true,
  isSortable: true,
  sortConfig: [
    {
      label: 'Time Created',
      dataIndex: 'createdAtTs',
      emitSort: false,
    },
  ],
};

export const ToolbarLanesConfigOptions: PaginationTableConfig = {
  showTopPaginator: true,
  showBottomPaginator: false,
  showSort: false,
  pageAmount: 10,
  scrollHeight: '600px',
  scrollable: true,
};

export const defaultCarrierToolbarState: CarrierToolbarState = {
  currentTab: CarrierToolbarTab.RECOMMENDATIONS,
  detailsPage: null,
  data: null,
  isOpen: false,
};
