import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { PostTruckService } from '@haulynx/services';
import { AppModel, CarrierDashboardModel } from '@haulynx/store';
import { DataTableModule } from '../data-table/data-table.module';
import { PostedTrucksComponent } from './posted-trucks.component';

@NgModule({
  declarations: [PostedTrucksComponent],
  imports: [CommonModule, MaterialModule, PipesModule, DataTableModule],
  providers: [CarrierDashboardModel, AppModel, PostTruckService],
  exports: [PostedTrucksComponent],
})
export class PostedTrucksModule {}
