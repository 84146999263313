import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { SidebarItem } from '@haulynx/types';
import { every, flatMap, get, has, indexOf, keys, max, reduce } from 'lodash';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // todo move to container for side effect
  animations: [
    trigger('sidebarToggle', [
      state('collapsed', style({ transform: 'translateX(-245px)' })),
      state('expanded', style({ transform: 'translateX(-5px)' })),
      transition('expanded <=> collapsed', animate('300ms cubic-bezier(0.4,0.0,0.2,1)')),
    ]),
  ],
})
export class SidebarComponent implements OnChanges {
  @Input() name: string;
  @Input() items: SidebarItem[] = [];
  @Input() currentUrl: string;
  @Input() currentData = {};
  @Input() isSidebarOpened: boolean;
  @Input() requiredDataFields: string[] = [];
  @Output() itemSelected = new EventEmitter<{ item: SidebarItem; previousItem: SidebarItem }>();
  @Output() sidebarToggled: EventEmitter<boolean> = new EventEmitter();
  @Output() matchedSidebarItem = new EventEmitter<SidebarItem>();
  @Output() deleteSidebarItem = new EventEmitter<SidebarItem>();
  @Output() updateSidebarItem = new EventEmitter<SidebarItem[]>();

  activeRoutes: string[] = [];
  selectedLink: string;

  private previousItem: SidebarItem;

  constructor() {}

  onItemSelected(item: SidebarItem): void {
    this.itemSelected.emit({ item, previousItem: this.previousItem });
    if (item.data) {
      this.previousItem = item;
    }
    // Force a reload of columns
    setTimeout(() => this.setCurrentSelectedLink(true));
  }

  getActiveBranch(url: string, items: SidebarItem[]): string[] {
    if (!items || !url) {
      return [];
    }

    return items.reduce((result: string[], item: SidebarItem) => {
      const route = this.getActiveBranch(url, item.children);

      result = item.route === url ? [item.route] : result;

      return route.length ? [...route, item.route] : result;
    }, []);
  }

  // todo move logic to ngrx state
  onToggleSidebar(): void {
    this.isSidebarOpened = !this.isSidebarOpened;
    this.sidebarToggled.emit(this.isSidebarOpened);
  }

  trackByFn(index: number, item: SidebarItem): string {
    return item.route;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { currentUrl, items, currentData } = changes;

    if (items || currentUrl) {
      const newItems = get(items, 'currentValue', this.items);
      const newUrl = get(currentUrl, 'currentValue', this.currentUrl);

      this.activeRoutes = this.getActiveBranch(newUrl, newItems);
    }

    if (items) {
      this.setCurrentSelectedLink();
    }

    // Do not reload columns if the only thing that is changing is the search query
    if (currentData) {
      this.setCurrentSelectedLink(false);
    }
  }

  private setCurrentSelectedLink(emit = true): void {
    const selectedSidebarItem = this.findSelectedLinkByMatchingData(emit);
    this.selectedLink = selectedSidebarItem?.label;
    if (selectedSidebarItem && selectedSidebarItem.data) {
      this.previousItem = selectedSidebarItem;
    }
  }

  onDeleteSidebarItem(item: SidebarItem) {
    this.deleteSidebarItem.emit(item);
  }

  onUpdateSidebarItem(items: SidebarItem[]) {
    this.updateSidebarItem.emit(items);
  }

  private findSelectedLinkByMatchingData(emit = true, data?: unknown): SidebarItem {
    function flattenSidebarNodes(items: SidebarItem[]): SidebarItem[] {
      if (!items) return [];
      return flatMap(items, (c) => [c, ...flattenSidebarNodes(c.children)]);
    }

    const allSidebarItems: SidebarItem[] = flattenSidebarNodes(this.items);
    const itemsScored: number[] = allSidebarItems.map((item) =>
      this.scoreObjectSimliarity(item.data, data || this.currentData, this.requiredDataFields)
    );
    const bestMatchIndex: number = indexOf(itemsScored, max(itemsScored));
    const bestMatch: SidebarItem = allSidebarItems[bestMatchIndex];

    if (emit) this.matchedSidebarItem.emit(bestMatch);
    return bestMatch;
  }

  /**
   * Score an object based on its key / value similiarity to a base object.
   * @param objectToScore the object in question that you want to score
   * @param baseObject the object by which you are scoring to determine similiarity
   * @returns an int number score. Higher values indicate more simliarity.
   */
  private scoreObjectSimliarity<T>(objectToScore: T, baseObject: T, requiredFields: string[] = []): number {
    const objectKeys = keys(objectToScore);
    if (!objectKeys.length) {
      return 0;
    }
    if (!baseObject) {
      // return 1 point for matching a nullish value
      return objectToScore === baseObject ? 1 : 0;
    }

    return reduce(
      objectKeys,
      (prevScore, currKey) => {
        // Start checking required fields
        if (requiredFields.includes(currKey)) {
          switch (typeof baseObject[currKey]) {
            case 'string':
            case 'number':
            case 'boolean':
              // +/-100 points for meeting or failing required fields match
              prevScore = objectToScore[currKey] === baseObject[currKey] ? prevScore + 100 : prevScore - prevScore;
              break;
            case 'object':
              if (Array.isArray(baseObject[currKey])) {
                // +/-100 points for meeting or failing required fields match
                const baseSet = new Set<string | number>(baseObject[currKey]);
                const scoringSet = new Set<string | number>(objectToScore[currKey]);
                const objectHasAllFieldsInBaseObject = every(baseSet, (value: string | number) =>
                  scoringSet.has(value)
                );
                prevScore = objectHasAllFieldsInBaseObject ? prevScore + 100 : prevScore - 100;
                break;
              }
              prevScore += this.scoreObjectSimliarity(objectToScore[currKey], baseObject[currKey], requiredFields);
              break;
            default:
              break;
          }
        }
        // End checking required fields

        if (has(baseObject, currKey)) {
          // +1 point for having the same key
          ++prevScore;
        } else {
          // -1 point for not having the same key
          --prevScore;
        }
        if (!objectToScore[currKey]) {
          return prevScore;
        }

        switch (typeof objectToScore[currKey]) {
          case 'string':
          case 'number':
          case 'boolean':
            // +1 point for matching the value of each key, -1 if not
            return objectToScore[currKey] === baseObject[currKey] ? (prevScore = ++prevScore) : --prevScore;
          case 'object':
            // compute points for nested objects / arrays
            return prevScore + this.scoreObjectSimliarity(objectToScore[currKey], baseObject[currKey], requiredFields);
          default:
            return prevScore;
        }
      },
      0
    );
  }
}
