import { Injectable } from '@angular/core';
import {
  CREATE_FREIGHT_CONTRACT,
  DELETE_FREIGHT_CONTRACT,
  SEARCH_FREIGHT_CONTRACTS,
  UPDATE_FREIGHT_CONTRACT,
} from '@haulynx/gql';
import { Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import {
  FreightContract,
  FreightContractInput,
  FreightContractPageParams,
  PageAndSort,
  PaginatedData,
} from '@haulynx/types';
import { GraphqlService } from '../graphql.service';

@Injectable({
  providedIn: 'root',
})
export class ContractsService {
  constructor(private graphqlService: GraphqlService) {}

  /**
   * @description
   * Searches for freight contracts.
   *
   * @returns {Observable<PaginatedData<any>>}
   */
  searchFreightContracts(
    searchParams: any = {},
    paging: Partial<PageAndSort> = { limit: 10 }
  ): Observable<PaginatedData<any>> {
    const pageParams: FreightContractPageParams =
      paging?.sort && paging?.order
        ? {
            pageSize: paging.limit,
            page: paging?.page ?? 1,
            sortColumn: paging?.order,
            sortDirection: paging?.sort.toUpperCase(),
          }
        : {
            pageSize: paging.limit,
            page: paging?.page ?? 1,
          };
    return this.graphqlService
      .query<PaginatedData<any>>({
        query: SEARCH_FREIGHT_CONTRACTS,
        variables: {
          searchParams,
          pageParams,
        },
      })
      .pipe(
        map((result) => {
          const response: any = result.data['searchFreightContracts'];
          return {
            data: response?.items,
            pagination: {
              totalPages: response?.pages,
              total: response?.pages,
              limit: response.pageSize,
              page: response.page,
            },
          } as PaginatedData<any>;
        })
      );
  }

  /**
   * @description
   * Creates a freight contract.
   *
   * @param freightContract FreightContractInput
   *
   * @returns {Observable<PaginatedData<FreightContract>>}
   */
  createFreightContract(freightContract: FreightContractInput): Observable<boolean> {
    return this.graphqlService
      .mutate<FreightContract>({
        mutation: CREATE_FREIGHT_CONTRACT,
        variables: {
          freightContract,
        },
      })
      .pipe(
        map(({ data, error }) => {
          if (error) {
            return false;
          }
          return true;
        })
      );
  }

  /**
   * @description
   * Updates a freight contract.
   *
   * @param freightContract FreightContractInput
   *
   * @returns {Observable<PaginatedData<FreightContract>>}
   */
  updateFreightContract(freightContract: FreightContractInput): Observable<boolean> {
    return this.graphqlService
      .mutate<FreightContract>({
        mutation: UPDATE_FREIGHT_CONTRACT,
        variables: {
          freightContract,
        },
      })
      .pipe(
        map(({ data, error }) => {
          if (error) {
            return false;
          }
          return true;
        })
      );
  }

  /**
   * @description
   * Deletes a freight contract.
   *
   * @param customer0 string
   * @param customer1 string
   * @param billTo string
   *
   * @returns {Observable<boolean>}
   */
  deleteFreightContract(customer0: string, customer1: string, billTo: string): Observable<boolean> {
    return this.graphqlService
      .mutate<boolean>({
        mutation: DELETE_FREIGHT_CONTRACT,
        variables: {
          customer0,
          customer1,
          billTo,
        },
      })
      .pipe(
        map(({ data, error }) => {
          if (error) {
            return false;
          }
          return true;
        })
      );
  }
}
