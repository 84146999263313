import { Injectable } from '@angular/core';
import { IColumns } from '@haulynx/types';
import { Observable, of } from 'rxjs';
import { LoadFeedActionsService } from '../load-feed/load-feed-actions.service';

@Injectable({
  providedIn: 'root',
})
export class LoadGridService {
  loadSearchColumnsUnathenticated$: Observable<IColumns[]> = of([
    {
      label: 'Origin City',
      dataIndex: 'pickupLocation',
      isCustomCell: true,
      sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
    },
    {
      label: 'State',
      dataIndex: 'pickupState',
      isCustomCell: true,
      sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
    },
    {
      label: 'Destination City',
      dataIndex: 'deliveryLocation',
      isCustomCell: true,
      sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
    },
    {
      label: 'State',
      dataIndex: 'deliveryState',
      isCustomCell: true,
      sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
    },
    {
      label: 'Equipment',
      dataIndex: 'equipmentType',
      isCustomCell: true,
      isSortable: true,
      sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
    },
    {
      label: 'Pick-Up Date',
      dataIndex: 'pickupTimestamp',
      isCustomCell: true,
      sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
      initiallySortedBy: 'asc',
    },
    {
      label: 'Delivery Date',
      dataIndex: 'dropoffTimestamp',
      isCustomCell: true,
      sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
    },
    {
      label: 'TMW #',
      dataIndex: 'tmwNumber',
      isSortable: true,
      isCustomCell: true,
    },
    {
      label: 'Loaded Miles',
      dataIndex: 'distance',
      isCustomCell: true,
    },
    {
      label: 'Order #',
      dataIndex: 'order',
      isSortable: true,
      isCustomCell: true,
      sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
    },
    {
      label: 'Price',
      dataIndex: 'price',
      isCustomCell: true,
      isSortable: false,
    },
    {
      label: 'Weight',
      dataIndex: 'weight',
      isCustomCell: true,
      sortConvert: this.loadFeedActionsService.fullLoadFieldSort.bind(this.loadFeedActionsService),
    },
  ]);

  constructor(private loadFeedActionsService: LoadFeedActionsService) {}
}
