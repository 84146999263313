import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  DateTimeAdapter,
  MomentDateTimeAdapter,
  OwlDateTimeModule,
  OwlMomentDateTimeModule,
  OWL_DATE_TIME_FORMATS,
  OWL_DATE_TIME_LOCALE,
} from '@danielmoncada/angular-datetime-picker';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { LoadQuickLocationFormVm } from '@haulynx/services';
import { DATE_TIME_PICKER_FORMATS } from '@haulynx/types';
import { DatetimePickerModule } from '../datetime-picker/datetime-picker.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { LoadQuickLocationFormComponent } from './load-quick-location-form.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    OwlDateTimeModule,
    OwlMomentDateTimeModule,
    SpinnerModule,
    DatetimePickerModule,
    PipesModule,
  ],
  declarations: [LoadQuickLocationFormComponent],
  exports: [LoadQuickLocationFormComponent],
  providers: [
    LoadQuickLocationFormVm,
    {
      provide: OWL_DATE_TIME_FORMATS,
      useValue: DATE_TIME_PICKER_FORMATS,
    },
    {
      provide: DateTimeAdapter,
      useClass: MomentDateTimeAdapter,
      deps: [OWL_DATE_TIME_LOCALE],
    },
  ],
})
export class LoadQuickLocationFormModule {}
