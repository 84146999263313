<div class="load-bid-details">
  <div *ngIf="row.bidDetails; else noBids">
    <div>
      <mat-icon class="bid-icon">gavel</mat-icon>
      <span class="icon-text">{{ row.bidDetails?.offerCount }}</span>
    </div>
    <div class="second-line">
      <span class="offer">
        <mat-icon class="bid bid-icon arrow">arrow_downward</mat-icon>
        <span class="bid icon-text">{{
          row.bidDetails?.lowestBid?.price | showCurrency: 'USD':'symbol':(showCents ? null : '1.0-0')
        }}</span>
      </span>
      <span class="icon-text lowest-bid-name"> ( {{ row.bidDetails?.lowestBid?.carrier?.name }} ) </span>
    </div>
  </div>

  <ng-template #noBids>
    <div>
      <mat-icon class="bid-icon">gavel</mat-icon>
    </div>
    <mat-icon class="no-bid bid-icon arrow">arrow_downward</mat-icon>
    <span class="no-bid icon-text">No Offer</span>
  </ng-template>
</div>
