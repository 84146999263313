import { Store } from '@ngrx/store';
import { isEqual } from 'lodash';
import { Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { AppState } from '../../../main-store/app.reducers';
import { ISelectSelectors } from './selector';

export interface ISelectedModelSelectors {
  selected$: Observable<any>;
}

export function selectModel(store: Store<AppState>, selectors: ISelectSelectors): ISelectedModelSelectors {
  const selected$ = store.select(selectors.getSelected).pipe(distinctUntilChanged((prev, next) => isEqual(prev, next)));

  return {
    selected$,
  };
}
