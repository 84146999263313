import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'haulynx-white-outline-button',
  templateUrl: './white-outline-button.component.html',
  styleUrls: ['./white-outline-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WhiteOutlineButtonComponent {
  @Input() disabled = false;
  @Input() icon: string = null;
  @Input() svgIcon: string = null;
  @Input() iconPosition: 'before' | 'after' = 'before';
  @Input() loading = false;
  @Input() text = 'text';
  @Input() tooltipText = '';
  @Input() tabindex = 0;
  @Output() clicked = new EventEmitter<MouseEvent>();

  public buttonClick(event: MouseEvent) {
    this.clicked.emit(event);
  }
}
