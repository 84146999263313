<ng-container *ngIf="!isLoading; else loadingIndicator">
  <ng-container *ngIf="isBookable; else notBookable">
    <h1 mat-dialog-title>Book this Load?</h1>
    <div class="terms-and-conditions" mat-dialog-content>
      <h6>Terms And Conditions</h6>
      <p>By booking this load, you comply with the following Terms and Conditions.</p>
      <p>{{ termsAndConditions }}</p>
    </div>
    <div mat-dialog-actions>
      <button mat-button [mat-dialog-close]="false">Cancel</button>
      <button
        mat-raised-button
        class="affirmative"
        [mat-dialog-close]="true"
        cdkFocusInitial
        data-test="confirm-book-it"
      >
        Book It
      </button>
    </div>
  </ng-container>
</ng-container>

<ng-template #loadingIndicator>
  <app-spinner [active]="true"></app-spinner>
</ng-template>

<ng-template #notBookable>
  <h1 mat-dialog-title>Please Contact a Broker</h1>
  <div class="terms-and-conditions" mat-dialog-content>
    <p>You must be approved to book this load. Please get in touch with the following contact.</p>

    <div class="contact-row" *ngIf="!!data.load?.providerDetails?.regionalManagerEmail">
      <mat-icon svgIcon="haulynx-envelope-red"></mat-icon>
      &nbsp;&nbsp;
      <span>
        <a href="mailto:{{ data.load?.providerDetails?.regionalManagerEmail }}">
          {{ data.load?.providerDetails?.regionalManagerEmail }}
        </a>
      </span>
    </div>

    <div class="contact-row">
      <mat-icon svgIcon="haulynx-phone-red"></mat-icon>
      &nbsp;&nbsp;
      <span>
        <a href="tel:{{ data.load?.providerDetails?.regionalManagerPhone }}">
          {{ data.load?.providerDetails?.regionalManagerPhone || 'Unknown' }}
        </a>
      </span>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="false">Okay</button>
  </div>
</ng-template>
