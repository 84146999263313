export interface TrailerAssignment {
  trailerNumber: string;
  carrierDot?: string;
  carrierId?: string;
}

export interface CreateTrailerDto {
  trailerNumber: string;
  carrier: { id: string };
}
