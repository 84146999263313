import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {
  @Input() pageData: {
    currentPage: number;
    total: number;
    totalPages: number;
  };
  @Input() pageTotal: number;
  @Input() rowCount: number;
  @Input() loading = false;
  @Output() page = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  pageLeft(): void {
    if (this.pageData.currentPage > 1) {
      this.page.emit(false);
    }
  }

  pageRight(): void {
    if (this.pageData.currentPage < this.pageData.totalPages) {
      this.page.emit(true);
    }
  }

  getFirstNumber(): number {
    const number = this.pageData?.currentPage * this.pageTotal - this.pageTotal + 1;
    return !Number.isNaN(number) ? number : 0;
  }

  getSecondNumber(): number {
    const number = this.pageData?.currentPage * this.pageTotal - this.pageTotal + this.rowCount;
    return !Number.isNaN(number) ? number : 0;
  }
}
