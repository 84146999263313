import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckAccountGuard } from '@haulynx/guards';

const routes: Routes = [
  // Previously created routes from main page. Meant to be here instead of in root.
  // Do not add more routes here but add them in root routing module.
  {
    path: 'brokerinfo',
    loadChildren: () => import('@haulynx/containers').then((m) => m.BrokerInfoModule),
  },
  {
    path: 'carrierinfo',
    loadChildren: () => import('@haulynx/containers').then((m) => m.CarrierInfoModule),
  },
  {
    path: 'contact',
    loadChildren: () => import('@haulynx/containers').then((m) => m.ContactModule),
  },
  {
    path: 'shipperinfo',
    loadChildren: () => import('@haulynx/containers').then((m) => m.ShipperInfoModule),
  },
  {
    path: 'privacypolicy',
    loadChildren: () => import('@haulynx/containers').then((m) => m.PrivacyModule),
  },
  {
    path: 'termsofservice',
    loadChildren: () => import('@haulynx/containers').then((m) => m.TermofserviceModule),
  },
  // Previously created routes from main page. Meant to be here instead of in root
  // Do not add more routes here but add them in root routing module.

  {
    path: '',
    redirectTo: 'account/login',
    pathMatch: 'full',
  },
  { path: 'home', redirectTo: 'account/login' },
  { path: 'login', redirectTo: 'account/login' },
  { path: 'onboarding', redirectTo: 'account' },
  { path: 'signup', redirectTo: 'account/create' },
  {
    path: 'account',
    loadChildren: () => import('@haulynx/account').then((m) => m.AccountModule),
  },

  {
    path: 'admin',
    loadChildren: () => import('@haulynx/containers').then((m) => m.DashboardModule),
  },
  {
    path: 'sharedLocation',
    loadChildren: () => import('@haulynx/containers').then((m) => m.SharedLocationModule),
  },
  {
    path: 'load-details',
    loadChildren: () => import('@haulynx/containers').then((m) => m.LoadFeedDetailsModule),
  },
  {
    path: 'dotSignup',
    loadChildren: () => import('@haulynx/containers').then((m) => m.DotSignupModule),
  },
  // This is the new routing, all new features should be nested under the RootModule!!!
  {
    path: '',
    canActivate: [CheckAccountGuard],
    loadChildren: () => import('@haulynx/containers').then((m) => m.RootModule),
  },
  { path: '**', redirectTo: 'account/login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', paramsInheritanceStrategy: 'always' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
