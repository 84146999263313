import { Injectable } from '@angular/core';
import { CreateNeptuneTruckMutation } from '@haulynx/gql';
import { IPostTruckInput } from '@haulynx/types';
import { toString } from 'lodash';
import { GraphqlService } from '../graphql/graphql.service';

@Injectable({
  providedIn: 'root',
})
export class CreateTruckService {
  constructor(private graphqlService: GraphqlService) {}

  createTruck(newTruck: IPostTruckInput) {
    const { id, ...truck } = newTruck;
    const preferredLocationLon: string | null = this.processLatLon(truck.preferredLocationLon);
    const preferredLocationLat: string | null = this.processLatLon(truck.preferredLocationLat);
    return this.graphqlService.mutate<any>({
      mutation: CreateNeptuneTruckMutation,
      variables: {
        newTruck: { ...truck, preferredLocationLat, preferredLocationLon },
      },
    });
  }

  private processLatLon(val: number | string | null): string | null {
    if (val == 0 || !val) {
      return null;
    } else {
      return toString(val);
    }
  }
}
