import { Pipe, PipeTransform } from '@angular/core';
import { NotificationEventType, LoadIdentifierType, SecondaryNotificationEvent, WebNotification } from '@haulynx/types';
import { getLoadsServiceLoadAlternateId } from '@haulynx/utils';
@Pipe({
  name: 'notificationMessage',
})
export class NotificationMessagePipe implements PipeTransform {
  transform(notification: WebNotification): unknown {
    switch (notification.eventType) {
      case NotificationEventType.BROKER_PROFILE_RECOMMENDATION: {
        return `
        You have a repeated load match for
        <span class="highlight-label">
          ${notification.targetCarrierDisplay ?? 'no name'}
        </span>
        `;
      }
      case NotificationEventType.POSTED_TRUCK_RECOMMENDATION: {
        return `
        You have a new match for
        <span class="highlight-label">
          ${notification.targetCarrierDisplay ?? 'no name'}
        </span>
        `;
      }
      case NotificationEventType.LOAD_MATCH: {
        return `
        You have a new match for
        <span class="highlight-label">
          ${notification.targetCarrierDisplay ?? 'no name'}
        </span>
        `;
      }
      case NotificationEventType.LOAD_BROKER_ASSIGNMENT: {
        if (notification.secondaryEventType === SecondaryNotificationEvent.BROKER_ASSIGNED_LOAD_BY_USER) {
          let string = `You have a new load assignment`;
          if (notification.createdBy === notification.recipient) {
            string += ` from
              <span class="highlight-label">
                ${notification.targetUserDisplay ? notification.targetUserDisplay : 'no name'}
              </span>`;
          }
          return string;
        } else if (notification.secondaryEventType === SecondaryNotificationEvent.BROKER_UNASSIGNED_LOAD_BY_USER) {
          return `
          You have been removed from a load by
          <span class="highlight-label">
            ${notification.targetUserDisplay ? notification.targetUserDisplay : 'no name'}
          </span>
          `;
        }
      }
      case NotificationEventType.AUTO_ASSIGN_BROKER: {
        return `
          New self-serve booking from
          <span class="highlight-label">
            ${notification.targetCarrierDisplay ?? 'no name'}
          </span>
          for load
          <span class="highlight-label">
            ${
              getLoadsServiceLoadAlternateId(
                notification?.loadsServiceLoad,
                LoadIdentifierType.BILL_OF_LADING,
                false
              ) || ''
            }
          </span>
        `;
      }
    }

    return null;
  }
}
