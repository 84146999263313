import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Carrier, equipmentTypes, hosRules, Info, SaferWatchData, states } from '@haulynx/types';

@Component({
  selector: 'app-register-carrier',
  templateUrl: './register-carrier.component.html',
  styleUrls: ['./register-carrier.component.scss'],
})
export class RegisterCarrierComponent {
  @Input() carrier: Carrier;

  carrierForm: FormGroup;
  states = states;
  hosRules = hosRules;
  equipmentTypes = equipmentTypes;

  constructor(private http: HttpClient, private fb: FormBuilder) {
    this.createForm();
  }

  createForm() {
    this.carrierForm = this.fb.group({
      name: ['', Validators.required],
      addressStreet: '',
      addressCity: '',
      addressState: '',
      phone: '',
      email: '',
      hosRule: 0,
      dot: '',
      temperature: '',
      hazmatCapable: '',
      insurance: '',
      weight: '',
      equipmentType: [],
      isLoadDistributionEnabled: false,
      surveyInfo: [],
    });
  }

  onSubmit() {
    this.carrier = this.prepareCarrier();
    this.http
      .post('/api/carriers', JSON.stringify(this.carrier))
      .toPromise()
      .then((resp) => {
        alert('new carrier created');
      })
      .catch((err) => {
        alert('Unable to create carrier');
      });
  }

  prepareCarrier(): Carrier {
    const formModel = this.carrierForm.value;
    const newCarrier: Carrier = new Carrier({
      id: '',
      isHaulynxUser: false,
      name: formModel.name as string,
      addressStreet: formModel.addressStreet as string,
      addressStreet2: formModel.addressStreet2 as string,
      addressCity: formModel.addressCity as string,
      addressState: formModel.addressState as string,
      addressCountry: formModel.addressCountry as string,
      phone: formModel.phone as string,
      email: formModel.email as string,
      dot: formModel.dot as string,
      temperature: formModel.temperature as string,
      hazmatCapable: formModel.hazmatCapable as boolean,
      insurance: formModel.insurance as string,
      weight: formModel.weight as string,
      equipmentType: formModel.equipmentType as string[],
      isLoadDistributionEnabled: formModel.isLoadDistributionEnabled as boolean,
      maximumDeadMiles: formModel.maximumDeadMiles as number,
      minimumLoadValue: formModel.minimumLoadValue as number,
      rank: formModel.rank as number,
      saferWatchData: formModel.saferWatchData as SaferWatchData,
      surveyInfo: formModel.surveyInfo as Info[],
    });

    return newCarrier;
  }
}
