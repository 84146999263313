import { AsyncManagerType, IAsyncEntityState, PageAndSort, PaginatedData } from '@haulynx/types';
import { Actions, CreateEffectMetadata } from '@ngrx/effects';
import { ReducerTypes } from '@ngrx/store';
import { toUpper } from 'lodash';
import { Observable } from 'rxjs';

export abstract class AsyncEntityActionFactory<ActionPayloadType, EntityType, ErrorPayloadType> {
  public sideEffect: (
    payload: string | ActionPayloadType | { query: ActionPayloadType; pageAndSort: PageAndSort }
  ) => Observable<EntityType | PaginatedData<EntityType>>;
  /**
   * State namespace
   */
  private _namespace: string;
  /**
   * The name of this action
   */
  private _name: string;

  public abstract managerType: AsyncManagerType;
  public abstract action;
  public abstract actionSuccess;

  constructor(
    namespace: string,
    name: string,
    sideEffect: (
      payload: ActionPayloadType | { query: ActionPayloadType; pageAndSort: PageAndSort }
    ) => Observable<EntityType | PaginatedData<EntityType>>
  ) {
    this._namespace = namespace;
    this._name = name;
    this.sideEffect = sideEffect;
  }

  get name(): string {
    return this._name;
  }

  get nameSuccess(): string {
    return `${this.name} success`;
  }

  get nameError(): string {
    return `${this.name} error`;
  }

  get nameCacheHit(): string {
    return `${this.name} cache hit`;
  }

  get type(): string {
    return `[${toUpper(this._namespace)}] ${this.name}`;
  }

  get typeSuccess(): string {
    return `[${toUpper(this._namespace)}] ${this.nameSuccess}`;
  }

  get typeError(): string {
    return `[${toUpper(this._namespace)}] ${this.nameError}`;
  }

  /**
   * Action type emitted when an async side effect is not required because
   * the state contains relevent cached data.
   */
  get typeCacheHit(): string {
    return `[${toUpper(this._namespace)}] ${this.nameCacheHit}`;
  }

  public abstract getReducers(): ReducerTypes<IAsyncEntityState<EntityType>, any>[];
  public abstract createEffect(
    actions$: Actions,
    options: { [key: string]: unknown }
  ): Observable<any> & CreateEffectMetadata;
}
