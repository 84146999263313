import { Injectable } from '@angular/core';
import { CarrierInsuranceAndSafety, CarrierKpiMetrics, CarrierUsx, IPostTruck, IPostTruckInput } from '@haulynx/types';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { List } from 'immutable';
import { combineLatest, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppState } from '../../main-store/app.reducers';
import { formModel, IFormModelSelector } from '../../shared/store/form/model';
import { DispatchAction } from '../../shared/store/helpers/action';
import { ISearchModelSelectors, searchModel } from '../../shared/store/search/model';
import {
  CarrierDashboardActions,
  CarrierDashboardLanesActions,
  CarrierDashboardTrucksActions,
  CarrierDashboardTrucksActionTypes,
} from './carriers.action';
import {
  carrierDashboardFormSelector,
  carrierDashboardLanesSearchSelector,
  carrierDashboardSearchSelector,
  carrierDashboardTrucksSearchSelector,
  carrierRouteSelector,
  getCarrierInsuranceAndSafetySelector,
  getCarrierKpiMetricsSelector,
  getCarriersStatusSelector,
  getIsLoadingCarrierInsuranceAndSafetySelector,
  getIsLoadingCarrierKpiMetricsSelector,
  getIsLoadingCarriersStatusSelector,
  getIsLoadingPostTruckSelector,
  getLoadingMapTruckIdsSelectors,
  isLoadingRouteSelector,
} from './carriers.selectors';

@Injectable({
  providedIn: 'root',
})
export class CarrierDashboardModel {
  recommendationSearch: ISearchModelSelectors = searchModel(this.store, carrierDashboardSearchSelector);
  lanesSearch: ISearchModelSelectors = searchModel(this.store, carrierDashboardLanesSearchSelector);
  trucksSearch: ISearchModelSelectors = searchModel(this.store, carrierDashboardTrucksSearchSelector);
  trucks$: Observable<IPostTruck[]> = this.trucksSearch.entities$.pipe(map((list) => list.toArray()));
  isLoadingTruckSearch$: Observable<boolean> = combineLatest([
    this.trucksSearch.isLoading$,
    this.trucksSearch.entities$,
  ]).pipe(
    map(([isLoading, entities]: [boolean, List<any>]) => {
      return isLoading && !entities.size;
    })
  );
  carrierForm: IFormModelSelector = formModel(this.store, carrierDashboardFormSelector);
  routes$: Observable<any> = this.store.select(carrierRouteSelector);
  isLoadingRoutes$: Observable<boolean> = this.store.select(isLoadingRouteSelector);

  isLoadingCarrierKpiMetrics$: Observable<boolean> = this.store.select(getIsLoadingCarrierKpiMetricsSelector);
  isLoadingPostTruck$: Observable<boolean> = this.store.select(getIsLoadingPostTruckSelector);
  carrierKpiMetrics$: Observable<CarrierKpiMetrics> = this.store.select(getCarrierKpiMetricsSelector);
  carrierInsuranceAndSafety$: Observable<CarrierInsuranceAndSafety> = this.store.select(
    getCarrierInsuranceAndSafetySelector
  );
  isLoadingCarrierInsuranceAndSafety$: Observable<boolean> = this.store.select(
    getIsLoadingCarrierInsuranceAndSafetySelector
  );
  isLoadingCarriersStatus$: Observable<boolean> = this.store.select(getIsLoadingCarriersStatusSelector);
  carriersStatus$: Observable<CarrierUsx> = this.store.select(getCarriersStatusSelector);
  loadingMapTruckIds$: Observable<{ [key: string]: boolean }> = this.store.select(getLoadingMapTruckIdsSelectors);
  createTruckSuccess$: Observable<IPostTruck> = this.actions$.pipe(
    ofType(CarrierDashboardTrucksActionTypes.CREATE_POSTED_TRUCK_SUCCESS),
    map((action: DispatchAction) => action.payload)
  );

  constructor(private store: Store<AppState>, private actions$: Actions) {}
  searchTrucks(filter?) {
    this.store.dispatch(CarrierDashboardTrucksActions.search(filter));
  }

  searchCarrierKpiMetrics(params: { dot: string; dateTime: string }) {
    this.store.dispatch(CarrierDashboardActions.getKpiMetrics(params));
  }

  searchRecommended(filter?) {
    this.store.dispatch(CarrierDashboardActions.search(filter));
  }

  searchLanes(filter?) {
    this.store.dispatch(CarrierDashboardLanesActions.search(filter));
  }

  setStateKey(key) {
    this.store.dispatch(CarrierDashboardActions.setStateKey(key));
  }

  get(data) {
    this.store.dispatch(CarrierDashboardActions.get(data));
  }

  getInsuranceAndSafety(dot: number) {
    this.store.dispatch(CarrierDashboardActions.getCarrierInsuranceAndSafety(dot));
  }

  getLoadRoute(data) {
    this.store.dispatch(CarrierDashboardActions.getRoutes(data));
  }

  createPostedTruck(data: IPostTruckInput | IPostTruckInput[]) {
    this.store.dispatch(CarrierDashboardTrucksActions.createPostedTruck(data));
  }

  removePostedTruck(data: { id: string }) {
    this.store.dispatch(CarrierDashboardTrucksActions.removePostedTruck(data));
  }

  getCarriersStatus(dot: string | number) {
    this.store.dispatch(CarrierDashboardActions.getCarrierStatus(dot));
  }
}
