import { CustomerAttributes } from '../web-interfaces';
import { LoadsServiceNote } from './loads-service-note';

export class LoadsServiceCustomer {
  id: string;
  name: string;
  company: string;
  number: string;
  billTo: string;
  customerTypes: string[];
  phone: string;
  status: string;
  notes: LoadsServiceNote[];
  customerAttributes: CustomerAttributes[];
  shippingHours?: HoursOfOperation[];
  receivingHours?: HoursOfOperation[];
}

export class HoursOfOperation {
  dayOfWeek?: string;
  hourType?: string;
  openTime?: string;
  closeTime?: string;
  isFirstComeFirstServe?: boolean;
}

export class LoadsServiceCustomerInput {
  name?: string;
  company?: string;
  number?: string;
  billTo?: string;
  customerTypes?: string[];
  phone?: string;
  status?: string;
  notes?: LoadsServiceNote[];
}

export class LoadsServiceCustomerAddress {
  address: string = null;
  city: string = null;
  state: string = null;
  zipcode: string = null;
}

export class LoadsServiceCustomerAddressInput {
  address: string = null;
  city: string = null;
  state: string = null;
  zipcode: string = null;
}
