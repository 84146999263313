import { Component, ChangeDetectionStrategy, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationsService } from '@haulynx/services';
import { LoadEntityService } from '@haulynx/store';
import {
  LoadDocumentUploadDialogDropdownType,
  LoadDocumentUploadDialogOptions,
  LoadsServiceDocumentErrorResponse,
  LoadsServiceDocumentPayload,
} from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'haulynx-load-document-upload-dialog',
  templateUrl: './load-document-upload-dialog.component.html',
  styleUrls: ['./load-document-upload-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadDocumentUploadDialogComponent {
  alive = aliveWhile();

  selectedFiles: File[] = [];
  selectedDropdownType: LoadDocumentUploadDialogDropdownType = null;

  isUploading$ = new BehaviorSubject<boolean>(false);

  constructor(
    private notifications: NotificationsService,
    private loadEntityService: LoadEntityService,
    @Optional() private dialogRef: MatDialogRef<LoadDocumentUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { options: LoadDocumentUploadDialogOptions }
  ) {}

  onFilesSelected($files: File[]): void {
    this.selectedFiles = $files;
  }

  removeFile(index: number): void {
    this.selectedFiles.splice(index, 1);
  }

  close(): void {
    this.dialogRef.close(false);
  }

  upload(): void {
    this.isUploading$.next(true);

    const { carrierId, loadId, token } = this.data.options;
    const documentPayload: LoadsServiceDocumentPayload = {
      file: this.selectedFiles[0],
      title: this.selectedFiles[0].name,
      tenant_id: carrierId,
      type: this.selectedDropdownType.key,
      tags: this.selectedDropdownType.tags,
      lookup_ids: {
        load_id: loadId,
      },
    };

    this.loadEntityService
      .uploadFile(documentPayload, token)
      .pipe(takeUntil(this.alive))
      .subscribe({
        next: () => {
          this.dialogRef.close(true);
        },
        error: async (err) => {
          const blob = err as Blob;
          const error = JSON.parse(await blob.text()) as LoadsServiceDocumentErrorResponse;

          this.notifications.error(error.message, 'Failed to Upload File');
          this.isUploading$.next(false);
        },
      });
  }
}
