<div class="carousel-wrapper" [ngStyle]="carouselWrapperStyle">
  <div class="carousel-inner" #carousel>
    <div *ngFor="let item of items" class="carousel-item">
      <ng-container [ngTemplateOutlet]="item.tpl"></ng-container>
    </div>
  </div>
  <div *ngIf="items.length > 1" class="carousel-nav" fxLayoutAlign="center center" fxLayout="row">
    <ng-container *ngFor="let item of items; let i = index">
      <mat-icon [ngClass]="{ active: (currSlide$ | async) === i }" svgIcon="circle" (click)="bubbleClick(i)"></mat-icon>
    </ng-container>
  </div>
</div>
