import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import {
  AddDriverComponent,
  AddTrailerComponent,
  AddTruckComponent,
  AssignLoadCarrierContainerComponent,
} from '@haulynx/components';
import { LocalStoreService, MapRoutesService, TitleService, WindowRef } from '@haulynx/services';
import {
  AppModel,
  CommonEntities,
  LoadDetailsModel,
  LoadEntityService,
  PaymentTypeStateModel,
  UserEntityService,
} from '@haulynx/store';
import {
  AssignDriverForm,
  AssignLoadCarrierData,
  AssignLoadCarrierFormMode,
  BookStatus,
  DeviceLocation,
  DispatchForm,
  DriverAssignment,
  equipmentTypes,
  FeatureFlag,
  FFState,
  LoadRouteSource,
  LoadsServiceLoad,
  LoadStep,
  LoadStepType,
  loadStepType,
  LocationForm,
  TrailerAssignment,
  TruckAssignment,
} from '@haulynx/types';
import {
  AddressPosition,
  aliveWhile,
  getLoadRoute,
  getLoadServiceLoadWayPointsCoordinates,
  hasValidCoordinates,
  splitAddress,
} from '@haulynx/utils';
import { concat, get } from 'lodash';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { catchError, filter, first, pairwise, takeUntil, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'app-active-load-details-container',
  templateUrl: './active-load-details-container.component.html',
  styleUrls: ['./active-load-details-container.component.scss'],
})
export class ActiveLoadDetailsContainerComponent implements OnDestroy, OnInit, OnChanges {
  @Input() loadId = null;
  @Input() loadOverview = false;
  @Input() finalledLoad = false;
  alive = aliveWhile();
  load: LoadsServiceLoad = null;
  route: LoadRouteSource[] = null;
  equipments = equipmentTypes;
  currentStatus = '';
  lastStep: LoadStep = null;
  activeStep: LoadStep = null;
  trucksAndDevice$ = new BehaviorSubject([]);
  carrierId: string;
  isBookedLoad = false;
  isPayLineEdit = false;
  showEditPriceButton = false;
  featureFlags: FFState = null;
  featureFlag = FeatureFlag;
  userEmail: string = null;
  steps: LoadStep[];
  referrer: string;
  loadStepType: LoadStepType = loadStepType;

  constructor(
    private activatedRoute: ActivatedRoute,
    private appModel: AppModel,
    private dialog: MatDialog,
    private loadEntityService: LoadEntityService,
    private localStoreService: LocalStoreService,
    private mapRoutesService: MapRoutesService,
    private titleService: TitleService,
    private windowRef: WindowRef,
    public commonEntities: CommonEntities,
    private userEntityService: UserEntityService,
    public loadDetailsModel: LoadDetailsModel,
    public paymentTypeStateModel: PaymentTypeStateModel
  ) {}

  setActiveStep(loadId: string, action: LoadStep): void {
    const activeStep = { key: loadId, stepId: action.id };

    this.loadDetailsModel.setActiveStep(activeStep);
  }

  onAddDriver(): void {
    this.dialog
      .open(AddDriverComponent, {
        data: { carrierId: this.carrierId },
      })
      .afterClosed()
      .pipe(withLatestFrom(this.loadDetailsModel.state$), first())
      .subscribe(([driverAssignment, load]: [DriverAssignment, LoadsServiceLoad]) => {
        if (driverAssignment) {
          driverAssignment.carrierId = load.carrier.id || '';
          driverAssignment.carrierDot = load.carrier.dot;
          driverAssignment.carrierName = load.carrier.name || '';

          this.loadDetailsModel.createDriver(driverAssignment);
        }
      });
  }

  onAddTruck(): void {
    this.dialog
      .open(AddTruckComponent, {
        data: { carrierId: this.carrierId },
      })
      .afterClosed()
      .subscribe((truckAssignment: TruckAssignment) => {
        if (truckAssignment) {
          this.loadDetailsModel.createTruck(truckAssignment);
        }
      });
  }

  onAddTrailer(): void {
    this.dialog
      .open(AddTrailerComponent, {
        data: { carrierId: this.carrierId },
      })
      .afterClosed()
      .subscribe((trailerAssignment: TrailerAssignment) => {
        if (trailerAssignment) {
          this.loadDetailsModel.createTrailer(trailerAssignment);
        }
      });
  }

  updateDriverFormAssigned(carrierId: string, loadId: string): void {
    const query = { carrierId };
    const key = loadId;

    this.commonEntities.graphQlDrivers.search({ key, query });
    this.commonEntities.graphQlTrailers.search({ key, query });
    this.commonEntities.graphQlTrucks.search({ key, query });
    this.commonEntities.graphQlDevices.search({ key, query });
  }

  onChangeLoadStatus(status: string): void {
    this.currentStatus = status;
  }

  submitDriver(loadId: string, data: AssignDriverForm): void {
    this.loadDetailsModel.assignToDriver(loadId, data);
  }

  submitLocation(loadId: string, locationId: string, data: LocationForm): void {
    this.loadDetailsModel.confirmLocation(loadId, locationId, data);
  }

  submitDispatch(loadId: string, data: DispatchForm): void {
    this.loadDetailsModel.dispatchLoad(loadId, data);
  }

  onViewAllNotes(load: LoadsServiceLoad): void {
    console.log('view all notes', load.id);
  }

  onEditPayDetails(loadId: string): void {
    const carrierDot = this.load.carrier && this.load.carrier.dot;
    const data: AssignLoadCarrierData = {
      loadId,
      carrierDot,
      formMode: AssignLoadCarrierFormMode.EDIT,
      userEmail: this.userEmail,
    };
    this.dialog.open(AssignLoadCarrierContainerComponent, { data });
  }

  onLoadDetails() {
    const url = `/dashboard/load-feeds/my/${this.load.id}`;
    this.windowRef.getNativeWindow().open(url, '_blank');
  }

  ngOnDestroy(): void {
    this.localStoreService.clear(this.load?.id);
    this.alive.destroy();
    this.titleService.resetTitle();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { loadId } = changes;
    if (loadId && loadId.currentValue) {
      this.loadDetailsModel.setStateKey({ key: loadId.currentValue, namespace: 'LOAD_ACTIVE_DETAILS' });
      this.loadDetailsModel.get({
        key: loadId.currentValue,
        id: loadId.currentValue,
        source: 'loads',
        isLatest: false,
      });
    }
  }

  ngOnInit(): void {
    combineLatest([this.commonEntities.graphQlTrucks.entities$, this.commonEntities.graphQlDevices.entities$])
      .pipe(takeUntil(this.alive))
      .subscribe(([trucks, device]) => {
        const truckAndDevices = concat(trucks.toArray(), device.toArray());

        this.trucksAndDevice$.next(truckAndDevices);
      });
    this.activatedRoute.params.pipe(takeUntil(this.alive)).subscribe((params) => {
      const { id } = params;

      if (id) {
        this.loadDetailsModel.setStateKey({ key: id, namespace: 'LOAD_ACTIVE_DETAILS' });
        this.loadDetailsModel.get({ key: id, id: id, source: 'loads', isLatest: false });
        this.localStoreService.get<string>(id).then((data) => {
          this.referrer = data ?? '/loads/search';
        });
      }
    });

    this.userEntityService.featureFlags$.pipe(takeUntil(this.alive)).subscribe((featureFlag) => {
      this.featureFlags = featureFlag;
      this.isPayLineEdit = featureFlag && featureFlag[FeatureFlag.PAY_LINE_EDIT];
      this.showEditPriceButton = featureFlag && featureFlag[FeatureFlag.LOAD_ACTIVE_EDIT_PRICE];
    });
    this.loadDetailsModel.state$.pipe(takeUntil(this.alive)).subscribe((load: LoadsServiceLoad) => {
      this.carrierId = get(load, 'carrier.id');

      const loadId: string = get(load, 'id');

      if (loadId) {
        this.loadDetailsModel.getTruckLoadLocation({ loadId });
      }

      this.isBookedLoad = load && load.bookStatus === BookStatus.BOOKED;
      this.load = load;
      if (!this.loadOverview) {
        this.setTitle(load);
      }
    });

    const deviseLocations$ = this.loadDetailsModel.deviceLocations$.pipe(catchError(() => of(null)));

    combineLatest([deviseLocations$, this.loadDetailsModel.state$])
      .pipe(takeUntil(this.alive))
      .subscribe(([deviceLocations, load]: [DeviceLocation, LoadsServiceLoad]) => {
        const id: string = get(load, 'id', null);
        const deviceLocation = get(deviceLocations, id, null);

        if (id && !deviceLocation) {
          const coordinates = getLoadServiceLoadWayPointsCoordinates(load);

          this.getLoadRoute(coordinates, id);
        } else {
          const truckLocation = this.mapRoutesService.getTruckLocation(deviceLocation);
          const isTruckLocationValid = this.mapRoutesService.isGpsLocationValid(truckLocation);
          const isLoadLocationValid = hasValidCoordinates(load);

          if (id && isTruckLocationValid && isLoadLocationValid) {
            const wayPoints = this.mapRoutesService.addTruckByLocationStatus(load, truckLocation);
            const coordinates = this.mapRoutesService.normalizeWayPoints(wayPoints);

            this.getLoadRoute(coordinates, id);
          }
        }
      });

    combineLatest([this.loadDetailsModel.routes$, this.loadDetailsModel.form.state$, deviseLocations$])
      .pipe(takeUntil(this.alive))
      .subscribe(([routes, load, deviceLocations]) => {
        if (routes) {
          const id = get(load, 'id', null);
          const deviceLocation = get(deviceLocations, id, null);
          const truckLocation = this.mapRoutesService.getTruckLocation(deviceLocation);

          this.route = getLoadRoute(routes, load, true, truckLocation);
        }
      });

    this.loadDetailsModel.steps$.pipe(takeUntil(this.alive)).subscribe((steps: LoadStep[]) => {
      this.steps = steps;
    });

    this.loadDetailsModel.activeStep$
      .pipe(takeUntil(this.alive), withLatestFrom(this.loadDetailsModel.id$, this.loadDetailsModel.carrierId$))
      .subscribe(([activeStep, key, carrierId]) => {
        this.activeStep = activeStep;
        if (this.loadOverview && !this.finalledLoad && this.activeStep?.id === 'assigned') {
          this.activeStep = { ...activeStep, id: 'dispatched', type: 'dispatched' };
        }
        if (this.activeStep?.id === 0) {
          this.lastStep = null;
        } else {
          for (let i = 0; i < this.steps.length; i++) {
            if (this.steps[i].id === this.activeStep?.id) {
              this.lastStep = this.steps[i - 1];
            }
          }
        }

        if (activeStep && activeStep.type === this.loadStepType.ASSIGNED) {
          this.updateDriverFormAssigned(carrierId, key);
        }
      });

    this.paymentTypeStateModel.isSaving$
      .pipe(
        pairwise(),
        filter(([previousLoading, currentLoading]) => !currentLoading && previousLoading),
        withLatestFrom(this.loadDetailsModel.form.state$)
      )
      .subscribe(([, load]: [[boolean, boolean], LoadsServiceLoad]) => {
        const key = load && load.id;

        if (key) {
          this.loadDetailsModel.refresh({ key: key, id: key, source: 'loads' });
        }
      });

    this.appModel.userEmail$.pipe(takeUntil(this.alive)).subscribe((userEmail) => (this.userEmail = userEmail));

    this.loadDetailsModel.onActiveLoadSuccesses$.pipe(takeUntil(this.alive)).subscribe(() => {
      if (this.loadId) {
        this.loadEntityService.getLoadByIdManager.dispatch(this.loadId);
      }
    });
  }

  getLoadRoute(coordinates: string, key: string): void {
    if (coordinates) {
      this.loadDetailsModel.getLoadRoute({ key, coordinates });
    } else {
      this.loadDetailsModel.showEmptyRoute(key);
    }
  }

  private setTitle(load: LoadsServiceLoad) {
    if (!load) return;

    const [pickup, dropoff] = load.locations;
    const origin = splitAddress(pickup && pickup.address, AddressPosition.CITY, true);
    const destination = splitAddress(dropoff && dropoff.address, AddressPosition.CITY, true);
    const originState = splitAddress(pickup.address, AddressPosition.STATE, true);
    const destinationState = splitAddress(dropoff.address, AddressPosition.STATE, true);
    const originNoVowels =
      origin.substring(0, 1).toUpperCase() +
      origin
        .substring(1)
        .replace(/[aeiouyAEIOUY]/g, '')
        .toUpperCase();
    const destinationNoVowels =
      destination.substring(0, 1).toUpperCase() +
      destination
        .substring(1)
        .replace(/[aeiouyAEIOUY]/g, '')
        .toUpperCase();
    this.titleService.setTitle(
      `${originNoVowels},${originState}→${destinationNoVowels},${destinationState} - Load Details - Haulynx`
    );
  }
}
