import { AppSettingsActionTypes } from '../../main-store/app-settings.actions';
import { DispatchAction } from '../../shared/store/helpers/action';
import { CarrierDetailsActionTypes } from './carrier-details.actions';
import { CarrierDetailsState, initialLoadDetails } from './carrier-details.state';

export function carrierDetailsReducer(
  state: CarrierDetailsState = initialLoadDetails,
  action: DispatchAction
): CarrierDetailsState {
  switch (action.type) {
    case AppSettingsActionTypes.LOG_OUT:
      return initialLoadDetails;

    case CarrierDetailsActionTypes.GET: {
      const { carrierId } = action.payload;
      return {
        ...state,
        isLoading: state.isLoading.set(carrierId, true),
      };
    }

    case CarrierDetailsActionTypes.GET_SUCCESS: {
      const { carrierId, carrier } = action.payload;
      return {
        ...state,
        isLoading: state.isLoading.set(carrierId, false),
        state: state.state.set(carrierId, carrier),
      };
    }

    case CarrierDetailsActionTypes.GET_ERROR: {
      const { carrierId, carrier } = action.payload;

      return {
        ...state,
        isLoading: state.isLoading.set(carrierId, false),
        state: state.state.set(carrierId, null),
      };
    }

    case CarrierDetailsActionTypes.STATE_KEY: {
      const { key } = action.payload;
      return {
        ...state,
        stateKey: key,
      };
    }

    default:
      return state;
  }
}
