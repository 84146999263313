<form class="form assign-load-carrier" [formGroup]="form">
  <div class="assign-load-carrier__order-info">
    <div class="assign-load-carrier__label">Order Info</div>
    <div class="assign-load-carrier__controls">
      <app-order-info-table [form]="orderInfo" [brokers]="brokers$ | async"></app-order-info-table>
      <app-order-info-notes [form]="editLoad"></app-order-info-notes>
    </div>
  </div>
  <div class="assign-load-carrier__carrier-info">
    <div class="assign-load-carrier__label">Carrier Info</div>
    <div class="assign-load-carrier__controls">
      <app-carrier-info-section
        [carrier]="carrier$ | async"
        [carrierAdmin]="carrierAdmin$ | async"
        [drivers]="drivers$ | async"
        [trucks]="trucks$ | async"
        [trailers]="trailers$ | async"
        (carrierSearch)="onSearchCarrier($event)"
        [carrierForm]="carrierInfo"
        [assignmentForm]="assignmentInfo"
        [carriers]="carriers$ | async"
        [featureFlags]="featureFlagState"
        [isLoadingCarriers]="isLoadingCarriers"
        [isLoadingDrivers]="isLoadingDrivers"
        [isLoadingTrucks]="isLoadingTrucks"
        [isLoadingTrailers]="isLoadingTrailers"
        [driverToPopulate]="driverToPopulate"
        [truckToPopulate]="truckToPopulate"
        [trailerToPopulate]="trailerToPopulate"
        (addDriver)="onAddDriver()"
        (addTruck)="onAddTruck()"
        (addTrailer)="onAddTrailer()"
        (trackingTypeChange)="onTrackingChange()"
        (carrierSelect)="onSelectCarrier($event)"
      ></app-carrier-info-section>
    </div>
  </div>
  <div class="assign-load-carrier__payment-info">
    <div class="assign-load-carrier__label">Payment Details</div>
    <div class="assign-load-carrier__controls">
      <app-payment-details
        *ngIf="featureFlagState[payLineFeatureFlag]"
        [form]="payments"
        [formData]="paymentFormData$ | async"
        (addPayType)="onAddPayType()"
        (deletePayType)="onDeletePayType($event)"
        (editPayType)="onEditPayType($event)"
        (newTotal)="onNewPayTypeTotal($event)"
      ></app-payment-details>
      <app-pay-line-price [price]="price$ | async" [revenue]="revenue$ | async" [total]="total$ | async">
      </app-pay-line-price>
      <br />
      <ng-container *appFeatures="rateconFeatureFlag">
        <div class="assign-load-carrier__label">Share Ratecon</div>
        <app-chip-selector
          [emails]="rateConEmails$ | async"
          (dataChange)="onRateConEmailsChange($event)"
        ></app-chip-selector>
      </ng-container>
    </div>
  </div>
  <div class="assign-load-carrier__dispatch-info">
    <div class="assign-load-carrier__label">Dispatch Driver</div>
    <div class="assign-load-carrier__controls">
      <app-load-dispatch-form
        [formCls]="'dispatch-form-row'"
        [formControlCls]="'app-dark-field'"
        [showSaveButton]="false"
        [form]="dispatchInfo"
      ></app-load-dispatch-form>
    </div>
  </div>
  <div class="assign-load-carrier__form-actions">
    <div class="assign-load-carrier__form-errors">
      <div class="shortErrors">
        <span *ngIf="form.get('dispatchInfo').errors?.locationIsEmpty && formSubmitted"
          >* Empty location is required</span
        >
        <span *ngIf="form.get('dispatchInfo').errors?.timeIsEmpty && formSubmitted">* Time of empty" is required</span>
        <span *ngIf="form.get('assignmentInfo')?.get('driverId')?.errors?.required && formSubmitted"
          >* The Driver field is required. Select a Driver</span
        >
        <span *ngIf="form.get('assignmentInfo')?.get('truckId')?.errors?.required && formSubmitted"
          >* The Truck field is required. Select a Truck</span
        >
      </div>
      <span *ngIf="form.get('assignmentInfo')?.get('phone')?.errors?.required && formSubmitted"
        >* The Driver Phone Number field is required. Please add a new driver to include a phone number or choose a
        different tracking method.</span
      >
    </div>

    <button
      mat-raised-button
      class="hlx-button-command hlx-button-command--cancel hlx-button-command--border-white"
      (click)="onCancel($event)"
    >
      Cancel
    </button>
    <!--    <button class="hlx-button-command hlx-button-command&#45;&#45;border-white" (click)="onEmailOffer($event, form.value)">Email Offer to Carrier</button>-->
    <button
      mat-raised-button
      class="hlx-button-command hlx-button-command--book"
      (click)="onSave($event)"
      [disabled]="isDisableBookCarrierButton || isLoading || isLoadingCreateEntity"
      *ngIf="formMode === assignLoadCarrierFormMode.NEW"
    >
      <mat-spinner [diameter]="18" *ngIf="isLoading" mode="indeterminate"></mat-spinner>
      <span matTooltipPosition="above" [matTooltip]="isDisableBookCarrierButton ? 'Load already booked' : ''"
        >Book for Carrier</span
      >
    </button>

    <button
      mat-raised-button
      *ngIf="formMode === assignLoadCarrierFormMode.EDIT_PAY_LINE"
      class="hlx-button-command hlx-button-command--book"
      (click)="onUpdate($event)"
    >
      Save
    </button>
  </div>
</form>
