import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@haulynx/modules';
import { HeaderEndbuttonsHelpComponent } from './header-endbuttons-help.component';

@NgModule({
  declarations: [HeaderEndbuttonsHelpComponent],
  imports: [CommonModule, MaterialModule],
  exports: [HeaderEndbuttonsHelpComponent],
})
export class HeaderEndbuttonsHelpModule {}
