import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PermissionsService } from '@haulynx/services';
import { AppModel, CommonEntities } from '@haulynx/store';
import { buttonTypes, CreateOptionsBts, Device, LoadsServiceLoad, Trailer, User } from '@haulynx/types';
import { aliveWhile, listToArray } from '@haulynx/utils';
import { find, get } from 'lodash';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-load-active-accept',
  templateUrl: './load-active-assign.component.html',
  styleUrls: ['./load-active-assign.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadActiveAssignComponent implements OnInit, OnDestroy {
  alive = aliveWhile();
  title = 'ASSIGN LOAD';
  allButtons = { ...buttonTypes };
  buttons$ = new BehaviorSubject([this.allButtons.ASSIGN]);
  isDataLoaded$ = new BehaviorSubject<boolean>(true);
  showWarning = false;
  isOnlyADriver = false;
  user: User;

  drivers: User[] = [];
  trucks: Device[] = [];
  trailers: Trailer[] = [];
  form: FormGroup;
  createOptionsBts: CreateOptionsBts = new CreateOptionsBts();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      load: LoadsServiceLoad;
      showWarning: boolean;
    } = {
      load: null,
      showWarning: false,
    },
    public dialogRef: MatDialogRef<LoadActiveAssignComponent>,
    private fb: FormBuilder,
    private appModel: AppModel,
    public commonEntities: CommonEntities,
    private permissionService: PermissionsService
  ) {
    const { load, showWarning }: { load: LoadsServiceLoad; showWarning: boolean } = this.data;
    this.showWarning = showWarning;
    this.form = this.fb.group({
      driver: [get(load, 'drivers[0].id', null)],
      truck: [get(load, 'truck.id', null)],
      trailer: [get(load, 'trailers[0].id', null)],
    });

    this.form
      .get('driver')
      .valueChanges.pipe(takeUntil(this.alive))
      .subscribe((value) => {
        if (value === this.createOptionsBts.NEW_DRIVER) {
          this.dialogRef.close({ action: this.createOptionsBts.NEW_DRIVER, data: {} });
        }
      });

    this.form
      .get('truck')
      .valueChanges.pipe(takeUntil(this.alive))
      .subscribe((value) => {
        if (value === this.createOptionsBts.NEW_TRUCK) {
          this.dialogRef.close({ action: this.createOptionsBts.NEW_TRUCK, data: {} });
        }
      });

    this.form
      .get('trailer')
      .valueChanges.pipe(takeUntil(this.alive))
      .subscribe((value) => {
        if (value === this.createOptionsBts.NEW_TRAILER) {
          this.dialogRef.close({ action: this.createOptionsBts.NEW_TRAILER, data: {} });
        }
      });
  }

  ngOnInit(): void {
    this.appModel.user$.pipe(takeUntil(this.alive)).subscribe((user) => {
      this.user = user;
      const carrierId = get(user, 'carrier.id', null);
      if (carrierId) {
        this.commonEntities.graphQlDrivers.search({ key: 'assign-users', query: { carrierId } });
        this.commonEntities.graphQlTrailers.search({ key: 'assign-trailer', query: { carrierId } });

        this.commonEntities.graphQlTrucks.search({ key: 'assign-trucks', query: { carrierId } });
        this.commonEntities.graphQlDevices.search({ key: 'assign-devices', query: { carrierId } });
        this.isOnlyADriver = this.permissionService.isOnlyADriver();
        if (this.isOnlyADriver) this.form.get('driver').patchValue(user.id);
      }
    });

    this.buttons$.next(
      this.showWarning
        ? [this.allButtons.ASSIGN_AND_SEND, this.allButtons.SKIP_AND_SEND_LOAD_INFO]
        : [this.allButtons.ASSIGN]
    );

    this.commonEntities.graphQlDrivers.entities$.pipe(takeUntil(this.alive)).subscribe((drivers) => {
      this.drivers = this.isOnlyADriver ? [this.user] : listToArray(drivers);
    });

    this.commonEntities.graphQlTrailers.entities$.pipe(takeUntil(this.alive)).subscribe((trailer) => {
      this.trailers = listToArray(trailer.filter((filterTrailer) => !filterTrailer.inactive));
    });

    combineLatest([this.commonEntities.graphQlTrucks.entities$, this.commonEntities.graphQlDevices.entities$])
      .pipe(takeUntil(this.alive))
      .subscribe(([trucks, devices]) => {
        const newTrucks = trucks;

        const newDevices = devices.filter((device) => device.type === 'phone');

        this.trucks = [...listToArray(newTrucks), ...listToArray(newDevices)];
      });

    combineLatest([
      this.commonEntities.graphQlDrivers.isLoading$,
      this.commonEntities.graphQlTrailers.isLoading$,
      this.commonEntities.graphQlTrucks.isLoading$,
      this.commonEntities.graphQlDevices.isLoading$,
    ])
      .pipe(takeUntil(this.alive))
      .subscribe((formData: boolean[]) => {
        this.isDataLoaded$.next(formData.every((item) => item === false));
      });
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }

  getUnitDisplayName(truck: Device | null): string {
    if (truck.type && truck.type === 'phone') {
      if (truck.lastLocation?.driverName) {
        return `${truck.lastLocation.driverName}'s Phone`;
      } else {
        return truck.unitId || 'Phone';
      }
    }

    return 'Unit # ' + (truck.unitId || truck.lastLocation || 'N/A');
  }

  action(action: string): void {
    if (action === this.allButtons.ASSIGN.action && this.form.valid) {
      const data = this.form.getRawValue();
      const truck = find(this.trucks, (item) => item.id === data.truck);
      const trailer = find(this.trailers, (item) => item.id === data.trailer);
      const trailers = trailer ? [trailer] : [];
      const trailerId = data.trailer;
      const driver = find(this.drivers, (item) => item.id === data.driver);
      const drivers = driver ? [driver] : [];

      this.dialogRef.close({ action, data: { truck, drivers, trailers, trailerId } });
    } else if (action === this.allButtons.SKIP_AND_SEND_LOAD_INFO.action) {
      this.dialogRef.close({ action });
    }
  }
}
