export interface PaginationParams {
  total?: number;
  limit?: number;
  page?: number;
}

export interface QueryParams {
  [key: string]: any;
}

export interface SearchParams extends PaginationParams {
  query?: QueryParams;
}
