import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CarrierService } from '@haulynx/services';
import { Carrier, Location, Truck, User } from '@haulynx/types';
import moment from 'moment';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-simulate-position',
  templateUrl: './simulate-position.component.html',
})
export class SimulatePositionComponent {
  @ViewChild('addressAutocomplete', { static: true }) public pickupAutocomplete: ElementRef;

  statesList;
  public user: User;

  public drivers: Observable<User[]>;
  public carrier: Carrier;
  public selectedDriver: User;
  public truck: Truck;
  public address: string;
  private place: google.maps.places.PlaceResult;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public snackBar: MatSnackBar,
    private mapsAPILoader: MapsAPILoader,
    public dialogRef: MatDialogRef<SimulatePositionComponent>,
    private carrierService: CarrierService
  ) {
    this.truck = data.truck;
    this.carrier = data.carrier;
  }
  ngOnInit() {
    this.drivers = this.carrierService.getDrivers(this.carrier.id);

    this.mapsAPILoader.load().then(() => {
      const pAutocomplete = new google.maps.places.Autocomplete(this.pickupAutocomplete.nativeElement, {
        types: ['address'],
      });
      pAutocomplete.addListener('place_changed', () => {
        this.place = pAutocomplete.getPlace();
        this.address = this.place.formatted_address;
      });
    });
  }

  save() {
    const lastLocation = new Location(
      this.place.formatted_address,
      this.place.geometry.location.lat(),
      this.place.geometry.location.lng()
    );
    lastLocation.date = moment().format('MM-DD-YYYY hh:mm a');
    lastLocation.driver = this.selectedDriver.id;
    lastLocation.driverName = this.selectedDriver.name;
    lastLocation.driverStatus = 'Off Duty';
    lastLocation.unitId = this.truck.unitId;

    this.carrierService.simulatePosition(this.truck.id, lastLocation).subscribe(() => {});
    this.truck.lastLocation = lastLocation;
    this.dialogRef.close();
  }
}
