<form [formGroup]="verifyEmailForm">
  <h1 mat-dialog-title class="text">Verify User's Email</h1>
  <div mat-dialog-content>
    <haulynx-blue-input
      [formControlName]="'email'"
      label="User's Email"
      type="text"
      required="true"
    ></haulynx-blue-input>
    <div class="error-or-success text" *ngIf="error || success">
      <span class="error" *ngIf="error">
        {{ error }}
      </span>
      <span class="success" *ngIf="success" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <mat-icon>check</mat-icon>
        {{ success }}
      </span>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-button mat-dialog-close class="text">Cancel</button>
    <haulynx-blue-button
      text="Verify"
      [loading]="loading | async"
      [disabled]="verifyEmailForm.invalid"
      (clicked)="verifyEmail()"
    ></haulynx-blue-button>
  </div>
</form>
