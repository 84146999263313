import { createSelector } from '@ngrx/store';
import { AppState } from '../../main-store/app.reducers';
import { CommonEntityState } from './common-entities.state';

const getCommonEntityState = (entityName) => {
  return (state: AppState): CommonEntityState => state.commonEntities[entityName];
};

export const getEntity = (entityName) => {
  return createSelector(getCommonEntityState(entityName), (state: CommonEntityState) => {
    return state.entities;
  });
};

export const isReceived = (entityName) => {
  return createSelector(getCommonEntityState(entityName), (state: CommonEntityState) => {
    return state.isLoading;
  });
};

export const getQuery = (entityName) => {
  return createSelector(getCommonEntityState(entityName), (state: CommonEntityState) => {
    return state.query;
  });
};
