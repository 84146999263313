<div class="details-info-wrapper" ngClass.lt-md="enlarge" *ngIf="load" fxLayout.lt-md="column" fxLayoutGap.lt-md="16px">
  <app-unauthenticated-load-feed-details-info-pane
    fxFlexOrder.lt-md="2"
    [load]="load"
    [stops]="stops"
    (signup)="signupClick()"
  ></app-unauthenticated-load-feed-details-info-pane>

  <app-lf-details-map
    fxFlexOrder.lt-md="1"
    [load]="load"
    [loadFeedRoute]="route"
    [showTruckRoute]="true"
  ></app-lf-details-map>
</div>
