import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FavoriteAddressFormHttpStatus, FavoriteAddressModel } from '@haulynx/store';
import { aliveWhile } from '@haulynx/utils';
import { pairwise, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-address-favorite-form',
  templateUrl: './address-favorite-form.component.html',
  styleUrls: ['./address-favorite-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressFavoriteFormComponent implements OnDestroy {
  alive = aliveWhile();
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      lat: number;
      lon: number;
      address: string;
      timeZone: string;
      name: string;
      customer: string;
      notes: string;
    },
    public favoriteAddressModel: FavoriteAddressModel,
    public dialogRef: MatDialogRef<AddressFavoriteFormComponent>
  ) {
    this.form = this.fb.group({
      name: [],
      lat: [],
      lon: [],
      address: [],
      timeZone: [],
      customer: [],
      notes: [],
    });
    this.form.patchValue(data || {});
    this.favoriteAddressModel.favoriteAddressFormHttpStatus$
      .pipe(takeUntil(this.alive), pairwise())
      .subscribe(([previousStatus, currentStatus]) => {
        if (
          previousStatus.httpStatus === FavoriteAddressFormHttpStatus.BLANK &&
          currentStatus.httpStatus === FavoriteAddressFormHttpStatus.SUCCESS
        ) {
          this.dialogRef.close(currentStatus.response);
        }
      });
  }

  save(event: Event) {
    event.stopPropagation();
    this.favoriteAddressModel.create(this.form.value);
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }
}
