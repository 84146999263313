import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileDragAndDropComponent } from './file-drag-and-drop.component';
import { FlexModule } from '@angular/flex-layout';
import { DirectivesModule } from '@haulynx/directives';

@NgModule({
  declarations: [FileDragAndDropComponent],
  imports: [CommonModule, FlexModule, DirectivesModule],
  exports: [FileDragAndDropComponent],
})
export class FileDragAndDropModule {}
