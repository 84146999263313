export class GenerateReportPayload {
  allTrucksSelected?: boolean;
  comment: string;
  driverId?: string;
  fromTimestamp: number;
  recipient: string[];
  toTimestamp: number;
  truckId?: string[];

  constructor(obj: any) {
    this.driverId = obj.driverId;
    this.fromTimestamp = obj.fromTimestamp;
    this.toTimestamp = obj.toTimestamp;
    this.comment = obj.comment;
    this.recipient = obj.recipient;
    this.truckId = obj.truckId;
    this.allTrucksSelected = obj.allTrucksSelected;
  }
}
