import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable()
export class LoadFeedFilters {
  filterByEquipmentType(entity: any, query) {
    const equipmentType = _.get(query, 'equipmentType', null);

    if (equipmentType === 'Any' || !equipmentType) {
      return true;
    } else {
      return entity.equipmentType === _.get(query, 'equipmentType');
    }
  }

  filterAllowStop(entity: any, query) {
    if (_.get(query, 'allowStops', true)) {
      return true;
    } else {
      return Number(entity.numberOfStops) === 2;
    }
  }

  filterNotExclusive(entity: any) {
    const isExpire = Number(entity.exclusiveUntil) < Date.now();
    const isExclusiveLoad = _.get(entity, 'exclusiveLoad', false);

    return !(!isExpire && isExclusiveLoad);
  }

  filterRecommended(entity: any) {
    return !entity.exclusiveLoad || Number(entity.exclusiveUntil) < Date.now();
  }

  filterPrice(entity: any, query) {
    const price = _.toNumber(query.price);
    const entityPrice = _.toNumber(entity.price);

    if (price && entityPrice) {
      return entityPrice >= price;
    }

    return true;
  }

  filterWeight(entity: any, query) {
    const weight = _.toNumber(query.weight);
    const entityWeight = _.toNumber(entity.weight);

    if (weight && entityWeight) {
      return entityWeight >= weight;
    }

    return true;
  }

  filterRatePerMile(entity: any, query) {
    const rpm = _.toNumber(query.rpm);
    const entityRpm = _.toNumber(entity.ratePerMile);

    if (rpm && entityRpm) {
      return entityRpm >= rpm;
    }

    return true;
  }

  filterDistance(entity: any, query) {
    const distance = _.toNumber(query.loadDistance);
    const entityDistance = _.toNumber(entity.distance);

    if (distance && entityDistance) {
      return entityDistance >= distance;
    }

    return true;
  }
}
