import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStoreService } from '@haulynx/services';
import { UserEntityService } from '@haulynx/store';
import { Environment } from '@haulynx/types';
import { startsWith } from 'lodash';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { isDevMode } from '@angular/core';

@Injectable()
export class ApiInterceptorsService implements HttpInterceptor {
  constructor(
    private userEntityService: UserEntityService,
    private localStoreService: LocalStoreService,
    private environment: Environment
  ) {}

  apiRequest(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.userEntityService.token$.pipe(
      take(1),
      switchMap((token: string) => {
        const headers: any = {
          'Content-Type': 'application/json',
        };

        if (token) {
          headers.Authorization = `Bearer ${token}`;
        }

        req = req.clone({
          url: `${this.environment.apiUrl}${req.url}`,
          setHeaders: headers,
        });

        return next.handle(req);
      })
    );
  }

  docRequest(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.userEntityService.token$.pipe(
      take(1),
      switchMap((token: string) => {
        req = req.clone({
          url: `${this.environment.documents.documentServerUrl}${req.url}`,
          setHeaders: { Authorization: `Bearer ${token}` },
          responseType: 'blob',
        });
        return next.handle(req);
      })
    );
  }

  handleApiError(error, url: string) {
    const JsonParseError = 'Http failure during parsing for';
    let matches = '';
    if (error.message) {
      matches = error.message.match(new RegExp(JsonParseError, 'ig'));
    }

    if (error.status === 200 && matches.length === 1) {
      return of(error.error ? error.error.text : error);
    }

    if (error.status === 401 && !url.includes('refresh-token')) {
      this.localStoreService.clear('user');
      this.localStoreService.clear('token');

      const { pathname } = window.location;

      if (!pathname.includes('/account/login') && !pathname.includes('/account/action')) {
        window.location.href = pathname ? '/account/login?returnUrl=' + pathname : '/account/login';
      }
    }
    if (error && error.error) {
      return throwError(error.error);
    } else if (error) {
      return throwError(error.message || error);
    } else {
      return throwError(error);
    }
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const { url } = req;
    const apiRequest = startsWith(url, '/api');
    const docRequest = startsWith(url, '/document-proxy');

    if (apiRequest) {
      return this.apiRequest(req, next).pipe(catchError((err) => this.handleApiError(err, url)));
    }

    if (docRequest) {
      return this.docRequest(req, next).pipe(catchError((err) => this.handleApiError(err, url)));
    }

    return next.handle(req).pipe(catchError((err) => this.handleApiError(err, url)));
  }
}
