<mat-form-field class="drop-down" [floatLabel]="floatLabel" appearance="outline">
  <input
    #autocompleteInput
    matInput
    [formControl]="control"
    [matAutocomplete]="auto"
    (blur)="inputBlur($event)"
    placeholder="{{ isLoading ? 'Loading... ' : placeholder }}"
    type="text"
    data-test="dropdownInput"
  />
  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="select($event)"
    [displayWith]="displayFn()"
    (closed)="panelClose()"
    (opened)="panelOpen()"
  >
    <cdk-virtual-scroll-viewport
      class="virtual-viewport"
      [itemSize]="48"
      [style.height.px]="virtualScroll.windowSize"
      [minBufferPx]="virtualScroll.minBufferPx"
      [maxBufferPx]="virtualScroll.maxBufferPx"
    >
      <mat-option *cdkVirtualFor="let item of filteredData | async" [value]="item">
        <ng-container *ngTemplateOutlet="templateLabel; context: { row: item }"></ng-container>
        <div *ngIf="!templateLabel">{{ item[displayLabel] }}</div>
      </mat-option>
    </cdk-virtual-scroll-viewport>
  </mat-autocomplete>
  <mat-icon (click)="showPanel($event, auto)" data-test="open-dropdown">{{
    auto.isOpen ? 'expand_less' : 'expand_more'
  }}</mat-icon>
  <mat-error *ngIf="control.errors?.mustSelected"> Must choose an option from the dropdown. </mat-error>
</mat-form-field>
