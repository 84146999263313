import { ISearchFilter, TextFilterType } from '../search-filter';
import { ISearchFilterType } from '../search-filter.types';

export class BillToFilter implements ISearchFilter {
  name = 'Bill To';
  type: ISearchFilterType = ISearchFilterType.TEXT_ARRAY;
  keys: TextFilterType;
  description = 'This will search who the load will bill to';
  searchPriority = 35;

  constructor(defaultValues?: TextFilterType) {
    this.keys = new TextFilterType({
      value: '',
      optionsDataIndex: 'billTo',
      textFormName: 'billTo',
      ...defaultValues,
    });
  }
}
