import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'trailer-delete-dialog',
  template: `
    <div class="trailer-delete-container">
      <button mat-icon-button data-test="close" class="pull-right close" [matDialogClose]="">
        <mat-icon>close</mat-icon>
      </button>
      <h2 matDialogTitle>Delete Trailer</h2>
      <mat-dialog-content> Are you sure you want to Delete this Trailer? </mat-dialog-content>
      <mat-dialog-actions>
        <button mat-button data-test="delete" class="button-right" color="warn" [matDialogClose]="{ delete: true }">
          Delete
        </button>
        <br />
      </mat-dialog-actions>
    </div>
  `,
  styleUrls: ['./trailer-delete.component.scss'],
})
export class TrailerDeleteDialog {
  constructor(public dialogRef: MatDialogRef<TrailerDeleteDialog>) {}
}
