import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CarrierDashboardModel } from '@haulynx/store';
import { aliveWhile, SubscriberComponent } from '@haulynx/utils';
import { takeUntil } from 'rxjs/operators';
import { CarrierLaneFormComponent } from '../carriers/carrier-lane-form/carrier-lane-form.component';

@Component({
  selector: 'app-lanes',
  templateUrl: './lanes.component.html',
  styleUrls: ['./lanes.component.scss'],
})
export class LanesComponent extends SubscriberComponent implements OnChanges {
  @Input() dot = '';
  query: {
    lanesMatches?: string[];
    truckZoneMatches?: any[];
    truckIdsMatches?: string[];
    filterByLanes?: boolean;
    filterByTrucks?: boolean;
    allTrucksSelected?: boolean;
    allLanesSelected?: boolean;
  } = {};

  constructor(public dashboardCarrierModel: CarrierDashboardModel, private dialog: MatDialog) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    const { dot } = changes;
    if (dot.currentValue) {
      this.dashboardCarrierModel.searchLanes({ limit: 100, query: { carrierDot: dot.currentValue } });
    }
  }

  addLane(event) {
    const alive = aliveWhile();
    const dialogRef = this.dialog.open(CarrierLaneFormComponent);

    dialogRef.componentInstance.form.patchValue({ dot: this.dot });
    dialogRef.componentInstance.onCreate.pipe(takeUntil(alive)).subscribe((newLane: any) => {
      dialogRef.componentInstance.isLoading = true;

      // Todo: Move into effects to call api
      // this.neptuneService.createLane(newLane).subscribe(
      //   () => {
      //     this.dashboardCarrierModel.searchLanes({ limit: 100, query: { carrierDot: this.dot } });
      //     dialogRef.close();
      //   },
      //   () => {
      //     dialogRef.close();
      //   }
      // );
    });
    dialogRef.afterClosed().subscribe(() => {
      alive.destroy();
    });
  }

  searchLanesPaginationChange(pagination) {
    this.dashboardCarrierModel.searchLanes(pagination);
  }
}
