<app-spinner [active]="featureFlagDashboardModel.isLoading$ | async" [fullCover]="true"></app-spinner>

<div class="feature-flag">
  <h4>Feature Flag Dashboard</h4>
  <button
    mat-button
    data-test="create-feature-button"
    class="feature-flag__button affirmative"
    (click)="onFeatureFlagForm()"
  >
    Create a Feature
  </button>
</div>

<data-table
  [data]="featureFlagDashboardModel.entities$ | async"
  [displayedColumns]="columns"
  [templateCellRef]="relationsTemplateRef"
  cls="feature-flag__table"
  data-test="feature-flag-table"
>
  <ng-template #relationsTemplateRef let-row="row" let-column="column">
    <ng-container *ngIf="column === 'environments'">
      <div *ngFor="let permission of row?.permissions" [attr.data-test]="permission?.environment">
        <ng-container *ngIf="permission?.environment">
          {{ permission?.environment }}
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="column === 'platforms'">
      <div data-test="platform-web" *ngIf="row?.platforms?.web">Web</div>
      <div data-test="platform-ios" *ngIf="row?.platforms?.ios">iOS</div>
      <div data-test="platform-android" *ngIf="row?.platforms?.android">Android</div>
    </ng-container>

    <ng-container *ngIf="column === 'options'">
      <button data-test="menu-button" mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button data-test="edit-button" mat-menu-item (click)="onFeatureFlagForm(row.name)">Edit</button>
        <button data-test="delete-button" mat-menu-item (click)="onDeleteFeatureFlag(row.name)">Delete</button>
      </mat-menu>
    </ng-container>
  </ng-template>
</data-table>
