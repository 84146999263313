import { ThemePalette } from '@angular/material/core';

export interface ActionButtonConfig {
  label: string;
  action: string;
  color?: ThemePalette;
  cls?: string;
  style?: string;
  icon?: string;
  disable?: boolean;
}

export interface ActionButtonsTypes {
  BACK: ActionButtonConfig;
  OK: ActionButtonConfig;
  NEXT: ActionButtonConfig;
  SAVE_TEMPLATE: ActionButtonConfig;
  ADD_TRUCK: ActionButtonConfig;
  SEARCH: ActionButtonConfig;
  RESET: ActionButtonConfig;
  SAVE: ActionButtonConfig;
  DELETE: ActionButtonConfig;
  CLOSE: ActionButtonConfig;
  CANCEL: ActionButtonConfig;
  ADD_TO_NETWORK: ActionButtonConfig;
  SIGN_UP: ActionButtonConfig;
  CREATE: ActionButtonConfig;
  CREATE_LOAD: ActionButtonConfig;
  UPDATE: ActionButtonConfig;
  RESET_PASSWORD: ActionButtonConfig;
  FORGOT_PASSWORD: ActionButtonConfig;
  LOGIN: ActionButtonConfig;
  EMAIL_LOAD: ActionButtonConfig;
  SEND_OFFER: ActionButtonConfig;
  REFRESH_MATCHES: ActionButtonConfig;
  ACCEPT: ActionButtonConfig;
  REJECT: ActionButtonConfig;
  DECLINE: ActionButtonConfig;
  ASSIGN: ActionButtonConfig;
  CONFIRM_PICKUP: ActionButtonConfig;
  ASSIGN_AND_SEND: ActionButtonConfig;
  COMPLETE_DELIVERY: ActionButtonConfig;
  SKIP_AND_SEND_LOAD_INFO: ActionButtonConfig;
  ALL_INFO: ActionButtonConfig;
  LOAD_EDIT: ActionButtonConfig;
  PAY_EDIT_DETAILS: ActionButtonConfig;
  BID: ActionButtonConfig;
  BOOK: ActionButtonConfig;
  EMAIL_ICON: ActionButtonConfig;
  CREATE_MILESTONE: ActionButtonConfig;
  EDIT_MILESTONE: ActionButtonConfig;
  DELETE_COMMENT: ActionButtonConfig;
  EDIT_COMMENT: ActionButtonConfig;
  CREATE_COMMENT: ActionButtonConfig;
  INVITE: ActionButtonConfig;
  MILESTONE_CANCEL: ActionButtonConfig;
  SUBMIT: ActionButtonConfig;
  SUBMIT_REPORT: ActionButtonConfig;
}

export const buttonTypes: ActionButtonsTypes = {
  SAVE_TEMPLATE: {
    label: 'Save Template',
    action: 'save-template',
  },
  NEXT: {
    label: 'Next',
    action: 'next',
  },
  ADD_TRUCK: {
    label: 'Add New Truck',
    action: 'add-truck',
  },
  SEARCH: {
    label: 'Search',
    action: 'search',
    color: 'primary',
    cls: 'search',
  },
  RESET: {
    label: 'Reset',
    action: 'reset',
  },
  SAVE: {
    label: 'Save',
    action: 'save',
    cls: 'btn-green',
  },
  DELETE: {
    label: 'Delete',
    action: 'delete',
    color: 'warn',
  },
  BACK: {
    label: 'Back',
    action: 'Back',
    cls: 'cancel',
  },
  OK: {
    label: 'Okay',
    action: 'ok',
    cls: 'accept',
  },
  CANCEL: {
    label: 'Cancel',
    action: 'cancel',
    cls: 'cancel',
  },
  ADD_TO_NETWORK: {
    label: '+ Add to your Network',
    action: 'add-to-network',
    color: 'accent',
  },
  SIGN_UP: {
    label: 'Sign Up',
    action: 'sign-up',
    color: 'primary',
  },
  CREATE: {
    label: 'Create',
    action: 'create',
    color: 'accent',
  },
  CREATE_LOAD: {
    label: 'Create Load',
    action: 'create',
    color: 'accent',
    cls: 'create-load',
  },
  UPDATE: {
    label: 'Update',
    action: 'update',
    cls: '',
    color: 'accent',
  },
  RESET_PASSWORD: {
    label: 'Send reset password',
    action: 'reset-password',
    color: 'primary',
  },
  FORGOT_PASSWORD: {
    label: 'Forgot password',
    action: 'forgot-password',
    color: 'primary',
    style: 'basic',
  },
  LOGIN: {
    label: 'Login',
    action: 'login',
    color: 'primary',
  },
  EMAIL_LOAD: {
    label: 'EMAIL LOAD',
    action: 'email-load',
  },
  SEND_OFFER: {
    label: 'SEND OFFER',
    action: 'send-offer',
  },
  REFRESH_MATCHES: {
    label: 'Refresh Matches',
    action: 'refresh-matches',
    color: 'accent',
    cls: 'btn-green',
  },
  ACCEPT: {
    label: 'Accept',
    action: 'accept',
    cls: 'accept',
  },
  REJECT: {
    label: 'Reject',
    action: 'reject',
    color: 'primary',
  },
  DECLINE: {
    label: 'Decline',
    action: 'reject',
    color: 'primary',
    cls: 'btn-decline-reason',
  },
  ASSIGN: {
    label: 'Assign',
    action: 'assign',
    cls: 'accept',
  },
  CLOSE: {
    label: 'Close',
    action: 'close',
    cls: 'accept',
  },
  ASSIGN_AND_SEND: {
    label: 'Assign and Send',
    action: 'assign',
    cls: 'accept',
  },
  CONFIRM_PICKUP: {
    label: 'Confirm Pickup',
    action: 'confirm-pickup',
    cls: 'accept',
  },
  COMPLETE_DELIVERY: {
    label: 'Complete Delivery',
    action: 'complete-delivery',
    cls: 'accept',
  },
  SKIP_AND_SEND_LOAD_INFO: {
    label: 'Skip, send load info anyway',
    action: 'skip',
    color: 'primary',
    cls: '',
  },
  ALL_INFO: {
    label: 'All Info',
    action: 'skip',
    cls: 'orange-button',
  },
  LOAD_EDIT: {
    label: 'EDIT LOAD',
    action: 'load-edit',
    cls: 'orange-button',
  },
  PAY_EDIT_DETAILS: {
    label: 'Edit pay details',
    action: 'pay-edit-details',
    cls: 'orange-button',
  },
  BOOK: {
    label: 'BOOK',
    action: 'book-carrier',
    cls: 'btn-green',
  },
  BID: {
    label: 'BID',
    action: 'bid-carrier',
    cls: 'btn-blue',
  },
  EMAIL_ICON: {
    label: '',
    action: 'email-carrier',
    cls: 'btn-primary',
    icon: 'haulynx-mail',
  },
  CREATE_MILESTONE: {
    label: 'Create Milestone',
    action: 'create-milestone',
    cls: 'btn-milestone-green-black',
  },
  EDIT_MILESTONE: {
    label: 'Update Milestone',
    action: 'create-milestone',
    cls: 'btn-milestone-green-black',
  },
  DELETE_COMMENT: {
    label: 'Delete',
    action: 'delete-comment',
    cls: 'btn-milestone-green-black',
  },
  EDIT_COMMENT: {
    label: 'Edit Comment',
    action: 'edit-comment',
    cls: 'btn-milestone-green-black',
  },
  CREATE_COMMENT: {
    label: 'Create Comment',
    action: 'create-comment',
    cls: 'btn-milestone-green-black',
  },
  MILESTONE_CANCEL: {
    label: 'Cancel',
    action: 'cancel',
    cls: 'btn-milestone-white-outlined',
  },
  SUBMIT: {
    label: 'Submit',
    action: 'submit',
    cls: 'btn-milestone-green-black',
  },
  INVITE: {
    label: 'Invite',
    action: 'invite',
  },
  SUBMIT_REPORT: {
    label: 'Submit Report',
    action: 'submit-report',
    cls: 'btn-milestone-green-black',
  },
};
