<div class="milestones__left__header">
  <app-spinner [active]="showSpinner" [diameter]="20" class="spinner"> </app-spinner>

  <div class="header-content" *ngIf="load">
    <div class="header-content__load-data">
      <div class="load-data">
        <div class="load-data__row">
          <div class="load-data__row-title">Status:</div>
          <div
            class="load-data__row-data"
            [ngStyle]="{ color: !isMilestoneOnTime ? '#F25E40' : '#00CA8E' }"
            data-test="load-status"
          >
            {{ getLastStatusUpdate.toUpperCase() }}{{ isMilestoneOnTimeText.toUpperCase() }}
          </div>
        </div>

        <div class="load-data__row">
          <div class="load-data__row-title">Tracking:</div>
          <div class="load-data__row-data">
            {{ getTrackingType }} {{ getTrackingType !== '' && getTrackingDriver !== '' ? ' - ' : '' }}
            {{ getTrackingDriver }}
          </div>
        </div>

        <div class="load-data__row">
          <div class="load-data__row-title">Update:</div>
          <div class="load-data__row-data">
            {{ getDateDistance }}
          </div>
        </div>

        <div class="load-data__row">
          <div class="load-data__row-title">Location:</div>
          <div class="load-data__row-data">
            {{ lastLocation || '-' }}
          </div>
        </div>

        <div class="load-data__row">
          <div class="load-data__row-title">Mile:</div>
          <div class="load-data__row-data" *ngIf="getMilesCompleted && getTotalMiles">
            {{ getMilesCompleted }}/{{ getTotalMiles }}
          </div>
        </div>
      </div>

      <div class="copy-button">
        <mat-icon style="cursor: pointer" (click)="copyLoadInformation()" data-cy="copy-load-text" data-test="copy">
          content_copy
        </mat-icon>
      </div>
    </div>

    <hr />
    <haulynx-milestone-trailer-status
      *ngIf="featureFlags[trailerTrackingFeatureFlag]"
      [trailers]="this.load.trailers"
      (toggle)="toggleTrailers($event)"
    ></haulynx-milestone-trailer-status>
  </div>

  <div class="actions">
    <app-actions [disabledButtons]="disabledButtons" (buttonClicked)="onActionButtonClicked($event)"> </app-actions>
  </div>
</div>
