import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ISearchFilter, NumericalFilterType } from '@haulynx/types';

@Component({
  selector: 'app-search-number-range',
  templateUrl: './search-number-range.component.html',
  styleUrls: ['./search-number-range.component.scss'],
})
export class SearchNumberRangeComponent implements OnInit, AfterViewInit {
  @ViewChild('snrFirstInput', { static: false }) firstInput: ElementRef;
  @ViewChild('wrapper', { static: false }) wrapper: ElementRef;

  @Input() focusedFilter: ISearchFilter;
  @Input() form: FormGroup;

  @Output() mouseFocus: EventEmitter<boolean> = new EventEmitter();
  @Output() commitFilter: EventEmitter<{ searchFilter: ISearchFilter; form: FormGroup }> = new EventEmitter();
  @Output() removeFilter: EventEmitter<ISearchFilter> = new EventEmitter();
  @Output() validForm: EventEmitter<boolean> = new EventEmitter();

  minFormName: string;
  maxFormName: string;

  shouldCheckClicks = false;

  constructor() {}

  @HostListener('window:keyup', ['$event']) onKeyPress(event: KeyboardEvent): void {
    if (event.key === 'Enter' && this.isFormValid) {
      this.commitFilter.emit({ searchFilter: this.focusedFilter, form: this.form });
      this.mouseFocus.emit(false);
      this.validForm.emit(false);
    } else {
      this.validForm.emit(this.isFormValid);
    }
  }

  @HostListener('document:click', ['$event.target']) onClick(targetElement: ElementRef): void {
    const clickedInside = this.wrapper.nativeElement.contains(targetElement);
    if (!clickedInside && this.shouldCheckClicks && !this.isFormValid) {
      this.mouseFocus.emit(false);
    } else {
      this.mouseFocus.emit(true);
      this.validForm.emit(this.isFormValid);
    }
  }

  ngOnInit(): void {
    if (this.focusedFilter) {
      this.minFormName = (<NumericalFilterType>this.focusedFilter.keys).minFormName;
      this.maxFormName = (<NumericalFilterType>this.focusedFilter.keys).maxFormName;
    }
  }

  ngAfterViewInit(): void {
    if (this.focusedFilter) {
      this.firstInput.nativeElement.select();
      this.mouseFocus.emit(true);
      this.shouldCheckClicks = false;
      setTimeout(() => {
        this.validForm.emit(this.isFormValid);
        this.shouldCheckClicks = true;
      }, 500);
    }
  }

  get minFormValue(): number {
    return this.form.get((<NumericalFilterType>this.focusedFilter?.keys)?.minFormName)?.value;
  }

  get maxFormValue(): number {
    return this.form.get((<NumericalFilterType>this.focusedFilter?.keys)?.maxFormName)?.value;
  }

  get isFormValid(): boolean {
    return this.isMaxFormValid() && this.isMinFormValid();
  }

  isMaxFormValid(): boolean {
    const isPositive = this.maxFormValue >= 0;
    return isPositive;
  }

  isMinFormValid(): boolean {
    const isPositive = this.minFormValue >= 0;
    return isPositive;
  }

  removeFocusedFilter(): void {
    this.removeFilter.emit(this.focusedFilter);
    this.mouseFocus.emit(false);
  }
}
