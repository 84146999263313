import {
  AddressField,
  BookStatus,
  DistributionMechanismString,
  EquipmentTypeToLoadsServiceEquipmentType,
  EquipmentTypeToLoadsEquipmentType,
  LoadIdentifierType,
  LoadsServiceLoadInput,
  LoadsServiceLoad,
  LoadsServiceLoadLocationInput,
  LoadsServiceRestrictionTypes,
  LoadsServiceLoadLocation,
  User,
  LoadsServiceLoadStatus,
  LoadsServiceLoadTemplateInput,
} from '@haulynx/types';
import { mapDto, AddressPosition, splitAddress, getLoadsServiceLoadAlternateId } from '@haulynx/utils';
import { map, trim } from 'lodash';
import { LoadForm, LoadLocationForm } from './load.vm';

export const loadsServiceLoadLocationFromDto: Pick<Record<string, unknown>, keyof LoadLocationForm> = {
  location: (load: LoadsServiceLoad, location: LoadsServiceLoadLocation): AddressField => {
    return {
      lon: location.geometry?.coordinates?.[0],
      lat: location.geometry?.coordinates?.[1],
      address: location?.address,
      timeZone: location?.timezone,
    };
  },
  date: 'appointmentStart',
  waitDate: 'appointmentEnd',
  customerId: 'customer.name',
  locationType: 'locationType',
  completed: 'carrierDeparture',
  arrivalTime: 'carrierArrival',
  specialNotes: (load: LoadsServiceLoad, location: LoadsServiceLoadLocation) => location?.notes?.text,
};

export const loadsServiceLoadLocationToDto: Partial<
  Pick<Record<string, unknown>, keyof LoadsServiceLoadLocationInput>
> = {
  customer: (load: LoadForm, location: LoadLocationForm) =>
    location?.customerId ? { name: location.customerId } : null,
  address: (load: LoadForm, location: LoadLocationForm) => location?.location?.address ?? null,
  city: (load: LoadForm, location: LoadLocationForm) => {
    const city = trim(splitAddress(location?.location?.address, AddressPosition.CITY, true));
    return city === 'No Info' ? null : city;
  },
  state: (load: LoadForm, location: LoadLocationForm) => {
    const state = trim(splitAddress(location?.location?.address, AddressPosition.STATE, true, true));
    return state === 'No Info' ? null : state;
  },
  zipcode: (load: LoadForm, location: LoadLocationForm) => {
    const zipcode = trim(splitAddress(location?.location?.address, AddressPosition.ZIP, true));
    return zipcode === 'No Info' ? null : zipcode;
  },
  appointmentStart: 'date',
  appointmentEnd: 'waitDate',
  appointmentSet: (load: LoadForm) => true,
  locationType: 'locationType',
  timezone: (load: LoadForm, location: LoadLocationForm) => location?.location?.timeZone ?? null,
  geometry: (load: LoadForm, location: LoadLocationForm) => {
    const coordinates = [];
    if (location?.location?.lon && location?.location?.lat)
      coordinates.push(location.location.lon, location.location.lat);
    return {
      coordinates: coordinates,
      type: 'Point',
    };
  },
  notes: (load: LoadForm, location: LoadLocationForm, user: User) =>
    location?.specialNotes ? { author: user?.id, text: location?.specialNotes } : null,
};

export const loadFromDto: Partial<Pick<Record<string, unknown>, keyof LoadForm>> = {
  id: 'id',
  price: 'paymentDetails.price',
  revenue: 'paymentDetails.revenue',
  creator: 'createdBy',
  billOfLading: (load: LoadsServiceLoad) =>
    getLoadsServiceLoadAlternateId(load, LoadIdentifierType.BILL_OF_LADING) ?? null,
  equipmentType: (load: LoadsServiceLoad) => EquipmentTypeToLoadsEquipmentType(load?.providerDetails?.equipmentType),
  loadWeight: 'providerDetails.weight',
  poNumber: (load: LoadsServiceLoad) => getLoadsServiceLoadAlternateId(load, LoadIdentifierType.PO_NUMBER) ?? null,
  commodity: 'providerDetails.commodity',
  quantity: 'providerDetails.quantity',
  packingType: 'providerDetails.packingType',
  temperature: 'providerDetails.temperature',
  distributionMechanism: (load: LoadsServiceLoad, locations: LoadsServiceLoadLocation[], user: User) => {
    if (
      (user?.broker || user?.shipper) &&
      user?.carrier &&
      load?.distributionMechanism === DistributionMechanismString.AUTO
    ) {
      return DistributionMechanismString.MANUAL;
    } else if (load?.drivers && user?.carrier) {
      return DistributionMechanismString.MANUAL;
    } else if (user?.carrier) {
      return DistributionMechanismString.NONE;
    } else if (load?.broker && !load?.carrier) {
      return DistributionMechanismString.AUTO;
    } else if (user?.broker && !load?.id) {
      return DistributionMechanismString.AUTO;
    } else {
      return load?.distributionMechanism;
    }
  },
  hazmatCapabilityRequired: (load: LoadsServiceLoad) =>
    !!(load?.restrictions && load?.restrictions.find((item) => item?.type == LoadsServiceRestrictionTypes.HAZMAT)) ??
    null,
  carrier: (load: LoadsServiceLoad, locations: LoadsServiceLoadLocation[], user: User) => {
    if (!load?.id && user?.carrier) {
      return user.carrier?.id;
    } else {
      return load?.carrier && load?.carrier?.id;
    }
  },
  driver: 'drivers[0].id',
  truck: 'truck.id',
  trailerId: 'trailers[0].id',
  trailer: 'trailers[0]',
  broker: 'broker.id',
  carrierName: 'carrier.name',
  carrierAccepted: (load: LoadsServiceLoad) => !!load?.carrier,
  locations: (load: LoadsServiceLoad, locations: LoadsServiceLoadLocation[], user: User) =>
    map(
      load?.locations,
      (location: LoadsServiceLoadLocation): LoadLocationForm =>
        mapDto(location, loadsServiceLoadLocationFromDto, [load, location])
    ),
  customer: 'providerDetails.billToName',
};

export const loadToDto: Partial<Pick<Record<string, unknown>, keyof LoadsServiceLoadInput>> = {
  id: 'id',
  loadStatus: (load: LoadForm) =>
    load?.loadStatus ||
    (load?.driver && load?.truck ? LoadsServiceLoadStatus.ASSIGNED : LoadsServiceLoadStatus.UNASSIGNED),
  bookStatus: (load: LoadForm) => (load?.carrier ? BookStatus.BOOKED : BookStatus.BOOKABLE),
  distributionMechanism: (load: LoadForm) => {
    let distributionMechanism = DistributionMechanismString.NONE;
    if (load?.distributionMechanism === 0) distributionMechanism = DistributionMechanismString.AUTO;
    if (load?.distributionMechanism === 1) distributionMechanism = DistributionMechanismString.MANUAL;
    return distributionMechanism;
  },
  carrier: (load: LoadForm, user: User, entities) => {
    const carrier = entities[load?.carrier] || entities[user?.carrier?.id];
    if (!carrier) return null;
    return {
      dot: carrier?.dot,
      id: carrier?.id,
      name: carrier?.name,
    };
  },
  broker: (load: LoadForm, user: User, entities) =>
    load?.broker
      ? {
          id: entities[load?.broker]?.id ?? null,
          name: entities[load?.broker]?.name ?? null,
          usxId: entities[load?.broker]?.usxId ?? null,
        }
      : null,
  truck: (load: LoadForm, user: User, entities) =>
    load?.truck
      ? {
          id: load?.truck ?? null,
          imei: entities[load?.truck]?.imei ?? null,
          unitId: entities[load?.truck]?.unitId ?? null,
          type: entities[load?.truck]?.type ?? null,
        }
      : null,
  providerDetails: (load: LoadForm) => {
    const altIds = [];
    if (load?.billOfLading) {
      const bol = {
        identifierType: LoadIdentifierType.BILL_OF_LADING,
        identifierValue: load.billOfLading,
      };
      altIds.push(bol);
    }
    if (load?.poNumber) {
      const po = {
        identifierType: LoadIdentifierType.PO_NUMBER,
        identifierValue: load.poNumber,
      };
      altIds.push(po);
    }
    const equipment = EquipmentTypeToLoadsServiceEquipmentType(load?.equipmentType);
    return {
      alternateIds: altIds,
      billToName: load?.customer ?? null,
      commodity: load?.commodity ?? null,
      equipmentType: equipment,
      quantity: load?.quantity ?? null,
      packingType: load?.packingType ?? null,
      weightLbs: load?.loadWeight ?? null,
    };
  },
  paymentDetails: (load: LoadForm) => (load?.price ? { price: load.price } : null),
  locations: (load: LoadForm, user: User) =>
    map(load?.locations, (location: LoadLocationForm) =>
      mapDto<LoadsServiceLoadLocationInput>(location, loadsServiceLoadLocationToDto, [load, location, user])
    ).sort((a, b) => a['timestamp'] - b['timestamp']),
  trailers: (load: LoadForm) =>
    load?.trailer
      ? [
          {
            id: load?.trailer?.id ?? null,
            trailerNumber: load?.trailer?.trailerNumber ?? null,
            type: load?.trailer?.type ?? null,
          },
        ]
      : null,
  drivers: (load: LoadForm, user: User, entities) =>
    load?.driver
      ? [
          {
            id: load?.driver,
            name: entities[load.driver]?.name ?? null,
            phone: entities[load.driver]?.phone ?? null,
          },
        ]
      : null,
};

export const loadTemplateLocationToDto: Partial<Pick<Record<string, unknown>, keyof LoadsServiceLoadLocation>> = {
  customer: (load: LoadsServiceLoad, location: LoadLocationForm) => ({ name: location?.customerId }),
  address: (load: LoadsServiceLoad, location: LoadLocationForm) => {
    return location.location ? location.location.address : null;
  },
  appointmentStart: 'date',
  appointmentEnd: 'waitDate',
  appointmentSet: (load: LoadsServiceLoad) => true,
  locationType: 'locationType',
  timezone: (load: LoadsServiceLoad, location: LoadLocationForm) =>
    location.location ? location.location.timeZone : null,
  geometry: (load: LoadsServiceLoad, location: LoadLocationForm) => {
    const coordinates = [];
    if (location?.location?.lon && location?.location?.lat)
      coordinates.push(location.location.lon, location.location.lat);
    return {
      coordinates: coordinates,
      type: 'Point',
    };
  },
  notes: (load: LoadsServiceLoad, location: LoadLocationForm, user: User) =>
    location?.specialNotes ? { author: user?.id, text: location?.specialNotes } : null,
};

export const loadTemplateToDto: Partial<Pick<Record<string, unknown>, keyof LoadsServiceLoadTemplateInput>> = {
  id: null,
  creator: (load: LoadForm, user: User, entities) => user?.id,
  dateCreated: Date.now(),
  carrier: (load: LoadForm, user: User, entities) => null,
  truck: (load: LoadForm, user: User, entities) => load?.truck && entities[load?.truck],
  broker: (load: LoadForm, user: User, entities) => entities[load?.broker],
  locations: (load: LoadForm) =>
    map(load?.locations, (location: LoadLocationForm) =>
      mapDto<LoadsServiceLoadLocation>(location, loadTemplateLocationToDto, [load, location])
    ).sort((a, b) => a['date'] - b['date']),

  distributionMechanism: (load: LoadForm) => {
    let distributionMechanism = DistributionMechanismString.NONE;
    if (load?.distributionMechanism === 0) distributionMechanism = DistributionMechanismString.AUTO;
    if (load?.distributionMechanism === 1) distributionMechanism = DistributionMechanismString.MANUAL;
    return distributionMechanism;
  },
  providerDetails: (load: LoadForm) => {
    const altIds = [];
    if (load?.billOfLading) {
      const bol = {
        identifierType: LoadIdentifierType.BILL_OF_LADING,
        identifierValue: load.billOfLading,
      };
      altIds.push(bol);
    }
    if (load?.poNumber) {
      const po = {
        identifierType: LoadIdentifierType.PO_NUMBER,
        identifierValue: load.poNumber,
      };
      altIds.push(po);
    }
    const equipment = EquipmentTypeToLoadsServiceEquipmentType(load?.equipmentType);
    return {
      alternateIds: altIds,
      billToName: load?.customer ?? null,
      commodity: load?.commodity ?? null,
      equipmentType: equipment,
      quantity: load?.quantity ?? null,
      packingType: load?.packingType ?? null,
      weightLbs: load?.loadWeight ?? null,
    };
  },
  paymentDetails: (load: LoadForm) => (load?.price ? { price: load.price } : null),
  trailers: (load: LoadForm) =>
    load?.trailer
      ? [
          {
            id: load?.trailer?.id ?? null,
            trailerNumber: load?.trailer?.trailerNumber ?? null,
            type: load?.trailer?.type ?? null,
          },
        ]
      : null,
  drivers: (load: LoadForm, user: User, entities) =>
    load?.driver
      ? [
          {
            id: load?.driver,
            name: entities[load.driver]?.name ?? null,
            phone: entities[load.driver]?.phone ?? null,
          },
        ]
      : null,
};
