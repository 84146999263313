import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { CarrierToolbarService, WindowRef } from '@haulynx/services';
import {
  Carrier,
  CarrierToolbarState,
  FeatureFlag,
  LoadsServiceSearchMetaData,
  MergedCarrierData,
  RecommendedCarriers,
} from '@haulynx/types';
import { Observable } from 'rxjs';

@Component({
  selector: 'haulynx-carrier-toolbar-carrier-profile',
  templateUrl: './carrier-toolbar-carrier-profile.component.html',
  styleUrls: ['./carrier-toolbar-carrier-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CarrierToolbarCarrierProfileComponent implements OnInit {
  @Input() isLoading: boolean;
  @Input() carrier: MergedCarrierData;
  @Input() returnText = 'All Recommendations';
  @Input() loadsMetaData: LoadsServiceSearchMetaData;
  @Output() finishViewing = new EventEmitter();
  @Output() carrierProfileTabChange = new EventEmitter<MatTabChangeEvent>();
  @Output() carrierProfileToggleDetails = new EventEmitter<boolean>();
  selectedTabIndex = 0;
  expandDetails = false;
  LanesFeature = FeatureFlag.PREFERRED_LANES;
  state$: Observable<CarrierToolbarState>;
  metricsFeature: FeatureFlag = FeatureFlag.CARRIER_METRICS;

  constructor(private windowRef: WindowRef, private carrierToolbarService: CarrierToolbarService) {}
  ngOnInit(): void {
    this.state$ = this.carrierToolbarService.state$;
  }

  toggleDetails(): void {
    this.expandDetails = !this.expandDetails;
    this.carrierProfileToggleDetails.emit(this.expandDetails);
  }

  get formatDomicile(): string {
    return `${this.carrier?.addressCity ?? ''}${this.carrier?.addressCity && this.carrier?.addressState ? ', ' : ''}${
      this.carrier?.addressState ?? ''
    }`;
  }

  onFinishViewing(): void {
    this.finishViewing.emit();
  }

  onViewProfile(): void {
    const id = this.carrier.dot;
    const url = `/dashboard/carriers/view/${id}`;
    this.windowRef.getNativeWindow().open(url, '_blank');
  }

  tabChange(event: MatTabChangeEvent): void {
    this.carrierProfileTabChange.emit(event);
    this.selectedTabIndex = event.index;
    this.carrierToolbarService.updateState({ data: { selectedTab: event.index } });
  }
}
