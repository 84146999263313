<mat-label id="label-area">{{ label }}</mat-label>
<textarea
  #input
  (focus)="onChangeFocus($event, notes.value)"
  (blur)="onBlur($event, notes.value)"
  matInput
  #textbox
  [class.active]="resizer"
  data-test="text-area"
  [formControl]="notes"
  [readonly]="readOnly"
>
>{{ data }}</textarea
>
