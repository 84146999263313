import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'assignedTo',
  pure: true,
})
export class AssignedToPipe implements PipeTransform {
  transform(value: { name: string }[], ...args: unknown[]): string {
    if (!value || !value.length) return '';
    if (value.length === 1) return value[0].name;

    return value[0].name + ` + ${value.length - 1} other${value.length === 2 ? '' : 's'}`;
  }
}
