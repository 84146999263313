export class Shipper {
  addressStreet: string;
  addressCity: string;
  addressState: string;
  id: string;
  name: string;
  phone: string;
  dot: string;

  constructor(props?: Partial<Shipper>) {
    Object.assign(this, props);
  }
}
