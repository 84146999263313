<div class="row" class="load-feed-carriers-v2-container">
  <div class="col-lg-12">
    <data-table-v2
      class="carrier-search-table"
      [data]="tableData$ | async"
      [emptyMessage]="'No Recommended Carriers Found'"
      [displayedColumns]="dataTableColumns"
      [title]="'Top Carriers'"
      [templateCellRef]="templateCell"
      [selectionMode]="''"
      [tableConfig]="configOptions$ | async"
      [rowSpacing]="6"
      [stickyColumnWidth]="'250px'"
      [filterForm]="filterForm"
      [parentToTable]="parentToTable"
      [loading]="isLoading$ | async"
      (clickableColumnOnSelect)="clickableColumnWasSelected($event)"
      (globalFilterChanged)="filterChanged($event)"
      data-test="top-carriers-table"
    >
      <ng-template #templateCell let-row="row" let-column="column" let-hovering="isHovering" let-width="columnWidth">
        <td [ngStyle]="{ width: width }" *ngIf="column.field === 'name'" style="position: relative">
          <span [underlineText]="hovering" [carrierDetails]="row.dot" data-test="carrier-name">
            {{ row.name ? row.name : 'No Name Carrier' }}
          </span>
        </td>

        <td
          [ngStyle]="{ width: width }"
          *ngIf="column.field === 'miles'"
          [tooltip]="row?.explanation?.type == recommendedType.FREQUENT_HAUL ? 'Recommendation is a Frequent Haul' : ''"
          data-test="price-per-miles"
        >
          <div class="flex-column" *ngIf="row?.explanation?.type !== recommendedType.FREQUENT_HAUL; else notAvailable">
            <div>
              {{ row.price | currency }}
            </div>
            <div>{{ row.load?.paymentDetails?.distanceMiles }} mi</div>
          </div>
        </td>

        <td
          [ngStyle]="{ width: width }"
          *ngIf="column.field === 'originDestination'"
          [tooltip]="row?.explanation?.type == recommendedType.FREQUENT_HAUL ? 'Recommendation is a Frequent Haul' : ''"
          data-test="origin-destination"
        >
          <app-load-timeline
            *ngIf="row?.explanation?.type !== recommendedType.FREQUENT_HAUL; else notAvailable"
            [load]="row.load"
            [showTimeWaitStatus]="false"
            [showSaveLocation]="false"
            [isBroker]="false"
            [showDateTimes]="false"
            [showLocationActions]="false"
            [showTimelineBorder]="false"
            [isSpaciousIcons]="false"
            [showNotesButton]="false"
            [showCustomerAttributes]="false"
          >
          </app-load-timeline>
        </td>

        <td
          [ngStyle]="{ width: width }"
          *ngIf="column.field === 'pickup'"
          class="last-column-with-sticky"
          [tooltip]="row?.explanation?.type == recommendedType.FREQUENT_HAUL ? 'Recommendation is a Frequent Haul' : ''"
          data-test="pickup"
        >
          <div class="flex-column" *ngIf="row?.explanation?.type !== recommendedType.FREQUENT_HAUL; else notAvailable">
            <div>
              {{ row.load?.locations | nth: 0 | get: 'appointmentStart' | date: 'shortDate' }}
            </div>
            <div class="small-time-text">
              {{ row.load?.locations | nth: 0 | get: 'appointmentStart' | date: 'shortTime' }}
            </div>
          </div>
        </td>

        <td [ngStyle]="{ width: width }" *ngIf="column.field === 'owner'" data-test="owner">
          {{ row.owner | carrierOwner: 'Unknown' }}
        </td>

        <td [ngStyle]="{ width: width }" *ngIf="column.field === 'actions'">
          <action-buttons
            *ngIf="load?.bookStatus !== BookStatus.BOOKED && !hideSearchAndButtons"
            [disableButtons]="disableButtons | get: row.id:[]"
            [buttons]="buttons$ | async"
            (onClick)="action($event, row)"
          >
          </action-buttons>
        </td>
      </ng-template>
    </data-table-v2>
  </div>
</div>
<ng-template #notAvailable>N/A</ng-template>
