import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '@haulynx/modules';
import { DataTableModule } from '../data-table/data-table.module';
import { CarrierManagementTableComponent } from './carrier-management-table.component';

@NgModule({
  declarations: [CarrierManagementTableComponent],
  exports: [CarrierManagementTableComponent],
  imports: [CommonModule, DataTableModule, FormsModule, MaterialModule],
})
export class CarrierManagementTableModule {}
