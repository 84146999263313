import { LoadServiceSearchParameters } from '../../loads-service';

export class SearchView {
  id?: string;
  label: string;
  route: string;
  parent: string;
  data: LoadServiceSearchParameters;
  quantity: number;
  children: SearchView[];
  metaData: SearchViewMetaData;
}

export class SearchViewMetaData {
  columns: SearchViewColumns[];
  stickyColumnWidthClosed: string;
  stickyColumnWidthOpen: string;
  isCustom?: boolean;
}

export class SearchViewColumns {
  key: string;
  value: boolean;
  order?: number;
}
