import { LoadsServiceLoad, LoadsServiceLoadStatus } from '@haulynx/types';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'loadStatusIcon',
})
export class LoadStatusIconPipe implements PipeTransform {
  transform(load: LoadsServiceLoad): string {
    switch (load?.loadStatus) {
      case LoadsServiceLoadStatus.UNASSIGNED:
        return 'location-pin';
      case LoadsServiceLoadStatus.ASSIGNED:
        return 'booked-icon';
      case LoadsServiceLoadStatus.DISPATCHED:
        return 'dispatched-icon';
      case LoadsServiceLoadStatus.AT_SHIPPER:
        return 'at-shipper-icon';
      case LoadsServiceLoadStatus.PICKED_UP:
        return 'in-transit-icon';
      case LoadsServiceLoadStatus.AT_RECEIVER:
        return 'at-receiver-icon';
      case LoadsServiceLoadStatus.DELIVERED:
        return 'at-stop-icon';
      case LoadsServiceLoadStatus.FINALLED:
        return 'finalled-icon';
      default:
        return 'booked-icon';
    }
  }
}
