import { ChangeDetectionStrategy, Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-book-load',
  templateUrl: './confirm-book-load.component.html',
  styleUrls: ['./confirm-book-load.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmBookLoadComponent {
  carrierName: string;
  carrierDot: string;
  totalAmount: string;

  constructor(
    @Optional() private dialogRef: MatDialogRef<ConfirmBookLoadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { carrierName: string; carrierDot: string; totalAmount: string }
  ) {
    const { carrierName = null, totalAmount = null, carrierDot = null } = data;

    this.carrierName = carrierName;
    this.carrierDot = carrierDot;
    this.totalAmount = totalAmount;
  }

  save(): void {
    this.dialogRef.close(true);
  }

  cancel(): void {
    this.dialogRef.close(false);
  }
}
