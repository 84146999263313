import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@haulynx/modules';
import { OrderInfoNotesVmService } from '@haulynx/services';
import { OrderInfoNotesComponent } from './order-info-notes.component';

@NgModule({
  declarations: [OrderInfoNotesComponent],
  exports: [OrderInfoNotesComponent],
  providers: [OrderInfoNotesVmService],
  imports: [ReactiveFormsModule, CommonModule, FlexLayoutModule, MaterialModule],
})
export class OrderInfoNotesModule {}
