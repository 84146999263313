import { NgModule } from '@angular/core';
import { PayLinePriceComponent } from './pay-line-price.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [PayLinePriceComponent],
  exports: [PayLinePriceComponent],
  imports: [CommonModule],
})
export class PayLinePriceModule {}
