import { createAction, createActionTypes } from '../helpers/action';

export interface IFormActions {
  refresh(params: { key: string; id: string });

  get(params: { key: string; id: string; source?: string; isLatest?: boolean });

  getSuccess(params?);

  getError(params?);

  save(params?);

  saveSuccess(params?);

  saveError(params?);

  saveTemplate(params?);

  saveTemplateSuccess(params?);

  saveTemplateError(params?);

  saveState(params?);

  reset(params?);

  selectMode(params?);

  setStateKey(params?);
}

export interface IFormTypes {
  REFRESH: string;
  GET: string;
  GET_SUCCESS: string;
  GET_ERROR: string;
  SAVE: string;
  SAVE_SUCCESS: string;
  SAVE_ERROR: string;

  SAVE_TEMPLATE: string;
  SAVE_TEMPLATE_SUCCESS: string;
  SAVE_TEMPLATE_ERROR: string;

  SAVE_STATE: string;
  RESET: string;
  SELECT_MODE: string;
  SET_STATE_KEY: string;
}

export const formActionTypes: IFormTypes = {
  REFRESH: 'refresh',
  GET: 'get',
  GET_SUCCESS: 'get success',
  GET_ERROR: 'get error',
  SAVE: 'save',
  SAVE_SUCCESS: 'save success',
  SAVE_TEMPLATE: 'save template',
  SAVE_TEMPLATE_SUCCESS: 'save template success',
  SAVE_TEMPLATE_ERROR: 'save template error',
  SAVE_ERROR: 'save error',
  SAVE_STATE: 'save state',
  RESET: 'reset',
  SELECT_MODE: 'select mode',
  SET_STATE_KEY: 'set state key',
};

export const createFormActionTypes = createActionTypes(formActionTypes);

export function makeFormActions(type): IFormActions {
  const refresh = createAction(type, formActionTypes.REFRESH);
  const get = createAction(type, formActionTypes.GET);
  const getSuccess = createAction(type, formActionTypes.GET_SUCCESS);
  const getError = createAction(type, formActionTypes.GET_ERROR);
  const save = createAction(type, formActionTypes.SAVE);
  const saveSuccess = createAction(type, formActionTypes.SAVE_SUCCESS);
  const saveTemplate = createAction(type, formActionTypes.SAVE_TEMPLATE);
  const saveTemplateSuccess = createAction(type, formActionTypes.SAVE_TEMPLATE_SUCCESS);
  const saveTemplateError = createAction(type, formActionTypes.SAVE_TEMPLATE_ERROR);
  const saveError = createAction(type, formActionTypes.SAVE_ERROR);
  const saveState = createAction(type, formActionTypes.SAVE_STATE);
  const reset = createAction(type, formActionTypes.RESET);
  const selectMode = createAction(type, formActionTypes.SELECT_MODE);
  const setStateKey = createAction(type, formActionTypes.SET_STATE_KEY);

  return {
    refresh,
    get,
    getSuccess,
    getError,
    save,
    saveSuccess,
    saveError,
    saveState,
    reset,
    selectMode,
    setStateKey,
    saveTemplate,
    saveTemplateSuccess,
    saveTemplateError,
  };
}

export function createActions(type) {
  return {
    getActionTypes(): IFormTypes {
      return createFormActionTypes(type);
    },
    getActions(): IFormActions {
      return makeFormActions(type);
    },
  };
}
