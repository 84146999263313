import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, NgControl, Validators } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-separate-date-inputs',
  templateUrl: './separate-date-inputs.component.html',
  styleUrls: ['./separate-date-inputs.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: SeparateDateInputsComponent,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SeparateDateInputsComponent implements MatFormFieldControl<any>, OnInit, OnDestroy {
  static nextId = 0;

  @ViewChild('month') month: ElementRef;

  private _placeholder: string;
  private _required = false;
  private _disabled = false;
  private _errorState = false;

  form: FormGroup;
  focused = false;
  ngControl: NgControl = null;
  controlType = 'app-month-day-year';
  stateChanges = new Subject<void>();

  @Output() onDateChange = new EventEmitter<string>();

  @Input()
  get value(): string {
    const { month, day, year } = this.form.value;

    return `${month}/${day}/${year}`;
  }

  set value(date: string) {
    if (date && date !== this.value) {
      const [month, day, year] = date.split('/');

      this.form.setValue({
        month,
        day,
        year,
      });

      this.stateChanges.next();
    }
  }

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(plh: string) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  get empty(): boolean {
    const n = this.form.value;

    return !n.month && !n.day && !n.year;
  }

  @HostBinding('class.floating')
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(req: boolean) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.form.disable() : this.form.enable();
    this.stateChanges.next();
  }

  get errorState(): boolean {
    return !!this._errorState;
  }

  set errorState(newValue: boolean) {
    this._errorState = newValue;
    this.stateChanges.next();
  }

  @HostBinding() id = `app-month-day-year-${SeparateDateInputsComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedBy = '';

  constructor(fb: FormBuilder, private fm: FocusMonitor, private elRef: ElementRef<HTMLElement>) {
    this.form = fb.group({
      month: [null, [Validators.min(1), Validators.max(12)]],
      day: [null, [Validators.min(1), Validators.max(31)]],
      year: [null, [Validators.minLength(4)]],
    });

    fm.monitor(elRef.nativeElement, true).subscribe((origin) => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  ngOnInit(): void {
    this.form.valueChanges.pipe(delay(0)).subscribe((changes) => {
      if (this.form.valid) {
        this.onDateChange.emit(`${changes.month}/${changes.day}/${changes.year}`);
      }
    });
  }

  setDescribedByIds(ids: string[]) {
    // this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this.elRef.nativeElement.querySelector('input').focus();
    }
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef.nativeElement);
  }
}
