export enum BulkAction {
  UPDATE_MAX_BUY = 'updateMaxBuy',
  CONFIRM_UPDATE_MAX_BUY = 'confirmUpdateMaxBuy',
  COPY_LOADS = 'copyLoads',
  ASSIGN_REPS = 'assignReps',
  PRIORITY_CHANGE = 'priorityChange',
  ADJUST_PERCENTAGE = 'adjustPercentage',
  CONFIRM_UPDATE_ADJUST_PERCENTAGE = 'confirmUpdateAdjustPercentage',
  ADJUST_DOLLAR_AMOUNT = 'adjustDollarAmount',
  CONFIRM_UPDATE_ADJUST_DOLLAR_AMOUNT = 'confirmUpdateAdjustDollarAmount',
  REPORT_ROLLED_LOADS = 'reportRolledLoads',
  DELETE_MISSION = 'deleteMission',
  EXPORT_CSV = 'exportCSV'
}

export enum BulkActionPage {
  SEARCH = 'search',
  LANE_RATE = 'laneRate',
  CARRIER_SEARCH = 'carrierSearch',
  MISSION = 'mission',
  NONE = 'none',
}
