import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpStatus, LoadsServiceLoad, LoadsServiceLoadInput } from '@haulynx/types';
import { Store } from '@ngrx/store';
import { Map } from 'immutable';
import { Moment } from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppState } from '../../main-store/app.reducers';
import { LoadActiveActions } from './load.actions';
import { getIsLoadActiveLoading, getLoadActiveHttpStatus, isNotesButtonVisible } from './load.selectors';

@Injectable({
  providedIn: 'root',
})
export class LoadActiveModel {
  isLoading$: Observable<Map<string, boolean>> = this.store.select(getIsLoadActiveLoading);
  httpStatus$: Observable<Map<string, HttpStatus>> = this.store.select(getLoadActiveHttpStatus);
  showNotesButton$: Observable<boolean> = this.store.select(isNotesButtonVisible);
  selectedLoadId$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private store: Store<AppState>, private router: Router, private activatedRoute: ActivatedRoute) {}

  assigned(data: { loadId: string; load: LoadsServiceLoad }) {
    this.store.dispatch(LoadActiveActions.assigned(data));
  }

  reject(data: { loadId: string; carrierDot: string; brokerId: string; rejectionReason: string }) {
    this.store.dispatch(LoadActiveActions.reject(data));
  }

  dispatch(data) {
    this.store.dispatch(LoadActiveActions.dispatch(data));
  }

  confirmPickup(data: { loadId: string; data: { entranceTime?: Moment; completed?: Moment }; position: number }) {
    this.store.dispatch(LoadActiveActions.confirmPickUp(data));
  }

  completeDelivery(data: { loadId: string; data: { entranceTime?: Moment; completed?: Moment }; position: number }) {
    this.store.dispatch(LoadActiveActions.completeDelivery(data));
  }

  close(data: { loadId: string }) {
    this.store.dispatch(LoadActiveActions.close(data));
  }

  goTo(url: string) {
    this.router.navigate([url], { relativeTo: this.activatedRoute });
  }

  updateLoad(data: { load: Partial<LoadsServiceLoadInput>; refreshLoad?: boolean; id: string }) {
    this.store.dispatch(LoadActiveActions.update(data));
  }

  showNotesButton(data: boolean): void {
    this.store.dispatch(LoadActiveActions.showNotesButton(data));
  }

  changeSelectedLoadId(loadId: string) {
    this.selectedLoadId$.next(loadId);
  }
}
