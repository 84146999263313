import { Injectable } from '@angular/core';
import { LoadsServiceService } from '@haulynx/services';
import { ActiveBrokeredLoadsAsLoadsParams, PageAndSort } from '@haulynx/types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '../../main-store/app.reducers';
import { DispatchAction } from '../../shared/store/helpers/action';
import { LoadOffersSearchActions, LoadOffersSearchActionTypes } from './load.actions';
import { loadOffersSearchSelector } from './load.selectors';

@Injectable()
export class LoadOffersEffects {
  loadSearch = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadOffersSearchActionTypes.SEARCH),
      map((action: DispatchAction) => action.payload),
      withLatestFrom(
        this.store.select(loadOffersSearchSelector.getGraphQlPaging),
        this.store.select(loadOffersSearchSelector.getQuery)
      ),
      switchMap(([filter, paging, query]) => {
        const searchFields: { filterParams: ActiveBrokeredLoadsAsLoadsParams; paging: Partial<PageAndSort> } = {
          filterParams: {
            dot: query.dot || undefined,
          },
          paging: paging,
        };
        return this.loadsServiceService.getLoadsServiceLoadsBrokered(searchFields).pipe(
          map((result) => LoadOffersSearchActions.searchSuccess(result)),
          catchError((errors) => of(LoadOffersSearchActions.searchError(errors)))
        );
      })
    )
  );
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private loadsServiceService: LoadsServiceService
  ) {}
}
