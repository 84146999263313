import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { NgModule } from '@angular/core';
import { RadioGroupComponent } from './radio-group.component';
import { MaterialModule } from '@haulynx/modules';

@NgModule({
  imports: [CommonModule, FormsModule, MaterialModule],
  exports: [RadioGroupComponent],
  declarations: [RadioGroupComponent],
})
export class RadioGroupModule {}
