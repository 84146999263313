import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CommonEntitiesEffects } from '../../shared/common-entities/common-entities.effects';
import { loadRouteStateProviders, LOAD_ROUTE_ENTITY_REDUCERS_TOKEN } from './config';
import { loadRouteEntityNamespace } from './namespace';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(loadRouteEntityNamespace, LOAD_ROUTE_ENTITY_REDUCERS_TOKEN),
    EffectsModule.forFeature([CommonEntitiesEffects]),
  ],
  providers: [...loadRouteStateProviders],
})
export class AsyncEntityLoadRouteStoreModule {}
