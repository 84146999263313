import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { buttonTypes } from '@haulynx/types';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-load-active-pick-up',
  templateUrl: './load-active-pick-up.component.html',
  styleUrls: ['./load-active-pick-up.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadActivePickUpComponent implements OnInit {
  title = 'LOAD PICKUP';
  buttons$ = new BehaviorSubject([]);
  allButtons = { ...buttonTypes };

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      load: any;
    } = {
      load: null,
    },
    public dialogRef: MatDialogRef<LoadActivePickUpComponent>
  ) {}

  ngOnInit(): void {
    this.buttons$.next([this.allButtons.CONFIRM_PICKUP]);
  }

  action(action) {
    if (action === this.allButtons.CONFIRM_PICKUP.action) {
      this.dialogRef.close({ action, data: true });
    }
  }
}
