import gql from 'graphql-tag';

export const GetOpportunity = gql`
  query GetOpportunity($opportunityParams: OpportunityParamsInput) {
    getOpportunity(opportunityParams: $opportunityParams) {
      freightId
      carrierId
      recommendationId
      brokerId
      score
    }
  }
`;
