import { KeyValuePair, RolledReasonCode } from '@haulynx/types';

export const reasons: KeyValuePair[] = [
  {
    key: RolledReasonCode.WEATHER,
    value: 'Weather',
  },
  {
    key: RolledReasonCode.ORIGIN_VOLUME,
    value: 'Origin Volume',
  },
  {
    key: RolledReasonCode.RATE,
    value: 'Rate',
  },
  {
    key: RolledReasonCode.BOUNCE_GENERAL,
    value: 'Bounce - General',
  },
  {
    key: RolledReasonCode.BOUNCE_BREAKDOWN,
    value: 'Bounce - Prev Load / Breakdown',
  },
  {
    key: RolledReasonCode.LOW_LEAD_TIME,
    value: 'Low Lead Time',
  },
  {
    key: RolledReasonCode.CAPACITY,
    value: 'Capacity',
  },
  {
    key: RolledReasonCode.NO_QUALIFIED_CARRIERS,
    value: 'No Qualified Carriers (HVP,TEAM)',
  },
  {
    key: RolledReasonCode.NO_USX_INTERCHANGE_CARRIERS,
    value: 'No USX Interchange Carriers',
  },
  {
    key: RolledReasonCode.APPOINTMENTS,
    value: 'Appointments / Transit issue',
  },
  {
    key: RolledReasonCode.SHIPPER_RELATED_ISSUE,
    value: 'Shipper Related Issue',
  },
  {
    key: RolledReasonCode.TRANSIT_RELATED_ISSUE,
    value: 'Transit Related Issue',
  },
  {
    key: RolledReasonCode.OTHER_CARRIER_RELATED,
    value: 'Other - Carrier Related',
  },
  {
    key: RolledReasonCode.OTHER_CX_RELATED,
    value: 'Other - CX Related',
  },
];
