import { Pipe, PipeTransform } from '@angular/core';
import { nth } from 'lodash';
import { LoadLocation, LoadsServiceLoadLocation } from '@haulynx/types';

@Pipe({ name: 'waypointClass' })
export class LoadWaypointClassPipe implements PipeTransform {
  transform(locations: Array<LoadLocation | LoadsServiceLoadLocation>, index: number): WaypointClassType {
    if (index === 0) {
      return WaypointClassType.ORIGIN;
    }

    if (index === locations.length - 1) {
      return WaypointClassType.DESTINATION;
    }

    const location = nth(locations, index);

    return location.locationType === 'pickup' ? WaypointClassType.PICKUP : WaypointClassType.DROPOFF;
  }
}

export enum WaypointClassType {
  ORIGIN = 'origin',
  DESTINATION = 'destination',
  PICKUP = 'pickup',
  DROPOFF = 'dropoff',
}
