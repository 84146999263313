<div class="timeline-container">
  <div
    class="timeline-wrapper"
    *ngFor="let location of load?.locations; let $last = last; let $first = first; let $index = index"
  >
    <div class="timeline-item">
      <div
        class="timeline-item__icon--{{ isSpaciousIcons ? 'large' : 'small' }}{{
          showTimelineBorder ? '' : '--no-border'
        }}"
      >
        <div class="timeline-item__icon--{{ load?.locations | waypointClass: $index }}"></div>
      </div>
      <div
        *ngIf="showTimelineBorder"
        [ngClass]="{ 'timeline-border': !$last, 'timeline-badges': showTimeWaitStatus }"
      ></div>

      <div class="timeline-item__content--{{ isSpaciousIcons ? 'large' : 'small' }}">
        <ng-template #notesButton>
          <div *ngIf="showNotesButton" class="timeline-item__notes">
            <button mat-icon-button class="timeline-item__button" data-test="specialNotesButton">
              <mat-icon (click)="onShowSpecialNote($index)">notes</mat-icon>
            </button>
          </div>
        </ng-template>

        <div class="load_address_type" *ngIf="showLocationActions">
          {{ $first ? 'Origin' : $last ? 'Destination' : (location | waypointType) }}
        </div>

        <div
          class="load_address_facility"
          data-test="load-address-facility"
          *ngIf="load | canViewFacility: user:location"
          (click)="onShowFacilityInfo(location?.customer?.number, $index)"
        >
          {{ location?.customer?.name }}
        </div>
        <div *ngIf="location?.customer?.customerAttributes && showCustomerAttributes">
          <ng-container *ngIf="location?.customer?.customerAttributes[0]?.hasFacilities">
            <mat-icon class="restroom-icon" svgIcon="haulynx-restroom"></mat-icon>
          </ng-container>

          <ng-container *ngIf="location?.customer?.customerAttributes[0]?.canBreakAtFacility">
            <mat-icon class="moon-icon" svgIcon="haulynx-moon"></mat-icon>
          </ng-container>
        </div>

        <ng-container
          *ngIf="
            load?.bookStatus && load?.bookStatus !== 'booked' && load?.bookStatus !== 'pause' && !isBroker;
            else notPrivate
          "
        >
          <div
            class="load_address"
            [ngClass]="{ 'load_address--normal': load | canViewFacility: user:location }"
            data-test="private-load-address"
            [ngStyle]="{ 'margin-top': isSpaciousIcons && !showLocationActions ? '33px' : '0px' }"
          >
            {{ location | loadAddressPrivate }}
            <ng-container *ngTemplateOutlet="notesButton"></ng-container>
          </div>
        </ng-container>
        <ng-template #notPrivate>
          <div
            class="load_address"
            [ngClass]="{ 'load_address--normal': load | canViewFacility: user:location }"
            data-test="public-load-address"
            [ngStyle]="{ 'margin-top': isSpaciousIcons && !showLocationActions ? '33px' : '0px' }"
          >
            {{ location | loadAddressPrivate: false }}
            <ng-container *ngTemplateOutlet="notesButton"></ng-container>
          </div>
        </ng-template>

        <div *ngIf="showTimeWaitStatus" [ngClass]="(location | loadLocationWaitTimeStatus) + ' load-feed-badge'">
          {{ location | loadLocationWaitTimeStatus | titlecase }}
        </div>
        <div *ngIf="showDateTimes">
          <span
            class="muted"
            *ngIf="location.appointmentSet; else !!load.broker && noPickupApp"
            data-test="public-load-time"
          >
            {{ location | loadServiceLocationTime: { timeFormat: 'd MMM yyyy HH:mm z', showLabel: true } }}
          </span>
          <ng-template #noPickupApp>
            <button
              class="noAppointment"
              mat-raised-button
              [matTooltip]="load?.providerDetails?.regionalManagerPhone | getApptText"
              matTooltipClass="no-appt-tooltip"
              data-test="no-appt"
            >
              <div class="no-appt-button" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="4px">
                <span>No Appt <mat-icon class="exclamation">error_outline</mat-icon></span>
              </div>
            </button>
          </ng-template>

          <div *ngIf="location?.customer?.customerAttributes">
            <div class="early-delivery" *ngIf="location?.locationType === 'dropoff'">
              <ng-container *ngIf="location.customer?.customerAttributes[0]?.canDeliverEarly">
                Early delivery available
              </ng-container>
            </div>
            <div class="early-pickup" *ngIf="location.locationType === 'pickup'">
              <ng-container *ngIf="location.customer?.customerAttributes[0]?.canPickUpEarly">
                Early pickup available
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="showSaveLocation" class="timeline-item__location"></div>
    </div>
  </div>
</div>
