export interface AssignLoadEditForm {
  internalNotes: string;
  externalNotes: string;
  customerNotes: string;
  rateConEmails: RateConEmail[];
}

export interface RateConEmail {
  emailRecipient: string;
  message?: string;
}
