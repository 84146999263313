<div class="carrier-info-container">
  <form [formGroup]="carrierForm" class="form">
    <mat-form-field class="app-dark-field" appearance="fill" floatLabel="always">
      <label class="label"> Carrier </label>
      <app-drop-down
        class="form-control"
        [placeholder]="'MC/DOT/Carrier'"
        formControlName="carrier"
        [data]="carriers"
        [changeableData]="true"
        [customFilter]="true"
        [isLoading]="isLoadingCarriers"
        displayLabel="name"
        key="dot"
        (keywordChanges)="onValueChanges($event)"
        (selected)="selectCarrier()"
      >
      </app-drop-down>
    </mat-form-field>
    <mat-form-field class="app-dark-field" appearance="fill" floatLabel="always">
      <label class="label"> MC # </label>
      <input class="form-control" matInput formControlName="mcNumber" placeholder="" />
    </mat-form-field>
    <mat-form-field class="app-dark-field" appearance="fill" floatLabel="always">
      <label class="label"> Contact </label>
      <input class="form-control" matInput formControlName="contact" placeholder="" />
    </mat-form-field>
    <mat-form-field class="app-dark-field" appearance="fill" floatLabel="always">
      <label class="label"> Email </label>
      <input class="form-control" matInput formControlName="email" placeholder="" />
    </mat-form-field>
    <mat-form-field class="app-dark-field" appearance="fill" floatLabel="always">
      <label class="label"> Phone </label>
      <input class="form-control" matInput mask="000-000-0000" formControlName="phone" placeholder="" />
    </mat-form-field>
  </form>
  <form [formGroup]="assignmentForm" class="form">
    <mat-form-field
      *ngIf="featureFlags[trackingFeatureFlag]"
      class="app-dark-field"
      appearance="fill"
      floatLabel="always"
    >
      <label class="label"> Tracking </label>
      <app-drop-down
        class="form-control"
        placeholder="/"
        formControlName="trackingType"
        displayLabel="label"
        key="value"
        [data]="trackingOptions"
        [changeableData]="true"
        (selected)="selectAssignment(entityTypes.TRACKING_TYPES)"
      >
      </app-drop-down>
    </mat-form-field>

    <mat-form-field class="app-dark-field" appearance="fill" floatLabel="always">
      <label class="label"> Truck </label>
      <app-drop-down
        #truckList
        class="form-control"
        placeholder="/"
        formControlName="truckId"
        displayLabel="unitId"
        [data]="trucks"
        [changeableData]="true"
        [isLoading]="isLoadingTrucks"
        (selected)="selectAssignment(entityTypes.TRUCKS)"
      >
      </app-drop-down>
    </mat-form-field>
    <mat-error *ngIf="assignmentForm.get('truckId').errors">*</mat-error>

    <mat-form-field class="app-dark-field" appearance="fill" floatLabel="always">
      <label class="label"> Driver </label>
      <app-drop-down
        #driverList
        class="form-control"
        placeholder="/"
        formControlName="driverId"
        displayLabel="name"
        [data]="drivers"
        [changeableData]="true"
        [isLoading]="isLoadingDrivers"
        (selected)="selectAssignment(entityTypes.DRIVERS)"
      >
      </app-drop-down>
    </mat-form-field>
    <mat-error *ngIf="assignmentForm.get('driverId').errors">*</mat-error>

    <mat-form-field class="app-dark-field" appearance="fill" floatLabel="always">
      <label class="label"> Trailer </label>
      <app-drop-down
        #trailerList
        class="form-control"
        placeholder="/"
        formControlName="trailerId"
        displayLabel="trailerNumber"
        [data]="trailers"
        [changeableData]="true"
        [isLoading]="isLoadingTrailers"
        (selected)="selectAssignment(entityTypes.TRAILERS)"
      >
      </app-drop-down>
    </mat-form-field>

    <mat-form-field class="app-dark-field" appearance="fill" floatLabel="always">
      <label class="label"> Phone </label>
      <input
        matInput
        mask="000-000-0000"
        class="form-control"
        formControlName="phone"
        placeholder="/"
        [readonly]="true"
      />
    </mat-form-field>
    <mat-error *ngIf="assignmentForm.get('phone').errors">*</mat-error>
  </form>
</div>
