import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-in-progress',
  templateUrl: './in-progress.component.html',
  styleUrls: ['./in-progress.component.scss'],
})
export class InProgressComponent {
  @Input() label = 'In progress';
  @Input() styles = 0;
  @Input() active = false;
}
