<div *ngIf="showNewToolbar$ | async; else brokerHeader">
  <haulynx-header
    [user]="user"
    [url]="router.url"
    [headerItems]="dashboardModel.headerMenu$ | async"
    (startTour)="startTour()"
    [totalNotifications]="notificationModel.totalNotifications$ | async"
    (showNotifications)="showNotifications()"
    (showVerifyEmail)="showVerifyEmail()"
    (goToHelp)="goToHelp()"
    (logout)="logout()"
  >
    <router-outlet outlet></router-outlet>
  </haulynx-header>
</div>

<ng-template #brokerHeader>
  <mat-sidenav-container autosize>
    <mat-sidenav-content class="sidenav-content">
      <app-toolbar
        [user]="user"
        [menuItems]="dashboardModel.menu$ | async"
        [reportMode]="reportMode"
        [url]="router.url"
        (goToSignup)="goToSignup()"
        (goToLogin)="goToLogin()"
        (startTour)="startTour()"
        (goToAccount)="goToAccount()"
        (goToOrders)="goToOrders()"
        (goToHelp)="goToHelp()"
        (goToLogs)="goToLogs()"
        (logout)="logout()"
        [totalNotifications]="notificationModel.totalNotifications$ | async"
        (showNotifications)="showNotifications()"
        (showVerifyEmail)="showVerifyEmail()"
      >
      </app-toolbar>

      <div class="{{ !reportMode ? 'main' : '' }}" [ngStyle]="{ padding: isMilestones ? '0px' : '3px 16px 20px 16px' }">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-template>

<app-notification-center
  [notifications]="notificationModel.notifications$ | async"
  [showNotification]="notificationModel.showNotification$ | async"
  [eventTypes]="notificationModel.notificationEventTypes$ | async"
  [query]="notificationModel.query$ | async"
  [isLoading]="notificationModel.isLoading$ | async"
  [user]="user"
  (selectNotification)="selectNotification($event)"
  (closeNotification)="closeNotification($event)"
  (search)="searchNotification($event)"
  data-test="notification-panel"
></app-notification-center>

<ng-container *appFeatures="featureFlag">
  <app-tour #tour *ngIf="user?.carrier || user?.broker"></app-tour>
</ng-container>
