import gql from 'graphql-tag';

export const GetLoadsBrokered = gql(`
  query getLoadsBrokered($filterParams: BrokerBookedLoadFilterParams, $paging: PageAndSort) {
    getLoadsBrokered(filterParams: $filterParams, paging: $paging) {
      total
      result {
        loadsServiceLoad {
          id
          loadStatus
          created 
          createdBy
          lastUpdatedAt
          bookStatus
          region
          distributionMechanism
          carrier {
            id
            name
            dot
            sourceType
            isEldCarrier
          }
          broker {
            name
            id
          }
          assignedBrokers {
            id
            name
            usxId
          }
          truck {
            id
            unitId
          }
          trackingType
          provider {
            id
            name
            detailType
            company
          }
          providerDetails {
            billTo
            billToName
            cxPriority
            equipmentType
            commodity
            weight: weightLbs
            quantity
            packingType
            operationsPriority
            orderType
            brokerTeamId
            alternateIds {
              identifierType
              identifierValue
            }
            regionalManagerName
            regionalManagerEmail
            regionalManagerPhone
          }
          paymentDetails {
            price
            revenue
            maxBuy
            ratePerMile
            distanceMiles
          }
          restrictions {
            type
            value
          }
          locations {
            id
            address
            city
            state
            zipcode
            timezone
            appointmentSet
            appointmentStart
            appointmentEnd
            locationType
            billOfLading
            geometry {
              type
              coordinates
            }
            notes {
              author
              text
            }
            billOfLading
            distanceMiles
            customer {
              id
              name
              telephone
            }
            carrierArrival
            carrierDeparture
            summaryAddress
          }
          trailers {
            id
            trailerNumber
          }
          drivers {
            id
            name
            phone
          }
          bidDetails {
            offerCount
            lowestOffer
            lowestOfferCarrierName
          }
          consignee
          shipperName
          dispatchLocation {
            address
            lat
            lon
            timezone
            timestamp
            notes
          }
        }
      }
    }
  }
`);
