import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { FeatureFlagService } from '@haulynx/services';
import { UserEntityService } from '@haulynx/store';
import { FeatureFlag, FFState } from '@haulynx/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class LaneRateToolGuard implements CanActivate {
  constructor(
    private router: Router,
    private userEntityService: UserEntityService,
    private featureFlagService: FeatureFlagService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.userEntityService.featureFlags$.pipe(
      map((features: FFState) => {
        if (this.featureFlagService.checkFlag(features, [FeatureFlag.LANE_RATE_TOOL])) {
          return true;
        } else {
          this.router.navigateByUrl(`/dashboard/load-feeds/search`);
          return false;
        }
      })
    );
  }
}
