import { IColumns } from '@haulynx/types';

export const devicesColumns: IColumns[] = [
  {
    label: 'Carrier',
    dataIndex: 'carrier',
    isCustomCell: true,
  },
  {
    label: 'IMEI',
    dataIndex: 'imei',
    isCustomCell: true,
  },
  {
    label: 'Unit',
    dataIndex: 'unitId',
    isCustomCell: true,
  },
  {
    defaultValue: '',
    label: 'Driver',
    dataIndex: 'lastLocation.driverName',
  },
  {
    defaultValue: '',
    label: 'Last Location',
    dataIndex: 'lastLocation.approximateAddress',
  },
  {
    defaultValue: '',
    label: 'Last Updated',
    dataIndex: 'lastUpdated',
    isCustomCell: true,
  },
  {
    defaultValue: '',
    label: 'Shipping Info',
    dataIndex: 'shippedDate',
    isCustomCell: true,
  },
  {
    defaultValue: '',
    label: 'Return Info',
    dataIndex: 'returnInfo',
    isCustomCell: true,
  },
  {
    defaultValue: '',
    label: 'Write-off',
    dataIndex: 'writeOff',
    isCustomCell: true,
  },
  {
    defaultValue: '',
    label: 'Deactivated',
    dataIndex: 'deactivated',
    isCustomCell: true,
  },
  {
    label: '',
    dataIndex: 'actions',
    isCustomCell: true,
    width: '48px',
    isSortable: false,
  },
];
