import { AssignLoadCarrierFormMode } from './assign-load-carrier-form-mode';

export interface AssignLoadCarrierData {
  /**
   * Optionally provide a bidId to indicate that a bid will be accepted upon booking.
   */
  bidId?: string;
  carrierDot: string;
  loadId: string;
  brokerId?: string;
  userEmail?: string;
  formData?: Partial<{ price: number }>;
  formMode?: AssignLoadCarrierFormMode;
}
