import gql from 'graphql-tag';

export const GetDeviceByImeiLittleInfo = gql(`
  query GetDeviceByImei($imei: String!) {
    getDeviceByImei(imei: $imei) {
      loc {
        gpsLon
        gpsLat
        last_recorded_at
        approximateAddress
      }
    }
  }
`);
