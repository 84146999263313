<div
  class="carrier-bid-button-conatiner"
  fxLayout="row"
  fxLayoutAlign="center center"
  fxLayoutGap="16px"
  *ngIf="
    (load.bookStatus === 'bookable' || load.bookStatus === 'viewable' || load.bookStatus === 'excluded') &&
    load.carrierBid?.status !== BidStatusType.ACCEPTED
  "
>
  <button
    mat-raised-button
    class="accept-bid"
    *ngIf="load.carrierBid && load.carrierBid.activeCounterOffer && !(loadFeedModel.isLoadingCounterAccepted$ | async)"
    (click)="acceptCounterOffer()"
  >
    ACCEPT: {{ load.carrierBid.activeCounterOffer.price | currency: 'USD':'symbol':'1.2-2' }}
  </button>
  <mat-spinner *ngIf="loadFeedModel.isLoadingCounterAccepted$ | async" [diameter]="24" [strokeWidth]="4"></mat-spinner>

  <button
    mat-raised-button
    class="new-bid"
    (click)="createNewBid()"
    *ngIf="!(loadFeedModel.isLoadingBidCreate$ | async); else loadingCreate"
  >
    {{
      load?.carrierBid?.status === BidStatusType.NO_CAPACITY || load.carrierBid?.status === BidStatusType.AUTO_REJECTED
        ? 'PLACE NEW'
        : load?.carrierBid?.status
        ? 'UPDATE'
        : 'PLACE NEW'
    }}
    BID
  </button>
  <ng-template #loadingCreate>
    <mat-spinner [diameter]="24" [strokeWidth]="4"></mat-spinner>
  </ng-template>
</div>
