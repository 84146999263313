import { FormGroup } from '@angular/forms';
import { ISearchFilterType } from './search-filter.types';
import { Observable } from 'rxjs';
import { PlaceInfo } from '../generic';

export interface ISearchFilter {
  name: string; // Origin
  type: ISearchFilterType;
  keys: GeoSpacialFilterType | DateRangeFilterType | NumericalFilterType | TextFilterType;
  description: string;
  formattedAddress?: string;
  validation?: NumericalFilterType;
  formPrefix?: string;
  formSuffix?: string;
  shouldPersistInputText?: boolean;
  /**
   * Some values are actually numbers (like an Order number).
   * Knowing this data is useful when helping the user select a filter.
   */
  valueIsNumber?: boolean;
  /**
   * The priority for sorting a list of ISearchFilter objects
   */
  searchPriority?: number;
  /**
   * A function you can use to transform the value of this filter.
   * @example
   *
   *  transformValue = startCase // lodash function that will turn flatBed -> Flat Bed
   *
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  transformValue?: Function;
  clearValue?: () => void;
  modificationFunction?(
    form: FormGroup
  ): GeoSpacialFilterType | DateRangeFilterType | NumericalFilterType | TextFilterType;
  localFilterFn?: <T>(data: T[], searchbarVal: Record<string, unknown>) => T[];
}

interface IFilterType {
  paramNames: string[];
  assignQuery?: (query: string) => void;
}

export class GeoSpacialFilterType implements IFilterType {
  radius?: number;
  lat?: number;
  lon?: number;
  location?: string;
  radiusFormName?: string;
  latFormName?: string;
  lonFormName?: string;
  locationFormName?: string;
  placeInfo?: PlaceInfo;
  state?: string;
  stateFormName?: string;
  zipcode?: string;
  zipFormName?: string;
  index?: number;

  get paramNames(): string[] {
    return [
      this.radiusFormName,
      this.latFormName,
      this.lonFormName,
      this.locationFormName,
      this.stateFormName,
      this.zipFormName,
    ];
  }
  assignQuery(query: string): void {
    this.location = query;
  }

  constructor(obj?: Omit<GeoSpacialFilterType, 'paramNames' | 'assignQuery'>) {
    Object.keys(obj).forEach((key) => (this[key] = obj[key]));
  }
}

export class DateRangeFilterType implements IFilterType {
  from?: number | string;
  to?: number | string;
  fromFormName?: string;
  toFormName?: string;
  get paramNames(): string[] {
    return [this.fromFormName, this.toFormName];
  }

  constructor(obj?: Omit<DateRangeFilterType, 'paramNames'>) {
    Object.keys(obj).forEach((key) => (this[key] = obj[key]));
  }
}

export class NumericalFilterType implements IFilterType {
  min?: number;
  max?: number;
  minFormName?: string;
  maxFormName?: string;
  get paramNames(): string[] {
    return [this.minFormName, this.maxFormName];
  }

  constructor(obj?: Omit<NumericalFilterType, 'paramNames'>) {
    Object.keys(obj).forEach((key) => (this[key] = obj[key]));
  }
}

export class BooleanFilterType implements IFilterType {
  value?: boolean;
  textFormName?: string;

  get paramNames(): string[] {
    return [this.textFormName];
  }

  constructor(obj?: Omit<BooleanFilterType, 'paramNames'>) {
    Object.keys(obj).forEach((key) => (this[key] = obj[key]));
  }
}
export class TextFilterType implements IFilterType {
  value?: string | string[] | boolean;
  /**
   * this would be the dataIndex/indexes for an autocomplete dropdown for text filters. not built yet.
   */
  optionsDataIndex?: string | DropdownDisplay[] | Observable<DropdownDisplay[]>;
  numericalOnly?: boolean;
  textFormName?: string;

  get paramNames(): string[] {
    return [this.textFormName];
  }

  assignQuery(query: string): void {
    this.value = query;
  }

  constructor(obj?: Omit<TextFilterType, 'paramNames' | 'assignQuery'>) {
    Object.keys(obj).forEach((key) => (this[key] = obj[key]));
  }
}

export class MultiDropFilterType implements IFilterType {
  value?: string;
  /**
   * this would be the dataIndex/indexes for an autocomplete dropdown for text filters. not built yet.
   */
  optionsDataIndex?: string | DropdownDisplay[] | Observable<DropdownDisplay[]>;
  textFormName?: string;

  get paramNames(): string[] {
    return [this.textFormName];
  }

  constructor(obj?: Omit<MultiDropFilterType, 'paramNames'>) {
    Object.keys(obj).forEach((key) => (this[key] = obj[key]));
  }
}

export class TextArrayFilterType implements IFilterType {
  value?: string;
  /**
   * this would be the dataIndex/indexes for an autocomplete dropdown for text filters. not built yet.
   */
  optionsDataIndex?: string | DropdownDisplay[] | Observable<DropdownDisplay[]>;
  textFormName?: string;
  index?: number;

  get paramNames(): string[] {
    return [this.textFormName];
  }

  assignQuery(query: string): void {
    this.value = query;
  }

  constructor(obj?: Omit<TextArrayFilterType, 'paramNames' | 'assignQuery'>) {
    Object.keys(obj).forEach((key) => (this[key] = obj[key]));
  }
}

export type DropdownDisplay = {
  key: string;
  value: string;
  isChecked?: boolean;
  metaData?: Record<string, unknown>;
};

export enum TextArrayMode {
  CREATE = 'create',
  UPDATE = 'update',
}

export interface SearchFilter {
  query?: Query;
  pagination?: IPagination;
}

interface Query {
  [key: string]: any;
}

export interface IPagination {
  total?: number;
  limit: number;
  page: number;
}
