import { Component, Input, OnInit } from '@angular/core';
import {
  LoadsServiceLoad,
  Milestone,
  MilestoneLog,
  MilestoneSubtype,
  MilestoneType,
  AssetBridgeTrailerTelemetryHistory,
} from '@haulynx/types';
import { formatDistance } from 'date-fns';

@Component({
  selector: 'haulynx-milestone-sub-item-system',
  templateUrl: './milestone-sub-item-system.component.html',
  styleUrls: ['./milestone-sub-item-system.component.scss'],
})
export class MilestoneSubItemSystemComponent implements OnInit {
  @Input() milestone: Milestone;
  @Input() log: MilestoneLog;
  @Input() user: string;
  @Input() load: LoadsServiceLoad;
  @Input() telemetryHistory: AssetBridgeTrailerTelemetryHistory;
  @Input() locationFromLatLong: string;

  ngOnInit(): void {}

  get isMilestoneTypeLocation(): boolean {
    return this.milestone.type === MilestoneType.LOCATION;
  }

  get isMilestoneTypeTrailer(): boolean {
    return !!this.telemetryHistory;
  }

  get telemetryLocation(): string {
    return this.locationFromLatLong;
  }

  get generateTimeInFacility(): string {
    if (this.telemetryHistory) {
      return '';
    }
    return formatDistance(this.log.primaryEvent.timestamp, this.log.secondaryEvent.timestamp, {
      includeSeconds: false,
    });
  }

  get getBackgroundColor(): string {
    let color = '';
    switch (this.user) {
      case 'broker': {
        color = '#FFFFFF';
        break;
      }
      case 'carrier': {
        color = '#AFB6CC';
        break;
      }
    }
    return color;
  }

  get displayTimeIn(): boolean {
    if (this.telemetryHistory) {
      return false;
    }
    if (this.milestone.type === MilestoneType.UPDATE) {
      return false;
    } else if (this.log.primaryEvent.timestamp) {
      return true;
    } else {
      return false;
    }
  }

  get displayTimeOut(): boolean {
    if (this.telemetryHistory) {
      return false;
    }
    if (this.milestone.type === MilestoneType.UPDATE) {
      return false;
    } else if (this.log.secondaryEvent && this.log.secondaryEvent.timestamp) {
      return true;
    } else {
      return false;
    }
  }

  get diplayTimeInTitle(): string {
    if (this.telemetryHistory) {
      return '';
    }
    if (
      this.milestone.type === MilestoneType.CHECKPOINT &&
      !this.milestone.logs.find((log) => log.subType === MilestoneSubtype.DISPATCH)
    ) {
      return 'Facility Entry:';
    } else {
      return 'Dispatch Empty:';
    }
  }

  get formatSubType(): string {
    if (this.telemetryHistory) {
      return '';
    }
    if (this.log.subType === MilestoneSubtype.LOCATION) {
      return 'General Location Update';
    } else {
      return this.log.subType;
    }
  }

  get formatTimeZone(): string {
    return this.load.locations.find((loc) => loc.id === this.milestone.locationId).timezone;
  }
}
