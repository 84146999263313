import { ISearchFilter, NumericalFilterType } from '../search-filter';
import { ISearchFilterType } from '../search-filter.types';

export class DistanceFilter implements ISearchFilter {
  name = 'Distance';
  type: ISearchFilterType = ISearchFilterType.NUMBER_RANGE;
  keys: NumericalFilterType;
  validation: NumericalFilterType;
  description = 'Filter loads by the distance';
  formSuffix = 'mi';

  constructor(defaultValues: Partial<NumericalFilterType> = {}, validationValues: Partial<NumericalFilterType> = {}) {
    this.keys = new NumericalFilterType({
      min: 0,
      max: null,
      minFormName: 'minDistanceMiles',
      maxFormName: 'maxDistanceMiles',
      ...defaultValues,
    });
    this.validation = new NumericalFilterType(validationValues);
  }
}
