import { MapsAPILoader } from '@agm/core';
import { MapsEventListener } from '@agm/core/services/google-maps-types';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, FormControlName } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MomentService } from '@haulynx/services';
import { GeocodingEntityService, UserEntityService } from '@haulynx/store';
import { AddressField, KeyValuePair, PlaceCountries, PlaceInfo, PlaceType, User } from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { get, head, last, trim } from 'lodash';
import { BehaviorSubject, combineLatest, fromEvent, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, pairwise, share, takeUntil } from 'rxjs/operators';
import { AddressTypes, KeyboardCodes, KeyboardKeys } from '../google-address-field.config';

@Component({
  selector: 'app-address-field',
  templateUrl: './app-address-field.component.html',
  styleUrls: ['./app-address-field.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: AppAddressFieldComponent,
    },
  ],
})
export class AppAddressFieldComponent implements ControlValueAccessor, OnInit, OnDestroy, MatFormFieldControl<unknown> {
  @HostBinding('class.floating')
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  @ViewChild('addressInputElement', { static: true }) public addressElement: ElementRef;

  @Input() showOnlyCities = false;
  @Input() countryRestrictions = ['us'];
  @Input() icon = null;
  @Input() error: string;
  @Input() customClass: string;
  @Output() onIconClick = new EventEmitter();
  @Output() onPlaceChange = new EventEmitter();
  @Output() stateSelected = new EventEmitter<boolean>();

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(req: boolean) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  @Input()
  set placeholder(newValue: string) {
    this._placeholder = newValue;
    this.stateChanges.next();
  }

  get placeholder(): string {
    return this._placeholder;
  }

  set value(newValue: AddressField) {
    const { lat = null, lon = null, address = null, timeZone = null } = newValue || {};

    this._value = { lat, lon, address, timeZone, id: null };
  }

  get value(): AddressField {
    return this._value;
  }

  get empty(): boolean {
    return !this.value;
  }

  set focused(newValue: boolean) {
    this._focused = newValue;
  }

  get focused(): boolean {
    return this._focused;
  }

  get errorState(): boolean {
    return !!this._errorState;
  }

  set errorState(newValue: boolean) {
    this._errorState = newValue;
  }

  // Todo: implement ids
  readonly id: string;
  readonly autofilled: boolean;
  readonly controlType: string;
  readonly stateChanges: Subject<void> = new Subject<void>();
  onTouch: (value) => unknown;
  isLoading$ = new BehaviorSubject(false);
  control: FormControl | AbstractControl | null = new FormControl();
  alive = aliveWhile();
  tabPressed = false;
  private _value: AddressField = null;
  private _placeholder: string = null;
  private _required = false;
  private _disabled = false;
  private _focused = false;
  private _errorState = false;
  private mapsAPIPromise: Promise<void>;
  private autoCompleteListener: MapsEventListener;
  private propagateChanges: (value) => unknown;
  options$ = new Observable<string[]>();
  user$ = new Observable<User>();
  previousValue = '';
  places: PlaceInfo[] = [];
  countryRestrictionKey: KeyValuePair[] = [
    { key: 'us', value: PlaceCountries.USA },
    { key: 'ca', value: PlaceCountries.CANADA },
    { key: 'mx', value: PlaceCountries.MEXICO },
  ];

  constructor(
    @Optional() @Self() public ngControl: FormControlName,
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
    private momentService: MomentService,
    public geocodingEntity: GeocodingEntityService,
    private mapsApiLoader: MapsAPILoader,
    private userEntityService: UserEntityService
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.fm
      .monitor(this.elRef.nativeElement, true)
      .pipe(takeUntil(this.alive))
      .subscribe((origin) => {
        this.focused = !!origin;
        this.stateChanges.next();
      });
  }

  ngOnInit(): void {
    fromEvent(this.addressElement.nativeElement, 'keydown')
      .pipe(
        takeUntil(this.alive),
        pairwise(),
        filter(([prev, curr]: [KeyboardEvent, KeyboardEvent]) => curr.code === KeyboardKeys.Tab)
      )
      .subscribe(([prev, curr]: [KeyboardEvent, KeyboardEvent]) => {
        const noop = () => {};
        const eventCode =
          prev.code === KeyboardKeys.ArrowDown || prev.code === KeyboardKeys.ArrowUp
            ? KeyboardCodes.Enter
            : KeyboardCodes.ArrowDown;

        google.maps.event.trigger(this.addressElement.nativeElement, 'keydown', {
          keyCode: eventCode,
          stopPropagation: noop,
          preventDefault: noop,
        });
      });

    const controlEvent = this.control.valueChanges.pipe(
      takeUntil(this.alive),
      debounceTime(400),
      distinctUntilChanged(),
      share()
    );

    this.options$ = combineLatest(
      controlEvent,
      this.geocodingEntity.getGeocodeAutocompleteManager.isSearching$,
      this.geocodingEntity.getGeocodeAutocompleteManager.searchResults$
    ).pipe(
      filter(([control, searching, locations]) => !(control && searching)),
      map(([control, searching, locations]) => {
        this.places = [];
        const places = locations;

        this.countryRestrictions.forEach((country) => {
          const key = this.countryRestrictionKey.find((restrictionKey) => restrictionKey.key === country);
          this.places = [
            ...this.places,
            ...places.filter((place) => {
              return key.value === place.country;
            }),
          ];
        });

        const options = this.control.value
          ? (<PlaceInfo[]>this.places).map((location) => {
              return location.fullAddress;
            })
          : [];

        return options;
      })
    );

    /*
     * If user is accessing this page not logged in then use google geocoding
     * Otherwise proceed with the graphql geocoding call. We don't was users to be
     * redirected to the login page
     */
    combineLatest(controlEvent, this.userEntityService.user$)
      .pipe(takeUntil(this.alive))
      .subscribe(([autocompleteInput, user]) => {
        if (user) {
          if (autocompleteInput !== this.previousValue && autocompleteInput) {
            const queryInput = this.showOnlyCities
              ? { search: autocompleteInput, autocomplete: true, limit: 10, placeType: PlaceType.place }
              : { search: autocompleteInput, autocomplete: true, limit: 10 };

            this.geocodingEntity.getGeocodeAutocompleteManager.dispatch({
              query: queryInput,
            });
          }
          this.previousValue = autocompleteInput;
        }
      });

    this.userEntityService.user$.pipe(takeUntil(this.alive)).subscribe((user: User) => {
      if (!user) {
        this.mapsAPIPromise = this.mapsApiLoader.load();
        this.mapsAPIPromise.then(() => {
          const options = {
            types: ['(cities)'],
            componentRestrictions: { country: this.countryRestrictions },
          };
          const autoCompleteWrapper = new google.maps.places.Autocomplete(
            this.addressElement.nativeElement,
            this.showOnlyCities ? options : null
          );

          this.autoCompleteListener = autoCompleteWrapper.addListener('place_changed', () => {
            const place: google.maps.places.PlaceResult = autoCompleteWrapper.getPlace();

            if (!place.geometry) {
              return;
            }

            const lat = place.geometry.location.lat();
            const lon = place.geometry.location.lng();
            const address = trim(place.formatted_address);
            const timeZone = this.momentService.getTimeZoneByLocation(lat, lon);

            this.value = { lat, lon, address, timeZone };
            this.isLoading$.next(false);
            this.control.patchValue(this.value.address);
            this.propagateChanges(this.value);
            this.onPlaceChange.emit(new Event('noop'));
            this.stateSelected.emit(this.isState(place.types));

            this.errorState = !!this.ngControl.errors;
            this.stateChanges.next();
          });
        });
      }
    });
  }

  onSelect(option) {
    const place = this.places.find((place) => {
      return place.fullAddress === option;
    });

    if (!place) {
      return;
    }

    const lat = place.lat;
    const lon = place.lon;
    const address = trim(place.fullAddress);
    const timeZone = this.momentService.getTimeZoneByLocation(lat, lon);

    this.value = { lat, lon, address, timeZone };
    this.isLoading$.next(false);
    this.control.patchValue(this.value.address);
    this.propagateChanges(this.value);
    this.onPlaceChange.emit(new Event('noop'));
    this.stateSelected.emit(place.state && !place.street ? true : false);

    this.errorState = !!this.ngControl.errors;
    this.stateChanges.next();
  }

  onEnter(e: KeyboardEvent): void {
    e.preventDefault(); // don't submit the form when a user clicks the enter key
  }
  onTab() {
    this.tabPressed = !this.tabPressed;
  }

  onBlur(e: FocusEvent): void {
    const value = get(e, 'target.value', null);
    const selectedAddress = get(this.value, 'address', null);
    if (!this.tabPressed) {
      if (value !== selectedAddress) {
        this.value = { lat: null, lon: null, address: null, timeZone: null };
        this.isLoading$.next(false);
        this.control.patchValue(null);
        this.propagateChanges(this.value);

        this.errorState = !!this.ngControl.errors;
        this.stateChanges.next();
      }
    } else {
      this.tabPressed = !this.tabPressed;
    }
  }

  iconClick(event): void {
    this.onIconClick.emit();
  }

  setDescribedByIds(ids: string[]): void {
    // throw new Error("Method not implemented.");
  }

  onContainerClick(event: MouseEvent): void {
    // throw new Error("Method not implemented.");
  }

  change(event): void {
    const value = get(event, 'target.value', null);
    if (!value) {
      this.value = null;
      this.control.patchValue(this.value.address);
      this.propagateChanges(this.value.address ? this.value : null);
    }

    this.errorState = !!this.ngControl.errors;
    this.stateChanges.next();
  }

  writeValue(value: AddressField): void {
    this.value = value;
    this.control.patchValue(this.value.address);
  }

  registerOnChange(fn: () => unknown): void {
    this.propagateChanges = fn;
  }

  registerOnTouched(fn: () => unknown): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;

    if (this.disabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  ngOnDestroy(): void {
    this.alive.destroy();

    if (this.autoCompleteListener) {
      this.autoCompleteListener.remove();
    }
  }

  private isState(types: string[] = []): boolean {
    return types.length === 2 && head(types) === AddressTypes.STATE && last(types) === AddressTypes.COUNTRY;
  }
}
