export const defaultCustomerAttributes: CustomerAttributeOption[] = [
  { key: 'canBreakAtFacility', label: 'Can Break at Customer', option: 'N/A' },
  { key: 'canDeliverEarly', label: 'Can Deliver Early', option: 'N/A' },
  { key: 'canPickUpEarly', label: 'Can Pickup Early', option: 'N/A' },
  { key: 'dropYard', label: 'Drop Yard', option: 'N/A' },
  { key: 'hasFacilities', label: 'Has Driver Facilities', option: 'N/A' },
];

export interface CustomerAttributeOption {
  key: string;
  label: string;
  option?: string;
  order?: number;
}

export enum AttributeOptionTypes {
  TRUE = 'Yes',
  FALSE = 'No',
  NOT_AVAILABLE = 'N/A',
}
