import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from '@haulynx/pipes';
import { DataTableModule } from '../data-table/data-table.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { ActiveLoadsTableComponent } from './active-loads-table.component';

@NgModule({
  declarations: [ActiveLoadsTableComponent],
  exports: [ActiveLoadsTableComponent],
  imports: [CommonModule, DataTableModule, SpinnerModule, PipesModule],
})
export class ActiveLoadsTableModule {}
