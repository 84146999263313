import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MenuName } from '@haulynx/store';
import { HeaderItem, User } from '@haulynx/types';

@Component({
  selector: 'haulynx-sidebar-nav-sub-account',
  templateUrl: './sidebar-nav-sub-account.component.html',
  styleUrls: ['./sidebar-nav-sub-account.component.scss'],
})
export class SidebarNavSubAccountComponent implements OnInit {
  @Input() sidenav: MatSidenav;
  @Input() user: User;
  @Input() headerItems: HeaderItem[] = [];

  @Output() accountPortal = new EventEmitter();
  @Output() ordersPortal = new EventEmitter();
  @Output() logout = new EventEmitter();
  @Output() subNavToggle = new EventEmitter<MatSidenav>();
  @Output() selectedSideItem = new EventEmitter<HeaderItem>();

  headerNames = MenuName;

  constructor() {}

  ngOnInit(): void {}

  sideItemSelect(item: HeaderItem): void {
    this.selectedSideItem.emit(item);
  }

  toggleSubNav(subNav: MatSidenav): void {
    this.subNavToggle.emit(subNav);
  }

  openLogout(): void {
    this.logout.emit();
  }
}
