import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSlider } from '@angular/material/slider';

@Component({
  selector: 'haulynx-range-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliderComponent implements OnInit {
  @ViewChild('sMin', { static: true }) _sliderMin: MatSlider;
  @ViewChild('sMax', { static: true }) _sliderMax: MatSlider;

  @Input() min: number = 0;
  @Input() max: number = 100;
  @Input() sliderMinValue: number = 0;
  @Input() sliderMaxValue: number = 100;
  @Input() step: number = 1;

  // Output the range to the parent component
  @Output() input = new EventEmitter<{ min: number; max: number }>();

  constructor() {}

  ngOnInit(): void {
    // Initializes the slider thumb values to the provided values
    this._sliderMin.writeValue(this.sliderMinValue);
    this._sliderMax.writeValue(this.max - this.sliderMaxValue);
  }

  onChange(isMax: boolean) {
    this.handleStopper(isMax);
    this.input.emit({ min: this._sliderMin.value, max: this.max - this._sliderMax.value });
  }

  //**
  //* Prevent the thumb handles from going beyond their respective sibling.
  //*/
  handleStopper(isMax: boolean) {
    const currentMin = this._sliderMin.value;
    const currentMax = this._sliderMax.value;

    const currentTotal = currentMin + currentMax;

    if (currentTotal > this.max) {
      if (isMax) {
        this._sliderMax.value = this.max - currentMin - this.step;
      } else if (!isMax) {
        this._sliderMin.value = this.max - currentMax - this.step;
      }
    }
  }
}
