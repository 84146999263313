import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  ActionButtonsModule,
  BookLoadModule,
  CarrierBidsModule,
  DataTableModule,
  DatetimePickerModule,
  DropDownModule,
  ExpandPanelModule,
  GoogleAddressFieldModule,
  LoadBidDetailsModule,
  LoadComponentsModule,
  LoadFeedSearchContainerComponent,
  LoadFeedSearchFormModule,
  LoadMainInfo,
  LoadPriceModule,
  MapsModule,
  SpinnerModule,
  TabsModule,
} from '@haulynx/components';
import { DirectivesModule } from '@haulynx/directives';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { LoadFeedActionsService, UserVmService } from '@haulynx/services';
import { UsersStoreModule } from '@haulynx/store';
import { LoadFeedDetailsModule } from '../load-feed-details/load-feed-details.module';
import { LoadFeedModule } from '../load-feed/load-feed.module';
import { LoadActiveDetails } from './load-active-details/load-active-details.component';
import { LoadActiveSearch } from './load-active-search/load-active-search.component';
import { LoadActiveContainer } from './load-active/load-active-container.component';
import { LoadAvailableDetailsComponent } from './load-available-details/load-available-details.component';
import { LoadAvailableSearchComponent } from './load-available-search/load-available-search.component';
import { LoadAvailableComponent } from './load-available/load-available.component';
import { LoadDistributionConfigComponent } from './load-distribution-config/load-distribution-config.component';
import { LoadFormComponent } from './load-form/load-form.component';
import { LoadRoutingModule } from './load-routing.module';
import { LoadSearchComponent } from './load-search/load-search.component';
import { LoadComponent } from './load/load.component';

@NgModule({
  declarations: [
    LoadSearchComponent,
    LoadFeedSearchContainerComponent,
    LoadDistributionConfigComponent,
    LoadAvailableSearchComponent,
    LoadAvailableDetailsComponent,
    LoadAvailableComponent,
    LoadActiveSearch,
    LoadActiveDetails,
    LoadActiveContainer,
    LoadComponent,
    LoadFormComponent,
  ],
  exports: [LoadFeedSearchContainerComponent],
  imports: [
    CommonModule,
    LoadRoutingModule,
    FlexLayoutModule,
    DirectivesModule,
    LoadComponentsModule,
    ExpandPanelModule,
    LoadBidDetailsModule,
    BookLoadModule,
    CarrierBidsModule,
    SpinnerModule,
    LoadFeedSearchFormModule,
    DataTableModule,
    PipesModule,
    LoadPriceModule,
    MaterialModule,
    GoogleAddressFieldModule,
    FormsModule,
    ReactiveFormsModule,
    DatetimePickerModule,
    DropDownModule,
    ActionButtonsModule,
    LoadFeedDetailsModule,
    TabsModule,
    MapsModule,
    LoadMainInfo,
    LoadFeedModule,
    UsersStoreModule,
  ],
  providers: [LoadFeedActionsService, UserVmService],
})
export class LoadModule {}
