<h6>Set {{ label }}</h6>
<form class="form haulynx-form" [formGroup]="form">
  <mat-form-field>
    <span matPrefix>$</span>
    <input type="number" min="0" formControlName="price" matInput [placeholder]="label" />
    <mat-error *ngIf="form.get('price').hasError('min')">Please enter a positive number.</mat-error>
  </mat-form-field>
  <br /><br />

  <action-buttons [buttons]="buttons$ | async" (onClick)="onAction($event)"></action-buttons>
</form>
