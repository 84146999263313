import { Component, Input, OnInit } from '@angular/core';
import { WindowRef } from '@haulynx/services';
import { LoadEntityService } from '@haulynx/store';
import {
  LoadIdentifierType,
  LoadsServiceLoad,
  LoadsServiceLoadStatus,
  ToolbarHistoryConfigOptions,
} from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { Observable } from 'rxjs';

@Component({
  selector: 'haulynx-carrier-history',
  templateUrl: './carrier-history.component.html',
  styleUrls: ['./carrier-history.component.scss'],
})
export class CarrierHistoryComponent implements OnInit {
  @Input() dot: string;
  alive = aliveWhile();
  public loadIdentifierType = LoadIdentifierType;
  data$: Observable<LoadsServiceLoad[]>;
  paginationData$: Observable<{
    currentPage: number;
    total: number;
    totalPages: number;
  }>;
  toolbarConfigOptions = ToolbarHistoryConfigOptions;
  currentFirstRecord = 0;

  constructor(public loadEntityService: LoadEntityService, private windowRef: WindowRef) {}

  ngOnInit(): void {
    this.data$ = this.loadEntityService.getCarrierHistoryManager.searchResults$;
    this.paginationData$ = this.loadEntityService.getCarrierHistoryManager.searchPaginationResults$;

    this.loadEntityService.getCarrierHistoryManager.dispatch({
      query: { carrierNameOrDot: [this.dot], loadStatus: [LoadsServiceLoadStatus.FINALLED] },
      pageAndSort: {
        limit: this.toolbarConfigOptions.pageAmount,
        page: 1,
        sort: 'desc',
        order: 'firstAppointmentStart',
      },
    });
  }

  onPage(event: { pageUp: boolean; currentPage: number; limit: number }): void {
    this.loadEntityService.getCarrierHistoryManager.goToPage(
      event.currentPage + (event.pageUp ? 1 : -1),
      this.toolbarConfigOptions.pageAmount
    );
  }

  onViewLoad(event): void {
    const url = `/loads/${event}/overview/info`;
    this.windowRef.getNativeWindow().open(url, '_blank');
  }
}
