import { Store } from '@ngrx/store';
import { List } from 'immutable';
import { forEach } from 'lodash';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { AppState } from '../../main-store/app.reducers';
import {
  commonActionTypes,
  commonEntitiesActionClasses,
  ICommonEntitiesActions,
  ICommonEntitiesActionTypes,
} from './common-entities.actions';
import { commonEntitiesReducer } from './common-entities.reducer';
import { getEntity, isReceived } from './common-entities.selectors';
import { CommonEntityState } from './common-entities.state';

function createNamedWrapperReducer(reducerFunction, reducerName) {
  const initialState = new CommonEntityState();

  return (state = initialState, action) => {
    const { name } = action;

    if (name && name === reducerName) {
      return reducerFunction(state, action);
    }
    return state;
  };
}

export const createCombinationReducers = (entities): { [key: string]: () => {} } => {
  const entitiesCombination = {};

  forEach(entities, (value, key) => {
    entitiesCombination[value] = createNamedWrapperReducer(commonEntitiesReducer, value);
  });

  return entitiesCombination;
};

export const entityActions = (entityName): ICommonEntitiesActions => {
  const actionTypes = commonActionTypes(entityName);
  return commonEntitiesActionClasses(actionTypes, entityName);
};

export interface ISharedEntitiesModel {
  isLoading$: Observable<boolean>;
  entities$: Observable<any>;
  actions: ICommonEntitiesActions;
  actionTypes: ICommonEntitiesActionTypes;

  search(params?);

  clear();
}

export class CommonEntitiesModel<T> implements ISharedEntitiesModel {
  public isLoading$: Observable<boolean>;
  public entities$: Observable<List<T>>;
  public actions: ICommonEntitiesActions;
  public actionTypes: ICommonEntitiesActionTypes;

  constructor(private store: Store<AppState>, private options: { storeName: string; entityName: string }) {
    this.isLoading$ = this.store.select(isReceived(this.options.storeName));
    this.entities$ = this.store.select(getEntity(this.options.storeName)).pipe(
      withLatestFrom(this.store.select(isReceived(this.options.storeName))),
      map(([carriers, isLoading]: [List<T>, boolean]) => {
        return isLoading ? List() : carriers;
      })
    );
    this.actions = entityActions(this.options.entityName);
    this.actionTypes = commonActionTypes(this.options.entityName);
  }

  public search(params?: { key?: string; query?: any }) {
    this.store.dispatch(this.actions.search(params));
  }

  public clear() {
    this.store.dispatch(this.actions.searchSuccess([]));
  }
}
