<div class="row">
  <div class="col-lg-10"></div>
  <div class="col-lg-2" style="text-align: right">
    <button
      mat-raised-button
      color="primary"
      (click)="openNewUserDialog()"
      *ngIf="!user.isHaulynxAdmin"
      data-test="new-user-button"
    >
      <span style="color: white">New User</span>
    </button>
  </div>
</div>
<mat-tab-group>
  <mat-tab label="Users">
    <div class="row">
      <div class="col-lg-12">
        <app-spinner [active]="usersModel.isLoadingUserForm$ | async" [fullCover]="true"></app-spinner>

        <data-table
          [data]="users"
          [displayedColumns]="usersModel.userColumns$ | async"
          [loading]="usersModel.isLoading$ | async"
          [pageOptions]="userPageOptions"
          [templateCellRef]="templateCell"
        >
          <ng-template #templateCell let-row="row" let-column="column">
            <div *ngIf="column === 'name'">
              {{ row.name }}
              <span *ngIf="user?.isHaulynxAdmin" style="font-size: 0.9em; font-weight: bold">
                <br />({{ row.id }})
                <span *ngIf="row.isDeleted" style="font-style: italic"> <br />(Deleted) </span>
              </span>
            </div>

            <div *ngIf="column === 'company'">
              {{ row.carrier ? row.carrier.name : row.broker ? row.broker.name : row.shipper ? row.shipper.name : '' }}
              <span *ngIf="user?.isHaulynxAdmin" style="font-size: 0.9em; font-weight: bold"
                >({{ row.carrier ? row.carrier.dot : '' }})</span
              >
            </div>

            <div *ngIf="column === 'phone'">
              <ng-container *ngIf="row.phone">
                {{ row.phone | phoneMask: 'xxx-xxx-xxxx' }}
              </ng-container>
            </div>

            <div *ngIf="column === 'isCompanyAdmin'">
              <mat-checkbox
                color="primary"
                [(ngModel)]="row.isCompanyAdmin"
                [disabled]="row?.enrollmentStatus === 'driver_lite' || row?.unregistered"
                (change)="updateUserProperty(editableProperties.IS_ADMIN, row)"
                data-test="is-admin-checkbox"
              >
              </mat-checkbox>
            </div>

            <div *ngIf="column === 'isDriver'">
              <mat-checkbox
                color="primary"
                [(ngModel)]="row.isDriver"
                [disabled]="row?.enrollmentStatus === 'driver_lite' || row?.unregistered"
                (change)="updateUserProperty(editableProperties.IS_DRIVER, row)"
                data-test="is-driver-checkbox"
              >
              </mat-checkbox>
            </div>

            <div *ngIf="column === 'isVerified'">
              <mat-checkbox
                *ngIf="canEditUserProperty(user, row)"
                [(ngModel)]="row.isVerified"
                color="primary"
                [disabled]="row.id === user.id || row?.enrollmentStatus === 'driver_lite' || row?.unregistered"
                (change)="updateUserProperty(editableProperties.IS_VERIFIED, row)"
                data-test="is-verified-chekbox"
              >
                {{ row.isVerified ? 'Yes' : 'No' }}
              </mat-checkbox>
            </div>

            <div *ngIf="column === 'trailerCreationEnabled'">
              <mat-checkbox
                [(ngModel)]="row.trailerCreationEnabled"
                color="primary"
                [disabled]="row?.enrollmentStatus === 'driver_lite' || row?.unregistered"
                (change)="updateUserProperty(editableProperties.TC_ENABLED, row)"
                data-test="add-trailers-checkbox"
              >
                {{ row.trailerCreationEnabled ? 'Yes' : 'No' }}
              </mat-checkbox>
            </div>

            <div *ngIf="column === 'options'">
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon data-test="more-options">more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button
                  *ngIf="!user.isHaulynxAdmin"
                  mat-menu-item
                  (click)="openNewUserDialog(row.id)"
                  data-test="edit-driver"
                >
                  <span>Edit User</span>
                </button>
                <button mat-menu-item *ngIf="row.id != user.id" (click)="deleteUser(row)" data-test="delete-driver">
                  <span>Delete User</span>
                </button>
              </mat-menu>
            </div>
          </ng-template>
        </data-table>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
