<div class="atomic-table-container">
  <div class="atomic-table-wrapper">
    <table mat-table [dataSource]="data" matSort>
      <div *ngFor="let column of displayedColumns; let colIndex = index; let last = last">
        <ng-container matColumnDef="{{ column }}">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            [disabled]="!displayedColumnTitles[colIndex]?.sortable"
            class="atomic-header"
          >
            {{ column !== 'delete' && column !== 'edit' ? displayedColumnTitles[colIndex]?.name : '' }}
          </th>

          <td
            mat-cell
            *matCellDef="let row; let rowIndex = index"
            attr.data-test="{{ displayedColumnTitles[colIndex] }}"
          >
            {{ column !== 'delete' && column !== 'edit' ? row[column].primary : '' }}
            <ng-container *ngIf="row[column]?.secondary">
              <div class="secondary-text">
                <sub>{{ row[column].secondary }}</sub>
              </div>
            </ng-container>
            <ng-container class="delete-col" *ngIf="column === 'edit'">
              <button mat-icon-button (click)="onEditClicked(row)" data-test="edit">
                <mat-icon svgIcon="haulynx-edit"></mat-icon>
              </button>
            </ng-container>
            <ng-container class="delete-col" *ngIf="column === 'delete'">
              <button mat-icon-button (click)="onDeleteClicked(row)" data-test="delete">
                <mat-icon svgIcon="haulynx-trash-gray"></mat-icon>
              </button>
            </ng-container>
          </td>
        </ng-container>
      </div>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center" class="paginator">
    <div class="text left-block" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
      <div>Showing</div>
      <mat-select
        class="select"
        fxFlex="50px"
        [disabled]="loading"
        (selectionChange)="onRowLimitChange($event)"
        [value]="pagination['limit']"
        panelClass="select-panel"
        fxLayout="row"
        fxLayoutAlign="center center"
      >
        <mat-option *ngFor="let limit of rowLimitOptions" [value]="limit">{{ limit }}</mat-option>
      </mat-select>
      <div>Results</div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" class="right-block">
      <div class="text">{{ pagination.currentPage }}/{{ pagination.total }}</div>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="32px">
        <div fxLayout="row">
          <button
            mat-icon-button
            [disabled]="pagination.currentPage <= 1 || loading"
            (click)="previousPageClicked()"
            data-test="previous-page"
          >
            <mat-icon>chevron_left</mat-icon>
          </button>
          <button
            mat-icon-button
            [disabled]="pagination.currentPage >= pagination.total || loading"
            (click)="nextPageClicked()"
            data-test="next-page"
          >
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
        <button mat-icon-button (click)="onRefreshClicked()" class="refresh-button" [disabled]="loading">
          <mat-icon>autorenew</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
