import { createSelector } from '@ngrx/store';
import { getRouterState } from '../../main-store/app.reducers';
import { bidAdapter, carriersLoadFeedAdapter, exclusiveLoadFeedAdapter, loadFeedAdapter } from './adapters';
import { getLoadFeedState } from './load-feed.state';

const getSearchState = createSelector(getLoadFeedState, (state) => state.loadFeedSearch);
const getCarriersLoadFeedSearchState = createSelector(getLoadFeedState, (state) => state.carriersLoadFeedSearch);

const getBidSearchState = createSelector(getLoadFeedState, (state) => state.bidSearch);

const getExclusiveSearchState = createSelector(getLoadFeedState, (state) => state.exclusiveLoadFeedSearch);

const getTabsState = createSelector(getLoadFeedState, (state) => state.tabs);

export const isLoadingSelector = createSelector(getLoadFeedState, (state) => state.isLoading);

export const selectUrlSelector = createSelector(getRouterState, (router) => router.state.url);

export const carrierLoadFeedSearchSelector = carriersLoadFeedAdapter.carriersLoadFeedSearch.getSelectors(
  getCarriersLoadFeedSearchState
);
export const loadFeedSearchSelector = loadFeedAdapter.loadFeedSearch.getSelectors(getSearchState);

export const exclusiveLoadFeedSearchSelector = exclusiveLoadFeedAdapter.exclusiveLoadFeedSearch.getSelectors(
  getExclusiveSearchState
);
export const loadFeedTabsSelector = loadFeedAdapter.tabs.getSelectors(getTabsState);

export const isExpandedExclusiveSelector = createSelector(getLoadFeedState, (state) => state.isExpandedExclusive);

export const isEldCarrierSelector = createSelector(getLoadFeedState, (state) => state.isEldCarrier);

export const bidSearchSelector = bidAdapter.bidSearch.getSelectors(getBidSearchState);

export const bidStatusesSelector = createSelector(getLoadFeedState, (state) => state.bidStatuses);

export const bidHistorySelector = createSelector(getLoadFeedState, (state) => state.bidHistory);

export const isLoadingBidHistorySelector = createSelector(getLoadFeedState, (state) => state.isLoadingBidHistory);

export const isLoadingAcceptBidSelector = createSelector(getLoadFeedState, (state) => state?.isLoadingAcceptBid);

export const isLoadingAcceptCounterBidSelector = createSelector(
  getLoadFeedState,
  (state) => state.isLoadingAcceptCounterBid
);

export const isLoadingBidCreateSelector = createSelector(getLoadFeedState, (state) => state.isLoadingBidCreate);

export const sortBidsBySelector = createSelector(getLoadFeedState, (state) => state.sortBidsBy);

export const isLoadingCounterOfferCreateSelector = createSelector(
  getLoadFeedState,
  (state) => state.isLoadingCounterOfferCreate
);

export const isLoadingCustomerSelector = createSelector(getLoadFeedState, (state) => state.isLoadingCustomer);

export const customersSelector = createSelector(getLoadFeedState, (state) => state.customers);
