import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'haulynx-blue-checkbox',
  templateUrl: './blue-checkbox.component.html',
  styleUrls: ['./blue-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlueCheckboxComponent {
  @Input() text = 'Text';
  @Input() model: boolean;
  @Input() tabindex = 0;
  @Output() modelChange = new EventEmitter<boolean>();

  public change(change: MatCheckboxChange): void {
    this.modelChange.emit(change.checked);
  }
}
