import { Component, ViewEncapsulation, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  LoadsServiceOrderByType,
  LoadsServiceOrderByTypeDirection,
  LoadsServiceOrderByTypeToVirtualTableSortTypes,
  VirtualTableSortTypesToLoadsServiceOrderByType,
  MatchVirtualTableSortDirectionToType,
  VirtualTableSortTypeToLoadsServiceDirectionSortType,
  VirtualSortDirection,
  VirtualTableSortTypes,
  VirtualColumn,
} from '@haulynx/types';

@Component({
  selector: 'haulynx-virtual-table-sort-dialog',
  templateUrl: './virtual-table-sort-dialog.component.html',
  styleUrls: ['./virtual-table-sort-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VirtualTableSortDialogComponent implements OnInit {
  sortTypes: { type: VirtualTableSortTypes; checked: boolean }[] = [];
  sortDirectionTypes: { direction: VirtualSortDirection; checked: boolean }[] = [];

  constructor(
    public dialogRef: MatDialogRef<VirtualTableSortDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      column: VirtualColumn;
      sortOrder: LoadsServiceOrderByType;
      sortDirection: LoadsServiceOrderByTypeDirection;
    }
  ) {
    const sortType = this?.data?.column?.sortType ?? null;
    const otherSortTypes = this?.data?.column?.otherSortTypes ?? null;

    this?.sortTypes?.push({
      type: LoadsServiceOrderByTypeToVirtualTableSortTypes(sortType),
      checked: this?.data?.sortOrder === sortType,
    });

    if (otherSortTypes && otherSortTypes.length) {
      otherSortTypes?.forEach((type) => {
        this?.sortTypes?.push({
          type: LoadsServiceOrderByTypeToVirtualTableSortTypes(type),
          checked: this?.data?.sortOrder === type,
        });
      });
    }

    const selected =
      VirtualTableSortTypesToLoadsServiceOrderByType(this?.sortTypes?.find((type) => type?.checked)?.type) ?? null;
    const directionType = selected
      ? LoadsServiceOrderByTypeToVirtualTableSortTypes(selected)
      : LoadsServiceOrderByTypeToVirtualTableSortTypes(sortType);

    this.newDirectionTypesForSortType(selected, directionType);
  }

  ngOnInit(): void {}

  selectedSortOption(option) {
    const order = VirtualTableSortTypesToLoadsServiceOrderByType(option?.type);

    this.newDirectionTypesForSortType(order, option?.type);

    if (this?.data?.sortOrder === order && option.checked) {
      this.deselectOtherSortOptions(null, null);
      return;
    }

    this.deselectOtherSortOptions(option?.type, order);
  }

  selectedDirectionOption(option) {
    const direction = VirtualTableSortTypeToLoadsServiceDirectionSortType(option?.direction);

    if (this?.data?.sortDirection === direction && option.checked) {
      this.deselectOtherDirectionOptions(null, null);
      return;
    }

    this.deselectOtherDirectionOptions(option.direction, direction);
  }

  private newDirectionTypesForSortType(orderType: LoadsServiceOrderByType, sortType: VirtualTableSortTypes) {
    this.sortDirectionTypes = MatchVirtualTableSortDirectionToType(sortType)?.map((direction) => {
      return {
        direction: direction,
        checked:
          VirtualTableSortTypeToLoadsServiceDirectionSortType(direction) === this?.data?.sortDirection &&
          this?.data?.sortOrder === orderType,
      };
    });
  }

  private deselectOtherSortOptions(sortType: VirtualTableSortTypes, order: LoadsServiceOrderByType) {
    this.data.sortOrder = order;
    this.data.sortDirection = null;
    this.sortTypes?.forEach((type) => {
      type.checked = type.type === sortType ?? false;
    });
  }

  private deselectOtherDirectionOptions(
    directionType: VirtualSortDirection,
    direction: LoadsServiceOrderByTypeDirection
  ) {
    this.data.sortDirection = direction;
    this.sortDirectionTypes?.forEach((type) => {
      type.checked = type.direction === directionType ?? false;
    });
  }
}
