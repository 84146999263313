import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { ElementsModule } from '@haulynx/elements';
import { MaterialModule } from '@haulynx/modules';
import { AdminVerifyEmailDialogComponent } from './admin-verify-email-dialog.component';

@NgModule({
  declarations: [AdminVerifyEmailDialogComponent],
  imports: [CommonModule, ReactiveFormsModule, MaterialModule, ElementsModule, FlexLayoutModule],
  exports: [AdminVerifyEmailDialogComponent],
})
export class AdminVerifyEmailDialogModule {}
