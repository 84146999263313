import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchRadiusFieldComponent } from './search-radius-field.component';
import { BlueInputModule } from '../blue-input/blue-input.module';
import { MaterialModule } from '@haulynx/modules';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BlueSelectModule } from '../blue-select/blue-select.module';

@NgModule({
  declarations: [SearchRadiusFieldComponent],
  imports: [BlueSelectModule, CommonModule, ReactiveFormsModule, FormsModule, MaterialModule, BlueInputModule],
  exports: [SearchRadiusFieldComponent],
})
export class SearchRadiusFieldModule {}
