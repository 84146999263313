import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  ActiveLoadsTableModule,
  AddDriverModule,
  AddTrailerModule,
  AddTruckModule,
  CustomerDetailsModule,
  LoadDispatchFormModule,
  LoadQuickActionsModule,
  LoadQuickDriverFormModule,
  LoadQuickLocationFormModule,
  LoadTableSearchBarModule,
} from '@haulynx/components';
import { LoadFormService, StepStatusService } from '@haulynx/services';
import { LoadDetailsStateModule } from '@haulynx/store';
import { ActiveLoadSearchContainerRoutingModule } from './active-load-search-container-routing.module';
import { ActiveLoadSearchContainerComponent } from './active-load-search-container.component';

@NgModule({
  declarations: [ActiveLoadSearchContainerComponent],
  imports: [
    CommonModule,
    ActiveLoadSearchContainerRoutingModule,
    LoadTableSearchBarModule,
    ActiveLoadsTableModule,
    LoadQuickActionsModule,
    LoadQuickDriverFormModule,
    LoadDetailsStateModule,
    LoadDispatchFormModule,
    LoadQuickLocationFormModule,
    CustomerDetailsModule,
    AddDriverModule,
    AddTruckModule,
    AddTrailerModule,
  ],
  providers: [LoadFormService, StepStatusService],
})
export class ActiveLoadSearchContainerModule {}
