<div class="app-notification-search">
  <ng-container *ngFor="let webNotification of webNotifications; let groupIndex = index">
    <ng-container *ngFor="let subWebNotification of webNotification; let i = index">
      <app-notification
        (click)="select(subWebNotification)"
        [attr.data-test]="groupIndex + '-' + i"
        [active]="subWebNotification.active"
        [dateCreated]="subWebNotification.dateCreated"
        [title]="
          groupIndex === 0 && i === 0 && subWebNotification.active
            ? 'New'
            : subWebNotification.active
            ? null
            : i === 0 && !subWebNotification.active
            ? subWebNotification.title
            : null
        "
      >
        <div appNotificationTitle>
          <ng-container
            *ngIf="subWebNotification.eventType === notificationEventTypes.BIDS_EVENT; else allOtherNotification"
          >
            <div [innerHTML]="subWebNotification | bidNotificationText: user"></div>
          </ng-container>
          <ng-template #allOtherNotification>
            <div [innerHTML]="subWebNotification | notificationMessage"></div>
          </ng-template>
        </div>

        <div appNotificationContent>
          <ng-container [ngSwitch]="true">
            <ng-container
              *ngSwitchCase="
                subWebNotification.eventType === notificationEventTypes.POSTED_TRUCK_RECOMMENDATION ||
                subWebNotification.eventType === notificationEventTypes.LOAD_MATCH
              "
            >
              <span>
                You have {{ subWebNotification.size }} new load recommendation<ng-container
                  *ngIf="subWebNotification.size > 1"
                  >s</ng-container
                >
              </span>
            </ng-container>
            <ng-container
              *ngSwitchCase="
                subWebNotification.eventType === notificationEventTypes.LOAD_BROKER_ASSIGNMENT ||
                subWebNotification.eventType === notificationEventTypes.BIDS_EVENT ||
                subWebNotification.eventType === notificationEventTypes.AUTO_ASSIGN_BROKER
              "
            >
              <app-load-search-timeline [load]="subWebNotification.loadsServiceLoad" [fontSize]="'12px'">
              </app-load-search-timeline>
            </ng-container>
            <ng-container *appFeatures="featureFlag">
              <ng-container
                *ngSwitchCase="subWebNotification.eventType === notificationEventTypes.BROKER_PROFILE_RECOMMENDATION"
              >
                <div class="repeat-notifications">
                  <app-load-search-timeline [load]="subWebNotification.loadsServiceLoad" [fontSize]="'12px'">
                  </app-load-search-timeline>
                  <div class="message">{{ subWebNotification.notifications[0].message }}</div>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>

        <div appNotificationFooter>
          <ng-container>
            <span
              *ngIf="notificationTemplate[subWebNotification.eventType]?.footer"
              class="new-match"
              [ngClass]="getButtonClass(subWebNotification.eventType)"
              data-test="notification-type"
              >{{ notificationTemplate[subWebNotification.eventType]?.footer }}</span
            >
          </ng-container>
        </div>
      </app-notification>
    </ng-container>
  </ng-container>
</div>
