import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GoogleAnalyticsService } from '@haulynx/services';
import { AppModel } from '@haulynx/store';
import { Action, Category, Label, User } from '@haulynx/types';
import { aliveWhile, openEmailClient } from '@haulynx/utils';
import { map, takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-referral',
  templateUrl: './referral.component.html',
  styleUrls: ['./referral.component.scss'],
})
export class ReferralComponent implements OnInit {
  public link: string;
  public userId: string;

  private alive = aliveWhile();

  constructor(
    private appModel: AppModel,
    private googleAnalyticsService: GoogleAnalyticsService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.appModel.user$
      .pipe(
        takeUntil(this.alive),
        map((user: User) => user.id)
      )
      .subscribe((userId: string) => {
        this.userId = userId;
        this.link = `https://app.haulynx.com/signup?brokerReferral=${this.userId}&utm_source=referral`;
      });
  }

  public composeEmail(): void {
    this.googleAnalyticsService.eventEmitter(Category.REFERRAL, Action.CLICK, Label.COMPOSE_EMAIL);
    openEmailClient({
      subject: `Sign up with Haulynx!`,
      body: `Navigate to the link below and start using Haulynx today! ${this.link}`,
    });
  }

  /**
   * The referral link was successfully copied
   */
  public copyLinkSuccess(): void {
    this.googleAnalyticsService.eventEmitter(Category.REFERRAL, Action.CLICK, Label.COPY);
    this.snackBar.open('Successfully copied referral url', 'Okay', { duration: 3000 });
  }
}
