import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { SetupRmisDialogComponent } from '@haulynx/components';
import { AnalyticsService, FireDatabaseService, LocalStoreService } from '@haulynx/services';
import { UserEntityService } from '@haulynx/store';
import {
  Carrier,
  FFState,
  HeaderItem,
  RmisRegistration,
  RmisStep,
  RmisSteps,
  RmisTokenValue,
  User,
} from '@haulynx/types';
import { aliveWhile, rmisAccountStatusComplete } from '@haulynx/utils';
import { differenceInHours } from 'date-fns';
import { Observable } from 'rxjs';
import { debounceTime, filter, map, mergeMap, skip, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'haulynx-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @ViewChild(MatSidenav) sidenavController;
  @Input() user: User;
  @Input() headerItems: HeaderItem[] = [];
  @Input() totalNotifications: Observable<number>;
  @Input() url: string;

  @Output() startTour = new EventEmitter();
  @Output() showNotifications = new EventEmitter();
  @Output() showVerifyEmail = new EventEmitter();
  @Output() goToHelp = new EventEmitter();
  @Output() logout = new EventEmitter();
  @Output() rmisStep = new EventEmitter();

  public currentSelectedItem: HeaderItem;
  public loadingContinue$: Observable<boolean>;
  public percentComplete = 0;
  public showAccountSetup = false;
  public registration: Observable<RmisRegistration> = this.userEntitityService.user$.pipe(
    map((user: User) => user?.carrier?.rmisRegistration || null)
  );
  public featureFlags$: Observable<FFState> = this.userEntitityService.featureFlags$;

  public alive = aliveWhile();
  public carrierDot: string;
  public rmisComplete: boolean = false;
  public lastRmisSetupDialogShown: Date;
  public currentAccountStep: RmisStep;

  constructor(
    private mediaObserver: MediaObserver,
    private analytics: AnalyticsService,
    private dialog: MatDialog,
    private firebaseDatabaseService: FireDatabaseService,
    private localStoreService: LocalStoreService,
    private router: Router,
    private userEntitityService: UserEntityService,
    private location: Location
  ) {
    const rmisSteps: RmisSteps = {
      fleetRegistered: { step: 1, label: 'Carrier Profile' },
      w9Orw8Registered: { step: 2, label: 'Tax Documentation' },
      comcheckAdvancesRegistered: { step: 3, label: 'Payment Advance' },
      paymentOptionRegistered: { step: 4, label: 'Payment Options' },
      supplierDiversityRegistered: { step: 5, label: 'Supplier Diversity' },
    };

    const rmisStepsKeys = Object.keys(rmisSteps);
    let step = 0;
    let label: string;

    this.registration.pipe(takeUntil(this.alive)).subscribe((rmisResponse) => {
      for (const rmisStep in rmisResponse) {
        if (rmisResponse['supplierDiversityRegistered']) {
          label = 'Account setup completed';
          break;
        }
        if (rmisResponse[rmisStep] && rmisStepsKeys.includes(rmisStep) && rmisSteps[rmisStep]?.step > step) {
          step = rmisSteps[rmisStep].step;
          label = rmisSteps[rmisStep].label;
        }
      }
    });

    this.currentAccountStep = { step, label };
  }

  ngOnInit(): void {
    this.mediaObserver
      .asObservable()
      .pipe(
        takeUntil(this.alive),
        filter((change: MediaChange[]) => change[0].mqAlias == 'lg' && !!this.sidenavController)
      )
      .subscribe(() => {
        this.sidenavController.close();
      });

    this.url = this.url === '/loads/available' ? '/dashboard/loads/search/all' : this.url;
    this.headerItems.forEach((item) => {
      if (item.link === this?.url || this?.url.includes(item.link)) {
        item.selected = true;
        if (item?.children?.length) {
          (item.children as HeaderItem[]).forEach((childItem) => {
            if (childItem?.link === this.url) {
              this.currentSelectedItem = childItem;
              return;
            }
          });
          return;
        }
        this.currentSelectedItem = item;
        this.analytics.logEvent(item?.analyticsEvent);
      } else {
        item.selected = false;
      }
    });

    this.loadingContinue$ = this.userEntitityService.rmisTokenManager.isLoading$;

    this.userEntitityService.user$
      .pipe(
        debounceTime(500),
        takeUntil(this.alive),
        map((user: User) => user?.carrier || null)
      )
      .subscribe(async (carrier: Carrier) => {
        if (!carrier) return;
        this.carrierDot = carrier.dot;
        const rmisComplete = await this.localStoreService.get(`${this.carrierDot}-${RmisTokenValue.RMIS_COMPLETE}`);
        this.rmisComplete = !!rmisComplete;
        this.lastRmisSetupDialogShown = await this.localStoreService.get(
          `${this.carrierDot}-${RmisTokenValue.RMIS_SETUP}`
        );
        this.checkRmisComplete(carrier);
      });

    this.userEntitityService.user$
      .pipe(
        filter((user) => !!user?.carrier),
        take(1),
        mergeMap((user: User) => {
          return this.firebaseDatabaseService.getItem(`complianceUpdates/dots/${user.carrier.dot}`).pipe(
            skip(1), // Do not take the first emission since the page just loaded and has the correct information
            map((value) => ({ user, value })),
            takeUntil(this.alive)
          );
        })
      )
      .subscribe(({ user, value }) => {
        if (value) {
          this.userEntitityService.getUserByIdManager.dispatch(user.id);
        }
      });
  }

  goBack() {
    this.location.back();
  }

  onShowNotifications(): void {
    this.showNotifications.emit();
  }

  onShowVerifyEmail(): void {
    this.showVerifyEmail.emit();
  }

  whatsNewTour(): void {
    this.startTour.emit();
  }

  selectedHeaderItem(item: HeaderItem): void {
    if (item.stopNavigationOnClick) return;
    if (item?.name === 'Spot Admin') {
      window.open(item?.link, '_blank');
      return;
    }
    this.setSelectedItem(item);
    this.analytics.logEvent(item?.analyticsEvent);

    this.router.navigate([item?.link]);
  }

  openHelp(): void {
    this.goToHelp.emit();
  }

  openLogout(): void {
    this.logout.emit();
  }

  ngOnDestroy() {
    this.alive.destroy();
  }

  complete() {
    this.setRmisSetupDate(`${this.carrierDot}-${RmisTokenValue.RMIS_COMPLETE}`);
    this.showAccountSetup = false;
  }

  continueToRmis() {
    this.userEntitityService.rmisTokenManager.dispatch();

    this.userEntitityService.rmisTokenManager.onError$.pipe(take(1)).subscribe((error) => {
      // this.router.navigate([AccountRoute.SORRY]);
    });

    this.userEntitityService.rmisTokenManager.onSuccess$.pipe(take(1)).subscribe((response: { url: string }) => {
      window.open(response.url, '_blank');
    });
  }

  private setSelectedItem(selectedItem: HeaderItem): void {
    this.currentSelectedItem = selectedItem;
    this.headerItems.forEach((item) => {
      if (item?.children?.length) {
        // look for children that are active
        return (item.selected = (item.children as HeaderItem[]).some((item) =>
          item?.name === selectedItem?.name ? true : false
        ));
      }

      item.selected = item?.name === selectedItem?.name ? true : false;
    });
  }

  /**
   * Get the last time the RMIS setup dialog has been shown.
   * If the time it was last shown has been at least 24 hours ago, show it again.
   * @returns void
   */
  private checkRmisComplete(carrier: Carrier) {
    const registration = carrier?.rmisRegistration;
    if (!registration) return;

    if (!registration?.hasComplianceProcessedCarrier) {
      return (this.showAccountSetup = false);
    }

    if (!registration?.isRmisSetup) return (this.showAccountSetup = false);

    // If you switch carriers, this should show and such not based off these flags
    this.checkPercentComplete(registration);

    if (this.rmisComplete) {
      this.showAccountSetup = false;
      return;
    }

    this.showAccountSetup = true;

    if (!this.lastRmisSetupDialogShown) {
      // Setup dialog has never shown
      this.showSetupDialog(carrier);
      return;
    }
    const lastShownDialog = new Date(this.lastRmisSetupDialogShown);
    const difference = differenceInHours(new Date(), lastShownDialog);
    if (difference < 24) return;

    // Setup dialog has been shown at least 24 hours ago and needs to be shown again
    this.showSetupDialog(carrier);
  }

  /**
   * Set percentage complete based off of the RMIS registration flags
   * @param registration
   * @returns
   */
  private checkPercentComplete(registration: RmisRegistration) {
    if (!registration) {
      this.percentComplete = 0;
      return;
    }

    this.percentComplete = rmisAccountStatusComplete(registration);
  }

  private setRmisSetupDate(key: string): void {
    this.localStoreService.set(key, new Date().getTime());
  }

  private showSetupDialog(carrier: Carrier) {
    this.setRmisSetupDate(`${this.carrierDot}-${RmisTokenValue.RMIS_SETUP}`);

    const dialog = this.dialog.open(SetupRmisDialogComponent, {
      panelClass: 'account-dialogs',
      maxWidth: '480px',
      disableClose: true,
      data: { registration: carrier?.rmisRegistration },
    });

    dialog
      .afterClosed()
      .pipe(takeUntil(this.alive))
      .subscribe((response: { redirectToRmis: boolean; completeSetup: boolean }) => {
        if (!response) return;

        if (response.completeSetup) {
          this.setRmisSetupDate(`${this.carrierDot}-${RmisTokenValue.RMIS_COMPLETE}`);
          return (this.showAccountSetup = false);
        }
        if (response.redirectToRmis) return this.continueToRmis();
      });
  }
}
