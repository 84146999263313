<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #sidenav>
    <!-- main menu -->
    <haulynx-sidebar-nav
      [user]="user"
      [headerItems]="headerItems"
      [currentSideItem]="currentSelectedItem"
      [sidenav]="sidenav"
      [rmisProps]="{
        loadingContinue$, 
        percentComplete, 
        showAccountSetup, 
        registration, 
        carrierDot, 
        rmisComplete, 
        lastRmisSetupDialogShown, 
        currentAccountStep
      }"
      [featureFlags]="featureFlags$ | async"
      (startTour)="whatsNewTour()"
      (sideItemSelected)="selectedHeaderItem($event)"
      (showNotifications)="onShowNotifications()"
      (showVerifyEmail)="onShowVerifyEmail()"
      [totalNotifications]="totalNotifications"
      (goToHelp)="openHelp()"
      (openLogout)="openLogout()"
      (complete)="complete()"
      (goToRmis)="continueToRmis()"
    ></haulynx-sidebar-nav>
  </mat-sidenav>
  <mat-sidenav-content class="sidenav-content">
    <div class="fill" fxLayout="column" fxLayoutAlign="start">
      <haulynx-header-toolbar
        [sidenav]="sidenav"
        [headerItems]="headerItems"
        [currentHeaderItem]="currentSelectedItem"
        [user]="user"
        [rmisProps]="{
          loadingContinue$, 
          percentComplete, 
          showAccountSetup, 
          registration, 
          carrierDot, 
          rmisComplete, 
          lastRmisSetupDialogShown          
        }"
        (startTour)="whatsNewTour()"
        (goToHelp)="openHelp()"
        (openLogout)="openLogout()"
        (showNotifications)="onShowNotifications()"
        (complete)="complete()"
        [totalNotifications]="totalNotifications"
        (showVerifyEmail)="onShowVerifyEmail()"
        (headerItemSelected)="selectedHeaderItem($event)"
        (goToRmis)="continueToRmis()"
        (goBack)="goBack()"
      ></haulynx-header-toolbar>
      <div class="outlet">
        <ng-content select="[outlet]"></ng-content>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
