import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@haulynx/modules';
import { PipesModule } from '@haulynx/pipes';
import { LoadTimelineComponent } from './load-timeline.component';

@NgModule({
  imports: [CommonModule, MaterialModule, PipesModule],
  declarations: [LoadTimelineComponent],
  exports: [LoadTimelineComponent],
})
export class LoadTimelineModule {}
