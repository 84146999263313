import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Bid, BidSourceType, BidStatusType } from '@haulynx/types';
import { forEach, keys } from 'lodash';

export class BidForm implements Partial<Bid> {
  id: string = null;
  notes = '';
  price: number = null;
  status: BidStatusType = null;
  carrierId: string = null;
  loadId: string = null;
  sourceType: BidSourceType = null;
  offerStateDescription: string = null;
  previousPrice: number = null;

  constructor(options = {}) {
    const key = keys(this);
    const defaultValue = [null];

    forEach(key, (prop) => {
      this[prop] = (options && options[prop]) || defaultValue;
    });
  }
}

@Injectable({
  providedIn: 'root',
})
export class BidVmService {
  constructor(private fb: FormBuilder) {}

  create(values?: Partial<Bid>): FormGroup {
    const bidsListForm = new BidForm(values);
    const formGroup = this.fb.group(bidsListForm);

    return formGroup;
  }

  setValue(form: FormGroup, values?: Partial<Bid>): void {
    form.setValue(new BidForm(values));
  }

  createFormGroup(value: Bid): FormGroup {
    return this.create(value);
  }

  createFormArray(values: Bid[] = []): FormArray {
    return this.fb.array((values || []).map((bid: Bid) => this.create(bid)));
  }
}
