<div class="search-input-container">
  <form [formGroup]="inputForm" autocomplete="off">
    <input
      matInput
      class="si-mat-input"
      formControlName="searchTypeSearch"
      placeholder="{{ title }}"
      (focus)="onFocus()"
      (blur)="onBlur()"
      #searchInput
      data-test="input-form"
    />
  </form>
</div>
