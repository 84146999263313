import { DecimalPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AddressPipe, MomentTzPipe } from '@haulynx/pipes';
import {
  Bid,
  BidHistory,
  BidHistoryEvent,
  BidHistoryEventType,
  Carrier,
  LoadIdentifierType,
  LoadsServiceLoad,
} from '@haulynx/types';
import { bidStatusToValue, objDifference, getLoadsServiceLoadAlternateId } from '@haulynx/utils';
import { capitalize, get, nth, omit } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';

const lineSeparator = `
`;

const historyEvents: Record<BidHistoryEvent, string> = {
  accept_bid: 'Accept Bid',
  accept_counter_offer: 'Accept Counter Offer',
  auto_reject_bid: 'Auto Reject Bid',
  carrier_decrease_bid: 'Carrier Decrease Bid',
  create_bid: 'Create Bid',
  create_counter_offer: 'Create Counter Offer',
  delete_bid: 'Delete Bid',
  reject_counter_offer: 'Reject Counter Offer',
  update_bid: 'Update Bid',
  update_counter_offer: 'Update Counter Offer',
};

@Component({
  selector: 'app-bid-history',
  templateUrl: './bid-history.component.html',
  styleUrls: ['./bid-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BidHistoryComponent implements OnChanges {
  @Input() isLoading: boolean;
  @Input() bid: Bid;
  @Input() carrier: Carrier;
  @Input() load: LoadsServiceLoad;
  @Input() bidHistory: BidHistory[];
  @Input() userTimeZone: string;
  @Output() done = new EventEmitter();

  public loadIdentifierType = LoadIdentifierType;
  historyCopied = new BehaviorSubject(false);
  historyCopied$: Observable<boolean> = this.historyCopied.asObservable();
  history: string[] = [];
  historyToCopy: string;

  constructor(private momentTzPipe: MomentTzPipe, private addressPipe: AddressPipe, private decimalPipe: DecimalPipe) {}

  formatBidHistory(bidHistory: BidHistory[]): string[] {
    return bidHistory.map((history: BidHistory, index: number) => {
      const { createdAt, createdBy, event, data } = history;
      const name = get(createdBy, 'name', null);
      let log = {};

      if (event === BidHistoryEventType.CREATE_BID || event === BidHistoryEventType.CREATE_COUNTER_OFFER) {
        log = data;
      }

      if (event === BidHistoryEventType.UPDATE_BID) {
        const previous = get(nth(bidHistory, index - 1), 'data', {});
        log = index > 0 ? objDifference(data, previous) : data;
      }

      const createdAtFormatted = this.momentTzPipe.transform(
        createdAt,
        'MM/DD/YY HH:mm',
        this.userTimeZone,
        false,
        true
      );
      const eventFormatted = historyEvents[event];
      const dataFormatted = this.formatBid(log, event);

      return `${createdAtFormatted} | ${name} — ${eventFormatted}; ${dataFormatted}`;
    });
  }

  formatBidHistoryHeader(bidHistory: BidHistory[]): string[] {
    const history = nth(bidHistory, 0);
    const carrier = get(history, 'bid.carrier.name', 'N/A');
    const createdBy = get(history, 'createdBy.name', 'N/A');
    const lastEdited = get(history, 'bid.updatedBy.name', 'N/A');
    const order = getLoadsServiceLoadAlternateId(this.load, LoadIdentifierType.TMW_NUMBER);
    const price = this.load?.paymentDetails?.price
      ? this.decimalPipe.transform(this.load?.paymentDetails?.price, '1.2-2')
      : 'N/A';
    const revenue = get(this.load?.paymentDetails, 'revenue', 'N/A');

    const from = this.addressPipe.transform(get(nth(this.load.locations, 0), 'name', 'N/A'), 1);
    const to = this.addressPipe.transform(get(nth(this.load.locations, -1), 'name', 'N/A'), 1);

    const dateCreated = this.momentTzPipe.transform(
      get(history, 'bid.createdAt'),
      'MM/DD/YY HH:mm',
      this.userTimeZone,
      false,
      true
    );

    const dateEdited = this.momentTzPipe.transform(
      get(history, 'bid.updatedAt'),
      'MM/DD/YY HH:mm',
      this.userTimeZone,
      false,
      true
    );

    return [
      `Carrier: ${carrier}`,
      `Created By: ${createdBy}`,
      `Date Created: ${dateCreated}`,
      `Last Edited: ${lastEdited}`,
      `Date Edited: ${dateEdited}`,
      `Price: $${price}`,
      `Revenue: $${revenue}`,
      `Order #: ${order}`,
      `Load: ${from} → ${to}`,
    ];
  }

  formatBid(bid: Partial<Bid>, event: BidHistoryEvent): string {
    bid = omit(bid, ['__typename']);

    const bidKeys = Object.keys(bid);

    const bidFormatted = bidKeys
      .filter((key) => {
        if (event !== BidHistoryEventType.UPDATE_BID) {
          return !!bid[key];
        }
        return bid[key] !== null;
      })
      .map((key: string) => {
        const prefix = key === 'price' ? '$' : '';
        const suffix = event === BidHistoryEventType.UPDATE_BID ? ' Change' : '';
        const field = capitalize(key);
        let value = bid[key] ? bid[key] : 'N/A';
        // todo remove after fixed on backend
        value =
          key === 'status'
            ? bidStatusToValue(value)
            : key === 'price'
            ? this.decimalPipe.transform(value, '1.2-2')
            : value;

        return `${field}${suffix}: ${prefix}${value}`;
      });

    return bidFormatted.join('; ');
  }

  onCopySuccess(): void {
    this.historyCopied.next(true);
  }

  onDone(): void {
    this.done.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { bidHistory, load } = changes;

    if (load && load.currentValue) {
      this.load = load.currentValue;
    }

    if (bidHistory && bidHistory.currentValue) {
      this.historyCopied.next(false);
      this.history = this.formatBidHistory(bidHistory.currentValue);
      const header = this.formatBidHistoryHeader(bidHistory.currentValue);

      this.historyToCopy = `${header.join(lineSeparator)}${lineSeparator.repeat(2)}${this.history.join(lineSeparator)}`;
    }
  }
}
