export interface IPostTruck {
  dot: string;
  locationLat: number;
  locationLon: number;
  location: string;
  timeAvailable: string;
  equipmentType?: string;
  brokerId?: string;
  preferredLocationLat?: number;
  preferredLocationLon?: number;
  preferredLocation?: string;
  zone?: string;
  id?: string; // the id of the truck
  timestampTruckPosted?: string;
  timeZone?: string;
  preferredLocationTimeZone?: string;
  preferredRadius?: number;
  radius?: number;
}
