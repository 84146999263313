import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoadsServiceService } from '@haulynx/services';

@Component({
  selector: 'app-load-view',
  templateUrl: './load-distribution-config.component.html',
  styleUrls: ['./load-distribution-config.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoadDistributionConfigComponent implements OnInit {
  config: any = {};

  constructor(private loadsServiceService: LoadsServiceService, public snackBar: MatSnackBar) {}

  saveConfig() {
    this.loadsServiceService.updateConfig(this.config).then((resp) => {
      this.snackBar.open(`Saved`, null, { duration: 5000 });
    });
  }

  ngOnInit(): void {
    this.loadsServiceService.getConfig().subscribe((config) => {
      this.config = config;
    });
  }
}
