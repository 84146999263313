<div class="add-trailer">
  <h3 class="add-trailer__title">Add Trailer Info</h3>

  <p class="add-trailer__description">Any changes that you make will appear across the platform</p>

  <form [formGroup]="form" data-test="trailer-form" (ngSubmit)="save()">
    <div class="add-trailer__row">
      <mat-label class="add-trailer__label"> Trailer </mat-label>
      <mat-form-field
        class="app-form-field add-trailer__field"
        hideRequiredMarker
        appearance="fill"
        floatLabel="always"
      >
        <input matInput formControlName="trailerNumber" autocomplete="off" placeholder="Enter Trailer Name" />
      </mat-form-field>
    </div>

    <div class="add-trailer__action">
      <button
        mat-dialog-close
        class="hlx-button-command hlx-button-command--cancel hlx-button-command--border-white add-trailer__button__back"
      >
        Go Back
      </button>

      <button
        type="submit"
        class="hlx-button-command hlx-button-command--book add-trailer__button__submit"
        [disabled]="form.invalid"
        (click)="save()"
        data-test="submit-button"
      >
        Submit
      </button>
    </div>
  </form>
</div>
