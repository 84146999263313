import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

interface IDictionaries {
  active: boolean;
  _id: string;
  name: string;
  code: string;
  __v: number;
}

@Injectable({
  providedIn: 'root',
})
export class DictionariesService {
  constructor(private http: HttpClient) {}

  getStates(filter): Observable<IDictionaries> {
    return this.http.post<IDictionaries>('/api/v2/dictionaries/state/search', filter);
  }

  getOrderStatuses(filter): Observable<IDictionaries> {
    return this.http.post<IDictionaries>('/api/v2/dictionaries/order-status/search', filter);
  }
}
