<mat-form-field class="google-search" appearance="outline">
  <input
    [formControl]="control"
    [errorStateMatcher]="errorMatcher"
    #addressInputElement
    matInput
    placeholder="{{ placeholder }}"
    (input)="change($event)"
  />
  <mat-icon
    matSuffix
    *ngIf="showSuffixIcon"
    [ngClass]="showSuffixIcon"
    svgIcon="haulynx-crosshair"
    (click)="onSuffixIconClick($event)"
  >
  </mat-icon>
  <mat-error *ngIf="showErrors && control.hasError('required')"> The address is required </mat-error>
  <mat-error *ngIf="parentForm && parentForm.hasError('notSelectedValues')">
    please select address from dropdown
  </mat-error>
</mat-form-field>
