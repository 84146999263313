import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoadAvailableTabsModel } from '@haulynx/store';
import { Tab } from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-load-available',
  templateUrl: './load-available.component.html',
  styleUrls: ['./load-available.component.scss'],
})
export class LoadAvailableComponent implements OnInit, OnDestroy {
  private alive = aliveWhile();

  constructor(public loadAvailableTabsModel: LoadAvailableTabsModel) {}

  ngOnInit(): void {
    this.loadAvailableTabsModel.tabsSelected$.pipe(takeUntil(this.alive)).subscribe((tab) => {
      if (tab) {
        this.loadAvailableTabsModel.goTo(`/dashboard/loads/search/all/${tab.url}`);
      }
    });
  }

  goTo(data: { tab: Tab }): void {
    this.loadAvailableTabsModel.selectTab(data.tab);
  }

  removeTab(tab: Tab): void {
    this.loadAvailableTabsModel.removeTabs(tab);
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }
}
