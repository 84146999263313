import gql from 'graphql-tag';

export const GetUsxLoads = (fullMission: boolean = true) => gql`
  query GetUsxLoads($searchParameters: LoadServiceSearchParameters, $paging: LoadsServicePageAndSort) {
    getUSXLoads(searchParameters: $searchParameters, paging: $paging) {
      data {
        created
        assignedBrokers {
          id
          name
          usxId
        }
        region
        shipperName
        consignee
        trackingType
        trackingStatus {
          usxOrderStatus
          usxOrderStatusReason
        }
        loadStatus
        id
        bookStatus
        latestMilestoneComment {
          timestamp
          timezone
          text
          contact
        }
        latestMilestoneLog {
          editedBy
          id
          primaryEvent {
            latitude
            longitude
            timestamp
            timezone
          }
          secondaryEvent {
            latitude
            longitude
            timestamp
            timezone
          }
          subType
          timestamp
          status
        }
        carrier {
          name
          dot
          owner
        }
        carrierBid {
          id
          price
          status
        }
        broker {
          name
          id
          usxId
        }
        drivers {
          id
          name
          phone
        }
        truck {
          id
          unitId
        }
        trailers {
          id
          trailerNumber
          trailerCompany
          trailerOwnerType
        }
        locations {
          id
          address
          city
          state
          zipcode
          timezone
          appointmentSet
          appointmentStart
          appointmentEnd
          locationType
          billOfLading
          geometry {
            type
            coordinates
          }
          distanceMiles
          customer {
            name
          }
        }
        mission {
          id
          ${
            fullMission
              ? `
                status
                loads {
                  id
                }`
              : ''
          }
        }
        provider {
          id
          name
          detailType
          company
        }
        providerDetails {
          billTo
          billToName
          cxPriority
          equipmentType
          commodity
          weight: weightLbs
          quantity
          operationsPriority
          regionalManagerName
          regionalManagerEmail
          regionalManagerPhone
          orderType
          brokerTeamId
          alternateIds {
            identifierType
            identifierValue
          }
          cxrEmployeeId
        }
        onTimeStatus {
          onTime
          pickupStatus
        }
        paymentDetails {
          price
          revenue
          maxBuy
          ratePerMile
          distanceMiles
        }
        restrictions {
          type
          value
        }
        recommendations {
          id
          carrierDot
          price
          matchPercentage
          exclusiveUntil
          exclusiveRatePerMile
        }
        numberOfAppointmentResets
      }
      paginator {
        currentPage
        nextPage
        previousPage
        total
        totalPages
      }
    }
  }
`;
