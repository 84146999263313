<app-spinner *ngIf="isLoading" [active]="true" [valignTop]="true" class="bids__spinner"></app-spinner>

<div [formGroup]="sortForm" class="bids__sort-form" *ngIf="showSortSelector">
  <app-spinner *ngIf="isUpdating" class="bids__container__spinner" [active]="true" [diameter]="17"></app-spinner>
  <app-selector
    class="sort-selector"
    key="value"
    [data]="sortOptions"
    styleDisplay="normal"
    placeholder="Sort by"
    formControlName="sort"
  ></app-selector>
</div>

<div class="bids__container">
  <mat-accordion displayMode="flat" hideToggle="true" class="bids" multi>
    <div *ngFor="let bid of sortedBids$ | async; trackBy: trackByFn">
      <div *appFeatures="bidV2Feature; else bidv1">
        <app-bid-item
          [bid]="bid"
          [isActive]="activeBidIds[bid.id]"
          [bidHistory]="bidHistory[bid.id]"
          [disabled]="bidsDisabled"
          [load]="load"
          [bidStatuses]="bidStatuses"
          [isLoadingAccept]="isLoadingAccept[bid.id]"
          [isLoadingCounterOffer]="isLoadingCounterOffer[bid.id]"
          [isLoadingHistory]="isLoadingHistory[bid.id]"
          [user]="user"
          [userTimeZone]="userTimeZone"
          [features]="features"
          (dataChange)="onDataChange($event)"
          (openHistory)="onOpenHistory($event)"
          (acceptBid)="onAcceptBid($event)"
          (openCounterOffer)="onOpenCounterOffer($event)"
        >
        </app-bid-item>
      </div>
      <ng-template #bidv1>
        <app-bid-item-v1
          [bid]="bid"
          [isActive]="activeBidIds[bid.id]"
          [bidHistory]="bidHistory[bid.id]"
          [disabled]="bidsDisabled"
          [load]="load"
          [bidStatuses]="bidStatuses"
          [isLoadingAccept]="isLoadingAccept[bid.id]"
          [isLoadingCounterOffer]="isLoadingCounterOffer[bid.id]"
          [isLoadingHistory]="isLoadingHistory[bid.id]"
          [user]="user"
          [userTimeZone]="userTimeZone"
          [features]="features"
          (dataChange)="onDataChange($event)"
          (openHistory)="onOpenHistory($event)"
          (acceptBid)="onAcceptBid($event)"
          (openCounterOffer)="onOpenCounterOffer($event)"
        >
        </app-bid-item-v1>
      </ng-template>
    </div>
  </mat-accordion>
</div>
