import { BooleanFilterType, ISearchFilter } from '../search-filter';
import { ISearchFilterType } from '../search-filter.types';

export class HazmatFilter implements ISearchFilter {
  name = 'Hazmat';
  type: ISearchFilterType = ISearchFilterType.BOOLEAN;
  keys: BooleanFilterType;
  description = 'Filter loads by Hazmat';

  constructor(defaultValues?: BooleanFilterType) {
    this.keys = new BooleanFilterType({
      value: undefined,
      textFormName: 'hazmat',
      ...defaultValues,
    });
  }
}
