<app-spinner [active]="loadModel.isLoadingConfirmDelivery$ | async"></app-spinner>
<data-table
  [data]="loads"
  [displayedColumns]="loadSearchColumns$ | async"
  [loading]="loadEntitiesModel.isLoading$ | async"
  [pageOptions]="userPageOptions"
  [cls]="'load-search clickable-row'"
  [clickableRow]="true"
  (onClick)="viewLoad($event)"
  [templateCellRef]="templateCell"
  [orderBy]="orderBy"
  data-test="completed-loads-table"
>
  <ng-template #templateCell let-row="row" let-column="column">
    <div *ngIf="column === 'pickupLocation'">
      {{ row.locations | nth: 0 | get: 'address':'N/A' | address: 3 }}
    </div>

    <div *ngIf="column === 'pickupState'">
      {{ row.locations | nth: 0 | get: 'address':'N/A' | address: 4 }}
    </div>

    <div *ngIf="column === 'deliveryLocation'">
      {{ row.locations | nth: -1 | get: 'address':'N/A' | address: 3 }}
    </div>

    <div *ngIf="column === 'deliveryState'">
      {{ row.locations | nth: -1 | get: 'address':'N/A' | address: 4 }}
    </div>

    <div *ngIf="column === 'pickupTimestamp'">
      {{ row.locations | nth: 0 | get: 'appointmentStart':'N/A' | timestamp: 'L' }}
    </div>

    <div *ngIf="column === 'dropoffTimestamp'">
      {{ row.locations | nth: -1 | get: 'appointmentStart':'N/A' | timestamp: 'L' }}
    </div>

    <div *ngIf="column === 'equipmentType'">
      {{ row?.providerDetails | get: 'equipmentType':'N/A' | fromCamelCase }}
    </div>

    <div *ngIf="column === 'distance'">
      {{ row?.paymentDetails | get: 'distance':'N/A' }}
    </div>

    <div *ngIf="column === 'order'" data-test="column-order">
      {{ row | loadAlternateId: loadIdentifierType.ORDER_NUMBER || 'N/A' }}
    </div>

    <div *ngIf="column === 'tmwNumber'">
      {{ row | loadAlternateId: loadIdentifierType.TMW_NUMBER || 'N/A' }}
    </div>

    <div *ngIf="column === 'shipper'">
      {{ row.locations | nth: 0 | get: 'customer.name':'N/A' }}
    </div>

    <div *ngIf="column === 'potentialCarriers'">
      {{ row.potentialCarriers }}
    </div>

    <div *ngIf="column === 'carrierOwner'">
      {{ row.carrierOwner }}
    </div>

    <div *ngIf="column === 'rpm'">
      {{ row | get: 'paymentDetails?.ratePerMile' | lessThan: 5 | showCurrency: 'USD':'symbol':'1.2-2' }}
    </div>

    <div *ngIf="column === 'price'">
      <app-load-price [price]="row?.paymentDetails?.price" [loadId]="row.id" [editablePrice]="false"></app-load-price>
    </div>

    <div *ngIf="column === 'revenue'" class="table-currency-col">
      {{ row | get: 'paymentDetails?.revenue':'N/A' | showCurrency: 'USD':'symbol':'1.0-0' }}
    </div>

    <div *ngIf="column === 'weight'">
      {{ row?.providerDetails | get: 'weight':'N/A' }}
    </div>
  </ng-template>
</data-table>
