import { ActionReducer, ActionReducerMap } from '@ngrx/store';
import { InjectionToken, Provider } from '@angular/core';
import { USER_PROVIDED_EFFECTS } from '@ngrx/effects';
import { IAsyncEntityState } from '@haulynx/types';
import { MissionEntityService } from './mission-entity.service';

export const MISSION_ENTITY_REDUCERS_TOKEN = new InjectionToken<ActionReducerMap<IAsyncEntityState<unknown>>>(
  'mission reducers'
);

function getMissionEntityReducersFactory(
  missionEntityService: MissionEntityService
): ActionReducer<IAsyncEntityState<unknown>> {
  return missionEntityService.getReducers();
}

const missionEntityReducerProvider: Provider = {
  provide: MISSION_ENTITY_REDUCERS_TOKEN,
  useFactory: getMissionEntityReducersFactory,
  deps: [MissionEntityService],
};

const missionEntityEffectProvider: Provider = {
  provide: USER_PROVIDED_EFFECTS,
  multi: true,
  useValue: [MissionEntityService],
};

export const missionStateProviders: Provider[] = [missionEntityReducerProvider, missionEntityEffectProvider];
