import { Component, Input, Output, ChangeDetectionStrategy, TemplateRef, EventEmitter } from '@angular/core';
import { IAsyncSearchResultsPaginationState } from '@haulynx/types';

@Component({
  selector: 'haulynx-virtual-scroll',
  templateUrl: './virtual-scroll.component.html',
  styleUrls: ['./virtual-scroll.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VirtualScrollComponent {
  @Input() size: number = 160;
  @Input() data: any[] = [];
  @Input() customReference: TemplateRef<any>;
  @Input() customButtonReference: TemplateRef<any>;
  @Input() pagination: IAsyncSearchResultsPaginationState;

  @Output() loadMoreData = new EventEmitter();
  @Output() endOfData = new EventEmitter<boolean>();

  constructor() {}

  /**
   * scrollIndex tracks current index location of scroll
   * @param index
   */
  scrollIndex(index: number): void {
    if (
      this.pagination?.totalPages > 1 &&
      this.pagination.currentPage < this.pagination.totalPages &&
      index / this.data.length > 0.75
    ) {
      this.endOfData.emit(true);
      return;
    }
    this.endOfData.emit(false);
  }
}
