import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'haulynx-dont-meet-requirements-dialog',
  templateUrl: './dont-meet-requirements-dialog.component.html',
  styleUrls: ['./dont-meet-requirements-dialog.component.scss'],
})
export class DontMeetRequirementsDialogComponent {
  constructor(private dialogRef: MatDialogRef<DontMeetRequirementsDialogComponent>) {}

  public close(): void {
    this.dialogRef.close();
  }
}
