import { InjectionToken, Provider } from '@angular/core';
import { Bid, IAsyncEntityState } from '@haulynx/types';
import { USER_PROVIDED_EFFECTS } from '@ngrx/effects';
import { ActionReducer, ActionReducerMap } from '@ngrx/store';
import { BidEntityService } from './bid-entity.service';

export const BID_ENTITY_REDUCERS_TOKEN = new InjectionToken<ActionReducerMap<IAsyncEntityState<Bid>>>(
  'bid entity reducers'
);

function getBidEntityReducersFactory(bidEntityService: BidEntityService): ActionReducer<IAsyncEntityState<Bid>> {
  return bidEntityService.getReducers();
}

const bidEntityReducerProvider: Provider = {
  provide: BID_ENTITY_REDUCERS_TOKEN,
  useFactory: getBidEntityReducersFactory,
  deps: [BidEntityService],
};

const bidEntityEffectProvider: Provider = {
  provide: USER_PROVIDED_EFFECTS,
  multi: true,
  useValue: [BidEntityService],
};

export const bidStateProviders: Provider[] = [bidEntityReducerProvider, bidEntityEffectProvider];
