import { Injectable } from '@angular/core';
import { DeviceEntityService } from '@haulynx/services';
import { EntityServicesBase, EntityServicesElements } from '@ngrx/data';

@Injectable()
export class AppEntityServices extends EntityServicesBase {
  constructor(
    entityCollectionServiceFactory: EntityServicesElements,

    // Inject custom services, register them with the EntityServices, and expose in API.
    public readonly deviceEntityService: DeviceEntityService
  ) {
    super(entityCollectionServiceFactory);
    this.registerEntityCollectionServices([deviceEntityService]);
  }
}
