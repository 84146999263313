import { List, Map, Record } from 'immutable';

export interface ICommonEntityState {
  isLoading: boolean;
  entities: List<any>;
  query: Map<string, any>;
}

const CommonEntityStateRecord = Record({
  isLoading: false,
  entities: List(),
  query: Map<any, any>(),
});

export class CommonEntityState extends CommonEntityStateRecord implements ICommonEntityState {
  isLoading: boolean;
  entities: List<any>;
  query: Map<string, any>;
}
