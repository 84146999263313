import gql from 'graphql-tag';

export const GetLoadServiceMetaData = gql(`
  query getLoadsServiceSearchMetaData( $searchParameters: LoadServiceSearchParameters, $histogramField: LoadsServiceHistogramField, $histogramInterval: NonNegativeInt){
    getLoadsServiceSearchMetaData(searchParameters: $searchParameters, histogramField: $histogramField, histogramInterval: $histogramInterval){
        totalLoads
        aggregations{
            bookedAt{
                value
            }
            prices{
                buckets{
                    key
                    doc_count
                }
            }
            loadedMiles{
                buckets{
                    key
                    doc_count
                }
            }
        }
    }   
}
`);
