import { Injectable } from '@angular/core';
import {
  ActiveLoadFilter,
  LoadsServiceLoad,
  loadStepType,
  LoadStepType,
  PaginationParams,
  SearchParams,
} from '@haulynx/types';
import { listToArray } from '@haulynx/utils';
import { Store } from '@ngrx/store';
import { List } from 'immutable';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppState } from '../../main-store/app.reducers';
import { ActiveLoadsActions } from './active-loads-state.actions';
import {
  activeLoadsSearchSelector,
  activeLoadsSelectedLoadIdSelector,
  brokersSelectors,
  carriersSelectors,
  isLoadingBrokersSelectors,
  isLoadingCarriersSelectors,
} from './active-loads-state.selectors';

@Injectable()
export class ActiveLoadsModel {
  entitiesQuery$: Observable<ActiveLoadFilter> = this.store
    .select(activeLoadsSearchSelector.getQuery)
    .pipe(map((query: ActiveLoadFilter) => query));
  entities$: Observable<List<LoadsServiceLoad>> = this.store
    .select(activeLoadsSearchSelector.getEntities)
    .pipe(map((entities) => entities));
  entitiesLoading$: Observable<boolean> = this.store.select(activeLoadsSearchSelector.getLoading);
  entitiesPagination$: Observable<PaginationParams> = this.store.select(activeLoadsSearchSelector.getPagination);
  selectedLoadId$: Observable<string> = this.store.select(activeLoadsSelectedLoadIdSelector);
  brokersEntities$: Observable<any[]> = this.store
    .select(brokersSelectors)
    .pipe(map((carriers) => listToArray(carriers)));
  brokersIsLoading$: Observable<boolean> = this.store.select(isLoadingBrokersSelectors);
  carriersEntities$: Observable<any[]> = this.store
    .select(carriersSelectors)
    .pipe(map((carriers) => listToArray(carriers)));
  carriersIsLoading$: Observable<boolean> = this.store.select(isLoadingCarriersSelectors);
  activeStepTypes$: Observable<Array<LoadStepType[keyof LoadStepType]>> = of([]).pipe(
    map(() => [loadStepType.ASSIGNED, loadStepType.DISPATCHED])
  );

  constructor(private store: Store<AppState>) {}

  findAndUpdate(id: string, entity: Partial<LoadsServiceLoad>) {
    this.store.dispatch(ActiveLoadsActions.searchUpdate({ id, entity }));
  }

  searchLoads(data: SearchParams) {
    this.store.dispatch(ActiveLoadsActions.search(data));
  }

  searchCarriers(params: SearchParams) {
    this.store.dispatch(ActiveLoadsActions.searchCarrier(params));
  }

  searchBrokers(params: SearchParams) {
    this.store.dispatch(ActiveLoadsActions.searchBroker(params));
  }

  selectLoad(loadId: string) {
    this.store.dispatch(ActiveLoadsActions.selectLoad(loadId));
  }
}
