import gql from 'graphql-tag';

export const GetOpportunities = gql`
  query GetOpportunities($opportunityParams: OpportunityParamsInput) {
    getOpportunities(opportunityParams: $opportunityParams) {
      freightId
      carrierId
      recommendationId
      brokerId
      score
      opportunityType
      load {
        id
        carrier {
          dot
          name
        }
        providerDetails {
          alternateIds {
            identifierType
            identifierValue
          }
          equipmentType
        }
        locations {
          id
          address
          summaryAddress
          city
          state
          zipcode
          timezone
          appointmentSet
          appointmentStart
          appointmentEnd
          locationType
          geometry {
            type
            coordinates
          }
          customer {
            name
            number
          }
          notes {
            author
            text
          }
        }
        paymentDetails {
          price
          revenue
        }
      }
      carrier {
        name
        dot
        id
        brokerOwners
      }
    }
  }
`;
