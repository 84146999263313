import { MapsAPILoader } from '@agm/core';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { TransformEvent } from '@haulynx/directives';
import {
  GoogleAddressService,
  GoogleAnalyticsService,
  LoadFeedFilterService,
  LocalStoreService,
  MomentService,
  UserService,
} from '@haulynx/services';
import { LoadFeedModel } from '@haulynx/store';
import {
  Action,
  AddressField,
  Category,
  dateButtons,
  EquipmentTypes,
  equipmentTypes,
  IPostTruck,
  LoadsServiceLoad,
  RadiusSearchOption,
  radiusSearchOptions,
  User,
} from '@haulynx/types';
import { aliveWhile, AppDateAdapter, APP_DATE_FORMATS } from '@haulynx/utils';
import { List } from 'immutable';
import { get, isEqual, trim } from 'lodash';
import moment from 'moment';
import { combineLatest, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, skip, take, takeUntil } from 'rxjs/operators';
import { DatepickerComponent } from '../../../datepicker/datepicker.component';
import { AppAddressFieldComponent } from '../../../google-address-field/components/app-address-field.component';
import { LoadFeedSearchFormVm } from './load-feed-search-form.vm';

@Component({
  selector: 'app-load-feed-search-form',
  templateUrl: './load-feed-search-form.component.html',
  styleUrls: ['./load-feed-search-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ],
})
export class LoadFeedSearchFormComponent implements OnChanges, OnDestroy, OnInit {
  @ViewChild('destination', { static: true }) destination: AppAddressFieldComponent;
  @ViewChild('datepicker', { static: true }) datepicker: DatepickerComponent;
  @Input() defaultRadius: number;
  @Input() defaultPreferredLocation: AddressField;
  @Input() truckToPopulate: IPostTruck;
  @Input() loadDefaultRadius: number;
  @Input() query = null;
  @Input() locations: unknown[] = [];
  @Input() equipmentTypes: EquipmentTypes[] = [{ selected: true, text: 'Any' }, ...equipmentTypes];
  @Output() onSearch = new EventEmitter();
  @Output() onClick = new EventEmitter<string>();

  alive = aliveWhile();
  user: User;
  activePickUpDateButton: number;
  radiusSearchOptions: RadiusSearchOption[] = radiusSearchOptions;
  dateButtons = dateButtons;
  form: FormGroup;
  userLocation: AddressField = {
    address: 'Your Location',
    lon: null,
    lat: null,
  };
  showAdvanced = false;

  private search$ = new Subject();

  constructor(
    private loadFeedModel: LoadFeedModel,
    private localStoreService: LocalStoreService,
    private loadFeedFilterService: LoadFeedFilterService,
    private userService: UserService,
    private googleAddressService: GoogleAddressService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private loadFeedSearchFormVm: LoadFeedSearchFormVm,
    private cd: ChangeDetectorRef,
    private mapsApiLoader: MapsAPILoader,
    private momentService: MomentService
  ) {
    this.userService.user.pipe(takeUntil(this.alive)).subscribe((ret) => {
      this.user = ret;
    });
    this.form = this.loadFeedSearchFormVm.create();

    this.form
      .get('pickupDate')
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.alive))
      .subscribe((date = {}) => {
        const { begin = null, end = null } = date || {};

        this.activePickUpDateButton = this.getActiveDate(begin, end);
      });

    this.search$.pipe(debounceTime(400), takeUntil(this.alive)).subscribe(({ form, onClick = false }) => {
      if (onClick) {
        this.onClick.emit('search');
      }
      this.onSearch.emit(form);
    });
  }

  transformDate(event: TransformEvent): void {
    const interval = setInterval(() => {
      this.search(true);
    }, 200);
    const [start, end] = event.value.split('-');
    let startDate: moment.Moment, endDate: moment.Moment;

    switch (start.toLowerCase()) {
      case 'today':
        startDate = this.momentService.getMoment();
        endDate = startDate.clone();
        break;
      case 'tomorrow':
        startDate = this.momentService.getMoment().add(1, 'day');
        endDate = startDate.clone();
        break;
      case 'next':
        startDate = this.momentService.getMoment().add(2, 'day');
        endDate = startDate.clone();
        break;
      default:
        break;
    }

    if (!startDate && !endDate) {
      const dateRegex = /\d+\/\d+\/(\d{4}|\d{2})/;
      if (!dateRegex.test(start) || !dateRegex.test(end)) {
        return clearInterval(interval);
      }

      startDate = this.momentService.getMoment(start);
      endDate = this.momentService.getMoment(end);
    }

    if (!startDate.isValid() || !endDate.isValid()) {
      return clearInterval(interval);
    }

    if (startDate.isAfter(endDate)) {
      return clearInterval(interval);
    }

    if (Math.abs(startDate.diff(endDate, 'days')) > 7) {
      return clearInterval(interval);
    }

    const newDates = {
      begin: startDate,
      end: endDate,
    };

    event.control.patchValue(newDates);
    this.search(true);
    clearInterval(interval);
  }

  transformRadius(event: TransformEvent): void {
    const interval = setInterval(() => {
      this.search(true);
    }, 200);
    const keys = radiusSearchOptions.map((option: RadiusSearchOption) => option.label.split(' mi')[0]);
    const values = radiusSearchOptions.map((option: RadiusSearchOption) => option.value);

    const keyIndex = keys.indexOf(event.value);
    const valueIndex = values.indexOf(Number(event.value));
    if (keyIndex > -1) {
      event.control.patchValue(radiusSearchOptions[keyIndex].value);
      this.search(true);
      return clearInterval(interval);
    } else if (valueIndex > -1) {
      event.control.patchValue(radiusSearchOptions[valueIndex].value);
      this.search(true);
      return clearInterval(interval);
    }

    event.value = event.value.replace(/\D/g, ''); // Remove all non-numeric characters
    const numberValue = Math.round(Number(event.value) / 0.00062137); // Transform the value into meters
    radiusSearchOptions.push({ label: `${event.value} mi`, value: numberValue });
    event.control.patchValue(numberValue);
    this.search(true);
    clearInterval(interval);
  }

  async transformLocation(event: TransformEvent): Promise<void> {
    const interval = setInterval(() => {
      this.search(true);
    }, 200);
    await this.mapsApiLoader.load();
    const input = document.createElement('input');
    const placesService = new google.maps.places.PlacesService(input);
    placesService.textSearch({ query: `${event.value}, USA` }, (result) => {
      if (!result.length) {
        return clearInterval(interval);
      }

      const place = result[0];
      if (!place.geometry) {
        return clearInterval(interval);
      }

      const lat = place.geometry.location.lat();
      const lon = place.geometry.location.lng();
      const address = trim(place.formatted_address);
      const timeZone = this.momentService.getTimeZoneByLocation(lat, lon);

      event.control.patchValue({ lat, lon, address, timeZone });
      this.search(true);
      clearInterval(interval);
    });
  }

  getActiveDate(begin, end) {
    if (this.loadFeedFilterService.isToday(begin) && this.loadFeedFilterService.isToday(end)) {
      return this.loadFeedFilterService.getButtonByLabel('Today');
    }

    if (this.loadFeedFilterService.isTomorrow(begin) && this.loadFeedFilterService.isTomorrow(end)) {
      return this.loadFeedFilterService.getButtonByLabel('Tomorrow');
    }

    if (this.loadFeedFilterService.isNextDay(begin) && this.loadFeedFilterService.isNextDay(end)) {
      return this.loadFeedFilterService.getButtonByLabel('Next Day');
    }

    return null;
  }

  ngOnInit(): void {
    // If the user came from DOT sign up, they will have selected lanes to be used in the load feed
    this.localStoreService.get('dotLanes').then((data) => {
      const dotLanesFromSignup = data;
      if (dotLanesFromSignup) {
        const begin = moment().startOf('day');
        const end = moment().clone().add(6, 'day').endOf('day');
        this.form = this.loadFeedSearchFormVm.create({
          pickUpLocation: get(dotLanesFromSignup, 'origin', null),
          deliveryLocation: get(dotLanesFromSignup, 'destination', null),
          pickupDate: {
            begin,
            end,
          },
        });
        this.localStoreService.clear('dotLanes');
      }

      this.search();

      if (dotLanesFromSignup) {
        combineLatest([this.loadFeedModel.loadFeedSearch.isLoading$, this.loadFeedModel.loadFeedSearch.entities$])
          .pipe(
            filter(([loading]) => !loading),
            skip(1),
            take(1)
          )
          .subscribe(([loading, ent]: [boolean, List<LoadsServiceLoad>]) => {
            this.googleAnalyticsService.eventEmitter(
              Category.DOT_SIGNUP,
              Action.AUTOMATIC_LOAD_FEED_SEARCH,
              `Found ${ent.count()}`
            );
          });
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { query, defaultPreferredLocation } = changes;

    if (defaultPreferredLocation) {
      const preferredLocation = defaultPreferredLocation.currentValue;
      if (this.form) {
        this.form.patchValue({ preferredLocation });
      }
    }

    if (query) {
      const formData = query.currentValue;
      const formRawData = this.form.getRawValue();

      if (!isEqual(formData, formRawData)) {
        this.form.patchValue(formData ? formData : {});
      }
    }
  }

  setRadiusControlState(formControlName: string, stateSelected: boolean): void {
    const control = this.form.controls[formControlName];
    if (stateSelected) {
      control.disable();
    } else {
      control.enable();
    }
    this.cd.detectChanges();
  }

  setCurrentUserLocation() {
    this.googleAddressService.getCurrentPosition().subscribe((userLocation) => {
      this.form.patchValue({ pickUpLocation: userLocation });
    });
  }

  dateButtonClick(activeDateButton: number): void {
    const newActiveDate = this.loadFeedFilterService.selectDate(activeDateButton);

    this.activePickUpDateButton = activeDateButton;
    this.form.patchValue({
      pickupDate: {
        begin: newActiveDate,
        end: newActiveDate,
      },
    });
  }

  search(onClick = false): void {
    if (this.form.valid) {
      this.search$.next({ form: this.form.getRawValue(), onClick });
    }
  }

  click() {
    this.onClick.emit('search');
    this.search();
  }

  reset() {
    const defaultValues = this.loadFeedSearchFormVm.defaultValues();
    this.form.reset(defaultValues);
  }

  advanced() {
    this.showAdvanced = !this.showAdvanced;
  }

  switchLocations(): void {
    const pickUpLocation = this.form.get('pickUpLocation').value;
    const deliveryLocation = this.form.get('deliveryLocation').value;

    this.form.patchValue({
      deliveryLocation: pickUpLocation,
      pickUpLocation: deliveryLocation,
    });
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }
}
