<div class="msi">
  <div
    class="system"
    *ngIf="subItemType === 'system' && displaySubItemSystem"
    [attr.data-test]="'system-generated-sub-item'"
  >
    <div class="content">
      <div class="content__badge">
        <mat-icon class="system-icon" svgIcon="haulynx-system"></mat-icon>
      </div>
      <div class="content__info">
        <div class="content__info__title" *ngIf="log">
          {{ 'System Generated ' + getItemType + ' Milestone' }}
        </div>
        <div class="content__info__title" *ngIf="telemetryHistory">
          {{ 'System Generated Trailer Position' }}
        </div>
        <div class="content__info__content" *ngIf="log">
          {{ log.timestamp | dateTime: null }}
        </div>
        <div class="content__info__content" *ngIf="telemetryHistory">
          {{ telemetryHistoryTimeStamp | dateTime: null }}
        </div>
        <div class="content__info__content" *ngIf="telemetryHistory">
          {{ locationFromLatLong | async }}
        </div>
        <div class="content__info__content" *ngIf="telemetryHistory">
          {{ telemetryHistory.latitude }}, {{ telemetryHistory.longitude }}
        </div>
      </div>
    </div>
    <div *ngIf="openSystem$ | async">
      <haulynx-milestone-sub-item-system
        [user]="'broker'"
        [log]="log"
        [milestone]="milestone"
        [telemetryHistory]="telemetryHistory"
        [locationFromLatLong]="locationFromLatLong | async"
      ></haulynx-milestone-sub-item-system>
    </div>
    <div *ngIf="milestone?.type != 'LOCATION'">
      <haulynx-milestone-sub-item-user
        [user]="subItemType"
        [milestone]="milestone"
        [load]="load"
      ></haulynx-milestone-sub-item-user>
    </div>
  </div>

  <div *ngIf="subItemType === 'broker_info' && displaySubItemBroker" [attr.data-test]="'broker-info-sub-item'">
    <div class="content">
      <div class="content__badge">
        <mat-icon svgIcon="haulynx-boxed-person"></mat-icon>
      </div>
      <div class="content__info">
        <div class="content__info__title" data-test="milestone-creator">
          {{ 'Broker ' + getBrokerMilestoneLog.editedBy }}
        </div>
        <div class="content__info__content">
          {{ getBrokerMilestoneLog.timestamp | dateTime: null }}
        </div>
      </div>
      <div class="content__buttons">
        <mat-icon
          *ngIf="displayBrokerDelete"
          (click)="commentActions({ action: 'delete-milestone-log', index: milestoneIndex })"
          data-test="delete-button"
          >delete_outline</mat-icon
        >
        <mat-icon
          *ngIf="displayBrokerEdit"
          (click)="commentActions({ action: 'edit-milestone', index: milestoneIndex })"
          svgIcon="haulynx-edit"
          data-test="edit-button"
        ></mat-icon>
      </div>
    </div>
    <haulynx-milestone-sub-item-user
      [user]="'broker'"
      [milestone]="milestone"
      [load]="load"
    ></haulynx-milestone-sub-item-user>
  </div>

  <div class="carrier" *ngIf="subItemType === 'carrier_info' && displaySubItemCarrier">
    <div class="content">
      <div class="content__badge">
        <mat-icon svgIcon="haulynx-boxed-truck"></mat-icon>
      </div>
      <div class="content__info">
        <div class="content__info__title">Carrier Submitted Milestone</div>
        <div class="content__info__content">
          {{ getCarrierMilestoneLog?.timestamp | dateTime: null }}
        </div>
      </div>
      <div class="content__buttons">
        <mat-icon
          (click)="commentActions({ action: 'edit-milestone', index: milestoneIndex })"
          *ngIf="displayCarrierEdit"
          svgIcon="haulynx-edit"
        ></mat-icon>
      </div>
    </div>
    <haulynx-milestone-sub-item-user
      [user]="'carrier'"
      [milestone]="milestone"
      [load]="load"
    ></haulynx-milestone-sub-item-user>
  </div>

  <div
    *ngIf="subItemType === 'comment' && milestone.type !== 'LOCATION' && milestone.type !== 'TRAILER'"
    [attr.data-test]="'comments-sub-item'"
  >
    <div class="content">
      <div class="content__badge">
        <mat-icon svgIcon="haulynx-boxed-comment"></mat-icon>
      </div>
      <div class="content__info">
        <div class="content__info__title">
          {{ 'Comments' }}
        </div>
        <div class="content__info__content">
          {{ countComments + ' comment' }}
        </div>
      </div>
    </div>
    <haulynx-milestone-sub-item-comments
      [user]="user"
      [currentUserType]="currentUserType"
      [milestone]="milestone"
      [milestoneIndex]="milestoneIndex"
      (commentAction)="commentActions($event)"
    ></haulynx-milestone-sub-item-comments>
    <div class="content__button">
      <button
        mat-button
        (click)="commentActions({ action: 'open-comment', index: milestoneIndex })"
        data-test="add-comment"
      >
        Add Comment
      </button>
    </div>
  </div>
</div>
