import { Driver, TrackingType, Trailer, TrailerOwnerType, Truck } from '../truck';
import { User } from '../generic';

export interface AssignDriverForm {
  trackingType?: TrackingType;
  driver: Driver | User;
  truck: Truck;
  trailerOwner?: TrailerOwnerType;
  trailer?: Trailer;
  phone?: string;
  email?: string;
  equipment?: string;
  secondaryDriver?: Driver | User;
  secDriverPhone?: string;
  secDriverEmail?: string;
}
