import { LoadsServiceLoad } from '@haulynx/types';
import { createAction, createActionTypes } from '../../shared/store/helpers/action';

const TYPE = '[BOOK LOAD]';

export interface IBookLoadActions {
  bookLoad(params: { load: LoadsServiceLoad; carrierDot: string });
  bookLoadSuccess(params: { loadId: string });
  bookLoadError(params?: { loadId: string });

  getBrokerBookedLoads(params: { loadId: string; carrierDot: string });
  getBrokerBookedLoadsSuccess(params: { loadId: string });
  getBrokerBookedLoadsError(params: { loadId: string });
}

export interface IBookLoadActionTypes {
  BOOK_LOAD: string;
  BOOK_LOAD_SUCCESS: string;
  BOOK_LOAD_ERROR: string;

  GET_BROKER_BOOKED_LOADS: string;
  GET_BROKER_BOOKED_LOADS_SUCCESS: string;
  GET_BROKER_BOOKED_LOADS_ERROR: string;
}

const bookLoadActionTypes = {
  BOOK_LOAD: 'book load',
  BOOK_LOAD_SUCCESS: 'book load success',
  BOOK_LOAD_ERROR: 'book load error',

  GET_BROKER_BOOKED_LOADS: 'get broker booked loads',
  GET_BROKER_BOOKED_LOADS_SUCCESS: 'get broker booked loads success',
  GET_BROKER_BOOKED_LOADS_ERROR: 'get broker booked loads error',
};

const bookLoadActions = {
  bookLoad: createAction(TYPE, bookLoadActionTypes.BOOK_LOAD),
  bookLoadSuccess: createAction(TYPE, bookLoadActionTypes.BOOK_LOAD_SUCCESS),
  bookLoadError: createAction(TYPE, bookLoadActionTypes.BOOK_LOAD_ERROR),

  getBrokerBookedLoads: createAction(TYPE, bookLoadActionTypes.GET_BROKER_BOOKED_LOADS),
  getBrokerBookedLoadsSuccess: createAction(TYPE, bookLoadActionTypes.GET_BROKER_BOOKED_LOADS_SUCCESS),
  getBrokerBookedLoadsError: createAction(TYPE, bookLoadActionTypes.GET_BROKER_BOOKED_LOADS_ERROR),
};

export const BookLoadActions: IBookLoadActions = bookLoadActions;
export const BookLoadActionTypes: IBookLoadActionTypes = createActionTypes(bookLoadActionTypes)(TYPE);
