import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LoadFeedActionsService } from '@haulynx/services';
import { AppModel } from '@haulynx/store';
import { buttonTypes, Carrier, CarrierSourceType, IColumns, LoadsServiceLoad, User } from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { List, Map } from 'immutable';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilKeyChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-load-feed-carrier-search',
  templateUrl: './load-feed-carrier-search.component.html',
  styleUrls: ['./load-feed-carrier-search.component.scss'],
})
export class LoadFeedCarrierSearchComponent implements OnChanges {
  @Input() data: List<any> | Map<string, any> | any[] = [];
  @Input() load: LoadsServiceLoad = null;
  @Input() isLoading = false;
  @Input() pagination = null;
  @Input() query: { [key: string]: any } = {};
  @Input() disableButtons = {};

  @Output() onPagination = new EventEmitter();
  @Output() onSearch = new EventEmitter();
  @Output() onAction = new EventEmitter<{ action: string; row: any }>();
  @Output() onCarrierSelect = new EventEmitter<Carrier>();

  buttons$ = new BehaviorSubject([]);
  form: FormGroup;
  pageOptions = [50];
  dataTableColumns: IColumns[] = [];
  user: User;
  carrierSourceType = CarrierSourceType;
  alive = aliveWhile();

  constructor(
    private fb: FormBuilder,
    private appModel: AppModel,
    private loadFeedActionsService: LoadFeedActionsService
  ) {
    this.appModel.user$.pipe(takeUntil(this.alive)).subscribe((user) => (this.user = user));

    this.form = this.fb.group({
      carrierSearch: [null],
      loadId: [null],
    });

    this.dataTableColumns = this.getColumns(false);
    this.form.valueChanges
      .pipe(takeUntil(this.alive), debounceTime(500), distinctUntilKeyChanged('carrierSearch'))
      .subscribe((values) => {
        this.onSearch.emit(values);
      });
    this.buttons$.next([buttonTypes.EMAIL_LOAD]);
  }

  getColumns(isRecommended): IColumns[] {
    let columns = [];

    if (isRecommended) {
      columns = [
        {
          dataIndex: 'carrier',
          isCustomCell: true,
          label: 'Carrier',
          sortConvert: this.loadFeedActionsService.fullCarrierFieldSort.bind(this.loadFeedActionsService),
        },
        {
          label: 'Ownership',
          dataIndex: 'owner',
          isCustomCell: true,
          sortConvert: this.loadFeedActionsService.fullCarrierFieldSort.bind(this.loadFeedActionsService),
        },
        {
          label: 'Type',
          dataIndex: 'sourceType',
          isCustomCell: true,
        },
        {
          dataIndex: 'match',
          isCustomCell: true,
          label: 'Match',
          isSortable: false,
        },
        {
          dataIndex: 'clicker',
          isCustomCell: true,
          isSortable: false,
        },
        {
          dataIndex: 'action',
          isCustomCell: true,
          label: '',
          width: '120px',
          isSortable: false,
        },
        {
          label: '',
          dataIndex: 'bookLoadButton',
          isCustomCell: true,
        },
      ];
    } else {
      columns = [
        {
          label: 'Carrier Name',
          dataIndex: 'name',
          width: '222px',
          isCustomCell: true,
        },
        {
          label: 'Ownership',
          dataIndex: 'owner',
          width: '138px',
          isCustomCell: true,
        },
        {
          label: 'Type',
          dataIndex: 'sourceType',
          isCustomCell: true,
        },
        {
          label: 'DOT',
          dataIndex: 'dot',
          width: '138px',
          isCustomCell: true,
        },
        {
          label: 'Contact Name',
          dataIndex: 'adminName',
          width: '138px',
          isCustomCell: true,
        },
        {
          dataIndex: 'clicker',
          isCustomCell: true,
          isSortable: false,
        },
        {
          dataIndex: 'action',
          isCustomCell: true,
          label: '',
          width: '130px',
          isSortable: false,
        },
        {
          label: '',
          dataIndex: 'bookLoadButton',
          isCustomCell: true,
          isSortable: false,
        },
      ];
    }

    return columns;
  }

  pageChange(event) {
    this.onPagination.emit(event);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { query, data } = changes;

    if (query) {
      this.form.patchValue(query.currentValue || {});
    }

    if (data) {
      const { carrierSearch } = this.query;

      this.dataTableColumns = carrierSearch ? this.getColumns(false) : this.getColumns(true);
    }
  }

  action(action, row) {
    this.onAction.emit({ action, row });
  }

  selectCarrier(event: { selection: Carrier }): void {
    const { selection = null } = event || {};
    this.onCarrierSelect.emit(selection);
  }
}
