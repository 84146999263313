import { Injectable } from '@angular/core';
import { LoadsServiceLoad, LoadsServiceLoadLocation, MapboxRoute, MapType, WayPoint } from '@haulynx/types';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LoadsServiceService } from '../loads-service/loads-service.service';
import { BroadcastMessage, BroadcastService } from '../shared-services/broadcast.service';

@Injectable({
  providedIn: 'root',
})
export class CarrierLoadMapService {
  constructor(private broadcastService: BroadcastService, private loadsServiceService: LoadsServiceService) {}

  /**
   * Get the current map type color theme for carrier load maps
   * @returns
   */
  public colorTheme(): Observable<MapType> {
    return combineLatest([
      of(document.documentElement.getAttribute('data-theme')),
      this.broadcastService.messagesOfType('theme-change').pipe(startWith(null as any)),
    ]).pipe(
      map(([lightMode, themeChange]: [any, BroadcastMessage]) => {
        const storage = window.localStorage.getItem('enableLightMode');
        const lightModeEnabled = storage ? (storage === 'true' ? true : false) : true;

        let theme = lightModeEnabled && lightMode === 'light' ? MapType.LIGHT : MapType.DARK;
        if (themeChange?.payload) {
          theme = themeChange?.payload === 'light' ? MapType.LIGHT : MapType.DARK;
        }

        return theme;
      })
    );
  }

  /**
   * Get mapbox coordinates for a single stop
   * @param stop
   * @param entityId
   * @returns
   */
  public singleStopMapboxRoute(
    stop: LoadsServiceLoadLocation,
    { entityId = 'Carrier Load Details Single Stop Map' }: { entityId?: string } = {}
  ): MapboxRoute[] {
    return [
      {
        entityId,
        wayPoints: [
          {
            name: stop?.summaryAddress as string,
            location: stop?.geometry?.coordinates as [number, number],
            locationType: stop?.locationType,
            isDetention: false,
            className: `carrier-load-search-${stop?.locationType}`,
            removeTextContent: true,
          },
        ],
        routeCoordinates: [stop?.geometry?.coordinates, stop?.geometry?.coordinates] as any,
        eldPoints: [],
        isDelayed: false,
      },
    ];
  }

  public loadMapboxRoute(
    load: LoadsServiceLoad,
    {
      entityId = 'Carrier Load Details Map',
      routeLineColor = '#1F8C4E',
      routeLineWidth = 2,
    }: { entityId?: string; routeLineColor?: string; routeLineWidth?: number } = {}
  ): Observable<{ route: MapboxRoute[]; loading: boolean }> {
    const response: BehaviorSubject<{ loading: boolean; route: MapboxRoute[] }> = new BehaviorSubject({
      loading: true,
      route: [],
    });

    const wayPoints = load.locations.map((loadLocation: LoadsServiceLoadLocation): WayPoint => {
      const location = loadLocation?.geometry?.coordinates || [];
      return {
        name: `${loadLocation.summaryAddress ?? ''}`,
        location: location as [number, number],
        inTimestamp: loadLocation.carrierArrival,
        outTimestamp: loadLocation.carrierDeparture,
        locationType: loadLocation.locationType,
        isDetention: false,
        className: `carrier-load-search-${loadLocation.locationType}`,
        removeTextContent: true,
      };
    });

    const loadWayPoints = load.locations
      .map((loadLocation: LoadsServiceLoadLocation): string => (loadLocation?.geometry?.coordinates || []).join(','))
      .join(';');

    this.loadsServiceService.getSingleLoadRoute(loadWayPoints).subscribe((points: any) => {
      const routeCoordinates = points.routes?.[0].geometry?.coordinates;

      const route = [
        {
          entityId,
          wayPoints,
          routeCoordinates,
          eldPoints: [],
          isDelayed: false,
          showTruck: true,
          lineColor: routeLineColor,
          lineWidth: routeLineWidth,
        },
      ];

      response.next({ loading: false, route });
      response.complete();
    });

    return response.asObservable();
  }
}
