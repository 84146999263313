import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PaymentTypeStateModel } from './payment-type-state-model';
import { PaymentTypesEffects } from './payment-types.effects';
import { paymentTypesReducer } from './payment-types.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature('payment-types', paymentTypesReducer),
    EffectsModule.forFeature([PaymentTypesEffects]),
  ],
  providers: [PaymentTypeStateModel, PaymentTypesEffects],
})
export class PaymentTypeStoreModule {}
