import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ElementsModule } from '@haulynx/elements';
import { DialogTemplateModule } from '../dialog-template/dialog-template.module';
import { MoreAboutRmisDialogComponent } from './more-about-rmis-dialog.component';

@NgModule({
  declarations: [MoreAboutRmisDialogComponent],
  imports: [CommonModule, DialogTemplateModule, FlexLayoutModule, ElementsModule],
  exports: [MoreAboutRmisDialogComponent],
})
export class MoreAboutRmisDialogModule {}
