<div (click)="close($event)" [ngClass]="{ 'app-notification__block': showNotification }" class="app-notification">
  <div (click)="$event.stopPropagation()" [ngClass]="{ notification__show: showNotification }" class="notification">
    <app-spinner [active]="isLoading" [fullCover]="true"></app-spinner>
    <div class="notification__header">
      <form [formGroup]="form">
        <app-drop-down [data]="types" formControlName="eventType"></app-drop-down>
        <mat-icon (click)="close($event)" [attr.data-test]="'notification-close'">cancel</mat-icon>
      </form>
    </div>

    <div class="notification__content">
      <app-notification-search
        (selectNotification)="select($event)"
        [notifications]="availableNotifications"
        [user]="user"
      ></app-notification-search>
    </div>

    <!--    <div class="notification__footer"><mat-icon>menu</mat-icon> <span (click)="all()">See All Notifications</span></div>-->
  </div>
</div>
