import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationBanner } from '@haulynx/types';
@Component({
  selector: 'haulynx-carrier-banner',
  templateUrl: './carrier-banner.component.html',
  styleUrls: ['./carrier-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarrierBannerComponent {
  @Input() items: NotificationBanner[] = [];
  @Input() loading: boolean;
  @Input() timer: boolean;

  @Output() clicked = new EventEmitter<string>();

  public onButtonClick(url: string) {
    this.clicked.emit(url);
  }
}
