import { formatDate } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoadAlternateIdPipe } from '@haulynx/pipes';
import { MilestonesService } from '@haulynx/services';
import { UserEntityService } from '@haulynx/store';
import {
  ActionButtonConfig,
  BookStatus,
  buttonTypes,
  Driver,
  FeatureFlag,
  FFState,
  KeyValuePair,
  LoadIdentifierType,
  LoadMilestoneInfo,
  LoadsServiceLoad,
  LoadsServiceLoadStatus,
  Milestone,
  MilestoneTrailerToggleEvent,
  RouteWaypoints,
  User,
} from '@haulynx/types';
import { aliveWhile, listToArray } from '@haulynx/utils';
import { formatDistance } from 'date-fns';
import { List } from 'lodash';
import { LngLatLike } from 'mapbox-gl';
import { ClipboardService } from 'ngx-clipboard';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-milestone-header',
  templateUrl: './milestone-header.component.html',
  styleUrls: ['./milestone-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MilestoneHeaderComponent implements OnInit, OnChanges {
  @Input() milestones: Milestone[];
  @Input() load: LoadsServiceLoad;
  @Input() showSpinner: boolean;
  @Input() user: User;
  @Input() totalDistance: number;
  @Input() truckPosition: LngLatLike;
  @Input() waypoints: RouteWaypoints[];
  @Input() lastLocation: string;
  @Input() remainingDistance: number;
  @Input() drivers: Partial<Driver>[] | List<Driver> = [];

  @Output() openMilestone = new EventEmitter();
  @Output() toggle = new EventEmitter<MilestoneTrailerToggleEvent>();

  @Output() clickEvent: EventEmitter<{
    event: KeyValuePair[];
    eventType: string;
  }> = new EventEmitter();

  alive = aliveWhile();

  headerInfo: LoadMilestoneInfo;
  currentDriver: Driver;
  featureFlags: FFState;
  trailerTrackingFeatureFlag = FeatureFlag.TRAILER_TRACKING;

  constructor(
    private loadAlternateIdPipe: LoadAlternateIdPipe,
    private snackbar: MatSnackBar,
    private milestonesService: MilestonesService,
    private clipboardService: ClipboardService,
    private userEntityService: UserEntityService
  ) {}

  ngOnInit(): void {
    this.userEntityService.featureFlags$.pipe(takeUntil(this.alive)).subscribe((features: FFState) => {
      this.featureFlags = features;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { drivers } = changes;
    if (!changes.user && !changes.showSpinner) {
      this.headerInfo = this.milestonesService.getMilestoneHeaderInformation(
        this.milestones,
        this.load,
        this.totalDistance,
        this.truckPosition,
        this.waypoints,
        this.remainingDistance
      );
    }
    if (drivers) {
      const newDrivers = listToArray(drivers.currentValue);
      this.currentDriver = <Driver>newDrivers.find((driver: Driver) => driver.id === this.load?.drivers[0]?.id);
    }
  }

  copyLoadInformation(): void {
    //TODO:: make this be: Load #, Origin → Destination, Status, Last Updated, Location, Loaded Miles
    const origin = this.load.locations[0];
    const orderNumber = this.loadAlternateIdPipe.transform(this.load, LoadIdentifierType.ORDER_NUMBER);
    const dest = this.load.locations[this.load.locations?.length - 1];
    const link = `https://app.haulynx.com/loads/${this.load.id}`;

    const loadInfo =
      `#${orderNumber}: ${origin.city}, ${origin.state} ${
        origin.appointmentSet ? formatDate(origin.appointmentStart, 'MMM', 'en-US', origin.timezone) + ' ' : 'No Appt'
      }` +
      `${origin.appointmentSet ? formatDate(origin.appointmentStart, 'd', 'en-US', origin.timezone) : ''} -> ` +
      `${dest.city}, ${dest.state} ${
        dest.appointmentSet ? formatDate(dest.appointmentEnd, 'MMM', 'en-US', dest.timezone) + ' ' : 'No Appt'
      }` +
      `${dest.appointmentSet ? formatDate(dest.appointmentEnd, 'd', 'en-US', dest.timezone) : ''}\n${link}`;

    this.clipboardService.copyFromContent(loadInfo);
    this.copyLoadSuccess();
  }

  copyLoadSuccess(): void {
    this.snackbar.open('Successfully copied load', 'Okay', { duration: 3000 });
  }

  get disabledButtons(): ActionButtonConfig[] {
    const loadIsReadOnly =
      this.load?.bookStatus === BookStatus.BOOKED && this.load?.loadStatus !== LoadsServiceLoadStatus.FINALLED;
    return loadIsReadOnly ? [] : [buttonTypes.CREATE_MILESTONE];
  }

  get getLastStatusUpdate(): string {
    return this.headerInfo?.displayStatus || '';
  }

  get isMilestoneOnTimeText(): string {
    if (!this.headerInfo || this.load?.loadStatus === LoadsServiceLoadStatus.FINALLED) return '';
    const lastUpdate = this.getLastStatusUpdate;
    if (
      lastUpdate === 'Available' ||
      lastUpdate === 'Ready for Dispatch' ||
      lastUpdate === 'Dispatched' ||
      lastUpdate === 'Awaiting Truck Details'
    ) {
      return '';
    }
    return this.headerInfo?.isOnTime ? ' / On Time' : ' / Late';
  }

  get isMilestoneOnTime(): boolean {
    if (!this.headerInfo) return true;
    return this.headerInfo?.isOnTime;
  }

  get getTrackingType(): string {
    return this.headerInfo?.trackingType;
  }

  get getDateDistance(): string {
    return this.headerInfo ? formatDistance(new Date().valueOf(), this.headerInfo.lastUpdateDisplayTime) + ' ago' : '';
  }

  get getLastKnownLocation(): string {
    return this.headerInfo?.currentLocationName || '';
  }

  get getMilesCompleted(): string {
    return `${this.headerInfo?.milesCompleted}` || '';
  }

  get getTotalMiles(): string {
    return `${this.headerInfo?.totalMiles}` || '';
  }

  get getTrackingDriver(): string {
    const number = !this.currentDriver?.phone
      ? ''
      : '(' +
        this.currentDriver?.phone.substring(0, 3) +
        ') ' +
        this.currentDriver?.phone.substring(3, 6) +
        ' ' +
        this.currentDriver?.phone.substring(6, 12);
    const driver = !this.currentDriver?.name ? '' : this.currentDriver?.name;
    return driver + ' ' + number;
  }

  onActionButtonClicked(click: { event: KeyValuePair[]; eventType: string }): void {
    if (click.eventType === 'create-milestone') {
      this.openMilestone.emit();
    } else {
      this.clickEvent.emit({
        event: click.event,
        eventType: click.eventType,
      });
    }
  }

  toggleTrailers(trailerToggleEvent) {
    this.toggle.emit(trailerToggleEvent);
  }
}
