import { ChangeDetectionStrategy, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AdvancedSearchService } from '@haulynx/services';
import { ActiveLoadsModel, AppModel, LaneRateEntityService } from '@haulynx/store';
import {
  BulkActionPage,
  IColumns2,
  ISearchFilter,
  ISortingConfig,
  LaneBulkActionPayload,
  laneConfigOptions,
  laneDataColumns,
  LaneRateToolSearchBarResult,
  laneServiceSearchFilters,
  LaneVolatility,
  PageAndSort,
  User,
  ZipLane,
  ZipLanePageAndSort,
  ZipLaneQueryUpdates,
  ZipLaneSearchParameters,
  ZipLaneUpdate,
} from '@haulynx/types';
import { aliveWhile } from '@haulynx/utils';
import { keys } from 'lodash';
import { TableHeaderCheckbox } from 'primeng/table';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { first, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-lane-rate-tool',
  templateUrl: './lane-rate-tool.component.html',
  styleUrls: ['./lane-rate-tool.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LaneRateToolComponent implements OnInit {
  @ViewChild('stickyColumnHeader', { static: false }) stickyColumnHeader: TemplateRef<unknown>;
  @ViewChild('headerCheckbox', { static: false }) headerCheckbox: TableHeaderCheckbox;

  alive = aliveWhile();
  filters: ISearchFilter[];
  columns: IColumns2[] = [];
  BulkActionPage = BulkActionPage;

  searchBarFilters$ = new BehaviorSubject<ISearchFilter[]>([]);
  searchQuery$: Observable<{
    queryHash: string;
    payload: Partial<ZipLaneSearchParameters>;
    pageAndSort: Partial<PageAndSort>;
  }>;
  dataLoading$: Observable<boolean>;
  tableData$: Observable<ZipLane[]>;
  searchPage: Partial<PageAndSort>;
  rowActionsDisplay: boolean[] = [];
  stickyColumnWidth = '330px';
  user: User;
  showBulkActions = true;
  selectedRows: ZipLane[] = [];
  laneVolatility = LaneVolatility;
  newFormArray: FormArray;
  tableRowArray: FormArray;
  form: FormGroup;
  showRPM = true;
  savingForm = false;
  savedIndex = -1;
  configOptions = laneConfigOptions;

  /**
   * The search payload from the search bar
   */
  private searchbarVal: ZipLaneSearchParameters;

  constructor(
    public appModel: AppModel,
    private route: ActivatedRoute,
    private router: Router,
    private advancedSearchService: AdvancedSearchService,
    public laneRateEntityService: LaneRateEntityService,
    private activeLoadsModel: ActiveLoadsModel,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      tableRowArray: this.fb.array([]),
    });

    this.filters = laneServiceSearchFilters({ brokers: this.activeLoadsModel.brokersEntities$ });
    this.searchQuery$ = this.laneRateEntityService.searchManager.searchQuery$;

    this.laneRateEntityService.updateLaneManager.isLoading$.subscribe((event: boolean) => (this.savingForm = event));
    this.dataLoading$ = this.laneRateEntityService.searchManager.isSearching$;
    this.tableData$ = this.laneRateEntityService.searchManager.searchResults$.pipe(
      map((data: ZipLane[]) => {
        this.form = this.fb.group({
          tableRowArray: this.fb.array([]),
        });
        data.map((lane, index) => {
          const arr = <FormArray>this.form.get('tableRowArray');
          arr.push(
            this.fb.group({
              allInRpmEdit: [lane.allInRPM],
              costRpmEdit: [lane.costToHireRPM],
              allInEdit: [lane.allInRPM * lane.miles],
              costEdit: [lane.costToHireRPM * lane.miles],
            })
          );
        });
        return data;
      })
    );
  }

  ngOnInit(): void {
    forkJoin([
      this.route.queryParams.pipe(first()),
      this.laneRateEntityService.searchManager.searchQuery$.pipe(first()),
    ]).subscribe(([routeParams, currSearchQuery]) => {
      const payload = this.chooseInitialSearchQuery(routeParams, currSearchQuery) || { query: {} };
      this.laneRateEntityService.searchManager.dispatch(payload);

      this.laneRateEntityService.bulkUpdateLanesManager.onSuccess$.pipe(takeUntil(this.alive)).subscribe(() => {
        const updatedQuery = this.chooseInitialSearchQuery(this.route.snapshot.queryParams, currSearchQuery) || {
          query: {},
        };
        this.laneRateEntityService.searchManager.dispatch({
          query: updatedQuery.query,
          pageAndSort: this.searchPage,
        });
      });
      this.laneRateEntityService.updateLaneManager.onSuccess$.pipe(takeUntil(this.alive)).subscribe(() => {
        const updatedQuery = this.chooseInitialSearchQuery(this.route.snapshot.queryParams, currSearchQuery) || {
          query: {},
        };
        this.laneRateEntityService.searchManager.dispatch({ query: updatedQuery.query, pageAndSort: this.searchPage });
      });

      const { searchFilters, searchParams } = this.advancedSearchService.convertSearchPayloadToSearchBarData(
        payload.query,
        this.filters
      );
      this.searchBarFilters$.next(searchFilters);
      this.searchbarVal = searchParams;

      this.watchSearchQuery();
    });

    this.columns = laneDataColumns(this.stickyColumnWidth, null, []);
  }

  ngOnDestroy(): void {
    this.alive.destroy();
  }

  onSubmitSearch(
    event: { newData: LaneRateToolSearchBarResult; previousData: LaneRateToolSearchBarResult },
    currSearchQuery: ZipLaneSearchParameters
  ): void {
    const newSearchData = this.advancedSearchService.mergeSearchData(
      currSearchQuery,
      this.advancedSearchService.convertSearchToLaneRateToolSearchParameters(event.newData),
      this.advancedSearchService.convertSearchToLaneRateToolSearchParameters(event.previousData)
    );
    this.searchbarVal = newSearchData;
    this.laneRateEntityService.searchManager.dispatch({ query: { ...this.searchbarVal } });
  }

  onRowSelect(lane: ZipLane | ZipLane[]): void {
    this.selectedRows = lane as ZipLane[];
  }

  onBulkAction(payload: LaneBulkActionPayload): void {
    const bidIds = payload.laneInputs.map((lane) => lane.bidId);
    this.laneRateEntityService.bulkUpdateLanesManager.dispatch(bidIds, {
      input: <Partial<ZipLaneUpdate>>payload.laneInputs,
      bidIds,
    });
  }

  getSelectedRowCount(): number {
    return this.selectedRows.length;
  }

  onPage(event: { pageUp: boolean; limit: number }): void {
    if (event.pageUp) {
      this.laneRateEntityService.searchManager.goToPage(null, event.limit);
    } else {
      this.laneRateEntityService.searchManager.goToPage(-1, event.limit);
    }
  }

  onSort(
    event: { config: ISortingConfig; sortType: 'ASC' | 'DESC' },
    searchQuery: { payload: Partial<ZipLaneSearchParameters>; pageAndSort: Partial<PageAndSort> }
  ): void {
    const search: Partial<ZipLanePageAndSort> = {
      ...this.searchPage,
      sort: event.sortType === 'ASC' ? 'asc' : 'desc',
      limit: this.configOptions.pageAmount,
    };
    const indexes = event.config.dataIndex.split('~');
    if (indexes.length !== 2) {
      console.error(
        'column config dataIndex incorrect. Please format dataIndex as follows: "<DESC order type>~<ASC order type>".'
      );
    } else {
      if (indexes[1].split('?').length !== 2) {
        console.error(
          'sorting dataIndex format is incorrect. if emitSort = true, format is <asc type>~<desc type>?<local sort config>'
        );
      }
      search.order = event.sortType === 'ASC' ? indexes[0] : indexes[1].split('?')[0];
      this.laneRateEntityService.searchManager.dispatch({ query: searchQuery.payload, pageAndSort: search });
    }
  }

  onToggleChange(toggle: boolean): void {
    this.showRPM = toggle;
  }

  saveLaneForm(lane: ZipLane, index: number): void {
    this.savedIndex = index;
    const outterForm = <FormArray>this.form.controls['tableRowArray'];
    const innerForm = <FormGroup>outterForm.controls[index];

    const payload: ZipLaneQueryUpdates = [
      {
        zipLaneQueryParameters: {
          originZip: lane.locations[0].zip,
          destinationZip: lane.locations[lane.locations.length - 1].zip,
        },
        zipLaneFieldsToUpdate: {},
      },
    ];

    if (this.showRPM) {
      payload[0].zipLaneFieldsToUpdate = {
        allInRPM: innerForm.controls['allInRpmEdit'].value.toString(),
        rpm: innerForm.controls['costRpmEdit'].value.toString(),
      };
    } else {
      payload[0].zipLaneFieldsToUpdate = {
        allInRate: innerForm.controls['allInEdit'].value.toString(),
        cost: innerForm.controls['costEdit'].value.toString(),
      };
    }
    this.laneRateEntityService.updateLaneManager.dispatch([lane.bidId?.toString()], {
      input: <Partial<ZipLaneQueryUpdates>>payload,
      bidIds: [lane.bidId?.toString()],
      paging: this.searchPage,
    });
  }

  private watchSearchQuery(): void {
    this.searchQuery$.pipe(takeUntil(this.alive)).subscribe((searchQuery) => {
      this.router.navigate([], { relativeTo: this.route, queryParams: searchQuery.payload });
      // send search filters to search bar
      const { searchFilters } = this.advancedSearchService.convertSearchPayloadToSearchBarData(
        searchQuery.payload,
        this.filters
      );
      this.searchBarFilters$.next(searchFilters);
    });
  }

  /**
   * We might do an initial search depending on the the currently URL query params,
   * or if there is an existing query in the state.
   */
  private chooseInitialSearchQuery(
    routeParams: Params,
    searchQuery: { queryHash: string; payload: Partial<ZipLaneSearchParameters>; pageAndSort: Partial<PageAndSort> }
  ): {
    query: Partial<ZipLaneSearchParameters>;
    pageAndSort?: Partial<PageAndSort>;
  } {
    const hasRouteParams = !!keys(routeParams).length;
    const hasCurrentQuery = !!searchQuery?.queryHash;
    if (hasRouteParams) {
      return { query: this.advancedSearchService.convertQueryParamsToSearchPayload(routeParams, this.filters) };
    } else if (hasCurrentQuery) {
      return { query: searchQuery.payload, pageAndSort: searchQuery.pageAndSort };
    }
    return null;
  }
}
