import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GetOpportunities, GetOpportunity } from '@haulynx/gql';
import { Opportunity, OpportunityParams, PaginatedData } from '@haulynx/types';
import { get } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GraphqlService } from '../graphql.service';

@Injectable({ providedIn: 'root' })
export class OpportunityService {
  constructor(private http: HttpClient, private graphqlService: GraphqlService) {}

  getOpportunity(opportunityParams: OpportunityParams): Observable<PaginatedData<Opportunity>> {
    return this.graphqlService
      .query<Opportunity>({
        query: GetOpportunity,
        variables: {
          opportunityParams,
        },
      })
      .pipe(
        map((result) => {
          return { data: get(result.data, 'getOpportunity', []), pagination: null };
        })
      );
  }

  getOpportunities(opportunityParams: OpportunityParams, owner?: string): Observable<PaginatedData<Opportunity>> {
    return this.graphqlService
      .query({
        query: GetOpportunities,
        variables: {
          opportunityParams,
        },
      })
      .pipe(
        map(
          (result: {
            data: {
              getOpportunities: Opportunity[];
            };
            error: {
              message: string;
            };
          }) => {
            let filteredResult: Opportunity[] = [];
            if (result.data && result.data.getOpportunities?.length) {
              filteredResult = result.data.getOpportunities.filter((opportunity) => {
                if (!opportunity.carrier.brokerOwners.length) {
                  return true;
                } else if (opportunity.carrier.brokerOwners.includes('UNKNOWN')) {
                  return true;
                } else if (owner && opportunity.carrier.brokerOwners.includes(owner)) {
                  return true;
                } else {
                  return false;
                }
              });
            }

            return { data: filteredResult, pagination: null };
          }
        )
      );
  }
}
