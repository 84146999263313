import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'load-complete-dialog',
  templateUrl: `./load-complete.component.html`,
  styleUrls: ['./load-complete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadCompleteDialog {
  constructor() {}
}
