export const loadFeedsMock = [
  {
    id: '-LhG7VoI8NWulcg55bot',
    pickupLocation: 'Phoenix, AZ 85003',
    deliveryLocation: 'Phoenix, AZ 85025',
    pickUpDate: '11/14',
    equipmentType: 'Dry Van',
    distance: '130',
    ratePerMile: '1.50',
    order: '16718831',
    shipper: 'Amazon',
    office: 'Chicago',
    potentialCarriers: '14',
    carrierOwner: '???',
    price: '1500',
    nowViewing: '13',
    weight: '11',
    loadLocations: [
      {
        billOfLading: '',
        commodity: '',
        completed: 1557738433091,
        creator: 'iR3S5Ysk6UV5rpqJZalThmsKaRl2',
        customerId: '',
        equipmentType: '',
        lat: 36.1699412,
        loadWeight: 0,
        locationType: 'pickup',
        lon: -115.13982960000001,
        name: 'Лас-Вегас, Невада, США',
        packingType: '',
        poNumber: '',
        quantity: 0,
        show_flag: false,
        specialNotes: '',
        temperature: '',
        timestamp: 1557586920000,
        waitTime: 0,
        condition: 'flexible',
      },
      {
        billOfLading: '',
        commodity: '',
        completed: 1557738435958,
        creator: 'iR3S5Ysk6UV5rpqJZalThmsKaRl2',
        customerId: '',
        equipmentType: '',
        lat: 40.7127753,
        loadWeight: 0,
        locationType: 'dropoff',
        lon: -74.0059728,
        name: 'New York, NY, USA',
        packingType: '',
        poNumber: '',
        quantity: 0,
        show_flag: false,
        specialNotes: '',
        temperature: '',
        timestamp: 1557673380000,
        waitTime: 0,
        condition: 'strict',
      },
    ],
  },
  {
    id: '-LgCEEd4KlsSIK1HOQnh',
    pickupLocation: 'Phoenix, AZ 85004',
    deliveryLocation: 'Phoenix, AZ 85023',
    pickUpDate: '10/14',
    equipmentType: 'Dry Van',
    distance: '140',
    rpm: '1.60',
    order: '16718830',
    shipper: 'Amazon',
    office: 'Phoenix',
    potentialCarriers: '13',
    carrierOwner: '???',
    price: '2500',
    nowViewing: '12',
    weight: '12',
    loadLocations: [
      {
        billOfLading: '',
        commodity: '',
        completed: 1557738433091,
        creator: 'iR3S5Ysk6UV5rpqJZalThmsKaRl2',
        customerId: '',
        equipmentType: '',
        lat: 36.1699412,
        loadWeight: 0,
        locationType: 'pickup',
        lon: -115.13982960000001,
        name: 'Лас-Вегас, Невада, США',
        packingType: '',
        poNumber: '',
        quantity: 0,
        show_flag: false,
        specialNotes: '',
        temperature: '',
        timestamp: 1557586920000,
        waitTime: 0,
        condition: 'strict',
      },
      {
        billOfLading: '',
        commodity: '',
        completed: 1557738435958,
        creator: 'iR3S5Ysk6UV5rpqJZalThmsKaRl2',
        customerId: '',
        equipmentType: '',
        lat: 40.7127753,
        loadWeight: 0,
        locationType: 'dropoff',
        lon: -74.0059728,
        name: 'New York, NY, USA',
        packingType: '',
        poNumber: '',
        quantity: 0,
        show_flag: false,
        specialNotes: '',
        temperature: '',
        timestamp: 1557673380000,
        waitTime: 0,
        condition: 'flexible',
      },
    ],
  },
];

export const loadFeedsMocks = [
  {
    id: '-LhG7VoI8NWulcg55bot',
    pickupLocation: 'Phoenix, AZ 85003',
    deliveryLocation: 'Phoenix, AZ 85025',
    pickUpDate: '11/14',
    equipmentType: 'Dry Van',
    distance: '130',
    ratePerMile: '1.50',
    order: '16718831',
    shipper: 'Amazon',
    office: 'Chicago',
    potentialCarriers: '14',
    carrierOwner: '???',
    price: '1500',
    nowViewing: '13',
    weight: '11',
    loadLocations: [
      {
        billOfLading: '',
        commodity: '',
        completed: 1557738433091,
        creator: 'iR3S5Ysk6UV5rpqJZalThmsKaRl2',
        customerId: '',
        equipmentType: '',
        lat: 36.1699412,
        loadWeight: 0,
        locationType: 'pickup',
        lon: -115.13982960000001,
        name: 'Лас-Вегас, Невада, США',
        packingType: '',
        poNumber: '',
        quantity: 0,
        show_flag: false,
        specialNotes: '',
        temperature: '',
        timestamp: 1557586920000,
        waitTime: 0,
        condition: 'flexible',
      },
      {
        billOfLading: '',
        commodity: '',
        completed: 1557738435958,
        creator: 'iR3S5Ysk6UV5rpqJZalThmsKaRl2',
        customerId: '',
        equipmentType: '',
        lat: 40.7127753,
        loadWeight: 0,
        locationType: 'dropoff',
        lon: -74.0059728,
        name: 'New York, NY, USA',
        packingType: '',
        poNumber: '',
        quantity: 0,
        show_flag: false,
        specialNotes: '',
        temperature: '',
        timestamp: 1557673380000,
        waitTime: 0,
        condition: 'strict',
      },
    ],
  },
  {
    id: '-LgCEEd4KlsSIK1HOQnh',
    pickupLocation: 'Phoenix, AZ 85004',
    deliveryLocation: 'Phoenix, AZ 85023',
    pickUpDate: '10/14',
    equipmentType: 'Dry Van',
    distance: '140',
    ratePerMile: '1.60',
    order: '16718830',
    shipper: 'Amazon',
    office: 'Phoenix',
    potentialCarriers: '13',
    carrierOwner: '???',
    price: '2500',
    nowViewing: '12',
    weight: '12',
    loadLocations: [
      {
        billOfLading: '',
        commodity: '',
        completed: 1557738433091,
        creator: 'iR3S5Ysk6UV5rpqJZalThmsKaRl2',
        customerId: '',
        equipmentType: '',
        lat: 36.1699412,
        loadWeight: 0,
        locationType: 'pickup',
        lon: -115.13982960000001,
        name: 'Лас-Вегас, Невада, США',
        packingType: '',
        poNumber: '',
        quantity: 0,
        show_flag: false,
        specialNotes: '',
        temperature: '',
        timestamp: 1557586920000,
        waitTime: 0,
        condition: 'strict',
      },
      {
        billOfLading: '',
        commodity: '',
        completed: 1557738435958,
        creator: 'iR3S5Ysk6UV5rpqJZalThmsKaRl2',
        customerId: '',
        equipmentType: '',
        lat: 40.7127753,
        loadWeight: 0,
        locationType: 'dropoff',
        lon: -74.0059728,
        name: 'New York, NY, USA',
        packingType: '',
        poNumber: '',
        quantity: 0,
        show_flag: false,
        specialNotes: '',
        temperature: '',
        timestamp: 1557673380000,
        waitTime: 0,
        condition: 'flexible',
      },
    ],
  },
];

export const loadFeedRoute = [
  {
    id: '-LkRLSrcNzIbtz3ahf8D6vvm7pw1b',
    coordinates: [
      [-90.07148, 29.951051],
      [-90.071213, 29.951944],
      [-90.070038, 29.95148],
      [-90.070572, 29.949668],
      [-90.08416, 29.954412],
      [-90.089402, 29.954942],
      [-90.431626, 30.085798],
      [-90.439545, 30.098941],
      [-90.532944, 30.83864],
      [-90.532891, 30.843593],
      [-90.500626, 31.024673],
      [-90.499276, 31.031339],
      [-90.479737, 31.537906],
      [-90.47847, 31.542509],
      [-90.479676, 31.545867],
      [-90.210244, 32.269416],
      [-90.178292, 32.275917],
      [-89.77549, 33.332469],
      [-89.774422, 33.339855],
      [-90.025712, 35.072967],
      [-90.033471, 35.07418],
      [-90.066971, 35.125064],
      [-90.066796, 35.124828],
      [-90.189835, 35.170452],
      [-90.278634, 35.387184],
      [-89.755646, 36.125495],
      [-89.532845, 36.86893],
      [-89.528031, 36.875225],
      [-89.202782, 37.035976],
      [-88.945786, 37.774093],
      [-88.972031, 38.683971],
      [-88.511941, 39.14841],
      [-87.632645, 41.71466],
      [-87.624131, 41.720428],
      [-87.625596, 41.768665],
      [-87.630379, 41.837532],
      [-87.631104, 41.845283],
      [-87.631058, 41.852783],
      [-87.627091, 41.852821],
      [-87.627633, 41.875553],
      [-87.629227, 41.875701],
      [-87.629265, 41.876888],
      [-87.629731, 41.876876],
      [-87.629769, 41.878112],
    ],
    wayPoints: [
      { name: 'New Orleans, LA, USA', location: [-90.0715323, 29.95106579999999] },
      { name: 'Chicago, IL, USA', location: [-87.62979819999998, 41.8781136] },
    ],
    truckPosition: [-111.93848441, 33.42412595],
    lineColor: 'darkslategrey',
    lineWidth: 3,
    markerColor: '#092a63',
  },
];

export const autocompleteOptions = ['Phoenix, AZ 85003', 'Phoenix, AZ 85004', 'Phoenix, AZ 85023', 'Phoenix, AZ 85025'];
