import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { WindowRef } from '../app-services/generic/window-ref';

@Injectable({
  providedIn: 'root',
})
export class SessionStoreService {
  public changes$ = new Subject<any>();
  private storage: Storage;

  constructor(windowRef: WindowRef) {
    this.storage = windowRef.getNativeWindow().sessionStorage;
  }

  public async get<T>(key: string): Promise<T> {
    try {
      const data = await JSON.parse(this.storage.getItem(key));
      return data || null;
    } catch (e) {
      throw 'Unable to get item from storage.';
    }
  }

  public set(key: string, data: any): void {
    this.storage.setItem(key, JSON.stringify(data));

    this.changes$.next({ key, value: data });
  }

  public async removeItem(key: string): Promise<void> {
    try {
      this.storage.removeItem(key);
    } catch (e) {
      throw 'Unable to remove item from storage.';
    }
    this.changes$.next({ key, value: null });
  }

  public async clear(): Promise<void> {
    try {
      this.storage.clear();
    } catch (e) {
      throw 'Unable to clear storage';
    }
    this.changes$.next({});
  }
}
