import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { LoadStatusPipe } from '@haulynx/pipes';
import { LocalStoreService, WindowRef } from '@haulynx/services';
import { ActiveLoadsModel, AppModel, CommonEntities, LoadDetailsModel, UserEntityService } from '@haulynx/store';
import {
  ActiveLoadFilter,
  AssignDriverForm,
  CustomerFacilityDto,
  DataTableSelection,
  DispatchForm,
  DriverAssignment,
  EquipmentTypes,
  equipmentTypes,
  FFState,
  LoadsServiceLoad,
  LoadStep,
  loadStepType,
  LoadStepType,
  loadStepTypeStatus,
  LocationForm,
  QueryParams,
  TrailerAssignment,
  TruckAssignment,
} from '@haulynx/types';
import { aliveWhile, listToArray } from '@haulynx/utils';
import { List } from 'immutable';
import { CustomerDetailsDialogComponent } from 'libs/components/src/lib/customer-details/components/customer-details-dialog/customer-details-dialog.component';
import { AddDriverComponent } from 'libs/components/src/lib/pay-line-items/add-driver/add-driver.component';
import { AddTrailerComponent } from 'libs/components/src/lib/pay-line-items/add-trailer/add-trailer.component';
import { AddTruckComponent } from 'libs/components/src/lib/pay-line-items/add-truck/add-truck.component';
import { concat, get } from 'lodash';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, first, map, takeUntil, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'app-active-load-search-container',
  templateUrl: './active-load-search-container.component.html',
  styleUrls: ['./active-load-search-container.component.scss'],
  providers: [
    {
      multi: false,
      provide: EquipmentTypes,
      useValue: equipmentTypes,
    },
  ],
})
export class ActiveLoadSearchContainerComponent implements OnInit, OnDestroy {
  alive = aliveWhile();
  selectedLoad = null;
  activeStep: LoadStep = null;
  setActiveStep$ = new BehaviorSubject(null);
  trucksAndDevice$ = new BehaviorSubject([]);
  defaultPagination = {
    page: 1,
    limit: 20,
  };
  isSkipped$ = new BehaviorSubject(null);
  lastStep: LoadStep = null;
  steps: LoadStep[];
  mapSkippedLoads = {};
  activeLoads: LoadsServiceLoad[] = null;
  previousUrl: string = null;
  currentUrl: string = null;
  featureFlags$: Observable<FFState>;
  public loadStepType: LoadStepType = loadStepType;
  private carrierId = null;
  private carrierDot = null;
  private carrierName = null;

  constructor(
    public loadDetailsModel: LoadDetailsModel,
    public activeLoadsModel: ActiveLoadsModel,
    private router: Router,
    public commonEntities: CommonEntities,
    public equipmentType: EquipmentTypes,
    public appModel: AppModel,
    private dialog: MatDialog,
    private userEntityService: UserEntityService,
    private loadStatusPipe: LoadStatusPipe,
    private localStoreService: LocalStoreService,
    private windowRef: WindowRef
  ) {}

  searchLoads(query: ActiveLoadFilter): void {
    this.activeLoadsModel.searchLoads({ query, ...this.defaultPagination });
  }

  carrierSearch(search: ActiveLoadFilter): void {
    const pagination = { limit: 20, page: 1 };
    const query = { search };

    this.activeLoadsModel.searchCarriers({ query, ...pagination });
  }

  selectLoad(data: DataTableSelection<LoadsServiceLoad>): void {
    const key = data.row && data.row.id;
    const namespace = 'LOAD_SEARCH_LOADS';
    const state = data.selection;

    if (!this.selectedLoad || (this.selectedLoad && this.selectedLoad.id !== get(state, 'id', null))) {
      this.loadDetailsModel.setLoad({ key, state });
      this.loadDetailsModel.setStateKey({ key, namespace });
    }
  }

  submitLocation(loadId: string, locationId: string, data: LocationForm): void {
    this.loadDetailsModel.confirmLocation(loadId, locationId, data);
  }

  submitDispatch(loadId: string, data: DispatchForm): void {
    this.loadDetailsModel.dispatchLoad(loadId, data);
  }

  quickAction({ action, payload }, load: LoadsServiceLoad): void {
    if (action === 'all-info') {
      const { id } = payload;
      const url = `/loads/active/${id}`;

      this.localStoreService.set(load.id, this.router.url);
      this.windowRef.getNativeWindow().open(url, '_blank');
    }

    if (action === 'close') {
      const key = load && load.id;
      this.loadDetailsModel.setLoad({ key, state: null });
    }
  }

  submitDriver(loadId: string, data: AssignDriverForm): void {
    this.loadDetailsModel.assignToDriver(loadId, data);
  }

  changePagination(pagination: { limit: number; page: number }): void {
    this.activeLoadsModel.searchLoads(pagination);
  }

  skipStep(loadId: string): void {
    this.isSkipped$.next({ loadId, skip: true });
  }

  onAddDriver(): void {
    this.dialog
      .open(AddDriverComponent, {
        data: { carrierId: this.carrierId },
      })
      .afterClosed()
      .subscribe(async (driverAssignment: DriverAssignment) => {
        if (driverAssignment) {
          driverAssignment.carrierId = this.carrierId || '';
          driverAssignment.carrierDot = this.carrierDot;
          driverAssignment.carrierName = this.carrierName || '';

          this.loadDetailsModel.createDriver(driverAssignment);
        }
      });
  }

  onAddTruck(): void {
    this.dialog
      .open(AddTruckComponent, {
        data: { carrierId: this.carrierId },
      })
      .afterClosed()
      .subscribe((truckAssignment: TruckAssignment) => {
        if (truckAssignment) {
          this.loadDetailsModel.createTruck(truckAssignment);
        }
      });
  }

  onAddTrailer(): void {
    this.dialog
      .open(AddTrailerComponent, {
        data: { carrierId: this.carrierId },
      })
      .afterClosed()
      .subscribe((trailerAssignment: TrailerAssignment) => {
        if (trailerAssignment) {
          this.loadDetailsModel.createTrailer(trailerAssignment);
        }
      });
  }

  ngOnInit(): void {
    this.featureFlags$ = this.userEntityService.featureFlags$;

    combineLatest([this.commonEntities.graphQlTrucks.entities$, this.commonEntities.graphQlDevices.entities$])
      .pipe(takeUntil(this.alive))
      .subscribe(([trucks, device]) => {
        const truckAndDevices = concat(trucks.toArray(), device.toArray());

        this.trucksAndDevice$.next(truckAndDevices);
      });

    this.loadDetailsModel.steps$.pipe(takeUntil(this.alive)).subscribe((steps: LoadStep[]) => {
      this.steps = steps;
    });

    this.isSkipped$
      .pipe(
        map((params) => {
          const { loadId = null, skip = false } = params || {};

          if (this.mapSkippedLoads && loadId) {
            this.mapSkippedLoads[loadId] = skip;
          }

          return !!this.mapSkippedLoads[loadId];
        })
      )
      .pipe(
        takeUntil(this.alive),
        withLatestFrom(this.loadDetailsModel.nextSteps$, this.loadDetailsModel.carrierId$, this.loadDetailsModel.id$)
      )
      .subscribe(([isSkipped, nextSteps, carrierId, key]) => {
        const { active, skipp, completed } = nextSteps;
        const currentStep = !isSkipped && completed && completed.type === this.loadStepType.ASSIGNED ? skipp : active;

        if (currentStep && currentStep.type === this.loadStepType.ASSIGNED) {
          const query = { carrierId };

          this.commonEntities.graphQlDrivers.search({ key, query });
          this.commonEntities.graphQlTrailers.search({ key, query });
          this.commonEntities.graphQlTrucks.search({ key, query });
          this.commonEntities.graphQlDevices.search({ key, query });
        }

        this.activeStep = currentStep;
        if (this.activeStep?.id === 0) {
          this.lastStep = null;
        } else {
          for (let i = 0; i < this.steps.length; i++) {
            if (this.steps[i].id === this.activeStep.id) {
              this.lastStep = this.steps[i - 1];
            }
          }
        }
        this.loadDetailsModel.nextStepTitle$.next(currentStep && currentStep.type);
      });
    this.loadDetailsModel.setStateKey({ namespace: 'LOAD_SEARCH_LOADS' });
    this.loadDetailsModel.form.state$
      .pipe(takeUntil(this.alive), withLatestFrom(this.activeLoadsModel.entities$, this.loadDetailsModel.nextSteps$))
      .subscribe(
        ([load, loads, nextSteps]: [
          LoadsServiceLoad,
          List<LoadsServiceLoad>,
          {
            completed: LoadStep;
            skipp: LoadStep;
            active: LoadStep;
          }
        ]) => {
          const existLoad = load && loads.find((item) => load && load.id === item.id);
          const loadId = existLoad && existLoad.id;
          const carrierId = existLoad && existLoad.carrier && existLoad.carrier.id;
          const carrierDot = existLoad && existLoad.carrier && existLoad.carrier.dot;
          const carrierName = existLoad && existLoad.carrier && existLoad.carrier.name;
          const { completed, active } = nextSteps;
          const isSkipped =
            completed &&
            active &&
            completed.type === this.loadStepType.ASSIGNED &&
            active.status !== loadStepTypeStatus.INACTIVE;

          this.carrierId = carrierId;
          this.carrierDot = carrierDot;
          this.carrierName = carrierName;
          this.selectedLoad = existLoad;
          this.isSkipped$.next({ loadId, skip: isSkipped });
        }
      );

    this.loadDetailsModel.updatedDetails$.pipe(takeUntil(this.alive)).subscribe((load) => {
      this.activeLoadsModel.findAndUpdate(load.id, load);
    });

    this.activeLoadsModel.searchBrokers({});

    this.activeLoadsModel.entitiesLoading$.pipe(takeUntil(this.alive)).subscribe((isLoading) => {
      if (isLoading) {
        this.carrierId = null;
        this.selectedLoad = null;
      }
    });

    this.activeLoadsModel.entities$.pipe(takeUntil(this.alive)).subscribe((loads: List<LoadsServiceLoad>) => {
      if (loads) {
        this.activeLoads = listToArray(loads).filter((load) => {
          const temp = this.loadStatusPipe.transform(load);
          if (temp !== 'Delivered' && temp !== 'Completed') {
            return load;
          }
        });
      }
    });

    this.activeLoadsModel.entitiesQuery$.pipe(takeUntil(this.alive), first()).subscribe((query) => {
      this.router.events
        .pipe(
          first(),
          filter((event) => event instanceof NavigationEnd)
        )
        .subscribe((event: NavigationEnd) => {
          this.previousUrl = this.currentUrl;
          this.currentUrl = event.url;
          if (this.previousUrl && this.previousUrl.includes('/loads/completed')) {
            query = { ...query, forceSearch: true };
          } else {
            query = { ...query, forceSearch: true, carrier: null };
          }
          this.activeLoadsModel.searchLoads({ query, ...this.defaultPagination });
        });
    });
  }

  onShowFacilityInfo({ customerNumber, index }: CustomerFacilityDto): void {
    if (customerNumber) {
      this.dialog.open(CustomerDetailsDialogComponent, {
        data: { customerNumber, index },
        position: { top: '15%' },
      });
    }
  }

  ngOnDestroy(): void {
    if (this.router.url !== '/loads/completed') {
      let query: QueryParams = null;
      query = { ...query, forceSearch: true, carrier: null };
      this.activeLoadsModel.searchLoads({ query, ...this.defaultPagination });
    }
    const key = this.selectedLoad && this.selectedLoad.id;
    this.loadDetailsModel.setLoad({ key, state: null });
    this.alive.destroy();
  }
}
