import gql from 'graphql-tag';
import { MINIMAL_LOAD_DETAILS_FRAGMENT } from './fragments';

export const CarrierLoadSearchGetUsxLoads = gql`
  query GetUsxLoads($searchParameters: LoadServiceSearchParameters, $paging: LoadsServicePageAndSort) {
    getUSXLoads(searchParameters: $searchParameters, paging: $paging) {
      data {
        ...minimalLoadDetails
      }
      paginator {
        currentPage
        nextPage
        previousPage
        total
        totalPages
      }
    }
  }
  ${MINIMAL_LOAD_DETAILS_FRAGMENT}
`;
