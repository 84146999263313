import { combineAdapters } from '../../shared/store/helpers/adapter';
import { createSearchAdapter, SearchAdapter } from '../../shared/store/search/adapter';

export const TYPE = '[FAVORITE ADDRESS]';

const mapState = {
  search: createSearchAdapter,
};

export interface IFavoriteAddressStateAdapter {
  search: SearchAdapter<any>;
}

export const favoriteAddressAdapter: IFavoriteAddressStateAdapter = combineAdapters<IFavoriteAddressStateAdapter>(
  mapState,
  TYPE
);
