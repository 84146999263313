import { Pipe, PipeTransform } from '@angular/core';
import { LoadsServiceLoadStatus } from '@haulynx/types';
import { FromCamelCasePipe } from './from-camel-case.pipe';

@Pipe({
  name: 'transformStatus',
})
export class TransformStatusPipe implements PipeTransform {
  constructor(public fromCamelCase: FromCamelCasePipe) {}

  transform(status: LoadsServiceLoadStatus): string {
    switch (status) {
      case LoadsServiceLoadStatus.UNASSIGNED:
        return 'Awaiting Truck Details';
      case LoadsServiceLoadStatus.ASSIGNED:
        return 'Ready for Dispatch';
      case LoadsServiceLoadStatus.AT_SHIPPER:
        return 'At Pickup';
      case LoadsServiceLoadStatus.PICKED_UP:
        return 'In Transit';
      case LoadsServiceLoadStatus.AT_RECEIVER:
        return 'At Dropoff';
      default:
        return this.fromCamelCase.transform(status);
    }
  }
}
