import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LoadEntitiesModel } from './load-entities-model';
import { LoadEntitiesEffects } from './load-entities.effects';
import { loadEntitiesReducer } from './load-entities.reducer';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature('load-entities', loadEntitiesReducer),
    EffectsModule.forFeature([LoadEntitiesEffects]),
  ],
  exports: [],
  providers: [LoadEntitiesEffects, LoadEntitiesModel],
})
export class LoadEntitiesStateModule {}
