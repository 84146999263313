<app-spinner
  class="modal-spinner"
  [active]="featureFlagDashboardModel.isLoadingDetails$ | async"
  [fullCover]="true"
></app-spinner>

<mat-dialog-content>
  <div class="feature-flags">
    <h3 class="feature-flags__title">
      {{ data?.featureFlagName ? 'Edit Feature' : 'Create a Feature' }}
    </h3>
    <form [formGroup]="form">
      <div class="feature-flags__row">
        <mat-form-field class="app-form-field" hideRequiredMarker appearance="fill" floatLabel="always">
          <mat-label class="release-notes__label"> Name </mat-label>
          <input
            data-test="feature-name-input"
            matInput
            formControlName="name"
            autocomplete="off"
            placeholder="Feature name"
            maxlength="30"
          />
        </mat-form-field>
      </div>

      <div class="feature-flags__row">
        <mat-form-field class="app-form-field" hideRequiredMarker appearance="fill" floatLabel="always">
          <mat-label class="release-notes__label"> Description </mat-label>
          <input
            data-test="feature-description-input"
            matInput
            formControlName="description"
            autocomplete="off"
            placeholder="Feature description"
          />
        </mat-form-field>
      </div>

      <div class="feature-flags__row feature-flags__row--platforms" formGroupName="platforms">
        Platforms

        <div class="feature-flags__build">
          <mat-checkbox data-test="ios-checkbox" color="primary" class="feature-flags__platform" formControlName="ios">
            iOS</mat-checkbox
          >

          <div class="feature-flags__build__item" [formGroup]="builds" *ngIf="platforms.value.ios">
            <mat-form-field class="app-form-field" hideRequiredMarker appearance="fill" floatLabel="always">
              <input appTrim matInput formControlName="ios" autocomplete="off" placeholder="Build" />
            </mat-form-field>
          </div>
        </div>

        <div class="feature-flags__build">
          <mat-checkbox
            data-test="android-checkbox"
            color="primary"
            class="feature-flags__platform"
            formControlName="android"
            >Android</mat-checkbox
          >

          <div class="feature-flags__build__item" [formGroup]="builds" *ngIf="platforms.value.android">
            <mat-form-field class="app-form-field" hideRequiredMarker appearance="fill" floatLabel="always">
              <input appTrim matInput formControlName="android" autocomplete="off" placeholder="Build" />
            </mat-form-field>
          </div>
        </div>

        <div class="feature-flags__build">
          <mat-checkbox data-test="web-checkbox" color="primary" class="feature-flags__platform" formControlName="web">
            Web</mat-checkbox
          >
        </div>
      </div>

      <mat-divider class="feature-flags__divider"></mat-divider>

      <h3 class="feature-flags__title">Environments</h3>

      <ng-container *ngFor="let env of environments.controls; let i = index">
        <div class="feature-flags__environment" formArrayName="environments">
          <ng-container [formGroupName]="i">
            <div class="feature-flags__environment__header">
              <mat-slide-toggle
                [attr.data-test]="env.value.environment"
                color="primary"
                (change)="onToggleEnvironment($event, i)"
                [checked]="isEnvironmentActive[i]"
              >
                {{ env.value.environment }}
              </mat-slide-toggle>
            </div>

            <div class="feature-flags__environment__content" *ngIf="isEnvironmentActive[i]">
              <mat-divider class="feature-flags__divider"></mat-divider>
              <h4 class="feature-flags__subtitle">Users</h4>
              <data-table
                data-test="users-table"
                *ngIf="users[i]?.length"
                [data]="users[i]"
                [displayedColumns]="userColumns"
                [templateCellRef]="usersTemplateRef"
                [pageOptions]="[rowsPerPage]"
                [showPagination]="users[i]?.length > rowsPerPage"
              >
                <ng-template #usersTemplateRef let-row="row" let-column="column">
                  <ng-container *ngIf="column === 'entityName'">
                    {{ row?.name }}
                  </ng-container>

                  <ng-container *ngIf="column === 'entityEmail'">
                    {{ row?.email }}
                  </ng-container>

                  <ng-container *ngIf="column === 'options'">
                    <button
                      data-test="delete-user-button"
                      mat-icon-button
                      type="button"
                      (click)="onDeleteEntity({ name: row.id, type: entityTypes.USER, index: i })"
                    >
                      <mat-icon>delete_outline</mat-icon>
                    </button>
                  </ng-container>
                </ng-template>
              </data-table>

              <div class="feature-flags__row">
                <mat-form-field
                  class="app-form-field feature-flags__form-field"
                  hideRequiredMarker
                  appearance="fill"
                  floatLabel="always"
                >
                  <app-drop-down
                    placeholder="Email"
                    formControlName="userToPopulate"
                    [data]="(featureFlagDashboardModel.users$ | async)[i]"
                    [changeableData]="true"
                    [chooseDefaultOnTab]="true"
                    [isLoading]="(featureFlagDashboardModel.isLoadingUsers$ | async)[i]"
                    displayLabel="email"
                    key="id"
                    (keywordChanges)="onValueChanges({ name: $event, type: entityTypes.USER, index: i })"
                    (selected)="onPopulate({ type: entityTypes.USER, index: i })"
                  ></app-drop-down>
                </mat-form-field>
              </div>

              <h4 class="feature-flags__subtitle">Carriers</h4>
              <data-table
                *ngIf="carriers[i]?.length"
                [data]="carriers[i]"
                [displayedColumns]="carrierColumns"
                [templateCellRef]="carriersTemplateRef"
                [pageOptions]="[rowsPerPage]"
                [showPagination]="carriers[i]?.length > rowsPerPage"
              >
                <ng-template #carriersTemplateRef let-row="row" let-column="column">
                  <ng-container *ngIf="column === 'entityName'">
                    {{ row?.name }}
                  </ng-container>

                  <ng-container *ngIf="column === 'entityEmail'">
                    {{ row?.email }}
                  </ng-container>

                  <ng-container *ngIf="column === 'options'">
                    <button
                      mat-icon-button
                      type="button"
                      (click)="onDeleteEntity({ name: row.dot, type: entityTypes.CARRIER, index: i })"
                    >
                      <mat-icon>delete_outline</mat-icon>
                    </button>
                  </ng-container>
                </ng-template>
              </data-table>

              <div class="feature-flags__row">
                <mat-form-field
                  class="app-form-field feature-flags__form-field"
                  hideRequiredMarker
                  appearance="fill"
                  floatLabel="always"
                >
                  <app-drop-down
                    placeholder="MC/DOT/Carrier"
                    formControlName="carrierToPopulate"
                    [data]="carriersFilter[i]"
                    [isLoading]="(featureFlagDashboardModel.isLoadingCarriers$ | async)[i]"
                    [chooseDefaultOnTab]="true"
                    [changeableData]="true"
                    [customFilter]="true"
                    displayLabel="name"
                    key="dot"
                    (keywordChanges)="onValueChanges({ name: $event, type: entityTypes.CARRIER, index: i })"
                    (selected)="onPopulate({ type: entityTypes.CARRIER, index: i })"
                  ></app-drop-down>
                </mat-form-field>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <div class="feature-flags__action">
        <button
          data-test="cancel-button"
          mat-flat-button
          class="feature-flags__button--cancel"
          [mat-dialog-close]="false"
        >
          Cancel
        </button>
        <button
          data-test="save-button"
          mat-flat-button
          class="feature-flags__button--save"
          [disabled]="form.invalid"
          (click)="onSave()"
        >
          Save Changes
        </button>
      </div>
    </form>
  </div>
</mat-dialog-content>
