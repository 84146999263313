<div class="assign-load-carrier-container">
  <ng-container *ngIf="data?.loadId; else noLoadDefined">
    <app-assign-load-carrier-form
      [drivers]="commonEntities.graphQlDrivers.entities$ | async"
      [trucks]="commonEntities.graphQlTrucks.entities$ | async"
      [trailers]="commonEntities.graphQlTrailers.entities$ | async"
      [brokers]="commonEntities.graphQlBrokersIds.entities$ | async"
      [carriers]="commonEntities.graphQlCarriers.entities$ | async"
      [carrier]="selectedCarrier$ | async"
      [carrierAdmin]="carrierAdmin$ | async"
      [load]="load"
      [customerNotes]="customerNotes$ | async"
      [featureFlagState]="userEntityService.featureFlags$ | async"
      [formData]="formData$ | async"
      [isLoading]="isLoading$ | async"
      [isLoadingCreateEntity]="loadDetailsModel.isLoadingCreateEntity$ | async"
      [isLoadingCarriers]="commonEntities.graphQlCarriers.isLoading$ | async"
      [paymentTypes]="commonEntities.paymentTypes.entities$ | async"
      [isLoadingDrivers]="commonEntities.graphQlDrivers.isLoading$ | async"
      [isLoadingTrucks]="commonEntities.graphQlTrucks.isLoading$ | async"
      [isLoadingTrailers]="commonEntities.graphQlTrailers.isLoading$ | async"
      [driverToPopulate]="loadDetailsModel.driverToPopulate$ | async"
      [truckToPopulate]="loadDetailsModel.truckToPopulate$ | async"
      [trailerToPopulate]="loadDetailsModel.trailerToPopulate$ | async"
      [formMode]="data?.formMode || assignLoadCarrierFormMode.NEW"
      [brokerEmail]="appModel.userEmail$ | async"
      (cancel)="cancel()"
      (searchCarrier)="onSearchCarrier($event)"
      (selectCarrier)="selectCarrier($event)"
      (bookForCarrier)="bookForCarrier($event)"
      (updatePayment)="updatePayment($event)"
      (addPayType)="onAddPayType($event)"
      (deletePayType)="onDeletePayType($event)"
      (editPayType)="onEditPayType($event)"
      (addDriver)="onCreateDriver($event)"
      (addTruck)="onCreateTruck($event)"
      (addTrailer)="onCreateTrailer($event)"
    ></app-assign-load-carrier-form>
  </ng-container>
  <ng-template #noLoadDefined>Load not found!</ng-template>
</div>
