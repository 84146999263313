import { DateRangeFilterType, ISearchFilter } from '../search-filter';
import { ISearchFilterType } from '../search-filter.types';

export class PickupDateFilter implements ISearchFilter {
  name = 'Pickup Date';
  type: ISearchFilterType = ISearchFilterType.DATE_RANGE;
  keys: DateRangeFilterType;
  description = 'Filter loads by a pickup date range';

  constructor(defaultValues?: DateRangeFilterType) {
    this.keys = new DateRangeFilterType({
      from: null,
      to: null,
      fromFormName: 'firstAppointmentStart',
      toFormName: 'firstAppointmentEnd',
      ...defaultValues,
    });
  }
}
