<div
  [ngClass]="{ sidebar__wrapper: true, sidebar__collapse: !isSidebarOpened }"
  [@sidebarToggle]="isSidebarOpened ? 'expanded' : 'collapsed'"
>
  <div class="sidebar_container">
    <mat-nav-list class="sidebar">
      <h3 *ngIf="name !== ''" class="sidebar__title">
        {{ name }}
        <mat-icon svgIcon="haulynx-sidebar-menu" (click)="onToggleSidebar()"></mat-icon>
      </h3>
      <ng-container *ngFor="let item of items; trackBy: trackByFn">
        <app-sidebar-node
          [item]="item"
          [activeNodeLabel]="selectedLink"
          [isSidebarOpened]="isSidebarOpened"
          (itemSelected)="onItemSelected($event)"
          (deleteSidebarItem)="onDeleteSidebarItem($event)"
          (updateSidebarItem)="onUpdateSidebarItem($event)"
        ></app-sidebar-node>
      </ng-container>
    </mat-nav-list>
  </div>
</div>
