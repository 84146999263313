<mat-toolbar class="carrier-search-toolbar">
  <mat-toolbar-row>
    <h6 class="title">CARRIER LIST</h6>
    <form class="form haulynx-form" [formGroup]="form">
      <mat-form-field class="carrier-search-field">
        <mat-icon matPrefix class="carrier-search-icon">search</mat-icon>
        <input matInput formControlName="carrierSearch" />
      </mat-form-field>
    </form>
  </mat-toolbar-row>
</mat-toolbar>

<div class="row">
  <div class="col-lg-12">
    <data-table
      [data]="data"
      [clickableRow]="true"
      [loading]="isLoading"
      [displayedColumns]="dataTableColumns"
      [pageOptions]="pageOptions"
      [lazyLoad]="true"
      [pagination]="pagination"
      [sortActiveColumn]="'match'"
      [sortActiveDirection]="'desc'"
      (onPagination)="pageChange($event)"
      [cls]="'recommended-carriers'"
      [templateCellRef]="templateCell"
      [selection]="true"
      [multipleSelection]="false"
      (onSelect)="selectCarrier($event)"
    >
      <ng-template #templateCell let-column="column" let-row="row">
        <div *ngIf="column === 'carrier'">
          <div
            *ngIf="row.owner | showCarrierMark"
            [ngClass]="{ 'carrier-mark--owned': user.email | isCarrierOwned: row.owner }"
            class="carrier-mark"
          ></div>
          <a>{{ row.name }}</a>
        </div>
        <div *ngIf="column === 'match'">
          <a>{{ row.match | number: '1.0-0' }}%</a>
        </div>
        <div *ngIf="column === 'sourceType'">
          <a>{{ row.sourceType }}</a>
        </div>
        <div *ngIf="column === 'name'">
          <a>{{ row.name }}</a>
        </div>
        <div *ngIf="column === 'owner'">
          <a>{{ row.owner | carrierOwner: 'Unknown' }}</a>
        </div>
        <div *ngIf="column === 'dot'">
          {{ row.dot }}
        </div>
        <div *ngIf="column === 'adminName'">
          {{ row.adminName }}
        </div>
        <div *ngIf="column === 'email'">
          {{ row.email }}
        </div>

        <div class="clickerIcon" *ngIf="column === 'clicker'">
          <mat-icon data-test="clickerTest" [carrierDetails]="row.dot" svgIcon="haulynx-load-feed-icon"> </mat-icon>
        </div>

        <div *ngIf="column === 'action'">
          <action-buttons
            *ngIf="row.sourceType !== carrierSourceType.LEAD"
            [disableButtons]="disableButtons | get: row.id:[]"
            [buttons]="buttons$ | async"
            (onClick)="action($event, row)"
          >
          </action-buttons>
        </div>
        <div *ngIf="column === 'bookLoadButton'">
          <app-book-load-button
            *ngIf="row.sourceType !== carrierSourceType.LEAD"
            [load]="load"
            [carrierDot]="row.carrierDot || row.dot"
            [buttonText]="'BOOK LOAD'"
          >
          </app-book-load-button>
        </div>
      </ng-template>
    </data-table>
  </div>
</div>
