<div class="search-results-container">
  <div fxHide fxShow.lt-md fxLayout="column">
    <div fxFlexOffset="16px">
      <app-spinner [active]="loading"></app-spinner>

      <div *ngIf="!loads?.length && !loading" fxLayout="row" fxLayoutAlign="center center">No Loads Found</div>
      <div *ngIf="loads?.length" fxLayout="column" fxLayoutGap="16px">
        <mat-card *ngFor="let load of loads" class="card" (click)="viewLoad(load)">
          <mat-card-content class="top">
            <div fxLayout="row">
              <div fxLayout="column" class="location" fxFlex="50">
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                  <div class="origin"></div>
                  <div class="city-state">
                    {{ load.locations | nth: 0 | get: 'summaryAddress':'N/A' | address: 3 }},
                    {{ load.locations | nth: 0 | get: 'summaryAddress':'N/A' | address: 4 }}
                  </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                  <div fxFlexOffset="22px">
                    <div class="load-date" *ngIf="load.locations | nth: 0 | get: 'appointmentSet':false; else noApp">
                      {{
                        load.locations
                          | nth: 0
                          | loadServiceLocationTime: { timeFormat: 'MM/dd/yy, HH:mm', showLabel: true }
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div fxLayout="column" class="location" fxFlex="50">
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                  <div class="destination"></div>
                  <div class="city-state">
                    {{ load.locations | nth: -1 | get: 'summaryAddress':'N/A' | address: 3 }},
                    {{ load.locations | nth: -1 | get: 'summaryAddress':'N/A' | address: 4 }}
                  </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                  <div fxFlexOffset="22px">
                    <div class="load-date" *ngIf="load.locations | nth: -1 | get: 'appointmentSet':false; else noApp">
                      {{
                        load.locations
                          | nth: -1
                          | loadServiceLocationTime: { timeFormat: 'MM/dd/yy, HH:MM', showLabel: true }
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-card-content>
          <mat-card-content class="details">
            <div fxLayout="row wrap">
              <div fxFlex="50" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" class="info">
                <div class="key">Equipment:</div>
                <div class="value">{{ (load?.providerDetails?.equipmentType | fromCamelCase) || 'N/A' }}</div>
              </div>
              <div
                fxFlex
                fxFlexOffset.gt-xs="22px"
                fxLayout="row"
                fxLayoutAlign="start center"
                fxLayoutGap="8px"
                class="info"
              >
                <div class="key">Loaded Miles:</div>
                <div class="value">{{ load?.paymentDetails?.distanceMiles | safeNumber: '1.0-0' }}</div>
              </div>
              <div fxFlex="50" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" class="info">
                <div class="key">Order #:</div>
                <div class="value">
                  {{
                    (load | loadAlternateId: loadIdentifierType.BILL_OF_LADING) ||
                      (load | loadAlternateId: loadIdentifierType.ORDER_NUMBER) ||
                      'N/A'
                  }}
                </div>
              </div>
              <div
                fxFlex
                fxFlexOffset.gt-xs="22px"
                fxLayout="row"
                fxLayoutAlign="start center"
                fxLayoutGap="8px"
                class="info"
              >
                <div class="key">TMW #:</div>
                <div class="value">{{ (load | loadAlternateId: loadIdentifierType.TMW_NUMBER) || 'N/A' }}</div>
              </div>
              <div fxFlex="50" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" class="info">
                <div class="key">Price:</div>
                <div class="value price">
                  <div *ngIf="showPrice; else dontShowPrice">
                    <app-load-price
                      [price]="load?.paymentDetails?.price"
                      [loadId]="load?.id"
                      [editablePrice]="false"
                    ></app-load-price>
                  </div>
                  <ng-template #dontShowPrice>
                    <div
                      class="view-price"
                      fxLayout="row"
                      fxLayoutAlign="start center"
                      fxLayoutGap="8px"
                      (click)="viewPriceClick($event)"
                    >
                      <div>VIEW PRICE</div>
                      <img src="./assets/images/lock.svg" />
                    </div>
                  </ng-template>
                </div>
              </div>
              <div
                fxFlex
                fxFlexOffset.gt-xs="22px"
                fxLayout="row"
                fxLayoutAlign="start center"
                fxLayoutGap="8px"
                class="info"
              >
                <div class="key">Weight:</div>
                <div class="value">{{ load?.providerDetails?.weight | safeNumber: '1.0-0' }}</div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>

  <div class="data-table" fxHide.lt-md fxShow>
    <data-table
      [data]="loads"
      [loading]="loading"
      [displayedColumns]="columns"
      (onClick)="viewLoad($event)"
      [templateCellRef]="templateCellSearch"
      [clickableRow]="true"
    >
      <ng-template #templateCellSearch let-row="row" let-column="column">
        <div *ngIf="column === 'pickupLocation'">
          {{ row.locations | nth: 0 | get: 'summaryAddress':'N/A' | address: 3 }}
        </div>

        <div *ngIf="column === 'pickupState'">
          {{ row.locations | nth: 0 | get: 'summaryAddress':'N/A' | address: 4 }}
        </div>

        <div *ngIf="column === 'deliveryLocation'">
          {{ row.locations | nth: -1 | get: 'summaryAddress':'N/A' | address: 3 }}
        </div>

        <div *ngIf="column === 'deliveryState'">
          {{ row.locations | nth: -1 | get: 'summaryAddress':'N/A' | address: 4 }}
        </div>

        <div *ngIf="column === 'pickupTimestamp'">
          <div *ngIf="row.locations | nth: 0 | get: 'appointmentSet':false; else noApp">
            <div>
              {{ row.locations | nth: 0 | loadServiceLocationTime: { timeFormat: 'MM/dd/yy', showLabel: true } }}
            </div>
            <span class="load-time">
              {{ row.locations | nth: 0 | loadServiceLocationTime: { timeFormat: 'HH:mm', showLabel: true } }}
            </span>
          </div>
        </div>

        <div *ngIf="column === 'dropoffTimestamp'">
          <div *ngIf="row.locations | nth: -1 | get: 'appointmentSet':false; else noApp">
            <div>
              {{ row.locations | nth: -1 | loadServiceLocationTime: { timeFormat: 'MM/dd/yy', showLabel: true } }}
            </div>
            <span class="load-time">
              {{ row.locations | nth: -1 | loadServiceLocationTime: { timeFormat: 'HH:mm', showLabel: true } }}
            </span>
          </div>
        </div>

        <div *ngIf="column === 'equipmentType'">
          {{ row?.providerDetails?.equipmentType || 'Unknown' | fromCamelCase }}
        </div>

        <div *ngIf="column === 'distance'">
          {{ row?.paymentDetails?.distanceMiles | safeNumber: '1.0-0' }}
        </div>

        <div *ngIf="column === 'order'">
          {{ row | loadAlternateId: loadIdentifierType.ORDER_NUMBER }}
        </div>

        <div *ngIf="column === 'tmwNumber'">
          {{ (row | loadAlternateId: loadIdentifierType.TMW_NUMBER) || 'N/A' }}
        </div>

        <div *ngIf="column === 'shipper'">
          {{ row.locations | nth: 0 | get: 'customer.name':'N/A' }}
        </div>

        <div *ngIf="column === 'carrierOwner'">
          {{ row.owner | carrierOwner: 'Unknown' }}
        </div>

        <div
          *ngIf="column === 'price'"
          class="view-price"
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="8px"
        >
          <div *ngIf="showPrice; else dontShowPrice">
            <app-load-price
              [price]="row?.paymentDetails?.price"
              [loadId]="row.id"
              [editablePrice]="false"
            ></app-load-price>
          </div>
          <ng-template #dontShowPrice>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
              <div>VIEW PRICE</div>
              <img src="./assets/images/lock.svg" />
            </div>
          </ng-template>
        </div>

        <div *ngIf="column === 'weight'">
          {{ (row.providerDetails?.weight | safeNumber: '1.0-0') || 'N/A' }}
        </div>
      </ng-template>
    </data-table>
  </div>
</div>

<ng-template #noApp>
  <button mat-raised-button>
    <div class="no-appt-button" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="4px">
      <span>No Appt</span>
      <mat-icon>error_outline</mat-icon>
    </div>
  </button>
</ng-template>
