import { Injectable } from '@angular/core';
import { DeactivateNotifications, GetNotifications } from '@haulynx/gql';
import { get, has, isArray, isObject, isString, map as lodashMap } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GraphqlService } from '../graphql/graphql.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(private toastr: ToastrService, private graphqlService: GraphqlService) {}

  convertError(errors) {
    let msg = '';

    if (isArray(errors)) {
      msg = lodashMap(errors, (error) => {
        const { path, message } = error;
        return `${message}\n`;
      }).join('');
    } else if (errors && errors.message) {
      msg = errors.message;
    } else if (isString(errors)) {
      msg = errors;
    } else {
      msg = 'Some internal error, please contact support for details';
    }

    return msg;
  }

  success(message: string, title: string) {
    if (isObject(message) && has(message, 'message')) {
      message = get(message, 'message', '');
    }

    this.toastr.success(message, title);
  }

  error(message: string, title = 'Woops! There was a problem.') {
    const newMessage = this.convertError(message);
    this.toastr.clear();
    this.toastr.error(newMessage, title);
  }

  warning(message: string, title: string) {
    if (isObject(message) && has(message, 'message')) {
      message = get(message, 'message', '');
    }

    this.toastr.warning(message, title);
  }

  getNotifications(filterParams?: {
    recipient?: string;
    eventType?: string;
    targetObjectId?: string;
    targetCarrierDot?: string;
    targetUserId?: string;
    targetTruckId?: string;
    pastDays?: number;
  }): Observable<unknown[]> {
    return this.graphqlService
      .query({
        query: GetNotifications,
        variables: {
          filterParams,
        },
      })
      .pipe(map((result) => result?.data['getNotifications'] || []));
  }

  deactivateNotifications(variables: { creatorId: string; notificationIds: string[] }): Observable<unknown> {
    const { creatorId, notificationIds } = variables;

    return this.graphqlService.mutate({
      variables: { creatorId, notificationIds },
      mutation: DeactivateNotifications,
    });
  }
}
